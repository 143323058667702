import { Message } from '../../message';

export class LSTourneyTimeBank extends Message {
    private MESSAGE_NAME: string = 'LSTourneyTimeBank';
    private initialTimeBankInSeconds: number = 0;
    private replenishTimeInSeconds: number = 0;
    private rateOfReplenishTimeInMInutes: number = 0;
    private timeBankCapInSeconds: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        initialTimeBankInSeconds: number = 0,
        replenishTimeInSeconds: number = 0,
        rateOfReplenishTimeInMInutes: number = 0,
        timeBankCapInSeconds: number = 0,
    ) {
        super(reqServerPeerId);
        this.initialTimeBankInSeconds = initialTimeBankInSeconds;
        this.replenishTimeInSeconds = replenishTimeInSeconds;
        this.rateOfReplenishTimeInMInutes = rateOfReplenishTimeInMInutes;
        this.timeBankCapInSeconds = timeBankCapInSeconds;
    }

    getInitialTimeBankInSeconds(): number {
        return this.initialTimeBankInSeconds;
    }

    setInitialTimeBankInSeconds(initialTimeBankInSeconds: number) {
        this.initialTimeBankInSeconds = initialTimeBankInSeconds;
    }
    getReplenishTimeInSeconds(): number {
        return this.replenishTimeInSeconds;
    }

    setReplenishTimeInSeconds(replenishTimeInSeconds: number) {
        this.replenishTimeInSeconds = replenishTimeInSeconds;
    }
    getRateOfReplenishTimeInMInutes(): number {
        return this.rateOfReplenishTimeInMInutes;
    }

    setRateOfReplenishTimeInMInutes(rateOfReplenishTimeInMInutes: number) {
        this.rateOfReplenishTimeInMInutes = rateOfReplenishTimeInMInutes;
    }
    getTimeBankCapInSeconds(): number {
        return this.timeBankCapInSeconds;
    }

    setTimeBankCapInSeconds(timeBankCapInSeconds: number) {
        this.timeBankCapInSeconds = timeBankCapInSeconds;
    }
}
