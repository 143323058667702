import { Message } from '../../message';

export class TabConfigText extends Message {
    private MESSAGE_NAME: string = 'TabConfigText';
    private version: string | null = null;
    private text: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, version: string | null = null, text: string | null = null) {
        super(reqServerPeerId);
        this.version = version;
        this.text = text;
    }

    getVersion(): string | null {
        return this.version;
    }

    setVersion(version: string | null) {
        this.version = version;
    }
    getText(): string | null {
        return this.text;
    }

    setText(text: string | null) {
        this.text = text;
    }
}
