import { Properties } from '../../utils/properties';
import { GameTable } from '../game-table';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class RitCommunityCardTask extends TableEventInfo {
    private _cards: any;

    public get cards(): any {
        return this._cards;
    }

    public set cards(v: any) {
        this._cards = v;
    }

    getEventType(): TableEvents {
        return TableEvents.RIT_COMMUNITY_CARD_TASK;
    }
    next(gameTable: GameTable): void {
        const communityCards = Object.create(gameTable.communityCards);
        for (const card of communityCards) {
            if (this._cards.has(card)) {
                card.show = true;
                card.cardAnimation = true;
                card.cardHide = false;
                const cardPos = Properties.propFile['COMMONSECTION']['COMMUNITYHAND_POS'].split('#');
                card.position = new Point(cardPos[0], cardPos[1]);
            }
        }

        gameTable.communityCards = communityCards;
    }

    prev(gameTable: GameTable): void {
        const communityCards = Object.create(gameTable.communityCards);
        for (const card of communityCards) {
            if (this._cards.has(card)) {
                card.cardHide = true;
                card.show = true;
                card.cardAnimation = false;
                card.cardState = '';
                // let cardPos = gameTable.dealer.position;
                // card.position = new Point((cardPos).x, (cardPos).y);
            }
        }

        gameTable.communityCards = communityCards;
    }
}
