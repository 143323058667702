import { Message } from '../../message';
import { TourneyResult } from './TourneyResult';

export class TBTTourneyResult extends Message {
    private MESSAGE_NAME: string = 'TBTTourneyResult';
    private trnyResult: TourneyResult | null = null;
    private playersRanks: any[] | null = null;
    private trnyRunTimeInSec: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        trnyResult: TourneyResult | null = null,
        playersRanks: any[] | null = null,
        trnyRunTimeInSec: number = 0,
    ) {
        super(reqServerPeerId);
        this.trnyResult = trnyResult;
        this.playersRanks = playersRanks;
        this.trnyRunTimeInSec = trnyRunTimeInSec;
    }

    getTrnyResult(): TourneyResult | null {
        return this.trnyResult;
    }

    setTrnyResult(trnyResult: TourneyResult | null) {
        this.trnyResult = trnyResult;
    }
    getPlayersRanks(): any[] | null {
        return this.playersRanks;
    }

    setPlayersRanks(playersRanks: any[] | null) {
        this.playersRanks = playersRanks;
    }
    getTrnyRunTimeInSec(): number {
        return this.trnyRunTimeInSec;
    }

    setTrnyRunTimeInSec(trnyRunTimeInSec: number) {
        this.trnyRunTimeInSec = trnyRunTimeInSec;
    }
}
