import { Message } from '../../message';

export class Winner extends Message {
    private MESSAGE_NAME: string = 'Winner';
    private winnerId: string | null = null;
    private amount: number = 0;
    private taxAmount: number = 0;
    private isCashoutEqtyWinner: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        winnerId: string | null = null,
        amount: number = 0,
        taxAmount: number = 0,
        isCashoutEqtyWinner: number = 0,
    ) {
        super(reqServerPeerId);
        this.winnerId = winnerId;
        this.amount = amount;
        this.taxAmount = taxAmount;
        this.isCashoutEqtyWinner = isCashoutEqtyWinner;
    }

    getWinnerId(): string | null {
        return this.winnerId;
    }

    setWinnerId(winnerId: string | null) {
        this.winnerId = winnerId;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getTaxAmount(): number {
        return this.taxAmount;
    }

    setTaxAmount(taxAmount: number) {
        this.taxAmount = taxAmount;
    }
    getIsCashoutEqtyWinner(): number {
        return this.isCashoutEqtyWinner;
    }

    setIsCashoutEqtyWinner(isCashoutEqtyWinner: number) {
        this.isCashoutEqtyWinner = isCashoutEqtyWinner;
    }
}
