import { Message } from '../../message';

export class PoolItaliaAuthorizationCodes extends Message {
    private MESSAGE_NAME: string = 'PoolItaliaAuthorizationCodes';
    private entryId: number = 0;
    private sessionId: string | null = null;
    private participationId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, entryId: number = 0, sessionId: string | null = null, participationId: string | null = null) {
        super(reqServerPeerId);
        this.entryId = entryId;
        this.sessionId = sessionId;
        this.participationId = participationId;
    }

    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getSessionId(): string | null {
        return this.sessionId;
    }

    setSessionId(sessionId: string | null) {
        this.sessionId = sessionId;
    }
    getParticipationId(): string | null {
        return this.participationId;
    }

    setParticipationId(participationId: string | null) {
        this.participationId = participationId;
    }
}
