import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { LoadingIndicatorService, Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import moment from 'moment';

import { PokerLeaderboardService } from './poker-leaderboard.service';

@Component({
    selector: 'poker-leaderboard-feed',
    templateUrl: 'poker-leaderboard-feed.component.html',
    standalone: true,
    imports: [CommonModule, MatTableModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, FormsModule],
})
export class PokerLeaderboardFeedComponent extends PCComponent implements OnInit {
    leaderboardFeed: any;
    contentParameters: any;
    promoData: any;
    selectedPromo: any;
    buttonParameters: any;
    filterValue: any = '';
    checkPromoData: boolean = true;
    displayedColumns: string[] = ['rank', 'points', 'screenName', 'prizes'];
    dataSource = new MatTableDataSource<any>();
    currentDate: any;
    refreshing: boolean;
    screenname: any;
    category: any;
    getPromoFilterId: Array<string>;
    categoryName: any;
    isParamCheck: boolean = false;
    selectedRowIndex: any;
    selectedRowInit: any;
    initialLoadCheck: boolean;
    pager: any = [25, 50, 100];
    hideButtons: boolean;
    queryPromoId: string;
    promoId: any;
    totalPromosData: any;
    singlePlayerRank: any[] = [];
    isSinglePlayer: boolean = false;
    playerCategoryName: any;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }
    constructor(
        private leaderboardService: PokerLeaderboardService,
        private route: ActivatedRoute,
        private loadingIndicatorService: LoadingIndicatorService,
        private page: Page,
    ) {
        super();
    }

    ngOnInit() {
        this.leaderboardFeed = this.item;

        this.contentParameters = this.leaderboardFeed.parameters;
        this.buttonParameters = this.leaderboardFeed.contentParameters;
        this.hideButtons = this.item.hideButtons;
        this.queryPromoId = this.item.queryPromoId;

        // get query parameters from the url
        this.screenname = this.getParameterByName('screenname');
        this.category = this.getParameterByName('category');

        // filter category list based on the query parameters category
        if (this.screenname && this.category) {
            let promoData;
            let finalData;
            this.getPromoFilterId = this.buttonParameters.filter((filterId: any) => filterId.promoId === this.category);

            if (this.getPromoFilterId.length != 0) {
                promoData = this.leaderboardFeed.leaderboardFeedData[this.category];
                finalData = promoData.details.filter((name: any) => name.screenName.toLowerCase() == this.screenname.toLowerCase());
            }

            if (finalData && promoData) {
                this.selectedRowInit = promoData.details.findIndex((item: any) => item.screenName.toLowerCase() == this.screenname.toLowerCase());
                this.initialLoadCheck = true;
                this.getPromo(this.getPromoFilterId[0]);
                if (finalData.length > 0) {
                    setTimeout(() => {
                        (document.querySelector('#highlightId') as HTMLElement).scrollIntoView({ block: 'center', behavior: 'smooth' });
                    }, 1000);
                }
            } else {
                this.checkPromoData = false;
                this.categoryName = this.getParameterByName('screenname');
            }
        } else if (this.screenname && !this.category) {
            const promoDataList = this.leaderboardFeed.leaderboardFeedData;
            this.isSinglePlayer = true;
            this.checkPromoData = false;
            // eslint-disable-next-line no-var
            for (var key in promoDataList) {
                // skip loop if the property is from prototype
                if (!promoDataList.hasOwnProperty(key)) continue;

                const promoData = promoDataList[key]?.details.filter((name: any) => name.screenName.toLowerCase() == this.screenname.toLowerCase());
                if (promoData && promoData.length) {
                    // const lbCategory = this.buttonParameters.filter((filterId: any) => {console.log(filterId); filterId.promoId == key});
                    this.playerCategoryName = this.buttonParameters.filter((filterId: any) => filterId.promoId === key);
                    promoData[0].category = this.playerCategoryName[0]?.buttonTitle;
                    this.singlePlayerRank.push(promoData[0]);
                    // break;
                }
            }
        } else {
            if (this.hideButtons) {
                this.getPromo(this.queryPromoId);
            } else {
                this.getPromo(this.buttonParameters[0]);
            }
        }

        this.getDateFormat();
        this.addMatTranslations();
        this.paginator._intl.itemsPerPageLabel = this.item.widgetConfigItem.itemsPerPage || 'Items per page';
    }

    addMatTranslations() {
        const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
            const numberOf = this.item.widgetConfigItem?.of || 'of';
            if (length == 0 || pageSize == 0) {
                return `0 ${numberOf} ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;

            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} ${numberOf} ${length}`;
        };

        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = this.item.widgetConfigItem?.itemsPerPage || 'Items per page:';
        this.paginator._intl.nextPageLabel = this.item.widgetConfigItem?.nextPage || 'Next Page';
        this.paginator._intl.lastPageLabel = this.item.widgetConfigItem?.lastPage || 'Last Page';
        this.paginator._intl.getRangeLabel = dutchRangeLabel;
        this.paginator._intl.previousPageLabel = this.item.widgetConfigItem?.previousPage || 'Previous Page';
        this.paginator._intl.firstPageLabel = this.item.widgetConfigItem?.firstPage || 'First Page';
    }

    getParameterByName(name: any) {
        const url = window.location.href;
        name = name.replace(/[[]]/g, '$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2]);
    }

    viewAllRanks() {
        this.isSinglePlayer = false;
        if (this.hideButtons) {
            this.getPromo(this.queryPromoId);
        } else {
            this.getPromo(this.buttonParameters[0]);
        }
    }

    getPromo(item: any) {
        this.selectedPromo = item;
        this.filterValue = '';
        let promoId;
        item.promoId ? (promoId = item.promoId) : (promoId = item);

        if (this.totalPromosData === undefined || this.totalPromosData.length == 0) {
            this.promoData = this.leaderboardFeed.leaderboardFeedData[promoId];
        } else {
            this.promoData = this.totalPromosData[promoId];
        }

        if (this.promoData === undefined) {
            this.checkPromoData = false;
            this.categoryName = this.selectedPromo.buttonTile;
            this.dataSource = new MatTableDataSource();
            this.paginator.pageIndex = 0;
            this.dataSource.paginator = this.paginator;
            this.selectedRowIndex = -1;
        } else {
            this.checkPromoData = true;
            this.dataSource = new MatTableDataSource(this.promoData.details);
            this.paginator.pageIndex = 0;
            this.dataSource.paginator = this.paginator;
            this.selectedRowIndex = -1;
            if (this.selectedRowInit >= 0 && this.category == item.promoId && this.initialLoadCheck) {
                this.initialLoadCheck = false;
                const getPageNum = Math.floor(this.selectedRowInit / this.pager[0]); // 25 numer is first item pagenator display options
                this.paginator.pageIndex = getPageNum;
                this.selectedRowIndex = this.selectedRowInit;
            } else if (this.selectedRowInit >= 0 && this.category == item.promoId) {
                this.selectedRowIndex = this.selectedRowInit;
            }
        }
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    // refreshPromoData() {
    //     window.location.reload();
    // }

    refreshPromoData() {
        this.refreshing = true;
        const loadingIndicator = this.loadingIndicatorService.start();
        this.promoId = this.getQueryParamIgnoreCase(this.route.snapshot.queryParamMap['params'], 'promoId');
        const contentParameters: any = this.leaderboardFeed.parameters;
        const buttonParameters: any = this.leaderboardFeed.contentParameters;

        if (this.promoId) {
            this.leaderboardService.getLeaderboardFeedByPromoId(this.promoId).subscribe((response) => {
                const leaderboardPosResponse = response;
                this.totalPromosData = leaderboardPosResponse;
                this.getPromo(this.promoId);
                this.getDateFormat();
                loadingIndicator.done();
                this.refreshing = false;
            });
        } else {
            this.leaderboardService
                .getLeaderboardFeedData(JSON.stringify(contentParameters), JSON.stringify(buttonParameters))
                .subscribe((response: any) => {
                    const leaderboardPosResponse = response;
                    this.totalPromosData = leaderboardPosResponse;
                    this.getPromo(this.buttonParameters[0]);
                    this.getDateFormat();
                    loadingIndicator.done();
                    this.refreshing = false;
                });
        }
    }

    getQueryParamIgnoreCase(paramMap: object, key: string) {
        const matchedKey = Object.keys(paramMap).find((k: any) => k.toLowerCase() === key.toLowerCase());
        if (matchedKey) {
            return paramMap[matchedKey];
        }
        return null;
    }

    getDateFormat() {
        this.currentDate = moment().locale(this.page.culture).format('ddd, D/M/YYYY, H:mm:ss');
    }
}
