import { Message } from '../../message';

export class MTCTTourneyStatus extends Message {
    private MESSAGE_NAME: string = 'MTCTTourneyStatus';
    private mtctId: number = 0;
    private noOfPlayers: number = 0;
    private noOfParticipants: number = 0;
    private status: number = 0;
    private nextBreakTime: number = 0;
    private nextLevelValue: number = 0;
    private currentBlindAmt: any | null = null;
    private nextBlindAmt: any | null = null;
    private tourneyCurrentLevel: number = 0;
    private watchersCount: number = 0;
    private lateRegInProgress: boolean | null = false;
    private unregClosed: boolean | null = false;
    private noOfDealMakings: number = 0;
    private currentShootoutRound: number = 0;
    private nextShootoutBreakDuration: number = 0;
    private nextRoundBeginsAt: number = 0;
    private dealMakingOn: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        noOfPlayers: number = 0,
        noOfParticipants: number = 0,
        status: number = 0,
        nextBreakTime: number = 0,
        nextLevelValue: number = 0,
        currentBlindAmt: any | null = null,
        nextBlindAmt: any | null = null,
        tourneyCurrentLevel: number = 0,
        watchersCount: number = 0,
        lateRegInProgress: boolean | null = false,
        unregClosed: boolean | null = false,
        noOfDealMakings: number = 0,
        currentShootoutRound: number = 0,
        nextShootoutBreakDuration: number = 0,
        nextRoundBeginsAt: number = 0,
        dealMakingOn: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.noOfPlayers = noOfPlayers;
        this.noOfParticipants = noOfParticipants;
        this.status = status;
        this.nextBreakTime = nextBreakTime;
        this.nextLevelValue = nextLevelValue;
        this.currentBlindAmt = currentBlindAmt;
        this.nextBlindAmt = nextBlindAmt;
        this.tourneyCurrentLevel = tourneyCurrentLevel;
        this.watchersCount = watchersCount;
        this.lateRegInProgress = lateRegInProgress;
        this.unregClosed = unregClosed;
        this.noOfDealMakings = noOfDealMakings;
        this.currentShootoutRound = currentShootoutRound;
        this.nextShootoutBreakDuration = nextShootoutBreakDuration;
        this.nextRoundBeginsAt = nextRoundBeginsAt;
        this.dealMakingOn = dealMakingOn;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getNoOfPlayers(): number {
        return this.noOfPlayers;
    }

    setNoOfPlayers(noOfPlayers: number) {
        this.noOfPlayers = noOfPlayers;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getNextBreakTime(): number {
        return this.nextBreakTime;
    }

    setNextBreakTime(nextBreakTime: number) {
        this.nextBreakTime = nextBreakTime;
    }
    getNextLevelValue(): number {
        return this.nextLevelValue;
    }

    setNextLevelValue(nextLevelValue: number) {
        this.nextLevelValue = nextLevelValue;
    }
    getCurrentBlindAmt(): any | null {
        return this.currentBlindAmt;
    }

    setCurrentBlindAmt(currentBlindAmt: any | null) {
        this.currentBlindAmt = currentBlindAmt;
    }
    getNextBlindAmt(): any | null {
        return this.nextBlindAmt;
    }

    setNextBlindAmt(nextBlindAmt: any | null) {
        this.nextBlindAmt = nextBlindAmt;
    }
    getTourneyCurrentLevel(): number {
        return this.tourneyCurrentLevel;
    }

    setTourneyCurrentLevel(tourneyCurrentLevel: number) {
        this.tourneyCurrentLevel = tourneyCurrentLevel;
    }
    getWatchersCount(): number {
        return this.watchersCount;
    }

    setWatchersCount(watchersCount: number) {
        this.watchersCount = watchersCount;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
    getNoOfDealMakings(): number {
        return this.noOfDealMakings;
    }

    setNoOfDealMakings(noOfDealMakings: number) {
        this.noOfDealMakings = noOfDealMakings;
    }
    getCurrentShootoutRound(): number {
        return this.currentShootoutRound;
    }

    setCurrentShootoutRound(currentShootoutRound: number) {
        this.currentShootoutRound = currentShootoutRound;
    }
    getNextShootoutBreakDuration(): number {
        return this.nextShootoutBreakDuration;
    }

    setNextShootoutBreakDuration(nextShootoutBreakDuration: number) {
        this.nextShootoutBreakDuration = nextShootoutBreakDuration;
    }
    getNextRoundBeginsAt(): number {
        return this.nextRoundBeginsAt;
    }

    setNextRoundBeginsAt(nextRoundBeginsAt: number) {
        this.nextRoundBeginsAt = nextRoundBeginsAt;
    }
    getDealMakingOn(): boolean | null {
        return this.dealMakingOn;
    }

    setDealMakingOn(dealMakingOn: boolean | null) {
        this.dealMakingOn = dealMakingOn;
    }
}
