import { Message } from '../../message';

export class JackpotAmounts extends Message {
    private MESSAGE_NAME: string = 'JackpotAmounts';
    private jackpotAmounts: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, jackpotAmounts: any[] | null = null) {
        super(reqServerPeerId);
        this.jackpotAmounts = jackpotAmounts;
    }

    getJackpotAmounts(): any[] | null {
        return this.jackpotAmounts;
    }

    setJackpotAmounts(jackpotAmounts: any[] | null) {
        this.jackpotAmounts = jackpotAmounts;
    }
}
