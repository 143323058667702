<div class="poker-modal">
    <div class="modal-cust" id="{{ item.name }}" tabindex="-1">
        <div class="">
            <div class="modal-main" [ngClass]="modalSize">
                <div class="modal-head">
                    <h5 class="modal-title text-center">{{ item.title }}</h5>
                    <button
                        type="button"
                        class="btn btn-modal-close btn-link theme-ex"
                        (click)="closeModal()"
                        data-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-content-in p-3">
                    <ng-container *ngFor="let child of item.items">
                        <vn-page-matrix [content]="child"></vn-page-matrix>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
