import { Message } from '../../message';

export class CanSitOutResponse extends Message {
    private MESSAGE_NAME: string = 'CanSitOutResponse';
    private responseCode: number = 0;
    private errorMessage: any | null = null;
    private athBlockTime: number = 0;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        responseCode: number = 0,
        errorMessage: any | null = null,
        athBlockTime: number = 0,
        requestType: number = 0,
    ) {
        super(reqServerPeerId);
        this.responseCode = responseCode;
        this.errorMessage = errorMessage;
        this.athBlockTime = athBlockTime;
        this.requestType = requestType;
    }

    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getErrorMessage(): any | null {
        return this.errorMessage;
    }

    setErrorMessage(errorMessage: any | null) {
        this.errorMessage = errorMessage;
    }
    getAthBlockTime(): number {
        return this.athBlockTime;
    }

    setAthBlockTime(athBlockTime: number) {
        this.athBlockTime = athBlockTime;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
