import { Message } from '../pgConnectors/server/common/message';
import { IBaseConnector } from './interfaces/IbaseConnector';
import { IPGConnector } from './interfaces/IpgConnector';

export class BaseConnector implements IBaseConnector {
    private connector: IPGConnector;

    constructor(connector: IPGConnector) {
        this.connector = connector;
    }

    closeAllConnections(): void {
        this.connector.closeAllConnections();
    }
    resumeAllConnections(): void {
        this.connector.resumeAllConnections();
    }
    closeConnection(domainId: number): void {
        this.connector.closeConnection(domainId);
    }
    sendClientLogout(): void {
        this.connector.sendClientLogout();
    }
    sendMessageOnDomain(msg: Message, domainId: number): void {
        this.connector.sendMessageOnDomain(msg, domainId);
    }
}
