import { Message } from '../../message';

export class LSDealerBlindPositions extends Message {
    private MESSAGE_NAME: string = 'LSDealerBlindPositions';
    private dealerPosition: number = 0;
    private smallBlindPosition: number = 0;
    private bigBlindPosition: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, dealerPosition: number = 0, smallBlindPosition: number = 0, bigBlindPosition: number = 0) {
        super(reqServerPeerId);
        this.dealerPosition = dealerPosition;
        this.smallBlindPosition = smallBlindPosition;
        this.bigBlindPosition = bigBlindPosition;
    }

    getDealerPosition(): number {
        return this.dealerPosition;
    }

    setDealerPosition(dealerPosition: number) {
        this.dealerPosition = dealerPosition;
    }
    getSmallBlindPosition(): number {
        return this.smallBlindPosition;
    }

    setSmallBlindPosition(smallBlindPosition: number) {
        this.smallBlindPosition = smallBlindPosition;
    }
    getBigBlindPosition(): number {
        return this.bigBlindPosition;
    }

    setBigBlindPosition(bigBlindPosition: number) {
        this.bigBlindPosition = bigBlindPosition;
    }
}
