import { Message } from '../../message';

export class TournamentRegisteredPlayerInfo extends Message {
    private MESSAGE_NAME: string = 'TournamentRegisteredPlayerInfo';
    private mtctId: number = 0;
    private brandId: string | null = null;
    private rank: number = 0;
    private participantNo: number = 0;
    private tournamentCategory: number = 0;
    private tableId: number = 0;
    private sngJackpotId: number = 0;
    private eliminatedPosition: number = 0;
    private regType: number = 0;
    private qualifiedChips: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        brandId: string | null = null,
        rank: number = 0,
        participantNo: number = 0,
        tournamentCategory: number = 0,
        tableId: number = 0,
        sngJackpotId: number = 0,
        eliminatedPosition: number = 0,
        regType: number = 0,
        qualifiedChips: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.brandId = brandId;
        this.rank = rank;
        this.participantNo = participantNo;
        this.tournamentCategory = tournamentCategory;
        this.tableId = tableId;
        this.sngJackpotId = sngJackpotId;
        this.eliminatedPosition = eliminatedPosition;
        this.regType = regType;
        this.qualifiedChips = qualifiedChips;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getBrandId(): string | null {
        return this.brandId;
    }

    setBrandId(brandId: string | null) {
        this.brandId = brandId;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getSngJackpotId(): number {
        return this.sngJackpotId;
    }

    setSngJackpotId(sngJackpotId: number) {
        this.sngJackpotId = sngJackpotId;
    }
    getEliminatedPosition(): number {
        return this.eliminatedPosition;
    }

    setEliminatedPosition(eliminatedPosition: number) {
        this.eliminatedPosition = eliminatedPosition;
    }
    getRegType(): number {
        return this.regType;
    }

    setRegType(regType: number) {
        this.regType = regType;
    }
    getQualifiedChips(): number {
        return this.qualifiedChips;
    }

    setQualifiedChips(qualifiedChips: number) {
        this.qualifiedChips = qualifiedChips;
    }
}
