import { Message } from '../../message';

export class RealityCheckSummaryPopUpAck extends Message {
    private MESSAGE_NAME: string = 'RealityCheckSummaryPopUpAck';
    private clientActionType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clientActionType: number = 0) {
        super(reqServerPeerId);
        this.clientActionType = clientActionType;
    }

    getClientActionType(): number {
        return this.clientActionType;
    }

    setClientActionType(clientActionType: number) {
        this.clientActionType = clientActionType;
    }
}
