const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export class Base64Codec {
    static toBase64(dataToConvert: number[]): string {
        let o1, o2, o3, h1, h2, h3, h4, bits;
        const res: string[] = [];

        if (!dataToConvert) {
            return '';
        }

        let i = 0;
        do {
            o1 = dataToConvert[i++];
            o2 = dataToConvert[i++];
            o3 = dataToConvert[i++];

            bits = (o1 << 16) | (o2 << 8) | o3;

            h1 = (bits >> 18) & 0x3f;
            h2 = (bits >> 12) & 0x3f;
            h3 = (bits >> 6) & 0x3f;
            h4 = bits & 0x3f;

            res.push(b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4));
        } while (i < dataToConvert.length);

        const enc = res.join('');
        const r = dataToConvert.length % 3;

        return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3);
    }

    static fromBase64(dataToConvert: string): number[] {
        let o1, o2, o3, h1, h2, h3, h4, bits;
        const res: number[] = [];

        if (!dataToConvert) {
            return [];
        }

        dataToConvert += '';

        let i = 0;
        do {
            h1 = b64.indexOf(dataToConvert.charAt(i++));
            h2 = b64.indexOf(dataToConvert.charAt(i++));
            h3 = b64.indexOf(dataToConvert.charAt(i++));
            h4 = b64.indexOf(dataToConvert.charAt(i++));

            bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;

            o1 = (bits >> 16) & 0xff;
            o2 = (bits >> 8) & 0xff;
            o3 = bits & 0xff;

            if (h3 === 64) {
                res.push(o1);
            } else if (h4 === 64) {
                res.push(o1);
                res.push(o2);
            } else {
                res.push(o1);
                res.push(o2);
                res.push(o3);
            }
        } while (i < dataToConvert.length);

        return res;
    }
}
