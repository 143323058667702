import { Routes } from '@angular/router';

import { PokerMyGameSynopsisResolver } from './poker-my-game-synopsis.resolver';
import { PokerMyGameResolver } from './poker-my-game.resolver';

export const pokerMyGameRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMyGameResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerMyGameSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMyGameResolver,
        },
    },
];
