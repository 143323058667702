export class CommunityCardsInfoModel {
    cards: any;
    constructor() {
        this.cards = [];
    }
    setCommunityCardsInfo = function (obj) {
        this.communityCardsInfo = obj;
        for (const card of obj.cards) this.cards.push(card);
    };
    getCommunityCardsArray = function () {
        return this.cards;
    };
    getCommunityCardsInfo = function () {
        return this.communityCardsInfo;
    };
    resetDataModel = function () {
        this.communityCardsInfo = null;
        this.cards = [];
    };
}
