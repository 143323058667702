import { Message } from '../../message';
import { UserAvatarInfo } from './UserAvatarInfo';
import { UserPersonalInfo } from './UserPersonalInfo';

export class UserProfile extends Message {
    private MESSAGE_NAME: string = 'UserProfile';
    private loginId: string | null = null;
    private accountName: string | null = null;
    private screenName: string | null = null;
    private webMasterId: number = 0;
    private referer: string | null = null;
    private mailId: string | null = null;
    private firstName: string | null = null;
    private lastName: string | null = null;
    private sex: string | null = null;
    private mailingAddress: string | null = null;
    private bonusCode: string | null = null;
    private IsValidated: boolean | null = false;
    private frontendId: string | null = null;
    private city: string | null = null;
    private canShowPersonalInfo: boolean | null = false;
    private userInfo: UserPersonalInfo | null = null;
    private hearFrom: string | null = null;
    private showCity: boolean | null = false;
    private encProfile: any[] | null = null;
    private channelId: string | null = null;
    private sessionLanguageId: string | null = null;
    private accountLanguageId: string | null = null;
    private accountCurrencyCode: string | null = null;
    private locale: string | null = null;
    private brandId: string | null = null;
    private isPMCEnabled: boolean | null = false;
    private accountStatus: number = 0;
    private isPointsEnabled: boolean | null = false;
    private partyPointsCategory: string | null = null;
    private avatarInfo: UserAvatarInfo | null = null;
    private userTimeZoneId: string | null = null;
    private vipStatus: string | null = null;
    private suspiciousLocationLoginStatus: boolean | null = false;
    private documentValidationStatus: string | null = null;
    private daysPassedAfterRealAccountCreation: number = 0;
    private invID: string | null = null;
    private promoID: string | null = null;
    private playerJurisdiction: string | null = null;
    private kycStatus: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        loginId: string | null = null,
        accountName: string | null = null,
        screenName: string | null = null,
        webMasterId: number = 0,
        referer: string | null = null,
        mailId: string | null = null,
        firstName: string | null = null,
        lastName: string | null = null,
        sex: string | null = null,
        mailingAddress: string | null = null,
        bonusCode: string | null = null,
        IsValidated: boolean | null = false,
        frontendId: string | null = null,
        city: string | null = null,
        canShowPersonalInfo: boolean | null = false,
        userInfo: UserPersonalInfo | null = null,
        hearFrom: string | null = null,
        showCity: boolean | null = false,
        encProfile: any[] | null = null,
        channelId: string | null = null,
        sessionLanguageId: string | null = null,
        accountLanguageId: string | null = null,
        accountCurrencyCode: string | null = null,
        locale: string | null = null,
        brandId: string | null = null,
        isPMCEnabled: boolean | null = false,
        accountStatus: number = 0,
        isPointsEnabled: boolean | null = false,
        partyPointsCategory: string | null = null,
        avatarInfo: UserAvatarInfo | null = null,
        userTimeZoneId: string | null = null,
        vipStatus: string | null = null,
        suspiciousLocationLoginStatus: boolean | null = false,
        documentValidationStatus: string | null = null,
        daysPassedAfterRealAccountCreation: number = 0,
        invID: string | null = null,
        promoID: string | null = null,
        playerJurisdiction: string | null = null,
        kycStatus: string | null = null,
    ) {
        super(reqServerPeerId);
        this.loginId = loginId;
        this.accountName = accountName;
        this.screenName = screenName;
        this.webMasterId = webMasterId;
        this.referer = referer;
        this.mailId = mailId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.sex = sex;
        this.mailingAddress = mailingAddress;
        this.bonusCode = bonusCode;
        this.IsValidated = IsValidated;
        this.frontendId = frontendId;
        this.city = city;
        this.canShowPersonalInfo = canShowPersonalInfo;
        this.userInfo = userInfo;
        this.hearFrom = hearFrom;
        this.showCity = showCity;
        this.encProfile = encProfile;
        this.channelId = channelId;
        this.sessionLanguageId = sessionLanguageId;
        this.accountLanguageId = accountLanguageId;
        this.accountCurrencyCode = accountCurrencyCode;
        this.locale = locale;
        this.brandId = brandId;
        this.isPMCEnabled = isPMCEnabled;
        this.accountStatus = accountStatus;
        this.isPointsEnabled = isPointsEnabled;
        this.partyPointsCategory = partyPointsCategory;
        this.avatarInfo = avatarInfo;
        this.userTimeZoneId = userTimeZoneId;
        this.vipStatus = vipStatus;
        this.suspiciousLocationLoginStatus = suspiciousLocationLoginStatus;
        this.documentValidationStatus = documentValidationStatus;
        this.daysPassedAfterRealAccountCreation = daysPassedAfterRealAccountCreation;
        this.invID = invID;
        this.promoID = promoID;
        this.playerJurisdiction = playerJurisdiction;
        this.kycStatus = kycStatus;
    }

    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getWebMasterId(): number {
        return this.webMasterId;
    }

    setWebMasterId(webMasterId: number) {
        this.webMasterId = webMasterId;
    }
    getReferer(): string | null {
        return this.referer;
    }

    setReferer(referer: string | null) {
        this.referer = referer;
    }
    getMailId(): string | null {
        return this.mailId;
    }

    setMailId(mailId: string | null) {
        this.mailId = mailId;
    }
    getFirstName(): string | null {
        return this.firstName;
    }

    setFirstName(firstName: string | null) {
        this.firstName = firstName;
    }
    getLastName(): string | null {
        return this.lastName;
    }

    setLastName(lastName: string | null) {
        this.lastName = lastName;
    }
    getSex(): string | null {
        return this.sex;
    }

    setSex(sex: string | null) {
        this.sex = sex;
    }
    getMailingAddress(): string | null {
        return this.mailingAddress;
    }

    setMailingAddress(mailingAddress: string | null) {
        this.mailingAddress = mailingAddress;
    }
    getBonusCode(): string | null {
        return this.bonusCode;
    }

    setBonusCode(bonusCode: string | null) {
        this.bonusCode = bonusCode;
    }
    getIsValidated(): boolean | null {
        return this.IsValidated;
    }

    setIsValidated(IsValidated: boolean | null) {
        this.IsValidated = IsValidated;
    }
    getFrontendId(): string | null {
        return this.frontendId;
    }

    setFrontendId(frontendId: string | null) {
        this.frontendId = frontendId;
    }
    getCity(): string | null {
        return this.city;
    }

    setCity(city: string | null) {
        this.city = city;
    }
    getCanShowPersonalInfo(): boolean | null {
        return this.canShowPersonalInfo;
    }

    setCanShowPersonalInfo(canShowPersonalInfo: boolean | null) {
        this.canShowPersonalInfo = canShowPersonalInfo;
    }
    getUserInfo(): UserPersonalInfo | null {
        return this.userInfo;
    }

    setUserInfo(userInfo: UserPersonalInfo | null) {
        this.userInfo = userInfo;
    }
    getHearFrom(): string | null {
        return this.hearFrom;
    }

    setHearFrom(hearFrom: string | null) {
        this.hearFrom = hearFrom;
    }
    getShowCity(): boolean | null {
        return this.showCity;
    }

    setShowCity(showCity: boolean | null) {
        this.showCity = showCity;
    }
    getEncProfile(): any[] | null {
        return this.encProfile;
    }

    setEncProfile(encProfile: any[] | null) {
        this.encProfile = encProfile;
    }
    getChannelId(): string | null {
        return this.channelId;
    }

    setChannelId(channelId: string | null) {
        this.channelId = channelId;
    }
    getSessionLanguageId(): string | null {
        return this.sessionLanguageId;
    }

    setSessionLanguageId(sessionLanguageId: string | null) {
        this.sessionLanguageId = sessionLanguageId;
    }
    getAccountLanguageId(): string | null {
        return this.accountLanguageId;
    }

    setAccountLanguageId(accountLanguageId: string | null) {
        this.accountLanguageId = accountLanguageId;
    }
    getAccountCurrencyCode(): string | null {
        return this.accountCurrencyCode;
    }

    setAccountCurrencyCode(accountCurrencyCode: string | null) {
        this.accountCurrencyCode = accountCurrencyCode;
    }
    getLocale(): string | null {
        return this.locale;
    }

    setLocale(locale: string | null) {
        this.locale = locale;
    }
    getBrandId(): string | null {
        return this.brandId;
    }

    setBrandId(brandId: string | null) {
        this.brandId = brandId;
    }
    getIsPMCEnabled(): boolean | null {
        return this.isPMCEnabled;
    }

    setIsPMCEnabled(isPMCEnabled: boolean | null) {
        this.isPMCEnabled = isPMCEnabled;
    }
    getAccountStatus(): number {
        return this.accountStatus;
    }

    setAccountStatus(accountStatus: number) {
        this.accountStatus = accountStatus;
    }
    getIsPointsEnabled(): boolean | null {
        return this.isPointsEnabled;
    }

    setIsPointsEnabled(isPointsEnabled: boolean | null) {
        this.isPointsEnabled = isPointsEnabled;
    }
    getPartyPointsCategory(): string | null {
        return this.partyPointsCategory;
    }

    setPartyPointsCategory(partyPointsCategory: string | null) {
        this.partyPointsCategory = partyPointsCategory;
    }
    getAvatarInfo(): UserAvatarInfo | null {
        return this.avatarInfo;
    }

    setAvatarInfo(avatarInfo: UserAvatarInfo | null) {
        this.avatarInfo = avatarInfo;
    }
    getUserTimeZoneId(): string | null {
        return this.userTimeZoneId;
    }

    setUserTimeZoneId(userTimeZoneId: string | null) {
        this.userTimeZoneId = userTimeZoneId;
    }
    getVipStatus(): string | null {
        return this.vipStatus;
    }

    setVipStatus(vipStatus: string | null) {
        this.vipStatus = vipStatus;
    }
    getSuspiciousLocationLoginStatus(): boolean | null {
        return this.suspiciousLocationLoginStatus;
    }

    setSuspiciousLocationLoginStatus(suspiciousLocationLoginStatus: boolean | null) {
        this.suspiciousLocationLoginStatus = suspiciousLocationLoginStatus;
    }
    getDocumentValidationStatus(): string | null {
        return this.documentValidationStatus;
    }

    setDocumentValidationStatus(documentValidationStatus: string | null) {
        this.documentValidationStatus = documentValidationStatus;
    }
    getDaysPassedAfterRealAccountCreation(): number {
        return this.daysPassedAfterRealAccountCreation;
    }

    setDaysPassedAfterRealAccountCreation(daysPassedAfterRealAccountCreation: number) {
        this.daysPassedAfterRealAccountCreation = daysPassedAfterRealAccountCreation;
    }
    getInvID(): string | null {
        return this.invID;
    }

    setInvID(invID: string | null) {
        this.invID = invID;
    }
    getPromoID(): string | null {
        return this.promoID;
    }

    setPromoID(promoID: string | null) {
        this.promoID = promoID;
    }
    getPlayerJurisdiction(): string | null {
        return this.playerJurisdiction;
    }

    setPlayerJurisdiction(playerJurisdiction: string | null) {
        this.playerJurisdiction = playerJurisdiction;
    }
    getKycStatus(): string | null {
        return this.kycStatus;
    }

    setKycStatus(kycStatus: string | null) {
        this.kycStatus = kycStatus;
    }
}
