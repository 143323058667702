import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    BottomNavService,
    DynamicHtmlDirective,
    MenuActionsService,
    NavigationService,
    PlainLinkComponent,
    TrackingService,
    UserService,
} from '@frontend/vanilla/core';
import { DslPipe, TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { PokerApiService } from './api.service';
import { NavigationClientConfig } from './client-config.models';
import { NavigationBarResourceService } from './navigation-bar.resource.service';

@Component({
    selector: 'pk-navigation-bar',
    templateUrl: 'navigation-bar.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, TrustAsHtmlPipe, DslPipe, PlainLinkComponent, DynamicHtmlDirective],
    providers: [NavigationBarResourceService],
})
export class NavigationBarComponent implements OnInit {
    activeMenuItemUrl: string;
    navItems: any;
    isMobile: boolean;
    anchorClass: any;
    constructor(
        public api: PokerApiService,
        private navigationConfig: NavigationClientConfig,
        private navigation: NavigationService,
        private renderer: Renderer2,
        private eRef: ElementRef,
        private menuActionsService: MenuActionsService,
        private bottomNavService: BottomNavService,
        private userService: UserService,
        private vanillaTrackingService: TrackingService,
        // private navigationBootstrapService: NavigationBootstrapService
        // private deviceService: DeviceService
    ) {
        this.menuActionsService.register('openPokerNavigation', () => {
            document.body.classList.add('showMenu');
            const cls = document.querySelector('.nav-item .close-btn');
            const pkNav = document.querySelector('pk-navigation-bar');
            if (cls != null) {
                pkNav?.appendChild(cls);
            }
        });
        this.navigation.locationChange.subscribe(() => {
            this.renderer.removeClass(document.body, 'showMenu');
        });
    }

    @HostListener('document:click', ['$event']) clickOut(event: any) {
        if (!this.eRef.nativeElement.contains(event.target) && !event.target.classList.contains('testtoggler')) {
            this.renderer.removeClass(document.body, 'showMenu');
        }
    }

    ngOnInit(): void {
        this.navItems = this.navigationConfig.items;
        this.isMobile = false;

        this.bottomNavService.whenReady.subscribe(() => {
            this.bottomNavService.isEnabled().subscribe((response: boolean) => {
                if (response == true) {
                    this.isMobile = true;
                    this.closeMenuTrigger();
                }
            });
        });
    }

    getNavItemsClass(isMobile: boolean, item: any) {
        if (isMobile && item.class) {
            if (item.class) {
                return 'nav-link ' + item.class;
            } else {
                return 'nav-link';
            }
        } else {
            if (item.parameters.desktopClass) {
                return 'nav-link ' + item.parameters.desktopClass;
            } else {
                return 'nav-link';
            }
        }
    }

    getChildrenClass(isMobile: boolean, item: any) {
        if (isMobile && item.class) {
            if (item.class) {
                return 'dropdown-item ' + item.class;
            } else {
                return 'dropdown-item';
            }
        } else {
            if (item.parameters.desktopClass) {
                return 'dropdown-item ' + item.parameters.desktopClass;
            } else {
                return 'dropdown-item';
            }
        }
    }

    closeMenuTrigger() {
        const closetoggler: HTMLElement | null = document.querySelector('.close-menu-trigger');
        if (closetoggler) {
            closetoggler.onclick = function () {
                document.body.classList.remove('showMenu');
            };
        }
    }

    // toggleMenu(activeIndex: any) {
    //     if (this.isMobile) {
    //         for (var i = 0; i < this.navItems.length; i++) {
    //             if (i !== activeIndex) { this.navItems[i].active = false; }
    //         }
    //         this.navItems[activeIndex].active = !this.navItems[activeIndex].active;
    //     }
    // }

    toggleMenuOnClick(event: any) {
        if (this.isMobile) {
            if (event.srcElement.parentNode.parentNode.id == 'productMenu' && event.srcElement.parentNode.className) {
                if (event.srcElement.parentNode.classList.contains('dropdown') && event.srcElement.parentNode.classList.contains('nav-item')) {
                    const isMenuItemOpen = event.srcElement.parentNode.classList.contains('open');
                    if (isMenuItemOpen) {
                        event.srcElement.parentNode.classList.remove('open');
                    } else {
                        event.srcElement.parentNode.classList.add('open');
                        //and close the other already open DD menu
                        const mobileMenulinks = document.getElementsByClassName('dropdown nav-item');
                        for (let i = 0; i < mobileMenulinks.length; i++) {
                            if (mobileMenulinks[i] !== event.srcElement.parentNode) {
                                mobileMenulinks[i].classList.remove('open');
                            }
                        }
                    }
                }
            }
        }
    }

    removeBodyClass(event: any, url: string) {
        if (!url || url == 'null' || url == 'undefined') {
            event.preventDefault();
        }
        this.renderer.removeClass(document.body, 'showMenu');
    }

    /* dropDownMobileHandler(event:any){
         if(this.isMobile){
             let pElement = event.currentTarget.parentElement;
             document.querySelectorAll('.openMobileMenu').forEach(element: any) => {
                     pElement.classList.remove('.openMobileMenu')
             }
             pElement.classList.add('.openMobileMenu');
         }else{
             event.preventDefault();
         }
     }*/

    menuItemClicked(event: any, itemUrl: string) {
        this.activeMenuItemUrl = itemUrl;
        event.preventDefault();
        this.navigation.goTo(itemUrl);
    }

    pkNavBarTracking(urlClicked: string) {
        if (urlClicked.toLocaleLowerCase().indexOf('download') > -1) {
            this.vanillaTrackingService.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'download',
                'component.LabelEvent': 'poker app',
                'component.ActionEvent': 'click',
                'component.PositionEvent': 'header',
                'component.LocationEvent': this.userService.isAuthenticated ? 'post login' : 'pre login',
                'component.EventDetails': 'download button',
                'component.URLClicked': urlClicked,
            });
        }
    }
}
