<div class="heading-strip">
    <i class="back-arrow icon-arrow-left" (click)="closeFilters()"></i>
    <h2>{{ serve.content.messages.Filters }}</h2>
    <a class="reset-filters" (click)="resetFilters()">{{ serve.content.messages.Reset }}</a>
</div>
<div class="hh-filters-wrapper">
    <div class="hh-filters-block">
        <ul>
            <li class="game_type active" [class.disable]="serve.singleHandStatus" id="cashgames" (click)="toggleHandHistoryType('cashgames', $event)">
                {{ serve.content.messages.CashGames }}
            </li>
            <li class="game_type" [class.disable]="serve.singleHandStatus" id="tournaments" (click)="toggleHandHistoryType('tournaments', $event)">
                {{ serve.content.messages.Tournaments }}
            </li>
        </ul>
    </div>
    <div class="hh-filters-menu" *ngIf="serve.content">
        <ul *ngIf="serve.hhGames === 'cashgames'" id="cashgames">
            <li>
                <i
                    class="theme-search search-icon"
                    [attr.disabled]="roundId.length >= 11 ? ' ' : 'disabled'"
                    [ngClass]="roundId.length >= 11 ? 'itIsValid' : 'itIsNotValid'"
                    (click)="searchWithId()"></i>
                <input
                    type="text"
                    id="roundIdCG"
                    [attr.placeholder]="serve.content.messages.SearchHandID"
                    [(ngModel)]="roundId"
                    value="roundId"
                    (keypress)="checkForNumbers($event)"
                    maxLength="11"
                    (focus)="focusOnRoundId($event)"
                    (blur)="blurOnRoundId($event)" />
                <span class="clear-roundid" *ngIf="roundId.length > 0" (click)="clearRoundId()"></span>
            </li>
            <li filter-type="GameTypeCG">
                <a (click)="showDropDown($event)">
                    {{ GameTypeTextCG }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('GameTypeCG')"></i>
                        <h3>{{ serve.content.messages.GameType }}</h3>
                        <a class="reset-filter" (click)="resetFilter('GameTypeCG')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul (click)="selectOption($event)" class="filters-dd" id="GameTypeCG" [attr.selected-value]="GameTypeCG">
                        <li
                            *ngFor="let gameType of serve.content.form.gametypes.values; let i = index"
                            [attr.value]="i"
                            [class.active]="GameTypeCG === i">
                            {{ gameType.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="LimitTypeCG">
                <a (click)="showDropDown($event)">
                    {{ LimitTypeTextCG }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('LimitTypeCG')"></i>
                        <h3>{{ serve.content.messages.LimitType }}</h3>
                        <a class="reset-filter" (click)="resetFilter('LimitTypeCG')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul
                        (click)="selectOption($event)"
                        *ngIf="serve.allCgLimits"
                        class="filters-dd"
                        id="LimitTypeCG"
                        [attr.selected-value]="LimitTypeCG">
                        <li
                            *ngFor="let limit of serve.allCgLimits; let i = index"
                            [attr.value]="limitTypeValues[limit.value]"
                            [class.active]="LimitTypeCG === limitTypeValues[limit.value]">
                            {{ limit.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="SearchDateCG">
                <a (click)="showDropDown($event)">
                    {{ SearchDateTextCG }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('SearchDateCG')"></i>
                        <h3>{{ serve.content.messages.SearchDate }}</h3>
                        <a class="reset-filter" (click)="resetFilter('SearchDateCG')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul (click)="selectOption($event)" class="filters-dd duration" id="SearchDateCG" [attr.selected-value]="SearchDateCG">
                        <li
                            *ngFor="let searchDate of serve.content.form.searchdates.values"
                            [attr.value]="searchDate.value"
                            [class.active]="SearchDateCG === searchDate.value">
                            {{ searchDate.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="ActionsCG">
                <a (click)="showDropDown($event)">
                    {{ ActionText }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('ActionsCG')"></i>
                        <h3>{{ serve.content.messages.AllActions }}</h3>
                        <a class="reset-filter" (click)="resetActions('ActionsCG')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul *ngIf="serve.allCgActions" class="filters-dd all-actions">
                        <li *ngFor="let action of serve.allCgActions; let i = index">
                            <label
                                ><input
                                    type="checkbox"
                                    [id]="action.value"
                                    [attr.checked]="action.status === true ? 'checked' : null"
                                    (click)="toggleActionsCheckbox(i, 'cashgames')" />
                                <span class="theme-check">{{ action.text }}</span></label
                            >
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="StakeTypeCG">
                <a (click)="showDropDown($event)">
                    {{ StakeTypeTextCG }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('StakeTypeCG')"></i>
                        <h3 *ngIf="GameTypeCG !== 2">{{ serve.content.messages.StakesBlinds }}</h3>
                        <h3 *ngIf="GameTypeCG === 2">{{ serve.content.messages.Antes }}</h3>
                        <a class="reset-filter" (click)="resetFilter('StakeTypeCG')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul
                        (click)="selectOption($event)"
                        *ngIf="GameTypeCG !== 2"
                        class="filters-dd"
                        id="StakeTypeCG"
                        [attr.selected-value]="StakeTypeCG">
                        <li
                            *ngFor="let stakeType of serve.content.form.staketypes.values; let i = index"
                            [attr.value]="i"
                            [class.active]="StakeTypeCG === i">
                            {{ stakeType.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                    <ul
                        (click)="selectOption($event)"
                        *ngIf="GameTypeCG === 2"
                        class="filters-dd"
                        id="StakeTypeCG"
                        [attr.selected-value]="StakeTypeCG">
                        <li
                            *ngFor="let anteType of serve.content.form.antetypes.values; let i = index"
                            [attr.value]="i"
                            [class.active]="StakeTypeCG === i">
                            {{ anteType.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <a class="pot-size-text port-hand"
                    >{{ PotRangeText
                    }}<span class="pot-size-range-value"
                        ><span *ngIf="potrangeCG === 0">{{ serve.content.messages.Any }}</span
                        ><span *ngIf="potrangeCG > 0">{{ potrangeCG }}</span></span
                    ></a
                >
                <div class="hh-filters-sub-menu">
                    <div class="pot-size-range">
                        <input
                            type="range"
                            min="0"
                            max="16"
                            value="{{ potrangeValueCG }}"
                            class="pot-range"
                            (keyup)="setPotRange()"
                            (touchend)="setPotRange()"
                            (mouseup)="setPotRange()"
                            [(ngModel)]="potrangeValueCG" />
                    </div>
                </div>
            </li>
            <li filter-type="finalHandCG">
                <a class="port-hand" (click)="showHandsFilter('', 'finalHandCG')"
                    >{{ FinalHandText }}
                    <span class="fh-filter"
                        >(<span
                            ><span *ngIf="FinalSelectedTextCG.length > 0">{{ FinalSelectedTextCG }} : </span> {{ FinalHandTextCG }}</span
                        >)</span
                    ><i class="theme-more-dots"></i
                ></a>
                <div class="hh-filters-sub-menu">
                    <div class="filters-popup-block">
                        <div class="filter-heading">
                            <i class="back-arrow icon-arrow-left" (click)="closePopup('finalHandCG')"></i>
                            <h3>{{ serve.content.messages.FinalHandCard }}</h3>
                            <a class="reset-filter" (click)="resetAllFilters('finalHandCG')">{{ serve.content.messages.Reset }}</a>
                        </div>
                        <ul class="final-hand" (click)="selectOption($event)" class="filters-dd" id="FinalHandCG" [attr.selected-value]="FinalHandCG">
                            <ng-container *ngIf="GameTypeCG !== 2">
                                <li
                                    *ngFor="let finalHand of serve.content.form.finalhand.values; let i = index"
                                    [attr.value]="i"
                                    [class.active]="FinalHandCG === i">
                                    {{ finalHand.text }}<i class="theme-check"></i>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="GameTypeCG === 2">
                                <li
                                    *ngFor="let finalHand of serve.content.form.shortdeckfinalhand.values; let i = index"
                                    [attr.value]="i"
                                    [class.active]="FinalHandCG === i">
                                    {{ finalHand.text }}<i class="theme-check"></i>
                                </li>
                            </ng-container>
                        </ul>
                        <h3 class="filter-sub-heading">{{ finalHandMatchText }}</h3>
                        <ul class="filters-dd finalHandMatch">
                            <li *ngFor="let finalMatchChoice of finalHandMatch; let i = index">
                                <label class="radio-buttons">
                                    {{ finalMatchChoice.text
                                    }}<input
                                        type="radio"
                                        [(ngModel)]="finalMatchChoiceCG"
                                        name="{{ finalMatchChoiceCG }}"
                                        name="final-hand"
                                        [value]="i"
                                        (change)="FinalSelectedTextCG = finalMatchChoice.text; applyFilter()"
                                        [attr.disabled]="toggleFinalMatchCG ? 'disabled' : null"
                                /></label>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li filter-type="pocketHandCG">
                <a class="port-hand">{{ PocketHandText }}</a>
                <div class="hh-filters-sub-menu hh-filters-menu-cards">
                    <div class="filters-popup-block hand-card-popup">
                        <div class="filter-heading">
                            <i class="back-arrow icon-arrow-left" (click)="closePopup('pocketHandCG')"></i>
                            <h3 class="card-one">{{ serve.content.messages.PocketHandCardOne }}</h3>
                            <h3 class="card-two">{{ serve.content.messages.PocketHandCardTwo }}</h3>
                            <a class="reset-filter card-one" (click)="resetFilter('pocketHandCG', 'card-one')">{{ serve.content.messages.Reset }}</a>
                            <a class="reset-filter card-two" (click)="resetFilter('pocketHandCG', 'card-two')">{{ serve.content.messages.Reset }}</a>
                        </div>
                        <ul
                            class="set-one card-one pocket-hand"
                            (click)="selectOption($event)"
                            id="PocketCardOneCG"
                            [attr.selected-value]="PocketCardOneCG">
                            <ng-container *ngIf="GameTypeCG !== 2">
                                <li
                                    *ngFor="let pocketHand of serve.content.form.pockethand.values"
                                    [attr.value]="pocketHand.value"
                                    [attr.value-card]="pocketHand.text"
                                    [class.active]="PocketCardOneCG === pocketHand.value">
                                    {{ pocketHand.text }}
                                </li>
                            </ng-container>
                            <ng-container *ngIf="GameTypeCG === 2">
                                <li
                                    *ngFor="let pocketHand of serve.content.form.shortdeckpockethand.values"
                                    [attr.value]="pocketHand.value"
                                    [attr.value-card]="pocketHand.text"
                                    [class.active]="PocketCardOneCG === pocketHand.value">
                                    {{ pocketHand.text }}
                                </li>
                            </ng-container>
                        </ul>
                        <ul
                            class="set-two card-two pocket-hand"
                            (click)="selectOption($event)"
                            id="PocketCardTwoCG"
                            [attr.selected-value]="PocketCardTwoCG">
                            <ng-container *ngIf="GameTypeCG !== 2">
                                <li
                                    *ngFor="let pocketHand of serve.content.form.pockethand.values"
                                    [attr.value]="pocketHand.value"
                                    [attr.value-card]="pocketHand.text"
                                    [class.active]="PocketCardTwoCG === pocketHand.value">
                                    {{ pocketHand.text }}
                                </li>
                            </ng-container>
                            <ng-container *ngIf="GameTypeCG === 2">
                                <li
                                    *ngFor="let pocketHand of serve.content.form.shortdeckpockethand.values"
                                    [attr.value]="pocketHand.value"
                                    [attr.value-card]="pocketHand.text"
                                    [class.active]="PocketCardTwoCG === pocketHand.value">
                                    {{ pocketHand.text }}
                                </li>
                            </ng-container>
                        </ul>
                        <label for="pocketSuitedCG" class="radio-buttons"
                            ><input
                                type="checkbox"
                                id="pocketSuitedCG"
                                name="pocket-card-suited"
                                [attr.disabled]="pocketSuitCG ? 'disabled' : null"
                                (change)="applySuited('cashgames')" />
                            {{ SuitedOnlyText }}</label
                        >
                    </div>
                    <ul class="pocket-hand hand-set">
                        <li (click)="showHandsFilter(0, 'pocketHandCG')">
                            <span [attr.initial-value]="PCardOneCG ? '-' : '+'" [attr.value-card]="PocketCardOneCG">{{ PocketCardOneTextCG }}</span>
                        </li>
                        <li (click)="showHandsFilter(1, 'pocketHandCG')">
                            <span [attr.initial-value]="PCardTwoCG ? '-' : '+'" [attr.value-card]="PocketCardTwoCG">{{ PocketCardTwoTextCG }}</span>
                        </li>
                    </ul>
                    <span *ngIf="getSuited('cashgames')" class="suited-text">{{ SuitedText }}</span>
                </div>
            </li>
        </ul>
        <ul *ngIf="serve.hhGames === 'tournaments'" id="tournaments">
            <li>
                <i
                    class="theme-search search-icon"
                    [attr.disabled]="roundId.length >= 11 ? ' ' : 'disabled'"
                    [ngClass]="roundId.length >= 11 ? 'itIsValid' : 'itIsNotValid'"
                    (click)="searchWithId()"></i>
                <input
                    type="text"
                    id="roundIdTR"
                    [attr.placeholder]="serve.content.messages.SearchHandID"
                    [(ngModel)]="roundId"
                    value="roundId"
                    (keypress)="checkForNumbers($event)"
                    maxLength="11"
                    (focus)="focusOnRoundId($event)"
                    (blur)="blurOnRoundId($event)" />
                <span class="clear-roundid" *ngIf="roundId.length > 0" (click)="clearRoundId()"></span>
            </li>
            <li filter-type="TournamentTypeTR">
                <a (click)="showDropDown($event)">
                    {{ TournamentTypeTextTR }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('TournamentTypeTR')"></i>
                        <h3>{{ serve.content.messages.TournamentType }}</h3>
                        <a class="reset-filter" (click)="resetFilter('TournamentTypeTR')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul (click)="selectOption($event)" class="filters-dd" id="TournamentTypeTR" [attr.selected-value]="TournamentTypeTR">
                        <li
                            *ngFor="let tournamentType of serve.content.form.tournamenttypes.values; let i = index"
                            [attr.value]="i"
                            [class.active]="TournamentTypeTR === i">
                            {{ tournamentType.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="GameTypeTR">
                <a (click)="showDropDown($event)">
                    {{ GameTypeTextTR }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('GameTypeTR')"></i>
                        <h3>{{ serve.content.messages.GameType }}</h3>
                        <a class="reset-filter" (click)="resetFilter('GameTypeTR')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul (click)="selectOption($event)" class="filters-dd" id="GameTypeTR" [attr.selected-value]="GameTypeTR">
                        <li
                            *ngFor="let tournamentGameType of serve.content.form.tournamentgametypes.values; let i = index"
                            [attr.value]="i"
                            [class.active]="GameTypeTR === i"
                            [attr.gameType]="tournamentGameType.value">
                            {{ tournamentGameType.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="BuyInTypeTR">
                <a (click)="showDropDown($event)">
                    {{ BuyInTypeTextTR }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('BuyInTypeTR')"></i>
                        <h3>{{ serve.content.messages.AllBuyins }}</h3>
                        <a class="reset-filter" (click)="resetFilter('BuyInTypeTR')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul (click)="selectOption($event)" class="filters-dd" id="BuyInTypeTR" [attr.selected-value]="BuyInTypeTR">
                        <li
                            *ngFor="let buyInType of serve.content.form.buyintypes.values; let i = index"
                            [attr.value]="i"
                            [class.active]="BuyInTypeTR === i">
                            {{ buyInType.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="SearchDateTR">
                <a (click)="showDropDown($event)">
                    {{ SearchDateTextTR }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('SearchDateTR')"></i>
                        <h3>{{ serve.content.messages.SearchDate }}</h3>
                        <a class="reset-filter" (click)="resetFilter('SearchDateTR')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul (click)="selectOption($event)" class="filters-dd duration" id="SearchDateTR" [attr.selected-value]="SearchDateTR">
                        <li
                            *ngFor="let searchDate of serve.content.form.searchdates.values"
                            [attr.value]="searchDate.value"
                            [class.active]="SearchDateTR === searchDate.value">
                            {{ searchDate.text }}<i class="theme-check"></i>
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="ActionsTR">
                <a (click)="showDropDown($event)">
                    {{ ActionText }}
                    <i class="theme-more-dots"></i>
                </a>
                <div class="filters-popup-block">
                    <div class="filter-heading">
                        <i class="back-arrow icon-arrow-left" (click)="closePopup('ActionsTR')"></i>
                        <h3>{{ serve.content.messages.AllActions }}</h3>
                        <a class="reset-filter" (click)="resetActions('ActionsTR')">{{ serve.content.messages.Reset }}</a>
                    </div>
                    <ul *ngIf="serve.allTrActions" class="filters-dd all-actions">
                        <li *ngFor="let action of serve.allTrActions; let i = index">
                            <label
                                ><input
                                    type="checkbox"
                                    [id]="action.value"
                                    [attr.checked]="action.status === true ? 'checked' : null"
                                    (click)="toggleActionsCheckbox(i, 'tournaments')" />
                                <span class="theme-check">{{ action.text }}</span></label
                            >
                        </li>
                    </ul>
                </div>
            </li>
            <li filter-type="finalHandTR">
                <a class="port-hand" (click)="showHandsFilter('', 'finalHandTR')"
                    >{{ FinalHandText }}
                    <span class="fh-filter"
                        >(<span
                            ><span *ngIf="FinalSelectedTextTR.length > 0">{{ FinalSelectedTextTR }} : </span> {{ FinalHandTextTR }}</span
                        >)</span
                    ><i class="theme-more-dots"></i
                ></a>
                <div class="hh-filters-sub-menu">
                    <div class="filters-popup-block hand-card-popup">
                        <div class="filter-heading">
                            <i class="back-arrow icon-arrow-left" (click)="closePopup('finalHandTR')"></i>
                            <h3>{{ serve.content.messages.FinalHandCard }}</h3>
                            <a class="reset-filter" (click)="resetAllFilters('finalHandTR')">{{ serve.content.messages.Reset }}</a>
                        </div>
                        <ul class="final-hand" (click)="selectOption($event)" class="filters-dd" id="FinalHandTR" [attr.selected-value]="FinalHandTR">
                            <li
                                *ngFor="let finalHand of serve.content.form.finalhand.values; let i = index"
                                [attr.value]="i"
                                [class.active]="FinalHandTR === i">
                                {{ finalHand.text }}<i class="theme-check"></i>
                            </li>
                        </ul>
                        <h3 class="filter-sub-heading">{{ finalHandMatchText }}</h3>
                        <ul class="filters-dd finalHandMatch">
                            <li *ngFor="let finalMatchChoice of finalHandMatch; let i = index">
                                <label class="radio-buttons">
                                    {{ finalMatchChoice.text
                                    }}<input
                                        type="radio"
                                        [(ngModel)]="finalMatchChoiceTR"
                                        name="{{ finalMatchChoiceTR }}"
                                        name="final-hand"
                                        [value]="i"
                                        (change)="FinalSelectedTextTR = finalMatchChoice.text; applyFilter()"
                                        [attr.disabled]="toggleFinalMatchTR ? 'disabled' : null" /><span class="checkmark"></span
                                ></label>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li filter-type="pocketHandTR">
                <a class="port-hand">{{ PocketHandText }}</a>
                <div class="hh-filters-sub-menu hh-filters-menu-cards">
                    <div class="filters-popup-block hand-card-popup">
                        <div class="filter-heading">
                            <i class="back-arrow icon-arrow-left" (click)="closePopup('pocketHandTR')"></i>
                            <h3 class="card-one">{{ serve.content.messages.PocketHandCardOne }}</h3>
                            <h3 class="card-two">{{ serve.content.messages.PocketHandCardTwo }}</h3>
                            <a class="reset-filter card-one" (click)="resetFilter('pocketHandTR', 'card-one')">{{ serve.content.messages.Reset }}</a>
                            <a class="reset-filter card-two" (click)="resetFilter('pocketHandTR', 'card-two')">{{ serve.content.messages.Reset }}</a>
                        </div>
                        <ul
                            class="set-one card-one pocket-hand"
                            (click)="selectOption($event)"
                            id="PocketCardOneTR"
                            [attr.selected-value]="PocketCardOneTR">
                            <li
                                *ngFor="let pocketHand of serve.content.form.pockethand.values"
                                [attr.value]="pocketHand.value"
                                [attr.value-card]="pocketHand.text"
                                [class.active]="PocketCardOneTR === pocketHand.value">
                                {{ pocketHand.text }}
                            </li>
                        </ul>
                        <ul
                            class="set-two card-two pocket-hand"
                            (click)="selectOption($event)"
                            id="PocketCardTwoTR"
                            [attr.selected-value]="PocketCardTwoTR">
                            <li
                                *ngFor="let pocketHand of serve.content.form.pockethand.values"
                                [attr.value]="pocketHand.value"
                                [attr.value-card]="pocketHand.text"
                                [class.active]="PocketCardTwoTR === pocketHand.value">
                                {{ pocketHand.text }}
                            </li>
                        </ul>
                        <label for="pocketSuitedTR" class="radio-buttons"
                            ><input
                                type="checkbox"
                                id="pocketSuitedTR"
                                name="pocket-card-suited"
                                [attr.disabled]="pocketSuitTR ? 'disabled' : null"
                                (change)="applySuited('tournaments')" />
                            {{ SuitedOnlyText }}</label
                        >
                    </div>
                    <ul class="pocket-hand hand-set">
                        <li (click)="showHandsFilter(0, 'pocketHandTR')">
                            <span [attr.initial-value]="PCardOneTR ? '-' : '+'" [attr.value-card]="PocketCardOneTR">{{ PocketCardOneTextTR }}</span>
                        </li>
                        <li (click)="showHandsFilter(1, 'pocketHandTR')">
                            <span [attr.initial-value]="PCardTwoTR ? '-' : '+'" [attr.value-card]="PocketCardTwoTR">{{ PocketCardTwoTextTR }}</span>
                        </li>
                    </ul>
                    <span *ngIf="getSuited('tournaments')" class="suited-text">{{ SuitedText }}</span>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="filter-popup-mask" *ngIf="filterPopupSwitch" (click)="closePopups()"></div>
<div class="filter-popup-mask" *ngIf="loadingMask"></div>
