<pwc-sub-lobby-header
    [isWidgetShow]="null"
    [tablesLength]="finalData && finalData.length"
    [initialGridViewType]="gridView"
    [gameTypes]="gameTypes"
    (updateGameType)="updateGameType($event)"
    (isGridViewType)="isItGridViewType($event)"
    (viewInfoPopup)="openInfoPopup($event)"
    [cardOrderType]="cardOrderBy"
    (sortTable)="sortTables($event)"
    (viewFilterPopup)="openFilterPopup($event)"></pwc-sub-lobby-header>
<div class="sub-lobby-content" [ngClass]="{ 'p-0': !gridView }">
    <div class="lobby-tiles" #gridContainer *ngIf="finalData && finalData.length > 0; else NoTables">
        <ng-container *ngIf="gridView">
            <pwc-cash-lobby-tile
                *ngFor="let card of finalData; let i = index"
                class="lobby-tile card-{{ i }}"
                [tileData]="card"
                [cardIndex]="i"
                (cardSelected)="selectedCard(i, card)"
                [ngClass]="{ active: selectedTableIndex === i }">
            </pwc-cash-lobby-tile>
        </ng-container>
        <ng-container *ngIf="!gridView">
            <div class="swiper-tile-view d-flex align-items-center">
                <div class="w-100">
                    <vn-swiper class="swiper" #SwiperComponent [swiperOptions]="lobbySwaiperConfig">
                        <div class="swiper-slide" *ngFor="let card of finalData; let i = index">
                            <pwc-cash-lobby-tile
                                class="lobby-tile lobby-carousel-tile"
                                [tileData]="card"
                                [cardIndex]="i"
                                (cardSelected)="selectedCard(i, card)"
                                [ngClass]="{ active: selectedTableIndex === i }">
                            </pwc-cash-lobby-tile>
                        </div>
                    </vn-swiper>

                    <div class="swiper-pager">
                        <div class="swiper-pagination-cust"></div>
                        <div class="swiper-buyin-values">
                            <span class="sp-min-value">${{ tileLeastBuyinValue }}</span>
                            <span class="sp-max-value">${{ tileMaxBuyinValue }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #NoTables>
        <p class="no-tables d-flex justify-content-center align-items-center h-100 m-0">
            {{ 'PARTY_POKER_MOBILE_COMMON_NO_TABLE_FOUND' | translate }}
        </p>
    </ng-template>
</div>
<div class="lobby-playNo-btn">
    <button
        class="btn btn-md btn-primary text-capitalize w-100"
        [ngClass]="finalData.length === 0 ? 'invisible' : 'visible'"
        (click)="onClickPlayNowButton()">
        {{ 'PARTY_POKER_GC_QSLOBBY_PLAYNOW' | translate }}
    </button>
</div>

<pwc-cash-ff-buyin
    *ngIf="popupIsOpen"
    (close)="closePopup()"
    [table]="selectedTable"
    [isAutorebuyin]="isAutoreBuyin"
    (tableValues)="onBuyInButtonClick($event)"></pwc-cash-ff-buyin>

<pwc-de-balance-pop-up
    *ngIf="isDePopupEnabled"
    [debalnceObtainedInfo]="deBalanceInfo"
    (closeGermanBalanceInfo)="closeGermanBalanceInfoPopup()"></pwc-de-balance-pop-up>

<pwc-ath-popup
    *ngIf="athalertpopup || athpenalitypopup"
    (close)="closePopup()"
    [athalertpopup]="athalertpopup"
    [athpenalitypopup]="athpenalitypopup"></pwc-ath-popup>
