import { Message } from '../../message';

export class RequestFeatureGamesLobbyUpdate extends Message {
    private MESSAGE_NAME: string = 'RequestFeatureGamesLobbyUpdate';
    private active: boolean | null = false;
    private tabSnapshotTimeInNanosList: any[] | null = null;
    private tableInfoSnapShotTimeInNanos: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        active: boolean | null = false,
        tabSnapshotTimeInNanosList: any[] | null = null,
        tableInfoSnapShotTimeInNanos: number = 0,
    ) {
        super(reqServerPeerId);
        this.active = active;
        this.tabSnapshotTimeInNanosList = tabSnapshotTimeInNanosList;
        this.tableInfoSnapShotTimeInNanos = tableInfoSnapShotTimeInNanos;
    }

    getActive(): boolean | null {
        return this.active;
    }

    setActive(active: boolean | null) {
        this.active = active;
    }
    getTabSnapshotTimeInNanosList(): any[] | null {
        return this.tabSnapshotTimeInNanosList;
    }

    setTabSnapshotTimeInNanosList(tabSnapshotTimeInNanosList: any[] | null) {
        this.tabSnapshotTimeInNanosList = tabSnapshotTimeInNanosList;
    }
    getTableInfoSnapShotTimeInNanos(): number {
        return this.tableInfoSnapShotTimeInNanos;
    }

    setTableInfoSnapShotTimeInNanos(tableInfoSnapShotTimeInNanos: number) {
        this.tableInfoSnapShotTimeInNanos = tableInfoSnapShotTimeInNanos;
    }
}
