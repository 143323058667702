import { Message } from '../../message';

export class ResponseBuyMoreChipsPokerTxnError extends Message {
    private MESSAGE_NAME: string = 'ResponseBuyMoreChipsPokerTxnError';
    private errorMessageToShow: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, errorMessageToShow: any | null = null) {
        super(reqServerPeerId);
        this.errorMessageToShow = errorMessageToShow;
    }

    getErrorMessageToShow(): any | null {
        return this.errorMessageToShow;
    }

    setErrorMessageToShow(errorMessageToShow: any | null) {
        this.errorMessageToShow = errorMessageToShow;
    }
}
