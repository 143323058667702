import { Message } from '../../message';

export class MegaPotPrize extends Message {
    private MESSAGE_NAME: string = 'MegaPotPrize';
    private megaPotPrize: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, megaPotPrize: number = 0) {
        super(reqServerPeerId);
        this.megaPotPrize = megaPotPrize;
    }

    getMegaPotPrize(): number {
        return this.megaPotPrize;
    }

    setMegaPotPrize(megaPotPrize: number) {
        this.megaPotPrize = megaPotPrize;
    }
}
