import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pmg from './poker-my-game.component';

@Injectable({ providedIn: 'root' })
export class PokerMyGameBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('mygamedatatemplate', pmg.PokerMyGameComponent);
        });
    }
}
