import { Routes } from '@angular/router';

import { HomepageComponent } from './homepage.component';
import { HomepageResolver } from './homepage.resolvers';

export const homepageRoutes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        resolve: {
            initData: HomepageResolver,
        },
    },
];
