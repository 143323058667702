import { Message } from '../../message';

export class CurrencyDetails extends Message {
    private MESSAGE_NAME: string = 'CurrencyDetails';
    private code: string | null = null;
    private symbol: string | null = null;
    private name: string | null = null;
    private defaultFractionDigits: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        code: string | null = null,
        symbol: string | null = null,
        name: string | null = null,
        defaultFractionDigits: number = 0,
    ) {
        super(reqServerPeerId);
        this.code = code;
        this.symbol = symbol;
        this.name = name;
        this.defaultFractionDigits = defaultFractionDigits;
    }

    getCode(): string | null {
        return this.code;
    }

    setCode(code: string | null) {
        this.code = code;
    }
    getSymbol(): string | null {
        return this.symbol;
    }

    setSymbol(symbol: string | null) {
        this.symbol = symbol;
    }
    getName(): string | null {
        return this.name;
    }

    setName(name: string | null) {
        this.name = name;
    }
    getDefaultFractionDigits(): number {
        return this.defaultFractionDigits;
    }

    setDefaultFractionDigits(defaultFractionDigits: number) {
        this.defaultFractionDigits = defaultFractionDigits;
    }
}
