import { Message } from '../../message';

export class ArjelGameLongValue extends Message {
    private MESSAGE_NAME: string = 'ArjelGameLongValue';
    private value: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, value: number = 0) {
        super(reqServerPeerId);
        this.value = value;
    }

    getValue(): number {
        return this.value;
    }

    setValue(value: number) {
        this.value = value;
    }
}
