import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener, Renderer2 } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PCComponent, PageMatrixComponent } from '@frontend/vanilla/features/content';

import { ModalPopupService } from '../shared-services/modal-popup.service';

@Component({
    selector: 'pp-modal-container',
    templateUrl: 'pp-modal-container.component.html',
    standalone: true,
    imports: [CommonModule, CommonModule, RouterModule, PageMatrixComponent],
})
export class PPModalContainerComponent extends PCComponent {
    data: any;
    modalSize: any;

    constructor(
        private renderer: Renderer2,
        private modalPopupService: ModalPopupService,
    ) {
        super();
    }

    @HostBinding() get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit() {
        this.data = this.item.name;
        this.modalSize = this.item.modalSize;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.modalPopupService.modalPopup();
        }, 200);
    }

    openModal() {
        (document.getElementById(this.item.name) as HTMLElement).classList.add('showModal');
    }

    closeModal() {
        (document.getElementById(this.item.name) as HTMLElement).classList.remove('showModal');
        this.renderer.removeClass(document.body, 'overflowHidden');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.closeModal();
    }
}
