import { Message } from '../../message';

export class DealMakingTrnyRank extends Message {
    private MESSAGE_NAME: string = 'DealMakingTrnyRank';
    private trnyRank: number = 0;
    private mtctId: number = 0;
    private participantNo: number = 0;
    private eliminatedPosition: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, trnyRank: number = 0, mtctId: number = 0, participantNo: number = 0, eliminatedPosition: number = 0) {
        super(reqServerPeerId);
        this.trnyRank = trnyRank;
        this.mtctId = mtctId;
        this.participantNo = participantNo;
        this.eliminatedPosition = eliminatedPosition;
    }

    getTrnyRank(): number {
        return this.trnyRank;
    }

    setTrnyRank(trnyRank: number) {
        this.trnyRank = trnyRank;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getEliminatedPosition(): number {
        return this.eliminatedPosition;
    }

    setEliminatedPosition(eliminatedPosition: number) {
        this.eliminatedPosition = eliminatedPosition;
    }
}
