import { Message } from '../../message';

export class PlayerATHStrike extends Message {
    private MESSAGE_NAME: string = 'PlayerATHStrike';
    private athBlockExpireTime: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, athBlockExpireTime: any | null = null) {
        super(reqServerPeerId);
        this.athBlockExpireTime = athBlockExpireTime;
    }

    getAthBlockExpireTime(): any | null {
        return this.athBlockExpireTime;
    }

    setAthBlockExpireTime(athBlockExpireTime: any | null) {
        this.athBlockExpireTime = athBlockExpireTime;
    }
}
