import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pdt from './poker-discount-timer.component';

@Injectable({ providedIn: 'root' })
export class PokerDiscountTimerPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('discountTimerTemplate', pdt.PokerDiscountTimerComponent);
        });
    }
}
