import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash-es';

@Pipe({
    name: 'sortByDate',
    standalone: true,
})
export class EventsSortByDatePipe implements PipeTransform {
    constructor() {}

    transform(value: any[], arg: string) {
        return _.sortBy(value, (item) => new Date(item['pplEventConfigContent'][arg]));
    }
}
