<div class="blogFeed text-white" *ngIf="initData">
    <ul class="list-unstyled">
        <ng-container *ngFor="let article of initData; let articleIndex = index">
            <li [class]="articleIndex === 0 ? 'media first flex-column mb-4' : 'media mb-2'">
                <a [href]="article.url" target="_blank" rel="noopener">
                    <img
                        [src]="article.featured_image"
                        class="mr-3 mw-100 image-fluid"
                        *ngIf="article.featured_image !== false && articleIndex === 0"
                        [alt]="article.title" />
                    <img
                        [src]="article.thumbnail_image"
                        class="mr-3"
                        *ngIf="article.thumbnail_image !== false && articleIndex > 0"
                        width="110"
                        [alt]="article.title" />
                </a>
                <div
                    [ngClass]="{
                        'media-body': articleIndex > 0
                    }">
                    <a [href]="article.url" target="_blank" rel="noopener">
                        <time *ngIf="articleIndex > 0">{{ getFormattedDate(article.date_published) }}</time>
                        <h5 class="mt-0 mb-1">{{ article.title }}</h5>
                        <span *ngIf="articleIndex === 0" [innerHtml]="article.excerpt">&nbsp;</span>
                    </a>
                    <hr class="hr-strip" *ngIf="articleIndex > 0" />
                </div>
            </li>
        </ng-container>
    </ul>
</div>
