import { Message } from '../../message';

export class ResponseSNGQuickRegister extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGQuickRegister';
    private requestId: number = 0;
    private cardId: number = 0;
    private fxSnapShotId: number = 0;
    private errorMessage: any | null = null;
    private mtctId: number = 0;
    private responseId: number = 0;
    private trackerIdList: any[] | null = null;
    private conversationId: number = 0;
    private participantNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        cardId: number = 0,
        fxSnapShotId: number = 0,
        errorMessage: any | null = null,
        mtctId: number = 0,
        responseId: number = 0,
        trackerIdList: any[] | null = null,
        conversationId: number = 0,
        participantNo: number = 0,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.cardId = cardId;
        this.fxSnapShotId = fxSnapShotId;
        this.errorMessage = errorMessage;
        this.mtctId = mtctId;
        this.responseId = responseId;
        this.trackerIdList = trackerIdList;
        this.conversationId = conversationId;
        this.participantNo = participantNo;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getFxSnapShotId(): number {
        return this.fxSnapShotId;
    }

    setFxSnapShotId(fxSnapShotId: number) {
        this.fxSnapShotId = fxSnapShotId;
    }
    getErrorMessage(): any | null {
        return this.errorMessage;
    }

    setErrorMessage(errorMessage: any | null) {
        this.errorMessage = errorMessage;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getTrackerIdList(): any[] | null {
        return this.trackerIdList;
    }

    setTrackerIdList(trackerIdList: any[] | null) {
        this.trackerIdList = trackerIdList;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
}
