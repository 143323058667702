import { inject } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as _ from 'lodash-es';
import * as PIXI from 'pixi.js';

import { BaseGameTableConnector } from '../../../pgBackendConnectors/baseGameTableConnector';
import { PokerBackendConnector } from '../../../pgBackendConnectors/pokerBackendConnector';
import { CSD } from '../../../pgConnectors/sal/CSD';
import { ClientToServerRequestServices } from '../../../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import { SpinGameService } from '../../../service/spin-game.service';
import { addMiniTable, miniTablesSelect } from '../../../store/mini-table.store';
import { selectProfileState, userBalanceSelect } from '../../../store/user.store';
import { PokerGameContainer } from '../app/poker-game-container';
import { AppDataModel } from '../common/AppDataModel';
import { SessionDataModel } from '../common/SessionDataModel';
import { TableSettingsDataModel } from '../common/TableSettingsDataModel';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import { controlPanelConfig } from '../game-config/controlPanelConfig';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { EmptyTableView } from '../views/EmptyTableView';
import { TableCreateView } from '../views/TableCreateView';
import { TableCreateViewModel } from '../vms/TableCreateViewModel';

export class AppFacade extends BaseGameTableConnector {
    displayUtil: DisplayUtil = new DisplayUtil();
    gameApp?: any;
    soundUtil: any;
    activeTables = [] as any;
    primaryAssetsLoaded = false;
    gameName;
    name = 'AppFacadeVer2.0';
    configData = {};
    newTableAdded = false;
    isConnected = false;
    isViewCreated = false;
    spinIndex = 1;
    isDisconnected = false;
    emptyTableViewArr = [];
    secondaryAssetsLoaded = false;
    isLoyaltyCashBackShimmerShowed = false;
    expoFullScreen = true;
    parentFacade = true;
    tablePositions: any;
    appDataModel: AppDataModel = new AppDataModel();
    tableSettingsDataModel: TableSettingsDataModel = new TableSettingsDataModel();
    sessionDataModel: SessionDataModel = new SessionDataModel();
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    eventDispatcherGlobal = new EventDispatcherGlobal();
    clientToServerRequestServices: ClientToServerRequestServices;
    spinGameService = inject(SpinGameService);
    isCreateTileView = true;
    isUserProfileUpdated = false;
    store = inject(Store);
    navigationService = inject(NavigationService);
    constructor() {
        super(PokerBackendConnector.getInstance());
        //this.gameName = game;
        // if (!uiConfig.isDesktop) {
        // 	document.body.addEventListener('touchend', this.enterFullScreen.bind(this));
        // }
        this.tablePositions = {
            x: [0, window.parent.innerWidth / 2, 0, window.parent.innerWidth / 2],
            y: [0, 0, window.parent.innerHeight / 2, window.parent.innerHeight / 2],
        };
        this.eventDispatcherGlobal.addEventListener('onSecondaryAssetsComplete', this.onSecondaryAssetsLoaded.bind(this));
        this.eventDispatcherGlobal.addEventListener('updateUserProfileAndTableSetting', this.updateUserProfileAndTableSetting.bind(this));
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }

    handleServerResponse = function (message) {
        let peerId = message.peerId;
        if (message.entryId) {
            peerId = message.entryId;
        } else if (this.tableToEntryMap && this.tableToEntryMap[message.peerId]) {
            peerId = this.tableToEntryMap[message.peerId];
        }
        if (message.className == 'PoolStartGameNotification') {
            this.tableToEntryMap[message.tableId] = message.entryId;
        }
        if (message.className == 'PoolEndGameNotification') {
            //if there's no mapping, clear all mappings for such entry
            if (this.tableToEntryMap[message.tableId]) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete this.tableToEntryMap[message.tableId];
            } else {
                for (const tableId in this.tableToEntryMap) {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    if (this.tableToEntryMap[tableId] == message.reqServerPeerId) delete this.tableToEntryMap[tableId];
                }
            }
        }
        if (message.className == 'RemoveGameTable') {
            this['table' + this.activeTableId]?.tableView.clearTableInterval();
            if (this.sideLeaveTableId) {
                peerId = this.sideLeaveTableId;
                this.sideLeaveTableId = undefined;
            } else {
                peerId = this.activeTableId;
            }
            if (
                this['table' + message.tableId]?.gameService.getGameTableType() != 1 &&
                this['table' + message.tableId]?.gameService.getGameTableType() != 2
            ) {
                const data = {};
                data['tableId'] = message.tableId;
                data['type'] = 'resetAnimation';
                this.eventDispatcherGlobal.dispatchEvent('resetExpoAnimationIframe', data);
            }
            this.removePeer(message.tableId, CSD.REAL);
        }
        //console.log("handleServerResponse----> " + message.className + " data ----- " + JSON.stringify(message));
        if (message.className == 'PreviousTableExists' && this.activeTables && this.activeTables.length > 0) {
            // this.checkForActiveTables(message.existingTablesArr);
        } else if (message.className == 'closeAllTables') {
            this.closeAllTables();
        } else if (message.className == 'AddSimilarGameTable') {
            this.sendOpenTableReq(message);
        } else if (message.className == 'HUPairTableSelectStatus') {
            /* else if (message.className == "disConnected") {
            this.handleDisconnection(message);
          }*/
            //console.log(message.className +"1" + " data ----- " + JSON.stringify(message));
            this['table' + this.activeTableId].handleHUPairTableSelectStatus(message);
        } else if (message.className == 'JoinTableResponse') {
            this.createPokerTableContainers(message, peerId);
        } else if (
            message.className == 'gameSettingData' ||
            message.className == 'doAppLogOut' ||
            message.className == 'DiamondBalance' ||
            message.className == 'themeChange'
        ) {
            if (message.className == 'DiamondBalance') {
                this.sessionDataModel.handleDiamondBalance(message);
            }
            this.itrateOverGameTables(message);
        } else if (this['table' + peerId] && this['table' + peerId].tableOpenStatus) {
            if (message.className === 'PoolStartGameNotification') {
                this.addPeer(message.tableId, CSD.REAL);
            }
            this['table' + peerId].handleGameServerResponse(message.className, message);
        } else if (message.className == 'JoinPoolResponse' || message.className == 'PrevPlayingFastPokerPools') {
            // ff meessages from here (tempcode)
            const storedData = localStorage.getItem('PoolAvailabilityResponseArray');
            const poolAvailabilityArray = storedData ? JSON.parse(storedData) : {};
            if (message.fastPoolId) {
                message['poolID'] = message['poolId'];
                message['peerId'] = message['poolId'];
                message['className'] = message['MESSAGE_NAME'];
                // for (let i = 0; i < message['entryIds']!.length; i++) {
                //     message['entryId'] = message['entryIds']![i];
                //     message['tableId'] = message['entryIds']![i];
                // }
            }
            if (message.tableStatus === undefined) {
                message['tableStatus'] = poolAvailabilityArray[message['fastPoolId']];
                if (message.tableStatus === undefined) {
                    let activeTable;
                    this.store.select(miniTablesSelect).subscribe((data) => {
                        if (data['miniTables'].length > 0) {
                            activeTable = data['miniTables'].filter((item) => item.tableObject.poolID === message.poolID);
                        }
                    });
                    message['tableStatus'] = activeTable[0].tableObject.tableStatus;
                }
            }
            this.store.dispatch(addMiniTable({ miniTables: message }));
            // this.navigationService.goTo('/play/game-table');
            this.createPokerTableContainers(message, peerId);
            setTimeout(() => {
                // const updatedPoolAvailabilityArray = Object.fromEntries(
                //     Object.entries(poolAvailabilityArray).filter(([key]) => Number(key) !== message.fastPoolId),
                // );
                // Store the updated object back into local storage
                localStorage.removeItem('PoolAvailabilityResponseArray');
                localStorage.removeItem('prevPlayingFastPokerPoolsArr');
            }, 40000);
        } else if (message.className == 'CloseTable') {
            //window.parent.Connector.removePeer(message.tableId, 0);
        } else if (message.className == 'AutoOptionToOptionsMappings') {
            this.sessionDataModel.handleAutoOptionToOptionsMappings(message);
        }
        if (message.className == 'autoRebuyStatusChange') {
            this.sendRequestAutoRebuyChange(message);
        }
        if (message.className == 'openTermsAndConditions') {
            // for reward surfacing T&C
            this.displayTandC();
        }
        if (message.className == 'onLoyaltyCashBackPopUpClose') {
            this.loyaltyCashBackWidgetStatus();
        }
        if (message.className == 'LCBProgressBarBoosterUpdate') {
            this.LCBProgressBarBoosterUpdate(message);
        }
        if (message.className == 'PlayedGamesCount') {
            this['table' + message.peerId].handleGameServerResponse(message.className, message);
        }
        if (message.className == 'CanLeaveTableResponse') {
            this['table' + message.peerId].handleGameServerResponse(message.className, message);
        }
        if (message.className == 'CanSitOutResponse') {
            this['table' + message.peerId].handleGameServerResponse(message.className, message);
        }
        if (message.className == 'ATHAutoSitOutMsg') {
            this['table' + message.peerId].handleGameServerResponse(message.className, message);
        }
        if (message.className == 'tapToUnMute') {
            this.tapToUnmute();
        }
        if (message.className == 'LoginSuccessResponse') {
        }
        if (message.className == 'LoginSuccessUserProfile') {
            this.store.select(selectProfileState).subscribe((data) => {
                if (data && Object.keys(data).length !== 0) {
                    this.sessionDataModel.handleUserProfile(data);
                }
                uiConfig.selfUserId = data ? data['screenName'] : null;
            });
            // this.store.select(GameTableSettingsSelect).subscribe(data=>{
            //   this.tableSettingsDataModel.storeGameSettingData(data);
            // });
        }
        if (message.className == 'BalanceInfo') {
            this.appDataModel.handleBalanceInfo(message);
        }
        if (message.className == 'UserFreeRollList') {
            this.sessionDataModel.handleUserFreeRollList(message);
        }
        if (message.className == 'removeReplayers') {
            this.removeReplayers();
        }
    };
    isTableAlreadyExist(peerId: number): boolean {
        if (this.activeTables.includes(peerId) || this.activeTables.length >= 4) {
            return true;
        }
        return false;
        // // Check if the maximum number of active tables is reached
        // if (this.activeTables.length >= 4) {
        //     console.log("Maximum number of active tables reached. Exiting function.");
        //     return;
        // }
    }
    createPokerTableContainers = function (data, peerId) {
        if (!this.isTableAlreadyExist(peerId)) {
            this.addPeer(peerId, CSD.REAL);
            if (this.isUserProfileUpdated == false) {
                this.isUserProfileUpdated = true;
                this.updateUserProfileAndTableSetting();
            }
            // --Adding tap To unMute Icon for initial context
            if (!this.isContextAvailable) {
                this.createTaptoUnmuteImage();
            }
            /*-------------Swipe Up Functiionality---------------*/
            if (uiConfig.hasSwipe) {
                this.checkForScrollInitially();
            }
            // window.dispatchEvent(new Event(SlotCoreGameEvent.SCROLL_TO_TOP));
            const tableId = peerId;
            //    for (let i = 0; i < this.activeTables.length; i++) {
            //     if (this.activeTables[i] === peerId) {
            //         tableExistStatus = true;
            //         break; // Exit the loop once the table is found
            //     }
            //  }
            //   if (this.activeTables.length >= 4 || tableExistStatus) {
            //      return;
            //   }
            //eMTCT = 0, eSTT = 1, eMTSG = 2, eSNGJP = 3}
            if (data && data.tableStatus) {
                if (
                    data.tableStatus.tableCategory == serverIdConstants.TABLE_CATEGORY_TOURNAMENT &&
                    data.tableStatus.tournamentCategory != 3 /*eSNGJP*/
                ) {
                    if (this.activeTables.length == 0) this.navigateToLobby();
                    return;
                }
                const seatCount = data.tableStatus.maxNoOfSeats;
                if (seatCount != 2 && seatCount != 3 && seatCount != 6 && seatCount != 7 && seatCount != 8) {
                    // let errorMessage = "We do not support "+ seatCount + " Max Tables at the moment.";
                    // let failedToJoinPool = new CustomEvent("DISPLAY_GENERIC_MESSAGE_ONLOBBY", {
                    //   detail: errorMessage
                    // });
                    // window.parent.dispatchEvent(failedToJoinPool);
                    if (this.activeTables.length == 0) this.navigateToLobby();
                    return;
                }
            }
            this.activeTables.push(tableId);
            this['table' + peerId] = new PokerGameContainer(tableId);
            this.stage.addChild(this['table' + peerId]);
            this['table' + peerId].createEventDispatcher();
            this['table' + peerId].createContainers();
            this['table' + peerId].createCoreViewModels();
            this['table' + peerId].handleGameServerResponse(data.className, data);
            this['table' + peerId].initiateViewsCreation(); // it was above create core viewmodels before
            this.setActiveTableId(peerId);
            this.showExpoSpinAnimation(peerId);
            //  window.parent.AppController.SendMessageToSwitcher({
            //    eventName: "AddNewGameTable",
            //    TableId: peerId,
            //    Title: data.tableStatus
            //  });
            if (this.fullScaleView) {
                const message = {};
                message['className'] = 'showWidget';
                message['tableId'] = peerId;
                message['show'] = true;
                this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
                this['table' + peerId].scale.set(1);
                this['table' + peerId].triggerMiniSwitcherSelect();
                this['table' + peerId].eventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_LOYALTY_CASH_BACK, true);
            } else {
                const message = {};
                message['className'] = 'showWidget';
                message['tableId'] = peerId;
                message['show'] = false;
                this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
                this['table' + peerId].scale.set(0.5);
                this.updateTablePos();
                this['table' + peerId].eventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_LOYALTY_CASH_BACK, false);
            }
            this['table' + peerId].eventDispatcher.dispatchEvent(SlotCoreGameEvent.TABLE_RESIZE_START);
            this.addNewTableView.setTableStatusData(
                this['table' + peerId].gameService.getTableStatusData(),
                peerId,
                this['table' + peerId].gameService.getGameTableType(),
            ); //, availableTickets);
            this.setAddNewTableView();
            this.removeReplayers();
            this['table' + peerId].hideKothBtn();
            this.navigationService.goTo('/play/game-table');
        }
    };
    handleVisibilityChange = function () {
        // if (document.hidden) {
        //   // The page is now hidden, do something here
        //   //alert("Page hidden");
        // } else {
        //   // The page is now visible, do something here
        //   alert("Page visible");
        // }
    };
    initiateGame = function (appData) {
        this.gameApp = appData.gameApp;
        this.soundUtil = appData.soundUtil;
        uiConfig.controlPanel.config = controlPanelConfig;
        this.activeTables = [];
        this.tableToEntryMap = {};
        this.activeAddTable = [];
        this.stage = this.gameApp;
        //below if  code for IOS sounds functionality
        this.isContextAvailable = false;
        // dummy Container
        this.dummyContainer = new PIXI.Container();
        const graphics = new PIXI.Graphics();
        graphics.beginFill(0x000000, 0.05);
        graphics.lineStyle(1, 1);
        graphics.drawRect(0, 0, uiConfig.windowWidth, uiConfig.windowHeight);
        graphics.endFill();
        this.dummyContainer.addChild(graphics);
        this.dummyContainer.name = 'dummyContainer';
        this.soundInterval = setInterval(
            function () {
                this.stage.addChildAt(this.dummyContainer, this.stage.children.length);
                this.dummyContainer.interactive = true;
                this.dummyContainer.buttonMode = true;
                this.dummyContainer.click = this.dummyContainer.tap = function () {
                    this.tapToUnmute();
                }.bind(this);
            }.bind(this),
            100,
        );
        this.fullScaleView = true;
        this.expoFullScreen = true;
        if (this.activeTables.length == 0) {
            this.navigateToLobby();
        }
    };
    updateUserProfileAndTableSetting = function () {
        // this.store.select(GameTableSettingsSelect).subscribe(data=>{
        //   this.tableSettingsDataModel.getGameSettingData();
        // });
        this.tableSettingsDataModel.getGameSettingData();
        this.store.select(selectProfileState).subscribe((data) => {
            this.sessionDataModel.handleUserProfile(data);
            uiConfig.selfUserId = data ? data['screenName'] : null;
        });
    };
    tapToUnmute = function () {
        this.isContextAvailable = true;
        //console.log("Pavan  :: now sound can play");
        clearInterval(this.soundInterval);
        this.stage.removeChild(this.dummyContainer);
    };
    //method for removing sounds dummy container after spinsanimation complete
    removeDummyContainer = function () {
        try {
            if (!this.isContextAvailable) {
                this.isContextAvailable = true;
                clearInterval(this.soundInterval);
                if (this.dummyContainer) {
                    this.stage.removeChild(this.dummyContainer);
                }
                this.tapToUnmuteDiv.style.display = 'none';
            }
        } catch (e) {
            //console.log(e);
        }
    };
    showExpoSpinAnimation = function (peerId) {
        if (this.activeTables.length > 0) {
            for (let i = 0; i < this.activeTables.length; i++) {
                if (CommonGameProperties.expoAnime[i].expoSpinAnime) {
                    CommonGameProperties.expoAnime[i].expoSpinAnime.dynamicContentContainer.nativeElement.style.display = 'none';
                    if (this.expoFullScreen == true) {
                        if (
                            CommonGameProperties.expoAnime[i].expoSpinAnime.tableId === peerId &&
                            CommonGameProperties.expoAnime[i].expoSpinAnime.activeStatus == true
                        ) {
                            const nativeElement = CommonGameProperties.expoAnime[i].expoSpinAnime.dynamicContentContainer
                                .nativeElement as HTMLElement;
                            nativeElement.style.cssText =
                                'position: absolute; border: 0; top: 0px; height: calc(100% - 0px); width: 100%; z-index: 1; background-color: #000000; display: block;';
                            this.eventDispatcherGlobal.dispatchEvent('resumeExpoAnime', CommonGameProperties.expoAnime[i].expoSpinAnime);
                        }
                    }
                    if (this.expoFullScreen == false) {
                        // if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId === peerId && CommonGameProperties.expoAnime[i].expoSpinAnime.activeStatus == true)
                        if (
                            CommonGameProperties.expoAnime[i].expoSpinAnime.tableId === peerId &&
                            CommonGameProperties.expoAnime[i].expoSpinAnime.activeStatus == true
                        ) {
                            this.selectExpoSpinTileView(
                                CommonGameProperties.expoAnime[i].expoSpinAnime,
                                this.activeTables[i],
                                CommonGameProperties.expoAnime[i].expoSpinAnime.expoId,
                            );
                        }
                    }
                }
            }
        }
    };
    selectExpoSpinTileView = function (expoAnimIframe, tableId, tableindex) {
        this.tablePositions = {
            x: [0, uiConfig.windowWidth / 2, 0, uiConfig.windowWidth / 2],
            y: [0, 0, uiConfig.windowHeight / 2, uiConfig.windowHeight / 2],
        };
        // let tableindex = this.activeTables.indexOf(tableId);
        const posX = this.tablePositions.x[tableindex] + 'px',
            posY = this.tablePositions.y[tableindex] + 'px',
            minHeight = window.innerHeight / 2 + 'px', //uiConfig.windowHeight / 2 + "px",
            minWidth = window.innerWidth / 2 + 'px'; //uiConfig.windowWidth / 2 + "px";
        // expoAnimIframe.setAttribute("style",
        //   "position: absolute;border:0;top: 0px;z-index:1;background-color:#000000;"
        // );
        const nativeElement = expoAnimIframe.dynamicContentContainer.nativeElement as HTMLElement;

        nativeElement.style.position = 'absolute';
        nativeElement.style.border = '0';
        nativeElement.style.top = '60px';
        nativeElement.style.zIndex = '1';
        nativeElement.style.backgroundColor = '#000000';

        const expoAnimIframe1 = expoAnimIframe.dynamicContentContainer.nativeElement as HTMLElement;
        expoAnimIframe1.style.display = 'none';
        expoAnimIframe1.style.height = minHeight; // Assuming minHeight is defined elsewhere
        expoAnimIframe1.style.width = minWidth; // Assuming minWidth is defined elsewhere
        expoAnimIframe1.style.left = posX; // Assuming posX is defined elsewhere
        expoAnimIframe1.style.top = posY;
    };
    // remove existing replayers from DOM
    removeReplayers = function () {
        for (let i = 0; i < this.activeTables.length; i++) {
            if (document.getElementById('replayerIframetable' + this.activeTables[i]) != null && this.activeTables[i] != null) {
                document.getElementById('replayerIframetable' + this.activeTables[i])?.remove();
                document.getElementById('closeReplayertable' + this.activeTables[i])?.remove();
                if (document.getElementById('loaderframetable' + this.activeTables[i]) != null) {
                    document.getElementById('loaderframetable' + this.activeTables[i])?.remove();
                }
            }
        }
    };
    checkForScrollInitially = function () {
        // $(window).scroll(function () {
        //   clearTimeout($.data(this, 'scrollTimer'));
        //   $.data(this, 'scrollTimer', setTimeout(function () {
        //     window.scrollTo(0, 1);
        //   }, 200));
        // });
        // window.scrollTo(0, 1);
        // $('#gameStage > canvas').css('top', '1px');
    };
    viewEmptyTableBG = function (view, index) {
        this.stage.addChildAt(view, 0);
        view.createView(index);
        this.emptyTableViewArr[index] = view;
    };
    viewAddNewTable = function (view) {
        this.createNewTableModel = new TableCreateViewModel(this.gameService);
        this.stage.addChildAt(view, 0);
        this.createNewTableModel.setView(view);
        view.createView();
        this.addNewTableView = view;
        this.setAddNewTableView();
        this.activeAddTable.push(view);
    };
    setActiveTableId = function (val) {
        this.activeTableId = val;
        if (this.activeTables && this.activeTables.length) {
            this.activeTables.map((id) => {
                this['table' + id].updateSoundVolume();
            });
        }
    };
    swipeTable = function (dir) {
        if (this.activeTables && this.activeTables.length) {
            const currentTableIndex = this.activeTables.indexOf(this.activeTableId);
            this.hideExpoSpinsAnimation(this.activeTableId);
            if (dir == 'left' && currentTableIndex < this.activeTables.length - 1) {
                this.maximiseTableView(this.activeTables[currentTableIndex + 1]);
            } else if (dir == 'right' && currentTableIndex > 0) {
                this.maximiseTableView(this.activeTables[currentTableIndex - 1]);
            }
        }
    };
    pauseAllExpoVid = function () {
        if (this.activeTables.length > 0) {
            this.activeTables.map((id) => {
                this.hideExpoSpinsAnimation(id);
            });
        }
    };
    resizeTableView = function (peerId) {
        for (let i = 0; i < this.activeTables.length; i++) {
            if (this.activeTables[i] == peerId) {
                this.setActiveTableId(peerId);
                this.resizeCanvas(peerId, i);
                break;
            }
        }
    };
    maximiseTableView = function (peerId) {
        for (let i = 0; i < this.activeTables.length; i++) {
            if (this.activeTables[i] == peerId) {
                this.expoFullScreen = true;
                this.resizeCanvas(peerId, i, true);
                this.showExpoSpinAnimation(peerId);
                break;
            }
        }
    };
    addNewTable = function () {
        this.createPokerTableContainers();
    };
    createCoreView = function () {
        uiConfig.controlPanel.config = controlPanelConfig;
        this.isFirstTime = true;
    };
    showErrorMessage = function (title, message, btn, tableId) {
        if (!tableId) {
            tableId = 1;
        }
        this['table' + tableId].showGameErrorMessage(title, message, btn);
    };
    updateLoadingProgress = function (num) {
        if (this.preloaderViewModel && this.preloaderViewModel.getView() != undefined) {
            this.table1.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_PRELOADER, num);
        }
    };
    onSpinClick = function () {
        this.parentCallBacks.onSpinClick();
        this.sendSpinRequest();
    };
    sendAddNewTableRequest = function () {
        this.createNewTable();
    };
    createSwipefeature = function () {
        // this.swipeUpComponent = new SwipeUpComponent();
        // this.swipeUpComponent.createView();
    };
    PrimaryAsstsLoaded = function (gameService) {
        this.gameService = gameService;
        for (let i = 0; i < 1; i++) {
            this.viewAddNewTable(new TableCreateView(this));
        }
        //always minimum 1 table exists so create 1,2,3
        for (let i = 1; i < 4; i++) {
            this.viewEmptyTableBG(new EmptyTableView(), i);
        }
    };
    onSecondaryAssetsLoaded = function () {
        this.secondaryAssetsLoaded = true;
        for (let i = 0; i < this.activeTables.length; i++) {
            this['table' + this.activeTables[i]].createSecondaryViews();
        }
    };
    onExtraAssetsLoaded = function () {
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.EXTRA_ASSETS_LOADED);
    };
    onLastAssetsLoaded = function () {};
    onSocketConnected = function (obj) {
        this.isConnected = true;
        this['table' + 'this.activeTableId'].handleGameAvailability(obj);
    };
    showRulesPaytable = function () {
        this.parentCallBacks.onInfoShowClick();
    };
    showHelpWindow = function () {
        this.parentCallBacks.onHelpShowClick();
    };
    showCashierMobile = function () {
        this.mobileTopPanel.onCashierClick();
    };
    getActivePeer = function () {
        this.activePeerId = this.activeTableId;
        for (let i = 0; i < this.activeTables.length; i++) {
            if (this['table' + this.activeTables[i]] && this['table' + this.activeTables[i]].scale.x == 1) {
                this.activePeerId = this.activeTables[i];
                break;
            }
        }
    };

    openLobbyFromGameTable(lobby: string) {
        // let activeTable;
        // this.store.select(miniTablesSelect).subscribe((data) => {
        //     activeTable = data['miniTables'].filter((item) => item.isActive === true);
        // });
        // this.hideExpoSpinsAnimation(activeTable);
        this.eventDispatcherGlobal.dispatchEvent('hideExpoAnimation', {});
        CommonGameProperties.clienttoserver['goToLobby'](`${lobby}`);
        this.removeReplayers();
    }

    sendOpenTableReq = function (message) {
        let similarTableId = message.TableId;
        if (this.activeTableId) {
            this.activePeerId = this.activeTableId;
        } else if (!this.activeTableId && this.activeTables.length > 0) {
            this.setActiveTableId(this.activeTables[this.activeTables.length - 1]);
        }
        if (!this['table' + similarTableId]) {
            similarTableId = this.activeTableId;
        }
        switch (this['table' + similarTableId].gameService.getGameTableType()) {
            case serverIdConstants.TABLE_TYPE_SPINS:
                const sngJpId = this['table' + similarTableId].getSngJackpotID();
                if (sngJpId) {
                    this.newTableAdded = true;
                    if (message.enableExpoSpin == true) {
                        this.spinGameService.RequestSNGJPPlayNow(sngJpId, message.gameCount, 0, message.ticketCount, false);
                    } else {
                        const tickets = this.spinGameService.getTicketsForSngJPId(sngJpId);
                        const ticketcount = this.appDataModel.getSpinTicketCount(tickets);
                        if (message.gameCount == undefined) {
                            message.gameCount = 1;
                        }
                        this.spinGameService.RequestSNGJPPlayNow(sngJpId, message.gameCount, 0, ticketcount, false);
                    }
                } else {
                    //console.log('No active sngJpId');
                }
                break;
            case serverIdConstants.TABLE_TYPE_FF:
                this.newTableAdded = true;
                //window.parent.fastForwardController.openSimilarFF(similarTableId);
                break;
            case serverIdConstants.TABLE_TYPE_CASH:
                // let miniTables;
                // let activeTable;
                // this.store.select(miniTablesSelect).subscribe(data => {
                //   if(data['miniTables'].length > 0){
                //       miniTables = _.sortBy(data['miniTables'], (obj) =>  obj.index);
                //       activeTable = data['miniTables'].filter(item => item.isActive === true);
                //   }
                // });
                // CommonGameProperties.clienttoserver["addSimilarGameTable"](activeTable[0]);
                /*let isServerGroupingEnabled = true;
          if(isServerGroupingEnabled===true){
            //window.parent.cashGamesController.openSimilarCash(getIdFromMiniTablesData.miniTables[this.activeTableId].tableInfoId);
            console.log('ls..grouping - similar table')
          } else {
            //window.parent.cashGamesController.openSimilarCash(similarTableId);
            console.log('ls..Non grouping - similar table')
          }*/
                this.newTableAdded = true;
                break;
        }
        if (this['table' + similarTableId].gameService.getGameTableType()) {
            let activeTable;
            this.store.select(miniTablesSelect).subscribe((data) => {
                if (data['miniTables'].length > 0) {
                    activeTable = data['miniTables'].filter((item) => item.tableId === similarTableId);
                }
            });
            CommonGameProperties.clienttoserver['addSimilarGameTable'](activeTable[0]);
            this.newTableAdded = true;
        }
    };
    loyaltyCashBackWidgetStatus = function () {
        // let loyaltyCashbackObj = JSON.parse(window.localStorage.getItem("loyaltyCashback"));
        for (let i = 0; i < this.activeTables.length; i++) {
            this['table' + this.activeTables[i]].loyaltyCashBackResetObj();
        }
    };
    LCBProgressBarBoosterUpdate = function (message) {
        for (let i = 0; i < this.activeTables.length; i++) {
            this['table' + this.activeTables[i]].updateLCBHandEnd(message['data']);
        }
    };
    checkForActiveTables = function (tablesArr) {
        for (let i = 0; i < tablesArr.length; i++) {
            if (this.activeTables.indexOf(tablesArr[i]) !== -1 && this['table' + tablesArr[i]]) {
                this['table' + tablesArr[i]].removeGameTable();
            }
        }
    };
    //     closeAllTables = function () {
    //       for (let i = 0; i < this.activeTables.length; i++) {
    //         this["table" + this.activeTables[i]].removeGameTable();
    //       }
    //     }
    itrateOverGameTables = function (message) {
        for (let i = 0; i < this.activeTables.length; i++) {
            this['table' + this.activeTables[i]].handleGameServerResponse(message.className, message);
        }
    };
    //     sendRequestDoAppLogOut = function () {
    //       window.parent.LSConnector.mainConnector.sendRequestDoAppLogOut();
    //     };
    //     sendServerRequest = function (obj) {
    //       window.parent.postMessage("tablePostReqData", obj);
    //     };
    //     handleDisconnection = function (obj) {
    //       if (!parseInt(status)) {
    //         this.isDisconnected = true;
    //         //alert('Connection to the server has been dropped.')
    //         console.log("Connection to the server has been dropped.");
    //       } else {
    //         console.log("Connection to the server is established again.");
    //         this.isDisconnected = false;
    //       }
    //     };
    setAddNewTableView = function () {
        this.tableAddedPositions = {
            x: [0, window.parent.innerWidth / 2, 0, window.parent.innerWidth / 2],
            y: [0, 0, window.parent.innerHeight / 2, window.parent.innerHeight / 2],
        };
        if (this.activeTables.length > 0) {
            this.addNewTableView.refreshTableCaptions();
        } else {
            this.navigateToLobby();
        }
        const i = this.activeTables.length;
        this.newTableScaleTween = gsap.to(this.addNewTableView.scale, 0.2, {
            x: 0.5,
            y: 0.5,
        });
        this.newTablePosTween = gsap.to(this.addNewTableView, 0.2, {
            x: this.tableAddedPositions.x[i],
            y: this.tableAddedPositions.y[i],
        });
    };
    navigateToLobby = function (tableType?) {
        if (tableType == serverIdConstants.TABLE_TYPE_FF) {
            this.navigationService.goTo('/play/fast-forward');
            // window.parent.dispatchEvent(new CustomEvent("NAVIGATE_TO_PAGE", {
            //   detail: ["home", "fast-forward"]
            // }));
        } else if (tableType == serverIdConstants.TABLE_TYPE_CASH) {
            this.navigationService.goTo('/play/cash');
            // window.parent.dispatchEvent(new CustomEvent("NAVIGATE_TO_PAGE", {
            //   detail: ["home", "cash-games"]
            // }));
        } else {
            this.navigationService.goTo('/play');
            // window.parent.dispatchEvent(new CustomEvent("NAVIGATE_TO_PAGE", {
            //   detail: ["home"]
            // }));
        }
    };
    navigateToSpinLobby = function (spinCardType) {
        switch (spinCardType) {
            case 0:
                // window.parent.dispatchEvent(new CustomEvent("NAVIGATE_TO_PAGE", {
                //   detail: ["home", "spinlobby"]
                // }));
                break;
            case 1:
                this.navigationService.goTo('/play/spins-overdrive');
                break;
            //   window.parent.dispatchEvent(new CustomEvent("NAVIGATE_TO_PAGE", {
            //     detail: ["home", "spinoverdrivelobby"]
            //   }));
            // break;
        }
    };
    resizeCanvas = function (i, index, fullScale) {
        this.tablePositions = {
            x: [0, window.parent.innerWidth / 2, 0, window.parent.innerWidth / 2],
            y: [0, 0, window.parent.innerHeight / 2, window.parent.innerHeight / 2],
        };
        if (!this['table' + i]) return;
        if (this.stage) {
            this.stage.addChild(this['table' + i]);
        }
        if (fullScale || this['table' + i].scale.x !== 1) {
            const message = {};
            message['className'] = 'showWidget';
            message['tableId'] = i;
            message['show'] = true;
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
            let tempTable;
            for (let j = 0; j < this.activeTables.length; j++) {
                tempTable = this.activeTables[j];
                this['tableScaleTween' + tempTable] = gsap.to(this['table' + tempTable].scale, 0.2, {
                    x: 1,
                    y: 1,
                    onComplete: function (tempTable) {
                        this.fullScaleView = true;
                        this.expoFullScreen = true;
                        this['table' + tempTable].eventDispatcher.dispatchEvent(SlotCoreGameEvent.TABLE_RESIZE_START);
                        this['table' + tempTable].eventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_LOYALTY_CASH_BACK, true);
                    }.bind(this, tempTable),
                });
                this['tablePosTween' + tempTable] = gsap.to(this['table' + tempTable], 0.2, {
                    x: 0,
                    y: 0,
                });
                if (this.stage) {
                    this.stage.addChild(this['table' + tempTable]);
                    this.stage.addChild(this['table' + i]);
                    this.setActiveTableId(this['table' + i].gameService.getPeerId());
                }
            }
        } else {
            const message = {};
            message['className'] = 'showWidget';
            message['tableId'] = i;
            message['show'] = false;
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
            for (let j = 0; j < this.activeTables.length; j++) {
                this['table' + this.activeTables[j]].triggerMiniSwitcherSelect();
                this['activeTableScaleTween' + this.activeTables[j]] = gsap.to(this['table' + this.activeTables[j]].scale, 0.2, {
                    x: 0.5,
                    y: 0.5,
                    onComplete: function (j) {
                        this.fullScaleView = false;
                        this.expoFullScreen = false;
                        this['table' + this.activeTables[j]].eventDispatcher.dispatchEvent(SlotCoreGameEvent.TABLE_RESIZE_START);
                        this['table' + this.activeTables[j]].eventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_LOYALTY_CASH_BACK, false);
                        this.showExpoSpinTileAnimation(j);
                    }.bind(this, j),
                });
                this['activeTablePosTween' + this.activeTables[j]] = gsap.to(this['table' + this.activeTables[j]], 0.2, {
                    x: this.tablePositions.x[j],
                    y: this.tablePositions.y[j],
                });
                this.setAddNewTableView();
            }
        }
    };
    updateTileModeExpoAnimationPosition() {
        if (!this.expoFullScreen) {
            for (let j = 0; j < this.activeTables.length; j++) {
                this.showExpoSpinTileAnimation(j);
            }
        }
    }
    showExpoSpinTileAnimation = function (j) {
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            const iframeId = this.getActiveIframeId(this.activeTables[j]);
            if (CommonGameProperties.expoAnime[iframeId]?.expoSpinAnime != null) {
                // let expoAnimIframe = document.getElementById("expoSpinIframe"+iframeId);
                const nativeElement = CommonGameProperties.expoAnime[iframeId].expoSpinAnime.dynamicContentContainer.nativeElement as HTMLElement;
                nativeElement.style.left = this.tablePositions.x[j] + 'px';
                nativeElement.style.top = this.tablePositions.y[j] + 'px';
                if (CommonGameProperties.expoAnime[iframeId].expoSpinAnime.activeStatus == true) {
                    nativeElement.style.display = 'block';
                    nativeElement.style.height = window.innerHeight / 2 + 'px'; //uiConfig.windowHeight / 2 + "px";
                    nativeElement.style.width = window.innerWidth / 2 + 'px'; //uiConfig.windowWidth / 2 + "px";
                    this.eventDispatcherGlobal.dispatchEvent('resumeExpoAnime', CommonGameProperties.expoAnime[j].expoSpinAnime);
                } else {
                    nativeElement.style.display = 'none';
                    nativeElement.style.position = 'absolute';
                    nativeElement.style.height = window.innerHeight / 2 + 'px';
                    nativeElement.style.width = window.innerWidth / 2 + 'px';
                    nativeElement.style.left = this.tablePositions.x[j] + 'px';
                    nativeElement.style.top = this.tablePositions.y[j] + 'px';
                }
            }
        }
    };
    sendOptionSetAck = function (optionSetId, clientServerClock, peerId) {
        this.gameTableNotificationService.sendOptionSetAckReq(optionSetId, clientServerClock, peerId);
    };
    sendSitRequest = function (conversationId, frFppIndicator, noOfChips, seatingPosition, tableCategory, peerId) {
        this.gameTableNotificationService.sendSitRequest(conversationId, frFppIndicator, noOfChips, seatingPosition, tableCategory, peerId);
    };
    sendSelectedOption = function (option, optionSetId, displayedOptionsForSecs, mouseClickSimulationType, optionSelectedAt, peerId) {
        this.gameTableNotificationService.sendSelectedOption(
            option,
            optionSetId,
            displayedOptionsForSecs,
            mouseClickSimulationType,
            optionSelectedAt,
            peerId,
        );
    };
    sendPoolSitOutNotification = function (peerId) {
        this.gameTableNotificationService.sendPoolSitOutNotification(peerId);
    };
    sendSitOutNexthandOn = function (peerId) {
        this.gameTableNotificationService.sendSitOutNexthandOn(peerId);
    };
    sendRequestSitoutNextHandOff = function (peerId) {
        this.gameTableNotificationService.sendRequestSitoutNextHandOff(peerId);
    };
    sendIamBackBtnClick = function (autoCheckEnabled, peerId) {
        if (autoCheckEnabled) this.gameTableNotificationService.sendRequestAutoCheck(false, peerId);
        else this.gameTableNotificationService.sendRequestAutopostBlindAndFoldOff(peerId);
    };
    sendRequestAutopostBlindAndFoldOff = function (peerId) {
        this.gameTableNotificationService.sendRequestAutopostBlindAndFoldOff(peerId);
    };
    sendRequestPoolSitout = function (entryId) {
        let activeTable;
        this.store.select(miniTablesSelect).subscribe((data) => {
            if (data['miniTables'].length > 0) {
                activeTable = data['miniTables'].filter((item) => item.tableId === entryId);
            }
        });
        this.gameTableNotificationService.sendRequestPoolSitout(entryId, activeTable[0].tableObject.poolID);
    };
    sendBackToPoolRequest = function (entryId) {
        let activeTable;
        this.store.select(miniTablesSelect).subscribe((data) => {
            if (data['miniTables'].length > 0) {
                activeTable = data['miniTables'].filter((item) => item.isActive === true);
            }
        });
        this.gameTableNotificationService.sendBackToPoolRequest(entryId, activeTable[0].tableObject.poolID);
    };
    sendLeavePoolRequest = function (entryId) {
        let activeTable;
        this.store.select(miniTablesSelect).subscribe((data) => {
            if (data['miniTables'].length > 0) {
                activeTable = data['miniTables'].filter((item) => item.tableId === entryId);
            }
        });
        this.gameTableNotificationService.sendLeavePoolRequest(entryId, activeTable[0].tableObject.poolID);
    };
    sendSelectedEmoji = function (emojiId, peerId) {
        this.gameTableNotificationService.sendSelectedEmoji(emojiId, peerId);
    };
    sendCashoutOption = function (tableId, gameId, isSelected) {
        this.gameTableNotificationService.sendCashoutOption(tableId, gameId, isSelected);
    };
    sendRabbitReq = function (peerId, gameId) {
        this.gameTableNotificationService.sendReqEnableRabbit(peerId, gameId);
    };
    sendUnRegisterReq = function (peerId, jackpotId, sngJPInstanceID, participantNo) {
        // window.parent.LSConnector.mainConnector.SNGJPUnRegistrationRequest(peerId);
        /** calling SNGJPUnRegistrationRequest method from spinController*/
        //window.parent.spinController.SNGJPUnRegistrationRequest(peerId, jackpotId, sngJPInstanceID, participantNo);
        /*this.updateTablePlay(peerId);*/
        // this.spinGameService.lobbyUpdate.setReqServerPeerId(peerId);
        this.gameTableNotificationService.SNGJPUnRegistrationRequest(jackpotId, peerId, sngJPInstanceID, participantNo);
    };
    sendReqNextHandOffFoldAndWatch = function (peerId) {
        this.gameTableNotificationService.sendReqNextHandOffFoldAndWatch(peerId);
    };
    sendShowOptionSelected = function (peerId) {
        this.gameTableNotificationService.sendShowOptionSelected(peerId);
    };
    sendMuckOptionSelected = function (peerId) {
        this.gameTableNotificationService.sendMuckOptionSelected(peerId);
    };
    sendDontShowOptionSelected = function (peerId) {
        this.gameTableNotificationService.sendDontShowOptionSelected(peerId);
    };
    sendRequestAutoRebuyChange = function (msg) {
        const tempObj = _.invert(this.tableToEntryMap);
        let peerId = Number(tempObj[msg.rebuyStatus.entryId]);
        if (!peerId) {
            peerId = msg.rebuyStatus.entryId;
        }
        if (msg && msg.rebuyStatus.autoRebuyStatus) {
            this['table' + msg.rebuyStatus.entryId].handleGameServerResponse(msg.className, msg);
            this.gameTableNotificationService.sendRequestAutoRebuyOn(peerId);
        } else {
            this['table' + msg.rebuyStatus.entryId].handleGameServerResponse(msg.className, msg);
            this.gameTableNotificationService.sendRequestAutoRebuyOff(peerId);
        }
    };
    sendRequestAutoPostBlindOn = function (peerId) {
        this.gameTableNotificationService.sendRequestAutoPostBlindOn(peerId);
    };
    sendRequestAutoPostBlindOff = function (peerId) {
        this.gameTableNotificationService.sendRequestAutoPostBlindOff(peerId);
    };
    updateTablePlay = function (peerId, tableType) {
        let spinCardType = 0;
        for (let i = 0; i < this.activeTables.length; i++) {
            if (this.activeTables[i] == peerId) {
                this.splicedTableInd = i;
                this.activeTables.splice(i, 1);
                if (tableType == serverIdConstants.TABLE_TYPE_SPINS) {
                    spinCardType = this['table' + peerId].gameService.SpinInfoModel.spinCardType;
                }
                this['table' + peerId] = null;
                const messageRG = {};
                messageRG['className'] = 'RemoveGameTable';
                messageRG['tableId'] = peerId;
                this.eventDispatcherGlobal.dispatchEvent('gameEventListener', messageRG);
                // let message = {};
                // message["className"] = "showWidget";
                // message["tableId"] = peerId;
                // message["show"] = false;
                // this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
                if (peerId == this.activeTableId) {
                    // this.activeTableId = (this.activeTables.length > 0) ? this.activeTables[this.activeTables.length - 1] : null;
                    const tId = this.activeTables.length > 0 ? this.activeTables[this.activeTables.length - 1] : null;
                    this.setActiveTableId(tId);
                }
                this.updateTablePos();
                break;
            }
        }
        this.setAddNewTableView();
        this.addNewTableView.removeTable(peerId);
        if (window.parent !== null && window.parent !== undefined && this.activeTables.length < 1) {
            this.isLoyaltyCashBackShimmerShowed = false;
            //this.activeTables.length -= 1;
            // window.parent.location.href = '/#/home/spinlobby';
            if (tableType == serverIdConstants.TABLE_TYPE_SPINS) {
                if (spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_ULTRA || spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_NORMAL) {
                    this.navigateToSpinLobby(1);
                } else {
                    this.navigateToSpinLobby(0);
                }
            } else {
                this.navigateToLobby(tableType); // cpmmented below navigation event and using common method for navigations
                // window.parent.dispatchEvent(
                //   new CustomEvent("NAVIGATE_TO_PAGE", { detail: ["home", "spinlobby"] })
                // );
            }
            if (uiConfig.winTableViewFullScaleView === null) {
                this.fullScaleView = true;
                this.expoFullScreen = true;
            } else {
                this.fullScaleView = uiConfig.winTableViewFullScaleView;
                this.expoFullScreen = uiConfig.winTableViewFullScaleView;
                uiConfig.winTableViewFullScaleView = null;
            }
        }
    };
    updateTablePos = function () {
        this.tablePositions = {
            x: [0, window.parent.innerWidth / 2, 0, window.parent.innerWidth / 2],
            y: [0, 0, window.parent.innerHeight / 2, window.parent.innerHeight / 2],
        };
        if (!this.fullScaleView) {
            for (let j = 0; j < this.activeTables.length; j++) {
                this['activeTablePosTween' + this.activeTables[j]] = gsap.to(this['table' + this.activeTables[j]], 0.2, {
                    x: this.tablePositions.x[j],
                    y: this.tablePositions.y[j],
                });
                this.showExpoSpinTileAnimation(j);
            }
        } else {
            //Below code commented to fix mini table getting issue
            //If after test compelete this else block should delete
            /*if (this.activeTableId != null && this.activeTableId != undefined) {
          let message = {};
          message["className"] = "showWidget";
          message["tableId"] = this.activeTableId;
          message["show"] = false;
          this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
        }*/
        }
        this.setAddNewTableView();
    };
    getActiveIframeId = function (tableId) {
        let activeIframe;
        for (let i = 0; i < 4; i++) {
            // let iframe = document.getElementById("expoSpinIframe"+i);
            // if(CommonGameProperties.expoAnime[i].expoSpinAnime.activeStatus==true &&CommonGameProperties.expoAnime[i].expoSpinAnime.tableId==tableId)
            if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == tableId) {
                activeIframe = i;
                break;
            }
        }
        return activeIframe;
    };
    sendRequestLeaveTable = function (tableCategory, peerId) {
        this.gameTableNotificationService.sendRequestLeaveTable(tableCategory, peerId);
    };
    sendRequestCanLeaveTable = function (peerId) {
        this.gameTableNotificationService.sendRequestCanLeaveTable(peerId);
    };
    sendRequestCanSitOut = function (peerId, requestType) {
        this.gameTableNotificationService.sendRequestCanSitOut(peerId, requestType);
    };
    sendRequestAutoPostBlindAndFoldOn = function (peerId) {
        this.gameTableNotificationService.sendRequestAutoPostBlindAndFoldOn(peerId);
    };
    sendAutoSelectedOption = function (autoOptionData, peerId) {
        this.gameTableNotificationService.sendAutoSelectedOption(autoOptionData, peerId);
    };
    sendRequestHUPairTable = function (boolVal, peerId) {
        this.gameTableNotificationService.sendRequestHUPairTable(boolVal, peerId);
    };
    sendRequestAutoCheck = function (autoCheckOn, peerId) {
        this.gameTableNotificationService.sendRequestAutoCheck(autoCheckOn, peerId);
    };
    sendRequestAutoCheckOn = function (peerId) {
        this.gameTableNotificationService.sendRequestAutoCheck(true, peerId);
    };
    sendReqThrowAnimation = function (animationId, toPlayerSeatNo, peerId) {
        this.gameTableNotificationService.sendReqThrowAnimation(animationId, toPlayerSeatNo, peerId);
    };
    sendRequestMuckLosingHand = function (status, peerId) {
        this.gameTableNotificationService.sendRequestMuckLosingHand(status, peerId);
    };
    sendEnableTimeBank = function (optionSetId, timeBankTimeoutAt, timeBankAutoEnabled, peerId) {
        this.gameTableNotificationService.sendEnableTimeBank(optionSetId, timeBankTimeoutAt, timeBankAutoEnabled, peerId);
    };
    switchActiveTable = function (peerId) {
        this.hideExpoSpinsAnimation(this.activeTableId);
        this.removeReplayers();
        this.setActiveTableId(peerId);
        this.maximiseTableView(peerId);
    };
    hideExpoSpinsAnimation(tableId) {
        if (this['table' + tableId].gameService.getGameTableType() === serverIdConstants.TABLE_TYPE_SPINS) {
            const message = {};
            message['className'] = 'hideExpoAnimation';
            message['peerId'] = tableId;
            this.eventDispatcherGlobal.dispatchEvent('hideExpoAnimation', message);
        }
    }
    onResize = function () {
        if (this.table1) this.table1.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_GAME_RESIZE);
        if (this.table2) this.table2.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_GAME_RESIZE);
        if (this.table3) this.table3.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_GAME_RESIZE);
        if (this.table4) this.table4.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_GAME_RESIZE);
    };
    //     getPoolIdForEntry = function (entryId) {
    //       return window.parent.fastForwardController.getPoolIdForEntry(entryId);
    //     }
    getDiamondbalance = function () {
        const diamondBalance = this.sessionDataModel.getDiamondBalance();
        return diamondBalance;
    };
    getAutoOptionToOptionsMappings = function () {
        return this.sessionDataModel.getAutoOptionToOptionsMappings();
    };
    //     sendMessageToSwitcher = function (obj) {
    //       window.parent.LSConnector.mainConnector.sendMessageToSwitcher(obj);
    //     };
    //     showSideMenuReq = function (tableId, gameType, autoRebuyStatus) {
    //       this.sideLeaveTableId = tableId;
    //       switch (gameType) {
    //         case serverIdConstants.TABLE_TYPE_SPINS:
    //           window.parent.dispatchEvent(new CustomEvent("showSideMenu", {
    //             detail: {
    //               from: "Spins",
    //               rebuy: autoRebuyStatus,
    //               tableId
    //             }
    //           }));
    //           break;
    //         case serverIdConstants.TABLE_TYPE_FF:
    //           window.parent.dispatchEvent(new CustomEvent("showSideMenu", {
    //             detail: {
    //               from: "FF",
    //               rebuy: autoRebuyStatus,
    //               tableId
    //             }
    //           }));
    //           break;
    //         case serverIdConstants.TABLE_TYPE_CASH:
    //           window.parent.dispatchEvent(new CustomEvent("showSideMenu", {
    //             detail: {
    //               from: "Cash",
    //               rebuy: autoRebuyStatus,
    //               tableId
    //             }
    //           }));
    //           break;
    //         default:
    //           break;
    //       }
    //     };
    showLoyaltyCashBackPopUp = function () {
        // window.parent.dispatchEvent(new Event('getLoyaltyWeeklyPoints'));
        const message = {};
        // message["className"] = "getLoyaltyWeeklyPoints";
        this.eventDispatcherGlobal.dispatchEvent('showLoyaltyCashBackPopUp', message);
    };
    //     showGenericMessageOnLobby = function (msg) {
    //       window.parent.LSConnector.mainConnector.showGenericMessage(msg);
    //     }
    sendRequestAutoOptionsOptionsMappings = function () {
        this.gameTableNotificationService.sendRequestAutoOptionsOptionsMappings();
    };
    //     getTableViewScale = function () {
    //       let tableViewScaleX = uiConfig.windowWidth / 720;
    //       let tableViewScaleY = uiConfig.windowHeight / 1280;
    //       return Math.min(tableViewScaleX, tableViewScaleY);
    //     }
    //     reCalculatePlayerPos = function () {
    //       let tableViewScale = this.getTableViewScale();
    //       //after maintaining aspect ratio of 720:1280, there would be some gap in x or y
    //       let remainingGapY = uiConfig.windowHeight - tableViewScale * 1280;
    //       let remainingGapX = uiConfig.windowWidth - tableViewScale * 720;
    //       let tableViewHeight = tableViewScale * 1280;
    //       let tableViewWidth = tableViewScale * 720;
    //       for (let seater = 0; seater <= 10; seater++) {
    //         //if there is no such seater, ignore
    //         if (!uiConfig.controlPanel.config.ControlPanelUIDataMPortrait["TableData" + seater + "MaxPos"])
    //           continue;
    //         let playerUIData = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait["TableData" + seater + "MaxPos"].playersUIData;
    //         let playerContainerPos = playerUIData.playerViewContainerPos;
    //         let playerContainerXArr = playerContainerPos.x;
    //         let playerContainerYArr = playerContainerPos.y;

    //         for (let i in playerContainerXArr) {
    //           let xPos = playerContainerXArr[i]; //w.r.t tableView
    //           //xPos = xPos * ((remainingGapX / tableViewScale) + tableViewWidth) / tableViewWidth;
    //           let seatalign = this.getSeatAlignmentforGap(i, seater)
    //           if (seatalign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
    //             //playerContainerXArr[i] = 30;
    //           } else if (seatalign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
    //             // playerContainerXArr[i] = uiConfig.windowWidth - 150;
    //           } else {
    //             //playerContainerXArr[i] = playerContainerXArr[i];
    //           }
    //         }

    //       }
    //     }
    //     getSeatAlignmentforGap = function (clientSeatNo, maxSeats) {
    //       if (clientSeatNo == uiConfig.selfUserClientPosInd)
    //         return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
    //       let centerSeatNo = maxSeats / 2;
    //       if (centerSeatNo == clientSeatNo)
    //         return serverIdConstants.SEAT_ALIGNMENT_TOP;
    //       return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    //     }
    tableClosed = function (tableId) {
        if (this['tableScaleTween' + tableId]) {
            this['tableScaleTween' + tableId].kill();
        }
        if (this['tablePosTween' + tableId]) {
            this['tablePosTween' + tableId].kill();
        }
        if (this['activeTableScaleTween' + tableId]) {
            this['activeTableScaleTween' + tableId].kill();
        }
        if (this['activeTablePosTween' + tableId]) {
            this['activeTablePosTween' + tableId].kill();
        }
        if (this['updateActiveTableScale' + tableId]) {
            this['updateActiveTableScale' + tableId].kill();
        }
        if (this['updateActiveTablePos' + tableId]) {
            this['updateActiveTablePos' + tableId].kill();
        }
        if (this['resizeTablePos' + tableId]) {
            this['resizeTablePos' + tableId].kill();
        }
        if (this['resizeTableDynamicPos' + tableId]) {
            this['resizeTableDynamicPos' + tableId].kill();
        }
    };
    //     updateJackpotInfo = function (tableId, sngjackpotInfo) {
    //       this.addNewTableView.updateJackpotInfo(tableId, sngjackpotInfo);
    //     }
    createTaptoUnmuteImage = function () {
        const tapToUnmuteBG = this.displayUtil.getSprite('tap_to_unmute_bg');
        const tapSoundIcon = this.displayUtil.getSprite('tap_to_unmute_speaker');
        const tapToUnmuteText = this.displayUtil.getTextField(uiConfig.font.props.tapToUnmuteText);
        tapToUnmuteText.text = 'Tap to Unmute';
        if (this.tapToUnmuteContainer) {
            this.dummyContainer.removeChild(this.tapToUnmuteContainer);
            this.tapToUnmuteContainer.destroy();
        }
        this.tapToUnmuteContainer = this.displayUtil.getContainer();
        this.tapToUnmuteContainer.addChild(tapToUnmuteBG);
        this.tapToUnmuteContainer.addChild(tapSoundIcon);
        this.tapToUnmuteContainer.addChild(tapToUnmuteText);
        this.dummyContainer.addChild(this.tapToUnmuteContainer);
        tapToUnmuteBG.anchor.set(0.5);
        tapToUnmuteText.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.tapToUnmuteContainer, window.innerWidth / 2, window.innerHeight - 110);
        this.displayUtil.setObjectPosition(tapSoundIcon, -67, 5);
        this.displayUtil.setObjectPosition(tapToUnmuteText, 10, 15);
        this.displayUtil.setObjectPosition(tapToUnmuteBG, 0, tapToUnmuteText.y);
        if (tapToUnmuteText.height > tapSoundIcon.height) {
            tapToUnmuteBG.height = tapToUnmuteText.height + 5;
        } else {
            tapToUnmuteBG.height = tapToUnmuteText.height + 8;
        }
    };
    kFormatter = function kFormatter(num): any {
        const math: any = (Math.abs(num) / 1000).toFixed(1);
        return Math.abs(num) > 999 ? Math.sign(num) * math + 'k' : Math.sign(num) * Math.abs(num);
    };
    getPDollerData = function () {
        let balance = {};
        this.store.select(userBalanceSelect).subscribe((userBalance) => {
            if (!_.isEmpty(userBalance) && !_.isEmpty(userBalance['netRealBalance']) && !_.isEmpty(userBalance['tourneyCurrencyBalance'])) {
                balance = userBalance;
            }
        });
        return balance;
    };

    //     muteSoundsOnWinScreen = function (peerId) {
    //         this["table" + peerId].eventDispatcher.dispatchEvent(SlotCoreGameEvent.MUTE_SOUNDS);
    //     }
    //     // handling terms and conditions for replayer
    //     displayTandC = function () {
    //         // let currenttable = "table" + this.gameService.getPeerId();
    //         // this.tablePositions = {
    //         //     x: [0, uiConfig.windowWidth / 2, 0, uiConfig.windowWidth / 2],
    //         //     y: [0, 0, uiConfig.windowHeight / 2, uiConfig.windowHeight / 2]
    //         // };
    //         // let height = "calc(100% - 60px)", width = "100%";
    //         // let tableindex:any;
    //         // let minHeight:any;
    //         // let minWidth:any;
    //         // if (this[currenttable].scaleX == 0.5) {
    //         //     tableindex = this.activeTables.indexOf(this.gameService.getPeerId());
    //         //     let posX = this.tablePositions.x[tableindex] + "px",
    //         //         posY = this.tablePositions.y[tableindex] + "px",
    //         //         minHeight = uiConfig.windowHeight / 2 + "px",
    //         //         minWidth = uiConfig.windowWidth / 2 + "px";
    //         // }
    //         // let sessionKey = window.parent.AppDataModel.getSsokey();
    //         // if (window.parent.AppDataModel) {
    //         //     let tncURL = window.parent.AppDataModel.getUrlData(sessionKey, "termsAndConditionsURL");
    //         // }
    //         // if (tncURL) {
    //         //     let self = this;
    //         //     // start loader
    //         //     this.loaderframe = document.createElement("img");
    //         //     this.loaderframe.setAttribute("src", "../../assets/lobbyAssets/loader_replayer.svg");

    //         //     this.loaderframe.setAttribute("style", "position: absolute;z-index: 100;");
    //         //     this.loaderframe.setAttribute("id", "loaderframe" + currenttable); // assign an id
    //         //     this.loaderframe.width = 100;
    //         //     this.loaderframe.height = 100;
    //         //     if (this[currenttable].scaleX == 0.5) {
    //         //         this.loaderframe.style.left = this.tablePositions.x[tableindex] + uiConfig.windowWidth / 4 - this.loaderframe.width / 2 + "px";
    //         //         this.loaderframe.style.top = this.tablePositions.y[tableindex] + uiConfig.windowHeight / 4 - this.loaderframe.height / 2 + "px";
    //         //     } else {
    //         //         this.loaderframe.style.left = uiConfig.windowWidth / 2 - this.loaderframe.width / 2 + "px";
    //         //         this.loaderframe.style.top = uiConfig.windowHeight / 2 - this.loaderframe.height / 2 + "px";
    //         //     }
    //         //     document.body.appendChild(this.loaderframe);
    //         //     // end loader
    //         //     this.replayerIframe = document.createElement("iframe");
    //         //     this.replayerIframe.setAttribute("id", "replayerIframe" + currenttable); // assign an id
    //         //     // assign url
    //         //     this.replayerIframe.setAttribute("src", tncURL);
    //         //     document.body.appendChild(this.replayerIframe); // to place at end of document

    //         //     this.closereplayerIframe = document.createElement("img");
    //         //     this.closereplayerIframe.setAttribute("src", "../../assets/games/poker/ivypoker/images/close.png");
    //         //     this.closereplayerIframe.setAttribute("id", "closeReplayer" + currenttable);
    //         //     if (this[currenttable].scaleX == 0.5) {
    //         //         this.replayerIframe.setAttribute("style",
    //         //             "position: absolute;border:0;top: 60px;z-index:1;background-color:#000000"
    //         //         );
    //         //         this.replayerIframe.style.height = minHeight;
    //         //         this.replayerIframe.style.width = minWidth;
    //         //         this.replayerIframe.style.left = posX;
    //         //         this.replayerIframe.style.top = posY;
    //         //         this.closereplayerIframe.setAttribute("style",
    //         //             "display: block; position: absolute;top: 60px;width: 5%;height: 3%;z-index:50");
    //         //         let btnLeft = this.tablePositions.x[tableindex] + (uiConfig.windowWidth / 2) - (uiConfig.windowWidth / 2) * (10 / 100) + "px";
    //         //         let btnTop = this.tablePositions.y[tableindex] + "px";
    //         //         this.closereplayerIframe.style.left = btnLeft;
    //         //         this.closereplayerIframe.style.top = btnTop;
    //         //     } else {
    //         //         this.replayerIframe.setAttribute("style",
    //         //             "position: absolute;border:0;top: 60px;height:calc(100% - 60px);width:100%;z-index:1;background-color:#000000");
    //         //         this.closereplayerIframe.setAttribute("style",
    //         //             "display: block; position: absolute;top: 60px;width: 10%;height: 6%;right:0%;z-index:50");
    //         //     }

    //         //     document.body.appendChild(this.closereplayerIframe);
    //         //     this.closereplayerIframe.onclick = function (e) {
    //         //         let tableid = this.id.split("closeReplayer")[1];
    //         //         console.log("replayer closed clicked");
    //         //         document.getElementById("replayerIframe" + tableid).remove();
    //         //         document.getElementById("closeReplayer" + tableid).remove();
    //         //         if (document.getElementById("loaderframe" + tableid) != null) {
    //         //             document.getElementById("loaderframe" + tableid).remove();
    //         //         }
    //         //     };
    //         //     document.getElementById("replayerIframe" + currenttable).onload = function () {
    //         //         let tableid = this.id.split("replayerIframe")[1];
    //         //         document.getElementById("loaderframe" + tableid).remove();
    //         //     };
    //         // }
    //     };
    //     checkCashGameSitoutMltyTbl = function(){
    //       let sitoutCount = 0;
    //       for (let i = 0; i < this.activeTables.length; i++) {
    //         let gameTableType = this["table" + this.activeTables[i]].gameService.getGameTableType();
    //         let noOfSeats = this.gameService.getMaxNoOfSeats();
    //          if(gameTableType == serverIdConstants.TABLE_TYPE_CASH && noOfSeats > 2)
    //          {
    //           if (this["table"+this.activeTables[i]].playerView.playersArray[0].activePlaterStatus != true)
    //           {
    //             sitoutCount += 1;
    //           } else if(this["table"+this.activeTables[i]].controlPanelView.iamBackContainer.visible == true)
    //           {
    //             sitoutCount += 1;
    //           } else if (this["table"+this.activeTables[i]].playerView.playersArray[0].alpha != 1)
    //           {
    //             sitoutCount += 1;
    //           }
    //          }
    //       }
    //       return sitoutCount > 1 ? true : false;
    //     };
    //     sitinAllTbl = function(){
    //       for (let i = 0; i < this.activeTables.length; i++) {
    //           let playerStaus = this["table"+ this.activeTables[i]].playerView.playersArray[0].activePlaterStatus;
    //           let gameTableType = this["table" + this.activeTables[i]].gameService.getGameTableType();
    //           if(playerStaus == false && gameTableType == serverIdConstants.TABLE_TYPE_CASH && this["table"+ this.activeTables[i]].playerView.playersArray.length > 2)
    //           {
    //             this["table" + this.activeTables[i]].eventDispatcher.dispatchEvent(SlotCoreGameEvent.IAM_BACK_CLICKED);
    //           }
    //         }
    //     };
}
