import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as plf from './poker-leaderboard-feed.component';

@Injectable({ providedIn: 'root' })
export class PokerLeaderboardFeedPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('leaderboardfeedtemplate', plf.PokerLeaderboardFeedComponent);
        });
    }
}
