import { Injectable } from '@angular/core';

import { GameTable } from '../models/game-table';
import { HHParser } from '../utils/hhparser';

@Injectable({ providedIn: 'root' })
export class GameService {
    private _gameTable: any = new GameTable();
    private _response: any;
    private _PROPERTIES: any;
    private _content: any;
    private _isInitialRequest: any;
    private _isMobile: any;
    private _isReplayerV3: boolean;

    get gameTable(): GameTable {
        this._gameTable = HHParser.parse(this._response, this._PROPERTIES, this._content, this.isInitialRequest, this.isMobile, this.isReplayerV3);
        return this._gameTable;
    }

    set gameTable(value: GameTable) {
        this._gameTable = value;
    }

    get response() {
        return this._response;
    }

    set response(value: any) {
        this._response = value;
    }

    get PROPERTIES() {
        return this._PROPERTIES;
    }

    set PROPERTIES(value: any) {
        this._PROPERTIES = value;
    }

    get content() {
        return this._content;
    }

    set content(value: any) {
        this._content = value;
    }

    get isInitialRequest() {
        return this._isInitialRequest;
    }
    set isInitialRequest(value: any) {
        this._isInitialRequest = value;
    }

    isReplayerOnInit(value: any) {
        this.isInitialRequest = value;
    }

    get isReplayerV3() {
        return this._isReplayerV3;
    }

    set isReplayerV3(value: boolean) {
        this._isReplayerV3 = value;
    }

    get isMobile() {
        return this._isMobile;
    }
    set isMobile(value: any) {
        this._isMobile = value;
    }
}
