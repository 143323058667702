import { Message } from '../../message';

export class LSTourneySyncAddonBreakTimeChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneySyncAddonBreakTimeChange';
    private nextBreakTime: number = 0;
    private nextLevelValue: number = 0;
    private levelChangeType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, nextBreakTime: number = 0, nextLevelValue: number = 0, levelChangeType: number = 0) {
        super(reqServerPeerId);
        this.nextBreakTime = nextBreakTime;
        this.nextLevelValue = nextLevelValue;
        this.levelChangeType = levelChangeType;
    }

    getNextBreakTime(): number {
        return this.nextBreakTime;
    }

    setNextBreakTime(nextBreakTime: number) {
        this.nextBreakTime = nextBreakTime;
    }
    getNextLevelValue(): number {
        return this.nextLevelValue;
    }

    setNextLevelValue(nextLevelValue: number) {
        this.nextLevelValue = nextLevelValue;
    }
    getLevelChangeType(): number {
        return this.levelChangeType;
    }

    setLevelChangeType(levelChangeType: number) {
        this.levelChangeType = levelChangeType;
    }
}
