import { PGMessageFactoryRegistration } from './PGMessageFactoryRegistration';
import { TSDataOutputStream } from './dataInputOutputStream/TSDataOutputStream';
import { FrameEncoder } from './frameEncoder';
import { MessageFactoryManager } from './messageFactoryManager';

export class MessageEncoder {
    private messageFactory: MessageFactoryManager;
    private msgFactryReg: PGMessageFactoryRegistration;

    constructor(msgFactryReg: PGMessageFactoryRegistration) {
        this.msgFactryReg = msgFactryReg;
        this.messageFactory = new MessageFactoryManager(this.msgFactryReg);
    }

    convertMessageToByteStream(message: any) {
        message.settopLevelMessage(true);
        // const isBCEnabled = this.connectionManager.getIfBackwardCompatibilityEnabled();
        let msgStream: TSDataOutputStream | null = new TSDataOutputStream(this.msgFactryReg); //isBCEnabled
        this.messageFactory.messageWrite(message, msgStream);
        // Serialize the message.
        message.serializedBytes = msgStream.getBytes();
        message.serializedBytes.flip();
        message.classId = this.messageFactory.getClassIdforClass(message);
        // message.receiverId = message.reqServerPeerId;
        const frameBuffer = FrameEncoder.encode(message);
        delete message.serializedBytes;
        msgStream = null;
        return frameBuffer;
    }
    getClass(obj: any): string {
        return obj.constructor;
    }
}
