export class initDataModel {
    handHistoryContent: any;
    gameRoundMetaData: any;
}

export class HandHistoryInputModel {
    RoundNumber: string;
    GameType: number;
    GameCodeType: number;
    LimitType: number;
    MonetaryType: number;
    MinStake: number;
    MaxStake: number;
    Time: Date;

    /* Player Actions */
    WonHand: boolean;
    AllIn: boolean;
    PreflopRaise: boolean;
    PreflopCall: boolean;
    ShowHand: boolean;
    RiverFold: boolean;
    WereKnockedOut: boolean;
    KnockedOutOthers: boolean;

    MinPotSize: number;
    FinalHandMatch: number;
    FinalHand: number;
    PocketCardSuited: boolean;
    PocketCardOne: string;
    PocketCardTwo: string;
    SearchDate: string;
    Page: number;

    SortAscending: boolean;
    SortBy: number;
    StakeType: number;
    BuyInType: number;

    //Tournament search
    MinBuyIn: number;
    MaxBuyIn: number;

    TournamentType: number;
    TournamentGameType: number;
    TournamentSearchDate: string;

    TournamentPocketCardSuited: boolean;
    TournamentPocketCardOne: string;
    TournamentPocketCardTwo: string;

    TournamentFinalHandMatch: number;
    TournamentFinalHand: number;

    TournamnetMinPotSize: number;
}

export class HandHistoryRoundInputModel {
    RoundId: string;
}
