import { Message } from '../../message';
import { UserProfile } from './UserProfile';

export class UserInfo extends Message {
    private MESSAGE_NAME: string = 'UserInfo';
    private profile: UserProfile | null = null;
    private opcode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, profile: UserProfile | null = null, opcode: number = 0) {
        super(reqServerPeerId);
        this.profile = profile;
        this.opcode = opcode;
    }

    getProfile(): UserProfile | null {
        return this.profile;
    }

    setProfile(profile: UserProfile | null) {
        this.profile = profile;
    }
    getOpcode(): number {
        return this.opcode;
    }

    setOpcode(opcode: number) {
        this.opcode = opcode;
    }
}
