import { Message } from '../../message';

export class ResponseBuyMoreChipsLossLimitExceeded extends Message {
    private MESSAGE_NAME: string = 'ResponseBuyMoreChipsLossLimitExceeded';
    private errorCode: number = 0;
    private desc: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, errorCode: number = 0, desc: any | null = null) {
        super(reqServerPeerId);
        this.errorCode = errorCode;
        this.desc = desc;
    }

    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
}
