import { Message } from '../../message';

export class SNGJPInstanceCardDeleteEvent extends Message {
    private MESSAGE_NAME: string = 'SNGJPInstanceCardDeleteEvent';
    private observerCardId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, observerCardId: number = 0) {
        super(reqServerPeerId);
        this.observerCardId = observerCardId;
    }

    getObserverCardId(): number {
        return this.observerCardId;
    }

    setObserverCardId(observerCardId: number) {
        this.observerCardId = observerCardId;
    }
}
