import { Message } from '../../message';
import { GameServerProperties } from '../../pgSharedMessageFactory/messages/GameServerProperties';

export class TableTransfer extends Message {
    private MESSAGE_NAME: string = 'TableTransfer';
    private transferredServer: GameServerProperties | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, transferredServer: GameServerProperties | null = null) {
        super(reqServerPeerId);
        this.transferredServer = transferredServer;
    }

    getTransferredServer(): GameServerProperties | null {
        return this.transferredServer;
    }

    setTransferredServer(transferredServer: GameServerProperties | null) {
        this.transferredServer = transferredServer;
    }
}
