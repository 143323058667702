import { Routes } from '@angular/router';

import { PokerCashGamesSynopsisResolver } from './poker-cashgames-synopsis.resolver';
import { PokerCashGamesResolver } from './poker-cashgames.resolver';

export const pokerCashGamesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerCashGamesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerCashGamesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerCashGamesResolver,
        },
    },
];
