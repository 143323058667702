import { Message } from '../../message';

export class LSTourneyRegistrationChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyRegistrationChange';
    private noOfParticipants: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, noOfParticipants: number = 0) {
        super(reqServerPeerId);
        this.noOfParticipants = noOfParticipants;
    }

    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
}
