import { LabelType, Options } from '@angular-slider/ngx-slider';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
//import { LabelHostModule } from '@labelhost/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DeviceService, TrackingService } from '@frontend/vanilla/core';
import { PlainLinkComponent } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { TournamentsCalendarClientConfig } from '@pokercore/module/core';
import { NavigationBarComponent, NavigationBarPokerComponent, ScrollToTopComponent, TournamentCalendarPagerService } from '@pokercore/module/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSliderModule } from 'ngx-slider-v2';

import { limitNamePipe } from './LimitName.pipe';
import { removeColorCodesPipe } from './RemoveColorCodes.pipe';
import { formatCurrencyPipe } from './formatCurrency.pipe';
import { gameNamePipe } from './gameName.pipe';
import { TournamentCalendarTimerComponent } from './tournament-calendar-timer/tournament-calendar-timer.component';
import { TournamentDetailsComponent } from './tournament-details.component';
import { TournamentCalendarComponent } from './tournamentcalendar.component';
import { TournamentCalendarResolver } from './tournamentcalendar.resolver';
import { TournamentCalendarService } from './tournamentcalendar.service';
import { WindowScrollingService } from './window-scrolling.service';

@Component({
    selector: 'pt-tournament-calendar-filters',
    templateUrl: 'tournament-calendar-filters.component.html',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [style({ transform: 'translateY(0%)' }), animate('0ms ease-in', style({ transform: 'translateY(0%)' }))]),
            transition(':leave', [animate('0ms ease-in', style({ transform: 'translateY(0%)' }))]),
        ]),
    ],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TrustAsHtmlPipe,
        MessagePanelComponent,
        PlainLinkComponent,
        FormsModule,
        NgxDatatableModule,
        NavigationBarComponent,
        NavigationBarPokerComponent,
        TournamentCalendarTimerComponent,
        NgxSliderModule,
        ScrollToTopComponent,
        TournamentCalendarTimerComponent,
        NgxSliderModule,
        ScrollToTopComponent,
        TournamentCalendarFiltersComponent,
        TournamentDetailsComponent,
        TournamentCalendarComponent,
        formatCurrencyPipe,
        gameNamePipe,
        limitNamePipe,
        removeColorCodesPipe,
    ],
    providers: [TournamentCalendarResolver, TournamentCalendarService, TournamentCalendarPagerService, WindowScrollingService],
})
export class TournamentCalendarFiltersComponent implements OnInit {
    gameTypeDetails: any;
    hideRunning: boolean = false;
    GameTypeValue: string = 'AllGames';
    pageNumber: number = 1;
    selectedBuyIn: string = 'Interval0';
    buyInMin: number;
    buyInMax: number;
    BuyInMin: number;
    BuyInMax: number;
    BuyInSteps: number;
    hideSlider: boolean;
    totalTournaments: number;
    requestParams: any = {};
    formElementTemplateChilds: any;
    filteredTournamentList: Array<any>;
    currentFiltervalues: any;
    EnableAndDisable: boolean = false;
    options: Options;
    pagedTournamentItemsList: any;
    monetaryType: string = '';
    visibleGame: boolean = true;
    visibleBuyIn: boolean = false;
    pmPokerNavigationView: any;
    @Output() onFilterClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public tournamentCalendarService: TournamentCalendarService,
        private tournamentsCalendarClientConfig: TournamentsCalendarClientConfig,
        private windowScrollingService: WindowScrollingService,
        private deviceService: DeviceService,
        private tracking: TrackingService,
    ) {}
    ngOnInit() {
        this.tournamentsCalendarClientConfig.whenReady.subscribe(() => {
            this.BuyInMax = this.tournamentsCalendarClientConfig.buyInMax;
            this.BuyInMin = this.tournamentsCalendarClientConfig.buyInMin;
            this.BuyInSteps = this.tournamentsCalendarClientConfig.buyInSliderSteps;
            this.hideSlider = this.tournamentsCalendarClientConfig.hideBuyInSlider;
        });

        this.currentFiltervalues = this.tournamentCalendarService.currentFiltervalues;
        this.pmPokerNavigationView = this.tournamentCalendarService.getPmPokerNavigationView();
        this.pagedTournamentItemsList = this.tournamentCalendarService.getinitialpagedTournamentItemsList();
        if (this.pagedTournamentItemsList) {
            if (this.pagedTournamentItemsList.length > 0) {
                this.monetaryType = this.pagedTournamentItemsList[0].buyIn.monetaryType;
            }
        }

        if (this.deviceService.currentOrientation == 'landscape') {
            this.visibleBuyIn = true;
        }
        if (this.tournamentCalendarService.pageBack) {
            this.EnableAndDisable = this.tournamentCalendarService.enableAndDisable;
            this.tournamentCalendarService.pageBack = false;
        }
        if (!this.hideSlider) {
            this.buyInMin = this.BuyInMin;
            this.buyInMax = this.BuyInMax + this.BuyInSteps;
            if (this.currentFiltervalues && this.currentFiltervalues.buyInMax && this.currentFiltervalues.buyInMax != 0) {
                this.buyInMax = this.currentFiltervalues.buyInMax;
                this.buyInMin = this.currentFiltervalues.buyInMin;
            }
            this.options = {
                floor: this.BuyInMin,
                ceil: this.BuyInMax + this.BuyInSteps,
                step: this.BuyInSteps,
                translate: (value: number, label: LabelType): string => {
                    switch (label) {
                        case LabelType.Low:
                            if (value == 0) return '<$1';
                            if (value == 105) return '>$100';
                            return '';
                        case LabelType.High:
                            if (value == 105) return '>$100';
                            if (value == 0) return '<$1';
                            return '';
                        default:
                            if (value > 100) return '>$100';
                            if (value == 0) return '<$1';
                            return '$' + value;
                    }
                },
            };
        }
        this.formElementTemplateChilds = this.tournamentCalendarService.getFormElementTemplateChilds();
        this.gameTypeDetails = this.formElementTemplateChilds['1'].values;
        let selectedGameType: string = 'AllGames';
        if (this.currentFiltervalues && this.currentFiltervalues.gameType && this.currentFiltervalues.gameType != '') {
            selectedGameType = this.currentFiltervalues.gameType;
            this.GameTypeValue = this.currentFiltervalues.gameType;
        }

        for (let i = 0; i < this.gameTypeDetails.length; i++) {
            if (this.gameTypeDetails[i].value === selectedGameType) {
                this.gameTypeDetails[i].checked = true;
            } else {
                this.gameTypeDetails[i].checked = false;
            }
        }
        this.EnableAndDisable = !(
            this.GameTypeValue == 'AllGames' &&
            this.buyInMax == this.BuyInMax + this.BuyInSteps &&
            this.buyInMin == this.BuyInMin
        );
    }

    sliderChange() {
        this.EnableAndDisable =
            !(this.GameTypeValue == 'AllGames') || !(this.buyInMax == this.BuyInMax + this.BuyInSteps) || !(this.buyInMin == this.BuyInMin);
    }

    closeFilters() {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournament Page Filter',
            'component.ActionEvent': 'Close CTA - Clicked',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'Tournament Page Filter',
            'component.EventDetails': 'User clicks on Close X CTA ',
            'component.URLClicked': '{URL}',
        });
        this.onFilterClose.emit(false);
    }

    visibleGameFunc() {
        this.visibleGame = !this.visibleGame;
    }
    visibleBuyInFunc() {
        this.visibleBuyIn = !this.visibleBuyIn;
    }
    hideRunnungOrFinished() {
        this.hideRunning = !this.hideRunning;
    }
    filterTournamentListByGame(gameType: string) {
        this.GameTypeValue = gameType;
        for (let i = 0; i < this.gameTypeDetails.length; i++) {
            if (this.gameTypeDetails[i].value === gameType) {
                this.gameTypeDetails[i].checked = true;
            } else {
                this.gameTypeDetails[i].checked = false;
            }
        }
        this.EnableAndDisable =
            !(this.GameTypeValue == 'AllGames') || !(this.buyInMax == this.BuyInMax + this.BuyInSteps) || !(this.buyInMin == this.BuyInMin);
    }
    filterTournamentListByBuyIn(buyIn: string) {
        this.selectedBuyIn = buyIn;
    }
    fetchFilteredTournaments(gameType: string, buyInMin: number, buyInMax: number, hiddenval: boolean, page: number) {
        this.requestParams = {
            gameType: gameType,
            buyInMin: buyInMin,
            buyInMax: buyInMax,
            hideRunning: hiddenval,
            pageNumber: page,
        };
        this.tournamentCalendarService.getFilteredData(this.requestParams, this.EnableAndDisable);
    }
    submitFilters() {
        this.fetchFilteredTournaments(this.GameTypeValue, this.buyInMin, this.buyInMax, this.hideRunning, this.pageNumber);
        (document.getElementById('filters-info') as HTMLElement).style.display = 'none';
        this.windowScrollingService.enable();
        if (this.deviceService.isMobile) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Tournaments',
                'component.LabelEvent': 'Tournament Page Filter',
                'component.ActionEvent': 'Show Filter Results CTA - Clicked',
                'component.PositionEvent': 'not applicable',
                'component.LocationEvent': 'Tournament Page Filter',
                'component.EventDetails': 'User clicks on Show results CTA',
                'component.URLClicked': '{URL}',
            });
        } else {
            //if it is desktop
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Tournaments',
                'component.LabelEvent': 'Tournaments - Filters',
                'component.ActionEvent': 'Filter - Applied',
                'component.PositionEvent': this.pmPokerNavigationView.messages['text-game-types'],
                'component.LocationEvent': 'not applicable',
                'component.EventDetails': this.GameTypeValue,
                'component.URLClicked': 'not applicable',
            });
        }
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournaments - Filters',
            'component.ActionEvent': 'Filter - Applied',
            'component.PositionEvent': 'Buy-Ins',
            'component.LocationEvent': 'User moves left side filter in slider',
            'component.EventDetails': this.buyInMin,
            'component.URLClicked': 'not applicable',
        });
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournaments - Filters',
            'component.ActionEvent': 'Filter - Applied',
            'component.PositionEvent': 'Buy-Ins',
            'component.LocationEvent': 'User moves right side filter in slider',
            'component.EventDetails': this.buyInMax,
            'component.URLClicked': 'not applicable',
        });
    }
    resetFilters() {
        const defaultValues: any = this.tournamentCalendarService.getInitialDefaultFilterValues();
        for (let i = 0; i < this.gameTypeDetails.length; i++) {
            if (defaultValues.GameTypeValue === this.gameTypeDetails[i].value) {
                this.gameTypeDetails[i].checked = true;
            } else {
                this.gameTypeDetails[i].checked = false;
            }
        }
        this.GameTypeValue = defaultValues.GameTypeValue;
        this.selectedBuyIn = defaultValues.selectedBuyIn;
        this.hideRunning = defaultValues.hideRunning;
        this.buyInMin = this.BuyInMin;
        this.buyInMax = this.BuyInMax + this.BuyInSteps;
        this.EnableAndDisable = false;

        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournament Page Filter',
            'component.ActionEvent': 'Reset Filter Results CTA - Clicked',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'Tournament Page Filter',
            'component.EventDetails': 'user Clicks on Reset Filters CTA',
            'component.URLClicked': '{URL}',
        });
    }
}
