/*
Messages Flow: (Server -> Client)
1. PlayerStatus: [with updated status of all players]
2. AddPlayer: to add a specific player at seatNo
3. RemovePlayer: to remove/Kickout a specific player from gameTable
4. PotWinners: to update pot winners of every hand.
5. PlayerBalances: to update balances of all players
6. PlayerBalance: to update balance of a single player

*/
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';

export class CashoutEquityParentViewModel {
    gameService: any;
    constructor(gs) {
        this.gameService = gs;
    }

    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CASHOUT_ANIM, this.showCashoutAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OPPONENT_CASHOUT_ANIM, this.opponentCashoutAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_CASHOUT_DISABLED, this.onCashOutDisabled.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.REMOVE_CASHOUT_DISABLED_TEXT, this.removeCashoutDisabled.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CASHOUT_WAIT_TEXT, this.showCashoutWaitMsg.bind(this));
    };

    opponentCashoutAnim = function (data) {
        const playerSeat = this.gameService.getClientSideSeatNo(data.detail.seatNo);
        if (playerSeat !== uiConfig.selfUserClientPosInd) this.view.cashOutAnim(playerSeat);
    };
    showCashoutAnim = function () {
        this.view.cashOutAnim(uiConfig.selfUserClientPosInd);
    };
    onCashOutDisabled = function () {
        this.view.onCashOutDisabled();
    };
    showCashoutWaitMsg = function () {
        this.view.showCashoutWaitMsg();
    };
    removeCashoutDisabled = function () {
        this.view.removeCashoutDisabled();
    };
    resetGame = function () {
        this.view.removeAnimations();
    };
}
