import { Message } from '../../message';

export class PlayersBountyWonInfo extends Message {
    private MESSAGE_NAME: string = 'PlayersBountyWonInfo';
    private boountyAmountWon: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, boountyAmountWon: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.boountyAmountWon = boountyAmountWon;
    }

    getBoountyAmountWon(): Map<any, any> | null {
        return this.boountyAmountWon;
    }

    setBoountyAmountWon(boountyAmountWon: Map<any, any> | null) {
        this.boountyAmountWon = boountyAmountWon;
    }
}
