import { InjectionToken, NgModuleFactory, Type } from '@angular/core';

export interface PokerLazyModules {
    [key: string]: { loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> };
}

export const pokerlazyMap: PokerLazyModules = {
    PokerTwitchFeedLWComponent: {
        loadChildren: () =>
            import(/* webpackChunkName: "pk-twitch-feed-lazy-widget-chunk" */ '@pokercore/module/poker-twitch-feed-lazy-widget').then(
                (t) => t.PokerTwitchFeedLWModule,
            ),
    },
    PokerUpcomingTornamentsLWComponent: {
        loadChildren: () =>
            import(
                /* webpackChunkName: "pk-upcoming-tournaments-lazy-widget-chunk" */ '@pokercore/module/poker-upcoming-tournaments-lazy-widget'
            ).then((u) => u.PokerUpcomingTournamentsLWModule),
    },
    PPBlogFeedLWComponent: {
        loadChildren: () =>
            import(/* webpackChunkName: "pk-pp-blog-feed-lazy-widge-chunk" */ '@pokercore/module/pp-blog-feed-lazy-widget').then(
                (pbf) => pbf.PPBlogFeedLWModule,
            ),
    },
};

export const POKER_LAZY_MODULES_MAP = new InjectionToken('POKER_LAZY_MODULES_MAP', {
    factory: () => pokerlazyMap,
});
