import { Message } from '../../message';

export class ChangeAvatar extends Message {
    private MESSAGE_NAME: string = 'ChangeAvatar';
    private avatarId: number = 0;
    private isCustomAvatar: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, avatarId: number = 0, isCustomAvatar: boolean | null = false) {
        super(reqServerPeerId);
        this.avatarId = avatarId;
        this.isCustomAvatar = isCustomAvatar;
    }

    getAvatarId(): number {
        return this.avatarId;
    }

    setAvatarId(avatarId: number) {
        this.avatarId = avatarId;
    }
    getIsCustomAvatar(): boolean | null {
        return this.isCustomAvatar;
    }

    setIsCustomAvatar(isCustomAvatar: boolean | null) {
        this.isCustomAvatar = isCustomAvatar;
    }
}
