import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { JackpotTickerService } from './jackpot-ticker.service';

@Injectable({ providedIn: 'root' })
export class JackpotTickerResolver implements Resolve<any> {
    constructor(private resource: JackpotTickerService) {}

    resolve(): Observable<any> {
        return this.resource.getAll();
    }
}
