import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MyTicketsService {
    constructor(private api: PokerApiService) {}

    getAllTickets(): Observable<any> {
        return this.api.get(
            'tournamenttickets/GetInitData?tournamentCategories=0&tournamentCategories=1&tournamentCategories=2&tournamentCategories=3',
            { showSpinner: true },
        );
    }

    getTicketDetails(ticketId: string): Observable<any> {
        return this.api.get('tournamenttickets/GetTournamentTicketInformation', { TicketId: ticketId }, { showSpinner: true });
    }
}
