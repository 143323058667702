import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';

import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class ControlPanelView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    constructor() {
        super();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }
    createView = function (parentViewModel, EventDispatcher, gsd) {
        this.tableSettingsDataModel = gsd;
        this.EventDispatcher = EventDispatcher;
        this.potOptionButtons = [];
        this.buttonLabelText = {
            fontFamily: 'arial',
            fontSize: 24,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        };
        this.selfUserPos = uiConfig.selfUserClientPosInd;
        this.fontProps = uiConfig.font.props;
        this.autoOptionsSelectedStateArr = [];
        this.potOptionsSelectedStateArr = [];
        this.selectedPotOption = [];
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            portAlignX: 'CENTER',
            portAlignY: 'BOTTOM',
            EventDispatcher: this.EventDispatcher,
        });
        this.blackBg = this.displayUtil.getRectangleSprite(
            (window.innerWidth * 1.95) / this.scale.x,
            (window.innerHeight * 1.95) / this.scale.y,
            '#000000',
        );
        this.displayUtil.setObjectPosition(this.blackBg, -this.x / this.scale.x, -this.y / this.scale.y);
        this.blackBg.on('touchstart', this.blackBgTouchStart.bind(this));
        this.blackBg.on('touchend', this.blackBgTouchEnd.bind(this));
        this.addChild(this.blackBg);
        this.blackBg.alpha = 0.4;
        this.blackBg.visible = false;
        this.dragMove = false;
        this.blackBg.buttonMode = false;
        this.displayUtil.setButtonActive(this.blackBg, true);
        this.parentViewModel = parentViewModel;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.buttons;
        const buttons = this.positionConfig.buttonsList;
        this.potOptions = [];
        const showOptions = this.positionConfig.showButtonsList;
        this.potOptionsDeciVal = [];
        this.buttonsContainer = this.displayUtil.getContainer();
        this.addChild(this.buttonsContainer);
        this.buttonsContainer.visible = true;
        this.createButtons(buttons);
        this.createIamBack();
        this.optionsConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.autoOptionsData;
        const autoOptions = this.positionConfig.autoButtonsList;
        this.autoOptionsContainer = this.displayUtil.getContainer();
        this.autoOptionsContainer.name = 'autoOptions';
        this.addChild(this.autoOptionsContainer);
        const autoOptionsBtns = true;
        this.createButtons(autoOptions, autoOptionsBtns);
        this.createShowOptions(showOptions);
        this.raiseContainer = this.displayUtil.getContainer();
        this.raiseContainer.name = 'RaiseContainer';
        this.addChild(this.raiseContainer);
        this.createTimerAnim();
        this.potAmountBg = this.displayUtil.getBorderRoundRectangleSprite(180, 69, 0x000000, 10, true, 2, 0x616161, 0.5);
        this.addChild(this.potAmountBg);
        this.potAmountBg.name = 'potAmountBg';
        this.displayUtil.setObjectPosition(this.potAmountBg, 279, 335);
        this.potAmountBg.scale.x = 0.9;
        this.potAmountBg.scale.y = 1.1;
        this.potAmountForRaise = this.displayUtil.getTextField(this.fontProps.tablePotText);
        this.potAmountForRaise.name = 'potAmountForRaise';
        this.potAmountBg.addChild(this.potAmountForRaise);
        this.displayUtil.setObjectPosition(this.potAmountForRaise, 88, 34);
        this.potAmountForRaise.anchor.set(0.5);
        this.potAmountForRaise.scale.set(0.8);
        this.potAmountBg.visible = false;
        this.hideAllButtons();
        this.isCashoutBtnClicked = false;
    };
    blackBgTouchStart = function (events) {
        if (events.data.originalEvent.touches && events.data.originalEvent.touches.length <= 1) {
            this.backBgTouchStarted = true;
        }
    };
    blackBgTouchEnd = function () {
        if (this.backBgTouchStarted) {
            this.backBgTouchStarted = false;
            this.closeBetSlider();
        }
    };
    createIamBack = function () {
        this.iamBackContainer = this.displayUtil.getButton('iamBack');
        this.iamBackContainer.name = 'iamBackContainer';
        this.addChild(this.iamBackContainer);
        this.iamBackContainer.name = 'IamBackButton';
        this.iamBackContainer.scale.x = 0.8;
        this.iamBackContainer.scale.y = 0.7;
        this.iamBackText = this.displayUtil.getTextField(this.fontProps.iamBackText);
        this.iamBackText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_COMMON_IMBACK');
        this.iamBackText.anchor.set(0.5);
        this.iamBackContainer.addChild(this.iamBackText);
        this.displayUtil.setClickable(this.iamBackContainer, true);
        this.displayUtil.addInteraction(this.iamBackContainer, this.iamBackBtnClick.bind(this));
        this.displayUtil.setObjectPosition(this.iamBackContainer, this.positionConfig.iamBack.x, this.positionConfig.iamBack.y);
        this.displayUtil.setObjectPosition(this.iamBackText, this.positionConfig.iamBackText.x, this.positionConfig.iamBackText.y);
        this.iamBackContainer.visible = false;
    };
    createNextHandContainer = function () {
        this.nextHandContainer = this.displayUtil.getButton('iamBack');
        this.nextHandContainer.name = 'nextHandContainer';
        this.addChild(this.nextHandContainer);
        this.nextHandContainer.name = 'ForwardFoldButton';
        this.nextHandContainer.scale.set(0.9);
        const forwardFoldText = this.displayUtil.getTextField(this.fontProps.iamBackText);
        forwardFoldText.text = CommonGameProperties.langPack['getCAPSString'](
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLEDLG_NEXTHANDTXT'),
        );
        forwardFoldText.anchor.set(0.5);
        this.nextHandContainer.addChild(forwardFoldText);
        this.displayUtil.setClickable(this.nextHandContainer, true);
        this.displayUtil.addInteraction(this.nextHandContainer, this.nextHandBtnClick.bind(this));
        this.displayUtil.setObjectPosition(this.nextHandContainer, this.positionConfig.foldPos.x, this.positionConfig.foldPos.y);
        this.displayUtil.setObjectPosition(forwardFoldText, this.positionConfig.foldPos.x, this.positionConfig.foldPos.y);
        this.nextHandContainer.visible = false;
    };
    createforwarfdFoldContainer = function () {
        this.forwarfdFoldContainer = this.displayUtil.getContainer();
        this.forwarfdFoldContainer.name = 'forwarfdFoldContainer';
        this.addChild(this.forwarfdFoldContainer);
        this.forwardFoldBG = this.displayUtil.getButton('forwardFoldBg');
        this.forwarfdFoldContainer.addChild(this.forwardFoldBG);
        this.forwarfdFoldContainer.name = 'ForwardFoldButton';
        this.forwardFoldBG.scale.set(2);
        const forwardFoldText = this.displayUtil.getTextField(this.fontProps.forwardfdText);
        forwardFoldText.text = 'Forward fold';
        forwardFoldText.anchor.set(0.5);
        this.forwarfdFoldContainer.addChild(forwardFoldText);
        this.displayUtil.setClickable(this.forwarfdFoldContainer, true);
        this.displayUtil.addInteraction(this.forwarfdFoldContainer, this.autoQuickFoldBtnClick.bind(this));
        this.displayUtil.setObjectPosition(this.forwarfdFoldContainer, 62, 933);
        this.displayUtil.setObjectPosition(this.forwardFoldBG, 0, 10);
        this.displayUtil.setObjectPosition(forwardFoldText, 126, 70);
        this.forwarfdFoldContainer.visible = false;
    };
    nextHandBtnClick = function () {
        this.hideNextHand();
        //event to send message to server
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.NEXT_HAND_SELECTED);
    };
    iamBackBtnClick = function () {
        this.hideAllButtons();
        this.iamBackContainer.visible = false;
        this.parentViewModel.iamBackClicked();
    };
    createTimerAnim = function () {
        const obj = this.displayUtil.getSpriteDetails('timerAni');
        const timebankObj = this.displayUtil.getSpriteDetails('sandTimerAnim');
        this.timerContainer = this.displayUtil.getContainer();
        this.raiseContainer.addChild(this.timerContainer);
        if (obj) {
            this.timerAnimation = this.displayUtil.getCustomAnimatedSprite(obj);
            this.timerAnimation.anchor.set(0.5);
            this.timerAnimation.scale.x = 1.05;
            this.timerAnimation.scale.y = 1.04;
            this.timerContainer.addChild(this.timerAnimation);
            this.timerAnimation.loop = false;
            this.timerAnimation.visible = false;
            this.displayUtil.setObjectPosition(
                this.timerAnimation,
                this.positionConfig.betSliderPos.timerAnimPos.x,
                this.positionConfig.betSliderPos.timerAnimPos.y,
            );
        }
        if (timebankObj) {
            this.sandTimerAnim = this.displayUtil.getCustomAnimatedSprite(timebankObj);
            this.sandTimerAnim.anchor.set(0.5);
            this.sandTimerAnim.scale.x = 1.05;
            this.sandTimerAnim.scale.y = 1.04;
            this.timerContainer.addChild(this.sandTimerAnim);
            this.sandTimerAnim.loop = false;
            this.sandTimerAnim.visible = false;
            this.displayUtil.setObjectPosition(
                this.sandTimerAnim,
                this.positionConfig.betSliderPos.timerAnimPos.x,
                this.positionConfig.betSliderPos.timerAnimPos.y,
            );
        }
        this.timerContainer.visible = false;
    };
    createButtons = function (buttons, autoOptionsBtns) {
        for (const i in buttons) {
            this[buttons[i]] = this.displayUtil.getButton(buttons[i], true);
            this.buttonsContainer.addChild(this[buttons[i]]);
            this[buttons[i]].anchor.set(0.5);
            this.displayUtil.setObjectPosition(
                this[buttons[i]],
                this.positionConfig[buttons[i] + 'Pos'].x,
                this.positionConfig[buttons[i] + 'Pos'].y,
            );
            this.setSize(720, 1280);
            this.displayUtil.setObjectPosition(this.blackBg, -this.x / this.scale.x, -this.y / this.scale.y);
            this.displayUtil.setClickable(this[buttons[i]], true);
            if (buttons[i] == 'raise' || buttons[i] == 'slidebet') {
                this[buttons[i]]
                    .on('touchstart', this.raiseBtnClick.bind(this))
                    .on('mousedown', this.raiseBtnClick.bind(this))
                    .on('touchstart', this.onSliderDown.bind(this))
                    .on('mousedown', this.onSliderDown.bind(this));
            }
            this.displayUtil.addInteraction(this[buttons[i]], this[buttons[i] + 'BtnClick'].bind(this));
            this[buttons[i]].visible = false;
            this[buttons[i]].name = this.positionConfig[buttons[i] + 'Label'];
            this[buttons[i] + 'Label'] = this.displayUtil.getTextField(this.buttonLabelText);
            this[buttons[i] + 'Label'].text = this.positionConfig[buttons[i] + 'Label'];
            this[buttons[i] + 'Label'].name = buttons[i];
            this[buttons[i] + 'Label'].labelTxt = this[buttons[i] + 'Label'].text;
            this.displayUtil.setObjectPosition(this[buttons[i] + 'Label'], 15, 110);
            if (autoOptionsBtns) {
                this[buttons[i] + 'SelectedState'] = this.displayUtil.getSprite(buttons[i] + '_down');
                this[buttons[i] + 'SelectedState'].anchor.set(0.5);
                this[buttons[i] + 'SelectedState'].visible = false;
                this[buttons[i] + 'SelectedState'].name = buttons[i] + 'SelectedState';
                this.buttonsContainer.addChild(this[buttons[i] + 'SelectedState']);
                this.autoOptionsSelectedStateArr.push(this[buttons[i] + 'SelectedState']);
                this.displayUtil.addInteraction(this[buttons[i] + 'SelectedState'], this[buttons[i] + 'SelectedState' + 'BtnClick'].bind(this));
                this.displayUtil.setClickable(this[buttons[i] + 'SelectedState'], true);
                this[buttons[i] + 'SelectedState'].scale.set(1);
                this.displayUtil.setObjectPosition(
                    this[buttons[i] + 'SelectedState'],
                    this.positionConfig[buttons[i] + 'Pos'].x,
                    this.positionConfig[buttons[i] + 'Pos'].y,
                );
            }
        }
        this.callText = this.displayUtil.getTextField(this.buttonLabelText);
        this.buttonsContainer.addChild(this.callText);
        this.callText.anchor.set(0.5);
        this.callText.name = 'callText';
        this.displayUtil.setProperties(this.callText, this.positionConfig.betSliderPos.callTextPos);
        this.callText.visible = false;
        this.betText = this.displayUtil.getTextField(this.buttonLabelText);
        this.buttonsContainer.addChild(this.betText);
        this.betText.anchor.set(0.5);
        this.betText.name = 'bettext';
        this.displayUtil.setProperties(this.betText, this.positionConfig.betSliderPos.betTextPos);
    };
    createShowOptions = function (showOptions) {
        for (let i = 0; i < showOptions.length; i++) {
            this[showOptions[i]] = this.displayUtil.getButton(showOptions[i], true);
            this.buttonsContainer.addChild(this[showOptions[i]]);
            this[showOptions[i]].anchor.set(0.5);
            this[showOptions[i]].name = showOptions[i];
            this.displayUtil.addInteraction(this[showOptions[i]], this[showOptions[i] + 'BtnClick'].bind(this));
            this.displayUtil.setClickable(this[showOptions[i]], true);
            this[showOptions[i]].visible = false;
            this.displayUtil.setObjectPosition(
                this[showOptions[i]],
                this.positionConfig[showOptions[i] + 'Pos'].x,
                this.positionConfig[showOptions[i] + 'Pos'].y,
            );
        }
    };
    callBtnClick = function () {
        this.allInCallActionCase = false;
        this.allInRaiseActionCase = false;
        this.allInBetActionCase = false;

        this.parentViewModel.betOptionSelected(this.callData);
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Call, false);
    };
    allinActBtnClick = function () {
        if (this.allInCallActionCase) {
            this.callBtnClick();
        } else if (this.allInRaiseActionCase || this.allInBetActionCase) {
            this.raiseBtnClick();
        }
    };
    betBtnClick = function () {
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Bet, false);
    };
    foldBtnClick = function () {
        if (this.check.visible == true) {
            this.parentViewModel.takeFoldConfirmation();
            return;
        }
        this.parentViewModel.betOptionSelected(this.foldData);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_SELF_HAND_STRENGTH, true);
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Fold, false);
    };
    forceFold = function () {
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Fold, false);
        this.parentViewModel.betOptionSelected(this.foldData);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_SELF_HAND_STRENGTH, true);
    };
    raiseBtnClick = function () {
        //bet Raise Container
        this.backBgTouchStarted = false;
        if (this.showRaiseBar) {
            if (!this.betlinecontainer) {
                if (this.blackBg) this.blackBg.visible = true;
                this.raiseContainer.addChild(this.blackBg);
                this.createBetRaiseContainer();
                this.raiseContainer.addChild(this.timerContainer);
                this.timerContainer.visible = true;
                this.betPerLineBarContainer.visible = true;
                if (this.potAmountBg) {
                    this.potAmountBg.visible = true;
                }
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_POT_AMOUNT);
            }
        } else {
            CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Bet, false);
            if (this.betData) {
                this.parentViewModel.betOptionSelected(this.betData);
            } else {
                this.parentViewModel.betOptionSelected(this.raiseData);
            }
        }
    };
    cashoutBtnClick = function () {
        this.cashout.visible = false;
        this.resumehand.visible = false;
        this.isCashoutBtnClicked = true;
        this.parentViewModel.selectedCashoutOption(1);
    };
    resumehandBtnClick = function () {
        this.cashout.visible = false;
        this.resumehand.visible = false;
        this.isCashoutBtnClicked = true;
        this.parentViewModel.selectedCashoutOption(0);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_ACTION_FOR_CASHOUT);
    };
    slidebetBtnClick = function (isTouch) {
        if (isTouch) {
            gsap.to(this.btn, 0.1, {
                alpha: 0,
            });
        }
        this.raiseBtnClick();
    };
    checkBtnClick = function () {
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Check, false);
        this.parentViewModel.betOptionSelected(this.checkData);
    };
    updateButtonData = function (obj, balance, bigBlind, userBetVectorAmount) {
        this.resetData();
        this.betOptions = obj;
        this.userBetVectorAmount = userBetVectorAmount;
        this.optionSet = obj.optionSet;
        this.selfUserBalance = balance;
        this.bigBlind = bigBlind ? bigBlind : 0.5;
        this.hideAllButtons();
        this.setBetOptions();
        this.parentViewModel.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_PLAY_OPTIONS);
    };
    resetData = function () {
        this.raiseData = undefined;
        this.betData = undefined;
        this.callData = undefined;
        this.checkData = undefined;
    };
    updatePotAmount = function (data, bigBlind, roundCount) {
        if (!bigBlind) return;
        this.bigBlind = bigBlind;
        this.potOptionsSelectedStateArr = [];
        this.potRoundCount = roundCount;
        const postData = this.tableSettingsDataModel.getPostFlopSettingData();
        const preData = this.tableSettingsDataModel.getPreFlopSettingData();
        for (let i = 0; i < 3; i++) {
            if (preData != undefined || postData != undefined) {
                if (this.betOptions.limitGameOptionSet[0]) {
                    if (this.selectedPotOption) {
                        this.buttonsContainer.removeChild(this.selectedPotOption[i]);
                    }
                    if (this.betOptions.limitGameOptionSet[0].option.amount != this.betOptions.limitGameOptionSet[0].maxRaiseLimit) {
                        if (!this.betOptions.limitGameOptionSet) return;
                        if (this.betOptions.limitGameOptionSet.length <= 0) return;
                        this.potAmountText = this.betOptions.limitGameOptionSet[0].clientAmt;
                        if (
                            !this.betOptions.limitGameOptionSet[0].isMaxRaiseAllIn &&
                            this.parentViewModel.getTableLimitType() == serverIdConstants.LIMIT_TYPE_POT_LIMIT
                        ) {
                            this.potAmountText = this.betOptions.limitGameOptionSet[0].maxRaiseLimit;
                        }
                        if (this.potRoundCount > 1) {
                            if (this.parentViewModel.gameService.getTableStatusData().gameTypeId == serverIdConstants.GAME_TYPE_SHORT_DECK) {
                                this.potOptions[i] = uiConfig.selectedAnteButtonSet[postData['button' + i]];
                            } else {
                                this.potOptions[i] = uiConfig.selectedRaiseButtonSet[postData['button' + i]];
                            }
                            this.selectedPotOption[i] = this.displayUtil.getButton(this.potOptions[i], true);
                            this.displayUtil.setObjectPosition(
                                this.selectedPotOption[i],
                                this.positionConfig[this.potOptions[i] + 'Pos'].x[i],
                                this.positionConfig[this.potOptions[i] + 'Pos'].y,
                            );
                            this.potOptionsDeciVal[i] = postData['button' + i];
                            if (postData['button' + i] == 'AllIN') {
                                this[this.potOptions[i] + 'Amt'] = this.getAvailableBalanceToBet(); //this.currentMaxBet;tablestatusdata.gameTypeId == GAME_TYPE_SHORT_DECK
                            } else {
                                this[this.potOptions[i] + 'Amt'] = Math.floor(this.potAmountText * this.potOptionsDeciVal[i]);
                            }
                        } else if (this.potRoundCount <= 1) {
                            let setScaleVal = 1;
                            if (this.parentViewModel.gameService.getTableStatusData().gameTypeId == serverIdConstants.GAME_TYPE_SHORT_DECK) {
                                this.potOptions[i] = uiConfig.selectedAnteButtonSet[preData['button' + i]];
                                setScaleVal = uiConfig.selectedAnteButtonSet[preData['button' + i]] == 'allIn' ? 0.99 : 0.96;
                            } else {
                                this.potOptions[i] = uiConfig.selectedRaiseButtonSet[preData['button' + i]];
                            }
                            this.selectedPotOption[i] = this.displayUtil.getButton(this.potOptions[i], true);
                            this.selectedPotOption[i].scale.set(setScaleVal);
                            this.displayUtil.setObjectPosition(
                                this.selectedPotOption[i],
                                this.positionConfig[this.potOptions[i] + 'Pos'].x[i],
                                this.positionConfig[this.potOptions[i] + 'Pos'].y,
                            );
                            this.potOptionsDeciVal[i] = preData['button' + i];
                            if (preData['button' + i] == 'POT') {
                                this[this.potOptions[i] + 'Amt'] = this.potAmountText;
                            } else if (preData['button' + i] == 'AllIN') {
                                this[this.potOptions[i] + 'Amt'] = this.getAvailableBalanceToBet(); //this.currentMaxBet;
                            } else {
                                this[this.potOptions[i] + 'Amt'] = Math.floor(this.bigBlind * this.potOptionsDeciVal[i]);
                            }
                        }
                        this.buttonsContainer.addChild(this.selectedPotOption[i]);
                        this.displayUtil.addInteraction(this.selectedPotOption[i], this['potBtnsClick'].bind(this, i));
                        this[this.potOptions[i] + 'Label'] = this.displayUtil.getTextField(this.buttonLabelText);
                        this.selectedPotOption[i].addChild(this[this.potOptions[i] + 'Label']);
                        this[this.potOptions[i] + 'Label'].text = this.getFormattedAmount(this[this.potOptions[i] + 'Amt'], true);
                        this[this.potOptions[i] + 'Label'].anchor.set(0.5);
                        this[this.potOptions[i] + 'Label'].visible = true;
                        this.potOptionButtons.push(this.selectedPotOption[i]);
                        if (this[this.potOptions[i] + 'Amt'] < this.currentMinBet || this[this.potOptions[i] + 'Amt'] > this.currentMaxBet) {
                            this[this.potOptions[i] + 'Label'].alpha = 0.5;
                            this.displayUtil.setClickable(this.selectedPotOption[i], false);
                        } else {
                            this.displayUtil.setClickable(this.selectedPotOption[i], true);
                        }
                        this.displayUtil.setObjectPosition(
                            this[this.potOptions[i] + 'Label'],
                            this.potOptionButtons[i].width / 2,
                            this.selectedPotOption[i].height + 18,
                        );
                        this.potOptionsSelectedStateArr.push(this[this.potOptions[i] + 'Amt']);
                    }
                }
            }
        }
    };
    hideAutoOptions = function () {
        this.deSelectAutoBtns();
        if (this.autoFold) {
            this.autoFold.visible = false;
            this.autoFold.gotoAndStop(0);
        }
        if (this.autoCheck) {
            this.autoCheck.visible = false;
            this.autoCheck.gotoAndStop(0);
        }
        if (this.autoFC) {
            this.autoFC.visible = false;
            this.autoFC.gotoAndStop(0);
        }
        this.hideForwardFold();
    };
    showNextHand = function () {
        if (!this.nextHandContainer) {
            this.createNextHandContainer();
        }
        this.nextHandContainer.visible = true;
    };
    //should be removed on poolEndGameNotification
    hideNextHand = function () {
        if (this.nextHandContainer) {
            this.nextHandContainer.visible = false;
        }
    };
    showForwardFold = function () {
        this.hideAllButtons();
        if (!this.forwarfdFoldContainer) {
            this.createforwarfdFoldContainer();
        }
        this.forwarfdFoldContainer.visible = true;
    };
    hideForwardFold = function () {
        if (this.forwarfdFoldContainer) {
            this.forwarfdFoldContainer.visible = false;
        }
    };
    showIamBackBtn = function () {
        this.hideAllButtons();
        this.iamBackContainer.visible = true;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_POT_AMOUNT);
    };
    setMinMaxLimits = function (canBet, canRaise) {
        this.showRaiseBar = true;
        this.currentMaxBet = 0;
        this.currentMinBet = 0;
        this.maxraiseLimitText = this.displayUtil.getTextField(this.buttonLabelText);
        this.buttonsContainer.addChild(this.maxraiseLimitText);
        this.parentViewModel.getStakeValues();
        if (this.betOptions.limitGameOptionSet && this.betOptions.limitGameOptionSet.length > 0) {
            this.currentMaxBet = this.betOptions.limitGameOptionSet[0].maxRaiseLimit;
            if (this.betOptions.limitGameOptionSet[0].option) {
                this.currentMinBet = this.betOptions.limitGameOptionSet[0].option.amount;
            }
        }
        if (this.currentMinBet >= this.currentMaxBet || this.currentMinBet >= this.getAvailableBalanceToBet()) {
            this.showRaiseBar = false;
            this.setBetOrRaiseTxtValues(canBet, canRaise);
        }
        if (this.currentMaxBet > this.getAvailableBalanceToBet()) {
            this.currentMaxBet = this.getAvailableBalanceToBet();
        }
    };
    setBetOrRaiseTxtValues = function (canBet, canRaise) {
        if (canRaise) {
            this['raise' + 'Label'].text = 'ALL IN';
            this.raise.visible = false;
            this.displayUtil.setObjectPosition(this.allinAct, this.slidebet.x, this.slidebet.y);
            this.allinAct.visible = true;
            this.allInRaiseActionCase = true;
            if (this.raiseData && this.raiseData.amount == 0) {
                this.raiseData.amount = this.selfUserBalance;
            }
        } else if (canBet) {
            this['bet' + 'Label'].text = 'ALL IN';
            this.slidebet.visible = false;
            this.allinAct.visible = true;
            this.allInBetActionCase = true;
            this.displayUtil.setObjectPosition(this.allinAct, this.slidebet.x, this.slidebet.y);
            if (this.betData && this.betData.amount == 0) {
                this.betData.amount = this.selfUserBalance;
            }
        }
    };
    //gets user balance + sidepot amount
    //if there is some amount in sidepot, add it
    getAvailableBalanceToBet = function () {
        if (this.betOptions.limitGameOptionSet && this.betOptions.limitGameOptionSet.length > 0)
            return this.selfUserBalance + this.userBetVectorAmount;
        return this.selfUserBalance;
    };
    setStakeValues = function (lowstake, highstake) {
        this.lowstake = lowstake;
        this.highstake = highstake;
    };
    initiateRaiseBarSteps = function () {
        if (this.parentViewModel.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.setVal = this.parentViewModel.gameService.getTableStatusData().smallBlind;
        } else {
            this.setVal = this.parentViewModel.gameService.getLowStake();
        }
        //need to add check for one
        //this.initNormalRaiseBarSteps();
        this.initiateExponentialRaiseBarSteps();
    };
    initNormalRaiseBarSteps = function () {
        this.tempBetArr = [];
        const maxStepVal = Math.ceil((this.currentMaxBet - this.currentMinBet) / this.setVal);
        let tempVal;
        for (let i = 0; i <= maxStepVal; i++) {
            tempVal = this.currentMinBet + i * this.setVal;
            if (tempVal > this.currentMaxBet) tempVal = this.currentMaxBet;
            this.tempBetArr.push(tempVal);
        }
    };
    initiateExponentialRaiseBarSteps = function () {
        this.tempBetArr = [];
        this.tempBetArr.push(this.currentMinBet);
        for (let i = 0; i <= 100; i++) {
            this.tempBetArr.push(this.getExponentialVal(this.currentMinBet, this.currentMaxBet, i));
        }
    };
    getExponentialVal = function (minBet, maxBet, percentage) {
        if (percentage == 0) return minBet;
        if (percentage == 100) return maxBet;
        const exponent = 2.5;
        const factor = Math.pow(maxBet - minBet, 1 / exponent);
        const scale = percentage * 0.01;
        const seekAmount = Math.pow(factor * scale, exponent);
        const betAmtValue = minBet + seekAmount;
        if (this.parentViewModel.gameService.getTableStatusData().tableCategory == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            return betAmtValue.toFixed(0);
        } else {
            return betAmtValue.toFixed(2);
        }
    };
    setBetOptions = function () {
        this.canCheck();
        this.canCall();
        const canBet = this.canBet();
        const canRaise = this.canRaise();
        this.canFold();
        // Show check indicator if check is possible
        // Show call/bet/raise indicator if either action is possible
        if (canBet || canRaise) {
            //As per native app while bet or raise button showing player action get hide
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_PLAYER_ACTION, this.selfUserPos);
            if (this.parentViewModel.gameService.getTableLimitType() != serverIdConstants.LIMIT_TYPE_REGULAR) {
                this.setMinMaxLimits(canBet, canRaise);
                this.initiateRaiseBarSteps();
                this.betText.text = this.getFormattedAmount(this.currentMinBet);
            }
            this.sliderAmountVal = this.currentMinBet;
            if (canBet) {
                this.slidebet.visible = true;
                this.raise.visible = false;
            } else {
                //show raise
                this.slidebet.visible = false;
                this.raise.visible = true;
            }
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_EMOJI_ON_TOP, {
                buttonsContainer: this.buttonsContainer,
                playerSeat: this.selfUserPos,
            });
        }
    };
    canCheck = function () {
        if (this.hasBetOption(serverIdConstants.OPTION_CHECK, 'check')) {
            //show check
            this.checkId = serverIdConstants.OPTION_CHECK;
            this.check.visible = true;
            this.callText.visible = false;
        }
    };
    canCall = function () {
        if (this.hasBetOption(serverIdConstants.OPTION_CALL, 'call')) {
            this.callId = serverIdConstants.OPTION_CALL;
            const callText = this.getFormattedAmount(this.callAmount);
            this.callText.text = callText;
            if (this.callAmount == this.selfUserBalance) {
                this.allInCallActionCase = true;
                this.displayUtil.setObjectPosition(this.allinAct, this.call.x, this.call.y);
                this.allinAct.visible = true;
            } else {
                this.call.visible = true;
            }
            this.callText.visible = true;
        }
    };
    canFold = function () {
        if (this.hasBetOption(serverIdConstants.OPTION_FOLD, 'fold')) {
            //show fold
            this.foldId = serverIdConstants.OPTION_FOLD;
            this.fold.visible = true;
        }
    };
    canBet = function () {
        this.betId = serverIdConstants.OPTION_BET;
        return this.hasBetOption(serverIdConstants.OPTION_BET, 'bet') || this.hasBetOption(serverIdConstants.OPTION_BET_TO, 'bet');
    };
    canRaise = function () {
        this.raiseId = serverIdConstants.OPTION_RAISE;
        return this.hasBetOption(serverIdConstants.OPTION_RAISE, 'raise') || this.hasBetOption(serverIdConstants.OPTION_RAISE_TO, 'raise');
    };
    getBetOption = function (optionId) {
        for (const b in this.optionSet) {
            if (this.optionSet[b].optionId == optionId) {
                return this.optionSet[b].amount;
            }
        }
    };
    hasBetOption = function (optionId, optionName) {
        for (const b in this.optionSet) {
            if (this.optionSet[b].optionId == optionId) {
                this[optionName + 'Label'].text = this[optionName + 'Label'].labelTxt;
                if (this.optionSet[b].amount !== 0) {
                    this[optionName + 'Label'].x = -15;
                    this[optionName + 'Label'].text += ' ' + this.getFormattedAmount(this.optionSet[b].amount);
                    if (optionName == 'call') {
                        this.callAmount = this.optionSet[b].amount;
                    }
                    if (optionId == serverIdConstants.OPTION_BET || optionId == serverIdConstants.OPTION_RAISE) {
                        this.betAmount = this.optionSet[b].amount;
                        if (this.parentViewModel.gameService.getTableLimitType() == serverIdConstants.LIMIT_TYPE_REGULAR) {
                            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.DISABLE_PLAYER_CLICK);
                            this.betText.visible = true;
                            this.betText.text = this.getFormattedAmount(this.betAmount);
                        }
                    }
                }
                this[optionName + 'Data'] = this.optionSet[b];
                return true;
            }
        }
        return false;
    };
    getBetOptionData = function (optionId, optionName) {
        for (const b in this.optionSet) {
            if (this.optionSet[b].optionId == optionId) {
                this[optionName + 'Data'] = this.optionSet[b];
                return this.optionSet[b];
            }
        }
    };
    createBetRaiseContainer = function () {
        const buttonLabelText = {
            fontFamily: 'arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        };
        this.betlinecontainer = this.displayUtil.getContainer();
        this.betlinecontainer.name = 'betlinecontainer';
        this.raiseContainer.addChild(this.betlinecontainer);
        this.betPerLineBarContainer = this.displayUtil.getContainer();
        this.betlinecontainer.addChild(this.betPerLineBarContainer);
        this.betSelectBtn = this.displayUtil.getSprite('slider_accept');
        this.betPerLineBarBase = this.displayUtil.getSprite('slider_bar');
        this.betPerLineBarContainer.addChild(this.betPerLineBarBase);
        this.betPerLineBarBase.anchor.set(0.5);
        this.betPerLineBarContainer.x = 326;
        this.betPerLineBarContainer.y = 414;
        this.betPerLineBarContainer.name = 'this.betPerLineBarContainer';
        this.betPerLineBarBase.name = 'slider_bg';
        this.displayUtil.setProperties(this.betPerLineBarBase, this.positionConfig.betSliderPos.betPerLineBarBase);
        this.betPerLineBarContainer.interactive = true;
        this.betPerLineBarContainer.defaultCursor = 'move';
        this.betperlinestxtnumstart = this.displayUtil.getTextField(buttonLabelText);
        this.betperlinestxtnumstart.text = this.getFormattedAmount(this.currentMinBet);
        this.displayUtil.setProperties(this.betperlinestxtnumstart, this.positionConfig.betSliderPos.betperlinestxtnumstart);
        this.betPerLineBarTop = this.displayUtil.getSprite('slider_fill');
        this.betPerLineBarContainer.addChild(this.betPerLineBarTop);
        this.betPerLineBarTop.name = 'sliderchannel';
        this.betPerLineBarMask = this.displayUtil.getRectangleSprite(this.betPerLineBarTop.width, this.betPerLineBarTop.height, 0x191515);
        this.betPerLineBarMask.name = 'bar_mask';
        this.betPerLineBarContainer.addChild(this.betPerLineBarMask);
        this.betPerLineBarMask.pivot.y = this.betPerLineBarTop.height;
        this.betPerLineBarMask.scale.y = 0;
        this.sliderMin = 110;
        this.sliderMax = this.betPerLineBarTop.height;
        this.displayUtil.setProperties(this.betPerLineBarTop, this.positionConfig.betSliderPos.betPerLineBarTop);
        this.displayUtil.setProperties(this.betPerLineBarMask, this.positionConfig.betSliderPos.betPerLineBarMask);
        this.betPerLineBarMask.alpha = 0.5;
        this.betPerLineBarTop.mask = this.betPerLineBarMask;
        this.betperlinestxtnumsend = this.displayUtil.getTextField(buttonLabelText);
        this.betperlinestxtnumsend.text = this.getFormattedAmount(this.currentMaxBet);
        this.betperlinestxtnumsend.name = 'betperlinestxtnumsend';
        this.displayUtil.setProperties(this.betperlinestxtnumsend, this.positionConfig.betSliderPos.betperlinestxtnumsend);
        this.sliderConfirmBet = this.displayUtil.getButton('slider_acpt');

        this.sliderConfirmBet.anchor.set(0.5);
        this.sliderConfirmBet.name = 'sliderBet';
        this.betPerLineBarContainer.addChild(this.sliderConfirmBet);
        this.displayUtil.setProperties(this.sliderConfirmBet, this.positionConfig.betSliderPos.sliderConfirmBet);
        this.displayUtil.setClickable(this.sliderConfirmBet, true);
        this.betPerLineBtn = this.displayUtil.getSprite('slider_btn');
        this.betPerLineBarContainer.addChild(this.betPerLineBtn);
        this.betPerLineBtn.name = 'slider_uparrow';
        this.displayUtil.setButtonActive(this.betPerLineBtn, true);
        this.betPerLineBtn
            .on('mousedown', this.onSliderDown.bind(this))
            .on('touchstart', this.onSliderDown.bind(this))
            .on('mouseup', this.onSliderRelease.bind(this))
            .on('mouseupoutside', this.onSliderRelease.bind(this))
            .on('touchend', this.onSliderRelease.bind(this))
            .on('touchendoutside', this.onSliderRelease.bind(this))
            .on('mousemove', this.onSliderDrag.bind(this))
            .on('touchmove', this.onSliderDrag.bind(this))
            .on('pointermove', this.onSliderDrag.bind(this));
        this.betPerLineBarInfo = this.displayUtil.getSprite('betAmountBg');
        this.betPerLineBarContainer.addChild(this.betPerLineBarInfo);
        this.displayUtil.setProperties(this.betPerLineBtn, this.positionConfig.betSliderPos.betPerLineBtn);
        this.betBarInfotxt = this.displayUtil.getTextField(buttonLabelText);
        this.betBarInfotxt.text = this.getFormattedAmount(this.currentMinBet);
        this.betBarInfotxt.scale.set(1.1);
        this.betBarInfotxt.anchor.set(0.5);
        this.betPerLineBarInfo.addChild(this.betBarInfotxt);
        this.betPerLineBarInfo.name = 'info';
        this.betPerLineBarInfo.scale.set(0.9);
        this.displayUtil.setProperties(this.betPerLineBarInfo, this.positionConfig.betSliderPos.betPerLineBarInfo);
        this.displayUtil.setProperties(this.betBarInfotxt, this.positionConfig.betSliderPos.betBarInfotxt);
        this['raise']
            .on('mouseup', this.onSliderRelease.bind(this))
            .on('mouseupoutside', this.onSliderRelease.bind(this))
            .on('touchend', this.onSliderRelease.bind(this))
            .on('touchendoutside', this.onSliderRelease.bind(this));
        this['slidebet']
            .on('mouseup', this.onSliderRelease.bind(this))
            .on('mouseupoutside', this.onSliderRelease.bind(this))
            .on('touchend', this.onSliderRelease.bind(this))
            .on('touchendoutside', this.onSliderRelease.bind(this));
        this.sliderConfirmBet.on('touchstart', this.sliderAccept.bind(this));
        this.sliderConfirmBet.on('touchend', this.sliderAccept.bind(this));
    };
    sliderAccept = function () {
        this.sliderDown = false;
        this.displayUtil.addInteraction(this.sliderConfirmBet, this.confirmSliderBet.bind(this));
    };
    confirmSliderBet = function () {
        //need to send server request with the bet value from the slider
        //this.betBarInfotxt.text
        this.blackBg.visible = false;
        if (this.potAmountBg) {
            this.potAmountBg.visible = false;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_POT_AMOUNT);
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Bet, false);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_BLACK_BG, false);
        this.raiseContainer.removeChild(this.betlinecontainer);
        this.raiseContainer.removeChild(this.timerContainer);
        this.betlinecontainer = undefined;
        this.sendBetRaiseAmount(this.sliderAmountVal);
    };
    sendBetRaiseAmount = function (amountInCents) {
        if (this.parentViewModel.gameService.getTableCategory() == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            //for tournaments, chips needs to be rounded off.
            amountInCents = amountInCents / 100;
            amountInCents = Math.round(amountInCents);
            amountInCents = amountInCents * 100;
        } else amountInCents = Math.round(amountInCents);
        //adding this as extra check to make sure that the amount send is valid
        if (amountInCents > this.getAvailableBalanceToBet()) {
            amountInCents = this.getAvailableBalanceToBet();
        }
        let dataToSend;
        if (this.betOptions.limitGameOptionSet) {
            dataToSend = this.betOptions.limitGameOptionSet[0].option;
        }
        if (dataToSend) {
            dataToSend.amount = amountInCents;
            for (let i = 0; i < dataToSend.optionTitle.length; i++) {
                if (dataToSend.optionTitle[i].params.length > 0) {
                    dataToSend.optionTitle[i].params[0].amount = amountInCents;
                }
            }
        }
        this.parentViewModel.betOptionSelected(dataToSend);
    };
    onSliderDown = function (e) {
        this.sliderDown = true;
        this.currentPointerY = e.data.getLocalPosition(this.parent).y;
    };
    onSliderRelease = function () {
        this.sliderDown = false;
        const autoRaise = this.tableSettingsDataModel.gameSettingsDataObj.raiseBarAutoConfirm
            ? serverIdConstants.DEFAULT_ON
            : serverIdConstants.DEFAULT_OFF;
        if (autoRaise == serverIdConstants.DEFAULT_ON) {
            this.confirmSliderBet();
        }
    };
    onSliderDrag = function (e) {
        if (this.sliderDown) {
            const pointerY = e.data.getLocalPosition(this.parent).y;
            const diffY = pointerY - this.currentPointerY;
            if (this.betPerLineBtn.y + diffY >= this.sliderMin && this.betPerLineBtn.y + diffY <= 442) {
                this.betPerLineBtn.y += diffY;
                this.currentPointerY = pointerY;
            } else if (this.betPerLineBtn.y + diffY < this.sliderMin) {
                this.betPerLineBtn.y = this.sliderMin;
            } else if (this.betPerLineBtn.y + diffY > 442) {
                this.betPerLineBtn.y = 442;
            }
            this.currentScale = (this.sliderMax - this.betPerLineBtn.y) / (this.sliderMax - this.sliderMin);
            this.betPerLineBarMask.scale.y = (this.sliderMax - this.betPerLineBtn.y) / (this.sliderMax - this.sliderMin);
            if (this.currentScale > 0.99) {
                this.currentScale = 1;
            } else if (this.currentScale < 0.01) {
                this.currentScale = 0;
            }

            const currentIndex = Math.round(this.currentScale * (this.tempBetArr.length - 1));

            this.sliderAmountVal = this.tempBetArr[currentIndex];
            if (this.tempBetArr[currentIndex] >= this.getAvailableBalanceToBet()) {
                this.betBarInfotxt.text = 'All IN';
            } else {
                this.betBarInfotxt.text = this.getFormattedAmount(this.tempBetArr[currentIndex]);
            }
            if (this.betPerLineInfoBg) {
                this.betPerLineInfoBg.visible = true;
            }
        }
    };
    resetGame = function () {
        this.raiseContainer.removeChild(this.betlinecontainer);
        this.raiseContainer.removeChild(this.timerContainer);
        this.betlinecontainer = undefined;
        this.callText.visible = false;
        this.blackBg.visible = false;
        this.potAmountBg.visible = false;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_BLACK_BG, false);
        this.potOptionButtons = [];
        this.potOptionsSelectedStateArr = [];
        for (const i in this.buttonsContainer.children) {
            this.buttonsContainer.children[i].visible = false;
        }
    };
    updateAutoButtonData = function (obj) {
        this.hideAllButtons();
        this.autoBetOptions = obj;
        this.setAutoOptions();
        this.parentViewModel.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_AUTO_OPTIONS);
    };
    hideAllButtons = function () {
        this.allInRaiseActionCase = false;
        this.allInCallActionCase = false;
        this.allInBetActionCase = false;
        this.isMaxRaiseAllIn = null;
        this.maxRaiseLimit = null;
        this.betRaiseAmountVal = null;
        this.call.visible = false;
        this.call.gotoAndStop(0);
        this.allinAct.visible = false;
        this.allinAct.gotoAndStop(0);
        this.slidebet.visible = false;
        this.slidebet.gotoAndStop(0);
        this.raise.visible = false;
        this.raise.gotoAndStop(0);
        this.check.visible = false;
        this.check.gotoAndStop(0);
        this.fold.visible = false;
        this.fold.gotoAndStop(0);
        this.bet.visible = false;
        this.bet.gotoAndStop(0);
        if (this.resumehand) {
            this.resumehand.visible = false;
            this.resumehand.gotoAndStop(0);
        }
        if (this.cashout) {
            this.cashout.visible = false;
            this.cashout.gotoAndStop(0);
        }
        if (this.betlinecontainer) {
            this.raiseContainer.removeChild(this.betlinecontainer);
            this.raiseContainer.removeChild(this.timerContainer);
            this.betlinecontainer = undefined;
        }
        this.hideAutoOptions();
        this.hideShowMuckOptions();
        this.hidePotButtons();
        this.callText.visible = false;
        this.blackBg.visible = false;
        this.betText.visible = false;
        this.potAmountBg.visible = false;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_BLACK_BG, false);
        this.parentViewModel.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_NONE);
    };
    hideShowMuckOptions = function () {
        this.show.visible = false;
        this.muck.visible = false;
        this.dontshow.visible = false;
    };
    hidePotButtons = function () {
        for (const potBtn of this.selectedPotOption) this.buttonsContainer.removeChild(potBtn);
    };
    setAutoOptions = function () {
        if (this.hasAutoBetOption(serverIdConstants.AUTO_OPTION_CHECK, 'autoCheck')) {
            this.autoCheckId = serverIdConstants.AUTO_OPTION_CHECK;
            this.autoCheck.visible = true;
        }
        if (this.hasAutoBetOption(serverIdConstants.AUTO_OPTION_FOLD, 'autoFold')) {
            //show fold
            this.autoFoldId = serverIdConstants.AUTO_OPTION_FOLD;
            this.autoFold.visible = true;
        } else if (this.hasAutoBetOption(serverIdConstants.AUTO_OPTION_FOLD_CHECK, 'autoFC')) {
            //show fold
            this.autoFCId = serverIdConstants.AUTO_OPTION_FOLD_CHECK;
            this.autoFC.visible = true;
        } else if (this.hasAutoBetOption(serverIdConstants.AUTO_OPTION_QUICK_FOLD, 'autoFold')) {
            this.autoFoldId = serverIdConstants.AUTO_OPTION_QUICK_FOLD;
            this.autoFold.visible = true;
        }
        if (this.hasAutoBetOption(serverIdConstants.AUTO_OPTION_QUICK_FOLD, 'autoQuickFold')) {
            this.autoQuickFoldId = serverIdConstants.AUTO_OPTION_QUICK_FOLD;
            const bData = this.parentViewModel.gameService.getTableBoosterTriggered();
            if (bData && bData.looserPerc > 0) {
            } else {
                this.showForwardFold();
            }
        }
    };
    deSelectAutoBtns = function () {
        this.autoCheckSelectedState.visible = false;
        this.autoFoldSelectedState.visible = false;
        this.autoFCSelectedState.visible = false;
        this.removeChild(this.autoCheckSelectedState);
        this.removeChild(this.autoFoldSelectedState);
        this.removeChild(this.autoFCSelectedState);
        this.addChild(this.autoFold);
        this.addChild(this.autoCheck);
        this.addChild(this.autoFC);
    };
    autoFCBtnClick = function () {
        this.deSelectAutoBtns();
        this.parentViewModel.autoBetOptionSelected(this.autoFCData);
        this.autoFCSelectedState.visible = true;
        this.autoFCSelectedState.name = 'autoFCSelectedState';
        this.addChild(this.autoFCSelectedState);
    };
    autoCheckBtnClick = function () {
        this.deSelectAutoBtns();
        this.parentViewModel.autoBetOptionSelected(this.autoCheckData);
        this.autoCheckSelectedState.visible = true;
        this.autoCheckSelectedState.name = 'autoCheckSelectedState';
        this.addChild(this.autoCheckSelectedState);
    };
    //forwardFoldbtnclick
    autoQuickFoldBtnClick = function () {
        this.deSelectAutoBtns();
        this.parentViewModel.autoBetOptionSelected(this.autoQuickFoldData);
        this.hideForwardFold();
        // dispatch event here
    };
    noAutoOptionSelected = function () {
        this.parentViewModel.autoBetOptionSelected({
            amount: 0,
            autoOptionId: serverIdConstants.AUTO_OPTION_NOT_SELECTED,
            isAllIn: false,
            title: null,
        });
    };
    autoCheckSelectedStateBtnClick = function () {
        this.noAutoOptionSelected();
        this.autoCheckSelectedState.visible = false;
        this.autoCheckSelectedState.name = 'autoCheckSelectedState';
        this.addChild(this.autoCheck);
    };
    autoFoldSelectedStateBtnClick = function () {
        this.noAutoOptionSelected();
        this.autoFoldSelectedState.visible = false;
        this.autoFoldSelectedState.name = 'autoFoldSelectedState';
        this.addChild(this.autoFold);
    };
    autoFCSelectedStateBtnClick = function () {
        this.noAutoOptionSelected();
        this.autoFCSelectedState.visible = false;
        this.autoFCSelectedState.name = 'autoFCSelectedState';
        this.addChild(this.autoFC);
    };
    autoFoldBtnClick = function () {
        this.deSelectAutoBtns();
        this.parentViewModel.autoBetOptionSelected(this.autoFoldData);
        this.autoFoldSelectedState.visible = true;
        this.autoFoldSelectedState.name = 'autoFoldSelectedState';
        this.addChild(this.autoFoldSelectedState);
    };
    hasAutoBetOption = function (autoOptionId, autoOptionName) {
        for (const b in this.autoBetOptions) {
            if (this.autoBetOptions[b].autoOptionId == autoOptionId) {
                this[autoOptionName + 'Data'] = this.autoBetOptions[b];
                if (autoOptionId == serverIdConstants.AUTO_OPTION_QUICK_FOLD) return true;
                this[autoOptionName + 'Label'].text = this[autoOptionName + 'Label'].labelTxt;
                if (this.autoBetOptions[b].amount !== 0) {
                    this[autoOptionName + 'Label'].x = -15;
                    this[autoOptionName + 'Label'].text += ' ' + this.getFormattedAmount(this.autoBetOptions[b].amount);
                }
                return true;
            }
        }
        return false;
    };
    potBtnsClick = function (potOption) {
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Bet, false);
        this.sendBetRaiseAmount(this.potOptionsSelectedStateArr[potOption]);
    };
    canMuckCards = function () {
        if (this.hasMuckCards('MuckCards')) {
            this.hideAllButtons();
        }
    };
    hasMuckCards = function (muckCardsName) {
        for (const b in this.muckCards) {
            if (this.muckCards[b] == muckCardsName) {
                this[muckCardsName + 'Data'] = this.muckCards[b];
                return true;
            }
        }
        return false;
    };
    setShowCardsOptions = function () {
        if (this.parentViewModel.gameService.getCurrentShowingOption() == serverIdConstants.SHOWING_IAMBACK) return;
        /**SHOW OPTIONS */
        switch (this.showCardsOptions.showCardsOptionId) {
            case serverIdConstants.SHOWCARDS_OPTIONS_SHOW:
                this.show.visible = true;
                this.muck.visible = false;
                this.dontshow.visible = false;
                break;
            case serverIdConstants.SHOWCARDS_OPTIONS_SHOW_OR_MUCK:
                this.dontshow.visible = false;
                this.show.visible = true;
                this.muck.visible = true;
                break;
            case serverIdConstants.SHOWCARDS_OPTIONS_SHOW_OR_DONTSHOW:
                this.muck.visible = false;
                this.show.visible = true;
                this.dontshow.visible = true;
                break;
        }
        this.parentViewModel.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_SHOWCARDS_OPTION);
    };
    updateMuckButtonData = function (obj) {
        this.muckCardsOptions = obj;
        this.canMuckCards();
    };
    showBtnClick = function () {
        this.hideAllButtons();
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CARD_OPTION_SHOW_SELECTED);
    };
    muckBtnClick = function () {
        this.hideAllButtons();
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CARD_OPTION_MUCK_SELECTED);
    };
    dontshowBtnClick = function () {
        this.hideAllButtons();
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CARD_OPTION_DONTSHOW_SELECTED);
    };
    updateShowCardsOptions = function (obj) {
        this.showCardsOptions = obj;
        for (let i = 0; i < this.potOptionButtons.length; i++) {
            this.potOptionButtons[i].visible = false;
            this.buttonsContainer.removeChild(this.potOptionButtons[i]);
        }
        this.hideAllButtons();
        this.setShowCardsOptions();
    };
    closeBetSlider = function () {
        this.raiseContainer.removeChild(this.betlinecontainer);
        this.raiseContainer.removeChild(this.timerContainer);
        this.betlinecontainer = undefined;
        this.blackBg.visible = false;
        if (this.potAmountBg) {
            this.potAmountBg.visible = false;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_BLACK_BG, false);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_POT_AMOUNT);
    };
    getFormattedAmount = function (cents) {
        let resultantValue;
        if (this.parentViewModel.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            resultantValue = CommonGameProperties.mathUtil['transform'](cents, this.parentViewModel.gameService.getGameCurrency());
        } else {
            resultantValue = (cents / 100).toFixed(0);
        }
        return resultantValue;
    };
    hideIamBack = function () {
        this.iamBackContainer.visible = false;
    };
    playAllSounds = function () {};
    currentTablePeerId = function (peerId) {
        this.currTablePeerId = 'table' + peerId;
        this.peerId = peerId;
    };
    updatePotAmountForRaise = function (amount) {
        this.potAmountForRaise.text = 'POT : ' + this.getFormattedAmount(amount);
    };
    updateTimerAnimation = function (timePeriod) {
        this.timerPeriod = timePeriod;
        this.startTimerAnimation(timePeriod);
    };
    startTimerAnimation = function (time) {
        const timeOutDataType = this.parentViewModel.gameService.getCurrentTimerStatus();
        if (timeOutDataType && timeOutDataType.timeOutType == 1) {
            this.sandTimerAnim.visible = true;
            // added +1 for difference received when you floor it
            this.sandTimerAnim.animationSpeed = 600 / ((time + 1) * 60);
            this.sandTimerAnim.gotoAndPlay(0);
            this.sandTimerAnim.onComplete = function () {
                this.removeBetTimerAnimation();
            }.bind(this);
        } else {
            this.timerAnimation.visible = true;
            this.timerAnimation.animationSpeed = 600 / (time * 60);
            this.timerAnimation.gotoAndPlay(0);
            this.timerAnimation.onComplete = function () {
                this.removeBetTimerAnimation();
            }.bind(this);
        }
    };
    removeBetTimerAnimation = function () {
        this.timerAnimation.visible = false;
        this.timerAnimation.gotoAndStop(0);
        this.sandTimerAnim.visible = false;
        this.sandTimerAnim.gotoAndStop(0);
    };
    onResizeToFitTheScreen = function () {
        const xRatio = uiConfig.windowWidth / uiConfig.game.baseLayout.ML.baseWidth;
        const yRatio = uiConfig.windowHeight / uiConfig.game.baseLayout.ML.baseHeight;

        const scaleFactor = Math.min(xRatio, yRatio);
        if (xRatio != scaleFactor) {
            const x_Up_ScaleFactor = xRatio / scaleFactor;
            this.blackBg.scale.x = x_Up_ScaleFactor;
            this.blackBg.x = -(this.blackBg.width - uiConfig.game.baseLayout.ML.baseWidth) * 0.5;
        }
        if (yRatio != scaleFactor) {
            const y_Up_ScaleFactor = yRatio / scaleFactor;
            this.blackBg.scale.y = y_Up_ScaleFactor;
            this.blackBg.y = -(this.blackBg.height - uiConfig.game.baseLayout.ML.baseHeight);
        }
    };
    enablecashbuttons = function () {
        this.playersCashoutEquityDetails = this.parentViewModel.gameService.gameInfoModel.getPlayersCashoutEquityDetails();
        const timeOutPeriod = this.playersCashoutEquityDetails.generatedAt + this.playersCashoutEquityDetails.timeOutPeriod;
        const peerTime = this.gameTableNotificationService.getTimeForPeer(this.peerId);
        const timeDiffToShow = Math.floor(timeOutPeriod - peerTime);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.CASHOUT_TIMER, timeDiffToShow);
        this.hideAllButtons();
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.DISABLE_ACTION_FOR_CASHOUT);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_PLAYER_ACTION, this.selfUserPos);
        this.cashout.visible = true;
        this.timerContainer.visible = true;
        this.resumehand.visible = true;
        setTimeout(
            function () {
                this.cashout.visible = false;
                this.resumehand.visible = false;
                if (this.isCashoutBtnClicked == false) this.resumehandBtnClick();
                this.isCashoutBtnClicked = false;
            }.bind(this),
            timeDiffToShow * 1000,
        );
    };
}
