import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    //eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[attributes]',
    standalone: true,
})
export class AttributeDirective implements OnChanges {
    @Input()
    public attributes: { [key: string]: any };

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.attributes) {
            for (const attributeName in this.attributes) {
                const attributeValue = this.attributes[attributeName];
                if (attributeValue) {
                    this.renderer.setAttribute(this.elementRef.nativeElement, attributeName, attributeValue);
                } else {
                    this.renderer.removeAttribute(this.elementRef.nativeElement, attributeName);
                }
            }
        }
    }
}
