import { ByteBuffer } from './Lib/byteBuffer';

export const FrameEncoder = {
    MIN_SIZE_FOR_ZIPPING: 2048,
    MIN_SIZE_FOR_BIG_PKT: 1024000,

    encode(msg: { serializedBytes: ByteBuffer; classId: number; reqServerPeerId: number }): ByteBuffer {
        const uncompressedSize = msg.serializedBytes.limit;
        const compressedSize = 0;
        const zipped = false;
        let zippedByteArray: any;

        const length = zipped ? compressedSize : uncompressedSize;
        let version = 2;
        let lengthLen = 0;

        if (length < 256) {
            lengthLen = 0;
        } else if (length > 255 && length < 65536) {
            lengthLen = 1;
        } else if (length > 65535 && length < 16777216) {
            lengthLen = 2;
        } else {
            lengthLen = 3;
        }

        const classIDLen = 3;
        let neededLength = 1 + (lengthLen + 1) + classIDLen + length;

        if (msg.reqServerPeerId > 0) {
            neededLength += 4;
        }
        if (zipped) {
            if (uncompressedSize >= FrameEncoder.MIN_SIZE_FOR_BIG_PKT) {
                neededLength += 4;
                version = 4;
            } else {
                neededLength += 2;
                version++;
            }
        }

        const encodedBuf = ByteBuffer.allocate(neededLength);
        let header = 0;

        header = (header << 3) | version;
        header = (header << 2) | lengthLen;

        if (msg.reqServerPeerId > 0) {
            header = (header << 1) | 1;
        } else {
            header = (header << 1) | 0;
        }

        header = header << 1;
        encodedBuf.put(header);

        switch (lengthLen) {
            case 0:
                encodedBuf.put(length);
                break;
            case 1:
                encodedBuf.putShort(length);
                break;
            case 2:
                encodedBuf.putMediumInt(length);
                break;
            case 3:
                encodedBuf.putInt(length);
                break;
        }

        if (zipped) {
            if (uncompressedSize >= FrameEncoder.MIN_SIZE_FOR_BIG_PKT) {
                encodedBuf.putInt(uncompressedSize);
            } else {
                encodedBuf.putShort(uncompressedSize);
            }
        }

        encodedBuf.putMediumInt(msg.classId);

        if (msg.reqServerPeerId > 0) {
            encodedBuf.putInt(msg.reqServerPeerId);
        }

        if (zipped) {
            encodedBuf.putBuffer(zippedByteArray);
        } else {
            encodedBuf.putBuffer(msg.serializedBytes);
        }

        return encodedBuf.flip();
    },
};
