import { Message } from '../../message';

export class BeginnerStatus extends Message {
    private MESSAGE_NAME: string = 'BeginnerStatus';
    private isFirstLogin: boolean | null = false;
    private remainingAPDs: number = 0;
    private allowedAPD: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, isFirstLogin: boolean | null = false, remainingAPDs: number = 0, allowedAPD: number = 0) {
        super(reqServerPeerId);
        this.isFirstLogin = isFirstLogin;
        this.remainingAPDs = remainingAPDs;
        this.allowedAPD = allowedAPD;
    }

    getIsFirstLogin(): boolean | null {
        return this.isFirstLogin;
    }

    setIsFirstLogin(isFirstLogin: boolean | null) {
        this.isFirstLogin = isFirstLogin;
    }
    getRemainingAPDs(): number {
        return this.remainingAPDs;
    }

    setRemainingAPDs(remainingAPDs: number) {
        this.remainingAPDs = remainingAPDs;
    }
    getAllowedAPD(): number {
        return this.allowedAPD;
    }

    setAllowedAPD(allowedAPD: number) {
        this.allowedAPD = allowedAPD;
    }
}
