<ng-container *ngIf="isCashgamesLobby; else spinsOrFFTemplate">
    <div class="filter-container">
        <div class="tables_count_text">
            <span class="totalTables_txt">Showing</span><span class="orange_txt">{{ filteredcount }}</span>
            <span class="totalTables_txt">of {{ totalcount }} Tables</span>
        </div>
        <div class="filters-list" [style.display]="viewContainerLength > 0 ? 'none' : 'block'">
            <ul class="filter-menu">
                <li class="filter-items" *ngFor="let filter of filtersConfig" (click)="navigateToSubFilters(filter)">
                    <div>
                        <div class="sidemenu_text">{{ filter.title }}</div>
                        <span class="selectedFilters">
                            <ng-container *ngIf="filter.maxFilterLength <= filter.filterData.length; else elseTemplate">
                                {{ 'PARTY_POKER_MOBILE_COMMON_ALL' | translate }}
                            </ng-container>
                            <ng-template #elseTemplate>
                                <ng-container *ngFor="let filterData of filter.filterData; let index = index">
                                    {{ filterData
                                    }}<ng-container
                                        *ngIf="
                                            index + 1 !== filter.filterData?.length &&
                                            filter.filterData?.length !== 1 &&
                                            !(filter.maxFilterLength === filter.filterData.length)
                                        ">
                                        ,&nbsp;</ng-container
                                    >
                                </ng-container>
                                <ng-container *ngIf="filter.filterData?.length === 0">{{
                                    'PARTY_POKER_AN_GEN_LOBBY_FILTERS_SORTING_NONE_SELECTED' | translate
                                }}</ng-container>
                            </ng-template>
                        </span>
                    </div>
                    <span class="theme-right icon-toCashier-icon"></span>
                </li>
            </ul>
        </div>
        <div class="filter-types" [style.display]="viewContainerLength > 0 ? 'block' : 'none'">
            <ng-container #viewContainer> </ng-container>
        </div>
        <div id="footer_fill_ff" class="footer_fill_cash mt-1 mx-3">
            <div class="filter_footer_width">
                <button type="button" class="btn btn-md btn-dark text-capitalize w-50 mr-2" (click)="resetFilters()">
                    {{ 'PARTY_POKER_GC_PREFERENCES_RESET' | translate }}
                </button>
                <button
                    type="button"
                    class="btn btn-md btn-primary text-capitalize w-50 ml-2"
                    [ngClass]="{ apply_btn: true, disableColor: isPlayButtonEnabled === false }"
                    [disabled]="isPlayButtonEnabled === false"
                    (click)="applyFilters()">
                    {{ 'PARTY_POKER_GC_COMMON_APPLY' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #spinsOrFFTemplate>
    <div class="full_height_ff px-3">
        <!-- ff filter header -->
        <div id="header_fil_ff" class="filter_template_head_ff">
            <span class="filter_title_ff">{{ 'PARTY_POKER_MOBILE_LOBBY_FILTER_FilterText' | translate }}</span>
            <span class="icon-close2 theme-close-small" (click)="closeNonCashFilter()"></span>
        </div>
        <div class="select_label_ff">
            <label class="filter_sub_txt_ff">{{ 'PARTY_POKER_MOBILE_LOBBY_FILTER_SELECTEDBUYINS' | translate }}</label>
        </div>
        <!-- ff filter body -->
        <div class="content_ff_fil">
            <div class="filter_buyins_ff">
                <div [ngClass]="{ 'horizontal_flex marT15 filter_scroll': true }">
                    <div class="btn_margin">
                        <button>All</button>
                    </div>
                    <div class="btn_margin">
                        <button [ngClass]="{ 'single-button_left': true }">25$</button>
                    </div>
                    <div class="btn_margin">
                        <button [ngClass]="{ 'single-button_left': true }">5$</button>
                    </div>
                    <div class="btn_margin">
                        <button [ngClass]="{ 'single-button_left': true }">5$</button>
                    </div>
                    <div class="btn_margin">
                        <button [ngClass]="{ 'single-button_left': true }">5$</button>
                    </div>
                    <div class="btn_margin">
                        <button [ngClass]="{ 'single-button_left': true }">5$</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- ff filter footer -->
        <div id="footer_fill_ff">
            <div class="txt_center filter_border">
                <span class="totalTables_txt"> {{ 'PARTY_POKER_MC_GAMESLOBBY_COMMON_TOTALTABLESFOUND' | translate }}:</span>
                <span class="orange_txt">10</span>
            </div>
            <div class="filter_footer_width">
                <button type="button" class="btn btn-md btn-dark text-capitalize w-50 ml-3 mr-4">
                    {{ 'PARTY_POKER_GC_PREFERENCES_RESET' | translate }}
                </button>
                <button type="button" class="btn btn-md btn-primary text-capitalize w-50 mr-3 ml-4">
                    {{ 'PARTY_POKER_GC_COMMON_APPLY' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
