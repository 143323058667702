import { Message } from '../../message';
import { ArjelPlayerCards } from './ArjelPlayerCards';

export class ArjelEndOfTheGameEventRes extends Message {
    private MESSAGE_NAME: string = 'ArjelEndOfTheGameEventRes';
    private conversationId: number = 0;
    private isValidGameData: boolean | null = false;
    private rakeDetails: Map<any, any> | null = null;
    private showCardsDetails: Map<any, any> | null = null;
    private tourneySessionids: Map<any, any> | null = null;
    private allPlayersCards: ArjelPlayerCards | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        conversationId: number = 0,
        isValidGameData: boolean | null = false,
        rakeDetails: Map<any, any> | null = null,
        showCardsDetails: Map<any, any> | null = null,
        tourneySessionids: Map<any, any> | null = null,
        allPlayersCards: ArjelPlayerCards | null = null,
    ) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.isValidGameData = isValidGameData;
        this.rakeDetails = rakeDetails;
        this.showCardsDetails = showCardsDetails;
        this.tourneySessionids = tourneySessionids;
        this.allPlayersCards = allPlayersCards;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getIsValidGameData(): boolean | null {
        return this.isValidGameData;
    }

    setIsValidGameData(isValidGameData: boolean | null) {
        this.isValidGameData = isValidGameData;
    }
    getRakeDetails(): Map<any, any> | null {
        return this.rakeDetails;
    }

    setRakeDetails(rakeDetails: Map<any, any> | null) {
        this.rakeDetails = rakeDetails;
    }
    getShowCardsDetails(): Map<any, any> | null {
        return this.showCardsDetails;
    }

    setShowCardsDetails(showCardsDetails: Map<any, any> | null) {
        this.showCardsDetails = showCardsDetails;
    }
    getTourneySessionids(): Map<any, any> | null {
        return this.tourneySessionids;
    }

    setTourneySessionids(tourneySessionids: Map<any, any> | null) {
        this.tourneySessionids = tourneySessionids;
    }
    getAllPlayersCards(): ArjelPlayerCards | null {
        return this.allPlayersCards;
    }

    setAllPlayersCards(allPlayersCards: ArjelPlayerCards | null) {
        this.allPlayersCards = allPlayersCards;
    }
}
