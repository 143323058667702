import { Message } from '../../message';

export class SessionTimeRemainingInfo extends Message {
    private MESSAGE_NAME: string = 'SessionTimeRemainingInfo';
    private sessionStatus: number = 0;
    private availableSessionLimit: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sessionStatus: number = 0, availableSessionLimit: number = 0) {
        super(reqServerPeerId);
        this.sessionStatus = sessionStatus;
        this.availableSessionLimit = availableSessionLimit;
    }

    getSessionStatus(): number {
        return this.sessionStatus;
    }

    setSessionStatus(sessionStatus: number) {
        this.sessionStatus = sessionStatus;
    }
    getAvailableSessionLimit(): number {
        return this.availableSessionLimit;
    }

    setAvailableSessionLimit(availableSessionLimit: number) {
        this.availableSessionLimit = availableSessionLimit;
    }
}
