import { Message } from '../../message';

export class ProbabilityOfWinning extends Message {
    private MESSAGE_NAME: string = 'ProbabilityOfWinning';
    private playerWinPercent: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerWinPercent: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.playerWinPercent = playerWinPercent;
    }

    getPlayerWinPercent(): Map<any, any> | null {
        return this.playerWinPercent;
    }

    setPlayerWinPercent(playerWinPercent: Map<any, any> | null) {
        this.playerWinPercent = playerWinPercent;
    }
}
