import { Message } from '../../message';

export class CompositeTableMessage extends Message {
    private MESSAGE_NAME: string = 'CompositeTableMessage';
    private messages: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, messages: any[] | null = null) {
        super(reqServerPeerId);
        this.messages = messages;
    }

    getMessages(): any[] | null {
        return this.messages;
    }

    setMessages(messages: any[] | null) {
        this.messages = messages;
    }
}
