import { Message } from '../../message';

export class Dealer extends Message {
    private MESSAGE_NAME: string = 'Dealer';
    private dealerId: string | null = null;
    private seatingPosition: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, dealerId: string | null = null, seatingPosition: number = 0) {
        super(reqServerPeerId);
        this.dealerId = dealerId;
        this.seatingPosition = seatingPosition;
    }

    getDealerId(): string | null {
        return this.dealerId;
    }

    setDealerId(dealerId: string | null) {
        this.dealerId = dealerId;
    }
    getSeatingPosition(): number {
        return this.seatingPosition;
    }

    setSeatingPosition(seatingPosition: number) {
        this.seatingPosition = seatingPosition;
    }
}
