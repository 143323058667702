import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { SpinsOverdriveLobbyComponent } from '../../lobby/spins-overdrive/spins-overdrive-lobby.component';
import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { FxRateHelperClassService } from '../../service/Fxratemanager';
import { MathUtils } from '../../service/MathUtil';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { SpinGameService } from '../../service/spin-game.service';
import { selectMiniTableState } from '../../store/mini-table.store';
import { userBalanceSelect } from '../../store/user.store';
import { PDollarInfoPopUpComponent } from '../p-dollar-info-pop-up/p-dollar-info-pop-up.component';

@Component({
    selector: 'pwc-spins-buyin',
    templateUrl: 'spins-buyin.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        SpinsOverdriveLobbyComponent,
        PDollarInfoPopUpComponent,
        TranslatePipe,
        FormatamountPipe,
        formatCurrencyPipe,
    ],
    providers: [MathUtils],
})
export class SpinsBuyInPopUpComponent implements OnInit, OnDestroy {
    @Input() selectedcardindex: any;
    @Input() expospinData: any;
    @Input() sngjpid: any;
    @Input() buyInData: any;
    @Output() close = new EventEmitter<void>();
    // sngjpcards: any;
    // currentCardSelectedIndex: any;
    // tourneyBalance: any;
    // userBalance: { mainBalance: any; tourneyBalance: any; };
    // isTourneyBalanceEnable: any;
    // totalBalance: any;
    // cashAlone: boolean;
    public totalBuyinValue: any;
    public cash: number = 1;
    // public gbpvalue="GBP";
    public cashAlone: boolean = false;
    // public cardData: any;
    public totalBuyinInCent: any;
    public tourneyFeeValue: any;
    public expoSpinData: any;
    public usdToGbpVal: any;
    public noOfTicket: number;
    public noOfGames: number = 1;
    public enableRegister: boolean = false;
    expoSpinBuyIn: any;
    public buyinvalue;
    public isDecimal = false;
    public leastTicketsAlert: boolean = false;
    public alert: boolean = false;
    public availableTicket: number;
    public availableBalance: any;
    public tableAddMaxRange: any;
    public conversionFactor4Client = 1;
    public userBalance: any;
    public mainBalance;
    public tourneyBalance = 0;
    public totalBalance = 0;
    public isBalanceInfoEnabled = false;
    cashTicket: boolean = false;
    public userCurrency;
    public isTourneyBalanceEnable: boolean;
    public isRebuyClicked = false;
    includingLiteral: string;
    includingMsg: string;
    durationTimerInSec = 30;
    buyInProgressBarWidth = 100;
    durationTimerLeftInSec: any;
    spincount: any;
    public accountCurrency = '';
    expoSpinGameCount: any;
    // buyInData: any;
    gbpvalue: any = 'GBP';
    // finalData: object[];
    buyInInterval: any;
    activeGameCount: number;
    availableGameCount: any;
    gameBuyIn: any;
    buyInGbp: any;
    private readonly logger: PokerLogger;
    miniTable: any;
    FxRateManager;
    conversionFactor4balance = 1;

    constructor(
        private store: Store,
        private formatamountPipe: FormatamountPipe,
        private spinGameService: SpinGameService,
        private translatePipe: TranslatePipe,
        loggerFactory: LoggerFactory,
        private mathUtil: MathUtils,
        private clientToServer: ClientToServerRequestServices,
    ) {
        // this.expoSpinBuyIn = this.BuyInInfo.bind(this);
        this.startCountdown(this.durationTimerInSec);
        this.FxRateManager = FxRateHelperClassService.getInstance();
        this.logger = loggerFactory.getLogger('spinsBunInLoggingComponent');
        this.store.select(userBalanceSelect).subscribe((data) => {
            if (!_.isEmpty(data) && !_.isEmpty(data['netRealBalance'])) {
                this.accountCurrency = data['netRealBalance']['accountCurrency'];
                this.conversionFactor4balance = this.FxRateManager.getFxRateWithOutSnapShotId(this.accountCurrency, 'USD', 'cashgames');
                this.userBalance =
                    data['netRealBalance']['accountBalance'] * this.conversionFactor4balance +
                    (data['tourneyCurrencyBalance'] != null ? data['tourneyCurrencyBalance']['tourneyCurrencyBalance'] : 0);
            }
        });
    }

    ngOnInit() {
        this.store.select(selectMiniTableState).subscribe((data: any) => {
            this.miniTable = Object.keys(data.miniTables).length;
            this.expoSpinGameCount = data.spinsTableCount;
        });
        this.expoSpinData = this.expospinData;
        //  this.expoSpinData=this.buyInData;
        //  this.expoSpinData=this.selectedcardindex;
        // this.balanceService.balanceProperties.subscribe((info: any) => {
        //   if (info) {
        //     this.userBalance = "$" + info.accountBalance;
        //     // this.accountCurrency = info.accountCurrency.id;
        //   }
        // });
        // this.BuyInInfo(this.expoSpinData);
        this.totalBuyinInCent = this.selectedcardindex.buyIn + this.selectedcardindex.tourneyFee;
        // this. buyinvalue =this.selectedcardindex.gameCurrency + (this.totalBuyinInCent/100);
        this.buyinvalue = '$' + this.totalBuyinInCent / 100;
        this.gameBuyIn = '$' + this.noOfGames * (this.totalBuyinInCent / 100);
        // let temp = this.mathUtil.getFormattedAmountValue(this.noOfGames * this.totalBuyinInCent, 'GBP', true);
        this.buyInGbp = this.mathUtil.getFormattedAmountValue(this.noOfGames * this.totalBuyinInCent, 'GBP', true);
        // this.formatCurrencyPipe.transform(this.noOfGames * (this.totalBuyinInCent),"GBP");

        this.totalBuyinValue = `${this.gameBuyIn} ( ${this.buyInGbp} ${this.gbpvalue} )`;
        let gbpVal = this.mathUtil.getFormattedAmountValue(100, 'EUR', false);
        let dolVal = this.FxRateManager.getFxRateWithOutSnapShotId('EUR', 'USD', 'cashgames');
        this.usdToGbpVal = gbpVal + ' = ' + this.mathUtil.getFormattedAmountValue(dolVal * 100, 'USD', true);

        this.tourneyFeeValue = this.formatamountPipe.transform(
            this.selectedcardindex.tourneyFee * this.noOfGames,
            this.selectedcardindex.gameCurrency,
        );
        this.includingLiteral = this.translatePipe.transform('PARTY_POKER_GC_SNGJP_BUYIN_TOURNEYFEE');
        this.includingMsg = this.includingLiteral.replace('%1s', this.tourneyFeeValue);
        this.getGameCountFromLocal();
        this.availableTicket = this.expoSpinData.availableTickets;
        this.noOfTicket = this.availableTicket > 0 ? Math.min(this.availableTicket, this.noOfGames) : 0;
        if (this.expoSpinData.responseCode == 0 && this.expoSpinData.availableTickets > 0) {
            this.cashAlone = true;
            this.cashTicket = true;
            this.noOfTicket = 0;
        }
        if (this.expoSpinData.responseCode == 1 && this.expoSpinData.availableTickets > 0) {
            this.cashAlone = true;
        }
        if (
            (this.noOfGames > this.availableTicket && this.cashTicket == true) ||
            (this.availableTicket < 1 && this.cashAlone == false) ||
            (this.noOfTicket < this.availableTicket && this.userBalance < this.totalBuyinInCent)
        ) {
            this.validateEnoughBalance();
        }
        this.validateTickets();
    }
    closePopup() {
        this.close.emit();
    }

    loadgame() {
        this.logger.info('clicked on Register..!');
        this.spinGameService.SNGJPRegistrationRequest(this.sngjpid, this.noOfGames, this.noOfTicket, 0, 0);
        this.closePopup();
        // this.navigation.goTo('/play/game-table');
    }
    getGameCountFromLocal() {
        const localSpinGameCountStr = localStorage.getItem('spinsGameCount') ? localStorage.getItem('spinsGameCount') : null;
        const localSpinGameCount = localSpinGameCountStr === null ? 1 : parseInt(localSpinGameCountStr);
        // let localSpinGameCount = parseInt(localStorage.getItem("spinsGameCount"));
        if (4 - this.miniTable >= localSpinGameCount) this.noOfGames = localSpinGameCount;
        else this.noOfGames = 4 - this.miniTable;
        this.tableAddMaxRange = 4 - this.miniTable; //this.availableGameCount == 5 ? 4 : this.availableGameCount;
        // this.store.dispatch(updateSpinsTableCount({ spinsTableCount:this.noOfGames}));
        // localStorage.setItem("spinsGameCount",JSON.stringify(this.noOfGames));
        this.gameBuyIn = '$' + this.noOfGames * (this.totalBuyinInCent / 100);
        // this.buyInGbp = "£" + (this.noOfGames * ((this.totalBuyinInCent / 100) / 2) + 0.14);
        this.buyInGbp = this.mathUtil.getFormattedAmountValue(this.noOfGames * this.totalBuyinInCent, 'GBP', true);
        this.tourneyFeeValue = this.formatamountPipe.transform(
            this.selectedcardindex.tourneyFee * this.noOfGames,
            this.selectedcardindex.gameCurrency,
        );
        this.includingMsg = this.includingLiteral.replace('%1s', this.tourneyFeeValue);
        this.totalBuyinValue = `${this.gameBuyIn} ( ${this.buyInGbp} ${this.gbpvalue} )`;
        if (
            (this.noOfGames > this.availableTicket && this.cashTicket == true) ||
            (this.availableTicket < 1 && this.cashAlone == false) ||
            (this.noOfTicket < this.availableTicket && this.userBalance < this.totalBuyinInCent)
        ) {
            this.validateEnoughBalance();
        }
        this.validateTickets();
    }
    updateGames(str: string) {
        if (str == 'sub' && this.noOfGames > 1) {
            if (this.noOfTicket > 0 && this.cashTicket == false && this.noOfTicket == this.noOfGames) {
                this.noOfTicket--;
            }
            this.noOfGames--;
            if (this.noOfGames < this.noOfTicket) {
                this.noOfTicket = this.noOfGames;
            }
        }
        if (str == 'add' && this.noOfGames < this.tableAddMaxRange) {
            if (this.cashTicket == false && this.noOfTicket < this.availableTicket && this.noOfTicket == this.noOfGames) {
                this.noOfTicket++;
            }
            this.noOfGames++;
        }
        if (
            (this.noOfGames > this.availableTicket && this.cashTicket == true) ||
            (this.availableTicket < 1 && this.cashAlone == false) ||
            (this.noOfTicket < this.availableTicket && this.userBalance < this.totalBuyinInCent)
        ) {
            this.validateEnoughBalance();
        } else {
            this.alert = false;
        }
        // this.store.dispatch(updateSpinsTableCount({ spinsTableCount: this.noOfGames }));
        this.gameBuyIn = '$' + this.noOfGames * (this.totalBuyinInCent / 100);
        // this.buyInGbp = "£" + (this.noOfGames * ((this.totalBuyinInCent / 100) / 2) + 0.14);
        this.buyInGbp = this.mathUtil.getFormattedAmountValue(this.noOfGames * this.totalBuyinInCent, 'GBP', true);
        this.tourneyFeeValue = this.formatamountPipe.transform(
            this.selectedcardindex.tourneyFee * this.noOfGames,
            this.selectedcardindex.gameCurrency,
        );
        this.includingMsg = this.includingLiteral.replace('%1s', this.tourneyFeeValue);
        this.totalBuyinValue = `${this.gameBuyIn} ( ${this.buyInGbp} ${this.gbpvalue} )`;
        // this.usdToGbpVal = '€1 = $1.18';
        // let gbpVal = this.mathUtil.getFormattedAmountValue(100, 'EUR', true);
        // let dolVal = this.FxRateManager.getFxRateWithOutSnapShotId('EUR', 'USD', 'cashgames');
        // this.usdToGbpVal = gbpVal + ' = ' + this.mathUtil.getFormattedAmountValue(dolVal * 100, 'USD', true);
        localStorage.setItem('spinsGameCount', JSON.stringify(this.noOfGames));
        this.validateTickets();
    }
    validateTickets() {
        if (
            (this.noOfGames > this.availableTicket && this.cashTicket == true) ||
            (this.availableTicket < 1 && this.cashAlone == false) ||
            (this.noOfTicket < this.availableTicket && this.userBalance < this.totalBuyinInCent)
        ) {
            this.validateEnoughBalance();
        } else {
            this.alert = false;
        }
        if (this.availableTicket < this.noOfGames && this.availableTicket != 0 && this.availableTicket !== undefined) {
            this.leastTicketsAlert = true;
        } else this.leastTicketsAlert = false;
    }
    updateTickets(str: string) {
        if (this.cashTicket == true) {
            if (str == 'minus' && this.noOfTicket > 0) {
                this.noOfTicket--;
            }
            if (str == 'plus' && this.noOfTicket < this.availableTicket && this.noOfTicket < this.noOfGames) {
                this.noOfTicket++;
            }

            this.validateTickets();
        }
    }
    validateEnoughBalance() {
        this.logger.info('clicked on - and + icons..!');
        if (this.expoSpinData.accountBalance < this.totalBuyinInCent * this.noOfGames) {
            this.alert = true;
        } else {
            this.alert = false;
        }
    }
    // BuyInInfo(data:any) {
    //   this.availableTicket = this.expoSpinData.availableTickets;
    //   this.noOfTicket = this.availableTicket >0 ? 1 :0;
    //   console.log("availalbe bal" + this.availableTicket);
    //   if(this.expoSpinData.accountBalance<this.totalBuyinInCent ){
    //     this.alert=true;
    //   }
    //   else{
    //     this.alert=false;
    //   }
    // }
    onClose() {
        this.logger.info('clicked on close buttion in buyin popup..!');
        this.close.emit();
    }
    openCashierPage() {
        this.clientToServer.openDepositPage('deposit');
    }
    openBalanceInfoPopup() {
        this.logger.info('clicked on info icon in buyin popup..!');
        this.isBalanceInfoEnabled = true;
    }
    closeBalanceInfoPopup() {
        this.logger.info('clicked on info icon in buyin popup close buttion..!');
        this.isBalanceInfoEnabled = false;
    }
    startCountdown(duration: number) {
        let timeLeft = duration;
        this.buyInInterval = setInterval(() => {
            if (timeLeft <= 0) {
                clearInterval(this.buyInInterval);
                this.onClose();
            }
            timeLeft -= 1;
            // console.log('...',timeLeft,  Number((timeLeft * 100 / duration).toFixed()));
            this.buyInProgressBarWidth = Number(((timeLeft * 100) / duration).toFixed());
            this.durationTimerLeftInSec = timeLeft;
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.buyInInterval);
    }
}
