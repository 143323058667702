import { Component, Input, OnChanges } from '@angular/core';

import { of } from 'rxjs';

import { GameTable } from '../../models/game-table';
import { Player } from '../../models/player';
import { Properties } from '../../utils/properties';
import { DemoGameTableService } from '../game-table/demo-game-table.service';

@Component({
    selector: 'pkr-player-pot',
    templateUrl: './player-pot.component.html',
})
export class PlayerPotComponent implements OnChanges {
    @Input() player: Player;
    @Input() gameTable: GameTable;
    @Input() toPot: any;

    properties: Properties;
    posx: any;
    posy: any;
    chiparray: any = [];
    chipKeys: any = [];
    value: any;
    isMobile: any;
    amount: any;
    greaterAmount: boolean = false;
    arr: any = [];
    itemsInRow: number = 4;
    itemInRowRange: any;
    items: any;

    //replayerV3
    potNo: any;
    getLastPotId: any;

    constructor(public gameTableService: DemoGameTableService) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(temp: any) {
        this.isMobile = this.gameTableService.isMobile;
        this.noOfChips(this.toPot);
        this.formatCount(this.player.toPot.value, true, 2, this.isMobile);
    }

    getXAxis() {
        this.posx = `${this.player.playerChipPos.x}%`;
        return this.posx;
    }
    getYAxis() {
        this.posy = `${this.player.playerChipPos.y}%`;
        return this.posy;
    }

    getDollars(val: number) {
        return val;
    }

    getCents(val: any) {
        return val;
    }

    noOfChips(totalVal: any) {
        const noOfChiparray: any = [];
        let obj: any;
        let denom;
        const combo: any = new Map();
        if (this.isMobile) {
            //refer   = [ $5000000000, $1000000000, $250000000, $50000000, $10000000, $5000000, $1000000,  $500000,   $100000, $25000, $5000,  $1000   $500,  $100, $25,  $5, $1,  50c, 25c, 10c, 5c,1c ]
            denom = [
                100000000000, 50000000000, 10000000000, 2500000000, 500000000, 100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000,
                2500, 500, 100, 50, 25, 10, 5, 1,
            ];
        } else {
            //for desktop/VC
            denom = [
                100000000000, 50000000000, 10000000000, 2500000000, 500000000, 100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000,
                2500, 500, 100, 25, 5, 1,
            ];
        }
        // let denom = [100000000000, 50000000000, 10000000000, 2500000000, 500000000, 100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000, 2500, 500, 100, 25, 5, 1];
        for (let i = 0; i < denom.length; i++) {
            if (totalVal <= 0) break;
            let nchips: any = Math.floor(totalVal / denom[i]);
            if (nchips == 0) continue;
            const nVal = denom[i];
            totalVal -= nVal * nchips;
            for (let j = 0; j < nchips; j++) {
                if (nchips > 0) {
                    const chipM: any = new Map();
                    this.player.chipMap.set(this.player.seatNo, chipM);
                    chipM.set(nVal, nchips);
                    combo.set(nVal, nchips);
                    nchips = Array(nchips).fill(1);
                    obj = [
                        {
                            nval: nVal ? `c${nVal}` : 0,
                            nChips: nchips ? nchips : 0,
                        },
                    ];
                    noOfChiparray[nVal] = obj;
                    this.chiparray = noOfChiparray;
                    this.chipKeys = Object.keys(this.chiparray);
                }
            }
        }
        if (combo.size > 0) {
            let i = 0;
            this.arr = [];
            const iterate = combo.values();
            const iterate1 = combo.keys();
            let check = iterate.next().value;
            let check2 = iterate1.next().value;
            for (let k = 0; k < combo.size; k++) {
                for (let j = 0; j < check; j++) {
                    this.arr[i] = `c${check2}`;
                    i++;
                }
                check = iterate.next().value;
                check2 = iterate1.next().value;
            }
        }
        this.itemInRowRange = Array.from(Array(this.itemsInRow).keys());
        this.items = of(this.arr);
    }

    formatCount(count: any, withAbbr: boolean, decimals: number, isMobile: boolean) {
        let result: any;
        this.findOutPotNumber(count);
        count = count / 100;
        if (isMobile && count > 999999) {
            const COUNT_ABBRS: any = ['', '', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
            const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
            result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
            if (withAbbr) {
                result += `${COUNT_ABBRS[i]}`;
            }
            this.amount = result;
        } else {
            if (count >= 10000 && count < 1000000) {
                count = count.toFixed(0);
            }
            this.amount = count;
            //this.amount = this.amount.toString();
            if (!this.gameTable.isTourny) {
                this.amount = this.amount.toLocaleString(undefined, { minimumFractionDigits: 2 });
            }
            this.amount = this.amount.toString();
            this.amount = this.amount.replace(/\.00$/, '');
            this.amount = this.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        if (this.amount.length > 6) {
            this.greaterAmount = true;
        }
    }

    findOutPotNumber(amount: any) {
        this.getLastPotId = this.gameTable.TotalPotIdsAtWinhigh[this.gameTable.TotalPotIdsAtWinhigh.length - 1];
        if (this.gameTable.winPotSeatMap.size > 1 && this.gameTable.getTotalSidePotslength > 1) {
            if (!this.gameTable.isRit) {
                for (const [key, value] of this.gameTable.playerVsWinAmout.entries()) {
                    if (value === amount) {
                        this.potNo = this.gameTable.winPotSeatMap.get(key);
                    }
                }
            } else {
                for (const [key, value] of this.gameTable.RITplayerVsWinAmount.entries()) {
                    if (value === amount) {
                        this.potNo = this.gameTable.winPotSeatMap.get(key);
                    }
                }
            }
        } else {
            this.potNo = -1;
        }
    }
}
