import { Message } from '../../message';

export class RTPointsUpdate extends Message {
    private MESSAGE_NAME: string = 'RTPointsUpdate';
    private isPointsEnabled: boolean | null = false;
    private playerCategory: string | null = null;
    private points: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, isPointsEnabled: boolean | null = false, playerCategory: string | null = null, points: number = 0) {
        super(reqServerPeerId);
        this.isPointsEnabled = isPointsEnabled;
        this.playerCategory = playerCategory;
        this.points = points;
    }

    getIsPointsEnabled(): boolean | null {
        return this.isPointsEnabled;
    }

    setIsPointsEnabled(isPointsEnabled: boolean | null) {
        this.isPointsEnabled = isPointsEnabled;
    }
    getPlayerCategory(): string | null {
        return this.playerCategory;
    }

    setPlayerCategory(playerCategory: string | null) {
        this.playerCategory = playerCategory;
    }
    getPoints(): number {
        return this.points;
    }

    setPoints(points: number) {
        this.points = points;
    }
}
