<span class="close-btn close-menu-trigger"><span>Close</span></span>
<div class="">
    <section class="pm-area-navigation">
        <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
            <ul id="productMenu" class="navbar-nav">
                <!-- {{navItems | json}} -->
                <ng-container *ngFor="let item of navItems | dsl | async; let menuIndex = index">
                    <li *ngIf="!item.children; else withChildren" class="nav-item">
                        <a
                            *ngIf="item.url"
                            [class]="getNavItemsClass(isMobile, item)"
                            [target]="item.target ? item.target : '_self'"
                            [href]="item.url"
                            (click)="removeBodyClass($event, item.url); pkNavBarTracking(item.url)"
                            [vnDynamicHtml]="item.text"></a>
                        <span
                            *ngIf="!item.url || item.url === 'null' || item.url === 'undefined'"
                            [class]="getNavItemsClass(isMobile, item)"
                            (click)="removeBodyClass($event, item.url); pkNavBarTracking(item.url)"
                            [vnDynamicHtml]="item.text"></span>
                    </li>
                    <ng-template #withChildren>
                        <li (click)="toggleMenuOnClick($event)" class="nav-item dropdown">
                            <span class="nav-link dropdown-toggle" data-toggle="dropdown">{{ item.text }}</span>
                            <div class="dropdown-menu">
                                <ng-container *ngFor="let subItem of item.children; let subMenuIndex = index">
                                    <a
                                        [class]="getChildrenClass(isMobile, item)"
                                        [target]="subItem.target ? subItem.target : '_self'"
                                        [href]="subItem.url"
                                        (click)="removeBodyClass($event, subItem.url); pkNavBarTracking(subItem.url)"
                                        >{{ subItem.text }}</a
                                    >
                                </ng-container>
                            </div>
                        </li>
                    </ng-template>
                </ng-container>
            </ul>
        </nav>
    </section>
</div>
