import { Message } from '../../message';

export class PlayerAccountType extends Message {
    private MESSAGE_NAME: string = 'PlayerAccountType';
    private isTestAccount: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, isTestAccount: boolean | null = false) {
        super(reqServerPeerId);
        this.isTestAccount = isTestAccount;
    }

    getIsTestAccount(): boolean | null {
        return this.isTestAccount;
    }

    setIsTestAccount(isTestAccount: boolean | null) {
        this.isTestAccount = isTestAccount;
    }
}
