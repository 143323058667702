import { Message } from '../../message';

export class LSTourneyLateRegistrationChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyLateRegistrationChange';
    private lateRegInProgress: boolean | null = false;
    private lrCloseTime: number = 0;
    private remainingLRTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lateRegInProgress: boolean | null = false, lrCloseTime: number = 0, remainingLRTime: number = 0) {
        super(reqServerPeerId);
        this.lateRegInProgress = lateRegInProgress;
        this.lrCloseTime = lrCloseTime;
        this.remainingLRTime = remainingLRTime;
    }

    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getLrCloseTime(): number {
        return this.lrCloseTime;
    }

    setLrCloseTime(lrCloseTime: number) {
        this.lrCloseTime = lrCloseTime;
    }
    getRemainingLRTime(): number {
        return this.remainingLRTime;
    }

    setRemainingLRTime(remainingLRTime: number) {
        this.remainingLRTime = remainingLRTime;
    }
}
