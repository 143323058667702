import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';

export class ControlPanelViewModel {
    gameService;
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        if (!this.peerId) {
            this.peerId = this.gameService.getPeerId();
        }
        this.view = view;
        this.optionsShown = false;
        this.view.currentTablePeerId(this.peerId);
        this.peerId = this.gameService.getPeerId();
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_AUTO_BET_OPTIONS, this.initiateAutoButtons.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_NEW_GAME, this.newGameStarted.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_BET_OPTIONS, this.initiateButtons.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_DISCONNECTION_STATE, this.handleDisconnection.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_SHOWCARD_OPTIONS, this.initiateShowCardsOptions.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_MUCKCARD_OPTIONS, this.initiateMuckButtons.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_IAM_BACK, this.showIamBackBtn.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.TIMER_ANI_DONE, this.onOptionSetTimeOut.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ACTION_FOLD, this.forceFold.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_POT_WINNERS, this.hideAllButtons.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION, this.handleSelectedOption.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_IAM_BACK, this.hideIamBack.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CARDS, this.handleShowCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_POTAMOUNT_IN_RAISEPANEL, this.updatePotAmountForRaise.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_TIMER_ANIM, this.updateTimerAnimation.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION, this.handleSelectedOpt.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_NEXT_HAND, this.showNextHand.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_CASHOUT_BUTTONS, this.enablecashbuttons.bind(this));
    };

    enablecashbuttons = function () {
        this.view.enablecashbuttons();
    };
    updatePotAmount = function (amount) {
        this.view.updatePotAmount(amount);
    };
    handleShowCards = function () {
        const obj = this.gameService.getShowCardsData();
        if (obj && this.gameService.getClientSideSeatNo(obj.seatNo) == uiConfig.selfUserClientPosInd) {
            this.view.hideAllButtons();
        }
    };
    handleSelectedOption = function () {
        this.optionsShown = false;
        const selectedOptionData = this.gameService.getOtherPlayerSelectedOption();
        if (selectedOptionData && this.gameService.getClientSideSeatNo(selectedOptionData.seatNo) == uiConfig.selfUserClientPosInd) {
            this.gameService.setAutoSelectedBetOption(null);
            this.view.hideAllButtons();
        }
    };
    initiateButtons = function () {
        this.optionsShown = true;
        if (this.gameService.getAutoCheckEnabled()) {
            this.sendSelectOptionDefault(serverIdConstants.OPTION_FOLD);
            return;
        }
        const optionSetData = this.gameService.getOptionSetData();
        if (this.useAutoOptions(optionSetData)) return;
        //since no AutoOption is used, clear them
        this.gameService.setAutoSelectedBetOption(null);
        this.view.hideIamBack();
        uiConfig['tableConfig' + this.peerId].playerOptionsVisible = true;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_TIME_BANK_SYMBOL);
        if (!uiConfig.selfUserId) {
            //uiConfig.selfUserId = window.parent.LSConnector.mainConnector.selfUserId;
            uiConfig.selfUserId = 0;
        }
        this.view.updateButtonData(
            optionSetData,
            this.gameService.getUserBalance(uiConfig.selfUserId),
            this.gameService.getBigBlind(),
            this.getUserBetVectorAmount(),
        );
        if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.view.updatePotAmount(
                this.gameService.getEndTheRoundData(),
                this.gameService.getTableStatusData().bigBlind,
                this.gameService.getRoundCount(),
            );
        } else {
            this.view.updatePotAmount(this.gameService.getEndTheRoundData(), this.gameService.getHighStake(), this.gameService.getRoundCount());
        }
    };
    initiateAutoButtons = function () {
        if (this.gameService.getAutoCheckEnabled()) return;
        this.view.hideIamBack();
        this.optionsShown = true;
        this.view.updateAutoButtonData(this.gameService.getAutoOptionSetData());
    };
    initiateShowCardsOptions = function () {
        this.view.hideAllButtons();
        this.view.updateShowCardsOptions(this.gameService.getShowCardsOptionsData());
    };
    initiateMuckButtons = function () {
        this.view.hideAllButtons();
        this.view.updateMuckButtonData(this.gameService.getMuckCardsData());
    };
    betOptionSelected = function (optionData) {
        setTimeout(
            function () {
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_PLAYER_CLICK);
            }.bind(this),
            300,
        );
        this.optionsShown = false;
        this.view.hideAllButtons();
        uiConfig['tableConfig' + this.peerId].playerOptionsVisible = false;
        this.gameService.setSelectedBetOption(optionData);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.BET_OPTION_SELECTED);
        clearTimeout(this.timeOut);
    };
    selectedCashoutOption = function (isSelected) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SELECTED_CASHOUT_OPTION, { isSelected: isSelected });
    };
    showIamBackBtn = function () {
        if (!this.optionsShown) {
            this.view.showIamBackBtn();
            this.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_IAMBACK);
        }
    };
    getStakeValues = function () {
        this.view.setStakeValues(this.gameService.getLowStake(), this.gameService.getHighStake());
    };
    iamBackClicked = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.IAM_BACK_CLICKED);
    };
    resetGame = function () {
        //this.optionsShown = false;
        this.view.resetGame();
        clearTimeout(this.timeOut);
        this.gameService.setAutoSelectedBetOption(null);
        this.gameService.setAutoCheckEnabled(false);
        //this.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_NONE);
    };
    handleDisconnection = function () {
        this.optionsShown = false;
        this.resetGame();
        this.view.hideAllButtons();
    };
    newGameStarted = function () {
        this.optionsShown = false;
        this.resetGame();
    };
    autoBetOptionSelected = function (autoOptionsData) {
        //need to maintain the state;
        //this.view.visible = false;
        this.optionsShown = false;
        uiConfig['tableConfig' + this.peerId].playerOptionsVisible = false;
        this.gameService.setAutoSelectedBetOption(autoOptionsData);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.AUTO_BET_OPTION_SELECTED);
    };
    isOptionSetTimedOut = function (optionSetData) {
        const optionSetActualTimeOutTime = optionSetData.generatedAt + optionSetData.betTimeOutPeriod;
        const presentTime = this.gameTableNotificationService.getTimeForPeer(this.gameService.getPeerId());
        if (presentTime < optionSetActualTimeOutTime) {
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(this.onOptionSetTimeOut.bind(this), (optionSetActualTimeOutTime - presentTime) * 1000);
            return false;
        }
        return true;
    };
    //TIMEBANK: this method gets invoked when optionset gets timed out
    //if timebank is available it gets reset to ZERO
    //and sends reqEnableTimeBank to server
    onOptionSetTimeOut = function () {
        if (this.gameService.getCurrentShowingOption() != serverIdConstants.SHOWING_PLAY_OPTIONS) return;
        const optionSetData = this.gameService.getOptionSetData();
        if (optionSetData == null || optionSetData == undefined) return;
        this.view.removeBetTimerAnimation();
        //this is extra check to stop timeouts in case of disconnections
        if (!this.isOptionSetTimedOut(optionSetData)) return;
        //this.view.removeBetTimerAnimation();
        const timeBankAvaiableTime = this.gameService.getTimeBankBalance() ? this.gameService.getTimeBankBalance() : 0;
        if (optionSetData && timeBankAvaiableTime > 0 && (optionSetData.timeBankAutoEnable || this.gameService.getEnableTimeBankBeforeFlop())) {
            const obj: any = {};
            // const peerTime = this.gameTableNotificationService.getTimeForPeer(this.peerId);
            obj.timeBankTimeoutAt = optionSetData.generatedAt + optionSetData.timeBankAvailableTime + optionSetData.betTimeOutPeriod;
            obj.optionSetId = optionSetData.optionSetId;
            obj.timeBankAutoEnabled = true;
            this.gameService.setTimeBankData(obj);
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_TIME_BANK);
            //update the existing optionSet to reset timebank availableTime
            optionSetData.betTimeOutPeriod += optionSetData.timeBankAvailableTime;
            optionSetData.timeBankAutoEnable = false;
            optionSetData.timeBankAvailableTime = 0;
            this.gameService.setOptionSetData(optionSetData);
        } else {
            if (this.gameService.getCurrentShowingOption() && timeBankAvaiableTime <= 0) {
                this.optionsShown = true;
            } else {
                this.optionsShown = false;
            }
            this.resetGame();
            this.sendSelectOptionDefault(serverIdConstants.OPTION_NO_ACTION);
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_FORCE_AWAY_STATE);
            this.showIamBackBtn();
        }
        clearTimeout(this.timeOut);
    };
    sendSelectOptionDefault = function (defaultAction) {
        this.optionsShown = false;
        const optionSetData = this.gameService.getOptionSetData();
        if (optionSetData == undefined || optionSetData == null) return;
        const optionNone = optionSetData.optionSet[0]; //override the option
        optionNone.optionId = defaultAction;
        optionNone.amount = 0;
        //optionNone.optionTitle = "";
        for (const b in optionSetData.optionSet) {
            if (optionSetData.optionSet[b].optionId == serverIdConstants.OPTION_CHECK) {
                optionNone.optionId = serverIdConstants.OPTION_CHECK;
                if (!this.gameService.getAutoCheckEnabled()) {
                    this.gameService.setAutoCheckEnabled(true);
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SEND_REQUEST_AUTO_CHECK_ON);
                }
            } else if (optionSetData.optionSet[b].optionId == serverIdConstants.OPTION_BRING_IN) {
                optionNone.optionId = serverIdConstants.OPTION_BRING_IN;
                this.gameService.setAutoCheckEnabled(true);
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SEND_REQUEST_AUTO_CHECK_ON);
            }
        }
        this.betOptionSelected(optionNone);
    };
    takeFoldConfirmation = function () {
        const freeFoldPopUpConfig: any = uiConfig.genericPopUpConfig;
        freeFoldPopUpConfig.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_TABLE_COMMON_FOLDHAND');
        freeFoldPopUpConfig.bodyText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_TABLE_COMMON_FOLDCONFIRMATION');
        freeFoldPopUpConfig.buttonsTextArr = [
            CommonGameProperties.langPack['getLiteral']('PARTY_COMMON_GC_YES'),
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_COMMON_NO'),
        ];
        freeFoldPopUpConfig.buttonsActionArr = [SlotCoreGameEvent.ACTION_FOLD, ''];
        freeFoldPopUpConfig.popUpId = 'PARTY_POKER_MC_TABLE_COMMON_FOLDHAND';
        this.gameService.setAddPopUpData(freeFoldPopUpConfig);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ADD_GENERIC_POPUP);
    };
    forceFold = function () {
        this.view.forceFold();
    };
    getTableLimitType = function () {
        return this.gameService.getTableLimitType();
    };
    getUserBetVectorAmount = function () {
        return this.gameService.getBetVector(this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd));
    };
    hideAllButtons = function () {
        this.view.hideAllButtons();
    };
    hideIamBack = function () {
        this.view.hideIamBack();
    };
    useAutoOptions = function (optionSetData) {
        const autoSelectedOption = this.gameService.getAutoSelectedBetOption();
        if (!autoSelectedOption) return false;
        const selectedAutoOptionId = autoSelectedOption.autoOptionId;
        const autoOptionToOptionMappings = CommonGameProperties.facade['getAutoOptionToOptionsMappings']();
        //  const autoOptionToOptionMappings:any = {};
        if (!autoOptionToOptionMappings) return false;
        //iterate on autoOptionMappings to search selected autoOption
        for (const mapping of autoOptionToOptionMappings.mappings) {
            if (mapping.autoOptionId == selectedAutoOptionId) {
                const selectOption = this.useMapping(mapping, optionSetData, autoSelectedOption);
                if (selectOption != null) {
                    this.betOptionSelected(selectOption);
                    //clear autoOptions as they should not reflect for next betting rounds
                    this.gameService.setAutoSelectedBetOption(null);
                    return true;
                }
            }
        }
        return false;
    };
    useMapping = function (mapping, optionSet, autoSelectedOption) {
        for (const mapOption of mapping.options) {
            for (const option of optionSet.optionSet) {
                if (option.optionId == parseInt(mapOption)) {
                    if (mapping.checkAmount == false || option.amount == autoSelectedOption.amount) {
                        return option;
                    }
                }
            }
        }
        return null;
    };
    updatePotAmountForRaise = function (amount) {
        this.view.updatePotAmountForRaise(amount.detail);
    };
    updateTimerAnimation = function (timePeriod) {
        this.view.updateTimerAnimation(timePeriod.detail);
    };
    playAllSounds = function () {
        this.view.playAllSounds();
    };
    handleSelectedOpt = function () {
        this.view.removeBetTimerAnimation();
    };
    showNextHand = function () {
        this.view.showNextHand();
    };
    hideOptionSet = function () {
        this.view.hideOptionSet();
    };
}
