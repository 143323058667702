import { Message } from '../../message';

export class RequestCloseClient extends Message {
    private MESSAGE_NAME: string = 'RequestCloseClient';
    private desc: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, desc: any | null = null) {
        super(reqServerPeerId);
        this.desc = desc;
    }

    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
}
