import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { LoadingIndicatorService } from '@frontend/vanilla/core';
import moment from 'moment';
import 'moment/locale/en-in';

import { PokerBlogFeedService } from '../poker-blog-feed.service';

@Component({
    selector: 'pp-blog-selection-wrapper',
    templateUrl: 'pp-blog-selection-view-wrapper.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PPBlogSelectionViewWrapperComponent implements OnInit {
    @Input() info: any;
    blogFeedItem: any = [];
    componentName: string = 'pp-blog-selection-wrapper';
    newsCategory: any = [];
    tabName: string = '';
    constructor(
        private pokerBlogFeedService: PokerBlogFeedService,
        private loadingIndicatorService: LoadingIndicatorService,
    ) {}

    ngOnInit() {
        this.newsCategory = Object.keys(this.info.blogStaticData.categories);
        this.tabName = this.newsCategory[0];

        this.getDataBasedOnCategory(this.newsCategory[0]);
        //console.log('newsCategory..', JSON.stringify(this.newsCategory));
    }

    getDataBasedOnCategory(categoryName: string) {
        this.tabName = categoryName;
        if (categoryName !== 'all') {
            const loadingIndicator = this.loadingIndicatorService.start();
            this.pokerBlogFeedService.getBlogFeedByCategoryName(categoryName).subscribe({
                next: (response: any) => {
                    this.blogFeedItem = [];

                    this.blogFeedItem = Object.keys(response).length > 0 && response.hasOwnProperty('items') ? response.items : [];

                    loadingIndicator.done();
                },
                error: () => {
                    this.blogFeedItem = [];
                    loadingIndicator.done();
                },
            });
        } else {
            this.blogFeedItem = [];
            this.blogFeedItem = this.info.blogData.items;
        }
    }
    returnNewsDate(value: string): string {
        return moment(value).from(new Date());
    }

    goToDetails(url: string) {
        window.open(url, '_blank');
    }

    formateName(itemName: string): string {
        return itemName.replace('-', ' ');
    }
}
