import { Message } from '../../message';

export class RequestSNGInstance extends Message {
    private MESSAGE_NAME: string = 'RequestSNGInstance';
    private requestId: number = 0;
    private sngTemplateId: number = 0;
    private sourceType: number = 0;
    private tourneyCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestId: number = 0, sngTemplateId: number = 0, sourceType: number = 0, tourneyCategory: number = 0) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.sngTemplateId = sngTemplateId;
        this.sourceType = sourceType;
        this.tourneyCategory = tourneyCategory;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getSngTemplateId(): number {
        return this.sngTemplateId;
    }

    setSngTemplateId(sngTemplateId: number) {
        this.sngTemplateId = sngTemplateId;
    }
    getSourceType(): number {
        return this.sourceType;
    }

    setSourceType(sourceType: number) {
        this.sourceType = sourceType;
    }
    getTourneyCategory(): number {
        return this.tourneyCategory;
    }

    setTourneyCategory(tourneyCategory: number) {
        this.tourneyCategory = tourneyCategory;
    }
}
