import { Message } from '../../message';

export class ValidateEmail extends Message {
    private MESSAGE_NAME: string = 'ValidateEmail';
    private code: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, code: string | null = null) {
        super(reqServerPeerId);
        this.code = code;
    }

    getCode(): string | null {
        return this.code;
    }

    setCode(code: string | null) {
        this.code = code;
    }
}
