import { TSDataInputStream } from '../../sal/dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from '../../sal/dataInputOutputStream/TSDataOutputStream';
import { IPGMessageFactory } from './interfaces/IPGMessageFactory';
import { Message } from './message';

export abstract class PGMessageFactory implements IPGMessageFactory {
    protected classes: Map<number, any> = new Map<number, any>();
    protected ids: Map<any, number> = new Map<any, number>();
    // public readonly NATURE_HOMOGENIOUS: number = 0x07;
    // public readonly NATURE_HETEROGENIOUS: number = 0x08;
    // public readonly ENTITY_TYPE_VECTOR: number = 0xF0;
    // public readonly ENTITY_TYPE_HASHTABLE: number = 0xF1;
    // public readonly ENTITY_TYPE_OBJECT: number = 0xFF;
    // public readonly ENTITY_TYPE_STRINGEX: number = 0x09;
    // public readonly CLASS_NOT_FOUND: number = -1;
    private bcEnabled: boolean | undefined;

    public abstract read(obj: Message, input: TSDataInputStream): void;
    public abstract write(obj: Message, out: TSDataOutputStream): void;

    public getClassId(cls: any): number {
        const classId: number | undefined = this.ids.get(cls);
        if (classId === undefined) {
            return 0;
        } else {
            return classId;
        }
    }

    public getClass(clsid: number): any {
        const cls = this.classes.get(clsid);
        if (cls === undefined || null) {
            return null;
        } else {
            return cls;
        }
    }

    public getClassIdByName(obj: any): number | null {
        for (const [cls, id] of this.ids.entries()) {
            if (new cls().getMessageName() === obj.MESSAGE_NAME) {
                return id;
            }
        }
        return 0;
    }

    public setBCEnabled(val: boolean): void {
        this.bcEnabled = val;
    }
    public getBCEnabled() {
        return this.bcEnabled;
    }
}
