<pk-page-not-found *ngIf="promoInitData.notFound"></pk-page-not-found>
<div *ngIf="pokerPromotionsInitData && !promoInitData.notFound">
    <div id="promotion-tournament-main" *ngIf="pokerPromotionsInitData.heroImage">
        <div class="contentWrap black">
            <div
                [class]="
                    pokerPromotionsInitData.heroTitle && pokerPromotionsInitData.heroTitle.length > 1 ? 'heroLeft col dark-bg' : 'heroCenter col'
                "
                class="col">
                <div *ngIf="pokerPromotionsInitData.heroImage.src" class="heroImageWrap">
                    <img *ngIf="pokerPromotionsInitData.heroImage.src" src="{{ pokerPromotionsInitData.heroImage.src }}" alt="promotion" />
                </div>
                <div *ngIf="pokerPromotionsInitData.heroTitle" class="heroContentWrap">
                    <h1 class="promoTitle">{{ pokerPromotionsInitData.heroTitle }}</h1>
                    <div [innerHtml]="pokerPromotionsInitData.heroDescription | trustAsHtml"></div>
                    <a *ngIf="pokerPromotionsInitData.heroCTA" [href]="pokerPromotionsInitData.heroCTAClass.url" class="btn btn-primary">{{
                        pokerPromotionsInitData.heroCTA
                    }}</a>
                </div>
            </div>
            <p *ngIf="pokerPromotionsInitData.synopsisTerms" class="significantTerms">
                {{ pokerPromotionsInitData.synopsisTerms }}
            </p>
        </div>
        <div class="contentWrap grey promoMeta" *ngIf="pokerPromotionsInitData.firstTitle">
            <!-- Example row of columns -->
            <div class="row col">
                <div class="col-md-4 metaItems">
                    <h2><span class="{{ pokerPromotionsInitData.firstIconClass }}"></span>{{ pokerPromotionsInitData.firstTitle }}</h2>
                    <div [innerHtml]="pokerPromotionsInitData.firstContent | trustAsHtml"></div>
                </div>
                <div class="col-md-4 metaItems">
                    <!--secondIconClass-->
                    <h2><span class="{{ pokerPromotionsInitData.secondIconClass }}"></span>{{ pokerPromotionsInitData.secondTitle }}</h2>
                    <div [innerHtml]="pokerPromotionsInitData.secondContent | trustAsHtml"></div>
                </div>
                <div class="col-md-4 metaItems">
                    <!--thirdIconClass-->
                    <h2><span class="{{ pokerPromotionsInitData.thirdIconClass }}"></span>{{ pokerPromotionsInitData.thirdTitle }}</h2>
                    <div [innerHtml]="pokerPromotionsInitData.thirdContent | trustAsHtml"></div>
                </div>
            </div>
        </div>

        <div class="pm-nav2col pm-simple-layout pm-nav-layout pm-page col d-flex row">
            <section class="pm-area-navigation col-lg-2 col-md-3 col-sm-4 p-0 pc-menu mb-3 mb-sm-0">
                <div class="poker-promo-menu" *ngIf="displaySideMenuBar">
                    <vn-page-matrix [content]="menuItem"></vn-page-matrix>
                </div>
            </section>
            <div [class]="relatedPromos ? 'col-lg-8 col-md-6 col-sm-8 pl-md-3 pl-sm-3' : 'col-lg-10 col-md-9 col-sm-8 pl-md-5 pl-sm-3'">
                <div class="pm-area-2col">
                    <section data-component="public">
                        <div>
                            <mat-tab-group [@.disabled]="true" *ngIf="pokerPromotionsInitData.mainTitle1; else nonTab">
                                <mat-tab
                                    *ngIf="pokerPromotionsInitData.mainTitle1"
                                    id="{{ pokerPromotionsInitData.mainTitle1 }}"
                                    label="{{ pokerPromotionsInitData.mainTitle1 }}">
                                    <div class="pc-text pm-widget-box pc-component">
                                        <section class="text pc-txt">
                                            <div [vnDynamicHtml]="pokerPromotionsInitData.mainContent1"></div>
                                        </section>
                                    </div>
                                </mat-tab>
                                <mat-tab *ngIf="pokerPromotionsInitData.mainTitle2" label="{{ pokerPromotionsInitData.mainTitle2 }}">
                                    <div class="pc-text pm-widget-box pc-component">
                                        <section class="text pc-txt">
                                            <div [vnDynamicHtml]="pokerPromotionsInitData.mainContent2"></div>
                                        </section>
                                    </div>
                                </mat-tab>

                                <mat-tab *ngIf="pokerPromotionsInitData.mainTitle3" label="{{ pokerPromotionsInitData.mainTitle3 }}">
                                    <div class="pc-text pm-widget-box pc-component">
                                        <section class="text pc-txt">
                                            <div [vnDynamicHtml]="pokerPromotionsInitData.mainContent3"></div>
                                        </section>
                                    </div>
                                </mat-tab>
                                <mat-tab *ngIf="pokerPromotionsInitData.mainTitle4" label="{{ pokerPromotionsInitData.mainTitle4 }}">
                                    <div class="pc-text pm-widget-box pc-component">
                                        <section class="text pc-txt">
                                            <div [vnDynamicHtml]="pokerPromotionsInitData.mainContent4"></div>
                                        </section>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                            <ng-template #nonTab>
                                <div class="pc-text pm-widget-box pc-component">
                                    <section class="text pc-txt">
                                        <div [vnDynamicHtml]="pokerPromotionsInitData.mainContent1"></div>
                                    </section>
                                </div>
                            </ng-template>
                        </div>
                        <div
                            *ngIf="pokerPromotionsInitData.terms && pokerPromotionsInitData.terms.length > 20"
                            class="pc-text pm-widget-box pc-component termsConditions">
                            <section class="text pc-txt">
                                <div [innerHtml]="pokerPromotionsInitData.terms | trustAsHtml"></div>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
            <section *ngIf="relatedPromos" class="pm-area-contentleft col-lg-2 col-md-3 col-sm-12 mt-4 mt-md-0">
                <div class="pc-text pm-widget-box pc-component row d-flex">
                    <ng-container *ngFor="let relatedPromoItem of relatedPromos; let i = index">
                        <div
                            *ngIf="relatedPromoItem.pokerPromotionsSitecoreInfo && relatedPromoItem.pokerPromotionsSitecoreInfo.synopsisImage"
                            class="col-md-12 col-sm-6 p-0">
                            <section class="teaserItem card p-0">
                                <a
                                    (click)="menuItemClicked(relatedPromoItem.documentRelativeUri)"
                                    [href]="'/' + lang + relatedPromoItem.documentRelativeUri">
                                    <section>
                                        <img [src]="relatedPromoItem.pokerPromotionsSitecoreInfo.synopsisImage.src" alt="promotion" />
                                        <div class="teaserContent pc-txt card-body">
                                            <h5>{{ relatedPromoItem.pokerPromotionsSitecoreInfo.synopsisTitle }}</h5>
                                            <div class="card-footer" *ngIf="relatedPromoItem.pokerPromotionsSitecoreInfo.synopsisTerms">
                                                <small class="text-muted">{{ relatedPromoItem.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                                            </div>
                                        </div>
                                    </section>
                                </a>
                            </section>
                        </div>
                    </ng-container>
                </div>
            </section>
        </div>
    </div>
</div>
