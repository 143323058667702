import { Message } from '../../message';

export class RequestSNGQuickRegisterAdvanced extends Message {
    private MESSAGE_NAME: string = 'RequestSNGQuickRegisterAdvanced';
    private requestId: number = 0;
    private gametype: number = 0;
    private limitType: number = 0;
    private buyInValue: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestId: number = 0, gametype: number = 0, limitType: number = 0, buyInValue: number = 0) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.gametype = gametype;
        this.limitType = limitType;
        this.buyInValue = buyInValue;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getGametype(): number {
        return this.gametype;
    }

    setGametype(gametype: number) {
        this.gametype = gametype;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getBuyInValue(): number {
        return this.buyInValue;
    }

    setBuyInValue(buyInValue: number) {
        this.buyInValue = buyInValue;
    }
}
