import { Message } from '../../message';

export class LoginDenial extends Message {
    private MESSAGE_NAME: string = 'LoginDenial';
    private conversationId: number = 0;
    private accountName: string | null = null;
    private screenName: string | null = null;
    private description: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        conversationId: number = 0,
        accountName: string | null = null,
        screenName: string | null = null,
        description: any | null = null,
    ) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.accountName = accountName;
        this.screenName = screenName;
        this.description = description;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getDescription(): any | null {
        return this.description;
    }

    setDescription(description: any | null) {
        this.description = description;
    }
}
