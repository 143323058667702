<div class="contentWrap">
    <div class="col d-flex seoContent row">
        <div class="col-md-6 col-sm-6 mb-4 mb-sm-0">
            <div *ngIf="howtoplayContentContent.image.src; else noImagehtp" class="row">
                <img
                    class="d-none d-md-block col-5 align-self-start"
                    *ngIf="howtoplayContentContent.image.src"
                    [src]="howtoplayContentContent.image.src"
                    alt="How to Play" />
                <span class="col-12 col-md-7" [innerHtml]="howtoplayContentContent.text"></span>
            </div>
            <ng-template #noImagehtp>
                <div class="row">
                    <div class="col-12" [innerHtml]="howtoplayContentContent.text"></div>
                </div>
            </ng-template>
        </div>
        <div class="col-md-6 col-sm-6">
            <div *ngIf="mobileContentContent.image.src; else noImagemc" class="row">
                <img
                    class="d-none d-md-block col-5 align-self-start"
                    *ngIf="mobileContentContent.image.src"
                    [src]="mobileContentContent.image.src"
                    alt="How to play" />
                <span class="col-12 col-md-7" [innerHtml]="mobileContentContent.text"></span>
            </div>
            <ng-template #noImagemc>
                <div class="row">
                    <div class="col-12" [innerHtml]="mobileContentContent.text"></div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
