import { Message } from '../../message';

export class AutoOption extends Message {
    private MESSAGE_NAME: string = 'AutoOption';
    private autoOptionId: number = 0;
    private isAllIn: boolean | null = false;
    private amount: number = 0;
    private title: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        autoOptionId: number = 0,
        isAllIn: boolean | null = false,
        amount: number = 0,
        title: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.autoOptionId = autoOptionId;
        this.isAllIn = isAllIn;
        this.amount = amount;
        this.title = title;
    }

    getAutoOptionId(): number {
        return this.autoOptionId;
    }

    setAutoOptionId(autoOptionId: number) {
        this.autoOptionId = autoOptionId;
    }
    getIsAllIn(): boolean | null {
        return this.isAllIn;
    }

    setIsAllIn(isAllIn: boolean | null) {
        this.isAllIn = isAllIn;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getTitle(): any[] | null {
        return this.title;
    }

    setTitle(title: any[] | null) {
        this.title = title;
    }
}
