import { Routes } from '@angular/router';

import { PokerDailyLegendsSynopsisResolver } from './poker-daily-legends-synopsis.resolver';
import { PokerDailyLegendsResolver } from './poker-daily-legends.resolver';

export const pokerDailyLegendsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerDailyLegendsResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerDailyLegendsSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerDailyLegendsResolver,
        },
    },
];
