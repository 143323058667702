import { Message } from '../../message';

export class IdleInfo extends Message {
    private MESSAGE_NAME: string = 'IdleInfo';
    private userIdleForSec: number = 0;
    private isActive: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, userIdleForSec: number = 0, isActive: boolean | null = false) {
        super(reqServerPeerId);
        this.userIdleForSec = userIdleForSec;
        this.isActive = isActive;
    }

    getUserIdleForSec(): number {
        return this.userIdleForSec;
    }

    setUserIdleForSec(userIdleForSec: number) {
        this.userIdleForSec = userIdleForSec;
    }
    getIsActive(): boolean | null {
        return this.isActive;
    }

    setIsActive(isActive: boolean | null) {
        this.isActive = isActive;
    }
}
