import { Message } from '../../message';

export class LSPoolDetails extends Message {
    private MESSAGE_NAME: string = 'LSPoolDetails';
    private tableInfoId: number = 0;
    private poolId: number = 0;
    private playerCount: number = 0;
    private handsPerHour: number = 0;
    private avgStack: number = 0;
    private avgFlopsPerSeen: number = 0;
    private avgPot: number = 0;
    private serverPeerId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableInfoId: number = 0,
        poolId: number = 0,
        playerCount: number = 0,
        handsPerHour: number = 0,
        avgStack: number = 0,
        avgFlopsPerSeen: number = 0,
        avgPot: number = 0,
        serverPeerId: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableInfoId = tableInfoId;
        this.poolId = poolId;
        this.playerCount = playerCount;
        this.handsPerHour = handsPerHour;
        this.avgStack = avgStack;
        this.avgFlopsPerSeen = avgFlopsPerSeen;
        this.avgPot = avgPot;
        this.serverPeerId = serverPeerId;
    }

    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getHandsPerHour(): number {
        return this.handsPerHour;
    }

    setHandsPerHour(handsPerHour: number) {
        this.handsPerHour = handsPerHour;
    }
    getAvgStack(): number {
        return this.avgStack;
    }

    setAvgStack(avgStack: number) {
        this.avgStack = avgStack;
    }
    getAvgFlopsPerSeen(): number {
        return this.avgFlopsPerSeen;
    }

    setAvgFlopsPerSeen(avgFlopsPerSeen: number) {
        this.avgFlopsPerSeen = avgFlopsPerSeen;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
}
