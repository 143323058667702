import { Message } from '../../message';

export class CurrencyAmount extends Message {
    private MESSAGE_NAME: string = 'CurrencyAmount';
    private currencyCode: string | null = null;
    private amount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currencyCode: string | null = null, amount: number = 0) {
        super(reqServerPeerId);
        this.currencyCode = currencyCode;
        this.amount = amount;
    }

    getCurrencyCode(): string | null {
        return this.currencyCode;
    }

    setCurrencyCode(currencyCode: string | null) {
        this.currencyCode = currencyCode;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
}
