import { Message } from '../../message';

export class RequestPlayerSearch extends Message {
    private MESSAGE_NAME: string = 'RequestPlayerSearch';
    private playerIdsToSearch: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerIdsToSearch: any[] | null = null) {
        super(reqServerPeerId);
        this.playerIdsToSearch = playerIdsToSearch;
    }

    getPlayerIdsToSearch(): any[] | null {
        return this.playerIdsToSearch;
    }

    setPlayerIdsToSearch(playerIdsToSearch: any[] | null) {
        this.playerIdsToSearch = playerIdsToSearch;
    }
}
