import { Message } from '../../message';

export class MTCTRebuyAddonInfo extends Message {
    private MESSAGE_NAME: string = 'MTCTRebuyAddonInfo';
    private maxRebuyCount: number = 0;
    private rebuyEndBreakNumber: number = 0;
    private addonSupported: boolean | null = false;
    private maxAddonCount: number = 0;
    private rebuyBuyIn: number = 0;
    private rebuyChips: number = 0;
    private addonBuyIn: number = 0;
    private addonChips: number = 0;
    private rebuyCountTillNow: number = 0;
    private addonCountTillNow: number = 0;
    private rebuyAddonStatus: number = 0;
    private rebuyFee: number = 0;
    private addonFee: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        maxRebuyCount: number = 0,
        rebuyEndBreakNumber: number = 0,
        addonSupported: boolean | null = false,
        maxAddonCount: number = 0,
        rebuyBuyIn: number = 0,
        rebuyChips: number = 0,
        addonBuyIn: number = 0,
        addonChips: number = 0,
        rebuyCountTillNow: number = 0,
        addonCountTillNow: number = 0,
        rebuyAddonStatus: number = 0,
        rebuyFee: number = 0,
        addonFee: number = 0,
    ) {
        super(reqServerPeerId);
        this.maxRebuyCount = maxRebuyCount;
        this.rebuyEndBreakNumber = rebuyEndBreakNumber;
        this.addonSupported = addonSupported;
        this.maxAddonCount = maxAddonCount;
        this.rebuyBuyIn = rebuyBuyIn;
        this.rebuyChips = rebuyChips;
        this.addonBuyIn = addonBuyIn;
        this.addonChips = addonChips;
        this.rebuyCountTillNow = rebuyCountTillNow;
        this.addonCountTillNow = addonCountTillNow;
        this.rebuyAddonStatus = rebuyAddonStatus;
        this.rebuyFee = rebuyFee;
        this.addonFee = addonFee;
    }

    getMaxRebuyCount(): number {
        return this.maxRebuyCount;
    }

    setMaxRebuyCount(maxRebuyCount: number) {
        this.maxRebuyCount = maxRebuyCount;
    }
    getRebuyEndBreakNumber(): number {
        return this.rebuyEndBreakNumber;
    }

    setRebuyEndBreakNumber(rebuyEndBreakNumber: number) {
        this.rebuyEndBreakNumber = rebuyEndBreakNumber;
    }
    getAddonSupported(): boolean | null {
        return this.addonSupported;
    }

    setAddonSupported(addonSupported: boolean | null) {
        this.addonSupported = addonSupported;
    }
    getMaxAddonCount(): number {
        return this.maxAddonCount;
    }

    setMaxAddonCount(maxAddonCount: number) {
        this.maxAddonCount = maxAddonCount;
    }
    getRebuyBuyIn(): number {
        return this.rebuyBuyIn;
    }

    setRebuyBuyIn(rebuyBuyIn: number) {
        this.rebuyBuyIn = rebuyBuyIn;
    }
    getRebuyChips(): number {
        return this.rebuyChips;
    }

    setRebuyChips(rebuyChips: number) {
        this.rebuyChips = rebuyChips;
    }
    getAddonBuyIn(): number {
        return this.addonBuyIn;
    }

    setAddonBuyIn(addonBuyIn: number) {
        this.addonBuyIn = addonBuyIn;
    }
    getAddonChips(): number {
        return this.addonChips;
    }

    setAddonChips(addonChips: number) {
        this.addonChips = addonChips;
    }
    getRebuyCountTillNow(): number {
        return this.rebuyCountTillNow;
    }

    setRebuyCountTillNow(rebuyCountTillNow: number) {
        this.rebuyCountTillNow = rebuyCountTillNow;
    }
    getAddonCountTillNow(): number {
        return this.addonCountTillNow;
    }

    setAddonCountTillNow(addonCountTillNow: number) {
        this.addonCountTillNow = addonCountTillNow;
    }
    getRebuyAddonStatus(): number {
        return this.rebuyAddonStatus;
    }

    setRebuyAddonStatus(rebuyAddonStatus: number) {
        this.rebuyAddonStatus = rebuyAddonStatus;
    }
    getRebuyFee(): number {
        return this.rebuyFee;
    }

    setRebuyFee(rebuyFee: number) {
        this.rebuyFee = rebuyFee;
    }
    getAddonFee(): number {
        return this.addonFee;
    }

    setAddonFee(addonFee: number) {
        this.addonFee = addonFee;
    }
}
