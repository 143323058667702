import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class DisconnectionViewModel {
    gameService: any;
    isDisconnected: any;
    showingForceTableLoader: any;
    constructor(gs) {
        this.gameService = gs;
        this.isDisconnected = false;
        this.showingForceTableLoader = false;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.tableId = this.gameService.getPeerId();
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_DISCONNECTION_STATE, this.handleDisconnectionState.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_FORCE_TABLE_LOADER, this.showForceTableLoader.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.FORCE_REFRESH_TABLE, this.forceRefreshTable.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_FULL_GAME_CLOSE, this.handleFullGameClose.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.GAME_VIEW_CREATED, this.onGameViewCreated.bind(this));
    };
    handleDisconnectionState = function (data) {
        if (!parseInt(data.status)) {
            this.isDisconnected = true;
            //alert('Connection to the server has been dropped.')
            //as sal layer resets the handshake after 2 minutes
            //need to uncomment later when sal layer resets the handshake
            // if(!this.closeTableTimeOut)
            //   this.closeTableTimeOut = setTimeout(this.closeTable.bind(this), 120 * 1000);
        } else {
            this.isDisconnected = false;
            //clearTimeout(this.closeTableTimeOut);
        }
        if (!this.showingForceTableLoader) this.view.handleDisconnectionState(this.isDisconnected);
    };
    closeTable = function () {
        //this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.CONCLUDED_LEAVE);
    };
    showForceTableLoader = function () {
        this.showingForceTableLoader = true;
        this.showTableLoader();
    };
    forceRefreshTable = function () {
        this.showingForceTableLoader = false;
        this.hideTableLoader();
    };
    showTableLoader = function () {
        this.view.showTableLoader();
    };
    onGameViewCreated = function () {
        this.hideTableLoader();
    };
    hideTableLoader = function () {
        if (!this.isDisconnected) this.view.hideTableLoader();
    };
    handleFullGameClose = function () {
        //clearTimeout(this.closeTableTimeOut);
    };
}
