import { Message } from '../../message';

export class AcceptChallengeRequest extends Message {
    private MESSAGE_NAME: string = 'AcceptChallengeRequest';
    private challengeId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, challengeId: number = 0) {
        super(reqServerPeerId);
        this.challengeId = challengeId;
    }

    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
}
