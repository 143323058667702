// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { Impetus } from '../services/impetus';

export class UserProfileView extends PIXI.Container {
    displayUtil = new DisplayUtil();
    throwImpetus: any;
    constructor() {
        super();
    }
    createView = function (EventDispatcher, userProfileViewModel, peerId) {
        this.viewModel = userProfileViewModel;
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        this.imojiObj = uiConfig.throwEmojisData;
        this.peerId = peerId;
        this.boosterAnimEnabled = false;
        // for making user profile view responsive bottom align
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        // this.blackBg = this.displayUtil.getRectangleSprite(720, 1280, "#000000");
        this.blackBg = this.displayUtil.getRectangleSprite(
            (window.innerWidth * 2) / this.scale.x,
            (window.innerHeight * 2) / this.scale.y,
            '#000000',
        );
        this.displayUtil.setObjectPosition(this.blackBg, -this.x / this.scale.x, -this.y / this.scale.y);
        //this.displayUtil.addInteraction(this.blackBg, this.onClose.bind(this));
        this.blackBg.on('touchstart', this.blackBgTouchStart.bind(this));
        this.blackBg.on('touchend', this.blackBgTouchEnd.bind(this));
        this.addChild(this.blackBg);
        this.blackBg.alpha = 0;
        this.blackBg.visible = false;
        this.blackBg.buttonMode = false;
        this.displayUtil.setButtonActive(this.blackBg, true);
        this.userProfileContainer = this.displayUtil.getContainer();
        this.addChild(this.userProfileContainer);
        this.titleImage = this.displayUtil.getSprite('userTitleBackground');
        this.userProfileContainer.addChild(this.titleImage);
        this.displayUtil.setObjectPosition(this.titleImage, 48, 279);
        this.userProfileText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
        this.titleImage.addChild(this.userProfileText);
        this.userProfileText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmPlayerProfileDialogTitle');
        this.userProfileText.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.userProfileText, 311, 36);
        this.closeBtn = this.displayUtil.getButton('close');
        this.userProfileContainer.addChild(this.closeBtn);
        this.displayUtil.addInteraction(this.closeBtn, this.onClose.bind(this));
        this.displayUtil.setClickable(this.closeBtn, true);
        this.displayUtil.setObjectPosition(this.closeBtn, 616, 295);
        this.displayUtil.assignHitArea(
            this.closeBtn,
            {
                x: 0,
                y: -10,
                w: this.closeBtn.width + 20,
                h: this.closeBtn.height + 20,
            },
            'rect',
        );
        this.bodybackgroundImage = this.displayUtil.getSprite('UserBodyBackground');
        this.userProfileContainer.addChild(this.bodybackgroundImage);
        this.displayUtil.setObjectPosition(this.bodybackgroundImage, 48, 348);
        this.imogiText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
        this.bodybackgroundImage.addChild(this.imogiText);
        this.imogiText.text = '';
        this.imogiText.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.imogiText, 359, 100);
        this.createPlayerFooter();
        this.CreateImoji();
        this.setSize(720, 1280);
    };
    createPlayerFooter = function () {
        if (this.viewModel.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            this.sitoutButton = this.displayUtil.getButton('sitOut');
            this.userProfileContainer.addChild(this.sitoutButton);
            this.displayUtil.addInteraction(this.sitoutButton, this.onSitOut.bind(this));
            this.displayUtil.setClickable(this.sitoutButton, true);
            this.displayUtil.setObjectPosition(this.sitoutButton, 48, 544);
            this.sitoutBtnText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
            this.sitoutButton.addChild(this.sitoutBtnText);
            this.sitoutBtnText.text = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_MOBILE_TABLE_ANSPINS_H5ANSPINS_PtmPlayerProfileDialogSitOut',
            );
            this.sitoutBtnText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(this.sitoutBtnText, 152, 49);
            this.leaveButton = this.displayUtil.getButton('leave');
            this.userProfileContainer.addChild(this.leaveButton);
            this.leaveButton.transform.scale.x = 0.97;
            this.displayUtil.addInteraction(this.leaveButton, this.onLeave.bind(this));
            this.displayUtil.setClickable(this.leaveButton, true);
            this.displayUtil.setObjectPosition(this.leaveButton, 356, 544);
            this.leaveBtnText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
            this.leaveButton.addChild(this.leaveBtnText);
            this.leaveBtnText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmPlayerProfileDialogLeave');
            this.leaveBtnText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(this.leaveBtnText, 157, 49);
            this.userProfileContainer.visible = false;
        } else {
            // below code for FF rebuy button in self user profile view
            this.reBuyButton = this.displayUtil.getButton('Rebuy');
            this.userProfileContainer.addChild(this.reBuyButton);
            this.displayUtil.addInteraction(this.reBuyButton, this.onRebuy.bind(this));
            this.displayUtil.setClickable(this.reBuyButton, true);
            this.displayUtil.setObjectPosition(this.reBuyButton, 218, 518);
            this.reBuyBtnText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
            this.reBuyButton.addChild(this.reBuyBtnText);
            this.reBuyBtnText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_REBUYADDON_FEEREBUY');
            this.reBuyBtnText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(this.reBuyBtnText, 152, 30);
            this.sitoutButton = this.displayUtil.getButton('sitOut');
            this.userProfileContainer.addChild(this.sitoutButton);
            this.displayUtil.addInteraction(this.sitoutButton, this.onSitOut.bind(this));
            this.displayUtil.setClickable(this.sitoutButton, true);
            this.displayUtil.setObjectPosition(this.sitoutButton, 48, 619);
            this.sitoutBtnText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
            this.sitoutButton.addChild(this.sitoutBtnText);
            this.sitoutBtnText.text = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_MOBILE_TABLE_ANSPINS_H5ANSPINS_PtmPlayerProfileDialogSitOut',
            );
            this.sitoutBtnText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(this.sitoutBtnText, 152, 49);
            this.leaveButton = this.displayUtil.getButton('leave');
            this.userProfileContainer.addChild(this.leaveButton);
            this.leaveButton.transform.scale.x = 0.97;
            this.displayUtil.addInteraction(this.leaveButton, this.onLeave.bind(this));
            this.displayUtil.setClickable(this.leaveButton, true);
            this.displayUtil.setObjectPosition(this.leaveButton, 356, 619);
            this.leaveBtnText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
            this.leaveButton.addChild(this.leaveBtnText);
            this.leaveBtnText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmPlayerProfileDialogLeave');
            this.leaveBtnText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(this.leaveBtnText, 157, 49);
            this.userProfileContainer.visible = false;
        }
    };
    createThrowEmojiMask = function () {
        this.emojiMaskSprite = this.displayUtil.getPolygon([0, 0, 620, 0, 620, 272, 0, 272], Math.random() * 300000000);
        this.throwEmojiContainer.addChild(this.emojiMaskSprite);
        this.throwEmojiContainer.mask = this.emojiMaskSprite;
    };
    CreateImoji = function () {
        this.othersImogiSlotBackground = this.displayUtil.getSprite('imogiBackground');
        this.userProfileContainer.addChild(this.othersImogiSlotBackground);
        this.displayUtil.setObjectPosition(this.othersImogiSlotBackground, 48, 590);
        this.othersImogiScoreBackground = this.displayUtil.getSprite('Rectangle');
        this.userProfileContainer.addChild(this.othersImogiScoreBackground);
        this.displayUtil.setObjectPosition(this.othersImogiScoreBackground, 48, 534);
        // start throwwrapper
        this.throwWrapper = this.displayUtil.getRectangleSprite(620, 272, '0X303030');
        this.userProfileContainer.addChild(this.throwWrapper);
        this.displayUtil.setClickable(this.throwWrapper, true);
        this.throwWrapper.buttonMode = false;
        this.displayUtil.setObjectPosition(this.throwWrapper, 48, 584);
        // end throwwrapper
        // start throwscroll
        // getBorderRoundRectangleSprite: function (wid, ht, col, rad, isBorder, borderWid, borderCol, borderAlp)
        this.throwScroll = this.displayUtil.getBorderRoundRectangleSprite(10, 150, '0XC4C4C4', 5, true, 1, '0XC4C4C4');
        this.throwWrapper.addChild(this.throwScroll);
        this.displayUtil.setObjectPosition(this.throwScroll, 600, 20);
        this.throwEmojiContainer = this.displayUtil.getContainer();
        this.throwWrapper.addChild(this.throwEmojiContainer);
        this.throwWrapper.interactive = true;
        //this.displayUtil.setObjectPosition(this.throwEmojiContainer, 48, 584);
        // start impetus1
        this.throwImpetus = new Impetus({
            source: this.throwWrapper,
            isPixiElement: true,
            bounce: false,
            boundY: [-100, 0],
            update: function (x1, y1) {
                this.throwEmojiContainer.y = y1;
                this.throwScroll.y = y1 * -1;
            }.bind(this),
        });
        // end impetus1
        //this.createThrowEmojiMask();
        // start mask1
        this.wrapperMask = this.displayUtil.getRectangleSprite(620, 272, '0X1c1c1c');
        this.wrapperMask.name = 'mask';
        this.displayUtil.setObjectPosition(this.wrapperMask, 0, 0);
        this.throwWrapper.addChild(this.wrapperMask);
        this.throwWrapper.mask = this.wrapperMask;
        // end mask1
        this.scoreIcon = this.displayUtil.getSprite('diamond');
        this.othersImogiScoreBackground.addChild(this.scoreIcon);
        this.displayUtil.setObjectPosition(this.scoreIcon, 254, 13);
        this.scoreText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
        this.othersImogiScoreBackground.addChild(this.scoreText);
        this.scoreText.text = '';
        this.scoreText.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.scoreText, 325, 30);
        for (let i = 0; i < this.imojiObj.length; i++) {
            this[this.imojiObj[i].name] = this.displayUtil.getSprite(this.imojiObj[i].imojiName + '_disabled');
            // Added price info of the emoji
            const emojiInfo = this.createEmojiValueInfo();
            this[this.imojiObj[i].name].addChild(emojiInfo);
            emojiInfo.x = 30;
            emojiInfo.y = -18;
            // Added price info of the emoji
            this.throwEmojiContainer.addChild(this[this.imojiObj[i].name]);
            this.displayUtil.addInteraction(this[this.imojiObj[i].name], this.onclickImoji.bind(this, i + 1));
            this.displayUtil.setClickable(this[this.imojiObj[i].name], false);
            this.displayUtil.setObjectPosition(this[this.imojiObj[i].name], this.imojiObj[i].x, this.imojiObj[i].y);
        }
        this.othersImogiScoreBackground.visible = false;
        this.throwEmojiContainer.visible = false;
    };
    createEmojiValueInfo = function () {
        const emojiValueInfo = this.displayUtil.getRectangleSprite(70, 35, 0x000);
        const emojiDiamond = this.displayUtil.getSprite('diamond');
        const emojiScoreText = this.displayUtil.getTextField(this.fontProps.userprofileStyle);
        emojiValueInfo.addChild(emojiDiamond);
        emojiValueInfo.addChild(emojiScoreText);
        emojiValueInfo.scale.x = 0.8;
        emojiValueInfo.scale.y = 0.9;
        // Added static value for emoji diamond cost, use _id value
        emojiScoreText.text = '1';
        emojiScoreText.x = emojiDiamond.width + 5;
        return emojiValueInfo;
    };
    checkEmojiState = function (diamondBal, flag) {
        if (diamondBal > 0 && flag && this.imojiObj) {
            for (let i = 0; i < this.imojiObj.length; i++) {
                if (this[this.imojiObj[i].name]._texture.textureCacheIds[0] == this.imojiObj[i].imojiName) {
                    return;
                } else {
                    if (this[this.imojiObj[i].name]) {
                        this.displayUtil.setTexture(this[this.imojiObj[i].name], this.imojiObj[i].imojiName);
                        this.displayUtil.setClickable(this[this.imojiObj[i].name], true);
                    }
                }
            }
        } else {
            for (let j = 0; j < this.imojiObj.length; j++) {
                this.displayUtil.setTexture(this[this.imojiObj[j].name], this.imojiObj[j].imojiName + '_disabled');
                this.displayUtil.setClickable(this[this.imojiObj[j].name], false);
            }
        }
    };
    onSitOut = function () {
        this.blackBg.visible = false;
        this.userProfileContainer.visible = false;
        this.viewModel.onClickSitOut();
    };
    onLeave = function () {
        this.blackBg.visible = false;
        this.userProfileContainer.visible = false;
        this.viewModel.onClickLeave();
    };
    // method for click on rebuy button for FF
    onRebuy = function () {
        this.blackBg.visible = false;
        this.userProfileContainer.visible = false;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.OPEN_REBUY_WINDOW);
    };
    onClose = function () {
        this.blackBg.visible = false;
        this.userProfileContainer.visible = false;
        this.throwImpetus.setValues(0, 0);
    };
    blackBgTouchStart = function (events) {
        if (events.data.originalEvent.touches && events.data.originalEvent.touches.length <= 1) {
            this.backBgTouchStarted = true;
        }
    };
    blackBgTouchEnd = function () {
        if (this.backBgTouchStarted) {
            this.backBgTouchStarted = false;
            this.onClose();
        }
    };
    onclickImoji = function (EmojiNo) {
        this.viewModel.onOtherPlayerEmojiClick(EmojiNo, this.viewModel.gameService.getServerSideSeatPos(this.index));
        this.blackBg.visible = false;
        this.userProfileContainer.visible = false;
    };
    showUserInfoViewNow = function (userData) {
        this.index = userData.index;
        if (userData !== undefined) {
            this.imogiText.text = userData.playerName;
            this.scoreText.text = userData.balance;
            this.imogiImage = this.displayUtil.getSprite('avatar_' + userData.iconImageNo);
            this.userProfileContainer.addChild(this.imogiImage);
            this.displayUtil.setObjectPosition(this.imogiImage, 207, 397);
        }
        this.blackBg.visible = true;
        this.backBgTouchStarted = false;
        this.userProfileContainer.visible = true;
        if (userData.index == uiConfig.selfUserClientPosInd) {
            this.userProfileContainer.x = 0;
            this.userProfileContainer.y = 200 - uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y;
            this.leaveButton.visible = true;
            this.sitoutButton.visible = true;
            if (this.reBuyButton) this.reBuyButton.visible = true;
            this.othersImogiSlotBackground.visible = false;
            this.othersImogiScoreBackground.visible = false;
            this.throwEmojiContainer.visible = false;
            this.throwWrapper.visible = false;
        } else {
            this.userProfileContainer.x = 0;
            this.userProfileContainer.y = 55 - uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y;
            this.leaveButton.visible = false;
            this.sitoutButton.visible = false;
            if (this.reBuyButton) this.reBuyButton.visible = false;
            this.othersImogiSlotBackground.visible = true;
            this.othersImogiScoreBackground.visible = true;
            this.throwEmojiContainer.visible = true;
            this.throwWrapper.visible = true;
            if (this.boosterAnimEnabled) {
                this.userProfileContainer.visible = false;
            }
        }
        // below three lines for resetting positions to  default when popup is opened after scroll
        this.throwEmojiContainer.y = 0;
        this.throwScroll.y = 20;
        this.throwWrapper.y = 584;
        this.throwImpetus.setValues(0, 0);
    };
    resetGame = function () {};
    //updateSitOutState = function() {
    //   if (
    //     this.viewModel.gameService.getCurrentShowingOption() ==
    //     serverIdConstants.SHOWING_IAMBACK
    //   ) {
    //     this.sitoutBtnText.text = "SIT IN";
    //   } else {
    //     this.sitoutBtnText.text = "SIT OUT";
    //   }
    // };
    updateDiamondBalance = function (diamondBalance) {
        this.scoreText.text = diamondBalance == undefined ? 0 : CommonGameProperties.facade['kFormatter'](diamondBalance);
    };
    showSitIn = function (showSitIn) {
        if (!this.sitoutBtnText) return;
        this.sitoutBtnText.text = showSitIn
            ? CommonGameProperties.langPack['getCAPSString'](
                  CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_GAMESLOBBY_TOURNAMENTINFO_H5TOURNAMENTINFO_SITIN'),
              )
            : CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_H5ANSPINS_PtmPlayerProfileDialogSitOut');
    };
    hideThrowEmojiContainer = function () {
        this.boosterAnimEnabled = true;
    };
    showThrowEmojiContainer = function () {
        this.boosterAnimEnabled = false;
    };
    hideThrowEmojiForCashOut = function () {
        if (this.userProfileContainer) {
            this.blackBg.visible = false;
            this.userProfileContainer.visible = false;
        }
    };
}
