import { Message } from '../server/common/message';
import { ConnectionHandler } from './connectionHandler';

export class ConnectionHandlerContext {
    public connId: number;
    private domain: number;
    private handler: ConnectionHandler | null;
    public privPktsPending: Map<number, any>;
    private messagesWaitingForHandshake: Message[];
    public peers: number[];
    private initialHandshakeDone: boolean;
    private isLoginInProgress: boolean;
    public isLoggedIn: boolean;
    private currentStatus: number;
    // private timer: Timer;
    private handlerConnector: any | null;
    private reconnectOnFailure: boolean;

    constructor() {
        this.connId = 0;
        this.domain = 0;
        this.handler = null;
        this.privPktsPending = new Map<number, any>();
        this.messagesWaitingForHandshake = [];
        this.peers = [];
        this.initialHandshakeDone = false;
        this.isLoginInProgress = false;
        this.isLoggedIn = false;
        this.currentStatus = 0;
        // this.timer = new Timer();
        this.handlerConnector = null;
        this.reconnectOnFailure = false;
    }

    addPeer(peerID: number): void {
        this.peers.push(peerID);
    }

    removePeer(peerId: number): void {
        this.peers = this.peers.filter((id) => id !== peerId);
    }

    getHandler(): ConnectionHandler | null {
        return this.handler;
    }

    setHandler(handler: ConnectionHandler): void {
        this.handler = handler;
    }

    isInitialHandshakeDone(): boolean {
        return this.initialHandshakeDone;
    }

    setInitialHandshakeDone(initialHandshakeDone: boolean): void {
        this.initialHandshakeDone = initialHandshakeDone;
    }

    getisLoginInProgress(): boolean {
        return this.isLoginInProgress;
    }

    setLoginInProgress(isLoginInProgress: boolean): void {
        this.isLoginInProgress = isLoginInProgress;
    }

    getPeers(): number[] {
        return this.peers;
    }

    setPeers(peers: number[]): void {
        this.peers = peers;
    }

    getCurrentStatus(): number {
        return this.currentStatus;
    }

    setCurrentStatus(currentStatus: number): void {
        this.currentStatus = currentStatus;
    }

    getConnId(): number {
        return this.connId;
    }

    setConnId(connId: number): void {
        this.connId = connId;
        if (this.handler !== null) {
            // this.handler.setConnId(connId); // commenting for linting errors
        }
    }

    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number): void {
        this.domain = domain;
        if (this.handler !== null) {
            // this.handler.setDomain(domain); // commenting for linting errors
        }
    }

    // getTimer(): Timer {
    //     return this.timer;
    // }

    // setTimer(timer: Timer): void {
    //     this.timer = timer;
    // }

    // getPrivPktsPending(): Map<number, ScheduledObject> {
    //     return this.privPktsPending;
    // }

    // setPrivPktsPending(privPktsPending: Map<number, ScheduledObject>): void {
    //     this.privPktsPending = privPktsPending;
    // }

    getHandlerConnector(): any | null {
        return this.handlerConnector;
    }

    setHandlerConnector(handlerConnector: any): void {
        this.handlerConnector = handlerConnector;
    }

    // isLoggedIn(): boolean {
    //     return this.isLoggedIn;
    // }

    setLoggedIn(isLoggedIn: boolean): void {
        this.isLoggedIn = isLoggedIn;
    }

    isReconnectOnFailure(): boolean {
        return this.reconnectOnFailure;
    }

    setReconnectOnFailure(reconnectOnFailure: boolean): void {
        this.reconnectOnFailure = reconnectOnFailure;
    }

    getMessagesWaitingForHandshake(): Message[] {
        return this.messagesWaitingForHandshake;
    }

    setMessagesWaitingForHandshake(pendingMessages: Message[]): void {
        this.messagesWaitingForHandshake = pendingMessages;
    }

    // enqueueOrRecieve(msg: Message): void {
    //     if (!this.initialHandshakeDone || !this.handler.isConnected()) {
    //         console.log(`adding message to queue: ${msg.constructor.name} domain: ${this.domain}`);
    //         this.messagesWaitingForHandshake.push(msg);
    //     } else {
    //         if (this.handler === null) {
    //             console.log(`null handler 2 domain: ${this.domain}`);
    //         }
    //         this.sendMessage(msg);
    //     }
    // }

    // sendMessage(msg: Message): void {
    //     if (this.handler !== null && this.isInitialHandshakeDone()) {
    //         this.handler.sendMessage(msg);
    //     }
    // }

    // sendPacket(msg: Message): boolean {
    //     if (this.handler !== null && this.isInitialHandshakeDone()) {
    //         this.handler.sendMessage(msg);
    //         return true;
    //     }
    //     return false;
    // }

    // processMessagesWaitingForHandshake(): void {
    //     if (this.handler === null) {
    //         console.log(`null handler domain ${this.domain}`);
    //     }
    //     this.initialHandshakeDone = true;
    //     const pendingMsgList = this.getMessagesWaitingForHandshake();
    //     const successfullySent: Message[] = [];

    //     for (const msg of pendingMsgList) {
    //         console.log(`processing queue packets: ${msg.constructor.name} domain: ${this.domain}`);
    //         if (this.sendPacket(msg)) {
    //             successfullySent.push(msg);
    //         }
    //     }

    //     for (const msg of successfullySent) {
    //         const index = pendingMsgList.indexOf(msg);
    //         if (index !== -1) {
    //             pendingMsgList.splice(index, 1);
    //         }
    //     }

    //     // this.notifyAll();
    // }

    // breakDown(): void {
    //     if (this.handler !== null) {
    //         this.handler.breakDown();
    //     }
    //     this.completeShutdown();
    // }

    shutDown(): void {
        if (this.handler !== null) {
            this.handler.closeConnection();
        }
        this.completeShutdown();
    }

    private completeShutdown(): void {
        // if (this.handlerConnector !== null && this.handlerConnector.isAlive()) {
        //   this.handlerConnector.interrupt();
        // }
        this.setInitialHandshakeDone(false);
        this.handlerConnector = null;
        this.handler = null;
        this.privPktsPending = new Map<number, any>();
        this.messagesWaitingForHandshake = [];
    }

    shutdownIfUnUsed(): boolean {
        if (this.peers.length === 0) {
            this.shutDown();
            return true;
        }
        return false;
    }
}
