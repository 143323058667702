import { Routes } from '@angular/router';

import { PokerKoSeriesSynopsisResolver } from './poker-koseries-synopsis.resolver';
import { PokerKoSeriesResolver } from './poker-koseries.resolver';

export const pokerKoSeriesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerKoSeriesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerKoSeriesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerKoSeriesResolver,
        },
    },
];
