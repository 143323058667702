import { TextStyle as t, TextMetrics as e, utils as i, Rectangle as s, Text as o, TEXT_GRADIENT as n } from "pixi.js";
var r = ["pointerover", "pointerenter", "pointerdown", "pointermove", "pointerup", "pointercancel", "pointerout", "pointerleave", "gotpointercapture", "lostpointercapture", "mouseover", "mouseenter", "mousedown", "mousemove", "mouseup", "mousecancel", "mouseout", "mouseleave", "touchover", "touchenter", "touchdown", "touchmove", "touchup", "touchcancel", "touchout", "touchleave"],
  a = {
    bbcode: ["[", "]"],
    xml: ["<", ">"]
  },
  h = function (o) {
    function n(t, e) {
      var i = this;
      o.call(this, t), this.styles = e, r.forEach(function (t) {
        i.on(t, function (t) {
          return i.handleInteraction(t);
        });
      });
    }
    o && (n.__proto__ = o), (n.prototype = Object.create(o && o.prototype)).constructor = n;
    var h = {
      styles: {
        configurable: !0
      }
    };
    return n.prototype.handleInteraction = function (t) {
      var e = t,
        i = t.data.getLocalPosition(this),
        s = this.hitboxes.reduce(function (t, e) {
          return void 0 !== t ? t : e.hitbox.contains(i.x, i.y) ? e : void 0;
        }, void 0);
      e.targetTag = void 0 === s ? void 0 : s.tag;
    }, h.styles.set = function (e) {
      for (var i in this.textStyles = {}, this.textStyles.default = this.assign({}, n.DEFAULT_TAG_STYLE), e) "default" === i ? this.assign(this.textStyles.default, e[i]) : this.textStyles[i] = this.assign({}, e[i]);
      "bbcode" === this.textStyles.default.tagStyle && (this.textStyles.b = this.assign({}, {
        fontStyle: "bold"
      }), this.textStyles.i = this.assign({}, {
        fontStyle: "italic"
      }), this.textStyles.color = this.assign({}, {
        fill: ""
      }), this.textStyles.outline = this.assign({}, {
        stroke: "",
        strokeThickness: 6
      }), this.textStyles.font = this.assign({}, {
        fontFamily: ""
      }), this.textStyles.shadow = this.assign({}, {
        dropShadowColor: "",
        dropShadow: !0,
        dropShadowBlur: 3,
        dropShadowDistance: 3,
        dropShadowAngle: 2
      }), this.textStyles.size = this.assign({}, {
        fontSize: "px"
      }), this.textStyles.spacing = this.assign({}, {
        letterSpacing: ""
      }), this.textStyles.align = this.assign({}, {
        align: ""
      })), this.withPrivateMembers()._style = new t(this.textStyles.default), this.withPrivateMembers().dirty = !0;
    }, n.prototype.setTagStyle = function (e, i) {
      e in this.textStyles ? this.assign(this.textStyles[e], i) : this.textStyles[e] = this.assign({}, i), this.withPrivateMembers()._style = new t(this.textStyles.default), this.withPrivateMembers().dirty = !0;
    }, n.prototype.deleteTagStyle = function (e) {
      "default" === e ? this.textStyles.default = this.assign({}, n.DEFAULT_TAG_STYLE) : delete this.textStyles[e], this.withPrivateMembers()._style = new t(this.textStyles.default), this.withPrivateMembers().dirty = !0;
    }, n.prototype.getTagRegex = function (t, e) {
      var i = Object.keys(this.textStyles).join("|");
      i = t ? "(" + i + ")" : "(?:" + i + ")";
      var s = "bbcode" === this.textStyles.default.tagStyle ? "\\" + a.bbcode[0] + i + "(?:\\=(?:[A-Za-z0-9_\\-\\#]+|'(?:[^']+|\\\\')*'))*\\s*\\" + a.bbcode[1] + "|\\" + a.bbcode[0] + "\\/" + i + "\\s*\\" + a.bbcode[1] : "\\" + a.xml[0] + i + "(?:\\s+[A-Za-z0-9_\\-]+=(?:\"(?:[^\"]+|\\\\\")*\"|'(?:[^']+|\\\\')*'))*\\s*\\" + a.xml[1] + "|\\" + a.xml[0] + "\\/" + i + "\\s*\\" + a.xml[1];
      return e && (s = "(" + s + ")"), new RegExp(s, "g");
    }, n.prototype.getPropertyRegex = function () {
      return new RegExp("([A-Za-z0-9_\\-]+)=(?:\"((?:[^\"]+|\\\\\")*)\"|'((?:[^']+|\\\\')*)')", "g");
    }, n.prototype.getBBcodePropertyRegex = function () {
      return new RegExp("[A-Za-z0-9_\\-]+=([A-Za-z0-9_\\-\\#]+)", "g");
    }, n.prototype._getTextDataPerLine = function (t) {
      for (var e = [], i = this.getTagRegex(!0, !1), s = [this.assign({}, this.textStyles.default)], o = [{
          name: "default",
          properties: {}
        }], n = 0; n < t.length; n++) {
        for (var r = [], h = [], l = void 0; l = i.exec(t[n]);) h.push(l);
        if (0 === h.length) r.push(this.createTextData(t[n], s[s.length - 1], o[o.length - 1]));else {
          for (var c = 0, d = 0; d < h.length; d++) {
            if (h[d].index > c && r.push(this.createTextData(t[n].substring(c, h[d].index), s[s.length - 1], o[o.length - 1])), "/" === h[d][0][1]) s.length > 1 && (s.pop(), o.pop());else {
              for (var x = {}, g = this.getPropertyRegex(), p = void 0; p = g.exec(h[d][0]);) x[p[1]] = p[2] || p[3];
              if (o.push({
                name: h[d][1],
                properties: x
              }), "bbcode" === this.textStyles.default.tagStyle && h[d][0].includes("=") && this.textStyles[h[d][1]]) {
                var u = this.getBBcodePropertyRegex().exec(h[d][0]),
                  f = {};
                Object.entries(this.textStyles[h[d][1]]).forEach(function (t) {
                  f[t[0]] = "string" != typeof t[1] ? t[1] : u[1] + t[1];
                }), s.push(this.assign({}, s[s.length - 1], f));
              } else s.push(this.assign({}, s[s.length - 1], this.textStyles[h[d][1]]));
            }
            c = h[d].index + h[d][0].length;
          }
          if (c < t[n].length) {
            var y = this.createTextData(c ? t[n].substring(c) : t[n], s[s.length - 1], o[o.length - 1]);
            r.push(y);
          }
        }
        e.push(r);
      }
      var b = this.textStyles.default.tagStyle;
      return e[e.length - 1].map(function (t) {
        t.text.includes(a[b][0]) && (t.text = t.text.match("bbcode" === b ? /^(.*)\[/ : /^(.*)\</)[1]);
      }), e;
    }, n.prototype.getFontString = function (e) {
      return new t(e).toFontString();
    }, n.prototype.createTextData = function (t, e, i) {
      return {
        text: t,
        style: e,
        width: 0,
        height: 0,
        fontProperties: void 0,
        tag: i
      };
    }, n.prototype.getDropShadowPadding = function () {
      var t = this,
        e = 0,
        i = 0;
      return Object.keys(this.textStyles).forEach(function (s) {
        var o = t.textStyles[s],
          n = o.dropShadowBlur;
        e = Math.max(e, o.dropShadowDistance || 0), i = Math.max(i, n || 0);
      }), e + i;
    }, n.prototype.withPrivateMembers = function () {
      return this;
    }, n.prototype.updateText = function () {
      var o = this;
      if (this.withPrivateMembers().dirty) {
        this.hitboxes = [], this.texture.baseTexture.resolution = this.resolution;
        var r = this.textStyles,
          a = this.text;
        this.withPrivateMembers()._style.wordWrap && (a = this.wordWrap(this.text));
        for (var h = a.split(/(?:\r\n|\r|\n)/), l = this._getTextDataPerLine(h), c = [], d = [], x = [], g = 0, p = 0; p < h.length; p++) {
          for (var u = 0, f = 0, y = 0, b = 0; b < l[p].length; b++) {
            var v = l[p][b].style;
            this.context.font = this.getFontString(v), l[p][b].width = this.context.measureText(l[p][b].text).width, 0 !== l[p][b].text.length && (l[p][b].width += (l[p][b].text.length - 1) * v.letterSpacing, b > 0 && (u += v.letterSpacing / 2), b < l[p].length - 1 && (u += v.letterSpacing / 2)), u += l[p][b].width, l[p][b].fontProperties = e.measureFont(this.context.font), l[p][b].height = l[p][b].fontProperties.fontSize, "number" == typeof v.valign ? (f = Math.min(f, v.valign - l[p][b].fontProperties.descent), y = Math.max(y, v.valign + l[p][b].fontProperties.ascent)) : (f = Math.min(f, -l[p][b].fontProperties.descent), y = Math.max(y, l[p][b].fontProperties.ascent));
          }
          c[p] = u, d[p] = f, x[p] = y, g = Math.max(g, u);
        }
        var S = Object.keys(r).map(function (t) {
            return r[t];
          }).reduce(function (t, e) {
            return Math.max(t, e.strokeThickness || 0);
          }, 0),
          m = this.getDropShadowPadding(),
          w = g + 2 * S + 2 * m,
          T = x.reduce(function (t, e) {
            return t + e;
          }, 0) - d.reduce(function (t, e) {
            return t + e;
          }, 0) + 2 * S + 2 * m;
        this.canvas.width = w * this.resolution, this.canvas.height = T * this.resolution, this.context.scale(this.resolution, this.resolution), this.context.textBaseline = "alphabetic", this.context.lineJoin = "round";
        for (var P = m + S, k = [], _ = 0; _ < l.length; _++) {
          var M = l[_],
            F = void 0;
          switch (this.withPrivateMembers()._style.align) {
            case "left":
              F = m + S;
              break;
            case "center":
              F = m + S + (g - c[_]) / 2;
              break;
            case "right":
              F = m + S + g - c[_];
          }
          for (var O = 0; O < M.length; O++) {
            var A = M[O],
              D = A.style,
              E = A.text,
              B = A.fontProperties,
              W = A.width,
              j = A.tag,
              R = P + B.ascent;
            switch (D.valign) {
              case "top":
                break;
              case "baseline":
                R += x[_] - B.ascent;
                break;
              case "middle":
                R += (x[_] - d[_] - B.ascent - B.descent) / 2;
                break;
              case "bottom":
                R += x[_] - d[_] - B.ascent - B.descent;
                break;
              default:
                R += x[_] - B.ascent - D.valign;
            }
            if (0 === D.letterSpacing) k.push({
              text: E,
              style: D,
              x: F,
              y: R,
              width: W,
              ascent: B.ascent,
              descent: B.descent,
              tag: j
            }), F += M[O].width;else {
              this.context.font = this.getFontString(M[O].style);
              for (var L = 0; L < E.length; L++) {
                (L > 0 || O > 0) && (F += D.letterSpacing / 2);
                var z = this.context.measureText(E.charAt(L)).width;
                k.push({
                  text: E.charAt(L),
                  style: D,
                  x: F,
                  y: R,
                  width: z,
                  ascent: B.ascent,
                  descent: B.descent,
                  tag: j
                }), F += z, (L < E.length - 1 || O < M.length - 1) && (F += D.letterSpacing / 2);
              }
            }
          }
          P += x[_] - d[_];
        }
        this.context.save(), k.forEach(function (t) {
          var e = t.style,
            s = t.text,
            n = t.x,
            r = t.y;
          if (e.dropShadow) {
            o.context.font = o.getFontString(e);
            var a = e.dropShadowColor;
            "number" == typeof a && (a = i.hex2string(a)), o.context.shadowColor = a, o.context.shadowBlur = e.dropShadowBlur, o.context.shadowOffsetX = Math.cos(e.dropShadowAngle) * e.dropShadowDistance * o.resolution, o.context.shadowOffsetY = Math.sin(e.dropShadowAngle) * e.dropShadowDistance * o.resolution, o.context.fillText(s, n, r);
          }
        }), this.context.restore(), k.forEach(function (t) {
          var e = t.style,
            s = t.text,
            n = t.x,
            r = t.y;
          if (void 0 !== e.stroke && e.strokeThickness) {
            o.context.font = o.getFontString(e);
            var a = e.stroke;
            "number" == typeof a && (a = i.hex2string(a)), o.context.strokeStyle = a, o.context.lineWidth = e.strokeThickness, o.context.strokeText(s, n, r);
          }
        }), k.forEach(function (e) {
          var s = e.style,
            n = e.text,
            r = e.x,
            a = e.y;
          if (void 0 !== s.fill) {
            o.context.font = o.getFontString(s);
            var h = s.fill;
            if ("number" == typeof h) h = i.hex2string(h);else if (Array.isArray(h)) for (var l = 0; l < h.length; l++) {
              var c = h[l];
              "number" == typeof c && (h[l] = i.hex2string(c));
            }
            o.context.fillStyle = o._generateFillStyle(new t(s), [n]), o.context.fillText(n, r, a);
          }
        }), k.forEach(function (t) {
          var e = t.style,
            i = t.x,
            r = t.y,
            a = t.width,
            h = t.ascent,
            l = t.descent,
            c = t.tag,
            d = -o.withPrivateMembers()._style.padding - o.getDropShadowPadding();
          o.hitboxes.push({
            tag: c,
            hitbox: new s(i + d, r - h + d, a, h + l)
          }), (void 0 === e.debug ? n.debugOptions.spans.enabled : e.debug) && (o.context.lineWidth = 1, n.debugOptions.spans.bounding && (o.context.fillStyle = n.debugOptions.spans.bounding, o.context.strokeStyle = n.debugOptions.spans.bounding, o.context.beginPath(), o.context.rect(i, r - h, a, h + l), o.context.fill(), o.context.stroke(), o.context.stroke()), n.debugOptions.spans.baseline && (o.context.strokeStyle = n.debugOptions.spans.baseline, o.context.beginPath(), o.context.moveTo(i, r), o.context.lineTo(i + a, r), o.context.closePath(), o.context.stroke()), n.debugOptions.spans.top && (o.context.strokeStyle = n.debugOptions.spans.top, o.context.beginPath(), o.context.moveTo(i, r - h), o.context.lineTo(i + a, r - h), o.context.closePath(), o.context.stroke()), n.debugOptions.spans.bottom && (o.context.strokeStyle = n.debugOptions.spans.bottom, o.context.beginPath(), o.context.moveTo(i, r + l), o.context.lineTo(i + a, r + l), o.context.closePath(), o.context.stroke()), n.debugOptions.spans.text && (o.context.fillStyle = "#ffffff", o.context.strokeStyle = "#000000", o.context.lineWidth = 2, o.context.font = "8px monospace", o.context.strokeText(c.name, i, r - h + 8), o.context.fillText(c.name, i, r - h + 8), o.context.strokeText(a.toFixed(2) + "x" + (h + l).toFixed(2), i, r - h + 16), o.context.fillText(a.toFixed(2) + "x" + (h + l).toFixed(2), i, r - h + 16)));
        }), n.debugOptions.objects.enabled && (n.debugOptions.objects.bounding && (this.context.fillStyle = n.debugOptions.objects.bounding, this.context.beginPath(), this.context.rect(0, 0, w, T), this.context.fill()), n.debugOptions.objects.text && (this.context.fillStyle = "#ffffff", this.context.strokeStyle = "#000000", this.context.lineWidth = 2, this.context.font = "8px monospace", this.context.strokeText(w.toFixed(2) + "x" + T.toFixed(2), 0, 8, w), this.context.fillText(w.toFixed(2) + "x" + T.toFixed(2), 0, 8, w))), this.updateTexture();
      }
    }, n.prototype.wordWrap = function (t) {
      var e = "",
        i = this.getTagRegex(!0, !0),
        s = t.split("\n"),
        o = this.withPrivateMembers()._style.wordWrapWidth,
        n = [this.assign({}, this.textStyles.default)];
      this.context.font = this.getFontString(this.textStyles.default);
      for (var r = 0; r < s.length; r++) {
        for (var a = o, h = s[r].split(i), l = !0, c = 0; c < h.length; c++) if (i.test(h[c])) e += h[c], "/" === h[c][1] ? (c += 2, n.pop()) : (n.push(this.assign({}, n[n.length - 1], this.textStyles[h[++c]])), c++), this.context.font = this.getFontString(n[n.length - 1]);else for (var d = h[c].split(" "), x = 0; x < d.length; x++) {
          var g = this.context.measureText(d[x]).width;
          if (this.withPrivateMembers()._style.breakWords && g > a) {
            var p = d[x].split("");
            x > 0 && (e += " ", a -= this.context.measureText(" ").width);
            for (var u = 0; u < p.length; u++) {
              var f = this.context.measureText(p[u]).width;
              f > a ? (e += "\n" + p[u], a = o - f) : (e += p[u], a -= f);
            }
          } else if (this.withPrivateMembers()._style.breakWords) e += d[x], a -= g;else {
            var y = g + (x > 0 ? this.context.measureText(" ").width : 0);
            y > a ? (l || (e += "\n"), e += d[x], a = o - g) : (a -= y, x > 0 && (e += " "), e += d[x]);
          }
          l = !1;
        }
        r < s.length - 1 && (e += "\n");
      }
      return e;
    }, n.prototype.updateTexture = function () {
      var t = this.withPrivateMembers()._texture,
        e = this.getDropShadowPadding();
      t.baseTexture.setRealSize(this.canvas.width, this.canvas.height, this.resolution), t.trim.width = t.frame.width = this.canvas.width / this.resolution, t.trim.height = t.frame.height = this.canvas.height / this.resolution, t.trim.x = -this.withPrivateMembers()._style.padding - e, t.trim.y = -this.withPrivateMembers()._style.padding - e, t.orig.width = t.frame.width - 2 * (this.withPrivateMembers()._style.padding + e), t.orig.height = t.frame.height - 2 * (this.withPrivateMembers()._style.padding + e), this.withPrivateMembers()._onTextureUpdate(), t.baseTexture.emit("update", t.baseTexture), this.withPrivateMembers().dirty = !1;
    }, n.prototype.assign = function (t) {
      for (var e = [], i = arguments.length - 1; i-- > 0;) e[i] = arguments[i + 1];
      for (var s = 0, o = e; s < o.length; s += 1) {
        var n = o[s];
        for (var r in n) t[r] = n[r];
      }
      return t;
    }, Object.defineProperties(n.prototype, h), n;
  }(o);
h.DEFAULT_TAG_STYLE = {
  align: "left",
  breakWords: !1,
  dropShadow: !1,
  dropShadowAngle: Math.PI / 6,
  dropShadowBlur: 0,
  dropShadowColor: "#000000",
  dropShadowDistance: 5,
  fill: "black",
  fillGradientType: n.LINEAR_VERTICAL,
  fontFamily: "Arial",
  fontSize: 26,
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  letterSpacing: 0,
  lineHeight: 0,
  lineJoin: "miter",
  miterLimit: 10,
  padding: 0,
  stroke: "black",
  strokeThickness: 0,
  textBaseline: "alphabetic",
  valign: "baseline",
  wordWrap: !1,
  wordWrapWidth: 100,
  tagStyle: "xml"
}, h.debugOptions = {
  spans: {
    enabled: !1,
    baseline: "#44BB44",
    top: "#BB4444",
    bottom: "#4444BB",
    bounding: "rgba(255, 255, 255, 0.1)",
    text: !0
  },
  objects: {
    enabled: !1,
    bounding: "rgba(255, 255, 255, 0.05)",
    text: !0
  }
};
export default h;
