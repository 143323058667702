import { Message } from '../../message';

export class TableColorDetailsList extends Message {
    private MESSAGE_NAME: string = 'TableColorDetailsList';
    private tableColorDetailsList: any[] | null = null;
    private domainID: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableColorDetailsList: any[] | null = null, domainID: number = 0) {
        super(reqServerPeerId);
        this.tableColorDetailsList = tableColorDetailsList;
        this.domainID = domainID;
    }

    getTableColorDetailsList(): any[] | null {
        return this.tableColorDetailsList;
    }

    setTableColorDetailsList(tableColorDetailsList: any[] | null) {
        this.tableColorDetailsList = tableColorDetailsList;
    }
    getDomainID(): number {
        return this.domainID;
    }

    setDomainID(domainID: number) {
        this.domainID = domainID;
    }
}
