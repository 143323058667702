{
    "ADD_NEW_TABLE": "addNewTable",
    "RESIZE_TABLE": "resizeTable",
    "UPDATE_PRELOADER": "updatePreloaderProgress",
    "BACKGROUND_CREATED": "backgroundCreated",
    "SECONDARY_ASSETS_LOADED": "SecondaryAssetsLoaded",
    "EXTRA_ASSETS_LOADED": "ExtraAssetsLoaded",
    "LAST_ASSETS_LOADED": "LastAssetsLoaded",
    "UPDATE_PRELOADER_POSITION": "updatePosition",
    "REFRESH_VIEW_TICK": "refreshViewTick",
    "SLIDER_VALUE_CHANGE": "sliderValueChange",
    "SLIDER_UP": "SliderUp",
    "SET_ACCOUNT_BALANCE": "setAccountBalance",
    "AUTOSPIN_BUTTON_STATE": "autoSpinButtonState",
    "SHOW_FREESPIN_START": "ShowFreeSpinStart",
    "SHOW_FREESPIN_END": "ShowFreeSpinEnd",
    "UPDATE_FREESPIN_COUNTER": "updateFreeSpinCounter",
    "DISABLE_CONTROL_BUTTONS": "disableControlButtons",
    "UPDATE_TOTALWIN_IN_PANEL": "updateTotalwinInPanel",
    "SHOW_BIG_WIN": "ShowBigWin",
    "SHOW_ALL_LINE_WINS": "showAllLineWins",
    "CLEAR_BIG_WINS": "ClearBigWin",
    "VALIDATE_GAME_STATE": "validateGameState",
    "SHOW_ALTERNATE_WIN": "ShowAlternateWin",
    "CLEAR_ALL_WINS": "ClearAllWins",
    "HANDLE_GAME_INIT": "HandleGameInit",
    "HANDLE_SPIN_RESULT": "HandleSpinResult",
    "HANDLE_SPIN_RESULT_SPECIAL": "HandleSpinResultSpecial",
    "SHOW_RULESPAYTABLE": "ShowRulesPaytable",
    "RESET_REELS": "ResetReels",
    "SHOW_SCATTER_EXCITEMENT": "ShowScatterExcitement",
    "SHOW_TRIGGERING_WIN": "ShowTriggerWin",
    "HANDLE_SPIN_START": "HandleSpinStart",
    "HANDLE_SPIN_START_REVERSE": "HandleSpinStartReverse",
    "UPDATE_FREESPIN_WIN": "UpdateFreeSpinWin",
    "SET_SYMBOL_POSITIONS": "SetSymbolPositions",
    "SHOW_SPECIAL_BONUS_TRIGGER": "showSpecialBonusTrigger",
    "SHOW_SPECIAL_BONUS_WINTYPE": "ShowSpecialBonusWinType",
    "REPLACE_REEL_WITH_WILD": "ReplaceReelWithWild",
    "CLEAR_SPECIAL_BONUSWIN_TYPE": "clearSpecialBonusWinType",
    "SHOW_BONUS_GAME": "ShowBonusGame",
    "HANDLE_FEATURE_RESPOINSE_IN_BONUS": "HandleFeatureResponseInBonus",
    "ON_GAME_RESIZE": "OnGameResize",
    "HIDE_WILD_EXPANSION": "HideWildExpansion",
    "SHOW_SECOND_REEL_FEATURE_REEL": "ShowSecondReelFeatureReel",
    "SHOW_SECOND_REEL_FEATURE_SYMBOL": "ShowSecondReelFeatureReelSymbol",
    "STOP_REELS_AFTER_SPECIAL_BONUS": "StopReelsAfterSpecialBonus",
    "SHOW_BRAND_LOGO": "ShowBrandLogo",
    "SHOW_FREESPIN_END_CELEBRATION": "ShowFreeSpinEndCelebration",
    "CLEAR_FREESPIN_END_POPUP": "ClearFreeSpinEndPopup",
    "HANDLE_REEL_ACTION_ON_STOP": "HandleReelActionOnStop",
    "ADD_BONUS_BG": "AddBonusBG",
    "SHOW_BONUS_BG": "ShowBonusBG",
    "HIDE_BONUS_BG": "HideBonusBG",
    "VIEWCHANGE_FOR_FREESPIN_START": "ViewChangeForFreeSpinStart",
    "VIEWCHANGE_FOR_FREESPIN_END": "ViewChangeForFreeSpinEnd",
    "UPDATE_AUTOSPIN_COUNT": "UpdateAutoSpinCount",
    "HANDLE_FEATURE_TRIGGER": "HandleFeatureTrigger",
    "SHOW_RESPIN_ACTIVATED": "ShowRespinActivated",
    "SHOW_ALL_REEL_SYMBOLS": "ShowAllReelSymbols",
    "KEYBOARD_EVENT": "KeyboardEvent",
    "ON_BIG_WIN_SHOWN_COMPLETE": "OnBigWinShownComplete",
    "HIDE_VIEWS_FOR_FEATURE_WITH_REELFRAME": "HideViewsForReatureWithReelFrame",
    "UPDATE_IDLE_TICKER": "UpdateIdleTicker",
    "UPDATE_SPINTIME_TICKER": "UpdateSpinTimeTicker",
    "UPDATE_WIN_MESSAGE_TICKER": "UpdateWinMessageTicker",
    "UPDATE_FEATURE_MESSAGE_TICKER": "UpdateFeatureMessageTicker",
    "UPDATE_NOWIN_MESSAGE_TICKER": "UpdateNoWinMessageTicker",
    "UPDATE_LINEWIN_MESSAGE_TICKER": "updateLineWinTicker",
    "SHOW_SETTING_BTN": "onSettingsBtnClick",
    "MOBILE_HOME_BTN": "onHomeBtnClick",
    "UPDATE_PRELOADING_TICKER": "updatePreLoadingTicker",
    "HIDE_ERROR": "hideerror",
    "SHOW_POPUP_WAPPER": "showpopupwapper",
    "HIDE_POPUP_WAPPER": "hidepopupwapper",
    "STOP_REELS_ONERROR": "StopReelsOnError",
    "BONUS_GAME_RENDERED": "BonusGameRendered",
    "RESTART_LOADING_ASSETS": "restartLoadingAssets",
    "UPDATE_FREESPIN_END_MESSAGE": "UpdateFreeSpinEndMessage",
    "SKIP_INTRO_VIDEO": "skipIntroVideo",
    "UNFINISHED_OK_BTN": "unFinishedOkBtn",
    "VIEWCHANGE_FOR_FREESPIN_START_DUMMY": "ViewChangeForFreeSpinStartDummy",
    "SHOW_NEXT_JACKPOT": "showNextJackpot",
    "UPDATE_JACKPOT_AMOUNT": "updateJackpotAmount",
    "VIEWCHANGE_FOR_JACKPOT_START": "viewChangeForJackpotStart",
    "VIEWCHANGE_FOR_JACKPOT_END": "viewChangeForJackpotEnd",
    "START_JACKPOT_GAME": "startJackpotgame",
    "SET_PAYLINE_VISIBILITY": "setPaylineVisibility",
    "ON_JACKPOT_END": "onJackpotEnd",
    "HANDLE_FEATURE_TYPE_RESPOINSE_IN_BONUS": "handleFeatureTypeResponseInBonus",
    "GAME_AVAILABILITY": "gameAvailibility",
    "HIDE_FEATURE_MESSAGE_TICKER": "hideFeatureMessageTicker",
    "HANDLE_UNFINISHED_REEL_STOP": "handleUnfinishedReelStop",
    "HIDE_CASHFLASHJACKPOT": "hidecashflashjackpot",
    "UPDATE_GAME_VALUES": "updateGameValues",
    "REMOVE_TICKER": "removeTicker",
    "SCATTER_POSITIONS": "scatterPositions",
    "UPDATE_AUTOSPIN_VISIBILITY": "updateAutoSpinVisibility",
    "UPDATE_CONNECTION_TICKER": "updateConnectionTicker",
    "CHANGE_FREE_SPIN_PAYLINES": "changeFreeSpinPaylines",
    "CHANGE_MAIN_GAME_PAYLINES": "changeMainGamePaylines",
    "SHOW_WILD_EXPANSION": "ShowWildExpansion",
    "WILD_EXAPANSION_COMPLETE": "wildExpansionComplete",
    "UPDATE_WIN_TICKER_IN_FEATURE": "updateWinTickerInFeature",
    "SHOW_WILD_EXPANDED_SYMBOL": "showWildExpandedSymbol",
    "HIDE_WILD_EXPANDED_SYMBOL": "hideWildExpandedSymbol",
    "SET_REELSET_HULA_GIRL": "setReelsetForHulaGirl",
    "HIDE_GAME_TITLE": "hideGameTitle",
    "UPDATE_STICKY_WILD": "updateStickyWild",
    "UPDATE_ROUNDWIN_IN_PANEL": "updateRoundWinInPanel",
    "SHOW_NECROPOLIS_BONUS_BG": "necroPolisBonusGameBg",
    "COMPLETE_BONUS_GAME_TRASITION": "completeBonusGameTransition",
    "SET_REELS_DATA": "setReelsData",
    "REPLACE_REEL_WITH_CUSTOM_WILD": "ReplaceReelWithCustomWild",
    "SHOW_FREE_SPIN_TRIGGER": "showfreespintrigger",
    "SHOW_WILD_TRIGGERING_ANIMATION": "showWildTriggeringAnimation",
    "REPLACE_SYMBOLS_WITH_STICKY_WILD": "replaceSymbolsWithStickyWild",
    "NECROPOLIS_BONUS_GAME_CLICK": "necroPolisBonusGameClick",
    "SHOW_FREE_SPIN_END_TRANSITION": "freeSpinEndTransition",
    "CHIP_CLICKED": "chipsClicked",
    "SIDEBET_CLICKED": "sideBetClicked",
    "PLAYERS_PAIR_CLICKED": "playersPairClicked",
    "DEAL_CLICK": "dealClick",
    "HANDLE_DEAL_RESULT": "handleDealResult",
    "DEAL_FOR_CARDS_CLICKED": "dealCardsClicked",
    "BET_PLACE_VIEW_CLICKED": "betPlaceViewClicked",
    "UPDATE_CONTROL_PANEL_BUTTONS": "updateControlPanelButtons",
    "GAME_OPTION_CLICK": "gameOptionClick",
    "HANDLE_CURRENT_HAND_DATA": "handleCurrentHandData",
    "HANDLE_BJ_SET_CARDS": "handleBJSetCards",
    "HANDLE_MPBJ_GAME_RESULT": "handleMPBJGameResult",
    "HANDLE_GAME_OVER": "handleGameOver",
    "REVEAL_DEALER_CARDS": "revealDealerCards",
    "SET_CURRENT_SEAT_NUMBER": "setCurrentSeatNumber",
    "TOTAL_DEAL_FOR_CARDS_CLICKED": "totalDealForCardsClicked",
    "ENABLE_ALL_FOR_NEW_DEAL": "enableAllForNewDeal",
    "SEND_NEXT_EVENT_IN_QUEUE": "sendnextEventInQueue",
    "ANIMATE_CHIPS": "animateChips",
    "BET_VALUE_UPDATE_ON_CLEARBET": "betValueUpdateOnClearBet",
    "DOUBLE_BET": "doubleBets",
    "REVERSE_ANIMATE_CHIPS": "reverseAnimateChips",
    "HANDLE_INSURANCE_MESSAGE": "handleInsuranceMessage",
    "HANDLE_INSURANCE_CLICK": "handleInsuranceClick",
    "REBET_CLICK": "rebetClick",
    "REBET_AND_DEAL_CLICK": "rebetAndDeal",
    "REBET_CHIPS_ANIMATION": "rebetChipsAnimation",
    "SPLIT_CARDS": "splitCards",
    "HANDLE_GAME_AFTER_SPLIT": "handleGameAfterSplit",
    "ANIMATE_INSURANCE_AMOUNT": "animateInsuranceAmount",
    "HIDE_CHIPSCONTAINER": "hideChipsContainer",
    "DEALER_SIDEBET_RESULT": "dealerSideBetResult",
    "HANDLE_UNFINISHED_DEAL_RESULT": "handleUnfinishedDealResult",
    "REMOVE_LAST_BET_PLACED": "removeLastBetPalced",
    "ANIMATE_LAST_CHIPS": "animateLastChips",
    "DOUBLE_AND_DEAL": "doubleAndDeal",
    "CARDS_VIEW_SHIFT_POSITIONS": "cardsViewShiftPositions",
    "DOUBLE_COUNT_PLUS": "doubleCountMinusReachedToMaxValue",
    "WIN_POP_UP_SHOW": "winPopUpShow",
    "CHECK_FOR_ADDRESS_BAR_N_TOP": "checkForAddressBarNTop",
    "CHECK_WINDOW_POSITION": "checkWindowPosition",
    "SCROLL_TO_TOP": "scrollToTop",
    "CHECK_FOR_ADDRESS_BAR": "CHECK_FOR_ADDRESS_BAR",
    "UPDATE_INTERMEDIATE_RESULT": "updateIntermediateResult",
    "SHOW_WARNING": "showWarning",
    "HIT_STAND_AFTER_CONTINUE": "hitStandAfterContinue",
    "HANDLE_DEAL_CARDS": "handleDealCards",
    "INFO_BUTTON_CLICKED": "inforButtonClicked",
    "SHOW_COMMUNITYCARDS": "showCommunityCards",
    "START_SPIN_MULTILPIER": "startSpinMultilpier",
    "END_SPIN_MULTIPLIER": "endSpinMultilpier",
    "RESET_GAME": "resetGame",
    "SHOW_BET_OPTIONS": "showBetOptions",
    "SHOW_SHOWCARD_OPTIONS": "showShowCardOptions",
    "SHOW_MUCKCARD_OPTIONS": "showMuckCardOptions",
    "SHOW_AUTO_BET_OPTIONS": "showAutoBetOptions",
    "BET_OPTION_SELECTED": "betOptionSelected",
    "SHOW_CARD_OPTION_SHOW_SELECTED": "showCardsOptionShowSelected",
    "SHOW_CARD_OPTION_MUCK_SELECTED": "showCardsOptionMuckSelected",
    "SHOW_CARD_OPTION_DONTSHOW_SELECTED": "showCardsOptionDontShowSelected",
    "AUTO_BET_OPTION_SELECTED": "autoBetOptionSelected",
    "THROW_ANIMATION": "throwAnimation",
    "REQ_THROW_ANIMATION": "ReqThrowAnimation",
    "SEND_REQUEST_AUTO_CHECK_ON": "RequestAutoCheckOn",
    "EMOJI_BUTTON_CLICKED": "emojiButtonClicked",
    "SELF_EMOJI_SELECTED": "selfEmojiForRequest",
    "BROADCAST_EMOJI": "broadcastEmojitoAll",
    "HANDLE_ADD_PLAYER": "handleAddPlayer",
    "OTHERPLAYER_SELECTED_OPTION": "otherPlayerSelectedOptions",
    "SHOW_IAM_BACK": "showIamBackBtn",
    "IAM_BACK_CLICKED": "iamBackClicked",
    "HANDLE_END_THE_ROUND": "handleEndTheRound",
    "Disable_All_In_Glow": "disableAllInGlow",
    "BOOSTER_ENABLED": "boosterEnabled",
    "HIDE_PLAYER_ACTION": "hidePlayerAction",
    "REMOVE_PLAYER": "removePlayer",
    "CLOSE_TABLE": "closeTable",
    "HANDLE_POT_WINNERS": "potWinnersData",
    "SHOW_BET_TIMER": "showBetTimer",
    "SHOW_CARDS": "ShowCards",
    "UN_REGISTER": "onClickUnregister",
    "HANDLE_BLIND_DATA": "handleBlindData",
    "HANDLE_ANTE_DATA": "handleAnteData",
    "SHOW_EMOJIS_NOW": "showEmojisNow",
    "REMOVE_BET_OPTIONS": "removeBetOptions",
    "PLAY_EMOJI_NOW": "playEmojiToAnotherPlayer",
    "SHOW_TIMER_ANI": "showTimerAniCircle",
    "ON_LEAVE": "onClickLeave",
    "ON_SITOUT": "onClickSitOut",
    "UPDATE_PLAYER_BALANCES": "updatePlayerBalances",
    "TIMER_STARTED_NOTIFICATION": "optionTimerStarted",
    "TIMER_STOPPED_NOTIFICATION": "optionTimerRemoved",
    "SHOW_TIME_BANK_SYMBOL": "showTimeBankSymbol",
    "ON_CLICK_TIME_BANK": "onClickTimeBank",
    "ENABLE_TIME_BANK": "enableTimeBank",
    "REQ_ENABLE_RABBIT": "ReqEnableRabbit",
    "SELF_HANDSTRENGTH": "showselfplayerhandstrength",
    "START_REEL_ANIMATION": "startReelAnimation",
    "REEL_ANIMATION_COMPLETED": "reelAnimationCompleted",
    "INITITATE_REEL_STOP": "initiateReelStop",
    "SHOW_RABBIT": "showrabbitforresponse",
    "UPDATE_DIAMOND_BALANCE": "updateDiamondBalance",
    "REQ_POKER_DIAMOND_BALANCE": "requestPokerDiamondBalance",
    "CHANGE_CARD_STYLE": "changeCardStyle",
    "HANDLE_SETTING_DATA": "handleSettingDataSentfromAngular",
    "HANDLE_THEME_CHANGE": "handlethemechangefromAngular",
    "SHOW_LEAVE_CONFORMATION": "showConformationPopUpWhenClickOnLeaveButton",
    "CONCLUDED_LEAVE": "leaveTableWhenUsewrConform",
    "RESIZE_TABLE_VIEW": "resizeTableView",
    "MAXIMISE_TABLE_VIEW": "maximiseTableScale",
    "FORCE_REFRESH_TABLE": "forceRefreshTable",
    "SNGJP_TOURNAMENT_RANKINFO": "showRankOfTournament",
    "SHOW_MUCKCARDS": "showMuckCards",
    "HANDLE_NEW_GAME": "handleNewGame",
    "HANDLE_PLAYER_STATUS": "handlePlayerStatus",
    "MINI_SWITCHER_CLICKED": "miniSwitcherClicked",
    "ADD_GENERIC_POPUP": "AddGenericPopup",
    "BROADCAST_SHOW_RABBIT": "broadCastRabbitOpponentPlayer",
    "ACTION_FOLD": "actionFold",
    "TABLE_RESIZE_START": "tableResizeStarted",
    "DISPLAY_MAX_BTN": "displayMaxBtn",
    "DISPLAY_MIN_BTN": "displayMinBtn",
    "HANDLE_GAME_STATUS": "handleGameStatus",
    "ON_WINCHIPS_ANIMATION_COMPLETED": "OnwinChipAnimationCompleted",
    "SHOW_EMOJI_ON_TOP": "showEmojiOnTop",
    "HIDE_WIN_PROB": "hideWinProb",
    "REMOVE_SELF_HAND_STRENGTH": "removeSelfHandStrength",
    "PLAY_SOUNDS": "playSounds",
    "STOP_SOUNDS": "stopSounds",
    "SHOW_BLACK_BG": "showBlackbg",
    "REMOVE_REGISTRAION_VIEW": "removeRegistrationView",
    "TIME_BANK_NOTIFICATION": "timeBankNotification",
    "HANDLE_WINNER_CARDS": "handleWinnerCards",
    "WIN_GAME_CLOSE": "winGameClose",
    "UPDATE_BACKGROUND_ON_DISC": "updateBackgroundOnDisc",
    "SHOW_OTHER_PLAYER_RABIT": "showOtherPlayerRabits",
    "SHOW_FORCE_AWAY_STATE": "showForceAwayState",
    "UPDATE_DEALER_ICON_POS": "updateDealerIconPos",
    "UPDATE_PRIZEPOOLVALUE": "updatePrizePoolValue",
    "HANDLE_FULL_GAME_CLOSE": "fullGameClosed",
    "HANDLE_DISCONNECTION_STATE": "handleDisconnectionState",
    "GAME_VIEW_CREATED": "gameViewCreated",
    "HANDLE_CHANGE_PLAYERSTATE": "changePlayerState",
    "MAX_BUTTON_CLICKED": "maxButtonClicked",
    "UPDATE_POTAMOUNT_IN_RAISEPANEL": "updatePotAmountInRaisePanel",
    "TIMER_ANI_DONE": "timerAniDone",
    "HIDE_IAM_BACK": "hideIamBack",
    "UPDATE_TIMER_ANIM": "updateTimerAnim",
    "SHOW_FORCE_TABLE_LOADER": "showTableLoader",
    "SHOW_WAITING_FOR_PLAYERS": "showWaitingForPlayers",
    "SHOW_NEXT_HAND": "showNextHand",
    "NEXT_HAND_SELECTED": "sendReqNextHandOffFoldAndWatch",
    "OPEN_REBUY_WINDOW": "openRebuyWindow",
    "UPDATE_REBUY_STATUS": "updaterebuystatusontable",
    "PROBABILITY_OF_WINNING": "probabilityOfWinning",
    "SHOW_HU_PAIR_TABLE": "showHUPairTable",
    "HU_PAIR_TABLE_STATUS": "huPairTableStatus",
    "MUTE_SOUNDS": "muteSounds",
    "DISABLE_PLAYER_CLICK": "disableplayericonclick",
    "ENABLE_PLAYER_CLICK": "enableplayericonclick",
    "SHOW_TIMER_FOR_ALL_IN": "showTimerForAllIn",
    "PLAYER_CONTAINER_POS": "playerContainerPos",
    "HIDE_POT_AMOUNT": "hidePotAmount",
    "SHOW_POT_AMOUNT": "showPotAmount",
    "ZOOM_IN_BG_RIM": "zoominbgrim",
    "SHOW_POPUP_ON_WIDGET": "showpopuponwidget",
    "HIDE_POPUP_ON_WIDGET": "hidepopuponwidget",
    "UPDATE_THROW_ANIM": "updatethrowanim",
    "UPDATE_EMOJI_TAB": "updateemojitab",
    "HIDE_KOTH_PLUS": "hidekothplus",
    "SHOW_LOYALTY_CASH_BACK_POPUP": "showLoyaltyCashBackPopUp",
    "HIDE_LOYALTY_CASH_BACK": "hideLoyaltyCashBack",
    "UPDATE_LCB_HANDEND": "updateLCBHandEnd",
    "RESET_LCB_DATA": "resetLCBData",
    "LCB_SET_NOT_CLICKABLE": "setNotClikable",
    "UPDATE_PLAYED_GAMES_COUNT": "UpdatePlayedGamesCount",
    "SHOW_ANTITBL_HOP_SITOUT": "showAntiTblHopSitOutPopup",
    "ATH_CLOSE_TABLE": "athCloseTable",
    "SITIN_ALL_TBL": "sitinAllTbl",
    "SHOW_ANTITBL_HOP_AUTO_SITOUT": "showAntiTblHopAutoSitOutPopup",
    "GO_TO_CASH_GAME_TABLE": "goToCashGameTable",
    "INACTIVE_PLAYER": "inactivePlayer",
    "ENABLE_CASHOUT_BUTTONS": "enablecashbuttons",
    "SHOW_CASHOUT_ANIM": "showcashoutanim",
    "DISABLE_ACTION_FOR_CASHOUT": "disableactionforcashout",
    "ENABLE_ACTION_FOR_CASHOUT": "enableactionforcashout",
    "CASHOUT_TIMER": "cashouttimer",
    "SELECTED_CASHOUT_OPTION": "selectedcashoutoption",
    "SHOW_CASHOUT_AMOUNT": "showcashoutamount",
    "HIDE_BACKCARDS": "hidebackcards",
    "OPPONENT_CASHOUT_ANIM": "opponentcashoutanim",
    "SHOW_EQUITY_PERCENTAGE": "showequitypercentage",
    "HIDE_ANIMATIONS_FOR_CASHOUT": "hideanimationsforcashout",
    "REMOVED_PLAYERS": "removedplayers",
    "HIDE_CARDS": "hidecards",
    "ON_CASHOUT_DISABLED": "oncashoutdisabled",
    "REMOVE_CASHOUT_DISABLED_TEXT": "removecashoutdisabledtext",
    "SHOW_CASHOUT_WAIT_TEXT": "showcashoutwaittext",
    "SHOW_LOCK_ICON_ANIM": "showlockiconanim",
    "PLAY_EXPOSPIN_VID": "playExpoSpinVid",
    "REMOVE_EXPOSPIN_VID": "removeExpoSpinVid",
    "SHOW_POOL_PRIZE": "showPoolPrize",
    "SHOW_PLAYER_BALANCE": "showPlayerBalance",
    "HIDE_PLAYER_BALANCE": "hidePlayerBalance",
    "MUTE_EXPOSPIN_AUDIO": "muteExpoSpinAudio",
    "RESUME_EXPOSPIN_ANIME": "resumeExpoAnime",
    "UPADATEEXPOSPINBG": "updateExpoSpinBG",
    "PLAY_AUDIO_EXPO_SPIN": "playAudioExpoSpin",
    "PUASE_EXPO_SPIN_ANIME": "pauseExpoSpinAnime",
    "RATHOL_CANCEL": "ratholCloseTable",
    "RATHOL_BUYIN": "ratholBuyIn",
    "RATHOL_TIME_OUT": "ratholTimeOut",
    "OPEN_ATH_LEARN_MORE": "openATHLearnMore",
    "GET_PLAYER_STATUS": "getPlayerStatus",
    "DISABLE_SHOW_PLAYERPROFILE": "disableShowPlayerProfile",
    "ENABLE_SHOW_PLAYERPROFILE": "enableShowPlayerProfile"
}
