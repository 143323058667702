import { Message } from '../../message';

export class DealPlayerData extends Message {
    private MESSAGE_NAME: string = 'DealPlayerData';
    private seatNo: number = 0;
    private chipCount: number = 0;
    private gamePayoutinCents: number = 0;
    private gamePayoutInPercent: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNo: number = 0,
        chipCount: number = 0,
        gamePayoutinCents: number = 0,
        gamePayoutInPercent: number = 0,
    ) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.chipCount = chipCount;
        this.gamePayoutinCents = gamePayoutinCents;
        this.gamePayoutInPercent = gamePayoutInPercent;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getChipCount(): number {
        return this.chipCount;
    }

    setChipCount(chipCount: number) {
        this.chipCount = chipCount;
    }
    getGamePayoutinCents(): number {
        return this.gamePayoutinCents;
    }

    setGamePayoutinCents(gamePayoutinCents: number) {
        this.gamePayoutinCents = gamePayoutinCents;
    }
    getGamePayoutInPercent(): number {
        return this.gamePayoutInPercent;
    }

    setGamePayoutInPercent(gamePayoutInPercent: number) {
        this.gamePayoutInPercent = gamePayoutInPercent;
    }
}
