import { Message } from '../../message';

export class LSPlayerBalances extends Message {
    private MESSAGE_NAME: string = 'LSPlayerBalances';
    private playerBalances: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerBalances: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.playerBalances = playerBalances;
    }

    getPlayerBalances(): Map<any, any> | null {
        return this.playerBalances;
    }

    setPlayerBalances(playerBalances: Map<any, any> | null) {
        this.playerBalances = playerBalances;
    }
}
