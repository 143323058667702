import { Message } from '../../message';

export class GameCurrencyDetailsList extends Message {
    private MESSAGE_NAME: string = 'GameCurrencyDetailsList';
    private currenciesDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currenciesDetails: any[] | null = null) {
        super(reqServerPeerId);
        this.currenciesDetails = currenciesDetails;
    }

    getCurrenciesDetails(): any[] | null {
        return this.currenciesDetails;
    }

    setCurrenciesDetails(currenciesDetails: any[] | null) {
        this.currenciesDetails = currenciesDetails;
    }
}
