export class Timer {
    private TimePeriod: number;
    private TimerProc: () => void;
    private TimerId: any = null;

    constructor(timePeriod: number, timerProc: () => void) {
        this.TimePeriod = timePeriod;
        this.TimerProc = timerProc;
    }

    // Start Timer.
    public Start() {
        if (this.TimerId !== null) {
            clearInterval(this.TimerId);
        }

        this.TimerId = setInterval(this.TimerProc, this.TimePeriod);
    }

    // Stop Timer.
    public Stop() {
        if (this.TimerId !== null) {
            clearInterval(this.TimerId);
            this.TimerId = null;
        }
    }

    // IsActive
    public IsActive() {
        return this.TimerId !== null;
    }
}
