import { Message } from '../../message';

export class TournamentShowAddonPopup extends Message {
    private MESSAGE_NAME: string = 'TournamentShowAddonPopup';
    private buyIn: number = 0;
    private chipCount: number = 0;
    private accountBalance: number = 0;
    private dialogTime: number = 0;
    private fee: number = 0;
    private costIncludesFee: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        buyIn: number = 0,
        chipCount: number = 0,
        accountBalance: number = 0,
        dialogTime: number = 0,
        fee: number = 0,
        costIncludesFee: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.buyIn = buyIn;
        this.chipCount = chipCount;
        this.accountBalance = accountBalance;
        this.dialogTime = dialogTime;
        this.fee = fee;
        this.costIncludesFee = costIncludesFee;
    }

    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getChipCount(): number {
        return this.chipCount;
    }

    setChipCount(chipCount: number) {
        this.chipCount = chipCount;
    }
    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getDialogTime(): number {
        return this.dialogTime;
    }

    setDialogTime(dialogTime: number) {
        this.dialogTime = dialogTime;
    }
    getFee(): number {
        return this.fee;
    }

    setFee(fee: number) {
        this.fee = fee;
    }
    getCostIncludesFee(): boolean | null {
        return this.costIncludesFee;
    }

    setCostIncludesFee(costIncludesFee: boolean | null) {
        this.costIncludesFee = costIncludesFee;
    }
}
