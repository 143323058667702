// import  BIG_ENDIAN from "./byteOrder";
// import { ByteOrder } from "./byteOrder";
// export class ByteBuffer {
//     array: any[];
//     private _mark: number;
//     limit: number;
//     capacity: number;
//     order: any;
//     public  static instance: ByteBuffer;
//     constructor(_186?: any) {
//         this.array = _186 || [];
//         this._mark = -1;
//         this.limit = this.capacity = this.array.length;
//         this.order = BIG_ENDIAN.BIG_ENDIAN;
//     }
//     public static getInstance(): ByteBuffer {
//         if (!ByteBuffer.instance) {
//             ByteBuffer.instance = new ByteBuffer();
//         }
//         return ByteBuffer.instance;
//       }
//     allocate(_187: number): ByteBuffer {
//         const buf: ByteBuffer = new ByteBuffer();
//         buf.capacity = _187;
//         buf.limit = _187;
//         return buf;
//     }
//     wrap(_189: any): ByteBuffer {
//         return new ByteBuffer(_189);
//     }
//     autoExpand = true;
//     position = 0;
//     mark() {
//         this._mark = this.position;
//         return this;
//     }
//     getPosition() {
//         return this.position;
//     }
//     setPosition(position: number) {
//         this.position = position;
//     }
//     reset() {
//         const m = this._mark;
//         if (m < 0) {
//             throw new Error("Invalid mark");
//         }
//         this.position = m;
//         return this;
//     }
//     dispose() {
//         this.limit = undefined;
//         this.position = undefined;
//         this.capacity = undefined;
//         this.array.length = 0;
//         this.array = undefined;
//     }
//     compact() {
//         this.array.splice(0, this.position);
//         this.limit -= this.position;
//         this.position = 0;
//         return this;
//     }
//     duplicate() {
//         const buf: ByteBuffer = new ByteBuffer(this.array);
//         buf.position = this.position;
//         buf.limit = this.limit;
//         buf.capacity = this.capacity;
//         return buf;
//     }
//     fill(size: number) {
//         this._autoExpand(this, size);
//         while (size-- > 0) {
//             this.put(0);
//         }
//         return this;
//     }
//     fillWith(b: any, size: number) {
//         this._autoExpand(this, size);
//         while (size-- > 0) {
//             this.put(b);
//         }
//         return this;
//     }
//     indexOf(b: any) {
//         const _191 = this.limit;
//         const _192 = this.array;
//         for (let i = this.position; i < _191; i++) {
//             if (_192[i] == b) {
//                 return i;
//             }
//         }
//         return -1;
//     }
//     put(v: any) {
//         console.log("PPPPPPP");
//         this._autoExpand(this, 1);
//         this.putAt(this.position++, v);
//         return;
//     }
//     putAt(_195: number, v: any) {
//         this._checkForWriteAt(this, _195, 1);
//         this.array[_195] = this.order._toUnsignedByte(v);
//         return this;
//     }
//     putUnsigned(v: any) {
//         this._autoExpand(this, 1);
//         this.putUnsignedAt(this.position, v & 255);
//         this.position += 1;
//         return this;
//     }
//     putUnsignedAt(_198: number, v: any) {
//         this._checkForWriteAt(this, _198, 1);
//         this.putAt(_198, v & 255);
//         return this;
//     }
//     putShort(v: any) {
//         this._autoExpand(this, 2);
//         this.putShortAt(this.position, v);
//         this.position += 2;
//         return this;
//     }
//     putShortAt(_19b: number, v: any) {
//         this._checkForWriteAt(this, _19b, 2);
//         this.putBytesAt(_19b, this.order._fromShort(v));
//         return this;
//     }
//     putUnsignedShort(v: any) {
//         this._autoExpand(this, 2);
//         this.putUnsignedShortAt(this.position, v & 65535);
//         this.position += 2;
//         return this;
//     }
//     putUnsignedShortAt(_19e: number, v: any) {
//         this._checkForWriteAt(this, _19e, 2);
//         this.putShortAt(_19e, v & 65535);
//         return this;
//     }
//     putMediumInt(v: any) {
//         this._autoExpand(this, 3);
//         this.putMediumIntAt(this.position, v);
//         this.position += 3;
//         return this;
//     }
//     putMediumIntAt(_1a1: number, v: any) {
//         this.putBytesAt(_1a1, this.order._fromMediumInt(v));
//         return this;
//     }
//     putInt(v: any) {
//         this._autoExpand(this, 4);
//         this.putIntAt(this.position, v);
//         this.position += 4;
//         return this;
//     }
//     putIntAt(_1a4: number, v: any) {
//         this._checkForWriteAt(this, _1a4, 4);
//         this.putBytesAt(_1a4, this.order._fromInt(v));
//         return this;
//     }
//     putUnsignedInt(v: any) {
//         this._autoExpand(this, 4);
//         this.putUnsignedIntAt(this.position, v & 4294967295);
//         this.position += 4;
//         return this;
//     }
//     putUnsignedIntAt(_1a7: number, v: any) {
//         this._checkForWriteAt(this, _1a7, 4);
//         this.putIntAt(_1a7, v & 4294967295);
//         return this;
//     }
//     putString(v: any, cs: any) {
//         console.log("vvvv" + v);
//         cs.encode(v, this);
//         return this;
//     }
//     putPrefixedString(_1ab: number, v: string, cs: any) {
//         if (typeof cs === "undefined" || typeof cs.encode === "undefined") {
//             throw new Error("ByteBuffer.putPrefixedString: character set parameter missing");
//         }
//         if (_1ab === 0) {
//             return this;
//         }
//         this._autoExpand(this, _1ab);
//         const len = v.length;
//         switch (_1ab) {
//             case 1:
//                 this.put(len);
//                 break;
//             case 2:
//                 this.putShort(len);
//                 break;
//             case 4:
//                 this.putInt(len);
//                 break;
//         }
//         cs.encode(v, this);
//         return this;
//     }
//     putBytes(v: any) {
//         this._autoExpand(this, v.length);
//         this.putBytesAt(this.position, v);
//         this.position += v.length;
//         return this;
//     }
//     putBytesAt(_1b0: number, v: any) {
//         this._checkForWriteAt(this, _1b0, v.length);
//         for (let j = 0, k = _1b0, len = v.length; j < len; j++, k++) {
//             this.putAt(k, v[j]);
//         }
//         return this;
//     }
//     putBuffer(v: any) {
//         this.putBytes(v.array.slice(v.position, v.limit));
//         return this;
//     }
//     putBufferAt(_1b6: number, v: any) {
//         this.putBytesAt(_1b6, v.array.slice(v.position, v.limit));
//         return this;
//     }
//     get() {
//         this._checkForRead(this, 1);
//         return this.getAt(this.position++);
//     }
//     getAt(_1b8: number) {
//         this._checkForReadAt(this, _1b8, 1);
//         return this.order._toByte(this.array[_1b8]);
//     }
//     getUnsigned() {
//         this._checkForRead(this, 1);
//         const val = this.getUnsignedAt(this.position);
//         this.position += 1;
//         return val;
//     }
//     getUnsignedAt(_1ba: number) {
//         this._checkForReadAt(this, _1ba, 1);
//         return this.order._toUnsignedByte(this.array[_1ba]);
//     }
//     getBytes(size: number) {
//         this._checkForRead(this, size);
//         const _1bc: any[] = [];
//         for (let i = 0; i < size; i++) {
//             _1bc.push(this.order._toByte(this.array[i + this.position]));
//         }
//         this.position += size;
//         return _1bc;
//     }
//     getBytesAt(_1be: number, size: number) {
//         this._checkForReadAt(this, _1be, size);
//         const _1c0: any[] = [];
//         this.position = _1be;
//         for (let i = 0; i < size; i++) {
//             _1c0.push(this.order._toByte(this.array[i + this.position]));
//         }
//         this.position += size;
//         return _1c0;
//     }
//     getShort() {
//         this._checkForRead(this, 2);
//         const val = this.getShortAt(this.position);
//         this.position += 2;
//         return val;
//     }
//     getShortAt(_1c3: number) {
//         this._checkForReadAt(this, _1c3, 2);
//         const _1c4 = this.array;
//         return this.order._toShort(_1c4[_1c3++], _1c4[_1c3++]);
//     }
//     getUnsignedShort() {
//         this._checkForRead(this, 2);
//         const val = this.getUnsignedShortAt(this.position);
//         this.position += 2;
//         return val;
//     }
//     getUnsignedShortAt(_1c6: number) {
//         this._checkForReadAt(this, _1c6, 2);
//         const _1c7 = this.array;
//         return this.order._toUnsignedShort(_1c7[_1c6++], _1c7[_1c6++]);
//     }
//     getUnsignedMediumInt() {
//         const _1c8 = this.array;
//         return this.order._toUnsignedMediumInt(_1c8[this.position++], _1c8[this.position++], _1c8[this.position++]);
//     }
//     getMediumInt() {
//         const val = this.getMediumIntAt(this.position);
//         this.position += 3;
//         return val;
//     }
//     getMediumIntAt(i: number) {
//         const _1cb = this.array;
//         return this.order._toMediumInt(_1cb[i++], _1cb[i++], _1cb[i++]);
//     }
//     getInt() {
//         this._checkForRead(this, 4);
//         const val = this.getIntAt(this.position);
//         this.position += 4;
//         return val;
//     }
//     getIntAt(_1cd: number) {
//         this._checkForReadAt(this, _1cd, 4);
//         const _1ce = this.array;
//         return this.order._toInt(_1ce[_1cd++], _1ce[_1cd++], _1ce[_1cd++], _1ce[_1cd++]);
//     }
//     getUnsignedInt() {
//         this._checkForRead(this, 4);
//         const val = this.getUnsignedIntAt(this.position);
//         this.position += 4;
//         return val;
//     }
//     getUnsignedIntAt(_1d0: number) {
//         this._checkForReadAt(this, _1d0, 4);
//         const _1d1 = this.array;
//         return this.order._toUnsignedInt(_1d1[_1d0++], _1d1[_1d0++], _1d1[_1d0++], _1d1[_1d0++]);
//     }
//     getPrefixedString(_1d2: number, cs: any) {
//         let len = 0;
//         switch (_1d2 || 2) {
//             case 1:
//                 len = this.getUnsigned();
//                 break;
//             case 2:
//                 len = this.getUnsignedShort();
//                 break;
//             case 4:
//                 len = this.getInt();
//                 break;
//         }
//         if (len === 0) {
//             return "";
//         }
//         const _1d5 = this.limit;
//         try {
//             this.limit = this.position + len;
//             return cs.decode(this);
//         } finally {
//             this.limit = _1d5;
//         }
//     }
//     getString(cs: any) {
//         let _1d7 = this.position;
//         const _1d8 = this.limit;
//         const _1d9 = this.array;
//         while (_1d7 < _1d8 && _1d9[_1d7] !== 0) {
//             _1d7++;
//         }
//         try {
//             this.limit = _1d7;
//             return cs.decode(this);
//         } finally {
//             if (_1d7 !== _1d8) {
//                 this.limit = _1d8;
//                 this.position = _1d7 + 1;
//             }
//         }
//     }
//     getBytesRemaining() {
//         return this.limit - this.position;
//     }
//     flip() {
//         this.limit = this.position;
//         this.position = 0;
//         this._mark = -1;
//         return this;
//     }
//     clear() {
//         this.position = 0;
//         this.limit = this.capacity;
//         this._mark = -1;
//         return this;
//     }
//     remaining(): number {
//         return this.limit - this.position;
//       }
//       hasRemaining(): boolean {
//         return this.limit > this.position;
//       }
//       skip(size: number): ByteBuffer {
//         this.position += size;
//         return this;
//       }
//       getHexDump(): string {
//         const array = this.array;
//         const pos = this.position;
//         const limit = this.limit;
//         if (pos === limit) {
//           return "empty";
//         }
//         const hexArray = [];
//         for (let i = pos; i < limit; i++) {
//           let hex = (array[i] || 0).toString(16);
//           if (hex.length === 1) {
//             hex = "0" + hex;
//           }
//           hexArray.push(hex);
//         }
//         return hexArray.join(" ");
//       }
//       toString(): string {
//         return this.getHexDump();
//       }
//       expand(_1e1: number): ByteBuffer {
//         return this.expandAt(this.position, _1e1);
//       }
//       expandAt(i: number, _1e3: number): ByteBuffer {
//         const end = i + _1e3;
//         if (end > this.capacity) {
//           this.capacity = end;
//         }
//         if (end > this.limit) {
//           this.limit = end;
//         }
//         return this;
//       }
//     rewind() {
//         this.position = 0;
//         this._mark = -1;
//         return this;
//     }
//     slice() {
//         const _1e3 = new ByteBuffer(this.array.slice(this.position, this.limit));
//         _1e3.array = this.array;
//         _1e3.position = this.position;
//         _1e3.limit = this.limit;
//         return _1e3;
//     }
//     asUint8Array() {
//         return this.array.slice(this.position, this.limit);
//     }
//     _autoExpand(_1e5, _1e6) {
//         if (_1e5.autoExpand) {
//             console.log("LLLL");
//             _1e5.expand(_1e6);
//         }
//         return _1e5;
//     }
//     _checkForWrite(_1e8: any, needed: number) {
//         if (_1e8.position < 0 || needed < 0 || _1e8.position + needed > _1e8.limit) {
//             throw new Error("ByteBuffer.put: position out of bounds");
//         }
//     }
//     _checkForWriteAt(_1ee, _1ef, _1f0) {
//         var end = _1ef + _1f0;
//         if (_1ef < 0 || end > _1ee.limit) {
//             throw new Error("Index out of bounds");
//         }
//         return _1ee;
//     }
//      _checkForRead(_1e7, _1e8) {
//         var end = _1e7.position + _1e8;
//         if (end > _1e7.limit) {
//             throw new Error("Buffer underflow");
//         }
//         return _1e7;
//     }
//     _checkForReadAt(_1ea, _1eb, _1ec) {
//         var end = _1eb + _1ec;
//         if (_1eb < 0 || end > _1ea.limit) {
//             throw new Error("Index out of bounds");
//         }
//         return _1ea;
//     }
// }
import { ByteOrder } from './byteOrder';

export class ByteBuffer {
    array: any;
    _mark: any;
    limit: any;
    capacity: any;
    position: any;
    order: any;

    constructor(_186?: any) {
        this.array = _186 || [];
        this._mark = -1;
        this.limit = this.capacity = this.array.length;
        this.order = ByteOrder.BIG_ENDIAN;
        this.position = 0;
    }

    static allocate(_187: number): ByteBuffer {
        const buf = new ByteBuffer();
        buf.capacity = _187;
        buf.limit = _187;
        return buf;
    }

    static wrap(_189: number[]): ByteBuffer {
        return new ByteBuffer(_189);
    }

    autoExpand: boolean = true;

    mark(): this {
        this._mark = this.position;
        return this;
    }

    getPosition(): number {
        return this.position;
    }

    setPosition(position: number): void {
        this.position = position;
    }

    reset(): this {
        const m = this._mark;
        if (m < 0) {
            throw new Error('Invalid mark');
        }
        this.position = m;
        return this;
    }

    dispose(): void {
        this.limit = undefined;
        this.position = undefined;
        this.capacity = undefined;
        this.array.length = 0;
        this.array = undefined;
    }

    compact(): this {
        this.array.splice(0, this.position);
        this.limit -= this.position;
        this.position = 0;
        return this;
    }

    duplicate(): ByteBuffer {
        const buf = new ByteBuffer(this.array);
        buf.position = this.position;
        buf.limit = this.limit;
        buf.capacity = this.capacity;
        return buf;
    }

    fill(size: number): this {
        _autoExpand(this, size);
        while (size-- > 0) {
            this.put(0);
        }
        return this;
    }

    fillWith(b: number, size: number): this {
        _autoExpand(this, size);
        while (size-- > 0) {
            this.put(b);
        }
        return this;
    }

    indexOf(b: number): number {
        const _191 = this.limit;
        const _192 = this.array;
        for (let i = this.position; i < _191; i++) {
            if (_192[i] === b) {
                return i;
            }
        }
        return -1;
    }

    put(v: number): this {
        _autoExpand(this, 1);
        this.putAt(this.position++, v);
        return this;
    }

    putAt(_195: number, v: number): this {
        _checkForWriteAt(this, _195, 1);
        this.array[_195] = this.order._toUnsignedByte(v);
        return this;
    }

    putUnsigned(v: number): this {
        _autoExpand(this, 1);
        this.putUnsignedAt(this.position, v & 255);
        this.position += 1;
        return this;
    }

    putUnsignedAt(_198: number, v: number): this {
        _checkForWriteAt(this, _198, 1);
        this.putAt(_198, v & 255);
        return this;
    }

    putShort(v: number): this {
        _autoExpand(this, 2);
        this.putShortAt(this.position, v);
        this.position += 2;
        return this;
    }

    putShortAt(_19b: number, v: number): this {
        _checkForWriteAt(this, _19b, 2);
        this.putBytesAt(_19b, this.order._fromShort(v));
        return this;
    }

    putUnsignedShort(v: number): this {
        _autoExpand(this, 2);
        this.putUnsignedShortAt(this.position, v & 65535);
        this.position += 2;
        return this;
    }

    putUnsignedShortAt(_19e: number, v: number): this {
        _checkForWriteAt(this, _19e, 2);
        this.putShortAt(_19e, v & 65535);
        return this;
    }

    putMediumInt(v: number): this {
        _autoExpand(this, 3);
        this.putMediumIntAt(this.position, v);
        this.position += 3;
        return this;
    }

    putMediumIntAt(_1a1: number, v: number): this {
        this.putBytesAt(_1a1, this.order._fromMediumInt(v));
        return this;
    }

    putInt(v: number): this {
        _autoExpand(this, 4);
        this.putIntAt(this.position, v);
        this.position += 4;
        return this;
    }

    putIntAt(_1a4: number, v: number): this {
        _checkForWriteAt(this, _1a4, 4);
        this.putBytesAt(_1a4, this.order._fromInt(v));
        return this;
    }

    putUnsignedInt(v: number): this {
        _autoExpand(this, 4);
        this.putUnsignedIntAt(this.position, v & 4294967295);
        this.position += 4;
        return this;
    }

    putUnsignedIntAt(_1a7: number, v: number): this {
        _checkForWriteAt(this, _1a7, 4);
        this.putIntAt(_1a7, v & 4294967295);
        return this;
    }

    putString(v: string, cs: any): this {
        cs.encode(v, this);
        return this;
    }

    putPrefixedString(_1ab: number, v: string, cs: any): this {
        if (typeof cs === 'undefined' || typeof cs.encode === 'undefined') {
            throw new Error('ByteBuffer.putPrefixedString: character set parameter missing');
        }
        if (_1ab === 0) {
            return this;
        }
        _autoExpand(this, _1ab);
        const len = v.length;
        switch (_1ab) {
            case 1:
                this.put(len);
                break;
            case 2:
                this.putShort(len);
                break;
            case 4:
                this.putInt(len);
                break;
        }
        cs.encode(v, this);
        return this;
    }

    putBytes(v: any) {
        _autoExpand(this, v.length);
        this.putBytesAt(this.position, v);
        this.position += v.length;
        return this;
    }

    putBytesAt(_1b0: number, v: number[]): this {
        _checkForWriteAt(this, _1b0, v.length);
        for (let j = 0, k = _1b0, len = v.length; j < len; j++, k++) {
            this.putAt(k, v[j]);
        }
        return this;
    }

    putBuffer(v: ByteBuffer): this {
        this.putBytes(v.array.slice(v.position, v.limit));
        return this;
    }

    putBufferAt(_1b6: number, v: ByteBuffer): this {
        this.putBytesAt(_1b6, v.array.slice(v.position, v.limit));
        return this;
    }

    get(): number {
        _checkForRead(this, 1);
        return this.getAt(this.position++);
    }

    getAt(_1b8: number): number {
        _checkForReadAt(this, _1b8, 1);
        return this.order._toByte(this.array[_1b8]);
    }

    getUnsigned(): number {
        _checkForRead(this, 1);
        const val = this.getUnsignedAt(this.position);
        this.position += 1;
        return val;
    }

    getUnsignedAt(_1ba: number): number {
        _checkForReadAt(this, _1ba, 1);
        return this.order._toUnsignedByte(this.array[_1ba]);
    }

    getBytes(size: number): number[] {
        _checkForRead(this, size);
        const _1bc: number[] = [];
        for (let i = 0; i < size; i++) {
            _1bc.push(this.order._toByte(this.array[i + this.position]));
        }
        this.position += size;
        return _1bc;
    }

    getBytesAt(_1be: number, size: number): number[] {
        _checkForReadAt(this, _1be, size);
        const _1c0: number[] = [];
        this.position = _1be;
        for (let i = 0; i < size; i++) {
            _1c0.push(this.order._toByte(this.array[i + this.position]));
        }
        this.position += size;
        return _1c0;
    }

    getShort(): number {
        _checkForRead(this, 2);
        const val = this.getShortAt(this.position);
        this.position += 2;
        return val;
    }

    getShortAt(_1c3: number): number {
        _checkForReadAt(this, _1c3, 2);
        const _1c4 = this.array;
        return this.order._toShort(_1c4[_1c3++], _1c4[_1c3++]);
    }

    getUnsignedShort(): number {
        _checkForRead(this, 2);
        const val = this.getUnsignedShortAt(this.position);
        this.position += 2;
        return val;
    }

    getUnsignedShortAt(_1c6: number): number {
        _checkForReadAt(this, _1c6, 2);
        const _1c7 = this.array;
        return this.order._toUnsignedShort(_1c7[_1c6++], _1c7[_1c6++]);
    }

    getUnsignedMediumInt(): number {
        const _1c8 = this.array;
        return this.order._toUnsignedMediumInt(_1c8[this.position++], _1c8[this.position++], _1c8[this.position++]);
    }

    getMediumInt(): number {
        const val = this.getMediumIntAt(this.position);
        this.position += 3;
        return val;
    }

    getMediumIntAt(i: number): number {
        const _1cb = this.array;
        return this.order._toMediumInt(_1cb[i++], _1cb[i++], _1cb[i++]);
    }

    getInt(): number {
        _checkForRead(this, 4);
        const val = this.getIntAt(this.position);
        this.position += 4;
        return val;
    }

    getIntAt(_1cd: number): number {
        _checkForReadAt(this, _1cd, 4);
        const _1ce = this.array;
        return this.order._toInt(_1ce[_1cd++], _1ce[_1cd++], _1ce[_1cd++], _1ce[_1cd++]);
    }

    getUnsignedInt(): number {
        _checkForRead(this, 4);
        const val = this.getUnsignedIntAt(this.position);
        this.position += 4;
        return val;
    }

    getUnsignedIntAt(_1d0: number): number {
        _checkForReadAt(this, _1d0, 4);
        const _1d1 = this.array;
        return this.order._toUnsignedInt(_1d1[_1d0++], _1d1[_1d0++], _1d1[_1d0++], _1d1[_1d0++]);
        //   return val;
    }

    getPrefixedString(_1d2?: number, cs?: any): string {
        let len = 0;
        switch (_1d2 || 2) {
            case 1:
                len = this.getUnsigned();
                break;
            case 2:
                len = this.getUnsignedShort();
                break;
            case 4:
                len = this.getInt();
                break;
        }
        if (len === 0) {
            return '';
        }
        const _1d5 = this.limit;
        try {
            this.limit = this.position + len;
            return cs.decode(this);
        } finally {
            this.limit = _1d5;
        }
    }

    getString(cs: any): string {
        let _1d7 = this.position;
        const _1d8 = this.limit;
        const _1d9 = this.array;
        while (_1d7 < _1d8 && _1d9[_1d7] !== 0) {
            _1d7++;
        }
        try {
            this.limit = _1d7;
            return cs.decode(this);
        } finally {
            if (_1d7 != _1d8) {
                this.limit = _1d8;
                this.position = _1d7 + 1;
            }
        }
    }

    slice(): ByteBuffer {
        return new ByteBuffer(this.array.slice(this.position, this.limit));
    }

    flip(): this {
        this.limit = this.position;
        this.position = 0;
        this._mark = -1;
        return this;
    }

    rewind(): this {
        this.position = 0;
        this._mark = -1;
        return this;
    }

    clear(): this {
        this.position = 0;
        this.limit = this.capacity;
        this._mark = -1;
        return this;
    }

    remaining(): number {
        return this.limit - this.position;
    }

    hasRemaining(): boolean {
        return this.limit > this.position;
    }

    skip(size: number): this {
        this.position += size;
        return this;
    }

    getHexDump(): string {
        const _1db = this.array;
        const pos = this.position;
        const _1dd = this.limit;
        if (pos == _1dd) {
            return 'empty';
        }
        const _1de: string[] = [];
        for (let i = pos; i < _1dd; i++) {
            let hex = (_1db[i] || 0).toString(16);
            if (hex.length == 1) {
                hex = '0' + hex;
            }
            _1de.push(hex);
        }
        return _1de.join(' ');
    }

    toString(): string {
        return this.getHexDump();
    }

    expand(_1e1: number): this {
        return this.expandAt(this.position, _1e1);
    }

    expandAt(i: number, _1e3: number): this {
        const end = i + _1e3;
        if (end > this.capacity) {
            this.capacity = end;
        }
        if (end > this.limit) {
            this.limit = end;
        }
        return this;
    }
}

function _autoExpand(_1e5: ByteBuffer, _1e6: number): ByteBuffer {
    if (_1e5.autoExpand) {
        _1e5.expand(_1e6);
    }
    return _1e5;
}

function _checkForRead(_1e7: ByteBuffer, _1e8: number): ByteBuffer {
    const end = _1e7.position + _1e8;
    if (end > _1e7.limit) {
        throw new Error('Buffer underflow');
    }
    return _1e7;
}

function _checkForReadAt(_1ea: ByteBuffer, _1eb: number, _1ec: number): ByteBuffer {
    const end = _1eb + _1ec;
    if (_1eb < 0 || end > _1ea.limit) {
        throw new Error('Index out of bounds');
    }
    return _1ea;
}

function _checkForWriteAt(_1ee: ByteBuffer, _1ef: number, _1f0: number): ByteBuffer {
    const end = _1ef + _1f0;
    if (_1ef < 0 || end > _1ee.limit) {
        throw new Error('Index out of bounds');
    }
    return _1ee;
}
