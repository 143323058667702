import { Message } from '../../message';

export class SNGPromoUpdate extends Message {
    private MESSAGE_NAME: string = 'SNGPromoUpdate';
    private promoAvailable: boolean | null = false;
    private promoId: number = 0;
    private timeStamp: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, promoAvailable: boolean | null = false, promoId: number = 0, timeStamp: number = 0) {
        super(reqServerPeerId);
        this.promoAvailable = promoAvailable;
        this.promoId = promoId;
        this.timeStamp = timeStamp;
    }

    getPromoAvailable(): boolean | null {
        return this.promoAvailable;
    }

    setPromoAvailable(promoAvailable: boolean | null) {
        this.promoAvailable = promoAvailable;
    }
    getPromoId(): number {
        return this.promoId;
    }

    setPromoId(promoId: number) {
        this.promoId = promoId;
    }
    getTimeStamp(): number {
        return this.timeStamp;
    }

    setTimeStamp(timeStamp: number) {
        this.timeStamp = timeStamp;
    }
}
