import { Message } from '../../message';

export class HandStrength extends Message {
    private MESSAGE_NAME: string = 'HandStrength';
    private handDescription: any | null = null;
    private isHigh: boolean | null = false;
    private boardType: number = 0;
    private cards: string | null = null;
    private rank: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        handDescription: any | null = null,
        isHigh: boolean | null = false,
        boardType: number = 0,
        cards: string | null = null,
        rank: number = 0,
    ) {
        super(reqServerPeerId);
        this.handDescription = handDescription;
        this.isHigh = isHigh;
        this.boardType = boardType;
        this.cards = cards;
        this.rank = rank;
    }

    getHandDescription(): any | null {
        return this.handDescription;
    }

    setHandDescription(handDescription: any | null) {
        this.handDescription = handDescription;
    }
    getIsHigh(): boolean | null {
        return this.isHigh;
    }

    setIsHigh(isHigh: boolean | null) {
        this.isHigh = isHigh;
    }
    getBoardType(): number {
        return this.boardType;
    }

    setBoardType(boardType: number) {
        this.boardType = boardType;
    }
    getCards(): string | null {
        return this.cards;
    }

    setCards(cards: string | null) {
        this.cards = cards;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
}
