<div class="row d-flex align-items-center" [class.h-100]="data?.gameType === 'spins-overdrive'">
    <div class="col-4">
        <button class="btn btn-link btn-link-dark pwc-btn-back btn-sm p-0" (click)="goBack(data?.url)">
            <span class="theme-left"></span>
        </button>
    </div>
    <div class="col-4" [class.h-100]="data?.gameType === 'spins-overdrive'">
        <div
            class="pwc-screen-name h-100"
            *ngIf="data?.gameType === 'spins-overdrive'"
            [ngStyle]="{ 'background-image': 'url(' + data?.image + ')' }"></div>
        <div class="pwc-screen-name" *ngIf="data?.gameType !== 'spins-overdrive'">{{ data?.title }}</div>
    </div>
    <div class="col-4 p-0">
        <pwc-client-header-balance *ngIf="data?.showBalanceBlock && userIsAuthenticated"></pwc-client-header-balance>
    </div>
</div>
