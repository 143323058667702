<div class="popup-backdrop" (click)="closePopup()"></div>
<div class="cash-ff-buyIn-container" #popupContent>
    <span class="close theme-ex" (click)="closePopup()"></span>
    <div class="buyIn-header mb-4">
        <div class="fflogo-flex mx-4" *ngIf="showInfoAlert">
            <div class="message-panel">
                <div class="theme-info-i">
                    <div class="cms-container">
                        <span>{{ showInfoAlertText }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="buyIn-title" *ngIf="showTitle">{{ buyInTitle }}</div>
        <div class="buyIn-subTitle mb-2">
            {{ buyInLimitTypeAndGameType }}
            <div>
                <ng-container *ngIf="table['MESSAGE_NAME'] === 'PoolAvailabilityResponse'; else elseTemplate">
                    {{ getBlindsLabel(table) }}:
                </ng-container>
                <ng-template #elseTemplate>{{ 'PARTY_POKER_GC_QSLOBBY_QUERY_BLINDS' | translate }}: </ng-template>
                {{ getBlinds(table) }}
                <ng-container *ngIf="table.anteCashAmount > 0">
                    | {{ 'PARTY_POKER_GC_QSLOBBY_ANTE' | translate }}: ${{ table.anteCashAmount }} {{ table.gameCurrency }}
                </ng-container>
            </div>
        </div>
        <div class="auto-rebuy" *ngIf="!isAutorebuyin">
            <div class="form-control-us-switcher d-flex justify-content-between align-items-center">
                <div class="d-flex us-switcher-label">
                    <span class="us-switcher-label-text">
                        {{ 'PARTY_POKER_GC_GAMETABLE_AUTOREBUYTOMAX' | translate }}
                    </span>
                    <span class="theme-info d-flex align-items-center autoReBuyInfo" (click)="showAutoReBuyInfo($event)">
                        <div [class.d-none]="!autoReBuyInfoPopUp" class="message-panel">
                            <!-- <span class="close theme-ex autoReBuyInfo-close" (click)="hideAutoReBuyInfo()"></span> -->
                            <div class="msg theme-info-i">
                                <div class="cms-container">
                                    {{ 'PARTY_POKER_MOBILE_Fast Forward_AUTOREBUYINFO' | translate }}
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="switcher-lg">
                    <label class="custom-control custom-checkbox custom-control-switcher">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="isAutoBuyInTrue" />
                        <span class="custom-control-switcher-lever"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="auto-close-preogressBar" *ngIf="!isAutorebuyin">
            <div class="background-bar" style="width: 100%">
                <div class="progress bg-primary" [style.width.%]="buyInProgressBarWidth"></div>
            </div>
            This seat is reserved for {{ durationTimerLeftInSec }} seconds.
        </div>
    </div>
    <div class="buyIn-body" [ngClass]="isAutorebuyin === true ? 'my-5' : 'my-3'">
        <div *ngIf="showBuyInRange" class="px-3 buyIn_input_container d-flex justify-content-between align-items-center">
            <div class="buyIn_input_box" [ngClass]="{ active: isAutorebuyin ? selectedBuyIn === textSB : selectedBuyIn === sliderMinValue }">
                {{ (isAutorebuyin ? textSB : sliderMinValue) * 100 | formatCurrencyPipe: 'USD' }}
                <br />
                <span *ngIf="accountCurrency !== 'USD'">({{ sliderMinValue * conversionFactor * 100 | formatCurrencyPipe: accountCurrency }})</span>
            </div>
            <div class="buyIn_input_range">
                <input
                    id="range"
                    type="range"
                    step="0.01"
                    [value]="selectedBuyIn"
                    (input)="setValue($event.target.value)"
                    [min]="isAutorebuyin ? textSB : sliderMinValue"
                    [max]="isAutorebuyin ? sliderMax : sliderMaxValue"
                    [(ngModel)]="selectedBuyIn" />
                <div id="tooltip" class="buyin_tooltip"></div>
            </div>
            <div class="buyIn_input_box" [ngClass]="{ active: isAutorebuyin ? selectedBuyIn === sliderMax : selectedBuyIn === sliderMaxValue }">
                {{ (isAutorebuyin ? sliderMax : sliderMaxValue) * 100 | formatCurrencyPipe: 'USD' }}
                <br />
                <span *ngIf="accountCurrency !== 'USD'">({{ sliderMaxValue * conversionFactor * 100 | formatCurrencyPipe: accountCurrency }})</span>
            </div>
        </div>
    </div>
    <div class="buyIn-footer my-3 mt-5">
        <div class="form-control-us-switcher d-flex justify-content-between align-items-center buyIn-balance-container mb-3 px-3">
            <div class="d-flex us-switcher-label">
                <span class="us-switcher-label-text">{{ 'PARTY_POKER_GC_ONECLICK_AVAILABLEBALANCE' | translate }}</span>
            </div>
            <div class="d-flex us-switcher-label">
                <span class="us-switcher-label-text mr-1 font-weight-bold balance-info-popup">
                    {{ (userBalance !== undefined ? userBalance : 0) | formatCurrencyPipe: 'USD' }}</span
                >
                <div class="d-flex align-items-center">
                    <span id="infoBtn" class="theme-info-i" (click)="openBalanceInfoPopup($event)"></span>
                    <pwc-app-p-dollar-info-pop-up
                        class="p-dollar-container"
                        *ngIf="isBalanceInfoEnabled === true"
                        (closeBalanceInfo)="closeBalanceInfoPopup()"></pwc-app-p-dollar-info-pop-up>
                </div>
            </div>
        </div>
        <div class="choose-no-of-seats mb-3 px-3">
            <div class="mb-3" *ngIf="table.maxNoOfSeats !== 2 && !isAutorebuyin">
                {{ 'PARTY_POKER_MOBILE_Fast Forward_CHOOSENUMBER' | translate }}
            </div>
            <div *ngIf="table.maxNoOfSeats !== 2 && !isAutorebuyin" class="d-flex justify-content-between align-items-center circle_seats m-3">
                <ng-container *ngFor="let count of [1, 2, 3, 4]; let i = index">
                    <button [ngClass]="count === gameCount ? 'circle_buttons active_button' : 'circle_buttons'" (click)="updateGameCount(count)">
                        {{ count }}
                    </button>
                </ng-container>
            </div>
            <div class="mx-1">
                <button *ngIf="userBalance / 100 >= selectedBuyIn * gameCount" class="btn btn-md btn-primary w-100" (click)="loadGame()">
                    {{ selectedBuyIn * gameCount * 100 | formatCurrencyPipe: 'USD' }} {{ table.gameCurrency }}
                    <span *ngIf="accountCurrency !== 'USD'"
                        >({{ selectedBuyIn * gameCount * conversionFactor * 100 | formatCurrencyPipe: accountCurrency }})</span
                    >
                </button>
                <button *ngIf="selectedBuyIn * gameCount > userBalance / 100" class="btn btn-md btn-primary w-100" (click)="goToCashier()">
                    {{ 'PARTY_POKER_AN_GEN_ANMISSING6_MainMenuCashierBtn' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
