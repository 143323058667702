// class ByteOrder {
//     constructor () {

//     }
//     toString(): string {
//         throw new Error("Abstract");
//     }

//     _toUnsignedByte(v: number): number {
//         return (v & 255);
//     }

//      _toByte(v: number): number {
//         return (v & 128) ? (v | -256) : v;
//     }

//      _fromShort(v: number): number[] {
//         return [(v >> 8) & 255, v & 255];
//     }

//      _toShort(_149: number, _14e: number): number {
//         return (this._toByte(_149) << 8) | (_14e & 255);
//     }

//      _toUnsignedShort(_150: number, _151: number): number {
//         return ((_150 & 255) << 8) | (_151 & 255);
//     }

//      _toUnsignedMediumInt(_153: number, _154: number, _155: number): number {
//         return ((_153 & 255) << 16) | ((_154 & 255) << 8) | (_155 & 255);
//     }

//      _fromMediumInt(v: number): number[] {
//         return [(v >> 16) & 255, (v >> 8) & 255, v & 255];
//     }

//      _toMediumInt(_159: number, _15a: number, _15b: number): number {
//         return ((_159 & 255) << 16) | ((_15a & 255) << 8) | (_15b & 255);
//     }

//      _fromInt(v: number): number[] {
//         return [(v >> 24) & 255, (v >> 16) & 255, (v >> 8) & 255, v & 255];
//     }

//      _toInt(_15f: number, _160: number, _161: number, _162: number): number {
//         return (this._toByte(_15f) << 24) | ((_160 & 255) << 16) | ((_161 & 255) << 8) | (_162 & 255);
//     }

//      _toUnsignedInt(_164: number, _165: number, _166: number, _167: number): number {
//         const _168 = this._toUnsignedShort(_164, _165);
//         const _169 = this._toUnsignedShort(_166, _167);
//         return (_168 * 65536 + _169);
//     }
// }

// const BIG_ENDIAN = (() => {
//     const _16a = new ByteOrder();
//     console.log("lllllll");
//     const _16b = _16a;
//     _16b._toUnsignedByte = _16a._toUnsignedByte;
//     _16b._toByte = _16a._toByte;
//     _16b._fromShort = _16a._fromShort;
//     _16b._toShort = _16a._toShort;
//     _16b._toUnsignedShort = _16a._toUnsignedShort;
//     _16b._toUnsignedMediumInt = _16a._toUnsignedMediumInt;
//     _16b._fromMediumInt = _16a._fromMediumInt;
//     _16b._toMediumInt = _16a._toMediumInt;
//     _16b._fromInt = _16a._fromInt;
//     _16b._toInt = _16a._toInt;
//     _16b._toUnsignedInt = _16a._toUnsignedInt;
//     _16b.toString = function () {
//         return "<ByteOrder.BIG_ENDIAN>";
//     };
//     return _16a;
// })();

// const LITTLE_ENDIAN = (() => {
//     const _16c = new ByteOrder();
//     const _16d = _16c;
//     _16d._toByte = _16d._toByte;
//     _16d._toUnsignedByte = _16d._toUnsignedByte;
//     _16d._fromShort = function (v: number) {
//         return _16d._fromShort(v).reverse();
//     };
//     _16d._toShort = function (_16f: number, _170: number) {
//         return _16d._toShort(_170, _16f);
//     };
//     _16d._toUnsignedShort = function (_171: number, _172: number) {
//         return _16d._toUnsignedShort(_172, _171);
//     };
//     _16d._toUnsignedMediumInt = function (_173: number, _174: number, _175: number) {
//         return _16d._toUnsignedMediumInt(_175, _174, _173);
//     };
//     _16d._fromMediumInt = function (v: number) {
//         return _16d._fromMediumInt(v).reverse();
//     };
//     _16d._toMediumInt = function (_177: number, _178: number, _179: number, _17a?: number, _17b?: number, _17c?: number) {
//         return _16d._toMediumInt(_17c, _17b, _17a);
//     };
//     _16d._fromInt = function (v: number) {
//         return _16d._fromInt(v).reverse();
//     };
//     _16d._toInt = function (_17e: number, _17f: number, _180: number, _181: number) {
//         return _16d._toInt(_181, _180, _17f, _17e);
//     };
//     _16d._toUnsignedInt = function (_182: number, _183: number, _184: number, _185: number) {
//         return _16d._toUnsignedInt(_185, _184, _183, _182);
//     };
//     _16d.toString = function () {
//         return "<ByteOrder.LITTLE_ENDIAN>";
//     };
//     return  _16c;
// })();

// export default {BIG_ENDIAN, ByteOrder, LITTLE_ENDIAN}

export abstract class ByteOrder {
    abstract _toUnsignedByte(v: number): number;
    abstract _toByte(v: number): number;
    abstract _fromShort(v: number): number[];
    abstract _toShort(v: number, w: number): number;
    abstract _toUnsignedShort(v: number, w: number): number;
    abstract _toUnsignedMediumInt(v: number, w: number, x: number): number;
    abstract _fromMediumInt(v: number): number[];
    abstract _toMediumInt(v: number, w: number, x: number): number;
    abstract _fromInt(v: number): number[];
    abstract _toInt(v: number, w: number, x: number, y: number): number;
    abstract _toUnsignedInt(v: number, w: number, x: number, y: number): number;
    abstract toString(): string;

    static BIG_ENDIAN: ByteOrder = new (class extends ByteOrder {
        _toUnsignedByte(v: number): number {
            return v & 255;
        }

        _toByte(v: number): number {
            return v & 128 ? v | -256 : v;
        }

        _fromShort(v: number): number[] {
            return [(v >> 8) & 255, v & 255];
        }

        _toShort(v: number, w: number): number {
            return (this._toByte(v) << 8) | (w & 255);
        }

        _toUnsignedShort(v: number, w: number): number {
            return ((v & 255) << 8) | (w & 255);
        }

        _toUnsignedMediumInt(v: number, w: number, x: number): number {
            return ((v & 255) << 16) | ((w & 255) << 8) | (x & 255);
        }

        _fromMediumInt(v: number): number[] {
            return [(v >> 16) & 255, (v >> 8) & 255, v & 255];
        }

        _toMediumInt(v: number, w: number, x: number): number {
            return ((v & 255) << 16) | ((w & 255) << 8) | (x & 255);
        }

        _fromInt(v: number): number[] {
            return [(v >> 24) & 255, (v >> 16) & 255, (v >> 8) & 255, v & 255];
        }

        _toInt(v: number, w: number, x: number, y: number): number {
            return (this._toByte(v) << 24) | ((w & 255) << 16) | ((x & 255) << 8) | (y & 255);
        }

        _f(_150, _151) {
            return ((_150 & 255) << 8) | (_151 & 255);
        }

        _toUnsignedInt(v: number, w: number, x: number, y: number): number {
            return this._f(v, w) * 65536 + this._f(x, y);
        }

        toString(): string {
            return '<ByteOrder.BIG_ENDIAN>';
        }
    })();

    static LITTLE_ENDIAN: ByteOrder = new (class extends ByteOrder {
        _toByte(v: number): number {
            return v & 128 ? v | -256 : v;
        }

        _toUnsignedByte(v: number): number {
            return v & 255;
        }

        _fromShort(v: number): number[] {
            return this._fromShort(v).reverse();
        }

        _toShort(v: number, w: number): number {
            return this._toShort(w, v);
        }

        _toUnsignedShort(v: number, w: number): number {
            return this._toUnsignedShort(w, v);
        }

        _toUnsignedMediumInt(v: number, w: number, x: number): number {
            return this._toUnsignedMediumInt(x, w, v);
        }

        _fromMediumInt(v: number): number[] {
            return this._fromMediumInt(v).reverse();
        }

        _toMediumInt(v: number, w: number, x: number): number {
            return this._toMediumInt(x, w, v);
        }

        _fromInt(v: number): number[] {
            return this._fromInt(v).reverse();
        }

        _toInt(v: number, w: number, x: number, y: number): number {
            return this._toInt(y, x, w, v);
        }

        _toUnsignedInt(v: number, w: number, x: number, y: number): number {
            return this._toUnsignedInt(y, x, w, v);
        }

        toString(): string {
            return '<ByteOrder.LITTLE_ENDIAN>';
        }
    })();
}

//   // Example usage
//   const bigEndian: ByteOrder = ByteOrder.BIG_ENDIAN;
//   const littleEndian: ByteOrder = ByteOrder.LITTLE_ENDIAN;

//   console.log(bigEndian.toString()); // Output: <ByteOrder.BIG_ENDIAN>
//   console.log(littleEndian.toString()); // Output: <ByteOrder.LITTLE_ENDIAN>
