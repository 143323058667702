import { Message } from '../../message';

export class QSResponse extends Message {
    private MESSAGE_NAME: string = 'QSResponse';
    private requestId: number = 0;
    private resultList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestId: number = 0, resultList: any[] | null = null) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.resultList = resultList;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getResultList(): any[] | null {
        return this.resultList;
    }

    setResultList(resultList: any[] | null) {
        this.resultList = resultList;
    }
}
