import { Routes } from '@angular/router';

import { routeData } from '@frontend/vanilla/core';

import { MyTicketsComponent } from './mytickets.component';
import { pokerTicketsResolver } from './mytickets.resolver';

export const pokerMyTicketsRoutes: Routes = [
    {
        path: '',
        component: MyTicketsComponent,
        resolve: {
            initData: pokerTicketsResolver,
        },
        data: routeData({ allowAllWorkflowTypes: true, allowAuthorized: true }),
    },
];
