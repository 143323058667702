import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class ModalPopupViewModel {
    gameService: any;
    view;
    constructor() {
        //this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_POPUP_WAPPER, this.showWapper.bind(this));
        EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_POPUP_WAPPER, this.hideWapper.bind(this));
        EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_ERROR, this.hidePopup.bind(this));
        EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_WARNING, this.showWarning.bind(this));
    };
    getView = function () {
        return this.view;
    };
    showWapper = function () {
        this.view.showWapper();
    };
    onReize = function () {
        this.view.positionElements();
    };
    hideWapper = function () {
        this.view.hideWapper();
    };
    showPopup = function (title, message, btnText) {
        this.view.showPopup(title, message, btnText);
    };
    hidePopup = function () {
        this.view.hidePopUp();
    };
    showWarning = function (title, message, btnText) {
        this.view.showWarning(title, message, btnText);
    };
}
