import { Message } from '../../message';

export class AddCommunityCards extends Message {
    private MESSAGE_NAME: string = 'AddCommunityCards';
    private cards: any[] | null = null;
    private cardNo: number = 0;
    private cardType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cards: any[] | null = null, cardNo: number = 0, cardType: number = 0) {
        super(reqServerPeerId);
        this.cards = cards;
        this.cardNo = cardNo;
        this.cardType = cardType;
    }

    getCards(): any[] | null {
        return this.cards;
    }

    setCards(cards: any[] | null) {
        this.cards = cards;
    }
    getCardNo(): number {
        return this.cardNo;
    }

    setCardNo(cardNo: number) {
        this.cardNo = cardNo;
    }
    getCardType(): number {
        return this.cardType;
    }

    setCardType(cardType: number) {
        this.cardType = cardType;
    }
}
