import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatDecimal',
    standalone: true,
})
export class FormatDecimalPipe implements PipeTransform {
    transform(value: string, currencyRePosition: string) {
        const splitVal: any = value.split('+');
        const symbol = splitVal[0].replace(/[.+0-9\s]/g, '');
        let primaryVal: any = splitVal[0].replace(/[^0-9\.+]/g, '');
        let secondaryVal: any = splitVal[1].replace(/[^0-9\.+]/g, '');

        if (primaryVal % 1 !== 0) {
            primaryVal = Number(primaryVal).toFixed(2);
        }
        if (secondaryVal % 1 !== 0) {
            secondaryVal = Number(secondaryVal).toFixed(2);
        }
        if (currencyRePosition == 'true' && symbol == '€') {
            return `${primaryVal} ${symbol} + ${secondaryVal} ${symbol} `;
        }
        return symbol + primaryVal + ' + ' + (symbol + secondaryVal);
    }
}
