import { Message } from '../../message';

export class PoolAvailabilityRequest extends Message {
    private MESSAGE_NAME: string = 'PoolAvailabilityRequest';
    private currentEntries: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currentEntries: any[] | null = null) {
        super(reqServerPeerId);
        this.currentEntries = currentEntries;
    }

    getCurrentEntries(): any[] | null {
        return this.currentEntries;
    }

    setCurrentEntries(currentEntries: any[] | null) {
        this.currentEntries = currentEntries;
    }
}
