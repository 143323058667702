import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { PokerPromotionsResourceService } from './poker-promotions-resource.service';

@Injectable({ providedIn: 'root' })
export class PokerPromotionsResolver implements Resolve<any> {
    constructor(private resource: PokerPromotionsResourceService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        let folderId = route.paramMap.get('folderId');
        if (folderId == null) {
            folderId = 'overview';
        }
        const parentId = 'Promotions';
        if (document.getElementsByTagName('html')[0].classList.contains('product-poker')) {
            document.getElementsByTagName('html')[0].classList.add('product-poker');
        }
        return this.resource.getInitValues(parentId, folderId);
    }
}
