import { Message } from '../../message';

export class TableStakeLevelInfoResponse extends Message {
    private MESSAGE_NAME: string = 'TableStakeLevelInfoResponse';
    private realTableStakesInfo: any[] | null = null;
    private playTableStakesInfo: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, realTableStakesInfo: any[] | null = null, playTableStakesInfo: any[] | null = null) {
        super(reqServerPeerId);
        this.realTableStakesInfo = realTableStakesInfo;
        this.playTableStakesInfo = playTableStakesInfo;
    }

    getRealTableStakesInfo(): any[] | null {
        return this.realTableStakesInfo;
    }

    setRealTableStakesInfo(realTableStakesInfo: any[] | null) {
        this.realTableStakesInfo = realTableStakesInfo;
    }
    getPlayTableStakesInfo(): any[] | null {
        return this.playTableStakesInfo;
    }

    setPlayTableStakesInfo(playTableStakesInfo: any[] | null) {
        this.playTableStakesInfo = playTableStakesInfo;
    }
}
