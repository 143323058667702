import { Message } from '../../message';

export class PPFont extends Message {
    private MESSAGE_NAME: string = 'PPFont';
    private color: string | null = null;
    private name: string | null = null;
    private size: number = 0;
    private bold: boolean | null = false;
    private italic: boolean | null = false;
    private underline: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        color: string | null = null,
        name: string | null = null,
        size: number = 0,
        bold: boolean | null = false,
        italic: boolean | null = false,
        underline: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.color = color;
        this.name = name;
        this.size = size;
        this.bold = bold;
        this.italic = italic;
        this.underline = underline;
    }

    getColor(): string | null {
        return this.color;
    }

    setColor(color: string | null) {
        this.color = color;
    }
    getName(): string | null {
        return this.name;
    }

    setName(name: string | null) {
        this.name = name;
    }
    getSize(): number {
        return this.size;
    }

    setSize(size: number) {
        this.size = size;
    }
    getBold(): boolean | null {
        return this.bold;
    }

    setBold(bold: boolean | null) {
        this.bold = bold;
    }
    getItalic(): boolean | null {
        return this.italic;
    }

    setItalic(italic: boolean | null) {
        this.italic = italic;
    }
    getUnderline(): boolean | null {
        return this.underline;
    }

    setUnderline(underline: boolean | null) {
        this.underline = underline;
    }
}
