import { Message } from '../../message';

export class RealBalance extends Message {
    private MESSAGE_NAME: string = 'RealBalance';
    private accountBalance: number = 0;
    private accountCurrency: string | null = null;
    private cashOutableBalance: number = 0;
    private restrictedBalance: any[] | null = null;
    private PayPalBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        accountBalance: number = 0,
        accountCurrency: string | null = null,
        cashOutableBalance: number = 0,
        restrictedBalance: any[] | null = null,
        PayPalBalance: number = 0,
    ) {
        super(reqServerPeerId);
        this.accountBalance = accountBalance;
        this.accountCurrency = accountCurrency;
        this.cashOutableBalance = cashOutableBalance;
        this.restrictedBalance = restrictedBalance;
        this.PayPalBalance = PayPalBalance;
    }

    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getAccountCurrency(): string | null {
        return this.accountCurrency;
    }

    setAccountCurrency(accountCurrency: string | null) {
        this.accountCurrency = accountCurrency;
    }
    getCashOutableBalance(): number {
        return this.cashOutableBalance;
    }

    setCashOutableBalance(cashOutableBalance: number) {
        this.cashOutableBalance = cashOutableBalance;
    }
    getRestrictedBalance(): any[] | null {
        return this.restrictedBalance;
    }

    setRestrictedBalance(restrictedBalance: any[] | null) {
        this.restrictedBalance = restrictedBalance;
    }
    getPayPalBalance(): number {
        return this.PayPalBalance;
    }

    setPayPalBalance(PayPalBalance: number) {
        this.PayPalBalance = PayPalBalance;
    }
}
