import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PokerPromotionsResourceService {
    constructor(private api: PokerApiService) {}

    getInitValues(parent: string, data: string): Observable<any> {
        return this.api.get('PokerPromotion/GetPromotion', { parentId: parent, folderId: data }).pipe(catchError(() => of({ notFound: true })));
    }
}
