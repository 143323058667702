import { Ping } from '../server/common/connectorMessageFactory/messages/Ping';
import { RequestPeerConnectivityStatus } from '../server/common/connectorMessageFactory/messages/RequestPeerConnectivityStatus';
import { CSD } from './CSD';
import { Timer } from './common/timer';
import { ConnectionHandler } from './connectionHandler';
import { ConnectionManager } from './connectionManager';

// import { IConnectorDelegate } from './interfaces/IconnectorDelegate';

export class HealthMonitor {
    protected handler: ConnectionHandler;
    protected Timer: Timer;
    protected manager: ConnectionManager;
    private tableIds: number[] = [];
    public enabledRpcs: boolean = false;
    private rpcsTimer: Timer;
    // private delegate: IConnectorDelegate;

    constructor(handler: ConnectionHandler, pingTimer: number, peerTimer: number, startPing: boolean, stopPing: boolean) {
        this.handler = handler;
        this.manager = handler.connectionManager;

        this.Timer = new Timer(pingTimer, this.checkSocketConnectionStatus.bind(this));
        this.rpcsTimer = new Timer(peerTimer, this.checkPeerConnectionStatus.bind(this));
        if (startPing && !stopPing) {
            this.startPinger();
        } else if (!startPing && stopPing) {
            this.stopPinger();
        }
    }

    startPinger() {
        this.Timer.Start();
    }

    stopPinger() {
        this.stopRPCS();
        this.Timer.Stop();
    }

    startRPCS() {
        this.rpcsTimer.Start();
        this.enabledRpcs = true;
    }

    stopRPCS() {
        this.rpcsTimer.Stop();
        this.enabledRpcs = false;
    }

    addTableId(tableId: number) {
        if (tableId > 0) {
            this.tableIds.push(tableId);
        }
    }

    getArrofTbleids(): number[] {
        return this.tableIds;
    }
    removeTableId(peerIdToRemove: number) {
        this.tableIds = this.tableIds.filter((peerId) => peerId !== peerIdToRemove);
    }

    checkSocketConnectionStatus() {
        if (this.handler.idleTime >= 15) {
            this.manager.delegate.log(CSD.LOG_INFO, `ReConnection Initiated For Domain ${this.handler.domain} From Health Monitor`);
            this.handler.reConnect();
            return;
        } else {
            const ping: Ping = new Ping();
            this.manager.sendMessage(ping, this.handler.domain);
        }
        this.handler.idleTime += 5;
    }

    checkPeerConnectionStatus() {
        const peerConnStatus: RequestPeerConnectivityStatus = new RequestPeerConnectivityStatus();
        peerConnStatus.setPeersConnected(this.tableIds);
        this.manager.sendMessage(peerConnStatus, this.handler.domain);
    }
}
