import { Injectable } from '@angular/core';

import { PageMatrixService } from '@frontend/vanilla/core';

// import { OnAppInit } from '@frontend/vanilla/core';
// import * as pc from './poker-video.component';
import * as pwc from './poker-web-client.component';
import * as simhands from './simulated-hands-client/simulated-hands-client.component';

@Injectable({ providedIn: 'root' })
export class PokerWebClientPageMatrixBootstrapService {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('discountTimerTemplate', pwc.PokerWebClientComponent);
            this.pageMatrixService.registerComponent('simulatedhands', simhands.SimulatedHandsClientComponent);
        });
    }
}
