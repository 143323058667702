// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class TimeBankView extends PIXI.Container {
    gameService;
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor(gs) {
        super();
        this.gameService = gs;
    }
    createView = function (EventDispatcher, vm, maxNoOfSeats) {
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        this.vm = vm;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].timeBankConfig;
        this.playerAbsPos =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].playersUIData.playerAbsPos;
        this.playerIconPositions =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].playersUIData.playerIconPositions;
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            portAlignX: 'LEFT',
            portAlignY: 'BOTTOM',
            EventDispatcher: this.EventDispatcher,
        });
        // timebank container creation
        this.timeBankContainer = this.displayUtil.getContainer();
        this.addChild(this.timeBankContainer);
        this.timeBankContainer.name = 'TimeBankContainer';
        this.adjTimeBankYPos = 30;
        // timebank static button creation
        this.timeBankBtn = this.displayUtil.getSprite('sandclock_loop0001');
        this.timeBankContainer.addChild(this.timeBankBtn);
        this.displayUtil.setObjectPosition(
            this.timeBankBtn,
            this.positionConfig.idleTimeBankPos.x,
            this.positionConfig.idleTimeBankPos.y - this.adjTimeBankYPos,
        );
        this.timeBankBtn.visible = false;
        // timebank text available time creation
        // this.timeBankText = this.displayUtil.getTextField(this.fontProps.diamondText);
        this.timeBankText = this.displayUtil.getTextField(this.fontProps.timeBankText);
        this.timeBankContainer.addChild(this.timeBankText);
        this.timeBankText.text = '';
        this.timeBankText.anchor.set(0, 0.5);
        this.displayUtil.setObjectPosition(
            this.timeBankText,
            this.positionConfig.timeBankTextPos.x[0],
            this.positionConfig.timeBankTextPos.y[0] - this.adjTimeBankYPos,
        );
    };
    // will display timebank static button, timebank text/available time
    showTimeBank = function (availTime) {
        this.timeBankBtn.visible = true;
        this.timeBankText.visible = true;
        this.displayUtil.setObjectPosition(
            this.timeBankText,
            this.positionConfig.timeBankTextPos.x[0],
            this.positionConfig.timeBankTextPos.y[0] - this.adjTimeBankYPos,
        );
        //console.log("Tbank displayed", availTime);
        if (availTime < 10) {
            this.timeBankText.x = 26;
        }
        // if(availTime > 99){}
        this.timeBankText.text = '+' + availTime + ' secs';
    };
    enableTimeBankNotify = function (time, seatNO) {
        this.timeBankBtn.visible = false;
        if (seatNO == uiConfig.selfUserClientPosInd) {
            const timeBankbeforeStartseq = this.displayUtil.getSpriteDetails('timeBankAbouttoStartSeq');
            this.timeBankbeforeStartAni = this.displayUtil.getCustomAnimatedSprite(timeBankbeforeStartseq);
            this.addChild(this.timeBankbeforeStartAni);
            this.displayUtil.setObjectPosition(
                this.timeBankbeforeStartAni,
                this.positionConfig.timeBankNotifyAni.x,
                this.positionConfig.timeBankNotifyAni.y - this.adjTimeBankYPos,
            );
            this.timeBankbeforeStartAni.play();
            this.timeBankbeforeStartAni.loop = false;
        }
        this.callOtherPlayerTimeBank(seatNO, time);
    };
    checkTimeBankAni = function (tbType, time, seatNo) {
        if (tbType == 1) {
            if (seatNo == uiConfig.selfUserClientPosInd) {
                this.timeBankText.visible = false;
                this.callSelfTimeBankAni();
            } else {
                // this.timeBankText.text = "";
                // this.callOtherPlayerTimeBank(seatNo, time);
            }
        }
    };
    // for self player
    callSelfTimeBankAni = function () {
        if (this.timeBankbeforeStartAni) {
            this.timeBankbeforeStartAni.visible = false;
        }
        this.timeBankBtn.visible = false;
        const timeBankseq = this.displayUtil.getSpriteDetails('timeBankSeqAni');
        this.timeBankAni = this.displayUtil.getCustomAnimatedSprite(timeBankseq);
        this.addChild(this.timeBankAni);
        this.displayUtil.setObjectPosition(
            this.timeBankAni,
            this.positionConfig.timeBankActivePos.x,
            this.positionConfig.timeBankActivePos.y - this.adjTimeBankYPos,
        );
        this.timeBankAni.play();
        this.timeBankAni.loop = true;
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Hurry, false);
    };
    callOtherPlayerTimeBank = function (seatNo, tbTime) {
        const timeBankOtherPlayerseq = this.displayUtil.getSpriteDetails('timeBankOtherPlayer');
        this.otherPlayertimeBankAni = this.displayUtil.getCustomAnimatedSprite(timeBankOtherPlayerseq);
        this.addChild(this.otherPlayertimeBankAni);
        this.otherPlayertimeBankAni.play();
        this.otherPlayertimeBankAni.loop = false;
        if (seatNo == uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(this.otherPlayertimeBankAni, -37, 1070);
            this.displayUtil.setObjectPosition(
                this.timeBankText,
                this.positionConfig.timeBankTextPos.x[uiConfig.selfUserClientPosInd],
                this.positionConfig.timeBankTextPos.y[uiConfig.selfUserClientPosInd] - this.adjTimeBankYPos,
            );
        }
        this.otherPlayertimeBankAni.onComplete = function () {
            if (this.otherPlayertimeBankAni) this.otherPlayertimeBankAni.destroy();
            this.removeChild(this.otherPlayertimeBankAni);
            this.otherPlayertimeBankAni = null;
            if (seatNo !== uiConfig.selfUserClientPosInd) {
                this.timeBankText.visible = false;
            }
            if (this.timeBankBtn) {
                this.timeBankBtn.visible = false;
            }
        }.bind(this);
        setTimeout(
            function () {
                if (tbTime !== undefined) {
                    if (tbTime < 10 && seatNo == uiConfig.selfUserClientPosInd) {
                        this.timeBankText.x = 26;
                    }
                    this.timeBankText.text = '+' + tbTime + ' secs';
                    this.timeBankText.visible = true;
                }
            }.bind(this),
            100,
        );
    };
    checkPreviousAniPlaying = function () {
        this.clearAllAnimations();
    };
    showTimeBankContainer = function () {
        this.timeBankContainer.visible = true;
    };
    clearAllAnimations = function () {
        if (this.timeBankbeforeStartAni) {
            this.timeBankbeforeStartAni.destroy();
            this.removeChild(this.timeBankbeforeStartAni);
            this.timeBankbeforeStartAni = null;
        }
        if (this.timeBankText) {
            this.timeBankText.visible = false;
        }
        if (this.timeBankAni) {
            this.timeBankAni.destroy();
            this.removeChild(this.timeBankAni);
            this.timeBankAni = null;
        }
        if (this.OtherPlayertimeBankAni) {
            this.OtherPlayertimeBankAni.destroy();
            this.removeChild(this.OtherPlayertimeBankAni);
            this.OtherPlayertimeBankAni = null;
        }
        if (this.timeBankBtn) {
            this.timeBankBtn.visible = false;
        }
    };
    resetGame = function () {
        this.clearAllAnimations();
    };
    playTimerSound = function () {};
    resizeView = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        this.scale.set(scaleXValue, scaleYValue);
    };
    currentTablePeerId = function (peerId) {
        this.currTablePeerId = 'table' + peerId;
    };
}
