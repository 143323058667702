import { SessionDataModel } from './SessionDataModel';
import { TableSettingsDataModel } from './TableSettingsDataModel';

export class AppDataModel {
    public loginSuccessCB = null;
    public ClientLanguage = 'en'; //by default it will be en.
    public popUpPath;
    public balanceLimit;
    public tableSettingsDataModel: TableSettingsDataModel = new TableSettingsDataModel();
    public sessiondataModel: SessionDataModel = new SessionDataModel();
    constructor() {}
    public getClientLanguage = function () {
        return this.ClientLanguage;
    };

    public setClientLanguage = function (lang) {
        this.ClientLanguage = lang;
    };
    public getTableBalanceLimit = function () {
        return this.balanceLimit;
    };

    public setTableBalanceLimit = function (balanceInfo) {
        this.balanceLimit = balanceInfo;
    };

    /**
     * registers the callback to the Success Login Callback
     *
     * @param cb
     */
    public registerLoginCallBack = function (cb) {
        this.loginSuccessCB = cb;
    };
    /////need to add/////
    public handleLoginSuccessResponse = function (message) {
        this.userData = message;
        this.sessiondataModel.handleLoginSuccessResponse(message);
        if (this.loginSuccessCB !== null) {
            this.loginSuccessCB(message);
        }
    };
    public getSsokey = function () {
        return this.userData.ssoKey;
    };
    ///// To get Each Table Details /////
    public getTableDataModel = function () {
        return TableSettingsDataModel;
    };
    // to get the required URl ////
    //   getUrlData = function(ssoKey, reqUrl) {
    //     return ConfigUrlData.getUrlData(ssoKey, reqUrl);
    //   }
    public handleDiamondBalance = function (message) {
        this.sessiondataModel.handleDiamondBalance(message);
    };
    public getDiamondbalance = function () {
        return this.sessiondataModel.getDiamondBalance();
    };
    public handleUserFreeRollList = function (message) {
        this.sessiondataModel.handleUserFreeRollList(message);
    };
    public getSpinTicketCount = function (tournmentTicketList) {
        return this.sessiondataModel.getSpinTicketCount(tournmentTicketList);
    };
    handleUserProfile = function (message) {
        this.sessiondataModel.handleUserProfile(message);
    };
    getUserProfile = function (param) {
        return this.sessiondataModel.getUserprofile(param);
    };
}
