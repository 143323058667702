<div *ngIf="!isEpcotNavigation; else useEpcotNavigation">
    <div id="desktop-navigation">
        <section class="pm-area-navigation">
            <nav class="navbar navbar-expand-sm">
                <ul id="productMenu" class="navbar-nav">
                    <ng-container *ngFor="let item of navItems | dsl | async; let menuIndex = index">
                        <li
                            *ngIf="!item.children; else withChildren"
                            class="nav-item"
                            [ngClass]="{ activeNavClass: item.active || activeLink === item.url }">
                            <a
                                class="nav-link"
                                [target]="item.target ? item.target : '_self'"
                                [href]="item.url"
                                (click)="removeBodyClass($event, item.url, menuIndex)"
                                ><span>{{ item.text }}</span></a
                            >
                        </li>
                        <ng-template #withChildren>
                            <li (click)="toggleMenu(menuIndex)" [class]="item.active ? 'nav-link dropdown open' : 'nav-link dropdown'">
                                <span class="nav-link dropdown-toggle" data-toggle="dropdown">{{ item.text }}</span>
                                <div class="dropdown-menu">
                                    <ng-container *ngFor="let subItem of item.children | dsl | async; let subMenuIndex = index">
                                        <a
                                            [class]="isMobile && item.class ? 'dropdown-item ' + item.class : 'dropdown-item-dekstop'"
                                            [target]="subItem.target ? subItem.target : '_self'"
                                            [href]="subItem.url"
                                            (click)="removeBodyClass($event, subItem.url, subMenuIndex)">
                                            {{ subItem.text }}
                                        </a>
                                    </ng-container>
                                </div>
                            </li>
                        </ng-template>
                    </ng-container>
                </ul>
            </nav>
        </section>
    </div>

    <div id="mobile-navigation">
        <nav class="navbar navbar-expand-sm">
            <ul id="productMenu" class="navbar-nav">
                <ng-container *ngFor="let item of navItems | dsl | async; let menuIndex = index">
                    <li class="nav-item" [ngClass]="{ activeNavClass: item.active || activeLink === item.url }">
                        <a
                            [class]="isMobile && item.class ? 'nav-link ' + item.class : 'nav-link'"
                            [target]="item.target ? item.target : '_self'"
                            [href]="item.url"
                            (click)="removeBodyClass($event, item.url, menuIndex)">
                            <span>
                                <span class="icon" [ngClass]="item.parameters.icon"></span>
                                <span class="mobile-nav-text">{{ item.text }}</span>
                            </span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </nav>
    </div>
</div>

<ng-template #useEpcotNavigation>
    <div id="epcot-navigation" *ngIf="!mobileNativeApp">
        <section class="pm-area-navigation">
            <nav class="navbar-expand-sm" [ngClass]="{ 'active-navigation': activeNavigation }">
                <ul id="productMenu">
                    <li class="nav-item navigate-to-back-item" *ngIf="activeNavigation">
                        <a class="nav-link" (click)="navigateToBack()">
                            <span class="close-icon theme-close-small"></span>
                        </a>
                    </li>
                    <div class="swiper" #swiperComponent>
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of navItems | dsl | async; let menuIndex = index">
                                <li
                                    *ngIf="!item.children; else withChildren"
                                    class="nav-item"
                                    [ngClass]="{ activeNavClass: item.active || activeLink === item.url }">
                                    <a
                                        class="nav-link"
                                        [target]="item.target ? item.target : '_self'"
                                        [href]="item.url"
                                        (click)="removeBodyClass($event, item.url, menuIndex)">
                                        <span (click)="trackingEvent($event, item, menuIndex)">
                                            <span class="icon" [ngClass]="item.parameters.icon"></span>
                                            <span>{{ item.text }}</span>
                                        </span>
                                    </a>
                                </li>
                                <ng-template #withChildren>
                                    <li (click)="toggleMenu(menuIndex)" [class]="item.active ? 'nav-link dropdown open' : 'nav-link dropdown'">
                                        <span>
                                            <span class="icon" [ngClass]="item.parameters.icon"></span>
                                            <span class="nav-link dropdown-toggle" data-toggle="dropdown">{{ item.text }}</span>
                                        </span>
                                        <div class="dropdown-menu">
                                            <ng-container *ngFor="let subItem of item.children | dsl | async; let subMenuIndex = index">
                                                <a
                                                    [class]="isMobile && item.class ? 'dropdown-item ' + item.class : 'dropdown-item-dekstop'"
                                                    [target]="subItem.target ? subItem.target : '_self'"
                                                    [href]="subItem.url"
                                                    (click)="removeBodyClass($event, subItem.url, subMenuIndex)">
                                                    {{ subItem.text }}
                                                </a>
                                            </ng-container>
                                        </div>
                                    </li>
                                </ng-template>
                            </div>
                        </div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </ul>
            </nav>
        </section>
    </div>
</ng-template>
