import { Message } from '../../message';

export class ResponseSNGJPObserverDeltaChanges extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGJPObserverDeltaChanges';
    private snapShotTimeInNanos: number = 0;
    private domain: number = 0;
    private deltaEvents: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, snapShotTimeInNanos: number = 0, domain: number = 0, deltaEvents: any[] | null = null) {
        super(reqServerPeerId);
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.domain = domain;
        this.deltaEvents = deltaEvents;
    }

    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
}
