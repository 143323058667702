import { Message } from '../../message';

export class ResponseBetAndWinSummary extends Message {
    private MESSAGE_NAME: string = 'ResponseBetAndWinSummary';
    private betAmount: number = 0;
    private winAmount: number = 0;
    private gameCurrency: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, betAmount: number = 0, winAmount: number = 0, gameCurrency: string | null = null) {
        super(reqServerPeerId);
        this.betAmount = betAmount;
        this.winAmount = winAmount;
        this.gameCurrency = gameCurrency;
    }

    getBetAmount(): number {
        return this.betAmount;
    }

    setBetAmount(betAmount: number) {
        this.betAmount = betAmount;
    }
    getWinAmount(): number {
        return this.winAmount;
    }

    setWinAmount(winAmount: number) {
        this.winAmount = winAmount;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
}
