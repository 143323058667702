<div class="mb-sm-3 event-details-banner-wrapper" *ngIf="item">
    <div class="banner-img">
        <div class="next-event">
            <div
                *ngIf="item.pplBackAndForthEvents[0].previousEventLink.length !== 0"
                class="float-left ppl-cursor-pointer"
                (click)="gotoEvent(item.pplBackAndForthEvents[0].previousEventLink)">
                <span class="btn btn-icon btn-link-light btn-link theme-move-left btn-sm pr-0 pl-0 ml-0"> </span>
                {{ item.eventConfigStaticData.previous }}
                {{ item.eventConfigStaticData.event }}
            </div>
            <div
                *ngIf="item.pplBackAndForthEvents[0].nextEventLink.length !== 0"
                class="float-right ppl-cursor-pointer"
                (click)="gotoEvent(item.pplBackAndForthEvents[0].nextEventLink)">
                {{ item.eventConfigStaticData.next }}
                {{ item.eventConfigStaticData.event }}
                <span class="btn btn-icon btn-link-light btn-link theme-move-right btn-sm pl-0 pr-0 mr-0"> </span>
            </div>
        </div>
        <img class="w-100" *ngIf="item.eventBannerImage.src" [src]="item.eventBannerImage.src" [alt]="item.eventBannerImage.alt" />
    </div>
    <div class="title">
        <h2>{{ item.eventTitle }}</h2>
        <p>{{ returnEventDate(item.eventStartDate, item.eventEndDate) }}</p>
        <div class="event-social-wrapper">
            <div class="social-item" *ngIf="item.eventType === 'upcoming'">
                <button class="btn btn-primary btn-sm">
                    <span class="theme-calendar s-icon" [title]="item.eventConfigStaticData.addToCalendar"> </span>
                </button>
                <div class="social-item-options add-to-calendar-options">
                    <span
                        class="item m-1 ppl-cursor-pointer"
                        *ngFor="let option of item.eventConfigStaticData.addToCalendarGroup"
                        (click)="getCalendarUrl(option)">
                        {{ option.label }}
                    </span>
                </div>
            </div>
            <div class="social-item">
                <button class="btn btn-primary btn-sm">
                    <span class="theme-share s-icon" [title]="item.eventConfigStaticData.share"> </span>
                </button>
                <div class="social-item-options">
                    <span
                        class="m-1 ppl-cursor-pointer item"
                        *ngFor="let option of item.eventConfigStaticData.socialMediaLinksGroup"
                        (click)="getSocialUrl(option)">
                        <img *ngIf="option.iconUrl" class="w-100" [src]="option.iconUrl" alt="Icon Url" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
