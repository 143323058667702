import { Message } from '../../message';

export class SelectOptionAck extends Message {
    private MESSAGE_NAME: string = 'SelectOptionAck';
    private optionSetId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, optionSetId: number = 0) {
        super(reqServerPeerId);
        this.optionSetId = optionSetId;
    }

    getOptionSetId(): number {
        return this.optionSetId;
    }

    setOptionSetId(optionSetId: number) {
        this.optionSetId = optionSetId;
    }
}
