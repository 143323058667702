import { Message } from '../../message';

export class RequestFoldToAnyBetOn extends Message {
    private MESSAGE_NAME: string = 'RequestFoldToAnyBetOn';

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0) {
        super(reqServerPeerId);
    }
}
