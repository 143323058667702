<pk-navigation-bar></pk-navigation-bar>
<div class="leaderboard-header">
    <h1>Leaderboard</h1>
    <input (keyup)="applyFilter($event.target.value)" placeholder="Type here to Filter..." />
    <div class="mat-elevation-z8">
        <mat-table [dataSource]="dataSource" id="sampleID">
            <!-- rank Column -->
            <ng-container matColumnDef="rank">
                <mat-header-cell *matHeaderCellDef> Rank </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element?.rank }} </mat-cell>
            </ng-container>

            <!-- screenName Column -->
            <ng-container matColumnDef="screenName">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element?.screenName?.charAt(0).toUpperCase() + element?.screenName?.substr(1).toLowerCase() }}
                </mat-cell>
            </ng-container>

            <!-- points Column -->
            <ng-container matColumnDef="points">
                <mat-header-cell *matHeaderCellDef> Points </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element?.points }} </mat-cell>
            </ng-container>

            <!-- prize Column -->
            <ng-container matColumnDef="prize">
                <mat-header-cell *matHeaderCellDef> Prize </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element?.prize[1]?.prize }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
    </div>
</div>
