import { Message } from '../../message';

export class CountryBlockEventResponse extends Message {
    private MESSAGE_NAME: string = 'CountryBlockEventResponse';
    private eventName: string | null = null;
    private permissionFlag: boolean | null = false;
    private errorCode: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, eventName: string | null = null, permissionFlag: boolean | null = false, errorCode: any | null = null) {
        super(reqServerPeerId);
        this.eventName = eventName;
        this.permissionFlag = permissionFlag;
        this.errorCode = errorCode;
    }

    getEventName(): string | null {
        return this.eventName;
    }

    setEventName(eventName: string | null) {
        this.eventName = eventName;
    }
    getPermissionFlag(): boolean | null {
        return this.permissionFlag;
    }

    setPermissionFlag(permissionFlag: boolean | null) {
        this.permissionFlag = permissionFlag;
    }
    getErrorCode(): any | null {
        return this.errorCode;
    }

    setErrorCode(errorCode: any | null) {
        this.errorCode = errorCode;
    }
}
