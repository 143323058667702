import { Message } from '../../message';

export class MTCTFontDetails extends Message {
    private MESSAGE_NAME: string = 'MTCTFontDetails';
    private fontId: number = 0;
    private prefixFontId: number = 0;
    private suffixFontId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, fontId: number = 0, prefixFontId: number = 0, suffixFontId: number = 0) {
        super(reqServerPeerId);
        this.fontId = fontId;
        this.prefixFontId = prefixFontId;
        this.suffixFontId = suffixFontId;
    }

    getFontId(): number {
        return this.fontId;
    }

    setFontId(fontId: number) {
        this.fontId = fontId;
    }
    getPrefixFontId(): number {
        return this.prefixFontId;
    }

    setPrefixFontId(prefixFontId: number) {
        this.prefixFontId = prefixFontId;
    }
    getSuffixFontId(): number {
        return this.suffixFontId;
    }

    setSuffixFontId(suffixFontId: number) {
        this.suffixFontId = suffixFontId;
    }
}
