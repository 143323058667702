import { Card } from '../card';
import { AllInOtherBlind } from '../events/all-in-other-blind';
import { TableEvent } from '../table-event';
import { TableEvents } from '../table-events.enum';
import { RitWinHigh } from './Rit-Win-High';
import { AllInAnte } from './all-in-ante';
import { AllInBet } from './all-in-bet';
import { AllInBigBlind } from './all-in-big-blind';
import { AllInBringIn } from './all-in-bring-in';
import { AllInButtonAnte } from './all-in-button-ante';
import { AllInCall } from './all-in-call';
import { AllInCompletBringIn } from './all-in-complete-bring-in';
import { AllInDoubleBet } from './all-in-double-bet';
import { AllInDoubleRaise } from './all-in-double-raise';
import { AllInRaise } from './all-in-raise';
import { AllInSmallBlind } from './all-in-small-blind';
import { Bet } from './bet';
import { Call } from './call';
import { CardHidden } from './card-hidden';
import { CardShown } from './card-shown';
import { CashOut } from './cashout';
import { Check } from './check';
import { CommunityCard } from './community-card';
import { CommunityCardTask } from './community-card-task';
import { CompleteBringIn } from './complete-bring-in';
import { DealHoleCards } from './deal-hole-cards';
import { DealingFifthStreet } from './dealing-fifth-street';
import { DealingFlop } from './dealing-flop';
import { DealingFourthStreet } from './dealing-fourth-street';
import { DealingRiver } from './dealing-river';
import { DealingSeventhStreet } from './dealing-seventh-street';
import { DealingSixthStreet } from './dealing-sixth-street';
import { DealingThirdStreet } from './dealing-third-street';
import { DealingTurn } from './dealing-turn';
import { DeselectedAdvanced } from './deselected-advanced';
import { DoubleBet } from './double-bet';
import { DoubleRaise } from './double-raise';
import { Fold } from './fold';
import { HANDSTRENGTH } from './hand-strength';
import { MissedSmallBlind } from './missed-small-blind';
import { OptionSet } from './option-set';
import { PlayerChat } from './player-chat';
import { PlayerHolecardsEvents } from './player-holecards-events';
import { PlayerHurryUp } from './player-hurry-up';
import { PlayerMuckCards } from './player-muck-cards';
import { PlayerShowCards } from './player-show-cards';
import { PlayerTimedOut } from './player-timed-out';
import { PlayersAnteEvent } from './players-ante-event';
import { PostAnte } from './post-ante';
import { PostBigBlind } from './post-big-blind';
import { PostBringIn } from './post-bring-in';
import { PostButtonAnte } from './post-button-ante';
import { PlayerDummyAnte } from './post-dummy-ante';
import { PostNewPlayerBlind } from './post-new-player-blind';
import { PostOtherBlind } from './post-other-blind';
import { PostSmallBlind } from './post-small-blind';
import { PostingAntes } from './posting-antes';
import { PostingBlind } from './posting-blind';
import { PotCreated } from './pot-created';
import { Raise } from './raise';
import { RitCommunityCardTask } from './rit-community-card-task';
import { SelectedAdvanced } from './selected-advanced';
import { SevencardPlayerHolecardsEvents } from './sevencard-player-holecards-events';
import { SevencardPlayerFifthStreetEventCards } from './sevencard-playerfifthStreet-holecards-events';
import { SevencardPlayerFourthStreetEventCards } from './sevencard-playerfourthStreet-holecards-events';
import { SevencardPlayerSeventhStreetEventCards } from './sevencard-playerseventhStreet-holecards-events';
import { SevencardPlayerSixthStreetEventCards } from './sevencard-playersixthStreet-holecards-events';
import { ShowDown } from './show-down';
import { Unknowncards } from './unknowncards';
import { WinHigh } from './win-high';
import { WinLow } from './win-low';

export class EventFactory {
    static getEvent(
        type: string,
        seat: number | null,
        amount: number | null,
        card: Card | null,
        potId: number | null,
        tmp: any | null,
        searchKey: string,
        handStrength: string,
    ): TableEvent {
        let e: any = null;

        switch (type) {
            case TableEvents[TableEvents.POSTING_BLINDS]: {
                e = new PostingBlind();
                break;
            }
            case TableEvents[TableEvents.POSTING_ANTES]: {
                e = new PostingAntes();
                break;
            }
            case TableEvents[TableEvents.POST_ANTE]: {
                e = new PostAnte();
                break;
            }
            case TableEvents[TableEvents.POST_BUTTON_ANTE]: {
                e = new PostButtonAnte();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_BUTTON_ANTE]: {
                e = new AllInButtonAnte();
                break;
            }
            case TableEvents[TableEvents.POST_SMALL_BLIND]: {
                e = new PostSmallBlind();
                break;
            }
            case TableEvents[TableEvents.POST_NEW_PLAYER_BLIND]: {
                e = new PostNewPlayerBlind();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_OTHER_BLIND]: {
                e = new AllInOtherBlind();
                break;
            }
            case TableEvents[TableEvents.MISSED_SMALL_BLIND]: {
                e = new MissedSmallBlind();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_ANTE]: {
                e = new AllInAnte();
                break;
            }
            case TableEvents[TableEvents.POST_DUMMY_ANTE]: {
                e = new PlayerDummyAnte();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_BIG_BLIND]: {
                e = new AllInBigBlind();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_SMALL_BLIND]: {
                e = new AllInSmallBlind();
                break;
            }
            case TableEvents[TableEvents.POST_BIG_BLIND]: {
                e = new PostBigBlind();
                break;
            }
            case TableEvents[TableEvents.POST_BRING_IN]: {
                e = new PostBringIn();
                break;
            }
            case TableEvents[TableEvents.COMPLETE_BRING_IN]: {
                e = new CompleteBringIn();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_COMPLETE_BRING_IN]: {
                e = new AllInCompletBringIn();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_BRING_IN]: {
                e = new AllInBringIn();
                break;
            }
            case TableEvents[TableEvents.POST_OTHER_BLIND]: {
                e = new PostOtherBlind();
                break;
            }
            case TableEvents[TableEvents.DEALING_HOLE_CARDS]: {
                e = new DealHoleCards();
                break;
            }
            case TableEvents[TableEvents.DEALING_THIRD_STREET]: {
                e = new DealingThirdStreet();
                break;
            }
            case TableEvents[TableEvents.DEALING_FOURTH_STREET]: {
                e = new DealingFourthStreet();
                break;
            }
            case TableEvents[TableEvents.DEALING_FIFTH_STREET]: {
                e = new DealingFifthStreet();
                break;
            }
            case TableEvents[TableEvents.DEALING_SIXTH_STREET]: {
                e = new DealingSixthStreet();
                break;
            }
            case TableEvents[TableEvents.DEALING_SEVENTH_STREET]: {
                e = new DealingSeventhStreet();
                break;
            }
            case TableEvents[TableEvents.PLAYER_HOLECARDS_EVENT]: {
                e = new PlayerHolecardsEvents();
                break;
            }
            case TableEvents[TableEvents.SEVENCARD_PLAYER_HOLECARDS_EVENT]: {
                e = new SevencardPlayerHolecardsEvents();
                break;
            }
            case TableEvents[TableEvents.SEVENCARD_DEALINGFOURTHSTREET_PLAYER_HOLECARDS_EVENT]: {
                e = new SevencardPlayerFourthStreetEventCards();
                break;
            }
            case TableEvents[TableEvents.SEVENCARD_DEALINGFIFTHSTREET_PLAYER_HOLECARDS_EVENT]: {
                e = new SevencardPlayerFifthStreetEventCards();
                break;
            }
            case TableEvents[TableEvents.SEVENCARD_DEALINGSIXTHSTREET_PLAYER_HOLECARDS_EVENT]: {
                e = new SevencardPlayerSixthStreetEventCards();
                break;
            }
            case TableEvents[TableEvents.SEVENCARD_DEALINGSEVENTHSTREET_PLAYER_HOLECARDS_EVENT]: {
                e = new SevencardPlayerSeventhStreetEventCards();
                break;
            }
            case TableEvents[TableEvents.CARD_SHOWN]: {
                e = new CardShown();
                break;
            }
            case TableEvents[TableEvents.CARD_HIDDEN]: {
                e = new CardHidden();
                break;
            }
            case TableEvents[TableEvents.OPTION_SET]: {
                e = new OptionSet();
                break;
            }
            case TableEvents[TableEvents.PLAYER_TIMED_OUT]: {
                e = new PlayerTimedOut();
                break;
            }
            case TableEvents[TableEvents.FOLD]: {
                e = new Fold();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_RAISE]: {
                e = new AllInRaise();
                break;
            }
            case TableEvents[TableEvents.POT_CREATED]: {
                e = new PotCreated();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_CALL]: {
                e = new AllInCall();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_BET]: {
                e = new AllInBet();
                break;
            }
            case TableEvents[TableEvents.CHECK]: {
                e = new Check();
                break;
            }
            case TableEvents[TableEvents.CALL]: {
                e = new Call();
                break;
            }
            case TableEvents[TableEvents.RAISE]: {
                e = new Raise();
                break;
            }
            case TableEvents[TableEvents.DOUBLE_RAISE]: {
                e = new DoubleRaise();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_DOUBLE_RAISE]: {
                e = new AllInDoubleRaise();
                break;
            }
            case TableEvents[TableEvents.BET]: {
                e = new Bet();
                break;
            }
            case TableEvents[TableEvents.DOUBLE_BET]: {
                e = new DoubleBet();
                break;
            }
            case TableEvents[TableEvents.ALL_IN_DOUBLE_BET]: {
                e = new AllInDoubleBet();
                break;
            }
            case TableEvents[TableEvents.COMMUNITY_CARD]: {
                e = new CommunityCard();
                break;
            }
            case TableEvents[TableEvents.DEALING_FLOP]: {
                e = new DealingFlop();
                break;
            }
            case TableEvents[TableEvents.DEALING_TURN]: {
                e = new DealingTurn();
                break;
            }
            case TableEvents[TableEvents.DEALING_RIVER]: {
                e = new DealingRiver();
                break;
            }
            case TableEvents[TableEvents.SHOWDOWN]: {
                e = new ShowDown();
                break;
            }
            case TableEvents[TableEvents.SELECTED_ADVANCED]: {
                e = new SelectedAdvanced();
                break;
            }
            case TableEvents[TableEvents.DESELECTED_ADVANCED]: {
                e = new DeselectedAdvanced();
                break;
            }
            case TableEvents[TableEvents.PLAYER_MUCK_CARDS]: {
                e = new PlayerMuckCards();
                break;
            }
            case TableEvents[TableEvents.PLAYER_SHOW_CARDS]: {
                e = new PlayerShowCards();
                break;
            }
            case TableEvents[TableEvents.UNKNOWN_CARDS]: {
                e = new Unknowncards();
                break;
            }
            case TableEvents[TableEvents.PLAYER_HURRY_UP]: {
                e = new PlayerHurryUp();
                break;
            }
            case TableEvents[TableEvents.PLAYER_CHAT]: {
                e = new PlayerChat();
                break;
            }
            case TableEvents[TableEvents.WIN_HIGH]: {
                e = new WinHigh();
                break;
            }
            case TableEvents[TableEvents.RIT_WIN_HIGH]: {
                e = new RitWinHigh();
                break;
            }
            case TableEvents[TableEvents.WIN_LOW]: {
                e = new WinLow();
                break;
            }
            case TableEvents[TableEvents.PLAYERS_ANTE_EVENT]: {
                e = new PlayersAnteEvent();
                break;
            }
            case TableEvents[TableEvents.CASHOUT]: {
                e = new CashOut();
                break;
            }

            case TableEvents[TableEvents.HAND_STRENGTH]: {
                e = new HANDSTRENGTH();
                break;
            }
        }

        if (e != null) {
            e.seat = seat;
            e.amount = amount;
            e.card = card;
            e.potId = potId;
            e.searchKey = searchKey;
            e.handStrength = handStrength;
        }

        return e as TableEvent;
    }

    static getCommunityCardTask(): CommunityCardTask {
        return new CommunityCardTask();
    }
    static getRitCommunityCardTask(): RitCommunityCardTask {
        return new RitCommunityCardTask();
    }
}
