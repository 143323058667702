import { Message } from '../../message';

export class ShowURL extends Message {
    private MESSAGE_NAME: string = 'ShowURL';
    private url: string | null = null;
    private actionType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, url: string | null = null, actionType: number = 0) {
        super(reqServerPeerId);
        this.url = url;
        this.actionType = actionType;
    }

    getUrl(): string | null {
        return this.url;
    }

    setUrl(url: string | null) {
        this.url = url;
    }
    getActionType(): number {
        return this.actionType;
    }

    setActionType(actionType: number) {
        this.actionType = actionType;
    }
}
