import { Routes } from '@angular/router';

import { InboxViewComponent } from './components/inbox-view.component';

export const ROUTES: Routes = [
    {
        path: '',
        component: InboxViewComponent,
    },
];
