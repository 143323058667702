import { Routes } from '@angular/router';

import { routeData } from '@frontend/vanilla/core';
import { provideStore } from '@ngrx/store';
import { HandHistoryComponent } from '@pokercore/module/hand-history';
import { MyTicketsComponent } from '@pokercore/module/pk-mytickets';

import { commonFiltersComponent } from './common/lobby-filters/ff-filters/common-filters.component';
import { LobbyFiltersComponent } from './common/lobby-filters/lobby-filters.component';
import { PortalPagesContainerComponent } from './common/portal-pages-container/portal-pages-container.component';
import { GameHolderComponent } from './game/game-holder.component';
import { CashLobbyComponent } from './lobby/cash-lobby/cash-lobby.component';
import { FastForwardLobbyComponent } from './lobby/ff-lobby/ff-lobby.component';
import { MainLobbyComponent } from './lobby/main-lobby/main-lobby.component';
import { MyTourneysComponent } from './lobby/myTourneys/myTourneys.component';
import { SpinsLobbyComponent } from './lobby/spin-lobby/spin-lobby.component';
import { SpinsOverdriveLobbyComponent } from './lobby/spins-overdrive/spins-overdrive-lobby.component';
import { PokerWebClientComponent } from './poker-web-client.component';
import { PokerWebClientResolver } from './poker-web-client.resolver';
import { ClientToServerRequestServices } from './service/client-to-server-requests.service';
import { PWCLoyaltyCashBackService } from './service/loyalty-cashBack.service';
import { SimulatedHandsClientComponent } from './simulated-hands-client/simulated-hands-client.component';
import { GameTableSettingsReducer, RaiseButtonPostFlopReducer, RaiseButtonPreFlopReducer } from './store/game-table-settings.store';
import {
    athBlockTimeReducer,
    athHandCountReducer,
    cashLobbyDataReducer,
    ffLobbyDataReducer,
    isSpinsBuyinReducer,
    registeredTourneysListReducer,
    spinsBuyinReducer,
    spinsOverdriveLobbyDataReducer,
} from './store/lobby.store';
import { LoyaltyCashBackReducer } from './store/loyalty-cashback.store';
import { miniTablesReducer } from './store/mini-table.store';
import { dynaconDataReducer, siteCoreLiteralsReducer } from './store/siteCore-Dynacon.store';
import {
    userBalanceReducer,
    userDuplicateLoginReducer,
    userIsAuthenticatedReducer,
    userLoginFailureReducer,
    userProfileReducer,
    userSSOKeyReducer,
    userTicketReducer,
} from './store/user.store';

export const pokerWebClientRoutes: Routes = [
    {
        path: '',
        component: PokerWebClientComponent,
        resolve: {
            initData: PokerWebClientResolver,
        },
        data: routeData({ allowAllWorkflowTypes: true, allowAuthorized: true }),
        providers: [
            ClientToServerRequestServices,
            PWCLoyaltyCashBackService,
            provideStore({
                UserSSOKey: userSSOKeyReducer,
                UserProfile: userProfileReducer,
                IsAuthenticated: userIsAuthenticatedReducer,
                CashGameLobbyData: cashLobbyDataReducer,
                FFGameLobbyData: ffLobbyDataReducer,
                MiniTables: miniTablesReducer,
                GameTableSettings: GameTableSettingsReducer,
                RaiseButtonPreFlop: RaiseButtonPreFlopReducer,
                RaiseButtonPostFlop: RaiseButtonPostFlopReducer,
                SiteCoreLiterals: siteCoreLiteralsReducer,
                DynaconConfigs: dynaconDataReducer,
                SpinsOverdriveGameLobbyData: spinsOverdriveLobbyDataReducer,
                SpinsBuyinData: spinsBuyinReducer,
                UserBalance: userBalanceReducer,
                UserTicket: userTicketReducer,
                HandCount: athHandCountReducer,
                BlockTime: athBlockTimeReducer,
                LoginFailure: userLoginFailureReducer,
                DuplicateLogin: userDuplicateLoginReducer,
                LoyaltyCashBack: LoyaltyCashBackReducer,
                isSpinsBuyInEnabled: isSpinsBuyinReducer,
                registeredTourneysData: registeredTourneysListReducer,
            }),
        ],

        children: [
            {
                path: '',
                component: MainLobbyComponent,
            },
            {
                path: 'cash',
                component: CashLobbyComponent,
            },
            {
                path: 'fast-forward',
                component: FastForwardLobbyComponent,
            },
            {
                path: 'spins',
                component: SpinsLobbyComponent,
            },
            {
                path: 'spins-overdrive',
                component: SpinsOverdriveLobbyComponent,
            },
            {
                path: 'game-table',
                component: GameHolderComponent,
            },
            {
                path: 'filters',
                component: commonFiltersComponent,
            },
            {
                path: 'cash-filters',
                component: LobbyFiltersComponent,
            },
            {
                path: 'portal',
                component: PortalPagesContainerComponent,
            },
            {
                path: 'handhistory',
                component: HandHistoryComponent,
            },
            {
                path: 'tickets',
                component: MyTicketsComponent,
            },
            {
                path: 'tutorial',
                component: SimulatedHandsClientComponent,
            },
            {
                path: 'myTourneys',
                component: MyTourneysComponent,
            },
            // {
            //     path: 'how-to-play',
            //     loadChildren: () => import('./portal-htp-container/portal-htp-container.routing').then(p => p.pokerHTPRoutes)
            // }
        ],
    },
];
