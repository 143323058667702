import { Message } from '../../message';

export class LSTourneyLevelChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyLevelChange';
    private level: number = 0;
    private currSeatSize: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, level: number = 0, currSeatSize: number = 0) {
        super(reqServerPeerId);
        this.level = level;
        this.currSeatSize = currSeatSize;
    }

    getLevel(): number {
        return this.level;
    }

    setLevel(level: number) {
        this.level = level;
    }
    getCurrSeatSize(): number {
        return this.currSeatSize;
    }

    setCurrSeatSize(currSeatSize: number) {
        this.currSeatSize = currSeatSize;
    }
}
