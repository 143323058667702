import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router, RouterModule } from '@angular/router';

import { NavigationService } from '@frontend/vanilla/core';
// import { NavigationService } from '@frontend/vanilla/core';
// import { NavigationService } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { Subscription } from 'rxjs';

// import { Store } from '@ngrx/store';
import { AppFacade } from '../PWC-games-lib/lib/app/app-facade';
import { GameCanvasComponent } from '../PWC-games-lib/lib/game-canvas/game-canvas.component';
import { CommonGameProperties } from '../PWC-games-lib/lib/game-config/CommonGameProperties';
import { SlotCoreService } from '../PWC-games-lib/lib/services/SlotCoreService';
// import { SlotCoreService } from '../PWC-games-lib/lib/services/SlotCoreService';
// import { AssetLoaderService } from '../PWC-games-lib/lib/services/asset-loader.service';
import { CashFFBuyInPopUpComponent } from '../common/cash-ff-buyin/cash-ff-buyIn.component';
import { PWCErrorDialogComponent } from '../common/error-dialog/pwc-error-dialog.component';
import { FormatamountPipe } from '../pipes/formatAmount.pipe';
// import { CgFiltersEventBusService } from '../common/lobby-filters/services/cg-filters-event-bus.service';
import { formatCurrencyPipe } from '../pipes/formatCurrency.pipe';
import { PokerWebClientSharedService } from '../poker-web-client-shared.service';
import { LangPack } from '../service/LangPack';
import { MathUtils } from '../service/MathUtil';
import { ClientToServerRequestServices } from '../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../service/event-despacher-global';
import { GameTableNotificationService } from '../service/game-table-notification.service';
import { ExpoSpinsAnimationComponent } from './expospin-animation/expospin-animation.component';

@Component({
    selector: 'pwc-gametable',
    templateUrl: 'game.component.html',
    //imports: [CommonModule, RouterModule, GameCanvasComponent,formatCurrencyPipe,ExpoSpinAnimationComponent],
    imports: [
        CommonModule,
        RouterModule,
        GameCanvasComponent,
        formatCurrencyPipe,
        ExpoSpinsAnimationComponent,
        CashFFBuyInPopUpComponent,
        PWCErrorDialogComponent,
        FormatamountPipe,
    ],
    providers: [formatCurrencyPipe, MathUtils, SlotCoreService, FormatamountPipe],
    standalone: true,
})
export class GameTableComponent implements OnInit, AfterViewInit {
    @ViewChild(GameCanvasComponent) gameCanvas?: GameCanvasComponent;
    @ViewChild('expoAnimation1') expoAnimation1?: ExpoSpinsAnimationComponent;
    @ViewChild('expoAnimation2') expoAnimation2?: ExpoSpinsAnimationComponent;
    @ViewChild('expoAnimation3') expoAnimation3?: ExpoSpinsAnimationComponent;
    @ViewChild('expoAnimation4') expoAnimation4?: ExpoSpinsAnimationComponent;
    public showAnimation1 = false;
    public showAnimation2 = false;
    public showAnimation3 = false;
    public showAnimation4 = false;
    private gameApp?: any;
    public expoAnimIframe: any;
    private appFacade = new AppFacade();
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    // private gameAssetStatus:any;
    // private storeObj:any;
    public selectedTable: any;
    enableExpo: boolean;
    totalBuyIn: number;
    // private gameService:SlotCoreService;
    isAutoreBuyin: boolean = false;
    finalData: any;
    gameType: any;
    entryId: any;
    poolId: any;
    entry: any;
    pool: any;
    playerbalance: any;
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    maxbuyinvalue: any;
    routerSubscription: Subscription;

    constructor(
        private dialog: MatDialog,
        private navigation: NavigationService,
        // private store:Store,
        private formatCurrencyPipe: formatCurrencyPipe,
        private routeData: RouteDataService,
        // private eventBus: CgFiltersEventBusService,
        private clientToServer: ClientToServerRequestServices,
        private mathUtil: MathUtils,
        // private gameService:SlotCoreService
        private formatamountPipe: FormatamountPipe,
        private sharedService: PokerWebClientSharedService,
        private router: Router,
    ) {
        CommonGameProperties.facade = this.appFacade;
        CommonGameProperties.mathUtil = this.formatCurrencyPipe;
        CommonGameProperties.dynaconConfigs = this.routeData.getInitData().dynaconData;
        CommonGameProperties.webAssets = this.routeData.getInitData().webAssets;
        CommonGameProperties.langPack = new LangPack(this.routeData.getInitData().siteCoreData);
        CommonGameProperties.clienttoserver = this.clientToServer;
        CommonGameProperties.currencyConverter = this.mathUtil;
        CommonGameProperties.numberFormatter = this.formatamountPipe;
        //    this.showWidget();
        this.eventDispatcherGlobal.addEventListener('gameEventListener', this.handShakeResponse.bind(this));
        this.eventDispatcherGlobal.addEventListener('resumeExpoAnime', this.resumeExpoAnime.bind(this));
        this.eventDispatcherGlobal.addEventListener('resetExpoAnimationIframe', this.receiveMessage.bind(this));
        this.eventDispatcherGlobal.addEventListener('showUnregisterPopup', this.openErrorPopup.bind(this));
        this.eventDispatcherGlobal.addEventListener('loadExpoSpinAnime', this.loadExpoSpinAnime.bind(this));
        this.eventDispatcherGlobal.addEventListener('hideExpoAnimation', this.hideExpoAnimations.bind(this));
        this.eventDispatcherGlobal.addEventListener('OPEN_ATH_LEARN_MORE', this.openlearnmorelink.bind(this));

        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.navigationTrigger === 'popstate') {
                    let message = {};
                    message['className'] = 'removeReplayers';
                    this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
                }
            }
        });
    }

    ngOnInit(): void {
        this.eventDispatcherGlobal.addEventListener('onShowRebuyPopUp', this.onShowRebuyPopUp.bind(this));
        this.receiveMessage = this.receiveMessage.bind(this);
        window.addEventListener('customEventName', this.handShakeResponse);
        window.addEventListener('newSpinTable', this.handShakeResponse);
        window.addEventListener('message', this.receiveMessage);
    }
    receiveMessage(event) {
        let message;
        if (event.detail) {
            message = event.detail;
        } else if (event.data) {
            message = event.data;
        }
        // Handle messages received from iframe
        if (message['type'] != 'resetAnimation') {
            if (message['type'] === 'ResponseExpoAudio' || message['type'] === 'SwipeExpoAnimation' || message['type'] === 'tapToUnMute')
                this.appFacade.handleServerResponse(message);
            return;
        }
        const frameId = this.getAsignedIframeId(message.tableId);
        // this.resetIframe(CommonGameProperties.expoAnime[frameId].expoSpinAnime,event.detail.tableId)
        if (frameId != undefined) {
            CommonGameProperties.expoAnime[frameId].expoSpinAnime.activeStatus = false;
            CommonGameProperties.expoAnime[frameId].expoSpinAnime.tableId = 0;
        }
    }
    ngAfterViewInit(): void {
        this.gameApp = this.gameCanvas?.getStage();
        this.createBasicView();
        //   this.loadScriptsSequentially(scriptUrls);
        CommonGameProperties.expoAnime.push({
            expoSpinAnime: this.expoAnimation1,
            expoId: 1,
        });
        CommonGameProperties.expoAnime.push({
            expoSpinAnime: this.expoAnimation2,
            expoId: 2,
        });
        CommonGameProperties.expoAnime.push({
            expoSpinAnime: this.expoAnimation3,
            expoId: 3,
        });
        CommonGameProperties.expoAnime.push({
            expoSpinAnime: this.expoAnimation4,
            expoId: 4,
        });
    }
    closePopup() {
        // this.logger.info("clicked on close popup icon...! ");
        // this.popupIsOpen = false;
        this.isAutoreBuyin = false;
    }
    onBuyInButtonClick(values: any) {
        // this.logger.info("buyInData.." + JSON.stringify(values));
        this.clientToServer.cashGameBuyinRequest(this.selectedTable, values);
        // this.cashGameService.requestForQuickSeatRing(this.buyInData,this.isAutoBuyInTrue,this.selectedBuyIn, this.gameCount);
    }

    createBasicView() {
        const appData = {
            gameApp: this.gameApp,
            // soundUtil: this.soundUtilService
        };
        this.appFacade.initiateGame(appData);
        setTimeout(() => {
            this.gameCanvas?.onResize();
        }, 5);
    }
    onShowRebuyPopUp(data) {
        if (data.detail.type == 'FF') {
            if (data.detail.table.maxBuyInAmount - data.detail.userBalance > 0) {
                this.isAutoreBuyin = data.detail.isAutoreBuyin;
                this.playerbalance = data.detail.userBalance;
                this.gameType = data.detail.type;
                this.selectedTable = data.detail.table;
                this.entry = data.detail.entryId;
                this.pool = data.detail.poolId;
                this.maxbuyinvalue = data.detail.table.maxBuyInAmount;
            } else {
                this.gameTableNotificationService.poolRebuyRequest({
                    amount: data.detail.table.maxBuyInAmount,
                    conversationId: 0,
                    poolId: data.detail.poolId,
                    entryId: data.detail.entryId,
                });
            }
        } else {
            if (data.detail.table.maxBuyInAmount - data.detail.userBalance > 0) {
                this.isAutoreBuyin = data.detail.isAutoreBuyin;
            }
            this.gameType = data.detail.type;
            this.playerbalance = data.detail.userBalance;
            this.selectedTable = data.detail.table;
            this.entry = data.detail.entryId;
        }
    }
    //   selectedCard(i, card) {
    //     // this.logger.info("Clicked on selected cards" + JSON.stringify(i, card));
    //     this.selectedTableIndex = i;
    //     this.selectedTable = card;
    //     this.totalBuyIn = card?.minBuyInAmount / 100;
    //   }
    //   getLobbyData() {
    //     this.store.select(cashLobbyDataSelect).subscribe((data) => {
    //       if (data.length != 0) {
    //         this.finalData = this.eventBus.filterCashGamesCards(data);
    //         // this.logger.info(" cardsData in cash lobby " + JSON.stringify(this.finalData));
    //         this.selectedCard(0, this.finalData[this.selectedTableIndex]);
    //       } else {
    //         // this.clientToServer.requestForLobbyData(true,0,'CASH');
    //         this.finalData = [];
    //         // this.logger.info(" cardsData in cash lobby " + JSON.stringify(this.finalData));
    //       }
    //       this.finalData = _.sortBy(this.finalData, [(obj) => obj.formattedBuyIn]);
    //       console.log("data... finalData..", data);
    //     });
    //   }
    handShakeResponse(message: any) {
        if (!message.detail['peerId']) {
            message.detail['peerId'] = message.detail.reqServerPeerId;
        }
        if (!message.detail['className']) {
            message.detail['className'] = message.detail.MESSAGE_NAME;
        }
        if (message.detail['className'] == 'SNGJPExpoAnimation') {
            this.showExpo(message.detail['data']);
        }
        if (message.detail['className'] == 'OnSwipe') {
            this.appFacade.swipeTable(message.detail.dir);
        }

        // if(message.detail["className"] == "JoinTableResponse")
        //     this.loadExpoSpinAnime(message.detail.peerId);
        this.appFacade.handleServerResponse(message.detail);
    }
    showExpo(message) {
        // this.enableExpo = true;
        // this.showAnimation1 = true;
        // console.log("expo click")
        let iframeWindow;
        if (this.getAsignedIframeId(message.peerId) != undefined) {
            CommonGameProperties.expoAnime[this.getAsignedIframeId(message.peerId)].expoSpinAnime.activeStatus = true;
            this.resetIframe(CommonGameProperties.expoAnime[this.getAsignedIframeId(message.peerId)].expoSpinAnime, message.peerId);
            const nativeElement = CommonGameProperties.expoAnime[this.getAsignedIframeId(message.peerId)].expoSpinAnime.dynamicContentContainer
                .nativeElement as HTMLElement;
            CommonGameProperties.expoAnime[
                this.getAsignedIframeId(message.peerId)
            ].expoSpinAnime.dynamicContentContainer.nativeElement.contentDocument.body.style.visibility = 'visible';
            // nativeElement.style.display = 'block';
            iframeWindow =
                CommonGameProperties.expoAnime[this.getAsignedIframeId(message.peerId)].expoSpinAnime.dynamicContentContainer.nativeElement
                    .contentWindow;
            const animationData = {};
            animationData['className'] = 'multiplier';
            let activetable = false;
            // if(CommonGameProperties["facade"]["activeTableId"] == message.peerId)
            //      activetable = true;
            if (CommonGameProperties.facade['table' + message.peerId].scale.x != 0.5) {
                if (CommonGameProperties.facade['activeTableId'] == message.peerId && this.router.url.includes('/play/game-table')) {
                    nativeElement.style.display = 'block';
                    // CommonGameProperties.expoAnime[this.getAsignedIframeId(message.peerId)].expoSpinAnime.activeStatus=true;
                    activetable = true;
                } else {
                    nativeElement.style.display = 'none';
                }
            } else {
                nativeElement.style.display = 'block';
            }
            //  nativeElement.style.display = 'none';
            animationData['data'] = {
                json: {
                    TimeDurationInMillis: message.expoAnimationInMillis,
                    PrizePoolValueText: CommonGameProperties.numberFormatter['transform'](message.sngJPPayoutInfo.totalPrize, 'USD'), //message.sngJPPayoutInfo.totalPrize/100,//window.parent.MathUtil.getFormattedAmountValue(message.sngJPPayoutInfo.totalPrize, this.gameService.getGameCurrency(),true),
                    MultiplierText: message.selectedMultiplierNew / 100,
                    maxMultiplierConfigured: message.maxMultiplierConfigured,
                    nMaxMultiplierConfigured: message.maxMultiplierConfigured,
                    isSoundsEnabled: true,
                    tableId: message.peerId,
                    iframeId: this.getAsignedIframeId(message.peerId), //iframeWindow.getAttribute("id"),
                    isContextAvailable: CommonGameProperties['facade']['isContextAvailable'],
                    prizepoolText: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMELOBBY_PRIZE_POOL').toUpperCase(), //facade.langPack.getLiteral("PARTY_POKER_GC_COMMON_PRIZEPOOL"),
                    tapToUnmuteText: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_COMMON_TAPTOUNMUTE'), //facade.langPack.getLiteral("PARTY_POKER_MOBILE_COMMON_TAPTOUNMUTE"),
                    activetable: activetable,
                },
            };
            // this.resetIframe(CommonGameProperties.expoAnime[this.getAsignedIframeId(message.peerId)].expoSpinAnime,message.peerId)
            iframeWindow.postMessage(animationData, '*');
        }
    }

    resetIframe(expoAnimIframe, peerId) {
        const currenttable = 'table' + peerId; //this.gameService.getPeerId();
        // var visible;
        // if(val == true){
        //     visible = "block";
        // }else{
        //    visible = "none";
        // }
        let posX, posY, minHeight, minWidth;
        const tablePositions = {
            x: [0, window.innerWidth / 2, 0, window.innerWidth / 2],
            y: [0, 0, window.innerHeight / 2, window.innerHeight / 2],
        };
        if (CommonGameProperties.facade[currenttable].scale.x == 0.5) {
            const tableindex = CommonGameProperties.facade['activeTables'].indexOf(peerId); //window.facade.activeTables.indexOf(this.gameService.getPeerId());
            posX = tablePositions.x[tableindex] + 'px';
            posY = tablePositions.y[tableindex] + 'px';
            minHeight = window.innerHeight / 2 + 'px';
            minWidth = window.innerWidth / 2 + 'px';
        }
        if (CommonGameProperties.facade[currenttable].scale.x == 0.5) {
            //   expoAnimIframe.setAttribute("style",
            //     "position: absolute;border:0;top: 0px;z-index:1;background-color:#000000");
            const nativeElement = expoAnimIframe.dynamicContentContainer.nativeElement as HTMLElement;
            nativeElement.style.position = 'absolute';
            nativeElement.style.border = '0';
            nativeElement.style.top = '0px';
            nativeElement.style.zIndex = '1';
            nativeElement.style.backgroundColor = '#000000';
            nativeElement.style.display = 'block';
            nativeElement.style.height = minHeight;
            nativeElement.style.width = minWidth;
            nativeElement.style.left = posX;
            nativeElement.style.top = posY;
        } else {
            const nativeElement = expoAnimIframe.dynamicContentContainer.nativeElement as HTMLElement;
            nativeElement.style.cssText =
                'position: absolute; border: 0; top: 0px; z-index: 1; background-color: #000000;z-index:1;display: block; height: calc(100% - 0px); width: 100%;';
        }
    }

    getAsignedIframeId = function (tableId) {
        let activeIframe;
        for (let i = 0; i < 4; i++) {
            if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == tableId) {
                activeIframe = i;
                break;
            }
        }
        return activeIframe;
    };
    loadExpoSpinAnime(val) {
        if (this.istableIdAssigned(val.detail.peerId) == true) return;
        // var currenttable = "table" + val.detail.peerId//this.gameService.getPeerId();
        const activeIframeId = this.getActiveIframeId();
        this.expoAnimIframe = CommonGameProperties.expoAnime[activeIframeId].expoSpinAnime;
        this.expoAnimIframe.tableId = val.detail.peerId; //this.gameService.getPeerId();
        CommonGameProperties.facade['updateTileModeExpoAnimationPosition']();
        // this.expoAnimIframe.activeStatus = true;
        // this.expoAnimIframe.setAttribute("tableId", this.gameService.getPeerId());
        // this.expoAnimIframe.setAttribute("active", "false");
        // this.resetIframe(false);
        // setTimeout(function(){
        //   this.playExpoSpin();
        // }.bind(this),10000);
    }
    getActiveIframeId() {
        let activeIframe;
        for (let i = 0; i < 4; i++) {
            //   var iframe = document.getElementById("expoSpinIframe"+i);
            if (
                CommonGameProperties.expoAnime[i].expoSpinAnime.activeStatus == false &&
                CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == 0
            ) {
                activeIframe = i;
                break;
            }
        }
        return activeIframe;
    }
    istableIdAssigned(id) {
        for (let i = 0; i < 4; i++) {
            if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == id) return true;
        }
        return false;
    }
    resumeExpoAnime = function (expoAnimIframe) {
        const message = {};
        message['className'] = 'ResumeExpoAnime';
        if (expoAnimIframe.detail.activeStatus == true) {
            //   expoAnimIframe.detail.style.display = "block";
            expoAnimIframe.detail.dynamicContentContainer.nativeElement.contentWindow.postMessage(message, '*');
        }
    };

    hideExpoAnimations(message) {
        if (message.detail.peerId === undefined) {
            this.handleAnimationInTileMode();
            return;
        }
        // for (let i = 0; i < 4; i++) {
        let iframeId = this.getAsignedIframeId(message.detail.peerId);
        if (iframeId !== undefined) {
            const doc = window.parent.document.getElementById('exponetialAnimation' + iframeId);
            let iframeWindow = CommonGameProperties.expoAnime[iframeId].expoSpinAnime.dynamicContentContainer.nativeElement.contentWindow;
            const animationData = {};
            animationData['className'] = 'PauseExpoSpinAnime';
            iframeWindow.postMessage(animationData, '*');
            if (doc) {
                doc.style.display = 'none';
            }
        }
    }

    openErrorPopup(data) {
        const obj = {
            title: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_ONECLICK_UNREGISTER'),
            message: data.detail,
            buttons: [{ text: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_LOGIN_OK') }],
        };
        this.dialog.open(PWCErrorDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: obj,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }

    handleAnimationInTileMode() {
        for (let i = 0; i < 4; i++) {
            if (CommonGameProperties.facade['activeTables'].length > 0) {
                CommonGameProperties.facade['activeTables'].map((id) => {
                    CommonGameProperties.facade['hideExpoSpinsAnimation'](id);
                });
            }
        }
    }

    openlearnmorelink() {
        this.sharedService.sendPortalPage({
            text: this.clientToServer.externalLinks['ath-link']['title'],
            url: this.clientToServer.externalLinks['ath-link']['titleLink']['url'],
        });
        this.navigation.goTo('/play/' + 'portal');
    }
}
