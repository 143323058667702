import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class BottomPanelViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISPLAY_MAX_BTN, this.displayMaxBtn.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISPLAY_MIN_BTN, this.displayMinBtn.bind(this));
    };
    initiateButtons = function () {
        this.view.updateButtonData(['infoicon', 'emojiicon', 'replayericon']);
    };
    displayMaxBtn = function () {
        this.view.displayMaxBtn();
    };
    displayMinBtn = function () {
        this.view.displayMinBtn();
    };
}
