import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'pwc-expospin-animation',
    templateUrl: 'expospin-animation.component.html',
    imports: [CommonModule],
    providers: [],
    standalone: true,
})
export class ExpoSpinsAnimationComponent {
    @ViewChild('expoSpinsAnimationContainer', { static: true }) dynamicContentContainer: ElementRef;
    public tableId: number = 0;
    public activeStatus: boolean = false;
    public assignStatus: boolean = false;
    htmlContent: string;
}
