import { Component, Input } from '@angular/core';

import { Dealer } from '../../models/dealer';
import { GameTableService } from '../game-table/game-table.service';

@Component({
    selector: 'pkr-dealer',
    templateUrl: './dealer.component.html',
})
export class DealerComponent {
    @Input() dealer: Dealer;
    slefPlayerDealer: any;

    constructor(private gameTableService: GameTableService) {}

    ngOnChanges() {
        this.slefPlayerDealer = this.gameTableService.isRequestingPlayerDealer;
    }
}
