import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';
import { PCComponentHeaderV2Component } from '@frontend/vanilla/features/content';
import { TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';

@Component({
    selector: 'poker-youtube-feed',
    templateUrl: 'poker-youtube-feed.component.html',
    host: {
        '(window:resize)': 'onResize($event)',
    },
    standalone: true,
    imports: [PCComponentHeaderV2Component, CommonModule, TrustAsResourceUrlPipe],
})
export class PokerYoutubeFeedComponent extends PCComponent implements OnInit {
    selectedClip: number;
    videoClipUrl: any;
    videoItemsArr: any = [];
    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit(): void {
        if (this.item !== undefined) {
            this.item.youtubeData.items.forEach((element: any) => {
                element.embedVideoUrl = 'https://www.youtube.com/embed/' + element.videoId;
                // element.embedVideoUrl = "https://www.youtube.com/embed/ENaggB6jdlU";
                this.videoItemsArr.push(element);
            });
        }
        this.playVideoClip(this.item.youtubeData.items[0]);
    }

    ngAfterViewInit() {
        this.videoListHeight();
    }

    playVideoClip(clip: any) {
        if (clip) {
            this.selectedClip = clip;
            this.videoClipUrl = clip.videoEmbededurl;
        }

        //  console.log('Video Click', clip)
    }

    videoListHeight() {
        const listHeight = document.querySelectorAll('.youtubeWrapper .embed-responsive');

        for (let i = 0; i < listHeight.length; i++) {
            const lh = listHeight[i].clientHeight + 'px';

            const videoList = document.querySelectorAll('.youtubeWrapper .videoListWrapper');
            videoList[i].setAttribute('style', 'height:' + lh);
        }
    }

    _decodeUriComponent(str: any) {
        return decodeURIComponent(str);
    }

    onResize() {
        this.videoListHeight();
    }
}
