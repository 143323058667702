import { CommonModule } from '@angular/common';
import { Component, HostBinding, NgZone, OnInit } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-countdown',
    templateUrl: 'poker-countdown.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PokerCountdownComponent extends PCComponent implements OnInit {
    tournamentDataList: any;
    widgetConfig: any;
    activeItem: any;
    currenttime: any;
    minutes: number;
    hours: number;
    seconds: number;
    daysleft: number;
    endTime: any;
    lateRegEndTime: any;
    millisecondsleft: number;
    lateRegisMillisecondsleft: number;
    // secondsleft: any;
    // timeLeft: string;

    constructor(public zone: NgZone) {
        super();
    }

    @HostBinding()
    get class() {
        // this.tournamentDataList = this.item.dataValue.tournaments;
        return this.createClass(this.item.name);
    }

    ngOnInit(): void {
        this.tournamentDataList = this.item.dataValue.tournaments;
        this.widgetConfig = this.item.dataValue.widgetConfig;
        setInterval(() => {
            this.refreshFeed();
        }, 1000);
    }

    private formatTimeStr(days: number, hours: number, minutes: number, seconds: number) {
        if (days > 0) {
            return (
                (days < 10 ? '0' : '') +
                days +
                ':' +
                (hours < 10 ? '0' : '') +
                hours +
                ':' +
                (minutes < 10 ? '0' : '') +
                minutes +
                ':' +
                (seconds < 10 ? '0' : '') +
                seconds
            );
        }
        return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    refreshFeed() {
        //this.zone.run(() => {
        if (this.tournamentDataList) {
            for (let t = 0; t < this.tournamentDataList.length; t++) {
                this.currenttime = new Date();
                this.endTime = new Date(this.tournamentDataList[t].startDate);
                this.lateRegEndTime = new Date(this.tournamentDataList[t].durationLateRegClose);
                this.millisecondsleft = this.endTime - this.currenttime;
                this.lateRegisMillisecondsleft = this.lateRegEndTime - this.currenttime;
                if (this.millisecondsleft > 0 || this.lateRegisMillisecondsleft > 0) {
                    if (this.millisecondsleft > 0) {
                        this.daysleft = Math.floor(this.millisecondsleft / (24 * 60 * 60 * 1000));
                        this.hours = Math.floor((this.millisecondsleft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

                        this.minutes = Math.floor((this.millisecondsleft % (60 * 60 * 1000)) / (60 * 1000));
                        this.seconds = Math.floor((this.millisecondsleft % (60 * 1000)) / 1000);

                        this.tournamentDataList[t].timeLeft = this.item.upcomingTournamentStartText
                            .replace(this.item.tournamentName, this.tournamentDataList[t].name)
                            .replace(this.item.tournamentTime, this.formatTimeStr(this.daysleft, this.hours, this.minutes, this.seconds));
                    }
                    if (this.millisecondsleft <= 0 && this.lateRegisMillisecondsleft > 0) {
                        this.daysleft = Math.floor(this.millisecondsleft / (24 * 60 * 60 * 1000));
                        this.hours = Math.floor((this.millisecondsleft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

                        this.minutes = Math.floor((this.millisecondsleft % (60 * 60 * 1000)) / (60 * 1000));
                        this.seconds = Math.floor((this.millisecondsleft % (60 * 1000)) / 1000);
                        this.tournamentDataList[t].timeLeft = this.item.lateRegistrationText
                            .replace(this.item.tournamentName, this.tournamentDataList[t].name)
                            .replace(this.item.tournamentTime, this.formatTimeStr(this.daysleft, this.hours, this.minutes, this.seconds));
                    }
                    //
                    this.activeItem = this.tournamentDataList[t];
                    return;
                }
                // if(this.millisecondsleft < 0 && this.lateRegisMillisecondsleft < 0)
                // {
                //     this.tournamentDataList.splice(t, 1);
                // }
            }
        }
        //});
    }

    openClient(tournament: any) {
        location.href = this.widgetConfig.ctaLink.replace('{{id}}', tournament.id);
    }
}
