import { Message } from '../../message';

export class ChallengeHistoryRequest extends Message {
    private MESSAGE_NAME: string = 'ChallengeHistoryRequest';
    private challengeType: number = 0;
    private isSender: boolean | null = false;
    private isRegistering: boolean | null = false;
    private isPlaying: boolean | null = false;
    private isCompleted: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        challengeType: number = 0,
        isSender: boolean | null = false,
        isRegistering: boolean | null = false,
        isPlaying: boolean | null = false,
        isCompleted: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.challengeType = challengeType;
        this.isSender = isSender;
        this.isRegistering = isRegistering;
        this.isPlaying = isPlaying;
        this.isCompleted = isCompleted;
    }

    getChallengeType(): number {
        return this.challengeType;
    }

    setChallengeType(challengeType: number) {
        this.challengeType = challengeType;
    }
    getIsSender(): boolean | null {
        return this.isSender;
    }

    setIsSender(isSender: boolean | null) {
        this.isSender = isSender;
    }
    getIsRegistering(): boolean | null {
        return this.isRegistering;
    }

    setIsRegistering(isRegistering: boolean | null) {
        this.isRegistering = isRegistering;
    }
    getIsPlaying(): boolean | null {
        return this.isPlaying;
    }

    setIsPlaying(isPlaying: boolean | null) {
        this.isPlaying = isPlaying;
    }
    getIsCompleted(): boolean | null {
        return this.isCompleted;
    }

    setIsCompleted(isCompleted: boolean | null) {
        this.isCompleted = isCompleted;
    }
}
