import { Message } from '../../message';

export class LSTabIdMTCTDetails extends Message {
    private MESSAGE_NAME: string = 'LSTabIdMTCTDetails';
    private mtcts: any[] | null = null;
    private deletedMtcts: any[] | null = null;
    private snapShotTimeInNanos: number = 0;
    private fullUpdate: boolean | null = false;
    private updatemtcts: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtcts: any[] | null = null,
        deletedMtcts: any[] | null = null,
        snapShotTimeInNanos: number = 0,
        fullUpdate: boolean | null = false,
        updatemtcts: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.mtcts = mtcts;
        this.deletedMtcts = deletedMtcts;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.fullUpdate = fullUpdate;
        this.updatemtcts = updatemtcts;
    }

    getMtcts(): any[] | null {
        return this.mtcts;
    }

    setMtcts(mtcts: any[] | null) {
        this.mtcts = mtcts;
    }
    getDeletedMtcts(): any[] | null {
        return this.deletedMtcts;
    }

    setDeletedMtcts(deletedMtcts: any[] | null) {
        this.deletedMtcts = deletedMtcts;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getFullUpdate(): boolean | null {
        return this.fullUpdate;
    }

    setFullUpdate(fullUpdate: boolean | null) {
        this.fullUpdate = fullUpdate;
    }
    getUpdatemtcts(): any[] | null {
        return this.updatemtcts;
    }

    setUpdatemtcts(updatemtcts: any[] | null) {
        this.updatemtcts = updatemtcts;
    }
}
