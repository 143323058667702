<div
    class="community-cards"
    [style.left]="getXAxis()"
    [style.top]="getYAxis()"
    [class.rit]="gameTable.isRit"
    [class.Mrit]="gameTable.ritcommuntycards"
    ngClass="c{{ gameTable.totalNumberOfCommunityCards }}">
    <pkr-card
        [card]="card"
        [gameTable]="gameTable"
        [index]="i"
        [seq]="getCommunityCardsSequence(i)"
        *ngFor="let card of cards; let i = index"></pkr-card>
</div>
