import { Message } from '../../message';

export class RGLimitErrorMsg extends Message {
    private MESSAGE_NAME: string = 'RGLimitErrorMsg';
    private errorMsg: any | null = null;
    private conversationId: number = 0;
    private responseId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, errorMsg: any | null = null, conversationId: number = 0, responseId: number = 0) {
        super(reqServerPeerId);
        this.errorMsg = errorMsg;
        this.conversationId = conversationId;
        this.responseId = responseId;
    }

    getErrorMsg(): any | null {
        return this.errorMsg;
    }

    setErrorMsg(errorMsg: any | null) {
        this.errorMsg = errorMsg;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
}
