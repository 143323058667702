import { Message } from '../../message';

export class ResponseAddFavoriteCard extends Message {
    private MESSAGE_NAME: string = 'ResponseAddFavoriteCard';
    private responseCode: number = 0;
    private favoriteId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseCode: number = 0, favoriteId: string | null = null) {
        super(reqServerPeerId);
        this.responseCode = responseCode;
        this.favoriteId = favoriteId;
    }

    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getFavoriteId(): string | null {
        return this.favoriteId;
    }

    setFavoriteId(favoriteId: string | null) {
        this.favoriteId = favoriteId;
    }
}
