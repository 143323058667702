import { Message } from '../../message';

export class LSTourneyPrizeInfo extends Message {
    private MESSAGE_NAME: string = 'LSTourneyPrizeInfo';
    private message: any | null = null;
    private totalDistributed: number = 0;
    private dealDistributed: number = 0;
    private totalPrizePool: number = 0;
    private remainingPrizePool: number = 0;
    private ignorePrizesUpdate: boolean | null = false;
    private prizeMessages: any[] | null = null;
    private mainPrizePool: number = 0;
    private bountyPrizePool: number = 0;
    private collectedPrizePool: number = 0;
    private considerLRCloseConfig: boolean | null = false;
    private placesPaid: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        message: any | null = null,
        totalDistributed: number = 0,
        dealDistributed: number = 0,
        totalPrizePool: number = 0,
        remainingPrizePool: number = 0,
        ignorePrizesUpdate: boolean | null = false,
        prizeMessages: any[] | null = null,
        mainPrizePool: number = 0,
        bountyPrizePool: number = 0,
        collectedPrizePool: number = 0,
        considerLRCloseConfig: boolean | null = false,
        placesPaid: number = 0,
    ) {
        super(reqServerPeerId);
        this.message = message;
        this.totalDistributed = totalDistributed;
        this.dealDistributed = dealDistributed;
        this.totalPrizePool = totalPrizePool;
        this.remainingPrizePool = remainingPrizePool;
        this.ignorePrizesUpdate = ignorePrizesUpdate;
        this.prizeMessages = prizeMessages;
        this.mainPrizePool = mainPrizePool;
        this.bountyPrizePool = bountyPrizePool;
        this.collectedPrizePool = collectedPrizePool;
        this.considerLRCloseConfig = considerLRCloseConfig;
        this.placesPaid = placesPaid;
    }

    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getTotalDistributed(): number {
        return this.totalDistributed;
    }

    setTotalDistributed(totalDistributed: number) {
        this.totalDistributed = totalDistributed;
    }
    getDealDistributed(): number {
        return this.dealDistributed;
    }

    setDealDistributed(dealDistributed: number) {
        this.dealDistributed = dealDistributed;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getRemainingPrizePool(): number {
        return this.remainingPrizePool;
    }

    setRemainingPrizePool(remainingPrizePool: number) {
        this.remainingPrizePool = remainingPrizePool;
    }
    getIgnorePrizesUpdate(): boolean | null {
        return this.ignorePrizesUpdate;
    }

    setIgnorePrizesUpdate(ignorePrizesUpdate: boolean | null) {
        this.ignorePrizesUpdate = ignorePrizesUpdate;
    }
    getPrizeMessages(): any[] | null {
        return this.prizeMessages;
    }

    setPrizeMessages(prizeMessages: any[] | null) {
        this.prizeMessages = prizeMessages;
    }
    getMainPrizePool(): number {
        return this.mainPrizePool;
    }

    setMainPrizePool(mainPrizePool: number) {
        this.mainPrizePool = mainPrizePool;
    }
    getBountyPrizePool(): number {
        return this.bountyPrizePool;
    }

    setBountyPrizePool(bountyPrizePool: number) {
        this.bountyPrizePool = bountyPrizePool;
    }
    getCollectedPrizePool(): number {
        return this.collectedPrizePool;
    }

    setCollectedPrizePool(collectedPrizePool: number) {
        this.collectedPrizePool = collectedPrizePool;
    }
    getConsiderLRCloseConfig(): boolean | null {
        return this.considerLRCloseConfig;
    }

    setConsiderLRCloseConfig(considerLRCloseConfig: boolean | null) {
        this.considerLRCloseConfig = considerLRCloseConfig;
    }
    getPlacesPaid(): number {
        return this.placesPaid;
    }

    setPlacesPaid(placesPaid: number) {
        this.placesPaid = placesPaid;
    }
}
