import { CSD } from './CSD';

// import { IConnectorDelegate } from './interfaces/IconnectorDelegate';

export class ConnectionHandlerStrategy {
    private existingConnIdsMap: Map<number, Map<number, number>> = new Map<number, Map<number, number>>();
    private domainConnMap: Map<number, number> = new Map<number, number>();
    private connNameMap: Map<number, string> = new Map<number, string>();
    // private delegate: IConnectorDelegate;

    public getConnectionName(socketId: number, domain: number): string {
        let connName = this.connNameMap.get(socketId);
        if (domain === CSD.NON_GAMING) {
            connName = 'NS Default';
        } else if (domain === CSD.SOCIAL) {
            connName = 'SC Default';
        }
        if (!connName) {
            connName = 'TS-CONN-' + socketId;
            this.connNameMap.set(socketId, connName);
        }
        return connName;
    }

    public getAllPeerIds(): number[] {
        const peers: number[] = [];
        for (const domain of this.existingConnIdsMap.keys()) {
            const peerIdConnIdMap = this.existingConnIdsMap.get(domain);
            if (!peerIdConnIdMap || peerIdConnIdMap.size === 0) continue;
            peers.push(...Array.from(peerIdConnIdMap.keys()));
        }
        return peers;
    }

    public getPeerIds(domainId: number): number[] {
        const peerIdConnIdMap = this.existingConnIdsMap.get(domainId);
        return peerIdConnIdMap ? Array.from(peerIdConnIdMap.keys()) : [];
    }

    public isConnectedToDomain(domainId: number): boolean {
        return this.domainConnMap.has(domainId);
    }

    public getPeersDomainId(peerId: number): number {
        for (const domain of this.existingConnIdsMap.keys()) {
            const domainMap = this.existingConnIdsMap.get(domain);
            if (domainMap && domainMap.has(peerId)) return domain;
        }
        // this.delegate.log(CSD.LOG_ERROR, `No domain is mapped to the peerId: ${peerId} `)
        throw new Error('No domain is mapped to the peerId: ' + peerId);
    }

    public isRealConnId(connId: number): boolean {
        for (const domain of this.existingConnIdsMap.keys()) {
            const domainMap = this.existingConnIdsMap.get(domain);
            if (domainMap && Object.values(domainMap).includes(connId)) {
                return domain === CSD.REAL;
            }
        }
        // this.delegate.log(CSD.LOG_ERROR,`The connectionid doesn't exist on any of the domains; existingMap: ${this.existingConnIdsMap}`);
        throw new Error("The connectionid doesn't exist on any of the domains; existingMap " + this.existingConnIdsMap);
    }

    public lookupPeersConnectionId(peerId: number): number {
        for (const domain of this.existingConnIdsMap.keys()) {
            const domainMap = this.existingConnIdsMap.get(domain);
            if (domainMap && domainMap.has(peerId)) return domainMap.get(peerId)!;
        }
        return -1;
    }

    public lookupOrCreateConnectionId(domain: number, peerId: number): number {
        let peerConnIdMap = this.existingConnIdsMap.get(domain);
        if (peerConnIdMap == undefined) {
            peerConnIdMap = new Map<number, number>();
            this.existingConnIdsMap.set(domain, peerConnIdMap);
        }
        let connId = peerConnIdMap.get(peerId);
        if (connId == undefined) {
            connId = this.getNewConnectionId(domain);
            peerConnIdMap.set(peerId, connId);
        }
        return connId;
    }

    public getNewConnectionId(domain: number): number {
        let maxVal = this.domainConnMap.get(domain);
        if (!maxVal) maxVal = 0;
        if (maxVal % 400 === 0 && maxVal !== 0) {
            maxVal++;
        }
        this.domainConnMap.set(domain, maxVal);
        return maxVal;
    }

    public removePeersOnConnection(connId: number): void {
        for (const domainId of this.existingConnIdsMap.keys()) {
            const domainMap = this.existingConnIdsMap.get(domainId);
            if (!domainMap) continue;
            for (const [peer, connectionId] of domainMap) {
                if (connectionId === connId) {
                    domainMap.delete(peer);
                }
            }
        }
    }

    public getGameClientPeerId(): number {
        return 0;
    }

    public getGameClientConnId(): number {
        for (const domainId of this.existingConnIdsMap.keys()) {
            const peerIdConnIdMap = this.existingConnIdsMap.get(domainId);
            if (peerIdConnIdMap) {
                const connId = peerIdConnIdMap.get(0);
                if (connId !== undefined) {
                    return connId;
                }
            }
        }
        // this.delegate.log(CSD.LOG_ERROR, `Game client is not connected on any connid existingConnIdMap: ${this.existingConnIdsMap}`);
        throw new Error('Game client is not connected on any connid existingConnIdMap: ' + this.existingConnIdsMap);
    }

    public getExistingConnIdsMap(): Map<number, Map<number, number>> {
        return this.existingConnIdsMap;
    }

    public getDomainConnMap(): Map<number, number> {
        return this.domainConnMap;
    }

    public removePeerFromDomain(peerID: number, domainID: number): void {
        const peerIdConnIdMap = this.existingConnIdsMap.get(domainID);
        if (peerIdConnIdMap) {
            peerIdConnIdMap.delete(peerID);
            // this.delegate.log(CSD.LOG_INFO, `PeerId: ${peerID} removed from domain: ${domainID}`);
            if (peerIdConnIdMap.size === 0) {
                this.existingConnIdsMap.delete(domainID);
            }
        }
    }
}

// Assuming CSD is an object containing constants
// For example:
//   const CSD = {
//     NON_GAMING: 1,
//     SOCIAL: 2,
//     REAL: 3,
//   };

// Usage example:
//   const strategy = new ConnectionHandlerStrategy();
//   const connectionId = strategy.lookupOrCreateConnectionId(CSD.SOCIAL, 123);
//   const connectionName = strategy.getConnectionName(connectionId, CSD.SOCIAL);
//   console.log(connectionName);
