import { ConfigExtended, IconsModel, ThemesContent } from './icons-model';

export class IconFastService {
    static getIconParameter(uniqueIconName: string, parameter: string) {
        const currentConfig: any = window;
        if (currentConfig) {
            const iconsData: any = <ConfigExtended>currentConfig.clientConfig.vnIcons.iconItems;
            const currentIconSet: ThemesContent = iconsData;
            const icons = currentIconSet.children ?? currentIconSet;
            if (icons) {
                const matchingIcon: IconsModel = icons.find((data) => data.name === uniqueIconName || data.iconName === uniqueIconName) || {
                    name: 'notfound',
                    iconName: '',
                    parameters: { urlId: '' },
                    image: { src: '' },
                };

                if (matchingIcon.name != 'notfound') {
                    return this.getAvailableValues(parameter, matchingIcon);
                }
            }
        }
        return '';
    }

    static getAvailableValues(parameter: string, icon: IconsModel) {
        switch (parameter) {
            case 'urlId':
                return icon.parameters?.urlId ?? icon.image?.src ?? icon.imageUrl;
            case 'size':
                return icon.parameters?.size ?? icon.size;
            case 'extraClass':
                return icon.parameters?.extraClass ?? icon.extraClass;
            case 'fillColor':
                return icon.parameters?.fillColor ?? icon.fillColor;
            case 'title':
                return icon.parameters?.title ?? icon.title;
            default:
                return '';
        }
    }

    static isValueHere(obj: any, searchValue: string): boolean {
        for (let key in obj) {
            if (obj[key] === searchValue) {
                return true;
            }
        }
        return false;
    }
}
