import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as ppBlog from './pp-blog-feed.component';

@Injectable({ providedIn: 'root' })
export class PPBlogFeedPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('ppblogtemplate', ppBlog.PPBlogFeedComponent);
        });
    }
}
