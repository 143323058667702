import { Message } from '../../message';

export class SNGJPRegistrationResponse extends Message {
    private MESSAGE_NAME: string = 'SNGJPRegistrationResponse';
    private sngJackpotID: number = 0;
    private status: number = 0;
    private sngJPRegistrationInfos: any[] | null = null;
    private sngjpRegistrationFailureDetails: any[] | null = null;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        status: number = 0,
        sngJPRegistrationInfos: any[] | null = null,
        sngjpRegistrationFailureDetails: any[] | null = null,
        conversationId: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.status = status;
        this.sngJPRegistrationInfos = sngJPRegistrationInfos;
        this.sngjpRegistrationFailureDetails = sngjpRegistrationFailureDetails;
        this.conversationId = conversationId;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getSngJPRegistrationInfos(): any[] | null {
        return this.sngJPRegistrationInfos;
    }

    setSngJPRegistrationInfos(sngJPRegistrationInfos: any[] | null) {
        this.sngJPRegistrationInfos = sngJPRegistrationInfos;
    }
    getSngjpRegistrationFailureDetails(): any[] | null {
        return this.sngjpRegistrationFailureDetails;
    }

    setSngjpRegistrationFailureDetails(sngjpRegistrationFailureDetails: any[] | null) {
        this.sngjpRegistrationFailureDetails = sngjpRegistrationFailureDetails;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
