// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class RabbitView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    EventDispatcher: any;
    constructor() {
        super();
    }
    createView = function (EventDispatcher, vm) {
        this.EventDispatcher = EventDispatcher;
        this.viewModel = vm;
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            portAlignX: 'RIGHT',
            portAlignY: 'BOTTOM',
            EventDispatcher: this.EventDispatcher,
        });
        this.blackBg = this.displayUtil.getRectangleSprite(window.innerWidth / this.scaleX, window.innerHeight / this.scaleY, '#000000');
        this.displayUtil.setObjectPosition(this.blackBg, 0, 0);
        this.addChild(this.blackBg);
        this.blackBg.alpha = 0;
        this.blackBg.visible = false;
        this.dragMove = false;
        this.blackBg.buttonMode = false;
        this.displayUtil.setButtonActive(this.blackBg, true);
        this.fontProps = uiConfig.font.props;
        this.visible = true;
        this.createRabbit();
        this.setSize(720, 1280);
        // this.displayUtil.setContainerScaleFitToScreen(this);
        // this.resizeView();
    };
    createRabbit = function () {
        //create diamond icon and total diamond balance
        this.diamondIcon = this.displayUtil.getSprite('diamond');
        this.addChild(this.diamondIcon);
        this.diamondIcon.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.diamondIcon, 641, 1113);
        const totalBalance = this.viewModel.gameService.getDiamondBalance();
        this.diamondText = this.displayUtil.getTextField(this.fontProps.diamondTextCopy);
        this.diamondIcon.addChild(this.diamondText);
        this.diamondText.text = totalBalance == undefined ? 0 : CommonGameProperties.facade['kFormatter'](totalBalance);
        this.displayUtil.setObjectPosition(this.diamondText, 26, -11);
        // create diamond value for rabbit
        this.reqDiamondText = this.displayUtil.getTextField(this.fontProps.diamondText);
        this.addChild(this.reqDiamondText);
        this.reqDiamondText.text = 0;
        this.reqDiamondText.visible = false;
        this.reqDiamondText.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.reqDiamondText, 605, 1010);
        // start rabbit idle
        this.rabbitIdleStage = this.displayUtil.getSprite('rabbit15');
        this.rabbitIdleStage.x = 586;
        this.rabbitIdleStage.y = 941;
        this.addChild(this.rabbitIdleStage);
        this.rabbitIdleStage.visible = false;
        this.displayUtil.addInteraction(this.rabbitIdleStage, this.onRabbitBtnClick.bind(this));
        this.displayUtil.setClickable(this.rabbitIdleStage, true);
        // rabbit entry ani start
        const requiredAnimObj = 'selfRabbitEntryAni';
        this.animatedRabbitEntry = this.displayUtil.getSpriteDetails(requiredAnimObj);
        this.rabbitEntry = this.displayUtil.getCustomAnimatedSprite(this.animatedRabbitEntry);
        this.rabbitEntry.loop = false;
        this.rabbitEntry.visible = false;
        this.rabbitEntry.x = 586;
        this.rabbitEntry.y = 941;
        this.addChild(this.rabbitEntry);
        // rabbit exit ani start
        const requiredExitAnimObj = 'selfRabbitExitAni';
        this.animatedRabbitExit = this.displayUtil.getSpriteDetails(requiredExitAnimObj);
        this.rabbitExit = this.displayUtil.getCustomAnimatedSprite(this.animatedRabbitExit);
        this.rabbitExit.loop = false;
        this.rabbitExit.visible = false;
        this.rabbitExit.x = 586;
        this.rabbitExit.y = 941;
        this.addChild(this.rabbitExit);
        // rabbit daimond transfer ani start
        const requiredDiamondAnimObj = 'selfRabbitDiamondAni';
        this.animatedDiamondRabbit = this.displayUtil.getSpriteDetails(requiredDiamondAnimObj);
        this.animatedDiamonds = this.displayUtil.getCustomAnimatedSprite(this.animatedDiamondRabbit);
        this.animatedDiamonds.loop = false;
        this.animatedDiamonds.visible = false;
        this.animatedDiamonds.x = 586;
        this.animatedDiamonds.y = 941;
        this.addChild(this.animatedDiamonds);
    };
    onRabbitBtnClick = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REQ_ENABLE_RABBIT);
        if (this.rabbitIdleStageTimer) {
            clearTimeout(this.rabbitIdleStageTimer);
        }
        this.rabbitIdleStage.visible = false;
        this.reqDiamondText.visible = false;
        this.animatedDiamonds.visible = true;
        this.animatedDiamonds.gotoAndPlay(0);
        this.animatedDiamonds.onComplete = function () {
            this.animatedDiamonds.visible = false;
            this.rabbitExit.visible = true;
            this.rabbitExit.gotoAndPlay(0);
        }.bind(this);
    };
    updateDiamondBalance = function (diamondBalance) {
        this.diamondText.text = diamondBalance == undefined ? 0 : CommonGameProperties.facade['kFormatter'](diamondBalance);
    };
    showRabbit = function (rabbitValue) {
        if (this.rabbitKillTimer) {
            clearTimeout(this.rabbitKillTimer);
        }
        if (this.rabbitEntry) {
            this.rabbitEntry.stop();
            this.rabbitEntry.visible = false;
        }
        if (this.rabbitExit) {
            this.rabbitExit.stop();
            this.rabbitExit.visible = false;
        }
        if (this.rabbitIdleStage) {
            this.rabbitIdleStage.visible = false;
        }
        if (this.animatedDiamonds) {
            this.animatedDiamonds.stop();
            this.animatedDiamonds.visible = false;
        }
        // if (rabbitValue != undefined && rabbitValue > 999) {
        //   this.reqDiamondText.x = 591;
        // }else if (rabbitValue != undefined && rabbitValue > 99) {
        //   this.reqDiamondText.x = 604;
        // }else if (rabbitValue != undefined && rabbitValue > 9) {
        //   this.reqDiamondText.x = 618;
        // }else {
        //   this.reqDiamondText.x = 625;
        // }
        this.reqDiamondText.text = rabbitValue;
        if (Number(this.diamondText.text) != 0) {
            if (this.rabbitEntry) {
                this.rabbitEntry.visible = true;
                this.rabbitEntry.gotoAndPlay(0);
            }
            this.rabbitEntry.onComplete = function () {
                this.rabbitEntry.visible = false;
                this.rabbitIdleStage.visible = true;
                this.reqDiamondText.visible = true;
                this.rabbitIdleStageTimer = setTimeout(
                    function () {
                        this.rabbitIdleStage.visible = false;
                        this.reqDiamondText.visible = false;
                        this.rabbitExit.visible = true;
                        this.rabbitExit.gotoAndPlay(0);
                    }.bind(this),
                    2000,
                );
            }.bind(this);
        }
        // below timeout for removing rabbit when lock screen
        this.rabbitKillTimer = setTimeout(
            function () {
                this.rabbitEntry.stop();
                this.rabbitEntry.visible = false;
                this.rabbitExit.stop();
                this.rabbitExit.visible = false;
                this.rabbitIdleStage.visible = false;
                this.animatedDiamonds.stop();
                this.animatedDiamonds.visible = false;
            }.bind(this),
            4000,
        );
    };
    showBroadcastRabbit = function (seatno) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_OTHER_PLAYER_RABIT, seatno);
    };
    resetGame = function () {
        if (this.rabbitEntry) {
            this.rabbitEntry.stop();
            this.rabbitEntry.visible = false;
        }
        if (this.rabbitExit) {
            this.rabbitExit.stop();
            this.rabbitExit.visible = false;
        }
        if (this.rabbitIdleStage) {
            this.rabbitIdleStage.visible = false;
        }
        if (this.animatedDiamonds) {
            this.animatedDiamonds.stop();
            this.animatedDiamonds.visible = false;
        }
    };
    resizeView = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        this.scale.set(scaleXValue, scaleYValue);
    };
}
