import { Message } from '../../message';

export class SNGJPBountyInfo extends Message {
    private MESSAGE_NAME: string = 'SNGJPBountyInfo';
    private bountyPlayerScrName: string | null = null;
    private bountyAmount: number = 0;
    private bountyWinnersInfo: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        bountyPlayerScrName: string | null = null,
        bountyAmount: number = 0,
        bountyWinnersInfo: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.bountyPlayerScrName = bountyPlayerScrName;
        this.bountyAmount = bountyAmount;
        this.bountyWinnersInfo = bountyWinnersInfo;
    }

    getBountyPlayerScrName(): string | null {
        return this.bountyPlayerScrName;
    }

    setBountyPlayerScrName(bountyPlayerScrName: string | null) {
        this.bountyPlayerScrName = bountyPlayerScrName;
    }
    getBountyAmount(): number {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: number) {
        this.bountyAmount = bountyAmount;
    }
    getBountyWinnersInfo(): any[] | null {
        return this.bountyWinnersInfo;
    }

    setBountyWinnersInfo(bountyWinnersInfo: any[] | null) {
        this.bountyWinnersInfo = bountyWinnersInfo;
    }
}
