<div class="tile-card-container" (click)="selectedCard(cardIndex)" [attr.index]="cardIndex">
    <div class="tile-card-header">
        <h4 class="title-card-title">
            <small>{{ 'PARTY_POKER_GC_GAMELOBBY_BLINDSCOL' | translate }}:</small> {{ tileData.smallBlindAmount | formatCurrencyPipe: 'USD' }}/{{
                tileData.bigBlindAmount | formatCurrencyPipe: 'USD'
            }}
        </h4>
    </div>
    <div class="tile-card-body">
        <div class="tile-stakes-ante">
            <span class="ff-stakes">{{ getLimitType(tileData.limitType) }} {{ getGameType(tileData.gameType) }}</span>
        </div>
        <div class="tile-card-buyIn-container">
            <div class="tile-card-buyIn-background">
                <svg viewBox="0 0 154 86" fill="none">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M77.0352 85.2537C119.356 85.2537 153.663 67.1971 153.663 44.9232C153.663 22.6493 119.356 4.59277 77.0352 4.59277C34.7148 4.59277 0.407349 22.6493 0.407349 44.9232C0.407349 67.1971 34.7148 85.2537 77.0352 85.2537Z"
                        fill="url(#paint0_linear_1050_7894)" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M77.0353 81.2205C119.356 81.2205 153.663 63.1639 153.663 40.89C153.663 18.6161 119.356 0.55957 77.0353 0.55957C34.7149 0.55957 0.407471 18.6161 0.407471 40.89C0.407471 63.1639 34.7149 81.2205 77.0353 81.2205Z"
                        fill="url(#paint1_linear_1050_7894)" />
                    <g filter="url(#filter0_ii_1050_7894)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M77.7073 78.5318C117.429 78.5318 149.63 61.679 149.63 40.89C149.63 20.1011 117.429 3.24829 77.7073 3.24829C37.9855 3.24829 5.78467 20.1011 5.78467 40.89C5.78467 61.679 37.9855 78.5318 77.7073 78.5318Z"
                            fill="url(#paint2_linear_1050_7894)" />
                    </g>
                    <defs>
                        <filter
                            id="filter0_ii_1050_7894"
                            x="5.78467"
                            y="3.24829"
                            width="143.845"
                            height="79.321"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2.69171" />
                            <feGaussianBlur stdDeviation="3.36463" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1050_7894" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4.03756" />
                            <feGaussianBlur stdDeviation="5.38341" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                            <feBlend mode="normal" in2="effect1_innerShadow_1050_7894" result="effect2_innerShadow_1050_7894" />
                        </filter>
                        <linearGradient
                            id="paint0_linear_1050_7894"
                            x1="153.663"
                            y1="4.59277"
                            x2="0.407349"
                            y2="4.59277"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#474747" />
                            <stop offset="0.487701" stop-color="#BBBBBB" />
                            <stop offset="1" stop-color="#4A4A4A" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_1050_7894"
                            x1="39.0633"
                            y1="42.9904"
                            x2="39.0287"
                            y2="82.961"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#494949" />
                            <stop stop-color="#4D4D4D" />
                            <stop offset="0.798353" stop-color="#EEEEEE" />
                            <stop offset="1" stop-color="#6F6F6F" />
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_1050_7894"
                            x1="149.195"
                            y1="78.0768"
                            x2="149.195"
                            y2="3.24829"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#152244" />
                            <stop offset="1" stop-color="#324A8A" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="tile-card-buyIn-buyIn">
                <span style="height: 15px"
                    ><span class="tile-card-realName" *ngIf="tileData.realNameTable">{{ 'PARTY_POKER_MOBILE_CASH_REALNAME' | translate }}</span></span
                >
                <div class="tile-card-text">
                    <span>{{ 'PARTY_POKER_GC_BUYINCHIPS_TITLE' | translate }}</span>
                    <span>{{ getBuyIn(tileData.maxBuyInAmount, tileData.minBuyInAmount) | formatCurrencyPipe: 'USD' }}</span>
                </div>
                <span class="tile-card-maxSeats">{{ tileData.noOfSeats }} {{ 'PARTY_POKER_GC_QSLOBBY_FAVSEATS' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="tile-card-footer justify-content-center">
        <div class="tile-card-players">
            <span>{{ tileData.playersCount }}</span>
            <span class="players-text">{{ 'PARTY_POKER_GC_GAMETABLE_TRNYINFO_PLAYERS' | translate }}</span>
        </div>
    </div>
</div>
