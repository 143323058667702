import { Message } from '../../message';

export class AppFriendsListResponse extends Message {
    private MESSAGE_NAME: string = 'AppFriendsListResponse';
    private appFBFriendDetails: any[] | null = null;
    private otherFBFriendDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, appFBFriendDetails: any[] | null = null, otherFBFriendDetails: any[] | null = null) {
        super(reqServerPeerId);
        this.appFBFriendDetails = appFBFriendDetails;
        this.otherFBFriendDetails = otherFBFriendDetails;
    }

    getAppFBFriendDetails(): any[] | null {
        return this.appFBFriendDetails;
    }

    setAppFBFriendDetails(appFBFriendDetails: any[] | null) {
        this.appFBFriendDetails = appFBFriendDetails;
    }
    getOtherFBFriendDetails(): any[] | null {
        return this.otherFBFriendDetails;
    }

    setOtherFBFriendDetails(otherFBFriendDetails: any[] | null) {
        this.otherFBFriendDetails = otherFBFriendDetails;
    }
}
