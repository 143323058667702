import { Provider } from '@angular/core';

import { ClientConfigService, LazyClientConfigService } from '@frontend/vanilla/core';

import * as models from './client-config.models';
import {
    DownloadClientConfig,
    GTMClientConfig,
    GlobalClientConfig,
    HandHistoryClientConfig,
    HtmlHeadTagsClientConfig,
    MyGameClientConfig,
    PPLEventClientConfig,
    PWCNavigationClientConfig,
    PokerVCClientConfig,
    PromoHubClientConfig,
    RedirixRedirectsClientConfig,
    RibbonTimerCountdownClientConfig,
    TournamentWidgetsClientConfig,
    TournamentsCalendarClientConfig,
    UserInformationClientConfig,
    WebGameEngineClientConfig,
} from './client-config.models';

export function provideTopLevelClientConfigs(): Provider[] {
    return [
        { provide: models.NavigationClientConfig, deps: [ClientConfigService], useFactory: navigationConfigFactory },
        { provide: PokerVCClientConfig, deps: [ClientConfigService], useFactory: pokerVCClientConfigFactory },
        { provide: models.PokerHomePageClientConfig, deps: [ClientConfigService], useFactory: pokerHomePageClientConfigFactory },
        { provide: DownloadClientConfig, deps: [ClientConfigService], useFactory: downloadClientConfigFactory },
        { provide: TournamentsCalendarClientConfig, deps: [LazyClientConfigService], useFactory: tournamentsCalendarClientConfigFactory },
        { provide: GlobalClientConfig, deps: [LazyClientConfigService], useFactory: globalClientConfigFactory },
        { provide: HandHistoryClientConfig, deps: [LazyClientConfigService], useFactory: handhistoryClientConfigFactory },
        { provide: PromoHubClientConfig, deps: [ClientConfigService], useFactory: promoHubClientConfigFactory },
        { provide: models.TopNavigationConfig, deps: [LazyClientConfigService], useFactory: topNavigationConfigFactory },
        { provide: models.PromotionsClientConfig, deps: [LazyClientConfigService], useFactory: promotionsClientConfigFactory },
        { provide: models.vnDeviceConfig, deps: [LazyClientConfigService], useFactory: vnDeviceConfigFactory },
        { provide: models.RoutesClientConfig, deps: [LazyClientConfigService], useFactory: routesClientConfigFactory },
        { provide: models.SocialComponentClientConfig, deps: [ClientConfigService], useFactory: SocialComponentConfigFactory },
        { provide: RedirixRedirectsClientConfig, deps: [LazyClientConfigService], useFactory: redirixRedirectsConfigFactory },
        { provide: MyGameClientConfig, deps: [ClientConfigService], useFactory: myGameClientConfigFactory },
        { provide: models.RibbonTimerCountdownClientConfig, deps: [LazyClientConfigService], useFactory: ribbonTimerCountdownConfigFactory },
        { provide: models.HtmlHeadTagsClientConfig, deps: [LazyClientConfigService], useFactory: htmlHeadTagsClientConfigFactory },
        { provide: models.TournamentWidgetsClientConfig, deps: [LazyClientConfigService], useFactory: tournamentWidgetsConfigFactory },
        { provide: models.GTMClientConfig, deps: [LazyClientConfigService], useFactory: gtmClientConfigFactory },
        { provide: models.UserInformationClientConfig, deps: [LazyClientConfigService], useFactory: userInformationClientConfigFactory },
        { provide: PPLEventClientConfig, deps: [LazyClientConfigService], useFactory: pplEventClientConfigFactory },
        { provide: PWCNavigationClientConfig, deps: [ClientConfigService], useFactory: PWCNavigationConfigFactory },
        { provide: models.TopRibbonInjectionConfig, deps: [ClientConfigService], useFactory: topRibbonInjectionConfigFactory },
        { provide: WebGameEngineClientConfig, deps: [ClientConfigService], useFactory: webGameEngineClientConfigFactory },
    ];
}

export function navigationConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.NavigationClientConfig);
}

export function pokerVCClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.PokerVCClientConfig);
}
export function pokerHomePageClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.PokerHomePageClientConfig);
}

export function downloadClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(DownloadClientConfig);
}

export function promotionsClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(models.PromotionsClientConfig);
}

export function routesClientConfigFactory(routeConfigService: LazyClientConfigService) {
    return routeConfigService.get(models.RoutesClientConfig);
}

export function tournamentsCalendarClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(TournamentsCalendarClientConfig);
}

export function globalClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(GlobalClientConfig);
}

export function handhistoryClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(HandHistoryClientConfig);
}
export function promoHubClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(PromoHubClientConfig);
}

export function topNavigationConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.TopNavigationConfig);
}

export function topRibbonInjectionConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.TopRibbonInjectionConfig);
}

export function vnDeviceConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(models.vnDeviceConfig);
}

export function SocialComponentConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.SocialComponentClientConfig);
}

export function redirixRedirectsConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(RedirixRedirectsClientConfig);
}

export function myGameClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(MyGameClientConfig);
}

export function ribbonTimerCountdownConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(RibbonTimerCountdownClientConfig);
}

export function htmlHeadTagsClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(HtmlHeadTagsClientConfig);
}

export function tournamentWidgetsConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(TournamentWidgetsClientConfig);
}

export function gtmClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(GTMClientConfig);
}

export function userInformationClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(UserInformationClientConfig);
}

export function pplEventClientConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(PPLEventClientConfig);
}

export function PWCNavigationConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(PWCNavigationClientConfig);
}

export function webGameEngineClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(WebGameEngineClientConfig);
}
