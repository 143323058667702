import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { BaseLobbyConnector } from '../../pgBackendConnectors/baseLobbyConnector';
import { ClassMessageListener, MessageListener } from '../../pgBackendConnectors/messageListener';
import { PokerBackendConnector } from '../../pgBackendConnectors/pokerBackendConnector';
import { ResponseGroupCashDetailsList } from '../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponseGroupCashDetailsList';
import { ResponseTableDetailsList } from '../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponseTableDetailsList';
import { ResponseTableInfoDetailsList } from '../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponseTableInfoDetailsList';
import { PokerWebClientService } from '../../poker-web-client.service';
import { HelperClassService } from '../../service/helper';
import { updateCashLobbyData } from '../../store/lobby.store';

@Injectable()
@ClassMessageListener
export class CashGameService extends BaseLobbyConnector {
    helperClassService = new HelperClassService();
    tableInfoDetailsList: any = {};
    tableDetailsList: any = {};
    listOfTables = [] as any;
    private readonly logger: PokerLogger;
    constructor(
        public pokerWebClientService: PokerWebClientService,
        loggerFactory: LoggerFactory,
        private store: Store,
    ) {
        super(PokerBackendConnector.getInstance());
        this.logger = loggerFactory.getLogger('cashLobbyserviceLoggingComponent');
        //console.log('ls..CashGameService..')
    }

    @MessageListener('ResponseTableInfoDetailsList')
    ResponseTableInfoDetailsList(message: ResponseTableInfoDetailsList) {
        this.tableInfoDetailsList = message;
        this.logger.info('ls..ResponseTableInfoDetailsList....!' + JSON.stringify(message));
    }

    @MessageListener('ResponseTableDetailsList')
    ResponseTableDetailsList(message: ResponseTableDetailsList) {
        this.tableDetailsList = message;
        this.getFinalData();
        this.logger.info('ls..ResponseTableDetailsList....!' + JSON.stringify(message));
    }

    @MessageListener('ResponseGroupCashDetailsList')
    ResponseGroupCashDetailsList(message: ResponseGroupCashDetailsList) {
        let inItData: any[] = [];
        const tabIdTableInfoDetailsMap = message.getTabIdTableInfoDetailsMap(); // Store the map in a variable to avoid multiple function calls

        // Check if tabIdTableInfoDetailsMap is not null before iterating
        if (tabIdTableInfoDetailsMap) {
            Object.keys(tabIdTableInfoDetailsMap).forEach((key) => {
                inItData = [...inItData, ...tabIdTableInfoDetailsMap[key].tableInfos];
            });
        }

        // Similar handling for tabIdTableDetailsMap
        const tabIdTableDetailsMap = message.getTabIdTableDetailsMap();
        if (tabIdTableDetailsMap) {
            this.tableDetailsList['tabIdTableDetailsMap'] = tabIdTableDetailsMap;
        }

        // Set the tableInfos data and log the message
        this.tableInfoDetailsList['tableInfos'] = inItData;
        this.getFinalData();
        this.logger.info('ls..ResponseTableDetailsList....!' + JSON.stringify(message));
    }

    @MessageListener('LSTableDetailsDeltaUpdate')
    getDetailsDeltaUpdate(message: any) {
        this.logger.info('ls..getDetailsDeltaUpdate....!' + JSON.stringify(message));
        //console.log('ls..getDetailsDeltaUpdate:: ', message);
        this.checkForDeltaUpdates(message);
    }

    getFinalData() {
        let tableList = [] as any;
        this.tableInfoDetailsList = this.tableInfoDetailsList['tableInfos'].map((obj) => ({ ...obj, tableIds: [], tableIdWithPlayerCount: [] }));

        Object.values(this.tableDetailsList['tabIdTableDetailsMap']).forEach((value: any) => {
            value['tables'].forEach((table) => {
                const tableDetails = _.find(this.tableInfoDetailsList, { tableInfoId: table.tableInfoId });
                if (tableDetails) {
                    const detailsObj = {};
                    detailsObj['tableId'] = table.tableId;
                    detailsObj['playerCount'] = table.playerCount;

                    tableDetails['playersCount'] = table.playerCount;
                    tableDetails['formattedBuyIn'] = tableDetails.maxBuyInAmount > 0 ? tableDetails.maxBuyInAmount : tableDetails.minBuyInAmount;
                    tableDetails['tableIds'].push(table.tableId);
                    tableDetails['tableIdWithPlayerCount'].push(detailsObj);
                    tableDetails['gameTypeIdString'] = this.helperClassService.getGameTypeIdString(tableDetails.gameTypeId);
                    tableDetails['text1'] = tableDetails.formattedBuyIn;
                    tableDetails['text2'] = tableDetails.formattedBuyIn;
                    tableDetails['limitType'] = this.helperClassService.getLimitType(tableDetails.tableLimitType);
                    tableList.push(tableDetails);
                }
            });
        });
        tableList = _.uniqBy(tableList, 'tableInfoId');
        this.store.dispatch(updateCashLobbyData(_.sortBy(tableList, [(obj) => obj.formattedBuyIn])));
        this.listOfTables = _.sortBy(tableList, [(obj) => obj.formattedBuyIn]);
    }

    checkForDeltaUpdates(data: any) {
        this.logger.info('checkForDeltaUpdates....!' + JSON.stringify(data));
        //console.log('@@@@..DeltaUpdates', data);
        data.deltaEvents.forEach((value) => {
            // LSDeletedTableDetails = 86025
            if (value.classId === 86025) {
                this.listOfTables.forEach((table) => {
                    table.tableIdWithPlayerCount.forEach((obj, index) => {
                        if (obj.tableId === value.tableId) {
                            _.drop(table.tableIdWithPlayerCount, index);
                        }
                    });
                    if (table.tableIds.includes(value.tableId)) {
                        table.tableIds = table.tableIds.filter((e) => e !== value.tableId);
                    }
                });
                this.store.dispatch(updateCashLobbyData(this.listOfTables));
            }
            // LSTableOccupancyChange = 86029
            else if (value.classId === 86029) {
                this.listOfTables.forEach((table) => {
                    let count = 0;
                    table.tableIdWithPlayerCount.forEach((obj) => {
                        if (obj.tableId === value.tableId) {
                            obj.playerCount = value.playerCount;
                        }
                        count += obj.playerCount;
                    });
                    table['playersCount'] = count;
                });
                this.store.dispatch(updateCashLobbyData(this.listOfTables));
            }
            // "LSTableDetails" = 86024
            else if (value.classId === 86024) {
                this.listOfTables.forEach((table) => {
                    if (table.tableInfoId === value.tableInfoId) {
                        if (table.tableIds.includes(value.tableId)) {
                            let count = 0;
                            table.tableIdWithPlayerCount.forEach((obj) => {
                                if (obj.tableId === value.tableId) {
                                    obj.playerCount = value.playerCount;
                                }
                                count += obj.playerCount;
                            });
                            table['playersCount'] = count;
                        } else {
                            table['tableIds'].push(value.tableId);
                            table.tableIdWithPlayerCount.push({ tableId: value.tableId, playerCount: value.playerCount });
                        }
                    }
                });
                this.store.dispatch(updateCashLobbyData(this.listOfTables));
            }
        });
    }
}
