import { Message } from '../../message';

export class CurrencyRollback extends Message {
    private MESSAGE_NAME: string = 'CurrencyRollback';
    private cause: number = 0;
    private accountBalance: number = 0;
    private currencyList: any[] | null = null;
    private fallbackCurrency: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        cause: number = 0,
        accountBalance: number = 0,
        currencyList: any[] | null = null,
        fallbackCurrency: string | null = null,
    ) {
        super(reqServerPeerId);
        this.cause = cause;
        this.accountBalance = accountBalance;
        this.currencyList = currencyList;
        this.fallbackCurrency = fallbackCurrency;
    }

    getCause(): number {
        return this.cause;
    }

    setCause(cause: number) {
        this.cause = cause;
    }
    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getCurrencyList(): any[] | null {
        return this.currencyList;
    }

    setCurrencyList(currencyList: any[] | null) {
        this.currencyList = currencyList;
    }
    getFallbackCurrency(): string | null {
        return this.fallbackCurrency;
    }

    setFallbackCurrency(fallbackCurrency: string | null) {
        this.fallbackCurrency = fallbackCurrency;
    }
}
