import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class ThrowAnimationView extends PIXI.Container {
    currenttable;
    gameService;
    displayUtil = new DisplayUtil();
    parentFacade;
    constructor(gs, facade) {
        super();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
        this.gameService = gs;
        this.currenttable = 'table' + this.gameService.getPeerId();
        this.parentFacade = facade;
    }
    createView = function (EventDispatcher, gameService, maxNoOfSeats) {
        this.EventDispatcher = EventDispatcher;
        this.throwEmojiArr = [];
        this.throwEmojiNewArr = [];
        this.gameService = gameService;
        this.playerIconPositions =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].playersUIData.playerIconPositions;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.seatPositions = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.backCardsAnimConfig =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].backCardsAnimConfig;
        this.playerViewContainerPos = this.positionConfig.playersUIData.playerViewContainerPos;
        this.playerIconPositions = this.positionConfig.playersUIData.playerIconPositions;
        this.fontProps = uiConfig.font.props;
        this.maxNoOfSeats = maxNoOfSeats;
        // this.createThrowAnimationContainers(maxNoOfSeats);
        // this.individualCardContainer.setSize(720, 1280);
    };
    createThrowAnimationContainers = function (maxNoOfSeats) {
        for (let i = 0; i < maxNoOfSeats; i++) {
            this.individualCardContainer = this.displayUtilgetContainer();
            this.individualCardContainer.name = 'individualCardContainer' + i;
            this.throwAnimationContainer.addChild(this.individualCardContainer);
            this.throwEmojiNewArr.push(this.individualCardContainer);
            const seatAlign = this.getSeatAlignmentforGap(i, maxNoOfSeats);
            // console.log(seatAlign + 'seatAlign' + " " + i );
            if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignX: 'LEFT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignX: 'RIGHT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_TOP) {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignY: 'TOP',
                    EventDispatcher: this.EventDispatcher,
                });
            } else {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignY: 'BOTTOM',
                    EventDispatcher: this.EventDispatcher,
                });
            }
            this.individualCardContainer.setSize(145, 335.5);
        }
    };
    // to get seatalignment as left / center / right
    getSeatAlignmentforGap = function (clientSeatNo, maxSeats) {
        if (clientSeatNo == uiConfig.selfUserClientPosInd) return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
        const centerSeatNo = maxSeats / 2;
        if (centerSeatNo == clientSeatNo) return serverIdConstants.SEAT_ALIGNMENT_TOP;
        return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    };
    checkForThrowPos = function (flag) {
        this.shiftEmojiPos = flag;
        // if(this.throwEmojiNewArr.length > 0 && this.throwEmojiNewArr[0].toSeatNo == uiConfig.selfUserClientPosInd){
        //   this.throwEmojiNewArr[0].x = 200,
        //   this.throwEmojiNewArr[0].y = 1115,
        //   this.throwEmojiNewArr[0].scale.set(0.5);
        // }
    };
    onThrowAnimation = function (obj) {
        //var spriteName = "diamond" + obj.animationId;
        //Throw All to be confirmed
        // if(obj.toPlayerSeatNo == "" || obj.toPlayerSeatNo == undefined)
        //   this.throwAll(obj.fromPlayerSeatNo, playerCount, obj.animationId);
        // else
        this.doThrowAnimation(obj.fromPlayerSeatNo, obj.toPlayerSeatNo, obj.animationId);
    };
    throwAll = function (fromPlayerSeatNo, playerCount, animationId) {
        for (let i = 0; i < playerCount; i++) {
            if (fromPlayerSeatNo != i) this.doThrowAnimation(fromPlayerSeatNo, i, animationId);
        }
    };
    doThrowAnimation = function (fromPlayerSeatNo, toPlayerSeatNo, animationId) {
        if (this.throwAnimationContainer) {
            this.removeChild(this.throwAnimationContainer);
        }
        this.throwAnimationContainer = this.displayUtil.getContainer();
        this.throwAnimationContainer.name = 'ThrowAnimationViewContainer';
        this.addChild(this.throwAnimationContainer);
        this.displayUtil.setObjectPosition(this.throwAnimationContainer, 0, 0);
        const throwStaticArr = [
            'fishingStatic',
            'emojiStatic',
            'pigeon_1',
            'kiss_1',
            'target_1',
            'boxing0001',
            'egg0001',
            'cigar_1',
            'Thief_1',
            'waving_1',
            'taxi_1',
            'ChampagneStatic',
            'goat_1',
        ];
        const defaultthrow = 'defaultthrow' + this.throwAnimationContainer.children.length;
        const throwAnimationObj = 'throwAnimationObj' + +this.throwAnimationContainer.children.length;
        this.setThrowAnimation(defaultthrow, fromPlayerSeatNo, throwStaticArr[animationId - 1], false);
        this.setThrowAnimation(throwAnimationObj, toPlayerSeatNo, 'throwAni' + animationId, true);
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.throwSound, false);
        gsap.to(this[defaultthrow], 0.3, {
            rotation: 2 * Math.PI,
        });
        gsap.to(this[defaultthrow], 0.65, {
            x: this[throwAnimationObj].x,
            y: this[throwAnimationObj].y,
            onComplete: function () {
                this[throwAnimationObj].visible = true;
                this.throwAniVisibility = true;
                CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps['throwAni' + animationId], false);
                this[throwAnimationObj].play();
                if (toPlayerSeatNo == uiConfig.selfUserClientPosInd) {
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_THROW_ANIM, this[throwAnimationObj]);
                }
                if (this.isEmojiTabEnabled) {
                    if (
                        !(uiConfig['tableConfig' + this.gameService.peerId] && uiConfig['tableConfig' + this.gameService.peerId].playerOptionsVisible)
                    ) {
                        this[throwAnimationObj].visible = false;
                    }
                } else {
                    this[throwAnimationObj].visible = true;
                }
                this[defaultthrow].visible = false;
                this.throwAnimationContainer.removeChild(this[defaultthrow]);
                this[throwAnimationObj].onComplete = function () {
                    this[throwAnimationObj].visible = false;
                    this.throwAniVisibility = false;
                    this[throwAnimationObj].destroy();
                    this.throwAnimationContainer.removeChild(this[throwAnimationObj]);
                }.bind(this);
            }.bind(this),
            ease: 'slow(0.1, 0.7, false)',
        });
    };
    setThrowAnimation = function (valueName, seatNo, animationId, isThrow) {
        if (animationId == undefined || animationId == null) {
            return;
        }
        let adjX = 0,
            adjY = 0,
            isChangeSize = false;
        if (this[valueName]) {
            this.throwAnimationContainer.removeChild(this[valueName]);
        }
        if (!isThrow) {
            this[valueName] = this.displayUtil.getSprite(animationId);
        } else {
            const animationSeq = this.displayUtil.getSpriteDetails(animationId);
            //Tail Animationss
            this[valueName] = this.displayUtil.getCustomAnimatedSprite(animationSeq);
            this[valueName].visible = false;
            this.throwAniVisibility = false;
            this[valueName].loop = false;
            if (seatNo == uiConfig.selfUserClientPosInd) {
                if (uiConfig['tableConfig' + this.gameService.peerId] && uiConfig['tableConfig' + this.gameService.peerId].playerOptionsVisible) {
                    adjX = 155;
                    adjY = -125;
                    isChangeSize = true;
                }
            }
        }
        this.throwAnimationContainer.addChild(this[valueName]);
        this[valueName].anchor.set(0.5);
        this[valueName].name = valueName;
        const seatAlign = this.getSeatAlignmentforGap(seatNo, this.maxNoOfSeats);
        if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
            ResponsiveContainer.call(this[valueName], {
                portX: this.playerViewContainerPos.x[seatNo] + this.playerIconPositions.x[seatNo] - adjX,
                portY: this.playerViewContainerPos.y[seatNo] + this.playerIconPositions.y[seatNo] - adjY,
                portAlignX: 'LEFT',
                EventDispatcher: this.EventDispatcher,
            });
        } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            ResponsiveContainer.call(this[valueName], {
                portX: this.playerViewContainerPos.x[seatNo] + this.playerIconPositions.x[seatNo] - adjX,
                portY: this.playerViewContainerPos.y[seatNo] + this.playerIconPositions.y[seatNo] - adjY,
                portAlignX: 'RIGHT',
                EventDispatcher: this.EventDispatcher,
            });
        } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_TOP) {
            ResponsiveContainer.call(this[valueName], {
                portX: this.playerViewContainerPos.x[seatNo] + this.playerIconPositions.x[seatNo] - adjX,
                portY: this.playerViewContainerPos.y[seatNo] + this.playerIconPositions.y[seatNo] - adjY,
                portAlignY: 'TOP',
                EventDispatcher: this.EventDispatcher,
            });
        } else {
            ResponsiveContainer.call(this[valueName], {
                portX: this.playerViewContainerPos.x[seatNo] + this.playerIconPositions.x[seatNo] - adjX,
                portY: this.playerViewContainerPos.y[seatNo] + this.playerIconPositions.y[seatNo] - adjY,
                portAlignY: 'BOTTOM',
                EventDispatcher: this.EventDispatcher,
            });
            if (isChangeSize) {
                this[valueName].width = this[valueName].width / 1.6;
                this[valueName].height = this[valueName].height / 1.6;
            }
        }
    };
    clearAnimations = function () {
        for (const j in this.throwEmojiNewArr.children) {
            this.throwEmojiNewArr.removeChild(this.throwEmojiNewArr.children[j]);
        }
        this.throwEmojiArr = [];
        this.throwEmojiNewArr = [];
    };
    currentTablePeerId = function (peerId) {
        this.currTablePeerId = 'table' + peerId;
    };
    playThrowSounds = function () {};
    updateThrowAnim = function (isSelfEmojiTabOpen) {
        this.isEmojiTabEnabled = isSelfEmojiTabOpen;
    };
    hideThrowAnimation = function () {
        if (this.throwAnimationContainer) this.throwAnimationContainer.visible = false;
    };
}
