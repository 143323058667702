import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

import { HomepageGetAllResponse } from './homepage.models';

@Injectable({ providedIn: 'root' })
export class HomepageResourceService {
    dynamicContent: any;

    constructor(private api: PokerApiService) {}

    getAll(): Observable<HomepageGetAllResponse> {
        return this.api.get('homepage/getAll');
    }

    getAllTeasers(): Observable<any> {
        return this.api.get('homepage/GetAllTeasers');
    }

    setContentFromID(id: string) {
        const content = this.dynamicContent.viewModel.contentItems;
        const contentNeedsEnabling = content.some((item: any) => item.metadata.id.itemName.toLowerCase() === id.toLowerCase());
        if (contentNeedsEnabling) {
            return contentNeedsEnabling;
        }
    }
}
