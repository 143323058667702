import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pif from './poker-youtube-feed.component';

@Injectable({ providedIn: 'root' })
export class PokerYoutubeFeedPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('youtubefeedtemplate', pif.PokerYoutubeFeedComponent);
        });
    }
}
