import { Message } from '../../message';

export class ServerUtilityResponse extends Message {
    private MESSAGE_NAME: string = 'ServerUtilityResponse';
    private message: any | null = null;
    private responseType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, message: any | null = null, responseType: number = 0) {
        super(reqServerPeerId);
        this.message = message;
        this.responseType = responseType;
    }

    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getResponseType(): number {
        return this.responseType;
    }

    setResponseType(responseType: number) {
        this.responseType = responseType;
    }
}
