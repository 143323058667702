import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PCComponent, PCComponentHeaderV2Component, PageMatrixDirective } from '@frontend/vanilla/features/content';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import Swiper from 'swiper';
import { A11y, Autoplay, EffectCoverflow, Navigation, Pagination, Scrollbar, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    selector: 'pp-container',
    templateUrl: 'pp-container.component.html',
    standalone: true,
    imports: [
        CommonModule,
        PageMatrixDirective,
        // PageMatrixComponent,
        RouterModule,
        PCComponentHeaderV2Component,
        SwiperComponent,
    ],
})
export class PPContainerComponent extends PCComponent implements OnInit {
    @ViewChild('swiperComponent') componentRef!: ElementRef;

    @HostBinding() get class() {
        return this.createClass(this.item.name);
    }

    public instanceId: string = 'swiper_' + Math.round(Math.random() * 10000);
    public PPContainerItems: any;
    PPContainerConfig: any;
    PPContainerCustomConfig: any;
    PPContainerDynamicConfig: any;

    config: SwiperOptions = {
        modules: [Navigation, Pagination, Scrollbar, A11y],
    };

    public configCust: SwiperOptions = {
        modules: [Navigation, Pagination, EffectCoverflow, Autoplay, Virtual],
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        pagination: {
            type: 'bullets',
            clickable: true,
            el: `#${this.instanceId} .swiper-pagination-custom`,
        },
        spaceBetween: 0,
        navigation: {
            nextEl: `#${this.instanceId} .external-button-next`,
            prevEl: `#${this.instanceId} .external-button-prev`,
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        slidesPerGroup: 1,
        keyboard: true,
        rewind: true,
        observer: true,
    };

    swiper: Swiper;

    ngOnInit() {
        // this.instanceId = 'swiper_' + Math.round(Math.random()*10000);
        this.PPContainerConfig = this.item.wrapperConfig;
        this.PPContainerItems = this.item.items;
        this.PPContainerDynamicConfig = this.item.dynamicConfig;

        const elementSelectors = ['pagination.el', 'navigation.nextEl', 'navigation.prevEl'];

        elementSelectors.forEach((key) => {
            if (this.PPContainerDynamicConfig[key]) {
                this.PPContainerDynamicConfig[key] = `#${this.instanceId} ${this.PPContainerDynamicConfig[key]}`;
            }
        });

        this.PPContainerCustomConfig = parseProps(this.PPContainerDynamicConfig);

        // this.config = {};
        if (this.PPContainerConfig == 'carousel') {
            this.config = Object.assign(this.config, {
                direction: 'horizontal',
                spaceBetween: 0,
                speed: 1000,
                autoplay: true,
                loop: true,
                slidesPerView: 1,
                watchOverflow: true,
                pagination: {
                    el: `#${this.instanceId} .swiper-pagination`,
                    type: 'bullets',
                    clickable: true,
                },
                navigation: {
                    nextEl: `#${this.instanceId} .swiper-button-next`,
                    prevEl: `#${this.instanceId} .swiper-button-prev`,
                },
            });
        } else if (this.PPContainerConfig == 'custom') {
            this.config = Object.assign(this.config, this.PPContainerCustomConfig);
        } else {
            this.config = Object.assign(this.config, {
                spaceBetween: 0,
                speed: 0,
                pagination: {
                    type: 'bullets',
                    clickable: true,
                    el: `.swiper-pagination`,
                    renderBullet: (index: any, className: any) => {
                        return '<span class="' + className + '">' + this.PPContainerItems[index]?.title + '</span>';
                    },
                },
            });
        }
    }

    ngAfterViewInit() {
        this.swiper = new Swiper(this.componentRef.nativeElement, this.config);
    }
}

function castPropValue(val: string) {
    if (val === 'true') {
        return true;
    }
    if (val === 'false') {
        return false;
    }
    if (val.match(/^\-?[0-9]+(\.[0-9]+)?$/)) {
        return parseFloat(val);
    }

    return val;
}

function parseProps(originalProps: { [key: string]: string }) {
    if (originalProps) {
        const props: { [key: string]: any } = {};

        Object.keys(originalProps).forEach((key) => {
            const val: string = originalProps[key];
            if (key.indexOf('.') == -1) {
                props[key] = castPropValue(val);
                return;
            }

            let target = props;
            const keyParts = key.split('.');

            for (let i = 0; i < keyParts.length - 1; i++) {
                const keyName = keyParts[i];
                if (!target[keyName]) {
                    target[keyName] = {};
                }
                target = target[keyName];
            }

            target[keyParts[keyParts.length - 1]] = castPropValue(val);
        });
        return props;
    } else {
        return null;
    }
}
