import { Message } from '../../message';

export class PoolUserAllBalance extends Message {
    private MESSAGE_NAME: string = 'PoolUserAllBalance';
    private playMoneyBalance: number = 0;
    private realMoneyBalance: number = 0;
    private realMoneyBalanceGC: number = 0;
    private partnerBalanceAC: number = 0;
    private partnerBalanceGC: number = 0;
    private partnerPlayBalance: number = 0;
    private entryId: number = 0;
    private balanceFor: number = 0;
    private refreshBalanceTypes: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playMoneyBalance: number = 0,
        realMoneyBalance: number = 0,
        realMoneyBalanceGC: number = 0,
        partnerBalanceAC: number = 0,
        partnerBalanceGC: number = 0,
        partnerPlayBalance: number = 0,
        entryId: number = 0,
        balanceFor: number = 0,
        refreshBalanceTypes: number = 0,
    ) {
        super(reqServerPeerId);
        this.playMoneyBalance = playMoneyBalance;
        this.realMoneyBalance = realMoneyBalance;
        this.realMoneyBalanceGC = realMoneyBalanceGC;
        this.partnerBalanceAC = partnerBalanceAC;
        this.partnerBalanceGC = partnerBalanceGC;
        this.partnerPlayBalance = partnerPlayBalance;
        this.entryId = entryId;
        this.balanceFor = balanceFor;
        this.refreshBalanceTypes = refreshBalanceTypes;
    }

    getPlayMoneyBalance(): number {
        return this.playMoneyBalance;
    }

    setPlayMoneyBalance(playMoneyBalance: number) {
        this.playMoneyBalance = playMoneyBalance;
    }
    getRealMoneyBalance(): number {
        return this.realMoneyBalance;
    }

    setRealMoneyBalance(realMoneyBalance: number) {
        this.realMoneyBalance = realMoneyBalance;
    }
    getRealMoneyBalanceGC(): number {
        return this.realMoneyBalanceGC;
    }

    setRealMoneyBalanceGC(realMoneyBalanceGC: number) {
        this.realMoneyBalanceGC = realMoneyBalanceGC;
    }
    getPartnerBalanceAC(): number {
        return this.partnerBalanceAC;
    }

    setPartnerBalanceAC(partnerBalanceAC: number) {
        this.partnerBalanceAC = partnerBalanceAC;
    }
    getPartnerBalanceGC(): number {
        return this.partnerBalanceGC;
    }

    setPartnerBalanceGC(partnerBalanceGC: number) {
        this.partnerBalanceGC = partnerBalanceGC;
    }
    getPartnerPlayBalance(): number {
        return this.partnerPlayBalance;
    }

    setPartnerPlayBalance(partnerPlayBalance: number) {
        this.partnerPlayBalance = partnerPlayBalance;
    }
    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getBalanceFor(): number {
        return this.balanceFor;
    }

    setBalanceFor(balanceFor: number) {
        this.balanceFor = balanceFor;
    }
    getRefreshBalanceTypes(): number {
        return this.refreshBalanceTypes;
    }

    setRefreshBalanceTypes(refreshBalanceTypes: number) {
        this.refreshBalanceTypes = refreshBalanceTypes;
    }
}
