import { Message } from '../../message';

export class LSMTCTDetailsDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSMTCTDetailsDeltaUpdate';
    private tabId: number = 0;
    private snapShotTimeInNanos: number = 0;
    private deltaEvents: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tabId: number = 0, snapShotTimeInNanos: number = 0, deltaEvents: any[] | null = null) {
        super(reqServerPeerId);
        this.tabId = tabId;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.deltaEvents = deltaEvents;
    }

    getTabId(): number {
        return this.tabId;
    }

    setTabId(tabId: number) {
        this.tabId = tabId;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
}
