import { Message } from '../../message';

export class PlayerTableList extends Message {
    private MESSAGE_NAME: string = 'PlayerTableList';
    private tableIds: any[] | null = null;
    private ffEntryIds: any[] | null = null;
    private failedTableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor(reqServerPeerId: number = 0, tableIds: any[] | null = null, ffEntryIds: any[] | null = null, failedTableId: number = 0) {
        super(reqServerPeerId);
        this.tableIds = tableIds;
        this.ffEntryIds = ffEntryIds;
        this.failedTableId = failedTableId;
    }

    getTableIds(): any[] | null {
        return this.tableIds;
    }

    setTableIds(tableIds: any[] | null) {
        this.tableIds = tableIds;
    }
    getFfEntryIds(): any[] | null {
        return this.ffEntryIds;
    }

    setFfEntryIds(ffEntryIds: any[] | null) {
        this.ffEntryIds = ffEntryIds;
    }
    getFailedTableId(): number {
        return this.failedTableId;
    }

    setFailedTableId(failedTableId: number) {
        this.failedTableId = failedTableId;
    }
}
