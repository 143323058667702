import { Message } from '../../message';
import { LSDynamicLanguagePackUpdate } from './LSDynamicLanguagePackUpdate';

export class ResponseMTCTLobbyData extends Message {
    private MESSAGE_NAME: string = 'ResponseMTCTLobbyData';
    private mtctDetails: any[] | null = null;
    private dynaDetails: LSDynamicLanguagePackUpdate | null = null;
    private tourneyRequestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctDetails: any[] | null = null,
        dynaDetails: LSDynamicLanguagePackUpdate | null = null,
        tourneyRequestType: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctDetails = mtctDetails;
        this.dynaDetails = dynaDetails;
        this.tourneyRequestType = tourneyRequestType;
    }

    getMtctDetails(): any[] | null {
        return this.mtctDetails;
    }

    setMtctDetails(mtctDetails: any[] | null) {
        this.mtctDetails = mtctDetails;
    }
    getDynaDetails(): LSDynamicLanguagePackUpdate | null {
        return this.dynaDetails;
    }

    setDynaDetails(dynaDetails: LSDynamicLanguagePackUpdate | null) {
        this.dynaDetails = dynaDetails;
    }
    getTourneyRequestType(): number {
        return this.tourneyRequestType;
    }

    setTourneyRequestType(tourneyRequestType: number) {
        this.tourneyRequestType = tourneyRequestType;
    }
}
