export const CommonGameProperties = {
    langPack: {},
    mathUtil: {},
    dynaconConfigs: {},
    facade: {},
    primaryAssetsLoaded: false,
    secondaryAssetsLoaded: false,
    clienttoserver: {},
    expoAnime: [] as any,
    currencyConverter: {},
    numberFormatter: {},
    ssokey: {},
    webAssets: {},
};
