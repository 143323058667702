import { Injectable } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class HtmlHeadtagNavigationService {
    constructor(private deviceService: DeviceService) {}

    navigationFixHtmlTag() {
        // isMobile ? document.addEventListener('touchend', this.onTouchNav) : document.addEventListener('click', this.onTouchNav);s

        if (this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet) {
            document.addEventListener('touchend', this.onTouchNav);
        } else {
            document.addEventListener('click', this.onTouchNav);
        }
    }

    private onTouchNav(e: any) {
        const elClasses = (e.target.className && e.target.className.split(' ')) || [];
        if (elClasses.indexOf('pc-header') === -1) {
            return;
        }

        let parent = e.target.parentElement;
        let success = false;
        while (parent && parent !== window) {
            const classes = (parent.className && parent.className.split(' ')) || [];
            if (classes.indexOf('pc-menu') > -1 && (parent.tagName === 'VN-PC-MENU' || classes.indexOf('left-nav') > -1)) {
                success = true;
                break;
            }

            parent = parent.parentElement;
        }
        if (!success) {
            return;
        }

        Array.from(document.querySelectorAll('.pc-menu-items')).forEach(function (el) {
            el.classList.toggle('collapsed');
        });
    }
}
