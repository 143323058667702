import { Message } from '../../message';
import { GlobalWaitlistCriteria } from './GlobalWaitlistCriteria';

export class RequestUnjoinGlobalWaitlist extends Message {
    private MESSAGE_NAME: string = 'RequestUnjoinGlobalWaitlist';
    private waitlistCriteria: GlobalWaitlistCriteria | null = null;
    private requestId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, waitlistCriteria: GlobalWaitlistCriteria | null = null, requestId: number = 0) {
        super(reqServerPeerId);
        this.waitlistCriteria = waitlistCriteria;
        this.requestId = requestId;
    }

    getWaitlistCriteria(): GlobalWaitlistCriteria | null {
        return this.waitlistCriteria;
    }

    setWaitlistCriteria(waitlistCriteria: GlobalWaitlistCriteria | null) {
        this.waitlistCriteria = waitlistCriteria;
    }
    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
}
