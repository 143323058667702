import { Message } from '../../message';

export class LSCurrencyAmount extends Message {
    private MESSAGE_NAME: string = 'LSCurrencyAmount';
    private currencyCode: string | null = null;
    private currencyAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currencyCode: string | null = null, currencyAmount: number = 0) {
        super(reqServerPeerId);
        this.currencyCode = currencyCode;
        this.currencyAmount = currencyAmount;
    }

    getCurrencyCode(): string | null {
        return this.currencyCode;
    }

    setCurrencyCode(currencyCode: string | null) {
        this.currencyCode = currencyCode;
    }
    getCurrencyAmount(): number {
        return this.currencyAmount;
    }

    setCurrencyAmount(currencyAmount: number) {
        this.currencyAmount = currencyAmount;
    }
}
