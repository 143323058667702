import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProductService } from '@frontend/vanilla/core';

import { SubLobbyHeaderComponent } from '../../common/sub-lobby-header/sub-lobby-header.component';
import { SpinGameService } from '../../service/spin-game.service';

@Component({
    selector: 'pwc-spin-lobby',
    templateUrl: 'spin-lobby.component.html',
    imports: [CommonModule, RouterModule, SubLobbyHeaderComponent],
    providers: [SpinGameService],
    standalone: true,
})
export class SpinsLobbyComponent implements OnInit {
    snjpcardsData: any;
    apiBaseUrl: string;
    sngjpcards: any[];
    finalData: any;
    sngjpcard: [];
    gridView: boolean = false;
    buyinData: any;
    constructor(public productService: ProductService) {
        // this.spinGameService.requestSNGJPPlayNowUpdate(0,1);
        // this.spinGameService.RequestSNGJPPlayNow(this.sngjpcards.sngJackpotID,1,0)
        // this.spinGameService.RequestSNGJPPlayNow(307078695,1,0);
        // this.spinGameService.SNGJPRegistrationRequest(307078695,1,0,0,0);
    }
    ngOnInit(): void {
        this.apiBaseUrl = this.productService.getMetadata('poker').apiBaseUrl;
        //   setTimeout(() => {
        //     this.spinGameService.getPlayNowUpdateDetails().subscribe(data=>{
        //         // const updatedCards = data.sngJPCards.map((card) => {
        //         //   return { ...card };
        //         // });
        //         this.finalData  = data.sngJPCards;
        //         this.initSngJPCards();
        //     });
        // }, 1000);
        // this.spinGameService.getPlayNowBuyinDetails().subscribe(data=>{
        //   this.buyinData = data;
        // })
    }
    initSngJPCards() {
        this.sngjpcards = this.finalData.map((value: any) => {
            const formatedValue = '$' + (value.buyIn + value.tourneyFee) / 100;
            let carousalImg, tileImg, selectedTileImg, textTitleValue, payOutTypeValue, payOutCardText;
            /** loading all types of cards conditionally*/
            if (value.cardType === 1) {
                carousalImg =
                    value.imageId === 'goldCard'
                        ? 'pictures/images/poker-web-client/spins/carousalNormalGold.png'
                        : 'pictures/images/poker-web-client/spins/carousalNormal.png';
                tileImg =
                    value.imageId === 'goldCard'
                        ? 'pictures/images/poker-web-client/spins/tileNormalGold.png'
                        : 'pictures/images/poker-web-client/spins/tileNormal.png';
                selectedTileImg =
                    value.imageId === 'goldCard'
                        ? 'pictures/images/poker-web-client/spins/tileNormalGoldSelected.png'
                        : 'pictures/images/poker-web-client/spins/tileNormalSelected.png';
            } else if (value.cardType === 2 || value.cardType === 5) {
                carousalImg =
                    value.imageId === 'goldCard'
                        ? 'pictures/images/poker-web-client/spins/carousalUltraGold.png'
                        : 'pictures/images/poker-web-client/spins/carousalUltra.png';
                tileImg =
                    value.imageId === 'goldCard'
                        ? 'pictures/images/poker-web-client/spins/tileUltraGold.png'
                        : 'pictures/images/poker-web-client/spins/tileUltra.png';
                selectedTileImg =
                    value.imageId === 'goldCard'
                        ? 'pictures/images/poker-web-client/spins/tileUltraGoldSelected.png'
                        : 'pictures/images/poker-web-client/spins/tileUltraSelected.png';
            }
            const promotionalTitle = value.promotionalTitle ? value.promotionalTitle : '';
            const promotionalSubTitle = value.promotionalSubTitle ? value.promotionalSubTitle : '';
            const promotionalText: string = promotionalTitle + promotionalSubTitle;
            if (value.payOutType === 0) {
                textTitleValue = 'Win Up to';
                payOutTypeValue = true;
            } else {
                textTitleValue = promotionalText;
                payOutCardText = value.payOutType === 1 ? 'TICKET' : 'PACKAGE';
                payOutTypeValue = false;
            }
            value.slide = {
                isGoldCard: value.imageId === 'goldCard' ? true : false,
                selectedImg: selectedTileImg,
                img: carousalImg,
                imgCardView: tileImg,
                gameType: value.gameType === 0 ? 'NLH' : 'PLO',
                textTitle: textTitleValue,
                text1: value.winUptoAmount / 100,
                text2: (value.buyIn + value.tourneyFee) / 100,
                text3: formatedValue,
                ticketCount: 0,
                cardType: value.cardType,
                payOutType: payOutTypeValue,
                payOutCard: payOutCardText ? payOutCardText : '',
            };
            return value;
        });
    }
    // onCardSelect(i: any) {}
    // initSlick() {
    //   let index = this.currentCardSelectedIndex;
    //   setTimeout(() => {
    //     if ($("#slider")[0]) {
    //       $("#slider")[0].slick.slickGoTo(index);
    //     }
    //   }, 600);
    // }
}
