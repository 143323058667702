import { Message } from '../../message';

export class LSPrizeDetailsMessage extends Message {
    private MESSAGE_NAME: string = 'LSPrizeDetailsMessage';
    private prizeMessage: any | null = null;
    private tooltip: any[] | null = null;
    private ticketType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, prizeMessage: any | null = null, tooltip: any[] | null = null, ticketType: number = 0) {
        super(reqServerPeerId);
        this.prizeMessage = prizeMessage;
        this.tooltip = tooltip;
        this.ticketType = ticketType;
    }

    getPrizeMessage(): any | null {
        return this.prizeMessage;
    }

    setPrizeMessage(prizeMessage: any | null) {
        this.prizeMessage = prizeMessage;
    }
    getTooltip(): any[] | null {
        return this.tooltip;
    }

    setTooltip(tooltip: any[] | null) {
        this.tooltip = tooltip;
    }
    getTicketType(): number {
        return this.ticketType;
    }

    setTicketType(ticketType: number) {
        this.ticketType = ticketType;
    }
}
