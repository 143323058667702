import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class WinLow extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.WIN_LOW;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.WIN_LOW;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.playerStatus = this.getPlayerStatus();

        const ALL_POTS = [gameTable.MAIN_POT, ...gameTable.SIDE_POTS];

        let i = 0;
        let winAmount = this.amount;
        while (i <= ALL_POTS.length) {
            if (ALL_POTS[i].totalValue >= winAmount) {
                ALL_POTS[i].removeChip(new Chip(Number(winAmount)));
                break;
            } else {
                winAmount -= ALL_POTS[i].totalValue;
                ALL_POTS[i].removeChip(new Chip(Number(ALL_POTS[i].totalValue)));
            }
            i++;
        }
        player.highlightEvent();
        const winChipPos = Properties.propFile[gameTable.gameType][`CHIPPOS${this.seat}`].split('#');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTable.playersMap.forEach((player: any) => {
            gameTable.changeChipPos = new Point(winChipPos[0], winChipPos[1]);
        });
        player.chips = new Chip(Number(player.chips.value) + Number(this.amount));
        gameTable.playerWinAmount = this.amount;
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        const winChipPos = Properties.propFile[gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTable.playersMap.forEach((player: any) => {
            gameTable.changeChipPos = new Point(winChipPos[0], winChipPos[1]);
        });
        gameTable.pot.removeChip(new Chip(Number(this.amount)));

        player.chips = new Chip(Number(player.chips.value) - Number(this.amount));
        const totalchipvalue = gameTable.roundObjects;
        if (gameTable.gameType == '7CARDHEADSUP' || gameTable.gameType == 'TABLE6MAX' || gameTable.gameType == '7CARDSTUD') {
            for (let i = 5; i >= 0; i--) {
                if (gameTable.roundObjects.hasOwnProperty(i)) {
                    gameTable.MAIN_POT.totalValue = totalchipvalue[i];
                    gameTable.ROUND_POT.totalValue = gameTable.MAIN_POT.totalValue;
                    break;
                }
            }
        } else {
            for (let i = 3; i >= 0; i--) {
                if (gameTable.roundObjects.hasOwnProperty(i)) {
                    gameTable.MAIN_POT.totalValue = totalchipvalue[i];
                    break;
                }
            }
        }
        gameTable.changeChip = true;
    }
}
