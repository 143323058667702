import { Message } from '../../message';

export class AddedFreeRoll extends Message {
    private MESSAGE_NAME: string = 'AddedFreeRoll';
    private userName: string | null = null;
    private freeRollId: number = 0;
    private domain: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, userName: string | null = null, freeRollId: number = 0, domain: number = 0) {
        super(reqServerPeerId);
        this.userName = userName;
        this.freeRollId = freeRollId;
        this.domain = domain;
    }

    getUserName(): string | null {
        return this.userName;
    }

    setUserName(userName: string | null) {
        this.userName = userName;
    }
    getFreeRollId(): number {
        return this.freeRollId;
    }

    setFreeRollId(freeRollId: number) {
        this.freeRollId = freeRollId;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
}
