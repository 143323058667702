import { Message } from '../../message';

export class LSMTCTStatusChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSMTCTStatusChangeEvent';
    private mtctId: number = 0;
    private status: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, status: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.status = status;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
}
