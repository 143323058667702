// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class TableCreateView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    eventDispatcherGlobal = new EventDispatcherGlobal();
    EventDispatcher: any;
    userCurrencyCode;
    parentFacade;
    constructor(facade) {
        super();
        this.parentFacade = facade;
        this.userCurrencyCode = this.parentFacade.sessionDataModel.getUserprofile('accountCurrencyCode');
    }
    createView = function () {
        // this.sngJPPayoutInfoData = sngJPPayoutInfoData;
        this.fontProps = uiConfig.font.props;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.buttons;
        this.buyInObjectSet = {};
        this.tableIdjackpotInfoMap = {};
        this.addSameGameTextArr = {};
        this.CreateNewtableContainer = this.displayUtil.getContainer();
        this.addChild(this.CreateNewtableContainer);
        //this.mainLobbyTiles = JSON.parse(localStorage.getItem("Main_Lobby_Tiles"));
        this.mainLobbyTiles = CommonGameProperties.dynaconConfigs['webClientConfigs']['mainLobbyTiles'];
        // this.mainLobbyTiles = JSON.parse('{"spins_ultra":false,"SHOW_SNG_JACKPOT_EXPO_TAB":true,"spins":false}');
        //this.SNG_JACKPOT_TAB_MODE = JSON.parse(localStorage.getItem("SNG_JACKPOT_TAB_MODE"));
        this.SNG_JACKPOT_TAB_MODE = CommonGameProperties.dynaconConfigs['webClientConfigs']['snG_JACKPOT_TAB_MODE'];
        this.onResize();
    };
    createSameGameTabs = function () {
        this.CreateNewtableContainer.removeChild(this.addSameGameContainer);
        this.CreateNewtableContainer.removeChild(this.otherGameNavContainer);
        this.addSameGameContainer = this.displayUtil.getContainer();
        this.CreateNewtableContainer.addChild(this.addSameGameContainer);
        this.displayUtil.setObjectPosition(this.addSameGameContainer, 70, 200);
        this.addSameGameTitle = this.displayUtil.getTextField(this.fontProps.addSpinGameTableText);
        this.addSameGameContainer.addChild(this.addSameGameTitle);
        this.addSameGameTitle.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_COMMON_ADD_NEW_TABLE');
        this.addSameGameTitle.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.addSameGameTitle, 317, 46);
        for (let i = 0; i < this.parentFacade.activeTables.length; i++) {
            this.addSameGameTabBG = this.displayUtil.getSprite('tileGrey');
            this.addSameGameContainer.addChild(this.addSameGameTabBG);
            this.addSameGameTabBG.scale.x = 1.2; //addgame_spins,addgame_ff
            this.addSameGameTabBG.scale.y = 0.9; //addgame_spins,addgame_ff
            this.displayUtil.addInteraction(
                this.addSameGameTabBG,
                this.onClickConfirmationSpinGameTable.bind(this, this.parentFacade.activeTables[i]),
            );
            this.displayUtil.setClickable(this.addSameGameTabBG, true);
            this.displayUtil.setObjectPosition(
                this.addSameGameTabBG,
                this.positionConfig.addSameGameTabPos.x[i],
                this.positionConfig.addSameGameTabPos.y[i],
            );
            const currenttableId = this.parentFacade.activeTables[i];
            this.addSameGameTextArr[currenttableId] = this.displayUtil.getTextField(this.fontProps.addSpinGameTableText);
            this.addSameGameContainer.addChild(this.addSameGameTextArr[currenttableId]);
            if (this.buyInObjectSet[currenttableId]) this.addSameGameTextArr[currenttableId].text = this.buyInObjectSet[currenttableId];
            this.addSameGameTextArr[currenttableId].anchor.set(0.5);
            this.displayUtil.setObjectPosition(
                this.addSameGameTextArr[currenttableId],
                this.positionConfig.addSameGameTabPos.x[i] + 300,
                this.positionConfig.addSameGameTabPos.y[i] + 73,
            );
        }
        let showNormalSpin = false;
        let showUltraSpin = false;
        if ((this.mainLobbyTiles['spins-ultra'] == true && this.SNG_JACKPOT_TAB_MODE == 1) || this.SNG_JACKPOT_TAB_MODE == 3) {
            showNormalSpin = true;
        } else if (this.mainLobbyTiles['spins-ultra'] == true && this.SNG_JACKPOT_TAB_MODE == 2) {
            showUltraSpin = true;
        }
        this.otherGameNavContainer = this.displayUtil.getContainer();
        this.CreateNewtableContainer.addChild(this.otherGameNavContainer);
        const otherGameY = this.addSameGameContainer.height + this.positionConfig.otherGameLogoPos.y[this.parentFacade.activeTables.length - 1];
        this.displayUtil.setObjectPosition(this.otherGameNavContainer, 80, otherGameY);
        this.optionalTitle = this.displayUtil.getTextField(this.fontProps.addSpinGameTableText);
        this.otherGameNavContainer.addChild(this.optionalTitle);
        this.optionalTitle.text = 'OR';
        this.optionalTitle.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.optionalTitle, 300, 0);
        this.otherGameBtn1 = this.displayUtil.getSprite('addgame_expo_spins');
        this.otherGameBtn1.visible = this.mainLobbyTiles['show-sng-jackpot-expo-tab'];
        this.otherGameNavContainer.addChild(this.otherGameBtn1);
        this.displayUtil.addInteraction(this.otherGameBtn1, this.onClickSpinOverDrive.bind(this));
        this.displayUtil.setClickable(this.otherGameBtn1, true);

        this.otherGameBtn2 = this.displayUtil.getSprite('addgame_spins');
        this.otherGameBtn2.visible = showNormalSpin;
        this.otherGameNavContainer.addChild(this.otherGameBtn2);
        this.displayUtil.addInteraction(this.otherGameBtn2, this.onClickSpinGameTable.bind(this));
        this.displayUtil.setClickable(this.otherGameBtn2, true);

        this.otherGameBtn3 = this.displayUtil.getSprite('addgame_spins_ultra');
        this.otherGameBtn3.visible = showUltraSpin;
        this.otherGameNavContainer.addChild(this.otherGameBtn3);
        this.displayUtil.addInteraction(this.otherGameBtn3, this.onClickSpinGameTable.bind(this));
        this.displayUtil.setClickable(this.otherGameBtn3, true);

        this.otherGameBtn4 = this.displayUtil.getSprite('addgame_ff');
        this.otherGameBtn4.visible = true;
        this.otherGameNavContainer.addChild(this.otherGameBtn4);
        this.displayUtil.addInteraction(this.otherGameBtn4, this.onClickFFGameTable.bind(this));
        this.displayUtil.setClickable(this.otherGameBtn4, true);

        this.otherGameBtn5 = this.displayUtil.getSprite('addgame_cash');
        this.otherGameBtn5.visible = true;
        this.otherGameNavContainer.addChild(this.otherGameBtn5);
        this.displayUtil.addInteraction(this.otherGameBtn5, this.onClickCashGameTable.bind(this));
        this.displayUtil.setClickable(this.otherGameBtn5, true);
        const positionConfig = [
            { xPos: 40, yPos: 66 },
            { xPos: 225, yPos: 66 },
            { xPos: 400, yPos: 66 },
            { xPos: 40, yPos: 225 },
            { xPos: 225, yPos: 225 },
        ];
        let posIndex = 0;
        for (let i = 0; i < positionConfig.length; i++) {
            if (this['otherGameBtn' + (i + 1)].visible == true) {
                this.displayUtil.setObjectPosition(this['otherGameBtn' + (i + 1)], positionConfig[posIndex].xPos, positionConfig[posIndex].yPos);
                posIndex++;
            }
        }
    };
    createConfirmationPopup = function () {
        this.CreateNewtableContainer.removeChild(this.addSameGameContainer);
        this.closeButton = this.displayUtil.getButton('tileClose');
        this.CreateNewtableContainer.addChild(this.closeButton);
        this.displayUtil.addInteraction(this.closeButton, this.closeBtnClick.bind(this));
        this.displayUtil.setClickable(this.closeButton, true);
        this.displayUtil.setObjectPosition(this.closeButton, 299, 210);
        this.closeButton.visible = false;
        this.CreateAddConformTableBg = this.displayUtil.getSprite('tileBg');
        this.CreateNewtableContainer.addChild(this.CreateAddConformTableBg);
        this.displayUtil.setObjectPosition(this.CreateAddConformTableBg, 72, 360);
        this.visible = true;
        this.closeButton.visible = false;
    };
    //on removing, remove buttons, create new ones and refresh and clear the data
    removeTable = function (tableId) {
        this.createSameGameTabs();
        this.refreshTableCaptions();
        this.buyInObjectSet[tableId] = null;
        this.tableIdjackpotInfoMap[tableId + ''] = null;
        this.addSameGameTextArr[tableId] = null;
    };
    //on adding a new table, this gets called.
    setTableStatusData = function (tableData, tableId, gameTableType) {
        //, availableTickets){
        tableData.gameTableType = gameTableType;
        this.tableStatusData = tableData;
        this.createSameGameTabs();
        if (this.tableStatusData != null || this.ffTableData != null) {
            if (this.tableStatusData.tableCategory == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
                this.updateCaptionForTournaments(tableId);
            } else {
                this.ffBuyInValue = this.tableStatusData.maxBuyInAmount;
                if (this.ffBuyInValue == 0)
                    // for FL Holdem games, there's no cap on max buyin.
                    this.ffBuyInValue = this.tableStatusData.minBuyInAmount;
                let gameTableTypeVal = gameTableType == serverIdConstants.TABLE_TYPE_CASH ? ' Cash' : ' FF';
                gameTableTypeVal = tableData.gameTypeId == 7 ? ' SD' : gameTableTypeVal;
                this.finalBuyIn = CommonGameProperties.mathUtil['transform'](this.ffBuyInValue, this.tableStatusData.gameCurrency) + gameTableTypeVal;
            }
        }
        this.storeCaptionForTable(tableId, this.finalBuyIn);
        this.addSameGameTextArr[tableId].text = this.finalBuyIn;
        this.refreshTableCaptions();
    };
    refreshTableCaptions = function () {
        for (const tableId of this.parentFacade.activeTables) {
            if (this.addSameGameTextArr[tableId] && this.buyInObjectSet[tableId])
                this.addSameGameTextArr[tableId].text = this.buyInObjectSet[tableId];
        }
    };
    //only this stored capetion will be used later
    storeCaptionForTable = function (tableId, caption) {
        this.buyInObjectSet[tableId] = caption;
    };
    updateCaptionForTournaments = function (tableId) {
        let cardType = 1;
        if (this.tableIdjackpotInfoMap[tableId + ''] && this.tableIdjackpotInfoMap[tableId + ''].cardType)
            cardType = this.tableIdjackpotInfoMap[tableId + ''].cardType;
        this.buyInValue = this.tableStatusData.tourneyBuyIn + this.tableStatusData.tourneyEntryFee + this.tableStatusData.bountyFee;
        if (this.spinCardType == 3 || this.spinCardType == 4) {
            this.finalBuyIn = CommonGameProperties.mathUtil['transform'](this.buyInValue, this.tableStatusData.gameCurrency) + ' ' + 'Overdrive';
        } else {
            this.finalBuyIn =
                CommonGameProperties.mathUtil['transform'](this.buyInValue, this.tableStatusData.gameCurrency) +
                ' ' +
                (cardType == 2 ? 'ULTRA' : 'SPINS');
        }

        this.addSameGameTextArr[tableId].text = this.finalBuyIn;
        this.storeCaptionForTable(tableId, this.finalBuyIn);
    };
    onClickConfirmationSpinGameTable = function (tableId) {
        const message = {};
        message['className'] = 'AddSimilarGameTable';
        message['TableId'] = tableId;
        message['enableExpoSpin'] = true;
        message['gameCount'] = 1;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    onClickSameSpinGameTable = function () {
        this.addSameGameContainer.visible = false;
        this.otherGameNavContainer.visible = false;
    };
    onClickSpinGameTable = function () {
        if (this.otherGameBtn2.visible == true || this.otherGameBtn3.visible == true) {
            this.parentFacade.openLobbyFromGameTable();
        }
    };
    onClickSpinOverDrive = function () {
        // for now expo spins(this.otherGameBtn1) is not enabled disabling the below logic and will be enabled later
        // if (this.otherGameBtn1.visible == true) {
        //  this.this.parentFacade.openLobbyFromGameTable("spins-overdrive");
        // }
        this.showWidget();
        this.parentFacade.openLobbyFromGameTable('spins-overdrive');
    };
    onClickFFGameTable = function () {
        // for now spins Ultra(this.otherGameBtn3) is not enabled disabling the below logic and will be enabled later
        // if (this.otherGameBtn3.visible == true) {
        //   this.parentFacade.openLobbyFromGameTable("fast-forward");
        // }
        this.showWidget();
        this.parentFacade.openLobbyFromGameTable('fast-forward');
    };
    onClickCashGameTable = function () {
        // send here request to cash games lobby cards update
        if (this.otherGameBtn4.visible == true) {
            this.showWidget();
            this.parentFacade.openLobbyFromGameTable('cash');
        }
    };
    showCreateTable = function () {
        this.visible = true;
        //this.CreateAddConformTableBg.visible = false;
        this.closeButton.visible = false;
    };
    onResize = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        this.CreateNewtableContainer.scale.set(scaleXValue, scaleYValue);
    };
    // jackpot info comes with a delay after creating the table so update caption
    updateJackpotInfo = function (tableId, jackpotInfo) {
        this.tableIdjackpotInfoMap[tableId] = jackpotInfo;
        this.spinCardType = jackpotInfo.cardType;
        this.updateCaptionForTournaments(tableId);
    };
    showWidget() {
        const message = {};
        message['className'] = 'showWidget';
        // message["tableId"] = peerId;
        message['show'] = true;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
}
