import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class Bet extends TableEventInfo {
    public audio = new Audio('pictures/sounds/bet_new.mp3');
    getEventType(): TableEvents {
        return TableEvents.BET;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.BET;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        if (gameTable.extraBetAmount > 0) {
            player.addToPot(this.amount + Number(gameTable.extraBetAmount));
            gameTable.addToPot(this.amount + Number(gameTable.extraBetAmount));
        } else {
            player.addToPot(this.amount);
            gameTable.addToPot(this.amount);
        }

        if (gameTable.isSoundsOn) {
            this.audio.play();
        }

        player.playerStatus = this.getPlayerStatus();
        if (!gameTable.onInit) {
            player.highlightEvent();
        }
        gameTable.mpot = false;
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.subtractFromPot(this.amount);
        gameTable.substractFromPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
    }
}
