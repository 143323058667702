import { Message } from '../../message';
import { GameTableStatus } from './GameTableStatus';

export class BuyinRefundStatus extends Message {
    private MESSAGE_NAME: string = 'BuyinRefundStatus';
    private msg: any | null = null;
    private gameTableStatus: GameTableStatus | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, msg: any | null = null, gameTableStatus: GameTableStatus | null = null) {
        super(reqServerPeerId);
        this.msg = msg;
        this.gameTableStatus = gameTableStatus;
    }

    getMsg(): any | null {
        return this.msg;
    }

    setMsg(msg: any | null) {
        this.msg = msg;
    }
    getGameTableStatus(): GameTableStatus | null {
        return this.gameTableStatus;
    }

    setGameTableStatus(gameTableStatus: GameTableStatus | null) {
        this.gameTableStatus = gameTableStatus;
    }
}
