import { Message } from '../../message';

export class LSMultiDayData extends Message {
    private MESSAGE_NAME: string = 'LSMultiDayData';
    private noOfDays: number = 0;
    private currentDay: number = 0;
    private prevDaysRunTime: number = 0;
    private currentDayStartTime: any | null = null;
    private days: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        noOfDays: number = 0,
        currentDay: number = 0,
        prevDaysRunTime: number = 0,
        currentDayStartTime: any | null = null,
        days: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.noOfDays = noOfDays;
        this.currentDay = currentDay;
        this.prevDaysRunTime = prevDaysRunTime;
        this.currentDayStartTime = currentDayStartTime;
        this.days = days;
    }

    getNoOfDays(): number {
        return this.noOfDays;
    }

    setNoOfDays(noOfDays: number) {
        this.noOfDays = noOfDays;
    }
    getCurrentDay(): number {
        return this.currentDay;
    }

    setCurrentDay(currentDay: number) {
        this.currentDay = currentDay;
    }
    getPrevDaysRunTime(): number {
        return this.prevDaysRunTime;
    }

    setPrevDaysRunTime(prevDaysRunTime: number) {
        this.prevDaysRunTime = prevDaysRunTime;
    }
    getCurrentDayStartTime(): any | null {
        return this.currentDayStartTime;
    }

    setCurrentDayStartTime(currentDayStartTime: any | null) {
        this.currentDayStartTime = currentDayStartTime;
    }
    getDays(): any[] | null {
        return this.days;
    }

    setDays(days: any[] | null) {
        this.days = days;
    }
}
