import { Routes } from '@angular/router';

import { PokerMonsterSeriesSynopsisResolver } from './poker-monsterseries-synopsis.resolver';
import { PokerMonsterSeriesResolver } from './poker-monsterseries.resolver';

export const pokerMonsterSeriesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMonsterSeriesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerMonsterSeriesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMonsterSeriesResolver,
        },
    },
];
