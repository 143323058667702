import { Message } from '../../message';
import { GameTableStatus } from './GameTableStatus';

export class InviteBuddyRequest extends Message {
    private MESSAGE_NAME: string = 'InviteBuddyRequest';
    private invitingBuddy: string | null = null;
    private tableId: number = 0;
    private gameTableStatus: GameTableStatus | null = null;
    private message: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        invitingBuddy: string | null = null,
        tableId: number = 0,
        gameTableStatus: GameTableStatus | null = null,
        message: string | null = null,
    ) {
        super(reqServerPeerId);
        this.invitingBuddy = invitingBuddy;
        this.tableId = tableId;
        this.gameTableStatus = gameTableStatus;
        this.message = message;
    }

    getInvitingBuddy(): string | null {
        return this.invitingBuddy;
    }

    setInvitingBuddy(invitingBuddy: string | null) {
        this.invitingBuddy = invitingBuddy;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getGameTableStatus(): GameTableStatus | null {
        return this.gameTableStatus;
    }

    setGameTableStatus(gameTableStatus: GameTableStatus | null) {
        this.gameTableStatus = gameTableStatus;
    }
    getMessage(): string | null {
        return this.message;
    }

    setMessage(message: string | null) {
        this.message = message;
    }
}
