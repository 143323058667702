import { Message } from '../../message';

export class PoolUserReconnectResponse extends Message {
    private MESSAGE_NAME: string = 'PoolUserReconnectResponse';
    private playerEntryStatuses: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerEntryStatuses: any[] | null = null) {
        super(reqServerPeerId);
        this.playerEntryStatuses = playerEntryStatuses;
    }

    getPlayerEntryStatuses(): any[] | null {
        return this.playerEntryStatuses;
    }

    setPlayerEntryStatuses(playerEntryStatuses: any[] | null) {
        this.playerEntryStatuses = playerEntryStatuses;
    }
}
