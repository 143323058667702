import { Message } from '../../message';

export class MTCTTourneyInfo extends Message {
    private MESSAGE_NAME: string = 'MTCTTourneyInfo';
    private mtctId: number = 0;
    private name: any | null = null;
    private info: any | null = null;
    private gameType: number = 0;
    private maxSeats: number = 0;
    private buyIn: number = 0;
    private entryFee: number = 0;
    private isTrnyFeeTypePercent: boolean | null = false;
    private trnyFeePercent: number = 0;
    private colorCode: number = 0;
    private limitType: number = 0;
    private tabIds: any[] | null = null;
    private trnyTypeId: number = 0;
    private tableInfoId: number = 0;
    private announceTime: any | null = null;
    private registrationStartTime: any | null = null;
    private registrationEndTime: any | null = null;
    private allowLateRegistration: boolean | null = false;
    private tourneyStartTime: any | null = null;
    private tourneyEndTime: any | null = null;
    private breakInterval: number = 0;
    private breakDuration: number = 0;
    private maxParticipants: number = 0;
    private status: number = 0;
    private buyInInfo: any | null = null;
    private mtsgId: number = 0;
    private defaultChips: number = 0;
    private gamePlayType: number = 0;
    private tournamentDescription: any | null = null;
    private firstRankPlayerName: string | null = null;
    private partyPoints: number = 0;
    private familyId: number = 0;
    private gameCurrency: string | null = null;
    private bountyAmount: number = 0;
    private unRegOffset: number = 0;
    private synchronisedBreakTrny: boolean | null = false;
    private allowLateRegistrationTillLevel: number = 0;
    private mttType: number = 0;
    private trnyMinPlrs: number = 0;
    private quinteChampionshipFee: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        name: any | null = null,
        info: any | null = null,
        gameType: number = 0,
        maxSeats: number = 0,
        buyIn: number = 0,
        entryFee: number = 0,
        isTrnyFeeTypePercent: boolean | null = false,
        trnyFeePercent: number = 0,
        colorCode: number = 0,
        limitType: number = 0,
        tabIds: any[] | null = null,
        trnyTypeId: number = 0,
        tableInfoId: number = 0,
        announceTime: any | null = null,
        registrationStartTime: any | null = null,
        registrationEndTime: any | null = null,
        allowLateRegistration: boolean | null = false,
        tourneyStartTime: any | null = null,
        tourneyEndTime: any | null = null,
        breakInterval: number = 0,
        breakDuration: number = 0,
        maxParticipants: number = 0,
        status: number = 0,
        buyInInfo: any | null = null,
        mtsgId: number = 0,
        defaultChips: number = 0,
        gamePlayType: number = 0,
        tournamentDescription: any | null = null,
        firstRankPlayerName: string | null = null,
        partyPoints: number = 0,
        familyId: number = 0,
        gameCurrency: string | null = null,
        bountyAmount: number = 0,
        unRegOffset: number = 0,
        synchronisedBreakTrny: boolean | null = false,
        allowLateRegistrationTillLevel: number = 0,
        mttType: number = 0,
        trnyMinPlrs: number = 0,
        quinteChampionshipFee: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.name = name;
        this.info = info;
        this.gameType = gameType;
        this.maxSeats = maxSeats;
        this.buyIn = buyIn;
        this.entryFee = entryFee;
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
        this.trnyFeePercent = trnyFeePercent;
        this.colorCode = colorCode;
        this.limitType = limitType;
        this.tabIds = tabIds;
        this.trnyTypeId = trnyTypeId;
        this.tableInfoId = tableInfoId;
        this.announceTime = announceTime;
        this.registrationStartTime = registrationStartTime;
        this.registrationEndTime = registrationEndTime;
        this.allowLateRegistration = allowLateRegistration;
        this.tourneyStartTime = tourneyStartTime;
        this.tourneyEndTime = tourneyEndTime;
        this.breakInterval = breakInterval;
        this.breakDuration = breakDuration;
        this.maxParticipants = maxParticipants;
        this.status = status;
        this.buyInInfo = buyInInfo;
        this.mtsgId = mtsgId;
        this.defaultChips = defaultChips;
        this.gamePlayType = gamePlayType;
        this.tournamentDescription = tournamentDescription;
        this.firstRankPlayerName = firstRankPlayerName;
        this.partyPoints = partyPoints;
        this.familyId = familyId;
        this.gameCurrency = gameCurrency;
        this.bountyAmount = bountyAmount;
        this.unRegOffset = unRegOffset;
        this.synchronisedBreakTrny = synchronisedBreakTrny;
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
        this.mttType = mttType;
        this.trnyMinPlrs = trnyMinPlrs;
        this.quinteChampionshipFee = quinteChampionshipFee;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getInfo(): any | null {
        return this.info;
    }

    setInfo(info: any | null) {
        this.info = info;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getEntryFee(): number {
        return this.entryFee;
    }

    setEntryFee(entryFee: number) {
        this.entryFee = entryFee;
    }
    getIsTrnyFeeTypePercent(): boolean | null {
        return this.isTrnyFeeTypePercent;
    }

    setIsTrnyFeeTypePercent(isTrnyFeeTypePercent: boolean | null) {
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
    }
    getTrnyFeePercent(): number {
        return this.trnyFeePercent;
    }

    setTrnyFeePercent(trnyFeePercent: number) {
        this.trnyFeePercent = trnyFeePercent;
    }
    getColorCode(): number {
        return this.colorCode;
    }

    setColorCode(colorCode: number) {
        this.colorCode = colorCode;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getTabIds(): any[] | null {
        return this.tabIds;
    }

    setTabIds(tabIds: any[] | null) {
        this.tabIds = tabIds;
    }
    getTrnyTypeId(): number {
        return this.trnyTypeId;
    }

    setTrnyTypeId(trnyTypeId: number) {
        this.trnyTypeId = trnyTypeId;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getAnnounceTime(): any | null {
        return this.announceTime;
    }

    setAnnounceTime(announceTime: any | null) {
        this.announceTime = announceTime;
    }
    getRegistrationStartTime(): any | null {
        return this.registrationStartTime;
    }

    setRegistrationStartTime(registrationStartTime: any | null) {
        this.registrationStartTime = registrationStartTime;
    }
    getRegistrationEndTime(): any | null {
        return this.registrationEndTime;
    }

    setRegistrationEndTime(registrationEndTime: any | null) {
        this.registrationEndTime = registrationEndTime;
    }
    getAllowLateRegistration(): boolean | null {
        return this.allowLateRegistration;
    }

    setAllowLateRegistration(allowLateRegistration: boolean | null) {
        this.allowLateRegistration = allowLateRegistration;
    }
    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
    getTourneyEndTime(): any | null {
        return this.tourneyEndTime;
    }

    setTourneyEndTime(tourneyEndTime: any | null) {
        this.tourneyEndTime = tourneyEndTime;
    }
    getBreakInterval(): number {
        return this.breakInterval;
    }

    setBreakInterval(breakInterval: number) {
        this.breakInterval = breakInterval;
    }
    getBreakDuration(): number {
        return this.breakDuration;
    }

    setBreakDuration(breakDuration: number) {
        this.breakDuration = breakDuration;
    }
    getMaxParticipants(): number {
        return this.maxParticipants;
    }

    setMaxParticipants(maxParticipants: number) {
        this.maxParticipants = maxParticipants;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getBuyInInfo(): any | null {
        return this.buyInInfo;
    }

    setBuyInInfo(buyInInfo: any | null) {
        this.buyInInfo = buyInInfo;
    }
    getMtsgId(): number {
        return this.mtsgId;
    }

    setMtsgId(mtsgId: number) {
        this.mtsgId = mtsgId;
    }
    getDefaultChips(): number {
        return this.defaultChips;
    }

    setDefaultChips(defaultChips: number) {
        this.defaultChips = defaultChips;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getTournamentDescription(): any | null {
        return this.tournamentDescription;
    }

    setTournamentDescription(tournamentDescription: any | null) {
        this.tournamentDescription = tournamentDescription;
    }
    getFirstRankPlayerName(): string | null {
        return this.firstRankPlayerName;
    }

    setFirstRankPlayerName(firstRankPlayerName: string | null) {
        this.firstRankPlayerName = firstRankPlayerName;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getFamilyId(): number {
        return this.familyId;
    }

    setFamilyId(familyId: number) {
        this.familyId = familyId;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getBountyAmount(): number {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: number) {
        this.bountyAmount = bountyAmount;
    }
    getUnRegOffset(): number {
        return this.unRegOffset;
    }

    setUnRegOffset(unRegOffset: number) {
        this.unRegOffset = unRegOffset;
    }
    getSynchronisedBreakTrny(): boolean | null {
        return this.synchronisedBreakTrny;
    }

    setSynchronisedBreakTrny(synchronisedBreakTrny: boolean | null) {
        this.synchronisedBreakTrny = synchronisedBreakTrny;
    }
    getAllowLateRegistrationTillLevel(): number {
        return this.allowLateRegistrationTillLevel;
    }

    setAllowLateRegistrationTillLevel(allowLateRegistrationTillLevel: number) {
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
    }
    getMttType(): number {
        return this.mttType;
    }

    setMttType(mttType: number) {
        this.mttType = mttType;
    }
    getTrnyMinPlrs(): number {
        return this.trnyMinPlrs;
    }

    setTrnyMinPlrs(trnyMinPlrs: number) {
        this.trnyMinPlrs = trnyMinPlrs;
    }
    getQuinteChampionshipFee(): number {
        return this.quinteChampionshipFee;
    }

    setQuinteChampionshipFee(quinteChampionshipFee: number) {
        this.quinteChampionshipFee = quinteChampionshipFee;
    }
}
