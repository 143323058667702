import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

// Initial Value
export const initialState = [];
export const dynaconInitialState = {};

// initialize Selector
export const selectSiteCoreState = createFeatureSelector<any[]>('SiteCoreLiterals');
export const selectDynaconConfigs = createFeatureSelector<{}>('DynaconConfigs');

// update Selector
export const siteCoreLiteralsSelect = createSelector(selectSiteCoreState, (state: Array<object>) => state);
export const dynaconConfigsSelect = createSelector(selectDynaconConfigs, (state: object) => state);

// create Action
export const updateSiteCoreLiterals = createAction('[Site Core Literals] Update Site Core Literals', (SiteCoreData: any[]) => ({ SiteCoreData }));
export const updateDynaconConfigs = createAction('[Dynacon Configs] Update Dynacon Configs', (DynaconData: {}) => ({ DynaconData }));

// create Reducer
export const siteCoreLiteralsReducer = createReducer(
    initialState,
    on(updateSiteCoreLiterals, (state, { SiteCoreData }) => SiteCoreData as any),
);
export const dynaconDataReducer = createReducer(
    dynaconInitialState,
    on(updateDynaconConfigs, (state, { DynaconData }) => DynaconData),
);
