import { Message } from '../../message';

export class TourneyRegistrationStatus extends Message {
    private MESSAGE_NAME: string = 'TourneyRegistrationStatus';
    private mtctId: number = 0;
    private registrationsOpen: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, registrationsOpen: boolean | null = false) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.registrationsOpen = registrationsOpen;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getRegistrationsOpen(): boolean | null {
        return this.registrationsOpen;
    }

    setRegistrationsOpen(registrationsOpen: boolean | null) {
        this.registrationsOpen = registrationsOpen;
    }
}
