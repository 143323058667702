import { Message } from '../../message';

export class UserClubDetails extends Message {
    private MESSAGE_NAME: string = 'UserClubDetails';
    private clubId: number = 0;
    private clubName: string | null = null;
    private clubMememberShipStatus: number = 0;
    private userType: number = 0;
    private joinDate: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        clubId: number = 0,
        clubName: string | null = null,
        clubMememberShipStatus: number = 0,
        userType: number = 0,
        joinDate: any | null = null,
    ) {
        super(reqServerPeerId);
        this.clubId = clubId;
        this.clubName = clubName;
        this.clubMememberShipStatus = clubMememberShipStatus;
        this.userType = userType;
        this.joinDate = joinDate;
    }

    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
    getClubName(): string | null {
        return this.clubName;
    }

    setClubName(clubName: string | null) {
        this.clubName = clubName;
    }
    getClubMememberShipStatus(): number {
        return this.clubMememberShipStatus;
    }

    setClubMememberShipStatus(clubMememberShipStatus: number) {
        this.clubMememberShipStatus = clubMememberShipStatus;
    }
    getUserType(): number {
        return this.userType;
    }

    setUserType(userType: number) {
        this.userType = userType;
    }
    getJoinDate(): any | null {
        return this.joinDate;
    }

    setJoinDate(joinDate: any | null) {
        this.joinDate = joinDate;
    }
}
