import { Message } from '../../message';

export class MTCTLevelChangeEvent extends Message {
    private MESSAGE_NAME: string = 'MTCTLevelChangeEvent';
    private mtctId: number = 0;
    private currentLevel: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, currentLevel: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.currentLevel = currentLevel;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getCurrentLevel(): number {
        return this.currentLevel;
    }

    setCurrentLevel(currentLevel: number) {
        this.currentLevel = currentLevel;
    }
}
