import { Message } from '../../message';

export class PotWinners extends Message {
    private MESSAGE_NAME: string = 'PotWinners';
    private potNumber: number = 0;
    private winners: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, potNumber: number = 0, winners: any[] | null = null) {
        super(reqServerPeerId);
        this.potNumber = potNumber;
        this.winners = winners;
    }

    getPotNumber(): number {
        return this.potNumber;
    }

    setPotNumber(potNumber: number) {
        this.potNumber = potNumber;
    }
    getWinners(): any[] | null {
        return this.winners;
    }

    setWinners(winners: any[] | null) {
        this.winners = winners;
    }
}
