import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { CardHidden } from './card-hidden';

export class PlayerHolecardsEvents extends CardHidden {
    override getEventType(): TableEvents {
        return TableEvents.PLAYER_HOLECARDS_EVENT;
    }
    override next(gameTable: GameTable): void {
        gameTable.currentBettingRound = 1;
        gameTable.playerCardMap.forEach((value: Array<any>, key: any) => {
            const player = gameTable.getPlayer(key);
            player.addHoleCards(value);
            if (!player.isPlayerRequesting) {
                player.isPlayerCardsOpened = false;
            } else {
                player.isPlayerCardsOpened = true;
            }
        });
        gameTable.cardAnimation = true;
    }
    override prev(gameTable: GameTable): void {
        gameTable.playerCardMap.forEach((value: Array<any>, key: any) => {
            const player = gameTable.getPlayer(key);
            player.removeHoleCards(value);
        });
    }
}
