import { Message } from '../../message';

export class OldUpgradeInfo extends Message {
    private MESSAGE_NAME: string = 'OldUpgradeInfo';
    private upgradeType: number = 0;
    private upgradeURL: string | null = null;
    private upgradeMsg: any | null = null;
    private minorBuildNumber: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        upgradeType: number = 0,
        upgradeURL: string | null = null,
        upgradeMsg: any | null = null,
        minorBuildNumber: number = 0,
    ) {
        super(reqServerPeerId);
        this.upgradeType = upgradeType;
        this.upgradeURL = upgradeURL;
        this.upgradeMsg = upgradeMsg;
        this.minorBuildNumber = minorBuildNumber;
    }

    getUpgradeType(): number {
        return this.upgradeType;
    }

    setUpgradeType(upgradeType: number) {
        this.upgradeType = upgradeType;
    }
    getUpgradeURL(): string | null {
        return this.upgradeURL;
    }

    setUpgradeURL(upgradeURL: string | null) {
        this.upgradeURL = upgradeURL;
    }
    getUpgradeMsg(): any | null {
        return this.upgradeMsg;
    }

    setUpgradeMsg(upgradeMsg: any | null) {
        this.upgradeMsg = upgradeMsg;
    }
    getMinorBuildNumber(): number {
        return this.minorBuildNumber;
    }

    setMinorBuildNumber(minorBuildNumber: number) {
        this.minorBuildNumber = minorBuildNumber;
    }
}
