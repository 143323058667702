<div class="row">
    <div class="col-6">
        <div class="pwc-user-balance">
            <div class="player-username">{{ userName }}</div>
            <div class="wallet-balance d-flex align-items-center justify-content-center w-100">
                <span class="money-icon" [ngStyle]="{ 'background-image': 'url(' + balanceIcon + ')' }">&nbsp;</span>
                <span class="money-number"
                    >{{ accountBalance | FormatamountPipe: accountCurrency }}
                    <small>{{ accountCurrency }}</small>
                </span>
            </div>
        </div>
    </div>
    <div class="col-6">
        <button class="btn btn-sm w-100 btn-cashier" (click)="openCashierPage()">
            {{ 'PARTY_POKER_AN_GEN_ANMISSING6_MainMenuCashierBtn' | translate }}
        </button>
    </div>
</div>
