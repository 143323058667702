import { Message } from '../../message';

export class FFCard extends Message {
    private MESSAGE_NAME: string = 'FFCard';
    private cardId: number = 0;
    private lowerStakesOrBlinds: number = 0;
    private higherStakesOrBlinds: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;
    private gameSpeed: number = 0;
    private tag: number = 0;
    private imageName: string | null = null;
    private isImageURL: number = 0;
    private gameCurrency: string | null = null;
    private lastPlayed: boolean | null = false;
    private poolId: number = 0;
    private minBuyin: number = 0;
    private maxBuyin: number = 0;
    private poolName: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        cardId: number = 0,
        lowerStakesOrBlinds: number = 0,
        higherStakesOrBlinds: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
        gameSpeed: number = 0,
        tag: number = 0,
        imageName: string | null = null,
        isImageURL: number = 0,
        gameCurrency: string | null = null,
        lastPlayed: boolean | null = false,
        poolId: number = 0,
        minBuyin: number = 0,
        maxBuyin: number = 0,
        poolName: any | null = null,
    ) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.lowerStakesOrBlinds = lowerStakesOrBlinds;
        this.higherStakesOrBlinds = higherStakesOrBlinds;
        this.gameType = gameType;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
        this.gameSpeed = gameSpeed;
        this.tag = tag;
        this.imageName = imageName;
        this.isImageURL = isImageURL;
        this.gameCurrency = gameCurrency;
        this.lastPlayed = lastPlayed;
        this.poolId = poolId;
        this.minBuyin = minBuyin;
        this.maxBuyin = maxBuyin;
        this.poolName = poolName;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getLowerStakesOrBlinds(): number {
        return this.lowerStakesOrBlinds;
    }

    setLowerStakesOrBlinds(lowerStakesOrBlinds: number) {
        this.lowerStakesOrBlinds = lowerStakesOrBlinds;
    }
    getHigherStakesOrBlinds(): number {
        return this.higherStakesOrBlinds;
    }

    setHigherStakesOrBlinds(higherStakesOrBlinds: number) {
        this.higherStakesOrBlinds = higherStakesOrBlinds;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getGameSpeed(): number {
        return this.gameSpeed;
    }

    setGameSpeed(gameSpeed: number) {
        this.gameSpeed = gameSpeed;
    }
    getTag(): number {
        return this.tag;
    }

    setTag(tag: number) {
        this.tag = tag;
    }
    getImageName(): string | null {
        return this.imageName;
    }

    setImageName(imageName: string | null) {
        this.imageName = imageName;
    }
    getIsImageURL(): number {
        return this.isImageURL;
    }

    setIsImageURL(isImageURL: number) {
        this.isImageURL = isImageURL;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getLastPlayed(): boolean | null {
        return this.lastPlayed;
    }

    setLastPlayed(lastPlayed: boolean | null) {
        this.lastPlayed = lastPlayed;
    }
    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
    getMinBuyin(): number {
        return this.minBuyin;
    }

    setMinBuyin(minBuyin: number) {
        this.minBuyin = minBuyin;
    }
    getMaxBuyin(): number {
        return this.maxBuyin;
    }

    setMaxBuyin(maxBuyin: number) {
        this.maxBuyin = maxBuyin;
    }
    getPoolName(): any | null {
        return this.poolName;
    }

    setPoolName(poolName: any | null) {
        this.poolName = poolName;
    }
}
