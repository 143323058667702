import { Injectable } from '@angular/core';

import { TournamentsCalendarClientConfig } from './client-config.models';

@Injectable({ providedIn: 'root' })
export class TournamentCalendarPagerService {
    pagerStartEndInterval: any;

    constructor(private tournamentCalendarClientConfig: TournamentsCalendarClientConfig) {}

    getTournamentCalendarPager(totalItems: number, currentPage: number = 1) {
        let pageSize: number = 1;
        this.tournamentCalendarClientConfig.whenReady.subscribe(() => {
            pageSize = this.tournamentCalendarClientConfig.defaultPageSize;
        });

        if (totalItems > 0) {
            this.pagerStartEndInterval = this.tournamentCalendarClientConfig.whenReady.subscribe(() => {
                this.tournamentCalendarClientConfig.pagerStartEndInterval;
            });
            const totalPages = Math.ceil(totalItems / pageSize);
            if (currentPage < 1) {
                currentPage = 1;
            } else if (currentPage > totalPages) {
                currentPage = totalPages;
            }

            const startPage: number = 1;
            if (currentPage <= this.pagerStartEndInterval + 1 && totalPages <= this.pagerStartEndInterval + 1) {
                const pages = Array.from(Array(totalPages).keys()).map((i) => i + 1);
                return {
                    totalItems,
                    currentPage,
                    pageSize,
                    totalPages,
                    startPage,
                    pages,
                };
            } else if (currentPage == 1 && totalPages > this.pagerStartEndInterval + 1) {
                const pages = Array.from(Array(3).keys()).map((i) => i + 1);
                pages.push(999);
                pages.push(totalPages);
                return {
                    totalItems,
                    currentPage,
                    pageSize,
                    totalPages,
                    startPage,
                    pages,
                };
            } else if (currentPage <= this.pagerStartEndInterval && currentPage != 1) {
                const pages = Array.from(Array(currentPage + 1).keys()).map((i) => i + 1);
                pages.push(999); //add '...' in the pager
                pages.push(totalPages);

                return {
                    totalItems,
                    currentPage,
                    pageSize,
                    totalPages,
                    startPage,
                    pages,
                };
            } else if (currentPage + this.pagerStartEndInterval >= totalPages) {
                this.pagerStartEndInterval = this.tournamentCalendarClientConfig.whenReady.subscribe(() => {
                    this.tournamentCalendarClientConfig.pagerStartEndInterval;
                });
                const pages = Array.from(Array(totalPages - currentPage + 2).keys()).map((i) => currentPage - 1 + i);
                pages.unshift(999);
                pages.unshift(startPage); //for adding '...' in the pager

                return {
                    totalItems,
                    currentPage,
                    pageSize,
                    totalPages,
                    startPage,
                    pages,
                };
            } else {
                this.pagerStartEndInterval = this.tournamentCalendarClientConfig.whenReady.subscribe(() => {
                    this.tournamentCalendarClientConfig.pagerStartEndInterval;
                });
                const pages = Array.from(Array(3).keys()).map((i) => currentPage - 1 + i);
                pages.unshift(999);
                pages.unshift(startPage);
                pages.push(999);
                pages.push(totalPages);

                return {
                    totalItems,
                    currentPage,
                    pageSize,
                    totalPages,
                    startPage,
                    pages,
                };
            }
        } else {
            return null;
        }
    }
}
