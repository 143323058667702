import { Message } from '../../message';
import { InstallDynamicDLLStatus } from './InstallDynamicDLLStatus';

export class DynamicMsgNotProcessed extends Message {
    private MESSAGE_NAME: string = 'DynamicMsgNotProcessed';
    private dynamicMsgBytes: any[] | null = null;
    private reason: number = 0;
    private installDynamicDLLStatus: InstallDynamicDLLStatus | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        dynamicMsgBytes: any[] | null = null,
        reason: number = 0,
        installDynamicDLLStatus: InstallDynamicDLLStatus | null = null,
    ) {
        super(reqServerPeerId);
        this.dynamicMsgBytes = dynamicMsgBytes;
        this.reason = reason;
        this.installDynamicDLLStatus = installDynamicDLLStatus;
    }

    getDynamicMsgBytes(): any[] | null {
        return this.dynamicMsgBytes;
    }

    setDynamicMsgBytes(dynamicMsgBytes: any[] | null) {
        this.dynamicMsgBytes = dynamicMsgBytes;
    }
    getReason(): number {
        return this.reason;
    }

    setReason(reason: number) {
        this.reason = reason;
    }
    getInstallDynamicDLLStatus(): InstallDynamicDLLStatus | null {
        return this.installDynamicDLLStatus;
    }

    setInstallDynamicDLLStatus(installDynamicDLLStatus: InstallDynamicDLLStatus | null) {
        this.installDynamicDLLStatus = installDynamicDLLStatus;
    }
}
