import { Pipe, PipeTransform } from '@angular/core';

import getSymbolFromCurrency from './currency-map';

@Pipe({ name: 'formatCurrencyPipe', standalone: true })
export class formatCurrencyPipe implements PipeTransform {
    transform(symbol: string) {
        return getSymbolFromCurrency(symbol);
    }
}
