// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class EmptyTableView extends PIXI.Container {
    displayUtil = new DisplayUtil();
    constructor() {
        super();
    }
    createView = function (index) {
        this.fontProps = uiConfig.font.props;
        this.emptytableContainer = this.displayUtil.getContainer();
        this.addChild(this.emptytableContainer);
        this.emptytableContainer.visible = true;
        this.emptytableContainer.text = 'EmptyTableView' + index;
        //create BG sprite
        this.emptyTableBG = this.displayUtil.getSprite('emptytableBG');
        this.emptytableContainer.addChild(this.emptyTableBG);
        //this.displayUtil .setObjectPosition(this.emptyTableBG, 0, 0);
        this.tableAddedPositions = {
            x: [0, uiConfig.windowWidth / 2, 0, uiConfig.windowWidth / 2],
            y: [0, 0, uiConfig.windowHeight / 2, uiConfig.windowHeight / 2],
        };
        this.displayUtil.setObjectPosition(this.emptytableContainer, this.tableAddedPositions.x[index], this.tableAddedPositions.y[index]);
        this.onResize();
    };
    onResize = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        this.emptytableContainer.scale.set(scaleXValue, scaleYValue);
        // this.displayUtil.setContainerScaleFitToScreen(this);
    };
}
