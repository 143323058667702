import { Message } from '../../message';

export class JoinTable extends Message {
    private MESSAGE_NAME: string = 'JoinTable';
    private password: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, password: string | null = null) {
        super(reqServerPeerId);
        this.password = password;
    }

    getPassword(): string | null {
        return this.password;
    }

    setPassword(password: string | null) {
        this.password = password;
    }
}
