import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { cashgamesSubFiltersComponent } from './cash-filters/cashgames-subfilter.component';
import { CgFiltersEventBusService } from './services/cg-filters-event-bus.service';
import { allFilters, filterTypesConfig } from './utils/filterConfig.model';
import * as filterTypeConfingData from './utils/filterTypesConfig.json';

// import * as filterTypeConfingData from './utils/'
@Component({
    selector: 'pwc-lobby-filters',
    templateUrl: 'lobby-filters.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
})
export class LobbyFiltersComponent {
    @ViewChild('viewContainer', { read: ViewContainerRef, static: false }) viewContainer: ViewContainerRef;
    filterTypesConfig: filterTypesConfig = (filterTypeConfingData as any).default;
    filtersConfig: allFilters[] = (filterTypeConfingData as any).default.allFilters;
    isCashgamesLobby: boolean = true;
    filteredcount: any;
    pageTitle: string;
    totalcount: number;
    isPlayButtonEnabled = true;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    private readonly logger: PokerLogger;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private changeRef: ChangeDetectorRef,
        private eventBus: CgFiltersEventBusService,
        private shareService: PokerWebClientSharedService,
        loggerFactory: LoggerFactory,
        private navigation: NavigationService,
    ) {
        this.logger = loggerFactory.getLogger('LobbyFiltersLoggingComponent');
        this.shareService.setPageHeaderTitle({ title: 'Filters', url: '/play/cash', showBalanceBlock: false });
        this.eventDispatcherGlobal.addEventListener('cashFilterNavigation', this.closeSubfilter.bind(this));
    }
    ngOnInit() {
        this.eventBus.getCurrentFilters.subscribe((cgFilters) => {
            this.isPlayButtonEnabled = true;
            this.filtersConfig.forEach((filter) => {
                filter.filterData = cgFilters[filter.path];
            });
            for (const key in cgFilters) {
                if (cgFilters[key].length === 0) this.isPlayButtonEnabled = false;
            }
        });
        this.eventBus.filteredCardsCount.subscribe((count) => {
            this.filteredcount = count;
            if (this.isPlayButtonEnabled == false) this.filteredcount = 0;
            this.totalcount = this.eventBus.totalcards;
        });
    }
    navigateToSubFilters(filterData) {
        this.viewContainer.clear();
        const resolver = this.componentFactoryResolver.resolveComponentFactory(cashgamesSubFiltersComponent);
        const componentFactory = this.viewContainer.createComponent(resolver);
        componentFactory.instance.filtersConfig = this.filterTypesConfig[filterData.path];
        componentFactory.instance.filterType = filterData.displayValue;
        componentFactory.instance.filterTypeValue = filterData.path;
        this.shareService.setPageHeaderTitle({ title: filterData.title, url: '/play/cash-filters', showBalanceBlock: false });
        if (!this.changeRef['destroyed']) {
            this.changeRef.detectChanges();
        }
    }
    get viewContainerLength() {
        return this.viewContainer ? this.viewContainer.length : 0;
    }
    closeNonCashFilter() {
        // this.router.navigate(['/play/fast-forward']);
        this.navigation.goTo('/play/fast-forward');
    }
    resetFilters() {
        this.logger.info('Clicked on reset button');
        this.eventBus.resetFilters();
    }

    applyFilters() {
        this.logger.info('Clicked on apply button');
        this.eventBus.applyFilters();
    }
    closeSubfilter() {
        this.viewContainer.clear();
        this.shareService.setPageHeaderTitle({ title: 'Filters', url: '/play/cash', showBalanceBlock: false });
    }
}
