import { Message } from '../../message';

export class MTTCard extends Message {
    private MESSAGE_NAME: string = 'MTTCard';
    private cardId: number = 0;
    private buyIn: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;
    private gameSpeed: number = 0;
    private tag: number = 0;
    private imageName: string | null = null;
    private isImageURL: number = 0;
    private gameCurrency: string | null = null;
    private isFeatured: boolean | null = false;
    private tourneyId: number = 0;
    private scheduledTime: number = 0;
    private shortName: any | null = null;
    private overlayText: string | null = null;
    private status: number = 0;
    private registrationStatus: boolean | null = false;
    private noOfRegistrants: number = 0;
    private buyInType: number = 0;
    private tourneyFee: number = 0;
    private freeRollIdList: any[] | null = null;
    private isEvent: boolean | null = false;
    private eventName: string | null = null;
    private colorCode: number = 0;
    private quintepokChampFee: number = 0;
    private bountyAmount: number = 0;
    private partyPoints: number = 0;
    private unregClosed: boolean | null = false;
    private participantEntryType: number = 0;
    private playerEntriesAllowed: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        cardId: number = 0,
        buyIn: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
        gameSpeed: number = 0,
        tag: number = 0,
        imageName: string | null = null,
        isImageURL: number = 0,
        gameCurrency: string | null = null,
        isFeatured: boolean | null = false,
        tourneyId: number = 0,
        scheduledTime: number = 0,
        shortName: any | null = null,
        overlayText: string | null = null,
        status: number = 0,
        registrationStatus: boolean | null = false,
        noOfRegistrants: number = 0,
        buyInType: number = 0,
        tourneyFee: number = 0,
        freeRollIdList: any[] | null = null,
        isEvent: boolean | null = false,
        eventName: string | null = null,
        colorCode: number = 0,
        quintepokChampFee: number = 0,
        bountyAmount: number = 0,
        partyPoints: number = 0,
        unregClosed: boolean | null = false,
        participantEntryType: number = 0,
        playerEntriesAllowed: number = 0,
    ) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.buyIn = buyIn;
        this.gameType = gameType;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
        this.gameSpeed = gameSpeed;
        this.tag = tag;
        this.imageName = imageName;
        this.isImageURL = isImageURL;
        this.gameCurrency = gameCurrency;
        this.isFeatured = isFeatured;
        this.tourneyId = tourneyId;
        this.scheduledTime = scheduledTime;
        this.shortName = shortName;
        this.overlayText = overlayText;
        this.status = status;
        this.registrationStatus = registrationStatus;
        this.noOfRegistrants = noOfRegistrants;
        this.buyInType = buyInType;
        this.tourneyFee = tourneyFee;
        this.freeRollIdList = freeRollIdList;
        this.isEvent = isEvent;
        this.eventName = eventName;
        this.colorCode = colorCode;
        this.quintepokChampFee = quintepokChampFee;
        this.bountyAmount = bountyAmount;
        this.partyPoints = partyPoints;
        this.unregClosed = unregClosed;
        this.participantEntryType = participantEntryType;
        this.playerEntriesAllowed = playerEntriesAllowed;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getGameSpeed(): number {
        return this.gameSpeed;
    }

    setGameSpeed(gameSpeed: number) {
        this.gameSpeed = gameSpeed;
    }
    getTag(): number {
        return this.tag;
    }

    setTag(tag: number) {
        this.tag = tag;
    }
    getImageName(): string | null {
        return this.imageName;
    }

    setImageName(imageName: string | null) {
        this.imageName = imageName;
    }
    getIsImageURL(): number {
        return this.isImageURL;
    }

    setIsImageURL(isImageURL: number) {
        this.isImageURL = isImageURL;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getIsFeatured(): boolean | null {
        return this.isFeatured;
    }

    setIsFeatured(isFeatured: boolean | null) {
        this.isFeatured = isFeatured;
    }
    getTourneyId(): number {
        return this.tourneyId;
    }

    setTourneyId(tourneyId: number) {
        this.tourneyId = tourneyId;
    }
    getScheduledTime(): number {
        return this.scheduledTime;
    }

    setScheduledTime(scheduledTime: number) {
        this.scheduledTime = scheduledTime;
    }
    getShortName(): any | null {
        return this.shortName;
    }

    setShortName(shortName: any | null) {
        this.shortName = shortName;
    }
    getOverlayText(): string | null {
        return this.overlayText;
    }

    setOverlayText(overlayText: string | null) {
        this.overlayText = overlayText;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getRegistrationStatus(): boolean | null {
        return this.registrationStatus;
    }

    setRegistrationStatus(registrationStatus: boolean | null) {
        this.registrationStatus = registrationStatus;
    }
    getNoOfRegistrants(): number {
        return this.noOfRegistrants;
    }

    setNoOfRegistrants(noOfRegistrants: number) {
        this.noOfRegistrants = noOfRegistrants;
    }
    getBuyInType(): number {
        return this.buyInType;
    }

    setBuyInType(buyInType: number) {
        this.buyInType = buyInType;
    }
    getTourneyFee(): number {
        return this.tourneyFee;
    }

    setTourneyFee(tourneyFee: number) {
        this.tourneyFee = tourneyFee;
    }
    getFreeRollIdList(): any[] | null {
        return this.freeRollIdList;
    }

    setFreeRollIdList(freeRollIdList: any[] | null) {
        this.freeRollIdList = freeRollIdList;
    }
    getIsEvent(): boolean | null {
        return this.isEvent;
    }

    setIsEvent(isEvent: boolean | null) {
        this.isEvent = isEvent;
    }
    getEventName(): string | null {
        return this.eventName;
    }

    setEventName(eventName: string | null) {
        this.eventName = eventName;
    }
    getColorCode(): number {
        return this.colorCode;
    }

    setColorCode(colorCode: number) {
        this.colorCode = colorCode;
    }
    getQuintepokChampFee(): number {
        return this.quintepokChampFee;
    }

    setQuintepokChampFee(quintepokChampFee: number) {
        this.quintepokChampFee = quintepokChampFee;
    }
    getBountyAmount(): number {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: number) {
        this.bountyAmount = bountyAmount;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
    getParticipantEntryType(): number {
        return this.participantEntryType;
    }

    setParticipantEntryType(participantEntryType: number) {
        this.participantEntryType = participantEntryType;
    }
    getPlayerEntriesAllowed(): number {
        return this.playerEntriesAllowed;
    }

    setPlayerEntriesAllowed(playerEntriesAllowed: number) {
        this.playerEntriesAllowed = playerEntriesAllowed;
    }
}
