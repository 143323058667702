import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { NavigationService } from '@frontend/vanilla/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { GameTableNotificationService } from '../../service/game-table-notification.service';
import {
    miniTablesSelect,
    removeMiniTable,
    updateActiveTable,
    updateProgressBarValue,
    updateTableCards,
    updateTableStatus,
} from '../../store/mini-table.store';
import { PWCErrorDialogComponent } from '../error-dialog/pwc-error-dialog.component';

@Component({
    selector: 'pwc-mini-tables',
    templateUrl: 'mini-table.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, TranslatePipe, NgbProgressbarModule, PWCErrorDialogComponent],
    providers: [],
})
export class MiniTablesComponent {
    miniTables: any = [];
    activeTable: any;
    isNewGameTableOpen = false;
    isOtherGame = false;
    similarGameText: string;
    interval = {};
    private readonly logger: PokerLogger;
    miniTableCards: any;
    private eventDispatcherGlobal = new EventDispatcherGlobal();

    constructor(
        private navigation: NavigationService,
        private store: Store,
        private clientToServer: ClientToServerRequestServices,
        loggerFactory: LoggerFactory,
        private gameTableNotificationService: GameTableNotificationService,
        private dialog: MatDialog,
        private translatePipe: TranslatePipe,
    ) {
        this.logger = loggerFactory.getLogger('minitableLoggingComponent');
        this.miniTableCards = this.clientToServer.webAssets['game-table']['mini-tables']['minitablecards']['image']['src'];
        this.store.select(miniTablesSelect).subscribe((data) => {
            if (data['miniTables'].length > 0) {
                this.miniTables = _.sortBy(data['miniTables'], (obj) => obj.index);
                this.activeTable = data['miniTables'].filter((item) => item.isActive === true);
            } else {
                this.navigation.goTo('/play');
            }
            if (this.miniTables.length > 0 && this.activeTable.length === 0) {
                this.store.dispatch(updateActiveTable(this.miniTables[this.miniTables.length - 1]));
            }
        });
        this.eventDispatcherGlobal.addEventListener('gameEventListener', this.getClass.bind(this));
    }

    openNewGameTable(e: any) {
        // close LHN, if it is already opened.
        this.eventDispatcherGlobal.dispatchEvent('UpdateLHNStatus', { status: false });

        this.logger.info('clicked on new game table button...!' + JSON.stringify(e));
        this.isOtherGame = false;
        this.isNewGameTableOpen = !this.isNewGameTableOpen;

        const text = this.activeTable[0].winUptoText === 'FF' ? 'Fast Forward' : this.activeTable[0].winUptoText;
        this.similarGameText = `$${this.activeTable[0].winUpto} ${text}`;
        e.stopPropagation();
    }
    showOtherGames(e: any) {
        this.logger.info('clicked on other game button...!' + JSON.stringify(e));
        this.isOtherGame = !this.isOtherGame;
        e.stopPropagation();
    }

    addSimilarGame() {
        this.logger.info('clicked on $2 cash button...!');
        this.isNewGameTableOpen = false;
        this.clientToServer.addSimilarGameTable(this.activeTable[0]);
    }

    goToLobby(lobby: string) {
        this.logger.info('clicked on add new games buttons...!' + JSON.stringify(lobby));
        this.isNewGameTableOpen = false;
        const message = {};
        message['className'] = 'hideExpoAnimation';
        message['peerId'] = this.activeTable[0].tableId;
        this.eventDispatcherGlobal.dispatchEvent('hideExpoAnimation', message);
        let data = {};
        data['className'] = 'removeReplayers';
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', data);
        this.navigation.goTo(`/play/${lobby}`);
    }

    activeMiniTable(table: any) {
        // close LHN, if it is already opened.
        this.eventDispatcherGlobal.dispatchEvent('UpdateLHNStatus', { status: false });

        this.logger.info('clicked on active mini table...!' + JSON.stringify(table));
        this.store.dispatch(updateActiveTable(table));
        const message = {};
        message['className'] = 'SwitchActiveTable';
        message['peerId'] = table.tableId;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        this.navigation.goTo('/play/game-table');
    }

    getClass(message: any) {
        if (message.detail.MESSAGE_NAME == 'TimeOut') {
        } else if (message.detail.className === 'UpdateGameTableTimer') {
            this.store.dispatch(
                updateProgressBarValue({
                    tableId: message.detail.tableId,
                    progressBarValue: 100,
                    TotalTimeAvailable: message.detail.TotalTimeAvailable,
                }),
            );
            this.updateTimer(message.detail);
        } else if (message.detail.className === 'StopGameTableTimer') {
            this.store.dispatch(
                updateProgressBarValue({
                    tableId: message.detail.tableId,
                    progressBarValue: 0,
                }),
            );
        } else if (message.detail.className === 'RemoveMiniTable') {
            const backToLobby = this.miniTables.length === 1 ? this.miniTables[0].from : '/play';
            this.store.dispatch(removeMiniTable(message.detail));
            if (this.miniTables.length >= 1) {
                this.store.dispatch(updateActiveTable(this.miniTables[this.miniTables.length - 1]));
            } else {
                this.navigation.goTo(`/play/${backToLobby}`);
            }
        } else if (message.detail.className === 'showWidget') {
            if (message.detail.show) {
                this.store.dispatch(updateActiveTable({ tableId: message.detail.tableId, status: true }));
            } else {
                this.store.dispatch(updateActiveTable({ tableId: message.detail.tableId, status: false }));
            }
        } else if (message.detail.className === 'UpdateGameTableCards') {
            this.store.dispatch(
                updateTableCards({
                    tableId: message.detail.tableId,
                    cards: message.detail['cards'].length != 0 ? message.detail['cards']['cards'].map((i) => i.toUpperCase()) : [],
                }),
            );
        } else if (message.detail.className === 'UpdateGameTableStatus') {
            this.store.dispatch(updateTableStatus({ status: message.detail?.status, tableId: message.detail?.tableId }));
        } else if (message.detail.className === 'playerBalance') {
        } else if (message.detail.className === 'UpdateSNGJPJackpotInfo') {
        } else if (message.detail.className === 'AddNewGameTable') {
        } else if (message.detail.className === 'autoRebuyStatusChange') {
        } else if (message.detail.className === 'selfPlayerTimerStarted') {
        } else if (message.detail.className === 'selectTourney') {
            let table = this.miniTables.filter((obj) => obj.tableId === message.detail.tableId);
            // this.activeMiniTable(table[0]);
            if (this.miniTables.length < 4) {
                this.gameTableNotificationService.openTable(message.detail.tableId);
                if (table.length > 0) {
                    this.activeMiniTable(table[0]);
                }
            } else {
                this.dialog.open(PWCErrorDialogComponent, {
                    width: '75%',
                    maxHeight: '100vh',
                    data: {
                        title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                        message: this.translatePipe.transform('PARTY_POKER_GC_QSLOBBY_OPENEDMAXTBLSMSG'),
                        buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_OK') }],
                    },
                    autoFocus: false,
                    panelClass: 'cg-error-wrapper',
                    hasBackdrop: true,
                    backdropClass: 'cg-error-overlay',
                    disableClose: false,
                    closeOnNavigation: true,
                });
            }
        }
    }

    updateTimer(response) {
        const tempTables = this.miniTables;
        let obj = {};
        let updatedProgressValue;
        obj = _.find(tempTables, { tableId: response.tableId });
        if (response['TotalTimeAvailable'] > 0) {
            const bar = 100 / obj['TotalTimeAvailable'];
            clearInterval(this.interval[response.tableId]);
            this.interval[response.tableId] = false;
            this.interval[response.tableId] = setInterval(() => {
                const tables = this.miniTables;
                obj = _.find(tables, { tableId: response.tableId });
                if (Object.keys(obj).length > 0) {
                    updatedProgressValue = obj['progressBarValue'] - bar;
                }
                updatedProgressValue > 0
                    ? this.store.dispatch(updateProgressBarValue({ tableId: response.tableId, progressBarValue: updatedProgressValue }))
                    : clearInterval(this.interval[response.tableId]);
            }, 1000);
        }
    }

    getAssets(value: string) {
        return this.clientToServer.webAssets['game-table']['mini-tables'][value].image.src;
    }
}
