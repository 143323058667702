import { Message } from '../../message';

export class TourneyPauseInfo extends Message {
    private MESSAGE_NAME: string = 'TourneyPauseInfo';
    private dayStartTime: any | null = null;
    private pauseType: number = 0;
    private pauseValue: number = 0;
    private tournamentType: number = 0;
    private flightedType: number = 0;
    private flightedDisplayId: number = 0;
    private resumeTime: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        dayStartTime: any | null = null,
        pauseType: number = 0,
        pauseValue: number = 0,
        tournamentType: number = 0,
        flightedType: number = 0,
        flightedDisplayId: number = 0,
        resumeTime: any | null = null,
    ) {
        super(reqServerPeerId);
        this.dayStartTime = dayStartTime;
        this.pauseType = pauseType;
        this.pauseValue = pauseValue;
        this.tournamentType = tournamentType;
        this.flightedType = flightedType;
        this.flightedDisplayId = flightedDisplayId;
        this.resumeTime = resumeTime;
    }

    getDayStartTime(): any | null {
        return this.dayStartTime;
    }

    setDayStartTime(dayStartTime: any | null) {
        this.dayStartTime = dayStartTime;
    }
    getPauseType(): number {
        return this.pauseType;
    }

    setPauseType(pauseType: number) {
        this.pauseType = pauseType;
    }
    getPauseValue(): number {
        return this.pauseValue;
    }

    setPauseValue(pauseValue: number) {
        this.pauseValue = pauseValue;
    }
    getTournamentType(): number {
        return this.tournamentType;
    }

    setTournamentType(tournamentType: number) {
        this.tournamentType = tournamentType;
    }
    getFlightedType(): number {
        return this.flightedType;
    }

    setFlightedType(flightedType: number) {
        this.flightedType = flightedType;
    }
    getFlightedDisplayId(): number {
        return this.flightedDisplayId;
    }

    setFlightedDisplayId(flightedDisplayId: number) {
        this.flightedDisplayId = flightedDisplayId;
    }
    getResumeTime(): any | null {
        return this.resumeTime;
    }

    setResumeTime(resumeTime: any | null) {
        this.resumeTime = resumeTime;
    }
}
