import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as spt from './poker-single-promo-timer.component';

@Injectable({ providedIn: 'root' })
export class PokerSinglePromoTimerPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('SinglePromoTimerTemplate', spt.PokerSinglePromoTimerComponent);
        });
    }
}
