import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { CardHidden } from './card-hidden';

export class SevencardPlayerFourthStreetEventCards extends CardHidden {
    override getEventType(): TableEvents {
        return TableEvents.SEVENCARD_DEALINGFOURTHSTREET_PLAYER_HOLECARDS_EVENT;
    }
    override next(gameTable: GameTable): void {
        gameTable.playerFourthCardMap.forEach((value: Array<any>, key: any) => {
            const player = gameTable.getPlayer(key);
            player.addNextStreetCards(value);
        });
    }
    override prev(gameTable: GameTable): void {
        gameTable.playerFourthCardMap.forEach((value: Array<any>, key: any) => {
            const player = gameTable.getPlayer(key);
            player.removeNextStreetCards(value);
        });
    }
}
