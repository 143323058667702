import { Message } from '../../message';
import { AutoOption } from './AutoOption';

export class SelectedAutoOption extends Message {
    private MESSAGE_NAME: string = 'SelectedAutoOption';
    private autoOption: AutoOption | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, autoOption: AutoOption | null = null) {
        super(reqServerPeerId);
        this.autoOption = autoOption;
    }

    getAutoOption(): AutoOption | null {
        return this.autoOption;
    }

    setAutoOption(autoOption: AutoOption | null) {
        this.autoOption = autoOption;
    }
}
