import { Message } from '../../message';

export class RequestHUPairTable extends Message {
    private MESSAGE_NAME: string = 'RequestHUPairTable';
    private selected: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, selected: boolean | null = false) {
        super(reqServerPeerId);
        this.selected = selected;
    }

    getSelected(): boolean | null {
        return this.selected;
    }

    setSelected(selected: boolean | null) {
        this.selected = selected;
    }
}
