import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class PlayersAnteEvent extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.PLAYERS_ANTE_EVENT;
    }
    getPlayerStatus(): any {
        return PlayerStatus.PLAYERS_ANTE_EVENT;
    }
    next(gameTable: GameTable): void {
        gameTable.currentBettingRound = 1;
        if (gameTable.isAnteCashGameOrTourny === 1 || gameTable.actualGameName === 'ShortDeckTexasHoldem') {
            gameTable.currentBettingRound = 0;
        }
        gameTable.enableTableMask = true;
        gameTable.playerAnteChips.forEach((value: any, key: any) => {
            const player = gameTable.getPlayer(key);
            player.addToPot(value);
            gameTable.addToPot(value);
            if (!gameTable.onInit) {
                player.playerStatus = this.getPlayerStatus();
                player.highlightEvent();
            }
            if (player.chips.value <= 0) {
                player.isPlayerAllIn = true;
            }
        });

        let timer: any;
        if (!gameTable.onInit) {
            timer = setInterval(() => {
                if (
                    gameTable.eventName != 'DEALING_THIRD_STREET' &&
                    (gameTable.actualGameName === 'SevenCardStudHighLow' || gameTable.actualGameName === 'SevenCardStud')
                ) {
                    gameTable.next(false);
                } else if (gameTable.eventName != 'POST_DUMMY_ANTE' && gameTable.actualGameName.indexOf('ShortDeck') > -1) {
                    gameTable.next(false);
                } else if (
                    gameTable.eventName != 'POSTING_BLINDS' &&
                    (gameTable.actualGameName.indexOf('Omaha') > -1 || gameTable.actualGameName === 'TexasHoldem')
                ) {
                    gameTable.next(false);
                } else {
                    clearInterval(timer);
                }
            }, 100);
        }
    }

    prev(gameTable: GameTable): void {
        gameTable.playerAnteChips.forEach((value: any, key: any) => {
            const player = gameTable.getPlayer(key);
            gameTable.substractFromPot(value);
            player.subtractFromPot(value);
            player.playerStatus = this.getPlayerStatus();
            player.highlightEvent();
            if (player.chips.value >= 0) {
                player.isPlayerAllIn = false;
            }
        });
        gameTable.MAIN_POT.totalValue = 0;
    }
}
