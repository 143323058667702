export class SessionDataModel {
    userProfile = null;
    /**
     *storage for user name.
     */
    username = null;
    /**
     * storage for ssoKey.
     */
    ssoKey = '';
    /**
     * storage for password.
     */
    /**
     * storage for player logged in check.
     */
    isLoggedIn = false;
    balance = 0;
    /**
     * storage for play money balance of user.
     */
    accountBalance = 0;
    /**
     * storage for real money balance of user.
     */
    realMoneyBalance = 0;
    playMoneyBalance = 0;
    tourneyCurrencyBalance = 0;
    /**
     * storage for real money activated check.
     */
    isRealMoneyActivated = false;
    isRegisterationPage = false;
    restrictedBalance = [];
    preLoginSessionKey = null;
    ucid = null;

    balanceObj: any = {};
    LoginSuccessUserObj: any = {};
    UserProfileObj: any = {};
    keyM1 = null;
    timeZoneInfoStr = null;
    userBlocked = false;
    accountName = '';
    accountCurrencyCode = '';
    realFreeRollMap = [];
    playFreeRollMap = [];
    diamondBalance = 0;
    autoOptionToOptionsMappings: any;
    handleUserFreeRollList = function (message) {
        if (message.domain == 1) {
            // Real Domain
            const freeRollArray = message.freeRollIds;
            this.realFreeRollMap = freeRollArray.reduce(function (map, val) {
                if (val in map) {
                    map[val]++;
                } else {
                    map[val] = 1; //set the count
                }
                return map;
            }, {});
        }
        if (message.domain == 0) {
            // play Domain
            const freeRollArray = message.freeRollIds;
            this.playFreeRollMap = freeRollArray.reduce(function (map, val) {
                if (val in map) {
                    map[val]++;
                } else {
                    map[val] = 1; //set the count
                }
                return map;
            }, {});
        }
        return;
    };
    getSpinTicketCount = function (tournmentTicketList) {
        let tickecount = 0;
        if (this.realFreeRollMap == null || tournmentTicketList == null) return tickecount;
        tournmentTicketList
            .forEach(function (TicketId) {
                if (TicketId in this.realFreeRollMap) tickecount += this.realFreeRollMap[TicketId];
            })
            .bind(this);
        return tickecount;
    };
    handleDiamondBalance = function (message) {
        this.diamondBalance = message.currPromoCurrBalance;
    };
    getDiamondBalance = function () {
        return this.diamondBalance;
    };
    handleUserProfile = function (message) {
        this.accountStatus = message.accountStatus;
        this.isLoggedIn = true;
        this.userProfile = message;
        this.accountName = message.accountName;
        this.screenName = message.screenName;
        this.avatarImageName = message.avatarInfo.avatarDetails[0].avatarImageName;
        this.accountCurrencyCode = message.accountCurrencyCode;
        if (this.accountStatus == 1 || this.accountStatus == 2) {
            this.isRealMoneyActivated = true;
        }
        this.UserProfileObj['accountStatus'] = this.accountStatus;
        this.UserProfileObj['isLoggedIn'] = this.isLoggedIn;
        this.UserProfileObj['userProfile'] = this.userProfile;
        this.UserProfileObj['accountName'] = this.accountName;
        this.UserProfileObj['screenName'] = this.screenName;
        this.UserProfileObj['avatarImageName'] = this.avatarImageName;
        this.UserProfileObj['accountCurrencyCode'] = this.accountCurrencyCode;
        this.UserProfileObj['isRealMoneyActivated'] = this.isRealMoneyActivated;
    };
    getUserprofile = function (param) {
        return this.UserProfileObj[param];
    };

    handleAutoOptionToOptionsMappings(message) {
        this.autoOptionToOptionsMappings = message;
    }

    getAutoOptionToOptionsMappings() {
        return this.autoOptionToOptionsMappings;
    }
}
