import { Message } from '../../message';

export class TableOccupancyList extends Message {
    private MESSAGE_NAME: string = 'TableOccupancyList';
    private tablesList: any[] | null = null;
    private tabNumber: number = 0;
    private trafficMessage: any | null = null;
    private lobbyUpdateId: number = 0;
    private isSnapShot: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tablesList: any[] | null = null,
        tabNumber: number = 0,
        trafficMessage: any | null = null,
        lobbyUpdateId: number = 0,
        isSnapShot: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.tablesList = tablesList;
        this.tabNumber = tabNumber;
        this.trafficMessage = trafficMessage;
        this.lobbyUpdateId = lobbyUpdateId;
        this.isSnapShot = isSnapShot;
    }

    getTablesList(): any[] | null {
        return this.tablesList;
    }

    setTablesList(tablesList: any[] | null) {
        this.tablesList = tablesList;
    }
    getTabNumber(): number {
        return this.tabNumber;
    }

    setTabNumber(tabNumber: number) {
        this.tabNumber = tabNumber;
    }
    getTrafficMessage(): any | null {
        return this.trafficMessage;
    }

    setTrafficMessage(trafficMessage: any | null) {
        this.trafficMessage = trafficMessage;
    }
    getLobbyUpdateId(): number {
        return this.lobbyUpdateId;
    }

    setLobbyUpdateId(lobbyUpdateId: number) {
        this.lobbyUpdateId = lobbyUpdateId;
    }
    getIsSnapShot(): boolean | null {
        return this.isSnapShot;
    }

    setIsSnapShot(isSnapShot: boolean | null) {
        this.isSnapShot = isSnapShot;
    }
}
