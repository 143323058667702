import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterEventSchedule',
    pure: false,
})
export class FilterEventSchedulesPipe implements PipeTransform {
    transform(value: any, eventType: any[], eventBuyIn: any[], rank: any[]): any {
        const data = value;
        if (eventType.length === 0 && eventBuyIn.length === 0 && rank.length === 0) {
            return data;
        } else if (eventType.length > 0 && eventBuyIn.length > 0 && rank.length > 0) {
            const et = data.filter((item: any) => eventType.includes(item.scheduleType.toLowerCase()));
            const bIn = this.checkBuyIn(et, eventBuyIn);
            return bIn.filter((item: any) => rank.includes(item.rank));
        } else if (eventType.length > 0 && eventBuyIn.length > 0) {
            const et = data.filter((item: any) => eventType.includes(item.scheduleType.toLowerCase()));
            return this.checkBuyIn(et, eventBuyIn);
        } else if (eventType.length > 0 && rank.length > 0) {
            const et = data.filter((item: any) => eventType.includes(item.scheduleType.toLowerCase()));
            return et.filter((item: any) => rank.includes(item.rank));
        } else if (eventBuyIn.length > 0 && rank.length > 0) {
            const bIn = this.checkBuyIn(data, eventBuyIn);
            return bIn.filter((item: any) => rank.includes(item.rank));
        } else if (eventType.length > 0) {
            return data.filter((item: any) => eventType.includes(item.scheduleType.toLowerCase()));
        } else if (eventBuyIn.length > 0) {
            return this.checkBuyIn(data, eventBuyIn);
        } else if (rank.length > 0) {
            return data.filter((item: any) => rank.includes(item.rank));
        }
    }
    checkBuyIn(data: any, eventBuyIn: any): any {
        if (eventBuyIn.length === 3) {
            return data.filter((item: any) => item.buyin.split('+')[0].slice(1) > 20);
        } else if (eventBuyIn.length === 2) {
            if (eventBuyIn.includes('medium') && eventBuyIn.includes('high')) {
                return data.filter((item: any) => item.buyin.split('+')[0].slice(1) > 20 && item.buyin.split('+')[0].slice(1) <= 530);
            } else if (eventBuyIn.includes('medium') && eventBuyIn.includes('high-roller')) {
                return data.filter(
                    (item: any) =>
                        (item.buyin.split('+')[0].slice(1) > 20 && item.buyin.split('+')[0].slice(1) < 55) ||
                        item.buyin.split('+')[0].slice(1) >= 530,
                );
            } else if (eventBuyIn.includes('high') && eventBuyIn.includes('high-roller')) {
                return data.filter((item: any) => item.buyin.split('+')[0].slice(1) > 55);
            }
        } else if (eventBuyIn[0] === 'medium') {
            return data.filter((item: any) => item.buyin.split('+')[0].slice(1) > 20 && item.buyin.split('+')[0].slice(1) <= 55);
        } else if (eventBuyIn[0] === 'high') {
            return data.filter((item: any) => item.buyin.split('+')[0].slice(1) > 55 && item.buyin.split('+')[0].slice(1) <= 530);
        } else if (eventBuyIn[0] === 'high-roller') {
            return data.filter((item: any) => item.buyin.split('+')[0].slice(1) >= 530);
        } else {
            return data;
        }
    }
}
