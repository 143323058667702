import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class IndividualPlayerView extends PIXI.Container {
    eventDispatcher: any;
    gameService: any;
    peerId: any;
    currenttable: any;
    animatedBgTween: any;
    displayUtil: DisplayUtil = new DisplayUtil();
    showBackCards = false;
    constructor(EventDispatcher, gameService) {
        super();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
        this.eventDispatcher = EventDispatcher;
        this.gameService = gameService;
        this.peerId = this.gameService.getPeerId();
        this.currenttable = 'table' + this.peerId;
        this.animatedBgTween = []; //  for clearing fadein/out animation on leave table for ff
    }
    createView = function (i, maxNoOfSeats) {
        this.showPlayerBalanceTxt = true;
        this.isAllInPlaying = false;
        this.currentIndex = i;
        this.allInGlowAniArr = [];
        // this.playingYouWin = false;
        this.allInShown = false;
        this.fontProps = uiConfig.font.props;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.playerAbsPos = this.positionConfig.playersUIData.playerAbsPos;
        this.opponentRabbitPos = this.positionConfig.playersUIData.playerIconPositions;
        this.dealerConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.dealerConfig;
        this.isRealNameGame = this.gameService.getTableStatusData().realNameTable;
        this.isBoosterEnabled = false;
        // if(this.gameService.gTableType == serverIdConstants.TABLE_TYPE_SPINS) {
        this.playerViewContainerPos = this.positionConfig.playersUIData.playerViewContainerPos;
        this.playerIconPositions = this.positionConfig.playersUIData.playerIconPositions;
        this.playersLockIconPos = this.positionConfig.playersUIData.playersLockIconPos;
        const buttonLabelText = {
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 400,
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        };
        this.PlayerViewContainer = this.displayUtil.getContainer();
        this.PlayerViewContainer.name = 'PlayerViewContainer' + i;
        this.activePlaterStatus = false;
        this.playerSeatNo = null;
        this.addChild(this.PlayerViewContainer);
        this.createSpotLights(i);
        if (i == uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(this.PlayerViewContainer, this.playerViewContainerPos.x[i], this.playerViewContainerPos.y[i] - 25);
        }
        this.dealerImg = this.displayUtil.getSprite('dealer');
        this.PlayerViewContainer.addChild(this.dealerImg);
        this.dealerImg.scale.set(0.8);
        this.dealerImg.visible = false;
        const allInSeq = this.displayUtil.getSpriteDetails('AllInGlowAnimation');
        this.allInGlowAni = this.displayUtil.getCustomAnimatedSprite(allInSeq);
        this.PlayerViewContainer.addChild(this.allInGlowAni);
        this.displayUtil.setObjectPosition(this.allInGlowAni, this.playerIconPositions.x[i], this.playerIconPositions.y[i]);
        this.allInGlowAni.anchor.set(0.5);
        this.allInGlowAni.scale.set(1);
        this.allInGlowAni.visible = false;
        this.allInGlowAni.loop = false;

        const playerGlowSeq = this.displayUtil.getSpriteDetails('playerWinGlowAni');
        this.playerGlowAni = this.displayUtil.getCustomAnimatedSprite(playerGlowSeq);
        this.PlayerViewContainer.addChild(this.playerGlowAni);
        this.displayUtil.setObjectPosition(this.playerGlowAni, this.playerIconPositions.x[i], this.playerIconPositions.y[i]);
        this.playerGlowAni.visible = false;
        this.playerGlowAni.loop = false;
        this.playerGlowAni.anchor.set(0.5);
        this.totalWinAmntBg = this.displayUtil.getSprite('actionTextBg');
        this.PlayerViewContainer.addChild(this.totalWinAmntBg);
        this.totalWinAmntBg.visible = false;
        this.displayUtil.setObjectPosition(this.totalWinAmntBg, this.playerIconPositions.x[i] - 40, this.playerIconPositions.y[i] - 95);
        this.totalWinAmnt = this.displayUtil.getTextField(this.fontProps.totalWinFont);
        this.PlayerViewContainer.addChild(this.totalWinAmnt);
        this.totalWinAmnt.anchor.set(0.5);
        this.totalWinAmnt.visible = false;
        this.displayUtil.setObjectPosition(this.totalWinAmnt, this.playerIconPositions.x[i] - 3, this.playerIconPositions.y[i] - 81);
        const youwinSeq = this.displayUtil.getSpriteDetails('youWinAni');
        this.youWinAni = this.displayUtil.getCustomAnimatedSprite(youwinSeq);
        this.PlayerViewContainer.addChild(this.youWinAni);
        this.displayUtil.setObjectPosition(this.youWinAni, this.playerIconPositions.x[i] + 2, this.playerIconPositions.y[i] - 192);
        this.youWinAni.visible = false;
        this.youWinAni.loop = false;
        this.youWinAni.anchor.set(0.5);
        this.youWinAni.name = 'youWinAni';
        this.defaultPlate = this.displayUtil.getSprite('avatarplate');
        this.defaultPlate.seatingPosition = i;
        this.defaultPlate.name = 'DefaultPlate';
        this.PlayerViewContainer.addChild(this.defaultPlate);
        //this.defaultPlate.scale.set(1.2);
        this.defaultPlate.anchor.set(0.5);
        // below is playerdetails postions
        if (i != uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(this.defaultPlate, 72.5, 163);
            this.defaultPlate.alpha = 0.6;
            this.defaultPlate.scale.x = 0.723;
            this.defaultPlate.scale.y = 1;
        } else {
            this.displayUtil.setObjectPosition(this.defaultPlate, 73, 289);
            this.defaultPlate.scale.x = 0.95;
        }
        this.lockBalanceAnimSeq = this.displayUtil.getSpriteDetails('lockAnimAssetsAnim');
        this.lockBalanceAnim = this.displayUtil.getCustomAnimatedSprite(this.lockBalanceAnimSeq);
        this.lockBalanceAnim.loop = false;
        this.lockBalanceAnim.anchor.set(0.5);
        this.lockBalanceAnim.name = 'BalanceLimitFeature';
        this.PlayerViewContainer.addChild(this.lockBalanceAnim);
        this.lockBalanceAnim.visible = false;
        this.displayUtil.setObjectPosition(this.lockBalanceAnim, this.playersLockIconPos.x[i], this.playersLockIconPos.y[i]);

        let waitinText;
        if (this.gameService.gTableType == serverIdConstants.TABLE_TYPE_SPINS) {
            waitinText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_TOURNAMENTLOBBY_HEADSUPWAITING'); //$.__("WAITING");
        } else {
            waitinText = '';
        }
        this.initialPlateTxt = this.displayUtil.getTextField(this.fontProps.waitingText);
        this.PlayerViewContainer.addChild(this.initialPlateTxt);
        this.initialPlateTxt.text = waitinText;
        this.initialPlateTxt.alpha = 0.5;
        this.initialPlateTxt.anchor.set(0.5);
        this.initialPlateTxt.name = 'initialPlateTxt';
        if (i != uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(this.initialPlateTxt, 70, 159);
        } else {
            this.displayUtil.setObjectPosition(this.initialPlateTxt, 72, 288);
        }
        this.balanceTxt = this.displayUtil.getTextField(this.fontProps.balanceTxt_2);
        this.PlayerViewContainer.addChild(this.balanceTxt);
        this.balanceTxt.text = 0;
        this.balanceTxt.name = 'balanceTxt';
        this.balanceTxt.y = 50;
        //this.balanceTxt.fontWeight=800;
        this.balanceTxt.style.fill = '0xf5b308';
        this.balanceTxt.anchor.set(0.5);
        this.balanceTxt.visible = false;
        if (i != uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(this.balanceTxt, 72, 177);
        } else {
            if (
                /android/.test(navigator.userAgent.toLowerCase()) == false &&
                /iPad|iPhone|iPod|AppleWebKit/.test(navigator.userAgent || navigator.vendor)
            )
                this.displayUtil.setObjectPosition(this.balanceTxt, 72, 295);
            else this.displayUtil.setObjectPosition(this.balanceTxt, 72, 306);
        }
        this.userNameTxt = this.displayUtil.getTextField(this.fontProps.userNameTxt_2);
        this.PlayerViewContainer.addChild(this.userNameTxt);
        this.userNameTxt.text = null;
        this.userNameTxt.anchor.set(0.5);
        this.userNameTxt.visible = false;
        this.userNameTxt.y = 50;
        this.userNameTxt.name = 'userNameTxt';
        if (i != uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(this.userNameTxt, 72, 149);
        } else {
            if (
                /android/.test(navigator.userAgent.toLowerCase()) == false &&
                /iPad|iPhone|iPod|AppleWebKit/.test(navigator.userAgent || navigator.vendor)
            )
                this.displayUtil.setObjectPosition(this.userNameTxt, 72, 268);
            else this.displayUtil.setObjectPosition(this.userNameTxt, 72, 277);
        }
        this.playerIconBG = this.displayUtil.getContainer();
        this.playerIconBG.name = 'playerIconBG';
        if (i != uiConfig.selfUserClientPosInd) {
            this.playerIconBG.scale.set(0.9);
        } else {
            this.playerIconBG.scale.set(1);
        }
        //this.playerIconBG.anchor.set(0.5);
        this.PlayerViewContainer.addChild(this.playerIconBG);
        this.displayUtil.setObjectPosition(this.playerIconBG, this.playerIconPositions.x[i], this.playerIconPositions.y[i]);
        this.playerIconBG.index = i;
        this.displayUtil.addInteraction(this.playerIconBG, this.onPlayerIconBGClick.bind(this, this.playerIconBG.index));
        this.defaultIconBG = this.displayUtil.getSprite('avatar_42');
        this.defaultIconBG.name = 'defaultAvatar';
        this.defaultIconBG.anchor.set(0.5);
        this.playerIconBG.addChild(this.defaultIconBG);
        this.displayUtil.setObjectPosition(this.defaultIconBG, 0, 0);
        this['playerBetTextVal' + i] = this.displayUtil.getTextField(this.fontProps.playerBet);
        this.PlayerViewContainer.addChild(this['playerBetTextVal' + i]);
        this['playerBetTextVal' + i].anchor.set(0.5);
        this['playerBalnceTextVal' + i] = this.displayUtil.getTextField(buttonLabelText);
        this.PlayerViewContainer.addChild(this['playerBalnceTextVal' + i]);
        this['playerBalnceTextVal' + i].anchor.set(0.5);
        this.betTimerTxt = this.displayUtil.getTextField(this.fontProps.waitingText);
        this.PlayerViewContainer.addChild(this.betTimerTxt);
        this.betTimerTxt.anchor.set(0.5);
        this.betTimerTxt.name = 'betTimerTxt';
        let xPos = -50;
        if (i == 0) {
            xPos = 150;
        }
        this.displayUtil.setObjectPosition(this.betTimerTxt, xPos, 0);
        this.betTimerTxt.visible = false;
        this.totalWinAmountBg = this.displayUtil.getSprite('actionTextBg');
        this.PlayerViewContainer.addChild(this.totalWinAmountBg);
        this.totalWinAmountBg.visible = false;
        this.totalWinAmount = this.displayUtil.getTextField(this.fontProps.totalWinFont);
        this.PlayerViewContainer.addChild(this.totalWinAmount);
        this.totalWinAmount.anchor.set(0.5);
        this.isClickEnabled = true;
        this.createTimerCircle();
        // this.createSpotLights(i);
        this.playerSlctdDataOptions();
        this.createTimeBankAnim();
        this.cashOutEnabled = false;
        // this.createCashOutAnim();
        // this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_WINCHIPS_ANIMATION_COMPLETED, this.disableAllInGlow.bind(this));
        //this.eventDispatcher.addEventListener(SlotCoreGameEvent.STOP_SOUNDS,this.stopTimerSound.bind(this));
        // this.checkForScale();
        const seatAlign = this.getSeatAlignmentforGap(i, maxNoOfSeats);
        if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
            ResponsiveContainer.call(this.PlayerViewContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignX: 'LEFT',
                EventDispatcher: this.eventDispatcher,
            });
        } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            ResponsiveContainer.call(this.PlayerViewContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignX: 'RIGHT',
                EventDispatcher: this.eventDispatcher,
            });
        } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_TOP) {
            ResponsiveContainer.call(this.PlayerViewContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignY: 'TOP',
                EventDispatcher: this.eventDispatcher,
            });
        } else {
            ResponsiveContainer.call(this.PlayerViewContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignY: 'BOTTOM',
                EventDispatcher: this.eventDispatcher,
            });
        }
        this.PlayerViewContainer.setSize(145, 335.5);
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.PLAYER_CONTAINER_POS, {
            index: i,
            PosX: this.PlayerViewContainer.x,
            PosY: this.PlayerViewContainer.y,
            scale: this.PlayerViewContainer.scale.x,
        });
    };
    hidePlayerBalance = function () {
        this.showPlayerBalanceTxt = false;
        this.balanceTxt.visible = false;
    };
    showPlayerBalance = function () {
        this.showPlayerBalanceTxt = true;
        this.balanceTxt.visible = true;
    };
    disableAllInGlow = function () {
        setTimeout(
            function () {
                if (this.isAllInPlaying) {
                    this.allInGlowAni.visible = false;
                    this.allInGlowAni.stop();
                    this.isAllInPlaying = false;
                    this.totalWinAmntBg.visible = false;
                    this.totalWinAmnt.visible = false;
                }
            }.bind(this),
            400,
        );
        // this.totalWinAmntBg.visible = false;
        // this.totalWinAmnt.visible = false;
    };
    //to get seatalignment as left / center / right / top
    getSeatAlignmentforGap = function (clientSeatNo, maxSeats) {
        if (clientSeatNo == uiConfig.selfUserClientPosInd) return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
        const centerSeatNo = maxSeats / 2;
        if (centerSeatNo == clientSeatNo) return serverIdConstants.SEAT_ALIGNMENT_TOP;
        return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    };
    checkForScale = function () {
        // if (uiConfig.windowWidth >= 400) {
        //   //this.playerIconBG.scale.set(1.20);
        //   if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) {
        //     this.defaultPlate.y = this.defaultPlate.y + 10;
        //     this.initialPlateTxt.y = this.initialPlateTxt.y + 10;
        //     this.balanceTxt.y = this.balanceTxt.y + 10;
        //     this.userNameTxt.y = this.userNameTxt.y + 10;
        //     this.timerAnimation.scale.set(0.5);
        //     this.sandTimerAnim.scale.set(0.5);
        //   } else {
        //     this.timerAnimation.y = 50.5;
        //     this.sandTimerAnim.y = 50.5;
        //     this.timerAnimation.scale.set(0.55);
        //     this.sandTimerAnim.scale.set(0.55);
        //   }
        //   if (this.playerIcon) {
        //     // this.playerIcon.scale.set(1.20);
        //   }
        //   // doing below if scale for 7 & 8 seater, decreasing in icons n container
        //   // if (this.gameService.getMaxNoOfSeats() >= 7) {
        //   // if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd)
        //   //   this.PlayerViewContainer.scale.set(0.8);
        //   //}
        // }
        if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) this.PlayerViewContainer.scale.set(0.9);
    };
    playerSlctdDataOptions = function () {};
    disablePlayerClick = function () {
        this.isClickEnabled = false;
    };
    enablePlayerClick = function () {
        this.isClickEnabled = true;
    };
    onPlayerIconBGClick = function (index) {
        if (this.isAllInPlaying == true) return;
        if (this.isClickEnabled || index > 0) {
            if (this.clientSeatPosition != uiConfig.selfUserClientPosInd) {
                if (!this.gameId) return;
                if (!this.gameService.getIsSeatOccupied(this.gameService.getServerSideSeatPos(this.clientSeatPosition))) return;
                if (!this.gameService.getSelfEmojiStatus()) return;
            }
            //this.gameService.getSecondaryAssetsStatus();
            if (this.gameService.secondaryAssetsLoaded || index == 0) {
                this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_EMOJIS_NOW, {
                    index: this.clientSeatPosition,
                    playerName: this.userNameTxt.text,
                    iconImageNo: this.imageNum,
                });
            }
        }
    };
    showOtherPlayerRabit = function (seatNo) {
        // start rabbit ears
        let requiredAnimObj = 'opponentRabbitEntryAni';
        this.opponentRabbitEntryDetails = this.displayUtil.getSpriteDetails(requiredAnimObj);
        this.opponentRabbitEntryAni = this.displayUtil.getCustomAnimatedSprite(this.opponentRabbitEntryDetails);
        this.opponentRabbitEntryAni.loop = false;
        this.opponentRabbitEntryAni.visible = true;
        const posX = seatNo == uiConfig.selfUserClientPosInd ? this.opponentRabbitPos.x[seatNo] - 91 : this.opponentRabbitPos.x[seatNo] - 84;
        const posY = seatNo == uiConfig.selfUserClientPosInd ? this.opponentRabbitPos.y[seatNo] - 152 : this.opponentRabbitPos.y[seatNo] - 145;
        this.opponentRabbitEntryAni.x = posX;
        this.opponentRabbitEntryAni.y = posY;
        this.PlayerViewContainer.addChildAt(this.opponentRabbitEntryAni, 0);
        this.opponentRabbitEntryAni.play();
        // idle rabbit ears
        this.opponentRabbitIdleStage = this.displayUtil.getSprite('ear10');
        this.opponentRabbitIdleStage.x = posX;
        this.opponentRabbitIdleStage.y = posY;
        this.PlayerViewContainer.addChildAt(this.opponentRabbitIdleStage, 0);
        this.opponentRabbitIdleStage.visible = false;
        // exit rabbit ears
        requiredAnimObj = 'opponentRabbitExitAni';
        this.opponentRabbitExitDetails = this.displayUtil.getSpriteDetails(requiredAnimObj);
        this.opponentRabbitExitAni = this.displayUtil.getCustomAnimatedSprite(this.opponentRabbitExitDetails);
        this.opponentRabbitExitAni.loop = false;
        this.opponentRabbitExitAni.visible = false;
        this.opponentRabbitExitAni.x = posX;
        this.opponentRabbitExitAni.y = posY;
        this.PlayerViewContainer.addChildAt(this.opponentRabbitExitAni, 0);
        this.opponentRabbitEntryAni.onComplete = function () {
            this.opponentRabbitEntryAni.visible = false;
            this.opponentRabbitIdleStage.visible = true;
            this.removeChild(this.opponentRabbitEntryAni);
            this.opponentRabbitIdleStageTimer = setTimeout(
                function () {
                    this.opponentRabbitIdleStage.visible = false;
                    this.opponentRabbitExitAni.visible = true;
                    this.opponentRabbitExitAni.gotoAndPlay(0);
                    this.removeChild(this.opponentRabbitIdleStage);
                    this.removeChild(this.opponentRabbitExitAni);
                }.bind(this),
                350,
            );
        }.bind(this);
    };
    handlePlayerStatus = function (playersData) {
        this.updateAddPlayerData(playersData);
    };
    updateAddPlayerData = function (data) {
        let addPlayerData = Object.assign({}, data);
        this.visible = true;
        /*    {"balance":50000,"brandId":"BWINCOM","city":"XY","country":"XY","imageUrl":null,"isCustomAvatar":false,"isSeatedPlayer":true,"playerId":"Player 3","seatingPosition":2,"sex":"M","avatarImagePath":null,"avatarImageName":null,"playerAccountId":0,"playerFullName":null,"playerRealName":null,"msgNumber":0,"peerId":5133284,"className":"AddPlayer"}*/
        this.initialPlateTxt.visible = false;
        this.playerIconBG.children[0].visible = false;
        this.playerId = addPlayerData.playerId;
        if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.balanceTxt.text = CommonGameProperties.mathUtil['transform'](addPlayerData.balance, this.gameService.getGameCurrency());
            // this.balanceTxt.text = "$" + addPlayerData.balance/ 100;
        } else {
            this.balanceTxt.text = CommonGameProperties.mathUtil['transform'](addPlayerData.balance, this.gameService.getGameCurrency());
        }

        //this.balanceTxt.text = addPlayerData.balance / 100;
        if (addPlayerData.balance == 0 && this.allInShown) {
            //  this.showAllInAnimation(this.gameService.getClientSideSeatNo(addPlayerData.seatingPosition));
            const actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_AllIn');
            this.balanceTxt.text = actionText;
            this.allInShown = true;
        }
        this.userNameTxt.text = this.isRealNameGame ? addPlayerData.playerRealName : addPlayerData.playerId;
        this.userNameTxt.resolution = window.devicePixelRatio;
        // if (this.userNameTxt.text && this.userNameTxt.text.length > 10) {
        //   this.defaultPlate.scale.set(this.userNameTxt.text.length * 0.09, 1);
        // }
        if (this.showPlayerBalanceTxt == true) {
            this.balanceTxt.visible = true;
        } else {
            this.balanceTxt.visible = false;
        }
        this.userNameTxt.visible = true;
        if (addPlayerData.isCustomAvatar) {
            //addPlayerData.avatarImage = Math.round(Math.random() % 40) + 1;
            // old one playersData.avatarImage = Math.random() * (40 - 1) + 1;
            addPlayerData.avatarImage = this.getFormattedImageValue(addPlayerData); //1or5
        } else {
            addPlayerData.avatarImage = addPlayerData.avatarImage == '42' ? '1' : addPlayerData.avatarImage;
        }
        //If server requests for avatarImage which is not present and is greater than 44 then we are assigning one avatar image from JSON
        //Here, in this case, assigning avatarImage number 43.
        if (parseInt(addPlayerData.avatarImage) > 41) {
            addPlayerData.avatarImage = '41';
        }
        if (this.playerIcon && addPlayerData && addPlayerData.avatarImage) {
            this.displayUtil.setTexture(this.playerIcon, 'avatar_' + addPlayerData.avatarImage);
        } else {
            this.playerIcon = this.displayUtil.getSprite('avatar_' + addPlayerData.avatarImage); //1 to 42
            this.playerIcon.name = 'PlayerIcon';
            this.playerIconBG.addChild(this.playerIcon);
            this.playerIcon.visible = false;
            this.activePlaterStatus = false;
            this.displayUtil.setObjectPosition(this.playerIcon, 0, 0);
        }
        this.playerIcon.anchor.set(0.5);
        if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) {
            this.playerIcon.scale.set(1);
        }
        this.displayUtil.setClickable(this.playerIconBG, true);
        this.playerIcon.visible = true;
        this.activePlaterStatus = true;
        this.PlayerViewContainer.alpha = 1;
        this.makePlayerActive(this.PlayerViewContainer.alpha);
        this.imageNum = addPlayerData.avatarImage; //1to42
        if (this.gameService.gTableType == serverIdConstants.TABLE_TYPE_SPINS && addPlayerData.avatarImage == null) {
            this.playerIcon.visible = false;
            this.defaultIconBG.visible = true;
            this.activePlaterStatus = false;
        }
    };
    getFormattedImageValue = function (addPlayerData) {
        let avatarId;
        if (addPlayerData && addPlayerData.avatarImage) {
            const str = addPlayerData.avatarImage.toString();
            let totalSum = 0;
            for (let i = 0; i < str.length; i++) {
                // eslint-disable-next-line unicorn/prefer-code-point
                totalSum += str.charCodeAt(i);
            }
            avatarId = totalSum % 42;
            if (avatarId == 0 || avatarId == 34 || avatarId == 42) {
                avatarId = 1;
            }
            return avatarId.toString();
        }
    };
    clearBetTimer = function () {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = undefined;
        }
        if (this.animatedBgTween) {
            for (let k = 0; k < this.animatedBgTween.length; k++) {
                this.animatedBgTween[k].kill();
            }
        }
        this.animatedBgTween = [];
    };
    updateRemovePlayerData = function (removePlayerData, gameTableStatus, isTourneyStarted) {
        //this.visible = false;
        //this.PlayerViewContainer.visible = false;
        if (!this.playerIconBG.children[0].visible) this.playerIconBG.children[0].visible = true;
        if (this.playerIcon) this.playerIcon.visible = false;
        this.activePlaterStatus = false;
        if (this.balanceTxt) this.balanceTxt.visible = false;
        if (this.userNameTxt) this.userNameTxt.visible = false;
        this.PlayerViewContainer.alpha = 1;
        this.activePlaterStatus = true;
        this.makePlayerActive(this.PlayerViewContainer.alpha);

        if (gameTableStatus.tableCategory == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.initialPlateTxt.visible = true;
            this.initialPlateTxt.text = '';
            if (!isTourneyStarted)
                this.initialPlateTxt.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_TOURNAMENTLOBBY_HEADSUPWAITING'); //$.__("WAITING");
            else this.PlayerViewContainer.alpha = 0.65;
            this.activePlaterStatus = false;
            this.makePlayerActive(this.PlayerViewContainer.alpha);
        } else {
            this.initialPlateTxt.visible = true;
            this.initialPlateTxt.text = '';
            this.PlayerViewContainer.alpha = 0.65;
            this.activePlaterStatus = false;
            this.makePlayerActive(this.PlayerViewContainer.alpha);
        }
    };
    showPlayerAction = function (actionText, clientSideSeatNo) {
        /*if(this.otherPlySlctdoptInprogress){
          setTimeout(function(){
            this.showPlayerAction(actionText)
          }.bind(this), 1000)
          return;
        }*/
        // let spriteName:any;
        // switch (optionId) {
        //   case serverIdConstants.OPTION_BET:
        //   case serverIdConstants.OPTION_BET_TO:
        //     spriteName = "bet";
        //     break;
        //   case serverIdConstants.OPTION_RAISE:
        //   case serverIdConstants.OPTION_RAISE_TO:
        //   case serverIdConstants.OPTION_RAISE_ON_PAIR:
        //     spriteName = "bet"; //"raise";
        //     break;
        //   case serverIdConstants.OPTION_FOLD:
        //     spriteName = "fold";
        //   default:
        //     spriteName = "call";
        // }
        let bgColor = '0x000000';
        //let actionText = facade.langPack.getCAPSString(actionText);
        actionText = actionText.toUpperCase();
        if (clientSideSeatNo == uiConfig.selfUserClientPosInd) {
            switch (actionText) {
                case 'CALL':
                    bgColor = '0x2593d8';
                    break;
                case 'CHECK':
                    bgColor = '0x2593d8';
                    break;
                case 'RAISE':
                    bgColor = '0xe8490d';
                    break;
                case 'BET':
                    bgColor = '0xe8490d';
                    break;
                case 'FOLD':
                    bgColor = '0x000000';
                    break;
                case 'ALL-IN':
                    bgColor = '0xe8490d';
                    break;
            }
        } else {
            switch (actionText) {
                case 'CALL':
                    bgColor = '0x2593d8';
                    break;
                case 'CHECK':
                    bgColor = '0x2593d8';
                    break;
                case 'RAISE':
                    bgColor = '0xe8490d';
                    break;
                case 'BET':
                    bgColor = '0xe8490d';
                    break;
                case 'FOLD':
                    bgColor = '0x000000';
                    break;
                case 'ALL-IN':
                    bgColor = '0x2593d8';
                    break;
            }
        }
        this.otherPlySlctdoptInprogress = true;
        this.PlayerViewContainer.removeChild(this.otherPlySlctdopt);
        this.selectedPlyText = this.displayUtil.getTextField(this.fontProps.playerActionLabelFont);
        this.selectedPlyText.text = actionText;
        this.selectedPlyText.resolution = window.devicePixelRatio;
        this.otherPlySlctdopt = this.displayUtil.getBorderRoundRectangleSprite(
            this.selectedPlyText.width + 24,
            this.selectedPlyText.height + 7,
            bgColor,
            10,
            false,
        );
        this.otherPlySlctdopt.resolution = window.devicePixelRatio;
        this.otherPlySlctdopt.name = 'foldActionLabel';
        if (this.gameService.getSeatAlignment(clientSideSeatNo) == uiConfig.selfUserClientPosInd && actionText == 'FOLD')
            this.otherPlySlctdopt.alpha = 0.65;
        this.PlayerViewContainer.addChild(this.otherPlySlctdopt);
        this.otherPlySlctdopt.addChild(this.selectedPlyText);
        this.selectedPlyText.scale.set(1);
        switch (this.gameService.getSeatAlignment(clientSideSeatNo)) {
            case serverIdConstants.SEAT_ALIGNMENT_RIGHT:
                this.displayUtil.setObjectPosition(
                    this.otherPlySlctdopt,
                    this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 44,
                    this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 72,
                );
                this.displayUtil.setObjectPosition(this.selectedPlyText, 12, 3.2);
                break;
            case serverIdConstants.SEAT_ALIGNMENT_LEFT:
                this.displayUtil.setObjectPosition(
                    this.otherPlySlctdopt,
                    this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 21,
                    this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 70,
                );
                this.displayUtil.setObjectPosition(this.selectedPlyText, 12, 3.2);
                break;
            case serverIdConstants.SEAT_ALIGNMENT_BOTTOM:
                this.displayUtil.setObjectPosition(
                    this.otherPlySlctdopt,
                    this.playerIconPositions.x[this.defaultPlate.seatingPosition] + 5,
                    this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 66,
                );
                this.displayUtil.setObjectPosition(this.selectedPlyText, 12, 3.2);
                break;
            case serverIdConstants.SEAT_ALIGNMENT_TOP:
                this.displayUtil.setObjectPosition(
                    this.otherPlySlctdopt,
                    this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 43,
                    this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 70,
                );
                this.displayUtil.setObjectPosition(this.selectedPlyText, 12, 3.2);
                break;
        }
        this.selectedPlyText.text = actionText;
        setTimeout(
            function () {
                this.otherPlySlctdoptInprogress = false;
                this.PlayerViewContainer.removeChild(this.otherPlySlctdopt);
            }.bind(this),
            1000,
        );
    };
    hidePlayerAction = function () {
        this.otherPlySlctdoptInprogress = false;
        this.PlayerViewContainer.removeChild(this.otherPlySlctdopt);
    };
    showLockIconAnim = function () {
        this.lockBalanceAnim.visible = true;
        this.lockBalanceAnim.gotoAndPlay(0);
        this.lockBalanceAnim.onComplete = function () {
            this.lockBalanceAnim.visible = false;
            this.lockBalanceAnim.stop();
        }.bind(this);
    };

    handlechangePlayerState = function (playerStateData) {
        switch (playerStateData.state) {
            case serverIdConstants.PLAYERSTATE_ALL_IN:
                setTimeout(
                    function () {
                        if (this.cashOutEnabled != true) {
                            this.showAllInAnimation(this.gameService.getClientSideSeatNo(playerStateData.seatNo));
                            this.hidespotLight(this.gameService.getClientSideSeatNo(playerStateData.seatNo));
                        }
                        this.hidespotLight(this.gameService.getClientSideSeatNo(playerStateData.seatNo));
                    }.bind(this),
                    50,
                );
                const actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_AllIn');
                this.balanceTxt.text = actionText;
                this.allInShown = true;
                break;
            case serverIdConstants.PLAYERSTATE_FOLDED:
                this.showBackCards = false;
                this.PlayerViewContainer.alpha = 0.65;
                for (let i = 0; i < this.PlayerViewContainer.children.length; i++) {
                    if (this.PlayerViewContainer.children[i].name != 'foldActionLabel') {
                        this.PlayerViewContainer.children[i].alpha = 0.65;
                    }
                }
                break;
            case serverIdConstants.PLAYERSTATE_SIT_OUT:
                this.showBackCards = false;
                this.PlayerViewContainer.alpha = 0.65;
                this.activePlaterStatus = false;
                this.makePlayerActive(this.PlayerViewContainer.alpha);
                break;
            case serverIdConstants.PLAYERSTATE_SIT_IN:
                this.showBackCards = true;
                this.PlayerViewContainer.alpha = 1;
                this.activePlaterStatus = true;
                this.makePlayerActive(this.PlayerViewContainer.alpha);
                if (
                    this.gameService.getSeatAlignment(playerStateData.seatNo) == uiConfig.selfUserClientPosInd &&
                    this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF
                ) {
                    const message = {};
                    message['className'] = 'UpdateGameTableStatus';
                    message['tableId'] = this.gameService.getPeerId();
                    message['status'] = serverIdConstants.PLAYERSTATE_SIT_IN;
                    this.eventDispatcherGlobal?.dispatchEvent('gameEventListener', message);
                }
                break;
        }
        this.playerSeatNo = this.gameService.getClientSideSeatNo(playerStateData.seatNo);
    };
    makePlayerActive = function (alphaVal) {
        for (let i = 0; i < this.PlayerViewContainer.children.length; i++) {
            if (this.PlayerViewContainer.children[i].name && alphaVal != 1) {
                if (this.PlayerViewContainer.children[i].name == 'youWinAni') {
                    this.PlayerViewContainer.children[i].alpha = alphaVal - 0.15;
                }
            } else {
                this.PlayerViewContainer.children[i].alpha = alphaVal;
            }
        }
    };
    handleAnteData = function (anteData, userBalance) {
        if (userBalance == 0) {
            this.showAllInAnimation(this.gameService.getClientSideSeatNo(anteData.seatNo));
        }
        let actionText = '';
        actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_ANTE');
        if (userBalance == 0) {
            actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_AllIn');
            this.balanceTxt.text = actionText;
            this.allInShown = true;
        }
    };
    handleBlindData = function (blinddata, userBalance) {
        if (userBalance == 0) {
            this.showAllInAnimation(this.gameService.getClientSideSeatNo(blinddata.seatNo));
        }
        let actionText = '';
        switch (blinddata.blindType) {
            case serverIdConstants.BLIND_TYPE_SMALL:
            case serverIdConstants.BLIND_TYPE_MISSED_SMALL:
                actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_POSTSB');
                break;
            case serverIdConstants.BLIND_TYPE_BIG:
            case serverIdConstants.BLIND_TYPE_NEW_PLAYER:
                actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLEDLG_POSTBB');
                break;
            case serverIdConstants.BLIND_TYPE_BIG_PLUS_DEAD:
                actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLEDLG_POSTBB') + 'deadAmt';
                break;
            default:
                actionText = '';
        }
        if (userBalance == 0) {
            actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_AllIn');
            this.balanceTxt.text = actionText;
            this.allInShown = true;
        }
        //this.showPlayerAction(actionText);
    };
    updateOthPlayerSelectedOpt = function (selectedOptData, clientSideSeatNo) {
        // if(this.otherPlySlctdopt){
        //  this.PlayerViewContainer.removeChild(this.otherPlySlctdopt);
        // }
        let actionText = '';
        if (selectedOptData.isAllIn) {
            this.showAllInAnimation(this.gameService.getClientSideSeatNo(selectedOptData.seatNo));
            actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_AllIn');
            this.balanceTxt.text = actionText;
            this.allInShown = true;
        } else {
            actionText = uiConfig.selectedOptionSet[selectedOptData.optionId];
            switch (actionText) {
                case 'FOLD':
                    actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_FOLD');
                    break;
                case 'CALL':
                    actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_CALL');
                    break;
                case 'CHECK':
                    actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_CHECK');
                    break;
                case 'BET':
                    actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_BET');
                    break;
                case 'RAISE':
                    actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_RAISE');
                    break;
            }
        }
        if (actionText) {
            //this.showPlayerAction(actionText, clientSideSeatNo, selectedOptData.optionId);
            this.showPlayerAction(actionText, clientSideSeatNo);
        }
    };
    updatePlayerBalance = function (playerBalance) {
        if (this.balanceTxt) {
            //this.balanceTxt.text = playerBalance.balance / 100;
            if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
                this.balanceTxt.text = CommonGameProperties.mathUtil['transform'](playerBalance.balance, this.gameService.getGameCurrency());
            } else {
                this.balanceTxt.text = playerBalance.balance / 100;
            }
            if (playerBalance.balance == 0 && this.allInShown) {
                //  this.showAllInAnimation(this.gameService.getClientSideSeatNo(playerBalance.seatNo));
                const actionText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_H5OPTIONS_AllIn');
                this.balanceTxt.text = actionText;
                this.allInShown = true;
            }
        }
    };
    createTimeBankAnim = function () {
        // this.timeBankText = this.displayUtil.getTextField(this.fontProps.diamondText);
        this.timeBankText = this.displayUtil.getTextField(this.fontProps.timeBankText);
        this.PlayerViewContainer.addChild(this.timeBankText);
        this.timeBankText.visible = false;
        this.timeBankText.text = '';
        this.timeBankText.anchor.set(0, 0.5);
        this.displayUtil.setObjectPosition(
            this.timeBankText,
            this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 43,
            this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 75,
        );
        const timeBankOtherPlayerseq = this.displayUtil.getSpriteDetails('timeBankOtherPlayer');
        this.otherPlayertimeBankAni = this.displayUtil.getCustomAnimatedSprite(timeBankOtherPlayerseq);
        this.PlayerViewContainer.addChild(this.otherPlayertimeBankAni);
        this.displayUtil.setObjectPosition(
            this.otherPlayertimeBankAni,
            this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 110,
            this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 135,
        );
        this.otherPlayertimeBankAni.visible = false;
    };
    createTimerCircle = function () {
        const objAllIn = this.displayUtil.getSpriteDetails('timerAni');
        this.timerAnimationAllIn = this.displayUtil.getCustomAnimatedSprite(objAllIn);
        this.timerAnimationAllIn.anchor.set(0.5);
        this.timerAnimationAllIn.scale.set(1.03);
        this.PlayerViewContainer.addChild(this.timerAnimationAllIn);
        this.timerAnimationAllIn.loop = false;
        this.timerAnimationAllIn.visible = false;
        this.displayUtil.setObjectPosition(
            this.timerAnimationAllIn,
            this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 1,
            this.playerIconPositions.y[this.defaultPlate.seatingPosition],
        );
        const timebankObjAllIn = this.displayUtil.getSpriteDetails('sandTimerAnim');
        this.sandTimerAnimAllIn = this.displayUtil.getCustomAnimatedSprite(timebankObjAllIn);
        this.sandTimerAnimAllIn.anchor.set(0.5);
        this.sandTimerAnimAllIn.scale.set(1.03);
        this.PlayerViewContainer.addChild(this.sandTimerAnimAllIn);
        this.sandTimerAnimAllIn.loop = false;
        this.sandTimerAnimAllIn.visible = false;
        this.displayUtil.setObjectPosition(
            this.sandTimerAnimAllIn,
            this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 1,
            this.playerIconPositions.y[this.defaultPlate.seatingPosition],
        );
        const obj = this.displayUtil.getSpriteDetails('timerAni');
        const timebankObj = this.displayUtil.getSpriteDetails('sandTimerAnim');
        if (obj) {
            this.timerAnimation = this.displayUtil.getCustomAnimatedSprite(obj);
            //this.timerAnimation.gotoAndPlay(20);
            this.timerAnimation.anchor.set(0.5);
            if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) {
                this.timerAnimation.scale.set(0.85);
            } else {
                this.timerAnimation.scale.set(0.96);
            }
            this.PlayerViewContainer.addChild(this.timerAnimation);
            this.timerAnimation.loop = false;
            this.timerAnimation.visible = false;
            this.timerAnimation.name = 'timerAnimation';
            //if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(
                this.timerAnimation,
                this.playerIconPositions.x[this.defaultPlate.seatingPosition],
                this.playerIconPositions.y[this.defaultPlate.seatingPosition],
            );
            // }else{
            //   this.displayUtil.setObjectPosition(this.timerAnimation,
            //     this.playerIconPositions.x[this.defaultPlate.seatingPosition]-0.5,
            //     this.playerIconPositions.y[this.defaultPlate.seatingPosition] + 1);
            // }
        }
        if (timebankObj) {
            this.sandTimerAnim = this.displayUtil.getCustomAnimatedSprite(timebankObj);
            //this.timerAnimation.gotoAndPlay(20);
            this.sandTimerAnim.anchor.set(0.5);

            if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) {
                this.sandTimerAnim.scale.set(0.85);
            } else {
                this.sandTimerAnim.scale.set(0.96);
            }
            this.PlayerViewContainer.addChild(this.sandTimerAnim);
            this.sandTimerAnim.loop = false;
            this.sandTimerAnim.visible = false;
            //  if (this.defaultPlate.seatingPosition != uiConfig.selfUserClientPosInd) {
            this.displayUtil.setObjectPosition(
                this.sandTimerAnim,
                this.playerIconPositions.x[this.defaultPlate.seatingPosition],
                this.playerIconPositions.y[this.defaultPlate.seatingPosition],
            );
            // }else{
            //   this.displayUtil.setObjectPosition(this.sandTimerAnim,
            //     this.playerIconPositions.x[this.defaultPlate.seatingPosition],
            //     this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 1);
            // }
        }
    };
    startTimerAni = function (time, allInForSelf) {
        this.allInEnable = allInForSelf;
        const timeOutDataType = this.gameService.getCurrentTimerStatus();
        // const timeOutDataType={
        //   className: "TimeOut",
        //   msgNumber: 0,
        //   peerId: 7254341,
        //   period: 1622574769,
        //   seatNo: 6,
        //   showClockAt: 0,
        //   timeOutType: 1
        // }
        // timeOutDataType.timeOutType=1;
        if (timeOutDataType && timeOutDataType.timeOutType == 1) {
            if (this.allInEnable) {
                this.sandTimerAnimAllIn.visible = true;
                this.sandTimerAnimAllIn.animationSpeed = 600 / (time * 60);
                this.sandTimerAnimAllIn.gotoAndPlay(0);
                this.sandTimerAnimAllIn.onComplete = function () {
                    // facade["table"+this.peerId].soundObj.stopSound(uiConfig.SoundProps.Alert, false);
                    this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIMER_ANI_DONE);
                    this.removeBetTimer();
                }.bind(this);
            } else {
                this.sandTimerAnim.visible = true;
                this.sandTimerAnim.animationSpeed = 600 / (time * 60);
                this.sandTimerAnim.gotoAndPlay(0);
                this.sandTimerAnim.onComplete = function () {
                    // facade["table"+this.peerId].soundObj.stopSound(uiConfig.SoundProps.Alert, false);
                    this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIMER_ANI_DONE);
                    this.removeBetTimer();
                }.bind(this);
            }
        } else {
            if (this.allInEnable) {
                this.timerAnimationAllIn.visible = true;
                this.timerAnimationAllIn.animationSpeed = 600 / (time * 60);
                this.timerAnimationAllIn.gotoAndPlay(0);
                this.timerAnimationAllIn.onComplete = function () {
                    // facade["table"+this.peerId].soundObj.stopSound(uiConfig.SoundProps.Alert, false);
                    this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIMER_ANI_DONE);
                    this.removeBetTimer();
                }.bind(this);
            } else {
                if (this.cashOutEnabled != true || this.playerId == uiConfig.selfUserId) this.timerAnimation.visible = true;
                this.timerAnimation.animationSpeed = 600 / (time * 60);
                this.timerAnimation.gotoAndPlay(0);
                this.timerAnimation.onComplete = function () {
                    // facade["table"+this.peerId].soundObj.stopSound(uiConfig.SoundProps.Alert, false);
                    this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIMER_ANI_DONE);
                    this.removeBetTimer();
                }.bind(this);
            }
        }
        if (!uiConfig.selfUserId) {
            //uiConfig.selfUserId = window.parent.LSConnector.mainConnector.selfUserId;
            uiConfig.selfUserId = 0;
        }
        if (this.playerId == uiConfig.selfUserId) {
            this.torchSelf.visible = true;
            this.torchSelf.loop = true;
            this.torchSelf.gotoAndPlay(0);
        } else if (this.cashOutEnabled != true) {
            this.torchOpp.visible = true;
            this.torchOpp.loop = true;
            this.torchOpp.gotoAndPlay(0);
        }
    };
    playAnimation = function () {
        this.timerAnimation.visible = true;
        this.timerAnimation.animationSpeed = 600 / (5 * 60);
        this.timerAnimation.gotoAndPlay(0);
    };
    showBetTimer = function (timePeriod, allInForSelf) {
        // console.log(this.allInEnable);
        if (this.defaultPlate.seatingPosition == uiConfig.selfUserClientPosInd) {
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIMER_STARTED_NOTIFICATION, timePeriod);
            //facade["table" + this.peerId].soundObj.playSound(uiConfig.SoundProps.optionSetNotification, false);
        }
        this.startTimerAni(timePeriod, allInForSelf);
        if (this.defaultPlate.seatingPosition == uiConfig.selfUserClientPosInd) {
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_TIMER_ANIM, timePeriod);
        }
        //this.betTimerTxt.visible = true;
        this.betTimerTxt.text = timePeriod;
        this.timerInterval = setInterval(
            function () {
                if (this.betTimerTxt.text > 0) {
                    this.betTimerTxt.text -= 1;
                    if (this.defaultPlate.seatingPosition == uiConfig.selfUserClientPosInd && this.betTimerTxt.text == 5) {
                        //facade["table" + this.peerId].soundObj.playSound(uiConfig.SoundProps.Alert, true);
                        if (uiConfig && uiConfig['tableAvailableStatus' + this.peerId]) {
                            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIME_BANK_NOTIFICATION);
                        }
                    }
                } else {
                    /*facade["table"+this.peerId].soundObj.stopSound(uiConfig.SoundProps.Alert, false);
              this.removeBetTimer();*/
                }
            }.bind(this),
            1000,
        );
    };
    removeBetTimer = function () {
        // console.log(this.allInEnable);
        this.allInEnable = false;
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = undefined;
        }
        if (this.defaultPlate.seatingPosition == uiConfig.selfUserClientPosInd) {
            // facade["table"+this.peerId].soundObj.stopSound(uiConfig.SoundProps.Alert, false);
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_BET_OPTIONS);
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.TIMER_STOPPED_NOTIFICATION);
        }
        this.betTimerTxt.visible = false;
        this.timerAnimation.visible = false;
        this.timerAnimationAllIn.visible = false;
        this.timerAnimation.gotoAndStop(0);
        this.timerAnimationAllIn.gotoAndStop(0);
        if (this.torchSelf) {
            this.torchSelf.visible = false;
            this.torchSelf.loop = false;
            this.torchSelf.gotoAndStop(0);
        }
        this.torchOpvisible = false;
        this.torchOploop = false;
        this.torchOpp.gotoAndStop(0);
        this.torchOpp.visible = false;
        this.sandTimerAnim.visible = false;
        this.sandTimerAnim.gotoAndStop(0);
        this.sandTimerAnimAllIn.visible = false;
        this.sandTimerAnimAllIn.gotoAndStop(0);
    };
    // showPotWin = function (amount, potNum, seatNo) {};
    resetGame = function (isSeatOccupied, index) {
        this.dealerImg.visible = false;
        this.cashOutEnabled = false;
        if (this.dealerImgTween) {
            this.dealerImgTween.kill();
        }
        // add below check for FF games as players are showing as darker in start of game
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            this.PlayerViewContainer.alpha = 0.65;
            this.makePlayerActive(this.PlayerViewContainer.alpha);
        } else {
            const alphaValue = this.activePlaterStatus ? 1 : 0.65;
            this.PlayerViewContainer.alpha = alphaValue;
            this.makePlayerActive(this.PlayerViewContainer.alpha);
        }
        // if(this.cashOut)
        // this.cashOut.visible=false;
        // this.cashOut = null;
        // this.playingYouWin = false;
        this.youWinAni.visible = false;
        this.youWinAni.stop();
        this.playerGlowAni.visible = false;
        this.playerGlowAni.stop();
        this.totalWinAmntBg.visible = false;
        this.totalWinAmnt.visible = false;
        if (
            (this.currentIndex == index && isSeatOccupied) ||
            (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS && this.currentIndex == index)
        ) {
            this.allInGlowAni.visible = false;
            this.allInGlowAni.stop();
            this.isAllInPlaying = false;
        }
        if (!this.isBoosterEnabled) {
            this.allInShown = false;
        }
        /* below condition for issue fix,
        if we go for ALL-IN, lost the hand, balance will come to zero, but still all in text is displayed at self player profile balance. and same issue on opponent profile balance.
      */
        if (this.balanceTxt.text == 'All-in ' && !this.allInShown) {
            this.balanceTxt.text = '$0';
        }
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = undefined;
        }
        this.totalWinAmount.text = ' ';
        this.gameId = undefined;
        if (this.otherPlySlctdopt) {
            this.PlayerViewContainer.removeChild(this.otherPlySlctdopt);
        }
        //uncomment later for fade-in test
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            this.playFadeInOut(index);
        }
        if (this.opponentRabbitEntryAni) {
            this.opponentRabbitEntryAni.stop();
            this.opponentRabbitEntryAni.visible = false;
            this.removeChild(this.opponentRabbitEntryAni);
        }
        if (this.opponentRabbitIdleStage) {
            this.opponentRabbitIdleStage.visible = false;
            this.removeChild(this.opponentRabbitIdleStage);
        }
        if (this.opponentRabbitExitAni) {
            this.opponentRabbitExitAni.stop();
            this.opponentRabbitExitAni.visible = false;
            this.removeChild(this.opponentRabbitExitAni);
        }
    };
    handleNewGame = function (gameId) {
        this.gameId = gameId;
    };
    createSpotLights = function (i) {
        const ownObj = this.displayUtil.getSpriteDetails('spotLightown');
        const oppObj = this.displayUtil.getSpriteDetails('spotLightopponent');
        if (ownObj && i == 0) {
            this.torchSelf = this.displayUtil.getCustomAnimatedSprite(ownObj);
            this.PlayerViewContainer.addChild(this.torchSelf);
            this.torchSelf.anchor.set(0.5);
            // this.torchSelf.scale.set(1.1);
            this.torchSelf.visible = false;
            this.torchSelf.loop = false;
            this.displayUtil.setObjectPosition(
                this.torchSelf,
                this.positionConfig.playersUIData.playerSelfTorchPos.x,
                this.positionConfig.playersUIData.playerSelfTorchPos.y,
            );
        }
        if (oppObj) {
            this.torchOpp = this.displayUtil.getCustomAnimatedSprite(oppObj);
            this.PlayerViewContainer.addChild(this.torchOpp);
            this.torchOpp.anchor.set(0.5);
            this.torchOpp.visible = false;
            this.torchOpp.loop = false;
            this.torchOpp.visible = false;
            this.displayUtil.setObjectPosition(this.torchOpp, this.playerIconPositions.x[i], this.playerIconPositions.y[i] - 10);
        }
    };
    playTimerSound = function () {
        if (this.playerId == uiConfig.selfUserId) {
            if (this.torchSelf) {
                if (this.torchSelf.visible == true && this.betTimerTxt.text < 6) {
                    //facade["table"+this.peerId].soundObj.playSound(uiConfig.SoundProps.Alert, true);
                }
            }
        }
    };
    playFadeInOut = function (index) {
        const playerBGTween = gsap.to(this.playerIconBG.scale, 0.2, {
            x: 0,
            y: 0,
            yoyo: true,
            repeat: 1,
            onComplete: function () {
                if (index != uiConfig.selfUserClientPosInd) {
                    this.playerIconBG.scale.set(0.9);
                } else {
                    this.playerIconBG.scale.set(1);
                }
            }.bind(this),
        });
        this.animatedBgTween.push(playerBGTween);
        const playerPlateTween = gsap.to(this.defaultPlate.scale, 0.2, {
            x: 0,
            y: 0,
            yoyo: true,
            repeat: 1,
            onComplete: function () {
                if (index != uiConfig.selfUserClientPosInd) {
                    this.defaultPlate.scale.x = 0.723;
                    this.defaultPlate.scale.y = 0.8;
                } else {
                    this.defaultPlate.scale.set(1);
                }
            }.bind(this),
        });
        this.animatedBgTween.push(playerPlateTween);
        const playerTextTween = gsap.to(this.initialPlateTxt.scale, 0.2, {
            x: 0,
            y: 0,
            yoyo: true,
            repeat: 1,
            onComplete: function () {
                this.initialPlateTxt.scale.set(1);
            }.bind(this),
        });
        this.animatedBgTween.push(playerTextTween);
    };
    hideBoosterItems = function () {};
    enableBoosterItems = function () {};
    showDealerBtnPos = function (dealerSeatPos) {
        const dealerX = this.dealerConfig[this.positionConfig.layOutPos[dealerSeatPos]].x;
        const dealerY = this.dealerConfig[this.positionConfig.layOutPos[dealerSeatPos]].y;
        if (this.dealerImg.visible == false) {
            this.dealerImg.visible = true;
            this.displayUtil.setObjectPosition(this.dealerImg, dealerX, dealerY);
        } else {
            gsap.to(this.dealerImg, 0.5, {
                x: dealerX,
                y: dealerY,
            });
        }
    };
    removeAnimations = function () {};
    callYouWinAni = function (seatNo, amount, totalWinAmount, isUnCalledBet) {
        this.youWinAnimation(seatNo, amount, totalWinAmount, isUnCalledBet);
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_WIN_PROB);
    };
    youWinAnimation = function (seatNo, amount, totalPlayerWinAmount, isUnCalledBet) {
        this.currTablePeerId = 'table' + this.gameService.getPeerId();
        this.totalWinAmntBg.visible = true;
        if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            totalPlayerWinAmount = CommonGameProperties.mathUtil['transform'](totalPlayerWinAmount, this.gameService.getGameCurrency());
        } else {
            totalPlayerWinAmount = totalPlayerWinAmount / 100;
        }
        this.totalWinAmnt.visible = true;
        this.totalWinAmnt.text = '+ ' + totalPlayerWinAmount;
        this.totalWinAmntBg.width = this.totalWinAmnt.width + 10;
        this.totalWinAmntBg.x = this.totalWinAmnt.x;
        this.totalWinAmntBg.y = this.totalWinAmnt.y;
        this.totalWinAmntBg.anchor.set(0.5);
        if (!isUnCalledBet) {
            if (seatNo == uiConfig.selfUserClientPosInd) {
                this.youWinAni.visible = true;
                this.youWinAni.gotoAndPlay(0);
                CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Win, false);
            }
            if (
                this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS &&
                this.activePlaterStatus == false &&
                this.dealerImg.visible == false
            ) {
                this.PlayerViewContainer.alpha = 1;
                this.makePlayerActive(this.PlayerViewContainer.alpha);
            }
            this.playerGlowAni.visible = true;
            this.playerGlowAni.gotoAndPlay(0);
            this.playerGlowAni.onComplete = function () {
                this.playerGlowAni.visible = false;
                this.youWinAni.visible = false;
                this.youWinAni.stop();
                this.playerGlowAni.stop();
                this.totalWinAmntBg.visible = false;
                this.totalWinAmnt.visible = false;
                if (
                    this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS &&
                    this.activePlaterStatus == false &&
                    this.dealerImg.visible == false
                ) {
                    this.PlayerViewContainer.alpha = 0.65;
                    this.makePlayerActive(this.PlayerViewContainer.alpha);
                }
            }.bind(this);
        }
    };
    showAllInAnimation = function (seatNo) {
        if (this.currentIndex == seatNo && !this.isAllInPlaying) {
            this.allInGlowAni.visible = true;
            this.allInGlowAni.loop = true;
            this.allInGlowAni.play();
            this.isAllInPlaying = true;
            if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS && this.activePlaterStatus == false) {
                this.PlayerViewContainer.alpha = 1;
                this.makePlayerActive(this.PlayerViewContainer.alpha);
            }
        }
    };
    callOtherPlayerTimeBank = function (seatNo, tbTime) {
        this.otherPlayertimeBankAni.visible = true;
        this.otherPlayertimeBankAni.gotoAndPlay(0);
        this.otherPlayertimeBankAni.loop = false;
        this.displayUtil.setObjectPosition(
            this.otherPlayertimeBankAni,
            this.playerIconPositions.x[this.defaultPlate.seatingPosition] - 110,
            this.playerIconPositions.y[this.defaultPlate.seatingPosition] - 135,
        );
        this.otherPlayertimeBankAni.onComplete = function () {
            this.otherPlayertimeBankAni.visible = false;
            if (seatNo !== uiConfig.selfUserClientPosInd) {
                this.timeBankText.visible = false;
            }
        }.bind(this);
        setTimeout(
            function () {
                if (tbTime !== undefined) {
                    this.timeBankText.text = '+' + tbTime + ' ' + 'secs';
                    this.timeBankText.visible = true;
                }
            }.bind(this),
            100,
        );
    };
    checkTimeBankAni = function (tbType, time, seatNo) {
        if (tbType == 1 && seatNo != uiConfig.selfUserClientPosInd) {
            this.timeBankText.text = '';
            this.callOtherPlayerTimeBank(seatNo, time);
        }
    };

    disableAllinAnim = function (isSeatOccupied, index) {
        // this.cashOutEnabled = true;
        if (this.currentIndex == index && isSeatOccupied) {
            this.allInGlowAni.visible = false;
            this.allInGlowAni.stop();
            this.isAllInPlaying = false;
        }
    };
    hidespotLight = function (seatNo) {
        if (seatNo == uiConfig.selfUserClientPosInd && this.cashOutEnabled == true) return;
        this.torchOpp.visible = false;
        this.sandTimerAnim.visible = false;
        this.timerAnimation.visible = false;
        this.timerAnimationAllIn.visible = false;
    };
    enableCashOut = function () {
        this.cashOutEnabled = true;
    };
    enableTimer = function () {
        this.timerAnimation.visible = true;
    };
}
