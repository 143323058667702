import { Message } from '../../message';
import { LSTableOccupancyChange } from './LSTableOccupancyChange';

export class LSTableOccupancyDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSTableOccupancyDeltaUpdate';
    private lsTableOccupancyChange: LSTableOccupancyChange | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lsTableOccupancyChange: LSTableOccupancyChange | null = null) {
        super(reqServerPeerId);
        this.lsTableOccupancyChange = lsTableOccupancyChange;
    }

    getLsTableOccupancyChange(): LSTableOccupancyChange | null {
        return this.lsTableOccupancyChange;
    }

    setLsTableOccupancyChange(lsTableOccupancyChange: LSTableOccupancyChange | null) {
        this.lsTableOccupancyChange = lsTableOccupancyChange;
    }
}
