import { Message } from '../../message';
import { CurrencyAmount } from '../../pgSharedMessageFactory/messages/CurrencyAmount';

export class ChallengeHistoryData extends Message {
    private MESSAGE_NAME: string = 'ChallengeHistoryData';
    private challengeId: number = 0;
    private challengeType: number = 0;
    private challengeStatus: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private maxSeat: number = 0;
    private occupiedSeat: number = 0;
    private opponent: string | null = null;
    private lStakeCurrencyAmount: CurrencyAmount | null = null;
    private uStakeCurrencyAmount: CurrencyAmount | null = null;
    private buyinCurrencyAmount: CurrencyAmount | null = null;
    private winCurrencyAmount: CurrencyAmount | null = null;
    private OpponentWinCurrencyAmount: CurrencyAmount | null = null;
    private isSender: boolean | null = false;
    private creatorName: string | null = null;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        challengeId: number = 0,
        challengeType: number = 0,
        challengeStatus: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        maxSeat: number = 0,
        occupiedSeat: number = 0,
        opponent: string | null = null,
        lStakeCurrencyAmount: CurrencyAmount | null = null,
        uStakeCurrencyAmount: CurrencyAmount | null = null,
        buyinCurrencyAmount: CurrencyAmount | null = null,
        winCurrencyAmount: CurrencyAmount | null = null,
        OpponentWinCurrencyAmount: CurrencyAmount | null = null,
        isSender: boolean | null = false,
        creatorName: string | null = null,
        tableId: number = 0,
    ) {
        super(reqServerPeerId);
        this.challengeId = challengeId;
        this.challengeType = challengeType;
        this.challengeStatus = challengeStatus;
        this.gameType = gameType;
        this.limitType = limitType;
        this.maxSeat = maxSeat;
        this.occupiedSeat = occupiedSeat;
        this.opponent = opponent;
        this.lStakeCurrencyAmount = lStakeCurrencyAmount;
        this.uStakeCurrencyAmount = uStakeCurrencyAmount;
        this.buyinCurrencyAmount = buyinCurrencyAmount;
        this.winCurrencyAmount = winCurrencyAmount;
        this.OpponentWinCurrencyAmount = OpponentWinCurrencyAmount;
        this.isSender = isSender;
        this.creatorName = creatorName;
        this.tableId = tableId;
    }

    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
    getChallengeType(): number {
        return this.challengeType;
    }

    setChallengeType(challengeType: number) {
        this.challengeType = challengeType;
    }
    getChallengeStatus(): number {
        return this.challengeStatus;
    }

    setChallengeStatus(challengeStatus: number) {
        this.challengeStatus = challengeStatus;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeat(): number {
        return this.maxSeat;
    }

    setMaxSeat(maxSeat: number) {
        this.maxSeat = maxSeat;
    }
    getOccupiedSeat(): number {
        return this.occupiedSeat;
    }

    setOccupiedSeat(occupiedSeat: number) {
        this.occupiedSeat = occupiedSeat;
    }
    getOpponent(): string | null {
        return this.opponent;
    }

    setOpponent(opponent: string | null) {
        this.opponent = opponent;
    }
    getLStakeCurrencyAmount(): CurrencyAmount | null {
        return this.lStakeCurrencyAmount;
    }

    setLStakeCurrencyAmount(lStakeCurrencyAmount: CurrencyAmount | null) {
        this.lStakeCurrencyAmount = lStakeCurrencyAmount;
    }
    getUStakeCurrencyAmount(): CurrencyAmount | null {
        return this.uStakeCurrencyAmount;
    }

    setUStakeCurrencyAmount(uStakeCurrencyAmount: CurrencyAmount | null) {
        this.uStakeCurrencyAmount = uStakeCurrencyAmount;
    }
    getBuyinCurrencyAmount(): CurrencyAmount | null {
        return this.buyinCurrencyAmount;
    }

    setBuyinCurrencyAmount(buyinCurrencyAmount: CurrencyAmount | null) {
        this.buyinCurrencyAmount = buyinCurrencyAmount;
    }
    getWinCurrencyAmount(): CurrencyAmount | null {
        return this.winCurrencyAmount;
    }

    setWinCurrencyAmount(winCurrencyAmount: CurrencyAmount | null) {
        this.winCurrencyAmount = winCurrencyAmount;
    }
    getOpponentWinCurrencyAmount(): CurrencyAmount | null {
        return this.OpponentWinCurrencyAmount;
    }

    setOpponentWinCurrencyAmount(OpponentWinCurrencyAmount: CurrencyAmount | null) {
        this.OpponentWinCurrencyAmount = OpponentWinCurrencyAmount;
    }
    getIsSender(): boolean | null {
        return this.isSender;
    }

    setIsSender(isSender: boolean | null) {
        this.isSender = isSender;
    }
    getCreatorName(): string | null {
        return this.creatorName;
    }

    setCreatorName(creatorName: string | null) {
        this.creatorName = creatorName;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
