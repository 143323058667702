// import { ByteBuffer } from "./TSByteBuffer";
// import Charset from "./charSet";
import { ByteBuffer } from '../Lib/byteBuffer';
import Charset from '../Lib/charSet';

// export class CodedInputStream {
//     readRawByte(buf: any): number {
//         return buf.get();
//     }

//     readFixed8(buf: any): number {
//         return this.readRawByte(buf);
//     }

//     readRawVarint32(buf: any): number {
//         let tmp = this.readRawByte(buf);

//         if (tmp >= 0) {
//             return tmp;
//         }

//         let result = tmp & 0x7F;

//         tmp = this.readRawByte(buf);

//         if (tmp >= 0) {
//             result = result | (tmp << 7);
//         } else {
//             result = result | ((tmp & 0x7F) << 7);

//             tmp = this.readRawByte(buf);

//             if (tmp >= 0) {
//                 result = result | (tmp << 14);
//             } else {
//                 result = result | ((tmp & 0x7F) << 14);

//                 tmp = this.readRawByte(buf);

//                 if (tmp >= 0) {
//                     result = result | (tmp << 21);
//                 } else {
//                     result = result | ((tmp & 0x7F) << 21);
//                     tmp = this.readRawByte(buf);
//                     result = result | (tmp << 28);

//                     if (tmp < 0) {
//                         for (let i = 0; i < 5; i++) {
//                             if (this.readRawByte(buf) >= 0) {
//                                 return result;
//                             }
//                         }
//                         throw new Error("Malformed varint");
//                     }
//                 }
//             }
//         }

//         return result;
//     }

//     readInt32(buf: any): number {
//         return this.readRawVarint32(buf);
//     }

//     readRawVarint64(buf: any): number {
//         let read = 0;
//         let result = 0;
//         let input = new ByteBuffer();

//         do {
//             read = this.readRawByte(buf);
//             input.put(read);
//         } while ((read & 0x80) !== 0);

//         input.position = 0;

//         if (input.limit < 9) {
//             result = this.readPositiveLong(input);
//         } else {
//             result = this.readNegativeLong(input);
//         }

//         input.dispose();
//         input = null;

//         return result;
//     }

//     readPositiveLong(input: any): number {
//         let result = 0;
//         let read = 0;
//         let count = 0;

//         do {
//             read = input.get();
//             result = result + (read & 0x7F) * Math.pow(2, 7 * count);
//             count++;
//         } while ((read & 0x80) !== 0);

//         return result;
//     }

//     readNegativeLong(input: any): number {
//         let result = 0;
//         let read = 0;
//         const decodedBytes = this.getDecodedBytes(input);
//         const complementForm = this.getTwosComplementForm(decodedBytes);
//         result = this.constructNumber(complementForm);
//         result = -result;

//         return result;
//     }

//     constructNumber(input: any): number {
//         let result = 0;
//         let read = 0;
//         let count = 0;

//         do {
//             read = input.get();
//             result += (read & 0xFF) * Math.pow(2, 8 * count);
//             count++;
//         } while (count < 7);

//         return result;
//     }

//     getTwosComplementForm(input: any): any {
//         const resultBuffer = new ByteBuffer();
//         let byte = 0;
//         let isAddComplete = false;

//         for (let i = 0; i < input.limit; i++) {
//             byte = input.get();
//             byte = ~byte;

//             if (!isAddComplete) {
//                 byte += 1;
//                 if ((byte & 0xFF) !== 0) {
//                     isAddComplete = true;
//                 }
//             }
//             resultBuffer.put(byte);
//         }
//         resultBuffer.flip();

//         return resultBuffer;
//     }

//     getDecodedBytes(input: any): any {
//         const result = new ByteBuffer();
//         let toWrite = 0;

//         // Read first byte
//         let read = input.get();
//         toWrite = read & 0x7F;
//         if ((read & 0x80) === 0x80) {
//             // Read second byte
//             read = input.get();
//             // 7 bits from the last byte and 1 bit from the current
//             toWrite |= read << 7;
//             result.put(toWrite);
//             // 6 bits forwarded
//             toWrite = (read & 0x7F) >>> 1;
//             if ((read & 0x80) === 0x80) {
//                 // Read third byte
//                 read = input.get();
//                 // 6 bits from the last byte and 2 bits from the current
//                 toWrite |= read << 6;
//                 result.put(toWrite);
//                 // 5 bits forwarded
//                 toWrite = (read & 0x7F) >>> 2;
//                 if ((read & 0x80) === 0x80) {
//                     // Read fourth byte
//                     read = input.get();
//                     // 5 bits from the last byte and 3 bits from the current
//                     toWrite |= read << 5;
//                     result.put(toWrite);
//                     // 4 bits forwarded
//                     toWrite = (read & 0x7F) >>> 3;
//                     if ((read & 0x80) === 0x80) {
//                         // Read fifth byte
//                         read = input.get();
//                         // 4 bits from the last byte and 4 bits from the current
//                         toWrite |= read << 4;
//                         result.put(toWrite);
//                         // 3 bits forwarded
//                         toWrite = (read & 0x7F) >>> 4;
//                         if ((read & 0x80) === 0x80) {
//                             // Read sixth byte
//                             read = input.get();
//                             // 3 bits from the last byte and 5 bits from the current
//                             toWrite |= read << 3;
//                             result.put(toWrite);
//                             // 2 bits forwarded
//                             toWrite = (read & 0x7F) >>> 5;
//                             if ((read & 0x80) === 0x80) {
//                                 // Read seventh byte
//                                 read = input.get();
//                                 // 2 bits from the last byte and 6 bits from the current
//                                 toWrite |= read << 2;
//                                 result.put(toWrite);
//                                 // 1 bit forwarded
//                                 toWrite = (read & 0x7F) >>> 6;
//                                 if ((read & 0x80) === 0x80) {
//                                     // Read eighth byte
//                                     read = input.get();
//                                     // 1 bit from the last byte and 7 bits from the current
//                                     toWrite |= read << 1;
//                                     result.put(toWrite);
//                                     // 0 bits forwarded
//                                     toWrite = 0;
//                                     if ((read & 0x80) === 0x80) {
//                                         // Read ninth byte
//                                         read = input.get();
//                                         // 0 bits from the last byte and 7 bits from the current
//                                         toWrite |= read & 0x7F;
//                                         if ((read & 0x80) === 0x80) {
//                                             read = input.get();
//                                             // 7 bits from the last byte and 1 bit from the current
//                                             toWrite |= read << 7;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         result.put(toWrite);
//         result.flip();

//         return result;
//     }
//     // Method to read Int64.
//     readInt64(buf) {
//         return this.readRawVarint64(buf);
//     };

//     // Method to read Bool.
//     readBool(buf) {
//         return this.readRawVarint32(buf) !== 0;
//     };

//     // Method to read String.
//     readString(buf) {
//         var size = this.readRawVarint32(buf);

//         if (size > 0 && size <= buf.limit - buf.position) {
//             var strBuffer = new ByteBuffer(buf.getBytes(size));

//             var str = strBuffer.getString(new Charset.UTF8());

//             strBuffer.dispose();
//             strBuffer = null;

//             return str;
//         }
//         else if (size === 0) {
//             return null;
//         }

//         throw "Size exceeds the buffer size";
//     };

//     //// Method to read Bytes.
//     readBytes(buf) {
//         var size = this.readRawVarint32(buf);

//         if (size > 0 && size <= buf.limit - buf.position) {
//             return buf.getBytes(size);
//         }
//         else if (size === 0) {
//             return null;
//         }

//         throw "Size exceeds the buffer size";
//     };
// }

export class CodedInputStream {
    // Method to read Raw Byte.
    readRawByte(buf: any): number {
        return buf.get();
    }

    // Method to read Fixed8.
    readFixed8(buf: any): number {
        return this.readRawByte(buf);
    }

    // Method to read RawVarint32.
    readRawVarint32(buf: any): number {
        let tmp = this.readRawByte(buf);

        if (tmp >= 0) {
            return tmp;
        }

        let result = tmp & 0x7f;

        tmp = this.readRawByte(buf);

        if (tmp >= 0) {
            result = result | (tmp << 7);
        } else {
            result = result | ((tmp & 0x7f) << 7);

            tmp = this.readRawByte(buf);

            if (tmp >= 0) {
                result = result | (tmp << 14);
            } else {
                result = result | ((tmp & 0x7f) << 14);

                tmp = this.readRawByte(buf);

                if (tmp >= 0) {
                    result = result | (tmp << 21);
                } else {
                    result = result | ((tmp & 0x7f) << 21);
                    tmp = this.readRawByte(buf);
                    result = result | (tmp << 28);

                    if (tmp < 0) {
                        for (let i = 0; i < 5; i++) {
                            if (this.readRawByte(buf) >= 0) {
                                return result;
                            }
                        }
                        throw new Error('Malformed varint');
                    }
                }
            }
        }

        return result;
    }

    // Method to read Int32.
    readInt32(buf: any): number {
        return this.readRawVarint32(buf);
    }

    // Method to read RawVarint64.
    readRawVarint64(buf: any): number {
        let read = 0;
        let result = 0;
        let input: any = new ByteBuffer();

        do {
            read = this.readRawByte(buf);
            input.put(read);
        } while ((read & 0x80) !== 0);

        input.position = 0;

        if (input.limit < 9) {
            result = this.readPositiveLong(input);
        } else {
            result = this.readNegativeLong(input);
        }

        input.dispose();
        input = null;

        return result;
    }

    // Method to read PositiveLong.
    readPositiveLong(input: any): number {
        let result = 0;
        let read = 0;
        let count = 0;

        do {
            read = input.get();
            result = result + (read & 0x7f) * Math.pow(2, 7 * count);
            count++;
        } while ((read & 0x80) !== 0);

        return result;
    }

    readNegativeLong(input: any): number {
        let result = 0;
        // let read = 0;
        const decodedBytes = this.getDecodedBytes(input);
        const complementForm = this.getTwosComplementForm(decodedBytes);
        result = this.constructNumber(complementForm);
        result = -result;

        return result;
    }

    constructNumber(input: any): number {
        let result = 0;
        let read = 0;
        let count = 0;

        do {
            read = input.get();
            result += (read & 0xff) * Math.pow(2, 8 * count);
            count++;
        } while (count < 7);

        return result;
    }

    getTwosComplementForm(input: any): any {
        const resultBuffer = new ByteBuffer();
        let byte = 0;
        let isAddComplete = false;

        for (let i = 0; i < input.limit; i++) {
            byte = input.get();
            byte = ~byte;

            if (!isAddComplete) {
                byte += 1;
                if ((byte & 0xff) !== 0) {
                    isAddComplete = true;
                }
            }
            resultBuffer.put(byte);
        }
        resultBuffer.flip();

        return resultBuffer;
    }

    getDecodedBytes(input: any): any {
        const result = new ByteBuffer();
        let toWrite = 0;

        // Read first byte
        let read = input.get();
        toWrite = read & 0x7f;
        if ((read & 0x80) === 0x80) {
            // Read second byte
            read = input.get();
            // 7 bits from last byte and 1 bit from current
            toWrite |= read << 7;
            result.put(toWrite);
            // 6 bits forwarded
            toWrite = (read & 0x7f) >>> 1;
            if ((read & 0x80) === 0x80) {
                // Read third byte
                read = input.get();
                // 6 bits from last byte and 2 bits from current
                toWrite |= read << 6;
                result.put(toWrite);
                // 5 bits forwarded
                toWrite = (read & 0x7f) >>> 2;
                if ((read & 0x80) === 0x80) {
                    // Read fourth byte
                    read = input.get();
                    // 5 bits from last byte and 3 bits from current
                    toWrite |= read << 5;
                    result.put(toWrite);
                    // 4 bits forwarded
                    toWrite = (read & 0x7f) >>> 3;
                    if ((read & 0x80) === 0x80) {
                        // Read fifth byte
                        read = input.get();
                        // 4 bits from last byte and 4 bits from current
                        toWrite |= read << 4;
                        result.put(toWrite);
                        // 3 bits forwarded
                        toWrite = (read & 0x7f) >>> 4;
                        if ((read & 0x80) === 0x80) {
                            // Read sixth byte
                            read = input.get();
                            // 3 bits from last byte and 5 bits from current
                            toWrite |= read << 3;
                            result.put(toWrite);
                            // 2 bits forwarded
                            toWrite = (read & 0x7f) >>> 5;
                            if ((read & 0x80) === 0x80) {
                                // Read seventh byte
                                read = input.get();
                                // 2 bits from last byte and 6 bits from current
                                toWrite |= read << 2;
                                result.put(toWrite);
                                // 1 bits forwarded
                                toWrite = (read & 0x7f) >>> 6;
                                if ((read & 0x80) === 0x80) {
                                    // Read eighth byte
                                    read = input.get();
                                    // 1 bit from last byte and 7 bits from current
                                    toWrite |= read << 1;
                                    result.put(toWrite);
                                    // 0 bits forwardedtoWrite = 0;
                                    if ((read & 0x80) === 0x80) {
                                        // Read ninth byte
                                        read = input.get();
                                        // 0 bits from last byte and 7 bits from current
                                        toWrite |= read & 0x7f;
                                        if ((read & 0x80) === 0x80) {
                                            read = input.get();
                                            // 7 bits from last byte and 1 bit from current
                                            toWrite |= read << 7;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        result.put(toWrite);
        result.flip();

        return result;
    }

    // Method to read Int64.
    readInt64(buf: any): number {
        return this.readRawVarint64(buf);
    }

    // Method to read Bool.
    readBool(buf: any): boolean {
        return this.readRawVarint32(buf) !== 0;
    }

    // Method to read String.
    readString(buf: any): string | null {
        const size = this.readRawVarint32(buf);

        if (size > 0 && size <= buf.limit - buf.position) {
            let strBuffer: ByteBuffer | null = new ByteBuffer(buf.getBytes(size));

            const str = strBuffer.getString(new Charset.UTF8());

            strBuffer.dispose();
            strBuffer = null;

            return str;
        } else if (size === 0) {
            return null;
        }

        throw new Error('Size exceeds the buffer size');
    }

    // Method to read Bytes.
    readBytes(buf: any): number[] | null {
        const size = this.readRawVarint32(buf);

        if (size > 0 && size <= buf.limit - buf.position) {
            return buf.getBytes(size);
        } else if (size === 0) {
            return null;
        }

        throw new Error('Size exceeds the buffer size');
    }
}
