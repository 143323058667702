import { Message } from '../../message';

export class JoinPoolRequest extends Message {
    private MESSAGE_NAME: string = 'JoinPoolRequest';
    private noOfEntries: number = 0;
    private buyInAmount: number = 0;
    private rebuyToMax: boolean | null = false;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        noOfEntries: number = 0,
        buyInAmount: number = 0,
        rebuyToMax: boolean | null = false,
        conversationId: number = 0,
    ) {
        super(reqServerPeerId);
        this.noOfEntries = noOfEntries;
        this.buyInAmount = buyInAmount;
        this.rebuyToMax = rebuyToMax;
        this.conversationId = conversationId;
    }

    getNoOfEntries(): number {
        return this.noOfEntries;
    }

    setNoOfEntries(noOfEntries: number) {
        this.noOfEntries = noOfEntries;
    }
    getBuyInAmount(): number {
        return this.buyInAmount;
    }

    setBuyInAmount(buyInAmount: number) {
        this.buyInAmount = buyInAmount;
    }
    getRebuyToMax(): boolean | null {
        return this.rebuyToMax;
    }

    setRebuyToMax(rebuyToMax: boolean | null) {
        this.rebuyToMax = rebuyToMax;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
