import { Message } from '../../message';

export class SNGJPInstanceWatchers extends Message {
    private MESSAGE_NAME: string = 'SNGJPInstanceWatchers';
    private observerCardId: number = 0;
    private tournamentID: number = 0;
    private watchersCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, observerCardId: number = 0, tournamentID: number = 0, watchersCount: number = 0) {
        super(reqServerPeerId);
        this.observerCardId = observerCardId;
        this.tournamentID = tournamentID;
        this.watchersCount = watchersCount;
    }

    getObserverCardId(): number {
        return this.observerCardId;
    }

    setObserverCardId(observerCardId: number) {
        this.observerCardId = observerCardId;
    }
    getTournamentID(): number {
        return this.tournamentID;
    }

    setTournamentID(tournamentID: number) {
        this.tournamentID = tournamentID;
    }
    getWatchersCount(): number {
        return this.watchersCount;
    }

    setWatchersCount(watchersCount: number) {
        this.watchersCount = watchersCount;
    }
}
