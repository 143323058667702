import { Message } from '../../message';

export class LSPoolGameStats extends Message {
    private MESSAGE_NAME: string = 'LSPoolGameStats';
    private poolId: number = 0;
    private handsPerHour: number = 0;
    private avgStack: number = 0;
    private avgFlopsPerSeen: number = 0;
    private avgPot: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        poolId: number = 0,
        handsPerHour: number = 0,
        avgStack: number = 0,
        avgFlopsPerSeen: number = 0,
        avgPot: number = 0,
    ) {
        super(reqServerPeerId);
        this.poolId = poolId;
        this.handsPerHour = handsPerHour;
        this.avgStack = avgStack;
        this.avgFlopsPerSeen = avgFlopsPerSeen;
        this.avgPot = avgPot;
    }

    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
    getHandsPerHour(): number {
        return this.handsPerHour;
    }

    setHandsPerHour(handsPerHour: number) {
        this.handsPerHour = handsPerHour;
    }
    getAvgStack(): number {
        return this.avgStack;
    }

    setAvgStack(avgStack: number) {
        this.avgStack = avgStack;
    }
    getAvgFlopsPerSeen(): number {
        return this.avgFlopsPerSeen;
    }

    setAvgFlopsPerSeen(avgFlopsPerSeen: number) {
        this.avgFlopsPerSeen = avgFlopsPerSeen;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
}
