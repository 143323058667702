import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class TimeBankViewModel {
    gameService;
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        if (!this.peerId) {
            this.peerId = this.gameService.getPeerId();
        }
        this.peerId = this.gameService.getPeerId();
        this.view.currentTablePeerId(this.peerId);
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_BET_TIMER, this.checkTimeBankAni.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.TIME_BANK_NOTIFICATION, this.enableTimeBankNotify.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.TIMER_STARTED_NOTIFICATION, this.showTimeBank.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.BET_OPTION_SELECTED, this.hideTimeBankContainer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION, this.hideTimeBankContainer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SELECTED_CASHOUT_OPTION, this.hideTimeBankContainer.bind(this));
    };
    showTimeBank = function () {
        if (this.gameService.getTimeBankBalance() <= 0) {
            return;
        }
        const optionSetData = this.gameService.getOptionSetData();
        if (!optionSetData || !optionSetData.timeBankAutoEnable) return;
        this.view.showTimeBank(this.gameService.getTimeBankBalance());
    };
    hideTimeBankContainer = function () {
        this.view.checkPreviousAniPlaying();
    };
    checkTimeBankAni = function () {
        const obj = this.gameService.getCurrentTimerStatus();
        const peerTime = this.gameTableNotificationService.getTimeForPeer(obj.peerId);
        const timeDiffToShow = Math.ceil(obj.period - peerTime);
        this.view.checkTimeBankAni(obj.timeOutType, timeDiffToShow, this.gameService.getClientSideSeatNo(obj.seatNo));
    };
    resetGame = function () {
        this.view.resetGame();
    };
    showTimeBankContainer = function () {
        this.view.showTimeBankContainer();
    };
    enableTimeBankNotify = function () {
        const obj = this.gameService.getCurrentTimerStatus();
        if (!obj) {
            return;
        }
        const seatNo = this.gameService.getClientSideSeatNo(obj.seatNo);
        const timeBankAvailableTime = this.gameService.getTimeBankBalance();
        const optionSetData = this.gameService.getOptionSetData();
        if (!optionSetData || !optionSetData.timeBankAutoEnable) return;
        if (timeBankAvailableTime == 0 || (optionSetData && optionSetData.timeBankAvailableTime == 0)) {
            return;
        }
        this.view.enableTimeBankNotify(timeBankAvailableTime, seatNo);
    };
    updateTimeBankBalance = function () {
        let timeBankAvailableTime = this.gameService.getTimeBankBalance();
        timeBankAvailableTime = timeBankAvailableTime ? timeBankAvailableTime : 0;
        this.view.updateTimeBankBalance(timeBankAvailableTime);
    };
    playTimerSound = function () {
        this.view.playTimerSound();
    };
}
