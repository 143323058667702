import { Injectable } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { CommonGameProperties } from './PWC-games-lib/lib/game-config/CommonGameProperties';
import AssetPaths from './PWC-games-lib/lib/game-config/assetpath.json';
import LoadConfig from './PWC-games-lib/lib/game-config/loadconfig.json';
import { AssetLoaderService } from './PWC-games-lib/lib/services/asset-loader.service';
import { AssetloaderConfig } from './game/game.model';
import { EventDispatcherGlobal } from './service/event-despacher-global';

@Injectable({ providedIn: 'root' })
export class PokerWebClientDynaconAssetsConfigService {
    private primaryAssets;
    private secondaryAssets;
    private loadByDynaconConfig;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    private readonly logger: PokerLogger;
    constructor(
        private assetLoader: AssetLoaderService,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('pokerwebdynaconassetsconfigserviceLoggingComponent');
        this.primaryAssets = LoadConfig.common.primaryAssets.gameAssets;
        this.secondaryAssets = LoadConfig.common.secondaryAssets.gameAssets;
        this.loadByDynaconConfig = LoadConfig.loadByDynacon;
    }

    /**
     * Load assets based on the given Dynacon configurations.
     * @param {object} dynaconConfigs - The Dynacon configurations.
     */
    loadAssetsonDynaconConfigs(dynaconConfigs) {
        this.logger.info('loadAssetsonDynaconConfigs..!!!!' + JSON.stringify(dynaconConfigs));
        // Load primary spritesheets configuration
        this.primarySpritesheetsConfig(dynaconConfigs);

        // Load primary fonts configuration
        // this.primaryFontsConfig(dynaconConfigs);

        // Load primary sound assets configuration
        //this.primarySoundAssetsConfig(dynaconConfigs);

        // Load primary language spritesheets configuration
        this.primaryLangSpritesheetsConfig(dynaconConfigs);

        // Load secondary assets configuration
        // this.secondaryAssetsConfig(dynaconConfigs);

        // Load secondary sound assets configuration
        //this.secondarySoundAssetsConfig(dynaconConfigs);

        // Load secondary fonts configuration
        // this.secondaryFontsConfig(dynaconConfigs);

        // Load secondary language spritesheets configuration
        // this.secondaryLangSpritesheetsConfig(dynaconConfigs);

        // Initialize asset loader
        this.initializeAssetLoader();
    }

    /**
     * Configures the asset loader and returns the configuration object.
     * @returns The asset loader configuration object.
     */
    configAssetLoader(): AssetloaderConfig {
        return {
            //* The paths of the assets to be loaded.
            assetPaths: AssetPaths,
            // * The configuration for loading the assets.
            loadConfig: LoadConfig,
            //* The device type.
            device: 'mobile',
            // * The resolution of the assets.
            resolution: '@1x',
            // * The language for loading the assets.
            language: 'en',
        };
    }

    /**
     * Initializes the asset loader and loads primary assets.
     */
    initializeAssetLoader() {
        this.logger.info('loadAssets..!!!!');
        // Set the configuration for the asset loader
        this.assetLoader.setConfig(this.configAssetLoader());

        // Load primary assets
        this.assetLoader.loadAssets('primaryAssets').subscribe(() => {
            // Log a message when assets are loaded
            CommonGameProperties.primaryAssetsLoaded = true;
            this.eventDispatcherGlobal.dispatchEvent('onPrimaryAssetsLoadComplete', { status: true });
            this.loadSecondaryAssets();
        });
    }
    private loadSecondaryAssets() {
        this.assetLoader.loadAssets('secondaryAssets').subscribe(() => {
            CommonGameProperties.secondaryAssetsLoaded = true;
            this.eventDispatcherGlobal.dispatchEvent('onSecondaryAssetsComplete', { status: true });
        });
    }

    /**
     * Adds primary spritesheets config to the primary assets.
     * @param {object} dynaconConfigs - The dynacon configs.
     */
    primarySpritesheetsConfig(dynaconConfigs) {
        // Check if dynaconConfigs is not null
        if (dynaconConfigs != null) {
            // Check if mainLobbyTiles.spins is truthy
            if (dynaconConfigs.mainLobbyTiles.spins) {
                // Add primary spritesheets from dynaconConfigs to primaryAssets.spritesheets
                this.primaryAssets.spritesheets.push(...this.loadByDynaconConfig.spins.primary.spritesheets);
            }
        }
    }

    /**
     * Updates the secondary assets configuration.
     * @param dynaconConfigs - The dynamic configuration data.
     */
    secondaryAssetsConfig() {}

    /**
     * This function generates the configuration for primary sound assets.
     * @param dynaconConfigs - The dynacon configurations.
     */
    primarySoundAssetsConfig() {}

    /**
     * Updates the secondary sound assets configuration based on the provided dynaconConfigs.
     * @param dynaconConfigs - The dynaconConfigs object containing the configuration data.
     */
    secondarySoundAssetsConfig(dynaconConfigs: any): void {
        this.logger.info('secondarySoundAssetsConfig..!!!!' + JSON.stringify(dynaconConfigs));
        // Check if dynaconConfigs object is not null
        if (dynaconConfigs != null) {
            // Check if spins property exists in mainLobbyTiles object
            if (dynaconConfigs.mainLobbyTiles.spins) {
                // Push sound assets into the secondary assets loader
                this.pushAssetsIntoObjectAssetLoader(this.secondaryAssets.sounds, this.loadByDynaconConfig.spins.secondary.sounds);
            }
        }
    }

    /**
     * Configures the primary fonts for the given dynacon configurations.
     * @param {any} dynaconConfigs - The dynacon configurations.
     */
    primaryFontsConfig() {}

    /**
     * Configures secondary fonts for the application.
     * @param dynaconConfigs - The dynamic configuration object.
     */
    secondaryFontsConfig() {}

    /**
     * Updates the primary language spritesheets configuration based on dynaconConfigs.
     * @param {object} dynaconConfigs - The dynaconConfigs object.
     */
    primaryLangSpritesheetsConfig(dynaconConfigs) {
        if (dynaconConfigs != null) {
            if (dynaconConfigs.mainLobbyTiles.spins) {
                // push assets into asset loader
                // this.pushAssetsintoAssetLoader(this.primaryAssets.spritesheets, this.loadByDynaconConfig.spins.primary.spritesheets);
            }
        }
    }

    /**
     * Generate the configuration for secondary language spritesheets.
     * @param {object} dynaconConfigs - The dynacon configurations.
     */
    secondaryLangSpritesheetsConfig() {
        // Generate secondary language spritesheets configuration
    }

    /**
     * Pushes assets loaded by Dynacon config into the asset loader object.
     * @param {object} loadConfigLoader - The asset loader object.
     * @param {object} assetsLoadedByDynaconConfig - The assets loaded by Dynacon config object.
     */
    pushAssetsIntoObjectAssetLoader(loadConfigLoader, assetsLoadedByDynaconConfig) {
        // Iterate over the keys in the assetsLoadedByDynaconConfig object
        for (const key in assetsLoadedByDynaconConfig) {
            // Check if the key is a property of the assetsLoadedByDynaconConfig object
            if (assetsLoadedByDynaconConfig.hasOwnProperty(key)) {
                // Assign the value of the key in assetsLoadedByDynaconConfig to the corresponding key in loadConfigLoader
                loadConfigLoader[key] = assetsLoadedByDynaconConfig[key];
            }
        }
    }
}
