import { Injectable } from '@angular/core';

import { MessageQueueService } from '@frontend/vanilla/core';
import { PokerApiService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

import { HandHistoryInputModel } from './hand-history.models';

@Injectable({ providedIn: 'root' })
export class HandHistoryService {
    totalCount: number;
    allCgLimits: any;
    allCgActions: any;
    allTrActions: any;
    handHistory: any = {};
    cashGames: any;
    tournaments: any;
    gameTypeCG: any = 1;
    gameTypeTR: any = 1;
    content: any;
    popupToggle: boolean;
    replayHandPosition: number;
    filterGroupedHandsCG: boolean = false;
    filterHandsCG: boolean = false;
    filterGroupedHandsTR: boolean = false;
    filterHandsTR: boolean = false;
    lazyloading: boolean = false;
    singleHand: boolean = false;
    singleGameRound: any;
    nodataCG: boolean = false;
    nodataTR: boolean = false;
    hhGames: string = 'cashgames';
    TodayHandsCG: any = [];
    YesterdayHandsCG: any = [];
    Last7daysHandsCG: any = [];
    Last2weeksHandsCG: any = [];
    Last30daysHandsCG: any = [];
    Last3monthsHandsCG: any = [];
    TodayHandsTR: any = [];
    YesterdayHandsTR: any = [];
    Last7daysHandsTR: any = [];
    Last2weeksHandsTR: any = [];
    Last30daysHandsTR: any = [];
    Last3monthsHandsTR: any = [];
    singleHandStatus: boolean = false;
    activeHandID: any;
    PocketCardOneCG: string;
    PocketCardTwoCG: string;
    PocketCardOneTR: string;
    PocketCardTwoTR: string;
    lazyLoad: boolean = false;
    pageTR: number = 0;
    pageCG: number = 0;
    noHands: boolean = false;
    disableTextView: boolean;
    isLego: any;
    isMobile: boolean; //for mobile potraite replayer
    //isTablet: boolean;
    Idevices: boolean;
    showBottomNavForIdevice: boolean = true;
    isTenSeaterGame: boolean = false;
    showErrorMessage: boolean = false;
    handstoReplayerDashBoard: any = [];
    serverErrorpage: boolean = false;

    constructor(
        private api: PokerApiService,
        private messageQueue: MessageQueueService,
    ) {}

    getHandHistory(handHistoryInputModel: HandHistoryInputModel): Observable<any> {
        return this.api.post('handhistory/GetInitData', handHistoryInputModel);
    }

    getRoundTextViewService(roundModel: any) {
        return this.api.post('handhistory/Round', roundModel);
    }

    getCashGamesActions(gameType: number) {
        return this.api.get('handhistory/GetActions', { gameType: gameType });
    }

    getCashGamesLimits(gameType: number) {
        return this.api.get('handhistory/GetLimits', { gameType: gameType });
    }

    getTournamentActions(gameType: string) {
        return this.api.get('handhistory/GetTournamentActions', { gameType: gameType });
    }

    getDate(time: number) {
        const dateValue: any = time;
        return dateValue.substring(6, 7) + '/' + dateValue.substring(9, 10) + '/' + dateValue.substring(0, 4);
    }

    getReplayer(roundId: string) {
        return this.api.get('handhistory/GetRoundDetails', { Id: roundId });
    }

    getTodayHands(handHistory: any) {
        let hands = handHistory.gameRounds,
            length = hands.length,
            currentDate,
            todayHandsCount = 0;
        currentDate = new Date();
        currentDate = currentDate.toLocaleDateString();
        for (let i = 0; i < length; i++) {
            const d = this.getDate(hands[i].gameRoundTime.start);
            if (d == currentDate) {
                todayHandsCount++;
            }
        }
        return todayHandsCount;
    }

    checkSuitedCards(holeCards: any) {
        const pocketCards: any = [],
            cardOneSet: any = [],
            cardTwoSet: any = [];
        if (this.hhGames === 'cashgames') {
            pocketCards.push(this.PocketCardOneCG, this.PocketCardTwoCG);
        } else {
            pocketCards.push(this.PocketCardOneTR, this.PocketCardTwoTR);
        }
        if (holeCards.length === 2 && holeCards[0].split('')[1] === holeCards[1].split('')[1]) {
            return true;
        } else if (holeCards.length > 2) {
            const hcardsLength = holeCards.length;
            for (let i = 0; i < hcardsLength - 1; i++) {
                for (let j = i + 1; j < hcardsLength; j++) {
                    if (holeCards[i].split('')[1] === holeCards[j].split('')[1]) {
                        return true;
                    }
                }
            }
        }
        for (let i = 0; i < pocketCards.length; i++) {
            holeCards.forEach((hCard: string) => {
                const holeCard = hCard.split('')[0];
                if (pocketCards[i].indexOf(holeCard) > -1) {
                    if (i === 0) {
                        cardOneSet.push(hCard.split('')[1]);
                    } else {
                        cardTwoSet.push(hCard.split('')[1]);
                    }
                }
            });
        }
        cardOneSet.forEach((cardOne: string) => {
            cardTwoSet.forEach((cardTwo: string) => {
                if (cardOne === cardTwo) {
                    return true;
                } else {
                    return false;
                }
            });
        });
        return false;
    }

    getTournaments() {
        const handHistoryInputModel: HandHistoryInputModel = new HandHistoryInputModel();
        handHistoryInputModel.TournamentPocketCardOne = 'XX';
        handHistoryInputModel.TournamentPocketCardTwo = 'XX';
        handHistoryInputModel.TournamentPocketCardSuited = false;
        handHistoryInputModel.TournamentFinalHand = 0;
        handHistoryInputModel.TournamentFinalHandMatch = 0;
        handHistoryInputModel.TournamentType = 0;
        handHistoryInputModel.TournamentGameType = 1;
        handHistoryInputModel.BuyInType = 0;
        handHistoryInputModel.TournamentSearchDate = 'All';
        handHistoryInputModel.Page = 0;
        handHistoryInputModel.SortAscending = false;
        handHistoryInputModel.SortBy = 0;
        handHistoryInputModel.GameCodeType = 2;
        handHistoryInputModel.WonHand = false;
        handHistoryInputModel.AllIn = false;
        handHistoryInputModel.PreflopRaise = false;
        handHistoryInputModel.PreflopCall = false;
        handHistoryInputModel.ShowHand = false;
        handHistoryInputModel.RiverFold = false;
        handHistoryInputModel.WereKnockedOut = false;
        handHistoryInputModel.KnockedOutOthers = false;

        this.getHandHistory(handHistoryInputModel).subscribe(
            (data) => {
                this.totalCount = data.gameRoundMetaData.totalCount;
                this.tournaments = data.gameRoundMetaData;
                this.groupRounds(this.tournaments, 'tournaments');
            },
            (error) => {
                this.messageQueue.clear();
                if (error.errorCode === '403') {
                    this.messageQueue.add(error.vnMessages[0].html);
                } else if (!error.hasOwnProperty('errorCode')) {
                    this.messageQueue.add(error.message);
                }
            },
        );
    }

    groupRounds(handHistory: any, hhGames: string) {
        let currentDate: any = new Date(),
            yesterdayDate: any = new Date(),
            last7Days: any = new Date(),
            last2Weeks: any = new Date(),
            last30Days: any = new Date(),
            last3Months: any = new Date(),
            todayHands: any = [],
            yesterdayHands: any = [],
            last7daysHands: any = [],
            last2weeksHands: any = [],
            last30daysHands: any = [],
            last3monthsHands: any = [],
            handsCount = 0, //this.initialHandCount,
            trimmedHands: any = [],
            totalHandsCount = 0;
        handHistory = handHistory.gameRounds;
        if (hhGames === 'cashgames' && this.pageCG > 0) {
            handsCount = this.pageCG * 100;
        } else if (hhGames === 'tournaments' && this.pageTR > 0) {
            handsCount = this.pageTR * 100;
        }
        if (!this.lazyLoad) {
            trimmedHands = handHistory.slice(0, handsCount + 50);
        } else {
            trimmedHands = handHistory.slice(0, handsCount + 100);
        }
        this.handstoReplayerDashBoard = trimmedHands;
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        yesterdayDate = yesterdayDate.toLocaleDateString();
        last7Days.setDate(last7Days.getDate() - 6);
        last2Weeks.setDate(last2Weeks.getDate() - 13);
        last30Days.setDate(last30Days.getDate() - 29);
        last3Months.setDate(last3Months.getDate() - 89);
        for (let i = 0; i < trimmedHands.length; i++) {
            let dateValue = trimmedHands[i].gameRoundTime.start,
                handDate = trimmedHands[i].gameRoundTime.start,
                suitedElement: any,
                holeCards = trimmedHands[i].playerHoleCards,
                flag = false,
                handsDate: any = new Date(handDate),
                currentDateString = this.getCurrentDateString(currentDate.toLocaleDateString(), 'today'),
                yestarDateString = this.getCurrentDateString(currentDate.toLocaleDateString(), 'yestarday'),
                d = dateValue.substring(5, 7) + '/' + dateValue.substring(8, 10) + '/' + dateValue.substring(0, 4);
            if (hhGames === 'cashgames') {
                suitedElement = document.getElementById('pocketSuitedCG');
            } else {
                suitedElement = document.getElementById('pocketSuitedTR');
            }
            if (suitedElement && suitedElement.checked) {
                flag = this.checkSuitedCards(holeCards);
            } else {
                flag = true;
            }
            if (flag) {
                totalHandsCount++;
                if (d === currentDateString) {
                    todayHands.push(trimmedHands[i]);
                } else if (d === yestarDateString) {
                    yesterdayHands.push(trimmedHands[i]);
                } else if (
                    handsDate.getMonth() == currentDate.getMonth() - 1 ||
                    (currentDate.getMonth() == 0 && handsDate.getYear() === currentDate.getYear() - 1)
                ) {
                    if (last7Days.getDate() <= handsDate.getDate() && last7Days.getMonth() == handsDate.getMonth()) {
                        last7daysHands.push(trimmedHands[i]);
                    } else if (last2Weeks.getDate() <= handsDate.getDate() && last2Weeks.getMonth() == handsDate.getMonth()) {
                        last2weeksHands.push(trimmedHands[i]);
                    } else if (last30Days.getDate() <= handsDate.getDate() && last30Days.getMonth() == handsDate.getMonth()) {
                        last30daysHands.push(trimmedHands[i]);
                    } else if (last30Days.getDate() > handsDate.getDate() && last30Days.getMonth() == handsDate.getMonth()) {
                        last3monthsHands.push(trimmedHands[i]);
                    }
                } else if (handsDate.getMonth() == currentDate.getMonth()) {
                    if (handsDate.getDate() >= last7Days.getDate() && last7Days.getMonth() == handsDate.getMonth()) {
                        last7daysHands.push(trimmedHands[i]);
                    } else if (handsDate.getDate() <= last7Days.getDate() && last7Days.getMonth() != handsDate.getMonth()) {
                        last7daysHands.push(trimmedHands[i]);
                    } else if (handsDate.getDate() >= last2Weeks.getDate() && last2Weeks.getMonth() == handsDate.getMonth()) {
                        last2weeksHands.push(trimmedHands[i]);
                    } else if (handsDate.getDate() <= last2Weeks.getDate() && last2Weeks.getMonth() != handsDate.getMonth()) {
                        last2weeksHands.push(trimmedHands[i]);
                    } else if (handsDate.getDate() >= last30Days.getDate() && last30Days.getMonth() == handsDate.getMonth()) {
                        last30daysHands.push(trimmedHands[i]);
                    } else if (handsDate.getDate() <= last30Days.getDate() && last30Days.getMonth() != handsDate.getMonth()) {
                        last30daysHands.push(trimmedHands[i]);
                    }
                } else if (
                    (handsDate.getMonth() <= currentDate.getMonth() - 2 && handsDate.getYear() == currentDate.getYear()) ||
                    handsDate.getYear() < currentDate.getYear()
                ) {
                    last3monthsHands.push(trimmedHands[i]);
                }
            }
        }
        if (hhGames === 'cashgames') {
            this.TodayHandsCG = todayHands;
            this.YesterdayHandsCG = yesterdayHands;
            this.Last7daysHandsCG = last7daysHands;
            this.Last2weeksHandsCG = last2weeksHands;
            this.Last30daysHandsCG = last30daysHands;
            this.Last3monthsHandsCG = last3monthsHands;
        } else if (hhGames === 'tournaments') {
            this.TodayHandsTR = todayHands;
            this.YesterdayHandsTR = yesterdayHands;
            this.Last7daysHandsTR = last7daysHands;
            this.Last2weeksHandsTR = last2weeksHands;
            this.Last30daysHandsTR = last30daysHands;
            this.Last3monthsHandsTR = last3monthsHands;
        }
        if (totalHandsCount === 0) {
            this.noHands = true;
        } else {
            this.noHands = false;
        }
        return totalHandsCount;
    }

    //Changes made for modifying the currentdate and yestarday

    getCurrentDateString(value: any, day: any) {
        let changedDate: any;
        let changedMonth: any;
        let changedYear: any;
        if (day === 'today') {
            if (value.substring(0, value.indexOf('/')).length === 1) {
                changedMonth = '0' + value.substring(0, value.indexOf('/'));
            } else {
                changedMonth = value.substring(0, value.indexOf('/'));
            }
            if (value.substring(value.indexOf('/') + 1, value.lastIndexOf('/')).length === 1) {
                changedDate = '0' + value.substring(value.indexOf('/') + 1, value.lastIndexOf('/'));
            } else {
                changedDate = value.substring(value.indexOf('/') + 1, value.lastIndexOf('/'));
            }
        } else if (day === 'yestarday') {
            if (value.substring(0, value.indexOf('/')).length === 1) {
                changedMonth = '0' + value.substring(0, value.indexOf('/'));
            } else {
                changedMonth = value.substring(0, value.indexOf('/'));
            }
            if (value.substring(value.indexOf('/') + 1, value.lastIndexOf('/')).length === 1) {
                changedDate = '0' + value.substring(value.indexOf('/') + 1, value.lastIndexOf('/'));
                const x: any = parseInt(changedDate);
                changedDate = x - 1;
            } else {
                changedDate = value.substring(value.indexOf('/') + 1, value.lastIndexOf('/'));
                const x: any = parseInt(changedDate);
                changedDate = x - 1;
            }
        }
        changedYear = value.substring(value.lastIndexOf('/') + 1, value.lastIndexOf('/') + 5);
        if (changedDate === 0) {
            return this.calculateChangeDate(changedDate, changedMonth, changedYear);
        }

        return changedMonth + '/' + changedDate + '/' + changedYear;
    }
    calculateChangeDate(changedDate: any, changedMonth: any, changedYear: any) {
        const array1: any = ['01', '02', '05', '07', '08', '10', '12'];
        const array2: any = ['04', '06', '09', '11'];
        const month: any = changedMonth;
        if (changedDate === 0) {
            array1.forEach((element: any) => {
                if (element === changedMonth) {
                    changedDate = 31;
                }
            });
        } else if (changedMonth === '03' && changedDate === 1) {
            const year: any = parseInt(changedYear);
            if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
                changedDate = 29;
            } else {
                changedDate = 28;
            }
        } else {
            array2.forEach((element: any) => {
                if (element === changedMonth) {
                    changedDate = 30;
                }
            });
        }
        if (changedDate === 0 && month === '01') {
            changedMonth = '12';
            changedYear = parseInt(changedYear) - 1;
        } else {
            changedMonth = parseInt(changedMonth) - 1;
            if (changedMonth.toString().length === 1) {
                changedMonth = '0' + changedMonth.toString();
            }
        }
        return changedMonth + '/' + changedDate + '/' + changedYear;
    }
}
