import { Message } from '../../message';

export class Payout extends Message {
    private MESSAGE_NAME: string = 'Payout';
    private seatNo: number = 0;
    private payoutInPercentage: number = 0;
    private payoutInCents: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatNo: number = 0, payoutInPercentage: number = 0, payoutInCents: number = 0) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.payoutInPercentage = payoutInPercentage;
        this.payoutInCents = payoutInCents;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getPayoutInPercentage(): number {
        return this.payoutInPercentage;
    }

    setPayoutInPercentage(payoutInPercentage: number) {
        this.payoutInPercentage = payoutInPercentage;
    }
    getPayoutInCents(): number {
        return this.payoutInCents;
    }

    setPayoutInCents(payoutInCents: number) {
        this.payoutInCents = payoutInCents;
    }
}
