import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';

export class WinTableViewModel {
    tableContainer: any;
    gameService: any;
    constructor(gs, EventDispatcher, tableContainer) {
        this.tableContainer = tableContainer;
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SNGJP_TOURNAMENT_RANKINFO, this.createWinScreen.bind(this));
    };
    showDummyView = function () {
        const sngJPTournamentRankInfo = {
            sngJackpotID: 221243395,
            instanceID: 221974025,
            participantNo: 4,
            rank: 2,
            winAmount: {
                amount: 1,
                currencyCode: 'USD',
            },
            replayAllowed: true,
            accountBalance: 1022255,
            ticketAvailable: false,
            fxSnapshotID: 20190916001,
            fxDetails: {
                conversionFactor4Client: 1000000000,
                fromCurrencyCode: 'USD',
                markupType: 'sitngo',
                reverseConversionFactor4Client: 1000000000,
                snapshotId: 20190916001,
                toCurrencyCode: 'USD',
                msgNumber: 0,
            },
            bountyAmount: {
                amount: 0,
                currencyCode: 'USD',
            },
            ticketValue: 0,
            tournamentTokens: null,
            ticketType: 0,
            ticketOnly: false,
            msgNumber: 0,
            peerId: 6387410,
            className: 'SNGJPTournamentRankInfo',
        };
        this.view.createWinScreen(
            this.gameService.getTableStatusData(),
            this.gameService.getPlayerStatusDataAtSeat(uiConfig.selfUserId),
            sngJPTournamentRankInfo,
        );
    };
    createWinScreen = function () {
        setTimeout(
            function () {
                this.view.createWinScreen(
                    this.gameService.getTableStatusData(),
                    this.gameService.getPlayerStatusDataAtSeat(uiConfig.selfUserId),
                    this.gameService.getSNGJPTournamentRankInfo(),
                );
            }.bind(this),
            2000,
        );
    };
    initiateButtons = function () {};
    onRegister = function () {
        const message = {};
        message['className'] = 'AddSimilarGameTable';
        message['gameCount'] = 1;
        message['enableExpoSpin'] = false;
        window.postMessage(message, '*');
    };
    onExpoSpinRegister = function (gameCount, ticketCount) {
        const message = {};
        message['className'] = 'AddSimilarGameTable';
        message['gameCount'] = gameCount;
        message['ticketCount'] = ticketCount;
        message['enableExpoSpin'] = true;
        message['TableId'] = this.gameService.getPeerId();
        // window.postMessage({"newSpinTable":message}, "*");
        CommonGameProperties.facade['sendOpenTableReq'](message);
    };
    onClose = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.CONCLUDED_LEAVE);
    };
}
