<div class="col d-flex event-widgets flex-wrap mb-3 ppl-wrapper">
    <h1 *ngIf="itemsData.title && eventType !== 'isCategoryDetails'; else isCategoryDetails" class="w-100 text-uppercase pl-2 pr-2 pr-sm-0 pl-sm-0">
        {{ itemsData.title }}
        <span
            class="filter-icon d-block d-sm-none ppl-cursor-pointer"
            (click)="displayFilters()"
            *ngIf="itemsData.pplEventWidgetContent.length !== 0"></span>
    </h1>
    <ng-template #isCategoryDetails>
        <h3 class="categoryDetailsTtitle">{{ itemsData.title }}</h3>
    </ng-template>
    <div
        class="p-md-2 p-sm-1 mb-4 event-filter-wrapper"
        [ngClass]="{ 'categoryEvents': eventType === 'categoryEvents', 'd-none': eventType === 'isCategoryDetails' }"
        [class.d-flex]="showFilters === true"
        *ngIf="itemsData.pplEventWidgetContent.length !== 0">
        <div class="dropDown-wrapper" *ngFor="let filter of eventFilters; let i = index">
            <div class="dropDown-title">
                {{ staticStrings.filterBy }}
                {{ staticStrings[filter.type] | titlecase }}
                <span class="theme-right"></span>
            </div>
            <div class="dropDown-options">
                <label class="check-box-label" *ngFor="let item of filter.items"
                    >{{ item }}
                    <input type="checkbox" [name]="item" value="{{ item }}" (change)="returnSelectedItems($event, i)" />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
    <ng-container
        *ngFor="
            let event of events
                | filterEvents: eventFilters[0].selectedItems : eventFilters[1].selectedItems : eventFilters[2].selectedItems
                | sortByDate: 'eventStartDate'
        ">
        <div
            (click)="gotoDetails(event.eventLink)"
            class="mb-4 ppl-event-thumbnail"
            [ngClass]="{ 'col-xs-12 col-sm-6 col-md-4': eventType !== 'isCategoryDetails' }">
            <div class="ppl-thumbnail-img">
                <img
                    class="w-100 rounded"
                    *ngIf="event.pplEventConfigContent.eventImage"
                    [src]="event.pplEventConfigContent.eventImage.src"
                    [alt]="event.pplEventConfigContent.eventImage.alt" />
                <p class="ppl-thumbnail-hover-text" *ngIf="event.pplEventConfigContent.eventIntro">
                    {{ event.pplEventConfigContent.eventIntro }}
                </p>
            </div>
            <h3 class="ppl-thumbnail-title">
                {{ event.pplEventConfigContent.eventTitle }}
            </h3>
            <p class="ppl-thumbnail-description" *ngIf="event.pplEventConfigContent.eventStartDate && event.pplEventConfigContent.eventEndDate">
                {{ returnEventDate(event.pplEventConfigContent.eventStartDate, event.pplEventConfigContent.eventEndDate) }}
            </p>
        </div>
    </ng-container>
    <div
        *ngIf="(events | filterEvents: eventFilters[0].selectedItems : eventFilters[1].selectedItems : eventFilters[2].selectedItems)?.length === 0"
        class="d-block w-100 text-center mt-5 mb-5">
        {{ staticStrings.sorry }}, {{ staticStrings.noResultsFound }}
    </div>
</div>
