import { Message } from '../../message';

export class LSTabIdTableDetails extends Message {
    private MESSAGE_NAME: string = 'LSTabIdTableDetails';
    private snapShotTimeInNanos: number = 0;
    private fullUpdate: boolean | null = false;
    private tables: any[] | null = null;
    private deletedTables: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        snapShotTimeInNanos: number = 0,
        fullUpdate: boolean | null = false,
        tables: any[] | null = null,
        deletedTables: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.fullUpdate = fullUpdate;
        this.tables = tables;
        this.deletedTables = deletedTables;
    }

    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getFullUpdate(): boolean | null {
        return this.fullUpdate;
    }

    setFullUpdate(fullUpdate: boolean | null) {
        this.fullUpdate = fullUpdate;
    }
    getTables(): any[] | null {
        return this.tables;
    }

    setTables(tables: any[] | null) {
        this.tables = tables;
    }
    getDeletedTables(): any[] | null {
        return this.deletedTables;
    }

    setDeletedTables(deletedTables: any[] | null) {
        this.deletedTables = deletedTables;
    }
}
