import { Message } from '../../message';

export class MTCTStatusChangeEvent extends Message {
    private MESSAGE_NAME: string = 'MTCTStatusChangeEvent';
    private mtctId: number = 0;
    private status: number = 0;
    private firstRankPlayerName: string | null = null;
    private lateRegInProgress: boolean | null = false;
    private unregClosed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        status: number = 0,
        firstRankPlayerName: string | null = null,
        lateRegInProgress: boolean | null = false,
        unregClosed: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.status = status;
        this.firstRankPlayerName = firstRankPlayerName;
        this.lateRegInProgress = lateRegInProgress;
        this.unregClosed = unregClosed;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getFirstRankPlayerName(): string | null {
        return this.firstRankPlayerName;
    }

    setFirstRankPlayerName(firstRankPlayerName: string | null) {
        this.firstRankPlayerName = firstRankPlayerName;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
}
