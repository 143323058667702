import { Message } from '../../message';

export class RequestMonitorMTCTTable extends Message {
    private MESSAGE_NAME: string = 'RequestMonitorMTCTTable';
    private mtctId: number = 0;
    private tableNo: number = 0;
    private tournamentCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, tableNo: number = 0, tournamentCategory: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tableNo = tableNo;
        this.tournamentCategory = tournamentCategory;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTableNo(): number {
        return this.tableNo;
    }

    setTableNo(tableNo: number) {
        this.tableNo = tableNo;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
}
