import { Message } from '../../message';

export class PlayerDealCards extends Message {
    private MESSAGE_NAME: string = 'PlayerDealCards';
    private seatingPosition: number = 0;
    private cards: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatingPosition: number = 0, cards: any[] | null = null) {
        super(reqServerPeerId);
        this.seatingPosition = seatingPosition;
        this.cards = cards;
    }

    getSeatingPosition(): number {
        return this.seatingPosition;
    }

    setSeatingPosition(seatingPosition: number) {
        this.seatingPosition = seatingPosition;
    }
    getCards(): any[] | null {
        return this.cards;
    }

    setCards(cards: any[] | null) {
        this.cards = cards;
    }
}
