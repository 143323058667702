import { Message } from '../../message';

export class DealMakingDetails extends Message {
    private MESSAGE_NAME: string = 'DealMakingDetails';
    private playerType: number = 0;
    private payoutData: any[] | null = null;
    private dealMakingTime: number = 0;
    private remainingprizePool: number = 0;
    private ringFencedAmount: number = 0;
    private playerData: any[] | null = null;
    private chipLeaderSeatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playerType: number = 0,
        payoutData: any[] | null = null,
        dealMakingTime: number = 0,
        remainingprizePool: number = 0,
        ringFencedAmount: number = 0,
        playerData: any[] | null = null,
        chipLeaderSeatNo: number = 0,
    ) {
        super(reqServerPeerId);
        this.playerType = playerType;
        this.payoutData = payoutData;
        this.dealMakingTime = dealMakingTime;
        this.remainingprizePool = remainingprizePool;
        this.ringFencedAmount = ringFencedAmount;
        this.playerData = playerData;
        this.chipLeaderSeatNo = chipLeaderSeatNo;
    }

    getPlayerType(): number {
        return this.playerType;
    }

    setPlayerType(playerType: number) {
        this.playerType = playerType;
    }
    getPayoutData(): any[] | null {
        return this.payoutData;
    }

    setPayoutData(payoutData: any[] | null) {
        this.payoutData = payoutData;
    }
    getDealMakingTime(): number {
        return this.dealMakingTime;
    }

    setDealMakingTime(dealMakingTime: number) {
        this.dealMakingTime = dealMakingTime;
    }
    getRemainingprizePool(): number {
        return this.remainingprizePool;
    }

    setRemainingprizePool(remainingprizePool: number) {
        this.remainingprizePool = remainingprizePool;
    }
    getRingFencedAmount(): number {
        return this.ringFencedAmount;
    }

    setRingFencedAmount(ringFencedAmount: number) {
        this.ringFencedAmount = ringFencedAmount;
    }
    getPlayerData(): any[] | null {
        return this.playerData;
    }

    setPlayerData(playerData: any[] | null) {
        this.playerData = playerData;
    }
    getChipLeaderSeatNo(): number {
        return this.chipLeaderSeatNo;
    }

    setChipLeaderSeatNo(chipLeaderSeatNo: number) {
        this.chipLeaderSeatNo = chipLeaderSeatNo;
    }
}
