import { Routes } from '@angular/router';

import { routeData } from '@frontend/vanilla/core';

import { BigWinComponent } from './bigwin.component';
import { BigWinResolver } from './bigwin.resolver';

export const BigWinRoutes: Routes = [
    {
        path: '',
        component: BigWinComponent,
        resolve: {
            initData: BigWinResolver,
        },
        data: routeData({ allowAllWorkflowTypes: true, allowAuthorized: true }),
    },
];
