import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { AlphaFilter } from '../services/AlphaFilter';

export class CardsView extends PIXI.Container {
    gameService;
    peerId;
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor(gameService) {
        super();
        this.gameService = gameService;
        this.peerId = this.gameService.getPeerId();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }
    createView = function (eventDispatcher, cardsViewModel, maxNoOfSeats) {
        this.viewModel = cardsViewModel;
        this.EventDispatcher = eventDispatcher;
        this.cardsContainer = this.displayUtil.getContainer();
        this.cardsContainer.name = 'cardsContainer';
        this.addChild(this.cardsContainer);
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.seatPositions = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.backCardsAnimConfig =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].backCardsAnimConfig;
        this.playerViewContainerPos = this.positionConfig.playersUIData.playerViewContainerPos;
        this.playerCenterPos = this.positionConfig.playersUIData.playerCenterPos;
        this.fontProps = uiConfig.font.props;
        this.playerAbsPos = this.seatPositions.playersUIData.playerAbsPos;
        this.playerIconPositions = this.seatPositions.playersUIData.playerIconPositions;
        this.cardsArray = [];
        this.maxNoOfSeats = maxNoOfSeats;
        this.isShifted = Array(maxNoOfSeats).fill(false);
        // this.selfPlayerCardsContainer =  this.displayUtil.getContainer();
        // this.addChild(this.selfPlayerCardsContainer);
        this.strengthArray = [];
        this.winningProbArray = [];
        this.winningProbTextArray = [];
        this.cardStyle = 0;
        this.backCardStyle = 1;
        this.frontCardArr = [];
        this.backCardsArray = [];
        this.isSelfHandStrengthRemoved = false;
        this.isCashOutEnabled = false;
        this.createCardContainers(maxNoOfSeats);
        this.selfCardsContainer = this.displayUtil.getContainer();
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.REMOVE_SELF_HAND_STRENGTH, this.removeSelfHandStrength.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_WINCHIPS_ANIMATION_COMPLETED, this.disableWinningProb.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_EMOJI_ON_TOP, this.showEmojiOnTop.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_WIN_PROB, this.hideWinProb.bind(this));
        //for removing showdown cards on reset
    };
    createCardContainers = function (maxNoOfSeats) {
        for (let i = 0; i < maxNoOfSeats; i++) {
            this.individualCardContainer = this.displayUtil.getContainer();
            this.individualCardContainer.name = 'individualCardContainer' + i;
            //  this.displayUtil.setObjectPosition(this.individualCardContainer,this.playerViewContainerPos.x[i], this.playerViewContainerPos.y[i])
            this.cardsContainer.addChild(this.individualCardContainer);
            this.cardsArray.push(this.individualCardContainer);
            const seatAlign = this.getSeatAlignmentforGap(i, maxNoOfSeats);
            if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignX: 'LEFT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignX: 'RIGHT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_TOP) {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignY: 'TOP',
                    EventDispatcher: this.EventDispatcher,
                });
            } else {
                ResponsiveContainer.call(this.individualCardContainer, {
                    portX: this.playerViewContainerPos.x[i],
                    portY: this.playerViewContainerPos.y[i],
                    portAlignY: 'BOTTOM',
                    EventDispatcher: this.EventDispatcher,
                });
            }
            this.individualCardContainer.setSize(145, 335.5);
        }
        // ResponsiveContainer.call(this.selfPlayerCardsContainer, {
        //   portX: 0,
        //   portY: 0,
        //   portAlignY: "BOTTOM",
        //   EventDispatcher: this.EventDispatcher
        // });
        //  this.individualCardContainer.setSize(720, 1280);
    };
    // to get seatalignment as left / center / right
    getSeatAlignmentforGap = function (clientSeatNo, maxSeats) {
        if (clientSeatNo == uiConfig.selfUserClientPosInd) return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
        const centerSeatNo = maxSeats / 2;
        if (centerSeatNo == clientSeatNo) return serverIdConstants.SEAT_ALIGNMENT_TOP;
        return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    };
    setCardStyle = function (cardStyleInd) {
        this.cardStyle = cardStyleInd;
    };
    getOtherPlayerSelectedOption = function (obj) {
        if (obj == undefined) {
            obj = {
                amount: 4000,
                betVector: [4000],
                isAllIn: false,
                optionId: 1,
                seatNo: 2,
                msgNumber: 0,
                peerId: 5183774,
                className: 'SelectedOption',
            };
        }
        // let seatNo = obj.seatNo;
        const seatNo = this.gameService.getClientSideSeatNo(obj.seatNo);
        if (this.backCardsArray[seatNo] == undefined) {
            return;
        }
        if (seatNo == uiConfig.selfUserClientPosInd && obj.optionId == serverIdConstants.OPTION_FOLD) {
            //remove the selfhandstrength here;
            if (this.gameTypeId == serverIdConstants.GAME_TYPE_OMAHA_HI) {
                this.alphaFilter = new AlphaFilter();
                this.alphaFilter.alpha = 0.5;
                for (let i = 0; i < this.cardsArray[seatNo].children.length; i++) {
                    if (this.cardsArray[seatNo].children[i].name == 'selfCardsContainer') {
                        this.selfCardsContainer.filters = [this.alphaFilter];
                        this.selfCardsContainer.filters[0].resolution = window.devicePixelRatio;
                    }
                }
            } else {
                // this.cardsArray[seatNo].alpha = 0.5;
                for (let i = 0; i < this.cardsArray[seatNo].children.length; i++) {
                    if (this.cardsArray[seatNo].children[i].name == 'selfCardsContainer') {
                        this.selfCardsContainer.alpha = 0.5;
                    }
                }
            }
            //this.removeSelfHandStrength();
            if (this.selfhandstrength) {
                this.cardsArray[uiConfig.selfUserClientPosInd].removeChild(this.selfhandstrength);
            }
        }
        if (obj.optionId == serverIdConstants.OPTION_FOLD) {
            if (seatNo == uiConfig.selfUserClientPosInd) {
                setTimeout(
                    function () {
                        if (this.backCardsArray.length > 0) {
                            this.backCardsArray[seatNo].visible = true;
                        }
                    }.bind(this),
                    400,
                );
            } else {
                this.backCardsArray[seatNo].visible = true;
            }
            gsap.to(this.backCardsArray[seatNo], 1, {
                rotation: 2 * Math.PI,
                x: this.backCardsAnimConfig[this.positionConfig.layOutPos[seatNo]].x,
                y: this.backCardsAnimConfig[this.positionConfig.layOutPos[seatNo]].y,
            });
            gsap.to(this.backCardsArray[seatNo].scale, 1, {
                x: 3,
                y: 3,
            });
            gsap.to(this.backCardsArray[seatNo], 1, {
                alpha: 0,
                onComplete: function () {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete this.backCardsArray[seatNo];
                    //backCardsArray.splice(seatNo, 1);
                    CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Fold, false);
                }.bind(this),
            });
        }
        this.makePlayerActive(seatNo, false);
    };
    updateGameType = function (data) {
        this.gameTypeId = data;
        if (this.gameTypeId == serverIdConstants.GAME_TYPE_TEXAS_HOLDEM) {
            this.noOfCards = 2;
        } else if (this.gameTypeId == serverIdConstants.GAME_TYPE_OMAHA_HI) {
            this.noOfCards = 4;
        } else {
            this.noOfCards = 2;
        }
    };
    callCardsAnimation = function (cardsData) {
        if (!this.cardsContainer) {
            this.cardsContainer = this.displayUtil.getContainer();
            this.addChild(this.cardsContainer);
            this.createCardContainers(this.maxNoOfSeats);
        }
        //this.selfPlayerCardsContainer.alpha = 1;
        for (let i = 0; i < cardsData.length; i++) {
            const seatNo = this.gameService.getClientSideSeatNo(cardsData[i].seatingPosition);
            // let seatNo = cardsData[i].seatingPosition;
            if (cardsData[i].cards[0] === 'XX') {
                if (CommonGameProperties.facade['table' + this.peerId].playerViewModel.view.playersArray[seatNo].showBackCards == true) {
                    const backCard1 = this.displayUtil.getSprite('P_' + this.backCardStyle + '_' + this.noOfCards + 'card');
                    this.cardsArray[seatNo].addChild(backCard1);
                    this.displayUtil.setObjectPosition(backCard1, this.playerCenterPos.x[seatNo], this.playerCenterPos.y[seatNo]);
                    this.backCardsArray[seatNo] = backCard1;
                    backCard1.visible = true;
                    // backCard1.scale.set(0.415);
                    backCard1.anchor.set(0.5, 0.5);
                    backCard1.name = 'backCard1';
                    let cardPosX = 0;
                    const cardPosY = this.seatPositions.playersUIData.playerIconPositions.y[seatNo] + 7;
                    if (serverIdConstants.SEAT_ALIGNMENT_RIGHT != this.gameService.getSeatAlignment(seatNo)) {
                        cardPosX = this.seatPositions.playersUIData.playerIconPositions.x[seatNo] + 40;
                    } else {
                        cardPosX = this.seatPositions.playersUIData.playerIconPositions.x[seatNo] - 41;
                    }
                    gsap.to(backCard1, 0.1, {
                        x: cardPosX,
                        y: cardPosY,
                    });
                }
            } else {
                const backCard = this.displayUtil.getSprite('P_' + this.backCardStyle + '_' + this.noOfCards + 'card');
                this.cardsArray[seatNo].addChild(backCard);
                this.displayUtil.setObjectPosition(backCard, this.playerCenterPos.x[seatNo], this.playerCenterPos.y[seatNo]);
                //backCard.visible = false;
                this.backCardsArray[seatNo] = backCard;
                backCard.name = 'backCard';
                //backCard.scale.set(0.9);
                backCard.anchor.set(0.5, 0.5);
                // if this is not for self player there will be issue with backcard position
                gsap.to(backCard, 0.1, {
                    x: 75,
                    y: 188,
                    onComplete: function (i) {
                        this.selfCardsContainer.removeChildren();
                        for (const j in cardsData[i].cards) {
                            const frontCard = this.displayUtil.getSprite('P_' + this.cardStyle + '_' + cardsData[i].cards[j]);
                            switch (j) {
                                case '0':
                                    if (cardsData[i].cards.length == 2) {
                                        this.displayUtil.setObjectPosition(frontCard, 37, 195);
                                    } else {
                                        this.displayUtil.setObjectPosition(frontCard, 20, 195);
                                    }
                                    break;
                                case '1':
                                    if (cardsData[i].cards.length == 2) {
                                        this.displayUtil.setObjectPosition(frontCard, 111, 195);
                                    } else {
                                        this.displayUtil.setObjectPosition(frontCard, 55, 195);
                                    }
                                    break;
                                case '2':
                                    if (cardsData[i].cards.length == 2) {
                                        this.displayUtil.setObjectPosition(frontCard, 50, 195);
                                    } else {
                                        this.displayUtil.setObjectPosition(frontCard, 91, 195);
                                    }
                                    break;
                                case '3':
                                    if (cardsData[i].cards.length == 2) {
                                        this.displayUtil.setObjectPosition(frontCard, 50, 195);
                                    } else {
                                        this.displayUtil.setObjectPosition(frontCard, 127, 195);
                                    }
                                    break;
                            }
                            frontCard.name = 'frontCard';
                            frontCard.visible = true;
                            frontCard.anchor.set(0.5, 0.5);
                            frontCard.scale.set(0.97);
                            this.cardsArray[seatNo].addChild(frontCard);
                            this.frontCardArr.push({
                                frontCard: frontCard,
                                cardValue: cardsData[i].cards[j],
                            });
                            this.cardsArray[seatNo].addChild(this.selfCardsContainer);
                            this.selfCardsContainer.addChild(frontCard);
                            this.selfCardsContainer.name = 'selfCardsContainer';
                            //this.cardsContainer.removeChild(backCard);
                            backCard.visible = false;
                        }
                        //  this.cardsArray[seatNo].updateSize();
                    }.bind(this, i),
                });
                if (seatNo == uiConfig.selfUserClientPosInd) {
                    if (this.alphaFilter && this.gameTypeId == serverIdConstants.GAME_TYPE_OMAHA_HI) {
                        this.alphaFilter.enabled = false;
                    } else {
                        this.cardsArray[seatNo].alpha = 1;
                        this.selfCardsContainer.alpha = 1;
                    }
                    // for( let i=0; i<this.cardsArray[seatNo].children.length;i++) {
                    //   // if(this.cardsArray[seatNo].children[i].name=="selfCardsContainer") {
                    //   //   this.selfCardsContainer.filters=[this.alphaFilter];
                    //   // }
                    //   if(this.cardsArray[seatNo].children[i].name=="backCard") {
                    //     backCard.visible=false;
                    //   }
                    // }
                }
            }
            this.makePlayerActive(seatNo, true);
        }
    };
    makePlayerActive = function (seatNo, status) {
        this.currenttable = 'table' + this.peerId;
        if (CommonGameProperties.facade[this.currenttable].playerViewModel.view.playersArray[seatNo].activePlaterStatus == false) {
            const alpha = status ? 1 : 0.65;
            CommonGameProperties.facade[this.currenttable].playerViewModel.view.playersArray[seatNo].PlayerViewContainer.alpha = alpha;
            CommonGameProperties.facade[this.currenttable].playerViewModel.view.playersArray[seatNo].makePlayerActive(alpha);
        }
    };
    handleWinnerCards = function (obj) {
        //this.setAlphaForAllCards(0.5);
        if (obj.winningCards && obj.winningCards.length > 0) {
            // for (card of obj.winningCards) {
            this.highlightCard(obj.winningCards);
            // }
        }
    };
    setAlphaForAllCards = function (alpha) {
        for (const frontCard of this.frontCardArr) {
            frontCard.frontCard.alpha = alpha;
        }
    };
    highlightCard = function (winnerCards) {
        for (const frontCard of this.frontCardArr) {
            if (winnerCards.includes(frontCard.cardValue)) {
                frontCard.frontCard.alpha = 1;
            } else {
                // this.cardMask =  this.displayUtil.getRectangleSprite(frontCard.frontCard.width, frontCard.frontCard.height, "0X000000");
                // this.cardMask.name = "mask";
                // this.cardMask.pivot.set(this.cardMask.width / 2, this.cardMask.height / 2);
                // this.cardMask.alpha = 0.5;
                frontCard.frontCard.tint = '0X7d7c7e';
                //this.cardMask.scale = frontCard.frontCard.scale;
                //  this.displayUtil.setObjectPosition(this.cardMask, 0, 0);
                // frontCard.frontCard.addChild(this.cardMask);
                //frontCard.frontCard.mask = this.cardMask;
            }
        }
        // if (frontCard.cardValue == cardValue) {
        //   frontCard.frontCard.alpha = 1;
        // }
    };
    handlePlayersDealCards = function (obj) {
        // if (obj == undefined) {
        //    let obj = [{ "cards": ["XX", "XX", "XX", "XX"], "seatingPosition": 3 }, { "cards": ["XX", "XX", "XX", "XX"], "seatingPosition": 1 }, { "cards": ["9c", "2s"], "seatingPosition": 0 }];
        // };
        if (!this.noOfCards && obj[0]) {
            this.noOfCards = obj[0].cards.length;
        }
        for (const i in obj) {
            if (obj[i].cards[0] !== 'XX') {
                const temp = obj.splice(i, 1);
                obj.push(temp[0]);
            }
        }
        setTimeout(
            function () {
                this.callCardsAnimation(obj);
            }.bind(this),
            50,
        );
    };
    handleFullGameClose = function () {
        this.resetGame();
    };
    removeSelfHandStrength = function (isGameFold) {
        if (this.gameTypeId != serverIdConstants.GAME_TYPE_SHORT_DECK) {
            if (this.selfhandstrength) {
                this.cardsArray[uiConfig.selfUserClientPosInd].removeChild(this.selfhandstrength);
            }
        } else {
            if (isGameFold == true) {
                if (this.selfhandstrength) {
                    this.cardsArray[uiConfig.selfUserClientPosInd].removeChild(this.selfhandstrength);
                }
            }
        }
    };
    resetGame = function () {
        // this.removeChild(this.cardsContainer);
        // this.cardsArray = [];
        this.isShifted.fill(false);
        if (this.selfhandstrength) {
            this.cardsArray[uiConfig.selfUserClientPosInd].removeChild(this.selfhandstrength);
        }
        // this.cardsContainer =  this.displayUtil.getContainer();
        // this.addChild(this.cardsContainer);
        // this.removeChild(this.selfPlayerCardsContainer);
        // this.selfPlayerCardsContainer =  this.displayUtil.getContainer();
        // this.addChild(this.selfPlayerCardsContainer);
        //this.createCardContainers(this.maxNoOfSeats);
        this.cardsContainer.name = 'cards';
        for (const k in this.cardsArray) {
            const len = this.cardsArray[k].children.length;
            for (let l = len; l >= 0; l--) {
                this.cardsArray[k].removeChild(this.cardsArray[k].children[l]);
            }
        }
        for (const i in this.strengthArray) {
            this.removeChild(this.strengthArray[i]);
        }
        for (const j in this.winningProbArray) {
            this.removeChild(this.winningProbArray[j]);
        }
        this.removeCashoutAmount();
        this.hideWinProb();
        this.strengthArray = [];
        this.winningProbArray = [];
        this.backCardsArray = [];
        this.frontCardArr = [];
        this.isSelfHandStrengthRemoved = false;
    };
    handleShowCards = function (obj) {
        //console.log(obj);
        //console.log(this.seatPositions.playersUIData.playerIconPositions);
        // obj =
        //   {
        //     bestCards: null, cards: Array(4), handStrengthHi: null, handStrengthLo: null, seatNo: 2,
        //     bestCards: null,
        //     cards: ["Ah", "3c", "Ah", "3c"],
        //     className: "ShowCards",
        //     handStrengthHi: null,
        //     handStrengthLo: null,
        //     msgNumber: 0,
        //     peerId: 10387177,
        //     seatNo: 2,
        //     show: true,
        //     showCardsType: 0,
        //   };
        if (obj.show) {
            const seatNo = this.gameService.getClientSideSeatNo(obj.seatNo);
            //  let seatNo = obj.seatNo;
            const clientSeatAlignNo = this.gameService.getSeatAlignment(seatNo);
            //console.log(clientSeatAlignNo +"clientSeatAlignNo");
            let card1PosX;
            let card2PosX;
            let card3PosX;
            let card4PosX = 0;
            if (obj.cards.length == 2) {
                if (clientSeatAlignNo == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
                    card1PosX = 31;
                    card2PosX = 27;
                } else {
                    card1PosX = 29;
                    card2PosX = 29;
                }
            } else {
                if (clientSeatAlignNo == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
                    card1PosX = 43;
                    card2PosX = 15;
                    card3PosX = 13;
                    card4PosX = 42;
                } else {
                    card1PosX = 41;
                    card2PosX = 13;
                    card3PosX = 15;
                    card4PosX = 44;
                }
            }
            if (seatNo == uiConfig.selfUserClientPosInd) {
                if (this.isSelfHandStrengthRemoved == false) {
                    this.isSelfHandStrengthRemoved = true;
                    if (this.selfhandstrength) {
                        this.cardsArray[uiConfig.selfUserClientPosInd].removeChild(this.selfhandstrength);
                    }
                }
                return;
            }
            if (this.backCardsArray[seatNo]) this.backCardsArray[seatNo].visible = false;

            for (const j in obj.cards) {
                const frontCard = this.displayUtil.getSprite('P_' + this.cardStyle + '_' + obj.cards[j]);
                this.cardsArray[seatNo].addChild(frontCard);
                const frontPosY = this.seatPositions.playersUIData.playerIconPositions.y[seatNo] + 7;
                switch (j) {
                    case '0':
                        if (obj.cards.length == 2) {
                            this.displayUtil.setObjectPosition(
                                frontCard,
                                this.seatPositions.playersUIData.playerIconPositions.x[seatNo] - card1PosX,
                                frontPosY,
                            );
                        } else {
                            this.displayUtil.setObjectPosition(
                                frontCard,
                                this.seatPositions.playersUIData.playerIconPositions.x[seatNo] - card1PosX,
                                frontPosY,
                            );
                        }
                        break;
                    case '1':
                        if (obj.cards.length == 2) {
                            this.displayUtil.setObjectPosition(
                                frontCard,
                                this.seatPositions.playersUIData.playerIconPositions.x[seatNo] + card2PosX,
                                frontPosY,
                            );
                        } else {
                            this.displayUtil.setObjectPosition(
                                frontCard,
                                this.seatPositions.playersUIData.playerIconPositions.x[seatNo] - card2PosX,
                                frontPosY,
                            );
                        }
                        break;
                    case '2':
                        if (obj.cards.length == 2) {
                            this.displayUtil.setObjectPosition(frontCard, this.seatPositions.playersUIData.playerIconPositions.x[seatNo], frontPosY);
                        } else {
                            this.displayUtil.setObjectPosition(
                                frontCard,
                                this.seatPositions.playersUIData.playerIconPositions.x[seatNo] + card3PosX,
                                frontPosY,
                            );
                        }
                        break;
                    case '3':
                        if (obj.cards.length == 2) {
                            this.displayUtil.setObjectPosition(frontCard, this.seatPositions.playersUIData.playerIconPositions.x[seatNo], frontPosY);
                        } else {
                            this.displayUtil.setObjectPosition(
                                frontCard,
                                this.seatPositions.playersUIData.playerIconPositions.x[seatNo] + card4PosX,
                                frontPosY,
                            );
                        }
                        break;
                }
                frontCard.visible = true;
                if (obj.cards.length == 2) {
                    frontCard.scale.x = 0.795;
                    frontCard.scale.y = 0.8;
                } else {
                    frontCard.scale.x = 0.68;
                    frontCard.scale.y = 0.685;
                }
                frontCard.anchor.set(0.5, 0.5);
                this.frontCardArr.push({
                    frontCard: frontCard,
                    cardValue: obj.cards[j],
                });
                // this.cardsArray[seatNo].updateSize();
            }

            if (this.addEmoji) {
                if (seatNo == this.addChild.seatNo) {
                    this.cardsArray[seatNo].addChild(this.addEmoji);
                }
            }
            // for ff forced all-in case
            setTimeout(
                function () {
                    if (this.backCardsArray[seatNo]) this.hideAllBackCards(seatNo);
                }.bind(this),
                10,
            );
        }
    };
    showSelfHandStrength = function (handStrenghtStr, playerSeat) {
        const handStrenghtString = CommonGameProperties.langPack['getPascalCaseString'](handStrenghtStr);
        if (this.selfhandstrength) {
            this.cardsArray[playerSeat].removeChild(this.selfhandstrength);
        }
        const posX = -13;
        const posY = 230;
        this.selfhandstrength = this.displayUtil.getSprite('handstrength_A');
        this.cardsArray[playerSeat].addChild(this.selfhandstrength);
        this.displayUtil.setObjectPosition(this.selfhandstrength, posX, posY);
        this.selfhandstrength.visible = true;
        this.selfhandstrength.scale.set(0.95);
        this.fontProps.handStrengthText.fontSize = 18;
        const hSText = this.displayUtil.getTextField(this.fontProps.handStrengthText);
        this.displayUtil.setObjectPosition(hSText, 90, 14);
        this.selfhandstrength.addChild(hSText);
        hSText.text = handStrenghtString;
        hSText.scale.set(1.2);
        hSText.anchor.set(0.5);
        // this.cardsArray[playerSeat].updateSize();
    };
    showHandStrength = function (handStrenghtStr, playerSeat) {
        if (this.selfhandstrength) {
            this.cardsArray[playerSeat].removeChild(this.selfhandstrength);
        }
        const handStrenghtString = CommonGameProperties.langPack['getPascalCaseString'](handStrenghtStr);
        let posX,
            posY = 0;
        if (playerSeat != uiConfig.selfUserClientPosInd) {
            posX = 7;
            posY = this.seatPositions.playersUIData.playerIconPositions.y[playerSeat] + 28;
        }
        const handstrength = this.displayUtil.getSprite('handstrength_A');
        this.cardsArray[playerSeat].addChild(handstrength);
        if (playerSeat != uiConfig.selfUserClientPosInd) {
            if (this.viewModel.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
                handstrength.scale.x = 0.72;
            } else {
                handstrength.scale.x = 0.735;
            }
            handstrength.scale.y = 0.79;
        }
        this.strengthArray.push(handstrength);
        this.displayUtil.setObjectPosition(handstrength, posX, posY);
        handstrength.visible = true;
        const hSText = this.displayUtil.getTextField(this.fontProps.handStrengthText);
        this.displayUtil.setObjectPosition(hSText, 91, 14);
        handstrength.addChild(hSText);
        hSText.text = handStrenghtString;
        hSText.anchor.set(0.5);
        if (hSText.text.length > 11) {
            hSText.scale.set(1.2);
        } else {
            hSText.scale.set(1.27);
        }
        if (this.addEmoji) {
            if (playerSeat == this.addChild.seatNo) {
                this.cardsArray[playerSeat].addChild(this.addEmoji);
            }
        }
        // this.cardsArray[playerSeat].updateSize();
    };
    showEmojiOnTop = function (data) {
        if (this.addEmoji) {
            if (data.detail.playerSeat == this.addChild.seatNo && this.addEmoji.scale.x == 1) {
                data.detail.buttonsContainer.addChild(this.addEmoji);
                this.addEmoji.x = data.detail.buttonsContainer.children[2].x;
                this.addEmoji.y = data.detail.buttonsContainer.children[2].y - 5;
            }
        }
    };
    handleProbabilityOfWinning = function (playerSeatToProbabilityMap) {
        for (const seat in playerSeatToProbabilityMap) {
            this.showWinningProb(playerSeatToProbabilityMap[seat], seat);
        }
    };
    showWinningProb = function (probability, playerSeatVal) {
        const playerSeat = this.gameService.getClientSideSeatNo(playerSeatVal);
        if (!this.winningProbArray[playerSeat]) {
            let posX,
                posY = 0;
            if (this.noOfCards == 2) {
                if (playerSeat == uiConfig.selfUserClientPosInd) {
                    posX = 73;
                    posY = 121;
                } else {
                    posX = 58;
                    posY = 25.5;
                }
            } else {
                if (playerSeat == uiConfig.selfUserClientPosInd) {
                    posX = 86;
                    posY = 120;
                } else {
                    posX = 67;
                    posY = 32;
                }
            }
            //  let handPercentage =  this.displayUtil.getSprite("showdown");
            const handPercentage = this.displayUtil.getBorderRoundRectangleSprite(50, 18, '0xd82525', 4, false);
            this.cardsArray[playerSeat].addChild(handPercentage);
            handPercentage.tempX = posX;
            this.winningProbArray[playerSeat] = handPercentage;
            this.displayUtil.setObjectPosition(handPercentage, posX, posY);
            handPercentage.visible = true;
            handPercentage.scale.set(1.4);
            const wPText = this.displayUtil.getTextField(this.fontProps.winningProbText_1);
            this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
            this.cardsArray[playerSeat].addChild(wPText);
            this.winningProbTextArray[playerSeat] = wPText;
            wPText.text = probability / 100 + '%';
            handPercentage.resolution = window.devicePixelRatio;
            wPText.resolution = window.devicePixelRatio;
            handPercentage.width = wPText.width + 10;
            if (wPText.text != '' && wPText.text != null && wPText.text != ' ' && wPText.text != undefined) {
                this.winPercentPostion(wPText, handPercentage);
            }
            if (this.selfhandstrength) {
                this.cardsArray[playerSeat].removeChild(this.selfhandstrength);
            }
        } else {
            this.winningProbTextArray[playerSeat].text = probability / 100 + '%';
            this.winningProbArray[playerSeat].width = this.winningProbTextArray[playerSeat].width + 10;
            if (
                this.winningProbTextArray[playerSeat].text != '' &&
                this.winningProbTextArray[playerSeat].text != null &&
                this.winningProbTextArray[playerSeat].text != ' ' &&
                this.winningProbTextArray[playerSeat].text != undefined
            ) {
                this.winPercentPostion(this.winningProbTextArray[playerSeat], this.winningProbArray[playerSeat]);
            }
        }
    };
    winPercentPostion = function (wPText, handPercentage) {
        const adjX = handPercentage.tempX - 5;
        if (wPText.text.length == 4) {
            if (parseFloat(wPText.text) < 0.9) {
                this.displayUtil.setObjectPosition(handPercentage, adjX + 17, handPercentage.y);
                this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
            } else {
                this.displayUtil.setObjectPosition(handPercentage, adjX + 11, handPercentage.y);
                this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
            }
        } else if (wPText.text.length == 2) {
            this.displayUtil.setObjectPosition(handPercentage, adjX + 35, handPercentage.y);
            this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
        } else if (wPText.text.length == 6) {
            this.displayUtil.setObjectPosition(handPercentage, adjX - 5, handPercentage.y);
            this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
        } else if (wPText.text.length == 5) {
            this.displayUtil.setObjectPosition(handPercentage, adjX + 5, handPercentage.y);
            this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
        } else if (wPText.text.length == 3) {
            this.displayUtil.setObjectPosition(handPercentage, adjX + 23, handPercentage.y);
            this.displayUtil.setObjectPosition(wPText, handPercentage.x + 5, handPercentage.y + 0.7);
        }
    };
    hideWinProb = function () {
        for (let i = 0; i < this.winningProbArray.length; i++) {
            if (this.winningProbArray[i] != undefined) {
                this.winningProbArray[i].visible = false;
            }
        }
        for (let i = 0; i < this.winningProbTextArray.length; i++) {
            if (this.winningProbTextArray[i] != undefined) {
                this.winningProbTextArray[i].visible = false;
            }
        }
    };
    updatePlayerCardStyle = function (frontBGStyle) {
        if (frontBGStyle == this.cardStyle) {
            return;
        } else {
            this.cardStyle = frontBGStyle;
            for (const i in this.frontCardArr) {
                // posArr.push({"x" :this.frontCardArr[i].frontCard.x, "y": this.frontCardArr[i].frontCard.y});
                // this.selfPlayerCardsContainer.removeChild(this.frontCardArr[i].frontCard);
                //  let frontCard =  this.displayUtil.getSprite("P_" + this.cardStyle + "_" +this.frontCardArr[i].cardValue);
                // this.selfPlayerCardsContainer.addChild(frontCard);
                // frontCard.anchor.set(0.5);
                //  this.displayUtil.setObjectPosition(frontCard, posArr[i].x, posArr[i].y);
                this.displayUtil.setTexture(this.frontCardArr[i].frontCard, 'P_' + this.cardStyle + '_' + this.frontCardArr[i].cardValue);
                //this.communityCardsArr.splice(i,1,{"presentCard" : presentCard, "cardValue" : this.communityCardsArr[i].cardValue});
                this.frontCardArr.splice(i, 1, {
                    frontCard: this.frontCardArr[i].frontCard,
                    cardValue: this.frontCardArr[i].cardValue,
                });
            }
        }
        //this.updateBackCardStyle(data);
    };
    updateBackCardStyle = function (backBGStyle) {
        if (backBGStyle == this.backCardStyle) {
            return;
        } else {
            this.backCardStyle = backBGStyle;
            for (const i in this.backCardsArray) {
                if (this.backCardsArray[i] !== undefined) {
                    //backCardPosArr[i] = {"x": this.backCardsArray[i].x, "y" : this.backCardsArray[i].y};
                    //backCardPosArr.push({"x": this.backCardsArray[i].x, "y" : this.backCardsArray[i].y});
                    //  this.cardsContainer.removeChild(this.backCardsArray[i]);
                    //   let backCard =  this.displayUtil.getSprite("P_" + this.backCardStyle + "_" + this.noOfCards + "card");
                    //  this.cardsContainer.addChild(backCard);
                    //   this.displayUtil.setObjectPosition(backCard, backCardPosArr[i].x, backCardPosArr[i].y);
                    //  backCard.anchor.set(0.5, 0.5);

                    this.displayUtil.setTexture(this.backCardsArray[i], 'P_' + this.backCardStyle + '_' + this.noOfCards + 'card');
                    this.backCardsArray.splice(i, 1, this.backCardsArray[i]);
                }
            }
        }
    };
    currentTablePeerId = function (peerId) {
        this.currTablePeerId = 'table' + peerId;
    };
    broadcastEmoji = function () {
        //opponentemoji_1_up
        const playerSeat = this.gameService.getBroadcastDetails().seatNo, // server position
            tempSeatPos = this.gameService.getClientSideSeatNo(playerSeat), // client position
            responseId = this.gameService.getBroadcastDetails().bEmojiId;
        let emojiId: any = 'emoji' + '_' + '1';
        if (serverIdConstants.SEAT_ALIGNMENT_RIGHT == this.gameService.getSeatAlignment(tempSeatPos) && responseId < 1000) {
            emojiId = 'opponentemoji' + '_' + responseId;
        } else {
            emojiId = 'emoji' + '_' + responseId;
        }
        let posX,
            posY,
            scaleValue = 1;
        if (emojiId.split('_')[1] > 1000) {
            posX = this.playerIconPositions.x[tempSeatPos];
            posY = this.playerIconPositions.y[tempSeatPos];
        } else {
            if (tempSeatPos == 0) {
                posX = 316;
                posY = 1000;
            } else {
                posX = this.playerIconPositions.x[tempSeatPos];
                posY = this.playerIconPositions.y[tempSeatPos] - 75;
            }
        }
        scaleValue = 1;
        if (tempSeatPos == uiConfig.selfUserClientPosInd) {
            let positionObj: any;
            if (uiConfig['tableConfig' + this.gameService.peerId] && uiConfig['tableConfig' + this.gameService.peerId].playerOptionsVisible) {
                positionObj = this.showSelfEmojiAtCard(emojiId);
            } else {
                positionObj = this.showEmojiAtPlayer(emojiId);
            }
            posX = positionObj.posX;
            posY = positionObj.posY;
            scaleValue = positionObj.scale;
        }
        if (this.addEmoji) {
            this.addEmoji.destroy();
            this.removeChild(this.addEmoji);
            clearTimeout(this.addEmojiTimer);
        }
        if (emojiId.split('_')[1] > 1000) {
            const requiredEmoji = emojiId + 'Anim';
            this.animatedSelfEmoji = this.displayUtil.getSpriteDetails(requiredEmoji);
            this.animatedSelfEmoji =
                this.animatedSelfEmoji == undefined ? this.displayUtil.getSpriteDetails('emojilaughingAnim') : this.animatedSelfEmoji;
            this.addEmoji = this.displayUtil.getCustomAnimatedSprite(this.animatedSelfEmoji);
            this.addEmoji.scale.set(scaleValue);
            this.addEmoji.loop = false;
            this.addEmoji.name = 'selfEmoji';
            this.addChild.seatNo = tempSeatPos;
            //this.addChildAt(this.addEmoji);
            if (tempSeatPos == uiConfig.selfUserClientPosInd) {
                //console.log(scaleValue);
                this.addEmoji.scale.set(scaleValue);
            } else {
                this.addEmoji.scale.set(0.9);
            }
            this.cardsArray[tempSeatPos].addChild(this.addEmoji);
            this.displayUtil.setObjectPosition(this.addEmoji, posX, posY);
            this.addEmoji.anchor.set(0.5);
            if (this.isCashOutEnabled == true) this.addEmoji.visible = false;
            else this.addEmoji.visible = true;
            this.addEmoji.play();
            this.addEmoji.onComplete = function () {
                if (this.addEmoji) {
                    this.addEmoji.destroy();
                    this.removeChild(this.addEmoji);
                    this.addEmoji = null;
                }
            }.bind(this);
        } else {
            this.addEmoji = this.displayUtil.getButton(emojiId);
            if (tempSeatPos != uiConfig.selfUserClientPosInd) {
                this.addEmoji.anchor.set(0.5);
            }
            this.addEmoji.scale.set(0.4);
            this.cardsArray[tempSeatPos].addChild(this.addEmoji);
            this.displayUtil.setObjectPosition(this.addEmoji, posX, posY);
            this.addEmojiTimer = setTimeout(
                function () {
                    gsap.to(this.addEmoji, 0.5, {
                        alpha: 0,
                    });
                }.bind(this),
                4000,
            );
        }
    };
    showEmojiAtPlayer = function (emojiId) {
        const obj: any = {};
        if (emojiId.split('_')[1] < 1000) {
            obj.posX = 175;
            obj.posY = 175;
            obj.scale = 0.7;
            return obj;
        } else {
            obj.posX = this.playerIconPositions.x[0];
            obj.posY = this.playerIconPositions.y[0];
            obj.scale = 1;
            return obj;
        }
    };
    showSelfEmojiAtCard = function (emojiId) {
        const obj: any = {};
        if (emojiId.split('_')[1] < 1000) {
            obj.posX = 175;
            obj.posY = 175;
            obj.scale = 0.7;
            return obj;
        } else {
            obj.posX = -78;
            obj.posY = 175;
            obj.scale = 0.5;
            return obj;
        }
    };
    hideBackCards = function (seatNo) {
        if (seatNo != uiConfig.selfUserClientPosInd) {
            gsap.to(this.backCardsArray[seatNo], 0.2, {
                rotation: 2 * Math.PI,
                alpha: 0,
                x: this.backCardsAnimConfig[this.positionConfig.layOutPos[seatNo]].x,
                y: this.backCardsAnimConfig[this.positionConfig.layOutPos[seatNo]].y,
            });
        }
    };
    //disableWinningProb = function (data) {
    //console.log(this.cardsArray[data.detail.seatNo]);
    //};

    showCashOutAmt = function (amount, isCashedout) {
        this.isCashoutEquityEnabled = CommonGameProperties.dynaconConfigs['webClientConfigs']['isCashoutEquityEnabled'];
        if (isCashedout == true) this.cardsArray[0].removeChild(this.cashOutAmtContainer);
        this.cashOutAmtContainer = this.displayUtil.getContainer();
        this.cardsArray[0].addChild(this.cashOutAmtContainer);
        this.displayUtil.setObjectPosition(this.cashOutAmtContainer, -123, -108);
        this.cashOutAmtContainer.name = 'cashOutAmtContainer';
        this.cashOutAmtBG = this.displayUtil.getBorderRoundRectangleSprite(390, 80, '0x000c16', 10, true, 2, '0xffffff', 0.4);
        let posY = 0;
        this.cashOutAmtContainer.addChild(this.cashOutAmtBG);
        this.cashOutAmtBG.alpha = 0.9;

        this.cashOuttxt = this.displayUtil.getTextField(this.fontProps.cashOutTxt);
        this.cashOutTxt1 = this.displayUtil.getTextField(this.fontProps.cashOutTxt);
        this.cashOutTxt2 = this.displayUtil.getTextField(this.fontProps.cashOutTxt);

        this.cashOutTextContainer = this.displayUtil.getContainer();
        this.cashOutAmtContainer.addChild(this.cashOutTextContainer);
        this.cashOutTextContainer.addChild(this.cashOutTxt1);
        this.cashOutTextContainer.addChild(this.cashOutTxt2);

        if (isCashedout == false) {
            const surcharge = this.gameService.gameInfoModel.getPlayersCashoutEquityDetails().surcharge / 100;
            this.cashOutTxt1.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHOUT_CASHOUT_FOR');
            this.cashOutTxt2.text = ' $' + amount / 100 + '?';
            this.cashOuttxt.text = this.cashOutTxt1.text + this.cashOutTxt2.text;
            this.surChargeTxt = this.displayUtil.getTextField(this.fontProps.surChargeTxt);
            this.surChargeTxt1 = this.displayUtil.getTextField(this.fontProps.surChargeTxt);
            this.cashOutAmtContainer.addChild(this.surChargeTxt);
            this.surChargeTxt1.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHOUT_SURCHARGE_MESSAGE');
            this.surChargeTxt1.text = this.surChargeTxt1.text.replace('%@%%', '');
            //   if (uiConfig.gameLangAssetsPath.includes("pt_BR") || uiConfig.gameLangAssetsPath.includes("pt") || uiConfig.gameLangAssetsPath.includes("ru"))
            //     this.surChargeTxt.text = "(" + this.surChargeTxt1.text + " " + surcharge + "%)";
            //   else
            this.surChargeTxt.text = '(' + surcharge + '%' + ' ' + this.surChargeTxt1.text + ')';
            this.surChargeTxt.anchor.y = 0.5;
            this.surChargeTxt.anchor.x = 0.5;
            this.displayUtil.setObjectPosition(this.surChargeTxt, this.cashOutAmtBG.width / 2, 60);
            posY = 10; //this.cashOutAmtBG.height / 2 - this.cashOutTextContainer.height / 2 + 3;
        } else {
            this.cashOutTxt1.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHOUT_CASHED_OUT');
            this.cashOutTxt2.text = ' $' + amount / 100;
            this.cashOuttxt.text = this.cashOutTxt1.text + this.cashOutTxt2.text;
            posY = 28; //this.cashOutAmtBG.height / 2;
        }

        this.cashOutTxt1.style = JSON.parse(JSON.stringify(this.cashOuttxt.style));
        this.cashOutTxt2.style = JSON.parse(JSON.stringify(this.cashOuttxt.style));
        this.cashOutTxt2.style.fontWeight = 700;
        this.cashOutTxt2.resolution = window.devicePixelRatio;
        this.cashOutAmtBG.resolution = window.devicePixelRatio;

        // this.cashOutTxt1.anchor.y = 0.5;
        // this.cashOutTxt2.anchor.y = 0.5;

        this.displayUtil.setObjectPosition(
            this.cashOutTextContainer,
            (this.cashOutAmtContainer.width - this.cashOutTxt1.width - this.cashOutTxt2.width) / 2,
            posY,
        );
        this.displayUtil.setObjectPosition(this.cashOutTxt2, 0, 0);
        this.displayUtil.setObjectPosition(this.cashOutTxt2, this.cashOutTxt1.width, 0);
    };
    removeCashoutAmount = function () {
        this.cardsArray[0].removeChild(this.cashOutAmtContainer);
    };
    hideEmojiForCashOut = function (tempSeatPos) {
        this.isCashOutEnabled = true;
        for (let i = 0; i < this.cardsArray[tempSeatPos.playerSeat].children.length; i++) {
            if (this.cardsArray[tempSeatPos.playerSeat].children[i].name == 'selfEmoji')
                this.cardsArray[tempSeatPos.playerSeat].children[i].visible = false;
        }
    };
    showEmojiForCashOut = function () {
        this.isCashOutEnabled = false;
    };
    hideCards = function (seatNo) {
        if (this.cardsArray[seatNo] != undefined) this.cardsArray[seatNo].visibe = false;
    };
    hideAllBackCards = function (seatNo) {
        for (let i = 0; i < this.cardsArray[seatNo].children.length; i++) {
            if (this.cardsArray[seatNo].children[i].name == 'backCard1') this.cardsArray[seatNo].children[i].visible = false;
        }
    };
}
