import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PageMatrixBootstrapService } from '../page-matrix/page-matrix-bootstrap.service';
import { ModalPopupService } from '../shared-services/modal-popup.service';

export function providePPModalContainer(): Provider[] {
    return [runOnAppInit(PageMatrixBootstrapService), ModalPopupService];
}
