<pk-navigation-bar *ngIf="showConfigureNavigation"></pk-navigation-bar>
<div *ngFor="let contents of downloadContents" class="poker-download-container">
    <div class="md-teaser-section hero-teaser-new small-teaser" *ngIf="contents.downloadPokerTeasersList">
        <pk-teaser-carousel
            [navigation]="navigationarrows"
            [dots]="dots"
            [loop]="loop"
            [teasersResponse]="contents.downloadPokerTeasersList"
            (teasersClick)="teaserClick($event)"
            (onCtaClick)="ctaClick($event)"
            [class]="assignBrowserSpecificClass()">
        </pk-teaser-carousel>
    </div>
    <div *ngIf="contents.pokerThreeTileContainer">
        <pk-threetile-container [threeTileContainer]="contents.pokerThreeTileContainer"></pk-threetile-container>
    </div>
    <div class="main-tile-list" *ngIf="contents.downloadNow && !isMobileDownloadNow && !isTabletDownloadNow">
        <div class="btn-alignment"><button class="btn btn-primary" (click)="goToDownload()">Download Now</button></div>
    </div>
</div>

<div *ngIf="isMobileDownloadNow" class="btn-mobile-sec">
    <button class="btn btn-primary" (click)="goToDownload()">
        {{ this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo.data.fields.downloadButton }}Download Now
    </button>
</div>
<div *ngIf="isTabletDownloadNow" class="btn-tablet-sec">
    <button class="btn btn-primary" (click)="goToDownload()">
        {{ this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo.data.fields.downloadButton }}Download Now
    </button>
</div>
