import { TSDataInputStream } from '../../../sal/dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from '../../../sal/dataInputOutputStream/TSDataOutputStream';
import { Message } from '../message';
import { PGMessageFactory } from '../pgmessagefactory';
import { AvatarDetails } from './messages/AvatarDetails';
import { CurrencyAmount } from './messages/CurrencyAmount';
import { FXConversionFactor } from './messages/FXConversionFactor';
import { FXRateData } from './messages/FXRateData';
import { FXRateDetails } from './messages/FXRateDetails';
import { FXRateSnapshot } from './messages/FXRateSnapshot';
import { GameServerProperties } from './messages/GameServerProperties';
import { LoginRequest } from './messages/LoginRequest';
import { LoginSuccessResponse } from './messages/LoginSuccessResponse';
import { LoginSuccessUserProfile } from './messages/LoginSuccessUserProfile';
import { MessageDeliveryFailure } from './messages/MessageDeliveryFailure';
import { MessageFloodGuardAlert } from './messages/MessageFloodGuardAlert';
import { RequestTerminateLoggedOnOtherMachine } from './messages/RequestTerminateLoggedOnOtherMachine';
import { SSOKeyMessage } from './messages/SSOKeyMessage';
import { UserAvatarInfo } from './messages/UserAvatarInfo';
import { UserInfo } from './messages/UserInfo';
import { UserPersonalInfo } from './messages/UserPersonalInfo';
import { UserProfile } from './messages/UserProfile';

export class PGSharedMessageFactory extends PGMessageFactory {
    override classes: Map<number, any> = new Map<number, any>();
    override ids: Map<any, number> = new Map<any, number>();

    constructor() {
        super();
        this.classes.set(28685, MessageFloodGuardAlert);
        this.classes.set(28686, MessageDeliveryFailure);
        this.classes.set(40980, GameServerProperties);
        this.classes.set(40995, UserPersonalInfo);
        this.classes.set(41007, UserAvatarInfo);
        this.classes.set(41008, UserProfile);
        this.classes.set(41012, UserInfo);
        this.classes.set(41021, FXRateDetails);
        this.classes.set(41022, FXRateSnapshot);
        this.classes.set(41024, FXConversionFactor);
        this.classes.set(41025, FXRateData);
        this.classes.set(41026, CurrencyAmount);
        this.classes.set(41076, RequestTerminateLoggedOnOtherMachine);
        this.classes.set(41199, LoginRequest);
        this.classes.set(41203, LoginSuccessResponse);
        this.classes.set(41204, LoginSuccessUserProfile);
        this.classes.set(41205, SSOKeyMessage);
        this.classes.set(41208, AvatarDetails);
        for (const [key, value] of this.classes.entries()) {
            this.ids.set(value, key);
        }
    }

    read(obj: Message, inStream: TSDataInputStream) {
        const classId = this.getClassId(obj.constructor);
        let result = false;
        switch (classId) {
            case 28685:
                this.readMessageFloodGuardAlert(inStream, obj as MessageFloodGuardAlert);
                result = true;
                break;
            case 28686:
                this.readMessageDeliveryFailure(inStream, obj as MessageDeliveryFailure);
                result = true;
                break;
            case 40980:
                this.readGameServerProperties(inStream, obj as GameServerProperties);
                result = true;
                break;
            case 40995:
                this.readUserPersonalInfo(inStream, obj as UserPersonalInfo);
                result = true;
                break;
            case 41007:
                this.readUserAvatarInfo(inStream, obj as UserAvatarInfo);
                result = true;
                break;
            case 41008:
                this.readUserProfile(inStream, obj as UserProfile);
                result = true;
                break;
            case 41012:
                this.readUserInfo(inStream, obj as UserInfo);
                result = true;
                break;
            case 41021:
                this.readFXRateDetails(inStream, obj as FXRateDetails);
                result = true;
                break;
            case 41022:
                this.readFXRateSnapshot(inStream, obj as FXRateSnapshot);
                result = true;
                break;
            case 41024:
                this.readFXConversionFactor(inStream, obj as FXConversionFactor);
                result = true;
                break;
            case 41025:
                this.readFXRateData(inStream, obj as FXRateData);
                result = true;
                break;
            case 41026:
                this.readCurrencyAmount(inStream, obj as CurrencyAmount);
                result = true;
                break;
            case 41076:
                this.readRequestTerminateLoggedOnOtherMachine(inStream, obj as RequestTerminateLoggedOnOtherMachine);
                result = true;
                break;
            case 41199:
                this.readLoginRequest(inStream, obj as LoginRequest);
                result = true;
                break;
            case 41203:
                this.readLoginSuccessResponse(inStream, obj as LoginSuccessResponse);
                result = true;
                break;
            case 41204:
                this.readLoginSuccessUserProfile(inStream, obj as LoginSuccessUserProfile);
                result = true;
                break;
            case 41205:
                this.readSSOKeyMessage(inStream, obj as SSOKeyMessage);
                result = true;
                break;
            case 41208:
                this.readAvatarDetails(inStream, obj as AvatarDetails);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    write(obj: Message, outStream: TSDataOutputStream) {
        const classId = this.getClassIdByName(obj);
        let result = false;
        switch (classId) {
            case 28685:
                this.writeMessageFloodGuardAlert(outStream, obj as MessageFloodGuardAlert);
                result = true;
                break;
            case 28686:
                this.writeMessageDeliveryFailure(outStream, obj as MessageDeliveryFailure);
                result = true;
                break;
            case 40980:
                this.writeGameServerProperties(outStream, obj as GameServerProperties);
                result = true;
                break;
            case 40995:
                this.writeUserPersonalInfo(outStream, obj as UserPersonalInfo);
                result = true;
                break;
            case 41007:
                this.writeUserAvatarInfo(outStream, obj as UserAvatarInfo);
                result = true;
                break;
            case 41008:
                this.writeUserProfile(outStream, obj as UserProfile);
                result = true;
                break;
            case 41012:
                this.writeUserInfo(outStream, obj as UserInfo);
                result = true;
                break;
            case 41021:
                this.writeFXRateDetails(outStream, obj as FXRateDetails);
                result = true;
                break;
            case 41022:
                this.writeFXRateSnapshot(outStream, obj as FXRateSnapshot);
                result = true;
                break;
            case 41024:
                this.writeFXConversionFactor(outStream, obj as FXConversionFactor);
                result = true;
                break;
            case 41025:
                this.writeFXRateData(outStream, obj as FXRateData);
                result = true;
                break;
            case 41026:
                this.writeCurrencyAmount(outStream, obj as CurrencyAmount);
                result = true;
                break;
            case 41076:
                this.writeRequestTerminateLoggedOnOtherMachine(outStream, obj as RequestTerminateLoggedOnOtherMachine);
                result = true;
                break;
            case 41199:
                this.writeLoginRequest(outStream, obj as LoginRequest);
                result = true;
                break;
            case 41203:
                this.writeLoginSuccessResponse(outStream, obj as LoginSuccessResponse);
                result = true;
                break;
            case 41204:
                this.writeLoginSuccessUserProfile(outStream, obj as LoginSuccessUserProfile);
                result = true;
                break;
            case 41205:
                this.writeSSOKeyMessage(outStream, obj as SSOKeyMessage);
                result = true;
                break;
            case 41208:
                this.writeAvatarDetails(outStream, obj as AvatarDetails);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    readMessageFloodGuardAlert(inStream: TSDataInputStream, obj: MessageFloodGuardAlert): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeMessageFloodGuardAlert(outStream: TSDataOutputStream, obj: MessageFloodGuardAlert): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readMessageDeliveryFailure(inStream: TSDataInputStream, obj: MessageDeliveryFailure): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFailedMsg(inStream.getBytes());
        obj.setFailedMsgClassId(inStream.getInteger());
        obj.setFailedMsgTargetId(inStream.getInteger());
        obj.setFailureCause(inStream.getInteger());
    }

    writeMessageDeliveryFailure(outStream: TSDataOutputStream, obj: MessageDeliveryFailure): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getFailedMsg());
        outStream.putInteger(obj.getFailedMsgClassId());
        outStream.putInteger(obj.getFailedMsgTargetId());
        outStream.putInteger(obj.getFailureCause());
    }
    readGameServerProperties(inStream: TSDataInputStream, obj: GameServerProperties): void {
        obj.setIpAddress(inStream.getString());
        obj.setPeerId(inStream.getInteger());
        obj.setPortNumber(inStream.getInteger());
    }

    writeGameServerProperties(outStream: TSDataOutputStream, obj: GameServerProperties): void {
        outStream.putString(obj.getIpAddress());
        outStream.putInteger(obj.getPeerId());
        outStream.putInteger(obj.getPortNumber());
    }
    readUserPersonalInfo(inStream: TSDataInputStream, obj: UserPersonalInfo): void {
        obj.setAccountName(inStream.getString());
        obj.setAddress(inStream.getString());
        obj.setCity(inStream.getString());
        obj.setCityOfBirth(inStream.getString());
        obj.setCountry(inStream.getString());
        obj.setCountryOfBirth(inStream.getString());
        obj.setDob(inStream.getString());
        obj.setFirstName(inStream.getString());
        obj.setLastName(inStream.getString());
        obj.setMobileNumber(inStream.getString());
        obj.setPhoneNumber(inStream.getString());
        obj.setState(inStream.getString());
        obj.setStateOfBirth(inStream.getString());
        obj.setTitle(inStream.getString());
        obj.setZipCode(inStream.getString());
    }

    writeUserPersonalInfo(outStream: TSDataOutputStream, obj: UserPersonalInfo): void {
        outStream.putString(obj.getAccountName());
        outStream.putString(obj.getAddress());
        outStream.putString(obj.getCity());
        outStream.putString(obj.getCityOfBirth());
        outStream.putString(obj.getCountry());
        outStream.putString(obj.getCountryOfBirth());
        outStream.putString(obj.getDob());
        outStream.putString(obj.getFirstName());
        outStream.putString(obj.getLastName());
        outStream.putString(obj.getMobileNumber());
        outStream.putString(obj.getPhoneNumber());
        outStream.putString(obj.getState());
        outStream.putString(obj.getStateOfBirth());
        outStream.putString(obj.getTitle());
        outStream.putString(obj.getZipCode());
    }
    readUserAvatarInfo(inStream: TSDataInputStream, obj: UserAvatarInfo): void {
        obj.setAvatarId(inStream.getInteger());
        obj.setAvatarMD5Sum(inStream.getString());
        obj.setAvatarDetails(inStream.getObjectArray());
        obj.setFirstUploadedInCycle(inStream.getLong());
        obj.setIsCustomEnabled(inStream.getBoolean());
        obj.setUploadsRemaining(inStream.getInteger());
    }

    writeUserAvatarInfo(outStream: TSDataOutputStream, obj: UserAvatarInfo): void {
        outStream.putInteger(obj.getAvatarId());
        outStream.putString(obj.getAvatarMD5Sum());
        outStream.putObjectArray(obj.getAvatarDetails());
        outStream.putLong(obj.getFirstUploadedInCycle());
        outStream.putBoolean(obj.getIsCustomEnabled());
        outStream.putInteger(obj.getUploadsRemaining());
    }
    readUserProfile(inStream: TSDataInputStream, obj: UserProfile): void {
        obj.setIsValidated(inStream.getBoolean());
        obj.setAccountCurrencyCode(inStream.getString());
        obj.setAccountLanguageId(inStream.getString());
        obj.setAccountName(inStream.getString());
        obj.setAccountStatus(inStream.getByte());
        obj.setAvatarInfo(inStream.getObject());
        obj.setBonusCode(inStream.getString());
        obj.setBrandId(inStream.getString());
        obj.setCanShowPersonalInfo(inStream.getBoolean());
        obj.setChannelId(inStream.getString());
        obj.setCity(inStream.getString());
        obj.setDaysPassedAfterRealAccountCreation(inStream.getInteger());
        obj.setDocumentValidationStatus(inStream.getString());
        obj.setEncProfile(inStream.getBytes());
        obj.setFirstName(inStream.getString());
        obj.setFrontendId(inStream.getString());
        obj.setHearFrom(inStream.getString());
        obj.setIsPMCEnabled(inStream.getBoolean());
        obj.setIsPointsEnabled(inStream.getBoolean());
        obj.setLastName(inStream.getString());
        obj.setLocale(inStream.getString());
        obj.setLoginId(inStream.getString());
        obj.setMailId(inStream.getString());
        obj.setMailingAddress(inStream.getString());
        obj.setPartyPointsCategory(inStream.getString());
        obj.setReferer(inStream.getString());
        obj.setScreenName(inStream.getString());
        obj.setSessionLanguageId(inStream.getString());
        obj.setSex(inStream.getString());
        obj.setShowCity(inStream.getBoolean());
        obj.setSuspiciousLocationLoginStatus(inStream.getBoolean());
        obj.setUserInfo(inStream.getObject());
        obj.setUserTimeZoneId(inStream.getString());
        obj.setVipStatus(inStream.getString());
        obj.setWebMasterId(inStream.getInteger());
        obj.setInvID(inStream.getString());
        obj.setPromoID(inStream.getString());
        obj.setPlayerJurisdiction(inStream.getString());
        obj.setKycStatus(inStream.getString());
    }

    writeUserProfile(outStream: TSDataOutputStream, obj: UserProfile): void {
        outStream.putBoolean(obj.getIsValidated());
        outStream.putString(obj.getAccountCurrencyCode());
        outStream.putString(obj.getAccountLanguageId());
        outStream.putString(obj.getAccountName());
        outStream.putByte(obj.getAccountStatus());
        outStream.putObject(obj.getAvatarInfo());
        outStream.putString(obj.getBonusCode());
        outStream.putString(obj.getBrandId());
        outStream.putBoolean(obj.getCanShowPersonalInfo());
        outStream.putString(obj.getChannelId());
        outStream.putString(obj.getCity());
        outStream.putInteger(obj.getDaysPassedAfterRealAccountCreation());
        outStream.putString(obj.getDocumentValidationStatus());
        outStream.putBytes(obj.getEncProfile());
        outStream.putString(obj.getFirstName());
        outStream.putString(obj.getFrontendId());
        outStream.putString(obj.getHearFrom());
        outStream.putBoolean(obj.getIsPMCEnabled());
        outStream.putBoolean(obj.getIsPointsEnabled());
        outStream.putString(obj.getLastName());
        outStream.putString(obj.getLocale());
        outStream.putString(obj.getLoginId());
        outStream.putString(obj.getMailId());
        outStream.putString(obj.getMailingAddress());
        outStream.putString(obj.getPartyPointsCategory());
        outStream.putString(obj.getReferer());
        outStream.putString(obj.getScreenName());
        outStream.putString(obj.getSessionLanguageId());
        outStream.putString(obj.getSex());
        outStream.putBoolean(obj.getShowCity());
        outStream.putBoolean(obj.getSuspiciousLocationLoginStatus());
        outStream.putObject(obj.getUserInfo());
        outStream.putString(obj.getUserTimeZoneId());
        outStream.putString(obj.getVipStatus());
        outStream.putInteger(obj.getWebMasterId());
        outStream.putString(obj.getInvID());
        outStream.putString(obj.getPromoID());
        outStream.putString(obj.getPlayerJurisdiction());
        outStream.putString(obj.getKycStatus());
    }
    readUserInfo(inStream: TSDataInputStream, obj: UserInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setOpcode(inStream.getInteger());
        obj.setProfile(inStream.getObject());
    }

    writeUserInfo(outStream: TSDataOutputStream, obj: UserInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getOpcode());
        outStream.putObject(obj.getProfile());
    }
    readFXRateDetails(inStream: TSDataInputStream, obj: FXRateDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversionFactor4Client(inStream.getLong());
        obj.setFromCurrencyCode(inStream.getString());
        obj.setMarkupType(inStream.getString());
        obj.setReverseConversionFactor4Client(inStream.getLong());
        obj.setSnapshotId(inStream.getLong());
        obj.setToCurrencyCode(inStream.getString());
    }

    writeFXRateDetails(outStream: TSDataOutputStream, obj: FXRateDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversionFactor4Client());
        outStream.putString(obj.getFromCurrencyCode());
        outStream.putString(obj.getMarkupType());
        outStream.putLong(obj.getReverseConversionFactor4Client());
        outStream.putLong(obj.getSnapshotId());
        outStream.putString(obj.getToCurrencyCode());
    }
    readFXRateSnapshot(inStream: TSDataInputStream, obj: FXRateSnapshot): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFxRates(inStream.getObjectArray());
        obj.setSnapshotId(inStream.getLong());
    }

    writeFXRateSnapshot(outStream: TSDataOutputStream, obj: FXRateSnapshot): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getFxRates());
        outStream.putLong(obj.getSnapshotId());
    }
    readFXConversionFactor(inStream: TSDataInputStream, obj: FXConversionFactor): void {
        obj.setConversionFactor4Client(inStream.getLong());
        obj.setMarkupType(inStream.getString());
        obj.setReverseConversionFactor4Client(inStream.getLong());
    }

    writeFXConversionFactor(outStream: TSDataOutputStream, obj: FXConversionFactor): void {
        outStream.putLong(obj.getConversionFactor4Client());
        outStream.putString(obj.getMarkupType());
        outStream.putLong(obj.getReverseConversionFactor4Client());
    }
    readFXRateData(inStream: TSDataInputStream, obj: FXRateData): void {
        obj.setFactors(inStream.getObjectArray());
        obj.setFromCurrencyCode(inStream.getString());
        obj.setToCurrencyCode(inStream.getString());
    }

    writeFXRateData(outStream: TSDataOutputStream, obj: FXRateData): void {
        outStream.putObjectArray(obj.getFactors());
        outStream.putString(obj.getFromCurrencyCode());
        outStream.putString(obj.getToCurrencyCode());
    }
    readCurrencyAmount(inStream: TSDataInputStream, obj: CurrencyAmount): void {
        obj.setAmount(inStream.getLong());
        obj.setCurrencyCode(inStream.getString());
    }

    writeCurrencyAmount(outStream: TSDataOutputStream, obj: CurrencyAmount): void {
        outStream.putLong(obj.getAmount());
        outStream.putString(obj.getCurrencyCode());
    }
    readRequestTerminateLoggedOnOtherMachine(inStream: TSDataInputStream, obj: RequestTerminateLoggedOnOtherMachine): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestTerminateLoggedOnOtherMachine(outStream: TSDataOutputStream, obj: RequestTerminateLoggedOnOtherMachine): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readLoginRequest(inStream: TSDataInputStream, obj: LoginRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginId(inStream.getString());
        obj.setSsoKey(inStream.getString());
    }

    writeLoginRequest(outStream: TSDataOutputStream, obj: LoginRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLoginId());
        outStream.putString(obj.getSsoKey());
    }
    readLoginSuccessResponse(inStream: TSDataInputStream, obj: LoginSuccessResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSsoKey(inStream.getString());
    }

    writeLoginSuccessResponse(outStream: TSDataOutputStream, obj: LoginSuccessResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getSsoKey());
    }
    readLoginSuccessUserProfile(inStream: TSDataInputStream, obj: LoginSuccessUserProfile): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserProfile(inStream.getObject());
    }

    writeLoginSuccessUserProfile(outStream: TSDataOutputStream, obj: LoginSuccessUserProfile): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getUserProfile());
    }
    readSSOKeyMessage(inStream: TSDataInputStream, obj: SSOKeyMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEncodedSSOKey(inStream.getString());
    }

    writeSSOKeyMessage(outStream: TSDataOutputStream, obj: SSOKeyMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getEncodedSSOKey());
    }
    readAvatarDetails(inStream: TSDataInputStream, obj: AvatarDetails): void {
        obj.setAvatarStatus(inStream.getByte());
        obj.setAvatarImageName(inStream.getString());
        obj.setAvatarImagePath(inStream.getString());
    }

    writeAvatarDetails(outStream: TSDataOutputStream, obj: AvatarDetails): void {
        outStream.putByte(obj.getAvatarStatus());
        outStream.putString(obj.getAvatarImageName());
        outStream.putString(obj.getAvatarImagePath());
    }
}
