import { Message } from '../../message';

export class SessionLimitsResponse extends Message {
    private MESSAGE_NAME: string = 'SessionLimitsResponse';
    private limitType: string | null = null;
    private percentageElapsed: number = 0;
    private sessionLimitElaspedMins: number = 0;
    private sessionLimitConfiguredMins: number = 0;
    private sessionLimitRemainingMins: number = 0;
    private accountName: string | null = null;
    private useCase: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        limitType: string | null = null,
        percentageElapsed: number = 0,
        sessionLimitElaspedMins: number = 0,
        sessionLimitConfiguredMins: number = 0,
        sessionLimitRemainingMins: number = 0,
        accountName: string | null = null,
        useCase: number = 0,
    ) {
        super(reqServerPeerId);
        this.limitType = limitType;
        this.percentageElapsed = percentageElapsed;
        this.sessionLimitElaspedMins = sessionLimitElaspedMins;
        this.sessionLimitConfiguredMins = sessionLimitConfiguredMins;
        this.sessionLimitRemainingMins = sessionLimitRemainingMins;
        this.accountName = accountName;
        this.useCase = useCase;
    }

    getLimitType(): string | null {
        return this.limitType;
    }

    setLimitType(limitType: string | null) {
        this.limitType = limitType;
    }
    getPercentageElapsed(): number {
        return this.percentageElapsed;
    }

    setPercentageElapsed(percentageElapsed: number) {
        this.percentageElapsed = percentageElapsed;
    }
    getSessionLimitElaspedMins(): number {
        return this.sessionLimitElaspedMins;
    }

    setSessionLimitElaspedMins(sessionLimitElaspedMins: number) {
        this.sessionLimitElaspedMins = sessionLimitElaspedMins;
    }
    getSessionLimitConfiguredMins(): number {
        return this.sessionLimitConfiguredMins;
    }

    setSessionLimitConfiguredMins(sessionLimitConfiguredMins: number) {
        this.sessionLimitConfiguredMins = sessionLimitConfiguredMins;
    }
    getSessionLimitRemainingMins(): number {
        return this.sessionLimitRemainingMins;
    }

    setSessionLimitRemainingMins(sessionLimitRemainingMins: number) {
        this.sessionLimitRemainingMins = sessionLimitRemainingMins;
    }
    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getUseCase(): number {
        return this.useCase;
    }

    setUseCase(useCase: number) {
        this.useCase = useCase;
    }
}
