import { Message } from '../../message';

export class MTCTResponse extends Message {
    private MESSAGE_NAME: string = 'MTCTResponse';
    private trackerIdList: any[] | null = null;
    private responseId: number = 0;
    private message: any | null = null;
    private mtctID: number = 0;
    private conversationId: number = 0;
    private isRematchRegRes: boolean | null = false;
    private participantNo: number = 0;
    private freeRollReturned: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        trackerIdList: any[] | null = null,
        responseId: number = 0,
        message: any | null = null,
        mtctID: number = 0,
        conversationId: number = 0,
        isRematchRegRes: boolean | null = false,
        participantNo: number = 0,
        freeRollReturned: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.trackerIdList = trackerIdList;
        this.responseId = responseId;
        this.message = message;
        this.mtctID = mtctID;
        this.conversationId = conversationId;
        this.isRematchRegRes = isRematchRegRes;
        this.participantNo = participantNo;
        this.freeRollReturned = freeRollReturned;
    }

    getTrackerIdList(): any[] | null {
        return this.trackerIdList;
    }

    setTrackerIdList(trackerIdList: any[] | null) {
        this.trackerIdList = trackerIdList;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getMtctID(): number {
        return this.mtctID;
    }

    setMtctID(mtctID: number) {
        this.mtctID = mtctID;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getIsRematchRegRes(): boolean | null {
        return this.isRematchRegRes;
    }

    setIsRematchRegRes(isRematchRegRes: boolean | null) {
        this.isRematchRegRes = isRematchRegRes;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getFreeRollReturned(): boolean | null {
        return this.freeRollReturned;
    }

    setFreeRollReturned(freeRollReturned: boolean | null) {
        this.freeRollReturned = freeRollReturned;
    }
}
