import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class TableViewModel {
    gameService: any;
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.view.setDispatcherScope(EventDispatcher);
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_END_THE_ROUND, this.updatePotLimit.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_NEW_GAME, this.updateTableData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.REQ_POKER_DIAMOND_BALANCE, this.sendReqDiamondbalance.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.MINI_SWITCHER_CLICKED, this.miniSwitcherClicked.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_REBUY_STATUS, this.updateRebuyStatus.bind(this));
        this.EventDispatcher.addEventListener('clearSpinsInterval', this.clearTableInterval.bind(this));
        if (this.gameService.getTableStatusData() !== undefined) {
            this.updateTableData();
        }
    };
    clearTableInterval = function () {
        this.view.clearTableInterval();
    };
    resetGame = function () {
        this.view.resetGame();
    };
    miniSwitcherClicked = function () {
        this.view.checkForResize();
    };
    updateTableData = function () {
        this.view.updateTableData(
            this.gameService.getTableStatusData(),
            this.gameService.getCurrentGameId(),
            this.gameService.getNextleavelData(),
            this.gameService.getGameTableType(),
            this.gameService.getPeerId(),
        );
    };
    updatePotLimit = function () {
        // unreachable code
        //this.view.updatePotLimit(this.gameService.getEndTheRoundData());
    };
    //@Maniraj to be removed
    sendReqDiamondbalance = function () {
        this.gameTableNotificationService.sendReqDiamondBalance(this.gameService.getPeerId());
    };
    updateRebuyStatus = function (type) {
        if (this.gameService.getGameTableType() != serverIdConstants.TABLE_TYPE_SPINS) {
            this.view.updateRebuyStatus(type.detail.status);
        }
    };
}
