import { Message } from '../../message';
import { SNGJPPayoutInfo } from './SNGJPPayoutInfo';

export class SNGJPPayoutAnimation extends Message {
    private MESSAGE_NAME: string = 'SNGJPPayoutAnimation';
    private sngJackpotID: number = 0;
    private sngJPInstanceID: number = 0;
    private sngJPTableId: number = 0;
    private sngJPType: number = 0;
    private animationType: number = 0;
    private animationPayOutData: any[] | null = null;
    private sngJPPayoutInfo: SNGJPPayoutInfo | null = null;
    private selectedMultiplier: number = 0;
    private selectedMultiplierNew: number = 0;
    private tableBackgroundImage: string | null = null;
    private buyin: number = 0;
    private sngCardType: number = 0;
    private expoAnimationInMillis: number = 0;
    private maxMultiplierConfigured: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        sngJPInstanceID: number = 0,
        sngJPTableId: number = 0,
        sngJPType: number = 0,
        animationType: number = 0,
        animationPayOutData: any[] | null = null,
        sngJPPayoutInfo: SNGJPPayoutInfo | null = null,
        selectedMultiplier: number = 0,
        selectedMultiplierNew: number = 0,
        tableBackgroundImage: string | null = null,
        buyin: number = 0,
        sngCardType: number = 0,
        expoAnimationInMillis: number = 0,
        maxMultiplierConfigured: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.sngJPInstanceID = sngJPInstanceID;
        this.sngJPTableId = sngJPTableId;
        this.sngJPType = sngJPType;
        this.animationType = animationType;
        this.animationPayOutData = animationPayOutData;
        this.sngJPPayoutInfo = sngJPPayoutInfo;
        this.selectedMultiplier = selectedMultiplier;
        this.selectedMultiplierNew = selectedMultiplierNew;
        this.tableBackgroundImage = tableBackgroundImage;
        this.buyin = buyin;
        this.sngCardType = sngCardType;
        this.expoAnimationInMillis = expoAnimationInMillis;
        this.maxMultiplierConfigured = maxMultiplierConfigured;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getSngJPInstanceID(): number {
        return this.sngJPInstanceID;
    }

    setSngJPInstanceID(sngJPInstanceID: number) {
        this.sngJPInstanceID = sngJPInstanceID;
    }
    getSngJPTableId(): number {
        return this.sngJPTableId;
    }

    setSngJPTableId(sngJPTableId: number) {
        this.sngJPTableId = sngJPTableId;
    }
    getSngJPType(): number {
        return this.sngJPType;
    }

    setSngJPType(sngJPType: number) {
        this.sngJPType = sngJPType;
    }
    getAnimationType(): number {
        return this.animationType;
    }

    setAnimationType(animationType: number) {
        this.animationType = animationType;
    }
    getAnimationPayOutData(): any[] | null {
        return this.animationPayOutData;
    }

    setAnimationPayOutData(animationPayOutData: any[] | null) {
        this.animationPayOutData = animationPayOutData;
    }
    getSngJPPayoutInfo(): SNGJPPayoutInfo | null {
        return this.sngJPPayoutInfo;
    }

    setSngJPPayoutInfo(sngJPPayoutInfo: SNGJPPayoutInfo | null) {
        this.sngJPPayoutInfo = sngJPPayoutInfo;
    }
    getSelectedMultiplier(): number {
        return this.selectedMultiplier;
    }

    setSelectedMultiplier(selectedMultiplier: number) {
        this.selectedMultiplier = selectedMultiplier;
    }
    getSelectedMultiplierNew(): number {
        return this.selectedMultiplierNew;
    }

    setSelectedMultiplierNew(selectedMultiplierNew: number) {
        this.selectedMultiplierNew = selectedMultiplierNew;
    }
    getTableBackgroundImage(): string | null {
        return this.tableBackgroundImage;
    }

    setTableBackgroundImage(tableBackgroundImage: string | null) {
        this.tableBackgroundImage = tableBackgroundImage;
    }
    getBuyin(): number {
        return this.buyin;
    }

    setBuyin(buyin: number) {
        this.buyin = buyin;
    }
    getSngCardType(): number {
        return this.sngCardType;
    }

    setSngCardType(sngCardType: number) {
        this.sngCardType = sngCardType;
    }
    getExpoAnimationInMillis(): number {
        return this.expoAnimationInMillis;
    }

    setExpoAnimationInMillis(expoAnimationInMillis: number) {
        this.expoAnimationInMillis = expoAnimationInMillis;
    }
    getMaxMultiplierConfigured(): number {
        return this.maxMultiplierConfigured;
    }

    setMaxMultiplierConfigured(maxMultiplierConfigured: number) {
        this.maxMultiplierConfigured = maxMultiplierConfigured;
    }
}
