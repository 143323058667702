import { Message } from '../../message';

export class ResponsePoolPlayerList extends Message {
    private MESSAGE_NAME: string = 'ResponsePoolPlayerList';
    private poolId: number = 0;
    private players: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, poolId: number = 0, players: any[] | null = null) {
        super(reqServerPeerId);
        this.poolId = poolId;
        this.players = players;
    }

    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
    getPlayers(): any[] | null {
        return this.players;
    }

    setPlayers(players: any[] | null) {
        this.players = players;
    }
}
