import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class KothTableViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_HU_PAIR_TABLE, this.showHuPairTableView.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.REMOVE_PLAYER, this.hideKothBtn.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_KOTH_BUTTONS, this.hideAllKothBtn.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_KOTH_PLUS, this.hidekothplus.bind(this));
    };
    showHuPairTableView = function () {
        if (CommonGameProperties.facade['activeTables'].length <= 4) {
            this.view.displayKothButtons();
        }
    };
    sendHuPairTableSelectStatus = function (boolVal) {
        this.gameService.setHuPairTableStatusVal(boolVal);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HU_PAIR_TABLE_STATUS);
    };
    hidekothplus = function () {
        this.view.isPlayerJoined = false;
        this.hideKothBtn();
    };
    hideKothBtn = function () {
        //if(this.view.hideKothBtn())
        this.view.hideKothBtn();
    };
    hideSelectedIcon = function () {
        this.view.hideSelectedIcon();
    };
    resetGame = function () {
        this.view.resetGame();
    };
}
