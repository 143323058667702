/* eslint-disable @typescript-eslint/no-unused-vars */
import { IConnectorDelegate } from '../pgConnectors/sal/interfaces/IconnectorDelegate';
import { Message } from '../pgConnectors/server/common/message';

export class ConnectorDelegateAdapter implements IConnectorDelegate {
    handleMessage(message: Message, i: number, domain: number): void {}

    updateConnectionStatus(domainId: number, status: number): void {}

    connectionSuccessforServer(serverIP: string): void {}

    log(cause: number, logMessage: string): void {}

    handshakeResponseStatus(success: boolean, domain: number): void {}
}
