<div class="full_height_ff px-3">
    <!-- filter header -->
    <div class="select_label_ff">
        <label class="filter_sub_txt_ff">{{ 'PARTY_POKER_MOBILE_LOBBY_FILTER_SELECTEDBUYINS' | translate }}</label>
    </div>
    <!-- filter body -->
    <div class="content_ff_fil">
        <div class="filter_buyins_ff">
            <div [ngClass]="{ 'horizontal_flex marT15 filter_scroll': true }">
                <div class="btn_margin">
                    <button
                        [ngClass]="uniqWinUptos.length === selectedWinUptos.length ? 'active_button ' : ''"
                        (click)="gameCostToggle(-1, uniqWinUptos.length === selectedWinUptos.length)">
                        {{ 'PARTY_POKER_MOBILE_COMMON_ALL' | translate }}
                    </button>
                </div>
                <div *ngFor="let item of uniqWinUptos; let i = index" [attr.index]="i" class="btn_margin">
                    <button
                        [ngClass]="{ 'single-button_left': true, 'active_button': selectedWinUptos.includes(item) }"
                        (click)="gameCostToggle(item, '')">
                        ${{ item }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- filter footer -->
    <div id="footer_fill_ff">
        <div class="txt_center filter_border mt-1">
            <span class="totalTables_txt">{{ 'PARTY_POKER_MOBILE_LOBBY_FILTER_TotalTablesFound' | translate }}</span>
            <span class="orange_txt">{{ filteredCardsCount }}</span>
        </div>
        <div class="filter_footer_width">
            <button type="button" class="btn btn-md btn-dark text-capitalize w-50 mr-2" (click)="initializeFilter(currentFilter, true)">
                {{ 'PARTY_POKER_GC_PREFERENCES_RESET' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-md btn-primary text-capitalize w-50"
                [ngClass]="{ apply_btn: true, disableColor: filteredCardsCount === 0 }"
                (click)="submit()"
                [disabled]="filteredCardsCount === 0">
                {{ 'PARTY_POKER_GC_COMMON_APPLY' | translate }}
            </button>
        </div>
    </div>
</div>
