import { Component, OnInit } from '@angular/core';

import { DeviceService, NavigationService, Page, TrackingService } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { DownloadClientConfig, NavigationBarComponent, NavigationBarPokerComponent, PokerHomePageClientConfig } from '@pokercore/module/core';

import { PokerThreeTileContainerComponent } from '../poker-threeTile-container/poker-threeTile-container.component';
import { PokerTeaserCarouselComponent } from '../teaser-carousel/pk-teaser-carousel.component';

@Component({
    selector: 'pk-poker-download-layout',
    templateUrl: 'poker-download-layout.component.html',
    standalone: true,
    imports: [NavigationBarComponent, NavigationBarPokerComponent, PokerThreeTileContainerComponent, PokerTeaserCarouselComponent],
})
export class PokerDownloadLayoutComponent implements OnInit {
    content: any;
    initData: any;
    directDownloadLink: string;
    directAppDownloadLink: string;
    downloadContent: any;
    showConfigureNavigation: boolean = false;
    teasersMainClass: string = 'teasers';
    downloadContents: any = [];
    isLandscape: any;
    useMD5: any;
    delay: number = 5000;
    navigationarrows: boolean = true;
    dots: boolean = true;
    loop: boolean = true;
    autoplay: boolean = true;
    pauseOnHover: boolean = true;
    class: any;
    teasersResponse: any[] = [];
    isMobileDownloadNow: boolean = false;
    isTabletDownloadNow: boolean = false;

    // For threeTileContainer

    threeTileContainer: any;

    constructor(
        private routeData: RouteDataService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private page: Page,
        private navigation: NavigationService,
        public deviceService: DeviceService,
        private tracking: TrackingService,
        private downloadClientConfig: DownloadClientConfig,
    ) {}

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.initData = this.routeData.getInitData();
        this.directDownloadLink = this.page.lang + '/downloadclient';
        this.directAppDownloadLink = this.page.lang + '/downloadapp';
        this.downloadContents = this.initData.downloadContent.downloadContents;

        // For DownloadPokerTeaser
        this.navigationarrows = false;
        this.dots = false;
        this.loop = false;
        this.class = this.assignBrowserSpecificClass();
        //For Responsive
        if (this.deviceService.isMobile && !this.deviceService.isTablet) {
            this.isMobileDownloadNow = true;
            this.isTabletDownloadNow = false;
        } else if (this.deviceService.isMobile && this.deviceService.isTablet) {
            if (this.deviceService.currentOrientation == 'portrait') {
                this.isTabletDownloadNow = true;
                this.isMobileDownloadNow = false;
            } else if (this.deviceService.currentOrientation == 'landscape') {
                this.isTabletDownloadNow = false;
                this.isMobileDownloadNow = false;
            }
        }
        if (this.downloadClientConfig.isPublicPage) {
            window.location.href = this.initData.downloadUrl;
        }
    }
    assignBrowserSpecificClass() {
        if (navigator.userAgent.indexOf('Edge') != -1) {
            return 'edge';
        }
        return null;
    }
    teaserClick(value: any) {
        if (value) {
        }
    }

    public ctaClick(value: any) {
        if (value) {
            const teaser: any = value.teaser;
            if (teaser && teaser.ctaLink) {
                this.trackingClickEvent(teaser.ctaLink.url);
                if (teaser.ctaLink.attributes.target != '_blank' && teaser.ctaLink.url != '') {
                    this.navigation.goTo(teaser.ctaLink.url);
                }
            }
        }
    }
    public goToDownload() {
        this.trackingClickEvent(this.initData.downloadUrl);
        window.location.href = this.initData.downloadUrl;
    }
    trackingClickEvent(clickUrl: any) {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Poker',
            'component.LabelEvent': 'Download',
            'component.ActionEvent': 'CTA_Clicked',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'Download',
            'component.EventDetails': 'Poker_Downloads',
            'component.URLClicked': clickUrl,
        });
    }
}
