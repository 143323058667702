import { Message } from '../../message';

export class UpdateCardTag extends Message {
    private MESSAGE_NAME: string = 'UpdateCardTag';
    private cardId: number = 0;
    private tag: number = 0;
    private lastPlayed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardId: number = 0, tag: number = 0, lastPlayed: boolean | null = false) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.tag = tag;
        this.lastPlayed = lastPlayed;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getTag(): number {
        return this.tag;
    }

    setTag(tag: number) {
        this.tag = tag;
    }
    getLastPlayed(): boolean | null {
        return this.lastPlayed;
    }

    setLastPlayed(lastPlayed: boolean | null) {
        this.lastPlayed = lastPlayed;
    }
}
