import { Message } from '../../message';

export class LobbyPlayerTableCounts extends Message {
    private MESSAGE_NAME: string = 'LobbyPlayerTableCounts';
    private tableCount: number = 0;
    private playerCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableCount: number = 0, playerCount: number = 0) {
        super(reqServerPeerId);
        this.tableCount = tableCount;
        this.playerCount = playerCount;
    }

    getTableCount(): number {
        return this.tableCount;
    }

    setTableCount(tableCount: number) {
        this.tableCount = tableCount;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
}
