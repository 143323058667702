import { Message } from '../../message';

export class ContentLockingAccessDeniedResponse extends Message {
    private MESSAGE_NAME: string = 'ContentLockingAccessDeniedResponse';
    private levelId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, levelId: number = 0) {
        super(reqServerPeerId);
        this.levelId = levelId;
    }

    getLevelId(): number {
        return this.levelId;
    }

    setLevelId(levelId: number) {
        this.levelId = levelId;
    }
}
