import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class UserProfileViewModel {
    gameService;
    parentFacade;
    constructor(gs, facade) {
        this.gameService = gs;
        this.parentFacade = facade;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.showingSitIn = false;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_EMOJIS_NOW, this.throwEmoji.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_DIAMOND_BALANCE, this.updateDiamondBalance.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_IAM_BACK, this.showSitIn.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.IAM_BACK_CLICKED, this.showSitOut.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_SITOUT, this.showSitIn.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_SETTING_DATA, this.updateThrowState.bind(this));
        this.enableThrowMode = this.parentFacade.tableSettingsDataModel.getGameSettingData().throwAnimations;
        // commented below two lines as popup getting closed for every bet option selected like "I'BACK"
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.BET_OPTION_SELECTED,this.closeUserPopup.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.AUTO_BET_OPTION_SELECTED,this.closeUserPopup.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_DIAMOND_BALANCE, this.updateEmojistate.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_ACTION_FOR_CASHOUT, this.hideThrowEmojiContainer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_ACTION_FOR_CASHOUT, this.showThrowEmojiContainer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_ACTION_FOR_CASHOUT, this.hideThrowEmojiForCashOut.bind(this));
    };
    hideThrowEmojiForCashOut = function () {
        this.view.hideThrowEmojiForCashOut();
    };
    hideThrowEmojiContainer = function () {
        this.view.hideThrowEmojiContainer();
    };
    showThrowEmojiContainer = function () {
        this.view.showThrowEmojiContainer();
    };
    closeUserPopup = function () {
        this.view.onClose();
    };
    //updateEmojistate= function(){
    // };
    onOtherPlayerEmojiClick = function (animationId, toPlayerSeatNo) {
        const obj = {
            animationId: animationId,
            toPlayerSeatNo: parseInt(toPlayerSeatNo),
        };
        this.gameService.setReqThrowAnimationData(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REQ_THROW_ANIMATION);
    };
    throwEmoji = function (obj) {
        obj.detail.balance = this.gameService.getDiamondBalance() ? this.gameService.getDiamondBalance() : 0;
        this.view.showUserInfoViewNow(obj.detail);
        this.updateDiamondBalance(obj.detail.balance);
    };
    onClickLeave = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_LEAVE);
    };
    onClickSitOut = function () {
        if (this.showingSitIn) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.IAM_BACK_CLICKED);
        } else {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_SITOUT);
        }
    };
    resetGame = function () {
        this.view.resetGame();
    };
    showSitOut = function () {
        this.showingSitIn = false;
        this.view.showSitIn(this.showingSitIn);
    };
    showSitIn = function () {
        this.showingSitIn = true;
        this.view.showSitIn(this.showingSitIn);
    };
    updateDiamondBalance = function () {
        const dBal = this.gameService.getDiamondBalance();
        this.view.updateDiamondBalance(dBal);
        this.view.checkEmojiState(dBal, this.enableThrowMode);
    };
    // update throw animations enabling from settings panel
    updateThrowState = function (data) {
        this.enableThrowMode = data.detail.throwAnimations;
    };
}
