import { Message } from '../../message';

export class LSRequestMTCTId extends Message {
    private MESSAGE_NAME: string = 'LSRequestMTCTId';
    private freerollId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, freerollId: number = 0) {
        super(reqServerPeerId);
        this.freerollId = freerollId;
    }

    getFreerollId(): number {
        return this.freerollId;
    }

    setFreerollId(freerollId: number) {
        this.freerollId = freerollId;
    }
}
