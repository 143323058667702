import { Message } from '../../message';

export class MTCTRegistrationChangeEvent extends Message {
    private MESSAGE_NAME: string = 'MTCTRegistrationChangeEvent';
    private mtctId: number = 0;
    private noOfParticipants: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, noOfParticipants: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.noOfParticipants = noOfParticipants;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
}
