import { Message } from '../../message';

export class InstallDynamicDLLStatus extends Message {
    private MESSAGE_NAME: string = 'InstallDynamicDLLStatus';
    private statusId: number = 0;
    private md5OfExistingDLL: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, statusId: number = 0, md5OfExistingDLL: string | null = null) {
        super(reqServerPeerId);
        this.statusId = statusId;
        this.md5OfExistingDLL = md5OfExistingDLL;
    }

    getStatusId(): number {
        return this.statusId;
    }

    setStatusId(statusId: number) {
        this.statusId = statusId;
    }
    getMd5OfExistingDLL(): string | null {
        return this.md5OfExistingDLL;
    }

    setMd5OfExistingDLL(md5OfExistingDLL: string | null) {
        this.md5OfExistingDLL = md5OfExistingDLL;
    }
}
