import { Message } from '../../message';

export class RequestNoBuddiesList extends Message {
    private MESSAGE_NAME: string = 'RequestNoBuddiesList';

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0) {
        super(reqServerPeerId);
    }
}
