<section class="psimu-get-started">
    <div id="getStarted">
        <div class="psimu-head">
            <a (click)="backTo()" class="back-btn"><span class="ui-back theme-left"></span><span></span></a>
            <a [href]="homePage" class="close-btn theme-ex mobile" *ngIf="!nativeApp"></a>
        </div>
        <form class="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="psimu-get-started-main">
                <div class="psimu-wiz-content psimu-wiz-content-form">
                    <h3>{{ commonParams.were_born }}</h3>
                    <div class="username-form floating-form">
                        <div class="form-group form-base mt-2">
                            <h6>{{ commonParams.call_you }}</h6>
                            <label class="form-group-label floating-label">{{ commonParams.nickname }}</label>
                            <div id="nickname" class="form-control-container">
                                <input name="nickname" formControlName="nickname" type="text" class="form-control" />
                                <div
                                    class="custom-error m2-validation-messages"
                                    *ngIf="
                                        registerFormControl.nickname.invalid &&
                                        (registerFormControl.nickname.dirty || registerFormControl.nickname.touched)
                                    ">
                                    <div *ngIf="registerFormControl.nickname.errors?.required">
                                        <span class="m2-validation-message">{{ commonParams.name_is_required }}</span>
                                    </div>
                                    <div *ngIf="registerFormControl.nickname.errors?.minlength">
                                        <span class="m2-validation-message">{{ commonParams.characters_long }}</span>
                                    </div>
                                    <div *ngIf="registerFormControl.nickname.errors?.maxlength">
                                        <span class="m2-validation-message">{{ commonParams.more_then_15 }}</span>
                                    </div>
                                    <div *ngIf="registerFormControl.nickname.errors?.pattern">
                                        <span class="m2-validation-message">{{ commonParams.valid_name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group form-base mt-3 form-group-dob">
                            <h6>{{ commonParams.age_confirm }}</h6>
                            <div class="row g-2" formGroupName="dob">
                                <div class="col-4">
                                    <div id="dobDate" class="form-control-container">
                                        <input
                                            #dayInput
                                            formControlName="day"
                                            name="dobDate"
                                            type="number"
                                            class="form-control"
                                            placeholder="DD"
                                            maxlength="2"
                                            (input)="moveFocus(dayInput, monthInput)" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div id="dobMonth" class="form-control-container">
                                        <input
                                            #monthInput
                                            formControlName="month"
                                            name="dobMonth"
                                            type="number"
                                            class="form-control"
                                            placeholder="MM"
                                            maxlength="2"
                                            (input)="moveFocus(monthInput, yearInput)" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div id="dobYear" class="form-control-container">
                                        <input
                                            #yearInput
                                            formControlName="year"
                                            name="dobYear"
                                            type="number"
                                            class="form-control"
                                            placeholder="YYYY"
                                            maxlength="4" />
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="dobFormControl?.year?.invalid && (dobFormControl?.year?.dirty || dobFormControl?.year?.touched)">
                                <h6 class="mt-3" *ngIf="dobFormControl?.year.errors?.required">
                                    <span class="error-mark">!</span>{{ commonParams.age_required }}
                                </h6>
                            </ng-container>
                            <h6 class="mt-3" *ngIf="!isDobValid"><span class="error-mark">!</span>{{ commonParams.valid_dob }}</h6>
                            <h6 class="mt-3" *ngIf="ageUnder18"><span class="error-mark">!</span>{{ commonParams.must_over_18 }}</h6>
                        </div>
                    </div>

                    <div class="getstarted-info">
                        <p>{{ commonParams.not_real_game_mesg }}</p>
                    </div>
                </div>

                <div class="psimu-get-started-bottom">
                    <button
                        class="btn btn-primary btn-g-start"
                        [ngClass]="{ disabled: registerForm.invalid || !isDobValid }"
                        type="submit"
                        (click)="trackingSubmitFormEvent(commonParams.Next)">
                        {{ commonParams.Next }}
                    </button>
                    <div class="psimu-download-link mt-5" *ngIf="!nativeApp">
                        <span>{{ commonParams.love_poker }}</span>
                        <a [href]="parameters?.url" (click)="trackingDownloadEvent(parameters?.url, parameters?.text || 'Download now')">{{
                            parameters?.text || 'Download now'
                        }}</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
