import { Message } from '../../message';
import { PvtTableType } from './PvtTableType';

export class CreatePvtTableResponse extends Message {
    private MESSAGE_NAME: string = 'CreatePvtTableResponse';
    private privateTableType: PvtTableType | null = null;
    private responseId: number = 0;
    private tableName: any | null = null;
    private timeOut: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        privateTableType: PvtTableType | null = null,
        responseId: number = 0,
        tableName: any | null = null,
        timeOut: number = 0,
    ) {
        super(reqServerPeerId);
        this.privateTableType = privateTableType;
        this.responseId = responseId;
        this.tableName = tableName;
        this.timeOut = timeOut;
    }

    getPrivateTableType(): PvtTableType | null {
        return this.privateTableType;
    }

    setPrivateTableType(privateTableType: PvtTableType | null) {
        this.privateTableType = privateTableType;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getTableName(): any | null {
        return this.tableName;
    }

    setTableName(tableName: any | null) {
        this.tableName = tableName;
    }
    getTimeOut(): number {
        return this.timeOut;
    }

    setTimeOut(timeOut: number) {
        this.timeOut = timeOut;
    }
}
