import { Injectable } from '@angular/core';

// import { GameTableNotificationService } from './service/game-table-notification.service';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import { BehaviorSubject, Observable } from 'rxjs';

import { ClientSubHeaderModel } from './models/client-sub-header.model';
import { PokerWebClientService } from './poker-web-client.service';

@Injectable({ providedIn: 'root' })
export class PokerWebClientSharedService {
    public lobbyPromotions: any;
    public activePortalPage = new BehaviorSubject<any>(null);
    public pageHeaderTitle = new BehaviorSubject<any>(null);
    regulatoryIconsData: any;
    private readonly logger: PokerLogger;
    constructor(
        private resource: PokerWebClientService,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('pokerwebclientsharedserviceLoggingComponent');
        this.getRegulatoryIconsData();
    }

    getApi() {}

    sendPortalPage(data: any): void {
        this.activePortalPage.next(data);
        this.logger.info('sendPortalPage...!' + JSON.stringify(data));
    }

    getPortalPage(): Observable<any> {
        this.logger.info('getPortalPage data...!');
        return this.activePortalPage.asObservable();
    }

    getRegulatoryIconsData() {
        this.logger.info('getRegulatoryIconsData...!');
        this.resource.getRegulatoryIcons().subscribe((data) => {
            this.regulatoryIconsData = data;
        });
    }

    setPageHeaderTitle(data: ClientSubHeaderModel) {
        this.logger.info('setPageHeaderTitle...!');
        this.pageHeaderTitle.next(data);
    }

    getPageHeaderTitle(): Observable<any> {
        return this.pageHeaderTitle.asObservable();
    }
}
