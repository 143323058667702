import { Routes } from '@angular/router';

import { PokerPowerFestSynopsisResolver } from './poker-powerfest-synopsis.resolver';
import { PokerPowerFestResolver } from './poker-powerfest.resolver';

export const pokerPowerFestRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPowerFestResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerPowerFestSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPowerFestResolver,
        },
    },
];
