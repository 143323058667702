import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-alpha-download-client',
    templateUrl: 'poker-alpha-download-client.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PokerAlphaDownloadClientComponent extends PCComponent {
    alphaDownloadContent: any;
    downloadUrl: any;
    buttonText: any;

    constructor(private page: Page) {
        super();
    }

    @HostBinding()
    get class() {
        this.alphaDownloadContent = this.item;
        this.downloadUrl = this.page.homePage + this.alphaDownloadContent.ctaLink.url;
        this.buttonText = this.alphaDownloadContent.ctaLink.text;
        return this.createClass(this.item.name);
    }
}
