import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-single-promo-timer',
    templateUrl: 'poker-single-promo-timer.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PokerSinglePromoTimerComponent extends PCComponent {
    startTime: any;
    endTime: any;
    currentTimeInUTC: any;
    currentTime: any;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    millisecondsleft: number;
    regEndMilliSecondsLeft: number;
    timeLeft: string;
    callback: any;
    registrationDetails: any;
    bannerDisplayTime: any;
    bannerKickoffInHours: number;
    bannerKickoffInDays: number;
    data: any;
    isBannerEnabled: boolean = false;
    isRegistrationStarted: boolean = false;
    isRegistrationClosed: boolean = false;
    registrationStartText: string;
    registrationEndText: string;
    registrationClosedText: string;
    beforeRegistrationCTALink: string;
    beforeRegistrationCTAText: string;
    duringRegistrationCTALink: string;
    duringRegistrationCTAText: string;
    promoFlashMessage: string;
    startTimeInUtc: string;
    endTimeInUtc: string;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit() {
        this.startTimeInUtc = this.item.startTimeInUtc;
        this.endTimeInUtc = this.item.endTimeInUtc;
        this.registrationStartText = this.item.registrationStartText;
        this.registrationEndText = this.item.registrationEndText;
        this.registrationClosedText = this.item.registrationClosedText;
        this.bannerKickoffInHours = this.item.bannerKickoffInHours;
        this.bannerKickoffInDays = this.item.bannerKickoffInDays;
        this.beforeRegistrationCTALink = this.item.beforeRegistrationCTALink.url;
        this.beforeRegistrationCTAText = this.item.beforeRegistrationCTAText;
        this.duringRegistrationCTALink = this.item.duringRegistrationCTALink.url;
        this.duringRegistrationCTAText = this.item.duringRegistrationCTAText;
        this.promoFlashMessage = this.item.promoFlashMessage;

        this.refresh();
        this.callback = setInterval(() => {
            this.refresh();
        }, 1000);
    }

    private refresh() {
        this.startTime = new Date(this.startTimeInUtc);
        this.endTime = new Date(this.endTimeInUtc);
        this.currentTimeInUTC = new Date().toUTCString();
        this.currentTime = new Date(this.currentTimeInUTC);

        if (this.bannerKickoffInHours > 0 && this.bannerKickoffInHours != null) {
            const bannerKickOffTime = new Date(this.startTime);
            this.bannerDisplayTime = bannerKickOffTime.setHours(bannerKickOffTime.getHours() - this.bannerKickoffInHours);
        } else {
            const bannerKickOffTime = new Date(this.startTime);
            this.bannerDisplayTime = bannerKickOffTime.setDate(bannerKickOffTime.getDate() - this.bannerKickoffInDays);
        }

        this.millisecondsleft = this.startTime - this.currentTime;
        this.regEndMilliSecondsLeft = this.endTime - this.currentTime;

        if (this.currentTime > this.bannerDisplayTime && this.millisecondsleft > 0) {
            if (this.millisecondsleft > 0) {
                this.isRegistrationClosed = false;
                this.isBannerEnabled = true;
                this.isRegistrationStarted = false;
                this.days = Math.floor(this.millisecondsleft / 86400000);
                this.hours = Math.floor((this.millisecondsleft / 3600000) % 24);
                this.minutes = Math.floor((this.millisecondsleft / (1000 * 60)) % 60);
                this.seconds = Math.floor((this.millisecondsleft / 1000) % 60);
                this.formatMessage(this.days, this.hours, this.minutes, this.seconds);
            } else {
                this.timeLeft = '';
                this.isBannerEnabled = false;
                this.isRegistrationStarted = false;
                this.isRegistrationClosed = true;
            }
        }

        if (this.currentTime > this.startTime && this.regEndMilliSecondsLeft > 0) {
            if (this.regEndMilliSecondsLeft > 0) {
                this.isRegistrationClosed = false;
                this.isBannerEnabled = false;
                this.isRegistrationStarted = true;
                this.days = Math.floor(this.millisecondsleft / 86400000);
                this.hours = Math.floor((this.millisecondsleft / 3600000) % 24);
                this.minutes = Math.floor((this.millisecondsleft / (1000 * 60)) % 60);
                this.seconds = Math.floor((this.millisecondsleft / 1000) % 60);
                this.formatMessage(this.days, this.hours, this.minutes, this.seconds);
            } else {
                this.timeLeft = '';
                this.isBannerEnabled = false;
                this.isRegistrationStarted = false;
                this.isRegistrationClosed = true;
            }
        }
    }

    private formatMessage(days: number, hours: number, minutes: number, seconds: number) {
        this.timeLeft =
            (days < 10 ? '0' : '') +
            days +
            ':' +
            (hours < 10 ? '0' : '') +
            hours +
            ':' +
            (minutes < 10 ? '0' : '') +
            minutes +
            ':' +
            (seconds < 10 ? '0' : '') +
            seconds;
    }

    ngOnDestroy() {
        clearInterval(this.callback);
    }
}
