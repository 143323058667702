import { Message } from '../../message';

export class BuyMoreChips extends Message {
    private MESSAGE_NAME: string = 'BuyMoreChips';
    private amount: number = 0;
    private conversationId: number = 0;
    private includePartnerWallet: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, amount: number = 0, conversationId: number = 0, includePartnerWallet: number = 0) {
        super(reqServerPeerId);
        this.amount = amount;
        this.conversationId = conversationId;
        this.includePartnerWallet = includePartnerWallet;
    }

    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getIncludePartnerWallet(): number {
        return this.includePartnerWallet;
    }

    setIncludePartnerWallet(includePartnerWallet: number) {
        this.includePartnerWallet = includePartnerWallet;
    }
}
