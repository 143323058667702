import { Message } from '../../message';

export class ShootoutInfo extends Message {
    private MESSAGE_NAME: string = 'ShootoutInfo';
    private totalRoundCount: number = 0;
    private prize11: number = 0;
    private prize12: number = 0;
    private prize13: number = 0;
    private prize21: number = 0;
    private prize22: number = 0;
    private prize23: number = 0;
    private prize31: number = 0;
    private prize32: number = 0;
    private prize33: number = 0;
    private prize41: number = 0;
    private prize42: number = 0;
    private prize43: number = 0;
    private playerCount1: number = 0;
    private playerCount2: number = 0;
    private playerCount3: number = 0;
    private playerCount4: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        totalRoundCount: number = 0,
        prize11: number = 0,
        prize12: number = 0,
        prize13: number = 0,
        prize21: number = 0,
        prize22: number = 0,
        prize23: number = 0,
        prize31: number = 0,
        prize32: number = 0,
        prize33: number = 0,
        prize41: number = 0,
        prize42: number = 0,
        prize43: number = 0,
        playerCount1: number = 0,
        playerCount2: number = 0,
        playerCount3: number = 0,
        playerCount4: number = 0,
    ) {
        super(reqServerPeerId);
        this.totalRoundCount = totalRoundCount;
        this.prize11 = prize11;
        this.prize12 = prize12;
        this.prize13 = prize13;
        this.prize21 = prize21;
        this.prize22 = prize22;
        this.prize23 = prize23;
        this.prize31 = prize31;
        this.prize32 = prize32;
        this.prize33 = prize33;
        this.prize41 = prize41;
        this.prize42 = prize42;
        this.prize43 = prize43;
        this.playerCount1 = playerCount1;
        this.playerCount2 = playerCount2;
        this.playerCount3 = playerCount3;
        this.playerCount4 = playerCount4;
    }

    getTotalRoundCount(): number {
        return this.totalRoundCount;
    }

    setTotalRoundCount(totalRoundCount: number) {
        this.totalRoundCount = totalRoundCount;
    }
    getPrize11(): number {
        return this.prize11;
    }

    setPrize11(prize11: number) {
        this.prize11 = prize11;
    }
    getPrize12(): number {
        return this.prize12;
    }

    setPrize12(prize12: number) {
        this.prize12 = prize12;
    }
    getPrize13(): number {
        return this.prize13;
    }

    setPrize13(prize13: number) {
        this.prize13 = prize13;
    }
    getPrize21(): number {
        return this.prize21;
    }

    setPrize21(prize21: number) {
        this.prize21 = prize21;
    }
    getPrize22(): number {
        return this.prize22;
    }

    setPrize22(prize22: number) {
        this.prize22 = prize22;
    }
    getPrize23(): number {
        return this.prize23;
    }

    setPrize23(prize23: number) {
        this.prize23 = prize23;
    }
    getPrize31(): number {
        return this.prize31;
    }

    setPrize31(prize31: number) {
        this.prize31 = prize31;
    }
    getPrize32(): number {
        return this.prize32;
    }

    setPrize32(prize32: number) {
        this.prize32 = prize32;
    }
    getPrize33(): number {
        return this.prize33;
    }

    setPrize33(prize33: number) {
        this.prize33 = prize33;
    }
    getPrize41(): number {
        return this.prize41;
    }

    setPrize41(prize41: number) {
        this.prize41 = prize41;
    }
    getPrize42(): number {
        return this.prize42;
    }

    setPrize42(prize42: number) {
        this.prize42 = prize42;
    }
    getPrize43(): number {
        return this.prize43;
    }

    setPrize43(prize43: number) {
        this.prize43 = prize43;
    }
    getPlayerCount1(): number {
        return this.playerCount1;
    }

    setPlayerCount1(playerCount1: number) {
        this.playerCount1 = playerCount1;
    }
    getPlayerCount2(): number {
        return this.playerCount2;
    }

    setPlayerCount2(playerCount2: number) {
        this.playerCount2 = playerCount2;
    }
    getPlayerCount3(): number {
        return this.playerCount3;
    }

    setPlayerCount3(playerCount3: number) {
        this.playerCount3 = playerCount3;
    }
    getPlayerCount4(): number {
        return this.playerCount4;
    }

    setPlayerCount4(playerCount4: number) {
        this.playerCount4 = playerCount4;
    }
}
