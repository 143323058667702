import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

import { PPLEventCategoriesComponent } from './ppl-event-categories/ppl-event-categories.component';
import { PPLEventsComponent } from './ppl-events/ppl-events.component';

@Component({
    selector: 'ppl-event-widget',
    templateUrl: 'ppl-event-widget.component.html',
    standalone: true,
    imports: [CommonModule, PPLEventsComponent, PPLEventCategoriesComponent],
})
export class PPLEventWidgetComponent extends PCComponent implements OnInit {
    loadCategories: boolean;
    constructor() {
        super();
    }

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit(): void {
        if (this.item && this.item.hasOwnProperty('parameters') && this.item.parameters.hasOwnProperty('isCategory')) {
            this.loadCategories = this.item.parameters.isCategory === 'true' ? true : false;
        }
    }
}
