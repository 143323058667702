// export class NotificationCenter {
//   private static instance: NotificationCenter;
//   private observers: Map<string, Function[]> = new Map();
//
//   public static get Instance(): NotificationCenter {
//     if (!NotificationCenter.instance) {
//       NotificationCenter.instance = new NotificationCenter();
//     }
//     return NotificationCenter.instance;
//   }
//
//   public postNotificationName(notificationName: string, userInfo: any, classId: number, peerId: number): void {
//     const observerKey = `${notificationName}:${classId}:${peerId || 0}`;
//     const observerList = this.observers.get(observerKey);
//     if (observerList) {
//       observerList.forEach((method) => {
//         method(userInfo, classId, peerId);
//       });
//     }
//   }
//
//   public addObserver(method: Function, notificationName: string, classId: number, peerId: number): void {
//     const observerKey = `${notificationName}:${classId}:${peerId || 0}`;
//
//     if (!this.observers.has(observerKey)) {
//       this.observers.set(observerKey, []);
//     }
//
//     const observerList = this.observers.get(observerKey);
//     if (observerList) {
//       observerList.push(method);
//     }
//   }
//
//   public removeObserver(notificationName: string, classId: number, peerId: number): void {
//     const observerKey = `${notificationName}:${classId}:${peerId || 0}`;
//     this.observers.delete(observerKey);
//   }
// }

// import {Message} from "@portal/projects/pokercore/module/poker-web-client/pgConnectors/server/common/message";

// export class PGNotificationCenter {
//   private static instance: PGNotificationCenter;
//   private observers: Map<string, Function[]> = new Map();

//   public static get Instance(): PGNotificationCenter {
//     if (!PGNotificationCenter.instance) {
//       PGNotificationCenter.instance = new PGNotificationCenter();
//     }
//     return PGNotificationCenter.instance;
//   }

//   public postNotificationName(notificationName: string, message: Message, classId: number, peerId: number): void {
//     const observerKey = `${notificationName}:${classId}:${peerId}`;
//     const observerList = this.observers.get(observerKey);
//     console.log("observerKey: "+notificationName);
//     if (observerList) {
//       observerList.forEach((method) => {
//         method(message, classId, peerId);
//       });
//     }
//   }

//   public addObserver(observerKey: string, method: Function): void {
//     if (!this.observers.has(observerKey)) {
//       this.observers.set(observerKey, []);
//     }
//     const observerList = this.observers.get(observerKey);
//     if (observerList) {
//       observerList.push(method);
//     }
//   }

//   public removeObserver(observerKey: string): void {
//     this.observers.delete(observerKey);
//   }
// }

// export function MessageObserver(notificationName: string, classId: number, peerId: number = 0) {
//   return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
//     console.log(descriptor);
//     const originalMethod = descriptor.value;
//
//     const observerKey = `${notificationName}:${classId}:${peerId}`;
//     PGNotificationCenter.Instance.addObserver(observerKey, originalMethod);
//
//     descriptor.value = function (message: any) {
//       originalMethod.call(this, message, classId, peerId);
//     };
//   };
// }

export class PGNotificationCenter {
    static instance: PGNotificationCenter;
    private listeners: Map<string, Function> = new Map();

    public static getInstance(): PGNotificationCenter {
        if (!PGNotificationCenter.instance) {
            PGNotificationCenter.instance = new PGNotificationCenter();
        }
        return PGNotificationCenter.instance;
    }

    public addListener(key: string, fn: Function) {
        this.listeners.set(key, fn);
    }

    public messageEmitter(name: string, classId: number, message: Object) {
        const observerKey = `${name}`; //${classId}:${peerId}
        this.listeners.forEach((fn, key) => {
            if (key === observerKey) {
                //console.log(`Key: ${key}: fn called.`);
                fn(message);
            }
        });
    }
}
