import { Message } from '../../message';

export class GamingSessionTimedOutPopUp extends Message {
    private MESSAGE_NAME: string = 'GamingSessionTimedOutPopUp';
    private sessionTimeOutDurationInMins: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sessionTimeOutDurationInMins: number = 0) {
        super(reqServerPeerId);
        this.sessionTimeOutDurationInMins = sessionTimeOutDurationInMins;
    }

    getSessionTimeOutDurationInMins(): number {
        return this.sessionTimeOutDurationInMins;
    }

    setSessionTimeOutDurationInMins(sessionTimeOutDurationInMins: number) {
        this.sessionTimeOutDurationInMins = sessionTimeOutDurationInMins;
    }
}
