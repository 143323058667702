import { ChangeDetectorRef, Component, DoCheck, Input, OnChanges } from '@angular/core';

import { HandHistoryService } from '../../../../../hand-history.service';
import { Player } from '../../models/player';
import { Properties } from '../../utils/properties';
import { GameTableService } from '../game-table/game-table.service';

@Component({
    selector: 'pkr-player',
    templateUrl: './player.component.html',
})
export class PlayerComponent implements OnChanges, DoCheck {
    @Input() player: Player;
    initContent: any;
    index: any;
    allInText: any;
    amount: any;
    isMobile: any;
    content: any;
    maxSeats: any;

    constructor(
        public serve: HandHistoryService,
        public gameTableService: GameTableService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        this.cdr.detectChanges();
        this.isMobile = this.serve.isMobile;
    }

    ngDoCheck() {
        this.getStatus();
    }

    getStatus() {
        this.initContent = this.serve.content.messages[`${this.player.playerStatus}`];
        if (this.player.isPlayerAllIn) {
            this.allInText = this.serve.content.messages['AllIn'];
        }
        this.content = this.serve.content.messages;
        return this.initContent;
    }
    ngOnInit(): void {
        this.content = this.serve.content.messages;
        this.maxSeats = this.gameTableService.gameTable.maxSeats;
    }

    getHoleCardsSequence(index: number) {
        this.index = index;
        return Properties.getHoleCardsSequence(this.index);
    }

    getxaxis() {
        const value = 0;
        return `${value}px`;
    }

    formatChange() {
        this.formatCount(this.player.chips.value, true, 2, this.isMobile);
    }

    formatCount(count: any, withAbbr: boolean, decimals: number, isMobile: boolean) {
        let result: any;
        count = count / 100;
        if (isMobile && count > 999999) {
            const COUNT_ABBRS: any = ['', '', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
            const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
            result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
            if (withAbbr) {
                result += `${COUNT_ABBRS[i]}`;
            }
            this.amount = result;
        } else {
            this.amount = count;
            //this.amount = this.amount.toString();
            if (!this.player.isTourny) {
                this.amount = this.amount.toLocaleString(undefined, { minimumFractionDigits: 2 });
            }
            this.amount = this.amount.toString();
            this.amount = this.amount.replace(/\.00$/, '');
            this.amount = this.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}
