import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    ClientConfigProductName,
    ContentService,
    HeaderService,
    LoadingIndicatorService,
    PageMatrixService,
    PlainLinkComponent,
} from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { NavigationBarComponent, NavigationBarPokerComponent, PokerHomePageClientConfig, ScrollToTopComponent } from '@pokercore/module/core';
import { Subject, Subscription, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

/**
 * @whatItDoes Loads public page content based on url path.
 *
 * @howToUse
 *
 * ```
 * export const routes: Routes = [
 * // ...
 * {
 *     path: '{culture}',
 *     children: [
 *         // ...
 *         {
 *             path: 'p',
 *             children: [
 *                 {
 *                     path: '**',
 *                     component: PublicPageLoaderComponent,
 *                     data: {
 *                         publicPageRoot: 'Playground-v1.0/PublicPages/'
 *                     }
 *                 }
 *             ]
 *         },
 *         // ...
 *     ]
 * }
 * // ...
 * ]
 * ```
 *
 * @stable
 */
@Component({
    selector: 'pk-poker-public-page-loader',
    templateUrl: './poker-public-page.index.html',
    standalone: true,
    imports: [
        PageMatrixComponent,
        CommonModule,
        NavigationBarComponent,
        NavigationBarPokerComponent,
        ScrollToTopComponent,
        DslPipe,
        PlainLinkComponent,
    ],
})
export class PokerPublicPageLoaderComponent implements OnInit, OnDestroy {
    content: any;
    showConfigureNavigation: boolean = false;
    disableHtmlHeadTag: boolean = false;
    private unsubscribe = new Subject<void>();
    isPagematrixReady: boolean;
    pageMatrixServiceSub: Subscription;
    navItems: any = {};
    scriptName: string = '';
    @Input() publicPageRoute: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private contentService: ContentService,
        private loadingIndicatorService: LoadingIndicatorService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private headerService: HeaderService,
        private pageMatrixService: PageMatrixService,
    ) {}

    ngOnInit() {
        if (this.publicPageRoute === null || this.publicPageRoute === undefined) {
            this.publicPageRoute = this.activatedRoute.snapshot.data['publicPageRoot'];
        }

        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('poker');
        });

        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;

        this.activatedRoute.url.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
            const loadingIndicator = this.loadingIndicatorService.start();

            this.contentService
                .getJson(this.publicPageRoute + url.join('/'), { product: ClientConfigProductName.POKER, filterOnClient: true })
                .pipe(
                    catchError(() =>
                        this.contentService.getJson('App-v1.0/partials/notfound', { product: ClientConfigProductName.SF, filterOnClient: true }),
                    ),
                )
                .subscribe((data) => {
                    this.content = data;
                    this.loadScripts(data);
                    loadingIndicator.done();
                });
        });
        this.pageMatrixServiceSub = this.pageMatrixService.whenReady.subscribe(() => {
            this.isPagematrixReady = true;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unloadScript();
    }

    public loadScripts(content: any) {
        if (content !== null) {
            if (content.parameters && content.parameters['script-path']) {
                const cmsPathForScript = content.parameters['script-path'];
                this.scriptName = cmsPathForScript.split('/')[cmsPathForScript.split('/').length - 1];
                this.contentService
                    .getJson(cmsPathForScript, { product: ClientConfigProductName.POKER, filterOnClient: true })
                    //.pipe(catchError(this.handleError))
                    // .pipe(catchError((error) => {
                    //     // error handle code
                    //     console.log('Caught in CatchError. Throwing error :: ' + error)
                    //     throw new Error(error)
                    // }))
                    .subscribe((data) => {
                        // if (data.name == this.scriptName) {
                        if (data.name.toUpperCase() == this.scriptName.toUpperCase()) {
                            const node = document.createElement('script');
                            node.text = data.text;
                            node.id = data.name;
                            node.type = 'text/javascript';
                            node.async = true;
                            document.head.appendChild(node);
                        }
                    });
            } else {
                this.activatedRoute.url.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
                    if (content !== null) {
                        if (content.parameters && content.parameters['hasScript'] == 'true') {
                            let scriptname = url[url.length - 1] + '-script'; //e.g. publicPage folder

                            if (this.activatedRoute.snapshot.data['publicPageRoot']?.split('/').pop() == 'index') {
                                scriptname = 'index-script';
                            }
                            let cmsPathForScript = url.join('/') + '/' + scriptname;
                            if (url.length == 0 && scriptname != 'index-script') {
                                // like url = /en/cashback
                                scriptname = this.activatedRoute.snapshot.data['publicPageRoot'].split('/')[1] + '-script';
                                cmsPathForScript = scriptname;
                            }

                            // console.log(this.activatedRoute.snapshot.data['publicPageRoot'] + cmsPathForScript);
                            this.contentService
                                .getJson(this.activatedRoute.snapshot.data['publicPageRoot'] + cmsPathForScript, {
                                    product: ClientConfigProductName.POKER,
                                    filterOnClient: true,
                                })
                                .pipe(catchError(() => of([])))
                                .subscribe((data) => {
                                    if (data.name == scriptname) {
                                        const node = document.createElement('script');
                                        node.text = data.text;
                                        node.id = data.name;
                                        node.type = 'text/javascript';
                                        node.async = true;
                                        // node.charset = 'utf-8';

                                        document.getElementsByTagName('head')[0]?.appendChild(node);
                                    }
                                });
                        }
                    }
                });
            }
        }
    }

    public unloadScript() {
        let elem = document.getElementById(this.scriptName);
        if (elem) {
            elem.parentNode?.removeChild(elem);
        }
        if (this.scriptName == '') {
            this.activatedRoute.url.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
                elem = document.getElementById(url.join('-') + '-script');
                if (!elem) {
                    if (this.activatedRoute.snapshot.data['publicPageRoot']?.split('/').pop() == 'index') {
                        // console.log(document.getElementById('index-script'));
                        elem = document.getElementById('index-script');
                    }
                }
                if (url.length == 0 && !elem) {
                    // like url = /en/cashback
                    const scriptname = this.activatedRoute.snapshot.data['publicPageRoot'].split('/')[1] + '-script';
                    elem = document.getElementById(scriptname);
                }
                if (elem) {
                    elem.parentNode?.removeChild(elem);
                }
            });
        }
    }
}
