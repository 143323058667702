import { CommonGameProperties } from '../game-config/CommonGameProperties';

export class UserModel {
    userName;
    balance;
    currencyCode;
    languageId;
    accountName;
    locale;
    constructor() {}
    deductBalance = function (bet) {
        if (this.balance > bet) {
            this.balance -= bet;
        }
    };

    returnBalance = function (bet) {
        this.balance += bet;
    };

    // getters
    getBalance = function () {
        this.balance = CommonGameProperties.mathUtil['transform'](this.balance, this.currencyCode);
        return this.balance;
    };

    getBalanceInCents = function () {
        return this.balance;
    };

    // setters
    setBalance = function (val) {
        this.balance = val;
    };
    setCurrenyCode = function (val) {
        this.currencyCode = val;
    };
    getCurrenyCode = function () {
        return this.currencyCode;
    };
    getLanguageId = function () {
        return this.languageId;
    };
    getAccountName = function () {
        return this.accountName;
    };
    getLocale = function () {
        return this.locale;
    };
    resetDataModel = function () {};
}
