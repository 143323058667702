import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { PokerPromotionsSynopsisResourceService } from './poker-promotions-synopsis-resource.service';

@Injectable({ providedIn: 'root' })
export class PokerTheBigGameSynopsisResolver implements Resolve<any> {
    constructor(private resource: PokerPromotionsSynopsisResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getInitSynopsisData('thebiggameHome/home');
    }
}
