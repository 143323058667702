<div class="contentWrap grey">
    <div *ngIf="downloadSectionContent.title" class="pc-h text-center">
        <h1>{{ downloadSectionContent.title }}</h1>
        <div *ngIf="downloadSectionContent.subtitle" class="pc-h text-center">
            <h2>{{ downloadSectionContent.subtitle }}</h2>
        </div>
    </div>

    <div class="col d-flex justify-content-center">
        <div class="col-md-4 col-sm-4">
            <a class="btn btn-primary btn-block" (click)="onDownloadClick($event, downloadSectionContent)">
                {{ downloadSectionContent.titleLink.text }}
            </a>
        </div>
    </div>
</div>
