/* eslint-disable no-console */
import { Logout } from '../server/common/connectorMessageFactory/messages/Logout';
import { CSD } from './CSD';
import { ByteBuffer } from './Lib/byteBuffer';
import { Base64Codec } from './Lib/toFromBase64';
import { Timer } from './common/timer';
import { ConnectionHandlerContext } from './connectionHandlerContext';
import { ConnectionManager } from './connectionManager';
import { ConvertSocketUrl } from './convertSocketUrl';
import { HealthMonitor } from './healthMonitor';

export class ConnectionHandler {
    static CONNECTION_VERY_GOOD = 1;
    static CONNECTION_GOOD = 2;
    static CONNECTION_BAD = 3;
    static NO_CONNECTION = -1;
    static SHOW_RECONNECTION_POPUP = 4;
    public connectionManager: ConnectionManager;
    idleTime: number = 0;
    // private currentStatus: number = ConnectionHandler.CONNECTION_GOOD;
    public veryGoodConnection: number = 0;
    public goodConnection: number = 1;
    public badConnection: number = 5;
    public isBrowserInternetConnectionActive: boolean = true;
    private isConnectionReleased: boolean = false;
    private connId: number;
    public domain: number = -1;
    public context: ConnectionHandlerContext;
    // private pingSentAt: number;
    private pinger: any;
    private myServerHost: string | null = null;
    private mySocket: WebSocket | null = null;
    private connected: boolean = false;
    private initialConnectionDone: boolean = false;
    // private connectionTimeout: number = 15000;
    public firstConnectionFailed: boolean = false;
    // private notifyOnHandshake: boolean;
    // private inNetworkDisconnectState: boolean = false;
    private buffer: ByteBuffer | null = null;
    private reConnectionAttempt: number = 0;
    reconnecting: boolean = false;
    protected disconnectionHandshakeResetTimer: Timer;
    protected m_ReconnectSocketTimer: Timer;

    constructor(manager: ConnectionManager, serverHost: string | null, connId: number, domain: number, ctx: ConnectionHandlerContext) {
        this.context = ctx;
        this.connId = connId;
        this.domain = domain;
        // this.msgsToSend = new MessagesContainerQueue();
        this.myServerHost = serverHost; //this.getIPForHost(serverHost, manager.doNotResolveIPForHost)
        // this.myServerPort = serverPort;
        // this.alternatePort = alternatePort;
        // this.webPort = webPort;
        this.connectionManager = manager;
        // this.connectionTimeout = connectionTimeOut;
        const myServerHost = this.myServerHost;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        if (!myServerHost || !this.connect(myServerHost)) {
            this.connectionManager.delegate.log(CSD.LOG_ERROR, `Connection Failed For Domain ${this.domain}`);
            throw new Error('Connection not available');
        }
        window.addEventListener('online', this.handleConnectionChange.bind(this));
        window.addEventListener('offline', this.handleConnectionChange.bind(this));
        this.goodConnection = ConnectionManager.getGoodConnection();
        this.badConnection = ConnectionManager.getBadConnection();
        this.veryGoodConnection = ConnectionManager.getVeryGoodConnection();

        // Timers
        this.disconnectionHandshakeResetTimer = new Timer(120 * 1000, this.refreshHandShake.bind(this));
        // var m_ReconnectSocketTimer = new Timer(5 * 1000, handleReconnectSocketTimer);
    }

    getHost() {
        return this.myServerHost;
    }

    async connect(myServerHost: string): Promise<boolean> {
        if (this.connected) {
            return true;
        }

        let reconnectTimeout: NodeJS.Timeout | null = null; // Track reconnection timeout

        const connectWebSocket = async () => {
            try {
                this.mySocket = new WebSocket(new ConvertSocketUrl().WSURL(myServerHost));
                if (!this.connectionManager.getisFallbackToweb2tcp()) {
                    this.mySocket.binaryType = 'arraybuffer';
                }
                await new Promise<void>((resolve) => {
                    this.mySocket!.onopen = () => {
                        this.connected = true;
                        this.initialConnectionDone = true;

                        this.connectionManager.doHandshake(this.connectionManager.isDefault(this.domain), this.connId, this.domain);
                        console.log('SOCKET STATUS :::::::::::: CONNECTED', `FOR DOMAIN  ${this.domain}`);
                        this.connectionManager.delegate.log(CSD.LOG_INFO, `CONNECTED FOR DOMAIN ${this.domain}`);

                        // Clear reconnection timeout if present
                        if (reconnectTimeout) {
                            clearTimeout(reconnectTimeout);
                            reconnectTimeout = null;
                        }
                        resolve();
                        this.reConnectionAttempt = 0;
                        this.reconnecting = false;
                    };

                    this.mySocket!.onclose = () => {
                        if (!this.initialConnectionDone) {
                            this.firstConnectionFailed = true;
                        }
                        // if (!this.isBrowserInternetConnectionActive) {
                        //     // this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.NO_CONNECTION);
                        // }
                        this.connectionManager.delegate.log(CSD.LOG_ERROR, `SOCKET CLOSED FOR DOMAIN ${this.domain}`);
                        if (this.getPinger()) {
                            this.getPinger().stopPinger();
                        }
                        // if (!event.wasClean) {
                        // Attempt reconnection
                        if (!this.connectionManager.duplicateLogin && this.isBrowserInternetConnectionActive) {
                            reconnect();
                        }
                        // this.disconnectionHandshakeResetTimer.Start();
                        // }
                    };

                    this.mySocket!.onmessage = this.onMessage.bind(this);
                });
            } catch (error) {
                if (!this.initialConnectionDone) {
                    this.firstConnectionFailed = true;
                }
                this.connectionManager.delegate.log(CSD.LOG_ERROR, `CAN NOT OPEN SOCKET FOR DOMAIN ${this.domain}`);
                // console.error('CAN NOT OPEN SOCKET');

                // Attempt reconnection
                if (!this.connectionManager.duplicateLogin && this.isBrowserInternetConnectionActive) {
                    reconnect();
                }
            }
        };

        const reconnect = () => {
            this.reConnectionAttempt++;
            if (reconnectTimeout) {
                clearTimeout(reconnectTimeout);
            }

            if (this.reConnectionAttempt! < 4 && this.isBrowserInternetConnectionActive) {
                // Set reconnection timeout
                this.reconnecting = true;
                this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.CONNECTION_BAD);
                console.log(this.reConnectionAttempt);
                // reconnectTimeout = setTimeout(() => {
                //     console.log('Attempting to reconnect...');
                //     connectWebSocket().catch((err) => {
                //         throw new Error('Reconnection attempt failed:' + err);
                //     });
                // }, 10000);
            } else {
                console.log(this.reConnectionAttempt + 'RECONNECT STOPPED');
                this.reconnecting = false;
                if (this.isBrowserInternetConnectionActive) {
                    // this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.CONNECTION_BAD);
                    this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.SHOW_RECONNECTION_POPUP);
                } else {
                    this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.NO_CONNECTION);
                }
            }
        };
        await connectWebSocket();
        return this.connected;
    }

    onMessage(msg) {
        this.idleTime = 0;
        if (msg.data === 'STATUS:CONNECTED') {
            // console.log(msg.data);
            return;
        }
        const byteBuffer = new ByteBuffer();
        if (this.connectionManager.getisFallbackToweb2tcp()) {
            const fromBase64 = Base64Codec.fromBase64(msg.data);
            byteBuffer.putBytes(fromBase64);
        } else {
            const rawData = new Uint8Array(msg.data);
            byteBuffer.putBytes(rawData);
        }
        byteBuffer.flip();
        this.connectionManager.receiveMessage(this.bufferSocketData(byteBuffer), this.domain, this.connId);
    }

    sendMessage(framesBytes: Uint8Array | string) {
        if (this.mySocket) this.mySocket.send(framesBytes);
    }

    bufferSocketData(buffer: ByteBuffer): ByteBuffer {
        if (this.buffer === null) {
            this.buffer = buffer;
            return this.buffer;
        }
        const x = this.buffer.array.slice(this.buffer.position, this.buffer.remaining());
        const newDataArray = buffer.array;
        const combinedArray = [...x, ...newDataArray];
        const newBuffer = new ByteBuffer(combinedArray);
        newBuffer.position = 0;
        this.buffer.dispose();
        this.buffer = newBuffer;
        return this.buffer;
    }

    reConnect() {
        if (this.reConnectionAttempt < 3 && this.isBrowserInternetConnectionActive) {
            this.connected = false;
            if (this.mySocket)
                if (this.mySocket.readyState === 1) {
                    this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.CONNECTION_BAD);
                    this.closeConnection();
                    console.log('RECONNECTION STARTED');
                    this.connectionManager.delegate.log(CSD.LOG_INFO, `RECONNECTION STARTED FOR DOMAIN ${this.domain}`);
                    if (this.myServerHost) this.connect(this.myServerHost);
                }
        } else {
            if (this.isBrowserInternetConnectionActive) {
                this.connectionManager.delegate.log(
                    CSD.LOG_ERROR,
                    `CLOSING SOCKET CONNECTED TO DOMAIN ${this.domain} AS THERE IS NO RESPONE FROM SERVER`,
                );
                // console.log('Closing Socket as there is No response!! ');
                this.closeConnection();
            } else if (!this.isBrowserInternetConnectionActive) {
                this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.NO_CONNECTION);
            }
        }
    }

    refreshHandShake() {
        this.disconnectionHandshakeResetTimer.Stop();
        this.connectionManager.disconnectionForMoreThan2Minutes = true;
        this.connectionManager.refreshHandShake();
    }

    closeConnection() {
        if (this.getPinger()) {
            this.getPinger().stopPinger();
        }
        if (this.mySocket) this.mySocket.close();
    }

    handleConnectionChange() {
        if (navigator.onLine) {
            console.log('Online');
            if (this.isConnectionReleased) {
                this.isConnectionReleased = false;
                // this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.CONNECTION_BAD);
                // this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.SHOW_RECONNECTION_POPUP);
            }
            this.isBrowserInternetConnectionActive = true;
        } else {
            console.log('Offline');
            this.closeConnection();
            this.isBrowserInternetConnectionActive = false;
            this.isConnectionReleased = true;
            this.connectionManager.delegate.updateConnectionStatus(this.domain, ConnectionHandler.NO_CONNECTION);
        }
    }

    doLogOut() {
        this.connectionManager.sendMessageOnDomain(new Logout(this.connectionManager.getGameClientPeerId()), this.domain);
        this.closeConnection();
    }

    // setDomain(domain) {}

    // setConnId(connId) {}

    isConnected(): boolean {
        return this.connected;
    }

    setPinger(pinger: HealthMonitor) {
        this.pinger = pinger;
    }

    getPinger(): HealthMonitor {
        return this.pinger;
    }
}
