import { Message } from '../../message';
import { PlayerBalanceInfo } from './PlayerBalanceInfo';

export class FRONTALPoolRebuyResponse extends Message {
    private MESSAGE_NAME: string = 'FRONTALPoolRebuyResponse';
    private entryId: number = 0;
    private balanceInfo: PlayerBalanceInfo | null = null;
    private minBuyIn: number = 0;
    private maxBuyIn: number = 0;
    private responseId: number = 0;
    private time: number = 0;
    private conversationId: number = 0;
    private poolName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        entryId: number = 0,
        balanceInfo: PlayerBalanceInfo | null = null,
        minBuyIn: number = 0,
        maxBuyIn: number = 0,
        responseId: number = 0,
        time: number = 0,
        conversationId: number = 0,
        poolName: string | null = null,
    ) {
        super(reqServerPeerId);
        this.entryId = entryId;
        this.balanceInfo = balanceInfo;
        this.minBuyIn = minBuyIn;
        this.maxBuyIn = maxBuyIn;
        this.responseId = responseId;
        this.time = time;
        this.conversationId = conversationId;
        this.poolName = poolName;
    }

    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getBalanceInfo(): PlayerBalanceInfo | null {
        return this.balanceInfo;
    }

    setBalanceInfo(balanceInfo: PlayerBalanceInfo | null) {
        this.balanceInfo = balanceInfo;
    }
    getMinBuyIn(): number {
        return this.minBuyIn;
    }

    setMinBuyIn(minBuyIn: number) {
        this.minBuyIn = minBuyIn;
    }
    getMaxBuyIn(): number {
        return this.maxBuyIn;
    }

    setMaxBuyIn(maxBuyIn: number) {
        this.maxBuyIn = maxBuyIn;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getTime(): number {
        return this.time;
    }

    setTime(time: number) {
        this.time = time;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getPoolName(): string | null {
        return this.poolName;
    }

    setPoolName(poolName: string | null) {
        this.poolName = poolName;
    }
}
