import { providePageMatrix } from './page-matrix/page-matrix.feature';
import { providePPLEventConfig } from './partypoker-live/ppl-event-config/ppl-event-config.feature';
import { providePPLEventSchedule } from './partypoker-live/ppl-event-schedule/ppl-event-schedule.feature';
import { providePPLEventWidget } from './partypoker-live/ppl-event-widget/ppl-event-widget.feature';
import { providePPLGalleryConfig } from './partypoker-live/ppl-gallery-config/ppl-gallery-config.feature';
import { providePPLGalleryContainer } from './partypoker-live/ppl-gallery-container/ppl-gallery-container.feature';
import { providePPLScheduleConfig } from './partypoker-live/ppl-schedule-config/ppl-schedule-config.feature';
import { providePokerAlphaDownloadClient } from './poker-alpha-download-client/poker-alpha-download-client.feature';
import { providePokerCountdown } from './poker-countdown-component/poker-countdown.feature';
import { providePokerCustomTemplates } from './poker-custom-templates/poker-custom-templates.feature';
import { providePokerFlickrFeed } from './poker-flickr-feed/poker-flickr-feed.feature';
import { providePokerInstagramFeed } from './poker-instagram-feed/poker-instagram-feed.feature';
import { providePokerLeaderboardFeed } from './poker-leaderboard-feed/poker-leaderboard-feed.feature';
import { providePokerMyGame } from './poker-my-game/poker-my-game.feature';
import { providePokerScheduleFeed } from './poker-schedule-feed/poker-schedule-feed.feature';
import { providePokerTournamentWidgets } from './poker-tournament-widgets/poker-tournament-widgets.feature';
import { providePokerTwicthFeed } from './poker-twitch-feed/poker-twitch-feed.feature';
import { providePokerTwitterFeed } from './poker-twitter-feed/poker-twitter-feed.feature';
import { providePokerUpcomingTournament } from './poker-upcoming-tournaments/poker-upcoming-tournaments.feature';
import { providePokerYoutubeFeed } from './poker-youtube-feed/poker-youtube-feed.feature';
import { providePPBlogFeed } from './pp-blog-feed/pp-blog-feed.feature';
import { providePPCashierOptions } from './pp-cashier-options/pp-cashier-options.feature';
import { providePPModalContainer } from './pp-modal-container/pp-modal-container.feature';
import { providePPPromoHeader } from './pp-promo-header/pp-promo-header.feature';
import { providePPRibbonContainer } from './pp-ribbon-container/pp-ribbon-container.feature';
import { providePPTeaserWidget } from './pp-teaser-widget/pp-teaser-widget.feature';
import { providePublicPageBreadcrumb } from './public-page-breadcrumbs/public-page-breadcrumbs.feature';
import { provideSimulatedHandsFeed } from './simulated-hands/simulated-hands.feature';

// import { providePokerDiscountTimer } from './poker-discount-timer/poker-discount-timer.feature';
// import { providePokerSinglePromoTimer } from './poker-single-promo-timer/poker-single-promo-timer.feature';

export function providePokerSrc() {
    return provideSimulatedHandsFeed().concat(
        providePublicPageBreadcrumb()
            .concat(providePPBlogFeed())
            .concat(providePPTeaserWidget())
            .concat(providePPCashierOptions())
            .concat(providePokerCountdown())
            .concat(providePPPromoHeader())
            .concat(providePPModalContainer())
            .concat(providePokerUpcomingTournament())
            .concat(providePokerCustomTemplates())
            .concat(providePokerMyGame())
            .concat(providePokerYoutubeFeed())
            .concat(providePageMatrix())
            .concat(providePokerScheduleFeed())
            .concat(providePokerTwitterFeed())
            .concat(providePokerFlickrFeed())
            .concat(providePokerAlphaDownloadClient())
            .concat(providePPRibbonContainer())
            .concat(providePokerTwicthFeed())
            .concat(providePokerTournamentWidgets())
            .concat(providePokerInstagramFeed())
            .concat(providePokerLeaderboardFeed())
            .concat(providePPLEventWidget())
            .concat(providePPLGalleryConfig())
            .concat(providePPLEventConfig())
            .concat(providePPLEventSchedule())
            .concat(providePPLGalleryContainer())
            .concat(providePPLScheduleConfig()),
    );
}
