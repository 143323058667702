import { Message } from '../../message';
import { UserProfile } from '../../pgSharedMessageFactory/messages/UserProfile';

export class Registration extends Message {
    private MESSAGE_NAME: string = 'Registration';
    private profile: UserProfile | null = null;
    private password: string | null = null;
    private isEmailOptin: boolean | null = false;
    private isFirstRequest: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        profile: UserProfile | null = null,
        password: string | null = null,
        isEmailOptin: boolean | null = false,
        isFirstRequest: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.profile = profile;
        this.password = password;
        this.isEmailOptin = isEmailOptin;
        this.isFirstRequest = isFirstRequest;
    }

    getProfile(): UserProfile | null {
        return this.profile;
    }

    setProfile(profile: UserProfile | null) {
        this.profile = profile;
    }
    getPassword(): string | null {
        return this.password;
    }

    setPassword(password: string | null) {
        this.password = password;
    }
    getIsEmailOptin(): boolean | null {
        return this.isEmailOptin;
    }

    setIsEmailOptin(isEmailOptin: boolean | null) {
        this.isEmailOptin = isEmailOptin;
    }
    getIsFirstRequest(): boolean | null {
        return this.isFirstRequest;
    }

    setIsFirstRequest(isFirstRequest: boolean | null) {
        this.isFirstRequest = isFirstRequest;
    }
}
