import { Message } from '../../message';

export class ResponseFoldNShowCards extends Message {
    private MESSAGE_NAME: string = 'ResponseFoldNShowCards';
    private holeCards: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, holeCards: any[] | null = null) {
        super(reqServerPeerId);
        this.holeCards = holeCards;
    }

    getHoleCards(): any[] | null {
        return this.holeCards;
    }

    setHoleCards(holeCards: any[] | null) {
        this.holeCards = holeCards;
    }
}
