import { Message } from '../../message';

export class RequestMTCTLobbyTablesOnScreenName extends Message {
    private MESSAGE_NAME: string = 'RequestMTCTLobbyTablesOnScreenName';
    private mtctId: number = 0;
    private screenName: string | null = null;
    private tournamentCategory: number = 0;
    private keepPlayerSubscription: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        screenName: string | null = null,
        tournamentCategory: number = 0,
        keepPlayerSubscription: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.screenName = screenName;
        this.tournamentCategory = tournamentCategory;
        this.keepPlayerSubscription = keepPlayerSubscription;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
    getKeepPlayerSubscription(): boolean | null {
        return this.keepPlayerSubscription;
    }

    setKeepPlayerSubscription(keepPlayerSubscription: boolean | null) {
        this.keepPlayerSubscription = keepPlayerSubscription;
    }
}
