import { Routes } from '@angular/router';

import { PokerWorldCupOfCardsSynopsisResolver } from './poker-world-cup-of-cards-synopsis.resolver';
import { PokerWorldCupOfCardsResolver } from './poker-world-cup-of-cards.resolver';

export const pokerWorldCupOfCardsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWorldCupOfCardsResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerWorldCupOfCardsSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWorldCupOfCardsResolver,
        },
    },
];
