{
    "common": {
        "primaryAssets": {
            "gameAssets": {
                "spritesheets": [
                    "logoAssets.json",
                    "Playercards_style2.json",
                    "Playercards_style3.json",
                    "Communitycards_style2.json",
                    "Communitycards_style3.json",
                    "timeBankOtherPlayerAni.json",
                    "TimeBankAboutToActivateAni.json",
                    "TimeBankActivatedAni.json",
                    "tablebackground.json",
                    "AllInGlowAnimation0.json",
                    "AllInGlowAnimation1.json",
                    "PlayerWinGlowAni1.json",
                    "PlayerWinGlowAni0.json",
                    "playerIcon_assets.json",
                    "PlayerDefault.json",
                    "betSliderAssets.json",
                    "menuBarAssets.json",
                    "playerAssets.json",
                    "gameAssets0.json",
                    "Playercards_style0.json",
                    "Playercards_style1.json",
                    "Communitycards_style0.json",
                    "Communitycards_style1.json",
                    "backCards.json",
                    "info_assets.json",
                    "bottomPanelAssets.json",
                    "userProfileAssets.json",
                    "timerAsset0.json",
                    "timerAsset1.json",
                    "timerAsset2.json",
                    "timerAsset3.json",
                    "timerAsset4.json",
                    "timerAsset5.json",
                    "popUp.json",
                    "YouWinAni.json",
                    "winnerAssets.json",
                    "tablebackground0.json",
                    "tablebackground1.json",
                    "tablebackground2.json",
                    "tablebackground3.json",
                    "tablebackground4.json",
                    "RabitAnimationSelf.json",
                    "RabitAnimationOpponent.json",
                    "ModalPopUpAssets.json",
                    "spotLights0.json",
                    "spotLights1.json",
                    "TilePopUpAssets.json",
                    "loader.json",
                    "emptytableBG.json",
                    "sandTimerAsset0.json",
                    "sandTimerAsset1.json",
                    "sandTimerAsset2.json",
                    "sandTimerAsset3.json",
                    "sandTimerAsset4.json",
                    "sandTimerAsset5.json",
                    "ffBgAssets.json",
                    "ultraAnim.json",
                    "shortDeck0.json",
                    "bubblechat_assets.json",
                    "cashoutEquityAnimAssets.json",
                    "cashbackLoyalityAssets.json",
                    "lockAnimAssets.json",
                    "antiTableHoppingAssets.json",
                    "expoSpinBGZoomOut_2.json",
                    "expoSpinBGZoomIn_2.json",
                    "expoSpinLogo.json",
                    "expoSpinPrizePool.json",
                    "expoSpinBGZoomOut_1.json",
                    "expoSpinBGZoomOut_3.json",
                    "expoSpinBGZoomOut_4.json",
                    "expoSpinBGZoomIn_1.json",
                    "expoSpinBGZoomIn_3.json",
                    "expoSpinBGZoomIn_4.json",
                    "expoSpinsAssets.json",
                    "pDollarPnlNspinsUltra.json",
                    "ratholeAssets.json",
                    "ratHoleProgAsset.json"
                ],
                "langSpritesheets": {
                    "langAssets": "langAssets.json",
                    "Cashout_langAssets": "Cashout_langAssets.json"
                },
                "sounds": {},
                "fonts": [
                    {
                        "name": "Impact",
                        "file": "impact.ttf"
                    },
                    {
                        "name": "Tahoma",
                        "file": "tahoma.ttf"
                    },
                    {
                        "name": "Roboto",
                        "file": "Roboto-Regular-Game.ttf"
                    },
                    {
                        "name": "Roboto-BlackItalic",
                        "file": "Roboto-BlackItalic.ttf"
                    },
                    {
                        "name": "Roboto-Italic",
                        "file": "Roboto-Italic.ttf"
                    },
                    {
                        "name": "RobotoCondensed-Regular",
                        "file": "RobotoCondensed-Regular.ttf"
                    },
                    {
                        "name": "Antonio-Regular",
                        "file": "Antonio-Regular.ttf"
                    },
                    {
                        "name": "FjallaOne-Regular",
                        "file": "FjallaOne-Regular.ttf"
                    }
                ]
            }
        },
        "secondaryAssets": {
            "gameAssets": {
                "spritesheets": [
                    "boxingThrowAssetsAni.json",
                    "eggThrowAssetsAni.json",
                    "goatThrowAssetsAni.json",
                    "kissThrowAssetsAni.json",
                    "pigeonThrowAssetsAni.json",
                    "targetThrowAssetsAni.json",
                    "taxiThrowAssetsAni.json",
                    "theifThrowAssetsAni.json",
                    "waveThrowAssetsAni.json",
                    "staticThrowAsset.json",
                    "cigerAni.json",
                    "fishingAni.json",
                    "throwEmojiAssets.json",
                    "champagneAni.json",
                    "throwMudAni.json",
                    "throwAnimation.json",
                    "emoji1001.json",
                    "emoji1002.json",
                    "emoji1003.json",
                    "emoji1004.json",
                    "emoji1005.json",
                    "emoji1006.json",
                    "emoji1007.json",
                    "emoji1008.json",
                    "emoji1009.json",
                    "emoji1010.json",
                    "emoji1012.json",
                    "emoji1013.json",
                    "emoji1014.json",
                    "emoji1015.json",
                    "emoji1016.json",
                    "emoji1017.json",
                    "emoji1018.json",
                    "emoji1019.json",
                    "emoji1020.json",
                    "emoji1021.json",
                    "emoji1022.json",
                    "emoji1023.json",
                    "selfEmojiAssets.json",
                    "smiley.json",
                    "chat_bubble_2.json"
                ],
                "langSpritesheets": {},
                "sounds": {
                    "throwSound": "GenericThrow.mp3",
                    "throwAni1": "Anim_Fishing.mp3",
                    "throwAni2": "Throw_Mud.mp3",
                    "throwAni3": "Anim_Pigeon.mp3",
                    "throwAni4": "Anim_Kiss.mp3",
                    "throwAni5": "Anim_Target.mp3",
                    "throwAni6": "Anim_Boxing.mp3",
                    "throwAni7": "Throw_Egg.mp3",
                    "throwAni8": "Anim_Cigar.mp3",
                    "throwAni9": "Anim_Thief.mp3",
                    "throwAni10": "Anim_Wave.mp3",
                    "throwAni11": "Anim_Taxi.mp3",
                    "throwAni12": "Anim_Champagne.mp3",
                    "throwAni13": "Anim_Goat.mp3",
                    "Alert": "alert1.mp3",
                    "Bet": "bet_new.mp3",
                    "Call": "call_new.mp3",
                    "Card": "card_new.mp3",
                    "Check": "check_new.mp3",
                    "Fold": "fold_new.mp3",
                    "Hurry": "hurry_new.mp3",
                    "Raise": "raise_new.mp3",
                    "Win": "win_new.mp3",
                    "Moving_Chips": "chips_moving.mp3",
                    "Options": "action.mp3",
                    "optionSetNotification": "click_alert.mp3",
                    "cashback_prize_reveal": "cashback_prize_reveal.mp3",
                    "cashback_safe_open": "cashback_safe_open.mp3",
                    "cashback_safe_unlock": "cashback_safe_unlock.mp3",
                    "cashout_player_sounds": "cashout_player_sounds.mp3",
                    "expo_MusicIntro_V1": "expo_MusicIntro_V1.mp3",
                    "expo_CarStart_V1": "expo_CarStart_V1.mp3",
                    "expo_Checkpoint_V1": "expo_Checkpoint_V1.mp3",
                    "expo_FinalBlast_V1": "expo_FinalBlast_V1.mp3",
                    "expo_InitialCountdown_V1": "expo_InitialCountdown_V1.mp3",
                    "expo_CarLoop_V1": "expo_CarLoop_V1.mp3",
                    "expo_MusicLoop_V1": "expo_MusicLoop_V1.mp3"
                },
                "fonts": []
            }
        }
    },
    "desktop": {
        "primaryAssets": {
            "gameAssets": {}
        }
    },
    "mobile": {
        "primaryAssets": {
            "gameAssets": {}
        }
    },
    "loadByDynacon": {
        "spins": {
            "primary": {
                "spritesheets": [
                    "spinGlowAni0.json",
                    "spinGlowAni1.json",
                    "spinGlowAni2.json",
                    "spinGlowAni3.json",
                    "spinGlowAni4.json",
                    "spinGlowAni5.json",
                    "spinGlowAni6.json",
                    "spinGlowAni7.json",
                    "SpinTimeBulbAni.json",
                    "spinNumbers.json",
                    "liverBobHandAni.json",
                    "spinsBgAssets.json"
                ]
            },
            "secondary": {
                "sounds": {
                    "spinMachineLanding": "audio-1.mp3",
                    "letsSpinSound": "audio-2.mp3",
                    "liverSound": "audio-3.mp3",
                    "reelSpinSound": "audio-4.mp3",
                    "spinGlowSound": "audio-5.mp3",
                    "spinMachineRemove": "audio-6.mp3"
                }
            }
        }
    },
    "gameSound": {
        "sounds": [
            {
                "name": "Alert",
                "file": "alert1",
                "loop": false
            },
            {
                "name": "Bet",
                "file": "bet_new",
                "loop": false
            },
            {
                "name": "Call",
                "file": "call_new",
                "loop": false
            },
            {
                "name": "Card",
                "file": "card_new",
                "loop": false
            },
            {
                "name": "Check",
                "file": "check_new",
                "loop": false
            },
            {
                "name": "Fold",
                "file": "fold_new",
                "loop": false
            },
            {
                "name": "Hurry",
                "file": "hurry_new",
                "loop": false
            },
            {
                "name": "Raise",
                "file": "raise_new",
                "loop": false
            },
            {
                "name": "Win",
                "file": "win_new",
                "loop": false
            },
            {
                "name": "Moving_Chips",
                "file": "chips_moving",
                "loop": false
            },
            {
                "name": "Options",
                "file": "action",
                "loop": false
            },
            {
                "name": "throwSound",
                "file": "GenericThrow",
                "loop": false
            },
            {
                "name": "throwAni1",
                "file": "Anim_Fishing",
                "loop": false
            },
            {
                "name": "throwAni2",
                "file": "Throw_Mud",
                "loop": false
            },
            {
                "name": "throwAni3",
                "file": "Anim_Pigeon",
                "loop": false
            },
            {
                "name": "throwAni4",
                "file": "Anim_Kiss",
                "loop": false
            },

            {
                "name": "throwAni5",
                "file": "Anim_Target",
                "loop": false
            },
            {
                "name": "throwAni6",
                "file": "Anim_Boxing",
                "loop": false
            },
            {
                "name": "throwAni7",
                "file": "Throw_Egg",
                "loop": false
            },
            {
                "name": "throwAni8",
                "file": "Anim_Cigar",
                "loop": false
            },
            {
                "name": "throwAni9",
                "file": "Anim_Thief",
                "loop": false
            },
            {
                "name": "throwAni10",
                "file": "Anim_Wave",
                "loop": false
            },
            {
                "name": "throwAni11",
                "file": "Anim_Taxi",
                "loop": false
            },
            {
                "name": "throwAni12",
                "file": "Anim_Champagne",
                "loop": false
            },
            {
                "name": "throwAni13",
                "file": "Anim_Goat",
                "loop": false
            },

            {
                "name": "spinMachineLanding",
                "file": "audio-1",
                "loop": false
            },
            {
                "name": "letsSpinSound",
                "file": "audio-2",
                "loop": false
            },
            {
                "name": "liverSound",
                "file": "audio-3",
                "loop": false
            },
            {
                "name": "reelSpinSound",
                "file": "audio-4",
                "loop": false
            },
            {
                "name": "spinGlowSound",
                "file": "audio-5",
                "loop": false
            },
            {
                "name": "spinMachineRemove",
                "file": "audio-6",
                "loop": false
            },
            {
                "name": "optionSetNotification",
                "file": "click_alert",
                "loop": false
            },
            {
                "name": "cashback_prize_reveal",
                "file": "cashback_prize_reveal",
                "loop": false
            },
            {
                "name": "cashback_safe_open",
                "file": "cashback_safe_open",
                "loop": false
            },
            {
                "name": "cashback_safe_unlock",
                "file": "cashback_safe_unlock",
                "loop": false
            },
            {
                "name": "cashout_player_sounds",
                "file": "cashout_player_sounds",
                "loop": false
            },
            {
                "name": "expo_MusicIntro_V1",
                "file": "expo_MusicIntro_V1",
                "loop": false
            },
            {
                "name": "expo_CarStart_V1",
                "file": "expo_CarStart_V1",
                "loop": false
            },
            {
                "name": "expo_Checkpoint_V1",
                "file": "expo_Checkpoint_V1",
                "loop": false
            },
            {
                "name": "expo_FinalBlast_V1",
                "file": "expo_FinalBlast_V1",
                "loop": false
            },
            {
                "name": "expo_InitialCountdown_V1",
                "file": "expo_InitialCountdown_V1",
                "loop": false
            },
            {
                "name": "expo_CarLoop_V1",
                "file": "expo_CarLoop_V1",
                "loop": true
            },
            {
                "name": "expo_MusicLoop_V1",
                "file": "expo_MusicLoop_V1",
                "loop": true
            }
        ]
    }
}
