<div id="schedule" class="pt-3 pb-3">
    <div class="schedule-tournament-main">
        <div class="filters-wrap">
            <!-- Filters -->
            <poker-schedule-feed-filters
                *ngIf="showFilters"
                [filterStatus]="filterStatus"
                (emitReset)="getEmitReset($event)"></poker-schedule-feed-filters>

            <div class="row justify-content-between align-items-center pb-md-3 pb-1 pt-3">
                <div class="col-12 col-md-6">
                    <div class="mat-table-filter-wrap">
                        <input
                            type="text"
                            (ngModelChange)="applySearchFilter($event)"
                            [(ngModel)]="searchFilterValue"
                            class="form-filter-control text-capitalize"
                            [placeholder]="configStrings.search" />
                        <button class="btn btn-sm clear-btn btn-circle bg-light" *ngIf="searchFilterValue" (click)="clearSearchInput()">
                            <span class="theme-ex text-dark"></span>
                        </button>
                    </div>
                </div>
                <div class="col-4 text-right" *ngIf="showFilters">
                    <button
                        class="btn btn-sm filters-toggle-btn d-none d-lg-inline-block"
                        [ngClass]="filterStatus ? 'btn-primary' : 'btn-light'"
                        (click)="toggleFilter()">
                        {{ filterStatus ? configStrings.turnOffFilerts : configStrings.turnOnFilerts || 'Filters' }} <i class="theme-filters"></i>
                    </button>
                </div>
            </div>
        </div>

        <!-- Table  -->
        <div class="mat-table-theme table-mobile-view">
            <div class="table-responsive">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    class="table table-layout-auto table-bordered table-striped text-center mb-4 schedule-mat-table">
                    <!-- Date Column -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef class="text-center">{{ tableHeadings.date || 'Start Time (CET)' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left date-column-cell">
                            {{ dateTimeCEST(element?.startDate) }}
                            <span class="label-mobile-inline">{{ tableHeadings.date || 'Start Time (CET)' }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startDateBst">
                        <th mat-header-cell *matHeaderCellDef class="text-center">{{ tableHeadings.dateBST || 'Start Time (GMT)' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left date-column-cell">
                            {{ dateTimeCEST(element?.startDate) }}
                            <span class="label-mobile-inline">{{ tableHeadings.dateBST || 'Start Time (GMT)' }}</span>
                        </td>
                    </ng-container>

                    <!-- Time Column -->
                    <ng-container matColumnDef="startTimestamp">
                        <th mat-header-cell *matHeaderCellDef class="text-center">{{ tableHeadings.time || 'Time' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left time-column-cell">{{ element?.startDate | date: 'HH:mm' }}</td>
                    </ng-container>

                    <!-- Countdown -->
                    <ng-container matColumnDef="countdownDate">
                        <th mat-header-cell *matHeaderCellDef class="text-center">{{ tableHeadings.status || 'Status' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left starts-column-cell">
                            <pt-calendar-countdown-timer
                                [timerStrings]="configStrings.statusTimerStrings"
                                [status]="element?.status"
                                [lateRegDate]="element.lateData"
                                [displayText]="tableHeadings.countdownText"
                                [endTime]="element.countdownDate"></pt-calendar-countdown-timer>
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="text-center table-name-column">{{ tableHeadings.name || 'Name' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left name-column-cell">{{ element?.name }}</td>
                    </ng-container>

                    <!-- Players registered -->
                    <ng-container matColumnDef="regPlayersCount">
                        <th mat-header-cell *matHeaderCellDef class="text-center table-players-column">
                            {{ tableHeadings.playersRegistered || 'Players Registered' }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left players-column-cell">
                            <span class="label-mobile">{{ tableHeadings.playersRegistered || 'Players Registered' }}</span>
                            {{ element?.regPlayersCount }}
                        </td>
                    </ng-container>

                    <!-- Guarantee Column -->
                    <ng-container matColumnDef="prizePool">
                        <th mat-header-cell *matHeaderCellDef class="text-center">{{ tableHeadings.prize || 'Prize' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left guarantee-column-cell">
                            <span *ngIf="contentIivtDataSource"
                                ><span class="label-mobile">{{ tableHeadings.prize || 'Prize' }}</span>
                                {{ element?.prizePool | toCurrencyPipe: configStrings?.currencyRePosition }}</span
                            >
                            <span *ngIf="!contentIivtDataSource"
                                ><span class="label-mobile">{{ tableHeadings.prize || 'Prize' }}</span>
                                {{ element?.prizePool.monetaryType | formatCurrencyPipe: element?.prizePool.unit }}</span
                            >
                        </td>
                    </ng-container>

                    <!-- Buy-in Column -->
                    <ng-container matColumnDef="buyIn">
                        <th mat-header-cell *matHeaderCellDef class="text-center">{{ tableHeadings.buyIn || 'Buy In' }}</th>
                        <td mat-cell *matCellDef="let element" class="text-left buyin-column-cell">
                            <span *ngIf="contentIivtDataSource"
                                ><span class="label-mobile">{{ tableHeadings.buyIn || 'Buy In' }}</span>
                                {{ element?.buyIn | formatDecimalPipe: configStrings?.currencyRePosition }}</span
                            >
                            <span *ngIf="!contentIivtDataSource"
                                ><span class="label-mobile">{{ tableHeadings.buyIn || 'Buy In' }}</span>
                                {{ element?.buyIn.monetaryType | formatCurrencyPipe: element?.buyIn.unit }} +
                                {{ element?.fee.monetaryType | formatCurrencyPipe: element?.fee.unit }}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Registration Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="text-center table-status-column">
                            {{ tableHeadings.registrationStatus || '' }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="reg-column-cell">
                            <ng-container
                                *ngIf="
                                    (element?.status | lowercase) === 'late registration' ||
                                        (element?.status | lowercase) === 'announced' ||
                                        (element?.status | lowercase) === 'registering';
                                    else CloseButton
                                ">
                                <div class="d-flex reg-cal-group">
                                    <button class="btn btn-primary btn-sm" (click)="openClient(element)">{{ tableHeadings.register }}</button>
                                    <div class="add-to-cal-wrap ml-2">
                                        <button
                                            class="btn btn-outline-default btn-sm btn-add-calendar-icon"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            (click)="toggleAddToCal($event, element)"
                                            [matTooltip]="configStrings.addToCalendar || 'Add to Calendar'"
                                            matTooltipPosition="left"
                                            id="{{ dataSource.filteredData.indexOf(element) }}">
                                            <span class="theme-calendar"></span>
                                        </button>

                                        <button
                                            class="btn btn-outline-default btn-sm d-lg-none"
                                            (click)="toggleAddToCal($event, element)"
                                            id="{{ dataSource.filteredData.indexOf(element) }}">
                                            {{ configStrings.addToCalendar || 'Add to Calendar' }}
                                        </button>
                                        <div class="add-to-cal-menu" *ngIf="dataSource.filteredData.indexOf(element) === addToCalActiveEle">
                                            <div class="hint theme-up"></div>
                                            <div class="social-links clear-fix" *ngFor="let calendarItem of addToCalendarGroup">
                                                <a (click)="calenderClicked(calendarItem, element)" target="_blank"
                                                    >{{ calendarItem.label }}<span class="theme-right"></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #CloseButton
                                ><button class="btn btn-sm btn-dark" disabled>{{ tableHeadings.closed || 'Closed' }}</button></ng-template
                            >
                        </td>
                    </ng-container>

                    <tr mat-header-row class="thead-dark" *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <div
                class="message-panel message-panel-dark-cust text-center mb-3"
                *ngIf="dataSource.data.length === 0 || dataSource.filteredData.length === 0">
                <div>{{ configStrings.noDataMessage || 'No Data found' }}</div>
            </div>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
