import { Message } from '../../message';

export class PlayedGamesCount extends Message {
    private MESSAGE_NAME: string = 'PlayedGamesCount';
    private count: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, count: number = 0) {
        super(reqServerPeerId);
        this.count = count;
    }

    getCount(): number {
        return this.count;
    }

    setCount(count: number) {
        this.count = count;
    }
}
