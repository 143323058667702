import { Message } from '../../message';

export class OneClickTourneyTicketCardChanges extends Message {
    private MESSAGE_NAME: string = 'OneClickTourneyTicketCardChanges';
    private cardIds: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardIds: any[] | null = null) {
        super(reqServerPeerId);
        this.cardIds = cardIds;
    }

    getCardIds(): any[] | null {
        return this.cardIds;
    }

    setCardIds(cardIds: any[] | null) {
        this.cardIds = cardIds;
    }
}
