export class Point {
    private _x: any;
    private _y: any;

    constructor(x: any, y: any) {
        this._x = x;
        this._y = y;
    }

    get x(): any {
        return this._x;
    }

    get y(): any {
        return this._y;
    }

    getXAxis(): any {
        return `${this._x}%`;
    }

    getYAxis(): any {
        return `${this._y}%`;
    }
}
