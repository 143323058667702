import { Message } from '../../message';

export class ArjelEndOfTheGameEventReq extends Message {
    private MESSAGE_NAME: string = 'ArjelEndOfTheGameEventReq';
    private conversationId: number = 0;
    private gameType: number = 0;
    private lowerStake: number = 0;
    private upperStake: number = 0;
    private tournId: number = 0;
    private handNumber: number = 0;
    private roundsInfo: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        conversationId: number = 0,
        gameType: number = 0,
        lowerStake: number = 0,
        upperStake: number = 0,
        tournId: number = 0,
        handNumber: number = 0,
        roundsInfo: Map<any, any> | null = null,
    ) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.gameType = gameType;
        this.lowerStake = lowerStake;
        this.upperStake = upperStake;
        this.tournId = tournId;
        this.handNumber = handNumber;
        this.roundsInfo = roundsInfo;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLowerStake(): number {
        return this.lowerStake;
    }

    setLowerStake(lowerStake: number) {
        this.lowerStake = lowerStake;
    }
    getUpperStake(): number {
        return this.upperStake;
    }

    setUpperStake(upperStake: number) {
        this.upperStake = upperStake;
    }
    getTournId(): number {
        return this.tournId;
    }

    setTournId(tournId: number) {
        this.tournId = tournId;
    }
    getHandNumber(): number {
        return this.handNumber;
    }

    setHandNumber(handNumber: number) {
        this.handNumber = handNumber;
    }
    getRoundsInfo(): Map<any, any> | null {
        return this.roundsInfo;
    }

    setRoundsInfo(roundsInfo: Map<any, any> | null) {
        this.roundsInfo = roundsInfo;
    }
}
