<div id="soloPromoTimer" style="font-size: xx-large" *ngIf="isBannerEnabled">
    <h3>
        <span>{{ registrationStartText }}</span
        >{{ timeLeft }}
    </h3>
</div>

<div id="soloPromoTimer" style="font-size: xx-large" *ngIf="isRegistrationStarted">
    <h3>
        <span>{{ registrationEndText }}</span
        >{{ timeLeft }}
    </h3>
</div>

<div id="soloPromoTimer" style="font-size: xx-large" *ngIf="isRegistrationClosed">
    <h3>
        <span>{{ registrationClosedText }}</span
        >{{ timeLeft }}
    </h3>
</div>
