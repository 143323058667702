export class LangPack {
    LangPack = {};
    dyn_num_id_and_string = [] as any;
    isDynamicDataCreated = false;
    constructor(siteCoreData) {
        this.initialize(siteCoreData);
    }

    initialize = function (LangPackText) {
        // console.log("langPack-------->", LangPackText);
        this.convertToKeyValueJSON(LangPackText);
        this.initialize = undefined;
        //window.dispatchEvent(new Event("LANGPACK_INITIALIZED"));
    };

    /*
               This is to return the String literal of the requested KEY
              */
    getLiteral = function (key, replaceStrArr, defaultValue, replaceSlashes) {
        let strLiteral = LangPack[key];
        /** replacing slashes if necessary */
        if (replaceSlashes) {
            strLiteral = strLiteral != undefined ? this.replaceAllSlashesN(strLiteral) : strLiteral;
        }
        if (arguments.length > 1) {
            if (strLiteral != undefined) {
                strLiteral = this.parseStringLiteral(strLiteral, replaceStrArr);
            } else {
                strLiteral = this.parseStringLiteral(defaultValue, replaceStrArr);
            }
        }
        return strLiteral ? strLiteral : '';
    };
    /*This method returns substring of literal from nth occurrence of '\n' to the end */
    getLiteralFromLine(literalText, lineNumber) {
        return literalText.substring(literalText.indexOf('\\n', lineNumber) + 2);
    }
    /*This method returns substring of literal from nth occurrence of '\n' to the end */
    getLiteralTillLine(literalText, lineNumber) {
        return literalText.substring(0, literalText.indexOf('\\n', lineNumber));
    }
    getJsonMessages = function (json) {
        if (this && this.initialize) {
            this.initialize(json);
        }
    };

    parseStringLiteral = function (str, replaceStrArr) {
        const literalRegex = new RegExp(/%\d[a-zA-Z]/);
        for (let i = 0; i < replaceStrArr.length; i++) {
            try {
                str = str.replace(literalRegex, replaceStrArr[i]);
            } catch (e) {
                //                            console.log(e);
            }
        }
        return str;
    };
    /*
      This method converts 'regular strings' to pascal case 'Regular String'
      Note: this translates only english characters 'a' to 'z' 
      */
    getPascalCaseString = function (str) {
        const substrArr = str.split(' ');
        let resStrArr = '';
        for (const substr of substrArr) {
            let ch = substr.charAt(0);
            if (ch <= 'z' && ch >= 'a') {
                ch = ch.toUpperCase();
            }
            resStrArr = resStrArr + ch + substr.substring(1) + ' ';
        }
        return resStrArr.trim();
    };
    /*This method converts 'simple string' to CAPS 'SIMPLE STRING'
      Note: it converts only english characters 'a' to 'z' */
    getCAPSString = function (str) {
        let resStr = '';
        for (let i = 0; i < str.length; i++) {
            let ch = str.charAt(i);
            if (ch <= 'z' && ch >= 'a') ch = ch.toUpperCase();
            resStr = resStr + ch;
        }
        return resStr.trim();
    };

    /*
               This is the main function called to format the string
              */

    getFormattedString = function (
        strExObj,
        localeUtil,
        replaceSlashes,
        currencyWithSymbol,
        currencyWithCode,
        currencyWithName,
        requiresComma,
        requiresDecimal,
    ) {
        const template = this.getTemplateContent(strExObj, strExObj.templateId, false, replaceSlashes); //This is to load the Value from lang pack
        if (template == null || template == '') {
            // If there is no template then return the plain string as it is.(The string we got from parameter values)
            return this.readAsPlainString(strExObj, replaceSlashes);
        }
        //we got value from lang pack nd we r goin to replace it
        return this.replacePositionalParameters(
            strExObj,
            localeUtil,
            template,
            false,
            replaceSlashes,
            currencyWithSymbol,
            currencyWithCode,
            currencyWithName,
            requiresComma,
            requiresDecimal,
        );
    };
    /*
                      This function is to get the template(string literal with placeholders) from the langpack.
               */
    getTemplateContent = function (strExObj, id, isEnglishRequired, replaceSlashes) {
        const templateID = Number(id);
        if (templateID == -1) {
            return this.readAsPlainString(strExObj, replaceSlashes);
        }
        if (strExObj.isLPElement) {
            return this.getLiteral(templateID);
        } else {
            return this.getDynLangString(templateID);
        }
    };

    getDynLangString = function (numID) {
        const s = String(this.dyn_num_id_and_string[numID]);
        if (s == null || s == 'undefined') {
            return '';
        } else {
            this.replaceAllSlashesN(s);
        }
        return s;
    };

    updateDynData = function (data, msg) {
        if (!this.isDynamicDataCreated) {
            if (data) {
                this.dyn_num_id_and_string = [] as any;
                const keys = this.getKeys(data);
                const len = keys.length;
                for (let i = 0; i < len; i++) {
                    const mkey = keys[i];
                    const mdata = data[mkey];
                    this.dyn_num_id_and_string[mkey] = mdata;
                }
                this.isDynamicDataCreated = true;
                return;
            }
        }
        if (data != null) {
            const keys = this.getKeys(data);
            const len = keys.length;
            for (let i = 0; i < len; i++) {
                const mkey = keys[i];
                const mdata = data[mkey];
                this.dyn_num_id_and_string[mkey] = mdata;
            }
        } else if (msg != null) {
            this.dyn_num_id_and_string[msg.templateId || msg.literalId] = msg.content || msg.literalValue;
        }
    };

    getKeys = function (_htable) {
        const keysArray = [] as any;
        for (const key in _htable) {
            if (_htable.hasOwnProperty(key)) {
                keysArray.push(key);
            }
        }
        return keysArray;
    };

    /*
                      This function checks for the presences of parametervalues
                      and if present return them(with or without replacing slashes depending on the values)
                      else return NULL
               */
    readAsPlainString = function (strExObj, replaceSlashes) {
        const val = strExObj.params != null && strExObj.params.length > 0 ? strExObj.params[0] : null;
        return val == null ? null : replaceSlashes ? this.replaceAllSlashesN(String(val)) : String(val);
    };
    /*
                      This function is to replace the escape charecters with their values
               */
    replaceAllSlashesN = function (newString) {
        let result = '';
        const len = newString.length;
        for (let i = 0; i < len; i++) {
            let nextChar = newString.charAt(i);
            if (nextChar == '\\') {
                // Here the \ slash is escaped.... :)
                i++;
                nextChar = newString.charAt(i);
                //Possible escape characters are: \t, \n, \b, \r.  .. IF we replace it with the correct values.. on display this may be replaced i think
                switch (nextChar) {
                    case 't':
                        nextChar = '\t';
                        break;
                    case 'n':
                        nextChar = '\n';
                        break;
                    case 'b':
                        nextChar = '\b';
                        break;
                    case 'r':
                        nextChar = '\r';
                        break;
                    default:
                        //Should not reach.
                        nextChar = '\n';
                }
            }
            result = result.concat(nextChar);
        }
        return result;
    };
    /*
                  This function converts the json file containing array of json objects to key value pairs.
               */
    convertToKeyValueJSON = function (LangPackText) {
        const size = LangPackText.length;
        for (let i = 0; i < size; i++) {
            const lpArray = LangPackText;
            const nid = lpArray[i]['id'];
            const sid = lpArray[i]['name'];
            const value = lpArray[i]['value'];

            LangPack[nid] = value;
            LangPack[sid] = value;
        }
        // return $.parseJSON(json);
    };
    /*
                      This function is to replace the positional parameters with the values.
               */
    replacePositionalParameters = function (
        strExObj,
        localeUtility,
        template,
        isEnglishRequired,
        replaceSlashes,
        currencyWithSymbol,
        currencyWithCode,
        currencyWithName,
        requiresComma,
        requiresDecimal,
    ) {
        const paramTypes = ['N', 'n', 'S', 's', 'F', 'f', 'c', 'C', 'D', 'T', 'U'] as any; //These are the possible positional parameters(place holders ) value
        let sb = '';
        // eslint-disable-next-line unicorn/prefer-code-point
        const charCode_0 = new String('0').charCodeAt(0);

        if (strExObj.templateId == -1) {
            if (strExObj.params && strExObj.params.length == 1) {
                return replaceSlashes ? this.replaceAllSlashesN(template) : template;
            }
            //If template is available within parameterValues vector itself, then
            //size of 1 is considered to have no parameter values.
        } else if (strExObj.params && strExObj.params.length == 0) {
            //size of 0 is considered to have no parameter values.
            return replaceSlashes ? this.replaceAllSlashesN(template) : template;
        }

        const len = template.length; //template is the content we got from langPack

        //loop through the template
        for (let i = 0, l = len; i < l; i++) {
            let c = template.charAt(i); //.. takes each char
            if (c == '%') {
                let n = 0;
                let k = 1;
                for (i++; i < l; i++, k++) {
                    c = template.charAt(i); //taking the char next to %
                    if (c >= '0' && c <= '9') {
                        //checking for number
                        // eslint-disable-next-line unicorn/prefer-code-point
                        n = n * 10 + (c.charCodeAt(0) - charCode_0);
                    } else {
                        break;
                    }
                }
                //K- position at which the loop breaks

                //n>0 -- it had some number  like %5s
                //c here is.... the char next to the number Eg: %5n - here c is 'n'..
                if (n > 0 && paramTypes.indexOf(c) >= 0) {
                    if (n > strExObj.params.length) {
                        continue; //This is to break the flow and continue to next char.. if the positional parameter (Eg %5s) 5>the length of parametervalues..  (i.e) no parametervalue is there to replace the posiotional parameter
                    }
                    const o = strExObj.params[n - 1]; // Take the value at n-th position
                    switch (
                        c // c can be - ("n","s","f","C","D","T","U")
                    ) {
                        case 'n':
                        case 'N':
                            sb = sb.concat(o.toString());
                            break;
                        case 's':
                        case 'S':
                            sb = sb.concat(o.toString());
                            break;
                        case 'f':
                        case 'F':
                            sb = sb.concat(o.toString());
                            break;
                        case 'C':
                        case 'c':
                            if (typeof o == 'number') {
                                sb = sb.concat(localeUtility.formatMoneyInCents(Number(o)));
                            } else if (o.classId == 41026) {
                                const ca = o; //-- object of 41026 (i.e) currencyAmount
                                const cd = localeUtility.getCurrencyDetails(ca.currencyCode); //cd- object of currencydetails
                                let fractiondigits = 2;
                                if (cd != null) {
                                    fractiondigits = cd.defaultFractionDigits;
                                }
                                fractiondigits = Math.pow(10, fractiondigits);
                                const val = ca.amount / fractiondigits;
                                const v = [] as any;
                                v.push(val);
                                const format = '#';
                                let choice = 0;
                                if (currencyWithSymbol && ca.currencyCode != null) choice += 1;
                                if (currencyWithCode && ca.currencyCode != null) choice += 5;
                                if (currencyWithName && ca.currencyCode != null) choice += 10;

                                switch (choice) {
                                    case 0:
                                        sb = sb.concat(localeUtility.doCF(val + '', null, null, requiresComma, requiresDecimal, false, false));
                                        break;
                                    case 1:
                                        sb = sb.concat(
                                            localeUtility.formatCurrencyWithSymbol(format, v, ca.currencyCode, requiresComma, requiresDecimal, false),
                                        );
                                        break;
                                    case 5:
                                        sb = sb.concat(
                                            localeUtility.formatCurrencyWithCode(format, v, ca.currencyCode, requiresComma, requiresDecimal),
                                        );
                                        break;
                                    case 6:
                                        sb = sb.concat(
                                            localeUtility.formatCurrencyWithSymbolCode(format, v, ca.currencyCode, requiresComma, requiresDecimal),
                                        );
                                        break;
                                    case 10:
                                        sb = sb.concat(
                                            localeUtility.formatCurrencyWithName(format, v, ca.currencyCode, requiresComma, requiresDecimal),
                                        );
                                        break;
                                    case 11:
                                        sb = sb.concat(
                                            localeUtility.formatCurrencyWithSymbolName(format, v, ca.currencyCode, requiresComma, requiresDecimal),
                                        );
                                        break;
                                    default:
                                        sb = sb.concat(
                                            localeUtility.formatCurrencyWithCodeName(format, v, ca.currencyCode, requiresComma, requiresDecimal),
                                        );
                                        break;
                                }
                            } else {
                                const arr = o.split('~');
                                if (arr != null && arr.length > 0) {
                                    const initialVal = Number(arr[0]);
                                    const currencyType = String(arr[1]);
                                    const mcurrencydetails = localeUtility.getCurrencyDetails(currencyType);
                                    let mfractiondigits = 2;
                                    if (mcurrencydetails != null) {
                                        mfractiondigits = mcurrencydetails.defaultFractionDigits;
                                    }
                                    mfractiondigits = Math.pow(10, mfractiondigits);
                                    const value = initialVal / mfractiondigits;
                                    const arycoll = [] as any;
                                    arycoll.push(value);
                                    const formatval = '#';
                                    if (currencyType == '***') {
                                        // play money only cf
                                        sb = sb.concat(localeUtility.doCF(value + '', null, null, requiresComma, false)); //This will just format the value.(i.e adding decimal seperator etc)
                                    } else {
                                        let choiceVal = 0;
                                        if (currencyWithSymbol) choiceVal += 1;
                                        if (currencyWithCode) choiceVal += 5;
                                        if (currencyWithName) choiceVal += 10;

                                        switch (choiceVal) {
                                            /*// will never come */ case 0:
                                                sb = sb.concat(localeUtility.doCF(value + '', null, null, requiresComma, false));
                                                break;
                                            case 1:
                                                sb = sb.concat(
                                                    localeUtility.formatCurrencyWithSymbol(formatval, arycoll, currencyType, requiresComma, false),
                                                );
                                                break;
                                            case 5:
                                                sb = sb.concat(
                                                    localeUtility.formatCurrencyWithCode(formatval, arycoll, currencyType, requiresComma, false),
                                                );
                                                break;
                                            case 6:
                                                sb = sb.concat(
                                                    localeUtility.formatCurrencyWithSymbolCode(
                                                        formatval,
                                                        arycoll,
                                                        currencyType,
                                                        requiresComma,
                                                        false,
                                                    ),
                                                );
                                                break;
                                            case 10:
                                                sb = sb.concat(
                                                    localeUtility.formatCurrencyWithName(formatval, arycoll, currencyType, requiresComma, false),
                                                );
                                                break;
                                            case 11:
                                                sb = sb.concat(
                                                    localeUtility.formatCurrencyWithSymbolName(
                                                        formatval,
                                                        arycoll,
                                                        currencyType,
                                                        requiresComma,
                                                        false,
                                                    ),
                                                );
                                                break;
                                            default:
                                                sb = sb.concat(
                                                    localeUtility.formatCurrencyWithCodeName(formatval, arycoll, currencyType, requiresComma, false),
                                                );
                                                break;
                                        }
                                    }
                                } else {
                                    sb = sb.concat(localeUtility.formatMoneyInCents(Number(o)));
                                }
                            }
                            break;
                        case 'D':
                            sb = sb.concat(localeUtility.formatDateAsMediumString(Number(o)));
                            break;
                        case 'T':
                            if (isEnglishRequired) {
                                sb = sb.concat(this.getTemplateContent(strExObj, Number(o), true, replaceSlashes)); //no nested replacement necessary
                            } else {
                                sb = sb.concat(this.getTemplateContent(strExObj, Number(o), false, replaceSlashes)); //no nested replacement necessary
                            }
                            break;
                        case 'U':
                            sb = sb.concat(this.getDynLangString(Number(o)));
                            break;
                    }
                } //  end of if (n > 0 && paramTypes.contains(c))
                else {
                    //E.g : %5j  - It has % and a number but 'j' will not be in parametervalues array.. (i.e) This is will not be replaced and it is displayed as it is.
                    for (let j = i - k; j < i; j++) {
                        sb = sb.concat(template.charAt(j));
                    }
                    i--;
                }
            } //end of if(c==%)
            else {
                // if it is not a % then append it
                sb = sb.concat(c);
            }
        }
        return replaceSlashes ? this.replaceAllSlashesN(sb.toString()) : sb.toString();
    };

    //Dynamic Literals messages handling --start
    responseMTCTDynaDetailsList = function (message) {
        this.dynamicLanguagePackUpdate(message.dynamicLanguagePackUpdate);
    };
    ResponseDynaDetailsDeltaChanges = function (message) {
        this.updateDynData(message.literalsMap, null);
    };
    dynamicLanguagePackUpdate = function (message) {
        this.updateDynData(message.literalsMap, null);
    };
    LSTabIdDynamicLanguagePackDetails = function (message) {
        this.dynamicLanguagePackUpdate(message.dynamicLanguagePackUpdate);
    };
}
