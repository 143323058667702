import MultiStyleText from 'pixi-multistyle-text';
import * as PIXI from 'pixi.js';

import { uiConfig } from '../game-config/uiconfig';

export class DisplayUtil {
    constructor() {}
    getCircleFill(x: any, y: any, r: any, col: any) {
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(2, 0x993333);
        graphics.beginFill(col);
        graphics.drawCircle(x, y, r);
        return graphics;
    }
    getRectangleSprite(wid: any, ht: any, col: any, isBorder: any, borderWid: any, borderCol: any, borderAlp: any) {
        const graphics = new PIXI.Graphics();
        if (isBorder) {
            borderWid = borderWid || 1;
            borderCol = borderCol || '0xffffff';
            borderAlp = borderAlp || 1;
            graphics.lineStyle(borderWid, borderCol, borderAlp);
        }
        // begin a fill..
        graphics.beginFill(col);

        // draw a triangle using lines
        graphics.drawRect(0, 0, wid, ht);
        return graphics;
    }
    getBorderRoundRectangleSprite(wid: any, ht: any, col: any, rad: any, isBorder: any, borderWid: any, borderCol: any, borderAlp: any) {
        const graphics = new PIXI.Graphics();
        if (isBorder) {
            borderWid = borderWid || 2;
            borderCol = borderCol || '0xffffff';
            borderAlp = borderAlp || 1;
            graphics.lineStyle(borderWid, borderCol, borderAlp);
        }
        // begin a fill..
        graphics.beginFill(col);

        // draw a triangle using lines
        graphics.drawRoundedRect(0, 0, wid, ht, rad);
        return graphics;
    }
    getArcSprite(lineWidth: any, lineColor: any, lineAlpha: any, x: any, y: any, radius: any, startAngle: any, endAngle: any, anticlockwise: any) {
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(lineWidth, lineColor, lineAlpha);
        graphics.arc(x, y, radius, startAngle, endAngle, anticlockwise);
        return graphics;
    }
    getPayline(arr: any, col: any) {
        if (col == undefined) {
            col = 0x000000;
        }
        const len = arr.length;
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(4, col, 1);
        for (let k = 0; k < len; k++) {
            if (k == 0) {
                graphics.moveTo(arr[k].x, arr[k].y);
            } else {
                graphics.lineTo(arr[k].x, arr[k].y);
            }
        }
        return graphics;
    }
    getPolygon(arr: any, col: any) {
        if (col == undefined) {
            col = 0x000000;
        }
        const graphics = new PIXI.Graphics();
        graphics.beginFill(col);
        graphics.drawPolygon(arr);
        graphics.endFill();
        return graphics;
    }
    reDrawPolygon(obj: any, arr: any, col: any) {
        if (col == undefined) {
            col = 0x000000;
        }
        obj.clear();
        obj.beginFill(col);
        obj.drawPolygon(arr);
        obj.endFill();
        return obj;
    }
    getTexture(name: any) {
        //return PIXI.Texture.from(name);
        return PIXI.utils.TextureCache[name];
    }
    getContainer() {
        return new PIXI.Container();
    }
    getSprite(name: any) {
        let sp;
        if (name && name.length > 0) {
            let texture;
            try {
                texture = this.getTexture(name);
                sp = new PIXI.Sprite(texture);
            } catch (e) {
                sp = new PIXI.Sprite();
            }
        } else {
            sp = new PIXI.Sprite();
        }
        return sp;
    }
    getSpriteDetails(str) {
        return uiConfig.fileNames.Mobile[str];
    }
    getAnimatedSprite(arr: any) {
        const textures = [] as any;
        for (let i = 0; i < arr.length; i++) {
            textures.push(this.getTexture(arr[i]));
        }
        const animatedSprite = new PIXI.AnimatedSprite(textures);
        return animatedSprite;
    }
    getCustomAnimatedFrames(obj: any) {
        const arr = [] as any;
        for (let k = obj.start; k <= obj.end; k++) {
            if (obj.doubleDigit) {
                const n = k <= 9 ? '0' + k : k;
                arr.push(obj.prefix + n + obj.postfix);
            } else {
                arr.push(obj.prefix + k + obj.postfix);
            }
        }
        return arr;
    }
    setProperties(element: any, props: any) {
        if (props.x !== undefined) {
            element.x = props.x;
        }
        if (props.y !== undefined) {
            element.y = props.y;
        }
        if (props.scale !== undefined) {
            if (typeof props.scale === 'object') {
                if (props.scale.x !== undefined) {
                    element.scale.x = props.scale.x;
                }
                if (props.scale.y !== undefined) {
                    element.scale.y = props.scale.y;
                }
            } else {
                element.scale.set(props.scale);
            }
        }
        if (props.anchor !== undefined) {
            if (typeof props.anchor === 'object') {
                if (props.anchor.x !== undefined) {
                    element.anchor.x = props.anchor.x;
                }
                if (props.anchor.y !== undefined) {
                    element.anchor.y = props.anchor.y;
                }
            } else {
                element.anchor.set(props.anchor, props.anchor);
            }
        }
        if (props.alpha !== undefined) {
            element.alpha = props.alpha;
        }
        if (props.rotation !== undefined) {
            element.rotation = props.rotation;
        }
        if (props.texture !== undefined) {
            this.setTexture(element, props.texture);
        }
    }
    getCustomAnimatedSprite(obj: any) {
        const arr = [] as any;
        for (let k = obj.start; k <= obj.end; k++) {
            switch (obj.doubleDigit) {
                case true:
                    const n = k <= 9 ? '0' + k : k;
                    arr.push(obj.prefix + n + obj.postfix);
                    break;
                case false:
                    arr.push(obj.prefix + k + obj.postfix);
                    break;
            }
        }
        const mc = this.getAnimatedSprite(arr);
        mc.anchor.set(0, 0);
        mc.animationSpeed = obj.animationSpeed;
        return mc;
    }

    setTexture(obj: any, image: any) {
        const texture = this.getTexture(image);
        obj.texture = texture;
    }

    setObjectPosition(obj: any, x: any, y: any) {
        obj.position.x = x;
        obj.position.y = y;
    }
    setObjectAnchor(obj: any, x: any, y: any) {
        obj.anchor.set.x = x;
        obj.anchor.set.y = y;
    }
    getFixedWidthTextField(obj: any, str: any) {
        if (str === undefined) {
            str = '';
        }
        let txt;
        if (obj.type === 'BitmapFont') {
            txt = new PIXI.BitmapText(str, obj);
            if (obj && obj.availableFontWidth) {
                txt.availableFontWidth = obj.availableFontWidth;
            }
        } else {
            txt = new PIXI.Text(str, obj);
        }
        if (obj && obj.availableFontWidth && !(obj.type === 'BitmapFont')) {
            Object.defineProperties(txt, {
                text: {
                    get: function () {
                        return this._text;
                    },
                    set: function (e) {
                        // eslint-disable-next-line unicorn/prefer-default-parameters
                        let text = e || ' ';
                        text = text.toString();
                        this._text = text;
                        this.dirty = true;

                        const fontSize = txt.style.fontSize;
                        //Storing the actual font size for further use
                        if (!txt.style.actualFontSize) {
                            txt.style.actualFontSize = fontSize;
                        }
                        //Update the font style with actual font size first
                        txt.style.fontSize = txt.style.actualFontSize;
                        //Decrement font size until it fits in available width
                        //And update font style
                        while (txt.width > obj.availableFontWidth) {
                            txt.style.fontSize--;
                            //txt.style = txt.style;
                        }
                    },
                },
            });
        }
        return txt;
    }
    getTextField(obj: any, str?: any) {
        if (obj && obj.availableFontWidth) {
            return this.getFixedWidthTextField(obj, str);
        }
        if (str == undefined) {
            str = '';
        }
        let txt;
        if (obj.type == 'BitmapFont') {
            txt = new PIXI.BitmapText(str, obj);
            if (obj && obj.availableFontWidth) {
                txt.availableFontWidth = obj.availableFontWidth;
            }
        } else {
            txt = new PIXI.Text(str, obj);
        }
        return txt;
    }
    getMultiStyleTextField(obj: any, str: any) {
        if ((obj && str != null) || str != '') {
            return new MultiStyleText(str, obj);
        }
        return false;
    }
    getCustomButton(up: any, over: any, down: any) {
        const arr = [] as any;
        arr.push(this.getTexture(up));
        try {
            arr.push(this.getTexture(over));
        } catch (e) {
            arr.push(this.getTexture(up));
        }
        try {
            arr.push(this.getTexture(down));
        } catch (e) {
            arr.push(this.getTexture(up));
        }

        const sprite = new PIXI.AnimatedSprite(arr);
        //sprite.interactive = true;
        //sprite.buttonMode = true;
        sprite.on('mousedown', function () {
            sprite.gotoAndStop(1);
        });
        sprite.on('mouseover', function () {
            sprite.gotoAndStop(1);
        });
        sprite.on('touchstart', function () {
            sprite.gotoAndStop(2);
        });

        return sprite;
    }
    getButton(name: any) {
        const arr = [] as any;
        arr.push(this.getTexture(name + '_up'));
        arr.push(this.getTexture(name + '_down'));
        const sprite = new PIXI.AnimatedSprite(arr);
        sprite.on('touchstart', function () {
            sprite.gotoAndStop(1);
        });
        sprite.on('touchend', function () {
            sprite.gotoAndStop(0);
        });
        sprite.on('touchendoutside', function () {
            sprite.gotoAndStop(0);
        });
        return sprite;
    }
    assignHitArea(btn: any, area: any, type: any) {
        if (area) {
            if (type == 'circle') {
                btn.hitArea = new PIXI.Circle(area.x, area.y, area.radius);
            } else if (type == 'polygon') {
                btn.hitArea = new PIXI.Polygon(area);
            } else {
                btn.hitArea = new PIXI.Rectangle(area.x, area.y, area.w, area.h);
            }
        }
    }
    addInteraction(btn: any, callback: any) {
        btn.interactive = true;
        btn.on('pointerdown', callback);
    }
    setButtonActive(btn: any, flag: any) {
        btn.interactive = flag;
        btn.buttonMode = flag;
    }
    setClickable(btn: any, flag: any, buttonMode: any) {
        btn.interactive = flag;
        if (buttonMode) btn.buttonMode = flag;
        if (flag) btn.tint = '0xffffff';
        else btn.tint = 0x666666;
    }
    setEnabled(btn: any, flag: any) {
        btn.interactive = flag;
        btn.buttonMode = flag;
        if (flag) {
            btn.filters = undefined;
            btn.gotoAndStop(0);
            btn.tint = '0xffffff';
            if (btn.iconLableConfig) {
                for (const key in btn.iconLableConfig) {
                    btn.children[0][key] = btn.iconLableConfig[key]['active'];
                }
            }
        } else {
            if (btn.totalFrames > 2) {
                btn.gotoAndStop(3);
            } else {
                btn.tint = 0x666666;
            }
            if (btn.iconLableConfig) {
                for (const key in btn.iconLableConfig) {
                    btn.children[0][key] = btn.iconLableConfig[key]['inactive'];
                }
            }
        }
    }
    setContainerScaleFitToScreen(container) {
        const scaleXValue = uiConfig.windowDimension.width / uiConfig.game.baseLayout.ML.baseWidth;
        const scaleYValue = uiConfig.windowDimension.height / uiConfig.game.baseLayout.ML.baseHeight;
        container.scale.set(scaleXValue, scaleYValue);
    }
}
