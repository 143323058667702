import { Message } from '../../message';

export class RequestQuickSeatFastForward extends Message {
    private MESSAGE_NAME: string = 'RequestQuickSeatFastForward';
    private requestId: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private lowerStakesOrBlinds: number = 0;
    private maxSeats: number = 0;
    private speedType: number = 0;
    private buyInValue: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        lowerStakesOrBlinds: number = 0,
        maxSeats: number = 0,
        speedType: number = 0,
        buyInValue: number = 0,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.gameType = gameType;
        this.limitType = limitType;
        this.lowerStakesOrBlinds = lowerStakesOrBlinds;
        this.maxSeats = maxSeats;
        this.speedType = speedType;
        this.buyInValue = buyInValue;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getLowerStakesOrBlinds(): number {
        return this.lowerStakesOrBlinds;
    }

    setLowerStakesOrBlinds(lowerStakesOrBlinds: number) {
        this.lowerStakesOrBlinds = lowerStakesOrBlinds;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getSpeedType(): number {
        return this.speedType;
    }

    setSpeedType(speedType: number) {
        this.speedType = speedType;
    }
    getBuyInValue(): number {
        return this.buyInValue;
    }

    setBuyInValue(buyInValue: number) {
        this.buyInValue = buyInValue;
    }
}
