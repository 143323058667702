import { Message } from '../../message';

export class MTCTLobbyData extends Message {
    private MESSAGE_NAME: string = 'MTCTLobbyData';
    private mtctId: number = 0;
    private tourneyStartTime: any | null = null;
    private name: any | null = null;
    private gameType: number = 0;
    private limitType: number = 0;
    private buyIn: number = 0;
    private entryFee: number = 0;
    private noOfPlayers: number = 0;
    private status: number = 0;
    private gamePlayType: number = 0;
    private noOfParticipants: number = 0;
    private maxParticipants: number = 0;
    private announceTime: any | null = null;
    private registrationStartTime: any | null = null;
    private registrationEndTime: any | null = null;
    private allowLateRegistration: boolean | null = false;
    private tourneyEndTime: any | null = null;
    private tourneyCurrentLevel: number = 0;
    private totalPrizePool: number = 0;
    private addonSupported: boolean | null = false;
    private rebuyAddonStatus: number = 0;
    private firstRankPlayerName: string | null = null;
    private mtctEvents: any[] | null = null;
    private totalRoundCount: number = 0;
    private maxSeats: number = 0;
    private mtsgId: number = 0;
    private tournamentDescription: any | null = null;
    private gameCurrency: string | null = null;
    private trnyTypeId: number = 0;
    private buyInInfo: any | null = null;
    private colorCode: number = 0;
    private partyPoints: number = 0;
    private lateRegInProgress: boolean | null = false;
    private unregClosed: boolean | null = false;
    private bountyAmount: number = 0;
    private allowLateRegistrationTillLevel: number = 0;
    private unRegOffset: number = 0;
    private mttType: number = 0;
    private trnyMinPlrs: number = 0;
    private isQuinteTourney: boolean | null = false;
    private quintepokChampFee: number = 0;
    private allowedCountryCodes: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        tourneyStartTime: any | null = null,
        name: any | null = null,
        gameType: number = 0,
        limitType: number = 0,
        buyIn: number = 0,
        entryFee: number = 0,
        noOfPlayers: number = 0,
        status: number = 0,
        gamePlayType: number = 0,
        noOfParticipants: number = 0,
        maxParticipants: number = 0,
        announceTime: any | null = null,
        registrationStartTime: any | null = null,
        registrationEndTime: any | null = null,
        allowLateRegistration: boolean | null = false,
        tourneyEndTime: any | null = null,
        tourneyCurrentLevel: number = 0,
        totalPrizePool: number = 0,
        addonSupported: boolean | null = false,
        rebuyAddonStatus: number = 0,
        firstRankPlayerName: string | null = null,
        mtctEvents: any[] | null = null,
        totalRoundCount: number = 0,
        maxSeats: number = 0,
        mtsgId: number = 0,
        tournamentDescription: any | null = null,
        gameCurrency: string | null = null,
        trnyTypeId: number = 0,
        buyInInfo: any | null = null,
        colorCode: number = 0,
        partyPoints: number = 0,
        lateRegInProgress: boolean | null = false,
        unregClosed: boolean | null = false,
        bountyAmount: number = 0,
        allowLateRegistrationTillLevel: number = 0,
        unRegOffset: number = 0,
        mttType: number = 0,
        trnyMinPlrs: number = 0,
        isQuinteTourney: boolean | null = false,
        quintepokChampFee: number = 0,
        allowedCountryCodes: string | null = null,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tourneyStartTime = tourneyStartTime;
        this.name = name;
        this.gameType = gameType;
        this.limitType = limitType;
        this.buyIn = buyIn;
        this.entryFee = entryFee;
        this.noOfPlayers = noOfPlayers;
        this.status = status;
        this.gamePlayType = gamePlayType;
        this.noOfParticipants = noOfParticipants;
        this.maxParticipants = maxParticipants;
        this.announceTime = announceTime;
        this.registrationStartTime = registrationStartTime;
        this.registrationEndTime = registrationEndTime;
        this.allowLateRegistration = allowLateRegistration;
        this.tourneyEndTime = tourneyEndTime;
        this.tourneyCurrentLevel = tourneyCurrentLevel;
        this.totalPrizePool = totalPrizePool;
        this.addonSupported = addonSupported;
        this.rebuyAddonStatus = rebuyAddonStatus;
        this.firstRankPlayerName = firstRankPlayerName;
        this.mtctEvents = mtctEvents;
        this.totalRoundCount = totalRoundCount;
        this.maxSeats = maxSeats;
        this.mtsgId = mtsgId;
        this.tournamentDescription = tournamentDescription;
        this.gameCurrency = gameCurrency;
        this.trnyTypeId = trnyTypeId;
        this.buyInInfo = buyInInfo;
        this.colorCode = colorCode;
        this.partyPoints = partyPoints;
        this.lateRegInProgress = lateRegInProgress;
        this.unregClosed = unregClosed;
        this.bountyAmount = bountyAmount;
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
        this.unRegOffset = unRegOffset;
        this.mttType = mttType;
        this.trnyMinPlrs = trnyMinPlrs;
        this.isQuinteTourney = isQuinteTourney;
        this.quintepokChampFee = quintepokChampFee;
        this.allowedCountryCodes = allowedCountryCodes;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getEntryFee(): number {
        return this.entryFee;
    }

    setEntryFee(entryFee: number) {
        this.entryFee = entryFee;
    }
    getNoOfPlayers(): number {
        return this.noOfPlayers;
    }

    setNoOfPlayers(noOfPlayers: number) {
        this.noOfPlayers = noOfPlayers;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
    getMaxParticipants(): number {
        return this.maxParticipants;
    }

    setMaxParticipants(maxParticipants: number) {
        this.maxParticipants = maxParticipants;
    }
    getAnnounceTime(): any | null {
        return this.announceTime;
    }

    setAnnounceTime(announceTime: any | null) {
        this.announceTime = announceTime;
    }
    getRegistrationStartTime(): any | null {
        return this.registrationStartTime;
    }

    setRegistrationStartTime(registrationStartTime: any | null) {
        this.registrationStartTime = registrationStartTime;
    }
    getRegistrationEndTime(): any | null {
        return this.registrationEndTime;
    }

    setRegistrationEndTime(registrationEndTime: any | null) {
        this.registrationEndTime = registrationEndTime;
    }
    getAllowLateRegistration(): boolean | null {
        return this.allowLateRegistration;
    }

    setAllowLateRegistration(allowLateRegistration: boolean | null) {
        this.allowLateRegistration = allowLateRegistration;
    }
    getTourneyEndTime(): any | null {
        return this.tourneyEndTime;
    }

    setTourneyEndTime(tourneyEndTime: any | null) {
        this.tourneyEndTime = tourneyEndTime;
    }
    getTourneyCurrentLevel(): number {
        return this.tourneyCurrentLevel;
    }

    setTourneyCurrentLevel(tourneyCurrentLevel: number) {
        this.tourneyCurrentLevel = tourneyCurrentLevel;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getAddonSupported(): boolean | null {
        return this.addonSupported;
    }

    setAddonSupported(addonSupported: boolean | null) {
        this.addonSupported = addonSupported;
    }
    getRebuyAddonStatus(): number {
        return this.rebuyAddonStatus;
    }

    setRebuyAddonStatus(rebuyAddonStatus: number) {
        this.rebuyAddonStatus = rebuyAddonStatus;
    }
    getFirstRankPlayerName(): string | null {
        return this.firstRankPlayerName;
    }

    setFirstRankPlayerName(firstRankPlayerName: string | null) {
        this.firstRankPlayerName = firstRankPlayerName;
    }
    getMtctEvents(): any[] | null {
        return this.mtctEvents;
    }

    setMtctEvents(mtctEvents: any[] | null) {
        this.mtctEvents = mtctEvents;
    }
    getTotalRoundCount(): number {
        return this.totalRoundCount;
    }

    setTotalRoundCount(totalRoundCount: number) {
        this.totalRoundCount = totalRoundCount;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getMtsgId(): number {
        return this.mtsgId;
    }

    setMtsgId(mtsgId: number) {
        this.mtsgId = mtsgId;
    }
    getTournamentDescription(): any | null {
        return this.tournamentDescription;
    }

    setTournamentDescription(tournamentDescription: any | null) {
        this.tournamentDescription = tournamentDescription;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getTrnyTypeId(): number {
        return this.trnyTypeId;
    }

    setTrnyTypeId(trnyTypeId: number) {
        this.trnyTypeId = trnyTypeId;
    }
    getBuyInInfo(): any | null {
        return this.buyInInfo;
    }

    setBuyInInfo(buyInInfo: any | null) {
        this.buyInInfo = buyInInfo;
    }
    getColorCode(): number {
        return this.colorCode;
    }

    setColorCode(colorCode: number) {
        this.colorCode = colorCode;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
    getBountyAmount(): number {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: number) {
        this.bountyAmount = bountyAmount;
    }
    getAllowLateRegistrationTillLevel(): number {
        return this.allowLateRegistrationTillLevel;
    }

    setAllowLateRegistrationTillLevel(allowLateRegistrationTillLevel: number) {
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
    }
    getUnRegOffset(): number {
        return this.unRegOffset;
    }

    setUnRegOffset(unRegOffset: number) {
        this.unRegOffset = unRegOffset;
    }
    getMttType(): number {
        return this.mttType;
    }

    setMttType(mttType: number) {
        this.mttType = mttType;
    }
    getTrnyMinPlrs(): number {
        return this.trnyMinPlrs;
    }

    setTrnyMinPlrs(trnyMinPlrs: number) {
        this.trnyMinPlrs = trnyMinPlrs;
    }
    getIsQuinteTourney(): boolean | null {
        return this.isQuinteTourney;
    }

    setIsQuinteTourney(isQuinteTourney: boolean | null) {
        this.isQuinteTourney = isQuinteTourney;
    }
    getQuintepokChampFee(): number {
        return this.quintepokChampFee;
    }

    setQuintepokChampFee(quintepokChampFee: number) {
        this.quintepokChampFee = quintepokChampFee;
    }
    getAllowedCountryCodes(): string | null {
        return this.allowedCountryCodes;
    }

    setAllowedCountryCodes(allowedCountryCodes: string | null) {
        this.allowedCountryCodes = allowedCountryCodes;
    }
}
