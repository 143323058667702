import { Message } from '../../message';

export class ResponseSNGJPFeed extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGJPFeed';
    private lastSnapShotTimeInNanos: number = 0;
    private domain: number = 0;
    private deletedFeedIds: any[] | null = null;
    private sngJPWinners: any[] | null = null;
    private isFullUpdate: boolean | null = false;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        lastSnapShotTimeInNanos: number = 0,
        domain: number = 0,
        deletedFeedIds: any[] | null = null,
        sngJPWinners: any[] | null = null,
        isFullUpdate: boolean | null = false,
        requestType: number = 0,
    ) {
        super(reqServerPeerId);
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
        this.domain = domain;
        this.deletedFeedIds = deletedFeedIds;
        this.sngJPWinners = sngJPWinners;
        this.isFullUpdate = isFullUpdate;
        this.requestType = requestType;
    }

    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getDeletedFeedIds(): any[] | null {
        return this.deletedFeedIds;
    }

    setDeletedFeedIds(deletedFeedIds: any[] | null) {
        this.deletedFeedIds = deletedFeedIds;
    }
    getSngJPWinners(): any[] | null {
        return this.sngJPWinners;
    }

    setSngJPWinners(sngJPWinners: any[] | null) {
        this.sngJPWinners = sngJPWinners;
    }
    getIsFullUpdate(): boolean | null {
        return this.isFullUpdate;
    }

    setIsFullUpdate(isFullUpdate: boolean | null) {
        this.isFullUpdate = isFullUpdate;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
