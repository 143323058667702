import { Message } from '../../message';

export class PromoUpdateTicker extends Message {
    private MESSAGE_NAME: string = 'PromoUpdateTicker';
    private handCounter: number = 0;
    private amount: number = 0;
    private countDownHands: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, handCounter: number = 0, amount: number = 0, countDownHands: number = 0) {
        super(reqServerPeerId);
        this.handCounter = handCounter;
        this.amount = amount;
        this.countDownHands = countDownHands;
    }

    getHandCounter(): number {
        return this.handCounter;
    }

    setHandCounter(handCounter: number) {
        this.handCounter = handCounter;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getCountDownHands(): number {
        return this.countDownHands;
    }

    setCountDownHands(countDownHands: number) {
        this.countDownHands = countDownHands;
    }
}
