import { Message } from '../../message';

export class ATHAutoSitOutMsg extends Message {
    private MESSAGE_NAME: string = 'ATHAutoSitOutMsg';
    private errorMessage: any | null = null;
    private athBlockTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, errorMessage: any | null = null, athBlockTime: number = 0) {
        super(reqServerPeerId);
        this.errorMessage = errorMessage;
        this.athBlockTime = athBlockTime;
    }

    getErrorMessage(): any | null {
        return this.errorMessage;
    }

    setErrorMessage(errorMessage: any | null) {
        this.errorMessage = errorMessage;
    }
    getAthBlockTime(): number {
        return this.athBlockTime;
    }

    setAthBlockTime(athBlockTime: number) {
        this.athBlockTime = athBlockTime;
    }
}
