<div class="pk-widget-main pb-5" [ngClass]="widgetsConfig?.label">
    <div class="row justify-content-between align-items-center pb-sm-4 pb-1 pt-4 mt-3">
        <div class="col-12 col-sm-6">
            <h1 class="schedule-tournaments-title mt-0 mb-0">{{ widgetsConfig?.pageTitle }}</h1>
        </div>
        <div class="col-sm-4 text-right">
            <div class="mat-table-filter-wrap">
                <input
                    type="text"
                    (ngModelChange)="applySearchFilter($event)"
                    [(ngModel)]="searchFilterValue"
                    class="form-filter-control text-capitalize"
                    [placeholder]="widgetsConfig.statusTimerStrings.search" />
                <button class="btn btn-sm clear-btn btn-circle bg-light" *ngIf="searchFilterValue" (click)="clearSearchInput()">
                    <span class="theme-ex text-dark"></span>
                </button>
            </div>
        </div>
    </div>

    <div class="pk-tournament-widgets pb-4" [ngClass]="item?.cardClass" *ngIf="activePageDataChunk">
        <ng-container *ngFor="let tournament of activePageDataChunk">
            <div class="tournament-widget" *ngIf="tournament">
                <div class="card-body" [ngClass]="{ 'size-md': tournament?.name?.length > 40, 'size-sm': tournament?.name?.length > 70 }">
                    <div class="card-front">
                        <div class="section" [ngStyle]="{ 'background-image': 'url(' + (tournament?.tournamentImageUrl || defaultCover) + ')' }">
                            <div class="logo"></div>
                            <div class="widgetContent">
                                <span class="time">{{ formatDate(tournament?.startDate) }}</span>
                                <span class="title"> {{ tournament?.name }}</span>
                                <span class="status">
                                    <pt-calendar-countdown-timer
                                        [timerStrings]="widgetsConfig?.statusTimerStrings"
                                        [status]="tournament?.status"
                                        [lateRegDate]="tournament.lateDate"
                                        [endTime]="tournament?.countdownDate"></pt-calendar-countdown-timer>
                                </span>
                            </div>
                        </div>
                        <div class="meta">
                            <span class="theme-account-secondary"> {{ tournament?.regPlayersCount }}/{{ tournament?.maxPlayers }}</span>
                            <span class="theme-coupon"> {{ tournament?.buyIn | formatDecimal: currencyRePosition }} </span>
                            <span class="theme-jackpotslots"> {{ tournament?.prizePool | formatPrizepool: widgetsConfig?.currencyRePosition }} </span>
                        </div>
                    </div>
                    <div class="card-back">
                        <div class="top-content">
                            <div class="logo"></div>
                            <div class="status">
                                <p>{{ tournament?.tournamentStatus }}</p>
                            </div>
                        </div>
                        <h2>
                            <span>{{ tournament?.name }}</span>
                        </h2>
                        <div class="backContent">
                            <span class="gtd">
                                <small class="d-block mb-2">{{ widgetsConfig?.prizepool }}</small>
                                {{ tournament?.prizePool | formatPrizepool: widgetsConfig?.currencyRePosition }}</span
                            >
                            <div class="tournament-info">
                                <div class="players">
                                    <h5>{{ widgetsConfig?.players }}</h5>
                                    <span class="theme-account-secondary"> {{ tournament?.regPlayersCount }}/{{ tournament?.maxPlayers }} </span>
                                </div>
                                <div class="buy-in">
                                    <h5>{{ widgetsConfig?.buyin }}</h5>
                                    <span class="theme-coupon"> {{ tournament?.buyIn | formatDecimal: widgetsConfig?.currencyRePosition }} </span>
                                </div>
                            </div>
                            <a (click)="openClient(tournament)" class="btn btn-primary">{{ widgetsConfig?.ctaText }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="message-panel message-panel-dark-cust text-center mb-3" *ngIf="!tournaments || activePageDataChunk?.length === 0">
        <div *ngIf="!searchFilterValue">{{ widgetsConfig?.noDataMsg }}</div>
        <div *ngIf="searchFilterValue">{{ widgetsConfig?.noSearchResultsFound }}</div>
    </div>
    <mat-paginator
        [length]="pageLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChanged($event)"
        aria-label="Select page"
        showFirstLastButtons>
    </mat-paginator>
</div>
