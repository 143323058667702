import { Message } from '../../message';

export class PlayerGameSessionReminder extends Message {
    private MESSAGE_NAME: string = 'PlayerGameSessionReminder';
    private playerState: number = 0;
    private sessionLength: number = 0;
    private sessionResult: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerState: number = 0, sessionLength: number = 0, sessionResult: number = 0) {
        super(reqServerPeerId);
        this.playerState = playerState;
        this.sessionLength = sessionLength;
        this.sessionResult = sessionResult;
    }

    getPlayerState(): number {
        return this.playerState;
    }

    setPlayerState(playerState: number) {
        this.playerState = playerState;
    }
    getSessionLength(): number {
        return this.sessionLength;
    }

    setSessionLength(sessionLength: number) {
        this.sessionLength = sessionLength;
    }
    getSessionResult(): number {
        return this.sessionResult;
    }

    setSessionResult(sessionResult: number) {
        this.sessionResult = sessionResult;
    }
}
