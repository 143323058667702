import { Message } from '../../message';
import { UserProfile } from '../../pgSharedMessageFactory/messages/UserProfile';

export class LoginResponse extends Message {
    private MESSAGE_NAME: string = 'LoginResponse';
    private responseId: number = 0;
    private userProfile: UserProfile | null = null;
    private isRealMoneyAccountActivated: boolean | null = false;
    private desc: any | null = null;
    private keyM1: string | null = null;
    private accountName: string | null = null;
    private failedLoginAttemptCount: number = 0;
    private showCaptcha: boolean | null = false;
    private conversationId: number = 0;
    private screenName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        responseId: number = 0,
        userProfile: UserProfile | null = null,
        isRealMoneyAccountActivated: boolean | null = false,
        desc: any | null = null,
        keyM1: string | null = null,
        accountName: string | null = null,
        failedLoginAttemptCount: number = 0,
        showCaptcha: boolean | null = false,
        conversationId: number = 0,
        screenName: string | null = null,
    ) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.userProfile = userProfile;
        this.isRealMoneyAccountActivated = isRealMoneyAccountActivated;
        this.desc = desc;
        this.keyM1 = keyM1;
        this.accountName = accountName;
        this.failedLoginAttemptCount = failedLoginAttemptCount;
        this.showCaptcha = showCaptcha;
        this.conversationId = conversationId;
        this.screenName = screenName;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getUserProfile(): UserProfile | null {
        return this.userProfile;
    }

    setUserProfile(userProfile: UserProfile | null) {
        this.userProfile = userProfile;
    }
    getIsRealMoneyAccountActivated(): boolean | null {
        return this.isRealMoneyAccountActivated;
    }

    setIsRealMoneyAccountActivated(isRealMoneyAccountActivated: boolean | null) {
        this.isRealMoneyAccountActivated = isRealMoneyAccountActivated;
    }
    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
    getKeyM1(): string | null {
        return this.keyM1;
    }

    setKeyM1(keyM1: string | null) {
        this.keyM1 = keyM1;
    }
    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getFailedLoginAttemptCount(): number {
        return this.failedLoginAttemptCount;
    }

    setFailedLoginAttemptCount(failedLoginAttemptCount: number) {
        this.failedLoginAttemptCount = failedLoginAttemptCount;
    }
    getShowCaptcha(): boolean | null {
        return this.showCaptcha;
    }

    setShowCaptcha(showCaptcha: boolean | null) {
        this.showCaptcha = showCaptcha;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
}
