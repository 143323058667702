// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class BottomPanelView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor() {
        super();
    }
    createView = function (EventDispatcher, gameService) {
        this.gameService = gameService;
        this.currTablePeerId = this.gameService.peerId;
        this.dragMove = false;
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        this.isATHEnabled = CommonGameProperties.dynaconConfigs['webClientConfigs']['enableATH'];
        this.handsDealtText;
        // ResponsiveContainer.call(this, {
        //   portX: 0,
        //   portY: 0,
        //   portAlignY: "BOTTOM",
        //   EventDispatcher: this.EventDispatcher
        // });
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait;
        this.buttons = this.positionConfig.bottomButtons;
        this.bottomBtns = this.buttons.buttonsList;
        this.buttonsContainer = this.displayUtil.getContainer();
        this.addChild(this.buttonsContainer);
        this.createHandsPlayedCount();
        this.createBottomButtons();
        this.displayUtil.setObjectPosition(this.buttonsContainer, 0, 0);
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_PLAYED_GAMES_COUNT, this.UpdatePlayedGamesCount.bind(this));
        //this.setSize(720, 1280);
        // this.checkPos();
    };
    createHandsPlayedCount = function () {
        if (
            this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
            this.gameService.getMaxNoOfSeats() > 2 &&
            this.isATHEnabled == true &&
            this.gameService.gameStateModel.tableStatus.athApplicable == true
        ) {
            this.handsDealtText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_HANDSPLAYED');
            if (this.handsDealtText.length > 11) {
                this.handsDealtText = this.handsDealtText.slice(0, 8) + '...';
            }
            let handsPlayedStyle;
            if (this.getMobileOperatingSystem() == 'Android') {
                handsPlayedStyle = this.fontProps.handsPlayedTextAndroid;
            } else {
                handsPlayedStyle = this.fontProps.handsPlayedTextIOS;
            }
            //Anti table hopping
            this.handsPlayedText = this.displayUtil.getTextField(handsPlayedStyle);
            this.handsPlayedText.text = this.handsDealtText + '(0)';
            this.handsPlayedText.resolution = window.devicePixelRatio;
            //this.displayUtil.setObjectPosition(this.handsPlayedText, 6, (window.innerHeight * 1.5) - (this.handsPlayedText.height + 10));
            this.addChild(this.handsPlayedText);
            //  this.handsPlayedCount = this.displayUtil.getTextField(handsPlayedStyle);
            //  this.handsPlayedCount.text = "(0)";
            //  this.handsPlayedCount.resolution = window.devicePixelRatio;
            //  this.displayUtil.setObjectPosition(this.handsPlayedCount, 7 + this.handsPlayedText.width, window.innerHeight - (this.handsPlayedText.height + 5));
            //   this.addChild(this.handsPlayedCount);
        }
    };
    getMobileOperatingSystem = function () {
        const userAgent = navigator.userAgent || navigator.vendor;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod|AppleWebKit/.test(userAgent)) {
            return 'iOS';
        }

        return 'unknown';
    };
    UpdatePlayedGamesCount = function (data) {
        this.handsPlayedText.text = this.handsDealtText + '(' + data.detail.count + ')';
    };
    checkPos = function () {
        //this.buttonsContainer.scale.set(scaleXValue);
        this.displayUtil.setObjectPosition(this.buttonsContainer, 0, 0);
        //this.buttonsContainer.width = uiConfig.windowWidth;
        //this.buttonsContainer.scale.y = this.buttonsContainer.scale.x;
    };
    createBottomButtons = function () {
        const currenttTableId = 'table' + this.gameService.getPeerId();
        for (const i in this.bottomBtns) {
            this[this.bottomBtns[i]] = this.displayUtil.getButton(this.bottomBtns[i]);
            this.buttonsContainer.addChild(this[this.bottomBtns[i]]);
            // let padX = 0;
            // let padY = 0;
            // if (uiConfig.windowWidth > 400) {
            this[this.bottomBtns[i]].scale.set(0.93);
            switch (i) {
                case '0':
                    // padX = -35;
                    // padY = -10;
                    break;
                case '1':
                    // padX = -20;
                    // padY = -10;
                    break;
                case '2':
                    // padX = -10;
                    // padY = -10;
                    break;
                case '3':
                    this[this.bottomBtns[i]].scale.set(0.8);
                    // padX = 18;
                    // padY = 0;
                    break;
                case '4':
                    // padX = 0;
                    // padY = -10;
                    break;
            }
            // }
            // this.displayUtil.setObjectPosition(this[this.bottomBtns[i]],
            //this.positionConfig.bottomButtons[this.bottomBtns[i] + "Pos"].x,
            //  this.positionConfig.bottomButtons[this.bottomBtns[i] + "Pos"].y);
            this.displayUtil.addInteraction(this[this.bottomBtns[i]], this[this.bottomBtns[i] + 'BtnClick'].bind(this));
            this.displayUtil.setClickable(this[this.bottomBtns[i]], true);
            this[this.bottomBtns[i]].visible = true;
            if (CommonGameProperties.facade[currenttTableId].scale.x == 1) {
                if (this[this.bottomBtns[i]] == this['tileMaxview']) {
                    this['tileMaxview'].visible = false;
                }
            }
            if (CommonGameProperties.facade[currenttTableId].scale.x == 0.5) {
                if (this[this.bottomBtns[i]] == this['multitile']) {
                    this['multitile'].visible = false;
                }
            }
            if (this[this.bottomBtns[i]] == this['infoicon']) {
                ResponsiveContainer.call(this[this.bottomBtns[i]], {
                    portX: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].x,
                    portY: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].y,
                    portAlignX: 'LEFT',
                    EventDispatcher: this.EventDispatcher,
                });
                if (
                    this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
                    this.gameService.getMaxNoOfSeats() > 2 &&
                    this.isATHEnabled == true &&
                    this.gameService.gameStateModel.tableStatus.athApplicable == true
                ) {
                    ResponsiveContainer.call(this.handsPlayedText, {
                        portX: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].x - 20,
                        portY: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].y + 80,
                        portAlignX: 'LEFT',
                        EventDispatcher: this.EventDispatcher,
                    });
                }
            } else if (this[this.bottomBtns[i]] == this['emojiicon']) {
                ResponsiveContainer.call(this[this.bottomBtns[i]], {
                    portX: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].x,
                    portY: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].y,
                    portAlignX: 'CENTER',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (this[this.bottomBtns[i]] == this['replayericon']) {
                ResponsiveContainer.call(this[this.bottomBtns[i]], {
                    portX: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].x,
                    portY: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].y,
                    portAlignX: 'CENTER',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (this[this.bottomBtns[i]] == this['tileMaxview']) {
                ResponsiveContainer.call(this[this.bottomBtns[i]], {
                    portX: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].x,
                    portY: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].y,
                    portAlignX: 'RIGHT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else {
                //for multitile
                ResponsiveContainer.call(this[this.bottomBtns[i]], {
                    portX: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].x,
                    portY: this.positionConfig.bottomButtons[this.bottomBtns[i] + 'Pos'].y,
                    portAlignX: 'RIGHT',
                    EventDispatcher: this.EventDispatcher,
                });
            }
        }
    };
    infoiconBtnClick = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.INFO_BUTTON_CLICKED);
    };
    emojiiconBtnClick = function () {
        if (CommonGameProperties.secondaryAssetsLoaded) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.EMOJI_BUTTON_CLICKED);
        }
    };
    replayericonBtnClick = function () {
        if (
            this.gameService.getCurrentGameId() !== null &&
            this.gameService.getPrevHandId() !== undefined &&
            this.gameService.getPrevHandId() !== 0
        ) {
            const currenttable = 'table' + this.gameService.getPeerId();
            this.tablePositions = {
                x: [0, window.innerWidth / 2, 0, window.innerWidth / 2],
                y: [0, 0, window.innerHeight / 2, window.innerHeight / 2],
            };
            //let height = "calc(100% - 60px)", width = "100%";
            let posX, posY, minHeight, minWidth, tableindex;
            if (CommonGameProperties.facade[currenttable].scale.x == 0.5) {
                tableindex = CommonGameProperties.facade['activeTables'].indexOf(this.gameService.getPeerId());
                posX = this.tablePositions.x[tableindex] + 'px';
                posY = this.tablePositions.y[tableindex] + 'px';
                minHeight = window.innerHeight / 2 + 'px';
                minWidth = window.innerWidth / 2 + 2 + 'px';
            }
            const replayerURL = this.createReplayerUrl();
            if (replayerURL) {
                let parentContainer = document.getElementById('poker-pwc-client-wrap');
                if (parentContainer != null) {
                    // start loader
                    this.loaderframe = document.createElement('img');
                    this.loaderframe.setAttribute('src', CommonGameProperties.webAssets['game-table']['common']['replayerloader']['image']['src']);
                    //this.loaderframe.setAttribute("style","position: absolute;z-index: 100;left: 50%;top: 50%;");
                    //this.loaderframe.setAttribute("style","position: absolute;z-index: 100;left: calc(50% - 80px);top: calc(50% - 80px);");
                    this.loaderframe.setAttribute('style', 'position: absolute;z-index: 100;');
                    this.loaderframe.setAttribute('id', 'loaderframe' + currenttable); // assign an id
                    this.loaderframe.width = 100;
                    this.loaderframe.height = 100;
                    if (CommonGameProperties.facade[currenttable].scale.x == 0.5) {
                        this.loaderframe.style.left = this.tablePositions.x[tableindex] + window.innerWidth / 4 - this.loaderframe.width / 2 + 'px';
                        this.loaderframe.style.top = this.tablePositions.y[tableindex] + window.innerHeight / 4 - this.loaderframe.height / 2 + 'px';
                    } else {
                        this.loaderframe.style.left = window.innerWidth / 2 - this.loaderframe.width / 2 + 'px';
                        this.loaderframe.style.top = window.innerHeight / 2 - this.loaderframe.height / 2 + 'px';
                    }
                    parentContainer.appendChild(this.loaderframe);
                    // end loader
                    this.replayerIframe = document.createElement('iframe');
                    this.replayerIframe.setAttribute('id', 'replayerIframe' + currenttable); // assign an id
                    // assign url
                    this.replayerIframe.setAttribute('src', replayerURL);
                    parentContainer.appendChild(this.replayerIframe); // to place at end of document
                    this.closereplayerIframe = document.createElement('img');
                    this.closereplayerIframe.setAttribute(
                        'src',
                        CommonGameProperties.webAssets['game-table']['common']['replayerclose']['image']['src'],
                    );
                    this.closereplayerIframe.setAttribute('id', 'closeReplayer' + currenttable);
                    if (CommonGameProperties.facade[currenttable].scale.x == 0.5) {
                        this.replayerIframe.setAttribute('style', 'position: absolute;border:0;top: 60px;z-index:2;background-color:#000000');
                        this.replayerIframe.style.height = minHeight;
                        this.replayerIframe.style.width = minWidth;
                        this.replayerIframe.style.left = posX;
                        this.replayerIframe.style.top = posY;
                        this.closereplayerIframe.setAttribute('style', 'display: block; position: absolute;top: 60px;width: 5%;height: 3%;z-index:3');
                        const btnLeft = this.tablePositions.x[tableindex] + window.innerWidth / 2 - (window.innerWidth / 2) * (10 / 100) + 'px';
                        const btnTop = this.tablePositions.y[tableindex] + 'px';
                        this.closereplayerIframe.style.left = btnLeft;
                        this.closereplayerIframe.style.top = btnTop;
                    } else {
                        this.replayerIframe.setAttribute(
                            'style',
                            'position: absolute;border:0;top: 60px;left: 0;height:calc(100% - 60px);width:100%;z-index:2;background-color:#000000',
                        );
                        this.closereplayerIframe.setAttribute(
                            'style',
                            'display: block; position: absolute;top: 60px;width: 10%;height: 6%;right:0;z-index:3',
                        );
                    }
                    parentContainer.appendChild(this.closereplayerIframe);
                    this.closereplayerIframe.onclick = function () {
                        const tableid = this.id.split('closeReplayer')[1];
                        document.getElementById('replayerIframe' + tableid)?.remove();
                        document.getElementById('closeReplayer' + tableid)?.remove();
                        if (document.getElementById('loaderframe' + tableid) != null) {
                            document.getElementById('loaderframe' + tableid)?.remove();
                        }
                    };
                    const replayerIframe = document.getElementById('replayerIframe' + currenttable);
                    if (replayerIframe != null) {
                        replayerIframe.onload = function () {
                            const tableid = this['id'].split('replayerIframe')[1];
                            document.getElementById('loaderframe' + tableid)?.remove();
                            // self.closereplayerIframe.setAttribute(
                            //    "style",
                            //     "display: block; position: absolute;top: 60px;width: 10%;height: 6%;right:0%;z-index:50"
                            // );
                        };
                    }
                }
            }
        }
    };

    createReplayerUrl = function () {
        let handId: any, sessionKey: any, tableId: any, gametype: any, poolid: any, feid: any, brandid: any;
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            // for spins
            handId = this.gameService.getPrevHandId();
            sessionKey = CommonGameProperties.ssokey['key'];
            tableId = this.gameService.gameInfoModel.getMtctId();
            gametype = 3;
            poolid = 0;
            feid = 'pp';
            brandid = 'PARTYPOKER';
            //mtctId =  this.gameService.gameInfoModel.getMtctId();
        } else if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            // for ff
            handId = this.gameService.getPrevHandId();
            sessionKey = CommonGameProperties.ssokey['key'];
            tableId = this.gameService.getPeerId();
            gametype = 0;
            //poolid = facade.getPoolIdForEntry(tableId);
            feid = 'pp';
            brandid = 'PARTYPOKER';
            //mtctId =  this.gameService.gameInfoModel.getMtctId();
        } else {
            // for cash games
            handId = this.gameService.getPrevHandId();
            sessionKey = CommonGameProperties.ssokey['key'];
            tableId = this.gameService.getPeerId();
            gametype = 0;
            poolid = 0;
            feid = 'pp';
            brandid = 'PARTYPOKER';
            //mtctId =  this.gameService.gameInfoModel.getMtctId();
        }
        let replayerURL =
            CommonGameProperties.dynaconConfigs['webClientConfigs']['replayerConfigurations']['host'] +
            '/?handId=$HAND_ID$&_sso=$SSO_KEY$&tableId=$TABLE_ID$&gType=$GAME_TYPE$&pooId=$POOL_ID$&feId=$FRONTEND_ID$&brandId=$BRAND_ID$&ENABLE_ZOOM_IN=TRUE';
        replayerURL = replayerURL.replace('$HAND_ID$', handId);
        replayerURL = replayerURL.replace('$TABLE_ID$', tableId);
        replayerURL = replayerURL.replace('$GAME_TYPE$', gametype);
        replayerURL = replayerURL.replace('$POOL_ID$', poolid);
        replayerURL = replayerURL.replace('$FRONTEND_ID$', feid);
        replayerURL = replayerURL.replace('$BRAND_ID$', brandid);
        replayerURL = replayerURL.replace('$SSO_KEY$', sessionKey);
        return replayerURL;
    };
    tileViewExpoSpinAnimation = function () {
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            this.tablePositions = {
                x: [0, uiConfig.windowWidth / 2, 0, uiConfig.windowWidth / 2],
                y: [0, 0, uiConfig.windowHeight / 2, uiConfig.windowHeight / 2],
            };
            this.activeTables = CommonGameProperties.facade['activeTables'];
            for (let i = 0; i < this.activeTables.length; i++) {
                const iframeId = this.getActiveIframeId(this.activeTables[i]);
                if (CommonGameProperties.expoAnime[iframeId]?.expoSpinAnime) {
                    const tableindex = CommonGameProperties.facade['activeTables'].indexOf(this.activeTables[i]);
                    const posX = this.tablePositions.x[tableindex] + 'px',
                        posY = this.tablePositions.y[tableindex] + 'px',
                        // minHeight = uiConfig.windowHeight / 2 + "px",
                        // minWidth = uiConfig.windowWidth / 2 + "px";
                        minHeight = window.innerHeight / 2 + 'px', //uiConfig.windowHeight / 2 + "px",
                        minWidth = window.innerWidth / 2 + 'px'; //uiConfig.windowWidth / 2 + "px";
                    const nativeElement = CommonGameProperties.expoAnime[iframeId].expoSpinAnime.dynamicContentContainer.nativeElement as HTMLElement;

                    // Set the desired CSS properties directly
                    nativeElement.style.position = 'absolute';
                    nativeElement.style.border = '0';
                    nativeElement.style.top = '60px';
                    nativeElement.style.zIndex = '1';
                    nativeElement.style.backgroundColor = '#000000';

                    // Additional properties for expoAnimIframe
                    const expoAnimIframe = CommonGameProperties.expoAnime[iframeId].expoSpinAnime.dynamicContentContainer
                        .nativeElement as HTMLElement;
                    expoAnimIframe.style.display = 'none';
                    expoAnimIframe.style.height = minHeight; // Assuming minHeight is defined elsewhere
                    expoAnimIframe.style.width = minWidth; // Assuming minWidth is defined elsewhere
                    expoAnimIframe.style.left = posX; // Assuming posX is defined elsewhere
                    expoAnimIframe.style.top = posY;
                }
            }
        }
    };
    getActiveIframeId(peerId) {
        let activeIframe;
        for (let i = 0; i < 4; i++) {
            // var iframe = document.getElementById("expoSpinIframe"+i);
            if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == peerId) {
                activeIframe = i;
                break;
            }
        }
        return activeIframe;
    }
    fullScreenViewExpoSpinAnimation = function () {
        this.activeTables = CommonGameProperties.facade['activeTables']; //CommonGameProperties.facade;//["activeTables"];
        for (let i = 0; i < this.activeTables.length; i++) {
            if (this.activeTables[i] == this.gameService.getPeerId()) {
                CommonGameProperties.facade['expoFullScreen'] = true;
                CommonGameProperties.facade['showExpoSpinAnimation'](this.gameService.getPeerId());
            }
        }
    };
    multitileBtnClick = function () {
        if (this['multitile']) {
            this['multitile'].visible = false;
            this['tileMaxview'].visible = true;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.RESIZE_TABLE_VIEW);
        this.tileViewExpoSpinAnimation();
    };
    tileMaxviewBtnClick = function () {
        CommonGameProperties.facade['removeReplayers'](); //remove tileview tables replayers when any table is maximized
        if (this['tileMaxview']) {
            this['tileMaxview'].visible = false;
            this['multitile'].visible = true;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.MAX_BUTTON_CLICKED);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.RESIZE_TABLE_VIEW);
        this.fullScreenViewExpoSpinAnimation();
    };
    displayMinBtn = function () {
        if (this['tileMaxview']) {
            this['multitile'].visible = true;
            this['tileMaxview'].visible = false;
        }
    };
    displayMaxBtn = function () {
        if (this['multitile']) {
            this['tileMaxview'].visible = true;
            this['multitile'].visible = false;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.STOP_SOUNDS);
    };
}
