import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { TournamentCalendarService } from './tournamentcalendar.service';

@Injectable({ providedIn: 'root' })
export class TournamentCalendarResolver implements Resolve<any> {
    constructor(private resource: TournamentCalendarService) {}

    resolve(): Observable<any> {
        return this.resource.getData();
    }
}
