import { Message } from '../../message';

export class DomainChangeAdvice extends Message {
    private MESSAGE_NAME: string = 'DomainChangeAdvice';
    private domainName: string | null = null;
    private validityMode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, domainName: string | null = null, validityMode: number = 0) {
        super(reqServerPeerId);
        this.domainName = domainName;
        this.validityMode = validityMode;
    }

    getDomainName(): string | null {
        return this.domainName;
    }

    setDomainName(domainName: string | null) {
        this.domainName = domainName;
    }
    getValidityMode(): number {
        return this.validityMode;
    }

    setValidityMode(validityMode: number) {
        this.validityMode = validityMode;
    }
}
