import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { DeviceService, NavigationService } from '@frontend/vanilla/core';
//import { PokerApiService } from '../../api/api.service';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { PokerApiService, TournamentsCalendarClientConfig } from '@pokercore/module/core';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { limitNamePipe } from '../homepage-pipes/LimitName.pipe';
import { removeColorCodesPipe } from '../homepage-pipes/RemoveColorCodes.pipe';
import { formatCurrencyPipe } from '../homepage-pipes/formatCurrency.pipe';
import { TournamentCalendarTimerComponent } from '../tournament-calendar-timer/tournament-calendar-timer.component';

@Component({
    selector: 'pt-homepage-tournament-calendar',
    templateUrl: 'homepage-tournament-calendar.component.html',
    standalone: true,
    imports: [CommonModule, TournamentCalendarTimerComponent, removeColorCodesPipe, limitNamePipe, formatCurrencyPipe, SwiperComponent],
})
export class HomePageTournamentCalendarComponent implements OnInit {
    tournamentList: Array<any>;
    arrows: boolean = true;
    dots: boolean = true;
    loop: boolean = true;
    isTouch: boolean;
    interval: number = 5000;
    slidesPerView: number;
    moreinfo: any;
    timeLeft: string;
    currentTime: Date;
    clickId: number;
    clicked: boolean = false;
    summaryView: boolean = true;
    googleCalendarHost: string;
    yahooCalendarHost: string;
    msnCalendarHost: string;
    previousValue: number;
    public swiperOptions: SwiperOptions;

    constructor(
        private api: PokerApiService,
        private tournamentsCalendarClientConfig: TournamentsCalendarClientConfig,
        private deviceService: DeviceService,
        private navigation: NavigationService,
    ) {
        this.swiperOptions = {
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: this.deviceService.isMobile ? 1 : window.innerWidth > 1200 ? 3 : 1,
        };
    }

    // public swiperOptions : SwiperOptions = {
    //     modules:[Navigation],
    //     navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //     },
    //     slidesPerView: this.deviceService.isMobile ? 1 : (window.innerWidth > 1200 ? 3 : 1),
    // }
    //   this.cashierService.whenReady.subscribe(() => {
    //     this.cashierService.goToCashierDeposit(this.cashierOptions);
    // });

    ngOnInit() {
        if (this.deviceService.isMobile) {
            this.slidesPerView = 1;
        } else if (window.innerWidth > 1200) {
            this.slidesPerView = 3;
        }
        this.isTouch = this.deviceService.isTouch;

        this.tournamentsCalendarClientConfig.whenReady.subscribe(() => {
            this.googleCalendarHost = this.tournamentsCalendarClientConfig.googleCalendarHost;
            this.yahooCalendarHost = this.tournamentsCalendarClientConfig.yahooCalendarHost;
            this.msnCalendarHost = this.tournamentsCalendarClientConfig.msnCalendarHost;
        });

        this.api.get('Tournament/GetData').subscribe(
            (response: any) => {
                this.moreinfo = response.pmPokerNavigationView;
                this.tournamentList = response.pagedTournamentItemsList;
            },
            (error: any) => {
                // eslint-disable-next-line no-console
                console.log(error);
            },
        );
    }
    handleTournamentClick(id: number, navigate: boolean) {
        if (this.summaryView || navigate) {
            this.navigation.goTo('/tournaments/calendar/' + id);
        } else {
            this.showDetails(id);
        }
    }
    showDetails(id: number) {
        this.clicked = this.clickId && this.clickId === id ? !this.clicked : true;
        this.clickId = id;
    }
    showMenu(event: any, id: string, i: number) {
        if (this.previousValue || this.previousValue == 0) {
            (document.getElementById(id + this.previousValue) as HTMLElement).style.display = 'none';
            (document.getElementById('add-to-cal' + this.previousValue) as HTMLElement).style.backgroundColor = '#fff';
            (document.getElementById('add-to-cal-mob' + this.previousValue) as HTMLElement).style.backgroundColor = '#fff';
        }
        this.previousValue = i;
        (document.getElementById(id + i) as HTMLElement).style.display = 'block';
        (document.getElementById('add-to-cal' + i) as HTMLElement).style.backgroundColor = '#e6e6e6';
        (document.getElementById('add-to-cal-mob' + i) as HTMLElement).style.backgroundColor = '#e6e6e6';
    }
    closeMenu(event: any, id: string, i: number) {
        (document.getElementById(id + i) as HTMLElement).style.display = 'none';
        (document.getElementById('add-to-cal' + i) as HTMLElement).style.backgroundColor = '#fff';
        (document.getElementById('add-to-cal-mob' + i) as HTMLElement).style.backgroundColor = '#fff';
    }
}
