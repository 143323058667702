import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pph from './pp-promo-header.component';

@Injectable({ providedIn: 'root' })
export class PPPromoHeaderPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('pppromoheader', pph.PPPromoHeaderComponent);
        });
    }
}
