<pwc-sub-lobby-header
    [isWidgetShow]="null"
    [gameTypes]="gameTypes"
    [tablesLength]="sngjpcards && sngjpcards.length"
    [initialGridViewType]="gridView"
    (isGridViewType)="isItGridViewType($event)"
    (viewInfoPopup)="openInfoPopup($event)"
    [cardOrderType]="cardOrderBy"
    (sortTable)="sortTables($event)"
    (viewFilterPopup)="openFilterPopup($event)"
    (updateGameType)="updateGameType($event)"></pwc-sub-lobby-header>
<div id="spins-lobby-main">
    <div class="spins-lobby-wrap spins-overdrive-wrap h-100">
        <div class="spins-lobby-content" #gridContainer *ngIf="sngjpcards && sngjpcards.length > 0; else NoTables">
            <ng-container *ngIf="gridView">
                <div
                    class="spins-tile card-{{ index }}"
                    *ngFor="let card of sngjpcards; let index = index"
                    [attr.index]="index"
                    (click)="onCardSelect(index)">
                    <div
                        class="initialSlideImg"
                        [ngClass]="{
                            showElement: index === currentCardSelectedIndex,
                            hideElement: index !== currentCardSelectedIndex
                        }">
                        <img *ngIf="card.imageId === 'goldCard'" src="{{ carouselGoldSelected.image.src }}" alt="{{ carouselGoldSelected.title }}" />
                        <img *ngIf="card.imageId !== 'goldCard'" src="{{ activeNormalSelected.image.src }}" alt="{{ activeNormalSelected.title }}" />
                    </div>
                    <div
                        class="initialSlideImg"
                        [ngClass]="{
                            showElement: index !== currentCardSelectedIndex,
                            hideElement: index === currentCardSelectedIndex
                        }">
                        <span class="spins-tile-bg">
                            <img *ngIf="card.imageId === 'goldCard'" src="{{ carouselGoldAsset.image.src }}" alt="{{ carouselGoldAsset.title }}" />
                            <img *ngIf="card.imageId !== 'goldCard'" src="{{ nonActiveNormal.image.src }}" alt="{{ nonActiveNormal.title }}" />
                        </span>
                    </div>
                    <div class="initialSlideText">
                        <span
                            class="spins-title"
                            [ngClass]="{
                                whitecard_text:
                                    card.payOutType === 0 &&
                                    card.buyInType === 1 &&
                                    (card.imageId !== 'goldCard' || index === currentCardSelectedIndex),
                                blackcard_text: card.imageId === 'goldCard' && index !== currentCardSelectedIndex
                            }">
                            {{ card.slide.gameType }}
                        </span>
                        <div class="spins-win">
                            <span
                                class="s-win-buyin"
                                [ngClass]="{
                                    whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                    blackcard_text: card.imageId === 'goldCard'
                                }">
                                {{ card.slide.text3 | formatCurrencyPipe: 'USD' }}
                            </span>
                            <span
                                class="s-win-label"
                                [ngClass]="{
                                    whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                    blackcard_text: card.imageId === 'goldCard'
                                }"
                                >{{ 'PARTY_POKER_GC_GAMELOBBY_SNGJP_PCARD_WINUPTO' | translate: [card.slide.textTitle] }}</span
                            >
                            <span
                                class="s-win-amount"
                                [ngClass]="{
                                    whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                    blackcard_text: card.imageId === 'goldCard'
                                }">
                                {{ card.slide.text1 * 100 | FormatamountPipe: 'USD' }}</span
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!gridView">
                <div class="swiper-tile-view d-flex align-items-center">
                    <div class="w-100">
                        <vn-swiper class="swiper" #SwiperComponent [swiperOptions]="lobbySwaiperConfig">
                            <div
                                class="spins-tile swiper-slide"
                                *ngFor="let card of sngjpcards; let index = index"
                                [attr.index]="index"
                                (click)="onCardSelect(index)">
                                <div
                                    class="initialSlideImg"
                                    [ngClass]="{
                                        showElement: index === currentCardSelectedIndex,
                                        hideElement: index !== currentCardSelectedIndex
                                    }">
                                    <img
                                        *ngIf="card.imageId === 'goldCard'"
                                        src="{{ carouselGoldAsset.image.src }}"
                                        alt="{{ carouselGoldAsset.title }}" />
                                    <img
                                        *ngIf="card.imageId !== 'goldCard'"
                                        src="{{ nonActiveNormal.image.src }}"
                                        alt="{{ nonActiveNormal.title }}" />
                                </div>
                                <div
                                    class="initialSlideImg"
                                    [ngClass]="{
                                        showElement: index !== currentCardSelectedIndex,
                                        hideElement: index === currentCardSelectedIndex
                                    }">
                                    <span class="spins-tile-bg">
                                        <img
                                            *ngIf="card.imageId === 'goldCard'"
                                            src="{{ carouselGoldAsset.image.src }}"
                                            alt="{{ carouselGoldAsset.title }}" />
                                        <img
                                            *ngIf="card.imageId !== 'goldCard'"
                                            src="{{ nonActiveNormal.image.src }}"
                                            alt="{{ nonActiveNormal.title }}" />
                                    </span>
                                </div>
                                <div class="initialSlideText">
                                    <span
                                        class="spins-title"
                                        [ngClass]="{
                                            whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                            blackcard_text: card.imageId === 'goldCard'
                                        }"
                                        >{{ card.slide.gameType }}</span
                                    >
                                    <div class="spins-win">
                                        <span
                                            class="s-win-buyin"
                                            [ngClass]="{
                                                whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                                blackcard_text: card.imageId === 'goldCard'
                                            }">
                                            {{ card.slide.text3 | formatCurrencyPipe: 'USD' }}
                                        </span>
                                        <span
                                            class="s-win-label"
                                            [ngClass]="{
                                                whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                                blackcard_text: card.imageId === 'goldCard'
                                            }"
                                            >{{ 'PARTY_POKER_GC_GAMELOBBY_SNGJP_PCARD_WINUPTO' | translate: [card.slide.textTitle] }}</span
                                        >
                                        <span
                                            class="s-win-amount"
                                            [ngClass]="{
                                                whitecard_text: card.payOutType === 0 && card.buyInType === 1 && card.imageId !== 'goldCard',
                                                blackcard_text: card.imageId === 'goldCard'
                                            }">
                                            {{ card.slide.text1 * 100 | FormatamountPipe: 'USD' }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </vn-swiper>
                        <div class="swiper-pager">
                            <div class="swiper-pagination-cust"></div>
                            <div class="swiper-buyin-values spins-overdrive-values">
                                <span class="sp-min-value">${{ tileLeastBuyinValue }}</span>
                                <span class="sp-max-value">${{ tileMaxBuyinValue }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-template #NoTables>
        <p class="no-tables d-flex justify-content-center align-items-center h-100 m-0">
            {{ 'PARTY_POKER_MOBILE_EXPOSPINS_EXPO_GAMES_NOTAVAILABLE' | translate }}
        </p>
    </ng-template>
</div>
<div class="spin-game_button" [ngClass]="sngjpcards.length === 0 ? 'invisible' : 'visible'">
    <button
        class="btn btn-md btn-primary text-capitalize w-100"
        *ngIf="spinsOverdrivePlayNowBtn"
        [ngClass]="{ disabled: sngjpcards.length === 0 }"
        (click)="onClickPlayNowButton()">
        {{ 'PARTY_POKER_GC_QSLOBBY_PLAYNOW' | translate }}
    </button>
</div>

<pwc-spins-buyin
    *ngIf="popupIsOpen"
    (close)="closePopup()"
    [sngjpid]="sngjpid"
    [expospinData]="expospinData"
    [selectedcardindex]="selectedcardindex"
    (click)="onBuyInButtonClick($event)"></pwc-spins-buyin>
