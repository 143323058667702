import { Routes } from '@angular/router';

import { PokerGrandPrixKoSynopsisResolver } from './poker-grand-prix-ko-synopsis.resolver';
import { PokerGrandPrixKoResolver } from './poker-grand-prix-ko.resolver';

export const pokerGrandPrixKoRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerGrandPrixKoResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerGrandPrixKoSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerGrandPrixKoResolver,
        },
    },
];
