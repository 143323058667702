import { Message } from '../../message';

export class PLSAuthenticationResponse extends Message {
    private MESSAGE_NAME: string = 'PLSAuthenticationResponse';
    private loginId: string | null = null;
    private isAuthSuccess: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, loginId: string | null = null, isAuthSuccess: boolean | null = false) {
        super(reqServerPeerId);
        this.loginId = loginId;
        this.isAuthSuccess = isAuthSuccess;
    }

    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
    getIsAuthSuccess(): boolean | null {
        return this.isAuthSuccess;
    }

    setIsAuthSuccess(isAuthSuccess: boolean | null) {
        this.isAuthSuccess = isAuthSuccess;
    }
}
