import { Message } from '../../message';

export class ResponseTourenyLobbyTablesGrid extends Message {
    private MESSAGE_NAME: string = 'ResponseTourenyLobbyTablesGrid';
    private mtctId: number = 0;
    private pageNo: number = 0;
    private pageRows: any[] | null = null;
    private totalPages: number = 0;
    private isDeltaUpdate: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        pageNo: number = 0,
        pageRows: any[] | null = null,
        totalPages: number = 0,
        isDeltaUpdate: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.pageNo = pageNo;
        this.pageRows = pageRows;
        this.totalPages = totalPages;
        this.isDeltaUpdate = isDeltaUpdate;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getPageNo(): number {
        return this.pageNo;
    }

    setPageNo(pageNo: number) {
        this.pageNo = pageNo;
    }
    getPageRows(): any[] | null {
        return this.pageRows;
    }

    setPageRows(pageRows: any[] | null) {
        this.pageRows = pageRows;
    }
    getTotalPages(): number {
        return this.totalPages;
    }

    setTotalPages(totalPages: number) {
        this.totalPages = totalPages;
    }
    getIsDeltaUpdate(): boolean | null {
        return this.isDeltaUpdate;
    }

    setIsDeltaUpdate(isDeltaUpdate: boolean | null) {
        this.isDeltaUpdate = isDeltaUpdate;
    }
}
