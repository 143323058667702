import { Message } from '../../message';

export class JackpotSnapshots extends Message {
    private MESSAGE_NAME: string = 'JackpotSnapshots';
    private jackpotSnapshots: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, jackpotSnapshots: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.jackpotSnapshots = jackpotSnapshots;
    }

    getJackpotSnapshots(): Map<any, any> | null {
        return this.jackpotSnapshots;
    }

    setJackpotSnapshots(jackpotSnapshots: Map<any, any> | null) {
        this.jackpotSnapshots = jackpotSnapshots;
    }
}
