import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PokerLeaderboardFeedPageMatrixBootstrapService } from './poker-leaderboard-feed-bootstrap.service';
import { PokerLeaderboardService } from './poker-leaderboard.service';

export function providePokerLeaderboardFeed(): Provider[] {
    return [runOnAppInit(PokerLeaderboardFeedPageMatrixBootstrapService), PokerLeaderboardService];
}
