<div class="sub-lobby-content">
    <ng-container *ngIf="tourneysData.sngJPInstanceDetails && tourneysData.sngJPInstanceDetails.length > 0; else NoTables">
        <ng-container *ngFor="let tourney of tourneysData.sngJPInstanceDetails">
            <div class="tourneyContainer" (click)="selectedTourney(tourney)">
                <div class="tourneyLeftView" [ngClass]="{ tourneyRunning: tourney.status === 5, tourneyRegistered: tourney.status !== 5 }">
                    <div class="tourneyStatus">
                        <span class="theme-radio-off-i"></span>
                        {{ getTournamentStatusText(tourney.status) | translate }}
                    </div>
                </div>
                <div class="tourneyRightView">
                    <div class="tourneyTitle">{{ getTournamentName(tourney.name.templateId) }}</div>
                    <div class="tourneyBuyIn">
                        {{ 'PARTY_POKER_GC_BUYINCHIPS_BUYINTITLE' | translate }}:<span> ${{ (tourney.buyIn + tourney.tourneyFee) / 100 }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #NoTables>
        <p class="no-tables d-flex justify-content-center align-items-center h-100 m-0">
            {{ 'PARTY_POKER_MOBILE_TOURNAMENTS_NOREGISTRATIONS' | translate }}
        </p>
    </ng-template>
</div>
