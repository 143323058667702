<div
    class="card"
    [@myanimation]="{
        value: card.cardState,
        params: {
            rotateValue: card.getCardSprite(),
            scaleValue: scaleValue,
            top: topValue,
            left: calcLeftValue(leftValue, index),
            cardIndex: cardIndex
        }
    }"
    [style.backgroundPosition]="card.getCardSprite()"
    [style.left]="calcLeftValue(leftValue, index)"
    [style.top]="calcTopValue(topValue)"
    [style.opacity]="1"
    [class.animate]="cardanima()"
    [class.hide]="cardHide()"></div>
