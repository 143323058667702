import { Message } from '../../message';

export class ResponseSatellitesData extends Message {
    private MESSAGE_NAME: string = 'ResponseSatellitesData';
    private mtctId: number = 0;
    private satelliteMTTCTDetails: any[] | null = null;
    private literalsMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        satelliteMTTCTDetails: any[] | null = null,
        literalsMap: Map<any, any> | null = null,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.satelliteMTTCTDetails = satelliteMTTCTDetails;
        this.literalsMap = literalsMap;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getSatelliteMTTCTDetails(): any[] | null {
        return this.satelliteMTTCTDetails;
    }

    setSatelliteMTTCTDetails(satelliteMTTCTDetails: any[] | null) {
        this.satelliteMTTCTDetails = satelliteMTTCTDetails;
    }
    getLiteralsMap(): Map<any, any> | null {
        return this.literalsMap;
    }

    setLiteralsMap(literalsMap: Map<any, any> | null) {
        this.literalsMap = literalsMap;
    }
}
