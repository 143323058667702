import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pbc from './public-page-breadcrumb.component';

@Injectable({ providedIn: 'root' })
export class PublicPageBreadcrumbBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('breadcrumbstemplate', pbc.PublicPageBreadcrumbComponent);
        });
    }
}
