import { Message } from '../../message';
import { PlayerBalanceInfo } from './PlayerBalanceInfo';

export class FRONTALLeavePoolResponse extends Message {
    private MESSAGE_NAME: string = 'FRONTALLeavePoolResponse';
    private entryId: number = 0;
    private balanceInfo: PlayerBalanceInfo | null = null;
    private responseId: number = 0;
    private time: number = 0;
    private conversationId: number = 0;
    private sessionId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        entryId: number = 0,
        balanceInfo: PlayerBalanceInfo | null = null,
        responseId: number = 0,
        time: number = 0,
        conversationId: number = 0,
        sessionId: number = 0,
    ) {
        super(reqServerPeerId);
        this.entryId = entryId;
        this.balanceInfo = balanceInfo;
        this.responseId = responseId;
        this.time = time;
        this.conversationId = conversationId;
        this.sessionId = sessionId;
    }

    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getBalanceInfo(): PlayerBalanceInfo | null {
        return this.balanceInfo;
    }

    setBalanceInfo(balanceInfo: PlayerBalanceInfo | null) {
        this.balanceInfo = balanceInfo;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getTime(): number {
        return this.time;
    }

    setTime(time: number) {
        this.time = time;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getSessionId(): number {
        return this.sessionId;
    }

    setSessionId(sessionId: number) {
        this.sessionId = sessionId;
    }
}
