export class PlayerInfoModel {
    maxNoOfSeats: any;
    playerInfoData = [];
    timeBankBeforeFlop = false;
    constructor() {
        this.maxNoOfSeats = 6;
    }
    setPlayerData = function (data) {
        //this.playerIds = [];
        this.playerInfoData = [];
        for (const i in data) {
            this.setAddPlayerData(data[i]);
        }
    };
    setAddPlayerData = function (message) {
        // this['PlayerData' + data.seatingPosition] = { ...data, seatNo: null, playerId: null, bet: null, betVector: null, winAmount: null };
        let data = Object.assign({}, message);
        this['PlayerData' + data.seatingPosition] = data;
        this['seatData' + data.seatingPosition] = {};
        this['PlayerData' + data.seatingPosition].seatNo = data.seatingPosition;
        this['seatData' + data.seatingPosition].isSeatOccupied = true;
        this['PlayerData' + data.seatingPosition].playerId = data.playerId;
        this['PlayerData' + data.seatingPosition].bet = data.bet ? data.bet : null;
        this['PlayerData' + data.seatingPosition].betVector = data.betVector;
        this['PlayerData' + data.seatingPosition].winAmount = null;
        //this.playerIds.push(data.playerId);
        this.playerInfoData.push(data);
    };
    setMaxNoOfSeats = function (maxNoOfSeats) {
        if (maxNoOfSeats != undefined) this.maxNoOfSeats = maxNoOfSeats;
    };
    getPlayerData = function () {
        if (!this.playerInfoData) {
            this.playerInfoData = [];
        }
        return this.playerInfoData;
    };
    getPlayerStatusDataAtSeat = function (playerId) {
        return this['PlayerData' + this.getPlayerSeatNo(playerId)];
    };
    getPlayerSeatingData = function () {
        const playerSeatingData = [];
        for (let seatNo = 0; seatNo < this.maxNoOfSeats; seatNo++) {
            if (this['PlayerData' + seatNo]) {
                playerSeatingData[seatNo + ''] = this['PlayerData' + seatNo];
                playerSeatingData[seatNo + ''].seatingPosition = this['PlayerData' + seatNo].seatNo;
            }
        }
        return playerSeatingData;
    };
    setPlayerResult = function () {
        //let seatNo = obj.playerHandsPayoffData[0].seatNumber;
        //let handInd = obj.playerHandsPayoffData[0].playerHandInfo[0].handIndex;
        //bjQueueArray.push(obj);
    };
    returnBalance = function () {
        //balance += totalBetValue;
    };
    // getters
    getBalance = function () {
        // return CommonGameProperties.mathUtil["transform"](balance);
    };
    getBalanceInCents = function () {
        //return balance;
    };
    // setters
    setBalance = function () {
        //balance = val;
    };
    getCurrentSeatNumber = function () {
        //return currentSeatNumber;
    };
    setCurrentHandIndex = function () {
        //currentHandIndex = val;
    };
    getCurrentHandIndex = function () {
        //return currentHandIndex;
    };
    getCurrentSeatData = function (seatNo) {
        for (let i = 0; i < this.playerInfoData.length; i++) {
            if (this.playerInfoData[i].seatNo == seatNo) {
                return this.playerInfoData[i];
            }
        }
    };
    getPlayerInfoData = function () {
        return this.playerInfoData;
    };
    //------------poker code --------------
    SetCurrentPlayer = function (obj) {
        this.currentPlayerSeatIndex = obj.Seatno;
    };
    setPlayerBalance = function (obj) {
        if (this['PlayerData' + obj.seatNo]) this['PlayerData' + obj.seatNo].balance = obj.balance;
    };
    getPlayerBalance = function (seatNo) {
        if (this['PlayerData' + seatNo]) return this['PlayerData' + seatNo].balance;
    };
    Blind = function (obj) {
        // for (let i = 0; i < this.playerIds.length; i++) {
        //   if (this["PlayerData" + seatNo].seatingPosition == obj.seatNo) {
        //     this.blindSeatNo = obj.seatNo;
        //     this["PlayerData" + this.playerIds[i]].blindtype = obj.Blindtype;
        //     this["PlayerData" + this.playerIds[i]].blindamount = obj.amount;
        //     this["PlayerData" + this.playerIds[i]].deadamt = obj.Deadamt;
        //     this["PlayerData" + this.playerIds[i]].blindStatus = obj.accepted;
        //   }
        // }
        //this.deductPlayerBalance(obj.seatNo, obj.amount);
        this.updateBetVector(obj.seatNo, obj.amount);
        this.BlindData = obj;
    };
    PostBlind = function () {
        //not in use, uncomment when required
        // for (let i = 0; i < this.playerIds.length; i++) {
        //   playerSeatNo = this.getPlayerSeatNo(this.playerIds[i]);
        //   if (this["PlayerData" + playerSeatNo].seatingPosition == this.blindSeatNo) {
        //     this["PlayerData" + playerSeatNo].blindtype = obj.Blindtype;
        //     this["PlayerData" + playerSeatNo].blindamount = obj.amount;
        //     this["PlayerData" + playerSeatNo].showheadsupwarning =
        //       obj.showheadsupwarning;
        //     this["PlayerData" + playerSeatNo].timoutAt = obj.timoutAt;
        //   }
        // }
    };
    Ante = function (obj) {
        //this.deductPlayerBalance(obj.seatNo, obj.amount);
        this.updateBetVector(obj.seatNo, obj.amount);
        this.anteData = obj;
    };
    getAnteData = function () {
        return this.anteData;
    };
    PostAnte = function () {};
    DealCards = function (obj) {
        /*for(let i=0; i < this.playerIds.length; i++) {
            for(let j=0; j < obj.playerDealCards.length; j++) {
                if(this["PlayerData" + this.playerIds[i]].seatingPosition == obj.playerDealCards[j].seatingPosition){
                    this["PlayerData" + this.playerIds[i]].cards = obj.playerDealCards[j];
                    break;          
                }
            }
        }*/
        this.currentDealCards = obj.playerDealCards;
        this.currentNumberOfTurns = obj.numberOfTurns;
        //"playerDealCards":[{"cards":["XX","XX","XX","XX"],"seatingPosition":0},{"cards":["XX","XX","XX","XX"],"seatingPosition":1},{"cards":["7s","9s","7h","Kc"],"seatingPosition":2}]
    };
    ShowCards = function (obj) {
        const seatNo = obj.seatNo;
        if (this['PlayerData' + seatNo]) {
            this['PlayerData' + seatNo].bestCards = obj.bestCards;
            this['PlayerData' + seatNo].cards = obj.cards;
            this['PlayerData' + seatNo].handStrengthHi = obj.handStrengthHi;
            this['PlayerData' + seatNo].handStrengthLo = obj.handStrengthLo;
            this['PlayerData' + seatNo].seatNo = obj.seatNo;
            this['PlayerData' + seatNo].show = obj.show;
            this['PlayerData' + seatNo].showCardsType = obj.showCardsType;
        }
        this.showCardsdata = obj;
    };
    getShowCardsData = function () {
        return this.showCardsdata;
    };
    getDealCards = function () {
        /*let dealCardsArr = [];
        for(let i=0; i < this.playerIds.length; i++) {
            dealCardsArr.push(this["PlayerData" + this.playerIds[i]].cards)
        }*/
        return this.currentDealCards;
    };
    resetDataModel = function () {
        this.currentPlayerSeatIndex = null;
        this.currentNumberOfTurns = null;
        this.blindSeatNo = null;
        for (let seatNo = 0; seatNo < this.maxNoOfSeats; seatNo++) {
            //this["PlayerData" + this.playerIds[i]].balance = null;
            //let seatNo = this.getPlayerSeatNo(this.playerIds[i]);
            if (this['PlayerData' + seatNo]) {
                this['PlayerData' + seatNo].blindtype = null;
                this['PlayerData' + seatNo].blindamount = null;
                this['PlayerData' + seatNo].deadamt = null;
                this['PlayerData' + seatNo].blindStatus = null;
                this['PlayerData' + seatNo].showheadsupwarning = null;
                this['PlayerData' + seatNo].timoutAt = null;
                this['PlayerData' + seatNo].cards = null;
                this['PlayerData' + seatNo].bestCards = null;
                this['PlayerData' + seatNo].handStrengthHi = null;
                this['PlayerData' + seatNo].handStrengthLo = null;
                // this["PlayerData" + seatNo].seatNo = null;
                this['PlayerData' + seatNo].show = null;
                this['PlayerData' + seatNo].showCardsType = null;
                this['PlayerData' + seatNo].winAmount = null;
            }
        }
        this.playerWinPercent = null;
        this.resetBetVector();
    };
    /*updatePlayerdata = function(playerId, seatNo) {
          if(this["PlayerData" + playerId]){
              this["PlayerData" + playerId].seatingPosition = seatNo;
          }
      }*/
    getPlayerSeatNo = function (playerId) {
        for (let seatNo = 0; seatNo < this.maxNoOfSeats; seatNo++) {
            if (this['PlayerData' + seatNo] && this['PlayerData' + seatNo].playerId == playerId) return seatNo;
        }
        return false;
    };
    HandleOtherPlayerSelectedOption = function (obj) {
        /*let option = message.isAllIn ? PokerCSD.OPTION_ALL_IN : message.optionId;
          this.view.seats.updateSelectedOption(message.seatNo, option);
          let pos = (this.model.get("isFFTable")) ? this.view.seats.getFFPosition(message.seatNo) : message.seatNo;
          this.view.chipStack.setPlayerChips(pos, 0, message.betVector);
          this.view.totalPot.model.set("totalPot", this.view.chipStack.getTotalPotAmount());
          this.view.gamePlayOptions.removeUserOptions();*/
        this.displayedOptionsForSecs = obj.displayedOptionsForSecs;
        this.mouseClickSimulationType = obj.mouseClickSimulationType;
        this.option = obj.option;
        this.optionSelectedAt = obj.optionSelectedAt;
        this.optionSetId = obj.optionSetId;
        this.otherPlayerselectedOpt = obj;
        this.updateBetVector(obj.seatNo, obj.amount);
    };
    getBetVector = function (seatNo) {
        return this['PlayerData' + seatNo] && this['PlayerData' + seatNo].bet ? this['PlayerData' + seatNo].bet : 0;
    };
    updateBetVector = function (seatNo, amount) {
        if (this['PlayerData' + seatNo]) this['PlayerData' + seatNo].bet += amount;
    };
    resetBetVector = function () {
        for (let seatNo = 0; seatNo < this.maxNoOfSeats; seatNo++) {
            if (this['PlayerData' + seatNo]) this['PlayerData' + seatNo].bet = null;
        }
    };
    getOtherPlayerSelectedOption = function () {
        return this.otherPlayerselectedOpt;
    };
    getBlidData = function () {
        return this.BlindData;
    };
    setRemovePlayer = function (obj) {
        this.removePlayerData = obj;
        if (!this['seatData' + obj.seatNumber]) this['seatData' + obj.seatNumber] = {};
        this['seatData' + obj.seatNumber].isSeatReserved = obj.isReserved;
        this['seatData' + obj.seatNumber].isSeatOccupied = false;
        this['PlayerData' + obj.seatNumber] = null;
    };
    getRemovePlayerData = function () {
        return this.removePlayerData;
    };
    setBroadcastDetails = function (obj) {
        this.broadCastDetails = {};
        this.broadCastDetails.bEmojiId = obj.emojiId;
        this.broadCastDetails.seatNo = obj.fromPlayerSeatNo;
    };
    getBroadcastDetails = function () {
        return this.broadCastDetails;
    };
    setHandStrength = function (obj) {
        this.handStrengthData = obj;
    };
    getHandStrength = function () {
        return this.handStrengthData;
    };
    getPlayerBalances = function () {
        const playerBalances = [] as any;
        for (let seatNo = 0; seatNo < this.maxNoOfSeats; seatNo++) {
            const playerBalance = {
                balance: this.getPlayerBalance(seatNo),
                seatNo: seatNo,
            };
            if (playerBalance.balance != undefined && playerBalance.balance != null) {
                playerBalances[seatNo] = playerBalance;
            }
        }
        return playerBalances;
    };
    reserveSeat = function (seatNo) {
        if (!this['seatData' + seatNo]) this['seatData' + seatNo] = {};
        this['seatData' + seatNo].isSeatReserved = true;
    };
    unReserveSeat = function (seatNo) {
        if (!this['seatData' + seatNo]) this['seatData' + seatNo] = {};
        this['seatData' + seatNo].isSeatReserved = false;
    };
    getIsSeatReserved = function (seatNo) {
        return this['seatData' + seatNo] && this['seatData' + seatNo].isSeatReserved;
    };
    getIsSeatOccupied = function (seatNo) {
        return this['seatData' + seatNo] && this['seatData' + seatNo].isSeatOccupied;
    };
    setTimeBankData = function (obj) {
        this.enableTimeBankData = obj;
    };
    getTimeBankData = function () {
        return this.enableTimeBankData;
    };
    getTimeBankBalance = function () {
        return this.timeBankBalance;
    };
    setTimeBankBalance = function (timeBankBalance) {
        this.timeBankBalance = timeBankBalance;
    };
    getEnableTimeBankBeforeFlop = function () {
        return this.timeBankBeforeFlop;
    };
    setEnableTimeBankBeforeFlop = function (bEnableTimeBankBeforeFlop) {
        this.timeBankBeforeFlop = bEnableTimeBankBeforeFlop;
    };
    setDiamondBalance = function (userDiamondBalance) {
        this.userDiamondBalance = userDiamondBalance;
    };
    getDiamondBalance = function () {
        return this.userDiamondBalance;
    };
    PotWinners = function (obj) {
        //{"potNumber":0,"winners":[{"amount":88000,"taxAmount":0,"winnerId":"qa4pro2"}],"msgNumber":0,"peerId":5134878,"className":"PotWinners"}
        //this.currentPotNumber = obj.potNumber;
        this.potWinnersData = obj;
        for (const winner of obj.winners) {
            const seatNo = this.getPlayerSeatNo(winner.winnerId);
            // if (this['PlayerData' + seatNo]) this['PlayerData' + seatNo].winAmount += winner.amount;
            if (this['PlayerData' + seatNo]) this['PlayerData' + seatNo].winAmount = winner.amount;
        }
    };
    getPlayerWinAmount = function (seatNo) {
        return this['PlayerData' + seatNo] ? this['PlayerData' + seatNo].winAmount : null;
    };
    getPotWinnersData = function () {
        return this.potWinnersData;
    };
    ProbabilityOfWinning = function (obj) {
        const seatNumToProbabilityMap = {};
        for (const playerId in obj.playerWinPercent) {
            seatNumToProbabilityMap[this.getPlayerSeatNo(playerId)] = obj.playerWinPercent[playerId];
        }
        this.playerWinPercent = seatNumToProbabilityMap;
    };
    getProbabilityOfWinning = function () {
        return this.playerWinPercent;
    };
    // getCurrentPotNumber = function() {
    //   return this.currentPotNumber;
    // };
}
