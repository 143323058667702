import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { FastForwardService } from '../../../lobby/ff-lobby/ff-lobby.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../../poker-web-client-shared.service';
import { ffLobbyDataSelect, spinsOverdriveDataSelect } from '../../../store/lobby.store';

@Component({
    selector: 'pwc-common-filters',
    templateUrl: 'common-filters.component.html',
    imports: [CommonModule, TranslatePipe],
    providers: [FastForwardService, TranslatePipe],
    standalone: true,
})
export class commonFiltersComponent {
    public selectedWinUptos: any[] = [];
    public fastForwardCards: any;
    public filteredCardsCount = -1;
    public uniqWinUptos: any[] = [];
    public selectedGameTypes: any[] = [];
    public currentFilter;
    public currentLobby;
    public uniqGameTypes: any[] = [];
    private readonly logger: PokerLogger;
    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private shareService: PokerWebClientSharedService,
        private navigation: NavigationService,
        loggerFactory: LoggerFactory,
    ) {
        this.route.queryParams.subscribe((params) => {
            const paramKey = params['key'];
            if (paramKey) {
                this.currentLobby = paramKey;
            }
        });
        this.logger = loggerFactory.getLogger('CommonFiltersLoggingComponent');
        if (this.currentLobby === 'ff-filters') {
            this.shareService.setPageHeaderTitle({ title: 'Filters', url: '/play/fast-forward', showBalanceBlock: false });
            this.store.select(ffLobbyDataSelect).subscribe((data) => {
                this.fastForwardCards = data;
                this.selectedGameTypes = _.uniq(_.map(this.fastForwardCards, (o) => o.gameTypeTxt));
                this.uniqWinUptos = _.sortBy(_.uniq(_.map(this.fastForwardCards, (o) => o.formattedBuyIn)));
            });
            this.filteredCardsCount = this.getUpdateFilteredCardsCount();
            this.currentFilter = JSON.parse(localStorage.getItem('fastForwardCardsFilter')!);
            this.initializeFilter(this.currentFilter, false);
        } else {
            this.shareService.setPageHeaderTitle({ title: 'Filters', url: '/play/spins-overdrive', showBalanceBlock: false });

            this.store.select(spinsOverdriveDataSelect).subscribe((data) => {
                this.fastForwardCards = data;
                this.selectedGameTypes = _.uniq(_.map(this.fastForwardCards, (o) => o.gameTypeTxt));
                // this.uniqGameTypes = _.uniq(_.map(this.fastForwardCards, (o) => o.gameTypeTxt));
                this.uniqWinUptos = _.sortBy(_.uniq(_.map(this.fastForwardCards, (o) => o.slide.text2)));
            });
            this.filteredCardsCount = this.getUpdateFilteredCardsCount();
            this.currentFilter = JSON.parse(localStorage.getItem('sngJpCardsFilter')!);
            this.initializeFilter(this.currentFilter, false);
        }
    }
    initializeFilter(filter, isResetClick?: boolean) {
        this.logger.info('Clicked on filter icon' + JSON.stringify(filter));
        this.selectedWinUptos = filter.winUptos.length > 0 ? (isResetClick ? [...this.uniqWinUptos] : [...filter.winUptos]) : [...this.uniqWinUptos];
        this.selectedGameTypes =
            filter.gameTypes.length > 0 ? (isResetClick ? [...filter.gameTypes] : [...filter.gameTypes]) : [...this.uniqGameTypes];
        this.filteredCardsCount = filter ? this.getUpdateFilteredCardsCount() : this.fastForwardCards.length;
    }
    getUpdateFilteredCardsCount(): number {
        const filtered1 = _.filter(this.fastForwardCards, (f) => _.filter(this.selectedWinUptos, (a) => f.formattedBuyIn === a).length > 0);
        return _.filter(filtered1, (f) => _.filter(this.selectedGameTypes, (b) => f.gameTypeTxt === b).length > 0).length;
    }
    gameCostToggle(item, isAllSelected) {
        this.logger.info('Clicked on selected buy-ins like $2,$3,$5 etc buttons' + JSON.stringify(item, isAllSelected));
        if (item === -1) {
            isAllSelected ? (this.selectedWinUptos = []) : (this.selectedWinUptos = [...this.uniqWinUptos]);
        } else {
            if (this.selectedWinUptos.includes(item)) {
                this.selectedWinUptos.splice(this.selectedWinUptos.indexOf(item), 1);
            } else {
                this.selectedWinUptos = this.selectedWinUptos.concat(item);
            }
        }
        this.filteredCardsCount = this.getUpdateFilteredCardsCount();
    }
    closeFilters() {
        if (this.currentLobby === 'ff-filters')
            // this.router.navigate(['/play/fast-forward']);
            this.navigation.goTo('/play/fast-forward');
        // this.router.navigate(['/play/spins-overdrive']);
        else this.navigation.goTo('/play/spins-overdrive');
    }
    submit() {
        this.logger.info('Clicked on apply button');
        const obj: any = {
            gameTypes: this.selectedGameTypes,
            winUptos: this.selectedWinUptos,
        };
        if (this.currentLobby === 'ff-filters') {
            localStorage.setItem('fastForwardCardsFilter', JSON.stringify(obj));
            // this.router.navigate(['/play/fast-forward']);
            this.navigation.goTo('/play/fast-forward');
        } else {
            localStorage.setItem('sngJpCardsFilter', JSON.stringify(obj));
            // this.router.navigate(['/play/spins-overdrive']);
            this.navigation.goTo('/play/spins-overdrive');
        }
    }
}
