import { Message } from '../../message';

export class ResponseTickersUpdate extends Message {
    private MESSAGE_NAME: string = 'ResponseTickersUpdate';
    private tickers: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tickers: any[] | null = null) {
        super(reqServerPeerId);
        this.tickers = tickers;
    }

    getTickers(): any[] | null {
        return this.tickers;
    }

    setTickers(tickers: any[] | null) {
        this.tickers = tickers;
    }
}
