import { Properties } from '../../utils/properties';
import { GameTable } from '../game-table';
import { Point } from '../point';
import { Pot } from '../pot';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

let playerPosition: number;
export class PotCreated extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.POT_CREATED;
    }

    next(gameTable: GameTable): void {
        let points: any;
        // let rake: any = gameTable.rakeMap.get(this.potId);
        if (gameTable.isReplayerV3) {
            gameTable.existingpotTotalValue = gameTable.existingpotTotalValue + this.amount;
        }

        if (Number(this.potId) === 0 && !gameTable.isReplayerV3) {
            gameTable.freezeMainPot(this.amount);
        } else if (Number(this.potId) === 0 && gameTable.isReplayerV3 && gameTable.allInSeatSequence.length === 0) {
            gameTable.freezeMainPot(this.amount);
        } else {
            if (gameTable.isReplayerV3 && gameTable.allInSeatSequence.length > 0 && !gameTable.isMobile) {
                if (this.potId < gameTable.allInSeatSequence.length) {
                    playerPosition = gameTable.allInSeatSequence[this.potId];
                    points = Properties.propFile[gameTable.gameType][`SIDEPOS${playerPosition}`].split('#');
                } else if (this.potId >= gameTable.allInSeatSequence.length) {
                    return;
                }
            } else {
                playerPosition = this.potId;
                points = Properties.propFile['COMMONSECTION'][`SIDEPOTPOS${this.potId}`].split('#');
            }
            const sidePot = new Pot();
            sidePot.position = new Point(points[0], points[1]);
            sidePot.potId = this.potId;
            sidePot.showPot = false;
            if (gameTable.createdActivePotId === this.potId && gameTable.SIDE_POTS[this.potId - 1] && !gameTable.isReplayerV3) {
                gameTable.valueUpdatedPotId = this.potId - 1;
                gameTable.potCreatedObject[gameTable.currentBettingRound] = this.amount;
                gameTable.SIDE_POTS[this.potId - 1].totalValue = this.amount;
            } else {
                sidePot.totalValue = this.amount;
                gameTable.SIDE_POTS.push(sidePot);
            }
        }
        if (gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber - 1 === this.potId) {
            if (gameTable.isUnCalledBet) {
                if (
                    gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount.unit ===
                        gameTable.unCalledBetAmountDetails['uncalledUnit'].unit ||
                    (gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount.unit >
                        gameTable.unCalledBetAmountDetails['uncalledUnit'].unit &&
                        gameTable.isUnCalledBet)
                ) {
                    gameTable.isUnCalledAmountMatched = true;
                } else {
                    if (gameTable.isReplayerV3 && !gameTable.isMobile) {
                        if (this.potId < gameTable.allInSeatSequence.length) {
                            playerPosition = gameTable.allInSeatSequence[this.potId];
                            points = Properties.propFile[gameTable.gameType][`SIDEPOS${playerPosition}`].split('#');
                        } else if (this.potId >= gameTable.allInSeatSequence.length) {
                            return;
                        }
                    } else {
                        // playerPosition = this.potId;
                        points = Properties.propFile['COMMONSECTION'][`SIDEPOTPOS${this.potId}`].split('#');
                    }
                    const sidePot = new Pot();
                    sidePot.position = new Point(points[0], points[1]);
                    sidePot.potId = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber;
                    gameTable.createdActivePotId = sidePot.potId;
                    sidePot.showPot = false;
                    sidePot.totalValue = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount
                        ? gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount.unit
                        : 0;
                    if (!gameTable.isReplayerV3) {
                        gameTable.SIDE_POTS.push(sidePot);
                    }
                    // }
                }
            } else {
                if (gameTable.isReplayerV3 && !gameTable.isMobile) {
                    if (this.potId < gameTable.allInSeatSequence.length) {
                        playerPosition = gameTable.allInSeatSequence[this.potId];
                        points = Properties.propFile[gameTable.gameType][`SIDEPOS${playerPosition}`].split('#');
                    }
                    // else {
                    //   points = Properties.propFile[gameTable.gameType][`SIDEPOS${this.potId}`].split('#');
                    // }
                    else if (this.potId >= gameTable.allInSeatSequence.length) {
                        if (this.potId === 0 && gameTable.allInSeatSequence.length === 0) {
                            for (let player in gameTable.players) {
                                if (gameTable.players[Number(player)] != gameTable.players[Number(player) + 1]) {
                                    if (gameTable.players[Number(player)].toPot.value < gameTable.players[Number(player) + 1].toPot.value) {
                                        playerPosition = gameTable.players[Number(player)].seatNo;
                                    } else {
                                        playerPosition = gameTable.players[Number(player) + 1].seatNo;
                                    }
                                }
                                break;
                            }
                            points = Properties.propFile[gameTable.gameType][`SIDEPOS${playerPosition}`].split('#');
                            const sidePot = new Pot();
                            sidePot.position = new Point(points[0], points[1]);
                            sidePot.potId = this.potId;
                            // gameTable.createdActivePotId = sidePot.potId;
                            sidePot.showPot = false;
                            sidePot.totalValue = this.amount;
                            gameTable.mainPotFreezed = false;
                            gameTable.SIDE_POTS.push(sidePot);
                        }
                        return;
                    }
                } else {
                    // playerPosition = this.potId;
                    points = Properties.propFile['COMMONSECTION'][`SIDEPOTPOS${this.potId}`].split('#');
                }
                const sidePot = new Pot();
                // const points = Properties.propFile[gameTable.gameType][`SIDEPOS${playerPosition}`].split('#');
                sidePot.position = new Point(points[0], points[1]);
                sidePot.potId = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber;
                gameTable.createdActivePotId = sidePot.potId;
                sidePot.showPot = false;
                sidePot.totalValue = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount
                    ? gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount.unit
                    : 0;
                if (!gameTable.isReplayerV3) {
                    gameTable.SIDE_POTS.push(sidePot);
                }
            }
        }
        // gameTable.activePotCreation(0, true);
    }

    prev(gameTable: GameTable): void {
        if (gameTable.isReplayerV3) {
            gameTable.existingpotTotalValue = gameTable.existingpotTotalValue - this.amount;
        }
        gameTable.isUnCalledAmountMatched = false;
        if (Number(this.potId) != 0) {
            // let pot = gameTable.SIDE_POTS.find(p => Number(p.potId) === Number(this.potId));
            let sidePots = gameTable.SIDE_POTS;
            for (let j = 0; j < sidePots.length; j++) {
                if (sidePots[j].potId === this.potId) {
                    if (gameTable.isReplayerV3) {
                        sidePots = sidePots.slice(0, j);
                    } else {
                        sidePots = sidePots.slice(0, j + 1);
                    }
                }
                gameTable.SIDE_POTS = sidePots;
            }
            if (!gameTable.isReplayerV3) {
                if (
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
                    this.potId ==
                        (gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]
                            ? gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber
                            : 0)
                ) {
                    if (gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]) {
                        gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                    }
                }
            }
        } else if (Number(this.potId == 0 && gameTable.isReplayerV3 && gameTable.allInSeatSequence.length > 0)) {
            gameTable.currentActivePot = gameTable.MAIN_POT.totalValue;
            gameTable.mainPotFreezed = false;
            if (gameTable.SIDE_POTS.length > 0) {
                gameTable.SIDE_POTS.pop();
            }
        } else {
            gameTable.mainPotFreezed = false;
            gameTable.MAIN_POT.totalValue = Number(gameTable.mainPotAmounttillPotCreated);
            if (gameTable.SIDE_POTS.length > 0) {
                gameTable.SIDE_POTS.pop();
            }
        }
        gameTable.createdActivePotId = this.potId;
        // let rake: any = gameTable.rakeMap.get(this.potId);
        // gameTable.substractRake(rake)
    }
}
