import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class ThrowAnimationViewModel {
    gameService;
    tableSettingsDataModel;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher, tableSettingsDataModel) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.tableSettingsDataModel = tableSettingsDataModel;
        if (!this.peerId) {
            this.peerId = this.gameService.getPeerId();
        }
        this.peerId = this.gameService.getPeerId();
        this.view.currentTablePeerId(this.peerId);
        // this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_PLAYER_STATUS,this.onThrowAnimation.bind(this));//temporarily
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.THROW_ANIMATION, this.onThrowAnimation.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.TIMER_STARTED_NOTIFICATION, this.checkForThrowPos.bind(this, true));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME,this.clearAnimations.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_NEW_GAME,this.clearAnimations.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.BET_OPTION_SELECTED, this.checkForThrowPos.bind(this, false));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.PLAY_SOUNDS,this.playThrowSounds.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_SETTING_DATA, this.updateThrowState.bind(this));
        this.enableThrowMode = this.tableSettingsDataModel.getGameSettingData().throwAnimations;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_EMOJI_TAB, this.updateThrowAnim.bind(this));

        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SELECTED_CASHOUT_OPTION, this.checkForThrowPos.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_ACTION_FOR_CASHOUT, this.clearAnimForCashOut.bind(this));
    };
    updateThrowAnim = function (isSelfEmojiTabOpen) {
        this.view.updateThrowAnim(isSelfEmojiTabOpen.detail.status);
    };
    checkForThrowPos = function (flag) {
        this.view.checkForThrowPos(flag.detail);
    };
    onThrowAnimation = function () {
        if (this.gameService.secondaryAssetsLoaded && this.enableThrowMode) {
            let obj = this.gameService.getThrowAnimationData();
            if (obj === undefined) {
                obj = {
                    animationId: 1,
                    fromPlayerSeatNo: 2,
                    toPlayerSeatNo: 0,
                };
            }
            if (Number.isInteger(obj.toPlayerSeatNo)) obj.toPlayerSeatNo = this.gameService.getClientSideSeatNo(obj.toPlayerSeatNo);
            if (Number.isInteger(obj.fromPlayerSeatNo)) obj.fromPlayerSeatNo = this.gameService.getClientSideSeatNo(obj.fromPlayerSeatNo);
            this.view.onThrowAnimation(obj);
        }
    };
    clearAnimations = function () {
        //this.view.clearAnimations();
    };
    playThrowSounds = function () {
        this.view.playThrowSounds();
    };
    // update throw animations enabling from settings panel
    updateThrowState = function (data) {
        this.enableThrowMode = data.detail.throwAnimations;
    };
    clearAnimForCashOut = function () {
        this.view.hideThrowAnimation();
    };
}
