import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'pt-tournament-calendar-timer',
    templateUrl: 'tournament-calendar-timer.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, NgxDatatableModule],
})
export class TournamentCalendarTimerComponent implements OnInit, OnDestroy {
    currenttime: any;
    minutes: number;
    hours: number;
    seconds: number;
    @Input()
    endTime: any;
    millisecondsleft: number;
    secondsleft: any;
    timeLeft: string;
    callback: any;
    ngOnInit() {
        this.refresh();
        this.callback = setInterval(() => {
            this.refresh();
        }, 1000); /*refresh for every second*/
    }
    private refresh() {
        this.currenttime = new Date();
        this.endTime = new Date(this.endTime);
        this.millisecondsleft = this.endTime - this.currenttime;
        if (this.millisecondsleft > 0) {
            this.hours = Math.floor(this.millisecondsleft / 3600000);
            this.minutes = Math.floor((this.millisecondsleft - this.hours * 3600000) / 60000);
            this.seconds = Math.floor((this.millisecondsleft - this.hours * 3600000 - this.minutes * 60000) / 1000);
            this.formatMessage(this.hours, this.minutes, this.seconds);
        } else {
            this.timeLeft = '';
        }
    }

    private formatMessage(hours: number, minutes: number, seconds: number) {
        this.timeLeft = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    ngOnDestroy() {
        clearInterval(this.callback);
    }
}
