import { Message } from '../../message';

export class ResponseUserClubDetails extends Message {
    private MESSAGE_NAME: string = 'ResponseUserClubDetails';
    private userClubDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, userClubDetails: any[] | null = null) {
        super(reqServerPeerId);
        this.userClubDetails = userClubDetails;
    }

    getUserClubDetails(): any[] | null {
        return this.userClubDetails;
    }

    setUserClubDetails(userClubDetails: any[] | null) {
        this.userClubDetails = userClubDetails;
    }
}
