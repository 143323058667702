import { Message } from '../../message';

export class BlockSeatCancel extends Message {
    private MESSAGE_NAME: string = 'BlockSeatCancel';
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestType: number = 0) {
        super(reqServerPeerId);
        this.requestType = requestType;
    }

    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
