<div class="flipclock">
    <div *ngIf="amount !== undefined">
        <div *ngIf="currency" class="currency-teaser">{{ currency }}</div>
        <div *ngFor="let mjItem of majorItems; let i = index" class="teasser-flip-value">
            <div class="seg" [class.commaSeg]="mjItem.value === ','">
                <div *ngIf="mjItem.value === ','" class="flip-comma">{{ mjItem.value }}</div>
                <div class="flip-wrapper" *ngIf="mjItem.value !== ','">
                    <div class="flip">{{ mjItem.value }}</div>
                    <div class="flip flip--bottom">{{ mjItem.value }}</div>
                </div>
            </div>
        </div>
        <span class="flip-dot">.</span>
        <div class="teasser-flip-value" *ngFor="let mnItem of minorItems">
            <div class="seg minor-item">
                <div class="flip-wrapper">
                    <div class="flip flip--next" *ngIf="mnItem.isFlipVisible">{{ mnItem.value }}</div>
                    <div class="flip">
                        {{ mnItem.value }}
                    </div>
                    <div class="flip flip--bottom">{{ mnItem.value }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
