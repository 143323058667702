import { Message } from '../../message';

export class EDSNGRequestMessage extends Message {
    private MESSAGE_NAME: string = 'EDSNGRequestMessage';
    private template: string | null = null;
    private templateParams: Map<any, any> | null = null;
    private eventDataId: number = 0;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        template: string | null = null,
        templateParams: Map<any, any> | null = null,
        eventDataId: number = 0,
        tableId: number = 0,
    ) {
        super(reqServerPeerId);
        this.template = template;
        this.templateParams = templateParams;
        this.eventDataId = eventDataId;
        this.tableId = tableId;
    }

    getTemplate(): string | null {
        return this.template;
    }

    setTemplate(template: string | null) {
        this.template = template;
    }
    getTemplateParams(): Map<any, any> | null {
        return this.templateParams;
    }

    setTemplateParams(templateParams: Map<any, any> | null) {
        this.templateParams = templateParams;
    }
    getEventDataId(): number {
        return this.eventDataId;
    }

    setEventDataId(eventDataId: number) {
        this.eventDataId = eventDataId;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
