import { Message } from '../../message';

export class ArjelGameSummaryPlayerInfo extends Message {
    private MESSAGE_NAME: string = 'ArjelGameSummaryPlayerInfo';
    private totalBetInGame: number = 0;
    private netAmount: number = 0;
    private won: boolean | null = false;
    private handStrengthCards: string | null = null;
    private initialBalance: number = 0;
    private endBlance: number = 0;
    private rake: number = 0;
    private showCards: number = 0;
    private showCardsRound: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        totalBetInGame: number = 0,
        netAmount: number = 0,
        won: boolean | null = false,
        handStrengthCards: string | null = null,
        initialBalance: number = 0,
        endBlance: number = 0,
        rake: number = 0,
        showCards: number = 0,
        showCardsRound: number = 0,
    ) {
        super(reqServerPeerId);
        this.totalBetInGame = totalBetInGame;
        this.netAmount = netAmount;
        this.won = won;
        this.handStrengthCards = handStrengthCards;
        this.initialBalance = initialBalance;
        this.endBlance = endBlance;
        this.rake = rake;
        this.showCards = showCards;
        this.showCardsRound = showCardsRound;
    }

    getTotalBetInGame(): number {
        return this.totalBetInGame;
    }

    setTotalBetInGame(totalBetInGame: number) {
        this.totalBetInGame = totalBetInGame;
    }
    getNetAmount(): number {
        return this.netAmount;
    }

    setNetAmount(netAmount: number) {
        this.netAmount = netAmount;
    }
    getWon(): boolean | null {
        return this.won;
    }

    setWon(won: boolean | null) {
        this.won = won;
    }
    getHandStrengthCards(): string | null {
        return this.handStrengthCards;
    }

    setHandStrengthCards(handStrengthCards: string | null) {
        this.handStrengthCards = handStrengthCards;
    }
    getInitialBalance(): number {
        return this.initialBalance;
    }

    setInitialBalance(initialBalance: number) {
        this.initialBalance = initialBalance;
    }
    getEndBlance(): number {
        return this.endBlance;
    }

    setEndBlance(endBlance: number) {
        this.endBlance = endBlance;
    }
    getRake(): number {
        return this.rake;
    }

    setRake(rake: number) {
        this.rake = rake;
    }
    getShowCards(): number {
        return this.showCards;
    }

    setShowCards(showCards: number) {
        this.showCards = showCards;
    }
    getShowCardsRound(): number {
        return this.showCardsRound;
    }

    setShowCardsRound(showCardsRound: number) {
        this.showCardsRound = showCardsRound;
    }
}
