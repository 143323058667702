<div class="touch-swiper-wide pb-2 col p-0">
    <div class="full-slide-view col-md-8" *ngIf="staticSlide !== null">
        <a *ngIf="staticSlide?.secondCta?.link; else withoutStaticsecondCta" href="{{ staticSlide?.secondCta?.link }}">
            <img
                src="{{ staticSlide?.staticSlideImageUrl }}"
                class="full-slide-view-image"
                alt="{{ staticSlide?.slideName }}"
                (click)="mainBannerClick($event, staticSlide)" />
        </a>
        <ng-template #withoutStaticsecondCta>
            <img
                src="{{ staticSlide?.staticSlideImageUrl }}"
                class="full-slide-view-image"
                alt="{{ staticSlide?.slideName }}"
                (click)="mainBannerClick($event, staticSlide)" />
        </ng-template>

        <div class="full-slide-view-content">
            <h2>{{ staticSlide?.title }}</h2>
            <div [innerHtml]="staticSlide?.body"></div>
            <a
                *ngIf="staticSlide?.mainCta"
                class="learn-more btn btn-primary mx-2 mb-3 d-inline-block d-sm-none"
                (click)="ctaClick($event, staticSlide, '1', 'mainCta')"
                href="javascript:void(0)"
                >{{ staticSlide?.mainCta?.text }}
            </a>
            <a
                *ngIf="staticSlide?.secondCta"
                class="learn-more btn btn-dark mx-2 mb-3"
                (click)="ctaClick($event, staticSlide, '1', 'secondCta')"
                href="javascript:void(0)"
                >{{ staticSlide?.secondCta?.text }}
            </a>
            <span class="significantTerms" [innerHtml]="staticSlide?.terms"></span>
        </div>
    </div>
    <div class="slide-preview">
        <vn-swiper
            [swiperOptions]="config"
            #SwiperComponent
            *ngIf="swiperData && swiperData.length > 0"
            (mouseenter)="pause($event)"
            (mouseleave)="resume($event)">
            <div *ngFor="let slide of swiperData; let slideIndex = index" class="swiper-slide">
                <div class="swiper-slide-content">
                    <a *ngIf="slide.secondCta; else withoutsecondCta" href="{{ slide.secondCta.link }}">
                        <div (click)="sideBannerClick($event, slide, slideIndex + 1)">
                            <img src="{{ slide.imageUrl }}" class="swiper-slide-image" alt="{{ slide.title }}" />
                            <div class="slide-content">
                                <h3 *ngIf="slide.title">{{ slide.title }}</h3>
                                <span class="significantTerms" [innerHtml]="slide.terms"></span>
                            </div>
                        </div>
                    </a>
                    <ng-template #withoutsecondCta>
                        <div (click)="sideBannerClick($event, slide, slideIndex + 1)">
                            <img src="{{ slide.imageUrl }}" class="swiper-slide-image" alt="{{ slide.title }}" />
                            <div class="slide-content">
                                <h3 *ngIf="slide.title">{{ slide.title }}</h3>
                                <span class="significantTerms" [innerHtml]="slide.terms"></span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </vn-swiper>
        <div id="navigation-container"></div>
    </div>
</div>
