import { Message } from '../../message';

export class PoolForcedSitOutNotification extends Message {
    private MESSAGE_NAME: string = 'PoolForcedSitOutNotification';
    private entryId: number = 0;
    private reasonId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, entryId: number = 0, reasonId: number = 0) {
        super(reqServerPeerId);
        this.entryId = entryId;
        this.reasonId = reasonId;
    }

    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getReasonId(): number {
        return this.reasonId;
    }

    setReasonId(reasonId: number) {
        this.reasonId = reasonId;
    }
}
