import { Message } from '../../message';
import { Option } from './Option';

export class SelectOption extends Message {
    private MESSAGE_NAME: string = 'SelectOption';
    private option: Option | null = null;
    private optionSetId: number = 0;
    private displayedOptionsForSecs: number = 0;
    private mouseClickSimulationType: number = 0;
    private optionSelectedAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        option: Option | null = null,
        optionSetId: number = 0,
        displayedOptionsForSecs: number = 0,
        mouseClickSimulationType: number = 0,
        optionSelectedAt: number = 0,
    ) {
        super(reqServerPeerId);
        this.option = option;
        this.optionSetId = optionSetId;
        this.displayedOptionsForSecs = displayedOptionsForSecs;
        this.mouseClickSimulationType = mouseClickSimulationType;
        this.optionSelectedAt = optionSelectedAt;
    }

    getOption(): Option | null {
        return this.option;
    }

    setOption(option: Option | null) {
        this.option = option;
    }
    getOptionSetId(): number {
        return this.optionSetId;
    }

    setOptionSetId(optionSetId: number) {
        this.optionSetId = optionSetId;
    }
    getDisplayedOptionsForSecs(): number {
        return this.displayedOptionsForSecs;
    }

    setDisplayedOptionsForSecs(displayedOptionsForSecs: number) {
        this.displayedOptionsForSecs = displayedOptionsForSecs;
    }
    getMouseClickSimulationType(): number {
        return this.mouseClickSimulationType;
    }

    setMouseClickSimulationType(mouseClickSimulationType: number) {
        this.mouseClickSimulationType = mouseClickSimulationType;
    }
    getOptionSelectedAt(): number {
        return this.optionSelectedAt;
    }

    setOptionSelectedAt(optionSelectedAt: number) {
        this.optionSelectedAt = optionSelectedAt;
    }
}
