import { Injectable } from '@angular/core';

import {
    ClientConfig,
    ClientConfigProductName,
    ClientConfigService,
    LazyClientConfig,
    LazyClientConfigBase,
    LazyClientConfigService,
} from '@frontend/vanilla/core';

import {
    PWCNavigationConfigFactory,
    SocialComponentConfigFactory,
    downloadClientConfigFactory,
    globalClientConfigFactory,
    gtmClientConfigFactory,
    handhistoryClientConfigFactory,
    htmlHeadTagsClientConfigFactory,
    myGameClientConfigFactory,
    navigationConfigFactory,
    pokerHomePageClientConfigFactory,
    pokerVCClientConfigFactory,
    pplEventClientConfigFactory,
    promoHubClientConfigFactory,
    promotionsClientConfigFactory,
    redirixRedirectsConfigFactory,
    ribbonTimerCountdownConfigFactory,
    routesClientConfigFactory,
    topNavigationConfigFactory,
    topRibbonInjectionConfigFactory,
    tournamentWidgetsConfigFactory,
    tournamentsCalendarClientConfigFactory,
    userInformationClientConfigFactory,
    vnDeviceConfigFactory,
    webGameEngineClientConfigFactory,
} from './client-config.module';

@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: navigationConfigFactory,
})
@ClientConfig({ key: 'navigationConfig', product: ClientConfigProductName.POKER })
export class NavigationClientConfig {
    items: any;
}

@ClientConfig({ key: 'pokerVCClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: pokerVCClientConfigFactory,
})
export class PokerVCClientConfig {
    clientLaunchHost: string;
    goToLobbyQueryParams: string;
    myTicketsQueryParams: string;
    macGoToLobbyQueryParams: string;
    macMyTicketsQueryParams: string;
}

@ClientConfig({ key: 'topRibbonInjectionClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: topRibbonInjectionConfigFactory,
})
export class TopRibbonInjectionConfig {
    enableTopRibbon: any;
}

@ClientConfig({ key: 'pokerHomePageClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: pokerHomePageClientConfigFactory,
})
export class PokerHomePageClientConfig {
    homePageComposition: any;
    useBwinNavigation: boolean;
    disableHtmlHeadTags: boolean;
    useEpcotNavigation: boolean;
}

@LazyClientConfig({ key: 'handHistoryClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: handhistoryClientConfigFactory,
})
export class HandHistoryClientConfig extends LazyClientConfigBase {
    disableTextView: boolean;
    isHHService: boolean;
    isUnCalledBet: boolean;
    isReplayerThree: boolean;
    isPotraiteReplayer: boolean;
}

@ClientConfig({ key: 'downloadConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: downloadClientConfigFactory,
})
export class DownloadClientConfig {
    downloadAppOnPageLoad: any;
    isPublicPage: boolean;
    navigationRouteAfterDownload: string;
    defaultTrackingId: string;
    wmid: any;
    nativeLauncherEvents: any;
}

@LazyClientConfig({ key: 'routesClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: routesClientConfigFactory,
})
export class RoutesClientConfig extends LazyClientConfigBase {
    routes: any;
    pokerPromotionsPaths: any;
}

@LazyClientConfig({ key: 'promotionsClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promotionsClientConfigFactory,
})
export class PromotionsClientConfig extends LazyClientConfigBase {
    usePokerPromotionsNewTemplate: boolean;
    activatePublicPageTournamentRoute: boolean;
}

@LazyClientConfig({ key: 'tournamentsCalendarConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: tournamentsCalendarClientConfigFactory,
})
export class TournamentsCalendarClientConfig extends LazyClientConfigBase {
    hideCalendarFilters: boolean;
    defaultHideRunningOrFinished: boolean;
    hidePagination: boolean;
    startTimeDisplayMode: string;
    googleCalendarHost: string;
    yahooCalendarHost: string;
    msnCalendarHost: string;
    pagerStartEndInterval: number;
    buyInMin: number;
    buyInMax: number;
    buyInSliderSteps: number;
    hideBuyInSlider: boolean;
    bwinDisplayMode: boolean;
    defaultPageSize: number;
    isPartyNavigation: boolean;
}

@LazyClientConfig({ key: 'globalConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: globalClientConfigFactory,
})
export class GlobalClientConfig extends LazyClientConfigBase {
    config: any;
}
export interface Config {
    device: string;
    url: string;
    useIdNow: boolean;
}

export interface IdNowNativeApp {
    product: string;
    config: Config;
}

export interface IdNowDeviceVersion {
    device: string;
    version: string;
}

@ClientConfig({ key: 'promoHubClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: promoHubClientConfigFactory,
})
export class PromoHubClientConfig {
    isPromoHub: boolean;
    isV2AccountMenu: boolean;
}

@LazyClientConfig({ key: 'topNavigationConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: topNavigationConfigFactory,
})
export class TopNavigationConfig extends LazyClientConfigBase {
    disableTopNavigation: any;
}

@LazyClientConfig({ key: 'vnDevice', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: vnDeviceConfigFactory,
})
export class vnDeviceConfig extends LazyClientConfigBase {
    isMobile: boolean;
    isMobilePhone: boolean;
    isRobot: boolean;
    isTablet: boolean;
    isTouch: boolean;
}

@ClientConfig({ key: 'socialComponentClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: SocialComponentConfigFactory,
})
export class SocialComponentClientConfig {
    addHoursToTournamentTime: number;
}

@LazyClientConfig({ key: 'redirixRedirectsClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: redirixRedirectsConfigFactory,
})
export class RedirixRedirectsClientConfig extends LazyClientConfigBase {
    redirectRoutes: RedirectRoutes[];
}

export interface RedirectRoutes {
    fromUrl: string;
    toUrl: string;
}

//My Game Configurations
export interface ErrorMessagesConfigItem {
    partialContent: string;
    unauthorized: string;
    badRequest: string;
    internalServerError: string;
    otherError: string;
}

export interface ContentConfigItem {
    fullScoreCTA: string;
    gameType: string;
    handsPlayed: string;
    lessHandsCTAText: string;
    lessHandsText: string;
    loginMyGame: string;
    loginTagline: string;
    notEnoughHands: string;
    overallScore: string;
    playNowUrl: string;
    yourSkillLevel: string;
}

@ClientConfig({ key: 'myGameClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: myGameClientConfigFactory,
})
export class MyGameClientConfig {
    errorMessagesConfigItem: ErrorMessagesConfigItem;
    contentConfigItem: ContentConfigItem;
    minimumHandsRequired: number;
    redirectLoginUrlHost: string;
}

@LazyClientConfig({ key: 'ribbonTimerCountdownClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: ribbonTimerCountdownConfigFactory,
})
export class RibbonTimerCountdownClientConfig extends LazyClientConfigBase {
    enableRibbonTimerCountdown: boolean;
    enablePokerDiscountRibbonComponent: boolean;
    enablePokerTournamentRibbonComponent: boolean;
}

@LazyClientConfig({ key: 'htmlHeadTagsClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: htmlHeadTagsClientConfigFactory,
})
export class HtmlHeadTagsClientConfig extends LazyClientConfigBase {
    restrictedWebUrls: any;
}

export interface TournamentWidgetsConfigItem {
    pageTitle: string;
    statusTimerStrings: StatusTimerStrings;
    ctaLink: string;
    noSearchResultsFound: string;
    noDataMsg: string;
    ctaText: string;
}

export interface StatusTimerStrings {
    search: string;
    day: string;
    days: string;
    regEnds: string;
    regClosed: string;
    regStarts: string;
    lateRegInProgress: string;
    registering: string;
}

@LazyClientConfig({ key: 'tournamentWidgetsConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: tournamentWidgetsConfigFactory,
})
export class TournamentWidgetsClientConfig extends LazyClientConfigBase {
    tournamentWidgetConfigs: TournamentWidgetsConfigItem;
}

@LazyClientConfig({ key: 'gtmClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: gtmClientConfigFactory,
})
export class GTMClientConfig extends LazyClientConfigBase {
    gtmInjectionDelay: number;
    triggerGTMClientInjection: boolean;
}

@LazyClientConfig({ key: 'userInfoClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: userInformationClientConfigFactory,
})
export class UserInformationClientConfig extends LazyClientConfigBase {
    countryCode: string;
    region: string;
    city: string;
    zip: string;
    metroCode: string;
    latitude: string;
    resolvedIP: string;
    errorMessage: string;
}

@LazyClientConfig({ key: 'pplEventClientConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: pplEventClientConfigFactory,
})
export class PPLEventClientConfig extends LazyClientConfigBase {
    categoryConfigurationPath: string;
    detailConfigurationPath: string;
    eventsFolderConfigurationPath: string;
}

@ClientConfig({ key: 'pwcNavigationConfig', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: PWCNavigationConfigFactory,
})
export class PWCNavigationClientConfig {
    items: any;
}

@ClientConfig({ key: 'webGameEngine', product: ClientConfigProductName.POKER })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: webGameEngineClientConfigFactory,
})
export class WebGameEngineClientConfig {
    isPlayRouteRequired: boolean;
    isQueryParamRequired: boolean;
}
