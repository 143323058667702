import { Message } from '../../message';

export class TokenVerificationRequest extends Message {
    private MESSAGE_NAME: string = 'TokenVerificationRequest';
    private loginId: string | null = null;
    private tokenCode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, loginId: string | null = null, tokenCode: number = 0) {
        super(reqServerPeerId);
        this.loginId = loginId;
        this.tokenCode = tokenCode;
    }

    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
    getTokenCode(): number {
        return this.tokenCode;
    }

    setTokenCode(tokenCode: number) {
        this.tokenCode = tokenCode;
    }
}
