import { Message } from '../../message';

export class SystemConfigDetails extends Message {
    private MESSAGE_NAME: string = 'SystemConfigDetails';
    private configDetails: any[] | null = null;
    private macAddress: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, configDetails: any[] | null = null, macAddress: string | null = null) {
        super(reqServerPeerId);
        this.configDetails = configDetails;
        this.macAddress = macAddress;
    }

    getConfigDetails(): any[] | null {
        return this.configDetails;
    }

    setConfigDetails(configDetails: any[] | null) {
        this.configDetails = configDetails;
    }
    getMacAddress(): string | null {
        return this.macAddress;
    }

    setMacAddress(macAddress: string | null) {
        this.macAddress = macAddress;
    }
}
