import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TournamentCalendarService {
    initData: any;
    title: string;
    id: number;
    tournamentList: any = {};
    requestParams: any = {};
    gametypes: any;
    statuses: any;
    limits: any;
    values: any;
    totalTournaments: number;
    GameTypeValue: string;
    selectedBuyIn: string;
    hideRunning: any;
    buyIn: any;
    submit: any;
    menuitems: any;
    inputmodel: any;
    messages: any;
    metadata: any;
    formElementTemplateChilds: any;
    savedfilter: any = {};
    pmPokerNavigationView: any;
    currentFiltervalues: any;
    pageNumber: number = 1;
    pageBack: boolean = false;
    pagedTournamentItemsList: any;
    enableAndDisable: boolean = false;
    constructor(private api: PokerApiService) {}

    setInitialDefaultFilterValues() {
        if (this.gametypes && this.gametypes.length > 0) {
            this.GameTypeValue = this.gametypes[0].value;
        }
        if (this.limits && this.limits.length > 0) {
            this.selectedBuyIn = this.limits[0].value;
        }
        if (this.hideRunning === undefined) {
            this.hideRunning = false;
        }
    }
    setinitialpagedTournamentItemsList(list: any) {
        this.pagedTournamentItemsList = list;
    }
    getinitialpagedTournamentItemsList() {
        return this.pagedTournamentItemsList;
    }
    getInitialDefaultFilterValues() {
        return {
            GameTypeValue: this.GameTypeValue,
            selectedBuyIn: this.selectedBuyIn,
            hideRunning: this.hideRunning,
        };
    }
    setSitecoreInfo(initResponse: any) {
        if (initResponse) {
            this.pmPokerNavigationView = initResponse.pmPokerNavigationView;
            this.formElementTemplateChilds = initResponse.formElementTemplateChilds;
        }
    }
    getFormElementTemplateChilds() {
        return this.formElementTemplateChilds;
    }
    getPmPokerNavigationView() {
        return this.pmPokerNavigationView;
    }

    getData(): Observable<any> {
        return this.api.get('Tournament/GetData').pipe(catchError(this.handleError('tournament getData')));
    }

    sort(Page: number, sortBy: any, sortOrder: any, buyIn: any, gameType: any, tournamentType: any, hideRunning: any): Observable<any> {
        return this.api.get('Tournament/GetPage', {
            page: Page,
            sortBy: sortBy,
            sortOrder: sortOrder,
            buyin: buyIn,
            gameType: gameType,
            tournamentType: tournamentType,
            hideRunning: hideRunning,
        });
    }

    getItemValues(itemName: string, formElementTemplateChilds: any) {
        for (let i = 0; i < formElementTemplateChilds.length; i++) {
            if (itemName === formElementTemplateChilds[i].metadata.id.itemName) {
                return formElementTemplateChilds[i].values;
            }
        }
    }
    // OBSERVABLE to get filter parameters and EnableAndDisable(for resetfilter) from filters component
    private filtersdata = new BehaviorSubject<Array<any>>([]);
    getFilteredTournamentData: Observable<Array<any>> = this.filtersdata.asObservable();
    getFilteredData(requestParamsList: Array<any>, EnableAndDisable: boolean) {
        this.currentFiltervalues = requestParamsList;
        this.enableAndDisable = EnableAndDisable;
        this.filtersdata.next(requestParamsList);
    }

    setGameTypes(gameTypes: any) {
        this.gametypes = gameTypes;
    }
    setLimits(limits: any) {
        this.limits = limits;
    }
    setTitle(title: string) {
        this.title = title;
    }
    private handleError<T>(result?: T) {
        return (): Observable<T> => {
            return of(result as T);
        };
    }
}
