<div class="tweets" *ngIf="initData">
    <ul class="list-unstyled">
        <ng-container *ngFor="let tweet of initData; let tweetIndex = index">
            <li class="media mb-4" *ngIf="tweetIndex < 6">
                <a [href]="getTweetUrl(tweet.text) || twitterLink" class="text-light" target="_blank" rel="noopener">
                    <img
                        class="d-none d-sm-block mr-3 rounded-circle"
                        [src]="this.getProfileImage(tweet.user.profile_image_url_https)"
                        width="48"
                        height="48"
                        alt="Profile image" />
                </a>
                <div class="media-body">
                    <a [href]="getTweetUrl(tweet.text) || twitterLink" class="text-light" target="_blank" rel="noopener">
                        <p class="mt-0 mb-1">
                            <strong class="verified">{{ tweet.user.screen_name }}</strong>
                            <span class="text-muted d-block">{{ tweet.user.screen_name }}</span>
                        </p>
                        <p class="mb-0 tweetText">{{ tweet.text }}</p>
                    </a>
                </div>
            </li>
        </ng-container>
    </ul>
    <br />
    <br />
</div>
