import { Message } from '../../message';
import { LSFlightedData } from './LSFlightedData';
import { LSMixMaxData } from './LSMixMaxData';
import { LSMultiDayData } from './LSMultiDayData';
import { LSReentryInfo } from './LSReentryInfo';
import { MTCTFontDetails } from './MTCTFontDetails';

export class LSMTCTLobbyData extends Message {
    private MESSAGE_NAME: string = 'LSMTCTLobbyData';
    private mtctId: number = 0;
    private tourneyStartTime: any | null = null;
    private name: any | null = null;
    private gameType: number = 0;
    private limitType: number = 0;
    private buyIn: number = 0;
    private entryFee: number = 0;
    private noOfPlayers: number = 0;
    private status: number = 0;
    private gamePlayType: number = 0;
    private noOfParticipants: number = 0;
    private maxParticipants: number = 0;
    private announceTime: any | null = null;
    private registrationStartTime: any | null = null;
    private registrationEndTime: any | null = null;
    private allowLateRegistration: boolean | null = false;
    private tourneyEndTime: any | null = null;
    private tourneyCurrentLevel: number = 0;
    private totalPrizePool: number = 0;
    private addonSupported: boolean | null = false;
    private rebuyAddonStatus: number = 0;
    private firstRankPlayerName: string | null = null;
    private mtctEvents: any[] | null = null;
    private totalRoundCount: number = 0;
    private maxSeats: number = 0;
    private mtsgId: number = 0;
    private tournamentDescription: any | null = null;
    private tabIds: any[] | null = null;
    private gameCurrency: string | null = null;
    private trnyTypeId: number = 0;
    private buyInInfo: any | null = null;
    private colorCode: number = 0;
    private partyPoints: number = 0;
    private lateRegInProgress: boolean | null = false;
    private unregClosed: boolean | null = false;
    private bountyAmount: number = 0;
    private allowLateRegistrationTillLevel: number = 0;
    private unRegOffset: number = 0;
    private mttType: number = 0;
    private trnyMinPlrs: number = 0;
    private isQuinteTourney: boolean | null = false;
    private quintepokChampFee: number = 0;
    private allowedCountryCodes: string | null = null;
    private qualifiers: any[] | null = null;
    private image: string | null = null;
    private isLink: boolean | null = false;
    private featuredPosition: number = 0;
    private isFeatured: boolean | null = false;
    private overLayText: string | null = null;
    private shortName: any | null = null;
    private tournamentCategory: number = 0;
    private tournamentType: number = 0;
    private trnyFeePercent: number = 0;
    private isTrnyFeeTypePercent: boolean | null = false;
    private templateId: number = 0;
    private guaranteedAmount: number = 0;
    private passwordProtected: boolean | null = false;
    private participantEntryType: number = 0;
    private playerEntriesAllowed: number = 0;
    private eventSeriesType: number = 0;
    private flightedMTTType: number = 0;
    private eventSeriesTypes: any[] | null = null;
    private bountyType: number = 0;
    private bountyTopUpPercentage: number = 0;
    private multiDayData: LSMultiDayData | null = null;
    private flightedData: LSFlightedData | null = null;
    private guaranteedPrizePoolRanges: any[] | null = null;
    private blindLevelType: number = 0;
    private subLiquidityType: number = 0;
    private genderAllowed: string | null = null;
    private mixmaxData: LSMixMaxData | null = null;
    private lrCloseTime: number = 0;
    private fontDetails: MTCTFontDetails | null = null;
    private remainingLRTime: number = 0;
    private realNameGame: boolean | null = false;
    private ageRestrictionValue: number = 0;
    private reentryInfo: LSReentryInfo | null = null;
    private defaultChips: number = 0;
    private bigBlindAmount: number = 0;
    private speedFilterType: number = 0;
    private onDemandMTCT: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        tourneyStartTime: any | null = null,
        name: any | null = null,
        gameType: number = 0,
        limitType: number = 0,
        buyIn: number = 0,
        entryFee: number = 0,
        noOfPlayers: number = 0,
        status: number = 0,
        gamePlayType: number = 0,
        noOfParticipants: number = 0,
        maxParticipants: number = 0,
        announceTime: any | null = null,
        registrationStartTime: any | null = null,
        registrationEndTime: any | null = null,
        allowLateRegistration: boolean | null = false,
        tourneyEndTime: any | null = null,
        tourneyCurrentLevel: number = 0,
        totalPrizePool: number = 0,
        addonSupported: boolean | null = false,
        rebuyAddonStatus: number = 0,
        firstRankPlayerName: string | null = null,
        mtctEvents: any[] | null = null,
        totalRoundCount: number = 0,
        maxSeats: number = 0,
        mtsgId: number = 0,
        tournamentDescription: any | null = null,
        tabIds: any[] | null = null,
        gameCurrency: string | null = null,
        trnyTypeId: number = 0,
        buyInInfo: any | null = null,
        colorCode: number = 0,
        partyPoints: number = 0,
        lateRegInProgress: boolean | null = false,
        unregClosed: boolean | null = false,
        bountyAmount: number = 0,
        allowLateRegistrationTillLevel: number = 0,
        unRegOffset: number = 0,
        mttType: number = 0,
        trnyMinPlrs: number = 0,
        isQuinteTourney: boolean | null = false,
        quintepokChampFee: number = 0,
        allowedCountryCodes: string | null = null,
        qualifiers: any[] | null = null,
        image: string | null = null,
        isLink: boolean | null = false,
        featuredPosition: number = 0,
        isFeatured: boolean | null = false,
        overLayText: string | null = null,
        shortName: any | null = null,
        tournamentCategory: number = 0,
        tournamentType: number = 0,
        trnyFeePercent: number = 0,
        isTrnyFeeTypePercent: boolean | null = false,
        templateId: number = 0,
        guaranteedAmount: number = 0,
        passwordProtected: boolean | null = false,
        participantEntryType: number = 0,
        playerEntriesAllowed: number = 0,
        eventSeriesType: number = 0,
        flightedMTTType: number = 0,
        eventSeriesTypes: any[] | null = null,
        bountyType: number = 0,
        bountyTopUpPercentage: number = 0,
        multiDayData: LSMultiDayData | null = null,
        flightedData: LSFlightedData | null = null,
        guaranteedPrizePoolRanges: any[] | null = null,
        blindLevelType: number = 0,
        subLiquidityType: number = 0,
        genderAllowed: string | null = null,
        mixmaxData: LSMixMaxData | null = null,
        lrCloseTime: number = 0,
        fontDetails: MTCTFontDetails | null = null,
        remainingLRTime: number = 0,
        realNameGame: boolean | null = false,
        ageRestrictionValue: number = 0,
        reentryInfo: LSReentryInfo | null = null,
        defaultChips: number = 0,
        bigBlindAmount: number = 0,
        speedFilterType: number = 0,
        onDemandMTCT: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tourneyStartTime = tourneyStartTime;
        this.name = name;
        this.gameType = gameType;
        this.limitType = limitType;
        this.buyIn = buyIn;
        this.entryFee = entryFee;
        this.noOfPlayers = noOfPlayers;
        this.status = status;
        this.gamePlayType = gamePlayType;
        this.noOfParticipants = noOfParticipants;
        this.maxParticipants = maxParticipants;
        this.announceTime = announceTime;
        this.registrationStartTime = registrationStartTime;
        this.registrationEndTime = registrationEndTime;
        this.allowLateRegistration = allowLateRegistration;
        this.tourneyEndTime = tourneyEndTime;
        this.tourneyCurrentLevel = tourneyCurrentLevel;
        this.totalPrizePool = totalPrizePool;
        this.addonSupported = addonSupported;
        this.rebuyAddonStatus = rebuyAddonStatus;
        this.firstRankPlayerName = firstRankPlayerName;
        this.mtctEvents = mtctEvents;
        this.totalRoundCount = totalRoundCount;
        this.maxSeats = maxSeats;
        this.mtsgId = mtsgId;
        this.tournamentDescription = tournamentDescription;
        this.tabIds = tabIds;
        this.gameCurrency = gameCurrency;
        this.trnyTypeId = trnyTypeId;
        this.buyInInfo = buyInInfo;
        this.colorCode = colorCode;
        this.partyPoints = partyPoints;
        this.lateRegInProgress = lateRegInProgress;
        this.unregClosed = unregClosed;
        this.bountyAmount = bountyAmount;
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
        this.unRegOffset = unRegOffset;
        this.mttType = mttType;
        this.trnyMinPlrs = trnyMinPlrs;
        this.isQuinteTourney = isQuinteTourney;
        this.quintepokChampFee = quintepokChampFee;
        this.allowedCountryCodes = allowedCountryCodes;
        this.qualifiers = qualifiers;
        this.image = image;
        this.isLink = isLink;
        this.featuredPosition = featuredPosition;
        this.isFeatured = isFeatured;
        this.overLayText = overLayText;
        this.shortName = shortName;
        this.tournamentCategory = tournamentCategory;
        this.tournamentType = tournamentType;
        this.trnyFeePercent = trnyFeePercent;
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
        this.templateId = templateId;
        this.guaranteedAmount = guaranteedAmount;
        this.passwordProtected = passwordProtected;
        this.participantEntryType = participantEntryType;
        this.playerEntriesAllowed = playerEntriesAllowed;
        this.eventSeriesType = eventSeriesType;
        this.flightedMTTType = flightedMTTType;
        this.eventSeriesTypes = eventSeriesTypes;
        this.bountyType = bountyType;
        this.bountyTopUpPercentage = bountyTopUpPercentage;
        this.multiDayData = multiDayData;
        this.flightedData = flightedData;
        this.guaranteedPrizePoolRanges = guaranteedPrizePoolRanges;
        this.blindLevelType = blindLevelType;
        this.subLiquidityType = subLiquidityType;
        this.genderAllowed = genderAllowed;
        this.mixmaxData = mixmaxData;
        this.lrCloseTime = lrCloseTime;
        this.fontDetails = fontDetails;
        this.remainingLRTime = remainingLRTime;
        this.realNameGame = realNameGame;
        this.ageRestrictionValue = ageRestrictionValue;
        this.reentryInfo = reentryInfo;
        this.defaultChips = defaultChips;
        this.bigBlindAmount = bigBlindAmount;
        this.speedFilterType = speedFilterType;
        this.onDemandMTCT = onDemandMTCT;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getEntryFee(): number {
        return this.entryFee;
    }

    setEntryFee(entryFee: number) {
        this.entryFee = entryFee;
    }
    getNoOfPlayers(): number {
        return this.noOfPlayers;
    }

    setNoOfPlayers(noOfPlayers: number) {
        this.noOfPlayers = noOfPlayers;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
    getMaxParticipants(): number {
        return this.maxParticipants;
    }

    setMaxParticipants(maxParticipants: number) {
        this.maxParticipants = maxParticipants;
    }
    getAnnounceTime(): any | null {
        return this.announceTime;
    }

    setAnnounceTime(announceTime: any | null) {
        this.announceTime = announceTime;
    }
    getRegistrationStartTime(): any | null {
        return this.registrationStartTime;
    }

    setRegistrationStartTime(registrationStartTime: any | null) {
        this.registrationStartTime = registrationStartTime;
    }
    getRegistrationEndTime(): any | null {
        return this.registrationEndTime;
    }

    setRegistrationEndTime(registrationEndTime: any | null) {
        this.registrationEndTime = registrationEndTime;
    }
    getAllowLateRegistration(): boolean | null {
        return this.allowLateRegistration;
    }

    setAllowLateRegistration(allowLateRegistration: boolean | null) {
        this.allowLateRegistration = allowLateRegistration;
    }
    getTourneyEndTime(): any | null {
        return this.tourneyEndTime;
    }

    setTourneyEndTime(tourneyEndTime: any | null) {
        this.tourneyEndTime = tourneyEndTime;
    }
    getTourneyCurrentLevel(): number {
        return this.tourneyCurrentLevel;
    }

    setTourneyCurrentLevel(tourneyCurrentLevel: number) {
        this.tourneyCurrentLevel = tourneyCurrentLevel;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getAddonSupported(): boolean | null {
        return this.addonSupported;
    }

    setAddonSupported(addonSupported: boolean | null) {
        this.addonSupported = addonSupported;
    }
    getRebuyAddonStatus(): number {
        return this.rebuyAddonStatus;
    }

    setRebuyAddonStatus(rebuyAddonStatus: number) {
        this.rebuyAddonStatus = rebuyAddonStatus;
    }
    getFirstRankPlayerName(): string | null {
        return this.firstRankPlayerName;
    }

    setFirstRankPlayerName(firstRankPlayerName: string | null) {
        this.firstRankPlayerName = firstRankPlayerName;
    }
    getMtctEvents(): any[] | null {
        return this.mtctEvents;
    }

    setMtctEvents(mtctEvents: any[] | null) {
        this.mtctEvents = mtctEvents;
    }
    getTotalRoundCount(): number {
        return this.totalRoundCount;
    }

    setTotalRoundCount(totalRoundCount: number) {
        this.totalRoundCount = totalRoundCount;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getMtsgId(): number {
        return this.mtsgId;
    }

    setMtsgId(mtsgId: number) {
        this.mtsgId = mtsgId;
    }
    getTournamentDescription(): any | null {
        return this.tournamentDescription;
    }

    setTournamentDescription(tournamentDescription: any | null) {
        this.tournamentDescription = tournamentDescription;
    }
    getTabIds(): any[] | null {
        return this.tabIds;
    }

    setTabIds(tabIds: any[] | null) {
        this.tabIds = tabIds;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getTrnyTypeId(): number {
        return this.trnyTypeId;
    }

    setTrnyTypeId(trnyTypeId: number) {
        this.trnyTypeId = trnyTypeId;
    }
    getBuyInInfo(): any | null {
        return this.buyInInfo;
    }

    setBuyInInfo(buyInInfo: any | null) {
        this.buyInInfo = buyInInfo;
    }
    getColorCode(): number {
        return this.colorCode;
    }

    setColorCode(colorCode: number) {
        this.colorCode = colorCode;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
    getBountyAmount(): number {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: number) {
        this.bountyAmount = bountyAmount;
    }
    getAllowLateRegistrationTillLevel(): number {
        return this.allowLateRegistrationTillLevel;
    }

    setAllowLateRegistrationTillLevel(allowLateRegistrationTillLevel: number) {
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
    }
    getUnRegOffset(): number {
        return this.unRegOffset;
    }

    setUnRegOffset(unRegOffset: number) {
        this.unRegOffset = unRegOffset;
    }
    getMttType(): number {
        return this.mttType;
    }

    setMttType(mttType: number) {
        this.mttType = mttType;
    }
    getTrnyMinPlrs(): number {
        return this.trnyMinPlrs;
    }

    setTrnyMinPlrs(trnyMinPlrs: number) {
        this.trnyMinPlrs = trnyMinPlrs;
    }
    getIsQuinteTourney(): boolean | null {
        return this.isQuinteTourney;
    }

    setIsQuinteTourney(isQuinteTourney: boolean | null) {
        this.isQuinteTourney = isQuinteTourney;
    }
    getQuintepokChampFee(): number {
        return this.quintepokChampFee;
    }

    setQuintepokChampFee(quintepokChampFee: number) {
        this.quintepokChampFee = quintepokChampFee;
    }
    getAllowedCountryCodes(): string | null {
        return this.allowedCountryCodes;
    }

    setAllowedCountryCodes(allowedCountryCodes: string | null) {
        this.allowedCountryCodes = allowedCountryCodes;
    }
    getQualifiers(): any[] | null {
        return this.qualifiers;
    }

    setQualifiers(qualifiers: any[] | null) {
        this.qualifiers = qualifiers;
    }
    getImage(): string | null {
        return this.image;
    }

    setImage(image: string | null) {
        this.image = image;
    }
    getIsLink(): boolean | null {
        return this.isLink;
    }

    setIsLink(isLink: boolean | null) {
        this.isLink = isLink;
    }
    getFeaturedPosition(): number {
        return this.featuredPosition;
    }

    setFeaturedPosition(featuredPosition: number) {
        this.featuredPosition = featuredPosition;
    }
    getIsFeatured(): boolean | null {
        return this.isFeatured;
    }

    setIsFeatured(isFeatured: boolean | null) {
        this.isFeatured = isFeatured;
    }
    getOverLayText(): string | null {
        return this.overLayText;
    }

    setOverLayText(overLayText: string | null) {
        this.overLayText = overLayText;
    }
    getShortName(): any | null {
        return this.shortName;
    }

    setShortName(shortName: any | null) {
        this.shortName = shortName;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
    getTournamentType(): number {
        return this.tournamentType;
    }

    setTournamentType(tournamentType: number) {
        this.tournamentType = tournamentType;
    }
    getTrnyFeePercent(): number {
        return this.trnyFeePercent;
    }

    setTrnyFeePercent(trnyFeePercent: number) {
        this.trnyFeePercent = trnyFeePercent;
    }
    getIsTrnyFeeTypePercent(): boolean | null {
        return this.isTrnyFeeTypePercent;
    }

    setIsTrnyFeeTypePercent(isTrnyFeeTypePercent: boolean | null) {
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
    }
    getTemplateId(): number {
        return this.templateId;
    }

    setTemplateId(templateId: number) {
        this.templateId = templateId;
    }
    getGuaranteedAmount(): number {
        return this.guaranteedAmount;
    }

    setGuaranteedAmount(guaranteedAmount: number) {
        this.guaranteedAmount = guaranteedAmount;
    }
    getPasswordProtected(): boolean | null {
        return this.passwordProtected;
    }

    setPasswordProtected(passwordProtected: boolean | null) {
        this.passwordProtected = passwordProtected;
    }
    getParticipantEntryType(): number {
        return this.participantEntryType;
    }

    setParticipantEntryType(participantEntryType: number) {
        this.participantEntryType = participantEntryType;
    }
    getPlayerEntriesAllowed(): number {
        return this.playerEntriesAllowed;
    }

    setPlayerEntriesAllowed(playerEntriesAllowed: number) {
        this.playerEntriesAllowed = playerEntriesAllowed;
    }
    getEventSeriesType(): number {
        return this.eventSeriesType;
    }

    setEventSeriesType(eventSeriesType: number) {
        this.eventSeriesType = eventSeriesType;
    }
    getFlightedMTTType(): number {
        return this.flightedMTTType;
    }

    setFlightedMTTType(flightedMTTType: number) {
        this.flightedMTTType = flightedMTTType;
    }
    getEventSeriesTypes(): any[] | null {
        return this.eventSeriesTypes;
    }

    setEventSeriesTypes(eventSeriesTypes: any[] | null) {
        this.eventSeriesTypes = eventSeriesTypes;
    }
    getBountyType(): number {
        return this.bountyType;
    }

    setBountyType(bountyType: number) {
        this.bountyType = bountyType;
    }
    getBountyTopUpPercentage(): number {
        return this.bountyTopUpPercentage;
    }

    setBountyTopUpPercentage(bountyTopUpPercentage: number) {
        this.bountyTopUpPercentage = bountyTopUpPercentage;
    }
    getMultiDayData(): LSMultiDayData | null {
        return this.multiDayData;
    }

    setMultiDayData(multiDayData: LSMultiDayData | null) {
        this.multiDayData = multiDayData;
    }
    getFlightedData(): LSFlightedData | null {
        return this.flightedData;
    }

    setFlightedData(flightedData: LSFlightedData | null) {
        this.flightedData = flightedData;
    }
    getGuaranteedPrizePoolRanges(): any[] | null {
        return this.guaranteedPrizePoolRanges;
    }

    setGuaranteedPrizePoolRanges(guaranteedPrizePoolRanges: any[] | null) {
        this.guaranteedPrizePoolRanges = guaranteedPrizePoolRanges;
    }
    getBlindLevelType(): number {
        return this.blindLevelType;
    }

    setBlindLevelType(blindLevelType: number) {
        this.blindLevelType = blindLevelType;
    }
    getSubLiquidityType(): number {
        return this.subLiquidityType;
    }

    setSubLiquidityType(subLiquidityType: number) {
        this.subLiquidityType = subLiquidityType;
    }
    getGenderAllowed(): string | null {
        return this.genderAllowed;
    }

    setGenderAllowed(genderAllowed: string | null) {
        this.genderAllowed = genderAllowed;
    }
    getMixmaxData(): LSMixMaxData | null {
        return this.mixmaxData;
    }

    setMixmaxData(mixmaxData: LSMixMaxData | null) {
        this.mixmaxData = mixmaxData;
    }
    getLrCloseTime(): number {
        return this.lrCloseTime;
    }

    setLrCloseTime(lrCloseTime: number) {
        this.lrCloseTime = lrCloseTime;
    }
    getFontDetails(): MTCTFontDetails | null {
        return this.fontDetails;
    }

    setFontDetails(fontDetails: MTCTFontDetails | null) {
        this.fontDetails = fontDetails;
    }
    getRemainingLRTime(): number {
        return this.remainingLRTime;
    }

    setRemainingLRTime(remainingLRTime: number) {
        this.remainingLRTime = remainingLRTime;
    }
    getRealNameGame(): boolean | null {
        return this.realNameGame;
    }

    setRealNameGame(realNameGame: boolean | null) {
        this.realNameGame = realNameGame;
    }
    getAgeRestrictionValue(): number {
        return this.ageRestrictionValue;
    }

    setAgeRestrictionValue(ageRestrictionValue: number) {
        this.ageRestrictionValue = ageRestrictionValue;
    }
    getReentryInfo(): LSReentryInfo | null {
        return this.reentryInfo;
    }

    setReentryInfo(reentryInfo: LSReentryInfo | null) {
        this.reentryInfo = reentryInfo;
    }
    getDefaultChips(): number {
        return this.defaultChips;
    }

    setDefaultChips(defaultChips: number) {
        this.defaultChips = defaultChips;
    }
    getBigBlindAmount(): number {
        return this.bigBlindAmount;
    }

    setBigBlindAmount(bigBlindAmount: number) {
        this.bigBlindAmount = bigBlindAmount;
    }
    getSpeedFilterType(): number {
        return this.speedFilterType;
    }

    setSpeedFilterType(speedFilterType: number) {
        this.speedFilterType = speedFilterType;
    }
    getOnDemandMTCT(): boolean | null {
        return this.onDemandMTCT;
    }

    setOnDemandMTCT(onDemandMTCT: boolean | null) {
        this.onDemandMTCT = onDemandMTCT;
    }
}
