import { Message } from '../../message';

export class TableBoosterTriggered extends Message {
    private MESSAGE_NAME: string = 'TableBoosterTriggered';
    private boosterType: number = 0;
    private fromBB: number = 0;
    private toBB: number = 0;
    private gameId: number = 0;
    private winnerPerc: number = 0;
    private looserPerc: number = 0;
    private shortAnimation: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        boosterType: number = 0,
        fromBB: number = 0,
        toBB: number = 0,
        gameId: number = 0,
        winnerPerc: number = 0,
        looserPerc: number = 0,
        shortAnimation: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.boosterType = boosterType;
        this.fromBB = fromBB;
        this.toBB = toBB;
        this.gameId = gameId;
        this.winnerPerc = winnerPerc;
        this.looserPerc = looserPerc;
        this.shortAnimation = shortAnimation;
    }

    getBoosterType(): number {
        return this.boosterType;
    }

    setBoosterType(boosterType: number) {
        this.boosterType = boosterType;
    }
    getFromBB(): number {
        return this.fromBB;
    }

    setFromBB(fromBB: number) {
        this.fromBB = fromBB;
    }
    getToBB(): number {
        return this.toBB;
    }

    setToBB(toBB: number) {
        this.toBB = toBB;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getWinnerPerc(): number {
        return this.winnerPerc;
    }

    setWinnerPerc(winnerPerc: number) {
        this.winnerPerc = winnerPerc;
    }
    getLooserPerc(): number {
        return this.looserPerc;
    }

    setLooserPerc(looserPerc: number) {
        this.looserPerc = looserPerc;
    }
    getShortAnimation(): boolean | null {
        return this.shortAnimation;
    }

    setShortAnimation(shortAnimation: boolean | null) {
        this.shortAnimation = shortAnimation;
    }
}
