import { Message } from '../../message';

export class SemiFinalCalendar extends Message {
    private MESSAGE_NAME: string = 'SemiFinalCalendar';
    private semiFinalGroups: any[] | null = null;
    private totalEntries: number = 0;
    private distribMultiplier: number = 0;
    private maxChipsPerDay: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        semiFinalGroups: any[] | null = null,
        totalEntries: number = 0,
        distribMultiplier: number = 0,
        maxChipsPerDay: number = 0,
    ) {
        super(reqServerPeerId);
        this.semiFinalGroups = semiFinalGroups;
        this.totalEntries = totalEntries;
        this.distribMultiplier = distribMultiplier;
        this.maxChipsPerDay = maxChipsPerDay;
    }

    getSemiFinalGroups(): any[] | null {
        return this.semiFinalGroups;
    }

    setSemiFinalGroups(semiFinalGroups: any[] | null) {
        this.semiFinalGroups = semiFinalGroups;
    }
    getTotalEntries(): number {
        return this.totalEntries;
    }

    setTotalEntries(totalEntries: number) {
        this.totalEntries = totalEntries;
    }
    getDistribMultiplier(): number {
        return this.distribMultiplier;
    }

    setDistribMultiplier(distribMultiplier: number) {
        this.distribMultiplier = distribMultiplier;
    }
    getMaxChipsPerDay(): number {
        return this.maxChipsPerDay;
    }

    setMaxChipsPerDay(maxChipsPerDay: number) {
        this.maxChipsPerDay = maxChipsPerDay;
    }
}
