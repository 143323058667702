import { Routes } from '@angular/router';

import { PokerPDollarsSynopsisResolver } from './poker-p-dollars-synopsis.resolver';
import { PokerPDollarsResolver } from './poker-p-dollars.resolver';

export const pokerPDollarsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPDollarsResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerPDollarsSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPDollarsResolver,
        },
    },
];
