import { Message } from '../../message';

export class ResponseTourenyTablePlayerList extends Message {
    private MESSAGE_NAME: string = 'ResponseTourenyTablePlayerList';
    private mtctId: number = 0;
    private tableNo: number = 0;
    private mtctTablePlayers: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, tableNo: number = 0, mtctTablePlayers: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tableNo = tableNo;
        this.mtctTablePlayers = mtctTablePlayers;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTableNo(): number {
        return this.tableNo;
    }

    setTableNo(tableNo: number) {
        this.tableNo = tableNo;
    }
    getMtctTablePlayers(): any[] | null {
        return this.mtctTablePlayers;
    }

    setMtctTablePlayers(mtctTablePlayers: any[] | null) {
        this.mtctTablePlayers = mtctTablePlayers;
    }
}
