import { Component, Input } from '@angular/core';

import { Card } from '../../models/card';
import { GameTable } from '../../models/game-table';
import { Properties } from '../../utils/properties';

@Component({
    selector: 'pkr-community-cards',
    templateUrl: './community-cards.component.html',
})
export class CommunityCardsComponent {
    @Input() cards: Card[];
    @Input() gameTable: GameTable;
    xPos: any;
    yPos: any;

    getXAxis(): string {
        const communityPosX = Properties.propFile.COMMONSECTION.COMMUNITYHAND_POS.split('#');
        return `${communityPosX[0]}%`;
    }

    getYAxis(): string {
        const communityPosY = Properties.propFile.COMMONSECTION.COMMUNITYHAND_POS.split('#');
        return `${communityPosY[1]}%`;
    }

    getCommunityCardsSequence(index: number) {
        return Properties.getCommunityCardsSequence(index);
    }
}
