import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class BGViewModel {
    gameService: any;
    tableContainer: any;
    view: any;
    constructor(gameService, tableContainer) {
        this.gameService = gameService;
        this.tableContainer = tableContainer;
        this.view = undefined;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.tableId = this.gameService.getPeerId();
        this.currTablePeerId = 'table' + this.tableId;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.TABLE_RESIZE_START, this.onTableResize.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.MAX_BUTTON_CLICKED, this.onMaxBtnClick.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.END_SPIN_MULTIPLIER, this.updatePrizePoolData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.PLAY_SOUNDS, this.playAllSounds.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.STOP_SOUNDS, this.stopAllSounds.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_BACKGROUND_ON_DISC, this.updateTableBgOnDisc.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_PRIZEPOOLVALUE, this.updatePrizePoolData.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_THEME_CHANGE, this.updateTableBgStyle.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_THEME_CHANGE, this.updateTableZoomStyle.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ZOOM_IN_BG_RIM, this.checkBgRimZoomInMode.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPADATEEXPOSPINBG, this.updateExpoSpinBG.bind(this));
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_GAME_STATUS,this.updateTableBgOnDisc.bind(this));
        this.spinsFfMap = {
            Frame0: 'Default',
            Frame1: 'MultiplierA',
            Frame2: 'MultiplierB',
            Frame3: 'MultiplierE',
        };
    };
    updateExpoSpinBG = function () {
        this.view.updateExpoSpinBG();
    };
    updateTableBgOnDisc = function () {
        const tableBGType = this.gameService.getTableBgType();
        if (tableBGType) this.view.updateTableBgOnDisc(tableBGType);
    };
    updatePrizePoolData = function (ppVal) {
        window.parent.dispatchEvent(new Event('EndSpinAnimation'));
        // window.parent.AppController.SendMessageToSwitcher({
        //   eventName: "EndSpinAnimation",
        //   TableId: this.gameService.getPeerId()
        // });
        if (!ppVal.detail) {
            ppVal['detail'] = this.gameService.getSNGJPPayoutAnimation();
        }
        this.view.updatePrizePoolData(ppVal.detail, this.gameService.getTableStatusData());
    };
    updateBGRim = function (boosterName, isBoosterEnabled) {
        this.view.updateBGRim(boosterName, isBoosterEnabled);
        this.view.updateTriggerBase();
    };
    onTableBgUpdate = function (data) {
        this.view.onTableBgUpdate(data);
    };
    onTableResize = function () {
        this.view.tableResize(this.tableContainer.currentTableScale());
        if (this.tableContainer.currentTableScale() == 0.5) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.DISPLAY_MAX_BTN);
        } else {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.DISPLAY_MIN_BTN);
        }
    };
    onMaxBtnClick = function () {
        this.view.showHamburgerBtn(false);
    };
    // changeTableBg = function() {
    //   let data = this.gameService.getSNGJPPayoutAnimation();
    //   if(data && data.tableBackgroundImage)
    //   this.view.changeTableBg(this.gameService.getSNGJPPayoutAnimation());
    // };
    stopAllSounds = function () {
        CommonGameProperties.facade[this.currTablePeerId].soundObj.setSoundVolume(0);
    };
    playAllSounds = function () {};
    // change table bg style from setting panel for ff games
    // updateTableBgStyle = function (data) {
    //     if (this.gameService.getGameTableType() != serverIdConstants.TABLE_TYPE_SPINS) {
    //         let bgFelt = "Frame" + data.cardStyle.tableFelt;
    //         this.view.updateTableBgOnDisc(bgFelt);
    //     }
    // }
    // change table bg & bg style from setting panel for ff games
    updateTableZoomStyle = function (data) {
        if (this.gameService.getGameTableType() != serverIdConstants.TABLE_TYPE_SPINS) {
            const bgFelt = 'Frame' + data.detail.cardStyle.tableFelt;
            const bgZoom = data.detail.cardStyle.tableStyle;
            this.view.updateTableBgZoomStyle(bgFelt, bgZoom);
        } else {
            // let bgFelt = this.spinsFfMap["Frame" + data.cardStyle.tableFelt];
            const bgZoom = data.detail.cardStyle.tableStyle;
            const bgFelt = 'Default';
            this.view.updateTableBgZoomStyle(bgFelt, bgZoom);
        }
    };
    removeBGRim = function () {
        this.view.removeBGAfterBooster();
    };
    updateTriggerBase = function () {
        this.view.updateTriggerBase();
    };
    checkBgRimZoomInMode = function () {
        this.view.tableResize(this.tableContainer.currentTableScale());
    };
}
