<div class="pp-promo-header {{ item.heroStructure }} {{ item.class }}" [ngClass]="{ 'promo-single-cta': promoCta }">
    <div class="col p-0" [class]="item.heroStructure === 'ImageCenterTextCenter' ? 'col p-0 d-flex flex-column' : 'col p-0 '">
        <div *ngIf="item.heroStructure !== 'ImageCenterNoText'" class="pp-promo-header-content-wrapper">
            <div *ngIf="item.promoTitle" class="promoTitle">
                <div [innerHtml]="item.promoTitle | trustAsHtml"></div>
            </div>
            <div *ngIf="item.promoSubtitle" class="promoTitle">
                <div [innerHtml]="item.promoSubtitle | trustAsHtml"></div>
            </div>
            <div *ngIf="item.ctA2Link; else singleButton" class="buttonWrapper">
                <div *ngIf="item.ctA1Link">
                    <a
                        class="{{ item.ctA1Class }}"
                        href="{{ item.ctA1Link.url }}"
                        [vnDynamicHtml]="item.ctA1Text"
                        [attributes]="attributesCta1"
                        (click)="ppPromoHeaderBannerCtaTracking(item.promoTitle, item.ctA1Link.url, item.ctA1Text)"></a>
                </div>
                <div *ngIf="item.ctA2Link">
                    <a
                        class="{{ item.ctA2Class }}"
                        href="{{ item.ctA2Link.url }}"
                        [vnDynamicHtml]="item.ctA2Text"
                        [attributes]="attributesCta2"
                        (click)="ppPromoHeaderBannerCtaTracking(item.promoTitle, item.ctA2Link.url, item.ctA2Text)"></a>
                </div>
            </div>
            <ng-template #singleButton>
                <div *ngIf="item.ctA1Link">
                    <a
                        class="{{ item.ctA1Class }}"
                        href="{{ item.ctA1Link.url }}"
                        [vnDynamicHtml]="item.ctA1Text"
                        [attributes]="attributesCta1"
                        (click)="ppPromoHeaderBannerCtaTracking(item.promoTitle, item.ctA1Link.url, item.ctA1Text)"></a>
                </div>
            </ng-template>
        </div>
        <div class="pp-promo-header-image-wrapper">
            <video *ngIf="item.videoImage && mobileEval === 'desktop'" autoplay muted loop poster="{{ item.heroImage.src }}" class="videoBackground">
                <source src="{{ item.videoImage.src }}" type="video/mp4" />
            </video>
            <img *ngIf="mobileEval === 'desktop' && item.heroImage" class="desktopImg" src="{{ item.heroImage.src }}" alt="Hero Image" />
            <img *ngIf="mobileEval === 'tablet' && item.heroImageTablet" class="tabletImg" src="{{ item.heroImageTablet.src }}" alt="Hero Image" />
            <img *ngIf="mobileEval === 'mobile' && item.heroImageMobile" class="mobileImg" src="{{ item.heroImageMobile.src }}" alt="HeroImage" />
        </div>
    </div>
    <div *ngIf="item.significantTerms" class="significantTerms" [style.color]="item.textColor">{{ item.significantTerms }}</div>
    <div *ngIf="item.backgroundImage" class="bgImage" [style.background-image]="'url(' + item.backgroundImage.src + ')'"></div>
    <div class="bgFull" [style.background-color]="item.backgroundColor"></div>

    <!-- Promo CTA -->
    <a *ngIf="promoCta" class="promo-single-cta" href="{{ promoCta.url }}" [attributes]="promoCta.attributes">&nbsp;</a>
</div>
