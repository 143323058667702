import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PPCashierOptionsBootstrapService } from './pp-cashier-options-bootstrap.service';
import { PPCashierOptionsService } from './pp-cashier-options.service';

export function providePPCashierOptions(): Provider[] {
    return [runOnAppInit(PPCashierOptionsBootstrapService), PPCashierOptionsService];
}
