import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import _ from 'lodash-es';
import moment from 'moment';
import 'moment/locale/en-in';

import { FilterEventsPipe } from '../ppl-event-filters.pipe';
import { EventsSortByDatePipe } from '../ppl-event-sortByDate.pipe';

moment.locale('en-in');

@Component({
    selector: 'ppl-events',
    templateUrl: 'ppl-events.component.html',
    standalone: true,
    imports: [CommonModule, FilterEventsPipe, EventsSortByDatePipe],
})
export class PPLEventsComponent extends PCComponent implements OnInit {
    @Input() itemsData: any;

    basePath: string;
    events: any[];
    eventType: string;
    showFilters = false;
    staticStrings: any = {};
    eventFilters = [
        {
            type: 'category',
            items: [],
            selectedItems: [],
        },
        {
            type: 'region',
            items: [],
            selectedItems: [],
        },
        {
            type: 'venue',
            items: [],
            selectedItems: [],
        },
    ] as any;

    // new Variables for new filter
    filterOptions: any = {};

    constructor(
        private page: Page,
        private router: Router,
    ) {
        super();
        this.basePath = this.page.homePage;
    }

    ngOnInit(): void {
        this.events = this.itemsData.pplEventWidgetContent;
        this.staticStrings = this.itemsData.eventWidgetStaticData;

        this.getAllEventCategories(this.itemsData.pplEventWidgetContent, 'eventCategory');
        this.getAllEventCategories(this.itemsData.pplEventWidgetContent, 'eventLocation');
        this.getAllEventCategories(this.itemsData.pplEventWidgetContent, 'eventVenue');
        this.checkEventType();
    }

    returnEventDate(startDate: string, endDate: string): string {
        const sDate = moment(startDate).format('Do');
        const eDate = moment(endDate).format('Do MMM YYYY');
        const sDateWithMonth = moment(startDate).format('Do MMM');
        const sDateWithMonthYear = moment(startDate).format('Do MMM YYYY');

        return moment(startDate).format('YYYY') === moment(endDate).format('YYYY')
            ? moment(startDate).format('MM') === moment(endDate).format('MM')
                ? `${sDate} - ${eDate}`
                : `${sDateWithMonth} - ${eDate}`
            : `${sDateWithMonthYear} - ${eDate}`;
    }

    getAllEventCategories(data: any, filterType: string): any {
        const filterIndexMap: { [key: string]: number } = {
            eventCategory: 0,
            eventLocation: 1,
            eventVenue: 2,
        };

        if (filterIndexMap.hasOwnProperty(filterType)) {
            data.forEach((element: any) => {
                const index = filterIndexMap[filterType];
                this.eventFilters[index].items.push(element.pplEventConfigContent[filterType]);
            });

            this.eventFilters.forEach((filter: any) => {
                filter.items = _.compact(_.uniq(filter.items));
            });
        }
    }

    returnSelectedItems(event: any, index: number): any {
        if (event.target.checked) {
            this.eventFilters[index].selectedItems.push(event.target.value);
        } else {
            const getCurrentValue = this.eventFilters[index].selectedItems.find((item: any) => item === event.target.value);
            this.eventFilters[index].selectedItems.splice(this.eventFilters[index].selectedItems.indexOf(getCurrentValue), 1);
        }
    }

    gotoDetails(link: string) {
        this.router.navigate([this.page.lang + '/' + link]);
    }
    checkEventType() {
        if (this.itemsData && this.itemsData.hasOwnProperty('parameters')) {
            if (this.itemsData.parameters.hasOwnProperty('isCategoryDetails')) {
                this.eventType = 'isCategoryDetails';
            } else {
                this.eventType =
                    this.itemsData.parameters.hasOwnProperty('eventType') && this.itemsData.parameters.eventType === 'past' ? 'past' : 'upcoming';
            }
        } else {
            this.eventType = 'categoryEvents';
        }
    }
    displayFilters() {
        this.showFilters = !this.showFilters;
    }
}
