import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';

@Injectable({ providedIn: 'root' })
export class LeaderBoardService {
    activeFeeds: string;

    constructor(private api: PokerApiService) {}

    getLeaderBoardByID(promoId: string) {
        return this.api.get(`leaderboard/GetLeaderboardFeedData?promoId=${promoId}`);
    }
}
