import { Message } from '../../message';

export class PMUQuintePokCounter extends Message {
    private MESSAGE_NAME: string = 'PMUQuintePokCounter';
    private mtctid: number = 0;
    private championshipAmount: number = 0;
    private counterStatus: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctid: number = 0, championshipAmount: number = 0, counterStatus: number = 0) {
        super(reqServerPeerId);
        this.mtctid = mtctid;
        this.championshipAmount = championshipAmount;
        this.counterStatus = counterStatus;
    }

    getMtctid(): number {
        return this.mtctid;
    }

    setMtctid(mtctid: number) {
        this.mtctid = mtctid;
    }
    getChampionshipAmount(): number {
        return this.championshipAmount;
    }

    setChampionshipAmount(championshipAmount: number) {
        this.championshipAmount = championshipAmount;
    }
    getCounterStatus(): number {
        return this.counterStatus;
    }

    setCounterStatus(counterStatus: number) {
        this.counterStatus = counterStatus;
    }
}
