import { Message } from '../../message';

export class ResponseGermanTaxValue extends Message {
    private MESSAGE_NAME: string = 'ResponseGermanTaxValue';
    private germanTaxInPercCentsMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, germanTaxInPercCentsMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.germanTaxInPercCentsMap = germanTaxInPercCentsMap;
    }

    getGermanTaxInPercCentsMap(): Map<any, any> | null {
        return this.germanTaxInPercCentsMap;
    }

    setGermanTaxInPercCentsMap(germanTaxInPercCentsMap: Map<any, any> | null) {
        this.germanTaxInPercCentsMap = germanTaxInPercCentsMap;
    }
}
