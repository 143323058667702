import camelCase from './camelCase.js';
import capitalize from './capitalize.js';
import deburr from './deburr.js';
import endsWith from './endsWith.js';
import escape from './escape.js';
import escapeRegExp from './escapeRegExp.js';
import kebabCase from './kebabCase.js';
import lowerCase from './lowerCase.js';
import lowerFirst from './lowerFirst.js';
import pad from './pad.js';
import padEnd from './padEnd.js';
import padStart from './padStart.js';
import parseInt from './parseInt.js';
import repeat from './repeat.js';
import replace from './replace.js';
import snakeCase from './snakeCase.js';
import split from './split.js';
import startCase from './startCase.js';
import startsWith from './startsWith.js';
import template from './template.js';
import templateSettings from './templateSettings.js';
import toLower from './toLower.js';
import toUpper from './toUpper.js';
import trim from './trim.js';
import trimEnd from './trimEnd.js';
import trimStart from './trimStart.js';
import truncate from './truncate.js';
import unescape from './unescape.js';
import upperCase from './upperCase.js';
import upperFirst from './upperFirst.js';
import words from './words.js';
export default {
  camelCase,
  capitalize,
  deburr,
  endsWith,
  escape,
  escapeRegExp,
  kebabCase,
  lowerCase,
  lowerFirst,
  pad,
  padEnd,
  padStart,
  parseInt,
  repeat,
  replace,
  snakeCase,
  split,
  startCase,
  startsWith,
  template,
  templateSettings,
  toLower,
  toUpper,
  trim,
  trimEnd,
  trimStart,
  truncate,
  unescape,
  upperCase,
  upperFirst,
  words
};