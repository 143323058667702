import { Message } from '../../message';

export class PokerChatRequest extends Message {
    private MESSAGE_NAME: string = 'PokerChatRequest';
    private messageBody: string | null = null;
    private msgType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, messageBody: string | null = null, msgType: number = 0) {
        super(reqServerPeerId);
        this.messageBody = messageBody;
        this.msgType = msgType;
    }

    getMessageBody(): string | null {
        return this.messageBody;
    }

    setMessageBody(messageBody: string | null) {
        this.messageBody = messageBody;
    }
    getMsgType(): number {
        return this.msgType;
    }

    setMsgType(msgType: number) {
        this.msgType = msgType;
    }
}
