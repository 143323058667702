<div *ngIf="!isMobile; else mobileHTML">
    <div
        class="chipChange"
        [class.rit-chipChange]="gameTable.isRitEnabled"
        *ngIf="gameTable.changeChip && gameTable.MAIN_POT.totalValue > 0"
        [style.left]="getXaxis()"
        [style.top]="getYaxis()">
        <div class="chip bulkmode" *ngIf="gameTable.isReplayerV3 && gameTable.currentActivePot > 0">
            <div class="chipgrids" *ngFor="let k of itemInRowRange">
                <ng-container *ngIf="items | async as items">
                    <span
                        class="showChip"
                        *ngFor="let item of items | slice: k * 8 : k * 8 + 8"
                        ngClass="{{ item }}"
                        [attr.dVal]="getDollars(item)"
                        [attr.cent]="getCents(item)"></span>
                </ng-container>
            </div>
        </div>
        <div *ngIf="!gameTable.isReplayerV3">
            <div class="chip" *ngFor="let key of chipKeys; let idx = index">
                <div *ngIf="idx < 4">
                    <div *ngFor="let chip of chiparray[key]">
                        <div *ngFor="let i of chip.nChips">
                            <span
                                class="showChip"
                                ngClass="{{ chip.nval }}"
                                [attr.dVal]="getDollars(chip.nval)"
                                [attr.cent]="getCents(chip.nval)"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pot" *ngIf="!gameTable.isReplayerV3">
                <p class="pot-value" *ngIf="gameTable.changeChip">
                    <span *ngIf="!gameTable.isTourny">{{
                        (gameTable.MAIN_POT.totalValue / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                    }}</span
                    ><span *ngIf="gameTable.isTourny">{{ (gameTable.MAIN_POT.totalValue / 100).toLocaleString() }}</span>
                </p>
            </div>
        </div>
        <div class="pot" *ngIf="gameTable.isReplayerV3">
            <p class="pot-value" *ngIf="gameTable.changeChip && gameTable.currentActivePot > 0">
                <span *ngIf="!gameTable.isTourny">{{
                    (gameTable.currentActivePot / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}</span
                ><span *ngIf="gameTable.isTourny">{{ (gameTable.currentActivePot / 100).toLocaleString() }}</span>
            </p>
        </div>
    </div>
</div>
<ng-template #mobileHTML>
    <div class="chipChange" *ngIf="gameTable.changeChip && gameTable.MAIN_POT.totalValue > 0" [style.left]="getXaxis()" [style.top]="getYaxis()">
        <div class="chip" *ngFor="let key of reverseChipKeys; let idx = index">
            <div class="chipgrids" *ngIf="idx < 6">
                <div *ngFor="let chip of chiparray[key]">
                    <div *ngFor="let i of chip.nChips">
                        <span class="showChip" ngClass="{{ chip.nval }}" [attr.dVal]="getDollars(chip.nval)" [attr.cent]="getCents(chip.nval)"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="pot">
            <p class="pot-value" [hidden]="formatChange(gameTable.MAIN_POT.totalValue)">
                <span *ngIf="!gameTable.isTourny">{{ amount }}</span
                ><span *ngIf="gameTable.isTourny">{{ amount }}</span>
            </p>
        </div>
    </div>
</ng-template>
<div *ngIf="!isMobile; else mobilepotHTML">
    <div
        class="pot totalpot"
        [class.rit-totalpot]="gameTable.isRitEnabled"
        [style.left]="gameTable.ROUND_POT.position.getXAxis()"
        [style.top]="gameTable.ROUND_POT.position.getYAxis()"
        *ngIf="gameTable.ROUND_POT.totalValue > 0">
        <div class="pot" [style.left]="pot.totalPot.getXAxis()" [style.top]="pot.totalPot.getYAxis()">
            <p class="pot-value">
                {{ content.POT }} : <span *ngIf="gameTable.isTourny">{{ (gameTable.ROUND_POT.totalValue / 100).toLocaleString() }}</span
                ><span *ngIf="!gameTable.isTourny">{{
                    (gameTable.ROUND_POT.totalValue / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}</span>
            </p>
        </div>
    </div>
</div>
<ng-template #mobilepotHTML>
    <div
        class="pot totalpot"
        [class.rit-totalpot]="gameTable.isRitEnabled"
        [style.left]="gameTable.ROUND_POT.position.getXAxis()"
        [style.top]="gameTable.ROUND_POT.position.getYAxis()"
        *ngIf="gameTable.ROUND_POT.totalValue > 0">
        <div class="pot" [style.left]="pot.totalPot.getXAxis()" [style.top]="pot.totalPot.getYAxis()">
            <p class="pot-value" [hidden]="formatChange()">
                {{ content.POT.toString().trim() }}: <span *ngIf="gameTable.isTourny">{{ amount }}</span
                ><span *ngIf="!gameTable.isTourny">{{ amount }}</span>
            </p>
        </div>
    </div>
</ng-template>
<div *ngIf="!isMobile; else mobileSidePot">
    <div class="sidePot" id="sidePotmain" [class.ritsidepot]="gameTable.isRitEnabled">
        <div class="sidePot-content-main" [class.active]="gameTable.SIDE_POTS.length !== 0">
            <div class="sidePot-content" id="sidePot-content">
                <ng-container *ngFor="let sidePot of gameTable.SIDE_POTS">
                    <div
                        class="sidePot-amount"
                        [style.left]="sidePot.position._x + '%'"
                        [style.top]="sidePot.position._y + '%'"
                        [class.hidepots]="!sidePot.showPot">
                        <p class="pot-value" *ngIf="sidePot.totalValue > 0 && sidePot.showPot">
                            <span *ngIf="!gameTable.isTourny">{{
                                (sidePot.totalValue / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}</span
                            ><span *ngIf="gameTable.isTourny">{{ (sidePot.totalValue / 100).toLocaleString() }}</span
                            >&nbsp;
                            <span class="potIdNo" *ngIf="sidePot.potId > 0 && !gameTable.isReplayerV3">({{ sidePot.potId }})</span>
                            <span class="potIdNo" *ngIf="sidePot.potId > 0 && gameTable.isReplayerV3">{{ sidePot.potId }}</span>
                            <span class="potIdNo" *ngIf="sidePot.potId === 0 && gameTable.isReplayerV3">M</span>
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #mobileSidePot>
    <div class="sidePot mobile-sidePot" id="sidePotmain">
        <div class="sidePot-content-main" [class.active]="gameTable.SIDE_POTS.length !== 0">
            <div class="sidePot-content" id="sidePot-content">
                <ng-container *ngFor="let sidePot of gameTable.SIDE_POTS">
                    <div
                        class="sidePot-amount"
                        [style.left]="sidePot.position._x + '%'"
                        [style.top]="sidePot.position._y + '%'"
                        [class.hidepots]="!sidePot.showPot">
                        <p class="pot-value" *ngIf="sidePot.totalValue > 0 && sidePot.showPot" [hidden]="formatChange(sidePot.totalValue)">
                            <span class="sidepotchip"></span>
                            <span class="sidepotvalue" *ngIf="!gameTable.isTourny">{{ amount }}</span>
                            <span class="sidepotvalue" *ngIf="gameTable.isTourny">{{ amount }}</span
                            >&nbsp;<span *ngIf="sidePot.potId > 0" class="pot-id">{{ sidePot.potId }}</span>
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
