export const fontProperties = {
    fontProps: {
        potWinText: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        conformationPopUpStyle: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 360,
            align: 'center',
        },
        tapToUnmuteText: {
            fontFamily: 'Roboto',
            fontSize: 15,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 150,
            availableFontWidth: 110,
            align: 'center',
        },
        spinMultiplierText: {
            SpinStartText: {
                fontFamily: 'Roboto',
                fontSize: 48,
                fontWeight: 'bold',
                wordWrap: true,
                wordWrapWidth: 400,
                align: 'center',
                fill: '0xffffff',
            },
            letsSpinTxt: {
                fontFamily: 'Roboto',
                fontSize: 68,
                fontWeight: 'bold',
                availableFontWidth: 350,
                fill: '0xf54914',
            },
        },
        prizePoolTitleTxt: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        prizePoolRankTxt: {
            fontFamily: 'Roboto',
            fontSize: 13,
            fontWeight: 'normal',
            align: 'center',
            fill: '0xADADAD',
        },
        prizePoolRankPosTxt: {
            fontFamily: 'Roboto',
            fontSize: 8,
            fontWeight: 'normal',
            fill: '0xADADAD',
            align: 'center',
        },
        prizePoolPrizeTxt: {
            fontFamily: 'Roboto',
            fontSize: 12,
            fontWeight: 'bold',
            align: 'center',
            fill: '0xf5b308',
        },
        prizePoolValueTxt: {
            fontFamily: 'Roboto',
            fontSize: 27,
            fontWeight: 'bold',
            fill: '0xf5b308',
            align: 'center',
        },
        sidePotText: {
            fontFamily: 'Roboto',
            fontSize: 24,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        diamondText: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        timeBankText: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fontWeight: 'bold',
            fill: '0xF9DBAC',
        },
        diamondTextCopy: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        tableTxt: {
            fontFamily: 'Roboto',
            fontSize: 17,
            fontStyle: 'bold',
            fill: '0xffffff',
        },
        tableTotalPotText: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: false,
        },
        ATHDummyMassage: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        tableKothText: {
            fontFamily: 'Roboto',
            fontSize: 24,
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            availableFontWidth: 162,
            align: 'center',
        },
        tablePotText: {
            fontFamily: 'Roboto',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '0xffffff',
        },
        tablePotTitleText: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
        },
        waitingText: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        timerText: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        balanceTxt: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xf5b308',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        userNameTxt: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'normal',
            fill: '0xffffff',
            availableFontWidth: 130,
            align: 'center',
        },
        playerBet: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        selectedOpt: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 150,
            availableFontWidth: 80,
            align: 'center',
            padding: 10,
        },
        playerActionLabelFont: {
            fontFamily: 'Roboto',
            fontSize: 20.5,
            fontWeight: 'bold',
            fill: '0xffffff',
            align: 'center',
            wordWrap: true,
            wordWrapWidth: 70,
            availableFontWidth: 90,
        },
        totalWinFont: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        userprofileStyle: {
            fontFamily: 'Roboto',
            fontSize: 24,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        genericPopUButtonTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 24,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 500,
        },
        genericPopUpTitleTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 500,
        },
        genericPopUpBodyTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'normal',
            fill: '0xE5E7E8',
            wordWrap: true,
            wordWrapWidth: 500,
        },
        handStrengthText: {
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 'bold',
            fill: '0x000000',
        },
        winningProbText: {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 'bold',
            fill: '0xffffff',
            availableFontWidth: 55,
            align: 'center',
        },
        winningProbText_1: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
            align: 'center',
        },
        gameInfoTitleText: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 380,
            availableFontWidth: 250,
            align: 'center',
        },
        iamBackText: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            availableFontWidth: 350,
            wordWrapWidth: 350,
            align: 'center',
        },
        forwardfdText: {
            fontFamily: 'Roboto',
            fontSize: 21,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            availableFontWidth: 350,
            wordWrapWidth: 350,
            align: 'center',
        },
        infoSwitchTabText: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 160,
            availableFontWidth: 160,
            align: 'center',
        },
        infoSwitchTabTextBold: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
            availableFontWidth: 170,
            align: 'center',
        },
        infoSpinsText: {
            fontFamily: 'Roboto',
            fontSize: 38,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'center',
        },
        infoTableIdText: {
            fontFamily: 'Roboto',
            fontSize: 24,
            fontWeight: 'bold',
            fill: '0x979797',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        infoPrizeText: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        infoHardRankText: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 300,
            align: 'center',
        },
        infoHardRankTextBold: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 300,
            align: 'center',
        },
        infoGameRulesMainText: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fontWeight: 500,
            fill: '0x8F8F8F',
            wordWrap: true,
            wordWrapWidth: 600,
            align: 'left',
        },
        infoGameRulseText: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'normal',
            fill: '0x8F8F8F',
            wordWrap: true,
            wordWrapWidth: 550,
            align: 'left',
        },
        finalCupWinText: {
            fontFamily: 'Roboto',
            fontSize: 40,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'center',
            fill: ['0xffe14e', '0xfad946', '0xf3cf3d', '0xecc433', '0xe5b92a', '0xdeae1f', '0xd9a618', '0xd29c0f', '0xc98e03', '0xc98e03'],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        congratsWinText: {
            fontFamily: 'Antonio-Regular',
            fontSize: 30,
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'center',
            fill: '#faaa06',
        },
        congratsWinPopUpText: {
            fontFamily: 'Antonio-Regular',
            fontSize: 15,
            fontWeight: 400,
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
            fill: '0xffffff',
            availableFontWidth: 150,
        },
        congratsPopUpText: {
            fontFamily: 'Antonio-Regular',
            fontSize: 22,
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'center',
            fill: '0xffffff',
        },
        congratsPopUpWinMsgText: {
            fontFamily: 'Antonio-Regular',
            fontSize: 24,
            fontWeight: 400,
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'center',
            fill: '0xffffff',
        },
        widPopUpTitle: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 500,
            fill: '0xF5B308',
            availableFontWidth: 200,
        },
        widPopUpDesc: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 13,
            fill: '0xFFFFFF',
            wordWrap: true,
            wordWrapWidth: 240,
        },
        winCoinsText: {
            fontFamily: 'Roboto',
            fontSize: 12,
            fontWeight: 700,
            wordWrap: true,
            wordWrapWidth: 150,
            align: 'center',
            fill: '0xffffff',
        },
        widgetText: {
            fontFamily: 'FjallaOne-Regular',
            fontSize: 16,
            align: 'center',
            fill: '0xffffff',
            availableFontWidth: 100,
        },
        finalCupWinAmountText: {
            fontFamily: 'Roboto',
            fontSize: 64,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: ['0xffe14e', '0xfad946', '0xf3cf3d', '0xecc433', '0xe5b92a', '0xdeae1f', '0xd9a618', '0xd29c0f', '0xc98e03', '0xc98e03'],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        silverCupWinText: {
            fontFamily: 'Roboto',
            fontSize: 40,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: ['0xffe14e', '0xfcfcfc', '0xeaeaea', '0xd8d8d8', '0xcbcbcb', '0xbcbcbc', '0xb1b1b1', '0xa4a4a4', '0x949494', '0x858585'],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        silverCupWinAmountText: {
            fontFamily: 'Roboto',
            fontSize: 64,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: ['0xffe14e', '0xfcfcfc', '0xeaeaea', '0xd8d8d8', '0xcbcbcb', '0xbcbcbc', '0xb1b1b1', '0xa4a4a4', '0x949494', '0x858585'],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        boosterTitleText: {
            fontFamily: 'FjallaOne-Regular',
            fontSize: 20,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: ['0xFFFFFF', '0xFFFFFF', '0xFFFFFF', '0xFFFFFF'],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        boosterWinAmountText: {
            fontFamily: 'FjallaOne-Regular',
            fontSize: 30,
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: ['0xFFA800', '0xFFA800', '0xFFA800', '0xFFA800'],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        bronzeCupWinText: {
            fontFamily: 'Roboto',
            fontSize: 40,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: [
                '0xffe14e',
                '0xe9bcac ',
                '0xdba794',
                '0xd19883',
                '0xc78972',
                '0xbb765d',
                '0xb87055',
                '0xb56a4d',
                '0xb05f40',
                '0xad5a3a',
                '0xa9512e',
            ],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        bronzeCupWinAmountText: {
            fontFamily: 'Roboto',
            fontSize: 64,
            fontWeight: 'bold',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
            fill: [
                '0xffe14e',
                '0xe9bcac ',
                '0xdba794',
                '0xd19883',
                '0xc78972',
                '0xbb765d',
                '0xb87055',
                '0xb56a4d',
                '0xb05f40',
                '0xad5a3a',
                '0xa9512e',
            ],
            fillGradientStops: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            fillGradientType: 0,
        },
        expoSpinsWinAmountText: {
            fontFamily: 'Roboto',
            fontSize: 30,
            fontWeight: 'bold',
            align: 'center',
            fill: '0xCC6012',
        },
        expoSpinBuyOutText: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 25,
            align: 'left',
            fill: '0xFFFFFFE5',
        },
        expoSpinBalanceAmt: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 23,
            align: 'right',
            fontWeight: 'bold',
            fill: '0xFFFFFFE5',
        },
        pDollerTotalAmt: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 25,
            align: 'right',
            fontWeight: 'bold',
            fill: '0xFFFFFFE5',
        },
        expoSpinNoFundAlert: {
            fontFamily: 'Roboto',
            fontSize: 25,
            align: 'right',
            fontWeight: 'bold',
            fill: '0xE8490D',
        },
        expoSpinGermanTax: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 25,
            align: 'right',
            fontWeight: 'italic',
            fill: '0xFFFFFF80',
        },
        expoSpinTicketCount: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 25,
            align: 'right',
            fontWeight: 'bold',
            fill: '0xE8480C',
        },
        winStripTitleStyle: {
            fontFamily: 'Roboto',
            fontSize: 36,
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: 1.6,
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        playerStripCongStyle: {
            fontFamily: 'Roboto',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'center',
        },
        registerTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 200,
            align: 'center',
        },
        buyInTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 40,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 120,
            availableFontWidth: 120,
            align: 'center',
        },
        includingFeeTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '0x979797',
            wordWrap: true,
            wordWrapWidth: 400,
            align: 'right',
        },
        nextGameDetailsText: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 600,
            align: 'center',
        },
        addSpinGameTableText: {
            fontFamily: 'Roboto',
            fontSize: 40,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 400,
            availableFontWidth: 300,
            align: 'center',
        },
        spinValueTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 40,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 300,
            align: 'center',
        },
        requestTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 30,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
        },
        waitingTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 36,
            fontWeight: 'normal',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 400,
            availableFontWidth: 300,
            align: 'center',
        },
        registrationHeaderText: {
            fontFamily: 'Roboto',
            fontSize: 46,
            fontWeight: 'bold',
            fill: '0xffffff',
            align: 'center',
        },
        unRegisterLabelStyle: {
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            align: 'center',
            availableFontWidth: 240,
            wordWrapWidth: 350,
        },
        unRegistrationMessageStyle: {
            fontFamily: 'Roboto',
            fontSize: 26,
            fontWeight: 400,
            fill: '0xffffff',
            align: 'center',
            wordWrap: true,
            wordWrapWidth: 430,
        },
        userNameTxt_2: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fontWeight: 'normal',
            fill: '0xffffff',
            availableFontWidth: 130,
            wordWrap: true,
            wordWrapWidth: 170,
            align: 'center',
        },
        balanceTxt_2: {
            fontFamily: 'Roboto',
            fontSize: 23,
            fontWeight: 'bold',
            fill: '0xf5b308',
            wordWrap: true,
            align: 'center',
            availableFontWidth: 120,
            wordWrapWidth: 200,
        },
        boosterLCBfontProps: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 17,
            fill: '0xFFFFFF',
            align: 'center',
            fontWeight: 'bold',
            availableFontWidth: 95,
        },
        greenFontProps: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 30,
            fill: '0x3EEE00',
            align: 'center',
            availableFontWidth: 85,
            fontWeight: 700,
        },
        winCashbackAmountFontProps: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 30,
            fill: '0xFFD600',
            align: 'center',
            availableFontWidth: 85,
            fontWeight: 700,
        },
        optInFontProps: {
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: 22,
            fill: '0x3EEE00',
            align: 'center',
            availableFontWidth: 90,
            fontWeight: 700,
        },
        cashOutTxt: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fill: '0xE5E7E8',
            align: 'center',
            availableFontWidth: 280,
            fontWeight: 400,
        },
        cashOutBrandTxt: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fill: '0xC4C4C4',
            fontWeight: 400,
            wordWrap: true,
            wordWrapWidth: 400,
        },
        surChargeTxt: {
            fontFamily: 'Roboto',
            fontSize: 19,
            fill: '0x808080',
            align: 'center',
            availableFontWidth: 250,
            fontWeight: 400,
        },
        cashoutWaitingTxt: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fill: '0xC4C4C4',
            align: 'center',
            fontWeight: 400,
            wordWrap: true,
            wordWrapWidth: 400,
        },
        handsPlayedTextAndroid: {
            fontFamily: 'Roboto',
            fontSize: 34.5,
            fontWeight: 'bold',
            fill: '0xb6b6b6',
            availableFontWidth: 90,
            align: 'center',
        },
        handsPlayedTextIOS: {
            fontFamily: 'Roboto',
            fontSize: 32,
            fill: '0xb6b6b6',
            align: 'center',
            availableFontWidth: 90,
            fontWeight: 'bold',
        },
        antiTblHopHeaderWarningTxt: {
            fontFamily: 'Roboto',
            fontSize: 28,
            align: 'center',
            fill: '0xFFFFFF',
            fontWeight: 700,
        },
        antiTblHopAlerMsgTxt: {
            fontFamily: 'Roboto',
            fontSize: 25,
            align: 'left',
            fill: '0xFFFFFF',
            fontWeight: 700,
            wordWrap: true,
            wordWrapWidth: 500,
        },
        antiTblHopWarningTxt: {
            fontFamily: 'Roboto',
            fontSize: 25,
            align: 'left',
            fill: '0x919191',
            fontWeight: 700,
        },
        antiTblHopWarningMsg: {
            fontFamily: 'Roboto-Italic',
            fontSize: 25,
            align: 'left',
            fill: '0x919191',
            fontStyle: 'italic',
            wordWrap: true,
            fontWeight: 400,
            wordWrapWidth: 500,
        },
        antiTblHopXYHandBold: {
            fontFamily: 'Roboto-BlackItalic',
            fontSize: 25,
            align: 'left',
            fill: '0x919191',
            fontWeight: 400,
            wordWrap: true,
            wordWrapWidth: 500,
        },
        antiTblHopLearnMoreTxt: {
            fontFamily: 'Roboto',
            fontSize: 25,
            align: 'left',
            fill: '0xFFFFFF',
            fontWeight: 'normal',
        },
        ratholPopUpTitleStyle: {
            fontFamily: 'Roboto',
            fontSize: 32,
            lineHeight: 45,
            fontWeight: 'bold',
            fill: '0xffffff',
            wordWrap: true,
            wordWrapWidth: 500,
            fontVariant: 'small-caps',
        },
        ratholPopUpBodyTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 28,
            fontWeight: 'normal',
            fill: '0xE5E7E8',
            wordWrap: true,
            wordWrapWidth: 500,
            align: 'center',
        },
        demoMultiSytle: {
            default: {
                fontFamily: 'Arial',
                fontSize: '24px',
                fill: '#cccccc',
                align: 'center',
            },
            ml: {
                fontStyle: 'italic',
                fill: '#ff8888',
            },
            ms: {
                fontStyle: 'italic',
                fill: '#4488ff',
            },
            pixi: {
                fontSize: '64px',
                fill: '#efefef',
            },
        },
    },
};
