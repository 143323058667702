import { Message } from '../../message';

export class ShowEmoji extends Message {
    private MESSAGE_NAME: string = 'ShowEmoji';
    private emojiId: number = 0;
    private fromPlayerSeatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, emojiId: number = 0, fromPlayerSeatNo: number = 0) {
        super(reqServerPeerId);
        this.emojiId = emojiId;
        this.fromPlayerSeatNo = fromPlayerSeatNo;
    }

    getEmojiId(): number {
        return this.emojiId;
    }

    setEmojiId(emojiId: number) {
        this.emojiId = emojiId;
    }
    getFromPlayerSeatNo(): number {
        return this.fromPlayerSeatNo;
    }

    setFromPlayerSeatNo(fromPlayerSeatNo: number) {
        this.fromPlayerSeatNo = fromPlayerSeatNo;
    }
}
