import { Message } from '../../message';

export class ShowInfoOnTable extends Message {
    private MESSAGE_NAME: string = 'ShowInfoOnTable';
    private message: any | null = null;
    private time: number = 0;
    private countDown: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, message: any | null = null, time: number = 0, countDown: boolean | null = false) {
        super(reqServerPeerId);
        this.message = message;
        this.time = time;
        this.countDown = countDown;
    }

    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getTime(): number {
        return this.time;
    }

    setTime(time: number) {
        this.time = time;
    }
    getCountDown(): boolean | null {
        return this.countDown;
    }

    setCountDown(countDown: boolean | null) {
        this.countDown = countDown;
    }
}
