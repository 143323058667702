import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { Impetus } from '../services/impetus';

export class SelfEmojiView extends PIXI.Container {
    selfClass;
    isDragging = false;
    displayUtil = new DisplayUtil();
    constructor() {
        super();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }
    createView = function (EventDispatcher, vm, maxNoOfSeats) {
        this.EventDispatcher = EventDispatcher;
        this.viewModel = vm;
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            portAlignY: 'BOTTOM',
            EventDispatcher: this.EventDispatcher,
        });
        this.blackBg = this.displayUtil.getRectangleSprite(
            (window.innerWidth * 2) / this.scale.x,
            (window.innerHeight * 2) / this.scale.y,
            '#000000',
        );
        this.displayUtil.setObjectPosition(this.blackBg, -this.x / this.scale.x, -this.y / this.scale.y);
        //this.displayUtil.addInteraction(this.blackBg, this.closeEmojiPanel.bind(this));
        this.blackBg.on('touchstart', this.blackBgTouchStart.bind(this));
        this.blackBg.on('touchend', this.blackBgTouchEnd.bind(this));
        this.addChild(this.blackBg);
        this.blackBg.alpha = 0;
        this.blackBg.visible = false;
        this.dragMove = false;
        this.blackBg.buttonMode = false;
        this.displayUtil.setButtonActive(this.blackBg, true);
        this.selfClass = this;
        this.boosterEnabled = false;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait;
        this.playerAbsPos =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].playersUIData.playerAbsPos;
        const buttons = this.positionConfig.emojiPanelButtons;
        this.createEmojiBackground(buttons);
        this.setSize(720, 1280);
        this.visible = true;
        this.scaleFactor = 1.4;
        // this.checkForScale();
    };
    checkForScale = function () {
        this.scaleFactor = 1;
        if (uiConfig.windowWidth >= 400) {
            this.scaleFactor = 1.2;
        }
    };
    blackBgTouchStart = function (events) {
        if (events.data.originalEvent.touches && events.data.originalEvent.touches.length <= 1) {
            this.backBgTouchStarted = true;
        }
    };
    blackBgTouchEnd = function () {
        if (this.backBgTouchStarted) {
            this.backBgTouchStarted = false;
            this.closeEmojiPanel();
        }
    };
    createEmojiBackground = function (buttons) {
        this.selectEmojiContainer = this.displayUtil.getContainer();
        this.displayUtil.setObjectPosition(this.selectEmojiContainer, 0, -110);
        this.addChild(this.selectEmojiContainer);
        this.selectEmojiContainer.visible = false;
        this.infoBg = this.displayUtil.getRectangleSprite(580, 200, '#000000');
        this.infoBg.x = 70;
        this.infoBg.y = 930;
        this.selectEmojiContainer.addChild(this.infoBg);
        this.infoBg.alpha = 1;
        this.dragMove = false;
        // start wrapper1
        //this.wrapper1 = this.displayUtil.getRectangleSprite(580, 200, "0X1c1c1c");
        this.wrapper1 = this.displayUtil.getSprite('chat_bg');
        this.wrapper1.scale.x = 1.06;
        this.infoBg.addChild(this.wrapper1);
        this.displayUtil.setClickable(this.wrapper1, true);
        this.wrapper1.buttonMode = false;
        // end wrapper1
        // below container for texts
        //this.chatContainer = this.displayUtil.getRectangleSprite(470,150, "0X1c1c1c",true, 1, "0X131313");
        this.chatContainer = this.displayUtil.getContainer();
        this.scroll1 = this.displayUtil.getBorderRoundRectangleSprite(10, 50, '0XC4C4C4', 5, true, 1, '0XC4C4C4');
        this.wrapper1.addChild(this.scroll1);
        this.displayUtil.setObjectPosition(this.scroll1, 528, 20);
        this.wrapper1.addChild(this.chatContainer);
        this.chatContainer.alpha = 1;
        this.dragMove = false;
        this.wrapper1.visible = false;
        for (const i in buttons.textList) {
            this[buttons.textList[i]] = this.displayUtil.getButton(buttons.textList[i]);
            this.chatContainer.addChild(this[buttons.textList[i]]);
            this.displayUtil.setObjectPosition(
                this[buttons.textList[i]],
                this.positionConfig.emojiPanelButtons[buttons.textList[i] + 'Pos'].x,
                this.positionConfig.emojiPanelButtons[buttons.textList[i] + 'Pos'].y,
            );
            this.displayUtil.addInteraction(this[buttons.textList[i]], this['selectEmoji' + 'BtnClick'].bind(this));
            this.displayUtil.setClickable(this[buttons.textList[i]], true);
            this[buttons.textList[i]].visible = true;
            this[buttons.textList[i]].scale.set(0.4);
        }
        // start impetus1
        this.wrapper1Impetus = new Impetus({
            source: this.wrapper1,
            isPixiElement: true,
            bounce: false,
            boundY: [-50, 0],
            update: function (x1, y1) {
                this.chatContainer.y = y1;
            }.bind(this),
        });
        // end impetus1
        // start mask1
        this.wrapperMask = this.displayUtil.getRectangleSprite(580, 200, '0X1c1c1c');
        this.wrapperMask.name = 'mask';
        this.displayUtil.setObjectPosition(this.wrapperMask, 0, 0);
        this.wrapper1.addChild(this.wrapperMask);
        this.wrapper1.mask = this.wrapperMask;
        // end mask1
        // start wrapper2
        //this.wrapper2 = this.displayUtil.getRectangleSprite(580, 250, "0X1c1c1c");
        this.wrapper2 = this.displayUtil.getSprite('chat_bg');
        this.wrapper2.scale.x = 1.06;
        this.infoBg.addChild(this.wrapper2);
        this.displayUtil.setClickable(this.wrapper2, true);
        this.wrapper2.buttonMode = false;
        // end wrapper2
        // below container for emoji's
        //this.emojiContainer = this.displayUtil.getRectangleSprite(470,150, "0X1c1c1c", true, 1, "0X131313");
        this.emojiContainer = this.displayUtil.getContainer();
        // start scroll2
        // getBorderRoundRectangleSprite: function (wid, ht, col, rad, isBorder, borderWid, borderCol, borderAlp)
        this.scroll2 = this.displayUtil.getBorderRoundRectangleSprite(10, 50, '0XC4C4C4', 5, true, 1, '0XC4C4C4');
        this.wrapper2.addChild(this.scroll2);
        this.displayUtil.setObjectPosition(this.scroll2, 528, 20);
        this.wrapper2.addChild(this.emojiContainer);
        this.emojiContainer.alpha = 1;
        this.dragMove = false;
        this.wrapper2.visible = true;
        for (const i in buttons.smileyList) {
            this[buttons.smileyList[i]] = this.displayUtil.getButton(buttons.smileyList[i]);
            this.emojiContainer.addChild(this[buttons.smileyList[i]]);
            this.displayUtil.setObjectPosition(
                this[buttons.smileyList[i]],
                this.positionConfig.emojiPanelButtons[buttons.smileyList[i] + 'Pos'].x,
                this.positionConfig.emojiPanelButtons[buttons.smileyList[i] + 'Pos'].y,
            );
            this.displayUtil.addInteraction(this[buttons.smileyList[i]], this['selectEmoji' + 'BtnClick'].bind(this));
            this.displayUtil.setClickable(this[buttons.smileyList[i]], true);
            this[buttons.smileyList[i]].visible = true;
            // this[buttons.smileyList[i]].scale.set(0.5);
        }
        // start impetus2
        this.wrapper2Impetus = new Impetus({
            source: this.wrapper2,
            isPixiElement: true,
            bounce: false,
            boundY: [-250, 0],
            update: function (x1, y1) {
                this.emojiContainer.y = y1;
                if (y1 > -150) {
                    this.scroll2.y = y1 * -1 + 5;
                }
            }.bind(this),
        });
        // end impetus2
        // start mask2
        this.wrapperMask2 = this.displayUtil.getRectangleSprite(580, 200, '0X1c1c1c');
        this.wrapperMask2.name = 'mask';
        this.displayUtil.setObjectPosition(this.wrapperMask2, 0, 0);
        this.wrapper2.addChild(this.wrapperMask2);
        this.wrapper2.mask = this.wrapperMask2;
        // end mask2
        // create switcher view below for chat and emoji's
        //this.switcherBg = this.displayUtil.getRectangleSprite(580,70,"0X151515",true,1,"#000000");
        this.switcherBg = this.displayUtil.getSprite('chat_base');
        this.switcherBg.scale.x = 1.06;
        this.switcherBg.x = 70;
        this.switcherBg.y = 1130;
        this.selectEmojiContainer.addChild(this.switcherBg);
        for (const i in buttons.switcherList) {
            this[buttons.switcherList[i]] = this.displayUtil.getCustomButton(
                buttons.switcherList[i] + '_up',
                buttons.switcherList[i] + '_down',
                buttons.switcherList[i] + '_down',
            );
            this.switcherBg.addChild(this[buttons.switcherList[i]]);
            this.displayUtil.setObjectPosition(
                this[buttons.switcherList[i]],
                this.positionConfig.emojiPanelButtons[buttons.switcherList[i] + 'Pos'].x,
                this.positionConfig.emojiPanelButtons[buttons.switcherList[i] + 'Pos'].y,
            );
            this.displayUtil.addInteraction(this[buttons.switcherList[i]], this[buttons.switcherList[i] + 'BtnClick'].bind(this));
            this.displayUtil.setEnabled(this[buttons.switcherList[i]], true);
            this.displayUtil.setClickable(this[buttons.switcherList[i]], true);
            this[buttons.switcherList[i]].visible = true;
            //this[buttons.switcherList[i]].scale.set(0.5);
        }
    };
    hideEmojiContainer = function () {
        this.boosterEnabled = true;
    };
    showEmojiContainer = function () {
        this.boosterEnabled = false;
    };
    showemoji = function (currentGameId) {
        this.backBgTouchStarted = false;
        const cGameId = currentGameId; //this.viewModel.gameService.getCurrentGameId();
        if (cGameId !== undefined && cGameId !== null && cGameId !== 0 && this.viewModel.gameService.getSelfEmojiStatus() == true) {
            if (this.wrapper1.visible) {
                this.text.gotoAndStop(2);
            } else {
                this.smiley.gotoAndStop(2);
            }
            this.visible = true;
            this.blackBg.visible = true;
            this.selectEmojiContainer.visible = true;
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_EMOJI_TAB, { status: true });
            if (
                this.throwAnimationObj &&
                !(uiConfig['tableConfig' + this.viewModel.peerId] && uiConfig['tableConfig' + this.viewModel.peerId].playerOptionsVisible)
            ) {
                this.throwAnimationObj.visible = false;
            }
            if (this.boosterEnabled) {
                this.visible = false;
                this.blackBg.visible = false;
                this.selectEmojiContainer.visible = false;
            }
        }
    };
    selectEmojiBtnClick = function (obj) {
        this.blackBg.visible = false;
        const emojiId = obj.currentTarget._texture.textureCacheIds[0];
        const requestEmojiId = emojiId.split('_')[1];
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SELF_EMOJI_SELECTED, requestEmojiId);
        this.viewModel.showEmojiReq();
        this.selectEmojiContainer.visible = false;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_EMOJI_TAB, { status: false });
    };
    // move below code to cardsview , no use
    showEmojiAtPlayer = function (emojiId) {
        const obj: any = {};
        if (emojiId.split('_')[1] < 1000) {
            obj.posX = 365;
            obj.posY = 900;
            obj.scale = this.scaleFactor / 2;
            return obj;
        } else {
            obj.posX = 363;
            obj.posY = 1002;
            obj.scale = this.scaleFactor;
            return obj;
        }
    };
    // move below code to cardsview , no use
    showSelfEmojiAtCard = function (emojiId) {
        const obj: any = {};
        if (emojiId.split('_')[1] < 1000) {
            obj.posX = 460;
            obj.posY = 1095;
            obj.scale = 0.5;
            return obj;
        } else {
            obj.posX = 200;
            obj.posY = 1115;
            obj.scale = 0.5;
            return obj;
        }
    };
    // move below code to cardsview , no use
    broadcastEmoji = function () {
        //opponentemoji_1_up
        const playerSeat = this.viewModel.gameService.getBroadcastDetails().seatNo, // server position
            tempSeatPos = this.viewModel.gameService.getClientSideSeatNo(playerSeat), // client position
            responseId = this.viewModel.gameService.getBroadcastDetails().bEmojiId;
        let emojiId: any = 'emoji' + '_' + '1';
        if (serverIdConstants.SEAT_ALIGNMENT_RIGHT == this.viewModel.gameService.getSeatAlignment(tempSeatPos) && responseId < 1000) {
            emojiId = 'opponentemoji' + '_' + responseId;
        } else {
            emojiId = 'emoji' + '_' + responseId;
        }
        let posX,
            posY,
            scaleValue = 1;
        if (emojiId.split('_')[1] > 1000) {
            posX = this.playerAbsPos.x[tempSeatPos];
            posY = this.playerAbsPos.y[tempSeatPos];
        } else {
            if (tempSeatPos == 0) {
                posX = 316;
                posY = 1000;
            } else {
                posX = this.playerAbsPos.x[tempSeatPos] + 48;
                posY = this.playerAbsPos.y[tempSeatPos] - 55;
            }
        }
        scaleValue = this.scaleFactor;
        if (tempSeatPos == uiConfig.selfUserClientPosInd) {
            let positionObj;
            if (uiConfig['tableConfig' + this.viewModel.peerId] && uiConfig['tableConfig' + this.viewModel.peerId].playerOptionsVisible) {
                positionObj = this.showSelfEmojiAtCard(emojiId);
            } else {
                positionObj = this.showEmojiAtPlayer(emojiId);
            }
            posX = positionObj.posX;
            posY = positionObj.posY;
            scaleValue = positionObj.scale;
        }
        if (this.addEmoji) {
            this.addEmoji.destroy();
            this.removeChild(this.addEmoji);
            clearTimeout(this.addEmojiTimer);
        }
        if (emojiId.split('_')[1] > 1000) {
            const requiredEmoji = emojiId + 'Anim';
            this.animatedSelfEmoji = this.displayUtil.getSpriteDetails(requiredEmoji);
            this.animatedSelfEmoji =
                this.animatedSelfEmoji == undefined ? this.displayUtil.getSpriteDetails('emojilaughingAnim') : this.animatedSelfEmoji;
            this.addEmoji = this.displayUtil.getCustomAnimatedSprite(this.animatedSelfEmoji);
            this.addEmoji.scale.set(scaleValue);
            this.addEmoji.loop = false;
            this.addChildAt(this.addEmoji);
            this.addEmoji.x = posX;
            this.addEmoji.y = posY;
            this.addEmoji.anchor.set(0.5);
            this.addEmoji.play();
            this.addEmoji.onComplete = function () {
                if (this.addEmoji) {
                    this.addEmoji.destroy();
                    this.removeChild(this.addEmoji);
                    this.addEmoji = null;
                }
            }.bind(this);
        } else {
            this.addEmoji = this.displayUtil.getButton(emojiId);
            if (serverIdConstants.SEAT_ALIGNMENT_RIGHT == this.viewModel.gameService.getSeatAlignment(tempSeatPos)) {
                posX = 550;
                posX = posX - this.addEmoji.width;
            }
            this.addChildAt(this.addEmoji);
            this.displayUtil.setObjectPosition(this.addEmoji, posX, posY);
            this.addEmojiTimer = setTimeout(
                function () {
                    gsap.to(this.addEmoji, 0.5, {
                        alpha: 0,
                    });
                }.bind(this),
                4000,
            );
        }
    };
    textBtnClick = function () {
        this.emojiContainer.visible = false;
        this.chatContainer.visible = true;
        this.wrapper2.visible = false;
        this.wrapper1.visible = true;
        this.smiley.gotoAndStop(0);
        this.text.gotoAndStop(2);
    };
    smileyBtnClick = function () {
        this.chatContainer.visible = false;
        this.emojiContainer.visible = true;
        this.wrapper1.visible = false;
        this.wrapper2.visible = true;
        this.smiley.gotoAndStop(2);
        this.text.gotoAndStop(0);
    };
    closeEmojiPanel = function () {
        this.visible = false;
        if (this.throwAnimationObj) this.throwAnimationObj.visible = true;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_EMOJI_TAB, { status: false });
        this.smiley.gotoAndStop(0);
        this.text.gotoAndStop(0);
    };
    hideEmoji = function () {
        if (this.addEmoji) {
            this.addEmoji.visible = false;
        }
    };
    updateThrowAnim = function (throwAnimationObj) {
        this.throwAnimationObj = {};
        this.throwAnimationObj = throwAnimationObj;
    };
    hideSelectEmojiPopUp = function () {
        this.visible = false;
    };
}
