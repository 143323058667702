<div class="contentWrap dark">
    <div class="col">
        <div class="row social-feeds p-4">
            <div class="blog text-white col-md-5 mh-100">
                <h3>{{ blogContentContentTitle }}</h3>
                <pt-blog class="col-md-5 mh-100"></pt-blog>
            </div>
            <div class="twitter col-md-7 mh-100">
                <h3>{{ twitterContentContentTitle }}</h3>
                <pt-twitter class="col-md-7 mh-100"></pt-twitter>
            </div>
        </div>
    </div>
</div>
