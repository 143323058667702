import { Message } from '../../message';

export class TimeSyncRequest extends Message {
    private MESSAGE_NAME: string = 'TimeSyncRequest';
    private requestSentTickCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestSentTickCount: number = 0) {
        super(reqServerPeerId);
        this.requestSentTickCount = requestSentTickCount;
    }

    getRequestSentTickCount(): number {
        return this.requestSentTickCount;
    }

    setRequestSentTickCount(requestSentTickCount: number) {
        this.requestSentTickCount = requestSentTickCount;
    }
}
