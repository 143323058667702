import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalPopupService {
    modalPopup() {
        const dataModalNodes: any = document.querySelectorAll('[data-modal]');
        if (dataModalNodes && dataModalNodes.length > 0) {
            dataModalNodes.forEach((item: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                item.addEventListener('click', (event: any) => {
                    const getModalName = item.getAttribute('data-modal');
                    (document.getElementById(getModalName) as HTMLElement).classList.add('showModal');
                    document.getElementsByTagName('body')[0].classList.add('overflowHidden');
                });
            });
            document.getElementsByTagName('body')[0].classList.remove('overflowHidden');
        }
    }
}
