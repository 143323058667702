import { Message } from '../../message';

export class AllInPot extends Message {
    private MESSAGE_NAME: string = 'AllInPot';
    private potNumber: number = 0;
    private amount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, potNumber: number = 0, amount: number = 0) {
        super(reqServerPeerId);
        this.potNumber = potNumber;
        this.amount = amount;
    }

    getPotNumber(): number {
        return this.potNumber;
    }

    setPotNumber(potNumber: number) {
        this.potNumber = potNumber;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
}
