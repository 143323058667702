import { Message } from '../../message';

export class InstallLowLevelHook extends Message {
    private MESSAGE_NAME: string = 'InstallLowLevelHook';
    private sourceUrl: string | null = null;
    private md5sumOfLowLevelHook: string | null = null;
    private installationMode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sourceUrl: string | null = null,
        md5sumOfLowLevelHook: string | null = null,
        installationMode: number = 0,
    ) {
        super(reqServerPeerId);
        this.sourceUrl = sourceUrl;
        this.md5sumOfLowLevelHook = md5sumOfLowLevelHook;
        this.installationMode = installationMode;
    }

    getSourceUrl(): string | null {
        return this.sourceUrl;
    }

    setSourceUrl(sourceUrl: string | null) {
        this.sourceUrl = sourceUrl;
    }
    getMd5sumOfLowLevelHook(): string | null {
        return this.md5sumOfLowLevelHook;
    }

    setMd5sumOfLowLevelHook(md5sumOfLowLevelHook: string | null) {
        this.md5sumOfLowLevelHook = md5sumOfLowLevelHook;
    }
    getInstallationMode(): number {
        return this.installationMode;
    }

    setInstallationMode(installationMode: number) {
        this.installationMode = installationMode;
    }
}
