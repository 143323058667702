import { Message } from '../../message';

export class RequestLSLobbyDyna extends Message {
    private MESSAGE_NAME: string = 'RequestLSLobbyDyna';
    private templateId: number = 0;
    private tournyType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, templateId: number = 0, tournyType: number = 0) {
        super(reqServerPeerId);
        this.templateId = templateId;
        this.tournyType = tournyType;
    }

    getTemplateId(): number {
        return this.templateId;
    }

    setTemplateId(templateId: number) {
        this.templateId = templateId;
    }
    getTournyType(): number {
        return this.tournyType;
    }

    setTournyType(tournyType: number) {
        this.tournyType = tournyType;
    }
}
