import { Component, Input } from '@angular/core';

import { GameTable } from '../../models/game-table';
import { Player } from '../../models/player';

@Component({
    selector: 'pkr-player-pots',
    templateUrl: './player-pots.component.html',
})
export class PlayerPotsComponent {
    @Input() players: Player[];
    @Input() gameTable: GameTable;
}
