import { Message } from '../../message';
import { LSTourneyLobbyData } from './LSTourneyLobbyData';

export class ResponseTourneyLobbyDetails extends Message {
    private MESSAGE_NAME: string = 'ResponseTourneyLobbyDetails';
    private lsTourneyLobbyData: LSTourneyLobbyData | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lsTourneyLobbyData: LSTourneyLobbyData | null = null) {
        super(reqServerPeerId);
        this.lsTourneyLobbyData = lsTourneyLobbyData;
    }

    getLsTourneyLobbyData(): LSTourneyLobbyData | null {
        return this.lsTourneyLobbyData;
    }

    setLsTourneyLobbyData(lsTourneyLobbyData: LSTourneyLobbyData | null) {
        this.lsTourneyLobbyData = lsTourneyLobbyData;
    }
}
