import { Message } from '../../message';

export class LSMixMaxData extends Message {
    private MESSAGE_NAME: string = 'LSMixMaxData';
    private mixmaxType: number = 0;
    private mixmaxEntries: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mixmaxType: number = 0, mixmaxEntries: any[] | null = null) {
        super(reqServerPeerId);
        this.mixmaxType = mixmaxType;
        this.mixmaxEntries = mixmaxEntries;
    }

    getMixmaxType(): number {
        return this.mixmaxType;
    }

    setMixmaxType(mixmaxType: number) {
        this.mixmaxType = mixmaxType;
    }
    getMixmaxEntries(): any[] | null {
        return this.mixmaxEntries;
    }

    setMixmaxEntries(mixmaxEntries: any[] | null) {
        this.mixmaxEntries = mixmaxEntries;
    }
}
