import { ElementRef, Injectable, ViewChild } from '@angular/core';

import { CookieService, DeviceService } from '@frontend/vanilla/core';
import { Subscription, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LauncherGaurdService {
    @ViewChild('HTML') _elementHTML: ElementRef;
    @ViewChild('launchInstalledPokerClient') _launchInstalledPokerClientElementHTML!: ElementRef<HTMLInputElement>;
    @ViewChild('modal') _modal: ElementRef;
    clickedElement: Subscription = new Subscription();

    constructor(
        private cookieService: CookieService,
        private deviceService: DeviceService,
    ) {}

    getVerifiedLaunchAccess(): void {
        this.launchInstalledPokerClient();
    }

    getPPQuerySring(name: any) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return results[2].replace(/\+/g, ' ');
    }

    getQueryParams(url) {
        const paramArr = url.slice(url.indexOf('?') + 1).split('&');
        const params = {};
        paramArr.map((param) => {
            const [key, val] = param.split('=');
            params[key] = decodeURIComponent(val);
        });
        return params;
    }

    isMac() {
        if (this.cookieService.get('NativeApp') && this.cookieService.get('NativeApp') === 'pokermc') {
            return true;
        }
        return false;
    }

    launchInstalledPokerClient() {
        if (this.getPPQuerySring('launch') === null) {
            let product = this.getPPQuerySring('P'),
                action,
                server,
                port,
                catergory,
                ugUrl,
                suUrl,
                launchProtocol,
                launchURL,
                extendedAttr;

            // if (product === null) {
            //   return true;
            // }

            action = this.getPPQuerySring('A');
            server = this.getPPQuerySring('S');
            port = this.getPPQuerySring('PO');
            catergory = this.getPPQuerySring('C');
            ugUrl = this.getPPQuerySring('UG');
            suUrl = this.getPPQuerySring('SU');
            extendedAttr = this.getPPQuerySring('EX');
            launchProtocol = 'launcher-' + product?.toLowerCase();
            launchURL = launchProtocol + '://-P=' + product;

            if (server) {
                launchURL += ' -S=' + server;
            }

            if (port) {
                if (!navigator.appVersion.includes('Mac')) {
                    launchURL += '#' + port;
                }
                if (this.isMac()) {
                    launchURL += '#' + port;
                }
            }

            if (action) {
                launchURL += ' -action=' + action;
            }

            if (catergory) {
                launchURL += ' -category=' + catergory;
            }

            if (ugUrl) {
                launchURL += ' -UG=' + ugUrl;
            }

            if (extendedAttr) {
                // launchURL += ' -extendedAttr=' + extendedAttr;
                // if(navigator.appVersion.includes('Mac')){
                if (this.isMac()) {
                    //console.log('launcher mac - launchInstalledPokerClient');
                    extendedAttr = extendedAttr.replace('$', '#');
                    // tourneyId = extendedAttr.substring(extendedAttr.indexOf("tourneyId:"), extendedAttr.indexOf("#")).replace("tourneyId:", "");
                }
                launchURL += ' -extendedAttr=' + extendedAttr;
                //console.log(' launchURL: ', launchURL);
            }

            if (suUrl) {
                launchURL += ' -SU=' + suUrl;
            }

            // if (siteConfig.isMac && $.browser.chrome) {
            if (this.isMac() && this.deviceService.isChrome) {
                launchURL = launchURL.replace(/ /g, '%20');
            }
            // let _classListArry = this._elementHTML?.nativeElement.classList;
            const hasIOS = this._elementHTML?.nativeElement.classList.contains('ios');
            const hasAndroid = this._elementHTML?.nativeElement.classList.contains('android');

            // if (!$('html').hasClass('ios') && !$('html').hasClass('android')) {
            if (!hasIOS && !hasAndroid) {
                if (~location.pathname.indexOf('launch')) {
                    setTimeout(function () {
                        location.href = launchURL;
                    }, 5000);
                } else {
                    this.clickedElement = fromEvent(this._launchInstalledPokerClientElementHTML.nativeElement, 'click').subscribe(() => {
                        if (this._launchInstalledPokerClientElementHTML.nativeElement.closest('#modal')?.getAttribute('id') !== 'undefined') {
                            this._modal.nativeElement.click();
                            location.href = launchURL;
                        } else {
                            location.href = launchURL;
                        }
                    });
                }
            }

            //console.log("launchURL final", launchURL);
        }
    }
}
