import { Message } from '../pgConnectors/server/common/message';
import { BaseConnector } from './baseConnector';
import { IPGConnector } from './interfaces/IpgConnector';

export class BaseGameTableConnector extends BaseConnector {
    private connect: IPGConnector;

    constructor(connector: IPGConnector) {
        super(connector);
        this.connect = connector;
    }
    addPeer(peerId: number, domainId: number) {
        this.connect.addPeer(peerId, domainId);
    }

    removePeer(peerId: number, domainId: number) {
        this.connect.removePeer(peerId, domainId);
    }

    sendMessageOnPeerId(msg: Message, peerId: number): void {
        this.connect.sendMessageOnPeerId(msg, peerId);
    }

    getTimeForPeer(peerId: number) {
        return this.connect.getTimeForPeer(peerId);
    }

    createSocialConnection(): void {
        this.connect.createSocialConnection();
    }

    closeSocialConnection(): void {
        this.connect.closeSocialConnection();
    }
}
