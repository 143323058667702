import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class DisconnectionView {
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor() {}
    createView = function (eventDispatcher) {
        this.EventDispatcher = eventDispatcher;
        this.blackBg = this.displayUtil.getRectangleSprite(window.innerWidth / this.scaleX, window.innerHeight / this.scaleY, '#000000');
        this.displayUtil.setObjectPosition(this.blackBg, -this.x / this.scaleX, -this.y / this.scaleY);
        this.blackBg.interactive = true;
        this.blackBg.alpha = 0.4;
        this.addChild(this.blackBg);
        this.disConContainer = this.displayUtil.getContainer();
        this.addChild(this.disConContainer);
        this.createConnectingAni();
        ResponsiveContainer.call(this.disConContainer, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        this.disConContainer.setSize(720, 1280);
        //this.onResize();
        this.visible = true;
    };
    createConnectingAni = function () {
        this.disconnectionAniObj = this.displayUtil.getSpriteDetails('disconnectionAni');
        this.disconnectionAni = this.displayUtil.getCustomAnimatedSprite(this.disconnectionAniObj);
        this.displayUtil.setObjectPosition(this.disconnectionAni, 302, 582);
        this.disconnectionAni.loop = true;
        this.disConContainer.addChild(this.disconnectionAni);
    };
    onResize = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        this.blackBg.scale.set(scaleXValue, scaleYValue);
    };
    handleDisconnectionState = function (flag) {
        if (flag) this.showTableLoader();
        else this.hideTableLoader();
    };
    showTableLoader = function () {
        this.visible = true;
        this.disconnectionAni.gotoAndPlay(0);
    };
    hideTableLoader = function () {
        this.visible = false;
    };
}
