import { Message } from '../../message';

export class GameTypeInfo extends Message {
    private MESSAGE_NAME: string = 'GameTypeInfo';
    private limitType: number = 0;
    private gameType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, limitType: number = 0, gameType: number = 0) {
        super(reqServerPeerId);
        this.limitType = limitType;
        this.gameType = gameType;
    }

    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
}
