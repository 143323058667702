import { Component, EventEmitter, Output } from '@angular/core';

import { MessageQueueService } from '@frontend/vanilla/core';

import { HandHistoryInputModel } from '../../hand-history.models';
import { HandHistoryService } from '../../hand-history.service';

@Component({
    selector: 'pk-hand-filters',
    templateUrl: 'hand-filters.component.html',
})
export class HandFiltersComponent {
    initData: any;
    potrangeCG: number = 0;
    potrangeValueCG: number = 0;
    PotRangeText: string;
    finalMatchChoiceCG: number;
    GameTypeCG: any;
    GameTypeTextCG: string;
    LimitTypeCG: any;
    LimitTypeTextCG: string;
    StakeTypeCG: any;
    StakeTypeTextCG: string;
    SearchDateCG: string;
    SearchDateTextCG: string;
    FinalHandCG: any;
    FinalHandTextCG: string;
    FinalHandText: string;
    finalHandMatchText: string;
    finalHandMatch: any;
    FinalSelectedTextCG: string;
    PocketCardOneCG: string;
    PCardOneCG: boolean = false;
    PocketCardOneTextCG: string;
    PocketCardTwoCG: string;
    PCardTwoCG: boolean = false;
    PocketCardTwoTextCG: string;
    PocketCardSuitedCG: boolean = false;
    ActionText: string;
    PocketHandText: string;
    SuitedOnlyText: string;
    SuitedText: string;
    SearchBtnText: string;
    ResetFilters: string;
    toggleFinalMatchCG: boolean = true;
    SortAscendingCG: boolean = false;
    pocketSuitCG: boolean = false;
    isSearchedForSingleHandId: boolean = false;

    TournamentTypeTR: any;
    TournamentTypeTextTR: string;
    BuyInTypeTR: any;
    BuyInTypeTextTR: string;
    GameTypeTR: any;
    GameTypeTextTR: string;
    SearchDateTR: string;
    SearchDateTextTR: string;
    FinalHandTR: any;
    FinalSelectedTextTR: string;
    FinalHandTextTR: string;
    PocketCardOneTR: string;
    PCardOneTR: boolean = false;
    PocketCardOneTextTR: string;
    PocketCardTwoTR: string;
    PCardTwoTR: boolean = false;
    PocketCardTwoTextTR: string;
    PocketCardSuitedTR: boolean = false;
    finalMatchChoiceTR: number;
    toggleFinalMatchTR: boolean = true;
    SortAscendingTR: boolean = false;
    pocketSuitTR: boolean = false;

    filterPopupSwitch: boolean = false;
    limitTypeValues: any = {};
    roundId: string;
    loadingMask: boolean = false;

    @Output() searchByFilterId = new EventEmitter<string>();
    @Output() updateDate = new EventEmitter<boolean>();
    @Output() updateFltrBtn = new EventEmitter<string>();
    @Output() filterHangup = new EventEmitter<string>();

    constructor(
        public serve: HandHistoryService,
        private messageQueue: MessageQueueService,
    ) {
        this.initContent();
        this.initCashGames();
        this.initTournaments();
        this.finalHandMatch = this.serve.content.form.finalhandmatch.values;
    }

    initContent() {
        const messages = this.serve.content.messages;
        this.LimitTypeTextCG = messages.AllLimits;
        this.ActionText = messages.AllActions;
        this.FinalHandText = messages.FinalHand;
        this.finalHandMatchText = messages.ShowResultsBy;
        this.PocketHandText = messages.PocketHand;
        this.PotRangeText = messages.PotSize;
        this.SuitedOnlyText = messages.SuitedOnly;
        this.SuitedText = messages.Suited;
        this.SearchBtnText = messages.Search;
        this.ResetFilters = messages.ResetFilters;
    }

    onGameChange(value: number, gameType: string) {
        if (gameType == 'cashgames') {
            this.FinalHandCG = 0;
            this.FinalHandTextCG = this.serve.content.form.finalhand.values[0].text;
            this.finalMatchChoiceCG = 0;
            this.toggleFinalMatchCG = true;
            this.FinalSelectedTextCG = '';
            value = this.getGameTypeValue(value);
            this.serve.getCashGamesActions(value).subscribe((data: any) => this.getCGActions(data.gameActionData));
            this.serve.getCashGamesLimits(value).subscribe((data: any) => {
                this.getCGLimits(data.gameLimitData);
                this.LimitTypeTextCG = this.serve.content.messages.AllLimits;
            });
        } else {
            this.FinalHandTR = 0;
            this.FinalHandTextTR = this.serve.content.form.finalhand.values[0].text;
            this.finalMatchChoiceTR = 0;
            this.toggleFinalMatchTR = true;
            this.FinalSelectedTextTR = '';
            this.serve.getTournamentActions('TexasHoldem').subscribe((data: any) => this.getTRActions(data.gameActionData));
        }
    }

    initCashGames() {
        const formContent = this.serve.content.form,
            pocketSuited: any = document.getElementById('pocketSuitedCG');
        this.GameTypeCG = 1;
        this.GameTypeTextCG = formContent.gametypes.values[1].text;
        this.StakeTypeCG = 0;
        this.StakeTypeTextCG = formContent.staketypes.values[0].text;
        this.SearchDateCG = formContent.searchdates.values[3].value;
        this.updateDate.next(formContent.searchdates.values[3].value);
        this.SearchDateTextCG = formContent.searchdates.values[3].text;
        this.FinalHandCG = 0;
        this.FinalHandTextCG = formContent.finalhand.values[0].text;
        this.finalMatchChoiceCG = 0;
        this.toggleFinalMatchCG = true;
        this.FinalSelectedTextCG = '';
        this.PocketCardOneCG = formContent.pockethand.values[0].value;
        this.PocketCardTwoCG = formContent.pockethand.values[0].value;
        this.serve.PocketCardOneCG = formContent.pockethand.values[0].value;
        this.serve.PocketCardTwoCG = formContent.pockethand.values[0].value;
        this.PCardOneCG = false;
        this.PCardTwoCG = false;
        this.PocketCardOneTextCG = '+';
        this.PocketCardTwoTextCG = '+';
        this.potrangeCG = 0;
        this.potrangeValueCG = 0;
        this.serve.allCgActions = [];
        this.LimitTypeCG = 0;
        this.serve.singleHand = false;
        this.roundId = '';
        this.onGameChange(1, 'cashgames');
        this.setLimitTypes();
        if (pocketSuited) {
            pocketSuited.checked = false;
        }
    }

    initTournaments() {
        const formContent = this.serve.content.form,
            pocketSuited: any = document.getElementById('pocketSuitedTR');
        this.TournamentTypeTR = 0;
        this.TournamentTypeTextTR = formContent.tournamenttypes.values[0].text;
        this.BuyInTypeTR = 0;
        this.BuyInTypeTextTR = formContent.buyintypes.values[0].text;
        this.GameTypeTR = 1;
        this.GameTypeTextTR = formContent.tournamentgametypes.values[1].text;
        this.SearchDateTR = formContent.searchdates.values[3].value;
        this.updateDate.next(formContent.searchdates.values[3].value);
        this.SearchDateTextTR = formContent.searchdates.values[3].text;
        this.PocketCardOneTR = formContent.pockethand.values[0].value;
        this.PocketCardTwoTR = formContent.pockethand.values[0].value;
        this.serve.PocketCardOneTR = formContent.pockethand.values[0].value;
        this.serve.PocketCardTwoTR = formContent.pockethand.values[0].value;
        this.PCardOneTR = false;
        this.PCardTwoTR = false;
        this.PocketCardOneTextTR = '+';
        this.PocketCardTwoTextTR = '+';
        this.FinalHandTR = 0;
        this.toggleFinalMatchTR = true;
        this.FinalSelectedTextTR = '';
        this.FinalHandTextTR = formContent.finalhand.values[0].text;
        this.finalMatchChoiceTR = 0;
        this.serve.allTrActions = [];
        this.serve.singleHand = false;
        this.roundId = '';
        this.onGameChange(1, 'tournaments');
        if (pocketSuited) {
            pocketSuited.checked = false;
        }
    }

    setLimitTypes() {
        const limitTypes = this.serve.content.form.limittypes.values;
        for (let i = 0; i < limitTypes.length; i++) {
            this.limitTypeValues[limitTypes[i].value] = i;
        }
    }

    showDropDown(event: any) {
        const toggleClass = event.currentTarget.parentElement.className,
            bodyElement = document.getElementsByTagName('body')[0];
        if (toggleClass.indexOf('show-dropdown') > -1) {
            event.currentTarget.parentElement.classList.remove('show-dropdown');
            this.filterPopupSwitch = false;
        } else {
            if (document.querySelectorAll('.hh-filters-menu li.show-dropdown').length > 0) {
                (document.querySelector('.hh-filters-menu li.show-dropdown') as HTMLElement).classList.remove('show-dropdown');
                this.filterPopupSwitch = false;
            }
            event.currentTarget.parentElement.className = ' show-dropdown';
            if (bodyElement.className.indexOf('filter-noscroll') <= -1) {
                bodyElement.className += ' filter-noscroll';
            }
            this.filterPopupSwitch = true;
        }
        if (window.innerWidth >= 768) {
            this.fitPopup(event.currentTarget.parentElement);
        }
    }

    applyFilter() {
        const windowWidth = window.innerWidth;
        this.roundId = '';
        if (this.serve.singleHand) {
            this.serve.singleHand = false;
        }
        if (windowWidth >= 768) {
            this.applyFilters(true, false, 'load');
        }
    }

    getGameTypeValue(gameTypeCG: any) {
        let gameTypeValue: number = gameTypeCG;
        gameTypeCG = parseInt(gameTypeCG);
        if (gameTypeCG > 2) {
            gameTypeValue = this.GameTypeCG - 1;
        } else if (gameTypeCG === 2) {
            gameTypeValue = 7;
        }
        return gameTypeValue;
    }

    applyFilters(scrollTop: boolean, pagination: boolean, loadType: string) {
        let hhGames = this.serve.hhGames,
            GameTypeCG: number;
        let handHistoryInputModel;
        if (loadType === 'autoload') {
            if (this.serve.handHistory.gameRounds.length < 500) {
                return;
            }
        }
        this.serve.nodataCG = false;
        this.serve.nodataTR = false;
        this.serve.singleHandStatus = false;
        if (hhGames === 'cashgames') {
            handHistoryInputModel = new HandHistoryInputModel();
            handHistoryInputModel.RoundNumber = this.roundId;
            GameTypeCG = this.getGameTypeValue(this.GameTypeCG);
            handHistoryInputModel.GameType = GameTypeCG;
            handHistoryInputModel.GameCodeType = 1;
            handHistoryInputModel.PocketCardOne = this.PocketCardOneCG;
            handHistoryInputModel.PocketCardTwo = this.PocketCardTwoCG;
            handHistoryInputModel.FinalHand = parseInt(this.FinalHandCG);
            handHistoryInputModel.FinalHandMatch = this.finalMatchChoiceCG;
            handHistoryInputModel.MinPotSize = this.potrangeCG * 100;
            handHistoryInputModel.LimitType = parseInt(this.LimitTypeCG);
            handHistoryInputModel.StakeType = parseInt(this.StakeTypeCG);
            handHistoryInputModel.SearchDate = this.SearchDateCG;
            handHistoryInputModel.SortAscending = this.SortAscendingCG;
            let allCgActions = this.serve.allCgActions,
                pocketSuitedCG: any = document.getElementById('pocketSuitedCG'),
                i = 0,
                action;
            if (pocketSuitedCG) {
                handHistoryInputModel.PocketCardSuited = pocketSuitedCG.checked;
            } else {
                handHistoryInputModel.PocketCardSuited = false;
            }
            for (i = 0; i < allCgActions.length; i++) {
                action = allCgActions[i].value;
                handHistoryInputModel[action] = allCgActions[i].status;
            }
            if (pagination && loadType != 'autoload') {
                this.serve.pageCG++;
                handHistoryInputModel.Page = this.serve.pageCG;
            } else if (loadType === 'autoload' && this.serve.pageCG > 0) {
                this.serve.pageCG = 0;
            } else {
                this.serve.pageCG = 0;
            }
        } else {
            handHistoryInputModel = new HandHistoryInputModel();
            handHistoryInputModel.PocketCardOne = this.PocketCardOneTR;
            handHistoryInputModel.PocketCardTwo = this.PocketCardTwoTR;
            handHistoryInputModel.TournamentFinalHand = parseInt(this.FinalHandTR);
            handHistoryInputModel.TournamentFinalHandMatch = this.finalMatchChoiceTR;
            handHistoryInputModel.TournamentType = parseInt(this.TournamentTypeTR);
            handHistoryInputModel.TournamentGameType = parseInt(this.GameTypeTR);
            handHistoryInputModel.BuyInType = parseInt(this.BuyInTypeTR);
            handHistoryInputModel.TournamentSearchDate = this.SearchDateTR;
            handHistoryInputModel.SortAscending = this.SortAscendingTR;
            handHistoryInputModel.SortBy = 0;
            handHistoryInputModel.GameCodeType = 2;
            let allTrActions = this.serve.allTrActions,
                pocketSuitedTR: any = document.getElementById('pocketSuitedTR'),
                i = 0,
                action;
            if (pocketSuitedTR) {
                handHistoryInputModel.PocketCardSuited = pocketSuitedTR.checked;
            } else {
                handHistoryInputModel.PocketCardSuited = false;
            }
            for (i = 0; i < allTrActions.length; i++) {
                action = allTrActions[i].value;
                handHistoryInputModel[action] = allTrActions[i].status;
            }
            if (pagination && loadType != 'autoload') {
                this.serve.pageTR++;
                handHistoryInputModel.Page = this.serve.pageTR;
            } else if (loadType === 'autoload' && this.serve.pageTR > 0) {
                this.serve.pageTR = 0;
            } else {
                this.serve.pageTR = 0;
            }
        }
        this.loadingMask = true;
        this.serve.getHandHistory(handHistoryInputModel).subscribe(
            (data) => {
                this.messageQueue.clear();
                data.gameRoundMetaData = data.gameRoundMetaDetails.gameRoundMetaData;
                this.serve.totalCount = data.gameRoundMetaData.totalCount;
                this.serve.lazyLoad = false;
                if (pagination) {
                    this.serve.handHistory.gameRounds = this.serve.handHistory.gameRounds.concat(data.gameRoundMetaData.gameRounds);
                    this.serve.handHistory.totalCount = data.gameRoundMetaData.totalCount;
                } else {
                    this.serve.handHistory = data.gameRoundMetaData;
                }

                if (this.serve.hhGames === 'cashgames') {
                    this.serve.cashGames = this.serve.handHistory;
                    this.serve.nodataCG = false;
                    if (data.gameRoundMetaData.gameRounds.length < 100) {
                        this.serve.nodataCG = true;
                    }
                } else {
                    this.serve.tournaments = this.serve.handHistory;
                    this.serve.nodataTR = false;
                    if (data.gameRoundMetaData.totalCount < 100) {
                        this.serve.nodataTR = true;
                    }
                }

                this.serve.totalCount = this.serve.getTodayHands(this.serve.handHistory);
                if (scrollTop) {
                    window.scroll(0, 0);
                }
                if (this.serve.lazyloading) {
                    setTimeout(() => {
                        this.serve.lazyloading = false;
                    }, 500);
                }
                this.serve.groupRounds(this.serve.handHistory, this.serve.hhGames);
                this.loadingMask = false;
                if (window.innerWidth < 1366) {
                    this.filterHangup.next('');
                }
            },
            (error) => {
                this.messageQueue.clear();
                if (error.errorCode === '403') {
                    this.messageQueue.add(error.vnMessages[0].html);
                } else if (!error.hasOwnProperty('errorCode')) {
                    this.messageQueue.add(error.message);
                } else {
                    this.messageQueue.add(error.data.common.messages[0]);
                }
                this.loadingMask = false;
            },
        );
    }

    selectOption(event: any): boolean | void {
        let value,
            content,
            ids = event.currentTarget.getAttribute('id'),
            pocketSuitedCG: any = <HTMLInputElement>document.getElementById('pocketSuitedCG'),
            pocketSuitedTR: any = <HTMLInputElement>document.getElementById('pocketSuitedTR');

        //value = event.target.attributes[0].value;
        value = event.target.attributes['value'].value;
        content = event.target.textContent;
        if (event.target.tagName != 'li' && event.target.tagName != 'LI') {
            return false;
        }
        switch (ids) {
            case 'GameTypeCG':
                this.serve.allCgActions = [];
                this.onGameChange(value, 'cashgames');
                this.GameTypeCG = value;
                this.serve.gameTypeCG = value;
                this.LimitTypeCG = 0;
                this.GameTypeTextCG = content;
                if (this.GameTypeCG == 2) {
                    this.StakeTypeTextCG = this.serve.content.form.antetypes.values[0].text;
                } else {
                    this.StakeTypeTextCG = this.serve.content.form.staketypes.values[0].text;
                }
                break;
            case 'LimitTypeCG':
                this.LimitTypeCG = value;
                this.LimitTypeTextCG = content;
                break;
            case 'StakeTypeCG':
                this.StakeTypeCG = value;
                this.StakeTypeTextCG = content;
                break;
            case 'SearchDateCG':
                this.SearchDateCG = value;
                this.SearchDateTextCG = content;
                this.updateDate.next(value);
                break;
            case 'FinalHandCG':
                this.FinalHandCG = value;
                this.FinalHandTextCG = content;
                if (value === '0') {
                    this.FinalSelectedTextCG = '';
                    this.toggleFinalMatchCG = true;
                } else {
                    this.FinalSelectedTextCG = this.serve.content.form.finalhandmatch.values[0].text;
                    this.toggleFinalMatchCG = false;
                }
                this.finalMatchChoiceCG = 0;
                (document.querySelector('#FinalHandCG li.active') as HTMLElement).classList.remove('active');
                event.target.className += ' active';
                break;
            case 'PocketCardOneCG':
                this.PocketCardOneCG = value;
                this.serve.PocketCardOneCG = value;
                this.PCardOneCG = true;
                (document.querySelector('#PocketCardOneCG li.active') as HTMLElement).classList.remove('active');
                event.target.className += ' active';
                this.PocketCardOneTextCG = event.target.getAttribute('value-card');
                if (this.PocketCardTwoCG === this.PocketCardOneCG && value != 'XX') {
                    this.pocketSuitCG = true;
                    if (pocketSuitedCG.checked == true) {
                        pocketSuitedCG.checked = false;
                    }
                } else if (pocketSuitedCG.getAttribute('disabled')) {
                    this.pocketSuitCG = false;
                }
                break;
            case 'PocketCardTwoCG':
                this.PocketCardTwoCG = value;
                this.serve.PocketCardTwoCG = value;
                this.PCardTwoCG = true;
                (document.querySelector('#PocketCardTwoCG li.active') as HTMLElement).classList.remove('active');
                event.target.className += ' active';
                this.PocketCardTwoTextCG = event.target.getAttribute('value-card');
                if (this.PocketCardTwoCG == this.PocketCardOneCG && value != 'XX') {
                    this.pocketSuitCG = true;
                    if (pocketSuitedCG.checked == true) {
                        pocketSuitedCG.checked = false;
                    }
                } else if (pocketSuitedCG.getAttribute('disabled')) {
                    this.pocketSuitCG = false;
                }
                break;
            case 'TournamentTypeTR':
                this.TournamentTypeTR = value;
                this.TournamentTypeTextTR = content;
                break;
            case 'GameTypeTR':
                this.serve.allTrActions = [];
                this.onGameChange(value, 'tournaments');
                this.GameTypeTR = value;
                this.serve.gameTypeTR = value;
                this.GameTypeTextTR = content;
                break;
            case 'BuyInTypeTR':
                this.BuyInTypeTR = value;
                this.BuyInTypeTextTR = content;
                break;
            case 'SearchDateTR':
                this.SearchDateTR = value;
                this.SearchDateTextTR = content;
                this.updateDate.next(value);
                break;
            case 'FinalHandTR':
                this.FinalHandTR = value;
                this.FinalHandTextTR = content;
                if (value === '0') {
                    this.FinalSelectedTextTR = '';
                    this.toggleFinalMatchTR = true;
                } else {
                    this.FinalSelectedTextTR = this.serve.content.form.finalhandmatch.values[0].text;
                    this.toggleFinalMatchTR = false;
                }
                this.finalMatchChoiceTR = 0;
                (document.querySelector('#FinalHandTR li.active') as HTMLElement).classList.remove('active');
                event.target.className += ' active';
                break;
            case 'PocketCardOneTR':
                this.PocketCardOneTR = value;
                this.serve.PocketCardOneTR = value;
                this.PCardOneTR = true;
                (document.querySelector('#PocketCardOneTR li.active') as HTMLElement).classList.remove('active');
                event.target.className += ' active';
                this.PocketCardOneTextTR = event.target.getAttribute('value-card');
                if (this.PocketCardTwoTR === this.PocketCardOneTR && value != 'XX') {
                    this.pocketSuitTR = true;
                    if (pocketSuitedTR.checked == true) {
                        pocketSuitedTR.checked = false;
                    }
                } else if (pocketSuitedTR.getAttribute('disabled')) {
                    this.pocketSuitTR = false;
                }
                break;
            case 'PocketCardTwoTR':
                this.PocketCardTwoTR = value;
                this.serve.PocketCardTwoTR = value;
                this.PCardTwoTR = true;
                (document.querySelector('#PocketCardTwoTR li.active') as HTMLElement).classList.remove('active');
                event.target.className += ' active';
                this.PocketCardTwoTextTR = event.target.getAttribute('value-card');
                if (this.PocketCardTwoTR === this.PocketCardOneTR && value != 'XX') {
                    this.pocketSuitTR = true;
                    if (pocketSuitedTR.checked == true) {
                        pocketSuitedTR.checked = false;
                    }
                } else if (pocketSuitedTR.getAttribute('disabled')) {
                    this.pocketSuitTR = false;
                }
                break;
        }
        this.applyFilter();
        this.closePopups();
    }

    getCGActions(data: any) {
        const allCgActions: any = [],
            cgActions = this.serve.content.form.cashgamesactions.values;
        for (const key in data) {
            for (let i = 0; i < cgActions.length; i++) {
                if (cgActions[i].value == data[key]) {
                    const entry = {
                        value: data[key],
                        text: cgActions[i].text,
                        status: false,
                    };
                    allCgActions.push(entry);
                    break;
                }
            }
        }
        this.serve.allCgActions = allCgActions;
    }

    resetActions(filterType: any) {
        let act: any;
        const actions: any = document.querySelectorAll('li[filter-type="' + filterType + '"] input[checked="checked"]');
        for (let i = 0; i < actions.length; i++) {
            actions[i].checked = false;
        }
        if (this.serve.hhGames === 'cashgames') {
            act = this.serve.allCgActions;
        } else {
            act = this.serve.allTrActions;
        }
        for (let i = 0; i < act.length; i++) {
            if (act[i].status == true) {
                act[i].status = false;
            }
        }
        this.applyFilter();
    }

    getCGLimits(data: any) {
        const allCgLimits: any = [],
            cgLimits = this.serve.content.form.limittypes.values;
        for (const key in data) {
            for (let i = 0; i < cgLimits.length; i++) {
                if (cgLimits[i].value == data[key]) {
                    const entry = {
                        value: data[key],
                        text: cgLimits[i].text,
                    };
                    allCgLimits.push(entry);
                    break;
                }
            }
        }
        this.serve.allCgLimits = allCgLimits;
    }

    getTRActions(data: any) {
        const allTrActions: any = [],
            trActions = this.serve.content.form.tournamentactions.values;
        for (const key in data) {
            for (let i = 0; i < trActions.length; i++) {
                if (trActions[i].value == data[key]) {
                    const entry = {
                        value: data[key],
                        text: trActions[i].text,
                        status: false,
                    };
                    allTrActions.push(entry);
                    break;
                }
            }
        }
        this.serve.allTrActions = allTrActions;
    }

    toggleCheckbox(event: any) {
        if (event.currentTarget.className.indexOf('active') <= -1) {
            event.currentTarget.className += ' active';
        } else {
            event.currentTarget.classList.remove('active');
        }
    }

    toggleActionsCheckbox(i: number, hhType: string) {
        let actions = this.serve.allTrActions;
        if (hhType === 'cashgames') {
            actions = this.serve.allCgActions;
        }
        if (actions[i].status == false) {
            actions[i].status = true;
        } else {
            actions[i].status = false;
        }
        if (hhType === 'cashgames') {
            this.serve.allCgActions = actions;
        } else {
            this.serve.allTrActions = actions;
        }
        this.applyFilter();
        this.closePopups();
    }

    toggleCardSuit(type: string) {
        if (type == 'cashgames') {
            if (this.PocketCardSuitedCG == false) {
                this.PocketCardSuitedCG = true;
            } else {
                this.PocketCardSuitedCG = false;
            }
        } else {
            if (this.PocketCardSuitedTR == false) {
                this.PocketCardSuitedTR = true;
            } else {
                this.PocketCardSuitedTR = false;
            }
        }
    }

    toggleHandHistoryType(hhType: string, event: any) {
        if (event.currentTarget.className.indexOf('active') <= -1 && event.currentTarget.className.indexOf('disable') <= -1) {
            if (hhType == 'cashgames') {
                (document.getElementById('tournaments') as HTMLElement).classList.remove('active');
                event.currentTarget.className += ' active';
                this.serve.hhGames = 'cashgames';
                this.serve.handHistory = this.serve.cashGames;
            } else {
                (document.getElementById('cashgames') as HTMLElement).classList.remove('active');
                event.currentTarget.className += ' active';
                this.serve.hhGames = 'tournaments';
                this.serve.handHistory = this.serve.tournaments;
            }
            if (window.innerWidth >= 768) {
                this.applyFilters(true, false, 'load');
            }
        }
        if (this.serve.handHistory) {
            this.serve.totalCount = this.serve.getTodayHands(this.serve.handHistory);
        }
    }

    resetPocketSuited(hhType: string, handSetPos: string) {
        let suitedElement: any;
        if (hhType === 'cashgames') {
            if (this.PocketCardSuitedCG) {
                const pocketSuitedCG: any = <HTMLInputElement>document.getElementById('pocketSuitedCG');
                pocketSuitedCG.checked = false;
                this.PocketCardSuitedCG = false;
            }
            suitedElement = document.getElementById('pocketSuitedCG');
            if (handSetPos === 'one') {
                this.PCardOneCG = false;
            } else if (handSetPos === 'two') {
                this.PCardTwoCG = false;
            }
        } else {
            if (this.PocketCardSuitedTR) {
                const pocketSuitedTR: any = <HTMLInputElement>document.getElementById('pocketSuitedTR');
                pocketSuitedTR.checked = false;
                this.PocketCardSuitedTR = false;
            }
            suitedElement = document.getElementById('pocketSuitedTR');
            if (handSetPos === 'one') {
                this.PCardOneTR = false;
            } else if (handSetPos === 'two') {
                this.PCardTwoTR = false;
            }
        }
        suitedElement.checked = false;
    }

    resetFilters() {
        const hhType = this.serve.hhGames;
        this.messageQueue.clear();
        if (hhType == 'cashgames') {
            this.initCashGames();
        } else {
            this.initTournaments();
        }
        this.resetPocketSuited(hhType, '');
        this.applyFilter();
    }

    closePopup(filterType: string) {
        const element = document.querySelector('li[filter-type=' + filterType + ']') as HTMLElement;
        if (element.className.indexOf('show-dropdown') >= 0) {
            element.classList.remove('show-dropdown');
            document.getElementsByTagName('body')[0].classList.remove('filter-noscroll');
            this.filterPopupSwitch = false;
        }
    }

    showHandsFilter(pocketHandType: any, handtype: string) {
        const element = document.querySelector('li[filter-type=' + handtype + ']') as HTMLElement,
            bodyElement = document.getElementsByTagName('body')[0];
        if (element.className.indexOf('show-dropdown') <= -1) {
            if (handtype == 'pocketHandCG' || handtype == 'pocketHandTR') {
                (document.querySelector('li[filter-type=' + handtype + '] .filters-popup-block') as HTMLElement).setAttribute(
                    'pocket-card',
                    pocketHandType,
                );
            }
            element.className += ' show-dropdown';
            if (bodyElement.className.indexOf('filter-noscroll') <= -1) {
                bodyElement.className += ' filter-noscroll';
            }
            this.filterPopupSwitch = true;
        }
        if (window.innerWidth >= 768) {
            this.fitPopup(element);
        }
    }

    closePopups() {
        const filterType = (document.querySelector('li.show-dropdown') as HTMLElement).getAttribute('filter-type') ?? '';
        this.closePopup(filterType);
    }

    closeFilters() {
        const handHistory: any = document.querySelector('.hands-history');
        if (handHistory.className.indexOf('hide-hands') > -1) {
            handHistory.classList.remove('hide-hands');
            this.updateFltrBtn.next(this.serve.content.messages.Filters);
        } else {
            this.updateFltrBtn.next(this.serve.content.messages.ApplyFilters);
        }
    }

    fitPopup(element: any) {
        let offsetTop = 0,
            elem = element,
            popup: any = document.querySelector('.show-dropdown .filters-popup-block'),
            windowHeight = window.innerHeight,
            diff,
            popupHeight = popup.offsetHeight,
            offsetTop1;
        if (elem.offsetParent) {
            do {
                offsetTop += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        offsetTop = offsetTop >= 0 ? offsetTop : 0;
        offsetTop1 = offsetTop + element.offsetHeight - window.pageYOffset;
        diff = windowHeight - offsetTop1;
        if (diff < popupHeight) {
            let headerHeight: number = 0;
            if (document.querySelector('header')) {
                headerHeight = (document.querySelector('header') as HTMLElement).offsetHeight;
            }
            window.scroll(0, offsetTop - headerHeight);
        }
    }

    togglePocketSuit() {
        let hhType = this.serve.hhGames,
            pocketSuited: any;
        if (hhType === 'cashgames') {
            pocketSuited = <HTMLInputElement>document.getElementById('pocketSuitedCG');
            this.PocketCardSuitedCG = pocketSuited.checked;
        } else {
            pocketSuited = <HTMLInputElement>document.getElementById('pocketSuitedTR');
            this.PocketCardSuitedTR = pocketSuited.checked;
        }
        this.applyFilter();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    focusOnRoundId(event: any) {
        const hideFilterButton: any = document.querySelectorAll('.apply-filters'),
            windowWidth: number = document.documentElement.clientWidth;
        if (windowWidth < 1366) {
            for (let i = 0; i < hideFilterButton.length; i++) {
                hideFilterButton[i].className += ' hidebtn';
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    blurOnRoundId(event: any) {
        const hideFilterButton: any = document.querySelectorAll('.apply-filters'),
            windowWidth: number = document.documentElement.clientWidth;
        if (windowWidth < 1366) {
            for (let i = 0; i < hideFilterButton.length; i++) {
                if (hideFilterButton[i].className.indexOf('hidebtn') > -1) {
                    hideFilterButton[i].classList.remove('hidebtn');
                } else {
                    const hideFilterButtons: any = document.querySelectorAll('.apply-filters.hidebtn');
                    if (hideFilterButtons) {
                        hideFilterButtons.classList.remove('hidebtn');
                    }
                }
            }
        }
    }

    // searchWithId() {
    // this.isSearchedForSingleHandId = true;
    // let windowWidth = document.documentElement.clientWidth;
    // if (windowWidth < 768 && this.roundId.length >= 11) {
    // 	let filterButton: any = document.getElementsByClassName('apply-filters')[1];
    // 	filterButton.click();
    // 	return;
    // }
    // else {
    // 	if (this.roundId.length >= 11) {
    // 		this.serve.singleHand = true;
    // 		this.searchByFilterId.next(this.roundId);
    // 	}
    // 	else {
    // 		if (this.roundId.length <= 0) {
    // 			this.serve.singleHand = false;
    // 		}
    // 	}
    // }
    // }

    searchWithId() {
        this.isSearchedForSingleHandId = true;
        const screenSize = document.documentElement.clientWidth;
        if (screenSize < 768 && this.roundId.length === 11) {
            const filterButton: any = document.getElementsByClassName('apply-filters')[1];
            filterButton.click();
        } else {
            if (this.roundId.length >= 11) {
                this.serve.singleHand = true;
                this.searchByFilterId.next(this.roundId);
            } else {
                this.serve.singleHand = false;
            }
        }
    }

    checkForNumbers(e: any): boolean | void {
        const keyCode = e.which ? e.which : e.keyCode;
        if (e.keyCode === 13 && this.roundId.length >= 11) {
            this.searchWithId();
            return;
        } else if ((e.key >= 'a' && e.key <= 'z') || (e.key >= 'A' && e.key <= 'Z') || (keyCode > 31 && (keyCode < 48 || keyCode > 57))) {
            e.preventDefault();
        } else {
            return true;
        }
    }

    clearRoundId() {
        this.serve.singleHandStatus = false;
        this.roundId = '';
        this.messageQueue.clear();
        if (this.isSearchedForSingleHandId) {
            this.applyFilter();
        }
        this.isSearchedForSingleHandId = false;
    }

    getSuited(hhType: string) {
        let pocketSuited: any;
        if (hhType === 'cashgames') {
            pocketSuited = document.getElementById('pocketSuitedCG');
        } else {
            pocketSuited = document.getElementById('pocketSuitedTR');
        }
        if (pocketSuited) {
            return pocketSuited.checked;
        } else {
            return false;
        }
    }

    setPotRange() {
        let potrangeValue: number = this.potrangeValueCG,
            potrange;
        switch (potrangeValue) {
            case 0:
                potrange = 0;
                break;
            case 1:
                potrange = 0.25;
                break;
            case 2:
                potrange = 0.5;
                break;
            case 3:
                potrange = 1;
                break;
            case 4:
                potrange = 2;
                break;
            case 5:
                potrange = 3;
                break;
            case 6:
                potrange = 4;
                break;
            case 7:
                potrange = 5;
                break;
            case 8:
                potrange = 10;
                break;
            case 9:
                potrange = 15;
                break;
            case 10:
                potrange = 20;
                break;
            case 11:
                potrange = 25;
                break;
            case 12:
                potrange = 50;
                break;
            case 13:
                potrange = 75;
                break;
            case 14:
                potrange = 100;
                break;
            case 15:
                potrange = 250;
                break;
            case 16:
                potrange = 500;
                break;
        }
        this.potrangeCG = potrange;
        this.applyFilter();
    }

    calcViewPort() {
        let header: any = document.querySelector('header'),
            subNav: any = document.querySelector('.navbar.sub-nav-wrapper'),
            mainbody: any = document.querySelector('.hh-mainbody'),
            heading: any = document.querySelector('.heading-strip'),
            groupHeading: any = document.querySelector('.grouped-hands.active .sub-heading-block'),
            headerHeight = header ? header.clientHeight : 0,
            subNavHeight = subNav ? subNav.clientHeight : 0,
            mainbodyOffset = mainbody ? mainbody.offsetTop : 0,
            headingHeight = heading ? heading.clientHeight : 0,
            groupHeadingHeight = groupHeading ? groupHeading.clientHeight : 0,
            upperHeight = headerHeight + subNavHeight + mainbodyOffset + headingHeight + groupHeadingHeight,
            windowHeight = window.innerHeight,
            handElements = document.querySelectorAll('.hands-details'),
            handsTotalHeight;
        if (!this.serve.noHands) {
            handsTotalHeight = handElements.length * handElements[0].clientHeight;
            if (upperHeight + handsTotalHeight < windowHeight) {
                return true;
            }
        }
        return false;
    }

    applySuited(hhType: string) {
        let suitedElement: any;
        if (hhType === 'cashgames') {
            suitedElement = document.querySelector('#pocketSuitedCG');
        } else {
            suitedElement = document.querySelector('#pocketSuitedTR');
        }
        this.serve.groupRounds(this.serve.handHistory, hhType);
        if ((suitedElement.checked && this.serve.noHands) || (suitedElement.checked && !this.serve.noHands && this.calcViewPort())) {
            this.applyFilters(true, true, 'load');
        } else {
            window.scroll(0, 0);
        }
    }

    resetAllFilters(filterType: string) {
        switch (filterType) {
            case 'finalHandCG':
                this.FinalHandCG = 0;
                this.FinalHandTextCG = this.serve.content.form.finalhand.values[0].text;
                this.FinalSelectedTextCG = '';
                this.finalMatchChoiceCG = 0;
                this.toggleFinalMatchCG = true;
                break;
            case 'finalHandTR':
                this.FinalHandTR = 0;
                this.FinalHandTextTR = this.serve.content.form.finalhand.values[0].text;
                this.FinalSelectedTextTR = '';
                this.finalMatchChoiceTR = 0;
                this.toggleFinalMatchTR = true;
                break;
        }
        this.applyFilter();
    }

    resetFilter(filtertype: string, pocketCard: string = '') {
        switch (filtertype) {
            case 'GameTypeCG':
                this.GameTypeCG = 1;
                this.serve.gameTypeCG = 1;
                this.GameTypeTextCG = this.serve.content.form.gametypes.values[1].text;
                this.StakeTypeTextCG = this.serve.content.form.staketypes.values[0].text;
                this.onGameChange(1, 'cashgames');
                break;
            case 'LimitTypeCG':
                this.LimitTypeCG = 0;
                this.LimitTypeTextCG = this.serve.content.messages.AllLimits;
                break;
            case 'SearchDateCG':
                this.SearchDateCG = this.serve.content.form.searchdates.values[4].value;
                this.SearchDateTextCG = this.serve.content.form.searchdates.values[4].text;
                break;
            case 'StakeTypeCG':
                this.StakeTypeCG = 0;
                this.StakeTypeTextCG = (document.querySelector('#StakeTypeCG li') as HTMLElement).textContent ?? '';
                break;
            case 'pocketHandCG':
                if (pocketCard === 'card-one') {
                    this.PocketCardOneCG = this.serve.content.form.pockethand.values[0].value;
                    this.serve.PocketCardOneCG = this.PocketCardOneCG;
                    this.PocketCardOneTextCG = '+';
                    this.pocketSuitCG = false;
                    this.resetPocketSuited('cashgames', 'one');
                } else if (pocketCard === 'card-two') {
                    this.PocketCardTwoCG = this.serve.content.form.pockethand.values[0].value;
                    this.serve.PocketCardTwoCG = this.PocketCardTwoCG;
                    this.PocketCardTwoTextCG = '+';
                    this.pocketSuitCG = false;
                    this.resetPocketSuited('cashgames', 'two');
                }
                break;
            case 'TournamentTypeTR':
                this.TournamentTypeTR = 0;
                this.TournamentTypeTextTR = this.serve.content.form.tournamenttypes.values[0].text;
                break;
            case 'GameTypeTR':
                this.GameTypeTR = 1;
                this.serve.gameTypeTR = 1;
                this.GameTypeTextTR = this.serve.content.form.tournamentgametypes.values[1].text;
                this.onGameChange(1, 'tournaments');
                break;
            case 'BuyInTypeTR':
                this.BuyInTypeTR = 0;
                this.BuyInTypeTextTR = this.serve.content.form.buyintypes.values[0].text;
                break;
            case 'SearchDateTR':
                this.SearchDateTR = this.serve.content.form.searchdates.values[3].value;
                this.SearchDateTextTR = this.serve.content.form.searchdates.values[3].text;
                break;
            case 'pocketHandTR':
                if (pocketCard === 'card-one') {
                    this.PocketCardOneTR = this.serve.content.form.pockethand.values[0].value;
                    this.serve.PocketCardOneTR = this.PocketCardOneTR;
                    this.PocketCardOneTextTR = '+';
                    this.pocketSuitTR = false;
                    this.resetPocketSuited('tournaments', 'one');
                } else if (pocketCard === 'card-two') {
                    this.PocketCardTwoTR = this.serve.content.form.pockethand.values[0].value;
                    this.serve.PocketCardTwoTR = this.PocketCardTwoTR;
                    this.PocketCardTwoTextTR = '+';
                    this.resetPocketSuited('tournaments', 'two');
                    this.pocketSuitTR = false;
                }
                break;
        }
        this.applyFilter();
    }
}
