import { Message } from '../../message';

export class MTCTParticipantNames extends Message {
    private MESSAGE_NAME: string = 'MTCTParticipantNames';
    private mtctId: number = 0;
    private rangeStart: number = 0;
    private participants: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, rangeStart: number = 0, participants: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.rangeStart = rangeStart;
        this.participants = participants;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getRangeStart(): number {
        return this.rangeStart;
    }

    setRangeStart(rangeStart: number) {
        this.rangeStart = rangeStart;
    }
    getParticipants(): Map<any, any> | null {
        return this.participants;
    }

    setParticipants(participants: Map<any, any> | null) {
        this.participants = participants;
    }
}
