import { Message } from '../../message';

export class MTCTDynaLiteralUpdate extends Message {
    private MESSAGE_NAME: string = 'MTCTDynaLiteralUpdate';
    private mtctDynaUpdateId: number = 0;
    private dynaUpdates: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctDynaUpdateId: number = 0, dynaUpdates: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctDynaUpdateId = mtctDynaUpdateId;
        this.dynaUpdates = dynaUpdates;
    }

    getMtctDynaUpdateId(): number {
        return this.mtctDynaUpdateId;
    }

    setMtctDynaUpdateId(mtctDynaUpdateId: number) {
        this.mtctDynaUpdateId = mtctDynaUpdateId;
    }
    getDynaUpdates(): any[] | null {
        return this.dynaUpdates;
    }

    setDynaUpdates(dynaUpdates: any[] | null) {
        this.dynaUpdates = dynaUpdates;
    }
}
