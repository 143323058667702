import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { PokerApiService } from '@pokercore/module/core';

import { WeeklyPointsDto } from '../models/loyalty-cashback-weeklyPoints';
import { updateIsCashBackEnabledStatus, updateLoyaltyWeeklyPoints, updateOptInStatus } from '../store/loyalty-cashback.store';
import { EventDispatcherGlobal } from './event-despacher-global';

@Injectable({
    providedIn: 'root',
})
export class PWCLoyaltyCashBackService {
    dynaconFlag: object;

    eventDispatcherGlobal = new EventDispatcherGlobal();

    constructor(
        private store: Store,
        private api: PokerApiService,
    ) {}

    optIn() {
        this.api.get('Loyalty/OptinPlayer').subscribe({
            next: () => {
                this.store.dispatch(updateOptInStatus({ optIn: { status: true } }));
                this.weeklyPoints();
            },
            error: () => {
                this.store.dispatch(updateOptInStatus({ optIn: { status: false } }));
            },
        });
    }
    weeklyPoints() {
        this.api.get('Loyalty/LoyaltyOverview').subscribe({
            next: (res: any) => {
                // let res = {
                //     startDate: 1716155999000,
                //     endDate: 1716155999000,
                //     playerName: 'pp_stg84',
                //     currency: '$',
                //     currencyCode: 'USD',
                //     category: 'P1',
                //     currentTarget: '5',
                //     errorCode: '0',
                //     gamePointEnabled: true,
                //     todayPoints: '0',
                //     quaterlyPoints: '1006',
                //     totalProductPoints: 0.0,
                //     currDate: 'May 17',
                //     currMonth: 'May',
                //     currQuarter: 'Apr-Jun',
                //     points1: 0.0,
                //     tablemap: {},
                //     tablebeanlist: [],
                //     totalPoints: 0.0,
                //     displayTotalPoints: '0.000',
                //     lengthOfMap: 0,
                //     monthPointsDate: 1714536000000,
                //     quaterDateFirst: 1711966711142,
                //     quaterDateLast: 1717237111142,
                //     isUkPlayer: false,
                //     currentDate: 1715941111141,
                //     opt: 'Y',
                //     errorMessage: 'SUCCESS',
                //     weeklyPoints: '0',
                //     currentSlabPoints: '0',
                //     nextSlabPoints: '20',
                //     currentCashback: '0',
                //     targetCashback: '0.5',
                //     isOptin: 'Y',
                //     nextTargetCashback: '1',
                //     awardType: 'CASH',
                //     todaysPointsList: [],
                //     points: '1',
                //     optIn: 1,
                //     segmentMultiplier: '1.7',
                //     monthlyPoints: '306',
                //     segmentId: '12',
                // };
                if (!Array.isArray(res)) {
                    const responseData = {
                        weeklyPoints: Number(res.weeklyPoints.replace(/\,/g, '')),
                        currentTarget: Number(res.currentTarget.replace(/\,/g, '')),
                        currentCashback: Number(res.currentCashback.replace(/\,/g, '')),
                        targetCashback: Number(res.targetCashback.replace(/\,/g, '')),
                        isOptin: res.opt,
                        currency: res.currency,
                        currentSlabPoints: Number(res.currentSlabPoints.replace(/\,/g, '')),
                        nextTargetCashback: Number(res.nextTargetCashback.replace(/\,/g, '')),
                        nextSlabPoints: Number(res.nextSlabPoints.replace(/\,/g, '')),
                        segmentId: res.segmentId,
                        segmentMultiplier: Number(res.segmentMultiplier),
                        startDate: JSON.stringify(res.startDate),
                        endDate: JSON.stringify(res.endDate),
                        additionalParams: [],
                    } as WeeklyPointsDto;

                    this.store.dispatch(updateIsCashBackEnabledStatus({ isCashBackEnabled: true }));
                    this.store.dispatch(updateLoyaltyWeeklyPoints({ weeklyPoints: responseData }));

                    if (res.opt === 'Y') {
                        this.store.dispatch(updateOptInStatus({ optIn: { status: true } }));
                        const message = {};
                        message['className'] = 'onLoyaltyCashBackPopUpClose';
                        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
                    } else {
                        this.store.dispatch(updateOptInStatus({ optIn: { status: false } }));
                    }
                } else {
                    this.store.dispatch(updateOptInStatus({ optIn: { status: false } }));
                    this.store.dispatch(updateLoyaltyWeeklyPoints({ weeklyPoints: {} }));
                    this.store.dispatch(updateIsCashBackEnabledStatus({ isCashBackEnabled: false }));
                }
            },
            error: () => {
                this.store.dispatch(updateOptInStatus({ optIn: { status: false } }));
                this.store.dispatch(updateLoyaltyWeeklyPoints({ weeklyPoints: {} }));
                this.store.dispatch(updateIsCashBackEnabledStatus({ isCashBackEnabled: false }));
            },
        });
    }
}
