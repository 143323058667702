import { Message } from '../../message';

export class ArjelBlindsRoundPlayerData extends Message {
    private MESSAGE_NAME: string = 'ArjelBlindsRoundPlayerData';
    private playerId: string | null = null;
    private seatNo: number = 0;
    private gameStartAmt: number = 0;
    private blindTypeIfPosted: number = 0;
    private blindAmtIfPosted: number = 0;
    private anteAmtIfPosted: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playerId: string | null = null,
        seatNo: number = 0,
        gameStartAmt: number = 0,
        blindTypeIfPosted: number = 0,
        blindAmtIfPosted: number = 0,
        anteAmtIfPosted: number = 0,
    ) {
        super(reqServerPeerId);
        this.playerId = playerId;
        this.seatNo = seatNo;
        this.gameStartAmt = gameStartAmt;
        this.blindTypeIfPosted = blindTypeIfPosted;
        this.blindAmtIfPosted = blindAmtIfPosted;
        this.anteAmtIfPosted = anteAmtIfPosted;
    }

    getPlayerId(): string | null {
        return this.playerId;
    }

    setPlayerId(playerId: string | null) {
        this.playerId = playerId;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getGameStartAmt(): number {
        return this.gameStartAmt;
    }

    setGameStartAmt(gameStartAmt: number) {
        this.gameStartAmt = gameStartAmt;
    }
    getBlindTypeIfPosted(): number {
        return this.blindTypeIfPosted;
    }

    setBlindTypeIfPosted(blindTypeIfPosted: number) {
        this.blindTypeIfPosted = blindTypeIfPosted;
    }
    getBlindAmtIfPosted(): number {
        return this.blindAmtIfPosted;
    }

    setBlindAmtIfPosted(blindAmtIfPosted: number) {
        this.blindAmtIfPosted = blindAmtIfPosted;
    }
    getAnteAmtIfPosted(): number {
        return this.anteAmtIfPosted;
    }

    setAnteAmtIfPosted(anteAmtIfPosted: number) {
        this.anteAmtIfPosted = anteAmtIfPosted;
    }
}
