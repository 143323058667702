import { Message } from '../../message';

export class GetPAMArticleContentFiles extends Message {
    private MESSAGE_NAME: string = 'GetPAMArticleContentFiles';
    private articleLanguageIds: Map<any, any> | null = null;
    private isPersonal: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, articleLanguageIds: Map<any, any> | null = null, isPersonal: boolean | null = false) {
        super(reqServerPeerId);
        this.articleLanguageIds = articleLanguageIds;
        this.isPersonal = isPersonal;
    }

    getArticleLanguageIds(): Map<any, any> | null {
        return this.articleLanguageIds;
    }

    setArticleLanguageIds(articleLanguageIds: Map<any, any> | null) {
        this.articleLanguageIds = articleLanguageIds;
    }
    getIsPersonal(): boolean | null {
        return this.isPersonal;
    }

    setIsPersonal(isPersonal: boolean | null) {
        this.isPersonal = isPersonal;
    }
}
