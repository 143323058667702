export class GameInfoModel {
    bigBlind: any;
    bountyFee: any;
    gameCurrency: any;
    maxBuyInAmount: any;
    minBuyInAmount: any;
    playerCount: any;
    smallBlind: any;
    tableColorCode: any;
    tableId: any;
    tableTemplateId: any;
    tourneyBuyIn: any;
    tourneyEntryFee: any;
    tourneyGameStatus: any;
    tableStatus: any;
    mtctId: any;
    removedPlayersData: any;
    constructor() {
        // this.bigBlind = obj.tableStatus.bigBlind;
        // this.bountyFee = obj.bountyFee;
        // this.gameCurrency = obj.gameCurrency;
        // this.maxBuyInAmount = obj.maxBuyInAmount;
        // this.minBuyInAmount = obj.minBuyInAmount;
        // this.playerCount = obj.tableStatus.playerCount;
        // this.smallBlind = obj.smallBlind;
        // this.tableColorCode = obj.tableColorCode;
        // this.tableId = obj.tableId;
        // this.tableTemplateId = obj.tableName ? obj.tableName.templateId : 0;
        // this.tourneyBuyIn = obj.tourneyBuyIn;
        // this.tourneyEntryFee = obj.tourneyEntryFee;
        // this.tourneyGameStatus = obj.tourneyGameStatus;
        // this.tableStatus = obj.tableStatus;
        // this.mtctId = obj.tableStatus.mtctId;
        // this.removedPlayersData = [];
    }
    setJoinTableResponse = function (obj) {
        this.bigBlind = obj.tableStatus.bigBlind;
        this.bountyFee = obj.bountyFee;
        this.gameCurrency = obj.gameCurrency;
        this.maxBuyInAmount = obj.maxBuyInAmount;
        this.minBuyInAmount = obj.minBuyInAmount;
        this.playerCount = obj.tableStatus.playerCount;
        this.smallBlind = obj.smallBlind;
        this.tableColorCode = obj.tableColorCode;
        this.tableId = obj.tableId;
        this.tableTemplateId = obj.tableName ? obj.tableName.templateId : 0;
        this.tourneyBuyIn = obj.tourneyBuyIn;
        this.tourneyEntryFee = obj.tourneyEntryFee;
        this.tourneyGameStatus = obj.tourneyGameStatus;
        this.tableStatus = obj.tableStatus;
        this.mtctId = obj.tableStatus.mtctId;
        this.removedPlayersData = [];
    };
    getPlayerCount = function () {
        return this.playerCount;
    };
    getMtctId = function () {
        return this.mtctId;
    };
    setCardStyle = function (obj) {
        this.cardStyle = obj.index;
    };
    getCardStyle = function () {
        return this.cardStyle;
    };
    HandleAutoOptionSet = function (obj) {
        this.autoOptionSetData = obj.autoOptionSet;
        // this.autoOptionSetId = this.autoOptionSetData.autoOptionId;
        this.selectedAutoOptionId = obj.selectedAutoOptionId;
    };
    HandleShowCardsOptions = function (obj) {
        this.showCardsOptionsData = obj;
        this.selectedShowCardsOptionId = obj.showCardsOptionId;
    };
    HandleMuckCards = function (obj) {
        this.muckCardsData = obj;
        this.selectedMuckCardsId = obj.muckCardsId;
    };
    /*HandleOptionSet = function(obj){
          this.betTimeOutPeriod = obj.betTimeOutPeriod;
          this.generatedAt = obj.generatedAt;
          this.limitGameOptionSet = obj.limitGameOptionSet;
          this.optionSet = obj.optionSet;
          this.optionSetId = obj.optionSetId;
          this.timeBankAutoEnable = obj.timeBankAutoEnable;
          this.timeBankAvailableTime = obj.timeBankAvailableTime;
      }*/
    HandleOption = function (obj) {
        this.optionId = obj.optionId;
    };
    HandleTourneyLevelInfo = function (obj) {
        this.nextLevelData = obj;
        this.highstake = obj.highstake;
        this.lowstake = obj.lowstake;
    };
    HandleOptionSet = function (obj) {
        this.optionSetData = obj;
        this.betTimeOutPeriod = obj.betTimeOutPeriod;
        this.generatedAt = obj.generatedAt;
        this.limitGameOptionSet = obj.limitGameOptionSet;
        this.setGameLimitOptionSetData(this.limitGameOptionSet);
        this.optionSetId = obj.optionSetId;
        this.timeBankAutoEnable = obj.timeBankAutoEnable;
        this.timeBankAvailableTime = obj.timeBankAvailableTime;
    };
    getGeneratedAt = function () {
        return this.generatedAt;
    };
    getTimeoutPeriod = function () {
        return this.betTimeOutPeriod;
    };
    setGameLimitOptionSetData = function (limitGameOptionSet) {
        if (limitGameOptionSet.length > 0) {
            this.isMaxRaiseAllIn = limitGameOptionSet[0].isMaxRaiseAllIn;
            this.maxRaiseLimit = limitGameOptionSet[0].maxRaiseLimit;
            this.betRaiseAmountVal = limitGameOptionSet[0].option.amount;
        }
    };
    getNextleavelData = function () {
        return this.nextLevelData;
    };
    getHighStake = function () {
        return this.highstake;
    };
    getLowStake = function () {
        return this.lowstake;
    };
    getMaxRaiseAllInStatus = function () {
        return this.isMaxRaiseAllIn;
    };
    getMaxRaiseLimit = function () {
        return this.maxRaiseLimit;
    };
    getBetRaiseAmount = function () {
        return this.betRaiseAmountVal;
    };
    getOptionSetData = function () {
        return this.optionSetData;
    };
    setOptionSetData = function (obj) {
        this.optionSetData = obj;
    };
    setMuckCardsData = function (obj) {
        this.muckCardsData = obj;
    };
    getShowCardsOptionsData = function () {
        return this.showCardsOptionsData;
    };
    setShowCardsOptionsData = function (obj) {
        this.showCardsOptionsData = obj;
    };
    setShowRabbitDetails = function (obj) {
        this.showRabbitData = obj;
    };
    getShowRabbitDetails = function () {
        return this.showRabbitData;
    };
    setBroadcastRabbitDetails = function (obj) {
        this.broadCastRabbitData = obj;
    };
    getBroadcastRabbitDetails = function () {
        return this.broadCastRabbitData;
    };
    getOptionSetId = function () {
        return this.optionSetId;
    };
    setAutoSelectedBetOption = function (data) {
        this.selectedAutoOptionData = data;
    };
    getAutoSelectedBetOption = function () {
        return this.selectedAutoOptionData;
    };
    getAutoOptionSetData = function () {
        return this.autoOptionSetData;
    };
    getShowCardsOptionsId = function () {
        return this.showCardsOptionId;
    };
    getMuckCardsData = function () {
        return this.muckCardsData;
    };
    // getselectedAutoOptionId = function() {
    //     return this.selectedAutoOptionId;
    // }
    resetDataModel = function () {
        this.removedPlayersData = [];
        this.bigBlind = null;
        this.bountyFee = null;
        this.gameCurrency = null;
        //this.maxBuyInAmount = null;
        //this.maxNoOfSeats = null;
        //this.minBuyInAmount = null;
        this.playerCount = null;
        this.smallBlind = null;
        //this.tableColorCode = null;
        //this.tableId = null;
        //this.tableTemplateId = null;
        //this.tourneyBuyIn = null;
        //this.tourneyEntryFee = null;
        //this.tourneyGameStatus = null;
        this.optionSetData = null;
        this.optionId = null;
        this.displayedOptionsForSecs = null;
        this.mouseClickSimulationType = null;
        this.option = null;
        this.optionSelectedAt = null;
        this.optionSetId = null;
        this.betTimeOutPeriod = null;
        this.generatedAt = null;
        this.limitGameOptionSet = null;
        this.optionSet = null;
        this.timeBankAutoEnable = null;
        this.timeBankAvailableTime = null;
        this.autoOptionSet = null;
        this.autoOptionId = null;
        this.autoOptionSetData = null;
        this.selectedAutoOptionId = null;
        this.selectedAutoOptionData = null;
        this.selectedHuPairTableStatus = null;
        this.showCardsOptions = null;
        this.showCardsOptionId = null;
        this.showCardsOptionsData = null;
        this.selectedShowCardsOptionId = null;
        this.muckCards = null;
        this.muckCardsId = null;
        this.muckCardsData = null;
        this.selectedMuckCardsId = null;
        //this.SNGJPGameAboutToStartData = null;
        //this.SNGJPPayoutAniData = null;
        //this.sngJackpotID = null;
        //this.name = null;
        //this.buyIn = null;
        //this.tourneyFee = null;
        //this.gameCurrency = null;
        //this.sngJPType = null;
        //this.gameType = null;
        //this.limitType = null;
        //this.maxSeats = null;
        //this.maxRegistrations = null;
        //this.gamePlayType = null;
        //this.minMultiplier = null;
        //this.maxMultiplier = null;
        //this.serverPeerId = null;
        //this.sngJPPayoutInfo = null;
        //this.selectedMultiplier = null;
        //this.minMultiplierNew = null;
        //this.maxMultiplierNew = null;
        //this.tableBackgroundImage = null;
    };
    setAddPopUpData = function (obj) {
        this.addPopUpData = obj;
    };
    getAddPopUpData = function () {
        return this.addPopUpData;
    };
    setHuPairTableStatusVal = function (boolVal) {
        this.selectedHuPairTableStatus = boolVal;
    };
    getHuPairTableStatusVal = function () {
        return this.selectedHuPairTableStatus;
    };
    setHUEvenGame = function (boolVal) {
        this.checkHUEvenGame = boolVal;
    };
    getHUEvenGame = function () {
        return this.checkHUEvenGame;
    };
    setTableBoosterTriggered = function (obj) {
        this.boosterTrigData = obj;
    };
    getTableBoosterTriggered = function () {
        return this.boosterTrigData;
    };
    setBroadcastTableBoosterTrigger = function (obj) {
        this.broadcastBoosterTrigData = obj;
    };
    getBroadcastTableBoosterTrigger = function () {
        return this.broadcastBoosterTrigData;
    };
    setTableBoosterWinners = function (obj) {
        this.boosterWinnderData = obj;
    };
    getTableBoosterWinners = function () {
        return this.boosterWinnderData;
    };
    setPlayersCashoutEquityDetails = function (obj) {
        this.PlayersCashoutEquityDetails = obj;
    };
    setRemovedPlayersData = function (obj) {
        this.removedPlayersData.push(obj);
    };
    getRemovedPlayersData = function () {
        return this.removedPlayersData;
    };
    setplayerStates = function (obj) {
        this.playerStates = obj;
    };
    getplayerStates = function () {
        return this.playerStates;
    };
    getPlayersCashoutEquityDetails = function () {
        return this.PlayersCashoutEquityDetails;
    };
}
