import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as sh from './simulated-hands.component';

@Injectable({ providedIn: 'root' })
export class SimulatedHandsBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('simulatedhands', sh.SimulatedHandsComponent);
        });
    }
}
