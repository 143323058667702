import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class CommunityCardsView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor() {
        super();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }
    createView = function (EventDispatcher, vm, maxNoOfSeats) {
        this.name = 'CommunityCardsView';
        this.viewModel = vm;
        //this.cCindex = 0;
        this.EventDispatcher = EventDispatcher;
        if (this.viewModel.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            this.bgLogo = this.displayUtil.getSprite('spins_logo');
        } else if (this.viewModel.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            this.bgLogo = this.displayUtil.getSprite('FF_logo');
        } else {
            this.bgLogo = this.displayUtil.getSprite('partypoker_logo');
        }
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        this.addChild(this.bgLogo);
        this.bgLogo.alpha = 1;
        this.displayUtil.setObjectPosition(this.bgLogo, 0, 470 - uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y);
        this.communityCardsContainer = this.displayUtil.getContainer();
        this.addChild(this.communityCardsContainer);
        //this.displayUtil.setObjectPosition(this.communityCardsContainer, 52, 0);
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'].communityCardConfig;
        this.cardStyle = 0;
        this.backCardStyle = 1;
        this.communityCardsArr = [];
        this.setSize(720, 1280);
        this.isCashoutBrandLabelEnabled = false;
    };
    updateExpoSpinLogo = function () {
        if (
            (this.viewModel.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS &&
                this.viewModel.gameService.SpinInfoModel.spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_NORMAL) ||
            this.viewModel.gameService.SpinInfoModel.spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_ULTRA
        ) {
            this.displayUtil.setTexture(this.bgLogo, 'expo_spin_logo');
        }
    };
    setCardStyle = function (cardStyleInd) {
        this.cardStyle = cardStyleInd;
    };
    handleWinnerCards = function (obj) {
        if (obj.winningCards && obj.winningCards.length > 0) {
            // for (card of obj.winningCards) {
            this.highlightCard(obj.winningCards);
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.Disable_All_In_Glow);
            // }
        }
    };
    setAlphaForAllCards = function (alpha) {
        for (const communityCard of this.communityCardsArr) {
            communityCard.presentCard.alpha = alpha;
        }
    };
    highlightCard = function (winnerCards) {
        for (const communityCard of this.communityCardsArr) {
            if (winnerCards.includes(communityCard.cardValue)) {
                communityCard.presentCard.alpha = 1;
            } else {
                communityCard.presentCard.tint = '0X7d7c7e';
                // this.commCardMask = this.displayUtil.getRectangleSprite(communityCard.presentCard.width, communityCard.presentCard.height, "0X000000");
                // this.commCardMask.name = "mask";
                // this.commCardMask.pivot.set(this.commCardMask.width / 2, this.commCardMask.height / 2);
                // this.commCardMask.alpha = 0.5;
                // this.displayUtil.setObjectPosition(this.commCardMask, 0, 0);
                // communityCard.presentCard.addChild(this.commCardMask);
            }
        }
        // if (communityCard.cardValue == cardValue) {
        //   communityCard.presentCard.alpha = 1;
        // }
    };
    handleAddCommunityCards = function (obj, cards) {
        if (this.isCashoutBrandLabelEnabled == true) {
            this.isCashoutBrandLabelEnabled = false;
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_CASHOUT_DISABLED_TEXT);
        }
        if (obj == null || obj == undefined) return;
        // Second condition is for showing community cards when rabbit feature is used
        if (
            obj.cardType == 0 ||
            (obj.cardType == 1 && this.viewModel.gameService.getShowRabbitDetails().gameId == this.viewModel.gameService.getCurrentGameId())
        ) {
            this.cCindex = obj.cardNo;
            // if (this.cCindex >= 5) {
            //   this.cCindex = 0;
            // }
            // code for temporary rabbit response time

            // if(obj.cardType == 1){
            //   this.removeChild(this.responsetimeText);
            //   window.rabbitResponseTime = new Date().getMilliseconds();
            //   let timeDiff = window.rabbitResponseTime - window.rabbitRequestTime;
            //   let temptext =  {
            //     "fontFamily": "Roboto",
            //     "fontSize": 24,
            //     "fontWeight": "bold",
            //     "fill": "0xffffff"
            //   };
            //   this.responsetimeText = this.displayUtil.getTextField(temptext);
            //   this.addChild(this.responsetimeText);
            //   this.displayUtil.setObjectPosition(this.responsetimeText, 660, 660);
            //   this.responsetimeText.text = timeDiff;
            //   setTimeout(function() {
            //       gsap.to(this.responsetimeText, 1, { alpha: 0 });
            //     }.bind(this),
            //     1000
            //   );
            // }
            if (obj.cardType == 1) {
                this.isRabbitFeatureTriggered = true;
            } else {
                this.isRabbitFeatureTriggered = false;
            }
            // if (obj == undefined){
            //   let obj = { "cardNo": 0, "cardType": 0, "cards": ["9h","5c","3s"], "msgNumber": 0, "peerId": 5127921, "className": "AddCommunityCards" };
            // }
            // if (this.cCindex > 2) {
            //   let obj = { "cardNo": 0, "cardType": 0, "cards": ["9h"], "msgNumber": 0, "peerId": 5127921, "className": "AddCommunityCards" };
            // }
            if (!this.communityCardsContainer) {
                this.communityCardsContainer = this.displayUtil.getContainer();
                this.addChild(this.communityCardsContainer);
            }
            if (obj.cards.length > 2) {
                //this.cCindex = 0;
                this.callThreeCardAni(obj, this.isRabbitFeatureTriggered);
            } else if (obj.cards.length == 2 && obj.cardType == 1) {
                this.callThreeCardAni(obj, this.isRabbitFeatureTriggered);
            } else {
                this.callSingleCardAni(obj, this.isRabbitFeatureTriggered);
            }
        }
        this.checkAndCreateMissingCards(cards);
    };
    checkAndCreateMissingCards = function (cards) {
        let cardIndex = 0;
        for (const card of cards) {
            if (!this.isCardCreated(card)) {
                this.createCard(card, cardIndex);
            }
            cardIndex++;
        }
    };
    createCard = function (cardValue, index) {
        const presentCard = this.displayUtil.getSprite('C_' + this.cardStyle + '_' + cardValue);
        if (!this.communityCardsContainer) {
            this.communityCardsContainer = this.displayUtil.getContainer();
            this.addChild(this.communityCardsContainer);
        }
        this.communityCardsContainer.addChild(presentCard);
        this.displayUtil.setObjectPosition(presentCard, this.positionConfig.cardsPosition.x[index], this.positionConfig.cardsPosition.y[index]);
        presentCard.anchor.set(0.5);
        //presentCard.scale.set(0, 1);
        //backCard.visible = true;
        this.communityCardsArr.push({
            presentCard: presentCard,
            cardValue: cardValue,
        });
        return presentCard;
    };
    isCardCreated = function (cardValue) {
        for (const communityCard of this.communityCardsArr) {
            if (communityCard.cardValue == cardValue) {
                return true;
            }
        }
        return false;
    };
    showSingleCardsWithAnimation = function (obj) {
        const backCard = this.displayUtil.getSprite('P_' + this.backCardStyle + '_backcard');
        this.addChild(backCard);
        this.displayUtil.setObjectPosition(backCard, 330, 330);
        backCard.anchor.set(0.5);
        backCard.scale.set(0.1);
        const presentCard = this.displayUtil.getSprite('C_' + this.cardStyle + '_' + obj.cards[0]);
        if (!this.communityCardsContainer) {
            this.communityCardsContainer = this.displayUtil.getContainer();
            this.addChild(this.communityCardsContainer);
        }
        this.communityCardsContainer.addChild(presentCard);
        this.displayUtil.setObjectPosition(presentCard, this.positionConfig.cardsPosition.x[this.cCindex], this.positionConfig.cardsPosition.y[0]);
        presentCard.anchor.set(0.5);
        presentCard.scale.set(0, 1);
        backCard.visible = true;
        this.communityCardsArr.push({
            presentCard: presentCard,
            cardValue: obj.cards[0],
        });
        gsap.to(backCard.scale, 0.6, {
            x: 1,
            y: 1,
        });
        gsap.to(backCard, 0.6, {
            rotation: 2 * Math.PI,
            x: this.positionConfig.cardsPosition.x[this.cCindex],
            y: this.positionConfig.cardsPosition.y[this.cCindex],
            autoRotate: true,
            rotationY: 180,
            onComplete: function () {
                gsap.to(backCard.scale, 0.25, {
                    x: 0,
                    y: 1,
                    ease: 'Exponential.easeIn',
                });
                gsap.to(backCard.scale, 0.25, {
                    x: -1,
                    y: 1,
                    ease: 'Exponential.easeOut',
                    onComplete: function () {},
                });
                setTimeout(
                    function () {
                        this.removeChild(backCard);
                    }.bind(this),
                    100,
                );
                gsap.to(presentCard.scale, 0.25, {
                    x: 1,
                    y: 1,
                    ease: 'Exponential.easeOut',
                });
                this.cCindex++;
            }.bind(this),
        });
    };
    showsingleCardsWithoutAnimation = function (obj, isRabbitFeature) {
        const presentCard = this.displayUtil.getSprite('C_' + this.cardStyle + '_' + obj.cards[0]);
        if (!this.communityCardsContainer) {
            this.communityCardsContainer = this.displayUtil.getContainer();
            this.addChild(this.communityCardsContainer);
        }
        this.communityCardsContainer.addChild(presentCard);
        this.displayUtil.setObjectPosition(
            presentCard,
            this.positionConfig.cardsPosition.x[this.cCindex],
            this.positionConfig.cardsPosition.y[0] - uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y,
        );
        // doing below if scale for 7 & 8 seater, decreasing in icons n container
        // if (this.viewModel.gameService.getMaxNoOfSeats() >= 7) {
        //   presentCard.scale.set(0.8);
        // }
        presentCard.anchor.set(0.5);
        //presentCard.scale.set(0, 1);
        //backCard.visible = true;
        this.communityCardsArr.push({
            presentCard: presentCard,
            cardValue: obj.cards[0],
        });
        if (isRabbitFeature) {
            //presentCard.alpha = 0.5;
            presentCard.tint = '0X7d7c7e';
        }
        this.cCindex++;
    };

    callSingleCardAni = function (obj, isRabbitFeature) {
        //if(isRabbitFeature){
        this.showsingleCardsWithoutAnimation(obj, isRabbitFeature);
        // }else{
        //   this.showsingleCardsWithoutAnimation(obj, isRabbitFeature);
        // }
    };
    showThreecardsWithoutAnimation = function (obj, isRabbitFeature) {
        if (!this.communityCardsContainer) {
            this.communityCardsContainer = this.displayUtil.getContainer();
            this.addChild(this.communityCardsContainer);
        }
        for (const card of obj.cards) {
            const presentCard = this.displayUtil.getSprite('C_' + this.cardStyle + '_' + card);
            this.communityCardsContainer.addChildAt(presentCard, 0);
            this.displayUtil.setObjectPosition(
                presentCard,
                this.positionConfig.cardsPosition.x[this.cCindex],
                this.positionConfig.cardsPosition.y[this.cCindex] - uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y,
            );
            presentCard.anchor.set(0.5);
            // doing below if scale for 7 & 8 seater, decreasing in icons n container
            // if (this.viewModel.gameService.getMaxNoOfSeats() >= 7) {
            //   presentCard.scale.set(0.8);
            // }
            if (isRabbitFeature) {
                //presentCard.alpha = 0.5;
                presentCard.tint = '0X7d7c7e';
            }
            this.communityCardsArr.push({
                presentCard: presentCard,
                cardValue: card,
            });
            this.cCindex++;
        }
    };
    showThreecardsWithAnimation = function (obj) {
        const backCard = this.displayUtil.getSprite('P_' + this.backCardStyle + '_backcard');
        this.addChild(backCard);
        this.displayUtil.setObjectPosition(backCard, 330, 330);
        backCard.anchor.set(0.5);
        backCard.scale.set(0.1);
        gsap.to(backCard.scale, 0.6, {
            x: 1,
            y: 1,
        });
        gsap.to(backCard, 0.6, {
            rotation: 2 * Math.PI,
            x: this.positionConfig.cardsPosition.x[0],
            y: this.positionConfig.cardsPosition.y[0],
            autoRotate: true,
            rotationY: 180,
            onComplete: function () {
                gsap.to(backCard.scale, 0.25, {
                    x: 0,
                    y: 1,
                    ease: 'Exponential.easeIn',
                });
                gsap.to(backCard.scale, 0.25, {
                    x: -1,
                    y: 1,
                    ease: 'Exponential.easeOut',
                });
                setTimeout(
                    function () {
                        this.removeChild(backCard);
                    }.bind(this),
                    100,
                );
                if (!this.cardStyle) {
                    this.cardStyle = 0;
                }
                if (!this.communityCardsContainer) {
                    this.communityCardsContainer = this.displayUtil.getContainer();
                    this.addChild(this.communityCardsContainer);
                }
                for (const card of obj.cards) {
                    const presentCard = this.displayUtil.getSprite('C_' + this.cardStyle + '_' + card);
                    this.communityCardsContainer.addChildAt(presentCard, 0);
                    this.displayUtil.setObjectPosition(
                        presentCard,
                        this.positionConfig.cardsPosition.x[0],
                        this.positionConfig.cardsPosition.y[this.cCindex],
                    );
                    presentCard.anchor.set(0.5);
                    presentCard.scale.set(0, 1);
                    this.communityCardsArr.push({
                        presentCard: presentCard,
                        cardValue: card,
                    });
                    gsap.to(presentCard.scale, 0.25, {
                        x: 1,
                        y: 1,
                        ease: 'Exponential.easeOut',
                    });
                    gsap.to(presentCard, 0.7, {
                        // x: this.positionConfig.cardsPosition.x[this.cCindex],
                        // y: this.positionConfig.cardsPosition.y[this.cCindex],
                        x: this.positionConfig.cardsPosition.x[this.cCindex],
                        y: this.positionConfig.cardsPosition.y[this.cCindex],
                        onComplete: function () {}.bind(this),
                    });
                    this.cCindex++;
                }
            }.bind(this),
        });
    };
    callThreeCardAni = function (obj, isRabbitFeature) {
        //if(isRabbitFeature){
        //this.showThreecardsWithoutAnimation(obj, isRabbitFeature);
        //}else{
        this.showThreecardsWithoutAnimation(obj, isRabbitFeature);
        //}
    };
    resetGame = function () {
        this.removeChild(this.communityCardsContainer);
        this.communityCardsContainer = null;
        //this.cCindex = 0;
        this.communityCardsArr = [];
    };
    updateCommunityCardStyle = function (data) {
        this.backCardStyle = data.cardStyle.cardBackgroundStyle;
        if (data.cardStyle.cardViewStyle == this.cardStyle) {
            return;
        } else {
            // if (data.cardStyle.cardViewStyle == 2) {
            //   this.cardStyle = 0;
            // }
            // if (data.cardStyle.cardViewStyle == 3) {
            //   this.cardStyle = 1;
            // }
            this.cardStyle = data.cardStyle.cardViewStyle;
            for (const i in this.communityCardsArr) {
                // posArr.push({"x": this.communityCardsArr[i].presentCard.x, "y" : this.communityCardsArr[i].presentCard.y});
                // this.communityCardsContainer.removeChild(this.communityCardsArr[i].presentCard);
                // let presentCard = this.displayUtil.getSprite("C_"+this.cardStyle+"_"+this.communityCardsArr[i].cardValue);
                // this.communityCardsContainer.addChild(presentCard);
                // presentCard.anchor.set(0.5);
                // this.displayUtil.setObjectPosition(presentCard, posArr[i].x, posArr[i].y);
                this.displayUtil.setTexture(this.communityCardsArr[i].presentCard, 'C_' + this.cardStyle + '_' + this.communityCardsArr[i].cardValue);
                //this.communityCardsArr.splice(i,1,{"presentCard" : presentCard, "cardValue" : this.communityCardsArr[i].cardValue});
                this.communityCardsArr.splice(i, 1, {
                    presentCard: this.communityCardsArr[i].presentCard,
                    cardValue: this.communityCardsArr[i].cardValue,
                });
            }
        }
    };
    updateSpinUltraLogo = function (spinCardType) {
        if (spinCardType == 2) {
            this.displayUtil.setTexture(this.bgLogo, 'spins_ultra_logo');
            this.displayUtil.setObjectPosition(
                this.bgLogo,
                110,
                470 - uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y,
            );
        }
    };
    enableCashOutDisableText = function () {
        this.isCashoutBrandLabelEnabled = true;
    };
}
