import { Message } from '../../message';

export class ResponseTourneyLobbyDeltaChanges extends Message {
    private MESSAGE_NAME: string = 'ResponseTourneyLobbyDeltaChanges';
    private mtctId: number = 0;
    private deltaEvents: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, deltaEvents: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.deltaEvents = deltaEvents;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
}
