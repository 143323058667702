import { Message } from '../../message';

export class UpdateUserPropSettings extends Message {
    private MESSAGE_NAME: string = 'UpdateUserPropSettings';
    private propertySettings: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, propertySettings: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.propertySettings = propertySettings;
    }

    getPropertySettings(): Map<any, any> | null {
        return this.propertySettings;
    }

    setPropertySettings(propertySettings: Map<any, any> | null) {
        this.propertySettings = propertySettings;
    }
}
