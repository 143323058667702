<div id="myTickets" class="myTickets">
    <section class="ticketDetails">
        <div class="subTickets-wrapper">
            <div class="subTickets">
                <div
                    class="iteam"
                    *ngFor="let ticket of allTtickets"
                    (click)="useTicketNow($event, ticket)"
                    [ngClass]="{ active: selectedSubTicket === ticket }">
                    <div class="startsInwrapper">
                        <div class="startsIn">
                            <span class="startInText"> {{ staticContent.messages.startsIn }}</span>
                            <span class="startInText">
                                {{ staticContent.messages.lateReg }}
                            </span>
                            <span class="startInValue" *ngIf="statusFilter(ticket) === 'days'"
                                >{{ ticket.days }}
                                {{ staticContent.messages.days }}
                            </span>
                            <span class="startInValue" *ngIf="statusFilter(ticket) === 'hours mins'">
                                {{ ticket.hours }}{{ staticContent.messages.h }} {{ ticket.mins }}{{ staticContent.messages.m }}
                            </span>
                            <span class="startInValue" *ngIf="statusFilter(ticket) === 'hours'"
                                >{{ ticket.hours }}
                                {{ staticContent.messages.h }}
                            </span>
                            <span class="startInValue" *ngIf="statusFilter(ticket) === 'mins'"
                                >{{ ticket.mins }}
                                {{ staticContent.messages.mins }}
                            </span>
                            <span class="startInValue" *ngIf="statusFilter(ticket) === 'secs'"
                                >{{ ticket.secs }}
                                {{ staticContent.messages.secs }}
                            </span>
                        </div>
                        <div class="buyinsWrapper">
                            <p>{{ ticket.name | ticketsTextTruncate: ['100', '...'] }}</p>
                            <div>
                                <span class="users"
                                    ><span>&nbsp;</span> {{ ticket.mtctLobbyData.noOfParticipants }}/{{ ticket.mtctLobbyData.maxParticipants }}</span
                                >
                                <span class="buyIn"
                                    >{{ staticContent.messages.buyIn }}: <span>${{ ticket.mtctLobbyData.totalBuyIn }}</span></span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subTicketsBtns" [class.btnHangup]="btnHangup">
                    <button class="activeTicket">{{ staticContent.messages.useTicketNow }}</button>
                    <button class="defaultTicket">{{ staticContent.messages.openLobby }}</button>
                </div>
            </div>
        </div>
    </section>
</div>
