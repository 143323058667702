import { Message } from '../../message';

export class ResponseMTCTDetailsList extends Message {
    private MESSAGE_NAME: string = 'ResponseMTCTDetailsList';
    private clearAllTabIdSnapShots: boolean | null = false;
    private tabIdMtctDetailsMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clearAllTabIdSnapShots: boolean | null = false, tabIdMtctDetailsMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
        this.tabIdMtctDetailsMap = tabIdMtctDetailsMap;
    }

    getClearAllTabIdSnapShots(): boolean | null {
        return this.clearAllTabIdSnapShots;
    }

    setClearAllTabIdSnapShots(clearAllTabIdSnapShots: boolean | null) {
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
    }
    getTabIdMtctDetailsMap(): Map<any, any> | null {
        return this.tabIdMtctDetailsMap;
    }

    setTabIdMtctDetailsMap(tabIdMtctDetailsMap: Map<any, any> | null) {
        this.tabIdMtctDetailsMap = tabIdMtctDetailsMap;
    }
}
