import { Message } from '../../message';

export class UpdateCardLastPlayed extends Message {
    private MESSAGE_NAME: string = 'UpdateCardLastPlayed';
    private cardId: number = 0;
    private lastPlayed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardId: number = 0, lastPlayed: boolean | null = false) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.lastPlayed = lastPlayed;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getLastPlayed(): boolean | null {
        return this.lastPlayed;
    }

    setLastPlayed(lastPlayed: boolean | null) {
        this.lastPlayed = lastPlayed;
    }
}
