import { Message } from '../../message';

export class LanguagePackUpdate extends Message {
    private MESSAGE_NAME: string = 'LanguagePackUpdate';
    private upgradeURL: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, upgradeURL: any[] | null = null) {
        super(reqServerPeerId);
        this.upgradeURL = upgradeURL;
    }

    getUpgradeURL(): any[] | null {
        return this.upgradeURL;
    }

    setUpgradeURL(upgradeURL: any[] | null) {
        this.upgradeURL = upgradeURL;
    }
}
