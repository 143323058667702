import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { HandHistoryInputModel } from './hand-history.models';
import { HandHistoryService } from './hand-history.service';

@Injectable({ providedIn: 'root' })
export class HandHistoryResolver implements Resolve<any> {
    constructor(private serve: HandHistoryService) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolve(route: ActivatedRouteSnapshot) {
        const handHistoryInputModel: HandHistoryInputModel = new HandHistoryInputModel();
        handHistoryInputModel.GameType = 1;
        handHistoryInputModel.GameCodeType = 1;
        handHistoryInputModel.PocketCardOne = 'XX';
        handHistoryInputModel.PocketCardTwo = 'XX';
        handHistoryInputModel.PocketCardSuited = false;
        handHistoryInputModel.FinalHand = 0;
        handHistoryInputModel.FinalHandMatch = 0;
        handHistoryInputModel.MinPotSize = 0;
        handHistoryInputModel.LimitType = 0;
        handHistoryInputModel.ShowHand = false;
        return this.serve.getHandHistory(handHistoryInputModel);
    }
}
