import { Message } from '../../message';

export class JoinWaitlistRefresh extends Message {
    private MESSAGE_NAME: string = 'JoinWaitlistRefresh';
    private joinedLists: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, joinedLists: any[] | null = null) {
        super(reqServerPeerId);
        this.joinedLists = joinedLists;
    }

    getJoinedLists(): any[] | null {
        return this.joinedLists;
    }

    setJoinedLists(joinedLists: any[] | null) {
        this.joinedLists = joinedLists;
    }
}
