import { Message } from '../../message';

export class CalendarAlert extends Message {
    private MESSAGE_NAME: string = 'CalendarAlert';
    private mtctId: number = 0;
    private alertType: number = 0;
    private alertTime: any | null = null;
    private email: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, alertType: number = 0, alertTime: any | null = null, email: string | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.alertType = alertType;
        this.alertTime = alertTime;
        this.email = email;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getAlertType(): number {
        return this.alertType;
    }

    setAlertType(alertType: number) {
        this.alertType = alertType;
    }
    getAlertTime(): any | null {
        return this.alertTime;
    }

    setAlertTime(alertTime: any | null) {
        this.alertTime = alertTime;
    }
    getEmail(): string | null {
        return this.email;
    }

    setEmail(email: string | null) {
        this.email = email;
    }
}
