import { Message } from '../../message';

export class TournamentCurrentStatus extends Message {
    private MESSAGE_NAME: string = 'TournamentCurrentStatus';
    private status: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: number = 0) {
        super(reqServerPeerId);
        this.status = status;
    }

    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
}
