import { ByteBuffer } from '../Lib/byteBuffer';
import Charset from '../Lib/charSet';

export class CodedOutputStream {
    position: number;
    limit: number;
    buffer: ByteBuffer;

    constructor() {
        this.position = 0;
        this.limit = 4096;
        this.buffer = new ByteBuffer();
    }

    writeInt32(val: number): void {
        if (val >= 0) {
            this.writeRawVarint32(val);
        } else {
            this.writeNegativeRawVarint64(val);
        }
    }

    writeRawVarint32(val: number): void {
        const condition = true;
        while (condition) {
            if ((val & ~0x7f) === 0) {
                this.writeRawByte(val);
                return;
            }
            this.writeRawByte((val & 0x7f) | 0x80);
            val = val >>> 7;
        }
    }

    writeRawByte(val: number): void {
        if (this.position === this.limit) {
            this.refreshBuffer();
        }

        this.buffer.put(val);
        this.position = this.position + 1;
    }

    writeBool(val: boolean | null): void {
        if (val) {
            this.writeRawByte(1);
        } else {
            this.writeRawByte(0);
        }
    }

    writeFixed8(val: number): void {
        this.writeRawByte(val);
    }

    writeString(val: string): void {
        try {
            let buf: ByteBuffer | null = new ByteBuffer();

            buf.putString(val, new Charset.UTF8());
            buf.flip();
            this.writeRawVarint32(buf.limit);
            this.buffer.putBuffer(buf);
            buf.dispose();
            buf = null;
        } catch (e) {
            throw new Error('Exception occured at writeString Method in codedOutPutStream');
            // alert('exception');
        }
    }

    writeBytes(val: number[]): void {
        this.writeRawVarint32(val.length);
        this.buffer.putBytes(val);
    }

    writeInt64(val: number): void {
        if (val >= 0) {
            this.writePositiveRawVarint64(val);
        } else {
            this.writeNegativeRawVarint64(val);
        }
    }

    writePositiveRawVarint64(val: number): void {
        let div: number;
        let toWrite: number;
        const condition = true;
        while (condition) {
            if (val <= 0x7f) {
                this.writeRawByte(val);
                return;
            }

            div = Math.pow(2, 7);
            toWrite = val % div;
            toWrite = toWrite | 0x80;

            this.writeRawByte(toWrite);
            val = val / div;
        }
    }

    writeNegativeRawVarint64(val: number): void {
        let temp = 0;
        let isAddComplete = false;
        let bArray: ByteBuffer | null = new ByteBuffer();

        // Convert to positive value
        val = -val;

        for (let x = 0; x < 8; x++) {
            // Little-endian format
            temp = val / Math.pow(2, 8 * x);
            // Ones complement
            temp = ~temp;

            if (!isAddComplete) {
                // Adding 1 to make it two's complement
                temp += 1;

                if ((temp & 0xff) !== 0) {
                    isAddComplete = true;
                }
            }

            bArray.put(temp);
        }
        this.generateProtobufCode(bArray);
        bArray.dispose();
        bArray = null;
    }

    generateProtobufCode(bArray: ByteBuffer): void {
        bArray.position = 0;

        // Write first byte
        let read = bArray.get();
        let toWrite;

        toWrite = (read & 0x7f) | 0x80;
        this.writeRawByte(toWrite);

        // Write second byte (1 byte left from previous)
        toWrite = (read & 0xff) >>> 7;
        read = bArray.get();
        toWrite = toWrite | (read << 1) | 0x80;
        this.writeRawByte(toWrite);

        // Write third byte (2 bytes left from previous)
        toWrite = (read & 0xff) >>> 6;
        read = bArray.get();
        toWrite = toWrite | (read << 2) | 0x80;
        this.writeRawByte(toWrite);

        // Write fourth byte (3 bytes left from previous)
        toWrite = (read & 0xff) >>> 5;
        read = bArray.get();
        toWrite = toWrite | (read << 3) | 0x80;
        this.writeRawByte(toWrite);

        // Write fifth byte (4 bytes left from previous)
        toWrite = (read & 0xff) >>> 4;
        read = bArray.get();
        toWrite = toWrite | (read << 4) | 0x80;
        this.writeRawByte(toWrite);

        // Write sixth byte (5 bytes left from previous)
        toWrite = (read & 0xff) >>> 3;
        read = bArray.get();
        toWrite = toWrite | (read << 5) | 0x80;
        this.writeRawByte(toWrite);

        // Write seventh byte (6 bytes left from previous)
        toWrite = (read & 0xff) >>> 2;
        read = bArray.get();
        toWrite = toWrite | (read << 6) | 0x80;
        this.writeRawByte(toWrite);

        // Write eighth byte (7 bytes left from previous)
        // const ftoWrite = (read & 0xff) >>> 1;            commented for linting errors
        // read = bArray.readByte();
        toWrite = toWrite | 0x80;
        this.writeRawByte(toWrite);

        // Write ninth byte (0 bytes from previous)
        read = bArray.get();
        if ((read & ~0x7f) === 0) {
            this.writeRawByte(read);
        } else {
            this.writeRawByte(read);
            // 10th byte (1 byte from previous)
            this.writeRawByte(0x01);
        }
    }

    refreshBuffer(): void {
        // CM TO-DO - this needs to change to support backward compatibility,
        // if there is any use case where this gets called.

        // write the existing data
        this.buffer.clear();
        this.position = 0;
    }

    getBytes(): ByteBuffer {
        return this.buffer;
    }

    getPosition(): number {
        return this.buffer.getPosition();
    }

    setPosition(position: number): void {
        this.buffer.setPosition(position);
    }
}
