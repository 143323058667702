import { Message } from '../../message';

export class AutoLoginRequest extends Message {
    private MESSAGE_NAME: string = 'AutoLoginRequest';
    private ssoKey: string | null = null;
    private invokerProductId: string | null = null;
    private invokerSessionKey: string | null = null;
    private loginId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        ssoKey: string | null = null,
        invokerProductId: string | null = null,
        invokerSessionKey: string | null = null,
        loginId: string | null = null,
    ) {
        super(reqServerPeerId);
        this.ssoKey = ssoKey;
        this.invokerProductId = invokerProductId;
        this.invokerSessionKey = invokerSessionKey;
        this.loginId = loginId;
    }

    getSsoKey(): string | null {
        return this.ssoKey;
    }

    setSsoKey(ssoKey: string | null) {
        this.ssoKey = ssoKey;
    }
    getInvokerProductId(): string | null {
        return this.invokerProductId;
    }

    setInvokerProductId(invokerProductId: string | null) {
        this.invokerProductId = invokerProductId;
    }
    getInvokerSessionKey(): string | null {
        return this.invokerSessionKey;
    }

    setInvokerSessionKey(invokerSessionKey: string | null) {
        this.invokerSessionKey = invokerSessionKey;
    }
    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
}
