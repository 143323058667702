import { Message } from '../../message';

export class RequestLobbyConfig extends Message {
    private MESSAGE_NAME: string = 'RequestLobbyConfig';
    private md5sum: string | null = null;
    private isLogedIn: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, md5sum: string | null = null, isLogedIn: boolean | null = false) {
        super(reqServerPeerId);
        this.md5sum = md5sum;
        this.isLogedIn = isLogedIn;
    }

    getMd5sum(): string | null {
        return this.md5sum;
    }

    setMd5sum(md5sum: string | null) {
        this.md5sum = md5sum;
    }
    getIsLogedIn(): boolean | null {
        return this.isLogedIn;
    }

    setIsLogedIn(isLogedIn: boolean | null) {
        this.isLogedIn = isLogedIn;
    }
}
