import { Message } from '../../message';

export class ResponseLossLimitExceed extends Message {
    private MESSAGE_NAME: string = 'ResponseLossLimitExceed';
    private message: any | null = null;
    private entryId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, message: any | null = null, entryId: number = 0) {
        super(reqServerPeerId);
        this.message = message;
        this.entryId = entryId;
    }

    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
}
