<div class="homepage-section" *ngIf="howtoplayContentEnabled">
    <div class="col lobby-width">
        <div class="row flex-column-reverse flex-md-row">
            <div class="col-md-5 pc-txt decription-section">
                <div class="decription-section-inner">
                    <h2 class="heading-text">{{ howtoplayContentContent.title }}</h2>
                    <div class="home-section-desc" [innerHtml]="howtoplayContentContent.text"></div>
                    <div
                        class="homepage-button"
                        *ngIf="howtoplayContentContent.optionalText"
                        [innerHtml]="howtoplayContentContent.optionalText"></div>
                    <div class="homepage-button" *ngIf="howtoplayContentContent.titleLink && howtoplayContentContent.titleLink.linkText">
                        <a
                            class="ml-4"
                            [href]="howtoplayContentContent.titleLink.url"
                            [target]="howtoplayContentContent.titleLink.attributes.target || '_self'"
                            >{{ howtoplayContentContent.titleLink.linkText }}</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center">
                <img *ngIf="howtoplayContentContent.image" [src]="howtoplayContentContent.image.src" alt="How to play" class="img-fluid" />
            </div>
        </div>
    </div>
</div>
