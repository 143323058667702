import { Message } from '../../message';

export class OptionSetAck extends Message {
    private MESSAGE_NAME: string = 'OptionSetAck';
    private optionSetId: number = 0;
    private clientServerClock: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, optionSetId: number = 0, clientServerClock: number = 0) {
        super(reqServerPeerId);
        this.optionSetId = optionSetId;
        this.clientServerClock = clientServerClock;
    }

    getOptionSetId(): number {
        return this.optionSetId;
    }

    setOptionSetId(optionSetId: number) {
        this.optionSetId = optionSetId;
    }
    getClientServerClock(): number {
        return this.clientServerClock;
    }

    setClientServerClock(clientServerClock: number) {
        this.clientServerClock = clientServerClock;
    }
}
