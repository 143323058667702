import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

import { NavigationService, PlainLinkComponent } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';

import { TicketsTruncatePipe } from './mytickets.pipe';

@Component({
    selector: 'pk-my-ticket-details',
    templateUrl: 'myticketDetails.component.html',
    standalone: true,
    imports: [CommonModule, PlainLinkComponent, TicketsTruncatePipe, myTicketDetailsComponent],
})
export class myTicketDetailsComponent implements OnInit {
    currentTicketDetails: any;
    allTtickets: any;
    currentticket: any;
    staticContent: any;
    btnHangup: boolean;
    selectedSubTicket: any;

    constructor(
        private routeData: RouteDataService,
        private navigation: NavigationService,
    ) {}

    ngOnInit(): void {
        this.currentTicketDetails = this.routeData.getInitData();
        this.allTtickets = this.currentTicketDetails.tournamentTicketDetails;
        this.currentticket = this.currentTicketDetails.tournamentticket;
        this.staticContent = this.currentTicketDetails.clientContentData;
    }

    useTicketNow(event: any, ticket: any) {
        this.selectedSubTicket = ticket;
    }

    backToOverview() {
        this.navigation.goTo('/mobilepoker/mytickets');
    }

    @HostListener('window:scroll', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onWindowScroll(event: any) {
        const windowScrollTop = window.pageYOffset;
        const windowHeight = document.documentElement.clientHeight;
        const footerOffsetTop = document.getElementsByTagName('footer')[0].offsetTop;
        if (windowHeight + windowScrollTop > footerOffsetTop) {
            this.btnHangup = true;
        } else {
            this.btnHangup = false;
        }
    }

    statusFilter(ticketStatus: any) {
        let key: any,
            value: any = '';
        for (key in ticketStatus) {
            if (ticketStatus[key] > 0) {
                switch (key) {
                    case 'days':
                        value = 'days';
                        break;
                    case 'hours':
                        value = 'hours';
                        if (ticketStatus['mins'] > 0) {
                            value += ' mins';
                        }
                        break;
                    case 'mins':
                        value = 'mins';
                        break;
                    case 'secs':
                        value = 'secs';
                        break;
                }
                if (value.length > 0) {
                    return value;
                }
            }
        }
    }
}
