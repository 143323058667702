import { Message } from '../../message';
import { DealMakingSelectedPayout } from './DealMakingSelectedPayout';

export class DealMakingSubmitDeal extends Message {
    private MESSAGE_NAME: string = 'DealMakingSubmitDeal';
    private selectedPayout: DealMakingSelectedPayout | null = null;
    private postDealPayouts: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, selectedPayout: DealMakingSelectedPayout | null = null, postDealPayouts: any[] | null = null) {
        super(reqServerPeerId);
        this.selectedPayout = selectedPayout;
        this.postDealPayouts = postDealPayouts;
    }

    getSelectedPayout(): DealMakingSelectedPayout | null {
        return this.selectedPayout;
    }

    setSelectedPayout(selectedPayout: DealMakingSelectedPayout | null) {
        this.selectedPayout = selectedPayout;
    }
    getPostDealPayouts(): any[] | null {
        return this.postDealPayouts;
    }

    setPostDealPayouts(postDealPayouts: any[] | null) {
        this.postDealPayouts = postDealPayouts;
    }
}
