import { Message } from '../../message';
import { SNGJPBountyInfo } from './SNGJPBountyInfo';

export class SNGJPPayoutInfo extends Message {
    private MESSAGE_NAME: string = 'SNGJPPayoutInfo';
    private sngJackpotID: number = 0;
    private sngJPInstanceID: number = 0;
    private sngJPType: number = 0;
    private totalPrize: number = 0;
    private prizesInfo: any[] | null = null;
    private sngJPBountyInfo: SNGJPBountyInfo | null = null;
    private payoutType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        sngJPInstanceID: number = 0,
        sngJPType: number = 0,
        totalPrize: number = 0,
        prizesInfo: any[] | null = null,
        sngJPBountyInfo: SNGJPBountyInfo | null = null,
        payoutType: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.sngJPInstanceID = sngJPInstanceID;
        this.sngJPType = sngJPType;
        this.totalPrize = totalPrize;
        this.prizesInfo = prizesInfo;
        this.sngJPBountyInfo = sngJPBountyInfo;
        this.payoutType = payoutType;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getSngJPInstanceID(): number {
        return this.sngJPInstanceID;
    }

    setSngJPInstanceID(sngJPInstanceID: number) {
        this.sngJPInstanceID = sngJPInstanceID;
    }
    getSngJPType(): number {
        return this.sngJPType;
    }

    setSngJPType(sngJPType: number) {
        this.sngJPType = sngJPType;
    }
    getTotalPrize(): number {
        return this.totalPrize;
    }

    setTotalPrize(totalPrize: number) {
        this.totalPrize = totalPrize;
    }
    getPrizesInfo(): any[] | null {
        return this.prizesInfo;
    }

    setPrizesInfo(prizesInfo: any[] | null) {
        this.prizesInfo = prizesInfo;
    }
    getSngJPBountyInfo(): SNGJPBountyInfo | null {
        return this.sngJPBountyInfo;
    }

    setSngJPBountyInfo(sngJPBountyInfo: SNGJPBountyInfo | null) {
        this.sngJPBountyInfo = sngJPBountyInfo;
    }
    getPayoutType(): number {
        return this.payoutType;
    }

    setPayoutType(payoutType: number) {
        this.payoutType = payoutType;
    }
}
