import { Message } from '../../message';

export class PlayerStatuses extends Message {
    private MESSAGE_NAME: string = 'PlayerStatuses';
    private playerStatuses: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerStatuses: any[] | null = null) {
        super(reqServerPeerId);
        this.playerStatuses = playerStatuses;
    }

    getPlayerStatuses(): any[] | null {
        return this.playerStatuses;
    }

    setPlayerStatuses(playerStatuses: any[] | null) {
        this.playerStatuses = playerStatuses;
    }
}
