import { Message } from '../../message';

export class ResponseFFDataOnClick extends Message {
    private MESSAGE_NAME: string = 'ResponseFFDataOnClick';
    private allCardsMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, allCardsMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.allCardsMap = allCardsMap;
    }

    getAllCardsMap(): Map<any, any> | null {
        return this.allCardsMap;
    }

    setAllCardsMap(allCardsMap: Map<any, any> | null) {
        this.allCardsMap = allCardsMap;
    }
}
