<div class="calendar-section">
    <div class="calendar-widjet">
        <div class="calendar-widjet-row cal-desktop">
            <vn-swiper [swiperOptions]="swiperOptions">
                <div class="swiper-slide" *ngFor="let tournament of tournamentList; let tournamentIndex = index">
                    <div class="medium-homepage-tournament">
                        <div class="time-info pull-left">
                            <pt-tournament-calendar-timer [endTime]="tournament.startDate"></pt-tournament-calendar-timer>
                        </div>
                        <div class="greencard-info pull-right">{{ tournament.gameType }}</div>
                        <div class="clear-fix heading-info">{{ tournament.name | removeColorCodesPipe }}</div>
                        <div class="border-sec pull-left">
                            <div class="pull-left small-heading-width">
                                <div class="small-heading">{{ moreinfo.messages['text-limit'] }}</div>
                                <div class="data-info">{{ tournament.limitType | limitNamePipe }}</div>
                            </div>
                            <div class="pull-left small-heading-width">
                                <div class="small-heading">{{ moreinfo.messages['text-buyin'] }}</div>
                                <div class="data-info">
                                    {{ tournament.buyIn.monetaryType | formatCurrencyPipe: tournament.buyIn.unit }} +
                                    {{ tournament.fee.monetaryType | formatCurrencyPipe: tournament.fee.unit }}
                                </div>
                            </div>
                            <div class="pull-left small-heading-width players">
                                <div class="small-heading">{{ moreinfo.messages['text-players'] }}</div>
                                <div class="data-info">{{ tournament.registeredPlayers }}</div>
                            </div>
                        </div>
                        <div class="clear-fix btn-padding">
                            <button type="button" class="pull-right btn btn-primary" (click)="handleTournamentClick(tournament.id, true)">
                                {{ moreinfo.messages['text-more-info'] }}
                            </button>
                            <button
                                type="button"
                                class="pull-right btn btn-light btn-block"
                                id="add-to-cal{{ tournamentIndex }}"
                                (click)="showMenu($event, 'menu-items', tournamentIndex)">
                                {{ moreinfo.messages['AddToCalendar'] }}
                            </button>
                        </div>
                        <div class="menu-items" id="menu-items{{ tournamentIndex }}">
                            <div class="pull-right theme-ex" (click)="closeMenu($event, 'menu-items', tournamentIndex)"></div>
                            <div class="clear-fix social-links">
                                <a [href]="googleCalendarHost + tournament.googleCalendarUrlSegment" target="_blank"
                                    >{{ moreinfo.messages['AddToCalendarGoogle'] }}<span class="theme-right"></span
                                ></a>
                            </div>
                            <div class="social-links">
                                <a [href]="msnCalendarHost + tournament.msnCalendarUrlSegment" target="_blank"
                                    >{{ moreinfo.messages['AddToCalendarMsn'] }}<span class="theme-right"></span
                                ></a>
                            </div>
                            <div class="social-links yahoo-cls">
                                <a [href]="yahooCalendarHost + tournament.yahooCalendarUrlSegment" target="_blank"
                                    >{{ moreinfo.messages['AddToCalendarYahoo'] }}<span class="theme-right"></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </vn-swiper>
        </div>
        <div class="calendar-widjet-row cal-mobile">
            <div class="calendar-widjet">
                <div class="medium-homepage-tournament" *ngFor="let tournament of tournamentList; let tournamentIndex = index">
                    <div class="time-info pull-left">
                        <pt-tournament-calendar-timer [endTime]="tournament.startDate"></pt-tournament-calendar-timer>
                    </div>
                    <div class="greencard-info pull-right">{{ tournament.gameType }}</div>
                    <div class="clear-fix heading-info">{{ tournament.name | removeColorCodesPipe }}</div>
                    <div class="border-sec pull-left">
                        <div class="pull-left small-heading-width">
                            <div class="small-heading">{{ moreinfo.messages['text-limit'] }}</div>
                            <div class="data-info">{{ tournament.limitType | limitNamePipe }}</div>
                        </div>
                        <div class="pull-left small-heading-width">
                            <div class="small-heading">{{ moreinfo.messages['text-buyin'] }}</div>
                            <div class="data-info">
                                {{ tournament.buyIn.monetaryType | formatCurrencyPipe: tournament.buyIn.unit }} +
                                {{ tournament.fee.monetaryType | formatCurrencyPipe: tournament.fee.unit }}
                            </div>
                        </div>
                        <div class="pull-left small-heading-width players">
                            <div class="small-heading">{{ moreinfo.messages['text-players'] }}</div>
                            <div class="data-info">{{ tournament.registeredPlayers }}</div>
                        </div>
                    </div>
                    <div class="clear-fix btn-padding">
                        <button type="button" class="pull-right btn btn-primary" (click)="handleTournamentClick(tournament.id, true)">
                            {{ moreinfo.messages['text-more-info'] }}
                        </button>
                        <button
                            type="button"
                            class="pull-right btn btn-light btn-block"
                            id="add-to-cal-mob{{ tournamentIndex }}"
                            (click)="showMenu($event, 'menu-items-mobile', tournamentIndex)">
                            {{ moreinfo.messages['AddToCalendar'] }}
                        </button>
                    </div>
                    <div class="menu-items-mobile" id="menu-items-mobile{{ tournamentIndex }}">
                        <div class="pull-right theme-ex" (click)="closeMenu($event, 'menu-items-mobile', tournamentIndex)"></div>
                        <div class="clear-fix social-links">
                            <a [href]="googleCalendarHost + tournament.googleCalendarUrlSegment" target="_blank"
                                >{{ moreinfo.messages['AddToCalendarGoogle'] }}<span class="theme-right"></span
                            ></a>
                        </div>
                        <div class="social-links">
                            <a [href]="msnCalendarHost + tournament.msnCalendarUrlSegment" target="_blank"
                                >{{ moreinfo.messages['AddToCalendarMsn'] }}<span class="theme-right"></span
                            ></a>
                        </div>
                        <div class="social-links yahoo-cls">
                            <a [href]="yahooCalendarHost + tournament.yahooCalendarUrlSegment" target="_blank"
                                >{{ moreinfo.messages['AddToCalendarYahoo'] }}<span class="theme-right"></span
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
