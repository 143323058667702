import { Message } from '../../message';

export class ResponseUnjoinTableWaitlist extends Message {
    private MESSAGE_NAME: string = 'ResponseUnjoinTableWaitlist';
    private status: boolean | null = false;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: boolean | null = false, tableId: number = 0) {
        super(reqServerPeerId);
        this.status = status;
        this.tableId = tableId;
    }

    getStatus(): boolean | null {
        return this.status;
    }

    setStatus(status: boolean | null) {
        this.status = status;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
