const ENTITY_TYPES = {
    INT: 0x01,
    LONG: 0x02,
    STRING: 0x03,
    BOOLEAN: 0x04,
    SHORT: 0x05,
    TIMESTAMP: 0x06,
    STRINGEX: 0x09,
    VECTOR: 0xf0,
    HASHTABLE: 0xf1,
    OBJECT: 0xff,
    NULL: 0xfe,
    BYTE: 0xfd,
    BYTE_ARRAY: 0xfc,
    ARRAY: 0xf4,
};

const NATURES = {
    HOMOGENEOUS: 0x07,
    HETEROGENEOUS: 0x08,
};

class StringEx {
    constructor(
        public msg: string | null,
        public templateId: any,
        public params: any,
        public isLPElement: any,
    ) {}
}

const TSUtil = {
    // Notification Names
    PGSocketConnected: 'PGSocketConnected',
    PGSocketDisconnected: 'PGSocketDisconnected',
    PGSocketDataReceived: 'PGSocketDataReceived',
    PGServerMessageNotification: 'PGServerMessage',
    PGClientMessageNotification: 'PGClientMessage',
    PGMessageNotification: 'PGMessageNotification',

    ENTITY_TYPES,
    NATURES,

    StringEx,

    // Domain Ids
    DOMAIN_REAL: 0,
    DOMAIN_PLAY: 1,

    // Subscription Codes
    UNSUBSCRIBE_CODE: 0,
    SUBSCRIBE_CODE: 1,

    // Handshake Codes
    HANDSHAKE_INIT_CONNECTION: 1,
    HANDSHAKE_REVISIT: 2,
    HANDSHAKE_RELOGIN: 3,
    HANDSHAKERESPONSE_SUCCESS: 1,
    HANDSHAKERESPONSE_INVALID_SESSION: 2,
    HANDSHAKERESPONSE_FAIL: 3,
    HANDSHAKERESPONSE_SERVER_OVERLOADED: 4,
    HANDSHAKERESPONSE_DUMMY_SERVER: 5,
    HANDSHAKERESPONSE_EXE_CHECKSUM_ERR: 6,
    HANDSHAKERESPONSE_INI_CHECKSUM_ERR: 7,
    HANDSHAKERESPONSE_STATIC_STATUS_ERR: 8,
    HANDSHAKERESPONSE_SYS_INI_CHECKSUM_ERR: 9,

    // Connection Status
    SOCKET_NOT_CONNECTED: 0,
    SOCKET_CONNECTED: 1,
    SOCKET_INTERNET_DELAYS: 2,
    SOCKET_SPEED: 3,
    SOCKET_PGHANDSHAKE_SUCCESS: 4,

    DUMMY_REAL_LOBBY_PEER: -100,
    DUMMY_PLAY_LOBBY_PEER: -200,

    CONNECTOR_NOT_INITIALIZED: 0,
    CONNECTOR_INITIALIZED: 1,
    CONNECTOR_CLOSED: 2,

    // Build Notification Name from classId.
    // buildServerMsgNotificationName(classId: any) {
    //   return this.PGServerMessageNotification + '_' + classId;
    // },

    // buildClientMsgNotificationName(classId: any) {
    //   return this.PGClientMessageNotification + '_' + classId;
    // },
};

export default TSUtil;
