import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as ptf from './poker-twitter-feed.component';

@Injectable({ providedIn: 'root' })
export class PokerTwitterFeedPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('twitterfeedtemplate', ptf.PokerTwitterFeedComponent);
        });
    }
}
