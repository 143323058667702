import { Message } from '../../message';

export class HandShakeResponse extends Message {
    private MESSAGE_NAME: string = 'HandShakeResponse';
    private responseId: number = 0;
    private sessionKey: string | null = null;
    private desc: any | null = null;
    private effectiveLanguage: string | null = null;
    private productCurrencyCode: string | null = null;
    private showCaptcha: boolean | null = false;
    private serverType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        responseId: number = 0,
        sessionKey: string | null = null,
        desc: any | null = null,
        effectiveLanguage: string | null = null,
        productCurrencyCode: string | null = null,
        showCaptcha: boolean | null = false,
        serverType: number = 0,
    ) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.sessionKey = sessionKey;
        this.desc = desc;
        this.effectiveLanguage = effectiveLanguage;
        this.productCurrencyCode = productCurrencyCode;
        this.showCaptcha = showCaptcha;
        this.serverType = serverType;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getSessionKey(): string | null {
        return this.sessionKey;
    }

    setSessionKey(sessionKey: string | null) {
        this.sessionKey = sessionKey;
    }
    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
    getEffectiveLanguage(): string | null {
        return this.effectiveLanguage;
    }

    setEffectiveLanguage(effectiveLanguage: string | null) {
        this.effectiveLanguage = effectiveLanguage;
    }
    getProductCurrencyCode(): string | null {
        return this.productCurrencyCode;
    }

    setProductCurrencyCode(productCurrencyCode: string | null) {
        this.productCurrencyCode = productCurrencyCode;
    }
    getShowCaptcha(): boolean | null {
        return this.showCaptcha;
    }

    setShowCaptcha(showCaptcha: boolean | null) {
        this.showCaptcha = showCaptcha;
    }
    getServerType(): number {
        return this.serverType;
    }

    setServerType(serverType: number) {
        this.serverType = serverType;
    }
}
