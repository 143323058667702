import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { ParseRibbonMarkupPipe } from './parse-ribbon-markup.pipe';

@Component({
    selector: 'pk-single-promo-timer',
    templateUrl: 'pk-single-promo-timer.component.html',
    standalone: true,
    imports: [CommonModule, TrustAsHtmlPipe, RouterModule, ParseRibbonMarkupPipe],
})
export class PkSinglePromoTimerComponent implements OnChanges {
    @Input() singlePromoTimerRibbonData: any;
    @Input() singlePromoTimerStyles: any;
    @Output() timerStatus = new EventEmitter<boolean>();
    startTime: any;
    endTime: any;
    currentTimeInUTC: any;
    currentTime: any;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    millisecondsleft: number;
    regEndMilliSecondsLeft: number;
    timeLeft: string;
    callback: any;
    registrationDetails: any;
    bannerDisplayTime: any;
    bannerKickoffInHours: number;
    bannerKickoffInDays: number;
    isBannerEnabled: boolean = false;
    isRegistrationStarted: boolean = false;
    isRegistrationClosed: boolean = false;
    registrationStartText: string;
    registrationEndText: string;
    registrationClosedText: string;
    beforeRegistrationCTALink: string;
    beforeRegistrationCTAText: string;
    duringRegistrationCTALink: string;
    duringRegistrationCTAText: string;
    promoFlashMessage: string;
    startTimeInUtc: string;
    endTimeInUtc: string;
    bannerData: any;
    name: string;
    parameters: any;
    templateName: any;
    htmlText: any;
    htmlTextUpdated: any;
    replacements: any = {};
    ribbonStatusClass: string;
    htmlStyles: any;
    hideDays: boolean;

    constructor(private parseRibbonMarkupPipe: ParseRibbonMarkupPipe) {}

    ngOnChanges() {
        this.name = this.singlePromoTimerRibbonData?.name;
        this.templateName = this.singlePromoTimerRibbonData?.templateName;
        this.htmlText = this.singlePromoTimerRibbonData?.text;
        this.parameters = this.singlePromoTimerRibbonData?.parameters;
        this.startTimeInUtc = this.parameters?.startTimeInUtc;
        this.endTimeInUtc = this.parameters?.endTimeInUtc;
        this.registrationStartText = this.parameters?.registrationStartText;
        this.registrationEndText = this.parameters?.registrationEndText;
        this.registrationClosedText = this.parameters?.registrationClosedText;
        this.bannerKickoffInHours = this.parameters?.bannerKickoffInHours;
        this.bannerKickoffInDays = this.parameters?.bannerKickoffInDays;
        this.beforeRegistrationCTALink = this.parameters?.beforeRegistrationCTALink?.url;
        this.beforeRegistrationCTAText = this.parameters?.beforeRegistrationCTAText;
        this.duringRegistrationCTALink = this.parameters?.duringRegistrationCTALink?.url;
        this.duringRegistrationCTAText = this.parameters?.duringRegistrationCTAText;
        this.promoFlashMessage = this.parameters?.promoFlashMessage;
        this.htmlStyles = this.singlePromoTimerStyles?.text;

        this.refresh();
        this.callback = setInterval(() => {
            this.refresh();
        }, 1000);
    }

    private refresh() {
        this.startTime = new Date(this.startTimeInUtc);
        this.endTime = new Date(this.endTimeInUtc);
        this.currentTimeInUTC = new Date().toUTCString();
        this.currentTime = new Date(this.currentTimeInUTC);

        if (this.bannerKickoffInHours > 0 && this.bannerKickoffInHours != null) {
            const bannerKickOffTime = new Date(this.startTime);
            this.bannerDisplayTime = bannerKickOffTime.setHours(bannerKickOffTime.getHours() - this.bannerKickoffInHours);
        } else {
            const bannerKickOffTime = new Date(this.startTime);
            this.bannerDisplayTime = bannerKickOffTime.setDate(bannerKickOffTime.getDate() - this.bannerKickoffInDays);
        }

        this.millisecondsleft = this.startTime - this.currentTime;
        this.regEndMilliSecondsLeft = this.endTime - this.currentTime;

        if (this.currentTime > this.bannerDisplayTime && this.millisecondsleft > 0) {
            if (this.millisecondsleft > 0) {
                this.isRegistrationClosed = false;
                this.isBannerEnabled = true;
                this.isRegistrationStarted = false;
                this.ribbonStatusClass = 'banner-enabled';
                this.days = Math.floor(this.millisecondsleft / 86400000);
                this.hours = Math.floor((this.millisecondsleft / 3600000) % 24);
                this.minutes = Math.floor((this.millisecondsleft / (1000 * 60)) % 60);
                this.seconds = Math.floor((this.millisecondsleft / 1000) % 60);
                this.formatMessage(this.days, this.hours, this.minutes, this.seconds);
                this.timerStatus.emit(true);
            } else {
                this.timeLeft = '';
                this.ribbonStatusClass = 'registration-closed';
                this.isBannerEnabled = false;
                this.isRegistrationStarted = false;
                this.isRegistrationClosed = true;
                this.timerStatus.emit(false);
            }
        } else if (this.currentTime > this.startTime && this.regEndMilliSecondsLeft > 0) {
            if (this.regEndMilliSecondsLeft > 0) {
                this.isRegistrationClosed = false;
                this.isBannerEnabled = false;
                this.isRegistrationStarted = true;
                this.ribbonStatusClass = 'registration-started';
                this.days = Math.floor(this.regEndMilliSecondsLeft / 86400000);
                this.hours = Math.floor((this.regEndMilliSecondsLeft / 3600000) % 24);
                this.minutes = Math.floor((this.regEndMilliSecondsLeft / (1000 * 60)) % 60);
                this.seconds = Math.floor((this.regEndMilliSecondsLeft / 1000) % 60);
                this.formatMessage(this.days, this.hours, this.minutes, this.seconds);
                this.timerStatus.emit(true);
            } else {
                this.timeLeft = '';
                this.ribbonStatusClass = 'registration-closed';
                this.isBannerEnabled = false;
                this.isRegistrationStarted = false;
                this.isRegistrationClosed = true;
                this.timerStatus.emit(false);
            }
        } else {
            this.timerStatus.emit(false);
        }
    }

    private formatMessage(days: number, hours: number, minutes: number, seconds: number) {
        this.replacements['DAYS'] = days;
        this.replacements['HOURS'] = hours;
        this.replacements['MINUTES'] = minutes;
        this.replacements['SECONDS'] = seconds;
        this.replacements['PROMOFLASHMESSAGE'] = this.parameters.promoFlashMessage;

        this.htmlTextUpdated = this.parseRibbonMarkupPipe.transform(this.htmlText, this.replacements);

        if (days <= 0) {
            this.hideDays = false;
        }
    }
}
