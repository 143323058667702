import { Message } from '../../message';

export class RequestTableForMTCTParticipant extends Message {
    private MESSAGE_NAME: string = 'RequestTableForMTCTParticipant';
    private mtctId: number = 0;
    private screenName: string | null = null;
    private tournamentCategory: number = 0;
    private participantNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        screenName: string | null = null,
        tournamentCategory: number = 0,
        participantNo: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.screenName = screenName;
        this.tournamentCategory = tournamentCategory;
        this.participantNo = participantNo;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
}
