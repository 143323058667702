import { Message } from '../../message';

export class FRONTALJoinPoolResponse extends Message {
    private MESSAGE_NAME: string = 'FRONTALJoinPoolResponse';
    private poolName: string | null = null;
    private balanceInfoMap: Map<any, any> | null = null;
    private minBuyIn: number = 0;
    private maxBuyIn: number = 0;
    private responseId: number = 0;
    private time: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        poolName: string | null = null,
        balanceInfoMap: Map<any, any> | null = null,
        minBuyIn: number = 0,
        maxBuyIn: number = 0,
        responseId: number = 0,
        time: number = 0,
        conversationId: number = 0,
    ) {
        super(reqServerPeerId);
        this.poolName = poolName;
        this.balanceInfoMap = balanceInfoMap;
        this.minBuyIn = minBuyIn;
        this.maxBuyIn = maxBuyIn;
        this.responseId = responseId;
        this.time = time;
        this.conversationId = conversationId;
    }

    getPoolName(): string | null {
        return this.poolName;
    }

    setPoolName(poolName: string | null) {
        this.poolName = poolName;
    }
    getBalanceInfoMap(): Map<any, any> | null {
        return this.balanceInfoMap;
    }

    setBalanceInfoMap(balanceInfoMap: Map<any, any> | null) {
        this.balanceInfoMap = balanceInfoMap;
    }
    getMinBuyIn(): number {
        return this.minBuyIn;
    }

    setMinBuyIn(minBuyIn: number) {
        this.minBuyIn = minBuyIn;
    }
    getMaxBuyIn(): number {
        return this.maxBuyIn;
    }

    setMaxBuyIn(maxBuyIn: number) {
        this.maxBuyIn = maxBuyIn;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getTime(): number {
        return this.time;
    }

    setTime(time: number) {
        this.time = time;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
