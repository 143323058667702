import { Message } from '../../message';

export class QSResult extends Message {
    private MESSAGE_NAME: string = 'QSResult';
    private rank: number = 0;
    private tableId: number = 0;
    private tableInfoId: number = 0;
    private peerId: number = 0;
    private isExact: boolean | null = false;
    private waitListStatus: number = 0;
    private errorCode: number = 0;
    private fuzzyParams: Map<any, any> | null = null;
    private seatNo: number = 0;
    private blockSeatExpiresAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        rank: number = 0,
        tableId: number = 0,
        tableInfoId: number = 0,
        peerId: number = 0,
        isExact: boolean | null = false,
        waitListStatus: number = 0,
        errorCode: number = 0,
        fuzzyParams: Map<any, any> | null = null,
        seatNo: number = 0,
        blockSeatExpiresAt: number = 0,
    ) {
        super(reqServerPeerId);
        this.rank = rank;
        this.tableId = tableId;
        this.tableInfoId = tableInfoId;
        this.peerId = peerId;
        this.isExact = isExact;
        this.waitListStatus = waitListStatus;
        this.errorCode = errorCode;
        this.fuzzyParams = fuzzyParams;
        this.seatNo = seatNo;
        this.blockSeatExpiresAt = blockSeatExpiresAt;
    }

    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getPeerId(): number {
        return this.peerId;
    }

    setPeerId(peerId: number) {
        this.peerId = peerId;
    }
    getIsExact(): boolean | null {
        return this.isExact;
    }

    setIsExact(isExact: boolean | null) {
        this.isExact = isExact;
    }
    getWaitListStatus(): number {
        return this.waitListStatus;
    }

    setWaitListStatus(waitListStatus: number) {
        this.waitListStatus = waitListStatus;
    }
    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
    getFuzzyParams(): Map<any, any> | null {
        return this.fuzzyParams;
    }

    setFuzzyParams(fuzzyParams: Map<any, any> | null) {
        this.fuzzyParams = fuzzyParams;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getBlockSeatExpiresAt(): number {
        return this.blockSeatExpiresAt;
    }

    setBlockSeatExpiresAt(blockSeatExpiresAt: number) {
        this.blockSeatExpiresAt = blockSeatExpiresAt;
    }
}
