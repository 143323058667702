import { Message } from '../../message';
import { FXConversionFactor } from '../../pgSharedMessageFactory/messages/FXConversionFactor';

export class BlockSeatResponse extends Message {
    private MESSAGE_NAME: string = 'BlockSeatResponse';
    private userBalance: number = 0;
    private userBalanceGC: number = 0;
    private validFreeRolls: number = 0;
    private blockSeatStatus: number = 0;
    private blockedSeatTimeOutAt: number = 0;
    private fppBuyIn: number = 0;
    private fppAvailable: number = 0;
    private levelChangeInfo: any | null = null;
    private messageToClient: any | null = null;
    private fXConversionFactor: FXConversionFactor | null = null;
    private newMcPlayer: boolean | null = false;
    private seatNumber: number = 0;
    private conversationId: number = 0;
    private responseId: number = 0;
    private gDollarBalanceGC: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        userBalance: number = 0,
        userBalanceGC: number = 0,
        validFreeRolls: number = 0,
        blockSeatStatus: number = 0,
        blockedSeatTimeOutAt: number = 0,
        fppBuyIn: number = 0,
        fppAvailable: number = 0,
        levelChangeInfo: any | null = null,
        messageToClient: any | null = null,
        fXConversionFactor: FXConversionFactor | null = null,
        newMcPlayer: boolean | null = false,
        seatNumber: number = 0,
        conversationId: number = 0,
        responseId: number = 0,
        gDollarBalanceGC: number = 0,
    ) {
        super(reqServerPeerId);
        this.userBalance = userBalance;
        this.userBalanceGC = userBalanceGC;
        this.validFreeRolls = validFreeRolls;
        this.blockSeatStatus = blockSeatStatus;
        this.blockedSeatTimeOutAt = blockedSeatTimeOutAt;
        this.fppBuyIn = fppBuyIn;
        this.fppAvailable = fppAvailable;
        this.levelChangeInfo = levelChangeInfo;
        this.messageToClient = messageToClient;
        this.fXConversionFactor = fXConversionFactor;
        this.newMcPlayer = newMcPlayer;
        this.seatNumber = seatNumber;
        this.conversationId = conversationId;
        this.responseId = responseId;
        this.gDollarBalanceGC = gDollarBalanceGC;
    }

    getUserBalance(): number {
        return this.userBalance;
    }

    setUserBalance(userBalance: number) {
        this.userBalance = userBalance;
    }
    getUserBalanceGC(): number {
        return this.userBalanceGC;
    }

    setUserBalanceGC(userBalanceGC: number) {
        this.userBalanceGC = userBalanceGC;
    }
    getValidFreeRolls(): number {
        return this.validFreeRolls;
    }

    setValidFreeRolls(validFreeRolls: number) {
        this.validFreeRolls = validFreeRolls;
    }
    getBlockSeatStatus(): number {
        return this.blockSeatStatus;
    }

    setBlockSeatStatus(blockSeatStatus: number) {
        this.blockSeatStatus = blockSeatStatus;
    }
    getBlockedSeatTimeOutAt(): number {
        return this.blockedSeatTimeOutAt;
    }

    setBlockedSeatTimeOutAt(blockedSeatTimeOutAt: number) {
        this.blockedSeatTimeOutAt = blockedSeatTimeOutAt;
    }
    getFppBuyIn(): number {
        return this.fppBuyIn;
    }

    setFppBuyIn(fppBuyIn: number) {
        this.fppBuyIn = fppBuyIn;
    }
    getFppAvailable(): number {
        return this.fppAvailable;
    }

    setFppAvailable(fppAvailable: number) {
        this.fppAvailable = fppAvailable;
    }
    getLevelChangeInfo(): any | null {
        return this.levelChangeInfo;
    }

    setLevelChangeInfo(levelChangeInfo: any | null) {
        this.levelChangeInfo = levelChangeInfo;
    }
    getMessageToClient(): any | null {
        return this.messageToClient;
    }

    setMessageToClient(messageToClient: any | null) {
        this.messageToClient = messageToClient;
    }
    getFXConversionFactor(): FXConversionFactor | null {
        return this.fXConversionFactor;
    }

    setFXConversionFactor(fXConversionFactor: FXConversionFactor | null) {
        this.fXConversionFactor = fXConversionFactor;
    }
    getNewMcPlayer(): boolean | null {
        return this.newMcPlayer;
    }

    setNewMcPlayer(newMcPlayer: boolean | null) {
        this.newMcPlayer = newMcPlayer;
    }
    getSeatNumber(): number {
        return this.seatNumber;
    }

    setSeatNumber(seatNumber: number) {
        this.seatNumber = seatNumber;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getGDollarBalanceGC(): number {
        return this.gDollarBalanceGC;
    }

    setGDollarBalanceGC(gDollarBalanceGC: number) {
        this.gDollarBalanceGC = gDollarBalanceGC;
    }
}
