import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class DealingSixthStreet extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.DEALING_SIXTH_STREET;
    }
    getPlayerStatus(): any {
        return PlayerStatus.DEALING_SIXTH_STREET;
    }
    next(gameTable: GameTable): void {
        gameTable.presentBettingRound = '3';
        gameTable.currentBettingRound = 5;
        const potPosition = Properties.propFile[gameTable.gameType]['MAINPOTCHIPPOS'].split('#');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTable.playersMap.forEach((player: any, seat: any) => {
            gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
        });
        gameTable.mpot = false;
        const mpot = gameTable.moveToPot();
        if (!gameTable.onInit) {
            gameTable.accumulateChip(this.potCreated, true);
        }

        const eventMap = gameTable.eventMap;
        let playerMap = eventMap.get(this.getPlayerStatus());
        if (!playerMap) {
            playerMap = new Map<string, Chip>();
            eventMap.set(this.getPlayerStatus(), playerMap);
        }

        gameTable.players.forEach((p: any) => {
            if (playerMap) playerMap.set(p.name, p.toPot);
        });
        const extraChip = this.getPlayerExtraChips(gameTable, playerMap);
        console.log(extraChip);

        if (gameTable.mainPotFreezed) {
            gameTable.MAIN_POT.totalValue = gameTable.mainPotLockedValue; //gameTable.MAIN_POT.totalValue;
        } else {
            gameTable.MAIN_POT.totalValue = gameTable.ROUND_POT.totalValue;
        }

        gameTable.roundObjects['3'] = gameTable.MAIN_POT.totalValue;

        if (gameTable.lastLockedPotAmount > 0) {
            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 2].activePotNumber - 1].totalValue =
                gameTable.lastLockedPotAmount;
        }

        if (mpot && !gameTable.onInit) {
            gameTable.enableTableMask = true;
            setTimeout(() => {
                gameTable.playersMap.forEach((player: any) => {
                    player.toPot = new Chip(0);
                });
                gameTable.accumulateChip(this.potCreated, false);
                gameTable.enableTableMask = false;
                if (gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]) {
                    if (
                        gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].totalLockedPots === 0 &&
                        gameTable.SIDE_POTS.length > 0 &&
                        gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                    ) {
                        gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                    }
                }
                gameTable.SIDE_POTS.forEach((pot) => {
                    if (pot.totalValue > 0) {
                        if (
                            gameTable.valueUpdatedPotId >= 0 &&
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId] &&
                            gameTable.potCreatedObject.hasOwnProperty(gameTable.currentBettingRound - 1)
                        ) {
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId].totalValue =
                                gameTable.potCreatedObject[gameTable.currentBettingRound - 1];
                        }
                        pot.showPot = true;
                    }
                });
            }, gameTable.gameSpeedBarSetter);
        } else {
            gameTable.playersMap.forEach((player: any) => {
                player.toPot = new Chip(0);
            });
        }
        gameTable.lastLockedPotAmount = 0;
    }

    prev(gameTable: GameTable): void {
        gameTable.mpot = false;
        gameTable.currentBettingRound = 4;
        gameTable.lastLockedPotAmount = 0;
        this.getPlrReverseExtraChips(gameTable);

        const currentBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber;
        const prevBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]
            ? gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber
            : 0;

        for (let i = currentBettingRoundActivePotNumber; i > prevBettingRoundActivePotNumber; i--) {
            gameTable.SIDE_POTS[i - 1].showPot = false;
        }

        if (
            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
            gameTable.SIDE_POTS.length > 0 &&
            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
        ) {
            gameTable.lastLockedPotAmount =
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue;
            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
        }

        if (gameTable.gameRoundDetails[gameTable.currentBettingRound]) {
            if (
                gameTable.gameRoundDetails[gameTable.currentBettingRound].totalLockedPots === 0 &&
                gameTable.SIDE_POTS.length > 0 &&
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
            ) {
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber - 1].totalValue =
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
            }
        }
        gameTable.playersMap.forEach((player: any, seat: any) => {
            const potPosition = Properties.propFile[gameTable.gameType][`CHIPPOS${seat}`].split('#');
            player.playerChipPos = new Point(potPosition[0], potPosition[1]);
            gameTable.revertChip(this.potCreated);
            const event = gameTable.eventMap;
            const t = event.get(this.getPlayerStatus()) ?? new Map();
            const f = t.get(player.name);
            player.toPot.value = f.value;
        });
        const totalchipvalue = gameTable.roundObjects;
        for (let i = 2; i >= 0; i--) {
            if (gameTable.roundObjects.hasOwnProperty(i)) {
                gameTable.MAIN_POT.totalValue = totalchipvalue[i];
                gameTable.changeChip = true;
                break;
            } else {
                gameTable.MAIN_POT.totalValue = 0;
                gameTable.changeChip = false;
            }
        }
    }
}
