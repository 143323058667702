import { Message } from '../../message';

export class EndTheRound extends Message {
    private MESSAGE_NAME: string = 'EndTheRound';
    private roundCount: number = 0;
    private currentPotAmount: number = 0;
    private sidePots: any[] | null = null;
    private rake: number = 0;
    private jackpotRake: number = 0;
    private uncalledBetAmount: number = 0;
    private uncalledBetPlrSeatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        roundCount: number = 0,
        currentPotAmount: number = 0,
        sidePots: any[] | null = null,
        rake: number = 0,
        jackpotRake: number = 0,
        uncalledBetAmount: number = 0,
        uncalledBetPlrSeatNo: number = 0,
    ) {
        super(reqServerPeerId);
        this.roundCount = roundCount;
        this.currentPotAmount = currentPotAmount;
        this.sidePots = sidePots;
        this.rake = rake;
        this.jackpotRake = jackpotRake;
        this.uncalledBetAmount = uncalledBetAmount;
        this.uncalledBetPlrSeatNo = uncalledBetPlrSeatNo;
    }

    getRoundCount(): number {
        return this.roundCount;
    }

    setRoundCount(roundCount: number) {
        this.roundCount = roundCount;
    }
    getCurrentPotAmount(): number {
        return this.currentPotAmount;
    }

    setCurrentPotAmount(currentPotAmount: number) {
        this.currentPotAmount = currentPotAmount;
    }
    getSidePots(): any[] | null {
        return this.sidePots;
    }

    setSidePots(sidePots: any[] | null) {
        this.sidePots = sidePots;
    }
    getRake(): number {
        return this.rake;
    }

    setRake(rake: number) {
        this.rake = rake;
    }
    getJackpotRake(): number {
        return this.jackpotRake;
    }

    setJackpotRake(jackpotRake: number) {
        this.jackpotRake = jackpotRake;
    }
    getUncalledBetAmount(): number {
        return this.uncalledBetAmount;
    }

    setUncalledBetAmount(uncalledBetAmount: number) {
        this.uncalledBetAmount = uncalledBetAmount;
    }
    getUncalledBetPlrSeatNo(): number {
        return this.uncalledBetPlrSeatNo;
    }

    setUncalledBetPlrSeatNo(uncalledBetPlrSeatNo: number) {
        this.uncalledBetPlrSeatNo = uncalledBetPlrSeatNo;
    }
}
