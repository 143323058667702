import { Message } from '../../message';

export class LSPoolInfoDetailsDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSPoolInfoDetailsDeltaUpdate';
    private snapShotTimeInNanos: number = 0;
    private poolInfos: any[] | null = null;
    private deletedPoolInfos: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, snapShotTimeInNanos: number = 0, poolInfos: any[] | null = null, deletedPoolInfos: any[] | null = null) {
        super(reqServerPeerId);
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.poolInfos = poolInfos;
        this.deletedPoolInfos = deletedPoolInfos;
    }

    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getPoolInfos(): any[] | null {
        return this.poolInfos;
    }

    setPoolInfos(poolInfos: any[] | null) {
        this.poolInfos = poolInfos;
    }
    getDeletedPoolInfos(): any[] | null {
        return this.deletedPoolInfos;
    }

    setDeletedPoolInfos(deletedPoolInfos: any[] | null) {
        this.deletedPoolInfos = deletedPoolInfos;
    }
}
