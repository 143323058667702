import { CommonModule } from '@angular/common';
import { Component, HostBinding, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { SocialComponentClientConfig } from '@pokercore/module/core';
import * as _ from 'lodash-es';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sv';
import { NgxSliderModule } from 'ngx-slider-v2';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CalendarCountdownTimerComponent } from '../calendar-countdown-timer/calendar-countdown-timer.component';
import { formatCurrencyPipe } from './formatCurrency.pipe';
import { formatDecimalPipe } from './formatDecimal.pipe';
import { PokerScheduleFeedFiltersComponent } from './poker-schedule-feed-filters.component';
import { PokerScheduleFeedService } from './poker-schedule-feed.service';
import { toCurrencyPipe } from './toCurrency.pipe';

@Component({
    selector: 'poker-schedule-feed',
    templateUrl: 'poker-schedule-feed.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSliderModule,
        CalendarCountdownTimerComponent,
        PokerScheduleFeedFiltersComponent,
        MatTooltipModule,
        formatCurrencyPipe,
        toCurrencyPipe,
        formatDecimalPipe,
    ],
})
export class PokerScheduleFeedComponent extends PCComponent implements OnInit {
    scheduleFeedItems: any;
    ctaLink: any;
    displayedColumns: string[];
    dataSource = new MatTableDataSource<any>();
    contentIivtDataSource: boolean;
    tableHeadings: any;
    hoursDiffer: any;
    previousValue: number;
    searchValue: Subject<string> = new Subject<string>();
    filterSubscription: Subscription;
    showFilters: boolean;
    configStrings: any;
    searchFilterValue: any;
    isStaticData: boolean;
    filterStatus: boolean = true;
    addToCalendarGroup: any;
    addToCalActiveEle: any;
    userLanguage: any;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    constructor(
        private socialComponentClientConfig: SocialComponentClientConfig,
        public zone: NgZone,
        private schedService: PokerScheduleFeedService,
        private page: Page,
    ) {
        super();
        this.applySearchFilterDo();
    }

    ngOnInit() {
        this.schedService.sendInitData(this.item);
        this.ctaLink = this.item.scheduleFeedData.scheduleStaticData.ctaLink;
        this.tableHeadings = this.item.scheduleFeedData.scheduleStaticData;
        this.scheduleFeedItems = this.item.scheduleFeedData.tournamentsFeedData
            ? this.item.scheduleFeedData.tournamentsFeedData
            : this.item.scheduleFeedData.scheduleData;
        this.item.scheduleFeedData.scheduleData ? (this.isStaticData = true) : (this.isStaticData = false);
        this.configStrings = this.item.scheduleFeedData.scheduleStaticData.tournamentFilters;
        this.addToCalendarGroup = this.item.scheduleFeedData.scheduleStaticData.addToCalendarGroup;
        this.showFilters = this.item.showFilters;
        this.getTableColumns();

        // this.scheduleFeedItems.forEach((t:any) => {
        //     t.isClose = false;
        //     t.isHidden = true;
        // });

        // Remove item which contains value "Restricted" in byin
        _.remove(this.scheduleFeedItems, function (feedItem: any) {
            return feedItem.buyIn == 'Restricted';
        });
        // Remove item which contains "durationLateRegClose = 0"
        _.remove(this.scheduleFeedItems, function (feedItem: any) {
            return feedItem.status.toLowerCase() == 'late registration' && feedItem.durationLateRegClose == 0;
        });

        this.dataSource = new MatTableDataSource(this.scheduleFeedItems);

        this.addMatTranslations();
        this.contentIivtDataSource = this.item.scheduleFeedData.tournamentsFeedData ? true : false;

        this.hoursDiffer = this.socialComponentClientConfig.addHoursToTournamentTime;
        this.addTimeZoneHours();

        if (this.isStaticData && this.scheduleFeedItems.length != 0) {
            this.scheduleFeedItems.map((item: any) => {
                item.buyIn.unit = Number((item.buyIn.unit / 100).toFixed(2));
                item.fee.unit = Number((item.fee.unit / 100).toFixed(2));
                item.prizePool.unit = Number((item.prizePool.unit / 100).toFixed(2));
            });
        }

        // Search predict
        this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
            return data.name.toLowerCase().includes(filter) || data.id.toString() === filter;
        };

        this.updateLateRegFeed();
        setInterval(() => {
            this.updateLateRegFeed();
        }, 400);
        this.subscribeToFilters();

        this.checkIdleState();
    }

    addMatTranslations() {
        const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
            const numberOf = this.configStrings?.pagination?.of || 'of';
            if (length == 0 || pageSize == 0) {
                return `0 ${numberOf} ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;

            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} ${numberOf} ${length}`;
        };

        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = this.configStrings?.pagination?.itemsPerPage || 'Items per page:';
        this.paginator._intl.nextPageLabel = this.configStrings?.pagination?.nextPage || 'Next Page';
        this.paginator._intl.lastPageLabel = this.configStrings?.pagination?.lastPage || 'Last Page';
        this.paginator._intl.getRangeLabel = dutchRangeLabel;
        this.paginator._intl.previousPageLabel = this.configStrings?.pagination?.previousPage || 'Previous Page';
        this.paginator._intl.firstPageLabel = this.configStrings?.pagination?.firstPage || 'First Page';
    }

    subscribeToFilters() {
        this.filterSubscription = this.schedService.getFilteredTournamentsData.subscribe((filteredList) => {
            if (filteredList != null) {
                this.dataSource.data = filteredList;
                //this.updateLateRegFeed();
                this.paginator.pageIndex = 0;
                this.dataSource.paginator = this.paginator;
            }
        });
    }

    updateLateRegFeed() {
        this.zone.run(() => {
            this.dataSource.data.forEach((item: any) => {
                if (item.lateData && item.durationLateRegClose != 0) {
                    const currentTime: any = new Date();
                    const remainingTime = item.lateData - currentTime;
                    if (remainingTime < 0) {
                        this.dataSource.data.splice(this.dataSource.data.indexOf(item), 1);
                        this.dataSource._updateChangeSubscription();
                    }
                    if (item.durationLateRegClose == '0') {
                        this.dataSource.data.splice(this.dataSource.data.indexOf(item), 1);
                        this.dataSource._updateChangeSubscription();
                    }
                }
            });
        });
    }

    getTableColumns() {
        const columnsPrep: string[] = [];
        _.map(this.item.tableColumns, function (item: any) {
            item = _.startCase(item);
            item = item.charAt(0).toLowerCase() + item.slice(1);
            item = item.replace(/\s/g, '');
            columnsPrep.push(item);
        });
        this.displayedColumns = columnsPrep;
    }

    // Add additional hours from timeZone
    addTimeZoneHours() {
        this.scheduleFeedItems.map((item: any) => {
            const itemDate = item.startDate.slice(0, -1);
            const dateMoment = moment(itemDate);
            const newDate = dateMoment.add(this.hoursDiffer, 'hours').format('YYYY-MM-DDTHH:mm:ss');
            item.startDate = new Date(newDate);

            item.lateData = null;
            if (item.status.toLowerCase() == 'late registration') {
                let dateHolder = item.startDate;
                if (item.durationLateRegClose != 0) {
                    const lateDuration = item.durationLateRegClose.split(':');
                    dateHolder = moment(dateHolder).add({ hours: lateDuration[0], minutes: lateDuration[1], seconds: lateDuration[2] });
                    item.lateData = new Date(dateHolder);
                }
            }
        });
    }

    // register click method
    openClient(tournament: any) {
        window.open(this.ctaLink.replace('{{id}}', tournament.id), '_blank');
    }

    toggleAddToCal(event: any, item: any) {
        item.isClose = item.isClose != true;

        const target = event.target || event.srcElement || event.currentTarget;
        const idAttr = target.attributes.id;
        const value = Number(idAttr.nodeValue);

        this.addToCalActiveEle == value ? (this.addToCalActiveEle = -1) : (this.addToCalActiveEle = value);
    }

    // Create Calendar Event
    calenderClicked(host: any, tourney: any) {
        let segmentUrl = host.segmentUrl;
        const tourneyDate = moment(tourney.startDate);
        tourney.name = tourney.name.replace(/#/g, '%23');
        segmentUrl = segmentUrl.replace('{{tourneyName}}', tourney.name);
        segmentUrl = segmentUrl.replace('{{tourneyStartDate}}', tourneyDate.format(host.dateTimeFormat));
        segmentUrl = segmentUrl.replace('{{tourneyEndDate}}', tourneyDate.add(1, 'hours').format(host.dateTimeFormat));
        segmentUrl = segmentUrl.replace('{{labelName}}', this.tableHeadings.labelName);
        window.open(host.hostUrl + segmentUrl, '_blank');
    }

    toggleFilter() {
        this.filterStatus = !this.filterStatus;
    }

    dateTimeCEST(date: any) {
        this.userLanguage = this.page.lang;
        if (this.userLanguage) {
            return moment(date).locale(this.userLanguage).format('ddd DD MMM HH:mm');
        }
        return moment(date).format('ddd DD MMM HH:mm');
    }

    // Filter by search
    applySearchFilterDo() {
        this.searchValue.pipe(debounceTime(300)).subscribe((searchModel: any) => {
            searchModel = searchModel.trim();
            searchModel = searchModel.toLowerCase(); // Datasource defaults to lowercase matches
            this.dataSource.filter = searchModel;
            if (searchModel.length != 0 && this.dataSource.filteredData.length != 0) {
                this.schedService.sendSearchFilterData(this.dataSource.filteredData);
            } else {
                this.schedService.sendSearchFilterData(null);
            }
        });
    }
    applySearchFilter(sval: string) {
        this.searchValue.next(sval);
    }

    // Clear Search Input Value
    clearSearchInput() {
        this.searchFilterValue = '';
        this.applySearchFilter('');
    }

    getEmitReset(res: boolean) {
        if (res) {
            this.clearSearchInput();
        }
    }

    checkIdleState() {
        // eslint-disable-next-line no-console
        console.log('Reload happen.', new Date());
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        timer(1000 * 60 * 20).subscribe((value: any) => {
            window.location.reload();
        });
    }

    ngOnDestroy() {
        this.filterSubscription.unsubscribe();
    }
}
