import { Card } from './card';
import { Chip } from './chip';
import { PlayerStatus } from './player-status';
import { Point } from './point';

export class Player {
    public _name: string;
    private _cards: Card[];
    private _chips: any;
    private _seatNo: number;
    private _dealer: boolean;
    private _position: Point;
    private _toPot: any;
    private _playerStatus: PlayerStatus;
    public OptionSet: boolean = false;
    public displayCards: boolean = true;
    public event: boolean = false;
    public _PlayerChipPos: Point;
    private _isPlayerRequesting: any;
    public sevenCardStud: boolean = false;
    private _omaha: boolean = false;
    public _gtype: any;
    private _transformPosition: any;
    public _playerHolecardXpos: any = 29;
    public _playerHolecardYpos: any = 0;
    public currencyType: any;
    private _isTourny: boolean = false;
    public chipMap = new Map<any, Map<number, number>>();
    public _isPlayerNameTruncated: any;
    private _isPlayerAllIn: any;
    private _requestingPlayerHoleCards: any[];
    private _isSelfPlayerfolded: any;
    private _isPlayerCardsOpened: any;
    private _cashedOut: boolean = false;
    private _cashedOutAmount: number = 0;
    public isReduceChipshappened: boolean;

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-array-constructor
        this._cards = [];
        this._chips = new Chip(0);
        this._toPot = new Chip(0);
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get Omahagame(): any {
        return this._omaha;
    }

    set Omahagame(value: any) {
        this._omaha = value;
    }

    get cards(): Card[] {
        return this._cards;
    }

    set cards(value: Card[]) {
        this._cards = value;
    }

    get isTourny(): boolean {
        return this._isTourny;
    }

    set isTourny(value: boolean) {
        this._isTourny = value;
    }

    get isPlayerAllIn(): boolean {
        return this._isPlayerAllIn;
    }

    set isPlayerAllIn(value: boolean) {
        this._isPlayerAllIn = value;
    }

    get isSelfPlayerfolded(): any {
        return this._isSelfPlayerfolded;
    }

    set isSelfPlayerfolded(value: any) {
        this._isSelfPlayerfolded = value;
    }

    get isPlayerCardsOpened(): any {
        return this._isPlayerCardsOpened;
    }

    set isPlayerCardsOpened(value: any) {
        this._isPlayerCardsOpened = value;
    }

    get chips(): any {
        return this._chips;
    }

    set chips(value: any) {
        this._chips = value;
    }

    get transformPosition(): any {
        return this._transformPosition;
    }

    set transformPosition(value: any) {
        this._transformPosition = value;
    }

    get playerChipPos(): Point {
        return this._PlayerChipPos;
    }

    set playerChipPos(value: Point) {
        this._PlayerChipPos = value;
    }

    get seatNo(): number {
        return this._seatNo;
    }

    set seatNo(value: number) {
        this._seatNo = value;
    }

    get dealer(): boolean {
        return this._dealer;
    }

    set dealer(value: boolean) {
        this._dealer = value;
    }

    get position(): Point {
        return this._position;
    }

    set position(value: Point) {
        this._position = value;
    }

    get toPot(): any {
        return this._toPot;
    }

    set toPot(value: any) {
        this._toPot = value;
    }

    get playerStatus(): PlayerStatus {
        return this._playerStatus;
    }

    set playerStatus(value: PlayerStatus) {
        this._playerStatus = value;
    }
    get isPlayerRequesting(): any {
        return this._isPlayerRequesting;
    }

    set isPlayerRequesting(value: any) {
        this._isPlayerRequesting = value;
    }

    get isPlayerNameTruncated(): any {
        return this._isPlayerNameTruncated;
    }

    set isPlayerNameTruncated(value: any) {
        this._isPlayerNameTruncated = value;
    }

    get requestedPlayerHoleCards(): any {
        return this._requestingPlayerHoleCards;
    }

    set requestedPlayerHoleCards(value: any) {
        this._requestingPlayerHoleCards = value;
    }

    //PlayercashedOut

    get cashedOut(): boolean {
        return this._cashedOut;
    }

    set cashedOut(value: boolean) {
        this._cashedOut = value;
    }

    get cashedOutAmount(): number {
        return this._cashedOutAmount;
    }

    set cashedOutAmount(value: number) {
        this._cashedOutAmount = value;
    }

    //end cashedout

    getOptionSet(value: any) {
        this.OptionSet = value;
    }

    highlightEvent() {
        if (this._playerStatus) {
            this.event = true;
            setTimeout(() => {
                this.event = false;
            }, 400);
        } else {
            this.event = false;
        }
    }

    addToPot(amount: number) {
        const totalAmount = this._chips.value - amount;
        this._toPot = new Chip(Number(this._toPot.value) + Number(amount));
        this._chips = new Chip(totalAmount);
    }

    subtractFromPot(amount: number) {
        const totalAmount = Number(this._chips.value) + Number(amount);
        this._toPot = new Chip(Number(this._toPot.value) - Number(amount));
        this._chips = new Chip(totalAmount);
    }

    addHoleCard(card: Card) {
        this._cards.push(card);
        if (this.isPlayerRequesting === 'true') {
            this._cards.forEach((card) => {
                card.show = true;
            });
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeHoleCard(card: any) {
        this._cards.splice(-1);
    }
    addHoleCards(card: any) {
        this._cards = card;
        if (this.isPlayerRequesting) {
            this._cards.forEach((card) => {
                card.show = true;
            });
        }
    }

    addNextStreetCards(card: any) {
        const tempCards = this._cards.concat(card);
        this._cards = tempCards;
        if (this.isPlayerRequesting) {
            this._cards.forEach((card) => {
                card.show = true;
            });
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeNextStreetCards(card: any) {
        this._cards.splice(-1);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeHoleCards(card: any) {
        this._cards = [];
    }

    removeFoldCard(val: any) {
        if (this._playerStatus == 'Fold') {
            this.displayCards = val;
        }
    }
    hideSevenCardStudCards(i: any, r: any) {
        this._cards[i].show = r;
    }

    showHoleCards() {
        this._cards.forEach((card: any) => {
            card.show = true;
        });
    }
    hideHolecards() {
        this._cards.forEach((card: any) => {
            card.show = false;
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeCard(card: any) {
        this._cards.splice(-1);
    }
}
