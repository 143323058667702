import { Message } from '../../message';

export class ResponseSessionLimitsData extends Message {
    private MESSAGE_NAME: string = 'ResponseSessionLimitsData';
    private dailySessionLimit: number = 0;
    private monthlySessionLimit: number = 0;
    private weeklySessionLimit: number = 0;
    private dailyDepositLimit: number = 0;
    private monthlyDepositLimit: number = 0;
    private weeklyDepositLimit: number = 0;
    private maximumCreditBal: number = 0;
    private accountName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        dailySessionLimit: number = 0,
        monthlySessionLimit: number = 0,
        weeklySessionLimit: number = 0,
        dailyDepositLimit: number = 0,
        monthlyDepositLimit: number = 0,
        weeklyDepositLimit: number = 0,
        maximumCreditBal: number = 0,
        accountName: string | null = null,
    ) {
        super(reqServerPeerId);
        this.dailySessionLimit = dailySessionLimit;
        this.monthlySessionLimit = monthlySessionLimit;
        this.weeklySessionLimit = weeklySessionLimit;
        this.dailyDepositLimit = dailyDepositLimit;
        this.monthlyDepositLimit = monthlyDepositLimit;
        this.weeklyDepositLimit = weeklyDepositLimit;
        this.maximumCreditBal = maximumCreditBal;
        this.accountName = accountName;
    }

    getDailySessionLimit(): number {
        return this.dailySessionLimit;
    }

    setDailySessionLimit(dailySessionLimit: number) {
        this.dailySessionLimit = dailySessionLimit;
    }
    getMonthlySessionLimit(): number {
        return this.monthlySessionLimit;
    }

    setMonthlySessionLimit(monthlySessionLimit: number) {
        this.monthlySessionLimit = monthlySessionLimit;
    }
    getWeeklySessionLimit(): number {
        return this.weeklySessionLimit;
    }

    setWeeklySessionLimit(weeklySessionLimit: number) {
        this.weeklySessionLimit = weeklySessionLimit;
    }
    getDailyDepositLimit(): number {
        return this.dailyDepositLimit;
    }

    setDailyDepositLimit(dailyDepositLimit: number) {
        this.dailyDepositLimit = dailyDepositLimit;
    }
    getMonthlyDepositLimit(): number {
        return this.monthlyDepositLimit;
    }

    setMonthlyDepositLimit(monthlyDepositLimit: number) {
        this.monthlyDepositLimit = monthlyDepositLimit;
    }
    getWeeklyDepositLimit(): number {
        return this.weeklyDepositLimit;
    }

    setWeeklyDepositLimit(weeklyDepositLimit: number) {
        this.weeklyDepositLimit = weeklyDepositLimit;
    }
    getMaximumCreditBal(): number {
        return this.maximumCreditBal;
    }

    setMaximumCreditBal(maximumCreditBal: number) {
        this.maximumCreditBal = maximumCreditBal;
    }
    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
}
