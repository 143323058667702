import { Message } from '../../message';

export class PlayerCashoutEquityDetails extends Message {
    private MESSAGE_NAME: string = 'PlayerCashoutEquityDetails';
    private seatNo: number = 0;
    private equityPercentage: Map<any, any> | null = null;
    private equityValue: Map<any, any> | null = null;
    private allPotsTotEquityWin: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNo: number = 0,
        equityPercentage: Map<any, any> | null = null,
        equityValue: Map<any, any> | null = null,
        allPotsTotEquityWin: number = 0,
    ) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.equityPercentage = equityPercentage;
        this.equityValue = equityValue;
        this.allPotsTotEquityWin = allPotsTotEquityWin;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getEquityPercentage(): Map<any, any> | null {
        return this.equityPercentage;
    }

    setEquityPercentage(equityPercentage: Map<any, any> | null) {
        this.equityPercentage = equityPercentage;
    }
    getEquityValue(): Map<any, any> | null {
        return this.equityValue;
    }

    setEquityValue(equityValue: Map<any, any> | null) {
        this.equityValue = equityValue;
    }
    getAllPotsTotEquityWin(): number {
        return this.allPotsTotEquityWin;
    }

    setAllPotsTotEquityWin(allPotsTotEquityWin: number) {
        this.allPotsTotEquityWin = allPotsTotEquityWin;
    }
}
