import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PokerScheduleFeedService {
    private feedData = new BehaviorSubject<any>(null);
    private filterData = new BehaviorSubject<any>(null);
    private resetButtonStatus = new BehaviorSubject<any>(true);
    private searchFilterData = new BehaviorSubject<any>(null);

    scheduleFeedItems: any;
    isBuyInAvail: boolean;
    isStaticData: boolean;
    regexNum: any = /\d/g;

    getFilteredTournamentsData: Observable<Array<any>> = this.filterData.asObservable();
    disableReset: Observable<boolean> = this.resetButtonStatus.asObservable();

    constructor() {}

    sendInitData(initData: any) {
        this.scheduleFeedItems = initData.scheduleFeedData.tournamentsFeedData
            ? initData.scheduleFeedData.tournamentsFeedData
            : initData.scheduleFeedData.scheduleData;
        initData.scheduleFeedData.scheduleData ? (this.isStaticData = true) : (this.isStaticData = false);
        this.feedData.next(initData);
    }

    getInitData(): Observable<any> {
        return this.feedData.asObservable();
    }

    sendSearchFilterData(data: any) {
        this.searchFilterData.next(data);
    }

    getSearchFilterData(): Observable<any> {
        return this.searchFilterData.asObservable();
    }

    // Filter Calcuations
    checkBuyIn() {
        if (this.isStaticData) {
            this.isBuyInAvail = this.regexNum.test(this.scheduleFeedItems[0]?.buyIn.unit) ? true : false;
            return this.isBuyInAvail;
        } else {
            this.isBuyInAvail = this.regexNum.test(this.scheduleFeedItems[0]?.buyIn) ? true : false;
            return this.isBuyInAvail;
        }
    }

    getBuyinTotal(rawString: any) {
        const replaceString = rawString.replace(/[^0-9\.+]/g, '');
        const splitVal = replaceString.split('+');
        const finalVal = Number(splitVal[0]) + Number(splitVal[1]);
        return finalVal;
    }

    getNumbers(rawString: any) {
        return Number(rawString.replace(/[^0-9\.]/g, ''));
    }

    // Filter Data
    applyFilters(filterValues: any, schedData: any) {
        const MainDataHolder = schedData;
        let buyInFilteredData: any = [];
        let gameTypesFilteredData: any = [];
        let formatFilteredData: any = [];
        let speedCategoryFilteredData: any = [];
        let regFilteredData: any = [];
        const dateSlideFilteredData: any = [];
        let buyInSlideFilteredData: any = [];
        const guaranteeSlideFilteredData: any = [];
        let currencySlideFilterData: any = [];

        if (filterValues.buyInValue.length != 0) {
            schedData.filter((item: any) => {
                filterValues.buyInValue.forEach((filterBuyIn: any) => {
                    if (this.isStaticData) {
                        if (
                            item.buyIn.unit + item.fee.unit >= Number(filterBuyIn?.min) &&
                            item.buyIn.unit + item.fee.unit <= (filterBuyIn?.max !== undefined ? Number(filterBuyIn?.max) : Number.MAX_SAFE_INTEGER)
                        ) {
                            buyInFilteredData.push(item);
                        }
                    } else {
                        if (
                            this.getBuyinTotal(item['buyIn']) >= Number(filterBuyIn?.min) &&
                            this.getBuyinTotal(item['buyIn']) <= (filterBuyIn?.max !== undefined ? Number(filterBuyIn?.max) : Number.MAX_SAFE_INTEGER)
                        ) {
                            buyInFilteredData.push(item);
                        }
                    }
                });
            });
            buyInFilteredData = buyInFilteredData.filter((item: any) => item !== undefined);
        } else buyInFilteredData = MainDataHolder;

        if (filterValues.gameTypes.length != 0) {
            buyInFilteredData.filter((item: any) => {
                filterValues.gameTypes.forEach((gameTypes: any) => {
                    if (item['gameType'].toLowerCase() == gameTypes.toLowerCase()) {
                        gameTypesFilteredData.push(item);
                    }
                });
            });
        } else gameTypesFilteredData = buyInFilteredData;

        if (filterValues.format.length != 0) {
            gameTypesFilteredData.filter((item: any) => {
                filterValues.format.forEach((format: any) => {
                    if (item['limitType'].toLowerCase() == format.toLowerCase()) {
                        formatFilteredData.push(item);
                    }
                });
            });
        } else formatFilteredData = gameTypesFilteredData;

        if (filterValues.speedCategory.length != 0) {
            formatFilteredData.filter((item: any) => {
                filterValues.speedCategory.forEach((speedCate: any) => {
                    if (item['name'].toLowerCase().search(speedCate.toLowerCase()) > -1) {
                        speedCategoryFilteredData.push(item);
                    }
                });
            });
        } else speedCategoryFilteredData = formatFilteredData;

        if (filterValues.registrationValue.length != 0) {
            speedCategoryFilteredData.filter((item: any) => {
                filterValues.registrationValue.forEach((registrationValue: any) => {
                    if (item['status'].toLowerCase().search(registrationValue.toLowerCase()) > -1) {
                        regFilteredData.push(item);
                    }
                });
            });
        } else regFilteredData = speedCategoryFilteredData;

        if (filterValues.currency.length != 0) {
            regFilteredData.filter((item: any) => {
                filterValues.currency.forEach((symbol: any) => {
                    if (item['buyIn'].indexOf(symbol) > -1) {
                        currencySlideFilterData.push(item);
                    }
                });
            });
        } else currencySlideFilterData = regFilteredData;

        if (filterValues.dateSlide.minDate || filterValues.dateSlide.maxDate) {
            currencySlideFilterData.filter((item: any) => {
                if (
                    Date.parse(item['startDate']) >= filterValues.dateSlide.minDate &&
                    Date.parse(item['startDate']) <= filterValues.dateSlide.maxDate
                ) {
                    dateSlideFilteredData.push(item);
                }
            });
        }

        if ((filterValues.buyInSlide.minBuy || filterValues.buyInSlide.maxBuy) && this.isBuyInAvail) {
            dateSlideFilteredData.filter((item: any) => {
                if (this.isStaticData) {
                    if (
                        item.buyIn.unit + item.fee.unit >= filterValues.buyInSlide.minBuy &&
                        item.buyIn.unit + item.fee.unit <= filterValues.buyInSlide.maxBuy
                    ) {
                        buyInSlideFilteredData.push(item);
                    }
                } else {
                    if (
                        (this.getBuyinTotal(item['buyIn']) >= filterValues.buyInSlide.minBuy &&
                            this.getBuyinTotal(item['buyIn']) <= filterValues.buyInSlide.maxBuy) ||
                        item['buyIn'].toLowerCase() == 'restricted'
                    ) {
                        buyInSlideFilteredData.push(item);
                    }
                }
            });
        } else buyInSlideFilteredData = dateSlideFilteredData;

        if (filterValues.guaranteeSlide.minGuarantee || filterValues.guaranteeSlide.maxGuarantee) {
            buyInSlideFilteredData.filter((item: any) => {
                if (this.isStaticData) {
                    if (
                        item.prizePool.unit >= filterValues.guaranteeSlide.minGuarantee &&
                        item.prizePool.unit <= filterValues.guaranteeSlide.maxGuarantee
                    ) {
                        guaranteeSlideFilteredData.push(item);
                    }
                } else {
                    if (
                        this.getNumbers(item['prizePool']) >= filterValues.guaranteeSlide.minGuarantee &&
                        this.getNumbers(item['prizePool']) <= filterValues.guaranteeSlide.maxGuarantee
                    ) {
                        guaranteeSlideFilteredData.push(item);
                    }
                }
            });
        }
        this.filterData.next(guaranteeSlideFilteredData);
        this.resetButtonStatus.next(guaranteeSlideFilteredData.length == schedData.length ? true : false);
    }
}
