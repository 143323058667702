import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

@Component({
    selector: 'homepage-howtoplay',
    templateUrl: 'homepage-howtoplay.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class HomePageHowtoplayComponent implements OnInit {
    howtoplayContentContent: any;
    //  mobileContentContent: any;
    howtoplayContentEnabled: boolean;
    allData: any;

    constructor(private homePageService: HomepageResourceService) {}
    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            if (allData[i].metadata.id.itemName.toLowerCase() === 'howtoplay') {
                this.howtoplayContentContent = allData[i];
                this.howtoplayContentEnabled = this.homePageService.setContentFromID(allData[i].metadata.id.itemName.toLowerCase());
                break;
            }
        }
    }
}
