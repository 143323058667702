import add from './add.js';
import ceil from './ceil.js';
import divide from './divide.js';
import floor from './floor.js';
import max from './max.js';
import maxBy from './maxBy.js';
import mean from './mean.js';
import meanBy from './meanBy.js';
import min from './min.js';
import minBy from './minBy.js';
import multiply from './multiply.js';
import round from './round.js';
import subtract from './subtract.js';
import sum from './sum.js';
import sumBy from './sumBy.js';
export default {
  add,
  ceil,
  divide,
  floor,
  max,
  maxBy,
  mean,
  meanBy,
  min,
  minBy,
  multiply,
  round,
  subtract,
  sum,
  sumBy
};