import serverIdConstants from '../game-config/serverIdConstants.json';

export const controlPanelConfig = {
    /*    "ControlPanelUIDataDesktop": {
          "ControlPanelUIDataMLandscape": {
          },
      */
    ControlPanelUIDataMPortrait: {
        buttons: {
            buttonsList: ['call', 'fold', 'raise', 'slidebet', 'check', 'bet', 'allinAct', 'cashout', 'resumehand'],
            autoButtonsList: ['autoFold', 'autoCheck', 'autoFC'],
            potButtonsList: ['oneTwoPot', 'oneThreePot', 'twoThreePot'],
            showButtonsList: ['show', 'muck', 'dontshow'],
            showLabel: 'Show',
            muckLabel: 'Muck',
            dontshowLabel: 'DontShow',
            showPos: {
                x: 197,
                y: 1019,
            },
            muckPos: {
                x: 525,
                y: 1019,
            },
            dontshowPos: {
                x: 525,
                y: 1019,
            },
            // "callPos": { "x": 470, "y": 910, "label":'Call'},
            // "foldPos": {"x": 150, "y": 910 , "label" : 'Fold'},
            // "raisePos": {"x": 310, "y": 910, "label":'Raise'},
            // "slidebetPos": {"x": 310, "y": 910, "label":'SlideBet'},
            // "checkPos": {"x": 470, "y": 910, "label":'Check'},
            // "iamBack": {"x": 600, "y": 1050, "label":'I am back'}
            //addsamegametabs
            addSameGameTabPos: {
                x: [0, 0, 0],
                y: [94, 260, 430],
            },
            otherGameLogoPos: {
                x: [0, 0, 0],
                y: [250, 250, 285],
            },
            addSameGameTextPos: {
                x: 251,
                y: 76,
            },
            callPos: {
                x: 525,
                y: 1019,
            },
            allinActPos: {
                x: 525,
                y: 1019,
            },
            foldPos: {
                x: 197,
                y: 1019,
            },
            raisePos: {
                x: 360,
                y: 1019,
            },
            slidebetPos: {
                x: 360,
                y: 1023,
            },
            cashoutPos: {
                x: 359.5,
                y: 1023,
            },
            betPos: {
                x: 360,
                y: 1023,
            },
            checkPos: {
                x: 525,
                y: 1019,
            },
            resumehandPos: {
                x: 525,
                y: 1020,
            },
            iamBack: {
                x: 200,
                y: 850,
            },
            cashoutpos: {
                x: 166,
                y: 860,
            },
            iamBackText: {
                x: 200,
                y: 50,
            },
            autoFoldPos: {
                x: 197,
                y: 1019,
            },
            autoFCPos: {
                x: 197,
                y: 1019,
            },
            autoCheckPos: {
                x: 525,
                y: 1019,
            },
            callLabel: 'Call',
            checkLabel: 'Check',
            betLabel: 'Bet',
            slidebetLabel: 'SlideBet',
            cashoutLabel: 'CashOut',
            resumehandLabel: 'ResumeHand',
            raiseLabel: 'Raise',
            foldLabel: 'Fold',
            allinActLabel: 'All In',
            autoCheckLabel: 'Check',
            autoFoldLabel: 'Fold',
            autoFCLabel: 'FC',
            iamBackLabel: 'I am back',
            twoAPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twoOneAPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twoTwoAPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twoFiveAPos: {
                x: [200, 325, 450],
                y: 843,
            },
            threeAPos: {
                x: [200, 325, 450],
                y: 843,
            },
            fourAPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twoBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twooneBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twotwoBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twofiveBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            threeBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            fourBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            oneFourPotPos: {
                x: [200, 325, 450],
                y: 843,
            },
            oneThreePotPos: {
                x: [200, 325, 450],
                y: 843,
            },
            oneTwoPotPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twoThreePotPos: {
                x: [200, 325, 450],
                y: 843,
            },
            threeFourPotPos: {
                x: [200, 325, 450],
                y: 843,
            },
            fourFivePos: {
                x: [200, 325, 450],
                y: 843,
            },
            onePotPos: {
                x: [200, 325, 450],
                y: 843,
            },
            allInPos: {
                x: [200, 325, 450],
                y: 843,
            },
            potBBPos: {
                x: [200, 325, 450],
                y: 843,
            },
            twoBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            twooneBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            twotwoBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            twofiveBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            threeBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            fourBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            oneFourPotLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            oneThreePotLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            oneTwoPotLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            twoThreePotLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            threeFourPotLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            fourFiveLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            onePotLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            allInLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            potBBLabel: {
                x: [199, 310, 440],
                y: 927,
            },
            betSliderPos: {
                betPerLineBarBase: {
                    x: 36,
                    y: 314,
                },
                betperlinestxtnumstart: {
                    x: 80,
                    y: 550,
                },
                betPerLineBarTop: {
                    x: -14,
                    y: 82,
                },
                betPerLineBarMask: {
                    x: 22,
                    y: 541,
                },
                betperlinestxtnumsend: {
                    x: 320,
                    y: 397,
                },
                betPerLineBtn: {
                    x: -14,
                    y: 438,
                },
                //betPerLineBtn: { x: -14, y: 512 },
                betBarInfotxt: {
                    x: 93,
                    y: 36,
                },
                betPerLineBarInfo: {
                    x: -50,
                    y: 5,
                },
                sliderConfirmBet: {
                    x: 36,
                    y: 600,
                },
                callTextPos: {
                    x: 521,
                    y: 1082,
                },
                betTextPos: {
                    x: 360,
                    y: 1083,
                },
                timerAnimPos: {
                    x: 360,
                    y: 1015,
                },
            },
        },
        bottomButtons: {
            buttonContainerPos: {
                x: 0,
                y: 1170,
            },
            buttonsList: ['infoicon', 'emojiicon', 'replayericon', 'tileMaxview', 'multitile'],
            infoiconPos: {
                x: 28,
                y: 1175,
            },
            emojiiconPos: {
                x: 140,
                y: 1198,
            },
            replayericonPos: {
                x: 505,
                y: 1198,
            },
            tileMaxviewPos: {
                x: 598,
                y: 1152,
            },
            multitilePos: {
                x: 618,
                y: 1175,
            },
        },
        infoPanelButtons: {
            buttonsList: ['close', 'game_info_default'],
            closePos: {
                x: 585,
                y: 10,
            },
            game_info_defaultPos: {
                x: 100,
                y: 1160,
            },
        },
        emojiPanelButtons: {
            switcherList: ['text', 'smiley'],
            textList: ['emoji_1', 'emoji_2', 'emoji_3', 'emoji_4', 'emoji_5', 'emoji_6', 'emoji_7', 'emoji_8', 'emoji_9', 'emoji_10'],
            smileyList: [
                'emoji_1001',
                'emoji_1002',
                'emoji_1003',
                'emoji_1004',
                'emoji_1005',
                'emoji_1006',
                'emoji_1007',
                'emoji_1008',
                'emoji_1009',
                'emoji_1010',
                'emoji_1011',
                'emoji_1012',
                'emoji_1013',
                'emoji_1014',
                'emoji_1015',
                'emoji_1016',
                'emoji_1017',
                'emoji_1018',
                'emoji_1019',
                'emoji_1020',
                'emoji_1021',
                'emoji_1022',
                'emoji_1023',
            ],
            emoji_1Pos: {
                x: 30,
                y: 40,
            },
            emoji_2Pos: {
                x: 180,
                y: 40,
            },
            emoji_3Pos: {
                x: 300,
                y: 40,
            },
            emoji_4Pos: {
                x: 440,
                y: 40,
            },
            emoji_5Pos: {
                x: 30,
                y: 120,
            },
            emoji_6Pos: {
                x: 170,
                y: 120,
            },
            emoji_7Pos: {
                x: 325,
                y: 120,
            },
            emoji_8Pos: {
                x: 440,
                y: 120,
            },
            emoji_9Pos: {
                x: 30,
                y: 200,
            },
            emoji_10Pos: {
                x: 170,
                y: 200,
            },
            emoji_1001Pos: {
                x: 0,
                y: 0,
            },
            emoji_1002Pos: {
                x: 115,
                y: 0,
            },
            emoji_1003Pos: {
                x: 225,
                y: 0,
            },
            emoji_1004Pos: {
                x: 335,
                y: 0,
            },
            emoji_1005Pos: {
                x: 440,
                y: 0,
            },
            emoji_1006Pos: {
                x: 0,
                y: 90,
            },
            emoji_1007Pos: {
                x: 115,
                y: 90,
            },
            emoji_1008Pos: {
                x: 225,
                y: 90,
            },
            emoji_1009Pos: {
                x: 335,
                y: 90,
            },
            emoji_1010Pos: {
                x: 440,
                y: 90,
            },
            emoji_1011Pos: {
                x: 0,
                y: 190,
            },
            emoji_1012Pos: {
                x: 115,
                y: 190,
            },
            emoji_1013Pos: {
                x: 225,
                y: 190,
            },
            emoji_1014Pos: {
                x: 335,
                y: 190,
            },
            emoji_1015Pos: {
                x: 440,
                y: 193,
            },
            emoji_1016Pos: {
                x: 0,
                y: 270,
            },
            emoji_1017Pos: {
                x: 115,
                y: 270,
            },
            emoji_1018Pos: {
                x: 225,
                y: 270,
            },
            emoji_1019Pos: {
                x: 335,
                y: 270,
            },
            emoji_1020Pos: {
                x: 440,
                y: 270,
            },
            emoji_1021Pos: {
                x: 0,
                y: 350,
            },
            emoji_1022Pos: {
                x: 115,
                y: 350,
            },
            emoji_1023Pos: {
                x: 225,
                y: 350,
            },
            smileyPos: {
                x: 0,
                y: 2,
            },
            textPos: {
                x: 94,
                y: 2,
            },
        },
        autoOptionsData: {
            buttonsList: ['fc', 'autoCheck'],
            fcPos: {
                x: 470,
                y: 1000,
            },
            autoCheckPos: {
                x: 150,
                y: 1000,
            },
            fcLabel: 'fold/check',
            autoCheckLabel: 'check',
        },
        registrationView: {
            unregisterBtn: {
                scale: 1.4,
                x: 184,
                y: 330,
            },
            unregisterLabel: {
                x: 128,
                y: 14,
            },
            unRegisterContainer: {
                x: 0,
                y: 520,
            },
            waitingForPlayersContainer: {
                x: 0,
                y: 386,
            },
            waitingForPlayersText: {
                x: 20,
                y: 20,
            },
        },
        chipsConfig: {
            1: {
                x: -5,
                y: 208,
            }, //LAYOUT_RIGHT_TOP -done 6
            2: {
                x: -3,
                y: 175,
            }, //LAYOUT_RIGHT_MID 7
            3: {
                x: -8,
                y: 35,
            }, //LAYOUT_RIGHT_BTM -done 8
            4: {
                x: -10.5,
                y: 210,
            }, //LAYOUT_TOP_LEFT -done 4
            5: {
                x: -54,
                y: 114,
            }, //LAYOUT_TOP_RIGHT
            6: {
                x: 147,
                y: 208,
            }, //LAYOUT_LEFT_TOP -done 3
            7: {
                x: 146,
                y: 175,
            }, //LAYOUT_LEFT_MID 2
            8: {
                x: 149,
                y: 35,
            }, //LAYOUT_LEFT_BTM -done 1
            9: {
                x: -7.5,
                y: -145,
            }, //LAYOUT_BOTTOM -done 0
        },
        sevenSeaterChipsConfig: {
            1: {
                x: -5,
                y: 208,
            }, //LAYOUT_RIGHT_TOP -done 6
            2: {
                x: -3,
                y: 175,
            }, //LAYOUT_RIGHT_MID 7
            3: {
                x: -8,
                y: 35,
            }, //LAYOUT_RIGHT_BTM -done 8
            4: {
                x: 25,
                y: 210,
            }, //LAYOUT_TOP_LEFT -done 4
            5: {
                x: 112,
                y: 210,
            }, //LAYOUT_TOP_RIGHT
            6: {
                x: 147,
                y: 208,
            }, //LAYOUT_LEFT_TOP -done 3
            7: {
                x: 146,
                y: 175,
            }, //LAYOUT_LEFT_MID 2
            8: {
                x: 149,
                y: 35,
            }, //LAYOUT_LEFT_BTM -done 1
            9: {
                x: -7.5,
                y: -145,
            }, //LAYOUT_BOTTOM -done 0
        },
        chipsTablePotPos: {
            1: {
                x: -58,
                y: 27,
            }, //LAYOUT_RIGHT_TOP -done
            2: {
                x: -70,
                y: -173,
            }, //LAYOUT_RIGHT_MID
            3: {
                x: -68,
                y: -284,
            }, //LAYOUT_RIGHT_BTM -done
            4: {
                x: 50,
                y: 147,
            }, //LAYOUT_TOP_LEFT -done
            5: {
                x: 50,
                y: 114,
            }, //LAYOUT_TOP_RIGHT
            6: {
                x: 184,
                y: 30,
            }, //LAYOUT_LEFT_TOP -done
            7: {
                x: 202,
                y: -175,
            }, //LAYOUT_LEFT_MID
            8: {
                x: 198,
                y: -280,
            }, //LAYOUT_LEFT_BTM -done
            9: {
                x: 50,
                y: -312,
            }, //LAYOUT_BOTTOM -done
        },
        dealerConfig: {
            1: {
                x: 10,
                y: 200,
            }, //LAYOUT_RIGHT_TOP
            2: {
                x: 16,
                y: 200,
            }, //LAYOUT_RIGHT_MID
            3: {
                x: 16,
                y: 200,
            }, //LAYOUT_RIGHT_BTM
            4: {
                x: 150,
                y: 145,
            }, //LAYOUT_TOP_LEFT
            5: {
                x: -30,
                y: 145,
            }, //LAYOUT_TOP_RIGHT
            6: {
                x: 110,
                y: 200,
            }, //LAYOUT_LEFT_TOP
            7: {
                x: 105,
                y: 200,
            }, //LAYOUT_LEFT_MID
            8: {
                x: 100,
                y: 200,
            }, //LAYOUT_LEFT_BTM
            9: {
                x: -19,
                y: -10,
            }, //LAYOUT_BOTTOM
        },

        displacementToCenter: {
            x: 0,
            y: 0,
        },
        TableData3MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 605],
                    y: [1132, 142, 142],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73, 74],
                    y: [65, 85, 85],
                },
                playerSelectedOptionPos: {
                    x: [75, 70, -15],
                    y: [-30, 5, 5],
                },
                playerViewContainerPos: {
                    x: [287, 18, 556],
                    y: [950, 255, 255],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 102, 624],
                    y: [1002, 369, 369],
                },
                playerCenterPos: {
                    x: [65, 318, -200],
                    y: [-360, 338, 342],
                },
                playersLockIconPos: {
                    x: [164, 140, 5],
                    y: [270, 185, 185],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },

            'DealCardsPosfor2': [
                {
                    x: [319, 393],
                    y: [1145, 1145],
                },
                {
                    x: [67, 137],
                    y: [220, 220],
                },
                {
                    x: [590, 660],
                    y: [220, 220],
                },
            ],
            'DealCardsPosfor4': [
                {
                    x: [309, 340, 382, 423],
                    y: [1145, 1145, 1145, 1145],
                },
                {
                    x: [59, 89, 119, 149],
                    y: [220, 220, 220, 220],
                },
                {
                    x: [590, 620, 650, 680],
                    y: [220, 220, 220, 220],
                },
            ],
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_LEFT_TOP,
                2: serverIdConstants.LAYOUT_RIGHT_TOP,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 336,
                y: 447,
            },
            'potAmountText': {
                x: 387,
                y: 447,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
        },
        TableData6MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 83, 150, 605, 605],
                    y: [1132, 442, 142, 150, 142, 442],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73, 73, 73, 74, 74],
                    y: [65, 85, 85, 85, 85, 85],
                },
                playerViewContainerPos: {
                    x: [287, 4, 18, 290, 556, 570],
                    y: [950, 735, 255, 135, 255, 735],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 102, 102, 360, 624, 624],
                    y: [1002, 755, 369, 220, 369, 755],
                },
                playerCenterPos: {
                    x: [65, 318, 318, 57, -200, -200],
                    y: [-360, -49, 429, 633, 427, -46],
                },
                playersLockIconPos: {
                    x: [164, 140, 140, 140, 5, 5],
                    y: [270, 185, 185, 185, 185, 185],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    // x: [210, 295, 380, 465, 550], commented and added +52
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },

            'DealCardsPosfor2': [
                {
                    x: [319, 393],
                    y: [1145, 1145],
                }, //0
                {
                    x: [67, 137],
                    y: [700, 700],
                }, //1
                {
                    x: [67, 137],
                    y: [220, 220],
                }, //2
                {
                    x: [167, 237],
                    y: [120, 120],
                }, //3
                {
                    x: [590, 660],
                    y: [220, 220],
                }, //4
                {
                    x: [590, 660],
                    y: [700, 700],
                }, //5
            ],
            'DealCardsPosfor4': [
                {
                    x: [309, 340, 382, 423],
                    y: [1145, 1145, 1145, 1145],
                },
                {
                    x: [59, 89, 119, 149],
                    y: [220, 220, 220, 220],
                },
                {
                    x: [590, 620, 650, 680],
                    y: [220, 220, 220, 220],
                },
            ],
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_LEFT_BTM,
                2: serverIdConstants.LAYOUT_LEFT_TOP,
                3: serverIdConstants.LAYOUT_TOP_LEFT,
                4: serverIdConstants.LAYOUT_RIGHT_TOP,
                5: serverIdConstants.LAYOUT_RIGHT_BTM,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 324,
                y: 447,
            },
            'potAmountText': {
                x: 360,
                y: 430,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
        },
        TableData2MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 605],
                    y: [1132, 142, 142],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73],
                    y: [65, 85],
                },
                playerViewContainerPos: {
                    x: [287, 290],
                    y: [950, 135],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 360],
                    y: [1002, 220],
                },
                playerCenterPos: {
                    x: [65, 57],
                    y: [-300, 520],
                },
                playersLockIconPos: {
                    x: [164, 5],
                    y: [270, 185],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_TOP_LEFT,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 332,
                y: 447,
            },
            'potAmountText': {
                x: 370,
                y: 430,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
        },
        TableData4MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 83, 150, 605, 605],
                    y: [1132, 442, 142, 150, 142, 442],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73, 73, 73, 74, 74],
                    y: [65, 85, 85, 85, 85, 85],
                },
                playerViewContainerPos: {
                    x: [287, 30, 30, 290, 550, 550],
                    y: [950, 626, 284, 93, 284, 626],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 102, 102, 360, 624, 624],
                    y: [1002, 711, 369, 178, 369, 711],
                },
                playersLockIconPos: {
                    x: [164, 140, 5, 5],
                    y: [270, 185, 185, 185],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },
            'DealCardsPosfor2': [
                {
                    x: [319, 393],
                    y: [1145, 1145],
                }, //0
                {
                    x: [67, 137],
                    y: [700, 700],
                }, //1
                {
                    x: [67, 137],
                    y: [220, 220],
                }, //2
                {
                    x: [167, 237],
                    y: [120, 120],
                }, //3
                {
                    x: [590, 660],
                    y: [220, 220],
                }, //4
                {
                    x: [590, 660],
                    y: [700, 700],
                }, //5
            ],
            'DealCardsPosfor4': [
                {
                    x: [309, 340, 382, 423],
                    y: [1145, 1145, 1145, 1145],
                },
                {
                    x: [59, 89, 119, 149],
                    y: [220, 220, 220, 220],
                },
                {
                    x: [590, 620, 650, 680],
                    y: [220, 220, 220, 220],
                },
            ],
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_LEFT_BTM,
                2: serverIdConstants.LAYOUT_LEFT_TOP,
                3: serverIdConstants.LAYOUT_TOP_LEFT,
                4: serverIdConstants.LAYOUT_RIGHT_TOP,
                5: serverIdConstants.LAYOUT_RIGHT_BTM,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 324,
                y: 447,
            },
            'potAmountText': {
                x: 360,
                y: 430,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
        },
        TableData7MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 185,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: -35,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 83, 150, 605, 605],
                    y: [1132, 442, 142, 150, 142, 442],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73, 73, 73, 73, 74, 74],
                    y: [65, 85, 85, 85, 85, 85, 85],
                },
                playerViewContainerPos: {
                    x: [287, 4, 18, 175, 409, 556, 570],
                    y: [950, 735, 255, 135, 135, 255, 735],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 102, 102, 262, 502, 624, 624],
                    y: [1002, 755, 369, 220, 220, 369, 755],
                },
                playerCenterPos: {
                    x: [65, 318, 318, 162, -12, -200, -200],
                    y: [-360, -47, 338, 488, 488, 342, -46],
                },
                playersLockIconPos: {
                    x: [164, 140, 140, 140, 5, 5, 5],
                    y: [270, 185, 185, 185, 185, 185, 185],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },

            'DealCardsPosfor4': [
                {
                    x: [309, 340, 382, 423],
                    y: [1145, 1145, 1145, 1145],
                },
                {
                    x: [59, 89, 119, 149],
                    y: [220, 220, 220, 220],
                },
                {
                    x: [590, 620, 650, 680],
                    y: [220, 220, 220, 220],
                },
            ],
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_LEFT_BTM,
                2: serverIdConstants.LAYOUT_LEFT_TOP,
                3: serverIdConstants.LAYOUT_TOP_LEFT,
                4: serverIdConstants.LAYOUT_TOP_RIGHT,
                5: serverIdConstants.LAYOUT_RIGHT_TOP,
                6: serverIdConstants.LAYOUT_RIGHT_BTM,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 324,
                y: 447,
            },
            'potAmountText': {
                x: 360,
                y: 430,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
        },
        TableData8MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 83, 150, 605, 605],
                    y: [1132, 442, 142, 150, 142, 442],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73, 73, 73, 73, 74, 74, 74],
                    y: [65, 85, 85, 85, 85, 85, 85, 85],
                },
                playerViewContainerPos: {
                    x: [287, 4, 4, 18, 290, 556, 571, 572],
                    y: [950, 735, 487, 255, 135, 255, 487, 735],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 102, 102, 102, 362, 624, 624, 624],
                    y: [1002, 755, 557, 369, 220, 369, 557, 755],
                },
                playerCenterPos: {
                    x: [65, 318, 318, 318, 57, -200, -200, -200],
                    y: [-360, -47, 152, 338, 488, 342, 150, -46],
                },
                playersLockIconPos: {
                    x: [164, 140, 140, 140, 5, 5, 5, 5],
                    y: [270, 185, 185, 185, 185, 185, 185, 185],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    // old gap was 85
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },
            'DealCardsPosfor4': [
                {
                    x: [309, 340, 382, 423],
                    y: [1145, 1145, 1145, 1145],
                },
                {
                    x: [59, 89, 119, 149],
                    y: [220, 220, 220, 220],
                },
                {
                    x: [590, 620, 650, 680],
                    y: [220, 220, 220, 220],
                },
            ],
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_LEFT_BTM,
                2: serverIdConstants.LAYOUT_LEFT_MID,
                3: serverIdConstants.LAYOUT_LEFT_TOP,
                4: serverIdConstants.LAYOUT_TOP_LEFT,
                5: serverIdConstants.LAYOUT_RIGHT_TOP,
                6: serverIdConstants.LAYOUT_RIGHT_MID,
                7: serverIdConstants.LAYOUT_RIGHT_BTM,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 324,
                y: 447,
            },
            'potAmountText': {
                x: 360,
                y: 430,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
        },
        TableData10MaxPos: {
            'backCardsAnimConfig': {
                1: {
                    x: -198,
                    y: 398,
                }, //LAYOUT_RIGHT_TOP
                2: {
                    x: -211,
                    y: 170,
                }, //LAYOUT_RIGHT_MID
                3: {
                    x: -208,
                    y: -76,
                }, //LAYOUT_RIGHT_BTM
                4: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_LEFT
                5: {
                    x: 72,
                    y: 520,
                }, //LAYOUT_TOP_RIGHT
                6: {
                    x: 342,
                    y: 398,
                }, //LAYOUT_LEFT_TOP
                7: {
                    x: 360,
                    y: 170,
                }, //LAYOUT_LEFT_MID
                8: {
                    x: 357,
                    y: -76,
                }, //LAYOUT_LEFT_BTM
                9: {
                    x: 72,
                    y: -294,
                }, //LAYOUT_BOTTOM
            },
            'timeBankConfig': {
                idleTimeBankPos: {
                    x: 35,
                    y: 1051,
                },
                timeBankNotifyAni: {
                    x: 18,
                    y: 1027,
                },
                timeBankActivePos: {
                    x: 35,
                    y: 1051,
                },
                timeBankTextPos: {
                    x: [18, 83, 83, 150, 605, 605, 83, 150, 605, 605],
                    y: [1132, 442, 142, 150, 142, 442, 83, 150, 605, 605],
                },
            },
            'tableTextContainer': {
                x: 0,
                y: 108,
            },
            'RAISEActionPos': {
                x: 44,
                y: 15,
            },
            'CALLActionPos': {
                x: 46,
                y: 15,
            },
            'FOLDActionPos': {
                x: 45,
                y: 15,
            },
            'CHECKActionPos': {
                x: 44,
                y: 15,
            },
            'BETActionPos': {
                x: 46,
                y: 15,
            },
            'Posts SBActionPos': {
                x: 46,
                y: 16,
            },
            'Posts BBActionPos': {
                x: 46,
                y: 16,
            },
            'playersUIData': {
                playerIconPositions: {
                    x: [73, 73, 73, 73, 74, 74, 73, 73, 74, 74],
                    y: [65, 85, 85, 85, 85, 85, 85, 85, 85, 85],
                },
                playerViewContainerPos: {
                    x: [287, 30, 30, 290, 550, 550, 30, 290, 550, 550],
                    y: [950, 626, 284, 93, 284, 626, 284, 93, 284, 626],
                },
                playerSelfTorchPos: {
                    x: 59,
                    y: 145,
                },
                playerAbsPos: {
                    x: [360, 102, 102, 360, 624, 624, 102, 360, 624, 624],
                    y: [1002, 711, 369, 178, 369, 711, 369, 178, 369, 711],
                },
            },
            'communityCardConfig': {
                cardsPosition: {
                    x: [187, 273, 359, 445, 531],
                    y: [590, 590, 590, 590, 590],
                },
            },
            'DealCardsPosfor2': [
                {
                    x: [319, 393],
                    y: [1145, 1145],
                }, //0
                {
                    x: [67, 137],
                    y: [700, 700],
                }, //1
                {
                    x: [67, 137],
                    y: [220, 220],
                }, //2
                {
                    x: [167, 237],
                    y: [120, 120],
                }, //3
                {
                    x: [590, 660],
                    y: [220, 220],
                }, //4
                {
                    x: [590, 660],
                    y: [700, 700],
                }, //5
            ],
            'DealCardsPosfor4': [
                {
                    x: [309, 340, 382, 423],
                    y: [1145, 1145, 1145, 1145],
                },
                {
                    x: [59, 89, 119, 149],
                    y: [220, 220, 220, 220],
                },
                {
                    x: [590, 620, 650, 680],
                    y: [220, 220, 220, 220],
                },
            ],
            'layOutPos': {
                0: serverIdConstants.LAYOUT_BOTTOM,
                1: serverIdConstants.LAYOUT_LEFT_BTM,
                2: serverIdConstants.LAYOUT_LEFT_TOP,
                3: serverIdConstants.LAYOUT_TOP_LEFT,
                4: serverIdConstants.LAYOUT_RIGHT_TOP,
                5: serverIdConstants.LAYOUT_RIGHT_BTM,
                6: serverIdConstants.LAYOUT_LEFT_TOP,
                7: serverIdConstants.LAYOUT_LEFT_TOP,
                8: serverIdConstants.LAYOUT_LEFT_TOP,
                9: serverIdConstants.LAYOUT_LEFT_TOP,
                10: serverIdConstants.LAYOUT_LEFT_TOP,
            },
            'youWinPos': {
                x: [368, 368, 368],
                y: [791, 890, 890],
            },
            'dealerSeatPos': {
                x: [263, 190, 500],
                y: [930, 220, 220],
            },
            'tablePotChipPos': {
                x: 345,
                y: 447,
            },
            'potAmtTitleText': {
                x: 324,
                y: 447,
            },
            'potAmountText': {
                x: 360,
                y: 430,
            },
            'betsContainerPos': {
                x: [330, 125, 515],
                y: [785, 340, 340],
            },
            'playersLockIconPos': {
                x: [164, 140, 140, 140, 140, 5, 5, 5, 5, 5],
                y: [270, 185, 185, 185, 185, 185, 185, 185, 185, 185],
            },
        },
    },
};
