import { Message } from '../../message';

export class FXRateDetails extends Message {
    private MESSAGE_NAME: string = 'FXRateDetails';
    private snapshotId: number = 0;
    private markupType: string | null = null;
    private fromCurrencyCode: string | null = null;
    private toCurrencyCode: string | null = null;
    private conversionFactor4Client: number = 0;
    private reverseConversionFactor4Client: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        snapshotId: number = 0,
        markupType: string | null = null,
        fromCurrencyCode: string | null = null,
        toCurrencyCode: string | null = null,
        conversionFactor4Client: number = 0,
        reverseConversionFactor4Client: number = 0,
    ) {
        super(reqServerPeerId);
        this.snapshotId = snapshotId;
        this.markupType = markupType;
        this.fromCurrencyCode = fromCurrencyCode;
        this.toCurrencyCode = toCurrencyCode;
        this.conversionFactor4Client = conversionFactor4Client;
        this.reverseConversionFactor4Client = reverseConversionFactor4Client;
    }

    getSnapshotId(): number {
        return this.snapshotId;
    }

    setSnapshotId(snapshotId: number) {
        this.snapshotId = snapshotId;
    }
    getMarkupType(): string | null {
        return this.markupType;
    }

    setMarkupType(markupType: string | null) {
        this.markupType = markupType;
    }
    getFromCurrencyCode(): string | null {
        return this.fromCurrencyCode;
    }

    setFromCurrencyCode(fromCurrencyCode: string | null) {
        this.fromCurrencyCode = fromCurrencyCode;
    }
    getToCurrencyCode(): string | null {
        return this.toCurrencyCode;
    }

    setToCurrencyCode(toCurrencyCode: string | null) {
        this.toCurrencyCode = toCurrencyCode;
    }
    getConversionFactor4Client(): number {
        return this.conversionFactor4Client;
    }

    setConversionFactor4Client(conversionFactor4Client: number) {
        this.conversionFactor4Client = conversionFactor4Client;
    }
    getReverseConversionFactor4Client(): number {
        return this.reverseConversionFactor4Client;
    }

    setReverseConversionFactor4Client(reverseConversionFactor4Client: number) {
        this.reverseConversionFactor4Client = reverseConversionFactor4Client;
    }
}
