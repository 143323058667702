import { Message } from '../../message';

export class BroadcastTableBoosterTrigger extends Message {
    private MESSAGE_NAME: string = 'BroadcastTableBoosterTrigger';
    private boosterType: number = 0;
    private fromBB: number = 0;
    private toBB: number = 0;
    private gameId: number = 0;
    private tableId: number = 0;
    private broadcastConfTypeVSGames: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        boosterType: number = 0,
        fromBB: number = 0,
        toBB: number = 0,
        gameId: number = 0,
        tableId: number = 0,
        broadcastConfTypeVSGames: Map<any, any> | null = null,
    ) {
        super(reqServerPeerId);
        this.boosterType = boosterType;
        this.fromBB = fromBB;
        this.toBB = toBB;
        this.gameId = gameId;
        this.tableId = tableId;
        this.broadcastConfTypeVSGames = broadcastConfTypeVSGames;
    }

    getBoosterType(): number {
        return this.boosterType;
    }

    setBoosterType(boosterType: number) {
        this.boosterType = boosterType;
    }
    getFromBB(): number {
        return this.fromBB;
    }

    setFromBB(fromBB: number) {
        this.fromBB = fromBB;
    }
    getToBB(): number {
        return this.toBB;
    }

    setToBB(toBB: number) {
        this.toBB = toBB;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getBroadcastConfTypeVSGames(): Map<any, any> | null {
        return this.broadcastConfTypeVSGames;
    }

    setBroadcastConfTypeVSGames(broadcastConfTypeVSGames: Map<any, any> | null) {
        this.broadcastConfTypeVSGames = broadcastConfTypeVSGames;
    }
}
