import { Message } from '../../message';

export class DealMakingPayoutData extends Message {
    private MESSAGE_NAME: string = 'DealMakingPayoutData';
    private payoutTitle: any | null = null;
    private payouts: any[] | null = null;
    private payoutId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, payoutTitle: any | null = null, payouts: any[] | null = null, payoutId: number = 0) {
        super(reqServerPeerId);
        this.payoutTitle = payoutTitle;
        this.payouts = payouts;
        this.payoutId = payoutId;
    }

    getPayoutTitle(): any | null {
        return this.payoutTitle;
    }

    setPayoutTitle(payoutTitle: any | null) {
        this.payoutTitle = payoutTitle;
    }
    getPayouts(): any[] | null {
        return this.payouts;
    }

    setPayouts(payouts: any[] | null) {
        this.payouts = payouts;
    }
    getPayoutId(): number {
        return this.payoutId;
    }

    setPayoutId(payoutId: number) {
        this.payoutId = payoutId;
    }
}
