import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { DynamicHtmlDirective, Page } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { PokerHomePageClientConfig, PromotionsClientConfig } from '@pokercore/module/core';

@Component({
    selector: 'pk-poker-synopsis-publicpage-promotions',
    templateUrl: 'poker-promotions-synopsis.publicpage.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, TrustAsHtmlPipe, DynamicHtmlDirective],
})
export class PokerPromotionsSynopsisPublicPageComponent implements OnInit {
    @Input() initData: any;
    //initData:any;
    pokerPromotionsHome: any;
    relatedPromos: any;
    menuItems: any;
    lang: string;
    menuContent: any;
    showConfigureNavigation: boolean = false;
    constructor(
        // private routeData: RouteDataService,
        private page: Page,
        private titleService: Title,
        private metaService: Meta,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private promotionsClientConfig: PromotionsClientConfig,
    ) {}
    getPromoLink(promoName: string) {
        if (promoName != null) {
            const tokens = promoName.split('/').slice(2);
            promoName = tokens.join('/');
        }
        //console.log(this.lang,promoName)
        return `${this.lang}/${promoName}`;
    }
    ngOnInit(): void {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        const el = document.getElementById('main-content') as HTMLElement;
        el.className = 'fixed-width';
        //this.initData = this.routeData.getInitData();
        this.pokerPromotionsHome = this.initData.pokerPromotionsSitecoreInfo;
        this.relatedPromos = this.initData.relatedPromos;
        //this.menuItems = this.pokerPromotionsHome.menuContent;
        this.lang = this.page.lang;

        if (this.promotionsClientConfig.whenReady.subscribe(() => this.promotionsClientConfig.usePokerPromotionsNewTemplate)) {
            this.titleService.setTitle(this.pokerPromotionsHome.seoTitle);
        } else {
            this.titleService.setTitle(this.pokerPromotionsHome.title);
        }
        this.metaService.updateTag({ name: 'description', content: this.pokerPromotionsHome.description });
    }

    menuItemClicked() {
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf('/') + 1);
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf(windowObj.clientConfig.lhProductHomepagesConfig.poker)+ 1);
        /*let windowObj:any =  window;
        let itemId:string;
        let siteUrl:string;
        siteUrl = windowObj.clientConfig.lhProductHomepagesConfig.poker;
        if(itemUrl.indexOf(siteUrl)>=0){
            itemId = itemUrl.substr(siteUrl.length+ 1);
        }else{
            itemId = itemUrl;
        }
        
        this.api.get('/PokerPromotion', { folderId: itemId },{showSpinner:true}).subscribe((response) => {
            
            this.pokerPromotionsInitData = response.promoModel;
            this.promoItem = response.promoItem;
            this.relatedPromos = response.promoModel.relatedPromos;
            this.promoListEx = response.promoModel.promoListEx;
        }, (error) => {
        });*/
        //console.log(itemUrl)
    }
}
