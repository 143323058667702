<div class="pp-container pp-container-swiper" id="{{ instanceId }}">
    <vn-pc-component-header-v2 *ngIf="item.title" [item]="item"></vn-pc-component-header-v2>
    <div *ngIf="item.wrapperConfig === 'custom'" class="external-button-next"></div>
    <div *ngIf="item.wrapperConfig === 'custom'" class="external-button-prev"></div>
    <div *ngIf="item.wrapperConfig === 'custom' && this.PPContainerItems?.length > 1" class="swiper-pagination swiper-pagination-custom"></div>
    <div
        #swiperComponent
        class="swiper {{ item.wrapperConfig }} mySwiper-{{ instanceId }}"
        [ngClass]="{ 'hide-swiper-pagination': item.items.length === 1 }">
        <div *ngIf="item.wrapperConfig === 'tabbed'" class="swiper-pagination ext-swiper-pagination-bullets"></div>
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let x of this.PPContainerItems; let i = index">
                <span [hidden]="true" class="pk-pp-swipper-title-hidden">{{ x.title }}</span>
                <ng-container *vnPageMatrix="x"></ng-container>
            </div>
        </div>
        <div *ngIf="item.wrapperConfig === 'carousel'" class="swiper-pagination"></div>
        <ng-container *ngIf="item.wrapperConfig === 'carousel'">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </ng-container>
    </div>
</div>
