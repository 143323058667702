import { Message } from '../../message';

export class SNGJPWinnerDeleteEvent extends Message {
    private MESSAGE_NAME: string = 'SNGJPWinnerDeleteEvent';
    private feedID: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, feedID: number = 0) {
        super(reqServerPeerId);
        this.feedID = feedID;
    }

    getFeedID(): number {
        return this.feedID;
    }

    setFeedID(feedID: number) {
        this.feedID = feedID;
    }
}
