<div id="spins-lobby-main">
    <div class="spins-lobby-wrap spins-ultra-wrap">
        <div class="spins-lobby-content">
            <div class="spins-tile" *ngFor="let card of sngjpcards; let index = index" [attr.index]="index">
                <span class="spins-tile-bg"><img src="{{ card.slide.imgCardView }}" alt="" /></span>
                <span class="spins-title">{{ card.slide.gameType }}</span>
                <div class="spins-win">
                    <span class="s-win-label" *ngIf="card.slide.ticketCount > 0">{{ card.slide.ticketCount }}</span>
                    <span class="s-win-label" *ngIf="card.slide.payOutType">{{ card.slide.textTitle }}</span>
                    <span class="s-win-amount" *ngIf="card.slide.payOutType">${{ card.slide.text1 }}</span>
                    <span class="s-win-label" *ngIf="!card.slide.payOutType">{{ card.slide.payOutCard }}</span>
                    <span class="s-win-label" *ngIf="!card.slide.payOutType">{{ card.slide.text3 }}</span>
                </div>
                <span class="s-win-buyin">{{ card.slide.text3 }} {{ 'Buy-in' }}</span>
            </div>
        </div>
    </div>
</div>
