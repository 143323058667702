export class TableCreateViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view) {
        this.view = view;
    };
    showCreateTable = function () {
        this.view.showCreateTable();
    };
}
