import { Message } from '../../message';

export class LoginRequest extends Message {
    private MESSAGE_NAME: string = 'LoginRequest';
    private ssoKey: string | null = null;
    private loginId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, ssoKey: string | null = null, loginId: string | null = null) {
        super(reqServerPeerId);
        this.ssoKey = ssoKey;
        this.loginId = loginId;
    }

    getSsoKey(): string | null {
        return this.ssoKey;
    }

    setSsoKey(ssoKey: string | null) {
        this.ssoKey = ssoKey;
    }
    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
}
