import { Message } from '../../message';

export class RefreshTablesList extends Message {
    private MESSAGE_NAME: string = 'RefreshTablesList';
    private refreshTablesList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, refreshTablesList: any[] | null = null) {
        super(reqServerPeerId);
        this.refreshTablesList = refreshTablesList;
    }

    getRefreshTablesList(): any[] | null {
        return this.refreshTablesList;
    }

    setRefreshTablesList(refreshTablesList: any[] | null) {
        this.refreshTablesList = refreshTablesList;
    }
}
