import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import * as r from './twitter.resolvers';
import { TwitterService } from './twitter.service';

@Component({
    selector: 'pt-twitter',
    templateUrl: 'twitter.component.html',
    standalone: true,
    imports: [CommonModule],
    providers: [TwitterService, r.TwitterResolver],
})
export class TwitterComponent implements OnInit {
    initData: Array<any>;
    stringData: string;
    twitterLink: string = '';

    constructor(private twitterService: TwitterService) {}

    getFormattedDate(dateString: string) {
        return new Date(dateString).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    }

    ngOnInit() {
        this.twitterService.getFeedData().subscribe((response) => {
            this.initData = response.items;
            this.twitterLink = `https://twitter.com/${this.initData[0].user.screen_name}`;
        });
    }

    getProfileImage(img: string) {
        return img.replace('_normal', '');
    }

    getTweetUrl(tweetText: string) {
        return tweetText.lastIndexOf('https') > -1 ? tweetText.substring(tweetText.lastIndexOf('https')) : null;
    }
}
