import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class RegistrationViewModel {
    public gameService;
    constructor(gs) {
        this.gameService = gs;
    }

    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.START_SPIN_MULTILPIER, this.hideView.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_DEAL_CARDS, this.hideView.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_NEW_GAME, this.hideView.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.REMOVE_REGISTRAION_VIEW, this.hideView.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_WAITING_FOR_PLAYERS, this.showWaitingForPlayers.bind(this));
    };
    unRegister = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UN_REGISTER);
    };
    hideView = function () {
        this.view.visible = false;
    };
    showWaitingForPlayers = function () {
        this.view.showWaitingForPlayers();
    };
    hideWaitingForPlayers = function () {
        this.view.hideWaitingForPlayers();
    };
}
