import { Routes } from '@angular/router';

import { LeaderBoardComponent } from './leaderboard.component';
// import { LeaderBoardModule } from "./leaderboard.module";
import { LeaderBoardResolver } from './leaderboard.resolvers';

export const leaderBoardRoutes: Routes = [
    {
        path: 'promotions/leaderboard',
        component: LeaderBoardComponent,
        resolve: {
            initData: LeaderBoardResolver,
        },
    },
];
