import { Message } from '../../message';

export class BoosterWinners extends Message {
    private MESSAGE_NAME: string = 'BoosterWinners';
    private screenName: string | null = null;
    private winAmount: number = 0;
    private isWinner: boolean | null = false;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        screenName: string | null = null,
        winAmount: number = 0,
        isWinner: boolean | null = false,
        seatNo: number = 0,
    ) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.winAmount = winAmount;
        this.isWinner = isWinner;
        this.seatNo = seatNo;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getWinAmount(): number {
        return this.winAmount;
    }

    setWinAmount(winAmount: number) {
        this.winAmount = winAmount;
    }
    getIsWinner(): boolean | null {
        return this.isWinner;
    }

    setIsWinner(isWinner: boolean | null) {
        this.isWinner = isWinner;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
