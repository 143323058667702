import { Message } from '../../message';

export class RequestLobbyActive extends Message {
    private MESSAGE_NAME: string = 'RequestLobbyActive';
    private active: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, active: boolean | null = false) {
        super(reqServerPeerId);
        this.active = active;
    }

    getActive(): boolean | null {
        return this.active;
    }

    setActive(active: boolean | null) {
        this.active = active;
    }
}
