// import { warn } from 'console';
import { PGMessageFactoryRegistration } from '../PGMessageFactoryRegistration';
import TSUtil from '../common/tsUtil';
import { MessageFactoryManager } from '../messageFactoryManager';
import { CodedInputStream } from './codedInputStream';

export class TSDataInputStream {
    messageFactoryManager: MessageFactoryManager;
    cis: CodedInputStream;
    isBCEnabled: boolean | undefined;
    bytes;
    msgFactryReg: PGMessageFactoryRegistration;

    constructor(bytes: any, msgFactryReg: PGMessageFactoryRegistration, isBCEnabled?: boolean) {
        this.msgFactryReg = msgFactryReg;
        this.messageFactoryManager = new MessageFactoryManager(this.msgFactryReg);
        this.cis = new CodedInputStream();
        this.isBCEnabled = isBCEnabled;
        this.bytes = bytes;
    }

    getBoolean(): boolean {
        return this.cis.readBool(this.bytes);
    }

    getByte(): number {
        return this.cis.readFixed8(this.bytes);
    }

    getShort(): number {
        return this.cis.readInt32(this.bytes);
    }

    getInteger(): number {
        return this.cis.readInt32(this.bytes);
    }

    getLong(): number {
        return this.cis.readInt64(this.bytes);
    }

    getString(): string | null {
        return this.cis.readString(this.bytes);
    }

    getStringEx(): any | null {
        try {
            const nullOrNot = this.getByte();

            if (!nullOrNot) {
                return null;
            }

            const templateID = this.getInteger();
            const paramValues = this.getStringArray();
            const isLPElement = this.getBoolean();

            return new TSUtil.StringEx(null, templateID, paramValues, isLPElement);
        } catch (e) {
            throw new Error(e);
            return;
        }
    }

    getObject(): any {
        const classId = this.cis.readInt32(this.bytes);

        if (classId === 0) {
            return null;
        }
        const messageObject = this.messageFactoryManager.getClassNameForClassId(classId);

        if (!messageObject.gettopLevelMessage()) {
            //&& this.isBCEnabled
            const msgLength = this.getInteger();
            const msgEndPos = this.getPosition() + msgLength;

            this.messageFactoryManager.messageRead(messageObject, this);
            const endPos = this.getPosition();

            if (endPos < msgEndPos) {
                // const logWarn =
                //     typeof console !== 'undefined' && console.warn
                //         ? (mex: string) => {
                //               console.warn(mex);
                //           }
                //         : () => {};
                // throw new warn(
                //     'Potential Serializer Mismatch...!!!\nSome fields skipped during deserialization of message: ' + messageObject.className,
                // );
            }

            this.setPosition(msgEndPos);
        } else {
            this.messageFactoryManager.messageRead(messageObject, this);
        }

        if (messageObject.classId === undefined) {
            messageObject.classId = classId;
            return messageObject;
        } else {
            return messageObject;
        }
    }

    getBytes(): any {
        return this.cis.readBytes(this.bytes);
    }

    getObjectArray(): any[] {
        return this.getList();
    }

    getStringArray(): string[] {
        return this.getList();
    }

    getIntArray(): number[] {
        return this.getList();
    }

    getLongArray(): number[] {
        return this.getList();
    }

    getStringExArray(): any[] {
        return this.getList();
    }

    getInt2IntMap(): any {
        return this.getMap();
    }

    getInt2StringMap(): any {
        return this.getMap();
    }

    getString2IntMap(): any {
        return this.getMap();
    }

    getString2StringMap(): any {
        return this.getMap();
    }

    getByte2LongMap(): any {
        return this.getMap();
    }

    getByte2IntMap(): any {
        return this.getMap();
    }

    getInt2BoolMap(): any {
        return this.getMap();
    }

    getInt2ObjectMap(): any {
        return this.getMap();
    }

    getByte2ObjectArrayMap(): any {
        alert('TO-DO: This does not implemented.');
        // return this.getMap();
    }

    getInt2LongMap(): any {
        return this.getMap();
    }

    getShort2StringMap(): any {
        return this.getMap();
    }

    getShort2ShortMap(): any {
        return this.getMap();
    }

    getShort2IntMap(): any {
        return this.getMap();
    }

    getShort2LongMap(): any {
        return this.getMap();
    }

    getTimestamp(): number {
        return this.getLong();
    }

    getItem(contentType: number): any {
        switch (contentType) {
            case TSUtil.ENTITY_TYPES.NULL:
                return null;

            case TSUtil.ENTITY_TYPES.SHORT:
                return this.getShort();

            case TSUtil.ENTITY_TYPES.BYTE:
                return this.getByte();

            case TSUtil.ENTITY_TYPES.STRING:
                return this.getString();

            case TSUtil.ENTITY_TYPES.STRINGEX:
                return this.getStringEx();

            case TSUtil.ENTITY_TYPES.INT:
                return this.getInteger();

            case TSUtil.ENTITY_TYPES.LONG:
                return this.getLong();

            case TSUtil.ENTITY_TYPES.BOOLEAN:
                return this.getBoolean();

            case TSUtil.ENTITY_TYPES.VECTOR:
                return this.getList();

            case TSUtil.ENTITY_TYPES.HASHTABLE:
                return this.getList();

            case TSUtil.ENTITY_TYPES.ARRAY:
                return this.getList();

            case TSUtil.ENTITY_TYPES.OBJECT:
                return this.getObject();

            case TSUtil.ENTITY_TYPES.BYTE_ARRAY:
                return this.getBytes();

            default:
                throw new Error(`Unknown entity type=${contentType} at offset=${this.bytes.position}`);
        }
    }

    getPosition(): number {
        return this.bytes.position;
    }

    setPosition(position: number): void {
        this.bytes.setPosition(position);
    }

    private getList(): any[] {
        const count = this.cis.readInt32(this.bytes);
        const arr: any[] = [];

        if (count > 0) {
            const nature = this.cis.readFixed8(this.bytes);

            if (nature === TSUtil.NATURES.HOMOGENEOUS) {
                const contentType = this.cis.readFixed8(this.bytes) & 0xff;

                for (let x = 0; x < count; x++) {
                    arr.push(this.getItem(contentType));
                }
            } else {
                for (let x = 0; x < count; x++) {
                    const contentType = this.cis.readFixed8(this.bytes) & 0xff;

                    arr.push(this.getItem(contentType));
                }
            }
        }

        return arr;
    }

    private getMap(): any {
        const count = this.cis.readInt32(this.bytes);
        const ht: any = {};

        if (count > 0) {
            const nature = this.cis.readFixed8(this.bytes);

            if (nature === TSUtil.NATURES.HOMOGENEOUS) {
                const keyType = this.cis.readFixed8(this.bytes) & 0xff;
                const valueType = this.cis.readFixed8(this.bytes) & 0xff;

                for (let x = 0; x < count; x++) {
                    const key = this.getItem(keyType);
                    const value = this.getItem(valueType);

                    if (key !== null && value !== null) {
                        ht[key] = value;
                    }
                }
            } else {
                for (let x = 0; x < count; x++) {
                    const keyType = this.cis.readFixed8(this.bytes) & 0xff;
                    const valueType = this.cis.readFixed8(this.bytes) & 0xff;
                    const key = this.getItem(keyType);
                    const value = this.getItem(valueType);

                    if (key !== null && value !== null) {
                        ht[key] = value;
                    }
                }
            }
        }

        return ht;
    }
}
