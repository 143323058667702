import { Message } from '../../message';

export class RequestEndOfTbt extends Message {
    private MESSAGE_NAME: string = 'RequestEndOfTbt';

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0) {
        super(reqServerPeerId);
    }
}
