import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class CommunityCardsViewModel {
    gameService;
    tableSettingsDataModel;
    constructor(gs, gsd) {
        this.gameService = gs;
        this.tableSettingsDataModel = gsd;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_COMMUNITYCARDS, this.handleAddCommunityCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.CHANGE_CARD_STYLE, this.setCardStyle.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_SETTING_DATA, this.updateCommunityCardStyle.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_WINNER_CARDS, this.handleWinnerCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_BACKGROUND_ON_DISC, this.updateSpinUltraLogo.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_CASHOUT_DISABLED, this.enableCashOutDisableText.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CASHOUT_WAIT_TEXT, this.enableCashOutDisableText.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPADATEEXPOSPINBG, this.updateExpoSpinLogo.bind(this));
    };
    updateExpoSpinLogo = function () {
        this.view.updateExpoSpinLogo();
    };
    handleWinnerCards = function () {
        const winnerCards = this.gameService.getWinnerCards();
        this.view.handleWinnerCards(winnerCards);
    };
    updateCommunityCardStyle = function (data) {
        this.view.updateCommunityCardStyle(data.detail);
    };
    setCardStyle = function () {
        this.view.setCardStyle(this.gameService.getCardStyle());
    };
    handleAddCommunityCards = function () {
        //let obj = {"cardNo":0,"cardType":0,"cards":["9h","Td","6d"],"msgNumber":0,"peerId":5127921,"className":"AddCommunityCards"};
        //let obj  = {"cardNo":3,"cardType":0,"cards":["2s"],"msgNumber":0,"peerId":5127921,"className":"AddCommunityCards"};
        //let obj = {"cardNo":4,"cardType":0,"cards":["5d"],"msgNumber":0,"peerId":5127921,"className":"AddCommunityCards"};
        this.view.updateCommunityCardStyle(this.tableSettingsDataModel.getGameSettingData());
        const addCommunityCardsData = this.gameService.getCommunityCardsInfo();
        const cards = this.gameService.getCommunityCardsArray();
        this.view.handleAddCommunityCards(addCommunityCardsData, cards);
    };
    updateSpinUltraLogo = function () {
        const spinCardType = this.gameService.getSpinCardType();
        if (spinCardType) this.view.updateSpinUltraLogo(spinCardType);
    };
    resetGame = function () {
        this.view.resetGame();
    };
    enableCashOutDisableText = function () {
        this.view.enableCashOutDisableText();
    };
}
