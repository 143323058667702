import { Message } from '../../message';

export class PoolPartnerRebuyRequest extends Message {
    private MESSAGE_NAME: string = 'PoolPartnerRebuyRequest';
    private amount: number = 0;
    private entryId: number = 0;
    private includePartnerWallet: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, amount: number = 0, entryId: number = 0, includePartnerWallet: number = 0, conversationId: number = 0) {
        super(reqServerPeerId);
        this.amount = amount;
        this.entryId = entryId;
        this.includePartnerWallet = includePartnerWallet;
        this.conversationId = conversationId;
    }

    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getIncludePartnerWallet(): number {
        return this.includePartnerWallet;
    }

    setIncludePartnerWallet(includePartnerWallet: number) {
        this.includePartnerWallet = includePartnerWallet;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
