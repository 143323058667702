import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'pt-calendar-countdown-timer',
    templateUrl: 'calendar-countdown-timer.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, NgxDatatableModule],
})
export class CalendarCountdownTimerComponent implements OnInit, OnDestroy {
    currenttime: any;
    minutes: number;
    hours: number;
    seconds: number;
    daysleft: number;
    @Input() endTime: any;
    @Input() displayText: any;
    @Input() timerStrings: any;
    @Input() lateRegDate: any;
    @Input() status: any;

    millisecondsleft: number;
    secondsleft: any;
    timeLeft: any;
    callback: any;
    lateRegSecondsLeft: number;
    constructor() {}
    ngOnInit() {
        this.refresh();
        this.callback = setInterval(() => {
            this.refresh();
        }, 1000); /*refresh for every second*/
    }
    private refresh() {
        this.currenttime = new Date();
        this.endTime = new Date(this.endTime);
        this.millisecondsleft = this.endTime - this.currenttime;
        if (this.millisecondsleft > 0) {
            const daysleft = Math.floor(this.millisecondsleft / (24 * 60 * 60 * 1000));
            const hours = Math.floor((this.millisecondsleft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

            const minutes = Math.floor((this.millisecondsleft % (60 * 60 * 1000)) / (60 * 1000));
            const seconds = Math.floor((this.millisecondsleft % (60 * 1000)) / 1000);
            this.formatTimeStr(daysleft, hours, minutes, seconds, this.timerStrings.regStarts);
        } else {
            if (this.status.toLowerCase() == 'registering') {
                this.lateRegSecondsLeft = this.millisecondsleft + 3600000;
            } else {
                this.lateRegDate = new Date(this.lateRegDate);
                this.lateRegSecondsLeft = this.lateRegDate - this.currenttime;
            }
            const daysleft = Math.floor(this.lateRegSecondsLeft / (24 * 60 * 60 * 1000));
            const hours = Math.floor((this.lateRegSecondsLeft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

            const minutes = Math.floor((this.lateRegSecondsLeft % (60 * 60 * 1000)) / (60 * 1000));
            const seconds = Math.floor((this.lateRegSecondsLeft % (60 * 1000)) / 1000);
            if (Math.sign(this.lateRegSecondsLeft) == -1) {
                this.timeLeft = this.timerStrings.regClosed;
            } else {
                this.formatTimeStr(daysleft, hours, minutes, seconds, this.timerStrings.regEnds);
            }
        }
    }

    private formatTimeStr(days: number, hours: number, minutes: number, seconds: number, text: any) {
        if (days > 0) {
            //this.timeLeft = (days <10 ? '0' :'') + days + ':' + (hours <10 ? '0' :'') + hours + ':' + (minutes < 10 ? '0' :'') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            days === 1 ? (this.timeLeft = days + ' ' + this.timerStrings.day) : (this.timeLeft = days + ' ' + this.timerStrings.days);
        } else {
            this.timeLeft =
                text + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        }
    }

    ngOnDestroy() {
        clearInterval(this.callback);
    }
}
