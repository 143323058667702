<div id="info_modal2">
    <div class="modal-header" id="info_header1">
        <div class="common_horizontal_flex info_width">
            <h4 id="balance_amount">{{ 'PARTY_POKER_MOBILE_COMMON_Poker_balance' | translate }}</h4>
            <span class="close theme-ex cross" (click)="cancel()"></span>
        </div>
    </div>
    <!-- body -->
    <div class="modal-body" id="balance_info_body">
        <div id="info_body">
            <!-- scrollable content section -->
            <!-- dynamic values section -->
            <div id="balance_second_sec" class="common_horizontal_flex">
                <div id="left_item">
                    <span class="balance_left_labels cash_before">
                        <span class="p-mainbalance-icon" [ngStyle]="{ 'background-image': 'url(' + balanceIcons.dollar.image.src + ')' }"></span>
                        <span class="mainbalance" *ngIf="!isDecimal">{{ userCurrencySymbol }}{{ mainBalance | number: '1.2-2' }} </span>
                        <span class="mainbalance" *ngIf="isDecimal">{{ userCurrencySymbol }}{{ mainBalance }} </span>
                        <span class="usd">USD</span>
                    </span>
                    <span class="balance_left_labels pamount_before">
                        <span
                            class="p-tourneyBalance-icon"
                            [ngStyle]="{ 'background-image': 'url(' + balanceIcons.gdollar_icon.image.src + ')' }"></span>
                        <span class="tourneyBalance"> {{ tourneyBalance }} </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- footer -->
    <div class="modal-footer" id="balance_info_footer">
        <div class="border-div"></div>
        <div class="balance-total">
            <span class="totalBal"> {{ 'PARTY_POKER_MOBILE_COMMON_Total' | translate }} </span>
            <span class="amount" *ngIf="!availableBalanceIsDecimal">{{ userCurrencySymbol }}{{ totalAvailableBalance | number: '1.2-2' }}</span>
            <span class="amount" *ngIf="availableBalanceIsDecimal">{{ userCurrencySymbol }}{{ totalAvailableBalance }}</span>
        </div>
        <span class="polygon"></span>
    </div>
</div>
