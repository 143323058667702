import { Message } from '../../message';

export class TableStakesInfo extends Message {
    private MESSAGE_NAME: string = 'TableStakesInfo';
    private stakeId: number = 0;
    private brandId: string | null = null;
    private tableCategory: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private lowerStake: number = 0;
    private upperStake: number = 0;
    private smallBlind: number = 0;
    private bigBlind: number = 0;
    private trnyBuyin: number = 0;
    private trnyFee: number = 0;
    private isFFInfo: boolean | null = false;
    private levelId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        stakeId: number = 0,
        brandId: string | null = null,
        tableCategory: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        lowerStake: number = 0,
        upperStake: number = 0,
        smallBlind: number = 0,
        bigBlind: number = 0,
        trnyBuyin: number = 0,
        trnyFee: number = 0,
        isFFInfo: boolean | null = false,
        levelId: number = 0,
    ) {
        super(reqServerPeerId);
        this.stakeId = stakeId;
        this.brandId = brandId;
        this.tableCategory = tableCategory;
        this.gameType = gameType;
        this.limitType = limitType;
        this.lowerStake = lowerStake;
        this.upperStake = upperStake;
        this.smallBlind = smallBlind;
        this.bigBlind = bigBlind;
        this.trnyBuyin = trnyBuyin;
        this.trnyFee = trnyFee;
        this.isFFInfo = isFFInfo;
        this.levelId = levelId;
    }

    getStakeId(): number {
        return this.stakeId;
    }

    setStakeId(stakeId: number) {
        this.stakeId = stakeId;
    }
    getBrandId(): string | null {
        return this.brandId;
    }

    setBrandId(brandId: string | null) {
        this.brandId = brandId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getLowerStake(): number {
        return this.lowerStake;
    }

    setLowerStake(lowerStake: number) {
        this.lowerStake = lowerStake;
    }
    getUpperStake(): number {
        return this.upperStake;
    }

    setUpperStake(upperStake: number) {
        this.upperStake = upperStake;
    }
    getSmallBlind(): number {
        return this.smallBlind;
    }

    setSmallBlind(smallBlind: number) {
        this.smallBlind = smallBlind;
    }
    getBigBlind(): number {
        return this.bigBlind;
    }

    setBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
    }
    getTrnyBuyin(): number {
        return this.trnyBuyin;
    }

    setTrnyBuyin(trnyBuyin: number) {
        this.trnyBuyin = trnyBuyin;
    }
    getTrnyFee(): number {
        return this.trnyFee;
    }

    setTrnyFee(trnyFee: number) {
        this.trnyFee = trnyFee;
    }
    getIsFFInfo(): boolean | null {
        return this.isFFInfo;
    }

    setIsFFInfo(isFFInfo: boolean | null) {
        this.isFFInfo = isFFInfo;
    }
    getLevelId(): number {
        return this.levelId;
    }

    setLevelId(levelId: number) {
        this.levelId = levelId;
    }
}
