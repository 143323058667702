import { Message } from '../../message';

export class PlayersBountyInfo extends Message {
    private MESSAGE_NAME: string = 'PlayersBountyInfo';
    private allPlayersBountyOnHeadValues: Map<any, any> | null = null;
    private specialBountyValues: Map<any, any> | null = null;
    private allPlayersBountyTopUpValues: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        allPlayersBountyOnHeadValues: Map<any, any> | null = null,
        specialBountyValues: Map<any, any> | null = null,
        allPlayersBountyTopUpValues: Map<any, any> | null = null,
    ) {
        super(reqServerPeerId);
        this.allPlayersBountyOnHeadValues = allPlayersBountyOnHeadValues;
        this.specialBountyValues = specialBountyValues;
        this.allPlayersBountyTopUpValues = allPlayersBountyTopUpValues;
    }

    getAllPlayersBountyOnHeadValues(): Map<any, any> | null {
        return this.allPlayersBountyOnHeadValues;
    }

    setAllPlayersBountyOnHeadValues(allPlayersBountyOnHeadValues: Map<any, any> | null) {
        this.allPlayersBountyOnHeadValues = allPlayersBountyOnHeadValues;
    }
    getSpecialBountyValues(): Map<any, any> | null {
        return this.specialBountyValues;
    }

    setSpecialBountyValues(specialBountyValues: Map<any, any> | null) {
        this.specialBountyValues = specialBountyValues;
    }
    getAllPlayersBountyTopUpValues(): Map<any, any> | null {
        return this.allPlayersBountyTopUpValues;
    }

    setAllPlayersBountyTopUpValues(allPlayersBountyTopUpValues: Map<any, any> | null) {
        this.allPlayersBountyTopUpValues = allPlayersBountyTopUpValues;
    }
}
