import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class HANDSTRENGTH extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.HAND_STRENGTH;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        //console.log("handStrength:"+this.handStrength);
        //console.log("player seatno:"+player.seatNo);
        player.handStrength = this.handStrength;
        player.isStrengthShow = true;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prev(gameTable: GameTable): void {
        //const player = gameTable.getPlayer(this.seat);
    }
}
