import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { TrustAsResourceUrlPipe } from '../../pipes/trust-as-resource-url.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';

@Component({
    selector: 'pwc-portal-pages-container',
    templateUrl: 'portal-pages-container.component.html',
    standalone: true,
    imports: [CommonModule, TrustAsResourceUrlPipe],
})
export class PortalPagesContainerComponent extends PCComponent {
    public pageUrl: string;
    private readonly logger: PokerLogger;

    constructor(
        private sharedService: PokerWebClientSharedService,
        loggerFactory: LoggerFactory,
        private navigation: NavigationService,
    ) {
        super();
        this.logger = loggerFactory.getLogger('portalpagescontainercomponentLoggingComponent');
        this.sharedService.getPortalPage().subscribe((pageInfo: any) => {
            pageInfo && pageInfo !== null ? this.setPageUrl(pageInfo) : this.navigation.goTo('/play');
        });
    }

    setPageUrl(pageInfo: any) {
        this.logger.info('pageUrl..!!!!' + JSON.stringify(pageInfo));
        // this.clientToServer.updatePageTitle(pageInfo.text);
        this.sharedService.setPageHeaderTitle({
            title: pageInfo.text,
            url: '/play',
            showBalanceBlock: pageInfo.showBalanceBlock ? pageInfo.showBalanceBlock : false,
        });
        this.pageUrl = window.location.href.includes('dev') ? pageInfo.url.replace(/(qa1|qa2|test)/g, 'dev') : pageInfo.url;
    }
}
