import { CommonModule } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContentService, CookieService, Page, PageMatrixService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ParseRibbonMarkupPipe } from './pk-multi-promo-timer/parse-ribbon-markup.pipe';
import { PkMultiPromoTimerComponent } from './pk-multi-promo-timer/pk-multi-promo-timer.component';

@Component({
    selector: 'poker-discount-ribbon',
    templateUrl: 'poker-discount-ribbon.component.html',
    standalone: true,
    imports: [CommonModule, TrustAsHtmlPipe, RouterModule, ParseRibbonMarkupPipe, PkMultiPromoTimerComponent],
})
export class PokerDiscountRibbonComponent implements OnInit, AfterViewChecked {
    content: any;
    cookieName: string;
    cookieValue: string;
    isEnabled: boolean;

    pageMatrixServiceSub: Subscription;
    isPagematrixReady: boolean;
    containterData: any;
    promoTimerData: any;
    bannerImage: any;
    contentData: any;
    private unsubscribe = new Subject<void>();
    finaldata: any = [];
    stylesData: any;
    showCloseButton: boolean;
    cookiesPresent: boolean = false;

    constructor(
        private contentService: ContentService,
        private cookieService: CookieService,
        private page: Page,
        private pageMatrixService: PageMatrixService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.contentService
            .getJsonFiltered('MobilePoker-v1.0/PublicPages/poker-discount-ribbon')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data: any) => {
                this.contentData = data.content[0]?.items[0];
                this.stylesData = data.content[0]?.items[1];
                this.showCloseButton = this.contentData?.parameters?.showCloseButton == 'true' ? true : false;
                this.checkIsEnabled();
            });
        this.pageMatrixServiceSub = this.pageMatrixService.whenReady.subscribe(() => {
            this.isPagematrixReady = true;
        });
    }

    checkIsEnabled() {
        this.cookieName = 'pp-timer-ribbon-' + this.contentData?.name;
        this.cookieValue = this.page.domain + '-' + this.contentData?.name;
        const getCookieValue = this.cookieService.get(this.cookieName);
        if (getCookieValue && getCookieValue == this.cookieValue) {
            this.cookiesPresent = true;
        } else {
            this.cookiesPresent = false;
        }
    }

    closeRibbon() {
        const expDate = moment().add(1, 'M').toDate();
        document.cookie = this.cookieName + '=' + this.cookieValue + '; domain=' + this.page.domain + '; path=/' + '; expires=' + expDate;
        this.cookiesPresent = true;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getTimerStatus(status: boolean) {
        status ? (this.isEnabled = true) : (this.isEnabled = false);
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
}
