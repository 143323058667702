import { Message } from '../../message';

export class LobbyTableOccupancyChange extends Message {
    private MESSAGE_NAME: string = 'LobbyTableOccupancyChange';
    private tableId: number = 0;
    private playerCount: number = 0;
    private reservedCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, playerCount: number = 0, reservedCount: number = 0) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.playerCount = playerCount;
        this.reservedCount = reservedCount;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getReservedCount(): number {
        return this.reservedCount;
    }

    setReservedCount(reservedCount: number) {
        this.reservedCount = reservedCount;
    }
}
