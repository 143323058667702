import { Message } from '../../message';

export class ResponseChangeBounceEmailSuccess extends Message {
    private MESSAGE_NAME: string = 'ResponseChangeBounceEmailSuccess';
    private msg: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, msg: any | null = null) {
        super(reqServerPeerId);
        this.msg = msg;
    }

    getMsg(): any | null {
        return this.msg;
    }

    setMsg(msg: any | null) {
        this.msg = msg;
    }
}
