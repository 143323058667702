import { Injectable } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import { BehaviorSubject } from 'rxjs';

import { PGNotificationCenter } from './pgBackendConnectors/notificationCenter';
import { CSD } from './pgConnectors/sal/CSD';
import { IConnectorDelegate } from './pgConnectors/sal/interfaces/IconnectorDelegate';
import { Message } from './pgConnectors/server/common/message';

@Injectable({ providedIn: 'root' })
export class PokerWebClientDelegatorService implements IConnectorDelegate {
    isLoaderOpened = false;
    connectionStatusUpdate = new BehaviorSubject<any>(null);
    private readonly logger: PokerLogger;
    constructor(private loggerFactory: LoggerFactory) {
        this.logger = this.loggerFactory.getLogger('PokerWebClientDelegatorServiceLoggingComponent');
    }
    handleMessage(message: Message): void {
        this.logger.info('clicked on other game button...!' + JSON.stringify(message));
        // eslint-disable-next-line no-console
        console.log(message);
        PGNotificationCenter.getInstance().messageEmitter(message.getMessageName(), message.classId, message);
    }

    connectionSuccessforServer(): void {}

    log(cause: number, logMessage: string): void {
        if (cause === CSD.LOG_INFO) {
            this.logger.info(logMessage);
        } else if (cause === CSD.LOG_ERROR) {
            this.logger.error(logMessage);
        } else if (cause === CSD.LOG_WARN) {
            this.logger.warning(logMessage);
        }
    }

    handshakeResponseStatus(): void {
        this.logger.info('handshakeResponseStatus...!');
    }

    updateConnectionStatus(domainId: number, status: number) {
        if (domainId == CSD.REAL) {
            this.connectionStatusUpdate.next(status);
        }
    }
    getConnectionStatus() {
        return this.connectionStatusUpdate.asObservable();
    }
}
