import { Message } from '../../message';

export class LSSTTTableGameStatus extends Message {
    private MESSAGE_NAME: string = 'LSSTTTableGameStatus';
    private tableId: number = 0;
    private tourneyGameStatus: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, tourneyGameStatus: number = 0) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.tourneyGameStatus = tourneyGameStatus;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
}
