/* eslint-disable sonarjs/no-identical-functions */
import { TSDataInputStream } from '../../../sal/dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from '../../../sal/dataInputOutputStream/TSDataOutputStream';
import { Message } from '../message';
import { PGMessageFactory } from '../pgmessagefactory';
import { ATHAutoSitOutMsg } from './messages/ATHAutoSitOutMsg';
import { ATHStrikeHandCountConfiguration } from './messages/ATHStrikeHandCountConfiguration';
import { AcceptChallengeRequest } from './messages/AcceptChallengeRequest';
import { AcceptChallengeResponse } from './messages/AcceptChallengeResponse';
import { AddBuddy } from './messages/AddBuddy';
import { AddCommunityCards } from './messages/AddCommunityCards';
import { AddPlayMoneyToPoolRequest } from './messages/AddPlayMoneyToPoolRequest';
import { AddPlayMoneyToPoolResponse } from './messages/AddPlayMoneyToPoolResponse';
import { AddPlayer } from './messages/AddPlayer';
import { AddedFreeRoll } from './messages/AddedFreeRoll';
import { AllInPot } from './messages/AllInPot';
import { AllInsRemaining } from './messages/AllInsRemaining';
import { AnonymousPlayerInfo } from './messages/AnonymousPlayerInfo';
import { Ante } from './messages/Ante';
import { AppFriendsListRequest } from './messages/AppFriendsListRequest';
import { AppFriendsListResponse } from './messages/AppFriendsListResponse';
import { ArjelBlindsRoundData } from './messages/ArjelBlindsRoundData';
import { ArjelBlindsRoundPlayerData } from './messages/ArjelBlindsRoundPlayerData';
import { ArjelEndOfTheGameEventReq } from './messages/ArjelEndOfTheGameEventReq';
import { ArjelEndOfTheGameEventRes } from './messages/ArjelEndOfTheGameEventRes';
import { ArjelEndOfTheGameSeatData } from './messages/ArjelEndOfTheGameSeatData';
import { ArjelGTPlayerData } from './messages/ArjelGTPlayerData';
import { ArjelGameAllPlayersAction } from './messages/ArjelGameAllPlayersAction';
import { ArjelGameCards } from './messages/ArjelGameCards';
import { ArjelGameLongValue } from './messages/ArjelGameLongValue';
import { ArjelGamePlayerAction } from './messages/ArjelGamePlayerAction';
import { ArjelGameSummary } from './messages/ArjelGameSummary';
import { ArjelGameSummaryPlayerInfo } from './messages/ArjelGameSummaryPlayerInfo';
import { ArjelPlayerCards } from './messages/ArjelPlayerCards';
import { AutoBuyInError } from './messages/AutoBuyInError';
import { AutoOption } from './messages/AutoOption';
import { AutoOptionSet } from './messages/AutoOptionSet';
import { AutoOptionToOptionsMapping } from './messages/AutoOptionToOptionsMapping';
import { AutoOptionToOptionsMappings } from './messages/AutoOptionToOptionsMappings';
import { BackToPoolRequest } from './messages/BackToPoolRequest';
import { BeginnerStatus } from './messages/BeginnerStatus';
import { BetLimitErrorMessage } from './messages/BetLimitErrorMessage';
import { BigWinNotification } from './messages/BigWinNotification';
import { Blind } from './messages/Blind';
import { BlockBuddy } from './messages/BlockBuddy';
import { BlockPlayerOnInvalidLocation } from './messages/BlockPlayerOnInvalidLocation';
import { BlockSeat } from './messages/BlockSeat';
import { BlockSeatCancel } from './messages/BlockSeatCancel';
import { BlockSeatResponse } from './messages/BlockSeatResponse';
import { BoosterWinners } from './messages/BoosterWinners';
import { BroadcastShowRabbit } from './messages/BroadcastShowRabbit';
import { BroadcastTableBoosterTrigger } from './messages/BroadcastTableBoosterTrigger';
import { Buddy } from './messages/Buddy';
import { BuddyList } from './messages/BuddyList';
import { BuddyResponse } from './messages/BuddyResponse';
import { BuyMoreChips } from './messages/BuyMoreChips';
import { BuyinRefundStatus } from './messages/BuyinRefundStatus';
import { CalendarAlert } from './messages/CalendarAlert';
import { CanJoinPrivateTableRequest } from './messages/CanJoinPrivateTableRequest';
import { CanJoinPrivateTableResponse } from './messages/CanJoinPrivateTableResponse';
import { CanLeaveTable } from './messages/CanLeaveTable';
import { CanLeaveTableResponse } from './messages/CanLeaveTableResponse';
import { CanPlayerRegister } from './messages/CanPlayerRegister';
import { CanPlayerRegisterResponse } from './messages/CanPlayerRegisterResponse';
import { CanSitOut } from './messages/CanSitOut';
import { CanSitOutResponse } from './messages/CanSitOutResponse';
import { CasinoTrnyLevelInfo } from './messages/CasinoTrnyLevelInfo';
import { ChallengeCancelledMessage } from './messages/ChallengeCancelledMessage';
import { ChallengeDetails } from './messages/ChallengeDetails';
import { ChallengeHistoryData } from './messages/ChallengeHistoryData';
import { ChallengeHistoryRequest } from './messages/ChallengeHistoryRequest';
import { ChallengeHistoryResponse } from './messages/ChallengeHistoryResponse';
import { ChallengeInvitationRequest } from './messages/ChallengeInvitationRequest';
import { ChallengeTemplate } from './messages/ChallengeTemplate';
import { ChallengeTemplatesList } from './messages/ChallengeTemplatesList';
import { ChangeInLevel } from './messages/ChangeInLevel';
import { ChangePlayerState } from './messages/ChangePlayerState';
import { Chat } from './messages/Chat';
import { ChatOffMessage } from './messages/ChatOffMessage';
import { ClosePoolEntry } from './messages/ClosePoolEntry';
import { CloseTable } from './messages/CloseTable';
import { ClubNameApproved } from './messages/ClubNameApproved';
import { CompositeLobbyMessage } from './messages/CompositeLobbyMessage';
import { CompositeTableMessage } from './messages/CompositeTableMessage';
import { ContentLockingAccessDeniedResponse } from './messages/ContentLockingAccessDeniedResponse';
import { CountryBlockEventRequest } from './messages/CountryBlockEventRequest';
import { CountryBlockEventResponse } from './messages/CountryBlockEventResponse';
import { CreateChallengeHUCashRequest } from './messages/CreateChallengeHUCashRequest';
import { CreateChallengeHUCashResponse } from './messages/CreateChallengeHUCashResponse';
import { CreateChallengeHUSNGRequest } from './messages/CreateChallengeHUSNGRequest';
import { CreateChallengeHUSNGResponse } from './messages/CreateChallengeHUSNGResponse';
import { CreatePrivateTableRequest } from './messages/CreatePrivateTableRequest';
import { CreatePrivateTableResponse } from './messages/CreatePrivateTableResponse';
import { CreatePvtTable } from './messages/CreatePvtTable';
import { CreatePvtTableResponse } from './messages/CreatePvtTableResponse';
import { CurrentPlayer } from './messages/CurrentPlayer';
import { CurrentPlayerMTCTRegistrations } from './messages/CurrentPlayerMTCTRegistrations';
import { DealCards } from './messages/DealCards';
import { DealConfirmedPlayerCount } from './messages/DealConfirmedPlayerCount';
import { DealHighCards } from './messages/DealHighCards';
import { DealMakingAcceptPlayerCount } from './messages/DealMakingAcceptPlayerCount';
import { DealMakingChat } from './messages/DealMakingChat';
import { DealMakingChatRequest } from './messages/DealMakingChatRequest';
import { DealMakingConfirmData } from './messages/DealMakingConfirmData';
import { DealMakingDetails } from './messages/DealMakingDetails';
import { DealMakingPayoutData } from './messages/DealMakingPayoutData';
import { DealMakingPostDealPayout } from './messages/DealMakingPostDealPayout';
import { DealMakingSelectedPayout } from './messages/DealMakingSelectedPayout';
import { DealMakingSubmitDeal } from './messages/DealMakingSubmitDeal';
import { DealMakingTrnyRank } from './messages/DealMakingTrnyRank';
import { DealPlayerData } from './messages/DealPlayerData';
import { Dealer } from './messages/Dealer';
import { DealerSbAndBbPositions } from './messages/DealerSbAndBbPositions';
import { DeletePrivateTableRequest } from './messages/DeletePrivateTableRequest';
import { DeletePrivateTableResponse } from './messages/DeletePrivateTableResponse';
import { DepositedFriendCount } from './messages/DepositedFriendCount';
import { DiamondBalance } from './messages/DiamondBalance';
import { DisconnectedPlayersList } from './messages/DisconnectedPlayersList';
import { DoPlayNow } from './messages/DoPlayNow';
import { EnableChat } from './messages/EnableChat';
import { EnableTimeBank } from './messages/EnableTimeBank';
import { EndTheRound } from './messages/EndTheRound';
import { FRONTALJoinPoolResponse } from './messages/FRONTALJoinPoolResponse';
import { FRONTALLeavePoolResponse } from './messages/FRONTALLeavePoolResponse';
import { FRONTALPoolRebuyResponse } from './messages/FRONTALPoolRebuyResponse';
import { FRSessionTimer } from './messages/FRSessionTimer';
import { FXConversionData } from './messages/FXConversionData';
import { FoldNShowOption } from './messages/FoldNShowOption';
import { ForceLogOutRequest } from './messages/ForceLogOutRequest';
import { GameCurrencyDetailsList } from './messages/GameCurrencyDetailsList';
import { GameStatus } from './messages/GameStatus';
import { GameTableStatus } from './messages/GameTableStatus';
import { GamingSessionTimedOutPopUp } from './messages/GamingSessionTimedOutPopUp';
import { GamingSessionTimedOutPopUpAck } from './messages/GamingSessionTimedOutPopUpAck';
import { GeneralRequestAddMorePlayMoney } from './messages/GeneralRequestAddMorePlayMoney';
import { GetPokerDiamondBalance } from './messages/GetPokerDiamondBalance';
import { GiveTourneyChips } from './messages/GiveTourneyChips';
import { GlobalWaitlistCriteria } from './messages/GlobalWaitlistCriteria';
import { HUDAnonymousState } from './messages/HUDAnonymousState';
import { HUEvenGame } from './messages/HUEvenGame';
import { HUPairTableSelectStatus } from './messages/HUPairTableSelectStatus';
import { HUSNGRankResultWithRematchRequest } from './messages/HUSNGRankResultWithRematchRequest';
import { HandHistory } from './messages/HandHistory';
import { HandStrength } from './messages/HandStrength';
import { HighlightWinCards } from './messages/HighlightWinCards';
import { HyperLinkedDealerChat } from './messages/HyperLinkedDealerChat';
import { ITMAdditionalInformation } from './messages/ITMAdditionalInformation';
import { ITMInformation } from './messages/ITMInformation';
import { Invitation } from './messages/Invitation';
import { InviteBuddy } from './messages/InviteBuddy';
import { InviteBuddyRequest } from './messages/InviteBuddyRequest';
import { ItaliaAuthorizationCodes } from './messages/ItaliaAuthorizationCodes';
import { ItaliaAuthorizationCodesForTable } from './messages/ItaliaAuthorizationCodesForTable';
import { JackpotAmount } from './messages/JackpotAmount';
import { JackpotAmounts } from './messages/JackpotAmounts';
import { JackpotHitTime } from './messages/JackpotHitTime';
import { JackpotSnapshots } from './messages/JackpotSnapshots';
import { JoinPoolRequest } from './messages/JoinPoolRequest';
import { JoinPoolResponse } from './messages/JoinPoolResponse';
import { JoinTable } from './messages/JoinTable';
import { JoinTableAutoBuyIn } from './messages/JoinTableAutoBuyIn';
import { JoinTableQS } from './messages/JoinTableQS';
import { JoinTableResponse } from './messages/JoinTableResponse';
import { JoinTableSTTReplay } from './messages/JoinTableSTTReplay';
import { JoinWaitlist } from './messages/JoinWaitlist';
import { JoinWaitlistRefresh } from './messages/JoinWaitlistRefresh';
import { JoinWaitlistResponse } from './messages/JoinWaitlistResponse';
import { LeavePoolRequest } from './messages/LeavePoolRequest';
import { LimitGameOption } from './messages/LimitGameOption';
import { LobbyBigBlindChange } from './messages/LobbyBigBlindChange';
import { LobbyFont } from './messages/LobbyFont';
import { LobbyTableDataChange } from './messages/LobbyTableDataChange';
import { LobbyTableOccupancyChange } from './messages/LobbyTableOccupancyChange';
import { LoginSessionTimeoutIntimation } from './messages/LoginSessionTimeoutIntimation';
import { LossLimitDetails } from './messages/LossLimitDetails';
import { LossLimitResponse } from './messages/LossLimitResponse';
import { MTCTCustomPropertyChangeEvent } from './messages/MTCTCustomPropertyChangeEvent';
import { MTCTDeleteEvent } from './messages/MTCTDeleteEvent';
import { MTCTDetails } from './messages/MTCTDetails';
import { MTCTDoRebuyAddon } from './messages/MTCTDoRebuyAddon';
import { MTCTDynaLiteralUpdate } from './messages/MTCTDynaLiteralUpdate';
import { MTCTLevel } from './messages/MTCTLevel';
import { MTCTLevelChangeEvent } from './messages/MTCTLevelChangeEvent';
import { MTCTLobbyData } from './messages/MTCTLobbyData';
import { MTCTLobbySnapshotChange } from './messages/MTCTLobbySnapshotChange';
import { MTCTLobbyToOpen } from './messages/MTCTLobbyToOpen';
import { MTCTParticipantNames } from './messages/MTCTParticipantNames';
import { MTCTParticipantNamesOnRequest } from './messages/MTCTParticipantNamesOnRequest';
import { MTCTPlayerCountChangeEvent } from './messages/MTCTPlayerCountChangeEvent';
import { MTCTPlayerKickedOut } from './messages/MTCTPlayerKickedOut';
import { MTCTPrizeInfo } from './messages/MTCTPrizeInfo';
import { MTCTPrizePoolChangeEvent } from './messages/MTCTPrizePoolChangeEvent';
import { MTCTRebuyAddonDialogInfo } from './messages/MTCTRebuyAddonDialogInfo';
import { MTCTRebuyAddonInfo } from './messages/MTCTRebuyAddonInfo';
import { MTCTRebuyAddonStatus } from './messages/MTCTRebuyAddonStatus';
import { MTCTRegister } from './messages/MTCTRegister';
import { MTCTRegistrationChangeEvent } from './messages/MTCTRegistrationChangeEvent';
import { MTCTRequestRebuyAddonDialogInfo } from './messages/MTCTRequestRebuyAddonDialogInfo';
import { MTCTResponse } from './messages/MTCTResponse';
import { MTCTStatusChangeEvent } from './messages/MTCTStatusChangeEvent';
import { MTCTTableStatus } from './messages/MTCTTableStatus';
import { MTCTTourneyInfo } from './messages/MTCTTourneyInfo';
import { MTCTTourneyStatus } from './messages/MTCTTourneyStatus';
import { MTCTUnRegister } from './messages/MTCTUnRegister';
import { MTTPlayerAutoRebuyAddonPreferences } from './messages/MTTPlayerAutoRebuyAddonPreferences';
import { MTTournamentFinalTable } from './messages/MTTournamentFinalTable';
import { MegaPotPrize } from './messages/MegaPotPrize';
import { MessageOnTable } from './messages/MessageOnTable';
import { MorePlayMoneyGeneralResponse } from './messages/MorePlayMoneyGeneralResponse';
import { MorePlayMoneyResponse } from './messages/MorePlayMoneyResponse';
import { MoveToTable } from './messages/MoveToTable';
import { MsgTrafficStats } from './messages/MsgTrafficStats';
import { MuckCards } from './messages/MuckCards';
import { MultiConnectionPostLoginSyncupMessage } from './messages/MultiConnectionPostLoginSyncupMessage';
import { NetPositionDetails } from './messages/NetPositionDetails';
import { NewGame } from './messages/NewGame';
import { NotifyPrivateTableCreator } from './messages/NotifyPrivateTableCreator';
import { Option } from './messages/Option';
import { OptionSet } from './messages/OptionSet';
import { OptionSetAck } from './messages/OptionSetAck';
import { PPFont } from './messages/PPFont';
import { Payout } from './messages/Payout';
import { PeerTables } from './messages/PeerTables';
import { PlayedGamesCount } from './messages/PlayedGamesCount';
import { PlayerATHStrike } from './messages/PlayerATHStrike';
import { PlayerAccountType } from './messages/PlayerAccountType';
import { PlayerBalance } from './messages/PlayerBalance';
import { PlayerBalanceInfo } from './messages/PlayerBalanceInfo';
import { PlayerBalances } from './messages/PlayerBalances';
import { PlayerCashedOutEquityStatus } from './messages/PlayerCashedOutEquityStatus';
import { PlayerCashoutEquityDetails } from './messages/PlayerCashoutEquityDetails';
import { PlayerDealCards } from './messages/PlayerDealCards';
import { PlayerEntryStatus } from './messages/PlayerEntryStatus';
import { PlayerGameSessionData } from './messages/PlayerGameSessionData';
import { PlayerGameSessionLimitsStatusRequest } from './messages/PlayerGameSessionLimitsStatusRequest';
import { PlayerGameSessionLimitsStatusResponse } from './messages/PlayerGameSessionLimitsStatusResponse';
import { PlayerGameSessionRemainingLimitsRequest } from './messages/PlayerGameSessionRemainingLimitsRequest';
import { PlayerGameSessionRemainingLimitsStatusResponse } from './messages/PlayerGameSessionRemainingLimitsStatusResponse ';
import { PlayerGameSessionReminder } from './messages/PlayerGameSessionReminder';
import { PlayerSearchResult } from './messages/PlayerSearchResult';
import { PlayerSearchResults } from './messages/PlayerSearchResults';
import { PlayerStates } from './messages/PlayerStates';
import { PlayerStatus } from './messages/PlayerStatus';
import { PlayerStatuses } from './messages/PlayerStatuses';
import { PlayerTableList } from './messages/PlayerTableList';
import { PlayerTourneyRank } from './messages/PlayerTourneyRank';
import { PlayerVerificationStatus } from './messages/PlayerVerificationStatus';
import { PlayersBountyInfo } from './messages/PlayersBountyInfo';
import { PlayersBountyWonInfo } from './messages/PlayersBountyWonInfo';
import { PlayersCashoutEquityDetails } from './messages/PlayersCashoutEquityDetails';
import { PlayersChipCount } from './messages/PlayersChipCount';
import { PokerChatRequest } from './messages/PokerChatRequest';
import { PokerPlayerProtectionInfo } from './messages/PokerPlayerProtectionInfo';
import { PoolAvailabilityRequest } from './messages/PoolAvailabilityRequest';
import { PoolAvailabilityResponse } from './messages/PoolAvailabilityResponse';
import { PoolEndGameNotification } from './messages/PoolEndGameNotification';
import { PoolForcedSitOutNotification } from './messages/PoolForcedSitOutNotification';
import { PoolFraudPlayerKickoutNotification } from './messages/PoolFraudPlayerKickoutNotification';
import { PoolItaliaAuthorizationCodes } from './messages/PoolItaliaAuthorizationCodes';
import { PoolKickoutNotification } from './messages/PoolKickoutNotification';
import { PoolPartnerRebuyRequest } from './messages/PoolPartnerRebuyRequest';
import { PoolRebuyRequest } from './messages/PoolRebuyRequest';
import { PoolRebuyResponse } from './messages/PoolRebuyResponse';
import { PoolRequestUserBalance } from './messages/PoolRequestUserBalance';
import { PoolRequestUserBalancePartnerPlayer } from './messages/PoolRequestUserBalancePartnerPlayer';
import { PoolRequestUserReconnect } from './messages/PoolRequestUserReconnect';
import { PoolShutdownNotification } from './messages/PoolShutdownNotification';
import { PoolSitOutNotification } from './messages/PoolSitOutNotification';
import { PoolStartGameNotification } from './messages/PoolStartGameNotification';
import { PoolUserAllBalance } from './messages/PoolUserAllBalance';
import { PoolUserBalance } from './messages/PoolUserBalance';
import { PoolUserReconnectResponse } from './messages/PoolUserReconnectResponse';
import { PoolWaitingNotification } from './messages/PoolWaitingNotification';
import { PostAnte } from './messages/PostAnte';
import { PostBlind } from './messages/PostBlind';
import { PostStayIn } from './messages/PostStayIn';
import { PotWinners } from './messages/PotWinners';
import { PrePromoTicker } from './messages/PrePromoTicker';
import { PrevPlayingFastPokerPools } from './messages/PrevPlayingFastPokerPools';
import { PrevPlayingGTs } from './messages/PrevPlayingGTs';
import { PrivateTableInvitationRequest } from './messages/PrivateTableInvitationRequest';
import { ProPlayerList } from './messages/ProPlayerList';
import { ProbabilityOfWinning } from './messages/ProbabilityOfWinning';
import { ProgressiveBountyAnimationInfo } from './messages/ProgressiveBountyAnimationInfo';
import { PromoUpdateTicker } from './messages/PromoUpdateTicker';
import { PromoWinnerPlayerList } from './messages/PromoWinnerPlayerList';
import { PvtTableType } from './messages/PvtTableType';
import { PvtTableTypes } from './messages/PvtTableTypes';
import { QFToAnyBetPlayerQuickFolded } from './messages/QFToAnyBetPlayerQuickFolded';
import { QSBlockSeatCancel } from './messages/QSBlockSeatCancel';
import { QSError } from './messages/QSError';
import { QSParam } from './messages/QSParam';
import { QSRequest } from './messages/QSRequest';
import { QSResponse } from './messages/QSResponse';
import { QSResult } from './messages/QSResult';
import { RCPUActionAcknowledgement } from './messages/RCPUActionAcknowledgement';
import { RGLimitErrorMsg } from './messages/RGLimitErrorMsg';
import { RatHoleResponse } from './messages/RatHoleResponse';
import { ReadLatestRandomSeatingFlag } from './messages/ReadLatestRandomSeatingFlag';
import { RealityCheckSummaryPopUp } from './messages/RealityCheckSummaryPopUp';
import { RealityCheckSummaryPopUpAck } from './messages/RealityCheckSummaryPopUpAck';
import { RecommendedStakeLimit } from './messages/RecommendedStakeLimit';
import { RecommendedStakesMessage } from './messages/RecommendedStakesMessage';
import { ReduceChips } from './messages/ReduceChips';
import { ReduceChipsResponse } from './messages/ReduceChipsResponse';
import { RefreshTablesList } from './messages/RefreshTablesList';
import { RejectChallengeRequest } from './messages/RejectChallengeRequest';
import { RejectChallengeResponse } from './messages/RejectChallengeResponse';
import { RelocateTable } from './messages/RelocateTable';
import { RemoveBuddy } from './messages/RemoveBuddy';
import { RemoveLobbyTable } from './messages/RemoveLobbyTable';
import { RemovePlayNow } from './messages/RemovePlayNow';
import { RemovePlayer } from './messages/RemovePlayer';
import { RemoveTourneyPauseInfo } from './messages/RemoveTourneyPauseInfo';
import { RemovedFreeRoll } from './messages/RemovedFreeRoll';
import { ReqEnablePlayerRunittwiceState } from './messages/ReqEnablePlayerRunittwiceState';
import { ReqEnableRabbit } from './messages/ReqEnableRabbit';
import { ReqNextHandOffFoldAndWatch } from './messages/ReqNextHandOffFoldAndWatch';
import { ReqNextHandOnFoldAndWatch } from './messages/ReqNextHandOnFoldAndWatch';
import { ReqQuickFoldToBetOrRaiseOff } from './messages/ReqQuickFoldToBetOrRaiseOff';
import { ReqQuickFoldToBetOrRaiseOn } from './messages/ReqQuickFoldToBetOrRaiseOn';
import { ReqShowEmoji } from './messages/ReqShowEmoji';
import { ReqSitoutNextBlindOff } from './messages/ReqSitoutNextBlindOff';
import { ReqSitoutNextBlindOn } from './messages/ReqSitoutNextBlindOn';
import { ReqThrowAnimation } from './messages/ReqThrowAnimation';
import { RequestAddMorePlayMoney } from './messages/RequestAddMorePlayMoney';
import { RequestAdviseToBuyMoreChips } from './messages/RequestAdviseToBuyMoreChips';
import { RequestAutoCheckOff } from './messages/RequestAutoCheckOff';
import { RequestAutoCheckOn } from './messages/RequestAutoCheckOn';
import { RequestAutoOptionsOptionsMappings } from './messages/RequestAutoOptionsOptionsMappings';
import { RequestAutoPostBlindAndFoldOn } from './messages/RequestAutoPostBlindAndFoldOn';
import { RequestAutoPostBlindOff } from './messages/RequestAutoPostBlindOff';
import { RequestAutoPostBlindOn } from './messages/RequestAutoPostBlindOn';
import { RequestAutoRebuyOn } from './messages/RequestAutoRebuyOn';
import { RequestAutopostBlindAndFoldOff } from './messages/RequestAutopostBlindAndFoldOff';
import { RequestAutorebuyOff } from './messages/RequestAutorebuyOff';
import { RequestBuddiesList } from './messages/RequestBuddiesList';
import { RequestCashoutEquity } from './messages/RequestCashoutEquity';
import { RequestChatOffDisabled } from './messages/RequestChatOffDisabled';
import { RequestChatOffEnabled } from './messages/RequestChatOffEnabled';
import { RequestCloseClient } from './messages/RequestCloseClient';
import { RequestCloseGameTable } from './messages/RequestCloseGameTable';
import { RequestCurrentPlayerRegistration } from './messages/RequestCurrentPlayerRegistration';
import { RequestDealMakingAllConfirmed } from './messages/RequestDealMakingAllConfirmed';
import { RequestDealMakingConfirm } from './messages/RequestDealMakingConfirm';
import { RequestDealMakingEnded } from './messages/RequestDealMakingEnded';
import { RequestDealMakingExit } from './messages/RequestDealMakingExit';
import { RequestDealMakingGameClosed } from './messages/RequestDealMakingGameClosed';
import { RequestDealMakingGameToContinue } from './messages/RequestDealMakingGameToContinue';
import { RequestDealMakingHide } from './messages/RequestDealMakingHide';
import { RequestDealMakingNo } from './messages/RequestDealMakingNo';
import { RequestDealMakingPayoutVetoed } from './messages/RequestDealMakingPayoutVetoed';
import { RequestDealMakingReject } from './messages/RequestDealMakingReject';
import { RequestDealMakingStarted } from './messages/RequestDealMakingStarted';
import { RequestDealMakingTimeout } from './messages/RequestDealMakingTimeout';
import { RequestDealMakingVetoed } from './messages/RequestDealMakingVetoed';
import { RequestDealMakingView } from './messages/RequestDealMakingView';
import { RequestDealMakingYes } from './messages/RequestDealMakingYes';
import { RequestEndOfTbt } from './messages/RequestEndOfTbt';
import { RequestEndOfTheGame } from './messages/RequestEndOfTheGame';
import { RequestEndOfTheGameFastPoker } from './messages/RequestEndOfTheGameFastPoker';
import { RequestFoldAndWatchOff } from './messages/RequestFoldAndWatchOff';
import { RequestFoldAndWatchOn } from './messages/RequestFoldAndWatchOn';
import { RequestFoldToAnyBetOff } from './messages/RequestFoldToAnyBetOff';
import { RequestFoldToAnyBetOn } from './messages/RequestFoldToAnyBetOn';
import { RequestGameStatus } from './messages/RequestGameStatus';
import { RequestHUPairTable } from './messages/RequestHUPairTable';
import { RequestHUSNGRematchAccepted } from './messages/RequestHUSNGRematchAccepted';
import { RequestHUSNGRematchAcceptedAck } from './messages/RequestHUSNGRematchAcceptedAck';
import { RequestHUSNGRematchRejected } from './messages/RequestHUSNGRematchRejected';
import { RequestJoinGlobalWaitlist } from './messages/RequestJoinGlobalWaitlist';
import { RequestJoinOtherDomainWaitlist } from './messages/RequestJoinOtherDomainWaitlist';
import { RequestJoinTableAutoBuyin } from './messages/RequestJoinTableAutoBuyin';
import { RequestJoinTableWaitlist } from './messages/RequestJoinTableWaitlist';
import { RequestKillGametableSilently } from './messages/RequestKillGametableSilently';
import { RequestLeaveTable } from './messages/RequestLeaveTable';
import { RequestListOfChallengeTemplates } from './messages/RequestListOfChallengeTemplates';
import { RequestListOfPVTTableTypes } from './messages/RequestListOfPVTTableTypes';
import { RequestMTCTParticipantNamesOnSearchString } from './messages/RequestMTCTParticipantNamesOnSearchString';
import { RequestMtctRebuyDialogCancel } from './messages/RequestMtctRebuyDialogCancel';
import { RequestMuckLosingHandOff } from './messages/RequestMuckLosingHandOff';
import { RequestMuckLosingHandOn } from './messages/RequestMuckLosingHandOn';
import { RequestNoBuddiesList } from './messages/RequestNoBuddiesList';
import { RequestPlayerNoteId } from './messages/RequestPlayerNoteId';
import { RequestPoolSitout } from './messages/RequestPoolSitOut';
import { RequestRealName } from './messages/RequestRealName';
import { RequestRegainPlayer } from './messages/RequestRegainPlayer';
import { RequestSendJackpotSnapshots } from './messages/RequestSendJackpotSnapshots';
import { RequestServerClosckReSync } from './messages/RequestServerClosckReSync';
import { RequestSessionLimitsData } from './messages/RequestSessionLimitsData';
import { RequestShowSitButtons } from './messages/RequestShowSitButtons';
import { RequestSitoutAtNextBBOff } from './messages/RequestSitoutAtNextBBOff';
import { RequestSitoutAtNextBBOn } from './messages/RequestSitoutAtNextBBOn';
import { RequestSitoutNextHandOff } from './messages/RequestSitoutNextHandOff';
import { RequestSitoutNextHandOn } from './messages/RequestSitoutNextHandOn';
import { RequestTableLimitBalance } from './messages/RequestTableLimitBalance';
import { RequestTournamentHH } from './messages/RequestTournamentHH';
import { RequestTourneyPrizeInfo } from './messages/RequestTourneyPrizeInfo';
import { RequestUnjoinGlobalWaitlist } from './messages/RequestUnjoinGlobalWaitlist';
import { RequestUnjoinOtherDomainWaitlist } from './messages/RequestUnjoinOtherDomainWaitlist';
import { RequestUnjoinTableWaitlist } from './messages/RequestUnjoinTableWaitlist';
import { RequestUserBalance } from './messages/RequestUserBalance';
import { RequestUserBalancePartnerPlayer } from './messages/RequestUserBalancePartnerPlayer';
import { RequestUserClubDetails } from './messages/RequestUserClubDetails';
import { RequestWaitForBigBlindOn } from './messages/RequestWaitForBigBlindOn';
import { RequestWaitforBigblindOff } from './messages/RequestWaitforBigblindOff';
import { RequestWaitingPlayers } from './messages/RequestWaitingPlayers';
import { ResThrowAnimation } from './messages/ResThrowAnimation';
import { ReserveSeat } from './messages/ReserveSeat';
import { ResponseAddonFailureAddonExceeded } from './messages/ResponseAddonFailureAddonExceeded';
import { ResponseAddonFailureDBError } from './messages/ResponseAddonFailureDBError';
import { ResponseAddonFailureInsufficientBalance } from './messages/ResponseAddonFailureInsufficientBalance';
import { ResponseAddonRebuyFailure } from './messages/ResponseAddonRebuyFailure';
import { ResponseAddonSuccess } from './messages/ResponseAddonSuccess';
import { ResponseAllinResetFailed } from './messages/ResponseAllinResetFailed';
import { ResponseAllinResetNotRequired } from './messages/ResponseAllinResetNotRequired';
import { ResponseAllinResetPending } from './messages/ResponseAllinResetPending';
import { ResponseAllinResetSuccess } from './messages/ResponseAllinResetSuccess';
import { ResponseBetAndWinSummary } from './messages/ResponseBetAndWinSummary';
import { ResponseBuyMoreChipsAllowedAmount } from './messages/ResponseBuyMoreChipsAllowedAmount';
import { ResponseBuyMoreChipsDBError } from './messages/ResponseBuyMoreChipsDBError';
import { ResponseBuyMoreChipsGeoLocationBlocked } from './messages/ResponseBuyMoreChipsGeoLocationBlocked';
import { ResponseBuyMoreChipsInsuffPartnerFund } from './messages/ResponseBuyMoreChipsInsuffPartnerFund';
import { ResponseBuyMoreChipsInsuffPokerFund } from './messages/ResponseBuyMoreChipsInsuffPokerFund';
import { ResponseBuyMoreChipsLossLimitExceeded } from './messages/ResponseBuyMoreChipsLossLimitExceeded';
import { ResponseBuyMoreChipsMultiple } from './messages/ResponseBuyMoreChipsMultiple';
import { ResponseBuyMoreChipsNotAPlayer } from './messages/ResponseBuyMoreChipsNotAPlayer';
import { ResponseBuyMoreChipsNotAllowed } from './messages/ResponseBuyMoreChipsNotAllowed';
import { ResponseBuyMoreChipsNotAvailable } from './messages/ResponseBuyMoreChipsNotAvailable';
import { ResponseBuyMoreChipsPlaying } from './messages/ResponseBuyMoreChipsPlaying';
import { ResponseBuyMoreChipsPokerTxnError } from './messages/ResponseBuyMoreChipsPokerTxnError';
import { ResponseBuyMoreChipsSessiontimeExpired } from './messages/ResponseBuyMoreChipsSessiontimeExpired';
import { ResponseBuyMoreChipsSuccess } from './messages/ResponseBuyMoreChipsSuccess';
import { ResponseBuyMoreChipsWalletTxnError } from './messages/ResponseBuyMoreChipsWalletTxnError';
import { ResponseCashoutEquity } from './messages/ResponseCashoutEquity';
import { ResponseChatRequestPermissionDenied } from './messages/ResponseChatRequestPermissionDenied';
import { ResponseDealMakingConfirmSuccess } from './messages/ResponseDealMakingConfirmSuccess';
import { ResponseDealMakingRejectSuccess } from './messages/ResponseDealMakingRejectSuccess';
import { ResponseDealMakingSubmitFailure } from './messages/ResponseDealMakingSubmitFailure';
import { ResponseDealMakingSubmitSuccess } from './messages/ResponseDealMakingSubmitSuccess';
import { ResponseElapsedTime } from './messages/ResponseElapsedTime';
import { ResponseFoldNShowBoth } from './messages/ResponseFoldNShowBoth';
import { ResponseFoldNShowCards } from './messages/ResponseFoldNShowCards';
import { ResponseFoldNShowFirstCard } from './messages/ResponseFoldNShowFirstCard';
import { ResponseFoldNShowSecondCard } from './messages/ResponseFoldNShowSecondCard';
import { ResponseGeneralErrorMessage } from './messages/ResponseGeneralErrorMessage';
import { ResponseGeolocationStatus } from './messages/ResponseGeolocationStatus';
import { ResponseGermanTaxValue } from './messages/ResponseGermanTaxValue';
import { ResponseHURematchAccepted } from './messages/ResponseHURematchAccepted';
import { ResponseHURematchRejected } from './messages/ResponseHURematchRejected';
import { ResponseHURematchTimeout } from './messages/ResponseHURematchTimeout';
import { ResponseHUSNGRematchAccepted } from './messages/ResponseHUSNGRematchAccepted';
import { ResponseHUSNGRematchBuyInFailed } from './messages/ResponseHUSNGRematchBuyInFailed';
import { ResponseHUSNGRematchRejected } from './messages/ResponseHUSNGRematchRejected';
import { ResponseHUSNGRematchTimeout } from './messages/ResponseHUSNGRematchTimeout';
import { ResponseHandHistoryFail } from './messages/ResponseHandHistoryFail';
import { ResponseHandHistorySuccess } from './messages/ResponseHandHistorySuccess';
import { ResponseJPWelcomeMsg } from './messages/ResponseJPWelcomeMsg';
import { ResponseJoinGlobalWaitlist } from './messages/ResponseJoinGlobalWaitlist';
import { ResponseJoinOtherDomainWaitlist } from './messages/ResponseJoinOtherDomainWaitlist';
import { ResponseJoinTableWaitlist } from './messages/ResponseJoinTableWaitlist';
import { ResponseLossLimitExceed } from './messages/ResponseLossLimitExceed';
import { ResponseNOBuddiesListSuccess } from './messages/ResponseNOBuddiesListSuccess';
import { ResponsePlayerNoteId } from './messages/ResponsePlayerNoteId';
import { ResponseRealName } from './messages/ResponseRealName';
import { ResponseRebuyAddonAccClosureFailure } from './messages/ResponseRebuyAddonAccClosureFailure';
import { ResponseRebuyAddonClosed } from './messages/ResponseRebuyAddonClosed';
import { ResponseRebuyAddonGenericFailure } from './messages/ResponseRebuyAddonGenericFailure';
import { ResponseRebuyFailureDBError } from './messages/ResponseRebuyFailureDBError';
import { ResponseRebuyFailureImproperChipBalance } from './messages/ResponseRebuyFailureImproperChipBalance';
import { ResponseRebuyFailureInsufficientBalance } from './messages/ResponseRebuyFailureInsufficientBalance';
import { ResponseRebuyFailureRebuysExceeded } from './messages/ResponseRebuyFailureRebuysExceeded';
import { ResponseRebuyGenericFailure } from './messages/ResponseRebuyGenericFailure';
import { ResponseRebuySuccess } from './messages/ResponseRebuySuccess';
import { ResponseRebuySuccessPending } from './messages/ResponseRebuySuccessPending';
import { ResponseReceivedExpiredOptions } from './messages/ResponseReceivedExpiredOptions';
import { ResponseRegainPlayerFailed } from './messages/ResponseRegainPlayerFailed';
import { ResponseRegainPlayerSuccess } from './messages/ResponseRegainPlayerSuccess';
import { ResponseSessionLimitsData } from './messages/ResponseSessionLimitsData';
import { ResponseShowCardsOptionDontShow } from './messages/ResponseShowCardsOptionDontShow';
import { ResponseShowCardsOptionMuck } from './messages/ResponseShowCardsOptionMuck';
import { ResponseShowCardsOptionShow } from './messages/ResponseShowCardsOptionShow';
import { ResponseShowRabbitHunt } from './messages/ResponseShowRabbitHunt';
import { ResponseUnjoinGlobalWaitlist } from './messages/ResponseUnjoinGlobalWaitlist';
import { ResponseUnjoinOtherDomainWaitlist } from './messages/ResponseUnjoinOtherDomainWaitlist';
import { ResponseUnjoinTableWaitlist } from './messages/ResponseUnjoinTableWaitlist';
import { ResponseUserClubDetails } from './messages/ResponseUserClubDetails';
import { ResponseUserTableBalanceReduceChips } from './messages/ResponseUserTableBalanceReduceChips';
import { SNGJPBountyAwardInfo } from './messages/SNGJPBountyAwardInfo';
import { SNGJPBountyInfo } from './messages/SNGJPBountyInfo';
import { SNGJPBountyWinner } from './messages/SNGJPBountyWinner';
import { SNGJPGameAboutToStart } from './messages/SNGJPGameAboutToStart';
import { SNGJPPayoutAnimation } from './messages/SNGJPPayoutAnimation';
import { SNGJPPayoutInfo } from './messages/SNGJPPayoutInfo';
import { SNGJPPlayNowRequest } from './messages/SNGJPPlayNowRequest';
import { SNGJPPlayNowResponse } from './messages/SNGJPPlayNowResponse';
import { SNGJPPrizeInfo } from './messages/SNGJPPrizeInfo';
import { SNGJPRegistrationFailureDetails } from './messages/SNGJPRegistrationFailureDetails';
import { SNGJPRegistrationInfo } from './messages/SNGJPRegistrationInfo';
import { SNGJPRegistrationRequest } from './messages/SNGJPRegistrationRequest';
import { SNGJPRegistrationResponse } from './messages/SNGJPRegistrationResponse';
import { SNGJPTournamentRankInfo } from './messages/SNGJPTournamentRankInfo';
import { SNGJPUnRegistrationRequest } from './messages/SNGJPUnRegistrationRequest';
import { SNGJPUnRegistrationResponse } from './messages/SNGJPUnRegistrationResponse';
import { SNGJackpotInfo } from './messages/SNGJackpotInfo';
import { SNGRankResultWithReplayRequest } from './messages/SNGRankResultWithReplayRequest';
import { STTReplayRequest } from './messages/STTReplayRequest';
import { STTReplayResponse } from './messages/STTReplayResponse';
import { SaveMTTPlayerAutoRebuyAddonPreferences } from './messages/SaveMTTPlayerAutoRebuyAddonPreferences';
import { ScratchCardTicker } from './messages/ScratchCardTicker';
import { SeatAvailable } from './messages/SeatAvailable';
import { SeatAvailableResponse } from './messages/SeatAvailableResponse';
import { SelectOption } from './messages/SelectOption';
import { SelectOptionAck } from './messages/SelectOptionAck';
import { SelectedAutoOption } from './messages/SelectedAutoOption';
import { SelectedOption } from './messages/SelectedOption';
import { SelectedOptionNew } from './messages/SelectedOptionNew';
import { SemiFinalCalendar } from './messages/SemiFinalCalendar';
import { SemiFinalDay } from './messages/SemiFinalDay';
import { SemiFinalGroup } from './messages/SemiFinalGroup';
import { SendMTCTParticipantNames } from './messages/SendMTCTParticipantNames';
import { ServerParamUpdateToClient } from './messages/ServerParamUpdateToClient';
import { SessionLimitNotification } from './messages/SessionLimitNotification';
import { SessionLimitsResponse } from './messages/SessionLimitsResponse';
import { SessionReset } from './messages/SessionReset';
import { SessionTimeRemainingInfo } from './messages/SessionTimeRemainingInfo';
import { SessionTimeRemainingNotification } from './messages/SessionTimeRemainingNotification';
import { SetCalendarAlert } from './messages/SetCalendarAlert';
import { SetPlayerGameSessionLimitsRequest } from './messages/SetPlayerGameSessionLimitsRequest';
import { ShootoutInfo } from './messages/ShootoutInfo';
import { ShowCards } from './messages/ShowCards';
import { ShowCards2 } from './messages/ShowCards2';
import { ShowCardsOptions } from './messages/ShowCardsOptions';
import { ShowCongrats } from './messages/ShowCongrats';
import { ShowESC } from './messages/ShowESC';
import { ShowEmoji } from './messages/ShowEmoji';
import { ShowHUPairTable } from './messages/ShowHUPairTable';
import { ShowInfoOnTable } from './messages/ShowInfoOnTable';
import { ShowPlayNow } from './messages/ShowPlayNow';
import { ShowPopup } from './messages/ShowPopup';
import { ShowRabbit } from './messages/ShowRabbit';
import { ShowScreenOnPool } from './messages/ShowScreenOnPool';
import { SidePot } from './messages/SidePot';
import { Sit } from './messages/Sit';
import { SitOutInfoNotification } from './messages/SitOutInfoNotification';
import { SitOutWarningOff } from './messages/SitOutWarningOff';
import { SitOutWarningOn } from './messages/SitOutWarningOn';
import { SitResponse } from './messages/SitResponse';
import { StayIn } from './messages/StayIn';
import { TBTMinEndTime } from './messages/TBTMinEndTime';
import { TBTTourneyResult } from './messages/TBTTourneyResult';
import { TabConfigText } from './messages/TabConfigText';
import { TableBoosterTriggered } from './messages/TableBoosterTriggered';
import { TableBoosterWinners } from './messages/TableBoosterWinners';
import { TableColorDetailsList } from './messages/TableColorDetailsList';
import { TableMessage } from './messages/TableMessage';
import { TableOccupancy } from './messages/TableOccupancy';
import { TableOccupancyList } from './messages/TableOccupancyList';
import { TableReduceBalanceLimit } from './messages/TableReduceBalanceLimit';
import { TableStakeLevelInfoRequest } from './messages/TableStakeLevelInfoRequest';
import { TableStakeLevelInfoResponse } from './messages/TableStakeLevelInfoResponse';
import { TableStakesInfo } from './messages/TableStakesInfo';
import { TableStakesInfoRequest } from './messages/TableStakesInfoRequest';
import { TableStakesInfoResponse } from './messages/TableStakesInfoResponse';
import { TableTransfer } from './messages/TableTransfer';
import { ThrowAnimation } from './messages/ThrowAnimation';
import { TimeDelayInfo } from './messages/TimeDelayInfo';
import { TimeOut } from './messages/TimeOut';
import { TournamentBackendUnRegistrationsInfo } from './messages/TournamentBackendUnRegistrationsInfo';
import { TournamentBackendUnRegistrationsInfoAck } from './messages/TournamentBackendUnRegistrationsInfoAck';
import { TournamentCurrentStatus } from './messages/TournamentCurrentStatus';
import { TournamentLateRegistrationDetails } from './messages/TournamentLateRegistrationDetails';
import { TournamentParticipantRankInfo } from './messages/TournamentParticipantRankInfo';
import { TournamentRegisteredPlayerInfo } from './messages/TournamentRegisteredPlayerInfo';
import { TournamentShowAddonPopup } from './messages/TournamentShowAddonPopup';
import { TourneyCancelled } from './messages/TourneyCancelled';
import { TourneyLevelInfo } from './messages/TourneyLevelInfo';
import { TourneyPauseInfo } from './messages/TourneyPauseInfo';
import { TourneyPrizeInfo } from './messages/TourneyPrizeInfo';
import { TourneyRegistrationStatus } from './messages/TourneyRegistrationStatus';
import { TourneyResult } from './messages/TourneyResult';
import { UnReserveSeat } from './messages/UnReserveSeat';
import { UnblockGamingSessionTimedOutPlayer } from './messages/UnblockGamingSessionTimedOutPlayer';
import { UncalledBetReturn } from './messages/UncalledBetReturn';
import { UnjoinAllWaitlist } from './messages/UnjoinAllWaitlist';
import { UnjoinWaitlist } from './messages/UnjoinWaitlist';
import { UserAllBalance } from './messages/UserAllBalance';
import { UserBalance } from './messages/UserBalance';
import { UserClubDetails } from './messages/UserClubDetails';
import { UserClubMemeberShipApproved } from './messages/UserClubMemeberShipApproved';
import { UserClubMemeberTypeChange } from './messages/UserClubMemeberTypeChange';
import { UserClubRemoved } from './messages/UserClubRemoved';
import { UserFreeRollList } from './messages/UserFreeRollList';
import { UserLocationStatus } from './messages/UserLocationStatus';
import { ValidFreeRolls } from './messages/ValidFreeRolls';
import { WaitStatus } from './messages/WaitStatus';
import { WaitingPlayersForLobby } from './messages/WaitingPlayersForLobby';
import { WaitingPlayersForTable } from './messages/WaitingPlayersForTable';
import { WaitlistCriteriaType } from './messages/WaitlistCriteriaType';
import { WatchersInfo } from './messages/WatchersInfo';
import { Winner } from './messages/Winner';
import { WinnerCards } from './messages/WinnerCards';

export class PokerMessageFactory extends PGMessageFactory {
    override classes: Map<number, any> = new Map<number, any>();
    override ids: Map<any, number> = new Map<any, number>();

    constructor() {
        super();
        this.classes.set(45057, Ante);
        this.classes.set(45058, Blind);
        this.classes.set(45059, BuyMoreChips);
        this.classes.set(45060, PvtTableType);
        this.classes.set(45061, HandHistory);
        this.classes.set(45062, Invitation);
        this.classes.set(45063, JoinTable);
        this.classes.set(45065, SeatAvailableResponse);
        this.classes.set(45066, SelectedOption);
        this.classes.set(45067, Sit);
        this.classes.set(45069, TabConfigText);
        this.classes.set(45070, AddCommunityCards);
        this.classes.set(45071, AddPlayer);
        this.classes.set(45072, AllInPot);
        this.classes.set(45073, AllInsRemaining);
        this.classes.set(45074, AutoOptionSet);
        this.classes.set(45075, ChangePlayerState);
        this.classes.set(45076, CurrentPlayer);
        this.classes.set(45077, DealCards);
        this.classes.set(45078, Dealer);
        this.classes.set(45079, EndTheRound);
        this.classes.set(45081, MuckCards);
        this.classes.set(45082, NewGame);
        this.classes.set(45083, OptionSet);
        this.classes.set(45085, PostAnte);
        this.classes.set(45086, PostBlind);
        this.classes.set(45087, PotWinners);
        this.classes.set(45088, PvtTableTypes);
        this.classes.set(45089, GameTableStatus);
        this.classes.set(45090, RemoveLobbyTable);
        this.classes.set(45091, RemovePlayer);
        this.classes.set(45092, ReserveSeat);
        this.classes.set(45093, ShowCardsOptions);
        this.classes.set(45095, TimeOut);
        this.classes.set(45096, WaitStatus);
        this.classes.set(45097, PlayerDealCards);
        this.classes.set(45098, PlayerStatus);
        this.classes.set(45099, CreatePvtTable);
        this.classes.set(45100, SidePot);
        this.classes.set(45101, Winner);
        this.classes.set(45102, UnReserveSeat);
        this.classes.set(45103, MorePlayMoneyResponse);
        this.classes.set(45104, GiveTourneyChips);
        this.classes.set(45105, TourneyResult);
        this.classes.set(45106, PrevPlayingGTs);
        this.classes.set(45107, ChangeInLevel);
        this.classes.set(45109, TableTransfer);
        this.classes.set(45110, AutoOptionToOptionsMapping);
        this.classes.set(45111, AutoOptionToOptionsMappings);
        this.classes.set(45112, WinnerCards);
        this.classes.set(45113, HighlightWinCards);
        this.classes.set(45114, ValidFreeRolls);
        this.classes.set(45115, Buddy);
        this.classes.set(45116, BlockBuddy);
        this.classes.set(45117, InviteBuddy);
        this.classes.set(45118, RemoveBuddy);
        this.classes.set(45119, AddBuddy);
        this.classes.set(45120, BuddyList);
        this.classes.set(45121, BuddyResponse);
        this.classes.set(45122, PlayerBalance);
        this.classes.set(45123, UserBalance);
        this.classes.set(45125, MTCTLobbyToOpen);
        this.classes.set(45127, MTCTTableStatus);
        this.classes.set(45128, CurrentPlayerMTCTRegistrations);
        this.classes.set(45129, MTCTRegister);
        this.classes.set(45130, MTCTUnRegister);
        this.classes.set(45133, SemiFinalDay);
        this.classes.set(45134, SemiFinalGroup);
        this.classes.set(45135, SemiFinalCalendar);
        this.classes.set(45137, CanPlayerRegister);
        this.classes.set(45139, TableOccupancy);
        this.classes.set(45141, TableOccupancyList);
        this.classes.set(45142, SendMTCTParticipantNames);
        this.classes.set(45143, MTCTParticipantNames);
        this.classes.set(45148, WaitingPlayersForTable);
        this.classes.set(45149, PlayerSearchResult);
        this.classes.set(45150, PlayerSearchResults);
        this.classes.set(45152, ShowESC);
        this.classes.set(45153, PPFont);
        this.classes.set(45154, JackpotAmount);
        this.classes.set(45155, JackpotAmounts);
        this.classes.set(45157, JackpotSnapshots);
        this.classes.set(45158, OptionSetAck);
        this.classes.set(45159, SelectOptionAck);
        this.classes.set(45160, EnableTimeBank);
        this.classes.set(45161, BlockSeat);
        this.classes.set(45162, MTCTRebuyAddonInfo);
        this.classes.set(45163, MTCTRebuyAddonStatus);
        this.classes.set(45164, MTCTRequestRebuyAddonDialogInfo);
        this.classes.set(45165, MTCTDoRebuyAddon);
        this.classes.set(45166, ShootoutInfo);
        this.classes.set(45167, JackpotHitTime);
        this.classes.set(45168, DealMakingChatRequest);
        this.classes.set(45169, DealMakingSelectedPayout);
        this.classes.set(45170, DealMakingAcceptPlayerCount);
        this.classes.set(45171, DealMakingDetails);
        this.classes.set(45172, Payout);
        this.classes.set(45173, PlayersChipCount);
        this.classes.set(45174, DealMakingConfirmData);
        this.classes.set(45175, DisconnectedPlayersList);
        this.classes.set(45176, DealMakingSubmitDeal);
        this.classes.set(45177, DealMakingTrnyRank);
        this.classes.set(45182, LobbyFont);
        this.classes.set(45183, TableColorDetailsList);
        this.classes.set(45184, DealPlayerData);
        this.classes.set(45185, DealMakingPostDealPayout);
        this.classes.set(45186, DealConfirmedPlayerCount);
        this.classes.set(45187, LobbyTableDataChange);
        this.classes.set(45188, LobbyTableOccupancyChange);
        this.classes.set(45192, MTCTTourneyStatus);
        this.classes.set(45193, TourneyPrizeInfo);
        this.classes.set(45194, DealMakingPayoutData);
        this.classes.set(45195, MTCTPrizeInfo);
        this.classes.set(45196, MTCTTourneyInfo);
        this.classes.set(45197, MTCTDetails);
        this.classes.set(45200, BlockSeatResponse);
        this.classes.set(45201, BuyinRefundStatus);
        this.classes.set(45202, CanPlayerRegisterResponse);
        this.classes.set(45203, CloseTable);
        this.classes.set(45204, CreatePvtTableResponse);
        this.classes.set(45205, Chat);
        this.classes.set(45206, HandStrength);
        this.classes.set(45207, JoinTableResponse);
        this.classes.set(45208, MessageOnTable);
        this.classes.set(45209, MoveToTable);
        this.classes.set(45210, MTCTRebuyAddonDialogInfo);
        this.classes.set(45211, ShowCards);
        this.classes.set(45212, ShowCongrats);
        this.classes.set(45213, ShowInfoOnTable);
        this.classes.set(45216, TourneyLevelInfo);
        this.classes.set(45217, RefreshTablesList);
        this.classes.set(45218, MTCTResponse);
        this.classes.set(45219, MsgTrafficStats);
        this.classes.set(45220, Option);
        this.classes.set(45221, LimitGameOption);
        this.classes.set(45222, AutoOption);
        this.classes.set(45223, SelectOption);
        this.classes.set(45224, SelectedAutoOption);
        this.classes.set(45225, InviteBuddyRequest);
        this.classes.set(45226, SeatAvailable);
        this.classes.set(45227, DealMakingChat);
        this.classes.set(45228, GameStatus);
        this.classes.set(45229, STTReplayRequest);
        this.classes.set(45230, STTReplayResponse);
        this.classes.set(45231, RequestTournamentHH);
        this.classes.set(45232, TimeDelayInfo);
        this.classes.set(45233, CasinoTrnyLevelInfo);
        this.classes.set(45234, TBTTourneyResult);
        this.classes.set(45235, TBTMinEndTime);
        this.classes.set(45236, PlayerTourneyRank);
        this.classes.set(45237, StayIn);
        this.classes.set(45238, PostStayIn);
        this.classes.set(45239, TableMessage);
        this.classes.set(45242, WaitlistCriteriaType);
        this.classes.set(45243, JoinWaitlist);
        this.classes.set(45244, JoinWaitlistResponse);
        this.classes.set(45245, UnjoinWaitlist);
        this.classes.set(45246, UnjoinAllWaitlist);
        this.classes.set(45247, JoinWaitlistRefresh);
        this.classes.set(45248, ChatOffMessage);
        this.classes.set(45249, MTCTDynaLiteralUpdate);
        this.classes.set(45259, QSParam);
        this.classes.set(45265, QSRequest);
        this.classes.set(45266, QSError);
        this.classes.set(45267, QSResponse);
        this.classes.set(45268, QSResult);
        this.classes.set(45269, QSBlockSeatCancel);
        this.classes.set(45270, PeerTables);
        this.classes.set(45271, DealHighCards);
        this.classes.set(45272, MegaPotPrize);
        this.classes.set(45277, CalendarAlert);
        this.classes.set(45278, SetCalendarAlert);
        this.classes.set(45283, MTCTLevel);
        this.classes.set(45285, PrePromoTicker);
        this.classes.set(45286, PromoUpdateTicker);
        this.classes.set(45287, RatHoleResponse);
        this.classes.set(45288, FXConversionData);
        this.classes.set(45289, MTCTLobbyData);
        this.classes.set(45291, MTCTRegistrationChangeEvent);
        this.classes.set(45293, MTCTStatusChangeEvent);
        this.classes.set(45294, MTCTDeleteEvent);
        this.classes.set(45295, MTCTLevelChangeEvent);
        this.classes.set(45296, MTCTPlayerCountChangeEvent);
        this.classes.set(45297, MTCTPrizePoolChangeEvent);
        this.classes.set(45298, MTCTLobbySnapshotChange);
        this.classes.set(45299, ScratchCardTicker);
        this.classes.set(45300, RelocateTable);
        this.classes.set(45301, MTCTCustomPropertyChangeEvent);
        this.classes.set(45310, MTCTPlayerKickedOut);
        this.classes.set(45312, MTCTParticipantNamesOnRequest);
        this.classes.set(45313, RequestMTCTParticipantNamesOnSearchString);
        this.classes.set(45315, JoinTableQS);
        this.classes.set(45316, JoinTableAutoBuyIn);
        this.classes.set(45317, JoinTableSTTReplay);
        this.classes.set(45318, SitResponse);
        this.classes.set(45319, AutoBuyInError);
        this.classes.set(45323, BlockSeatCancel);
        this.classes.set(45324, ItaliaAuthorizationCodes);
        this.classes.set(45325, RequestAutoCheckOff);
        this.classes.set(45326, RequestAutoCheckOn);
        this.classes.set(45327, RequestAutoOptionsOptionsMappings);
        this.classes.set(45328, RequestBuddiesList);
        this.classes.set(45329, RequestChatOffDisabled);
        this.classes.set(45330, RequestChatOffEnabled);
        this.classes.set(45331, RequestCurrentPlayerRegistration);
        this.classes.set(45332, RequestDealMakingExit);
        this.classes.set(45333, RequestDealMakingReject);
        this.classes.set(45334, RequestDealMakingView);
        this.classes.set(45336, RequestFoldToAnyBetOn);
        this.classes.set(45340, RequestListOfPVTTableTypes);
        this.classes.set(45341, RequestMtctRebuyDialogCancel);
        this.classes.set(45343, RequestNoBuddiesList);
        this.classes.set(45345, RequestRegainPlayer);
        this.classes.set(45346, RequestFoldToAnyBetOff);
        this.classes.set(45347, RequestSendJackpotSnapshots);
        this.classes.set(45356, RequestTourneyPrizeInfo);
        this.classes.set(45357, RequestWaitingPlayers);
        this.classes.set(45360, RequestAutopostBlindAndFoldOff);
        this.classes.set(45361, RequestAutoPostBlindAndFoldOn);
        this.classes.set(45362, RequestAutoPostBlindOff);
        this.classes.set(45363, RequestAutoPostBlindOn);
        this.classes.set(45364, RequestAutorebuyOff);
        this.classes.set(45365, RequestAutoRebuyOn);
        this.classes.set(45366, RequestDealMakingAllConfirmed);
        this.classes.set(45367, RequestDealMakingConfirm);
        this.classes.set(45368, RequestDealMakingNo);
        this.classes.set(45369, RequestDealMakingYes);
        this.classes.set(45370, RequestGameStatus);
        this.classes.set(45372, RequestLeaveTable);
        this.classes.set(45373, RequestMuckLosingHandOff);
        this.classes.set(45374, RequestMuckLosingHandOn);
        this.classes.set(45375, RequestSitoutAtNextBBOff);
        this.classes.set(45376, RequestSitoutAtNextBBOn);
        this.classes.set(45377, RequestSitoutNextHandOff);
        this.classes.set(45378, RequestSitoutNextHandOn);
        this.classes.set(45379, RequestWaitforBigblindOff);
        this.classes.set(45380, RequestWaitForBigBlindOn);
        this.classes.set(45381, RequestAdviseToBuyMoreChips);
        this.classes.set(45383, RequestCloseGameTable);
        this.classes.set(45384, RequestDealMakingEnded);
        this.classes.set(45385, RequestDealMakingGameClosed);
        this.classes.set(45386, RequestDealMakingGameToContinue);
        this.classes.set(45387, RequestDealMakingHide);
        this.classes.set(45388, RequestDealMakingPayoutVetoed);
        this.classes.set(45389, RequestDealMakingStarted);
        this.classes.set(45390, RequestDealMakingTimeout);
        this.classes.set(45391, RequestDealMakingVetoed);
        this.classes.set(45392, RequestEndOfTbt);
        this.classes.set(45393, RequestEndOfTheGame);
        this.classes.set(45398, RequestKillGametableSilently);
        this.classes.set(45403, RequestServerClosckReSync);
        this.classes.set(45404, RequestShowSitButtons);
        this.classes.set(45411, ResponseAddonFailureAddonExceeded);
        this.classes.set(45412, ResponseAddonFailureDBError);
        this.classes.set(45413, ResponseAddonFailureInsufficientBalance);
        this.classes.set(45414, ResponseAddonSuccess);
        this.classes.set(45415, ResponseAllinResetFailed);
        this.classes.set(45416, ResponseAllinResetNotRequired);
        this.classes.set(45417, ResponseAllinResetPending);
        this.classes.set(45418, ResponseAllinResetSuccess);
        this.classes.set(45421, ResponseBuyMoreChipsDBError);
        this.classes.set(45422, ResponseBuyMoreChipsNotAPlayer);
        this.classes.set(45423, ResponseBuyMoreChipsNotAvailable);
        this.classes.set(45424, ResponseBuyMoreChipsPlaying);
        this.classes.set(45425, ResponseBuyMoreChipsSuccess);
        this.classes.set(45429, ResponseChatRequestPermissionDenied);
        this.classes.set(45430, ResponseDealMakingConfirmSuccess);
        this.classes.set(45431, ResponseDealMakingRejectSuccess);
        this.classes.set(45432, ResponseDealMakingSubmitFailure);
        this.classes.set(45433, ResponseDealMakingSubmitSuccess);
        this.classes.set(45434, ResponseGeneralErrorMessage);
        this.classes.set(45436, ResponseHandHistoryFail);
        this.classes.set(45437, ResponseHandHistorySuccess);
        this.classes.set(45438, ResponseHURematchAccepted);
        this.classes.set(45439, ResponseHURematchRejected);
        this.classes.set(45440, ResponseHURematchTimeout);
        this.classes.set(45441, ResponseJPWelcomeMsg);
        this.classes.set(45442, ResponseNOBuddiesListSuccess);
        this.classes.set(45444, ResponseRebuyAddonClosed);
        this.classes.set(45445, ResponseRebuyFailureDBError);
        this.classes.set(45446, ResponseRebuyFailureImproperChipBalance);
        this.classes.set(45447, ResponseRebuyFailureInsufficientBalance);
        this.classes.set(45448, ResponseRebuyFailureRebuysExceeded);
        this.classes.set(45449, ResponseRebuySuccess);
        this.classes.set(45450, ResponseRebuySuccessPending);
        this.classes.set(45451, ResponseRegainPlayerFailed);
        this.classes.set(45452, ResponseRegainPlayerSuccess);
        this.classes.set(45454, ResponseShowCardsOptionShow);
        this.classes.set(45458, ResponseReceivedExpiredOptions);
        this.classes.set(45459, ResponseShowCardsOptionDontShow);
        this.classes.set(45463, ResponseShowCardsOptionMuck);
        this.classes.set(45477, ResponseBuyMoreChipsAllowedAmount);
        this.classes.set(45482, RGLimitErrorMsg);
        this.classes.set(45484, ItaliaAuthorizationCodesForTable);
        this.classes.set(45485, PokerChatRequest);
        this.classes.set(45487, RequestUserBalance);
        this.classes.set(45490, RequestAddMorePlayMoney);
        this.classes.set(45491, WaitingPlayersForLobby);
        this.classes.set(45492, ArjelEndOfTheGameEventReq);
        this.classes.set(45493, ArjelEndOfTheGameEventRes);
        this.classes.set(45494, ArjelBlindsRoundData);
        this.classes.set(45495, ArjelPlayerCards);
        this.classes.set(45496, ArjelGameAllPlayersAction);
        this.classes.set(45497, ArjelGameLongValue);
        this.classes.set(45498, ArjelGameCards);
        this.classes.set(45499, ArjelGameSummary);
        this.classes.set(45500, ArjelBlindsRoundPlayerData);
        this.classes.set(45501, ArjelGamePlayerAction);
        this.classes.set(45502, ArjelGameSummaryPlayerInfo);
        this.classes.set(45505, ResponseShowRabbitHunt);
        this.classes.set(45513, FoldNShowOption);
        this.classes.set(45514, ResponseFoldNShowFirstCard);
        this.classes.set(45516, ResponseFoldNShowSecondCard);
        this.classes.set(45517, ResponseFoldNShowBoth);
        this.classes.set(45521, ProPlayerList);
        this.classes.set(45522, CompositeLobbyMessage);
        this.classes.set(45523, CompositeTableMessage);
        this.classes.set(45524, PlayerBalances);
        this.classes.set(45525, PlayerStates);
        this.classes.set(45537, BeginnerStatus);
        this.classes.set(45538, LobbyBigBlindChange);
        this.classes.set(45539, DealerSbAndBbPositions);
        this.classes.set(45540, ResponseBuyMoreChipsInsuffPokerFund);
        this.classes.set(45541, ResponseBuyMoreChipsInsuffPartnerFund);
        this.classes.set(45542, ResponseBuyMoreChipsPokerTxnError);
        this.classes.set(45543, ResponseBuyMoreChipsWalletTxnError);
        this.classes.set(45544, UserAllBalance);
        this.classes.set(45545, RequestUserBalancePartnerPlayer);
        this.classes.set(45546, ResponseBuyMoreChipsMultiple);
        this.classes.set(45550, RequestCloseClient);
        this.classes.set(45551, AnonymousPlayerInfo);
        this.classes.set(45552, ResponseBetAndWinSummary);
        this.classes.set(45553, ArjelEndOfTheGameSeatData);
        this.classes.set(45557, PoolAvailabilityRequest);
        this.classes.set(45558, JoinPoolRequest);
        this.classes.set(45559, LeavePoolRequest);
        this.classes.set(45560, PoolAvailabilityResponse);
        this.classes.set(45561, JoinPoolResponse);
        this.classes.set(45566, ReqQuickFoldToBetOrRaiseOn);
        this.classes.set(45567, ReqQuickFoldToBetOrRaiseOff);
        this.classes.set(45568, ReqSitoutNextBlindOn);
        this.classes.set(45569, ReqSitoutNextBlindOff);
        this.classes.set(45570, RequestFoldAndWatchOn);
        this.classes.set(45571, RequestFoldAndWatchOff);
        this.classes.set(45572, PoolRebuyRequest);
        this.classes.set(45573, PoolRebuyResponse);
        this.classes.set(45574, AddPlayMoneyToPoolRequest);
        this.classes.set(45575, AddPlayMoneyToPoolResponse);
        this.classes.set(45576, BackToPoolRequest);
        this.classes.set(45577, PoolStartGameNotification);
        this.classes.set(45578, PoolWaitingNotification);
        this.classes.set(45579, PoolEndGameNotification);
        this.classes.set(45580, PoolSitOutNotification);
        this.classes.set(45581, PoolKickoutNotification);
        this.classes.set(45582, ReqNextHandOffFoldAndWatch);
        this.classes.set(45583, PoolRequestUserBalance);
        this.classes.set(45584, PoolUserBalance);
        this.classes.set(45585, ReqNextHandOnFoldAndWatch);
        this.classes.set(45586, RequestEndOfTheGameFastPoker);
        this.classes.set(45587, PoolRequestUserReconnect);
        this.classes.set(45588, PoolUserReconnectResponse);
        this.classes.set(45589, PlayerEntryStatus);
        this.classes.set(45590, PoolShutdownNotification);
        this.classes.set(45591, ShowScreenOnPool);
        this.classes.set(45592, PrevPlayingFastPokerPools);
        this.classes.set(45593, PoolFraudPlayerKickoutNotification);
        this.classes.set(45594, QFToAnyBetPlayerQuickFolded);
        this.classes.set(45595, RequestPoolSitout);
        this.classes.set(45596, HyperLinkedDealerChat);
        this.classes.set(45597, ResponseRebuyAddonAccClosureFailure);
        this.classes.set(45598, RecommendedStakeLimit);
        this.classes.set(45599, RecommendedStakesMessage);
        this.classes.set(45600, PokerPlayerProtectionInfo);
        this.classes.set(45601, RequestJoinTableWaitlist);
        this.classes.set(45602, ResponseJoinTableWaitlist);
        this.classes.set(45603, RequestUnjoinTableWaitlist);
        this.classes.set(45604, ResponseUnjoinTableWaitlist);
        this.classes.set(45605, GlobalWaitlistCriteria);
        this.classes.set(45606, RequestJoinGlobalWaitlist);
        this.classes.set(45607, ResponseJoinGlobalWaitlist);
        this.classes.set(45608, RequestUnjoinGlobalWaitlist);
        this.classes.set(45609, ResponseUnjoinGlobalWaitlist);
        this.classes.set(45611, RequestJoinOtherDomainWaitlist);
        this.classes.set(45612, ResponseJoinOtherDomainWaitlist);
        this.classes.set(45613, RequestUnjoinOtherDomainWaitlist);
        this.classes.set(45614, ResponseUnjoinOtherDomainWaitlist);
        this.classes.set(45616, ArjelGTPlayerData);
        this.classes.set(45618, ChallengeDetails);
        this.classes.set(45619, AcceptChallengeRequest);
        this.classes.set(45620, AcceptChallengeResponse);
        this.classes.set(45621, CreateChallengeHUCashRequest);
        this.classes.set(45622, CreateChallengeHUCashResponse);
        this.classes.set(45623, CreateChallengeHUSNGRequest);
        this.classes.set(45624, CreateChallengeHUSNGResponse);
        this.classes.set(45625, RejectChallengeRequest);
        this.classes.set(45626, RejectChallengeResponse);
        this.classes.set(45627, ChallengeInvitationRequest);
        this.classes.set(45628, ChallengeCancelledMessage);
        this.classes.set(45629, ChallengeHistoryData);
        this.classes.set(45630, ChallengeHistoryRequest);
        this.classes.set(45631, ChallengeHistoryResponse);
        this.classes.set(45632, ChallengeTemplate);
        this.classes.set(45633, ChallengeTemplatesList);
        this.classes.set(45634, RequestListOfChallengeTemplates);
        this.classes.set(45635, DeletePrivateTableRequest);
        this.classes.set(45636, DeletePrivateTableResponse);
        this.classes.set(45637, PrivateTableInvitationRequest);
        this.classes.set(45638, CreatePrivateTableResponse);
        this.classes.set(45639, CreatePrivateTableRequest);
        this.classes.set(45640, NotifyPrivateTableCreator);
        this.classes.set(45641, HUSNGRankResultWithRematchRequest);
        this.classes.set(45642, RequestHUSNGRematchAccepted);
        this.classes.set(45643, RequestHUSNGRematchRejected);
        this.classes.set(45644, RequestHUSNGRematchAcceptedAck);
        this.classes.set(45645, ResponseHUSNGRematchAccepted);
        this.classes.set(45646, ResponseHUSNGRematchRejected);
        this.classes.set(45647, ResponseHUSNGRematchTimeout);
        this.classes.set(45648, ResponseHUSNGRematchBuyInFailed);
        this.classes.set(45649, SNGRankResultWithReplayRequest);
        this.classes.set(45650, TournamentRegisteredPlayerInfo);
        this.classes.set(45651, CanJoinPrivateTableRequest);
        this.classes.set(45652, CanJoinPrivateTableResponse);
        this.classes.set(45653, UserFreeRollList);
        this.classes.set(45654, AddedFreeRoll);
        this.classes.set(45655, RemovedFreeRoll);
        this.classes.set(45656, TableStakesInfoRequest);
        this.classes.set(45657, TableStakesInfoResponse);
        this.classes.set(45658, UncalledBetReturn);
        this.classes.set(45659, TableStakesInfo);
        this.classes.set(45660, TableStakeLevelInfoRequest);
        this.classes.set(45661, TableStakeLevelInfoResponse);
        this.classes.set(45662, ContentLockingAccessDeniedResponse);
        this.classes.set(45663, MTTournamentFinalTable);
        this.classes.set(45664, ResponseBuyMoreChipsNotAllowed);
        this.classes.set(45665, PoolForcedSitOutNotification);
        this.classes.set(45670, ResponseRebuyGenericFailure);
        this.classes.set(45671, ResponseRebuyAddonGenericFailure);
        this.classes.set(45673, SitOutInfoNotification);
        this.classes.set(45674, TournamentBackendUnRegistrationsInfo);
        this.classes.set(45675, TournamentBackendUnRegistrationsInfoAck);
        this.classes.set(45676, TournamentShowAddonPopup);
        this.classes.set(45678, AppFriendsListRequest);
        this.classes.set(45679, AppFriendsListResponse);
        this.classes.set(45680, GamingSessionTimedOutPopUp);
        this.classes.set(45681, UnblockGamingSessionTimedOutPlayer);
        this.classes.set(45682, GamingSessionTimedOutPopUpAck);
        this.classes.set(45683, SitOutWarningOn);
        this.classes.set(45684, SitOutWarningOff);
        this.classes.set(45685, PlayerBalanceInfo);
        this.classes.set(45686, FRONTALLeavePoolResponse);
        this.classes.set(45687, FRONTALJoinPoolResponse);
        this.classes.set(45688, FRONTALPoolRebuyResponse);
        this.classes.set(45689, SaveMTTPlayerAutoRebuyAddonPreferences);
        this.classes.set(45690, MTTPlayerAutoRebuyAddonPreferences);
        this.classes.set(45691, CountryBlockEventRequest);
        this.classes.set(45692, CountryBlockEventResponse);
        this.classes.set(45693, PoolItaliaAuthorizationCodes);
        this.classes.set(45696, PoolPartnerRebuyRequest);
        this.classes.set(45697, PoolRequestUserBalancePartnerPlayer);
        this.classes.set(45698, PoolUserAllBalance);
        this.classes.set(45699, ShowPlayNow);
        this.classes.set(45700, DoPlayNow);
        this.classes.set(45701, RemovePlayNow);
        this.classes.set(45702, TournamentParticipantRankInfo);
        this.classes.set(45703, TourneyRegistrationStatus);
        this.classes.set(45704, PlayerStatuses);
        this.classes.set(45705, HUDAnonymousState);
        this.classes.set(45706, SNGJPPlayNowRequest);
        this.classes.set(45707, SNGJPPlayNowResponse);
        this.classes.set(45708, SNGJPRegistrationRequest);
        this.classes.set(45709, SNGJPRegistrationResponse);
        this.classes.set(45710, SNGJPRegistrationInfo);
        this.classes.set(45711, SNGJPUnRegistrationRequest);
        this.classes.set(45712, SNGJPUnRegistrationResponse);
        this.classes.set(45713, SNGJackpotInfo);
        this.classes.set(45714, SNGJPPayoutAnimation);
        this.classes.set(45715, SNGJPPayoutInfo);
        this.classes.set(45716, SNGJPPrizeInfo);
        this.classes.set(45717, SNGJPBountyInfo);
        this.classes.set(45718, SNGJPBountyWinner);
        this.classes.set(45719, SNGJPGameAboutToStart);
        this.classes.set(45720, SNGJPTournamentRankInfo);
        this.classes.set(45721, SNGJPRegistrationFailureDetails);
        this.classes.set(45722, SNGJPBountyAwardInfo);
        this.classes.set(45724, EnableChat);
        this.classes.set(45725, WatchersInfo);
        this.classes.set(45726, PlayersBountyInfo);
        this.classes.set(45727, ProgressiveBountyAnimationInfo);
        this.classes.set(45728, TourneyPauseInfo);
        this.classes.set(45729, RemoveTourneyPauseInfo);
        this.classes.set(45730, PromoWinnerPlayerList);
        this.classes.set(45731, ResponseLossLimitExceed);
        this.classes.set(45732, BetLimitErrorMessage);
        this.classes.set(45733, LoginSessionTimeoutIntimation);
        this.classes.set(45734, ReadLatestRandomSeatingFlag);
        this.classes.set(45735, GameCurrencyDetailsList);
        this.classes.set(45736, GeneralRequestAddMorePlayMoney);
        this.classes.set(45737, MorePlayMoneyGeneralResponse);
        this.classes.set(45738, ReduceChips);
        this.classes.set(45739, ReduceChipsResponse);
        this.classes.set(45740, SessionTimeRemainingInfo);
        this.classes.set(45741, RealityCheckSummaryPopUp);
        this.classes.set(45742, RealityCheckSummaryPopUpAck);
        this.classes.set(45743, ClosePoolEntry);
        this.classes.set(45744, PlayerGameSessionData);
        this.classes.set(45747, PlayerVerificationStatus);
        this.classes.set(45748, SelectedOptionNew);
        this.classes.set(45749, ReqShowEmoji);
        this.classes.set(45750, ShowEmoji);
        this.classes.set(45751, ShowRabbit);
        this.classes.set(45752, ReqEnableRabbit);
        this.classes.set(45753, BroadcastShowRabbit);
        this.classes.set(45754, ReqThrowAnimation);
        this.classes.set(45755, ResThrowAnimation);
        this.classes.set(45756, ThrowAnimation);
        this.classes.set(45757, GetPokerDiamondBalance);
        this.classes.set(45758, ProbabilityOfWinning);
        this.classes.set(45759, DiamondBalance);
        this.classes.set(45761, ReqEnablePlayerRunittwiceState);
        this.classes.set(45762, RequestHUPairTable);
        this.classes.set(45763, HUPairTableSelectStatus);
        this.classes.set(45764, PlayersBountyWonInfo);
        this.classes.set(45765, ITMInformation);
        this.classes.set(45766, ShowHUPairTable);
        this.classes.set(45767, RCPUActionAcknowledgement);
        this.classes.set(45768, RequestJoinTableAutoBuyin);
        this.classes.set(45769, DepositedFriendCount);
        this.classes.set(45770, HUEvenGame);
        this.classes.set(45771, RequestPlayerNoteId);
        this.classes.set(45772, ResponsePlayerNoteId);
        this.classes.set(45774, RequestUserClubDetails);
        this.classes.set(45775, UserClubDetails);
        this.classes.set(45776, UserClubRemoved);
        this.classes.set(45777, ResponseUserClubDetails);
        this.classes.set(45778, ClubNameApproved);
        this.classes.set(45779, UserClubMemeberShipApproved);
        this.classes.set(45780, UserClubMemeberTypeChange);
        this.classes.set(45781, RequestRealName);
        this.classes.set(45782, ResponseRealName);
        this.classes.set(45784, PlayerTableList);
        this.classes.set(45785, TableBoosterTriggered);
        this.classes.set(45786, BroadcastTableBoosterTrigger);
        this.classes.set(45787, TableBoosterWinners);
        this.classes.set(45788, BoosterWinners);
        this.classes.set(45789, FRSessionTimer);
        this.classes.set(45796, ITMAdditionalInformation);
        this.classes.set(45797, ResponseGermanTaxValue);
        this.classes.set(45798, TournamentLateRegistrationDetails);
        this.classes.set(45799, LossLimitDetails);
        this.classes.set(45800, LossLimitResponse);
        this.classes.set(45801, SessionLimitsResponse);
        this.classes.set(45802, ResponseFoldNShowCards);
        this.classes.set(45803, RequestCashoutEquity);
        this.classes.set(45804, PlayerCashedOutEquityStatus);
        this.classes.set(45805, PlayerCashoutEquityDetails);
        this.classes.set(45806, PlayersCashoutEquityDetails);
        this.classes.set(45807, ResponseCashoutEquity);
        this.classes.set(45810, ShowCards2);
        this.classes.set(45813, PlayerATHStrike);
        this.classes.set(45814, ATHStrikeHandCountConfiguration);
        this.classes.set(45816, ResponseUserTableBalanceReduceChips);
        this.classes.set(45817, TableReduceBalanceLimit);
        this.classes.set(45818, RequestTableLimitBalance);
        this.classes.set(45819, PlayedGamesCount);
        this.classes.set(45820, CanLeaveTable);
        this.classes.set(45821, CanLeaveTableResponse);
        this.classes.set(45822, CanSitOut);
        this.classes.set(45823, CanSitOutResponse);
        this.classes.set(45824, ATHAutoSitOutMsg);
        this.classes.set(45825, TourneyCancelled);
        this.classes.set(45827, TournamentCurrentStatus);
        this.classes.set(45842, PlayerAccountType);
        this.classes.set(45843, PlayerGameSessionLimitsStatusResponse);
        this.classes.set(45844, PlayerGameSessionRemainingLimitsStatusResponse);
        this.classes.set(45845, PlayerGameSessionRemainingLimitsRequest);
        this.classes.set(45846, SetPlayerGameSessionLimitsRequest);
        this.classes.set(45847, PlayerGameSessionReminder);
        this.classes.set(45848, PlayerGameSessionLimitsStatusRequest);
        this.classes.set(45850, ServerParamUpdateToClient);
        this.classes.set(45865, UserLocationStatus);
        this.classes.set(45866, ShowPopup);
        this.classes.set(45867, ResponseGeolocationStatus);
        this.classes.set(45868, ResponseBuyMoreChipsGeoLocationBlocked);
        this.classes.set(45869, SessionLimitNotification);
        this.classes.set(45870, SessionReset);
        this.classes.set(45871, SessionTimeRemainingNotification);
        this.classes.set(45872, ResponseBuyMoreChipsSessiontimeExpired);
        this.classes.set(45873, ResponseAddonRebuyFailure);
        this.classes.set(45874, ResponseBuyMoreChipsLossLimitExceeded);
        this.classes.set(45875, BlockPlayerOnInvalidLocation);
        this.classes.set(45876, RequestSessionLimitsData);
        this.classes.set(45877, ResponseSessionLimitsData);
        this.classes.set(45878, NetPositionDetails);
        this.classes.set(45879, ForceLogOutRequest);
        this.classes.set(45880, MultiConnectionPostLoginSyncupMessage);
        this.classes.set(45882, ResponseElapsedTime);
        this.classes.set(45888, BigWinNotification);
        for (const [key, value] of this.classes.entries()) {
            this.ids.set(value, key);
        }
    }

    read(obj: Message, inStream: TSDataInputStream) {
        const classId = this.getClassId(obj.constructor);
        let result = false;
        switch (classId) {
            case 45057:
                this.readAnte(inStream, obj as Ante);
                result = true;
                break;
            case 45058:
                this.readBlind(inStream, obj as Blind);
                result = true;
                break;
            case 45059:
                this.readBuyMoreChips(inStream, obj as BuyMoreChips);
                result = true;
                break;
            case 45060:
                this.readPvtTableType(inStream, obj as PvtTableType);
                result = true;
                break;
            case 45061:
                this.readHandHistory(inStream, obj as HandHistory);
                result = true;
                break;
            case 45062:
                this.readInvitation(inStream, obj as Invitation);
                result = true;
                break;
            case 45063:
                this.readJoinTable(inStream, obj as JoinTable);
                result = true;
                break;
            case 45065:
                this.readSeatAvailableResponse(inStream, obj as SeatAvailableResponse);
                result = true;
                break;
            case 45066:
                this.readSelectedOption(inStream, obj as SelectedOption);
                result = true;
                break;
            case 45067:
                this.readSit(inStream, obj as Sit);
                result = true;
                break;
            case 45069:
                this.readTabConfigText(inStream, obj as TabConfigText);
                result = true;
                break;
            case 45070:
                this.readAddCommunityCards(inStream, obj as AddCommunityCards);
                result = true;
                break;
            case 45071:
                this.readAddPlayer(inStream, obj as AddPlayer);
                result = true;
                break;
            case 45072:
                this.readAllInPot(inStream, obj as AllInPot);
                result = true;
                break;
            case 45073:
                this.readAllInsRemaining(inStream, obj as AllInsRemaining);
                result = true;
                break;
            case 45074:
                this.readAutoOptionSet(inStream, obj as AutoOptionSet);
                result = true;
                break;
            case 45075:
                this.readChangePlayerState(inStream, obj as ChangePlayerState);
                result = true;
                break;
            case 45076:
                this.readCurrentPlayer(inStream, obj as CurrentPlayer);
                result = true;
                break;
            case 45077:
                this.readDealCards(inStream, obj as DealCards);
                result = true;
                break;
            case 45078:
                this.readDealer(inStream, obj as Dealer);
                result = true;
                break;
            case 45079:
                this.readEndTheRound(inStream, obj as EndTheRound);
                result = true;
                break;
            case 45081:
                this.readMuckCards(inStream, obj as MuckCards);
                result = true;
                break;
            case 45082:
                this.readNewGame(inStream, obj as NewGame);
                result = true;
                break;
            case 45083:
                this.readOptionSet(inStream, obj as OptionSet);
                result = true;
                break;
            case 45085:
                this.readPostAnte(inStream, obj as PostAnte);
                result = true;
                break;
            case 45086:
                this.readPostBlind(inStream, obj as PostBlind);
                result = true;
                break;
            case 45087:
                this.readPotWinners(inStream, obj as PotWinners);
                result = true;
                break;
            case 45088:
                this.readPvtTableTypes(inStream, obj as PvtTableTypes);
                result = true;
                break;
            case 45089:
                this.readGameTableStatus(inStream, obj as GameTableStatus);
                result = true;
                break;
            case 45090:
                this.readRemoveLobbyTable(inStream, obj as RemoveLobbyTable);
                result = true;
                break;
            case 45091:
                this.readRemovePlayer(inStream, obj as RemovePlayer);
                result = true;
                break;
            case 45092:
                this.readReserveSeat(inStream, obj as ReserveSeat);
                result = true;
                break;
            case 45093:
                this.readShowCardsOptions(inStream, obj as ShowCardsOptions);
                result = true;
                break;
            case 45095:
                this.readTimeOut(inStream, obj as TimeOut);
                result = true;
                break;
            case 45096:
                this.readWaitStatus(inStream, obj as WaitStatus);
                result = true;
                break;
            case 45097:
                this.readPlayerDealCards(inStream, obj as PlayerDealCards);
                result = true;
                break;
            case 45098:
                this.readPlayerStatus(inStream, obj as PlayerStatus);
                result = true;
                break;
            case 45099:
                this.readCreatePvtTable(inStream, obj as CreatePvtTable);
                result = true;
                break;
            case 45100:
                this.readSidePot(inStream, obj as SidePot);
                result = true;
                break;
            case 45101:
                this.readWinner(inStream, obj as Winner);
                result = true;
                break;
            case 45102:
                this.readUnReserveSeat(inStream, obj as UnReserveSeat);
                result = true;
                break;
            case 45103:
                this.readMorePlayMoneyResponse(inStream, obj as MorePlayMoneyResponse);
                result = true;
                break;
            case 45104:
                this.readGiveTourneyChips(inStream, obj as GiveTourneyChips);
                result = true;
                break;
            case 45105:
                this.readTourneyResult(inStream, obj as TourneyResult);
                result = true;
                break;
            case 45106:
                this.readPrevPlayingGTs(inStream, obj as PrevPlayingGTs);
                result = true;
                break;
            case 45107:
                this.readChangeInLevel(inStream, obj as ChangeInLevel);
                result = true;
                break;
            case 45109:
                this.readTableTransfer(inStream, obj as TableTransfer);
                result = true;
                break;
            case 45110:
                this.readAutoOptionToOptionsMapping(inStream, obj as AutoOptionToOptionsMapping);
                result = true;
                break;
            case 45111:
                this.readAutoOptionToOptionsMappings(inStream, obj as AutoOptionToOptionsMappings);
                result = true;
                break;
            case 45112:
                this.readWinnerCards(inStream, obj as WinnerCards);
                result = true;
                break;
            case 45113:
                this.readHighlightWinCards(inStream, obj as HighlightWinCards);
                result = true;
                break;
            case 45114:
                this.readValidFreeRolls(inStream, obj as ValidFreeRolls);
                result = true;
                break;
            case 45115:
                this.readBuddy(inStream, obj as Buddy);
                result = true;
                break;
            case 45116:
                this.readBlockBuddy(inStream, obj as BlockBuddy);
                result = true;
                break;
            case 45117:
                this.readInviteBuddy(inStream, obj as InviteBuddy);
                result = true;
                break;
            case 45118:
                this.readRemoveBuddy(inStream, obj as RemoveBuddy);
                result = true;
                break;
            case 45119:
                this.readAddBuddy(inStream, obj as AddBuddy);
                result = true;
                break;
            case 45120:
                this.readBuddyList(inStream, obj as BuddyList);
                result = true;
                break;
            case 45121:
                this.readBuddyResponse(inStream, obj as BuddyResponse);
                result = true;
                break;
            case 45122:
                this.readPlayerBalance(inStream, obj as PlayerBalance);
                result = true;
                break;
            case 45123:
                this.readUserBalance(inStream, obj as UserBalance);
                result = true;
                break;
            case 45125:
                this.readMTCTLobbyToOpen(inStream, obj as MTCTLobbyToOpen);
                result = true;
                break;
            case 45127:
                this.readMTCTTableStatus(inStream, obj as MTCTTableStatus);
                result = true;
                break;
            case 45128:
                this.readCurrentPlayerMTCTRegistrations(inStream, obj as CurrentPlayerMTCTRegistrations);
                result = true;
                break;
            case 45129:
                this.readMTCTRegister(inStream, obj as MTCTRegister);
                result = true;
                break;
            case 45130:
                this.readMTCTUnRegister(inStream, obj as MTCTUnRegister);
                result = true;
                break;
            case 45133:
                this.readSemiFinalDay(inStream, obj as SemiFinalDay);
                result = true;
                break;
            case 45134:
                this.readSemiFinalGroup(inStream, obj as SemiFinalGroup);
                result = true;
                break;
            case 45135:
                this.readSemiFinalCalendar(inStream, obj as SemiFinalCalendar);
                result = true;
                break;
            case 45137:
                this.readCanPlayerRegister(inStream, obj as CanPlayerRegister);
                result = true;
                break;
            case 45139:
                this.readTableOccupancy(inStream, obj as TableOccupancy);
                result = true;
                break;
            case 45141:
                this.readTableOccupancyList(inStream, obj as TableOccupancyList);
                result = true;
                break;
            case 45142:
                this.readSendMTCTParticipantNames(inStream, obj as SendMTCTParticipantNames);
                result = true;
                break;
            case 45143:
                this.readMTCTParticipantNames(inStream, obj as MTCTParticipantNames);
                result = true;
                break;
            case 45148:
                this.readWaitingPlayersForTable(inStream, obj as WaitingPlayersForTable);
                result = true;
                break;
            case 45149:
                this.readPlayerSearchResult(inStream, obj as PlayerSearchResult);
                result = true;
                break;
            case 45150:
                this.readPlayerSearchResults(inStream, obj as PlayerSearchResults);
                result = true;
                break;
            case 45152:
                this.readShowESC(inStream, obj as ShowESC);
                result = true;
                break;
            case 45153:
                this.readPPFont(inStream, obj as PPFont);
                result = true;
                break;
            case 45154:
                this.readJackpotAmount(inStream, obj as JackpotAmount);
                result = true;
                break;
            case 45155:
                this.readJackpotAmounts(inStream, obj as JackpotAmounts);
                result = true;
                break;
            case 45157:
                this.readJackpotSnapshots(inStream, obj as JackpotSnapshots);
                result = true;
                break;
            case 45158:
                this.readOptionSetAck(inStream, obj as OptionSetAck);
                result = true;
                break;
            case 45159:
                this.readSelectOptionAck(inStream, obj as SelectOptionAck);
                result = true;
                break;
            case 45160:
                this.readEnableTimeBank(inStream, obj as EnableTimeBank);
                result = true;
                break;
            case 45161:
                this.readBlockSeat(inStream, obj as BlockSeat);
                result = true;
                break;
            case 45162:
                this.readMTCTRebuyAddonInfo(inStream, obj as MTCTRebuyAddonInfo);
                result = true;
                break;
            case 45163:
                this.readMTCTRebuyAddonStatus(inStream, obj as MTCTRebuyAddonStatus);
                result = true;
                break;
            case 45164:
                this.readMTCTRequestRebuyAddonDialogInfo(inStream, obj as MTCTRequestRebuyAddonDialogInfo);
                result = true;
                break;
            case 45165:
                this.readMTCTDoRebuyAddon(inStream, obj as MTCTDoRebuyAddon);
                result = true;
                break;
            case 45166:
                this.readShootoutInfo(inStream, obj as ShootoutInfo);
                result = true;
                break;
            case 45167:
                this.readJackpotHitTime(inStream, obj as JackpotHitTime);
                result = true;
                break;
            case 45168:
                this.readDealMakingChatRequest(inStream, obj as DealMakingChatRequest);
                result = true;
                break;
            case 45169:
                this.readDealMakingSelectedPayout(inStream, obj as DealMakingSelectedPayout);
                result = true;
                break;
            case 45170:
                this.readDealMakingAcceptPlayerCount(inStream, obj as DealMakingAcceptPlayerCount);
                result = true;
                break;
            case 45171:
                this.readDealMakingDetails(inStream, obj as DealMakingDetails);
                result = true;
                break;
            case 45172:
                this.readPayout(inStream, obj as Payout);
                result = true;
                break;
            case 45173:
                this.readPlayersChipCount(inStream, obj as PlayersChipCount);
                result = true;
                break;
            case 45174:
                this.readDealMakingConfirmData(inStream, obj as DealMakingConfirmData);
                result = true;
                break;
            case 45175:
                this.readDisconnectedPlayersList(inStream, obj as DisconnectedPlayersList);
                result = true;
                break;
            case 45176:
                this.readDealMakingSubmitDeal(inStream, obj as DealMakingSubmitDeal);
                result = true;
                break;
            case 45177:
                this.readDealMakingTrnyRank(inStream, obj as DealMakingTrnyRank);
                result = true;
                break;
            case 45182:
                this.readLobbyFont(inStream, obj as LobbyFont);
                result = true;
                break;
            case 45183:
                this.readTableColorDetailsList(inStream, obj as TableColorDetailsList);
                result = true;
                break;
            case 45184:
                this.readDealPlayerData(inStream, obj as DealPlayerData);
                result = true;
                break;
            case 45185:
                this.readDealMakingPostDealPayout(inStream, obj as DealMakingPostDealPayout);
                result = true;
                break;
            case 45186:
                this.readDealConfirmedPlayerCount(inStream, obj as DealConfirmedPlayerCount);
                result = true;
                break;
            case 45187:
                this.readLobbyTableDataChange(inStream, obj as LobbyTableDataChange);
                result = true;
                break;
            case 45188:
                this.readLobbyTableOccupancyChange(inStream, obj as LobbyTableOccupancyChange);
                result = true;
                break;
            case 45192:
                this.readMTCTTourneyStatus(inStream, obj as MTCTTourneyStatus);
                result = true;
                break;
            case 45193:
                this.readTourneyPrizeInfo(inStream, obj as TourneyPrizeInfo);
                result = true;
                break;
            case 45194:
                this.readDealMakingPayoutData(inStream, obj as DealMakingPayoutData);
                result = true;
                break;
            case 45195:
                this.readMTCTPrizeInfo(inStream, obj as MTCTPrizeInfo);
                result = true;
                break;
            case 45196:
                this.readMTCTTourneyInfo(inStream, obj as MTCTTourneyInfo);
                result = true;
                break;
            case 45197:
                this.readMTCTDetails(inStream, obj as MTCTDetails);
                result = true;
                break;
            case 45200:
                this.readBlockSeatResponse(inStream, obj as BlockSeatResponse);
                result = true;
                break;
            case 45201:
                this.readBuyinRefundStatus(inStream, obj as BuyinRefundStatus);
                result = true;
                break;
            case 45202:
                this.readCanPlayerRegisterResponse(inStream, obj as CanPlayerRegisterResponse);
                result = true;
                break;
            case 45203:
                this.readCloseTable(inStream, obj as CloseTable);
                result = true;
                break;
            case 45204:
                this.readCreatePvtTableResponse(inStream, obj as CreatePvtTableResponse);
                result = true;
                break;
            case 45205:
                this.readChat(inStream, obj as Chat);
                result = true;
                break;
            case 45206:
                this.readHandStrength(inStream, obj as HandStrength);
                result = true;
                break;
            case 45207:
                this.readJoinTableResponse(inStream, obj as JoinTableResponse);
                result = true;
                break;
            case 45208:
                this.readMessageOnTable(inStream, obj as MessageOnTable);
                result = true;
                break;
            case 45209:
                this.readMoveToTable(inStream, obj as MoveToTable);
                result = true;
                break;
            case 45210:
                this.readMTCTRebuyAddonDialogInfo(inStream, obj as MTCTRebuyAddonDialogInfo);
                result = true;
                break;
            case 45211:
                this.readShowCards(inStream, obj as ShowCards);
                result = true;
                break;
            case 45212:
                this.readShowCongrats(inStream, obj as ShowCongrats);
                result = true;
                break;
            case 45213:
                this.readShowInfoOnTable(inStream, obj as ShowInfoOnTable);
                result = true;
                break;
            case 45216:
                this.readTourneyLevelInfo(inStream, obj as TourneyLevelInfo);
                result = true;
                break;
            case 45217:
                this.readRefreshTablesList(inStream, obj as RefreshTablesList);
                result = true;
                break;
            case 45218:
                this.readMTCTResponse(inStream, obj as MTCTResponse);
                result = true;
                break;
            case 45219:
                this.readMsgTrafficStats(inStream, obj as MsgTrafficStats);
                result = true;
                break;
            case 45220:
                this.readOption(inStream, obj as Option);
                result = true;
                break;
            case 45221:
                this.readLimitGameOption(inStream, obj as LimitGameOption);
                result = true;
                break;
            case 45222:
                this.readAutoOption(inStream, obj as AutoOption);
                result = true;
                break;
            case 45223:
                this.readSelectOption(inStream, obj as SelectOption);
                result = true;
                break;
            case 45224:
                this.readSelectedAutoOption(inStream, obj as SelectedAutoOption);
                result = true;
                break;
            case 45225:
                this.readInviteBuddyRequest(inStream, obj as InviteBuddyRequest);
                result = true;
                break;
            case 45226:
                this.readSeatAvailable(inStream, obj as SeatAvailable);
                result = true;
                break;
            case 45227:
                this.readDealMakingChat(inStream, obj as DealMakingChat);
                result = true;
                break;
            case 45228:
                this.readGameStatus(inStream, obj as GameStatus);
                result = true;
                break;
            case 45229:
                this.readSTTReplayRequest(inStream, obj as STTReplayRequest);
                result = true;
                break;
            case 45230:
                this.readSTTReplayResponse(inStream, obj as STTReplayResponse);
                result = true;
                break;
            case 45231:
                this.readRequestTournamentHH(inStream, obj as RequestTournamentHH);
                result = true;
                break;
            case 45232:
                this.readTimeDelayInfo(inStream, obj as TimeDelayInfo);
                result = true;
                break;
            case 45233:
                this.readCasinoTrnyLevelInfo(inStream, obj as CasinoTrnyLevelInfo);
                result = true;
                break;
            case 45234:
                this.readTBTTourneyResult(inStream, obj as TBTTourneyResult);
                result = true;
                break;
            case 45235:
                this.readTBTMinEndTime(inStream, obj as TBTMinEndTime);
                result = true;
                break;
            case 45236:
                this.readPlayerTourneyRank(inStream, obj as PlayerTourneyRank);
                result = true;
                break;
            case 45237:
                this.readStayIn(inStream, obj as StayIn);
                result = true;
                break;
            case 45238:
                this.readPostStayIn(inStream, obj as PostStayIn);
                result = true;
                break;
            case 45239:
                this.readTableMessage(inStream, obj as TableMessage);
                result = true;
                break;
            case 45242:
                this.readWaitlistCriteriaType(inStream, obj as WaitlistCriteriaType);
                result = true;
                break;
            case 45243:
                this.readJoinWaitlist(inStream, obj as JoinWaitlist);
                result = true;
                break;
            case 45244:
                this.readJoinWaitlistResponse(inStream, obj as JoinWaitlistResponse);
                result = true;
                break;
            case 45245:
                this.readUnjoinWaitlist(inStream, obj as UnjoinWaitlist);
                result = true;
                break;
            case 45246:
                this.readUnjoinAllWaitlist(inStream, obj as UnjoinAllWaitlist);
                result = true;
                break;
            case 45247:
                this.readJoinWaitlistRefresh(inStream, obj as JoinWaitlistRefresh);
                result = true;
                break;
            case 45248:
                this.readChatOffMessage(inStream, obj as ChatOffMessage);
                result = true;
                break;
            case 45249:
                this.readMTCTDynaLiteralUpdate(inStream, obj as MTCTDynaLiteralUpdate);
                result = true;
                break;
            case 45259:
                this.readQSParam(inStream, obj as QSParam);
                result = true;
                break;
            case 45265:
                this.readQSRequest(inStream, obj as QSRequest);
                result = true;
                break;
            case 45266:
                this.readQSError(inStream, obj as QSError);
                result = true;
                break;
            case 45267:
                this.readQSResponse(inStream, obj as QSResponse);
                result = true;
                break;
            case 45268:
                this.readQSResult(inStream, obj as QSResult);
                result = true;
                break;
            case 45269:
                this.readQSBlockSeatCancel(inStream, obj as QSBlockSeatCancel);
                result = true;
                break;
            case 45270:
                this.readPeerTables(inStream, obj as PeerTables);
                result = true;
                break;
            case 45271:
                this.readDealHighCards(inStream, obj as DealHighCards);
                result = true;
                break;
            case 45272:
                this.readMegaPotPrize(inStream, obj as MegaPotPrize);
                result = true;
                break;
            case 45277:
                this.readCalendarAlert(inStream, obj as CalendarAlert);
                result = true;
                break;
            case 45278:
                this.readSetCalendarAlert(inStream, obj as SetCalendarAlert);
                result = true;
                break;
            case 45283:
                this.readMTCTLevel(inStream, obj as MTCTLevel);
                result = true;
                break;
            case 45285:
                this.readPrePromoTicker(inStream, obj as PrePromoTicker);
                result = true;
                break;
            case 45286:
                this.readPromoUpdateTicker(inStream, obj as PromoUpdateTicker);
                result = true;
                break;
            case 45287:
                this.readRatHoleResponse(inStream, obj as RatHoleResponse);
                result = true;
                break;
            case 45288:
                this.readFXConversionData(inStream, obj as FXConversionData);
                result = true;
                break;
            case 45289:
                this.readMTCTLobbyData(inStream, obj as MTCTLobbyData);
                result = true;
                break;
            case 45291:
                this.readMTCTRegistrationChangeEvent(inStream, obj as MTCTRegistrationChangeEvent);
                result = true;
                break;
            case 45293:
                this.readMTCTStatusChangeEvent(inStream, obj as MTCTStatusChangeEvent);
                result = true;
                break;
            case 45294:
                this.readMTCTDeleteEvent(inStream, obj as MTCTDeleteEvent);
                result = true;
                break;
            case 45295:
                this.readMTCTLevelChangeEvent(inStream, obj as MTCTLevelChangeEvent);
                result = true;
                break;
            case 45296:
                this.readMTCTPlayerCountChangeEvent(inStream, obj as MTCTPlayerCountChangeEvent);
                result = true;
                break;
            case 45297:
                this.readMTCTPrizePoolChangeEvent(inStream, obj as MTCTPrizePoolChangeEvent);
                result = true;
                break;
            case 45298:
                this.readMTCTLobbySnapshotChange(inStream, obj as MTCTLobbySnapshotChange);
                result = true;
                break;
            case 45299:
                this.readScratchCardTicker(inStream, obj as ScratchCardTicker);
                result = true;
                break;
            case 45300:
                this.readRelocateTable(inStream, obj as RelocateTable);
                result = true;
                break;
            case 45301:
                this.readMTCTCustomPropertyChangeEvent(inStream, obj as MTCTCustomPropertyChangeEvent);
                result = true;
                break;
            case 45310:
                this.readMTCTPlayerKickedOut(inStream, obj as MTCTPlayerKickedOut);
                result = true;
                break;
            case 45312:
                this.readMTCTParticipantNamesOnRequest(inStream, obj as MTCTParticipantNamesOnRequest);
                result = true;
                break;
            case 45313:
                this.readRequestMTCTParticipantNamesOnSearchString(inStream, obj as RequestMTCTParticipantNamesOnSearchString);
                result = true;
                break;
            case 45315:
                this.readJoinTableQS(inStream, obj as JoinTableQS);
                result = true;
                break;
            case 45316:
                this.readJoinTableAutoBuyIn(inStream, obj as JoinTableAutoBuyIn);
                result = true;
                break;
            case 45317:
                this.readJoinTableSTTReplay(inStream, obj as JoinTableSTTReplay);
                result = true;
                break;
            case 45318:
                this.readSitResponse(inStream, obj as SitResponse);
                result = true;
                break;
            case 45319:
                this.readAutoBuyInError(inStream, obj as AutoBuyInError);
                result = true;
                break;
            case 45323:
                this.readBlockSeatCancel(inStream, obj as BlockSeatCancel);
                result = true;
                break;
            case 45324:
                this.readItaliaAuthorizationCodes(inStream, obj as ItaliaAuthorizationCodes);
                result = true;
                break;
            case 45325:
                this.readRequestAutoCheckOff(inStream, obj as RequestAutoCheckOff);
                result = true;
                break;
            case 45326:
                this.readRequestAutoCheckOn(inStream, obj as RequestAutoCheckOn);
                result = true;
                break;
            case 45327:
                this.readRequestAutoOptionsOptionsMappings(inStream, obj as RequestAutoOptionsOptionsMappings);
                result = true;
                break;
            case 45328:
                this.readRequestBuddiesList(inStream, obj as RequestBuddiesList);
                result = true;
                break;
            case 45329:
                this.readRequestChatOffDisabled(inStream, obj as RequestChatOffDisabled);
                result = true;
                break;
            case 45330:
                this.readRequestChatOffEnabled(inStream, obj as RequestChatOffEnabled);
                result = true;
                break;
            case 45331:
                this.readRequestCurrentPlayerRegistration(inStream, obj as RequestCurrentPlayerRegistration);
                result = true;
                break;
            case 45332:
                this.readRequestDealMakingExit(inStream, obj as RequestDealMakingExit);
                result = true;
                break;
            case 45333:
                this.readRequestDealMakingReject(inStream, obj as RequestDealMakingReject);
                result = true;
                break;
            case 45334:
                this.readRequestDealMakingView(inStream, obj as RequestDealMakingView);
                result = true;
                break;
            case 45336:
                this.readRequestFoldToAnyBetOn(inStream, obj as RequestFoldToAnyBetOn);
                result = true;
                break;
            case 45340:
                this.readRequestListOfPVTTableTypes(inStream, obj as RequestListOfPVTTableTypes);
                result = true;
                break;
            case 45341:
                this.readRequestMtctRebuyDialogCancel(inStream, obj as RequestMtctRebuyDialogCancel);
                result = true;
                break;
            case 45343:
                this.readRequestNoBuddiesList(inStream, obj as RequestNoBuddiesList);
                result = true;
                break;
            case 45345:
                this.readRequestRegainPlayer(inStream, obj as RequestRegainPlayer);
                result = true;
                break;
            case 45346:
                this.readRequestFoldToAnyBetOff(inStream, obj as RequestFoldToAnyBetOff);
                result = true;
                break;
            case 45347:
                this.readRequestSendJackpotSnapshots(inStream, obj as RequestSendJackpotSnapshots);
                result = true;
                break;
            case 45356:
                this.readRequestTourneyPrizeInfo(inStream, obj as RequestTourneyPrizeInfo);
                result = true;
                break;
            case 45357:
                this.readRequestWaitingPlayers(inStream, obj as RequestWaitingPlayers);
                result = true;
                break;
            case 45360:
                this.readRequestAutopostBlindAndFoldOff(inStream, obj as RequestAutopostBlindAndFoldOff);
                result = true;
                break;
            case 45361:
                this.readRequestAutoPostBlindAndFoldOn(inStream, obj as RequestAutoPostBlindAndFoldOn);
                result = true;
                break;
            case 45362:
                this.readRequestAutoPostBlindOff(inStream, obj as RequestAutoPostBlindOff);
                result = true;
                break;
            case 45363:
                this.readRequestAutoPostBlindOn(inStream, obj as RequestAutoPostBlindOn);
                result = true;
                break;
            case 45364:
                this.readRequestAutorebuyOff(inStream, obj as RequestAutorebuyOff);
                result = true;
                break;
            case 45365:
                this.readRequestAutoRebuyOn(inStream, obj as RequestAutoRebuyOn);
                result = true;
                break;
            case 45366:
                this.readRequestDealMakingAllConfirmed(inStream, obj as RequestDealMakingAllConfirmed);
                result = true;
                break;
            case 45367:
                this.readRequestDealMakingConfirm(inStream, obj as RequestDealMakingConfirm);
                result = true;
                break;
            case 45368:
                this.readRequestDealMakingNo(inStream, obj as RequestDealMakingNo);
                result = true;
                break;
            case 45369:
                this.readRequestDealMakingYes(inStream, obj as RequestDealMakingYes);
                result = true;
                break;
            case 45370:
                this.readRequestGameStatus(inStream, obj as RequestGameStatus);
                result = true;
                break;
            case 45372:
                this.readRequestLeaveTable(inStream, obj as RequestLeaveTable);
                result = true;
                break;
            case 45373:
                this.readRequestMuckLosingHandOff(inStream, obj as RequestMuckLosingHandOff);
                result = true;
                break;
            case 45374:
                this.readRequestMuckLosingHandOn(inStream, obj as RequestMuckLosingHandOn);
                result = true;
                break;
            case 45375:
                this.readRequestSitoutAtNextBBOff(inStream, obj as RequestSitoutAtNextBBOff);
                result = true;
                break;
            case 45376:
                this.readRequestSitoutAtNextBBOn(inStream, obj as RequestSitoutAtNextBBOn);
                result = true;
                break;
            case 45377:
                this.readRequestSitoutNextHandOff(inStream, obj as RequestSitoutNextHandOff);
                result = true;
                break;
            case 45378:
                this.readRequestSitoutNextHandOn(inStream, obj as RequestSitoutNextHandOn);
                result = true;
                break;
            case 45379:
                this.readRequestWaitforBigblindOff(inStream, obj as RequestWaitforBigblindOff);
                result = true;
                break;
            case 45380:
                this.readRequestWaitForBigBlindOn(inStream, obj as RequestWaitForBigBlindOn);
                result = true;
                break;
            case 45381:
                this.readRequestAdviseToBuyMoreChips(inStream, obj as RequestAdviseToBuyMoreChips);
                result = true;
                break;
            case 45383:
                this.readRequestCloseGameTable(inStream, obj as RequestCloseGameTable);
                result = true;
                break;
            case 45384:
                this.readRequestDealMakingEnded(inStream, obj as RequestDealMakingEnded);
                result = true;
                break;
            case 45385:
                this.readRequestDealMakingGameClosed(inStream, obj as RequestDealMakingGameClosed);
                result = true;
                break;
            case 45386:
                this.readRequestDealMakingGameToContinue(inStream, obj as RequestDealMakingGameToContinue);
                result = true;
                break;
            case 45387:
                this.readRequestDealMakingHide(inStream, obj as RequestDealMakingHide);
                result = true;
                break;
            case 45388:
                this.readRequestDealMakingPayoutVetoed(inStream, obj as RequestDealMakingPayoutVetoed);
                result = true;
                break;
            case 45389:
                this.readRequestDealMakingStarted(inStream, obj as RequestDealMakingStarted);
                result = true;
                break;
            case 45390:
                this.readRequestDealMakingTimeout(inStream, obj as RequestDealMakingTimeout);
                result = true;
                break;
            case 45391:
                this.readRequestDealMakingVetoed(inStream, obj as RequestDealMakingVetoed);
                result = true;
                break;
            case 45392:
                this.readRequestEndOfTbt(inStream, obj as RequestEndOfTbt);
                result = true;
                break;
            case 45393:
                this.readRequestEndOfTheGame(inStream, obj as RequestEndOfTheGame);
                result = true;
                break;
            case 45398:
                this.readRequestKillGametableSilently(inStream, obj as RequestKillGametableSilently);
                result = true;
                break;
            case 45403:
                this.readRequestServerClosckReSync(inStream, obj as RequestServerClosckReSync);
                result = true;
                break;
            case 45404:
                this.readRequestShowSitButtons(inStream, obj as RequestShowSitButtons);
                result = true;
                break;
            case 45411:
                this.readResponseAddonFailureAddonExceeded(inStream, obj as ResponseAddonFailureAddonExceeded);
                result = true;
                break;
            case 45412:
                this.readResponseAddonFailureDBError(inStream, obj as ResponseAddonFailureDBError);
                result = true;
                break;
            case 45413:
                this.readResponseAddonFailureInsufficientBalance(inStream, obj as ResponseAddonFailureInsufficientBalance);
                result = true;
                break;
            case 45414:
                this.readResponseAddonSuccess(inStream, obj as ResponseAddonSuccess);
                result = true;
                break;
            case 45415:
                this.readResponseAllinResetFailed(inStream, obj as ResponseAllinResetFailed);
                result = true;
                break;
            case 45416:
                this.readResponseAllinResetNotRequired(inStream, obj as ResponseAllinResetNotRequired);
                result = true;
                break;
            case 45417:
                this.readResponseAllinResetPending(inStream, obj as ResponseAllinResetPending);
                result = true;
                break;
            case 45418:
                this.readResponseAllinResetSuccess(inStream, obj as ResponseAllinResetSuccess);
                result = true;
                break;
            case 45421:
                this.readResponseBuyMoreChipsDBError(inStream, obj as ResponseBuyMoreChipsDBError);
                result = true;
                break;
            case 45422:
                this.readResponseBuyMoreChipsNotAPlayer(inStream, obj as ResponseBuyMoreChipsNotAPlayer);
                result = true;
                break;
            case 45423:
                this.readResponseBuyMoreChipsNotAvailable(inStream, obj as ResponseBuyMoreChipsNotAvailable);
                result = true;
                break;
            case 45424:
                this.readResponseBuyMoreChipsPlaying(inStream, obj as ResponseBuyMoreChipsPlaying);
                result = true;
                break;
            case 45425:
                this.readResponseBuyMoreChipsSuccess(inStream, obj as ResponseBuyMoreChipsSuccess);
                result = true;
                break;
            case 45429:
                this.readResponseChatRequestPermissionDenied(inStream, obj as ResponseChatRequestPermissionDenied);
                result = true;
                break;
            case 45430:
                this.readResponseDealMakingConfirmSuccess(inStream, obj as ResponseDealMakingConfirmSuccess);
                result = true;
                break;
            case 45431:
                this.readResponseDealMakingRejectSuccess(inStream, obj as ResponseDealMakingRejectSuccess);
                result = true;
                break;
            case 45432:
                this.readResponseDealMakingSubmitFailure(inStream, obj as ResponseDealMakingSubmitFailure);
                result = true;
                break;
            case 45433:
                this.readResponseDealMakingSubmitSuccess(inStream, obj as ResponseDealMakingSubmitSuccess);
                result = true;
                break;
            case 45434:
                this.readResponseGeneralErrorMessage(inStream, obj as ResponseGeneralErrorMessage);
                result = true;
                break;
            case 45436:
                this.readResponseHandHistoryFail(inStream, obj as ResponseHandHistoryFail);
                result = true;
                break;
            case 45437:
                this.readResponseHandHistorySuccess(inStream, obj as ResponseHandHistorySuccess);
                result = true;
                break;
            case 45438:
                this.readResponseHURematchAccepted(inStream, obj as ResponseHURematchAccepted);
                result = true;
                break;
            case 45439:
                this.readResponseHURematchRejected(inStream, obj as ResponseHURematchRejected);
                result = true;
                break;
            case 45440:
                this.readResponseHURematchTimeout(inStream, obj as ResponseHURematchTimeout);
                result = true;
                break;
            case 45441:
                this.readResponseJPWelcomeMsg(inStream, obj as ResponseJPWelcomeMsg);
                result = true;
                break;
            case 45442:
                this.readResponseNOBuddiesListSuccess(inStream, obj as ResponseNOBuddiesListSuccess);
                result = true;
                break;
            case 45444:
                this.readResponseRebuyAddonClosed(inStream, obj as ResponseRebuyAddonClosed);
                result = true;
                break;
            case 45445:
                this.readResponseRebuyFailureDBError(inStream, obj as ResponseRebuyFailureDBError);
                result = true;
                break;
            case 45446:
                this.readResponseRebuyFailureImproperChipBalance(inStream, obj as ResponseRebuyFailureImproperChipBalance);
                result = true;
                break;
            case 45447:
                this.readResponseRebuyFailureInsufficientBalance(inStream, obj as ResponseRebuyFailureInsufficientBalance);
                result = true;
                break;
            case 45448:
                this.readResponseRebuyFailureRebuysExceeded(inStream, obj as ResponseRebuyFailureRebuysExceeded);
                result = true;
                break;
            case 45449:
                this.readResponseRebuySuccess(inStream, obj as ResponseRebuySuccess);
                result = true;
                break;
            case 45450:
                this.readResponseRebuySuccessPending(inStream, obj as ResponseRebuySuccessPending);
                result = true;
                break;
            case 45451:
                this.readResponseRegainPlayerFailed(inStream, obj as ResponseRegainPlayerFailed);
                result = true;
                break;
            case 45452:
                this.readResponseRegainPlayerSuccess(inStream, obj as ResponseRegainPlayerSuccess);
                result = true;
                break;
            case 45454:
                this.readResponseShowCardsOptionShow(inStream, obj as ResponseShowCardsOptionShow);
                result = true;
                break;
            case 45458:
                this.readResponseReceivedExpiredOptions(inStream, obj as ResponseReceivedExpiredOptions);
                result = true;
                break;
            case 45459:
                this.readResponseShowCardsOptionDontShow(inStream, obj as ResponseShowCardsOptionDontShow);
                result = true;
                break;
            case 45463:
                this.readResponseShowCardsOptionMuck(inStream, obj as ResponseShowCardsOptionMuck);
                result = true;
                break;
            case 45477:
                this.readResponseBuyMoreChipsAllowedAmount(inStream, obj as ResponseBuyMoreChipsAllowedAmount);
                result = true;
                break;
            case 45482:
                this.readRGLimitErrorMsg(inStream, obj as RGLimitErrorMsg);
                result = true;
                break;
            case 45484:
                this.readItaliaAuthorizationCodesForTable(inStream, obj as ItaliaAuthorizationCodesForTable);
                result = true;
                break;
            case 45485:
                this.readPokerChatRequest(inStream, obj as PokerChatRequest);
                result = true;
                break;
            case 45487:
                this.readRequestUserBalance(inStream, obj as RequestUserBalance);
                result = true;
                break;
            case 45490:
                this.readRequestAddMorePlayMoney(inStream, obj as RequestAddMorePlayMoney);
                result = true;
                break;
            case 45491:
                this.readWaitingPlayersForLobby(inStream, obj as WaitingPlayersForLobby);
                result = true;
                break;
            case 45492:
                this.readArjelEndOfTheGameEventReq(inStream, obj as ArjelEndOfTheGameEventReq);
                result = true;
                break;
            case 45493:
                this.readArjelEndOfTheGameEventRes(inStream, obj as ArjelEndOfTheGameEventRes);
                result = true;
                break;
            case 45494:
                this.readArjelBlindsRoundData(inStream, obj as ArjelBlindsRoundData);
                result = true;
                break;
            case 45495:
                this.readArjelPlayerCards(inStream, obj as ArjelPlayerCards);
                result = true;
                break;
            case 45496:
                this.readArjelGameAllPlayersAction(inStream, obj as ArjelGameAllPlayersAction);
                result = true;
                break;
            case 45497:
                this.readArjelGameLongValue(inStream, obj as ArjelGameLongValue);
                result = true;
                break;
            case 45498:
                this.readArjelGameCards(inStream, obj as ArjelGameCards);
                result = true;
                break;
            case 45499:
                this.readArjelGameSummary(inStream, obj as ArjelGameSummary);
                result = true;
                break;
            case 45500:
                this.readArjelBlindsRoundPlayerData(inStream, obj as ArjelBlindsRoundPlayerData);
                result = true;
                break;
            case 45501:
                this.readArjelGamePlayerAction(inStream, obj as ArjelGamePlayerAction);
                result = true;
                break;
            case 45502:
                this.readArjelGameSummaryPlayerInfo(inStream, obj as ArjelGameSummaryPlayerInfo);
                result = true;
                break;
            case 45505:
                this.readResponseShowRabbitHunt(inStream, obj as ResponseShowRabbitHunt);
                result = true;
                break;
            case 45513:
                this.readFoldNShowOption(inStream, obj as FoldNShowOption);
                result = true;
                break;
            case 45514:
                this.readResponseFoldNShowFirstCard(inStream, obj as ResponseFoldNShowFirstCard);
                result = true;
                break;
            case 45516:
                this.readResponseFoldNShowSecondCard(inStream, obj as ResponseFoldNShowSecondCard);
                result = true;
                break;
            case 45517:
                this.readResponseFoldNShowBoth(inStream, obj as ResponseFoldNShowBoth);
                result = true;
                break;
            case 45521:
                this.readProPlayerList(inStream, obj as ProPlayerList);
                result = true;
                break;
            case 45522:
                this.readCompositeLobbyMessage(inStream, obj as CompositeLobbyMessage);
                result = true;
                break;
            case 45523:
                this.readCompositeTableMessage(inStream, obj as CompositeTableMessage);
                result = true;
                break;
            case 45524:
                this.readPlayerBalances(inStream, obj as PlayerBalances);
                result = true;
                break;
            case 45525:
                this.readPlayerStates(inStream, obj as PlayerStates);
                result = true;
                break;
            case 45537:
                this.readBeginnerStatus(inStream, obj as BeginnerStatus);
                result = true;
                break;
            case 45538:
                this.readLobbyBigBlindChange(inStream, obj as LobbyBigBlindChange);
                result = true;
                break;
            case 45539:
                this.readDealerSbAndBbPositions(inStream, obj as DealerSbAndBbPositions);
                result = true;
                break;
            case 45540:
                this.readResponseBuyMoreChipsInsuffPokerFund(inStream, obj as ResponseBuyMoreChipsInsuffPokerFund);
                result = true;
                break;
            case 45541:
                this.readResponseBuyMoreChipsInsuffPartnerFund(inStream, obj as ResponseBuyMoreChipsInsuffPartnerFund);
                result = true;
                break;
            case 45542:
                this.readResponseBuyMoreChipsPokerTxnError(inStream, obj as ResponseBuyMoreChipsPokerTxnError);
                result = true;
                break;
            case 45543:
                this.readResponseBuyMoreChipsWalletTxnError(inStream, obj as ResponseBuyMoreChipsWalletTxnError);
                result = true;
                break;
            case 45544:
                this.readUserAllBalance(inStream, obj as UserAllBalance);
                result = true;
                break;
            case 45545:
                this.readRequestUserBalancePartnerPlayer(inStream, obj as RequestUserBalancePartnerPlayer);
                result = true;
                break;
            case 45546:
                this.readResponseBuyMoreChipsMultiple(inStream, obj as ResponseBuyMoreChipsMultiple);
                result = true;
                break;
            case 45550:
                this.readRequestCloseClient(inStream, obj as RequestCloseClient);
                result = true;
                break;
            case 45551:
                this.readAnonymousPlayerInfo(inStream, obj as AnonymousPlayerInfo);
                result = true;
                break;
            case 45552:
                this.readResponseBetAndWinSummary(inStream, obj as ResponseBetAndWinSummary);
                result = true;
                break;
            case 45553:
                this.readArjelEndOfTheGameSeatData(inStream, obj as ArjelEndOfTheGameSeatData);
                result = true;
                break;
            case 45557:
                this.readPoolAvailabilityRequest(inStream, obj as PoolAvailabilityRequest);
                result = true;
                break;
            case 45558:
                this.readJoinPoolRequest(inStream, obj as JoinPoolRequest);
                result = true;
                break;
            case 45559:
                this.readLeavePoolRequest(inStream, obj as LeavePoolRequest);
                result = true;
                break;
            case 45560:
                this.readPoolAvailabilityResponse(inStream, obj as PoolAvailabilityResponse);
                result = true;
                break;
            case 45561:
                this.readJoinPoolResponse(inStream, obj as JoinPoolResponse);
                result = true;
                break;
            case 45566:
                this.readReqQuickFoldToBetOrRaiseOn(inStream, obj as ReqQuickFoldToBetOrRaiseOn);
                result = true;
                break;
            case 45567:
                this.readReqQuickFoldToBetOrRaiseOff(inStream, obj as ReqQuickFoldToBetOrRaiseOff);
                result = true;
                break;
            case 45568:
                this.readReqSitoutNextBlindOn(inStream, obj as ReqSitoutNextBlindOn);
                result = true;
                break;
            case 45569:
                this.readReqSitoutNextBlindOff(inStream, obj as ReqSitoutNextBlindOff);
                result = true;
                break;
            case 45570:
                this.readRequestFoldAndWatchOn(inStream, obj as RequestFoldAndWatchOn);
                result = true;
                break;
            case 45571:
                this.readRequestFoldAndWatchOff(inStream, obj as RequestFoldAndWatchOff);
                result = true;
                break;
            case 45572:
                this.readPoolRebuyRequest(inStream, obj as PoolRebuyRequest);
                result = true;
                break;
            case 45573:
                this.readPoolRebuyResponse(inStream, obj as PoolRebuyResponse);
                result = true;
                break;
            case 45574:
                this.readAddPlayMoneyToPoolRequest(inStream, obj as AddPlayMoneyToPoolRequest);
                result = true;
                break;
            case 45575:
                this.readAddPlayMoneyToPoolResponse(inStream, obj as AddPlayMoneyToPoolResponse);
                result = true;
                break;
            case 45576:
                this.readBackToPoolRequest(inStream, obj as BackToPoolRequest);
                result = true;
                break;
            case 45577:
                this.readPoolStartGameNotification(inStream, obj as PoolStartGameNotification);
                result = true;
                break;
            case 45578:
                this.readPoolWaitingNotification(inStream, obj as PoolWaitingNotification);
                result = true;
                break;
            case 45579:
                this.readPoolEndGameNotification(inStream, obj as PoolEndGameNotification);
                result = true;
                break;
            case 45580:
                this.readPoolSitOutNotification(inStream, obj as PoolSitOutNotification);
                result = true;
                break;
            case 45581:
                this.readPoolKickoutNotification(inStream, obj as PoolKickoutNotification);
                result = true;
                break;
            case 45582:
                this.readReqNextHandOffFoldAndWatch(inStream, obj as ReqNextHandOffFoldAndWatch);
                result = true;
                break;
            case 45583:
                this.readPoolRequestUserBalance(inStream, obj as PoolRequestUserBalance);
                result = true;
                break;
            case 45584:
                this.readPoolUserBalance(inStream, obj as PoolUserBalance);
                result = true;
                break;
            case 45585:
                this.readReqNextHandOnFoldAndWatch(inStream, obj as ReqNextHandOnFoldAndWatch);
                result = true;
                break;
            case 45586:
                this.readRequestEndOfTheGameFastPoker(inStream, obj as RequestEndOfTheGameFastPoker);
                result = true;
                break;
            case 45587:
                this.readPoolRequestUserReconnect(inStream, obj as PoolRequestUserReconnect);
                result = true;
                break;
            case 45588:
                this.readPoolUserReconnectResponse(inStream, obj as PoolUserReconnectResponse);
                result = true;
                break;
            case 45589:
                this.readPlayerEntryStatus(inStream, obj as PlayerEntryStatus);
                result = true;
                break;
            case 45590:
                this.readPoolShutdownNotification(inStream, obj as PoolShutdownNotification);
                result = true;
                break;
            case 45591:
                this.readShowScreenOnPool(inStream, obj as ShowScreenOnPool);
                result = true;
                break;
            case 45592:
                this.readPrevPlayingFastPokerPools(inStream, obj as PrevPlayingFastPokerPools);
                result = true;
                break;
            case 45593:
                this.readPoolFraudPlayerKickoutNotification(inStream, obj as PoolFraudPlayerKickoutNotification);
                result = true;
                break;
            case 45594:
                this.readQFToAnyBetPlayerQuickFolded(inStream, obj as QFToAnyBetPlayerQuickFolded);
                result = true;
                break;
            case 45595:
                this.readRequestPoolSitout(inStream, obj as RequestPoolSitout);
                result = true;
                break;
            case 45596:
                this.readHyperLinkedDealerChat(inStream, obj as HyperLinkedDealerChat);
                result = true;
                break;
            case 45597:
                this.readResponseRebuyAddonAccClosureFailure(inStream, obj as ResponseRebuyAddonAccClosureFailure);
                result = true;
                break;
            case 45598:
                this.readRecommendedStakeLimit(inStream, obj as RecommendedStakeLimit);
                result = true;
                break;
            case 45599:
                this.readRecommendedStakesMessage(inStream, obj as RecommendedStakesMessage);
                result = true;
                break;
            case 45600:
                this.readPokerPlayerProtectionInfo(inStream, obj as PokerPlayerProtectionInfo);
                result = true;
                break;
            case 45601:
                this.readRequestJoinTableWaitlist(inStream, obj as RequestJoinTableWaitlist);
                result = true;
                break;
            case 45602:
                this.readResponseJoinTableWaitlist(inStream, obj as ResponseJoinTableWaitlist);
                result = true;
                break;
            case 45603:
                this.readRequestUnjoinTableWaitlist(inStream, obj as RequestUnjoinTableWaitlist);
                result = true;
                break;
            case 45604:
                this.readResponseUnjoinTableWaitlist(inStream, obj as ResponseUnjoinTableWaitlist);
                result = true;
                break;
            case 45605:
                this.readGlobalWaitlistCriteria(inStream, obj as GlobalWaitlistCriteria);
                result = true;
                break;
            case 45606:
                this.readRequestJoinGlobalWaitlist(inStream, obj as RequestJoinGlobalWaitlist);
                result = true;
                break;
            case 45607:
                this.readResponseJoinGlobalWaitlist(inStream, obj as ResponseJoinGlobalWaitlist);
                result = true;
                break;
            case 45608:
                this.readRequestUnjoinGlobalWaitlist(inStream, obj as RequestUnjoinGlobalWaitlist);
                result = true;
                break;
            case 45609:
                this.readResponseUnjoinGlobalWaitlist(inStream, obj as ResponseUnjoinGlobalWaitlist);
                result = true;
                break;
            case 45611:
                this.readRequestJoinOtherDomainWaitlist(inStream, obj as RequestJoinOtherDomainWaitlist);
                result = true;
                break;
            case 45612:
                this.readResponseJoinOtherDomainWaitlist(inStream, obj as ResponseJoinOtherDomainWaitlist);
                result = true;
                break;
            case 45613:
                this.readRequestUnjoinOtherDomainWaitlist(inStream, obj as RequestUnjoinOtherDomainWaitlist);
                result = true;
                break;
            case 45614:
                this.readResponseUnjoinOtherDomainWaitlist(inStream, obj as ResponseUnjoinOtherDomainWaitlist);
                result = true;
                break;
            case 45616:
                this.readArjelGTPlayerData(inStream, obj as ArjelGTPlayerData);
                result = true;
                break;
            case 45618:
                this.readChallengeDetails(inStream, obj as ChallengeDetails);
                result = true;
                break;
            case 45619:
                this.readAcceptChallengeRequest(inStream, obj as AcceptChallengeRequest);
                result = true;
                break;
            case 45620:
                this.readAcceptChallengeResponse(inStream, obj as AcceptChallengeResponse);
                result = true;
                break;
            case 45621:
                this.readCreateChallengeHUCashRequest(inStream, obj as CreateChallengeHUCashRequest);
                result = true;
                break;
            case 45622:
                this.readCreateChallengeHUCashResponse(inStream, obj as CreateChallengeHUCashResponse);
                result = true;
                break;
            case 45623:
                this.readCreateChallengeHUSNGRequest(inStream, obj as CreateChallengeHUSNGRequest);
                result = true;
                break;
            case 45624:
                this.readCreateChallengeHUSNGResponse(inStream, obj as CreateChallengeHUSNGResponse);
                result = true;
                break;
            case 45625:
                this.readRejectChallengeRequest(inStream, obj as RejectChallengeRequest);
                result = true;
                break;
            case 45626:
                this.readRejectChallengeResponse(inStream, obj as RejectChallengeResponse);
                result = true;
                break;
            case 45627:
                this.readChallengeInvitationRequest(inStream, obj as ChallengeInvitationRequest);
                result = true;
                break;
            case 45628:
                this.readChallengeCancelledMessage(inStream, obj as ChallengeCancelledMessage);
                result = true;
                break;
            case 45629:
                this.readChallengeHistoryData(inStream, obj as ChallengeHistoryData);
                result = true;
                break;
            case 45630:
                this.readChallengeHistoryRequest(inStream, obj as ChallengeHistoryRequest);
                result = true;
                break;
            case 45631:
                this.readChallengeHistoryResponse(inStream, obj as ChallengeHistoryResponse);
                result = true;
                break;
            case 45632:
                this.readChallengeTemplate(inStream, obj as ChallengeTemplate);
                result = true;
                break;
            case 45633:
                this.readChallengeTemplatesList(inStream, obj as ChallengeTemplatesList);
                result = true;
                break;
            case 45634:
                this.readRequestListOfChallengeTemplates(inStream, obj as RequestListOfChallengeTemplates);
                result = true;
                break;
            case 45635:
                this.readDeletePrivateTableRequest(inStream, obj as DeletePrivateTableRequest);
                result = true;
                break;
            case 45636:
                this.readDeletePrivateTableResponse(inStream, obj as DeletePrivateTableResponse);
                result = true;
                break;
            case 45637:
                this.readPrivateTableInvitationRequest(inStream, obj as PrivateTableInvitationRequest);
                result = true;
                break;
            case 45638:
                this.readCreatePrivateTableResponse(inStream, obj as CreatePrivateTableResponse);
                result = true;
                break;
            case 45639:
                this.readCreatePrivateTableRequest(inStream, obj as CreatePrivateTableRequest);
                result = true;
                break;
            case 45640:
                this.readNotifyPrivateTableCreator(inStream, obj as NotifyPrivateTableCreator);
                result = true;
                break;
            case 45641:
                this.readHUSNGRankResultWithRematchRequest(inStream, obj as HUSNGRankResultWithRematchRequest);
                result = true;
                break;
            case 45642:
                this.readRequestHUSNGRematchAccepted(inStream, obj as RequestHUSNGRematchAccepted);
                result = true;
                break;
            case 45643:
                this.readRequestHUSNGRematchRejected(inStream, obj as RequestHUSNGRematchRejected);
                result = true;
                break;
            case 45644:
                this.readRequestHUSNGRematchAcceptedAck(inStream, obj as RequestHUSNGRematchAcceptedAck);
                result = true;
                break;
            case 45645:
                this.readResponseHUSNGRematchAccepted(inStream, obj as ResponseHUSNGRematchAccepted);
                result = true;
                break;
            case 45646:
                this.readResponseHUSNGRematchRejected(inStream, obj as ResponseHUSNGRematchRejected);
                result = true;
                break;
            case 45647:
                this.readResponseHUSNGRematchTimeout(inStream, obj as ResponseHUSNGRematchTimeout);
                result = true;
                break;
            case 45648:
                this.readResponseHUSNGRematchBuyInFailed(inStream, obj as ResponseHUSNGRematchBuyInFailed);
                result = true;
                break;
            case 45649:
                this.readSNGRankResultWithReplayRequest(inStream, obj as SNGRankResultWithReplayRequest);
                result = true;
                break;
            case 45650:
                this.readTournamentRegisteredPlayerInfo(inStream, obj as TournamentRegisteredPlayerInfo);
                result = true;
                break;
            case 45651:
                this.readCanJoinPrivateTableRequest(inStream, obj as CanJoinPrivateTableRequest);
                result = true;
                break;
            case 45652:
                this.readCanJoinPrivateTableResponse(inStream, obj as CanJoinPrivateTableResponse);
                result = true;
                break;
            case 45653:
                this.readUserFreeRollList(inStream, obj as UserFreeRollList);
                result = true;
                break;
            case 45654:
                this.readAddedFreeRoll(inStream, obj as AddedFreeRoll);
                result = true;
                break;
            case 45655:
                this.readRemovedFreeRoll(inStream, obj as RemovedFreeRoll);
                result = true;
                break;
            case 45656:
                this.readTableStakesInfoRequest(inStream, obj as TableStakesInfoRequest);
                result = true;
                break;
            case 45657:
                this.readTableStakesInfoResponse(inStream, obj as TableStakesInfoResponse);
                result = true;
                break;
            case 45658:
                this.readUncalledBetReturn(inStream, obj as UncalledBetReturn);
                result = true;
                break;
            case 45659:
                this.readTableStakesInfo(inStream, obj as TableStakesInfo);
                result = true;
                break;
            case 45660:
                this.readTableStakeLevelInfoRequest(inStream, obj as TableStakeLevelInfoRequest);
                result = true;
                break;
            case 45661:
                this.readTableStakeLevelInfoResponse(inStream, obj as TableStakeLevelInfoResponse);
                result = true;
                break;
            case 45662:
                this.readContentLockingAccessDeniedResponse(inStream, obj as ContentLockingAccessDeniedResponse);
                result = true;
                break;
            case 45663:
                this.readMTTournamentFinalTable(inStream, obj as MTTournamentFinalTable);
                result = true;
                break;
            case 45664:
                this.readResponseBuyMoreChipsNotAllowed(inStream, obj as ResponseBuyMoreChipsNotAllowed);
                result = true;
                break;
            case 45665:
                this.readPoolForcedSitOutNotification(inStream, obj as PoolForcedSitOutNotification);
                result = true;
                break;
            case 45670:
                this.readResponseRebuyGenericFailure(inStream, obj as ResponseRebuyGenericFailure);
                result = true;
                break;
            case 45671:
                this.readResponseRebuyAddonGenericFailure(inStream, obj as ResponseRebuyAddonGenericFailure);
                result = true;
                break;
            case 45673:
                this.readSitOutInfoNotification(inStream, obj as SitOutInfoNotification);
                result = true;
                break;
            case 45674:
                this.readTournamentBackendUnRegistrationsInfo(inStream, obj as TournamentBackendUnRegistrationsInfo);
                result = true;
                break;
            case 45675:
                this.readTournamentBackendUnRegistrationsInfoAck(inStream, obj as TournamentBackendUnRegistrationsInfoAck);
                result = true;
                break;
            case 45676:
                this.readTournamentShowAddonPopup(inStream, obj as TournamentShowAddonPopup);
                result = true;
                break;
            case 45678:
                this.readAppFriendsListRequest(inStream, obj as AppFriendsListRequest);
                result = true;
                break;
            case 45679:
                this.readAppFriendsListResponse(inStream, obj as AppFriendsListResponse);
                result = true;
                break;
            case 45680:
                this.readGamingSessionTimedOutPopUp(inStream, obj as GamingSessionTimedOutPopUp);
                result = true;
                break;
            case 45681:
                this.readUnblockGamingSessionTimedOutPlayer(inStream, obj as UnblockGamingSessionTimedOutPlayer);
                result = true;
                break;
            case 45682:
                this.readGamingSessionTimedOutPopUpAck(inStream, obj as GamingSessionTimedOutPopUpAck);
                result = true;
                break;
            case 45683:
                this.readSitOutWarningOn(inStream, obj as SitOutWarningOn);
                result = true;
                break;
            case 45684:
                this.readSitOutWarningOff(inStream, obj as SitOutWarningOff);
                result = true;
                break;
            case 45685:
                this.readPlayerBalanceInfo(inStream, obj as PlayerBalanceInfo);
                result = true;
                break;
            case 45686:
                this.readFRONTALLeavePoolResponse(inStream, obj as FRONTALLeavePoolResponse);
                result = true;
                break;
            case 45687:
                this.readFRONTALJoinPoolResponse(inStream, obj as FRONTALJoinPoolResponse);
                result = true;
                break;
            case 45688:
                this.readFRONTALPoolRebuyResponse(inStream, obj as FRONTALPoolRebuyResponse);
                result = true;
                break;
            case 45689:
                this.readSaveMTTPlayerAutoRebuyAddonPreferences(inStream, obj as SaveMTTPlayerAutoRebuyAddonPreferences);
                result = true;
                break;
            case 45690:
                this.readMTTPlayerAutoRebuyAddonPreferences(inStream, obj as MTTPlayerAutoRebuyAddonPreferences);
                result = true;
                break;
            case 45691:
                this.readCountryBlockEventRequest(inStream, obj as CountryBlockEventRequest);
                result = true;
                break;
            case 45692:
                this.readCountryBlockEventResponse(inStream, obj as CountryBlockEventResponse);
                result = true;
                break;
            case 45693:
                this.readPoolItaliaAuthorizationCodes(inStream, obj as PoolItaliaAuthorizationCodes);
                result = true;
                break;
            case 45696:
                this.readPoolPartnerRebuyRequest(inStream, obj as PoolPartnerRebuyRequest);
                result = true;
                break;
            case 45697:
                this.readPoolRequestUserBalancePartnerPlayer(inStream, obj as PoolRequestUserBalancePartnerPlayer);
                result = true;
                break;
            case 45698:
                this.readPoolUserAllBalance(inStream, obj as PoolUserAllBalance);
                result = true;
                break;
            case 45699:
                this.readShowPlayNow(inStream, obj as ShowPlayNow);
                result = true;
                break;
            case 45700:
                this.readDoPlayNow(inStream, obj as DoPlayNow);
                result = true;
                break;
            case 45701:
                this.readRemovePlayNow(inStream, obj as RemovePlayNow);
                result = true;
                break;
            case 45702:
                this.readTournamentParticipantRankInfo(inStream, obj as TournamentParticipantRankInfo);
                result = true;
                break;
            case 45703:
                this.readTourneyRegistrationStatus(inStream, obj as TourneyRegistrationStatus);
                result = true;
                break;
            case 45704:
                this.readPlayerStatuses(inStream, obj as PlayerStatuses);
                result = true;
                break;
            case 45705:
                this.readHUDAnonymousState(inStream, obj as HUDAnonymousState);
                result = true;
                break;
            case 45706:
                this.readSNGJPPlayNowRequest(inStream, obj as SNGJPPlayNowRequest);
                result = true;
                break;
            case 45707:
                this.readSNGJPPlayNowResponse(inStream, obj as SNGJPPlayNowResponse);
                result = true;
                break;
            case 45708:
                this.readSNGJPRegistrationRequest(inStream, obj as SNGJPRegistrationRequest);
                result = true;
                break;
            case 45709:
                this.readSNGJPRegistrationResponse(inStream, obj as SNGJPRegistrationResponse);
                result = true;
                break;
            case 45710:
                this.readSNGJPRegistrationInfo(inStream, obj as SNGJPRegistrationInfo);
                result = true;
                break;
            case 45711:
                this.readSNGJPUnRegistrationRequest(inStream, obj as SNGJPUnRegistrationRequest);
                result = true;
                break;
            case 45712:
                this.readSNGJPUnRegistrationResponse(inStream, obj as SNGJPUnRegistrationResponse);
                result = true;
                break;
            case 45713:
                this.readSNGJackpotInfo(inStream, obj as SNGJackpotInfo);
                result = true;
                break;
            case 45714:
                this.readSNGJPPayoutAnimation(inStream, obj as SNGJPPayoutAnimation);
                result = true;
                break;
            case 45715:
                this.readSNGJPPayoutInfo(inStream, obj as SNGJPPayoutInfo);
                result = true;
                break;
            case 45716:
                this.readSNGJPPrizeInfo(inStream, obj as SNGJPPrizeInfo);
                result = true;
                break;
            case 45717:
                this.readSNGJPBountyInfo(inStream, obj as SNGJPBountyInfo);
                result = true;
                break;
            case 45718:
                this.readSNGJPBountyWinner(inStream, obj as SNGJPBountyWinner);
                result = true;
                break;
            case 45719:
                this.readSNGJPGameAboutToStart(inStream, obj as SNGJPGameAboutToStart);
                result = true;
                break;
            case 45720:
                this.readSNGJPTournamentRankInfo(inStream, obj as SNGJPTournamentRankInfo);
                result = true;
                break;
            case 45721:
                this.readSNGJPRegistrationFailureDetails(inStream, obj as SNGJPRegistrationFailureDetails);
                result = true;
                break;
            case 45722:
                this.readSNGJPBountyAwardInfo(inStream, obj as SNGJPBountyAwardInfo);
                result = true;
                break;
            case 45724:
                this.readEnableChat(inStream, obj as EnableChat);
                result = true;
                break;
            case 45725:
                this.readWatchersInfo(inStream, obj as WatchersInfo);
                result = true;
                break;
            case 45726:
                this.readPlayersBountyInfo(inStream, obj as PlayersBountyInfo);
                result = true;
                break;
            case 45727:
                this.readProgressiveBountyAnimationInfo(inStream, obj as ProgressiveBountyAnimationInfo);
                result = true;
                break;
            case 45728:
                this.readTourneyPauseInfo(inStream, obj as TourneyPauseInfo);
                result = true;
                break;
            case 45729:
                this.readRemoveTourneyPauseInfo(inStream, obj as RemoveTourneyPauseInfo);
                result = true;
                break;
            case 45730:
                this.readPromoWinnerPlayerList(inStream, obj as PromoWinnerPlayerList);
                result = true;
                break;
            case 45731:
                this.readResponseLossLimitExceed(inStream, obj as ResponseLossLimitExceed);
                result = true;
                break;
            case 45732:
                this.readBetLimitErrorMessage(inStream, obj as BetLimitErrorMessage);
                result = true;
                break;
            case 45733:
                this.readLoginSessionTimeoutIntimation(inStream, obj as LoginSessionTimeoutIntimation);
                result = true;
                break;
            case 45734:
                this.readReadLatestRandomSeatingFlag(inStream, obj as ReadLatestRandomSeatingFlag);
                result = true;
                break;
            case 45735:
                this.readGameCurrencyDetailsList(inStream, obj as GameCurrencyDetailsList);
                result = true;
                break;
            case 45736:
                this.readGeneralRequestAddMorePlayMoney(inStream, obj as GeneralRequestAddMorePlayMoney);
                result = true;
                break;
            case 45737:
                this.readMorePlayMoneyGeneralResponse(inStream, obj as MorePlayMoneyGeneralResponse);
                result = true;
                break;
            case 45738:
                this.readReduceChips(inStream, obj as ReduceChips);
                result = true;
                break;
            case 45739:
                this.readReduceChipsResponse(inStream, obj as ReduceChipsResponse);
                result = true;
                break;
            case 45740:
                this.readSessionTimeRemainingInfo(inStream, obj as SessionTimeRemainingInfo);
                result = true;
                break;
            case 45741:
                this.readRealityCheckSummaryPopUp(inStream, obj as RealityCheckSummaryPopUp);
                result = true;
                break;
            case 45742:
                this.readRealityCheckSummaryPopUpAck(inStream, obj as RealityCheckSummaryPopUpAck);
                result = true;
                break;
            case 45743:
                this.readClosePoolEntry(inStream, obj as ClosePoolEntry);
                result = true;
                break;
            case 45744:
                this.readPlayerGameSessionData(inStream, obj as PlayerGameSessionData);
                result = true;
                break;
            case 45747:
                this.readPlayerVerificationStatus(inStream, obj as PlayerVerificationStatus);
                result = true;
                break;
            case 45748:
                this.readSelectedOptionNew(inStream, obj as SelectedOptionNew);
                result = true;
                break;
            case 45749:
                this.readReqShowEmoji(inStream, obj as ReqShowEmoji);
                result = true;
                break;
            case 45750:
                this.readShowEmoji(inStream, obj as ShowEmoji);
                result = true;
                break;
            case 45751:
                this.readShowRabbit(inStream, obj as ShowRabbit);
                result = true;
                break;
            case 45752:
                this.readReqEnableRabbit(inStream, obj as ReqEnableRabbit);
                result = true;
                break;
            case 45753:
                this.readBroadcastShowRabbit(inStream, obj as BroadcastShowRabbit);
                result = true;
                break;
            case 45754:
                this.readReqThrowAnimation(inStream, obj as ReqThrowAnimation);
                result = true;
                break;
            case 45755:
                this.readResThrowAnimation(inStream, obj as ResThrowAnimation);
                result = true;
                break;
            case 45756:
                this.readThrowAnimation(inStream, obj as ThrowAnimation);
                result = true;
                break;
            case 45757:
                this.readGetPokerDiamondBalance(inStream, obj as GetPokerDiamondBalance);
                result = true;
                break;
            case 45758:
                this.readProbabilityOfWinning(inStream, obj as ProbabilityOfWinning);
                result = true;
                break;
            case 45759:
                this.readDiamondBalance(inStream, obj as DiamondBalance);
                result = true;
                break;
            case 45761:
                this.readReqEnablePlayerRunittwiceState(inStream, obj as ReqEnablePlayerRunittwiceState);
                result = true;
                break;
            case 45762:
                this.readRequestHUPairTable(inStream, obj as RequestHUPairTable);
                result = true;
                break;
            case 45763:
                this.readHUPairTableSelectStatus(inStream, obj as HUPairTableSelectStatus);
                result = true;
                break;
            case 45764:
                this.readPlayersBountyWonInfo(inStream, obj as PlayersBountyWonInfo);
                result = true;
                break;
            case 45765:
                this.readITMInformation(inStream, obj as ITMInformation);
                result = true;
                break;
            case 45766:
                this.readShowHUPairTable(inStream, obj as ShowHUPairTable);
                result = true;
                break;
            case 45767:
                this.readRCPUActionAcknowledgement(inStream, obj as RCPUActionAcknowledgement);
                result = true;
                break;
            case 45768:
                this.readRequestJoinTableAutoBuyin(inStream, obj as RequestJoinTableAutoBuyin);
                result = true;
                break;
            case 45769:
                this.readDepositedFriendCount(inStream, obj as DepositedFriendCount);
                result = true;
                break;
            case 45770:
                this.readHUEvenGame(inStream, obj as HUEvenGame);
                result = true;
                break;
            case 45771:
                this.readRequestPlayerNoteId(inStream, obj as RequestPlayerNoteId);
                result = true;
                break;
            case 45772:
                this.readResponsePlayerNoteId(inStream, obj as ResponsePlayerNoteId);
                result = true;
                break;
            case 45774:
                this.readRequestUserClubDetails(inStream, obj as RequestUserClubDetails);
                result = true;
                break;
            case 45775:
                this.readUserClubDetails(inStream, obj as UserClubDetails);
                result = true;
                break;
            case 45776:
                this.readUserClubRemoved(inStream, obj as UserClubRemoved);
                result = true;
                break;
            case 45777:
                this.readResponseUserClubDetails(inStream, obj as ResponseUserClubDetails);
                result = true;
                break;
            case 45778:
                this.readClubNameApproved(inStream, obj as ClubNameApproved);
                result = true;
                break;
            case 45779:
                this.readUserClubMemeberShipApproved(inStream, obj as UserClubMemeberShipApproved);
                result = true;
                break;
            case 45780:
                this.readUserClubMemeberTypeChange(inStream, obj as UserClubMemeberTypeChange);
                result = true;
                break;
            case 45781:
                this.readRequestRealName(inStream, obj as RequestRealName);
                result = true;
                break;
            case 45782:
                this.readResponseRealName(inStream, obj as ResponseRealName);
                result = true;
                break;
            case 45784:
                this.readPlayerTableList(inStream, obj as PlayerTableList);
                result = true;
                break;
            case 45785:
                this.readTableBoosterTriggered(inStream, obj as TableBoosterTriggered);
                result = true;
                break;
            case 45786:
                this.readBroadcastTableBoosterTrigger(inStream, obj as BroadcastTableBoosterTrigger);
                result = true;
                break;
            case 45787:
                this.readTableBoosterWinners(inStream, obj as TableBoosterWinners);
                result = true;
                break;
            case 45788:
                this.readBoosterWinners(inStream, obj as BoosterWinners);
                result = true;
                break;
            case 45789:
                this.readFRSessionTimer(inStream, obj as FRSessionTimer);
                result = true;
                break;
            case 45796:
                this.readITMAdditionalInformation(inStream, obj as ITMAdditionalInformation);
                result = true;
                break;
            case 45797:
                this.readResponseGermanTaxValue(inStream, obj as ResponseGermanTaxValue);
                result = true;
                break;
            case 45798:
                this.readTournamentLateRegistrationDetails(inStream, obj as TournamentLateRegistrationDetails);
                result = true;
                break;
            case 45799:
                this.readLossLimitDetails(inStream, obj as LossLimitDetails);
                result = true;
                break;
            case 45800:
                this.readLossLimitResponse(inStream, obj as LossLimitResponse);
                result = true;
                break;
            case 45801:
                this.readSessionLimitsResponse(inStream, obj as SessionLimitsResponse);
                result = true;
                break;
            case 45802:
                this.readResponseFoldNShowCards(inStream, obj as ResponseFoldNShowCards);
                result = true;
                break;
            case 45803:
                this.readRequestCashoutEquity(inStream, obj as RequestCashoutEquity);
                result = true;
                break;
            case 45804:
                this.readPlayerCashedOutEquityStatus(inStream, obj as PlayerCashedOutEquityStatus);
                result = true;
                break;
            case 45805:
                this.readPlayerCashoutEquityDetails(inStream, obj as PlayerCashoutEquityDetails);
                result = true;
                break;
            case 45806:
                this.readPlayersCashoutEquityDetails(inStream, obj as PlayersCashoutEquityDetails);
                result = true;
                break;
            case 45807:
                this.readResponseCashoutEquity(inStream, obj as ResponseCashoutEquity);
                result = true;
                break;
            case 45810:
                this.readShowCards2(inStream, obj as ShowCards2);
                result = true;
                break;
            case 45813:
                this.readPlayerATHStrike(inStream, obj as PlayerATHStrike);
                result = true;
                break;
            case 45814:
                this.readATHStrikeHandCountConfiguration(inStream, obj as ATHStrikeHandCountConfiguration);
                result = true;
                break;
            case 45816:
                this.readResponseUserTableBalanceReduceChips(inStream, obj as ResponseUserTableBalanceReduceChips);
                result = true;
                break;
            case 45817:
                this.readTableReduceBalanceLimit(inStream, obj as TableReduceBalanceLimit);
                result = true;
                break;
            case 45818:
                this.readRequestTableLimitBalance(inStream, obj as RequestTableLimitBalance);
                result = true;
                break;
            case 45819:
                this.readPlayedGamesCount(inStream, obj as PlayedGamesCount);
                result = true;
                break;
            case 45820:
                this.readCanLeaveTable(inStream, obj as CanLeaveTable);
                result = true;
                break;
            case 45821:
                this.readCanLeaveTableResponse(inStream, obj as CanLeaveTableResponse);
                result = true;
                break;
            case 45822:
                this.readCanSitOut(inStream, obj as CanSitOut);
                result = true;
                break;
            case 45823:
                this.readCanSitOutResponse(inStream, obj as CanSitOutResponse);
                result = true;
                break;
            case 45824:
                this.readATHAutoSitOutMsg(inStream, obj as ATHAutoSitOutMsg);
                result = true;
                break;
            case 45825:
                this.readTourneyCancelled(inStream, obj as TourneyCancelled);
                result = true;
                break;
            case 45827:
                this.readTournamentCurrentStatus(inStream, obj as TournamentCurrentStatus);
                result = true;
                break;
            case 45842:
                this.readPlayerAccountType(inStream, obj as PlayerAccountType);
                result = true;
                break;
            case 45843:
                this.readPlayerGameSessionLimitsStatusResponse(inStream, obj as PlayerGameSessionLimitsStatusResponse);
                result = true;
                break;
            case 45844:
                this.readPlayerGameSessionRemainingLimitsStatusResponse(inStream, obj as PlayerGameSessionRemainingLimitsStatusResponse);
                result = true;
                break;
            case 45845:
                this.readPlayerGameSessionRemainingLimitsRequest(inStream, obj as PlayerGameSessionRemainingLimitsRequest);
                result = true;
                break;
            case 45846:
                this.readSetPlayerGameSessionLimitsRequest(inStream, obj as SetPlayerGameSessionLimitsRequest);
                result = true;
                break;
            case 45847:
                this.readPlayerGameSessionReminder(inStream, obj as PlayerGameSessionReminder);
                result = true;
                break;
            case 45848:
                this.readPlayerGameSessionLimitsStatusRequest(inStream, obj as PlayerGameSessionLimitsStatusRequest);
                result = true;
                break;
            case 45850:
                this.readServerParamUpdateToClient(inStream, obj as ServerParamUpdateToClient);
                result = true;
                break;
            case 45865:
                this.readUserLocationStatus(inStream, obj as UserLocationStatus);
                result = true;
                break;
            case 45866:
                this.readShowPopup(inStream, obj as ShowPopup);
                result = true;
                break;
            case 45867:
                this.readResponseGeolocationStatus(inStream, obj as ResponseGeolocationStatus);
                result = true;
                break;
            case 45868:
                this.readResponseBuyMoreChipsGeoLocationBlocked(inStream, obj as ResponseBuyMoreChipsGeoLocationBlocked);
                result = true;
                break;
            case 45869:
                this.readSessionLimitNotification(inStream, obj as SessionLimitNotification);
                result = true;
                break;
            case 45870:
                this.readSessionReset(inStream, obj as SessionReset);
                result = true;
                break;
            case 45871:
                this.readSessionTimeRemainingNotification(inStream, obj as SessionTimeRemainingNotification);
                result = true;
                break;
            case 45872:
                this.readResponseBuyMoreChipsSessiontimeExpired(inStream, obj as ResponseBuyMoreChipsSessiontimeExpired);
                result = true;
                break;
            case 45873:
                this.readResponseAddonRebuyFailure(inStream, obj as ResponseAddonRebuyFailure);
                result = true;
                break;
            case 45874:
                this.readResponseBuyMoreChipsLossLimitExceeded(inStream, obj as ResponseBuyMoreChipsLossLimitExceeded);
                result = true;
                break;
            case 45875:
                this.readBlockPlayerOnInvalidLocation(inStream, obj as BlockPlayerOnInvalidLocation);
                result = true;
                break;
            case 45876:
                this.readRequestSessionLimitsData(inStream, obj as RequestSessionLimitsData);
                result = true;
                break;
            case 45877:
                this.readResponseSessionLimitsData(inStream, obj as ResponseSessionLimitsData);
                result = true;
                break;
            case 45878:
                this.readNetPositionDetails(inStream, obj as NetPositionDetails);
                result = true;
                break;
            case 45879:
                this.readForceLogOutRequest(inStream, obj as ForceLogOutRequest);
                result = true;
                break;
            case 45880:
                this.readMultiConnectionPostLoginSyncupMessage(inStream, obj as MultiConnectionPostLoginSyncupMessage);
                result = true;
                break;
            case 45882:
                this.readResponseElapsedTime(inStream, obj as ResponseElapsedTime);
                result = true;
                break;
            case 45888:
                this.readBigWinNotification(inStream, obj as BigWinNotification);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    write(obj: Message, outStream: TSDataOutputStream) {
        const classId = this.getClassIdByName(obj);
        let result = false;
        switch (classId) {
            case 45057:
                this.writeAnte(outStream, obj as Ante);
                result = true;
                break;
            case 45058:
                this.writeBlind(outStream, obj as Blind);
                result = true;
                break;
            case 45059:
                this.writeBuyMoreChips(outStream, obj as BuyMoreChips);
                result = true;
                break;
            case 45060:
                this.writePvtTableType(outStream, obj as PvtTableType);
                result = true;
                break;
            case 45061:
                this.writeHandHistory(outStream, obj as HandHistory);
                result = true;
                break;
            case 45062:
                this.writeInvitation(outStream, obj as Invitation);
                result = true;
                break;
            case 45063:
                this.writeJoinTable(outStream, obj as JoinTable);
                result = true;
                break;
            case 45065:
                this.writeSeatAvailableResponse(outStream, obj as SeatAvailableResponse);
                result = true;
                break;
            case 45066:
                this.writeSelectedOption(outStream, obj as SelectedOption);
                result = true;
                break;
            case 45067:
                this.writeSit(outStream, obj as Sit);
                result = true;
                break;
            case 45069:
                this.writeTabConfigText(outStream, obj as TabConfigText);
                result = true;
                break;
            case 45070:
                this.writeAddCommunityCards(outStream, obj as AddCommunityCards);
                result = true;
                break;
            case 45071:
                this.writeAddPlayer(outStream, obj as AddPlayer);
                result = true;
                break;
            case 45072:
                this.writeAllInPot(outStream, obj as AllInPot);
                result = true;
                break;
            case 45073:
                this.writeAllInsRemaining(outStream, obj as AllInsRemaining);
                result = true;
                break;
            case 45074:
                this.writeAutoOptionSet(outStream, obj as AutoOptionSet);
                result = true;
                break;
            case 45075:
                this.writeChangePlayerState(outStream, obj as ChangePlayerState);
                result = true;
                break;
            case 45076:
                this.writeCurrentPlayer(outStream, obj as CurrentPlayer);
                result = true;
                break;
            case 45077:
                this.writeDealCards(outStream, obj as DealCards);
                result = true;
                break;
            case 45078:
                this.writeDealer(outStream, obj as Dealer);
                result = true;
                break;
            case 45079:
                this.writeEndTheRound(outStream, obj as EndTheRound);
                result = true;
                break;
            case 45081:
                this.writeMuckCards(outStream, obj as MuckCards);
                result = true;
                break;
            case 45082:
                this.writeNewGame(outStream, obj as NewGame);
                result = true;
                break;
            case 45083:
                this.writeOptionSet(outStream, obj as OptionSet);
                result = true;
                break;
            case 45085:
                this.writePostAnte(outStream, obj as PostAnte);
                result = true;
                break;
            case 45086:
                this.writePostBlind(outStream, obj as PostBlind);
                result = true;
                break;
            case 45087:
                this.writePotWinners(outStream, obj as PotWinners);
                result = true;
                break;
            case 45088:
                this.writePvtTableTypes(outStream, obj as PvtTableTypes);
                result = true;
                break;
            case 45089:
                this.writeGameTableStatus(outStream, obj as GameTableStatus);
                result = true;
                break;
            case 45090:
                this.writeRemoveLobbyTable(outStream, obj as RemoveLobbyTable);
                result = true;
                break;
            case 45091:
                this.writeRemovePlayer(outStream, obj as RemovePlayer);
                result = true;
                break;
            case 45092:
                this.writeReserveSeat(outStream, obj as ReserveSeat);
                result = true;
                break;
            case 45093:
                this.writeShowCardsOptions(outStream, obj as ShowCardsOptions);
                result = true;
                break;
            case 45095:
                this.writeTimeOut(outStream, obj as TimeOut);
                result = true;
                break;
            case 45096:
                this.writeWaitStatus(outStream, obj as WaitStatus);
                result = true;
                break;
            case 45097:
                this.writePlayerDealCards(outStream, obj as PlayerDealCards);
                result = true;
                break;
            case 45098:
                this.writePlayerStatus(outStream, obj as PlayerStatus);
                result = true;
                break;
            case 45099:
                this.writeCreatePvtTable(outStream, obj as CreatePvtTable);
                result = true;
                break;
            case 45100:
                this.writeSidePot(outStream, obj as SidePot);
                result = true;
                break;
            case 45101:
                this.writeWinner(outStream, obj as Winner);
                result = true;
                break;
            case 45102:
                this.writeUnReserveSeat(outStream, obj as UnReserveSeat);
                result = true;
                break;
            case 45103:
                this.writeMorePlayMoneyResponse(outStream, obj as MorePlayMoneyResponse);
                result = true;
                break;
            case 45104:
                this.writeGiveTourneyChips(outStream, obj as GiveTourneyChips);
                result = true;
                break;
            case 45105:
                this.writeTourneyResult(outStream, obj as TourneyResult);
                result = true;
                break;
            case 45106:
                this.writePrevPlayingGTs(outStream, obj as PrevPlayingGTs);
                result = true;
                break;
            case 45107:
                this.writeChangeInLevel(outStream, obj as ChangeInLevel);
                result = true;
                break;
            case 45109:
                this.writeTableTransfer(outStream, obj as TableTransfer);
                result = true;
                break;
            case 45110:
                this.writeAutoOptionToOptionsMapping(outStream, obj as AutoOptionToOptionsMapping);
                result = true;
                break;
            case 45111:
                this.writeAutoOptionToOptionsMappings(outStream, obj as AutoOptionToOptionsMappings);
                result = true;
                break;
            case 45112:
                this.writeWinnerCards(outStream, obj as WinnerCards);
                result = true;
                break;
            case 45113:
                this.writeHighlightWinCards(outStream, obj as HighlightWinCards);
                result = true;
                break;
            case 45114:
                this.writeValidFreeRolls(outStream, obj as ValidFreeRolls);
                result = true;
                break;
            case 45115:
                this.writeBuddy(outStream, obj as Buddy);
                result = true;
                break;
            case 45116:
                this.writeBlockBuddy(outStream, obj as BlockBuddy);
                result = true;
                break;
            case 45117:
                this.writeInviteBuddy(outStream, obj as InviteBuddy);
                result = true;
                break;
            case 45118:
                this.writeRemoveBuddy(outStream, obj as RemoveBuddy);
                result = true;
                break;
            case 45119:
                this.writeAddBuddy(outStream, obj as AddBuddy);
                result = true;
                break;
            case 45120:
                this.writeBuddyList(outStream, obj as BuddyList);
                result = true;
                break;
            case 45121:
                this.writeBuddyResponse(outStream, obj as BuddyResponse);
                result = true;
                break;
            case 45122:
                this.writePlayerBalance(outStream, obj as PlayerBalance);
                result = true;
                break;
            case 45123:
                this.writeUserBalance(outStream, obj as UserBalance);
                result = true;
                break;
            case 45125:
                this.writeMTCTLobbyToOpen(outStream, obj as MTCTLobbyToOpen);
                result = true;
                break;
            case 45127:
                this.writeMTCTTableStatus(outStream, obj as MTCTTableStatus);
                result = true;
                break;
            case 45128:
                this.writeCurrentPlayerMTCTRegistrations(outStream, obj as CurrentPlayerMTCTRegistrations);
                result = true;
                break;
            case 45129:
                this.writeMTCTRegister(outStream, obj as MTCTRegister);
                result = true;
                break;
            case 45130:
                this.writeMTCTUnRegister(outStream, obj as MTCTUnRegister);
                result = true;
                break;
            case 45133:
                this.writeSemiFinalDay(outStream, obj as SemiFinalDay);
                result = true;
                break;
            case 45134:
                this.writeSemiFinalGroup(outStream, obj as SemiFinalGroup);
                result = true;
                break;
            case 45135:
                this.writeSemiFinalCalendar(outStream, obj as SemiFinalCalendar);
                result = true;
                break;
            case 45137:
                this.writeCanPlayerRegister(outStream, obj as CanPlayerRegister);
                result = true;
                break;
            case 45139:
                this.writeTableOccupancy(outStream, obj as TableOccupancy);
                result = true;
                break;
            case 45141:
                this.writeTableOccupancyList(outStream, obj as TableOccupancyList);
                result = true;
                break;
            case 45142:
                this.writeSendMTCTParticipantNames(outStream, obj as SendMTCTParticipantNames);
                result = true;
                break;
            case 45143:
                this.writeMTCTParticipantNames(outStream, obj as MTCTParticipantNames);
                result = true;
                break;
            case 45148:
                this.writeWaitingPlayersForTable(outStream, obj as WaitingPlayersForTable);
                result = true;
                break;
            case 45149:
                this.writePlayerSearchResult(outStream, obj as PlayerSearchResult);
                result = true;
                break;
            case 45150:
                this.writePlayerSearchResults(outStream, obj as PlayerSearchResults);
                result = true;
                break;
            case 45152:
                this.writeShowESC(outStream, obj as ShowESC);
                result = true;
                break;
            case 45153:
                this.writePPFont(outStream, obj as PPFont);
                result = true;
                break;
            case 45154:
                this.writeJackpotAmount(outStream, obj as JackpotAmount);
                result = true;
                break;
            case 45155:
                this.writeJackpotAmounts(outStream, obj as JackpotAmounts);
                result = true;
                break;
            case 45157:
                this.writeJackpotSnapshots(outStream, obj as JackpotSnapshots);
                result = true;
                break;
            case 45158:
                this.writeOptionSetAck(outStream, obj as OptionSetAck);
                result = true;
                break;
            case 45159:
                this.writeSelectOptionAck(outStream, obj as SelectOptionAck);
                result = true;
                break;
            case 45160:
                this.writeEnableTimeBank(outStream, obj as EnableTimeBank);
                result = true;
                break;
            case 45161:
                this.writeBlockSeat(outStream, obj as BlockSeat);
                result = true;
                break;
            case 45162:
                this.writeMTCTRebuyAddonInfo(outStream, obj as MTCTRebuyAddonInfo);
                result = true;
                break;
            case 45163:
                this.writeMTCTRebuyAddonStatus(outStream, obj as MTCTRebuyAddonStatus);
                result = true;
                break;
            case 45164:
                this.writeMTCTRequestRebuyAddonDialogInfo(outStream, obj as MTCTRequestRebuyAddonDialogInfo);
                result = true;
                break;
            case 45165:
                this.writeMTCTDoRebuyAddon(outStream, obj as MTCTDoRebuyAddon);
                result = true;
                break;
            case 45166:
                this.writeShootoutInfo(outStream, obj as ShootoutInfo);
                result = true;
                break;
            case 45167:
                this.writeJackpotHitTime(outStream, obj as JackpotHitTime);
                result = true;
                break;
            case 45168:
                this.writeDealMakingChatRequest(outStream, obj as DealMakingChatRequest);
                result = true;
                break;
            case 45169:
                this.writeDealMakingSelectedPayout(outStream, obj as DealMakingSelectedPayout);
                result = true;
                break;
            case 45170:
                this.writeDealMakingAcceptPlayerCount(outStream, obj as DealMakingAcceptPlayerCount);
                result = true;
                break;
            case 45171:
                this.writeDealMakingDetails(outStream, obj as DealMakingDetails);
                result = true;
                break;
            case 45172:
                this.writePayout(outStream, obj as Payout);
                result = true;
                break;
            case 45173:
                this.writePlayersChipCount(outStream, obj as PlayersChipCount);
                result = true;
                break;
            case 45174:
                this.writeDealMakingConfirmData(outStream, obj as DealMakingConfirmData);
                result = true;
                break;
            case 45175:
                this.writeDisconnectedPlayersList(outStream, obj as DisconnectedPlayersList);
                result = true;
                break;
            case 45176:
                this.writeDealMakingSubmitDeal(outStream, obj as DealMakingSubmitDeal);
                result = true;
                break;
            case 45177:
                this.writeDealMakingTrnyRank(outStream, obj as DealMakingTrnyRank);
                result = true;
                break;
            case 45182:
                this.writeLobbyFont(outStream, obj as LobbyFont);
                result = true;
                break;
            case 45183:
                this.writeTableColorDetailsList(outStream, obj as TableColorDetailsList);
                result = true;
                break;
            case 45184:
                this.writeDealPlayerData(outStream, obj as DealPlayerData);
                result = true;
                break;
            case 45185:
                this.writeDealMakingPostDealPayout(outStream, obj as DealMakingPostDealPayout);
                result = true;
                break;
            case 45186:
                this.writeDealConfirmedPlayerCount(outStream, obj as DealConfirmedPlayerCount);
                result = true;
                break;
            case 45187:
                this.writeLobbyTableDataChange(outStream, obj as LobbyTableDataChange);
                result = true;
                break;
            case 45188:
                this.writeLobbyTableOccupancyChange(outStream, obj as LobbyTableOccupancyChange);
                result = true;
                break;
            case 45192:
                this.writeMTCTTourneyStatus(outStream, obj as MTCTTourneyStatus);
                result = true;
                break;
            case 45193:
                this.writeTourneyPrizeInfo(outStream, obj as TourneyPrizeInfo);
                result = true;
                break;
            case 45194:
                this.writeDealMakingPayoutData(outStream, obj as DealMakingPayoutData);
                result = true;
                break;
            case 45195:
                this.writeMTCTPrizeInfo(outStream, obj as MTCTPrizeInfo);
                result = true;
                break;
            case 45196:
                this.writeMTCTTourneyInfo(outStream, obj as MTCTTourneyInfo);
                result = true;
                break;
            case 45197:
                this.writeMTCTDetails(outStream, obj as MTCTDetails);
                result = true;
                break;
            case 45200:
                this.writeBlockSeatResponse(outStream, obj as BlockSeatResponse);
                result = true;
                break;
            case 45201:
                this.writeBuyinRefundStatus(outStream, obj as BuyinRefundStatus);
                result = true;
                break;
            case 45202:
                this.writeCanPlayerRegisterResponse(outStream, obj as CanPlayerRegisterResponse);
                result = true;
                break;
            case 45203:
                this.writeCloseTable(outStream, obj as CloseTable);
                result = true;
                break;
            case 45204:
                this.writeCreatePvtTableResponse(outStream, obj as CreatePvtTableResponse);
                result = true;
                break;
            case 45205:
                this.writeChat(outStream, obj as Chat);
                result = true;
                break;
            case 45206:
                this.writeHandStrength(outStream, obj as HandStrength);
                result = true;
                break;
            case 45207:
                this.writeJoinTableResponse(outStream, obj as JoinTableResponse);
                result = true;
                break;
            case 45208:
                this.writeMessageOnTable(outStream, obj as MessageOnTable);
                result = true;
                break;
            case 45209:
                this.writeMoveToTable(outStream, obj as MoveToTable);
                result = true;
                break;
            case 45210:
                this.writeMTCTRebuyAddonDialogInfo(outStream, obj as MTCTRebuyAddonDialogInfo);
                result = true;
                break;
            case 45211:
                this.writeShowCards(outStream, obj as ShowCards);
                result = true;
                break;
            case 45212:
                this.writeShowCongrats(outStream, obj as ShowCongrats);
                result = true;
                break;
            case 45213:
                this.writeShowInfoOnTable(outStream, obj as ShowInfoOnTable);
                result = true;
                break;
            case 45216:
                this.writeTourneyLevelInfo(outStream, obj as TourneyLevelInfo);
                result = true;
                break;
            case 45217:
                this.writeRefreshTablesList(outStream, obj as RefreshTablesList);
                result = true;
                break;
            case 45218:
                this.writeMTCTResponse(outStream, obj as MTCTResponse);
                result = true;
                break;
            case 45219:
                this.writeMsgTrafficStats(outStream, obj as MsgTrafficStats);
                result = true;
                break;
            case 45220:
                this.writeOption(outStream, obj as Option);
                result = true;
                break;
            case 45221:
                this.writeLimitGameOption(outStream, obj as LimitGameOption);
                result = true;
                break;
            case 45222:
                this.writeAutoOption(outStream, obj as AutoOption);
                result = true;
                break;
            case 45223:
                this.writeSelectOption(outStream, obj as SelectOption);
                result = true;
                break;
            case 45224:
                this.writeSelectedAutoOption(outStream, obj as SelectedAutoOption);
                result = true;
                break;
            case 45225:
                this.writeInviteBuddyRequest(outStream, obj as InviteBuddyRequest);
                result = true;
                break;
            case 45226:
                this.writeSeatAvailable(outStream, obj as SeatAvailable);
                result = true;
                break;
            case 45227:
                this.writeDealMakingChat(outStream, obj as DealMakingChat);
                result = true;
                break;
            case 45228:
                this.writeGameStatus(outStream, obj as GameStatus);
                result = true;
                break;
            case 45229:
                this.writeSTTReplayRequest(outStream, obj as STTReplayRequest);
                result = true;
                break;
            case 45230:
                this.writeSTTReplayResponse(outStream, obj as STTReplayResponse);
                result = true;
                break;
            case 45231:
                this.writeRequestTournamentHH(outStream, obj as RequestTournamentHH);
                result = true;
                break;
            case 45232:
                this.writeTimeDelayInfo(outStream, obj as TimeDelayInfo);
                result = true;
                break;
            case 45233:
                this.writeCasinoTrnyLevelInfo(outStream, obj as CasinoTrnyLevelInfo);
                result = true;
                break;
            case 45234:
                this.writeTBTTourneyResult(outStream, obj as TBTTourneyResult);
                result = true;
                break;
            case 45235:
                this.writeTBTMinEndTime(outStream, obj as TBTMinEndTime);
                result = true;
                break;
            case 45236:
                this.writePlayerTourneyRank(outStream, obj as PlayerTourneyRank);
                result = true;
                break;
            case 45237:
                this.writeStayIn(outStream, obj as StayIn);
                result = true;
                break;
            case 45238:
                this.writePostStayIn(outStream, obj as PostStayIn);
                result = true;
                break;
            case 45239:
                this.writeTableMessage(outStream, obj as TableMessage);
                result = true;
                break;
            case 45242:
                this.writeWaitlistCriteriaType(outStream, obj as WaitlistCriteriaType);
                result = true;
                break;
            case 45243:
                this.writeJoinWaitlist(outStream, obj as JoinWaitlist);
                result = true;
                break;
            case 45244:
                this.writeJoinWaitlistResponse(outStream, obj as JoinWaitlistResponse);
                result = true;
                break;
            case 45245:
                this.writeUnjoinWaitlist(outStream, obj as UnjoinWaitlist);
                result = true;
                break;
            case 45246:
                this.writeUnjoinAllWaitlist(outStream, obj as UnjoinAllWaitlist);
                result = true;
                break;
            case 45247:
                this.writeJoinWaitlistRefresh(outStream, obj as JoinWaitlistRefresh);
                result = true;
                break;
            case 45248:
                this.writeChatOffMessage(outStream, obj as ChatOffMessage);
                result = true;
                break;
            case 45249:
                this.writeMTCTDynaLiteralUpdate(outStream, obj as MTCTDynaLiteralUpdate);
                result = true;
                break;
            case 45259:
                this.writeQSParam(outStream, obj as QSParam);
                result = true;
                break;
            case 45265:
                this.writeQSRequest(outStream, obj as QSRequest);
                result = true;
                break;
            case 45266:
                this.writeQSError(outStream, obj as QSError);
                result = true;
                break;
            case 45267:
                this.writeQSResponse(outStream, obj as QSResponse);
                result = true;
                break;
            case 45268:
                this.writeQSResult(outStream, obj as QSResult);
                result = true;
                break;
            case 45269:
                this.writeQSBlockSeatCancel(outStream, obj as QSBlockSeatCancel);
                result = true;
                break;
            case 45270:
                this.writePeerTables(outStream, obj as PeerTables);
                result = true;
                break;
            case 45271:
                this.writeDealHighCards(outStream, obj as DealHighCards);
                result = true;
                break;
            case 45272:
                this.writeMegaPotPrize(outStream, obj as MegaPotPrize);
                result = true;
                break;
            case 45277:
                this.writeCalendarAlert(outStream, obj as CalendarAlert);
                result = true;
                break;
            case 45278:
                this.writeSetCalendarAlert(outStream, obj as SetCalendarAlert);
                result = true;
                break;
            case 45283:
                this.writeMTCTLevel(outStream, obj as MTCTLevel);
                result = true;
                break;
            case 45285:
                this.writePrePromoTicker(outStream, obj as PrePromoTicker);
                result = true;
                break;
            case 45286:
                this.writePromoUpdateTicker(outStream, obj as PromoUpdateTicker);
                result = true;
                break;
            case 45287:
                this.writeRatHoleResponse(outStream, obj as RatHoleResponse);
                result = true;
                break;
            case 45288:
                this.writeFXConversionData(outStream, obj as FXConversionData);
                result = true;
                break;
            case 45289:
                this.writeMTCTLobbyData(outStream, obj as MTCTLobbyData);
                result = true;
                break;
            case 45291:
                this.writeMTCTRegistrationChangeEvent(outStream, obj as MTCTRegistrationChangeEvent);
                result = true;
                break;
            case 45293:
                this.writeMTCTStatusChangeEvent(outStream, obj as MTCTStatusChangeEvent);
                result = true;
                break;
            case 45294:
                this.writeMTCTDeleteEvent(outStream, obj as MTCTDeleteEvent);
                result = true;
                break;
            case 45295:
                this.writeMTCTLevelChangeEvent(outStream, obj as MTCTLevelChangeEvent);
                result = true;
                break;
            case 45296:
                this.writeMTCTPlayerCountChangeEvent(outStream, obj as MTCTPlayerCountChangeEvent);
                result = true;
                break;
            case 45297:
                this.writeMTCTPrizePoolChangeEvent(outStream, obj as MTCTPrizePoolChangeEvent);
                result = true;
                break;
            case 45298:
                this.writeMTCTLobbySnapshotChange(outStream, obj as MTCTLobbySnapshotChange);
                result = true;
                break;
            case 45299:
                this.writeScratchCardTicker(outStream, obj as ScratchCardTicker);
                result = true;
                break;
            case 45300:
                this.writeRelocateTable(outStream, obj as RelocateTable);
                result = true;
                break;
            case 45301:
                this.writeMTCTCustomPropertyChangeEvent(outStream, obj as MTCTCustomPropertyChangeEvent);
                result = true;
                break;
            case 45310:
                this.writeMTCTPlayerKickedOut(outStream, obj as MTCTPlayerKickedOut);
                result = true;
                break;
            case 45312:
                this.writeMTCTParticipantNamesOnRequest(outStream, obj as MTCTParticipantNamesOnRequest);
                result = true;
                break;
            case 45313:
                this.writeRequestMTCTParticipantNamesOnSearchString(outStream, obj as RequestMTCTParticipantNamesOnSearchString);
                result = true;
                break;
            case 45315:
                this.writeJoinTableQS(outStream, obj as JoinTableQS);
                result = true;
                break;
            case 45316:
                this.writeJoinTableAutoBuyIn(outStream, obj as JoinTableAutoBuyIn);
                result = true;
                break;
            case 45317:
                this.writeJoinTableSTTReplay(outStream, obj as JoinTableSTTReplay);
                result = true;
                break;
            case 45318:
                this.writeSitResponse(outStream, obj as SitResponse);
                result = true;
                break;
            case 45319:
                this.writeAutoBuyInError(outStream, obj as AutoBuyInError);
                result = true;
                break;
            case 45323:
                this.writeBlockSeatCancel(outStream, obj as BlockSeatCancel);
                result = true;
                break;
            case 45324:
                this.writeItaliaAuthorizationCodes(outStream, obj as ItaliaAuthorizationCodes);
                result = true;
                break;
            case 45325:
                this.writeRequestAutoCheckOff(outStream, obj as RequestAutoCheckOff);
                result = true;
                break;
            case 45326:
                this.writeRequestAutoCheckOn(outStream, obj as RequestAutoCheckOn);
                result = true;
                break;
            case 45327:
                this.writeRequestAutoOptionsOptionsMappings(outStream, obj as RequestAutoOptionsOptionsMappings);
                result = true;
                break;
            case 45328:
                this.writeRequestBuddiesList(outStream, obj as RequestBuddiesList);
                result = true;
                break;
            case 45329:
                this.writeRequestChatOffDisabled(outStream, obj as RequestChatOffDisabled);
                result = true;
                break;
            case 45330:
                this.writeRequestChatOffEnabled(outStream, obj as RequestChatOffEnabled);
                result = true;
                break;
            case 45331:
                this.writeRequestCurrentPlayerRegistration(outStream, obj as RequestCurrentPlayerRegistration);
                result = true;
                break;
            case 45332:
                this.writeRequestDealMakingExit(outStream, obj as RequestDealMakingExit);
                result = true;
                break;
            case 45333:
                this.writeRequestDealMakingReject(outStream, obj as RequestDealMakingReject);
                result = true;
                break;
            case 45334:
                this.writeRequestDealMakingView(outStream, obj as RequestDealMakingView);
                result = true;
                break;
            case 45336:
                this.writeRequestFoldToAnyBetOn(outStream, obj as RequestFoldToAnyBetOn);
                result = true;
                break;
            case 45340:
                this.writeRequestListOfPVTTableTypes(outStream, obj as RequestListOfPVTTableTypes);
                result = true;
                break;
            case 45341:
                this.writeRequestMtctRebuyDialogCancel(outStream, obj as RequestMtctRebuyDialogCancel);
                result = true;
                break;
            case 45343:
                this.writeRequestNoBuddiesList(outStream, obj as RequestNoBuddiesList);
                result = true;
                break;
            case 45345:
                this.writeRequestRegainPlayer(outStream, obj as RequestRegainPlayer);
                result = true;
                break;
            case 45346:
                this.writeRequestFoldToAnyBetOff(outStream, obj as RequestFoldToAnyBetOff);
                result = true;
                break;
            case 45347:
                this.writeRequestSendJackpotSnapshots(outStream, obj as RequestSendJackpotSnapshots);
                result = true;
                break;
            case 45356:
                this.writeRequestTourneyPrizeInfo(outStream, obj as RequestTourneyPrizeInfo);
                result = true;
                break;
            case 45357:
                this.writeRequestWaitingPlayers(outStream, obj as RequestWaitingPlayers);
                result = true;
                break;
            case 45360:
                this.writeRequestAutopostBlindAndFoldOff(outStream, obj as RequestAutopostBlindAndFoldOff);
                result = true;
                break;
            case 45361:
                this.writeRequestAutoPostBlindAndFoldOn(outStream, obj as RequestAutoPostBlindAndFoldOn);
                result = true;
                break;
            case 45362:
                this.writeRequestAutoPostBlindOff(outStream, obj as RequestAutoPostBlindOff);
                result = true;
                break;
            case 45363:
                this.writeRequestAutoPostBlindOn(outStream, obj as RequestAutoPostBlindOn);
                result = true;
                break;
            case 45364:
                this.writeRequestAutorebuyOff(outStream, obj as RequestAutorebuyOff);
                result = true;
                break;
            case 45365:
                this.writeRequestAutoRebuyOn(outStream, obj as RequestAutoRebuyOn);
                result = true;
                break;
            case 45366:
                this.writeRequestDealMakingAllConfirmed(outStream, obj as RequestDealMakingAllConfirmed);
                result = true;
                break;
            case 45367:
                this.writeRequestDealMakingConfirm(outStream, obj as RequestDealMakingConfirm);
                result = true;
                break;
            case 45368:
                this.writeRequestDealMakingNo(outStream, obj as RequestDealMakingNo);
                result = true;
                break;
            case 45369:
                this.writeRequestDealMakingYes(outStream, obj as RequestDealMakingYes);
                result = true;
                break;
            case 45370:
                this.writeRequestGameStatus(outStream, obj as RequestGameStatus);
                result = true;
                break;
            case 45372:
                this.writeRequestLeaveTable(outStream, obj as RequestLeaveTable);
                result = true;
                break;
            case 45373:
                this.writeRequestMuckLosingHandOff(outStream, obj as RequestMuckLosingHandOff);
                result = true;
                break;
            case 45374:
                this.writeRequestMuckLosingHandOn(outStream, obj as RequestMuckLosingHandOn);
                result = true;
                break;
            case 45375:
                this.writeRequestSitoutAtNextBBOff(outStream, obj as RequestSitoutAtNextBBOff);
                result = true;
                break;
            case 45376:
                this.writeRequestSitoutAtNextBBOn(outStream, obj as RequestSitoutAtNextBBOn);
                result = true;
                break;
            case 45377:
                this.writeRequestSitoutNextHandOff(outStream, obj as RequestSitoutNextHandOff);
                result = true;
                break;
            case 45378:
                this.writeRequestSitoutNextHandOn(outStream, obj as RequestSitoutNextHandOn);
                result = true;
                break;
            case 45379:
                this.writeRequestWaitforBigblindOff(outStream, obj as RequestWaitforBigblindOff);
                result = true;
                break;
            case 45380:
                this.writeRequestWaitForBigBlindOn(outStream, obj as RequestWaitForBigBlindOn);
                result = true;
                break;
            case 45381:
                this.writeRequestAdviseToBuyMoreChips(outStream, obj as RequestAdviseToBuyMoreChips);
                result = true;
                break;
            case 45383:
                this.writeRequestCloseGameTable(outStream, obj as RequestCloseGameTable);
                result = true;
                break;
            case 45384:
                this.writeRequestDealMakingEnded(outStream, obj as RequestDealMakingEnded);
                result = true;
                break;
            case 45385:
                this.writeRequestDealMakingGameClosed(outStream, obj as RequestDealMakingGameClosed);
                result = true;
                break;
            case 45386:
                this.writeRequestDealMakingGameToContinue(outStream, obj as RequestDealMakingGameToContinue);
                result = true;
                break;
            case 45387:
                this.writeRequestDealMakingHide(outStream, obj as RequestDealMakingHide);
                result = true;
                break;
            case 45388:
                this.writeRequestDealMakingPayoutVetoed(outStream, obj as RequestDealMakingPayoutVetoed);
                result = true;
                break;
            case 45389:
                this.writeRequestDealMakingStarted(outStream, obj as RequestDealMakingStarted);
                result = true;
                break;
            case 45390:
                this.writeRequestDealMakingTimeout(outStream, obj as RequestDealMakingTimeout);
                result = true;
                break;
            case 45391:
                this.writeRequestDealMakingVetoed(outStream, obj as RequestDealMakingVetoed);
                result = true;
                break;
            case 45392:
                this.writeRequestEndOfTbt(outStream, obj as RequestEndOfTbt);
                result = true;
                break;
            case 45393:
                this.writeRequestEndOfTheGame(outStream, obj as RequestEndOfTheGame);
                result = true;
                break;
            case 45398:
                this.writeRequestKillGametableSilently(outStream, obj as RequestKillGametableSilently);
                result = true;
                break;
            case 45403:
                this.writeRequestServerClosckReSync(outStream, obj as RequestServerClosckReSync);
                result = true;
                break;
            case 45404:
                this.writeRequestShowSitButtons(outStream, obj as RequestShowSitButtons);
                result = true;
                break;
            case 45411:
                this.writeResponseAddonFailureAddonExceeded(outStream, obj as ResponseAddonFailureAddonExceeded);
                result = true;
                break;
            case 45412:
                this.writeResponseAddonFailureDBError(outStream, obj as ResponseAddonFailureDBError);
                result = true;
                break;
            case 45413:
                this.writeResponseAddonFailureInsufficientBalance(outStream, obj as ResponseAddonFailureInsufficientBalance);
                result = true;
                break;
            case 45414:
                this.writeResponseAddonSuccess(outStream, obj as ResponseAddonSuccess);
                result = true;
                break;
            case 45415:
                this.writeResponseAllinResetFailed(outStream, obj as ResponseAllinResetFailed);
                result = true;
                break;
            case 45416:
                this.writeResponseAllinResetNotRequired(outStream, obj as ResponseAllinResetNotRequired);
                result = true;
                break;
            case 45417:
                this.writeResponseAllinResetPending(outStream, obj as ResponseAllinResetPending);
                result = true;
                break;
            case 45418:
                this.writeResponseAllinResetSuccess(outStream, obj as ResponseAllinResetSuccess);
                result = true;
                break;
            case 45421:
                this.writeResponseBuyMoreChipsDBError(outStream, obj as ResponseBuyMoreChipsDBError);
                result = true;
                break;
            case 45422:
                this.writeResponseBuyMoreChipsNotAPlayer(outStream, obj as ResponseBuyMoreChipsNotAPlayer);
                result = true;
                break;
            case 45423:
                this.writeResponseBuyMoreChipsNotAvailable(outStream, obj as ResponseBuyMoreChipsNotAvailable);
                result = true;
                break;
            case 45424:
                this.writeResponseBuyMoreChipsPlaying(outStream, obj as ResponseBuyMoreChipsPlaying);
                result = true;
                break;
            case 45425:
                this.writeResponseBuyMoreChipsSuccess(outStream, obj as ResponseBuyMoreChipsSuccess);
                result = true;
                break;
            case 45429:
                this.writeResponseChatRequestPermissionDenied(outStream, obj as ResponseChatRequestPermissionDenied);
                result = true;
                break;
            case 45430:
                this.writeResponseDealMakingConfirmSuccess(outStream, obj as ResponseDealMakingConfirmSuccess);
                result = true;
                break;
            case 45431:
                this.writeResponseDealMakingRejectSuccess(outStream, obj as ResponseDealMakingRejectSuccess);
                result = true;
                break;
            case 45432:
                this.writeResponseDealMakingSubmitFailure(outStream, obj as ResponseDealMakingSubmitFailure);
                result = true;
                break;
            case 45433:
                this.writeResponseDealMakingSubmitSuccess(outStream, obj as ResponseDealMakingSubmitSuccess);
                result = true;
                break;
            case 45434:
                this.writeResponseGeneralErrorMessage(outStream, obj as ResponseGeneralErrorMessage);
                result = true;
                break;
            case 45436:
                this.writeResponseHandHistoryFail(outStream, obj as ResponseHandHistoryFail);
                result = true;
                break;
            case 45437:
                this.writeResponseHandHistorySuccess(outStream, obj as ResponseHandHistorySuccess);
                result = true;
                break;
            case 45438:
                this.writeResponseHURematchAccepted(outStream, obj as ResponseHURematchAccepted);
                result = true;
                break;
            case 45439:
                this.writeResponseHURematchRejected(outStream, obj as ResponseHURematchRejected);
                result = true;
                break;
            case 45440:
                this.writeResponseHURematchTimeout(outStream, obj as ResponseHURematchTimeout);
                result = true;
                break;
            case 45441:
                this.writeResponseJPWelcomeMsg(outStream, obj as ResponseJPWelcomeMsg);
                result = true;
                break;
            case 45442:
                this.writeResponseNOBuddiesListSuccess(outStream, obj as ResponseNOBuddiesListSuccess);
                result = true;
                break;
            case 45444:
                this.writeResponseRebuyAddonClosed(outStream, obj as ResponseRebuyAddonClosed);
                result = true;
                break;
            case 45445:
                this.writeResponseRebuyFailureDBError(outStream, obj as ResponseRebuyFailureDBError);
                result = true;
                break;
            case 45446:
                this.writeResponseRebuyFailureImproperChipBalance(outStream, obj as ResponseRebuyFailureImproperChipBalance);
                result = true;
                break;
            case 45447:
                this.writeResponseRebuyFailureInsufficientBalance(outStream, obj as ResponseRebuyFailureInsufficientBalance);
                result = true;
                break;
            case 45448:
                this.writeResponseRebuyFailureRebuysExceeded(outStream, obj as ResponseRebuyFailureRebuysExceeded);
                result = true;
                break;
            case 45449:
                this.writeResponseRebuySuccess(outStream, obj as ResponseRebuySuccess);
                result = true;
                break;
            case 45450:
                this.writeResponseRebuySuccessPending(outStream, obj as ResponseRebuySuccessPending);
                result = true;
                break;
            case 45451:
                this.writeResponseRegainPlayerFailed(outStream, obj as ResponseRegainPlayerFailed);
                result = true;
                break;
            case 45452:
                this.writeResponseRegainPlayerSuccess(outStream, obj as ResponseRegainPlayerSuccess);
                result = true;
                break;
            case 45454:
                this.writeResponseShowCardsOptionShow(outStream, obj as ResponseShowCardsOptionShow);
                result = true;
                break;
            case 45458:
                this.writeResponseReceivedExpiredOptions(outStream, obj as ResponseReceivedExpiredOptions);
                result = true;
                break;
            case 45459:
                this.writeResponseShowCardsOptionDontShow(outStream, obj as ResponseShowCardsOptionDontShow);
                result = true;
                break;
            case 45463:
                this.writeResponseShowCardsOptionMuck(outStream, obj as ResponseShowCardsOptionMuck);
                result = true;
                break;
            case 45477:
                this.writeResponseBuyMoreChipsAllowedAmount(outStream, obj as ResponseBuyMoreChipsAllowedAmount);
                result = true;
                break;
            case 45482:
                this.writeRGLimitErrorMsg(outStream, obj as RGLimitErrorMsg);
                result = true;
                break;
            case 45484:
                this.writeItaliaAuthorizationCodesForTable(outStream, obj as ItaliaAuthorizationCodesForTable);
                result = true;
                break;
            case 45485:
                this.writePokerChatRequest(outStream, obj as PokerChatRequest);
                result = true;
                break;
            case 45487:
                this.writeRequestUserBalance(outStream, obj as RequestUserBalance);
                result = true;
                break;
            case 45490:
                this.writeRequestAddMorePlayMoney(outStream, obj as RequestAddMorePlayMoney);
                result = true;
                break;
            case 45491:
                this.writeWaitingPlayersForLobby(outStream, obj as WaitingPlayersForLobby);
                result = true;
                break;
            case 45492:
                this.writeArjelEndOfTheGameEventReq(outStream, obj as ArjelEndOfTheGameEventReq);
                result = true;
                break;
            case 45493:
                this.writeArjelEndOfTheGameEventRes(outStream, obj as ArjelEndOfTheGameEventRes);
                result = true;
                break;
            case 45494:
                this.writeArjelBlindsRoundData(outStream, obj as ArjelBlindsRoundData);
                result = true;
                break;
            case 45495:
                this.writeArjelPlayerCards(outStream, obj as ArjelPlayerCards);
                result = true;
                break;
            case 45496:
                this.writeArjelGameAllPlayersAction(outStream, obj as ArjelGameAllPlayersAction);
                result = true;
                break;
            case 45497:
                this.writeArjelGameLongValue(outStream, obj as ArjelGameLongValue);
                result = true;
                break;
            case 45498:
                this.writeArjelGameCards(outStream, obj as ArjelGameCards);
                result = true;
                break;
            case 45499:
                this.writeArjelGameSummary(outStream, obj as ArjelGameSummary);
                result = true;
                break;
            case 45500:
                this.writeArjelBlindsRoundPlayerData(outStream, obj as ArjelBlindsRoundPlayerData);
                result = true;
                break;
            case 45501:
                this.writeArjelGamePlayerAction(outStream, obj as ArjelGamePlayerAction);
                result = true;
                break;
            case 45502:
                this.writeArjelGameSummaryPlayerInfo(outStream, obj as ArjelGameSummaryPlayerInfo);
                result = true;
                break;
            case 45505:
                this.writeResponseShowRabbitHunt(outStream, obj as ResponseShowRabbitHunt);
                result = true;
                break;
            case 45513:
                this.writeFoldNShowOption(outStream, obj as FoldNShowOption);
                result = true;
                break;
            case 45514:
                this.writeResponseFoldNShowFirstCard(outStream, obj as ResponseFoldNShowFirstCard);
                result = true;
                break;
            case 45516:
                this.writeResponseFoldNShowSecondCard(outStream, obj as ResponseFoldNShowSecondCard);
                result = true;
                break;
            case 45517:
                this.writeResponseFoldNShowBoth(outStream, obj as ResponseFoldNShowBoth);
                result = true;
                break;
            case 45521:
                this.writeProPlayerList(outStream, obj as ProPlayerList);
                result = true;
                break;
            case 45522:
                this.writeCompositeLobbyMessage(outStream, obj as CompositeLobbyMessage);
                result = true;
                break;
            case 45523:
                this.writeCompositeTableMessage(outStream, obj as CompositeTableMessage);
                result = true;
                break;
            case 45524:
                this.writePlayerBalances(outStream, obj as PlayerBalances);
                result = true;
                break;
            case 45525:
                this.writePlayerStates(outStream, obj as PlayerStates);
                result = true;
                break;
            case 45537:
                this.writeBeginnerStatus(outStream, obj as BeginnerStatus);
                result = true;
                break;
            case 45538:
                this.writeLobbyBigBlindChange(outStream, obj as LobbyBigBlindChange);
                result = true;
                break;
            case 45539:
                this.writeDealerSbAndBbPositions(outStream, obj as DealerSbAndBbPositions);
                result = true;
                break;
            case 45540:
                this.writeResponseBuyMoreChipsInsuffPokerFund(outStream, obj as ResponseBuyMoreChipsInsuffPokerFund);
                result = true;
                break;
            case 45541:
                this.writeResponseBuyMoreChipsInsuffPartnerFund(outStream, obj as ResponseBuyMoreChipsInsuffPartnerFund);
                result = true;
                break;
            case 45542:
                this.writeResponseBuyMoreChipsPokerTxnError(outStream, obj as ResponseBuyMoreChipsPokerTxnError);
                result = true;
                break;
            case 45543:
                this.writeResponseBuyMoreChipsWalletTxnError(outStream, obj as ResponseBuyMoreChipsWalletTxnError);
                result = true;
                break;
            case 45544:
                this.writeUserAllBalance(outStream, obj as UserAllBalance);
                result = true;
                break;
            case 45545:
                this.writeRequestUserBalancePartnerPlayer(outStream, obj as RequestUserBalancePartnerPlayer);
                result = true;
                break;
            case 45546:
                this.writeResponseBuyMoreChipsMultiple(outStream, obj as ResponseBuyMoreChipsMultiple);
                result = true;
                break;
            case 45550:
                this.writeRequestCloseClient(outStream, obj as RequestCloseClient);
                result = true;
                break;
            case 45551:
                this.writeAnonymousPlayerInfo(outStream, obj as AnonymousPlayerInfo);
                result = true;
                break;
            case 45552:
                this.writeResponseBetAndWinSummary(outStream, obj as ResponseBetAndWinSummary);
                result = true;
                break;
            case 45553:
                this.writeArjelEndOfTheGameSeatData(outStream, obj as ArjelEndOfTheGameSeatData);
                result = true;
                break;
            case 45557:
                this.writePoolAvailabilityRequest(outStream, obj as PoolAvailabilityRequest);
                result = true;
                break;
            case 45558:
                this.writeJoinPoolRequest(outStream, obj as JoinPoolRequest);
                result = true;
                break;
            case 45559:
                this.writeLeavePoolRequest(outStream, obj as LeavePoolRequest);
                result = true;
                break;
            case 45560:
                this.writePoolAvailabilityResponse(outStream, obj as PoolAvailabilityResponse);
                result = true;
                break;
            case 45561:
                this.writeJoinPoolResponse(outStream, obj as JoinPoolResponse);
                result = true;
                break;
            case 45566:
                this.writeReqQuickFoldToBetOrRaiseOn(outStream, obj as ReqQuickFoldToBetOrRaiseOn);
                result = true;
                break;
            case 45567:
                this.writeReqQuickFoldToBetOrRaiseOff(outStream, obj as ReqQuickFoldToBetOrRaiseOff);
                result = true;
                break;
            case 45568:
                this.writeReqSitoutNextBlindOn(outStream, obj as ReqSitoutNextBlindOn);
                result = true;
                break;
            case 45569:
                this.writeReqSitoutNextBlindOff(outStream, obj as ReqSitoutNextBlindOff);
                result = true;
                break;
            case 45570:
                this.writeRequestFoldAndWatchOn(outStream, obj as RequestFoldAndWatchOn);
                result = true;
                break;
            case 45571:
                this.writeRequestFoldAndWatchOff(outStream, obj as RequestFoldAndWatchOff);
                result = true;
                break;
            case 45572:
                this.writePoolRebuyRequest(outStream, obj as PoolRebuyRequest);
                result = true;
                break;
            case 45573:
                this.writePoolRebuyResponse(outStream, obj as PoolRebuyResponse);
                result = true;
                break;
            case 45574:
                this.writeAddPlayMoneyToPoolRequest(outStream, obj as AddPlayMoneyToPoolRequest);
                result = true;
                break;
            case 45575:
                this.writeAddPlayMoneyToPoolResponse(outStream, obj as AddPlayMoneyToPoolResponse);
                result = true;
                break;
            case 45576:
                this.writeBackToPoolRequest(outStream, obj as BackToPoolRequest);
                result = true;
                break;
            case 45577:
                this.writePoolStartGameNotification(outStream, obj as PoolStartGameNotification);
                result = true;
                break;
            case 45578:
                this.writePoolWaitingNotification(outStream, obj as PoolWaitingNotification);
                result = true;
                break;
            case 45579:
                this.writePoolEndGameNotification(outStream, obj as PoolEndGameNotification);
                result = true;
                break;
            case 45580:
                this.writePoolSitOutNotification(outStream, obj as PoolSitOutNotification);
                result = true;
                break;
            case 45581:
                this.writePoolKickoutNotification(outStream, obj as PoolKickoutNotification);
                result = true;
                break;
            case 45582:
                this.writeReqNextHandOffFoldAndWatch(outStream, obj as ReqNextHandOffFoldAndWatch);
                result = true;
                break;
            case 45583:
                this.writePoolRequestUserBalance(outStream, obj as PoolRequestUserBalance);
                result = true;
                break;
            case 45584:
                this.writePoolUserBalance(outStream, obj as PoolUserBalance);
                result = true;
                break;
            case 45585:
                this.writeReqNextHandOnFoldAndWatch(outStream, obj as ReqNextHandOnFoldAndWatch);
                result = true;
                break;
            case 45586:
                this.writeRequestEndOfTheGameFastPoker(outStream, obj as RequestEndOfTheGameFastPoker);
                result = true;
                break;
            case 45587:
                this.writePoolRequestUserReconnect(outStream, obj as PoolRequestUserReconnect);
                result = true;
                break;
            case 45588:
                this.writePoolUserReconnectResponse(outStream, obj as PoolUserReconnectResponse);
                result = true;
                break;
            case 45589:
                this.writePlayerEntryStatus(outStream, obj as PlayerEntryStatus);
                result = true;
                break;
            case 45590:
                this.writePoolShutdownNotification(outStream, obj as PoolShutdownNotification);
                result = true;
                break;
            case 45591:
                this.writeShowScreenOnPool(outStream, obj as ShowScreenOnPool);
                result = true;
                break;
            case 45592:
                this.writePrevPlayingFastPokerPools(outStream, obj as PrevPlayingFastPokerPools);
                result = true;
                break;
            case 45593:
                this.writePoolFraudPlayerKickoutNotification(outStream, obj as PoolFraudPlayerKickoutNotification);
                result = true;
                break;
            case 45594:
                this.writeQFToAnyBetPlayerQuickFolded(outStream, obj as QFToAnyBetPlayerQuickFolded);
                result = true;
                break;
            case 45595:
                this.writeRequestPoolSitout(outStream, obj as RequestPoolSitout);
                result = true;
                break;
            case 45596:
                this.writeHyperLinkedDealerChat(outStream, obj as HyperLinkedDealerChat);
                result = true;
                break;
            case 45597:
                this.writeResponseRebuyAddonAccClosureFailure(outStream, obj as ResponseRebuyAddonAccClosureFailure);
                result = true;
                break;
            case 45598:
                this.writeRecommendedStakeLimit(outStream, obj as RecommendedStakeLimit);
                result = true;
                break;
            case 45599:
                this.writeRecommendedStakesMessage(outStream, obj as RecommendedStakesMessage);
                result = true;
                break;
            case 45600:
                this.writePokerPlayerProtectionInfo(outStream, obj as PokerPlayerProtectionInfo);
                result = true;
                break;
            case 45601:
                this.writeRequestJoinTableWaitlist(outStream, obj as RequestJoinTableWaitlist);
                result = true;
                break;
            case 45602:
                this.writeResponseJoinTableWaitlist(outStream, obj as ResponseJoinTableWaitlist);
                result = true;
                break;
            case 45603:
                this.writeRequestUnjoinTableWaitlist(outStream, obj as RequestUnjoinTableWaitlist);
                result = true;
                break;
            case 45604:
                this.writeResponseUnjoinTableWaitlist(outStream, obj as ResponseUnjoinTableWaitlist);
                result = true;
                break;
            case 45605:
                this.writeGlobalWaitlistCriteria(outStream, obj as GlobalWaitlistCriteria);
                result = true;
                break;
            case 45606:
                this.writeRequestJoinGlobalWaitlist(outStream, obj as RequestJoinGlobalWaitlist);
                result = true;
                break;
            case 45607:
                this.writeResponseJoinGlobalWaitlist(outStream, obj as ResponseJoinGlobalWaitlist);
                result = true;
                break;
            case 45608:
                this.writeRequestUnjoinGlobalWaitlist(outStream, obj as RequestUnjoinGlobalWaitlist);
                result = true;
                break;
            case 45609:
                this.writeResponseUnjoinGlobalWaitlist(outStream, obj as ResponseUnjoinGlobalWaitlist);
                result = true;
                break;
            case 45611:
                this.writeRequestJoinOtherDomainWaitlist(outStream, obj as RequestJoinOtherDomainWaitlist);
                result = true;
                break;
            case 45612:
                this.writeResponseJoinOtherDomainWaitlist(outStream, obj as ResponseJoinOtherDomainWaitlist);
                result = true;
                break;
            case 45613:
                this.writeRequestUnjoinOtherDomainWaitlist(outStream, obj as RequestUnjoinOtherDomainWaitlist);
                result = true;
                break;
            case 45614:
                this.writeResponseUnjoinOtherDomainWaitlist(outStream, obj as ResponseUnjoinOtherDomainWaitlist);
                result = true;
                break;
            case 45616:
                this.writeArjelGTPlayerData(outStream, obj as ArjelGTPlayerData);
                result = true;
                break;
            case 45618:
                this.writeChallengeDetails(outStream, obj as ChallengeDetails);
                result = true;
                break;
            case 45619:
                this.writeAcceptChallengeRequest(outStream, obj as AcceptChallengeRequest);
                result = true;
                break;
            case 45620:
                this.writeAcceptChallengeResponse(outStream, obj as AcceptChallengeResponse);
                result = true;
                break;
            case 45621:
                this.writeCreateChallengeHUCashRequest(outStream, obj as CreateChallengeHUCashRequest);
                result = true;
                break;
            case 45622:
                this.writeCreateChallengeHUCashResponse(outStream, obj as CreateChallengeHUCashResponse);
                result = true;
                break;
            case 45623:
                this.writeCreateChallengeHUSNGRequest(outStream, obj as CreateChallengeHUSNGRequest);
                result = true;
                break;
            case 45624:
                this.writeCreateChallengeHUSNGResponse(outStream, obj as CreateChallengeHUSNGResponse);
                result = true;
                break;
            case 45625:
                this.writeRejectChallengeRequest(outStream, obj as RejectChallengeRequest);
                result = true;
                break;
            case 45626:
                this.writeRejectChallengeResponse(outStream, obj as RejectChallengeResponse);
                result = true;
                break;
            case 45627:
                this.writeChallengeInvitationRequest(outStream, obj as ChallengeInvitationRequest);
                result = true;
                break;
            case 45628:
                this.writeChallengeCancelledMessage(outStream, obj as ChallengeCancelledMessage);
                result = true;
                break;
            case 45629:
                this.writeChallengeHistoryData(outStream, obj as ChallengeHistoryData);
                result = true;
                break;
            case 45630:
                this.writeChallengeHistoryRequest(outStream, obj as ChallengeHistoryRequest);
                result = true;
                break;
            case 45631:
                this.writeChallengeHistoryResponse(outStream, obj as ChallengeHistoryResponse);
                result = true;
                break;
            case 45632:
                this.writeChallengeTemplate(outStream, obj as ChallengeTemplate);
                result = true;
                break;
            case 45633:
                this.writeChallengeTemplatesList(outStream, obj as ChallengeTemplatesList);
                result = true;
                break;
            case 45634:
                this.writeRequestListOfChallengeTemplates(outStream, obj as RequestListOfChallengeTemplates);
                result = true;
                break;
            case 45635:
                this.writeDeletePrivateTableRequest(outStream, obj as DeletePrivateTableRequest);
                result = true;
                break;
            case 45636:
                this.writeDeletePrivateTableResponse(outStream, obj as DeletePrivateTableResponse);
                result = true;
                break;
            case 45637:
                this.writePrivateTableInvitationRequest(outStream, obj as PrivateTableInvitationRequest);
                result = true;
                break;
            case 45638:
                this.writeCreatePrivateTableResponse(outStream, obj as CreatePrivateTableResponse);
                result = true;
                break;
            case 45639:
                this.writeCreatePrivateTableRequest(outStream, obj as CreatePrivateTableRequest);
                result = true;
                break;
            case 45640:
                this.writeNotifyPrivateTableCreator(outStream, obj as NotifyPrivateTableCreator);
                result = true;
                break;
            case 45641:
                this.writeHUSNGRankResultWithRematchRequest(outStream, obj as HUSNGRankResultWithRematchRequest);
                result = true;
                break;
            case 45642:
                this.writeRequestHUSNGRematchAccepted(outStream, obj as RequestHUSNGRematchAccepted);
                result = true;
                break;
            case 45643:
                this.writeRequestHUSNGRematchRejected(outStream, obj as RequestHUSNGRematchRejected);
                result = true;
                break;
            case 45644:
                this.writeRequestHUSNGRematchAcceptedAck(outStream, obj as RequestHUSNGRematchAcceptedAck);
                result = true;
                break;
            case 45645:
                this.writeResponseHUSNGRematchAccepted(outStream, obj as ResponseHUSNGRematchAccepted);
                result = true;
                break;
            case 45646:
                this.writeResponseHUSNGRematchRejected(outStream, obj as ResponseHUSNGRematchRejected);
                result = true;
                break;
            case 45647:
                this.writeResponseHUSNGRematchTimeout(outStream, obj as ResponseHUSNGRematchTimeout);
                result = true;
                break;
            case 45648:
                this.writeResponseHUSNGRematchBuyInFailed(outStream, obj as ResponseHUSNGRematchBuyInFailed);
                result = true;
                break;
            case 45649:
                this.writeSNGRankResultWithReplayRequest(outStream, obj as SNGRankResultWithReplayRequest);
                result = true;
                break;
            case 45650:
                this.writeTournamentRegisteredPlayerInfo(outStream, obj as TournamentRegisteredPlayerInfo);
                result = true;
                break;
            case 45651:
                this.writeCanJoinPrivateTableRequest(outStream, obj as CanJoinPrivateTableRequest);
                result = true;
                break;
            case 45652:
                this.writeCanJoinPrivateTableResponse(outStream, obj as CanJoinPrivateTableResponse);
                result = true;
                break;
            case 45653:
                this.writeUserFreeRollList(outStream, obj as UserFreeRollList);
                result = true;
                break;
            case 45654:
                this.writeAddedFreeRoll(outStream, obj as AddedFreeRoll);
                result = true;
                break;
            case 45655:
                this.writeRemovedFreeRoll(outStream, obj as RemovedFreeRoll);
                result = true;
                break;
            case 45656:
                this.writeTableStakesInfoRequest(outStream, obj as TableStakesInfoRequest);
                result = true;
                break;
            case 45657:
                this.writeTableStakesInfoResponse(outStream, obj as TableStakesInfoResponse);
                result = true;
                break;
            case 45658:
                this.writeUncalledBetReturn(outStream, obj as UncalledBetReturn);
                result = true;
                break;
            case 45659:
                this.writeTableStakesInfo(outStream, obj as TableStakesInfo);
                result = true;
                break;
            case 45660:
                this.writeTableStakeLevelInfoRequest(outStream, obj as TableStakeLevelInfoRequest);
                result = true;
                break;
            case 45661:
                this.writeTableStakeLevelInfoResponse(outStream, obj as TableStakeLevelInfoResponse);
                result = true;
                break;
            case 45662:
                this.writeContentLockingAccessDeniedResponse(outStream, obj as ContentLockingAccessDeniedResponse);
                result = true;
                break;
            case 45663:
                this.writeMTTournamentFinalTable(outStream, obj as MTTournamentFinalTable);
                result = true;
                break;
            case 45664:
                this.writeResponseBuyMoreChipsNotAllowed(outStream, obj as ResponseBuyMoreChipsNotAllowed);
                result = true;
                break;
            case 45665:
                this.writePoolForcedSitOutNotification(outStream, obj as PoolForcedSitOutNotification);
                result = true;
                break;
            case 45670:
                this.writeResponseRebuyGenericFailure(outStream, obj as ResponseRebuyGenericFailure);
                result = true;
                break;
            case 45671:
                this.writeResponseRebuyAddonGenericFailure(outStream, obj as ResponseRebuyAddonGenericFailure);
                result = true;
                break;
            case 45673:
                this.writeSitOutInfoNotification(outStream, obj as SitOutInfoNotification);
                result = true;
                break;
            case 45674:
                this.writeTournamentBackendUnRegistrationsInfo(outStream, obj as TournamentBackendUnRegistrationsInfo);
                result = true;
                break;
            case 45675:
                this.writeTournamentBackendUnRegistrationsInfoAck(outStream, obj as TournamentBackendUnRegistrationsInfoAck);
                result = true;
                break;
            case 45676:
                this.writeTournamentShowAddonPopup(outStream, obj as TournamentShowAddonPopup);
                result = true;
                break;
            case 45678:
                this.writeAppFriendsListRequest(outStream, obj as AppFriendsListRequest);
                result = true;
                break;
            case 45679:
                this.writeAppFriendsListResponse(outStream, obj as AppFriendsListResponse);
                result = true;
                break;
            case 45680:
                this.writeGamingSessionTimedOutPopUp(outStream, obj as GamingSessionTimedOutPopUp);
                result = true;
                break;
            case 45681:
                this.writeUnblockGamingSessionTimedOutPlayer(outStream, obj as UnblockGamingSessionTimedOutPlayer);
                result = true;
                break;
            case 45682:
                this.writeGamingSessionTimedOutPopUpAck(outStream, obj as GamingSessionTimedOutPopUpAck);
                result = true;
                break;
            case 45683:
                this.writeSitOutWarningOn(outStream, obj as SitOutWarningOn);
                result = true;
                break;
            case 45684:
                this.writeSitOutWarningOff(outStream, obj as SitOutWarningOff);
                result = true;
                break;
            case 45685:
                this.writePlayerBalanceInfo(outStream, obj as PlayerBalanceInfo);
                result = true;
                break;
            case 45686:
                this.writeFRONTALLeavePoolResponse(outStream, obj as FRONTALLeavePoolResponse);
                result = true;
                break;
            case 45687:
                this.writeFRONTALJoinPoolResponse(outStream, obj as FRONTALJoinPoolResponse);
                result = true;
                break;
            case 45688:
                this.writeFRONTALPoolRebuyResponse(outStream, obj as FRONTALPoolRebuyResponse);
                result = true;
                break;
            case 45689:
                this.writeSaveMTTPlayerAutoRebuyAddonPreferences(outStream, obj as SaveMTTPlayerAutoRebuyAddonPreferences);
                result = true;
                break;
            case 45690:
                this.writeMTTPlayerAutoRebuyAddonPreferences(outStream, obj as MTTPlayerAutoRebuyAddonPreferences);
                result = true;
                break;
            case 45691:
                this.writeCountryBlockEventRequest(outStream, obj as CountryBlockEventRequest);
                result = true;
                break;
            case 45692:
                this.writeCountryBlockEventResponse(outStream, obj as CountryBlockEventResponse);
                result = true;
                break;
            case 45693:
                this.writePoolItaliaAuthorizationCodes(outStream, obj as PoolItaliaAuthorizationCodes);
                result = true;
                break;
            case 45696:
                this.writePoolPartnerRebuyRequest(outStream, obj as PoolPartnerRebuyRequest);
                result = true;
                break;
            case 45697:
                this.writePoolRequestUserBalancePartnerPlayer(outStream, obj as PoolRequestUserBalancePartnerPlayer);
                result = true;
                break;
            case 45698:
                this.writePoolUserAllBalance(outStream, obj as PoolUserAllBalance);
                result = true;
                break;
            case 45699:
                this.writeShowPlayNow(outStream, obj as ShowPlayNow);
                result = true;
                break;
            case 45700:
                this.writeDoPlayNow(outStream, obj as DoPlayNow);
                result = true;
                break;
            case 45701:
                this.writeRemovePlayNow(outStream, obj as RemovePlayNow);
                result = true;
                break;
            case 45702:
                this.writeTournamentParticipantRankInfo(outStream, obj as TournamentParticipantRankInfo);
                result = true;
                break;
            case 45703:
                this.writeTourneyRegistrationStatus(outStream, obj as TourneyRegistrationStatus);
                result = true;
                break;
            case 45704:
                this.writePlayerStatuses(outStream, obj as PlayerStatuses);
                result = true;
                break;
            case 45705:
                this.writeHUDAnonymousState(outStream, obj as HUDAnonymousState);
                result = true;
                break;
            case 45706:
                this.writeSNGJPPlayNowRequest(outStream, obj as SNGJPPlayNowRequest);
                result = true;
                break;
            case 45707:
                this.writeSNGJPPlayNowResponse(outStream, obj as SNGJPPlayNowResponse);
                result = true;
                break;
            case 45708:
                this.writeSNGJPRegistrationRequest(outStream, obj as SNGJPRegistrationRequest);
                result = true;
                break;
            case 45709:
                this.writeSNGJPRegistrationResponse(outStream, obj as SNGJPRegistrationResponse);
                result = true;
                break;
            case 45710:
                this.writeSNGJPRegistrationInfo(outStream, obj as SNGJPRegistrationInfo);
                result = true;
                break;
            case 45711:
                this.writeSNGJPUnRegistrationRequest(outStream, obj as SNGJPUnRegistrationRequest);
                result = true;
                break;
            case 45712:
                this.writeSNGJPUnRegistrationResponse(outStream, obj as SNGJPUnRegistrationResponse);
                result = true;
                break;
            case 45713:
                this.writeSNGJackpotInfo(outStream, obj as SNGJackpotInfo);
                result = true;
                break;
            case 45714:
                this.writeSNGJPPayoutAnimation(outStream, obj as SNGJPPayoutAnimation);
                result = true;
                break;
            case 45715:
                this.writeSNGJPPayoutInfo(outStream, obj as SNGJPPayoutInfo);
                result = true;
                break;
            case 45716:
                this.writeSNGJPPrizeInfo(outStream, obj as SNGJPPrizeInfo);
                result = true;
                break;
            case 45717:
                this.writeSNGJPBountyInfo(outStream, obj as SNGJPBountyInfo);
                result = true;
                break;
            case 45718:
                this.writeSNGJPBountyWinner(outStream, obj as SNGJPBountyWinner);
                result = true;
                break;
            case 45719:
                this.writeSNGJPGameAboutToStart(outStream, obj as SNGJPGameAboutToStart);
                result = true;
                break;
            case 45720:
                this.writeSNGJPTournamentRankInfo(outStream, obj as SNGJPTournamentRankInfo);
                result = true;
                break;
            case 45721:
                this.writeSNGJPRegistrationFailureDetails(outStream, obj as SNGJPRegistrationFailureDetails);
                result = true;
                break;
            case 45722:
                this.writeSNGJPBountyAwardInfo(outStream, obj as SNGJPBountyAwardInfo);
                result = true;
                break;
            case 45724:
                this.writeEnableChat(outStream, obj as EnableChat);
                result = true;
                break;
            case 45725:
                this.writeWatchersInfo(outStream, obj as WatchersInfo);
                result = true;
                break;
            case 45726:
                this.writePlayersBountyInfo(outStream, obj as PlayersBountyInfo);
                result = true;
                break;
            case 45727:
                this.writeProgressiveBountyAnimationInfo(outStream, obj as ProgressiveBountyAnimationInfo);
                result = true;
                break;
            case 45728:
                this.writeTourneyPauseInfo(outStream, obj as TourneyPauseInfo);
                result = true;
                break;
            case 45729:
                this.writeRemoveTourneyPauseInfo(outStream, obj as RemoveTourneyPauseInfo);
                result = true;
                break;
            case 45730:
                this.writePromoWinnerPlayerList(outStream, obj as PromoWinnerPlayerList);
                result = true;
                break;
            case 45731:
                this.writeResponseLossLimitExceed(outStream, obj as ResponseLossLimitExceed);
                result = true;
                break;
            case 45732:
                this.writeBetLimitErrorMessage(outStream, obj as BetLimitErrorMessage);
                result = true;
                break;
            case 45733:
                this.writeLoginSessionTimeoutIntimation(outStream, obj as LoginSessionTimeoutIntimation);
                result = true;
                break;
            case 45734:
                this.writeReadLatestRandomSeatingFlag(outStream, obj as ReadLatestRandomSeatingFlag);
                result = true;
                break;
            case 45735:
                this.writeGameCurrencyDetailsList(outStream, obj as GameCurrencyDetailsList);
                result = true;
                break;
            case 45736:
                this.writeGeneralRequestAddMorePlayMoney(outStream, obj as GeneralRequestAddMorePlayMoney);
                result = true;
                break;
            case 45737:
                this.writeMorePlayMoneyGeneralResponse(outStream, obj as MorePlayMoneyGeneralResponse);
                result = true;
                break;
            case 45738:
                this.writeReduceChips(outStream, obj as ReduceChips);
                result = true;
                break;
            case 45739:
                this.writeReduceChipsResponse(outStream, obj as ReduceChipsResponse);
                result = true;
                break;
            case 45740:
                this.writeSessionTimeRemainingInfo(outStream, obj as SessionTimeRemainingInfo);
                result = true;
                break;
            case 45741:
                this.writeRealityCheckSummaryPopUp(outStream, obj as RealityCheckSummaryPopUp);
                result = true;
                break;
            case 45742:
                this.writeRealityCheckSummaryPopUpAck(outStream, obj as RealityCheckSummaryPopUpAck);
                result = true;
                break;
            case 45743:
                this.writeClosePoolEntry(outStream, obj as ClosePoolEntry);
                result = true;
                break;
            case 45744:
                this.writePlayerGameSessionData(outStream, obj as PlayerGameSessionData);
                result = true;
                break;
            case 45747:
                this.writePlayerVerificationStatus(outStream, obj as PlayerVerificationStatus);
                result = true;
                break;
            case 45748:
                this.writeSelectedOptionNew(outStream, obj as SelectedOptionNew);
                result = true;
                break;
            case 45749:
                this.writeReqShowEmoji(outStream, obj as ReqShowEmoji);
                result = true;
                break;
            case 45750:
                this.writeShowEmoji(outStream, obj as ShowEmoji);
                result = true;
                break;
            case 45751:
                this.writeShowRabbit(outStream, obj as ShowRabbit);
                result = true;
                break;
            case 45752:
                this.writeReqEnableRabbit(outStream, obj as ReqEnableRabbit);
                result = true;
                break;
            case 45753:
                this.writeBroadcastShowRabbit(outStream, obj as BroadcastShowRabbit);
                result = true;
                break;
            case 45754:
                this.writeReqThrowAnimation(outStream, obj as ReqThrowAnimation);
                result = true;
                break;
            case 45755:
                this.writeResThrowAnimation(outStream, obj as ResThrowAnimation);
                result = true;
                break;
            case 45756:
                this.writeThrowAnimation(outStream, obj as ThrowAnimation);
                result = true;
                break;
            case 45757:
                this.writeGetPokerDiamondBalance(outStream, obj as GetPokerDiamondBalance);
                result = true;
                break;
            case 45758:
                this.writeProbabilityOfWinning(outStream, obj as ProbabilityOfWinning);
                result = true;
                break;
            case 45759:
                this.writeDiamondBalance(outStream, obj as DiamondBalance);
                result = true;
                break;
            case 45761:
                this.writeReqEnablePlayerRunittwiceState(outStream, obj as ReqEnablePlayerRunittwiceState);
                result = true;
                break;
            case 45762:
                this.writeRequestHUPairTable(outStream, obj as RequestHUPairTable);
                result = true;
                break;
            case 45763:
                this.writeHUPairTableSelectStatus(outStream, obj as HUPairTableSelectStatus);
                result = true;
                break;
            case 45764:
                this.writePlayersBountyWonInfo(outStream, obj as PlayersBountyWonInfo);
                result = true;
                break;
            case 45765:
                this.writeITMInformation(outStream, obj as ITMInformation);
                result = true;
                break;
            case 45766:
                this.writeShowHUPairTable(outStream, obj as ShowHUPairTable);
                result = true;
                break;
            case 45767:
                this.writeRCPUActionAcknowledgement(outStream, obj as RCPUActionAcknowledgement);
                result = true;
                break;
            case 45768:
                this.writeRequestJoinTableAutoBuyin(outStream, obj as RequestJoinTableAutoBuyin);
                result = true;
                break;
            case 45769:
                this.writeDepositedFriendCount(outStream, obj as DepositedFriendCount);
                result = true;
                break;
            case 45770:
                this.writeHUEvenGame(outStream, obj as HUEvenGame);
                result = true;
                break;
            case 45771:
                this.writeRequestPlayerNoteId(outStream, obj as RequestPlayerNoteId);
                result = true;
                break;
            case 45772:
                this.writeResponsePlayerNoteId(outStream, obj as ResponsePlayerNoteId);
                result = true;
                break;
            case 45774:
                this.writeRequestUserClubDetails(outStream, obj as RequestUserClubDetails);
                result = true;
                break;
            case 45775:
                this.writeUserClubDetails(outStream, obj as UserClubDetails);
                result = true;
                break;
            case 45776:
                this.writeUserClubRemoved(outStream, obj as UserClubRemoved);
                result = true;
                break;
            case 45777:
                this.writeResponseUserClubDetails(outStream, obj as ResponseUserClubDetails);
                result = true;
                break;
            case 45778:
                this.writeClubNameApproved(outStream, obj as ClubNameApproved);
                result = true;
                break;
            case 45779:
                this.writeUserClubMemeberShipApproved(outStream, obj as UserClubMemeberShipApproved);
                result = true;
                break;
            case 45780:
                this.writeUserClubMemeberTypeChange(outStream, obj as UserClubMemeberTypeChange);
                result = true;
                break;
            case 45781:
                this.writeRequestRealName(outStream, obj as RequestRealName);
                result = true;
                break;
            case 45782:
                this.writeResponseRealName(outStream, obj as ResponseRealName);
                result = true;
                break;
            case 45784:
                this.writePlayerTableList(outStream, obj as PlayerTableList);
                result = true;
                break;
            case 45785:
                this.writeTableBoosterTriggered(outStream, obj as TableBoosterTriggered);
                result = true;
                break;
            case 45786:
                this.writeBroadcastTableBoosterTrigger(outStream, obj as BroadcastTableBoosterTrigger);
                result = true;
                break;
            case 45787:
                this.writeTableBoosterWinners(outStream, obj as TableBoosterWinners);
                result = true;
                break;
            case 45788:
                this.writeBoosterWinners(outStream, obj as BoosterWinners);
                result = true;
                break;
            case 45789:
                this.writeFRSessionTimer(outStream, obj as FRSessionTimer);
                result = true;
                break;
            case 45796:
                this.writeITMAdditionalInformation(outStream, obj as ITMAdditionalInformation);
                result = true;
                break;
            case 45797:
                this.writeResponseGermanTaxValue(outStream, obj as ResponseGermanTaxValue);
                result = true;
                break;
            case 45798:
                this.writeTournamentLateRegistrationDetails(outStream, obj as TournamentLateRegistrationDetails);
                result = true;
                break;
            case 45799:
                this.writeLossLimitDetails(outStream, obj as LossLimitDetails);
                result = true;
                break;
            case 45800:
                this.writeLossLimitResponse(outStream, obj as LossLimitResponse);
                result = true;
                break;
            case 45801:
                this.writeSessionLimitsResponse(outStream, obj as SessionLimitsResponse);
                result = true;
                break;
            case 45802:
                this.writeResponseFoldNShowCards(outStream, obj as ResponseFoldNShowCards);
                result = true;
                break;
            case 45803:
                this.writeRequestCashoutEquity(outStream, obj as RequestCashoutEquity);
                result = true;
                break;
            case 45804:
                this.writePlayerCashedOutEquityStatus(outStream, obj as PlayerCashedOutEquityStatus);
                result = true;
                break;
            case 45805:
                this.writePlayerCashoutEquityDetails(outStream, obj as PlayerCashoutEquityDetails);
                result = true;
                break;
            case 45806:
                this.writePlayersCashoutEquityDetails(outStream, obj as PlayersCashoutEquityDetails);
                result = true;
                break;
            case 45807:
                this.writeResponseCashoutEquity(outStream, obj as ResponseCashoutEquity);
                result = true;
                break;
            case 45810:
                this.writeShowCards2(outStream, obj as ShowCards2);
                result = true;
                break;
            case 45813:
                this.writePlayerATHStrike(outStream, obj as PlayerATHStrike);
                result = true;
                break;
            case 45814:
                this.writeATHStrikeHandCountConfiguration(outStream, obj as ATHStrikeHandCountConfiguration);
                result = true;
                break;
            case 45816:
                this.writeResponseUserTableBalanceReduceChips(outStream, obj as ResponseUserTableBalanceReduceChips);
                result = true;
                break;
            case 45817:
                this.writeTableReduceBalanceLimit(outStream, obj as TableReduceBalanceLimit);
                result = true;
                break;
            case 45818:
                this.writeRequestTableLimitBalance(outStream, obj as RequestTableLimitBalance);
                result = true;
                break;
            case 45819:
                this.writePlayedGamesCount(outStream, obj as PlayedGamesCount);
                result = true;
                break;
            case 45820:
                this.writeCanLeaveTable(outStream, obj as CanLeaveTable);
                result = true;
                break;
            case 45821:
                this.writeCanLeaveTableResponse(outStream, obj as CanLeaveTableResponse);
                result = true;
                break;
            case 45822:
                this.writeCanSitOut(outStream, obj as CanSitOut);
                result = true;
                break;
            case 45823:
                this.writeCanSitOutResponse(outStream, obj as CanSitOutResponse);
                result = true;
                break;
            case 45824:
                this.writeATHAutoSitOutMsg(outStream, obj as ATHAutoSitOutMsg);
                result = true;
                break;
            case 45825:
                this.writeTourneyCancelled(outStream, obj as TourneyCancelled);
                result = true;
                break;
            case 45827:
                this.writeTournamentCurrentStatus(outStream, obj as TournamentCurrentStatus);
                result = true;
                break;
            case 45842:
                this.writePlayerAccountType(outStream, obj as PlayerAccountType);
                result = true;
                break;
            case 45843:
                this.writePlayerGameSessionLimitsStatusResponse(outStream, obj as PlayerGameSessionLimitsStatusResponse);
                result = true;
                break;
            case 45844:
                this.writePlayerGameSessionRemainingLimitsStatusResponse(outStream, obj as PlayerGameSessionRemainingLimitsStatusResponse);
                result = true;
                break;
            case 45845:
                this.writePlayerGameSessionRemainingLimitsRequest(outStream, obj as PlayerGameSessionRemainingLimitsRequest);
                result = true;
                break;
            case 45846:
                this.writeSetPlayerGameSessionLimitsRequest(outStream, obj as SetPlayerGameSessionLimitsRequest);
                result = true;
                break;
            case 45847:
                this.writePlayerGameSessionReminder(outStream, obj as PlayerGameSessionReminder);
                result = true;
                break;
            case 45848:
                this.writePlayerGameSessionLimitsStatusRequest(outStream, obj as PlayerGameSessionLimitsStatusRequest);
                result = true;
                break;
            case 45850:
                this.writeServerParamUpdateToClient(outStream, obj as ServerParamUpdateToClient);
                result = true;
                break;
            case 45865:
                this.writeUserLocationStatus(outStream, obj as UserLocationStatus);
                result = true;
                break;
            case 45866:
                this.writeShowPopup(outStream, obj as ShowPopup);
                result = true;
                break;
            case 45867:
                this.writeResponseGeolocationStatus(outStream, obj as ResponseGeolocationStatus);
                result = true;
                break;
            case 45868:
                this.writeResponseBuyMoreChipsGeoLocationBlocked(outStream, obj as ResponseBuyMoreChipsGeoLocationBlocked);
                result = true;
                break;
            case 45869:
                this.writeSessionLimitNotification(outStream, obj as SessionLimitNotification);
                result = true;
                break;
            case 45870:
                this.writeSessionReset(outStream, obj as SessionReset);
                result = true;
                break;
            case 45871:
                this.writeSessionTimeRemainingNotification(outStream, obj as SessionTimeRemainingNotification);
                result = true;
                break;
            case 45872:
                this.writeResponseBuyMoreChipsSessiontimeExpired(outStream, obj as ResponseBuyMoreChipsSessiontimeExpired);
                result = true;
                break;
            case 45873:
                this.writeResponseAddonRebuyFailure(outStream, obj as ResponseAddonRebuyFailure);
                result = true;
                break;
            case 45874:
                this.writeResponseBuyMoreChipsLossLimitExceeded(outStream, obj as ResponseBuyMoreChipsLossLimitExceeded);
                result = true;
                break;
            case 45875:
                this.writeBlockPlayerOnInvalidLocation(outStream, obj as BlockPlayerOnInvalidLocation);
                result = true;
                break;
            case 45876:
                this.writeRequestSessionLimitsData(outStream, obj as RequestSessionLimitsData);
                result = true;
                break;
            case 45877:
                this.writeResponseSessionLimitsData(outStream, obj as ResponseSessionLimitsData);
                result = true;
                break;
            case 45878:
                this.writeNetPositionDetails(outStream, obj as NetPositionDetails);
                result = true;
                break;
            case 45879:
                this.writeForceLogOutRequest(outStream, obj as ForceLogOutRequest);
                result = true;
                break;
            case 45880:
                this.writeMultiConnectionPostLoginSyncupMessage(outStream, obj as MultiConnectionPostLoginSyncupMessage);
                result = true;
                break;
            case 45882:
                this.writeResponseElapsedTime(outStream, obj as ResponseElapsedTime);
                result = true;
                break;
            case 45888:
                this.writeBigWinNotification(outStream, obj as BigWinNotification);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    readAnte(inStream: TSDataInputStream, obj: Ante): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccepted(inStream.getBoolean());
        obj.setAmount(inStream.getLong());
        obj.setAnteType(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
    }

    writeAnte(outStream: TSDataOutputStream, obj: Ante): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getAccepted());
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getAnteType());
        outStream.putByte(obj.getSeatNo());
    }
    readBlind(inStream: TSDataInputStream, obj: Blind): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccepted(inStream.getBoolean());
        obj.setAmount(inStream.getLong());
        obj.setBlindType(inStream.getInteger());
        obj.setDeadAmt(inStream.getLong());
        obj.setSeatNo(inStream.getByte());
        obj.setDeadAnteAmount(inStream.getLong());
    }

    writeBlind(outStream: TSDataOutputStream, obj: Blind): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getAccepted());
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getBlindType());
        outStream.putLong(obj.getDeadAmt());
        outStream.putByte(obj.getSeatNo());
        outStream.putLong(obj.getDeadAnteAmount());
    }
    readBuyMoreChips(inStream: TSDataInputStream, obj: BuyMoreChips): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setConversationId(inStream.getLong());
        obj.setIncludePartnerWallet(inStream.getByte());
    }

    writeBuyMoreChips(outStream: TSDataOutputStream, obj: BuyMoreChips): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putLong(obj.getConversationId());
        outStream.putByte(obj.getIncludePartnerWallet());
    }
    readPvtTableType(inStream: TSDataInputStream, obj: PvtTableType): void {
        obj.setGameTypeId(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxBuyin(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
    }

    writePvtTableType(outStream: TSDataOutputStream, obj: PvtTableType): void {
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxBuyin());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getTableInfoId());
    }
    readHandHistory(inStream: TSDataInputStream, obj: HandHistory): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setNoOfHands(inStream.getInteger());
    }

    writeHandHistory(outStream: TSDataOutputStream, obj: HandHistory): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getNoOfHands());
    }
    readInvitation(inStream: TSDataInputStream, obj: Invitation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDestination(inStream.getString());
        obj.setMessageBody(inStream.getString());
        obj.setSource(inStream.getString());
    }

    writeInvitation(outStream: TSDataOutputStream, obj: Invitation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getDestination());
        outStream.putString(obj.getMessageBody());
        outStream.putString(obj.getSource());
    }
    readJoinTable(inStream: TSDataInputStream, obj: JoinTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPassword(inStream.getString());
    }

    writeJoinTable(outStream: TSDataOutputStream, obj: JoinTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getPassword());
    }
    readSeatAvailableResponse(inStream: TSDataInputStream, obj: SeatAvailableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setServerPeerId(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
    }

    writeSeatAvailableResponse(outStream: TSDataOutputStream, obj: SeatAvailableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseId());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putInteger(obj.getTableId());
    }
    readSelectedOption(inStream: TSDataInputStream, obj: SelectedOption): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setBetVector(inStream.getLongArray());
        obj.setIsAllIn(inStream.getBoolean());
        obj.setOptionId(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
    }

    writeSelectedOption(outStream: TSDataOutputStream, obj: SelectedOption): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putLongArray(obj.getBetVector());
        outStream.putBoolean(obj.getIsAllIn());
        outStream.putInteger(obj.getOptionId());
        outStream.putByte(obj.getSeatNo());
    }
    readSit(inStream: TSDataInputStream, obj: Sit): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setFrFppIndicator(inStream.getByte());
        obj.setNoOfChips(inStream.getLong());
        obj.setSeatingPosition(inStream.getInteger());
        obj.setTableCategory(inStream.getByte());
    }

    writeSit(outStream: TSDataOutputStream, obj: Sit): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putByte(obj.getFrFppIndicator());
        outStream.putLong(obj.getNoOfChips());
        outStream.putInteger(obj.getSeatingPosition());
        outStream.putByte(obj.getTableCategory());
    }
    readTabConfigText(inStream: TSDataInputStream, obj: TabConfigText): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setText(inStream.getString());
        obj.setVersion(inStream.getString());
    }

    writeTabConfigText(outStream: TSDataOutputStream, obj: TabConfigText): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getText());
        outStream.putString(obj.getVersion());
    }
    readAddCommunityCards(inStream: TSDataInputStream, obj: AddCommunityCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCardNo(inStream.getInteger());
        obj.setCardType(inStream.getByte());
        obj.setCards(inStream.getStringArray());
    }

    writeAddCommunityCards(outStream: TSDataOutputStream, obj: AddCommunityCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getCardNo());
        outStream.putByte(obj.getCardType());
        outStream.putStringArray(obj.getCards());
    }
    readAddPlayer(inStream: TSDataInputStream, obj: AddPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalance(inStream.getLong());
        obj.setBrandId(inStream.getString());
        obj.setCity(inStream.getString());
        obj.setCountry(inStream.getString());
        obj.setImageUrl(inStream.getString());
        obj.setIsCustomAvatar(inStream.getBoolean());
        obj.setIsSeatedPlayer(inStream.getBoolean());
        obj.setPlayerId(inStream.getString());
        obj.setSeatingPosition(inStream.getInteger());
        obj.setSex(inStream.getString());
        obj.setAvatarImagePath(inStream.getString());
        obj.setAvatarImageName(inStream.getString());
        obj.setPlayerAccountId(inStream.getInteger());
        obj.setPlayerFullName(inStream.getString());
        obj.setPlayerRealName(inStream.getString());
        obj.setPlayerNoteId(inStream.getString());
        obj.setPrivateScn(inStream.getBoolean());
        obj.setCountryCode(inStream.getString());
    }

    writeAddPlayer(outStream: TSDataOutputStream, obj: AddPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getBalance());
        outStream.putString(obj.getBrandId());
        outStream.putString(obj.getCity());
        outStream.putString(obj.getCountry());
        outStream.putString(obj.getImageUrl());
        outStream.putBoolean(obj.getIsCustomAvatar());
        outStream.putBoolean(obj.getIsSeatedPlayer());
        outStream.putString(obj.getPlayerId());
        outStream.putInteger(obj.getSeatingPosition());
        outStream.putString(obj.getSex());
        outStream.putString(obj.getAvatarImagePath());
        outStream.putString(obj.getAvatarImageName());
        outStream.putInteger(obj.getPlayerAccountId());
        outStream.putString(obj.getPlayerFullName());
        outStream.putString(obj.getPlayerRealName());
        outStream.putString(obj.getPlayerNoteId());
        outStream.putBoolean(obj.getPrivateScn());
        outStream.putString(obj.getCountryCode());
    }
    readAllInPot(inStream: TSDataInputStream, obj: AllInPot): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getInteger());
        obj.setPotNumber(inStream.getInteger());
    }

    writeAllInPot(outStream: TSDataOutputStream, obj: AllInPot): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAmount());
        outStream.putInteger(obj.getPotNumber());
    }
    readAllInsRemaining(inStream: TSDataInputStream, obj: AllInsRemaining): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAllIns(inStream.getInteger());
    }

    writeAllInsRemaining(outStream: TSDataOutputStream, obj: AllInsRemaining): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAllIns());
    }
    readAutoOptionSet(inStream: TSDataInputStream, obj: AutoOptionSet): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAutoOptionSet(inStream.getObjectArray());
        obj.setSelectedAutoOptionId(inStream.getInteger());
    }

    writeAutoOptionSet(outStream: TSDataOutputStream, obj: AutoOptionSet): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getAutoOptionSet());
        outStream.putInteger(obj.getSelectedAutoOptionId());
    }
    readChangePlayerState(inStream: TSDataInputStream, obj: ChangePlayerState): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
        obj.setState(inStream.getInteger());
    }

    writeChangePlayerState(outStream: TSDataOutputStream, obj: ChangePlayerState): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getSeatNo());
        outStream.putInteger(obj.getState());
    }
    readCurrentPlayer(inStream: TSDataInputStream, obj: CurrentPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
    }

    writeCurrentPlayer(outStream: TSDataOutputStream, obj: CurrentPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getSeatNo());
    }
    readDealCards(inStream: TSDataInputStream, obj: DealCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setNumberOfTurns(inStream.getInteger());
        obj.setPlayerDealCards(inStream.getObjectArray());
    }

    writeDealCards(outStream: TSDataOutputStream, obj: DealCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getNumberOfTurns());
        outStream.putObjectArray(obj.getPlayerDealCards());
    }
    readDealer(inStream: TSDataInputStream, obj: Dealer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDealerId(inStream.getString());
        obj.setSeatingPosition(inStream.getInteger());
    }

    writeDealer(outStream: TSDataOutputStream, obj: Dealer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getDealerId());
        outStream.putInteger(obj.getSeatingPosition());
    }
    readEndTheRound(inStream: TSDataInputStream, obj: EndTheRound): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrentPotAmount(inStream.getLong());
        obj.setJackpotRake(inStream.getInteger());
        obj.setRake(inStream.getInteger());
        obj.setRoundCount(inStream.getInteger());
        obj.setSidePots(inStream.getObjectArray());
        obj.setUncalledBetAmount(inStream.getLong());
        obj.setUncalledBetPlrSeatNo(inStream.getInteger());
    }

    writeEndTheRound(outStream: TSDataOutputStream, obj: EndTheRound): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getCurrentPotAmount());
        outStream.putInteger(obj.getJackpotRake());
        outStream.putInteger(obj.getRake());
        outStream.putInteger(obj.getRoundCount());
        outStream.putObjectArray(obj.getSidePots());
        outStream.putLong(obj.getUncalledBetAmount());
        outStream.putInteger(obj.getUncalledBetPlrSeatNo());
    }
    readMuckCards(inStream: TSDataInputStream, obj: MuckCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
    }

    writeMuckCards(outStream: TSDataOutputStream, obj: MuckCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getSeatNo());
    }
    readNewGame(inStream: TSDataInputStream, obj: NewGame): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setSeatNo(inStream.getByte());
        obj.setEncGameId(inStream.getString());
        obj.setPrivateGId(inStream.getBoolean());
    }

    writeNewGame(outStream: TSDataOutputStream, obj: NewGame): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getGameId());
        outStream.putByte(obj.getSeatNo());
        outStream.putString(obj.getEncGameId());
        outStream.putBoolean(obj.getPrivateGId());
    }
    readOptionSet(inStream: TSDataInputStream, obj: OptionSet): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBetTimeOutPeriod(inStream.getInteger());
        obj.setGeneratedAt(inStream.getLong());
        obj.setLimitGameOptionSet(inStream.getObjectArray());
        obj.setOptionSet(inStream.getObjectArray());
        obj.setOptionSetId(inStream.getLong());
        obj.setTimeBankAutoEnable(inStream.getBoolean());
        obj.setTimeBankAvailableTime(inStream.getShort());
    }

    writeOptionSet(outStream: TSDataOutputStream, obj: OptionSet): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBetTimeOutPeriod());
        outStream.putLong(obj.getGeneratedAt());
        outStream.putObjectArray(obj.getLimitGameOptionSet());
        outStream.putObjectArray(obj.getOptionSet());
        outStream.putLong(obj.getOptionSetId());
        outStream.putBoolean(obj.getTimeBankAutoEnable());
        outStream.putShort(obj.getTimeBankAvailableTime());
    }
    readPostAnte(inStream: TSDataInputStream, obj: PostAnte): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setAnteType(inStream.getInteger());
        obj.setTimeOutAt(inStream.getInteger());
    }

    writePostAnte(outStream: TSDataOutputStream, obj: PostAnte): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getAnteType());
        outStream.putInteger(obj.getTimeOutAt());
    }
    readPostBlind(inStream: TSDataInputStream, obj: PostBlind): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setBlindType(inStream.getInteger());
        obj.setDeadAmt(inStream.getLong());
        obj.setShowHeadsUpWarning(inStream.getBoolean());
        obj.setTimeOutAt(inStream.getInteger());
    }

    writePostBlind(outStream: TSDataOutputStream, obj: PostBlind): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getBlindType());
        outStream.putLong(obj.getDeadAmt());
        outStream.putBoolean(obj.getShowHeadsUpWarning());
        outStream.putInteger(obj.getTimeOutAt());
    }
    readPotWinners(inStream: TSDataInputStream, obj: PotWinners): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPotNumber(inStream.getInteger());
        obj.setWinners(inStream.getObjectArray());
    }

    writePotWinners(outStream: TSDataOutputStream, obj: PotWinners): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPotNumber());
        outStream.putObjectArray(obj.getWinners());
    }
    readPvtTableTypes(inStream: TSDataInputStream, obj: PvtTableTypes): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPvtTablesList(inStream.getObjectArray());
    }

    writePvtTableTypes(outStream: TSDataOutputStream, obj: PvtTableTypes): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPvtTablesList());
    }
    readGameTableStatus(inStream: TSDataInputStream, obj: GameTableStatus): void {
        obj.setActiveFlag(inStream.getBoolean());
        obj.setAllowPlayerToSit(inStream.getBoolean());
        obj.setAnte(inStream.getLong());
        obj.setAutoLoad(inStream.getBoolean());
        obj.setAvgFlopSeen(inStream.getByte());
        obj.setAvgPot(inStream.getLong());
        obj.setBigBlind(inStream.getLong());
        obj.setBringIn(inStream.getInteger());
        obj.setCasinoTrnyLevelInfo(inStream.getObjectArray());
        obj.setCountDownTime(inStream.getShort());
        obj.setDbTableInfoId(inStream.getInteger());
        obj.setDisconProtectType(inStream.getInteger());
        obj.setFamilyId(inStream.getByte());
        obj.setFppBuyIn(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setGameId(inStream.getLong());
        obj.setGamePlayType(inStream.getInteger());
        obj.setGameTypeId(inStream.getInteger());
        obj.setGroupId(inStream.getInteger());
        obj.setHandsPerHour(inStream.getShort());
        obj.setHuTableType(inStream.getByte());
        obj.setIsChampionshipTable(inStream.getBoolean());
        obj.setIsTBTRandomized(inStream.getBoolean());
        obj.setJackpotId(inStream.getInteger());
        obj.setMaxBuyInAmount(inStream.getInteger());
        obj.setMaxNoOfSeats(inStream.getInteger());
        obj.setMinBuyInAmount(inStream.getInteger());
        obj.setMtctBuyIn(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setPlayersPerFlop(inStream.getInteger());
        obj.setPvtTableType(inStream.getByte());
        obj.setReservedSeatsCount(inStream.getByte());
        obj.setServerPeerId(inStream.getInteger());
        obj.setSmallBlind(inStream.getLong());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setStakesUpperLimit(inStream.getInteger());
        obj.setTabIds(inStream.getIntArray());
        obj.setTableCategory(inStream.getInteger());
        obj.setTableColorCode(inStream.getByte());
        obj.setTableId(inStream.getInteger());
        obj.setTableLimitType(inStream.getInteger());
        obj.setTableName(inStream.getStringEx());
        obj.setTableTypeId(inStream.getInteger());
        obj.setTbtMaxTime(inStream.getByte());
        obj.setTourneyBuyIn(inStream.getInteger());
        obj.setTourneyEntryFee(inStream.getInteger());
        obj.setTourneyGameStatus(inStream.getInteger());
        obj.setTrnyChips(inStream.getLong());
        obj.setWaitListCount(inStream.getInteger());
        obj.setWatchersCount(inStream.getInteger());
        obj.setProtectionLevel(inStream.getInteger());
        obj.setChallengeType(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setBountyFee(inStream.getInteger());
        obj.setTableProfileType(inStream.getInteger());
        obj.setTrnyMixMaxType(inStream.getInteger());
        obj.setBalanceThreshold(inStream.getInteger());
        obj.setRebuyAddonTrnyTable(inStream.getBoolean());
        obj.setRealNameTable(inStream.getBoolean());
        obj.setAnteCashAmount(inStream.getInteger());
        obj.setRestrictedRoomId(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
        obj.setIsFFTable(inStream.getBoolean());
        obj.setSngPayOutType(inStream.getByte());
        obj.setAthApplicable(inStream.getBoolean());
    }

    writeGameTableStatus(outStream: TSDataOutputStream, obj: GameTableStatus): void {
        outStream.putBoolean(obj.getActiveFlag());
        outStream.putBoolean(obj.getAllowPlayerToSit());
        outStream.putLong(obj.getAnte());
        outStream.putBoolean(obj.getAutoLoad());
        outStream.putByte(obj.getAvgFlopSeen());
        outStream.putLong(obj.getAvgPot());
        outStream.putLong(obj.getBigBlind());
        outStream.putInteger(obj.getBringIn());
        outStream.putObjectArray(obj.getCasinoTrnyLevelInfo());
        outStream.putShort(obj.getCountDownTime());
        outStream.putInteger(obj.getDbTableInfoId());
        outStream.putInteger(obj.getDisconProtectType());
        outStream.putByte(obj.getFamilyId());
        outStream.putInteger(obj.getFppBuyIn());
        outStream.putString(obj.getGameCurrency());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getGamePlayType());
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getGroupId());
        outStream.putShort(obj.getHandsPerHour());
        outStream.putByte(obj.getHuTableType());
        outStream.putBoolean(obj.getIsChampionshipTable());
        outStream.putBoolean(obj.getIsTBTRandomized());
        outStream.putInteger(obj.getJackpotId());
        outStream.putInteger(obj.getMaxBuyInAmount());
        outStream.putInteger(obj.getMaxNoOfSeats());
        outStream.putInteger(obj.getMinBuyInAmount());
        outStream.putInteger(obj.getMtctBuyIn());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putInteger(obj.getPlayersPerFlop());
        outStream.putByte(obj.getPvtTableType());
        outStream.putByte(obj.getReservedSeatsCount());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putLong(obj.getSmallBlind());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getStakesUpperLimit());
        outStream.putIntArray(obj.getTabIds());
        outStream.putInteger(obj.getTableCategory());
        outStream.putByte(obj.getTableColorCode());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableLimitType());
        outStream.putStringEx(obj.getTableName());
        outStream.putInteger(obj.getTableTypeId());
        outStream.putByte(obj.getTbtMaxTime());
        outStream.putInteger(obj.getTourneyBuyIn());
        outStream.putInteger(obj.getTourneyEntryFee());
        outStream.putInteger(obj.getTourneyGameStatus());
        outStream.putLong(obj.getTrnyChips());
        outStream.putInteger(obj.getWaitListCount());
        outStream.putInteger(obj.getWatchersCount());
        outStream.putInteger(obj.getProtectionLevel());
        outStream.putInteger(obj.getChallengeType());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putInteger(obj.getBountyFee());
        outStream.putInteger(obj.getTableProfileType());
        outStream.putInteger(obj.getTrnyMixMaxType());
        outStream.putInteger(obj.getBalanceThreshold());
        outStream.putBoolean(obj.getRebuyAddonTrnyTable());
        outStream.putBoolean(obj.getRealNameTable());
        outStream.putInteger(obj.getAnteCashAmount());
        outStream.putInteger(obj.getRestrictedRoomId());
        outStream.putInteger(obj.getClubId());
        outStream.putBoolean(obj.getIsFFTable());
        outStream.putByte(obj.getSngPayOutType());
        outStream.putBoolean(obj.getAthApplicable());
    }
    readRemoveLobbyTable(inStream: TSDataInputStream, obj: RemoveLobbyTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
    }

    writeRemoveLobbyTable(outStream: TSDataOutputStream, obj: RemoveLobbyTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
    }
    readRemovePlayer(inStream: TSDataInputStream, obj: RemovePlayer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDontShowSeatOpen(inStream.getBoolean());
        obj.setIsReserved(inStream.getBoolean());
        obj.setIsSeatedPlayer(inStream.getBoolean());
        obj.setSeatNumber(inStream.getInteger());
    }

    writeRemovePlayer(outStream: TSDataOutputStream, obj: RemovePlayer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getDontShowSeatOpen());
        outStream.putBoolean(obj.getIsReserved());
        outStream.putBoolean(obj.getIsSeatedPlayer());
        outStream.putInteger(obj.getSeatNumber());
    }
    readReserveSeat(inStream: TSDataInputStream, obj: ReserveSeat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNumber(inStream.getInteger());
    }

    writeReserveSeat(outStream: TSDataOutputStream, obj: ReserveSeat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSeatNumber());
    }
    readShowCardsOptions(inStream: TSDataInputStream, obj: ShowCardsOptions): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setShowCardsOptionId(inStream.getInteger());
        obj.setTimeOut(inStream.getInteger());
    }

    writeShowCardsOptions(outStream: TSDataOutputStream, obj: ShowCardsOptions): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getShowCardsOptionId());
        outStream.putInteger(obj.getTimeOut());
    }
    readTimeOut(inStream: TSDataInputStream, obj: TimeOut): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPeriod(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
        obj.setShowClockAt(inStream.getLong());
        obj.setTimeOutType(inStream.getInteger());
    }

    writeTimeOut(outStream: TSDataOutputStream, obj: TimeOut): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPeriod());
        outStream.putByte(obj.getSeatNo());
        outStream.putLong(obj.getShowClockAt());
        outStream.putInteger(obj.getTimeOutType());
    }
    readWaitStatus(inStream: TSDataInputStream, obj: WaitStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setWaitingNo(inStream.getInteger());
        obj.setWaitingStatus(inStream.getInteger());
        obj.setWaitingUsersCount(inStream.getInteger());
    }

    writeWaitStatus(outStream: TSDataOutputStream, obj: WaitStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getWaitingNo());
        outStream.putInteger(obj.getWaitingStatus());
        outStream.putInteger(obj.getWaitingUsersCount());
    }
    readPlayerDealCards(inStream: TSDataInputStream, obj: PlayerDealCards): void {
        obj.setCards(inStream.getStringArray());
        obj.setSeatingPosition(inStream.getInteger());
    }

    writePlayerDealCards(outStream: TSDataOutputStream, obj: PlayerDealCards): void {
        outStream.putStringArray(obj.getCards());
        outStream.putInteger(obj.getSeatingPosition());
    }
    readPlayerStatus(inStream: TSDataInputStream, obj: PlayerStatus): void {
        obj.setAvatarImage(inStream.getString());
        obj.setBalance(inStream.getLong());
        obj.setBet(inStream.getLong());
        obj.setBetVector(inStream.getLongArray());
        obj.setBrandId(inStream.getString());
        obj.setCards(inStream.getStringArray());
        obj.setChatIndicator(inStream.getBoolean());
        obj.setCity(inStream.getString());
        obj.setCountry(inStream.getString());
        obj.setImageURL(inStream.getString());
        obj.setIsCustomAvatar(inStream.getBoolean());
        obj.setIsSeatedPlayer(inStream.getBoolean());
        obj.setPlayerId(inStream.getString());
        obj.setPointCardStatus(inStream.getByte());
        obj.setSeatingPosition(inStream.getInteger());
        obj.setSex(inStream.getString());
        obj.setState(inStream.getInteger());
        obj.setAvatarImageName(inStream.getString());
        obj.setAvatarImagePath(inStream.getString());
        obj.setForceSitOutReasonId(inStream.getByte());
        obj.setPlayerAccountId(inStream.getInteger());
        obj.setPlayerFullName(inStream.getString());
        obj.setPlayerRealName(inStream.getString());
        obj.setPlayerNoteId(inStream.getString());
        obj.setPrivateScn(inStream.getBoolean());
        obj.setCountryCode(inStream.getString());
    }

    writePlayerStatus(outStream: TSDataOutputStream, obj: PlayerStatus): void {
        outStream.putString(obj.getAvatarImage());
        outStream.putLong(obj.getBalance());
        outStream.putLong(obj.getBet());
        outStream.putLongArray(obj.getBetVector());
        outStream.putString(obj.getBrandId());
        outStream.putStringArray(obj.getCards());
        outStream.putBoolean(obj.getChatIndicator());
        outStream.putString(obj.getCity());
        outStream.putString(obj.getCountry());
        outStream.putString(obj.getImageURL());
        outStream.putBoolean(obj.getIsCustomAvatar());
        outStream.putBoolean(obj.getIsSeatedPlayer());
        outStream.putString(obj.getPlayerId());
        outStream.putByte(obj.getPointCardStatus());
        outStream.putInteger(obj.getSeatingPosition());
        outStream.putString(obj.getSex());
        outStream.putInteger(obj.getState());
        outStream.putString(obj.getAvatarImageName());
        outStream.putString(obj.getAvatarImagePath());
        outStream.putByte(obj.getForceSitOutReasonId());
        outStream.putInteger(obj.getPlayerAccountId());
        outStream.putString(obj.getPlayerFullName());
        outStream.putString(obj.getPlayerRealName());
        outStream.putString(obj.getPlayerNoteId());
        outStream.putBoolean(obj.getPrivateScn());
        outStream.putString(obj.getCountryCode());
    }
    readCreatePvtTable(inStream: TSDataInputStream, obj: CreatePvtTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPassword(inStream.getString());
        obj.setPrivateTableType(inStream.getObject());
    }

    writeCreatePvtTable(outStream: TSDataOutputStream, obj: CreatePvtTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getPassword());
        outStream.putObject(obj.getPrivateTableType());
    }
    readSidePot(inStream: TSDataInputStream, obj: SidePot): void {
        obj.setAmount(inStream.getLong());
        obj.setPotNo(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
    }

    writeSidePot(outStream: TSDataOutputStream, obj: SidePot): void {
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getPotNo());
        outStream.putByte(obj.getSeatNo());
    }
    readWinner(inStream: TSDataInputStream, obj: Winner): void {
        obj.setAmount(inStream.getLong());
        obj.setTaxAmount(inStream.getLong());
        obj.setWinnerId(inStream.getString());
        obj.setIsCashoutEqtyWinner(inStream.getInteger());
    }

    writeWinner(outStream: TSDataOutputStream, obj: Winner): void {
        outStream.putLong(obj.getAmount());
        outStream.putLong(obj.getTaxAmount());
        outStream.putString(obj.getWinnerId());
        outStream.putInteger(obj.getIsCashoutEqtyWinner());
    }
    readUnReserveSeat(inStream: TSDataInputStream, obj: UnReserveSeat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNumber(inStream.getInteger());
    }

    writeUnReserveSeat(outStream: TSDataOutputStream, obj: UnReserveSeat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSeatNumber());
    }
    readMorePlayMoneyResponse(inStream: TSDataInputStream, obj: MorePlayMoneyResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setUpdatedPlayMoney(inStream.getLong());
    }

    writeMorePlayMoneyResponse(outStream: TSDataOutputStream, obj: MorePlayMoneyResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseId());
        outStream.putLong(obj.getUpdatedPlayMoney());
    }
    readGiveTourneyChips(inStream: TSDataInputStream, obj: GiveTourneyChips): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTourneyChips(inStream.getLong());
        obj.setTrnyStartedAt(inStream.getLong());
    }

    writeGiveTourneyChips(outStream: TSDataOutputStream, obj: GiveTourneyChips): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getTourneyChips());
        outStream.putLong(obj.getTrnyStartedAt());
    }
    readTourneyResult(inStream: TSDataInputStream, obj: TourneyResult): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountCurrencyCode(inStream.getString());
        obj.setAmountAC(inStream.getInteger());
        obj.setHuRematchDialogueTime(inStream.getInteger());
        obj.setRank(inStream.getInteger());
        obj.setSttReplayAllowed(inStream.getBoolean());
        obj.setWinAmount(inStream.getInteger());
    }

    writeTourneyResult(outStream: TSDataOutputStream, obj: TourneyResult): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountCurrencyCode());
        outStream.putInteger(obj.getAmountAC());
        outStream.putInteger(obj.getHuRematchDialogueTime());
        outStream.putInteger(obj.getRank());
        outStream.putBoolean(obj.getSttReplayAllowed());
        outStream.putInteger(obj.getWinAmount());
    }
    readPrevPlayingGTs(inStream: TSDataInputStream, obj: PrevPlayingGTs): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGtList(inStream.getObjectArray());
        obj.setIsFullList(inStream.getBoolean());
    }

    writePrevPlayingGTs(outStream: TSDataOutputStream, obj: PrevPlayingGTs): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getGtList());
        outStream.putBoolean(obj.getIsFullList());
    }
    readChangeInLevel(inStream: TSDataInputStream, obj: ChangeInLevel): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLevel(inStream.getInteger());
    }

    writeChangeInLevel(outStream: TSDataOutputStream, obj: ChangeInLevel): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLevel());
    }
    readTableTransfer(inStream: TSDataInputStream, obj: TableTransfer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTransferredServer(inStream.getObject());
    }

    writeTableTransfer(outStream: TSDataOutputStream, obj: TableTransfer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getTransferredServer());
    }
    readAutoOptionToOptionsMapping(inStream: TSDataInputStream, obj: AutoOptionToOptionsMapping): void {
        obj.setAutoOptionId(inStream.getInteger());
        obj.setCheckAmount(inStream.getBoolean());
        obj.setOptions(inStream.getStringArray());
    }

    writeAutoOptionToOptionsMapping(outStream: TSDataOutputStream, obj: AutoOptionToOptionsMapping): void {
        outStream.putInteger(obj.getAutoOptionId());
        outStream.putBoolean(obj.getCheckAmount());
        outStream.putStringArray(obj.getOptions());
    }
    readAutoOptionToOptionsMappings(inStream: TSDataInputStream, obj: AutoOptionToOptionsMappings): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMappings(inStream.getObjectArray());
    }

    writeAutoOptionToOptionsMappings(outStream: TSDataOutputStream, obj: AutoOptionToOptionsMappings): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getMappings());
    }
    readWinnerCards(inStream: TSDataInputStream, obj: WinnerCards): void {
        obj.setSeatNo(inStream.getByte());
        obj.setWinningCards(inStream.getStringArray());
    }

    writeWinnerCards(outStream: TSDataOutputStream, obj: WinnerCards): void {
        outStream.putByte(obj.getSeatNo());
        outStream.putStringArray(obj.getWinningCards());
    }
    readHighlightWinCards(inStream: TSDataInputStream, obj: HighlightWinCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setWinnersCards(inStream.getObjectArray());
    }

    writeHighlightWinCards(outStream: TSDataOutputStream, obj: HighlightWinCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getWinnersCards());
    }
    readValidFreeRolls(inStream: TSDataInputStream, obj: ValidFreeRolls): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCount(inStream.getInteger());
    }

    writeValidFreeRolls(outStream: TSDataOutputStream, obj: ValidFreeRolls): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getCount());
    }
    readBuddy(inStream: TSDataInputStream, obj: Buddy): void {
        obj.setBuddyId(inStream.getString());
        obj.setIsOnline(inStream.getBoolean());
        obj.setStatus(inStream.getInteger());
    }

    writeBuddy(outStream: TSDataOutputStream, obj: Buddy): void {
        outStream.putString(obj.getBuddyId());
        outStream.putBoolean(obj.getIsOnline());
        outStream.putInteger(obj.getStatus());
    }
    readBlockBuddy(inStream: TSDataInputStream, obj: BlockBuddy): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBlock(inStream.getBoolean());
        obj.setBuddy(inStream.getObject());
    }

    writeBlockBuddy(outStream: TSDataOutputStream, obj: BlockBuddy): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getBlock());
        outStream.putObject(obj.getBuddy());
    }
    readInviteBuddy(inStream: TSDataInputStream, obj: InviteBuddy): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuddyId(inStream.getString());
        obj.setMessage(inStream.getString());
        obj.setTableId(inStream.getInteger());
    }

    writeInviteBuddy(outStream: TSDataOutputStream, obj: InviteBuddy): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getBuddyId());
        outStream.putString(obj.getMessage());
        outStream.putInteger(obj.getTableId());
    }
    readRemoveBuddy(inStream: TSDataInputStream, obj: RemoveBuddy): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuddy(inStream.getObject());
    }

    writeRemoveBuddy(outStream: TSDataOutputStream, obj: RemoveBuddy): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getBuddy());
    }
    readAddBuddy(inStream: TSDataInputStream, obj: AddBuddy): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuddy(inStream.getObject());
    }

    writeAddBuddy(outStream: TSDataOutputStream, obj: AddBuddy): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getBuddy());
    }
    readBuddyList(inStream: TSDataInputStream, obj: BuddyList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuddies(inStream.getObjectArray());
    }

    writeBuddyList(outStream: TSDataOutputStream, obj: BuddyList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getBuddies());
    }
    readBuddyResponse(inStream: TSDataInputStream, obj: BuddyResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuddy(inStream.getObject());
        obj.setResponseId(inStream.getInteger());
    }

    writeBuddyResponse(outStream: TSDataOutputStream, obj: BuddyResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getBuddy());
        outStream.putInteger(obj.getResponseId());
    }
    readPlayerBalance(inStream: TSDataInputStream, obj: PlayerBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalance(inStream.getLong());
        obj.setSeatNo(inStream.getByte());
    }

    writePlayerBalance(outStream: TSDataOutputStream, obj: PlayerBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getBalance());
        outStream.putByte(obj.getSeatNo());
    }
    readUserBalance(inStream: TSDataInputStream, obj: UserBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalanceFor(inStream.getByte());
        obj.setPlayMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalanceGC(inStream.getLong());
        obj.setGDollarBalanceGC(inStream.getLong());
    }

    writeUserBalance(outStream: TSDataOutputStream, obj: UserBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getBalanceFor());
        outStream.putLong(obj.getPlayMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalanceGC());
        outStream.putLong(obj.getGDollarBalanceGC());
    }
    readMTCTLobbyToOpen(inStream: TSDataInputStream, obj: MTCTLobbyToOpen): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setCategory(inStream.getInteger());
        obj.setDomain(inStream.getInteger());
    }

    writeMTCTLobbyToOpen(outStream: TSDataOutputStream, obj: MTCTLobbyToOpen): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getCategory());
        outStream.putInteger(obj.getDomain());
    }
    readMTCTTableStatus(inStream: TSDataInputStream, obj: MTCTTableStatus): void {
        obj.setLargestStack(inStream.getLong());
        obj.setPlayerCount(inStream.getByte());
        obj.setServerPeerId(inStream.getInteger());
        obj.setSmallestStack(inStream.getLong());
        obj.setTableId(inStream.getInteger());
        obj.setTableNumber(inStream.getShort());
        obj.setTotalStack(inStream.getLong());
    }

    writeMTCTTableStatus(outStream: TSDataOutputStream, obj: MTCTTableStatus): void {
        outStream.putLong(obj.getLargestStack());
        outStream.putByte(obj.getPlayerCount());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putLong(obj.getSmallestStack());
        outStream.putInteger(obj.getTableId());
        outStream.putShort(obj.getTableNumber());
        outStream.putLong(obj.getTotalStack());
    }
    readCurrentPlayerMTCTRegistrations(inStream: TSDataInputStream, obj: CurrentPlayerMTCTRegistrations): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAlerts(inStream.getObjectArray());
        obj.setFavouriteMtctIdList(inStream.getStringArray());
        obj.setRegistredMtcts(inStream.getObjectArray());
        obj.setUnregisteredMtcts(inStream.getIntArray());
    }

    writeCurrentPlayerMTCTRegistrations(outStream: TSDataOutputStream, obj: CurrentPlayerMTCTRegistrations): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getAlerts());
        outStream.putStringArray(obj.getFavouriteMtctIdList());
        outStream.putObjectArray(obj.getRegistredMtcts());
        outStream.putIntArray(obj.getUnregisteredMtcts());
    }
    readMTCTRegister(inStream: TSDataInputStream, obj: MTCTRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setFxSnapshotId(inStream.getLong());
        obj.setIsRealMessage(inStream.getBoolean());
        obj.setMtctId(inStream.getInteger());
        obj.setUseFPP(inStream.getBoolean());
        obj.setUseFreeRolls(inStream.getBoolean());
        obj.setPassword(inStream.getString());
        obj.setFromReEntryDialog(inStream.getBoolean());
    }

    writeMTCTRegister(outStream: TSDataOutputStream, obj: MTCTRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBuyIn());
        outStream.putLong(obj.getConversationId());
        outStream.putLong(obj.getFxSnapshotId());
        outStream.putBoolean(obj.getIsRealMessage());
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getUseFPP());
        outStream.putBoolean(obj.getUseFreeRolls());
        outStream.putString(obj.getPassword());
        outStream.putBoolean(obj.getFromReEntryDialog());
    }
    readMTCTUnRegister(inStream: TSDataInputStream, obj: MTCTUnRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setFxSnapshotId(inStream.getLong());
        obj.setIsRealMessage(inStream.getBoolean());
        obj.setMtctId(inStream.getInteger());
    }

    writeMTCTUnRegister(outStream: TSDataOutputStream, obj: MTCTUnRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putLong(obj.getFxSnapshotId());
        outStream.putBoolean(obj.getIsRealMessage());
        outStream.putInteger(obj.getMtctId());
    }
    readSemiFinalDay(inStream: TSDataInputStream, obj: SemiFinalDay): void {
        obj.setDate(inStream.getString());
        obj.setDisable(inStream.getBoolean());
        obj.setExtraStartingChips(inStream.getInteger());
        obj.setSelected(inStream.getBoolean());
    }

    writeSemiFinalDay(outStream: TSDataOutputStream, obj: SemiFinalDay): void {
        outStream.putString(obj.getDate());
        outStream.putBoolean(obj.getDisable());
        outStream.putInteger(obj.getExtraStartingChips());
        outStream.putBoolean(obj.getSelected());
    }
    readSemiFinalGroup(inStream: TSDataInputStream, obj: SemiFinalGroup): void {
        obj.setGroupId(inStream.getInteger());
        obj.setGroupName(inStream.getString());
        obj.setSemiDays(inStream.getObjectArray());
        obj.setTotalChips(inStream.getInteger());
    }

    writeSemiFinalGroup(outStream: TSDataOutputStream, obj: SemiFinalGroup): void {
        outStream.putInteger(obj.getGroupId());
        outStream.putString(obj.getGroupName());
        outStream.putObjectArray(obj.getSemiDays());
        outStream.putInteger(obj.getTotalChips());
    }
    readSemiFinalCalendar(inStream: TSDataInputStream, obj: SemiFinalCalendar): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDistribMultiplier(inStream.getInteger());
        obj.setMaxChipsPerDay(inStream.getInteger());
        obj.setSemiFinalGroups(inStream.getObjectArray());
        obj.setTotalEntries(inStream.getInteger());
    }

    writeSemiFinalCalendar(outStream: TSDataOutputStream, obj: SemiFinalCalendar): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getDistribMultiplier());
        outStream.putInteger(obj.getMaxChipsPerDay());
        outStream.putObjectArray(obj.getSemiFinalGroups());
        outStream.putInteger(obj.getTotalEntries());
    }
    readCanPlayerRegister(inStream: TSDataInputStream, obj: CanPlayerRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
    }

    writeCanPlayerRegister(outStream: TSDataOutputStream, obj: CanPlayerRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putLong(obj.getConversationId());
    }
    readTableOccupancy(inStream: TSDataInputStream, obj: TableOccupancy): void {
        obj.setAvgPot(inStream.getLong());
        obj.setPlayerCount(inStream.getByte());
        obj.setServerPeerId(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setTourneyGameStatus(inStream.getByte());
        obj.setWaitingCount(inStream.getInteger());
    }

    writeTableOccupancy(outStream: TSDataOutputStream, obj: TableOccupancy): void {
        outStream.putLong(obj.getAvgPot());
        outStream.putByte(obj.getPlayerCount());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putInteger(obj.getTableId());
        outStream.putByte(obj.getTourneyGameStatus());
        outStream.putInteger(obj.getWaitingCount());
    }
    readTableOccupancyList(inStream: TSDataInputStream, obj: TableOccupancyList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsSnapShot(inStream.getBoolean());
        obj.setLobbyUpdateId(inStream.getInteger());
        obj.setTabNumber(inStream.getInteger());
        obj.setTablesList(inStream.getObjectArray());
        obj.setTrafficMessage(inStream.getStringEx());
    }

    writeTableOccupancyList(outStream: TSDataOutputStream, obj: TableOccupancyList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsSnapShot());
        outStream.putInteger(obj.getLobbyUpdateId());
        outStream.putInteger(obj.getTabNumber());
        outStream.putObjectArray(obj.getTablesList());
        outStream.putStringEx(obj.getTrafficMessage());
    }
    readSendMTCTParticipantNames(inStream: TSDataInputStream, obj: SendMTCTParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setRangeEnd(inStream.getInteger());
        obj.setRangeStart(inStream.getInteger());
    }

    writeSendMTCTParticipantNames(outStream: TSDataOutputStream, obj: SendMTCTParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getRangeEnd());
        outStream.putInteger(obj.getRangeStart());
    }
    readMTCTParticipantNames(inStream: TSDataInputStream, obj: MTCTParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipants(inStream.getInt2StringMap());
        obj.setRangeStart(inStream.getInteger());
    }

    writeMTCTParticipantNames(outStream: TSDataOutputStream, obj: MTCTParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInt2StringMap(obj.getParticipants());
        outStream.putInteger(obj.getRangeStart());
    }
    readWaitingPlayersForTable(inStream: TSDataInputStream, obj: WaitingPlayersForTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayers(inStream.getStringArray());
        obj.setPosition(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setWaitListCount(inStream.getInteger());
    }

    writeWaitingPlayersForTable(outStream: TSDataOutputStream, obj: WaitingPlayersForTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getPlayers());
        outStream.putInteger(obj.getPosition());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getWaitListCount());
    }
    readPlayerSearchResult(inStream: TSDataInputStream, obj: PlayerSearchResult): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFoundTables(inStream.getObjectArray());
        obj.setPlayerId(inStream.getString());
    }

    writePlayerSearchResult(outStream: TSDataOutputStream, obj: PlayerSearchResult): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getFoundTables());
        outStream.putString(obj.getPlayerId());
    }
    readPlayerSearchResults(inStream: TSDataInputStream, obj: PlayerSearchResults): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResults(inStream.getObjectArray());
    }

    writePlayerSearchResults(outStream: TSDataOutputStream, obj: PlayerSearchResults): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getResults());
    }
    readShowESC(inStream: TSDataInputStream, obj: ShowESC): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setShow(inStream.getBoolean());
    }

    writeShowESC(outStream: TSDataOutputStream, obj: ShowESC): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getShow());
    }
    readPPFont(inStream: TSDataInputStream, obj: PPFont): void {
        obj.setBold(inStream.getBoolean());
        obj.setColor(inStream.getString());
        obj.setItalic(inStream.getBoolean());
        obj.setName(inStream.getString());
        obj.setSize(inStream.getInteger());
        obj.setUnderline(inStream.getBoolean());
    }

    writePPFont(outStream: TSDataOutputStream, obj: PPFont): void {
        outStream.putBoolean(obj.getBold());
        outStream.putString(obj.getColor());
        outStream.putBoolean(obj.getItalic());
        outStream.putString(obj.getName());
        outStream.putInteger(obj.getSize());
        outStream.putBoolean(obj.getUnderline());
    }
    readJackpotAmount(inStream: TSDataInputStream, obj: JackpotAmount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFamilyId(inStream.getByte());
        obj.setJackpotAmount(inStream.getLong());
        obj.setJackpotId(inStream.getInteger());
    }

    writeJackpotAmount(outStream: TSDataOutputStream, obj: JackpotAmount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getFamilyId());
        outStream.putLong(obj.getJackpotAmount());
        outStream.putInteger(obj.getJackpotId());
    }
    readJackpotAmounts(inStream: TSDataInputStream, obj: JackpotAmounts): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setJackpotAmounts(inStream.getObjectArray());
    }

    writeJackpotAmounts(outStream: TSDataOutputStream, obj: JackpotAmounts): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getJackpotAmounts());
    }
    readJackpotSnapshots(inStream: TSDataInputStream, obj: JackpotSnapshots): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setJackpotSnapshots(inStream.getString2StringMap());
    }

    writeJackpotSnapshots(outStream: TSDataOutputStream, obj: JackpotSnapshots): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getJackpotSnapshots());
    }
    readOptionSetAck(inStream: TSDataInputStream, obj: OptionSetAck): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClientServerClock(inStream.getLong());
        obj.setOptionSetId(inStream.getLong());
    }

    writeOptionSetAck(outStream: TSDataOutputStream, obj: OptionSetAck): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getClientServerClock());
        outStream.putLong(obj.getOptionSetId());
    }
    readSelectOptionAck(inStream: TSDataInputStream, obj: SelectOptionAck): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setOptionSetId(inStream.getLong());
    }

    writeSelectOptionAck(outStream: TSDataOutputStream, obj: SelectOptionAck): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getOptionSetId());
    }
    readEnableTimeBank(inStream: TSDataInputStream, obj: EnableTimeBank): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setOptionSetId(inStream.getLong());
        obj.setTimeBankAutoEnabled(inStream.getBoolean());
        obj.setTimeBankTimeoutAt(inStream.getLong());
    }

    writeEnableTimeBank(outStream: TSDataOutputStream, obj: EnableTimeBank): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getOptionSetId());
        outStream.putBoolean(obj.getTimeBankAutoEnabled());
        outStream.putLong(obj.getTimeBankTimeoutAt());
    }
    readBlockSeat(inStream: TSDataInputStream, obj: BlockSeat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
    }

    writeBlockSeat(outStream: TSDataOutputStream, obj: BlockSeat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSeatNo());
        outStream.putLong(obj.getConversationId());
    }
    readMTCTRebuyAddonInfo(inStream: TSDataInputStream, obj: MTCTRebuyAddonInfo): void {
        obj.setAddonChips(inStream.getInteger());
        obj.setAddonBuyIn(inStream.getInteger());
        obj.setAddonCountTillNow(inStream.getInteger());
        obj.setAddonSupported(inStream.getBoolean());
        obj.setMaxAddonCount(inStream.getInteger());
        obj.setMaxRebuyCount(inStream.getInteger());
        obj.setRebuyAddonStatus(inStream.getByte());
        obj.setRebuyChips(inStream.getInteger());
        obj.setRebuyBuyIn(inStream.getInteger());
        obj.setRebuyCountTillNow(inStream.getInteger());
        obj.setRebuyEndBreakNumber(inStream.getInteger());
        obj.setRebuyFee(inStream.getInteger());
        obj.setAddonFee(inStream.getInteger());
    }

    writeMTCTRebuyAddonInfo(outStream: TSDataOutputStream, obj: MTCTRebuyAddonInfo): void {
        outStream.putInteger(obj.getAddonChips());
        outStream.putInteger(obj.getAddonBuyIn());
        outStream.putInteger(obj.getAddonCountTillNow());
        outStream.putBoolean(obj.getAddonSupported());
        outStream.putInteger(obj.getMaxAddonCount());
        outStream.putInteger(obj.getMaxRebuyCount());
        outStream.putByte(obj.getRebuyAddonStatus());
        outStream.putInteger(obj.getRebuyChips());
        outStream.putInteger(obj.getRebuyBuyIn());
        outStream.putInteger(obj.getRebuyCountTillNow());
        outStream.putInteger(obj.getRebuyEndBreakNumber());
        outStream.putInteger(obj.getRebuyFee());
        outStream.putInteger(obj.getAddonFee());
    }
    readMTCTRebuyAddonStatus(inStream: TSDataInputStream, obj: MTCTRebuyAddonStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getByte());
        obj.setAddonSupported(inStream.getBoolean());
        obj.setAutoRebuyAddonEnabled(inStream.getByte());
    }

    writeMTCTRebuyAddonStatus(outStream: TSDataOutputStream, obj: MTCTRebuyAddonStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getStatus());
        outStream.putBoolean(obj.getAddonSupported());
        outStream.putByte(obj.getAutoRebuyAddonEnabled());
    }
    readMTCTRequestRebuyAddonDialogInfo(inStream: TSDataInputStream, obj: MTCTRequestRebuyAddonDialogInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAddonDialog(inStream.getBoolean());
    }

    writeMTCTRequestRebuyAddonDialogInfo(outStream: TSDataOutputStream, obj: MTCTRequestRebuyAddonDialogInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getAddonDialog());
    }
    readMTCTDoRebuyAddon(inStream: TSDataInputStream, obj: MTCTDoRebuyAddon): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAddon(inStream.getBoolean());
        obj.setConversationId(inStream.getLong());
        obj.setNumberOfAddons(inStream.getInteger());
    }

    writeMTCTDoRebuyAddon(outStream: TSDataOutputStream, obj: MTCTDoRebuyAddon): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getAddon());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getNumberOfAddons());
    }
    readShootoutInfo(inStream: TSDataInputStream, obj: ShootoutInfo): void {
        obj.setPlayerCount1(inStream.getInteger());
        obj.setPlayerCount2(inStream.getInteger());
        obj.setPlayerCount3(inStream.getInteger());
        obj.setPlayerCount4(inStream.getInteger());
        obj.setPrize11(inStream.getInteger());
        obj.setPrize12(inStream.getInteger());
        obj.setPrize13(inStream.getInteger());
        obj.setPrize21(inStream.getInteger());
        obj.setPrize22(inStream.getInteger());
        obj.setPrize23(inStream.getInteger());
        obj.setPrize31(inStream.getInteger());
        obj.setPrize32(inStream.getInteger());
        obj.setPrize33(inStream.getInteger());
        obj.setPrize41(inStream.getInteger());
        obj.setPrize42(inStream.getInteger());
        obj.setPrize43(inStream.getInteger());
        obj.setTotalRoundCount(inStream.getInteger());
    }

    writeShootoutInfo(outStream: TSDataOutputStream, obj: ShootoutInfo): void {
        outStream.putInteger(obj.getPlayerCount1());
        outStream.putInteger(obj.getPlayerCount2());
        outStream.putInteger(obj.getPlayerCount3());
        outStream.putInteger(obj.getPlayerCount4());
        outStream.putInteger(obj.getPrize11());
        outStream.putInteger(obj.getPrize12());
        outStream.putInteger(obj.getPrize13());
        outStream.putInteger(obj.getPrize21());
        outStream.putInteger(obj.getPrize22());
        outStream.putInteger(obj.getPrize23());
        outStream.putInteger(obj.getPrize31());
        outStream.putInteger(obj.getPrize32());
        outStream.putInteger(obj.getPrize33());
        outStream.putInteger(obj.getPrize41());
        outStream.putInteger(obj.getPrize42());
        outStream.putInteger(obj.getPrize43());
        outStream.putInteger(obj.getTotalRoundCount());
    }
    readJackpotHitTime(inStream: TSDataInputStream, obj: JackpotHitTime): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setJpHitTime(inStream.getLong());
    }

    writeJackpotHitTime(outStream: TSDataOutputStream, obj: JackpotHitTime): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getJpHitTime());
    }
    readDealMakingChatRequest(inStream: TSDataInputStream, obj: DealMakingChatRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessageBody(inStream.getString());
        obj.setMsgType(inStream.getByte());
    }

    writeDealMakingChatRequest(outStream: TSDataOutputStream, obj: DealMakingChatRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMessageBody());
        outStream.putByte(obj.getMsgType());
    }
    readDealMakingSelectedPayout(inStream: TSDataInputStream, obj: DealMakingSelectedPayout): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPayoutType(inStream.getByte());
        obj.setPayouts(inStream.getObjectArray());
    }

    writeDealMakingSelectedPayout(outStream: TSDataOutputStream, obj: DealMakingSelectedPayout): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getPayoutType());
        outStream.putObjectArray(obj.getPayouts());
    }
    readDealMakingAcceptPlayerCount(inStream: TSDataInputStream, obj: DealMakingAcceptPlayerCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAcceptedPlayerCount(inStream.getShort());
    }

    writeDealMakingAcceptPlayerCount(outStream: TSDataOutputStream, obj: DealMakingAcceptPlayerCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putShort(obj.getAcceptedPlayerCount());
    }
    readDealMakingDetails(inStream: TSDataInputStream, obj: DealMakingDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChipLeaderSeatNo(inStream.getByte());
        obj.setDealMakingTime(inStream.getLong());
        obj.setPayoutData(inStream.getObjectArray());
        obj.setPlayerData(inStream.getObjectArray());
        obj.setPlayerType(inStream.getByte());
        obj.setRemainingprizePool(inStream.getLong());
        obj.setRingFencedAmount(inStream.getLong());
    }

    writeDealMakingDetails(outStream: TSDataOutputStream, obj: DealMakingDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getChipLeaderSeatNo());
        outStream.putLong(obj.getDealMakingTime());
        outStream.putObjectArray(obj.getPayoutData());
        outStream.putObjectArray(obj.getPlayerData());
        outStream.putByte(obj.getPlayerType());
        outStream.putLong(obj.getRemainingprizePool());
        outStream.putLong(obj.getRingFencedAmount());
    }
    readPayout(inStream: TSDataInputStream, obj: Payout): void {
        obj.setPayoutInCents(inStream.getLong());
        obj.setPayoutInPercentage(inStream.getShort());
        obj.setSeatNo(inStream.getShort());
    }

    writePayout(outStream: TSDataOutputStream, obj: Payout): void {
        outStream.putLong(obj.getPayoutInCents());
        outStream.putShort(obj.getPayoutInPercentage());
        outStream.putShort(obj.getSeatNo());
    }
    readPlayersChipCount(inStream: TSDataInputStream, obj: PlayersChipCount): void {
        obj.setChipCount(inStream.getLong());
        obj.setSeatNo(inStream.getShort());
    }

    writePlayersChipCount(outStream: TSDataOutputStream, obj: PlayersChipCount): void {
        outStream.putLong(obj.getChipCount());
        outStream.putShort(obj.getSeatNo());
    }
    readDealMakingConfirmData(inStream: TSDataInputStream, obj: DealMakingConfirmData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConfirmedPlayers(inStream.getByte());
        obj.setCustomPayout(inStream.getObjectArray());
        obj.setDealMakingDetails(inStream.getObject());
        obj.setHasResponded(inStream.getBoolean());
        obj.setPayoutType(inStream.getByte());
        obj.setPostDealPayout(inStream.getObjectArray());
    }

    writeDealMakingConfirmData(outStream: TSDataOutputStream, obj: DealMakingConfirmData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getConfirmedPlayers());
        outStream.putObjectArray(obj.getCustomPayout());
        outStream.putObject(obj.getDealMakingDetails());
        outStream.putBoolean(obj.getHasResponded());
        outStream.putByte(obj.getPayoutType());
        outStream.putObjectArray(obj.getPostDealPayout());
    }
    readDisconnectedPlayersList(inStream: TSDataInputStream, obj: DisconnectedPlayersList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDisConnectedUsersSeatNos(inStream.getBytes());
    }

    writeDisconnectedPlayersList(outStream: TSDataOutputStream, obj: DisconnectedPlayersList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getDisConnectedUsersSeatNos());
    }
    readDealMakingSubmitDeal(inStream: TSDataInputStream, obj: DealMakingSubmitDeal): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPostDealPayouts(inStream.getObjectArray());
        obj.setSelectedPayout(inStream.getObject());
    }

    writeDealMakingSubmitDeal(outStream: TSDataOutputStream, obj: DealMakingSubmitDeal): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPostDealPayouts());
        outStream.putObject(obj.getSelectedPayout());
    }
    readDealMakingTrnyRank(inStream: TSDataInputStream, obj: DealMakingTrnyRank): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTrnyRank(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setEliminatedPosition(inStream.getInteger());
    }

    writeDealMakingTrnyRank(outStream: TSDataOutputStream, obj: DealMakingTrnyRank): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTrnyRank());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getEliminatedPosition());
    }
    readLobbyFont(inStream: TSDataInputStream, obj: LobbyFont): void {
        obj.setBlue(inStream.getByte());
        obj.setBold(inStream.getBoolean());
        obj.setColorID(inStream.getByte());
        obj.setGreen(inStream.getByte());
        obj.setItalic(inStream.getBoolean());
        obj.setName(inStream.getString());
        obj.setRed(inStream.getByte());
        obj.setSize(inStream.getByte());
        obj.setUnderline(inStream.getBoolean());
    }

    writeLobbyFont(outStream: TSDataOutputStream, obj: LobbyFont): void {
        outStream.putByte(obj.getBlue());
        outStream.putBoolean(obj.getBold());
        outStream.putByte(obj.getColorID());
        outStream.putByte(obj.getGreen());
        outStream.putBoolean(obj.getItalic());
        outStream.putString(obj.getName());
        outStream.putByte(obj.getRed());
        outStream.putByte(obj.getSize());
        outStream.putBoolean(obj.getUnderline());
    }
    readTableColorDetailsList(inStream: TSDataInputStream, obj: TableColorDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableColorDetailsList(inStream.getObjectArray());
        obj.setDomainID(inStream.getInteger());
    }

    writeTableColorDetailsList(outStream: TSDataOutputStream, obj: TableColorDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getTableColorDetailsList());
        outStream.putInteger(obj.getDomainID());
    }
    readDealPlayerData(inStream: TSDataInputStream, obj: DealPlayerData): void {
        obj.setChipCount(inStream.getLong());
        obj.setGamePayoutInPercent(inStream.getShort());
        obj.setGamePayoutinCents(inStream.getLong());
        obj.setSeatNo(inStream.getShort());
    }

    writeDealPlayerData(outStream: TSDataOutputStream, obj: DealPlayerData): void {
        outStream.putLong(obj.getChipCount());
        outStream.putShort(obj.getGamePayoutInPercent());
        outStream.putLong(obj.getGamePayoutinCents());
        outStream.putShort(obj.getSeatNo());
    }
    readDealMakingPostDealPayout(inStream: TSDataInputStream, obj: DealMakingPostDealPayout): void {
        obj.setPayoutInCents(inStream.getLong());
        obj.setPayoutinPercentage(inStream.getShort());
        obj.setPlace(inStream.getByte());
    }

    writeDealMakingPostDealPayout(outStream: TSDataOutputStream, obj: DealMakingPostDealPayout): void {
        outStream.putLong(obj.getPayoutInCents());
        outStream.putShort(obj.getPayoutinPercentage());
        outStream.putByte(obj.getPlace());
    }
    readDealConfirmedPlayerCount(inStream: TSDataInputStream, obj: DealConfirmedPlayerCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setNoOfConfirmedPlayers(inStream.getByte());
    }

    writeDealConfirmedPlayerCount(outStream: TSDataOutputStream, obj: DealConfirmedPlayerCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getNoOfConfirmedPlayers());
    }
    readLobbyTableDataChange(inStream: TSDataInputStream, obj: LobbyTableDataChange): void {
        obj.setAvgFlopSeen(inStream.getByte());
        obj.setAvgPot(inStream.getLong());
        obj.setHandsPerHour(inStream.getByte());
        obj.setPlayerCount(inStream.getByte());
        obj.setReservedCnt(inStream.getByte());
        obj.setTableId(inStream.getInteger());
        obj.setTourneyGameStatus(inStream.getByte());
        obj.setWaitingCount(inStream.getInteger());
    }

    writeLobbyTableDataChange(outStream: TSDataOutputStream, obj: LobbyTableDataChange): void {
        outStream.putByte(obj.getAvgFlopSeen());
        outStream.putLong(obj.getAvgPot());
        outStream.putByte(obj.getHandsPerHour());
        outStream.putByte(obj.getPlayerCount());
        outStream.putByte(obj.getReservedCnt());
        outStream.putInteger(obj.getTableId());
        outStream.putByte(obj.getTourneyGameStatus());
        outStream.putInteger(obj.getWaitingCount());
    }
    readLobbyTableOccupancyChange(inStream: TSDataInputStream, obj: LobbyTableOccupancyChange): void {
        obj.setPlayerCount(inStream.getByte());
        obj.setReservedCount(inStream.getByte());
        obj.setTableId(inStream.getInteger());
    }

    writeLobbyTableOccupancyChange(outStream: TSDataOutputStream, obj: LobbyTableOccupancyChange): void {
        outStream.putByte(obj.getPlayerCount());
        outStream.putByte(obj.getReservedCount());
        outStream.putInteger(obj.getTableId());
    }
    readMTCTTourneyStatus(inStream: TSDataInputStream, obj: MTCTTourneyStatus): void {
        obj.setCurrentBlindAmt(inStream.getStringEx());
        obj.setCurrentShootoutRound(inStream.getByte());
        obj.setDealMakingOn(inStream.getBoolean());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setMtctId(inStream.getInteger());
        obj.setNextBlindAmt(inStream.getStringEx());
        obj.setNextBreakTime(inStream.getLong());
        obj.setNextLevelValue(inStream.getLong());
        obj.setNextRoundBeginsAt(inStream.getLong());
        obj.setNextShootoutBreakDuration(inStream.getInteger());
        obj.setNoOfDealMakings(inStream.getShort());
        obj.setNoOfParticipants(inStream.getInteger());
        obj.setNoOfPlayers(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setTourneyCurrentLevel(inStream.getInteger());
        obj.setUnregClosed(inStream.getBoolean());
        obj.setWatchersCount(inStream.getInteger());
    }

    writeMTCTTourneyStatus(outStream: TSDataOutputStream, obj: MTCTTourneyStatus): void {
        outStream.putStringEx(obj.getCurrentBlindAmt());
        outStream.putByte(obj.getCurrentShootoutRound());
        outStream.putBoolean(obj.getDealMakingOn());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putInteger(obj.getMtctId());
        outStream.putStringEx(obj.getNextBlindAmt());
        outStream.putLong(obj.getNextBreakTime());
        outStream.putLong(obj.getNextLevelValue());
        outStream.putLong(obj.getNextRoundBeginsAt());
        outStream.putInteger(obj.getNextShootoutBreakDuration());
        outStream.putShort(obj.getNoOfDealMakings());
        outStream.putInteger(obj.getNoOfParticipants());
        outStream.putInteger(obj.getNoOfPlayers());
        outStream.putInteger(obj.getStatus());
        outStream.putInteger(obj.getTourneyCurrentLevel());
        outStream.putBoolean(obj.getUnregClosed());
        outStream.putInteger(obj.getWatchersCount());
    }
    readTourneyPrizeInfo(inStream: TSDataInputStream, obj: TourneyPrizeInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIndividualPrizes(inStream.getStringExArray());
        obj.setTotalPrizeInfo(inStream.getStringEx());
    }

    writeTourneyPrizeInfo(outStream: TSDataOutputStream, obj: TourneyPrizeInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringExArray(obj.getIndividualPrizes());
        outStream.putStringEx(obj.getTotalPrizeInfo());
    }
    readDealMakingPayoutData(inStream: TSDataInputStream, obj: DealMakingPayoutData): void {
        obj.setPayoutId(inStream.getByte());
        obj.setPayoutTitle(inStream.getStringEx());
        obj.setPayouts(inStream.getObjectArray());
    }

    writeDealMakingPayoutData(outStream: TSDataOutputStream, obj: DealMakingPayoutData): void {
        outStream.putByte(obj.getPayoutId());
        outStream.putStringEx(obj.getPayoutTitle());
        outStream.putObjectArray(obj.getPayouts());
    }
    readMTCTPrizeInfo(inStream: TSDataInputStream, obj: MTCTPrizeInfo): void {
        obj.setDealDistributed(inStream.getLong());
        obj.setIgnorePrizesUpdate(inStream.getBoolean());
        obj.setMessage(inStream.getStringEx());
        obj.setPrizeMessages(inStream.getObjectArray());
        obj.setRemainingPrizePool(inStream.getLong());
        obj.setTotalDistributed(inStream.getLong());
        obj.setTotalPrizePool(inStream.getLong());
    }

    writeMTCTPrizeInfo(outStream: TSDataOutputStream, obj: MTCTPrizeInfo): void {
        outStream.putLong(obj.getDealDistributed());
        outStream.putBoolean(obj.getIgnorePrizesUpdate());
        outStream.putStringEx(obj.getMessage());
        outStream.putObjectArray(obj.getPrizeMessages());
        outStream.putLong(obj.getRemainingPrizePool());
        outStream.putLong(obj.getTotalDistributed());
        outStream.putLong(obj.getTotalPrizePool());
    }
    readMTCTTourneyInfo(inStream: TSDataInputStream, obj: MTCTTourneyInfo): void {
        obj.setAllowLateRegistration(inStream.getBoolean());
        obj.setAllowLateRegistrationTillLevel(inStream.getInteger());
        obj.setAnnounceTime(inStream.getTimestamp());
        obj.setBountyAmount(inStream.getInteger());
        obj.setBreakDuration(inStream.getInteger());
        obj.setBreakInterval(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setColorCode(inStream.getByte());
        obj.setDefaultChips(inStream.getLong());
        obj.setEntryFee(inStream.getInteger());
        obj.setFamilyId(inStream.getByte());
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setGameCurrency(inStream.getString());
        obj.setGamePlayType(inStream.getByte());
        obj.setGameType(inStream.getInteger());
        obj.setInfo(inStream.getStringEx());
        obj.setIsTrnyFeeTypePercent(inStream.getBoolean());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxParticipants(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setMtsgId(inStream.getInteger());
        obj.setMttType(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setPartyPoints(inStream.getInteger());
        obj.setQuinteChampionshipFee(inStream.getInteger());
        obj.setRegistrationEndTime(inStream.getTimestamp());
        obj.setRegistrationStartTime(inStream.getTimestamp());
        obj.setStatus(inStream.getInteger());
        obj.setSynchronisedBreakTrny(inStream.getBoolean());
        obj.setTabIds(inStream.getIntArray());
        obj.setTableInfoId(inStream.getInteger());
        obj.setTournamentDescription(inStream.getStringEx());
        obj.setTourneyEndTime(inStream.getTimestamp());
        obj.setTourneyStartTime(inStream.getTimestamp());
        obj.setTrnyFeePercent(inStream.getShort());
        obj.setTrnyMinPlrs(inStream.getInteger());
        obj.setTrnyTypeId(inStream.getInteger());
        obj.setUnRegOffset(inStream.getLong());
    }

    writeMTCTTourneyInfo(outStream: TSDataOutputStream, obj: MTCTTourneyInfo): void {
        outStream.putBoolean(obj.getAllowLateRegistration());
        outStream.putInteger(obj.getAllowLateRegistrationTillLevel());
        outStream.putTimestamp(obj.getAnnounceTime());
        outStream.putInteger(obj.getBountyAmount());
        outStream.putInteger(obj.getBreakDuration());
        outStream.putInteger(obj.getBreakInterval());
        outStream.putInteger(obj.getBuyIn());
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putByte(obj.getColorCode());
        outStream.putLong(obj.getDefaultChips());
        outStream.putInteger(obj.getEntryFee());
        outStream.putByte(obj.getFamilyId());
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putString(obj.getGameCurrency());
        outStream.putByte(obj.getGamePlayType());
        outStream.putInteger(obj.getGameType());
        outStream.putStringEx(obj.getInfo());
        outStream.putBoolean(obj.getIsTrnyFeeTypePercent());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxParticipants());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getMtsgId());
        outStream.putInteger(obj.getMttType());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putInteger(obj.getQuinteChampionshipFee());
        outStream.putTimestamp(obj.getRegistrationEndTime());
        outStream.putTimestamp(obj.getRegistrationStartTime());
        outStream.putInteger(obj.getStatus());
        outStream.putBoolean(obj.getSynchronisedBreakTrny());
        outStream.putIntArray(obj.getTabIds());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putStringEx(obj.getTournamentDescription());
        outStream.putTimestamp(obj.getTourneyEndTime());
        outStream.putTimestamp(obj.getTourneyStartTime());
        outStream.putShort(obj.getTrnyFeePercent());
        outStream.putInteger(obj.getTrnyMinPlrs());
        outStream.putInteger(obj.getTrnyTypeId());
        outStream.putLong(obj.getUnRegOffset());
    }
    readMTCTDetails(inStream: TSDataInputStream, obj: MTCTDetails): void {
        obj.setCurrentHeadsUpRound(inStream.getByte());
        obj.setCurrentSOSRound(inStream.getByte());
        obj.setLastParticipantNo(inStream.getInteger());
        obj.setLastUpdateTimeStamp(inStream.getLong());
        obj.setMtctEvents(inStream.getIntArray());
        obj.setMtctId(inStream.getInteger());
        obj.setMtctTourneyInfo(inStream.getObject());
        obj.setMtctTourneyStatus(inStream.getObject());
        obj.setParticipantStatus(inStream.getInt2IntMap());
        obj.setPrizeInfo(inStream.getObject());
        obj.setRebuyAddonInfo(inStream.getObject());
        obj.setRemoveScheduleOnDeltaUpdate(inStream.getBoolean());
        obj.setShootoutInfo(inStream.getObject());
    }

    writeMTCTDetails(outStream: TSDataOutputStream, obj: MTCTDetails): void {
        outStream.putByte(obj.getCurrentHeadsUpRound());
        outStream.putByte(obj.getCurrentSOSRound());
        outStream.putInteger(obj.getLastParticipantNo());
        outStream.putLong(obj.getLastUpdateTimeStamp());
        outStream.putIntArray(obj.getMtctEvents());
        outStream.putInteger(obj.getMtctId());
        outStream.putObject(obj.getMtctTourneyInfo());
        outStream.putObject(obj.getMtctTourneyStatus());
        outStream.putInt2IntMap(obj.getParticipantStatus());
        outStream.putObject(obj.getPrizeInfo());
        outStream.putObject(obj.getRebuyAddonInfo());
        outStream.putBoolean(obj.getRemoveScheduleOnDeltaUpdate());
        outStream.putObject(obj.getShootoutInfo());
    }
    readBlockSeatResponse(inStream: TSDataInputStream, obj: BlockSeatResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBlockSeatStatus(inStream.getInteger());
        obj.setBlockedSeatTimeOutAt(inStream.getLong());
        obj.setFXConversionFactor(inStream.getObject());
        obj.setFppAvailable(inStream.getInteger());
        obj.setFppBuyIn(inStream.getInteger());
        obj.setLevelChangeInfo(inStream.getStringEx());
        obj.setMessageToClient(inStream.getStringEx());
        obj.setNewMcPlayer(inStream.getBoolean());
        obj.setSeatNumber(inStream.getByte());
        obj.setUserBalance(inStream.getLong());
        obj.setUserBalanceGC(inStream.getLong());
        obj.setValidFreeRolls(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setResponseId(inStream.getInteger());
        obj.setGDollarBalanceGC(inStream.getLong());
    }

    writeBlockSeatResponse(outStream: TSDataOutputStream, obj: BlockSeatResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBlockSeatStatus());
        outStream.putLong(obj.getBlockedSeatTimeOutAt());
        outStream.putObject(obj.getFXConversionFactor());
        outStream.putInteger(obj.getFppAvailable());
        outStream.putInteger(obj.getFppBuyIn());
        outStream.putStringEx(obj.getLevelChangeInfo());
        outStream.putStringEx(obj.getMessageToClient());
        outStream.putBoolean(obj.getNewMcPlayer());
        outStream.putByte(obj.getSeatNumber());
        outStream.putLong(obj.getUserBalance());
        outStream.putLong(obj.getUserBalanceGC());
        outStream.putInteger(obj.getValidFreeRolls());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getResponseId());
        outStream.putLong(obj.getGDollarBalanceGC());
    }
    readBuyinRefundStatus(inStream: TSDataInputStream, obj: BuyinRefundStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameTableStatus(inStream.getObject());
        obj.setMsg(inStream.getStringEx());
    }

    writeBuyinRefundStatus(outStream: TSDataOutputStream, obj: BuyinRefundStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getGameTableStatus());
        outStream.putStringEx(obj.getMsg());
    }
    readCanPlayerRegisterResponse(inStream: TSDataInputStream, obj: CanPlayerRegisterResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalance(inStream.getLong());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setFppBalance(inStream.getLong());
        obj.setFppBuyIn(inStream.getLong());
        obj.setFreeRollBalance(inStream.getLong());
        obj.setFxRateDetails(inStream.getObject());
        obj.setFxSnapshotId(inStream.getLong());
        obj.setIsFreerollOnlyTourny(inStream.getBoolean());
        obj.setIsRealMoney(inStream.getBoolean());
        obj.setMtctId(inStream.getInteger());
        obj.setIsPasswordTrny(inStream.getBoolean());
        obj.setBuyInType(inStream.getInteger());
        obj.setNoOfEntriesUsed(inStream.getByte());
        obj.setRebuyBuyIn(inStream.getInteger());
        obj.setRebuyFee(inStream.getInteger());
        obj.setAddonBuyIn(inStream.getInteger());
        obj.setAddonFee(inStream.getInteger());
        obj.setPrizeType(inStream.getInteger());
        obj.setAllowLateRegistrationTillLevel(inStream.getInteger());
        obj.setConsiderLRCloseConfig(inStream.getBoolean());
        obj.setLrCloseTime(inStream.getLong());
        obj.setTdBalance(inStream.getLong());
    }

    writeCanPlayerRegisterResponse(outStream: TSDataOutputStream, obj: CanPlayerRegisterResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getBalance());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putLong(obj.getFppBalance());
        outStream.putLong(obj.getFppBuyIn());
        outStream.putLong(obj.getFreeRollBalance());
        outStream.putObject(obj.getFxRateDetails());
        outStream.putLong(obj.getFxSnapshotId());
        outStream.putBoolean(obj.getIsFreerollOnlyTourny());
        outStream.putBoolean(obj.getIsRealMoney());
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getIsPasswordTrny());
        outStream.putInteger(obj.getBuyInType());
        outStream.putByte(obj.getNoOfEntriesUsed());
        outStream.putInteger(obj.getRebuyBuyIn());
        outStream.putInteger(obj.getRebuyFee());
        outStream.putInteger(obj.getAddonBuyIn());
        outStream.putInteger(obj.getAddonFee());
        outStream.putInteger(obj.getPrizeType());
        outStream.putInteger(obj.getAllowLateRegistrationTillLevel());
        outStream.putBoolean(obj.getConsiderLRCloseConfig());
        outStream.putLong(obj.getLrCloseTime());
        outStream.putLong(obj.getTdBalance());
    }
    readCloseTable(inStream: TSDataInputStream, obj: CloseTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDelayInSecs(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
        obj.setTableId(inStream.getInteger());
        obj.setAthBlockTime(inStream.getLong());
        obj.setAthStrikePresent(inStream.getBoolean());
    }

    writeCloseTable(outStream: TSDataOutputStream, obj: CloseTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getDelayInSecs());
        outStream.putStringEx(obj.getMessage());
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getAthBlockTime());
        outStream.putBoolean(obj.getAthStrikePresent());
    }
    readCreatePvtTableResponse(inStream: TSDataInputStream, obj: CreatePvtTableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPrivateTableType(inStream.getObject());
        obj.setResponseId(inStream.getInteger());
        obj.setTableName(inStream.getStringEx());
        obj.setTimeOut(inStream.getInteger());
    }

    writeCreatePvtTableResponse(outStream: TSDataOutputStream, obj: CreatePvtTableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getPrivateTableType());
        outStream.putInteger(obj.getResponseId());
        outStream.putStringEx(obj.getTableName());
        outStream.putInteger(obj.getTimeOut());
    }
    readChat(inStream: TSDataInputStream, obj: Chat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessages(inStream.getStringExArray());
        obj.setSender(inStream.getString());
        obj.setType(inStream.getInteger());
    }

    writeChat(outStream: TSDataOutputStream, obj: Chat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringExArray(obj.getMessages());
        outStream.putString(obj.getSender());
        outStream.putInteger(obj.getType());
    }
    readHandStrength(inStream: TSDataInputStream, obj: HandStrength): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setHandDescription(inStream.getStringEx());
        obj.setIsHigh(inStream.getBoolean());
        obj.setBoardType(inStream.getInteger());
        obj.setCards(inStream.getString());
        obj.setRank(inStream.getInteger());
    }

    writeHandStrength(outStream: TSDataOutputStream, obj: HandStrength): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getHandDescription());
        outStream.putBoolean(obj.getIsHigh());
        outStream.putInteger(obj.getBoardType());
        outStream.putString(obj.getCards());
        outStream.putInteger(obj.getRank());
    }
    readJoinTableResponse(inStream: TSDataInputStream, obj: JoinTableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFXConversionFactor(inStream.getObject());
        obj.setMessageToShow(inStream.getStringEx());
        obj.setResponseId(inStream.getInteger());
        obj.setTableStatus(inStream.getObject());
    }

    writeJoinTableResponse(outStream: TSDataOutputStream, obj: JoinTableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getFXConversionFactor());
        outStream.putStringEx(obj.getMessageToShow());
        outStream.putInteger(obj.getResponseId());
        outStream.putObject(obj.getTableStatus());
    }
    readMessageOnTable(inStream: TSDataInputStream, obj: MessageOnTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFont(inStream.getObject());
        obj.setMsgText(inStream.getStringEx());
        obj.setStatus(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setUrl(inStream.getString());
        obj.setX_coord(inStream.getInteger());
        obj.setY_coord(inStream.getInteger());
    }

    writeMessageOnTable(outStream: TSDataOutputStream, obj: MessageOnTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getFont());
        outStream.putStringEx(obj.getMsgText());
        outStream.putInteger(obj.getStatus());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putString(obj.getUrl());
        outStream.putInteger(obj.getX_coord());
        outStream.putInteger(obj.getY_coord());
    }
    readMoveToTable(inStream: TSDataInputStream, obj: MoveToTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGts(inStream.getObject());
        obj.setOldTableToBeRemoved(inStream.getInteger());
        obj.setText(inStream.getStringEx());
        obj.setTimeOut(inStream.getInteger());
    }

    writeMoveToTable(outStream: TSDataOutputStream, obj: MoveToTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getGts());
        outStream.putInteger(obj.getOldTableToBeRemoved());
        outStream.putStringEx(obj.getText());
        outStream.putInteger(obj.getTimeOut());
    }
    readMTCTRebuyAddonDialogInfo(inStream: TSDataInputStream, obj: MTCTRebuyAddonDialogInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountBalance(inStream.getLong());
        obj.setAddonDialog(inStream.getBoolean());
        obj.setBuyIn(inStream.getInteger());
        obj.setBuysLeft(inStream.getInteger());
        obj.setBuysTillNow(inStream.getInteger());
        obj.setChipCount(inStream.getInteger());
        obj.setDialogCloseTime(inStream.getInteger());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setFee(inStream.getInteger());
        obj.setFxRateDetails(inStream.getObject());
        obj.setIsTrnyFeeTypePercent(inStream.getBoolean());
        obj.setPlayerBroke(inStream.getBoolean());
        obj.setRebuyEndBreakNumber(inStream.getInteger());
        obj.setSynchronizedBreakTrny(inStream.getBoolean());
        obj.setTrnyFeePercent(inStream.getShort());
        obj.setRebuyEndMode(inStream.getInteger());
        obj.setRebuyEndValue(inStream.getInteger());
        obj.setTdBalance(inStream.getLong());
    }

    writeMTCTRebuyAddonDialogInfo(outStream: TSDataOutputStream, obj: MTCTRebuyAddonDialogInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAccountBalance());
        outStream.putBoolean(obj.getAddonDialog());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getBuysLeft());
        outStream.putInteger(obj.getBuysTillNow());
        outStream.putInteger(obj.getChipCount());
        outStream.putInteger(obj.getDialogCloseTime());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putInteger(obj.getFee());
        outStream.putObject(obj.getFxRateDetails());
        outStream.putBoolean(obj.getIsTrnyFeeTypePercent());
        outStream.putBoolean(obj.getPlayerBroke());
        outStream.putInteger(obj.getRebuyEndBreakNumber());
        outStream.putBoolean(obj.getSynchronizedBreakTrny());
        outStream.putShort(obj.getTrnyFeePercent());
        outStream.putInteger(obj.getRebuyEndMode());
        outStream.putInteger(obj.getRebuyEndValue());
        outStream.putLong(obj.getTdBalance());
    }
    readShowCards(inStream: TSDataInputStream, obj: ShowCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBestCards(inStream.getString());
        obj.setCards(inStream.getStringArray());
        obj.setHandStrengthHi(inStream.getStringEx());
        obj.setHandStrengthLo(inStream.getStringEx());
        obj.setSeatNo(inStream.getByte());
        obj.setShow(inStream.getBoolean());
        obj.setShowCardsType(inStream.getByte());
    }

    writeShowCards(outStream: TSDataOutputStream, obj: ShowCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getBestCards());
        outStream.putStringArray(obj.getCards());
        outStream.putStringEx(obj.getHandStrengthHi());
        outStream.putStringEx(obj.getHandStrengthLo());
        outStream.putByte(obj.getSeatNo());
        outStream.putBoolean(obj.getShow());
        outStream.putByte(obj.getShowCardsType());
    }
    readShowCongrats(inStream: TSDataInputStream, obj: ShowCongrats): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCongratsType(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeShowCongrats(outStream: TSDataOutputStream, obj: ShowCongrats): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getCongratsType());
        outStream.putStringEx(obj.getDesc());
    }
    readShowInfoOnTable(inStream: TSDataInputStream, obj: ShowInfoOnTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCountDown(inStream.getBoolean());
        obj.setMessage(inStream.getStringEx());
        obj.setTime(inStream.getLong());
    }

    writeShowInfoOnTable(outStream: TSDataOutputStream, obj: ShowInfoOnTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getCountDown());
        outStream.putStringEx(obj.getMessage());
        outStream.putLong(obj.getTime());
    }
    readTourneyLevelInfo(inStream: TSDataInputStream, obj: TourneyLevelInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setHighstake(inStream.getLong());
        obj.setLevelInfo(inStream.getStringEx());
        obj.setLowstake(inStream.getLong());
        obj.setNextLevelValue(inStream.getLong());
        obj.setRemainingHands(inStream.getStringEx());
        obj.setLevelNo(inStream.getInteger());
        obj.setNextLevelNo(inStream.getInteger());
        obj.setBlindLevelType(inStream.getInteger());
        obj.setNextLevelLowStake(inStream.getLong());
        obj.setNextLevelHighStake(inStream.getLong());
        obj.setAnte(inStream.getLong());
        obj.setNextLevelAnte(inStream.getLong());
        obj.setLevelNewInfo(inStream.getStringEx());
        obj.setNoOfPlacesPaid(inStream.getInteger());
        obj.setNoOfPlayers(inStream.getInteger());
        obj.setAverageStack(inStream.getLong());
    }

    writeTourneyLevelInfo(outStream: TSDataOutputStream, obj: TourneyLevelInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getHighstake());
        outStream.putStringEx(obj.getLevelInfo());
        outStream.putLong(obj.getLowstake());
        outStream.putLong(obj.getNextLevelValue());
        outStream.putStringEx(obj.getRemainingHands());
        outStream.putInteger(obj.getLevelNo());
        outStream.putInteger(obj.getNextLevelNo());
        outStream.putInteger(obj.getBlindLevelType());
        outStream.putLong(obj.getNextLevelLowStake());
        outStream.putLong(obj.getNextLevelHighStake());
        outStream.putLong(obj.getAnte());
        outStream.putLong(obj.getNextLevelAnte());
        outStream.putStringEx(obj.getLevelNewInfo());
        outStream.putInteger(obj.getNoOfPlacesPaid());
        outStream.putInteger(obj.getNoOfPlayers());
        outStream.putLong(obj.getAverageStack());
    }
    readRefreshTablesList(inStream: TSDataInputStream, obj: RefreshTablesList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRefreshTablesList(inStream.getObjectArray());
    }

    writeRefreshTablesList(outStream: TSDataOutputStream, obj: RefreshTablesList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getRefreshTablesList());
    }
    readMTCTResponse(inStream: TSDataInputStream, obj: MTCTResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setMessage(inStream.getStringEx());
        obj.setMtctID(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setTrackerIdList(inStream.getLongArray());
        obj.setIsRematchRegRes(inStream.getBoolean());
        obj.setParticipantNo(inStream.getInteger());
        obj.setFreeRollReturned(inStream.getBoolean());
    }

    writeMTCTResponse(outStream: TSDataOutputStream, obj: MTCTResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putStringEx(obj.getMessage());
        outStream.putInteger(obj.getMtctID());
        outStream.putInteger(obj.getResponseId());
        outStream.putLongArray(obj.getTrackerIdList());
        outStream.putBoolean(obj.getIsRematchRegRes());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putBoolean(obj.getFreeRollReturned());
    }
    readMsgTrafficStats(inStream: TSDataInputStream, obj: MsgTrafficStats): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTrafficStats(inStream.getStringEx());
    }

    writeMsgTrafficStats(outStream: TSDataOutputStream, obj: MsgTrafficStats): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getTrafficStats());
    }
    readOption(inStream: TSDataInputStream, obj: Option): void {
        obj.setAmount(inStream.getLong());
        obj.setOptionId(inStream.getInteger());
        obj.setOptionTitle(inStream.getStringExArray());
    }

    writeOption(outStream: TSDataOutputStream, obj: Option): void {
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getOptionId());
        outStream.putStringExArray(obj.getOptionTitle());
    }
    readLimitGameOption(inStream: TSDataInputStream, obj: LimitGameOption): void {
        obj.setClientAmt(inStream.getLong());
        obj.setIsMaxRaiseAllIn(inStream.getBoolean());
        obj.setMaxRaiseLimit(inStream.getLong());
        obj.setOption(inStream.getObject());
        obj.setSliderAmount(inStream.getLong());
        obj.setCurrentMaxBet(inStream.getLong());
    }

    writeLimitGameOption(outStream: TSDataOutputStream, obj: LimitGameOption): void {
        outStream.putLong(obj.getClientAmt());
        outStream.putBoolean(obj.getIsMaxRaiseAllIn());
        outStream.putLong(obj.getMaxRaiseLimit());
        outStream.putObject(obj.getOption());
        outStream.putLong(obj.getSliderAmount());
        outStream.putLong(obj.getCurrentMaxBet());
    }
    readAutoOption(inStream: TSDataInputStream, obj: AutoOption): void {
        obj.setAmount(inStream.getLong());
        obj.setAutoOptionId(inStream.getInteger());
        obj.setIsAllIn(inStream.getBoolean());
        obj.setTitle(inStream.getStringExArray());
    }

    writeAutoOption(outStream: TSDataOutputStream, obj: AutoOption): void {
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getAutoOptionId());
        outStream.putBoolean(obj.getIsAllIn());
        outStream.putStringExArray(obj.getTitle());
    }
    readSelectOption(inStream: TSDataInputStream, obj: SelectOption): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDisplayedOptionsForSecs(inStream.getByte());
        obj.setMouseClickSimulationType(inStream.getByte());
        obj.setOption(inStream.getObject());
        obj.setOptionSelectedAt(inStream.getLong());
        obj.setOptionSetId(inStream.getLong());
    }

    writeSelectOption(outStream: TSDataOutputStream, obj: SelectOption): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getDisplayedOptionsForSecs());
        outStream.putByte(obj.getMouseClickSimulationType());
        outStream.putObject(obj.getOption());
        outStream.putLong(obj.getOptionSelectedAt());
        outStream.putLong(obj.getOptionSetId());
    }
    readSelectedAutoOption(inStream: TSDataInputStream, obj: SelectedAutoOption): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAutoOption(inStream.getObject());
    }

    writeSelectedAutoOption(outStream: TSDataOutputStream, obj: SelectedAutoOption): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getAutoOption());
    }
    readInviteBuddyRequest(inStream: TSDataInputStream, obj: InviteBuddyRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameTableStatus(inStream.getObject());
        obj.setInvitingBuddy(inStream.getString());
        obj.setMessage(inStream.getString());
        obj.setTableId(inStream.getInteger());
    }

    writeInviteBuddyRequest(outStream: TSDataOutputStream, obj: InviteBuddyRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getGameTableStatus());
        outStream.putString(obj.getInvitingBuddy());
        outStream.putString(obj.getMessage());
        outStream.putInteger(obj.getTableId());
    }
    readSeatAvailable(inStream: TSDataInputStream, obj: SeatAvailable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setForGlobalWaitlist(inStream.getBoolean());
        obj.setGameTableStatus(inStream.getObject());
        obj.setSeatNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setTimeOut(inStream.getInteger());
    }

    writeSeatAvailable(outStream: TSDataOutputStream, obj: SeatAvailable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getForGlobalWaitlist());
        outStream.putObject(obj.getGameTableStatus());
        outStream.putInteger(obj.getSeatNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTimeOut());
    }
    readDealMakingChat(inStream: TSDataInputStream, obj: DealMakingChat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChat(inStream.getObject());
    }

    writeDealMakingChat(outStream: TSDataOutputStream, obj: DealMakingChat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getChat());
    }
    readGameStatus(inStream: TSDataInputStream, obj: GameStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCommunityHand(inStream.getStringArray());
        obj.setCurrentStake(inStream.getInteger());
        obj.setDealer(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setJackpotRake(inStream.getInteger());
        obj.setPlayers(inStream.getObjectArray());
        obj.setPots(inStream.getObjectArray());
        obj.setRabbitCommunityCards(inStream.getStringArray());
        obj.setRake(inStream.getInteger());
        obj.setReservedSeats(inStream.getStringArray());
        obj.setRoundCount(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
        obj.setTourneyGameStatus(inStream.getInteger());
        obj.setTrnyLevelInfo(inStream.getObject());
        obj.setTrnyStartedAt(inStream.getLong());
        obj.setWaitingNo(inStream.getInteger());
        obj.setWaitingStatus(inStream.getInteger());
        obj.setWaitingUsersCount(inStream.getInteger());
        obj.setCommunityHand2(inStream.getStringArray());
        obj.setBoosterType(inStream.getInteger());
        obj.setBoosterValFromBB(inStream.getInteger());
        obj.setBoosterValToBB(inStream.getInteger());
        obj.setBoosterWinnerPerc(inStream.getLong());
        obj.setBoosterLooserPerc(inStream.getLong());
    }

    writeGameStatus(outStream: TSDataOutputStream, obj: GameStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getCommunityHand());
        outStream.putInteger(obj.getCurrentStake());
        outStream.putInteger(obj.getDealer());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getJackpotRake());
        outStream.putObjectArray(obj.getPlayers());
        outStream.putObjectArray(obj.getPots());
        outStream.putStringArray(obj.getRabbitCommunityCards());
        outStream.putInteger(obj.getRake());
        outStream.putStringArray(obj.getReservedSeats());
        outStream.putInteger(obj.getRoundCount());
        outStream.putByte(obj.getSeatNo());
        outStream.putInteger(obj.getTourneyGameStatus());
        outStream.putObject(obj.getTrnyLevelInfo());
        outStream.putLong(obj.getTrnyStartedAt());
        outStream.putInteger(obj.getWaitingNo());
        outStream.putInteger(obj.getWaitingStatus());
        outStream.putInteger(obj.getWaitingUsersCount());
        outStream.putStringArray(obj.getCommunityHand2());
        outStream.putInteger(obj.getBoosterType());
        outStream.putInteger(obj.getBoosterValFromBB());
        outStream.putInteger(obj.getBoosterValToBB());
        outStream.putLong(obj.getBoosterWinnerPerc());
        outStream.putLong(obj.getBoosterLooserPerc());
    }
    readSTTReplayRequest(inStream: TSDataInputStream, obj: STTReplayRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
    }

    writeSTTReplayRequest(outStream: TSDataOutputStream, obj: STTReplayRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableInfoId());
    }
    readSTTReplayResponse(inStream: TSDataInputStream, obj: STTReplayResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setPeerId(inStream.getInteger());
        obj.setSeatNo(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setTableName(inStream.getStringEx());
    }

    writeSTTReplayResponse(outStream: TSDataOutputStream, obj: STTReplayResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountName());
        outStream.putInteger(obj.getPeerId());
        outStream.putInteger(obj.getSeatNo());
        outStream.putInteger(obj.getStatus());
        outStream.putInteger(obj.getTableId());
        outStream.putStringEx(obj.getTableName());
    }
    readRequestTournamentHH(inStream: TSDataInputStream, obj: RequestTournamentHH): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTrnyId(inStream.getLong());
    }

    writeRequestTournamentHH(outStream: TSDataOutputStream, obj: RequestTournamentHH): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getTrnyId());
    }
    readTimeDelayInfo(inStream: TSDataInputStream, obj: TimeDelayInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDelayInMillis(inStream.getInteger());
        obj.setType(inStream.getByte());
    }

    writeTimeDelayInfo(outStream: TSDataOutputStream, obj: TimeDelayInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getDelayInMillis());
        outStream.putByte(obj.getType());
    }
    readCasinoTrnyLevelInfo(inStream: TSDataInputStream, obj: CasinoTrnyLevelInfo): void {
        obj.setDuration(inStream.getInteger());
        obj.setGamesCount(inStream.getInteger());
        obj.setLevelId(inStream.getInteger());
        obj.setLevelStructId(inStream.getInteger());
        obj.setMaxBet(inStream.getInteger());
        obj.setMinBet(inStream.getInteger());
    }

    writeCasinoTrnyLevelInfo(outStream: TSDataOutputStream, obj: CasinoTrnyLevelInfo): void {
        outStream.putInteger(obj.getDuration());
        outStream.putInteger(obj.getGamesCount());
        outStream.putInteger(obj.getLevelId());
        outStream.putInteger(obj.getLevelStructId());
        outStream.putInteger(obj.getMaxBet());
        outStream.putInteger(obj.getMinBet());
    }
    readTBTTourneyResult(inStream: TSDataInputStream, obj: TBTTourneyResult): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayersRanks(inStream.getObjectArray());
        obj.setTrnyResult(inStream.getObject());
        obj.setTrnyRunTimeInSec(inStream.getInteger());
    }

    writeTBTTourneyResult(outStream: TSDataOutputStream, obj: TBTTourneyResult): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPlayersRanks());
        outStream.putObject(obj.getTrnyResult());
        outStream.putInteger(obj.getTrnyRunTimeInSec());
    }
    readTBTMinEndTime(inStream: TSDataInputStream, obj: TBTMinEndTime): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMinEndTime(inStream.getInteger());
    }

    writeTBTMinEndTime(outStream: TSDataOutputStream, obj: TBTMinEndTime): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMinEndTime());
    }
    readPlayerTourneyRank(inStream: TSDataInputStream, obj: PlayerTourneyRank): void {
        obj.setRank(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setWinAmount(inStream.getInteger());
    }

    writePlayerTourneyRank(outStream: TSDataOutputStream, obj: PlayerTourneyRank): void {
        outStream.putInteger(obj.getRank());
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getWinAmount());
    }
    readStayIn(inStream: TSDataInputStream, obj: StayIn): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccepted(inStream.getBoolean());
        obj.setAmount(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
    }

    writeStayIn(outStream: TSDataOutputStream, obj: StayIn): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getAccepted());
        outStream.putInteger(obj.getAmount());
        outStream.putByte(obj.getSeatNo());
    }
    readPostStayIn(inStream: TSDataInputStream, obj: PostStayIn): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getInteger());
        obj.setTimeOutAt(inStream.getInteger());
    }

    writePostStayIn(outStream: TSDataOutputStream, obj: PostStayIn): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAmount());
        outStream.putInteger(obj.getTimeOutAt());
    }
    readTableMessage(inStream: TSDataInputStream, obj: TableMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
        obj.setTagId(inStream.getByte());
        obj.setTagAction(inStream.getString());
        obj.setTableName(inStream.getStringEx());
    }

    writeTableMessage(outStream: TSDataOutputStream, obj: TableMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMessage());
        outStream.putByte(obj.getTagId());
        outStream.putString(obj.getTagAction());
        outStream.putStringEx(obj.getTableName());
    }
    readWaitlistCriteriaType(inStream: TSDataInputStream, obj: WaitlistCriteriaType): void {
        obj.setDpAny(inStream.getBoolean());
        obj.setGamePlayTypeAny(inStream.getBoolean());
        obj.setMinPlayers(inStream.getByte());
        obj.setTableInfoid(inStream.getInteger());
    }

    writeWaitlistCriteriaType(outStream: TSDataOutputStream, obj: WaitlistCriteriaType): void {
        outStream.putBoolean(obj.getDpAny());
        outStream.putBoolean(obj.getGamePlayTypeAny());
        outStream.putByte(obj.getMinPlayers());
        outStream.putInteger(obj.getTableInfoid());
    }
    readJoinWaitlist(inStream: TSDataInputStream, obj: JoinWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCriteria(inStream.getObject());
        obj.setTableid(inStream.getInteger());
    }

    writeJoinWaitlist(outStream: TSDataOutputStream, obj: JoinWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getCriteria());
        outStream.putInteger(obj.getTableid());
    }
    readJoinWaitlistResponse(inStream: TSDataInputStream, obj: JoinWaitlistResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCriteria(inStream.getObject());
        obj.setStatus(inStream.getBoolean());
        obj.setTableId(inStream.getInteger());
    }

    writeJoinWaitlistResponse(outStream: TSDataOutputStream, obj: JoinWaitlistResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getCriteria());
        outStream.putBoolean(obj.getStatus());
        outStream.putInteger(obj.getTableId());
    }
    readUnjoinWaitlist(inStream: TSDataInputStream, obj: UnjoinWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSuccess(inStream.getBoolean());
        obj.setTableId(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
    }

    writeUnjoinWaitlist(outStream: TSDataOutputStream, obj: UnjoinWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getSuccess());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableInfoId());
    }
    readUnjoinAllWaitlist(inStream: TSDataInputStream, obj: UnjoinAllWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableids(inStream.getIntArray());
    }

    writeUnjoinAllWaitlist(outStream: TSDataOutputStream, obj: UnjoinAllWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getTableids());
    }
    readJoinWaitlistRefresh(inStream: TSDataInputStream, obj: JoinWaitlistRefresh): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setJoinedLists(inStream.getObjectArray());
    }

    writeJoinWaitlistRefresh(outStream: TSDataOutputStream, obj: JoinWaitlistRefresh): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getJoinedLists());
    }
    readChatOffMessage(inStream: TSDataInputStream, obj: ChatOffMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChatIndicator(inStream.getBoolean());
        obj.setSeatNo(inStream.getByte());
    }

    writeChatOffMessage(outStream: TSDataOutputStream, obj: ChatOffMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getChatIndicator());
        outStream.putByte(obj.getSeatNo());
    }
    readMTCTDynaLiteralUpdate(inStream: TSDataInputStream, obj: MTCTDynaLiteralUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDynaUpdates(inStream.getBytes());
        obj.setMtctDynaUpdateId(inStream.getLong());
    }

    writeMTCTDynaLiteralUpdate(outStream: TSDataOutputStream, obj: MTCTDynaLiteralUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getDynaUpdates());
        outStream.putLong(obj.getMtctDynaUpdateId());
    }
    readQSParam(inStream: TSDataInputStream, obj: QSParam): void {
        obj.setOperatorId(inStream.getInteger());
        obj.setParamId(inStream.getInteger());
        obj.setValue(inStream.getString());
    }

    writeQSParam(outStream: TSDataOutputStream, obj: QSParam): void {
        outStream.putInteger(obj.getOperatorId());
        outStream.putInteger(obj.getParamId());
        outStream.putString(obj.getValue());
    }
    readQSRequest(inStream: TSDataInputStream, obj: QSRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setParamList(inStream.getObjectArray());
        obj.setRequestId(inStream.getInteger());
        obj.setRequestType(inStream.getByte());
        obj.setTabTypes(inStream.getIntArray());
        obj.setTableIdList(inStream.getIntArray());
    }

    writeQSRequest(outStream: TSDataOutputStream, obj: QSRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getParamList());
        outStream.putInteger(obj.getRequestId());
        outStream.putByte(obj.getRequestType());
        outStream.putIntArray(obj.getTabTypes());
        outStream.putIntArray(obj.getTableIdList());
    }
    readQSError(inStream: TSDataInputStream, obj: QSError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getByte());
        obj.setRequestId(inStream.getInteger());
        obj.setAthBlockTime(inStream.getLong());
    }

    writeQSError(outStream: TSDataOutputStream, obj: QSError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getErrorCode());
        outStream.putInteger(obj.getRequestId());
        outStream.putLong(obj.getAthBlockTime());
    }
    readQSResponse(inStream: TSDataInputStream, obj: QSResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setResultList(inStream.getObjectArray());
    }

    writeQSResponse(outStream: TSDataOutputStream, obj: QSResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putObjectArray(obj.getResultList());
    }
    readQSResult(inStream: TSDataInputStream, obj: QSResult): void {
        obj.setBlockSeatExpiresAt(inStream.getLong());
        obj.setErrorCode(inStream.getInteger());
        obj.setFuzzyParams(inStream.getString2StringMap());
        obj.setIsExact(inStream.getBoolean());
        obj.setPeerId(inStream.getInteger());
        obj.setRank(inStream.getByte());
        obj.setSeatNo(inStream.getByte());
        obj.setTableId(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setWaitListStatus(inStream.getByte());
    }

    writeQSResult(outStream: TSDataOutputStream, obj: QSResult): void {
        outStream.putLong(obj.getBlockSeatExpiresAt());
        outStream.putInteger(obj.getErrorCode());
        outStream.putString2StringMap(obj.getFuzzyParams());
        outStream.putBoolean(obj.getIsExact());
        outStream.putInteger(obj.getPeerId());
        outStream.putByte(obj.getRank());
        outStream.putByte(obj.getSeatNo());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putByte(obj.getWaitListStatus());
    }
    readQSBlockSeatCancel(inStream: TSDataInputStream, obj: QSBlockSeatCancel): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPeerTableList(inStream.getObjectArray());
    }

    writeQSBlockSeatCancel(outStream: TSDataOutputStream, obj: QSBlockSeatCancel): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPeerTableList());
    }
    readPeerTables(inStream: TSDataInputStream, obj: PeerTables): void {
        obj.setPeerId(inStream.getInteger());
        obj.setTableIdList(inStream.getIntArray());
    }

    writePeerTables(outStream: TSDataOutputStream, obj: PeerTables): void {
        outStream.putInteger(obj.getPeerId());
        outStream.putIntArray(obj.getTableIdList());
    }
    readDealHighCards(inStream: TSDataInputStream, obj: DealHighCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setHighCardSeat(inStream.getByte());
        obj.setPlayerDealCards(inStream.getObjectArray());
    }

    writeDealHighCards(outStream: TSDataOutputStream, obj: DealHighCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getHighCardSeat());
        outStream.putObjectArray(obj.getPlayerDealCards());
    }
    readMegaPotPrize(inStream: TSDataInputStream, obj: MegaPotPrize): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMegaPotPrize(inStream.getLong());
    }

    writeMegaPotPrize(outStream: TSDataOutputStream, obj: MegaPotPrize): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getMegaPotPrize());
    }
    readCalendarAlert(inStream: TSDataInputStream, obj: CalendarAlert): void {
        obj.setAlertTime(inStream.getTimestamp());
        obj.setAlertType(inStream.getShort());
        obj.setEmail(inStream.getString());
        obj.setMtctId(inStream.getInteger());
    }

    writeCalendarAlert(outStream: TSDataOutputStream, obj: CalendarAlert): void {
        outStream.putTimestamp(obj.getAlertTime());
        outStream.putShort(obj.getAlertType());
        outStream.putString(obj.getEmail());
        outStream.putInteger(obj.getMtctId());
    }
    readSetCalendarAlert(inStream: TSDataInputStream, obj: SetCalendarAlert): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAlertTime(inStream.getTimestamp());
        obj.setAlertType(inStream.getShort());
        obj.setEmail(inStream.getString());
        obj.setMtctId(inStream.getInteger());
    }

    writeSetCalendarAlert(outStream: TSDataOutputStream, obj: SetCalendarAlert): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putTimestamp(obj.getAlertTime());
        outStream.putShort(obj.getAlertType());
        outStream.putString(obj.getEmail());
        outStream.putInteger(obj.getMtctId());
    }
    readMTCTLevel(inStream: TSDataInputStream, obj: MTCTLevel): void {
        obj.setAnte(inStream.getLong());
        obj.setBigBlind(inStream.getLong());
        obj.setBlindLevelValue(inStream.getInteger());
        obj.setLevel(inStream.getInteger());
        obj.setSmallBlind(inStream.getLong());
    }

    writeMTCTLevel(outStream: TSDataOutputStream, obj: MTCTLevel): void {
        outStream.putLong(obj.getAnte());
        outStream.putLong(obj.getBigBlind());
        outStream.putInteger(obj.getBlindLevelValue());
        outStream.putInteger(obj.getLevel());
        outStream.putLong(obj.getSmallBlind());
    }
    readPrePromoTicker(inStream: TSDataInputStream, obj: PrePromoTicker): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStartDate(inStream.getLong());
    }

    writePrePromoTicker(outStream: TSDataOutputStream, obj: PrePromoTicker): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getStartDate());
    }
    readPromoUpdateTicker(inStream: TSDataInputStream, obj: PromoUpdateTicker): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setCountDownHands(inStream.getInteger());
        obj.setHandCounter(inStream.getLong());
    }

    writePromoUpdateTicker(outStream: TSDataOutputStream, obj: PromoUpdateTicker): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putInteger(obj.getCountDownHands());
        outStream.putLong(obj.getHandCounter());
    }
    readRatHoleResponse(inStream: TSDataInputStream, obj: RatHoleResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBlockSeatResponse(inStream.getObject());
        obj.setMinAmount(inStream.getLong());
    }

    writeRatHoleResponse(outStream: TSDataOutputStream, obj: RatHoleResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getBlockSeatResponse());
        outStream.putLong(obj.getMinAmount());
    }
    readFXConversionData(inStream: TSDataInputStream, obj: FXConversionData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFxFactor(inStream.getObject());
    }

    writeFXConversionData(outStream: TSDataOutputStream, obj: FXConversionData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getFxFactor());
    }
    readMTCTLobbyData(inStream: TSDataInputStream, obj: MTCTLobbyData): void {
        obj.setAddonSupported(inStream.getBoolean());
        obj.setAllowLateRegistration(inStream.getBoolean());
        obj.setAllowLateRegistrationTillLevel(inStream.getInteger());
        obj.setAllowedCountryCodes(inStream.getString());
        obj.setAnnounceTime(inStream.getTimestamp());
        obj.setBountyAmount(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setColorCode(inStream.getByte());
        obj.setEntryFee(inStream.getInteger());
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setGameCurrency(inStream.getString());
        obj.setGamePlayType(inStream.getByte());
        obj.setGameType(inStream.getInteger());
        obj.setIsQuinteTourney(inStream.getBoolean());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxParticipants(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setMtctEvents(inStream.getIntArray());
        obj.setMtctId(inStream.getInteger());
        obj.setMtsgId(inStream.getInteger());
        obj.setMttType(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setNoOfParticipants(inStream.getInteger());
        obj.setNoOfPlayers(inStream.getInteger());
        obj.setPartyPoints(inStream.getInteger());
        obj.setQuintepokChampFee(inStream.getInteger());
        obj.setRebuyAddonStatus(inStream.getByte());
        obj.setRegistrationEndTime(inStream.getTimestamp());
        obj.setRegistrationStartTime(inStream.getTimestamp());
        obj.setStatus(inStream.getInteger());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setTotalRoundCount(inStream.getInteger());
        obj.setTournamentDescription(inStream.getStringEx());
        obj.setTourneyCurrentLevel(inStream.getInteger());
        obj.setTourneyEndTime(inStream.getTimestamp());
        obj.setTourneyStartTime(inStream.getTimestamp());
        obj.setTrnyMinPlrs(inStream.getInteger());
        obj.setTrnyTypeId(inStream.getInteger());
        obj.setUnRegOffset(inStream.getLong());
        obj.setUnregClosed(inStream.getBoolean());
    }

    writeMTCTLobbyData(outStream: TSDataOutputStream, obj: MTCTLobbyData): void {
        outStream.putBoolean(obj.getAddonSupported());
        outStream.putBoolean(obj.getAllowLateRegistration());
        outStream.putInteger(obj.getAllowLateRegistrationTillLevel());
        outStream.putString(obj.getAllowedCountryCodes());
        outStream.putTimestamp(obj.getAnnounceTime());
        outStream.putInteger(obj.getBountyAmount());
        outStream.putInteger(obj.getBuyIn());
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putByte(obj.getColorCode());
        outStream.putInteger(obj.getEntryFee());
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putString(obj.getGameCurrency());
        outStream.putByte(obj.getGamePlayType());
        outStream.putInteger(obj.getGameType());
        outStream.putBoolean(obj.getIsQuinteTourney());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxParticipants());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putIntArray(obj.getMtctEvents());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getMtsgId());
        outStream.putInteger(obj.getMttType());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getNoOfParticipants());
        outStream.putInteger(obj.getNoOfPlayers());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putInteger(obj.getQuintepokChampFee());
        outStream.putByte(obj.getRebuyAddonStatus());
        outStream.putTimestamp(obj.getRegistrationEndTime());
        outStream.putTimestamp(obj.getRegistrationStartTime());
        outStream.putInteger(obj.getStatus());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putInteger(obj.getTotalRoundCount());
        outStream.putStringEx(obj.getTournamentDescription());
        outStream.putInteger(obj.getTourneyCurrentLevel());
        outStream.putTimestamp(obj.getTourneyEndTime());
        outStream.putTimestamp(obj.getTourneyStartTime());
        outStream.putInteger(obj.getTrnyMinPlrs());
        outStream.putInteger(obj.getTrnyTypeId());
        outStream.putLong(obj.getUnRegOffset());
        outStream.putBoolean(obj.getUnregClosed());
    }
    readMTCTRegistrationChangeEvent(inStream: TSDataInputStream, obj: MTCTRegistrationChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setNoOfParticipants(inStream.getInteger());
    }

    writeMTCTRegistrationChangeEvent(outStream: TSDataOutputStream, obj: MTCTRegistrationChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getNoOfParticipants());
    }
    readMTCTStatusChangeEvent(inStream: TSDataInputStream, obj: MTCTStatusChangeEvent): void {
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setMtctId(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setUnregClosed(inStream.getBoolean());
    }

    writeMTCTStatusChangeEvent(outStream: TSDataOutputStream, obj: MTCTStatusChangeEvent): void {
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getStatus());
        outStream.putBoolean(obj.getUnregClosed());
    }
    readMTCTDeleteEvent(inStream: TSDataInputStream, obj: MTCTDeleteEvent): void {
        obj.setMtctId(inStream.getInteger());
    }

    writeMTCTDeleteEvent(outStream: TSDataOutputStream, obj: MTCTDeleteEvent): void {
        outStream.putInteger(obj.getMtctId());
    }
    readMTCTLevelChangeEvent(inStream: TSDataInputStream, obj: MTCTLevelChangeEvent): void {
        obj.setCurrentLevel(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
    }

    writeMTCTLevelChangeEvent(outStream: TSDataOutputStream, obj: MTCTLevelChangeEvent): void {
        outStream.putInteger(obj.getCurrentLevel());
        outStream.putInteger(obj.getMtctId());
    }
    readMTCTPlayerCountChangeEvent(inStream: TSDataInputStream, obj: MTCTPlayerCountChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
    }

    writeMTCTPlayerCountChangeEvent(outStream: TSDataOutputStream, obj: MTCTPlayerCountChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPlayerCount());
    }
    readMTCTPrizePoolChangeEvent(inStream: TSDataInputStream, obj: MTCTPrizePoolChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setTotalPrizePool(inStream.getLong());
    }

    writeMTCTPrizePoolChangeEvent(outStream: TSDataOutputStream, obj: MTCTPrizePoolChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putLong(obj.getTotalPrizePool());
    }
    readMTCTLobbySnapshotChange(inStream: TSDataInputStream, obj: MTCTLobbySnapshotChange): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapshotId(inStream.getInteger());
        obj.setTabIds(inStream.getIntArray());
    }

    writeMTCTLobbySnapshotChange(outStream: TSDataOutputStream, obj: MTCTLobbySnapshotChange): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSnapshotId());
        outStream.putIntArray(obj.getTabIds());
    }
    readScratchCardTicker(inStream: TSDataInputStream, obj: ScratchCardTicker): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTickerData(inStream.getLong());
    }

    writeScratchCardTicker(outStream: TSDataOutputStream, obj: ScratchCardTicker): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getTickerData());
    }
    readRelocateTable(inStream: TSDataInputStream, obj: RelocateTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setTargetServer(inStream.getInteger());
    }

    writeRelocateTable(outStream: TSDataOutputStream, obj: RelocateTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTargetServer());
    }
    readMTCTCustomPropertyChangeEvent(inStream: TSDataInputStream, obj: MTCTCustomPropertyChangeEvent): void {
        obj.setAllowedCountryCodes(inStream.getString());
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setMtctId(inStream.getInteger());
        obj.setPartyPoints(inStream.getInteger());
    }

    writeMTCTCustomPropertyChangeEvent(outStream: TSDataOutputStream, obj: MTCTCustomPropertyChangeEvent): void {
        outStream.putString(obj.getAllowedCountryCodes());
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPartyPoints());
    }
    readMTCTPlayerKickedOut(inStream: TSDataInputStream, obj: MTCTPlayerKickedOut): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setRank(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setEliminatedPosition(inStream.getInteger());
    }

    writeMTCTPlayerKickedOut(outStream: TSDataOutputStream, obj: MTCTPlayerKickedOut): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getRank());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getEliminatedPosition());
    }
    readMTCTParticipantNamesOnRequest(inStream: TSDataInputStream, obj: MTCTParticipantNamesOnRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipants(inStream.getInt2StringMap());
    }

    writeMTCTParticipantNamesOnRequest(outStream: TSDataOutputStream, obj: MTCTParticipantNamesOnRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInt2StringMap(obj.getParticipants());
    }
    readRequestMTCTParticipantNamesOnSearchString(inStream: TSDataInputStream, obj: RequestMTCTParticipantNamesOnSearchString): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setSearchString(inStream.getString());
    }

    writeRequestMTCTParticipantNamesOnSearchString(outStream: TSDataOutputStream, obj: RequestMTCTParticipantNamesOnSearchString): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getSearchString());
    }
    readJoinTableQS(inStream: TSDataInputStream, obj: JoinTableQS): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setRequestType(inStream.getByte());
        obj.setTableCategory(inStream.getByte());
    }

    writeJoinTableQS(outStream: TSDataOutputStream, obj: JoinTableQS): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putByte(obj.getRequestType());
        outStream.putByte(obj.getTableCategory());
    }
    readJoinTableAutoBuyIn(inStream: TSDataInputStream, obj: JoinTableAutoBuyIn): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setPassword(inStream.getString());
        obj.setRequestType(inStream.getByte());
        obj.setSeatNumber(inStream.getByte());
        obj.setTableCategory(inStream.getByte());
        obj.setSource(inStream.getByte());
    }

    writeJoinTableAutoBuyIn(outStream: TSDataOutputStream, obj: JoinTableAutoBuyIn): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putString(obj.getPassword());
        outStream.putByte(obj.getRequestType());
        outStream.putByte(obj.getSeatNumber());
        outStream.putByte(obj.getTableCategory());
        outStream.putByte(obj.getSource());
    }
    readJoinTableSTTReplay(inStream: TSDataInputStream, obj: JoinTableSTTReplay): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeJoinTableSTTReplay(outStream: TSDataOutputStream, obj: JoinTableSTTReplay): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readSitResponse(inStream: TSDataInputStream, obj: SitResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setResponseId(inStream.getInteger());
        obj.setSitResponseToClient(inStream.getStringEx());
        obj.setTrackerIdList(inStream.getLongArray());
    }

    writeSitResponse(outStream: TSDataOutputStream, obj: SitResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getResponseId());
        outStream.putStringEx(obj.getSitResponseToClient());
        outStream.putLongArray(obj.getTrackerIdList());
    }
    readAutoBuyInError(inStream: TSDataInputStream, obj: AutoBuyInError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setErrorCode(inStream.getInteger());
    }

    writeAutoBuyInError(outStream: TSDataOutputStream, obj: AutoBuyInError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getErrorCode());
    }
    readBlockSeatCancel(inStream: TSDataInputStream, obj: BlockSeatCancel): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestType(inStream.getByte());
    }

    writeBlockSeatCancel(outStream: TSDataOutputStream, obj: BlockSeatCancel): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getRequestType());
    }
    readItaliaAuthorizationCodes(inStream: TSDataInputStream, obj: ItaliaAuthorizationCodes): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipationId(inStream.getString());
        obj.setSessionId(inStream.getString());
    }

    writeItaliaAuthorizationCodes(outStream: TSDataOutputStream, obj: ItaliaAuthorizationCodes): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getParticipationId());
        outStream.putString(obj.getSessionId());
    }
    readRequestAutoCheckOff(inStream: TSDataInputStream, obj: RequestAutoCheckOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoCheckOff(outStream: TSDataOutputStream, obj: RequestAutoCheckOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutoCheckOn(inStream: TSDataInputStream, obj: RequestAutoCheckOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoCheckOn(outStream: TSDataOutputStream, obj: RequestAutoCheckOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutoOptionsOptionsMappings(inStream: TSDataInputStream, obj: RequestAutoOptionsOptionsMappings): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoOptionsOptionsMappings(outStream: TSDataOutputStream, obj: RequestAutoOptionsOptionsMappings): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestBuddiesList(inStream: TSDataInputStream, obj: RequestBuddiesList): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestBuddiesList(outStream: TSDataOutputStream, obj: RequestBuddiesList): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestChatOffDisabled(inStream: TSDataInputStream, obj: RequestChatOffDisabled): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestChatOffDisabled(outStream: TSDataOutputStream, obj: RequestChatOffDisabled): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestChatOffEnabled(inStream: TSDataInputStream, obj: RequestChatOffEnabled): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestChatOffEnabled(outStream: TSDataOutputStream, obj: RequestChatOffEnabled): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestCurrentPlayerRegistration(inStream: TSDataInputStream, obj: RequestCurrentPlayerRegistration): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestCurrentPlayerRegistration(outStream: TSDataOutputStream, obj: RequestCurrentPlayerRegistration): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingExit(inStream: TSDataInputStream, obj: RequestDealMakingExit): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingExit(outStream: TSDataOutputStream, obj: RequestDealMakingExit): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingReject(inStream: TSDataInputStream, obj: RequestDealMakingReject): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingReject(outStream: TSDataOutputStream, obj: RequestDealMakingReject): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingView(inStream: TSDataInputStream, obj: RequestDealMakingView): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingView(outStream: TSDataOutputStream, obj: RequestDealMakingView): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestFoldToAnyBetOn(inStream: TSDataInputStream, obj: RequestFoldToAnyBetOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestFoldToAnyBetOn(outStream: TSDataOutputStream, obj: RequestFoldToAnyBetOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestListOfPVTTableTypes(inStream: TSDataInputStream, obj: RequestListOfPVTTableTypes): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestListOfPVTTableTypes(outStream: TSDataOutputStream, obj: RequestListOfPVTTableTypes): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestMtctRebuyDialogCancel(inStream: TSDataInputStream, obj: RequestMtctRebuyDialogCancel): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestMtctRebuyDialogCancel(outStream: TSDataOutputStream, obj: RequestMtctRebuyDialogCancel): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestNoBuddiesList(inStream: TSDataInputStream, obj: RequestNoBuddiesList): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestNoBuddiesList(outStream: TSDataOutputStream, obj: RequestNoBuddiesList): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestRegainPlayer(inStream: TSDataInputStream, obj: RequestRegainPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestRegainPlayer(outStream: TSDataOutputStream, obj: RequestRegainPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestFoldToAnyBetOff(inStream: TSDataInputStream, obj: RequestFoldToAnyBetOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestFoldToAnyBetOff(outStream: TSDataOutputStream, obj: RequestFoldToAnyBetOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestSendJackpotSnapshots(inStream: TSDataInputStream, obj: RequestSendJackpotSnapshots): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSendJackpotSnapshots(outStream: TSDataOutputStream, obj: RequestSendJackpotSnapshots): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestTourneyPrizeInfo(inStream: TSDataInputStream, obj: RequestTourneyPrizeInfo): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestTourneyPrizeInfo(outStream: TSDataOutputStream, obj: RequestTourneyPrizeInfo): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestWaitingPlayers(inStream: TSDataInputStream, obj: RequestWaitingPlayers): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestWaitingPlayers(outStream: TSDataOutputStream, obj: RequestWaitingPlayers): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutopostBlindAndFoldOff(inStream: TSDataInputStream, obj: RequestAutopostBlindAndFoldOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutopostBlindAndFoldOff(outStream: TSDataOutputStream, obj: RequestAutopostBlindAndFoldOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutoPostBlindAndFoldOn(inStream: TSDataInputStream, obj: RequestAutoPostBlindAndFoldOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoPostBlindAndFoldOn(outStream: TSDataOutputStream, obj: RequestAutoPostBlindAndFoldOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutoPostBlindOff(inStream: TSDataInputStream, obj: RequestAutoPostBlindOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoPostBlindOff(outStream: TSDataOutputStream, obj: RequestAutoPostBlindOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutoPostBlindOn(inStream: TSDataInputStream, obj: RequestAutoPostBlindOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoPostBlindOn(outStream: TSDataOutputStream, obj: RequestAutoPostBlindOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutorebuyOff(inStream: TSDataInputStream, obj: RequestAutorebuyOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutorebuyOff(outStream: TSDataOutputStream, obj: RequestAutorebuyOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAutoRebuyOn(inStream: TSDataInputStream, obj: RequestAutoRebuyOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAutoRebuyOn(outStream: TSDataOutputStream, obj: RequestAutoRebuyOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingAllConfirmed(inStream: TSDataInputStream, obj: RequestDealMakingAllConfirmed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingAllConfirmed(outStream: TSDataOutputStream, obj: RequestDealMakingAllConfirmed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingConfirm(inStream: TSDataInputStream, obj: RequestDealMakingConfirm): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingConfirm(outStream: TSDataOutputStream, obj: RequestDealMakingConfirm): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingNo(inStream: TSDataInputStream, obj: RequestDealMakingNo): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingNo(outStream: TSDataOutputStream, obj: RequestDealMakingNo): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingYes(inStream: TSDataInputStream, obj: RequestDealMakingYes): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingYes(outStream: TSDataOutputStream, obj: RequestDealMakingYes): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestGameStatus(inStream: TSDataInputStream, obj: RequestGameStatus): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestGameStatus(outStream: TSDataOutputStream, obj: RequestGameStatus): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestLeaveTable(inStream: TSDataInputStream, obj: RequestLeaveTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setTableCategory(inStream.getByte());
    }

    writeRequestLeaveTable(outStream: TSDataOutputStream, obj: RequestLeaveTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putByte(obj.getTableCategory());
    }
    readRequestMuckLosingHandOff(inStream: TSDataInputStream, obj: RequestMuckLosingHandOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestMuckLosingHandOff(outStream: TSDataOutputStream, obj: RequestMuckLosingHandOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestMuckLosingHandOn(inStream: TSDataInputStream, obj: RequestMuckLosingHandOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestMuckLosingHandOn(outStream: TSDataOutputStream, obj: RequestMuckLosingHandOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestSitoutAtNextBBOff(inStream: TSDataInputStream, obj: RequestSitoutAtNextBBOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSitoutAtNextBBOff(outStream: TSDataOutputStream, obj: RequestSitoutAtNextBBOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestSitoutAtNextBBOn(inStream: TSDataInputStream, obj: RequestSitoutAtNextBBOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSitoutAtNextBBOn(outStream: TSDataOutputStream, obj: RequestSitoutAtNextBBOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestSitoutNextHandOff(inStream: TSDataInputStream, obj: RequestSitoutNextHandOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSitoutNextHandOff(outStream: TSDataOutputStream, obj: RequestSitoutNextHandOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestSitoutNextHandOn(inStream: TSDataInputStream, obj: RequestSitoutNextHandOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSitoutNextHandOn(outStream: TSDataOutputStream, obj: RequestSitoutNextHandOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestWaitforBigblindOff(inStream: TSDataInputStream, obj: RequestWaitforBigblindOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestWaitforBigblindOff(outStream: TSDataOutputStream, obj: RequestWaitforBigblindOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestWaitForBigBlindOn(inStream: TSDataInputStream, obj: RequestWaitForBigBlindOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestWaitForBigBlindOn(outStream: TSDataOutputStream, obj: RequestWaitForBigBlindOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAdviseToBuyMoreChips(inStream: TSDataInputStream, obj: RequestAdviseToBuyMoreChips): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAdviseToBuyMoreChips(outStream: TSDataOutputStream, obj: RequestAdviseToBuyMoreChips): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestCloseGameTable(inStream: TSDataInputStream, obj: RequestCloseGameTable): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestCloseGameTable(outStream: TSDataOutputStream, obj: RequestCloseGameTable): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingEnded(inStream: TSDataInputStream, obj: RequestDealMakingEnded): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingEnded(outStream: TSDataOutputStream, obj: RequestDealMakingEnded): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingGameClosed(inStream: TSDataInputStream, obj: RequestDealMakingGameClosed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingGameClosed(outStream: TSDataOutputStream, obj: RequestDealMakingGameClosed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingGameToContinue(inStream: TSDataInputStream, obj: RequestDealMakingGameToContinue): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingGameToContinue(outStream: TSDataOutputStream, obj: RequestDealMakingGameToContinue): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingHide(inStream: TSDataInputStream, obj: RequestDealMakingHide): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingHide(outStream: TSDataOutputStream, obj: RequestDealMakingHide): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingPayoutVetoed(inStream: TSDataInputStream, obj: RequestDealMakingPayoutVetoed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingPayoutVetoed(outStream: TSDataOutputStream, obj: RequestDealMakingPayoutVetoed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingStarted(inStream: TSDataInputStream, obj: RequestDealMakingStarted): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingStarted(outStream: TSDataOutputStream, obj: RequestDealMakingStarted): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingTimeout(inStream: TSDataInputStream, obj: RequestDealMakingTimeout): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingTimeout(outStream: TSDataOutputStream, obj: RequestDealMakingTimeout): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestDealMakingVetoed(inStream: TSDataInputStream, obj: RequestDealMakingVetoed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDealMakingVetoed(outStream: TSDataOutputStream, obj: RequestDealMakingVetoed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestEndOfTbt(inStream: TSDataInputStream, obj: RequestEndOfTbt): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestEndOfTbt(outStream: TSDataOutputStream, obj: RequestEndOfTbt): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestEndOfTheGame(inStream: TSDataInputStream, obj: RequestEndOfTheGame): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestEndOfTheGame(outStream: TSDataOutputStream, obj: RequestEndOfTheGame): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestKillGametableSilently(inStream: TSDataInputStream, obj: RequestKillGametableSilently): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestKillGametableSilently(outStream: TSDataOutputStream, obj: RequestKillGametableSilently): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestServerClosckReSync(inStream: TSDataInputStream, obj: RequestServerClosckReSync): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestServerClosckReSync(outStream: TSDataOutputStream, obj: RequestServerClosckReSync): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestShowSitButtons(inStream: TSDataInputStream, obj: RequestShowSitButtons): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestShowSitButtons(outStream: TSDataOutputStream, obj: RequestShowSitButtons): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseAddonFailureAddonExceeded(inStream: TSDataInputStream, obj: ResponseAddonFailureAddonExceeded): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseAddonFailureAddonExceeded(outStream: TSDataOutputStream, obj: ResponseAddonFailureAddonExceeded): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseAddonFailureDBError(inStream: TSDataInputStream, obj: ResponseAddonFailureDBError): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseAddonFailureDBError(outStream: TSDataOutputStream, obj: ResponseAddonFailureDBError): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseAddonFailureInsufficientBalance(inStream: TSDataInputStream, obj: ResponseAddonFailureInsufficientBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseAddonFailureInsufficientBalance(outStream: TSDataOutputStream, obj: ResponseAddonFailureInsufficientBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseAddonSuccess(inStream: TSDataInputStream, obj: ResponseAddonSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseAddonSuccess(outStream: TSDataOutputStream, obj: ResponseAddonSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseAllinResetFailed(inStream: TSDataInputStream, obj: ResponseAllinResetFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseAllinResetFailed(outStream: TSDataOutputStream, obj: ResponseAllinResetFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseAllinResetNotRequired(inStream: TSDataInputStream, obj: ResponseAllinResetNotRequired): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseAllinResetNotRequired(outStream: TSDataOutputStream, obj: ResponseAllinResetNotRequired): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseAllinResetPending(inStream: TSDataInputStream, obj: ResponseAllinResetPending): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseAllinResetPending(outStream: TSDataOutputStream, obj: ResponseAllinResetPending): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseAllinResetSuccess(inStream: TSDataInputStream, obj: ResponseAllinResetSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseAllinResetSuccess(outStream: TSDataOutputStream, obj: ResponseAllinResetSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsDBError(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsDBError): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsDBError(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsDBError): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsNotAPlayer(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsNotAPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsNotAPlayer(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsNotAPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsNotAvailable(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsNotAvailable): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsNotAvailable(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsNotAvailable): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsPlaying(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsPlaying): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsPlaying(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsPlaying): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsSuccess(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setPartnerBalanceAC(inStream.getLong());
        obj.setRealMoneyBalanceAC(inStream.getLong());
        obj.setRebuyAmountAC(inStream.getLong());
        obj.setRefreshBalanceTypes(inStream.getByte());
    }

    writeResponseBuyMoreChipsSuccess(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putLong(obj.getPartnerBalanceAC());
        outStream.putLong(obj.getRealMoneyBalanceAC());
        outStream.putLong(obj.getRebuyAmountAC());
        outStream.putByte(obj.getRefreshBalanceTypes());
    }
    readResponseChatRequestPermissionDenied(inStream: TSDataInputStream, obj: ResponseChatRequestPermissionDenied): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChatRequestPermissionDenied(outStream: TSDataOutputStream, obj: ResponseChatRequestPermissionDenied): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseDealMakingConfirmSuccess(inStream: TSDataInputStream, obj: ResponseDealMakingConfirmSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseDealMakingConfirmSuccess(outStream: TSDataOutputStream, obj: ResponseDealMakingConfirmSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseDealMakingRejectSuccess(inStream: TSDataInputStream, obj: ResponseDealMakingRejectSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseDealMakingRejectSuccess(outStream: TSDataOutputStream, obj: ResponseDealMakingRejectSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseDealMakingSubmitFailure(inStream: TSDataInputStream, obj: ResponseDealMakingSubmitFailure): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseDealMakingSubmitFailure(outStream: TSDataOutputStream, obj: ResponseDealMakingSubmitFailure): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseDealMakingSubmitSuccess(inStream: TSDataInputStream, obj: ResponseDealMakingSubmitSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseDealMakingSubmitSuccess(outStream: TSDataOutputStream, obj: ResponseDealMakingSubmitSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseGeneralErrorMessage(inStream: TSDataInputStream, obj: ResponseGeneralErrorMessage): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseGeneralErrorMessage(outStream: TSDataOutputStream, obj: ResponseGeneralErrorMessage): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseHandHistoryFail(inStream: TSDataInputStream, obj: ResponseHandHistoryFail): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseHandHistoryFail(outStream: TSDataOutputStream, obj: ResponseHandHistoryFail): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseHandHistorySuccess(inStream: TSDataInputStream, obj: ResponseHandHistorySuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseHandHistorySuccess(outStream: TSDataOutputStream, obj: ResponseHandHistorySuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseHURematchAccepted(inStream: TSDataInputStream, obj: ResponseHURematchAccepted): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseHURematchAccepted(outStream: TSDataOutputStream, obj: ResponseHURematchAccepted): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseHURematchRejected(inStream: TSDataInputStream, obj: ResponseHURematchRejected): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseHURematchRejected(outStream: TSDataOutputStream, obj: ResponseHURematchRejected): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseHURematchTimeout(inStream: TSDataInputStream, obj: ResponseHURematchTimeout): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseHURematchTimeout(outStream: TSDataOutputStream, obj: ResponseHURematchTimeout): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseJPWelcomeMsg(inStream: TSDataInputStream, obj: ResponseJPWelcomeMsg): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseJPWelcomeMsg(outStream: TSDataOutputStream, obj: ResponseJPWelcomeMsg): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseNOBuddiesListSuccess(inStream: TSDataInputStream, obj: ResponseNOBuddiesListSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseNOBuddiesListSuccess(outStream: TSDataOutputStream, obj: ResponseNOBuddiesListSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRebuyAddonClosed(inStream: TSDataInputStream, obj: ResponseRebuyAddonClosed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuyAddonClosed(outStream: TSDataOutputStream, obj: ResponseRebuyAddonClosed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRebuyFailureDBError(inStream: TSDataInputStream, obj: ResponseRebuyFailureDBError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuyFailureDBError(outStream: TSDataOutputStream, obj: ResponseRebuyFailureDBError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRebuyFailureImproperChipBalance(inStream: TSDataInputStream, obj: ResponseRebuyFailureImproperChipBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuyFailureImproperChipBalance(outStream: TSDataOutputStream, obj: ResponseRebuyFailureImproperChipBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRebuyFailureInsufficientBalance(inStream: TSDataInputStream, obj: ResponseRebuyFailureInsufficientBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuyFailureInsufficientBalance(outStream: TSDataOutputStream, obj: ResponseRebuyFailureInsufficientBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRebuyFailureRebuysExceeded(inStream: TSDataInputStream, obj: ResponseRebuyFailureRebuysExceeded): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuyFailureRebuysExceeded(outStream: TSDataOutputStream, obj: ResponseRebuyFailureRebuysExceeded): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRebuySuccess(inStream: TSDataInputStream, obj: ResponseRebuySuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuySuccess(outStream: TSDataOutputStream, obj: ResponseRebuySuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRebuySuccessPending(inStream: TSDataInputStream, obj: ResponseRebuySuccessPending): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRebuySuccessPending(outStream: TSDataOutputStream, obj: ResponseRebuySuccessPending): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseRegainPlayerFailed(inStream: TSDataInputStream, obj: ResponseRegainPlayerFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRegainPlayerFailed(outStream: TSDataOutputStream, obj: ResponseRegainPlayerFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRegainPlayerSuccess(inStream: TSDataInputStream, obj: ResponseRegainPlayerSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRegainPlayerSuccess(outStream: TSDataOutputStream, obj: ResponseRegainPlayerSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseShowCardsOptionShow(inStream: TSDataInputStream, obj: ResponseShowCardsOptionShow): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseShowCardsOptionShow(outStream: TSDataOutputStream, obj: ResponseShowCardsOptionShow): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseReceivedExpiredOptions(inStream: TSDataInputStream, obj: ResponseReceivedExpiredOptions): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseReceivedExpiredOptions(outStream: TSDataOutputStream, obj: ResponseReceivedExpiredOptions): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseShowCardsOptionDontShow(inStream: TSDataInputStream, obj: ResponseShowCardsOptionDontShow): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseShowCardsOptionDontShow(outStream: TSDataOutputStream, obj: ResponseShowCardsOptionDontShow): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseShowCardsOptionMuck(inStream: TSDataInputStream, obj: ResponseShowCardsOptionMuck): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseShowCardsOptionMuck(outStream: TSDataOutputStream, obj: ResponseShowCardsOptionMuck): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsAllowedAmount(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsAllowedAmount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseBuyMoreChipsAllowedAmount(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsAllowedAmount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readRGLimitErrorMsg(inStream: TSDataInputStream, obj: RGLimitErrorMsg): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMsg(inStream.getStringEx());
        obj.setConversationId(inStream.getLong());
        obj.setResponseId(inStream.getInteger());
    }

    writeRGLimitErrorMsg(outStream: TSDataOutputStream, obj: RGLimitErrorMsg): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMsg());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getResponseId());
    }
    readItaliaAuthorizationCodesForTable(inStream: TSDataInputStream, obj: ItaliaAuthorizationCodesForTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getString());
        obj.setParticipationId(inStream.getString());
        obj.setSessionId(inStream.getString());
    }

    writeItaliaAuthorizationCodesForTable(outStream: TSDataOutputStream, obj: ItaliaAuthorizationCodesForTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMtctId());
        outStream.putString(obj.getParticipationId());
        outStream.putString(obj.getSessionId());
    }
    readPokerChatRequest(inStream: TSDataInputStream, obj: PokerChatRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessageBody(inStream.getString());
        obj.setMsgType(inStream.getByte());
    }

    writePokerChatRequest(outStream: TSDataOutputStream, obj: PokerChatRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMessageBody());
        outStream.putByte(obj.getMsgType());
    }
    readRequestUserBalance(inStream: TSDataInputStream, obj: RequestUserBalance): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestUserBalance(outStream: TSDataOutputStream, obj: RequestUserBalance): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAddMorePlayMoney(inStream: TSDataInputStream, obj: RequestAddMorePlayMoney): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAddMorePlayMoney(outStream: TSDataOutputStream, obj: RequestAddMorePlayMoney): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readWaitingPlayersForLobby(inStream: TSDataInputStream, obj: WaitingPlayersForLobby): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPosition(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setWaitListCount(inStream.getInteger());
    }

    writeWaitingPlayersForLobby(outStream: TSDataOutputStream, obj: WaitingPlayersForLobby): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPosition());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getWaitListCount());
    }
    readArjelEndOfTheGameEventReq(inStream: TSDataInputStream, obj: ArjelEndOfTheGameEventReq): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setGameType(inStream.getByte());
        obj.setHandNumber(inStream.getLong());
        obj.setLowerStake(inStream.getLong());
        obj.setRoundsInfo(inStream.getInt2ObjectMap());
        obj.setTournId(inStream.getInteger());
        obj.setUpperStake(inStream.getLong());
    }

    writeArjelEndOfTheGameEventReq(outStream: TSDataOutputStream, obj: ArjelEndOfTheGameEventReq): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putByte(obj.getGameType());
        outStream.putLong(obj.getHandNumber());
        outStream.putLong(obj.getLowerStake());
        outStream.putInt2ObjectMap(obj.getRoundsInfo());
        outStream.putInteger(obj.getTournId());
        outStream.putLong(obj.getUpperStake());
    }
    readArjelEndOfTheGameEventRes(inStream: TSDataInputStream, obj: ArjelEndOfTheGameEventRes): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setIsValidGameData(inStream.getBoolean());
        obj.setRakeDetails(inStream.getShort2LongMap());
        obj.setShowCardsDetails(inStream.getString2IntMap());
        obj.setTourneySessionids(inStream.getShort2LongMap());
        obj.setAllPlayersCards(inStream.getObject());
    }

    writeArjelEndOfTheGameEventRes(outStream: TSDataOutputStream, obj: ArjelEndOfTheGameEventRes): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putBoolean(obj.getIsValidGameData());
        outStream.putShort2LongMap(obj.getRakeDetails());
        outStream.putString2IntMap(obj.getShowCardsDetails());
        outStream.putShort2LongMap(obj.getTourneySessionids());
        outStream.putObject(obj.getAllPlayersCards());
    }
    readArjelBlindsRoundData(inStream: TSDataInputStream, obj: ArjelBlindsRoundData): void {
        obj.setBlindRoundPlayerData(inStream.getInt2ObjectMap());
        obj.setDate(inStream.getLong());
    }

    writeArjelBlindsRoundData(outStream: TSDataOutputStream, obj: ArjelBlindsRoundData): void {
        outStream.putInt2ObjectMap(obj.getBlindRoundPlayerData());
        outStream.putLong(obj.getDate());
    }
    readArjelPlayerCards(inStream: TSDataInputStream, obj: ArjelPlayerCards): void {
        obj.setCards(inStream.getInt2ObjectMap());
    }

    writeArjelPlayerCards(outStream: TSDataOutputStream, obj: ArjelPlayerCards): void {
        outStream.putInt2ObjectMap(obj.getCards());
    }
    readArjelGameAllPlayersAction(inStream: TSDataInputStream, obj: ArjelGameAllPlayersAction): void {
        obj.setActions(inStream.getInt2ObjectMap());
    }

    writeArjelGameAllPlayersAction(outStream: TSDataOutputStream, obj: ArjelGameAllPlayersAction): void {
        outStream.putInt2ObjectMap(obj.getActions());
    }
    readArjelGameLongValue(inStream: TSDataInputStream, obj: ArjelGameLongValue): void {
        obj.setValue(inStream.getLong());
    }

    writeArjelGameLongValue(outStream: TSDataOutputStream, obj: ArjelGameLongValue): void {
        outStream.putLong(obj.getValue());
    }
    readArjelGameCards(inStream: TSDataInputStream, obj: ArjelGameCards): void {
        obj.setCardsDealt(inStream.getString());
        obj.setDate(inStream.getLong());
    }

    writeArjelGameCards(outStream: TSDataOutputStream, obj: ArjelGameCards): void {
        outStream.putString(obj.getCardsDealt());
        outStream.putLong(obj.getDate());
    }
    readArjelGameSummary(inStream: TSDataInputStream, obj: ArjelGameSummary): void {
        obj.setSummary(inStream.getInt2ObjectMap());
    }

    writeArjelGameSummary(outStream: TSDataOutputStream, obj: ArjelGameSummary): void {
        outStream.putInt2ObjectMap(obj.getSummary());
    }
    readArjelBlindsRoundPlayerData(inStream: TSDataInputStream, obj: ArjelBlindsRoundPlayerData): void {
        obj.setAnteAmtIfPosted(inStream.getLong());
        obj.setBlindAmtIfPosted(inStream.getLong());
        obj.setBlindTypeIfPosted(inStream.getInteger());
        obj.setGameStartAmt(inStream.getLong());
        obj.setPlayerId(inStream.getString());
        obj.setSeatNo(inStream.getInteger());
    }

    writeArjelBlindsRoundPlayerData(outStream: TSDataOutputStream, obj: ArjelBlindsRoundPlayerData): void {
        outStream.putLong(obj.getAnteAmtIfPosted());
        outStream.putLong(obj.getBlindAmtIfPosted());
        outStream.putInteger(obj.getBlindTypeIfPosted());
        outStream.putLong(obj.getGameStartAmt());
        outStream.putString(obj.getPlayerId());
        outStream.putInteger(obj.getSeatNo());
    }
    readArjelGamePlayerAction(inStream: TSDataInputStream, obj: ArjelGamePlayerAction): void {
        obj.setAction(inStream.getInteger());
        obj.setActionAmtIfAny(inStream.getLong());
        obj.setDate(inStream.getLong());
        obj.setSeatNo(inStream.getInteger());
    }

    writeArjelGamePlayerAction(outStream: TSDataOutputStream, obj: ArjelGamePlayerAction): void {
        outStream.putInteger(obj.getAction());
        outStream.putLong(obj.getActionAmtIfAny());
        outStream.putLong(obj.getDate());
        outStream.putInteger(obj.getSeatNo());
    }
    readArjelGameSummaryPlayerInfo(inStream: TSDataInputStream, obj: ArjelGameSummaryPlayerInfo): void {
        obj.setHandStrengthCards(inStream.getString());
        obj.setNetAmount(inStream.getLong());
        obj.setTotalBetInGame(inStream.getLong());
        obj.setWon(inStream.getBoolean());
        obj.setInitialBalance(inStream.getLong());
        obj.setEndBlance(inStream.getLong());
        obj.setRake(inStream.getLong());
        obj.setShowCards(inStream.getInteger());
        obj.setShowCardsRound(inStream.getInteger());
    }

    writeArjelGameSummaryPlayerInfo(outStream: TSDataOutputStream, obj: ArjelGameSummaryPlayerInfo): void {
        outStream.putString(obj.getHandStrengthCards());
        outStream.putLong(obj.getNetAmount());
        outStream.putLong(obj.getTotalBetInGame());
        outStream.putBoolean(obj.getWon());
        outStream.putLong(obj.getInitialBalance());
        outStream.putLong(obj.getEndBlance());
        outStream.putLong(obj.getRake());
        outStream.putInteger(obj.getShowCards());
        outStream.putInteger(obj.getShowCardsRound());
    }
    readResponseShowRabbitHunt(inStream: TSDataInputStream, obj: ResponseShowRabbitHunt): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseShowRabbitHunt(outStream: TSDataOutputStream, obj: ResponseShowRabbitHunt): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readFoldNShowOption(inStream: TSDataInputStream, obj: FoldNShowOption): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTimeOut(inStream.getInteger());
    }

    writeFoldNShowOption(outStream: TSDataOutputStream, obj: FoldNShowOption): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTimeOut());
    }
    readResponseFoldNShowFirstCard(inStream: TSDataInputStream, obj: ResponseFoldNShowFirstCard): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseFoldNShowFirstCard(outStream: TSDataOutputStream, obj: ResponseFoldNShowFirstCard): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseFoldNShowSecondCard(inStream: TSDataInputStream, obj: ResponseFoldNShowSecondCard): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseFoldNShowSecondCard(outStream: TSDataOutputStream, obj: ResponseFoldNShowSecondCard): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseFoldNShowBoth(inStream: TSDataInputStream, obj: ResponseFoldNShowBoth): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseFoldNShowBoth(outStream: TSDataOutputStream, obj: ResponseFoldNShowBoth): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readProPlayerList(inStream: TSDataInputStream, obj: ProPlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setProPlayerScreenNames(inStream.getStringArray());
        obj.setProPlayerFullNames(inStream.getStringArray());
    }

    writeProPlayerList(outStream: TSDataOutputStream, obj: ProPlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getProPlayerScreenNames());
        outStream.putStringArray(obj.getProPlayerFullNames());
    }
    readCompositeLobbyMessage(inStream: TSDataInputStream, obj: CompositeLobbyMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessages(inStream.getObjectArray());
    }

    writeCompositeLobbyMessage(outStream: TSDataOutputStream, obj: CompositeLobbyMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getMessages());
    }
    readCompositeTableMessage(inStream: TSDataInputStream, obj: CompositeTableMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessages(inStream.getObjectArray());
    }

    writeCompositeTableMessage(outStream: TSDataOutputStream, obj: CompositeTableMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getMessages());
    }
    readPlayerBalances(inStream: TSDataInputStream, obj: PlayerBalances): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalances(inStream.getByte2LongMap());
    }

    writePlayerBalances(outStream: TSDataOutputStream, obj: PlayerBalances): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte2LongMap(obj.getBalances());
    }
    readPlayerStates(inStream: TSDataInputStream, obj: PlayerStates): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStates(inStream.getByte2IntMap());
    }

    writePlayerStates(outStream: TSDataOutputStream, obj: PlayerStates): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte2IntMap(obj.getStates());
    }
    readBeginnerStatus(inStream: TSDataInputStream, obj: BeginnerStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAllowedAPD(inStream.getInteger());
        obj.setIsFirstLogin(inStream.getBoolean());
        obj.setRemainingAPDs(inStream.getInteger());
    }

    writeBeginnerStatus(outStream: TSDataOutputStream, obj: BeginnerStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAllowedAPD());
        outStream.putBoolean(obj.getIsFirstLogin());
        outStream.putInteger(obj.getRemainingAPDs());
    }
    readLobbyBigBlindChange(inStream: TSDataInputStream, obj: LobbyBigBlindChange): void {
        obj.setBigBlindPosition(inStream.getByte());
        obj.setDealerPosition(inStream.getByte());
        obj.setSmallBlindPosition(inStream.getByte());
    }

    writeLobbyBigBlindChange(outStream: TSDataOutputStream, obj: LobbyBigBlindChange): void {
        outStream.putByte(obj.getBigBlindPosition());
        outStream.putByte(obj.getDealerPosition());
        outStream.putByte(obj.getSmallBlindPosition());
    }
    readDealerSbAndBbPositions(inStream: TSDataInputStream, obj: DealerSbAndBbPositions): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLobbyBigBlindChange(inStream.getObject());
        obj.setTableId(inStream.getInteger());
    }

    writeDealerSbAndBbPositions(outStream: TSDataOutputStream, obj: DealerSbAndBbPositions): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getLobbyBigBlindChange());
        outStream.putInteger(obj.getTableId());
    }
    readResponseBuyMoreChipsInsuffPokerFund(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsInsuffPokerFund): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsInsuffPokerFund(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsInsuffPokerFund): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsInsuffPartnerFund(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsInsuffPartnerFund): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsInsuffPartnerFund(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsInsuffPartnerFund): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsPokerTxnError(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsPokerTxnError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMessageToShow(inStream.getStringEx());
    }

    writeResponseBuyMoreChipsPokerTxnError(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsPokerTxnError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMessageToShow());
    }
    readResponseBuyMoreChipsWalletTxnError(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsWalletTxnError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMessageToShow(inStream.getStringEx());
    }

    writeResponseBuyMoreChipsWalletTxnError(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsWalletTxnError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMessageToShow());
    }
    readUserAllBalance(inStream: TSDataInputStream, obj: UserAllBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPartnerBalanceAC(inStream.getLong());
        obj.setPartnerBalanceGC(inStream.getLong());
        obj.setPartnerPlayBalance(inStream.getLong());
        obj.setPlayMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalanceGC(inStream.getLong());
        obj.setRefreshBalanceTypes(inStream.getByte());
    }

    writeUserAllBalance(outStream: TSDataOutputStream, obj: UserAllBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getPartnerBalanceAC());
        outStream.putLong(obj.getPartnerBalanceGC());
        outStream.putLong(obj.getPartnerPlayBalance());
        outStream.putLong(obj.getPlayMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalanceGC());
        outStream.putByte(obj.getRefreshBalanceTypes());
    }
    readRequestUserBalancePartnerPlayer(inStream: TSDataInputStream, obj: RequestUserBalancePartnerPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestUserBalancePartnerPlayer(outStream: TSDataOutputStream, obj: RequestUserBalancePartnerPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseBuyMoreChipsMultiple(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsMultiple): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsMultiple(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsMultiple): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestCloseClient(inStream: TSDataInputStream, obj: RequestCloseClient): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeRequestCloseClient(outStream: TSDataOutputStream, obj: RequestCloseClient): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readAnonymousPlayerInfo(inStream: TSDataInputStream, obj: AnonymousPlayerInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAnonymousName(inStream.getString());
        obj.setScreenName(inStream.getString());
        obj.setSeatNo(inStream.getInteger());
    }

    writeAnonymousPlayerInfo(outStream: TSDataOutputStream, obj: AnonymousPlayerInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAnonymousName());
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getSeatNo());
    }
    readResponseBetAndWinSummary(inStream: TSDataInputStream, obj: ResponseBetAndWinSummary): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBetAmount(inStream.getLong());
        obj.setGameCurrency(inStream.getString());
        obj.setWinAmount(inStream.getLong());
    }

    writeResponseBetAndWinSummary(outStream: TSDataOutputStream, obj: ResponseBetAndWinSummary): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getBetAmount());
        outStream.putString(obj.getGameCurrency());
        outStream.putLong(obj.getWinAmount());
    }
    readArjelEndOfTheGameSeatData(inStream: TSDataInputStream, obj: ArjelEndOfTheGameSeatData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setHandNumber(inStream.getLong());
        obj.setPlayersInfo(inStream.getInt2ObjectMap());
    }

    writeArjelEndOfTheGameSeatData(outStream: TSDataOutputStream, obj: ArjelEndOfTheGameSeatData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putLong(obj.getHandNumber());
        outStream.putInt2ObjectMap(obj.getPlayersInfo());
    }
    readPoolAvailabilityRequest(inStream: TSDataInputStream, obj: PoolAvailabilityRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrentEntries(inStream.getLongArray());
    }

    writePoolAvailabilityRequest(outStream: TSDataOutputStream, obj: PoolAvailabilityRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLongArray(obj.getCurrentEntries());
    }
    readJoinPoolRequest(inStream: TSDataInputStream, obj: JoinPoolRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setNoOfEntries(inStream.getInteger());
        obj.setBuyInAmount(inStream.getLong());
        obj.setRebuyToMax(inStream.getBoolean());
        obj.setConversationId(inStream.getLong());
    }

    writeJoinPoolRequest(outStream: TSDataOutputStream, obj: JoinPoolRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getNoOfEntries());
        outStream.putLong(obj.getBuyInAmount());
        outStream.putBoolean(obj.getRebuyToMax());
        outStream.putLong(obj.getConversationId());
    }
    readLeavePoolRequest(inStream: TSDataInputStream, obj: LeavePoolRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setConversationId(inStream.getLong());
    }

    writeLeavePoolRequest(outStream: TSDataOutputStream, obj: LeavePoolRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putLong(obj.getConversationId());
    }
    readPoolAvailabilityResponse(inStream: TSDataInputStream, obj: PoolAvailabilityResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserBalance(inStream.getLong());
        obj.setTimeOutAt(inStream.getLong());
        obj.setMessageToClient(inStream.getStringEx());
        obj.setFXConversionFactor(inStream.getObject());
        obj.setPoolAvailabiltyStatus(inStream.getInteger());
        obj.setMaxBuyin(inStream.getInteger());
        obj.setMinBuyin(inStream.getInteger());
        obj.setPoolName(inStream.getStringEx());
        obj.setTableTypeId(inStream.getInteger());
        obj.setGameTypeId(inStream.getInteger());
        obj.setMaxNoOfSeats(inStream.getInteger());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setStakesUpperLimit(inStream.getInteger());
        obj.setTableLimitType(inStream.getInteger());
        obj.setSmallBlind(inStream.getInteger());
        obj.setBigBlind(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setGroupId(inStream.getInteger());
        obj.setHUTabletype(inStream.getInteger());
        obj.setTableCategory(inStream.getByte());
        obj.setAnteCashAmount(inStream.getInteger());
        obj.setGDollarBalance(inStream.getLong());
    }

    writePoolAvailabilityResponse(outStream: TSDataOutputStream, obj: PoolAvailabilityResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getUserBalance());
        outStream.putLong(obj.getTimeOutAt());
        outStream.putStringEx(obj.getMessageToClient());
        outStream.putObject(obj.getFXConversionFactor());
        outStream.putInteger(obj.getPoolAvailabiltyStatus());
        outStream.putInteger(obj.getMaxBuyin());
        outStream.putInteger(obj.getMinBuyin());
        outStream.putStringEx(obj.getPoolName());
        outStream.putInteger(obj.getTableTypeId());
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getMaxNoOfSeats());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getStakesUpperLimit());
        outStream.putInteger(obj.getTableLimitType());
        outStream.putInteger(obj.getSmallBlind());
        outStream.putInteger(obj.getBigBlind());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getGroupId());
        outStream.putInteger(obj.getHUTabletype());
        outStream.putByte(obj.getTableCategory());
        outStream.putInteger(obj.getAnteCashAmount());
        outStream.putLong(obj.getGDollarBalance());
    }
    readJoinPoolResponse(inStream: TSDataInputStream, obj: JoinPoolResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserBalance(inStream.getLong());
        obj.setNoOfEntriesRejected(inStream.getInteger());
        obj.setEntryIds(inStream.getLongArray());
        obj.setPlayerStatus(inStream.getObject());
        obj.setMessageToClient(inStream.getStringEx());
        obj.setFXConversionFactor(inStream.getObject());
        obj.setPoolJoinStatus(inStream.getInteger());
        obj.setTrackerIdList(inStream.getLongArray());
    }

    writeJoinPoolResponse(outStream: TSDataOutputStream, obj: JoinPoolResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getUserBalance());
        outStream.putInteger(obj.getNoOfEntriesRejected());
        outStream.putLongArray(obj.getEntryIds());
        outStream.putObject(obj.getPlayerStatus());
        outStream.putStringEx(obj.getMessageToClient());
        outStream.putObject(obj.getFXConversionFactor());
        outStream.putInteger(obj.getPoolJoinStatus());
        outStream.putLongArray(obj.getTrackerIdList());
    }
    readReqQuickFoldToBetOrRaiseOn(inStream: TSDataInputStream, obj: ReqQuickFoldToBetOrRaiseOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReqQuickFoldToBetOrRaiseOn(outStream: TSDataOutputStream, obj: ReqQuickFoldToBetOrRaiseOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readReqQuickFoldToBetOrRaiseOff(inStream: TSDataInputStream, obj: ReqQuickFoldToBetOrRaiseOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReqQuickFoldToBetOrRaiseOff(outStream: TSDataOutputStream, obj: ReqQuickFoldToBetOrRaiseOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readReqSitoutNextBlindOn(inStream: TSDataInputStream, obj: ReqSitoutNextBlindOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReqSitoutNextBlindOn(outStream: TSDataOutputStream, obj: ReqSitoutNextBlindOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readReqSitoutNextBlindOff(inStream: TSDataInputStream, obj: ReqSitoutNextBlindOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReqSitoutNextBlindOff(outStream: TSDataOutputStream, obj: ReqSitoutNextBlindOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestFoldAndWatchOn(inStream: TSDataInputStream, obj: RequestFoldAndWatchOn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestFoldAndWatchOn(outStream: TSDataOutputStream, obj: RequestFoldAndWatchOn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestFoldAndWatchOff(inStream: TSDataInputStream, obj: RequestFoldAndWatchOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestFoldAndWatchOff(outStream: TSDataOutputStream, obj: RequestFoldAndWatchOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readPoolRebuyRequest(inStream: TSDataInputStream, obj: PoolRebuyRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setEntryId(inStream.getLong());
        obj.setConversationId(inStream.getLong());
    }

    writePoolRebuyRequest(outStream: TSDataOutputStream, obj: PoolRebuyRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putLong(obj.getEntryId());
        outStream.putLong(obj.getConversationId());
    }
    readPoolRebuyResponse(inStream: TSDataInputStream, obj: PoolRebuyResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserBalance(inStream.getLong());
        obj.setMessageToClient(inStream.getStringEx());
        obj.setFXConversionFactor(inStream.getObject());
        obj.setRebuyStatus(inStream.getInteger());
        obj.setRebuyAmount(inStream.getLong());
        obj.setEntryId(inStream.getLong());
    }

    writePoolRebuyResponse(outStream: TSDataOutputStream, obj: PoolRebuyResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getUserBalance());
        outStream.putStringEx(obj.getMessageToClient());
        outStream.putObject(obj.getFXConversionFactor());
        outStream.putInteger(obj.getRebuyStatus());
        outStream.putLong(obj.getRebuyAmount());
        outStream.putLong(obj.getEntryId());
    }
    readAddPlayMoneyToPoolRequest(inStream: TSDataInputStream, obj: AddPlayMoneyToPoolRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writeAddPlayMoneyToPoolRequest(outStream: TSDataOutputStream, obj: AddPlayMoneyToPoolRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readAddPlayMoneyToPoolResponse(inStream: TSDataInputStream, obj: AddPlayMoneyToPoolResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUpdatedPlayMoney(inStream.getLong());
        obj.setMessageToClient(inStream.getStringEx());
        obj.setAddPlayMoneyStatus(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writeAddPlayMoneyToPoolResponse(outStream: TSDataOutputStream, obj: AddPlayMoneyToPoolResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getUpdatedPlayMoney());
        outStream.putStringEx(obj.getMessageToClient());
        outStream.putInteger(obj.getAddPlayMoneyStatus());
        outStream.putLong(obj.getEntryId());
    }
    readBackToPoolRequest(inStream: TSDataInputStream, obj: BackToPoolRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writeBackToPoolRequest(outStream: TSDataOutputStream, obj: BackToPoolRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readPoolStartGameNotification(inStream: TSDataInputStream, obj: PoolStartGameNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setTableId(inStream.getInteger());
    }

    writePoolStartGameNotification(outStream: TSDataOutputStream, obj: PoolStartGameNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putInteger(obj.getTableId());
    }
    readPoolWaitingNotification(inStream: TSDataInputStream, obj: PoolWaitingNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writePoolWaitingNotification(outStream: TSDataOutputStream, obj: PoolWaitingNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readPoolEndGameNotification(inStream: TSDataInputStream, obj: PoolEndGameNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setTableId(inStream.getInteger());
    }

    writePoolEndGameNotification(outStream: TSDataOutputStream, obj: PoolEndGameNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putInteger(obj.getTableId());
    }
    readPoolSitOutNotification(inStream: TSDataInputStream, obj: PoolSitOutNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writePoolSitOutNotification(outStream: TSDataOutputStream, obj: PoolSitOutNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readPoolKickoutNotification(inStream: TSDataInputStream, obj: PoolKickoutNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setMessageToClient(inStream.getStringEx());
    }

    writePoolKickoutNotification(outStream: TSDataOutputStream, obj: PoolKickoutNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putStringEx(obj.getMessageToClient());
    }
    readReqNextHandOffFoldAndWatch(inStream: TSDataInputStream, obj: ReqNextHandOffFoldAndWatch): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReqNextHandOffFoldAndWatch(outStream: TSDataOutputStream, obj: ReqNextHandOffFoldAndWatch): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readPoolRequestUserBalance(inStream: TSDataInputStream, obj: PoolRequestUserBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writePoolRequestUserBalance(outStream: TSDataOutputStream, obj: PoolRequestUserBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readPoolUserBalance(inStream: TSDataInputStream, obj: PoolUserBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalanceGC(inStream.getLong());
        obj.setEntryId(inStream.getLong());
        obj.setBalanceFor(inStream.getByte());
        obj.setGDollarBalanceGC(inStream.getLong());
    }

    writePoolUserBalance(outStream: TSDataOutputStream, obj: PoolUserBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getPlayMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalanceGC());
        outStream.putLong(obj.getEntryId());
        outStream.putByte(obj.getBalanceFor());
        outStream.putLong(obj.getGDollarBalanceGC());
    }
    readReqNextHandOnFoldAndWatch(inStream: TSDataInputStream, obj: ReqNextHandOnFoldAndWatch): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReqNextHandOnFoldAndWatch(outStream: TSDataOutputStream, obj: ReqNextHandOnFoldAndWatch): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestEndOfTheGameFastPoker(inStream: TSDataInputStream, obj: RequestEndOfTheGameFastPoker): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestEndOfTheGameFastPoker(outStream: TSDataOutputStream, obj: RequestEndOfTheGameFastPoker): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readPoolRequestUserReconnect(inStream: TSDataInputStream, obj: PoolRequestUserReconnect): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryIds(inStream.getLongArray());
    }

    writePoolRequestUserReconnect(outStream: TSDataOutputStream, obj: PoolRequestUserReconnect): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLongArray(obj.getEntryIds());
    }
    readPoolUserReconnectResponse(inStream: TSDataInputStream, obj: PoolUserReconnectResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerEntryStatuses(inStream.getObjectArray());
    }

    writePoolUserReconnectResponse(outStream: TSDataOutputStream, obj: PoolUserReconnectResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPlayerEntryStatuses());
    }
    readPlayerEntryStatus(inStream: TSDataInputStream, obj: PlayerEntryStatus): void {
        obj.setPlayerEntryId(inStream.getLong());
        obj.setEntryStatus(inStream.getInteger());
        obj.setActiveTableId(inStream.getInteger());
        obj.setBalance(inStream.getLong());
    }

    writePlayerEntryStatus(outStream: TSDataOutputStream, obj: PlayerEntryStatus): void {
        outStream.putLong(obj.getPlayerEntryId());
        outStream.putInteger(obj.getEntryStatus());
        outStream.putInteger(obj.getActiveTableId());
        outStream.putLong(obj.getBalance());
    }
    readPoolShutdownNotification(inStream: TSDataInputStream, obj: PoolShutdownNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setMessageToClient(inStream.getStringEx());
    }

    writePoolShutdownNotification(outStream: TSDataOutputStream, obj: PoolShutdownNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putStringEx(obj.getMessageToClient());
    }
    readShowScreenOnPool(inStream: TSDataInputStream, obj: ShowScreenOnPool): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setScreenType(inStream.getInteger());
        obj.setAlertMessageToShow(inStream.getStringEx());
        obj.setAlertMessageType(inStream.getInteger());
        obj.setTitleMessage(inStream.getStringEx());
        obj.setIsMandatory(inStream.getBoolean());
        obj.setFailureMessage(inStream.getStringEx());
        obj.setCancelMessage(inStream.getStringEx());
    }

    writeShowScreenOnPool(outStream: TSDataOutputStream, obj: ShowScreenOnPool): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putInteger(obj.getScreenType());
        outStream.putStringEx(obj.getAlertMessageToShow());
        outStream.putInteger(obj.getAlertMessageType());
        outStream.putStringEx(obj.getTitleMessage());
        outStream.putBoolean(obj.getIsMandatory());
        outStream.putStringEx(obj.getFailureMessage());
        outStream.putStringEx(obj.getCancelMessage());
    }
    readPrevPlayingFastPokerPools(inStream: TSDataInputStream, obj: PrevPlayingFastPokerPools): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryIds(inStream.getLongArray());
        obj.setFastPoolId(inStream.getInteger());
        obj.setPlayerStatus(inStream.getObject());
        obj.setPoolDomain(inStream.getString());
        obj.setFXConversionFactor(inStream.getObject());
        obj.setMaxBuyin(inStream.getInteger());
        obj.setMinBuyin(inStream.getInteger());
        obj.setPoolName(inStream.getStringEx());
        obj.setTableTypeId(inStream.getInteger());
        obj.setGameTypeId(inStream.getInteger());
        obj.setMaxNoOfSeats(inStream.getInteger());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setStakesUpperLimit(inStream.getInteger());
        obj.setTableLimitType(inStream.getInteger());
        obj.setSmallBlind(inStream.getInteger());
        obj.setBigBlind(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setGroupId(inStream.getInteger());
        obj.setHUTabletype(inStream.getInteger());
        obj.setTableCategory(inStream.getByte());
    }

    writePrevPlayingFastPokerPools(outStream: TSDataOutputStream, obj: PrevPlayingFastPokerPools): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLongArray(obj.getEntryIds());
        outStream.putInteger(obj.getFastPoolId());
        outStream.putObject(obj.getPlayerStatus());
        outStream.putString(obj.getPoolDomain());
        outStream.putObject(obj.getFXConversionFactor());
        outStream.putInteger(obj.getMaxBuyin());
        outStream.putInteger(obj.getMinBuyin());
        outStream.putStringEx(obj.getPoolName());
        outStream.putInteger(obj.getTableTypeId());
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getMaxNoOfSeats());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getStakesUpperLimit());
        outStream.putInteger(obj.getTableLimitType());
        outStream.putInteger(obj.getSmallBlind());
        outStream.putInteger(obj.getBigBlind());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getGroupId());
        outStream.putInteger(obj.getHUTabletype());
        outStream.putByte(obj.getTableCategory());
    }
    readPoolFraudPlayerKickoutNotification(inStream: TSDataInputStream, obj: PoolFraudPlayerKickoutNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writePoolFraudPlayerKickoutNotification(outStream: TSDataOutputStream, obj: PoolFraudPlayerKickoutNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readQFToAnyBetPlayerQuickFolded(inStream: TSDataInputStream, obj: QFToAnyBetPlayerQuickFolded): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeQFToAnyBetPlayerQuickFolded(outStream: TSDataOutputStream, obj: QFToAnyBetPlayerQuickFolded): void {
        outStream.putInteger(obj.getMsgNumber());
    }

    readRequestPoolSitout(inStream: TSDataInputStream, obj: RequestPoolSitout): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writeRequestPoolSitout(outStream: TSDataOutputStream, obj: RequestPoolSitout): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }

    readHyperLinkedDealerChat(inStream: TSDataInputStream, obj: HyperLinkedDealerChat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSender(inStream.getString());
        obj.setType(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
        obj.setTagId(inStream.getByte());
        obj.setTagAction(inStream.getString());
        obj.setTableName(inStream.getStringEx());
    }

    writeHyperLinkedDealerChat(outStream: TSDataOutputStream, obj: HyperLinkedDealerChat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getSender());
        outStream.putInteger(obj.getType());
        outStream.putStringEx(obj.getMessage());
        outStream.putByte(obj.getTagId());
        outStream.putString(obj.getTagAction());
        outStream.putStringEx(obj.getTableName());
    }
    readResponseRebuyAddonAccClosureFailure(inStream: TSDataInputStream, obj: ResponseRebuyAddonAccClosureFailure): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRebuyAddonAccountClosureFailureMessage(inStream.getStringEx());
        obj.setResponseId(inStream.getInteger());
    }

    writeResponseRebuyAddonAccClosureFailure(outStream: TSDataOutputStream, obj: ResponseRebuyAddonAccClosureFailure): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getRebuyAddonAccountClosureFailureMessage());
        outStream.putInteger(obj.getResponseId());
    }
    readRecommendedStakeLimit(inStream: TSDataInputStream, obj: RecommendedStakeLimit): void {
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setValue(inStream.getInteger());
    }

    writeRecommendedStakeLimit(outStream: TSDataOutputStream, obj: RecommendedStakeLimit): void {
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getValue());
    }
    readRecommendedStakesMessage(inStream: TSDataInputStream, obj: RecommendedStakesMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRecommendedStakesLimits(inStream.getObjectArray());
    }

    writeRecommendedStakesMessage(outStream: TSDataOutputStream, obj: RecommendedStakesMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getRecommendedStakesLimits());
    }
    readPokerPlayerProtectionInfo(inStream: TSDataInputStream, obj: PokerPlayerProtectionInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setProtectionLevel(inStream.getInteger());
    }

    writePokerPlayerProtectionInfo(outStream: TSDataOutputStream, obj: PokerPlayerProtectionInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getProtectionLevel());
    }
    readRequestJoinTableWaitlist(inStream: TSDataInputStream, obj: RequestJoinTableWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestJoinTableWaitlist(outStream: TSDataOutputStream, obj: RequestJoinTableWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseJoinTableWaitlist(inStream: TSDataInputStream, obj: ResponseJoinTableWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
        obj.setTableId(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
    }

    writeResponseJoinTableWaitlist(outStream: TSDataOutputStream, obj: ResponseJoinTableWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getStatus());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getResponseId());
    }
    readRequestUnjoinTableWaitlist(inStream: TSDataInputStream, obj: RequestUnjoinTableWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestUnjoinTableWaitlist(outStream: TSDataOutputStream, obj: RequestUnjoinTableWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseUnjoinTableWaitlist(inStream: TSDataInputStream, obj: ResponseUnjoinTableWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
        obj.setTableId(inStream.getInteger());
    }

    writeResponseUnjoinTableWaitlist(outStream: TSDataOutputStream, obj: ResponseUnjoinTableWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getStatus());
        outStream.putInteger(obj.getTableId());
    }
    readGlobalWaitlistCriteria(inStream: TSDataInputStream, obj: GlobalWaitlistCriteria): void {
        obj.setGameTypeId(inStream.getByte());
        obj.setTableTypeId(inStream.getByte());
        obj.setGamePlayType(inStream.getInteger());
        obj.setTableLimitType(inStream.getByte());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setStakesUpperLimit(inStream.getInteger());
        obj.setMinPlayers(inStream.getByte());
        obj.setMaxSeats(inStream.getByte());
        obj.setDpAny(inStream.getInteger());
        obj.setIsBeginner(inStream.getBoolean());
        obj.setIncludeAnteCashGames(inStream.getBoolean());
    }

    writeGlobalWaitlistCriteria(outStream: TSDataOutputStream, obj: GlobalWaitlistCriteria): void {
        outStream.putByte(obj.getGameTypeId());
        outStream.putByte(obj.getTableTypeId());
        outStream.putInteger(obj.getGamePlayType());
        outStream.putByte(obj.getTableLimitType());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getStakesUpperLimit());
        outStream.putByte(obj.getMinPlayers());
        outStream.putByte(obj.getMaxSeats());
        outStream.putInteger(obj.getDpAny());
        outStream.putBoolean(obj.getIsBeginner());
        outStream.putBoolean(obj.getIncludeAnteCashGames());
    }
    readRequestJoinGlobalWaitlist(inStream: TSDataInputStream, obj: RequestJoinGlobalWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setWaitlistCriteria(inStream.getObject());
        obj.setRequestId(inStream.getInteger());
    }

    writeRequestJoinGlobalWaitlist(outStream: TSDataOutputStream, obj: RequestJoinGlobalWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getWaitlistCriteria());
        outStream.putInteger(obj.getRequestId());
    }
    readResponseJoinGlobalWaitlist(inStream: TSDataInputStream, obj: ResponseJoinGlobalWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
        obj.setRequestId(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
    }

    writeResponseJoinGlobalWaitlist(outStream: TSDataOutputStream, obj: ResponseJoinGlobalWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getStatus());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getResponseId());
    }
    readRequestUnjoinGlobalWaitlist(inStream: TSDataInputStream, obj: RequestUnjoinGlobalWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setWaitlistCriteria(inStream.getObject());
        obj.setRequestId(inStream.getInteger());
    }

    writeRequestUnjoinGlobalWaitlist(outStream: TSDataOutputStream, obj: RequestUnjoinGlobalWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getWaitlistCriteria());
        outStream.putInteger(obj.getRequestId());
    }
    readResponseUnjoinGlobalWaitlist(inStream: TSDataInputStream, obj: ResponseUnjoinGlobalWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
        obj.setRequestId(inStream.getInteger());
    }

    writeResponseUnjoinGlobalWaitlist(outStream: TSDataOutputStream, obj: ResponseUnjoinGlobalWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getStatus());
        outStream.putInteger(obj.getRequestId());
    }
    readRequestJoinOtherDomainWaitlist(inStream: TSDataInputStream, obj: RequestJoinOtherDomainWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableid(inStream.getInteger());
    }

    writeRequestJoinOtherDomainWaitlist(outStream: TSDataOutputStream, obj: RequestJoinOtherDomainWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableid());
    }
    readResponseJoinOtherDomainWaitlist(inStream: TSDataInputStream, obj: ResponseJoinOtherDomainWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
    }

    writeResponseJoinOtherDomainWaitlist(outStream: TSDataOutputStream, obj: ResponseJoinOtherDomainWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putBoolean(obj.getStatus());
    }
    readRequestUnjoinOtherDomainWaitlist(inStream: TSDataInputStream, obj: RequestUnjoinOtherDomainWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableid(inStream.getInteger());
    }

    writeRequestUnjoinOtherDomainWaitlist(outStream: TSDataOutputStream, obj: RequestUnjoinOtherDomainWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableid());
    }
    readResponseUnjoinOtherDomainWaitlist(inStream: TSDataInputStream, obj: ResponseUnjoinOtherDomainWaitlist): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
    }

    writeResponseUnjoinOtherDomainWaitlist(outStream: TSDataOutputStream, obj: ResponseUnjoinOtherDomainWaitlist): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putBoolean(obj.getStatus());
    }
    readArjelGTPlayerData(inStream: TSDataInputStream, obj: ArjelGTPlayerData): void {
        obj.setPlayerAccountName(inStream.getString());
        obj.setPlayerFrontEndId(inStream.getString());
        obj.setPlayerIp(inStream.getString());
        obj.setSessionId(inStream.getLong());
        obj.setTrnyIbsSessionId(inStream.getLong());
    }

    writeArjelGTPlayerData(outStream: TSDataOutputStream, obj: ArjelGTPlayerData): void {
        outStream.putString(obj.getPlayerAccountName());
        outStream.putString(obj.getPlayerFrontEndId());
        outStream.putString(obj.getPlayerIp());
        outStream.putLong(obj.getSessionId());
        outStream.putLong(obj.getTrnyIbsSessionId());
    }
    readChallengeDetails(inStream: TSDataInputStream, obj: ChallengeDetails): void {
        obj.setChallengeId(inStream.getInteger());
        obj.setCreator(inStream.getString());
        obj.setChallengeType(inStream.getInteger());
        obj.setInvitees(inStream.getStringArray());
    }

    writeChallengeDetails(outStream: TSDataOutputStream, obj: ChallengeDetails): void {
        outStream.putInteger(obj.getChallengeId());
        outStream.putString(obj.getCreator());
        outStream.putInteger(obj.getChallengeType());
        outStream.putStringArray(obj.getInvitees());
    }
    readAcceptChallengeRequest(inStream: TSDataInputStream, obj: AcceptChallengeRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
    }

    writeAcceptChallengeRequest(outStream: TSDataOutputStream, obj: AcceptChallengeRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
    }
    readAcceptChallengeResponse(inStream: TSDataInputStream, obj: AcceptChallengeResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setGameTableStatus(inStream.getObject());
        obj.setChallengeDetails(inStream.getObject());
    }

    writeAcceptChallengeResponse(outStream: TSDataOutputStream, obj: AcceptChallengeResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
        outStream.putInteger(obj.getErrorCode());
        outStream.putObject(obj.getGameTableStatus());
        outStream.putObject(obj.getChallengeDetails());
    }
    readCreateChallengeHUCashRequest(inStream: TSDataInputStream, obj: CreateChallengeHUCashRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setIsDefaultMessage(inStream.getBoolean());
        obj.setMessage(inStream.getString());
        obj.setInvitee(inStream.getString());
        obj.setChallengeId(inStream.getInteger());
    }

    writeCreateChallengeHUCashRequest(outStream: TSDataOutputStream, obj: CreateChallengeHUCashRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putBoolean(obj.getIsDefaultMessage());
        outStream.putString(obj.getMessage());
        outStream.putString(obj.getInvitee());
        outStream.putInteger(obj.getChallengeId());
    }
    readCreateChallengeHUCashResponse(inStream: TSDataInputStream, obj: CreateChallengeHUCashResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setGameTableStatus(inStream.getObject());
        obj.setInvitee(inStream.getString());
    }

    writeCreateChallengeHUCashResponse(outStream: TSDataOutputStream, obj: CreateChallengeHUCashResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getErrorCode());
        outStream.putObject(obj.getGameTableStatus());
        outStream.putString(obj.getInvitee());
    }
    readCreateChallengeHUSNGRequest(inStream: TSDataInputStream, obj: CreateChallengeHUSNGRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setFee(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setSpeed(inStream.getInteger());
        obj.setIsDefaultMessage(inStream.getBoolean());
        obj.setMessage(inStream.getString());
        obj.setInvitee(inStream.getString());
        obj.setChallengeId(inStream.getInteger());
    }

    writeCreateChallengeHUSNGRequest(outStream: TSDataOutputStream, obj: CreateChallengeHUSNGRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getFee());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getSpeed());
        outStream.putBoolean(obj.getIsDefaultMessage());
        outStream.putString(obj.getMessage());
        outStream.putString(obj.getInvitee());
        outStream.putInteger(obj.getChallengeId());
    }
    readCreateChallengeHUSNGResponse(inStream: TSDataInputStream, obj: CreateChallengeHUSNGResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setChallengeDetails(inStream.getObject());
    }

    writeCreateChallengeHUSNGResponse(outStream: TSDataOutputStream, obj: CreateChallengeHUSNGResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getErrorCode());
        outStream.putObject(obj.getChallengeDetails());
    }
    readRejectChallengeRequest(inStream: TSDataInputStream, obj: RejectChallengeRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
    }

    writeRejectChallengeRequest(outStream: TSDataOutputStream, obj: RejectChallengeRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
    }
    readRejectChallengeResponse(inStream: TSDataInputStream, obj: RejectChallengeResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
    }

    writeRejectChallengeResponse(outStream: TSDataOutputStream, obj: RejectChallengeResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
        outStream.putInteger(obj.getErrorCode());
    }
    readChallengeInvitationRequest(inStream: TSDataInputStream, obj: ChallengeInvitationRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeChallengeInvitationRequest(outStream: TSDataOutputStream, obj: ChallengeInvitationRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
        outStream.putStringEx(obj.getMsg());
    }
    readChallengeCancelledMessage(inStream: TSDataInputStream, obj: ChallengeCancelledMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
    }

    writeChallengeCancelledMessage(outStream: TSDataOutputStream, obj: ChallengeCancelledMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
        outStream.putInteger(obj.getTableId());
        outStream.putStringEx(obj.getMessage());
    }
    readChallengeHistoryData(inStream: TSDataInputStream, obj: ChallengeHistoryData): void {
        obj.setChallengeId(inStream.getInteger());
        obj.setChallengeType(inStream.getInteger());
        obj.setChallengeStatus(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setLStakeCurrencyAmount(inStream.getObject());
        obj.setUStakeCurrencyAmount(inStream.getObject());
        obj.setBuyinCurrencyAmount(inStream.getObject());
        obj.setWinCurrencyAmount(inStream.getObject());
        obj.setOpponentWinCurrencyAmount(inStream.getObject());
        obj.setMaxSeat(inStream.getInteger());
        obj.setOccupiedSeat(inStream.getInteger());
        obj.setOpponent(inStream.getString());
        obj.setIsSender(inStream.getBoolean());
        obj.setCreatorName(inStream.getString());
        obj.setTableId(inStream.getInteger());
    }

    writeChallengeHistoryData(outStream: TSDataOutputStream, obj: ChallengeHistoryData): void {
        outStream.putInteger(obj.getChallengeId());
        outStream.putInteger(obj.getChallengeType());
        outStream.putInteger(obj.getChallengeStatus());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putObject(obj.getLStakeCurrencyAmount());
        outStream.putObject(obj.getUStakeCurrencyAmount());
        outStream.putObject(obj.getBuyinCurrencyAmount());
        outStream.putObject(obj.getWinCurrencyAmount());
        outStream.putObject(obj.getOpponentWinCurrencyAmount());
        outStream.putInteger(obj.getMaxSeat());
        outStream.putInteger(obj.getOccupiedSeat());
        outStream.putString(obj.getOpponent());
        outStream.putBoolean(obj.getIsSender());
        outStream.putString(obj.getCreatorName());
        outStream.putInteger(obj.getTableId());
    }
    readChallengeHistoryRequest(inStream: TSDataInputStream, obj: ChallengeHistoryRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeType(inStream.getInteger());
        obj.setIsSender(inStream.getBoolean());
        obj.setIsRegistering(inStream.getBoolean());
        obj.setIsPlaying(inStream.getBoolean());
        obj.setIsCompleted(inStream.getBoolean());
    }

    writeChallengeHistoryRequest(outStream: TSDataOutputStream, obj: ChallengeHistoryRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeType());
        outStream.putBoolean(obj.getIsSender());
        outStream.putBoolean(obj.getIsRegistering());
        outStream.putBoolean(obj.getIsPlaying());
        outStream.putBoolean(obj.getIsCompleted());
    }
    readChallengeHistoryResponse(inStream: TSDataInputStream, obj: ChallengeHistoryResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
        obj.setChallengeHistoryDataList(inStream.getObjectArray());
    }

    writeChallengeHistoryResponse(outStream: TSDataOutputStream, obj: ChallengeHistoryResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseCode());
        outStream.putObjectArray(obj.getChallengeHistoryDataList());
    }
    readChallengeTemplate(inStream: TSDataInputStream, obj: ChallengeTemplate): void {
        obj.setGameTypeId(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setCaStakesOrBlindsLowerLimit(inStream.getObject());
        obj.setCaStakesOrBlindsHigherLimit(inStream.getObject());
        obj.setCaBuyIn(inStream.getObject());
        obj.setTableInfoId(inStream.getInteger());
        obj.setSpeed(inStream.getInteger());
        obj.setCaFee(inStream.getObject());
        obj.setMaxSeats(inStream.getInteger());
        obj.setMaxBuyin(inStream.getInteger());
    }

    writeChallengeTemplate(outStream: TSDataOutputStream, obj: ChallengeTemplate): void {
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getLimitType());
        outStream.putObject(obj.getCaStakesOrBlindsLowerLimit());
        outStream.putObject(obj.getCaStakesOrBlindsHigherLimit());
        outStream.putObject(obj.getCaBuyIn());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putInteger(obj.getSpeed());
        outStream.putObject(obj.getCaFee());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getMaxBuyin());
    }
    readChallengeTemplatesList(inStream: TSDataInputStream, obj: ChallengeTemplatesList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setHuCashChallengeTemplatesList(inStream.getObjectArray());
        obj.setHuSNGChallengeTemplatesList(inStream.getObjectArray());
        obj.setPrivateTablesTemplatesList(inStream.getObjectArray());
    }

    writeChallengeTemplatesList(outStream: TSDataOutputStream, obj: ChallengeTemplatesList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getHuCashChallengeTemplatesList());
        outStream.putObjectArray(obj.getHuSNGChallengeTemplatesList());
        outStream.putObjectArray(obj.getPrivateTablesTemplatesList());
    }
    readRequestListOfChallengeTemplates(inStream: TSDataInputStream, obj: RequestListOfChallengeTemplates): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestListOfChallengeTemplates(outStream: TSDataOutputStream, obj: RequestListOfChallengeTemplates): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readDeletePrivateTableRequest(inStream: TSDataInputStream, obj: DeletePrivateTableRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
    }

    writeDeletePrivateTableRequest(outStream: TSDataOutputStream, obj: DeletePrivateTableRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getChallengeId());
    }
    readDeletePrivateTableResponse(inStream: TSDataInputStream, obj: DeletePrivateTableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableDeleted(inStream.getBoolean());
        obj.setTableId(inStream.getInteger());
    }

    writeDeletePrivateTableResponse(outStream: TSDataOutputStream, obj: DeletePrivateTableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getTableDeleted());
        outStream.putInteger(obj.getTableId());
    }
    readPrivateTableInvitationRequest(inStream: TSDataInputStream, obj: PrivateTableInvitationRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChallengeId(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
        obj.setGameTableStatus(inStream.getObject());
    }

    writePrivateTableInvitationRequest(outStream: TSDataOutputStream, obj: PrivateTableInvitationRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getChallengeId());
        outStream.putStringEx(obj.getMsg());
        outStream.putObject(obj.getGameTableStatus());
    }
    readCreatePrivateTableResponse(inStream: TSDataInputStream, obj: CreatePrivateTableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setGameTableStatus(inStream.getObject());
    }

    writeCreatePrivateTableResponse(outStream: TSDataOutputStream, obj: CreatePrivateTableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getErrorCode());
        outStream.putObject(obj.getGameTableStatus());
    }
    readCreatePrivateTableRequest(inStream: TSDataInputStream, obj: CreatePrivateTableRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setIsDefaultMessage(inStream.getBoolean());
        obj.setMessage(inStream.getString());
        obj.setInvitee(inStream.getStringArray());
        obj.setPassword(inStream.getString());
    }

    writeCreatePrivateTableRequest(outStream: TSDataOutputStream, obj: CreatePrivateTableRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putBoolean(obj.getIsDefaultMessage());
        outStream.putString(obj.getMessage());
        outStream.putStringArray(obj.getInvitee());
        outStream.putString(obj.getPassword());
    }
    readNotifyPrivateTableCreator(inStream: TSDataInputStream, obj: NotifyPrivateTableCreator): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setInvitee(inStream.getString());
    }

    writeNotifyPrivateTableCreator(outStream: TSDataOutputStream, obj: NotifyPrivateTableCreator): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putString(obj.getInvitee());
    }
    readHUSNGRankResultWithRematchRequest(inStream: TSDataInputStream, obj: HUSNGRankResultWithRematchRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setHuRematchDialogueTime(inStream.getInteger());
        obj.setTargetSNGId(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setTournamentName(inStream.getStringEx());
        obj.setBuyIn(inStream.getInteger());
        obj.setFee(inStream.getInteger());
        obj.setCurrentUserScreenName(inStream.getString());
        obj.setOtherUserScreenName(inStream.getString());
        obj.setRankInfo(inStream.getStringExArray());
        obj.setHasTouneyTicket(inStream.getBoolean());
        obj.setBuyinType(inStream.getByte());
        obj.setRank(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setTicketType(inStream.getByte());
    }

    writeHUSNGRankResultWithRematchRequest(outStream: TSDataOutputStream, obj: HUSNGRankResultWithRematchRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getHuRematchDialogueTime());
        outStream.putInteger(obj.getTargetSNGId());
        outStream.putInteger(obj.getGameType());
        outStream.putStringEx(obj.getTournamentName());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getFee());
        outStream.putString(obj.getCurrentUserScreenName());
        outStream.putString(obj.getOtherUserScreenName());
        outStream.putStringExArray(obj.getRankInfo());
        outStream.putBoolean(obj.getHasTouneyTicket());
        outStream.putByte(obj.getBuyinType());
        outStream.putInteger(obj.getRank());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putByte(obj.getTicketType());
    }
    readRequestHUSNGRematchAccepted(inStream: TSDataInputStream, obj: RequestHUSNGRematchAccepted): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUseTourneyTicket(inStream.getByte());
    }

    writeRequestHUSNGRematchAccepted(outStream: TSDataOutputStream, obj: RequestHUSNGRematchAccepted): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getUseTourneyTicket());
    }
    readRequestHUSNGRematchRejected(inStream: TSDataInputStream, obj: RequestHUSNGRematchRejected): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestHUSNGRematchRejected(outStream: TSDataOutputStream, obj: RequestHUSNGRematchRejected): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestHUSNGRematchAcceptedAck(inStream: TSDataInputStream, obj: RequestHUSNGRematchAcceptedAck): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTargetSNGId(inStream.getInteger());
    }

    writeRequestHUSNGRematchAcceptedAck(outStream: TSDataOutputStream, obj: RequestHUSNGRematchAcceptedAck): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTargetSNGId());
    }
    readResponseHUSNGRematchAccepted(inStream: TSDataInputStream, obj: ResponseHUSNGRematchAccepted): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTargetSNGId(inStream.getInteger());
        obj.setRematchMTCTId(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setDomain(inStream.getInteger());
        obj.setFxSnapshotId(inStream.getLong());
    }

    writeResponseHUSNGRematchAccepted(outStream: TSDataOutputStream, obj: ResponseHUSNGRematchAccepted): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTargetSNGId());
        outStream.putInteger(obj.getRematchMTCTId());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getDomain());
        outStream.putLong(obj.getFxSnapshotId());
    }
    readResponseHUSNGRematchRejected(inStream: TSDataInputStream, obj: ResponseHUSNGRematchRejected): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTargetSNGId(inStream.getInteger());
    }

    writeResponseHUSNGRematchRejected(outStream: TSDataOutputStream, obj: ResponseHUSNGRematchRejected): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTargetSNGId());
    }
    readResponseHUSNGRematchTimeout(inStream: TSDataInputStream, obj: ResponseHUSNGRematchTimeout): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTargetSNGId(inStream.getInteger());
    }

    writeResponseHUSNGRematchTimeout(outStream: TSDataOutputStream, obj: ResponseHUSNGRematchTimeout): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTargetSNGId());
    }
    readResponseHUSNGRematchBuyInFailed(inStream: TSDataInputStream, obj: ResponseHUSNGRematchBuyInFailed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTargetSNGId(inStream.getInteger());
    }

    writeResponseHUSNGRematchBuyInFailed(outStream: TSDataOutputStream, obj: ResponseHUSNGRematchBuyInFailed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTargetSNGId());
    }
    readSNGRankResultWithReplayRequest(inStream: TSDataInputStream, obj: SNGRankResultWithReplayRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTemplateId(inStream.getInteger());
        obj.setBuyInType(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setFee(inStream.getInteger());
        obj.setTournamentName(inStream.getStringEx());
        obj.setGameType(inStream.getInteger());
        obj.setRankInfo(inStream.getStringExArray());
        obj.setRank(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setNumberOfTourneyTickets(inStream.getInteger());
        obj.setAccBalanceGC(inStream.getLong());
        obj.setFxSnapshotID(inStream.getLong());
        obj.setFxDetails(inStream.getObject());
        obj.setTicketType(inStream.getByte());
    }

    writeSNGRankResultWithReplayRequest(outStream: TSDataOutputStream, obj: SNGRankResultWithReplayRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTemplateId());
        outStream.putInteger(obj.getBuyInType());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getFee());
        outStream.putStringEx(obj.getTournamentName());
        outStream.putInteger(obj.getGameType());
        outStream.putStringExArray(obj.getRankInfo());
        outStream.putInteger(obj.getRank());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getNumberOfTourneyTickets());
        outStream.putLong(obj.getAccBalanceGC());
        outStream.putLong(obj.getFxSnapshotID());
        outStream.putObject(obj.getFxDetails());
        outStream.putByte(obj.getTicketType());
    }
    readTournamentRegisteredPlayerInfo(inStream: TSDataInputStream, obj: TournamentRegisteredPlayerInfo): void {
        obj.setMtctId(inStream.getInteger());
        obj.setBrandId(inStream.getString());
        obj.setRank(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setSngJackpotId(inStream.getInteger());
        obj.setEliminatedPosition(inStream.getInteger());
        obj.setRegType(inStream.getInteger());
        obj.setQualifiedChips(inStream.getLong());
    }

    writeTournamentRegisteredPlayerInfo(outStream: TSDataOutputStream, obj: TournamentRegisteredPlayerInfo): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getBrandId());
        outStream.putInteger(obj.getRank());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getSngJackpotId());
        outStream.putInteger(obj.getEliminatedPosition());
        outStream.putInteger(obj.getRegType());
        outStream.putLong(obj.getQualifiedChips());
    }
    readCanJoinPrivateTableRequest(inStream: TSDataInputStream, obj: CanJoinPrivateTableRequest): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeCanJoinPrivateTableRequest(outStream: TSDataOutputStream, obj: CanJoinPrivateTableRequest): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readCanJoinPrivateTableResponse(inStream: TSDataInputStream, obj: CanJoinPrivateTableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
    }

    writeCanJoinPrivateTableResponse(outStream: TSDataOutputStream, obj: CanJoinPrivateTableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getResponseCode());
    }
    readUserFreeRollList(inStream: TSDataInputStream, obj: UserFreeRollList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserName(inStream.getString());
        obj.setFreeRollIds(inStream.getIntArray());
        obj.setDomain(inStream.getByte());
    }

    writeUserFreeRollList(outStream: TSDataOutputStream, obj: UserFreeRollList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getUserName());
        outStream.putIntArray(obj.getFreeRollIds());
        outStream.putByte(obj.getDomain());
    }
    readAddedFreeRoll(inStream: TSDataInputStream, obj: AddedFreeRoll): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserName(inStream.getString());
        obj.setFreeRollId(inStream.getInteger());
        obj.setDomain(inStream.getByte());
    }

    writeAddedFreeRoll(outStream: TSDataOutputStream, obj: AddedFreeRoll): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getUserName());
        outStream.putInteger(obj.getFreeRollId());
        outStream.putByte(obj.getDomain());
    }
    readRemovedFreeRoll(inStream: TSDataInputStream, obj: RemovedFreeRoll): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserName(inStream.getString());
        obj.setFreeRollId(inStream.getInteger());
        obj.setDomain(inStream.getByte());
    }

    writeRemovedFreeRoll(outStream: TSDataOutputStream, obj: RemovedFreeRoll): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getUserName());
        outStream.putInteger(obj.getFreeRollId());
        outStream.putByte(obj.getDomain());
    }
    readTableStakesInfoRequest(inStream: TSDataInputStream, obj: TableStakesInfoRequest): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeTableStakesInfoRequest(outStream: TSDataOutputStream, obj: TableStakesInfoRequest): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readTableStakesInfoResponse(inStream: TSDataInputStream, obj: TableStakesInfoResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLevelId(inStream.getInteger());
        obj.setRealTableStakesInfo(inStream.getObjectArray());
        obj.setPlayTableStakesInfo(inStream.getObjectArray());
    }

    writeTableStakesInfoResponse(outStream: TSDataOutputStream, obj: TableStakesInfoResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLevelId());
        outStream.putObjectArray(obj.getRealTableStakesInfo());
        outStream.putObjectArray(obj.getPlayTableStakesInfo());
    }
    readUncalledBetReturn(inStream: TSDataInputStream, obj: UncalledBetReturn): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerId(inStream.getString());
        obj.setUncalledBetAmount(inStream.getLong());
        obj.setPotNo(inStream.getInteger());
    }

    writeUncalledBetReturn(outStream: TSDataOutputStream, obj: UncalledBetReturn): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getPlayerId());
        outStream.putLong(obj.getUncalledBetAmount());
        outStream.putInteger(obj.getPotNo());
    }
    readTableStakesInfo(inStream: TSDataInputStream, obj: TableStakesInfo): void {
        obj.setStakeId(inStream.getInteger());
        obj.setBrandId(inStream.getString());
        obj.setTableCategory(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setLowerStake(inStream.getInteger());
        obj.setUpperStake(inStream.getInteger());
        obj.setSmallBlind(inStream.getInteger());
        obj.setBigBlind(inStream.getInteger());
        obj.setTrnyBuyin(inStream.getInteger());
        obj.setTrnyFee(inStream.getInteger());
        obj.setIsFFInfo(inStream.getBoolean());
        obj.setLevelId(inStream.getInteger());
    }

    writeTableStakesInfo(outStream: TSDataOutputStream, obj: TableStakesInfo): void {
        outStream.putInteger(obj.getStakeId());
        outStream.putString(obj.getBrandId());
        outStream.putInteger(obj.getTableCategory());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getLowerStake());
        outStream.putInteger(obj.getUpperStake());
        outStream.putInteger(obj.getSmallBlind());
        outStream.putInteger(obj.getBigBlind());
        outStream.putInteger(obj.getTrnyBuyin());
        outStream.putInteger(obj.getTrnyFee());
        outStream.putBoolean(obj.getIsFFInfo());
        outStream.putInteger(obj.getLevelId());
    }
    readTableStakeLevelInfoRequest(inStream: TSDataInputStream, obj: TableStakeLevelInfoRequest): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeTableStakeLevelInfoRequest(outStream: TSDataOutputStream, obj: TableStakeLevelInfoRequest): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readTableStakeLevelInfoResponse(inStream: TSDataInputStream, obj: TableStakeLevelInfoResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRealTableStakesInfo(inStream.getObjectArray());
        obj.setPlayTableStakesInfo(inStream.getObjectArray());
    }

    writeTableStakeLevelInfoResponse(outStream: TSDataOutputStream, obj: TableStakeLevelInfoResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getRealTableStakesInfo());
        outStream.putObjectArray(obj.getPlayTableStakesInfo());
    }
    readContentLockingAccessDeniedResponse(inStream: TSDataInputStream, obj: ContentLockingAccessDeniedResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLevelId(inStream.getInteger());
    }

    writeContentLockingAccessDeniedResponse(outStream: TSDataOutputStream, obj: ContentLockingAccessDeniedResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLevelId());
    }
    readMTTournamentFinalTable(inStream: TSDataInputStream, obj: MTTournamentFinalTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
    }

    writeMTTournamentFinalTable(outStream: TSDataOutputStream, obj: MTTournamentFinalTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTableId());
    }
    readResponseBuyMoreChipsNotAllowed(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsNotAllowed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMessageToShow(inStream.getStringEx());
    }

    writeResponseBuyMoreChipsNotAllowed(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsNotAllowed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMessageToShow());
    }
    readPoolForcedSitOutNotification(inStream: TSDataInputStream, obj: PoolForcedSitOutNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setReasonId(inStream.getByte());
    }

    writePoolForcedSitOutNotification(outStream: TSDataOutputStream, obj: PoolForcedSitOutNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putByte(obj.getReasonId());
    }
    readResponseRebuyGenericFailure(inStream: TSDataInputStream, obj: ResponseRebuyGenericFailure): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
        obj.setResponseId(inStream.getInteger());
    }

    writeResponseRebuyGenericFailure(outStream: TSDataOutputStream, obj: ResponseRebuyGenericFailure): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMessage());
        outStream.putInteger(obj.getResponseId());
    }
    readResponseRebuyAddonGenericFailure(inStream: TSDataInputStream, obj: ResponseRebuyAddonGenericFailure): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
    }

    writeResponseRebuyAddonGenericFailure(outStream: TSDataOutputStream, obj: ResponseRebuyAddonGenericFailure): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseId());
        outStream.putStringEx(obj.getMessage());
    }
    readSitOutInfoNotification(inStream: TSDataInputStream, obj: SitOutInfoNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setReasonId(inStream.getByte());
    }

    writeSitOutInfoNotification(outStream: TSDataOutputStream, obj: SitOutInfoNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getReasonId());
    }
    readTournamentBackendUnRegistrationsInfo(inStream: TSDataInputStream, obj: TournamentBackendUnRegistrationsInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUnregistredMtcts(inStream.getObjectArray());
    }

    writeTournamentBackendUnRegistrationsInfo(outStream: TSDataOutputStream, obj: TournamentBackendUnRegistrationsInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getUnregistredMtcts());
    }
    readTournamentBackendUnRegistrationsInfoAck(inStream: TSDataInputStream, obj: TournamentBackendUnRegistrationsInfoAck): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeTournamentBackendUnRegistrationsInfoAck(outStream: TSDataOutputStream, obj: TournamentBackendUnRegistrationsInfoAck): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readTournamentShowAddonPopup(inStream: TSDataInputStream, obj: TournamentShowAddonPopup): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setChipCount(inStream.getInteger());
        obj.setAccountBalance(inStream.getLong());
        obj.setDialogTime(inStream.getInteger());
        obj.setFee(inStream.getInteger());
        obj.setCostIncludesFee(inStream.getBoolean());
    }

    writeTournamentShowAddonPopup(outStream: TSDataOutputStream, obj: TournamentShowAddonPopup): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getChipCount());
        outStream.putLong(obj.getAccountBalance());
        outStream.putInteger(obj.getDialogTime());
        outStream.putInteger(obj.getFee());
        outStream.putBoolean(obj.getCostIncludesFee());
    }
    readAppFriendsListRequest(inStream: TSDataInputStream, obj: AppFriendsListRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccessToken(inStream.getString());
    }

    writeAppFriendsListRequest(outStream: TSDataOutputStream, obj: AppFriendsListRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccessToken());
    }
    readAppFriendsListResponse(inStream: TSDataInputStream, obj: AppFriendsListResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAppFBFriendDetails(inStream.getObjectArray());
        obj.setOtherFBFriendDetails(inStream.getObjectArray());
    }

    writeAppFriendsListResponse(outStream: TSDataOutputStream, obj: AppFriendsListResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getAppFBFriendDetails());
        outStream.putObjectArray(obj.getOtherFBFriendDetails());
    }
    readGamingSessionTimedOutPopUp(inStream: TSDataInputStream, obj: GamingSessionTimedOutPopUp): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSessionTimeOutDurationInMins(inStream.getLong());
    }

    writeGamingSessionTimedOutPopUp(outStream: TSDataOutputStream, obj: GamingSessionTimedOutPopUp): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSessionTimeOutDurationInMins());
    }
    readUnblockGamingSessionTimedOutPlayer(inStream: TSDataInputStream, obj: UnblockGamingSessionTimedOutPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeUnblockGamingSessionTimedOutPlayer(outStream: TSDataOutputStream, obj: UnblockGamingSessionTimedOutPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readGamingSessionTimedOutPopUpAck(inStream: TSDataInputStream, obj: GamingSessionTimedOutPopUpAck): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeGamingSessionTimedOutPopUpAck(outStream: TSDataOutputStream, obj: GamingSessionTimedOutPopUpAck): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readSitOutWarningOn(inStream: TSDataInputStream, obj: SitOutWarningOn): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setWarningType(inStream.getInteger());
        obj.setTimerExpiryTimeAt(inStream.getLong());
    }

    writeSitOutWarningOn(outStream: TSDataOutputStream, obj: SitOutWarningOn): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getWarningType());
        outStream.putLong(obj.getTimerExpiryTimeAt());
    }
    readSitOutWarningOff(inStream: TSDataInputStream, obj: SitOutWarningOff): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeSitOutWarningOff(outStream: TSDataOutputStream, obj: SitOutWarningOff): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readPlayerBalanceInfo(inStream: TSDataInputStream, obj: PlayerBalanceInfo): void {
        obj.setPreviousBalance(inStream.getLong());
        obj.setTransactionAmount(inStream.getLong());
        obj.setCurrentBalance(inStream.getLong());
        obj.setSessionId(inStream.getLong());
    }

    writePlayerBalanceInfo(outStream: TSDataOutputStream, obj: PlayerBalanceInfo): void {
        outStream.putLong(obj.getPreviousBalance());
        outStream.putLong(obj.getTransactionAmount());
        outStream.putLong(obj.getCurrentBalance());
        outStream.putLong(obj.getSessionId());
    }
    readFRONTALLeavePoolResponse(inStream: TSDataInputStream, obj: FRONTALLeavePoolResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setBalanceInfo(inStream.getObject());
        obj.setResponseId(inStream.getInteger());
        obj.setTime(inStream.getLong());
        obj.setConversationId(inStream.getLong());
        obj.setSessionId(inStream.getLong());
    }

    writeFRONTALLeavePoolResponse(outStream: TSDataOutputStream, obj: FRONTALLeavePoolResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putObject(obj.getBalanceInfo());
        outStream.putInteger(obj.getResponseId());
        outStream.putLong(obj.getTime());
        outStream.putLong(obj.getConversationId());
        outStream.putLong(obj.getSessionId());
    }
    readFRONTALJoinPoolResponse(inStream: TSDataInputStream, obj: FRONTALJoinPoolResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPoolName(inStream.getString());
        // obj.setBalanceInfoMap(inStream.getInt64CSerializerMFCMap());
        obj.setMinBuyIn(inStream.getInteger());
        obj.setMaxBuyIn(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setTime(inStream.getLong());
        obj.setConversationId(inStream.getLong());
    }

    writeFRONTALJoinPoolResponse(outStream: TSDataOutputStream, obj: FRONTALJoinPoolResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getPoolName());
        // outStream.putInt64CSerializerMFCMap(obj.getBalanceInfoMap());
        outStream.putInteger(obj.getMinBuyIn());
        outStream.putInteger(obj.getMaxBuyIn());
        outStream.putInteger(obj.getResponseId());
        outStream.putLong(obj.getTime());
        outStream.putLong(obj.getConversationId());
    }
    readFRONTALPoolRebuyResponse(inStream: TSDataInputStream, obj: FRONTALPoolRebuyResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setBalanceInfo(inStream.getObject());
        obj.setMinBuyIn(inStream.getInteger());
        obj.setMaxBuyIn(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setTime(inStream.getLong());
        obj.setConversationId(inStream.getLong());
        obj.setPoolName(inStream.getString());
    }

    writeFRONTALPoolRebuyResponse(outStream: TSDataOutputStream, obj: FRONTALPoolRebuyResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putObject(obj.getBalanceInfo());
        outStream.putInteger(obj.getMinBuyIn());
        outStream.putInteger(obj.getMaxBuyIn());
        outStream.putInteger(obj.getResponseId());
        outStream.putLong(obj.getTime());
        outStream.putLong(obj.getConversationId());
        outStream.putString(obj.getPoolName());
    }
    readSaveMTTPlayerAutoRebuyAddonPreferences(inStream: TSDataInputStream, obj: SaveMTTPlayerAutoRebuyAddonPreferences): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAutoRebuyOption(inStream.getByte());
        obj.setAutoAddonOption(inStream.getByte());
    }

    writeSaveMTTPlayerAutoRebuyAddonPreferences(outStream: TSDataOutputStream, obj: SaveMTTPlayerAutoRebuyAddonPreferences): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getAutoRebuyOption());
        outStream.putByte(obj.getAutoAddonOption());
    }
    readMTTPlayerAutoRebuyAddonPreferences(inStream: TSDataInputStream, obj: MTTPlayerAutoRebuyAddonPreferences): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAutoRebuyOption(inStream.getByte());
        obj.setAutoAddonOption(inStream.getByte());
    }

    writeMTTPlayerAutoRebuyAddonPreferences(outStream: TSDataOutputStream, obj: MTTPlayerAutoRebuyAddonPreferences): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getAutoRebuyOption());
        outStream.putByte(obj.getAutoAddonOption());
    }
    readCountryBlockEventRequest(inStream: TSDataInputStream, obj: CountryBlockEventRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEventName(inStream.getString());
    }

    writeCountryBlockEventRequest(outStream: TSDataOutputStream, obj: CountryBlockEventRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getEventName());
    }
    readCountryBlockEventResponse(inStream: TSDataInputStream, obj: CountryBlockEventResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEventName(inStream.getString());
        obj.setPermissionFlag(inStream.getBoolean());
        obj.setErrorCode(inStream.getStringEx());
    }

    writeCountryBlockEventResponse(outStream: TSDataOutputStream, obj: CountryBlockEventResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getEventName());
        outStream.putBoolean(obj.getPermissionFlag());
        outStream.putStringEx(obj.getErrorCode());
    }
    readPoolItaliaAuthorizationCodes(inStream: TSDataInputStream, obj: PoolItaliaAuthorizationCodes): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setSessionId(inStream.getString());
        obj.setParticipationId(inStream.getString());
    }

    writePoolItaliaAuthorizationCodes(outStream: TSDataOutputStream, obj: PoolItaliaAuthorizationCodes): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
        outStream.putString(obj.getSessionId());
        outStream.putString(obj.getParticipationId());
    }
    readPoolPartnerRebuyRequest(inStream: TSDataInputStream, obj: PoolPartnerRebuyRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setEntryId(inStream.getLong());
        obj.setIncludePartnerWallet(inStream.getByte());
        obj.setConversationId(inStream.getLong());
    }

    writePoolPartnerRebuyRequest(outStream: TSDataOutputStream, obj: PoolPartnerRebuyRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putLong(obj.getEntryId());
        outStream.putByte(obj.getIncludePartnerWallet());
        outStream.putLong(obj.getConversationId());
    }
    readPoolRequestUserBalancePartnerPlayer(inStream: TSDataInputStream, obj: PoolRequestUserBalancePartnerPlayer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writePoolRequestUserBalancePartnerPlayer(outStream: TSDataOutputStream, obj: PoolRequestUserBalancePartnerPlayer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEntryId());
    }
    readPoolUserAllBalance(inStream: TSDataInputStream, obj: PoolUserAllBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalance(inStream.getLong());
        obj.setRealMoneyBalanceGC(inStream.getLong());
        obj.setPartnerBalanceAC(inStream.getLong());
        obj.setPartnerBalanceGC(inStream.getLong());
        obj.setPartnerPlayBalance(inStream.getLong());
        obj.setEntryId(inStream.getLong());
        obj.setBalanceFor(inStream.getByte());
        obj.setRefreshBalanceTypes(inStream.getByte());
    }

    writePoolUserAllBalance(outStream: TSDataOutputStream, obj: PoolUserAllBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getPlayMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalance());
        outStream.putLong(obj.getRealMoneyBalanceGC());
        outStream.putLong(obj.getPartnerBalanceAC());
        outStream.putLong(obj.getPartnerBalanceGC());
        outStream.putLong(obj.getPartnerPlayBalance());
        outStream.putLong(obj.getEntryId());
        outStream.putByte(obj.getBalanceFor());
        outStream.putByte(obj.getRefreshBalanceTypes());
    }
    readShowPlayNow(inStream: TSDataInputStream, obj: ShowPlayNow): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setShowPlayNowBtn(inStream.getBoolean());
    }

    writeShowPlayNow(outStream: TSDataOutputStream, obj: ShowPlayNow): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getShowPlayNowBtn());
    }
    readDoPlayNow(inStream: TSDataInputStream, obj: DoPlayNow): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeDoPlayNow(outStream: TSDataOutputStream, obj: DoPlayNow): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRemovePlayNow(inStream: TSDataInputStream, obj: RemovePlayNow): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRemovePlayNow(outStream: TSDataOutputStream, obj: RemovePlayNow): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readTournamentParticipantRankInfo(inStream: TSDataInputStream, obj: TournamentParticipantRankInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getByte());
        obj.setParticipantNo(inStream.getInteger());
        obj.setRank(inStream.getInteger());
        obj.setRankInfo(inStream.getStringExArray());
        obj.setDialogDisplayTimeInsSecs(inStream.getInteger());
        obj.setReentryAllowed(inStream.getBoolean());
        obj.setEliminatedPosition(inStream.getInteger());
        obj.setWinAmount(inStream.getObject());
        obj.setBountyAmount(inStream.getObject());
        obj.setTicketValue(inStream.getObject());
        obj.setTournamentTokens(inStream.getObject());
        obj.setTicketType(inStream.getByte());
        obj.setName(inStream.getStringEx());
        obj.setBuyIn(inStream.getInteger());
        obj.setEntryFee(inStream.getInteger());
        obj.setAccountBalance(inStream.getLong());
        obj.setTicketAvailable(inStream.getBoolean());
        obj.setFxSnapshotID(inStream.getLong());
        obj.setFlightedNextPhaseNo(inStream.getInteger());
        obj.setQualifiedChips(inStream.getLong());
        obj.setTdBalance(inStream.getLong());
    }

    writeTournamentParticipantRankInfo(outStream: TSDataOutputStream, obj: TournamentParticipantRankInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putByte(obj.getTournamentCategory());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getRank());
        outStream.putStringExArray(obj.getRankInfo());
        outStream.putInteger(obj.getDialogDisplayTimeInsSecs());
        outStream.putBoolean(obj.getReentryAllowed());
        outStream.putInteger(obj.getEliminatedPosition());
        outStream.putObject(obj.getWinAmount());
        outStream.putObject(obj.getBountyAmount());
        outStream.putObject(obj.getTicketValue());
        outStream.putObject(obj.getTournamentTokens());
        outStream.putByte(obj.getTicketType());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getEntryFee());
        outStream.putLong(obj.getAccountBalance());
        outStream.putBoolean(obj.getTicketAvailable());
        outStream.putLong(obj.getFxSnapshotID());
        outStream.putInteger(obj.getFlightedNextPhaseNo());
        outStream.putLong(obj.getQualifiedChips());
        outStream.putLong(obj.getTdBalance());
    }
    readTourneyRegistrationStatus(inStream: TSDataInputStream, obj: TourneyRegistrationStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setRegistrationsOpen(inStream.getBoolean());
    }

    writeTourneyRegistrationStatus(outStream: TSDataOutputStream, obj: TourneyRegistrationStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getRegistrationsOpen());
    }
    readPlayerStatuses(inStream: TSDataInputStream, obj: PlayerStatuses): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerStatuses(inStream.getObjectArray());
    }

    writePlayerStatuses(outStream: TSDataOutputStream, obj: PlayerStatuses): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPlayerStatuses());
    }
    readHUDAnonymousState(inStream: TSDataInputStream, obj: HUDAnonymousState): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsAnonymousState(inStream.getBoolean());
    }

    writeHUDAnonymousState(outStream: TSDataOutputStream, obj: HUDAnonymousState): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsAnonymousState());
    }
    readSNGJPPlayNowRequest(inStream: TSDataInputStream, obj: SNGJPPlayNowRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setEntries(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
    }

    writeSNGJPPlayNowRequest(outStream: TSDataOutputStream, obj: SNGJPPlayNowRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getEntries());
        outStream.putLong(obj.getConversationId());
    }
    readSNGJPPlayNowResponse(inStream: TSDataInputStream, obj: SNGJPPlayNowResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
        obj.setRequestedEntries(inStream.getInteger());
        obj.setAvailableEntries(inStream.getInteger());
        obj.setResponseMessage(inStream.getStringEx());
        obj.setAccountBalance(inStream.getLong());
        obj.setAvailableTickets(inStream.getInteger());
        obj.setFxSnapshotID(inStream.getLong());
        obj.setFxDetails(inStream.getObject());
        obj.setTdBalance(inStream.getLong());
        obj.setRemainingEntries(inStream.getInteger());
    }

    writeSNGJPPlayNowResponse(outStream: TSDataOutputStream, obj: SNGJPPlayNowResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getResponseCode());
        outStream.putInteger(obj.getRequestedEntries());
        outStream.putInteger(obj.getAvailableEntries());
        outStream.putStringEx(obj.getResponseMessage());
        outStream.putLong(obj.getAccountBalance());
        outStream.putInteger(obj.getAvailableTickets());
        outStream.putLong(obj.getFxSnapshotID());
        outStream.putObject(obj.getFxDetails());
        outStream.putLong(obj.getTdBalance());
        outStream.putInteger(obj.getRemainingEntries());
    }
    readSNGJPRegistrationRequest(inStream: TSDataInputStream, obj: SNGJPRegistrationRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setEntries(inStream.getInteger());
        obj.setTicketsToUse(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setSourceType(inStream.getByte());
    }

    writeSNGJPRegistrationRequest(outStream: TSDataOutputStream, obj: SNGJPRegistrationRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getEntries());
        outStream.putInteger(obj.getTicketsToUse());
        outStream.putLong(obj.getConversationId());
        outStream.putByte(obj.getSourceType());
    }
    readSNGJPRegistrationResponse(inStream: TSDataInputStream, obj: SNGJPRegistrationResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setStatus(inStream.getByte());
        obj.setSngJPRegistrationInfos(inStream.getObjectArray());
        obj.setSngjpRegistrationFailureDetails(inStream.getObjectArray());
        obj.setConversationId(inStream.getLong());
    }

    writeSNGJPRegistrationResponse(outStream: TSDataOutputStream, obj: SNGJPRegistrationResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putByte(obj.getStatus());
        outStream.putObjectArray(obj.getSngJPRegistrationInfos());
        outStream.putObjectArray(obj.getSngjpRegistrationFailureDetails());
        outStream.putLong(obj.getConversationId());
    }
    readSNGJPRegistrationInfo(inStream: TSDataInputStream, obj: SNGJPRegistrationInfo): void {
        obj.setSngJackpotID(inStream.getInteger());
        obj.setSngJPInstanceID(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
    }

    writeSNGJPRegistrationInfo(outStream: TSDataOutputStream, obj: SNGJPRegistrationInfo): void {
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getSngJPInstanceID());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getStatus());
    }
    readSNGJPUnRegistrationRequest(inStream: TSDataInputStream, obj: SNGJPUnRegistrationRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setSngJPInstanceID(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
    }

    writeSNGJPUnRegistrationRequest(outStream: TSDataOutputStream, obj: SNGJPUnRegistrationRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getSngJPInstanceID());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putLong(obj.getConversationId());
    }
    readSNGJPUnRegistrationResponse(inStream: TSDataInputStream, obj: SNGJPUnRegistrationResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
        obj.setSngJPInstanceID(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setResponseMessage(inStream.getStringEx());
        obj.setConversationId(inStream.getLong());
    }

    writeSNGJPUnRegistrationResponse(outStream: TSDataOutputStream, obj: SNGJPUnRegistrationResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getResponseCode());
        outStream.putInteger(obj.getSngJPInstanceID());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putStringEx(obj.getResponseMessage());
        outStream.putLong(obj.getConversationId());
    }
    readSNGJackpotInfo(inStream: TSDataInputStream, obj: SNGJackpotInfo): void {
        obj.setSngJackpotID(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setBuyIn(inStream.getInteger());
        obj.setTourneyFee(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setSngJPType(inStream.getByte());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getByte());
        obj.setMaxRegistrations(inStream.getByte());
        obj.setGamePlayType(inStream.getByte());
        obj.setMinMultiplier(inStream.getInteger());
        obj.setMaxMultiplier(inStream.getInteger());
        obj.setServerPeerId(inStream.getString());
        obj.setSngJPPayoutInfo(inStream.getObject());
        obj.setSelectedMultiplier(inStream.getInteger());
        obj.setMinMultiplierNew(inStream.getInteger());
        obj.setMaxMultiplierNew(inStream.getInteger());
        obj.setTableBackgroundImage(inStream.getString());
        obj.setCardType(inStream.getInteger());
    }

    writeSNGJackpotInfo(outStream: TSDataOutputStream, obj: SNGJackpotInfo): void {
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putString(obj.getGameCurrency());
        outStream.putByte(obj.getSngJPType());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putByte(obj.getMaxSeats());
        outStream.putByte(obj.getMaxRegistrations());
        outStream.putByte(obj.getGamePlayType());
        outStream.putInteger(obj.getMinMultiplier());
        outStream.putInteger(obj.getMaxMultiplier());
        outStream.putString(obj.getServerPeerId());
        outStream.putObject(obj.getSngJPPayoutInfo());
        outStream.putInteger(obj.getSelectedMultiplier());
        outStream.putInteger(obj.getMinMultiplierNew());
        outStream.putInteger(obj.getMaxMultiplierNew());
        outStream.putString(obj.getTableBackgroundImage());
        outStream.putInteger(obj.getCardType());
    }
    readSNGJPPayoutAnimation(inStream: TSDataInputStream, obj: SNGJPPayoutAnimation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setSngJPInstanceID(inStream.getInteger());
        obj.setSngJPTableId(inStream.getInteger());
        obj.setSngJPType(inStream.getByte());
        obj.setAnimationType(inStream.getByte());
        obj.setAnimationPayOutData(inStream.getIntArray());
        obj.setSngJPPayoutInfo(inStream.getObject());
        obj.setSelectedMultiplier(inStream.getInteger());
        obj.setSelectedMultiplierNew(inStream.getInteger());
        obj.setTableBackgroundImage(inStream.getString());
        obj.setBuyin(inStream.getInteger());
        obj.setSngCardType(inStream.getInteger());
        obj.setExpoAnimationInMillis(inStream.getLong());
        obj.setMaxMultiplierConfigured(inStream.getInteger());
    }

    writeSNGJPPayoutAnimation(outStream: TSDataOutputStream, obj: SNGJPPayoutAnimation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getSngJPInstanceID());
        outStream.putInteger(obj.getSngJPTableId());
        outStream.putByte(obj.getSngJPType());
        outStream.putByte(obj.getAnimationType());
        outStream.putIntArray(obj.getAnimationPayOutData());
        outStream.putObject(obj.getSngJPPayoutInfo());
        outStream.putInteger(obj.getSelectedMultiplier());
        outStream.putInteger(obj.getSelectedMultiplierNew());
        outStream.putString(obj.getTableBackgroundImage());
        outStream.putInteger(obj.getBuyin());
        outStream.putInteger(obj.getSngCardType());
        outStream.putLong(obj.getExpoAnimationInMillis());
        outStream.putInteger(obj.getMaxMultiplierConfigured());
    }
    readSNGJPPayoutInfo(inStream: TSDataInputStream, obj: SNGJPPayoutInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setSngJPInstanceID(inStream.getInteger());
        obj.setSngJPType(inStream.getByte());
        obj.setTotalPrize(inStream.getLong());
        obj.setPrizesInfo(inStream.getObjectArray());
        obj.setSngJPBountyInfo(inStream.getObject());
        obj.setPayoutType(inStream.getByte());
    }

    writeSNGJPPayoutInfo(outStream: TSDataOutputStream, obj: SNGJPPayoutInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getSngJPInstanceID());
        outStream.putByte(obj.getSngJPType());
        outStream.putLong(obj.getTotalPrize());
        outStream.putObjectArray(obj.getPrizesInfo());
        outStream.putObject(obj.getSngJPBountyInfo());
        outStream.putByte(obj.getPayoutType());
    }
    readSNGJPPrizeInfo(inStream: TSDataInputStream, obj: SNGJPPrizeInfo): void {
        obj.setRank(inStream.getInteger());
        obj.setPrizeAmount(inStream.getLong());
        obj.setPrizeType(inStream.getByte());
    }

    writeSNGJPPrizeInfo(outStream: TSDataOutputStream, obj: SNGJPPrizeInfo): void {
        outStream.putInteger(obj.getRank());
        outStream.putLong(obj.getPrizeAmount());
        outStream.putByte(obj.getPrizeType());
    }
    readSNGJPBountyInfo(inStream: TSDataInputStream, obj: SNGJPBountyInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBountyPlayerScrName(inStream.getString());
        obj.setBountyAmount(inStream.getInteger());
        obj.setBountyWinnersInfo(inStream.getObjectArray());
    }

    writeSNGJPBountyInfo(outStream: TSDataOutputStream, obj: SNGJPBountyInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getBountyPlayerScrName());
        outStream.putInteger(obj.getBountyAmount());
        outStream.putObjectArray(obj.getBountyWinnersInfo());
    }
    readSNGJPBountyWinner(inStream: TSDataInputStream, obj: SNGJPBountyWinner): void {
        obj.setScreenName(inStream.getString());
        obj.setAmount(inStream.getLong());
    }

    writeSNGJPBountyWinner(outStream: TSDataOutputStream, obj: SNGJPBountyWinner): void {
        outStream.putString(obj.getScreenName());
        outStream.putLong(obj.getAmount());
    }
    readSNGJPGameAboutToStart(inStream: TSDataInputStream, obj: SNGJPGameAboutToStart): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setSngJPInstanceID(inStream.getInteger());
        obj.setGamePlayStartTime(inStream.getString());
        obj.setSngJackpotPayoutInfo(inStream.getObject());
        obj.setTableBackgroundImage(inStream.getString());
    }

    writeSNGJPGameAboutToStart(outStream: TSDataOutputStream, obj: SNGJPGameAboutToStart): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getSngJPInstanceID());
        outStream.putString(obj.getGamePlayStartTime());
        outStream.putObject(obj.getSngJackpotPayoutInfo());
        outStream.putString(obj.getTableBackgroundImage());
    }
    readSNGJPTournamentRankInfo(inStream: TSDataInputStream, obj: SNGJPTournamentRankInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJackpotID(inStream.getInteger());
        obj.setInstanceID(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setRank(inStream.getInteger());
        obj.setWinAmount(inStream.getObject());
        obj.setReplayAllowed(inStream.getBoolean());
        obj.setAccountBalance(inStream.getLong());
        obj.setTicketAvailable(inStream.getBoolean());
        obj.setFxSnapshotID(inStream.getLong());
        obj.setFxDetails(inStream.getObject());
        obj.setBountyAmount(inStream.getObject());
        obj.setTicketValue(inStream.getObject());
        obj.setTournamentTokens(inStream.getObject());
        obj.setTicketType(inStream.getByte());
        obj.setTicketOnly(inStream.getBoolean());
        obj.setTdBalance(inStream.getLong());
        obj.setAvailableTickets(inStream.getInteger());
    }

    writeSNGJPTournamentRankInfo(outStream: TSDataOutputStream, obj: SNGJPTournamentRankInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSngJackpotID());
        outStream.putInteger(obj.getInstanceID());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getRank());
        outStream.putObject(obj.getWinAmount());
        outStream.putBoolean(obj.getReplayAllowed());
        outStream.putLong(obj.getAccountBalance());
        outStream.putBoolean(obj.getTicketAvailable());
        outStream.putLong(obj.getFxSnapshotID());
        outStream.putObject(obj.getFxDetails());
        outStream.putObject(obj.getBountyAmount());
        outStream.putObject(obj.getTicketValue());
        outStream.putObject(obj.getTournamentTokens());
        outStream.putByte(obj.getTicketType());
        outStream.putBoolean(obj.getTicketOnly());
        outStream.putLong(obj.getTdBalance());
        outStream.putInteger(obj.getAvailableTickets());
    }
    readSNGJPRegistrationFailureDetails(inStream: TSDataInputStream, obj: SNGJPRegistrationFailureDetails): void {
        obj.setErrorCode(inStream.getInteger());
        obj.setErrorMessage(inStream.getStringEx());
    }

    writeSNGJPRegistrationFailureDetails(outStream: TSDataOutputStream, obj: SNGJPRegistrationFailureDetails): void {
        outStream.putInteger(obj.getErrorCode());
        outStream.putStringEx(obj.getErrorMessage());
    }
    readSNGJPBountyAwardInfo(inStream: TSDataInputStream, obj: SNGJPBountyAwardInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBountyWinningMessage(inStream.getStringExArray());
        obj.setTimeToDisplay(inStream.getInteger());
        obj.setSngJPBountyInfo(inStream.getObject());
    }

    writeSNGJPBountyAwardInfo(outStream: TSDataOutputStream, obj: SNGJPBountyAwardInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringExArray(obj.getBountyWinningMessage());
        outStream.putInteger(obj.getTimeToDisplay());
        outStream.putObject(obj.getSngJPBountyInfo());
    }
    readEnableChat(inStream: TSDataInputStream, obj: EnableChat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEnabled(inStream.getBoolean());
    }

    writeEnableChat(outStream: TSDataOutputStream, obj: EnableChat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getEnabled());
    }
    readWatchersInfo(inStream: TSDataInputStream, obj: WatchersInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setWatchersCount(inStream.getInteger());
    }

    writeWatchersInfo(outStream: TSDataOutputStream, obj: WatchersInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getWatchersCount());
    }
    readPlayersBountyInfo(inStream: TSDataInputStream, obj: PlayersBountyInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAllPlayersBountyOnHeadValues(inStream.getString2IntMap());
        obj.setSpecialBountyValues(inStream.getString2IntMap());
        obj.setAllPlayersBountyTopUpValues(inStream.getString2IntMap());
    }

    writePlayersBountyInfo(outStream: TSDataOutputStream, obj: PlayersBountyInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2IntMap(obj.getAllPlayersBountyOnHeadValues());
        outStream.putString2IntMap(obj.getSpecialBountyValues());
        outStream.putString2IntMap(obj.getAllPlayersBountyTopUpValues());
    }
    readProgressiveBountyAnimationInfo(inStream: TSDataInputStream, obj: ProgressiveBountyAnimationInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setKoPlayersScrNames(inStream.getStringArray());
        obj.setWinnersBountyValues(inStream.getString2IntMap());
        obj.setAllPlayersBountyOnHeadValues(inStream.getString2IntMap());
        obj.setAllPlayersBountyTopUpValues(inStream.getString2IntMap());
    }

    writeProgressiveBountyAnimationInfo(outStream: TSDataOutputStream, obj: ProgressiveBountyAnimationInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getKoPlayersScrNames());
        outStream.putString2IntMap(obj.getWinnersBountyValues());
        outStream.putString2IntMap(obj.getAllPlayersBountyOnHeadValues());
        outStream.putString2IntMap(obj.getAllPlayersBountyTopUpValues());
    }
    readTourneyPauseInfo(inStream: TSDataInputStream, obj: TourneyPauseInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDayStartTime(inStream.getTimestamp());
        obj.setPauseType(inStream.getInteger());
        obj.setPauseValue(inStream.getInteger());
        obj.setTournamentType(inStream.getByte());
        obj.setFlightedType(inStream.getInteger());
        obj.setFlightedDisplayId(inStream.getInteger());
        obj.setResumeTime(inStream.getTimestamp());
    }

    writeTourneyPauseInfo(outStream: TSDataOutputStream, obj: TourneyPauseInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putTimestamp(obj.getDayStartTime());
        outStream.putInteger(obj.getPauseType());
        outStream.putInteger(obj.getPauseValue());
        outStream.putByte(obj.getTournamentType());
        outStream.putInteger(obj.getFlightedType());
        outStream.putInteger(obj.getFlightedDisplayId());
        outStream.putTimestamp(obj.getResumeTime());
    }
    readRemoveTourneyPauseInfo(inStream: TSDataInputStream, obj: RemoveTourneyPauseInfo): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRemoveTourneyPauseInfo(outStream: TSDataOutputStream, obj: RemoveTourneyPauseInfo): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readPromoWinnerPlayerList(inStream: TSDataInputStream, obj: PromoWinnerPlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPromoWinnerPlayerScreenNames(inStream.getStringArray());
        obj.setPromoWinnerPlayerFullNames(inStream.getStringArray());
    }

    writePromoWinnerPlayerList(outStream: TSDataOutputStream, obj: PromoWinnerPlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getPromoWinnerPlayerScreenNames());
        outStream.putStringArray(obj.getPromoWinnerPlayerFullNames());
    }
    readResponseLossLimitExceed(inStream: TSDataInputStream, obj: ResponseLossLimitExceed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
        obj.setEntryId(inStream.getLong());
    }

    writeResponseLossLimitExceed(outStream: TSDataOutputStream, obj: ResponseLossLimitExceed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMessage());
        outStream.putLong(obj.getEntryId());
    }
    readBetLimitErrorMessage(inStream: TSDataInputStream, obj: BetLimitErrorMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMsg(inStream.getStringEx());
    }

    writeBetLimitErrorMessage(outStream: TSDataOutputStream, obj: BetLimitErrorMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMsg());
    }
    readLoginSessionTimeoutIntimation(inStream: TSDataInputStream, obj: LoginSessionTimeoutIntimation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTimeLeft(inStream.getInteger());
    }

    writeLoginSessionTimeoutIntimation(outStream: TSDataOutputStream, obj: LoginSessionTimeoutIntimation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTimeLeft());
    }
    readReadLatestRandomSeatingFlag(inStream: TSDataInputStream, obj: ReadLatestRandomSeatingFlag): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeReadLatestRandomSeatingFlag(outStream: TSDataOutputStream, obj: ReadLatestRandomSeatingFlag): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readGameCurrencyDetailsList(inStream: TSDataInputStream, obj: GameCurrencyDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrenciesDetails(inStream.getObjectArray());
    }

    writeGameCurrencyDetailsList(outStream: TSDataOutputStream, obj: GameCurrencyDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getCurrenciesDetails());
    }
    readGeneralRequestAddMorePlayMoney(inStream: TSDataInputStream, obj: GeneralRequestAddMorePlayMoney): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeGeneralRequestAddMorePlayMoney(outStream: TSDataOutputStream, obj: GeneralRequestAddMorePlayMoney): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readMorePlayMoneyGeneralResponse(inStream: TSDataInputStream, obj: MorePlayMoneyGeneralResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setUpdatedPlayMoney(inStream.getLong());
    }

    writeMorePlayMoneyGeneralResponse(outStream: TSDataOutputStream, obj: MorePlayMoneyGeneralResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseId());
        outStream.putLong(obj.getUpdatedPlayMoney());
    }
    readReduceChips(inStream: TSDataInputStream, obj: ReduceChips): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAmount(inStream.getLong());
        obj.setConversationId(inStream.getLong());
    }

    writeReduceChips(outStream: TSDataOutputStream, obj: ReduceChips): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAmount());
        outStream.putLong(obj.getConversationId());
    }
    readReduceChipsResponse(inStream: TSDataInputStream, obj: ReduceChipsResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMessageToShow(inStream.getStringEx());
    }

    writeReduceChipsResponse(outStream: TSDataOutputStream, obj: ReduceChipsResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMessageToShow());
    }
    readSessionTimeRemainingInfo(inStream: TSDataInputStream, obj: SessionTimeRemainingInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSessionStatus(inStream.getInteger());
        obj.setAvailableSessionLimit(inStream.getLong());
    }

    writeSessionTimeRemainingInfo(outStream: TSDataOutputStream, obj: SessionTimeRemainingInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSessionStatus());
        outStream.putLong(obj.getAvailableSessionLimit());
    }
    readRealityCheckSummaryPopUp(inStream: TSDataInputStream, obj: RealityCheckSummaryPopUp): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSessionDuration(inStream.getInteger());
        obj.setNetWinAmount(inStream.getLong());
        obj.setCurrencyCode(inStream.getString());
        obj.setWagerAmt(inStream.getLong());
        obj.setWinAmt(inStream.getLong());
    }

    writeRealityCheckSummaryPopUp(outStream: TSDataOutputStream, obj: RealityCheckSummaryPopUp): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSessionDuration());
        outStream.putLong(obj.getNetWinAmount());
        outStream.putString(obj.getCurrencyCode());
        outStream.putLong(obj.getWagerAmt());
        outStream.putLong(obj.getWinAmt());
    }
    readRealityCheckSummaryPopUpAck(inStream: TSDataInputStream, obj: RealityCheckSummaryPopUpAck): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClientActionType(inStream.getInteger());
    }

    writeRealityCheckSummaryPopUpAck(outStream: TSDataOutputStream, obj: RealityCheckSummaryPopUpAck): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClientActionType());
    }
    readClosePoolEntry(inStream: TSDataInputStream, obj: ClosePoolEntry): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPoolId(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
    }

    writeClosePoolEntry(outStream: TSDataOutputStream, obj: ClosePoolEntry): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPoolId());
        outStream.putLong(obj.getEntryId());
    }
    readPlayerGameSessionData(inStream: TSDataInputStream, obj: PlayerGameSessionData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setTableOrPoolId(inStream.getInteger());
        obj.setEntryId(inStream.getLong());
        obj.setTotalBetInGC(inStream.getLong());
        obj.setTotalWinInGC(inStream.getLong());
    }

    writePlayerGameSessionData(outStream: TSDataOutputStream, obj: PlayerGameSessionData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountName());
        outStream.putInteger(obj.getTableOrPoolId());
        outStream.putLong(obj.getEntryId());
        outStream.putLong(obj.getTotalBetInGC());
        outStream.putLong(obj.getTotalWinInGC());
    }
    readPlayerVerificationStatus(inStream: TSDataInputStream, obj: PlayerVerificationStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setVerified(inStream.getBoolean());
    }

    writePlayerVerificationStatus(outStream: TSDataOutputStream, obj: PlayerVerificationStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getVerified());
    }
    readSelectedOptionNew(inStream: TSDataInputStream, obj: SelectedOptionNew): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getString());
        obj.setOptionId(inStream.getString());
        obj.setAmount(inStream.getLong());
        obj.setIsAllIn(inStream.getBoolean());
        obj.setBetVector(inStream.getLongArray());
    }

    writeSelectedOptionNew(outStream: TSDataOutputStream, obj: SelectedOptionNew): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getSeatNo());
        outStream.putString(obj.getOptionId());
        outStream.putLong(obj.getAmount());
        outStream.putBoolean(obj.getIsAllIn());
        outStream.putLongArray(obj.getBetVector());
    }
    readReqShowEmoji(inStream: TSDataInputStream, obj: ReqShowEmoji): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEmojiId(inStream.getInteger());
    }

    writeReqShowEmoji(outStream: TSDataOutputStream, obj: ReqShowEmoji): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getEmojiId());
    }
    readShowEmoji(inStream: TSDataInputStream, obj: ShowEmoji): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEmojiId(inStream.getInteger());
        obj.setFromPlayerSeatNo(inStream.getByte());
    }

    writeShowEmoji(outStream: TSDataOutputStream, obj: ShowEmoji): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getEmojiId());
        outStream.putByte(obj.getFromPlayerSeatNo());
    }
    readShowRabbit(inStream: TSDataInputStream, obj: ShowRabbit): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRabbitValue(inStream.getInteger());
        obj.setGameId(inStream.getLong());
    }

    writeShowRabbit(outStream: TSDataOutputStream, obj: ShowRabbit): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRabbitValue());
        outStream.putLong(obj.getGameId());
    }
    readReqEnableRabbit(inStream: TSDataInputStream, obj: ReqEnableRabbit): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameId(inStream.getLong());
    }

    writeReqEnableRabbit(outStream: TSDataOutputStream, obj: ReqEnableRabbit): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getGameId());
    }
    readBroadcastShowRabbit(inStream: TSDataInputStream, obj: BroadcastShowRabbit): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
        obj.setGameId(inStream.getLong());
    }

    writeBroadcastShowRabbit(outStream: TSDataOutputStream, obj: BroadcastShowRabbit): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getSeatNo());
        outStream.putLong(obj.getGameId());
    }
    readReqThrowAnimation(inStream: TSDataInputStream, obj: ReqThrowAnimation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAnimationId(inStream.getInteger());
        obj.setDestinationPlayerSeatNo(inStream.getByte());
    }

    writeReqThrowAnimation(outStream: TSDataOutputStream, obj: ReqThrowAnimation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAnimationId());
        outStream.putByte(obj.getDestinationPlayerSeatNo());
    }
    readResThrowAnimation(inStream: TSDataInputStream, obj: ResThrowAnimation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getBoolean());
    }

    writeResThrowAnimation(outStream: TSDataOutputStream, obj: ResThrowAnimation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getStatus());
    }
    readThrowAnimation(inStream: TSDataInputStream, obj: ThrowAnimation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAnimationId(inStream.getInteger());
        obj.setFromPlayerSeatNo(inStream.getByte());
        obj.setToPlayerSeatNo(inStream.getByte());
    }

    writeThrowAnimation(outStream: TSDataOutputStream, obj: ThrowAnimation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAnimationId());
        outStream.putByte(obj.getFromPlayerSeatNo());
        outStream.putByte(obj.getToPlayerSeatNo());
    }
    readGetPokerDiamondBalance(inStream: TSDataInputStream, obj: GetPokerDiamondBalance): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeGetPokerDiamondBalance(outStream: TSDataOutputStream, obj: GetPokerDiamondBalance): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readProbabilityOfWinning(inStream: TSDataInputStream, obj: ProbabilityOfWinning): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerWinPercent(inStream.getString2IntMap());
    }

    writeProbabilityOfWinning(outStream: TSDataOutputStream, obj: ProbabilityOfWinning): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2IntMap(obj.getPlayerWinPercent());
    }
    readDiamondBalance(inStream: TSDataInputStream, obj: DiamondBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrPromoCurrBalance(inStream.getLong());
    }

    writeDiamondBalance(outStream: TSDataOutputStream, obj: DiamondBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getCurrPromoCurrBalance());
    }
    readReqEnablePlayerRunittwiceState(inStream: TSDataInputStream, obj: ReqEnablePlayerRunittwiceState): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerRITState(inStream.getInteger());
    }

    writeReqEnablePlayerRunittwiceState(outStream: TSDataOutputStream, obj: ReqEnablePlayerRunittwiceState): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPlayerRITState());
    }
    readRequestHUPairTable(inStream: TSDataInputStream, obj: RequestHUPairTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSelected(inStream.getBoolean());
    }

    writeRequestHUPairTable(outStream: TSDataOutputStream, obj: RequestHUPairTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getSelected());
    }
    readHUPairTableSelectStatus(inStream: TSDataInputStream, obj: HUPairTableSelectStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSelected(inStream.getBoolean());
        obj.setTables(inStream.getIntArray());
    }

    writeHUPairTableSelectStatus(outStream: TSDataOutputStream, obj: HUPairTableSelectStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getSelected());
        outStream.putIntArray(obj.getTables());
    }
    readPlayersBountyWonInfo(inStream: TSDataInputStream, obj: PlayersBountyWonInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBoountyAmountWon(inStream.getString2IntMap());
    }

    writePlayersBountyWonInfo(outStream: TSDataOutputStream, obj: PlayersBountyWonInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2IntMap(obj.getBoountyAmountWon());
    }
    readITMInformation(inStream: TSDataInputStream, obj: ITMInformation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPrizeAmount(inStream.getInteger());
        obj.setNextPrizeRank(inStream.getInteger());
        obj.setNextPrizeAmount(inStream.getInteger());
    }

    writeITMInformation(outStream: TSDataOutputStream, obj: ITMInformation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPrizeAmount());
        outStream.putInteger(obj.getNextPrizeRank());
        outStream.putInteger(obj.getNextPrizeAmount());
    }
    readShowHUPairTable(inStream: TSDataInputStream, obj: ShowHUPairTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setOpponetName(inStream.getString());
    }

    writeShowHUPairTable(outStream: TSDataOutputStream, obj: ShowHUPairTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getOpponetName());
    }
    readRCPUActionAcknowledgement(inStream: TSDataInputStream, obj: RCPUActionAcknowledgement): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setRcpuAction(inStream.getString());
        obj.setResponseId(inStream.getInteger());
    }

    writeRCPUActionAcknowledgement(outStream: TSDataOutputStream, obj: RCPUActionAcknowledgement): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountName());
        outStream.putString(obj.getRcpuAction());
        outStream.putInteger(obj.getResponseId());
    }
    readRequestJoinTableAutoBuyin(inStream: TSDataInputStream, obj: RequestJoinTableAutoBuyin): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableStatus(inStream.getObject());
    }

    writeRequestJoinTableAutoBuyin(outStream: TSDataOutputStream, obj: RequestJoinTableAutoBuyin): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getTableStatus());
    }
    readDepositedFriendCount(inStream: TSDataInputStream, obj: DepositedFriendCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setReferredCount(inStream.getLong());
    }

    writeDepositedFriendCount(outStream: TSDataOutputStream, obj: DepositedFriendCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getReferredCount());
    }
    readHUEvenGame(inStream: TSDataInputStream, obj: HUEvenGame): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEvenGame(inStream.getBoolean());
    }

    writeHUEvenGame(outStream: TSDataOutputStream, obj: HUEvenGame): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getEvenGame());
    }
    readRequestPlayerNoteId(inStream: TSDataInputStream, obj: RequestPlayerNoteId): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestPlayerNoteId(outStream: TSDataOutputStream, obj: RequestPlayerNoteId): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponsePlayerNoteId(inStream: TSDataInputStream, obj: ResponsePlayerNoteId): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerNoteId(inStream.getString());
    }

    writeResponsePlayerNoteId(outStream: TSDataOutputStream, obj: ResponsePlayerNoteId): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getPlayerNoteId());
    }
    readRequestUserClubDetails(inStream: TSDataInputStream, obj: RequestUserClubDetails): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestUserClubDetails(outStream: TSDataOutputStream, obj: RequestUserClubDetails): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readUserClubDetails(inStream: TSDataInputStream, obj: UserClubDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
        obj.setClubName(inStream.getString());
        obj.setClubMememberShipStatus(inStream.getInteger());
        obj.setUserType(inStream.getInteger());
        obj.setJoinDate(inStream.getTimestamp());
    }

    writeUserClubDetails(outStream: TSDataOutputStream, obj: UserClubDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClubId());
        outStream.putString(obj.getClubName());
        outStream.putInteger(obj.getClubMememberShipStatus());
        outStream.putInteger(obj.getUserType());
        outStream.putTimestamp(obj.getJoinDate());
    }
    readUserClubRemoved(inStream: TSDataInputStream, obj: UserClubRemoved): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
    }

    writeUserClubRemoved(outStream: TSDataOutputStream, obj: UserClubRemoved): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClubId());
    }
    readResponseUserClubDetails(inStream: TSDataInputStream, obj: ResponseUserClubDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserClubDetails(inStream.getObjectArray());
    }

    writeResponseUserClubDetails(outStream: TSDataOutputStream, obj: ResponseUserClubDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getUserClubDetails());
    }
    readClubNameApproved(inStream: TSDataInputStream, obj: ClubNameApproved): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
        obj.setClubName(inStream.getString());
    }

    writeClubNameApproved(outStream: TSDataOutputStream, obj: ClubNameApproved): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClubId());
        outStream.putString(obj.getClubName());
    }
    readUserClubMemeberShipApproved(inStream: TSDataInputStream, obj: UserClubMemeberShipApproved): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
    }

    writeUserClubMemeberShipApproved(outStream: TSDataOutputStream, obj: UserClubMemeberShipApproved): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClubId());
    }
    readUserClubMemeberTypeChange(inStream: TSDataInputStream, obj: UserClubMemeberTypeChange): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
        obj.setUserType(inStream.getInteger());
    }

    writeUserClubMemeberTypeChange(outStream: TSDataOutputStream, obj: UserClubMemeberTypeChange): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClubId());
        outStream.putInteger(obj.getUserType());
    }
    readRequestRealName(inStream: TSDataInputStream, obj: RequestRealName): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setGameId(inStream.getLong());
        obj.setSeatNo(inStream.getByte());
    }

    writeRequestRealName(outStream: TSDataOutputStream, obj: RequestRealName): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getScreenName());
        outStream.putLong(obj.getGameId());
        outStream.putByte(obj.getSeatNo());
    }
    readResponseRealName(inStream: TSDataInputStream, obj: ResponseRealName): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setFullName(inStream.getString());
        obj.setRealName(inStream.getString());
        obj.setGameId(inStream.getLong());
        obj.setSeatNo(inStream.getByte());
    }

    writeResponseRealName(outStream: TSDataOutputStream, obj: ResponseRealName): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getScreenName());
        outStream.putString(obj.getFullName());
        outStream.putString(obj.getRealName());
        outStream.putLong(obj.getGameId());
        outStream.putByte(obj.getSeatNo());
    }
    readPlayerTableList(inStream: TSDataInputStream, obj: PlayerTableList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableIds(inStream.getIntArray());
        obj.setFfEntryIds(inStream.getIntArray());
        obj.setFailedTableId(inStream.getInteger());
    }

    writePlayerTableList(outStream: TSDataOutputStream, obj: PlayerTableList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getTableIds());
        outStream.putIntArray(obj.getFfEntryIds());
        outStream.putInteger(obj.getFailedTableId());
    }
    readTableBoosterTriggered(inStream: TSDataInputStream, obj: TableBoosterTriggered): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBoosterType(inStream.getInteger());
        obj.setFromBB(inStream.getInteger());
        obj.setToBB(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setWinnerPerc(inStream.getLong());
        obj.setLooserPerc(inStream.getLong());
        obj.setShortAnimation(inStream.getBoolean());
    }

    writeTableBoosterTriggered(outStream: TSDataOutputStream, obj: TableBoosterTriggered): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBoosterType());
        outStream.putInteger(obj.getFromBB());
        outStream.putInteger(obj.getToBB());
        outStream.putLong(obj.getGameId());
        outStream.putLong(obj.getWinnerPerc());
        outStream.putLong(obj.getLooserPerc());
        outStream.putBoolean(obj.getShortAnimation());
    }
    readBroadcastTableBoosterTrigger(inStream: TSDataInputStream, obj: BroadcastTableBoosterTrigger): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBoosterType(inStream.getInteger());
        obj.setFromBB(inStream.getInteger());
        obj.setToBB(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setTableId(inStream.getLong());
        obj.setBroadcastConfTypeVSGames(inStream.getInt2StringMap());
    }

    writeBroadcastTableBoosterTrigger(outStream: TSDataOutputStream, obj: BroadcastTableBoosterTrigger): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBoosterType());
        outStream.putInteger(obj.getFromBB());
        outStream.putInteger(obj.getToBB());
        outStream.putLong(obj.getGameId());
        outStream.putLong(obj.getTableId());
        outStream.putInt2StringMap(obj.getBroadcastConfTypeVSGames());
    }
    readTableBoosterWinners(inStream: TSDataInputStream, obj: TableBoosterWinners): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setBoosterType(inStream.getInteger());
        obj.setBoosterMultiplyValue(inStream.getLong());
        obj.setBoosterCalcValue(inStream.getLong());
        obj.setBoosterWinners(inStream.getObjectArray());
        obj.setPrizeRevealBoosterRangeVals(inStream.getLongArray());
        obj.setShortAnimation(inStream.getBoolean());
    }

    writeTableBoosterWinners(outStream: TSDataOutputStream, obj: TableBoosterWinners): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getBoosterType());
        outStream.putLong(obj.getBoosterMultiplyValue());
        outStream.putLong(obj.getBoosterCalcValue());
        outStream.putObjectArray(obj.getBoosterWinners());
        outStream.putLongArray(obj.getPrizeRevealBoosterRangeVals());
        outStream.putBoolean(obj.getShortAnimation());
    }
    readBoosterWinners(inStream: TSDataInputStream, obj: BoosterWinners): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setWinAmount(inStream.getLong());
        obj.setIsWinner(inStream.getBoolean());
        obj.setSeatNo(inStream.getByte());
    }

    writeBoosterWinners(outStream: TSDataOutputStream, obj: BoosterWinners): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getScreenName());
        outStream.putLong(obj.getWinAmount());
        outStream.putBoolean(obj.getIsWinner());
        outStream.putByte(obj.getSeatNo());
    }
    readFRSessionTimer(inStream: TSDataInputStream, obj: FRSessionTimer): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSessionTimeRemaining(inStream.getLong());
    }

    writeFRSessionTimer(outStream: TSDataOutputStream, obj: FRSessionTimer): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSessionTimeRemaining());
    }
    readITMAdditionalInformation(inStream: TSDataInputStream, obj: ITMAdditionalInformation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTotalPrizeAmount(inStream.getLong());
        obj.setFirstPrizeAmount(inStream.getInteger());
    }

    writeITMAdditionalInformation(outStream: TSDataOutputStream, obj: ITMAdditionalInformation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getTotalPrizeAmount());
        outStream.putInteger(obj.getFirstPrizeAmount());
    }
    readResponseGermanTaxValue(inStream: TSDataInputStream, obj: ResponseGermanTaxValue): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGermanTaxInPercCentsMap(inStream.getInt2IntMap());
    }

    writeResponseGermanTaxValue(outStream: TSDataOutputStream, obj: ResponseGermanTaxValue): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2IntMap(obj.getGermanTaxInPercCentsMap());
    }
    readTournamentLateRegistrationDetails(inStream: TSDataInputStream, obj: TournamentLateRegistrationDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLrCloseTime(inStream.getLong());
        obj.setRemainingLRTime(inStream.getLong());
        obj.setTourneyStartTime(inStream.getTimestamp());
    }

    writeTournamentLateRegistrationDetails(outStream: TSDataOutputStream, obj: TournamentLateRegistrationDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLrCloseTime());
        outStream.putLong(obj.getRemainingLRTime());
        outStream.putTimestamp(obj.getTourneyStartTime());
    }
    readLossLimitDetails(inStream: TSDataInputStream, obj: LossLimitDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrency(inStream.getString());
        obj.setPendingAmt(inStream.getLong());
        obj.setPlayerLimitAmt(inStream.getLong());
        obj.setTotalLossAmt(inStream.getLong());
        obj.setLimitType(inStream.getString());
    }

    writeLossLimitDetails(outStream: TSDataOutputStream, obj: LossLimitDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getCurrency());
        outStream.putLong(obj.getPendingAmt());
        outStream.putLong(obj.getPlayerLimitAmt());
        outStream.putLong(obj.getTotalLossAmt());
        outStream.putString(obj.getLimitType());
    }
    readLossLimitResponse(inStream: TSDataInputStream, obj: LossLimitResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setLimitsDetails(inStream.getObjectArray());
    }

    writeLossLimitResponse(outStream: TSDataOutputStream, obj: LossLimitResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountName());
        outStream.putObjectArray(obj.getLimitsDetails());
    }
    readSessionLimitsResponse(inStream: TSDataInputStream, obj: SessionLimitsResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLimitType(inStream.getString());
        obj.setPercentageElapsed(inStream.getInteger());
        obj.setSessionLimitElaspedMins(inStream.getInteger());
        obj.setSessionLimitConfiguredMins(inStream.getInteger());
        obj.setSessionLimitRemainingMins(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setUseCase(inStream.getInteger());
    }

    writeSessionLimitsResponse(outStream: TSDataOutputStream, obj: SessionLimitsResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLimitType());
        outStream.putInteger(obj.getPercentageElapsed());
        outStream.putInteger(obj.getSessionLimitElaspedMins());
        outStream.putInteger(obj.getSessionLimitConfiguredMins());
        outStream.putInteger(obj.getSessionLimitRemainingMins());
        outStream.putString(obj.getAccountName());
        outStream.putInteger(obj.getUseCase());
    }
    readResponseFoldNShowCards(inStream: TSDataInputStream, obj: ResponseFoldNShowCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setHoleCards(inStream.getIntArray());
    }

    writeResponseFoldNShowCards(outStream: TSDataOutputStream, obj: ResponseFoldNShowCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getHoleCards());
    }
    readRequestCashoutEquity(inStream: TSDataInputStream, obj: RequestCashoutEquity): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setRequestCode(inStream.getInteger());
    }

    writeRequestCashoutEquity(outStream: TSDataOutputStream, obj: RequestCashoutEquity): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getRequestCode());
    }
    readPlayerCashedOutEquityStatus(inStream: TSDataInputStream, obj: PlayerCashedOutEquityStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setSeatNo(inStream.getInteger());
        obj.setCashedoutStatus(inStream.getInteger());
    }

    writePlayerCashedOutEquityStatus(outStream: TSDataOutputStream, obj: PlayerCashedOutEquityStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getSeatNo());
        outStream.putInteger(obj.getCashedoutStatus());
    }
    readPlayerCashoutEquityDetails(inStream: TSDataInputStream, obj: PlayerCashoutEquityDetails): void {
        obj.setSeatNo(inStream.getInteger());
        obj.setEquityPercentage(inStream.getInt2IntMap());
        obj.setEquityValue(inStream.getInt2IntMap());
        obj.setAllPotsTotEquityWin(inStream.getLong());
    }

    writePlayerCashoutEquityDetails(outStream: TSDataOutputStream, obj: PlayerCashoutEquityDetails): void {
        outStream.putInteger(obj.getSeatNo());
        outStream.putInt2IntMap(obj.getEquityPercentage());
        outStream.putInt2IntMap(obj.getEquityValue());
        outStream.putLong(obj.getAllPotsTotEquityWin());
    }
    readPlayersCashoutEquityDetails(inStream: TSDataInputStream, obj: PlayersCashoutEquityDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setSurcharge(inStream.getInteger());
        obj.setGeneratedAt(inStream.getLong());
        obj.setTimeOutPeriod(inStream.getInteger());
        obj.setPlayerCashoutEquityDetails(inStream.getObjectArray());
    }

    writePlayersCashoutEquityDetails(outStream: TSDataOutputStream, obj: PlayersCashoutEquityDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getSurcharge());
        outStream.putLong(obj.getGeneratedAt());
        outStream.putInteger(obj.getTimeOutPeriod());
        outStream.putObjectArray(obj.getPlayerCashoutEquityDetails());
    }
    readResponseCashoutEquity(inStream: TSDataInputStream, obj: ResponseCashoutEquity): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setGameId(inStream.getLong());
        obj.setStatusCode(inStream.getInteger());
        obj.setEquityPercentage(inStream.getInteger());
        obj.setEquityValue(inStream.getInteger());
    }

    writeResponseCashoutEquity(outStream: TSDataOutputStream, obj: ResponseCashoutEquity): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getGameId());
        outStream.putInteger(obj.getStatusCode());
        outStream.putInteger(obj.getEquityPercentage());
        outStream.putInteger(obj.getEquityValue());
    }
    readShowCards2(inStream: TSDataInputStream, obj: ShowCards2): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getByte());
        obj.setCards(inStream.getStringArray());
        obj.setHandStrengthHi2(inStream.getStringEx());
        obj.setBestCards(inStream.getString());
    }

    writeShowCards2(outStream: TSDataOutputStream, obj: ShowCards2): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getSeatNo());
        outStream.putStringArray(obj.getCards());
        outStream.putStringEx(obj.getHandStrengthHi2());
        outStream.putString(obj.getBestCards());
    }
    readPlayerATHStrike(inStream: TSDataInputStream, obj: PlayerATHStrike): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAthBlockExpireTime(inStream.getTimestamp());
    }

    writePlayerATHStrike(outStream: TSDataOutputStream, obj: PlayerATHStrike): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putTimestamp(obj.getAthBlockExpireTime());
    }
    readATHStrikeHandCountConfiguration(inStream: TSDataInputStream, obj: ATHStrikeHandCountConfiguration): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMinimumHandsCount(inStream.getInteger());
    }

    writeATHStrikeHandCountConfiguration(outStream: TSDataOutputStream, obj: ATHStrikeHandCountConfiguration): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMinimumHandsCount());
    }
    readResponseUserTableBalanceReduceChips(inStream: TSDataInputStream, obj: ResponseUserTableBalanceReduceChips): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSeatNo(inStream.getInteger());
        obj.setReduceChipsApplied(inStream.getBoolean());
        obj.setPlayerBalance(inStream.getLong());
    }

    writeResponseUserTableBalanceReduceChips(outStream: TSDataOutputStream, obj: ResponseUserTableBalanceReduceChips): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSeatNo());
        outStream.putBoolean(obj.getReduceChipsApplied());
        outStream.putLong(obj.getPlayerBalance());
    }
    readTableReduceBalanceLimit(inStream: TSDataInputStream, obj: TableReduceBalanceLimit): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalanceLimit(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
    }

    writeTableReduceBalanceLimit(outStream: TSDataOutputStream, obj: TableReduceBalanceLimit): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getBalanceLimit());
        outStream.putString(obj.getGameCurrency());
    }
    readRequestTableLimitBalance(inStream: TSDataInputStream, obj: RequestTableLimitBalance): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestTableLimitBalance(outStream: TSDataOutputStream, obj: RequestTableLimitBalance): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readPlayedGamesCount(inStream: TSDataInputStream, obj: PlayedGamesCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCount(inStream.getInteger());
    }

    writePlayedGamesCount(outStream: TSDataOutputStream, obj: PlayedGamesCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getCount());
    }
    readCanLeaveTable(inStream: TSDataInputStream, obj: CanLeaveTable): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeCanLeaveTable(outStream: TSDataOutputStream, obj: CanLeaveTable): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readCanLeaveTableResponse(inStream: TSDataInputStream, obj: CanLeaveTableResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseCode(inStream.getByte());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setAthBlockTime(inStream.getLong());
    }

    writeCanLeaveTableResponse(outStream: TSDataOutputStream, obj: CanLeaveTableResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getResponseCode());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putLong(obj.getAthBlockTime());
    }
    readCanSitOut(inStream: TSDataInputStream, obj: CanSitOut): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestType(inStream.getByte());
    }

    writeCanSitOut(outStream: TSDataOutputStream, obj: CanSitOut): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getRequestType());
    }
    readCanSitOutResponse(inStream: TSDataInputStream, obj: CanSitOutResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseCode(inStream.getByte());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setAthBlockTime(inStream.getLong());
        obj.setRequestType(inStream.getByte());
    }

    writeCanSitOutResponse(outStream: TSDataOutputStream, obj: CanSitOutResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getResponseCode());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putLong(obj.getAthBlockTime());
        outStream.putByte(obj.getRequestType());
    }
    readATHAutoSitOutMsg(inStream: TSDataInputStream, obj: ATHAutoSitOutMsg): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setAthBlockTime(inStream.getLong());
    }

    writeATHAutoSitOutMsg(outStream: TSDataOutputStream, obj: ATHAutoSitOutMsg): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putLong(obj.getAthBlockTime());
    }
    readTourneyCancelled(inStream: TSDataInputStream, obj: TourneyCancelled): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeTourneyCancelled(outStream: TSDataOutputStream, obj: TourneyCancelled): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readTournamentCurrentStatus(inStream: TSDataInputStream, obj: TournamentCurrentStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
    }

    writeTournamentCurrentStatus(outStream: TSDataOutputStream, obj: TournamentCurrentStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getStatus());
    }
    readPlayerAccountType(inStream: TSDataInputStream, obj: PlayerAccountType): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsTestAccount(inStream.getBoolean());
    }

    writePlayerAccountType(outStream: TSDataOutputStream, obj: PlayerAccountType): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsTestAccount());
    }
    readPlayerGameSessionLimitsStatusResponse(inStream: TSDataInputStream, obj: PlayerGameSessionLimitsStatusResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setValue1(inStream.getInteger());
        obj.setValue2(inStream.getLong());
    }

    writePlayerGameSessionLimitsStatusResponse(outStream: TSDataOutputStream, obj: PlayerGameSessionLimitsStatusResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getStatus());
        outStream.putInteger(obj.getValue1());
        outStream.putLong(obj.getValue2());
    }
    readPlayerGameSessionRemainingLimitsStatusResponse(inStream: TSDataInputStream, obj: PlayerGameSessionRemainingLimitsStatusResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLimitBalance(inStream.getLong());
        obj.setSessionBalance(inStream.getInteger());
    }

    writePlayerGameSessionRemainingLimitsStatusResponse(outStream: TSDataOutputStream, obj: PlayerGameSessionRemainingLimitsStatusResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLimitBalance());
        outStream.putInteger(obj.getSessionBalance());
    }
    readPlayerGameSessionRemainingLimitsRequest(inStream: TSDataInputStream, obj: PlayerGameSessionRemainingLimitsRequest): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writePlayerGameSessionRemainingLimitsRequest(outStream: TSDataOutputStream, obj: PlayerGameSessionRemainingLimitsRequest): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readSetPlayerGameSessionLimitsRequest(inStream: TSDataInputStream, obj: SetPlayerGameSessionLimitsRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMaxSessionLimit(inStream.getInteger());
        obj.setMaxLossLimit(inStream.getLong());
        obj.setBreakPeriod(inStream.getInteger());
    }

    writeSetPlayerGameSessionLimitsRequest(outStream: TSDataOutputStream, obj: SetPlayerGameSessionLimitsRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMaxSessionLimit());
        outStream.putLong(obj.getMaxLossLimit());
        outStream.putInteger(obj.getBreakPeriod());
    }
    readPlayerGameSessionReminder(inStream: TSDataInputStream, obj: PlayerGameSessionReminder): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerState(inStream.getInteger());
        obj.setSessionLength(inStream.getInteger());
        obj.setSessionResult(inStream.getInteger());
    }

    writePlayerGameSessionReminder(outStream: TSDataOutputStream, obj: PlayerGameSessionReminder): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPlayerState());
        outStream.putInteger(obj.getSessionLength());
        outStream.putInteger(obj.getSessionResult());
    }
    readPlayerGameSessionLimitsStatusRequest(inStream: TSDataInputStream, obj: PlayerGameSessionLimitsStatusRequest): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writePlayerGameSessionLimitsStatusRequest(outStream: TSDataOutputStream, obj: PlayerGameSessionLimitsStatusRequest): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readServerParamUpdateToClient(inStream: TSDataInputStream, obj: ServerParamUpdateToClient): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setServerParamConfigMap(inStream.getString2StringMap());
    }

    writeServerParamUpdateToClient(outStream: TSDataOutputStream, obj: ServerParamUpdateToClient): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getServerParamConfigMap());
    }
    readUserLocationStatus(inStream: TSDataInputStream, obj: UserLocationStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsRMJoinTableAllowed(inStream.getBoolean());
        obj.setLocationStatus(inStream.getByte());
        obj.setIsPMJoinTableAllowed(inStream.getBoolean());
        obj.setErrorCode(inStream.getInteger());
    }

    writeUserLocationStatus(outStream: TSDataOutputStream, obj: UserLocationStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsRMJoinTableAllowed());
        outStream.putByte(obj.getLocationStatus());
        outStream.putBoolean(obj.getIsPMJoinTableAllowed());
        outStream.putInteger(obj.getErrorCode());
    }
    readShowPopup(inStream: TSDataInputStream, obj: ShowPopup): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPopupId(inStream.getInteger());
        obj.setDescription(inStream.getString());
    }

    writeShowPopup(outStream: TSDataOutputStream, obj: ShowPopup): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPopupId());
        outStream.putString(obj.getDescription());
    }
    readResponseGeolocationStatus(inStream: TSDataInputStream, obj: ResponseGeolocationStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
    }

    writeResponseGeolocationStatus(outStream: TSDataOutputStream, obj: ResponseGeolocationStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
    }
    readResponseBuyMoreChipsGeoLocationBlocked(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsGeoLocationBlocked): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseBuyMoreChipsGeoLocationBlocked(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsGeoLocationBlocked): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readSessionLimitNotification(inStream: TSDataInputStream, obj: SessionLimitNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSessionStatus(inStream.getInteger());
        obj.setUserSessionLimit(inStream.getLong());
        obj.setSessionTimeElapsed(inStream.getLong());
        obj.setLoggedInSince(inStream.getLong());
        obj.setIsTimeLimitUpdated(inStream.getBoolean());
    }

    writeSessionLimitNotification(outStream: TSDataOutputStream, obj: SessionLimitNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getSessionStatus());
        outStream.putLong(obj.getUserSessionLimit());
        outStream.putLong(obj.getSessionTimeElapsed());
        outStream.putLong(obj.getLoggedInSince());
        outStream.putBoolean(obj.getIsTimeLimitUpdated());
    }
    readSessionReset(inStream: TSDataInputStream, obj: SessionReset): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeSessionReset(outStream: TSDataOutputStream, obj: SessionReset): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readSessionTimeRemainingNotification(inStream: TSDataInputStream, obj: SessionTimeRemainingNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setReminderEventType(inStream.getInteger());
    }

    writeSessionTimeRemainingNotification(outStream: TSDataOutputStream, obj: SessionTimeRemainingNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getReminderEventType());
    }
    readResponseBuyMoreChipsSessiontimeExpired(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsSessiontimeExpired): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLimitType(inStream.getString());
    }

    writeResponseBuyMoreChipsSessiontimeExpired(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsSessiontimeExpired): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLimitType());
    }
    readResponseAddonRebuyFailure(inStream: TSDataInputStream, obj: ResponseAddonRebuyFailure): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseAddonRebuyFailure(outStream: TSDataOutputStream, obj: ResponseAddonRebuyFailure): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseBuyMoreChipsLossLimitExceeded(inStream: TSDataInputStream, obj: ResponseBuyMoreChipsLossLimitExceeded): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseBuyMoreChipsLossLimitExceeded(outStream: TSDataOutputStream, obj: ResponseBuyMoreChipsLossLimitExceeded): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
        outStream.putStringEx(obj.getDesc());
    }
    readBlockPlayerOnInvalidLocation(inStream: TSDataInputStream, obj: BlockPlayerOnInvalidLocation): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setGameDetails(inStream.getString());
    }

    writeBlockPlayerOnInvalidLocation(outStream: TSDataOutputStream, obj: BlockPlayerOnInvalidLocation): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getGameDetails());
    }
    readRequestSessionLimitsData(inStream: TSDataInputStream, obj: RequestSessionLimitsData): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSessionLimitsData(outStream: TSDataOutputStream, obj: RequestSessionLimitsData): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseSessionLimitsData(inStream: TSDataInputStream, obj: ResponseSessionLimitsData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDailySessionLimit(inStream.getInteger());
        obj.setMonthlySessionLimit(inStream.getInteger());
        obj.setWeeklySessionLimit(inStream.getInteger());
        obj.setDailyDepositLimit(inStream.getInteger());
        obj.setMonthlyDepositLimit(inStream.getInteger());
        obj.setWeeklyDepositLimit(inStream.getInteger());
        obj.setMaximumCreditBal(inStream.getInteger());
        obj.setAccountName(inStream.getString());
    }

    writeResponseSessionLimitsData(outStream: TSDataOutputStream, obj: ResponseSessionLimitsData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getDailySessionLimit());
        outStream.putInteger(obj.getMonthlySessionLimit());
        outStream.putInteger(obj.getWeeklySessionLimit());
        outStream.putInteger(obj.getDailyDepositLimit());
        outStream.putInteger(obj.getMonthlyDepositLimit());
        outStream.putInteger(obj.getWeeklyDepositLimit());
        outStream.putInteger(obj.getMaximumCreditBal());
        outStream.putString(obj.getAccountName());
    }
    readNetPositionDetails(inStream: TSDataInputStream, obj: NetPositionDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrentBalance(inStream.getLong());
        obj.setInitialBalance(inStream.getLong());
        obj.setTotalStake(inStream.getLong());
        obj.setLoss(inStream.getLong());
        obj.setProfit(inStream.getLong());
    }

    writeNetPositionDetails(outStream: TSDataOutputStream, obj: NetPositionDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getCurrentBalance());
        outStream.putLong(obj.getInitialBalance());
        outStream.putLong(obj.getTotalStake());
        outStream.putLong(obj.getLoss());
        outStream.putLong(obj.getProfit());
    }
    readForceLogOutRequest(inStream: TSDataInputStream, obj: ForceLogOutRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
    }

    writeForceLogOutRequest(outStream: TSDataOutputStream, obj: ForceLogOutRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
    }
    readMultiConnectionPostLoginSyncupMessage(inStream: TSDataInputStream, obj: MultiConnectionPostLoginSyncupMessage): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeMultiConnectionPostLoginSyncupMessage(outStream: TSDataOutputStream, obj: MultiConnectionPostLoginSyncupMessage): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseElapsedTime(inStream: TSDataInputStream, obj: ResponseElapsedTime): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActiveStatus(inStream.getBoolean());
        obj.setFirstActivatedTime(inStream.getLong());
    }

    writeResponseElapsedTime(outStream: TSDataOutputStream, obj: ResponseElapsedTime): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getActiveStatus());
        outStream.putLong(obj.getFirstActivatedTime());
    }
    readBigWinNotification(inStream: TSDataInputStream, obj: BigWinNotification): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrenctBalance(inStream.getLong());
        obj.setWinAmount(inStream.getLong());
        obj.setCurrency(inStream.getString());
        obj.setThresholdValue(inStream.getLong());
        obj.setBigWinTrackingId(inStream.getLong());
        obj.setTransactionTime(inStream.getTimestamp());
        obj.setLocationEvent(inStream.getString());
        obj.setPositionEvent(inStream.getString());
    }

    writeBigWinNotification(outStream: TSDataOutputStream, obj: BigWinNotification): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getCurrenctBalance());
        outStream.putLong(obj.getWinAmount());
        outStream.putString(obj.getCurrency());
        outStream.putLong(obj.getThresholdValue());
        outStream.putLong(obj.getBigWinTrackingId());
        outStream.putTimestamp(obj.getTransactionTime());
        outStream.putString(obj.getLocationEvent());
        outStream.putString(obj.getPositionEvent());
    }
}
