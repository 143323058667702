import { Message } from '../../message';
import { NetRealBalance } from './NetRealBalance';
import { PlayBalance } from './PlayBalance';
import { TourneyCurrencyBalance } from './TourneyCurrencyBalance';

export class ResponseNetUserBalanceInfo extends Message {
    private MESSAGE_NAME: string = 'ResponseNetUserBalanceInfo';
    private playBalance: PlayBalance | null = null;
    private netRealBalance: NetRealBalance | null = null;
    private tourneyCurrencyBalance: TourneyCurrencyBalance | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playBalance: PlayBalance | null = null,
        netRealBalance: NetRealBalance | null = null,
        tourneyCurrencyBalance: TourneyCurrencyBalance | null = null,
    ) {
        super(reqServerPeerId);
        this.playBalance = playBalance;
        this.netRealBalance = netRealBalance;
        this.tourneyCurrencyBalance = tourneyCurrencyBalance;
    }

    getPlayBalance(): PlayBalance | null {
        return this.playBalance;
    }

    setPlayBalance(playBalance: PlayBalance | null) {
        this.playBalance = playBalance;
    }
    getNetRealBalance(): NetRealBalance | null {
        return this.netRealBalance;
    }

    setNetRealBalance(netRealBalance: NetRealBalance | null) {
        this.netRealBalance = netRealBalance;
    }
    getTourneyCurrencyBalance(): TourneyCurrencyBalance | null {
        return this.tourneyCurrencyBalance;
    }

    setTourneyCurrencyBalance(tourneyCurrencyBalance: TourneyCurrencyBalance | null) {
        this.tourneyCurrencyBalance = tourneyCurrencyBalance;
    }
}
