import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class PokerBlogFeedService {
    constructor(private api: PokerApiService) {}

    getBlogFeedByCategoryName(categoryName?: string): Observable<any> {
        return this.api.get(`blog/GetBlogFeedCategoryData?categoryName=${categoryName}`);
    }
}
