import { Message } from '../../message';

export class ResponseSNGPromoUpdate extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGPromoUpdate';
    private promoSNGTemplates: any[] | null = null;
    private deletedSNGTemplateIds: any[] | null = null;
    private promoName: any | null = null;
    private promotionId: number = 0;
    private lastSnapShotTimeInNanos: number = 0;
    private isFullUpdate: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        promoSNGTemplates: any[] | null = null,
        deletedSNGTemplateIds: any[] | null = null,
        promoName: any | null = null,
        promotionId: number = 0,
        lastSnapShotTimeInNanos: number = 0,
        isFullUpdate: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.promoSNGTemplates = promoSNGTemplates;
        this.deletedSNGTemplateIds = deletedSNGTemplateIds;
        this.promoName = promoName;
        this.promotionId = promotionId;
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
        this.isFullUpdate = isFullUpdate;
    }

    getPromoSNGTemplates(): any[] | null {
        return this.promoSNGTemplates;
    }

    setPromoSNGTemplates(promoSNGTemplates: any[] | null) {
        this.promoSNGTemplates = promoSNGTemplates;
    }
    getDeletedSNGTemplateIds(): any[] | null {
        return this.deletedSNGTemplateIds;
    }

    setDeletedSNGTemplateIds(deletedSNGTemplateIds: any[] | null) {
        this.deletedSNGTemplateIds = deletedSNGTemplateIds;
    }
    getPromoName(): any | null {
        return this.promoName;
    }

    setPromoName(promoName: any | null) {
        this.promoName = promoName;
    }
    getPromotionId(): number {
        return this.promotionId;
    }

    setPromotionId(promotionId: number) {
        this.promotionId = promotionId;
    }
    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
    getIsFullUpdate(): boolean | null {
        return this.isFullUpdate;
    }

    setIsFullUpdate(isFullUpdate: boolean | null) {
        this.isFullUpdate = isFullUpdate;
    }
}
