import { IConnectorDelegate } from '../pgConnectors/sal/interfaces/IconnectorDelegate';
import { Message } from '../pgConnectors/server/common/message';

export class ConnectorDelegateProxy implements IConnectorDelegate {
    private destination: IConnectorDelegate;

    constructor(destination: IConnectorDelegate) {
        this.destination = destination;
    }

    replaceDestination(destination: IConnectorDelegate) {
        this.destination = destination;
    }

    handleMessage(message: Message, peerID: number, domain: number) {
        this.destination.handleMessage(message, peerID, domain);
    }

    updateConnectionStatus(domainId: number, status: number) {
        this.destination.updateConnectionStatus(domainId, status);
    }

    async connectionSuccessforServer(serverIP: string): Promise<void> {
        await this.destination.connectionSuccessforServer(serverIP);
    }

    log(cause: number, logMessage: string) {
        this.destination.log(cause, logMessage);
    }

    handshakeResponseStatus(success: boolean, domain: number) {
        this.destination.handshakeResponseStatus(success, domain);
    }

    // getDomainsFromApp(gsDomains: Map<string, string>){
    //     this.destination.getDomainsFromApp(gsDomains);
    // };
}
