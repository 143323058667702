import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pgc from './ppl-gallery-container.component';

@Injectable({ providedIn: 'root' })
export class PPLGalleryContainerPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('PPLGalleryContainer', pgc.PPLGalleryContainerComponent);
        });
    }
}
