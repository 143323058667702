import { Message } from '../../message';

export class ReqThrowAnimation extends Message {
    private MESSAGE_NAME: string = 'ReqThrowAnimation';
    private animationId: number = 0;
    private destinationPlayerSeatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, animationId: number = 0, destinationPlayerSeatNo: number = 0) {
        super(reqServerPeerId);
        this.animationId = animationId;
        this.destinationPlayerSeatNo = destinationPlayerSeatNo;
    }

    getAnimationId(): number {
        return this.animationId;
    }

    setAnimationId(animationId: number) {
        this.animationId = animationId;
    }
    getDestinationPlayerSeatNo(): number {
        return this.destinationPlayerSeatNo;
    }

    setDestinationPlayerSeatNo(destinationPlayerSeatNo: number) {
        this.destinationPlayerSeatNo = destinationPlayerSeatNo;
    }
}
