//This viewModel keeps a track of various popup containers and invokes the corresponding actons of view
//restrict the functionality of this vm to operate any actions like addPopup, deletePopUp, modidyPopUp,

/*
VM: 
	Maintains a track of all GenericPopUps this.popUpIdMap
	Maintains priorities of all popUps
VM -> View:
	addPopUp()
	deletePopUp()
	focusPopUp()
View -> VM:
	removePopUp()
View:
	Creates a different container for each popUp,
	deletes container, hides the container.
	dispatches the event in actionsArr when action is performed on any button and removes the popUp
*/
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class GenericPopUpViewModel {
    gameService;
    EventDispatcher;
    serialNumber;
    popUpIdMap; //Id -> priority Map
    currenttable;
    constructor(gs, EventDispatcher) {
        this.gameService = gs;
        this.EventDispatcher = EventDispatcher;
        this.serialNumber = 0;
        this.popUpIdMap = {}; //Id -> priority Map
        this.currenttable = 'table' + this.gameService.getPeerId();
    }
    setView = function (view) {
        this.view = view;
    };
    addPopUp = function (obj) {
        if (this.popUpIdMap[obj.popUpId]) {
            this.view.focusPopUp(obj.popUpId);
            return;
        }
        this.view.addPopUp(obj);
        if (!obj.priority) obj.priority = serverIdConstants.PRIORITY_NONE;
        this.popUpIdMap[obj.popUpId] = {
            priority: parseInt(obj.priority),
            sNo: this.serialNumber,
        };
        this.view.focusPopUp(this.getTopPriorityPopUp());
        this.serialNumber++;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RATHOL_BUYIN, this.onRathBuyin.bind(this));
    };
    onRathBuyin = function () {
        this.view.onRathBuyin();
    };
    removePopUp = function (popUpId) {
        if (!this.popUpIdMap) return;
        this.view.deletePopUp(popUpId);
        this.popUpIdMap[popUpId] = undefined;
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.popUpIdMap[popUpId];
        this.view.focusPopUp(this.getTopPriorityPopUp());
    };
    //returns popUp Id with top priority and latest sNo
    getTopPriorityPopUp = function () {
        let topPriority = serverIdConstants.PRIORITY_NONE;
        let topPriorityPopUp;
        for (const popUpId in this.popUpIdMap) {
            if (popUpId === undefined) return popUpId;
            if (!topPriorityPopUp) topPriorityPopUp = popUpId;
            const priority = this.popUpIdMap[popUpId].priority;
            if (topPriority < priority) {
                topPriority = priority;
                topPriorityPopUp = popUpId;
            } else if (topPriority == priority) {
                if (this.popUpIdMap[popUpId].sNo > this.popUpIdMap[topPriorityPopUp].sNo) topPriorityPopUp = popUpId;
            }
        }
        return topPriorityPopUp;
    };
}
