import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class OptionSet extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.OPTION_SET;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.OPTION_SET;
    }
    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.playerStatus = this.getPlayerStatus();
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.playerStatus = this.getPlayerStatus();
    }
}
