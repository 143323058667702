import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class BetViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_GAME_RESIZE, this.onResize.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_BLIND_DATA, this.handleBlindData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_ANTE_DATA, this.handleAnteData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_END_THE_ROUND, this.onEndTheRound.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION, this.handleSelectedOption.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.FORCE_REFRESH_TABLE, this.forceRefreshTable.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_POT_WINNERS, this.callYouWinAni.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_NEW_GAME, this.callResetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_FULL_GAME_CLOSE, this.handleFullGameClose.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_POT_AMOUNT, this.hidePotAmount.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_POT_AMOUNT, this.showPotAmount.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.PLAYER_CONTAINER_POS, this.checkPlayerPos.bind(this));
        this.peerId = this.gameService.getPeerId();
        this.view.currentTablePeerId(this.peerId);
    };
    checkPlayerPos = function (data) {
        this.view.checkPlayerPos(data);
    };
    hidePotAmount = function () {
        this.view.hidePotAmount();
    };
    showPotAmount = function () {
        this.view.showPotAmount();
    };
    forceRefreshTable = function () {
        const userBetsMap = [] as any;
        for (let clientSeatNo = 0; clientSeatNo < this.gameService.getMaxNoOfSeats(); clientSeatNo++) {
            userBetsMap[clientSeatNo] = this.gameService.getBetVector(this.gameService.getServerSideSeatPos(clientSeatNo));
        }
        const pots = this.gameService.getPots();
        this.view.refreshUserBetsAndPots(userBetsMap, pots);
    };
    callResetGame = function () {
        this.view.resetGame();
    };
    callYouWinAni = function () {
        //this.view.winnerpotsTriggered();
        const uncalledBetPotNo = this.gameService.getUncalledBetPotNo();
        const potWinnersData = this.gameService.getPotWinnersData();
        let isUncalledAmount = false;
        if (uncalledBetPotNo && uncalledBetPotNo == potWinnersData.potNumber) isUncalledAmount = true;
        this.view.callYouWinAni(potWinnersData.winners, isUncalledAmount);
    };
    handleSelectedOption = function () {
        this.view.handleSelectedOption(this.gameService.getOtherPlayerSelectedOption());
    };
    onEndTheRound = function () {
        const endTheRoundData = this.gameService.getEndTheRoundData();
        this.checkForUncalledBets(endTheRoundData);
        this.view.onEndTheRound(endTheRoundData);
        this.gameService.resetBetVector();
    };
    checkForUncalledBets = function (endTheRoundData) {
        //@Maniraj start here for uncalled bet case
        //if(endTheRoundData.roundCount = serverIdConstants.BETTING_ROUND_RIVER){
        if (endTheRoundData.sidePots.length > 0) {
            const seatNoToBetMap = this.gameService.getSeatNoToBetAmtMap();
            let maxBet1 = 0;
            let maxBet2 = 0;
            for (const seatNo in seatNoToBetMap) {
                if (maxBet1 < seatNoToBetMap[seatNo]) {
                    maxBet2 = maxBet1;
                    maxBet1 = seatNoToBetMap[seatNo];
                } else if (maxBet2 < seatNoToBetMap[seatNo]) {
                    maxBet2 = seatNoToBetMap[seatNo];
                }
            }
            const diffBetAmt = maxBet1 - maxBet2;
            const sidePotAmt = endTheRoundData.sidePots[endTheRoundData.sidePots.length - 1].amount;
            if (diffBetAmt == sidePotAmt) {
                this.gameService.setUncalledBetPotNo(endTheRoundData.sidePots[endTheRoundData.sidePots.length - 1].potNo);
            } else if (diffBetAmt == endTheRoundData.currentPotAmount /*&& endTheRoundData.currentPotAmount!= sidePotAmt*/) {
                this.gameService.setUncalledBetPotNo(endTheRoundData.sidePots[endTheRoundData.sidePots.length - 1].potNo + 1);
            }
        }
        //}
    };
    handleGameInit = function () {
        this.view.createBetsView(this.gameService.getBetsArray(), this.gameService.getDefaultBetPos());
    };
    hideChipsContainer = function (flag) {
        if (flag === true) {
            this.view.hideChipsContainer(true);
        } else {
            this.view.hideChipsContainer(false);
        }
    };

    handleEnableAllForNewDeal = function () {
        this.view.hideChipsContainer(true);
    };
    handleBlindData = function () {
        this.view.handleBlindData(this.gameService.getBlidData());
    };
    handleAnteData = function () {
        this.view.handleAnteData(this.gameService.getAnteData());
    };
    onResize = function () {
        //this.view.onResize();
    };
    handleFullGameClose = function () {
        this.view.handleFullGameClose();
    };
    resetGame = function () {
        this.view.resetGame();
    };
}
