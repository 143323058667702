import { Message } from '../../message';

export class PeerTables extends Message {
    private MESSAGE_NAME: string = 'PeerTables';
    private peerId: number = 0;
    private tableIdList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, peerId: number = 0, tableIdList: any[] | null = null) {
        super(reqServerPeerId);
        this.peerId = peerId;
        this.tableIdList = tableIdList;
    }

    getPeerId(): number {
        return this.peerId;
    }

    setPeerId(peerId: number) {
        this.peerId = peerId;
    }
    getTableIdList(): any[] | null {
        return this.tableIdList;
    }

    setTableIdList(tableIdList: any[] | null) {
        this.tableIdList = tableIdList;
    }
}
