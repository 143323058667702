import { Message } from '../../message';

export class QSError extends Message {
    private MESSAGE_NAME: string = 'QSError';
    private requestId: number = 0;
    private errorCode: number = 0;
    private athBlockTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestId: number = 0, errorCode: number = 0, athBlockTime: number = 0) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.errorCode = errorCode;
        this.athBlockTime = athBlockTime;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
    getAthBlockTime(): number {
        return this.athBlockTime;
    }

    setAthBlockTime(athBlockTime: number) {
        this.athBlockTime = athBlockTime;
    }
}
