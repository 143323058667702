import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MessageQueueService, NavigationService, Page, PlainLinkComponent, UserService, WindowRef } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { NavigationLayoutPageComponent } from '@frontend/vanilla/features/navigation-layout';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import {
    NavigationBootstrapService,
    PokerHomePageClientConfig,
    PokerVCClientConfig,
    PromoHubClientConfig,
    ScrollToTopComponent,
    TopNavigationConfig,
    VCClientService,
} from '@pokercore/module/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { myTicketDetailsComponent } from './myticketDetails.component';
import { ConstService } from './mytickets.model';
import { TicketsTruncatePipe } from './mytickets.pipe';
import { pokerTicketsResolver } from './mytickets.resolver';
import { MyTicketsService } from './mytickets.service';

@Component({
    selector: 'pk-my-tickets',
    templateUrl: 'mytickets.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ScrollToTopComponent,
        MessagePanelComponent,
        NavigationLayoutPageComponent,
        PlainLinkComponent,
        TicketsTruncatePipe,
        TrustAsHtmlPipe,
        myTicketDetailsComponent,
    ],
    providers: [pokerTicketsResolver],
})
export class MyTicketsComponent implements OnInit {
    inItData: any;
    allTickets: any;
    siteCoreContent: any;
    showfilters: boolean = false;
    wrapperCount: number;
    ticketdetails: any;
    parentTicketDetails: any;
    selectedSubTicket: any;
    selectedSubTicketId: any;
    selectedSubSngTicketId: any;
    ticketFilterData: any;
    selectedItem: any;
    selectedFilterValue: any;
    selectedValue: any;
    showTicketDetails: boolean = false;
    currency: string;
    landingPageUrl: any;
    noTicketsInfo: any;
    mobileNativeApp: boolean;
    MacDesktop: boolean;
    prevUrl: string;
    isPromoHub: boolean;
    previousUrl: string;
    disableTopNavigation: any;
    disableTopNav: string = 'Always';
    disableHtmlHeadTag: boolean = false;
    showConfigureNavigation: boolean = false;
    isUSNJ: boolean = false;
    private readonly logger: PokerLogger;

    constructor(
        private routeData: RouteDataService,
        private myTicketService: MyTicketsService,
        private messageQueue: MessageQueueService,
        private userService: UserService,
        private windowref: WindowRef,
        private vCClientService: VCClientService,
        private navigationBootstrap: NavigationBootstrapService,
        private navigation: NavigationService,
        private pokerVCClientConfig: PokerVCClientConfig,
        private promoHubClientConfig: PromoHubClientConfig,
        private topNavigationConfig: TopNavigationConfig,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private page: Page,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('MyTicketsLoggingComponent');
    }

    ngOnInit(): void {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.inItData = this.routeData.getInitData();
        if (!this.inItData.ticketsContent) {
            this.dataResolve().subscribe({
                next: (data: any) => {
                    this.inItData = data;
                    this.initMethod();
                },
                error: (error: any) => {
                    console.error('Error fetching data:', error);
                },
            });
        } else {
            this.initMethod();
        }
    }

    initMethod() {
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;
        this.allTickets = this.inItData.tournamentTickets;
        this.siteCoreContent = this.inItData.ticketsContent;
        this.isUSNJ = this.inItData.isUSNJ;
        this.landingPageUrl = '/' + this.userService.lang + '/poker';
        this.isPromoHub = this.promoHubClientConfig.isPromoHub;
        this.topNavigationConfig.whenReady.subscribe(() => {
            this.disableTopNavigation = this.topNavigationConfig.disableTopNavigation;
            if (this.disableTopNavigation.toLowerCase() === 'true') {
                this.disableTopNav = 'Never'; //true;
            } else {
                this.disableTopNav = 'Always'; //false;
            }
        });

        this.ticketFilterData = [
            {
                title: this.siteCoreContent.messages.allTickets,
                value: ConstService.ALL_TICKETS,
                count: this.ActiveTicketsCount(),
            },
        ];
        if (this.siteCoreContent.messages.tournaments !== undefined) {
            this.ticketFilterData.push({
                title: this.siteCoreContent.messages.tournaments,
                value: ConstService.TOURNAMENTS_SITNGO,
                count: this.TrnyTicketsCount(),
            });
        }
        if (this.siteCoreContent.messages.sitngo !== undefined) {
            this.ticketFilterData.push({
                title: this.siteCoreContent.messages.sitngo,
                value: ConstService.SITNGO,
                count: this.SngTicketsCount(),
            });
        }
        if (this.siteCoreContent.messages.sitngohero !== undefined) {
            this.ticketFilterData.push({
                title: this.siteCoreContent.messages.sitngohero,
                value: ConstService.SITNGO_HERO,
                count: this.SngJackpotTicketsCount(),
            });
        }
        if (this.siteCoreContent.messages.inactiveTickets !== undefined) {
            this.ticketFilterData.push({
                title: this.siteCoreContent.messages.inactiveTickets,
                value: ConstService.INACTIVE_TICKETS,
                count: this.InactiveTicketsCount(),
            });
        }
        if (this.siteCoreContent.messages.offlineTickets !== undefined) {
            this.ticketFilterData.push({
                title: this.siteCoreContent.messages.offlineTickets,
                value: ConstService.OFFLINE_TICKETS,
                count: this.offlineTicketsCount(),
            });
        }

        if (localStorage.userInfo) {
            const userInfo = JSON.parse(localStorage.userInfo);
            if (userInfo.userName === this.userService.username) {
                // let initItem: any = {
                //     'title': userInfo.filterTitle,
                //     'value': userInfo.filterValue
                // }
                this.selectFilters(this.ticketFilterData[0], true);
            } else {
                const initItem: any = {
                    title: this.siteCoreContent.messages.allTickets,
                    value: ConstService.ALL_TICKETS,
                };
                this.selectFilters(initItem, false);
                this.selectFilters(this.ticketFilterData[0], true);
            }
        } else {
            const initItem: any = {
                title: this.siteCoreContent.messages.allTickets,
                value: ConstService.ALL_TICKETS,
            };
            this.selectFilters(initItem, false);
        }

        this.previousUrl = this.navigationBootstrap.getPreviousUrl();
        this.mobileNativeApp = this.vCClientService.isMobileApp();
        this.MacDesktop = this.vCClientService.isMacDesktop();
    }

    dataResolve() {
        return this.myTicketService.getAllTickets();
    }

    goToBack(event: Event) {
        if (
            this.previousUrl &&
            this.previousUrl.indexOf('promo') > 0 &&
            (this.previousUrl.indexOf('promo/lobby') < 0 || this.previousUrl.indexOf('promo/offers') < 0)
        ) {
            if (this.isPromoHub && this.previousUrl.indexOf('promo/offers') < 0) {
                this.previousUrl = this.previousUrl + this.page.lang + '/promo/offers';
            } else if (!this.isPromoHub && this.previousUrl.indexOf('promo/lobby') < 0) {
                this.previousUrl = this.previousUrl + this.page.lang + '/promo/lobby';
            }
            this.navigation.goTo(this.previousUrl);
        } else if (this.previousUrl) {
            this.navigation.goTo(this.previousUrl);
        } else {
            event.preventDefault();
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTicketDetails(event: any, ticketId: string, trnyCount: number) {
        const target = event.currentTarget;
        const parentEle = target.parentElement.parentElement.parentElement;

        if (parentEle.className.indexOf('active') > -1) {
            parentEle.classList.remove('active');
            target.classList.remove('in-view');
        } else {
            // API Call
            this.myTicketService.getTicketDetails(ticketId).subscribe(
                (response: any) => {
                    const activeEle = document.querySelector('.ticketContainer.active');
                    const defaultTicketInView = document.querySelector('.defaultTicket.in-view');

                    if (activeEle && defaultTicketInView) {
                        activeEle.classList.remove('active');
                        defaultTicketInView.classList.remove('in-view');
                    }
                    this.selectedSubTicket = null;
                    this.ticketdetails = response.tournamentTicketDetails;
                    // add active class to ticket to show the ticket details view
                    parentEle.className += ' active';
                    target.className += ' in-view';

                    if (!this.selectedSubTicket) {
                        this.selectedSubTicket = this.ticketdetails[0];
                        this.selectedSubTicketId = this.ticketdetails[0].mtctLobbyData.mtctId;
                        this.selectedSubSngTicketId = this.ticketdetails[0].mtctLobbyData.mtsgId;
                    }
                    this.parentTicketDetails = response.tournamentticket;

                    const windowWidth = document.documentElement.clientWidth;
                    //let lastElement:any = document.querySelector('.ticketContainer.active');
                    if (windowWidth < 768) {
                        const timerID = setInterval(function () {
                            window.scrollBy(0, 50);
                            if (window.pageYOffset >= parentEle.offsetTop + parentEle.offsetHeight / 2) clearInterval(timerID);
                        }, 13);
                    }
                },
                (error: any) => {
                    this.messageQueue.clear();
                    if (error.errorCode === '403') {
                        this.messageQueue.add(error.vnMessages[0].html);
                    } else if (error.errorCode === 'G-500') {
                        this.messageQueue.add(error.vnMessages[0].html);
                    } else if (!error.hasOwnProperty('errorCode')) {
                        this.messageQueue.add(error.message);
                    } else {
                        this.messageQueue.add(error.data.common.messages[0]);
                    }
                },
            );
        }
    }
    setToolTipPosition() {
        const tooltipTextHeight = (document.getElementById('tooltip-text') as HTMLElement).offsetHeight;
        const availableHeight = (document.getElementById('tooltip') as HTMLElement).getBoundingClientRect().top - 10;
        if (tooltipTextHeight > availableHeight) {
            (document.getElementById('tooltip-text') as HTMLElement).classList.remove('tooltiptop');
            (document.getElementById('tooltip-text') as HTMLElement).classList.add('tooltipbottom');
        } else {
            (document.getElementById('tooltip-text') as HTMLElement).classList.add('tooltiptop');
            (document.getElementById('tooltip-text') as HTMLElement).classList.remove('tooltipbottom');
        }
    }

    onSubTicketClick(ticket: any, subTicketId: any, subSngTicketId: any) {
        this.selectedSubTicket = ticket;
        this.selectedSubTicketId = subTicketId;
        this.selectedSubSngTicketId = subSngTicketId;
    }
    statusFilter(ticketStatus: any) {
        let key: any,
            value: any = '';
        for (key in ticketStatus) {
            if (ticketStatus[key] > 0) {
                switch (key) {
                    case 'days':
                        value = 'days';
                        break;
                    case 'hours':
                        value = 'hours';
                        if (ticketStatus['mins'] > 0) {
                            value += ' mins';
                        }
                        break;
                    case 'mins':
                        value = 'mins';
                        break;
                    case 'secs':
                        value = 'secs';
                        break;
                }
                if (value.length > 0) {
                    return value;
                }
            }
        }
    }

    showFilters(event: any) {
        const windowWidth = document.documentElement.clientWidth;

        const targetClass: any = event.target.className;
        if (targetClass.indexOf('back-arrow') > -1) {
            this.showfilters = false;
            if (windowWidth < 768) {
                (document.querySelector('body') as HTMLElement).classList.remove('tickets-filters-fixed');
            }
        } else if (targetClass.indexOf('ticketFilterBtn') > -1) {
            this.showfilters = true;
            if (windowWidth < 768) {
                (document.querySelector('body') as HTMLElement).classList.add('tickets-filters-fixed');
            }
        }
    }
    hideFilters() {
        this.showfilters = false;
        const windowWidth = document.documentElement.clientWidth;
        if (windowWidth < 768) {
            (document.querySelector('body') as HTMLElement).classList.remove('tickets-filters-fixed');
        }
    }

    selectFilters(item: any, userClick: boolean) {
        this.selectedItem = item;

        if (this.selectedItem.value == -1) {
            this.allTickets = this.filterInactiveTickets();
            this.noTicketsInfo = this.siteCoreContent.messages.noInactiveTickets;
        } else if (this.selectedItem.value == 0) {
            this.allTickets = this.filterTrnyTickets();
            this.noTicketsInfo = this.siteCoreContent.messages.noSngOrTrnyTickets;
        } else if (this.selectedItem.value == 2) {
            this.allTickets = this.filterSngTickets();
            this.noTicketsInfo = this.siteCoreContent.messages.noSngTickets;
        } else if (this.selectedItem.value == 3) {
            this.allTickets = this.filterSngJackpotTickets();
            this.noTicketsInfo = this.siteCoreContent.messages.noSngJackpotTickets;
        } else if (this.selectedItem.value == 9999) {
            this.allTickets = this.filterActiveTickets();
            this.noTicketsInfo = this.siteCoreContent.messages.noActiveTickets;
        } else {
            this.allTickets = this.offlineTickets();
            this.noTicketsInfo = this.siteCoreContent.messages.noOfflineTickets;
        }

        if (userClick) {
            const user: any = {
                userName: this.userService.username,
                filterValue: item.value,
                filterTitle: item.title,
            };
            localStorage.setItem('userInfo', JSON.stringify(user));
        }

        this.hideFilters();
    }

    filterTrnyTickets() {
        const tickets = this.inItData.tournamentTickets;
        const TrnyTickets: any = [];
        for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].tktDlt.ticketType == 'SngOrTrny' &&
                tickets[i]?.trnyDlt?.mtctLobbyData?.tournamentCategory == 0 &&
                tickets[i].trnyCount != 0 &&
                tickets[i].tktDlt.isOfflineEvent != 1 &&
                tickets[i].tktDlt.freeRollCount != 0
            ) {
                TrnyTickets.push(tickets[i]);
            }
        }
        return TrnyTickets;
    }
    TrnyTicketsCount() {
        const tickets = this.inItData.tournamentTickets;
        let TrnyTicketsPillCount = 0;
        for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].tktDlt.ticketType == 'SngOrTrny' &&
                tickets[i]?.trnyDlt?.mtctLobbyData?.tournamentCategory == 0 &&
                tickets[i].trnyCount != 0 &&
                tickets[i].tktDlt.isOfflineEvent != 1 &&
                tickets[i].tktDlt.freeRollCount != 0
            ) {
                TrnyTicketsPillCount = TrnyTicketsPillCount + tickets[i].tktDlt.freeRollCount;
            }
        }
        return TrnyTicketsPillCount;
    }
    filterSngTickets() {
        const tickets = this.inItData.tournamentTickets;
        const SngTickets: any = [];
        for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].tktDlt.ticketType == 'SngOrTrny' &&
                tickets[i]?.trnyDlt?.mtctLobbyData?.tournamentCategory == 1 &&
                tickets[i].trnyCount != 0 &&
                tickets[i].tktDlt.freeRollCount != 0 &&
                tickets[i].tktDlt.isOfflineEvent != 1
            ) {
                SngTickets.push(tickets[i]);
            }
        }
        return SngTickets;
    }
    SngTicketsCount() {
        const tickets = this.inItData.tournamentTickets;
        let SngTicketsPillCount = 0;
        for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].tktDlt.ticketType == 'SngOrTrny' &&
                tickets[i]?.trnyDlt?.mtctLobbyData?.tournamentCategory == 1 &&
                tickets[i].trnyCount != 0 &&
                tickets[i].tktDlt.freeRollCount != 0 &&
                tickets[i].tktDlt.isOfflineEvent != 1
            ) {
                SngTicketsPillCount = SngTicketsPillCount + tickets[i].tktDlt.freeRollCount;
            }
        }
        return SngTicketsPillCount;
    }

    filterSngJackpotTickets() {
        const tickets = this.inItData.tournamentTickets;
        const SngJackpotTickets: any = [];
        for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].tktDlt.ticketType == 'SngJackpot' &&
                tickets[i]?.trnyDlt?.mtctLobbyData?.tournamentCategory == 3 &&
                tickets[i].trnyCount != 0 &&
                tickets[i].tktDlt.isOfflineEvent != 1 &&
                tickets[i].tktDlt.freeRollCount != 0
            ) {
                SngJackpotTickets.push(tickets[i]);
            }
        }
        return SngJackpotTickets;
    }
    SngJackpotTicketsCount() {
        const tickets = this.inItData.tournamentTickets;
        let SngJackpotTicketsPillCount = 0;
        for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].tktDlt.ticketType == 'SngJackpot' &&
                tickets[i]?.trnyDlt?.mtctLobbyData?.tournamentCategory == 3 &&
                tickets[i].trnyCount != 0 &&
                tickets[i].tktDlt.isOfflineEvent != 1 &&
                tickets[i].tktDlt.freeRollCount != 0
            ) {
                SngJackpotTicketsPillCount = SngJackpotTicketsPillCount + tickets[i].tktDlt.freeRollCount;
            }
        }
        return SngJackpotTicketsPillCount;
    }
    filterInactiveTickets() {
        const tickets = this.inItData.tournamentTickets;
        const inactiveTickets: any = [];
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].trnyCount == 0 && tickets[i].tktDlt.freeRollCount != 0 && tickets[i].tktDlt.isOfflineEvent != 1) {
                inactiveTickets.push(tickets[i]);
            }
        }
        return inactiveTickets;
    }
    InactiveTicketsCount() {
        const tickets = this.inItData.tournamentTickets;
        let InactiveTicketsPillCount = 0;
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].trnyCount == 0 && tickets[i].tktDlt.freeRollCount != 0 && tickets[i].tktDlt.isOfflineEvent != 1) {
                InactiveTicketsPillCount = InactiveTicketsPillCount + tickets[i].tktDlt.freeRollCount;
            }
        }
        return InactiveTicketsPillCount;
    }
    filterActiveTickets() {
        const tickets = this.inItData.tournamentTickets;
        const ActiveTickets: any = [];
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].trnyCount != 0 && tickets[i].tktDlt.freeRollCount != 0 && tickets[i].tktDlt.isOfflineEvent != 1) {
                ActiveTickets.push(tickets[i]);
            }
        }
        return ActiveTickets;
    }
    ActiveTicketsCount() {
        const tickets = this.inItData.tournamentTickets;
        let ActiveTicketsPillCount = 0;
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].trnyCount != 0 && tickets[i].tktDlt.freeRollCount != 0 && tickets[i].tktDlt.isOfflineEvent != 1) {
                ActiveTicketsPillCount = ActiveTicketsPillCount + tickets[i].tktDlt.freeRollCount;
            }
        }
        return ActiveTicketsPillCount;
    }
    offlineTickets() {
        const tickets = this.inItData.tournamentTickets;
        const OfflineTickets: any = [];
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].tktDlt.isOfflineEvent == 1 && tickets[i].tktDlt.freeRollCount != 0) {
                OfflineTickets.push(tickets[i]);
            }
        }
        return OfflineTickets;
    }
    offlineTicketsCount() {
        const tickets = this.inItData.tournamentTickets;
        let offlineTicketsPillCount = 0;
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].tktDlt.isOfflineEvent == 1 && tickets[i].tktDlt.freeRollCount != 0) {
                offlineTicketsPillCount = offlineTicketsPillCount + tickets[i].tktDlt.freeRollCount;
            }
        }
        return offlineTicketsPillCount;
    }

    useTicketNowBtn(tktId: any, sngId: any, tktType: any, trnyCategory: any) {
        if (!this.pokerVCClientConfig) {
            this.logger.error('pokerVCClientConfig is not loaded yet');
            return;
        }
        // if (tktType === 'SngOrTrny') {
        //     tktType = '0';
        // } else {
        //     tktType = '3';
        //     tktId = sngId;
        // }

        if (trnyCategory === 0) {
            tktType = 'MTCT';
        } else if (trnyCategory === 1) {
            tktType = 'SNG';
        } else {
            tktType = 'SNGJP';
            tktId = sngId;
        }
        let myTicketsUrlParam = '';
        const myTicketsUrlParamMap = {
            __tktId__: tktId,
            __tktType__: tktType,
        };
        if (window.navigator.userAgent.indexOf('Windows') > -1) {
            myTicketsUrlParam = this.pokerVCClientConfig.myTicketsQueryParams;
        } else if (window.navigator.userAgent.indexOf('Mac') > -1) {
            myTicketsUrlParam = this.pokerVCClientConfig.macMyTicketsQueryParams;
        }
        myTicketsUrlParam = myTicketsUrlParam.replace(/__tktId__|__tktType__/gi, function (matched: any) {
            return myTicketsUrlParamMap[matched];
        });
        if (this.vCClientService.isClient() || this.vCClientService.isMacDesktop() || this.vCClientService.isMobileApp()) {
            if (tktType === 'SNGJP') {
                tktType = '3';
                const request = {
                    OpenSNGJackpotBuyinDialog: {
                        sngJackpotId: String(sngId),
                        category: tktType,
                    },
                };
                //console.log ("OpenSNGJackpotBuyinDialog", request);
                //this.windowref.nativeWindow.external['NativeDispatch']('OpenSNGJackpotBuyinDialog', 'datatype=json|' + JSON.stringify(request));
                if (this.windowref.nativeWindow.messageToNative) {
                    this.windowref.nativeWindow.messageToNative({ eventName: 'OpenSNGJackpotBuyinDialog', parameters: request });
                } else if (this.windowref.nativeWindow.external) {
                    this.windowref.nativeWindow.external['NativeDispatch']('OpenSNGJackpotBuyinDialog', 'datatype=json|' + JSON.stringify(request));
                }
            } else {
                if (tktType === 'MTCT') {
                    tktType = '0';
                } else if (tktType === 'SNG') {
                    tktType = '1';
                }
                const request = {
                    OpenTournamentLobby: {
                        TournamentID: String(tktId),
                        category: tktType,
                    },
                };
                //this.windowref.nativeWindow.external['NativeDispatch']('OpenTournamentLobby', 'datatype=json|' + JSON.stringify(request));
                //console.log ("OpenTournamentLobby", request);
                if (this.windowref.nativeWindow.messageToNative) {
                    this.windowref.nativeWindow.messageToNative({ eventName: 'OpenTournamentLobby', parameters: request });
                } else if (this.windowref.nativeWindow.external) {
                    this.windowref.nativeWindow.external['NativeDispatch']('OpenTournamentLobby', 'datatype=json|' + JSON.stringify(request));
                }
            }
        } else {
            window.open(this.pokerVCClientConfig.clientLaunchHost + myTicketsUrlParam);
        }
    }
    gotoLobbyBtn() {
        let goToLobbyQueryParams = '';
        if (window.navigator.userAgent.indexOf('Windows') > -1) {
            goToLobbyQueryParams = this.pokerVCClientConfig.goToLobbyQueryParams;
        } else if (window.navigator.userAgent.indexOf('Mac') > -1) {
            goToLobbyQueryParams = this.pokerVCClientConfig.macGoToLobbyQueryParams;
        }
        if (this.vCClientService.isClient() || this.vCClientService.isMacDesktop()) {
            const request = {
                showLobby: {},
            };
            //this.windowref.nativeWindow.external['NativeDispatch']('showLobby', 'datatype=json|' + JSON.stringify(request));
            //window.postMessage({ type: 'showLobby', data: JSON.stringify(request) }, '*');
            if (this.windowref.nativeWindow.messageToNative) {
                this.windowref.nativeWindow.messageToNative({ eventName: 'showLobby', parameters: request });
            } else if (this.windowref.nativeWindow.external) {
                this.windowref.nativeWindow.external['NativeDispatch']('showLobby', 'datatype=json|' + JSON.stringify(request));
            }
        } else {
            window.open(this.pokerVCClientConfig.clientLaunchHost + goToLobbyQueryParams);
        }
    }

    DateConversion(dateValue: any) {
        if (this.isUSNJ) {
            return dateValue.substring(5, 7) + '/' + dateValue.substring(8, 10) + '/' + dateValue.substring(0, 4);
        } else return dateValue.substring(8, 10) + '/' + dateValue.substring(5, 7) + '/' + dateValue.substring(0, 4);
    }
}
