import { Pipe, PipeTransform } from '@angular/core';

import { TournamentCalendarService } from './tournamentcalendar.service';

@Pipe({ name: 'gameNamePipe', standalone: true })
export class gameNamePipe implements PipeTransform {
    constructor(private tournamentCalendarService: TournamentCalendarService) {}
    transform(game: string) {
        const foundGame = this.tournamentCalendarService.values.find((item: any) => item.value === game);
        if (foundGame) {
            return foundGame.text;
        }
        return null;
    }
}
