import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class InfoTableViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        //getting game table type
        this.gTableType = this.gameService.getGameTableType();
        this.gameType = this.gameService.getTableStatusData().gameTypeId; //getting the gameType Id to verify the type of game like short Deck
        this.view.currentGameTableType(this.gTableType, this.gameType);
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.INFO_BUTTON_CLICKED, this.showinfo.bind(this));
    };
    showinfo = function () {
        this.view.showinfo(this.gameService.getSngJPPayoutInfo(), this.gameService.getTableStatusData());
    };
    initiateButtons = function () {};
}
