// import { SocketService, IwebSocket } from "./interfaces/IwebSocket";

export class ConvertSocketUrl {
    public URL_SPEC_HTTP: string;
    public URL_SPEC_WS: string;
    constructor() {
        this.URL_SPEC_HTTP = 'http';
        this.URL_SPEC_WS = 'ws';
    }
    WSURL(url: string) {
        if (url.slice(0, this.URL_SPEC_HTTP.length) === this.URL_SPEC_HTTP) {
            url = this.URL_SPEC_WS + url.slice(this.URL_SPEC_HTTP.length);
            url = url + (url[url.length - 1] === '/' ? 'websocket' : '/websocket');
        }
        return url;
    }
}

// export class ConvertSocketUrlReturn implements SocketService {
//   create(url: string): IwebSocket {
//     console.log("hel");
//       if (typeof WebSocket !== 'undefined') {
//         const wsurl = new ConvertSocketUrl();
//         return new WebSocket(wsurl.WSURL(url));
//       } else {
//           throw new Error("WebSocket support missing")
//       }
//   }
// }
