import { Message } from '../../message';

export class LSTourneyTablePlayer extends Message {
    private MESSAGE_NAME: string = 'LSTourneyTablePlayer';
    private participantNo: number = 0;
    private balance: number = 0;
    private noOfBounties: number = 0;
    private totalBountyWon: number = 0;
    private bountyOnPlayerHead: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        participantNo: number = 0,
        balance: number = 0,
        noOfBounties: number = 0,
        totalBountyWon: number = 0,
        bountyOnPlayerHead: number = 0,
    ) {
        super(reqServerPeerId);
        this.participantNo = participantNo;
        this.balance = balance;
        this.noOfBounties = noOfBounties;
        this.totalBountyWon = totalBountyWon;
        this.bountyOnPlayerHead = bountyOnPlayerHead;
    }

    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
    getNoOfBounties(): number {
        return this.noOfBounties;
    }

    setNoOfBounties(noOfBounties: number) {
        this.noOfBounties = noOfBounties;
    }
    getTotalBountyWon(): number {
        return this.totalBountyWon;
    }

    setTotalBountyWon(totalBountyWon: number) {
        this.totalBountyWon = totalBountyWon;
    }
    getBountyOnPlayerHead(): number {
        return this.bountyOnPlayerHead;
    }

    setBountyOnPlayerHead(bountyOnPlayerHead: number) {
        this.bountyOnPlayerHead = bountyOnPlayerHead;
    }
}
