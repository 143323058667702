import { Message } from '../../message';

export class PrevPlayingGTs extends Message {
    private MESSAGE_NAME: string = 'PrevPlayingGTs';
    private gtList: any[] | null = null;
    private isFullList: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, gtList: any[] | null = null, isFullList: boolean | null = false) {
        super(reqServerPeerId);
        this.gtList = gtList;
        this.isFullList = isFullList;
    }

    getGtList(): any[] | null {
        return this.gtList;
    }

    setGtList(gtList: any[] | null) {
        this.gtList = gtList;
    }
    getIsFullList(): boolean | null {
        return this.isFullList;
    }

    setIsFullList(isFullList: boolean | null) {
        this.isFullList = isFullList;
    }
}
