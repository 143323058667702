import { Message } from '../../message';

export class TBTMinEndTime extends Message {
    private MESSAGE_NAME: string = 'TBTMinEndTime';
    private minEndTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, minEndTime: number = 0) {
        super(reqServerPeerId);
        this.minEndTime = minEndTime;
    }

    getMinEndTime(): number {
        return this.minEndTime;
    }

    setMinEndTime(minEndTime: number) {
        this.minEndTime = minEndTime;
    }
}
