import { Message } from '../../message';

export class LSMTCTFirstRankChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSMTCTFirstRankChangeEvent';
    private mtctId: number = 0;
    private firstRankPlayerName: string | null = null;
    private tourneyEndTime: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, firstRankPlayerName: string | null = null, tourneyEndTime: any | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.firstRankPlayerName = firstRankPlayerName;
        this.tourneyEndTime = tourneyEndTime;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getFirstRankPlayerName(): string | null {
        return this.firstRankPlayerName;
    }

    setFirstRankPlayerName(firstRankPlayerName: string | null) {
        this.firstRankPlayerName = firstRankPlayerName;
    }
    getTourneyEndTime(): any | null {
        return this.tourneyEndTime;
    }

    setTourneyEndTime(tourneyEndTime: any | null) {
        this.tourneyEndTime = tourneyEndTime;
    }
}
