import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
//import { DemoGameTableService } from './game-table/demo-game-table.service';
import { FormsModule } from '@angular/forms';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { CardComponent } from './card/card.component';
import { ChipComponent } from './chip/chip.component';
import { CommunityCardsComponent } from './community-cards/community-cards.component';
import { DealerComponent } from './dealer/dealer.component';
import { DemoGameTableComponent } from './game-table/demo-game-table.component';
import { PlayerPotComponent } from './player-pot/player-pot.component';
import { PlayerPotsComponent } from './player-pots/player-pots.component';
import { PlayerComponent } from './player/player.component';
import { PotComponent } from './pot/pot.component';
import { RakeComponent } from './rake/rake.component';
import { SeatComponent } from './seat/seat.component';

@NgModule({
    imports: [CommonModule, FormsModule, TrustAsHtmlPipe],
    declarations: [
        DemoGameTableComponent,
        ChipComponent,
        PlayerComponent,
        SeatComponent,
        CardComponent,
        DealerComponent,
        PotComponent,
        CommunityCardsComponent,
        PlayerPotsComponent,
        PlayerPotComponent,
        RakeComponent,
    ],
    exports: [DemoGameTableComponent],
})
export class PokerTrainerModule {
    public static forRoot(): ModuleWithProviders<PokerTrainerModule> {
        return {
            ngModule: PokerTrainerModule,
            providers: [],
        };
    }
}
