import { Message } from '../../message';

export class ProgressiveBountyAnimationInfo extends Message {
    private MESSAGE_NAME: string = 'ProgressiveBountyAnimationInfo';
    private koPlayersScrNames: any[] | null = null;
    private winnersBountyValues: Map<any, any> | null = null;
    private allPlayersBountyOnHeadValues: Map<any, any> | null = null;
    private allPlayersBountyTopUpValues: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        koPlayersScrNames: any[] | null = null,
        winnersBountyValues: Map<any, any> | null = null,
        allPlayersBountyOnHeadValues: Map<any, any> | null = null,
        allPlayersBountyTopUpValues: Map<any, any> | null = null,
    ) {
        super(reqServerPeerId);
        this.koPlayersScrNames = koPlayersScrNames;
        this.winnersBountyValues = winnersBountyValues;
        this.allPlayersBountyOnHeadValues = allPlayersBountyOnHeadValues;
        this.allPlayersBountyTopUpValues = allPlayersBountyTopUpValues;
    }

    getKoPlayersScrNames(): any[] | null {
        return this.koPlayersScrNames;
    }

    setKoPlayersScrNames(koPlayersScrNames: any[] | null) {
        this.koPlayersScrNames = koPlayersScrNames;
    }
    getWinnersBountyValues(): Map<any, any> | null {
        return this.winnersBountyValues;
    }

    setWinnersBountyValues(winnersBountyValues: Map<any, any> | null) {
        this.winnersBountyValues = winnersBountyValues;
    }
    getAllPlayersBountyOnHeadValues(): Map<any, any> | null {
        return this.allPlayersBountyOnHeadValues;
    }

    setAllPlayersBountyOnHeadValues(allPlayersBountyOnHeadValues: Map<any, any> | null) {
        this.allPlayersBountyOnHeadValues = allPlayersBountyOnHeadValues;
    }
    getAllPlayersBountyTopUpValues(): Map<any, any> | null {
        return this.allPlayersBountyTopUpValues;
    }

    setAllPlayersBountyTopUpValues(allPlayersBountyTopUpValues: Map<any, any> | null) {
        this.allPlayersBountyTopUpValues = allPlayersBountyTopUpValues;
    }
}
