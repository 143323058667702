import { Message } from '../../message';

export class LSMultiDayChangeData extends Message {
    private MESSAGE_NAME: string = 'LSMultiDayChangeData';
    private currentDay: number = 0;
    private prevDaysRunTime: number = 0;
    private currentDayStartTime: any | null = null;
    private nextDay: number = 0;
    private nextDayResumeLevel: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        currentDay: number = 0,
        prevDaysRunTime: number = 0,
        currentDayStartTime: any | null = null,
        nextDay: number = 0,
        nextDayResumeLevel: number = 0,
    ) {
        super(reqServerPeerId);
        this.currentDay = currentDay;
        this.prevDaysRunTime = prevDaysRunTime;
        this.currentDayStartTime = currentDayStartTime;
        this.nextDay = nextDay;
        this.nextDayResumeLevel = nextDayResumeLevel;
    }

    getCurrentDay(): number {
        return this.currentDay;
    }

    setCurrentDay(currentDay: number) {
        this.currentDay = currentDay;
    }
    getPrevDaysRunTime(): number {
        return this.prevDaysRunTime;
    }

    setPrevDaysRunTime(prevDaysRunTime: number) {
        this.prevDaysRunTime = prevDaysRunTime;
    }
    getCurrentDayStartTime(): any | null {
        return this.currentDayStartTime;
    }

    setCurrentDayStartTime(currentDayStartTime: any | null) {
        this.currentDayStartTime = currentDayStartTime;
    }
    getNextDay(): number {
        return this.nextDay;
    }

    setNextDay(nextDay: number) {
        this.nextDay = nextDay;
    }
    getNextDayResumeLevel(): number {
        return this.nextDayResumeLevel;
    }

    setNextDayResumeLevel(nextDayResumeLevel: number) {
        this.nextDayResumeLevel = nextDayResumeLevel;
    }
}
