import { Location } from '@angular/common';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
// import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterModule } from '@angular/router';

import { MessageQueueService } from '@frontend/vanilla/core';
import { PlainLinkComponent } from '@frontend/vanilla/core';
// import { DeviceService, MetaTagsService, NavigationService, PlainLinkComponent, TrackingService } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { GlobalClientConfig, PokerApiService, TournamentsCalendarClientConfig } from '@pokercore/module/core';
import {
    NavigationBarComponent,
    NavigationBarPokerComponent, // // PokerHomePageClientConfig,
    ScrollToTopComponent,
    TournamentCalendarPagerService,
} from '@pokercore/module/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSliderModule } from 'ngx-slider-v2';

// // import { Subscription } from 'rxjs';
import { limitNamePipe } from './LimitName.pipe';
import { removeColorCodesPipe } from './RemoveColorCodes.pipe';
import { formatCurrencyPipe } from './formatCurrency.pipe';
import { gameNamePipe } from './gameName.pipe';
import { TournamentCalendarFiltersComponent } from './tournament-calendar-filters.component';
import { TournamentCalendarTimerComponent } from './tournament-calendar-timer/tournament-calendar-timer.component';
import { TournamentCalendarResolver } from './tournamentcalendar.resolver';
import { TournamentCalendarService } from './tournamentcalendar.service';
import { WindowScrollingService } from './window-scrolling.service';

@Component({
    selector: 'pt-tournament-details',
    templateUrl: 'tournament-details.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TrustAsHtmlPipe,
        MessagePanelComponent,
        PlainLinkComponent,
        FormsModule,
        NgxDatatableModule,
        NavigationBarComponent,
        NavigationBarPokerComponent,
        TournamentCalendarTimerComponent,
        NgxSliderModule,
        ScrollToTopComponent,
        TournamentCalendarFiltersComponent,
        formatCurrencyPipe,
        gameNamePipe,
        limitNamePipe,
        removeColorCodesPipe,
    ],
    providers: [TournamentCalendarResolver, TournamentCalendarService, TournamentCalendarPagerService, WindowScrollingService],
})
export class TournamentDetailsComponent implements OnInit {
    initdata: any;
    tournament: any;
    tournamentList: any;
    tournamentData: any;
    filteredData: any;
    googleCalendarHost: string;
    yahooCalendarHost: string;
    msnCalendarHost: string;
    pmPokerNavigationView: any;
    formElementTemplateChilds: any;
    detailsImage: any;
    pager: any = {};
    id: any;
    bwinDisplayMode: boolean;
    isPartyNavigation: boolean;

    constructor(
        public tournamentCalendarService: TournamentCalendarService,
        private route: ActivatedRoute,
        private router: Router,
        private api: PokerApiService,
        private messageQueue: MessageQueueService,
        private globalClient: GlobalClientConfig,
        private tournamentsCalendarClientConfig: TournamentsCalendarClientConfig,
        private location: Location,
    ) {}

    ngOnInit() {
        this.tournamentsCalendarClientConfig.whenReady.subscribe(() => {
            this.googleCalendarHost = this.tournamentsCalendarClientConfig.googleCalendarHost;
            this.yahooCalendarHost = this.tournamentsCalendarClientConfig.yahooCalendarHost;
            this.msnCalendarHost = this.tournamentsCalendarClientConfig.msnCalendarHost;
            this.bwinDisplayMode = this.tournamentsCalendarClientConfig.bwinDisplayMode;
            this.isPartyNavigation = this.tournamentsCalendarClientConfig.isPartyNavigation;
        });

        this.pmPokerNavigationView = this.tournamentCalendarService.getPmPokerNavigationView();
        this.formElementTemplateChilds = this.tournamentCalendarService.getFormElementTemplateChilds();
        const isSitecoreInfoRequired: boolean = this.pmPokerNavigationView ? false : true;

        this.id = this.route.snapshot.params['id'];

        this.api.get('tournament/GetDetails', { id: this.id, isSitecoreInfoRequired: isSitecoreInfoRequired }).subscribe(
            (response: any) => {
                // if (isSitecoreInfoRequired) {
                //     this.tournament = response.tournamentCalendarDetailsModel;
                //     this.pmPokerNavigationView = response.pmPokerNavigationView;
                //     this.detailsImage = response.tournamentSitecoreInfo.image.src;
                // }
                // else {
                //     this.tournament = response.tournamentCalendarDetailsModel;
                //     if(response.tournamentSitecoreInfo && response.tournamentSitecoreInfo.image && response.tournamentSitecoreInfo.image.src){
                //         this.detailsImage = response.tournamentSitecoreInfo.image.src;
                //     }
                // }
                if (isSitecoreInfoRequired) {
                    this.tournament = response.tournamentCalendarDetailsModel;
                    this.pmPokerNavigationView = response.pmPokerNavigationView;
                    if (response.tournamentSitecoreInfo && response.tournamentSitecoreInfo.image && response.tournamentSitecoreInfo.image.src) {
                        this.detailsImage = response.tournamentSitecoreInfo.image.src;
                    }
                } else {
                    this.tournament = response.tournamentCalendarDetailsModel;
                    if (response.tournamentSitecoreInfo && response.tournamentSitecoreInfo.image && response.tournamentSitecoreInfo.image.src) {
                        this.detailsImage = response.tournamentSitecoreInfo.image.src;
                    }
                }
            },
            (error: any) => {
                this.messageQueue.addError(
                    this.globalClient.whenReady.subscribe(() => this.globalClient.config.versionedList.TournamentNotFound) + error.errorId,
                );
            },
        );
    }
    goToTournamentCalendar() {
        this.router.navigate(['../../calendar'], { relativeTo: this.route });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showMenu(event: any) {
        (document.getElementById('menu-items') as HTMLElement).style.display = 'block';
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    closeMenu(event: any) {
        (document.getElementById('menu-items') as HTMLElement).style.display = 'none';
    }
    goBack() {
        this.tournamentCalendarService.pageBack = true;
        this.location.back();
    }
}
