import { Message } from '../../message';

export class ChallengeDetails extends Message {
    private MESSAGE_NAME: string = 'ChallengeDetails';
    private challengeId: number = 0;
    private creator: string | null = null;
    private invitees: any[] | null = null;
    private challengeType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        challengeId: number = 0,
        creator: string | null = null,
        invitees: any[] | null = null,
        challengeType: number = 0,
    ) {
        super(reqServerPeerId);
        this.challengeId = challengeId;
        this.creator = creator;
        this.invitees = invitees;
        this.challengeType = challengeType;
    }

    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
    getCreator(): string | null {
        return this.creator;
    }

    setCreator(creator: string | null) {
        this.creator = creator;
    }
    getInvitees(): any[] | null {
        return this.invitees;
    }

    setInvitees(invitees: any[] | null) {
        this.invitees = invitees;
    }
    getChallengeType(): number {
        return this.challengeType;
    }

    setChallengeType(challengeType: number) {
        this.challengeType = challengeType;
    }
}
