import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class RabbitViewModel {
    gameService;
    tableSettingsDataModel;
    constructor(gs, gsd) {
        this.gameService = gs;
        this.tableSettingsDataModel = gsd;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.peerId = this.gameService.getPeerId();
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_RABBIT, this.showRabbit.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.BROADCAST_SHOW_RABBIT, this.broadcastRabbit.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_DIAMOND_BALANCE, this.updateDiamondBalance.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_SETTING_DATA, this.updateRabbitState.bind(this));
        this.enableRabbitFeature = this.tableSettingsDataModel.getGameSettingData().rabbitFeature;
        this.enableRabbitFeature = true;
    };
    updateDiamondBalance = function () {
        const diamondBalance = this.gameService.getDiamondBalance();
        this.view.updateDiamondBalance(diamondBalance === undefined ? 0 : diamondBalance);
    };
    showRabbit = function () {
        if (this.enableRabbitFeature) {
            const rabbitValue = this.gameService.getShowRabbitDetails().rabbitValue;
            this.view.showRabbit(rabbitValue);
        }
    };
    broadcastRabbit = function () {
        const broadcastRabbitSeat = this.gameService.getBroadcastShowRabbit().seatNo;
        const tempSeatPos = this.gameService.getClientSideSeatNo(broadcastRabbitSeat);
        this.view.showBroadcastRabbit(tempSeatPos);
    };
    resetGame = function () {
        this.view.resetGame();
    };
    // update rabbbit enabling from settings panel
    updateRabbitState = function (data) {
        this.enableRabbitFeature = data.detail.rabbitFeature;
    };
}
