import { Message } from '../../message';

export class MTCTLevel extends Message {
    private MESSAGE_NAME: string = 'MTCTLevel';
    private level: number = 0;
    private blindLevelValue: number = 0;
    private smallBlind: number = 0;
    private bigBlind: number = 0;
    private ante: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        level: number = 0,
        blindLevelValue: number = 0,
        smallBlind: number = 0,
        bigBlind: number = 0,
        ante: number = 0,
    ) {
        super(reqServerPeerId);
        this.level = level;
        this.blindLevelValue = blindLevelValue;
        this.smallBlind = smallBlind;
        this.bigBlind = bigBlind;
        this.ante = ante;
    }

    getLevel(): number {
        return this.level;
    }

    setLevel(level: number) {
        this.level = level;
    }
    getBlindLevelValue(): number {
        return this.blindLevelValue;
    }

    setBlindLevelValue(blindLevelValue: number) {
        this.blindLevelValue = blindLevelValue;
    }
    getSmallBlind(): number {
        return this.smallBlind;
    }

    setSmallBlind(smallBlind: number) {
        this.smallBlind = smallBlind;
    }
    getBigBlind(): number {
        return this.bigBlind;
    }

    setBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
    }
    getAnte(): number {
        return this.ante;
    }

    setAnte(ante: number) {
        this.ante = ante;
    }
}
