import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { DynamicHtmlDirective, PlainLinkComponent } from '@frontend/vanilla/core';
import { PCComponent, PageMatrixComponent } from '@frontend/vanilla/features/content';
import { LhHeaderBarComponent } from '@frontend/vanilla/features/header-bar';

@Component({
    selector: 'pt-text-with-header-bar',
    templateUrl: 'pc-text-with-header-bar.component.html',
    standalone: true,
    imports: [CommonModule, PageMatrixComponent, LhHeaderBarComponent, DynamicHtmlDirective, PlainLinkComponent],
})
export class PCTextWithHeaderBarComponent extends PCComponent {}
