import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { DynamicHtmlDirective, NavigationService, Page, PlainLinkComponent } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import {
    NavigationBarComponent,
    NavigationBarPokerComponent,
    PageNotFoundComponent,
    PokerApiService,
    PokerHomePageClientConfig,
    ScrollToTopComponent,
} from '@pokercore/module/core';

import { PokerKoSeriesResolver } from '../poker-promotions/poker-koseries.resolver';
import { PokerMonsterSeriesResolver } from '../poker-promotions/poker-monsterseries.resolver';
import { PokerWPTDeepStacksOnlineResolver } from './poker-WPT-DeepStacks-Online.resolver';
import { PokerCashGamesResolver } from './poker-cashgames.resolver';
import { PokerClubGamesResolver } from './poker-club-games.resolver';
import { PokerDailyLegendsResolver } from './poker-daily-legends.resolver';
import { PokerFastForwardResolver } from './poker-fastforward.resolver';
import { PokerFastPokerResolver } from './poker-fastpoker.resolver';
import { PokerFootballPartyResolver } from './poker-football-party.resolver';
import { PokerGrandPrixKoResolver } from './poker-grand-prix-ko.resolver';
import { PokerGrandPrixUKResolver } from './poker-grand-prix-uk.resolver';
import { PokerHomeGamesResolver } from './poker-home-games.resolver';
import { PokerHotTablesResolver } from './poker-hot-tables.resolver';
import { PokerIrishPokerMastersKoOnlineResolver } from './poker-irish-poker-masters-ko-online.resolver';
import { PokerKoWeekResolver } from './poker-koWeek.resolver';
import { PokerLaNotteDegliAssiResolver } from './poker-la-notte-degli-assi.resolver';
import { PokerLoyaltyResolver } from './poker-loyalty.resolver';
import { PokerMicroSeriesResolver } from './poker-micro-series.resolver';
import { PokerMillionsOnlineKnockoutEditionResolver } from './poker-millions-online-knockout-edition.resolver';
import { PokerMillionsOnlineResolver } from './poker-millionsonline.resolver';
import { PokerMyGameSynopsisResolver } from './poker-my-game-synopsis.resolver';
import { PokerMyGameResolver } from './poker-my-game.resolver';
import { PokerOmahaDailyLegendsResolver } from './poker-omaha-daily-legends.resolver';
import { PokerOmahaSeriesSynopsisResolver } from './poker-omaha-series-synopsis.resolver';
import { PokerOmahaSeriesResolver } from './poker-omaha-series.resolver';
import { PokerPDollarsResolver } from './poker-p-dollars.resolver';
import { PokerPartypokerChampionshipResolver } from './poker-partypoker-championship.resolver';
import { PokerPartyPokerMillionResolver } from './poker-partypokermillion.resolver';
import { PokerPowerFestResolver } from './poker-powerfest.resolver';
import { PokerPowerSeriesResolver } from './poker-powerseries.resolver';
import { PokerPromotionsResourceService } from './poker-promotions-resource.service';
import { PokerPromotionsResolver } from './poker-promotions.resolver';
import { PokerReferAFriendResolver } from './poker-refer-a-friend.resolver';
import { PokerRunItTwiceResolver } from './poker-run-it-twice.resolver';
import { PokerScheduledTournamentsResolver } from './poker-scheduledtournaments.resolver';
import { PokerShortDeckSynopsisResolver } from './poker-short-deck-synopsis.resolver';
import { PokerShortDeckResolver } from './poker-short-deck.resolver';
import { PokerSitAndGoJackpotsResolver } from './poker-sitandgo-jackpots.resolver';
import { PokerSitAndGoResolver } from './poker-sitandgo.resolver';
import { PokerSpinsResolver } from './poker-spins.resolver';
import { PokerTheBigGameSynopsisResolver } from './poker-the-big-game-synopsis.resolver';
import { PokerTheBigGameResolver } from './poker-the-big-game.resolver';
import { PokerTheDeckResolver } from './poker-the-deck.resolver';
import { PokerTorneiProgressiveKnockoutResolver } from './poker-tornei-progressive-knockout.resolver';
import { PokerTournamentDollarsSynopsisResolver } from './poker-tournament-dollars-synopsis.resolver';
import { PokerTournamentDollarsResolver } from './poker-tournament-dollars.resolver';
import { PokerTournamentsResolver } from './poker-tournaments.resolver';
import { PokerTrickettsRoomSynopsisResolver } from './poker-tricketts-room-synopsis.resolver';
import { PokerTrickettsRoomResolver } from './poker-tricketts-room.resolver';
import { PokerWorldCupOfCardsResolver } from './poker-world-cup-of-cards.resolver';
import { PokerWptDeepstacksResolver } from './poker-wpt-deepstacks.resolver';
import { PokerWptOnlineSeriesResolver } from './poker-wpt-online-series.resolver';
import { PokerWptWorldOnlineChampionshipResolver } from './poker-wpt-world-online-championship.resolver';
import { TournamentSynopsisResolver } from './tournaments-synopsis.resolver';
import { TournamentsResolver } from './tournaments.resolver';

//

@Component({
    selector: 'pk-poker-promotions',
    templateUrl: 'poker-promotions.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        RouterModule,
        TrustAsHtmlPipe,
        NavigationBarComponent,
        PageNotFoundComponent,
        NavigationBarPokerComponent,
        ScrollToTopComponent,
        PageMatrixComponent,
        DynamicHtmlDirective,
        PlainLinkComponent,
    ],
    providers: [
        PokerPromotionsResolver,
        PokerPromotionsResourceService,
        PokerTournamentsResolver,
        PokerPowerSeriesResolver,
        PokerMillionsOnlineResolver,
        PokerCashGamesResolver,
        PokerFastForwardResolver,
        PokerPowerFestResolver,
        PokerScheduledTournamentsResolver,
        PokerSpinsResolver,
        PokerSitAndGoResolver,
        PokerSitAndGoJackpotsResolver,
        PokerLoyaltyResolver,
        PokerKoSeriesResolver,
        PokerMonsterSeriesResolver,
        TournamentSynopsisResolver,
        TournamentsResolver,
        PokerTheBigGameResolver,
        PokerTheBigGameSynopsisResolver,
        PokerMyGameResolver,
        PokerMyGameSynopsisResolver,
        PokerShortDeckResolver,
        PokerShortDeckSynopsisResolver,
        PokerOmahaSeriesSynopsisResolver,
        PokerOmahaSeriesResolver,
        PokerTournamentDollarsResolver,
        PokerTournamentDollarsSynopsisResolver,
        PokerTrickettsRoomResolver,
        PokerTrickettsRoomSynopsisResolver,
        PokerPartyPokerMillionResolver,
        PokerHomeGamesResolver,
        PokerWptOnlineSeriesResolver,
        PokerTheDeckResolver,
        PokerClubGamesResolver,
        PokerWptWorldOnlineChampionshipResolver,
        PokerRunItTwiceResolver,
        PokerMicroSeriesResolver,
        PokerReferAFriendResolver,
        PokerDailyLegendsResolver,
        PokerTorneiProgressiveKnockoutResolver,
        PokerHotTablesResolver,
        PokerFastPokerResolver,
        PokerOmahaDailyLegendsResolver,
        PokerFootballPartyResolver,
        PokerWorldCupOfCardsResolver,
        PokerWPTDeepStacksOnlineResolver,
        PokerGrandPrixKoResolver,
        PokerWptDeepstacksResolver,
        PokerIrishPokerMastersKoOnlineResolver,
        PokerPartypokerChampionshipResolver,
        PokerKoWeekResolver,
        PokerLaNotteDegliAssiResolver,
        PokerMillionsOnlineKnockoutEditionResolver,
        PokerGrandPrixUKResolver,
        PokerPDollarsResolver,
    ],
})
export class PokerPromotionsComponent implements OnInit, AfterViewInit {
    @Input() promoInitData: any;
    pokerPromotionsInitData: any;
    promoItem: string;
    menuItems: any;
    menuItem: any;
    relatedPromos: any;
    lang: string;
    promoListEx: any;
    currentRelativeUrl: string;
    showConfigureNavigation: boolean = false;
    disableHtmlHeadTag: boolean = false;
    parentElement: any;
    pageMatrixServiceSub: any;
    isPagematrixReady: boolean = false;
    element: HTMLElement;
    promoTabIndex: any = 0;

    constructor(
        private routeData: RouteDataService,
        private api: PokerApiService,
        private navigation: NavigationService,
        private page: Page,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private router: Router,
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit(): void {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;

        this.lang = this.page.lang;
        const el = document.getElementById('main-content') as HTMLElement;
        el.className = 'fixed-width';
        this.promoInitData = this.routeData.getInitData();
        if (!this.promoInitData.notFound && this.promoInitData.promoModel.pokerPromotionsSitecoreInfo) {
            this.pokerPromotionsInitData = this.promoInitData.promoModel.pokerPromotionsSitecoreInfo;
            this.promoItem = this.promoInitData.promoItem;
            this.menuItems = this.promoInitData.menuContent;
            if (this.promoInitData.menuContent && this.promoInitData.menuContent.length > 0) {
                this.menuItem = this.promoInitData.menuContent[0];
                this.menuItem.title = this.menuItem.text;
                if (this.promoInitData.menuContent.length > 1) {
                    const menuItems: any[] = [];
                    for (let i = 1; i < this.promoInitData.menuContent.length; i++) {
                        menuItems.push(this.promoInitData.menuContent[i]);
                    }
                    this.menuItem.menu = {
                        children: menuItems,
                    };

                    this.menuItem.parameters = {
                        template: 'pc menu',
                    };
                }
            }
            this.relatedPromos = this.promoInitData.promoModel.relatedPromos;
            this.promoListEx = this.pokerPromotionsInitData.promoListEx;
            this.route.data.subscribe((routeinitdata: any) => {
                this.promoInitData = routeinitdata.initData;
                // if (!this.promoInitData.promoModel.pokerPromotionsSitecoreInfo || (this.promoInitData.promoModel.pokerPromotionsSitecoreInfo && !this.promoInitData.promoModel.pokerPromotionsSitecoreInfo.heroImage)) {
                //     this.navigation.goTo('/promotions');
                // }
                this.pokerPromotionsInitData = this.promoInitData.promoModel.pokerPromotionsSitecoreInfo;
                this.promoItem = this.promoInitData.promoItem;
                this.menuItems = this.promoInitData.menuContent;

                this.pokerPromotionsInitData = this.promoInitData.promoModel.pokerPromotionsSitecoreInfo;
                this.promoItem = this.promoInitData.promoItem;
                this.menuItems = this.promoInitData.menuContent;
                if (this.promoInitData.menuContent && this.promoInitData.menuContent.length > 0) {
                    this.menuItem = this.promoInitData.menuContent[0];
                    this.menuItem.title = this.menuItem.text;
                    if (this.promoInitData.menuContent.length > 1) {
                        const menuItems: any[] = [];
                        for (let i = 1; i < this.promoInitData.menuContent.length; i++) {
                            menuItems.push(this.promoInitData.menuContent[i]);
                        }
                        this.menuItem.menu = {
                            children: menuItems,
                        };

                        this.menuItem.parameters = {
                            template: 'pc menu',
                        };
                    }
                }

                //this.relatedPromos = this.pokerPromotionsInitData.relatedPromos;
                this.promoListEx = this.pokerPromotionsInitData.promoListEx;
                this.currentRelativeUrl = this.navigation.location.absUrl();
                this.titleService.setTitle(this.pokerPromotionsInitData.title);
                this.metaService.updateTag({ name: 'description', content: this.pokerPromotionsInitData.description });
                this.switchTabHash();
            });
        }
        // else {
        //     this.navigation.goTo('/promotions');
        // }
    }

    menuItemClicked(itemUrl: string) {
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf('/') + 1);
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf(windowObj.clientConfig.lhProductHomepagesConfig.poker)+ 1);
        const windowObj: any = window;
        let itemId: string;
        const siteUrl: string = windowObj.clientConfig.vnProductHomepages.poker;
        if (itemUrl.indexOf(siteUrl) >= 0) {
            itemId = itemUrl.substr(siteUrl.length + 1);
        } else {
            itemId = itemUrl;
        }

        this.api.get('PokerPromotion/GetPromoItem', { folderId: itemId }, { showSpinner: true }).subscribe(
            (response: any) => {
                this.pokerPromotionsInitData = response.promoModel.pokerPromotionsSitecoreInfo;
                this.promoItem = response.promoItem;
                this.relatedPromos = response.promoModel.pokerPromotionsSitecoreInfo?.relatedPromos;
                this.promoListEx = response.promoModel.pokerPromotionsSitecoreInfo?.promoListEx;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (error: any) => {},
        );
    }

    relatedPromoClick(relatedPromoItem: string) {
        this.navigation.goTo('/mobilepoker/pokerpromotions/' + relatedPromoItem);
    }

    ngAfterViewInit() {
        //var accItem = document.getElementsByClassName('accordionItem');
        const accHD = document.getElementsByClassName('accordionItemHeading');
        if (accHD) {
            for (let i = 0; i < accHD.length; i++) {
                this.parentElement = accHD[i].parentElement?.className;
                accHD[i].addEventListener('click', this.toggleItem, false);
            }
        }
    }

    toggleItem() {
        this.parentElement.className == 'accordionItem close'
            ? (this.parentElement.className = 'accordionItem open')
            : (this.parentElement.className = 'accordionItem close');
    }

    // New Promotion Tab
    private switchTabHash() {
        const targetUrl = this.navigation.location.absUrl();
        if (targetUrl.indexOf('#') > 0) {
            const splitValue = targetUrl.split('#');
            const targetID = splitValue[1];
            if (targetID) {
                const targetTabDecode: any = decodeURIComponent(decodeURIComponent(targetID));
                const targetTabName: any = decodeURIComponent(decodeURIComponent(targetTabDecode));
                const targetTab = targetTabName.toLowerCase();

                switch (targetTab) {
                    case this.pokerPromotionsInitData?.mainTitle1?.toLowerCase() == targetTab:
                        this.promoTabIndex = 0;
                        break;
                    case this.pokerPromotionsInitData?.mainTitle2?.toLowerCase() == targetTab:
                        this.promoTabIndex = 1;
                        break;
                    case this.pokerPromotionsInitData?.mainTitle3?.toLowerCase() == targetTab:
                        this.promoTabIndex = 2;
                        break;
                    case this.pokerPromotionsInitData?.mainTitle4?.toLowerCase() == targetTab:
                        this.promoTabIndex = 3;
                        break;
                    default:
                        this.promoTabIndex = 0;
                }
            }
        } else if (this.pokerPromotionsInitData.mainTitle1) {
            this.promoTabIndex = 0;
        }
    }
}
