import { Message } from '../../message';

export class UnjoinAllWaitlist extends Message {
    private MESSAGE_NAME: string = 'UnjoinAllWaitlist';
    private tableids: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableids: any[] | null = null) {
        super(reqServerPeerId);
        this.tableids = tableids;
    }

    getTableids(): any[] | null {
        return this.tableids;
    }

    setTableids(tableids: any[] | null) {
        this.tableids = tableids;
    }
}
