import { Message } from '../../message';

export class ResponseTourenyLobbyPlayersGrid extends Message {
    private MESSAGE_NAME: string = 'ResponseTourenyLobbyPlayersGrid';
    private mtctId: number = 0;
    private pageNo: number = 0;
    private totalPages: number = 0;
    private page: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, pageNo: number = 0, totalPages: number = 0, page: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.pageNo = pageNo;
        this.totalPages = totalPages;
        this.page = page;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getPageNo(): number {
        return this.pageNo;
    }

    setPageNo(pageNo: number) {
        this.pageNo = pageNo;
    }
    getTotalPages(): number {
        return this.totalPages;
    }

    setTotalPages(totalPages: number) {
        this.totalPages = totalPages;
    }
    getPage(): any[] | null {
        return this.page;
    }

    setPage(page: any[] | null) {
        this.page = page;
    }
}
