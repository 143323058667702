import { Message } from '../../message';

export class PlayerGameSessionRemainingLimitsStatusResponse extends Message {
    private MESSAGE_NAME: string = 'PlayerGameSessionRemainingLimitsStatusResponse ';
    private limitBalance: number = 0;
    private sessionBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, limitBalance: number = 0, sessionBalance: number = 0) {
        super(reqServerPeerId);
        this.limitBalance = limitBalance;
        this.sessionBalance = sessionBalance;
    }

    getLimitBalance(): number {
        return this.limitBalance;
    }

    setLimitBalance(limitBalance: number) {
        this.limitBalance = limitBalance;
    }
    getSessionBalance(): number {
        return this.sessionBalance;
    }

    setSessionBalance(sessionBalance: number) {
        this.sessionBalance = sessionBalance;
    }
}
