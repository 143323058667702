import { Message } from '../../message';

export class ClientSwitchMessage extends Message {
    private MESSAGE_NAME: string = 'ClientSwitchMessage';
    private navigationUrl: string | null = null;
    private clientUrl: string | null = null;
    private silent: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, navigationUrl: string | null = null, clientUrl: string | null = null, silent: boolean | null = false) {
        super(reqServerPeerId);
        this.navigationUrl = navigationUrl;
        this.clientUrl = clientUrl;
        this.silent = silent;
    }

    getNavigationUrl(): string | null {
        return this.navigationUrl;
    }

    setNavigationUrl(navigationUrl: string | null) {
        this.navigationUrl = navigationUrl;
    }
    getClientUrl(): string | null {
        return this.clientUrl;
    }

    setClientUrl(clientUrl: string | null) {
        this.clientUrl = clientUrl;
    }
    getSilent(): boolean | null {
        return this.silent;
    }

    setSilent(silent: boolean | null) {
        this.silent = silent;
    }
}
