import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Autoplay, EffectCoverflow, Navigation, Pagination, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { TouchSlideItem } from './touch-swiper.models';

@Component({
    selector: 'pt-touch-swiper',
    templateUrl: 'touch-swiper.component.html',
    styleUrls: ['./swiper.min.css'],
    host: {
        '(window:resize)': 'onResize($event)',
    },
    standalone: true,
    imports: [CommonModule, SwiperComponent],
})
export class TouchSwiperComponent implements OnInit, AfterViewInit {
    @Input() isStatic: boolean = false;
    @Input() swiperData: Array<TouchSlideItem>;

    public currentSlide: TouchSlideItem;
    public staticSlide: TouchSlideItem | null | undefined;

    public dataLength: number;

    public config: SwiperOptions = {
        modules: [Navigation, Pagination, EffectCoverflow, Autoplay, Virtual],
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        keyboard: true,
        slidesPerView: 2,
        loop: true,
        observer: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };
    @ViewChild('SwiperComponent') componentRef?: SwiperComponent;

    constructor(private tracking: TrackingService) {}

    ngOnInit() {
        this.staticSlide = this.swiperData && this.swiperData.length > 0 ? this.swiperData.shift() : null;
        if (window.innerWidth < 601) {
            this.config = Object.assign({}, this.config, { direction: 'horizontal', slidesPerView: 1 });
        } else if (window.innerWidth < 769) {
            this.config = Object.assign({}, this.config, { direction: 'horizontal' });
        } else {
            this.config = Object.assign({}, this.config, { direction: 'vertical' });
        }

        if (this.swiperData && this.swiperData.length > 2) {
            this.config = Object.assign({}, this.config, {
                loop: true,
            });
        }
    }

    ngAfterViewInit() {
        const navContainer = document.querySelector('#navigation-container');
        [].slice.call(document.querySelectorAll('.swiper-button-next, .swiper-button-prev')).forEach((element: any) => {
            element.classList.add('swiper-button-white');

            if ([].slice.call(element.classList).indexOf('swiper-button-next') !== -1) {
                if (element.getAttribute('hidden') != null) {
                    element.removeAttribute('hidden');
                }
                element.addEventListener('click', this.nextSlide.bind(this));
            } else {
                if (element.getAttribute('hidden') != null) {
                    element.removeAttribute('hidden');
                }
                element.addEventListener('click', this.prevSlide.bind(this));
            }

            navContainer?.appendChild(element);
        });
    }

    onResize(event: any) {
        if (event.target.innerWidth < 601) {
            this.config = Object.assign({}, this.config, { direction: 'horizontal', slidesPerView: 1 });
        } else if (event.target.innerWidth < 769) {
            this.config = Object.assign({}, this.config, { direction: 'horizontal', slidesPerView: 2 });
        } else {
            this.config = Object.assign({}, this.config, { direction: 'vertical', slidesPerView: 2 });
        }
        this.update();
    }

    update() {
        this.componentRef?.swiper.update();
    }

    nextSlide() {
        // eslint-disable-next-line no-console
        console.log('next click', this.componentRef?.swiper.slideNext());
        this.componentRef?.swiper.slideNext();
    }

    prevSlide() {
        // eslint-disable-next-line no-console
        console.log('previous click', this.componentRef?.swiper.slidePrev());
        this.componentRef?.swiper.slidePrev();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pause(e: Event) {
        this.componentRef?.swiper.autoplay.stop();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resume(e: Event) {
        this.componentRef?.swiper.autoplay.start();
    }

    public mainBannerClick(event: any, teaser: any) {
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Banner',
            'component.LabelEvent': 'Poker Homepage' + '_' + teaser.slideName,
            'component.ActionEvent': 'Banner - Click',
            'component.PositionEvent': 'Teaser_1',
            'component.LocationEvent': 'Main Banner',
            'component.EventDetails': 'CTA Name|' + teaser.title,
            'component.URLClicked': teaser.secondCta.link,
        });
    }

    public sideBannerClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Banner',
            'component.LabelEvent': 'Poker Homepage' + '_' + teaser.slideName,
            'component.ActionEvent': 'Banner - Click',
            'component.LocationEvent': 'Side Banner',
            'component.PositionEvent': 'Teaser_' + (+bannerPos + 1), // since staticSlide is 1st slide.
            'component.EventDetails': 'CTA Name|' + teaser.title,
            'component.URLClicked': teaser.secondCta.link,
        });
    }

    public ctaClick(event: any, teaser: any, bannerPos: any, ctaType: string) {
        event.stopPropagation();
        let ctaLink = teaser.mainCta.link;
        let locationEvent = 'Main Banner';
        if (ctaType === 'secondCta') {
            ctaLink = teaser.secondCta.link;
        }
        if (bannerPos !== '1') {
            locationEvent = 'Side Banner';
        }
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Banner',
            'component.LabelEvent': 'Poker Homepage' + '_' + teaser.slideName,
            'component.ActionEvent': 'Banner - Click',
            'component.PositionEvent': 'Teaser_' + (+bannerPos + 1),
            'component.EventDetails': 'CTA Name|' + teaser.title,
            'component.URLClicked': ctaLink,
            'component.LocationEvent': locationEvent,
        });
        window.open(
            ctaLink,
            '_self', //teaser.secondCta.target
        );
    }
}
