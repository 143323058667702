import { Message } from '../../message';

export class PopUpInfo extends Message {
    private MESSAGE_NAME: string = 'PopUpInfo';
    private infoType: number = 0;
    private popUpMsg: any | null = null;
    private interval: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, infoType: number = 0, popUpMsg: any | null = null, interval: number = 0) {
        super(reqServerPeerId);
        this.infoType = infoType;
        this.popUpMsg = popUpMsg;
        this.interval = interval;
    }

    getInfoType(): number {
        return this.infoType;
    }

    setInfoType(infoType: number) {
        this.infoType = infoType;
    }
    getPopUpMsg(): any | null {
        return this.popUpMsg;
    }

    setPopUpMsg(popUpMsg: any | null) {
        this.popUpMsg = popUpMsg;
    }
    getInterval(): number {
        return this.interval;
    }

    setInterval(interval: number) {
        this.interval = interval;
    }
}
