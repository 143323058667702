import { map } from 'lodash-es';

import { FxRateHelperClassService } from './Fxratemanager';

interface CurrencySettings {
    symbol: string;
    format: string;
    decimal: string;
    thousand: string;
    precision: number;
    grouping: number;
}

interface NumberSettings {
    precision: number;
    grouping: number;
    thousand: string;
    decimal: string;
}

interface Settings {
    currency: CurrencySettings;
    number: NumberSettings;
}

interface Library {
    version: string;
    settings: Settings;
    formatMoney: (
        number: number, //| number[],
        symbol: string,
        precision: number,
        thousand: string,
        decimal: string,
        format: string,
    ) => string | string[];
    formatNumber: (number: number, precision: number, thousand: string, decimal: string) => any;
    unformat: (value: any, decimal: string) => any;
    checkForArray: (value: any) => any;
    toFixed: (value: any, precision: number) => string;
    defaults: (object: any, defs: any) => any;
    isObject: (obj: any) => any;
    checkPrecision: (val: any, base: any) => any;
    checkCurrencyFormat: (format: any) => any;
    isString: (obj: any) => any;
}

export class MathUtils {
    FxRateManager = FxRateHelperClassService.getInstance();
    public currencyDetails = {};
    public nativeMap: (callbackfn: (value: any, index: number, array: any[]) => any, thisArg?: any) => any[] = Array.prototype.map;
    public nativeIsArray: (arg: any) => arg is any[] = Array.isArray;
    public toString: () => string = Object.prototype.toString;
    public lib: Library = {
        version: '0.3.2',
        settings: {
            currency: {
                symbol: '$',
                format: '%S%A',
                decimal: '.',
                thousand: ',',
                precision: 2,
                grouping: 3,
            },
            number: {
                precision: 0,
                grouping: 3,
                thousand: ',',
                decimal: '.',
            },
        },
        defaults(object, defs) {
            let key;
            object = object || {};
            defs = defs || {};
            // Iterate over object non-prototype properties:
            for (key in defs) {
                if (defs.hasOwnProperty(key)) {
                    // Replace values with defaults only if undefined (allow empty/zero values):
                    if (object[key] == null) object[key] = defs[key];
                }
            }
            return object;
        },
        isObject(obj) {
            return obj && toString.call(obj) === '[object Object]';
        },
        checkCurrencyFormat(format) {
            const defaults = this.settings.currency.format;

            // Allow function as format parameter (should return string or object):
            if (typeof format === 'function') format = format();

            // Format can be a string, in which case `value` ("%A") must be present:
            if (this.isString(format) && format.match('%A')) {
                // Create and return positive, negative and zero formats:
                return {
                    pos: format,
                    neg: format.replace('-', '').replace('%A', '-%A'),
                    zero: format,
                };

                // If no format, or object is missing valid positive value, use defaults:
            } else if (!format || !format.pos || !format.pos.match('%A')) {
                // If defaults is a string, casts it to an object for faster checking next time:
                return !this.isString(defaults)
                    ? defaults
                    : (this.settings.currency.format = {
                          pos: defaults,
                          neg: defaults.replace('%A', '-%A'),
                          zero: defaults,
                      });
            }
            // Otherwise, assume format was fine:
            return format;
        },
        isString(obj) {
            return !!(obj === '' || (obj && obj.charCodeAt && obj.substr));
        },
        formatMoney(number, symbol, precision, thousand, decimal, format) {
            if (Array.isArray(number)) {
                return map(number, function (val) {
                    return this.formatMoney(val, symbol, precision, thousand, decimal, format);
                });
            }
            // Clean up number:
            number = this.unformat(number);
            const opts = this.defaults(
                    this.isObject(symbol)
                        ? symbol
                        : {
                              symbol: symbol,
                              precision: precision,
                              thousand: thousand,
                              decimal: decimal,
                              format: format,
                          },
                    this.settings.currency,
                ),
                // Clean up precision
                formats = this.checkCurrencyFormat(opts.format);
            const useFormat = number > 0 ? formats.pos : number < 0 ? formats.neg : formats.zero;
            return useFormat
                .replace('%S', opts.symbol)
                .replace('%A', this.formatNumber(Math.abs(number), this.checkPrecision(opts.precision), opts.thousand, opts.decimal));
        },
        formatNumber(number, precision, thousand, decimal) {
            if (Array.isArray(number)) {
                return number.map((val) => this.formatNumber(val, precision, thousand, decimal));
            }

            // Clean up number:
            number = this.unformat(number);

            // Build options object from second param (if object) or all params, extending defaults:
            const opts = this.defaults(
                    this.isObject(precision)
                        ? precision
                        : {
                              precision: precision,
                              thousand: thousand,
                              decimal: decimal,
                          },
                    this.settings.number,
                ),
                // Clean up precision
                usePrecision = this.checkPrecision(opts.precision, undefined),
                // Do some calc:
                negative = number < 0 ? '-' : '',
                base = parseInt(this.toFixed(Math.abs(number), usePrecision).toString(), 10) + '',
                mod = base.length > 3 ? base.length % 3 : 0;

            // Format the number:
            return (
                negative +
                (mod ? base.substr(0, mod) + opts.thousand : '') +
                base.substr(mod).replace(/(\d{3})(?=\d)/g, '$1' + opts.thousand) +
                (usePrecision ? opts.decimal + this.toFixed(Math.abs(number), usePrecision).split('.')[1] : '')
            );
        },
        checkForArray(obj) {
            return Array.isArray ? Array.isArray(obj) : toString.call(obj) === '[object Array]';
        },
        checkPrecision(val, base) {
            val = Math.round(Math.abs(val));
            return isNaN(val) ? base : val;
        },
        unformat(value, decimal) {
            if (this.checkForArray(value)) {
                return map(value, function (val) {
                    return this.unformat(val, decimal);
                });
            }
            // Fails silently (need decent errors):
            value = value || 0;

            // Return the value as-is if it's already a number:
            if (typeof value === 'number') return value;

            // Default decimal point comes from settings, but could be set to eg. "," in opts:
            decimal = decimal || this.settings.number.decimal;

            // Build regex to strip out everything except digits, decimal point and minus sign:
            const regex: RegExp = new RegExp(`[^0-9-${decimal}]`, 'g'),
                unformatted = parseFloat(
                    ('' + value)
                        .replace(/\((.*)\)/, '-$1') // replace bracketed values with negatives
                        .replace(regex, '') // strip out any cruft
                        .replace(decimal, '.'), // make sure decimal point is standard
                );

            // This will fail silently which may cause trouble, let's wait and see:
            return !isNaN(unformatted) ? unformatted : 0;
        },
        toFixed(value: any, precision: number) {
            precision = this.checkPrecision(precision, this.settings.number.precision);
            const power = Math.pow(10, precision);

            // Multiply up by precision, round accurately, then divide and use native toFixed():
            return (Math.round(this.unformat(value) * power) / power).toFixed(precision);
        },
    };
    defaults(object, defs) {
        let key;
        object = object || {};
        defs = defs || {};
        // Iterate over object non-prototype properties:
        for (key in defs) {
            if (defs.hasOwnProperty(key)) {
                // Replace values with defaults only if undefined (allow empty/zero values):
                if (object[key] == null) object[key] = defs[key];
            }
        }
        return object;
    }
    checkPrecision(val, base) {
        val = Math.round(Math.abs(val));
        return isNaN(val) ? base : val;
    }
    isObject(obj) {
        return obj && toString.call(obj) === '[object Object]';
    }

    getFormattedAmountValue(amount, currencyAbbr, noConvertion) {
        let formattedValue = this.formatCurrencyFromObj(amount, currencyAbbr, noConvertion);
        // : "$" + amount / 100;
        if (formattedValue.slice(-3) === '.00') {
            formattedValue = formattedValue.slice(0, -3);
        }
        return formattedValue;
    }
    formatCurrencyFromObj(amount, abbr, noConvertion) {
        /**as convertion is not required for userBalance */
        let convertedAmount;
        if (noConvertion == false) {
            convertedAmount = amount;
        } else {
            convertedAmount = this.getMultipliedValue(amount, abbr);
        }
        this.initializeCurrencyDetails();
        const obj = this.getCurrencyCode(abbr);
        return this.formatCurrency(convertedAmount, obj.symbol, obj.precision | 2, obj.thousandSeparator, obj.decimalSeparator, obj.format);
    }
    getMultipliedValue(amount, abbr) {
        const toCurrency = abbr;
        const fxRate = this.FxRateManager.getFxRateWithOutSnapShotId('USD', toCurrency, 'deposit');
        if (fxRate) {
            return amount * fxRate;
        } else {
            return amount;
        }
    }
    getCurrencyCode(code) {
        if (this.currencyDetails && this.currencyDetails[code]) {
            return this.currencyDetails[code];
        }
        return false;
    }
    formatCurrency(number, symbol, precision, thousand, decimal, format) {
        return this.lib.formatMoney(this.toCurrency(number, true, precision), symbol, precision, thousand, decimal, format);
    }
    put(key, value) {
        this.currencyDetails[key] = value;
    }

    initializeCurrencyDetails() {
        this.put('AUD', {
            currencyCode: 'AUD',
            symbolName: 'Australian Dollar',
            symbol: '$',
            centSymbol: '¢',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('BRL', {
            currencyCode: 'BRL',
            symbolName: 'Brazilian Real',
            symbol: 'R$',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('USD', {
            currencyCode: 'USD',
            symbolName: 'US Dollar',
            symbol: '$',
            centSymbol: '¢',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('GBP', {
            currencyCode: 'GBP',
            symbolName: 'Pound Sterling',
            symbol: '£',
            centSymbol: 'p',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('CAD', {
            currencyCode: 'CAD',
            symbolName: 'Canadian Dollar',
            symbol: '$',
            centSymbol: '¢',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('HKD', {
            currencyCode: 'HKD',
            symbolName: 'Hong Kong Dollar',
            symbol: 'HK$',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('HUF', {
            currencyCode: 'HUF',
            symbolName: 'Hungarian Forint',
            symbol: 'Ft',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('MYR', {
            currencyCode: 'MYR',
            symbolName: 'Malaysian Ringgit',
            symbol: 'RM',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('TWD', {
            currencyCode: 'TWD',
            symbolName: 'New Taiwan Dollar',
            symbol: '$',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('TRY', {
            currencyCode: 'TRY',
            symbolName: 'New Turkish Lira',
            symbol: 'YTL',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('NZD', {
            currencyCode: 'NZD',
            symbolName: 'New Zealand Dollar',
            symbol: '$',
            centSymbol: 'c',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('NOK', {
            currencyCode: 'NOK',
            symbolName: 'Norwegian Krone',
            symbol: 'kr',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('EUR', {
            currencyCode: 'EUR',
            symbolName: 'Euro',
            symbol: '€',
            centSymbol: 'c',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('JPY', {
            currencyCode: 'JPY',
            symbolName: 'Yen',
            symbol: '¥',
            centSymbol: 'c',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('RUB', {
            currencyCode: 'RUB',
            symbolName: 'Russian ruble',
            symbol: '₽',
            centSymbol: '',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
        this.put('***', {
            currencyCode: '***',
            symbolName: '',
            symbol: '',
            centSymbol: 'c',
            thousandSeparator: ',',
            decimalSeparator: '.',
        });
    }
    isArray(obj) {
        return this.nativeIsArray ? this.nativeIsArray(obj) : toString.call(obj) === '[object Array]';
    }
    toCurrency(value, accurately, precision) {
        const fraction = precision,
            pow = Math.pow(10, fraction);
        const value1 = Math.round(value);
        //skip decimal part if length more than  999,999.99 - Note 27.01.2017: according to last requirements we should show cents allways. For PFF mode too!
        return value1.toString().length > 8 && !accurately ? Math.round(value1 / pow) : Number((value1 / pow).toFixed(fraction));
    }
}
