<div id="poker-pwc-client-wrap">
    <ng-container *ngIf="handshakeSuccessful; else noConnection">
        <pwc-client-header [class.absoluteHeader]="isGameTableOpen"></pwc-client-header>
        <div class="poker-pwc-content-wraper" [class.pwc-gameTableWrapper]="isGameTableOpen">
            <router-outlet></router-outlet>
        </div>
        <pwc-client-footer *ngIf="!isGameTableOpen"></pwc-client-footer>
        <pwc-gametable [class.showGameTable]="isGameTableOpen"></pwc-gametable>
    </ng-container>
    <ng-template #noConnection>
        <div class="text-light w-100 h-100 d-flex justify-content-center align-self-center align-items-center">Connection / Handshake Failed</div>
    </ng-template>
</div>

<div class="orientation_block" [ngStyle]="{ 'background-image': 'url(' + orientationImg + ')' }">
    <p>This web app supports portrait only</p>
</div>
