import { Properties } from '../utils/properties';
import { Point } from './point';

export class Card {
    private _name: string;
    private _type: string;
    private _show: boolean;
    private _position: Point;
    private _hide: boolean = false;
    private _cardAnimation: boolean = false;
    private _communityCard = false;
    private _cardState: string;

    constructor(name: string, type: string, show: boolean) {
        this._name = name;
        this._type = type;
        this._show = show;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get show(): boolean {
        return this._show;
    }

    set show(value: boolean) {
        this._show = value;
    }

    get position(): Point {
        return this._position;
    }

    set position(value: Point) {
        this._position = value;
    }

    get cardHide(): boolean {
        return this._hide;
    }

    set cardHide(value: boolean) {
        this._hide = value;
    }

    get cardAnimation(): boolean {
        return this._cardAnimation;
    }

    set cardAnimation(value: boolean) {
        this._cardAnimation = value;
    }

    communityCardAnimation() {
        this._cardAnimation = true;
    }

    getCardSprite(): string {
        return Properties.getCardSprite(this.type, this.name, this.show);
    }

    get communityCard(): boolean {
        return this._communityCard;
    }

    set communityCard(value: boolean) {
        this._communityCard = value;
    }
    get cardState(): string {
        return this._cardState;
    }

    set cardState(value: string) {
        this._cardState = value;
    }
}
