import { Message } from '../../message';

export class InstallDynamicDLL extends Message {
    private MESSAGE_NAME: string = 'InstallDynamicDLL';
    private sourceUrl: string | null = null;
    private md5sumOfDynamicDLL: string | null = null;
    private installationMode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sourceUrl: string | null = null,
        md5sumOfDynamicDLL: string | null = null,
        installationMode: number = 0,
    ) {
        super(reqServerPeerId);
        this.sourceUrl = sourceUrl;
        this.md5sumOfDynamicDLL = md5sumOfDynamicDLL;
        this.installationMode = installationMode;
    }

    getSourceUrl(): string | null {
        return this.sourceUrl;
    }

    setSourceUrl(sourceUrl: string | null) {
        this.sourceUrl = sourceUrl;
    }
    getMd5sumOfDynamicDLL(): string | null {
        return this.md5sumOfDynamicDLL;
    }

    setMd5sumOfDynamicDLL(md5sumOfDynamicDLL: string | null) {
        this.md5sumOfDynamicDLL = md5sumOfDynamicDLL;
    }
    getInstallationMode(): number {
        return this.installationMode;
    }

    setInstallationMode(installationMode: number) {
        this.installationMode = installationMode;
    }
}
