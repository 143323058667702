import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { PublicPageWrapperComponent } from './public-page-wrapper/public-page-wrapper.component';

@Component({
    selector: 'pk-homepage-dynamic',
    templateUrl: './homepage-dynamic.component.html',
    standalone: true,
    imports: [CommonModule, PublicPageWrapperComponent],
})
export class HomepageDynamicComponent implements OnInit {
    info: any;

    ngOnInit() {
        this.info = {
            sitecorePath: 'MobilePoker-v1.0/Homepage-PublicPageSection/Main',
        };
    }
}
