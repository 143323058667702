<div class="flickrFeed social-feed-section" *ngIf="item.flickrData.items.length > 0">
    <div class="row no-gutters">
        <vn-pc-component-header-v2 class="col-auto" *ngIf="item.title" [item]="item"></vn-pc-component-header-v2>
        <h3 *ngIf="item.flickrProfileTitle" class="col-auto pl-0">
            <a [href]="item.flickrProfileLink" target="_blank" class="color-primary">{{ item.flickrProfileTitle }}</a>
        </h3>
    </div>

    <div class="flickr-feed-wrap">
        <div class="slide-preview">
            <vn-swiper [swiperOptions]="config">
                <ng-template *ngFor="let slide of flickrFeedData" swiperSlide>
                    <div class="swiper-slide">
                        <a class="flick-item" [href]="slide.post_url" target="_blank">
                            <img [src]="slide.thumbnail" alt=" Flickr Slides" />
                        </a>
                    </div>
                </ng-template>
            </vn-swiper>
        </div>
    </div>
</div>
