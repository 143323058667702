import { Message } from '../../message';

export class MuckCards extends Message {
    private MESSAGE_NAME: string = 'MuckCards';
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatNo: number = 0) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
