import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PokerPromotionsSynopsisResourceService {
    constructor(private api: PokerApiService) {}

    getInitValues(): Observable<any> {
        return this.api.get('PokerPromotion').pipe(catchError(() => of({ notFound: true })));
    }

    getInitSynopsisData(rootPath: string): Observable<any> {
        return this.api.get('PokerPromotion/GetPromotionsSynopsis', { path: rootPath }).pipe(catchError(() => of({ notFound: true })));
    }
}
