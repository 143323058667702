export abstract class Message {
    private static currentMessageNumber: number = 0;
    protected msgNumber: number;
    protected reqServerPeerId: number;
    protected debug: boolean = true;
    public classId: number;
    protected topLevelMessage: boolean = false;

    public static excludeFields: string[] = ['debug'];
    private lastMessageSendTime: any;
    private secondsToWaitBeforeResend: number = 0;
    private timesMessageSent: number = 0;

    constructor(reqServerPeerId: number) {
        if (Message.currentMessageNumber >= Number.MAX_SAFE_INTEGER) {
            Message.currentMessageNumber = 0;
        }
        Message.currentMessageNumber += 2;
        this.msgNumber = Message.currentMessageNumber;
        this.reqServerPeerId = reqServerPeerId;
    }

    public setMsgNumber(msgNumber: number): void {
        this.msgNumber = msgNumber;
    }

    public getMsgNumber(): number {
        return this.msgNumber;
    }

    public setReqServerPeerId(reqServerPeerId: number): void {
        this.reqServerPeerId = reqServerPeerId;
    }

    public getReqServerPeerId(): number {
        return this.reqServerPeerId;
    }

    public settopLevelMessage(topLevelMessage: boolean) {
        this.topLevelMessage = topLevelMessage;
    }
    public gettopLevelMessage(): boolean {
        return this.topLevelMessage;
    }
    public isPrivileged(): boolean {
        return false;
    }

    isBlocked(): boolean {
        return false;
    }

    setTimesMessageSent(timesMessageSent: number): void {
        this.timesMessageSent = timesMessageSent;
    }

    getTimesMessageSent(): number {
        return this.timesMessageSent;
    }

    setSecondsToWaitBeforeResend(secondsToWaitBeforeResend: number): void {
        this.secondsToWaitBeforeResend = secondsToWaitBeforeResend;
    }

    getSecondsToWaitBeforeResend(): number {
        return this.secondsToWaitBeforeResend;
    }

    setLastMessageSendTime(lastMessageSendTime) {
        this.lastMessageSendTime = lastMessageSendTime;
    }

    getLastMessageSendTime() {
        return this.lastMessageSendTime;
    }

    public abstract getMessageName(): string;

    public setDebug(debug: boolean): void {
        this.debug = debug;
    }

    public canDebug(): boolean {
        return this.debug;
    }

    public cloneWithModification(): Message {
        return this;
    }
}
