import { Injectable } from '@angular/core';

// import { PoolAvailabilityRequest } from "../../pgConnectors/server/common/pokerMessageFactory/messages/PoolAvailabilityRequest";
// import { PoolAvailabilityResponse } from "../../pgConnectors/server/common/pokerMessageFactory/messages/PoolAvailabilityResponse";
// import { JoinPoolRequest } from "../../pgConnectors/server/common/pokerMessageFactory/messages/JoinPoolRequest";
// import { JoinPoolResponse } from "../../pgConnectors/server/common/pokerMessageFactory/messages/JoinPoolResponse";
// import { BehaviorSubject, Observable } from "rxjs";
// import { LoginRequest } from "../../pgConnectors/server/common/sharedMessageFactory/messages/LoginRequest";
// import { UserService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
// import { CSD } from "../../pgConnectors/sal/CSD";
import * as _ from 'lodash-es';

import { BaseLobbyConnector } from '../../pgBackendConnectors/baseLobbyConnector';
import { ClassMessageListener, MessageListener } from '../../pgBackendConnectors/messageListener';
import { PokerBackendConnector } from '../../pgBackendConnectors/pokerBackendConnector';
import { ResponsePoolDetailsList } from '../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponsePoolDetailsList';
// import { HelperClassService } from "../../service/helper";
// import { RequestLobbyUpdate } from "../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestLobbyUpdate";
// import { LSTabIdLastUpdatedTime } from "../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/LSTabIdLastUpdatedTime";
import { ResponsePoolInfoDetailsList } from '../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponsePoolInfoDetailsList';
import { updateFFLobbyData } from '../../store/lobby.store';

@Injectable()
@ClassMessageListener
export class FastForwardService extends BaseLobbyConnector {
    // helperClassService = new HelperClassService();
    poolTableInfoDetailsList: any;
    poolTableDetailsList: any;
    poolFinalData = [] as any;
    private readonly logger: PokerLogger;
    // private _poolAvailabilityObj = new BehaviorSubject<PoolAvailabilityResponse>({} as PoolAvailabilityResponse);
    // private _poolAvailabilityObj$ = this._poolAvailabilityObj.asObservable();

    constructor(
        // private userService: UserService,
        loggerFactory: LoggerFactory,
        private store: Store,
    ) {
        super(PokerBackendConnector.getInstance());
        this.logger = loggerFactory.getLogger('ffLobbyserviceLoggingComponent');
    }

    @MessageListener('ResponsePoolInfoDetailsList', 86044)
    ResponsePoolInfoDetailsList(message: ResponsePoolInfoDetailsList) {
        this.logger.info('ls..PoolInfoDetailsList....!' + JSON.stringify(message));
        this.poolTableInfoDetailsList = message;
    }

    @MessageListener('ResponsePoolDetailsList', 86049)
    ResponsePoolDetailsList(message: ResponsePoolDetailsList) {
        this.logger.info('ls..PoolDetailsList....!' + JSON.stringify(message));
        this.poolTableDetailsList = message;
        this.getFinalPoolData();
    }

    getFinalPoolData() {
        let tableList = [] as any;
        Object.values(this.poolTableDetailsList['tabIdPoolDetailsMap']).forEach((value: any) => {
            value['pools'].forEach((table) => {
                const tableDetails = _.find(this.poolTableInfoDetailsList['poolInfos'], { poolId: table.poolId });
                if (tableDetails) {
                    tableDetails['playersCount'] = table.playerCount;
                    tableDetails['formattedBuyIn'] = tableDetails.maxBuyInAmount / 100;
                    tableDetails['gameTypeTxt'] = this.getGameType(tableDetails.gameType);
                    tableList.push(tableDetails);
                }
            });
        });
        tableList = _.uniqBy(tableList, 'poolId');
        this.poolFinalData = _.sortBy(tableList, [(obj: any) => obj.maxBuyInAmount]);
        this.logger.info('getFinalPoolData....!');
        // return this.poolFinalData
        this.store.dispatch(updateFFLobbyData(this.poolFinalData));
    }

    getGameType(gameType) {
        if (gameType === 0) {
            return 'Hold’em';
        } else if (gameType === 1) {
            return 'Omaha';
        } else {
            return 'Omaha HL';
        }
    }

    checkForPoolDeltaUpdates(data: any) {
        this.logger.info('checkForPoolDeltaUpdates....!' + JSON.stringify(data));
        data.deltaEvents.forEach((value) => {
            // updatePlayerCount = 86050
            if (value.classId === 86050) {
                _.find(this.poolFinalData, { poolId: value.poolId })['playersCount'] = value.playerCount;
                this.store.dispatch(updateFFLobbyData(_.sortBy(this.poolFinalData, [(obj: any) => obj.maxBuyInAmount])));
            }
        });
    }

    // getPoolAvailability():Observable<any>{
    //     return this._poolAvailabilityObj$;
    // }

    // setPoolAvailability(value:any){
    //     return this._poolAvailabilityObj.next(value);
    // }
}
