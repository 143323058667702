import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';

import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class BetView extends PIXI.Container {
    gameService;
    displayUtil: DisplayUtil = new DisplayUtil();
    eventDispatcherGlobal = new EventDispatcherGlobal();
    constructor(gameService) {
        super();
        this.gameService = gameService;
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }
    createView = function (EventDispatcher, maxNoOfSeats) {
        this.name = 'BetView';
        this.EventDispatcher = EventDispatcher;
        this.playersPosXArr = [];
        this.playersPosYArr = [];
        this.WinpotChipsArr = [];
        this.animateWinChipsTween = [];
        this.yPadding = 0;
        this.totalPotAmountVal = null;
        this.gameTypeId = this.gameService.getGameTableType();
        this.mainBetViewContainer = this.displayUtil.getContainer();
        this.addChild(this.mainBetViewContainer);
        this.betArray = [];
        this.fontProps = uiConfig.font.props;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.playerViewContainerPos = this.positionConfig.playersUIData.playerViewContainerPos;
        this.playerAbsPos = this.positionConfig.playersUIData.playerAbsPos;
        const chipsConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.chipsConfig;
        const sevenSeaterChipsConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.sevenSeaterChipsConfig;
        this.chipsTablePotPos = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.chipsTablePotPos;
        this.chipsBGPos = {
            x: [],
            y: [],
        };
        for (let i = 0; i < maxNoOfSeats; i++) {
            if (maxNoOfSeats == 7) {
                this.chipsBGPos.x[i] = this.playerViewContainerPos.x[i] + sevenSeaterChipsConfig[this.positionConfig.layOutPos[i]].x;
                this.chipsBGPos.y[i] = this.playerViewContainerPos.y[i] + sevenSeaterChipsConfig[this.positionConfig.layOutPos[i]].y;
            } else {
                this.chipsBGPos.x[i] = this.playerViewContainerPos.x[i] + chipsConfig[this.positionConfig.layOutPos[i]].x;
                this.chipsBGPos.y[i] = this.playerViewContainerPos.y[i] + chipsConfig[this.positionConfig.layOutPos[i]].y;
            }
        }
        //this is for Total Pot Amount
        this.createBetContainers(maxNoOfSeats);
        this.tablePotTextContainer = this.displayUtil.getContainer();
        this.addChild(this.tablePotTextContainer);
        this.tablePotTextContainer.name = 'tablePotTextContainer';
        ResponsiveContainer.call(this.tablePotTextContainer, {
            portX: (720 - this.tablePotTextContainer.initialWidth) / 2,
            portY: 485,
            EventDispatcher: this.EventDispatcher,
        });
        // this.tablePotTextContainer.setSize(720, 1280);
        this.potAmountBg = this.displayUtil.getBorderRoundRectangleSprite(160, 40, '0x000000', 20);
        this.potAmountBg.alpha = 0.4;
        this.tablePotTextContainer.addChild(this.potAmountBg);
        this.tablePotTextContainer.scale.set(0.8);
        this.potAmountBg.visible = false;
        this.potAmtTitleText = this.displayUtil.getTextField(this.fontProps.tablePotTitleText);
        this.potAmountText = this.displayUtil.getTextField(this.fontProps.tableTotalPotText);
        this.tablePotTextContainer.addChild(this.potAmountText);
        this.tablePotTextContainer.addChild(this.potAmtTitleText);
        //this.potAmtTitleText.anchor.set(0.5);
        // this.potAmountText.anchor.set(0.5);
        // this.checkForScale();
        // this.showDummyText();
    };
    showDummyText = function () {
        const vidPlayTxt = this.displayUtil.getTextField(this.fontProps.expoSpinBalanceAmt);
        vidPlayTxt.text = 'play Video Expo';
        this.tablePotTextContainer.addChild(vidPlayTxt);
        this.displayUtil.addInteraction(vidPlayTxt, this.playVideo.bind(this));
        this.displayUtil.setClickable(vidPlayTxt, true);
    };
    playVideo = function () {
        // console.log("Expo Play");
        // CommonGameProperties.expoAnime[0];
        // let message = {};
        // message["className"] = "playExpoSpinAnime";
        // message["expoId"] = 1;
        // if(this.getActiveIframeId(this.gameService.getPeerId()) != undefined){
        //     CommonGameProperties.expoAnime[this.getActiveIframeId(this.gameService.getPeerId())].expoSpinAnime.activeStatus=true
        //       const nativeElement = CommonGameProperties.expoAnime[this.getActiveIframeId(this.gameService.getPeerId())].expoSpinAnime.dynamicContentContainer.nativeElement as HTMLElement;
        //       CommonGameProperties.expoAnime[this.getActiveIframeId(this.gameService.getPeerId())].expoSpinAnime.dynamicContentContainer.nativeElement.contentDocument.body.style.visibility = 'visible';
        //       nativeElement.style.display = 'block';
        //     const iframeWindow = CommonGameProperties.expoAnime[this.getActiveIframeId(this.gameService.getPeerId())].expoSpinAnime.dynamicContentContainer.nativeElement.contentWindow;
        // // Access the function defined in the loaded script
        //         iframeWindow.postMessage('executeFunction', '*');
        // }
        //this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.PLAY_EXPOSPIN_VID);
        //this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_POOL_PRIZE);
    };
    getActiveIframeId = function (tableId) {
        let activeIframe;
        for (let i = 0; i < 4; i++) {
            if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == tableId) {
                activeIframe = i;
                break;
            }
        }
        return activeIframe;
    };
    createBetContainers = function (maxNoOfSeats) {
        for (let i = 0; i < maxNoOfSeats; i++) {
            this.individualBetContainer = this.displayUtil.getContainer();
            this.individualBetContainer.name = 'individualBetContainer' + i;
            // this.displayUtil.setObjectPosition(this.individualBetContainer,this.playerViewContainerPos.x[i], this.playerViewContainerPos.y[i])
            this.mainBetViewContainer.addChild(this.individualBetContainer);
            //this.sectionContainer = this.displayUtil.getContainer();
            this.betArray.push(this.individualBetContainer);
            //this.individualBetContainer.addChild(this.sectionContainer);
            // this.betArray.push(this.sectionContainer);
            const seatAlign = this.getSeatAlignmentforGap(i, maxNoOfSeats);
            if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
                ResponsiveContainer.call(this.individualBetContainer, {
                    portX: this.chipsBGPos.x[i],
                    portY: this.chipsBGPos.y[i],
                    portAlignX: 'LEFT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
                ResponsiveContainer.call(this.individualBetContainer, {
                    portX: this.chipsBGPos.x[i] - this.individualBetContainer.initialWidth,
                    portY: this.chipsBGPos.y[i],
                    portAlignX: 'RIGHT',
                    EventDispatcher: this.EventDispatcher,
                });
            } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_TOP) {
                ResponsiveContainer.call(this.individualBetContainer, {
                    portX: (720 - this.individualBetContainer.initialWidth) / 2,
                    portY: this.chipsBGPos.y[i],
                    portAlignY: 'TOP',
                    EventDispatcher: this.EventDispatcher,
                });
            } else {
                ResponsiveContainer.call(this.individualBetContainer, {
                    portX: (720 - this.individualBetContainer.initialWidth) / 2,
                    portY: this.chipsBGPos.y[i],
                    portAlignY: 'BOTTOM',
                    EventDispatcher: this.EventDispatcher,
                });
            }
            // this.individualBetContainer.setSize(139.75, 335.5);
        }
    };
    getSeatAlignmentforGap = function (clientSeatNo, maxSeats) {
        if (clientSeatNo == uiConfig.selfUserClientPosInd) return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
        const centerSeatNo = maxSeats / 2;
        if (centerSeatNo == clientSeatNo) return serverIdConstants.SEAT_ALIGNMENT_TOP;
        return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    };
    callYouWinAni = function (winnersData, isUnCalledBet) {
        const len = winnersData.length;
        if (len < 1) {
            return;
        }
        setTimeout(
            function () {
                for (let i = 0; i < len; i++) {
                    if (winnersData[i].isCashoutEqtyWinner == 1) {
                        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_BACKCARDS, {
                            seatNo: this.gameService.getClientSideSeatNo(this.gameService.getPlayerSeatNo(winnersData[i].winnerId)),
                        });
                        continue;
                    }
                    setTimeout(
                        function (i) {
                            this.animateWinChips(
                                this.gameService.getClientSideSeatNo(this.gameService.getPlayerSeatNo(winnersData[i].winnerId)),
                                winnersData[i].amount,
                                isUnCalledBet,
                            );
                        }.bind(this, i),
                        200,
                    );
                }
                this.setTotalPotAmount(null);
            }.bind(this),
            550,
        );
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVED_PLAYERS);
    };
    animateWinChips = function (seatNo, amount, isUnCalledBet) {
        const potChips = this.displayUtil.getSprite('potchips');
        this.potChipsContainer = this.displayUtil.getContainer();
        this.addChild(this.potChipsContainer);
        this.potChipsContainer.addChild(potChips);
        this.WinpotChipsArr.push(this.potChipsContainer);
        this.displayUtil.setObjectPosition(potChips, 0, 0);
        ResponsiveContainer.call(this.potChipsContainer, {
            portX: 360,
            portY: 485,
            EventDispatcher: this.EventDispatcher,
        });
        gsap.to(this.potChipsContainer, 0.3, {
            x: this.playersPosXArr[seatNo] + 53 * this.scaleValue,
            y: this.playersPosYArr[seatNo] + 71 * this.scaleValue,
            alpha: 1,
            onComplete: function () {
                this.potChipsContainer.visible = false;
                this.potChipsContainer.removeChild(potChips);
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_WINCHIPS_ANIMATION_COMPLETED, {
                    seatNo: seatNo,
                    amount: amount,
                    isUnCalledBet: isUnCalledBet,
                });
            }.bind(this),
        });
    };
    refreshUserBetsAndPots = function (userBetsMap, pots) {
        let mainPotAmount = 0;
        let potAmounts = 0;
        //"pots": [{"potNo": 0,"amount": 3000}]
        if (pots != null && pots.length > 0)
            for (let i = 0; i < pots.length; i++) {
                const pot = pots[i];
                potAmounts += pot.amount;
            }
        for (const clientSeatNo in userBetsMap) {
            const betAmt = userBetsMap[clientSeatNo];
            if (betAmt != undefined && betAmt > 0) {
                mainPotAmount += betAmt;
                this.updateUserBet(clientSeatNo, betAmt);
            }
        }
        this.setTotalPotAmount(potAmounts + mainPotAmount);
    };
    setBetsData = function (clientSeatNo, betAmt) {
        if (betAmt == null || betAmt == undefined || betAmt < 0) return;
        if (betAmt.toString().split('.').length > 1) {
            betAmt = parseInt(betAmt.toString().split('.')[0] + betAmt.toString().split('.')[1]);
        }
        this.showPotAmount();
        this.yPadding = 9;
        for (const k in this.betArray) {
            this.betArray[k].alpha = 1;
            this.betArray[k].children.forEach(function () {
                if (k == clientSeatNo) {
                    this.betArray[k]?.removeChildren();
                }
            });
        }
        const chip = this.displayUtil.getSprite('chip');
        chip.name = 'chips';
        chip.scale.set(1.1);
        const amountText = this.displayUtil.getTextField(this.fontProps.sidePotText);
        // amountText.anchor.set(0, 0.5);
        if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            betAmt = CommonGameProperties.mathUtil['transform'](betAmt, this.gameService.getGameCurrency());
        } else {
            betAmt = betAmt / 100;
        }
        amountText.text = betAmt;
        let amountBg;
        if (this.gameService.getSeatAlignment(clientSeatNo) == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            amountBg = this.displayUtil.getBorderRoundRectangleSprite(amountText.width + chip.width + 20, 28, '0x000000', 14, false);
            amountBg.alpha = 0.4;
            this.displayUtil.setObjectPosition(amountBg, 0, 0);
            this.displayUtil.setObjectPosition(chip, amountBg.width - chip.width + 20, -1);
            this.displayUtil.setObjectPosition(amountText, amountBg.width - (amountText.width + chip.width + 10) + 2, -1);
            // amountText.anchor.set(1, -0.019);
            chip.anchor.set(1, -0.15);
        } else {
            amountBg = this.displayUtil.getBorderRoundRectangleSprite(chip.width + 17 + amountText.width, 28, '0x000000', 14, false);
            amountBg.alpha = 0.4;
            this.displayUtil.setObjectPosition(chip, 5, 2);
            this.displayUtil.setObjectPosition(amountText, chip.x + chip.width + 2, -1);
            this.displayUtil.setObjectPosition(amountBg, 0, 0);
        }
        this.betArray[clientSeatNo].addChild(amountBg);
        this.betArray[clientSeatNo].addChild(amountText);
        this.betArray[clientSeatNo].addChild(chip);
        if (
            this.gameService.getSeatAlignment(clientSeatNo) == serverIdConstants.SEAT_ALIGNMENT_TOP ||
            this.gameService.getSeatAlignment(clientSeatNo) == serverIdConstants.SEAT_ALIGNMENT_BOTTOM
        ) {
            this.betArray[clientSeatNo].updateSize();
            this.betArray[clientSeatNo].setPosition({
                portX: (720 - this.betArray[clientSeatNo].initialWidth) / 2,
            });
        }
        if (this.gameService.getSeatAlignment(clientSeatNo) == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            this.betArray[clientSeatNo].updateSize();
            this.betArray[clientSeatNo].setPosition({
                portX: this.chipsBGPos.x[clientSeatNo] - this.betArray[clientSeatNo].initialWidth,
            });
        }
        if (this.gameService.getSeatAlignment(clientSeatNo) == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
            this.betArray[clientSeatNo].updateSize();
            this.betArray[clientSeatNo].setPosition({
                portX: this.chipsBGPos.x[clientSeatNo],
            });
        }
    };
    updateUserBet = function (clientSeatNo, betAmt) {
        if (betAmt) this.setBetsData(clientSeatNo, betAmt);
    };
    handleAnteData = function (anteData) {
        this.updatePotLimit(anteData.amount);
    };
    handleBlindData = function (blindData) {
        const seatNo = this.gameService.getClientSideSeatNo(blindData.seatNo);
        const blindAmount = blindData.amount;
        if (blindAmount) {
            this.setBetsData(seatNo, blindAmount);
        }
        this.updatePotLimit(blindData.amount);
    };
    checkForScale = function () {
        if (uiConfig.windowWidth >= 400) {
            this.yPadding = -15;
        }
    };
    setTotalPotAmount = function (amount) {
        this.totalPotAmountVal = amount;
        const totalPotVisibility = amount ? true : false;
        //this.potAmtTitleText.text = $.__("POT: ");
        this.potAmtTitleText.text = 'POT: ';
        if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.potAmountText.text = ' ' + CommonGameProperties.mathUtil['transform'](amount, this.gameService.getGameCurrency());
        } else {
            this.potAmountText.text = ' ' + amount / 100;
        }
        this.potAmountBg.width = this.potAmountText.width + this.potAmtTitleText.width + 10;
        this.displayUtil.setObjectPosition(this.potAmountBg, 0, 0);
        this.displayUtil.setObjectPosition(this.potAmtTitleText, 6, this.potAmountBg.y + 4);
        this.displayUtil.setObjectPosition(this.potAmountText, this.potAmtTitleText.width + 4, this.potAmountBg.y + 4);

        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_POTAMOUNT_IN_RAISEPANEL, amount);
        this.potAmountBg.visible = totalPotVisibility;
        this.potAmtTitleText.visible = totalPotVisibility;
        this.potAmountText.visible = totalPotVisibility;
        this.tablePotTextContainer.updateSize();
        this.tablePotTextContainer.setPosition({
            portX: (720 - this.tablePotTextContainer.initialWidth) / 2,
        });
    };
    updatePotLimit = function (amountData) {
        if (amountData <= 0) return;
        this.setTotalPotAmount(this.totalPotAmountVal + amountData);
    };
    onEndTheRound = function () {
        for (const k in this.betArray) {
            for (const l in this.betArray[k].children) {
                if (this.betArray[k].children[l].name == 'chips') {
                    gsap.to(this.betArray[k], 0.4, {
                        // x: this.chipsTablePotPos[this.positionConfig.layOutPos[k]].x,
                        // y: this.chipsTablePotPos[this.positionConfig.layOutPos[k]].y,
                        x: this.tablePotTextContainer.x + this.tablePotTextContainer.width / 2,
                        y: this.tablePotTextContainer.y + this.tablePotTextContainer.height / 2,
                        alpha: 1,
                        onComplete: function (k, l) {
                            if (this.betArray[k].children[l]) {
                                this.betArray[k].children[l].visible = false;
                                this.betArray[k]?.removeChildren();
                            }
                        }.bind(this, k, l),
                    });
                } else {
                    this.betArray[k].children[l].visible = false;
                }
            }
        }
        //this.setTotalPotAmount(endData.currentPotAmount);
    };
    handleSelectedOption = function (data) {
        this.yPadding = 9;
        // if (data == undefined) {
        //   data = { "amount": 0, "betVector": [], "isAllIn": false, "optionId": 1, "seatNo": 0, "msgNumber": 0, "peerId": 5185105, "className": "SelectedOption" };
        // }
        if (data.amount <= 0 && data.betVector.length == 0) {
            return;
        }
        const seatNo = this.gameService.getClientSideSeatNo(data.seatNo);
        // let seatNo=data.seatNo;
        for (const k in this.betArray) {
            this.betArray[k].alpha = 1;
            if (k == seatNo) {
                this.betArray[k].removeChildren();
            }
        }
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.Moving_Chips, false);
        const chip = this.displayUtil.getSprite('chip');
        chip.name = 'chips';
        chip.scale.set(1.1);
        const amountText = this.displayUtil.getTextField(this.fontProps.sidePotText);
        amountText.text = null;
        if (data.betVector.length > 0) {
            let chipValue = 0;
            for (const i in data.betVector) {
                chipValue += data.betVector[i];
            }
            if (this.gameService.getTableStatusData().tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
                amountText.text = CommonGameProperties.mathUtil['transform'](chipValue, this.gameService.getGameCurrency());
            } else {
                amountText.text = chipValue / 100;
            }
        } else {
            amountText.text = CommonGameProperties.mathUtil['transform'](data.amount, this.gameService.getGameCurrency());
        }
        let amountBg;
        if (this.gameService.getSeatAlignment(seatNo) == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            amountBg = this.displayUtil.getBorderRoundRectangleSprite(amountText.width + chip.width + 20, 28, '0x000000', 14);
            amountBg.alpha = 0.4;
            this.displayUtil.setObjectPosition(amountBg, 0, 0);
            this.displayUtil.setObjectPosition(chip, amountBg.width - chip.width + 20, -1);
            this.displayUtil.setObjectPosition(amountText, amountBg.width - (amountText.width + chip.width + 10) + 2, -1);
            // amountText.anchor.set(1, -0.019);
            chip.anchor.set(1, -0.15);
        } else {
            amountBg = this.displayUtil.getBorderRoundRectangleSprite(chip.width + 17 + amountText.width, 28, '0x000000', 14);
            amountBg.alpha = 0.4;
            this.displayUtil.setObjectPosition(chip, 5, 2);
            this.displayUtil.setObjectPosition(amountText, chip.x + chip.width + 2, -1);
            this.displayUtil.setObjectPosition(amountBg, 0, 0);
        }
        this.betArray[seatNo].addChild(amountBg);
        this.betArray[seatNo].addChild(amountText);
        this.betArray[seatNo].addChild(chip);
        if (
            this.gameService.getSeatAlignment(seatNo) == serverIdConstants.SEAT_ALIGNMENT_TOP ||
            this.gameService.getSeatAlignment(seatNo) == serverIdConstants.SEAT_ALIGNMENT_BOTTOM
        ) {
            this.betArray[seatNo].updateSize();
            this.betArray[seatNo].setPosition({
                portX: (720 - this.betArray[seatNo].initialWidth) / 2,
            });
        }
        if (this.gameService.getSeatAlignment(seatNo) == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            this.betArray[seatNo].updateSize();
            this.betArray[seatNo].setPosition({
                portX: this.chipsBGPos.x[seatNo] - this.betArray[seatNo].initialWidth,
            });
        }
        if (this.gameService.getSeatAlignment(seatNo) == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
            this.betArray[seatNo].updateSize();
            this.betArray[seatNo].setPosition({
                portX: this.chipsBGPos.x[seatNo],
            });
        }
        this.updatePotLimit(data.amount);
    };
    handleFullGameClose = function () {
        this.resetGame();
    };
    resetGame = function () {
        if (this.WinpotChipsArr.length > 0) {
            for (const k in this.betArray) {
                for (let t = 0; t < this.WinpotChipsArr.length; t++) {
                    this.betArray[k].removeChildren();
                }
            }
            for (let m = 0; m < this.WinpotChipsArr.length; m++) {
                this.removeChild(this.WinpotChipsArr[m]);
            }
            this.WinpotChipsArr = [];
        } else {
            for (const k in this.betArray) {
                this.betArray[k].removeChildren();
            }
        }
    };
    currentTablePeerId = function (peerId) {
        this.currTablePeerId = 'table' + peerId;
    };
    hidePotAmount = function () {
        this.tablePotTextContainer.visible = false;
    };
    showPotAmount = function () {
        this.tablePotTextContainer.visible = true;
    };
    checkPlayerPos = function (data) {
        this.scaleValue = data.detail.scale;
        this.playersPosXArr.push(data.detail.PosX);
        this.playersPosYArr.push(data.detail.PosY);
    };
}
