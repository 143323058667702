import { Message } from '../../message';

export class RequestLeaveTable extends Message {
    private MESSAGE_NAME: string = 'RequestLeaveTable';
    private conversationId: number = 0;
    private tableCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, conversationId: number = 0, tableCategory: number = 0) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.tableCategory = tableCategory;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
}
