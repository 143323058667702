import { Message } from '../../message';

export class HighlightWinCards extends Message {
    private MESSAGE_NAME: string = 'HighlightWinCards';
    private winnersCards: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, winnersCards: any[] | null = null) {
        super(reqServerPeerId);
        this.winnersCards = winnersCards;
    }

    getWinnersCards(): any[] | null {
        return this.winnersCards;
    }

    setWinnersCards(winnersCards: any[] | null) {
        this.winnersCards = winnersCards;
    }
}
