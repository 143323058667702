import { Message } from '../../message';

export class DealConfirmedPlayerCount extends Message {
    private MESSAGE_NAME: string = 'DealConfirmedPlayerCount';
    private noOfConfirmedPlayers: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, noOfConfirmedPlayers: number = 0) {
        super(reqServerPeerId);
        this.noOfConfirmedPlayers = noOfConfirmedPlayers;
    }

    getNoOfConfirmedPlayers(): number {
        return this.noOfConfirmedPlayers;
    }

    setNoOfConfirmedPlayers(noOfConfirmedPlayers: number) {
        this.noOfConfirmedPlayers = noOfConfirmedPlayers;
    }
}
