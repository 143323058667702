import { Message } from '../../message';

export class PLSAuthenticationRequest extends Message {
    private MESSAGE_NAME: string = 'PLSAuthenticationRequest';
    private gtid: string | null = null;
    private loginId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, gtid: string | null = null, loginId: string | null = null) {
        super(reqServerPeerId);
        this.gtid = gtid;
        this.loginId = loginId;
    }

    getGtid(): string | null {
        return this.gtid;
    }

    setGtid(gtid: string | null) {
        this.gtid = gtid;
    }
    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
}
