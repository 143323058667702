import { Routes } from '@angular/router';

import { PokerSpinsSynopsisResolver } from './poker-spins-synopsis.resolver';
import { PokerSpinsResolver } from './poker-spins.resolver';

export const pokerSpinsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerSpinsResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerSpinsSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerSpinsResolver,
        },
    },
];
