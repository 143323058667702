{
    "R_JOINTABLE_SUCCESS": 108,
    "R_JOINTABLE_FAIL": 109,
    "R_JOINTABLE_INVALID_TABLEID": 132,
    "R_JOINTABLE_WAIT_HAND_END": 177,
    "R_JOINTABLE_INVALID_TABLE_PASSWORD": 189,
    "R_JOINTABLE_REGAIN_SUCCESS": 202,
    "R_JOINTABLE_SHUTTING_DOWN_TABLE": 213,
    "R_JOINTABLE_CLOSED": 214,
    "R_JOINTABLE_TABLE_ROTATED": 240,
    "R_JOINTABLE_SUCCESS_STT_REPLAY": 1510,
    "R_SERVER_SHUTTING_DOWN": 180,
    "BETTING_ROUND_PREFLOP": 2,
    "BETTING_ROUND_POSTFLOP": 3,
    "BETTING_ROUND_TURN": 4,
    "BETTING_ROUND_RIVER": 5,
    "BLOCKSEAT_BLOCKED": 1,
    "BLOCKSEAT_NOT_AVAILABLE": 2,
    "BLOCKSEAT_REAL_TABLE": 3,
    "BLOCKSEAT_NOT_LOGGED_IN": 4,
    "BLOCKSEAT_FAILED": 5,
    "BLOCKSEAT_NOT_IN_PLAYERS_CLUB": 6,
    "BLOCKSEAT_INSUFFICIENT_POINTS": 7,
    "BLOCKSEAT_TIMER_4_QS_EXPIRED": 8,
    "BLOCKSEAT_HOGGING_PLAYER_FAILED": 9,
    "BLOCKSEAT_RECOMMENDED_BLOCKED": 10,
    "PLAYERSTATE_UNDEFINED": -1,
    "PLAYERSTATE_SIT_OUT": 1,
    "PLAYERSTATE_SIT_IN": 2,
    "PLAYERSTATE_ALL_IN": 3,
    "PLAYERSTATE_FOLDED": 4,
    "PLAYERSTATE_AUTOPOST_BLIND_AND_FOLD": 5,
    "PLAYERSTATE_ALL_IN_NOTRESPONDING": 7,
    "PLAYERSTATE_NOT_IN_GAME": 9,
    "OPTION_FOLD": 1,
    "OPTION_CALL": 2,
    "OPTION_CHECK": 3,
    "OPTION_BET": 4,
    "OPTION_RAISE": 5,
    "OPTION_BRING_IN": 6,
    "OPTION_RAISE_ON_PAIR": 7,
    "OPTION_COMPLETE_TO": 8,
    "OPTION_BET_ON_PAIR": 9,
    "OPTION_RAISE_TO": 10,
    "OPTION_BET_TO": 11,
    "OPTION_NO_ACTION": 12,
    "OPTION_STAYIN": 15,
    "OPTION_UNDEFINED": 666,
    "AUTO_OPTION_FOLD": 1,
    "AUTO_OPTION_FOLD_CHECK": 2,
    "AUTO_OPTION_CHECK": 3,
    "AUTO_OPTION_CHECK_CALL": 4,
    "AUTO_OPTION_CALL": 5,
    "AUTO_OPTION_CALL_ANY": 6,
    "AUTO_OPTION_BET": 7,
    "AUTO_OPTION_BET_RAISE": 8,
    "AUTO_OPTION_RAISE": 9,
    "AUTO_OPTION_RAISE_ANY": 10,
    "AUTO_OPTION_NOT_SELECTED": 11,
    "AUTO_OPTION_BRING_IN": 12,
    "AUTO_OPTION_RAISE_ON_PAIR": 13,
    "AUTO_OPTION_BRING_IN_FOLD": 14,
    "AUTO_OPTION_BRING_IN_CALL": 15,
    "AUTO_OPTION_COMPLETE_TO": 16,
    "AUTO_OPTION_COMPLETE_RAISE": 17,
    "AUTO_OPTION_BET_ON_PAIR": 18,
    "AUTO_OPTION_BET_RAISE_ANY_ON_PAIR": 19,
    "AUTO_OPTION_RAISE_ANY_ON_PAIR": 20,
    "AUTO_OPTION_CALL_BB": 21,
    "AUTO_OPTION_BET_POT": 22,
    "AUTO_OPTION_QUICK_FOLD": 23,
    "R_SIT_ALREADY_OCCUPIED": 122,
    "R_SIT_PLAYING_ALREADY": 123,
    "R_SIT_INSUFFICIENT_CHIPS": 124,
    "R_SIT_SUCCESS": 125,
    "R_REGAIN_PLAYER_SUCCESS": 207,
    "R_BUYCHIPS_USING_FREEROLL_FAILURE": 244,
    "R_BUYCHIPS_USING_FPP_FAILURE": 311,
    "R_SIT_NOT_LOGGED_IN": 151,
    "R_SIT_REALTABLE_PERMISSION_DENIED": 169,
    "R_SIT_WAIT_HAND_END": 178,
    "R_SIT_REGAIN_PLAYER_SUCCESS": 205,
    "R_SIT_REGAIN_PLAYER_FAILED": 206,
    "R_SIT_RESERVED": 209,
    "R_SIT_TABLE_SHUTTING_DOWN": 212,
    "R_SIT_FAILED_SAME_IP": 215,
    "R_BUYCHIPS_FAILED": 137,
    "BLIND_TYPE_SMALL": 520,
    "BLIND_TYPE_BIG": 521,
    "BLIND_TYPE_NEW_PLAYER": 522,
    "BLIND_TYPE_UNKNOWN": 523,
    "ANTE_TYPE_GENERAL": 524,
    "ANTE_TYPE_BUTTON": 531,
    "BLIND_TYPE_BIG_PLUS_DEAD": 525,
    "BLIND_TYPE_NEW_PLAYER_WAIT_FOR_BB": 526,
    "BLIND_TYPE_BIG_PLUS_DEAD_WAIT_FOR_BB": 527,
    "BLIND_TYPE_MISSED_SMALL": 529,
    "BLIND_TYPE_MISSED_SMALL_WAIT_FOR_BB": 530,
    "POKER_POOL_AVAILABLE": 0,
    "POKER_POOL_UNAVAILABLE": 1,
    "POKER_POOL_NOT_VISIBLE": 2,
    "USER_NOT_LOGGED_IN": 3,
    "USER_NOT_REAL_OR_PSEUDO_REAL": 4,
    "PLAYERSTATE_SIT_OUT_WITHOUT_BACK": 10,
    "PLAYERSTATE_SHOW_PLAYNOW": 11,
    "PLAYERSTATE_SHOW_UNRIGISTERED": 12,
    "GAME_TYPE_TEXAS_HOLDEM": 0,
    "GAME_TYPE_OMAHA_HI": 1,
    "GAME_TYPE_OMAHA_HI_LO": 2,
    "GAME_TYPE_SEVEN_STUD_HI": 3,
    "GAME_TYPE_SEVEN_STUD_HI_LO": 4,
    "GAME_TYPE_DOUBLE_HOLDEM": 6,
    "GAME_TYPE_SHORT_DECK": 7,
    "GAME_TYPE_NOLIMIT_HOLDEM": 9,
    "GAME_TYPE_POTLIMIT_HOLDEM": 10,
    "GAME_TYPE_BLACKJACK": 11,
    "GAME_TYPE_5_CARD_OMAHA_HI": 12,
    "GAME_TYPE_5_CARD_OMAHA_HI_LO": 13,
    "GAME_TYPE_6_CARD_OMAHA_HI": 14,
    "GAME_TYPE_6_CARD_OMAHA_HI_LO": 15,
    "GAME_TYPE_COURCHEVEL_HI": 16,
    "GAME_TYPE_COURCHEVEL_HI_LO": 17,
    "GAME_TYPE_SEVEN_STUD_LO": 18,
    "GAME_TYPE_TWO_SEVEN_TRIPLE_DRAW": 19,
    "ERROR_IN_GETTING_DELAY_TIME": -2,
    "DEFAULT_TIME_DELAY": -1,
    "ZERO_TIME_DEALAY": 0,
    "TABLE_CATEGORY_SYSTEM": 0,
    "TABLE_CATEGORY_PRIVATE_TABLE": 1,
    "TABLE_CATEGORY_ONE_ON_ONE": 2,
    "TABLE_CATEGORY_MAX_SIX": 4,
    "TABLE_CATEGORY_TOURNAMENT": 3,
    "TABLE_CATEGORY_TRAINER": 5,
    "TOURNEY_STATUS_UNKNOWN": 0,
    "TOURNEY_STATUS_PAUSED": 24,
    "TOURNEY_STATUS_WAITING_FOR_MORE_PLAYERS": 123,
    "TOURNEY_STATUS_FINISHED": 124,
    "TOURNEY_STATUS_STARTED": 125,
    "TOURNEY_STATUS_DELETED": 126,
    "SHOWING_NONE": 0,
    "SHOWING_IAMBACK": 1,
    "SHOWING_SHOWCARDS_OPTION": 2,
    "SHOWING_PLAY_OPTIONS": 3,
    "SHOWING_BLIND_OPTION": 4,
    "SHOWING_JOINWAITLIST": 5,
    "SHOWING_UNJOINWAITLIST": 6,
    "SHOWING_ANTE_OPTION": 7,
    "SHOWING_STAYIN_OPTION": 8,
    "SHOWING_POINTCARD_OPTION": 9,
    "SHOWING_FOLDNSHOW_OPTION": 10,
    "SHOWING_PLAYNOW_OPTION": 11,
    "SHOWING_FINDASEAT": 12,
    "SHOWING_LEAVEWAITLIST": 13,
    "SHOWING_JOINTABLE": 14,
    "SHOWING_REGULARJOINTABLE": 15,
    "SHOWING_AUTO_OPTIONS": 16,
    "SHOWCARDS_OPTIONS_SHOW": 700,
    "SHOWCARDS_OPTIONS_SHOW_OR_MUCK": 701,
    "SHOWCARDS_OPTIONS_SHOW_OR_DONTSHOW": 702,
    "LIMIT_TYPE_REGULAR": 0,
    "LIMIT_TYPE_NO_LIMIT": 1,
    "LIMIT_TYPE_POT_LIMIT": 2,
    "DEFAULT_ON": 1,
    "DEFAULT_OFF": 0,
    "PRIORITY_HIGH": 3,
    "PRIORITY_MEDIUM": 2,
    "PRIORITY_LOW": 1,
    "PRIORITY_NONE": 0,
    "TOURNAMENT_CATEGORY_MTT": 1,
    "TOURNAMENT_CATEGORY_MTSG": 2,
    "TOURNAMENT_CATEGORY_SNGJP": 3,
    "SEAT_ALIGNMENT_BOTTOM": 0,
    "SEAT_ALIGNMENT_LEFT": 1,
    "SEAT_ALIGNMENT_RIGHT": 2,
    "SEAT_ALIGNMENT_TOP": 3,
    "LAYOUT_TOP_RIGHT": 5,
    "LAYOUT_RIGHT_TOP": 1,
    "LAYOUT_RIGHT_MID": 2,
    "LAYOUT_RIGHT_BTM": 3,
    "LAYOUT_TOP_LEFT": 4,
    "LAYOUT_LEFT_TOP": 6,
    "LAYOUT_LEFT_MID": 7,
    "LAYOUT_LEFT_BTM": 8,
    "LAYOUT_BOTTOM": 9,
    "TABLE_TYPE_SPINS": 0,
    "TABLE_TYPE_FF": 1,
    "TABLE_TYPE_CASH": 2,
    "TABLE_TYPE_TOURNAMENT": 3,
    "TABLE_TYPE_EXPO_SPIN_NORMAL": 3,
    "TABLE_TYPE_EXPO_SPIN_ULTRA": 4
}
