import { Message } from '../../message';

export class HandHistory extends Message {
    private MESSAGE_NAME: string = 'HandHistory';
    private noOfHands: number = 0;
    private gameId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, noOfHands: number = 0, gameId: number = 0) {
        super(reqServerPeerId);
        this.noOfHands = noOfHands;
        this.gameId = gameId;
    }

    getNoOfHands(): number {
        return this.noOfHands;
    }

    setNoOfHands(noOfHands: number) {
        this.noOfHands = noOfHands;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
}
