import { Message } from '../../message';

export class HUSNGRankResultWithRematchRequest extends Message {
    private MESSAGE_NAME: string = 'HUSNGRankResultWithRematchRequest';
    private huRematchDialogueTime: number = 0;
    private targetSNGId: number = 0;
    private gameType: number = 0;
    private tournamentName: any | null = null;
    private buyIn: number = 0;
    private fee: number = 0;
    private currentUserScreenName: string | null = null;
    private otherUserScreenName: string | null = null;
    private rankInfo: any[] | null = null;
    private hasTouneyTicket: boolean | null = false;
    private buyinType: number = 0;
    private rank: number = 0;
    private participantNo: number = 0;
    private ticketType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        huRematchDialogueTime: number = 0,
        targetSNGId: number = 0,
        gameType: number = 0,
        tournamentName: any | null = null,
        buyIn: number = 0,
        fee: number = 0,
        currentUserScreenName: string | null = null,
        otherUserScreenName: string | null = null,
        rankInfo: any[] | null = null,
        hasTouneyTicket: boolean | null = false,
        buyinType: number = 0,
        rank: number = 0,
        participantNo: number = 0,
        ticketType: number = 0,
    ) {
        super(reqServerPeerId);
        this.huRematchDialogueTime = huRematchDialogueTime;
        this.targetSNGId = targetSNGId;
        this.gameType = gameType;
        this.tournamentName = tournamentName;
        this.buyIn = buyIn;
        this.fee = fee;
        this.currentUserScreenName = currentUserScreenName;
        this.otherUserScreenName = otherUserScreenName;
        this.rankInfo = rankInfo;
        this.hasTouneyTicket = hasTouneyTicket;
        this.buyinType = buyinType;
        this.rank = rank;
        this.participantNo = participantNo;
        this.ticketType = ticketType;
    }

    getHuRematchDialogueTime(): number {
        return this.huRematchDialogueTime;
    }

    setHuRematchDialogueTime(huRematchDialogueTime: number) {
        this.huRematchDialogueTime = huRematchDialogueTime;
    }
    getTargetSNGId(): number {
        return this.targetSNGId;
    }

    setTargetSNGId(targetSNGId: number) {
        this.targetSNGId = targetSNGId;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getTournamentName(): any | null {
        return this.tournamentName;
    }

    setTournamentName(tournamentName: any | null) {
        this.tournamentName = tournamentName;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getFee(): number {
        return this.fee;
    }

    setFee(fee: number) {
        this.fee = fee;
    }
    getCurrentUserScreenName(): string | null {
        return this.currentUserScreenName;
    }

    setCurrentUserScreenName(currentUserScreenName: string | null) {
        this.currentUserScreenName = currentUserScreenName;
    }
    getOtherUserScreenName(): string | null {
        return this.otherUserScreenName;
    }

    setOtherUserScreenName(otherUserScreenName: string | null) {
        this.otherUserScreenName = otherUserScreenName;
    }
    getRankInfo(): any[] | null {
        return this.rankInfo;
    }

    setRankInfo(rankInfo: any[] | null) {
        this.rankInfo = rankInfo;
    }
    getHasTouneyTicket(): boolean | null {
        return this.hasTouneyTicket;
    }

    setHasTouneyTicket(hasTouneyTicket: boolean | null) {
        this.hasTouneyTicket = hasTouneyTicket;
    }
    getBuyinType(): number {
        return this.buyinType;
    }

    setBuyinType(buyinType: number) {
        this.buyinType = buyinType;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getTicketType(): number {
        return this.ticketType;
    }

    setTicketType(ticketType: number) {
        this.ticketType = ticketType;
    }
}
