import { Message } from '../../message';

export class LSTourneyDealMakingChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyDealMakingChange';
    private noOfDealMakings: number = 0;
    private dealMakingOn: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, noOfDealMakings: number = 0, dealMakingOn: boolean | null = false) {
        super(reqServerPeerId);
        this.noOfDealMakings = noOfDealMakings;
        this.dealMakingOn = dealMakingOn;
    }

    getNoOfDealMakings(): number {
        return this.noOfDealMakings;
    }

    setNoOfDealMakings(noOfDealMakings: number) {
        this.noOfDealMakings = noOfDealMakings;
    }
    getDealMakingOn(): boolean | null {
        return this.dealMakingOn;
    }

    setDealMakingOn(dealMakingOn: boolean | null) {
        this.dealMakingOn = dealMakingOn;
    }
}
