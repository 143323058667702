import { Injectable } from '@angular/core';

// eslint-disable-next-line no-restricted-imports
import { RemoteLogger } from '@frontend/vanilla/core';

import { PokerLogger } from './logging.service';

@Injectable({
    providedIn: 'root',
})
export class LoggerFactory {
    constructor(private vanillaLogger: RemoteLogger) {}

    getLogger(loggerName: string): PokerLogger {
        return new PokerLogger(loggerName, this.vanillaLogger);
    }
}
