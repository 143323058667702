import { Message } from '../../message';

export class FoldNShowOption extends Message {
    private MESSAGE_NAME: string = 'FoldNShowOption';
    private timeOut: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, timeOut: number = 0) {
        super(reqServerPeerId);
        this.timeOut = timeOut;
    }

    getTimeOut(): number {
        return this.timeOut;
    }

    setTimeOut(timeOut: number) {
        this.timeOut = timeOut;
    }
}
