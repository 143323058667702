import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'pwc-game-holder',
    templateUrl: 'game-holder.component.html',
    imports: [CommonModule],
    providers: [],
    standalone: true,
})
export class GameHolderComponent {}
