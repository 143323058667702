import { Message } from '../../message';
import { GameTableStatus } from './GameTableStatus';

export class RequestJoinTableAutoBuyin extends Message {
    private MESSAGE_NAME: string = 'RequestJoinTableAutoBuyin';
    private tableStatus: GameTableStatus | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableStatus: GameTableStatus | null = null) {
        super(reqServerPeerId);
        this.tableStatus = tableStatus;
    }

    getTableStatus(): GameTableStatus | null {
        return this.tableStatus;
    }

    setTableStatus(tableStatus: GameTableStatus | null) {
        this.tableStatus = tableStatus;
    }
}
