import { Message } from '../../message';
import { FXConversionFactor } from '../../pgSharedMessageFactory/messages/FXConversionFactor';
import { PlayerStatus } from './PlayerStatus';

export class JoinPoolResponse extends Message {
    private MESSAGE_NAME: string = 'JoinPoolResponse';
    private userBalance: number = 0;
    private noOfEntriesRejected: number = 0;
    private entryIds: any[] | null = null;
    private playerStatus: PlayerStatus | null = null;
    private messageToClient: any | null = null;
    private fXConversionFactor: FXConversionFactor | null = null;
    private poolJoinStatus: number = 0;
    private trackerIdList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        userBalance: number = 0,
        noOfEntriesRejected: number = 0,
        entryIds: any[] | null = null,
        playerStatus: PlayerStatus | null = null,
        messageToClient: any | null = null,
        fXConversionFactor: FXConversionFactor | null = null,
        poolJoinStatus: number = 0,
        trackerIdList: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.userBalance = userBalance;
        this.noOfEntriesRejected = noOfEntriesRejected;
        this.entryIds = entryIds;
        this.playerStatus = playerStatus;
        this.messageToClient = messageToClient;
        this.fXConversionFactor = fXConversionFactor;
        this.poolJoinStatus = poolJoinStatus;
        this.trackerIdList = trackerIdList;
    }

    getUserBalance(): number {
        return this.userBalance;
    }

    setUserBalance(userBalance: number) {
        this.userBalance = userBalance;
    }
    getNoOfEntriesRejected(): number {
        return this.noOfEntriesRejected;
    }

    setNoOfEntriesRejected(noOfEntriesRejected: number) {
        this.noOfEntriesRejected = noOfEntriesRejected;
    }
    getEntryIds(): any[] | null {
        return this.entryIds;
    }

    setEntryIds(entryIds: any[] | null) {
        this.entryIds = entryIds;
    }
    getPlayerStatus(): PlayerStatus | null {
        return this.playerStatus;
    }

    setPlayerStatus(playerStatus: PlayerStatus | null) {
        this.playerStatus = playerStatus;
    }
    getMessageToClient(): any | null {
        return this.messageToClient;
    }

    setMessageToClient(messageToClient: any | null) {
        this.messageToClient = messageToClient;
    }
    getFXConversionFactor(): FXConversionFactor | null {
        return this.fXConversionFactor;
    }

    setFXConversionFactor(fXConversionFactor: FXConversionFactor | null) {
        this.fXConversionFactor = fXConversionFactor;
    }
    getPoolJoinStatus(): number {
        return this.poolJoinStatus;
    }

    setPoolJoinStatus(poolJoinStatus: number) {
        this.poolJoinStatus = poolJoinStatus;
    }
    getTrackerIdList(): any[] | null {
        return this.trackerIdList;
    }

    setTrackerIdList(trackerIdList: any[] | null) {
        this.trackerIdList = trackerIdList;
    }
}
