import { Routes } from '@angular/router';

import { PokerWPTDeepStacksOnlineSynopsisResolver } from './poker-WPT-DeepStacks-Online-synopsis.resolver';
import { PokerWPTDeepStacksOnlineResolver } from './poker-WPT-DeepStacks-Online.resolver';

export const pokerWPTDeepStacksOnlineRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWPTDeepStacksOnlineResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerWPTDeepStacksOnlineSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWPTDeepStacksOnlineResolver,
        },
    },
];
