//implementation of inflate
import inflate from './rawInflate';

// import pako from 'pako';

// export function deflate(data) {
//   return  pako.deflate(data);
// }

// export function inflate(data) {
//   try{
//     let inflate = pako.inflate(data);
//     return  inflate;
//   }catch(e) {
//     console.log(e + "ERROR");
//   }

// }

function getStringFromCharCode(bytes: number[], start: number, end: number): string {
    let str = '';
    for (let i = start; i < end; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return str;
}

function getByteArrayFromCharCode(str: string): number[] {
    const bytes: number[] = [];
    for (let i = 0; i < str.length; i++) {
        bytes.push(str.charCodeAt(i) & 0xff);
    }
    return bytes;
}

export function inflateBytes(compressedBytes: number[]): number[] {
    const msgLen = compressedBytes.length;
    const zippedBytes = getStringFromCharCode(compressedBytes, 2, msgLen - 4);
    const uncompressed = inflate(zippedBytes);
    return getByteArrayFromCharCode(uncompressed);
}
