import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NavigationService } from '@frontend/vanilla/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { CardInfo } from '../../models/tile-card-info.model';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';

@Component({
    selector: 'pwc-lobby-tile-info',
    templateUrl: 'lobby-tile-info.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    providers: [TranslatePipe],
})
export class LobbyTileInfoComponent {
    private readonly logger: PokerLogger;
    constructor(
        private dialogRef: MatDialogRef<LobbyTileInfoComponent>,
        private navigation: NavigationService,
        loggerFactory: LoggerFactory,
        @Inject(MAT_DIALOG_DATA) public data: CardInfo,
        private sharedService: PokerWebClientSharedService,
    ) {
        this.logger = loggerFactory.getLogger('lobbyTileInfoLoggingComponent');
    }

    closeModal($event: any = null) {
        this.logger.info('Clicked on cross button info popup..!' + JSON.stringify($event));
        $event && $event.stopImmediatePropagation();
        this.dialogRef.close({ data: 'it is closed now..!!!' });
    }

    onButtonClick(obj) {
        this.logger.info('Clicked on more info button..!' + JSON.stringify(obj));
        if (obj.attributes?.class && obj.attributes.class === 'portal') {
            this.sharedService.sendPortalPage({ text: obj.attributes.title, url: obj.url });
            this.navigation.goTo('/play/' + 'portal');
        } else {
            this.navigation.goTo(obj.url);
        }
        this.closeModal();
    }
}
