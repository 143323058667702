import { Message } from '../../message';

export class CulturalFormat extends Message {
    private MESSAGE_NAME: string = 'CulturalFormat';
    private locale: string | null = null;
    private numberFormat: string | null = null;
    private currencyFormatSymbol: string | null = null;
    private currencyFormatCode: string | null = null;
    private currencyFormatName: string | null = null;
    private currencyFormatSymbolCode: string | null = null;
    private currencyFormatSymbolName: string | null = null;
    private currencyFormatCodeName: string | null = null;
    private dateFormatLong: string | null = null;
    private dateFormatShort: string | null = null;
    private time24Hours: boolean | null = false;
    private defaultFormat: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        locale: string | null = null,
        numberFormat: string | null = null,
        currencyFormatSymbol: string | null = null,
        currencyFormatCode: string | null = null,
        currencyFormatName: string | null = null,
        currencyFormatSymbolCode: string | null = null,
        currencyFormatSymbolName: string | null = null,
        currencyFormatCodeName: string | null = null,
        dateFormatLong: string | null = null,
        dateFormatShort: string | null = null,
        time24Hours: boolean | null = false,
        defaultFormat: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.locale = locale;
        this.numberFormat = numberFormat;
        this.currencyFormatSymbol = currencyFormatSymbol;
        this.currencyFormatCode = currencyFormatCode;
        this.currencyFormatName = currencyFormatName;
        this.currencyFormatSymbolCode = currencyFormatSymbolCode;
        this.currencyFormatSymbolName = currencyFormatSymbolName;
        this.currencyFormatCodeName = currencyFormatCodeName;
        this.dateFormatLong = dateFormatLong;
        this.dateFormatShort = dateFormatShort;
        this.time24Hours = time24Hours;
        this.defaultFormat = defaultFormat;
    }

    getLocale(): string | null {
        return this.locale;
    }

    setLocale(locale: string | null) {
        this.locale = locale;
    }
    getNumberFormat(): string | null {
        return this.numberFormat;
    }

    setNumberFormat(numberFormat: string | null) {
        this.numberFormat = numberFormat;
    }
    getCurrencyFormatSymbol(): string | null {
        return this.currencyFormatSymbol;
    }

    setCurrencyFormatSymbol(currencyFormatSymbol: string | null) {
        this.currencyFormatSymbol = currencyFormatSymbol;
    }
    getCurrencyFormatCode(): string | null {
        return this.currencyFormatCode;
    }

    setCurrencyFormatCode(currencyFormatCode: string | null) {
        this.currencyFormatCode = currencyFormatCode;
    }
    getCurrencyFormatName(): string | null {
        return this.currencyFormatName;
    }

    setCurrencyFormatName(currencyFormatName: string | null) {
        this.currencyFormatName = currencyFormatName;
    }
    getCurrencyFormatSymbolCode(): string | null {
        return this.currencyFormatSymbolCode;
    }

    setCurrencyFormatSymbolCode(currencyFormatSymbolCode: string | null) {
        this.currencyFormatSymbolCode = currencyFormatSymbolCode;
    }
    getCurrencyFormatSymbolName(): string | null {
        return this.currencyFormatSymbolName;
    }

    setCurrencyFormatSymbolName(currencyFormatSymbolName: string | null) {
        this.currencyFormatSymbolName = currencyFormatSymbolName;
    }
    getCurrencyFormatCodeName(): string | null {
        return this.currencyFormatCodeName;
    }

    setCurrencyFormatCodeName(currencyFormatCodeName: string | null) {
        this.currencyFormatCodeName = currencyFormatCodeName;
    }
    getDateFormatLong(): string | null {
        return this.dateFormatLong;
    }

    setDateFormatLong(dateFormatLong: string | null) {
        this.dateFormatLong = dateFormatLong;
    }
    getDateFormatShort(): string | null {
        return this.dateFormatShort;
    }

    setDateFormatShort(dateFormatShort: string | null) {
        this.dateFormatShort = dateFormatShort;
    }
    getTime24Hours(): boolean | null {
        return this.time24Hours;
    }

    setTime24Hours(time24Hours: boolean | null) {
        this.time24Hours = time24Hours;
    }
    getDefaultFormat(): boolean | null {
        return this.defaultFormat;
    }

    setDefaultFormat(defaultFormat: boolean | null) {
        this.defaultFormat = defaultFormat;
    }
}
