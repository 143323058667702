import { Message } from '../../message';

export class ResponseLSTourneyParticipantNames extends Message {
    private MESSAGE_NAME: string = 'ResponseLSTourneyParticipantNames';
    private mtctId: number = 0;
    private participantNames: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, participantNames: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.participantNames = participantNames;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getParticipantNames(): Map<any, any> | null {
        return this.participantNames;
    }

    setParticipantNames(participantNames: Map<any, any> | null) {
        this.participantNames = participantNames;
    }
}
