import { Message } from '../../message';

export class ShowScreenResponse extends Message {
    private MESSAGE_NAME: string = 'ShowScreenResponse';
    private screenType: number = 0;
    private selectedOption: number = 0;
    private isMandatory: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenType: number = 0, selectedOption: number = 0, isMandatory: boolean | null = false) {
        super(reqServerPeerId);
        this.screenType = screenType;
        this.selectedOption = selectedOption;
        this.isMandatory = isMandatory;
    }

    getScreenType(): number {
        return this.screenType;
    }

    setScreenType(screenType: number) {
        this.screenType = screenType;
    }
    getSelectedOption(): number {
        return this.selectedOption;
    }

    setSelectedOption(selectedOption: number) {
        this.selectedOption = selectedOption;
    }
    getIsMandatory(): boolean | null {
        return this.isMandatory;
    }

    setIsMandatory(isMandatory: boolean | null) {
        this.isMandatory = isMandatory;
    }
}
