import { Message } from '../../message';
import { LSMultiDayChangeData } from './LSMultiDayChangeData';

export class LSMTCTUpdateChanges extends Message {
    private MESSAGE_NAME: string = 'LSMTCTUpdateChanges';
    private mtctId: number = 0;
    private currentLevel: number = 0;
    private buyInInfo: any | null = null;
    private partyPoints: number = 0;
    private allowedCountryCodes: string | null = null;
    private firstRankPlayerName: string | null = null;
    private tourneyEndTime: any | null = null;
    private lateRegInProgress: boolean | null = false;
    private playerCount: number = 0;
    private totalPrizePool: number = 0;
    private noOfParticipants: number = 0;
    private status: number = 0;
    private unregClosed: boolean | null = false;
    private multiDayChangeData: LSMultiDayChangeData | null = null;
    private events: any[] | null = null;
    private qualifiers: any[] | null = null;
    private tourneyStartTime: any | null = null;
    private maxSeats: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        currentLevel: number = 0,
        buyInInfo: any | null = null,
        partyPoints: number = 0,
        allowedCountryCodes: string | null = null,
        firstRankPlayerName: string | null = null,
        tourneyEndTime: any | null = null,
        lateRegInProgress: boolean | null = false,
        playerCount: number = 0,
        totalPrizePool: number = 0,
        noOfParticipants: number = 0,
        status: number = 0,
        unregClosed: boolean | null = false,
        multiDayChangeData: LSMultiDayChangeData | null = null,
        events: any[] | null = null,
        qualifiers: any[] | null = null,
        tourneyStartTime: any | null = null,
        maxSeats: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.currentLevel = currentLevel;
        this.buyInInfo = buyInInfo;
        this.partyPoints = partyPoints;
        this.allowedCountryCodes = allowedCountryCodes;
        this.firstRankPlayerName = firstRankPlayerName;
        this.tourneyEndTime = tourneyEndTime;
        this.lateRegInProgress = lateRegInProgress;
        this.playerCount = playerCount;
        this.totalPrizePool = totalPrizePool;
        this.noOfParticipants = noOfParticipants;
        this.status = status;
        this.unregClosed = unregClosed;
        this.multiDayChangeData = multiDayChangeData;
        this.events = events;
        this.qualifiers = qualifiers;
        this.tourneyStartTime = tourneyStartTime;
        this.maxSeats = maxSeats;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getCurrentLevel(): number {
        return this.currentLevel;
    }

    setCurrentLevel(currentLevel: number) {
        this.currentLevel = currentLevel;
    }
    getBuyInInfo(): any | null {
        return this.buyInInfo;
    }

    setBuyInInfo(buyInInfo: any | null) {
        this.buyInInfo = buyInInfo;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getAllowedCountryCodes(): string | null {
        return this.allowedCountryCodes;
    }

    setAllowedCountryCodes(allowedCountryCodes: string | null) {
        this.allowedCountryCodes = allowedCountryCodes;
    }
    getFirstRankPlayerName(): string | null {
        return this.firstRankPlayerName;
    }

    setFirstRankPlayerName(firstRankPlayerName: string | null) {
        this.firstRankPlayerName = firstRankPlayerName;
    }
    getTourneyEndTime(): any | null {
        return this.tourneyEndTime;
    }

    setTourneyEndTime(tourneyEndTime: any | null) {
        this.tourneyEndTime = tourneyEndTime;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
    getMultiDayChangeData(): LSMultiDayChangeData | null {
        return this.multiDayChangeData;
    }

    setMultiDayChangeData(multiDayChangeData: LSMultiDayChangeData | null) {
        this.multiDayChangeData = multiDayChangeData;
    }
    getEvents(): any[] | null {
        return this.events;
    }

    setEvents(events: any[] | null) {
        this.events = events;
    }
    getQualifiers(): any[] | null {
        return this.qualifiers;
    }

    setQualifiers(qualifiers: any[] | null) {
        this.qualifiers = qualifiers;
    }
    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
}
