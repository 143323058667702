import { Message } from '../../message';

export class PoolSitOutNotification extends Message {
    private MESSAGE_NAME: string = 'PoolSitOutNotification';
    private entryId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, entryId: number = 0) {
        super(reqServerPeerId);
        this.entryId = entryId;
    }

    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
}
