import { Injectable } from '@angular/core';

import { OnFeatureInit } from '@frontend/vanilla/core';

import { FlagsConfig } from './flags.client-config';

@Injectable()
export class FlagsBootstrapService implements OnFeatureInit {
    constructor(private config: FlagsConfig) {}

    onFeatureInit() {
        this.config.whenReady.subscribe();
    }
}
