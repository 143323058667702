import { Message } from '../../message';

export class AllInsRemaining extends Message {
    private MESSAGE_NAME: string = 'AllInsRemaining';
    private allIns: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, allIns: number = 0) {
        super(reqServerPeerId);
        this.allIns = allIns;
    }

    getAllIns(): number {
        return this.allIns;
    }

    setAllIns(allIns: number) {
        this.allIns = allIns;
    }
}
