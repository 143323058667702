import { CSD } from './CSD';
import { ConnectionManager } from './connectionManager';
import { IConnectionManager } from './interfaces/IconnectionManager';

export class Connector extends ConnectionManager implements IConnectionManager {
    constructor(serverIp) {
        super(serverIp);
    }

    override initiateConnectionWithParams(params: Map<string, any>) {
        this.initiateConnections(params);
        try {
            this.connectToServer(0, CSD.REAL);
        } catch (e) {}
    }
}
