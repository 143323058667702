import { Message } from '../../message';

export class JoinTableAutoBuyIn extends Message {
    private MESSAGE_NAME: string = 'JoinTableAutoBuyIn';
    private password: string | null = null;
    private requestType: number = 0;
    private conversationId: number = 0;
    private tableCategory: number = 0;
    private seatNumber: number = 0;
    private source: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        password: string | null = null,
        requestType: number = 0,
        conversationId: number = 0,
        tableCategory: number = 0,
        seatNumber: number = 0,
        source: number = 0,
    ) {
        super(reqServerPeerId);
        this.password = password;
        this.requestType = requestType;
        this.conversationId = conversationId;
        this.tableCategory = tableCategory;
        this.seatNumber = seatNumber;
        this.source = source;
    }

    getPassword(): string | null {
        return this.password;
    }

    setPassword(password: string | null) {
        this.password = password;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getSeatNumber(): number {
        return this.seatNumber;
    }

    setSeatNumber(seatNumber: number) {
        this.seatNumber = seatNumber;
    }
    getSource(): number {
        return this.source;
    }

    setSource(source: number) {
        this.source = source;
    }
}
