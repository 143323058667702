import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { MenuActionComponent, NavigationService } from '@frontend/vanilla/core';
import { GlobalClientConfig } from '@pokercore/module/core';
import { PokerTeaserCarouselComponent } from '@pokercore/module/poker-download';

import { HomepageResourceService } from '../homepage-resource.service';
import { FlipClockComponent } from './flip-clock/flip-clock.component';

@Component({
    selector: 'pk-teasers',
    templateUrl: 'teasers.component.html',
    styleUrls: ['teasers.component.css'],
    standalone: true,
    imports: [CommonModule, PokerTeaserCarouselComponent, FlipClockComponent, MenuActionComponent],
})
export class TeasersComponent implements OnInit, OnDestroy {
    EnableJackpotTickerTeasers: any;
    breakpointWidths: any = [320, 414, 480, 667, 768, 1024, 1436];
    standardWidths: any = [320, 480, 640, 800, 1024, 1280];
    teaserWidth: any;
    teaserHeight: any;
    elementWidth: any;
    elementHeight: any;
    jackpot_image: any;
    index: any = 0;
    eventId: any = null;
    slideEventId: any = null;
    eventClickId: any;
    isNewSportsNative: any;
    isLandNative: any;
    jackpotHandle: any;
    jpTitle: any = 24;
    jpContainer: any = 37;
    jpCta: any = 48;
    teasers: any = [];

    extract: any;
    embedded: any;
    moreMessage: any;
    moreInfoMessage: any;
    isScreenshotMode: any;
    slideInterval: any;
    useMD5: any;
    autoslide: any;
    isLandscape: any;
    teaserLandscape: any;
    isiOS: any;
    useMD3: any;
    isTeaserMode: any;
    showPromoInfo: any;
    margin: any;
    promoMessage: any;
    downloadContent: any;
    isButtonVisible: boolean;
    isTakeOverteaser: boolean;
    isTouch: boolean;
    TeaserMode: any;
    newTeasers: any;
    teasersMainClass: string = 'teasers';
    interval: number = 5000;
    arrows: boolean = true;
    dots: boolean = true;
    loop: boolean = true;
    autoplay: boolean = true;
    class: any;
    pauseOnHover: boolean = true;
    teasersResponse: any = [];

    constructor(
        private global: GlobalClientConfig,
        private navigation: NavigationService,
        private homePageService: HomepageResourceService,
    ) {}
    ngOnInit() {
        // Get all teasers
        this.homePageService.getAllTeasers().subscribe((response: any) => {
            this.teasersResponse = response;
            this.arrows = this.teasersResponse.length > 1 ? true : false;
            this.dots = this.teasersResponse.length > 1 ? true : false;
            this.loop = this.teasersResponse.length > 1 ? true : false;
            this.class = this.assignBrowserSpecificClass();
        });

        this.global.whenReady.subscribe(() => {
            if (this.global && this.global.config && this.global.config.sharedList) {
                this.TeaserMode = this.global.config.sharedList.TeaserMode;
            }
        });
    }
    assignBrowserSpecificClass() {
        if (navigator.userAgent.indexOf('Edge') != -1) {
            return 'edge';
        } else {
            return null;
        }
    }
    teaserClick(value: any) {
        if (value) {
        }
    }
    ngOnDestroy() {
        if (this.isLandscape) {
            document.documentElement.classList.remove('landscape');
        }
    }
    public ctaClick(value: any) {
        if (value) {
            const teaser: any = value.teaser;
            if (teaser && teaser.ctaLink) {
                this.navigation.goTo(teaser.ctaLink.url);
            }
        }
    }
}
