import { Message } from '../../message';
import { LSHeadsUpTourenyPlayerSequence } from './LSHeadsUpTourenyPlayerSequence';
import { LSMTCTLobbyData } from './LSMTCTLobbyData';
import { LSTourneyBlindStructureDetails } from './LSTourneyBlindStructureDetails';
import { LSTourneyLobbyStackInfo } from './LSTourneyLobbyStackInfo';
import { LSTourneyPrizeInfo } from './LSTourneyPrizeInfo';
import { LSTourneyRebuyAddonInfo } from './LSTourneyRebuyAddonInfo';
import { LSTourneyRoundInfo } from './LSTourneyRoundInfo';
import { LSTourneyShootoutInfo } from './LSTourneyShootoutInfo';
import { LSTourneyTimeBank } from './LSTourneyTimeBank';
import { LSTourneyTimeToAct } from './LSTourneyTimeToAct';

export class LSTourneyLobbyData extends Message {
    private MESSAGE_NAME: string = 'LSTourneyLobbyData';
    private mtctId: number = 0;
    private synchronisedBreakTrny: boolean | null = false;
    private defaultChips: number = 0;
    private info: any | null = null;
    private isTrnyFeeTypePercent: boolean | null = false;
    private breakDuration: number = 0;
    private breakInterval: number = 0;
    private dealMakingOn: boolean | null = false;
    private noOfDealMakings: number = 0;
    private nextBreakTime: number = 0;
    private nextLevelValue: number = 0;
    private mainLobbyData: LSMTCTLobbyData | null = null;
    private tourneyRoundInfo: LSTourneyRoundInfo | null = null;
    private prizeInfo: LSTourneyPrizeInfo | null = null;
    private rebuyAddonInfo: LSTourneyRebuyAddonInfo | null = null;
    private shootoutInfo: LSTourneyShootoutInfo | null = null;
    private stackInfo: LSTourneyLobbyStackInfo | null = null;
    private blindStructureDetails: LSTourneyBlindStructureDetails | null = null;
    private headsUpTourenyPlayerSequence: LSHeadsUpTourenyPlayerSequence | null = null;
    private byeRoundOrderList: any[] | null = null;
    private addedPrizePool: number = 0;
    private ringFencedAmount: number = 0;
    private finalTableHandsPerLevel: number = 0;
    private nextLevelChangeType: number = 0;
    private timetoAct: LSTourneyTimeToAct | null = null;
    private preFlopTimeBank: LSTourneyTimeBank | null = null;
    private postFlopTimeBank: LSTourneyTimeBank | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        synchronisedBreakTrny: boolean | null = false,
        defaultChips: number = 0,
        info: any | null = null,
        isTrnyFeeTypePercent: boolean | null = false,
        breakDuration: number = 0,
        breakInterval: number = 0,
        dealMakingOn: boolean | null = false,
        noOfDealMakings: number = 0,
        nextBreakTime: number = 0,
        nextLevelValue: number = 0,
        mainLobbyData: LSMTCTLobbyData | null = null,
        tourneyRoundInfo: LSTourneyRoundInfo | null = null,
        prizeInfo: LSTourneyPrizeInfo | null = null,
        rebuyAddonInfo: LSTourneyRebuyAddonInfo | null = null,
        shootoutInfo: LSTourneyShootoutInfo | null = null,
        stackInfo: LSTourneyLobbyStackInfo | null = null,
        blindStructureDetails: LSTourneyBlindStructureDetails | null = null,
        headsUpTourenyPlayerSequence: LSHeadsUpTourenyPlayerSequence | null = null,
        byeRoundOrderList: any[] | null = null,
        addedPrizePool: number = 0,
        ringFencedAmount: number = 0,
        finalTableHandsPerLevel: number = 0,
        nextLevelChangeType: number = 0,
        timetoAct: LSTourneyTimeToAct | null = null,
        preFlopTimeBank: LSTourneyTimeBank | null = null,
        postFlopTimeBank: LSTourneyTimeBank | null = null,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.synchronisedBreakTrny = synchronisedBreakTrny;
        this.defaultChips = defaultChips;
        this.info = info;
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
        this.breakDuration = breakDuration;
        this.breakInterval = breakInterval;
        this.dealMakingOn = dealMakingOn;
        this.noOfDealMakings = noOfDealMakings;
        this.nextBreakTime = nextBreakTime;
        this.nextLevelValue = nextLevelValue;
        this.mainLobbyData = mainLobbyData;
        this.tourneyRoundInfo = tourneyRoundInfo;
        this.prizeInfo = prizeInfo;
        this.rebuyAddonInfo = rebuyAddonInfo;
        this.shootoutInfo = shootoutInfo;
        this.stackInfo = stackInfo;
        this.blindStructureDetails = blindStructureDetails;
        this.headsUpTourenyPlayerSequence = headsUpTourenyPlayerSequence;
        this.byeRoundOrderList = byeRoundOrderList;
        this.addedPrizePool = addedPrizePool;
        this.ringFencedAmount = ringFencedAmount;
        this.finalTableHandsPerLevel = finalTableHandsPerLevel;
        this.nextLevelChangeType = nextLevelChangeType;
        this.timetoAct = timetoAct;
        this.preFlopTimeBank = preFlopTimeBank;
        this.postFlopTimeBank = postFlopTimeBank;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getSynchronisedBreakTrny(): boolean | null {
        return this.synchronisedBreakTrny;
    }

    setSynchronisedBreakTrny(synchronisedBreakTrny: boolean | null) {
        this.synchronisedBreakTrny = synchronisedBreakTrny;
    }
    getDefaultChips(): number {
        return this.defaultChips;
    }

    setDefaultChips(defaultChips: number) {
        this.defaultChips = defaultChips;
    }
    getInfo(): any | null {
        return this.info;
    }

    setInfo(info: any | null) {
        this.info = info;
    }
    getIsTrnyFeeTypePercent(): boolean | null {
        return this.isTrnyFeeTypePercent;
    }

    setIsTrnyFeeTypePercent(isTrnyFeeTypePercent: boolean | null) {
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
    }
    getBreakDuration(): number {
        return this.breakDuration;
    }

    setBreakDuration(breakDuration: number) {
        this.breakDuration = breakDuration;
    }
    getBreakInterval(): number {
        return this.breakInterval;
    }

    setBreakInterval(breakInterval: number) {
        this.breakInterval = breakInterval;
    }
    getDealMakingOn(): boolean | null {
        return this.dealMakingOn;
    }

    setDealMakingOn(dealMakingOn: boolean | null) {
        this.dealMakingOn = dealMakingOn;
    }
    getNoOfDealMakings(): number {
        return this.noOfDealMakings;
    }

    setNoOfDealMakings(noOfDealMakings: number) {
        this.noOfDealMakings = noOfDealMakings;
    }
    getNextBreakTime(): number {
        return this.nextBreakTime;
    }

    setNextBreakTime(nextBreakTime: number) {
        this.nextBreakTime = nextBreakTime;
    }
    getNextLevelValue(): number {
        return this.nextLevelValue;
    }

    setNextLevelValue(nextLevelValue: number) {
        this.nextLevelValue = nextLevelValue;
    }
    getMainLobbyData(): LSMTCTLobbyData | null {
        return this.mainLobbyData;
    }

    setMainLobbyData(mainLobbyData: LSMTCTLobbyData | null) {
        this.mainLobbyData = mainLobbyData;
    }
    getTourneyRoundInfo(): LSTourneyRoundInfo | null {
        return this.tourneyRoundInfo;
    }

    setTourneyRoundInfo(tourneyRoundInfo: LSTourneyRoundInfo | null) {
        this.tourneyRoundInfo = tourneyRoundInfo;
    }
    getPrizeInfo(): LSTourneyPrizeInfo | null {
        return this.prizeInfo;
    }

    setPrizeInfo(prizeInfo: LSTourneyPrizeInfo | null) {
        this.prizeInfo = prizeInfo;
    }
    getRebuyAddonInfo(): LSTourneyRebuyAddonInfo | null {
        return this.rebuyAddonInfo;
    }

    setRebuyAddonInfo(rebuyAddonInfo: LSTourneyRebuyAddonInfo | null) {
        this.rebuyAddonInfo = rebuyAddonInfo;
    }
    getShootoutInfo(): LSTourneyShootoutInfo | null {
        return this.shootoutInfo;
    }

    setShootoutInfo(shootoutInfo: LSTourneyShootoutInfo | null) {
        this.shootoutInfo = shootoutInfo;
    }
    getStackInfo(): LSTourneyLobbyStackInfo | null {
        return this.stackInfo;
    }

    setStackInfo(stackInfo: LSTourneyLobbyStackInfo | null) {
        this.stackInfo = stackInfo;
    }
    getBlindStructureDetails(): LSTourneyBlindStructureDetails | null {
        return this.blindStructureDetails;
    }

    setBlindStructureDetails(blindStructureDetails: LSTourneyBlindStructureDetails | null) {
        this.blindStructureDetails = blindStructureDetails;
    }
    getHeadsUpTourenyPlayerSequence(): LSHeadsUpTourenyPlayerSequence | null {
        return this.headsUpTourenyPlayerSequence;
    }

    setHeadsUpTourenyPlayerSequence(headsUpTourenyPlayerSequence: LSHeadsUpTourenyPlayerSequence | null) {
        this.headsUpTourenyPlayerSequence = headsUpTourenyPlayerSequence;
    }
    getByeRoundOrderList(): any[] | null {
        return this.byeRoundOrderList;
    }

    setByeRoundOrderList(byeRoundOrderList: any[] | null) {
        this.byeRoundOrderList = byeRoundOrderList;
    }
    getAddedPrizePool(): number {
        return this.addedPrizePool;
    }

    setAddedPrizePool(addedPrizePool: number) {
        this.addedPrizePool = addedPrizePool;
    }
    getRingFencedAmount(): number {
        return this.ringFencedAmount;
    }

    setRingFencedAmount(ringFencedAmount: number) {
        this.ringFencedAmount = ringFencedAmount;
    }
    getFinalTableHandsPerLevel(): number {
        return this.finalTableHandsPerLevel;
    }

    setFinalTableHandsPerLevel(finalTableHandsPerLevel: number) {
        this.finalTableHandsPerLevel = finalTableHandsPerLevel;
    }
    getNextLevelChangeType(): number {
        return this.nextLevelChangeType;
    }

    setNextLevelChangeType(nextLevelChangeType: number) {
        this.nextLevelChangeType = nextLevelChangeType;
    }
    getTimetoAct(): LSTourneyTimeToAct | null {
        return this.timetoAct;
    }

    setTimetoAct(timetoAct: LSTourneyTimeToAct | null) {
        this.timetoAct = timetoAct;
    }
    getPreFlopTimeBank(): LSTourneyTimeBank | null {
        return this.preFlopTimeBank;
    }

    setPreFlopTimeBank(preFlopTimeBank: LSTourneyTimeBank | null) {
        this.preFlopTimeBank = preFlopTimeBank;
    }
    getPostFlopTimeBank(): LSTourneyTimeBank | null {
        return this.postFlopTimeBank;
    }

    setPostFlopTimeBank(postFlopTimeBank: LSTourneyTimeBank | null) {
        this.postFlopTimeBank = postFlopTimeBank;
    }
}
