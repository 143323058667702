import { Message } from '../../message';

export class SelectedOption extends Message {
    private MESSAGE_NAME: string = 'SelectedOption';
    private seatNo: number = 0;
    private optionId: number = 0;
    private amount: number = 0;
    private isAllIn: boolean | null = false;
    private betVector: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNo: number = 0,
        optionId: number = 0,
        amount: number = 0,
        isAllIn: boolean | null = false,
        betVector: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.optionId = optionId;
        this.amount = amount;
        this.isAllIn = isAllIn;
        this.betVector = betVector;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getOptionId(): number {
        return this.optionId;
    }

    setOptionId(optionId: number) {
        this.optionId = optionId;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getIsAllIn(): boolean | null {
        return this.isAllIn;
    }

    setIsAllIn(isAllIn: boolean | null) {
        this.isAllIn = isAllIn;
    }
    getBetVector(): any[] | null {
        return this.betVector;
    }

    setBetVector(betVector: any[] | null) {
        this.betVector = betVector;
    }
}
