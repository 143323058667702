import { Message } from '../../message';

export class Buddy extends Message {
    private MESSAGE_NAME: string = 'Buddy';
    private buddyId: string | null = null;
    private status: number = 0;
    private isOnline: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, buddyId: string | null = null, status: number = 0, isOnline: boolean | null = false) {
        super(reqServerPeerId);
        this.buddyId = buddyId;
        this.status = status;
        this.isOnline = isOnline;
    }

    getBuddyId(): string | null {
        return this.buddyId;
    }

    setBuddyId(buddyId: string | null) {
        this.buddyId = buddyId;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getIsOnline(): boolean | null {
        return this.isOnline;
    }

    setIsOnline(isOnline: boolean | null) {
        this.isOnline = isOnline;
    }
}
