export class TournamentCalendarPagerService {
    getTournamentCalendarPager(totalItems: number, currentPage: number = 1, pageSize: number = 18) {
        const totalPages = Math.ceil(totalItems / pageSize);
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        const startPage: number = 1;
        if (currentPage <= 4) {
            const pages = Array.from(Array(currentPage + 1).keys()).map((i) => i + 1);
            pages.unshift(startPage);
            pages.unshift(999); //add '...' in the pager
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
            const endPage = endIndex;

            return {
                totalItems,
                currentPage,
                pageSize,
                totalPages,
                startPage,
                endPage,
                startIndex,
                endIndex,
                pages,
            };
        } else if (currentPage + 4 >= totalPages) {
            const pages = Array.from(Array(totalPages - currentPage + 1).keys()).map((i) => currentPage - 1 + i);
            pages.unshift(startPage);
            pages.unshift(999); //for adding '...' in the pager
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
            const endPage = endIndex;
            return {
                totalItems,
                currentPage,
                pageSize,
                totalPages,
                startPage,
                endPage,
                startIndex,
                endIndex,
                pages,
            };
        } else {
            const pages = Array.from(Array(3).keys()).map((i) => currentPage - 1 + i);
            pages.unshift(startPage);
            pages.unshift(999);
            pages.unshift(startPage);
            pages.unshift(999);
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
            const endPage = endIndex;
            return {
                totalItems,
                currentPage,
                pageSize,
                totalPages,
                startPage,
                endPage,
                startIndex,
                endIndex,
                pages,
            };
        }
    }
}
