import { Message } from '../../message';
import { UserAvatarInfo } from '../../pgSharedMessageFactory/messages/UserAvatarInfo';

export class AvatarUpdateEventMessage extends Message {
    private MESSAGE_NAME: string = 'AvatarUpdateEventMessage';
    private userId: string | null = null;
    private userAvatarInfo: UserAvatarInfo | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, userId: string | null = null, userAvatarInfo: UserAvatarInfo | null = null) {
        super(reqServerPeerId);
        this.userId = userId;
        this.userAvatarInfo = userAvatarInfo;
    }

    getUserId(): string | null {
        return this.userId;
    }

    setUserId(userId: string | null) {
        this.userId = userId;
    }
    getUserAvatarInfo(): UserAvatarInfo | null {
        return this.userAvatarInfo;
    }

    setUserAvatarInfo(userAvatarInfo: UserAvatarInfo | null) {
        this.userAvatarInfo = userAvatarInfo;
    }
}
