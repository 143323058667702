import { Message } from '../../message';

export class RequestSNGJPPlayNowUpdate extends Message {
    private MESSAGE_NAME: string = 'RequestSNGJPPlayNowUpdate';
    private lastSnapShotTimeInNanos: number = 0;
    private domain: number = 0;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lastSnapShotTimeInNanos: number = 0, domain: number = 0, requestType: number = 0) {
        super(reqServerPeerId);
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
        this.domain = domain;
        this.requestType = requestType;
    }

    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
