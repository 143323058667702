import { Message } from '../../message';

export class CreateChallengeHUSNGRequest extends Message {
    private MESSAGE_NAME: string = 'CreateChallengeHUSNGRequest';
    private buyIn: number = 0;
    private fee: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private speed: number = 0;
    private isDefaultMessage: boolean | null = false;
    private message: string | null = null;
    private invitee: string | null = null;
    private challengeId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        buyIn: number = 0,
        fee: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        speed: number = 0,
        isDefaultMessage: boolean | null = false,
        message: string | null = null,
        invitee: string | null = null,
        challengeId: number = 0,
    ) {
        super(reqServerPeerId);
        this.buyIn = buyIn;
        this.fee = fee;
        this.gameType = gameType;
        this.limitType = limitType;
        this.speed = speed;
        this.isDefaultMessage = isDefaultMessage;
        this.message = message;
        this.invitee = invitee;
        this.challengeId = challengeId;
    }

    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getFee(): number {
        return this.fee;
    }

    setFee(fee: number) {
        this.fee = fee;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getSpeed(): number {
        return this.speed;
    }

    setSpeed(speed: number) {
        this.speed = speed;
    }
    getIsDefaultMessage(): boolean | null {
        return this.isDefaultMessage;
    }

    setIsDefaultMessage(isDefaultMessage: boolean | null) {
        this.isDefaultMessage = isDefaultMessage;
    }
    getMessage(): string | null {
        return this.message;
    }

    setMessage(message: string | null) {
        this.message = message;
    }
    getInvitee(): string | null {
        return this.invitee;
    }

    setInvitee(invitee: string | null) {
        this.invitee = invitee;
    }
    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
}
