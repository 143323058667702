import { Message } from '../../message';

export class LSMTCTPlayerCountChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSMTCTPlayerCountChangeEvent';
    private mtctId: number = 0;
    private playerCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, playerCount: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.playerCount = playerCount;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
}
