import { Message } from '../../message';

export class DealCards extends Message {
    private MESSAGE_NAME: string = 'DealCards';
    private numberOfTurns: number = 0;
    private playerDealCards: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, numberOfTurns: number = 0, playerDealCards: any[] | null = null) {
        super(reqServerPeerId);
        this.numberOfTurns = numberOfTurns;
        this.playerDealCards = playerDealCards;
    }

    getNumberOfTurns(): number {
        return this.numberOfTurns;
    }

    setNumberOfTurns(numberOfTurns: number) {
        this.numberOfTurns = numberOfTurns;
    }
    getPlayerDealCards(): any[] | null {
        return this.playerDealCards;
    }

    setPlayerDealCards(playerDealCards: any[] | null) {
        this.playerDealCards = playerDealCards;
    }
}
