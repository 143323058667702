import { Message } from '../../message';

export class JoinTableQS extends Message {
    private MESSAGE_NAME: string = 'JoinTableQS';
    private requestType: number = 0;
    private conversationId: number = 0;
    private tableCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestType: number = 0, conversationId: number = 0, tableCategory: number = 0) {
        super(reqServerPeerId);
        this.requestType = requestType;
        this.conversationId = conversationId;
        this.tableCategory = tableCategory;
    }

    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
}
