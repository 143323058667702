import { Message } from '../../message';

export class UCID extends Message {
    private MESSAGE_NAME: string = 'UCID';
    private id: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, id: string | null = null) {
        super(reqServerPeerId);
        this.id = id;
    }

    getId(): string | null {
        return this.id;
    }

    setId(id: string | null) {
        this.id = id;
    }
}
