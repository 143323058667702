import { Message } from '../../message';

export class TourneyCurrencyBalance extends Message {
    private MESSAGE_NAME: string = 'TourneyCurrencyBalance';
    private tourneyCurrencyBalance: number = 0;
    private tourneyCurrencyType: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tourneyCurrencyBalance: number = 0, tourneyCurrencyType: string | null = null) {
        super(reqServerPeerId);
        this.tourneyCurrencyBalance = tourneyCurrencyBalance;
        this.tourneyCurrencyType = tourneyCurrencyType;
    }

    getTourneyCurrencyBalance(): number {
        return this.tourneyCurrencyBalance;
    }

    setTourneyCurrencyBalance(tourneyCurrencyBalance: number) {
        this.tourneyCurrencyBalance = tourneyCurrencyBalance;
    }
    getTourneyCurrencyType(): string | null {
        return this.tourneyCurrencyType;
    }

    setTourneyCurrencyType(tourneyCurrencyType: string | null) {
        this.tourneyCurrencyType = tourneyCurrencyType;
    }
}
