import { Routes } from '@angular/router';

import { PokerPublicPageLoaderComponent } from './poker-public-page-loader.component';

export const pokerGenericPublicPageLoaderRoutes: Routes = [
    {
        path: '**',
        component: PokerPublicPageLoaderComponent,
        data: {
            publicPageRoot: 'MobilePoker-v1.0/',
        },
    },
];
