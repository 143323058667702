import { Message } from '../../message';
import { FXRateDetails } from '../../pgSharedMessageFactory/messages/FXRateDetails';

export class MTCTRebuyAddonDialogInfo extends Message {
    private MESSAGE_NAME: string = 'MTCTRebuyAddonDialogInfo';
    private addonDialog: boolean | null = false;
    private buyIn: number = 0;
    private fee: number = 0;
    private chipCount: number = 0;
    private buysTillNow: number = 0;
    private rebuyEndBreakNumber: number = 0;
    private buysLeft: number = 0;
    private errorMessage: any | null = null;
    private accountBalance: number = 0;
    private tdBalance: number = 0;
    private dialogCloseTime: number = 0;
    private isTrnyFeeTypePercent: boolean | null = false;
    private trnyFeePercent: number = 0;
    private fxRateDetails: FXRateDetails | null = null;
    private playerBroke: boolean | null = false;
    private synchronizedBreakTrny: boolean | null = false;
    private rebuyEndMode: number = 0;
    private rebuyEndValue: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        addonDialog: boolean | null = false,
        buyIn: number = 0,
        fee: number = 0,
        chipCount: number = 0,
        buysTillNow: number = 0,
        rebuyEndBreakNumber: number = 0,
        buysLeft: number = 0,
        errorMessage: any | null = null,
        accountBalance: number = 0,
        tdBalance: number = 0,
        dialogCloseTime: number = 0,
        isTrnyFeeTypePercent: boolean | null = false,
        trnyFeePercent: number = 0,
        fxRateDetails: FXRateDetails | null = null,
        playerBroke: boolean | null = false,
        synchronizedBreakTrny: boolean | null = false,
        rebuyEndMode: number = 0,
        rebuyEndValue: number = 0,
    ) {
        super(reqServerPeerId);
        this.addonDialog = addonDialog;
        this.buyIn = buyIn;
        this.fee = fee;
        this.chipCount = chipCount;
        this.buysTillNow = buysTillNow;
        this.rebuyEndBreakNumber = rebuyEndBreakNumber;
        this.buysLeft = buysLeft;
        this.errorMessage = errorMessage;
        this.accountBalance = accountBalance;
        this.tdBalance = tdBalance;
        this.dialogCloseTime = dialogCloseTime;
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
        this.trnyFeePercent = trnyFeePercent;
        this.fxRateDetails = fxRateDetails;
        this.playerBroke = playerBroke;
        this.synchronizedBreakTrny = synchronizedBreakTrny;
        this.rebuyEndMode = rebuyEndMode;
        this.rebuyEndValue = rebuyEndValue;
    }

    getAddonDialog(): boolean | null {
        return this.addonDialog;
    }

    setAddonDialog(addonDialog: boolean | null) {
        this.addonDialog = addonDialog;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getFee(): number {
        return this.fee;
    }

    setFee(fee: number) {
        this.fee = fee;
    }
    getChipCount(): number {
        return this.chipCount;
    }

    setChipCount(chipCount: number) {
        this.chipCount = chipCount;
    }
    getBuysTillNow(): number {
        return this.buysTillNow;
    }

    setBuysTillNow(buysTillNow: number) {
        this.buysTillNow = buysTillNow;
    }
    getRebuyEndBreakNumber(): number {
        return this.rebuyEndBreakNumber;
    }

    setRebuyEndBreakNumber(rebuyEndBreakNumber: number) {
        this.rebuyEndBreakNumber = rebuyEndBreakNumber;
    }
    getBuysLeft(): number {
        return this.buysLeft;
    }

    setBuysLeft(buysLeft: number) {
        this.buysLeft = buysLeft;
    }
    getErrorMessage(): any | null {
        return this.errorMessage;
    }

    setErrorMessage(errorMessage: any | null) {
        this.errorMessage = errorMessage;
    }
    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getTdBalance(): number {
        return this.tdBalance;
    }

    setTdBalance(tdBalance: number) {
        this.tdBalance = tdBalance;
    }
    getDialogCloseTime(): number {
        return this.dialogCloseTime;
    }

    setDialogCloseTime(dialogCloseTime: number) {
        this.dialogCloseTime = dialogCloseTime;
    }
    getIsTrnyFeeTypePercent(): boolean | null {
        return this.isTrnyFeeTypePercent;
    }

    setIsTrnyFeeTypePercent(isTrnyFeeTypePercent: boolean | null) {
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
    }
    getTrnyFeePercent(): number {
        return this.trnyFeePercent;
    }

    setTrnyFeePercent(trnyFeePercent: number) {
        this.trnyFeePercent = trnyFeePercent;
    }
    getFxRateDetails(): FXRateDetails | null {
        return this.fxRateDetails;
    }

    setFxRateDetails(fxRateDetails: FXRateDetails | null) {
        this.fxRateDetails = fxRateDetails;
    }
    getPlayerBroke(): boolean | null {
        return this.playerBroke;
    }

    setPlayerBroke(playerBroke: boolean | null) {
        this.playerBroke = playerBroke;
    }
    getSynchronizedBreakTrny(): boolean | null {
        return this.synchronizedBreakTrny;
    }

    setSynchronizedBreakTrny(synchronizedBreakTrny: boolean | null) {
        this.synchronizedBreakTrny = synchronizedBreakTrny;
    }
    getRebuyEndMode(): number {
        return this.rebuyEndMode;
    }

    setRebuyEndMode(rebuyEndMode: number) {
        this.rebuyEndMode = rebuyEndMode;
    }
    getRebuyEndValue(): number {
        return this.rebuyEndValue;
    }

    setRebuyEndValue(rebuyEndValue: number) {
        this.rebuyEndValue = rebuyEndValue;
    }
}
