import { Message } from '../../message';

export class PostStayIn extends Message {
    private MESSAGE_NAME: string = 'PostStayIn';
    private amount: number = 0;
    private timeOutAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, amount: number = 0, timeOutAt: number = 0) {
        super(reqServerPeerId);
        this.amount = amount;
        this.timeOutAt = timeOutAt;
    }

    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getTimeOutAt(): number {
        return this.timeOutAt;
    }

    setTimeOutAt(timeOutAt: number) {
        this.timeOutAt = timeOutAt;
    }
}
