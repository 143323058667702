import { Message } from '../../message';

export class LSTourneyRoundInfo extends Message {
    private MESSAGE_NAME: string = 'LSTourneyRoundInfo';
    private nextRoundBeginsAt: number = 0;
    private currentRound: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, nextRoundBeginsAt: number = 0, currentRound: number = 0) {
        super(reqServerPeerId);
        this.nextRoundBeginsAt = nextRoundBeginsAt;
        this.currentRound = currentRound;
    }

    getNextRoundBeginsAt(): number {
        return this.nextRoundBeginsAt;
    }

    setNextRoundBeginsAt(nextRoundBeginsAt: number) {
        this.nextRoundBeginsAt = nextRoundBeginsAt;
    }
    getCurrentRound(): number {
        return this.currentRound;
    }

    setCurrentRound(currentRound: number) {
        this.currentRound = currentRound;
    }
}
