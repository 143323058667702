import { Routes } from '@angular/router';

import { PokerPromotionsResolver } from './poker-promotions.resolver';

export const pokerPromotionsHomeRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPromotionsResolver,
        },
    },
];
