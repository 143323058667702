import { Message } from '../../message';
import { FXConversionFactor } from '../../pgSharedMessageFactory/messages/FXConversionFactor';

export class PoolRebuyResponse extends Message {
    private MESSAGE_NAME: string = 'PoolRebuyResponse';
    private userBalance: number = 0;
    private messageToClient: any | null = null;
    private fXConversionFactor: FXConversionFactor | null = null;
    private rebuyStatus: number = 0;
    private rebuyAmount: number = 0;
    private entryId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        userBalance: number = 0,
        messageToClient: any | null = null,
        fXConversionFactor: FXConversionFactor | null = null,
        rebuyStatus: number = 0,
        rebuyAmount: number = 0,
        entryId: number = 0,
    ) {
        super(reqServerPeerId);
        this.userBalance = userBalance;
        this.messageToClient = messageToClient;
        this.fXConversionFactor = fXConversionFactor;
        this.rebuyStatus = rebuyStatus;
        this.rebuyAmount = rebuyAmount;
        this.entryId = entryId;
    }

    getUserBalance(): number {
        return this.userBalance;
    }

    setUserBalance(userBalance: number) {
        this.userBalance = userBalance;
    }
    getMessageToClient(): any | null {
        return this.messageToClient;
    }

    setMessageToClient(messageToClient: any | null) {
        this.messageToClient = messageToClient;
    }
    getFXConversionFactor(): FXConversionFactor | null {
        return this.fXConversionFactor;
    }

    setFXConversionFactor(fXConversionFactor: FXConversionFactor | null) {
        this.fXConversionFactor = fXConversionFactor;
    }
    getRebuyStatus(): number {
        return this.rebuyStatus;
    }

    setRebuyStatus(rebuyStatus: number) {
        this.rebuyStatus = rebuyStatus;
    }
    getRebuyAmount(): number {
        return this.rebuyAmount;
    }

    setRebuyAmount(rebuyAmount: number) {
        this.rebuyAmount = rebuyAmount;
    }
    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
}
