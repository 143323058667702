import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { DeviceService, NavigationService, Page } from '@frontend/vanilla/core';
import {
    NguCarousel,
    NguCarouselConfig,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,
} from '@ngu/carousel';
import { Custom } from '@ngu/carousel/lib/ngu-carousel/ngu-carousel';
import { HomepageResourceService, NavigationClientConfig } from '@pokercore/module/core';

@Component({
    selector: 'homepage-promo',
    templateUrl: 'homepage-promo.component.html',
    standalone: true,
    imports: [CommonModule, NguCarousel, NguCarouselNextDirective, NguCarouselPrevDirective, NguCarouselDefDirective, NguItemComponent],
})
export class HomepagePromoComponent implements OnInit {
    lang: string;
    offersData: any;
    public carouselConfig: NguCarouselConfig;
    isTouch: boolean;
    navigationItems: any;
    showOffersArrowBtns: boolean = false;
    validRelatedPromos: Array<Custom> = [];
    initData: any;

    constructor(
        private page: Page,
        private homePageService: HomepageResourceService,
        private deviceService: DeviceService,
        private navigationConfig: NavigationClientConfig,
        private navigation: NavigationService,
    ) {
        this.carouselConfig = {
            grid: { xs: 2, sm: 2, md: 3, lg: 3, all: 0 },
            slide: 2,
            speed: 400,
            point: {
                visible: false,
            },
            loop: false,
            touch: false,
        };
    }

    ngOnInit() {
        this.isTouch = this.deviceService?.isTouch;
        this.offersData = this.homePageService?.dynamicContent?.viewModel?.offers;
        this.initData = this.offersData?.relatedPromos;
        this.initData?.forEach((item: any) => {
            if (item.pokerPromotionsSitecoreInfo) {
                this.validRelatedPromos.push(item);
                this.showOffersArrowBtns = true;
            }
        });
    }

    getPromoLink(promoName: string) {
        this.lang = this.page.lang;
        if (promoName != null) {
            const tokens = promoName.split('/').slice(2);
            promoName = tokens.join('/');
        }
        return `${this.lang}/${promoName}`;
    }

    seeAll() {
        this.navigationItems = this.navigationConfig;

        const promotion = this.navigationItems.filter(function (navigationitem: any) {
            return navigationitem.name.toLowerCase() === 'promotions';
        });
        this.navigation.goTo(promotion[0].url);
    }
}
