import { Message } from '../../message';

export class ChallengeHistoryResponse extends Message {
    private MESSAGE_NAME: string = 'ChallengeHistoryResponse';
    private responseCode: number = 0;
    private challengeHistoryDataList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseCode: number = 0, challengeHistoryDataList: any[] | null = null) {
        super(reqServerPeerId);
        this.responseCode = responseCode;
        this.challengeHistoryDataList = challengeHistoryDataList;
    }

    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getChallengeHistoryDataList(): any[] | null {
        return this.challengeHistoryDataList;
    }

    setChallengeHistoryDataList(challengeHistoryDataList: any[] | null) {
        this.challengeHistoryDataList = challengeHistoryDataList;
    }
}
