import { Message } from '../../message';
import { Chat } from './Chat';

export class DealMakingChat extends Message {
    private MESSAGE_NAME: string = 'DealMakingChat';
    private chat: Chat | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, chat: Chat | null = null) {
        super(reqServerPeerId);
        this.chat = chat;
    }

    getChat(): Chat | null {
        return this.chat;
    }

    setChat(chat: Chat | null) {
        this.chat = chat;
    }
}
