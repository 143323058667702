import { Message } from '../../message';

export class PostBlind extends Message {
    private MESSAGE_NAME: string = 'PostBlind';
    private blindType: number = 0;
    private amount: number = 0;
    private deadAmt: number = 0;
    private timeOutAt: number = 0;
    private showHeadsUpWarning: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        blindType: number = 0,
        amount: number = 0,
        deadAmt: number = 0,
        timeOutAt: number = 0,
        showHeadsUpWarning: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.blindType = blindType;
        this.amount = amount;
        this.deadAmt = deadAmt;
        this.timeOutAt = timeOutAt;
        this.showHeadsUpWarning = showHeadsUpWarning;
    }

    getBlindType(): number {
        return this.blindType;
    }

    setBlindType(blindType: number) {
        this.blindType = blindType;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getDeadAmt(): number {
        return this.deadAmt;
    }

    setDeadAmt(deadAmt: number) {
        this.deadAmt = deadAmt;
    }
    getTimeOutAt(): number {
        return this.timeOutAt;
    }

    setTimeOutAt(timeOutAt: number) {
        this.timeOutAt = timeOutAt;
    }
    getShowHeadsUpWarning(): boolean | null {
        return this.showHeadsUpWarning;
    }

    setShowHeadsUpWarning(showHeadsUpWarning: boolean | null) {
        this.showHeadsUpWarning = showHeadsUpWarning;
    }
}
