import { Message } from '../../message';

export class AutoOptionToOptionsMapping extends Message {
    private MESSAGE_NAME: string = 'AutoOptionToOptionsMapping';
    private autoOptionId: number = 0;
    private options: any[] | null = null;
    private checkAmount: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, autoOptionId: number = 0, options: any[] | null = null, checkAmount: boolean | null = false) {
        super(reqServerPeerId);
        this.autoOptionId = autoOptionId;
        this.options = options;
        this.checkAmount = checkAmount;
    }

    getAutoOptionId(): number {
        return this.autoOptionId;
    }

    setAutoOptionId(autoOptionId: number) {
        this.autoOptionId = autoOptionId;
    }
    getOptions(): any[] | null {
        return this.options;
    }

    setOptions(options: any[] | null) {
        this.options = options;
    }
    getCheckAmount(): boolean | null {
        return this.checkAmount;
    }

    setCheckAmount(checkAmount: boolean | null) {
        this.checkAmount = checkAmount;
    }
}
