<pwc-lib-game-canvas></pwc-lib-game-canvas>

<pwc-expospin-animation id="exponetialAnimation0" #expoAnimation1></pwc-expospin-animation>
<pwc-expospin-animation id="exponetialAnimation1" #expoAnimation2></pwc-expospin-animation>
<pwc-expospin-animation id="exponetialAnimation2" #expoAnimation3></pwc-expospin-animation>
<pwc-expospin-animation id="exponetialAnimation3" #expoAnimation4></pwc-expospin-animation>
<pwc-cash-ff-buyin
    *ngIf="isAutoreBuyin"
    (close)="closePopup()"
    [entryId]="entry"
    [table]="selectedTable"
    [selectedgameType]="gameType"
    [playerbalance]="playerbalance"
    [isAutorebuyin]="isAutoreBuyin"></pwc-cash-ff-buyin>
