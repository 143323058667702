import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import * as r from './blog.resolvers';
import { BlogService } from './blog.service';

@Component({
    selector: 'pt-blog',
    templateUrl: 'blog.component.html',
    standalone: true,
    imports: [CommonModule],
    providers: [BlogService, r.BlogResolver],
})
export class BlogComponent implements OnInit {
    initData: Array<any>;
    stringData: string;

    constructor(private blogService: BlogService) {}

    getFormattedDate(dateString: string) {
        return new Date(dateString).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    }

    ngOnInit() {
        this.blogService.getFeedData().subscribe((response: any) => {
            const d = response.items.slice(0, 4);
            this.initData = d;
            //console.log(d);
        });
    }
}
