import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Page, TrackingService } from '@frontend/vanilla/core';
import { HomepageResourceService } from '@pokercore/module/core';

@Component({
    selector: 'homepage-promosection',
    templateUrl: 'homepage-promosection.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class HomepagePromoSectionComponent implements OnInit {
    lang: string;
    offersData: any;
    constructor(
        private page: Page,
        private homePageService: HomepageResourceService,
        private tracking: TrackingService,
    ) {}

    ngOnInit() {
        this.offersData = this.homePageService.dynamicContent.viewModel.offers;
    }

    getPromoLink(promoName: string) {
        this.lang = this.page.lang;
        if (promoName != null) {
            const tokens = promoName.split('/').slice(2);
            promoName = tokens.join('/');
        }
        return `${this.lang}/${promoName}`;
    }

    promoTilesClick(event: any, teaser: any, bannerPos: any) {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Banners',
            'component.LabelEvent': 'Poker Homepage_' + teaser.pokerPromotionsSitecoreInfo.title,
            'component.ActionEvent': 'Banner - Click',
            'component.LocationEvent': 'Promo Tiles',
            'component.PositionEvent': 'Teaser_' + (+bannerPos + 1),
            'component.EventDetails': 'Promo Name|' + teaser.pokerPromotionsSitecoreInfo.title,
            'component.URLClicked': this.offersData.pokerPromotionsSitecoreInfo.promoListEx[bannerPos].path,
        });
    }
}
