import { Message } from '../../message';

export class DeletePrivateTableResponse extends Message {
    private MESSAGE_NAME: string = 'DeletePrivateTableResponse';
    private tableDeleted: boolean | null = false;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableDeleted: boolean | null = false, tableId: number = 0) {
        super(reqServerPeerId);
        this.tableDeleted = tableDeleted;
        this.tableId = tableId;
    }

    getTableDeleted(): boolean | null {
        return this.tableDeleted;
    }

    setTableDeleted(tableDeleted: boolean | null) {
        this.tableDeleted = tableDeleted;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
