import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnChanges, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { ParseRibbonMarkupPipe } from './parse-ribbon-markup.pipe';

@Component({
    selector: 'pk-multi-promo-timer',
    templateUrl: 'pk-multi-promo-timer.component.html',
    standalone: true,
    imports: [CommonModule, TrustAsHtmlPipe, RouterModule],
})
export class PkMultiPromoTimerComponent implements OnChanges {
    @Input() multiPromoTimerRibbonData: any;
    @Input() multiPromoTimerStyles: any;
    @Output() timerStatus = new EventEmitter<boolean>();
    startTime: any;
    endTime: any;
    currentTimeInUTC: any;
    currentTime: any;
    hours: number;
    minutes: number;
    seconds: number;
    millisecondsleft: number;
    regEndMilliSecondsLeft: number;
    timeLeft: string;
    callback: any;
    registrationInfo: any;
    bannerDisplayTime: any;
    bannerKickoffInHours: number;
    data: any;
    isBannerEnabled: boolean = false;
    isRegistrationStarted: boolean = false;
    isRegistrationClosed: boolean = false;
    registrationStartText: string;
    registrationEndText: string;
    registrationClosedText: string;
    beforeRegistrationCTALink: string;
    beforeRegistrationCTAText: string;
    duringRegistrationCTALink: string;
    duringRegistrationCTAText: string;
    discountBulletin: string;
    item: any;
    registrationTimeDetails: any;
    htmlText: any;
    htmlTextUpdated: any;
    replacements: any = {};
    ribbonStatusClass: string;
    htmlStyles: any;

    constructor(
        public zone: NgZone,
        private parseRibbonMarkupPipe: ParseRibbonMarkupPipe,
    ) {}

    ngOnChanges() {
        this.item = this.multiPromoTimerRibbonData?.parameters;

        if (this.item?.Details != undefined && this.item?.Details != null) {
            this.registrationTimeDetails = JSON.parse(this.item?.Details);
        }

        this.registrationStartText = this.item?.RegistrationStartText;
        this.registrationEndText = this.item?.RegistrationEndText;
        this.bannerKickoffInHours = this.item?.BannerKickoffInHours;
        this.beforeRegistrationCTALink = this.item?.BeforeRegistrationCTALink;
        this.beforeRegistrationCTAText = this.item?.BeforeRegistrationCTAText;
        this.duringRegistrationCTALink = this.item?.DuringRegistrationCTALink;
        this.duringRegistrationCTAText = this.item?.DuringRegistrationCTAText;
        this.discountBulletin = this.item?.DiscountBulletin;
        this.registrationClosedText = this.item?.RegistrationClosedText;
        this.htmlText = this.multiPromoTimerRibbonData?.text;
        this.htmlStyles = this.multiPromoTimerStyles?.text;

        this.refresh();
        this.callback = setInterval(() => {
            this.refresh();
        }, 1000);
    }

    private refresh() {
        this.registrationInfo = this.registrationTimeDetails?.registrationDetails;
        if (this.registrationInfo != null && this.registrationInfo.length > 0) {
            for (let r = 0; r < this.registrationInfo.length; r++) {
                this.currentTimeInUTC = new Date().toUTCString();
                this.currentTime = new Date(this.currentTimeInUTC);

                this.startTime = new Date(this.registrationInfo[r].registrationStartTime);
                this.endTime = new Date(this.registrationInfo[r].registrationEndTime);

                this.bannerDisplayTime = new Date(this.startTime);
                this.bannerDisplayTime.setHours(this.bannerDisplayTime.getHours() - this.bannerKickoffInHours);

                this.millisecondsleft = this.startTime - this.currentTime;
                this.regEndMilliSecondsLeft = this.endTime - this.currentTime;

                if (this.currentTime > this.bannerDisplayTime && this.millisecondsleft > 0) {
                    this.isRegistrationClosed = false;
                    this.isBannerEnabled = true;
                    this.isRegistrationStarted = false;
                    this.ribbonStatusClass = 'banner-enabled';
                    this.hours = Math.floor(this.millisecondsleft / 3600000);
                    this.minutes = Math.floor((this.millisecondsleft - this.hours * 3600000) / 60000);
                    this.seconds = Math.floor((this.millisecondsleft - this.hours * 3600000 - this.minutes * 60000) / 1000);
                    this.formatMessage(this.hours, this.minutes, this.seconds);
                    this.timerStatus.emit(true);
                    break;
                }
                if (this.currentTime > this.startTime && this.regEndMilliSecondsLeft > 0) {
                    this.isRegistrationClosed = false;
                    this.isBannerEnabled = false;
                    this.isRegistrationStarted = true;
                    this.ribbonStatusClass = 'registration-started';
                    this.hours = Math.floor(this.regEndMilliSecondsLeft / 3600000);
                    this.minutes = Math.floor((this.regEndMilliSecondsLeft - this.hours * 3600000) / 60000);
                    this.seconds = Math.floor((this.regEndMilliSecondsLeft - this.hours * 3600000 - this.minutes * 60000) / 1000);
                    this.formatMessage(this.hours, this.minutes, this.seconds);
                    this.timerStatus.emit(true);
                    break;
                } else {
                    this.timeLeft = '';
                    this.isBannerEnabled = false;
                    this.isRegistrationStarted = false;
                    this.isRegistrationClosed = true;
                    this.timerStatus.emit(false);
                }
            }
        }
    }

    private formatMessage(hours: number, minutes: number, seconds: number) {
        this.replacements['HOURS'] = hours;
        this.replacements['MINUTES'] = minutes;
        this.replacements['SECONDS'] = seconds;
        this.replacements['PROMOFLASHMESSAGE'] = this.item.promoFlashMessage;

        this.htmlTextUpdated = this.parseRibbonMarkupPipe.transform(this.htmlText, this.replacements);
    }

    ngOnDestroy() {
        clearInterval(this.callback);
    }
}
