import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ClientConfigProductName, ContentService, NavigationService, Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { SimulatedHandsComponent } from '@pokercore/module';
import { NavigationBootstrapService } from '@pokercore/module/core';

@Component({
    selector: 'pwc-simulated-hands-client',
    templateUrl: 'simulated-hands-client.component.html',
    standalone: true,
    imports: [CommonModule, SimulatedHandsComponent],
})
export class SimulatedHandsClientComponent extends PCComponent implements OnInit {
    content: any;
    constructor(
        private contentService: ContentService,
        private navigation: NavigationService,
        private navigationBootstrap: NavigationBootstrapService,
        private page: Page,
    ) {
        super();
    }

    ngOnInit() {
        this.contentService
            .getJson('MobilePoker-v1.0/PublicPages/tutorial', { product: ClientConfigProductName.POKER, filterOnClient: true })
            .subscribe((response) => {
                const data = response?.content[0]?.items[0];
                if (data) {
                    this.content = data;
                }
            });
    }

    closeSimHands() {
        const previousUrl = this.navigationBootstrap.getPreviousUrl();

        if (previousUrl) {
            this.navigation.goTo(previousUrl);
        } else {
            const lobbyUrl = this.page.homePage;
            window.open(`${lobbyUrl}/play`, '_self');
        }
    }
}
