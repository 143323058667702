<div class="tmt-heading-mobile">
    <div class="heading-info">{{ pmPokerNavigationView.messages['text-filter-by'] }}</div>
    <div class="theme-ex" (click)="closeFilters()"></div>
</div>
<form>
    <div class="filter-sec">
        <div class="filter-info-sec">
            <div class="filter-heading" checked="visibleGame" (click)="visibleGameFunc()">
                {{ pmPokerNavigationView.messages['text-game-types'] }}<span [ngClass]="visibleGame ? 'theme-up' : 'theme-down'"></span>
                <div class="filter-sub-info" *ngIf="GameTypeValue">
                    {{ GameTypeValue | gameNamePipe }} {{ pmPokerNavigationView.messages['text-selected'] }}
                </div>
                <div class="filter-sub-info" *ngIf="!GameTypeValue">
                    {{ pmPokerNavigationView.messages['combo-game-000'] }} {{ pmPokerNavigationView.messages['text-selected'] }}
                </div>
            </div>
            <div *ngIf="visibleGame" [@slideInOut]>
                <div class="filters" *ngFor="let tournament of gameTypeDetails; let tournamentIndex = index">
                    <div class="clear-fix heading-info">
                        <label class="form-check-label" for="hide-finished">{{ tournament['text'] }}</label>
                        <input type="radio" [checked]="tournament.checked" (click)="filterTournamentListByGame(tournament.value)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-info-sec buy-in-filter">
            <div class="filter-heading" checked="visibleBuyIn" (click)="visibleBuyInFunc()">
                {{ pmPokerNavigationView.messages['text-buy-ins'] }}<span [ngClass]="visibleBuyIn ? 'theme-up' : 'theme-down'"></span>
                <div class="filter-sub-info">
                    <a *ngIf="buyInMin === buyInMax; then EqualContent; else NotEqualContent"></a>
                    <ng-template #EqualContent>
                        <a *ngIf="buyInMin < BuyInSteps; then thenContent; else elseContent"></a>
                        <ng-template #thenContent>
                            <span>&lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }} {{ pmPokerNavigationView.messages['text-selected'] }}</span>
                        </ng-template>
                        <ng-template #elseContent>
                            <a *ngIf="buyInMax > BuyInMax; then thenBlock; else elseBlock"></a>
                            <ng-template #thenBlock>
                                <span
                                    >&gt;{{ BuyInMax | currency: monetaryType : 'symbol' : '1.0' }}
                                    {{ pmPokerNavigationView.messages['text-selected'] }}</span
                                >
                            </ng-template>
                            <ng-template #elseBlock>
                                <span
                                    >{{ this.buyInMin | currency: monetaryType : 'symbol' : '1.0' }}
                                    {{ pmPokerNavigationView.messages['text-selected'] }}</span
                                >
                            </ng-template>
                        </ng-template>
                    </ng-template>
                    <ng-template #NotEqualContent>
                        <a *ngIf="buyInMax > BuyInMax; then content; else elseContent"></a>
                        <ng-template #content>
                            <a *ngIf="buyInMin < BuyInSteps; then ThenBlock; else ElseBlock"></a>
                            <ng-template #ThenBlock>
                                <span
                                    >&lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }} - &gt;{{
                                        BuyInMax | currency: monetaryType : 'symbol' : '1.0'
                                    }}
                                    {{ pmPokerNavigationView.messages['text-selected'] }}</span
                                >
                            </ng-template>
                            <ng-template #ElseBlock>
                                <a *ngIf="buyInMin > BuyInMax; then newThenBlock; else newElseBlock"></a>
                                <ng-template #newThenBlock>
                                    <span
                                        >&gt;{{ BuyInMax | currency: monetaryType : 'symbol' : '1.0' }} - &gt;{{
                                            BuyInMax | currency: monetaryType : 'symbol' : '1.0'
                                        }}
                                        {{ pmPokerNavigationView.messages['text-selected'] }}
                                    </span>
                                </ng-template>
                                <ng-template #newElseBlock>
                                    <span
                                        >{{ this.buyInMin | currency: monetaryType : 'symbol' : '1.0' }} - &gt;{{
                                            BuyInMax | currency: monetaryType : 'symbol' : '1.0'
                                        }}
                                        {{ pmPokerNavigationView.messages['text-selected'] }}
                                    </span>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                        <ng-template #elseContent>
                            <a *ngIf="buyInMin < BuyInSteps; then ThenBlock; else ElseBlock"></a>
                            <ng-template #ThenBlock>
                                <a *ngIf="buyInMax < BuyInSteps; then newThenBlock; else newElseBlock"></a>
                                <ng-template #newThenBlock>
                                    <span
                                        >&lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }} - &lt;{{
                                            1 | currency: monetaryType : 'symbol' : '1.0'
                                        }}
                                        {{ pmPokerNavigationView.messages['text-selected'] }}
                                    </span>
                                </ng-template>
                                <ng-template #newElseBlock>
                                    <span
                                        >&lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }} -
                                        {{ this.buyInMax | currency: monetaryType : 'symbol' : '1.0' }}
                                        {{ pmPokerNavigationView.messages['text-selected'] }}
                                    </span>
                                </ng-template>
                            </ng-template>
                            <ng-template #ElseBlock>
                                <span
                                    >{{ this.buyInMin | currency: monetaryType : 'symbol' : '1.0' }} -
                                    {{ this.buyInMax | currency: monetaryType : 'symbol' : '1.0' }}
                                    {{ pmPokerNavigationView.messages['text-selected'] }}
                                </span>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <div class="data-info" *ngIf="visibleBuyIn" [@slideInOut]>
                <a *ngIf="buyInMin === buyInMax; then EqualContent; else NotEqualContent"></a>
                <ng-template #EqualContent>
                    <a *ngIf="buyInMin < BuyInSteps; then thenContent; else elseContent"></a>
                    <ng-template #thenContent>
                        <span>&lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }}</span>
                    </ng-template>
                    <ng-template #elseContent>
                        <a *ngIf="buyInMax > BuyInMax; then thenBlock; else elseBlock"></a>
                        <ng-template #thenBlock>
                            <span>&gt;{{ BuyInMax | currency: monetaryType : 'symbol' : '1.0' }}</span>
                        </ng-template>
                        <ng-template #elseBlock>
                            <span>{{ this.buyInMin | currency: monetaryType : 'symbol' : '1.0' }}</span>
                        </ng-template>
                    </ng-template>
                </ng-template>
                <ng-template #NotEqualContent>
                    <a *ngIf="buyInMax > BuyInMax; then content; else elseContent"></a>
                    <ng-template #content>
                        <a *ngIf="buyInMin < BuyInSteps; then ThenBlock; else ElseBlock"></a>
                        <ng-template #ThenBlock>
                            <span
                                >{{ pmPokerNavigationView.messages['text-from'] }} &lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }}
                                {{ pmPokerNavigationView.messages['text-to'] }} &gt;{{ BuyInMax | currency: monetaryType : 'symbol' : '1.0' }}</span
                            >
                        </ng-template>
                        <ng-template #ElseBlock>
                            <a *ngIf="buyInMin > BuyInMax; then newThenBlock; else newElseBlock"></a>
                            <ng-template #newThenBlock>
                                <span
                                    >{{ pmPokerNavigationView.messages['text-from'] }} &gt;{{ BuyInMax | currency: monetaryType : 'symbol' : '1.0' }}
                                    {{ pmPokerNavigationView.messages['text-to'] }} &gt;{{
                                        BuyInMax | currency: monetaryType : 'symbol' : '1.0'
                                    }}</span
                                >
                            </ng-template>
                            <ng-template #newElseBlock>
                                <span
                                    >{{ pmPokerNavigationView.messages['text-from'] }}
                                    {{ this.buyInMin | currency: monetaryType : 'symbol' : '1.0' }}
                                    {{ pmPokerNavigationView.messages['text-to'] }} &gt;{{
                                        BuyInMax | currency: monetaryType : 'symbol' : '1.0'
                                    }}</span
                                >
                            </ng-template>
                        </ng-template>
                    </ng-template>
                    <ng-template #elseContent>
                        <a *ngIf="buyInMin < BuyInSteps; then ThenBlock; else ElseBlock"></a>
                        <ng-template #ThenBlock>
                            <a *ngIf="buyInMax < BuyInSteps; then newThenBlock; else newElseBlock"></a>
                            <ng-template #newThenBlock>
                                <span
                                    >{{ pmPokerNavigationView.messages['text-from'] }} &lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0'
                                    }}{{ pmPokerNavigationView.messages['text-to'] }} &lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }}</span
                                >
                            </ng-template>
                            <ng-template #newElseBlock>
                                <span
                                    >{{ pmPokerNavigationView.messages['text-from'] }} &lt;{{ 1 | currency: monetaryType : 'symbol' : '1.0' }}
                                    {{ pmPokerNavigationView.messages['text-to'] }}
                                    {{ this.buyInMax | currency: monetaryType : 'symbol' : '1.0' }}</span
                                >
                            </ng-template>
                        </ng-template>
                        <ng-template #ElseBlock>
                            <span
                                >{{ pmPokerNavigationView.messages['text-from'] }} {{ this.buyInMin | currency: monetaryType : 'symbol' : '1.0' }}
                                {{ pmPokerNavigationView.messages['text-to'] }} {{ this.buyInMax | currency: monetaryType : 'symbol' : '1.0' }}</span
                            >
                        </ng-template>
                    </ng-template>
                </ng-template>

                <ngx-slider
                    [(value)]="buyInMin"
                    [(highValue)]="buyInMax"
                    [options]="options"
                    (valueChange)="sliderChange()"
                    (highValueChange)="sliderChange()"></ngx-slider>
            </div>
        </div>
    </div>
    <div class="filter-sec filter-btn-sec">
        <div class="btns-sec">
            <div class="btn-info">
                <button class="btn btn-secondary" [disabled]="!EnableAndDisable" (click)="resetFilters()">
                    {{ pmPokerNavigationView.messages['text-reset-filters'] }}
                </button>
            </div>
            <div class="btn-info">
                <button class="btn btn-primary" (click)="submitFilters()">{{ pmPokerNavigationView.messages['text-submit'] }}</button>
            </div>
        </div>
    </div>
</form>
