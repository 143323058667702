import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DeviceService, HeaderService } from '@frontend/vanilla/core';
import { PokerApiService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PokerDownloadResolver implements Resolve<any> {
    screenSize: number;
    constructor(
        private api: PokerApiService,
        private deviceService: DeviceService,
        private headerService: HeaderService,
    ) {}

    resolve(): Observable<any> {
        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('poker');
        });
        if (this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet) {
            return this.api.get('download/GetAutomaticAppDownloadUrl', { devicePixelRatio: devicePixelRatio });
        }
        return this.api.get('download/GetAutomaticDownloadUrl');
    }
}
