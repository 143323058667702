<div class="mobile-only-menu">
    <pk-navigation-bar *ngIf="showConfigureNavigation"></pk-navigation-bar>
    <pk-navigation-bar-poker *ngIf="!showConfigureNavigation"></pk-navigation-bar-poker>
</div>

<lh-navigation-layout-page sourceItem="handhistory" class="portal-wrapper-fw" *ngIf="!isV2AccountMenu">
    <ng-container *ngTemplateOutlet="MyHHView"></ng-container>
</lh-navigation-layout-page>

<div class="portal-wrapper-fw" *ngIf="isV2AccountMenu">
    <ng-container>
        <ng-container *ngTemplateOutlet="MyHHView"></ng-container>
    </ng-container>
</div>

<ng-template #MyHHView>
    <vn-message-panel></vn-message-panel>
    <div class="hand-history-mainbody" [ngClass]="deviceType" *ngIf="serve.content" [class.hh]="!serve.isLego">
        <div class="hands-history">
            <pk-hand-filters
                class="pk-hand-filters"
                (filterHangup)="postApplyFilters($event)"
                (searchByFilterId)="searchByRoundId($event)"
                (updateDate)="updateDateContent($event)"
                (updateFltrBtn)="updateFilterBtn($event)"
                [ngClass]="showFilters === true ? (windowWidth >= 1366 ? 'hidefilters' : 'showfilters') : 'hidefilters'">
            </pk-hand-filters>
            <pk-hands-mainbody
                #pkHandsMainbody
                class="pk-hands-mainbody"
                (roundIdError)="postApplyFilters($event)"
                [ngClass]="showFilters === true ? (windowWidth >= 1366 ? 'hidefilters' : 'showfilters') : 'hidefilters'">
            </pk-hands-mainbody>
        </div>
        <button class="filter_btn apply-filters" [class.hanged-up]="filterHangup" (click)="toggleFilters()">
            <i class="icon-filter-variant" *ngIf="filterBtnTxt === serve.content.messages.Filters"></i> {{ filterBtnTxt }}
        </button>
        <button class="apply-filters" [class.hanged-up]="filterHangup" (click)="toggleFilters()">
            <i class="icon-filter-variant" *ngIf="toogleFilterBtn === serve.content.messages.Filters"></i> {{ toogleFilterBtn }}
        </button>
    </div>
</ng-template>
<scroll-to-top *ngIf="disableHtmlHeadTag"></scroll-to-top>
