import { Message } from '../../message';

export class UnReserveSeat extends Message {
    private MESSAGE_NAME: string = 'UnReserveSeat';
    private seatNumber: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatNumber: number = 0) {
        super(reqServerPeerId);
        this.seatNumber = seatNumber;
    }

    getSeatNumber(): number {
        return this.seatNumber;
    }

    setSeatNumber(seatNumber: number) {
        this.seatNumber = seatNumber;
    }
}
