import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { PCComponent, PCComponentHeaderV2Component } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-twitter-feed',
    templateUrl: 'poker-twitter-feed.component.html',
    imports: [PCComponentHeaderV2Component, CommonModule],
    standalone: true,
})
export class PokerTwitterFeedComponent extends PCComponent {
    pokerTwitterFeed: any;
    @HostBinding()
    get class() {
        this.pokerTwitterFeed = this.item.tweetData.items;
        return this.createClass(this.item.name);
    }

    getTweetUrl(tweetText: string) {
        return tweetText.lastIndexOf('https') > -1 ? tweetText.substring(tweetText.lastIndexOf('https')) : null;
    }
    getUserUrl(twitterLink: string) {
        // return  (tweetText.lastIndexOf( "https") > -1)?tweetText.substring(tweetText.lastIndexOf( "https")):null;
        return `https://twitter.com/${twitterLink}`;
    }
}
