import { controlPanelConfig } from '../game-config/controlPanelConfig';
import { fontProperties } from '../game-config/fontproperties';

export const uiConfig = {
    deviceType: 2,
    isIOSMobile: false,
    gameType: 'NLH',
    selfUserId: 0,
    currentSelfUserSeatInd: null,
    windowDimension: {
        width: window.parent.innerWidth,
        height: window.parent.innerHeight,
    },
    controlPanel: {
        config: controlPanelConfig,
    },
    font: {
        props: fontProperties.fontProps,
    },
    windowWidth: 720,
    windowHeight: 1280,
    isWindowVisible: true,
    disableImpetusScroll: true,
    hasSwipe: false,
    tableCoinText: {
        x: 62,
        y: 63,
    },
    winTableViewFullScaleView: null,
    selectedOptionSet: {
        1: 'FOLD',
        2: 'CALL',
        3: 'CHECK',
        4: 'BET',
        5: 'RAISE',
        7: 'RAISE',
        9: 'BET',
        10: 'RAISE',
        11: 'BET',
        18: '',
    },
    selectedRaiseButtonSet: {
        2: 'twoBB',
        2.1: 'twooneBB',
        2.2: 'twotwoBB',
        2.5: 'twofiveBB',
        3: 'threeBB',
        4: 'fourBB',
        0.25: 'oneFourPot',
        0.3333333333333333: 'oneThreePot',
        0.5: 'oneTwoPot',
        0.6666666666666666: 'twoThreePot',
        0.75: 'threeFourPot',
        0.8: 'fourFive',
        1: 'onePot',
        AllIN: 'allIn',
        POT: 'potBB',
    },
    selectedAnteButtonSet: {
        2: 'twoA',
        2.1: 'twoOneA',
        2.2: 'twoTwoA',
        2.5: 'twoFiveA',
        3: 'threeA',
        4: 'fourA',
        0.25: 'oneFourPot',
        0.3333333333333333: 'oneThreePot',
        0.5: 'oneTwoPot',
        0.6666666666666666: 'twoThreePot',
        0.75: 'threeFourPot',
        0.8: 'fourFive',
        1: 'onePot',
        AllIN: 'allIn',
        POT: 'potBB',
    },
    wheelWealthContainer: {
        responsivePosition: {
            landX: 128,
            landY: 40,
            landScaleX: 0.85,
            landScaleY: 0.85,
            portX: 100,
            portY: 300,
            portScaleX: 0.55,
            portScaleY: 0.55,
        },
        globalYScale: 0.45,
        container0: {
            y: 27,
            scaleY: 1,
        },
        container1: {
            y: 27,
            scaleY: 1,
        },
        container2: {
            y: 27,
            scaleY: 1,
        },
        container3: {
            y: 153,
            scaleY: 1,
        },
        container4: {
            y: 429,
            scaleY: 1,
        },
        container5: {
            y: 560,
            scaleY: 1,
        },
        container6: {
            y: 560,
            scaleY: 1,
        },
        container7: {
            y: 560,
            scaleY: 1,
        },
        container8: {
            y: 560,
            scaleY: 1,
        },
    },
    chipThousandPostfix: 'K',
    coinComponentUI: {
        anchorX: 0.5,
        anchorY: 0.5,
    },
    coinText: {
        desktop: {
            x: 0,
            y: 2,
        },
        mobile: {
            x: 0,
            y: 2,
        },
    },
    gameBG: {
        MainGameBG: 'gameBGLandscape',
        PageBG: false,
        BonusGameBG: 'maingamebg',
        FreeSpinBG: 'freespinbg',
        BonusGameCoverAlpha: 0.2,
        ambAniMaskSprite: [930, 195, 800, 435, 1136, 475, 1136, 195],
        bgWidth: 720,
        bgScaleFactorPortrait: 0.6,
    },
    game: {
        audioSprite: false,
        portrait: {
            gameWidth: 720,
            gameHeight: 1280,
            //defaultwidth : 800,
            panelWidth: 736,
            windowWidth: 720,
            windowHeight: 1280,
            xAdjustValue: 96,
            panelTextScale: 1,
            popScale: 0.8,
            baseWidth: 720,
            baseHeight: 1280,
            screenMoveDuration: 0.3,
            availablePixelRatio: [1],
            gametitle: {
                x: 300,
                y: 15,
                scale: 0.6,
            },
        },
        baseLayout: {
            DT: {
                baseWidth: 720,
                baseHeight: 1280,
            },
            ML: {
                baseWidth: 720,
                baseHeight: 1280,
            },
            MP: {
                baseWidth: 720,
                baseHeight: 1280,
            },
        },
    },
    PaylinesUIData: {
        showBoxesAtGameTime: true,
        showLinesAtGameTime: false,
        showPaylineBoxes: {
            left: true,
            right: false,
            customBoxes: true,
            leftPrefix: 'up/Box (',
            leftPostfix: ')',
            leftOverPrefix: 'Over/Box (',
            leftOverPostfix: ')',
            rightPrefix: 'up/Box (',
            rightPostfix: ')',
            freeleftPrefix: 'paylineNumberBoxFreeSpin',
            freeleftPostfix: '',
            freeleftOverPrefix: 'Over/Box (',
            freeleftOverPostfix: ')',
        },
        position: {
            x: 0,
            y: 0,
        },
        NumberBox: {
            leftxPositions: [
                120, 86, 120, 86, 120, 86, 120, 86, 120, 86, 120, 86, 120, 86, 120, 86, 120, 86, 120, 86, 978, 1011, 978, 1011, 978, 1011, 978, 1011,
                978, 1011, 978, 1011, 978, 1011, 978, 1011, 978, 1011, 978, 1011,
            ],
            leftyPositions: [
                92, 110, 132, 150, 172, 190, 212, 230, 252, 270, 292, 310, 332, 350, 373, 392, 413, 432, 455, 474, 92, 110, 132, 150, 172, 190, 212,
                230, 252, 270, 292, 310, 330, 350, 373, 392, 413, 432, 454, 474,
            ],
        },
        Paylines: {
            xPositions: [82, 82, 82, 88, 90, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88, 88],
            yPositions: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            prefix: '',
            postfix: '',
        },
    },
    reelsUIData: {
        reelMaxSpinSpeed: 30,
        reelMinSpinSpeed: 30,
        //'reelSpinDuration': 100,
        reelAccelerationTime: 0.01,
        reelHeight: 100,
        reelSpinStopGap: 0.01,
        stopAfterResultGap: 0.01,
        reelSpinStartGap: 0.01,
        reelJerkGap: 0.01,
        stopAfterResultGapForReverse: 0.01,
        reelFirstJerkDist: 0.01,
        reelFirstJerkDistQS: 0.01,
        reelFirstJerkDuration: 0.01,
        reelFirstJerkDurationQS: 0.01,
        reelSpinStopGapQS: 0.01,
        reelEndJerkDist: 0.01,
        reelEndJerkDistQS: 0.01,
        initialMaxSpeed: 15,
        initialMinSpeed: 15,
        reelPositionIndex: [
            [0, 5, 10],
            [1, 6, 11],
            [2, 7, 12],
            [3, 8, 13],
            [4, 9, 14],
        ],
        reelStripRowLengths: [3, 3, 3, 3, 3, 3, 3],
        reelPositions: [
            {
                c: 1,
                r: 1,
            },
            {
                c: 2,
                r: 1,
            },
            {
                c: 3,
                r: 1,
            },
            {
                c: 4,
                r: 1,
            },
            {
                c: 5,
                r: 1,
            },
            {
                c: 6,
                r: 1,
            },
            {
                c: 7,
                r: 1,
            },
        ],
        defaultReel: [[0], [0], [0], [0], [0], [0], [0]],
        //'defaultReel': [[0,0,0,0,0], [0,0,0,0,0], [0,0,0,0,0], [0,0,0,0,0], [0,0,0,0,0] ],
        defaultReelStopPosition: [0, 0, 0, 0, 0, 0, 0],
        position: {
            x: 0,
            y: -45 /*'portrait':{x:0,y:20}*/,
        },
        portrait: {
            position: {
                x: 0,
                y: -45,
            },
        },
        numberOfReels: 7,
        numberOfRows: 1,
        reelToFrameGap: 0,
        reelPositionsX: [140, 212, 284, 356, 428, 500, 572],
        // 'reelMaskRect': [
        //     [98, 540, 174, 540, 174, 687, 98, 687],
        //     [178, 540, 248, 540, 248, 688, 178, 688],
        //     [248, 540, 318, 540, 318, 688, 248, 688],
        //     [319, 540, 389, 540, 389, 688, 319, 688],
        //     [390, 540, 460, 540, 460, 688, 390, 688],
        //     [461, 540, 531, 540, 531, 688, 461, 688],
        //     [532, 540, 602, 540, 602, 688, 532, 688]
        // ],
        reelMaskRect: [63, 574, 602, 574, 602, 723, 63, 723],
        reelPositionY: 784,
        symbolHeight: 97,
        symbolWidth: 52,
        symbolGap: 30,
        reelFrameBG: {
            x: 0,
            y: 50,
        },
        freespinReelIndex1: false,
        reelFrame: {
            x: 0,
            y: 50,
        },
    },
    RulesPaytableUI: {
        totalPagesDesktop: 3,
        totalPagesMobile: 3,
        bgPosition: {
            x: 45,
            y: 88,
        },
    },
    introScreen: {
        position: {
            x: 8,
            y: 0,
        },
        startBtn: {
            x: 399,
            y: 560,
        },
    },
    winViewData: {
        bigWinYPos: 175,
        megaWinYPos: 175,
        giganticWinYPos: 175,
        symbolAnimFrame: [
            {
                symb: '0',
                animationtype: 1,
                prefix: 'girl_000',
                postfix: '',
                start: 1,
                end: 35,
                animationSpeed: 0.18,
                doubleDigit: true,
            },
            //{symb: "0", animations : [{animProps : {scaleX : .95, scaleY: .95}, duration : .5}, {animProps : {scaleX : 1.05, scaleY: 1.05}, duration : .5}]},
            {
                symb: '1',
                animationtype: 1,
                prefix: 'boy_000',
                postfix: '',
                start: 0,
                end: 35,
                animationSpeed: 0.18,
                doubleDigit: true,
            },
        ],
        reelWidth: 812,
        reelInsideArea: {
            width: 790,
            height: 470,
            x: 170,
            y: 59,
        },
        frameX: 28,
        paylineColors: [
            '0xa16001',
            '0xc07171',
            '0x826783',
            '0xfebb7c',
            '0xfefd80',
            '0x61c3db',
            '0x03eded',
            '0xab3470',
            '0x40ce40',
            '0x76f9b7',
            '0x60bd2e',
            '0xf04fb9',
            '0x6f4ff5',
            '0x8fc4c4',
            '0xf25656',
            '0x268585',
            '0xa6cd49',
            '0xf87575',
            '0xe583e6',
            '0xdbd17c',
            '0x7687c7',
            '0x945901',
            '0xbf5aea',
            '0x4288ee',
            '0xfc9a0a',
            '0x5555d5',
            '0xef1d1d',
            '0xe23d3d',
            '0xde8221',
            '0x00b168',
        ],
        bigWinImageBG: {
            x: 261,
            y: 242,
        },
        reelStripHighlightAnim: {
            name: 'Plasma_Version_2_02_00',
            start: 59,
            end: 128,
        },
        symbolWinFrame: {},
        reelSymbolsAlpha: 0.6,
        scaleWinSymbol: 1.3,
    },
    SoundProps: {
        youwin: 'youwin',
        push: 'push',
        click: 'click',
        Card: 'Card',
        CardFlip: 'CardFlip',
        chips: 'chips',
        Bonus_win: 'Bonus_win',
        split: 'split',
        chipselection: 'chipselection',
        Alert: 'Alert',
        Bet: 'Bet',
        Call: 'Call',
        Check: 'Check',
        Fold: 'Fold',
        Hurry: 'Hurry',
        Raise: 'Raise',
        Win: 'Win',
        throwSound: 'throwSound',
        throwAni1: 'throwAni1',
        throwAni2: 'throwAni2',
        throwAni3: 'throwAni3',
        throwAni4: 'throwAni4',
        throwAni5: 'throwAni5',
        throwAni6: 'throwAni6',
        throwAni7: 'throwAni7',
        throwAni8: 'throwAni8',
        throwAni9: 'throwAni9',
        throwAni10: 'throwAni10',
        throwAni11: 'throwAni11',
        throwAni12: 'throwAni12',
        throwAni13: 'throwAni13',
        spinMachineLanding: 'spinMachineLanding',
        letsSpinSound: 'letsSpinSound',
        liverSound: 'liverSound',
        reelSpinSound: 'reelSpinSound',
        spinGlowSound: 'spinGlowSound',
        spinMachineRemove: 'spinMachineRemove',
        optionSetNotification: 'optionSetNotification',
        Moving_Chips: 'Moving_Chips',
        widget_flash: 'widget_flash',
        trigger_coinDrop: 'trigger_coinDrop',
        trigger_popup: 'trigger_popup',
        triggerPopupFadeAway: 'triggerPopupFadeAway',
        win_pulsing: 'win_pulsing',
        win_prize_reveal: 'win_prize_reveal',
        win_prize_moveUp: 'win_prize_moveUp',
        win_prize_animation: 'win_prize_animation',
        win_chips_hoovered: 'win_chips_hoovered',
        win_coins_moveToPlayer: 'win_coins_moveToPlayer',
        coinsAnimation: 'coinsAnimation',
        cashback_prize_reveal: 'cashback_prize_reveal',
        cashback_safe_open: 'cashback_safe_open',
        cashback_safe_unlock: 'cashback_safe_unlock',
        cashout_player_sounds: 'cashout_player_sounds',
        expo_MusicLoop_V1: 'expo_MusicLoop_V1',
        expo_CarLoop_V1: 'expo_CarLoop_V1',
        expo_InitialCountdown_V1: 'expo_InitialCountdown_V1',
        expo_FinalBlast_V1: 'expo_FinalBlast_V1',
        expo_Checkpoint_V1: 'expo_Checkpoint_V1',
        expo_CarStart_V1: 'expo_CarStart_V1',
        expo_MusicIntro_V1: 'expo_MusicIntro_V1',
    },
    fileNames: {
        Mobile: {
            timeBankOtherPlayer: {
                prefix: 'num_bgeffect00',
                postfix: '',
                start: 1,
                end: 67,
                animationSpeed: 0.8,
                doubleDigit: true,
            },
            timeBankSeqAni: {
                prefix: 'sandclock_loop00',
                postfix: '',
                start: 1,
                end: 90,
                animationSpeed: 1,
                doubleDigit: true,
            },
            timeBankAbouttoStartSeq: {
                prefix: 'sandclock00',
                postfix: '',
                start: 1,
                end: 56,
                animationSpeed: 1,
                doubleDigit: true,
            },
            leverHandleAnimation: {
                prefix: 'handle',
                postfix: '',
                start: 1,
                end: 89,
                animationSpeed: 1,
                doubleDigit: false,
            },
            bombAnim: {
                prefix: 'tile0',
                postfix: '',
                start: 1,
                end: 89,
                animationSpeed: 0.25,
                doubleDigit: true,
            },
            emoji9Anim: {
                prefix: 'emoji9_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.5,
                doubleDigit: true,
            },
            emoji16Anim: {
                prefix: 'emoji16_',
                postfix: '',
                start: 1,
                end: 128,
                animationSpeed: 0.5,
                doubleDigit: true,
            },
            emojilaughingAnim: {
                prefix: 'emoji_laughing00',
                postfix: '',
                start: 1,
                end: 87,
                animationSpeed: 0.5,
                doubleDigit: true,
            },
            emoji_1001Anim: {
                prefix: 'smiley_',
                postfix: '',
                start: 0,
                end: 107,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1002Anim: {
                prefix: 'frown',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1003Anim: {
                prefix: 'surprise',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1004Anim: {
                prefix: 'devil',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1005Anim: {
                prefix: 'confused',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1006Anim: {
                prefix: 'vomiting',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1007Anim: {
                prefix: 'violin',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1008Anim: {
                prefix: 'sarky',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1009Anim: {
                prefix: 'bored',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1010Anim: {
                prefix: 'crying',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1012Anim: {
                prefix: 'thumbsup',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1013Anim: {
                prefix: 'thumbsDown',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1015Anim: {
                prefix: 'donkey',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1016Anim: {
                prefix: 'whale',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1018Anim: {
                prefix: 'shark',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1017Anim: {
                prefix: 'fish',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1014Anim: {
                prefix: 'lucky',
                postfix: '',
                start: 1,
                end: 107,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1019Anim: {
                prefix: 'ppicon',
                postfix: '',
                start: 1,
                end: 128,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1020Anim: {
                prefix: 'club',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1021Anim: {
                prefix: 'diamond',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1022Anim: {
                prefix: 'heart',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1023Anim: {
                prefix: 'spade',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            timerAni: {
                prefix: 'timer',
                postfix: '',
                start: 1,
                end: 600,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            sandTimerAnim: {
                prefix: 'sandTimer',
                postfix: '',
                start: 1,
                end: 600,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            liverBobAni: {
                prefix: 'Nob1-100',
                postfix: '',
                start: 1,
                end: 25,
                animationSpeed: 0.9,
                doubleDigit: true,
            },
            spinTimeBulbAni: {
                prefix: 'LightsGlow-looping00',
                postfix: '',
                start: 1,
                end: 10,
                animationSpeed: 1,
                doubleDigit: true,
            },
            spinSTopBulbAni: {
                prefix: 'lightsOnOff00',
                postfix: '',
                start: 1,
                end: 2,
                animationSpeed: 0.1,
                doubleDigit: false,
            },
            spinGlowAni: {
                prefix: 'glow',
                postfix: '',
                start: 1,
                end: 118,
                animationSpeed: 1.1,
                doubleDigit: false,
            },
            playerWinGlowAni: {
                prefix: 'plate_eff',
                postfix: '',
                start: 1,
                end: 75,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            youWinAni: {
                prefix: 'youwin',
                postfix: '',
                start: 1,
                end: 80,
                animationSpeed: 0.75,
                doubleDigit: false,
            },
            selfRabbitEntryAni: {
                prefix: 'rabbit',
                postfix: '',
                start: 1,
                end: 14,
                animationSpeed: 0.35,
                doubleDigit: false,
            },
            disconnectionAni: {
                prefix: 'loader',
                postfix: '',
                start: 1,
                end: 24,
                animationSpeed: 0.2,
                doubleDigit: false,
            },
            selfRabbitDiamondAni: {
                prefix: 'rabbit',
                postfix: '',
                start: 86,
                end: 94,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            selfRabbitExitAni: {
                prefix: 'rabbit',
                postfix: '',
                start: 95,
                end: 108,
                animationSpeed: 0.35,
                doubleDigit: false,
            },
            opponentRabbitEntryAni: {
                prefix: 'ear',
                postfix: '',
                start: 1,
                end: 10,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            opponentRabbitExitAni: {
                prefix: 'ear',
                postfix: '',
                start: 33,
                end: 36,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            AllInGlowAnimation: {
                prefix: 'allin_bg',
                postfix: '',
                start: 1,
                end: 189,
                animationSpeed: 0.75,
                doubleDigit: false,
            },
            spotLightown: {
                prefix: 'spotlightown',
                postfix: '',
                start: 1,
                end: 48,
                animationSpeed: 0.3,
                doubleDigit: false,
            },
            spotLightopponent: {
                prefix: 'spotlightopp',
                postfix: '',
                start: 1,
                end: 48,
                animationSpeed: 0.15,
                doubleDigit: false,
            },
            throwAni1: {
                prefix: 'fishing_',
                postfix: '',
                start: 1,
                end: 129,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni2: {
                prefix: 'throwMud_',
                postfix: '',
                start: 1,
                end: 109,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni12: {
                prefix: 'Champagne',
                postfix: '',
                start: 1,
                end: 115,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni8: {
                prefix: 'cigar_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni10: {
                prefix: 'waving_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni7: {
                prefix: 'egg00',
                postfix: '',
                start: 1,
                end: 70,
                animationSpeed: 0.6,
                doubleDigit: true,
            },
            throwAni13: {
                prefix: 'goat_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni4: {
                prefix: 'kiss_',
                postfix: '',
                start: 1,
                end: 124,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni3: {
                prefix: 'pigeon_',
                postfix: '',
                start: 1,
                end: 121,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni5: {
                prefix: 'target_',
                postfix: '',
                start: 1,
                end: 112,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni11: {
                prefix: 'taxi_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni9: {
                prefix: 'Thief_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni6: {
                prefix: 'boxing00',
                postfix: '',
                start: 1,
                end: 92,
                animationSpeed: 0.6,
                doubleDigit: true,
            },
            widgetBlink: {
                prefix: 'widgetBlink',
                postfix: '',
                start: 1,
                end: 20,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            widgetParticleEffect: {
                prefix: 'widgetParticleEffect',
                postfix: '',
                start: 1,
                end: 17,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            widgetShimmer: {
                prefix: 'widgetShimmer',
                postfix: '',
                start: 1,
                end: 30,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            coinsConvert: {
                prefix: 'coinsConvert',
                postfix: '',
                start: 1,
                end: 16,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            coinDrop: {
                prefix: 'coinDrop',
                postfix: '',
                start: 1,
                end: 90,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            rippleEffect: {
                prefix: 'rippleEffect',
                postfix: '',
                start: 1,
                end: 22,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            glowEffect: {
                prefix: 'glowEffect',
                postfix: '',
                start: 1,
                end: 52,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            glowEffectFinal: {
                prefix: 'glowEffectFinal',
                postfix: '',
                start: 1,
                end: 30,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            finalTextRippleAnim: {
                prefix: 'finalTextRippleAnim',
                postfix: '',
                start: 1,
                end: 26,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            winCoinsEffect: {
                prefix: 'winCoinsEffect',
                postfix: '',
                start: 1,
                end: 81,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            winCoinsBlast: {
                prefix: 'winCoinsBlast',
                postfix: '',
                start: 1,
                end: 89,
                animationSpeed: 0.7,
                doubleDigit: false,
            },
            coinsRotate: {
                prefix: 'coinsRotate',
                postfix: '',
                start: 1,
                end: 15,
                animationSpeed: 0.3,
                doubleDigit: false,
            },
            widgetGlow: {
                prefix: 'widgetGlow',
                postfix: '',
                start: 1,
                end: 19,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            winCoinsDropEffectShortAnim: {
                prefix: 'winCoinsDropEffectShortAnim',
                postfix: '',
                start: 1,
                end: 30,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            coinsConvertShortAnim: {
                prefix: 'coinsConvertShortAnim',
                postfix: '',
                start: 1,
                end: 9,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            coinsDropGlow: {
                prefix: 'coinsDropGlow',
                postfix: '',
                start: 1,
                end: 7,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            loyaltyCashBackShimmer: { prefix: 'shimmer', postfix: '', start: 1, end: 19, animationSpeed: 0.5, doubleDigit: false },
            loyaltyCashBackLockerShine: { prefix: 'safe_shine', postfix: '', start: 1, end: 17, animationSpeed: 0.5, doubleDigit: false },
            loyaltyCashBackLockerStarAnim: { prefix: 'star_ani', postfix: '', start: 1, end: 15, animationSpeed: 0.4, doubleDigit: false },
            loyaltyCashBackProgressBarAnim: { prefix: 'green_loader', postfix: '', start: 1, end: 40, animationSpeed: 1, doubleDigit: false },
            cashOutEquityAnim: { prefix: 'cashout', postfix: '', start: 1, end: 90, animationSpeed: 0.47, doubleDigit: false },
            lockAnimAssetsAnim: { prefix: 'lock_', postfix: '', start: 1, end: 75, animationSpeed: 0.47, doubleDigit: false },
            ratholProgressAnim: { prefix: 'ratHoleProg_', postfix: '', start: 1, end: 600, animationSpeed: 0.47, doubleDigit: false },
        },
        Desktop: {
            timeBankOtherPlayer: {
                prefix: 'num_bgeffect00',
                postfix: '',
                start: 1,
                end: 67,
                animationSpeed: 0.8,
                doubleDigit: true,
            },
            timeBankSeqAni: {
                prefix: 'sandclock_loop00',
                postfix: '',
                start: 1,
                end: 90,
                animationSpeed: 1,
                doubleDigit: true,
            },
            timeBankAbouttoStartSeq: {
                prefix: 'sandclock00',
                postfix: '',
                start: 1,
                end: 56,
                animationSpeed: 1,
                doubleDigit: true,
            },
            leverHandleAnimation: {
                prefix: 'handle',
                postfix: '',
                start: 1,
                end: 89,
                animationSpeed: 1,
                doubleDigit: false,
            },
            bombAnim: {
                prefix: 'tile0',
                postfix: '',
                start: 1,
                end: 89,
                animationSpeed: 0.25,
                doubleDigit: true,
            },
            emoji9Anim: {
                prefix: 'emoji9_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.5,
                doubleDigit: true,
            },
            emoji16Anim: {
                prefix: 'emoji16_',
                postfix: '',
                start: 1,
                end: 128,
                animationSpeed: 0.5,
                doubleDigit: true,
            },
            emojilaughingAnim: {
                prefix: 'emoji_laughing00',
                postfix: '',
                start: 1,
                end: 87,
                animationSpeed: 0.5,
                doubleDigit: true,
            },
            emoji_1001Anim: {
                prefix: 'smiley_',
                postfix: '',
                start: 0,
                end: 107,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1002Anim: {
                prefix: 'frown',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1003Anim: {
                prefix: 'surprise',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1004Anim: {
                prefix: 'devil',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1005Anim: {
                prefix: 'confused',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1006Anim: {
                prefix: 'vomiting',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1007Anim: {
                prefix: 'violin',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1008Anim: {
                prefix: 'sarky',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1009Anim: {
                prefix: 'bored',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1010Anim: {
                prefix: 'crying',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1012Anim: {
                prefix: 'thumbsup',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1013Anim: {
                prefix: 'thumbsDown',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1015Anim: {
                prefix: 'donkey',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1016Anim: {
                prefix: 'whale',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1018Anim: {
                prefix: 'shark',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1017Anim: {
                prefix: 'fish',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1014Anim: {
                prefix: 'lucky',
                postfix: '',
                start: 1,
                end: 107,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1019Anim: {
                prefix: 'ppicon',
                postfix: '',
                start: 1,
                end: 128,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1020Anim: {
                prefix: 'club',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1021Anim: {
                prefix: 'diamond',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1022Anim: {
                prefix: 'heart',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            emoji_1023Anim: {
                prefix: 'spade',
                postfix: '',
                start: 1,
                end: 100,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            timerAni: {
                prefix: 'timer',
                postfix: '',
                start: 1,
                end: 600,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            sandTimerAnim: {
                prefix: 'sandTimer',
                postfix: '',
                start: 1,
                end: 600,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            liverBobAni: {
                prefix: 'Nob1-100',
                postfix: '',
                start: 1,
                end: 25,
                animationSpeed: 0.9,
                doubleDigit: true,
            },
            spinTimeBulbAni: {
                prefix: 'LightsGlow-looping00',
                postfix: '',
                start: 1,
                end: 10,
                animationSpeed: 1,
                doubleDigit: true,
            },
            spinSTopBulbAni: {
                prefix: 'lightsOnOff00',
                postfix: '',
                start: 1,
                end: 2,
                animationSpeed: 0.1,
                doubleDigit: false,
            },
            spinGlowAni: {
                prefix: 'glow',
                postfix: '',
                start: 1,
                end: 118,
                animationSpeed: 1.1,
                doubleDigit: false,
            },
            playerWinGlowAni: {
                prefix: 'plate_eff',
                postfix: '',
                start: 1,
                end: 75,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            youWinAni: {
                prefix: 'youwin',
                postfix: '',
                start: 1,
                end: 80,
                animationSpeed: 0.75,
                doubleDigit: false,
            },
            selfRabbitEntryAni: {
                prefix: 'rabbit',
                postfix: '',
                start: 1,
                end: 14,
                animationSpeed: 0.35,
                doubleDigit: false,
            },
            disconnectionAni: {
                prefix: 'loader',
                postfix: '',
                start: 1,
                end: 24,
                animationSpeed: 0.2,
                doubleDigit: false,
            },
            selfRabbitDiamondAni: {
                prefix: 'rabbit',
                postfix: '',
                start: 86,
                end: 94,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            selfRabbitExitAni: {
                prefix: 'rabbit',
                postfix: '',
                start: 95,
                end: 108,
                animationSpeed: 0.35,
                doubleDigit: false,
            },
            opponentRabbitEntryAni: {
                prefix: 'ear',
                postfix: '',
                start: 1,
                end: 10,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            opponentRabbitExitAni: {
                prefix: 'ear',
                postfix: '',
                start: 33,
                end: 36,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            AllInGlowAnimation: {
                prefix: 'allin_bg',
                postfix: '',
                start: 1,
                end: 189,
                animationSpeed: 0.75,
                doubleDigit: false,
            },
            spotLightown: {
                prefix: 'spotlightown',
                postfix: '',
                start: 1,
                end: 48,
                animationSpeed: 0.3,
                doubleDigit: false,
            },
            spotLightopponent: {
                prefix: 'spotlightopp',
                postfix: '',
                start: 1,
                end: 48,
                animationSpeed: 0.15,
                doubleDigit: false,
            },
            throwAni1: {
                prefix: 'fishing_',
                postfix: '',
                start: 1,
                end: 129,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni2: {
                prefix: 'throwMud_',
                postfix: '',
                start: 1,
                end: 109,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni12: {
                prefix: 'Champagne',
                postfix: '',
                start: 1,
                end: 115,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni8: {
                prefix: 'cigar_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni10: {
                prefix: 'waving_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni7: {
                prefix: 'egg00',
                postfix: '',
                start: 1,
                end: 70,
                animationSpeed: 0.6,
                doubleDigit: true,
            },
            throwAni13: {
                prefix: 'goat_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni4: {
                prefix: 'kiss_',
                postfix: '',
                start: 1,
                end: 124,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni3: {
                prefix: 'pigeon_',
                postfix: '',
                start: 1,
                end: 121,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni5: {
                prefix: 'target_',
                postfix: '',
                start: 1,
                end: 112,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni11: {
                prefix: 'taxi_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni9: {
                prefix: 'Thief_',
                postfix: '',
                start: 1,
                end: 108,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            throwAni6: {
                prefix: 'boxing00',
                postfix: '',
                start: 1,
                end: 92,
                animationSpeed: 0.6,
                doubleDigit: true,
            },
            widgetBlink: {
                prefix: 'widgetBlink',
                postfix: '',
                start: 1,
                end: 20,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            widgetParticleEffect: {
                prefix: 'widgetParticleEffect',
                postfix: '',
                start: 1,
                end: 17,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            widgetShimmer: {
                prefix: 'widgetShimmer',
                postfix: '',
                start: 1,
                end: 30,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            coinsConvert: {
                prefix: 'coinsConvert',
                postfix: '',
                start: 1,
                end: 16,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            coinDrop: {
                prefix: 'coinDrop',
                postfix: '',
                start: 1,
                end: 90,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            rippleEffect: {
                prefix: 'rippleEffect',
                postfix: '',
                start: 1,
                end: 22,
                animationSpeed: 0.6,
                doubleDigit: false,
            },
            glowEffect: {
                prefix: 'glowEffect',
                postfix: '',
                start: 1,
                end: 52,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            glowEffectFinal: {
                prefix: 'glowEffectFinal',
                postfix: '',
                start: 1,
                end: 30,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            finalTextRippleAnim: {
                prefix: 'finalTextRippleAnim',
                postfix: '',
                start: 1,
                end: 26,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            winCoinsEffect: {
                prefix: 'winCoinsEffect',
                postfix: '',
                start: 1,
                end: 81,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            winCoinsBlast: {
                prefix: 'winCoinsBlast',
                postfix: '',
                start: 1,
                end: 89,
                animationSpeed: 0.7,
                doubleDigit: false,
            },
            coinsRotate: {
                prefix: 'coinsRotate',
                postfix: '',
                start: 1,
                end: 15,
                animationSpeed: 0.3,
                doubleDigit: false,
            },
            widgetGlow: {
                prefix: 'widgetGlow',
                postfix: '',
                start: 1,
                end: 19,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            winCoinsDropEffectShortAnim: {
                prefix: 'winCoinsDropEffectShortAnim',
                postfix: '',
                start: 1,
                end: 30,
                animationSpeed: 0.4,
                doubleDigit: false,
            },
            coinsConvertShortAnim: {
                prefix: 'coinsConvertShortAnim',
                postfix: '',
                start: 1,
                end: 9,
                animationSpeed: 0.25,
                doubleDigit: false,
            },
            coinsDropGlow: {
                prefix: 'coinsDropGlow',
                postfix: '',
                start: 1,
                end: 7,
                animationSpeed: 0.5,
                doubleDigit: false,
            },
            loyaltyCashBackShimmer: { prefix: 'shimmer', postfix: '', start: 1, end: 19, animationSpeed: 1, doubleDigit: false },
            loyaltyCashBackLockerShine: { prefix: 'safe_shine', postfix: '', start: 1, end: 17, animationSpeed: 1, doubleDigit: false },
            loyaltyCashBackLockerStarAnim: { prefix: 'star_ani', postfix: '', start: 1, end: 15, animationSpeed: 1, doubleDigit: false },
            loyaltyCashBackProgressBarAnim: { prefix: 'green_loader', postfix: '', start: 1, end: 40, animationSpeed: 1, doubleDigit: false },
            cashOutEquityAnim: { prefix: 'cashout', postfix: '', start: 1, end: 90, animationSpeed: 0.47, doubleDigit: false },
            lockAnimAssetsAnim: { prefix: 'lock_', postfix: '', start: 1, end: 75, animationSpeed: 0.47, doubleDigit: false },
            ratholProgressAnim: { prefix: 'ratHoleProg_', postfix: '', start: 1, end: 600, animationSpeed: 0.47, doubleDigit: false },
        },
    },
    allowedOrientation: 1,
    screenMoveDuration: 0.5,
    needCallBackOnSoundEnd: true,
    //------------- poker UI data--------
    maxPlayerCount: 3,
    TableDataPos: {},
    selfUserClientPosInd: 0,
    throwEmojisData: [
        {
            name: 'imoji1',
            imojiName: 'throw1',
            x: 45,
            y: 38,
        },
        {
            name: 'imoji2',
            imojiName: 'throw2',
            x: 163,
            y: 38,
        },
        {
            name: 'imoji3',
            imojiName: 'throw3',
            x: 280,
            y: 38,
        },
        {
            name: 'imoji4',
            imojiName: 'throw4',
            x: 396,
            y: 38,
        },
        {
            name: 'imoji5',
            imojiName: 'throw5',
            x: 505,
            y: 38,
        },
        {
            name: 'imoji6',
            imojiName: 'throw6',
            x: 45,
            y: 163,
        },
        {
            name: 'imoji7',
            imojiName: 'throw7',
            x: 163,
            y: 163,
        },
        {
            name: 'imoji8',
            imojiName: 'throw8',
            x: 280,
            y: 163,
        },
        {
            name: 'imoji9',
            imojiName: 'throw9',
            x: 396,
            y: 163,
        },
        {
            name: 'imoji10',
            imojiName: 'throw10',
            x: 505,
            y: 163,
        },
        {
            name: 'imoji11',
            imojiName: 'throw11',
            x: 45,
            y: 288,
        },
        {
            name: 'imoji12',
            imojiName: 'throw12',
            x: 163,
            y: 288,
        },
        {
            name: 'imoji13',
            imojiName: 'throw13',
            x: 280,
            y: 288,
        },
    ],
    genericPopUpConfig: {
        titleText: 'TITLE',
        bodyText: 'BODY TEXT',
        buttonsTextArr: [],
        buttonsActionArr: [],
        popUpId: 0,
        showCloseButton: true,
        showCheckBox: false,
        priority: 0, //"PRIORITY_NONE"
        antiWarningMSG: '',
        isAntiTblHop: false,
        antiTblPopupType: 0,
    },
    athMultiStyleConfig: {
        leaveTableWarning: [
            { lang: 'en', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'hu', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'nl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt-br', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'ru', learnMoreYAdj: { yAdj: 50 } },
            { lang: 'de', learnMoreYAdj: { yAdj: 25 } },
        ],
        leaveTablePenalty: [
            { lang: 'en', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'hu', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'nl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt-br', learnMoreYAdj: { yAdj: 80 } },
            { lang: 'pt', learnMoreYAdj: { yAdj: 80 } },
            { lang: 'ru', learnMoreYAdj: { yAdj: 100 } },
            { lang: 'de', learnMoreYAdj: { yAdj: 25 } },
        ],
        manualSitoutWarning: [
            { lang: 'en', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'hu', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'nl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt-br', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'ru', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'de', learnMoreYAdj: { yAdj: 25 } },
        ],
        manualSitoutPenalty: [
            { lang: 'en', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'hu', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'nl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt-br', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'ru', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'de', learnMoreYAdj: { yAdj: 25 } },
        ],
        autoSitoutPenalty: [
            { lang: 'en', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'hu', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'nl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pl', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt-br', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'pt', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'ru', learnMoreYAdj: { yAdj: 25 } },
            { lang: 'de', learnMoreYAdj: { yAdj: 25 } },
        ],
    },
};
