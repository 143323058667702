import { Message } from '../../message';

export class LSPoolPlayerListDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSPoolPlayerListDeltaUpdate';
    private poolId: number = 0;
    private deltaEvents: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, poolId: number = 0, deltaEvents: any[] | null = null) {
        super(reqServerPeerId);
        this.poolId = poolId;
        this.deltaEvents = deltaEvents;
    }

    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
}
