import { Message } from '../../message';

export class SuspiciousLocationQuestions extends Message {
    private MESSAGE_NAME: string = 'SuspiciousLocationQuestions';
    private responseId: number = 0;
    private noOfAttempts: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseId: number = 0, noOfAttempts: number = 0) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.noOfAttempts = noOfAttempts;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getNoOfAttempts(): number {
        return this.noOfAttempts;
    }

    setNoOfAttempts(noOfAttempts: number) {
        this.noOfAttempts = noOfAttempts;
    }
}
