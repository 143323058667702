<div
    class="optin-popup"
    [ngClass]="isOptIn ? 'optIn' : 'notOptIn'"
    [ngStyle]="!isOptIn ? { 'background-image': 'url(' + loyaltyAssets.notoptinbg.image.src + ')' } : ''">
    <span class="theme-ex close_btn" (click)="closeLoyaltyCashBackPopup()"></span>
    <div *ngIf="!isOptIn" class="optin-nonActive">
        <span class="bold-text multi-line-test-truncate-title">
            <span>
                {{ 'PARTY_POKER_MOBILE_CASHBACK_PRE_OPTIN_EARN_CASH_TEXT' | translate }}
            </span>
            {{ 'PARTY_POKER_MOBILE_CASHBACK_AS_YOU_PLAY_YOUR_TEXT' | translate }}
            <br />
            <span>
                {{ 'PARTY_POKER_MOBILE_CASHBACK_PRE_OPTIN_OVERLAY_FAV_GAMES_TEXT' | translate }}
            </span>
        </span>
        <p class="multi-line-test-truncate-description">
            {{ 'PARTY_POKER_MOBILE_CASHBACK_PRE_OPTIN_OVERLAY_DESCIRPTION_TEXT' | translate }}
        </p>
        <button
            type="button"
            class="opt-in-btn"
            aria-label="opt-in"
            (click)="enableOptIn()"
            [ngStyle]="{ 'background-image': 'url(' + loyaltyAssets.optinbuttonbg.image.src + ')' }">
            {{ 'PARTY_POKER_MOBILE_CASHBACK_OPT_IN_BTN_TEXT' | translate }}
        </button>
        <span (click)="loyaltyInfoPopup()" class="theme-info-i loyaltyInfoPopup"></span>
        <span class="loyaltyInfoPopupAfter" [ngStyle]="{ 'background-image': 'url(' + loyaltyAssets.loyaltycashbackicon.image.src + ')' }"></span>
    </div>
    <div *ngIf="isOptIn" class="option-active">
        <p class="title">
            {{ 'PARTY_POKER_MOBILE_CASHBACK_CASHBACK_TEXT' | translate }}
        </p>
        <span class="currentTarget">
            {{ 'PARTY_POKER_MOBILE_CASHBACK_OVERLAY_PRIZE_TEXT' | translate | titlecase }}:
            <!-- prize: Target -->
            {{ currencyConvert(weeklyPoints.targetCashback) }}. {{ 'PARTY_POKER_MOBILE_CASHBACK_TARGET_TEXT' | translate }}:
            <!-- points: -->
            {{ pointsConvert(weeklyPoints.currentTarget) }}
            {{ 'PARTY_POKER_MOBILE_CASHBACK_POINTS_TEXT' | translate }}
        </span>
        <span class="soFar">
            {{ 'PARTY_POKER_MOBILE_CASHBACK_BANKED_TEXT' | translate }}:
            <span class="bold-text"> {{ currencyConvert(weeklyPoints.currentCashback) }} </span>
        </span>
        <div class="progressBar-section">
            <div class="progressBar-left">
                <div class="progressBar-points">
                    <div>
                        {{ pointsConvert(weeklyPoints.currentSlabPoints) }}
                    </div>
                    <div class="progress-booster" *ngIf="progressBooster > 0 ? true : false">
                        {{ progressBooster }}%
                        {{ 'PARTY_POKER_MOBILE_CASHBACK_BOOST_TEXT' | translate }}
                    </div>
                    <div class="text-right">
                        <span class="bold-text"> {{ pointsConvert(weeklyPoints.weeklyPoints) }} </span>/{{
                            pointsConvert(weeklyPoints.currentTarget)
                        }}
                    </div>
                </div>
                <div>
                    <div
                        *ngIf="weeklyPoints"
                        class="progress progress-bgImag"
                        [ngStyle]="{ 'background-image': 'url(' + loyaltyAssets.progressbg.image.src + ')' }">
                        <div class="progress-bar progress-bar-color" [ngStyle]="{ width: progressBarValue() }"></div>
                        <div class="progressbar-segments">
                            <span style="width: 20%"></span>
                            <span style="width: 20%"></span>
                            <span style="width: 20%"></span>
                            <span style="width: 20%"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="progressBar-right">
                <span class="progressBar-right-text">{{ currencyConvert(weeklyPoints.targetCashback) }}</span>
                <span
                    class="progressBar-right-icon"
                    [ngStyle]="{ 'background-image': 'url(' + loyaltyAssets.loyaltycashbackicon.image.src + ')' }"></span>
            </div>
        </div>
        <span class="expires">
            {{ 'PARTY_POKER_MOBILE_CASHBACK_OVERLAY_EXPIRES_TEXT' | translate }}:
            {{ convertDate(weeklyPoints.endDate) }}
        </span>
        <p class="nextTarget">
            <span class="bold-text">
                {{ 'PARTY_POKER_MOBILE_CASHBACK_NEXT_PRIZE_TEXT' | translate }}
                {{ currencyConvert(weeklyPoints.nextTargetCashback) }}.
            </span>
            <span class="color-white">
                {{ 'PARTY_POKER_MOBILE_CASHBACK_OVERLAY_NEXT_TARGET_TEXT' | translate }}
            </span>
            <span class="bold-text">
                {{ pointsConvert(weeklyPoints.nextSlabPoints) }}
                {{ 'PARTY_POKER_MOBILE_CASHBACK_POINTS_TEXT' | translate }}.
            </span>
        </p>
        <p class="learnMore" (click)="loyaltyInfoPopup()">
            {{ 'PARTY_POKER_GC_MAT_LEARNMORE' | translate }}
        </p>
    </div>
</div>
