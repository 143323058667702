import { Message } from '../../message';

export class LSTourneyTimeToAct extends Message {
    private MESSAGE_NAME: string = 'LSTourneyTimeToAct';
    private preFlopStandardTime: number = 0;
    private flopStandardTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, preFlopStandardTime: number = 0, flopStandardTime: number = 0) {
        super(reqServerPeerId);
        this.preFlopStandardTime = preFlopStandardTime;
        this.flopStandardTime = flopStandardTime;
    }

    getPreFlopStandardTime(): number {
        return this.preFlopStandardTime;
    }

    setPreFlopStandardTime(preFlopStandardTime: number) {
        this.preFlopStandardTime = preFlopStandardTime;
    }
    getFlopStandardTime(): number {
        return this.flopStandardTime;
    }

    setFlopStandardTime(flopStandardTime: number) {
        this.flopStandardTime = flopStandardTime;
    }
}
