import { Message } from '../../message';

export class ShowPlayNow extends Message {
    private MESSAGE_NAME: string = 'ShowPlayNow';
    private showPlayNowBtn: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, showPlayNowBtn: boolean | null = false) {
        super(reqServerPeerId);
        this.showPlayNowBtn = showPlayNowBtn;
    }

    getShowPlayNowBtn(): boolean | null {
        return this.showPlayNowBtn;
    }

    setShowPlayNowBtn(showPlayNowBtn: boolean | null) {
        this.showPlayNowBtn = showPlayNowBtn;
    }
}
