import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { TranslatePipe } from '../../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../../poker-web-client-shared.service';
import { CgFiltersEventBusService } from '../services/cg-filters-event-bus.service';
import { FilterConfig } from '../utils/filterConfig.model';

@Component({
    selector: 'pwc-cashgames-subfilter',
    templateUrl: 'cashgames-subfilter.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    providers: [TranslatePipe],
})
export class cashgamesSubFiltersComponent implements OnInit {
    @Input() filtersConfig: FilterConfig[];
    @Input() filterType: string;
    @Input() filterTypeValue: string;
    selectedFilters: any[] = [];
    private readonly logger: PokerLogger;
    constructor(
        private eventBus: CgFiltersEventBusService,
        loggerFactory: LoggerFactory,
        private shareService: PokerWebClientSharedService,
        public translatePipe: TranslatePipe,
    ) {
        this.shareService.setPageHeaderTitle({ title: 'Filters2', url: '/play/cash-filters', showBalanceBlock: false });
        this.logger = loggerFactory.getLogger('cashGameSubfilterLoggingComponent');
    }

    ngOnInit(): void {
        this.eventBus.getCurrentFilters.subscribe((res) => {
            this.selectedFilters = res[this.filterTypeValue];
        });
    }

    isFilterSelected(filter) {
        this.logger.info('clicked on subfilter..!' + JSON.stringify(filter));
        if (this.selectedFilters.length > 0) {
            if (this.currentFiltersLength == this.selectedFilters.length) {
                return true;
            } else {
                return this.selectedFilters.includes(filter);
            }
        } else {
            return false;
        }
    }
    updateFilter(item, availableFiltersCount) {
        item.value === 'All'
            ? this.eventBus.updateFilterWithAllValues(this.filterTypeValue)
            : this.eventBus.updateFilter(this.filterTypeValue, item.value, availableFiltersCount);
    }

    get currentFiltersLength() {
        return this.filtersConfig.length - 1;
    }
}
