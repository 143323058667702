export class Seat {
    private _number: number;
    private _player: string | null;

    constructor(number: number, player: string | null) {
        this._number = number;
        this._player = player;
    }

    get number(): number {
        return this._number;
    }

    set number(value: number) {
        this._number = value;
    }

    get player(): string | null {
        return this._player;
    }

    set player(value: string) {
        this._player = value;
    }
}
