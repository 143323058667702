import { Message } from '../../message';

export class ResponseBuyMoreChipsSuccess extends Message {
    private MESSAGE_NAME: string = 'ResponseBuyMoreChipsSuccess';
    private conversationId: number = 0;
    private partnerBalanceAC: number = 0;
    private realMoneyBalanceAC: number = 0;
    private refreshBalanceTypes: number = 0;
    private rebuyAmountAC: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        conversationId: number = 0,
        partnerBalanceAC: number = 0,
        realMoneyBalanceAC: number = 0,
        refreshBalanceTypes: number = 0,
        rebuyAmountAC: number = 0,
    ) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.partnerBalanceAC = partnerBalanceAC;
        this.realMoneyBalanceAC = realMoneyBalanceAC;
        this.refreshBalanceTypes = refreshBalanceTypes;
        this.rebuyAmountAC = rebuyAmountAC;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getPartnerBalanceAC(): number {
        return this.partnerBalanceAC;
    }

    setPartnerBalanceAC(partnerBalanceAC: number) {
        this.partnerBalanceAC = partnerBalanceAC;
    }
    getRealMoneyBalanceAC(): number {
        return this.realMoneyBalanceAC;
    }

    setRealMoneyBalanceAC(realMoneyBalanceAC: number) {
        this.realMoneyBalanceAC = realMoneyBalanceAC;
    }
    getRefreshBalanceTypes(): number {
        return this.refreshBalanceTypes;
    }

    setRefreshBalanceTypes(refreshBalanceTypes: number) {
        this.refreshBalanceTypes = refreshBalanceTypes;
    }
    getRebuyAmountAC(): number {
        return this.rebuyAmountAC;
    }

    setRebuyAmountAC(rebuyAmountAC: number) {
        this.rebuyAmountAC = rebuyAmountAC;
    }
}
