import { Message } from '../../message';

export class SelectedOptionNew extends Message {
    private MESSAGE_NAME: string = 'SelectedOptionNew';
    private seatNo: string | null = null;
    private optionId: string | null = null;
    private amount: number = 0;
    private isAllIn: boolean | null = false;
    private betVector: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNo: string | null = null,
        optionId: string | null = null,
        amount: number = 0,
        isAllIn: boolean | null = false,
        betVector: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.optionId = optionId;
        this.amount = amount;
        this.isAllIn = isAllIn;
        this.betVector = betVector;
    }

    getSeatNo(): string | null {
        return this.seatNo;
    }

    setSeatNo(seatNo: string | null) {
        this.seatNo = seatNo;
    }
    getOptionId(): string | null {
        return this.optionId;
    }

    setOptionId(optionId: string | null) {
        this.optionId = optionId;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getIsAllIn(): boolean | null {
        return this.isAllIn;
    }

    setIsAllIn(isAllIn: boolean | null) {
        this.isAllIn = isAllIn;
    }
    getBetVector(): any[] | null {
        return this.betVector;
    }

    setBetVector(betVector: any[] | null) {
        this.betVector = betVector;
    }
}
