import { Message } from '../../message';

export class RemovePlayer extends Message {
    private MESSAGE_NAME: string = 'RemovePlayer';
    private seatNumber: number = 0;
    private isReserved: boolean | null = false;
    private isSeatedPlayer: boolean | null = false;
    private dontShowSeatOpen: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNumber: number = 0,
        isReserved: boolean | null = false,
        isSeatedPlayer: boolean | null = false,
        dontShowSeatOpen: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.seatNumber = seatNumber;
        this.isReserved = isReserved;
        this.isSeatedPlayer = isSeatedPlayer;
        this.dontShowSeatOpen = dontShowSeatOpen;
    }

    getSeatNumber(): number {
        return this.seatNumber;
    }

    setSeatNumber(seatNumber: number) {
        this.seatNumber = seatNumber;
    }
    getIsReserved(): boolean | null {
        return this.isReserved;
    }

    setIsReserved(isReserved: boolean | null) {
        this.isReserved = isReserved;
    }
    getIsSeatedPlayer(): boolean | null {
        return this.isSeatedPlayer;
    }

    setIsSeatedPlayer(isSeatedPlayer: boolean | null) {
        this.isSeatedPlayer = isSeatedPlayer;
    }
    getDontShowSeatOpen(): boolean | null {
        return this.dontShowSeatOpen;
    }

    setDontShowSeatOpen(dontShowSeatOpen: boolean | null) {
        this.dontShowSeatOpen = dontShowSeatOpen;
    }
}
