import { Routes } from '@angular/router';

import { PokerFastPokerSynopsisResolver } from './poker-fastpoker-synopsis.resolver';
import { PokerFastPokerResolver } from './poker-fastpoker.resolver';

export const pokerFastPokerRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerFastPokerResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerFastPokerSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerFastPokerResolver,
        },
    },
];
