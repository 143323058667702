import { Message } from '../../message';

export class MTCTLobbyToOpen extends Message {
    private MESSAGE_NAME: string = 'MTCTLobbyToOpen';
    private mtctId: number = 0;
    private category: number = 0;
    private domain: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, category: number = 0, domain: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.category = category;
        this.domain = domain;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getCategory(): number {
        return this.category;
    }

    setCategory(category: number) {
        this.category = category;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
}
