import { Message } from '../../message';

export class ResponseRebuySuccess extends Message {
    private MESSAGE_NAME: string = 'ResponseRebuySuccess';
    private desc: any | null = null;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, desc: any | null = null, conversationId: number = 0) {
        super(reqServerPeerId);
        this.desc = desc;
        this.conversationId = conversationId;
    }

    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
