import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DslPipe, DynamicHtmlDirective, NavigationService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';

@Component({
    selector: 'pwc-main-lobby-banner',
    templateUrl: 'main-lobby-banner.component.html',
    imports: [CommonModule, FormsModule, DslPipe, DynamicHtmlDirective, SwiperComponent],
    providers: [],
    standalone: true,
})
export class MainLobbyBannerComponent implements OnInit {
    initData: any;
    promotionBanners: any;
    sliderInterval: number;
    promotionBannersCheck: boolean = true;
    requestingClient: any;
    private readonly logger: PokerLogger;
    public config: SwiperOptions = {
        modules: [Navigation, Pagination, EffectCoverflow, Autoplay],
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 10,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        slidesPerGroup: 1,
        keyboard: true,
        rewind: true,
        observer: true,
    };

    // @ViewChild('swiper', { static: false }) componentRef?: Swiper;
    @ViewChild('swiperComponent') componentRef!: SwiperComponent;
    @ViewChild('pokerTeasersCarouselWrap') carouselWarp: ElementRef;

    constructor(
        private navigation: NavigationService,
        private clientToServer: ClientToServerRequestServices,
        loggerFactory: LoggerFactory,
        private sharedService: PokerWebClientSharedService,
    ) {
        this.logger = loggerFactory.getLogger('mainLobbyBannerLoggingComponent');
    }

    ngOnInit(): void {
        this.initData = this.clientToServer.mainLobbyBanners;
        this.promotionBanners = this.initData?.promotionBanners;
        this.sliderInterval = this.initData?.carouselSliderTimeSpan * 1000;

        if (window.innerWidth > 601) {
            this.config = Object.assign({}, this.config, { touchRatio: 0 });
        }
        this.config = Object.assign({}, this.config, {
            autoplay: {
                delay: this.sliderInterval,
                disableOnInteraction: false,
            },
            watchOverflow: true,
        });
    }

    linkToAction(promo: any) {
        this.logger.info('clicked on banner image..!' + JSON.stringify(promo));
        this.sharedService.sendPortalPage({ url: promo.cta[0].link, text: promo.title });
        this.navigation.goTo('/play/' + 'portal');
    }
}
