import { Routes } from '@angular/router';

import { PokerGrandPrixUKSynopsisResolver } from './poker-grand-prix-uk-synopsis.resolver';
import { PokerGrandPrixUKResolver } from './poker-grand-prix-uk.resolver';

export const pokerGrandPrixUKRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerGrandPrixUKResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerGrandPrixUKSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerGrandPrixUKResolver,
        },
    },
];
