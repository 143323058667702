import { Message } from '../../message';

export class BalanceInfo extends Message {
    private MESSAGE_NAME: string = 'BalanceInfo';
    private balance: number = 0;
    private formatRequired: boolean | null = false;
    private inPlayBalance: number = 0;
    private netBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        balance: number = 0,
        formatRequired: boolean | null = false,
        inPlayBalance: number = 0,
        netBalance: number = 0,
    ) {
        super(reqServerPeerId);
        this.balance = balance;
        this.formatRequired = formatRequired;
        this.inPlayBalance = inPlayBalance;
        this.netBalance = netBalance;
    }

    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
    getFormatRequired(): boolean | null {
        return this.formatRequired;
    }

    setFormatRequired(formatRequired: boolean | null) {
        this.formatRequired = formatRequired;
    }
    getInPlayBalance(): number {
        return this.inPlayBalance;
    }

    setInPlayBalance(inPlayBalance: number) {
        this.inPlayBalance = inPlayBalance;
    }
    getNetBalance(): number {
        return this.netBalance;
    }

    setNetBalance(netBalance: number) {
        this.netBalance = netBalance;
    }
}
