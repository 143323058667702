import { Message } from '../../message';

export class OneClickTourneyRegistrationChange extends Message {
    private MESSAGE_NAME: string = 'OneClickTourneyRegistrationChange';
    private cardId: number = 0;
    private noOfParticipants: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardId: number = 0, noOfParticipants: number = 0) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.noOfParticipants = noOfParticipants;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
}
