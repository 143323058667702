<div id="leaderboard" class="leaderboard-container">
    <!-- Button List -->
    <ng-container *ngIf="!isSinglePlayer">
        <div class="mb-2" *ngIf="!hideButtons">
            <ng-container *ngFor="let item of buttonParameters">
                <button
                    *ngIf="item.promoId !== null"
                    [ngClass]="{ 'tab-btn-item-active': selectedPromo === item }"
                    (click)="getPromo(item)"
                    class="btn-sm btn tab-btn-item d-inline mr-1 mb-1">
                    {{ item.buttonTitle }}
                </button>
            </ng-container>
            <button
                class="btn-sm btn d-inline mr-1 float-right refresh-bth mt-2"
                [ngClass]="{ 'table-refreshing': refreshing }"
                (click)="refreshPromoData()">
                <span class="theme-refresh text-capitalize">{{ item.widgetConfigItem.refresh || 'Refresh' }}</span>
            </button>
        </div>
    </ng-container>
    <div [ngClass]="{ 'd-none-visible': !checkPromoData }">
        <!-- Search Filter -->
        <div class="mb-3">
            <input
                (keyup)="applyFilter($event.target.value)"
                [(ngModel)]="filterValue"
                class="form-filter-control"
                placeholder="{{ item.widgetConfigItem.search || 'Search' }}" />
            <button
                *ngIf="hideButtons"
                class="btn-sm btn float-right refresh-bth mt-3 ml-3"
                [ngClass]="{ 'table-refreshing': refreshing }"
                (click)="refreshPromoData()">
                <span class="theme-refresh text-capitalize">{{ item.widgetConfigItem.refresh || 'Refresh' }}</span>
            </button>
            <p class="mb-1 float-right mt-4 pr-1">{{ item.widgetConfigItem.lastUpdatedTime || 'Last updated time:' }} {{ currentDate }}</p>
        </div>

        <!-- Table -->
        <div class="mat-table-theme mat-table-theme-smooth" [ngClass]="{ 'table-refreshing': refreshing }">
            <table mat-table [dataSource]="dataSource" class="table table-bordered table-striped text-center mb-4">
                <!-- rank Column -->
                <ng-container matColumnDef="rank">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">
                        {{ contentParameters?.rank || item.widgetConfigItem.rank || 'Rank' }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element?.rank }}</td>
                </ng-container>

                <!-- points Column -->
                <ng-container matColumnDef="points">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                        {{ contentParameters?.points || item.widgetConfigItem.points || 'Points' }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-left">{{ element?.points }}</td>
                </ng-container>

                <!-- screenName Column -->
                <ng-container matColumnDef="screenName">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                        {{ contentParameters?.screenName || item.widgetConfigItem.screenName || 'Screen name' }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-left">
                        {{ element?.screenName?.charAt(0).toUpperCase() + element?.screenName?.substr(1).toLowerCase() }}
                    </td>
                </ng-container>

                <!-- prizes Column -->
                <ng-container matColumnDef="prizes">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                        {{ contentParameters?.prizes || item.widgetConfigItem.prizes || 'Prizes' }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element?.prizes['en-US'] }}</td>
                </ng-container>

                <tr mat-header-row class="thead-dark" *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    [attr.id]="selectedRowIndex === dataSource.data.indexOf(row) ? 'highlightId' : null"
                    [ngClass]="{ highlight: selectedRowIndex === dataSource.data.indexOf(row) }"
                    *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="pager" showFirstLastButtons></mat-paginator>
            <!-- Update getPageNum variable, when updating the pageSizeOptions array -->
        </div>
    </div>
    <div class="single-player-rank" *ngIf="singlePlayerRank && singlePlayerRank?.length && isSinglePlayer">
        <ng-container *ngFor="let rank of singlePlayerRank">
            <div class="lb-ranking-highlighted mb-3 mt-4">
                <div class="lb-ranking-grid-a" style="background-image: url('https://scmedia.itsfogo.com/$-$/dd82a0645b14484b8d45aacd534ac85e.png')">
                    <div class="lb-ranking-highlighted-rank">
                        <div class="lb-rank-label">{{ item.widgetConfigItem.rank || 'Rank' }}</div>
                        <div class="lb-rank-value">{{ rank.rank }}</div>
                    </div>
                </div>
                <div class="lb-ranking-grid-b">
                    <div class="lb-ranking-highlighted-player">
                        <div class="lb-rh-label">{{ item.widgetConfigItem.screenName || 'Screen name' }}</div>
                        <div class="lb-rh-value text-truncate">
                            {{ rank?.screenName?.charAt(0).toUpperCase() + rank?.screenName?.substr(1).toLowerCase() }}
                        </div>
                    </div>
                </div>
                <div class="lb-ranking-grid-c">
                    <div class="lb-ranking-highlighted-player">
                        <div class="lb-rh-label">{{ item.widgetConfigItem.points || 'Points' }}</div>
                        <div class="lb-rh-value text-truncate">{{ rank.points }}</div>
                    </div>
                </div>
                <div class="lb-ranking-grid-e">
                    <div class="lb-ranking-highlighted-player">
                        <div class="lb-rh-label">{{ item.widgetConfigItem.category || 'Category' }}</div>
                        <div class="lb-rh-value text-truncate">{{ rank.category }}</div>
                    </div>
                </div>
                <div class="lb-ranking-grid-d">
                    <div
                        class="lb-ranking-highlighted-prize"
                        style="background-image: url('https://scmedia.itsfogo.com/$-$/9b0f123ba7ad4c9bab2d38f3229375d3.png')">
                        <span class="text-truncate">{{ rank?.prizes['en-US'] }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="text-center">
            <button class="btn btn-outline-dark mt-3 view-lbs-btn align-self-start btn-sm" (click)="viewAllRanks()">
                <span class="theme-competitions-all text-capitalize">{{ item.widgetConfigItem.viewCompleteList || 'View all ranks' }}</span>
            </button>
        </div>
    </div>
    <div class="message-panel mt-4 mb-3" *ngIf="!checkPromoData && !isSinglePlayer">
        <div class="theme-info-i warning">
            <div class="cms-container">
                <h3>
                    <span>{{ item.widgetConfigItem.errorMessage || 'Data not available for' }}</span
                    ><strong> {{ categoryName || 'requested Screen name or Category.' }}</strong>
                </h3>
            </div>
        </div>
    </div>
    <div class="message-panel mt-4 mb-3" *ngIf="singlePlayerRank?.length === 0 && isSinglePlayer">
        <div class="theme-info-i warning">
            <div class="cms-container">
                <h3>
                    <span>{{ item.widgetConfigItem.errorMessage || 'Data not available for' }}</span
                    ><strong> {{ categoryName || 'requested Screen name or Category.' }}</strong>
                </h3>
            </div>
        </div>
    </div>
</div>
<div class="msg-display">&nbsp;</div>
