import { Message } from '../../message';

export class NetRealBalance extends Message {
    private MESSAGE_NAME: string = 'NetRealBalance';
    private accountBalance: number = 0;
    private accountCurrency: string | null = null;
    private cashOutableBalance: number = 0;
    private restrictedBalance: any[] | null = null;
    private netAccountBalance: number = 0;
    private payPalBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        accountBalance: number = 0,
        accountCurrency: string | null = null,
        cashOutableBalance: number = 0,
        restrictedBalance: any[] | null = null,
        netAccountBalance: number = 0,
        payPalBalance: number = 0,
    ) {
        super(reqServerPeerId);
        this.accountBalance = accountBalance;
        this.accountCurrency = accountCurrency;
        this.cashOutableBalance = cashOutableBalance;
        this.restrictedBalance = restrictedBalance;
        this.netAccountBalance = netAccountBalance;
        this.payPalBalance = payPalBalance;
    }

    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getAccountCurrency(): string | null {
        return this.accountCurrency;
    }

    setAccountCurrency(accountCurrency: string | null) {
        this.accountCurrency = accountCurrency;
    }
    getCashOutableBalance(): number {
        return this.cashOutableBalance;
    }

    setCashOutableBalance(cashOutableBalance: number) {
        this.cashOutableBalance = cashOutableBalance;
    }
    getRestrictedBalance(): any[] | null {
        return this.restrictedBalance;
    }

    setRestrictedBalance(restrictedBalance: any[] | null) {
        this.restrictedBalance = restrictedBalance;
    }
    getNetAccountBalance(): number {
        return this.netAccountBalance;
    }

    setNetAccountBalance(netAccountBalance: number) {
        this.netAccountBalance = netAccountBalance;
    }
    getPayPalBalance(): number {
        return this.payPalBalance;
    }

    setPayPalBalance(payPalBalance: number) {
        this.payPalBalance = payPalBalance;
    }
}
