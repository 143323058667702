import { Message } from '../../message';

export class NotifyPrivateTableCreator extends Message {
    private MESSAGE_NAME: string = 'NotifyPrivateTableCreator';
    private tableId: number = 0;
    private invitee: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, invitee: string | null = null) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.invitee = invitee;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getInvitee(): string | null {
        return this.invitee;
    }

    setInvitee(invitee: string | null) {
        this.invitee = invitee;
    }
}
