import { Message } from '../../message';

export class MTCTDoRebuyAddon extends Message {
    private MESSAGE_NAME: string = 'MTCTDoRebuyAddon';
    private addon: boolean | null = false;
    private numberOfAddons: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, addon: boolean | null = false, numberOfAddons: number = 0, conversationId: number = 0) {
        super(reqServerPeerId);
        this.addon = addon;
        this.numberOfAddons = numberOfAddons;
        this.conversationId = conversationId;
    }

    getAddon(): boolean | null {
        return this.addon;
    }

    setAddon(addon: boolean | null) {
        this.addon = addon;
    }
    getNumberOfAddons(): number {
        return this.numberOfAddons;
    }

    setNumberOfAddons(numberOfAddons: number) {
        this.numberOfAddons = numberOfAddons;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
