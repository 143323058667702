import { Message } from '../../message';

export class ResThrowAnimation extends Message {
    private MESSAGE_NAME: string = 'ResThrowAnimation';
    private status: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: boolean | null = false) {
        super(reqServerPeerId);
        this.status = status;
    }

    getStatus(): boolean | null {
        return this.status;
    }

    setStatus(status: boolean | null) {
        this.status = status;
    }
}
