import { Message } from '../../message';

export class AvatarDetails extends Message {
    private MESSAGE_NAME: string = 'AvatarDetails';
    private avatarStatus: number = 0;
    private avatarImageName: string | null = null;
    private avatarImagePath: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, avatarStatus: number = 0, avatarImageName: string | null = null, avatarImagePath: string | null = null) {
        super(reqServerPeerId);
        this.avatarStatus = avatarStatus;
        this.avatarImageName = avatarImageName;
        this.avatarImagePath = avatarImagePath;
    }

    getAvatarStatus(): number {
        return this.avatarStatus;
    }

    setAvatarStatus(avatarStatus: number) {
        this.avatarStatus = avatarStatus;
    }
    getAvatarImageName(): string | null {
        return this.avatarImageName;
    }

    setAvatarImageName(avatarImageName: string | null) {
        this.avatarImageName = avatarImageName;
    }
    getAvatarImagePath(): string | null {
        return this.avatarImagePath;
    }

    setAvatarImagePath(avatarImagePath: string | null) {
        this.avatarImagePath = avatarImagePath;
    }
}
