import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PlainLinkComponent } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { NavigationLayoutPageComponent } from '@frontend/vanilla/features/navigation-layout';
import { NavigationBarComponent, NavigationBarPokerComponent, NavigationBootstrapService, ScrollToTopComponent } from '@pokercore/module/core';

import { HandHistoryComponent } from './hand-history.component';
import { HandHistoryResolver } from './hand-history.resolver';
import { HandHistoryService } from './hand-history.service';
import { HandFiltersComponent } from './hands-mainbody/hand-filters/hand-filters.component';
import { HandsMainbodyComponent } from './hands-mainbody/hands-mainbody.component';
import { GameTableService } from './hands-mainbody/hh-replayer/replayer/game/game-table/game-table.service';
import { GameModule } from './hands-mainbody/hh-replayer/replayer/game/game.module';
import { GameService } from './hands-mainbody/hh-replayer/replayer/services/game.service';
import { TextViewComponent } from './hands-mainbody/text-view/text-view.component';
import { ScrollBarWidthService } from './scroll-bar-width.service';

@NgModule({
    imports: [
        CommonModule,
        PlainLinkComponent,
        FormsModule,
        HttpClientModule,
        GameModule,
        ScrollToTopComponent,
        MessagePanelComponent,
        NavigationLayoutPageComponent,
        NavigationBarComponent,
        NavigationBarPokerComponent,
    ],
    declarations: [HandHistoryComponent, HandFiltersComponent, TextViewComponent, HandsMainbodyComponent],
    exports: [HandHistoryComponent],
})
export class HandHistoryModule {
    public static forRoot(): ModuleWithProviders<HandHistoryModule> {
        return {
            ngModule: HandHistoryModule,
            providers: [HandHistoryResolver, HandHistoryService, GameService, GameTableService, NavigationBootstrapService, ScrollBarWidthService],
        };
    }
}
