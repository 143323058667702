import { Message } from '../../message';

export class ServerUtilityRequest extends Message {
    private MESSAGE_NAME: string = 'ServerUtilityRequest';
    private message: any | null = null;
    private channelIdRequired: boolean | null = false;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, message: any | null = null, channelIdRequired: boolean | null = false, requestType: number = 0) {
        super(reqServerPeerId);
        this.message = message;
        this.channelIdRequired = channelIdRequired;
        this.requestType = requestType;
    }

    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getChannelIdRequired(): boolean | null {
        return this.channelIdRequired;
    }

    setChannelIdRequired(channelIdRequired: boolean | null) {
        this.channelIdRequired = channelIdRequired;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
