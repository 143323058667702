import { Message } from '../../message';
import { LSDealerBlindPositions } from './LSDealerBlindPositions';

export class ResponsePlayerList extends Message {
    private MESSAGE_NAME: string = 'ResponsePlayerList';
    private tableId: number = 0;
    private players: any[] | null = null;
    private dealerBlindPositions: LSDealerBlindPositions | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        players: any[] | null = null,
        dealerBlindPositions: LSDealerBlindPositions | null = null,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.players = players;
        this.dealerBlindPositions = dealerBlindPositions;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getPlayers(): any[] | null {
        return this.players;
    }

    setPlayers(players: any[] | null) {
        this.players = players;
    }
    getDealerBlindPositions(): LSDealerBlindPositions | null {
        return this.dealerBlindPositions;
    }

    setDealerBlindPositions(dealerBlindPositions: LSDealerBlindPositions | null) {
        this.dealerBlindPositions = dealerBlindPositions;
    }
}
