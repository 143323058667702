import { Routes } from '@angular/router';

import { PokerClubGamesSynopsisResolver } from './poker-club-games-synopsis.resolver';
import { PokerClubGamesResolver } from './poker-club-games.resolver';

export const pokerClubGamesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerClubGamesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerClubGamesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerClubGamesResolver,
        },
    },
];
