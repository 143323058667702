import { Message } from '../../message';

export class PAMArticleList extends Message {
    private MESSAGE_NAME: string = 'PAMArticleList';
    private attributeList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, attributeList: any[] | null = null) {
        super(reqServerPeerId);
        this.attributeList = attributeList;
    }

    getAttributeList(): any[] | null {
        return this.attributeList;
    }

    setAttributeList(attributeList: any[] | null) {
        this.attributeList = attributeList;
    }
}
