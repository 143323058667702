import { Routes } from '@angular/router';

import { PokerFootballPartySynopsisResolver } from './poker-football-party-synopsis.resolver';
import { PokerFootballPartyResolver } from './poker-football-party.resolver';

export const pokerFootballPartyRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerFootballPartyResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerFootballPartySynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerFootballPartyResolver,
        },
    },
];
