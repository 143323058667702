import { Message } from '../../message';

export class BigWinNotification extends Message {
    private MESSAGE_NAME: string = 'BigWinNotification';
    private currenctBalance: number = 0;
    private winAmount: number = 0;
    private currency: string | null = null;
    private thresholdValue: number = 0;
    private bigWinTrackingId: number = 0;
    private transactionTime: any | null = null;
    private locationEvent: string | null = null;
    private positionEvent: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        currenctBalance: number = 0,
        winAmount: number = 0,
        currency: string | null = null,
        thresholdValue: number = 0,
        bigWinTrackingId: number = 0,
        transactionTime: any | null = null,
        locationEvent: string | null = null,
        positionEvent: string | null = null,
    ) {
        super(reqServerPeerId);
        this.currenctBalance = currenctBalance;
        this.winAmount = winAmount;
        this.currency = currency;
        this.thresholdValue = thresholdValue;
        this.bigWinTrackingId = bigWinTrackingId;
        this.transactionTime = transactionTime;
        this.locationEvent = locationEvent;
        this.positionEvent = positionEvent;
    }

    getCurrenctBalance(): number {
        return this.currenctBalance;
    }

    setCurrenctBalance(currenctBalance: number) {
        this.currenctBalance = currenctBalance;
    }
    getWinAmount(): number {
        return this.winAmount;
    }

    setWinAmount(winAmount: number) {
        this.winAmount = winAmount;
    }
    getCurrency(): string | null {
        return this.currency;
    }

    setCurrency(currency: string | null) {
        this.currency = currency;
    }
    getThresholdValue(): number {
        return this.thresholdValue;
    }

    setThresholdValue(thresholdValue: number) {
        this.thresholdValue = thresholdValue;
    }
    getBigWinTrackingId(): number {
        return this.bigWinTrackingId;
    }

    setBigWinTrackingId(bigWinTrackingId: number) {
        this.bigWinTrackingId = bigWinTrackingId;
    }
    getTransactionTime(): any | null {
        return this.transactionTime;
    }

    setTransactionTime(transactionTime: any | null) {
        this.transactionTime = transactionTime;
    }
    getLocationEvent(): string | null {
        return this.locationEvent;
    }

    setLocationEvent(locationEvent: string | null) {
        this.locationEvent = locationEvent;
    }
    getPositionEvent(): string | null {
        return this.positionEvent;
    }

    setPositionEvent(positionEvent: string | null) {
        this.positionEvent = positionEvent;
    }
}
