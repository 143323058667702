import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { HandHistoryClientConfig, PokerHomePageClientConfig, PromoHubClientConfig, VCClientService } from '@pokercore/module/core';

import { HandHistoryInputModel } from './hand-history.models';
import { HandHistoryService } from './hand-history.service';
import { HandFiltersComponent } from './hands-mainbody/hand-filters/hand-filters.component';
import { HandsMainbodyComponent } from './hands-mainbody/hands-mainbody.component';
import { ScrollBarWidthService } from './scroll-bar-width.service';

@Component({
    selector: 'pk-hand-history',
    templateUrl: 'hand-history.component.html',
})
export class HandHistoryComponent implements OnInit {
    showFilters = true;
    filterBtnTxt: string;
    windowWidth: number;
    filterHangup: boolean = false;
    toogleFilterBtn: string;
    windowScrollTop: number = 0;
    prevScrollTop: number = 0;
    searchByFilterId: boolean = false;
    updateDate: boolean = false;
    updateFltrBtn: string;
    deviceType: string = '';
    prevShowFilters: boolean;
    isOnInit: any;
    isV2AccountMenu: boolean;
    disableHtmlHeadTag: boolean = false;
    showConfigureNavigation: boolean = false;

    @Input() initData: any;

    @ViewChild(HandFiltersComponent) handFilter: any;
    @ViewChild(HandsMainbodyComponent) handMainbody: any;
    @ViewChild('pkHandsMainbody', { read: ElementRef }) pkHandsMainbody?: ElementRef;

    constructor(
        public serve: HandHistoryService,
        private vCClientService: VCClientService,
        private scrollBarWidthService: ScrollBarWidthService,
        private promoHubClientConfig: PromoHubClientConfig,
        private handHistoryClientConfig: HandHistoryClientConfig,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private routeData: RouteDataService,
    ) {
        this.windowWidth = document.documentElement.clientWidth;
    }

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.initData = this.routeData.getInitData();
        if (!this.initData.handHistoryContent) {
            this.dataResolve().subscribe({
                next: (data: any) => {
                    this.initData = data;
                    this.initMethod();
                },
                error: (error: any) => {
                    console.error('Error fetching data:', error);
                },
                complete: () => {
                    //  console.log("Data fetching completed successfully");
                },
            });
        } else {
            this.initMethod();
        }

        this.initData.gameRoundMetaData = this.initData.gameRoundMetaDetails.gameRoundMetaData;
        this.serve.handHistory = this.initData.gameRoundMetaData;
        this.serve.serverErrorpage = this.initData.showErrorPage;

        if (this.serve.handHistory.gameRounds.length < 100) {
            this.serve.nodataCG = true;
        }
        this.serve.groupRounds(this.initData.gameRoundMetaData, 'cashgames');
        //this.serve.totalCount = this.serve.getTodayHands(this.serve.handHistory);
        this.prepareHandHistoryChildData(this.initData.handHistoryContentChildren);
        this.serve.cashGames = this.serve.handHistory;
        this.toogleFilterBtn = this.serve.content.messages.Filters;
        this.filterBtnTxt = this.serve.content.messages.HideFilters;
        // this.serve.getTournaments();
        this.getDeviceType();
        this.isOnInit = true;
    }

    initMethod() {
        this.serve.content = this.initData.handHistoryContent;
        this.isV2AccountMenu = this.promoHubClientConfig.isV2AccountMenu;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;
        this.initData.gameRoundMetaData = this.initData.gameRoundMetaDetails.gameRoundMetaData;
        this.serve.handHistory = this.initData.gameRoundMetaData;
        this.serve.serverErrorpage = this.initData.showErrorPage;

        if (this.serve.handHistory.gameRounds.length < 100) {
            this.serve.nodataCG = true;
        }
        this.serve.groupRounds(this.initData.gameRoundMetaData, 'cashgames');
        //this.serve.totalCount = this.serve.getTodayHands(this.serve.handHistory);
        this.prepareHandHistoryChildData(this.initData.handHistoryContentChildren);
        this.serve.cashGames = this.serve.handHistory;
        this.toogleFilterBtn = this.serve.content.messages.Filters;
        this.filterBtnTxt = this.serve.content.messages.HideFilters;
        // this.serve.getTournaments();
        this.getDeviceType();
        this.isOnInit = true;
    }

    dataResolve() {
        let handHistoryInputModel: HandHistoryInputModel = new HandHistoryInputModel();
        handHistoryInputModel.GameType = 1;
        handHistoryInputModel.GameCodeType = 1;
        handHistoryInputModel.PocketCardOne = 'XX';
        handHistoryInputModel.PocketCardTwo = 'XX';
        handHistoryInputModel.PocketCardSuited = false;
        handHistoryInputModel.FinalHand = 0;
        handHistoryInputModel.FinalHandMatch = 0;
        handHistoryInputModel.MinPotSize = 0;
        handHistoryInputModel.LimitType = 0;
        handHistoryInputModel.ShowHand = false;
        return this.serve.getHandHistory(handHistoryInputModel);
    }

    prepareHandHistoryChildData(handHistoryContentChildren: any) {
        const obj: any = {};
        for (let i = 0; i < handHistoryContentChildren.length; i++) {
            const childVal = handHistoryContentChildren[i];
            const childObj = {};
            childObj['id'] = childVal.metadata.id.itemName;
            childObj['values'] = childVal.values;
            obj[childObj['id']] = childObj;
        }
        this.serve.content.form = obj;
    }

    checkForClient() {
        const isClient = this.vCClientService.isClient(),
            isMobileApp = this.vCClientService.isMobileApp();
        if (isClient && !isMobileApp) {
            const filterBtn: any = document.querySelector('.filter_btn.apply-filters');
            filterBtn.click();
        }
    }

    ngAfterViewInit() {
        this.filterBtnStatic();
        this.checkForClient();
    }

    // ngAfterViewChecked() {
    // 	this.serve.handsAfterViewchecked = document.getElementsByClassName('hands-details').length;
    // }

    toggleFilters() {
        //let getScrollbarWidth = require('get-scrollbar-width');
        const currentBrowserScrollbarWidth = this.scrollBarWidthService.getScrollBarWidth(); // getScrollbarWidth();
        this.windowWidth = document.documentElement.clientWidth + currentBrowserScrollbarWidth;
        if (this.windowWidth < 1366 && this.windowWidth >= 768) {
            if (this.showFilters == true) {
                this.showFilters = false;
                this.filterBtnTxt = this.serve.content.messages.Filters;
            } else {
                this.showFilters = true;
                this.filterBtnTxt = this.serve.content.messages.HideFilters;
            }
            this.prevShowFilters = this.showFilters;
        } else if (this.windowWidth < 768) {
            const handHistory: any = document.querySelector('.hands-history');
            window.scroll(0, 0);
            if (handHistory.className.indexOf('hide-hands') <= -1) {
                handHistory.className += ' hide-hands';
                this.toogleFilterBtn = this.serve.content.messages.ApplyFilters;
            } else {
                if (this.handFilter.roundId.length > 0) {
                    this.serve.singleHand = true;
                    this.searchByRoundId(this.handFilter.roundId);
                } else {
                    this.handFilter.applyFilters(true, false, 'load');
                }
            }
        }
    }

    filterBtnStatic() {
        const windowHeight = window.innerHeight;
        if (this.pkHandsMainbody != undefined) this.pkHandsMainbody.nativeElement.style.minHeight = windowHeight + 'px';
    }

    getDeviceType() {
        if (navigator && navigator.appVersion) {
            const appVersion = navigator.appVersion;
            if (appVersion.indexOf('iPhone') > -1 || appVersion.indexOf('Mac') > -1 || appVersion.indexOf('iPad') > -1) {
                this.deviceType = 'apple-device';
                this.serve.Idevices = true;
            }
            const userAgent: any = navigator.userAgent;
            if (
                (userAgent.match(/(iPhone|iPad|iPod)/) || userAgent.match(/BlackBerry/) || userAgent.match(/Android/)) &&
                this.handHistoryClientConfig.whenReady.subscribe(() => this.handHistoryClientConfig.isPotraiteReplayer)
            ) {
                this.deviceType = 'mobile';
                this.serve.isMobile = true;
            } else if (
                userAgent.match(/Macintosh/) !== null &&
                navigator.maxTouchPoints > 2 &&
                this.handHistoryClientConfig.whenReady.subscribe(() => this.handHistoryClientConfig.isPotraiteReplayer)
            ) {
                this.serve.isMobile = Object.values(userAgent.match(/Macintosh/))[0] === 'Macintosh' && navigator.maxTouchPoints > 2;
                this.deviceType = 'mobile';
                // this.serve.isMobile = true;
            } else {
                this.deviceType = 'desktop';
                this.serve.isMobile = false;
            }
        }
    }

    showMoreHands(loadingPoint: number) {
        const mainbody: any = document.getElementsByClassName('hh-mainbody')[0];
        let headerHeight = 0,
            handsEndPoint;
        if (document.getElementsByTagName('header')[0]) {
            headerHeight = document.getElementsByTagName('header')[0].clientHeight;
        }
        handsEndPoint = headerHeight + mainbody.offsetTop + mainbody.clientHeight;
        if (this.serve.hhGames === 'cashgames') {
            if (loadingPoint >= handsEndPoint && !this.serve.lazyloading && !this.serve.singleHand) {
                if (this.serve.cashGames.gameRounds.length > this.serve.pageCG * 100 + 50 && !this.serve.lazyLoad) {
                    this.serve.lazyLoad = true;
                    this.serve.groupRounds(this.serve.cashGames, 'cashgames');
                } else if (
                    this.serve.cashGames.gameRounds.length >= this.serve.pageCG * 100 + 100 ||
                    this.serve.cashGames.totalCount > this.serve.cashGames.gameRounds.length
                ) {
                    this.serve.lazyloading = true;
                    this.handFilter.applyFilters(false, true, 'load');
                }
            }
        } else {
            if (loadingPoint >= handsEndPoint && !this.serve.lazyloading && !this.serve.singleHand) {
                if (this.serve.tournaments.gameRounds.length > this.serve.pageTR * 100 + 50 && !this.serve.lazyLoad) {
                    this.serve.lazyLoad = true;
                    this.serve.groupRounds(this.serve.tournaments, 'tournaments');
                } else if (
                    this.serve.tournaments.gameRounds.length >= this.serve.pageTR * 100 + 100 ||
                    this.serve.tournaments.totalCount > this.serve.tournaments.gameRounds.length
                ) {
                    this.serve.lazyloading = true;
                    this.handFilter.applyFilters(false, true, 'load');
                }
            }
        }
        window.scroll(0, this.prevScrollTop);
    }

    searchByRoundId(roundId: any) {
        this.handMainbody.getTextViewPopup(roundId, false);
    }

    updateFilterBtn(text: string) {
        this.toogleFilterBtn = text;
    }

    updateDateContent(dateValue: string) {
        const hhType = this.serve.hhGames;
        if (dateValue === this.serve.content.form.searchdates.values[1].text) {
            if (hhType === 'cashgames') {
                this.handMainbody.toggleDateCG = true;
            } else {
                this.handMainbody.toggleDateTR = true;
            }
        } else {
            if (hhType === 'cashgames') {
                this.handMainbody.toggleDateCG = false;
            } else {
                this.handMainbody.toggleDateTR = false;
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    windowScroll(event: any) {
        let pageYOffset: any = window.pageYOffset;
        if (this.isOnInit) {
            pageYOffset = 0;
            setTimeout(() => {
                this.isOnInit = false;
            }, 300);
        }
        const windowScrollTop = pageYOffset;
        const windowHeight = document.documentElement.clientHeight;
        let mainbodyHeight = document.getElementsByClassName('hand-history-mainbody')[0].clientHeight,
            headerHeight = 0;
        const hideHands = document.querySelector('.hands-history.hide-hands');
        const winWidth = window.innerWidth;
        if (document.getElementsByTagName('header')[0]) {
            headerHeight = document.getElementsByTagName('header')[0].clientHeight;
        }
        if (windowHeight + windowScrollTop > headerHeight + mainbodyHeight) {
            this.filterHangup = true;
        } else {
            this.filterHangup = false;
        }

        if (
            this.windowScrollTop <= windowScrollTop &&
            ((!hideHands && winWidth < 768) || (hideHands && winWidth >= 768) || (!hideHands && winWidth >= 768))
        ) {
            this.prevScrollTop = windowScrollTop;
            this.showMoreHands(windowScrollTop + windowHeight);
        }
        this.windowScrollTop = windowScrollTop;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    postApplyFilters(event: any) {
        const handHistory: any = document.querySelector('.hands-history');
        handHistory.classList.remove('hide-hands');
        this.toogleFilterBtn = this.serve.content.messages.Filters;
        this.handMainbody.activeRoundId = undefined;
        this.filterBtnStatic();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        const innerWidth = event.target.innerWidth;
        this.windowWidth = innerWidth;
        if (navigator.userAgent.indexOf('iPad') <= -1) {
            if (innerWidth >= 1366) {
                this.showFilters = false;
                this.filterBtnTxt = this.serve.content.messages.Filters;
            } else {
                this.showFilters = this.prevShowFilters;
                if (this.showFilters) {
                    this.filterBtnTxt = this.serve.content.messages.HideFilters;
                } else {
                    this.filterBtnTxt = this.serve.content.messages.Filters;
                }
            }
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: any) {
        if (!this.handFilter.loadingMask) {
            this.windowScroll(event);
        }
    }
}
