import { Message } from '../../message';

export class RequestSatellitesData extends Message {
    private MESSAGE_NAME: string = 'RequestSatellitesData';
    private mtctId: number = 0;
    private satelliteMTTCTIds: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, satelliteMTTCTIds: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.satelliteMTTCTIds = satelliteMTTCTIds;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getSatelliteMTTCTIds(): any[] | null {
        return this.satelliteMTTCTIds;
    }

    setSatelliteMTTCTIds(satelliteMTTCTIds: any[] | null) {
        this.satelliteMTTCTIds = satelliteMTTCTIds;
    }
}
