<pk-navigation-bar-poker *ngIf="!isPartyNavigation"></pk-navigation-bar-poker>
<pk-navigation-bar *ngIf="isPartyNavigation"></pk-navigation-bar>
<div>
    <h1 class="tmt-title" *ngIf="initData && initData.pmPokerNavigationView && initData.pmPokerNavigationView.title">
        {{ initData.pmPokerNavigationView.title }}
    </h1>
</div>

<div class="tournamets-calendar tmt-desktop">
    <div class="tournaments-section" *ngIf="initData">
        <div class="left-sec">
            <pt-tournament-calendar-filters></pt-tournament-calendar-filters>
        </div>
        <div class="right-sec" *ngIf="initData">
            <div class="right-sec-container">
                <div *ngIf="!initData.backendFailure && filteredTournamentList && filteredTournamentList.length === 0" class="error-info">
                    {{ initData.pmPokerNavigationView.messages['notournamentsfound'] }}
                </div>
                <div class="right-sec-content">
                    <div *ngIf="!initData.backendFailure && filteredTournamentList && filteredTournamentList.length > 0" class="tmt-heading">
                        <div class="tmt-info tmt-name">{{ initData.pmPokerNavigationView.messages['text-tournaments'] }}</div>
                        <div class="tmt-info tmt-limit">{{ initData.pmPokerNavigationView.messages['text-limit'] }}</div>
                        <div class="tmt-info tmt-buyin">{{ initData.pmPokerNavigationView.messages['text-buyin'] }}</div>
                        <div class="tmt-info tmt-players">{{ initData.pmPokerNavigationView.messages['text-players'] }}</div>
                        <div class="tmt-info tmt-start">{{ initData.pmPokerNavigationView.messages['text-start'] }}</div>
                        <div class="tmt-info tmt-game">{{ initData.pmPokerNavigationView.messages['text-gametype'] }}</div>
                        <div class="tmt-info tmt-btn-add">&nbsp;</div>
                        <div class="tmt-info tmt-btn-more">&nbsp;</div>
                    </div>
                    <div *ngIf="initData.backendFailure" class="tmt-content error-info">
                        {{ initData.pmPokerNavigationView.messages['BackendFailedMessage'] }}
                    </div>
                    <div *ngIf="!initData.backendFailure && filteredTournamentList && filteredTournamentList.length > 0">
                        <div class="tmt-content" *ngFor="let tournament of filteredTournamentList; let tournamentIndex = index">
                            <div class="tmt-info tmt-name">{{ tournament.name | removeColorCodesPipe }}</div>
                            <div class="tmt-info tmt-limit">{{ tournament.limitType | limitNamePipe }}</div>
                            <div class="tmt-info tmt-buyin">
                                {{ tournament.buyIn.monetaryType | formatCurrencyPipe: tournament.buyIn.unit }} +
                                {{ tournament.fee.monetaryType | formatCurrencyPipe: tournament.fee.unit }}
                            </div>
                            <div class="tmt-info tmt-players">{{ tournament.registeredPlayers }}</div>
                            <div class="tmt-info tmt-start">
                                <pt-tournament-calendar-timer [endTime]="tournament.startDate"> </pt-tournament-calendar-timer>
                            </div>
                            <div class="tmt-info tmt-game">
                                <span class="tmt-game-info" [ngClass]="'tmt-' + tournament.gameType | lowercase">{{
                                    tournament.gameType | gameNamePipe
                                }}</span>
                            </div>
                            <div class="tmt-info tmt-btn-add">
                                <span
                                    class="btn btn-secondary btn-light btn-block"
                                    [ngClass]="{ 'add-to-calendar-close': tournament.isClose }"
                                    (click)="action($event, 'menu-items', tournamentIndex, tournament)"
                                    id="add-to-cal{{ tournamentIndex }}"
                                    >{{ initData.pmPokerNavigationView.messages['AddToCalendar'] }}</span
                                >
                            </div>
                            <div class="tmt-info tmt-btn-more" (click)="handleTournamentClick(tournament.id, true)">
                                <span class="btn btn-primary">{{ initData.pmPokerNavigationView.messages['text-more-info'] }}</span>
                            </div>
                            <div class="menu-items" id="menu-items{{ tournamentIndex }}">
                                <div class="hint theme-up"></div>
                                <div class="clear-fix social-links">
                                    <a
                                        [href]="googleCalendarHost + tournament.googleCalendarUrlSegment"
                                        (click)="calenderClicked('google')"
                                        target="_blank"
                                        >{{ initData.pmPokerNavigationView.messages['AddToCalendarGoogle'] }}<span class="theme-right"></span
                                    ></a>
                                </div>
                                <div class="social-links">
                                    <a [href]="msnCalendarHost + tournament.msnCalendarUrlSegment" (click)="calenderClicked('msn')" target="_blank"
                                        >{{ initData.pmPokerNavigationView.messages['AddToCalendarMsn'] }}<span class="theme-right"></span
                                    ></a>
                                </div>
                                <div class="social-links yahoo-cls">
                                    <a
                                        [href]="yahooCalendarHost + tournament.yahooCalendarUrlSegment"
                                        (click)="calenderClicked('yahoo')"
                                        target="_blank"
                                        >{{ initData.pmPokerNavigationView.messages['AddToCalendarYahoo'] }}<span class="theme-right"></span
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pager-sec" *ngIf="!hidePagination && pager && pager.pages && pager.pages.length > 1 && totalTournaments !== 0">
                <ul class="pagination">
                    <li class="previous" *ngIf="pager.currentPage > 1" [ngClass]="{ disabled: pager.currentPage === 1 }">
                        <a (click)="setTournamentCalendarPager(pager.currentPage - 1)">
                            <i class="theme-left"></i>
                        </a>
                    </li>
                    <li *ngFor="let page of pager.pages" [ngClass]="{ active: pager.currentPage === page }">
                        <div *ngIf="page !== 999; then content; else elseContent"></div>
                        <ng-template #content
                            ><a (click)="setTournamentCalendarPager(page)"> {{ page }} </a></ng-template
                        >
                        <ng-template #elseContent><a> ... </a></ng-template>
                    </li>
                    <li class="next" *ngIf="pager.currentPage < pager.totalPages" [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                        <a *ngIf="pager.currentPage !== pager.totalPages" (click)="setTournamentCalendarPager(pager.currentPage + 1)">
                            <i class="theme-right"></i>
                        </a>
                    </li>
                </ul>
                <ul class="pagination" *ngIf="totalTournaments !== 0 && pager.pages && pager.pages.length > 1">
                    <p>
                        {{ this.startIndex }} - {{ this.endIndex }} {{ initData.pmPokerNavigationView.messages['text-of'] }}
                        {{ this.totalTournaments }} {{ initData.pmPokerNavigationView.messages['text-tournaments'] }}
                    </p>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="tournamets-calendar tmt-mobile" *ngIf="initData">
    <div class="tournaments-section">
        <div class="left-sec">
            <pt-tournament-calendar-filters></pt-tournament-calendar-filters>
        </div>
        <div class="right-sec">
            <div class="right-sec-container" *ngIf="initData.backendFailure">
                <div class="alert alert-danger" role="alert">
                    {{ initData.pmPokerNavigationView.messages['BackendFailedMessage'] }}
                </div>
            </div>
            <div class="right-sec-container" *ngIf="!initData.backendFailure && filteredTournamentList && filteredTournamentList.length === 0">
                <div class="alert alert-info" role="alert">
                    {{ initData.pmPokerNavigationView.messages['notournamentsfound'] }}
                </div>
            </div>
            <div *ngIf="!initData.backendFailure && filteredTournamentList && filteredTournamentList.length > 0">
                <div class="right-sec-content" *ngFor="let tournament of filteredTournamentList; let tournamentIndex = index">
                    <div class="tmt-content">
                        <div class="tmt-info tmt-name">{{ tournament.name | removeColorCodesPipe }}</div>
                        <div class="tmt-info tmt-game-sec">
                            <span class="tmt-game-info" [ngClass]="'tmt-' + tournament.gameType | lowercase">{{
                                tournament.gameType | gameNamePipe
                            }}</span>
                        </div>
                    </div>
                    <div class="tmt-content clear-fix">
                        <div class="left-part">
                            <div class="tmt-info tmt-limit">
                                <div class="tmt-info-heading">{{ initData.pmPokerNavigationView.messages['text-limit'] }}</div>
                                <div>{{ tournament.limitType | limitNamePipe }}</div>
                            </div>
                            <div class="tmt-info tmt-buyin">
                                <div class="tmt-info-heading">{{ initData.pmPokerNavigationView.messages['text-buyin'] }}</div>
                                <div class="tmt-buyin-info">
                                    {{ tournament.buyIn.monetaryType | formatCurrencyPipe: tournament.buyIn.unit }} +
                                    {{ tournament.fee.monetaryType | formatCurrencyPipe: tournament.fee.unit }}
                                </div>
                            </div>
                            <div class="tmt-info tmt-players">
                                <div class="tmt-info-heading">
                                    {{ initData.pmPokerNavigationView.messages['text-players'] }}
                                </div>
                                <div>{{ tournament.registeredPlayers }}</div>
                            </div>
                            <div class="tmt-info tmt-star">
                                <div class="tmt-info-heading">{{ initData.pmPokerNavigationView.messages['text-start'] }}</div>
                                <div>
                                    <pt-tournament-calendar-timer [endTime]="tournament.startDate"> </pt-tournament-calendar-timer>
                                </div>
                            </div>
                        </div>
                        <div class="right-part">
                            <div class="tmt-info tmt-btn-more" (click)="handleTournamentClick(tournament.id, true)">
                                <span class="btn btn-primary">{{ initData.pmPokerNavigationView.messages['text-more-info'] }}</span>
                            </div>
                            <div class="tmt-info tmt-btn-add">
                                <span
                                    class="btn btn-secondary btn-light btn-block"
                                    [ngClass]="{ 'add-to-calendar-close': tournament.isClose }"
                                    (click)="action($event, 'menu-items-mobile', tournamentIndex, tournament)"
                                    id="add-to-cal-mob{{ tournamentIndex }}"
                                    >{{ initData.pmPokerNavigationView.messages['AddToCalendar'] }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="transparent-div"
                            id="transparent-div{{ tournamentIndex }}"
                            (click)="closeMenu($event, 'menu-items-mobile', tournamentIndex)"></div>
                        <div class="menu-items-mobile" id="menu-items-mobile{{ tournamentIndex }}">
                            <div class="hint theme-up"></div>
                            <div class="clear-fix social-links">
                                <a
                                    [href]="googleCalendarHost + tournament.googleCalendarUrlSegment"
                                    (click)="calenderClicked('google')"
                                    target="_blank"
                                    >{{ initData.pmPokerNavigationView.messages['AddToCalendarGoogle'] }}<span class="theme-right"></span
                                ></a>
                            </div>
                            <div class="social-links">
                                <a [href]="msnCalendarHost + tournament.msnCalendarUrlSegment" target="_blank" (click)="calenderClicked('msn')"
                                    >{{ initData.pmPokerNavigationView.messages['AddToCalendarMsn'] }}<span class="theme-right"></span
                                ></a>
                            </div>
                            <div class="social-links yahoo-cls">
                                <a [href]="yahooCalendarHost + tournament.yahooCalendarUrlSegment" (click)="calenderClicked('yahoo')" target="_blank"
                                    >{{ initData.pmPokerNavigationView.messages['AddToCalendarYahoo'] }}<span class="theme-right"></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pager-sec" *ngIf="pager && pager.pages">
                <ul class="pagination" *ngIf="!hidePagination && pager && pager.pages && pager.pages.length > 1 && totalTournaments !== 0">
                    <li class="previous" [ngClass]="{ disabled: pager.currentPage === 1 }">
                        <a (click)="setTournamentCalendarPager(pager.currentPage - 1)">
                            <i class="theme-left"></i>
                        </a>
                    </li>
                    <li *ngFor="let page of pager.pages" [ngClass]="{ active: pager.currentPage === page }">
                        <div *ngIf="page !== 999; then content; else elseContent"></div>
                        <ng-template #content
                            ><a (click)="setTournamentCalendarPager(page)"> {{ page }} </a></ng-template
                        >
                        <ng-template #elseContent><a> ... </a></ng-template>
                    </li>
                    <li class="next" [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                        <a *ngIf="pager.currentPage !== pager.totalPages" (click)="setTournamentCalendarPager(pager.currentPage + 1)">
                            <i class="theme-right"></i>
                        </a>
                    </li>
                </ul>
                <ul class="pagination" *ngIf="totalTournaments !== 0 && pager.pages && pager.pages.length > 1">
                    <p>
                        {{ this.startIndex }} - {{ this.endIndex }} {{ initData.pmPokerNavigationView.messages['text-of'] }}
                        {{ this.totalTournaments }} {{ initData.pmPokerNavigationView.messages['text-tournaments'] }}
                    </p>
                </ul>
            </div>
        </div>
    </div>
    <div class="filters-btn" (click)="showFilters()">
        <span class="theme-filters"></span>{{ initData.pmPokerNavigationView.messages['text-filters']
        }}<span [ngClass]="EnableAndDisable ? 'filter-on' : ''"></span>
    </div>
    <div #filtersInfo id="filters-info" class="filters-info">
        <div class="left-sec" *ngIf="filterDisplayStatus">
            <pt-tournament-calendar-filters (onFilterClose)="hideFilters($event)"></pt-tournament-calendar-filters>
        </div>
    </div>
</div>
<div class="clear-fix"></div>
<scroll-to-top *ngIf="disableHtmlHeadTag"></scroll-to-top>
