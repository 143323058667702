import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PCComponent } from '@frontend/vanilla/features/content';
import { PokerApiService } from '@pokercore/module/core';

import { PokerPromotionsSynopsisPublicPageComponent } from './poker-promotions-synopsis.publicpage.component';
import { PokerPromotionsPublicPageComponent } from './poker-promotions.publicpage.component';

@Component({
    selector: 'pk-poker-promotions-generic',
    templateUrl: 'poker-promotions-generic.html',
    standalone: true,
    imports: [CommonModule, RouterModule, PokerPromotionsPublicPageComponent, PokerPromotionsSynopsisPublicPageComponent],
})
export class PokerPromotionsGenericComponent extends PCComponent implements OnInit {
    initData: any;
    showConfigureNavigation: boolean;
    errorMessage: any;

    @HostBinding() get class() {
        return this.createClass(this.item.name);
    }

    constructor(private api: PokerApiService) {
        super();
    }

    ngOnInit(): void {
        //this.initData = this.routeData.getInitData();
        if (!this.item.isPokerPromotionSynopsis && this.item.sideNavigation && this.item.sideNavigation.relativeUri) {
            this.getSideNavData(this.item.sideNavigation.relativeUri);
        }
        this.initData = this.item;
    }

    getSideNavData(sideNavPath: string) {
        this.api.get('PokerPromotion/GetSideNavigation', { sideNavigationPath: sideNavPath }).subscribe(
            (response: any) => {
                this.item.menuContent = response;
                this.initData = Object.assign({}, this.item);
            },
            (error: any) => {
                this.errorMessage = error;
                //console.log(error);
            },
        );
    }
}
