import { Message } from '../../message';

export class DisconnectedPlayersList extends Message {
    private MESSAGE_NAME: string = 'DisconnectedPlayersList';
    private disConnectedUsersSeatNos: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, disConnectedUsersSeatNos: any[] | null = null) {
        super(reqServerPeerId);
        this.disConnectedUsersSeatNos = disConnectedUsersSeatNos;
    }

    getDisConnectedUsersSeatNos(): any[] | null {
        return this.disConnectedUsersSeatNos;
    }

    setDisConnectedUsersSeatNos(disConnectedUsersSeatNos: any[] | null) {
        this.disConnectedUsersSeatNos = disConnectedUsersSeatNos;
    }
}
