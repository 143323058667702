import { Message } from '../../message';

export class PromoWinnerPlayerList extends Message {
    private MESSAGE_NAME: string = 'PromoWinnerPlayerList';
    private promoWinnerPlayerScreenNames: any[] | null = null;
    private promoWinnerPlayerFullNames: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, promoWinnerPlayerScreenNames: any[] | null = null, promoWinnerPlayerFullNames: any[] | null = null) {
        super(reqServerPeerId);
        this.promoWinnerPlayerScreenNames = promoWinnerPlayerScreenNames;
        this.promoWinnerPlayerFullNames = promoWinnerPlayerFullNames;
    }

    getPromoWinnerPlayerScreenNames(): any[] | null {
        return this.promoWinnerPlayerScreenNames;
    }

    setPromoWinnerPlayerScreenNames(promoWinnerPlayerScreenNames: any[] | null) {
        this.promoWinnerPlayerScreenNames = promoWinnerPlayerScreenNames;
    }
    getPromoWinnerPlayerFullNames(): any[] | null {
        return this.promoWinnerPlayerFullNames;
    }

    setPromoWinnerPlayerFullNames(promoWinnerPlayerFullNames: any[] | null) {
        this.promoWinnerPlayerFullNames = promoWinnerPlayerFullNames;
    }
}
