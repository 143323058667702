<float-ui-content class="ngxp__tooltip" [ngClass]="cssClass">
    @if (closeType === 'button') {
        <span class="ui-icon ui-close theme-ex" (click)="close()"></span>
    }
    <div [ngClass]="cssTextClass">
        <ng-content select="[content]" />
    </div>
    <div class="popper-buttons">
        <ng-content select="[buttons]" />
        @if (closeType === 'link') {
            <div class="ngxp__close-link">
                <span class="popper-close-link dh" (click)="close()">
                    {{ closeLinkText || commonMessages?.PopoverCloseText }}
                </span>
            </div>
        }
    </div>
</float-ui-content>
