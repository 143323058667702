/* eslint-disable @typescript-eslint/no-unused-vars */
import { PGNotificationCenter } from './notificationCenter';

// export function MessageObserver(notificationName: string, classId: number, peerId: number = 0) {
//     return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
//         console.log(descriptor);
//         const originalMethod = descriptor.value;

//         const observerKey = `${notificationName}:${classId}:${peerId}`;
//         PGNotificationCenter.getInstance().addListener(observerKey, originalMethod);

//         descriptor.value = function (message: Message) {
//             originalMethod.call(this, message, classId, peerId);
//         };
//     };
// }

const NotificationMethods = Symbol('NotificationMethods');

//Method Decorator
export function MessageListener(notificationName: string, classId?: number) {
    // peerId: number
    const observerKey = `${notificationName}`; //${classId} // ${peerId}
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        target[NotificationMethods] = target[NotificationMethods] || new Map();
        const originalMethod = descriptor.value;
        descriptor.value = function (this: any, messageObject: object, ...args: any[]) {
            originalMethod.apply(this, [messageObject, ...args]);
        };
        target[NotificationMethods].set(propertyKey, observerKey);
    };
}

//Method Decorator For Gametable
// export function GTMessageListener(notificationName: string, classId?: number) {
//     const observerKey = `${notificationName}`; //${classId}
//     return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
//         target[NotificationMethods] = target[NotificationMethods] || new Map();
//         const originalMethod = descriptor.value;
//         descriptor.value = function (this: any, messageObject: object, ...args: any[]) {
//             originalMethod.apply(this, [messageObject, ...args]);
//         };
//         target[NotificationMethods].set(propertyKey, observerKey);
//     };
// }

//class Decorator
export function ClassMessageListener<T extends { new (...args: any[]): {} }>(Base: T) {
    return class extends Base {
        constructor(...args: any[]) {
            super(...args);

            const notificationMethods = Base.prototype[NotificationMethods];
            if (notificationMethods) {
                notificationMethods.forEach((notificationName: string, method: string) => {
                    PGNotificationCenter.getInstance().addListener(notificationName, (messageObject: object) => (this as any)[method](messageObject));
                });
            }
        }
    };
}
