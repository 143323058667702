import { Card } from '../models/card';
import { Chip } from '../models/chip';
import { Dealer } from '../models/dealer';
import { RitWinHigh } from '../models/events/Rit-Win-High';
import { EventFactory } from '../models/events/event-factory';
import { PotCreated } from '../models/events/pot-created';
import { WinHigh } from '../models/events/win-high';
import { GameTable } from '../models/game-table';
import { Player } from '../models/player';
import { Point } from '../models/point';
import { Properties } from './properties';

export class HHParser {
    static gameName: any;
    static parse(
        hh: any,
        prop: any,
        content: any,
        isOnInit: any,
        isMobile: any,
        isReplayerV3: boolean,
        selfPlayerName: any,
        playerNames: any,
    ): GameTable | void {
        Properties.seatMap.clear();
        Properties.messages = content;
        Properties.isMobile = isMobile;
        Properties.isReplayerV3 = isReplayerV3;
        const gt = new GameTable();
        gt.isMobile = isMobile;
        gt.isReplayerV3 = isReplayerV3;
        const tableCards = new Set();
        const ritFlopCards = new Set();
        // eslint-disable-next-line @typescript-eslint/no-array-constructor
        const ritEvents = new Array();
        let ritCommunityCardEventCreated: any = false;
        const playerVsWinAmout = new Map<number, number>();
        let innerMap: any = new Map();
        let numberOfPots = 0;
        let noOfFoldedEvents: any = 0;
        let OmahaGameType: any;
        let sevenCardCommunityPosition: Point;
        let communityCardNumber: any = 0;
        // eslint-disable-next-line @typescript-eslint/no-array-constructor
        let sdPots = new Array();
        // eslint-disable-next-line @typescript-eslint/no-array-constructor
        const potSequence = new Array();
        let uncalledPlayerAmount: any = 0;
        let reqPlayerSeatno: any = 0;
        if (hh.gameRound) {
            const gameInfo = hh.gameRound;
            gt.name = gameInfo.tableName;
            gt.maxSeats = gameInfo.maxplayers;
            gt.gameType = gameInfo.game.gameType.trim();
            gt.roundID = gameInfo.roundReference.roundNumber;
            gt.gameLimit = gameInfo.game.limitType;
            gt.actualGameName = gt.gameType;
            gt.gameRoundDetails = gameInfo.gameRoundDetails;
            gt.isAnteCashGameOrTourny = gameInfo.isAnteCashGameOrTourny;
            gt.anteAmount = gameInfo.anteInfo.anteCost.unit;
            if (gameInfo.extendedProperties) {
                gt.isAamsIdPresent = true;
                gt.requestingPlayerSessionAamsId = gameInfo.extendedProperties.sessionAamsId ? gameInfo.extendedProperties.sessionAamsId : '';
                gt.requestingPlayerUserAamsId = gameInfo.extendedProperties.userAamsId ? gameInfo.extendedProperties.userAamsId : '';
                if (gameInfo.extendedProperties['playeR_CASHOUT_NA_BRAND'] === 'false') {
                    gt.cashOutOfferedinHHparser = false;
                } else if (gameInfo.extendedProperties['playeR_CASHOUT_OFFERED'] === 'true') gt.cashOutOfferedinHHparser = true;
            }
            if (gt.gameLimit) {
                if (gt.gameLimit === 'FixedLimit') {
                    gt.gameLimit = 'FIXED_LIMIT';
                } else if (gt.gameLimit === 'PotLimit') {
                    gt.gameLimit = 'POT_LIMIT';
                } else {
                    gt.gameLimit = 'NO_LIMIT';
                }
                gt.gameLimit = content[gt.gameLimit];
            }
            if (gt.gameType === 'OmahaHighLow' || gt.gameType === 'OmahaHigh') {
                OmahaGameType = true;
            }
            if (
                gt.gameType === 'TexasHoldem' ||
                gt.gameType === 'OmahaHighLow' ||
                gt.gameType === 'OmahaHigh' ||
                gt.gameType === 'ShortDeckTexasHoldem'
            ) {
                let changesGameType: any;
                if (gt.gameType === 'TexasHoldem') {
                    changesGameType = 'TEXAS_HOLDEM';
                } else if (gt.gameType === 'ShortDeckTexasHoldem') {
                    changesGameType = 'SHORT_DECK';
                } else if (gt.gameType === 'OmahaHigh') {
                    changesGameType = 'OMAHA_HI';
                } else if (gt.gameType === 'OmahaHighLow') {
                    changesGameType = 'OMAHA_HI_LO';
                }
                gt.gType = content[changesGameType];
                HHParser.gameName = gt.gameType;
                gt.smallBlind = gameInfo.anteInfo.smallBlind.unit;
                gt.bigBlind = gameInfo.anteInfo.bigBlind.unit;

                gt.gameType = `TEXASHOLDEM${gt.maxSeats}MAX`;
                if (Number(gt.maxSeats) === 10) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_TEN_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 9) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_NINE_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 8) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_EIGHT_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 7) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_SEVEN_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 6) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_SIX_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 5) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_FIVE_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 4) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_FOUR_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 3) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_THREE_MAX_TABLE';
                } else if (Number(gt.maxSeats) === 2) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_HEADS_UP_TABLE';
                }
            } else if (gt.gameType === 'SevenCardStudHighLow' || gt.gameType === 'SEVEN_CARD_STUD_HI' || gt.gameType === 'SevenCardStud') {
                let changesGameType: any;
                if (gt.gameType === 'SevenCardStudHighLow') {
                    changesGameType = 'SEVEN_CARD_STUD_HI_LO';
                } else if (gt.gameType === 'SevenCardStud') {
                    changesGameType = 'SEVEN_CARD_STUD';
                }
                gt.gType = content[changesGameType];
                gt.smallBlind = gameInfo.anteInfo.smallBet.unit;
                gt.bigBlind = gameInfo.anteInfo.bigBet.unit;

                if (gt.maxSeats === 2) {
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_HEADS_UP_TABLE';
                    gt.gameType = '7CARDHEADSUP';
                } else {
                    gt.gameType = '7CARDSTUD';
                    Properties.maximumNumberofSeats = 'MAX_SEATS_FOR_EIGHT_MAX_TABLE';
                }
                HHParser.gameName = gt.gameType;
            }

            Properties.gameType = gt.gameType;
            const mainPotPos = prop[gt.gameType][`MAINPOTPOS`].split('#');
            gt.pot.position = new Point(mainPotPos[0], mainPotPos[1]);
            gt.MAIN_POT.position = new Point(mainPotPos[0], mainPotPos[1]);

            const totalPot = prop['COMMONSECTION']['TOTALPOTAMOUNT'].split('#');
            gt.pot.totalPot = new Point(totalPot[0], totalPot[1]);
            gt.ROUND_POT.position = new Point(totalPot[0], totalPot[1]);

            const propfile = prop;
            Properties.propFile = propfile;

            gt.createSeats();
            const roundPlayers = gameInfo['roundPlayers'];
            let totalNumberOfPlayers: any = roundPlayers.length;
            // eslint-disable-next-line @typescript-eslint/no-array-constructor
            const players = new Array();
            if (roundPlayers) {
                let playersArray: any;
                if (isMobile || gt.isReplayerV3) {
                    playersArray = Properties.rearrangePlayersArray(roundPlayers, gameInfo.maxplayers);
                } else {
                    playersArray = roundPlayers;
                }
                if (gt.isReplayerV3 && gt.allInSeatSequence.length > 0) {
                    sdPots = gt.allInSeatSequence;
                    sdPots.forEach((value: any) => {
                        const potSeq = Properties.seatMap.get(value);
                        potSequence.push(potSeq);
                    });
                    gt.allInSeatSequence = potSequence;
                }
                playersArray.forEach((roundPlayer: any, ind: number) => {
                    if (uncalledPlayerAmount < roundPlayer.startMoney.unit && roundPlayer.hiHandRank != 'INVALID' && roundPlayer.bet.unit > 0) {
                        uncalledPlayerAmount = roundPlayer.startMoney.unit;
                        gt.uncalledBetPostedPlayer = roundPlayer;
                    }
                    const player = new Player();
                    player.name = playerNames[ind];
                    if (player.name.length > 10) {
                        player.isPlayerNameTruncated = true;
                    }
                    if (gameInfo.roundReference.gameCode === 'T5') {
                        player.isTourny = true;
                        gt.isTourny = true;
                    } else {
                        player.isTourny = false;
                        gt.isTourny = false;
                    }
                    if (isMobile || gt.isReplayerV3) {
                        player.seatNo = Properties.seatMap.get(roundPlayer.seat);
                    } else {
                        player.seatNo = roundPlayer.seat;
                    }

                    if (roundPlayer.requestingPlayer) {
                        reqPlayerSeatno = roundPlayer.seat;
                        player.name = selfPlayerName;
                    }

                    player.isPlayerRequesting = roundPlayer.requestingPlayer;
                    if (player.isPlayerRequesting) {
                        player.requestedPlayerHoleCards = roundPlayer.playerHoleCards;
                    }

                    const playerBetAmount: any = Number(roundPlayer.bet.unit);
                    const playerNetAmount: any = Number(roundPlayer.net.unit);
                    const totalPlayerWon: number = playerBetAmount + playerNetAmount;
                    let requestingPlayerSeatNo!: Number;
                    if (totalPlayerWon > 0) {
                        gt.extraAmountCalculationMap.set(Number(player.seatNo), totalPlayerWon);
                    }
                    player.isPlayerRequesting = roundPlayer.requestingPlayer;
                    if (player.isPlayerRequesting) {
                        requestingPlayerSeatNo = player.seatNo;
                        gt.requestingPlayerSeatNo = player.seatNo;
                        player.requestedPlayerHoleCards = roundPlayer.playerHoleCards;
                    }
                    player._gtype = gt.gameType;
                    if (gt.gameType === '7CARDSTUD' || gt.gameType === '7CARDHEADSUP') {
                        player.dealer = false;
                        player.sevenCardStud = true;
                    } else {
                        player.dealer = roundPlayer.seat === gameInfo.dealerSeat;
                    }
                    if (OmahaGameType) {
                        player.Omahagame = true;
                    }
                    player.chips = new Chip(roundPlayer.startMoney.unit);

                    const chipPos = prop[gt.gameType][`CHIPPOS${player.seatNo}`].split('#');
                    player.playerChipPos = new Point(chipPos[0], chipPos[1]);

                    const pointxy = prop[gt.gameType][`AVATARPOS${player.seatNo}`].split('#');
                    player.position = new Point(pointxy[0], pointxy[1]);
                    if (player.seatNo === 1) {
                        sevenCardCommunityPosition = player.position;
                    }
                    player.transformPosition = `c${Properties.avatarPos(Number(player.seatNo))}`;
                    const monetaryUnit = gameInfo.anteInfo.smallBlind.monetaryType;
                    if (monetaryUnit) {
                        const cType = monetaryUnit;
                        player.currencyType = prop['COMMONSECTION'][cType];
                    }
                    gt.pot.gameCurrency = player.currencyType;

                    if (isMobile || gt.isReplayerV3) {
                        if (requestingPlayerSeatNo === Properties.seatMap.get(gameInfo.dealerSeat)) {
                            gt.isrequestingPlayerDealer = true;
                        }
                    } else {
                        if (requestingPlayerSeatNo === gameInfo.dealerSeat) {
                            gt.isrequestingPlayerDealer = true;
                        }
                    }

                    players.push(player);
                    if (player.dealer) {
                        const dealerPoint = prop[gt.gameType][`DEALERBTNPOS${player.seatNo}`].split('#');
                        gt.dealer = new Dealer(player.name, player.seatNo, new Point(dealerPoint[0], dealerPoint[1]));
                    }
                });
            }
            if (players.length > 0) {
                gt.fillSeatsWithPlayers(players);
            }
            let potCreated = -1;
            const roundEvents = gameInfo['roundEvents'];
            const decisions = gameInfo['decisions'];

            gt.captureDecisions(decisions);
            // eslint-disable-next-line @typescript-eslint/no-array-constructor
            const events = new Array();
            // eslint-disable-next-line @typescript-eslint/no-array-constructor
            const dubEvents = new Array();
            if (roundEvents) {
                let totalHoleCards = 0;
                let dealingFourthStreetcards = 0;
                let dealingFifthStreetcards = 0;
                let dealingSixthStreetcards = 0;
                let dealingSeventhStreetcards = 0;
                let addEvent = true;
                roundEvents.forEach((roundEvent: any) => {
                    addEvent = true;
                    const eventType = roundEvent.handType;
                    if (eventType === 'DEALING_FLOP_REPEAT') {
                        events.push(dubEvents[0]);
                        events.push(dubEvents[1]);
                    } else if (eventType === 'DEALING_TURN_REPEAT') {
                        events.push(dubEvents[2]);
                        events.push(dubEvents[3]);
                    } else if (eventType === 'DEALING_RIVER_REPEAT') {
                        events.push(dubEvents[4]);
                        events.push(dubEvents[5]);
                    } else {
                        let seat: any;
                        if (isMobile || gt.isReplayerV3) {
                            seat = Properties.seatMap.get(roundEvent.seat);
                        } else {
                            seat = roundEvent.seat;
                        }
                        let amount: any;
                        if (roundEvent.amount) {
                            amount = roundEvent.amount.unit;
                        } else {
                            amount = 0;
                        }
                        const potId = roundEvent.potId;
                        let createdPotId;
                        let card: any = null;
                        if (roundEvent.card) {
                            const number = roundEvent.card[0];
                            const cardType = roundEvent.card[1];
                            card = new Card(number, cardType, false);
                            card.number = number;
                            card.type = cardType;
                        }

                        if (roundEvent.handType === 'CARD_HIDDEN' || roundEvent.handType === 'CARD_SHOWN') {
                            card.position = new Point(0, 0);
                        }

                        if (roundEvent.handType === 'CARD_HIDDEN' && reqPlayerSeatno === roundEvent.seat) {
                            if (roundEvent.card) {
                                const number = roundEvent.card[0];
                                const cardType = roundEvent.card[1];
                                card = new Card(number, cardType, false);
                                card.number = number;
                                card.type = cardType;
                                card.show = true;
                            }
                            gt.miniTableCards.push(card);
                        }

                        if (potCreated > -1) {
                            createdPotId = potCreated;
                            potCreated = -1;
                        }

                        if (roundEvent.handType === 'FOLD') {
                            noOfFoldedEvents += 1;
                        }

                        if (roundEvent.handType === 'POT_CREATED') {
                            numberOfPots++;
                        }
                        if (roundEvent.handType === 'DEALING_HOLE_CARDS') {
                            const rplayers = roundPlayers.length;
                            totalHoleCards = rplayers * 2;
                            if (HHParser.gameName === 'OmahaHighLow' || HHParser.gameName === 'OmahaHigh') {
                                totalHoleCards = rplayers * 4;
                            }
                        } else if (roundEvent.handType === 'DEALING_THIRD_STREET') {
                            const rplayers = roundPlayers.length;
                            totalHoleCards = rplayers * 3;
                        } else if (roundEvent.handType === 'DEALING_FOURTH_STREET') {
                            const rplayers = roundPlayers.length;
                            dealingFourthStreetcards = rplayers * 1 - noOfFoldedEvents;
                        } else if (roundEvent.handType === 'DEALING_FIFTH_STREET') {
                            const rplayers = roundPlayers.length;
                            dealingFifthStreetcards = rplayers * 1 - noOfFoldedEvents;
                        } else if (roundEvent.handType === 'DEALING_SIXTH_STREET') {
                            const rplayers = roundPlayers.length;
                            dealingSixthStreetcards = rplayers * 1 - noOfFoldedEvents;
                        } else if (roundEvent.handType === 'DEALING_SEVENTH_STREET') {
                            const rplayers = roundPlayers.length;
                            dealingSeventhStreetcards = rplayers * 1 - noOfFoldedEvents;
                        } else if (roundEvent.handType === 'POSTING_BLINDS') {
                            const rplayers = roundPlayers.length;
                            totalHoleCards = rplayers;
                        } else if (roundEvent.handType === 'WIN_HIGH' || roundEvent.handType === 'WIN_LOW') {
                            if (roundEvent.amount.unit > 0) {
                                gt.winPotSeatMap.set(Number(seat), Number(potId));
                            }
                            gt.TotalPotIdsAtWinhigh.unshift(Number(potId));
                            if (!gameInfo.ritGame) {
                                let amt = Number(roundEvent.amount.unit);
                                const t = playerVsWinAmout.get(Number(seat));
                                if (t) {
                                    amt += Number(t);
                                }
                                gt.playerVsWinAmout.set(Number(seat), Number(amt));
                                if (gt.playerVsWinAmout && !gameInfo.ritGame) {
                                    const e = EventFactory.getEvent('WIN_HIGH', seat, null, null, null, null, '', '');
                                    const winHighEvent = e as WinHigh;
                                    winHighEvent.playerVsWinAmout = gt.playerVsWinAmout;
                                    events.push(winHighEvent);
                                }
                                addEvent = false;
                            } else {
                                //for winAmout POt representation
                                let amt = Number(roundEvent.amount.unit);
                                const v = gt.RITplayerVsWinAmount.get(Number(seat));
                                if (v) {
                                    amt += Number(v);
                                }
                                gt.RITplayerVsWinAmount.set(Number(seat), Number(amt));
                                //
                                const t = gt.potArrayMap.get(roundEvent.potId);
                                if (t) {
                                    t.set(seat, roundEvent.amount.unit);
                                    gt.potArrayMap.set(roundEvent.potId, t);
                                    addEvent = false;
                                } else {
                                    innerMap = new Map();
                                    innerMap.set(seat, roundEvent.amount.unit);
                                    gt.potArrayMap.set(roundEvent.potId, innerMap);
                                    addEvent = false;
                                    const e = EventFactory.getEvent('RIT_WIN_HIGH', null, null, null, roundEvent.potId, null, '', '');
                                    const ritWinHighEvent = e as RitWinHigh;
                                    ritWinHighEvent.playerVsRitWinAmout = gt.potArrayMap;
                                    events.push(ritWinHighEvent);
                                }
                            }
                        }
                        if (roundEvent.boardTypeId === 3) {
                            addEvent = false;
                            ritEvents.push(roundEvent);
                        }

                        if (
                            HHParser.gameName === 'TexasHoldem' ||
                            HHParser.gameName === 'OmahaHighLow' ||
                            HHParser.gameName === 'OmahaHigh' ||
                            HHParser.gameName === 'ShortDeckTexasHoldem'
                        ) {
                            if (roundEvent.handType === 'CARD_HIDDEN') {
                                addEvent = false;

                                if (totalHoleCards > 0) {
                                    let cards = gt.playerCardMap.get(seat);
                                    if (!cards) {
                                        cards = [];
                                        gt.playerCardMap.set(seat, cards);
                                    }

                                    cards.push(card);

                                    totalHoleCards--;

                                    if (totalHoleCards === 0) {
                                        const eventTypeName = 'PLAYER_HOLECARDS_EVENT';
                                        const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                        events.push(event);
                                    }
                                }
                            }

                            //Accumulation of all post-ante  events

                            if (roundEvent.handType === 'POST_ANTE' || roundEvent.handType === 'ALL_IN_ANTE') {
                                addEvent = false;
                                gt.playerAnteChips.set(seat, roundEvent.amount.unit);
                                totalNumberOfPlayers--;
                                if (totalNumberOfPlayers === 0) {
                                    const eventTypeName = 'PLAYERS_ANTE_EVENT';
                                    const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                    events.push(event);
                                }
                            }

                            if (
                                roundEvent.handType === 'UNKNOWN_CARDS' ||
                                roundEvent.handType === 'WAIT_FOR_BIG_BLIND' ||
                                roundEvent.handType === 'OPTION_SET'
                            ) {
                                addEvent = false;
                            }

                            if (roundEvent.handType === 'COMMUNITY_CARD') {
                                communityCardNumber = communityCardNumber + 1;
                                gt.textViewCommunityCards.push(roundEvent.card);
                                if (tableCards.size < 3) {
                                    tableCards.add(card);
                                    addEvent = false;

                                    if (tableCards.size === 3) {
                                        const event = EventFactory.getCommunityCardTask();
                                        event.cards = tableCards;
                                        events.push(event);
                                        dubEvents.push(event);
                                    }
                                }
                            }
                            if (communityCardNumber > 5) {
                                gt.isRit = gameInfo.ritGame;
                                gt.totalNumberOfCommunityCards = communityCardNumber;
                            }
                        }
                        if (HHParser.gameName === '7CARDSTUD' || HHParser.gameName === '7CARDHEADSUP') {
                            if (roundEvent.handType === 'POST_ANTE' || roundEvent.handType === 'ALL_IN_ANTE') {
                                addEvent = false;
                                gt.playerAnteChips.set(seat, roundEvent.amount.unit);
                                totalNumberOfPlayers--;
                                if (totalNumberOfPlayers === 0) {
                                    const eventTypeName = 'PLAYERS_ANTE_EVENT';
                                    const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                    events.push(event);
                                }
                            }
                            if (roundEvent.handType === 'CARD_HIDDEN' || roundEvent.handType === 'CARD_SHOWN') {
                                if (totalHoleCards > 0) {
                                    addEvent = false;
                                    let cards = gt.playerCardMap.get(seat);
                                    if (!cards) {
                                        cards = [];
                                        gt.playerCardMap.set(seat, cards);
                                    }
                                    if (roundEvent.handType === 'CARD_SHOWN') {
                                        card.show = true;
                                    }

                                    cards.push(card);

                                    totalHoleCards--;

                                    if (totalHoleCards === 0) {
                                        const eventTypeName = 'SEVENCARD_PLAYER_HOLECARDS_EVENT';
                                        const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                        events.push(event);
                                    }
                                }
                            }
                            if (roundEvent.handType === 'CARD_HIDDEN' || roundEvent.handType === 'CARD_SHOWN') {
                                if (dealingFourthStreetcards > 0) {
                                    addEvent = false;
                                    let pcards = gt.playerFourthCardMap.get(seat);
                                    if (!pcards) {
                                        pcards = [];
                                        gt.playerFourthCardMap.set(seat, pcards);
                                    }
                                    if (roundEvent.handType === 'CARD_SHOWN') {
                                        card.show = true;
                                    }

                                    pcards.push(card);

                                    dealingFourthStreetcards--;

                                    if (dealingFourthStreetcards === 0) {
                                        const eventTypeName = 'SEVENCARD_DEALINGFOURTHSTREET_PLAYER_HOLECARDS_EVENT';
                                        const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                        events.push(event);
                                    }
                                }
                            }
                            if (roundEvent.handType === 'CARD_HIDDEN' || roundEvent.handType === 'CARD_SHOWN') {
                                if (dealingFifthStreetcards > 0) {
                                    addEvent = false;
                                    let pcards = gt.playerFifthCardMap.get(seat);
                                    if (!pcards) {
                                        pcards = [];
                                        gt.playerFifthCardMap.set(seat, pcards);
                                    }
                                    if (roundEvent.handType === 'CARD_SHOWN') {
                                        card.show = true;
                                    }

                                    pcards.push(card);

                                    dealingFifthStreetcards--;

                                    if (dealingFifthStreetcards === 0) {
                                        const eventTypeName = 'SEVENCARD_DEALINGFIFTHSTREET_PLAYER_HOLECARDS_EVENT';
                                        const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                        events.push(event);
                                    }
                                }
                            }
                            if (roundEvent.handType === 'CARD_HIDDEN' || roundEvent.handType === 'CARD_SHOWN') {
                                if (dealingSixthStreetcards > 0) {
                                    addEvent = false;
                                    let pcards = gt.playerSixthCardMap.get(seat);
                                    if (!pcards) {
                                        pcards = [];
                                        gt.playerSixthCardMap.set(seat, pcards);
                                    }
                                    if (roundEvent.handType === 'CARD_SHOWN') {
                                        card.show = true;
                                    }

                                    pcards.push(card);

                                    dealingSixthStreetcards--;

                                    if (dealingSixthStreetcards === 0) {
                                        const eventTypeName = 'SEVENCARD_DEALINGSIXTHSTREET_PLAYER_HOLECARDS_EVENT';
                                        const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                        events.push(event);
                                    }
                                }
                            }
                            if (roundEvent.handType === 'CARD_HIDDEN' || roundEvent.handType === 'CARD_SHOWN') {
                                if (dealingSeventhStreetcards > 0) {
                                    addEvent = false;
                                    let pcards = gt.playerSeventhCardMap.get(seat);
                                    if (!pcards) {
                                        pcards = [];
                                        gt.playerSeventhCardMap.set(seat, pcards);
                                    }
                                    if (roundEvent.handType === 'CARD_SHOWN') {
                                        card.show = true;
                                    }

                                    pcards.push(card);

                                    dealingSeventhStreetcards--;

                                    if (dealingSeventhStreetcards === 0) {
                                        const eventTypeName = 'SEVENCARD_DEALINGSEVENTHSTREET_PLAYER_HOLECARDS_EVENT';
                                        const event = EventFactory.getEvent(eventTypeName, null, null, null, null, null, '', '');
                                        events.push(event);
                                    }
                                }
                            }
                        }
                        if (gt.playerAnteChips.size === 0) {
                            if (roundEvent.handType === 'POSTING_BLINDS') {
                                addEvent = false;
                            }
                        }
                        if (
                            roundEvent.handType === 'UNKNOWN_CARDS' ||
                            roundEvent.handType === 'WAIT_FOR_BIG_BLIND' ||
                            roundEvent.handType === 'OPTION_SET'
                        ) {
                            addEvent = false;
                        }

                        //RIT Changes for delting community cards
                        if (
                            (communityCardNumber === 10 || communityCardNumber === 7 || communityCardNumber === 6) &&
                            roundEvent.handType === 'SHOWDOWN'
                        ) {
                            ritEvents.forEach((roundEvent: any) => {
                                if (roundEvent.handType != 'COMMUNITY_CARD') {
                                    addEvent = false;
                                } else {
                                    addEvent = true;
                                }
                                const eventType = roundEvent.handType;
                                let seat: any;
                                if (isMobile || gt.isReplayerV3) {
                                    seat = Properties.seatMap.get(roundEvent.seat);
                                } else {
                                    seat = roundEvent.seat;
                                }
                                let amount: any;
                                if (roundEvent.amount) {
                                    amount = roundEvent.amount.unit;
                                } else {
                                    amount = 0;
                                }
                                const potId = roundEvent.potId;
                                let createdPotId;
                                let card: any = null;
                                if (roundEvent.card) {
                                    const number = roundEvent.card[0];
                                    const cardType = roundEvent.card[1];
                                    card = new Card(number, cardType, false);
                                    card.number = number;
                                    card.type = cardType;
                                }
                                if (roundEvent.handType === 'COMMUNITY_CARD' && gt.totalNumberOfCommunityCards.valueOf() > 7) {
                                    if (ritFlopCards.size < 3) {
                                        ritFlopCards.add(card);
                                        addEvent = false;
                                    }
                                    if (ritFlopCards.size === 3 && !ritCommunityCardEventCreated) {
                                        const event = EventFactory.getRitCommunityCardTask();
                                        event.cards = ritFlopCards;
                                        events.push(event);
                                        ritCommunityCardEventCreated = true;
                                    }
                                }
                                if (roundEvent.handType === 'COMMUNITY_CARD') {
                                    if (HHParser.gameName === '7CARDSTUD' || HHParser.gameName === '7CARDHEADSUP') {
                                        card.position = sevenCardCommunityPosition;
                                    } else {
                                        card.position = gt.dealer.position;
                                    }
                                    const pos = prop['COMMONSECTION']['COMMUNITYHAND_POS'].split('#');
                                    card.position = new Point(pos[1], pos[2]);
                                    card.communityCard = true;
                                    gt.addCommunityCard(card);
                                }
                                const event = EventFactory.getEvent(eventType, seat, amount, card, potId, createdPotId, '', '');
                                createdPotId = '';
                                if (event && addEvent) {
                                    events.push(event);
                                }
                            });
                        }

                        if (addEvent) {
                            // eslint-disable-next-line no-var
                            var decision = '';
                            if (roundEvent.decision) {
                                decision = roundEvent.decision;
                            }

                            if (eventType == 'HAND_STRENGTH') {
                                // eslint-disable-next-line no-var
                                var handStrength = roundEvent.handStrength;
                            }
                            const event = EventFactory.getEvent(eventType, seat, amount, card, potId, createdPotId, decision, handStrength);
                            createdPotId = '';
                            if (event) {
                                events.push(event);
                                if (
                                    eventType === 'DEALING_FLOP' ||
                                    eventType === 'DEALING_TURN' ||
                                    eventType === 'DEALING_RIVER' ||
                                    roundEvent.cardType === 'DEALING_TURN' ||
                                    roundEvent.cardType === 'DEALING_RIVER'
                                ) {
                                    dubEvents.push(event);
                                }
                            }
                        } else {
                        }
                        if (roundEvent.handType === 'COMMUNITY_CARD' && roundEvent.boardTypeId != 3) {
                            //condition for RIT
                            if (HHParser.gameName === '7CARDSTUD' || HHParser.gameName === '7CARDHEADSUP') {
                                card.position = sevenCardCommunityPosition;
                            } else {
                                card.position = gt.dealer.position;
                            }
                            const pos = prop['COMMONSECTION']['COMMUNITYHAND_POS'].split('#');
                            card.position = new Point(pos[1], pos[2]);
                            card.communityCard = true;
                            gt.addCommunityCard(card);
                        }
                    }
                });
            }

            // SidePot Map Creation
            if (gameInfo.pots && Array.isArray(gameInfo.pots)) {
                const pots = gameInfo.pots;
                if (pots) {
                    const potMap = new Map();
                    const rMap = new Map();
                    pots.forEach((pot: any) => {
                        potMap.set(Number(pot.id), Number(pot.amount.unit + (pot.rakeAmount.unit ? pot.rakeAmount.unit : 0)));
                        if (pot.rakeAmount) {
                            let rakeAmount: any;
                            if (pot.rakeAmount.unit) {
                                rakeAmount = pot.rakeAmount.unit;
                            } else {
                                rakeAmount = 0;
                            }
                            rMap.set(pot.id, rakeAmount);
                        } else {
                            rMap.set(pot.id, 0);
                        }
                    });
                    if (rMap.size > 0) {
                        const rPositon = prop['COMMONSECTION']['RAKEPOS'].split('#');
                        gt.rakePosition = rPositon;
                        if (rMap.size <= 1) {
                            gt.rakeFromSidePot = false;
                        }
                    }
                    gt.rakeMap = rMap;
                    gt.potMap = potMap;
                    gt.unCalledFlag = gameInfo.unCalledFlag;
                    gt.unCalledBetAmount = gameInfo.unBetPotSize;
                    const roundDetails = gameInfo.gameRoundDetails;
                    //RIT GameChanges, For not creating sidepot with uncalled bet
                    const gameRoundDetailesKeys: any = Object.keys(roundDetails);
                    if (gameInfo.ritGame) {
                        gameRoundDetailesKeys.forEach((gameRoundDetailesKeysIndex: any) => {
                            if (
                                gameInfo.unCalledFlag === 0 &&
                                gameInfo.unBetPotSize > 0 &&
                                gameInfo.unBetPotSize === gt.gameRoundDetails[gameRoundDetailesKeysIndex].activePotAmount.unit
                            ) {
                                gt.gameRoundDetails[gameRoundDetailesKeysIndex].activePotAmount.unit = 0;
                            }
                        });
                    }
                    const roundDetailsLength = Object.keys(roundDetails).length;
                    if (gameInfo.unBetPotSize && gameInfo.unCalledFlag === 0 && !gameInfo.ritGame) {
                        gt.potMap.set(gt.potMap.size, gameInfo.unBetPotSize);
                    } else {
                        if (
                            gameInfo.isAnteCashGameOrTourny === 1 ||
                            (gt.actualGameName.indexOf('ShortDeck') > -1 && gameInfo.isAnteCashGameOrTourny === -1)
                        ) {
                            if (
                                gt.potMap.size >= roundDetails[roundDetailsLength - 1].activePotNumber &&
                                roundDetails[roundDetailsLength - 1].activePotAmount.unit === 0
                            ) {
                                gt.potMap.set(gt.potMap.size, 0);
                            }
                        } else if (
                            gt.potMap.size >= roundDetails[roundDetailsLength].activePotNumber &&
                            roundDetails[roundDetailsLength].activePotAmount.unit === 0
                        ) {
                            gt.potMap.set(gt.potMap.size, 0);
                        }
                    }

                    if (gt.potMap.size !== numberOfPots) {
                        let actualPotValue = 0;
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        gt.potMap.forEach((value, key) => {
                            actualPotValue += value;
                        });

                        let tmpPots = 0;
                        let totalPotCreatedValue = 0;
                        events.forEach((e) => {
                            if (e instanceof PotCreated) {
                                tmpPots++;
                                const pc = e;
                                totalPotCreatedValue += Number(pc.amount) - Number(gt.rakeMap.get(pc.potId));
                                if (tmpPots === numberOfPots) {
                                    const rakeAmountToAddForLastPot = Number(gt.rakeMap.get(tmpPots));
                                    const event = EventFactory.getEvent(
                                        'POT_CREATED',
                                        null,
                                        actualPotValue - totalPotCreatedValue + rakeAmountToAddForLastPot,
                                        null,
                                        numberOfPots,
                                        null,
                                        '',
                                        '',
                                    );
                                    if (event) {
                                    }
                                }
                            }
                        });
                    }
                }
            } else {
                let potId: any;

                gameInfo.pots.forEach((pot: any) => {
                    potId = pot.id;
                });
                gt.potSize = gameInfo.pots[potId].amount.unit;
            }

            if (events.length > 0) {
                gt.captureEvents(events);
            }

            if (isOnInit) {
                gt.cardAnimation = false;
                gt.onInit = false;
            } else {
                gt.showTable = true;
                gt.onInit = false;
                gt.cardAnimation = true;
            }

            return gt;
        }
    }
}
