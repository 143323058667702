import { Message } from '../../message';

export class UserAvatarInfo extends Message {
    private MESSAGE_NAME: string = 'UserAvatarInfo';
    private avatarId: number = 0;
    private isCustomEnabled: boolean | null = false;
    private avatarMD5Sum: string | null = null;
    private firstUploadedInCycle: number = 0;
    private uploadsRemaining: number = 0;
    private avatarDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        avatarId: number = 0,
        isCustomEnabled: boolean | null = false,
        avatarMD5Sum: string | null = null,
        firstUploadedInCycle: number = 0,
        uploadsRemaining: number = 0,
        avatarDetails: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.avatarId = avatarId;
        this.isCustomEnabled = isCustomEnabled;
        this.avatarMD5Sum = avatarMD5Sum;
        this.firstUploadedInCycle = firstUploadedInCycle;
        this.uploadsRemaining = uploadsRemaining;
        this.avatarDetails = avatarDetails;
    }

    getAvatarId(): number {
        return this.avatarId;
    }

    setAvatarId(avatarId: number) {
        this.avatarId = avatarId;
    }
    getIsCustomEnabled(): boolean | null {
        return this.isCustomEnabled;
    }

    setIsCustomEnabled(isCustomEnabled: boolean | null) {
        this.isCustomEnabled = isCustomEnabled;
    }
    getAvatarMD5Sum(): string | null {
        return this.avatarMD5Sum;
    }

    setAvatarMD5Sum(avatarMD5Sum: string | null) {
        this.avatarMD5Sum = avatarMD5Sum;
    }
    getFirstUploadedInCycle(): number {
        return this.firstUploadedInCycle;
    }

    setFirstUploadedInCycle(firstUploadedInCycle: number) {
        this.firstUploadedInCycle = firstUploadedInCycle;
    }
    getUploadsRemaining(): number {
        return this.uploadsRemaining;
    }

    setUploadsRemaining(uploadsRemaining: number) {
        this.uploadsRemaining = uploadsRemaining;
    }
    getAvatarDetails(): any[] | null {
        return this.avatarDetails;
    }

    setAvatarDetails(avatarDetails: any[] | null) {
        this.avatarDetails = avatarDetails;
    }
}
