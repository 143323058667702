import { LabelType, Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClaimsService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { SocialComponentClientConfig } from '@pokercore/module/core';
import * as _ from 'lodash-es';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sv';
import { NgxSliderModule } from 'ngx-slider-v2';
import { Subscription } from 'rxjs';

import { CalendarCountdownTimerComponent } from '../calendar-countdown-timer/calendar-countdown-timer.component';
import { formatCurrencyPipe } from './formatCurrency.pipe';
import { formatDecimalPipe } from './formatDecimal.pipe';
import { PokerScheduleFeedComponent } from './poker-schedule-feed.component';
import { PokerScheduleFeedService } from './poker-schedule-feed.service';
import { toCurrencyPipe } from './toCurrency.pipe';

@Component({
    selector: 'poker-schedule-feed-filters',
    templateUrl: 'poker-schedule-feed-filters.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSliderModule,
        PokerScheduleFeedComponent,
        CalendarCountdownTimerComponent,
        PokerScheduleFeedFiltersComponent,
        MatTooltipModule,
        formatCurrencyPipe,
        toCurrencyPipe,
        formatDecimalPipe,
    ],
})
export class PokerScheduleFeedFiltersComponent extends PCComponent implements OnInit {
    @Input() filterStatus: boolean;
    @Output() emitReset = new EventEmitter();

    feedData: any;
    initSubscription: Subscription;
    searchFilterSubscription: Subscription;
    getResetButtonSubscription: Subscription;
    mobileFiltersOn: boolean = false; // false default
    configStrings: any;
    showBuyin: any;
    isStaticData: boolean;
    disableReset: boolean;
    isBuyInAvail: boolean;
    scheduleFeedItems: any;
    regStatus: any;
    hoursDiffer: any;

    buyInCategory: String[];
    gameTypes: any[] = [];
    format: any[] = [];
    speedCategory: String[];
    currencyList: any[];
    buyInValues: any[];
    skippedRecords: any[] = [];

    minDate: any;
    maxDate: any;
    minGuarantee: number;
    maxGuarantee: number;
    maxGuaranteeHold: number;
    BuyInMaxHold: number;
    buyInMinO: number;
    buyInMaxO: number;
    minDateInit: any;
    maxDateInit: any;
    BuyInMinInit: number;
    BuyInMaxInit: number;
    minGuaranteeInit: number;
    maxGuaranteeInit: number;

    buyInOptions: Options;
    byDateOptions: Options;
    buyInSliderOptionsTick: Options;
    guaranteeOptions: Options;
    currencyCode: string;
    userLanguage: any;

    filterValues = {
        buyInValue: [],
        registrationValue: [],
        gameTypes: [],
        format: [],
        speedCategory: [],
        currency: [],
        dateSlide: {
            minDate: new Number(),
            maxDate: new Number(),
        },
        buyInSlide: {
            minBuy: new Number(),
            maxBuy: new Number(),
        },
        guaranteeSlide: {
            minGuarantee: new Number(),
            maxGuarantee: new Number(),
        },
    };

    buyInSelect = new UntypedFormControl();
    gameTypesSelect = new UntypedFormControl();
    registrationSelect = new UntypedFormControl();
    formatSelect = new UntypedFormControl();
    speedCategorySelect = new UntypedFormControl();
    currencySelect = new UntypedFormControl();

    manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private socialComponentClientConfig: SocialComponentClientConfig,
        private schedService: PokerScheduleFeedService,
        private claimsService: ClaimsService,
    ) {
        super();
        this.initSubscription = this.schedService.getInitData().subscribe((initData) => {
            if (initData != null) {
                this.feedData = initData;
            }
        });

        this.isBuyInAvail = this.schedService.checkBuyIn();
        this.userLanguage = this.claimsService.get('language');
    }

    ngOnInit() {
        this.scheduleFeedItems = this.feedData.scheduleFeedData.tournamentsFeedData
            ? this.feedData.scheduleFeedData.tournamentsFeedData
            : this.feedData.scheduleFeedData.scheduleData;
        this.feedData.scheduleFeedData.scheduleData ? (this.isStaticData = true) : (this.isStaticData = false);
        this.configStrings = this.feedData.scheduleFeedData.scheduleStaticData.tournamentFilters;
        this.showBuyin = this.feedData.showBuyin;

        this.speedCategory = this.feedData.scheduleFeedData.speedCategory;
        this.regStatus = this.feedData.scheduleFeedData.scheduleStaticData.tournamentFilters.registrationStatusOptions;
        this.hoursDiffer = this.socialComponentClientConfig.addHoursToTournamentTime;

        this.formatBuyInOptions();

        // Remove item which contains value "Restricted" in byin
        _.remove(this.scheduleFeedItems, function (feedItem: any) {
            return feedItem.buyIn == 'Restricted';
        });
        // Remove item which contains "durationLateRegClose = 0"
        _.remove(this.scheduleFeedItems, function (feedItem: any) {
            return feedItem.status.toLowerCase() == 'late registration' && feedItem.durationLateRegClose == 0;
        });

        // Get unique filter options
        this.getUniqueOptions();

        this.dateMinMax(this.scheduleFeedItems);
        this.buyInMinMax(this.scheduleFeedItems);
        this.guaranteeMinMax(this.scheduleFeedItems);
        this.buyInSliderOptionsTicks();

        // Create common currency symbol array from Schedule feed
        this.getCurrencySymbol();

        // Date Slider Options
        this.dateSliderOptions();

        // Guarantee Slider Options
        this.guaranteeSliderOptions();

        this.buyInSelect.valueChanges.subscribe((buyInValue) => {
            this.filterValues.buyInValue = buyInValue;
            this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        });
        this.gameTypesSelect.valueChanges.subscribe((gameType) => {
            this.filterValues.gameTypes = gameType;
            this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        });
        this.formatSelect.valueChanges.subscribe((format) => {
            this.filterValues.format = format;
            this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        });
        this.speedCategorySelect.valueChanges.subscribe((speedCate) => {
            this.filterValues.speedCategory = speedCate;
            this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        });
        this.registrationSelect.valueChanges.subscribe((registrationValue) => {
            this.filterValues.registrationValue = registrationValue;
            this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        });
        this.currencySelect.valueChanges.subscribe((symbol) => {
            this.filterValues.currency = symbol;
            this.currencyCode = symbol?.length == 1 ? symbol[0] : this.currencyList[0];
            this.buyInSliderOptionsTicks();
            this.guaranteeSliderOptions('currencyUpdate');
            this.manualRefresh.emit();
            this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        });

        this.getResetButtonStatus();
        this.getSearchFilterData();
    }

    formatBuyInOptions() {
        const buyinsFormatted: any[] = [];

        this.feedData.scheduleFeedData.buyinCategories.forEach((item: any) => {
            const buyInSplit = item.split('%');
            const min = buyInSplit[1];
            let max = buyInSplit[3];
            const name = item.replaceAll('%', '');
            if (Number(min) == 0) max = 0;
            const buyInOption = { name, min, max };
            buyinsFormatted.push(buyInOption);
        });
        this.buyInCategory = buyinsFormatted;
    }

    getUniqueOptions() {
        this.gameTypes = _.uniq(_.map(this.scheduleFeedItems, 'gameType'));
        this.format = _.uniq(_.map(this.scheduleFeedItems, 'limitType'));
    }

    getResetButtonStatus() {
        this.getResetButtonSubscription = this.schedService.disableReset.subscribe((status) => {
            if (status != null) this.disableReset = status;
        });
    }

    getCurrencySymbol() {
        const holdCurrencySymbols: string[] = [];
        _.map(this.scheduleFeedItems, function (item: any) {
            const splitValue = item.buyIn.split('+');
            const getFirstVal = splitValue[0].replace(/[.+0-9\s]/g, '');
            if (getFirstVal) {
                holdCurrencySymbols.push(getFirstVal);
            }
        });
        this.currencyList = _.uniq(holdCurrencySymbols);
        this.currencyCode = this.currencyList[0];
    }

    // BuyIn common values (Temp)
    getBuyInCommonValues(values: any) {
        this.buyInValues = _.uniq(values);
        this.buyInValues = _.sortBy(this.buyInValues);
    }

    // Date Min Max
    dateMinMax(feed: any) {
        const getMinDate = Math.min.apply(
            null,
            feed.map(function (item: any) {
                return new Date(item.startDate);
            }),
        );
        const getMaxDate = Math.max.apply(
            null,
            feed.map(function (item: any) {
                return new Date(item.startDate);
            }),
        );

        this.minDate = getMinDate;
        this.maxDate = getMaxDate;
        this.minDateInit = getMinDate;
        this.maxDateInit = getMaxDate;

        this.filterValues.dateSlide.minDate = this.minDate;
        this.filterValues.dateSlide.maxDate = this.maxDate;
    }

    // Date Slider
    dateSliderOptions() {
        const millisInDay = 24 * 60 * 60 * 1000;
        this.byDateOptions = {
            floor: this.minDate,
            ceil: this.maxDate,
            step: millisInDay,
            translate: function (date_millis) {
                if (date_millis !== null) {
                    return moment(date_millis).locale('en').format('DD MMM YYYY');
                }
                return '';
            },
        };
    }

    // Get BuyIn Min Max
    buyInMinMax(feed: any) {
        const buyInList: Number[] = [];
        if (this.isStaticData) {
            feed.map((item: any) => {
                if (this.isBuyInAvail) {
                    buyInList.push(Math.floor(item.buyIn.unit + item.fee.unit));
                } else {
                    buyInList.push(Number(0));
                }
            });
        } else {
            feed.map((item: any) => {
                if (this.isBuyInAvail) {
                    const replaceString = item.buyIn.replace(/[^0-9\.+]/g, '');
                    const splitVal = replaceString.split('+');
                    const finalVal = Number(splitVal[0]) + Number(splitVal[1]);
                    Number.isNaN(finalVal) ? this.skippedRecords.push(Math.floor(finalVal)) : buyInList.push(Math.floor(finalVal));
                } else {
                    buyInList.push(Number(0));
                }
            });
        }
        this.getBuyInCommonValues(buyInList);
        const buyInMinHold = Math.min.apply(null, buyInList);
        const buyInMaxHold = Math.max.apply(null, buyInList);
        this.buyInMinO = buyInMinHold;
        this.buyInMaxO = buyInMaxHold;
        this.filterValues.buyInSlide.minBuy = buyInMinHold;
        this.filterValues.buyInSlide.maxBuy = buyInMaxHold;
        this.BuyInMinInit = buyInMinHold;
        this.BuyInMaxInit = buyInMaxHold;
    }

    // Get Guarantee Min Max
    guaranteeMinMax(feed: any) {
        const guaranteeList: Number[] = [];
        if (this.isStaticData) {
            feed.map((item: any) => {
                guaranteeList.push(item.prizePool.unit);
            });
        } else {
            feed.map((item: any) => {
                const checkValue = Number(item.prizePool.match(/\d+/));
                Number.isNaN(checkValue) ? this.skippedRecords.push(checkValue) : guaranteeList.push(checkValue);
            });
        }
        const minGuaranteeHold = Math.min.apply(null, guaranteeList);
        const maxGuaranteeHold = Math.max.apply(null, guaranteeList);
        this.minGuarantee = minGuaranteeHold;
        this.maxGuarantee = maxGuaranteeHold;
        this.filterValues.guaranteeSlide.minGuarantee = minGuaranteeHold;
        this.filterValues.guaranteeSlide.maxGuarantee = maxGuaranteeHold;
        this.minGuaranteeInit = minGuaranteeHold;
        this.maxGuaranteeInit = maxGuaranteeHold;
    }

    buyInSliderOptionsTicks() {
        this.buyInSliderOptionsTick = {
            showTicks: true,
            ticksTooltip: (val: number): string => {
                return 'Buy In ' + this.buyInValues[val];
            },
            stepsArray: this.buyInValues.map((val: number) => {
                return { value: val };
            }),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            translate: (value: number, label: LabelType): string => {
                if (this.configStrings?.currencyRePosition == 'true' && this.currencyCode == '€') {
                    return value + ' ' + this.currencyCode;
                }
                return this.currencyCode + value;
            },
        };
    }

    // Guarantee Slider
    guaranteeSliderOptions(currencyCheck?: any) {
        this.guaranteeOptions = {
            floor: currencyCheck == 'currencyUpdate' ? this.minGuaranteeInit : this.minGuarantee,
            ceil: currencyCheck == 'currencyUpdate' ? this.maxGuaranteeInit : this.maxGuarantee,
            showTicks: true,
            step: 50,
            customValueToPosition: (val: number, minVal: number, maxVal: number): number => {
                val = Math.sqrt(Math.sqrt(val));
                minVal = Math.sqrt(Math.sqrt(minVal));
                maxVal = Math.sqrt(Math.sqrt(maxVal));
                const range: number = maxVal - minVal;
                return (val - minVal) / range;
            },
            customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {
                minVal = Math.sqrt(Math.sqrt(minVal));
                maxVal = Math.sqrt(Math.sqrt(maxVal));
                const value: number = percent * (maxVal - minVal) + minVal;
                return Math.pow(value, 4);
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            translate: (value: number, label: LabelType): string => {
                if (this.configStrings?.currencyRePosition == 'true' && this.currencyCode == '€') {
                    return value + ' ' + this.currencyCode;
                }
                return this.currencyCode + value;
            },
        };
    }

    dateSliderChange() {
        this.filterValues.dateSlide.minDate = this.minDate;
        this.filterValues.dateSlide.maxDate = this.maxDate;
        this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
    }

    guaranteeSliderChange() {
        this.filterValues.guaranteeSlide.minGuarantee = this.minGuarantee;
        this.filterValues.guaranteeSlide.maxGuarantee = this.maxGuarantee;
        this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
    }

    buyinSliderChange() {
        this.filterValues.buyInSlide.minBuy = this.buyInMinO;
        this.filterValues.buyInSlide.maxBuy = this.buyInMaxO;
        this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
    }

    getSearchFilterData() {
        this.searchFilterSubscription = this.schedService.getSearchFilterData().subscribe((searchfilteredList) => {
            if (searchfilteredList != null) {
                this.guaranteeMinMax(searchfilteredList);
                this.buyInMinMax(searchfilteredList);
                this.dateMinMax(searchfilteredList);
                this.buyInSliderOptionsTicks();
                this.guaranteeSliderOptions();
                this.dateSliderOptions();
            } else {
                this.guaranteeMinMax(this.scheduleFeedItems);
                this.buyInMinMax(this.scheduleFeedItems);
                this.dateMinMax(this.scheduleFeedItems);
                this.buyInSliderOptionsTicks();
                this.guaranteeSliderOptions();
                this.dateSliderOptions();
                this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
            }
        });
    }

    // Add additional hours from timeZone
    addTimeZoneHours() {
        this.scheduleFeedItems.map((item: any) => {
            const dateMoment = moment(item.startDate);
            const newDate = dateMoment.add(this.hoursDiffer, 'hours').format('YYYY-MM-DDTHH:mm:ss');
            item.startDate = new Date(newDate);
        });
    }

    toggleFilter() {
        this.filterStatus = !this.filterStatus;
    }
    submitFilters() {
        this.mobileFiltersOn = false;
    }
    closeFilters() {
        this.mobileFiltersOn = false;
    }
    showMobileFilters() {
        this.filterStatus = true;
        this.mobileFiltersOn = true;
    }

    resetFilters() {
        this.filterValues.buyInSlide.minBuy = this.BuyInMinInit;
        this.filterValues.buyInSlide.maxBuy = this.BuyInMaxInit;
        this.buyInMinO = this.BuyInMinInit;
        this.buyInMaxO = this.BuyInMaxInit;
        this.filterValues.dateSlide.minDate = this.minDateInit;
        this.filterValues.dateSlide.maxDate = this.maxDateInit;
        this.minDate = this.minDateInit;
        this.maxDate = this.maxDateInit;
        this.minGuarantee = this.minGuaranteeInit;
        this.maxGuarantee = this.maxGuaranteeInit;
        this.filterValues.guaranteeSlide.minGuarantee = this.minGuaranteeInit;
        this.filterValues.guaranteeSlide.maxGuarantee = this.maxGuaranteeInit;
        this.buyInSelect.reset('');
        this.registrationSelect.reset('');
        this.gameTypesSelect.reset('');
        this.formatSelect.reset('');
        this.speedCategorySelect.reset('');
        this.currencySelect.reset('');
        this.schedService.applyFilters(this.filterValues, this.scheduleFeedItems);
        this.emitReset.emit(true);
    }

    ngOnDestroy() {
        this.initSubscription.unsubscribe();
        this.getResetButtonSubscription.unsubscribe();
        this.searchFilterSubscription.unsubscribe();
    }
}
