import { Routes } from '@angular/router';

import { PokerTorneiProgressiveKnockoutSynopsisResolver } from './poker-tornei-progressive-knockout-synopsis.resolver';
import { PokerTorneiProgressiveKnockoutResolver } from './poker-tornei-progressive-knockout.resolver';

export const pokerTorneiProgressiveKnockoutRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTorneiProgressiveKnockoutResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerTorneiProgressiveKnockoutSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTorneiProgressiveKnockoutResolver,
        },
    },
];
