import { Message } from '../../message';

export class WatchersInfo extends Message {
    private MESSAGE_NAME: string = 'WatchersInfo';
    private watchersCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, watchersCount: number = 0) {
        super(reqServerPeerId);
        this.watchersCount = watchersCount;
    }

    getWatchersCount(): number {
        return this.watchersCount;
    }

    setWatchersCount(watchersCount: number) {
        this.watchersCount = watchersCount;
    }
}
