import { Message } from '../../message';

export class LSMTCTUnRegistrationCloseChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSMTCTUnRegistrationCloseChangeEvent';
    private mtctId: number = 0;
    private unregClosed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, unregClosed: boolean | null = false) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.unregClosed = unregClosed;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
}
