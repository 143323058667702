import { Message } from '../../message';

export class ClubNameApproved extends Message {
    private MESSAGE_NAME: string = 'ClubNameApproved';
    private clubId: number = 0;
    private clubName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clubId: number = 0, clubName: string | null = null) {
        super(reqServerPeerId);
        this.clubId = clubId;
        this.clubName = clubName;
    }

    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
    getClubName(): string | null {
        return this.clubName;
    }

    setClubName(clubName: string | null) {
        this.clubName = clubName;
    }
}
