import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-discount-timer',
    templateUrl: 'poker-discount-timer.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PokerDiscountTimerComponent extends PCComponent implements OnInit, OnDestroy {
    startTime: any;
    endTime: any;
    currentTimeInUTC: any;
    currentTime: any;
    hours: number;
    minutes: number;
    seconds: number;
    millisecondsleft: number;
    regEndMilliSecondsLeft: number;
    timeLeft: string;
    callback: any;
    registrationDetails: any;
    bannerDisplayTime: any;
    bannerKickoffInHours: number;
    data: any;
    isBannerEnabled: boolean = false;
    isRegistrationStarted: boolean = false;
    isRegistrationClosed: boolean = false;
    registrationStartText: string;
    registrationEndText: string;
    registrationClosedText: string;
    beforeRegistrationCTALink: string;
    beforeRegistrationCTAText: string;
    duringRegistrationCTALink: string;
    duringRegistrationCTAText: string;
    discountBulletin: string;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit() {
        this.data = this.item.totalRegistrationDetails;
        this.registrationStartText = this.item.registrationStartText;
        this.registrationEndText = this.item.registrationEndText;
        this.bannerKickoffInHours = this.item.bannerKickoffInHours;
        this.beforeRegistrationCTALink = this.item.beforeRegistrationCTALink.url;
        this.beforeRegistrationCTAText = this.item.beforeRegistrationCTAText;
        this.duringRegistrationCTALink = this.item.duringRegistrationCTALink.url;
        this.duringRegistrationCTAText = this.item.duringRegistrationCTAText;
        this.discountBulletin = this.item.discountBulletin;
        this.registrationClosedText = this.item.registrationClosedText;

        this.refresh();
        this.callback = setInterval(() => {
            this.refresh();
        }, 1000);
    }

    private refresh() {
        this.registrationDetails = this.data.registrationDetails;
        if (this.registrationDetails != null && this.registrationDetails.length > 0) {
            for (let r = 0; r < this.registrationDetails.length; r++) {
                this.currentTimeInUTC = new Date().toUTCString();
                this.currentTime = new Date(this.currentTimeInUTC);

                this.startTime = new Date(this.registrationDetails[r].registrationStartTime);
                this.endTime = new Date(this.registrationDetails[r].registrationEndTime);

                this.bannerDisplayTime = new Date(this.startTime);
                this.bannerDisplayTime.setHours(this.bannerDisplayTime.getHours() - this.bannerKickoffInHours);

                this.millisecondsleft = this.startTime - this.currentTime;
                this.regEndMilliSecondsLeft = this.endTime - this.currentTime;

                //if (this.currentTime.getDate() == this.startTime.getDate()) {

                if (this.currentTime > this.bannerDisplayTime && this.millisecondsleft > 0) {
                    this.isRegistrationClosed = false;
                    this.isBannerEnabled = true;
                    this.isRegistrationStarted = false;
                    //  this.days= Math.floor(this.millisecondsleft/86400000);
                    this.hours = Math.floor(this.millisecondsleft / 3600000);
                    this.minutes = Math.floor((this.millisecondsleft - this.hours * 3600000) / 60000);
                    this.seconds = Math.floor((this.millisecondsleft - this.hours * 3600000 - this.minutes * 60000) / 1000);
                    this.formatMessage(this.hours, this.minutes, this.seconds);
                    break;
                }
                if (this.currentTime > this.startTime && this.regEndMilliSecondsLeft > 0) {
                    this.isRegistrationClosed = false;
                    this.isBannerEnabled = false;
                    this.isRegistrationStarted = true;
                    //this.days= Math.floor(this.regEndMilliSecondsLeft/86400000);
                    this.hours = Math.floor(this.regEndMilliSecondsLeft / 3600000);
                    this.minutes = Math.floor((this.regEndMilliSecondsLeft - this.hours * 3600000) / 60000);
                    this.seconds = Math.floor((this.regEndMilliSecondsLeft - this.hours * 3600000 - this.minutes * 60000) / 1000);
                    this.formatMessage(this.hours, this.minutes, this.seconds);
                    break;
                } else {
                    this.timeLeft = '';
                    this.isBannerEnabled = false;
                    this.isRegistrationStarted = false;
                    this.isRegistrationClosed = true;
                }
                //}
            }
        }
    }

    private formatMessage(hours: number, minutes: number, seconds: number) {
        this.timeLeft = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    ngOnDestroy() {
        clearInterval(this.callback);
    }
}
