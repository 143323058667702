import { Message } from '../../message';

export class ReqEnablePlayerRunittwiceState extends Message {
    private MESSAGE_NAME: string = 'ReqEnablePlayerRunittwiceState';
    private playerRITState: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerRITState: number = 0) {
        super(reqServerPeerId);
        this.playerRITState = playerRITState;
    }

    getPlayerRITState(): number {
        return this.playerRITState;
    }

    setPlayerRITState(playerRITState: number) {
        this.playerRITState = playerRITState;
    }
}
