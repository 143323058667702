import { Message } from '../../message';
import { GameTableStatus } from './GameTableStatus';

export class MoveToTable extends Message {
    private MESSAGE_NAME: string = 'MoveToTable';
    private text: any | null = null;
    private gts: GameTableStatus | null = null;
    private timeOut: number = 0;
    private oldTableToBeRemoved: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        text: any | null = null,
        gts: GameTableStatus | null = null,
        timeOut: number = 0,
        oldTableToBeRemoved: number = 0,
    ) {
        super(reqServerPeerId);
        this.text = text;
        this.gts = gts;
        this.timeOut = timeOut;
        this.oldTableToBeRemoved = oldTableToBeRemoved;
    }

    getText(): any | null {
        return this.text;
    }

    setText(text: any | null) {
        this.text = text;
    }
    getGts(): GameTableStatus | null {
        return this.gts;
    }

    setGts(gts: GameTableStatus | null) {
        this.gts = gts;
    }
    getTimeOut(): number {
        return this.timeOut;
    }

    setTimeOut(timeOut: number) {
        this.timeOut = timeOut;
    }
    getOldTableToBeRemoved(): number {
        return this.oldTableToBeRemoved;
    }

    setOldTableToBeRemoved(oldTableToBeRemoved: number) {
        this.oldTableToBeRemoved = oldTableToBeRemoved;
    }
}
