import { Message } from '../../message';

export class OptionSet extends Message {
    private MESSAGE_NAME: string = 'OptionSet';
    private optionSetId: number = 0;
    private optionSet: any[] | null = null;
    private generatedAt: number = 0;
    private betTimeOutPeriod: number = 0;
    private timeBankAvailableTime: number = 0;
    private timeBankAutoEnable: boolean | null = false;
    private limitGameOptionSet: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        optionSetId: number = 0,
        optionSet: any[] | null = null,
        generatedAt: number = 0,
        betTimeOutPeriod: number = 0,
        timeBankAvailableTime: number = 0,
        timeBankAutoEnable: boolean | null = false,
        limitGameOptionSet: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.optionSetId = optionSetId;
        this.optionSet = optionSet;
        this.generatedAt = generatedAt;
        this.betTimeOutPeriod = betTimeOutPeriod;
        this.timeBankAvailableTime = timeBankAvailableTime;
        this.timeBankAutoEnable = timeBankAutoEnable;
        this.limitGameOptionSet = limitGameOptionSet;
    }

    getOptionSetId(): number {
        return this.optionSetId;
    }

    setOptionSetId(optionSetId: number) {
        this.optionSetId = optionSetId;
    }
    getOptionSet(): any[] | null {
        return this.optionSet;
    }

    setOptionSet(optionSet: any[] | null) {
        this.optionSet = optionSet;
    }
    getGeneratedAt(): number {
        return this.generatedAt;
    }

    setGeneratedAt(generatedAt: number) {
        this.generatedAt = generatedAt;
    }
    getBetTimeOutPeriod(): number {
        return this.betTimeOutPeriod;
    }

    setBetTimeOutPeriod(betTimeOutPeriod: number) {
        this.betTimeOutPeriod = betTimeOutPeriod;
    }
    getTimeBankAvailableTime(): number {
        return this.timeBankAvailableTime;
    }

    setTimeBankAvailableTime(timeBankAvailableTime: number) {
        this.timeBankAvailableTime = timeBankAvailableTime;
    }
    getTimeBankAutoEnable(): boolean | null {
        return this.timeBankAutoEnable;
    }

    setTimeBankAutoEnable(timeBankAutoEnable: boolean | null) {
        this.timeBankAutoEnable = timeBankAutoEnable;
    }
    getLimitGameOptionSet(): any[] | null {
        return this.limitGameOptionSet;
    }

    setLimitGameOptionSet(limitGameOptionSet: any[] | null) {
        this.limitGameOptionSet = limitGameOptionSet;
    }
}
