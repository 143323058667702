import { CommonModule } from '@angular/common';
import { Component, HostBinding, ViewChild } from '@angular/core';

import { PCComponent, PCComponentHeaderV2Component } from '@frontend/vanilla/features/content';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Swiper } from 'swiper';
import { Navigation, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

@Component({
    selector: 'poker-flickr-feed',
    templateUrl: 'poker-flickr-feed.component.html',
    standalone: true,
    imports: [CommonModule, SwiperComponent, PCComponentHeaderV2Component],
})
export class PokerFlickrFeedComponent extends PCComponent {
    public config: SwiperOptions = {
        modules: [Navigation, Virtual],
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    // @ViewChild(SwiperComponent) componentRef: SwiperComponent;
    @ViewChild('SwiperComponent', { static: false }) componentRef?: Swiper | undefined;

    flickrFeedData: any;

    @HostBinding()
    get class() {
        this.flickrFeedData = this.item.flickrData.items;
        return this.createClass(this.item.name);
    }

    ngOnInit() {
        if (window.innerWidth > 601) {
            this.config = Object.assign({}, this.config, { touchRatio: 0 });
        }
        this.config = Object.assign({}, this.config, {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            autoplay: false,
            loop: false,
            watchOverflow: true,
        });
    }

    //ngAfterViewInit() {
    /*const navContainer = document.querySelector('#navigation-container');
        [].slice.call(document.querySelectorAll('.swiper-button-next, .swiper-button-prev')).forEach((element: any) => {

            element.classList.add('swiper-button-white');

            if ([].slice.call(element.classList).indexOf('swiper-button-next') !== -1) {
                if (element.getAttribute('hidden') != null) { element.removeAttribute('hidden') }
                element.addEventListener('click', this.nextSlide.bind(this))
            } else {
                if (element.getAttribute('hidden') != null) { element.removeAttribute('hidden') }
                element.addEventListener('click', this.prevSlide.bind(this))
            }

            navContainer.appendChild(element);
        })*/
    //}
    update() {
        this.componentRef?.update();
    }

    nextSlide() {
        this.componentRef?.slideNext();
    }

    prevSlide() {
        this.componentRef?.slidePrev();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pause(e: Event) {
        this.componentRef?.autoplay.stop();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resume(e: Event) {
        this.componentRef?.autoplay.start();
    }
}
