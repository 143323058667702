import { Message } from '../../message';

export class ResponseLobbyUpdateOneClick extends Message {
    private MESSAGE_NAME: string = 'ResponseLobbyUpdateOneClick';
    private allCardsMap: Map<any, any> | null = null;
    private allCardsOrder: any[] | null = null;
    private topCardsOrder: any[] | null = null;
    private lobbyType: number = 0;
    private snapShotTimeInNanos: number = 0;
    private isFullUpdate: boolean | null = false;
    private deletedCardIds: any[] | null = null;
    private clearAllOneClickUpdate: boolean | null = false;
    private tourneyTicketCards: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        allCardsMap: Map<any, any> | null = null,
        allCardsOrder: any[] | null = null,
        topCardsOrder: any[] | null = null,
        lobbyType: number = 0,
        snapShotTimeInNanos: number = 0,
        isFullUpdate: boolean | null = false,
        deletedCardIds: any[] | null = null,
        clearAllOneClickUpdate: boolean | null = false,
        tourneyTicketCards: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.allCardsMap = allCardsMap;
        this.allCardsOrder = allCardsOrder;
        this.topCardsOrder = topCardsOrder;
        this.lobbyType = lobbyType;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.isFullUpdate = isFullUpdate;
        this.deletedCardIds = deletedCardIds;
        this.clearAllOneClickUpdate = clearAllOneClickUpdate;
        this.tourneyTicketCards = tourneyTicketCards;
    }

    getAllCardsMap(): Map<any, any> | null {
        return this.allCardsMap;
    }

    setAllCardsMap(allCardsMap: Map<any, any> | null) {
        this.allCardsMap = allCardsMap;
    }
    getAllCardsOrder(): any[] | null {
        return this.allCardsOrder;
    }

    setAllCardsOrder(allCardsOrder: any[] | null) {
        this.allCardsOrder = allCardsOrder;
    }
    getTopCardsOrder(): any[] | null {
        return this.topCardsOrder;
    }

    setTopCardsOrder(topCardsOrder: any[] | null) {
        this.topCardsOrder = topCardsOrder;
    }
    getLobbyType(): number {
        return this.lobbyType;
    }

    setLobbyType(lobbyType: number) {
        this.lobbyType = lobbyType;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getIsFullUpdate(): boolean | null {
        return this.isFullUpdate;
    }

    setIsFullUpdate(isFullUpdate: boolean | null) {
        this.isFullUpdate = isFullUpdate;
    }
    getDeletedCardIds(): any[] | null {
        return this.deletedCardIds;
    }

    setDeletedCardIds(deletedCardIds: any[] | null) {
        this.deletedCardIds = deletedCardIds;
    }
    getClearAllOneClickUpdate(): boolean | null {
        return this.clearAllOneClickUpdate;
    }

    setClearAllOneClickUpdate(clearAllOneClickUpdate: boolean | null) {
        this.clearAllOneClickUpdate = clearAllOneClickUpdate;
    }
    getTourneyTicketCards(): any[] | null {
        return this.tourneyTicketCards;
    }

    setTourneyTicketCards(tourneyTicketCards: any[] | null) {
        this.tourneyTicketCards = tourneyTicketCards;
    }
}
