import { Routes } from '@angular/router';

import { PokerPowerSeriesSynopsisResolver } from './poker-powerseries-synopsis.resolver';
import { PokerPowerSeriesResolver } from './poker-powerseries.resolver';

export const pokerPowerSeriesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPowerSeriesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerPowerSeriesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPowerSeriesResolver,
        },
    },
];
