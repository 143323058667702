import { Routes } from '@angular/router';

import { PokerHotTablesSynopsisResolver } from './poker-hot-tables-synopsis.resolver';
import { PokerHotTablesResolver } from './poker-hot-tables.resolver';

export const pokerHotTablesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerHotTablesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerHotTablesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerHotTablesResolver,
        },
    },
];
