import { Message } from '../../message';

export class LSMixMaxEntry extends Message {
    private MESSAGE_NAME: string = 'LSMixMaxEntry';
    private startRange: number = 0;
    private endRange: number = 0;
    private value: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, startRange: number = 0, endRange: number = 0, value: number = 0) {
        super(reqServerPeerId);
        this.startRange = startRange;
        this.endRange = endRange;
        this.value = value;
    }

    getStartRange(): number {
        return this.startRange;
    }

    setStartRange(startRange: number) {
        this.startRange = startRange;
    }
    getEndRange(): number {
        return this.endRange;
    }

    setEndRange(endRange: number) {
        this.endRange = endRange;
    }
    getValue(): number {
        return this.value;
    }

    setValue(value: number) {
        this.value = value;
    }
}
