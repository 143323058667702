import { Message } from '../../message';

export class ChangePassword extends Message {
    private MESSAGE_NAME: string = 'ChangePassword';
    private currentPassword: string | null = null;
    private newPassword: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currentPassword: string | null = null, newPassword: string | null = null) {
        super(reqServerPeerId);
        this.currentPassword = currentPassword;
        this.newPassword = newPassword;
    }

    getCurrentPassword(): string | null {
        return this.currentPassword;
    }

    setCurrentPassword(currentPassword: string | null) {
        this.currentPassword = currentPassword;
    }
    getNewPassword(): string | null {
        return this.newPassword;
    }

    setNewPassword(newPassword: string | null) {
        this.newPassword = newPassword;
    }
}
