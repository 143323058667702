import { TSDataInputStream } from '../../../sal/dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from '../../../sal/dataInputOutputStream/TSDataOutputStream';
import { Message } from '../message';
import { PGMessageFactory } from '../pgmessagefactory';
import { AccountCurrencyChange } from './messages/AccountCurrencyChange';
import { AccountTemplateParam } from './messages/AccountTemplateParam';
import { AutoLoginRequest } from './messages/AutoLoginRequest';
import { AvatarUpdateEventMessage } from './messages/AvatarUpdateEventMessage';
import { BalanceInfo } from './messages/BalanceInfo';
import { BonusInfo } from './messages/BonusInfo';
import { ChangeAvatar } from './messages/ChangeAvatar';
import { ChangeBounceEmail } from './messages/ChangeBounceEmail';
import { ChangeEmail } from './messages/ChangeEmail';
import { ChangePassword } from './messages/ChangePassword';
import { ClientConfig } from './messages/ClientConfig';
import { ClientSwitchMessage } from './messages/ClientSwitchMessage';
import { CulturalFormat } from './messages/CulturalFormat';
import { CurrencyDetails } from './messages/CurrencyDetails';
import { CurrencyDetailsList } from './messages/CurrencyDetailsList';
import { CurrencyDetailsRequest } from './messages/CurrencyDetailsRequest';
import { CurrencyRollback } from './messages/CurrencyRollback';
import { DebugInfo } from './messages/DebugInfo';
import { DownloadAndExecute } from './messages/DownloadAndExecute';
import { DynamicDLLRequest } from './messages/DynamicDLLRequest';
import { DynamicLanguagePackUpdate } from './messages/DynamicLanguagePackUpdate';
import { DynamicMsgNotProcessed } from './messages/DynamicMsgNotProcessed';
import { DynamicStringEx } from './messages/DynamicStringEx';
import { EDSNGRequestMessage } from './messages/EDSNGRequestMessage';
import { EDSNGResponseMessage } from './messages/EDSNGResponseMessage';
import { FXRateRequest } from './messages/FXRateRequest';
import { ForceLogoutFromPartner } from './messages/ForceLogoutFromPartner';
import { ForgotPassword } from './messages/ForgotPassword';
import { GameTypeInfo } from './messages/GameTypeInfo';
import { GameVariantBonus } from './messages/GameVariantBonus';
import { GenInfo } from './messages/GenInfo';
import { GetPAMArticleContentFiles } from './messages/GetPAMArticleContentFiles';
import { GetRegistryValue } from './messages/GetRegistryValue';
import { IdleInfo } from './messages/IdleInfo';
import { InstallDynamicDLL } from './messages/InstallDynamicDLL';
import { InstallDynamicDLLStatus } from './messages/InstallDynamicDLLStatus';
import { InstallLowLevelHook } from './messages/InstallLowLevelHook';
import { KeyValuePair } from './messages/KeyValuePair';
import { LanguagePackUpdate } from './messages/LanguagePackUpdate';
import { LastLoginTime } from './messages/LastLoginTime';
import { LoginDenial } from './messages/LoginDenial';
import { LoginFailureResponse } from './messages/LoginFailureResponse';
import { LoginIncompleteLaunchURL } from './messages/LoginIncompleteLaunchURL';
import { LoginResponse } from './messages/LoginResponse';
import { LoginServiceUnavailable } from './messages/LoginServiceUnavailable';
import { LoyaltyInfo } from './messages/LoyaltyInfo';
import { NetRealBalance } from './messages/NetRealBalance';
import { NotifyPlayerProfileWithSSOKey } from './messages/NotifyPlayerProfileWithSSOKey';
import { OTPResponse } from './messages/OTPResponse';
import { OldUpgradeInfo } from './messages/OldUpgradeInfo';
import { PAMArticle } from './messages/PAMArticle';
import { PAMArticleList } from './messages/PAMArticleList';
import { PanelMessage } from './messages/PanelMessage';
import { PartyInboxInfo } from './messages/PartyInboxInfo';
import { PlayAllowedDays } from './messages/PlayAllowedDays';
import { PlayBalance } from './messages/PlayBalance';
import { PopUpInfo } from './messages/PopUpInfo';
import { PopUpInfoEx } from './messages/PopUpInfoEx';
import { PopUpInfoExML } from './messages/PopUpInfoExML';
import { PopUpInfoExResponse } from './messages/PopUpInfoExResponse';
import { PreliminaryAccountCurrency } from './messages/PreliminaryAccountCurrency';
import { PromptLogin } from './messages/PromptLogin';
import { PromptScreenName } from './messages/PromptScreenName';
import { RTPointsUpdate } from './messages/RTPointsUpdate';
import { RealBalance } from './messages/RealBalance';
import { RegProfile } from './messages/RegProfile';
import { Registration } from './messages/Registration';
import { RequestAllinsRemaining } from './messages/RequestAllinsRemaining';
import { RequestAllinsReset } from './messages/RequestAllinsReset';
import { RequestBounceEmailDontRemind } from './messages/RequestBounceEmailDontRemind';
import { RequestBounceEmailRemindLater } from './messages/RequestBounceEmailRemindLater';
import { RequestJackpotPanel } from './messages/RequestJackpotPanel';
import { RequestJackpotPromoMonsterPanel } from './messages/RequestJackpotPromoMonsterPanel';
import { RequestMigrationKeepOldCurrency } from './messages/RequestMigrationKeepOldCurrency';
import { RequestMyAccountPanelFull } from './messages/RequestMyAccountPanelFull';
import { RequestMyAccountPanelHalf } from './messages/RequestMyAccountPanelHalf';
import { RequestNetUserBalanceInfo } from './messages/RequestNetUserBalanceInfo';
import { RequestReconnectOnUserAction } from './messages/RequestReconnectOnUserAction';
import { RequestSendValidationCode } from './messages/RequestSendValidationCode';
import { RequestTermsAndConditionsAcceptance } from './messages/RequestTermsAndConditionsAcceptance';
import { RequestToStayORLogOutOnUserAction } from './messages/RequestToStayORLogOutOnUserAction';
import { RequestUserBalanceInfo } from './messages/RequestUserBalanceInfo';
import { ResponseChangeBounceDupEmailFail } from './messages/ResponseChangeBounceDupEmailFail';
import { ResponseChangeBounceEmailDBError } from './messages/ResponseChangeBounceEmailDBError';
import { ResponseChangeBounceEmailFail } from './messages/ResponseChangeBounceEmailFail';
import { ResponseChangeBounceEmailSuccess } from './messages/ResponseChangeBounceEmailSuccess';
import { ResponseChangeEMailDBError } from './messages/ResponseChangeEMailDBError';
import { ResponseChangeEMailFail } from './messages/ResponseChangeEMailFail';
import { ResponseChangeEmailSuccess } from './messages/ResponseChangeEmailSuccess';
import { ResponseChangePasswordDBError } from './messages/ResponseChangePasswordDBError';
import { ResponseChangePasswordFailed } from './messages/ResponseChangePasswordFailed';
import { ResponseChangePasswordSuccess } from './messages/ResponseChangePasswordSuccess';
import { ResponseConvertedToReal } from './messages/ResponseConvertedToReal';
import { ResponseForgotPasswordFailed } from './messages/ResponseForgotPasswordFailed';
import { ResponseForgotPasswordSuccess } from './messages/ResponseForgotPasswordSuccess';
import { ResponseInvalidBonusCode } from './messages/ResponseInvalidBonusCode';
import { ResponseKeepOldAcctccySuccess } from './messages/ResponseKeepOldAcctccySuccess';
import { ResponseLogoutSuccess } from './messages/ResponseLogoutSuccess';
import { ResponseNetUserBalanceInfo } from './messages/ResponseNetUserBalanceInfo';
import { ResponseNetUserBalanceInfoError } from './messages/ResponseNetUserBalanceInfoError';
import { ResponseRegistrationDuplicateMailId } from './messages/ResponseRegistrationDuplicateMailId';
import { ResponseRegistrationIDNotAvailable } from './messages/ResponseRegistrationIDNotAvailable';
import { ResponseRegistrationMultiBrandChkFailed } from './messages/ResponseRegistrationMultiBrandChkFailed';
import { ResponseRegistrationSuccess } from './messages/ResponseRegistrationSuccess';
import { ResponseReloginDBError } from './messages/ResponseReloginDBError';
import { ResponseReloginInvalidSessionKey } from './messages/ResponseReloginInvalidSessionKey';
import { ResponseReloginServerOverloaded } from './messages/ResponseReloginServerOverloaded';
import { ResponseReloginSuccess } from './messages/ResponseReloginSuccess';
import { ResponseRevisitServerOverLoaded } from './messages/ResponseRevisitServerOverLoaded';
import { ResponseRevisitSuccess } from './messages/ResponseRevisitSuccess';
import { ResponseTellaFriendDBError } from './messages/ResponseTellaFriendDBError';
import { ResponseTellaFriendFailed } from './messages/ResponseTellaFriendFailed';
import { ResponseTellaFriendNotLoggedIn } from './messages/ResponseTellaFriendNotLoggedIn';
import { ResponseTellaFriendSuccess } from './messages/ResponseTellaFriendSuccess';
import { ResponseTermsAndConditionsAcceptance } from './messages/ResponseTermsAndConditionsAcceptance';
import { ResponseUserAcctCCYNotMatching } from './messages/ResponseUserAcctCCYNotMatching';
import { ResponseUserBalanceInfo } from './messages/ResponseUserBalanceInfo';
import { ResponseUserBalanceInfoError } from './messages/ResponseUserBalanceInfoError';
import { ResponseValidateCodeFail } from './messages/ResponseValidateCodeFail';
import { ResponseValidateCodeSuccess } from './messages/ResponseValidateCodeSuccess';
import { ResponseValidationCodeSendFailed } from './messages/ResponseValidationCodeSendFailed';
import { ResponseValidationCodeSendSuccess } from './messages/ResponseValidationCodeSendSuccess';
import { ResponseWordVerifyFailure } from './messages/ResponseWordVerifyFailure';
import { SaveFile } from './messages/SaveFile';
import { SecurityTokenOTPRequest } from './messages/SecurityTokenOTPRequest';
import { SelectedScreenName } from './messages/SelectedScreenName';
import { SendProfile } from './messages/SendProfile';
import { ServerUtilityRequest } from './messages/ServerUtilityRequest';
import { ServerUtilityResponse } from './messages/ServerUtilityResponse';
import { SessionLimitLogout } from './messages/SessionLimitLogout';
import { ShowScreen } from './messages/ShowScreen';
import { ShowScreenResponse } from './messages/ShowScreenResponse';
import { ShowURL } from './messages/ShowURL';
import { StringMapResponse } from './messages/StringMapResponse';
import { SuspiciousLocationAnswers } from './messages/SuspiciousLocationAnswers';
import { SuspiciousLocationQuestions } from './messages/SuspiciousLocationQuestions';
import { SystemConfigDetails } from './messages/SystemConfigDetails';
import { TakeScreenShot } from './messages/TakeScreenShot';
import { TimeZoneDetails } from './messages/TimeZoneDetails';
import { TokenVerificationRequest } from './messages/TokenVerificationRequest';
import { TourneyCurrencyBalance } from './messages/TourneyCurrencyBalance';
import { UpdateUserPropSettings } from './messages/UpdateUserPropSettings';
import { UserPropertySettings } from './messages/UserPropertySettings';
import { ValidateEmail } from './messages/ValidateEmail';

export class ARAMessageFactory extends PGMessageFactory {
    override classes: Map<number, any> = new Map<number, any>();
    override ids: Map<any, number> = new Map<any, number>();

    constructor() {
        super();
        this.classes.set(28680, ResponseLogoutSuccess);
        this.classes.set(28683, ServerUtilityRequest);
        this.classes.set(28684, ServerUtilityResponse);
        this.classes.set(40961, ChangePassword);
        this.classes.set(40962, ForgotPassword);
        this.classes.set(40964, GetRegistryValue);
        this.classes.set(40965, ChangeEmail);
        this.classes.set(40966, InstallDynamicDLLStatus);
        this.classes.set(40967, SelectedScreenName);
        this.classes.set(40968, TakeScreenShot);
        this.classes.set(40971, ValidateEmail);
        this.classes.set(40974, DynamicMsgNotProcessed);
        this.classes.set(40975, GenInfo);
        this.classes.set(40976, DebugInfo);
        this.classes.set(40977, UpdateUserPropSettings);
        this.classes.set(40978, ShowURL);
        this.classes.set(40982, KeyValuePair);
        this.classes.set(40983, PopUpInfoEx);
        this.classes.set(40984, PopUpInfoExResponse);
        this.classes.set(40985, ClientConfig);
        this.classes.set(40987, UserPropertySettings);
        this.classes.set(40988, InstallLowLevelHook);
        this.classes.set(40989, RegProfile);
        this.classes.set(40990, DynamicDLLRequest);
        this.classes.set(40991, InstallDynamicDLL);
        this.classes.set(40992, ShowScreenResponse);
        this.classes.set(40994, PanelMessage);
        this.classes.set(40996, SystemConfigDetails);
        this.classes.set(40997, DynamicStringEx);
        this.classes.set(40998, PopUpInfoExML);
        this.classes.set(40999, ShowScreen);
        this.classes.set(41000, PopUpInfo);
        this.classes.set(41001, PromptLogin);
        this.classes.set(41002, OldUpgradeInfo);
        this.classes.set(41003, PromptScreenName);
        this.classes.set(41005, DownloadAndExecute);
        this.classes.set(41009, LoginResponse);
        this.classes.set(41010, Registration);
        this.classes.set(41011, SendProfile);
        this.classes.set(41013, GetPAMArticleContentFiles);
        this.classes.set(41014, SaveFile);
        this.classes.set(41015, LanguagePackUpdate);
        this.classes.set(41016, DynamicLanguagePackUpdate);
        this.classes.set(41018, CurrencyDetailsRequest);
        this.classes.set(41019, FXRateRequest);
        this.classes.set(41020, CurrencyDetailsList);
        this.classes.set(41023, CurrencyDetails);
        this.classes.set(41027, CulturalFormat);
        this.classes.set(41028, CurrencyRollback);
        this.classes.set(41029, AccountCurrencyChange);
        this.classes.set(41030, PreliminaryAccountCurrency);
        this.classes.set(41033, AccountTemplateParam);
        this.classes.set(41036, PlayAllowedDays);
        this.classes.set(41046, ChangeAvatar);
        this.classes.set(41047, TimeZoneDetails);
        this.classes.set(41050, SuspiciousLocationQuestions);
        this.classes.set(41051, SuspiciousLocationAnswers);
        this.classes.set(41052, ChangeBounceEmail);
        this.classes.set(41058, TokenVerificationRequest);
        this.classes.set(41059, SecurityTokenOTPRequest);
        this.classes.set(41060, OTPResponse);
        this.classes.set(41064, StringMapResponse);
        this.classes.set(41065, PAMArticle);
        this.classes.set(41066, PAMArticleList);
        this.classes.set(41072, RequestMigrationKeepOldCurrency);
        this.classes.set(41073, RequestMyAccountPanelFull);
        this.classes.set(41074, RequestMyAccountPanelHalf);
        this.classes.set(41079, ResponseChangeBounceDupEmailFail);
        this.classes.set(41080, ResponseChangeBounceEmailDBError);
        this.classes.set(41081, ResponseChangeBounceEmailFail);
        this.classes.set(41082, ResponseChangeBounceEmailSuccess);
        this.classes.set(41083, ResponseChangeEMailDBError);
        this.classes.set(41084, ResponseChangeEMailFail);
        this.classes.set(41085, ResponseChangeEmailSuccess);
        this.classes.set(41086, ResponseChangePasswordDBError);
        this.classes.set(41087, ResponseChangePasswordFailed);
        this.classes.set(41088, ResponseChangePasswordSuccess);
        this.classes.set(41089, ResponseConvertedToReal);
        this.classes.set(41092, ResponseForgotPasswordFailed);
        this.classes.set(41093, ResponseForgotPasswordSuccess);
        this.classes.set(41095, ResponseKeepOldAcctccySuccess);
        this.classes.set(41101, ResponseRegistrationSuccess);
        this.classes.set(41106, ResponseTellaFriendSuccess);
        this.classes.set(41107, ResponseTellaFriendDBError);
        this.classes.set(41108, ResponseTellaFriendFailed);
        this.classes.set(41109, ResponseTellaFriendNotLoggedIn);
        this.classes.set(41112, ResponseUserAcctCCYNotMatching);
        this.classes.set(41113, ResponseValidateCodeFail);
        this.classes.set(41114, ResponseValidateCodeSuccess);
        this.classes.set(41115, ResponseValidationCodeSendFailed);
        this.classes.set(41116, ResponseValidationCodeSendSuccess);
        this.classes.set(41118, RequestJackpotPanel);
        this.classes.set(41119, RequestSendValidationCode);
        this.classes.set(41122, RequestBounceEmailDontRemind);
        this.classes.set(41123, RequestBounceEmailRemindLater);
        this.classes.set(41129, ResponseRegistrationIDNotAvailable);
        this.classes.set(41130, ResponseRegistrationDuplicateMailId);
        this.classes.set(41131, ResponseRegistrationMultiBrandChkFailed);
        this.classes.set(41132, ResponseReloginInvalidSessionKey);
        this.classes.set(41133, ResponseReloginDBError);
        this.classes.set(41134, ResponseReloginServerOverloaded);
        this.classes.set(41135, ResponseRevisitServerOverLoaded);
        this.classes.set(41136, ResponseInvalidBonusCode);
        this.classes.set(41137, ResponseRevisitSuccess);
        this.classes.set(41138, ResponseReloginSuccess);
        this.classes.set(41139, RequestAllinsRemaining);
        this.classes.set(41140, RequestAllinsReset);
        this.classes.set(41144, RequestJackpotPromoMonsterPanel);
        this.classes.set(41153, ResponseWordVerifyFailure);
        this.classes.set(41157, LastLoginTime);
        this.classes.set(41158, BalanceInfo);
        this.classes.set(41159, LoyaltyInfo);
        this.classes.set(41160, PartyInboxInfo);
        this.classes.set(41161, BonusInfo);
        this.classes.set(41165, LoginDenial);
        this.classes.set(41180, RequestTermsAndConditionsAcceptance);
        this.classes.set(41181, ResponseTermsAndConditionsAcceptance);
        this.classes.set(41185, EDSNGRequestMessage);
        this.classes.set(41186, EDSNGResponseMessage);
        this.classes.set(41196, IdleInfo);
        this.classes.set(41197, RequestReconnectOnUserAction);
        this.classes.set(41198, AutoLoginRequest);
        this.classes.set(41200, LoginFailureResponse);
        this.classes.set(41201, LoginIncompleteLaunchURL);
        this.classes.set(41202, LoginServiceUnavailable);
        this.classes.set(41207, ClientSwitchMessage);
        this.classes.set(41209, AvatarUpdateEventMessage);
        this.classes.set(41210, GameTypeInfo);
        this.classes.set(41211, GameVariantBonus);
        this.classes.set(41212, PlayBalance);
        this.classes.set(41213, RealBalance);
        this.classes.set(41214, RequestUserBalanceInfo);
        this.classes.set(41215, TourneyCurrencyBalance);
        this.classes.set(41216, ResponseUserBalanceInfo);
        this.classes.set(41217, ResponseUserBalanceInfoError);
        this.classes.set(41218, NotifyPlayerProfileWithSSOKey);
        this.classes.set(41219, NetRealBalance);
        this.classes.set(41220, RequestNetUserBalanceInfo);
        this.classes.set(41221, ResponseNetUserBalanceInfo);
        this.classes.set(41222, ResponseNetUserBalanceInfoError);
        this.classes.set(41223, SessionLimitLogout);
        this.classes.set(41224, RequestToStayORLogOutOnUserAction);
        this.classes.set(69633, RTPointsUpdate);
        this.classes.set(73729, ForceLogoutFromPartner);
        for (const [key, value] of this.classes.entries()) {
            this.ids.set(value, key);
        }
    }

    read(obj: Message, inStream: TSDataInputStream) {
        const classId = this.getClassId(obj.constructor);
        let result = false;
        //  console.log(classId);
        switch (classId) {
            case 28680:
                this.readResponseLogoutSuccess(inStream, obj as ResponseLogoutSuccess);
                result = true;
                break;
            case 28683:
                this.readServerUtilityRequest(inStream, obj as ServerUtilityRequest);
                result = true;
                break;
            case 28684:
                this.readServerUtilityResponse(inStream, obj as ServerUtilityResponse);
                result = true;
                break;
            case 40961:
                this.readChangePassword(inStream, obj as ChangePassword);
                result = true;
                break;
            case 40962:
                this.readForgotPassword(inStream, obj as ForgotPassword);
                result = true;
                break;
            case 40964:
                this.readGetRegistryValue(inStream, obj as GetRegistryValue);
                result = true;
                break;
            case 40965:
                this.readChangeEmail(inStream, obj as ChangeEmail);
                result = true;
                break;
            case 40966:
                this.readInstallDynamicDLLStatus(inStream, obj as InstallDynamicDLLStatus);
                result = true;
                break;
            case 40967:
                this.readSelectedScreenName(inStream, obj as SelectedScreenName);
                result = true;
                break;
            case 40968:
                this.readTakeScreenShot(inStream, obj as TakeScreenShot);
                result = true;
                break;
            case 40971:
                this.readValidateEmail(inStream, obj as ValidateEmail);
                result = true;
                break;
            case 40974:
                this.readDynamicMsgNotProcessed(inStream, obj as DynamicMsgNotProcessed);
                result = true;
                break;
            case 40975:
                this.readGenInfo(inStream, obj as GenInfo);
                result = true;
                break;
            case 40976:
                this.readDebugInfo(inStream, obj as DebugInfo);
                result = true;
                break;
            case 40977:
                this.readUpdateUserPropSettings(inStream, obj as UpdateUserPropSettings);
                result = true;
                break;
            case 40978:
                this.readShowURL(inStream, obj as ShowURL);
                result = true;
                break;
            case 40982:
                this.readKeyValuePair(inStream, obj as KeyValuePair);
                result = true;
                break;
            case 40983:
                this.readPopUpInfoEx(inStream, obj as PopUpInfoEx);
                result = true;
                break;
            case 40984:
                this.readPopUpInfoExResponse(inStream, obj as PopUpInfoExResponse);
                result = true;
                break;
            case 40985:
                this.readClientConfig(inStream, obj as ClientConfig);
                result = true;
                break;
            case 40987:
                this.readUserPropertySettings(inStream, obj as UserPropertySettings);
                result = true;
                break;
            case 40988:
                this.readInstallLowLevelHook(inStream, obj as InstallLowLevelHook);
                result = true;
                break;
            case 40989:
                this.readRegProfile(inStream, obj as RegProfile);
                result = true;
                break;
            case 40990:
                this.readDynamicDLLRequest(inStream, obj as DynamicDLLRequest);
                result = true;
                break;
            case 40991:
                this.readInstallDynamicDLL(inStream, obj as InstallDynamicDLL);
                result = true;
                break;
            case 40992:
                this.readShowScreenResponse(inStream, obj as ShowScreenResponse);
                result = true;
                break;
            case 40994:
                this.readPanelMessage(inStream, obj as PanelMessage);
                result = true;
                break;
            case 40996:
                this.readSystemConfigDetails(inStream, obj as SystemConfigDetails);
                result = true;
                break;
            case 40997:
                this.readDynamicStringEx(inStream, obj as DynamicStringEx);
                result = true;
                break;
            case 40998:
                this.readPopUpInfoExML(inStream, obj as PopUpInfoExML);
                result = true;
                break;
            case 40999:
                this.readShowScreen(inStream, obj as ShowScreen);
                result = true;
                break;
            case 41000:
                this.readPopUpInfo(inStream, obj as PopUpInfo);
                result = true;
                break;
            case 41001:
                this.readPromptLogin(inStream, obj as PromptLogin);
                result = true;
                break;
            case 41002:
                this.readOldUpgradeInfo(inStream, obj as OldUpgradeInfo);
                result = true;
                break;
            case 41003:
                this.readPromptScreenName(inStream, obj as PromptScreenName);
                result = true;
                break;
            case 41005:
                this.readDownloadAndExecute(inStream, obj as DownloadAndExecute);
                result = true;
                break;
            case 41009:
                this.readLoginResponse(inStream, obj as LoginResponse);
                result = true;
                break;
            case 41010:
                this.readRegistration(inStream, obj as Registration);
                result = true;
                break;
            case 41011:
                this.readSendProfile(inStream, obj as SendProfile);
                result = true;
                break;
            case 41013:
                this.readGetPAMArticleContentFiles(inStream, obj as GetPAMArticleContentFiles);
                result = true;
                break;
            case 41014:
                this.readSaveFile(inStream, obj as SaveFile);
                result = true;
                break;
            case 41015:
                this.readLanguagePackUpdate(inStream, obj as LanguagePackUpdate);
                result = true;
                break;
            case 41016:
                this.readDynamicLanguagePackUpdate(inStream, obj as DynamicLanguagePackUpdate);
                result = true;
                break;
            case 41018:
                this.readCurrencyDetailsRequest(inStream, obj as CurrencyDetailsRequest);
                result = true;
                break;
            case 41019:
                this.readFXRateRequest(inStream, obj as FXRateRequest);
                result = true;
                break;
            case 41020:
                this.readCurrencyDetailsList(inStream, obj as CurrencyDetailsList);
                result = true;
                break;
            case 41023:
                this.readCurrencyDetails(inStream, obj as CurrencyDetails);
                result = true;
                break;
            case 41027:
                this.readCulturalFormat(inStream, obj as CulturalFormat);
                result = true;
                break;
            case 41028:
                this.readCurrencyRollback(inStream, obj as CurrencyRollback);
                result = true;
                break;
            case 41029:
                this.readAccountCurrencyChange(inStream, obj as AccountCurrencyChange);
                result = true;
                break;
            case 41030:
                this.readPreliminaryAccountCurrency(inStream, obj as PreliminaryAccountCurrency);
                result = true;
                break;
            case 41033:
                this.readAccountTemplateParam(inStream, obj as AccountTemplateParam);
                result = true;
                break;
            case 41036:
                this.readPlayAllowedDays(inStream, obj as PlayAllowedDays);
                result = true;
                break;
            case 41046:
                this.readChangeAvatar(inStream, obj as ChangeAvatar);
                result = true;
                break;
            case 41047:
                this.readTimeZoneDetails(inStream, obj as TimeZoneDetails);
                result = true;
                break;
            case 41050:
                this.readSuspiciousLocationQuestions(inStream, obj as SuspiciousLocationQuestions);
                result = true;
                break;
            case 41051:
                this.readSuspiciousLocationAnswers(inStream, obj as SuspiciousLocationAnswers);
                result = true;
                break;
            case 41052:
                this.readChangeBounceEmail(inStream, obj as ChangeBounceEmail);
                result = true;
                break;
            case 41058:
                this.readTokenVerificationRequest(inStream, obj as TokenVerificationRequest);
                result = true;
                break;
            case 41059:
                this.readSecurityTokenOTPRequest(inStream, obj as SecurityTokenOTPRequest);
                result = true;
                break;
            case 41060:
                this.readOTPResponse(inStream, obj as OTPResponse);
                result = true;
                break;
            case 41064:
                this.readStringMapResponse(inStream, obj as StringMapResponse);
                result = true;
                break;
            case 41065:
                this.readPAMArticle(inStream, obj as PAMArticle);
                result = true;
                break;
            case 41066:
                this.readPAMArticleList(inStream, obj as PAMArticleList);
                result = true;
                break;
            case 41072:
                this.readRequestMigrationKeepOldCurrency(inStream, obj as RequestMigrationKeepOldCurrency);
                result = true;
                break;
            case 41073:
                this.readRequestMyAccountPanelFull(inStream, obj as RequestMyAccountPanelFull);
                result = true;
                break;
            case 41074:
                this.readRequestMyAccountPanelHalf(inStream, obj as RequestMyAccountPanelHalf);
                result = true;
                break;
            case 41079:
                this.readResponseChangeBounceDupEmailFail(inStream, obj as ResponseChangeBounceDupEmailFail);
                result = true;
                break;
            case 41080:
                this.readResponseChangeBounceEmailDBError(inStream, obj as ResponseChangeBounceEmailDBError);
                result = true;
                break;
            case 41081:
                this.readResponseChangeBounceEmailFail(inStream, obj as ResponseChangeBounceEmailFail);
                result = true;
                break;
            case 41082:
                this.readResponseChangeBounceEmailSuccess(inStream, obj as ResponseChangeBounceEmailSuccess);
                result = true;
                break;
            case 41083:
                this.readResponseChangeEMailDBError(inStream, obj as ResponseChangeEMailDBError);
                result = true;
                break;
            case 41084:
                this.readResponseChangeEMailFail(inStream, obj as ResponseChangeEMailFail);
                result = true;
                break;
            case 41085:
                this.readResponseChangeEmailSuccess(inStream, obj as ResponseChangeEmailSuccess);
                result = true;
                break;
            case 41086:
                this.readResponseChangePasswordDBError(inStream, obj as ResponseChangePasswordDBError);
                result = true;
                break;
            case 41087:
                this.readResponseChangePasswordFailed(inStream, obj as ResponseChangePasswordFailed);
                result = true;
                break;
            case 41088:
                this.readResponseChangePasswordSuccess(inStream, obj as ResponseChangePasswordSuccess);
                result = true;
                break;
            case 41089:
                this.readResponseConvertedToReal(inStream, obj as ResponseConvertedToReal);
                result = true;
                break;
            case 41092:
                this.readResponseForgotPasswordFailed(inStream, obj as ResponseForgotPasswordFailed);
                result = true;
                break;
            case 41093:
                this.readResponseForgotPasswordSuccess(inStream, obj as ResponseForgotPasswordSuccess);
                result = true;
                break;
            case 41095:
                this.readResponseKeepOldAcctccySuccess(inStream, obj as ResponseKeepOldAcctccySuccess);
                result = true;
                break;
            case 41101:
                this.readResponseRegistrationSuccess(inStream, obj as ResponseRegistrationSuccess);
                result = true;
                break;
            case 41106:
                this.readResponseTellaFriendSuccess(inStream, obj as ResponseTellaFriendSuccess);
                result = true;
                break;
            case 41107:
                this.readResponseTellaFriendDBError(inStream, obj as ResponseTellaFriendDBError);
                result = true;
                break;
            case 41108:
                this.readResponseTellaFriendFailed(inStream, obj as ResponseTellaFriendFailed);
                result = true;
                break;
            case 41109:
                this.readResponseTellaFriendNotLoggedIn(inStream, obj as ResponseTellaFriendNotLoggedIn);
                result = true;
                break;
            case 41112:
                this.readResponseUserAcctCCYNotMatching(inStream, obj as ResponseUserAcctCCYNotMatching);
                result = true;
                break;
            case 41113:
                this.readResponseValidateCodeFail(inStream, obj as ResponseValidateCodeFail);
                result = true;
                break;
            case 41114:
                this.readResponseValidateCodeSuccess(inStream, obj as ResponseValidateCodeSuccess);
                result = true;
                break;
            case 41115:
                this.readResponseValidationCodeSendFailed(inStream, obj as ResponseValidationCodeSendFailed);
                result = true;
                break;
            case 41116:
                this.readResponseValidationCodeSendSuccess(inStream, obj as ResponseValidationCodeSendSuccess);
                result = true;
                break;
            case 41118:
                this.readRequestJackpotPanel(inStream, obj as RequestJackpotPanel);
                result = true;
                break;
            case 41119:
                this.readRequestSendValidationCode(inStream, obj as RequestSendValidationCode);
                result = true;
                break;
            case 41122:
                this.readRequestBounceEmailDontRemind(inStream, obj as RequestBounceEmailDontRemind);
                result = true;
                break;
            case 41123:
                this.readRequestBounceEmailRemindLater(inStream, obj as RequestBounceEmailRemindLater);
                result = true;
                break;
            case 41129:
                this.readResponseRegistrationIDNotAvailable(inStream, obj as ResponseRegistrationIDNotAvailable);
                result = true;
                break;
            case 41130:
                this.readResponseRegistrationDuplicateMailId(inStream, obj as ResponseRegistrationDuplicateMailId);
                result = true;
                break;
            case 41131:
                this.readResponseRegistrationMultiBrandChkFailed(inStream, obj as ResponseRegistrationMultiBrandChkFailed);
                result = true;
                break;
            case 41132:
                this.readResponseReloginInvalidSessionKey(inStream, obj as ResponseReloginInvalidSessionKey);
                result = true;
                break;
            case 41133:
                this.readResponseReloginDBError(inStream, obj as ResponseReloginDBError);
                result = true;
                break;
            case 41134:
                this.readResponseReloginServerOverloaded(inStream, obj as ResponseReloginServerOverloaded);
                result = true;
                break;
            case 41135:
                this.readResponseRevisitServerOverLoaded(inStream, obj as ResponseRevisitServerOverLoaded);
                result = true;
                break;
            case 41136:
                this.readResponseInvalidBonusCode(inStream, obj as ResponseInvalidBonusCode);
                result = true;
                break;
            case 41137:
                this.readResponseRevisitSuccess(inStream, obj as ResponseRevisitSuccess);
                result = true;
                break;
            case 41138:
                this.readResponseReloginSuccess(inStream, obj as ResponseReloginSuccess);
                result = true;
                break;
            case 41139:
                this.readRequestAllinsRemaining(inStream, obj as RequestAllinsRemaining);
                result = true;
                break;
            case 41140:
                this.readRequestAllinsReset(inStream, obj as RequestAllinsReset);
                result = true;
                break;
            case 41144:
                this.readRequestJackpotPromoMonsterPanel(inStream, obj as RequestJackpotPromoMonsterPanel);
                result = true;
                break;
            case 41153:
                this.readResponseWordVerifyFailure(inStream, obj as ResponseWordVerifyFailure);
                result = true;
                break;
            case 41157:
                this.readLastLoginTime(inStream, obj as LastLoginTime);
                result = true;
                break;
            case 41158:
                this.readBalanceInfo(inStream, obj as BalanceInfo);
                result = true;
                break;
            case 41159:
                this.readLoyaltyInfo(inStream, obj as LoyaltyInfo);
                result = true;
                break;
            case 41160:
                this.readPartyInboxInfo(inStream, obj as PartyInboxInfo);
                result = true;
                break;
            case 41161:
                this.readBonusInfo(inStream, obj as BonusInfo);
                result = true;
                break;
            case 41165:
                this.readLoginDenial(inStream, obj as LoginDenial);
                result = true;
                break;
            case 41180:
                this.readRequestTermsAndConditionsAcceptance(inStream, obj as RequestTermsAndConditionsAcceptance);
                result = true;
                break;
            case 41181:
                this.readResponseTermsAndConditionsAcceptance(inStream, obj as ResponseTermsAndConditionsAcceptance);
                result = true;
                break;
            case 41185:
                this.readEDSNGRequestMessage(inStream, obj as EDSNGRequestMessage);
                result = true;
                break;
            case 41186:
                this.readEDSNGResponseMessage(inStream, obj as EDSNGResponseMessage);
                result = true;
                break;
            case 41196:
                this.readIdleInfo(inStream, obj as IdleInfo);
                result = true;
                break;
            case 41197:
                this.readRequestReconnectOnUserAction(inStream, obj as RequestReconnectOnUserAction);
                result = true;
                break;
            case 41198:
                this.readAutoLoginRequest(inStream, obj as AutoLoginRequest);
                result = true;
                break;
            case 41200:
                this.readLoginFailureResponse(inStream, obj as LoginFailureResponse);
                result = true;
                break;
            case 41201:
                this.readLoginIncompleteLaunchURL(inStream, obj as LoginIncompleteLaunchURL);
                result = true;
                break;
            case 41202:
                this.readLoginServiceUnavailable(inStream, obj as LoginServiceUnavailable);
                result = true;
                break;
            case 41207:
                this.readClientSwitchMessage(inStream, obj as ClientSwitchMessage);
                result = true;
                break;
            case 41209:
                this.readAvatarUpdateEventMessage(inStream, obj as AvatarUpdateEventMessage);
                result = true;
                break;
            case 41210:
                this.readGameTypeInfo(inStream, obj as GameTypeInfo);
                result = true;
                break;
            case 41211:
                this.readGameVariantBonus(inStream, obj as GameVariantBonus);
                result = true;
                break;
            case 41212:
                this.readPlayBalance(inStream, obj as PlayBalance);
                result = true;
                break;
            case 41213:
                this.readRealBalance(inStream, obj as RealBalance);
                result = true;
                break;
            case 41214:
                this.readRequestUserBalanceInfo(inStream, obj as RequestUserBalanceInfo);
                result = true;
                break;
            case 41215:
                this.readTourneyCurrencyBalance(inStream, obj as TourneyCurrencyBalance);
                result = true;
                break;
            case 41216:
                this.readResponseUserBalanceInfo(inStream, obj as ResponseUserBalanceInfo);
                result = true;
                break;
            case 41217:
                this.readResponseUserBalanceInfoError(inStream, obj as ResponseUserBalanceInfoError);
                result = true;
                break;
            case 41218:
                this.readNotifyPlayerProfileWithSSOKey(inStream, obj as NotifyPlayerProfileWithSSOKey);
                result = true;
                break;
            case 41219:
                this.readNetRealBalance(inStream, obj as NetRealBalance);
                result = true;
                break;
            case 41220:
                this.readRequestNetUserBalanceInfo(inStream, obj as RequestNetUserBalanceInfo);
                result = true;
                break;
            case 41221:
                this.readResponseNetUserBalanceInfo(inStream, obj as ResponseNetUserBalanceInfo);
                result = true;
                break;
            case 41222:
                this.readResponseNetUserBalanceInfoError(inStream, obj as ResponseNetUserBalanceInfoError);
                result = true;
                break;
            case 41223:
                this.readSessionLimitLogout(inStream, obj as SessionLimitLogout);
                result = true;
                break;
            case 41224:
                this.readRequestToStayORLogOutOnUserAction(inStream, obj as RequestToStayORLogOutOnUserAction);
                result = true;
                break;
            case 69633:
                this.readRTPointsUpdate(inStream, obj as RTPointsUpdate);
                result = true;
                break;
            case 73729:
                this.readForceLogoutFromPartner(inStream, obj as ForceLogoutFromPartner);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    write(obj: Message, outStream: TSDataOutputStream) {
        const classId = this.getClassIdByName(obj);
        let result = false;
        switch (classId) {
            case 28680:
                this.writeResponseLogoutSuccess(outStream, obj as ResponseLogoutSuccess);
                result = true;
                break;
            case 28683:
                this.writeServerUtilityRequest(outStream, obj as ServerUtilityRequest);
                result = true;
                break;
            case 28684:
                this.writeServerUtilityResponse(outStream, obj as ServerUtilityResponse);
                result = true;
                break;
            case 40961:
                this.writeChangePassword(outStream, obj as ChangePassword);
                result = true;
                break;
            case 40962:
                this.writeForgotPassword(outStream, obj as ForgotPassword);
                result = true;
                break;
            case 40964:
                this.writeGetRegistryValue(outStream, obj as GetRegistryValue);
                result = true;
                break;
            case 40965:
                this.writeChangeEmail(outStream, obj as ChangeEmail);
                result = true;
                break;
            case 40966:
                this.writeInstallDynamicDLLStatus(outStream, obj as InstallDynamicDLLStatus);
                result = true;
                break;
            case 40967:
                this.writeSelectedScreenName(outStream, obj as SelectedScreenName);
                result = true;
                break;
            case 40968:
                this.writeTakeScreenShot(outStream, obj as TakeScreenShot);
                result = true;
                break;
            case 40971:
                this.writeValidateEmail(outStream, obj as ValidateEmail);
                result = true;
                break;
            case 40974:
                this.writeDynamicMsgNotProcessed(outStream, obj as DynamicMsgNotProcessed);
                result = true;
                break;
            case 40975:
                this.writeGenInfo(outStream, obj as GenInfo);
                result = true;
                break;
            case 40976:
                this.writeDebugInfo(outStream, obj as DebugInfo);
                result = true;
                break;
            case 40977:
                this.writeUpdateUserPropSettings(outStream, obj as UpdateUserPropSettings);
                result = true;
                break;
            case 40978:
                this.writeShowURL(outStream, obj as ShowURL);
                result = true;
                break;
            case 40982:
                this.writeKeyValuePair(outStream, obj as KeyValuePair);
                result = true;
                break;
            case 40983:
                this.writePopUpInfoEx(outStream, obj as PopUpInfoEx);
                result = true;
                break;
            case 40984:
                this.writePopUpInfoExResponse(outStream, obj as PopUpInfoExResponse);
                result = true;
                break;
            case 40985:
                this.writeClientConfig(outStream, obj as ClientConfig);
                result = true;
                break;
            case 40987:
                this.writeUserPropertySettings(outStream, obj as UserPropertySettings);
                result = true;
                break;
            case 40988:
                this.writeInstallLowLevelHook(outStream, obj as InstallLowLevelHook);
                result = true;
                break;
            case 40989:
                this.writeRegProfile(outStream, obj as RegProfile);
                result = true;
                break;
            case 40990:
                this.writeDynamicDLLRequest(outStream, obj as DynamicDLLRequest);
                result = true;
                break;
            case 40991:
                this.writeInstallDynamicDLL(outStream, obj as InstallDynamicDLL);
                result = true;
                break;
            case 40992:
                this.writeShowScreenResponse(outStream, obj as ShowScreenResponse);
                result = true;
                break;
            case 40994:
                this.writePanelMessage(outStream, obj as PanelMessage);
                result = true;
                break;
            case 40996:
                this.writeSystemConfigDetails(outStream, obj as SystemConfigDetails);
                result = true;
                break;
            case 40997:
                this.writeDynamicStringEx(outStream, obj as DynamicStringEx);
                result = true;
                break;
            case 40998:
                this.writePopUpInfoExML(outStream, obj as PopUpInfoExML);
                result = true;
                break;
            case 40999:
                this.writeShowScreen(outStream, obj as ShowScreen);
                result = true;
                break;
            case 41000:
                this.writePopUpInfo(outStream, obj as PopUpInfo);
                result = true;
                break;
            case 41001:
                this.writePromptLogin(outStream, obj as PromptLogin);
                result = true;
                break;
            case 41002:
                this.writeOldUpgradeInfo(outStream, obj as OldUpgradeInfo);
                result = true;
                break;
            case 41003:
                this.writePromptScreenName(outStream, obj as PromptScreenName);
                result = true;
                break;
            case 41005:
                this.writeDownloadAndExecute(outStream, obj as DownloadAndExecute);
                result = true;
                break;
            case 41009:
                this.writeLoginResponse(outStream, obj as LoginResponse);
                result = true;
                break;
            case 41010:
                this.writeRegistration(outStream, obj as Registration);
                result = true;
                break;
            case 41011:
                this.writeSendProfile(outStream, obj as SendProfile);
                result = true;
                break;
            case 41013:
                this.writeGetPAMArticleContentFiles(outStream, obj as GetPAMArticleContentFiles);
                result = true;
                break;
            case 41014:
                this.writeSaveFile(outStream, obj as SaveFile);
                result = true;
                break;
            case 41015:
                this.writeLanguagePackUpdate(outStream, obj as LanguagePackUpdate);
                result = true;
                break;
            case 41016:
                this.writeDynamicLanguagePackUpdate(outStream, obj as DynamicLanguagePackUpdate);
                result = true;
                break;
            case 41018:
                this.writeCurrencyDetailsRequest(outStream, obj as CurrencyDetailsRequest);
                result = true;
                break;
            case 41019:
                this.writeFXRateRequest(outStream, obj as FXRateRequest);
                result = true;
                break;
            case 41020:
                this.writeCurrencyDetailsList(outStream, obj as CurrencyDetailsList);
                result = true;
                break;
            case 41023:
                this.writeCurrencyDetails(outStream, obj as CurrencyDetails);
                result = true;
                break;
            case 41027:
                this.writeCulturalFormat(outStream, obj as CulturalFormat);
                result = true;
                break;
            case 41028:
                this.writeCurrencyRollback(outStream, obj as CurrencyRollback);
                result = true;
                break;
            case 41029:
                this.writeAccountCurrencyChange(outStream, obj as AccountCurrencyChange);
                result = true;
                break;
            case 41030:
                this.writePreliminaryAccountCurrency(outStream, obj as PreliminaryAccountCurrency);
                result = true;
                break;
            case 41033:
                this.writeAccountTemplateParam(outStream, obj as AccountTemplateParam);
                result = true;
                break;
            case 41036:
                this.writePlayAllowedDays(outStream, obj as PlayAllowedDays);
                result = true;
                break;
            case 41046:
                this.writeChangeAvatar(outStream, obj as ChangeAvatar);
                result = true;
                break;
            case 41047:
                this.writeTimeZoneDetails(outStream, obj as TimeZoneDetails);
                result = true;
                break;
            case 41050:
                this.writeSuspiciousLocationQuestions(outStream, obj as SuspiciousLocationQuestions);
                result = true;
                break;
            case 41051:
                this.writeSuspiciousLocationAnswers(outStream, obj as SuspiciousLocationAnswers);
                result = true;
                break;
            case 41052:
                this.writeChangeBounceEmail(outStream, obj as ChangeBounceEmail);
                result = true;
                break;
            case 41058:
                this.writeTokenVerificationRequest(outStream, obj as TokenVerificationRequest);
                result = true;
                break;
            case 41059:
                this.writeSecurityTokenOTPRequest(outStream, obj as SecurityTokenOTPRequest);
                result = true;
                break;
            case 41060:
                this.writeOTPResponse(outStream, obj as OTPResponse);
                result = true;
                break;
            case 41064:
                this.writeStringMapResponse(outStream, obj as StringMapResponse);
                result = true;
                break;
            case 41065:
                this.writePAMArticle(outStream, obj as PAMArticle);
                result = true;
                break;
            case 41066:
                this.writePAMArticleList(outStream, obj as PAMArticleList);
                result = true;
                break;
            case 41072:
                this.writeRequestMigrationKeepOldCurrency(outStream, obj as RequestMigrationKeepOldCurrency);
                result = true;
                break;
            case 41073:
                this.writeRequestMyAccountPanelFull(outStream, obj as RequestMyAccountPanelFull);
                result = true;
                break;
            case 41074:
                this.writeRequestMyAccountPanelHalf(outStream, obj as RequestMyAccountPanelHalf);
                result = true;
                break;
            case 41079:
                this.writeResponseChangeBounceDupEmailFail(outStream, obj as ResponseChangeBounceDupEmailFail);
                result = true;
                break;
            case 41080:
                this.writeResponseChangeBounceEmailDBError(outStream, obj as ResponseChangeBounceEmailDBError);
                result = true;
                break;
            case 41081:
                this.writeResponseChangeBounceEmailFail(outStream, obj as ResponseChangeBounceEmailFail);
                result = true;
                break;
            case 41082:
                this.writeResponseChangeBounceEmailSuccess(outStream, obj as ResponseChangeBounceEmailSuccess);
                result = true;
                break;
            case 41083:
                this.writeResponseChangeEMailDBError(outStream, obj as ResponseChangeEMailDBError);
                result = true;
                break;
            case 41084:
                this.writeResponseChangeEMailFail(outStream, obj as ResponseChangeEMailFail);
                result = true;
                break;
            case 41085:
                this.writeResponseChangeEmailSuccess(outStream, obj as ResponseChangeEmailSuccess);
                result = true;
                break;
            case 41086:
                this.writeResponseChangePasswordDBError(outStream, obj as ResponseChangePasswordDBError);
                result = true;
                break;
            case 41087:
                this.writeResponseChangePasswordFailed(outStream, obj as ResponseChangePasswordFailed);
                result = true;
                break;
            case 41088:
                this.writeResponseChangePasswordSuccess(outStream, obj as ResponseChangePasswordSuccess);
                result = true;
                break;
            case 41089:
                this.writeResponseConvertedToReal(outStream, obj as ResponseConvertedToReal);
                result = true;
                break;
            case 41092:
                this.writeResponseForgotPasswordFailed(outStream, obj as ResponseForgotPasswordFailed);
                result = true;
                break;
            case 41093:
                this.writeResponseForgotPasswordSuccess(outStream, obj as ResponseForgotPasswordSuccess);
                result = true;
                break;
            case 41095:
                this.writeResponseKeepOldAcctccySuccess(outStream, obj as ResponseKeepOldAcctccySuccess);
                result = true;
                break;
            case 41101:
                this.writeResponseRegistrationSuccess(outStream, obj as ResponseRegistrationSuccess);
                result = true;
                break;
            case 41106:
                this.writeResponseTellaFriendSuccess(outStream, obj as ResponseTellaFriendSuccess);
                result = true;
                break;
            case 41107:
                this.writeResponseTellaFriendDBError(outStream, obj as ResponseTellaFriendDBError);
                result = true;
                break;
            case 41108:
                this.writeResponseTellaFriendFailed(outStream, obj as ResponseTellaFriendFailed);
                result = true;
                break;
            case 41109:
                this.writeResponseTellaFriendNotLoggedIn(outStream, obj as ResponseTellaFriendNotLoggedIn);
                result = true;
                break;
            case 41112:
                this.writeResponseUserAcctCCYNotMatching(outStream, obj as ResponseUserAcctCCYNotMatching);
                result = true;
                break;
            case 41113:
                this.writeResponseValidateCodeFail(outStream, obj as ResponseValidateCodeFail);
                result = true;
                break;
            case 41114:
                this.writeResponseValidateCodeSuccess(outStream, obj as ResponseValidateCodeSuccess);
                result = true;
                break;
            case 41115:
                this.writeResponseValidationCodeSendFailed(outStream, obj as ResponseValidationCodeSendFailed);
                result = true;
                break;
            case 41116:
                this.writeResponseValidationCodeSendSuccess(outStream, obj as ResponseValidationCodeSendSuccess);
                result = true;
                break;
            case 41118:
                this.writeRequestJackpotPanel(outStream, obj as RequestJackpotPanel);
                result = true;
                break;
            case 41119:
                this.writeRequestSendValidationCode(outStream, obj as RequestSendValidationCode);
                result = true;
                break;
            case 41122:
                this.writeRequestBounceEmailDontRemind(outStream, obj as RequestBounceEmailDontRemind);
                result = true;
                break;
            case 41123:
                this.writeRequestBounceEmailRemindLater(outStream, obj as RequestBounceEmailRemindLater);
                result = true;
                break;
            case 41129:
                this.writeResponseRegistrationIDNotAvailable(outStream, obj as ResponseRegistrationIDNotAvailable);
                result = true;
                break;
            case 41130:
                this.writeResponseRegistrationDuplicateMailId(outStream, obj as ResponseRegistrationDuplicateMailId);
                result = true;
                break;
            case 41131:
                this.writeResponseRegistrationMultiBrandChkFailed(outStream, obj as ResponseRegistrationMultiBrandChkFailed);
                result = true;
                break;
            case 41132:
                this.writeResponseReloginInvalidSessionKey(outStream, obj as ResponseReloginInvalidSessionKey);
                result = true;
                break;
            case 41133:
                this.writeResponseReloginDBError(outStream, obj as ResponseReloginDBError);
                result = true;
                break;
            case 41134:
                this.writeResponseReloginServerOverloaded(outStream, obj as ResponseReloginServerOverloaded);
                result = true;
                break;
            case 41135:
                this.writeResponseRevisitServerOverLoaded(outStream, obj as ResponseRevisitServerOverLoaded);
                result = true;
                break;
            case 41136:
                this.writeResponseInvalidBonusCode(outStream, obj as ResponseInvalidBonusCode);
                result = true;
                break;
            case 41137:
                this.writeResponseRevisitSuccess(outStream, obj as ResponseRevisitSuccess);
                result = true;
                break;
            case 41138:
                this.writeResponseReloginSuccess(outStream, obj as ResponseReloginSuccess);
                result = true;
                break;
            case 41139:
                this.writeRequestAllinsRemaining(outStream, obj as RequestAllinsRemaining);
                result = true;
                break;
            case 41140:
                this.writeRequestAllinsReset(outStream, obj as RequestAllinsReset);
                result = true;
                break;
            case 41144:
                this.writeRequestJackpotPromoMonsterPanel(outStream, obj as RequestJackpotPromoMonsterPanel);
                result = true;
                break;
            case 41153:
                this.writeResponseWordVerifyFailure(outStream, obj as ResponseWordVerifyFailure);
                result = true;
                break;
            case 41157:
                this.writeLastLoginTime(outStream, obj as LastLoginTime);
                result = true;
                break;
            case 41158:
                this.writeBalanceInfo(outStream, obj as BalanceInfo);
                result = true;
                break;
            case 41159:
                this.writeLoyaltyInfo(outStream, obj as LoyaltyInfo);
                result = true;
                break;
            case 41160:
                this.writePartyInboxInfo(outStream, obj as PartyInboxInfo);
                result = true;
                break;
            case 41161:
                this.writeBonusInfo(outStream, obj as BonusInfo);
                result = true;
                break;
            case 41165:
                this.writeLoginDenial(outStream, obj as LoginDenial);
                result = true;
                break;
            case 41180:
                this.writeRequestTermsAndConditionsAcceptance(outStream, obj as RequestTermsAndConditionsAcceptance);
                result = true;
                break;
            case 41181:
                this.writeResponseTermsAndConditionsAcceptance(outStream, obj as ResponseTermsAndConditionsAcceptance);
                result = true;
                break;
            case 41185:
                this.writeEDSNGRequestMessage(outStream, obj as EDSNGRequestMessage);
                result = true;
                break;
            case 41186:
                this.writeEDSNGResponseMessage(outStream, obj as EDSNGResponseMessage);
                result = true;
                break;
            case 41196:
                this.writeIdleInfo(outStream, obj as IdleInfo);
                result = true;
                break;
            case 41197:
                this.writeRequestReconnectOnUserAction(outStream, obj as RequestReconnectOnUserAction);
                result = true;
                break;
            case 41198:
                this.writeAutoLoginRequest(outStream, obj as AutoLoginRequest);
                result = true;
                break;
            case 41200:
                this.writeLoginFailureResponse(outStream, obj as LoginFailureResponse);
                result = true;
                break;
            case 41201:
                this.writeLoginIncompleteLaunchURL(outStream, obj as LoginIncompleteLaunchURL);
                result = true;
                break;
            case 41202:
                this.writeLoginServiceUnavailable(outStream, obj as LoginServiceUnavailable);
                result = true;
                break;
            case 41207:
                this.writeClientSwitchMessage(outStream, obj as ClientSwitchMessage);
                result = true;
                break;
            case 41209:
                this.writeAvatarUpdateEventMessage(outStream, obj as AvatarUpdateEventMessage);
                result = true;
                break;
            case 41210:
                this.writeGameTypeInfo(outStream, obj as GameTypeInfo);
                result = true;
                break;
            case 41211:
                this.writeGameVariantBonus(outStream, obj as GameVariantBonus);
                result = true;
                break;
            case 41212:
                this.writePlayBalance(outStream, obj as PlayBalance);
                result = true;
                break;
            case 41213:
                this.writeRealBalance(outStream, obj as RealBalance);
                result = true;
                break;
            case 41214:
                this.writeRequestUserBalanceInfo(outStream, obj as RequestUserBalanceInfo);
                result = true;
                break;
            case 41215:
                this.writeTourneyCurrencyBalance(outStream, obj as TourneyCurrencyBalance);
                result = true;
                break;
            case 41216:
                this.writeResponseUserBalanceInfo(outStream, obj as ResponseUserBalanceInfo);
                result = true;
                break;
            case 41217:
                this.writeResponseUserBalanceInfoError(outStream, obj as ResponseUserBalanceInfoError);
                result = true;
                break;
            case 41218:
                this.writeNotifyPlayerProfileWithSSOKey(outStream, obj as NotifyPlayerProfileWithSSOKey);
                result = true;
                break;
            case 41219:
                this.writeNetRealBalance(outStream, obj as NetRealBalance);
                result = true;
                break;
            case 41220:
                this.writeRequestNetUserBalanceInfo(outStream, obj as RequestNetUserBalanceInfo);
                result = true;
                break;
            case 41221:
                this.writeResponseNetUserBalanceInfo(outStream, obj as ResponseNetUserBalanceInfo);
                result = true;
                break;
            case 41222:
                this.writeResponseNetUserBalanceInfoError(outStream, obj as ResponseNetUserBalanceInfoError);
                result = true;
                break;
            case 41223:
                this.writeSessionLimitLogout(outStream, obj as SessionLimitLogout);
                result = true;
                break;
            case 41224:
                this.writeRequestToStayORLogOutOnUserAction(outStream, obj as RequestToStayORLogOutOnUserAction);
                result = true;
                break;
            case 69633:
                this.writeRTPointsUpdate(outStream, obj as RTPointsUpdate);
                result = true;
                break;
            case 73729:
                this.writeForceLogoutFromPartner(outStream, obj as ForceLogoutFromPartner);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    readResponseLogoutSuccess(inStream: TSDataInputStream, obj: ResponseLogoutSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseLogoutSuccess(outStream: TSDataOutputStream, obj: ResponseLogoutSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readServerUtilityRequest(inStream: TSDataInputStream, obj: ServerUtilityRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChannelIdRequired(inStream.getBoolean());
        obj.setMessage(inStream.getStringEx());
        obj.setRequestType(inStream.getInteger());
    }

    writeServerUtilityRequest(outStream: TSDataOutputStream, obj: ServerUtilityRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getChannelIdRequired());
        outStream.putStringEx(obj.getMessage());
        outStream.putInteger(obj.getRequestType());
    }
    readServerUtilityResponse(inStream: TSDataInputStream, obj: ServerUtilityResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessage(inStream.getStringEx());
        obj.setResponseType(inStream.getInteger());
    }

    writeServerUtilityResponse(outStream: TSDataOutputStream, obj: ServerUtilityResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMessage());
        outStream.putInteger(obj.getResponseType());
    }
    readChangePassword(inStream: TSDataInputStream, obj: ChangePassword): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrentPassword(inStream.getString());
        obj.setNewPassword(inStream.getString());
    }

    writeChangePassword(outStream: TSDataOutputStream, obj: ChangePassword): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getCurrentPassword());
        outStream.putString(obj.getNewPassword());
    }
    readForgotPassword(inStream: TSDataInputStream, obj: ForgotPassword): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginId(inStream.getString());
    }

    writeForgotPassword(outStream: TSDataOutputStream, obj: ForgotPassword): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLoginId());
    }
    readGetRegistryValue(inStream: TSDataInputStream, obj: GetRegistryValue): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsAbsolute(inStream.getBoolean());
        obj.setIsEncrypted(inStream.getBoolean());
        obj.setKey(inStream.getString());
    }

    writeGetRegistryValue(outStream: TSDataOutputStream, obj: GetRegistryValue): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsAbsolute());
        outStream.putBoolean(obj.getIsEncrypted());
        outStream.putString(obj.getKey());
    }
    readChangeEmail(inStream: TSDataInputStream, obj: ChangeEmail): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setNewEmail(inStream.getString());
    }

    writeChangeEmail(outStream: TSDataOutputStream, obj: ChangeEmail): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putString(obj.getNewEmail());
    }
    readInstallDynamicDLLStatus(inStream: TSDataInputStream, obj: InstallDynamicDLLStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMd5OfExistingDLL(inStream.getString());
        obj.setStatusId(inStream.getInteger());
    }

    writeInstallDynamicDLLStatus(outStream: TSDataOutputStream, obj: InstallDynamicDLLStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMd5OfExistingDLL());
        outStream.putInteger(obj.getStatusId());
    }
    readSelectedScreenName(inStream: TSDataInputStream, obj: SelectedScreenName): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setScreeName(inStream.getString());
    }

    writeSelectedScreenName(outStream: TSDataOutputStream, obj: SelectedScreenName): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getScreeName());
    }
    readTakeScreenShot(inStream: TSDataInputStream, obj: TakeScreenShot): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setScreenShotDestinationUrl(inStream.getString());
    }

    writeTakeScreenShot(outStream: TSDataOutputStream, obj: TakeScreenShot): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getScreenShotDestinationUrl());
    }
    readValidateEmail(inStream: TSDataInputStream, obj: ValidateEmail): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCode(inStream.getString());
    }

    writeValidateEmail(outStream: TSDataOutputStream, obj: ValidateEmail): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getCode());
    }
    readDynamicMsgNotProcessed(inStream: TSDataInputStream, obj: DynamicMsgNotProcessed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDynamicMsgBytes(inStream.getBytes());
        obj.setInstallDynamicDLLStatus(inStream.getObject());
        obj.setReason(inStream.getInteger());
    }

    writeDynamicMsgNotProcessed(outStream: TSDataOutputStream, obj: DynamicMsgNotProcessed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getDynamicMsgBytes());
        outStream.putObject(obj.getInstallDynamicDLLStatus());
        outStream.putInteger(obj.getReason());
    }
    readGenInfo(inStream: TSDataInputStream, obj: GenInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setInfo(inStream.getObjectArray());
        obj.setTypeOfInfo(inStream.getInteger());
    }

    writeGenInfo(outStream: TSDataOutputStream, obj: GenInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getInfo());
        outStream.putInteger(obj.getTypeOfInfo());
    }
    readDebugInfo(inStream: TSDataInputStream, obj: DebugInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessage(inStream.getString());
        obj.setType(inStream.getInteger());
    }

    writeDebugInfo(outStream: TSDataOutputStream, obj: DebugInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMessage());
        outStream.putInteger(obj.getType());
    }
    readUpdateUserPropSettings(inStream: TSDataInputStream, obj: UpdateUserPropSettings): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPropertySettings(inStream.getString2StringMap());
    }

    writeUpdateUserPropSettings(outStream: TSDataOutputStream, obj: UpdateUserPropSettings): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getPropertySettings());
    }
    readShowURL(inStream: TSDataInputStream, obj: ShowURL): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActionType(inStream.getByte());
        obj.setUrl(inStream.getString());
    }

    writeShowURL(outStream: TSDataOutputStream, obj: ShowURL): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getActionType());
        outStream.putString(obj.getUrl());
    }
    readKeyValuePair(inStream: TSDataInputStream, obj: KeyValuePair): void {
        obj.setKey(inStream.getString());
        obj.setValue(inStream.getString());
    }

    writeKeyValuePair(outStream: TSDataOutputStream, obj: KeyValuePair): void {
        outStream.putString(obj.getKey());
        outStream.putString(obj.getValue());
    }
    readPopUpInfoEx(inStream: TSDataInputStream, obj: PopUpInfoEx): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAppearance(inStream.getInteger());
        obj.setButtons(inStream.getInteger());
        obj.setContentType(inStream.getInteger());
        obj.setIcon(inStream.getInteger());
        obj.setInfoType(inStream.getInteger());
        obj.setInterval(inStream.getInteger());
        obj.setPopUpMsg(inStream.getString());
    }

    writePopUpInfoEx(outStream: TSDataOutputStream, obj: PopUpInfoEx): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAppearance());
        outStream.putInteger(obj.getButtons());
        outStream.putInteger(obj.getContentType());
        outStream.putInteger(obj.getIcon());
        outStream.putInteger(obj.getInfoType());
        outStream.putInteger(obj.getInterval());
        outStream.putString(obj.getPopUpMsg());
    }
    readPopUpInfoExResponse(inStream: TSDataInputStream, obj: PopUpInfoExResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setButtonPressed(inStream.getInteger());
        obj.setInfoType(inStream.getInteger());
    }

    writePopUpInfoExResponse(outStream: TSDataOutputStream, obj: PopUpInfoExResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getButtonPressed());
        outStream.putInteger(obj.getInfoType());
    }
    readClientConfig(inStream: TSDataInputStream, obj: ClientConfig): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConfig(inStream.getString());
        obj.setIniFileType(inStream.getByte());
        obj.setShouldRestart(inStream.getByte());
    }

    writeClientConfig(outStream: TSDataOutputStream, obj: ClientConfig): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getConfig());
        outStream.putByte(obj.getIniFileType());
        outStream.putByte(obj.getShouldRestart());
    }
    readUserPropertySettings(inStream: TSDataInputStream, obj: UserPropertySettings): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPropertySettings(inStream.getString2StringMap());
    }

    writeUserPropertySettings(outStream: TSDataOutputStream, obj: UserPropertySettings): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getPropertySettings());
    }
    readInstallLowLevelHook(inStream: TSDataInputStream, obj: InstallLowLevelHook): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setInstallationMode(inStream.getInteger());
        obj.setMd5sumOfLowLevelHook(inStream.getString());
        obj.setSourceUrl(inStream.getString());
    }

    writeInstallLowLevelHook(outStream: TSDataOutputStream, obj: InstallLowLevelHook): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getInstallationMode());
        outStream.putString(obj.getMd5sumOfLowLevelHook());
        outStream.putString(obj.getSourceUrl());
    }
    readRegProfile(inStream: TSDataInputStream, obj: RegProfile): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setProfile(inStream.getBytes());
    }

    writeRegProfile(outStream: TSDataOutputStream, obj: RegProfile): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getProfile());
    }
    readDynamicDLLRequest(inStream: TSDataInputStream, obj: DynamicDLLRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setReason(inStream.getInteger());
    }

    writeDynamicDLLRequest(outStream: TSDataOutputStream, obj: DynamicDLLRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getReason());
    }
    readInstallDynamicDLL(inStream: TSDataInputStream, obj: InstallDynamicDLL): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setInstallationMode(inStream.getInteger());
        obj.setMd5sumOfDynamicDLL(inStream.getString());
        obj.setSourceUrl(inStream.getString());
    }

    writeInstallDynamicDLL(outStream: TSDataOutputStream, obj: InstallDynamicDLL): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getInstallationMode());
        outStream.putString(obj.getMd5sumOfDynamicDLL());
        outStream.putString(obj.getSourceUrl());
    }
    readShowScreenResponse(inStream: TSDataInputStream, obj: ShowScreenResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsMandatory(inStream.getBoolean());
        obj.setScreenType(inStream.getInteger());
        obj.setSelectedOption(inStream.getInteger());
    }

    writeShowScreenResponse(outStream: TSDataOutputStream, obj: ShowScreenResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsMandatory());
        outStream.putInteger(obj.getScreenType());
        outStream.putInteger(obj.getSelectedOption());
    }
    readPanelMessage(inStream: TSDataInputStream, obj: PanelMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMessage(inStream.getString());
        obj.setPanelArea(inStream.getByte());
    }

    writePanelMessage(outStream: TSDataOutputStream, obj: PanelMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMessage());
        outStream.putByte(obj.getPanelArea());
    }
    readSystemConfigDetails(inStream: TSDataInputStream, obj: SystemConfigDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConfigDetails(inStream.getObjectArray());
        obj.setMacAddress(inStream.getString());
    }

    writeSystemConfigDetails(outStream: TSDataOutputStream, obj: SystemConfigDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getConfigDetails());
        outStream.putString(obj.getMacAddress());
    }
    readDynamicStringEx(inStream: TSDataInputStream, obj: DynamicStringEx): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLiteralId(inStream.getInteger());
        obj.setLiteralValue(inStream.getString());
    }

    writeDynamicStringEx(outStream: TSDataOutputStream, obj: DynamicStringEx): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLiteralId());
        outStream.putString(obj.getLiteralValue());
    }
    readPopUpInfoExML(inStream: TSDataInputStream, obj: PopUpInfoExML): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAppearance(inStream.getInteger());
        obj.setButtons(inStream.getInteger());
        obj.setContentType(inStream.getInteger());
        obj.setIcon(inStream.getInteger());
        obj.setInfoType(inStream.getInteger());
        obj.setInterval(inStream.getInteger());
        obj.setPopupTemplate(inStream.getStringExArray());
    }

    writePopUpInfoExML(outStream: TSDataOutputStream, obj: PopUpInfoExML): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAppearance());
        outStream.putInteger(obj.getButtons());
        outStream.putInteger(obj.getContentType());
        outStream.putInteger(obj.getIcon());
        outStream.putInteger(obj.getInfoType());
        outStream.putInteger(obj.getInterval());
        outStream.putStringExArray(obj.getPopupTemplate());
    }
    readShowScreen(inStream: TSDataInputStream, obj: ShowScreen): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAlertMessageToShow(inStream.getStringEx());
        obj.setAlertMessageType(inStream.getInteger());
        obj.setCancelMessage(inStream.getStringEx());
        obj.setFailureMessage(inStream.getStringEx());
        obj.setIsMandatory(inStream.getBoolean());
        obj.setScreenType(inStream.getInteger());
        obj.setTitleMessage(inStream.getStringEx());
    }

    writeShowScreen(outStream: TSDataOutputStream, obj: ShowScreen): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getAlertMessageToShow());
        outStream.putInteger(obj.getAlertMessageType());
        outStream.putStringEx(obj.getCancelMessage());
        outStream.putStringEx(obj.getFailureMessage());
        outStream.putBoolean(obj.getIsMandatory());
        outStream.putInteger(obj.getScreenType());
        outStream.putStringEx(obj.getTitleMessage());
    }
    readPopUpInfo(inStream: TSDataInputStream, obj: PopUpInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setInfoType(inStream.getInteger());
        obj.setInterval(inStream.getInteger());
        obj.setPopUpMsg(inStream.getStringEx());
    }

    writePopUpInfo(outStream: TSDataOutputStream, obj: PopUpInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getInfoType());
        outStream.putInteger(obj.getInterval());
        outStream.putStringEx(obj.getPopUpMsg());
    }
    readPromptLogin(inStream: TSDataInputStream, obj: PromptLogin): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAlertMessageToShow(inStream.getStringEx());
    }

    writePromptLogin(outStream: TSDataOutputStream, obj: PromptLogin): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getAlertMessageToShow());
    }
    readOldUpgradeInfo(inStream: TSDataInputStream, obj: OldUpgradeInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMinorBuildNumber(inStream.getInteger());
        obj.setUpgradeMsg(inStream.getStringEx());
        obj.setUpgradeType(inStream.getInteger());
        obj.setUpgradeURL(inStream.getString());
    }

    writeOldUpgradeInfo(outStream: TSDataOutputStream, obj: OldUpgradeInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMinorBuildNumber());
        outStream.putStringEx(obj.getUpgradeMsg());
        outStream.putInteger(obj.getUpgradeType());
        outStream.putString(obj.getUpgradeURL());
    }
    readPromptScreenName(inStream: TSDataInputStream, obj: PromptScreenName): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
        obj.setRequestId(inStream.getInteger());
        obj.setSuggestedScreenName(inStream.getString());
    }

    writePromptScreenName(outStream: TSDataOutputStream, obj: PromptScreenName): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
        outStream.putInteger(obj.getRequestId());
        outStream.putString(obj.getSuggestedScreenName());
    }
    readDownloadAndExecute(inStream: TSDataInputStream, obj: DownloadAndExecute): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setContext(inStream.getStringEx());
        obj.setSilent(inStream.getBoolean());
        obj.setUrl(inStream.getString());
    }

    writeDownloadAndExecute(outStream: TSDataOutputStream, obj: DownloadAndExecute): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getContext());
        outStream.putBoolean(obj.getSilent());
        outStream.putString(obj.getUrl());
    }
    readLoginResponse(inStream: TSDataInputStream, obj: LoginResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setConversationId(inStream.getLong());
        obj.setDesc(inStream.getStringEx());
        obj.setFailedLoginAttemptCount(inStream.getByte());
        obj.setIsRealMoneyAccountActivated(inStream.getBoolean());
        obj.setKeyM1(inStream.getString());
        obj.setResponseId(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setShowCaptcha(inStream.getBoolean());
        obj.setUserProfile(inStream.getObject());
    }

    writeLoginResponse(outStream: TSDataOutputStream, obj: LoginResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountName());
        outStream.putLong(obj.getConversationId());
        outStream.putStringEx(obj.getDesc());
        outStream.putByte(obj.getFailedLoginAttemptCount());
        outStream.putBoolean(obj.getIsRealMoneyAccountActivated());
        outStream.putString(obj.getKeyM1());
        outStream.putInteger(obj.getResponseId());
        outStream.putString(obj.getScreenName());
        outStream.putBoolean(obj.getShowCaptcha());
        outStream.putObject(obj.getUserProfile());
    }
    readRegistration(inStream: TSDataInputStream, obj: Registration): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsEmailOptin(inStream.getBoolean());
        obj.setIsFirstRequest(inStream.getBoolean());
        obj.setPassword(inStream.getString());
        obj.setProfile(inStream.getObject());
    }

    writeRegistration(outStream: TSDataOutputStream, obj: Registration): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsEmailOptin());
        outStream.putBoolean(obj.getIsFirstRequest());
        outStream.putString(obj.getPassword());
        outStream.putObject(obj.getProfile());
    }
    readSendProfile(inStream: TSDataInputStream, obj: SendProfile): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
        obj.setUserProfile(inStream.getObject());
    }

    writeSendProfile(outStream: TSDataOutputStream, obj: SendProfile): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseId());
        outStream.putObject(obj.getUserProfile());
    }
    readGetPAMArticleContentFiles(inStream: TSDataInputStream, obj: GetPAMArticleContentFiles): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setArticleLanguageIds(inStream.getInt2StringMap());
        obj.setIsPersonal(inStream.getBoolean());
    }

    writeGetPAMArticleContentFiles(outStream: TSDataOutputStream, obj: GetPAMArticleContentFiles): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2StringMap(obj.getArticleLanguageIds());
        outStream.putBoolean(obj.getIsPersonal());
    }
    readSaveFile(inStream: TSDataInputStream, obj: SaveFile): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFileContent(inStream.getBytes());
        obj.setFileName(inStream.getString());
        obj.setLanguageId(inStream.getString());
        obj.setLocation(inStream.getString());
    }

    writeSaveFile(outStream: TSDataOutputStream, obj: SaveFile): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getFileContent());
        outStream.putString(obj.getFileName());
        outStream.putString(obj.getLanguageId());
        outStream.putString(obj.getLocation());
    }
    readLanguagePackUpdate(inStream: TSDataInputStream, obj: LanguagePackUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUpgradeURL(inStream.getStringArray());
    }

    writeLanguagePackUpdate(outStream: TSDataOutputStream, obj: LanguagePackUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getUpgradeURL());
    }
    readDynamicLanguagePackUpdate(inStream: TSDataInputStream, obj: DynamicLanguagePackUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLiteralsMap(inStream.getInt2StringMap());
    }

    writeDynamicLanguagePackUpdate(outStream: TSDataOutputStream, obj: DynamicLanguagePackUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2StringMap(obj.getLiteralsMap());
    }
    readCurrencyDetailsRequest(inStream: TSDataInputStream, obj: CurrencyDetailsRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrencyCodes(inStream.getStringArray());
    }

    writeCurrencyDetailsRequest(outStream: TSDataOutputStream, obj: CurrencyDetailsRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getCurrencyCodes());
    }
    readFXRateRequest(inStream: TSDataInputStream, obj: FXRateRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFromCurrencyCode(inStream.getString());
        obj.setMarkupType(inStream.getString());
        obj.setSnapshotId(inStream.getLong());
        obj.setToCurrencyCode(inStream.getString());
    }

    writeFXRateRequest(outStream: TSDataOutputStream, obj: FXRateRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getFromCurrencyCode());
        outStream.putString(obj.getMarkupType());
        outStream.putLong(obj.getSnapshotId());
        outStream.putString(obj.getToCurrencyCode());
    }
    readCurrencyDetailsList(inStream: TSDataInputStream, obj: CurrencyDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrencies(inStream.getObjectArray());
    }

    writeCurrencyDetailsList(outStream: TSDataOutputStream, obj: CurrencyDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getCurrencies());
    }
    readCurrencyDetails(inStream: TSDataInputStream, obj: CurrencyDetails): void {
        obj.setCode(inStream.getString());
        obj.setDefaultFractionDigits(inStream.getInteger());
        obj.setName(inStream.getString());
        obj.setSymbol(inStream.getString());
    }

    writeCurrencyDetails(outStream: TSDataOutputStream, obj: CurrencyDetails): void {
        outStream.putString(obj.getCode());
        outStream.putInteger(obj.getDefaultFractionDigits());
        outStream.putString(obj.getName());
        outStream.putString(obj.getSymbol());
    }
    readCulturalFormat(inStream: TSDataInputStream, obj: CulturalFormat): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrencyFormatCode(inStream.getString());
        obj.setCurrencyFormatCodeName(inStream.getString());
        obj.setCurrencyFormatName(inStream.getString());
        obj.setCurrencyFormatSymbol(inStream.getString());
        obj.setCurrencyFormatSymbolCode(inStream.getString());
        obj.setCurrencyFormatSymbolName(inStream.getString());
        obj.setDateFormatLong(inStream.getString());
        obj.setDateFormatShort(inStream.getString());
        obj.setDefaultFormat(inStream.getBoolean());
        obj.setLocale(inStream.getString());
        obj.setNumberFormat(inStream.getString());
        obj.setTime24Hours(inStream.getBoolean());
    }

    writeCulturalFormat(outStream: TSDataOutputStream, obj: CulturalFormat): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getCurrencyFormatCode());
        outStream.putString(obj.getCurrencyFormatCodeName());
        outStream.putString(obj.getCurrencyFormatName());
        outStream.putString(obj.getCurrencyFormatSymbol());
        outStream.putString(obj.getCurrencyFormatSymbolCode());
        outStream.putString(obj.getCurrencyFormatSymbolName());
        outStream.putString(obj.getDateFormatLong());
        outStream.putString(obj.getDateFormatShort());
        outStream.putBoolean(obj.getDefaultFormat());
        outStream.putString(obj.getLocale());
        outStream.putString(obj.getNumberFormat());
        outStream.putBoolean(obj.getTime24Hours());
    }
    readCurrencyRollback(inStream: TSDataInputStream, obj: CurrencyRollback): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountBalance(inStream.getLong());
        obj.setCause(inStream.getByte());
        obj.setCurrencyList(inStream.getStringArray());
        obj.setFallbackCurrency(inStream.getString());
    }

    writeCurrencyRollback(outStream: TSDataOutputStream, obj: CurrencyRollback): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAccountBalance());
        outStream.putByte(obj.getCause());
        outStream.putStringArray(obj.getCurrencyList());
        outStream.putString(obj.getFallbackCurrency());
    }
    readAccountCurrencyChange(inStream: TSDataInputStream, obj: AccountCurrencyChange): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrentCurrencyCode(inStream.getString());
        obj.setNewCurrencyCode(inStream.getString());
        obj.setType(inStream.getByte());
    }

    writeAccountCurrencyChange(outStream: TSDataOutputStream, obj: AccountCurrencyChange): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getCurrentCurrencyCode());
        outStream.putString(obj.getNewCurrencyCode());
        outStream.putByte(obj.getType());
    }
    readPreliminaryAccountCurrency(inStream: TSDataInputStream, obj: PreliminaryAccountCurrency): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCurrencyCode(inStream.getString());
    }

    writePreliminaryAccountCurrency(outStream: TSDataOutputStream, obj: PreliminaryAccountCurrency): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getCurrencyCode());
    }
    readAccountTemplateParam(inStream: TSDataInputStream, obj: AccountTemplateParam): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setParams(inStream.getString2StringMap());
    }

    writeAccountTemplateParam(outStream: TSDataOutputStream, obj: AccountTemplateParam): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getParams());
    }
    readPlayAllowedDays(inStream: TSDataInputStream, obj: PlayAllowedDays): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsMandatory(inStream.getBoolean());
        obj.setMadatoryDaysLeft(inStream.getLong());
        obj.setOptionalDaysLeft(inStream.getLong());
    }

    writePlayAllowedDays(outStream: TSDataOutputStream, obj: PlayAllowedDays): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsMandatory());
        outStream.putLong(obj.getMadatoryDaysLeft());
        outStream.putLong(obj.getOptionalDaysLeft());
    }
    readChangeAvatar(inStream: TSDataInputStream, obj: ChangeAvatar): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAvatarId(inStream.getInteger());
        obj.setIsCustomAvatar(inStream.getBoolean());
    }

    writeChangeAvatar(outStream: TSDataOutputStream, obj: ChangeAvatar): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAvatarId());
        outStream.putBoolean(obj.getIsCustomAvatar());
    }
    readTimeZoneDetails(inStream: TSDataInputStream, obj: TimeZoneDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDaylightLongDisplayName(inStream.getLong());
        obj.setDaylightShortDisplayName(inStream.getLong());
        obj.setDstSavings(inStream.getInteger());
        obj.setEndDay(inStream.getInteger());
        obj.setEndDayOfWeek(inStream.getInteger());
        obj.setEndMode(inStream.getInteger());
        obj.setEndMonth(inStream.getInteger());
        obj.setEndTime(inStream.getInteger());
        obj.setMetaZoneID(inStream.getString());
        obj.setMsgSTZ(inStream.getBoolean());
        obj.setRowOffset(inStream.getInteger());
        obj.setStandardLongDisplayName(inStream.getLong());
        obj.setStandardShortDisplayName(inStream.getLong());
        obj.setStartDay(inStream.getInteger());
        obj.setStartDayOfWeek(inStream.getInteger());
        obj.setStartMode(inStream.getInteger());
        obj.setStartMonth(inStream.getInteger());
        obj.setStartTime(inStream.getInteger());
        obj.setUseDSTime(inStream.getBoolean());
        obj.setWindowsTZid(inStream.getString());
    }

    writeTimeZoneDetails(outStream: TSDataOutputStream, obj: TimeZoneDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getDaylightLongDisplayName());
        outStream.putLong(obj.getDaylightShortDisplayName());
        outStream.putInteger(obj.getDstSavings());
        outStream.putInteger(obj.getEndDay());
        outStream.putInteger(obj.getEndDayOfWeek());
        outStream.putInteger(obj.getEndMode());
        outStream.putInteger(obj.getEndMonth());
        outStream.putInteger(obj.getEndTime());
        outStream.putString(obj.getMetaZoneID());
        outStream.putBoolean(obj.getMsgSTZ());
        outStream.putInteger(obj.getRowOffset());
        outStream.putLong(obj.getStandardLongDisplayName());
        outStream.putLong(obj.getStandardShortDisplayName());
        outStream.putInteger(obj.getStartDay());
        outStream.putInteger(obj.getStartDayOfWeek());
        outStream.putInteger(obj.getStartMode());
        outStream.putInteger(obj.getStartMonth());
        outStream.putInteger(obj.getStartTime());
        outStream.putBoolean(obj.getUseDSTime());
        outStream.putString(obj.getWindowsTZid());
    }
    readSuspiciousLocationQuestions(inStream: TSDataInputStream, obj: SuspiciousLocationQuestions): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setNoOfAttempts(inStream.getByte());
        obj.setResponseId(inStream.getInteger());
    }

    writeSuspiciousLocationQuestions(outStream: TSDataOutputStream, obj: SuspiciousLocationQuestions): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getNoOfAttempts());
        outStream.putInteger(obj.getResponseId());
    }
    readSuspiciousLocationAnswers(inStream: TSDataInputStream, obj: SuspiciousLocationAnswers): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSuspiciousLocationLoginStatus(inStream.getBoolean());
        obj.setSuspiciuosQnAnsMap(inStream.getInt2StringMap());
    }

    writeSuspiciousLocationAnswers(outStream: TSDataOutputStream, obj: SuspiciousLocationAnswers): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getSuspiciousLocationLoginStatus());
        outStream.putInt2StringMap(obj.getSuspiciuosQnAnsMap());
    }
    readChangeBounceEmail(inStream: TSDataInputStream, obj: ChangeBounceEmail): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setNewEmail(inStream.getString());
    }

    writeChangeBounceEmail(outStream: TSDataOutputStream, obj: ChangeBounceEmail): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getNewEmail());
    }
    readTokenVerificationRequest(inStream: TSDataInputStream, obj: TokenVerificationRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginId(inStream.getString());
        obj.setTokenCode(inStream.getInteger());
    }

    writeTokenVerificationRequest(outStream: TSDataOutputStream, obj: TokenVerificationRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLoginId());
        outStream.putInteger(obj.getTokenCode());
    }
    readSecurityTokenOTPRequest(inStream: TSDataInputStream, obj: SecurityTokenOTPRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginId(inStream.getString());
    }

    writeSecurityTokenOTPRequest(outStream: TSDataOutputStream, obj: SecurityTokenOTPRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLoginId());
    }
    readOTPResponse(inStream: TSDataInputStream, obj: OTPResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastDigitsOfMobile(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
    }

    writeOTPResponse(outStream: TSDataOutputStream, obj: OTPResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLastDigitsOfMobile());
        outStream.putInteger(obj.getResponseId());
    }
    readStringMapResponse(inStream: TSDataInputStream, obj: StringMapResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAttributeMap(inStream.getString2StringMap());
        obj.setMsgType(inStream.getString());
    }

    writeStringMapResponse(outStream: TSDataOutputStream, obj: StringMapResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getAttributeMap());
        outStream.putString(obj.getMsgType());
    }
    readPAMArticle(inStream: TSDataInputStream, obj: PAMArticle): void {
        obj.setArticleCategory(inStream.getInteger());
        obj.setArticleID(inStream.getInteger());
        obj.setEndTime(inStream.getLong());
        obj.setIsBaseHtml(inStream.getBoolean());
        obj.setIsCBTArticle(inStream.getBoolean());
        obj.setIsPersonal(inStream.getBoolean());
        obj.setLanguageId(inStream.getString());
        obj.setPriority(inStream.getByte());
        obj.setStartTime(inStream.getLong());
        obj.setToBeShown(inStream.getBoolean());
    }

    writePAMArticle(outStream: TSDataOutputStream, obj: PAMArticle): void {
        outStream.putInteger(obj.getArticleCategory());
        outStream.putInteger(obj.getArticleID());
        outStream.putLong(obj.getEndTime());
        outStream.putBoolean(obj.getIsBaseHtml());
        outStream.putBoolean(obj.getIsCBTArticle());
        outStream.putBoolean(obj.getIsPersonal());
        outStream.putString(obj.getLanguageId());
        outStream.putByte(obj.getPriority());
        outStream.putLong(obj.getStartTime());
        outStream.putBoolean(obj.getToBeShown());
    }
    readPAMArticleList(inStream: TSDataInputStream, obj: PAMArticleList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAttributeList(inStream.getObjectArray());
    }

    writePAMArticleList(outStream: TSDataOutputStream, obj: PAMArticleList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getAttributeList());
    }
    readRequestMigrationKeepOldCurrency(inStream: TSDataInputStream, obj: RequestMigrationKeepOldCurrency): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestMigrationKeepOldCurrency(outStream: TSDataOutputStream, obj: RequestMigrationKeepOldCurrency): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestMyAccountPanelFull(inStream: TSDataInputStream, obj: RequestMyAccountPanelFull): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestMyAccountPanelFull(outStream: TSDataOutputStream, obj: RequestMyAccountPanelFull): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestMyAccountPanelHalf(inStream: TSDataInputStream, obj: RequestMyAccountPanelHalf): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestMyAccountPanelHalf(outStream: TSDataOutputStream, obj: RequestMyAccountPanelHalf): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseChangeBounceDupEmailFail(inStream: TSDataInputStream, obj: ResponseChangeBounceDupEmailFail): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChangeBounceDupEmailFail(outStream: TSDataOutputStream, obj: ResponseChangeBounceDupEmailFail): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseChangeBounceEmailDBError(inStream: TSDataInputStream, obj: ResponseChangeBounceEmailDBError): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChangeBounceEmailDBError(outStream: TSDataOutputStream, obj: ResponseChangeBounceEmailDBError): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseChangeBounceEmailFail(inStream: TSDataInputStream, obj: ResponseChangeBounceEmailFail): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChangeBounceEmailFail(outStream: TSDataOutputStream, obj: ResponseChangeBounceEmailFail): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseChangeBounceEmailSuccess(inStream: TSDataInputStream, obj: ResponseChangeBounceEmailSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseChangeBounceEmailSuccess(outStream: TSDataOutputStream, obj: ResponseChangeBounceEmailSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseChangeEMailDBError(inStream: TSDataInputStream, obj: ResponseChangeEMailDBError): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChangeEMailDBError(outStream: TSDataOutputStream, obj: ResponseChangeEMailDBError): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseChangeEMailFail(inStream: TSDataInputStream, obj: ResponseChangeEMailFail): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChangeEMailFail(outStream: TSDataOutputStream, obj: ResponseChangeEMailFail): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseChangeEmailSuccess(inStream: TSDataInputStream, obj: ResponseChangeEmailSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseChangeEmailSuccess(outStream: TSDataOutputStream, obj: ResponseChangeEmailSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getConversationId());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseChangePasswordDBError(inStream: TSDataInputStream, obj: ResponseChangePasswordDBError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseChangePasswordDBError(outStream: TSDataOutputStream, obj: ResponseChangePasswordDBError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseChangePasswordFailed(inStream: TSDataInputStream, obj: ResponseChangePasswordFailed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseChangePasswordFailed(outStream: TSDataOutputStream, obj: ResponseChangePasswordFailed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseChangePasswordSuccess(inStream: TSDataInputStream, obj: ResponseChangePasswordSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseChangePasswordSuccess(outStream: TSDataOutputStream, obj: ResponseChangePasswordSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseConvertedToReal(inStream: TSDataInputStream, obj: ResponseConvertedToReal): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseConvertedToReal(outStream: TSDataOutputStream, obj: ResponseConvertedToReal): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseForgotPasswordFailed(inStream: TSDataInputStream, obj: ResponseForgotPasswordFailed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseForgotPasswordFailed(outStream: TSDataOutputStream, obj: ResponseForgotPasswordFailed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseForgotPasswordSuccess(inStream: TSDataInputStream, obj: ResponseForgotPasswordSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseForgotPasswordSuccess(outStream: TSDataOutputStream, obj: ResponseForgotPasswordSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseKeepOldAcctccySuccess(inStream: TSDataInputStream, obj: ResponseKeepOldAcctccySuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseKeepOldAcctccySuccess(outStream: TSDataOutputStream, obj: ResponseKeepOldAcctccySuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRegistrationSuccess(inStream: TSDataInputStream, obj: ResponseRegistrationSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseRegistrationSuccess(outStream: TSDataOutputStream, obj: ResponseRegistrationSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readResponseTellaFriendSuccess(inStream: TSDataInputStream, obj: ResponseTellaFriendSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseTellaFriendSuccess(outStream: TSDataOutputStream, obj: ResponseTellaFriendSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseTellaFriendDBError(inStream: TSDataInputStream, obj: ResponseTellaFriendDBError): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseTellaFriendDBError(outStream: TSDataOutputStream, obj: ResponseTellaFriendDBError): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseTellaFriendFailed(inStream: TSDataInputStream, obj: ResponseTellaFriendFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseTellaFriendFailed(outStream: TSDataOutputStream, obj: ResponseTellaFriendFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseTellaFriendNotLoggedIn(inStream: TSDataInputStream, obj: ResponseTellaFriendNotLoggedIn): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseTellaFriendNotLoggedIn(outStream: TSDataOutputStream, obj: ResponseTellaFriendNotLoggedIn): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseUserAcctCCYNotMatching(inStream: TSDataInputStream, obj: ResponseUserAcctCCYNotMatching): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseUserAcctCCYNotMatching(outStream: TSDataOutputStream, obj: ResponseUserAcctCCYNotMatching): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseValidateCodeFail(inStream: TSDataInputStream, obj: ResponseValidateCodeFail): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseValidateCodeFail(outStream: TSDataOutputStream, obj: ResponseValidateCodeFail): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseValidateCodeSuccess(inStream: TSDataInputStream, obj: ResponseValidateCodeSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMsg(inStream.getStringEx());
    }

    writeResponseValidateCodeSuccess(outStream: TSDataOutputStream, obj: ResponseValidateCodeSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getMsg());
    }
    readResponseValidationCodeSendFailed(inStream: TSDataInputStream, obj: ResponseValidationCodeSendFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseValidationCodeSendFailed(outStream: TSDataOutputStream, obj: ResponseValidationCodeSendFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseValidationCodeSendSuccess(inStream: TSDataInputStream, obj: ResponseValidationCodeSendSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseValidationCodeSendSuccess(outStream: TSDataOutputStream, obj: ResponseValidationCodeSendSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestJackpotPanel(inStream: TSDataInputStream, obj: RequestJackpotPanel): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestJackpotPanel(outStream: TSDataOutputStream, obj: RequestJackpotPanel): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestSendValidationCode(inStream: TSDataInputStream, obj: RequestSendValidationCode): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestSendValidationCode(outStream: TSDataOutputStream, obj: RequestSendValidationCode): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestBounceEmailDontRemind(inStream: TSDataInputStream, obj: RequestBounceEmailDontRemind): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestBounceEmailDontRemind(outStream: TSDataOutputStream, obj: RequestBounceEmailDontRemind): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestBounceEmailRemindLater(inStream: TSDataInputStream, obj: RequestBounceEmailRemindLater): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestBounceEmailRemindLater(outStream: TSDataOutputStream, obj: RequestBounceEmailRemindLater): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRegistrationIDNotAvailable(inStream: TSDataInputStream, obj: ResponseRegistrationIDNotAvailable): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRegistrationIDNotAvailable(outStream: TSDataOutputStream, obj: ResponseRegistrationIDNotAvailable): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRegistrationDuplicateMailId(inStream: TSDataInputStream, obj: ResponseRegistrationDuplicateMailId): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRegistrationDuplicateMailId(outStream: TSDataOutputStream, obj: ResponseRegistrationDuplicateMailId): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRegistrationMultiBrandChkFailed(inStream: TSDataInputStream, obj: ResponseRegistrationMultiBrandChkFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRegistrationMultiBrandChkFailed(outStream: TSDataOutputStream, obj: ResponseRegistrationMultiBrandChkFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseReloginInvalidSessionKey(inStream: TSDataInputStream, obj: ResponseReloginInvalidSessionKey): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseReloginInvalidSessionKey(outStream: TSDataOutputStream, obj: ResponseReloginInvalidSessionKey): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseReloginDBError(inStream: TSDataInputStream, obj: ResponseReloginDBError): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseReloginDBError(outStream: TSDataOutputStream, obj: ResponseReloginDBError): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseReloginServerOverloaded(inStream: TSDataInputStream, obj: ResponseReloginServerOverloaded): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseReloginServerOverloaded(outStream: TSDataOutputStream, obj: ResponseReloginServerOverloaded): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRevisitServerOverLoaded(inStream: TSDataInputStream, obj: ResponseRevisitServerOverLoaded): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRevisitServerOverLoaded(outStream: TSDataOutputStream, obj: ResponseRevisitServerOverLoaded): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseInvalidBonusCode(inStream: TSDataInputStream, obj: ResponseInvalidBonusCode): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseInvalidBonusCode(outStream: TSDataOutputStream, obj: ResponseInvalidBonusCode): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseRevisitSuccess(inStream: TSDataInputStream, obj: ResponseRevisitSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseRevisitSuccess(outStream: TSDataOutputStream, obj: ResponseRevisitSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseReloginSuccess(inStream: TSDataInputStream, obj: ResponseReloginSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseReloginSuccess(outStream: TSDataOutputStream, obj: ResponseReloginSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAllinsRemaining(inStream: TSDataInputStream, obj: RequestAllinsRemaining): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAllinsRemaining(outStream: TSDataOutputStream, obj: RequestAllinsRemaining): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestAllinsReset(inStream: TSDataInputStream, obj: RequestAllinsReset): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestAllinsReset(outStream: TSDataOutputStream, obj: RequestAllinsReset): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestJackpotPromoMonsterPanel(inStream: TSDataInputStream, obj: RequestJackpotPromoMonsterPanel): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestJackpotPromoMonsterPanel(outStream: TSDataOutputStream, obj: RequestJackpotPromoMonsterPanel): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseWordVerifyFailure(inStream: TSDataInputStream, obj: ResponseWordVerifyFailure): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
    }

    writeResponseWordVerifyFailure(outStream: TSDataOutputStream, obj: ResponseWordVerifyFailure): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
    }
    readLastLoginTime(inStream: TSDataInputStream, obj: LastLoginTime): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastLoginTime(inStream.getLong());
    }

    writeLastLoginTime(outStream: TSDataOutputStream, obj: LastLoginTime): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastLoginTime());
    }
    readBalanceInfo(inStream: TSDataInputStream, obj: BalanceInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setBalance(inStream.getLong());
        obj.setFormatRequired(inStream.getBoolean());
        obj.setInPlayBalance(inStream.getLong());
        obj.setNetBalance(inStream.getLong());
    }

    writeBalanceInfo(outStream: TSDataOutputStream, obj: BalanceInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getBalance());
        outStream.putBoolean(obj.getFormatRequired());
        outStream.putLong(obj.getInPlayBalance());
        outStream.putLong(obj.getNetBalance());
    }
    readLoyaltyInfo(inStream: TSDataInputStream, obj: LoyaltyInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsPointsEnabled(inStream.getBoolean());
        obj.setPartyPoints(inStream.getInteger());
        obj.setVipStatus(inStream.getString());
    }

    writeLoyaltyInfo(outStream: TSDataOutputStream, obj: LoyaltyInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsPointsEnabled());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putString(obj.getVipStatus());
    }
    readPartyInboxInfo(inStream: TSDataInputStream, obj: PartyInboxInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsPmcEnabled(inStream.getBoolean());
        obj.setMailCount(inStream.getInteger());
    }

    writePartyInboxInfo(outStream: TSDataOutputStream, obj: PartyInboxInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsPmcEnabled());
        outStream.putInteger(obj.getMailCount());
    }
    readBonusInfo(inStream: TSDataInputStream, obj: BonusInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAbCurrBonus(inStream.getString());
        obj.setAbTotalBonus(inStream.getString());
        obj.setBonusOffer1(inStream.getString());
        obj.setBonusOffer2(inStream.getString());
        obj.setBonusOffer3(inStream.getString());
        obj.setBonusOffer4(inStream.getString());
        obj.setBonusOfferUrl(inStream.getString());
        obj.setCapAccCurrType(inStream.getString());
        obj.setCapBonusPercent(inStream.getString());
        obj.setCapMaxBonus(inStream.getString());
    }

    writeBonusInfo(outStream: TSDataOutputStream, obj: BonusInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAbCurrBonus());
        outStream.putString(obj.getAbTotalBonus());
        outStream.putString(obj.getBonusOffer1());
        outStream.putString(obj.getBonusOffer2());
        outStream.putString(obj.getBonusOffer3());
        outStream.putString(obj.getBonusOffer4());
        outStream.putString(obj.getBonusOfferUrl());
        outStream.putString(obj.getCapAccCurrType());
        outStream.putString(obj.getCapBonusPercent());
        outStream.putString(obj.getCapMaxBonus());
    }
    readLoginDenial(inStream: TSDataInputStream, obj: LoginDenial): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountName(inStream.getString());
        obj.setConversationId(inStream.getLong());
        obj.setDescription(inStream.getStringEx());
        obj.setScreenName(inStream.getString());
    }

    writeLoginDenial(outStream: TSDataOutputStream, obj: LoginDenial): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getAccountName());
        outStream.putLong(obj.getConversationId());
        outStream.putStringEx(obj.getDescription());
        outStream.putString(obj.getScreenName());
    }
    readRequestTermsAndConditionsAcceptance(inStream: TSDataInputStream, obj: RequestTermsAndConditionsAcceptance): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestTermsAndConditionsAcceptance(outStream: TSDataOutputStream, obj: RequestTermsAndConditionsAcceptance): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseTermsAndConditionsAcceptance(inStream: TSDataInputStream, obj: ResponseTermsAndConditionsAcceptance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTermsAndConditionsAccepted(inStream.getBoolean());
    }

    writeResponseTermsAndConditionsAcceptance(outStream: TSDataOutputStream, obj: ResponseTermsAndConditionsAcceptance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getTermsAndConditionsAccepted());
    }
    readEDSNGRequestMessage(inStream: TSDataInputStream, obj: EDSNGRequestMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEventDataId(inStream.getLong());
        obj.setTableId(inStream.getLong());
        obj.setTemplate(inStream.getString());
        obj.setTemplateParams(inStream.getString2StringMap());
    }

    writeEDSNGRequestMessage(outStream: TSDataOutputStream, obj: EDSNGRequestMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getEventDataId());
        outStream.putLong(obj.getTableId());
        outStream.putString(obj.getTemplate());
        outStream.putString2StringMap(obj.getTemplateParams());
    }
    readEDSNGResponseMessage(inStream: TSDataInputStream, obj: EDSNGResponseMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAcceptanceStatus(inStream.getInteger());
        obj.setEventDataId(inStream.getLong());
    }

    writeEDSNGResponseMessage(outStream: TSDataOutputStream, obj: EDSNGResponseMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getAcceptanceStatus());
        outStream.putLong(obj.getEventDataId());
    }
    readIdleInfo(inStream: TSDataInputStream, obj: IdleInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsActive(inStream.getBoolean());
        obj.setUserIdleForSec(inStream.getInteger());
    }

    writeIdleInfo(outStream: TSDataOutputStream, obj: IdleInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsActive());
        outStream.putInteger(obj.getUserIdleForSec());
    }
    readRequestReconnectOnUserAction(inStream: TSDataInputStream, obj: RequestReconnectOnUserAction): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestReconnectOnUserAction(outStream: TSDataOutputStream, obj: RequestReconnectOnUserAction): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readAutoLoginRequest(inStream: TSDataInputStream, obj: AutoLoginRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setInvokerProductId(inStream.getString());
        obj.setInvokerSessionKey(inStream.getString());
        obj.setSsoKey(inStream.getString());
        obj.setLoginId(inStream.getString());
    }

    writeAutoLoginRequest(outStream: TSDataOutputStream, obj: AutoLoginRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getInvokerProductId());
        outStream.putString(obj.getInvokerSessionKey());
        outStream.putString(obj.getSsoKey());
        outStream.putString(obj.getLoginId());
    }
    readLoginFailureResponse(inStream: TSDataInputStream, obj: LoginFailureResponse): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeLoginFailureResponse(outStream: TSDataOutputStream, obj: LoginFailureResponse): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readLoginIncompleteLaunchURL(inStream: TSDataInputStream, obj: LoginIncompleteLaunchURL): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setInterceptorName(inStream.getString());
    }

    writeLoginIncompleteLaunchURL(outStream: TSDataOutputStream, obj: LoginIncompleteLaunchURL): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
        outStream.putString(obj.getInterceptorName());
    }
    readLoginServiceUnavailable(inStream: TSDataInputStream, obj: LoginServiceUnavailable): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeLoginServiceUnavailable(outStream: TSDataOutputStream, obj: LoginServiceUnavailable): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readClientSwitchMessage(inStream: TSDataInputStream, obj: ClientSwitchMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setNavigationUrl(inStream.getString());
        obj.setClientUrl(inStream.getString());
        obj.setSilent(inStream.getBoolean());
    }

    writeClientSwitchMessage(outStream: TSDataOutputStream, obj: ClientSwitchMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getNavigationUrl());
        outStream.putString(obj.getClientUrl());
        outStream.putBoolean(obj.getSilent());
    }
    readAvatarUpdateEventMessage(inStream: TSDataInputStream, obj: AvatarUpdateEventMessage): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setUserId(inStream.getString());
        obj.setUserAvatarInfo(inStream.getObject());
    }

    writeAvatarUpdateEventMessage(outStream: TSDataOutputStream, obj: AvatarUpdateEventMessage): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getUserId());
        outStream.putObject(obj.getUserAvatarInfo());
    }
    readGameTypeInfo(inStream: TSDataInputStream, obj: GameTypeInfo): void {
        obj.setLimitType(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
    }

    writeGameTypeInfo(outStream: TSDataOutputStream, obj: GameTypeInfo): void {
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getGameType());
    }
    readGameVariantBonus(inStream: TSDataInputStream, obj: GameVariantBonus): void {
        obj.setGameCategory(inStream.getInteger());
        obj.setAllowedStakesInCents(inStream.getIntArray());
        obj.setAllowedSeats(inStream.getIntArray());
        obj.setAllowedPools(inStream.getIntArray());
        obj.setAllowedGameTypes(inStream.getObjectArray());
        obj.setGvb(inStream.getBoolean());
        obj.setBonusAmount(inStream.getLong());
        obj.setAccountCurrencyType(inStream.getString());
    }

    writeGameVariantBonus(outStream: TSDataOutputStream, obj: GameVariantBonus): void {
        outStream.putInteger(obj.getGameCategory());
        outStream.putIntArray(obj.getAllowedStakesInCents());
        outStream.putIntArray(obj.getAllowedSeats());
        outStream.putIntArray(obj.getAllowedPools());
        outStream.putObjectArray(obj.getAllowedGameTypes());
        outStream.putBoolean(obj.getGvb());
        outStream.putLong(obj.getBonusAmount());
        outStream.putString(obj.getAccountCurrencyType());
    }
    readPlayBalance(inStream: TSDataInputStream, obj: PlayBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChips(inStream.getLong());
    }

    writePlayBalance(outStream: TSDataOutputStream, obj: PlayBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getChips());
    }
    readRealBalance(inStream: TSDataInputStream, obj: RealBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountBalance(inStream.getLong());
        obj.setAccountCurrency(inStream.getString());
        obj.setCashOutableBalance(inStream.getLong());
        obj.setRestrictedBalance(inStream.getObjectArray());
        obj.setPayPalBalance(inStream.getLong());
    }

    writeRealBalance(outStream: TSDataOutputStream, obj: RealBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAccountBalance());
        outStream.putString(obj.getAccountCurrency());
        outStream.putLong(obj.getCashOutableBalance());
        outStream.putObjectArray(obj.getRestrictedBalance());
        outStream.putLong(obj.getPayPalBalance());
    }
    readRequestUserBalanceInfo(inStream: TSDataInputStream, obj: RequestUserBalanceInfo): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestUserBalanceInfo(outStream: TSDataOutputStream, obj: RequestUserBalanceInfo): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readTourneyCurrencyBalance(inStream: TSDataInputStream, obj: TourneyCurrencyBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTourneyCurrencyBalance(inStream.getLong());
        obj.setTourneyCurrencyType(inStream.getString());
    }

    writeTourneyCurrencyBalance(outStream: TSDataOutputStream, obj: TourneyCurrencyBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getTourneyCurrencyBalance());
        outStream.putString(obj.getTourneyCurrencyType());
    }
    readResponseUserBalanceInfo(inStream: TSDataInputStream, obj: ResponseUserBalanceInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayBalance(inStream.getObject());
        obj.setRealBalance(inStream.getObject());
        obj.setTourneyCurrencyBalance(inStream.getObject());
    }

    writeResponseUserBalanceInfo(outStream: TSDataOutputStream, obj: ResponseUserBalanceInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getPlayBalance());
        outStream.putObject(obj.getRealBalance());
        outStream.putObject(obj.getTourneyCurrencyBalance());
    }
    readResponseUserBalanceInfoError(inStream: TSDataInputStream, obj: ResponseUserBalanceInfoError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
    }

    writeResponseUserBalanceInfoError(outStream: TSDataOutputStream, obj: ResponseUserBalanceInfoError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
    }
    readNotifyPlayerProfileWithSSOKey(inStream: TSDataInputStream, obj: NotifyPlayerProfileWithSSOKey): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEncryptedProfile(inStream.getBytes());
        obj.setSessionKey(inStream.getString());
        obj.setEncodedSSOKey(inStream.getString());
    }

    writeNotifyPlayerProfileWithSSOKey(outStream: TSDataOutputStream, obj: NotifyPlayerProfileWithSSOKey): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getEncryptedProfile());
        outStream.putString(obj.getSessionKey());
        outStream.putString(obj.getEncodedSSOKey());
    }
    readNetRealBalance(inStream: TSDataInputStream, obj: NetRealBalance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAccountBalance(inStream.getLong());
        obj.setAccountCurrency(inStream.getString());
        obj.setCashOutableBalance(inStream.getLong());
        obj.setRestrictedBalance(inStream.getObjectArray());
        obj.setNetAccountBalance(inStream.getLong());
        obj.setPayPalBalance(inStream.getLong());
    }

    writeNetRealBalance(outStream: TSDataOutputStream, obj: NetRealBalance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getAccountBalance());
        outStream.putString(obj.getAccountCurrency());
        outStream.putLong(obj.getCashOutableBalance());
        outStream.putObjectArray(obj.getRestrictedBalance());
        outStream.putLong(obj.getNetAccountBalance());
        outStream.putLong(obj.getPayPalBalance());
    }
    readRequestNetUserBalanceInfo(inStream: TSDataInputStream, obj: RequestNetUserBalanceInfo): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestNetUserBalanceInfo(outStream: TSDataOutputStream, obj: RequestNetUserBalanceInfo): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseNetUserBalanceInfo(inStream: TSDataInputStream, obj: ResponseNetUserBalanceInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayBalance(inStream.getObject());
        obj.setNetRealBalance(inStream.getObject());
        obj.setTourneyCurrencyBalance(inStream.getObject());
    }

    writeResponseNetUserBalanceInfo(outStream: TSDataOutputStream, obj: ResponseNetUserBalanceInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getPlayBalance());
        outStream.putObject(obj.getNetRealBalance());
        outStream.putObject(obj.getTourneyCurrencyBalance());
    }
    readResponseNetUserBalanceInfoError(inStream: TSDataInputStream, obj: ResponseNetUserBalanceInfoError): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
    }

    writeResponseNetUserBalanceInfoError(outStream: TSDataOutputStream, obj: ResponseNetUserBalanceInfoError): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getErrorCode());
    }
    readSessionLimitLogout(inStream: TSDataInputStream, obj: SessionLimitLogout): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeSessionLimitLogout(outStream: TSDataOutputStream, obj: SessionLimitLogout): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readRequestToStayORLogOutOnUserAction(inStream: TSDataInputStream, obj: RequestToStayORLogOutOnUserAction): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTimeLeftInSec(inStream.getInteger());
    }

    writeRequestToStayORLogOutOnUserAction(outStream: TSDataOutputStream, obj: RequestToStayORLogOutOnUserAction): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTimeLeftInSec());
    }
    readRTPointsUpdate(inStream: TSDataInputStream, obj: RTPointsUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setIsPointsEnabled(inStream.getBoolean());
        obj.setPlayerCategory(inStream.getString());
        obj.setPoints(inStream.getInteger());
    }

    writeRTPointsUpdate(outStream: TSDataOutputStream, obj: RTPointsUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getIsPointsEnabled());
        outStream.putString(obj.getPlayerCategory());
        outStream.putInteger(obj.getPoints());
    }
    readForceLogoutFromPartner(inStream: TSDataInputStream, obj: ForceLogoutFromPartner): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeForceLogoutFromPartner(outStream: TSDataOutputStream, obj: ForceLogoutFromPartner): void {
        outStream.putInteger(obj.getMsgNumber());
    }
}
