import { IPGMessageFactory } from '../server/common/interfaces/IPGMessageFactory';
import { IPGMessageFactoryRegistration } from '../server/common/interfaces/IPGMessageFactoryRegistration';

export class PGMessageFactoryRegistration implements IPGMessageFactoryRegistration {
    private msgFactoryType: Map<IPGMessageFactory, boolean> = new Map<IPGMessageFactory, boolean>();
    // private static instance: PGMessageFactoryRegistration;
    public registerFactory(factory: IPGMessageFactory, isClient?: boolean | any): void {
        if (isClient !== null || undefined) {
            this.msgFactoryType.set(factory, isClient);
            // factory.setBCEnabled(PGConnector.isBCEnabled());
        } else {
            this.registerFactory(factory, true);
        }
    }

    public getMessageFactoryTypeTable(): Map<IPGMessageFactory, boolean> {
        return this.msgFactoryType;
    }
}
