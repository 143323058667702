import { Message } from '../../message';

export class ResponseElapsedTime extends Message {
    private MESSAGE_NAME: string = 'ResponseElapsedTime';
    private activeStatus: boolean | null = false;
    private firstActivatedTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, activeStatus: boolean | null = false, firstActivatedTime: number = 0) {
        super(reqServerPeerId);
        this.activeStatus = activeStatus;
        this.firstActivatedTime = firstActivatedTime;
    }

    getActiveStatus(): boolean | null {
        return this.activeStatus;
    }

    setActiveStatus(activeStatus: boolean | null) {
        this.activeStatus = activeStatus;
    }
    getFirstActivatedTime(): number {
        return this.firstActivatedTime;
    }

    setFirstActivatedTime(firstActivatedTime: number) {
        this.firstActivatedTime = firstActivatedTime;
    }
}
