import { Message } from '../../message';

export class FXRateSnapshot extends Message {
    private MESSAGE_NAME: string = 'FXRateSnapshot';
    private snapshotId: number = 0;
    private fxRates: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, snapshotId: number = 0, fxRates: any[] | null = null) {
        super(reqServerPeerId);
        this.snapshotId = snapshotId;
        this.fxRates = fxRates;
    }

    getSnapshotId(): number {
        return this.snapshotId;
    }

    setSnapshotId(snapshotId: number) {
        this.snapshotId = snapshotId;
    }
    getFxRates(): any[] | null {
        return this.fxRates;
    }

    setFxRates(fxRates: any[] | null) {
        this.fxRates = fxRates;
    }
}
