import { Message } from '../../message';

export class FXRateRequest extends Message {
    private MESSAGE_NAME: string = 'FXRateRequest';
    private fromCurrencyCode: string | null = null;
    private toCurrencyCode: string | null = null;
    private snapshotId: number = 0;
    private markupType: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    // constructor();

    constructor(
        reqServerPeerId: number = 0,
        fromCurrencyCode: string | null = null,
        toCurrencyCode: string | null = null,
        snapshotId: number = 0,
        markupType: string | null = null,
    ) {
        super(reqServerPeerId);
        this.fromCurrencyCode = fromCurrencyCode;
        this.toCurrencyCode = toCurrencyCode;
        this.snapshotId = snapshotId;
        this.markupType = markupType;
    }

    getFromCurrencyCode(): string | null {
        return this.fromCurrencyCode;
    }

    setFromCurrencyCode(fromCurrencyCode: string | null) {
        this.fromCurrencyCode = fromCurrencyCode;
    }
    getToCurrencyCode(): string | null {
        return this.toCurrencyCode;
    }

    setToCurrencyCode(toCurrencyCode: string | null) {
        this.toCurrencyCode = toCurrencyCode;
    }
    getSnapshotId(): number {
        return this.snapshotId;
    }

    setSnapshotId(snapshotId: number) {
        this.snapshotId = snapshotId;
    }
    getMarkupType(): string | null {
        return this.markupType;
    }

    setMarkupType(markupType: string | null) {
        this.markupType = markupType;
    }
}
