import { Message } from '../../message';

export class ShowRabbit extends Message {
    private MESSAGE_NAME: string = 'ShowRabbit';
    private rabbitValue: number = 0;
    private gameId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, rabbitValue: number = 0, gameId: number = 0) {
        super(reqServerPeerId);
        this.rabbitValue = rabbitValue;
        this.gameId = gameId;
    }

    getRabbitValue(): number {
        return this.rabbitValue;
    }

    setRabbitValue(rabbitValue: number) {
        this.rabbitValue = rabbitValue;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
}
