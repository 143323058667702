import { Injectable, Renderer2 } from '@angular/core';

import {
    AuthService,
    LoginDialogService,
    LoginNavigationService,
    NavigationService,
    SharedFeaturesApiService,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import { first } from 'rxjs';

import { BaseLobbyConnector } from '../pgBackendConnectors/baseLobbyConnector';
import { PokerBackendConnector } from '../pgBackendConnectors/pokerBackendConnector';
import { CSD } from '../pgConnectors/sal/CSD';
import { LoginRequest } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/LoginRequest';
import { updateIsAuthenticatedStatus } from '../store/user.store';

@Injectable({ providedIn: 'root' })
export class PokerWebClientLoginService extends BaseLobbyConnector {
    readonly logger: PokerLogger;
    readonly returnUrl = window.location.href;
    isloggedOut: boolean = false;
    constructor(
        private userService: UserService,
        private store: Store,
        private loginDialogService: LoginDialogService,
        private sharedFeaturesApiService: SharedFeaturesApiService,
        private authService: AuthService,
        private regDialogService: LoginNavigationService,
        private renderer: Renderer2,
        loggerFactory: LoggerFactory,
        private navigation: NavigationService,
    ) {
        super(PokerBackendConnector.getInstance());
        // this.requestForClientLogin();
        this.logger = loggerFactory.getLogger('pokerwebclientloginserviceLoggingComponent');
        if (this.userService.id != null && this.userService.username != null) {
            this.requestForClientLogin();
        }
        this.userService.events.pipe(first((e) => e instanceof UserLoginEvent)).subscribe(() => {
            setTimeout(() => {
                this.requestForClientLogin();
            }, 1500);
        });
    }

    /**
     * Request for client login to the game server.
     */
    requestForClientLogin(): void {
        // Update the authentication status in the store
        this.store.dispatch(updateIsAuthenticatedStatus(this.userService.isAuthenticated));

        // Create a new LoginRequest and set the login ID and SSO key
        const loginRequest: LoginRequest = new LoginRequest();
        loginRequest.setLoginId(this.userService.id);
        loginRequest.setSsoKey(this.userService.ssoToken);

        // Send the login request to the domain
        this.sendMessageOnDomain(loginRequest, CSD.REAL);

        // Log the login request
        //console.log('user client login Request..!!!', loginRequest);
        this.logger.info('user client login Request..!!!!' + JSON.stringify(loginRequest));
    }

    // Portal Login

    /**
     * Requests for portal login by opening a login dialog.
     *
     * @returns {void}
     */
    requestForPortalLogin(): void {
        this.renderer.addClass(document.body, 'pwc-login-modal-show');
        // Open the login dialog with the current URL as the return URL
        this.loginDialogService.whenReady.subscribe(() => {
            this.loginDialogService
                .open({ returnUrl: this.returnUrl, autoFocus: false })
                // Subscribe to the beforeClosed event of the dialog
                .beforeClosed()
                .subscribe((resp: any) => {
                    this.renderer.removeClass(document.body, 'pwc-login-modal-show');
                    // Log the success message with the response
                    //console.log("Portal LogIn success...!!! ", resp);
                    this.logger.info('Portal LogIn success...!!!!' + JSON.stringify(resp));
                });
        });
    }

    /**
     * Requests for portal registration.
     *
     * This function navigates to the registration page of the portal
     * and appends the current URL as the referrer.
     */
    requestForPortalRegistration() {
        // Navigate to the registration page and append the referrer
        this.regDialogService.goToRegistration({ appendReferrer: this.returnUrl });
        this.logger.info('requestForPortalRegistration...!!!!');
    }

    /**
     * Requests the server to log out the user from the portal.
     * Logs out the client and redirects to the play page.
     */
    requestForPortalLogOut() {
        this.isloggedOut = true;
        // Send request to server to log out
        this.sharedFeaturesApiService.post('auth/logout');

        // Send client logout signal
        this.sendClientLogout();

        // Log out the user on the client side
        this.authService.logout({ redirectAfterLogout: false, isAutoLogout: true }).then(() => {
            // Redirect to the play page with force reload and no referrer appended
            this.navigation.goTo('/play', { forceReload: true, appendReferrer: false });

            // Log success message
            //console.log("Portal LogOut success...!!! ");
            this.logger.info('Portal LogIn success...!!!!');
        });
    }
}
