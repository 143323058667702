import CustomEvent from 'custom-event-js';

export class EventDispatcher {
    customEvent;
    tableId;
    constructor(tableId) {
        this.customEvent = CustomEvent;
        this.tableId = tableId;
    }
    dispatchEvent(funcName: string, params: any): void {
        if (params != undefined) {
            this.customEvent.dispatch(funcName + this.tableId, params);
        } else {
            this.customEvent.dispatch(funcName + this.tableId);
        }
    }

    addEventListener(eventName: string, callback: any): void {
        this.customEvent.on(eventName + this.tableId, callback);
    }

    removeEventListener(eventName: string): void {
        this.customEvent.off(eventName + this.tableId);
    }
}
