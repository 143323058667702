import { Message } from '../../message';

export class EnableTimeBank extends Message {
    private MESSAGE_NAME: string = 'EnableTimeBank';
    private optionSetId: number = 0;
    private timeBankTimeoutAt: number = 0;
    private timeBankAutoEnabled: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, optionSetId: number = 0, timeBankTimeoutAt: number = 0, timeBankAutoEnabled: boolean | null = false) {
        super(reqServerPeerId);
        this.optionSetId = optionSetId;
        this.timeBankTimeoutAt = timeBankTimeoutAt;
        this.timeBankAutoEnabled = timeBankAutoEnabled;
    }

    getOptionSetId(): number {
        return this.optionSetId;
    }

    setOptionSetId(optionSetId: number) {
        this.optionSetId = optionSetId;
    }
    getTimeBankTimeoutAt(): number {
        return this.timeBankTimeoutAt;
    }

    setTimeBankTimeoutAt(timeBankTimeoutAt: number) {
        this.timeBankTimeoutAt = timeBankTimeoutAt;
    }
    getTimeBankAutoEnabled(): boolean | null {
        return this.timeBankAutoEnabled;
    }

    setTimeBankAutoEnabled(timeBankAutoEnabled: boolean | null) {
        this.timeBankAutoEnabled = timeBankAutoEnabled;
    }
}
