import { Message } from '../../message';

export class SNGJPCard extends Message {
    private MESSAGE_NAME: string = 'SNGJPCard';
    private sngJPId: number = 0;
    private name: any | null = null;
    private buyIn: number = 0;
    private tourneyFee: number = 0;
    private isTrnyFeeTypePercent: boolean | null = false;
    private trnyFeePercent: number = 0;
    private gameCurrency: string | null = null;
    private sngJPType: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;
    private maxRegistrations: number = 0;
    private gamePlayType: number = 0;
    private minMultiplier: number = 0;
    private maxMultiplier: number = 0;
    private defaultChips: number = 0;
    private buyInType: number = 0;
    private tournmentTicketList: any[] | null = null;
    private isPasswordProtected: boolean | null = false;
    private serverPeerId: number = 0;
    private winUptoAmount: number = 0;
    private promotionalTitle: string | null = null;
    private promotionalSubTitle: string | null = null;
    private payOutType: number = 0;
    private totalPrizeValue: number = 0;
    private jackpotTemplateId: number = 0;
    private summary: any | null = null;
    private moreInfoUrl: string | null = null;
    private blindDuration: number = 0;
    private subLiquidityType: number = 0;
    private imageId: string | null = null;
    private customisedImage: boolean | null = false;
    private cardType: number = 0;
    private cardPropertiesId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJPId: number = 0,
        name: any | null = null,
        buyIn: number = 0,
        tourneyFee: number = 0,
        isTrnyFeeTypePercent: boolean | null = false,
        trnyFeePercent: number = 0,
        gameCurrency: string | null = null,
        sngJPType: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
        maxRegistrations: number = 0,
        gamePlayType: number = 0,
        minMultiplier: number = 0,
        maxMultiplier: number = 0,
        defaultChips: number = 0,
        buyInType: number = 0,
        tournmentTicketList: any[] | null = null,
        isPasswordProtected: boolean | null = false,
        serverPeerId: number = 0,
        winUptoAmount: number = 0,
        promotionalTitle: string | null = null,
        promotionalSubTitle: string | null = null,
        payOutType: number = 0,
        totalPrizeValue: number = 0,
        jackpotTemplateId: number = 0,
        summary: any | null = null,
        moreInfoUrl: string | null = null,
        blindDuration: number = 0,
        subLiquidityType: number = 0,
        imageId: string | null = null,
        customisedImage: boolean | null = false,
        cardType: number = 0,
        cardPropertiesId: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJPId = sngJPId;
        this.name = name;
        this.buyIn = buyIn;
        this.tourneyFee = tourneyFee;
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
        this.trnyFeePercent = trnyFeePercent;
        this.gameCurrency = gameCurrency;
        this.sngJPType = sngJPType;
        this.gameType = gameType;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
        this.maxRegistrations = maxRegistrations;
        this.gamePlayType = gamePlayType;
        this.minMultiplier = minMultiplier;
        this.maxMultiplier = maxMultiplier;
        this.defaultChips = defaultChips;
        this.buyInType = buyInType;
        this.tournmentTicketList = tournmentTicketList;
        this.isPasswordProtected = isPasswordProtected;
        this.serverPeerId = serverPeerId;
        this.winUptoAmount = winUptoAmount;
        this.promotionalTitle = promotionalTitle;
        this.promotionalSubTitle = promotionalSubTitle;
        this.payOutType = payOutType;
        this.totalPrizeValue = totalPrizeValue;
        this.jackpotTemplateId = jackpotTemplateId;
        this.summary = summary;
        this.moreInfoUrl = moreInfoUrl;
        this.blindDuration = blindDuration;
        this.subLiquidityType = subLiquidityType;
        this.imageId = imageId;
        this.customisedImage = customisedImage;
        this.cardType = cardType;
        this.cardPropertiesId = cardPropertiesId;
    }

    getSngJPId(): number {
        return this.sngJPId;
    }

    setSngJPId(sngJPId: number) {
        this.sngJPId = sngJPId;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getTourneyFee(): number {
        return this.tourneyFee;
    }

    setTourneyFee(tourneyFee: number) {
        this.tourneyFee = tourneyFee;
    }
    getIsTrnyFeeTypePercent(): boolean | null {
        return this.isTrnyFeeTypePercent;
    }

    setIsTrnyFeeTypePercent(isTrnyFeeTypePercent: boolean | null) {
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
    }
    getTrnyFeePercent(): number {
        return this.trnyFeePercent;
    }

    setTrnyFeePercent(trnyFeePercent: number) {
        this.trnyFeePercent = trnyFeePercent;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getSngJPType(): number {
        return this.sngJPType;
    }

    setSngJPType(sngJPType: number) {
        this.sngJPType = sngJPType;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getMaxRegistrations(): number {
        return this.maxRegistrations;
    }

    setMaxRegistrations(maxRegistrations: number) {
        this.maxRegistrations = maxRegistrations;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getMinMultiplier(): number {
        return this.minMultiplier;
    }

    setMinMultiplier(minMultiplier: number) {
        this.minMultiplier = minMultiplier;
    }
    getMaxMultiplier(): number {
        return this.maxMultiplier;
    }

    setMaxMultiplier(maxMultiplier: number) {
        this.maxMultiplier = maxMultiplier;
    }
    getDefaultChips(): number {
        return this.defaultChips;
    }

    setDefaultChips(defaultChips: number) {
        this.defaultChips = defaultChips;
    }
    getBuyInType(): number {
        return this.buyInType;
    }

    setBuyInType(buyInType: number) {
        this.buyInType = buyInType;
    }
    getTournmentTicketList(): any[] | null {
        return this.tournmentTicketList;
    }

    setTournmentTicketList(tournmentTicketList: any[] | null) {
        this.tournmentTicketList = tournmentTicketList;
    }
    getIsPasswordProtected(): boolean | null {
        return this.isPasswordProtected;
    }

    setIsPasswordProtected(isPasswordProtected: boolean | null) {
        this.isPasswordProtected = isPasswordProtected;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
    getWinUptoAmount(): number {
        return this.winUptoAmount;
    }

    setWinUptoAmount(winUptoAmount: number) {
        this.winUptoAmount = winUptoAmount;
    }
    getPromotionalTitle(): string | null {
        return this.promotionalTitle;
    }

    setPromotionalTitle(promotionalTitle: string | null) {
        this.promotionalTitle = promotionalTitle;
    }
    getPromotionalSubTitle(): string | null {
        return this.promotionalSubTitle;
    }

    setPromotionalSubTitle(promotionalSubTitle: string | null) {
        this.promotionalSubTitle = promotionalSubTitle;
    }
    getPayOutType(): number {
        return this.payOutType;
    }

    setPayOutType(payOutType: number) {
        this.payOutType = payOutType;
    }
    getTotalPrizeValue(): number {
        return this.totalPrizeValue;
    }

    setTotalPrizeValue(totalPrizeValue: number) {
        this.totalPrizeValue = totalPrizeValue;
    }
    getJackpotTemplateId(): number {
        return this.jackpotTemplateId;
    }

    setJackpotTemplateId(jackpotTemplateId: number) {
        this.jackpotTemplateId = jackpotTemplateId;
    }
    getSummary(): any | null {
        return this.summary;
    }

    setSummary(summary: any | null) {
        this.summary = summary;
    }
    getMoreInfoUrl(): string | null {
        return this.moreInfoUrl;
    }

    setMoreInfoUrl(moreInfoUrl: string | null) {
        this.moreInfoUrl = moreInfoUrl;
    }
    getBlindDuration(): number {
        return this.blindDuration;
    }

    setBlindDuration(blindDuration: number) {
        this.blindDuration = blindDuration;
    }
    getSubLiquidityType(): number {
        return this.subLiquidityType;
    }

    setSubLiquidityType(subLiquidityType: number) {
        this.subLiquidityType = subLiquidityType;
    }
    getImageId(): string | null {
        return this.imageId;
    }

    setImageId(imageId: string | null) {
        this.imageId = imageId;
    }
    getCustomisedImage(): boolean | null {
        return this.customisedImage;
    }

    setCustomisedImage(customisedImage: boolean | null) {
        this.customisedImage = customisedImage;
    }
    getCardType(): number {
        return this.cardType;
    }

    setCardType(cardType: number) {
        this.cardType = cardType;
    }
    getCardPropertiesId(): number {
        return this.cardPropertiesId;
    }

    setCardPropertiesId(cardPropertiesId: number) {
        this.cardPropertiesId = cardPropertiesId;
    }
}
