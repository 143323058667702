import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { CashErrorDialogModel } from '../../models/cash-error-dialog.model';

@Component({
    selector: 'pwc-error-dialog',
    templateUrl: 'pwc-error-dialog.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PWCErrorDialogComponent {
    private readonly logger: PokerLogger;

    constructor(
        loggerFactory: LoggerFactory,
        private dialogRef: MatDialogRef<PWCErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CashErrorDialogModel,
    ) {
        this.logger = loggerFactory.getLogger('pwcerrordialogLoggingComponent');
    }

    closePopup() {
        this.logger.info('clicked on close popup icon..!');
        this.dialogRef.close();
    }
}
