import { Message } from '../../message';

export class MTCTParticipantNamesOnRequest extends Message {
    private MESSAGE_NAME: string = 'MTCTParticipantNamesOnRequest';
    private mtctId: number = 0;
    private participants: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, participants: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.participants = participants;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getParticipants(): Map<any, any> | null {
        return this.participants;
    }

    setParticipants(participants: Map<any, any> | null) {
        this.participants = participants;
    }
}
