import { Message } from '../../message';
import { SNGJPBountyInfo } from './SNGJPBountyInfo';

export class SNGJPBountyAwardInfo extends Message {
    private MESSAGE_NAME: string = 'SNGJPBountyAwardInfo';
    private bountyWinningMessage: any[] | null = null;
    private timeToDisplay: number = 0;
    private sngJPBountyInfo: SNGJPBountyInfo | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        bountyWinningMessage: any[] | null = null,
        timeToDisplay: number = 0,
        sngJPBountyInfo: SNGJPBountyInfo | null = null,
    ) {
        super(reqServerPeerId);
        this.bountyWinningMessage = bountyWinningMessage;
        this.timeToDisplay = timeToDisplay;
        this.sngJPBountyInfo = sngJPBountyInfo;
    }

    getBountyWinningMessage(): any[] | null {
        return this.bountyWinningMessage;
    }

    setBountyWinningMessage(bountyWinningMessage: any[] | null) {
        this.bountyWinningMessage = bountyWinningMessage;
    }
    getTimeToDisplay(): number {
        return this.timeToDisplay;
    }

    setTimeToDisplay(timeToDisplay: number) {
        this.timeToDisplay = timeToDisplay;
    }
    getSngJPBountyInfo(): SNGJPBountyInfo | null {
        return this.sngJPBountyInfo;
    }

    setSngJPBountyInfo(sngJPBountyInfo: SNGJPBountyInfo | null) {
        this.sngJPBountyInfo = sngJPBountyInfo;
    }
}
