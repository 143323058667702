import { Message } from '../../message';

export class TakeScreenShot extends Message {
    private MESSAGE_NAME: string = 'TakeScreenShot';
    private screenShotDestinationUrl: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenShotDestinationUrl: string | null = null) {
        super(reqServerPeerId);
        this.screenShotDestinationUrl = screenShotDestinationUrl;
    }

    getScreenShotDestinationUrl(): string | null {
        return this.screenShotDestinationUrl;
    }

    setScreenShotDestinationUrl(screenShotDestinationUrl: string | null) {
        this.screenShotDestinationUrl = screenShotDestinationUrl;
    }
}
