import { Message } from '../../message';

export class NewGame extends Message {
    private MESSAGE_NAME: string = 'NewGame';
    private gameId: number = 0;
    private seatNo: number = 0;
    private encGameId: string | null = null;
    private privateGId: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        gameId: number = 0,
        seatNo: number = 0,
        encGameId: string | null = null,
        privateGId: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.gameId = gameId;
        this.seatNo = seatNo;
        this.encGameId = encGameId;
        this.privateGId = privateGId;
    }

    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getEncGameId(): string | null {
        return this.encGameId;
    }

    setEncGameId(encGameId: string | null) {
        this.encGameId = encGameId;
    }
    getPrivateGId(): boolean | null {
        return this.privateGId;
    }

    setPrivateGId(privateGId: boolean | null) {
        this.privateGId = privateGId;
    }
}
