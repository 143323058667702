import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PokerMyGameBootstrapService } from './poker-my-game-bootstrap.service';
import { MyGameDataService } from './poker-my-game-data.service';

export function providePokerMyGame(): Provider[] {
    return [runOnAppInit(PokerMyGameBootstrapService), MyGameDataService];
}
