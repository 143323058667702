import { Message } from '../../message';

export class LoyaltyInfo extends Message {
    private MESSAGE_NAME: string = 'LoyaltyInfo';
    private isPointsEnabled: boolean | null = false;
    private vipStatus: string | null = null;
    private partyPoints: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, isPointsEnabled: boolean | null = false, vipStatus: string | null = null, partyPoints: number = 0) {
        super(reqServerPeerId);
        this.isPointsEnabled = isPointsEnabled;
        this.vipStatus = vipStatus;
        this.partyPoints = partyPoints;
    }

    getIsPointsEnabled(): boolean | null {
        return this.isPointsEnabled;
    }

    setIsPointsEnabled(isPointsEnabled: boolean | null) {
        this.isPointsEnabled = isPointsEnabled;
    }
    getVipStatus(): string | null {
        return this.vipStatus;
    }

    setVipStatus(vipStatus: string | null) {
        this.vipStatus = vipStatus;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
}
