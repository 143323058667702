import { Message } from '../../message';

export class SNGJPPrizeInfo extends Message {
    private MESSAGE_NAME: string = 'SNGJPPrizeInfo';
    private rank: number = 0;
    private prizeAmount: number = 0;
    private prizeType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, rank: number = 0, prizeAmount: number = 0, prizeType: number = 0) {
        super(reqServerPeerId);
        this.rank = rank;
        this.prizeAmount = prizeAmount;
        this.prizeType = prizeType;
    }

    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getPrizeAmount(): number {
        return this.prizeAmount;
    }

    setPrizeAmount(prizeAmount: number) {
        this.prizeAmount = prizeAmount;
    }
    getPrizeType(): number {
        return this.prizeType;
    }

    setPrizeType(prizeType: number) {
        this.prizeType = prizeType;
    }
}
