import { Message } from '../../message';

export class MTCTUnRegister extends Message {
    private MESSAGE_NAME: string = 'MTCTUnRegister';
    private mtctId: number = 0;
    private fxSnapshotId: number = 0;
    private isRealMessage: boolean | null = false;
    private conversationId: number = 0;
    private targetSNGId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        fxSnapshotId: number = 0,
        isRealMessage: boolean | null = false,
        conversationId: number = 0,
        targetSNGId: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.fxSnapshotId = fxSnapshotId;
        this.isRealMessage = isRealMessage;
        this.conversationId = conversationId;
        this.targetSNGId = targetSNGId;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getFxSnapshotId(): number {
        return this.fxSnapshotId;
    }

    setFxSnapshotId(fxSnapshotId: number) {
        this.fxSnapshotId = fxSnapshotId;
    }
    getIsRealMessage(): boolean | null {
        return this.isRealMessage;
    }

    setIsRealMessage(isRealMessage: boolean | null) {
        this.isRealMessage = isRealMessage;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getTargetSNGId(): number {
        return this.targetSNGId;
    }

    setTargetSNGId(targetSNGId: number) {
        this.targetSNGId = targetSNGId;
    }
}
