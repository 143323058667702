// import { ApiBase, ApiServiceFactory } from '@frontend/vanilla';
// import { Injectable } from '@angular/core';
// @Injectable({
//     providedIn: 'root',
//     useFactory: apiServiceFactory,
//     deps: [ApiServiceFactory]
// })
// export class PokerApiService extends ApiBase {}
// export function apiServiceFactory(apiServiceFactory: ApiServiceFactory) {
//     return apiServiceFactory.create(PokerApiService, { product: 'poker', area: 'mobilepoker' });
// }
// import { ApiBase } from '@frontend/vanilla';
// export class PokerApiService extends ApiBase {
// }
import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory, ClientConfigProductName, ProductService } from '@frontend/vanilla/core';

import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
    useFactory: apiServiceFactory,
    deps: [ApiServiceFactory, ProductService],
})
export class PokerApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    return apiServiceFactory.create(PokerApiService, {
        product: ClientConfigProductName.POKER,
        area: 'mobilepoker',
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.POKER).isEnabled,
    });
}
