import { Message } from '../../message';

export class WinnerCards extends Message {
    private MESSAGE_NAME: string = 'WinnerCards';
    private winningCards: any[] | null = null;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, winningCards: any[] | null = null, seatNo: number = 0) {
        super(reqServerPeerId);
        this.winningCards = winningCards;
        this.seatNo = seatNo;
    }

    getWinningCards(): any[] | null {
        return this.winningCards;
    }

    setWinningCards(winningCards: any[] | null) {
        this.winningCards = winningCards;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
