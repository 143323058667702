import { Message } from '../../message';

export class WaitingPlayersForTable extends Message {
    private MESSAGE_NAME: string = 'WaitingPlayersForTable';
    private tableId: number = 0;
    private waitListCount: number = 0;
    private position: number = 0;
    private players: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, waitListCount: number = 0, position: number = 0, players: any[] | null = null) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.waitListCount = waitListCount;
        this.position = position;
        this.players = players;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getWaitListCount(): number {
        return this.waitListCount;
    }

    setWaitListCount(waitListCount: number) {
        this.waitListCount = waitListCount;
    }
    getPosition(): number {
        return this.position;
    }

    setPosition(position: number) {
        this.position = position;
    }
    getPlayers(): any[] | null {
        return this.players;
    }

    setPlayers(players: any[] | null) {
        this.players = players;
    }
}
