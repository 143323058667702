import { Message } from '../../message';

export class ShowHUPairTable extends Message {
    private MESSAGE_NAME: string = 'ShowHUPairTable';
    private opponetName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, opponetName: string | null = null) {
        super(reqServerPeerId);
        this.opponetName = opponetName;
    }

    getOpponetName(): string | null {
        return this.opponetName;
    }

    setOpponetName(opponetName: string | null) {
        this.opponetName = opponetName;
    }
}
