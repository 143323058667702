import { Message } from '../../message';

export class Blind extends Message {
    private MESSAGE_NAME: string = 'Blind';
    private blindType: number = 0;
    private amount: number = 0;
    private deadAmt: number = 0;
    private accepted: boolean | null = false;
    private seatNo: number = 0;
    private deadAnteAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        blindType: number = 0,
        amount: number = 0,
        deadAmt: number = 0,
        accepted: boolean | null = false,
        seatNo: number = 0,
        deadAnteAmount: number = 0,
    ) {
        super(reqServerPeerId);
        this.blindType = blindType;
        this.amount = amount;
        this.deadAmt = deadAmt;
        this.accepted = accepted;
        this.seatNo = seatNo;
        this.deadAnteAmount = deadAnteAmount;
    }

    getBlindType(): number {
        return this.blindType;
    }

    setBlindType(blindType: number) {
        this.blindType = blindType;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getDeadAmt(): number {
        return this.deadAmt;
    }

    setDeadAmt(deadAmt: number) {
        this.deadAmt = deadAmt;
    }
    getAccepted(): boolean | null {
        return this.accepted;
    }

    setAccepted(accepted: boolean | null) {
        this.accepted = accepted;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getDeadAnteAmount(): number {
        return this.deadAnteAmount;
    }

    setDeadAnteAmount(deadAnteAmount: number) {
        this.deadAnteAmount = deadAnteAmount;
    }
}
