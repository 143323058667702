import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';
import { HomepageResourceService } from '@pokercore/module/core';

@Component({
    selector: 'homepage-downloadsection',
    templateUrl: 'homepage-downloadsection.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class HomePageDownloadSectionComponent implements OnInit {
    downloadSectionContent: any;
    allData: any;

    constructor(
        private homePageService: HomepageResourceService,
        private tracking: TrackingService,
    ) {}
    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            if (allData[i].metadata.id.itemName.toLowerCase().includes('downloadsection')) {
                this.downloadSectionContent = allData[i];
                break;
            }
        }
    }

    public onDownloadClick(event: any, downloadContent: any) {
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Download',
            'component.LabelEvent': 'Poker Homepage_Download',
            'component.ActionEvent': 'DOWNLOAD Click',
            'component.PositionEvent': 'Download',
            'component.EventDetails': 'CTA Name|' + downloadContent.titleLink.text,
            'component.URLClicked': downloadContent.titleLink.url,
            'component.LocationEvent': 'Download',
        });

        window.open(
            this.downloadSectionContent.titleLink.url,
            '_self', //teaser.secondCta.target
        );
    }
}
