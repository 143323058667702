import { Message } from '../../message';

export class ChatOffMessage extends Message {
    private MESSAGE_NAME: string = 'ChatOffMessage';
    private chatIndicator: boolean | null = false;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, chatIndicator: boolean | null = false, seatNo: number = 0) {
        super(reqServerPeerId);
        this.chatIndicator = chatIndicator;
        this.seatNo = seatNo;
    }

    getChatIndicator(): boolean | null {
        return this.chatIndicator;
    }

    setChatIndicator(chatIndicator: boolean | null) {
        this.chatIndicator = chatIndicator;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
