import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class PlayerCashReduceAction extends TableEventInfo {
    extraAmount: any;
    boosterAmount: number;
    getEventType(): TableEvents {
        return TableEvents.PLAYER_CASH_REDUCE_ACTION;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        if (gameTable.isReducedChipsEnabled) {
            if (player.chips.value > gameTable.reduceChipsLimit) {
                this.extraAmount = player.chips.value - gameTable.reduceChipsLimit;
                player.chips.value = player.chips.value - this.extraAmount;
                gameTable.isCashReducedActionCalled = true;
                player.isReduceChipshappened = true;
                if (this.amount['unit'] > 0) this.boosterAmount = this.amount['unit'] - this.extraAmount;
            }
        }
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        if (gameTable.isReducedChipsEnabled) {
            gameTable.isCashReducedActionCalled = false;
            player.isReduceChipshappened = false;
            player.chips.value = player.chips.value + this.extraAmount;
        }
    }
}
