import { Message } from '../../message';

export class UserFreeRollList extends Message {
    private MESSAGE_NAME: string = 'UserFreeRollList';
    private userName: string | null = null;
    private freeRollIds: any[] | null = null;
    private domain: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, userName: string | null = null, freeRollIds: any[] | null = null, domain: number = 0) {
        super(reqServerPeerId);
        this.userName = userName;
        this.freeRollIds = freeRollIds;
        this.domain = domain;
    }

    getUserName(): string | null {
        return this.userName;
    }

    setUserName(userName: string | null) {
        this.userName = userName;
    }
    getFreeRollIds(): any[] | null {
        return this.freeRollIds;
    }

    setFreeRollIds(freeRollIds: any[] | null) {
        this.freeRollIds = freeRollIds;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
}
