import { Message } from '../../message';

export class RequestTournamentHH extends Message {
    private MESSAGE_NAME: string = 'RequestTournamentHH';
    private trnyId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, trnyId: number = 0) {
        super(reqServerPeerId);
        this.trnyId = trnyId;
    }

    getTrnyId(): number {
        return this.trnyId;
    }

    setTrnyId(trnyId: number) {
        this.trnyId = trnyId;
    }
}
