import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pff from './poker-flickr-feed.component';

@Injectable({ providedIn: 'root' })
export class PokerFlickrFeedPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('flickrfeedtemplate', pff.PokerFlickrFeedComponent);
        });
    }
}
