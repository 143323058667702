import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import * as r from './jackpot-ticker.resolvers';
import { JackpotTickerService } from './jackpot-ticker.service';

@Component({
    selector: 'pt-jackpot-ticker',
    templateUrl: 'jackpot-ticker.component.html',
    standalone: true,
    imports: [CommonModule],
    providers: [JackpotTickerService, r.JackpotTickerResolver],
})
export class JackpotTickerComponent implements OnInit {
    initData: Array<any>;
    currency: string = '$';
    jackpot: string = '';
    @Input()
    jackpotGroupName: string = 'TotalJPAmount';
    @Input()
    currencyPosition: string = 'left';
    @Input()
    elementClass: string;
    @Input()
    elementID: string;

    constructor(private jackpotTickerService: JackpotTickerService) {}

    ngOnInit() {
        // Guard against legacy jackpotGroupName
        if (this.jackpotGroupName === 'Total') {
            this.jackpotGroupName = 'TotalJPAmount';
        }

        // Retrieve the jackpot data
        this.jackpotTickerService.getAll().subscribe((response: any) => {
            // Update the initData from the response
            this.initData = response;

            // Retrieve the jackpot value
            const jackpot = this.getJackpot(this.initData);

            // If jackpot is valid, generate the ticker
            if (jackpot) {
                this.generateTicker(jackpot);
            }
        });
    }

    getJackpot(jackpotData: Array<any>): string | null {
        // Filter the jackpotData array based on jackpotGroupName
        const jackpot: Array<any> = jackpotData.filter((currentJackpot) => currentJackpot.jackpotGroupName === this.jackpotGroupName);

        // Return the amount field from the jackpot array when jackpot is not null
        if (jackpot) {
            return jackpot[0].amount;
        }
        // Return null in all other cases
        return null;
    }

    generateTicker(jackpotAmountString: String): void {
        // Update currency based on symbols in jackpotAmountString
        this.currency = jackpotAmountString.includes('£') ? '£' : jackpotAmountString.includes('€') ? '€' : this.currency;
        // Remove symbols from jackpotAmountString and parse as float
        const jackpotAmount = parseFloat(jackpotAmountString.replace(/[,$£€]/g, ''));
        const startingAmount = jackpotAmount - 250;
        const ticker = (previousNumber: number) => {
            const nextNumber = previousNumber + 0.29;
            // Increment the ticker while nextNumber is less than jackpotAmount
            if (nextNumber < jackpotAmount) {
                // Update the ticker view
                this.updateTicker(this.formatJackpot(nextNumber.toFixed(2)));
                // Run the ticker with the nextNumber at a randomised interval to simulate realism
                const randomisedTimeout = Math.random() * 2500 + 500;
                setTimeout(() => ticker(nextNumber), randomisedTimeout);
            }
            // Once nextNumber exceeds jackpotAmount, restart the ticker using startingAmount
            else {
                ticker(startingAmount);
            }
        };
        ticker(startingAmount);
    }

    updateTicker(jackpot: string, doDecimalFormatting: boolean = false): void {
        let formattedJackpot = jackpot;

        // Add some html formatting to the jackpot value
        if (formattedJackpot.includes('.') && doDecimalFormatting) {
            const _splitJackpot = formattedJackpot.split('.');
            formattedJackpot = `${_splitJackpot[0]}.<sub>${_splitJackpot[1]}</sub>`;
        }
        // Add the currency symbol in the correct position
        this.jackpot = this.currencyPosition === 'left' ? `${this.currency}${formattedJackpot}` : `${formattedJackpot}${this.currency}`;
    }

    formatJackpot(jackpot: string): string {
        let formattedJackpot = jackpot;

        // Start processing the string if it contains a period (.)

        if (formattedJackpot.includes('.')) {
            // Split the string into an array
            const decimalSplitArray = formattedJackpot.split('.');

            // Extract the wholeNumber
            let wholeNumber: string = decimalSplitArray[0];

            // Add commas if the wholeNumber consists of more than 3 characters
            if (wholeNumber.length > 3) {
                // Split the wholeNumber into an array and reverse it
                //const reversedWholeNumberArray = wholeNumber.split('').reverse();
                // Loop over the array and add commas after every third character
                // Once done, create a string once more by running reverse and join
                /*wholeNumber = reversedWholeNumberArray.map((currentChar, index) => {
                    if ((index % 3 === 2 && index !== 0) && typeof reversedWholeNumberArray[index + 1] !== 'undefined') {
                        return `${currentChar},`;
                    } else {
                        return currentChar;
                    }
                }).reverse().join('');*/
                wholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
            // Add the decimals to the whole number once again
            formattedJackpot = `${wholeNumber}.${decimalSplitArray[1]}`;
        }
        // Return the formattedJackpot string
        return formattedJackpot;
    }
}
