import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class PlayerTimedOut extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.PLAYER_TIMED_OUT;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    next(gameTable: GameTable): void {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prev(gameTable: GameTable): void {}
}
