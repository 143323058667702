<div class="poker-teasers-mobile" id="pokerTeasersCarouselWrap">
    <div #pokerTeasersCarouselWrap>
        <div class="poker-teasers-wrap" id="pokerTeasersCarousel">
            <vn-swiper #swiperComponent [swiperOptions]="config" *ngIf="promotionBanners?.length > 0 && promotionBannersCheck">
                <div class="swiper-slide" *ngFor="let promo of promotionBanners | dsl | async">
                    <div class="swiper-bg-container" [ngClass]="{ 'align-right': promo?.alignTextToTheRight }" (click)="linkToAction(promo)">
                        <div class="pk-banner-web-plp-image" [ngStyle]="{ 'background-image': 'url(' + promo?.backgroundImage?.src + ')' }"></div>
                        <div class="swiper-content-wrap">
                            <div class="pk-banner-slide-content">
                                <h2 class="pk-banner-title text-truncate" vnDynamicHtml="{{ promo?.title }}"></h2>
                                <div
                                    class="pk-banner-description"
                                    vnDynamicHtml="{{
                                        promo?.description?.length > 50 ? (promo?.description | slice: 0 : 50) + '..' : promo?.description
                                    }}"></div>
                                <p class="pk-banner-terms" vnDynamicHtml="{{ promo?.termsAndConditions }}"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </vn-swiper>
        </div>
    </div>
</div>
