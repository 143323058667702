import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';

export class SelfEmojiViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.peerId = this.gameService.getPeerId();
        // move below event and code to cardsview
        //this.EventDispatcher.addEventListener(SlotCoreGameEvent.BROADCAST_EMOJI,this.broadcastEmoji.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_BET_OPTIONS, this.hideEmoji.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.EMOJI_BUTTON_CLICKED, this.showemoji.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_ACTION_FOR_CASHOUT, this.hideEmojiContainer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_ACTION_FOR_CASHOUT, this.showEmojiContainer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_THROW_ANIM, this.updateThrowAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_ACTION_FOR_CASHOUT, this.hideSelectEmojiPopUp.bind(this));
    };
    hideSelectEmojiPopUp = function () {
        this.view.hideSelectEmojiPopUp();
    };
    updateThrowAnim = function (throwAnimationObj) {
        this.view.updateThrowAnim(throwAnimationObj);
    };
    hideEmojiContainer = function () {
        this.view.hideEmojiContainer();
    };
    showEmojiContainer = function () {
        this.view.showEmojiContainer();
    };
    showemoji = function () {
        const currenctGameId = this.gameService.getCurrentGameId();
        this.view.showemoji(currenctGameId);
    };
    showEmojiReq = function () {
        if (!this.peerId) {
            this.peerId = this.gameService.getPeerId();
        }
    };
    hideEmoji = function () {
        this.view.hideEmoji();
    };
}
