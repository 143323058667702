import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

import { BlogComponent } from './blog/blog.component';
import { TwitterComponent } from './twitter/twitter.component';

@Component({
    selector: 'homepage-socialfeedsection',
    templateUrl: 'homepage-socialfeedsection.component.html',
    standalone: true,
    imports: [CommonModule, BlogComponent, TwitterComponent],
})
export class HomePageSocialFeedSectionComponent implements OnInit {
    blogContentContentTitle: any;
    twitterContentContentTitle: any;
    allData: any;

    constructor(private homePageService: HomepageResourceService) {}
    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            switch (allData[i].metadata.id.itemName.toLowerCase()) {
                case 'blogcontent': {
                    this.blogContentContentTitle = allData[i].title;
                    break;
                }
                case 'twittercontent': {
                    this.twitterContentContentTitle = allData[i].title;
                    break;
                }
            }
        }
    }
}
