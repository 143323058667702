import { Message } from '../../message';

export class NotifyPlayerProfileWithSSOKey extends Message {
    private MESSAGE_NAME: string = 'NotifyPlayerProfileWithSSOKey';
    private sessionKey: string | null = null;
    private encryptedProfile: any[] | null = null;
    private encodedSSOKey: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sessionKey: string | null = null,
        encryptedProfile: any[] | null = null,
        encodedSSOKey: string | null = null,
    ) {
        super(reqServerPeerId);
        this.sessionKey = sessionKey;
        this.encryptedProfile = encryptedProfile;
        this.encodedSSOKey = encodedSSOKey;
    }

    getSessionKey(): string | null {
        return this.sessionKey;
    }

    setSessionKey(sessionKey: string | null) {
        this.sessionKey = sessionKey;
    }
    getEncryptedProfile(): any[] | null {
        return this.encryptedProfile;
    }

    setEncryptedProfile(encryptedProfile: any[] | null) {
        this.encryptedProfile = encryptedProfile;
    }
    getEncodedSSOKey(): string | null {
        return this.encodedSSOKey;
    }

    setEncodedSSOKey(encodedSSOKey: string | null) {
        this.encodedSSOKey = encodedSSOKey;
    }
}
