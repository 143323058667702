import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CSD } from '../pgConnectors/sal/CSD';
import { EventDispatcherGlobal } from '../service/event-despacher-global';

@Component({
    selector: 'pwc-loader',
    templateUrl: 'loader.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class LoaderComponent implements OnInit {
    txtToBeDisplayed: string;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<LoaderComponent>,
    ) {
        this.txtToBeDisplayed = data == CSD.CONNECTION_BAD ? 'Reconnecting' : 'No Connection';
    }
    ngOnInit() {
        this.eventDispatcherGlobal.addEventListener('updateConnectionStatus', this.onClose.bind(this));
    }
    onClose() {
        this.dialogRef.close();
    }
}
