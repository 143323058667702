import { Message } from '../../message';
import { WaitlistCriteriaType } from './WaitlistCriteriaType';

export class JoinWaitlist extends Message {
    private MESSAGE_NAME: string = 'JoinWaitlist';
    private tableid: number = 0;
    private criteria: WaitlistCriteriaType | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableid: number = 0, criteria: WaitlistCriteriaType | null = null) {
        super(reqServerPeerId);
        this.tableid = tableid;
        this.criteria = criteria;
    }

    getTableid(): number {
        return this.tableid;
    }

    setTableid(tableid: number) {
        this.tableid = tableid;
    }
    getCriteria(): WaitlistCriteriaType | null {
        return this.criteria;
    }

    setCriteria(criteria: WaitlistCriteriaType | null) {
        this.criteria = criteria;
    }
}
