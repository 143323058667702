import { Message } from '../../message';

export class RequestSNGQuickRegister extends Message {
    private MESSAGE_NAME: string = 'RequestSNGQuickRegister';
    private requestId: number = 0;
    private cardId: number = 0;
    private selectedBuyInType: number = 0;
    private buyInValue: number = 0;
    private sngTemplateId: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        cardId: number = 0,
        selectedBuyInType: number = 0,
        buyInValue: number = 0,
        sngTemplateId: number = 0,
        conversationId: number = 0,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.cardId = cardId;
        this.selectedBuyInType = selectedBuyInType;
        this.buyInValue = buyInValue;
        this.sngTemplateId = sngTemplateId;
        this.conversationId = conversationId;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getSelectedBuyInType(): number {
        return this.selectedBuyInType;
    }

    setSelectedBuyInType(selectedBuyInType: number) {
        this.selectedBuyInType = selectedBuyInType;
    }
    getBuyInValue(): number {
        return this.buyInValue;
    }

    setBuyInValue(buyInValue: number) {
        this.buyInValue = buyInValue;
    }
    getSngTemplateId(): number {
        return this.sngTemplateId;
    }

    setSngTemplateId(sngTemplateId: number) {
        this.sngTemplateId = sngTemplateId;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
