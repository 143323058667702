import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MenuActionComponent, PlainLinkComponent } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { GlobalClientConfig } from '@pokercore/module/core';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    selector: 'pk-teaser-carousel',
    templateUrl: 'pk-teaser-carousel.component.html',
    styleUrls: ['pk-teaser-carousel.component.css'],
    standalone: true,
    imports: [CommonModule, TrustAsHtmlPipe, PlainLinkComponent, MenuActionComponent, SwiperComponent],
})
export class PokerTeaserCarouselComponent implements OnInit, OnDestroy {
    public swiperOptions: SwiperOptions = {
        modules: [Navigation, Pagination, EffectCoverflow, Autoplay],
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            clickable: true,
            el: '.swiper-pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        loop: true,
    };

    EnableJackpotTickerTeasers: any;
    breakpointWidths: any = [320, 414, 480, 667, 768, 1024, 1436];
    standardWidths: any = [320, 480, 640, 800, 1024, 1280];
    teaserWidth: any;
    teaserHeight: any;
    elementWidth: any;
    elementHeight: any;
    jackpot_image: any;
    index: any = 0;
    eventId: any = null;
    slideEventId: any = null;
    eventClickId: any;
    isNewSportsNative: any;
    isLandNative: any;
    jackpotHandle: any;
    jpTitle: any = 24;
    jpContainer: any = 37;
    jpCta: any = 48;
    teasers: any = [];

    extract: any;
    embedded: any;
    moreMessage: any;
    moreInfoMessage: any;
    isScreenshotMode: any;
    slideInterval: any;
    useMD5: any;
    autoslide: any;
    isLandscape: any;
    teaserLandscape: any;
    isiOS: any;
    useMD3: any;
    isTeaserMode: any;
    showPromoInfo: any;
    margin: any;
    promoMessage: any;
    downloadContent: any;
    isButtonVisible: boolean;
    isTakeOverteaser: boolean;
    isTouch: boolean;
    TeaserMode: any;
    newTeasers: any;
    teasersMainClass: string = 'teasers';
    showDots: boolean | undefined;

    @Input() autoplay: any;
    @Input() delay: any;
    @Input() navigation: any;
    @Input() dots: any;
    @Input() loop: any;
    @Input() pauseOnHover: any;
    @Input() teasersResponse: any;
    @Input() class: any;
    @Output() teasersClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCtaClick: EventEmitter<any> = new EventEmitter<any>();

    initData: any;

    constructor(
        private global: GlobalClientConfig,
        private routeData: RouteDataService,
    ) {}
    ngOnInit() {
        // Get all teasers
        this.initData = this.routeData.getInitData();

        this.global.whenReady.subscribe(() => {
            if (this.global && this.global.config && this.global.config.sharedList) {
                this.TeaserMode = this.global.config.sharedList.TeaserMode;
            }
        });
        this.extract = false;
        this.extract = true;

        this.showPromoInfo = true;

        this.isLandscape = this.isInLandscape();
        this.teaserLandscape = this.elementWidth >= 480;
        this.isiOS = /(iPad|iPhone|iPod)/g.test(window.navigator.userAgent);
    }

    assignBrowserSpecificClass() {
        if (navigator.userAgent.indexOf('Edge') != -1) {
            return 'edge';
        } else {
            return null;
        }
    }

    ngOnDestroy() {
        if (this.isLandscape) {
            document.documentElement.classList.remove('landscape');
        }
    }
    public closest(array: any, num: any) {
        return array.reduce((a: any, b: any) => {
            return Math.abs(a - num) < Math.abs(b - num) ? a : b;
        });
    }
    public isInLandscape() {
        if (this.useMD5) {
            return !this.isPortraitMD5();
        } else {
            return false;
        }
    }
    public isPortraitMD5() {
        return true;
    }

    public getHeight() {
        let index;
        if (this.teasers.length > 0) {
            if (index === 0 || isNaN(index)) {
                index = this.teasers[0].image ? this.teasers[0].image.width / this.teasers[0].image.height : 0;
            }
        }
        // md5 type teasers don't use rotation
        if (this.useMD5) {
            const margin = 0;
            this.elementWidth = this.elementWidth - margin * 2;
            const ctaButtonHeight = 48;
            let termsHeight = 45;
            const titleHeight = 30;
            if (480 <= this.elementWidth && this.elementWidth < 667) {
                termsHeight = titleHeight;
            }
            const teaserCtaAndTermsHeight = ctaButtonHeight + termsHeight + titleHeight;
            if (this.isPortraitMD5()) {
                this.teaserHeight = (this.elementWidth / this.index.toFixed(2) + teaserCtaAndTermsHeight - 1).toFixed(0);
            } else {
                this.teaserHeight = (this.elementWidth / 2 / this.index.toFixed(2) + termsHeight - 1).toFixed(0);
            }

            return this.teaserHeight;
        }
        return this.teaserHeight;
    }
    public jackpotImageStyle(teaser: any) {
        let jackpot_image, padding;
        padding = parseInt((this.teaserHeight * 0.01).toFixed());
        if (!this.teaserLandscape) {
            if (teaser.ctaLink) {
                //if cta button is present
                jackpot_image = (this.teaserHeight - (this.jpCta + this.jpContainer + this.jpTitle + padding * 3)).toFixed(); //35%
            } else {
                jackpot_image = (this.teaserHeight - (this.jpContainer + this.jpTitle + padding * 3)).toFixed(); //35%
            }
            if (this.EnableJackpotTickerTeasers)
                return {
                    height: '145px',
                    width: 'auto',
                    margin: '0px 113px',
                };
            else
                return {
                    'height': this.jackpot_image + 'px',
                    'width': this.jackpot_image + 'px',
                    'margin-left': window.screen.width / 2 - this.jackpot_image / 2 + 'px',
                    'margin-top': padding + 'px',
                    'position': 'absolute',
                };
        } else {
            jackpot_image = parseInt((this.teaserHeight * 0.58).toFixed()); //35%

            return {
                'height': jackpot_image + 'px',
                'width': jackpot_image + 'px',
                'margin-left': window.screen.width / 5 - jackpot_image / 2 + 'px',
                'margin-top': padding + 'px',
                'position': 'absolute',
            };
        }
    }
    public jackpotContainerStyle(teaser: any) {
        let padding, jackpotTop;
        if (!this.teaserLandscape) {
            padding = parseInt((this.teaserHeight * 0.01).toFixed());
            if (teaser.ctaLink) {
                //if cta button is present
                jackpotTop = (this.teaserHeight - (this.jpCta + this.jpContainer + this.jpTitle + padding * 3)).toFixed(); //35%
            } else {
                jackpotTop = (this.teaserHeight - (this.jpContainer + this.jpTitle + padding * 2)).toFixed(); //35%
            }
            return {
                'margin-top': jackpotTop + 'px',
                'margin-bottom': padding + 'px',
            };
        } else {
            const jackpotlength = teaser.jackpot.length;
            padding = parseInt((this.teaserHeight * 0.02).toFixed());
            jackpotTop = parseInt((this.teaserHeight * 0.58).toFixed()) + padding * 2;
            const margin_left = (window.screen.width / 5 - (jackpotlength * 12) / 4).toFixed();
            return {
                'padding-top': jackpotTop + 'px',
                'padding-bottom': padding + 'px',
                'left': margin_left + 'px',
                'position': 'absolute',
                'text-align': 'center',
            };
        }
    }

    public jackpotTitle(teaser: any) {
        return {
            'color': teaser.attributes.titleColor,
            'font-size': teaser.attributes.titleFontSize + 'px',
            'font-weight': teaser.attributes.titleFontWeight,
        };
    }
    public jackpotCTA(teaser: any) {
        return {
            'color': teaser.attributes.ctaColor,
            'background-color': teaser.attributes.ctaBackgroundColor,
            'font-size': teaser.attributes.btnTextFontSize,
        };
    }
    public showJPBackgroundImage(teaser: any) {
        if (teaser.showBackgroundImage && this.elementWidth >= 400) {
            return true;
        } else {
            return false;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public extendData(teasers: any) {}

    public teaserClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        this.teasersClick.emit({ teaser, bannerPos });
        event.stopPropagation();
    }

    public ctaClick(event: any, teaser: any, bannerPos: any) {
        event.stopPropagation();
        this.onCtaClick.emit({ teaser, bannerPos });
    }
}
