import { AfterViewChecked, Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';

import { HandHistoryService } from '../../../../../hand-history.service';
import { GameTable } from '../../models/game-table';
import { GameService } from '../../services/game.service';
import { GameTableService } from '../game-table/game-table.service';

@Component({
    selector: 'pkr-table-text-view',
    templateUrl: './table-text-view.component.html',
})
export class TableTextViewComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() gameTable: GameTable;
    @Input() player: any;
    status: boolean = false;
    gameType = {
        TexasHoldem: 1,
        ShortDeckTexasHoldem: 2,
        OmahaHigh: 3,
        OmahaHighLow: 4,
        SevenCardStud: 5,
        SevenCardStudHighLow: 6,
        SevenCardStudHigh: 5,
        DoubleHoldem: 7,
    };
    playerHoleCards: any = [];
    communityCards: any = [];
    pot: any = 0;
    handHistory: any;

    constructor(
        private handHistoryService: HandHistoryService,
        private gameService: GameService,
        private gameTableService: GameTableService,
    ) {}
    serve: any;
    hands: any;
    activeID: any;
    // toggleEvent() {
    //   this.status = !this.status;
    // }
    ngOnInit() {
        this.handHistory = this.handHistoryService;
        this.serve = this.handHistoryService.content.messages;
        this.activeID = Number(this.handHistoryService.activeHandID);
        this.getGameRounds();
    }
    ngOnChanges() {
        this.activeID = Number(this.handHistoryService.activeHandID);
        this.handHistory = this.handHistoryService;
    }

    ngAfterViewChecked() {
        if (!this.handHistoryService.singleHand) {
            if (!this.gameTableService.viewUpdatedforDashBoard) {
                this.scrollTilltheSelectedHand();
            }
        }
    }

    getGameRounds() {
        let gameRounds = [];
        if (
            this.handHistoryService.content.form.gametypes.values[this.handHistoryService.gameTypeCG].value === 'ShortDeck' &&
            !this.handHistoryService.singleHand
        ) {
            this.handHistoryService.content.form.gametypes.values[this.handHistoryService.gameTypeCG].value = 'ShortDeckTexasHoldem';
        } else if (
            (!this.handHistoryService.singleHand &&
                this.handHistoryService.content.form.gametypes.values[this.handHistoryService.gameTypeCG].value === 'All' &&
                this.handHistoryService.hhGames === 'cashgames') ||
            (this.handHistoryService.content.form.gametypes.values[this.handHistoryService.gameTypeTR].value === 'All' &&
                this.handHistoryService.hhGames === 'tournaments')
        ) {
            this.gameTable.actualGameName = 'All';
        }
        if (
            !this.handHistoryService.singleHand &&
            this.gameTable.isTourny &&
            this.gameTable.actualGameName ===
                this.handHistoryService.content.form.tournamentgametypes.values[this.handHistoryService.gameTypeTR].value.trim() &&
            this.handHistoryService.tournaments
        ) {
            gameRounds = this.handHistoryService.handstoReplayerDashBoard;
        } else if (
            !this.handHistoryService.singleHand &&
            !this.gameTable.isTourny &&
            this.gameTable.actualGameName ===
                this.handHistoryService.content.form.gametypes.values[this.handHistoryService.gameTypeCG].value.trim() &&
            this.handHistoryService.cashGames
        ) {
            gameRounds = this.handHistoryService.handstoReplayerDashBoard;
        }
        this.data(gameRounds);
    }

    data(handsData: any): any {
        if (handsData.length === 0 && this.handHistoryService.singleHand) {
            this.dashBoardHandswithSingleHandSearch();
        } else {
            const finalRes: any = handsData;
            this.hands = finalRes;
        }
    }

    replay(roundID: any) {
        this.handHistoryService.activeHandID = roundID;
        this.handHistoryService.getReplayer(roundID).subscribe((res: any) => {
            this.gameService.response = res.gameRoundJson;
            this.gameService.isReplayerOnInit(true);
            this.gameTable = this.gameService.gameTable;
            this.gameTableService.initGameTable(this.gameTable);
            this.gameTableService.viewUpdatedforDashBoard = true;
        });
    }

    dashBoardHandswithSingleHandSearch() {
        if (this.handHistoryService.singleHand) {
            this.handHistoryService.singleGameRound.pots.forEach((pot: any) => {
                this.pot = this.pot + pot.amount.unit;
            });
            if (this.handHistoryService.singleGameRound.unCalledFlag === 0) {
                this.pot = this.pot + this.handHistoryService.singleGameRound.unBetPotSize;
            }

            this.handHistoryService.singleGameRound.roundPlayers.forEach((player: any) => {
                if (player.requestingPlayer) {
                    this.playerHoleCards = player.playerHoleCards;
                }
            });

            this.handHistoryService.singleGameRound.roundEvents.forEach((event: any) => {
                if (event.handType === 'COMMUNITY_CARD' && (event.boardTypeId === 2 || event.boardTypeId === 0)) {
                    this.communityCards.push(event.card);
                }
            });
            this.gameTableService.disableNextHandButton = true;
            this.gameTableService.disablePrevHandButton = true;
        }
    }

    scrollTilltheSelectedHand() {
        const ele: any = document.getElementsByClassName('activeHand')[0];
        if (ele) {
            ele.scrollIntoView(false);
            this.gameTableService.viewUpdatedforDashBoard = true;
        }
    }

    @HostListener('mousewheel', ['$event'])
    scroll(event: any) {
        if (event.deltaY < 0) {
            document.getElementsByClassName('table-content')[0].scrollBy(0, -35);
        } else if (event.deltaY > 0) {
            document.getElementsByClassName('table-content')[0].scrollBy(0, 35);
        }
        event.preventDefault();
    }
}
