import { Message } from '../../message';

export class ThrowAnimation extends Message {
    private MESSAGE_NAME: string = 'ThrowAnimation';
    private animationId: number = 0;
    private fromPlayerSeatNo: number = 0;
    private toPlayerSeatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, animationId: number = 0, fromPlayerSeatNo: number = 0, toPlayerSeatNo: number = 0) {
        super(reqServerPeerId);
        this.animationId = animationId;
        this.fromPlayerSeatNo = fromPlayerSeatNo;
        this.toPlayerSeatNo = toPlayerSeatNo;
    }

    getAnimationId(): number {
        return this.animationId;
    }

    setAnimationId(animationId: number) {
        this.animationId = animationId;
    }
    getFromPlayerSeatNo(): number {
        return this.fromPlayerSeatNo;
    }

    setFromPlayerSeatNo(fromPlayerSeatNo: number) {
        this.fromPlayerSeatNo = fromPlayerSeatNo;
    }
    getToPlayerSeatNo(): number {
        return this.toPlayerSeatNo;
    }

    setToPlayerSeatNo(toPlayerSeatNo: number) {
        this.toPlayerSeatNo = toPlayerSeatNo;
    }
}
