import { Message } from '../../message';

export class LSMTCTEventsQualifiersChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSMTCTEventsQualifiersChangeEvent';
    private mtctId: number = 0;
    private mtctEvents: any[] | null = null;
    private qualifiers: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, mtctEvents: any[] | null = null, qualifiers: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.mtctEvents = mtctEvents;
        this.qualifiers = qualifiers;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getMtctEvents(): any[] | null {
        return this.mtctEvents;
    }

    setMtctEvents(mtctEvents: any[] | null) {
        this.mtctEvents = mtctEvents;
    }
    getQualifiers(): any[] | null {
        return this.qualifiers;
    }

    setQualifiers(qualifiers: any[] | null) {
        this.qualifiers = qualifiers;
    }
}
