import { Message } from '../../message';
import { LSPoolPlayerCount } from './LSPoolPlayerCount';

export class LSPoolPlayerCountDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSPoolPlayerCountDeltaUpdate';
    private lsPoolPlayerCount: LSPoolPlayerCount | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lsPoolPlayerCount: LSPoolPlayerCount | null = null) {
        super(reqServerPeerId);
        this.lsPoolPlayerCount = lsPoolPlayerCount;
    }

    getLsPoolPlayerCount(): LSPoolPlayerCount | null {
        return this.lsPoolPlayerCount;
    }

    setLsPoolPlayerCount(lsPoolPlayerCount: LSPoolPlayerCount | null) {
        this.lsPoolPlayerCount = lsPoolPlayerCount;
    }
}
