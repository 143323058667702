export class ThrowAnimationModel {
    setThrowAnimationData = function (obj) {
        //we might get more than 1 throw animation requests at an instance, process them in FIFO
        if (this.throwAnimationData === undefined) {
            this.throwAnimationData = [];
        }
        this.throwAnimationData.push(obj);
    };
    setReqThrowAnimationData = function (obj) {
        this.throwAnimationRequestData = obj;
    };
    getReqThrowAnimationData = function () {
        return this.throwAnimationRequestData;
    };
    resetReqThrowAnimationData = function () {
        this.throwAnimationRequestData = null;
    };
    getThrowAnimationData = function () {
        const rt = undefined;
        if (this.throwAnimationData === undefined) return rt;
        if (this.throwAnimationData.length <= 0) return rt;
        return this.throwAnimationData.pop();
    };
}
