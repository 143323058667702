import { Message } from '../../message';
import { LSDynamicLanguagePackUpdate } from './LSDynamicLanguagePackUpdate';

export class ResponseSNGJPLobbyData extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGJPLobbyData';
    private sngJPInstanceDetails: any[] | null = null;
    private dynaDetails: LSDynamicLanguagePackUpdate | null = null;
    private tourneyRequestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJPInstanceDetails: any[] | null = null,
        dynaDetails: LSDynamicLanguagePackUpdate | null = null,
        tourneyRequestType: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJPInstanceDetails = sngJPInstanceDetails;
        this.dynaDetails = dynaDetails;
        this.tourneyRequestType = tourneyRequestType;
    }

    getSngJPInstanceDetails(): any[] | null {
        return this.sngJPInstanceDetails;
    }

    setSngJPInstanceDetails(sngJPInstanceDetails: any[] | null) {
        this.sngJPInstanceDetails = sngJPInstanceDetails;
    }
    getDynaDetails(): LSDynamicLanguagePackUpdate | null {
        return this.dynaDetails;
    }

    setDynaDetails(dynaDetails: LSDynamicLanguagePackUpdate | null) {
        this.dynaDetails = dynaDetails;
    }
    getTourneyRequestType(): number {
        return this.tourneyRequestType;
    }

    setTourneyRequestType(tourneyRequestType: number) {
        this.tourneyRequestType = tourneyRequestType;
    }
}
