import { Message } from '../../message';

export class LobbyFont extends Message {
    private MESSAGE_NAME: string = 'LobbyFont';
    private red: number = 0;
    private blue: number = 0;
    private green: number = 0;
    private name: string | null = null;
    private size: number = 0;
    private bold: boolean | null = false;
    private italic: boolean | null = false;
    private underline: boolean | null = false;
    private colorID: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        red: number = 0,
        blue: number = 0,
        green: number = 0,
        name: string | null = null,
        size: number = 0,
        bold: boolean | null = false,
        italic: boolean | null = false,
        underline: boolean | null = false,
        colorID: number = 0,
    ) {
        super(reqServerPeerId);
        this.red = red;
        this.blue = blue;
        this.green = green;
        this.name = name;
        this.size = size;
        this.bold = bold;
        this.italic = italic;
        this.underline = underline;
        this.colorID = colorID;
    }

    getRed(): number {
        return this.red;
    }

    setRed(red: number) {
        this.red = red;
    }
    getBlue(): number {
        return this.blue;
    }

    setBlue(blue: number) {
        this.blue = blue;
    }
    getGreen(): number {
        return this.green;
    }

    setGreen(green: number) {
        this.green = green;
    }
    getName(): string | null {
        return this.name;
    }

    setName(name: string | null) {
        this.name = name;
    }
    getSize(): number {
        return this.size;
    }

    setSize(size: number) {
        this.size = size;
    }
    getBold(): boolean | null {
        return this.bold;
    }

    setBold(bold: boolean | null) {
        this.bold = bold;
    }
    getItalic(): boolean | null {
        return this.italic;
    }

    setItalic(italic: boolean | null) {
        this.italic = italic;
    }
    getUnderline(): boolean | null {
        return this.underline;
    }

    setUnderline(underline: boolean | null) {
        this.underline = underline;
    }
    getColorID(): number {
        return this.colorID;
    }

    setColorID(colorID: number) {
        this.colorID = colorID;
    }
}
