import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DeviceService, Page, TrackingService } from '@frontend/vanilla/core';

import { SimulatedService } from '../simulated-hands.service';

@Component({
    selector: 'pk-get-started-check',
    templateUrl: './get-started-check.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule],
})
export class GetStartedCheckComponent implements OnInit {
    @Input() parameters: any;
    @Input() commonParams: any;
    @Output() passCheckEvent = new EventEmitter<{ getCheckEvent: boolean }>();
    public nativeApp: boolean = false;
    showWiz: boolean = false;

    homePage: any;
    constructor(
        private vnPage: Page,
        private trackingService: TrackingService,
        private deviceService: DeviceService,
        public serve: SimulatedService,
    ) {}

    ngOnInit(): void {
        this.homePage = this.vnPage.homePage;
        this.nativeApp = this.serve.nativeApp;
        // tracking
        this.trackingOnLoad();

        setTimeout(() => {
            this.showWiz = true;
        }, 800);
    }

    checkEvent(eventValue: any) {
        this.showWiz = false;
        setTimeout(() => {
            this.passCheckEvent.emit({ getCheckEvent: eventValue });
        }, 800);
        // tracking
        this.trackingCheckEvent(eventValue);

        if (!eventValue) {
            this.serve.warnToTipComponent();
        }
    }

    // ------ GTM tracking start-------
    trackingOnLoad() {
        this.trackingService.triggerEvent('contentView', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'load',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'Loading simulated hands screen',
            'component.EventDetails': 'sim hands tutorials',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingCheckEvent(eventvalue: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //Note:If hands are not available then give value as not applicable',
            'component.LocationEvent': 'get started check screen',
            'component.EventDetails': eventvalue ? 'yes cta' : 'no cta', //'{cta name} cta ex: yes cta, try again cta etc',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingDownloadEvent(urlLink: any, downloadText: string) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //Note:If hands are not available then give value as not applicable',
            'component.LocationEvent': 'get started check screen',
            'component.EventDetails': downloadText + ' link', //'{cta name} cta ex: yes cta, try again cta etc',
            'component.URLClicked': urlLink,
        });
    }

    trackingCloseButton() {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'get started check screen',
            'component.PositionEvent': 'not applicable',
            'component.EventDetails': 'close icon',
            'component.URLClicked': 'not applicable',
        });
    }
    // ------GTM tracking end-------

    ngAfterViewChecked() {
        const width = window.innerWidth;
        if (this.deviceService.isMobile) {
            const ele: any = document.getElementById('getStarted');
            // let elenew: any = document.getElementById('Player-id');
            let zoomValue = width / 400;
            if (zoomValue > 1.4) {
                zoomValue = 1.4;
            }
            if (ele) {
                ele.style.zoom = zoomValue;
                //elenew.style.zoom = zoomValue;
            }
        }
    }
}
