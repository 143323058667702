// import { Component, OnInit } from '@angular/core';
// import { Page } from '@vanilla/core';
// import { RouteDataService } from '@labelhost/core';
// @Component({
//     selector: 'pk-poker-download',
//     templateUrl: 'poker-download.component.html'
// })
// export class PokerDownloadComponent implements OnInit {
//     initData:any;
//     directDownloadLink:string;
//     directAppDownloadLink:string;
//     downloadContent:any;
//     constructor(private routeData:RouteDataService,
//                 private page:Page) { }
//     ngOnInit(): void {
//         this.initData = this.routeData.getInitData();
//         this.directDownloadLink = this.page.lang+'/downloadclient';
//         this.directAppDownloadLink = this.page.lang+'/downloadapp';
//         this.downloadContent = this.initData.downloadContent;
//         window.location.href = this.initData.downloadUrl;
//     }
// }
//===================================================================
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { ClientConfigProductName, ContentService, LoadingIndicatorService, MetaTagsService, Page, PageMatrixService } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import {
    DownloadClientConfig,
    NavigationBarComponent,
    NavigationBarPokerComponent,
    PokerHomePageClientConfig,
    ScrollToTopComponent,
} from '@pokercore/module/core';
import { Subject, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { PokerDownloadLayoutComponent } from './poker-download-layout/poker-download-layout.component';
import { PokerDownloadResolver } from './poker-download.resolver';
import { PokerThreeTileContainerComponent } from './poker-threeTile-container/poker-threeTile-container.component';
import { PokerTeaserCarouselComponent } from './teaser-carousel/pk-teaser-carousel.component';

/**
 * @whatItDoes Loads public page content based on url path.
 *
 * @howToUse
 *
 * ```
 * export const routes: Routes = [
 * // ...
 * {
 *     path: '{culture}',
 *     children: [
 *         // ...
 *         {
 *             path: 'p',
 *             children: [
 *                 {
 *                     path: '**',
 *                     component: PublicPageLoaderComponent,
 *                     data: {
 *                         publicPageRoot: 'Playground-v1.0/PublicPages/'
 *                     }
 *                 }
 *             ]
 *         },
 *         // ...
 *     ]
 * }
 * // ...
 * ]
 * ```
 *
 * @stable
 */
@Component({
    selector: 'pk-poker-download',
    templateUrl: 'poker-download.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        PageMatrixComponent,
        NavigationBarComponent,
        NavigationBarPokerComponent,
        PokerDownloadLayoutComponent,
        PokerTeaserCarouselComponent,
        PokerThreeTileContainerComponent,
        ScrollToTopComponent,
        DslPipe,
    ],
    providers: [PokerDownloadResolver],
})
export class PokerDownloadComponent implements OnInit, OnDestroy {
    content: any;
    initData: any;
    directDownloadLink: string;
    directAppDownloadLink: string;
    downloadContent: any;
    showConfigureNavigation: boolean = false;
    isPublicPage: boolean;
    isPagematrixReady: boolean;
    pageMatrixServiceSub: Subscription;
    disableHtmlHeadTag: boolean = false;
    private unsubscribe = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private contentService: ContentService,
        private loadingIndicatorService: LoadingIndicatorService,
        private routeData: RouteDataService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private page: Page,
        private metaTagsService: MetaTagsService,
        private downloadClientConfig: DownloadClientConfig,
        private pageMatrixService: PageMatrixService,
    ) {}

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;
        this.initData = this.routeData.getInitData();
        this.directDownloadLink = this.page.lang + '/downloadclient';
        this.directAppDownloadLink = this.page.lang + '/downloadapp';
        this.downloadContent = this.initData.downloadContent;
        this.isPublicPage = this.downloadClientConfig.isPublicPage;

        if (
            this.downloadClientConfig.downloadAppOnPageLoad &&
            typeof this.downloadClientConfig.downloadAppOnPageLoad === 'string' &&
            this.downloadClientConfig.downloadAppOnPageLoad.toLowerCase() === 'true' &&
            this.downloadClientConfig.isPublicPage
        ) {
            window.location.href = this.initData.downloadUrl;
        }

        if (
            !this.isPublicPage &&
            this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo &&
            this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo.data &&
            this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo.data.fields
        ) {
            this.metaTagsService.whenReady.subscribe(() => {
                this.metaTagsService.setPageTags(this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo.data.fields.pageTitle, {
                    description: this.initData.downloadContent.pokerDownloadLayoutSitecoreInfo.data.fields.pageDescription,
                });
            });
        }

        this.activatedRoute.url.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
            const loadingIndicator = this.loadingIndicatorService.start();

            this.contentService
                .getJson(this.activatedRoute.snapshot.data.publicPageRoot + url.join('/'), {
                    product: ClientConfigProductName.POKER,
                    filterOnClient: true,
                })
                .pipe(
                    catchError(() =>
                        this.contentService.getJson('App-v1.0/partials/notfound', { product: ClientConfigProductName.SF, filterOnClient: true }),
                    ),
                )
                .subscribe((data) => {
                    this.content = data.items[0];

                    loadingIndicator.done();
                });
        });
        this.pageMatrixServiceSub = this.pageMatrixService.whenReady.subscribe(() => {
            this.isPagematrixReady = true;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
