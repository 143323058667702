import { Message } from '../../message';

export class RequestPxPlayersGridOnPageNumber extends Message {
    private MESSAGE_NAME: string = 'RequestPxPlayersGridOnPageNumber';
    private mtctId: number = 0;
    private pageNumber: number = 0;
    private keepTableSubscription: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, pageNumber: number = 0, keepTableSubscription: boolean | null = false) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.pageNumber = pageNumber;
        this.keepTableSubscription = keepTableSubscription;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getPageNumber(): number {
        return this.pageNumber;
    }

    setPageNumber(pageNumber: number) {
        this.pageNumber = pageNumber;
    }
    getKeepTableSubscription(): boolean | null {
        return this.keepTableSubscription;
    }

    setKeepTableSubscription(keepTableSubscription: boolean | null) {
        this.keepTableSubscription = keepTableSubscription;
    }
}
