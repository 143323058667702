import { Message } from '../../message';

export class ClientConfig extends Message {
    private MESSAGE_NAME: string = 'ClientConfig';
    private iniFileType: number = 0;
    private shouldRestart: number = 0;
    private config: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, iniFileType: number = 0, shouldRestart: number = 0, config: string | null = null) {
        super(reqServerPeerId);
        this.iniFileType = iniFileType;
        this.shouldRestart = shouldRestart;
        this.config = config;
    }

    getIniFileType(): number {
        return this.iniFileType;
    }

    setIniFileType(iniFileType: number) {
        this.iniFileType = iniFileType;
    }
    getShouldRestart(): number {
        return this.shouldRestart;
    }

    setShouldRestart(shouldRestart: number) {
        this.shouldRestart = shouldRestart;
    }
    getConfig(): string | null {
        return this.config;
    }

    setConfig(config: string | null) {
        this.config = config;
    }
}
