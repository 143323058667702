import { Message } from '../../message';

export class RequestHUSNGRematchAcceptedAck extends Message {
    private MESSAGE_NAME: string = 'RequestHUSNGRematchAcceptedAck';
    private targetSNGId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, targetSNGId: number = 0) {
        super(reqServerPeerId);
        this.targetSNGId = targetSNGId;
    }

    getTargetSNGId(): number {
        return this.targetSNGId;
    }

    setTargetSNGId(targetSNGId: number) {
        this.targetSNGId = targetSNGId;
    }
}
