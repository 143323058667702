import { Message } from '../../message';

export class TableStakesInfoResponse extends Message {
    private MESSAGE_NAME: string = 'TableStakesInfoResponse';
    private levelId: number = 0;
    private realTableStakesInfo: any[] | null = null;
    private playTableStakesInfo: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        levelId: number = 0,
        realTableStakesInfo: any[] | null = null,
        playTableStakesInfo: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.levelId = levelId;
        this.realTableStakesInfo = realTableStakesInfo;
        this.playTableStakesInfo = playTableStakesInfo;
    }

    getLevelId(): number {
        return this.levelId;
    }

    setLevelId(levelId: number) {
        this.levelId = levelId;
    }
    getRealTableStakesInfo(): any[] | null {
        return this.realTableStakesInfo;
    }

    setRealTableStakesInfo(realTableStakesInfo: any[] | null) {
        this.realTableStakesInfo = realTableStakesInfo;
    }
    getPlayTableStakesInfo(): any[] | null {
        return this.playTableStakesInfo;
    }

    setPlayTableStakesInfo(playTableStakesInfo: any[] | null) {
        this.playTableStakesInfo = playTableStakesInfo;
    }
}
