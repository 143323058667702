import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { MyTicketsService } from './mytickets.service';

@Injectable({ providedIn: 'root' })
export class pokerTicketsResolver implements Resolve<any> {
    constructor(private resource: MyTicketsService) {}

    resolve(): Observable<any> {
        return this.resource.getAllTickets();
    }
}
