import { Message } from '../../message';

export class HUEvenGame extends Message {
    private MESSAGE_NAME: string = 'HUEvenGame';
    private evenGame: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, evenGame: boolean | null = false) {
        super(reqServerPeerId);
        this.evenGame = evenGame;
    }

    getEvenGame(): boolean | null {
        return this.evenGame;
    }

    setEvenGame(evenGame: boolean | null) {
        this.evenGame = evenGame;
    }
}
