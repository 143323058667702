import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import { Observable, Subject, of } from 'rxjs';

import { ConnectorDelegateAdapter } from './pgBackendConnectors/connectorDelegateAdapter';
import { ConnectorDelegateProxy } from './pgBackendConnectors/connectorDelegateProxy';
import { IPGConnector } from './pgBackendConnectors/interfaces/IpgConnector';
import { PGNotificationCenter } from './pgBackendConnectors/notificationCenter';
import { PokerBackendConnector } from './pgBackendConnectors/pokerBackendConnector';
import { CSD } from './pgConnectors/sal/CSD';
import { ARAMessageFactory } from './pgConnectors/server/common/araMessageFactory/ARAMessageFactory';
import { Message } from './pgConnectors/server/common/message';
import { PokerLobbyMessageFactory } from './pgConnectors/server/common/pokerLobbyMessageFactory/PokerLobbyMessageFactory';
import { PokerMessageFactory } from './pgConnectors/server/common/pokerMessageFactory/PokerMessageFactory';
import { PokerWebClientDelegatorService } from './poker-web-client-delegator.service';
import { PokerWebClientDynaconAssetsConfigService } from './poker-web-dynacon-assets-config.service';
import { EventDispatcherGlobal } from './service/event-despacher-global';
import { GameTableNotificationService } from './service/game-table-notification.service';

@Injectable({ providedIn: 'root' })
export class PokerWebClientService extends ConnectorDelegateAdapter {
    params: Map<any, any> = new Map<any, any>();
    handshakeResponseSubject = new Subject<boolean>();
    delegateForwarder: ConnectorDelegateProxy;
    static connector: IPGConnector;
    myObservable = new Observable();
    gameTableNotificationService = new GameTableNotificationService();
    readonly logger: PokerLogger;
    private balanceLimit = 0;
    private eventDispatcherGlobal = new EventDispatcherGlobal();

    constructor(
        private api: PokerApiService,
        private dynaconAssetsConfigService: PokerWebClientDynaconAssetsConfigService,
        private pokerWebClientDelegatorService: PokerWebClientDelegatorService,
        loggerFactory: LoggerFactory,
    ) {
        super();
        this.openSplash();
        this.logger = loggerFactory.getLogger('PokerWebClientServiceLoggingComponent');
        this.delegateForwarder = new ConnectorDelegateProxy(this);
        if (PokerWebClientService.connector == null) {
            PokerWebClientService.connector = PokerBackendConnector.getInstance();
            PokerWebClientService.connector.registerFct(new ARAMessageFactory());
            PokerWebClientService.connector.registerFct(new PokerLobbyMessageFactory());
            PokerWebClientService.connector.registerFct(new PokerMessageFactory());
        }
        PokerWebClientService.connector.setDelegate(this.delegateForwarder);
        this.eventDispatcherGlobal.addEventListener('TABLE_REDUCE_BALANCE_INFO', this.setTableBalanceLimit.bind(this));
    }

    openSplash() {
        const checkInterval = 100; // Check every 100ms
        const maxAttempts = 100; // Stop after 100 attempts (10 seconds if checking every 100ms)

        let attempts = 0;

        const intervalId = setInterval(() => {
            const loadingIndicator = document.getElementsByTagName('vn-loading-indicator')[0];

            if (loadingIndicator) {
                const loadingElement = loadingIndicator.querySelector('.loading');
                if (loadingElement) {
                    loadingElement.classList.add('pwc-loader');
                    clearInterval(intervalId); // Element found, stop the interval
                }
            }

            attempts++;
            if (attempts >= maxAttempts) {
                clearInterval(intervalId); // Stop the interval after max attempts
            }
        }, checkInterval);
    }

    loadPrimaryAssets(dynaconConfigs) {
        this.dynaconAssetsConfigService.loadAssetsonDynaconConfigs(dynaconConfigs);
    }

    getSiteCoreLiterals(): Observable<any> {
        return this.api.get('gametable/GetLiterals');
    }

    getDynaconConfigs(): Observable<any> {
        return this.api.get('gametable/GetDynaconConfigs');
    }

    getLobbyPromotions(): Observable<any> {
        return this.api.get('PokerWebClient/GetLobbyPromotions');
    }
    getRegulatoryIcons(): Observable<any> {
        return this.api.get('PokerWebClient/GetRegulatoryIcons');
    }
    getBannerDetails(folderId: string | null): Observable<any> {
        this.logger.info('getBannerDetails..!!!!' + JSON.stringify(folderId));
        // const pathType = window.location.pathname.substring(4);
        return this.api.get(`pokerbanner/GetBannerData`, { pathType: 'mobilebanner', folderId: folderId });
    }

    getGameTableAssets() {
        return this.api.get('pokerWebClient/GetGameAssetLinks');
    }

    getPokerWebAssets() {
        return this.api.get('pokerWebClient/GetPokerWebAssets');
    }

    getClientExternalLinks() {
        return this.api.get('pokerWebClient/GetClientLinks');
    }

    getNoLobby(): Observable<any> {
        return of(null);
    }

    validateAccessId(accessId: string): Observable<boolean> {
        return this.api.get('pokerWebClient/ValidateAccessId', { accessId: accessId });
    }

    test() {
        return this.api;
    }

    override handleMessage(message: Message, i: number, domain: number) {
        this.logger.info('handleMessage..!!!!' + JSON.stringify(message));
        this.logger.info('handleMessage..!!!!' + JSON.stringify(i));
        this.logger.info('handleMessage..!!!!' + JSON.stringify(domain));
        //console.log(message);
        PGNotificationCenter.getInstance().messageEmitter(message.getMessageName(), message.classId, message);
    }

    handshakeResponseObservable(params): Observable<boolean> {
        this.handShakeRequest(params);
        return this.handshakeResponseSubject.asObservable();
    }

    override handshakeResponseStatus(success: boolean, domain: number) {
        if (!success) {
            this.logger.info('HandShake has been Failed for' + JSON.stringify(domain));
        } else {
            this.connectionSuccess();
        }
        this.handshakeResponseSubject.next(success);
        this.logger.info('HandShake has been success');
    }
    getTableBalanceLimit() {
        return this.balanceLimit;
    }
    setTableBalanceLimit(message) {
        this.balanceLimit = message.detail.balanceLimit;
    }

    override connectionSuccessforServer(serverIP: string) {
        super.connectionSuccessforServer(serverIP);
    }
    override updateConnectionStatus(domainId: number, status: number) {
        this.logger.info('updateConnectionStatus..!!!!' + JSON.stringify(domainId));
        this.logger.info('updateConnectionStatus..!!!!' + JSON.stringify(status));
    }
    override log(cause: number, logMessage: string) {
        if (cause === CSD.LOG_INFO) {
            this.logger.info(logMessage);
        } else if (cause === CSD.LOG_ERROR) {
            this.logger.error(logMessage);
        } else if (cause === CSD.LOG_WARN) {
            this.logger.warning(logMessage);
        }
    }

    handShakeRequest(value) {
        this.logger.info('handShakeRequest' + JSON.stringify(value));
        this.params.set('BRAND_ID', value.brandId);
        this.params.set('FRONTEND_ID', value.frontendId);
        this.params.set('PRODUCT_ID', value.productId);
        this.params.set('CID', value.cid);
        this.params.set('UCID', '');
        this.params.set('SL', value.sl);
        this.params.set('INVOKER_PRODUCT', value.invokerProduct);
        this.params.set('LAUNCH_TYPE', value.launchType);
        this.params.set('LOCALE', value.locale);
        this.params.set('ARABuildNumber', 90);
        this.params.set('GRABuildNumber', 1);
        this.params.set('TYPE', 1);
        this.params.set('SERVER_IP', value.serverIp);
        this.params.set('isFallbackToweb2tcp', value.isFallbackToweb2tcp);

        PokerWebClientService.connector.initiateConnectionWithParams(this.params);
    }

    connectionSuccess() {
        this.delegateForwarder.replaceDestination(this.pokerWebClientDelegatorService);
        this.logger.info('delegator has been changed..');
    }
}
