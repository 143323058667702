import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HtmlHeadtagIframeService {
    iframeResizer() {
        window.addEventListener('message', this.receiveMessage, false);
    }

    receiveMessage(evt: any) {
        if (evt.data !== '') {
            try {
                const payload = JSON.parse(evt.data);
                if (payload.type !== 'iframe-height') {
                    return '';
                }

                const newHeight = parseInt(payload.height, 10);
                const ifrm = document.getElementById('iframeResizer');
                if (ifrm) {
                    ifrm.style.height = newHeight + 'px';
                }
            } catch (e) {}
        }
        return '';
    }
}
