import { Message } from '../../message';
import { LSFlightedNextDayDetails } from './LSFlightedNextDayDetails';

export class LSFlightedData extends Message {
    private MESSAGE_NAME: string = 'LSFlightedData';
    private pauseType: number = 0;
    private pauseValue: number = 0;
    private nextDayMTT: LSFlightedNextDayDetails | null = null;
    private defaultChips: number = 0;
    private flightedBuyinOption: number = 0;
    private flightedDisplayId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        pauseType: number = 0,
        pauseValue: number = 0,
        nextDayMTT: LSFlightedNextDayDetails | null = null,
        defaultChips: number = 0,
        flightedBuyinOption: number = 0,
        flightedDisplayId: number = 0,
    ) {
        super(reqServerPeerId);
        this.pauseType = pauseType;
        this.pauseValue = pauseValue;
        this.nextDayMTT = nextDayMTT;
        this.defaultChips = defaultChips;
        this.flightedBuyinOption = flightedBuyinOption;
        this.flightedDisplayId = flightedDisplayId;
    }

    getPauseType(): number {
        return this.pauseType;
    }

    setPauseType(pauseType: number) {
        this.pauseType = pauseType;
    }
    getPauseValue(): number {
        return this.pauseValue;
    }

    setPauseValue(pauseValue: number) {
        this.pauseValue = pauseValue;
    }
    getNextDayMTT(): LSFlightedNextDayDetails | null {
        return this.nextDayMTT;
    }

    setNextDayMTT(nextDayMTT: LSFlightedNextDayDetails | null) {
        this.nextDayMTT = nextDayMTT;
    }
    getDefaultChips(): number {
        return this.defaultChips;
    }

    setDefaultChips(defaultChips: number) {
        this.defaultChips = defaultChips;
    }
    getFlightedBuyinOption(): number {
        return this.flightedBuyinOption;
    }

    setFlightedBuyinOption(flightedBuyinOption: number) {
        this.flightedBuyinOption = flightedBuyinOption;
    }
    getFlightedDisplayId(): number {
        return this.flightedDisplayId;
    }

    setFlightedDisplayId(flightedDisplayId: number) {
        this.flightedDisplayId = flightedDisplayId;
    }
}
