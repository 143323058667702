import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

// Initial Value
// export const initialState = {
//   "tableThemesCardStyle": 0,
//   "tableThemesCardBackground": 0,
//   "tableThemesTableFelt": 1,
//   "tableThemesTableStyle": 0,
//   "tableStyle": "#1EADEB",
//   "autoMuck": true,
//   "sound": true,
//   "vibrateWhenSilent": false,
//   "raiseBarAutoConfirm": false,
//   "rabbitFeature": true,
//   "reBuyToMax": false,
//   "throwAnimations": true,
//   "isPreFlopActive":true,
//   "betButton1":'2bb',
//   "betButton2":'2-2bb',
//   "betButton3":'2-5bb'
// };

export const initialGameSettingState = {
    tableThemesCardStyle: 0,
    tableThemesCardBackground: 0,
    tableThemesTableFelt: 1,
    tableThemesTableStyle: 0,
    tableStyle: '#1EADEB',
    autoMuck: true,
    sound: true,
    vibrateWhenSilent: false,
    raiseBarAutoConfirm: false,
    rabbitFeature: true,
    reBuyToMax: false,
    throwAnimations: true,
};
export const initialRaiseBtnPreFlopState = {
    isPreFlopActive: true,
    button0: 2,
    button1: 3,
    button2: 'AllIN',
    Type: 'preFlop',
};
export const initialRaiseBtnPostFlopState = {
    isPreFlopActive: false,
    button0: 0.5,
    button1: 0.75,
    button2: 'AllIN',
    Type: 'postFlop',
};

// initialize Selector
export const selectCashState = createFeatureSelector<any[]>('GameTableSettings');
export const selectRaiseBtnPreFlopState = createFeatureSelector<any[]>('RaiseButtonPreFlop');
export const selectRaiseBtnPostFlopState = createFeatureSelector<any[]>('RaiseButtonPostFlop');

// update Selector
export const GameTableSettingsSelect = createSelector(selectCashState, (tableSettings: any) => tableSettings);
export const RaiseButtonPreFlopSelect = createSelector(selectRaiseBtnPreFlopState, (tableSettings: any) => tableSettings);
export const RaiseButtonPostFlopSelect = createSelector(selectRaiseBtnPostFlopState, (tableSettings: any) => tableSettings);

// create Action
export const updateGameTableSettings = createAction('[Game Table] Table Settings', (tableSettings: any) => ({ tableSettings }));
export const updateRaiseButtonPreFlop = createAction('[Game Table] Raise Button PreFlop Settings', (tableSettings: any) => ({ tableSettings }));
export const updateRaiseButtonPostFlop = createAction('[Game Table] Raise Button PostFlop Settings', (tableSettings: any) => ({ tableSettings }));

// create Reducer
export const GameTableSettingsReducer = createReducer(
    initialGameSettingState,
    on(updateGameTableSettings, (state, { tableSettings }) => tableSettings),
);
export const RaiseButtonPreFlopReducer = createReducer(
    initialRaiseBtnPreFlopState,
    on(updateRaiseButtonPreFlop, (state, { tableSettings }) => tableSettings),
);
export const RaiseButtonPostFlopReducer = createReducer(
    initialRaiseBtnPostFlopState,
    on(updateRaiseButtonPostFlop, (state, { tableSettings }) => tableSettings),
);
