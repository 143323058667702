export const GameType = {
    SPINS: 0,
    FAST_FORWARD: 1,
    CASH: 2,
};

export const SERVER_EVENTS = {
    LSDeletedTableDetails: 'LSDeletedTableDetails',
    LSTableOccupancyChange: 'LSTableOccupancyChange',
    LSTableDetails: 'LSTableDetails',

    HIGH_ROLLER_STAKE_RANGE: 'High Roller',
    HIGH_STAKE_RANGE: 'High',
    MEDIUM_STAKE_RANGE: 'Medium',
    LOW_STAKE_RANGE: 'Low',
    MICRO_STAKE_RANGE: 'Micro',

    HI_VARIANT: 'Hi',
    HI_LO_VARIANT: 'Hi-Lo',

    TABLE_SIZE_FULL_RING: '7-8 (Full Ring)',
    TABLE_SIZE_SHORT_HANDED: '5-6 (Short Handed)',
    TABLE_SIZE_HEADSUP: '2 (Heads Up)',

    CASH_GAME_TYPE_STANDARD: 'Standard',
    CASH_GAME_TYPE_REGULAR: 'Regular',
    CASH_GAME_TYPE_Cash_Games: 'Cash games',
    CASH_GAME_TYPE_ANTE: 'Ante',
    CASH_GAME_TYPE_REALNAME: 'Real Name',
    CASH_GAME_TYPE_FEATURED: 'Featured',

    GAME_TYPE_TEXAS_HOLDEM: 0,
    GAME_TYPE_DOUBLE_HOLDEM: 6,
    GAME_TYPE_NOLIMIT_HOLDEM: 9,
    GAME_TYPE_POTLIMIT_HOLDEM: 10,
    GAME_TYPE_OMAHA_HI: 1,
    GAME_TYPE_OMAHA_HI_LO: 2,
    GAME_TYPE_SHORT_DECK: 7,
};
export const QSGAMETYPES = {
    GAME_TYPE_TEXAS_HOLDEM: 0,
    GAME_TYPE_OMAHA_HI: 1,
    GAME_TYPE_OMAHA_HI_LO: 2,
    GAME_TYPE_SEVEN_STUD_HI: 3,
    GAME_TYPE_SEVEN_STUD_HI_LO: 4,
    GAME_TYPE_DOUBLE_HOLDEM: 6,
    GAME_TYPE_SHORT_DECK: 7,
    QS_GAME_TYPE_NLHOLDEM_SHORTDECK: 18,
    QS_GAME_TYPE_PLHOLDEM_SHORTDECK: 19,
    LIMIT_TYPE_NO_LIMIT: 1,
    LIMIT_TYPE_POT_LIMIT: 2,
    QS_GAME_TYPE_OMAHA: 4,
    QS_GAME_TYPE_PLOMAHA: 5,
    QS_GAME_TYPE_NLHOLDEM: 2,
    QS_GAME_TYPE_NOLIMIT_DOUBLE_HOLDEM: 13,
    QS_GAME_TYPE_LIMIT_DOUBLE_HOLDEM: 12,
    QS_GAME_TYPE_LIMITHOLDEM: 1,
    LIMIT_TYPE_REGULAR: 0,
    QS_GAME_TYPE_7CARDSTUD: 6,
    QS_GAME_TYPE_PLHOLDEM: 3,
};
