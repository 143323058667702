import { Message } from '../../message';

export class ITMInformation extends Message {
    private MESSAGE_NAME: string = 'ITMInformation';
    private prizeAmount: number = 0;
    private nextPrizeRank: number = 0;
    private nextPrizeAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, prizeAmount: number = 0, nextPrizeRank: number = 0, nextPrizeAmount: number = 0) {
        super(reqServerPeerId);
        this.prizeAmount = prizeAmount;
        this.nextPrizeRank = nextPrizeRank;
        this.nextPrizeAmount = nextPrizeAmount;
    }

    getPrizeAmount(): number {
        return this.prizeAmount;
    }

    setPrizeAmount(prizeAmount: number) {
        this.prizeAmount = prizeAmount;
    }
    getNextPrizeRank(): number {
        return this.nextPrizeRank;
    }

    setNextPrizeRank(nextPrizeRank: number) {
        this.nextPrizeRank = nextPrizeRank;
    }
    getNextPrizeAmount(): number {
        return this.nextPrizeAmount;
    }

    setNextPrizeAmount(nextPrizeAmount: number) {
        this.nextPrizeAmount = nextPrizeAmount;
    }
}
