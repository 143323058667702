import { Message } from '../../message';

export class LoginSessionTimeoutIntimation extends Message {
    private MESSAGE_NAME: string = 'LoginSessionTimeoutIntimation';
    private timeLeft: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, timeLeft: number = 0) {
        super(reqServerPeerId);
        this.timeLeft = timeLeft;
    }

    getTimeLeft(): number {
        return this.timeLeft;
    }

    setTimeLeft(timeLeft: number) {
        this.timeLeft = timeLeft;
    }
}
