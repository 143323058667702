import { Message } from '../../message';

export class LSTourneyRoundChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyRoundChange';
    private currentRound: number = 0;
    private nextRoundBeginsAt: number = 0;
    private nextShootoutBreakDuration: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currentRound: number = 0, nextRoundBeginsAt: number = 0, nextShootoutBreakDuration: number = 0) {
        super(reqServerPeerId);
        this.currentRound = currentRound;
        this.nextRoundBeginsAt = nextRoundBeginsAt;
        this.nextShootoutBreakDuration = nextShootoutBreakDuration;
    }

    getCurrentRound(): number {
        return this.currentRound;
    }

    setCurrentRound(currentRound: number) {
        this.currentRound = currentRound;
    }
    getNextRoundBeginsAt(): number {
        return this.nextRoundBeginsAt;
    }

    setNextRoundBeginsAt(nextRoundBeginsAt: number) {
        this.nextRoundBeginsAt = nextRoundBeginsAt;
    }
    getNextShootoutBreakDuration(): number {
        return this.nextShootoutBreakDuration;
    }

    setNextShootoutBreakDuration(nextShootoutBreakDuration: number) {
        this.nextShootoutBreakDuration = nextShootoutBreakDuration;
    }
}
