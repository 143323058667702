import { Message } from '../../message';
import { GameTableStatus } from './GameTableStatus';

export class CreatePrivateTableResponse extends Message {
    private MESSAGE_NAME: string = 'CreatePrivateTableResponse';
    private tableId: number = 0;
    private errorCode: number = 0;
    private gameTableStatus: GameTableStatus | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, errorCode: number = 0, gameTableStatus: GameTableStatus | null = null) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.errorCode = errorCode;
        this.gameTableStatus = gameTableStatus;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
    getGameTableStatus(): GameTableStatus | null {
        return this.gameTableStatus;
    }

    setGameTableStatus(gameTableStatus: GameTableStatus | null) {
        this.gameTableStatus = gameTableStatus;
    }
}
