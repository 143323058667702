import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { HtmlHeadtagIframeService } from './html-head-tag-iframe.service';
import { HtmlHeadtagNavigationService } from './html-head-tag-navigation-fix.service';
import { NavigationBootstrapService } from './navigation-bootstrap.service';
import { ScrollToTargetService } from './scroll-to-target.service';

export function provideNavigationBootstrap(): Provider[] {
    return [runOnAppInit(NavigationBootstrapService), HtmlHeadtagIframeService, HtmlHeadtagNavigationService, ScrollToTargetService];
}
