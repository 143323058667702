import { Message } from '../../message';

export class RelocateTable extends Message {
    private MESSAGE_NAME: string = 'RelocateTable';
    private targetServer: number = 0;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, targetServer: number = 0, tableId: number = 0) {
        super(reqServerPeerId);
        this.targetServer = targetServer;
        this.tableId = tableId;
    }

    getTargetServer(): number {
        return this.targetServer;
    }

    setTargetServer(targetServer: number) {
        this.targetServer = targetServer;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
