import { Message } from '../../message';

export class LastLoginTime extends Message {
    private MESSAGE_NAME: string = 'LastLoginTime';
    private lastLoginTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lastLoginTime: number = 0) {
        super(reqServerPeerId);
        this.lastLoginTime = lastLoginTime;
    }

    getLastLoginTime(): number {
        return this.lastLoginTime;
    }

    setLastLoginTime(lastLoginTime: number) {
        this.lastLoginTime = lastLoginTime;
    }
}
