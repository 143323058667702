<lh-navigation-layout-page
    [showTitle]="false"
    topMenuVisibility="{{ disableTopNav }}"
    sourceItem="mytickets"
    highlightProduct="promo"
    class="portal-wrapper-fw"
    *ngIf="!isPromoHub">
    <ng-container *ngTemplateOutlet="MyTicketsView"></ng-container>
</lh-navigation-layout-page>

<div id="poker-tickets" class="portal-wrapper-fw" *ngIf="isPromoHub">
    <ng-container>
        <ng-container *ngTemplateOutlet="MyTicketsView"></ng-container>
    </ng-container>
</div>

<ng-template #MyTicketsView>
    <vn-message-panel></vn-message-panel>

    <div id="myTickets" class="myTickets" [class.mac-desktop]="MacDesktop" [class.mobile-nativeapp]="mobileNativeApp" *ngIf="allTickets">
        <section class="ticketsOverview">
            <div class="myTicketsTitle" [ngClass]="{ noPrevButton: !previousUrl }">
                <a (click)="goToBack($event)"><span class="backbtn icon-arrow-left" *ngIf="previousUrl"></span></a>
                <a (click)="goToBack($event)"><span class="closebtn theme-ex" *ngIf="previousUrl"></span></a>
                <h1>{{ siteCoreContent.title }}</h1>
                <ul class="mytickets-filters">
                    <li
                        *ngFor="let item of ticketFilterData"
                        (click)="selectFilters(item, true)"
                        [ngClass]="{ active: selectedItem.value === item.value }">
                        <button *ngIf="item.count < 999; else elseBlock" type="button" class="mytickets-filter-btn">
                            {{ item.title }}({{ item.count }})
                        </button>
                        <ng-template #elseBlock>
                            <button type="button" class="mytickets-filter-btn count" [attr.data-tooltip]="item.count">{{ item.title }}</button>
                        </ng-template>
                    </li>
                    <li class="info-tooltip-wrap" id="tooltip" on-mouseover="setToolTipPosition()">
                        <button type="button" class="mytickets-filter-btn info-tooltip" on-mouseover="setToolTipPosition()">i</button>
                        <div class="tooltiptext" [innerHtml]="siteCoreContent.messages.InfoIcon | trustAsHtml" id="tooltip-text">&nbsp;</div>
                    </li>
                </ul>
                <div class="ticketFilterBtn" (click)="showFilters($event)">
                    <span class="icon-filter-variant"></span> {{ siteCoreContent.messages.filter }}
                    <div class="filters-popup-block" *ngIf="showfilters">
                        <div class="filter-heading">
                            <i class="back-arrow theme-left"></i>
                            <h3>{{ siteCoreContent.messages.filter }} {{ siteCoreContent.messages.Tickets }}</h3>
                        </div>
                        <ul class="ticketType">
                            <li
                                *ngFor="let item of ticketFilterData"
                                (click)="selectFilters(item, true)"
                                [ngClass]="{ active: selectedItem.value === item.value }">
                                <label *ngIf="item">
                                    <input type="radio" name="ticketFilterOption" id="{{ item.value }}" value="{{ item.value }}" />
                                    {{ item.title }}<i class="theme-check"></i>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="allTicketsWrapper" *ngIf="allTickets.length !== 0">
                <div class="ticketContainer" *ngFor="let ticket of allTickets">
                    <div class="ticketWrapper">
                        <h3>{{ ticket.tktDlt.desc }}</h3>
                        <label
                            >{{ siteCoreContent.messages.expires }}
                            <span>{{ DateConversion(ticket.tktDlt.validupto) }}</span>
                            <span>
                                &nbsp;
                                {{ ticket.tktDlt.validupto | slice: 11 : 16 }} ET
                            </span>
                        </label>
                        <div class="availableAndPrice">
                            <div class="available">
                                <label>{{ siteCoreContent.messages.available }}</label>
                                <h1>{{ siteCoreContent.messages.X }}{{ ticket.tktDlt.freeRollCount }}</h1>
                            </div>
                            <div class="price">
                                <label>{{ siteCoreContent.messages.price }}</label>
                                <h1>{{ siteCoreContent.messages[ticket.tktDlt.cur] }}{{ ticket.tktDlt.value / 100 | number: '1.2-2' }}</h1>
                            </div>
                        </div>
                        <label *ngIf="ticket.trnyCount !== 0">{{ siteCoreContent.messages.nextAvailableTournament }}</label>
                        <div class="startsInwrapper" *ngIf="ticket.trnyCount !== 0">
                            <div class="startsIn">
                                <ng-container *ngIf="ticket.trnyDlt.mtctLobbyData.status !== 5">
                                    <ng-container *ngIf="ticket.trnyDlt.mtctLobbyData.tournamentCategory === 0">
                                        <ng-container *ngIf="!ticket.trnyDlt.mtctLobbyData.onDemandMTCT">
                                            <span class="startInText" [class.startsIn]="ticket.trnyDlt.mtctLobbyData.status !== 5">
                                                {{ siteCoreContent.messages.startsIn }}
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="ticket.trnyDlt.mtctLobbyData.onDemandMTCT && isUSNJ">
                                            <span class="startInText" [class.startsIn]="ticket.trnyDlt.mtctLobbyData.status !== 5">
                                                {{ siteCoreContent.messages.starts }}
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="ticket.trnyDlt.mtctLobbyData.onDemandMTCT && !isUSNJ">
                                            <span class="startInText" [class.startsIn]="ticket.trnyDlt.mtctLobbyData.status !== 5">
                                                {{ siteCoreContent.messages.startsIn }}
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 1 ||
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 3
                                        ">
                                        <span class="startInText" [class.startsIn]="ticket.trnyDlt.mtctLobbyData.status !== 5">
                                            {{ siteCoreContent.messages.starts }}
                                        </span>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="ticket.trnyDlt.mtctLobbyData.status === 5">
                                    <span class="startInText" [class.lateReg]="ticket.trnyDlt.mtctLobbyData.status === 5">
                                        {{ siteCoreContent.messages.lateReg }}
                                    </span>
                                </ng-container>
                                <span *ngIf="ticket.trnyDlt.mtctLobbyData.status !== 5">
                                    <span
                                        class="startInValue"
                                        *ngIf="
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 1 ||
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 3
                                        ">
                                        {{ ticket.trnyDlt.mtctLobbyData.maxParticipants }} {{ siteCoreContent.messages.player }}
                                    </span>
                                    <span
                                        class="startInValue"
                                        *ngIf="
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 0 &&
                                            ticket.trnyDlt.mtctLobbyData.onDemandMTCT &&
                                            isUSNJ
                                        ">
                                        {{ ticket.trnyDlt.mtctLobbyData.trnyMinPlrs }} {{ siteCoreContent.messages.player }}
                                    </span>
                                    <span
                                        class="startInValue"
                                        *ngIf="
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 0 &&
                                            ticket.trnyDlt.mtctLobbyData.onDemandMTCT === false
                                        ">
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'days'">
                                            {{ ticket.trnyDlt.days }} {{ siteCoreContent.messages.days }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'hours mins'">
                                            {{ ticket.trnyDlt.hours }}{{ siteCoreContent.messages.h }} {{ ticket.trnyDlt.mins
                                            }}{{ siteCoreContent.messages.m }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'hours'">
                                            {{ ticket.trnyDlt.hours }} {{ siteCoreContent.messages.h }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'mins'">
                                            {{ ticket.trnyDlt.mins }} {{ siteCoreContent.messages.mins }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'secs'">
                                            {{ ticket.trnyDlt.secs }} {{ siteCoreContent.messages.secs }}
                                        </ng-container>
                                    </span>
                                    <span
                                        class="startInValue"
                                        *ngIf="
                                            ticket.trnyDlt.mtctLobbyData.tournamentCategory === 0 &&
                                            ticket.trnyDlt.mtctLobbyData.onDemandMTCT &&
                                            !isUSNJ
                                        ">
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'days'">
                                            {{ ticket.trnyDlt.days }} {{ siteCoreContent.messages.days }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'hours mins'">
                                            {{ ticket.trnyDlt.hours }}{{ siteCoreContent.messages.h }} {{ ticket.trnyDlt.mins
                                            }}{{ siteCoreContent.messages.m }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'hours'">
                                            {{ ticket.trnyDlt.hours }} {{ siteCoreContent.messages.h }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'mins'">
                                            {{ ticket.trnyDlt.mins }} {{ siteCoreContent.messages.mins }}
                                        </ng-container>
                                        <ng-container *ngIf="statusFilter(ticket.trnyDlt) === 'secs'">
                                            {{ ticket.trnyDlt.secs }} {{ siteCoreContent.messages.secs }}
                                        </ng-container>
                                    </span>
                                </span>
                                <span
                                    class="startInValue"
                                    [class.lateReg]="ticket.trnyDlt.mtctLobbyData.status === 5"
                                    *ngIf="ticket.trnyDlt.mtctLobbyData.status === 5">
                                    {{
                                        isUSNJ
                                            ? (ticket.trnyDlt.mtctLobbyData.tourneyStartTime | date: 'MM/dd/yy')
                                            : (ticket.trnyDlt.mtctLobbyData.tourneyStartTime | date: 'dd/MM/yy')
                                    }}
                                </span>
                            </div>
                            <p class="startsInDesc" *ngIf="ticket.trnyDlt.name.length !== 0">
                                {{ ticket.trnyDlt.name | ticketsTextTruncate: ['100', '...'] }}
                            </p>
                            <p class="startsInDesc" *ngIf="ticket.trnyDlt.name.length === 0">
                                {{ siteCoreContent.messages[ticket.tktDlt.cur] }}{{ ticket.tktDlt.value / 100 | number: '1.2-2' }}
                            </p>
                        </div>
                        <div class="ticketsbuttons" [class.disabled]="ticket.trnyCount === 0">
                            <button class="defaultTicket" [disabled]="ticket.trnyCount === 0" *ngIf="ticket.trnyCount === 0">
                                {{ siteCoreContent.messages.noOptions }}
                            </button>
                            <button
                                class="defaultTicket"
                                *ngIf="ticket.trnyCount !== 0"
                                (click)="getTicketDetails($event, ticket.tktDlt.id, ticket.trnyCount)">
                                <span class="inview">{{ siteCoreContent.messages.view }}</span>
                                <span class="inhidden">{{ siteCoreContent.messages.hide }}</span>
                                {{ ticket.trnyCount }} {{ siteCoreContent.messages.options }}
                            </button>
                            <button
                                class="activeTicket"
                                [disabled]="ticket.trnyCount === 0"
                                id="useTcktbutton"
                                (click)="
                                    useTicketNowBtn(
                                        ticket.trnyDlt.mtctLobbyData.mtctId,
                                        ticket.trnyDlt.mtctLobbyData.mtsgId,
                                        ticket.tktDlt.ticketType,
                                        ticket.trnyDlt.mtctLobbyData.tournamentCategory
                                    )
                                ">
                                {{ siteCoreContent.messages.useTicketNow }}
                            </button>
                        </div>
                    </div>
                    <div class="ticketDetails">
                        <div class="subTickets-wrapper">
                            <div class="subTickets">
                                <div
                                    class="iteam"
                                    *ngFor="let ticket of ticketdetails"
                                    (click)="onSubTicketClick(ticket, ticket.mtctLobbyData.mtctId, ticket.mtctLobbyData.mtsgId)"
                                    [ngClass]="{ active: selectedSubTicket === ticket }">
                                    <div class="startsInwrapper">
                                        <div class="startsIn">
                                            <ng-container *ngIf="ticket.mtctLobbyData.status !== 5">
                                                <ng-container *ngIf="ticket.mtctLobbyData.tournamentCategory === 0">
                                                    <ng-container *ngIf="!ticket.mtctLobbyData.onDemandMTCT">
                                                        <span class="startInText" [class.startsIn]="ticket.mtctLobbyData.status !== 5">
                                                            {{ siteCoreContent.messages.startsIn }}
                                                        </span>
                                                    </ng-container>
                                                    <ng-container *ngIf="ticket.mtctLobbyData.onDemandMTCT && isUSNJ">
                                                        <span class="startInText" [class.startsIn]="ticket.mtctLobbyData.status !== 5">
                                                            {{ siteCoreContent.messages.starts }}
                                                        </span>
                                                    </ng-container>
                                                    <ng-container *ngIf="ticket.mtctLobbyData.onDemandMTCT && !isUSNJ">
                                                        <span class="startInText" [class.startsIn]="ticket.mtctLobbyData.status !== 5">
                                                            {{ siteCoreContent.messages.startsIn }}
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        ticket.mtctLobbyData.tournamentCategory === 1 || ticket.mtctLobbyData.tournamentCategory === 3
                                                    ">
                                                    <span class="startInText" [class.startsIn]="ticket.mtctLobbyData.status !== 5">
                                                        {{ siteCoreContent.messages.starts }}
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="ticket.mtctLobbyData.status === 5">
                                                <span class="startInText" [class.lateReg]="ticket.mtctLobbyData.status === 5">
                                                    {{ siteCoreContent.messages.lateReg }}
                                                </span>
                                            </ng-container>
                                            <span *ngIf="ticket.mtctLobbyData.status !== 5">
                                                <span
                                                    class="startInValue"
                                                    *ngIf="
                                                        ticket.mtctLobbyData.tournamentCategory === 1 || ticket.mtctLobbyData.tournamentCategory === 3
                                                    ">
                                                    {{ ticket.mtctLobbyData.maxParticipants }} {{ siteCoreContent.messages.player }}
                                                </span>
                                                <span
                                                    class="startInValue"
                                                    *ngIf="
                                                        ticket.mtctLobbyData.tournamentCategory === 0 && ticket.mtctLobbyData.onDemandMTCT && isUSNJ
                                                    ">
                                                    {{ ticket.mtctLobbyData.trnyMinPlrs }} {{ siteCoreContent.messages.player }}
                                                </span>
                                                <span
                                                    class="startInValue"
                                                    *ngIf="
                                                        ticket.mtctLobbyData.tournamentCategory === 0 && ticket.mtctLobbyData.onDemandMTCT === false
                                                    ">
                                                    <ng-container *ngIf="statusFilter(ticket) === 'days'">
                                                        {{ ticket.days }} {{ siteCoreContent.messages.days }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'hours mins'">
                                                        {{ ticket.hours }}{{ siteCoreContent.messages.h }} {{ ticket.mins
                                                        }}{{ siteCoreContent.messages.m }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'hours'">
                                                        {{ ticket.hours }} {{ siteCoreContent.messages.h }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'mins'">
                                                        {{ ticket.mins }} {{ siteCoreContent.messages.mins }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'secs'">
                                                        {{ ticket.secs }} {{ siteCoreContent.messages.secs }}
                                                    </ng-container>
                                                </span>
                                                <span
                                                    class="startInValue"
                                                    *ngIf="
                                                        ticket.mtctLobbyData.tournamentCategory === 0 && ticket.mtctLobbyData.onDemandMTCT && !isUSNJ
                                                    ">
                                                    <ng-container *ngIf="statusFilter(ticket) === 'days'">
                                                        {{ ticket.days }} {{ siteCoreContent.messages.days }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'hours mins'">
                                                        {{ ticket.hours }}{{ siteCoreContent.messages.h }} {{ ticket.mins
                                                        }}{{ siteCoreContent.messages.m }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'hours'">
                                                        {{ ticket.hours }} {{ siteCoreContent.messages.h }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'mins'">
                                                        {{ ticket.mins }} {{ siteCoreContent.messages.mins }}
                                                    </ng-container>
                                                    <ng-container *ngIf="statusFilter(ticket) === 'secs'">
                                                        {{ ticket.secs }} {{ siteCoreContent.messages.secs }}
                                                    </ng-container>
                                                </span>
                                            </span>
                                            <span
                                                class="startInValue"
                                                [class.lateReg]="ticket.mtctLobbyData.status === 5"
                                                *ngIf="ticket.mtctLobbyData.status === 5">
                                                {{
                                                    isUSNJ
                                                        ? (ticket.mtctLobbyData.tourneyStartTime | date: 'MM/dd/yy')
                                                        : (ticket.mtctLobbyData.tourneyStartTime | date: 'dd/MM/yy')
                                                }}
                                            </span>
                                        </div>
                                        <div class="buyinsWrapper">
                                            <p *ngIf="ticket.name.length !== 0">{{ ticket.name | ticketsTextTruncate: ['100', '...'] }}</p>
                                            <p *ngIf="ticket.name.length === 0">
                                                {{ siteCoreContent.messages[parentTicketDetails.tktDlt.cur]
                                                }}{{ ticket.mtctLobbyData.totalBuyIn / 100 | number: '1.2-2' }}
                                            </p>
                                            <div>
                                                <span class="users"
                                                    ><span>&nbsp;</span> <b>{{ ticket.mtctLobbyData.noOfParticipants }}</b
                                                    >/{{ ticket.mtctLobbyData.maxParticipants }}</span
                                                >
                                                <span class="buyIn"
                                                    >{{ siteCoreContent.messages.buyIn }}:
                                                    <span
                                                        >{{ siteCoreContent.messages[parentTicketDetails.tktDlt.cur]
                                                        }}{{ ticket.mtctLobbyData.totalBuyIn / 100 | number: '1.2-2' }}</span
                                                    ></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="subTicketsBtns">
                                <button class="defaultTicket" (click)="gotoLobbyBtn()">{{ siteCoreContent.messages.openLobby }}</button>
                                <button
                                    class="activeTicket"
                                    id="useTcktbutton"
                                    (click)="
                                        useTicketNowBtn(
                                            selectedSubTicketId,
                                            selectedSubSngTicketId,
                                            parentTicketDetails.tktDlt.ticketType,
                                            parentTicketDetails.trnyDlt.mtctLobbyData.tournamentCategory
                                        )
                                    ">
                                    {{ siteCoreContent.messages.useTicketNow }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="allTickets.length === 0" class="noTickets">
                <h3>{{ noTicketsInfo }}.</h3>
            </div>
        </section>
    </div>

    <div class="filter-popup-mask" *ngIf="showfilters" (click)="hideFilters()"></div>
</ng-template>
<scroll-to-top *ngIf="disableHtmlHeadTag"></scroll-to-top>
