import { Message } from '../../message';

export class SessionTimeRemainingNotification extends Message {
    private MESSAGE_NAME: string = 'SessionTimeRemainingNotification';
    private reminderEventType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, reminderEventType: number = 0) {
        super(reqServerPeerId);
        this.reminderEventType = reminderEventType;
    }

    getReminderEventType(): number {
        return this.reminderEventType;
    }

    setReminderEventType(reminderEventType: number) {
        this.reminderEventType = reminderEventType;
    }
}
