export class GameStateModel {
    tableStatus: any;
    connectionStatus = -1;
    sidePots: any = {};
    constructor() {
        this.tableStatus = {};
        this.tableStatus.maxNoOfSeats = 6;
    }
    // getters
    getConnectionStatus = function () {
        return this.connectionStatus;
    };
    //----------poker game code starts -----------
    setGameStatus = function (obj) {
        // delete earlier gameStatus total data and update with new
        //communityHand ---> communityCards
        this.communityHand = obj.communityHand;
        this.currentStake = obj.currentStake;
        this.dealer = obj.dealer;
        // this.tableStatus.gameId = obj.gameId;
        this.jackpotRake = obj.jackpotRake;
        if (obj.players) {
            this.playersData = obj.players;
        }
        this.pots = obj.pots;
        this.rabbitCommunityCards = obj.rabbitCommunityCards;
        this.rake = obj.rake;
        this.reservedSeats = obj.reservedSeats;
        this.roundCount = obj.roundCount;
        this.seatNo = obj.seatNo;
        this.tableStatus.tourneyGameStatus = obj.tourneyGameStatus;
        this.trnyLevelInfo = obj.trnyLevelInfo;
        this.trnyStartedAt = obj.trnyStartedAt;
        this.waitingNo = obj.waitingNo;
        this.waitingStatus = obj.waitingStatus;
        this.waitingUsersCount = obj.waitingUsersCount;
        this.dealerSeatNo = obj.dealer;
        // this.tableStatus.tableLimitType = obj.tableLimitType;
    };
    getPots = function () {
        return this.pots;
    };
    getTourneyGameStatus = function () {
        return this.tableStatus.tourneyGameStatus;
    };
    AddCommunityCards = function (obj) {
        this.communityCardNo = obj.cardNo;
        this.communityCardType = obj.cardType;
        this.communityCards = obj.cards;
    };
    AddPlayer = function (obj) {
        this.newPlayerBalance = obj.balance;
        this.newPlayerBrandId = obj.brandId;
        this.newPlayerCity = obj.city;
        this.newPlayerCountry = obj.country;
        this.newPlayerImageUrl = obj.imageUrl;
        this.newPlayerIsCustomAvatar = obj.isCustomAvatar;
        this.newPlayerIsSeatedPlayer = obj.isSeatedPlayer;
        this.newPlayerPlayerId = obj.playerId;
        this.newPlayerSeatingPosition = obj.seatingPosition;
        this.newPlayerSex = obj.sex;
        this.newPlayerAvatarImagePath = obj.avatarImagePath;
        this.newPlayerAvatarImageName = obj.avatarImageName;
        this.newPlayerPlayerAccountId = obj.playerAccountId;
        this.newPlayerPlayerFullName = obj.playerFullName;
        this.newPlayerPlayerRealName = obj.playerRealName;
        this.addPlayerData = obj;
    };
    getAddPlayerData = function () {
        return this.addPlayerData;
    };
    CurrentPlayer = function (seatNo) {
        this.activeSeatNo = seatNo;
    };
    Dealer = function (obj) {
        this.dealerId = obj.dealerId;
        this.dealerSeatNo = obj.seatingPosition;
    };
    setEndTheRound = function (obj) {
        this.currentPotAmount = obj.currentPotAmount;
        this.jackpotRake = obj.jackpotRake;
        this.rake = obj.rake;
        this.roundCount = obj.roundCount;
        this.endTheRoundData = obj;
        for (const sidePot of obj.sidePots) {
            //this.sidePots[sidePot.potNumber] = sidePot;
            this.sidePots[sidePot.potNo] = sidePot;
        }
    };
    setRoundCount = function (roundCount) {
        this.roundCount = roundCount;
    };
    NewGame = function (obj) {
        if (this.tableStatus.gameId == 0) {
            this.prevHandId = this.tableStatus.gameId;
        } else {
            this.prevHandId = this.oldHandId;
        }
        this.tableStatus.gameId = obj.gameId;
        this.oldHandId = this.tableStatus.gameId;

        if (obj.dealerPosition != undefined) {
            //first round, if player joins in between the hand
            this.dealerSeatNo = obj.dealerPosition;
        } else {
            //for all other rounds
            this.dealerSeatNo = obj.seatNo;
        }
        this.roundCount = 1;
    };
    getCurrentGameId = function () {
        return this.tableStatus.gameId;
    };
    setCurrentGameId = function (gameId) {
        this.tableStatus.gameId = gameId;
    };
    getPrevHandId = function () {
        return this.prevHandId;
    };
    getEndTheRoundData = function () {
        return this.endTheRoundData;
    };
    getDealerSeatNo = function () {
        return this.dealerSeatNo;
    };
    GameTableStatus = function (obj) {
        //this.tableStatus = obj;
        this.tableStatus.activeFlag = obj.activeFlag;
        this.tableStatus.allowPlayerToSit = obj.allowPlayerToSit;
        this.tableStatus.ante = obj.ante;
        this.tableStatus.autoLoad = obj.autoLoad;
        this.tableStatus.avgFlopSeen = obj.avgFlopSeen;
        this.tableStatus.avgPot = obj.avgPot;
        this.tableStatus.bigBlind = obj.bigBlind;
        this.tableStatus.bringIn = obj.bringIn;
        this.tableStatus.casinoTrnyLevelInfo = obj.casinoTrnyLevelInfo;
        this.tableStatus.countDownTime = obj.countDownTime;
        this.tableStatus.dbTableInfoId = obj.dbTableInfoId;
        this.tableStatus.disconProtectType = obj.disconProtectType;
        this.tableStatus.familyId = obj.familyId;
        this.tableStatus.fppBuyIn = obj.fppBuyIn;
        this.tableStatus.gameCurrency = obj.gameCurrency;
        this.tableStatus.gameId = obj.gameId;
        this.tableStatus.gamePlayType = obj.gamePlayType;
        this.tableStatus.gameTypeId = obj.gameTypeId;
        this.tableStatus.groupId = obj.groupId;
        this.tableStatus.handsPerHour = obj.handsPerHour;
        this.tableStatus.huTableType = obj.huTableType;
        this.tableStatus.isChampionshipTable = obj.isChampionshipTable;
        this.tableStatus.isTBTRandomized = obj.isTBTRandomized;
        this.tableStatus.jackpotId = obj.jackpotId;
        this.tableStatus.maxBuyInAmount = obj.maxBuyInAmount;
        this.tableStatus.maxNoOfSeats = obj.maxNoOfSeats;
        this.tableStatus.minBuyInAmount = obj.minBuyInAmount;
        this.tableStatus.mtctBuyIn = obj.mtctBuyIn;
        this.tableStatus.mtctId = obj.mtctId;
        this.tableStatus.playerCount = obj.playerCount;
        this.tableStatus.playersPerFlop = obj.playersPerFlop;
        this.tableStatus.pvtTableType = obj.pvtTableType;
        this.tableStatus.reservedSeatsCount = obj.reservedSeatsCount;
        this.tableStatus.serverPeerId = obj.serverPeerId;
        this.tableStatus.smallBlind = obj.smallBlind;
        this.tableStatus.stakesLowerLimit = obj.stakesLowerLimit;
        this.tableStatus.stakesUpperLimit = obj.stakesUpperLimit;
        this.tableStatus.tabIds = obj.tabIds;
        this.tableStatus.tableCategory = obj.tableCategory;
        this.tableStatus.tableColorCode = obj.tableColorCode;
        this.tableStatus.tableId = obj.tableId;
        this.tableStatus.tableLimitType = obj.tableLimitType;
        this.tableStatus.tableName = obj.tableName;
        this.tableStatus.tableTypeId = obj.tableTypeId;
        this.tableStatus.tbtMaxTime = obj.tbtMaxTime;
        this.tableStatus.tourneyBuyIn = obj.tourneyBuyIn;
        this.tableStatus.tourneyEntryFee = obj.tourneyEntryFee;
        this.tableStatus.tourneyGameStatus = obj.tourneyGameStatus;
        this.tableStatus.trnyChips = obj.trnyChips;
        this.tableStatus.waitListCount = obj.waitListCount;
        this.tableStatus.watchersCount = obj.watchersCount;
        this.tableStatus.protectionLevel = obj.protectionLevel;
        this.tableStatus.challengeType = obj.challengeType;
        this.tableStatus.tournamentCategory = obj.tournamentCategory;
        this.tableStatus.bountyFee = obj.bountyFee;
        this.tableStatus.tableProfileType = obj.tableProfileType;
        this.tableStatus.trnyMixMaxType = obj.trnyMixMaxType;
        this.tableStatus.balanceThreshold = obj.balanceThreshold;
        this.tableStatus.rebuyAddonTrnyTable = obj.rebuyAddonTrnyTable;
        this.tableStatus.realNameTable = obj.realNameTable;
        this.tableStatus.athApplicable = obj.athApplicable;
    };
    setPoolAvailabilityResponse = function (obj) {
        // obj.userBalance;
        //  obj.fXConversionFactor;
        //  obj.poolAvailabiltyStatus;
        //this.tableStatus = obj;
        this.tableStatus.maxBuyInAmount = obj.maxBuyin;
        this.tableStatus.minBuyInAmount = obj.minBuyin;
        //obj.poolName;
        this.tableStatus.tableTypeId = obj.tableTypeId;
        this.tableStatus.gameTypeId = obj.gameTypeId;
        this.tableStatus.maxNoOfSeats = obj.maxNoOfSeats;
        this.tableStatus.stakesLowerLimit = obj.stakesLowerLimit;
        this.tableStatus.stakesUpperLimit = obj.stakesUpperLimit;
        this.tableStatus.tableLimitType = obj.tableLimitType;
        this.tableStatus.smallBlind = obj.smallBlind;
        this.tableStatus.bigBlind = obj.bigBlind;
        this.tableStatus.gameCurrency = obj.gameCurrency;
        this.tableStatus.groupId = obj.groupId;
        this.tableStatus.huTableType = obj.HUTabletype;
        this.tableStatus.tableCategory = obj.tableCategory;
        this.tableStatus.tableName = obj.poolName;
    };
    getTableLimitType = function () {
        return this.tableStatus.tableLimitType;
    };
    getBigBlind = function () {
        return this.tableStatus.bigBlind;
    };
    getSmallBlind = function () {
        return this.tableStatus.smallBlind;
    };
    getTableCategory = function () {
        return this.tableStatus.tableCategory;
    };
    getTournamentCategory = function () {
        return this.tableStatus.tournamentCategory;
    };
    getMaxNoOfSeats = function () {
        return this.tableStatus.maxNoOfSeats;
    };
    getGameTypeId = function () {
        return this.tableStatus.gameTypeId;
    };
    getGameCurrency = function () {
        return this.tableStatus.gameCurrency;
    };
    ChangePlayerState = function (obj) {
        this.changePlayerSeatNo = obj.seatNo;
        this.changePlayerStateInfo = obj.state;
        /*PLAYERSTATE_SIT_OUT        1
      PLAYERSTATE_SIT_IN        2
      PLAYERSTATE_ALL_IN        3
      PLAYERSTATE_FOLDED        4
      PLAYERSTATE_AUTOPOST_BLIND_AND_FOLD    5
      PLAYERSTATE_ALL_IN_NOTRESPONDING        7
      PLAYERSTATE_NOT_IN_GAME    9*/
    };
    setUncalledBetPotNo = function (potNumber) {
        this.uncalledPotNumber = potNumber;
    };
    getUncalledBetPotNo = function () {
        return this.uncalledPotNumber;
    };
    getPlayerStateData = function () {
        return {
            seatNo: this.changePlayerSeatNo,
            state: this.changePlayerStateInfo,
        };
    };
    updatePlayerCount = function () {
        this.tableStatus.playerCount = this.tableStatus.playerCount - 1;
    };
    getPlayerCount = function () {
        return this.tableStatus.playerCount;
    };
    RemovePlayer = function (obj) {
        this.dontShowSeatOpen = obj.dontShowSeatOpen;
        this.isReserved = obj.isReserved;
        this.isSeatedPlayer = obj.isSeatedPlayer;
        //this.seatNumber = obj.seatNumber;
    };
    ReserveSeat = function (obj) {
        this.reserveSeatNumber = obj.seatNumber;
    };
    ShowCardsOptions = function (obj) {
        this.showCardsOptionId = obj.showCardsOptionId;
        this.timeOut = obj.timeOut;
    };
    TimeOut = function (msgTimeOut) {
        /*debugger;
      this.timePeriodToAct = msgTimeOut.period;
      this.timeoutSeatNo = msgTimeOut.seatNo;
      this.showClockAt = msgTimeOut.showClockAt;*/
        this.timeOutData = msgTimeOut;
        /*var timeout = msgTimeOut.period - ServerTimeSync.getServerTimeSyncForDomain(0).getCurrentServerTime();
      var seatNo = msgTimeOut.seatNo;
      var showClockAt = this.model.get('gameTableStatus').get("countDownTime");
      if (msgTimeOut.showClockAt > 0) {
          showClockAt = msgTimeOut.period - msgTimeOut.showClockAt;
      }
      console.log('handleTimeOut:: ' + Math.floor(timeout) +" : "+Math.floor(showClockAt));
      var pModel = this.view.seats.getPlayerBySeatNo(seatNo).model;
      if (pModel) {
          pModel.set({'timeout':Math.floor(timeout), 'showTimer':true, showClockAt:Math.floor(showClockAt)}, {silent:true});
          pModel.trigger(PokerEvents.SHOW_PLAYER_TIMER);
      }*/
    };
    setTimeOut = function (timeOut) {
        this.timeOutData = timeOut;
    };
    getCurrentTimerStatus = function () {
        return this.timeOutData;
    };
    WaitStatus = function (waitingNo, waitingStatus, waitingUsersCount) {
        this.waitingNo = waitingNo;
        this.waitingStatus = waitingStatus;
        //this.waitingUsersCount = waitingStatus;
        this.waitingUsersCount = waitingUsersCount;
    };
    PlayerDealCards = function (obj) {
        this.PlayerDealCardsInfo = obj.cards;
        this.playerDealseatingPosition = obj.seatingPosition;
    };
    Winner = function (obj) {
        this.winAmount = obj.amount;
        this.winTaxAmount = obj.taxAmount;
        this.winnerId = obj.winnerId;
    };
    WinnerCards = function (obj) {
        this.winnerSeatNo = obj.seatNo;
        this.winningCards = obj.winningCards;
    };
    getWinnerCards = function () {
        return {
            seatNo: this.winnerSeatNo,
            winningCards: this.winningCards,
        };
    };
    HighlightWinCards = function (obj) {
        //this.winnerSeatNo = obj.seatNo;
        this.winnersCards = obj.winnersCards;
    };
    //don't reset this data as this should remain persistent till close table
    getIsTableCreated = function () {
        return this.isTableCreated;
    };
    setIsTableCreated = function (obj) {
        return (this.isTableCreated = obj);
    };
    ShowCongrats = function (obj) {
        /*CONGRATS_TYPE_GEN_WINNER        1
      CONGRATS_TYPE_TOURNEY_FIRST        2
      CONGRATS_TYPE_TOURNEY_SECOND    3
      CONGRATS_TYPE_TOURNEY_THIRD        4
      CONGRATS_TYPE_PPM_FIRST         5*/
        this.congratsType = obj.congratsType;
        this.desc = obj.desc;
    };
    setCurrentShowingOption = function (showingOption) {
        this.showingOption = showingOption;
    };
    getCurrentShowingOption = function () {
        return this.showingOption;
    };

    getRoundCount = function () {
        return this.roundCount;
    };
    setAutoCheckEnabled = function (autoCheckEnabled) {
        this.autoCheckEnabled = autoCheckEnabled;
    };
    getAutoCheckEnabled = function () {
        return this.autoCheckEnabled;
    };
    getTableStatusData = function () {
        return this.tableStatus;
    };
    resetDataModel = function () {
        this.uncalledPotNumber = null;
        this.communityHand = null;
        this.currentStake = null;
        this.dealer = null;
        // this.tableStatus.gameId = null;
        this.jackpotRake = null;
        this.playersData = null;
        this.pots = null;
        this.rabbitCommunityCards = null;
        this.rake = null;
        this.reservedSeats = null;
        this.seatNo = null;
        this.trnyLevelInfo = null;
        this.trnyStartedAt = null;
        this.waitingNo = null;
        this.waitingStatus = null;
        this.waitingUsersCount = null;
        this.communityCardNo = null;
        this.communityCardType = null;
        this.communityCards = null;
        this.newPlayerBalance = null;
        this.newPlayerBrandId = null;
        this.newPlayerCity = null;
        this.newPlayerCountry = null;
        this.newPlayerImageUrl = null;
        this.newPlayerIsCustomAvatar = null;
        this.newPlayerIsSeatedPlayer = null;
        this.newPlayerPlayerId = null;
        this.newPlayerSeatingPosition = null;
        this.newPlayerSex = null;
        this.newPlayerAvatarImagePath = null;
        this.newPlayerAvatarImageName = null;
        this.newPlayerPlayerAccountId = null;
        this.newPlayerPlayerFullName = null;
        this.newPlayerPlayerRealName = null;
        this.activeSeatNo = null;
        this.dealerId = null;
        this.currentPotAmount = null;
        this.jackpotRake = null;
        this.rake = null;
        this.sidePots = {};
        this.dealerSeatNo = null;
        this.potNumber = null;
        this.winners = null;
        // this.tableStatus.activeFlag = null;
        // this.tableStatus.allowPlayerToSit = null;
        // this.tableStatus.ante = null;
        // this.tableStatus.autoLoad = null;
        // this.tableStatus.avgFlopSeen = null;
        // this.tableStatus.avgPot = null;
        // this.tableStatus.bigBlind = null;
        // this.tableStatus.bringIn = null;
        // this.tableStatus.casinoTrnyLevelInfo = null;
        // this.tableStatus.countDownTime = null;
        // this.tableStatus.dbTableInfoId = null;
        // this.tableStatus.disconProtectType = null;
        // this.tableStatus.familyId = null;
        // this.tableStatus.fppBuyIn = null;
        // this.tableStatus.gameCurrency = null;
        // this.tableStatus.gameId = null;
        // this.tableStatus.gamePlayType = null;
        // this.tableStatus.gameTypeId = null;
        // this.tableStatus.groupId = null;
        // this.tableStatus.handsPerHour = null;
        // this.tableStatus.huTableType = null;
        // this.tableStatus.isChampionshipTable = null;
        // this.tableStatus.isTBTRandomized = null;
        // this.tableStatus.jackpotId = null;
        // this.tableStatus.mtctBuyIn = null;
        // this.tableStatus.mtctId = null;
        // this.tableStatus.playerCount = null;
        // this.tableStatus.playersPerFlop = null;
        // this.tableStatus.pvtTableType = null;
        // this.tableStatus.reservedSeatsCount = null;
        // this.tableStatus.serverPeerId = null;
        // this.tableStatus.smallBlind = null;
        // this.tableStatus.stakesLowerLimit = null;
        // this.tableStatus.stakesUpperLimit = null;
        // this.tableStatus.tabIds = null;
        // this.tableStatus.tableColorCode = null;
        // this.tableStatus.tableId = null;
        // this.tableStatus.tableName = null;
        // this.tableStatus.tableTypeId = null;
        // this.tableStatus.tbtMaxTime = null;
        // this.tableStatus.tourneyBuyIn = null;
        // this.tableStatus.tourneyEntryFee = null;
        // this.tableStatus.trnyChips = null;
        // this.tableStatus.waitListCount = null;
        // this.tableStatus.watchersCount = null;
        // this.tableStatus.protectionLevel = null;
        // this.tableStatus.challengeType = null;
        // this.tableStatus.tournamentCategory = null;
        // this.tableStatus.bountyFee = null;
        // this.tableStatus.tableProfileType = null;
        // this.tableStatus.trnyMixMaxType = null;
        // this.tableStatus.balanceThreshold = null;
        // this.tableStatus.rebuyAddonTrnyTable = null;
        // this.tableStatus.realNameTable = null;
        this.changePlayerSeatNo = null;
        this.changePlayerStateInfo = null;
        this.dontShowSeatOpen = null;
        this.isReserved = null;
        this.isSeatedPlayer = null;
        this.reserveSeatNumber = null;
        this.showCardsOptionId = null;
        this.timeOut = null;
        this.timePeriodToAct = null;
        this.timeoutSeatNo = null;
        this.showClockAt = null;
        this.waitingNo = null;
        this.waitingStatus = null;
        this.PlayerDealCardsInfo = null;
        this.playerDealseatingPosition = null;
        this.winAmount = null;
        this.winTaxAmount = null;
        this.winnerId = null;
        this.winnerSeatNo = null;
        this.winningCards = null;
        this.congratsType = null;
        this.desc = null;
        this.roundCount = 0;
        this.autoCheckEnabled = null;
    };
}
