import { Message } from '../../message';

export class LoginSuccessResponse extends Message {
    private MESSAGE_NAME: string = 'LoginSuccessResponse';
    private ssoKey: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, ssoKey: string | null = null) {
        super(reqServerPeerId);
        this.ssoKey = ssoKey;
    }

    getSsoKey(): string | null {
        return this.ssoKey;
    }

    setSsoKey(ssoKey: string | null) {
        this.ssoKey = ssoKey;
    }
}
