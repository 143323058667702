<div *ngIf="item.youtubeData.items" class="youtubeWrapper">
    <div class="row no-gutters">
        <vn-pc-component-header-v2 class="col-auto" *ngIf="item.title" [item]="item"></vn-pc-component-header-v2>
    </div>
    <div class="row no-gutters">
        <div class="col-12 col-md-8 p-0">
            <ng-container *ngIf="videoItemsArr.length > 0">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item video-clip-player" [src]="videoClipUrl | trustAsResourceUrl" width="100%"></iframe>
                </div>
            </ng-container>
        </div>
        <div class="col-12 col-md-4 mt-0 p-0">
            <ng-container>
                <div class="w-100 videoListWrapper" *ngIf="videoItemsArr">
                    <div class="mb-1" *ngFor="let clip of videoItemsArr">
                        <a class="clipsListData media align-items-center" (click)="playVideoClip(clip)" [ngClass]="{ active: selectedClip === clip }">
                            <div class="video-thumb">
                                <button class="btn btn-outline-light btn-plpa">
                                    <i [ngClass]="selectedClip === clip ? 'theme-pause' : 'theme-play-solid'"></i>
                                </button>
                                <img [src]="clip.videoThumbnail" alt="Video Image" />
                            </div>
                            <div class="media-body p-2">
                                <h5 class="mt-0 mb-2">{{ clip.videoTitle }}</h5>
                                <span class="channel-name d-block">{{ _decodeUriComponent(clip.channelName) }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
