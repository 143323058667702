import { Message } from '../../message';

export class SetPlayerGameSessionLimitsRequest extends Message {
    private MESSAGE_NAME: string = 'SetPlayerGameSessionLimitsRequest';
    private maxSessionLimit: number = 0;
    private maxLossLimit: number = 0;
    private breakPeriod: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, maxSessionLimit: number = 0, maxLossLimit: number = 0, breakPeriod: number = 0) {
        super(reqServerPeerId);
        this.maxSessionLimit = maxSessionLimit;
        this.maxLossLimit = maxLossLimit;
        this.breakPeriod = breakPeriod;
    }

    getMaxSessionLimit(): number {
        return this.maxSessionLimit;
    }

    setMaxSessionLimit(maxSessionLimit: number) {
        this.maxSessionLimit = maxSessionLimit;
    }
    getMaxLossLimit(): number {
        return this.maxLossLimit;
    }

    setMaxLossLimit(maxLossLimit: number) {
        this.maxLossLimit = maxLossLimit;
    }
    getBreakPeriod(): number {
        return this.breakPeriod;
    }

    setBreakPeriod(breakPeriod: number) {
        this.breakPeriod = breakPeriod;
    }
}
