import { Routes } from '@angular/router';

import { PokerPartyPokerMillionSynopsisResolver } from './poker-partypokermillion-synopsis.resolver';
import { PokerPartyPokerMillionResolver } from './poker-partypokermillion.resolver';

export const pokerPartyPokerMillionRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPartyPokerMillionResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerPartyPokerMillionSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPartyPokerMillionResolver,
        },
    },
];
