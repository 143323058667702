<div class="textView-popup" id="text-view-popup" *ngIf="showTextPopup && roundTextView" [attr.roundId]="roundId">
    <div class="textView-popup-content">
        <div class="popup-heading-section">
            <h3>{{ gameTypes[roundTextView.game.gameType + roundTextView.game.limitType] }}</h3>
            <i class="close-text-view theme-ex" (click)="closeTextPopup()"></i>
        </div>
        <div class="hand-tag" [class.sevenCard]="roundTextView.game.gameType.indexOf('SevenCard') > -1">
            <i class="theme-left" [class.disabled]="prevDisabled || serve.singleHand" (click)="goToTextView('prev', $event)"></i>
            <i class="theme-right" [class.disabled]="nextDisabled || serve.singleHand" (click)="goToTextView('next', $event)"></i>
            <h4>{{ winnerNickName }} {{ serve.content.messages.Wins }}</h4>
            <p>
                <span *ngIf="!tournamentType" class="table-name">{{ roundTextView.tableName }} - </span>
                <span *ngIf="roundTextView.game.gameType.indexOf('SevenCard') <= -1" class="set-type"
                    ><span *ngIf="!tournamentType">{{ currency[roundTextView.anteInfo.smallBlind.monetaryType] }}</span
                    >{{ fixedToTwo(roundTextView.anteInfo.smallBlind.unit) }}/<span *ngIf="!tournamentType">{{
                        currency[roundTextView.anteInfo.bigBlind.monetaryType]
                    }}</span
                    >{{ fixedToTwo(roundTextView.anteInfo.bigBlind.unit) }}</span
                >
                <span *ngIf="roundTextView.game.gameType.indexOf('SevenCard') > -1" class="set-type"
                    ><span *ngIf="!tournamentType">{{ currency[roundTextView.anteInfo.smallBet.monetaryType] }}</span
                    >{{ fixedToTwo(roundTextView.anteInfo.smallBet.unit) }}/<span *ngIf="!tournamentType">{{
                        currency[roundTextView.anteInfo.bigBet.monetaryType]
                    }}</span
                    >{{ fixedToTwo(roundTextView.anteInfo.bigBet.unit) }}</span
                >
            </p>
            <p class="hand-time">
                {{ convertTime(roundTextView.gameRoundtime.start, 'datetime') }}
                <span>{{ roundTextView.gameRoundtime.start | date: 'zzzz' }}</span>
                <!-- <span>{{convertTime(roundTextView.gameRoundtime.start, 'timezone')}}</span> -->
            </p>
            <p class="handId">
                {{ serve.content.messages.Habd }}# <span>{{ handId }}</span>
            </p>
        </div>
        <div class="text-view-details">
            <h5 class="details-heading">{{ serve.content.messages.PlayerList }}</h5>
            <ul>
                <li *ngFor="let player of roundTextView.roundPlayers">
                    {{ player.nickName }} (<span *ngIf="!tournamentType">{{ currency[player.startMoney.monetaryType] }}</span
                    >{{ fixedToTwo(player.startMoney.unit) }})
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(anteBlindEvents)">
            <h5 class="details-heading">{{ serve.content.messages.AnteBlinds }}</h5>
            <ul>
                <li *ngFor="let anteblind of anteBlindEvents">
                    <span *ngIf="anteblind.handType === 'CARD_HIDDEN'">{{ events[anteblind.handType] }} </span>{{ roundPlayers[anteblind.seat] }}
                    <span *ngIf="anteblind.handType !== 'CARD_HIDDEN'"
                        >{{ events[anteblind.handType] }} <span *ngIf="!tournamentType">{{ currency[anteblind.amount.monetaryType] }}</span
                        >{{ fixedToTwo(anteblind.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(anteblind)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="anteblind.handType === 'CARD_HIDDEN'"> [{{ anteblind.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(preFlopEvents)">
            <h5 class="details-heading">{{ serve.content.messages.PreFlop }}</h5>
            <ul>
                <li *ngFor="let preflopevent of preFlopEvents">
                    <span *ngIf="preflopevent.handType === 'CARD_HIDDEN'">{{ events[preflopevent.handType] }} </span
                    ><span *ngIf="events.hasOwnProperty(preflopevent.handType)"
                        >{{ roundPlayers[preflopevent.seat] }}
                        <span *ngIf="preflopevent.handType !== 'CARD_HIDDEN'">{{ events[preflopevent.handType] }}</span>
                        <span *ngIf="toggleAmount(preflopevent)"
                            ><span *ngIf="!tournamentType">{{ currency[preflopevent.amount.monetaryType] }}</span
                            >{{ fixedToTwo(preflopevent.amount.unit) }}</span
                        ><span *ngIf="toggleAllIn(preflopevent)" class="nowrap">
                            {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                        ><span *ngIf="preflopevent.handType === 'CARD_HIDDEN'"> [{{ preflopevent.cards.join(', ') }}]</span></span
                    >
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="flopEvents && flopCommunityCards && flopCommunityCards.length > 0">
            <h5 class="details-heading">
                {{ serve.content.messages.Flop
                }}<span *ngIf="flopCommunityCards && flopCommunityCards.length > 0" class="nowrap"> [{{ flopCommunityCards.join(', ') }}]</span>
            </h5>
            <ul *ngIf="flopEvents && flopEvents.length > 0">
                <li *ngFor="let flopevent of flopEvents">
                    <span *ngIf="flopevent.handType === 'CARD_HIDDEN'">{{ events[flopevent.handType] }} </span>{{ roundPlayers[flopevent.seat] }}
                    <span *ngIf="flopevent.handType !== 'CARD_HIDDEN'">{{ events[flopevent.handType] }}</span>
                    <span *ngIf="toggleAmount(flopevent)"
                        ><span *ngIf="!tournamentType">{{ currency[flopevent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(flopevent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(flopevent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="flopevent.handType === 'CARD_HIDDEN'"> [{{ flopevent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="turnEvents && turnCommunityCards && turnCommunityCards.length > 0">
            <h5 class="details-heading">
                {{ serve.content.messages.Turn
                }}<span *ngIf="turnCommunityCards && turnCommunityCards.length > 0" class="nowrap">
                    [{{ flopCommunityCards.join(', ') }}, {{ turnCommunityCards.join(', ') }}]</span
                >
            </h5>
            <ul *ngIf="turnEvents && turnEvents.length > 0">
                <li *ngFor="let turnEvent of turnEvents">
                    <span *ngIf="turnEvent.handType === 'CARD_HIDDEN'">{{ events[turnEvent.handType] }} </span>{{ roundPlayers[turnEvent.seat] }}
                    <span *ngIf="turnEvent.handType !== 'CARD_HIDDEN'">{{ events[turnEvent.handType] }}</span>
                    <span *ngIf="toggleAmount(turnEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[turnEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(turnEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(turnEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="turnEvent.handType === 'CARD_HIDDEN'"> [{{ turnEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="riverEvents && riverCommunityCards && riverCommunityCards.length > 0">
            <h5 class="details-heading">
                {{ serve.content.messages.River
                }}<span *ngIf="riverCommunityCards && riverCommunityCards.length > 0" class="nowrap">
                    [{{ flopCommunityCards.join(', ') }}, {{ turnCommunityCards.join(', ') }}, {{ riverCommunityCards.join(', ') }}]</span
                >
            </h5>
            <ul *ngIf="riverEvents && riverEvents.length > 0">
                <li *ngFor="let riverEvent of riverEvents">
                    <span *ngIf="riverEvent.handType === 'CARD_HIDDEN'">{{ events[riverEvent.handType] }} </span>{{ roundPlayers[riverEvent.seat] }}
                    <span *ngIf="riverEvent.handType !== 'CARD_HIDDEN'">{{ events[riverEvent.handType] }}</span>
                    <span *ngIf="toggleAmount(riverEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[riverEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(riverEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(riverEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="riverEvent.handType === 'CARD_HIDDEN'"> [{{ riverEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(thirdStreetEvents)">
            <h5 class="details-heading">{{ serve.content.messages.ThirdStreet }}</h5>
            <ul>
                <li *ngFor="let thirdStreetEvent of thirdStreetEvents">
                    <span *ngIf="thirdStreetEvent.handType === 'CARD_HIDDEN'">{{ events[thirdStreetEvent.handType] }} </span
                    >{{ roundPlayers[thirdStreetEvent.seat] }}
                    <span *ngIf="thirdStreetEvent.handType !== 'CARD_HIDDEN'">{{ events[thirdStreetEvent.handType] }}</span>
                    <span *ngIf="thirdStreetEvent.handType === 'CARD_SHOWN'">[{{ thirdStreetEvent.card }}]</span
                    ><span *ngIf="toggleAmount(thirdStreetEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[thirdStreetEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(thirdStreetEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(thirdStreetEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="thirdStreetEvent.handType === 'CARD_HIDDEN'"> [{{ thirdStreetEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(fourthStreetEvents)">
            <h5 class="details-heading">{{ serve.content.messages.FourthStreet }}</h5>
            <ul>
                <li *ngFor="let fourthStreetEvent of fourthStreetEvents">
                    <span *ngIf="fourthStreetEvent.handType === 'CARD_HIDDEN'">{{ events[fourthStreetEvent.handType] }} </span
                    >{{ roundPlayers[fourthStreetEvent.seat] }}
                    <span *ngIf="fourthStreetEvent.handType !== 'CARD_HIDDEN'">{{ events[fourthStreetEvent.handType] }}</span>
                    <span *ngIf="fourthStreetEvent.handType === 'CARD_SHOWN'">[{{ fourthStreetEvent.card }}]</span
                    ><span *ngIf="toggleAmount(fourthStreetEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[fourthStreetEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(fourthStreetEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(fourthStreetEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="fourthStreetEvent.handType === 'CARD_HIDDEN'"> [{{ fourthStreetEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(fifthStreetEvents)">
            <h5 class="details-heading">{{ serve.content.messages.FifthStreet }}</h5>
            <ul>
                <li *ngFor="let fifthStreetEvent of fifthStreetEvents">
                    <span *ngIf="fifthStreetEvent.handType === 'CARD_HIDDEN'">{{ events[fifthStreetEvent.handType] }} </span
                    >{{ roundPlayers[fifthStreetEvent.seat] }}
                    <span *ngIf="fifthStreetEvent.handType !== 'CARD_HIDDEN'">{{ events[fifthStreetEvent.handType] }}</span>
                    <span *ngIf="fifthStreetEvent.handType === 'CARD_SHOWN'">[{{ fifthStreetEvent.card }}]</span
                    ><span *ngIf="toggleAmount(fifthStreetEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[fifthStreetEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(fifthStreetEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(fifthStreetEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="fifthStreetEvent.handType === 'CARD_HIDDEN'"> [{{ fifthStreetEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(sixthStreetEvents)">
            <h5 class="details-heading">{{ serve.content.messages.SixthStreet }}</h5>
            <ul>
                <li *ngFor="let sixthStreetEvent of sixthStreetEvents">
                    <span *ngIf="sixthStreetEvent.handType === 'CARD_HIDDEN'">{{ events[sixthStreetEvent.handType] }} </span
                    >{{ roundPlayers[sixthStreetEvent.seat] }}
                    <span *ngIf="sixthStreetEvent.handType !== 'CARD_HIDDEN'">{{ events[sixthStreetEvent.handType] }}</span>
                    <span *ngIf="sixthStreetEvent.handType === 'CARD_SHOWN'">[{{ sixthStreetEvent.card }}]</span
                    ><span *ngIf="toggleAmount(sixthStreetEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[sixthStreetEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(sixthStreetEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(sixthStreetEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="sixthStreetEvent.handType === 'CARD_HIDDEN'"> [{{ sixthStreetEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(seventhStreetEvents)">
            <h5 class="details-heading">{{ serve.content.messages.SeventhStreet }}</h5>
            <ul>
                <li *ngFor="let seventhStreetEvent of seventhStreetEvents">
                    <span *ngIf="seventhStreetEvent.handType === 'CARD_HIDDEN'">{{ events[seventhStreetEvent.handType] }} </span
                    >{{ roundPlayers[seventhStreetEvent.seat] }}
                    <span *ngIf="seventhStreetEvent.handType !== 'CARD_HIDDEN'">{{ events[seventhStreetEvent.handType] }}</span>
                    <span *ngIf="seventhStreetEvent.handType === 'CARD_SHOWN'">[{{ seventhStreetEvent.card }}]</span
                    ><span *ngIf="toggleAmount(seventhStreetEvent)"
                        ><span *ngIf="!tournamentType">{{ currency[seventhStreetEvent.amount.monetaryType] }}</span
                        >{{ fixedToTwo(seventhStreetEvent.amount.unit) }}</span
                    ><span *ngIf="toggleAllIn(seventhStreetEvent)" class="nowrap">
                        {{ serve.content.messages.AndIs }} "{{ serve.content.messages.AllIn }}"</span
                    ><span *ngIf="seventhStreetEvent.handType === 'CARD_HIDDEN'"> [{{ seventhStreetEvent.cards.join(', ') }}]</span>
                </li>
            </ul>
        </div>
        <div class="text-view-details" *ngIf="checkEvents(showdownEvents)">
            <h5 class="details-heading">{{ serve.content.messages.ShowDown }}</h5>
            <ul>
                <ng-container *ngFor="let showdownEvent of showdownEvents">
                    <li *ngIf="showdownEvent.handType.indexOf('WIN') <= -1">
                        {{ roundPlayers[showdownEvent.seat]
                        }}<span *ngIf="!toggleShowDown(showdownEvent, 'wins')"> {{ events[showdownEvent.handType] }}</span>
                        <span *ngIf="toggleShowDown(showdownEvent, 'amount')"
                            ><span *ngIf="!tournamentType">{{ currency[showdownEvent.amount.monetaryType] }}</span
                            ><span>{{ fixedToTwo(showdownEvent.amount.unit) }}</span></span
                        >
                        <span
                            *ngIf="showdownEvent.handType === 'PLAYER_SHOW_CARDS' && roundPlayers[showdownEvent.seat + 'playerHoleCards']"
                            class="nowrap"
                            >[{{ roundPlayers[showdownEvent.seat + 'playerHoleCards'].join(', ') }}]</span
                        >
                    </li>
                </ng-container>
                <ng-container *ngIf="winners.length > 0">
                    <li *ngFor="let winner of winners">
                        {{ roundPlayers[winner.seat] }} {{ events[winner.handType] }}
                        <span *ngIf="toggleShowDown(winner, 'amount')"
                            ><span *ngIf="!tournamentType">{{ currency[winner.amount.monetaryType] }}</span
                            ><span>{{ fixedToTwo(winner.amount.unit) }}</span></span
                        >
                        <span *ngIf="winner.potId === 0" class="nowrap">{{ serve.content.messages.MainPot }}</span
                        ><span *ngIf="winner.potId !== 0" class="nowrap">{{ serve.content.messages.SidePot }} {{ winner.potId }}</span>
                        <span *ngIf="roundPlayers[winner.seat + 'hhrankInfo']"
                            >{{ serve.content.messages.With }}
                            <span class="winner-rank nowrap">{{ roundPlayers[winner.seat + 'hhrankInfo'] }}</span></span
                        >
                    </li>
                </ng-container>
                <li *ngFor="let roundPlayer of roundTextView.roundPlayers">
                    <span
                        *ngIf="
                            roundPlayers[roundPlayer.seat + 'winAmount'] !== 0 &&
                            roundPlayer.bet.unit + roundPlayer.net.unit > roundPlayers[roundPlayer.seat + 'winAmount']
                        "
                        >{{ roundPlayers[roundPlayer.seat] }} {{ events['WIN_HIGH'] }}
                        <span *ngIf="!tournamentType">{{ currency[roundPlayer.bet.monetaryType] }}</span
                        >{{ fixedToTwo(roundPlayer.bet.unit + roundPlayer.net.unit - roundPlayers[roundPlayer.seat + 'winAmount']) }}
                        <span class="nowrap"
                            >{{ serve.content.messages.SidePot }} {{ roundTextView.pots[roundTextView.pots.length - 1].id + 1 }}</span
                        >
                        <span *ngIf="roundPlayers[roundPlayer.seat + 'hhrankInfo']"
                            >{{ serve.content.messages.With }}
                            <span class="winner-rank nowrap">{{ roundPlayers[roundPlayer.seat + 'hhrankInfo'] }}</span></span
                        ></span
                    >
                </li>
            </ul>
        </div>
        <div class="text-view-details">
            <h5 class="details-heading">{{ serve.content.messages.Summary }}</h5>
            <ul>
                <li *ngIf="roundTextView.game.gameType.indexOf('SevenCard') <= -1">
                    {{ serve.content.messages.Dealer }}: {{ roundPlayers[roundTextView.dealerSeat] }}
                </li>
                <li *ngFor="let pot of roundTextView.pots">
                    <span *ngIf="pot.potType === 'MainPot'"
                        >{{ serve.content.messages.MainPotTag }}: <span *ngIf="!tournamentType">{{ currency[pot.amount.monetaryType] }}</span
                        >{{ fixedToTwo(pot.amount.unit) }}</span
                    >
                </li>
                <li *ngFor="let pot of roundTextView.pots">
                    <span *ngIf="pot.potType === 'SidePot'"
                        >{{ serve.content.messages.SidePotTag }} {{ pot.id }}:
                        <span *ngIf="!tournamentType">{{ currency[pot.amount.monetaryType] }}</span
                        >{{ fixedToTwo(pot.amount.unit) }}</span
                    >
                </li>
                <li *ngFor="let pot of uncalledSidePots">
                    <span *ngIf="pot.potId > 0">{{ serve.content.messages.SidePotTag }} {{ pot.potId }}: </span
                    ><span *ngIf="pot.potId === 0">{{ serve.content.messages.MainPotTag }}: </span
                    ><span *ngIf="!roundTextView.tourneyInfo.tournamentId">{{ currency[pot.amount.monetaryType] }}</span
                    >{{ fixedToTwo(pot.amount.unit) }}
                </li>
                <li *ngIf="!tournamentType && roundTextView.rake && roundTextView.rake.unit > 0">
                    {{ serve.content.messages.Rake }}: <span *ngIf="!tournamentType">{{ currency[roundTextView.rake.monetaryType] }}</span
                    >{{ fixedToTwo(roundTextView.rake.unit) }}
                </li>
                <li *ngFor="let roundPlayer of roundTextView.roundPlayers">
                    <span *ngIf="toggleSummary(roundPlayer, 'playerDetails')"
                        >{{ roundPlayer.nickName }}
                        <span *ngIf="toggleSummary(roundPlayer, 'winnerStatus')"
                            >{{ serve.content.messages.Bets }} <span *ngIf="!tournamentType">{{ currency[roundPlayer.bet.monetaryType] }}</span
                            >{{ fixedToTwo(roundPlayer.bet.unit) }}, {{ serve.content.messages.Collects }}
                            <span *ngIf="!tournamentType">{{ currency[roundPlayer.net.monetaryType] }}</span
                            >{{ fixedToTwo(roundPlayer.bet.unit + roundPlayer.net.unit) }},
                            <span *ngIf="roundPlayer.net.unit >= 0"
                                >{{ serve.content.messages.Net }} <span *ngIf="!tournamentType">{{ currency[roundPlayer.net.monetaryType] }}</span
                                >{{ fixedToTwo(roundPlayer.net.unit) }}</span
                            ></span
                        >
                        <span *ngIf="roundPlayer.net.unit < 0"
                            >{{ serve.content.messages.Loses }} -<span *ngIf="!tournamentType">{{ currency[roundPlayer.net.monetaryType] }}</span
                            >{{ (-roundPlayer.net.unit / 100).toFixed(2) }}</span
                        >
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
