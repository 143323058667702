import { Message } from '../../message';

export class SNGJPWinner extends Message {
    private MESSAGE_NAME: string = 'SNGJPWinner';
    private feedId: number = 0;
    private screenName: string | null = null;
    private winningAmount: number = 0;
    private winningType: number = 0;
    private gameCurrency: string | null = null;
    private buyIn: number = 0;
    private multiplier: number = 0;
    private awardTime: number = 0;
    private cardPropertiesId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        feedId: number = 0,
        screenName: string | null = null,
        winningAmount: number = 0,
        winningType: number = 0,
        gameCurrency: string | null = null,
        buyIn: number = 0,
        multiplier: number = 0,
        awardTime: number = 0,
        cardPropertiesId: number = 0,
    ) {
        super(reqServerPeerId);
        this.feedId = feedId;
        this.screenName = screenName;
        this.winningAmount = winningAmount;
        this.winningType = winningType;
        this.gameCurrency = gameCurrency;
        this.buyIn = buyIn;
        this.multiplier = multiplier;
        this.awardTime = awardTime;
        this.cardPropertiesId = cardPropertiesId;
    }

    getFeedId(): number {
        return this.feedId;
    }

    setFeedId(feedId: number) {
        this.feedId = feedId;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getWinningAmount(): number {
        return this.winningAmount;
    }

    setWinningAmount(winningAmount: number) {
        this.winningAmount = winningAmount;
    }
    getWinningType(): number {
        return this.winningType;
    }

    setWinningType(winningType: number) {
        this.winningType = winningType;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getMultiplier(): number {
        return this.multiplier;
    }

    setMultiplier(multiplier: number) {
        this.multiplier = multiplier;
    }
    getAwardTime(): number {
        return this.awardTime;
    }

    setAwardTime(awardTime: number) {
        this.awardTime = awardTime;
    }
    getCardPropertiesId(): number {
        return this.cardPropertiesId;
    }

    setCardPropertiesId(cardPropertiesId: number) {
        this.cardPropertiesId = cardPropertiesId;
    }
}
