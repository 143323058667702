<div class="col d-flex flex-wrap mb-5 ppl-wrapper ppl-gallery">
    <h1 *ngIf="contentType !== 'album'" class="w-100 text-uppercase pl-2 pr-2 pr-sm-0 pl-sm-0">
        {{ pageTitle }}
    </h1>
    <div class="col-xs-12 d-flex flex-wrap" *ngIf="galleryItems.length !== 0">
        <div
            *ngFor="let categoryImage of galleryItems; index as i"
            (click)="
                galleryLayer !== 'layer2'
                    ? goToItemDetails(
                          categoryImage,
                          categoryImage.hasOwnProperty('properties') && categoryImage.properties.length !== 0 ? true : false,
                          contentType
                      )
                    : null
            "
            class="col-sm-6 col-md-4 col-lg-3 mb-2 mb-sm-4 ppl-event-thumbnail"
            [ngClass]="{ 'col-6': galleryLayer === 'layer2', 'col-12': galleryLayer !== 'layer2' }">
            <div class="ppl-thumbnail-img">
                <img
                    class="w-100 rounded"
                    *ngIf="galleryLayer !== 'layer2'; else layerTwo"
                    [src]="categoryImage.eventImage.src"
                    [alt]="categoryImage.eventImage.alt" />
                <ng-template #layerTwo>
                    <img
                        *ngIf="categoryImage.data"
                        class="rounded galleryLayer2"
                        [src]="categoryImage.data.thumb"
                        [alt]=""
                        (click)="openModal(); currentSlide(i + 1)" />
                </ng-template>
            </div>
            <h3 class="ppl-thumbnail-title" *ngIf="categoryImage.hasOwnProperty('eventTitle')">
                {{ categoryImage.eventTitle }}
            </h3>
        </div>
    </div>
    <div *ngIf="galleryItems.length === 0" class="d-block w-100 text-center mt-5 mb-5">
        {{ item.pplGalleryStaticData.errorMessage }}
    </div>
</div>

<div id="imgModal" [style.display]="isModalVisible ? 'block' : 'none'" class="slide-modal-wrapper">
    <div class="img-modal-block">
        <div class="slide-modal">
            <span class="ui-icon ui-icon-size-lg theme-ex popup-close" (click)="closeModal()"></span>
            <div class="slide-modal-content">
                <div class="img-slides" *ngFor="let image of galleryItems; let i = index">
                    <img src="{{ image?.data?.thumb }}" alt="Gallery Image" class="main-img" />
                </div>
                <a class="prev" (click)="plusSlides(-1)"><span class="ui-icon ui-icon-size-lg theme-left"></span></a>
                <a class="next" (click)="plusSlides(1)"><span class="ui-icon ui-icon-size-lg theme-right"></span></a>
            </div>
        </div>
        <div class="thumbnail-container">
            <div class="thumbnails" #thumbnailContainer>
                <div class="image-column" *ngFor="let image of galleryItems; let i = index">
                    <img class="thumbnail" (click)="currentSlide(i + 1)" src="{{ image?.data?.thumb }}" alt="Current Slide" />
                </div>
            </div>
        </div>
    </div>
</div>
