import { Message } from '../../message';

export class LSTableInfoDetails extends Message {
    private MESSAGE_NAME: string = 'LSTableInfoDetails';
    private tableInfoId: number = 0;
    private tableTypeId: number = 0;
    private tableCategory: number = 0;
    private groupId: number = 0;
    private maxNoOfSeats: number = 0;
    private gameTypeId: number = 0;
    private stakesLowerLimit: number = 0;
    private stakesUpperLimit: number = 0;
    private tourneyBuyIn: number = 0;
    private tourneyEntryFee: number = 0;
    private tableLimitType: number = 0;
    private minBuyInAmount: number = 0;
    private maxBuyInAmount: number = 0;
    private smallBlind: number = 0;
    private bigBlind: number = 0;
    private ante: number = 0;
    private bringIn: number = 0;
    private autoLoad: boolean | null = false;
    private allowPlayerToSit: boolean | null = false;
    private tabIds: any[] | null = null;
    private disconProtectType: number = 0;
    private jackpotId: number = 0;
    private countDownTime: number = 0;
    private tableColorCode: number = 0;
    private sttCountryRestrictionId: number = 0;
    private pvtTableType: number = 0;
    private gamePlayType: number = 0;
    private familyId: number = 0;
    private fppBuyIn: number = 0;
    private tbtMaxTime: number = 0;
    private isTBTRandomized: boolean | null = false;
    private trnyChips: number = 0;
    private gameCurrency: string | null = null;
    private isChampionshipTable: boolean | null = false;
    private huTableType: number = 0;
    private brandIdList: any[] | null = null;
    private isBeginner: boolean | null = false;
    private protectionLevel: number = 0;
    private tableProfileType: number = 0;
    private featuredTableImageUrl: string | null = null;
    private featuredTableFeedOrder: number = 0;
    private ffPool: boolean | null = false;
    private trickettsRoom: boolean | null = false;
    private subLiquidityType: number = 0;
    private realNameTable: boolean | null = false;
    private anteCashAmount: number = 0;
    private restrictedRoomId: number = 0;
    private clubId: number = 0;
    private tablesCount: number = 0;
    private playersCount: number = 0;
    private name: string | null = null;
    private groupingDisbaled: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableInfoId: number = 0,
        tableTypeId: number = 0,
        tableCategory: number = 0,
        groupId: number = 0,
        maxNoOfSeats: number = 0,
        gameTypeId: number = 0,
        stakesLowerLimit: number = 0,
        stakesUpperLimit: number = 0,
        tourneyBuyIn: number = 0,
        tourneyEntryFee: number = 0,
        tableLimitType: number = 0,
        minBuyInAmount: number = 0,
        maxBuyInAmount: number = 0,
        smallBlind: number = 0,
        bigBlind: number = 0,
        ante: number = 0,
        bringIn: number = 0,
        autoLoad: boolean | null = false,
        allowPlayerToSit: boolean | null = false,
        tabIds: any[] | null = null,
        disconProtectType: number = 0,
        jackpotId: number = 0,
        countDownTime: number = 0,
        tableColorCode: number = 0,
        sttCountryRestrictionId: number = 0,
        pvtTableType: number = 0,
        gamePlayType: number = 0,
        familyId: number = 0,
        fppBuyIn: number = 0,
        tbtMaxTime: number = 0,
        isTBTRandomized: boolean | null = false,
        trnyChips: number = 0,
        gameCurrency: string | null = null,
        isChampionshipTable: boolean | null = false,
        huTableType: number = 0,
        brandIdList: any[] | null = null,
        isBeginner: boolean | null = false,
        protectionLevel: number = 0,
        tableProfileType: number = 0,
        featuredTableImageUrl: string | null = null,
        featuredTableFeedOrder: number = 0,
        ffPool: boolean | null = false,
        trickettsRoom: boolean | null = false,
        subLiquidityType: number = 0,
        realNameTable: boolean | null = false,
        anteCashAmount: number = 0,
        restrictedRoomId: number = 0,
        clubId: number = 0,
        tablesCount: number = 0,
        playersCount: number = 0,
        name: string | null = null,
        groupingDisbaled: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.tableInfoId = tableInfoId;
        this.tableTypeId = tableTypeId;
        this.tableCategory = tableCategory;
        this.groupId = groupId;
        this.maxNoOfSeats = maxNoOfSeats;
        this.gameTypeId = gameTypeId;
        this.stakesLowerLimit = stakesLowerLimit;
        this.stakesUpperLimit = stakesUpperLimit;
        this.tourneyBuyIn = tourneyBuyIn;
        this.tourneyEntryFee = tourneyEntryFee;
        this.tableLimitType = tableLimitType;
        this.minBuyInAmount = minBuyInAmount;
        this.maxBuyInAmount = maxBuyInAmount;
        this.smallBlind = smallBlind;
        this.bigBlind = bigBlind;
        this.ante = ante;
        this.bringIn = bringIn;
        this.autoLoad = autoLoad;
        this.allowPlayerToSit = allowPlayerToSit;
        this.tabIds = tabIds;
        this.disconProtectType = disconProtectType;
        this.jackpotId = jackpotId;
        this.countDownTime = countDownTime;
        this.tableColorCode = tableColorCode;
        this.sttCountryRestrictionId = sttCountryRestrictionId;
        this.pvtTableType = pvtTableType;
        this.gamePlayType = gamePlayType;
        this.familyId = familyId;
        this.fppBuyIn = fppBuyIn;
        this.tbtMaxTime = tbtMaxTime;
        this.isTBTRandomized = isTBTRandomized;
        this.trnyChips = trnyChips;
        this.gameCurrency = gameCurrency;
        this.isChampionshipTable = isChampionshipTable;
        this.huTableType = huTableType;
        this.brandIdList = brandIdList;
        this.isBeginner = isBeginner;
        this.protectionLevel = protectionLevel;
        this.tableProfileType = tableProfileType;
        this.featuredTableImageUrl = featuredTableImageUrl;
        this.featuredTableFeedOrder = featuredTableFeedOrder;
        this.ffPool = ffPool;
        this.trickettsRoom = trickettsRoom;
        this.subLiquidityType = subLiquidityType;
        this.realNameTable = realNameTable;
        this.anteCashAmount = anteCashAmount;
        this.restrictedRoomId = restrictedRoomId;
        this.clubId = clubId;
        this.tablesCount = tablesCount;
        this.playersCount = playersCount;
        this.name = name;
        this.groupingDisbaled = groupingDisbaled;
    }

    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getTableTypeId(): number {
        return this.tableTypeId;
    }

    setTableTypeId(tableTypeId: number) {
        this.tableTypeId = tableTypeId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getGroupId(): number {
        return this.groupId;
    }

    setGroupId(groupId: number) {
        this.groupId = groupId;
    }
    getMaxNoOfSeats(): number {
        return this.maxNoOfSeats;
    }

    setMaxNoOfSeats(maxNoOfSeats: number) {
        this.maxNoOfSeats = maxNoOfSeats;
    }
    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getStakesLowerLimit(): number {
        return this.stakesLowerLimit;
    }

    setStakesLowerLimit(stakesLowerLimit: number) {
        this.stakesLowerLimit = stakesLowerLimit;
    }
    getStakesUpperLimit(): number {
        return this.stakesUpperLimit;
    }

    setStakesUpperLimit(stakesUpperLimit: number) {
        this.stakesUpperLimit = stakesUpperLimit;
    }
    getTourneyBuyIn(): number {
        return this.tourneyBuyIn;
    }

    setTourneyBuyIn(tourneyBuyIn: number) {
        this.tourneyBuyIn = tourneyBuyIn;
    }
    getTourneyEntryFee(): number {
        return this.tourneyEntryFee;
    }

    setTourneyEntryFee(tourneyEntryFee: number) {
        this.tourneyEntryFee = tourneyEntryFee;
    }
    getTableLimitType(): number {
        return this.tableLimitType;
    }

    setTableLimitType(tableLimitType: number) {
        this.tableLimitType = tableLimitType;
    }
    getMinBuyInAmount(): number {
        return this.minBuyInAmount;
    }

    setMinBuyInAmount(minBuyInAmount: number) {
        this.minBuyInAmount = minBuyInAmount;
    }
    getMaxBuyInAmount(): number {
        return this.maxBuyInAmount;
    }

    setMaxBuyInAmount(maxBuyInAmount: number) {
        this.maxBuyInAmount = maxBuyInAmount;
    }
    getSmallBlind(): number {
        return this.smallBlind;
    }

    setSmallBlind(smallBlind: number) {
        this.smallBlind = smallBlind;
    }
    getBigBlind(): number {
        return this.bigBlind;
    }

    setBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
    }
    getAnte(): number {
        return this.ante;
    }

    setAnte(ante: number) {
        this.ante = ante;
    }
    getBringIn(): number {
        return this.bringIn;
    }

    setBringIn(bringIn: number) {
        this.bringIn = bringIn;
    }
    getAutoLoad(): boolean | null {
        return this.autoLoad;
    }

    setAutoLoad(autoLoad: boolean | null) {
        this.autoLoad = autoLoad;
    }
    getAllowPlayerToSit(): boolean | null {
        return this.allowPlayerToSit;
    }

    setAllowPlayerToSit(allowPlayerToSit: boolean | null) {
        this.allowPlayerToSit = allowPlayerToSit;
    }
    getTabIds(): any[] | null {
        return this.tabIds;
    }

    setTabIds(tabIds: any[] | null) {
        this.tabIds = tabIds;
    }
    getDisconProtectType(): number {
        return this.disconProtectType;
    }

    setDisconProtectType(disconProtectType: number) {
        this.disconProtectType = disconProtectType;
    }
    getJackpotId(): number {
        return this.jackpotId;
    }

    setJackpotId(jackpotId: number) {
        this.jackpotId = jackpotId;
    }
    getCountDownTime(): number {
        return this.countDownTime;
    }

    setCountDownTime(countDownTime: number) {
        this.countDownTime = countDownTime;
    }
    getTableColorCode(): number {
        return this.tableColorCode;
    }

    setTableColorCode(tableColorCode: number) {
        this.tableColorCode = tableColorCode;
    }
    getSttCountryRestrictionId(): number {
        return this.sttCountryRestrictionId;
    }

    setSttCountryRestrictionId(sttCountryRestrictionId: number) {
        this.sttCountryRestrictionId = sttCountryRestrictionId;
    }
    getPvtTableType(): number {
        return this.pvtTableType;
    }

    setPvtTableType(pvtTableType: number) {
        this.pvtTableType = pvtTableType;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getFamilyId(): number {
        return this.familyId;
    }

    setFamilyId(familyId: number) {
        this.familyId = familyId;
    }
    getFppBuyIn(): number {
        return this.fppBuyIn;
    }

    setFppBuyIn(fppBuyIn: number) {
        this.fppBuyIn = fppBuyIn;
    }
    getTbtMaxTime(): number {
        return this.tbtMaxTime;
    }

    setTbtMaxTime(tbtMaxTime: number) {
        this.tbtMaxTime = tbtMaxTime;
    }
    getIsTBTRandomized(): boolean | null {
        return this.isTBTRandomized;
    }

    setIsTBTRandomized(isTBTRandomized: boolean | null) {
        this.isTBTRandomized = isTBTRandomized;
    }
    getTrnyChips(): number {
        return this.trnyChips;
    }

    setTrnyChips(trnyChips: number) {
        this.trnyChips = trnyChips;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getIsChampionshipTable(): boolean | null {
        return this.isChampionshipTable;
    }

    setIsChampionshipTable(isChampionshipTable: boolean | null) {
        this.isChampionshipTable = isChampionshipTable;
    }
    getHuTableType(): number {
        return this.huTableType;
    }

    setHuTableType(huTableType: number) {
        this.huTableType = huTableType;
    }
    getBrandIdList(): any[] | null {
        return this.brandIdList;
    }

    setBrandIdList(brandIdList: any[] | null) {
        this.brandIdList = brandIdList;
    }
    getIsBeginner(): boolean | null {
        return this.isBeginner;
    }

    setIsBeginner(isBeginner: boolean | null) {
        this.isBeginner = isBeginner;
    }
    getProtectionLevel(): number {
        return this.protectionLevel;
    }

    setProtectionLevel(protectionLevel: number) {
        this.protectionLevel = protectionLevel;
    }
    getTableProfileType(): number {
        return this.tableProfileType;
    }

    setTableProfileType(tableProfileType: number) {
        this.tableProfileType = tableProfileType;
    }
    getFeaturedTableImageUrl(): string | null {
        return this.featuredTableImageUrl;
    }

    setFeaturedTableImageUrl(featuredTableImageUrl: string | null) {
        this.featuredTableImageUrl = featuredTableImageUrl;
    }
    getFeaturedTableFeedOrder(): number {
        return this.featuredTableFeedOrder;
    }

    setFeaturedTableFeedOrder(featuredTableFeedOrder: number) {
        this.featuredTableFeedOrder = featuredTableFeedOrder;
    }
    getFfPool(): boolean | null {
        return this.ffPool;
    }

    setFfPool(ffPool: boolean | null) {
        this.ffPool = ffPool;
    }
    getTrickettsRoom(): boolean | null {
        return this.trickettsRoom;
    }

    setTrickettsRoom(trickettsRoom: boolean | null) {
        this.trickettsRoom = trickettsRoom;
    }
    getSubLiquidityType(): number {
        return this.subLiquidityType;
    }

    setSubLiquidityType(subLiquidityType: number) {
        this.subLiquidityType = subLiquidityType;
    }
    getRealNameTable(): boolean | null {
        return this.realNameTable;
    }

    setRealNameTable(realNameTable: boolean | null) {
        this.realNameTable = realNameTable;
    }
    getAnteCashAmount(): number {
        return this.anteCashAmount;
    }

    setAnteCashAmount(anteCashAmount: number) {
        this.anteCashAmount = anteCashAmount;
    }
    getRestrictedRoomId(): number {
        return this.restrictedRoomId;
    }

    setRestrictedRoomId(restrictedRoomId: number) {
        this.restrictedRoomId = restrictedRoomId;
    }
    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
    getTablesCount(): number {
        return this.tablesCount;
    }

    setTablesCount(tablesCount: number) {
        this.tablesCount = tablesCount;
    }
    getPlayersCount(): number {
        return this.playersCount;
    }

    setPlayersCount(playersCount: number) {
        this.playersCount = playersCount;
    }
    getName(): string | null {
        return this.name;
    }

    setName(name: string | null) {
        this.name = name;
    }
    getGroupingDisbaled(): boolean | null {
        return this.groupingDisbaled;
    }

    setGroupingDisbaled(groupingDisbaled: boolean | null) {
        this.groupingDisbaled = groupingDisbaled;
    }
}
