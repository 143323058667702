export const playerNames = [
    'Barterbike',
    'Dimensional',
    'Dragonpickle',
    'Drawingdeer',
    'Drawingreindeer',
    'Ruthlessrat',
    'Victoryparka',
    'Hungryprof',
    'Learnspace',
    'Perpetualpi',
    'Deermuscle',
    'Shortbread',
    'Flickitin',
    'Animangus',
    'Delicioustap',
    'Dillornament',
    'Footprintdog',
    'Impishland',
    'Nonchalentpo',
    'Optimalrate',
    'Optimismimp',
    'Rewardingpea',
    'Miraclestark',
    'Earringsclip',
    'Garagechange',
    'Guitarshrewd',
    'Patheticmayo',
    'Severethorny',
    'Beatmillion',
    'Greedyllama',
    'Jumpykarate',
    'Lilybandana',
    'Profitsloth',
    'Savouryhelm',
    'Beebop',
    'Echogently',
    'Eggnogfile',
    'Totalnewly',
    'Uglybridge',
    'Lunchlift',
    'Smilevest',
    'Rugbyway',
    'Bozoban',
    'PokerBlue',
    'Nodnow',
];
