import { Message } from '../../message';

export class BlockPlayerOnInvalidLocation extends Message {
    private MESSAGE_NAME: string = 'BlockPlayerOnInvalidLocation';
    private gameDetails: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, gameDetails: string | null = null) {
        super(reqServerPeerId);
        this.gameDetails = gameDetails;
    }

    getGameDetails(): string | null {
        return this.gameDetails;
    }

    setGameDetails(gameDetails: string | null) {
        this.gameDetails = gameDetails;
    }
}
