import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as psc from './ppl-schedule-config.component';

@Injectable({ providedIn: 'root' })
export class PPLScheduleConfigPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('PPLScheduleCustomConfigTemplate', psc.PPLScheduleConfigComponent);
        });
    }
}
