import { Routes } from '@angular/router';

import { PokerTheBigGameSynopsisResolver } from './poker-the-big-game-synopsis.resolver';
import { PokerTheBigGameResolver } from './poker-the-big-game.resolver';

export const pokerTheBigGameRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTheBigGameResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerTheBigGameSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTheBigGameResolver,
        },
    },
];
