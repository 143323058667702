<!-- Temp Wrapping DIVs -->
<div class="row" *ngIf="pokerTwitterFeed">
    <div class="col-12">
        <div class="social-feed-section">
            <div class="row no-gutters">
                <vn-pc-component-header-v2 class="col-auto" *ngIf="item?.title" [item]="item"></vn-pc-component-header-v2>
                <h3 *ngIf="item?.twitterProfileTitle" class="col-auto pl-0 m-0">
                    <a [href]="item.twitterProfileLink" target="_blank" class="color-primary">{{ item?.twitterProfileTitle }}</a>
                </h3>
            </div>
            <ul class="list-unstyled mt-4">
                <ng-container *ngFor="let tweet of pokerTwitterFeed">
                    <li class="mb-3 border-bottom tweet-item pb-3">
                        <a [href]="getTweetUrl(tweet.text) || getUserUrl(tweet.user.screen_name)" target="_blank" rel="noopener">
                            <div class="media">
                                <img
                                    class="d--block mr-3 rounded-circle"
                                    [src]="tweet.user.profile_image_url_https"
                                    width="48"
                                    height="48"
                                    alt="Profile image" />
                                <div class="media-body">
                                    <p class="mt-0 mb-1">
                                        <strong class="verified">{{ tweet.user.screen_name }}</strong>
                                        <span class="d-block">{{ tweet.user.name }}</span>
                                    </p>
                                </div>
                            </div>
                            <p class="mb-0 mt-2 tweetText">{{ tweet.text }}</p>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
