import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';

import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { PWCLoyaltyCashBackService } from '../../service/loyalty-cashBack.service';
import { userBalanceSelect, userTicketSelect } from '../../store/user.store';

@Component({
    selector: 'pwc-client-header-balance',
    templateUrl: 'client-header-balance.component.html',
    standalone: true,
    imports: [CommonModule, FormatamountPipe],
    providers: [],
})
export class ClientHeaderBalanceComponent implements OnInit {
    accountBalance = 0;
    accountCurrency = 'USD';
    tourneyCurrencyBalance = '0';
    userTickets: number = 0;
    balanceIcon: string;

    constructor(
        private store: Store,
        private pwcLoyaltyCashBackService: PWCLoyaltyCashBackService,
        private clientToServerService: ClientToServerRequestServices,
        private navigation: NavigationService,
        private sharedService: PokerWebClientSharedService,
    ) {
        this.balanceIcon = this.clientToServerService.webAssets['lobby']['common']['header-balance-icon']['image']['src'];
        this.store.select(userBalanceSelect).subscribe((userBalance) => {
            if (!_.isEmpty(userBalance) && !_.isEmpty(userBalance['netRealBalance'])) {
                this.accountCurrency = userBalance['netRealBalance']['accountCurrency'];
                this.accountBalance = userBalance['netRealBalance']['accountBalance'];
                this.tourneyCurrencyBalance = Intl.NumberFormat().format(
                    userBalance['tourneyCurrencyBalance'] != null ? userBalance['tourneyCurrencyBalance']['tourneyCurrencyBalance'] / 100 : 0,
                );
            }
        });
        this.store.select(userTicketSelect).subscribe((userTicket: number) => {
            if (userTicket) {
                this.userTickets = userTicket;
                // if (userTicket['MESSAGE_NAME'] === "UserFreeRollList") {
                //     this.userTickets = userTicket['freeRollIds'].length;
                // } else if (userTicket['MESSAGE_NAME'] === "RemovedFreeRoll") {
                //     this.userTickets = this.userTickets - userTicket['freeRollId'].length;
                // } else if (userTicket['MESSAGE_NAME'] === "AddedFreeRoll") {
                //     this.userTickets = userTicket['freeRollId'].length;
                // }
            }
        });
    }

    ngOnInit(): void {
        this.pwcLoyaltyCashBackService.weeklyPoints();
    }

    showBalanceBreakDown() {
        let data = {
            text: this.clientToServerService.externalLinks['balancebreakdown'].title,
            url: this.clientToServerService.externalLinks['balancebreakdown']['titleLink']['url'],
        };
        this.sharedService.sendPortalPage(data);
        this.navigation.goTo('/play/' + 'portal');
    }
}
