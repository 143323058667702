import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { LeaderBoardService } from './leaderboard.service';

@Injectable({ providedIn: 'root' })
export class LeaderBoardResolver implements Resolve<any> {
    constructor(private resource: LeaderBoardService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.resource.getLeaderBoardByID(route.paramMap.get('promoId') ?? '');
    }
}
