import { Message } from '../../message';

export class SemiFinalDay extends Message {
    private MESSAGE_NAME: string = 'SemiFinalDay';
    private date: string | null = null;
    private extraStartingChips: number = 0;
    private selected: boolean | null = false;
    private disable: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        date: string | null = null,
        extraStartingChips: number = 0,
        selected: boolean | null = false,
        disable: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.date = date;
        this.extraStartingChips = extraStartingChips;
        this.selected = selected;
        this.disable = disable;
    }

    getDate(): string | null {
        return this.date;
    }

    setDate(date: string | null) {
        this.date = date;
    }
    getExtraStartingChips(): number {
        return this.extraStartingChips;
    }

    setExtraStartingChips(extraStartingChips: number) {
        this.extraStartingChips = extraStartingChips;
    }
    getSelected(): boolean | null {
        return this.selected;
    }

    setSelected(selected: boolean | null) {
        this.selected = selected;
    }
    getDisable(): boolean | null {
        return this.disable;
    }

    setDisable(disable: boolean | null) {
        this.disable = disable;
    }
}
