<div class="card-body" *ngIf="activeItem">
    <h6>{{ activeItem.name }}</h6>
    <span>{{ activeItem.id }}</span>

    <div class="row">
        <div class="col-auto pr-md-0">
            {{ activeItem.timeLeft }}
        </div>
    </div>
    <button class="btn btn-dark btn-sm test-uppercase mt-2" (click)="openClient(activeItem)">
        {{ widgetConfig.register }}
    </button>
</div>
