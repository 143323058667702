import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DynamicHtmlDirective, PlainLinkComponent, TrackingService, UserService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { AttributeDirective } from './attribute.directive';

@Component({
    selector: 'pk-pp-promo-header',
    templateUrl: 'pp-promo-header.component.html',
    host: {
        '(window:resize)': 'onResize($event)',
    },
    standalone: true,
    imports: [TrustAsHtmlPipe, CommonModule, RouterModule, PlainLinkComponent, DynamicHtmlDirective, AttributeDirective],
})
export class PPPromoHeaderComponent extends PCComponent implements OnInit {
    constructor(
        private userService: UserService,
        private vanillaTrackingService: TrackingService,
    ) {
        super();
    }

    //mobileEval:boolean;
    mobileEval: string;
    attributesCta1 = {};
    attributesCta2 = {};
    promoCta: any;
    //    constructor(
    //        private vnDeviceConfig: vnDeviceConfig) {
    //            super();
    //         }
    @HostBinding()
    get class() {
        this.attributesCta1 = this.item?.ctA1Link?.attributes;
        this.attributesCta2 = this.item?.ctA2Link?.attributes;
        this.promoSingleCta();
        return this.createClass(this.item.name);
    }
    //eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit() {
        //this.mobileEval=(this.vnDeviceConfig.isMobile || this.vnDeviceConfig.isMobilePhone || this.vnDeviceConfig.isTablet || this.vnDeviceConfig.isTouch || window.innerWidth < 600);
    }

    ngAfterViewInit() {
        if (window.innerWidth > 950) {
            this.mobileEval = 'desktop';
        } else if (window.innerWidth > 600 && window.innerWidth < 950) {
            this.mobileEval = 'tablet';
        } else {
            this.mobileEval = 'mobile';
        }
    }

    promoSingleCta() {
        if (this.item.ctA1Link && this.item.ctA2Link) {
            // both CTAs are present
        } else if (this.item.ctA1Link) {
            this.promoCta = this.item.ctA1Link;
            this.promoCta.attributes = this.item.ctA1Link.attributes;
        } else if (this.item.ctA2Link) {
            this.promoCta = this.item.ctA2Link;
            this.promoCta.attributes = this.item.ctA2Link.attributes;
        }
    }

    onResize(event: any) {
        //    if(event.target.innerWidth < 600){
        //        this.mobileEval=true;
        //    }else{
        //        this.mobileEval=false;
        //    }
        if (event.target.innerWidth > 950) {
            this.mobileEval = 'desktop';
        } else if (event.target.innerWidth > 600 && event.target.innerWidth < 950) {
            this.mobileEval = 'tablet';
        } else {
            this.mobileEval = 'mobile';
        }
    }

    ppPromoHeaderBannerCtaTracking(bannerName: string, urlClicked: string, eventDetailsText: string) {
        this.vanillaTrackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'download',
            'component.LabelEvent': 'poker app',
            'component.ActionEvent': 'click',
            'component.PositionEvent': bannerName.replace(/<\/?[^>]+(>|$)/g, ''), //'{banner name in English only}', //ex:Welcome to your FREE Play offer
            'component.LocationEvent': this.userService.isAuthenticated ? 'post login' : 'pre login', //ex: pre login when user downloads without login
            'component.EventDetails': eventDetailsText.toLowerCase() == 'learn more' ? eventDetailsText : 'download button',
            'component.URLClicked': urlClicked, //'{clicked URL}'
        });
    }
}
