import { Message } from '../../message';

export class AccountTemplateParam extends Message {
    private MESSAGE_NAME: string = 'AccountTemplateParam';
    private params: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, params: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.params = params;
    }

    getParams(): Map<any, any> | null {
        return this.params;
    }

    setParams(params: Map<any, any> | null) {
        this.params = params;
    }
}
