import { Message } from '../../message';

export class NetPositionDetails extends Message {
    private MESSAGE_NAME: string = 'NetPositionDetails';
    private currentBalance: number = 0;
    private InitialBalance: number = 0;
    private totalStake: number = 0;
    private loss: number = 0;
    private profit: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        currentBalance: number = 0,
        InitialBalance: number = 0,
        totalStake: number = 0,
        loss: number = 0,
        profit: number = 0,
    ) {
        super(reqServerPeerId);
        this.currentBalance = currentBalance;
        this.InitialBalance = InitialBalance;
        this.totalStake = totalStake;
        this.loss = loss;
        this.profit = profit;
    }

    getCurrentBalance(): number {
        return this.currentBalance;
    }

    setCurrentBalance(currentBalance: number) {
        this.currentBalance = currentBalance;
    }
    getInitialBalance(): number {
        return this.InitialBalance;
    }

    setInitialBalance(InitialBalance: number) {
        this.InitialBalance = InitialBalance;
    }
    getTotalStake(): number {
        return this.totalStake;
    }

    setTotalStake(totalStake: number) {
        this.totalStake = totalStake;
    }
    getLoss(): number {
        return this.loss;
    }

    setLoss(loss: number) {
        this.loss = loss;
    }
    getProfit(): number {
        return this.profit;
    }

    setProfit(profit: number) {
        this.profit = profit;
    }
}
