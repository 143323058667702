import { Component, Injector } from '@angular/core';

import { MenuActionComponent, PlainLinkComponent, ProductService } from '@frontend/vanilla/core';
import { PublicPageWrapperComponent, TeasersComponent } from '@pokercore/module/homepage';

import { BigWinComponent } from './bigwin/bigwin.component';
import { CalendarCountdownTimerComponent } from './calendar-countdown-timer/calendar-countdown-timer.component';
import { OnAppInitModule } from './on-app-init/on-app-init.module';
import { PokerCoreLazyPlaceholderComponent } from './poker-core-lazy-placeholder/poker-core-lazy-placeholder.component';
import { PokerCountdownComponent } from './poker-countdown-component/poker-countdown.component';
import { PokerDiscountRibbonComponent } from './poker-discount-ribbon/poker-discount-ribbon.component';
import { PokerRibbonTimerComponent } from './poker-ribbon-timer/poker-ribbon-timer.component';
import { PokerTournamentRibbonComponent } from './poker-tournament-ribbon/poker-tournament-ribbon.component';
import { PPPromoHeaderComponent } from './pp-promo-header/pp-promo-header.component';
import { RootServicesModule } from './root-services.module';
import { SimulatedHandsComponent } from './simulated-hands/simulated-hands.component';

// import { PokerFrameworkComponent } from './poker-framework.component';
// import { CoreModule } from '@pokercore/module/core';

@Component({
    selector: 'pk-lib-poker-framework',
    template: ` <p>poker-framework 333545545!safugsaufsad</p> `,
    styles: [],
    standalone: true,
    imports: [
        // CoreModule,
        // RouterModule.forChild(POKER_ROUTES),
        OnAppInitModule,
        PPPromoHeaderComponent,
        CalendarCountdownTimerComponent,
        PokerCountdownComponent,
        PokerCoreLazyPlaceholderComponent,
        PokerRibbonTimerComponent,
        PokerDiscountRibbonComponent,
        PokerTournamentRibbonComponent,
        PlainLinkComponent,
        MenuActionComponent,
        BigWinComponent,
        SimulatedHandsComponent,
        TeasersComponent,
        PublicPageWrapperComponent,
        RootServicesModule,
    ],
    // exports: [PokerFrameworkComponent, CoreModule, OnAppInitModule, RootServicesModule],
})
export class PokerFrameworkComponent {
    constructor(
        private productService: ProductService,
        private injector: Injector,
    ) {
        this.productService.register('poker', this.injector);
    }
}
