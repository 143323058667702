import { Message } from '../../message';

export class GameVariantBonus extends Message {
    private MESSAGE_NAME: string = 'GameVariantBonus';
    private gameCategory: number = 0;
    private allowedStakesInCents: any[] | null = null;
    private allowedSeats: any[] | null = null;
    private allowedPools: any[] | null = null;
    private allowedGameTypes: any[] | null = null;
    private gvb: boolean | null = false;
    private bonusAmount: number = 0;
    private accountCurrencyType: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        gameCategory: number = 0,
        allowedStakesInCents: any[] | null = null,
        allowedSeats: any[] | null = null,
        allowedPools: any[] | null = null,
        allowedGameTypes: any[] | null = null,
        gvb: boolean | null = false,
        bonusAmount: number = 0,
        accountCurrencyType: string | null = null,
    ) {
        super(reqServerPeerId);
        this.gameCategory = gameCategory;
        this.allowedStakesInCents = allowedStakesInCents;
        this.allowedSeats = allowedSeats;
        this.allowedPools = allowedPools;
        this.allowedGameTypes = allowedGameTypes;
        this.gvb = gvb;
        this.bonusAmount = bonusAmount;
        this.accountCurrencyType = accountCurrencyType;
    }

    getGameCategory(): number {
        return this.gameCategory;
    }

    setGameCategory(gameCategory: number) {
        this.gameCategory = gameCategory;
    }
    getAllowedStakesInCents(): any[] | null {
        return this.allowedStakesInCents;
    }

    setAllowedStakesInCents(allowedStakesInCents: any[] | null) {
        this.allowedStakesInCents = allowedStakesInCents;
    }
    getAllowedSeats(): any[] | null {
        return this.allowedSeats;
    }

    setAllowedSeats(allowedSeats: any[] | null) {
        this.allowedSeats = allowedSeats;
    }
    getAllowedPools(): any[] | null {
        return this.allowedPools;
    }

    setAllowedPools(allowedPools: any[] | null) {
        this.allowedPools = allowedPools;
    }
    getAllowedGameTypes(): any[] | null {
        return this.allowedGameTypes;
    }

    setAllowedGameTypes(allowedGameTypes: any[] | null) {
        this.allowedGameTypes = allowedGameTypes;
    }
    getGvb(): boolean | null {
        return this.gvb;
    }

    setGvb(gvb: boolean | null) {
        this.gvb = gvb;
    }
    getBonusAmount(): number {
        return this.bonusAmount;
    }

    setBonusAmount(bonusAmount: number) {
        this.bonusAmount = bonusAmount;
    }
    getAccountCurrencyType(): string | null {
        return this.accountCurrencyType;
    }

    setAccountCurrencyType(accountCurrencyType: string | null) {
        this.accountCurrencyType = accountCurrencyType;
    }
}
