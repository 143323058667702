import { Message } from '../../message';

export class LSPoolRemovePlayerInfo extends Message {
    private MESSAGE_NAME: string = 'LSPoolRemovePlayerInfo';
    private screenName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenName: string | null = null) {
        super(reqServerPeerId);
        this.screenName = screenName;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
}
