import { Message } from '../../message';

export class ResponsePlayerNoteId extends Message {
    private MESSAGE_NAME: string = 'ResponsePlayerNoteId';
    private playerNoteId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerNoteId: string | null = null) {
        super(reqServerPeerId);
        this.playerNoteId = playerNoteId;
    }

    getPlayerNoteId(): string | null {
        return this.playerNoteId;
    }

    setPlayerNoteId(playerNoteId: string | null) {
        this.playerNoteId = playerNoteId;
    }
}
