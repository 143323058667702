import { Routes } from '@angular/router';

import { PokerFastForwardSynopsisResolver } from './poker-fastforward-synopsis.resolver';
import { PokerFastForwardResolver } from './poker-fastforward.resolver';

export const pokerFastForwardRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerFastForwardResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerFastForwardSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerFastForwardResolver,
        },
    },
];
