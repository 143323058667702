import { Message } from '../../message';
import { UserProfile } from './UserProfile';

export class LoginSuccessUserProfile extends Message {
    private MESSAGE_NAME: string = 'LoginSuccessUserProfile';
    private userProfile: UserProfile | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, userProfile: UserProfile | null = null) {
        super(reqServerPeerId);
        this.userProfile = userProfile;
    }

    getUserProfile(): UserProfile | null {
        return this.userProfile;
    }

    setUserProfile(userProfile: UserProfile | null) {
        this.userProfile = userProfile;
    }
}
