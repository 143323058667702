import { Routes } from '@angular/router';

import { PokerScheduledTournamentsSynopsisResolver } from './poker-scheduledtournaments-synopsis.resolver';
import { PokerScheduledTournamentsResolver } from './poker-scheduledtournaments.resolver';

export const pokerScheduledTournamentsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerScheduledTournamentsResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerScheduledTournamentsSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerScheduledTournamentsResolver,
        },
    },
];
