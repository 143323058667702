import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { PokerCoreLazyRouteService } from './poker-core-lazy-route.service';

@Component({
    selector: 'poker-core-lazy-placeholder',
    templateUrl: 'poker-core-lazy-placeholder.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PokerCoreLazyPlaceholderComponent implements OnInit, OnChanges {
    @Input() componentName: string;
    @Input() inputData: any;
    @Input() outputEvents: OutputEventModel[];
    componentRef_: any;
    @ViewChild('pokerCoreLazyTemplate', { read: ViewContainerRef, static: true }) pokerCoreLazyPlaceholdercontainer: ViewContainerRef;
    constructor(private lazyRouteService: PokerCoreLazyRouteService) {}
    ngOnInit() {
        const componentData = {
            type: this.componentName,
        };
        this.lazyRouteService.loadAndRenderLazyComponents(componentData, this.pokerCoreLazyPlaceholdercontainer).then((componentFactory) => {
            this.componentRef_ = this.pokerCoreLazyPlaceholdercontainer.createComponent(componentFactory);
            for (const attribute in this.inputData) {
                this.componentRef_.instance[attribute] = this.inputData[attribute];
            }
            if (this.outputEvents) {
                this.outputEvents.forEach((outputEvent: OutputEventModel) => {
                    this.componentRef_.instance[outputEvent.eventName].subscribe((data: any) => {
                        outputEvent.callback(data);
                    });
                });
            }
        });
    }
    ngOnChanges() {
        if (this.componentRef_) {
            for (const attribute in this.inputData) {
                this.componentRef_.instance[attribute] = this.inputData[attribute];
            }
            try {
                this.componentRef_.instance.ngOnChanges();
            } catch {
                // ignored;
            }
        }
    }
}

export class OutputEventModel {
    eventName: string;
    callback: any; //callback function to execute on eventName event
}
