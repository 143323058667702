import { Message } from '../../message';

export class MTSGCard extends Message {
    private MESSAGE_NAME: string = 'MTSGCard';
    private cardId: number = 0;
    private buyIn: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;
    private gameSpeed: number = 0;
    private tag: number = 0;
    private imageName: string | null = null;
    private isImageURL: number = 0;
    private gameCurrency: string | null = null;
    private isFeatured: number = 0;
    private tourneyId: number = 0;
    private sngGame: number = 0;
    private status: number = 0;
    private noOfRegistrants: number = 0;
    private buyInType: number = 0;
    private tourneyFee: number = 0;
    private freeRollIdList: any[] | null = null;
    private colorCode: number = 0;
    private quintepokChampFee: number = 0;
    private bountyAmount: number = 0;
    private name: any | null = null;
    private partyPoints: number = 0;
    private maxParticipants: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        cardId: number = 0,
        buyIn: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
        gameSpeed: number = 0,
        tag: number = 0,
        imageName: string | null = null,
        isImageURL: number = 0,
        gameCurrency: string | null = null,
        isFeatured: number = 0,
        tourneyId: number = 0,
        sngGame: number = 0,
        status: number = 0,
        noOfRegistrants: number = 0,
        buyInType: number = 0,
        tourneyFee: number = 0,
        freeRollIdList: any[] | null = null,
        colorCode: number = 0,
        quintepokChampFee: number = 0,
        bountyAmount: number = 0,
        name: any | null = null,
        partyPoints: number = 0,
        maxParticipants: number = 0,
    ) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.buyIn = buyIn;
        this.gameType = gameType;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
        this.gameSpeed = gameSpeed;
        this.tag = tag;
        this.imageName = imageName;
        this.isImageURL = isImageURL;
        this.gameCurrency = gameCurrency;
        this.isFeatured = isFeatured;
        this.tourneyId = tourneyId;
        this.sngGame = sngGame;
        this.status = status;
        this.noOfRegistrants = noOfRegistrants;
        this.buyInType = buyInType;
        this.tourneyFee = tourneyFee;
        this.freeRollIdList = freeRollIdList;
        this.colorCode = colorCode;
        this.quintepokChampFee = quintepokChampFee;
        this.bountyAmount = bountyAmount;
        this.name = name;
        this.partyPoints = partyPoints;
        this.maxParticipants = maxParticipants;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getGameSpeed(): number {
        return this.gameSpeed;
    }

    setGameSpeed(gameSpeed: number) {
        this.gameSpeed = gameSpeed;
    }
    getTag(): number {
        return this.tag;
    }

    setTag(tag: number) {
        this.tag = tag;
    }
    getImageName(): string | null {
        return this.imageName;
    }

    setImageName(imageName: string | null) {
        this.imageName = imageName;
    }
    getIsImageURL(): number {
        return this.isImageURL;
    }

    setIsImageURL(isImageURL: number) {
        this.isImageURL = isImageURL;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getIsFeatured(): number {
        return this.isFeatured;
    }

    setIsFeatured(isFeatured: number) {
        this.isFeatured = isFeatured;
    }
    getTourneyId(): number {
        return this.tourneyId;
    }

    setTourneyId(tourneyId: number) {
        this.tourneyId = tourneyId;
    }
    getSngGame(): number {
        return this.sngGame;
    }

    setSngGame(sngGame: number) {
        this.sngGame = sngGame;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getNoOfRegistrants(): number {
        return this.noOfRegistrants;
    }

    setNoOfRegistrants(noOfRegistrants: number) {
        this.noOfRegistrants = noOfRegistrants;
    }
    getBuyInType(): number {
        return this.buyInType;
    }

    setBuyInType(buyInType: number) {
        this.buyInType = buyInType;
    }
    getTourneyFee(): number {
        return this.tourneyFee;
    }

    setTourneyFee(tourneyFee: number) {
        this.tourneyFee = tourneyFee;
    }
    getFreeRollIdList(): any[] | null {
        return this.freeRollIdList;
    }

    setFreeRollIdList(freeRollIdList: any[] | null) {
        this.freeRollIdList = freeRollIdList;
    }
    getColorCode(): number {
        return this.colorCode;
    }

    setColorCode(colorCode: number) {
        this.colorCode = colorCode;
    }
    getQuintepokChampFee(): number {
        return this.quintepokChampFee;
    }

    setQuintepokChampFee(quintepokChampFee: number) {
        this.quintepokChampFee = quintepokChampFee;
    }
    getBountyAmount(): number {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: number) {
        this.bountyAmount = bountyAmount;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getMaxParticipants(): number {
        return this.maxParticipants;
    }

    setMaxParticipants(maxParticipants: number) {
        this.maxParticipants = maxParticipants;
    }
}
