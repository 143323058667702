import { Message } from '../../message';

export class PlayerBalances extends Message {
    private MESSAGE_NAME: string = 'PlayerBalances';
    private balances: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, balances: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.balances = balances;
    }

    getBalances(): Map<any, any> | null {
        return this.balances;
    }

    setBalances(balances: Map<any, any> | null) {
        this.balances = balances;
    }
}
