import { Message } from '../../message';

export class SitOutWarningOn extends Message {
    private MESSAGE_NAME: string = 'SitOutWarningOn';
    private warningType: number = 0;
    private timerExpiryTimeAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, warningType: number = 0, timerExpiryTimeAt: number = 0) {
        super(reqServerPeerId);
        this.warningType = warningType;
        this.timerExpiryTimeAt = timerExpiryTimeAt;
    }

    getWarningType(): number {
        return this.warningType;
    }

    setWarningType(warningType: number) {
        this.warningType = warningType;
    }
    getTimerExpiryTimeAt(): number {
        return this.timerExpiryTimeAt;
    }

    setTimerExpiryTimeAt(timerExpiryTimeAt: number) {
        this.timerExpiryTimeAt = timerExpiryTimeAt;
    }
}
