import { Message } from '../../message';

export class PlayerSearchResults extends Message {
    private MESSAGE_NAME: string = 'PlayerSearchResults';
    private results: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, results: any[] | null = null) {
        super(reqServerPeerId);
        this.results = results;
    }

    getResults(): any[] | null {
        return this.results;
    }

    setResults(results: any[] | null) {
        this.results = results;
    }
}
