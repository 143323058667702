import { Message } from '../../message';

export class ReduceChips extends Message {
    private MESSAGE_NAME: string = 'ReduceChips';
    private amount: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, amount: number = 0, conversationId: number = 0) {
        super(reqServerPeerId);
        this.amount = amount;
        this.conversationId = conversationId;
    }

    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
