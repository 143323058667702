import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { PCComponent, PCComponentHeaderV2Component } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-instagram-feed',
    templateUrl: 'poker-instagram-feed.component.html',
    standalone: true,
    imports: [CommonModule, PCComponentHeaderV2Component],
})
export class PokerInstagramFeedComponent extends PCComponent {
    pokerInstagramFeed: any;
    @HostBinding()
    get class() {
        this.pokerInstagramFeed = this.item.instagramFeed.items;
        return this.createClass(this.item.name);
    }
}
