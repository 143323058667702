import { Message } from '../../message';

export class ATHStrikeHandCountConfiguration extends Message {
    private MESSAGE_NAME: string = 'ATHStrikeHandCountConfiguration';
    private minimumHandsCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, minimumHandsCount: number = 0) {
        super(reqServerPeerId);
        this.minimumHandsCount = minimumHandsCount;
    }

    getMinimumHandsCount(): number {
        return this.minimumHandsCount;
    }

    setMinimumHandsCount(minimumHandsCount: number) {
        this.minimumHandsCount = minimumHandsCount;
    }
}
