import { Message } from '../../message';

export class ExtendedAttribs extends Message {
    private MESSAGE_NAME: string = 'ExtendedAttribs';
    private extendedAttribs: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, extendedAttribs: any | null = null) {
        super(reqServerPeerId);
        this.extendedAttribs = extendedAttribs;
    }

    getExtendedAttribs(): any {
        return this.extendedAttribs;
    }

    setExtendedAttribs(extendedAttribs: any) {
        this.extendedAttribs = extendedAttribs;
    }
}
