import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'ppl-event-categories',
    templateUrl: './ppl-event-categories.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PPLEventCategoriesComponent extends PCComponent {
    @Input() override item: any = null;
    getAllEventCategoryContent: any;
    getFewEventCategory: any;
    basePath: any;
    itemsToLoad: number = 0;
    showLoadMoreBtn: boolean = true;
    constructor(
        private page: Page,
        private router: Router,
    ) {
        super();
        this.basePath = this.page.homePage;
    }

    ngOnInit(): void {
        this.getAllEventCategoryContent = this.item.pplEventCategoryContent;
        this.loadMore();
    }

    loadMore() {
        this.itemsToLoad += 10;
        this.getFewEventCategory = this.getAllEventCategoryContent.slice(0, this.itemsToLoad);
        this.showLoadMoreBtn =
            this.getAllEventCategoryContent.length < this.itemsToLoad || this.getAllEventCategoryContent.length === this.itemsToLoad ? false : true;
    }
    gotoDetails(link: string) {
        this.router.navigate([this.page.lang + '/' + link]);
    }
}
