import { Message } from '../../message';

export class LSPoolPlayerInfo extends Message {
    private MESSAGE_NAME: string = 'LSPoolPlayerInfo';
    private screenName: string | null = null;
    private noOfEntreis: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenName: string | null = null, noOfEntreis: number = 0) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.noOfEntreis = noOfEntreis;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getNoOfEntreis(): number {
        return this.noOfEntreis;
    }

    setNoOfEntreis(noOfEntreis: number) {
        this.noOfEntreis = noOfEntreis;
    }
}
