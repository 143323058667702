import { Message } from '../../message';
import { FXConversionFactor } from '../../pgSharedMessageFactory/messages/FXConversionFactor';

export class FXConversionData extends Message {
    private MESSAGE_NAME: string = 'FXConversionData';
    private fxFactor: FXConversionFactor | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, fxFactor: FXConversionFactor | null = null) {
        super(reqServerPeerId);
        this.fxFactor = fxFactor;
    }

    getFxFactor(): FXConversionFactor | null {
        return this.fxFactor;
    }

    setFxFactor(fxFactor: FXConversionFactor | null) {
        this.fxFactor = fxFactor;
    }
}
