import { Message } from '../../message';

export class RequestLSPlayerTableCounts extends Message {
    private MESSAGE_NAME: string = 'RequestLSPlayerTableCounts';
    private subscribed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, subscribed: boolean | null = false) {
        super(reqServerPeerId);
        this.subscribed = subscribed;
    }

    getSubscribed(): boolean | null {
        return this.subscribed;
    }

    setSubscribed(subscribed: boolean | null) {
        this.subscribed = subscribed;
    }
}
