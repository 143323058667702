import { Message } from '../../message';

export class NotifyPlayerProfile extends Message {
    private MESSAGE_NAME: string = 'NotifyPlayerProfile';
    private sessionKey: string | null = null;
    private encryptedProfile: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sessionKey: string | null = null, encryptedProfile: any[] | null = null) {
        super(reqServerPeerId);
        this.sessionKey = sessionKey;
        this.encryptedProfile = encryptedProfile;
    }

    getSessionKey(): string | null {
        return this.sessionKey;
    }

    setSessionKey(sessionKey: string | null) {
        this.sessionKey = sessionKey;
    }
    getEncryptedProfile(): any[] | null {
        return this.encryptedProfile;
    }

    setEncryptedProfile(encryptedProfile: any[] | null) {
        this.encryptedProfile = encryptedProfile;
    }
}
