import { Component } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { VCClientService } from '@pokercore/module/core';

import { HandHistoryService } from '../../../../../hand-history.service';
import { GameTable } from '../../models/game-table';
import { GameTableService } from './game-table.service';

@Component({
    selector: 'pkr-game-table',
    templateUrl: './game-table.component.html',
})
export class GameTableComponent {
    gameTable: GameTable;
    posX: any;
    posY: any;
    content: any;
    vcClient: any;
    mNativeApp: any;
    macDesktop: any;
    isMobile: boolean;
    isTablet: boolean;
    isMobileRotatedToLandScape: any;
    IpadTabUserAgent: any = false;
    maxSeats: any;

    constructor(
        public gameTableService: GameTableService,
        public serve: HandHistoryService,
        public vcClientServce: VCClientService,
        private deviceService: DeviceService,
    ) {}
    ngOnInit() {
        this.isMobile = this.serve.isMobile;
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Macintosh/) !== null) {
            this.IpadTabUserAgent = Object.values(userAgent.match(/Macintosh/))[0] === 'Macintosh' && navigator.maxTouchPoints > 2;
        }
        this.isTablet = this.deviceService.isTablet || this.IpadTabUserAgent;
        this.content = this.serve.content.messages;
        this.vcClient = this.vcClientServce.isClient();
        this.mNativeApp = this.vcClientServce.isMobileApp();
        this.macDesktop = this.vcClientServce.isMacDesktop();
        this.maxSeats = this.gameTableService.gameTable?.maxSeats;
    }

    ngDoCheck() {
        this.isMobileRotatedToLandScape = false;
        if (window.orientation === 90 || window.orientation === -90) {
            this.changeOrientations();
        }
        this.maxSeats = this.gameTableService.gameTable?.maxSeats;
    }

    closeGameTable(event: any) {
        const className = event.target.className;
        const winWidth = window.outerWidth;
        if (winWidth >= 768 || className.indexOf('close-btn') > -1) {
            if (
                className.indexOf('game-table-wrapper') > -1 ||
                className.indexOf('game-table-innerwrapper') > -1 ||
                className.indexOf('close-btn') > -1
            ) {
                this.showBottomNavForIdevices();
                //this.exitFullScreenForMobiles();
                const bodyElement = document.getElementsByTagName('body')[0];
                this.gameTableService.gtInitializeDone = false;
                this.serve.popupToggle = false;
                this.gameTableService.disablePrevHandButton = false;
                this.gameTableService.disableNextHandButton = false;
                (document.querySelector('body') as HTMLElement).style.top = '0px';
                if (bodyElement.className.indexOf('noscroll') > -1) {
                    bodyElement.classList.remove('noscroll');
                }
                window.scroll(0, this.serve.replayHandPosition);
                const navClass: any = document.getElementsByClassName('slot-main')[0];
                const filter: any = document.getElementsByClassName('apply-filters')[0];
                const mobilefilter: any = document.getElementsByClassName('apply-filters')[1];
                const subnav: any = document.getElementsByClassName('mobile-only-menu')[0];
                navClass.classList.remove('hideNav');
                filter.classList.remove('hideHHOfilter');
                mobilefilter.classList.remove('hideHHOfilter');
                if (this.isMobile) {
                    subnav.classList.remove('hidesubNav');
                }
            }
        }
    }

    ngAfterViewChecked() {
        const width = window.innerWidth;
        if (this.gameTableService.isMobile && !this.deviceService.isTablet) {
            const ele: any = document.getElementById('game-table-container');
            const elenew: any = document.getElementById('Player-id');
            let zoomValue = width / 720;
            if (zoomValue > 1.46) {
                zoomValue = 1.46;
            }
            if (ele || elenew) {
                ele.style.zoom = zoomValue;
                elenew.style.zoom = zoomValue;
            }
        } else if (this.deviceService.isTablet || this.gameTableService.IpadTabUserAgent) {
            const ele: any = document.getElementById('game-table-container');
            const elenew: any = document.getElementById('Player-id');
            let zoomValue = width / 1024;
            if (zoomValue > 1.46) {
                zoomValue = 1.46;
            }
            if (ele || elenew) {
                ele.style.zoom = zoomValue;
                elenew.style.zoom = zoomValue;
            }
        }
    }

    getXaxis() {
        this.posX = this.gameTableService.gameTable.changeChipPos.x;
        return `${this.posX}%`;
    }
    getYaxis() {
        this.posY = this.gameTableService.gameTable.changeChipPos.y;
        return `${this.posY}%`;
    }

    changeOrientations() {
        this.isMobileRotatedToLandScape = true;
    }

    exitFullScreenForMobiles() {
        const elem: any = document.getElementsByClassName('web-veiw-wrapper')[0];
        if (elem && this.serve.isMobile && !this.serve.Idevices && !this.serve.isTenSeaterGame) {
            if (document.exitFullscreen) {
                /* Chrome, Safari and Opera */
                document.exitFullscreen();
            } else if (elem.webkitExitFullscreen) {
                elem.webkitExitFullscreen();
            }
        } else {
            return;
        }
    }

    showBottomNavForIdevices() {
        const hideBottomNavForIdevice: any = document.getElementsByClassName('bottom-nav')[0];
        if (
            hideBottomNavForIdevice &&
            hideBottomNavForIdevice.className.indexOf('bottom-nav') > -1 &&
            this.serve.isMobile &&
            !this.serve.showBottomNavForIdevice
        ) {
            hideBottomNavForIdevice.style.zIndex = 1030;
        }
    }
}
