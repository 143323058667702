<div class="pwc-user-balance d-flex flex-wrap justify-content-center" (click)="showBalanceBreakDown()">
    <div class="wallet-balance d-flex align-items-center justify-content-center w-100">
        <span class="money-icon" [ngStyle]="{ 'background-image': 'url(' + balanceIcon + ')' }">&nbsp;</span>
        <span class="money-number">{{ accountBalance | FormatamountPipe: accountCurrency }} {{ accountCurrency }}</span>
    </div>
    <div class="tickets-balance poker-balance d-flex align-items-center">
        <span class="ticket-icon" [ngStyle]="{ 'background-image': 'url(' + balanceIcon + ')' }">&nbsp;</span>
        <span class="money-number">P$ {{ tourneyCurrencyBalance }}</span>
    </div>
    <div class="tickets-balance d-flex align-items-center">
        <span class="ticket-icon" [ngStyle]="{ 'background-image': 'url(' + balanceIcon + ')' }">&nbsp;</span>
        <span class="money-number">{{ userTickets }}</span>
    </div>
</div>
