import { Message } from '../../message';

export class MTCTRebuyAddonStatus extends Message {
    private MESSAGE_NAME: string = 'MTCTRebuyAddonStatus';
    private status: number = 0;
    private addonSupported: boolean | null = false;
    private autoRebuyAddonEnabled: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: number = 0, addonSupported: boolean | null = false, autoRebuyAddonEnabled: number = 0) {
        super(reqServerPeerId);
        this.status = status;
        this.addonSupported = addonSupported;
        this.autoRebuyAddonEnabled = autoRebuyAddonEnabled;
    }

    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getAddonSupported(): boolean | null {
        return this.addonSupported;
    }

    setAddonSupported(addonSupported: boolean | null) {
        this.addonSupported = addonSupported;
    }
    getAutoRebuyAddonEnabled(): number {
        return this.autoRebuyAddonEnabled;
    }

    setAutoRebuyAddonEnabled(autoRebuyAddonEnabled: number) {
        this.autoRebuyAddonEnabled = autoRebuyAddonEnabled;
    }
}
