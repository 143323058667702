import { Message } from '../../message';

export class AddPlayMoneyToPoolResponse extends Message {
    private MESSAGE_NAME: string = 'AddPlayMoneyToPoolResponse';
    private updatedPlayMoney: number = 0;
    private messageToClient: any | null = null;
    private addPlayMoneyStatus: number = 0;
    private entryId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        updatedPlayMoney: number = 0,
        messageToClient: any | null = null,
        addPlayMoneyStatus: number = 0,
        entryId: number = 0,
    ) {
        super(reqServerPeerId);
        this.updatedPlayMoney = updatedPlayMoney;
        this.messageToClient = messageToClient;
        this.addPlayMoneyStatus = addPlayMoneyStatus;
        this.entryId = entryId;
    }

    getUpdatedPlayMoney(): number {
        return this.updatedPlayMoney;
    }

    setUpdatedPlayMoney(updatedPlayMoney: number) {
        this.updatedPlayMoney = updatedPlayMoney;
    }
    getMessageToClient(): any | null {
        return this.messageToClient;
    }

    setMessageToClient(messageToClient: any | null) {
        this.messageToClient = messageToClient;
    }
    getAddPlayMoneyStatus(): number {
        return this.addPlayMoneyStatus;
    }

    setAddPlayMoneyStatus(addPlayMoneyStatus: number) {
        this.addPlayMoneyStatus = addPlayMoneyStatus;
    }
    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
}
