import { Message } from '../../message';

export class CurrentPlayerMTCTRegistrations extends Message {
    private MESSAGE_NAME: string = 'CurrentPlayerMTCTRegistrations';
    private registredMtcts: any[] | null = null;
    private favouriteMtctIdList: any[] | null = null;
    private alerts: any[] | null = null;
    private unregisteredMtcts: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        registredMtcts: any[] | null = null,
        favouriteMtctIdList: any[] | null = null,
        alerts: any[] | null = null,
        unregisteredMtcts: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.registredMtcts = registredMtcts;
        this.favouriteMtctIdList = favouriteMtctIdList;
        this.alerts = alerts;
        this.unregisteredMtcts = unregisteredMtcts;
    }

    getRegistredMtcts(): any[] | null {
        return this.registredMtcts;
    }

    setRegistredMtcts(registredMtcts: any[] | null) {
        this.registredMtcts = registredMtcts;
    }
    getFavouriteMtctIdList(): any[] | null {
        return this.favouriteMtctIdList;
    }

    setFavouriteMtctIdList(favouriteMtctIdList: any[] | null) {
        this.favouriteMtctIdList = favouriteMtctIdList;
    }
    getAlerts(): any[] | null {
        return this.alerts;
    }

    setAlerts(alerts: any[] | null) {
        this.alerts = alerts;
    }
    getUnregisteredMtcts(): any[] | null {
        return this.unregisteredMtcts;
    }

    setUnregisteredMtcts(unregisteredMtcts: any[] | null) {
        this.unregisteredMtcts = unregisteredMtcts;
    }
}
