import { Message } from '../../message';

export class PlayerLobbyToGameplayTrackerInfo extends Message {
    private MESSAGE_NAME: string = 'PlayerLobbyToGameplayTrackerInfo';
    private cardId: number = 0;
    private lobbyType: number = 0;
    private originalCardPosition: number = 0;
    private currentCardPosition: number = 0;
    private trackerIdList: any[] | null = null;
    private filterUsedByPlayer: number = 0;
    private cardType: number = 0;
    private tourneyId: number = 0;
    private playerAccountName: string | null = null;
    private tag: number = 0;
    private topCardIds: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        cardId: number = 0,
        lobbyType: number = 0,
        originalCardPosition: number = 0,
        currentCardPosition: number = 0,
        trackerIdList: any[] | null = null,
        filterUsedByPlayer: number = 0,
        cardType: number = 0,
        tourneyId: number = 0,
        playerAccountName: string | null = null,
        tag: number = 0,
        topCardIds: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.lobbyType = lobbyType;
        this.originalCardPosition = originalCardPosition;
        this.currentCardPosition = currentCardPosition;
        this.trackerIdList = trackerIdList;
        this.filterUsedByPlayer = filterUsedByPlayer;
        this.cardType = cardType;
        this.tourneyId = tourneyId;
        this.playerAccountName = playerAccountName;
        this.tag = tag;
        this.topCardIds = topCardIds;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getLobbyType(): number {
        return this.lobbyType;
    }

    setLobbyType(lobbyType: number) {
        this.lobbyType = lobbyType;
    }
    getOriginalCardPosition(): number {
        return this.originalCardPosition;
    }

    setOriginalCardPosition(originalCardPosition: number) {
        this.originalCardPosition = originalCardPosition;
    }
    getCurrentCardPosition(): number {
        return this.currentCardPosition;
    }

    setCurrentCardPosition(currentCardPosition: number) {
        this.currentCardPosition = currentCardPosition;
    }
    getTrackerIdList(): any[] | null {
        return this.trackerIdList;
    }

    setTrackerIdList(trackerIdList: any[] | null) {
        this.trackerIdList = trackerIdList;
    }
    getFilterUsedByPlayer(): number {
        return this.filterUsedByPlayer;
    }

    setFilterUsedByPlayer(filterUsedByPlayer: number) {
        this.filterUsedByPlayer = filterUsedByPlayer;
    }
    getCardType(): number {
        return this.cardType;
    }

    setCardType(cardType: number) {
        this.cardType = cardType;
    }
    getTourneyId(): number {
        return this.tourneyId;
    }

    setTourneyId(tourneyId: number) {
        this.tourneyId = tourneyId;
    }
    getPlayerAccountName(): string | null {
        return this.playerAccountName;
    }

    setPlayerAccountName(playerAccountName: string | null) {
        this.playerAccountName = playerAccountName;
    }
    getTag(): number {
        return this.tag;
    }

    setTag(tag: number) {
        this.tag = tag;
    }
    getTopCardIds(): any[] | null {
        return this.topCardIds;
    }

    setTopCardIds(topCardIds: any[] | null) {
        this.topCardIds = topCardIds;
    }
}
