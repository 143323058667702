import { Chip } from './chip';
import { Point } from './point';

export class Pot {
    private _totalValue: number;
    private _potId: number;
    private _gameCurrency: any;
    private _showPot: any;

    position: Point;
    totalPot: Point;
    sidePots: Pot[] = [];

    constructor() {
        this._totalValue = 0;
    }

    get potId(): number {
        return this._potId;
    }

    set potId(value: number) {
        this._potId = value;
    }

    get showPot(): any {
        return this._showPot;
    }

    set showPot(value: any) {
        this._showPot = value;
    }

    get totalValue(): number {
        return this._totalValue;
    }

    set totalValue(value: number) {
        this._totalValue = value;
    }

    get gameCurrency(): any {
        return this._gameCurrency;
    }

    set gameCurrency(value: any) {
        this._gameCurrency = value;
    }

    addChip(chip: Chip) {
        this._totalValue = Number(this._totalValue) + Number(chip.value);
    }

    removeChip(chip: any) {
        this._totalValue = Number(this._totalValue) - Number(chip.value);
    }
}
