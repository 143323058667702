<div id="myGameContainer" #myGameWrapper>
    <!-- Post Login -->
    <ng-container *ngIf="myGameData?.gameType">
        <div class="mygame-ribbon align-items-center d-flex">
            <div class="skill-level-info d-flex align-items-center">
                <span class="skill-badge"
                    ><img src="{{ 'https://scmedia.itsfogo.com/vpp/PromoSites/partypokerspins.com/my-game/' + myGameData?.medal + '.png' }}" alt="My Game"
                /></span>
                <span class="skill-level"
                    >{{ contentConfig?.yourSkillLevel }} <strong>{{ myGameData?.medal }}</strong></span
                >
            </div>
            <div class="my-game-info d-flex align-items-center">
                <div class="mg-info">
                    <span class="mg-key">{{ contentConfig?.gameType }}</span>
                    <span class="mg-value">{{ myGameData?.gameType }}</span>
                </div>
                <div class="mg-info">
                    <span class="mg-key">{{ contentConfig?.overallScore }}</span>
                    <span class="mg-value">{{ myGameData?.points }}</span>
                </div>
                <div class="mg-info">
                    <span class="mg-key">{{ contentConfig?.handsPlayed }}</span>
                    <span class="mg-value">{{ myGameData?.hands }}</span>
                </div>
            </div>
            <div class="view-card">
                <a (click)="viewFullScore()" target="_blank">{{ contentConfig?.fullScoreCTA }}</a>
            </div>
        </div>
    </ng-container>

    <!-- Not sufficient hands/points -->
    <ng-container *ngIf="myGameData?.myGamePartial?.msg">
        <div class="mygame-ribbon mygame-more-hands text-center" style="padding-right: 145px" id="myGameContainer">
            <div class="skill-level-info pt-1">
                <span class="need-more-hands">{{ contentConfigItems?.lessHandsText }}</span>
            </div>
            <div class="view-card">
                <a [href]="contentConfigItems?.playNowUrl" target="_blank">{{ contentConfigItems?.lessHandsCTAText }}</a>
            </div>
        </div>
    </ng-container>

    <!-- Pre Login -->
    <ng-container *ngIf="myGameData?.errorMessage === 1">
        <div class="mygame-ribbon d-flex align-items-center justify-content-center">
            <div class="mg-pre-login">
                <h3><a (click)="loginDialog()" [innerHtml]="contentConfig?.loginMyGame | trustAsHtml"></a></h3>
                <p>{{ contentConfig?.loginTagline }}</p>
            </div>
        </div>
    </ng-container>

    <!-- Error from MY GAME service -->
    <ng-container *ngIf="myGameData?.errorMessage && myGameData?.errorMessage !== 1">
        <div class="mygame-ribbon d-flex align-items-center justify-content-center">
            <div class="mg-pre-login">
                <p>{{ errorConfig[myGameData?.errorMessage] }}</p>
            </div>
        </div>
    </ng-container>

    <!-- Exception Message - Error -->
    <ng-container *ngIf="myGameData?.exceptionMessage">
        <div class="mygame-ribbon d-flex align-items-center justify-content-center">
            <div class="mg-pre-login">
                <p>{{ errorConfig[500] }}</p>
            </div>
        </div>
    </ng-container>
</div>
