import { Pipe, PipeTransform } from '@angular/core';

import getSymbolFromCurrency from './currency-map';

@Pipe({ name: 'formatCurrencyPipe', standalone: true })
export class formatCurrencyPipe implements PipeTransform {
    transform(symbol: string, unit: number) {
        return getSymbolFromCurrency(symbol) + (unit / 100).toFixed(2);
    }
}
