// import { error } from "winston";
import { IPGMessageFactory } from '../server/common/interfaces/IPGMessageFactory';
import { Message } from '../server/common/message';
import { CSD } from './CSD';
import { PGMessageFactoryRegistration } from './PGMessageFactoryRegistration';
import { TSDataInputStream } from './dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from './dataInputOutputStream/TSDataOutputStream';
import { IConnectorDelegate } from './interfaces/IconnectorDelegate';

export class MessageFactoryManager {
    // private m_Factories: { [key: string]: MessageFactory } = {};
    private msgFactryReg: PGMessageFactoryRegistration;
    private delegate: IConnectorDelegate;

    constructor(msgFactryReg: PGMessageFactoryRegistration) {
        this.msgFactryReg = msgFactryReg;
        // this.msgFactryReg = PGMessageFactoryRegistration.getInstance();
    }

    // Method to read message from stream.
    public messageRead(message: Message, iStream: TSDataInputStream): boolean {
        let success: boolean = false;

        const en: IPGMessageFactory[] = Array.from(this.msgFactryReg.getMessageFactoryTypeTable().keys());
        for (const msgfact in en) {
            if (en[msgfact].read(message, iStream)) {
                success = true;
                break;
            }
        }
        if (!success) {
            // console.error(message);
            this.delegate.log(CSD.LOG_ERROR, 'Message Class Id not defined in the Registered Message Factories.' + message);
            //console.error('Message Class Id not defined in the Registered Message Factories.' + message);
        }
        return success;
    }

    getClassIdforClass(clas: any): number {
        let classId: number;
        const en: IPGMessageFactory[] = Array.from(this.msgFactryReg.getMessageFactoryTypeTable().keys());
        for (const msgfact of en) {
            classId = msgfact.getClassIdByName(clas);
            if (classId !== 0) {
                return classId;
            }
        }

        return 0;
    }
    getClassNameForClassId(clsId: number) {
        let msgClass: any | null = null;
        const en: IPGMessageFactory[] = Array.from(this.msgFactryReg.getMessageFactoryTypeTable().keys());
        for (const msgfact of en) {
            msgClass = msgfact.getClass(clsId);
            if (msgClass !== null) {
                return new msgClass();
            }
        }

        return '';
    }

    // Method to write message to stream.
    public messageWrite(message: Message, oStream: TSDataOutputStream): boolean {
        let success: boolean = false;
        const en: IPGMessageFactory[] = Array.from(this.msgFactryReg.getMessageFactoryTypeTable().keys());

        for (const msgfact in en) {
            if (en[msgfact].write(message, oStream)) {
                success = true;
                break;
            }
        }

        if (!success) {
            this.delegate.log(CSD.LOG_ERROR, 'Message Class Id not defined in the Registered Message Factories.' + message);
            throw new Error('Message Class Id not defined in the Registered Message Factories.' + message);
        }
        return (success = true);
    }
}
