import { Message } from '../../message';

export class LSMTCTUpdateMinChanges extends Message {
    private MESSAGE_NAME: string = 'LSMTCTUpdateMinChanges';
    private mtctId: number = 0;
    private currentLevel: number = 0;
    private lateRegInProgress: boolean | null = false;
    private playerCount: number = 0;
    private totalPrizePool: number = 0;
    private noOfParticipants: number = 0;
    private status: number = 0;
    private events: any[] | null = null;
    private qualifiers: any[] | null = null;
    private tourneyStartTime: any | null = null;
    private maxSeats: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        currentLevel: number = 0,
        lateRegInProgress: boolean | null = false,
        playerCount: number = 0,
        totalPrizePool: number = 0,
        noOfParticipants: number = 0,
        status: number = 0,
        events: any[] | null = null,
        qualifiers: any[] | null = null,
        tourneyStartTime: any | null = null,
        maxSeats: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.currentLevel = currentLevel;
        this.lateRegInProgress = lateRegInProgress;
        this.playerCount = playerCount;
        this.totalPrizePool = totalPrizePool;
        this.noOfParticipants = noOfParticipants;
        this.status = status;
        this.events = events;
        this.qualifiers = qualifiers;
        this.tourneyStartTime = tourneyStartTime;
        this.maxSeats = maxSeats;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getCurrentLevel(): number {
        return this.currentLevel;
    }

    setCurrentLevel(currentLevel: number) {
        this.currentLevel = currentLevel;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getNoOfParticipants(): number {
        return this.noOfParticipants;
    }

    setNoOfParticipants(noOfParticipants: number) {
        this.noOfParticipants = noOfParticipants;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getEvents(): any[] | null {
        return this.events;
    }

    setEvents(events: any[] | null) {
        this.events = events;
    }
    getQualifiers(): any[] | null {
        return this.qualifiers;
    }

    setQualifiers(qualifiers: any[] | null) {
        this.qualifiers = qualifiers;
    }
    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
}
