import { Message } from '../../message';

export class BetLimitErrorMessage extends Message {
    private MESSAGE_NAME: string = 'BetLimitErrorMessage';
    private errorMsg: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, errorMsg: any | null = null) {
        super(reqServerPeerId);
        this.errorMsg = errorMsg;
    }

    getErrorMsg(): any | null {
        return this.errorMsg;
    }

    setErrorMsg(errorMsg: any | null) {
        this.errorMsg = errorMsg;
    }
}
