import { Message } from '../../message';

export class BlockSeat extends Message {
    private MESSAGE_NAME: string = 'BlockSeat';
    private seatNo: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatNo: number = 0, conversationId: number = 0) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.conversationId = conversationId;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
