<div class="penality_popup_page">
    <div class="parent">
        <div class="child">
            <div class="penality_content" *ngIf="athpenalitypopup">
                <div class="header-wrapper">
                    <div class="warning-header">{{ 'PARTY_POKER_AN_GEN_ANMISSING_commonwarning' | translate }}</div>
                    <span class="close theme-ex" (click)="closePopup()"></span>
                </div>
                <div class="content-wrapper">
                    <div class="warn-container">
                        <span class="theme-attention" alt="Warning symbol"></span>
                    </div>
                    <div class="content-text-wrapper">
                        <p class="text-one">{{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_CASHGAMESPENALTY_LINEONE' | translate }}</p>
                        <p class="text-two">{{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_CASHGAMESPENALTY_LINETWO' | translate }}</p>
                        <p class="space">
                            {{ displayTime }}
                        </p>
                        <p class="text-three">{{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_CASHGAMESPENALTY_LINETHREE' | translate }}</p>
                        <a class="learnmore" target="_blank" (click)="openlearnmorelink()">{{
                            'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEARNMORE' | translate
                        }}</a>
                        <div class="button-wrapper d-flex">
                            <button class="close-btn" (click)="closePopup()">
                                {{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_CLOSE_TEXT' | translate }}
                            </button>
                            <button class="nav-btn" (click)="openff()">
                                {{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_GOTOFFLOBBYTXT' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert_content" *ngIf="athalertpopup">
                <div class="header-wrapper">
                    <div class="warning-header">{{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_ALERT' | translate }}</div>
                    <span class="close theme-ex" (click)="closePopup()"></span>
                </div>
                <div class="content-wrapper">
                    <div class="warn-container">
                        <span class="theme-attention" alt="Warning symbol"></span>
                    </div>
                    <div class="content-text-wrapper text-center">
                        <p class="text-one">
                            {{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_PLEASENOTETXT' | translate }} -
                            <span class="text-one-one"> {{ 'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_MOBILELOGINMSG_LINEONE' | translate }} </span>
                        </p>
                        <p class="mb-5">
                            {{ mobileLoginMsg }}
                        </p>
                        <div>
                            <button class="btn btn-md btn-primary w-50" (click)="closePopup()">
                                {{ 'PARTY_POKER_GC_GAMETABLE_OK' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
