import { Message } from '../../message';

export class PAMArticle extends Message {
    private MESSAGE_NAME: string = 'PAMArticle';
    private articleID: number = 0;
    private priority: number = 0;
    private articleCategory: number = 0;
    private isPersonal: boolean | null = false;
    private isCBTArticle: boolean | null = false;
    private startTime: number = 0;
    private endTime: number = 0;
    private languageId: string | null = null;
    private isBaseHtml: boolean | null = false;
    private toBeShown: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        articleID: number = 0,
        priority: number = 0,
        articleCategory: number = 0,
        isPersonal: boolean | null = false,
        isCBTArticle: boolean | null = false,
        startTime: number = 0,
        endTime: number = 0,
        languageId: string | null = null,
        isBaseHtml: boolean | null = false,
        toBeShown: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.articleID = articleID;
        this.priority = priority;
        this.articleCategory = articleCategory;
        this.isPersonal = isPersonal;
        this.isCBTArticle = isCBTArticle;
        this.startTime = startTime;
        this.endTime = endTime;
        this.languageId = languageId;
        this.isBaseHtml = isBaseHtml;
        this.toBeShown = toBeShown;
    }

    getArticleID(): number {
        return this.articleID;
    }

    setArticleID(articleID: number) {
        this.articleID = articleID;
    }
    getPriority(): number {
        return this.priority;
    }

    setPriority(priority: number) {
        this.priority = priority;
    }
    getArticleCategory(): number {
        return this.articleCategory;
    }

    setArticleCategory(articleCategory: number) {
        this.articleCategory = articleCategory;
    }
    getIsPersonal(): boolean | null {
        return this.isPersonal;
    }

    setIsPersonal(isPersonal: boolean | null) {
        this.isPersonal = isPersonal;
    }
    getIsCBTArticle(): boolean | null {
        return this.isCBTArticle;
    }

    setIsCBTArticle(isCBTArticle: boolean | null) {
        this.isCBTArticle = isCBTArticle;
    }
    getStartTime(): number {
        return this.startTime;
    }

    setStartTime(startTime: number) {
        this.startTime = startTime;
    }
    getEndTime(): number {
        return this.endTime;
    }

    setEndTime(endTime: number) {
        this.endTime = endTime;
    }
    getLanguageId(): string | null {
        return this.languageId;
    }

    setLanguageId(languageId: string | null) {
        this.languageId = languageId;
    }
    getIsBaseHtml(): boolean | null {
        return this.isBaseHtml;
    }

    setIsBaseHtml(isBaseHtml: boolean | null) {
        this.isBaseHtml = isBaseHtml;
    }
    getToBeShown(): boolean | null {
        return this.toBeShown;
    }

    setToBeShown(toBeShown: boolean | null) {
        this.toBeShown = toBeShown;
    }
}
