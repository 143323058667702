// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class InfoTableView extends PIXI.Container {
    displayUtil = new DisplayUtil();
    buttons;
    backBgTouchStarted = false;
    constructor() {
        super();
    }
    createView = function (EventDispatcher) {
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        this.blackBG = this.displayUtil.getRectangleSprite(
            (window.innerWidth * 2) / this.scale.x,
            (window.innerHeight * 2) / this.scale.y,
            '#000000',
        );
        this.displayUtil.setObjectPosition(this.blackBG, -this.x / this.scale.x, -this.y / this.scale.y);
        this.addChild(this.blackBG);
        this.blackBG.name = 'blackBG';
        this.blackBG.alpha = 0;
        this.blackBG.visible = false;
        this.blackBG.buttonMode = false;
        this.displayUtil.setButtonActive(this.blackBG, true);
        this.blackBG.on('touchstart', this.blackBgTouchStart.bind(this));
        this.blackBG.on('touchend', this.blackBgTouchEnd.bind(this));
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait;
        this.buttons = this.positionConfig.infoPanelButtons;
        this.visible = false;
        this.setSize(720, 1280);
        // this.displayUtil.setContainerScaleFitToScreen(this);
        //this.resizeView();
    };
    blackBgTouchStart = function (events) {
        if (events.data.originalEvent.touches && events.data.originalEvent.touches.length <= 1) {
            this.backBgTouchStarted = true;
        }
    };
    blackBgTouchEnd = function () {
        if (this.backBgTouchStarted) {
            this.backBgTouchStarted = false;
            this.closeBtnClick();
        }
    };
    showinfo = function (sngJPPayoutInfoData, tableStatusData) {
        this.tableStatusData = tableStatusData;
        this.sngJPPayoutInfoData = sngJPPayoutInfoData;
        this.backBgTouchStarted = false;
        /*created two different methods to handle 3 and seater, based on gameTableType -- 0 for spins, 1 for FF*/
        if (this.gameTableType == 0) {
            this.createInfoBackground();
            if (this.tableStatusData != null) {
                const buyInValue = this.tableStatusData.tourneyBuyIn + this.tableStatusData.tourneyEntryFee + this.tableStatusData.bountyFee;
                this.cardValueText.text =
                    CommonGameProperties.mathUtil['transform'](buyInValue, tableStatusData.gameCurrency) +
                    ' ' +
                    CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_SNGJP_FILTER_SNGJACKPOT');
                this.tableIdText.text = '#' + this.tableStatusData.mtctId;
            }
            if (this.sngJPPayoutInfoData != null) {
                if (this.sngJPPayoutInfoData.prizesInfo.length > 0) {
                    this.createSprite('prizeTableBg', 'prizePoolContainer', 'table_' + (this.sngJPPayoutInfoData.prizesInfo.length + 1), 65, 469);
                    if (this.sngJPPayoutInfoData.prizesInfo.length === 3) {
                        this.prizeTableBg.scale.set(1.9);
                    }
                    this.prizeTableBg.width = 592;
                    this.prizeTableBg.scaleY = this.prizeTableBg.scaleX;
                    this.createText(
                        'prizePollTotalPrizeText',
                        'prizePoolContainer',
                        CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_SNGJP_TABLE_JPINFOTOTALPRIZE'),
                        166,
                        506,
                        this.fontProps.infoPrizeText,
                    );
                    this.createText(
                        'prizePollTotalAmount',
                        'prizePoolContainer',
                        CommonGameProperties.numberFormatter['transform'](this.sngJPPayoutInfoData.totalPrize, 'USD'),
                        560,
                        506,
                        this.fontProps.infoPrizeText,
                    );
                    const rankDetails = [
                        {
                            textPosX: 166,
                            textPosY: 591,
                            amountPosX: 560,
                            amountPosY: 591,
                            textKey: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMELOBBY_GAMEINFOLISTFIRSTPLACE') + ':',
                            //textKey: "1st place" + ":"
                        },
                        {
                            textPosX: 166,
                            textPosY: 676,
                            amountPosX: 560,
                            amountPosY: 676,
                            textKey: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_SNGJP_TABLE_JPINFOSCNDPLACE'),
                            //textKey: "2nd Place:"
                        },
                        {
                            textPosX: 166,
                            textPosY: 761,
                            amountPosX: 560,
                            amountPosY: 761,
                            textKey: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_SNGJP_TABLE_JPINFOTRDPLACE'),
                        },
                    ];
                    for (let i = 0; i < this.sngJPPayoutInfoData.prizesInfo.length; i++) {
                        this.createText(
                            'PrizeText' + (i + 1),
                            'prizePoolContainer',
                            rankDetails[i].textKey,
                            rankDetails[i].textPosX,
                            rankDetails[i].textPosY,
                            this.fontProps.infoPrizeText,
                        );
                        this.createText(
                            'PrizeAmount' + (i + 1),
                            'prizePoolContainer',
                            CommonGameProperties.numberFormatter['transform'](this.sngJPPayoutInfoData.prizesInfo[i].prizeAmount, 'USD'),
                            rankDetails[i].amountPosX,
                            rankDetails[i].amountPosY,
                            this.fontProps.infoPrizeText,
                        );
                    }
                }
            }
        } else {
            this.createFFInfoBlock();
            this.hardRankBtnClick();
        }
        //this.resizeView();
        this.visible = true;
        this.blackBG.visible = true;
    };
    closeInfo = function () {
        this.visible = false;
    };
    createSprite = function (spriteName, parentName, sprite, xPos, yPos) {
        this[spriteName] = this.displayUtil.getSprite(sprite);
        this[parentName].addChild(this[spriteName]);
        this.displayUtil.setObjectPosition(this[spriteName], xPos, yPos);
    };
    createText = function (textName, parentName, text, xPos, yPos, reqStyle) {
        this[textName] = this.displayUtil.getTextField(reqStyle);
        this[parentName].addChild(this[textName]);
        this[textName].text = text;
        this[textName].anchor.set(0.5);
        this.displayUtil.setObjectPosition(this[textName], xPos, yPos);
    };
    createInfoBackground = function () {
        this.infoContainer = this.displayUtil.getContainer();
        this.addChild(this.infoContainer);

        this.createSprite('infoBg', 'infoContainer', 'info_bg', 25, 127);
        this.createText('gameInfoLabelText', 'infoBg', 'GAME INFO', 340, 45, this.fontProps.gameInfoTitleText);

        this.closeButton = this.displayUtil.getButton('close');
        this.infoBg.addChild(this.closeButton);
        this.displayUtil.addInteraction(this.closeButton, this.closeBtnClick.bind(this));
        this.displayUtil.setClickable(this.closeButton, true);
        this.displayUtil.setObjectPosition(this.closeButton, 609, 26);
        this.displayUtil.assignHitArea(
            this.closeButton,
            {
                x: 0,
                y: -10,
                w: this.closeButton.width + 20,
                h: this.closeButton.height + 20,
            },
            'rect',
        );
        this.createSprite('prizePoolButton_down', 'infoBg', 'right_down', 25, 117);

        this.displayUtil.addInteraction(this.prizePoolButton_down, this.prizePoolBtnClick.bind(this));
        this.displayUtil.setClickable(this.prizePoolButton_down, true);

        this.createSprite('prizePoolButton_up', 'prizePoolButton_down', 'right_up', 2, 81);

        this.displayUtil.setClickable(this.prizePoolButton_up, true);
        this.prizePoolButton_up.visible = true;

        // this.createText(
        //   "prizePoolBtnText",
        //   "prizePoolButton_down",
        //   this.gameService.getLangPack().getLiteral("PARTY_POKER_GC_COMMON_PRIZEPOOL"),
        //   100,
        //   44,
        //   this.fontProps.infoSwitchTabTextBold
        // );

        this.createText('prizePoolBtnText', 'prizePoolButton_down', 'Prizepool', 100, 44, this.fontProps.infoSwitchTabTextBold);

        this.createSprite('hardRankButton_down', 'infoBg', 'center_down', 232, 117);

        this.displayUtil.addInteraction(this.hardRankButton_down, this.hardRankBtnClick.bind(this));
        this.displayUtil.setClickable(this.hardRankButton_down, true);

        this.createSprite('hardRankButton_up', 'hardRankButton_down', 'centre_up', 0, 81);

        this.displayUtil.setClickable(this.prizePoolButton_up, true);
        this.hardRankButton_up.visible = false;

        // this.createText("hardRankBtnText", "hardRankButton_down",
        //   this.gameService.getLangPack().getLiteral("PARTY_POKER_MOBILE_TABLE_TERMS_HANDRANK", "", "Hand Rank"), 100, 44, this.fontProps.infoSwitchTabText);

        this.createText('hardRankBtnText', 'hardRankButton_down', 'Hand Rank', 100, 44, this.fontProps.infoSwitchTabText);

        this.createSprite('gameRulesButton_down', 'infoBg', 'left_down', 439, 117);

        this.displayUtil.addInteraction(this.gameRulesButton_down, this.gameRulseBtnClick.bind(this));
        this.displayUtil.setClickable(this.gameRulesButton_down, true);

        this.createSprite('gameRulesButton_up', 'gameRulesButton_down', 'left_up', 2, 81);

        this.displayUtil.setClickable(this.gameRulesButton_up, true);
        this.gameRulesButton_up.visible = false;

        // this.createText("gameRulseBtnText", "gameRulesButton_down",
        //   this.gameService.getLangPack().getLiteral("PARTY_POKER_GS_FECONFIG_MENU_GAMERULES"),
        //   103, 44, this.fontProps.infoSwitchTabText);
        this.createText('gameRulseBtnText', 'gameRulesButton_down', 'Game Rules', 103, 44, this.fontProps.infoSwitchTabText);

        this.createPrizePoolBlock();
        this.createHardRankblock();
        this.createGameRulseBlock();
    };
    createPrizePoolBlock = function () {
        this.prizePoolContainer = this.displayUtil.getContainer();
        this.infoContainer.addChild(this.prizePoolContainer);

        this.createText('cardValueText', 'prizePoolContainer', '', 330, 390, this.fontProps.infoSpinsText);
        this.cardValueText.anchor.set(0.5);
        this.createText('tableIdText', 'prizePoolContainer', '', 330, 432, this.fontProps.infoTableIdText);
        this.tableIdText.anchor.set(0.5);
        this.prizePoolContainer.visible = true;
    };
    createHardRankblock = function () {
        this.hardRankContainer = this.displayUtil.getContainer();
        this.infoContainer.addChild(this.hardRankContainer);

        this.createSprite('hardrankBg', 'hardRankContainer', 'handrank_bg1', 50, 350);

        this.createText(
            'handText',
            'hardrankBg',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_TERMS_HAND', '', 'Hand:'),
            145,
            30,
            this.fontProps.infoHardRankTextBold,
        );
        this.createText(
            'handTitleText',
            'hardrankBg',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_COMMON_TITLE', '', 'Title'),
            450,
            30,
            this.fontProps.infoHardRankTextBold,
        );
        this.createSprite('royalFlushImg', 'hardrankBg', 'royal_flush', 25, 85);
        this.createText(
            'royalFlushText',
            'hardrankBg',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_RankNameRoyalFlush'),
            455,
            114,
            this.fontProps.infoHardRankText,
        );

        this.createSprite('strightFlushImg', 'hardrankBg', 'straight_flush', 25, 153);

        this.createText(
            'strightFlushText',
            'hardrankBg',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_RANKS_STRAIGHTFLUSH', '', 'Straight Flush'),
            455,
            179,
            this.fontProps.infoHardRankText,
        );

        this.createSprite('fourOfaKindImg', 'hardrankBg', 'four_kind', 25, 221);

        this.createText(
            'fourOfaKindText',
            'hardrankBg',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_TABLE_HAND_EVAL_FOUR_OF_A_KIND'),
            455,
            247,
            this.fontProps.infoHardRankText,
        );

        if (this.gameType == serverIdConstants.GAME_TYPE_SHORT_DECK) {
            this.createSprite('flushImg', 'hardrankBg', 'flush', 25, 289);
            this.createText(
                'flushText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_RankNameFlush'),
                455,
                314,
                this.fontProps.infoHardRankText,
            );

            this.createSprite('fullHouseImg', 'hardrankBg', 'full_house', 25, 357);
            this.createText(
                'fullHouseText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_RANKS_FULLHOUSE', '', 'Full House'),
                455,
                382,
                this.fontProps.infoHardRankText,
            );
        } else {
            this.createSprite('fullHouseImg', 'hardrankBg', 'full_house', 25, 289);
            this.createText(
                'fullHouseText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_RANKS_FULLHOUSE', '', 'Full House'),
                455,
                314,
                this.fontProps.infoHardRankText,
            );
            this.createSprite('flushImg', 'hardrankBg', 'flush', 25, 357);
            this.createText(
                'flushText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_RankNameFlush'),
                455,
                382,
                this.fontProps.infoHardRankText,
            );
            this.createSprite('strightImg', 'hardrankBg', 'straight', 25, 425);

            this.createText(
                'strightText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_RankNameStraight'),
                455,
                450,
                this.fontProps.infoHardRankText,
            );

            this.createSprite('threeOfaKindImg', 'hardrankBg', 'three_kind', 25, 493);

            this.createText(
                'threeOfaKindText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_TABLE_HAND_EVAL_THREE_OF_A_KIND'),
                455,
                520,
                this.fontProps.infoHardRankText,
            );

            this.createSprite('twoPairImg', 'hardrankBg', 'two_pair', 25, 561);

            this.createText(
                'twoPairText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_RANKS_TWOPAIR', '', 'Two Pair'),
                455,
                589,
                this.fontProps.infoHardRankText,
            );

            this.createSprite('pairImg', 'hardrankBg', 'one_pair', 25, 629);

            this.createText(
                'pairText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_RankNamePair'),
                455,
                660,
                this.fontProps.infoHardRankText,
            );

            this.createSprite('highCardImg', 'hardrankBg', 'high_card', 25, 700);

            this.createText(
                'highCardText',
                'hardrankBg',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_RANKS_HIGHCARD', '', 'High Card'),
                455,
                725,
                this.fontProps.infoHardRankText,
            );

            this.hardRankContainer.visible = false;
        }
    };
    createGameRulseBlock = function () {
        this.gameRulseContainer = this.displayUtil.getContainer();
        this.infoContainer.addChild(this.gameRulseContainer);
        this.ffGameRulesContainer = this.displayUtil.getContainer();
        this.infoContainer.addChild(this.ffGameRulesContainer);
        this.cashGameRulesContainer = this.displayUtil.getContainer();
        this.infoContainer.addChild(this.cashGameRulesContainer);
        this.shortDeckRulesContainer = this.displayUtil.getContainer();
        this.infoContainer.addChild(this.shortDeckRulesContainer);
        let gameRulseSubText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmInfoDialogSnjpGameRules');
        gameRulseSubText = CommonGameProperties.langPack['getLiteralFromLine'](gameRulseSubText, 1);
        gameRulseSubText = CommonGameProperties.langPack['replaceAllSlashesN'](gameRulseSubText);
        this.createSprite('gamerulseMainText', 'gameRulseContainer', 'handrank_bg2', 50, 350);

        this.createText('gamerulseSubText', 'gameRulseContainer', gameRulseSubText, 365, 650, this.fontProps.infoGameRulseText);
        this.createSprite('ffGameInfoBg', 'ffGameRulesContainer', 'handrank_bg2', 50, 350);
        this.ffGameInfo = this.displayUtil.getTextField(this.gamerulseSubText.style);

        this.ffGameInfo.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_FASTFORWARDQL_RHPGAMEDESC', '', '', true);
        this.displayUtil.setObjectPosition(this.ffGameInfo, 30, 20);
        this.ffGameInfoBg.addChild(this.ffGameInfo);

        this.createSprite('cashGameInfoBg', 'cashGameRulesContainer', 'handrank_bg2', 50, 350);
        this.cashGameInfo = this.displayUtil.getTextField(this.gamerulseSubText.style);

        this.cashGameInfo.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_COMMON_CASHGAMERULES', '', '', true);
        this.displayUtil.setObjectPosition(this.cashGameInfo, 30, 20);
        this.cashGameInfoBg.addChild(this.cashGameInfo);

        this.createSprite('shortDeckGameInfoBg', 'shortDeckRulesContainer', 'handrank_bg2', 50, 350);
        this.shortDeckGameInfo = this.displayUtil.getTextField(this.gamerulseSubText.style);

        this.shortDeckGameInfo.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_COMMON_SHORTDECKRULES', '', '', true);
        this.displayUtil.setObjectPosition(this.shortDeckGameInfo, 30, 20);
        this.shortDeckGameInfoBg.addChild(this.shortDeckGameInfo);

        this.gameRulseContainer.visible = false;
        this.ffGameRulesContainer.visible = false;
        this.cashGameRulesContainer.visible = false;
        this.shortDeckRulesContainer.visible = false;
    };
    closeBtnClick = function () {
        this.visible = false;
        if (this.gameTableType == 0) {
            this.prizePoolContainer.visible = true;
            this.prizePoolBtnText.style = this.fontProps.infoSwitchTabTextBold;
            this.prizePoolButton_up.visible = true;
        }
        this.hardRankContainer.visible = false;
        this.gameRulseContainer.visible = false;
        this.ffGameRulesContainer.visible = false;
        this.cashGameRulesContainer.visible = false;
        this.shortDeckRulesContainer.visible = false;

        this.hardRankBtnText.style = this.fontProps.infoSwitchTabText;
        this.gameRulseBtnText.style = this.fontProps.infoSwitchTabText;

        this.hardRankButton_up.visible = false;
        this.gameRulesButton_up.visible = false;
        this.blackBG.visible = false;
    };
    prizePoolBtnClick = function () {
        this.prizePoolContainer.visible = true;
        this.hardRankContainer.visible = false;
        this.gameRulseContainer.visible = false;
        this.ffGameRulesContainer.visible = false;
        this.cashGameRulesContainer.visible = false;
        this.shortDeckRulesContainer.visible = false;

        this.prizePoolBtnText.style = this.fontProps.infoSwitchTabTextBold;
        this.hardRankBtnText.style = this.fontProps.infoSwitchTabText;
        this.gameRulseBtnText.style = this.fontProps.infoSwitchTabText;
        this.prizePoolButton_up.visible = true;
        this.hardRankButton_up.visible = false;
        this.gameRulesButton_up.visible = false;
    };
    hardRankBtnClick = function () {
        if (this.gameTableType == 0) {
            this.prizePoolContainer.visible = false;
            this.prizePoolBtnText.style = this.fontProps.infoSwitchTabText;
            this.prizePoolButton_up.visible = false;
        }
        this.hardRankContainer.visible = true;
        this.gameRulseContainer.visible = false;
        this.ffGameRulesContainer.visible = false;
        this.cashGameRulesContainer.visible = false;
        this.shortDeckRulesContainer.visible = false;

        this.hardRankBtnText.style = this.fontProps.infoSwitchTabTextBold;
        this.gameRulseBtnText.style = this.fontProps.infoSwitchTabText;

        this.hardRankButton_up.visible = true;
        this.gameRulesButton_up.visible = false;
    };
    gameRulseBtnClick = function () {
        this.hardRankContainer.visible = false;
        // if(this.gameTableType == 0){
        //   this.prizePoolContainer.visible = false;
        //   this.prizePoolBtnText.style = this.fontProps.infoSwitchTabText;
        //   this.prizePoolButton_up.visible = false;

        //   this.gameRulseContainer.visible = true;
        // }
        // else{
        //   this.ffGameRulesContainer.visible = true;
        // }
        switch (this.gameTableType) {
            case serverIdConstants.TABLE_TYPE_SPINS:
                this.prizePoolContainer.visible = false;
                this.prizePoolBtnText.style = this.fontProps.infoSwitchTabText;
                this.prizePoolButton_up.visible = false;

                this.gameRulseContainer.visible = true;
                break;
            case serverIdConstants.TABLE_TYPE_FF:
                this.ffGameRulesContainer.visible = true;
                break;
            case serverIdConstants.TABLE_TYPE_CASH:
                if (this.gameType == serverIdConstants.GAME_TYPE_SHORT_DECK) {
                    this.shortDeckRulesContainer.visible = true;
                } else {
                    this.cashGameRulesContainer.visible = true;
                }
                break;
            default:
                break;
        }
        this.hardRankBtnText.style = this.fontProps.infoSwitchTabText;
        this.gameRulseBtnText.style = this.fontProps.infoSwitchTabTextBold;

        this.hardRankButton_up.visible = false;
        this.gameRulesButton_up.visible = true;
    };

    resizeView = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        this.infoContainer.scale.set(Math.min(scaleXValue, scaleYValue));
        this.blackBG.scale.set(Math.max(scaleXValue, scaleYValue));
        this.infoContainer.x = (uiConfig.windowWidth - this.infoContainer.width) / 2;
    };
    currentGameTableType = function (gameTableType, gameType) {
        this.gameTableType = gameTableType;
        this.gameType = gameType;
    };
    createFFInfoBlock = function () {
        this.infoContainer = this.displayUtil.getContainer();
        this.addChild(this.infoContainer);

        this.createSprite('infoBg', 'infoContainer', 'info_bg', 25, 127);

        this.createText(
            'gameInfoLabelText',
            'infoBg',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_TERMS_GAMEINFO', '', 'GAME INFO'),
            340,
            45,
            this.fontProps.gameInfoTitleText,
        );
        this.closeButton = this.displayUtil.getButton('close');
        this.infoBg.addChild(this.closeButton);
        this.displayUtil.addInteraction(this.closeButton, this.closeBtnClick.bind(this));
        this.displayUtil.setClickable(this.closeButton, true);
        this.displayUtil.setObjectPosition(this.closeButton, 609, 26);
        this.displayUtil.assignHitArea(
            this.closeButton,
            {
                x: 0,
                y: -10,
                w: this.closeButton.width + 20,
                h: this.closeButton.height + 20,
            },
            'rect',
        );

        this.createSprite('hardRankButton_down', 'infoBg', 'right_down', 26, 117);
        this.displayUtil.addInteraction(this.hardRankButton_down, this.hardRankBtnClick.bind(this));
        this.displayUtil.setClickable(this.hardRankButton_down, true);
        this.hardRankButton_down.scale.x = 1.5;

        this.createSprite('hardRankButton_up', 'hardRankButton_down', 'right_up', 0, 81);
        this.hardRankButton_up.visible = false;
        this.createText(
            'hardRankBtnText',
            'infoContainer',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_TERMS_HANDRANK', '', 'Hand Rank'),
            207,
            287,
            this.fontProps.infoSwitchTabText,
        );
        this.createSprite('gameRulesButton_down', 'infoBg', 'left_down', 333, 117);
        this.displayUtil.addInteraction(this.gameRulesButton_down, this.gameRulseBtnClick.bind(this));
        this.displayUtil.setClickable(this.gameRulesButton_down, true);

        this.createSprite('gameRulesButton_up', 'gameRulesButton_down', 'left_up', 2, 81);
        this.gameRulesButton_down.scale.x = 1.5;
        this.gameRulesButton_up.visible = false;

        this.createText(
            'gameRulseBtnText',
            'infoContainer',
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_FECONFIG_MENU_GAMERULES'),
            510,
            287,
            this.fontProps.infoSwitchTabText,
        );
        //this.createPrizePoolBlock();
        this.createHardRankblock();
        this.createGameRulseBlock();
    };
}
