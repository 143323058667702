import { Message } from '../../message';

export class MTCTPrizeInfo extends Message {
    private MESSAGE_NAME: string = 'MTCTPrizeInfo';
    private message: any | null = null;
    private totalDistributed: number = 0;
    private dealDistributed: number = 0;
    private totalPrizePool: number = 0;
    private remainingPrizePool: number = 0;
    private ignorePrizesUpdate: boolean | null = false;
    private prizeMessages: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        message: any | null = null,
        totalDistributed: number = 0,
        dealDistributed: number = 0,
        totalPrizePool: number = 0,
        remainingPrizePool: number = 0,
        ignorePrizesUpdate: boolean | null = false,
        prizeMessages: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.message = message;
        this.totalDistributed = totalDistributed;
        this.dealDistributed = dealDistributed;
        this.totalPrizePool = totalPrizePool;
        this.remainingPrizePool = remainingPrizePool;
        this.ignorePrizesUpdate = ignorePrizesUpdate;
        this.prizeMessages = prizeMessages;
    }

    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getTotalDistributed(): number {
        return this.totalDistributed;
    }

    setTotalDistributed(totalDistributed: number) {
        this.totalDistributed = totalDistributed;
    }
    getDealDistributed(): number {
        return this.dealDistributed;
    }

    setDealDistributed(dealDistributed: number) {
        this.dealDistributed = dealDistributed;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getRemainingPrizePool(): number {
        return this.remainingPrizePool;
    }

    setRemainingPrizePool(remainingPrizePool: number) {
        this.remainingPrizePool = remainingPrizePool;
    }
    getIgnorePrizesUpdate(): boolean | null {
        return this.ignorePrizesUpdate;
    }

    setIgnorePrizesUpdate(ignorePrizesUpdate: boolean | null) {
        this.ignorePrizesUpdate = ignorePrizesUpdate;
    }
    getPrizeMessages(): any[] | null {
        return this.prizeMessages;
    }

    setPrizeMessages(prizeMessages: any[] | null) {
        this.prizeMessages = prizeMessages;
    }
}
