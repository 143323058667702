import { Routes } from '@angular/router';

import { PokerPartypokerChampionshipSynopsisResolver } from './poker-partypoker-championship-synopsis.resolver';
import { PokerPartypokerChampionshipResolver } from './poker-partypoker-championship.resolver';

export const pokerPartypokerChampionshipRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPartypokerChampionshipResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerPartypokerChampionshipSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerPartypokerChampionshipResolver,
        },
    },
];
