import { Message } from '../../message';

export class ResponseQuickSeatRing extends Message {
    private MESSAGE_NAME: string = 'ResponseQuickSeatRing';
    private requestId: number = 0;
    private responseCode: number = 0;
    private resultList: any[] | null = null;
    private cardId: number = 0;
    private prevSearchedSeats: any[] | null = null;
    private nextRecommendedSeater: number = 0;
    private athBlockTime: number = 0;
    private responseErrorValue: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        responseCode: number = 0,
        resultList: any[] | null = null,
        cardId: number = 0,
        prevSearchedSeats: any[] | null = null,
        nextRecommendedSeater: number = 0,
        athBlockTime: number = 0,
        responseErrorValue: number = 0,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.responseCode = responseCode;
        this.resultList = resultList;
        this.cardId = cardId;
        this.prevSearchedSeats = prevSearchedSeats;
        this.nextRecommendedSeater = nextRecommendedSeater;
        this.athBlockTime = athBlockTime;
        this.responseErrorValue = responseErrorValue;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getResultList(): any[] | null {
        return this.resultList;
    }

    setResultList(resultList: any[] | null) {
        this.resultList = resultList;
    }
    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getPrevSearchedSeats(): any[] | null {
        return this.prevSearchedSeats;
    }

    setPrevSearchedSeats(prevSearchedSeats: any[] | null) {
        this.prevSearchedSeats = prevSearchedSeats;
    }
    getNextRecommendedSeater(): number {
        return this.nextRecommendedSeater;
    }

    setNextRecommendedSeater(nextRecommendedSeater: number) {
        this.nextRecommendedSeater = nextRecommendedSeater;
    }
    getAthBlockTime(): number {
        return this.athBlockTime;
    }

    setAthBlockTime(athBlockTime: number) {
        this.athBlockTime = athBlockTime;
    }
    getResponseErrorValue(): number {
        return this.responseErrorValue;
    }

    setResponseErrorValue(responseErrorValue: number) {
        this.responseErrorValue = responseErrorValue;
    }
}
