import { Message } from '../../message';

export class PopUpInfoExResponse extends Message {
    private MESSAGE_NAME: string = 'PopUpInfoExResponse';
    private infoType: number = 0;
    private buttonPressed: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, infoType: number = 0, buttonPressed: number = 0) {
        super(reqServerPeerId);
        this.infoType = infoType;
        this.buttonPressed = buttonPressed;
    }

    getInfoType(): number {
        return this.infoType;
    }

    setInfoType(infoType: number) {
        this.infoType = infoType;
    }
    getButtonPressed(): number {
        return this.buttonPressed;
    }

    setButtonPressed(buttonPressed: number) {
        this.buttonPressed = buttonPressed;
    }
}
