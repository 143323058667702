/* eslint-disable @typescript-eslint/no-unused-vars */
import { ByteBuffer } from '../Lib/byteBuffer';
import { PGMessageFactoryRegistration } from '../PGMessageFactoryRegistration';
import TSUtil from '../common/tsUtil';
import { MessageFactoryManager } from '../messageFactoryManager';
import { CodedOutputStream } from './codedOutputStream';

export class TSDataOutputStream {
    private messageFactoryManager: MessageFactoryManager;
    cos: CodedOutputStream;
    isBCEnabled: boolean;
    private msgFactryReg: PGMessageFactoryRegistration;

    constructor(msgFactryReg: PGMessageFactoryRegistration, isBCEnabled?: boolean) {
        this.msgFactryReg = msgFactryReg;
        this.messageFactoryManager = new MessageFactoryManager(this.msgFactryReg);
        this.cos = new CodedOutputStream();
        // this.isBCEnabled = isBCEnabled;
    }

    putBoolean(val: boolean | null): void {
        this.cos.writeBool(val);
    }

    putByte(val: any): void {
        this.cos.writeFixed8(val);
    }

    putShort(val: any): void {
        this.cos.writeInt32(val);
    }

    putInteger(val: any): void {
        this.cos.writeInt32(val);
    }

    putLong(val: number): void {
        this.cos.writeInt64(val);
    }

    putString(val: string | null): void {
        if (val === null) {
            this.cos.writeFixed8(0);
            return;
        }
        this.cos.writeString(val);
    }

    putStringEx(val: any): void {
        if (!val) {
            this.cos.writeFixed8(0);
            return;
        }
        this.putByte(1);
        this.putInteger(val.templateId);
        this.putStringArray(val.params);
        this.putBoolean(val.isLPElement);
    }

    putObject(val: any): void {
        if (!val) {
            this.cos.writeInt32(0);
            return;
        }
        if (!val.classId) {
            const classId = this.messageFactoryManager.getClassIdforClass(val);
            this.cos.writeInt32(classId);
        } else {
            this.cos.writeInt32(val.classId);
        }
        /** for an embedded message, write message length before the message **/
        if (!val.gettopLevelMessage()) {
            //&& this.isBCEnabled
            //create a temporary stream to write messgae to know its length
            let _ostream: any = new TSDataOutputStream(this.msgFactryReg); //this.isBCEnabled
            const startposition = _ostream.getPosition(); //start position
            this.messageFactoryManager.messageWrite(val, _ostream); //message write
            const endposition = _ostream.getPosition(); //end position
            //writing the length to the original stream
            this.cos.writeInt32(endposition - startposition);
            //append the bytes to the original stream
            const _byteArray = _ostream.getBytes().array;
            for (let i = 0; i < _byteArray.length; i++) {
                this.cos.buffer.put(_byteArray[i]);
            }
            //get rid of temporary stream
            _ostream = null;
        } else {
            this.messageFactoryManager.messageWrite(val, this);
        }
    }

    putBytes(val: any | null): void {
        if (val === null) {
            this.cos.writeFixed8(0);
            return;
        }
        this.cos.writeBytes(val);
    }

    putObjectArray(val: any[] | null): void {
        this.putArray(val, TSUtil.ENTITY_TYPES.OBJECT);
    }

    putStringArray(val: string[] | null): void {
        this.putArray(val, TSUtil.ENTITY_TYPES.STRING);
    }

    putIntArray(val: number[] | null): void {
        this.putArray(val, TSUtil.ENTITY_TYPES.INT);
    }

    putLongArray(val: number[] | null): void {
        this.putArray(val, TSUtil.ENTITY_TYPES.LONG);
    }

    putStringExArray(val: any[] | null): void {
        this.putArray(val, TSUtil.ENTITY_TYPES.STRINGEX);
    }

    putArray(val: any[] | null, type: any): void {
        if (!val || val.length === 0) {
            this.cos.writeFixed8(0);
            return;
        }
        let size = 0;
        for (const _count in val) {
            size++;
        }

        this.cos.writeInt32(size);
        this.cos.writeFixed8(TSUtil.NATURES.HOMOGENEOUS);
        this.cos.writeFixed8(type);

        switch (type) {
            case TSUtil.ENTITY_TYPES.OBJECT:
                for (let i = 0; i < size; i++) {
                    this.putObject(val[i]);
                }
                break;

            case TSUtil.ENTITY_TYPES.STRING:
                for (let i = 0; i < size; i++) {
                    this.putString(val[i]);
                }
                break;

            case TSUtil.ENTITY_TYPES.INT:
                for (let i = 0; i < size; i++) {
                    this.putInteger(val[i]);
                }
                break;

            case TSUtil.ENTITY_TYPES.LONG:
                for (let i = 0; i < size; i++) {
                    this.putLong(val[i]);
                }
                break;

            case TSUtil.ENTITY_TYPES.STRINGEX:
                for (let i = 0; i < size; i++) {
                    this.putStringEx(val[i]);
                }
                break;
        }
    }

    putShort2ShortMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.SHORT, TSUtil.ENTITY_TYPES.SHORT);
    }

    putShort2StringMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.SHORT, TSUtil.ENTITY_TYPES.STRING);
    }

    putShort2IntMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.SHORT, TSUtil.ENTITY_TYPES.INT);
    }

    putShort2LongMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.SHORT, TSUtil.ENTITY_TYPES.LONG);
    }

    putInt2IntMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.INT, TSUtil.ENTITY_TYPES.INT);
    }

    putInt2StringMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.INT, TSUtil.ENTITY_TYPES.STRING);
    }

    putString2IntMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.STRING, TSUtil.ENTITY_TYPES.INT);
    }

    putString2StringMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.STRING, TSUtil.ENTITY_TYPES.STRING);
    }

    putByte2LongMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.BYTE, TSUtil.ENTITY_TYPES.LONG);
    }

    putByte2IntMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.BYTE, TSUtil.ENTITY_TYPES.INT);
    }

    putInt2BoolMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.INT, TSUtil.ENTITY_TYPES.BOOLEAN);
    }

    putInt2ObjectMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.INT, TSUtil.ENTITY_TYPES.OBJECT);
    }

    putByte2ObjectArrayMap(val: any): void {
        //this.putMap(val,TSUtil.ENTITY_TYPES.BYTE, TSUtil.ENTITY_TYPES.OBJECT);
        alert('TO-DO: This method is not implemented.');
    }

    putInt2LongMap(val: any): void {
        this.putMap(val, TSUtil.ENTITY_TYPES.INT, TSUtil.ENTITY_TYPES.LONG);
    }

    putMap(val: any, keyType: any, valueType: any): void {
        if (!val) {
            this.cos.writeFixed8(0);
            return;
        }

        const size = Object.keys(val).length;

        if (size === 0) {
            this.cos.writeFixed8(0);
            return;
        }

        this.cos.writeInt32(size);
        this.cos.writeFixed8(TSUtil.NATURES.HOMOGENEOUS);
        this.cos.writeFixed8(keyType);
        this.cos.writeFixed8(valueType);

        switch (keyType + '2' + valueType) {
            case TSUtil.ENTITY_TYPES.SHORT + '2' + TSUtil.ENTITY_TYPES.SHORT:
                for (const key in val) {
                    this.putShort(key);
                    this.putShort(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.SHORT + '2' + TSUtil.ENTITY_TYPES.INT:
                for (const key in val) {
                    this.putShort(key);
                    this.putInteger(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.SHORT + '2' + TSUtil.ENTITY_TYPES.LONG:
                for (const key in val) {
                    this.putShort(key);
                    this.putLong(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.INT + '2' + TSUtil.ENTITY_TYPES.INT:
                for (const key in val) {
                    this.putInteger(key);
                    this.putInteger(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.INT + '2' + TSUtil.ENTITY_TYPES.STRING:
                for (const key in val) {
                    this.putInteger(key);
                    this.putString(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.STRING + '2' + TSUtil.ENTITY_TYPES.STRING:
                for (const key in val) {
                    this.putString(key);
                    this.putString(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.BYTE + '2' + TSUtil.ENTITY_TYPES.LONG:
                for (const key in val) {
                    this.putByte(key);
                    this.putLong(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.INT + '2' + TSUtil.ENTITY_TYPES.BOOLEAN:
                for (const key in val) {
                    this.putInteger(key);
                    this.putBoolean(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.INT + '2' + TSUtil.ENTITY_TYPES.OBJECT:
                for (const key in val) {
                    this.putInteger(key);
                    // this.putPGSerializable(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.INT + '2' + TSUtil.ENTITY_TYPES.LONG:
                for (const key in val) {
                    this.putInteger(key);
                    this.putLong(val[key]);
                }
                break;

            case TSUtil.ENTITY_TYPES.SHORT + '2' + TSUtil.ENTITY_TYPES.STRING:
                for (const key in val) {
                    this.putShort(key);
                    this.putString(val[key]);
                }
                break;
        }
    }

    putTimestamp(val: number): void {
        this.putLong(val);
    }

    getBytes(): ByteBuffer {
        return this.cos.getBytes();
    }

    getPosition(): number {
        return this.cos.getPosition();
    }

    setPosition(position: number): void {
        this.cos.setPosition(position);
    }

    string(srtValue) {
        return { type: TSUtil.ENTITY_TYPES.STRING, value: srtValue };
    }

    Object(obj) {
        return { type: TSUtil.ENTITY_TYPES.OBJECT, value: obj };
    }
}
