import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, Router, RouterEvent } from '@angular/router';

import { NavigationService, Page, ProductService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { CurrentSessionConfig } from '@frontend/vanilla/shared/current-session';
import { Store } from '@ngrx/store';
import { PWCNavigationClientConfig } from '@pokercore/module/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import moment from 'moment';
import { filter } from 'rxjs';

import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { PokerWebClientLoginService } from '../../service/poker-web-client-login.service';
import { userIsAuthenticatedSelect } from '../../store/user.store';

@Component({
    selector: 'pwc-client-footer',
    templateUrl: 'client-footer.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
})
export class ClientFooterComponent extends PCComponent implements OnInit, OnDestroy {
    apiBaseUrl: string;
    menuItems: any;
    activeTime: any;
    // startTime: any = moment();
    timeInterval: any;
    presentTime: any = moment();
    showLicenseWrapper = false;
    selectedMenuItem = 0;
    isUserAuthenticated = false;
    regulatoryIcons: any;
    regulatoryContent: any;
    private readonly logger: PokerLogger;
    startTime: any = Date.now();
    secondsInterval: any;
    constructor(
        public productService: ProductService,
        private page: Page,
        public sharedService: PokerWebClientSharedService,
        private navigation: NavigationService,
        private pwcNavigationClientConfig: PWCNavigationClientConfig,
        private router: Router,
        private store: Store,
        loggerFactory: LoggerFactory,
        // private userService: UserService,
        // private translatePipe:TranslatePipe,
        public clientToServer: ClientToServerRequestServices,
        private currentSessionConfig: CurrentSessionConfig,
        private pokerWebClientLoginService: PokerWebClientLoginService,
    ) {
        super();
        this.logger = loggerFactory.getLogger('ClientFooterLoggingComponent');
        this.menuItems = this.pwcNavigationClientConfig?.items.find((item) => item.name === 'bottomnavigation');
    }

    ngOnInit(): void {
        this.router.events.pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)).subscribe((e: RouterEvent) => {
            if (!e.url.includes('/play/portal')) {
                this.selectedMenuItem = 0;
            }
        });
        this.store.select(userIsAuthenticatedSelect).subscribe((data) => {
            this.isUserAuthenticated = data;
            // if (this.isUserAuthenticated === true) {
            //     this.updateActiveTime();
            // }
        });
        this.secondsInterval = 60 - new Date().getSeconds();
        this.currentTime();
        this.timeInterval = setInterval(() => {
            this.currentTime();
            // this.updateActiveTime();
            this.secondsInterval = 60;
        }, this.secondsInterval * 1000);
        this.apiBaseUrl = this.productService.getMetadata('poker').apiBaseUrl;
        this.timeInterval = setInterval(() => {
            // this.updateActiveTime();
            this.presentTime = moment().locale(this.page.culture).locale(this.page.culture).format('hh:mm A');
        }, 1000);

        // make it active the footer item if user cliecked on LHN offers item
        this.sharedService.getPortalPage().subscribe((info) => {
            if (info) {
                this.selectedMenuItem = this.menuItems.children.findIndex((item) => item.name === info.name);
            }
        });
    }

    updateActiveTime() {
        if (this.isUserAuthenticated) {
            const loginDuration = this.currentSessionConfig.loginDuration || 0;
            const currentTime = Date.now();
            const elapsedTime = currentTime - this.startTime;
            const totalElapsedTime = elapsedTime + loginDuration;

            // Calculate hours and minutes from elapsed time
            const hours = Math.floor(totalElapsedTime / (1000 * 60 * 60));
            const minutes = Math.floor((totalElapsedTime % (1000 * 60 * 60)) / (1000 * 60));

            // Format the time duration
            this.activeTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        }
    }

    addLeadingZero(number: any) {
        return String(number).padStart(2, '0');
    }

    onMenuClick(activePage, index) {
        this.logger.info('clicked on poker,casino ect.. icons..!' + JSON.stringify(activePage, index));
        if (activePage.name === 'poker') {
            this.navigation.goTo('/play');
            this.selectedMenuItem = index;
        } else if (activePage.name === 'deposit') {
            if (this.isUserAuthenticated === true) {
                this.clientToServer.openDepositPage('deposit');
                this.selectedMenuItem = index;
            } else {
                this.pokerWebClientLoginService.requestForPortalLogin();
            }
        } else {
            this.sharedService.sendPortalPage({
                ...activePage,
                showBalanceBlock: this.isUserAuthenticated === true && (activePage.name == 'casino' || activePage.name == 'sports') ? true : false,
            });
            this.navigation.goTo('/play/' + 'portal');
            this.selectedMenuItem = index;
        }
    }

    onLicenseClick() {
        this.logger.info('clicked on regulatory icon..!');
        this.regulatoryContent = this.sharedService.regulatoryIconsData?.contentMessages;
        this.regulatoryIcons = this.sharedService.regulatoryIconsData?.regulatoryIcons;

        this.showLicenseWrapper = !this.showLicenseWrapper;
    }

    currentTime() {
        this.presentTime = moment().locale(this.page.culture).locale(this.page.culture).format('hh:mm A');
    }

    ngOnDestroy() {
        clearInterval(this.timeInterval);
    }
}
