import { Message } from '../../message';

export class SaveFile extends Message {
    private MESSAGE_NAME: string = 'SaveFile';
    private fileContent: any[] | null = null;
    private fileName: string | null = null;
    private location: string | null = null;
    private languageId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        fileContent: any[] | null = null,
        fileName: string | null = null,
        location: string | null = null,
        languageId: string | null = null,
    ) {
        super(reqServerPeerId);
        this.fileContent = fileContent;
        this.fileName = fileName;
        this.location = location;
        this.languageId = languageId;
    }

    getFileContent(): any[] | null {
        return this.fileContent;
    }

    setFileContent(fileContent: any[] | null) {
        this.fileContent = fileContent;
    }
    getFileName(): string | null {
        return this.fileName;
    }

    setFileName(fileName: string | null) {
        this.fileName = fileName;
    }
    getLocation(): string | null {
        return this.location;
    }

    setLocation(location: string | null) {
        this.location = location;
    }
    getLanguageId(): string | null {
        return this.languageId;
    }

    setLanguageId(languageId: string | null) {
        this.languageId = languageId;
    }
}
