<!doctype html>
<html lang="en">
    <head>
        <meta charset="utf-8" />
        <title>HHReplayer</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </head>

    <body>
        <div class="web-veiw-wrapper">
            <div
                class="game-table-wrapper"
                *ngIf="gameTableService.gtInitializeDone"
                (click)="closeGameTable($event)"
                [class.vcClient]="vcClient"
                [class.m-NativeApp]="mNativeApp"
                [class.macDesktop]="macDesktop"
                [attr.roundid]="gameTableService.gameTable.roundID">
                <div class="game-table-innerwrapper web-veiw" [class.tablet]="isTablet" [class.mobileReplayer]="isMobile">
                    <div class="close-btn theme-ex" (click)="closeGameTable($event)"></div>

                    <div class="tableInfo s-screen">
                        <div class="tableInfo-content">
                            <span class="tableName">{{ gameTableService.gameTable.name }} - </span>
                            <span class="gameLimit" *ngIf="!gameTableService.gameTable.isShortDeck">{{ gameTableService.gameTable.gameLimit }}</span>
                            <span class="gameType" *ngIf="!gameTableService.gameTable.isShortDeck"
                                >{{ gameTableService.gameTable.gType }} ({{ gameTableService.gameTable.maxSeats }}-{{ content.Max }}) -
                            </span>
                            <span class="gameType" *ngIf="gameTableService.gameTable.isShortDeck"
                                >{{ gameTableService.gameTable.gType }}
                                <span class="gameLimit" *ngIf="gameTableService.gameTable.isShortDeck">{{
                                    gameTableService.gameTable.gameLimit
                                }}</span>
                                Holde'm ({{ gameTableService.gameTable.maxSeats }}-{{ content.Max }}) -
                            </span>
                            <span class="bids" *ngIf="gameTableService.gameTable.smallBlind > 0 && gameTableService.gameTable.bigBlind > 0">
                                <span *ngIf="!gameTableService.gameTable.isTourny">{{ gameTableService.gameTable.pot.gameCurrency }}</span
                                >{{ gameTableService.gameTable.smallBlind / 100 }}/
                                <span *ngIf="!gameTableService.gameTable.isTourny">{{ gameTableService.gameTable.pot.gameCurrency }}</span
                                >{{ gameTableService.gameTable.bigBlind / 100 }}
                                <span
                                    class="ante"
                                    *ngIf="!gameTableService.gameTable.isTourny && gameTableService.gameTable.isAnteCashGameOrTourny === 1"
                                    >Ante {{ gameTableService.gameTable.pot.gameCurrency }}</span
                                ><span *ngIf="gameTableService.gameTable.anteAmount > 0">{{
                                    gameTableService.gameTable.anteAmount / 100
                                }}</span></span
                            >
                            <div
                                class="chips-reduce-head"
                                *ngIf="!gameTableService.gameTable.isReplayerV3 && gameTableService.gameTable.isReducedChipsEnabled">
                                {{ content.BalanceReductionMessage }}
                            </div>
                        </div>
                        <div class="handID">
                            <span class="handId">{{ content.Habd }}#&nbsp;{{ gameTableService.gameTable.roundID.toLocaleString() }}</span>
                        </div>
                    </div>

                    <div class="Player-id" id="Player-id">
                        <span class="hand">#&nbsp;{{ gameTableService.gameTable.roundID.toLocaleString() }}</span>
                        <span class="title">{{ content.Replay }}</span>
                        <div class="close-btn theme-ex mobile" (click)="closeGameTable($event)"></div>
                    </div>

                    <div
                        class="chips-reduce-head"
                        *ngIf="gameTableService.gameTable.isReducedChipsEnabled && gameTableService.gameTable.isReplayerV3">
                        {{ content.BalanceReductionMessage }}
                    </div>

                    <span class="rake s-screen" *ngIf="!gameTableService.gameTable.isTourny"
                        >{{ content.Rake }} :<span class="rakeAmount"
                            >&nbsp;{{ gameTableService.gameTable.pot.gameCurrency
                            }}{{ (gameTableService.gameTable.totalRake / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}</span
                        ></span
                    >

                    <div class="game-table-container" id="game-table-container">
                        <div class="game-player-info" *ngIf="!gameTableService.showErrorMessage">
                            <div class="step1">
                                <span class="bids" *ngIf="gameTableService.gameTable.smallBlind > 0 && gameTableService.gameTable.bigBlind > 0">
                                    <span *ngIf="!gameTableService.gameTable.isTourny">{{ gameTableService.gameTable.pot.gameCurrency }}</span>
                                    {{ gameTableService.gameTable.smallBlind / 100 }}/
                                    <span *ngIf="!gameTableService.gameTable.isTourny">{{ gameTableService.gameTable.pot.gameCurrency }}</span>
                                    {{ gameTableService.gameTable.bigBlind / 100 }}
                                </span>
                                <span class="tableName"> - {{ gameTableService.gameTable.name }} </span>
                            </div>
                            <div class="step2">
                                <span class="gameLimit" *ngIf="!gameTableService.gameTable.isShortDeck">{{
                                    gameTableService.gameTable.gameLimit
                                }}</span>

                                <span class="gameType" *ngIf="!gameTableService.gameTable.isShortDeck"
                                    >{{ gameTableService.gameTable.gType }} ({{ gameTableService.gameTable.maxSeats }}-{{ content.Max }})</span
                                >

                                <span class="gameType" *ngIf="gameTableService.gameTable.isShortDeck"
                                    >{{ gameTableService.gameTable.gType }}
                                    <span class="gameLimit" *ngIf="gameTableService.gameTable.isShortDeck">{{
                                        gameTableService.gameTable.gameLimit
                                    }}</span>
                                    Holde'm ({{ gameTableService.gameTable.maxSeats }}-{{ content.Max }})
                                </span>
                            </div>
                        </div>
                        <div class="game-player-info" [class.error]="gameTableService.showErrorMessage" *ngIf="gameTableService.showErrorMessage">
                            {{ content.TenSeaterErrorMessage }}
                        </div>
                        <div class="hand-Info">
                            <span class="replayer-icon"></span>
                            <span class="replay-text">Replay</span>
                            <span class="chips-reduce-head" *ngIf="gameTableService.gameTable.isReducedChipsEnabled">
                                {{ content.BalanceReductionMessage }}
                            </span>
                        </div>
                        <div class="rake">
                            <span class="rake" *ngIf="!gameTableService.gameTable.isTourny"
                                >{{ content.Rake }} :<span class="rakeAmount"
                                    >&nbsp;{{ gameTableService.gameTable.pot.gameCurrency
                                    }}{{ (gameTableService.gameTable.totalRake / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}</span
                                ></span
                            >
                        </div>
                        <div class="table-border"></div>
                        <div class="table-ring"></div>
                        <div class="tableInfo h-screen">
                            <span class="tableName">{{ gameTableService.gameTable.name }} - </span>
                            <span class="gameLimit" *ngIf="!gameTableService.gameTable.isShortDeck">{{ gameTableService.gameTable.gameLimit }}</span>
                            <span class="gameType" *ngIf="!gameTableService.gameTable.isShortDeck"
                                >{{ gameTableService.gameTable.gType }} ({{ gameTableService.gameTable.maxSeats }}-{{ content.Max }}) -
                            </span>
                            <span class="gameType" *ngIf="gameTableService.gameTable.isShortDeck"
                                >{{ gameTableService.gameTable.gType }}
                                <span class="gameLimit" *ngIf="gameTableService.gameTable.isShortDeck">{{
                                    gameTableService.gameTable.gameLimit
                                }}</span>
                                Holde'm ({{ gameTableService.gameTable.maxSeats }}-{{ content.Max }}) -
                            </span>
                            <span class="bids" *ngIf="gameTableService.gameTable.smallBlind > 0 && gameTableService.gameTable.bigBlind > 0">
                                <span *ngIf="!gameTableService.gameTable.isTourny">{{ gameTableService.gameTable.pot.gameCurrency }}</span
                                >{{ gameTableService.gameTable.smallBlind / 100 }}/
                                <span *ngIf="!gameTableService.gameTable.isTourny">{{ gameTableService.gameTable.pot.gameCurrency }}</span
                                >{{ gameTableService.gameTable.bigBlind / 100 }}
                                <span
                                    class="ante"
                                    *ngIf="!gameTableService.gameTable.isTourny && gameTableService.gameTable.isAnteCashGameOrTourny === 1"
                                    >Ante {{ gameTableService.gameTable.pot.gameCurrency }}</span
                                ><span *ngIf="gameTableService.gameTable.anteAmount > 0">{{
                                    gameTableService.gameTable.anteAmount / 100
                                }}</span></span
                            >
                            <div class="handID">
                                <span class="handId">{{ content.Habd }}#&nbsp;{{ gameTableService.gameTable.roundID.toLocaleString() }}</span>
                            </div>
                        </div>
                        <div *ngIf="!gameTableService.showErrorMessage">
                            <pkr-player *ngFor="let player of gameTableService.gameTable.players" [player]="player"> </pkr-player>
                            <pkr-dealer [dealer]="gameTableService.gameTable.dealer" *ngIf="gameTableService.gameTable.dealer"> </pkr-dealer>
                            <pkr-pot
                                [pot]="gameTableService.gameTable.pot"
                                [gameTable]="gameTableService.gameTable"
                                [totalPot]="gameTableService.gameTable.MAIN_POT.totalValue"></pkr-pot>
                            <pkr-community-cards
                                [cards]="gameTableService.gameTable.communityCards"
                                [gameTable]="gameTableService.gameTable"></pkr-community-cards>
                            <pkr-player-pots [players]="gameTableService.gameTable.players" [gameTable]="gameTableService.gameTable">
                            </pkr-player-pots>
                            <pkr-table-text-view
                                class="text-view-hh-screen"
                                [gameTable]="gameTableService.gameTable"
                                [player]="gameTableService.gameTable.players"></pkr-table-text-view>
                        </div>
                        <div class="play-control h-screen">
                            <div class="backGround">
                                <div class="controls">
                                    <a
                                        class="prev"
                                        (click)="gameTableService.prev(true)"
                                        [class.disable]="gameTableService.gameTable.disablePrevButton">
                                        <span class="tooltiptext">{{ content.PrevStep }}</span>
                                    </a>

                                    <a
                                        class="play"
                                        (click)="gameTableService.play()"
                                        [class.pause]="gameTableService.gameTable.pause"
                                        [class.replay]="gameTableService.gameTable.replay">
                                        <span class="tooltiptext">{{ content.Play }}</span>
                                        <span class="tooltiptext pause">{{ content.Pause }}</span>
                                        <span class="tooltiptext replay">{{ content.Replay }}</span>
                                    </a>
                                    <a class="replay" (click)="gameTableService.reset()">
                                        <span class="tooltiptext">{{ content.Reset }}</span>
                                    </a>
                                    <a
                                        class="next"
                                        (click)="gameTableService.next(true)"
                                        [class.disable]="gameTableService.gameTable.disableNextButton">
                                        <span class="tooltiptext">{{ content.NextStep }}</span>
                                    </a>
                                </div>
                                <div class="game-change-controls">
                                    <a
                                        class="start"
                                        (click)="gameTableService.goToReplayer('next', $event)"
                                        [class.disable]="gameTableService.disableNextHandButton">
                                        <span class="tooltiptext">{{ content.PrevHand }}</span>
                                    </a>
                                    <a
                                        class="end"
                                        (click)="gameTableService.goToReplayer('prev', $event)"
                                        [class.disable]="gameTableService.disablePrevHandButton">
                                        <span class="tooltiptext">{{ content.NextHand }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="slidecontainer h-screen" [ngClass]="{ 'cashout-max': maxSeats >= 7 }">
                            <input
                                type="range"
                                min="0"
                                max="4"
                                list="tickmarks"
                                [attr.value]="gameTableService.currentReplaySpeed"
                                class="slider"
                                id="myRange"
                                (mouseup)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                                (keyup)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                                (touchend)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                                [(ngModel)]="gameTableService.currentReplaySpeed" />
                        </div>
                        <div class="mobile-player-control">
                            <div class="slidecontainer h-screen" *ngIf="!gameTableService.showErrorMessage">
                                <input
                                    type="range"
                                    min="0"
                                    max="4"
                                    list="tickmarks"
                                    [attr.value]="gameTableService.currentReplaySpeed"
                                    class="slider"
                                    id="myRange"
                                    (mouseup)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                                    (keyup)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                                    (touchend)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                                    [(ngModel)]="gameTableService.currentReplaySpeed" />
                            </div>
                            <div class="backGround">
                                <div class="controls">
                                    <a
                                        class="start"
                                        (click)="gameTableService.goToReplayer('next', $event)"
                                        [class.disable]="gameTableService.disableNextHandButton">
                                        <span class="tooltiptext">{{ content.PrevHand }}</span>
                                    </a>
                                    <a
                                        class="prev"
                                        (click)="gameTableService.prev(true)"
                                        [class.disable]="gameTableService.gameTable.disablePrevButton || gameTableService.showErrorMessage">
                                        <span class="tooltiptext">{{ content.PrevStep }}</span>
                                    </a>
                                    <a
                                        class="play"
                                        (click)="gameTableService.play()"
                                        [class.pause]="gameTableService.gameTable.pause"
                                        [class.replay]="gameTableService.gameTable.replay"
                                        [class.disable]="gameTableService.showErrorMessage">
                                        <span class="tooltiptext">{{ content.Play }}</span>
                                        <span class="tooltiptext pause">{{ content.Pause }}</span>
                                        <span class="tooltiptext replay">{{ content.Replay }}</span>
                                    </a>
                                    <a
                                        class="next"
                                        (click)="gameTableService.next(true)"
                                        [class.disable]="gameTableService.gameTable.disableNextButton || gameTableService.showErrorMessage">
                                        <span class="tooltiptext">{{ content.NextStep }}</span>
                                    </a>
                                    <a
                                        class="end"
                                        (click)="gameTableService.goToReplayer('prev', $event)"
                                        [class.disable]="gameTableService.disablePrevHandButton">
                                        <span class="tooltiptext">{{ content.NextHand }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            class="aamsIdDetails h-screen"
                            *ngIf="
                                gameTableService.gameTable.isAamsIdPresent &&
                                gameTableService.gameTable.requestingPlayerSessionAamsId.length > 0 &&
                                gameTableService.gameTable.requestingPlayerUserAamsId.length > 0
                            ">
                            <span class="sid">{{ content.Sid }} : {{ gameTableService.gameTable.requestingPlayerSessionAamsId }}</span
                            >/
                            <span class="pid"> {{ content.Pid }} : {{ gameTableService.gameTable.requestingPlayerUserAamsId }}</span>
                        </div>
                    </div>
                    <pkr-table-text-view
                        class="text-view-sm-screen"
                        [gameTable]="gameTableService.gameTable"
                        [player]="gameTableService.gameTable.players"></pkr-table-text-view>
                    <div class="play-control s-screen">
                        <div class="backGround">
                            <div class="controls">
                                <a class="prev" (click)="gameTableService.prev(true)" [class.disable]="gameTableService.gameTable.disablePrevButton">
                                    <span class="tooltiptext">{{ content.PrevStep }}</span>
                                </a>

                                <a
                                    class="play"
                                    (click)="gameTableService.play()"
                                    [class.pause]="gameTableService.gameTable.pause"
                                    [class.replay]="gameTableService.gameTable.replay">
                                    <span class="tooltiptext">{{ content.Play }}</span>
                                    <span class="tooltiptext pause">{{ content.Pause }}</span>
                                    <span class="tooltiptext replay">{{ content.Replay }}</span>
                                </a>
                                <a class="replay" (click)="gameTableService.reset()">
                                    <span class="tooltiptext">{{ content.Reset }}</span>
                                </a>
                                <a class="next" (click)="gameTableService.next(true)" [class.disable]="gameTableService.gameTable.disableNextButton">
                                    <span class="tooltiptext">{{ content.NextStep }}</span>
                                </a>
                            </div>
                            <div class="game-change-controls">
                                <a
                                    class="start"
                                    (click)="gameTableService.goToReplayer('next', $event)"
                                    [class.disable]="gameTableService.disableNextHandButton">
                                    <span class="tooltiptext">{{ content.PrevHand }}</span>
                                </a>
                                <a
                                    class="end"
                                    (click)="gameTableService.goToReplayer('prev', $event)"
                                    [class.disable]="gameTableService.disablePrevHandButton">
                                    <span class="tooltiptext">{{ content.NextHand }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="aamsIdDetails s-screen"
                        *ngIf="gameTableService.gameTable.requestingPlayerSessionAamsId && gameTableService.gameTable.requestingPlayerUserAamsId">
                        <span class="sid">{{ content.Sid }} : {{ gameTableService.gameTable.requestingPlayerSessionAamsId }}</span
                        >/
                        <span class="pid"> {{ content.Pid }} : {{ gameTableService.gameTable.requestingPlayerUserAamsId }}</span>
                    </div>
                    <div class="slidecontainer s-screen" *ngIf="gameTableService.disableSlider">
                        <input
                            type="range"
                            min="0"
                            max="4"
                            list="tickmarks"
                            [attr.value]="gameTableService.currentReplaySpeed"
                            class="slider"
                            id="myRange"
                            (mouseup)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                            (keyup)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                            (touchend)="gameTableService.slider(gameTableService.currentReplaySpeed)"
                            [(ngModel)]="gameTableService.currentReplaySpeed" />
                    </div>
                </div>
                <div class="landScape" *ngIf="isMobileRotatedToLandScape && isMobile"></div>
            </div>
        </div>
    </body>
</html>
