import { Routes } from '@angular/router';

import { PokerMillionsOnlineSynopsisResolver } from './poker-millionsonline-synopsis.resolver';
import { PokerMillionsOnlineResolver } from './poker-millionsonline.resolver';

export const pokerMillionsOnlineRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMillionsOnlineResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerMillionsOnlineSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMillionsOnlineResolver,
        },
    },
];
