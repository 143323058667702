import { Message } from '../../message';

export class RealityCheckSummaryPopUp extends Message {
    private MESSAGE_NAME: string = 'RealityCheckSummaryPopUp';
    private sessionDuration: number = 0;
    private netWinAmount: number = 0;
    private currencyCode: string | null = null;
    private wagerAmt: number = 0;
    private winAmt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sessionDuration: number = 0,
        netWinAmount: number = 0,
        currencyCode: string | null = null,
        wagerAmt: number = 0,
        winAmt: number = 0,
    ) {
        super(reqServerPeerId);
        this.sessionDuration = sessionDuration;
        this.netWinAmount = netWinAmount;
        this.currencyCode = currencyCode;
        this.wagerAmt = wagerAmt;
        this.winAmt = winAmt;
    }

    getSessionDuration(): number {
        return this.sessionDuration;
    }

    setSessionDuration(sessionDuration: number) {
        this.sessionDuration = sessionDuration;
    }
    getNetWinAmount(): number {
        return this.netWinAmount;
    }

    setNetWinAmount(netWinAmount: number) {
        this.netWinAmount = netWinAmount;
    }
    getCurrencyCode(): string | null {
        return this.currencyCode;
    }

    setCurrencyCode(currencyCode: string | null) {
        this.currencyCode = currencyCode;
    }
    getWagerAmt(): number {
        return this.wagerAmt;
    }

    setWagerAmt(wagerAmt: number) {
        this.wagerAmt = wagerAmt;
    }
    getWinAmt(): number {
        return this.winAmt;
    }

    setWinAmt(winAmt: number) {
        this.winAmt = winAmt;
    }
}
