import { Message } from '../../message';

export class ResponseSNGPromoDeltaChanges extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGPromoDeltaChanges';
    private deltaEvents: any[] | null = null;
    private lastSnapShotTimeInNanos: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, deltaEvents: any[] | null = null, lastSnapShotTimeInNanos: number = 0) {
        super(reqServerPeerId);
        this.deltaEvents = deltaEvents;
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }

    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
}
