import { Message } from '../../message';

export class ResponseRealName extends Message {
    private MESSAGE_NAME: string = 'ResponseRealName';
    private screenName: string | null = null;
    private fullName: string | null = null;
    private realName: string | null = null;
    private gameId: number = 0;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        screenName: string | null = null,
        fullName: string | null = null,
        realName: string | null = null,
        gameId: number = 0,
        seatNo: number = 0,
    ) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.fullName = fullName;
        this.realName = realName;
        this.gameId = gameId;
        this.seatNo = seatNo;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getFullName(): string | null {
        return this.fullName;
    }

    setFullName(fullName: string | null) {
        this.fullName = fullName;
    }
    getRealName(): string | null {
        return this.realName;
    }

    setRealName(realName: string | null) {
        this.realName = realName;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
