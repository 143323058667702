import { GameTable } from '../game-table';
import { Player } from '../player';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class AllInCompletBringIn extends TableEventInfo {
    player: Player;
    getEventType(): TableEvents {
        return TableEvents.ALL_IN_COMPLETE_BRING_IN;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.ALL_IN_COMPLETE_BRING_IN;
    }
    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.addToPot(this.amount);
        gameTable.addToPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        if (!gameTable.onInit) {
            player.highlightEvent();
        }
        player.isPlayerAllIn = true;
        gameTable.mpot = false;
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.subtractFromPot(this.amount);
        gameTable.substractFromPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
        player.isPlayerAllIn = false;
    }
}
