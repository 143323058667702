export class SpinInfoModel {
    constructor() {}
    resetDataModel = function () {};
    getSngJackpotID = function () {
        return this.sngJackpotID;
    };
    getSngJPInstanceID = function () {
        return this.sngJPInstanceID;
    };
    SNGJackpotInfo = function (obj) {
        this.sngJackpotID = obj.sngJackpotID;
        this.name = obj.name;
        this.buyIn = obj.buyIn;
        this.tourneyFee = obj.tourneyFee;
        this.gameCurrency = obj.gameCurrency;
        this.sngJPType = obj.sngJPType;
        this.gameType = obj.gameType;
        this.limitType = obj.limitType;
        this.maxSeats = obj.maxSeats;
        this.maxRegistrations = obj.maxRegistrations;
        this.gamePlayType = obj.gamePlayType;
        this.minMultiplier = obj.minMultiplier;
        this.maxMultiplier = obj.maxMultiplier;
        this.serverPeerId = obj.serverPeerId;
        this.sngJPPayoutInfo = obj.sngJPPayoutInfo;
        this.selectedMultiplier = obj.selectedMultiplier;
        this.minMultiplierNew = obj.minMultiplierNew;
        this.maxMultiplierNew = obj.maxMultiplierNew;
        this.tableBackgroundImage = obj.tableBackgroundImage;
        this.spinCardType = obj.cardType;
        if (this.sngJPPayoutInfo) {
            this.sngJPInstanceID = obj.sngJPPayoutInfo.sngJPInstanceID;
        }
    };
    getSngJPPayoutInfo = function () {
        return this.sngJPPayoutInfo;
    };
    setSNGJPPayoutAnimation = function (obj) {
        this.SNGJPPayoutAniData = obj;
        this.tableBackgroundImage = obj.tableBackgroundImage;
        if (obj.sngJPPayoutInfo) this.sngJPPayoutInfo = obj.sngJPPayoutInfo;
    };
    setSNGJPTournamentRankInfo = function (obj) {
        this.SNGJPTournamentRankInfo = obj;
    };
    getSNGJPTournamentRankInfo = function () {
        return this.SNGJPTournamentRankInfo;
    };
    getSNGJPPayoutAnimation = function () {
        return this.SNGJPPayoutAniData;
    };
    setSNGJPGameAboutToStart = function (obj) {
        this.SNGJPGameAboutToStartData = obj;
        this.sngJackpotID = obj.sngJackpotID;
        this.sngJPInstanceID = obj.sngJPInstanceID;
        if (obj.tableBackgroundImage) this.tableBackgroundImage = obj.tableBackgroundImage;
        this.selectedMultiplier = obj.selectedMultiplier;
        //this.selectedMultiplierNew = selectedMultiplierNew;
    };
    getsetSNGJPGameAboutToStart = function () {
        return this.SNGJPGameAboutToStartData;
    };
    getTableBgType = function () {
        return this.tableBackgroundImage;
    };
    getSpinCardType = function () {
        return this.spinCardType;
    };
}
