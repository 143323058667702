import { Message } from '../../message';

export class ShowPopup extends Message {
    private MESSAGE_NAME: string = 'ShowPopup';
    private popupId: number = 0;
    private description: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, popupId: number = 0, description: string | null = null) {
        super(reqServerPeerId);
        this.popupId = popupId;
        this.description = description;
    }

    getPopupId(): number {
        return this.popupId;
    }

    setPopupId(popupId: number) {
        this.popupId = popupId;
    }
    getDescription(): string | null {
        return this.description;
    }

    setDescription(description: string | null) {
        this.description = description;
    }
}
