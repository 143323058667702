import { Message } from '../../message';

export class AppFriendsListRequest extends Message {
    private MESSAGE_NAME: string = 'AppFriendsListRequest';
    private accessToken: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, accessToken: string | null = null) {
        super(reqServerPeerId);
        this.accessToken = accessToken;
    }

    getAccessToken(): string | null {
        return this.accessToken;
    }

    setAccessToken(accessToken: string | null) {
        this.accessToken = accessToken;
    }
}
