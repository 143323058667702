import { Message } from '../../message';

export class EDSNGResponseMessage extends Message {
    private MESSAGE_NAME: string = 'EDSNGResponseMessage';
    private eventDataId: number = 0;
    private acceptanceStatus: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, eventDataId: number = 0, acceptanceStatus: number = 0) {
        super(reqServerPeerId);
        this.eventDataId = eventDataId;
        this.acceptanceStatus = acceptanceStatus;
    }

    getEventDataId(): number {
        return this.eventDataId;
    }

    setEventDataId(eventDataId: number) {
        this.eventDataId = eventDataId;
    }
    getAcceptanceStatus(): number {
        return this.acceptanceStatus;
    }

    setAcceptanceStatus(acceptanceStatus: number) {
        this.acceptanceStatus = acceptanceStatus;
    }
}
