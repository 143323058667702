import { Message } from '../../message';

export class SelectedScreenName extends Message {
    private MESSAGE_NAME: string = 'SelectedScreenName';
    private screeName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screeName: string | null = null) {
        super(reqServerPeerId);
        this.screeName = screeName;
    }

    getScreeName(): string | null {
        return this.screeName;
    }

    setScreeName(screeName: string | null) {
        this.screeName = screeName;
    }
}
