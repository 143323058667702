import { Message } from '../../message';

export class RequestQuickSeatRing extends Message {
    private MESSAGE_NAME: string = 'RequestQuickSeatRing';
    private requestId: number = 0;
    private tableIdList: any[] | null = null;
    private gameType: number = 0;
    private limitType: number = 0;
    private lowerStakesOrBlinds: number = 0;
    private maxSeats: number = 0;
    private speedType: number = 0;
    private selectedBuyIn: number = 0;
    private minBuyIn: number = 0;
    private noOfTables: number = 0;
    private anonymousFlag: number = 0;
    private actionType: number = 0;
    private cardId: number = 0;
    private beginnerFlag: number = 0;
    private prevSearchedSeats: any[] | null = null;
    private protectionLevel: number = 0;
    private actionButtonId: number = 0;
    private subLiquidityType: number = 0;
    private realNameTable: boolean | null = false;
    private anteAmount: number = 0;
    private restrictedRoomId: number = 0;
    private clubId: number = 0;
    private upperStakesOrBlinds: number = 0;
    private noOfSeats: number = 0;
    private buyInAmount: number = 0;
    private rebuyToMax: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        tableIdList: any[] | null = null,
        gameType: number = 0,
        limitType: number = 0,
        lowerStakesOrBlinds: number = 0,
        maxSeats: number = 0,
        speedType: number = 0,
        selectedBuyIn: number = 0,
        minBuyIn: number = 0,
        noOfTables: number = 0,
        anonymousFlag: number = 0,
        actionType: number = 0,
        cardId: number = 0,
        beginnerFlag: number = 0,
        prevSearchedSeats: any[] | null = null,
        protectionLevel: number = 0,
        actionButtonId: number = 0,
        subLiquidityType: number = 0,
        realNameTable: boolean | null = false,
        anteAmount: number = 0,
        restrictedRoomId: number = 0,
        clubId: number = 0,
        upperStakesOrBlinds: number = 0,
        noOfSeats: number = 0,
        buyInAmount: number = 0,
        rebuyToMax: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.tableIdList = tableIdList;
        this.gameType = gameType;
        this.limitType = limitType;
        this.lowerStakesOrBlinds = lowerStakesOrBlinds;
        this.maxSeats = maxSeats;
        this.speedType = speedType;
        this.selectedBuyIn = selectedBuyIn;
        this.minBuyIn = minBuyIn;
        this.noOfTables = noOfTables;
        this.anonymousFlag = anonymousFlag;
        this.actionType = actionType;
        this.cardId = cardId;
        this.beginnerFlag = beginnerFlag;
        this.prevSearchedSeats = prevSearchedSeats;
        this.protectionLevel = protectionLevel;
        this.actionButtonId = actionButtonId;
        this.subLiquidityType = subLiquidityType;
        this.realNameTable = realNameTable;
        this.anteAmount = anteAmount;
        this.restrictedRoomId = restrictedRoomId;
        this.clubId = clubId;
        this.upperStakesOrBlinds = upperStakesOrBlinds;
        this.noOfSeats = noOfSeats;
        this.buyInAmount = buyInAmount;
        this.rebuyToMax = rebuyToMax;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getTableIdList(): any[] | null {
        return this.tableIdList;
    }

    setTableIdList(tableIdList: any[] | null) {
        this.tableIdList = tableIdList;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getLowerStakesOrBlinds(): number {
        return this.lowerStakesOrBlinds;
    }

    setLowerStakesOrBlinds(lowerStakesOrBlinds: number) {
        this.lowerStakesOrBlinds = lowerStakesOrBlinds;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getSpeedType(): number {
        return this.speedType;
    }

    setSpeedType(speedType: number) {
        this.speedType = speedType;
    }
    getSelectedBuyIn(): number {
        return this.selectedBuyIn;
    }

    setSelectedBuyIn(selectedBuyIn: number) {
        this.selectedBuyIn = selectedBuyIn;
    }
    getMinBuyIn(): number {
        return this.minBuyIn;
    }

    setMinBuyIn(minBuyIn: number) {
        this.minBuyIn = minBuyIn;
    }
    getNoOfTables(): number {
        return this.noOfTables;
    }

    setNoOfTables(noOfTables: number) {
        this.noOfTables = noOfTables;
    }
    getAnonymousFlag(): number {
        return this.anonymousFlag;
    }

    setAnonymousFlag(anonymousFlag: number) {
        this.anonymousFlag = anonymousFlag;
    }
    getActionType(): number {
        return this.actionType;
    }

    setActionType(actionType: number) {
        this.actionType = actionType;
    }
    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getBeginnerFlag(): number {
        return this.beginnerFlag;
    }

    setBeginnerFlag(beginnerFlag: number) {
        this.beginnerFlag = beginnerFlag;
    }
    getPrevSearchedSeats(): any[] | null {
        return this.prevSearchedSeats;
    }

    setPrevSearchedSeats(prevSearchedSeats: any[] | null) {
        this.prevSearchedSeats = prevSearchedSeats;
    }
    getProtectionLevel(): number {
        return this.protectionLevel;
    }

    setProtectionLevel(protectionLevel: number) {
        this.protectionLevel = protectionLevel;
    }
    getActionButtonId(): number {
        return this.actionButtonId;
    }

    setActionButtonId(actionButtonId: number) {
        this.actionButtonId = actionButtonId;
    }
    getSubLiquidityType(): number {
        return this.subLiquidityType;
    }

    setSubLiquidityType(subLiquidityType: number) {
        this.subLiquidityType = subLiquidityType;
    }
    getRealNameTable(): boolean | null {
        return this.realNameTable;
    }

    setRealNameTable(realNameTable: boolean | null) {
        this.realNameTable = realNameTable;
    }
    getAnteAmount(): number {
        return this.anteAmount;
    }

    setAnteAmount(anteAmount: number) {
        this.anteAmount = anteAmount;
    }
    getRestrictedRoomId(): number {
        return this.restrictedRoomId;
    }

    setRestrictedRoomId(restrictedRoomId: number) {
        this.restrictedRoomId = restrictedRoomId;
    }
    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
    getUpperStakesOrBlinds(): number {
        return this.upperStakesOrBlinds;
    }

    setUpperStakesOrBlinds(upperStakesOrBlinds: number) {
        this.upperStakesOrBlinds = upperStakesOrBlinds;
    }
    getNoOfSeats(): number {
        return this.noOfSeats;
    }

    setNoOfSeats(noOfSeats: number) {
        this.noOfSeats = noOfSeats;
    }
    getBuyInAmount(): number {
        return this.buyInAmount;
    }

    setBuyInAmount(buyInAmount: number) {
        this.buyInAmount = buyInAmount;
    }
    getRebuyToMax(): boolean | null {
        return this.rebuyToMax;
    }

    setRebuyToMax(rebuyToMax: boolean | null) {
        this.rebuyToMax = rebuyToMax;
    }
}
