import { Message } from '../../message';

export class RequestSNGJPLobbyData extends Message {
    private MESSAGE_NAME: string = 'RequestSNGJPLobbyData';
    private tournamentIDs: any[] | null = null;
    private tourneyRequestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tournamentIDs: any[] | null = null, tourneyRequestType: number = 0) {
        super(reqServerPeerId);
        this.tournamentIDs = tournamentIDs;
        this.tourneyRequestType = tourneyRequestType;
    }

    getTournamentIDs(): any[] | null {
        return this.tournamentIDs;
    }

    setTournamentIDs(tournamentIDs: any[] | null) {
        this.tournamentIDs = tournamentIDs;
    }
    getTourneyRequestType(): number {
        return this.tourneyRequestType;
    }

    setTourneyRequestType(tourneyRequestType: number) {
        this.tourneyRequestType = tourneyRequestType;
    }
}
