import { Message } from '../../message';

export class ResponseFeatureGamesLobbyUpdate extends Message {
    private MESSAGE_NAME: string = 'ResponseFeatureGamesLobbyUpdate';
    private tabIdTableDetailsMap: Map<any, any> | null = null;
    private tableInfos: any[] | null = null;
    private deletedTableInfos: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tabIdTableDetailsMap: Map<any, any> | null = null,
        tableInfos: any[] | null = null,
        deletedTableInfos: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.tabIdTableDetailsMap = tabIdTableDetailsMap;
        this.tableInfos = tableInfos;
        this.deletedTableInfos = deletedTableInfos;
    }

    getTabIdTableDetailsMap(): Map<any, any> | null {
        return this.tabIdTableDetailsMap;
    }

    setTabIdTableDetailsMap(tabIdTableDetailsMap: Map<any, any> | null) {
        this.tabIdTableDetailsMap = tabIdTableDetailsMap;
    }
    getTableInfos(): any[] | null {
        return this.tableInfos;
    }

    setTableInfos(tableInfos: any[] | null) {
        this.tableInfos = tableInfos;
    }
    getDeletedTableInfos(): any[] | null {
        return this.deletedTableInfos;
    }

    setDeletedTableInfos(deletedTableInfos: any[] | null) {
        this.deletedTableInfos = deletedTableInfos;
    }
}
