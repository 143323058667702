import { Injectable } from '@angular/core';

// import { Observable, of } from 'rxjs';
// import { Http } from '@angular/http';
// import { catchError } from 'rxjs/operators';
import { PokerApiService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlogService {
    // private apiEndpoint = 'https://en-pp.ppblogs.yznet.com/blog/feed/json';

    constructor(private api: PokerApiService) {}

    // getAll(): Observable<any> {
    //     return this.api.get(this.apiEndpoint)
    //         .pipe(catchError(this.handleError('getAll', {})));
    // }

    getFeedData(): Observable<any> {
        return this.api.get('blog/GetBlogFeedData');
    }

    // private handleError<T>(operation = 'operation', result?: T) {
    //     return (error: any): Observable<T> => {
    //         return of(result as T);
    //     };
    // }
}
