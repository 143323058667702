import { Message } from '../../message';

export class ChangeBounceEmail extends Message {
    private MESSAGE_NAME: string = 'ChangeBounceEmail';
    private newEmail: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, newEmail: string | null = null) {
        super(reqServerPeerId);
        this.newEmail = newEmail;
    }

    getNewEmail(): string | null {
        return this.newEmail;
    }

    setNewEmail(newEmail: string | null) {
        this.newEmail = newEmail;
    }
}
