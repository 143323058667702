// eslint-disable-next-line @nx/enforce-module-boundaries
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';

import { selectLoyaltyCashBack } from '../../../store/loyalty-cashback.store';
import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class LoyaltyCashBackView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor() {
        super();
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }
    createView = function (EventDispatcher, vm, loyaltyCashbackObj, isFullScaleView) {
        this.isFullScaleView = isFullScaleView;
        this.EventDispatcher = EventDispatcher;
        this.viewModel = vm;
        this.getLCBObj(loyaltyCashbackObj);
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            portAlignX: 'RIGHT',
            portAlignY: 'TOP',
            EventDispatcher: this.EventDispatcher,
        });
        this.blackBg = this.displayUtil.getRectangleSprite(window.innerWidth / this.scaleX, window.innerHeight / this.scaleY, '#000000');
        this.displayUtil.setObjectPosition(this.blackBg, 0, 0);
        this.addChild(this.blackBg);
        this.blackBg.alpha = 0;
        this.blackBg.visible = false;
        this.dragMove = false;
        this.blackBg.buttonMode = false;
        this.displayUtil.setButtonActive(this.blackBg, true);
        this.fontProps = uiConfig['font']['props'];
        this.positionConfig = uiConfig['controlPanel']['config'];
        this.visible = true;
        this.progressBarAnimeCurrentFrame = 0;
        this.isShimmerShowing = false;
        this.UpdatedCashBackAmt = 0;
        this.createOptIn();
        this.createLoyaltyCashBack();
        this.createWinLoyaltyCashBack();
        this.showLoyaltyCashBack(this.optInStatus);
        this.setLCBData();
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_LOYALTY_CASH_BACK, this.hideLoyaltyCashBack.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_LCB_DATA, this.resetLCBData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_LCB_HANDEND, this.updateLCBHandEnd.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.LCB_SET_NOT_CLICKABLE, this.setNotClikable.bind(this));
        this.hideLoyaltyCashBack({ detail: this.isFullScaleView });
        this.displayUtil.addInteraction(this, this.loyaltyCashBackBgClick.bind(this));
        this.displayUtil.setClickable(this, true);
        this.setSize(720, 1280);
    };
    setNotClikable = function (isClickable) {
        this.displayUtil.setClickable(this, isClickable);
    };
    currentTablePeerId = function (peerId) {
        this.currTablePeerId = 'table' + peerId;
    };
    getLCBObj = function (obj) {
        this.loyaltyCashbackObj = obj;
        if (obj.weeklyPoints.isOptin == 'Y') {
            this.optInStatus = true;
        } else {
            this.optInStatus = false;
        }
        this.isCashBackEnabled = obj.isCashBackEnabled;
        this.optInStatus = obj.optIn.status;
        this.additionalParams = obj.weeklyPoints.additionalParams;
        this.currency = obj.weeklyPoints.currency;
        this.currentCashback = obj.weeklyPoints.currentCashback;
        this.currentSlabPoints = obj.weeklyPoints.currentSlabPoints;
        this.currentTarget = obj.weeklyPoints.currentTarget;
        this.endDate = obj.weeklyPoints.endDate;
        this.isOptin = obj.weeklyPoints.isOptin;
        //this.isOptin = "N";
        this.nextSlabPoints = obj.weeklyPoints.nextSlabPoints;
        this.nextTargetCashback = obj.weeklyPoints.nextTargetCashback;
        this.segmentId = obj.weeklyPoints.segmentId;
        this.segmentMultiplier = obj.weeklyPoints.segmentMultiplier;
        //this.segmentMultiplier = 3;
        this.startDate = obj.weeklyPoints.startDate;
        this.targetCashback = obj.weeklyPoints.targetCashback;
        this.weeklyPoints = obj.weeklyPoints.weeklyPoints;
    };
    createOptIn = function () {
        this.optInContainer = this.displayUtil.getContainer();
        this.addChild(this.optInContainer);
        this.optInContainer.name = 'optInContainer';
        this.optInContainer.visible = false;
        this.displayUtil.setObjectPosition(this.optInContainer, 600, 150);

        const optInBg = this.displayUtil.getSprite('rectangle_bg_0');
        this.optInContainer.addChild(optInBg);
        optInBg.anchor.set(0.5);
        this.displayUtil.setObjectPosition(optInBg, 0, 0);

        this.optInTxt = this.displayUtil.getTextField(this.fontProps.optInFontProps);
        this.optInContainer.addChild(this.optInTxt);
        let optInText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHBACK_OPT_IN_TEXT');
        if (optInText.length > 13) {
            optInText = optInText.slice(0, 13) + '...';
        }
        this.optInTxt.text = optInText;
        this.displayUtil.setObjectPosition(this.optInTxt, -7, -6);
        this.optInTxt.anchor.set(0.5);

        const info_icon = this.displayUtil.getSprite('info_icon');
        this.optInContainer.addChild(info_icon);
        info_icon.anchor.set(0.5);
        this.displayUtil.setObjectPosition(info_icon, -45, 20);

        const optInLocker = this.displayUtil.getSprite('locker_1');
        this.optInContainer.addChild(optInLocker);
        optInLocker.anchor.set(0.5);
        this.displayUtil.setObjectPosition(optInLocker, 70, 1);
    };
    createLoyaltyCashBack = function () {
        this.loyaltyCashBackContainer = this.displayUtil.getContainer();
        this.addChild(this.loyaltyCashBackContainer);
        this.loyaltyCashBackContainer.name = 'loyaltyCashBackContainer';
        this.loyaltyCashBackContainer.visible = false;
        this.displayUtil.setObjectPosition(this.loyaltyCashBackContainer, 600, 150);

        this.booster_bg = this.displayUtil.getSprite('booster_bg');
        this.loyaltyCashBackContainer.addChild(this.booster_bg);
        this.booster_bg.visible = false;
        this.booster_bg.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.booster_bg, 0, 37);
        this.boosterTxt = this.displayUtil.getTextField(this.fontProps.boosterLCBfontProps);
        this.booster_bg.addChild(this.boosterTxt);
        this.displayUtil.setObjectPosition(this.boosterTxt, 0, 1);
        this.boosterTxt.anchor.set(0.5);

        const loyaltyCashBackBg = this.displayUtil.getSprite('rectangle_bg_1');
        this.loyaltyCashBackContainer.addChild(loyaltyCashBackBg);
        loyaltyCashBackBg.anchor.set(0.5);
        this.displayUtil.setObjectPosition(loyaltyCashBackBg, 0, 0);

        const shimmerBg = this.displayUtil.getSpriteDetails('loyaltyCashBackShimmer');
        this.shimmerAnim = this.displayUtil.getCustomAnimatedSprite(shimmerBg);
        this.shimmerAnim.anchor.set(0.5);
        this.loyaltyCashBackContainer.addChild(this.shimmerAnim);
        this.shimmerAnim.loop = false;
        this.displayUtil.setObjectPosition(this.shimmerAnim, 0, 0);
        this.cashbackAmount = this.displayUtil.getTextField(this.fontProps.greenFontProps);
        this.loyaltyCashBackContainer.addChild(this.cashbackAmount);
        this.displayUtil.setObjectPosition(this.cashbackAmount, -7, -(this.shimmerAnim.height / 10));
        this.cashbackAmount.anchor.set(0.5);

        const progressbar_bg = this.displayUtil.getSprite('progressbar_bg');
        this.loyaltyCashBackContainer.addChild(progressbar_bg);
        progressbar_bg.anchor.set(0.5);
        progressbar_bg.scale.x = 1;
        progressbar_bg.scale.y = 1.1;
        this.displayUtil.setObjectPosition(progressbar_bg, 2, 19);

        const loyaltyCashBackProgressBar = this.displayUtil.getSpriteDetails('loyaltyCashBackProgressBarAnim');
        this.loyaltyCashBackProgressBarAnim = this.displayUtil.getCustomAnimatedSprite(loyaltyCashBackProgressBar);
        this.loyaltyCashBackProgressBarAnim.anchor.set(0.5);
        this.loyaltyCashBackProgressBarAnim.scale.x = 1;
        this.loyaltyCashBackProgressBarAnim.scale.y = 0.7;
        this.loyaltyCashBackContainer.addChild(this.loyaltyCashBackProgressBarAnim);
        this.loyaltyCashBackProgressBarAnim.loop = false;
        this.displayUtil.setObjectPosition(this.loyaltyCashBackProgressBarAnim, -7.5, 19);

        const loyaltyCashBackLocker = this.displayUtil.getSprite('locker_2');
        this.loyaltyCashBackContainer.addChild(loyaltyCashBackLocker);
        loyaltyCashBackLocker.anchor.set(0.5);
        this.displayUtil.setObjectPosition(loyaltyCashBackLocker, 70, 1);
    };
    createWinLoyaltyCashBack = function () {
        this.winLoyaltyCashBackContainer = this.displayUtil.getContainer();
        this.addChild(this.winLoyaltyCashBackContainer);
        this.winLoyaltyCashBackContainer.name = 'winLoyaltyCashBackContainer';
        this.winLoyaltyCashBackContainer.visible = false;
        this.displayUtil.setObjectPosition(this.winLoyaltyCashBackContainer, 600, 150);

        const winloyaltyCashBackBg = this.displayUtil.getSprite('rectangle_bg_yellow');
        this.winLoyaltyCashBackContainer.addChild(winloyaltyCashBackBg);
        winloyaltyCashBackBg.anchor.set(0.5);
        this.displayUtil.setObjectPosition(winloyaltyCashBackBg, 0, 0);
        this.winCashbackAmountTxt = this.displayUtil.getTextField(this.fontProps.winCashbackAmountFontProps);
        this.winLoyaltyCashBackContainer.addChild(this.winCashbackAmountTxt);
        this.displayUtil.setObjectPosition(this.winCashbackAmountTxt, -8, -6);
        this.winCashbackAmountTxt.anchor.set(0.5);

        const loyaltyCashBackLockerStar = this.displayUtil.getSpriteDetails('loyaltyCashBackLockerStarAnim');
        this.loyaltyCashBackLockerStarAnim = this.displayUtil.getCustomAnimatedSprite(loyaltyCashBackLockerStar);
        this.loyaltyCashBackLockerStarAnim.anchor.set(0.5);
        this.winLoyaltyCashBackContainer.addChild(this.loyaltyCashBackLockerStarAnim);
        this.loyaltyCashBackLockerStarAnim.loop = false;
        this.displayUtil.setObjectPosition(this.loyaltyCashBackLockerStarAnim, 25, 0);
        this.loyaltyCashBackLockerStarAnim.scale.x = 0.5;
        this.loyaltyCashBackLockerStarAnim.scale.y = 0.5;

        const winloyaltyCashBackLocker = this.displayUtil.getSprite('locker_3');
        this.winLoyaltyCashBackContainer.addChild(winloyaltyCashBackLocker);
        winloyaltyCashBackLocker.anchor.set(0.5);
        this.displayUtil.setObjectPosition(winloyaltyCashBackLocker, 70, 1);

        const lockerShine = this.displayUtil.getSpriteDetails('loyaltyCashBackLockerShine');
        this.lockerShineAnim = this.displayUtil.getCustomAnimatedSprite(lockerShine);
        this.lockerShineAnim.anchor.set(0.5);
        this.winLoyaltyCashBackContainer.addChild(this.lockerShineAnim);
        this.lockerShineAnim.loop = false;
        this.displayUtil.setObjectPosition(this.lockerShineAnim, 70, 1);
        this.lockerShineAnim.width = winloyaltyCashBackLocker.width;
        this.lockerShineAnim.height = winloyaltyCashBackLocker.height;
    };
    showLoyaltyCashBack = function (optInStatus) {
        this.winLoyaltyCashBackContainer.visible = false;
        this.winLoyaltyCashBackContainer.alpha = 1;
        this.loyaltyCashBackContainer.visible = false;
        this.optInContainer.visible = false;
        if (Object.keys(this.loyaltyCashbackObj.weeklyPoints).length > 0 && this.isCashBackEnabled === true) {
            if (optInStatus == false) {
                for (let i = 0; i < CommonGameProperties.facade['activeTables'].length; i++) {
                    if (
                        CommonGameProperties.facade['table' + CommonGameProperties.facade['activeTables'][i]].loyaltyCashBackViewModel.view
                            .optInContainer.visible == false
                    ) {
                        CommonGameProperties.facade[
                            'table' + CommonGameProperties.facade['activeTables'][i]
                        ].loyaltyCashBackViewModel.view.optInContainer.visible = true;
                        let optInText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHBACK_OPT_IN_TEXT');
                        if (optInText.length > 13) {
                            optInText = optInText.slice(0, 13) + '...';
                        }
                        CommonGameProperties.facade['table' + CommonGameProperties.facade['activeTables'][i]].loyaltyCashBackViewModel.view.text =
                            optInText;
                    }
                }
            } else {
                this.loyaltyCashBackContainer.visible = true;
                setTimeout(
                    function () {
                        if (CommonGameProperties.facade[this.currTablePeerId].parentFacade.isLoyaltyCashBackShimmerShowed == false) {
                            CommonGameProperties.facade[this.currTablePeerId].parentFacade.isLoyaltyCashBackShimmerShowed = true;
                            this.isShimmerShowing = true;
                            this.shimmerAnim.visible = true;
                            this.shimmerAnim.play();
                            this.shimmerAnim.gotoAndPlay(0);
                            this.shimmerAnim.onComplete = function () {
                                this.shimmerAnim.visible = false;
                                this.shimmerAnim.gotoAndStop(0);
                            }.bind(this);
                        } else {
                            this.isShimmerShowing = false;
                        }
                        this.updateSegmentMultiplier(this.segmentMultiplier);
                        this.progressBarPercentage(this.weeklyPoints);
                    }.bind(this),
                    250,
                );
            }
        }
    };
    progressBarPercentage = function (weeklyPoints) {
        //this.loyaltyCashBackProgressBarAnim.gotoAndStop(0);
        const percentage = ((weeklyPoints - this.currentSlabPoints) * 100) / (this.currentTarget - this.currentSlabPoints);
        if (percentage > 0 && this.optInStatus == true) {
            this.progressBarAnimeCurrentFrame = Math.round((percentage / 100) * 40);
            if (this.progressBarAnimeCurrentFrame >= 40) {
                this.progressBarAnimeCurrentFrame = 39;
            } else if (this.progressBarAnimeCurrentFrame > 35) {
                this.progressBarAnimeCurrentFrame = this.progressBarAnimeCurrentFrame - 1;
            }
            if (this.isShimmerShowing == true) {
                this.isShimmerShowing = false;
                let frame = 0;
                const barUpdate = setInterval(
                    function () {
                        frame = frame + 1;
                        if (frame >= this.progressBarAnimeCurrentFrame) {
                            clearInterval(barUpdate);
                        }
                        this.loyaltyCashBackProgressBarAnim.gotoAndStop(frame);
                    }.bind(this),
                    10,
                );
            } else {
                this.loyaltyCashBackProgressBarAnim.gotoAndStop(this.progressBarAnimeCurrentFrame);
            }
            if (percentage >= 100) {
                setTimeout(
                    function () {
                        //this.loyaltyCashBackProgressBarAnim.gotoAndStop(0);
                        this.winLoyaltyCashBackContainer.visible = true;
                        this.progressBarAnimeCurrentFrame = 0;
                        this.playWinAnimation();
                    }.bind(this),
                    1000,
                );
            }
        }
    };
    playWinAnimation = function () {
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps['cashback_safe_unlock'], false);
        setTimeout(
            function () {
                CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps['cashback_safe_open'], false);
            }.bind(this),
            750,
        );
        this.lockerShineAnim.visible = true;
        setTimeout(
            function () {
                this.lockerShineAnim.gotoAndPlay(1);
                this.lockerShineAnim.onComplete = function () {
                    this.lockerShineAnim.visible = true;
                }.bind(this);
                this.loyaltyCashBackLockerStarAnim.visible = true;
                this.loyaltyCashBackLockerStarAnim.gotoAndPlay(1);
                this.loyaltyCashBackLockerStarAnim.onComplete = function () {
                    CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps['cashback_prize_reveal'], false);
                    this.loyaltyCashBackContainer.visible = true;
                    this.loyaltyCashBackProgressBarAnim.gotoAndStop(0);
                    this.displayUtil.setObjectPosition(this.cashbackAmount, 69, 0);
                    gsap.to(this.cashbackAmount, 0.5, {
                        x: -7,
                        y: -(this.shimmerAnim.height / 10),
                    });
                    gsap.to(this.loyaltyCashBackContainer, 0.2, {
                        alpha: 1,
                        onComplete: function () {
                            this.cashbackAmount.text = '$' + String(this.kFormatter(this.UpdatedCashBackAmt));
                        }.bind(this),
                    });
                    gsap.to(this.winLoyaltyCashBackContainer, 0.2, {
                        alpha: 0,
                    });
                    gsap.to(this.winLoyaltyCashBackContainer, 2, {
                        x: this.winLoyaltyCashBackContainer.x - this.winLoyaltyCashBackContainer.width,
                        y: this.winLoyaltyCashBackContainer.y,
                        onComplete: function () {
                            this.winLoyaltyCashBackContainer.visible = false;
                            this.winLoyaltyCashBackContainer.alpha = 1;
                            this.displayUtil.setObjectPosition(this.winLoyaltyCashBackContainer, 600, 150);
                            this.loyaltyCashBackLockerStarAnim.gotoAndStop(0);
                            this.lockerShineAnim.gotoAndStop(0);
                            this.resetLCBData();
                            this.updateSegmentMultiplier(this.segmentMultiplier);
                            this.progressBarPercentage(this.weeklyPoints);
                        }.bind(this),
                    });
                }.bind(this);
            }.bind(this),
            1750,
        );
    };
    setLCBData = function () {
        this.cashbackAmount.text = '$' + String(this.kFormatter(this.targetCashback));
        this.winCashbackAmountTxt.text = '$' + String(this.kFormatter(this.targetCashback));
    };
    updateLCBHandEnd = function () {
        let loyaltyCashbackObj;
        CommonGameProperties.facade['store'].select(selectLoyaltyCashBack).subscribe((data) => {
            loyaltyCashbackObj = data;
        });
        if (loyaltyCashbackObj.isCashBackEnabled === true) {
            if (Object.keys(this.loyaltyCashbackObj.weeklyPoints).length == 0) {
                //window.localStorage.setItem("loyaltyCashback",JSON.stringify(message));
                this.resetLCBData();
            } else {
                if (loyaltyCashbackObj.weeklyPoints.isOptin == 'Y') {
                    this.optInStatus = true;
                } else {
                    this.optInStatus = false;
                }
                this.UpdatedCashBackAmt = loyaltyCashbackObj.weeklyPoints.targetCashback;
                this.progressBarPercentage(loyaltyCashbackObj.weeklyPoints.weeklyPoints);
                this.updateSegmentMultiplier(loyaltyCashbackObj.weeklyPoints.segmentMultiplier);
            }
        }
    };
    updateSegmentMultiplier = function (segmentMultiplier) {
        if (segmentMultiplier == null || segmentMultiplier == undefined || segmentMultiplier == 0) {
            this.booster_bg.visible = false;
        } else {
            const boosterVal = (segmentMultiplier - 1) * 100;
            if (boosterVal > 0) {
                this.booster_bg.visible = true;
                // var boosterText = facade.langPack.getLiteral('PARTY_POKER_MOBILE_CASHBACK_BOOST_TEXT');
                // if(boosterText.length > 5)
                // {
                //   boosterText = boosterText.slice( 0, 5) + "...";
                // }
                this.boosterTxt.text = Math.round(boosterVal) + '% boost';
            } else {
                this.booster_bg.visible = false;
            }
        }
    };
    kFormatter = function (num) {
        // if(num > 100)
        // {
        //   num = Math.round(num);
        // }
        // let kval = (num: number): string | number => {
        //     let absNum = Math.abs(num);
        //     if (absNum > 999 && absNum <= 9990) {
        //         return Math.sign(num) * (+(absNum / 1000).toFixed(2)) + 'k';
        //     } else {
        //         return Math.sign(num) * Math.abs(num);
        //     }
        // };
        // return kval;
        let kval;
        if (num > 100) {
            num = Math.round(num);
        }
        if (Math.abs(num) > 999 && Math.abs(num) <= 9990) {
            kval = (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(2) + 'k';
        } else {
            if (num < 1) {
                kval = num.toFixed(2);
            } else {
                kval = Math.sign(num) * Math.abs(num);
            }
        }
        return kval;
    };
    hideLoyaltyCashBack = function (isVisible) {
        this.visible = isVisible.detail;
    };
    loyaltyCashBackBgClick = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_LOYALTY_CASH_BACK_POPUP);
    };
    resetLCBData = function () {
        let loyaltyCashbackObj;
        CommonGameProperties.facade['store'].select(selectLoyaltyCashBack).subscribe((data) => {
            loyaltyCashbackObj = data;
        });
        if (loyaltyCashbackObj.weeklyPoints.isOptin == 'Y') {
            this.optInStatus = true;
        } else {
            this.optInStatus = false;
        }
        this.getLCBObj(loyaltyCashbackObj);
        this.showLoyaltyCashBack(this.optInStatus);
        this.setLCBData();
    };
}
