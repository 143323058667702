<div class="pwc-error-dialog">
    <div class="pwc-error-dialog-header mb-3">
        <h3 class="pwc-error-dialog-title">{{ data.title }}</h3>
    </div>
    <div class="pwc-error-dialog-body">
        <p>{{ data.message }}</p>
    </div>
    <div class="pwc-error-dialog-footer mb-3">
        <button *ngFor="let button of data.buttons" type="button" class="btn btn-md w-50 btn-dark" (click)="closePopup()">
            {{ button.text }}
        </button>
    </div>
</div>
