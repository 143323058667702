import { Message } from '../../message';

export class SubscriptionRequest extends Message {
    private MESSAGE_NAME: string = 'SubscriptionRequest';
    private operationCode: number = 0;
    private oldSubscribedDomain: number = 0;
    private regInProgress: boolean | null = false;
    private loginInProgress: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        operationCode: number = 0,
        oldSubscribedDomain: number = 0,
        regInProgress: boolean | null = false,
        loginInProgress: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.operationCode = operationCode;
        this.oldSubscribedDomain = oldSubscribedDomain;
        this.regInProgress = regInProgress;
        this.loginInProgress = loginInProgress;
    }

    getOperationCode(): number {
        return this.operationCode;
    }

    setOperationCode(operationCode: number) {
        this.operationCode = operationCode;
    }
    getOldSubscribedDomain(): number {
        return this.oldSubscribedDomain;
    }

    setOldSubscribedDomain(oldSubscribedDomain: number) {
        this.oldSubscribedDomain = oldSubscribedDomain;
    }
    getRegInProgress(): boolean | null {
        return this.regInProgress;
    }

    setRegInProgress(regInProgress: boolean | null) {
        this.regInProgress = regInProgress;
    }
    getLoginInProgress(): boolean | null {
        return this.loginInProgress;
    }

    setLoginInProgress(loginInProgress: boolean | null) {
        this.loginInProgress = loginInProgress;
    }
}
