import { Message } from '../../message';

export class Sit extends Message {
    private MESSAGE_NAME: string = 'Sit';
    private seatingPosition: number = 0;
    private noOfChips: number = 0;
    private frFppIndicator: number = 0;
    private tableCategory: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatingPosition: number = 0,
        noOfChips: number = 0,
        frFppIndicator: number = 0,
        tableCategory: number = 0,
        conversationId: number = 0,
    ) {
        super(reqServerPeerId);
        this.seatingPosition = seatingPosition;
        this.noOfChips = noOfChips;
        this.frFppIndicator = frFppIndicator;
        this.tableCategory = tableCategory;
        this.conversationId = conversationId;
    }

    getSeatingPosition(): number {
        return this.seatingPosition;
    }

    setSeatingPosition(seatingPosition: number) {
        this.seatingPosition = seatingPosition;
    }
    getNoOfChips(): number {
        return this.noOfChips;
    }

    setNoOfChips(noOfChips: number) {
        this.noOfChips = noOfChips;
    }
    getFrFppIndicator(): number {
        return this.frFppIndicator;
    }

    setFrFppIndicator(frFppIndicator: number) {
        this.frFppIndicator = frFppIndicator;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
