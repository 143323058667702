import { Message } from '../../message';

export class HandShake extends Message {
    private MESSAGE_NAME: string = 'HandShake';
    private type: number = 0;
    private frontendId: string | null = null;
    private ARABuildNumber: number = 0;
    private GRABuildNumber: number = 0;
    private sessionKey: string | null = null;
    private loginId: string | null = null;
    private ucid: string | null = null;
    private password: string | null = null;
    private encProfile: any[] | null = null;
    private productId: string | null = null;
    private messageVector: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        type: number = 0,
        frontendId: string | null = null,
        ARABuildNumber: number = 0,
        GRABuildNumber: number = 0,
        sessionKey: string | null = null,
        loginId: string | null = null,
        ucid: string | null = null,
        password: string | null = null,
        encProfile: any[] | null = null,
        productId: string | null = null,
        messageVector: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.type = type;
        this.frontendId = frontendId;
        this.ARABuildNumber = ARABuildNumber;
        this.GRABuildNumber = GRABuildNumber;
        this.sessionKey = sessionKey;
        this.loginId = loginId;
        this.ucid = ucid;
        this.password = password;
        this.encProfile = encProfile;
        this.productId = productId;
        this.messageVector = messageVector;
    }

    getType(): number {
        return this.type;
    }

    setType(type: number) {
        this.type = type;
    }
    getFrontendId(): string | null {
        return this.frontendId;
    }

    setFrontendId(frontendId: string | null) {
        this.frontendId = frontendId;
    }
    getARABuildNumber(): number {
        return this.ARABuildNumber;
    }

    setARABuildNumber(ARABuildNumber: number) {
        this.ARABuildNumber = ARABuildNumber;
    }
    getGRABuildNumber(): number {
        return this.GRABuildNumber;
    }

    setGRABuildNumber(GRABuildNumber: number) {
        this.GRABuildNumber = GRABuildNumber;
    }
    getSessionKey(): string | null {
        return this.sessionKey;
    }

    setSessionKey(sessionKey: string | null) {
        this.sessionKey = sessionKey;
    }
    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
    getUcid(): string | null {
        return this.ucid;
    }

    setUcid(ucid: string | null) {
        this.ucid = ucid;
    }
    getPassword(): string | null {
        return this.password;
    }

    setPassword(password: string | null) {
        this.password = password;
    }
    getEncProfile(): any[] | null {
        return this.encProfile;
    }

    setEncProfile(encProfile: any[] | null) {
        this.encProfile = encProfile;
    }
    getProductId(): string | null {
        return this.productId;
    }

    setProductId(productId: string | null) {
        this.productId = productId;
    }
    getMessageVector(): any[] | null {
        return this.messageVector;
    }

    setMessageVector(messageVector: any[] | null) {
        this.messageVector = messageVector;
    }
}
