import { Message } from '../../message';

export class STTReplayResponse extends Message {
    private MESSAGE_NAME: string = 'STTReplayResponse';
    private accountName: string | null = null;
    private tableId: number = 0;
    private seatNo: number = 0;
    private status: number = 0;
    private peerId: number = 0;
    private tableName: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        accountName: string | null = null,
        tableId: number = 0,
        seatNo: number = 0,
        status: number = 0,
        peerId: number = 0,
        tableName: any | null = null,
    ) {
        super(reqServerPeerId);
        this.accountName = accountName;
        this.tableId = tableId;
        this.seatNo = seatNo;
        this.status = status;
        this.peerId = peerId;
        this.tableName = tableName;
    }

    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getPeerId(): number {
        return this.peerId;
    }

    setPeerId(peerId: number) {
        this.peerId = peerId;
    }
    getTableName(): any | null {
        return this.tableName;
    }

    setTableName(tableName: any | null) {
        this.tableName = tableName;
    }
}
