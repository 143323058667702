import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

import { NavigationService, UserService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { WeeklyPointsDto } from '../../models/loyalty-cashback-weeklyPoints';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { PWCLoyaltyCashBackService } from '../../service/loyalty-cashBack.service';
import { selectLoyaltyCashBack } from '../../store/loyalty-cashback.store';

@Component({
    selector: 'pwc-loyalty-cashback',
    templateUrl: 'pwc-loyalty-cashback.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    providers: [],
})
export class PWCLoyaltyCashbackComponent {
    @Output() close = new EventEmitter<void>();
    isOptIn: boolean;
    weeklyPoints: WeeklyPointsDto;
    userTimeZone: string;
    progressBooster: number;
    loyaltyAssets: any;
    constructor(
        private store: Store,
        private navigation: NavigationService,
        private sharedService: PokerWebClientSharedService,
        private loyaltyCashBackService: PWCLoyaltyCashBackService,
        private clientToServer: ClientToServerRequestServices,
        private userService: UserService,
    ) {
        this.loyaltyAssets = this.clientToServer.webAssets['lobby']['loyaltycashback'];
        this.store.select(selectLoyaltyCashBack).subscribe((data) => {
            this.isOptIn = data['optIn']['status'];
            this.weeklyPoints = data['weeklyPoints'];
            this.progressBooster = Math.round((this.weeklyPoints.segmentMultiplier - 1) * 100);
        });
    }

    enableOptIn() {
        this.loyaltyCashBackService.optIn();
        this.closeLoyaltyCashBackPopup();
    }

    closeLoyaltyCashBackPopup($event: any = null) {
        this.close.emit();
        $event && $event.stopImmediatePropagation();
        // this.dialogRef.close({ data: 'it is closed now..!!!' });
    }

    progressBarBlocks(n: number): Array<number> {
        return Array(n);
    }

    convertDate(date) {
        const dateObject = new Date(parseInt(date.match(/\d/g).join('')));
        return `${moment.utc(dateObject).local().format('DD/MM/YYYY - HH:mm')} ${momentTimezone().tz(momentTimezone.tz.guess()).zoneAbbr()}`;
    }

    currencyConvert(value) {
        if (value >= 1000) {
            const i = 0 === value ? value : Math.floor(Math.log(value) / Math.log(1000));
            const result = parseFloat((value / Math.pow(1000, i)).toFixed(2));
            return `${this.weeklyPoints.currency + result + 'k'}`;
        } else if (value >= 100 && value <= 999) {
            return `${this.weeklyPoints.currency + Math.round(value)}`;
        } else if (value >= 1 && value <= 99) {
            const regexPattern = /^-?[0-9]+$/;
            const result = regexPattern.test(value)
                ? value
                : parseFloat(value)
                      .toFixed(2)
                      .toString()
                      .replace(/(\.0+|0+)$/, '');
            return `${this.weeklyPoints.currency + result}`;
        } else {
            return `${this.weeklyPoints.currency + (value == 0 ? value : value.toFixed(2))}`;
        }
    }

    pointsConvert(value) {
        const result = value.toString().includes('.0')
            ? Math.round(value)
            : value.toString().includes('.')
              ? parseFloat(value)
                    .toFixed(2)
                    .toString()
                    .replace(/(\.0+|0+)$/, '')
              : parseInt(value)
                    .toFixed(2)
                    .toString()
                    .replace(/(\.0+|0+)$/, '');
        return `${result}`;
    }

    progressBarValue() {
        return (
            ((this.weeklyPoints.weeklyPoints - this.weeklyPoints.currentSlabPoints) * 100) /
                (this.weeklyPoints.currentTarget - this.weeklyPoints.currentSlabPoints) +
            '%'
        );
    }

    loyaltyInfoPopup() {
        this.sharedService.sendPortalPage({
            text: this.clientToServer.externalLinks['cashback-link']['title'],
            url: this.clientToServer.externalLinks['cashback-link']['titleLink']['url'] + '&_sso=' + this.userService.ssoToken,
        });
        this.navigation.goTo('/play/' + 'portal');
    }
}
