import { Message } from '../../message';

export class ResponseLobbyConfig extends Message {
    private MESSAGE_NAME: string = 'ResponseLobbyConfig';
    private tabConfigText: string | null = null;
    private md5sum: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tabConfigText: string | null = null, md5sum: string | null = null) {
        super(reqServerPeerId);
        this.tabConfigText = tabConfigText;
        this.md5sum = md5sum;
    }

    getTabConfigText(): string | null {
        return this.tabConfigText;
    }

    setTabConfigText(tabConfigText: string | null) {
        this.tabConfigText = tabConfigText;
    }
    getMd5sum(): string | null {
        return this.md5sum;
    }

    setMd5sum(md5sum: string | null) {
        this.md5sum = md5sum;
    }
}
