import { Message } from '../../message';

export class ResponseSNGJPObserverUpdate extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGJPObserverUpdate';
    private lastSnapShotTimeInNanos: number = 0;
    private domain: number = 0;
    private sngJPInstanceCards: any[] | null = null;
    private isFullUpdate: boolean | null = false;
    private deletedObserverCardIds: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        lastSnapShotTimeInNanos: number = 0,
        domain: number = 0,
        sngJPInstanceCards: any[] | null = null,
        isFullUpdate: boolean | null = false,
        deletedObserverCardIds: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
        this.domain = domain;
        this.sngJPInstanceCards = sngJPInstanceCards;
        this.isFullUpdate = isFullUpdate;
        this.deletedObserverCardIds = deletedObserverCardIds;
    }

    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getSngJPInstanceCards(): any[] | null {
        return this.sngJPInstanceCards;
    }

    setSngJPInstanceCards(sngJPInstanceCards: any[] | null) {
        this.sngJPInstanceCards = sngJPInstanceCards;
    }
    getIsFullUpdate(): boolean | null {
        return this.isFullUpdate;
    }

    setIsFullUpdate(isFullUpdate: boolean | null) {
        this.isFullUpdate = isFullUpdate;
    }
    getDeletedObserverCardIds(): any[] | null {
        return this.deletedObserverCardIds;
    }

    setDeletedObserverCardIds(deletedObserverCardIds: any[] | null) {
        this.deletedObserverCardIds = deletedObserverCardIds;
    }
}
