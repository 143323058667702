export class Impetus {
    stopThresholdDefault = 0.3;
    bounceDeceleration = 0.04;
    bounceAcceleration = 0.11;
    _ref$source;
    sourceEl;
    updateCallback;
    _ref$multiplier;
    multiplier;
    _ref$friction;
    friction;
    initialValues;
    boundX;
    boundY;
    isPixiElement;
    _ref$bounce;
    bounce;
    boundXmin;
    boundXmax;
    boundYmin;
    boundYmax;
    pointerLastX;
    pointerLastY;
    pointerCurrentX;
    pointerCurrentY;
    pointerId;
    decVelX;
    decVelY;
    targetX = 0;
    targetY = 0;
    stopThreshold;
    ticking = false;
    pointerActive = false;
    paused = false;
    decelerating = false;
    trackingPoints = [];
    constructor(_ref) {
        this._ref$source = _ref.source;
        this.sourceEl = this._ref$source === undefined ? document : this._ref$source;
        this.updateCallback = _ref.update;
        this._ref$multiplier = _ref.multiplier;
        this.multiplier = this._ref$multiplier === undefined ? 1 : this._ref$multiplier;
        this._ref$friction = _ref.friction;
        this.friction = this._ref$friction === undefined ? 0.92 : this._ref$friction;
        this.initialValues = _ref.initialValues;
        this.boundX = _ref.boundX;
        this.boundY = _ref.boundY;
        this.isPixiElement = _ref.isPixiElement;
        this._ref$bounce = _ref.bounce;
        this.sourceEl = typeof this.sourceEl === 'string' ? document.querySelector(this.sourceEl) : this.sourceEl;
        if (!this.sourceEl) {
            throw new Error('IMPETUS: source not found.');
        }

        if (!this.updateCallback) {
            throw new Error('IMPETUS: update function not defined.');
        }

        if (this.initialValues) {
            if (this.initialValues[0]) {
                this.targetX = this.initialValues[0];
            }
            if (this.initialValues[1]) {
                this.targetY = this.initialValues[1];
            }
            this.callUpdateCallback();
        }

        // Initialize bound values
        if (this.boundX) {
            this.boundXmin = this.boundX[0];
            this.boundXmax = this.boundX[1];
        }
        if (this.boundY) {
            this.boundYmin = this.boundY[0];
            this.boundYmax = this.boundY[1];
        }
        if (this.isPixiElement) {
            // this.sourceEl.mousedown = this.sourceEl.touchstart = null;
            // this.sourceEl.mousedown = this.sourceEl.touchstart = this.onDown;

            this.sourceEl.on('mousedown', this.onDown.bind(this));
            this.sourceEl.on('touchstart', this.onDown.bind(this));
        } else {
            this.sourceEl.removeEventListener('touchstart', this.onDown);
            this.sourceEl.removeEventListener('mousedown', this.onDown);

            this.sourceEl.addEventListener('touchstart', this.onDown.bind(this));
            this.sourceEl.addEventListener('mousedown', this.onDown.bind(this));
        }
    }
    /**
     * Disable movement processing
     * @public
     */
    pause = function () {
        this.pointerActive = false;
        this.paused = true;
    };

    /**
     * Enable movement processing
     * @public
     */
    resume = function () {
        this.paused = false;
    };

    /**
     * Update the current x and y values
     * @public
     * @param {Number} x
     * @param {Number} y
     */
    setValues = function (x, y) {
        if (typeof x === 'number') {
            this.targetX = x;
        }
        if (typeof y === 'number') {
            this.targetY = y;
        }
    };

    /**
     * Update the multiplier value
     * @public
     * @param {Number} val
     */
    setMultiplier = function (val) {
        this.multiplier = val;
        this.stopThreshold = this.stopThresholdDefault * this.multiplier;
    };

    updateBoundY = function (_boundY) {
        if (_boundY) {
            this.boundY = _boundY;
            this.boundYmin = _boundY[0];
            this.boundYmax = _boundY[1];
        }
    };

    updateBoundX = function (_boundX) {
        if (_boundX) {
            this.boundX = _boundX;
            this.boundXmin = _boundX[0];
            this.boundXmax = _boundX[1];
        }
    };

    getBoundY = function () {
        return this.boundY;
    };

    cleanUp = function () {
        this.ticking = false;
        this.pointerActive = false;
        this.paused = false;
        this.decelerating = false;

        if (this.sourceEl) {
            if (this.isPixiElement) {
                this.sourceEl.mousedown = this.sourceEl.touchstart = null;
            } else {
                // this.sourceEl.removeEventListener('touchstart', this.onDown);
                // this.sourceEl.removeEventListener('mousedown', this.onDown);
                this.sourceEl.off('mousedown', this.onDown);
                this.sourceEl.off('touchstart', this.onDown);
            }
        }

        document.removeEventListener('touchmove', this.onMove);
        document.removeEventListener('touchend', this.onUp);
        document.removeEventListener('touchcancel', this.stopTracking);
        document.removeEventListener('mousemove', this.onMove);
        document.removeEventListener('mouseup', this.onUp);
    };

    addScrollListener = function () {
        if (this.isPixiElement) {
            //this.sourceEl.mousedown = this.sourceEl.touchstart = this.onDown;
            this.sourceEl.on('mousedown', this.onDown.bind(this));
        } else {
            this.sourceEl.addEventListener('touchstart', this.onDown.bind(this));
            this.sourceEl.addEventListener('mousedown', this.onDown.bind(this));
        }
    };

    /**
     * Executes the update function
     */
    callUpdateCallback = function () {
        this.updateCallback.call(this.sourceEl, this.targetX, this.targetY);
    };

    //  checkAndCall = function(callback) {

    // }

    /**
     * Creates a custom normalized event object from touch and mouse events
     * @param  {Event} ev
     * @returns {Object} with x, y, and id properties
     */
    normalizeEvent = function (ev) {
        if (ev.type === 'touchstart') {
            let clientY = !ev.data.originalEvent.touches ? ev.data.originalEvent.clientY : ev.data.originalEvent.touches[0].clientY;
            let clientX = !ev.data.originalEvent.touches ? ev.data.originalEvent.clientX : ev.data.originalEvent.touches[0].clientX;
            return {
                x: clientX,
                y: clientY,
            };
        } else if (ev.type === 'touchmove') {
            //let touch = ev.targetTouches[0] || ev.changedTouches[0];
            let clientY = !ev.touches ? ev.clientY : ev.touches[0].clientY;
            let clientX = !ev.touches ? ev.clientX : ev.touches[0].clientX;
            return {
                x: clientX,
                y: clientY,
            };
        } else if (ev.type === 'touchend') {
            return {};
        } else {
            if (!ev.data) {
                // mouse events
                return {
                    x: ev.clientX,
                    y: ev.clientY,
                    id: null,
                };
            } else {
                let clientY = !ev.data.originalEvent.touches ? ev.data.originalEvent.clientY : ev.data.originalEvent.touches[0].clientY;
                let clientX = !ev.data.originalEvent.touches ? ev.data.originalEvent.clientX : ev.data.originalEvent.touches[0].clientX;
                return {
                    x: clientX,
                    y: clientY,
                    id: null,
                };
            }
        }
    };

    /**
     * Initializes movement tracking
     * @param  {Object} ev Normalized event
     */
    onDown = function (ev) {
        let event = this.normalizeEvent(ev);
        if (!this.pointerActive && !this.paused) {
            this.pointerActive = true;
            this.decelerating = false;
            this.pointerId = event.id;

            this.pointerLastX = this.pointerCurrentX = event.x;
            this.pointerLastY = this.pointerCurrentY = event.y;
            this.trackingPoints = [];
            this.addTrackingPoint(this.pointerLastX, this.pointerLastY);
            try {
                document.removeEventListener('touchmove', this.onMove);
                document.removeEventListener('touchend', this.onUp);
                document.removeEventListener('touchcancel', this.stopTracking);
                document.removeEventListener('mousemove', this.onMove);
                document.removeEventListener('mouseup', this.onUp);
            } catch (error) {}

            document.addEventListener('touchmove', this.onMove.bind(this));
            document.addEventListener('touchend', this.onUp.bind(this));
            document.addEventListener('touchcancel', this.stopTracking.bind(this));
            document.addEventListener('mousemove', this.onMove.bind(this));
            document.addEventListener('mouseup', this.onUp.bind(this));
        }
    };

    /**
     * Handles move events
     * @param  {Object} ev Normalized event
     */
    onMove = function (ev) {
        this.isDragging = true;
        ev.preventDefault();
        let event = this.normalizeEvent(ev);
        if (this.pointerActive && event.id === this.pointerId) {
            this.pointerCurrentX = event.x;
            this.pointerCurrentY = event.y;
            this.addTrackingPoint(this.pointerLastX, this.pointerLastY);
            this.requestTick();
        }
    };

    /**
     * Handles up/end events
     * @param {Object} ev Normalized event
     */
    onUp = function (ev) {
        let event = this.normalizeEvent(ev);

        if (this.pointerActive && event.id === this.pointerId) {
            this.isDragging = false;
            this.stopTracking();
        }
    };

    /**
     * Stops movement tracking, starts animation
     */
    stopTracking = function () {
        this.pointerActive = false;
        this.addTrackingPoint(this.pointerLastX, this.pointerLastY);
        this.startDecelAnim();

        document.removeEventListener('touchmove', this.onMove);
        document.removeEventListener('touchend', this.onUp);
        document.removeEventListener('touchcancel', this.stopTracking);
        document.removeEventListener('mouseup', this.onUp);
        document.removeEventListener('mousemove', this.onMove);
    };
    /**
     * Records movement for the last 100ms
     * @param {number} x
     * @param {number} y [description]
     */
    addTrackingPoint = function (x, y) {
        let time = Date.now();
        while (this.trackingPoints.length > 0) {
            if (time - this.trackingPoints[0].time <= 100) {
                break;
            }
            this.trackingPoints.shift();
        }

        this.trackingPoints.push({ x: x, y: y, time: time });
    };

    /**
     * Calculate new values, call update function
     */
    updateAndRender = function () {
        let pointerChangeX = this.pointerCurrentX - this.pointerLastX;
        let pointerChangeY = this.pointerCurrentY - this.pointerLastY;

        this.targetX += pointerChangeX * this.multiplier;
        this.targetY += pointerChangeY * this.multiplier;

        if (this.bounce) {
            let diff = this.checkBounds();
            if (diff.x !== 0) {
                this.targetX -= pointerChangeX * this.dragOutOfBoundsMultiplier(diff.x) * this.multiplier;
            }
            if (diff.y !== 0) {
                this.targetY -= pointerChangeY * this.dragOutOfBoundsMultiplier(diff.y) * this.multiplier;
            }
        } else {
            this.checkBounds(true);
        }

        this.callUpdateCallback();

        this.pointerLastX = this.pointerCurrentX;
        this.pointerLastY = this.pointerCurrentY;
        this.ticking = false;
    };

    /**
     * Returns a value from around 0.5 to 1, based on distance
     * @param {Number} val
     */
    dragOutOfBoundsMultiplier = function (val) {
        return 0.000005 * Math.pow(val, 2) + 0.0001 * val + 0.55;
    };

    /**
     * prevents animating faster than current framerate
     */
    requestTick = function () {
        if (!this.ticking) {
            requestAnimationFrame(this.updateAndRender.bind(this));
        }
        this.ticking = true;
    };

    /**
     * Determine position relative to bounds
     * @param {Boolean} restrict Whether to restrict target to bounds
     */
    checkBounds = function (restrict) {
        let xDiff = 0;
        let yDiff = 0;

        if (this.boundXmin !== undefined && this.targetX < this.boundXmin) {
            xDiff = this.boundXmin - this.targetX;
        } else if (this.boundXmax !== undefined && this.targetX > this.boundXmax) {
            xDiff = this.boundXmax - this.targetX;
        }

        if (this.boundYmin !== undefined && this.targetY < this.boundYmin) {
            yDiff = this.boundYmin - this.targetY;
        } else if (this.boundYmax !== undefined && this.targetY > this.boundYmax) {
            yDiff = this.boundYmax - this.targetY;
        }

        if (restrict) {
            if (xDiff !== 0) {
                this.targetX = xDiff > 0 ? this.boundXmin : this.boundXmax;
            }
            if (yDiff !== 0) {
                this.targetY = yDiff > 0 ? this.boundYmin : this.boundYmax;
            }
        }

        return {
            x: xDiff,
            y: yDiff,
            inBounds: xDiff === 0 && yDiff === 0,
        };
    };

    /**
     * Initialize animation of values coming to a stop
     */
    startDecelAnim = function () {
        let firstPoint = this.trackingPoints[0];
        let lastPoint = this.trackingPoints[this.trackingPoints.length - 1];

        let xOffset = lastPoint.x - firstPoint.x;
        let yOffset = lastPoint.y - firstPoint.y;
        let timeOffset = lastPoint.time - firstPoint.time;

        let D = timeOffset / 15 / this.multiplier;

        this.decVelX = xOffset / D || 0; // prevent NaN
        this.decVelY = yOffset / D || 0;

        let diff = this.checkBounds();

        if (Math.abs(this.decVelX) > 1 || Math.abs(this.decVelY) > 1 || !diff.inBounds) {
            this.decelerating = true;
            requestAnimationFrame(this.stepDecelAnim.bind(this));
        }
    };

    /**
     * Animates values slowing down
     */
    stepDecelAnim = function () {
        if (!this.decelerating) {
            return;
        }

        this.decVelX *= this.friction;
        this.decVelY *= this.friction;

        this.targetX += this.decVelX;
        this.targetY += this.decVelY;

        let diff = this.checkBounds();

        if (Math.abs(this.decVelX) > this.stopThreshold || Math.abs(this.decVelY) > this.stopThreshold || !diff.inBounds || this.decVelY > -0.5) {
            if (this.bounce) {
                let reboundAdjust = 2.5;

                if (diff.x !== 0) {
                    if (diff.x * this.decVelX <= 0) {
                        this.decVelX += diff.x * this.bounceDeceleration;
                    } else {
                        let adjust = diff.x > 0 ? reboundAdjust : -reboundAdjust;
                        this.decVelX = (diff.x + adjust) * this.bounceAcceleration;
                    }
                }
                if (diff.y !== 0) {
                    if (diff.y * this.decVelY <= 0) {
                        this.decVelY += diff.y * this.bounceDeceleration;
                    } else {
                        let adjust = diff.y > 0 ? reboundAdjust : -reboundAdjust;
                        this.decVelY = (diff.y + adjust) * this.bounceAcceleration;
                    }
                }
            } else {
                if (diff.x !== 0) {
                    if (diff.x > 0) {
                        this.targetX = this.boundXmin;
                    } else {
                        this.targetX = this.boundXmax;
                    }
                    this.decVelX = 0;
                }
                if (diff.y !== 0) {
                    if (diff.y > 0) {
                        this.targetY = this.boundYmin;
                    } else {
                        this.targetY = this.boundYmax;
                    }
                    this.decVelY = 0;
                }
            }

            this.callUpdateCallback();

            requestAnimationFrame(this.stepDecelAnim.bind(this));
        } else {
            this.decelerating = false;
        }
    };

    /**
     * @see http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
     */
    //;

    //module.exports = Impetus;
    // eslint-disable-next-line unicorn/consistent-function-scoping
    requestAnimationFrame = (function () {
        return (
            window.requestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            }
        );
    })();
}
