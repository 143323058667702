import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { PCComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { DropdownModule } from 'primeng/dropdown';

import { formatCurrencyPipe } from './formatCurrency.pipe';
import { PPCashierOptionsService } from './pp-cashier-options.service';
import { SearchPipe } from './search.pipe';

@Component({
    selector: 'pk-cashier-options-faqs-modal',
    templateUrl: 'pp-cashier-option-faqs-modal.component.html',
    standalone: true,
    imports: [CommonModule, DropdownModule, FormsModule, ReactiveFormsModule, TrustAsHtmlPipe, formatCurrencyPipe, SearchPipe],
})
export class PPCashierOptionsFaqsModalComponent extends PCComponent implements OnChanges {
    @Input() serviceParams: any;
    @Input() widgetConfig: any;
    @Output() clearFaqs = new EventEmitter();

    public faqData: any = '';
    public testMarkup: SafeHtml;
    public faqSearch: string;
    public enableLoader: boolean = false;

    constructor(
        private cashierOptionsService: PPCashierOptionsService,
        private sanitized: DomSanitizer,
    ) {
        super();
    }

    ngOnInit() {
        this.enableLoader = true;
        this.getFaq(this.serviceParams);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getFaq(changes.serviceID);
    }

    getFaq(serviceParams: any) {
        if (serviceParams?.option) {
            serviceParams.option = serviceParams.option.replace('pay_', '');
            this.cashierOptionsService
                .getCashierOptionsFaq(
                    serviceParams.country,
                    serviceParams.optionType,
                    serviceParams.option,
                    serviceParams.currency,
                    serviceParams.language,
                )
                .subscribe((response: any) => {
                    this.faqData = response.faqDetails[0];
                    if (!this.faqData) {
                        //this.toasterService.pop('error', 'Error', this.widgetConfig?.faqsNotFound);
                        this.closeFAQsModal(true);
                    }
                    this.enableLoader = false;
                });
        }
    }

    getFaqAnswer(faqAnswer: any) {
        return this.sanitized.bypassSecurityTrustHtml(faqAnswer);
    }

    closeFAQsModal(value: any) {
        this.clearFaqs.emit(value);
    }
}
