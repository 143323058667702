import { Message } from '../../message';
import { Option } from './Option';

export class LimitGameOption extends Message {
    private MESSAGE_NAME: string = 'LimitGameOption';
    private option: Option | null = null;
    private maxRaiseLimit: number = 0;
    private clientAmt: number = 0;
    private sliderAmount: number = 0;
    private isMaxRaiseAllIn: boolean | null = false;
    private currentMaxBet: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        option: Option | null = null,
        maxRaiseLimit: number = 0,
        clientAmt: number = 0,
        sliderAmount: number = 0,
        isMaxRaiseAllIn: boolean | null = false,
        currentMaxBet: number = 0,
    ) {
        super(reqServerPeerId);
        this.option = option;
        this.maxRaiseLimit = maxRaiseLimit;
        this.clientAmt = clientAmt;
        this.sliderAmount = sliderAmount;
        this.isMaxRaiseAllIn = isMaxRaiseAllIn;
        this.currentMaxBet = currentMaxBet;
    }

    getOption(): Option | null {
        return this.option;
    }

    setOption(option: Option | null) {
        this.option = option;
    }
    getMaxRaiseLimit(): number {
        return this.maxRaiseLimit;
    }

    setMaxRaiseLimit(maxRaiseLimit: number) {
        this.maxRaiseLimit = maxRaiseLimit;
    }
    getClientAmt(): number {
        return this.clientAmt;
    }

    setClientAmt(clientAmt: number) {
        this.clientAmt = clientAmt;
    }
    getSliderAmount(): number {
        return this.sliderAmount;
    }

    setSliderAmount(sliderAmount: number) {
        this.sliderAmount = sliderAmount;
    }
    getIsMaxRaiseAllIn(): boolean | null {
        return this.isMaxRaiseAllIn;
    }

    setIsMaxRaiseAllIn(isMaxRaiseAllIn: boolean | null) {
        this.isMaxRaiseAllIn = isMaxRaiseAllIn;
    }
    getCurrentMaxBet(): number {
        return this.currentMaxBet;
    }

    setCurrentMaxBet(currentMaxBet: number) {
        this.currentMaxBet = currentMaxBet;
    }
}
