import { Routes } from '@angular/router';

import { PokerIrishPokerMastersKoOnlineSynopsisResolver } from './poker-irish-poker-masters-ko-online-synopsis.resolver';
import { PokerIrishPokerMastersKoOnlineResolver } from './poker-irish-poker-masters-ko-online.resolver';

export const pokerIrishMastersKoOnlineRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerIrishPokerMastersKoOnlineResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerIrishPokerMastersKoOnlineSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerIrishPokerMastersKoOnlineResolver,
        },
    },
];
