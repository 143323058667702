<div class="pwc-error-dialog">
    <div class="pwc-error-dialog-header mb-2">
        <h3 class="pwc-error-dialog-title">{{ data.title }}</h3>
    </div>
    <div class="pwc-error-dialog-body">
        <p class="m-0">{{ data.message }}</p>
    </div>
    <div class="pwc-error-dialog-footer mb-2">
        <div class="button-wrapper d-flex w-100 px-4">
            <button class="btn btn-md w-50 btn-dark mr-2" (click)="buttonYes()">{{ btnText1 }}</button>
            <button class="btn btn-md w-50 btn-dark ml-2" (click)="buttonNo()">{{ btnText2 }}</button>
        </div>
    </div>
</div>
