import { Routes } from '@angular/router';

import { PokerRunItTwiceSynopsisResolver } from './poker-run-it-twice-synopsis.resolver';
import { PokerRunItTwiceResolver } from './poker-run-it-twice.resolver';

export const pokerRunItTwiceRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerRunItTwiceResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerRunItTwiceSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerRunItTwiceResolver,
        },
    },
];
