import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class CardHidden extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.CARD_HIDDEN;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.addHoleCard(this.card);
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.removeCard(this.card);
    }
}
