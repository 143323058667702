import { Message } from '../../message';

export class LSTourneyUnRegistrationCloseChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSTourneyUnRegistrationCloseChangeEvent';
    private unregClosed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, unregClosed: boolean | null = false) {
        super(reqServerPeerId);
        this.unregClosed = unregClosed;
    }

    getUnregClosed(): boolean | null {
        return this.unregClosed;
    }

    setUnregClosed(unregClosed: boolean | null) {
        this.unregClosed = unregClosed;
    }
}
