<div class="tableTextView">
    <div class="TextViewToggle">
        <div class="replayer-text-view" [class.active]="status" *ngIf="!handHistory.singleHand; else singleHand">
            <table caption="Text View">
                <tr>
                    <th>{{ serve.MyCards }}</th>
                    <th>{{ serve.Board }}</th>
                    <th class="dashboard-pot">{{ serve.Pot }}</th>
                </tr>
            </table>
            <div class="table-content">
                <table caption="Text View">
                    <tr
                        *ngFor="let Hands of hands"
                        [class.activeHand]="activeID === Hands.roundReference.roundNumber"
                        (click)="replay(Hands.roundReference.roundNumber)">
                        <td>
                            <ul class="user-card replayer">
                                <li
                                    *ngFor="let holeCards of Hands.playerHoleCards"
                                    pocket-cards="replayer"
                                    class="hand-history-card-replayer"
                                    [attr.card-value]="holeCards"></li>
                            </ul>
                        </td>
                        <td class="dashboard-communitycards">
                            <ul class="user-card replayer" *ngIf="Hands.communityCards[0].length > 0">
                                <li
                                    *ngFor="let holeCards of Hands.communityCards"
                                    pocket-cards="replayer"
                                    class="hand-history-card-replayer"
                                    [attr.card-value]="holeCards"></li>
                            </ul>
                        </td>
                        <td class="dashboard-pot" *ngIf="!gameTable.isUSUncalledbet">
                            <span *ngIf="!gameTable.isTourny">{{ gameTable.pot.gameCurrency }}</span
                            ><span *ngIf="!gameTable.isTourny && !Hands.isRitHand">{{
                                (Hands.totalPotSize.unit / 100 - Hands.unCalledBetAmt / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}</span
                            ><span *ngIf="!gameTable.isTourny && Hands.isRitHand">{{
                                (Hands.totalPotSize.unit / 100 - Hands.unCalledBetAmt / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}</span
                            ><span *ngIf="gameTable.isTourny">{{
                                (Hands.totalPotSize.unit / 100 - Hands.unCalledBetAmt / 100).toLocaleString()
                            }}</span>
                        </td>
                        <td class="dashboard-pot" *ngIf="gameTable.isUSUncalledbet">
                            <span *ngIf="!gameTable.isTourny">{{ gameTable.pot.gameCurrency }}</span
                            ><span *ngIf="!gameTable.isTourny && !Hands.isRitHand">{{
                                (Hands.totalPotSize.unit / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}</span
                            ><span *ngIf="!gameTable.isTourny && Hands.isRitHand">{{
                                (Hands.totalPotSize.unit / 100 - Hands.unCalledBetAmt / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}</span
                            ><span *ngIf="gameTable.isTourny">{{ (Hands.totalPotSize.unit / 100).toLocaleString() }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <ng-template #singleHand>
            <ng-container *ngIf="handHistory.singleHand">
                <div class="replayer-text-view" [class.active]="status">
                    <table caption="">
                        <tr>
                            <th>{{ serve.MyCards }}</th>
                            <th>{{ serve.Board }}</th>
                            <th class="dashboard-pot">{{ serve.Pot }}</th>
                        </tr>
                    </table>
                    <div class="table-content">
                        <table>
                            <tr
                                [class.activeHand]="activeID === handHistory.singleGameRound.roundReference.roundNumber"
                                (click)="replay(handHistory.singleGameRound.roundReference.roundNumber)">
                                <td>
                                    <ul class="user-card replayer">
                                        <li
                                            *ngFor="let holeCards of playerHoleCards"
                                            pocket-cards="replayer"
                                            class="hand-history-card-replayer"
                                            [attr.card-value]="holeCards"></li>
                                    </ul>
                                </td>
                                <td class="dashboard-communitycards">
                                    <ul class="user-card replayer">
                                        <li
                                            *ngFor="let holeCards of communityCards"
                                            pocket-cards="replayer"
                                            class="hand-history-card-replayer"
                                            [attr.card-value]="holeCards"></li>
                                    </ul>
                                </td>
                                <td class="dashboard-pot" *ngIf="!gameTable.isUSUncalledbet">
                                    <span *ngIf="!gameTable.isTourny">{{ gameTable.pot.gameCurrency }}</span>
                                    <span *ngIf="!gameTable.isTourny && !handHistory.singleGameRound.ritGame">{{
                                        ((pot - handHistory.singleGameRound.unBetPotSize) / 100).toLocaleString(undefined, {
                                            minimumFractionDigits: 2
                                        })
                                    }}</span>
                                    <span *ngIf="!gameTable.isTourny && handHistory.singleGameRound.ritGame">{{
                                        ((pot - handHistory.singleGameRound.unBetPotSize) / 100).toLocaleString(undefined, {
                                            minimumFractionDigits: 2
                                        })
                                    }}</span>
                                    <span *ngIf="gameTable.isTourny && !handHistory.singleGameRound.ritGame">{{
                                        ((pot - handHistory.singleGameRound.unBetPotSize) / 100).toLocaleString()
                                    }}</span>
                                </td>
                                <td class="dashboard-pot" *ngIf="gameTable.isUSUncalledbet">
                                    <span *ngIf="!gameTable.isTourny">{{ gameTable.pot.gameCurrency }}</span>
                                    <span *ngIf="!gameTable.isTourny && !handHistory.singleGameRound.ritGame">{{
                                        (pot / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                    }}</span>
                                    <span *ngIf="!gameTable.isTourny && handHistory.singleGameRound.ritGame">{{
                                        ((pot - handHistory.singleGameRound.unBetPotSize) / 100).toLocaleString(undefined, {
                                            minimumFractionDigits: 2
                                        })
                                    }}</span>
                                    <span *ngIf="gameTable.isTourny && !handHistory.singleGameRound.ritGame">{{ (pot / 100).toLocaleString() }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>
