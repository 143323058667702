<section class="sim-demo-video-wrap">
    <!-- Video Element -->
    <video
        preload="auto"
        muted
        #demoVideo
        playsinline
        webkit-playsinline
        class="sim-demo-video"
        poster="/pictures/images/mobile-poker-trainer/demo-video/sim-demo-video-cover.jpg"
        src="/pictures/images/mobile-poker-trainer/demo-video/sim-demo-video.mp4"
        type="video/mp4"></video>

    <!-- User instructions -->
    <div class="wiz-modal wiz-modal-zoom player-info-modal" id="wizModalInfo">
        <div
            (click)="playVideo()"
            class="dv-modal {{ insight?.extraClass }} {{ insight?.animationDirection }}"
            [ngClass]="{ 'show-insight': showInsight && insight.message }"
            id="wizModal">
            <div class="insight-speech" *ngIf="showInsight" [innerHTML]="insight?.message"></div>
            <div class="insight-animate-wrap">
                <ng-container *ngIf="insight?.animationType === 1; else DefaultAnimation">
                    <div class="insight-animate hi-animate" [ngClass]="{ animate: showInsight }"></div>
                    <div class="insight-animate-loop hi-animate-loop" [ngClass]="{ animate: showLoopAnimate }"></div>
                </ng-container>
                <ng-template #DefaultAnimation>
                    <div class="insight-animate default-animate" [ngClass]="{ animate: showInsight }"></div>
                    <div class="insight-animate-loop default-animate-loop" [ngClass]="{ animate: showLoopAnimate }"></div>
                </ng-template>
            </div>
        </div>

        <div class="close-btn theme-ex close-simu-btn" *ngIf="!nativeApp" (click)="closeGameTable()"></div>
        <a class="skip-to-download demo-instruct" [ngClass]="{ 'show-demo-instruct': playReminder }">{{ commonParams.demoInstructions }}</a>
        <a
            class="skip-to-download"
            [ngClass]="{ 'skip-to-download-ranking': insight?.animationType === 0 }"
            *ngIf="!videoEnd; else finalScreen"
            (click)="skipDemo(); trackingSkipVideo(commonParams.skip_video)"
            >{{ commonParams.skip_video }}</a
        >
        <ng-template #finalScreen>
            <a
                class="skip-to-download skip-to-download-lg"
                *ngIf="isAndroid && !nativeApp"
                (click)="trackingSkipToDownloadClick(item?.parameters?.downloadFinalScreen, item?.parameters?.googlePlayCtaText)"
                [href]="item?.parameters?.downloadFinalScreen"
                >{{ item?.parameters?.googlePlayCtaText }}</a
            >
            <a
                class="skip-to-download skip-to-download-lg"
                *ngIf="!isAndroid && !nativeApp"
                (click)="
                    trackingSkipToDownloadClick(item?.parameters?.downloadFinalScreen, item?.parameters?.appStoreCtaText || 'Send me to App Store')
                "
                [href]="item?.parameters?.downloadFinalScreen"
                >{{ item?.parameters?.appStoreCtaText || 'Send me to App Store' }}</a
            >
            <div class="next-hand-btn-wrap">
                <a class="btn btn-primary" (click)="skipDemo(); trackingPlayTutorial(commonParams.playTutorial, 'cta')">{{
                    commonParams.playTutorial
                }}</a>
            </div>
        </ng-template>
    </div>
</section>
