import { Injectable, Input } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { HandHistoryClientConfig } from '@pokercore/module/core';

import { HandHistoryService } from '../../../../../hand-history.service';
import { GameTable } from '../../models/game-table';
import { Player } from '../../models/player';
import { GameService } from '../../services/game.service';

@Injectable({ providedIn: 'root' })
export class GameTableService {
    @Input() gameTable: GameTable;
    gtInitializeDone: boolean = false;
    players: Player[];
    playersMap = new Map<number, Player>();

    disablePrevHandButton: any = true;
    disableNextHandButton: any = true;
    currentReplaySpeed: any;
    disableSlider: any = true;
    roundId: any;
    viewUpdatedforDashBoard: any = false;
    isMobile: any;
    isRequestingPlayerDealer: any;
    showErrorMessage: boolean = false;
    IpadTabUserAgent: any = false;

    constructor(
        public serve: HandHistoryService,
        private gameService: GameService,
        private deviceService: DeviceService,
        private handHistoryClientConfig: HandHistoryClientConfig,
    ) {}

    initGameTable(gameTable: any) {
        if (gameTable) {
            this.showErrorMessage = this.serve.showErrorMessage;
            this.gameTable = gameTable;
            this.initialize();
            this.gtInitializeDone = true;
            this.roundId = this.gameTable.roundID;
            this.checkDisableHands(this.serve.singleHand);
            this.viewUpdatedforDashBoard = false;
            this.isRequestingPlayerDealer = this.gameTable.isrequestingPlayerDealer;
        }
    }
    private initialize() {
        this.players = this.gameTable.players;
        this.players.forEach((player) => {
            this.playersMap.set(player.seatNo, player);
        });
        const value: string | null = localStorage.getItem('lastReplaySliderSpeedValue');
        if (value != null) this.currentReplaySpeed = parseInt(value);
        this.slider(this.currentReplaySpeed);
    }

    prev(val: any) {
        if (!this.gameTable.enableTableMask) {
            this.gameTable.prev(val);
        }
    }

    next(val: any) {
        if (!this.gameTable.enableTableMask) {
            this.gameTable.next(val);
        }
    }
    play() {
        if (!this.gameTable.enableTableMask) {
            const userAgent: any = navigator.userAgent;
            if (userAgent.match(/Macintosh/) !== null) {
                this.IpadTabUserAgent = Object.values(userAgent.match(/Macintosh/))[0] === 'Macintosh' && navigator.maxTouchPoints > 2;
            }
            if (
                (userAgent.match(/(iPhone|iPod|iPad)/) || userAgent.match(/BlackBerry/) || userAgent.match(/Android/)) &&
                !this.deviceService.isTablet &&
                this.handHistoryClientConfig.whenReady.subscribe(() => this.handHistoryClientConfig.isPotraiteReplayer)
            ) {
                this.isMobile = true;
            } else if (this.deviceService.isTablet || this.IpadTabUserAgent) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
            if (this.gameTable.replay) {
                this.reset();
            }
            if (this.gameTable.isReducedChipsEnabled) {
                this.gameTable.isCashReducedActionCalled = false;
            }
            this.gameTable.play();
        }
        this.gameTable.accumulateChipSetTimeOutValue = localStorage.getItem('lastaccumulateChipSetTimeOutValue');
        this.gameTable.gameSpeedBarSetter = localStorage.getItem('lastgameSpeedBarSetter');
    }

    reset() {
        if (!this.gameTable.enableTableMask) {
            this.gameTable.isMobile = this.isMobile;
            const accumulateChipSetTimeOutValue: any = this.gameTable.accumulateChipSetTimeOutValue;
            const gameSpeedBarSetter: any = this.gameTable.gameSpeedBarSetter;
            const timeOut = this.gameTable.timeOut;
            this.gameService.isReplayerOnInit(false);
            this.gameTable.onInit = false;
            this.gameTable.cardAnimation = true;
            this.gameTable.ritcommuntycards = false;
            this.gameTable.reset();
            this.gameTable = this.gameService.gameTable;
            this.gameTable.timeOut = timeOut;
            this.gameTable.accumulateChipSetTimeOutValue = accumulateChipSetTimeOutValue;
            this.gameTable.gameSpeedBarSetter = gameSpeedBarSetter;
            if (this.gameTable.isReducedChipsEnabled) {
                this.gameTable.isCashReducedActionCalled = false;
            }
        }
    }

    slider(value: any) {
        this.gameTable.play();
        localStorage.setItem('lastReplaySliderSpeedValue', value);
        switch (value) {
            case 0: {
                this.gameTable.timeOut = 900;
                this.gameTable.accumulateChipSetTimeOutValue = 500;
                this.gameTable.gameSpeedBarSetter = 500;
                break;
            }
            case 1: {
                this.gameTable.timeOut = 750;
                this.gameTable.accumulateChipSetTimeOutValue = 500;
                this.gameTable.gameSpeedBarSetter = 500;
                break;
            }
            case 2: {
                this.gameTable.timeOut = 500;
                this.gameTable.accumulateChipSetTimeOutValue = 400;
                this.gameTable.gameSpeedBarSetter = 300;
                break;
            }
            case 3: {
                this.gameTable.timeOut = 400;
                this.gameTable.accumulateChipSetTimeOutValue = 250;
                this.gameTable.gameSpeedBarSetter = 200;
                break;
            }
            case 4: {
                this.gameTable.timeOut = 300;
                this.gameTable.accumulateChipSetTimeOutValue = 150;
                this.gameTable.gameSpeedBarSetter = 100;
                break;
            }
        }
        localStorage.setItem('lastaccumulateChipSetTimeOutValue', this.gameTable.accumulateChipSetTimeOutValue);
        localStorage.setItem('lastgameSpeedBarSetter', this.gameTable.gameSpeedBarSetter);
        this.gameTable.play();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    goToReplayer(direction: any, event: any) {
        let roundid = document.getElementsByClassName('game-table-wrapper')[0].getAttribute('roundid'),
            navRoundid,
            element: any = document.querySelector('.hands-details[roundid="' + roundid + '"]');
        navRoundid = this.checkForHands(element, direction, roundid);
        if (navRoundid.length > 0) {
            this.viewUpdatedforDashBoard = false;
            const x: any = document.querySelector('.hands-details[roundid="' + navRoundid + '"] .replay-btn.hand-btn');
            x.click();
        }
    }

    checkForHands(element: any, direction: string, roundid: any) {
        let prevBlock: any,
            nextBlock: any,
            prevElement: any,
            nextElement: any,
            navRoundid: any = '';
        if (direction === 'prev') {
            prevElement = element.previousElementSibling;
        } else if (direction === 'next') {
            nextElement = element.nextElementSibling;
        }
        if ((!prevElement || prevElement.className.indexOf('hands-details') <= -1) && direction === 'prev') {
            prevBlock = document.querySelector('.hands-details[roundid="' + roundid + '"]');
            prevBlock = prevBlock.parentElement.parentElement;
            if (prevBlock && prevBlock.previousElementSibling && prevBlock.previousElementSibling.className.indexOf('grouped-block') > -1) {
                do {
                    prevBlock = prevBlock.previousElementSibling;
                    if (prevBlock.querySelector('.grouped-hands')) {
                        prevElement = prevBlock.querySelector('.hands-details:last-child');
                        break;
                    }
                } while (prevBlock.previousElementSibling);
            }
        } else if ((!nextElement || nextElement.className.indexOf('hands-details') <= -1) && direction === 'next') {
            nextBlock = document.querySelector('.hands-details[roundid="' + roundid + '"]');
            nextBlock = nextBlock.parentElement.parentElement;
            if (nextBlock && nextBlock.nextElementSibling && nextBlock.nextElementSibling.className.indexOf('grouped-block') > -1) {
                do {
                    nextBlock = nextBlock.nextElementSibling;
                    if (nextBlock.querySelector('.grouped-hands')) {
                        nextElement = nextBlock.querySelector('.hands-details');
                        break;
                    }
                } while (nextBlock.nextElementSibling);
            }
        }
        if (direction === 'prev' && prevElement && prevElement.className.indexOf('hands-details') > -1) {
            navRoundid = prevElement.getAttribute('roundid');
        } else if (direction === 'next' && nextElement && nextElement.className.indexOf('hands-details') > -1) {
            navRoundid = nextElement.getAttribute('roundid');
        }
        return navRoundid;
    }

    checkDisableHands(isSingleHand: boolean) {
        let navRoundid;
        const element = document.querySelector('.hands-details[roundid="' + this.roundId + '"]');
        if (!isSingleHand) {
            navRoundid = this.checkForHands(element, 'prev', this.roundId);
            if (navRoundid.length <= 0) {
                this.disablePrevHandButton = true;
            } else {
                this.disablePrevHandButton = false;
            }
            navRoundid = this.checkForHands(element, 'next', this.roundId);
            if (navRoundid.length <= 0) {
                this.disableNextHandButton = true;
            } else {
                this.disableNextHandButton = false;
            }
        }
    }
    getFullScreenForMobiles() {
        const elem: any = document.getElementsByClassName('web-veiw-wrapper')[0];
        if (elem && this.serve.isMobile && !this.serve.Idevices && !this.serve.isTenSeaterGame) {
            elem.requestFullscreen() || elem.webkitRequestFullscreen() || elem.mozRequestFullScreen() || elem.msRequestFullscreen();
        } else {
            return;
        }
    }
}
