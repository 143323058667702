import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export const ResponsiveContainer = (function () {
    function initResponsiveContainer(params) {
        if (params == undefined) {
            params = {};
        }
        this.EventDispatcher = params.EventDispatcher;
        this.xLandscape = params.landX || 0;
        this.yLandscape = params.landY || 0;
        this.xPortrait = params.portX || 0;
        this.yPortrait = params.portY || 0;
        this.xAlignLandscape = params.landAlignX || 'CENTER';
        this.yAlignLandscape = params.landAlignY || 'CENTER';
        this.xAlignPortrait = params.portAlignX || 'CENTER';
        this.yAlignPortrait = params.portAlignY || 'CENTER';
        this.displayUtil = new DisplayUtil();
        this.displayUtil.setObjectPosition(this, this.xPortrait, this.yPortrait);
        this.initialWidth = this.width;
        this.initialHeight = this.height;
        this.initialXScale = 1;
        this.initialYScale = 1;
        this.initialMLScaleX = params.landScaleX != undefined ? params.landScaleX : this.initialXScale;
        this.initialMLScaleY = params.landScaleY != undefined ? params.landScaleY : this.initialYScale;
        this.initialMPScaleX = params.portScaleX != undefined ? params.portScaleX : this.initialXScale;
        this.initialMPScaleY = params.portScaleY != undefined ? params.portScaleY : this.initialYScale;
        this.isResponsiveEnabled = true;
        this.onResizeReference = this.onResize.bind(this);
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_GAME_RESIZE, this.onResizeReference);
        this.onResize();
    }
    function onResize() {
        if (this) {
            try {
                if (!this.scale) {
                    this.removeResizeEvent();
                    return;
                }
                // if (this.scale) {
                //     return;
                // } else {
                //     this.removeResizeEvent();
                //     return;
                // }
            } catch (e) {
                this.removeResizeEvent();
                return;
            }
        }
        if (!this.isResponsiveEnabled) {
            return;
        }
        const width = uiConfig.windowWidth;
        const height = uiConfig.windowHeight;
        this.xRatio = 1;
        this.yRatio = 1;
        this.xRatio = uiConfig.windowWidth / uiConfig.game.baseLayout.ML.baseWidth;
        this.yRatio = uiConfig.windowHeight / uiConfig.game.baseLayout.ML.baseHeight;
        this.scaleFactor = Math.min(this.xRatio, this.yRatio);
        let x = 0;
        let y = 0;
        let xAlignment = 'CENTER';
        let yAlignment = 'CENTER';
        x = this.xPortrait;
        y = this.yPortrait;
        xAlignment = this.xAlignPortrait;
        yAlignment = this.yAlignPortrait;
        this.scale.set(this.scaleFactor * this.initialMPScaleX, this.scaleFactor * this.initialMPScaleY);
        switch (xAlignment) {
            case 'CENTER':
                this.x = x * this.xRatio + (this.initialWidth * (this.xRatio - this.scaleFactor)) / 2;
                break;
            case 'LEFT':
                this.x = x * this.scaleFactor;
                break;
            case 'RIGHT':
                this.x = width - (width / this.xRatio - x) * this.scaleFactor;
                break;
        }
        switch (yAlignment) {
            case 'CENTER':
                this.y = y * this.yRatio + (this.initialHeight * (this.yRatio - this.scaleFactor)) / 2;
                break;
            case 'TOP':
                this.y = y * this.scaleFactor;
                break;
            case 'BOTTOM':
                this.y = height - (height / this.yRatio - y) * this.scaleFactor;
                break;
        }
        this.onResizeCustom();
    }
    function onResizeCustom() {}
    function setAlignment(params) {
        if ('landAlignX' in params) {
            this.xAlignLandscape = params.landAlignX;
        }
        if ('landAlignY' in params) {
            this.yAlignLandscape = params.landAlignY;
        }
        if ('portAlignX' in params) {
            this.xAlignPortrait = params.portAlignX;
        }
        if ('portAlignY' in params) {
            this.yAlignPortrait = params.portAlignY;
        }
        this.onResize();
    }
    function setPosition(params) {
        if ('landX' in params) {
            this.xLandscape = params.landX;
        }
        if ('landY' in params) {
            this.yLandscape = params.landY;
        }
        if ('portX' in params) {
            this.xPortrait = params.portX;
        }
        if ('portY' in params) {
            this.yPortrait = params.portY;
        }
        this.onResize();
    }
    function setSize(width, height) {
        this.initialWidth = width;
        this.initialHeight = height;
        this.onResize();
    }
    function setScale(xScale, yScale) {
        this.initialXScale = xScale;
        this.initialYScale = yScale;
        this.initialMLScaleX = this.initialMPScaleX = this.initialXScale;
        this.initialMLScaleY = this.initialMPScaleY = this.initialYScale;
        this.onResize();
        this.updateSize();
    }
    function updateSize() {
        this.initialWidth = this.width / this.scaleFactor;
        this.initialHeight = this.height / this.scaleFactor;
        this.onResize();
    }
    function removeResizeEvent() {
        this.EventDispatcher.scope.off(SlotCoreGameEvent.ON_GAME_RESIZE, this.onResizeReference);
    }
    function toggleResponsiveNess(bool) {
        this.isResponsiveEnabled = bool;
    }
    return function (params) {
        this.onResize = onResize;
        this.setAlignment = setAlignment;
        this.setPosition = setPosition;
        this.setSize = setSize;
        this.removeResizeEvent = removeResizeEvent;
        this.updateSize = updateSize;
        this.setScale = setScale;
        this.toggleResponsiveNess = toggleResponsiveNess;
        this.onResizeCustom = onResizeCustom;
        initResponsiveContainer.call(this, params);
    };
})();
