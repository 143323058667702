import { Message } from '../../message';

export class ArjelGTPlayerData extends Message {
    private MESSAGE_NAME: string = 'ArjelGTPlayerData';
    private playerAccountName: string | null = null;
    private playerFrontEndId: string | null = null;
    private playerIp: string | null = null;
    private sessionId: number = 0;
    private trnyIbsSessionId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playerAccountName: string | null = null,
        playerFrontEndId: string | null = null,
        playerIp: string | null = null,
        sessionId: number = 0,
        trnyIbsSessionId: number = 0,
    ) {
        super(reqServerPeerId);
        this.playerAccountName = playerAccountName;
        this.playerFrontEndId = playerFrontEndId;
        this.playerIp = playerIp;
        this.sessionId = sessionId;
        this.trnyIbsSessionId = trnyIbsSessionId;
    }

    getPlayerAccountName(): string | null {
        return this.playerAccountName;
    }

    setPlayerAccountName(playerAccountName: string | null) {
        this.playerAccountName = playerAccountName;
    }
    getPlayerFrontEndId(): string | null {
        return this.playerFrontEndId;
    }

    setPlayerFrontEndId(playerFrontEndId: string | null) {
        this.playerFrontEndId = playerFrontEndId;
    }
    getPlayerIp(): string | null {
        return this.playerIp;
    }

    setPlayerIp(playerIp: string | null) {
        this.playerIp = playerIp;
    }
    getSessionId(): number {
        return this.sessionId;
    }

    setSessionId(sessionId: number) {
        this.sessionId = sessionId;
    }
    getTrnyIbsSessionId(): number {
        return this.trnyIbsSessionId;
    }

    setTrnyIbsSessionId(trnyIbsSessionId: number) {
        this.trnyIbsSessionId = trnyIbsSessionId;
    }
}
