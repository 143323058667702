import { Message } from '../../message';

export class RegProfile extends Message {
    private MESSAGE_NAME: string = 'RegProfile';
    private profile: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, profile: any[] | null = null) {
        super(reqServerPeerId);
        this.profile = profile;
    }

    getProfile(): any[] | null {
        return this.profile;
    }

    setProfile(profile: any[] | null) {
        this.profile = profile;
    }
}
