import { Message } from '../../message';

export class SitResponse extends Message {
    private MESSAGE_NAME: string = 'SitResponse';
    private responseId: number = 0;
    private conversationId: number = 0;
    private sitResponseToClient: any | null = null;
    private trackerIdList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        responseId: number = 0,
        conversationId: number = 0,
        sitResponseToClient: any | null = null,
        trackerIdList: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.conversationId = conversationId;
        this.sitResponseToClient = sitResponseToClient;
        this.trackerIdList = trackerIdList;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getSitResponseToClient(): any | null {
        return this.sitResponseToClient;
    }

    setSitResponseToClient(sitResponseToClient: any | null) {
        this.sitResponseToClient = sitResponseToClient;
    }
    getTrackerIdList(): any[] | null {
        return this.trackerIdList;
    }

    setTrackerIdList(trackerIdList: any[] | null) {
        this.trackerIdList = trackerIdList;
    }
}
