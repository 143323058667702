import { Message } from '../../message';

export class PostAnte extends Message {
    private MESSAGE_NAME: string = 'PostAnte';
    private anteType: number = 0;
    private amount: number = 0;
    private timeOutAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, anteType: number = 0, amount: number = 0, timeOutAt: number = 0) {
        super(reqServerPeerId);
        this.anteType = anteType;
        this.amount = amount;
        this.timeOutAt = timeOutAt;
    }

    getAnteType(): number {
        return this.anteType;
    }

    setAnteType(anteType: number) {
        this.anteType = anteType;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getTimeOutAt(): number {
        return this.timeOutAt;
    }

    setTimeOutAt(timeOutAt: number) {
        this.timeOutAt = timeOutAt;
    }
}
