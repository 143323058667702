import { Message } from '../../message';

export class Invitation extends Message {
    private MESSAGE_NAME: string = 'Invitation';
    private source: string | null = null;
    private destination: string | null = null;
    private messageBody: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, source: string | null = null, destination: string | null = null, messageBody: string | null = null) {
        super(reqServerPeerId);
        this.source = source;
        this.destination = destination;
        this.messageBody = messageBody;
    }

    getSource(): string | null {
        return this.source;
    }

    setSource(source: string | null) {
        this.source = source;
    }
    getDestination(): string | null {
        return this.destination;
    }

    setDestination(destination: string | null) {
        this.destination = destination;
    }
    getMessageBody(): string | null {
        return this.messageBody;
    }

    setMessageBody(messageBody: string | null) {
        this.messageBody = messageBody;
    }
}
