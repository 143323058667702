import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pad from './poker-alpha-download-client.component';

@Injectable({ providedIn: 'root' })
export class PokerAlphaDownloadClientMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('alphadownloadclient', pad.PokerAlphaDownloadClientComponent);
        });
    }
}
