import { Message } from '../../message';

export class ResponseUnjoinGlobalWaitlist extends Message {
    private MESSAGE_NAME: string = 'ResponseUnjoinGlobalWaitlist';
    private status: boolean | null = false;
    private requestId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: boolean | null = false, requestId: number = 0) {
        super(reqServerPeerId);
        this.status = status;
        this.requestId = requestId;
    }

    getStatus(): boolean | null {
        return this.status;
    }

    setStatus(status: boolean | null) {
        this.status = status;
    }
    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
}
