import { Message } from '../../message';

export class LSPlayerInfo extends Message {
    private MESSAGE_NAME: string = 'LSPlayerInfo';
    private screenName: string | null = null;
    private seatingPosition: number = 0;
    private balance: number = 0;
    private seatingStatus: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        screenName: string | null = null,
        seatingPosition: number = 0,
        balance: number = 0,
        seatingStatus: number = 0,
    ) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.seatingPosition = seatingPosition;
        this.balance = balance;
        this.seatingStatus = seatingStatus;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getSeatingPosition(): number {
        return this.seatingPosition;
    }

    setSeatingPosition(seatingPosition: number) {
        this.seatingPosition = seatingPosition;
    }
    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
    getSeatingStatus(): number {
        return this.seatingStatus;
    }

    setSeatingStatus(seatingStatus: number) {
        this.seatingStatus = seatingStatus;
    }
}
