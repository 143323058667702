import { Message } from '../../message';

export class AddPlayer extends Message {
    private MESSAGE_NAME: string = 'AddPlayer';
    private playerId: string | null = null;
    private seatingPosition: number = 0;
    private balance: number = 0;
    private country: string | null = null;
    private imageUrl: string | null = null;
    private sex: string | null = null;
    private city: string | null = null;
    private isSeatedPlayer: boolean | null = false;
    private isCustomAvatar: boolean | null = false;
    private brandId: string | null = null;
    private avatarImageName: string | null = null;
    private avatarImagePath: string | null = null;
    private playerAccountId: number = 0;
    private playerFullName: string | null = null;
    private playerRealName: string | null = null;
    private playerNoteId: string | null = null;
    private privateScn: boolean | null = false;
    private countryCode: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playerId: string | null = null,
        seatingPosition: number = 0,
        balance: number = 0,
        country: string | null = null,
        imageUrl: string | null = null,
        sex: string | null = null,
        city: string | null = null,
        isSeatedPlayer: boolean | null = false,
        isCustomAvatar: boolean | null = false,
        brandId: string | null = null,
        avatarImageName: string | null = null,
        avatarImagePath: string | null = null,
        playerAccountId: number = 0,
        playerFullName: string | null = null,
        playerRealName: string | null = null,
        playerNoteId: string | null = null,
        privateScn: boolean | null = false,
        countryCode: string | null = null,
    ) {
        super(reqServerPeerId);
        this.playerId = playerId;
        this.seatingPosition = seatingPosition;
        this.balance = balance;
        this.country = country;
        this.imageUrl = imageUrl;
        this.sex = sex;
        this.city = city;
        this.isSeatedPlayer = isSeatedPlayer;
        this.isCustomAvatar = isCustomAvatar;
        this.brandId = brandId;
        this.avatarImageName = avatarImageName;
        this.avatarImagePath = avatarImagePath;
        this.playerAccountId = playerAccountId;
        this.playerFullName = playerFullName;
        this.playerRealName = playerRealName;
        this.playerNoteId = playerNoteId;
        this.privateScn = privateScn;
        this.countryCode = countryCode;
    }

    getPlayerId(): string | null {
        return this.playerId;
    }

    setPlayerId(playerId: string | null) {
        this.playerId = playerId;
    }
    getSeatingPosition(): number {
        return this.seatingPosition;
    }

    setSeatingPosition(seatingPosition: number) {
        this.seatingPosition = seatingPosition;
    }
    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
    getCountry(): string | null {
        return this.country;
    }

    setCountry(country: string | null) {
        this.country = country;
    }
    getImageUrl(): string | null {
        return this.imageUrl;
    }

    setImageUrl(imageUrl: string | null) {
        this.imageUrl = imageUrl;
    }
    getSex(): string | null {
        return this.sex;
    }

    setSex(sex: string | null) {
        this.sex = sex;
    }
    getCity(): string | null {
        return this.city;
    }

    setCity(city: string | null) {
        this.city = city;
    }
    getIsSeatedPlayer(): boolean | null {
        return this.isSeatedPlayer;
    }

    setIsSeatedPlayer(isSeatedPlayer: boolean | null) {
        this.isSeatedPlayer = isSeatedPlayer;
    }
    getIsCustomAvatar(): boolean | null {
        return this.isCustomAvatar;
    }

    setIsCustomAvatar(isCustomAvatar: boolean | null) {
        this.isCustomAvatar = isCustomAvatar;
    }
    getBrandId(): string | null {
        return this.brandId;
    }

    setBrandId(brandId: string | null) {
        this.brandId = brandId;
    }
    getAvatarImageName(): string | null {
        return this.avatarImageName;
    }

    setAvatarImageName(avatarImageName: string | null) {
        this.avatarImageName = avatarImageName;
    }
    getAvatarImagePath(): string | null {
        return this.avatarImagePath;
    }

    setAvatarImagePath(avatarImagePath: string | null) {
        this.avatarImagePath = avatarImagePath;
    }
    getPlayerAccountId(): number {
        return this.playerAccountId;
    }

    setPlayerAccountId(playerAccountId: number) {
        this.playerAccountId = playerAccountId;
    }
    getPlayerFullName(): string | null {
        return this.playerFullName;
    }

    setPlayerFullName(playerFullName: string | null) {
        this.playerFullName = playerFullName;
    }
    getPlayerRealName(): string | null {
        return this.playerRealName;
    }

    setPlayerRealName(playerRealName: string | null) {
        this.playerRealName = playerRealName;
    }
    getPlayerNoteId(): string | null {
        return this.playerNoteId;
    }

    setPlayerNoteId(playerNoteId: string | null) {
        this.playerNoteId = playerNoteId;
    }
    getPrivateScn(): boolean | null {
        return this.privateScn;
    }

    setPrivateScn(privateScn: boolean | null) {
        this.privateScn = privateScn;
    }
    getCountryCode(): string | null {
        return this.countryCode;
    }

    setCountryCode(countryCode: string | null) {
        this.countryCode = countryCode;
    }
}
