import { Message } from '../../message';

export class SemiFinalGroup extends Message {
    private MESSAGE_NAME: string = 'SemiFinalGroup';
    private groupName: string | null = null;
    private totalChips: number = 0;
    private semiDays: any[] | null = null;
    private groupId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        groupName: string | null = null,
        totalChips: number = 0,
        semiDays: any[] | null = null,
        groupId: number = 0,
    ) {
        super(reqServerPeerId);
        this.groupName = groupName;
        this.totalChips = totalChips;
        this.semiDays = semiDays;
        this.groupId = groupId;
    }

    getGroupName(): string | null {
        return this.groupName;
    }

    setGroupName(groupName: string | null) {
        this.groupName = groupName;
    }
    getTotalChips(): number {
        return this.totalChips;
    }

    setTotalChips(totalChips: number) {
        this.totalChips = totalChips;
    }
    getSemiDays(): any[] | null {
        return this.semiDays;
    }

    setSemiDays(semiDays: any[] | null) {
        this.semiDays = semiDays;
    }
    getGroupId(): number {
        return this.groupId;
    }

    setGroupId(groupId: number) {
        this.groupId = groupId;
    }
}
