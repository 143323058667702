import { Message } from '../../message';

export class RequestRealName extends Message {
    private MESSAGE_NAME: string = 'RequestRealName';
    private screenName: string | null = null;
    private gameId: number = 0;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenName: string | null = null, gameId: number = 0, seatNo: number = 0) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.gameId = gameId;
        this.seatNo = seatNo;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
