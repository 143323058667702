import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PokerBlogFeedService } from './poker-blog-feed.service';
import { PPBlogFeedPageMatrixBootstrapService } from './pp-blog-feed-bootstrap.service';

export function providePPBlogFeed(): Provider[] {
    return [runOnAppInit(PPBlogFeedPageMatrixBootstrapService), PokerBlogFeedService];
}
