export class LoyaltyCashBackViewModel {
    gameService;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.peerId = this.gameService.getPeerId();
        this.view.currentTablePeerId(this.peerId);
    };
}
