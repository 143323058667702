import { Message } from '../../message';

export class Chat extends Message {
    private MESSAGE_NAME: string = 'Chat';
    private type: number = 0;
    private sender: string | null = null;
    private messages: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, type: number = 0, sender: string | null = null, messages: any[] | null = null) {
        super(reqServerPeerId);
        this.type = type;
        this.sender = sender;
        this.messages = messages;
    }

    getType(): number {
        return this.type;
    }

    setType(type: number) {
        this.type = type;
    }
    getSender(): string | null {
        return this.sender;
    }

    setSender(sender: string | null) {
        this.sender = sender;
    }
    getMessages(): any[] | null {
        return this.messages;
    }

    setMessages(messages: any[] | null) {
        this.messages = messages;
    }
}
