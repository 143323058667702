import { Message } from '../../message';
import { PPFont } from './PPFont';

export class MessageOnTable extends Message {
    private MESSAGE_NAME: string = 'MessageOnTable';
    private msgText: any | null = null;
    private x_coord: number = 0;
    private y_coord: number = 0;
    private font: PPFont | null = null;
    private url: string | null = null;
    private tableInfoId: number = 0;
    private status: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        msgText: any | null = null,
        x_coord: number = 0,
        y_coord: number = 0,
        font: PPFont | null = null,
        url: string | null = null,
        tableInfoId: number = 0,
        status: number = 0,
    ) {
        super(reqServerPeerId);
        this.msgText = msgText;
        this.x_coord = x_coord;
        this.y_coord = y_coord;
        this.font = font;
        this.url = url;
        this.tableInfoId = tableInfoId;
        this.status = status;
    }

    getMsgText(): any | null {
        return this.msgText;
    }

    setMsgText(msgText: any | null) {
        this.msgText = msgText;
    }
    getX_coord(): number {
        return this.x_coord;
    }

    setX_coord(x_coord: number) {
        this.x_coord = x_coord;
    }
    getY_coord(): number {
        return this.y_coord;
    }

    setY_coord(y_coord: number) {
        this.y_coord = y_coord;
    }
    getFont(): PPFont | null {
        return this.font;
    }

    setFont(font: PPFont | null) {
        this.font = font;
    }
    getUrl(): string | null {
        return this.url;
    }

    setUrl(url: string | null) {
        this.url = url;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
}
