import { Message } from '../../message';

export class GetRegistryValue extends Message {
    private MESSAGE_NAME: string = 'GetRegistryValue';
    private key: string | null = null;
    private isAbsolute: boolean | null = false;
    private isEncrypted: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, key: string | null = null, isAbsolute: boolean | null = false, isEncrypted: boolean | null = false) {
        super(reqServerPeerId);
        this.key = key;
        this.isAbsolute = isAbsolute;
        this.isEncrypted = isEncrypted;
    }

    getKey(): string | null {
        return this.key;
    }

    setKey(key: string | null) {
        this.key = key;
    }
    getIsAbsolute(): boolean | null {
        return this.isAbsolute;
    }

    setIsAbsolute(isAbsolute: boolean | null) {
        this.isAbsolute = isAbsolute;
    }
    getIsEncrypted(): boolean | null {
        return this.isEncrypted;
    }

    setIsEncrypted(isEncrypted: boolean | null) {
        this.isEncrypted = isEncrypted;
    }
}
