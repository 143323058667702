import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'ppl-schedule-config',
    templateUrl: 'ppl-schedule-config.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PPLScheduleConfigComponent extends PCComponent {
    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }
}
