import { ByteBuffer } from './Lib/byteBuffer';
import { inflateBytes } from './Lib/zip/zipUtils';
import { IFrame } from './interfaces/Iframe';

export const FrameDecoder = {
    MAX_SIZE_FOR_INCOMING_PKT: 512 * 1024,

    decode(buf: ByteBuffer): IFrame[] {
        const frames: IFrame[] = [];
        let one: IFrame | null;
        do {
            one = FrameDecoder.decodeOne(buf);
            if (one) {
                frames.push(one);
            }
        } while (one && buf.hasRemaining());

        return frames;
    },

    decodeOne(buf: ByteBuffer): IFrame | null {
        buf.mark();
        const header = buf.get();
        const lengthLen = (header & 0x0c) >>> 2;
        let length = 0;

        if (buf.remaining() > lengthLen) {
            switch (lengthLen) {
                case 0:
                    length = buf.getUnsigned();
                    break;
                case 1:
                    length = buf.getUnsignedShort();
                    break;
                case 2:
                    length = buf.getUnsignedMediumInt();
                    break;
                case 3:
                    length = buf.getUnsignedInt();
                    break;
            }

            if (length > FrameDecoder.MAX_SIZE_FOR_INCOMING_PKT) {
                throw new Error('Frame length limit exceeded');
            }

            if (length <= 0) {
                throw new Error('Negative/Zero Length');
            }
        } else {
            buf.reset();
            return null;
        }

        const protocolVersion = (header >>> 4) & 7;
        const receiverIdExists = (header & 2) !== 0;
        const msgFrmClient = header >>> 7 === 0;
        let neededLength = 3 + length;

        if (protocolVersion === 1 || protocolVersion === 3) {
            neededLength += 2;
        } else if (protocolVersion === 4) {
            neededLength += 4;
        }

        if (receiverIdExists) {
            neededLength += 4;
        }

        if (!msgFrmClient) {
            neededLength += 2;
        }

        if (buf.remaining() < neededLength) {
            buf.reset();
            return null;
        }

        let uncompressedSize = 0;

        if (protocolVersion === 1 || protocolVersion === 3) {
            uncompressedSize = buf.getUnsignedShort();

            if (uncompressedSize <= 0) {
                throw new Error('Negative/Zero Uncompressed size');
            }
        } else if (protocolVersion === 4) {
            uncompressedSize = buf.getUnsignedInt();

            if (uncompressedSize <= 0) {
                throw new Error('Negative/Zero Uncompressed size');
            }
        }

        if (uncompressedSize > FrameDecoder.MAX_SIZE_FOR_INCOMING_PKT) {
            throw new Error('Frame uncompressed length limit exceeded');
        }

        const classId = buf.getUnsignedMediumInt();
        let reqServerPeerId = 0;

        if (receiverIdExists) {
            reqServerPeerId = buf.getUnsignedInt();
        }

        if (!msgFrmClient) {
            buf.getUnsignedShort();
        }

        let msgFrame: IFrame | null = null;
        const msgBodyBytes = buf.getBytes(length);

        switch (protocolVersion) {
            case 1:
            case 3:
            case 4:
                {
                    const uncompressedByteArray = inflateBytes(msgBodyBytes);
                    const uncompressedBuf = new ByteBuffer(uncompressedByteArray);
                    msgFrame = { msgBody: uncompressedBuf, classId, protocolVersion, reqServerPeerId };
                }
                break;

            default:
                {
                    const msgBodyBuf = new ByteBuffer(msgBodyBytes);

                    msgFrame = { msgBody: msgBodyBuf, classId, protocolVersion, reqServerPeerId };
                }
                break;
        }

        return msgFrame;
    },
};
