import { Message } from '../../message';

export class RequestLobbyUpdateOneClick extends Message {
    private MESSAGE_NAME: string = 'RequestLobbyUpdateOneClick';
    private lobbyType: number = 0;
    private snapShotTimeInNanos: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lobbyType: number = 0, snapShotTimeInNanos: number = 0) {
        super(reqServerPeerId);
        this.lobbyType = lobbyType;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }

    getLobbyType(): number {
        return this.lobbyType;
    }

    setLobbyType(lobbyType: number) {
        this.lobbyType = lobbyType;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
}
