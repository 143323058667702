<div class="teasers-crousale teasers" *ngIf="teasersResponse.length">
    <div [ngClass]="TeaserMode" class="md-teaser-section hero-teaser-new small-teaser">
        <pk-teaser-carousel
            [autoplay]="autoplay"
            [dots]="dots"
            [loop]="loop"
            [pauseOnHover]="pauseOnHover"
            [teasersResponse]="teasersResponse"
            (teasersClick)="teaserClick($event)"
            (onCtaClick)="ctaClick($event)"
            [class]="assignBrowserSpecificClass()">
        </pk-teaser-carousel>
    </div>
</div>
