import { Message } from '../../message';

export class HUPairTableSelectStatus extends Message {
    private MESSAGE_NAME: string = 'HUPairTableSelectStatus';
    private selected: boolean | null = false;
    private tables: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, selected: boolean | null = false, tables: any[] | null = null) {
        super(reqServerPeerId);
        this.selected = selected;
        this.tables = tables;
    }

    getSelected(): boolean | null {
        return this.selected;
    }

    setSelected(selected: boolean | null) {
        this.selected = selected;
    }
    getTables(): any[] | null {
        return this.tables;
    }

    setTables(tables: any[] | null) {
        this.tables = tables;
    }
}
