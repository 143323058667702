import { Message } from '../../message';

export class LSTabIdLastUpdatedTime extends Message {
    private MESSAGE_NAME: string = 'LSTabIdLastUpdatedTime';
    private tabId: number = 0;
    private snapShotTimeInNanos: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tabId: number = 0, snapShotTimeInNanos: number = 0) {
        super(reqServerPeerId);
        this.tabId = tabId;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }

    getTabId(): number {
        return this.tabId;
    }

    setTabId(tabId: number) {
        this.tabId = tabId;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
}
