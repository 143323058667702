import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

@Component({
    selector: 'homepage-howtoplaysection',
    templateUrl: 'homepage-howtoplaysection.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class HomePageHowtoplaySectionComponent implements OnInit {
    howtoplayContentContent: any;
    mobileContentContent: any;
    allData: any;

    constructor(private homePageService: HomepageResourceService) {}
    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            switch (allData[i].metadata.id.itemName.toLowerCase()) {
                case 'howtoplaycontent': {
                    this.howtoplayContentContent = allData[i];
                    break;
                }
                case 'mobilecontent': {
                    this.mobileContentContent = allData[i];
                    break;
                }
            }
        }
    }
}
