// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class GenericPopUpView extends PIXI.Container {
    EventDispatcher;
    fontProps;
    blackBG;
    displayUtil: DisplayUtil = new DisplayUtil();
    genericPopupContainer;
    isKOTH;
    isAntiTblHop;
    isRathol;
    constructor() {
        super();
    }
    createView(EventDispatcher) {
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        //this.blackBG = this.displayUtil.getRectangleSprite(uiConfig.windowWidth, uiConfig.windowHeight, "#000000",false,0,0,0);
        this.blackBG = this.displayUtil.getRectangleSprite(720, 1280, '#000000', false, 0, 0, 0);
        if (window.innerWidth <= 0 || window.innerHeight <= 0) {
            this.blackBG = this.displayUtil.getRectangleSprite(10, 10, '#000000', false, 0, 0, 0);
        }
        this.displayUtil.setObjectPosition(this.blackBG, 0, 0);
        this.blackBG.visible = false;
        this.blackBG.name = 'blackBG';
        this.blackBG.alpha = 0.5;
        this.blackBG.interactive = true;
        this.blackBG.buttonMode = true;
        this.addChild(this.blackBG);
        this.genericPopupContainer = this.displayUtil.getContainer();
        this.addChild(this.genericPopupContainer);
        ResponsiveContainer.call(this.genericPopupContainer, {
            portX: 82,
            portY: 472,
            EventDispatcher: this.EventDispatcher,
        });
        this.genericPopupContainer.setSize(720, 1280);
        this.isKOTH = false;
        this.isAntiTblHop = false;
        this.isRathol = false;
    }
    resizeBlackBg = function () {
        this.blackBG.width = 720;
        this.blackBG.height = 1280;
        this.onResizeToFitTheScreen();
        // console.log(window.innerWidth, window.innerHeight);
    };
    onResizeToFitTheScreen = function () {
        const xRatio = uiConfig.windowWidth / uiConfig.game.baseLayout['MP'].baseWidth;
        const yRatio = uiConfig.windowHeight / uiConfig.game.baseLayout['MP'].baseHeight;
        const scaleFactor = Math.min(xRatio, yRatio);
        if (xRatio != scaleFactor) {
            const x_Up_ScaleFactor = xRatio / scaleFactor;
            this.blackBG.scale.x = x_Up_ScaleFactor;
            this.blackBG.x = -(this.blackBG.width - uiConfig.game.baseLayout['MP'].baseWidth) * 0.5;
        }
        if (yRatio != scaleFactor) {
            const y_Up_ScaleFactor = yRatio / scaleFactor;
            this.blackBG.scale.y = y_Up_ScaleFactor;
            this.blackBG.y = -(this.blackBG.height - uiConfig.game.baseLayout['MP'].baseHeight);
        }
    };
    setParentViewModel = function (parentViewModel) {
        this.parentViewModel = parentViewModel;
    };
    onClose = function (popUpId) {
        this['p' + popUpId]['popUp'].visible = false;
        this.removePopUp(popUpId);
    };
    //brings the popUp to front
    focusPopUp = function (popUpId) {
        if (!popUpId || !this['p' + popUpId]['popUp']) return;
        this.blackBG.visible = true;
        this.resizeBlackBg();
        this['p' + popUpId]['popUp'].visible = true;
    };
    //creates a new container and adds to genericpopupView
    addPopUp = function (obj) {
        //obj = JSON.parse('{"titleText":"Warning","bodyText":"If you leave now , your hand will be folded.","buttonsTextArr":["LEAVE TABLE","KEEP PLAYING"],"buttonsActionArr":["",""],"popUpId":"PARTY_POKER_GC_LEAVETABLE","showCloseButton":false,"showCheckBox":true,"priority":0}')
        this.peerId = obj.popUpId;
        this.blackBG.visible = true;
        this.isAntiTblHop = obj.isAntiTblHop;
        this.isRathol = obj.isRathol;
        this.popUpBGHeightAdj = 70;
        this.resizeBlackBg();
        this.createPopUpContainer(obj.popUpId, obj.showCloseButton, obj.showCheckBox, obj);
        //this.addElementsToPopUp(obj)
        //set elements in the container
        this.setTitle(obj.popUpId, obj.titleText);
        this.setBodyText(obj.popUpId, obj.bodyText);
        if (obj.titleText == CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_KOTH_TITLEBAR_TEXT_NEW')) {
            const Ypos = this['p' + obj.popUpId]['bodyText'].height + 81;
            //this["p" + obj.popUpId]["bodyText"].text=this["p" + obj.popUpId]["bodyText"].text+facade.langPack.getLiteral("PARTY_POKER_GC_GAMELOBBY_DONTSHOWMSG");
            this['p' + obj.popUpId]['bodyText'].text = this['p' + obj.popUpId]['bodyText'].text + "Don't show this message again.";
            this.displayUtil.setObjectPosition(this.checkBoxContainer, 33, Ypos);
            this.isKOTH = true;
            this.checkMask.tint = '0xffffff';
            this.quickCheck.tint = '0x000000';
        }
        this.scaleBodyForText(obj.popUpId);
        this.setButtons(obj.popUpId, obj.buttonsTextArr, obj.buttonsActionArr);
        this['p' + obj.popUpId]['popUp'].visible = false;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.LCB_SET_NOT_CLICKABLE, false);
        this.setPopupBG(obj.popUpId);
        this.handlePopupBG(true);
        this.centerPopUpToScreen(obj.popUpId);
    };
    handlePopupBG = function (isSelected) {
        for (let i = 0; i < this.genericPopupContainer.children.length - 1; i++) {
            for (let j = 0; j < this.genericPopupContainer.children[i].children.length; j++) {
                if (this.genericPopupContainer.children[i].children[j].name == 'popupBG')
                    this.genericPopupContainer.children[i].children[j].visible = isSelected;
            }
        }
    };
    setPopupBG = function (popUpId) {
        this.popupBG = this.displayUtil.getRectangleSprite(this['p' + popUpId]['popUp'].width, this['p' + popUpId]['popUp'].height, '#000000');
        this.popupBG.alpha = 0.9;
        this.popupBG.name = 'popupBG';
        this.popupBG.visible = false;
        this['p' + popUpId]['popUp'].addChild(this.popupBG);
    };
    //notifies parentViewModel to update popUpMap and delete PopUp @Maniraj - needs to be done
    removePopUp = function (popUpId) {
        this['p' + popUpId]['popUp'].visible = false;
        this.parentViewModel.removePopUp(popUpId);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.LCB_SET_NOT_CLICKABLE, true);
    };
    // viewModel updates the popUpMap and invokes this,
    // this method deletes the popup container with the given Id
    // warning: don't use this method directly, call removePopUp instead
    deletePopUp = function (popUpId) {
        this.blackBG.visible = false;
        this.handlePopupBG(false);
        this['p' + popUpId]['popUp'].visible = false;
        this.genericPopupContainer.removeChild(this['p' + popUpId]['popUp']);
        this['p' + popUpId]['popUp'].destroy();
        this['p' + popUpId] = undefined;
        // this['p'+popUpId]["popUp"] = undefined;
        // this['p'+popUpId]["actionsArr"]  = undefined;
        // this['p'+popUpId]["titleText"] = undefined;
        // this['p'+popUpId]["bodyText"] = undefined;
    };
    setButtons = function (popUpId, buttonsTextArr, actionsArr) {
        let adjX = 20;
        this['p' + popUpId]['actionsArr'] = actionsArr;
        if (!this['p' + popUpId]['buttons']) this['p' + popUpId]['buttons'] = [];
        for (let i = 0; i < buttonsTextArr.length; i++) {
            switch (buttonsTextArr.length) {
                case 1:
                    if (this.isKOTH == true) {
                        this['p' + popUpId]['buttons'][i] = this.displayUtil.getBorderRoundRectangleSprite(516, 96, '0xE8490D', 5, false);
                    } else if (this.isAntiTblHop == true) {
                        adjX = 150;
                        this['p' + popUpId]['buttons'][i] = this.displayUtil.getSprite('antiOrengeBtn');
                    } else {
                        this['p' + popUpId]['buttons'][i] = this.displayUtil.getButton('okBtn');
                    }
                    break;
                case 2:
                    if (this.isAntiTblHop == true) {
                        adjX = adjX - 5;
                        switch (i) {
                            case 0:
                                this['p' + popUpId]['buttons'][i] = this.displayUtil.getButton('AthGrayBtn');
                                break;
                            case 1:
                                this['p' + popUpId]['buttons'][i] = this.displayUtil.getSprite('antiOrengeBtn');
                                break;
                        }
                    } else if (this.isRathol == true) {
                        switch (i) {
                            case 0:
                                this['p' + popUpId]['buttons'][i] = this.displayUtil.getButton('ratholcancelBtnBg');
                                break;
                            case 1:
                                this['p' + popUpId]['buttons'][i] = this.displayUtil.getButton('ratholbuyInBtnBg');
                                break;
                        }
                    } else {
                        this['p' + popUpId]['buttons'][i] = this.displayUtil.getButton('YesNoBtn');
                    }
                    break;
                default:
                    this['p' + popUpId]['buttons'][i] = this.displayUtil.getButton('YesNoBtn');
            }
            this['p' + popUpId]['popUp'].addChild(this['p' + popUpId]['buttons'][i]);
            this.displayUtil.addInteraction(this['p' + popUpId]['buttons'][i], this.onButtonAction.bind(this, popUpId, i));
            this.displayUtil.setClickable(this['p' + popUpId]['buttons'][i], true);
            const buttonWidth = this['p' + popUpId]['buttons'][i].width;
            let buttonY = this['p' + popUpId]['bodyBG'].y + this['p' + popUpId]['bodyBG'].height - this['p' + popUpId]['buttons'][i].height - 28;
            if (this.isAntiTblHop == true) {
                buttonY = this['p' + popUpId]['learnMoreTxt'].y + 60;
            }
            this.displayUtil.setObjectPosition(this['p' + popUpId]['buttons'][i], adjX + i * (6 + buttonWidth), buttonY);
            this.setButtonText(popUpId, i, buttonsTextArr[i]); //onButtonAction;//mcmdjjkd; //create button
        }
    };
    setButtonText = function (popUpId, index, text) {
        if (!this.buttonText) this.buttonText = [];
        if (this.isAntiTblHop == true) {
            this.fontProps.genericPopUButtonTextStyle.wordWrapWidth = 175;
        } else {
            this.fontProps.genericPopUButtonTextStyle.wordWrapWidth = 500;
        }
        this.buttonText[index] = this.displayUtil.getTextField(this.fontProps.genericPopUButtonTextStyle);
        this['p' + popUpId]['buttons'][index].addChild(this.buttonText[index]);
        this.buttonText[index].text = text;
        this.buttonText[index].anchor.set(0.5);
        const button = this['p' + popUpId]['buttons'][index];
        if (this.isKOTH == true) {
            this.displayUtil.setObjectPosition(this.buttonText[index], button.width / 2 - 10, 45);
        } else if (this.isAntiTblHop == true) {
            if (this.buttonText.length > 1) {
                this.displayUtil.setObjectPosition(this.buttonText[index], button.width / 2, 45);
            } else {
                this.displayUtil.setObjectPosition(this.buttonText[index], button.width / 2, 45);
            }
        } else {
            this.displayUtil.setObjectPosition(this.buttonText[index], button.width / 2, 45);
        }
    };
    setTitle = function (popUpId, text) {
        if (text) this['p' + popUpId]['titleText'].text = text;
        // this.titleMultiText = new MultiStyleText("Let's make some <ml>multiline</ml>\nand <ms>multistyle</ms> text for\n<pixi>Pixi.js!</pixi>",
        // {
        //     "default": {
        //         fontFamily: "Arial",
        //         fontSize: "24px",
        //         fill: "#cccccc",
        //         align: "center"
        //     },
        //     "ml": {
        //         fontStyle: "italic",
        //         fill: "#ff8888"
        //     },
        //     "ms": {
        //         fontStyle: "italic",
        //         fill: "#4488ff"
        //     },
        //     "pixi": {
        //         fontSize: "64px",
        //         fill: "#efefef"
        //     }
        // });

        // this.titleImage.addChild(this.titleMultiText);
        // this.titleText.anchor.set(0.5);
        // this.displayUtil.setObjectPosition(this.titleMultiText, 311, 45);
    };
    setBodyText = function (popUpId, text) {
        if (text) {
            if (this.isRathol == true) {
                this['p' + popUpId]['bodyText1'].text = text.text1 + text.rathAmt;
                this['p' + popUpId]['bodyText2'].text = text.text2;
            } else {
                this['p' + popUpId]['bodyText'].text = text;
            }
        }
        if (this.isKOTH == true) {
            this['p' + popUpId]['bodyText'].style.align = 'center';
        }
    };
    onButtonAction = function (popUpId, i) {
        this.EventDispatcher.dispatchEvent(this['p' + popUpId]['actionsArr'][i]);
        this.removePopUp(popUpId);
    };
    createPopUpContainer = function (popUpId, showCloseButton, showCheckBox, popUpObj) {
        //create one container and add to parent.
        this.popUpBGHeightAdj = this.popUpBGHeightAdj + popUpObj.learnMoreYAdj;
        this['p' + popUpId] = {};
        this['p' + popUpId]['popUp'] = this.displayUtil.getContainer();
        this.genericPopupContainer.addChild(this['p' + popUpId]['popUp']);
        const popUpContainer = this['p' + popUpId]['popUp'];
        //popUpContainer.pivot.y = 0.5;
        if (this.isAntiTblHop == true) {
            if (popUpObj.enableDarkBG == true) {
                this.blackBG.alpha = 1;
            }
            const bodybackgroundImage = this.displayUtil.getSprite('antiBodyBG');
            popUpContainer.addChild(bodybackgroundImage);
            this.displayUtil.setObjectPosition(bodybackgroundImage, 0, 0);
            this['p' + popUpId]['bodyBG'] = bodybackgroundImage;
            const titleImage = this.displayUtil.getSprite('antiHeaderBG');
            popUpContainer.addChild(titleImage);
            this.displayUtil.setObjectPosition(titleImage, 5, 4);
            this['p' + popUpId]['headerBG'] = titleImage;
            const closeBtn = this.displayUtil.getButton('close'); //this.displayUtil.getSprite("close");
            titleImage.addChild(closeBtn);
            this.displayUtil.addInteraction(closeBtn, this.onButtonAction.bind(this, popUpId, 1));
            this.displayUtil.setClickable(closeBtn, true);
            this.displayUtil.setObjectPosition(closeBtn, titleImage.x + titleImage.width - closeBtn.width - 20, 18);
            this.displayUtil.assignHitArea(
                closeBtn,
                {
                    x: 0,
                    y: -10,
                    w: closeBtn.width + 20,
                    h: closeBtn.height + 20,
                },
                'rect',
            );
            //Add Text Elements to popup
            const titleText = this.displayUtil.getTextField(this.fontProps.genericPopUpTitleTextStyle);
            this['p' + popUpId]['titleText'] = titleText;
            popUpContainer.addChild(titleText);
            //titleText.text = "Popup Title";
            titleText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(titleText, 286, 42);

            const antiTblAlert = this.displayUtil.getSprite('antiAlert');
            popUpContainer.addChild(antiTblAlert);
            antiTblAlert.anchor.set(0.5);
            this['p' + popUpId]['antiTblAlert'] = antiTblAlert;
            this.displayUtil.setObjectPosition(antiTblAlert, 282, titleImage.y + titleImage.height + 56);

            this.fontProps.antiTblHopAlerMsgTxt.wordWrapWidth = bodybackgroundImage.width - 40;
            const bodyText = this.displayUtil.getTextField(this.fontProps.antiTblHopAlerMsgTxt);
            this['p' + popUpId]['bodyText'] = bodyText;
            popUpContainer.addChild(bodyText);
            bodyText.text = popUpObj.bodyText;
            this.displayUtil.setObjectPosition(bodyText, 22, antiTblAlert.y + 60);
            let warningMsgYPos = 0;
            if (popUpObj.showWarning == true) {
                const warningText = this.displayUtil.getTextField(this.fontProps.antiTblHopWarningTxt);
                this['p' + popUpId]['warningText'] = warningText;
                popUpContainer.addChild(warningText);
                warningText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_WARNINGTXT');
                //warningText.anchor.set(0.5, 0);
                this.displayUtil.setObjectPosition(warningText, 22, bodyText.y + bodyText.height + 35);
                warningMsgYPos = warningText.y + warningText.height + 5;
            } else {
                warningMsgYPos = bodyText.y + bodyText.height + 35;
            }

            const warningMsg = this.displayUtil.getTextField(this.fontProps.antiTblHopWarningMsg);
            this['p' + popUpId]['warningMsg'] = warningMsg;
            popUpContainer.addChild(warningMsg);
            warningMsg.text = popUpObj.antiWarningMSG;
            this.displayUtil.setObjectPosition(warningMsg, 22, warningMsgYPos);
            // if(popUpObj.showXYBold == true)
            // {
            //   var yhandsBoldTxt = this.displayUtil.getTextField(
            //     this.fontProps.antiTblHopXYHandBold
            //   );
            //   this["p" + popUpId]["yhandsBoldTxt"] = yhandsBoldTxt;
            //   popUpContainer.addChild(yhandsBoldTxt);
            //   yhandsBoldTxt.text = popUpObj.yhandsTxt;
            //   this.displayUtil.setObjectPosition(
            //     yhandsBoldTxt,
            //     22 + popUpObj.yhandsXpos,
            //     warningMsg.y - popUpObj.yhandsYpos
            //   );
            //   var xMorehandsBoldTxt = this.displayUtil.getTextField(
            //     this.fontProps.antiTblHopXYHandBold
            //   );
            //   this["p" + popUpId]["xMorehandsBoldTxt"] = xMorehandsBoldTxt;
            //   popUpContainer.addChild(xMorehandsBoldTxt);
            //   xMorehandsBoldTxt.text = popUpObj.xMorehandsTxt;
            //   this.displayUtil.setObjectPosition(
            //     xMorehandsBoldTxt,
            //     22 + popUpObj.xMorehandsXPos,
            //     warningMsg.y - popUpObj.xMorehandsYPos
            //   );
            // }
            const learnMoreTxt = this.displayUtil.getTextField(this.fontProps.antiTblHopLearnMoreTxt);
            this['p' + popUpId]['learnMoreTxt'] = learnMoreTxt;
            popUpContainer.addChild(learnMoreTxt);
            learnMoreTxt.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEARNMORE');
            this.displayUtil.setObjectPosition(learnMoreTxt, 22, warningMsg.y + warningMsg.height + popUpObj.learnMoreYAdj);
            this.displayUtil.addInteraction(learnMoreTxt, this.onLearnMore.bind(this));
            this.displayUtil.setClickable(learnMoreTxt, true);
            const learnMoreUnderLine = this.displayUtil.getBorderRoundRectangleSprite(learnMoreTxt.width, 2, '0x919191', 0, false);
            this['p' + popUpId]['learnMoreUnderLine'] = learnMoreUnderLine;
            popUpContainer.addChild(learnMoreUnderLine);
            this.displayUtil.setObjectPosition(learnMoreUnderLine, learnMoreTxt.x, learnMoreTxt.y + (learnMoreTxt.height - 3));
            this.displayUtil.setObjectPosition(popUpContainer, popUpContainer.x, popUpContainer.y - 75);
        } else if (
            this.isRathol == true &&
            popUpObj.titleText === CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_BUYINCHIPS_BUYINTITLE')
        ) {
            this.blackBG.alpha = 0.5;

            //create background and close button
            const ratholBodybackgroundImage = this.displayUtil.getSprite('ratholPopUpBg');
            popUpContainer.addChild(ratholBodybackgroundImage);
            this.displayUtil.setObjectPosition(ratholBodybackgroundImage, 0, 0);
            ratholBodybackgroundImage.scale.x = 1.74;
            this['p' + popUpId]['bodyBG'] = ratholBodybackgroundImage;

            const ratholTitleImage = this.displayUtil.getSprite('ratholTitleBg');
            popUpContainer.addChild(ratholTitleImage);
            this.displayUtil.setObjectPosition(ratholTitleImage, 7, 5);
            ratholTitleImage.scale.x = 1.74;
            ratholTitleImage.scale.y = 1.6;
            this['p' + popUpId]['headerBG'] = ratholTitleImage;

            const ratholHeaderProgressBar = this.displayUtil.getSpriteDetails('ratholProgressAnim');
            ratholHeaderProgressBar.animationSpeed = 600 / (popUpObj.bodyText.progressBarTime * 60);
            const ratholHeaderProgressBarAnim = this.displayUtil.getCustomAnimatedSprite(ratholHeaderProgressBar);
            //ratholHeaderProgressBarAnim.anchor.set(0.5);
            popUpContainer.addChild(ratholHeaderProgressBarAnim);
            ratholHeaderProgressBarAnim.loop = false;
            ratholHeaderProgressBarAnim.scale.x = 1.73;
            this.displayUtil.setObjectPosition(ratholHeaderProgressBarAnim, 7, ratholTitleImage.y + ratholTitleImage.height);
            this['p' + popUpId]['ratHolePBAnime'] = ratholHeaderProgressBarAnim;
            this['p' + popUpId]['ratHolePBAnime'].play();
            this['p' + popUpId]['ratHolePBAnime'].onComplete = function () {
                // console.log("ls..timeOut");
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.RATHOL_CANCEL);
                this.isRathol = false;
            }.bind(this);

            const ratholTitleText = this.displayUtil.getTextField(this.fontProps.ratholPopUpTitleStyle);
            this['p' + popUpId]['titleText'] = ratholTitleText;
            popUpContainer.addChild(ratholTitleText);
            ratholTitleText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(ratholTitleText, 286, 42);
            this.fontProps.genericPopUpBodyTextStyle.wordWrapWidth = ratholBodybackgroundImage.width - 50;

            const ratholBodyText1 = this.displayUtil.getTextField(this.fontProps.ratholPopUpBodyTextStyle);
            this['p' + popUpId]['bodyText1'] = ratholBodyText1;
            popUpContainer.addChild(ratholBodyText1);
            ratholBodyText1.anchor.set(0.5, 0);
            this.displayUtil.setObjectPosition(ratholBodyText1, 280, ratholTitleImage.y + ratholTitleImage.height + 36);

            const ratholBodyText2 = this.displayUtil.getTextField(this.fontProps.ratholPopUpBodyTextStyle);
            this['p' + popUpId]['bodyText2'] = ratholBodyText2;
            popUpContainer.addChild(ratholBodyText2);
            ratholBodyText2.anchor.set(0.5, 0);
            this.displayUtil.setObjectPosition(ratholBodyText2, 280, ratholTitleImage.y + ratholTitleImage.height + 130);
        } else {
            //create background and close button
            const bodybackgroundImage = this.displayUtil.getSprite('bodyBG');
            popUpContainer.addChild(bodybackgroundImage);
            this.displayUtil.setObjectPosition(bodybackgroundImage, 0, 0);
            this['p' + popUpId]['bodyBG'] = bodybackgroundImage;
            const titleImage = this.displayUtil.getSprite('headerBG');
            popUpContainer.addChild(titleImage);
            this.displayUtil.setObjectPosition(titleImage, 6, 4);
            this['p' + popUpId]['headerBG'] = titleImage;
            if (showCloseButton) {
                const closeBtn = this.displayUtil.getButton('close'); //this.displayUtil.getSprite("close");
                titleImage.addChild(closeBtn);
                this.displayUtil.addInteraction(closeBtn, this.onClose.bind(this, popUpId));
                this.displayUtil.setClickable(closeBtn, true);
                this.displayUtil.setObjectPosition(closeBtn, titleImage.x + titleImage.width - closeBtn.width - 20, 18);
                this.displayUtil.assignHitArea(
                    closeBtn,
                    {
                        x: 0,
                        y: -10,
                        w: closeBtn.width + 20,
                        h: closeBtn.height + 20,
                    },
                    'rect',
                );
            }
            if (showCheckBox) {
                this.checkBoxContainer = this.displayUtil.getContainer();
                popUpContainer.addChild(this.checkBoxContainer);
                this.displayUtil.setObjectPosition(this.checkBoxContainer, 20, 274);
                this.checkBoxIcon = this.displayUtil.getSprite('Rectangle_CheckBox');
                this.checkBoxContainer.addChild(this.checkBoxIcon);
                this.displayUtil.setObjectPosition(this.checkBoxIcon, 0, 0);
                this.displayUtil.setClickable(this.checkBoxIcon, true);
                this.displayUtil.addInteraction(this.checkBoxIcon, this.CheckboxClick.bind(this));
                this.checkMask = this.displayUtil.getRectangleSprite(this.checkBoxIcon.width, this.checkBoxIcon.height, '0xaaaaaa');
                this.checkBoxContainer.addChild(this.checkMask);
                this.displayUtil.setObjectPosition(this.checkMask, 0, 0);
                this.quickCheck = this.checkMask;
                this.checkMask.visible = false;
                this.quickCheck = this.displayUtil.getSprite('Rectangle_Tick');
                this.checkBoxContainer.addChild(this.quickCheck);
                this.quickCheck.visible = false;
                this.displayUtil.setObjectPosition(this.quickCheck, 6, 6);
                this.displayUtil.setClickable(this.checkMask, true);
                this.displayUtil.addInteraction(this.checkMask, this.deSelectCheckBox.bind(this));
            }
            //Add Text Elements to popup
            const titleText = this.displayUtil.getTextField(this.fontProps.genericPopUpTitleTextStyle);
            this['p' + popUpId]['titleText'] = titleText;
            popUpContainer.addChild(titleText);
            //titleText.text = "Popup Title";
            titleText.anchor.set(0.5);
            this.displayUtil.setObjectPosition(titleText, 286, 42);
            this.fontProps.genericPopUpBodyTextStyle.wordWrapWidth = bodybackgroundImage.width - 50;
            const bodyText = this.displayUtil.getTextField(this.fontProps.genericPopUpBodyTextStyle);
            this['p' + popUpId]['bodyText'] = bodyText;
            popUpContainer.addChild(bodyText);
            //bodyText.text = "Body text";
            bodyText.anchor.set(0.5, 0);
            this.displayUtil.setObjectPosition(bodyText, 280, titleImage.y + titleImage.height + 36);
        }
        this.genericPopupContainer.updateSize();
    };
    onRathBuyin = function () {
        if (this.isRathol == true) {
            this.isRathol = false;
            this['p' + this.peerId]['ratHolePBAnime'].gotoAndStop(50);
        }
    };
    onLearnMore = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.OPEN_ATH_LEARN_MORE);
    };

    scaleBodyForText = function (popUpId) {
        let requiredHeight;
        if (this.isRathol == true) {
            requiredHeight =
                this['p' + popUpId]['headerBG'].height + this['p' + popUpId]['bodyText1'].height + this['p' + popUpId]['bodyText2'].height + 260;
        } else {
            requiredHeight = this['p' + popUpId]['headerBG'].height + this['p' + popUpId]['bodyText'].height + 200;
            if (this.isAntiTblHop == true) {
                let warningTextHeight = 0;
                if (this['p' + popUpId]['warningText'] != undefined) {
                    warningTextHeight = this['p' + popUpId]['warningText'].height;
                }
                requiredHeight =
                    requiredHeight +
                    this['p' + popUpId]['antiTblAlert'].height +
                    warningTextHeight +
                    this['p' + popUpId]['warningMsg'].height +
                    this['p' + popUpId]['learnMoreTxt'].height +
                    this.popUpBGHeightAdj;
            }
        }
        this['p' + popUpId]['bodyBG'].scale.y = requiredHeight / this['p' + popUpId]['bodyBG'].height;
        //this["p" + popUpId]["popUp"].pivot.y = 0.5;
        //this.displayUtil.setObjectPosition(this["p" + popUpId]["popUp"], 86, 300);
        this['p' + popUpId]['popUp'].name = 'childCon';
        // this.displayUtil.setObjectPosition(this["p" + popUpId]["popUp"], (720 - this["p" + popUpId]["popUp"].width)/2, (1280 - this["p" + popUpId]["popUp"].height)/2);
        // this.centerPopUpToScreen(popUpId);
        this.genericPopupContainer.updateSize();
    };
    centerPopUpToScreen = function (popUpId) {
        if (this.isAntiTblHop == true) {
            const popUpContainer = this['p' + popUpId]['popUp'];
            this.displayUtil.setObjectPosition(popUpContainer, popUpContainer.x, popUpContainer.y - 75);
        }
    };
    CheckboxClick = function () {
        this.quickCheck.visible = true;
        this.checkMask.visible = true;
        this.checkBoxIcon.visible = false;
        localStorage.setItem('isChecked', JSON.stringify(true));
    };
    deSelectCheckBox = function () {
        this.quickCheck.visible = false;
        this.checkMask.visible = false;
        this.checkBoxIcon.visible = true;
        localStorage.setItem('isChecked', JSON.stringify(false));
    };
}
