import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class PlayerMuckCards extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.PLAYER_MUCK_CARDS;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.PLAYER_MUCK_CARDS;
    }
    next(gameTable: GameTable): void {
        const pMap: any = new Map();
        const player = gameTable.getPlayer(this.seat);
        player.cards.forEach((key, index) => {
            const pindex: any = index;
            const cardShow: any = key.show;
            pMap.set(pindex, cardShow);
        });
        gameTable.cardMap.set(this.seat, pMap);
        player.playerStatus = this.getPlayerStatus();
        player.showHoleCards();
        if (!gameTable.onInit) {
            player.highlightEvent();
        }
        player.isPlayerCardsOpened = true;
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
        if (player.isPlayerRequesting) {
            player.showHoleCards();
            player.isPlayerCardsOpened = true;
        } else {
            gameTable.cardMap.get(this.seat).forEach((mapValue: any, mapKey: any) => {
                player.hideSevenCardStudCards(mapKey, mapValue);
            });
            player.isPlayerCardsOpened = false;
        }
    }
}
