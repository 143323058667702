import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ClientConfigProductName, ContentService, LoadingIndicatorService, PageMatrixService, PlainLinkComponent } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { Subject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @whatItDoes Loads public page content based on url path.
 *
 * @howToUse
 *
 * ```
 * export const routes: Routes = [
 * // ...
 * {
 *     path: '{culture}',
 *     children: [
 *         // ...
 *         {
 *             path: 'p',
 *             children: [
 *                 {
 *                     path: '**',
 *                     component: PublicPageLoaderComponent,
 *                     data: {
 *                         publicPageRoot: 'Playground-v1.0/PublicPages/'
 *                     }
 *                 }
 *             ]
 *         },
 *         // ...
 *     ]
 * }
 * // ...
 * ]
 * ```
 *
 * @stable
 */
@Component({
    selector: 'pk-public-page-wrapper',
    templateUrl: './public-page-wrapper.component.html',
    standalone: true,
    imports: [CommonModule, PageMatrixComponent, DslPipe, PlainLinkComponent],
})
export class PublicPageWrapperComponent implements OnInit, OnDestroy {
    content: any;
    isPagematrixReady: boolean;
    pageMatrixServiceSub: Subscription;
    showConfigureNavigation: boolean = false;
    private unsubscribe = new Subject<void>();
    @Input() info: any;

    scriptName: string = '';

    constructor(
        private contentService: ContentService,
        private loadingIndicatorService: LoadingIndicatorService,
        //private pageViewDataService: PageViewDataService,
        //private activatedRoute: ActivatedRoute,
        private pageMatrixService: PageMatrixService,
    ) {}

    ngOnInit() {
        const loadingIndicator = this.loadingIndicatorService.start();
        this.contentService
            .getJson(this.info.sitecorePath + '/', { product: ClientConfigProductName.POKER, filterOnClient: true })
            .pipe(
                catchError(() => {
                    return this.contentService.getJson('App-v1.0/partials/notfound', { product: ClientConfigProductName.SF, filterOnClient: true });
                }),
            )
            .subscribe((data) => {
                this.content = data;
                loadingIndicator.done();
                // if (isNotFound) {
                //     this.pageViewDataService.setDataForNavigation(this.activatedRoute.snapshot, {
                //         'page.name': 'Errorpage - Not Found'
                //     });
                // } else {
                //     this.pageViewDataService.setDataForNavigation(this.activatedRoute.snapshot, {});
                // }
                this.loadScripts(data);
            });
        this.pageMatrixServiceSub = this.pageMatrixService.whenReady.subscribe(() => {
            this.isPagematrixReady = true;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unloadScript();
    }

    public loadScripts(content: any) {
        if (content !== null) {
            if (content.parameters && content.parameters['script-path']) {
                const cmsPathForScript = content.parameters['script-path'];
                this.scriptName = cmsPathForScript.split('/')[cmsPathForScript.split('/').length - 1];

                this.contentService
                    .getJson(cmsPathForScript, { filterOnClient: true })
                    //.pipe(catchError(this.handleError))
                    .subscribe((data) => {
                        //console.log(data);
                        //console.log(data.name);
                        if (data.name.toUpperCase() == this.scriptName.toUpperCase()) {
                            const node = document.createElement('script');
                            node.text = data.text;
                            node.id = data.name;
                            node.type = 'text/javascript';
                            node.async = true;
                            // node.charset = 'utf-8';
                            document.head.appendChild(node);
                        }
                    });
            }
        }
    }

    public unloadScript() {
        const elem = document.getElementById(this.scriptName);
        if (elem) {
            elem.parentNode?.removeChild(elem);
        }
    }
}
