import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { HeaderService } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { PokerPromotionsResourceService } from './poker-promotions-resource.service';

@Injectable({ providedIn: 'root' })
export class TournamentsResolver implements Resolve<any> {
    constructor(
        private resource: PokerPromotionsResourceService,
        private headerService: HeaderService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('poker');
        });
        if (document.getElementsByTagName('html')[0].classList.contains('product-poker')) {
            document.getElementsByTagName('html')[0].classList.add('product-poker');
        }
        let folderId = route.paramMap.get('folderId'); //  route.params.folderId;
        if (folderId == null) {
            folderId = 'overview';
        }
        const parentId = 'Tournaments';
        return this.resource.getInitValues(parentId, folderId);
    }
}
