<div class="homepage-section" *ngIf="casinosectionContentEnabled">
    <div class="col lobby-width">
        <div class="row flex-column-reverse flex-md-row">
            <div class="col-md-5 pc-txt decription-section">
                <div class="decription-section-inner">
                    <h2 class="heading-text">{{ casinosectionContentContent.title }}</h2>
                    <div class="home-section-desc" [innerHtml]="casinosectionContentContent.text"></div>
                    <div
                        class="homepage-button"
                        *ngIf="casinosectionContentContent.optionalText"
                        [innerHtml]="casinosectionContentContent.optionalText"></div>
                    <div class="homepage-button" *ngIf="casinosectionContentContent.titleLink.linkText">
                        <a
                            class="ml-4"
                            [href]="casinosectionContentContent.titleLink.url"
                            [target]="casinosectionContentContent.titleLink.attributes.target || '_self'"
                            >{{ casinosectionContentContent.titleLink.linkText }}</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center">
                <img *ngIf="casinosectionContentContent.image" [src]="casinosectionContentContent.image.src" alt="Casino Section" class="img-fluid" />
            </div>
        </div>
    </div>
</div>
