import { Point } from './point';

export class Dealer {
    private _playerName: string;
    private _seatNo: number;
    private _position: Point;

    constructor(playerName: string, seatNo: number, position: Point) {
        this._playerName = playerName;
        this._seatNo = seatNo;
        this._position = position;
    }

    get playerName(): string {
        return this._playerName;
    }

    set playerName(value: string) {
        this._playerName = value;
    }

    get seatNo(): number {
        return this._seatNo;
    }

    set seatNo(value: number) {
        this._seatNo = value;
    }

    get position(): Point {
        return this._position;
    }

    set position(value: Point) {
        this._position = value;
    }
}
