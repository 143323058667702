import { Routes } from '@angular/router';

import { PokerLaNotteDegliAssiSynopsisResolver } from './poker-la-notte-degli-assi-synopsis.resolver';
import { PokerLaNotteDegliAssiResolver } from './poker-la-notte-degli-assi.resolver';

export const pokerLaNotteDegliAssiRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerLaNotteDegliAssiResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerLaNotteDegliAssiSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerLaNotteDegliAssiResolver,
        },
    },
];
