import { Message } from '../../message';

export class ResponseMTCTTableInfoForParticipant extends Message {
    private MESSAGE_NAME: string = 'ResponseMTCTTableInfoForParticipant';
    private mtctId: number = 0;
    private tableSeqNumber: number = 0;
    private tableId: number = 0;
    private serverPeerId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, tableSeqNumber: number = 0, tableId: number = 0, serverPeerId: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tableSeqNumber = tableSeqNumber;
        this.tableId = tableId;
        this.serverPeerId = serverPeerId;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTableSeqNumber(): number {
        return this.tableSeqNumber;
    }

    setTableSeqNumber(tableSeqNumber: number) {
        this.tableSeqNumber = tableSeqNumber;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
}
