import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PageMatrixComponent } from '@frontend/vanilla/features/content';

import { PokerHomePageClientConfig } from './client-config.models';
import { NavigationBarPokerComponent } from './navigation-bar-poker.component';
import { NavigationBarComponent } from './navigation-bar.component';

@Component({
    selector: 'pk-page-not-found',
    templateUrl: 'page-not-found.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, NavigationBarComponent, NavigationBarPokerComponent, PageMatrixComponent],
})
export class PageNotFoundComponent implements OnInit {
    showConfigureNavigation: boolean = false;

    constructor(private pokerHomePageClientConfig: PokerHomePageClientConfig) {}

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
    }
}
