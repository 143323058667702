import { Message } from '../../message';

export class DeletePrivateTableRequest extends Message {
    private MESSAGE_NAME: string = 'DeletePrivateTableRequest';
    private tableId: number = 0;
    private challengeId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, challengeId: number = 0) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.challengeId = challengeId;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
}
