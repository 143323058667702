import { Message } from '../../message';

export class STTPlayerSearchResultTable extends Message {
    private MESSAGE_NAME: string = 'STTPlayerSearchResultTable';
    private tableId: number = 0;
    private tableTypeId: number = 0;
    private tableName: any | null = null;
    private maxNoOfSeats: number = 0;
    private gameTypeId: number = 0;
    private playerCount: number = 0;
    private tableLimitType: number = 0;
    private gameCurrency: string | null = null;
    private groupId: number = 0;
    private tableCategory: number = 0;
    private tourneyBuyIn: number = 0;
    private tourneyEntryFee: number = 0;
    private fppBuyIn: number = 0;
    private tourneyGameStatus: number = 0;
    private maxBuyInAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        tableTypeId: number = 0,
        tableName: any | null = null,
        maxNoOfSeats: number = 0,
        gameTypeId: number = 0,
        playerCount: number = 0,
        tableLimitType: number = 0,
        gameCurrency: string | null = null,
        groupId: number = 0,
        tableCategory: number = 0,
        tourneyBuyIn: number = 0,
        tourneyEntryFee: number = 0,
        fppBuyIn: number = 0,
        tourneyGameStatus: number = 0,
        maxBuyInAmount: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.tableTypeId = tableTypeId;
        this.tableName = tableName;
        this.maxNoOfSeats = maxNoOfSeats;
        this.gameTypeId = gameTypeId;
        this.playerCount = playerCount;
        this.tableLimitType = tableLimitType;
        this.gameCurrency = gameCurrency;
        this.groupId = groupId;
        this.tableCategory = tableCategory;
        this.tourneyBuyIn = tourneyBuyIn;
        this.tourneyEntryFee = tourneyEntryFee;
        this.fppBuyIn = fppBuyIn;
        this.tourneyGameStatus = tourneyGameStatus;
        this.maxBuyInAmount = maxBuyInAmount;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTableTypeId(): number {
        return this.tableTypeId;
    }

    setTableTypeId(tableTypeId: number) {
        this.tableTypeId = tableTypeId;
    }
    getTableName(): any | null {
        return this.tableName;
    }

    setTableName(tableName: any | null) {
        this.tableName = tableName;
    }
    getMaxNoOfSeats(): number {
        return this.maxNoOfSeats;
    }

    setMaxNoOfSeats(maxNoOfSeats: number) {
        this.maxNoOfSeats = maxNoOfSeats;
    }
    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getTableLimitType(): number {
        return this.tableLimitType;
    }

    setTableLimitType(tableLimitType: number) {
        this.tableLimitType = tableLimitType;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getGroupId(): number {
        return this.groupId;
    }

    setGroupId(groupId: number) {
        this.groupId = groupId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getTourneyBuyIn(): number {
        return this.tourneyBuyIn;
    }

    setTourneyBuyIn(tourneyBuyIn: number) {
        this.tourneyBuyIn = tourneyBuyIn;
    }
    getTourneyEntryFee(): number {
        return this.tourneyEntryFee;
    }

    setTourneyEntryFee(tourneyEntryFee: number) {
        this.tourneyEntryFee = tourneyEntryFee;
    }
    getFppBuyIn(): number {
        return this.fppBuyIn;
    }

    setFppBuyIn(fppBuyIn: number) {
        this.fppBuyIn = fppBuyIn;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
    getMaxBuyInAmount(): number {
        return this.maxBuyInAmount;
    }

    setMaxBuyInAmount(maxBuyInAmount: number) {
        this.maxBuyInAmount = maxBuyInAmount;
    }
}
