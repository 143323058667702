<div class="spin_popup_page">
    <div class="parent">
        <div class="spin_parent">
            <div class="spin_content">
                <div class="spin-header">{{ buyinvalue }} {{ 'PARTY_POKER_GC_SNGJP_FILTER_SNGJACKPOT' | translate }}</div>
                <span class="cross theme-ex" (click)="onClose()"> </span>
            </div>
        </div>
        <div class="spin-content-wrapper">
            <div class="available-balance-txt">
                <div class="avail-bal-subtxt">
                    {{ 'PARTY_POKER_GC_ONECLICK_AVAILABLEBALANCE' | translate }}
                </div>
                <span class="avail-bal-txt-position"> {{ userBalance | formatCurrencyPipe: 'USD' }}</span>
                <span class="theme-info-i" (click)="openBalanceInfoPopup()"></span>
                <div class="spins-buyin-container">
                    <pwc-app-p-dollar-info-pop-up
                        class="p-dollar-container"
                        *ngIf="isBalanceInfoEnabled === true"
                        (closeBalanceInfo)="closeBalanceInfoPopup()"></pwc-app-p-dollar-info-pop-up>
                </div>
            </div>
            <div class="no-of-games-btn">
                <span class="no-of-games-txt">
                    {{ 'PARTY_POKER_MOBILE_EXPOSPINS_NUM_OF_GAMES' | translate }}
                </span>
                <div class="min-plus-txt">
                    <div class="operate-buttons" (click)="updateGames('sub')">
                        <span class="icon theme-minus"></span>
                    </div>
                    <div class="minus-txt">
                        <div class="minus-txt-color">{{ noOfGames }}</div>
                    </div>
                    <div class="operate-buttons" (click)="updateGames('add')">
                        <span class="icon theme-plus"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="cashAlone" class="available-ticket-txtvalue">
                <div class="pr">
                    <span class="availticket-value">{{ availableTicket }}</span>
                    <span class="ticketsaval-txt">{{ 'PARTY_POKER_GC_SNGJP_TICKETSAVAILABLE' | translate }}</span>
                </div>
                <div class="minplu-txt">
                    <div class="operate-buttons" [ngClass]="!cashTicket ? 'minus-btnn' : 'minus-btn'" (click)="updateTickets('minus')">
                        <span class="icon theme-minus"></span>
                    </div>
                    <div class="minus-txt">
                        <div class="minus-txt-color">{{ noOfTicket }}</div>
                    </div>
                    <div class="operate-buttons" [ngClass]="!cashTicket ? 'plus-btn' : 'plus'" (click)="updateTickets('plus')">
                        <span class="icon theme-plus"></span>
                    </div>
                </div>
            </div>
            <div class="gamebuyin-value">
                <div class="gamebuyin-color">
                    <span class="buyin">{{ 'PARTY_POKER_GC_SNGJP_GAMEBUYIN' | translate }}</span>
                </div>
                <div class="parentbuy-in">
                    <div class="buyin-text">{{ noOfGames }}{{ ' x ' }}{{ buyinvalue }}{{ includingMsg }}</div>
                    <span class="buyin-value">{{ totalBuyinValue }}</span>
                </div>
            </div>
            <div *ngIf="!(cashAlone && cashTicket)">
                <div *ngIf="leastTicketsAlert" class="ticketsalert">
                    <span>{{ 'PARTY_POKER_MOBILE_EXPOSPINS_NO_ENOUGH_TICKETS' | translate }}</span>
                </div>
            </div>

            <div class="low-balance-alert" *ngIf="alert">
                <span style="color: orangered">
                    {{ 'PARTY_POKER_MOBILE_EXPOSPINS_NO_ENOUGH_FUNDS' | translate }}
                </span>
            </div>
            <div class="button-wrapper d-flex">
                <button *ngIf="!alert" [disabled]="leastTicketsAlert && !cashTicket" class="btn btn-md btn-primary w-100" (click)="loadgame()">
                    {{ 'PARTY_POKER_GC_REGISTER' | translate }}
                </button>
                <button *ngIf="alert" class="btn btn-md btn-primary w-100" (click)="openCashierPage()">
                    {{ 'PARTY_POKER_MOBILE_EXPOSPINS_DEPOSIT' | translate }}
                </button>
            </div>

            <div class="value-in">
                <span class="value-align">{{ usdToGbpVal }}</span>
            </div>
        </div>
    </div>
</div>
