import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { CashoutEquityView } from './CashoutEquityView';

export class CashoutEquityParentView extends PIXI.Container {
    gameService: any;
    EventDispatcher: any;
    peerId: any;
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor(gs, EventDispatcher, peerId) {
        super();
        this.gameService = gs;
        this.EventDispatcher = EventDispatcher;
        this.peerId = peerId;
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }

    createView = function (vm) {
        this.viewModel = vm;
        this.maxPlayerCount = this.gameService.getMaxNoOfSeats();
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + this.maxPlayerCount + 'MaxPos'];
        if (this.maxPlayerCount == undefined || this.maxPlayerCount == null) this.maxPlayerCount = 3;
        this.cashEquityPlayersArray = [];
        this.cashEquityPlayerContainer = this.displayUtil.getContainer();
        this.addChild(this.cashEquityPlayerContainer);
        for (let i = 0; i < this.maxPlayerCount; i++) {
            const cashEquityPlayer = new CashoutEquityView(this.EventDispatcher, this.gameService);
            cashEquityPlayer.createView(i, this.maxPlayerCount);
            this.cashEquityPlayersArray.push(cashEquityPlayer);
            this.cashEquityPlayerContainer.addChild(cashEquityPlayer);
        }
        this.betArray = [[], [], []];
    };
    removeAnimations = function () {
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            this.cashEquityPlayersArray[i].removeAnimations();
        }
    };
    cashOutAnim = function (seatNo) {
        this.cashEquityPlayersArray[seatNo].showCashOutAnim(seatNo);
    };
    onCashOutDisabled = function () {
        this.cashEquityPlayersArray[uiConfig.selfUserClientPosInd].onCashOutDisabled();
    };
    removeCashoutDisabled = function () {
        this.cashEquityPlayersArray[uiConfig.selfUserClientPosInd].removeCashoutDisabled();
    };
    showCashoutWaitMsg = function () {
        this.cashEquityPlayersArray[uiConfig.selfUserClientPosInd].showCashoutWaitMsg();
    };
}
