import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { DeviceService, Page, TrackingService } from '@frontend/vanilla/core';
import moment from 'moment';

import { SimulatedService } from '../simulated-hands.service';

@Component({
    selector: 'pk-get-started',
    templateUrl: 'get-started.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class GetStartedComponent implements OnInit {
    @Output() passNickname = new EventEmitter<{ nickname: string; getStartedModal: boolean }>();
    @Output() backToCheck = new EventEmitter<{ getStartedModal: boolean; getStartedCheckModal: boolean }>();
    @Input() parameters: any;
    @Input() commonParams: any;
    homePage: any;
    nickname: string;
    showWiz: boolean = false;
    public nativeApp: boolean = false;
    registerForm: FormGroup;
    validateDateFormat: any;
    ageUnder18: boolean = false;
    dobFormGroup: FormGroup;
    isDobValid: boolean = true;
    constructor(
        private vnPage: Page,
        private deviceService: DeviceService,
        public serve: SimulatedService,
        private fb: FormBuilder,
        private trackingService: TrackingService,
    ) {}
    ngOnInit(): void {
        this.showWiz = true;
        this.homePage = this.vnPage.homePage;
        this.nativeApp = this.serve.nativeApp;
        this.registerForm = this.fb.group({
            nickname: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(15), Validators.pattern(/[\S]/)]),
            dob: new FormGroup({
                day: new FormControl('', [Validators.required, Validators.maxLength(2)]),
                month: new FormControl('', [Validators.required, Validators.maxLength(2)]),
                year: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
            }),
        });

        this.dobFormGroup = this.registerForm.get('dob') as FormGroup;

        //this.getUserInfo();
        this.ageVerificationCheck();
    }

    get registerFormControl() {
        return this.registerForm.controls;
    }
    get dobFormControl() {
        return this.dobFormGroup.controls;
    }

    getUserInfo() {
        const userInfoString = localStorage.getItem('userInfo');
        if (userInfoString != null || userInfoString != undefined) {
            const userInfiStored = JSON.parse(userInfoString);
            this.registerForm.setValue(userInfiStored);
            // this.registerForm.controls['nickname'].setValue(userInfiStored.nickname, { emitEvent: true });
            this.registerForm.disable();
            this.ageUnder18 = true;
        }
    }

    removeUserInfo() {
        if (localStorage.getItem('userInfo') !== null) {
            localStorage.removeItem('userInfo');
        }
    }

    ageVerificationCheck() {
        this.registerForm.valueChanges.subscribe((values: any) => {
            this.isDobValid = true;
            if (this.registerForm.valid) {
                const dobDay = values.dob.day,
                    dobMonth = values.dob.month,
                    dobYear = values.dob.year;
                const dateOfBirth = `${this.padStart(dobDay)}-${this.padStart(dobMonth)}-${dobYear}`;
                const dateOfBirthfor18 = new Date(dobYear, dobMonth - 1, dobDay); // Month is 0-based in JavaScript Date object

                this.validateDateFormat = moment(dateOfBirth, 'DD-MM-YYYY', true);
                if (this.validateDateFormat.isValid()) {
                    this.isDobValid = true;
                    // Parsing the date of birth into a Moment object
                    //const dob = moment(dateOfBirth);

                    // Calculate the difference between the date of birth and the current date
                    // const ageDiff = moment().diff(dob, 'years');
                    const ageDiff = this.calculateAge(dateOfBirthfor18);
                    if (ageDiff > 100 || ageDiff < 0) {
                        this.isDobValid = false;
                    }
                    // Check if the age difference is less than 18
                    if (ageDiff < 18 && this.isDobValid == true) {
                        this.ageUnder18 = true;
                        //console.log('Date of birth is under 18 years old');
                    } else {
                        this.ageUnder18 = false;
                        //console.log('Date of birth is 18 years old or older');
                    }
                    //console.log('registerFormControl.dob ', this.registerFormControl.dob);
                } else {
                    this.isDobValid = false;
                    this.ageUnder18 = false;
                }
            } else {
                this.ageUnder18 = false;
            }
        });
    }

    // Calculate the age difference in years
    calculateAge(dateOfBirth: Date): number {
        const today = new Date();
        let age = today.getFullYear() - dateOfBirth.getFullYear();
        const monthDiff = today.getMonth() - dateOfBirth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
            age--;
        }
        return age;
    }

    padStart(numer: any) {
        return String(numer).padStart(2, '0');
    }

    onSubmit() {
        if (this.registerForm.valid && !this.ageUnder18) {
            this.passNickname.emit({ nickname: this.registerForm.value.nickname, getStartedModal: false });
            //this.removeUserInfo();
        } else {
            alert('Sorry, you must be over 18 to play this tutorial');
            //window.open(this.homePage, "_self");
            //this.storeUserInfo();
        }
    }

    storeUserInfo() {
        const userInfo: any = {
            nickname: this.registerForm.value.nickname || '',
            dob: {
                day: this.registerForm.value.dob.day,
                month: this.registerForm.value.dob.month,
                year: this.registerForm.value.dob.year,
            },
        };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }

    getStarted() {
        this.passNickname.emit({ nickname: this.nickname, getStartedModal: false });
    }

    backTo() {
        this.backToCheck.emit({ getStartedModal: false, getStartedCheckModal: true });
    }

    ngAfterViewChecked() {
        const width = window.innerWidth;
        if (this.deviceService.isMobile) {
            const ele: any = document.getElementById('getStarted');
            // let elenew: any = document.getElementById('Player-id');
            let zoomValue = width / 400;
            if (zoomValue > 1.4) {
                zoomValue = 1.4;
            }
            if (ele) {
                ele.style.zoom = zoomValue;
                //elenew.style.zoom = zoomValue;
            }
        }
    }

    moveFocus(currentInput: HTMLInputElement, nextInput: HTMLInputElement) {
        const maxlengthStr = currentInput.getAttribute('maxlength');
        const maxLength = maxlengthStr ? parseInt(maxlengthStr, 10) : 0; // Default to 0
        //const maxLength = parseInt(currentInput.getAttribute('maxlength'), 10);

        if (currentInput.value.length >= maxLength) {
            nextInput.focus();
        }
    }

    // GTM tracking started
    trackingDownloadEvent(url: any, downloadText: string) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //Note:If hands are not available then give value as not applicable',
            'component.LocationEvent': 'get started check screen',
            'component.EventDetails': downloadText + ' link', //'{cta name} cta ex: yes cta, try again cta etc',
            'component.URLClicked': url,
        });
    }

    trackingSubmitFormEvent(ctaText: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //Note:If hands are not available then give value as not applicable',
            'component.LocationEvent': 'get started screen',
            'component.EventDetails': ctaText + ' cta', //'{cta name} cta ex: yes cta, try again cta etc',
            'component.URLClicked': 'not applicable',
        });
    }
    // GTM tracking ends
}
