import { Message } from '../../message';

export class SidePot extends Message {
    private MESSAGE_NAME: string = 'SidePot';
    private potNo: number = 0;
    private amount: number = 0;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, potNo: number = 0, amount: number = 0, seatNo: number = 0) {
        super(reqServerPeerId);
        this.potNo = potNo;
        this.amount = amount;
        this.seatNo = seatNo;
    }

    getPotNo(): number {
        return this.potNo;
    }

    setPotNo(potNo: number) {
        this.potNo = potNo;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
