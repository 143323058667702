import { Message } from '../../message';

export class Ante extends Message {
    private MESSAGE_NAME: string = 'Ante';
    private anteType: number = 0;
    private amount: number = 0;
    private accepted: boolean | null = false;
    private seatNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, anteType: number = 0, amount: number = 0, accepted: boolean | null = false, seatNo: number = 0) {
        super(reqServerPeerId);
        this.anteType = anteType;
        this.amount = amount;
        this.accepted = accepted;
        this.seatNo = seatNo;
    }

    getAnteType(): number {
        return this.anteType;
    }

    setAnteType(anteType: number) {
        this.anteType = anteType;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
    getAccepted(): boolean | null {
        return this.accepted;
    }

    setAccepted(accepted: boolean | null) {
        this.accepted = accepted;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
}
