import { Message } from '../../message';
import { CurrencyAmount } from '../../pgSharedMessageFactory/messages/CurrencyAmount';

export class ChallengeTemplate extends Message {
    private MESSAGE_NAME: string = 'ChallengeTemplate';
    private gameTypeId: number = 0;
    private tableInfoId: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;
    private speed: number = 0;
    private caStakesOrBlindsLowerLimit: CurrencyAmount | null = null;
    private caStakesOrBlindsHigherLimit: CurrencyAmount | null = null;
    private caBuyIn: CurrencyAmount | null = null;
    private caFee: CurrencyAmount | null = null;
    private maxBuyin: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        gameTypeId: number = 0,
        tableInfoId: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
        speed: number = 0,
        caStakesOrBlindsLowerLimit: CurrencyAmount | null = null,
        caStakesOrBlindsHigherLimit: CurrencyAmount | null = null,
        caBuyIn: CurrencyAmount | null = null,
        caFee: CurrencyAmount | null = null,
        maxBuyin: number = 0,
    ) {
        super(reqServerPeerId);
        this.gameTypeId = gameTypeId;
        this.tableInfoId = tableInfoId;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
        this.speed = speed;
        this.caStakesOrBlindsLowerLimit = caStakesOrBlindsLowerLimit;
        this.caStakesOrBlindsHigherLimit = caStakesOrBlindsHigherLimit;
        this.caBuyIn = caBuyIn;
        this.caFee = caFee;
        this.maxBuyin = maxBuyin;
    }

    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getSpeed(): number {
        return this.speed;
    }

    setSpeed(speed: number) {
        this.speed = speed;
    }
    getCaStakesOrBlindsLowerLimit(): CurrencyAmount | null {
        return this.caStakesOrBlindsLowerLimit;
    }

    setCaStakesOrBlindsLowerLimit(caStakesOrBlindsLowerLimit: CurrencyAmount | null) {
        this.caStakesOrBlindsLowerLimit = caStakesOrBlindsLowerLimit;
    }
    getCaStakesOrBlindsHigherLimit(): CurrencyAmount | null {
        return this.caStakesOrBlindsHigherLimit;
    }

    setCaStakesOrBlindsHigherLimit(caStakesOrBlindsHigherLimit: CurrencyAmount | null) {
        this.caStakesOrBlindsHigherLimit = caStakesOrBlindsHigherLimit;
    }
    getCaBuyIn(): CurrencyAmount | null {
        return this.caBuyIn;
    }

    setCaBuyIn(caBuyIn: CurrencyAmount | null) {
        this.caBuyIn = caBuyIn;
    }
    getCaFee(): CurrencyAmount | null {
        return this.caFee;
    }

    setCaFee(caFee: CurrencyAmount | null) {
        this.caFee = caFee;
    }
    getMaxBuyin(): number {
        return this.maxBuyin;
    }

    setMaxBuyin(maxBuyin: number) {
        this.maxBuyin = maxBuyin;
    }
}
