import { GameTable } from '../models/game-table';
import { Player } from '../models/player';
import { Point } from '../models/point';

// @dynamic
export class Properties {
    static gameTable: GameTable;
    static player: Player;
    static propFile: any;
    static gameType: any;
    static messages: any;
    static maximumNumberofSeats: any;
    static pointMap = new Map<any, Point>();
    static seatPos: any;
    static seatMap: any = new Map<number, number>();
    static isReplayerV3: boolean = false;

    static DEAL_CARD_POSITIONS = [
        new Point(498, 126),
        new Point(583, 150),
        new Point(639, 212),
        new Point(578, 295),
        new Point(494, 306),
        new Point(337, 306),
        new Point(198, 295),
        new Point(133, 212),
        new Point(194, 150),
        new Point(292, 126),
    ];

    static SUITS = ['S', 'H', 'C', 'D'];

    static SUITE = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'K', 'Q', 'J', 'A'];
    static MOBILESUITE = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
    static HOLE_CARDS_CARD_OVERLAY = 23;
    static COMMUNITY_CARD_SEQUENCE = 10.5;
    static isMobile: any;
    static requestingPlayerseatNumber: any;
    static setRequestingPlayerSeatNumberTo: any;
    // static isReplayerThree: boolean = true;

    static getDealCardPosition(seat: number) {
        return Properties.DEAL_CARD_POSITIONS[seat];
    }

    static getCardSprite(suit: string, card: string, show: boolean): string {
        if (!show) {
            if (this.isMobile) {
                return `-${4280}px 0px`;
            } else if (this.isReplayerV3) {
                return `-${4890}px 0px`;
            } else {
                return `-${4957}px 0px`;
            }
        } else {
            if (this.isMobile) {
                return `-${Properties.SUITS.indexOf(suit) * 1066 + Properties.MOBILESUITE.indexOf(card) * 82}px 0px`;
            } else if (this.isReplayerV3) {
                return `-${Properties.SUITS.indexOf(suit) * 808 + Properties.SUITE.indexOf(card) * 62}px 0px`;
            } else {
                return `-${Properties.SUITS.indexOf(suit) * 767 + Properties.SUITE.indexOf(card) * 59}px 0px`;
            }
        }
    }

    static getHoleCardsSequence(index: number) {
        if (Properties.gameType == '7CARDSTUD') {
            const x = index * Properties.HOLE_CARDS_CARD_OVERLAY;
            let point!: Point;
            switch (index) {
                case 0:
                case 1: {
                    point = new Point(x, 6);
                    break;
                }
                case 2:
                case 3:
                case 4:
                case 5: {
                    point = new Point(x, -2);
                    break;
                }

                case 6: {
                    point = new Point(x, 6);
                    break;
                }
            }
            Properties.pointMap.set(index, point);
            return Properties.pointMap;
        } else {
            let point: Point;
            const x = index * Properties.HOLE_CARDS_CARD_OVERLAY;
            point = new Point(x, 0);
            Properties.pointMap.set(index, point);
            return Properties.pointMap;
        }
    }

    static getPlayerPotPosition(seatNo: any): any {
        const chipPos = Properties.propFile[Properties.gameType][`CHIPPOS${seatNo}`].split('#');
        const potPos = new Point(chipPos[0], chipPos[1]);
        return potPos;
    }

    static getCommunityCardsSequence(index: number) {
        if (!this.isMobile) {
            Properties.COMMUNITY_CARD_SEQUENCE = 7.5;
        } else if (this.isMobile && window.innerWidth >= 641 && window.innerWidth <= 1024) {
            Properties.COMMUNITY_CARD_SEQUENCE = 9;
        }
        const values = `${index * Properties.COMMUNITY_CARD_SEQUENCE}px`;
        return values;
    }

    static avatarPos(seat: any) {
        let nseatPos: any = 1;
        switch (Properties.maximumNumberofSeats) {
            case 'MAX_SEATS_FOR_SIX_MAX_TABLE': {
                switch (seat) {
                    case 0: {
                        nseatPos = 3;
                        break;
                    }
                    case 1: {
                        nseatPos = 1;
                        break;
                    }
                    case 2: {
                        nseatPos = 1;
                        break;
                    }
                    case 3: {
                        nseatPos = 1;
                        break;
                    }
                    case 4: {
                        nseatPos = 3;
                        break;
                    }
                    case 5: {
                        nseatPos = 3;
                        break;
                    }
                    default: {
                        nseatPos = 1;
                        break;
                    }
                }
                break;
            }
            case 'MAX_SEATS_FOR_SEVEN_MAX_TABLE': {
                switch (seat) {
                    case 0: {
                        nseatPos = 1;
                        break;
                    }
                    case 1: {
                        nseatPos = 1;
                        break;
                    }
                    case 2: {
                        nseatPos = 1;
                        break;
                    }
                    case 3: {
                        nseatPos = 1;
                        break;
                    }
                    case 4: {
                        nseatPos = 3;
                        break;
                    }
                    case 5: {
                        nseatPos = 3;
                        break;
                    }
                    case 6: {
                        nseatPos = 3;
                        break;
                    }
                    default: {
                        nseatPos = 1;
                        break;
                    }
                }
                break;
            }
            case 'MAX_SEATS_FOR_FIVE_MAX_TABLE':
                {
                    switch (seat) {
                        case 0: {
                            nseatPos = 1;
                            break;
                        }
                        case 1: {
                            nseatPos = 1;
                            break;
                        }
                        case 2: {
                            nseatPos = 1;
                            break;
                        }
                        case 3: {
                            nseatPos = 3;
                            break;
                        }
                        case 4: {
                            nseatPos = 3;
                            break;
                        }
                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
            case 'MAX_SEATS_FOR_FOUR_MAX_TABLE':
                {
                    switch (seat) {
                        case 0: {
                            nseatPos = 1;
                            break;
                        }
                        case 1: {
                            nseatPos = 1;
                            break;
                        }
                        case 2: {
                            nseatPos = 3;
                            break;
                        }
                        case 3: {
                            nseatPos = 3;
                            break;
                        }
                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
            case 'MAX_SEATS_FOR_THREE_MAX_TABLE':
                {
                    switch (seat) {
                        case 0: {
                            nseatPos = 1;
                            break;
                        }
                        case 1: {
                            nseatPos = 1;
                            break;
                        }
                        case 2: {
                            nseatPos = 3;
                            break;
                        }
                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
            case 'MAX_SEATS_FOR_HEADS_UP_TABLE':
                {
                    if (Properties.gameType == '7CARDHEADSUP') {
                        switch (seat) {
                            case 2: {
                                nseatPos = 3;
                                break;
                            }
                            case 5: {
                                nseatPos = 1;
                                break;
                            }
                            default: {
                                nseatPos = 1;
                                break;
                            }
                        }
                    } else {
                        switch (seat) {
                            case 0: {
                                nseatPos = 3;
                                break;
                            }
                            case 1: {
                                nseatPos = 1;
                                break;
                            }
                            default: {
                                nseatPos = 1;
                                break;
                            }
                        }
                    }
                }
                break;
            case 'MAX_SEATS_FOR_NINE_MAX_TABLE':
                {
                    switch (seat) {
                        case 0:
                        case 1:
                        case 3:
                        case 4: {
                            nseatPos = 1;
                            break;
                        }
                        case 2: {
                            nseatPos = 2;
                            break;
                        }
                        case 5:
                        case 7:
                        case 8: {
                            nseatPos = 3;
                            break;
                        }
                        case 6: {
                            nseatPos = 4;
                            break;
                        }

                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
            case 'MAX_SEATS_FOR_EIGHT_MAX_TABLE':
                {
                    switch (seat) {
                        case 0:
                        case 1:
                        case 2:
                        case 3: {
                            nseatPos = 1;
                            break;
                        }
                        case 4:
                        case 5:
                        case 6:
                        case 7: {
                            nseatPos = 3;
                            break;
                        }
                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
            case 'MAX_SEATS_FOR_TEN_MAX_TABLE':
                {
                    switch (seat) {
                        case 0:
                        case 1:
                        case 3:
                        case 4: {
                            nseatPos = 1;
                            break;
                        }
                        case 2: {
                            nseatPos = 2;
                            break;
                        }

                        case 5:
                        case 6:
                        case 8:
                        case 9: {
                            nseatPos = 3;
                            break;
                        }
                        case 7: {
                            nseatPos = 4;
                            break;
                        }
                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
            case 7:
                {
                    switch (seat) {
                        case 1:
                        case 3:
                        case 4: {
                            nseatPos = 1;
                            break;
                        }
                        case 2: {
                            nseatPos = 2;
                            break;
                        }
                        case 0:
                        case 5:
                        case 7: {
                            nseatPos = 3;
                            break;
                        }
                        case 6: {
                            nseatPos = 4;
                            break;
                        }
                        default: {
                            nseatPos = 1;
                            break;
                        }
                    }
                }
                break;
        }
        Properties.seatPos = nseatPos;
        return Properties.seatPos;
    }

    static rearrangePlayersArray(roundPlayers: any, numberOfSeats: any) {
        const array: any = [];
        roundPlayers.forEach((player: any) => {
            if (player.requestingPlayer) {
                Properties.requestingPlayerseatNumber = player.seat;
                array.unshift(player);
            } else {
                array.push(player);
            }
        });
        const prefferedSeatNumber: any = Properties.setRequestingPlayerSeatNumber(numberOfSeats);
        return Properties.changedPlayerArray(prefferedSeatNumber, Properties.requestingPlayerseatNumber, array, numberOfSeats);
    }

    static changedPlayerArray(prefferedSeatNumber: any, requestingPlayerSeatNumber: any, playersModifiedArray: any, numberOfSeats: any) {
        let OffsetPosition: any;
        if (prefferedSeatNumber === requestingPlayerSeatNumber) {
            if (Properties.seatMap.size === 0) {
                Properties.createSetObj(playersModifiedArray);
            }
            return playersModifiedArray;
        } else {
            Properties.seatMap.clear();
            OffsetPosition = prefferedSeatNumber - requestingPlayerSeatNumber;
            if (OffsetPosition < 0) {
                OffsetPosition = numberOfSeats + prefferedSeatNumber - requestingPlayerSeatNumber;
            }
        }
        playersModifiedArray.forEach((playerM: any) => {
            const actualSeat: any = playerM.seat;
            let newSeat: any;
            newSeat = playerM.seat + OffsetPosition;
            if (newSeat >= numberOfSeats) {
                newSeat = newSeat % numberOfSeats;
            }
            Properties.seatMap.set(actualSeat, newSeat);
        });
        return playersModifiedArray;
    }

    static createSetObj(playersModifiedArray: any) {
        Properties.seatMap.clear();
        playersModifiedArray.forEach((player: any) => {
            Properties.seatMap.set(player.seat, player.seat);
        });
    }

    static setRequestingPlayerSeatNumber(maxSeats: any) {
        switch (maxSeats) {
            case 3:
                Properties.setRequestingPlayerSeatNumberTo = 1;
                break;
            case 4:
                Properties.setRequestingPlayerSeatNumberTo = 2;
                break;
            case 5:
                Properties.setRequestingPlayerSeatNumberTo = 2;
                break;
            case 6:
                Properties.setRequestingPlayerSeatNumberTo = 3;
                break;
            case 7:
                Properties.setRequestingPlayerSeatNumberTo = 3;
                break;
            case 8:
                Properties.setRequestingPlayerSeatNumberTo = 4;
                break;
            case 9:
                Properties.setRequestingPlayerSeatNumberTo = 4;
                break;
            case 10:
                Properties.setRequestingPlayerSeatNumberTo = 4;
                break;
            case 2:
                Properties.setRequestingPlayerSeatNumberTo = 1;
                break;

            default:
                Properties.setRequestingPlayerSeatNumberTo = 1;
                break;
            // Properties.setRequestingPlayerSeatNumberTo = Math.round(maxSeats / 2);
        }
        return Properties.setRequestingPlayerSeatNumberTo;
    }
}
