import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toCurrencyPipe', standalone: true })
export class toCurrencyPipe implements PipeTransform {
    transform(value: string, currencyRePosition: string) {
        const symbol = value.replace(/[.+0-9\s]/g, '');
        const numbers = value.replace(/^\D+/g, '');
        if (currencyRePosition == 'true' && symbol == '€') {
            return Number(numbers).toLocaleString() + ' ' + symbol;
        }
        return symbol + Number(numbers).toLocaleString();
    }
}
