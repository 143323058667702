import { EventDispatcherGlobal } from '../../../service/event-despacher-global';

export class TableSettingsDataModel {
    private eventDispatcherGlobal = new EventDispatcherGlobal();

    /// To storage for pre / post flop data. ///
    public preFlopdataObj: any = {};
    public postFlopDataObj: any = {};
    /// To Store setting data ///
    public gameSettingsDataObj: any = {};
    /// To Store the Filoters data ///
    public filterSettingDataObj: any = [];
    //private appFacade:AppFacade = new AppFacade();
    constructor() {
        this.eventDispatcherGlobal.addEventListener('gameEventListener', this.updateSettingsData.bind(this));
    }

    updateSettingsData(message) {
        if (message.detail.className === 'storeGameSettingData') {
            this.storeGameSettingData(message.detail);
        } else if (message.detail.className === 'storePreflopSettingData') {
            this.storePreflopSettingData(message.detail);
        } else if (message.detail.className === 'storePostFlopSettingData') {
            this.storePostFlopSettingData(message.detail);
        }
    }

    storeGameSettingData(data) {
        this.gameSettingsDataObj = {
            cardStyle: {
                cardBackgroundStyle: data.data?.tableThemesCardBackground,
                cardViewStyle: data.data?.tableThemesCardStyle,
                tableFelt: data.data?.tableThemesTableFelt,
                tableStyle: data.data?.tableThemesTableStyle,
            },
            tableStyle: data.data?.tableStyle,
            autoMuck: data.data?.autoMuck,
            sound: data.data?.sound,
            vibrateWhenSilent: data.data?.vibrateWhenSilent,
            throwAnimations: data.data?.throwAnimations,
            rebuyToMax: data.data?.reBuyToMax,
            rabbitFeature: data.data?.rabbitFeature,
            raiseBarAutoConfirm: data.data?.raiseBarAutoConfirm,
        };

        const message = {
            className: data?.isFeltChange === 1 ? 'themeChange' : 'gameSettingData',
            cardStyle: {
                cardBackgroundStyle: data.data?.tableThemesCardBackground,
                cardViewStyle: data.data?.tableThemesCardStyle,
                tableFelt: data.data?.tableThemesTableFelt,
                tableStyle: data.data?.tableThemesTableStyle,
            },
            tableStyle: data.data?.tableStyle,
            autoMuck: data.data?.autoMuck,
            sound: data.data?.sound,
            vibrateWhenSilent: data.data?.vibrateWhenSilent,
            throwAnimations: data.data?.throwAnimations,
            rebuyToMax: data.data?.reBuyToMax,
            rabbitFeature: data.data?.rabbitFeature,
            raiseBarAutoConfirm: data.data?.raiseBarAutoConfirm,
        };
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    public storePreflopSettingData = function (data) {
        this.preFlopdataObj['Type'] = data.data.Type;
        this.preFlopdataObj['button0'] = data.data.button0;
        this.preFlopdataObj['button1'] = data.data.button1;
        this.preFlopdataObj['button2'] = data.data.button2;
        window.localStorage.setItem('PreFlopData', JSON.stringify(this.preFlopdataObj));
        //var iframeEl = document.getElementById("gameIframe");
        const message = {};
        message['className'] = 'PreFlopData';
        message['Type'] = data.data.Type;
        message['button0'] = data.data.button0;
        message['button1'] = data.data.button1;
        message['button2'] = data.data.button2;
        // if (this.appFacade) {
        //     this.appFacade.handleServerResponse(message);
        // }
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    public storePostFlopSettingData = function (data) {
        this.postFlopDataObj['Type'] = data.data.Type;
        this.postFlopDataObj['button0'] = data.data.button0;
        this.postFlopDataObj['button1'] = data.data.button1;
        this.postFlopDataObj['button2'] = data.data.button2;
        window.localStorage.setItem('PostFlopData', JSON.stringify(this.postFlopDataObj));
        //var iframeEl = document.getElementById("gameIframe");
        const message = {};
        message['className'] = 'PostFlopData';
        message['Type'] = data.data.Type;
        message['button0'] = data.data.button0;
        message['button1'] = data.data.button1;
        message['button2'] = data.data.button2;
        // if (this.appFacade) {
        //     this.appFacade.handleServerResponse(message);
        // }
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    public getPreFlopSettingData = function () {
        const data: string | null = window.localStorage.getItem('PreFlopData');
        if (data != null) {
            const preFlopData = JSON.parse(data);
            this.preFlopdataObj['Type'] = preFlopData.Type;
            this.preFlopdataObj['button0'] = preFlopData.button0;
            this.preFlopdataObj['button1'] = preFlopData.button1;
            this.preFlopdataObj['button2'] = preFlopData.button2;
            return this.preFlopdataObj;
        } else {
            this.preFlopdataObj['Type'] = 'preFlop';

            this.preFlopdataObj['button0'] = 2;
            this.preFlopdataObj['button1'] = 3;
            this.preFlopdataObj['button2'] = 'AllIN';
            window.localStorage.setItem('PreFlopData', JSON.stringify(this.preFlopdataObj));
            return this.preFlopdataObj;
        }
    };
    public getPostFlopSettingData = function () {
        const data: string | null = window.localStorage.getItem('PostFlopData');
        if (data != null) {
            const postFlopData = JSON.parse(data);
            this.postFlopDataObj['Type'] = postFlopData.Type;
            this.postFlopDataObj['button0'] = postFlopData.button0;
            this.postFlopDataObj['button1'] = postFlopData.button1;
            this.postFlopDataObj['button2'] = postFlopData.button2;
            return this.postFlopDataObj;
        } else {
            this.postFlopDataObj['Type'] = 'postFlop';
            this.postFlopDataObj['button0'] = 0.5;
            this.postFlopDataObj['button1'] = 0.75;
            this.postFlopDataObj['button2'] = 'AllIN';
            window.localStorage.setItem('PostFlopData', JSON.stringify(this.postFlopDataObj));
            return this.postFlopDataObj;
        }
    };
    public getGameSettingData = function () {
        const gameSettingData: string | null = window.localStorage.getItem('gameSettingData');
        if (gameSettingData != null) {
            const data = JSON.parse(gameSettingData);
            this.gameSettingsDataObj = {
                cardStyle: {
                    cardBackgroundStyle: data?.tableThemesCardBackground,
                    cardViewStyle: data?.tableThemesCardStyle,
                    tableFelt: data?.tableThemesTableFelt,
                    tableStyle: data?.tableThemesTableStyle,
                },
                tableStyle: data?.tableStyle,
                autoMuck: data?.autoMuck,
                sound: data?.sound,
                vibrateWhenSilent: data?.vibrateWhenSilent,
                throwAnimations: data?.throwAnimations,
                rebuyToMax: data?.reBuyToMax,
                rabbitFeature: data?.rabbitFeature,
                raiseBarAutoConfirm: data?.raiseBarAutoConfirm,
            };
        }
        return this.gameSettingsDataObj;
    };
}
