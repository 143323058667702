import { Message } from '../../message';
import { CurrencyAmount } from '../../pgSharedMessageFactory/messages/CurrencyAmount';
import { FXRateDetails } from '../../pgSharedMessageFactory/messages/FXRateDetails';

export class SNGJPTournamentRankInfo extends Message {
    private MESSAGE_NAME: string = 'SNGJPTournamentRankInfo';
    private sngJackpotID: number = 0;
    private instanceID: number = 0;
    private participantNo: number = 0;
    private rank: number = 0;
    private winAmount: CurrencyAmount | null = null;
    private replayAllowed: boolean | null = false;
    private accountBalance: number = 0;
    private ticketAvailable: boolean | null = false;
    private fxSnapshotID: number = 0;
    private fxDetails: FXRateDetails | null = null;
    private bountyAmount: CurrencyAmount | null = null;
    private ticketValue: CurrencyAmount | null = null;
    private tournamentTokens: CurrencyAmount | null = null;
    private ticketType: number = 0;
    private ticketOnly: boolean | null = false;
    private tdBalance: number = 0;
    private availableTickets: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        instanceID: number = 0,
        participantNo: number = 0,
        rank: number = 0,
        winAmount: CurrencyAmount | null = null,
        replayAllowed: boolean | null = false,
        accountBalance: number = 0,
        ticketAvailable: boolean | null = false,
        fxSnapshotID: number = 0,
        fxDetails: FXRateDetails | null = null,
        bountyAmount: CurrencyAmount | null = null,
        ticketValue: CurrencyAmount | null = null,
        tournamentTokens: CurrencyAmount | null = null,
        ticketType: number = 0,
        ticketOnly: boolean | null = false,
        tdBalance: number = 0,
        availableTickets: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.instanceID = instanceID;
        this.participantNo = participantNo;
        this.rank = rank;
        this.winAmount = winAmount;
        this.replayAllowed = replayAllowed;
        this.accountBalance = accountBalance;
        this.ticketAvailable = ticketAvailable;
        this.fxSnapshotID = fxSnapshotID;
        this.fxDetails = fxDetails;
        this.bountyAmount = bountyAmount;
        this.ticketValue = ticketValue;
        this.tournamentTokens = tournamentTokens;
        this.ticketType = ticketType;
        this.ticketOnly = ticketOnly;
        this.tdBalance = tdBalance;
        this.availableTickets = availableTickets;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getInstanceID(): number {
        return this.instanceID;
    }

    setInstanceID(instanceID: number) {
        this.instanceID = instanceID;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getWinAmount(): CurrencyAmount | null {
        return this.winAmount;
    }

    setWinAmount(winAmount: CurrencyAmount | null) {
        this.winAmount = winAmount;
    }
    getReplayAllowed(): boolean | null {
        return this.replayAllowed;
    }

    setReplayAllowed(replayAllowed: boolean | null) {
        this.replayAllowed = replayAllowed;
    }
    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getTicketAvailable(): boolean | null {
        return this.ticketAvailable;
    }

    setTicketAvailable(ticketAvailable: boolean | null) {
        this.ticketAvailable = ticketAvailable;
    }
    getFxSnapshotID(): number {
        return this.fxSnapshotID;
    }

    setFxSnapshotID(fxSnapshotID: number) {
        this.fxSnapshotID = fxSnapshotID;
    }
    getFxDetails(): FXRateDetails | null {
        return this.fxDetails;
    }

    setFxDetails(fxDetails: FXRateDetails | null) {
        this.fxDetails = fxDetails;
    }
    getBountyAmount(): CurrencyAmount | null {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: CurrencyAmount | null) {
        this.bountyAmount = bountyAmount;
    }
    getTicketValue(): CurrencyAmount | null {
        return this.ticketValue;
    }

    setTicketValue(ticketValue: CurrencyAmount | null) {
        this.ticketValue = ticketValue;
    }
    getTournamentTokens(): CurrencyAmount | null {
        return this.tournamentTokens;
    }

    setTournamentTokens(tournamentTokens: CurrencyAmount | null) {
        this.tournamentTokens = tournamentTokens;
    }
    getTicketType(): number {
        return this.ticketType;
    }

    setTicketType(ticketType: number) {
        this.ticketType = ticketType;
    }
    getTicketOnly(): boolean | null {
        return this.ticketOnly;
    }

    setTicketOnly(ticketOnly: boolean | null) {
        this.ticketOnly = ticketOnly;
    }
    getTdBalance(): number {
        return this.tdBalance;
    }

    setTdBalance(tdBalance: number) {
        this.tdBalance = tdBalance;
    }
    getAvailableTickets(): number {
        return this.availableTickets;
    }

    setAvailableTickets(availableTickets: number) {
        this.availableTickets = availableTickets;
    }
}
