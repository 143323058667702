import { Message } from '../../message';

export class WaitlistCriteriaType extends Message {
    private MESSAGE_NAME: string = 'WaitlistCriteriaType';
    private tableInfoid: number = 0;
    private gamePlayTypeAny: boolean | null = false;
    private dpAny: boolean | null = false;
    private minPlayers: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableInfoid: number = 0,
        gamePlayTypeAny: boolean | null = false,
        dpAny: boolean | null = false,
        minPlayers: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableInfoid = tableInfoid;
        this.gamePlayTypeAny = gamePlayTypeAny;
        this.dpAny = dpAny;
        this.minPlayers = minPlayers;
    }

    getTableInfoid(): number {
        return this.tableInfoid;
    }

    setTableInfoid(tableInfoid: number) {
        this.tableInfoid = tableInfoid;
    }
    getGamePlayTypeAny(): boolean | null {
        return this.gamePlayTypeAny;
    }

    setGamePlayTypeAny(gamePlayTypeAny: boolean | null) {
        this.gamePlayTypeAny = gamePlayTypeAny;
    }
    getDpAny(): boolean | null {
        return this.dpAny;
    }

    setDpAny(dpAny: boolean | null) {
        this.dpAny = dpAny;
    }
    getMinPlayers(): number {
        return this.minPlayers;
    }

    setMinPlayers(minPlayers: number) {
        this.minPlayers = minPlayers;
    }
}
