import { Message } from '../../message';

export class PlayerGameSessionLimitsStatusResponse extends Message {
    private MESSAGE_NAME: string = 'PlayerGameSessionLimitsStatusResponse';
    private status: number = 0;
    private value1: number = 0;
    private value2: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: number = 0, value1: number = 0, value2: number = 0) {
        super(reqServerPeerId);
        this.status = status;
        this.value1 = value1;
        this.value2 = value2;
    }

    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getValue1(): number {
        return this.value1;
    }

    setValue1(value1: number) {
        this.value1 = value1;
    }
    getValue2(): number {
        return this.value2;
    }

    setValue2(value2: number) {
        this.value2 = value2;
    }
}
