import { Message } from '../../message';

export class LSTourneyParticipantStatus extends Message {
    private MESSAGE_NAME: string = 'LSTourneyParticipantStatus';
    private participantStatus: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, participantStatus: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.participantStatus = participantStatus;
    }

    getParticipantStatus(): Map<any, any> | null {
        return this.participantStatus;
    }

    setParticipantStatus(participantStatus: Map<any, any> | null) {
        this.participantStatus = participantStatus;
    }
}
