// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class BGView extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    ENABLE_SPIN_PRIZE_POOL = true;
    eventDispatcherGlobal = new EventDispatcherGlobal();
    constructor() {
        super();
    }
    createView = function (eventDispatcher, vm, gsd) {
        this.name = 'BGView';
        this.tableBGType = null;
        this.isBoosterEnabledRim = false;
        this.EventDispatcher = eventDispatcher;
        this.vm = vm;
        this.gameSettingsDataObj = gsd;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.buttons;
        this.fontProps = uiConfig.font.props;
        this.feltArray = ['Default', 'MultiplierA', 'MultiplierB', 'MultiplierE'];
        this.spinsFfMap = {
            Frame0: 'Default',
            Frame1: 'MultiplierA',
            Frame2: 'MultiplierB',
            Frame3: 'MultiplierE',
        };
        const localStBg = this.gameSettingsDataObj.cardStyle.tableFelt;
        this.zoomStyle = this.gameSettingsDataObj.cardStyle.tableStyle;
        this.bgZoomVal = this.zoomStyle;
        if (this.vm.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            if (this.zoomStyle == 0) {
                this.background = this.displayUtil.getSprite('Default' + '_out');
            } else {
                // let requiredBg = this.spinsFfMap["Default" + localStBg];
                this.background = this.displayUtil.getSprite('Default');
            }
        } else {
            if (localStBg != undefined) {
                if (this.zoomStyle == 0) {
                    this.background = this.displayUtil.getSprite('Frame' + localStBg + '_out');
                } else {
                    this.background = this.displayUtil.getSprite('Frame' + localStBg);
                }
            } else {
                this.background = this.displayUtil.getSprite('Frame1');
            }
        }
        this.addChild(this.background);
        //this.addChild(this.background);
        this.bgRim = this.displayUtil.getSprite('rimBg');
        this.background.addChild(this.bgRim);
        this.bgRim.visible = false;
        if (this.zoomStyle == 1) {
            this.bgRim.visible = true;
        }
        this.displayUtil.setObjectPosition(this.bgRim, 0, 1280 - this.bgRim.height);
        // this.ENABLE_SPIN_PRIZE_POOL = CommonGameProperties.dynaconConfigs["webClientConfigs"]["ENABLE_SPIN_PRIZE_POOL"];
        this.createPrizePoolContainer();
        this.onResize();
    };
    updateExpoSpinBG = function () {
        if (this.tableBGType == null) {
            if (
                (this.vm.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS &&
                    this.vm.gameService.SpinInfoModel.spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_NORMAL) ||
                this.vm.gameService.SpinInfoModel.spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_ULTRA
            ) {
                this.eventDispatcherGlobal.dispatchEvent('loadExpoSpinAnime', { peerId: this.vm.gameService.getPeerId() });
                // Set default expo spin BG
                if (this.zoomStyle == 0) {
                    this.displayUtil.setTexture(this.background, 'MultiplierXB' + '_out');
                    this.bgRim.visible = false;
                } else {
                    this.displayUtil.setTexture(this.background, 'MultiplierXB' + '_in');
                    this.bgRim.visible = true;
                }
            }
        }
    };
    createHamburger = function () {
        this.hamburgerBtn = this.displayUtil.getButton('HamburgerBtn');
        this.addChild(this.hamburgerBtn);
        this.hamburgerBtn.name = 'hamburgerButton';
        this.displayUtil.setClickable(this.hamburgerBtn, true);
        this.displayUtil.setObjectPosition(this.hamburgerBtn, 20, 20);
        this.hamburgerBtn.scale.set(0.5);
        this.displayUtil.assignHitArea(
            this.hamburgerBtn,
            {
                x: 0,
                y: -10,
                w: this.hamburgerBtn.width + 60,
                h: this.hamburgerBtn.height + 40,
            },
            'rect',
        );
        this.hamburgerBtn.interactive = true;
        this.displayUtil.addInteraction(this.hamburgerBtn, this.sendShowMenuClick.bind(this));
        this.hamburgerBtn.visible = false;
        if (CommonGameProperties.facade['fullScaleView']) {
            this.hamburgerBtn.visible = false;
        }
    };
    sendShowMenuClick = function () {
        // this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_SIDE_MENU);
        this.eventDispatcherGlobal.dispatchEvent('UpdateTileViewStatus', {
            status: true,
            tableId: this.vm.gameService.getPeerId(),
            tableType: this.vm.gameService.getGameTableType(),
        });
    };
    showHamburgerBtn = function (flag) {
        if (!this.hamburgerBtn) {
            this.createHamburger();
        }
        this.hamburgerBtn.visible = flag;
    };
    createPrizePoolContainer = function () {
        if (this.ENABLE_SPIN_PRIZE_POOL == true) {
            this.prizePoolContainer = this.displayUtil.getContainer();
            this.addChild(this.prizePoolContainer);
            this.prizePoolContainer.visible = false;
            this.prizePoolTitle = this.displayUtil.getTextField(this.fontProps.prizePoolTitleTxt);
            this.prizePoolContainer.addChild(this.prizePoolTitle);
            this.displayUtil.setObjectPosition(this.prizePoolTitle, 0, 0);
            //this.prizePoolTitle.anchor.set(0.5);
            this.prizePoolTitle.text = 'Prizepool'.toUpperCase();
            this.prizePoolValue = this.displayUtil.getTextField(this.fontProps.prizePoolValueTxt);
            this.prizePoolContainer.addChild(this.prizePoolValue);
            this.displayUtil.setObjectPosition(
                this.prizePoolValue,
                this.prizePoolTitle.x + this.prizePoolTitle.width / 2.2,
                this.prizePoolTitle.y + this.prizePoolTitle.height,
            );
            this.prizePoolValue.anchor.set(0.5, 0);
            this.prizePoolValue.text = '$2';
            //Prize pool break down
            this.prizePoolBreakDownContainer = this.displayUtil.getContainer();
            this.addChild(this.prizePoolBreakDownContainer);
            this.prizePoolBreakDownBG = this.displayUtil.getSprite('prizepool_panal');
            this.prizePoolBreakDownContainer.addChild(this.prizePoolBreakDownBG);
            this.prizePoolBreakDownContainer.visible = false;
            this.displayUtil.setObjectPosition(this.prizePoolBreakDownBG, 0, 0);
            this.rank1Txt = this.displayUtil.getTextField(this.fontProps.prizePoolRankTxt);
            this.prizePoolBreakDownBG.addChild(this.rank1Txt);
            this.displayUtil.setObjectPosition(this.rank1Txt, 0, 0);
            this.rank1Txt.text = '1  :';
            this.rank1PosTxt = this.displayUtil.getTextField(this.fontProps.prizePoolRankPosTxt);
            this.rank1Txt.addChild(this.rank1PosTxt);
            this.displayUtil.setObjectPosition(this.rank1PosTxt, 6, 0);
            this.rank1PosTxt.text = 'st';
            this.rankPrizeTxt1 = this.displayUtil.getTextField(this.fontProps.prizePoolPrizeTxt);
            this.prizePoolBreakDownBG.addChild(this.rankPrizeTxt1);
            this.displayUtil.setObjectPosition(this.rankPrizeTxt1, 18, 0);
            this.rankPrizeTxt1.text = '$0.00';
            this.line1 = this.displayUtil.getSprite('prizepool_line');
            this.prizePoolBreakDownBG.addChild(this.line1);
            this.displayUtil.setObjectPosition(this.line1, 66, 0);

            this.rank2Txt = this.displayUtil.getTextField(this.fontProps.prizePoolRankTxt);
            this.prizePoolBreakDownBG.addChild(this.rank2Txt);
            this.displayUtil.setObjectPosition(this.rank2Txt, 70, 0);
            this.rank2Txt.text = '2   :';
            this.rank2PosTxt = this.displayUtil.getTextField(this.fontProps.prizePoolRankPosTxt);
            this.rank2Txt.addChild(this.rank2PosTxt);
            this.displayUtil.setObjectPosition(this.rank2PosTxt, 8, 0);
            this.rank2PosTxt.text = 'nd';
            this.rankPrizeTxt2 = this.displayUtil.getTextField(this.fontProps.prizePoolPrizeTxt);
            this.prizePoolBreakDownBG.addChild(this.rankPrizeTxt2);
            this.displayUtil.setObjectPosition(this.rankPrizeTxt2, 92, 0);
            this.rankPrizeTxt2.text = '$0.00';
            this.line2 = this.displayUtil.getSprite('prizepool_line');
            this.prizePoolBreakDownBG.addChild(this.line2);
            this.displayUtil.setObjectPosition(this.line2, 138, 0);

            this.rank3Txt = this.displayUtil.getTextField(this.fontProps.prizePoolRankTxt);
            this.prizePoolBreakDownBG.addChild(this.rank3Txt);
            this.displayUtil.setObjectPosition(this.rank3Txt, 142, 0);
            this.rank3Txt.text = '3   :';
            this.rank3PosTxt = this.displayUtil.getTextField(this.fontProps.prizePoolRankPosTxt);
            this.rank3Txt.addChild(this.rank3PosTxt);
            this.displayUtil.setObjectPosition(this.rank3PosTxt, 9, 0);
            this.rank3PosTxt.text = 'rd';
            this.rankPrizeTxt3 = this.displayUtil.getTextField(this.fontProps.prizePoolPrizeTxt);
            this.prizePoolBreakDownBG.addChild(this.rankPrizeTxt3);
            this.displayUtil.setObjectPosition(this.rankPrizeTxt3, 164, 0);
            this.rankPrizeTxt3.text = '$0.00';
            //this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_POOL_PRIZE, this.updatePrizePoolData.bind(this));
        }
    };
    //not getting used for booster
    onTableBgUpdate = function (data) {
        this.removeChild(this.background);
        this.background = this.displayUtil.getSprite('mainBG');
        this.addChildAt(this.background, 0);
        let color;
        switch (data.tableStyle) {
            case 'red':
                color = '0xFF0000';
                break;
            case 'blue':
                color = '0X0000FF';
                break;
            case 'green':
                color = '0X00FF00';
                break;
            case 'black':
                color = '0X000000';
                break;
            default:
                color = '0XFF0';
                break;
        }
        this.background.tint = color;
        this.bgRim = this.displayUtil.getSprite('rimBg');
        this.background.addChild(this.bgRim);
        this.bgRim.visible = false;
        if (this.bgZoomVal == 1 || this.zoomStyle == 1) {
            this.bgRim.visible = true;
        }
        this.displayUtil.setObjectPosition(this.bgRim, 0, 0);
    };
    //indirect function but is used
    updateTableBgOnDisc = function (bgType, bgZoomVal) {
        if (this.vm.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS) {
            const spinsBgType = this.vm.gameService.getTableBgType();
            if (spinsBgType != null) bgType = spinsBgType;
        }
        if (bgZoomVal != undefined) {
            this.bgZoomVal = bgZoomVal;
        }
        if (this.boosterName && this.isBoosterEnabledRim) {
            // let data = this.gameService.getTableBoosterTriggered();
            // this.boosterType = data.boosterType;
            // switch (this.boosterType) {
            //     case 1:
            //     break;
            //     case 2: this.boosterName = "Bronze";
            //     break;
            //     case 3: this.boosterName = "Silver";
            //     break;
            //     case 4:
            //     this.boosterName = "Gold";
            //     break;
            //     case 5:
            //     this.boosterName = "Platinum";
            //     break;
            //     case 6:
            //     this.boosterName = "Diamond";
            //     break;
            //     default: break;
            // }
            this.updateBGRim(this.boosterName, true);
        } else {
            if (
                (this.vm.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_SPINS &&
                    this.vm.gameService.SpinInfoModel.spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_NORMAL) ||
                this.vm.gameService.SpinInfoModel.spinCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_ULTRA
            ) {
                this.tableBGType = bgType;
                // Set default expo spin BG
                if (bgType == 'Default') {
                    bgType = 'MultiplierXB';
                }
                if (this.bgZoomVal == 0) {
                    this.displayUtil.setTexture(this.background, bgType + '_out');
                    this.bgRim.visible = false;
                } else {
                    this.displayUtil.setTexture(this.background, bgType + '_in');
                    this.bgRim.visible = true;
                }
            } else {
                if (this.background && bgType !== null) {
                    if (bgType == 'MultiplierI' || bgType == 'MultiplierJ' || bgType === 'Default_img') {
                        const reqBG = 'Default';
                        if (this.bgZoomVal == 0) {
                            this.displayUtil.setTexture(this.background, reqBG + '_out');
                            this.bgRim.visible = false;
                        } else {
                            this.displayUtil.setTexture(this.background, reqBG);
                            this.bgRim.visible = true;
                        }
                    } else {
                        if (this.bgZoomVal == 0) {
                            this.displayUtil.setTexture(this.background, bgType + '_out');
                            this.bgRim.visible = false;
                        } else {
                            this.displayUtil.setTexture(this.background, bgType);
                            this.background.removeChild(this.bgRim);
                            this.displayUtil.setTexture(this.bgRim, 'rimBg');
                            this.background.removeChild(this.bgRim);
                            this.displayUtil.setObjectPosition(this.bgRim, 0, 1280 - this.bgRim.height);
                            // this.bgRim = this.displayUtil.getSprite("rim");
                            this.background.addChild(this.bgRim);
                            this.bgRim.visible = true;
                        }
                    }
                }
            }
        }
    };
    onResize = function () {
        // this.displayUtil.setContainerScaleFitToScreen(this.background);
        // if (this.ENABLE_SPIN_PRIZE_POOL == true) {
        //     this.displayUtil.setContainerScaleFitToScreen(this.prizePoolContainer);
        // }
        let scaleXValue = uiConfig.windowWidth / 720;
        let scaleYValue = uiConfig.windowHeight / 1280;
        this.background.scale.set(scaleXValue, scaleYValue);
        if (this.ENABLE_SPIN_PRIZE_POOL == true) {
            this.prizePoolContainer.scale.set(scaleXValue);
        }
    };
    tableResize = function (scaleVal) {
        this.zoomStyle = this.gameSettingsDataObj.cardStyle.tableStyle;
        if (scaleVal == 1) {
            this.showHamburgerBtn(false);
        } else {
            this.showHamburgerBtn(true);
        }
        if (this.zoomStyle == 1) {
            if (this.isBoosterEnabledRim) {
                this.displayUtil.setObjectPosition(this.bgRim, 0, 0);
            } else {
                this.displayUtil.setObjectPosition(this.bgRim, 0, 1280 - this.bgRim.height);
            }
        }
    };
    updatePrizePoolData = function (data, gameTableStatus) {
        if (this.ENABLE_SPIN_PRIZE_POOL == true) {
            // let data = JSON.parse('{"sngJackpotID":147371467,"tableId":13470656,"sngJPInstanceID":147372815,"gamePlayStartTime":null,"sngJackpotPayoutInfo":{"sngJackpotID":147371467,"sngJPInstanceID":147372815,"sngJPType":0,"totalPrize":6700,"prizesInfo":[{"rank":1,"prizeAmount":4050,"prizeType":0},{"rank":2,"prizeAmount":2750,"prizeType":0}],"sngJPBountyInfo":null,"payoutType":0,"msgNumber":0},"msgNumber":0,"peerId":13470656,"className":"SNGJPGameAboutToStart"}');
            // let gameTableStatus = JSON.parse('{"maxNoOfSeats":3,"activeFlag":true,"allowPlayerToSit":false,"ante":0,"autoLoad":false,"avgFlopSeen":0,"avgPot":0,"bigBlind":0,"bringIn":0,"casinoTrnyLevelInfo":[],"countDownTime":5,"dbTableInfoId":1684567,"disconProtectType":2,"familyId":0,"fppBuyIn":0,"gameCurrency":"USD","gameId":0,"gamePlayType":0,"gameTypeId":0,"groupId":5,"handsPerHour":0,"huTableType":0,"isChampionshipTable":false,"isTBTRandomized":false,"jackpotId":0,"maxBuyInAmount":0,"minBuyInAmount":0,"mtctBuyIn":2970,"mtctId":147372815,"playerCount":1,"playersPerFlop":0,"pvtTableType":0,"reservedSeatsCount":0,"serverPeerId":6,"smallBlind":0,"stakesLowerLimit":0,"stakesUpperLimit":0,"tabIds":[999999],"tableCategory":3,"tableColorCode":0,"tableId":13470656,"tableLimitType":1,"tableName":{"msg":null,"templateId":10056,"params":[25368539,147372815,1],"isLPElement":true},"tableTypeId":0,"tbtMaxTime":0,"tourneyBuyIn":2970,"tourneyEntryFee":30,"tourneyGameStatus":125,"trnyChips":0,"waitListCount":0,"watchersCount":0,"protectionLevel":0,"challengeType":0,"tournamentCategory":3,"bountyFee":0,"tableProfileType":0,"trnyMixMaxType":0,"balanceThreshold":0,"rebuyAddonTrnyTable":false,"realNameTable":false,"gameTableType":0}');
            let prizePoolValue;
            if (data && data.sngJPPayoutInfo && data.sngJPPayoutInfo.totalPrize) {
                prizePoolValue = data.sngJPPayoutInfo.totalPrize;
                // this.prizePoolValue.text = CommonGameProperties.mathUtil["transform"](data.sngJPPayoutInfo.totalPrize);
            } else if (data && data.sngJackpotPayoutInfo && data.sngJackpotPayoutInfo.totalPrize) {
                prizePoolValue = data.sngJackpotPayoutInfo.totalPrize;
            }
            // this.prizePoolValue.text = "$100";
            this.prizePoolValue.text = CommonGameProperties.numberFormatter['transform'](prizePoolValue, this.vm.gameService.getGameCurrency());
            CommonGameProperties.numberFormatter['transform'](prizePoolValue, this.vm.gameService.getGameCurrency());
            //this.prizePoolContainer.pivot.x = this.prizePoolContainer.width/2;
            //this.prizePoolContainer.pivot.y = this.prizePoolContainer.height/2;
            const yDiff = 70;
            /*if ($(".tablewidget_flex", window.parent.document.body)) {
              yDiff = $(".tablewidget_flex", window.parent.document.body).height();
            }*/
            this.displayUtil.setObjectPosition(
                this.prizePoolContainer,
                (uiConfig.windowDimension.width - this.prizePoolContainer.width) / 2,
                uiConfig.windowDimension.height * 0.03 + yDiff - 25,
            );
            this.prizePoolContainer.visible = true;
            this.displayUtil.setObjectPosition(
                this.prizePoolBreakDownContainer,
                (uiConfig.windowDimension.width - this.prizePoolBreakDownContainer.width) / 2,
                uiConfig.windowDimension.height * 0.03 + (yDiff + 15),
            );
            this.prizePoolBreakDownContainer.visible = true;
            let prizesInfo: any;
            if (data.className == 'SNGJPGameAboutToStart') {
                prizesInfo = data.sngJackpotPayoutInfo.prizesInfo;
            } else {
                prizesInfo = data.sngJPPayoutInfo.prizesInfo;
            }
            if (prizesInfo != undefined) {
                for (let i = 0; i < prizesInfo.length; i++) {
                    this['rankPrizeTxt' + (i + 1)].text = CommonGameProperties.numberFormatter['transform'](
                        prizesInfo[i].prizeAmount,
                        gameTableStatus.gameCurrency,
                    );
                }
            }
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_PLAYER_BALANCE);
    };
    updateTableBgZoomStyle = function (bgType, bgZoom) {
        this.bgZoomVal = bgZoom;
        this.updateTableBgOnDisc(bgType, bgZoom);
    };
    updateBGRim = function (boosterName, isBoosterEnabledRim) {
        this.isBoosterEnabledRim = isBoosterEnabledRim;
        this.boosterName = boosterName;
        this.zoomStyle = this.gameSettingsDataObj.cardStyle.tableStyle;
        if (this.boosterName != undefined && this.boosterName != null) {
            if (this.zoomStyle == 0) {
                this.displayUtil.setTexture(this.background, 'Overlay_zoom_out_' + this.boosterName);
                this.bgRim.visible = false;
            } else {
                if (this.isBoosterEnabledRim) {
                    this.displayUtil.setTexture(this.background, 'Overlay_zoom_in_' + this.boosterName);
                    this.displayUtil.setTexture(this.bgRim, 'rim_zoom_out_' + this.boosterName);
                    this.displayUtil.setObjectPosition(this.bgRim, 0, 0);
                } else {
                    const localStBg = this.gameSettingsDataObj.cardStyle.tableFelt;
                    this.displayUtil.setTexture(this.background, 'Frame' + localStBg);
                    this.background.removeChild(this.bgRim);
                    this.bgRim = this.displayUtil.getSprite('rimBg');
                    this.displayUtil.setObjectPosition(this.bgRim, 0, 1280 - this.bgRim.height);
                    this.background.addChild(this.bgRim);
                }
                this.bgRim.visible = true;
            }
        }
    };
    updateTriggerBase = function () {
        // this.triggerBtnBase.visible = true;
        //  this.triggerBtn.visible = true;
    };
}
