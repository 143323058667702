import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PokerScheduleFeedPageMatrixBootstrapService } from './poker-schedule-feed-bootstrap.service';
import { PokerScheduleFeedService } from './poker-schedule-feed.service';

export function providePokerScheduleFeed(): Provider[] {
    return [runOnAppInit(PokerScheduleFeedPageMatrixBootstrapService), PokerScheduleFeedService];
}
