<div [class]="item.isNewStyle ? 'promoHover' : 'h-100'" *ngIf="item.relatedPagePath || item.synopsisLink">
    <a
        [href]="item.synopsisLink ? item.synopsisLink.url : getPromoLink(item.relatedPagePath[0].path)"
        [attr.target]="item.synopsisLink && item.synopsisLink.attributes.target ? item.synopsisLink.attributes.target : null"
        (click)="
            pkPromoOffersCtaTracking(
                item.name,
                item.synopsisLink ? item.synopsisLink.url : getPromoLink(item.relatedPagePath[0].path),
                $event,
                item.synopsisTitle
            )
        ">
        <div *ngIf="item.isNewStyle; else oldStyle">
            <div class="contentWrapper" *ngIf="item.synopsisImage">
                <img [src]="item.synopsisImage.src" [alt]="item.synopsisImage.alt" />
                <div class="teaserContent">
                    <div class="synopsisTitle" *ngIf="item.synopsisTitle">
                        <p>{{ item.synopsisTitle }}</p>
                    </div>
                    <div class="synopsisDescription" *ngIf="item.synopsisDescription">
                        <p>{{ item.synopsisDescription }}</p>
                    </div>
                    <div class="synopsisCTA" *ngIf="item.synopsisCTA">
                        <p>{{ item.synopsisCTA }}</p>
                    </div>
                </div>
            </div>
            <div class="significantTerms" *ngIf="item.synopsisTerms">
                <p>{{ item.synopsisTerms }}</p>
            </div>
        </div>
        <ng-template #oldStyle>
            <div class="card h-100">
                <img [src]="item.synopsisImage.src" alt="Synopsis Image" class="card-img-top img-fluid" />
                <div *ngIf="item.synopsisTitle || item.synopsisDescription" class="card-body">
                    <p *ngIf="item.synopsisTitle" class="card-body-title mb-0">{{ item.synopsisTitle }}</p>
                    <div *ngIf="item.synopsisDescription" class="card-body-title mb-0" [innerHtml]="item.synopsisDescription"></div>
                    <p *ngIf="item.synopsisCTA" class="card-body-title">{{ item.synopsisCTA }}</p>
                </div>
                <div class="card-footer" *ngIf="item.synopsisTerms">
                    <small class="text-footer">{{ item.synopsisTerms }}</small>
                </div>
            </div>
        </ng-template>
    </a>
</div>
