import { Message } from '../../message';

export class UnjoinWaitlist extends Message {
    private MESSAGE_NAME: string = 'UnjoinWaitlist';
    private tableId: number = 0;
    private tableInfoId: number = 0;
    private success: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, tableInfoId: number = 0, success: boolean | null = false) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.tableInfoId = tableInfoId;
        this.success = success;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getSuccess(): boolean | null {
        return this.success;
    }

    setSuccess(success: boolean | null) {
        this.success = success;
    }
}
