import { Message } from '../../message';

export class CloseTable extends Message {
    private MESSAGE_NAME: string = 'CloseTable';
    private tableId: number = 0;
    private message: any | null = null;
    private delayInSecs: number = 0;
    private athBlockTime: number = 0;
    private athStrikePresent: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        message: any | null = null,
        delayInSecs: number = 0,
        athBlockTime: number = 0,
        athStrikePresent: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.message = message;
        this.delayInSecs = delayInSecs;
        this.athBlockTime = athBlockTime;
        this.athStrikePresent = athStrikePresent;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getDelayInSecs(): number {
        return this.delayInSecs;
    }

    setDelayInSecs(delayInSecs: number) {
        this.delayInSecs = delayInSecs;
    }
    getAthBlockTime(): number {
        return this.athBlockTime;
    }

    setAthBlockTime(athBlockTime: number) {
        this.athBlockTime = athBlockTime;
    }
    getAthStrikePresent(): boolean | null {
        return this.athStrikePresent;
    }

    setAthStrikePresent(athStrikePresent: boolean | null) {
        this.athStrikePresent = athStrikePresent;
    }
}
