import { Message } from '../../message';

export class LSTourneyEventsQualifiersChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyEventsQualifiersChange';
    private mtctEvents: any[] | null = null;
    private qualifiers: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctEvents: any[] | null = null, qualifiers: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctEvents = mtctEvents;
        this.qualifiers = qualifiers;
    }

    getMtctEvents(): any[] | null {
        return this.mtctEvents;
    }

    setMtctEvents(mtctEvents: any[] | null) {
        this.mtctEvents = mtctEvents;
    }
    getQualifiers(): any[] | null {
        return this.qualifiers;
    }

    setQualifiers(qualifiers: any[] | null) {
        this.qualifiers = qualifiers;
    }
}
