import { Message } from '../../message';

export class RequestGroupLobbyUpdate extends Message {
    private MESSAGE_NAME: string = 'RequestGroupLobbyUpdate';
    private active: boolean | null = false;
    private tabSnapshotTimeInNanosList: any[] | null = null;
    private requestType: number = 0;
    private ffTabSnapShotList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        active: boolean | null = false,
        tabSnapshotTimeInNanosList: any[] | null = null,
        requestType: number = 0,
        ffTabSnapShotList: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.active = active;
        this.tabSnapshotTimeInNanosList = tabSnapshotTimeInNanosList;
        this.requestType = requestType;
        this.ffTabSnapShotList = ffTabSnapShotList;
    }

    getActive(): boolean | null {
        return this.active;
    }

    setActive(active: boolean | null) {
        this.active = active;
    }
    getTabSnapshotTimeInNanosList(): any[] | null {
        return this.tabSnapshotTimeInNanosList;
    }

    setTabSnapshotTimeInNanosList(tabSnapshotTimeInNanosList: any[] | null) {
        this.tabSnapshotTimeInNanosList = tabSnapshotTimeInNanosList;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
    getFfTabSnapShotList(): any[] | null {
        return this.ffTabSnapShotList;
    }

    setFfTabSnapShotList(ffTabSnapShotList: any[] | null) {
        this.ffTabSnapShotList = ffTabSnapShotList;
    }
}
