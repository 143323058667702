import { Message } from '../../message';

export class UserLocationStatus extends Message {
    private MESSAGE_NAME: string = 'UserLocationStatus';
    private isRMJoinTableAllowed: boolean | null = false;
    private locationStatus: number = 0;
    private isPMJoinTableAllowed: boolean | null = false;
    private errorCode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        isRMJoinTableAllowed: boolean | null = false,
        locationStatus: number = 0,
        isPMJoinTableAllowed: boolean | null = false,
        errorCode: number = 0,
    ) {
        super(reqServerPeerId);
        this.isRMJoinTableAllowed = isRMJoinTableAllowed;
        this.locationStatus = locationStatus;
        this.isPMJoinTableAllowed = isPMJoinTableAllowed;
        this.errorCode = errorCode;
    }

    getIsRMJoinTableAllowed(): boolean | null {
        return this.isRMJoinTableAllowed;
    }

    setIsRMJoinTableAllowed(isRMJoinTableAllowed: boolean | null) {
        this.isRMJoinTableAllowed = isRMJoinTableAllowed;
    }
    getLocationStatus(): number {
        return this.locationStatus;
    }

    setLocationStatus(locationStatus: number) {
        this.locationStatus = locationStatus;
    }
    getIsPMJoinTableAllowed(): boolean | null {
        return this.isPMJoinTableAllowed;
    }

    setIsPMJoinTableAllowed(isPMJoinTableAllowed: boolean | null) {
        this.isPMJoinTableAllowed = isPMJoinTableAllowed;
    }
    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
}
