import { Message } from '../../message';

export class OTPResponse extends Message {
    private MESSAGE_NAME: string = 'OTPResponse';
    private responseId: number = 0;
    private lastDigitsOfMobile: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseId: number = 0, lastDigitsOfMobile: number = 0) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.lastDigitsOfMobile = lastDigitsOfMobile;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getLastDigitsOfMobile(): number {
        return this.lastDigitsOfMobile;
    }

    setLastDigitsOfMobile(lastDigitsOfMobile: number) {
        this.lastDigitsOfMobile = lastDigitsOfMobile;
    }
}
