import { Message } from '../../message';

export class ResponseLSMTCTStatus extends Message {
    private MESSAGE_NAME: string = 'ResponseLSMTCTStatus';
    private mtctId: number = 0;
    private responseId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, responseId: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.responseId = responseId;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
}
