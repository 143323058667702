import { Message } from '../../message';

export class ProPlayerList extends Message {
    private MESSAGE_NAME: string = 'ProPlayerList';
    private proPlayerScreenNames: any[] | null = null;
    private proPlayerFullNames: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, proPlayerScreenNames: any[] | null = null, proPlayerFullNames: any[] | null = null) {
        super(reqServerPeerId);
        this.proPlayerScreenNames = proPlayerScreenNames;
        this.proPlayerFullNames = proPlayerFullNames;
    }

    getProPlayerScreenNames(): any[] | null {
        return this.proPlayerScreenNames;
    }

    setProPlayerScreenNames(proPlayerScreenNames: any[] | null) {
        this.proPlayerScreenNames = proPlayerScreenNames;
    }
    getProPlayerFullNames(): any[] | null {
        return this.proPlayerFullNames;
    }

    setProPlayerFullNames(proPlayerFullNames: any[] | null) {
        this.proPlayerFullNames = proPlayerFullNames;
    }
}
