import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NavigationService } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { LobbyTileInfoComponent } from '../../common/lobby-tile-info/lobby-tile-info.component';
import { MainLobbyBannerComponent } from '../../common/main-lobby-banner/main-lobby-banner.component';
import { CardInfo } from '../../models/tile-card-info.model';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';

@Component({
    selector: 'pwc-main-lobby',
    templateUrl: 'main-lobby.component.html',
    imports: [CommonModule, MainLobbyBannerComponent, LobbyTileInfoComponent],
    providers: [TranslatePipe],
    standalone: true,
})
export class MainLobbyComponent {
    public lobbyPromotions: any;
    dynaconData: any;
    athHandCount: number = 0;
    private readonly logger: PokerLogger;
    enableExpo: boolean;
    isATHEnabled: any;
    constructor(
        private navigation: NavigationService,
        private dialog: MatDialog,
        private routeData: RouteDataService,
        loggerFactory: LoggerFactory,
        private clientToServer: ClientToServerRequestServices,
    ) {
        this.dynaconData = this.clientToServer.dynaconConfigs['webClientConfigs'];
        this.logger = loggerFactory.getLogger('mainLobbyLoggingComponent');
        const lobbyPromotionsOrigin = this.routeData.getInitData().lobbyPromotions.lobbyPromotions;
        const sortedPromos = lobbyPromotionsOrigin.sort((a, b) => {
            if (a.fullSizeTile && !b.fullSizeTile) {
                return -1; // a comes before b
            } else if (!a.fullSizeTile && b.fullSizeTile) {
                return 1; // b comes before a
            } else {
                return 0; // no change in order
            }
        });
        this.lobbyPromotions = sortedPromos;
        this.isATHEnabled = this.clientToServer.dynaconConfigs['webClientConfigs']['enableATH'];
    }

    gameLobby(gameType: any) {
        this.navigation.goTo('/play/' + gameType);
    }

    gameInfo(info: any) {
        this.logger.info('clicked on info popup icon' + JSON.stringify(info));
        const data: CardInfo = {
            viewType: 0,
            headerImageUrl: `${info.infoActorImage.src}`,
            description: `${info.infoDescription}`,
            ctaButtons: [info.primaryCTA, info.secondaryCTA],
        };
        this.dialog.open(LobbyTileInfoComponent, {
            width: '95%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'wpc-card-info',
            hasBackdrop: true,
            backdropClass: 'wpc-card-info-bg',
            disableClose: false,
            closeOnNavigation: true,
        });
    }
    showExpo() {
        this.enableExpo = true;
    }
}
