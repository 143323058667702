import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { CashErrorDialogModel } from '../../models/cash-error-dialog.model';
import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { FxRateHelperClassService } from '../../service/Fxratemanager';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
// import { MathUtils } from '../../service/MathUtil';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { GameTableNotificationService } from '../../service/game-table-notification.service';
import { miniTablesSelect } from '../../store/mini-table.store';
import { userBalanceSelect } from '../../store/user.store';
import { PWCErrorDialogComponent } from '../error-dialog/pwc-error-dialog.component';
import { PDollarInfoPopUpComponent } from '../p-dollar-info-pop-up/p-dollar-info-pop-up.component';

@Component({
    selector: 'pwc-cash-ff-buyin',
    templateUrl: 'cash-ff-buyIn.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, TranslatePipe, PDollarInfoPopUpComponent, FormatamountPipe, PWCErrorDialogComponent, formatCurrencyPipe],
    providers: [],
})
export class CashFFBuyInPopUpComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() table: any;
    @Input() entryId: any;
    @Input() isAutorebuyin: any;
    @Input() selectedgameType: any;
    @Input() playerbalance: any;
    @Output() close = new EventEmitter<void>();
    @Output() tableValues = new EventEmitter<object>();
    // public buyInData:any;
    public buyInTitle: any;
    public sliderMinValue: any;
    public sliderMaxValue: any;
    public showBuyInRange = false;
    public isAutoBuyInTrue = false;
    public gameCount = 1;
    public isBalanceInfoEnabled = false;
    public selectedBuyIn: any;
    public userBalance = 0;
    public accountCurrency = 'USD';
    public buyInLimitTypeAndGameType = '';
    public showInfoAlert = false;
    public showInfoAlertText = '';
    public userBalanceData: any;
    athBlockTime: any = 2;
    durationTimerInSec = 30;
    buyInProgressBarWidth = 100;
    durationTimerLeftInSec: any;
    buyInInterval: any;
    private readonly logger: PokerLogger;
    miniTableCount = 0;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    selectedTable: any;
    autoReBuyInfoPopUp = false;
    elRef: ElementRef;
    private isServerGroupingEnabled: boolean;
    showTitle: boolean = true;
    FxRateManager;
    conversionFactor;
    conversionFactor4balance = 1;
    public textSB: any;
    sliderMax: any;

    constructor(
        private dialog: MatDialog,
        private store: Store,
        loggerFactory: LoggerFactory,
        private translatePipe: TranslatePipe,
        private gameTableNotificationService: GameTableNotificationService,
        public elementRef: ElementRef,
        private routeData: RouteDataService,
        private clientToServer: ClientToServerRequestServices,
    ) {
        this.elRef = elementRef;
        this.isServerGroupingEnabled = this.routeData.getInitData().dynaconData.webClientConfigs.serverGroupingEnabled;
        this.FxRateManager = FxRateHelperClassService.getInstance();
        this.startCountdown(this.durationTimerInSec);
        this.logger = loggerFactory.getLogger('cashffbuyInLoggingComponent');
        this.store.select(userBalanceSelect).subscribe((data) => {
            if (!_.isEmpty(data) && !_.isEmpty(data['netRealBalance'])) {
                this.accountCurrency = data['netRealBalance']['accountCurrency'];
                this.conversionFactor4balance = this.FxRateManager.getFxRateWithOutSnapShotId(this.accountCurrency, 'USD', 'cashgames');
                this.userBalance =
                    data['netRealBalance']['accountBalance'] * this.conversionFactor4balance +
                    (data['tourneyCurrencyBalance'] != null ? data['tourneyCurrencyBalance']['tourneyCurrencyBalance'] : 0);
            }
        });
        this.eventDispatcherGlobal.addEventListener('ResponseQuickSeatRingError', this.ResponseQuickSeatRingError.bind(this));
        this.eventDispatcherGlobal.addEventListener('JoinTableResponseError', this.JoinTableResponseError.bind(this));
        this.eventDispatcherGlobal.addEventListener('UpdateLHNStatus', this.closePopup.bind(this));
    }

    ngOnInit(): void {
        this.buyInLimitTypeAndGameType = this.getLimitTypeAndGameType(this.table.tableLimitType, this.table.gameTypeId);
        this.conversionFactor = this.FxRateManager.getFxRateWithOutSnapShotId('USD', this.accountCurrency, 'cashgames');
        if (this.table['MESSAGE_NAME'] === 'PoolAvailabilityResponse') {
            this.buyInTitle = this.table.poolName.params[0];
            this.getBuyInMinMaxValues(this.table.minBuyin, this.table.maxBuyin);
        } else {
            if (this.isServerGroupingEnabled) {
                this.showTitle = false;
            }
            if (this.isAutorebuyin) this.getTitle(this.table.smallBlind, this.table.maxBuyInAmount - this.playerbalance);
            else {
                this.getTitle(this.table.minBuyInAmount, this.table.maxBuyInAmount);
                this.getInfoMessage(this.table.realNameTable, this.table.maxNoOfSeats);
            }
        }
    }
    ngAfterViewInit(): void {
        if (this.showBuyInRange) {
            this.setValue(this.sliderMaxValue);
            document.addEventListener('DOMContentLoaded', () => this.setValue(this.sliderMaxValue));
        }
    }
    setValue(value: any) {
        this.logger.info('clicked on playnow button..!' + JSON.stringify(value));
        //if (!this.showBuyInRange) return;
        const range = document.getElementById('range') as HTMLInputElement;
        const tooltip = document.getElementById('tooltip');

        //if (!range || !tooltip) return;
        const min = parseFloat(range.min);
        const max = parseFloat(range.max);
        const currentValue = parseFloat(value);

        //const normalizedValue = ((currentValue - min) / (max - min)) * 100;

        const newValue = ((currentValue - min) / (max - min)) * 100;
        const newPosition = 21 - newValue * 0.42;

        if (tooltip) {
            let valueToShow = currentValue % 1 !== 0 ? currentValue.toFixed(2) : currentValue;
            tooltip.innerHTML = `<span>Buy-in $${valueToShow}</span>`;
            tooltip.style.left = `calc(${newValue}% + (${newPosition}px))`;
            document.documentElement.style.setProperty('--range-progress', `calc(${newValue}% + (${newPosition}px))`);
        }
    }
    openBalanceInfoPopup(event: MouseEvent) {
        this.logger.info('clicked on info popup..!');
        this.autoReBuyInfoPopUp = false;
        event.stopPropagation();
        this.isBalanceInfoEnabled = !this.isBalanceInfoEnabled;
    }

    closePopup() {
        this.logger.info('clicked on bunin popup close icon..!');
        this.close.emit();
    }
    closeBalanceInfoPopup() {
        this.logger.info('clicked on info popup close icon..!');
        this.isBalanceInfoEnabled = false;
    }
    updateGameCount(count) {
        this.gameCount = count;
    }

    // AppConstants.LIMIT_TYPE_REGULAR = 0
    // AppConstants.LIMIT_TYPE_NO_LIMIT = 1
    getLimitType(data) {
        if (data.tableLimitType === 0) {
            return 'Fixed Limit';
        } else if (data.tableLimitType === 1) {
            return 'No Limit';
        } else {
            return 'Pot Limit';
        }
    }

    getGameType(data) {
        if (data.gameTypeId === 0) {
            return "Hold'em";
        } else if (data.gameTypeId === 1) {
            return 'Omaha';
        } else {
            return 'Omaha HL';
        }
    }

    getBlindsLabel(data) {
        if (this.getLimitType(data).trim() === 'Fixed Limit') {
            return 'Stakes';
        } else if (data['gameTypeId'] != 7) {
            return 'Blinds';
        } else if (data['gameTypeId'] == 7) {
            return 'Ante';
        } else {
            return;
        }
    }

    getBlinds(data) {
        this.textSB = (data.smallBlind / 100).toString().split('.').length > 1 ? Number((data.smallBlind / 100).toFixed(2)) : data.smallBlind / 100;
        const textBB = (data.bigBlind / 100).toString().split('.').length > 1 ? Number((data.bigBlind / 100).toFixed(2)) : data.bigBlind / 100;
        const text = this.textSB != textBB ? `$${this.textSB}/$${textBB} ` : `$${textBB} `;
        this.sliderMax = this.sliderMaxValue % 1 !== 0 ? Number(Number(this.sliderMaxValue).toFixed(2)) : this.sliderMaxValue; //(parseFloat(this.sliderMaxValue)).toFixed(2);
        return text + data.gameCurrency;
    }

    getSeaterText(data) {
        if (data.gameTypeId != 7) {
            return `${data.maxNoOfSeats == 2 ? 'HU' : data.maxNoOfSeats + '-Max'}`;
        } else {
            return '';
        }
    }

    // GAME_TYPE_OMAHA_HI_LO = 2
    // GAME_TYPE_SEVEN_STUD_HI_LO = 4
    checkIfHiLoGame(gameTypeId) {
        if (gameTypeId === 2 || gameTypeId === 4) {
            return 'Hi-Lo';
        } else {
            return '';
        }
    }

    getGameTypeString(data) {
        if (data.realNameTable) {
            return 'Real Name';
        } else if (data.anteCashAmount > 0) {
            return 'Ante';
        } else if (data.tableProfileType == 1) {
            return 'Featured';
        }
        return '';
    }

    getLimitWithGameValue(value) {
        if (value.gameTypeId === 0) {
            //Hold’em
            if (value.tableLimitType === 0) {
                return 'FLH';
            } else if (value.tableLimitType === 1) {
                return 'NLH';
            } else {
                return 'PLH';
            }
        } else if (value.gameTypeId === 1 || value.gameTypeId === 3 || value.gameTypeId === 2 || value.gameTypeId === 4) {
            //omaha
            let result = '';

            if (value.tableLimitType === 0) {
                result = 'FLO';
            } else if (value.tableLimitType === 1) {
                result = 'NLO';
            } else {
                result = 'PLO';
            }

            if (value.gameTypeId === 2 || value.gameTypeId === 4) {
                return `${result}8`;
            } else {
                return result;
            }
        } else if (value.gameTypeId === 7) {
            //Shortdeck
            return 'Short Deck';
        } else {
            return;
        }
    }

    loadGame() {
        this.logger.info('clicked on $usd button..!');
        if (this.isAutorebuyin == true) {
            let message;
            switch (this.selectedgameType) {
                case 'Cash':
                    message = {
                        amount: this.selectedBuyIn * 100,
                        conversationId: 0,
                        includePartnerWallet: 0,
                        tableId: this.entryId,
                    };
                    this.gameTableNotificationService.buyMoreChips(message);
                    break;
                case 'FF':
                    let activeTable;
                    this.store.select(miniTablesSelect).subscribe((data) => {
                        if (data['miniTables'].length > 0) {
                            activeTable = data['miniTables'].filter((item) => item.tableId === this.entryId);
                        }
                    });
                    message = {
                        amount: this.selectedBuyIn * 100,
                        conversationId: 0,
                        includePartnerWallet: 0,
                        entryId: this.entryId,
                        poolId: activeTable[0].tableObject.poolID,
                    };
                    this.gameTableNotificationService.poolRebuyRequest(message);
                    break;
            }
        } else {
            this.store.select(miniTablesSelect).subscribe((data) => {
                this.miniTableCount = data['miniTables'].length;
            });
            const remainingTables = 4 - this.miniTableCount;

            if (this.miniTableCount + this.gameCount > 4) {
                if (this.miniTableCount === 4) {
                    this.openErrorDialog({
                        title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                        message: this.translatePipe.transform('PARTY_POKER_GC_QSLOBBY_OPENEDMAXTBLSMSG'),
                        buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
                    });
                } else {
                    const title = this.translatePipe.transform('PARTY_POKER_COMMON_ERROR');
                    const messageTemplate = this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_MAXTABLESINFOMSG');
                    const message = messageTemplate.replace('%1n', this.miniTableCount.toString()).replace('%2n', remainingTables.toString());

                    const buttons = [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }];
                    this.openErrorDialog({ title, message, buttons });
                }
            } else if (this.selectedBuyIn * this.gameCount > this.userBalance) {
                this.openErrorDialog({
                    title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                    message: this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_REBUY_ErrorInsufficientFunds'),
                    buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
                });
            } else {
                if (this.table['MESSAGE_NAME'] === 'PoolAvailabilityResponse') {
                    this.tableValues.emit({
                        autoBuyIn: this.isAutoBuyInTrue,
                        selectedBuyIn: this.selectedBuyIn * 100,
                        noOfGamesSelected: this.gameCount,
                    });
                } else {
                    this.tableValues.emit({
                        autoBuyIn: this.isAutoBuyInTrue,
                        selectedBuyIn: this.selectedBuyIn,
                        noOfGamesSelected: this.gameCount,
                    });
                }
                // this.tableValues.emit({
                //     autoBuyIn: this.isAutoBuyInTrue,
                //     selectedBuyIn: this.selectedBuyIn,
                //     noOfGamesSelected: this.gameCount,
                // });
                this.closePopup();
            }
        }
        this.closePopup();
    }

    startCountdown(duration: number) {
        let timeLeft = duration;
        this.buyInInterval = setInterval(() => {
            if (timeLeft <= 0) {
                clearInterval(this.buyInInterval);
                this.closePopup();
            }
            timeLeft -= 1;
            this.buyInProgressBarWidth = Number(((timeLeft * 100) / duration).toFixed());
            this.durationTimerLeftInSec = timeLeft;
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.buyInInterval);
    }

    getTitle(minBuyIn: any, maxBuyIn: any) {
        this.getBuyInMinMaxValues(minBuyIn, maxBuyIn);
        const buyInValue =
            minBuyIn === maxBuyIn
                ? `[$${this.sliderMinValue} Buy-in]`
                : maxBuyIn === 0
                  ? `[$${this.sliderMinValue} Buy-in]`
                  : `[$${this.sliderMinValue}-$${this.sliderMaxValue} Buy-in]`;
        this.buyInTitle = `${this.getSeaterText(this.table)} ${this.getLimitWithGameValue(this.table)} ${this.checkIfHiLoGame(this.table.gameTypeId)} ${this.getGameTypeString(this.table)} ${buyInValue}`;
    }

    getBuyInMinMaxValues(minBuyIn: any, maxBuyIn: any) {
        this.showBuyInRange = minBuyIn != maxBuyIn && maxBuyIn > minBuyIn;
        this.sliderMinValue = (minBuyIn / 100).toString().split('.').length > 1 ? Number((minBuyIn / 100).toFixed(2)) : minBuyIn / 100;
        this.sliderMaxValue = (maxBuyIn / 100).toString().split('.').length > 1 ? Number((maxBuyIn / 100).toFixed(2)) : maxBuyIn / 100;
        this.selectedBuyIn =
            minBuyIn != maxBuyIn && maxBuyIn > minBuyIn
                ? this.sliderMaxValue
                : this.isAutorebuyin === true && this.sliderMinValue > this.sliderMaxValue
                  ? this.sliderMaxValue
                  : this.sliderMinValue;
    }

    getLimitTypeAndGameType(limitType: number, gameType: number) {
        let lType, gType;

        if (this.table['MESSAGE_NAME'] === 'PoolAvailabilityResponse') {
            lType = limitType == 1 ? 'No Limit' : 'Pot Limit';
            gType = gameType == 0 ? 'Hold’em' : 'Omaha';
        } else {
            lType = limitType === 0 ? 'Fixed Limit' : limitType === 1 ? 'No Limit' : 'Pot Limit';

            switch (gameType) {
                case 0:
                case 6:
                case 9:
                case 10:
                    gType = "Hold'em";
                    break;
                case 1:
                case 2:
                case 3:
                case 4:
                    gType = 'Omaha';
                    break;
                case 7:
                    gType = 'Short Deck';
                    break;
            }
        }
        return lType + ' ' + gType;
    }

    getInfoMessage(realNameTable: boolean, maxSeats) {
        this.showInfoAlert = true;
        if (realNameTable === true && maxSeats === 2) {
            this.showInfoAlertText = this.translatePipe.transform('PARTY_POKER_GC_BUYINCHIPS_REALNAME_INFOMSG');
        } else if (realNameTable === true && maxSeats != 2) {
            this.showInfoAlertText = this.translatePipe.transform('PARTY_POKER_GC_COMMON_REALCASH_REALNAME_NOTE');
        } else if (realNameTable === false && maxSeats === 2) {
            this.showInfoAlertText = this.translatePipe.transform('PARTY_POKER_GC_COMMON_REALCASH_HANDSUP_NOTE');
        } else {
            this.showInfoAlert = false;
            this.showInfoAlertText = '';
        }
    }

    ResponseQuickSeatRingError(message) {
        this.logger.error('server message response..!' + JSON.stringify(message));
        const errorCods = {
            '119': ['PARTY_POKER_GC_QSLOBBY_TABLESLESSTHANREQUESTED'],
            '115': ['PARTY_POKER_GC_QSLOBBY_TABLESLESSTHANREQUESTED'],
            '121': ['PARTY_POKER_GS_CFRCOOLOFFPARALLELGAMEPLAYRESTRICTED'],
            '124': ['PARTY_POKER_GS_COOLDOWNLIMITREMAINING'],
            '125': ['PARTY_POKER_GC_GAMELOBBY_DYNAMIC_lANG_PACK_PARALLEL_GAMEPLAY_RESTRICTED_ERROR'],
            '-50': ['PARTY_POKER_AN_GEN_JOIN_MTT_FRAUD_KICKOUT_ERR'],
            '-53': ['PARTY_COMMON_GS_SERVICECLOSURE_PERMANENTSELFEXCLUSION'],
            '-54': ['PARTY_COMMON_GS_SERVICECLOSURE_SELFEXCLUSIONMSG'],
            '-61': ['PARTY_COMMON_GS_SERVICECLOSURE_MANUALANDORPLYRBLCKED'],
            '-62': ['PARTY_COMMON_GS_SERVICECLOSURE_PRODUCTCLOSEDBYPLAYER'],
            '-63': ['PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem'],
            '-51': ['PARTY_POKER_GS_LOSSLIMITS_CHECKMSG', 'PARTY_POKER_MC_ResponsibleGaming_DAILY'],
            '-52': ['PARTY_POKER_GS_LOSSLIMITS_CHECKMSG', 'PARTY_POKER_MC_ResponsibleGaming_MONTHLY'],
            '-65': ['PARTY_POKER_GS_LOSSLIMITS_CHECKMSG', 'PARTY_POKER_MC_ResponsibleGaming_WEEKLY'],
            // '70': ['PARTY_POKER_GS_LOSSLIMITS_WARNINGMSG', 'PARTY_POKER_MC_ResponsibleGaming_DAILY', `$${data.detail.responseErrorValue}`],
            // '71': ['PARTY_POKER_GS_LOSSLIMITS_WARNINGMSG', 'PARTY_POKER_MC_ResponsibleGaming_MONTHLY', `$${data.detail.responseErrorValue}`],
            // '72': ['PARTY_POKER_GS_LOSSLIMITS_WARNINGMSG', 'PARTY_POKER_MC_ResponsibleGaming_MONTHLY', `$${data.detail.responseErrorValue}`],
            '201': ['PARTY_POKER_GC_GAMETABLE_TBLSOONCLSMAINJOINANOTHERTBL'],
            '197': ['PARTY_POKER_GC_GAMETABLE_SEATALREADYRESVTAKEANOTHERSEAT'],
            '196': ['PARTY_POKER_GC_GAMETABLE_SEATALREADYRESTAKEANOTHERSEAT'],
            '114': ['PARTY_POKER_GC_TABLE_HUMSG'],
            '235': ['PARTY_POKER_GC_COMMON_KYC_ERRORMSG'],
            '2': ['PARTY_POKER_GC_BUYINCHIPS_INSUFFFUNDSGOCASHADDFNDS'],
            '3': ['PARTY_POKER_GC_EVENTS_MTPROMOQSERROR'],
            '0000': ['PARTY_POKER_GC_SOCIAL_NS_TECHNICALISSUE'],
        };

        this.openErrorDialog({
            title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message:
                errorCods[message.detail.responseCode] != undefined
                    ? this.translatePipe.transform(errorCods[message.detail.responseCode][0])
                    : this.translatePipe.transform(errorCods['0000'][0]),
            buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_OK') }],
        });
    }

    JoinTableResponseError() {
        this.openErrorDialog({
            title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: this.translatePipe.transform('PARTY_POKER_GC_GAMECLIENT_JOINTABLEFAIL'),
            buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_OK') }],
        });
    }

    openErrorDialog(data: CashErrorDialogModel) {
        this.dialog.open(PWCErrorDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }

    showAutoReBuyInfo(event: MouseEvent) {
        this.isBalanceInfoEnabled = false;
        event.stopPropagation();
        this.autoReBuyInfoPopUp = !this.autoReBuyInfoPopUp;
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        const target = event.target as HTMLElement;
        const container = this.elRef.nativeElement as HTMLElement;

        // Check if the clicked element is within the component's container
        if (container.contains(target)) {
            // Close the appropriate popup if it's opened and the click occurred outside it
            if (this.autoReBuyInfoPopUp && !target.classList.contains('autoReBuyInfo')) {
                this.autoReBuyInfoPopUp = false;
            }
            if (this.isBalanceInfoEnabled && !target.classList.contains('balance-info-popup')) {
                this.isBalanceInfoEnabled = false;
            }
        }
    }

    goToCashier() {
        this.clientToServer.openDepositPage('deposit');
    }
}
