/* eslint-disable no-console */
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NavigationService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';
import Swiper from 'swiper';
import { Autoplay, EffectCoverflow, Pagination, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { CashFFBuyInPopUpComponent } from '../../common/cash-ff-buyin/cash-ff-buyIn.component';
import { DeBalancePopUpComponent } from '../../common/de-table-balance/de-balance-pop-up.component';
import { PWCErrorDialogComponent } from '../../common/error-dialog/pwc-error-dialog.component';
import { FFLobbyTileComponent } from '../../common/ff-lobby-tile/ff-lobby-tile.component';
import { FFFiltersEventBusService } from '../../common/lobby-filters/services/ff-filters-event-bus.service';
import { LobbyTileInfoComponent } from '../../common/lobby-tile-info/lobby-tile-info.component';
import { SubLobbyHeaderComponent } from '../../common/sub-lobby-header/sub-lobby-header.component';
import { CashErrorDialogModel } from '../../models/cash-error-dialog.model';
import { CardInfo } from '../../models/tile-card-info.model';
// import { LSPoolInfoDetails } from '../../pgConnectors/server/common/pokerLobbyMessageFactory/messages/LSPoolInfoDetails';
import { PoolAvailabilityResponse } from '../../pgConnectors/server/common/pokerMessageFactory/messages/PoolAvailabilityResponse';
import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { PokerWebClientService } from '../../poker-web-client.service';
import { FxRateHelperClassService } from '../../service/Fxratemanager';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { GameTableNotificationService } from '../../service/game-table-notification.service';
import { PokerWebClientLoginService } from '../../service/poker-web-client-login.service';
import { ffLobbyDataSelect } from '../../store/lobby.store';
import { userBalanceSelect, userIsAuthenticatedSelect } from '../../store/user.store';
import { FastForwardService } from './ff-lobby.service';

@Component({
    selector: 'pwc-fast-forward-lobby',
    templateUrl: 'ff-lobby.component.html',
    imports: [
        CommonModule,
        SubLobbyHeaderComponent,
        FFLobbyTileComponent,
        LobbyTileInfoComponent,
        CashFFBuyInPopUpComponent,
        TranslatePipe,
        SwiperComponent,
        formatCurrencyPipe,
        PWCErrorDialogComponent,
        DeBalancePopUpComponent,
    ],
    providers: [FastForwardService, formatCurrencyPipe, TranslatePipe, GameTableNotificationService],
    standalone: true,
})
export class FastForwardLobbyComponent implements OnInit {
    public isAutoreBuyin = false;
    isLoggedIn = false;
    public gridView = true;
    public cardOrderBy = 'asc';
    public selectedTableIndex = 0;
    public selectedTable: any;
    tileLeastBuyinValue: number;
    tileMaxBuyinValue: number;
    totalBuyIn: number;
    public currentLobbyInfo: any;
    public uniqWinUptos: any;
    finalData = [] as any;
    public gameTypes = {
        'All': true,
        'Hold’em': true,
        'Omaha': true,
    };
    isDePopupEnabled = false;
    deBalanceInfo;
    popupIsOpen = false;
    buyInData: any;
    public lobbySwaiperConfig: SwiperOptions = {
        modules: [Pagination, EffectCoverflow, Autoplay, Virtual],
        direction: 'horizontal',
        effect: 'coverflow',
        centeredSlides: true,
        spaceBetween: 50,
        slidesPerView: 1.9,
        loop: true,
        navigation: true,
        autoplay: false,
        virtual: {
            addSlidesBefore: 2,
            addSlidesAfter: 5,
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: true,
        },
        pagination: {
            el: '.swiper-pagination-cust',
            dynamicBullets: true,
            dynamicMainBullets: 6,
            clickable: true,
        },
        keyboard: true,
        rewind: true,
        observer: true,
        on: {
            click: (swiper: Swiper, event: any) => {
                this.onSlideClick(swiper, event);
            },
        },
    };
    isSwiperInit: boolean = false;
    @ViewChild('SwiperComponent', { static: false })
    componentRef?: SwiperComponent;
    private readonly logger: PokerLogger;
    //translatePipe: any;
    public userBalance = 0;

    private eventDispatcherGlobal = new EventDispatcherGlobal();
    @ViewChild('gridContainer', { read: ElementRef }) gridContainer: ElementRef;

    constructor(
        private dialog: MatDialog,
        public fastForwardService: FastForwardService,
        private shareService: PokerWebClientSharedService,
        private navigation: NavigationService,
        private ffFiltersEventBusService: FFFiltersEventBusService,
        private store: Store,
        private translatePipe: TranslatePipe,
        private clientToServer: ClientToServerRequestServices,
        loggerFactory: LoggerFactory,
        private formatCurrencyPipe: formatCurrencyPipe,
        private pokerWebClientLoginService: PokerWebClientLoginService,
        private gameTableNotificationService: GameTableNotificationService,
        private pokerWebClientService: PokerWebClientService,
    ) {
        this.eventDispatcherGlobal.addEventListener('PoolAvailabilityResponseForFF', this.poolAvailabilityResponse.bind(this));
        this.eventDispatcherGlobal.addEventListener('JoinPoolResponseError', this.joinPoolResponseError.bind(this));

        // this.clientToServer.updatePageTitle("fast forward games");
        this.clientToServer.SendLobbyUpdateRequest(true, 1, 'FF');
        this.logger = loggerFactory.getLogger('fastForwardLobbyLoggingComponent');
        this.store.select(userIsAuthenticatedSelect).subscribe((data) => {
            this.isLoggedIn = data;
        });
        this.getLobbyData();
        this.showInitialGameType(JSON.parse(localStorage.getItem('fastForwardCardsFilter') ?? '{}'));
    }

    ngOnInit() {
        this.store.select(userBalanceSelect).subscribe((data) => {
            if (!_.isEmpty(data) && !_.isEmpty(data['netRealBalance'])) {
                let conversionFactor4balance = FxRateHelperClassService.getInstance().getFxRateWithOutSnapShotId(
                    data['netRealBalance']['accountCurrency'],
                    'USD',
                    'cashgames',
                );
                this.userBalance =
                    data['netRealBalance']['accountBalance'] * conversionFactor4balance +
                    (data['tourneyCurrencyBalance'] != null ? data['tourneyCurrencyBalance']['tourneyCurrencyBalance'] : 0);
            }
        });
        const currentURL = window.location.pathname;
        this.currentLobbyInfo = _.find(this.shareService.lobbyPromotions.lobbyPromotions.lobbyPromotions, (obj) => {
            return currentURL.includes(obj.gameType);
        });
        console.log('ls..currentLobbyInfo..', this.currentLobbyInfo);
        this.shareService.setPageHeaderTitle({ title: this.currentLobbyInfo.title, url: '/play', showBalanceBlock: true });
        this.deBalanceInfo = this.pokerWebClientService.getTableBalanceLimit();
        this.getDeTableBalancePopUp();
    }

    getDeTableBalancePopUp() {
        // if (this.appConfig.ENABLE_TABLE_BALANCE_POPUP) { (this.AppDataModel.getTableBalanceLimit()!=0)

        // }
        if (localStorage.getItem('DO_NOT_SHOW_BALANCE_REDUCTION_POPUP') != '1' && this.pokerWebClientService.getTableBalanceLimit() != 0) {
            const resetobj = {
                detail: {
                    balanceLimit: 0,
                },
            };
            this.isDePopupEnabled = true;
            this.pokerWebClientService.setTableBalanceLimit(resetobj);
        }
    }

    getLobbyData() {
        this.store.select(ffLobbyDataSelect).subscribe((data) => {
            this.updateBuyinFilters(data);
            if (data.length != 0) {
                this.finalData = this.ffFiltersEventBusService.filterFastForwardCards(data);
                if (this.finalData?.length <= 10 && this.lobbySwaiperConfig) {
                    this.lobbySwaiperConfig.pagination = {
                        el: '.swiper-pagination-cust',
                        dynamicBullets: true,
                        clickable: true,
                    };
                    this.lobbySwaiperConfig.virtual = false;
                }
                this.finalData = _.sortBy(this.finalData as [], [(obj: any) => obj.formattedBuyIn]);
                this.logger.info(' cardsData in ff lobby ' + JSON.stringify(this.finalData));
                this.selectedCard(0, this.finalData[this.selectedTableIndex]);
            } else {
                // this.clientToServer.requestForLobbyData(true,1,'FF');
                this.finalData = [];
                this.logger.info(' cardsData in ff lobby ' + JSON.stringify(this.finalData));
            }
            // console.log('data... finalData..', data);
        });
    }
    updateBuyinFilters(data) {
        this.uniqWinUptos = _.sortBy(_.uniq(_.map(data, (o) => o.formattedBuyIn)));
        const fastForwardCardsFilter = JSON.parse(localStorage.getItem('fastForwardCardsFilter') ?? '{}');
        const selectedGameTypes = fastForwardCardsFilter.gameTypes;
        const selectedBuyIn = fastForwardCardsFilter.winUptos;
        if (selectedGameTypes.length == 0 && selectedBuyIn.length == 0) {
            for (const key in this.gameTypes) {
                if (this.gameTypes[key]) {
                    selectedGameTypes.push(key);
                }
            }
        }
        const obj: any = {
            gameTypes: selectedGameTypes,
            winUptos: selectedBuyIn.length == 0 ? this.uniqWinUptos : selectedBuyIn,
        };
        localStorage.setItem('fastForwardCardsFilter', JSON.stringify(obj));
    }
    checkSwiper(swiper: any) {
        if (swiper.initialized) {
            this.isSwiperInit = true;
        }
    }
    isItGridViewType(e: boolean) {
        // console.log('clicked on Grid Icon..!', e);
        this.gridView = e;
        this.logger.info('clicked on Grid Icon..!' + JSON.stringify(e));
        // const globalThisValue = this;
        if (!this.gridView) {
            this.setSwiperBuyinValues(this.finalData);
        }
        setTimeout(() => {
            if (this.componentRef) {
                if (!this.gridView) {
                    this.componentRef.swiper.slideToLoop(this.selectedTableIndex);
                }
                this.componentRef.swiper.on(
                    'slideChange',
                    function (swiperRef) {
                        this.selectedCard(swiperRef.realIndex, this.finalData[swiperRef.realIndex]);
                    }.bind(this),
                );
            }
        }, 200);
        if (this.gridView) {
            setTimeout(() => {
                const selectedElement = this.gridContainer.nativeElement.querySelector(`.card-${this.selectedTableIndex}`);
                const gridViewContainer = selectedElement.closest('.sub-lobby-content');
                if (selectedElement) {
                    gridViewContainer.scroll({
                        behavior: 'smooth',
                        top: selectedElement.offsetTop - gridViewContainer.offsetTop,
                    });
                }
            }, 100);
        }
    }

    openInfoPopup(e: any) {
        this.logger.info('clicked on info popup icon' + JSON.stringify(e));
        const data: CardInfo = {
            viewType: 1,
            headerImageUrl: `${this.currentLobbyInfo.infoActorImage.src}`,
            description: `${this.currentLobbyInfo.infoSubDescription}`,
            cardDetails: {
                game: this.getLimitTypeAndGameType(this.selectedTable.getLimitType(), this.selectedTable.getGameType()),
                format: this.translatePipe.transform('PARTY_POKER_GC_SHORTDECKQL_CASHGAMEVAL'),
                blinds:
                    this.formatCurrencyPipe.transform(this.selectedTable.getSmallBlindAmount(), this.selectedTable.getGameCurrency() ?? '') +
                    '/' +
                    this.formatCurrencyPipe.transform(this.selectedTable.getBigBlindAmount(), this.selectedTable.getGameCurrency() ?? ''),
                // `${this.selectedTable.gameCurrency} ` +
                // `${this.selectedTable.smallBlindAmount / 100}/` +
                // `${this.selectedTable.gameCurrency} ` +
                // `${this.selectedTable.bigBlindAmount / 100}`,
                type: `${this.selectedTable.getNoOfSeats()} ` + this.translatePipe.transform('PARTY_POKER_MOBILE_LOBBY_DE_Max'), //`${this.selectedTable.getNoOfSeats() === 2 ? '-Heads Up' : '-Max'}`,
            },
            ctaButtons: [this.currentLobbyInfo.secondaryCTA],
        };
        this.dialog.open(LobbyTileInfoComponent, {
            width: '95%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'wpc-card-info',
            hasBackdrop: true,
            backdropClass: 'wpc-card-info-bg',
            disableClose: false,
            closeOnNavigation: true,
        });
    }

    sortTables(e: any) {
        this.logger.info('Clicked on sort icon' + JSON.stringify(e));
        this.cardOrderBy = e;
        this.finalData.reverse();
        this.selectedCard(0, this.finalData[this.selectedTableIndex]);
    }

    openFilterPopup(e: any) {
        this.logger.info('Clicked on filter icon' + JSON.stringify(e));
        this.navigation.goTo('/play/filters?key=ff-filters');
        // this.router.navigate(["/play/filters"], {
        //   queryParams: { key: "ff-filters" },
        // });
    }

    selectedCard(i, card) {
        this.logger.info('Clicked on selected cards' + JSON.stringify(i, card));
        this.selectedTableIndex = i;
        this.selectedTable = card;
        this.totalBuyIn = card?.maxBuyInAmount / 100;
    }

    onClickPlayNowButton() {
        this.logger.info('clicked on playnow');
        if (this.isLoggedIn) {
            if (this.userBalance / 100 >= this.selectedTable?.minBuyInAmount / 100) {
                this.gameTableNotificationService.poolAvailabilityRequest(this.selectedTable.getPoolId(), []);
            } else {
                this.openErrorDialog({
                    title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                    message: this.translatePipe.transform('PARTY_POKER_GC_BUYINCHIPS_INSUFFFUNDSGOCASHADDFNDS'),
                    buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
                });
            }
        } else {
            this.pokerWebClientLoginService.requestForPortalLogin();
        }
    }

    getLimitTypeAndGameType(limitType: number, gameType: number) {
        const lType = limitType == 1 ? 'No Limit' : 'Pot Limit';
        const gType = gameType == 0 ? 'Hold’em' : 'Omaha';
        return lType + ' ' + gType;
    }
    showInitialGameType(cgFilter) {
        if (cgFilter.gameTypes.length == 3 || (cgFilter.gameTypes.length == 0 && cgFilter.winUptos.length == 0)) {
            this.gameTypes['All'] = true;
            this.gameTypes['Omaha'] = true;
            this.gameTypes['Hold’em'] = true;
        } else if (cgFilter.gameTypes.length == 0) {
            this.gameTypes['All'] = false;
            this.gameTypes['Omaha'] = false;
            this.gameTypes['Hold’em'] = false;
        }

        if (cgFilter.gameTypes.includes('Omaha')) {
            this.gameTypes['Omaha'] = true;
            this.gameTypes['All'] = false;
        } else {
            if (cgFilter.gameTypes.length != 0) this.gameTypes['Omaha'] = false;
        }

        if (cgFilter.gameTypes.includes('Hold’em')) {
            this.gameTypes['Hold’em'] = true;
            this.gameTypes['All'] = false;
        } else {
            if (cgFilter.gameTypes.length != 0) this.gameTypes['Hold’em'] = false;
        }
        if (this.gameTypes['Hold’em'] && this.gameTypes['Omaha']) {
            this.gameTypes['All'] = true;
        }
    }

    poolAvailabilityResponse(data: PoolAvailabilityResponse) {
        this.logger.info('Buyindata for fflobby received is ' + JSON.stringify(data));
        // console.log('@@..poolAvailabilityResponse...', data);
        let errorMessage = '';
        switch (data['detail'].getPoolAvailabiltyStatus()) {
            case 0: //POKER_POOL_AVAILABLE
                this.openBuyInPopUp(data['detail']);
                return;
            case 4: //USER_NOT_REAL_OR_PSEUDO_REAL
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
                break;
            case 2601: //USER_BLOCKED_REALITY_CHECK
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
                break;
            case 2602: //R_GAME_SESSION_TIMEDOUT
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_PREREGCONDITION_FreerollRegTourErrMsg');
                break;
            default:
                errorMessage = this.translatePipe.transform('PARTY_POKER_AN_GEN_ANMISSING7_JoinPoolFailedNotAvailableErr');
        }
        if (errorMessage != '') {
            // window.alert(errorMessage);
            this.openErrorDialog({
                title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                message: errorMessage,
                buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_OK') }],
            });
        }
    }
    openBuyInPopUp(data) {
        this.logger.info('Clicked on Playnow ' + JSON.stringify(data));
        this.popupIsOpen = true;
        this.buyInData = data;
    }

    closePopup() {
        this.logger.info('clicked on close popup icon...! ');
        this.popupIsOpen = false;
    }

    onBuyInButtonClick(values: any) {
        this.logger.info('buyInData..' + JSON.stringify(values));
        // console.log('buyInData..', values);
        const validSeatNumbers = [2, 3, 6, 7, 8];
        if (!validSeatNumbers.includes(this.selectedTable.getNoOfSeats())) {
            this.openErrorDialog({
                title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                message: 'We do not support ' + this.selectedTable.getNoOfSeats() + ' Seater Tables at the moment.',
                buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
            });
        } else {
            this.logger.info('buyInData ...' + values);
            this.gameTableNotificationService.joinPoolRequest(this.selectedTable.getPoolId(), values);
        }
    }

    openErrorDialog(data: CashErrorDialogModel) {
        this.dialog.open(PWCErrorDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }
    updateGameType(data) {
        const filterData = JSON.parse(localStorage.getItem('fastForwardCardsFilter') ?? '');
        filterData.gameTypes = data;
        localStorage.setItem('fastForwardCardsFilter', JSON.stringify(filterData));
        this.getLobbyData();
    }
    closeGermanBalanceInfoPopup() {
        this.isDePopupEnabled = false;
    }
    setSwiperBuyinValues(cardsData) {
        this.tileLeastBuyinValue = cardsData[0]['maxBuyInAmount'] / 100;
        this.tileMaxBuyinValue = cardsData[cardsData.length - 1]['maxBuyInAmount'] / 100;
    }

    joinPoolResponseError(errorMessage) {
        this.openErrorDialog({
            title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: errorMessage.detail,
            buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_OK') }],
        });
    }

    onSlideClick(swiper: Swiper, event: any) {
        let clickPosition: any;
        let clickedSlideNext: any = document.querySelector<HTMLDivElement>('.swiper-slide-next');
        let clickedSlidePrev: any = document.querySelector<HTMLDivElement>('.swiper-slide-prev');

        if (event instanceof MouseEvent) {
            clickPosition = swiper.clickedSlide?.dataset.swiperSlideIndex;
        } else if (event instanceof TouchEvent && swiper.clickedIndex >= 0) {
            clickPosition = swiper.clickedSlide?.dataset.swiperSlideIndex;
        } else {
            console.error('Event type not supported for determining click position');
            return;
        }
        const pervSelectNumber: any = clickedSlidePrev?.dataset.swiperSlideIndex;
        const nextSelectNumber: any = clickedSlideNext?.dataset.swiperSlideIndex;

        clickPosition = parseInt(clickPosition);
        if (clickPosition === parseInt(pervSelectNumber)) {
            swiper.slidePrev();
        } else if (clickPosition === parseInt(nextSelectNumber)) {
            swiper.slideNext();
        }
    }
}
