import { Message } from '../../message';

export class ResponseLSPlayerTourneyTabInfo extends Message {
    private MESSAGE_NAME: string = 'ResponseLSPlayerTourneyTabInfo';
    private chipsInPlay: number = 0;
    private largestStackSize: number = 0;
    private smallestStackSize: number = 0;
    private averageStackSize: number = 0;
    private numberOfPlayerRemaining: number = 0;
    private nextBreakInSecs: number = 0;
    private playerCurrentPosition: number = 0;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        chipsInPlay: number = 0,
        largestStackSize: number = 0,
        smallestStackSize: number = 0,
        averageStackSize: number = 0,
        numberOfPlayerRemaining: number = 0,
        nextBreakInSecs: number = 0,
        playerCurrentPosition: number = 0,
        tableId: number = 0,
    ) {
        super(reqServerPeerId);
        this.chipsInPlay = chipsInPlay;
        this.largestStackSize = largestStackSize;
        this.smallestStackSize = smallestStackSize;
        this.averageStackSize = averageStackSize;
        this.numberOfPlayerRemaining = numberOfPlayerRemaining;
        this.nextBreakInSecs = nextBreakInSecs;
        this.playerCurrentPosition = playerCurrentPosition;
        this.tableId = tableId;
    }

    getChipsInPlay(): number {
        return this.chipsInPlay;
    }

    setChipsInPlay(chipsInPlay: number) {
        this.chipsInPlay = chipsInPlay;
    }
    getLargestStackSize(): number {
        return this.largestStackSize;
    }

    setLargestStackSize(largestStackSize: number) {
        this.largestStackSize = largestStackSize;
    }
    getSmallestStackSize(): number {
        return this.smallestStackSize;
    }

    setSmallestStackSize(smallestStackSize: number) {
        this.smallestStackSize = smallestStackSize;
    }
    getAverageStackSize(): number {
        return this.averageStackSize;
    }

    setAverageStackSize(averageStackSize: number) {
        this.averageStackSize = averageStackSize;
    }
    getNumberOfPlayerRemaining(): number {
        return this.numberOfPlayerRemaining;
    }

    setNumberOfPlayerRemaining(numberOfPlayerRemaining: number) {
        this.numberOfPlayerRemaining = numberOfPlayerRemaining;
    }
    getNextBreakInSecs(): number {
        return this.nextBreakInSecs;
    }

    setNextBreakInSecs(nextBreakInSecs: number) {
        this.nextBreakInSecs = nextBreakInSecs;
    }
    getPlayerCurrentPosition(): number {
        return this.playerCurrentPosition;
    }

    setPlayerCurrentPosition(playerCurrentPosition: number) {
        this.playerCurrentPosition = playerCurrentPosition;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
