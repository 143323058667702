import { Message } from '../../message';

export class PromoSNGTemplate extends Message {
    private MESSAGE_NAME: string = 'PromoSNGTemplate';
    private sngName: any | null = null;
    private buyIn: number = 0;
    private tourneyFee: number = 0;
    private maxSeats: number = 0;
    private gameCurrency: string | null = null;
    private templateId: number = 0;
    private sngTemplateOrderNumber: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngName: any | null = null,
        buyIn: number = 0,
        tourneyFee: number = 0,
        maxSeats: number = 0,
        gameCurrency: string | null = null,
        templateId: number = 0,
        sngTemplateOrderNumber: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngName = sngName;
        this.buyIn = buyIn;
        this.tourneyFee = tourneyFee;
        this.maxSeats = maxSeats;
        this.gameCurrency = gameCurrency;
        this.templateId = templateId;
        this.sngTemplateOrderNumber = sngTemplateOrderNumber;
    }

    getSngName(): any | null {
        return this.sngName;
    }

    setSngName(sngName: any | null) {
        this.sngName = sngName;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getTourneyFee(): number {
        return this.tourneyFee;
    }

    setTourneyFee(tourneyFee: number) {
        this.tourneyFee = tourneyFee;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getTemplateId(): number {
        return this.templateId;
    }

    setTemplateId(templateId: number) {
        this.templateId = templateId;
    }
    getSngTemplateOrderNumber(): number {
        return this.sngTemplateOrderNumber;
    }

    setSngTemplateOrderNumber(sngTemplateOrderNumber: number) {
        this.sngTemplateOrderNumber = sngTemplateOrderNumber;
    }
}
