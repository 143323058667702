import { Message } from '../../message';

export class RCPUActionAcknowledgement extends Message {
    private MESSAGE_NAME: string = 'RCPUActionAcknowledgement';
    private accountName: string | null = null;
    private rcpuAction: string | null = null;
    private responseId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, accountName: string | null = null, rcpuAction: string | null = null, responseId: number = 0) {
        super(reqServerPeerId);
        this.accountName = accountName;
        this.rcpuAction = rcpuAction;
        this.responseId = responseId;
    }

    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getRcpuAction(): string | null {
        return this.rcpuAction;
    }

    setRcpuAction(rcpuAction: string | null) {
        this.rcpuAction = rcpuAction;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
}
