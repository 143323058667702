import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../api/api.service';
import { MyGameInputModel } from './poker-my-game.models';

@Injectable({ providedIn: 'root' })
export class MyGameDataService {
    constructor(private api: PokerApiService) {}

    getMyGameData(): Observable<any> {
        return this.api.get('mygamedataservice/GetMyGameData');
    }

    getMyGameDataModel(myGameInputModel: MyGameInputModel): Observable<any> {
        return this.api.get('mygamedataservice/GetMyGameDataModel', myGameInputModel);
    }

    getPlayerCredentials(): Observable<any> {
        return this.api.get('mygamedataservice/GetPlayerCredentials');
    }
}
