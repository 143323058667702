import { Message } from '../../message';

export class PanelMessage extends Message {
    private MESSAGE_NAME: string = 'PanelMessage';
    private panelArea: number = 0;
    private message: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, panelArea: number = 0, message: string | null = null) {
        super(reqServerPeerId);
        this.panelArea = panelArea;
        this.message = message;
    }

    getPanelArea(): number {
        return this.panelArea;
    }

    setPanelArea(panelArea: number) {
        this.panelArea = panelArea;
    }
    getMessage(): string | null {
        return this.message;
    }

    setMessage(message: string | null) {
        this.message = message;
    }
}
