<ng-container *ngFor="let count of [1, 2, 3, 4]; let i = index">
    <div class="empty-mini-table">
        <svg width="100%" height="100%" viewBox="0 0 63 37" fill="#000000" (click)="openNewGameTable($event)">
            <g opacity="1">
                <rect x="1.21002" y="1.34722" width="60.9167" height="34.6389" rx="4.67824" stroke="#D2D3D3" stroke-width="1.19444" />
                <rect x="30.3481" y="10.6665" width="2.69531" height="16" rx="1.34766" fill="#D2D3D3" />
                <rect x="23.6958" y="20.1665" width="2.74701" height="16" rx="1.37351" transform="rotate(-90 23.6958 20.1665)" fill="#D2D3D3" />
            </g>
        </svg>
        <ng-container *ngFor="let table of miniTables; let j = index">
            <div *ngIf="table && i === j" class="mini-table-content-wrapper" [class.activeTable]="table.isActive" (click)="activeMiniTable(table)">
                <svg width="100%" height="100%" viewBox="0 0 63 37" fill="#000000">
                    <g opacity="1">
                        <rect
                            x="1.21002"
                            y="1.34722"
                            width="60.9167"
                            height="34.6389"
                            rx="4.67824"
                            [attr.stroke]="table.isActive ? '#2593d8' : '#D2D3D3'"
                            stroke-width="1.19444" />
                    </g>
                </svg>
                <div class="mini-table-cards">
                    <span
                        *ngFor="let card of table.tableCards"
                        [class]="'card-' + card"
                        [ngStyle]="{ 'background-image': 'url(' + miniTableCards + ')' }"></span>
                </div>
                <div class="mini-table-text">
                    <ngb-progressbar
                        class="mini-table-progressbar"
                        *ngIf="table.progressBarValue > 0 && table.progressBarValue <= 100"
                        [type]="table.progressBarValue > 50 ? 'success' : 'danger'"
                        [ngClass]="table.progressBarValue > 50 ? 'success' : 'danger'"
                        [max]="100"
                        textType="white"
                        [value]="table.progressBarValue">
                    </ngb-progressbar>
                    <span *ngIf="table.miniTableStatus !== 1 && table.miniTableStatus !== 4">
                        ${{ table.winUpto }}{{ ' ' + table.winUptoText }}
                    </span>
                    <span *ngIf="table.miniTableStatus === 1">
                        {{ 'PARTY_POKER_GC_GAMETABLE_SITOUTOPTIONS_AWAY' | translate }}
                    </span>
                    <span *ngIf="table.miniTableStatus === 4">
                        {{ 'PARTY_POKER_COMMON_OPTIONS_FOLD' | translate }}
                    </span>
                </div>
                <!-- <div
                  [ngClass]="{'active_button':table.progressBarValue > 0 && table.progressBarValue <= 100}"
                  class="progress_div">
                  <ngb-progressbar *ngIf="table.tableStatus?.status !== 1"
                    [type]="(table.progressBarValue > 50) ? 'success' : 'danger'"
                    [value]="table.progressBarValue">
                  </ngb-progressbar>
                  <span [ngClass]="table.tableStatus?.status === 1 ? 'hideDiv':'progressive_text'">
                    {{'$' + table.winUpto + ' '}}{{table.winUptoText}}</span>
                  <p *ngIf="table.tableStatus?.status === 1 ">
                    {{'PARTY_POKER_MC_TABLE_COMMON_AWAYTEXT'|translate}}</p>
                </div> -->
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="isNewGameTableOpen">
    <div class="similar-otherGame-wrapper" (click)="openNewGameTable($event)">
        <div class="similar-otherGame">
            <button class="btn-similar" (click)="addSimilarGame()">{{ similarGameText }}</button>
            <button class="btn-other" (click)="showOtherGames($event)">{{ 'PARTY_POKER_MOBILE_Fast Forward_OTHERGAME' | translate }}</button>
            <span class="close-similar theme-ex" (click)="openNewGameTable($event)"></span>
            <div *ngIf="isOtherGame" class="otherGames-wrapper">
                <h4>{{ 'PARTY_POKER_MOBILE_Fast Forward_NEWGAME' | translate }}</h4>
                <div>
                    <img src="{{ getAssets('spins_overdrive') }}" alt="spins-overdrive" (click)="goToLobby('spins-overdrive')" />
                    <img src="{{ getAssets('ff-game') }}" alt="ff-games" (click)="goToLobby('fast-forward')" />
                    <img src="{{ getAssets('cash-game') }}" alt="cash-games" (click)="goToLobby('cash')" />
                </div>
            </div>
        </div>
    </div>
</ng-container>
