import { Message } from '../../message';

export class ResponseGroupCashDetailsList extends Message {
    private MESSAGE_NAME: string = 'ResponseGroupCashDetailsList';
    private tabIdTableInfoDetailsMap: Map<any, any> | null = null;
    private clearAllTabIdSnapShots: boolean | null = false;
    private tabIdTableDetailsMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tabIdTableInfoDetailsMap: Map<any, any> | null = null,
        clearAllTabIdSnapShots: boolean | null = false,
        tabIdTableDetailsMap: Map<any, any> | null = null,
    ) {
        super(reqServerPeerId);
        this.tabIdTableInfoDetailsMap = tabIdTableInfoDetailsMap;
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
        this.tabIdTableDetailsMap = tabIdTableDetailsMap;
    }

    getTabIdTableInfoDetailsMap(): Map<any, any> | null {
        return this.tabIdTableInfoDetailsMap;
    }

    setTabIdTableInfoDetailsMap(tabIdTableInfoDetailsMap: Map<any, any> | null) {
        this.tabIdTableInfoDetailsMap = tabIdTableInfoDetailsMap;
    }
    getClearAllTabIdSnapShots(): boolean | null {
        return this.clearAllTabIdSnapShots;
    }

    setClearAllTabIdSnapShots(clearAllTabIdSnapShots: boolean | null) {
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
    }
    getTabIdTableDetailsMap(): Map<any, any> | null {
        return this.tabIdTableDetailsMap;
    }

    setTabIdTableDetailsMap(tabIdTableDetailsMap: Map<any, any> | null) {
        this.tabIdTableDetailsMap = tabIdTableDetailsMap;
    }
}
