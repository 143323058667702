import { Message } from '../../message';

export class PlayerGameSessionData extends Message {
    private MESSAGE_NAME: string = 'PlayerGameSessionData';
    private accountName: string | null = null;
    private tableOrPoolId: number = 0;
    private entryId: number = 0;
    private totalBetInGC: number = 0;
    private totalWinInGC: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        accountName: string | null = null,
        tableOrPoolId: number = 0,
        entryId: number = 0,
        totalBetInGC: number = 0,
        totalWinInGC: number = 0,
    ) {
        super(reqServerPeerId);
        this.accountName = accountName;
        this.tableOrPoolId = tableOrPoolId;
        this.entryId = entryId;
        this.totalBetInGC = totalBetInGC;
        this.totalWinInGC = totalWinInGC;
    }

    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getTableOrPoolId(): number {
        return this.tableOrPoolId;
    }

    setTableOrPoolId(tableOrPoolId: number) {
        this.tableOrPoolId = tableOrPoolId;
    }
    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getTotalBetInGC(): number {
        return this.totalBetInGC;
    }

    setTotalBetInGC(totalBetInGC: number) {
        this.totalBetInGC = totalBetInGC;
    }
    getTotalWinInGC(): number {
        return this.totalWinInGC;
    }

    setTotalWinInGC(totalWinInGC: number) {
        this.totalWinInGC = totalWinInGC;
    }
}
