import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterEvents',
    pure: false,
    standalone: true,
})
export class FilterEventsPipe implements PipeTransform {
    transform(value: any, category: any[], location: any[], venue: any[]): any {
        const data = value;
        // if (!data) return [];
        if (category.length === 0 && location.length === 0 && venue.length === 0) {
            return data;
        } else if (category.length > 0 && location.length > 0 && venue.length > 0) {
            const c = data.filter((item: any) => category.includes(item.pplEventConfigContent.eventCategory));
            const l = c.filter((item: any) => location.includes(item.pplEventConfigContent.eventLocation));
            return l.filter((item: any) => venue.includes(item.pplEventConfigContent.eventVenue));
        } else if (category.length > 0 && location.length > 0) {
            const c = data.filter((item: any) => category.includes(item.pplEventConfigContent.eventCategory));
            return c.filter((item: any) => location.includes(item.pplEventConfigContent.eventLocation));
        } else if (category.length > 0 && venue.length > 0) {
            const c = data.filter((item: any) => category.includes(item.pplEventConfigContent.eventCategory));
            return c.filter((item: any) => venue.includes(item.pplEventConfigContent.eventVenue));
        } else if (location.length > 0 && venue.length > 0) {
            const l = data.filter((item: any) => location.includes(item.pplEventConfigContent.eventLocation));
            return l.filter((item: any) => venue.includes(item.pplEventConfigContent.eventVenue));
        } else if (category.length > 0) {
            return data.filter((item: any) => category.includes(item.pplEventConfigContent.eventCategory));
        } else if (location.length > 0) {
            return data.filter((item: any) => location.includes(item.pplEventConfigContent.eventLocation));
        } else if (venue.length > 0) {
            return data.filter((item: any) => venue.includes(item.pplEventConfigContent.eventVenue));
        }
    }
}
