import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as ppt from './pp-teaser-widget.component';

@Injectable({ providedIn: 'root' })
export class PPTeaserWidgetPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('ppteaserwidget', ppt.PPTeaserWidgetComponent);
        });
    }
}
