import { Message } from '../../message';

export class SearchResultForPlayer extends Message {
    private MESSAGE_NAME: string = 'SearchResultForPlayer';
    private screenName: string | null = null;
    private foundTables: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenName: string | null = null, foundTables: any[] | null = null) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.foundTables = foundTables;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getFoundTables(): any[] | null {
        return this.foundTables;
    }

    setFoundTables(foundTables: any[] | null) {
        this.foundTables = foundTables;
    }
}
