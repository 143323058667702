import { Message } from '../../message';

export class RequestToStayORLogOutOnUserAction extends Message {
    private MESSAGE_NAME: string = 'RequestToStayORLogOutOnUserAction';
    private timeLeftInSec: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, timeLeftInSec: number = 0) {
        super(reqServerPeerId);
        this.timeLeftInSec = timeLeftInSec;
    }

    getTimeLeftInSec(): number {
        return this.timeLeftInSec;
    }

    setTimeLeftInSec(timeLeftInSec: number) {
        this.timeLeftInSec = timeLeftInSec;
    }
}
