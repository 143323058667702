import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class BigWinService {
    constructor(private api: PokerApiService) {}

    getInitValues(): Observable<any> {
        return this.api.get(`bigwin/GetInitValues`);
    }
}
