import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class PPCashierOptionsService {
    constructor(private api: PokerApiService) {}

    getCashierOptions(country?: string, type?: string, currency?: string, language?: string): Observable<any> {
        return this.api.get('CashierOptions/GetCashierOptions', { country: country, type: type, currency: currency, language: language });
    }

    getCashierOptionsFaq(country?: string, type?: string, option?: string, currency?: string, language?: string): Observable<any> {
        return this.api.get('CashierOptions/GetCashierOptionsFaq', {
            country: country,
            type: type,
            option: option,
            currency: currency,
            language: language,
        });
    }
}
