import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { DynamicHtmlDirective, NavigationService, Page, PlainLinkComponent } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { PageNotFoundComponent, PokerApiService, PokerHomePageClientConfig, PromotionsClientConfig } from '@pokercore/module/core';

@Component({
    selector: 'pk-poker-publicpage-promotions',
    templateUrl: 'poker-promotions.publicpage.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        RouterModule,
        PageMatrixComponent,
        PageNotFoundComponent,
        DynamicHtmlDirective,
        TrustAsHtmlPipe,
        PlainLinkComponent,
    ],
})
export class PokerPromotionsPublicPageComponent implements OnInit, OnChanges {
    @Input() initData: any;
    promoInitData: any;
    pokerPromotionsInitData: any;
    promoItem: string;
    menuItems: any;
    menuItem: any;
    displaySideMenuBar: boolean;
    relatedPromos: any;
    lang: string;
    promoListEx: any;
    currentRelativeUrl: string;
    showConfigureNavigation: boolean = false;
    constructor(
        private api: PokerApiService,
        private navigation: NavigationService,
        private page: Page,
        private titleService: Title,
        private metaService: Meta,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private promotionsClientConfig: PromotionsClientConfig,
    ) {}

    ngOnInit(): void {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.lang = this.page.lang;
        const el = document.getElementById('main-content') as HTMLElement;
        el.className = 'fixed-width';
        //this.promoInitData = this.routeData.getInitData();
        this.promoInitData = this.initData;
        //if (!this.promoInitData.notFound && this.promoInitData.promoModel.pokerPromotionsSitecoreInfo.heroImage)         {
        if (!this.promoInitData.notFound && this.promoInitData.heroImage) {
            this.pokerPromotionsInitData = this.promoInitData; //.promoModel.pokerPromotionsSitecoreInfo;
            this.promoItem = this.promoInitData.promoItem; // nothing//undefined
            this.menuItems = this.promoInitData.menuContent; // nothing//undefined
            if (this.promoInitData.menuContent && this.promoInitData.menuContent.length > 0) {
                this.menuItem = this.promoInitData.menuContent[0];
                this.menuItem.title = this.menuItem.text;
                if (this.promoInitData.menuContent.length > 1) {
                    const menuItems: any[] = [];
                    for (let i = 1; i < this.promoInitData.menuContent.length; i++) {
                        menuItems.push(this.promoInitData.menuContent[i]);
                    }
                    this.menuItem.menu = {
                        children: menuItems,
                    };

                    this.menuItem.parameters = {
                        template: 'pc menu',
                    };
                    this.displaySideMenuBar = true;
                }
            }

            this.relatedPromos = this.promoInitData.relatedPromos;
            this.promoListEx = this.pokerPromotionsInitData.promoListEx;
            this.titleService.setTitle(this.pokerPromotionsInitData.seoTitle);
            this.metaService.updateTag({ name: 'description', content: this.pokerPromotionsInitData.description });
            // this.route.data.subscribe((routeinitdata: any) => {
            //     this.promoInitData = routeinitdata.initData;
            //     if (!this.promoInitData.heroImage) {
            //         this.navigation.goTo('/promotions');
            //     }
            //     this.pokerPromotionsInitData = this.promoInitData;
            //     this.promoItem = this.promoInitData.promoItem;
            //     this.menuItems = this.promoInitData.menuContent;

            //     this.pokerPromotionsInitData = this.promoInitData;
            //     this.promoItem = this.promoInitData.promoItem;
            //     this.menuItems = this.promoInitData.menuContent;
            //     if (this.promoInitData.menuContent && this.promoInitData.menuContent.length > 0) {
            //         this.menuItem = this.promoInitData.menuContent[0];
            //         this.menuItem.title = this.menuItem.text;
            //         if (this.promoInitData.menuContent.length > 1) {
            //             let menuItems: any[] = [];
            //             for (let i = 1; i < this.promoInitData.menuContent.length; i++) {
            //                 menuItems.push(this.promoInitData.menuContent[i]);
            //             }
            //             this.menuItem.menu =
            //                 {
            //                     children: menuItems
            //                 }

            //             this.menuItem.parameters =
            //                 {
            //                     template: 'pc menu'
            //                 }
            //         }
            //     }

            //     //this.relatedPromos = this.pokerPromotionsInitData.relatedPromos;
            //     this.promoListEx = this.pokerPromotionsInitData.promoListEx;
            //     this.currentRelativeUrl = this.navigation.location.absUrl();
            //     this.titleService.setTitle(this.pokerPromotionsInitData.title);
            //     this.metaService.updateTag({ name: 'description', content: this.pokerPromotionsInitData.description });
            // });
        }
        // else {
        //     this.navigation.goTo('/promotions')
        // }
    }

    ngOnChanges() {
        this.promoInitData = this.initData;
        this.menuItems = this.promoInitData.menuContent; // nothing//undefined
        if (this.promoInitData.menuContent && this.promoInitData.menuContent.length > 0) {
            this.menuItem = this.promoInitData.menuContent[0];
            this.menuItem.title = this.menuItem.text;
            if (this.promoInitData.menuContent.length > 1) {
                const menuItems: any[] = [];
                for (let i = 1; i < this.promoInitData.menuContent.length; i++) {
                    menuItems.push(this.promoInitData.menuContent[i]);
                }
                this.menuItem.menu = {
                    children: menuItems,
                };

                this.menuItem.parameters = {
                    template: 'pc menu',
                };
                this.displaySideMenuBar = true;
            }
        }
    }

    menuItemClicked(itemUrl: string) {
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf('/') + 1);
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf(windowObj.clientConfig.lhProductHomepagesConfig.poker)+ 1);
        const windowObj: any = window;
        let itemId: string;
        // siteUrl = windowObj.clientConfig.lhProductHomepages.poker;
        const siteUrl: string = windowObj.clientConfig.vnProductHomepages.poker;
        if (itemUrl.indexOf(siteUrl) >= 0) {
            itemId = itemUrl.substr(siteUrl.length + 1);
        } else {
            itemId = itemUrl;
        }

        if (this.promotionsClientConfig.whenReady.subscribe(() => this.promotionsClientConfig.usePokerPromotionsNewTemplate)) {
            this.api.get('PokerPromotion/GetBrandNewPromoItem', { folderId: itemId }, { showSpinner: true }).subscribe(
                (response) => {
                    this.pokerPromotionsInitData = response.promoModel.pokerPromotionsSitecoreInfo;
                    this.promoItem = response.promoItem;
                    this.relatedPromos = response.promoModel.pokerPromotionsSitecoreInfo?.relatedPromos;
                    this.promoListEx = response.promoModel.pokerPromotionsSitecoreInfo?.promoListEx;
                },
                () => {},
            );
        } else {
            this.api.get('PokerPromotion/GetPromoItem', { folderId: itemId }, { showSpinner: true }).subscribe(
                (response) => {
                    this.pokerPromotionsInitData = response.promoModel.pokerPromotionsSitecoreInfo;
                    this.promoItem = response.promoItem;
                    this.relatedPromos = response.promoModel.pokerPromotionsSitecoreInfo?.relatedPromos;
                    this.promoListEx = response.promoModel.pokerPromotionsSitecoreInfo?.promoListEx;
                },
                () => {},
            );
        }
    }

    relatedPromoClick(relatedPromoItem: string) {
        this.navigation.goTo('/mobilepoker/pokerpromotions/' + relatedPromoItem);
    }
}
