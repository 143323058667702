import { Message } from '../../message';

export class UserClubMemeberTypeChange extends Message {
    private MESSAGE_NAME: string = 'UserClubMemeberTypeChange';
    private clubId: number = 0;
    private userType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clubId: number = 0, userType: number = 0) {
        super(reqServerPeerId);
        this.clubId = clubId;
        this.userType = userType;
    }

    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
    getUserType(): number {
        return this.userType;
    }

    setUserType(userType: number) {
        this.userType = userType;
    }
}
