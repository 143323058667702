import { PGMessageFactoryRegistration } from '../pgConnectors/sal/PGMessageFactoryRegistration';
import { Connector } from '../pgConnectors/sal/connector';
import { IConnectionManager } from '../pgConnectors/sal/interfaces/IconnectionManager';
import { IConnectorDelegate } from '../pgConnectors/sal/interfaces/IconnectorDelegate';
import { ConnectorMessageFactory } from '../pgConnectors/server/common/connectorMessageFactory/ConnectorMessageFactory';
import { Message } from '../pgConnectors/server/common/message';
import { PGSharedMessageFactory } from '../pgConnectors/server/common/pgSharedMessageFactory/PGSharedMessageFactory';
import { IPGConnector } from './interfaces/IpgConnector';

export class PokerBackendConnector implements IPGConnector {
    static pgConnector: IPGConnector | null = null;
    private connector: IConnectionManager;
    private messageFactoryReg: PGMessageFactoryRegistration;
    private delegate: IConnectorDelegate;

    private constructor() {
        this.messageFactoryReg = new PGMessageFactoryRegistration();
        this.messageFactoryReg.registerFactory(new ConnectorMessageFactory());
        this.messageFactoryReg.registerFactory(new PGSharedMessageFactory());
    }

    public static getInstance(): IPGConnector {
        if (!PokerBackendConnector.pgConnector) {
            PokerBackendConnector.pgConnector = new PokerBackendConnector();
        }
        return PokerBackendConnector.pgConnector;
    }

    initConnector(serverIp: string) {
        this.connector = new Connector(serverIp);
        this.connector.setMessageFactoryRegistration(this.messageFactoryReg);
        this.connector.setDelegate(this.delegate);
    }

    registerFct(msgFactory: any) {
        this.messageFactoryReg.registerFactory(msgFactory);
    }

    // Implement interface methods
    setDelegate(delegate: IConnectorDelegate): void {
        this.delegate = delegate;
    }

    getConnector(): IConnectionManager {
        return this.connector;
    }

    initiateConnectionWithParams(params: Map<string, any>): void {
        this.initConnector(params.get('SERVER_IP'));
        this.connector.initiateConnectionWithParams(params);
    }

    closeAllConnections(): void {
        this.connector.closeAllConnections();
    }

    resumeAllConnections(): void {
        // Implement this method to resume all connections
    }

    getSessionKey(): string {
        // Implement this method to get the session key
        return '';
    }

    createNonGamingConnection(): void {
        // Implement this method to create a non-gaming connection
    }

    closeConnection(domainId: number): void {
        this.connector.closeConnection(domainId);
    }
    sendClientLogout(): void {
        this.connector.sendClientLogout();
    }

    getTimeForPeer(peerId: number) {
        return this.connector.getTimeForPeer(peerId);
    }

    closePlayConnection(): void {
        this.connector.closePlayConnection();
    }

    closeNonGamingConnection(): void {
        this.connector.closeNonGamingConnection();
    }

    createSocialConnection(): void {
        this.connector.createSocialConnection();
    }

    closeSocialConnection(): void {
        this.connector.closeSocialConnection();
    }

    sendMessageOnDomain(msg: Message, domainId: number): void {
        this.connector.sendMessageOnDomain(msg, domainId);
    }

    sendMessageOnPeerId(msg: Message, peerId: number): void {
        this.connector.sendMessageOnPeerId(msg, peerId);
    }

    addPeer(peerId: number, domainId: number) {
        this.connector.addPeer(peerId, domainId);
    }
    removePeer(peerId: number, domainId: number) {
        this.connector.removePeer(peerId, domainId);
    }
}
