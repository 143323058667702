import { Message } from '../../message';

export class InviteBuddy extends Message {
    private MESSAGE_NAME: string = 'InviteBuddy';
    private buddyId: string | null = null;
    private tableId: number = 0;
    private message: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, buddyId: string | null = null, tableId: number = 0, message: string | null = null) {
        super(reqServerPeerId);
        this.buddyId = buddyId;
        this.tableId = tableId;
        this.message = message;
    }

    getBuddyId(): string | null {
        return this.buddyId;
    }

    setBuddyId(buddyId: string | null) {
        this.buddyId = buddyId;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getMessage(): string | null {
        return this.message;
    }

    setMessage(message: string | null) {
        this.message = message;
    }
}
