import { GameTable } from '../game-table';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class PlayerShowCards extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.PLAYER_SHOW_CARDS;
    }
    next(gameTable: GameTable): void {
        const pMap: any = new Map();
        const player = gameTable.getPlayer(this.seat);
        player.cards.forEach((key, index) => {
            const pindex: any = index;
            const cardShow: any = key.show;
            pMap.set(pindex, cardShow);
        });
        gameTable.cardMap.set(this.seat, pMap);
        player.showHoleCards();
        player.isPlayerCardsOpened = true;
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        if (player.isPlayerRequesting) {
            player.showHoleCards();
            player.isPlayerCardsOpened = true;
        } else {
            gameTable.cardMap.get(this.seat).forEach((mapValue: any, mapKey: any) => {
                player.hideSevenCardStudCards(mapKey, mapValue);
            });
            if (gameTable.isRit && gameTable.isMobile) {
                player.isPlayerCardsOpened = true;
            } else {
                player.isPlayerCardsOpened = false;
            }
        }
    }
}
