import { Message } from '../../message';

export class RequestLobbyDyna extends Message {
    private MESSAGE_NAME: string = 'RequestLobbyDyna';
    private templateIds: any[] | null = null;
    private tournyType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, templateIds: any[] | null = null, tournyType: number = 0) {
        super(reqServerPeerId);
        this.templateIds = templateIds;
        this.tournyType = tournyType;
    }

    getTemplateIds(): any[] | null {
        return this.templateIds;
    }

    setTemplateIds(templateIds: any[] | null) {
        this.templateIds = templateIds;
    }
    getTournyType(): number {
        return this.tournyType;
    }

    setTournyType(tournyType: number) {
        this.tournyType = tournyType;
    }
}
