<div
    class="player-pot"
    ngClass="{{ player.transformPosition }}"
    [class.animate]="player.toPot.value > 0"
    [style.left]="getXAxis()"
    [style.top]="getYAxis()"
    [hidden]="!(player.toPot.value > 0)"
    *ngIf="!isMobile; else mobileHTML"
    [class.bulkchips]="gameTableService.gameTable.replay"
    [class.centeranimation]="!gameTable.mpot"
    [class.hide]="gameTable.mpot"
    [class.Mpot]="amount && potNo === 0"
    [class.potNo]="amount && potNo < getLastPotId && potNo >= 1">
    <div class="chip" *ngIf="gameTable.isReplayerV3" [class.Wins]="gameTableService.gameTable.replay">
        <div class="chipgrids" *ngFor="let k of itemInRowRange">
            <ng-container *ngIf="items | async as items">
                <span
                    class="showChip"
                    *ngFor="let item of items | slice: k * 8 : k * 8 + 8"
                    ngClass="{{ item }}"
                    [attr.dVal]="getDollars(item)"
                    [attr.cent]="getCents(item)"></span>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!gameTable.isReplayerV3">
        <div class="chip" *ngFor="let key of chipKeys; let idx = index">
            <div *ngIf="idx < 2">
                <div *ngFor="let chip of chiparray[key]">
                    <div *ngFor="let i of chip.nChips">
                        <span class="showChip" ngClass="{{ chip.nval }}" [attr.dVal]="getDollars(chip.nval)" [attr.cent]="getCents(chip.nval)"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="pot-value" [class.bulkchips]="gameTableService.gameTable.replay" ngClass="seat{{ player.seatNo }}">
        <span class="Winpots" *ngIf="amount && potNo === 0 && gameTable.isReplayerV3 && gameTableService.gameTable.replay">M</span>
        <span class="Winpots" *ngIf="amount && potNo < getLastPotId && potNo >= 1 && gameTable.isReplayerV3 && gameTableService.gameTable.replay">{{
            potNo
        }}</span>
        <!-- <span *ngIf="!player.isTourny">{{player.currencyType}}</span> -->
        <span *ngIf="!player.isTourny">{{ amount.toLocaleString(undefined, { minimumFractionDigits: 2 }) }}</span
        ><span *ngIf="player.isTourny">{{ (player.toPot.value / 100).toLocaleString() }}</span>
    </p>
</div>
<ng-template #mobileHTML>
    <div
        class="player-pot"
        [class.selfPlayerpot]="player.isPlayerRequesting"
        [style.left]="getXAxis()"
        [style.top]="getYAxis()"
        [class.animate]="player.toPot.value > 0"
        ngClass="{{ player.transformPosition }}"
        [hidden]="!(player.toPot.value > 0)">
        <div class="chip">
            <div class="chipgrids" *ngFor="let k of itemInRowRange">
                <ng-container *ngIf="items | async as items">
                    <span
                        class="showChip"
                        *ngFor="let item of items | slice: k * 8 : k * 8 + 8"
                        ngClass="{{ item }}"
                        [attr.dVal]="getDollars(item)"
                        [attr.cent]="getCents(item)"></span>
                </ng-container>
            </div>
        </div>
        <p class="pot-value" [class.hide]="gameTable.mpot" [class.amountTruncate]="greaterAmount">
            <!-- <span *ngIf="!player.isTourny">{{player.currencyType}}</span> -->
            <span *ngIf="!player.isTourny">{{ amount }}</span
            ><span *ngIf="player.isTourny">{{ amount }}</span>
        </p>
    </div>
</ng-template>
