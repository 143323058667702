import { Message } from '../../message';

export class QSRequest extends Message {
    private MESSAGE_NAME: string = 'QSRequest';
    private requestId: number = 0;
    private requestType: number = 0;
    private paramList: any[] | null = null;
    private tableIdList: any[] | null = null;
    private tabTypes: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        requestType: number = 0,
        paramList: any[] | null = null,
        tableIdList: any[] | null = null,
        tabTypes: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.requestType = requestType;
        this.paramList = paramList;
        this.tableIdList = tableIdList;
        this.tabTypes = tabTypes;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
    getParamList(): any[] | null {
        return this.paramList;
    }

    setParamList(paramList: any[] | null) {
        this.paramList = paramList;
    }
    getTableIdList(): any[] | null {
        return this.tableIdList;
    }

    setTableIdList(tableIdList: any[] | null) {
        this.tableIdList = tableIdList;
    }
    getTabTypes(): any[] | null {
        return this.tabTypes;
    }

    setTabTypes(tabTypes: any[] | null) {
        this.tabTypes = tabTypes;
    }
}
