import { Message } from '../../message';

export class GameServerProperties extends Message {
    private MESSAGE_NAME: string = 'GameServerProperties';
    private peerId: number = 0;
    private ipAddress: string | null = null;
    private portNumber: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, peerId: number = 0, ipAddress: string | null = null, portNumber: number = 0) {
        super(reqServerPeerId);
        this.peerId = peerId;
        this.ipAddress = ipAddress;
        this.portNumber = portNumber;
    }

    getPeerId(): number {
        return this.peerId;
    }

    setPeerId(peerId: number) {
        this.peerId = peerId;
    }
    getIpAddress(): string | null {
        return this.ipAddress;
    }

    setIpAddress(ipAddress: string | null) {
        this.ipAddress = ipAddress;
    }
    getPortNumber(): number {
        return this.portNumber;
    }

    setPortNumber(portNumber: number) {
        this.portNumber = portNumber;
    }
}
