import { Message } from '../../message';

export class TourneyLevelInfo extends Message {
    private MESSAGE_NAME: string = 'TourneyLevelInfo';
    private levelInfo: any | null = null;
    private remainingHands: any | null = null;
    private nextLevelValue: number = 0;
    private lowstake: number = 0;
    private highstake: number = 0;
    private levelNo: number = 0;
    private nextLevelNo: number = 0;
    private blindLevelType: number = 0;
    private nextLevelLowStake: number = 0;
    private nextLevelHighStake: number = 0;
    private ante: number = 0;
    private nextLevelAnte: number = 0;
    private levelNewInfo: any | null = null;
    private noOfPlacesPaid: number = 0;
    private noOfPlayers: number = 0;
    private averageStack: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        levelInfo: any | null = null,
        remainingHands: any | null = null,
        nextLevelValue: number = 0,
        lowstake: number = 0,
        highstake: number = 0,
        levelNo: number = 0,
        nextLevelNo: number = 0,
        blindLevelType: number = 0,
        nextLevelLowStake: number = 0,
        nextLevelHighStake: number = 0,
        ante: number = 0,
        nextLevelAnte: number = 0,
        levelNewInfo: any | null = null,
        noOfPlacesPaid: number = 0,
        noOfPlayers: number = 0,
        averageStack: number = 0,
    ) {
        super(reqServerPeerId);
        this.levelInfo = levelInfo;
        this.remainingHands = remainingHands;
        this.nextLevelValue = nextLevelValue;
        this.lowstake = lowstake;
        this.highstake = highstake;
        this.levelNo = levelNo;
        this.nextLevelNo = nextLevelNo;
        this.blindLevelType = blindLevelType;
        this.nextLevelLowStake = nextLevelLowStake;
        this.nextLevelHighStake = nextLevelHighStake;
        this.ante = ante;
        this.nextLevelAnte = nextLevelAnte;
        this.levelNewInfo = levelNewInfo;
        this.noOfPlacesPaid = noOfPlacesPaid;
        this.noOfPlayers = noOfPlayers;
        this.averageStack = averageStack;
    }

    getLevelInfo(): any | null {
        return this.levelInfo;
    }

    setLevelInfo(levelInfo: any | null) {
        this.levelInfo = levelInfo;
    }
    getRemainingHands(): any | null {
        return this.remainingHands;
    }

    setRemainingHands(remainingHands: any | null) {
        this.remainingHands = remainingHands;
    }
    getNextLevelValue(): number {
        return this.nextLevelValue;
    }

    setNextLevelValue(nextLevelValue: number) {
        this.nextLevelValue = nextLevelValue;
    }
    getLowstake(): number {
        return this.lowstake;
    }

    setLowstake(lowstake: number) {
        this.lowstake = lowstake;
    }
    getHighstake(): number {
        return this.highstake;
    }

    setHighstake(highstake: number) {
        this.highstake = highstake;
    }
    getLevelNo(): number {
        return this.levelNo;
    }

    setLevelNo(levelNo: number) {
        this.levelNo = levelNo;
    }
    getNextLevelNo(): number {
        return this.nextLevelNo;
    }

    setNextLevelNo(nextLevelNo: number) {
        this.nextLevelNo = nextLevelNo;
    }
    getBlindLevelType(): number {
        return this.blindLevelType;
    }

    setBlindLevelType(blindLevelType: number) {
        this.blindLevelType = blindLevelType;
    }
    getNextLevelLowStake(): number {
        return this.nextLevelLowStake;
    }

    setNextLevelLowStake(nextLevelLowStake: number) {
        this.nextLevelLowStake = nextLevelLowStake;
    }
    getNextLevelHighStake(): number {
        return this.nextLevelHighStake;
    }

    setNextLevelHighStake(nextLevelHighStake: number) {
        this.nextLevelHighStake = nextLevelHighStake;
    }
    getAnte(): number {
        return this.ante;
    }

    setAnte(ante: number) {
        this.ante = ante;
    }
    getNextLevelAnte(): number {
        return this.nextLevelAnte;
    }

    setNextLevelAnte(nextLevelAnte: number) {
        this.nextLevelAnte = nextLevelAnte;
    }
    getLevelNewInfo(): any | null {
        return this.levelNewInfo;
    }

    setLevelNewInfo(levelNewInfo: any | null) {
        this.levelNewInfo = levelNewInfo;
    }
    getNoOfPlacesPaid(): number {
        return this.noOfPlacesPaid;
    }

    setNoOfPlacesPaid(noOfPlacesPaid: number) {
        this.noOfPlacesPaid = noOfPlacesPaid;
    }
    getNoOfPlayers(): number {
        return this.noOfPlayers;
    }

    setNoOfPlayers(noOfPlayers: number) {
        this.noOfPlayers = noOfPlayers;
    }
    getAverageStack(): number {
        return this.averageStack;
    }

    setAverageStack(averageStack: number) {
        this.averageStack = averageStack;
    }
}
