import { Message } from '../../message';

export class SNGJPCardDeleteEvent extends Message {
    private MESSAGE_NAME: string = 'SNGJPCardDeleteEvent';
    private sngJPId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sngJPId: number = 0) {
        super(reqServerPeerId);
        this.sngJPId = sngJPId;
    }

    getSngJPId(): number {
        return this.sngJPId;
    }

    setSngJPId(sngJPId: number) {
        this.sngJPId = sngJPId;
    }
}
