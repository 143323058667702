import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPrizepool',
    standalone: true,
})
export class FormatPrizepoolPipe implements PipeTransform {
    transform(value: string, currencyRePosition: string) {
        const symbol = value.replace(/[.+0-9\s]/g, '');
        const primeValue: any = value.replace(/[^0-9\.+]/g, '');

        if (currencyRePosition == 'true' && symbol == '€') {
            return `${Number(primeValue).toLocaleString()} ${symbol}`;
        }
        return symbol + Number(primeValue).toLocaleString();
    }
}
