import { Message } from '../../message';

export class RequestHUSNGRematchAccepted extends Message {
    private MESSAGE_NAME: string = 'RequestHUSNGRematchAccepted';
    private targetSNGId: number = 0;
    private useTourneyTicket: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, targetSNGId: number = 0, useTourneyTicket: number = 0) {
        super(reqServerPeerId);
        this.targetSNGId = targetSNGId;
        this.useTourneyTicket = useTourneyTicket;
    }

    getTargetSNGId(): number {
        return this.targetSNGId;
    }

    setTargetSNGId(targetSNGId: number) {
        this.targetSNGId = targetSNGId;
    }
    getUseTourneyTicket(): number {
        return this.useTourneyTicket;
    }

    setUseTourneyTicket(useTourneyTicket: number) {
        this.useTourneyTicket = useTourneyTicket;
    }
}
