import { Message } from '../../message';

export class LossLimitDetails extends Message {
    private MESSAGE_NAME: string = 'LossLimitDetails';
    private currency: string | null = null;
    private pendingAmt: number = 0;
    private playerLimitAmt: number = 0;
    private totalLossAmt: number = 0;
    private limitType: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        currency: string | null = null,
        pendingAmt: number = 0,
        playerLimitAmt: number = 0,
        totalLossAmt: number = 0,
        limitType: string | null = null,
    ) {
        super(reqServerPeerId);
        this.currency = currency;
        this.pendingAmt = pendingAmt;
        this.playerLimitAmt = playerLimitAmt;
        this.totalLossAmt = totalLossAmt;
        this.limitType = limitType;
    }

    getCurrency(): string | null {
        return this.currency;
    }

    setCurrency(currency: string | null) {
        this.currency = currency;
    }
    getPendingAmt(): number {
        return this.pendingAmt;
    }

    setPendingAmt(pendingAmt: number) {
        this.pendingAmt = pendingAmt;
    }
    getPlayerLimitAmt(): number {
        return this.playerLimitAmt;
    }

    setPlayerLimitAmt(playerLimitAmt: number) {
        this.playerLimitAmt = playerLimitAmt;
    }
    getTotalLossAmt(): number {
        return this.totalLossAmt;
    }

    setTotalLossAmt(totalLossAmt: number) {
        this.totalLossAmt = totalLossAmt;
    }
    getLimitType(): string | null {
        return this.limitType;
    }

    setLimitType(limitType: string | null) {
        this.limitType = limitType;
    }
}
