import { Message } from '../../message';

export class QSParam extends Message {
    private MESSAGE_NAME: string = 'QSParam';
    private paramId: number = 0;
    private operatorId: number = 0;
    private value: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, paramId: number = 0, operatorId: number = 0, value: string | null = null) {
        super(reqServerPeerId);
        this.paramId = paramId;
        this.operatorId = operatorId;
        this.value = value;
    }

    getParamId(): number {
        return this.paramId;
    }

    setParamId(paramId: number) {
        this.paramId = paramId;
    }
    getOperatorId(): number {
        return this.operatorId;
    }

    setOperatorId(operatorId: number) {
        this.operatorId = operatorId;
    }
    getValue(): string | null {
        return this.value;
    }

    setValue(value: string | null) {
        this.value = value;
    }
}
