import { Message } from '../../message';

export class TourneyPrizeInfo extends Message {
    private MESSAGE_NAME: string = 'TourneyPrizeInfo';
    private totalPrizeInfo: any | null = null;
    private individualPrizes: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, totalPrizeInfo: any | null = null, individualPrizes: any[] | null = null) {
        super(reqServerPeerId);
        this.totalPrizeInfo = totalPrizeInfo;
        this.individualPrizes = individualPrizes;
    }

    getTotalPrizeInfo(): any | null {
        return this.totalPrizeInfo;
    }

    setTotalPrizeInfo(totalPrizeInfo: any | null) {
        this.totalPrizeInfo = totalPrizeInfo;
    }
    getIndividualPrizes(): any[] | null {
        return this.individualPrizes;
    }

    setIndividualPrizes(individualPrizes: any[] | null) {
        this.individualPrizes = individualPrizes;
    }
}
