<footer class="pwc-footer">
    <div class="pwc-session-bar">
        <div class="session-time">
            <span class="session-time-icon">
                <svg width="17" height="22" viewBox="0 -2 18 20" fill="none">
                    <path
                        d="M4.79237 1.2382C4.79237 0.861248 5.09178 0.555664 5.46112 0.555664H10.8112C11.1805 0.555664 11.4799 0.861248 11.4799 1.2382C11.4799 1.61516 11.1805 1.92074 10.8112 1.92074H5.46112C5.09178 1.92074 4.79237 1.61516 4.79237 1.2382ZM8.13614 6.01598C7.7668 6.01598 7.46739 6.32157 7.46739 6.69852V12.1588C7.46739 12.5358 7.7668 12.8414 8.13614 12.8414C8.50548 12.8414 8.8049 12.5358 8.8049 12.1588V6.69852C8.8049 6.32157 8.50548 6.01598 8.13614 6.01598ZM16.1612 11.4763C16.1612 15.9998 12.5683 19.6668 8.13614 19.6668C3.70402 19.6668 0.111084 15.9998 0.111084 11.4763C0.111084 6.95283 3.70402 3.28582 8.13614 3.28582C12.5683 3.28582 16.1612 6.95283 16.1612 11.4763ZM14.8237 11.4763C14.8237 7.70674 11.8296 4.6509 8.13614 4.6509C4.44271 4.6509 1.44859 7.70674 1.44859 11.4763C1.44859 15.2459 4.44271 18.3017 8.13614 18.3017C11.8296 18.3017 14.8237 15.2459 14.8237 11.4763ZM14.761 2.79697C14.4999 2.53042 14.0764 2.53042 13.8153 2.79697C13.5541 3.06351 13.5541 3.49567 13.8153 3.76222L15.6917 5.67729C15.9528 5.94384 16.3763 5.94384 16.6374 5.67729C16.8986 5.41074 16.8986 4.97858 16.6374 4.71204L14.761 2.79697Z"
                        fill="#A8A8A8" />
                </svg>
            </span>
            <span class="session-time-value">
                <ng-container *ngIf="isUserAuthenticated"> {{ clientToServer.activeTime }} | </ng-container>
                {{ presentTime }}
            </span>
        </div>
        <div class="lisence-btn" (click)="onLicenseClick()">
            <svg width="18" height="22" viewBox="0 -2 24 20" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-0.000732422 3.1528C-0.000732422 1.83346 1.06881 0.763916 2.38816 0.763916H13.8207C15.14 0.763916 16.2096 1.83346 16.2096 3.15281L16.2096 5.08667C12.0316 5.08667 8.64476 8.47355 8.64476 12.6515C8.64476 14.9419 9.6627 16.9946 11.2708 18.3819L11.0235 20.2163H2.38816C1.06881 20.2163 -0.000732422 19.1468 -0.000732422 17.8274V3.1528ZM14.3438 17.8716C14.7657 17.991 15.2109 18.0549 15.6711 18.0549C15.8503 18.0549 16.0272 18.0452 16.2014 18.0263C16.1946 18.1091 16.1835 18.1907 16.1685 18.2709C15.5186 18.2653 14.9012 18.1231 14.3438 17.8716ZM2.06462 4.41253C2.06462 3.96489 2.4275 3.60201 2.87513 3.60201H13.1417C13.5893 3.60201 13.9522 3.96489 13.9522 4.41253C13.9522 4.86016 13.5893 5.22304 13.1417 5.22304H2.87513C2.4275 5.22304 2.06462 4.86016 2.06462 4.41253ZM2.87513 8.46511C2.4275 8.46511 2.06462 8.82799 2.06462 9.27562C2.06462 9.72326 2.4275 10.0861 2.87513 10.0861H7.73823C8.18586 10.0861 8.54875 9.72326 8.54875 9.27562C8.54875 8.82799 8.18587 8.46511 7.73823 8.46511H2.87513ZM2.06462 14.1387C2.06462 13.6911 2.4275 13.3282 2.87513 13.3282H6.65754C7.10518 13.3282 7.46806 13.6911 7.46806 14.1387C7.46806 14.5864 7.10518 14.9492 6.65754 14.9492H2.87513C2.4275 14.9492 2.06462 14.5864 2.06462 14.1387Z"
                    fill="white" />
                <path
                    d="M19.453 23.4582L18.7626 16.9741C17.9821 17.4345 17.1115 17.6647 16.2109 17.6647C15.3104 17.6647 14.4398 17.3962 13.6593 16.9741L12.9689 23.4582L16.2109 20.1203L19.453 23.4582Z"
                    fill="#F42C2C" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.2104 18.0549C19.1947 18.0549 21.6139 15.6357 21.6139 12.6515C21.6139 9.66725 19.1947 7.24805 16.2104 7.24805C13.2262 7.24805 10.807 9.66725 10.807 12.6515C10.807 15.6357 13.2262 18.0549 16.2104 18.0549ZM16.2103 15.8935C18.0009 15.8935 19.4524 14.442 19.4524 12.6515C19.4524 10.8609 18.0009 9.40942 16.2103 9.40942C14.4198 9.40942 12.9683 10.8609 12.9683 12.6515C12.9683 14.442 14.4198 15.8935 16.2103 15.8935Z"
                    fill="#F42C2C" />
            </svg>
        </div>
    </div>
    <div class="pwc-license-wrapper" *ngIf="showLicenseWrapper" (click)="onLicenseClick()">
        <div class="pwc-license-container">
            <div [innerHTML]="regulatoryContent"></div>
            <div class="d-flex align-content-center flex-wrap justify-content-center">
                <a class="mx-1 my-1" *ngFor="let icon of regulatoryIcons" [href]="icon.imageLink.url" target="_blank">
                    <img [src]="icon.image.src" [alt]="icon.image.alt" />
                </a>
            </div>
        </div>
    </div>
    <nav>
        <ul>
            <li *ngFor="let item of menuItems.children; let ind = index">
                <a (click)="onMenuClick(item, ind)" [ngClass]="{ active: selectedMenuItem === ind }">
                    <span class="menu-item-icon">
                        <img [src]="item.image.src" [alt]="item.image.alt" />
                    </span>
                    <span class="menu-item-name">{{ item.text }}</span>
                </a>
            </li>
        </ul>
    </nav>
</footer>
