import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { BigWinService } from './bigwin.service';

@Injectable({ providedIn: 'root' })
export class BigWinResolver implements Resolve<any> {
    constructor(private resource: BigWinService) {}

    resolve(): Observable<any> {
        return this.resource.getInitValues();
    }
}
