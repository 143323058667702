import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonGameProperties } from '../PWC-games-lib/lib/game-config/CommonGameProperties';
import { PWCErrorDialogComponent } from '../common/error-dialog/pwc-error-dialog.component';
import { PWCReconnectionDialogComponent } from '../common/reconnection-popup/pwc-reconnection-dialog.component';
import { CashErrorDialogModel } from '../models/cash-error-dialog.model';
import { BaseLobbyConnector } from '../pgBackendConnectors/baseLobbyConnector';
import { ClassMessageListener, MessageListener } from '../pgBackendConnectors/messageListener';
import { PokerBackendConnector } from '../pgBackendConnectors/pokerBackendConnector';
import { CSD } from '../pgConnectors/sal/CSD';
import { LoginRequest } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/LoginRequest';
import { RequestSNGJPLobbyData } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestSNGJPLobbyData';
import { RequestSNGJPPlayNowUpdate } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestSNGJPPlayNowUpdate';
import { ResponseSNGJPPlayNowUpdate } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponseSNGJPPlayNowUpdate';
import { CurrentPlayerMTCTRegistrations } from '../pgConnectors/server/common/pokerMessageFactory/messages/CurrentPlayerMTCTRegistrations';
import { JoinTable } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinTable';
import { SNGJPPlayNowRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/SNGJPPlayNowRequest';
import { SNGJPPlayNowResponse } from '../pgConnectors/server/common/pokerMessageFactory/messages/SNGJPPlayNowResponse';
import { SNGJPRegistrationRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/SNGJPRegistrationRequest';
import { SNGJPRegistrationResponse } from '../pgConnectors/server/common/pokerMessageFactory/messages/SNGJPRegistrationResponse';
import { SNGJPUnRegistrationResponse } from '../pgConnectors/server/common/pokerMessageFactory/messages/SNGJPUnRegistrationResponse';
import { TranslatePipe } from '../pipes/translate.pipe';
import {
    selectSpinsBuyIn,
    spinsOverdriveDataSelect,
    updateSpinTypeData,
    updateSpinsOverdriveLobbyData,
    updateTourneysData,
    updateisSpinsBuyIn,
} from '../store/lobby.store';
import { EventDispatcherGlobal } from './event-despacher-global';

@Injectable()
@ClassMessageListener
export class SpinGameService extends BaseLobbyConnector {
    // private _listOfTables = new BehaviorSubject<any>([]);
    // listOfTables = this._listOfTables.asObservable();
    // private connector:IPGConnector = PokerBackendConnector.getInstance();

    finalData: any;
    sngjpcards: any;
    payoutValue: any;
    SNG_JACKPOT_TAB_MODE: number;
    SNG_JACKPOT_EXPO_TAB_MODE = 3;
    spinstype: number;
    buyInTypeValue: any;
    sngJackpotID: any;
    entries: any;
    ticketCount: any;
    enableBuyIn: boolean;
    updatedCardsData: any;
    _playnowUpdateDetails = new BehaviorSubject<ResponseSNGJPPlayNowUpdate>({} as ResponseSNGJPPlayNowUpdate);
    SNGJPPlayNowRes: SNGJPPlayNowResponse;
    playnowUpdateDetails = this._playnowUpdateDetails.asObservable();
    _playnowBuyinDetails = new BehaviorSubject<SNGJPPlayNowResponse>({} as SNGJPPlayNowResponse);
    playnowBuyinDetails = this._playnowBuyinDetails.asObservable();
    // spinsLobbyCardInfo: ResponseSNGJPPlayNowUpdate;
    // SNGJPPlayNowRes: SNGJPPlayNowResponse;
    SNGJPRegistrationRes: SNGJPRegistrationResponse;
    SNGJPUnRegistrationRes: SNGJPUnRegistrationResponse;
    eventDispatcherGlobal = new EventDispatcherGlobal();
    registeredTournaments: any = [];
    pendingRegReqArr: any[] = [];

    constructor(
        private userService: UserService,
        private store: Store,
        private dialog: MatDialog,
        private translatePipe: TranslatePipe,
    ) {
        super(PokerBackendConnector.getInstance());
        this.store.select(selectSpinsBuyIn).subscribe((data) => {
            this.enableBuyIn = data;
        });
        this.store.select(spinsOverdriveDataSelect).subscribe((data) => {
            this.updatedCardsData = data;
        });
        this.eventDispatcherGlobal.addEventListener('onPrimaryAssetsLoadComplete', this.onPrimaryAssetsLoadComplete.bind(this));
    }

    clientLoginRequest() {
        const loginRequest: LoginRequest = new LoginRequest();
        loginRequest.setLoginId(this.userService.id);
        loginRequest.setSsoKey(this.userService.ssoToken);
        //console.log('loginRequest..', loginRequest);
        this.sendMessageOnDomain(loginRequest, CSD.REAL);
        //console.log('sendClientLoginRequest..');
    }
    // This method is used to request spins lobby cards data
    requestSNGJPPlayNowUpdate(domain: number, spinsType: number) {
        //console.log("requesting snjp playnow update" );
        const lobbyUpdate: RequestSNGJPPlayNowUpdate = new RequestSNGJPPlayNowUpdate();
        lobbyUpdate.setLastSnapShotTimeInNanos(0);
        lobbyUpdate.setDomain(domain);
        lobbyUpdate.setRequestType(spinsType);
        this.spinstype = spinsType;
        this.store.dispatch(updateSpinTypeData(spinsType));
        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
        //console.log("testing SPINS");
    }
    //   spinsType(spinsType: any): { spinTypeData: any; } & import("@ngrx/store/src/models").TypedAction<"[ spinType] Update SpinType data"> {
    //     throw new Error("Method not implemented.");
    //   }
    // this method is response for requestSNGJPPlayNowUpdate
    @MessageListener('ResponseSNGJPPlayNowUpdate', 86207)
    ResponseSNGJPPlayNowUpdate(message: ResponseSNGJPPlayNowUpdate) {
        //  this.spinsLobbyCardInfo=message;
        // console.log('playnow',message.getSngJPCards());
        this.finalData = message.getSngJPCards();
        this.initSngJPCards();
        this.removeDuplicateIds(1);
        this.store.dispatch(updateSpinsOverdriveLobbyData(this.sngjpcards));
    }

    // this method is used for requesting for playnowresponse
    RequestSNGJPPlayNow(sngJackpotID: any, entries: number, conversationId: number, ticketCount: number, enableBuyIn: boolean) {
        const lobbyUpdate: SNGJPPlayNowRequest = new SNGJPPlayNowRequest();
        // window.Connector.addPeer(sngJackpotID, 0);
        this.sngJackpotID = sngJackpotID;
        this.entries = entries;
        this.ticketCount = ticketCount;
        this.enableBuyIn = enableBuyIn;
        this.store.dispatch(updateisSpinsBuyIn(enableBuyIn));
        lobbyUpdate.setSngJackpotID(sngJackpotID);
        lobbyUpdate.setReqServerPeerId(sngJackpotID);
        lobbyUpdate.setEntries(entries);
        lobbyUpdate.setConversationId(0);
        lobbyUpdate.isPrivileged();
        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
        // console.log("requesting SNGJPPlayNowRequest " );
    }

    //this method is response for SNGJPPlayNowRequest
    @MessageListener('SNGJPPlayNowResponse', 45707)
    SNGJPPlayNowResponse(message: SNGJPPlayNowResponse) {
        //  this.SNGJPPlayNowRes=message;
        this.handleSNGJPPlaynowResponse(message);
        // console.log("SNGJPPlayNowResponse "+ message);
        // this.getPlayNowBuyinDetails().subscribe((data) => {
        //     // console.log('@@@@..playnowbuyin data..', data);
        // });
    }
    onPrimaryAssetsLoadComplete() {
        for (const sngjpid in this.pendingRegReqArr) {
            const obj = this.pendingRegReqArr[sngjpid];
            this.SNGJPRegistrationRequest(obj.sngJackpotID, obj.entries, obj.ticketsToUse, 0, 0);
        }
        this.pendingRegReqArr = [];
    }

    //this method is used for requesting for registration data
    SNGJPRegistrationRequest(sngJackpotID: any, entries: number, ticketsToUse: number, conversationId: number, sourceType: any) {
        // console.log(" Registration request");
        const lobbyUpdate: SNGJPRegistrationRequest = new SNGJPRegistrationRequest();
        lobbyUpdate.setSngJackpotID(sngJackpotID);
        lobbyUpdate.setReqServerPeerId(sngJackpotID);
        lobbyUpdate.setEntries(entries);
        lobbyUpdate.setTicketsToUse(ticketsToUse);
        lobbyUpdate.setConversationId(conversationId);
        lobbyUpdate.setSourceType(sourceType);
        // check if assets are loaded or not, if not store the request
        if (!CommonGameProperties.primaryAssetsLoaded) {
            this.pendingRegReqArr[sngJackpotID] = lobbyUpdate;
            this.showAssetsDialog();
        } else {
            this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
        }
    }

    //this method is response for registration request
    @MessageListener('SNGJPRegistrationResponse', 45709)
    SNGJPRegistrationResponse(message: SNGJPRegistrationResponse) {
        this.SNGJPRegistrationRes = message;
        // console.log("SNGJPRegistrationResponse"+this.SNGJPRegistrationRes);
        this.updateRegistrationResponse(message);
        // let joinTable = new JoinTable();
        // joinTable.setReqServerPeerId(this.SNGJPRegistrationRes.getSngJPRegistrationInfos()[0].tableId);
        // this.sendMessageOnDomain(joinTable,0);
    }

    //this method is used for Unregistration of user
    // SNGJPUnRegistrationRequest(sngJackpotID:number,tableId:number,sngJPInstanceID:number,participantNo:number,conversationId:number){
    //     console.log("Unregistration request");
    //     const lobbyUpdate:SNGJPUnRegistrationRequest = new SNGJPUnRegistrationRequest();
    //     lobbyUpdate.setSngJackpotID(sngJackpotID);
    //     lobbyUpdate.setTableId(tableId);
    //     lobbyUpdate.getSngJPInstanceID();
    //     lobbyUpdate.setParticipantNo(participantNo);
    //     lobbyUpdate.getConversationId();
    //     this.sendMessageOnDomain(lobbyUpdate,0);

    // }

    // @MessageListener("SNGJPUnRegistrationResponse",45712,0)
    // SNGJPUnRegistrationResponse(message:SNGJPUnRegistrationResponse){
    //     this.SNGJPUnRegistrationRes=message;
    //     console.log("SNGJPUnRegistrationResponse"+this.SNGJPUnRegistrationResponse);
    // }

    getPlayNowUpdateDetails(): Observable<any> {
        return this.playnowUpdateDetails;
    }
    setPlayNowUpdateDetails(data: any) {
        return this._playnowUpdateDetails.next(data);
    }
    getPlayNowBuyinDetails(): Observable<any> {
        return this.playnowBuyinDetails;
    }
    setPlayNowBuyinDetails(data: any) {
        return this._playnowBuyinDetails.next(data);
    }
    getTicketsForSngJPId(SngJPId) {
        if (this.updatedCardsData.length == 0 || this.updatedCardsData == undefined) return this.updatedCardsData;
        const tickets = _.find(this.updatedCardsData, { sngJPId: SngJPId })['tournmentTicketList'];
        return tickets;
    }
    private initSngJPCards() {
        this.sngjpcards = this.finalData.map((value: any) => {
            const formatedValue = value.buyIn + value.tourneyFee;
            this.payoutValue = value.payOutType;
            this.buyInTypeValue = value.buyInType;
            let textTitleValue, payOutTypeValue, payOutCardText;
            // let carousalImg, tileImg, selectedTileImg,;
            // if (value.payOutType === 0 || value.buyInType == 1) {
            //     carousalImg =
            //         value.imageId === 'goldCard'
            //             ? 'pictures/images/poker-web-client/spins/Carousel-Gold.png'
            //             : 'pictures/images/poker-web-client/spins/Non-active-Normal.png';
            //     tileImg =
            //         value.imageId === 'goldCard'
            //             ? 'pictures/images/poker-web-client/spins/Carousel-Gold.png'
            //             : 'pictures/images/poker-web-client/spins/Non-active-Normal.png';
            //     selectedTileImg =
            //         value.imageId === 'goldCard'
            //             ? 'pictures/images/poker-web-client/spins/Carousel-GoldSelected.png'
            //             : 'pictures/images/poker-web-client/spins/Active-NormalSelected.png';
            // } else if (value.buyInType !== 1) {
            //     carousalImg =
            //         value.imageId === 'goldCard'
            //             ? 'pictures/images/poker-web-client/spins/Carousel-Gold.png'
            //             : 'pictures/images/poker-web-client/spins/Non-active-Normal.png';
            //     tileImg =
            //         value.imageId === 'goldCard'
            //             ? 'pictures/images/poker-web-client/spins/Carousel-Gold.png'
            //             : 'pictures/images/poker-web-client/spins/Non-active-Normal.png';
            //     selectedTileImg =
            //         value.imageId === 'goldCard'
            //             ? 'pictures/images/poker-web-client/spins/Carousel-GoldSelected.png'
            //             : 'pictures/images/poker-web-client/spins/Active-NormalSelected.png';
            // }
            const promotionalTitle = value.promotionalTitle ? value.promotionalTitle : '';
            const promotionalSubTitle = value.promotionalSubTitle ? value.promotionalSubTitle : '';
            const promotionalText: string = promotionalTitle + promotionalSubTitle;
            if (value.payOutType === 0) {
                textTitleValue = 'Win Up to';
                payOutTypeValue = true;
            } else {
                textTitleValue = promotionalText;
                payOutCardText = value.payOutType === 1 ? 'TICKET' : 'PACKAGE';
                payOutTypeValue = false;
            }
            value.formattedBuyIn = (value.buyIn + value.tourneyFee) / 100;
            value.gameTypeTxt = value.gameType === 0 ? 'Hold’em' : 'Omaha';
            value.slide = {
                isGoldCard: value.imageId === 'goldCard' ? true : false,
                // selectedImg: selectedTileImg,
                // img: carousalImg,
                // imgCardView: tileImg,
                gameType: value.gameType === 0 ? 'NLH' : 'PLO',
                textTitle: textTitleValue,
                text1: value.winUptoAmount / 100,
                text2: (value.buyIn + value.tourneyFee) / 100,
                text3: formatedValue,
                ticketCount: 1,
                cardType: value.cardType,
                payOutType: payOutTypeValue,
                payOutCard: payOutCardText ? payOutCardText : '',
            };
            return value;
        });
    }
    handleSNGJPPlaynowResponse(message) {
        let errorMessage;
        if (message.responseMessage) {
            errorMessage = CommonGameProperties.langPack['getFormattedString'](message.responseMessage);
        }
        switch (message.responseCode) {
            case 0: //SNGJP_PLAYNOW_SUCCESS
            case 1: {
                //SNGJP_PLAYNOW_TICKETONLY_SUCCESS
                if (this.enableBuyIn == true) {
                    // this.store.dispatch(updateSpinBuyinData(message));
                    this.eventDispatcherGlobal.dispatchEvent('showSpinsBuyin', message);
                    this.setPlayNowBuyinDetails(message);
                } else {
                    this.SNGJPRegistrationRequest(message.sngJackpotID, message.availableEntries, message.availableTickets, 0, 0);
                }
                return;
            }
            //not needed to hande this s we get a stringEx from server.
            // case -1: //SNGJP_PLAYNOW_MAXLIMIT
            //   errorMessage = LangPack.getLiteral(
            //     "PARTY_POKER_GC_GAMELOBBY_SNGJP_MAXLIMIT"
            //   );
            //   console.log("errorMessage", errorMessage);
            //   break;
            case -4: //SNGJP_PLAYNOW_TECHPROBLEM
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
                break;
            case 268: //SNGJP_PLAYNOW_GENERICERROR
                if (!errorMessage) errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
                break;
            case -2: //SNGJP_PLAYNOW_TICKETONLY_FAIL
                if (!errorMessage) errorMessage = 'Sorry, you do not have the tournament ticket required to register for this tournament';
                break;
            case -24: //SNGJP_PLAYNOW_SESSIONLIMIT_EXCEEDED //Show session limit popup
            case -23: //SNGJP_PLAYNOW_GEOLOC_BLOCKED //Show Geo location popup
                break;
        }
        if (errorMessage === '') errorMessage = 'Play Now error';
        this.openErrorDialog({
            title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: errorMessage,
            buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
        });
    }

    private updateRegistrationResponse(data) {
        const tableCount = data.sngJPRegistrationInfos ? data.sngJPRegistrationInfos.length : 0;

        let mtctIds: any = [];
        if (data.sngJPRegistrationInfos) {
            for (let counter = 0; counter < data.sngJPRegistrationInfos.length; counter++) {
                mtctIds.push(data.sngJPRegistrationInfos[counter].sngJPInstanceID);
            }
            if (mtctIds.length > 0) {
                if (!this.registeredTournaments.includes(...mtctIds)) {
                    this.registeredTournaments.push(...mtctIds);
                    this.RequestSNGJPLobbyData(this.registeredTournaments, 0);
                    // var reqLobbyData = new LobbyMessageFactory.Classes.RequestSNGJPLobbyData(this.registeredTournaments, 0);
                    // connector.sendMessage(reqLobbyData, 0);
                }
            }
        }

        // this.sngJPRegistrationInfos = data.sngJPRegistrationInfos;
        // need to display an error message
        if (tableCount > 0) {
            for (let i = 0; i < tableCount; i++) {
                // Joining the newly created Tables
                const joinTable = new JoinTable();
                joinTable.setReqServerPeerId(data.sngJPRegistrationInfos[i].tableId);
                this.sendMessageOnDomain(joinTable, CSD.REAL);
                // this.openErrorDialog({
                //     title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                //     message: "errorText",
                //     buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }]
                //   });
            }
        } else {
            let errorText = '';
            if (data.sngjpRegistrationFailureDetails) {
                for (let i = 0; i < data.sngjpRegistrationFailureDetails.length; i++) {
                    errorText = errorText + CommonGameProperties.langPack['getFormattedString'](data.sngjpRegistrationFailureDetails[i].errorMessage);
                }
            }
            if (errorText === '' || errorText === 'null') errorText = 'Registration error';
            // var errorTableResponse = new CustomEvent("ERROR_CREATING_TABLE", {
            //   detail: errorText
            // });
            // window.dispatchEvent(errorTableResponse);
            this.openErrorDialog({
                title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                message: errorText,
                buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
            });
        }
    }

    openErrorDialog(data: CashErrorDialogModel) {
        this.dialog.open(PWCErrorDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }
    removeDuplicateIds(spinsType) {
        if (spinsType == 1) {
            if (this.SNG_JACKPOT_EXPO_TAB_MODE == 1) {
                this.sngjpcards = this.sngjpcards.filter((f) => f.slide.cardType == 3);
                this.sngjpcards = _.uniqBy(this.sngjpcards, 'cardPropertiesId');
            } else if (this.SNG_JACKPOT_EXPO_TAB_MODE == 2) {
                this.sngjpcards = this.sngjpcards.filter((f) => f.slide.cardType == 4);
                this.sngjpcards = _.uniqBy(this.sngjpcards, 'cardPropertiesId');
            } else if (this.SNG_JACKPOT_EXPO_TAB_MODE == 3) {
                this.sngjpcards = _.uniqBy(this.sngjpcards, 'cardPropertiesId');
                this.sngjpcards = this.sngjpcards.filter((f) => f.slide.cardType == 3 || f.slide.cardType == 4);
            }
        }
    }

    RequestSNGJPLobbyData(mtctIds: any, TourneyRequestType: any) {
        const lobbyUpdate: RequestSNGJPLobbyData = new RequestSNGJPLobbyData();
        lobbyUpdate.setTournamentIDs(mtctIds);
        lobbyUpdate.setTourneyRequestType(TourneyRequestType);
        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
    }

    @MessageListener('ResponseSNGJPLobbyData', 86221)
    ResponseSNGJPLobbyData(message: any) {
        this.store.dispatch(updateTourneysData(message));
    }

    @MessageListener('CurrentPlayerMTCTRegistrations', 45709)
    CurrentPlayerMTCTRegistrations(message: CurrentPlayerMTCTRegistrations) {
        let mtctIds: any = [];
        if (message['registredMtcts'] != null)
            for (let i = 0; i < message['registredMtcts'].length; i++) {
                mtctIds.push(message['registredMtcts'][i].mtctId);
            }
        this.registeredTournaments = mtctIds;
        if (mtctIds.length == 0) {
            this.store.dispatch(
                updateTourneysData({
                    sngJPInstanceDetails: [],
                    dynaDetails: {
                        literalsMap: {},
                    },
                }),
            );
        } else {
            this.RequestSNGJPLobbyData(mtctIds, 0);
        }
    }

    @MessageListener('SNGJPTournamentRankInfo', 45720)
    sngJPTournamentRankInfo(message: any) {
        let index = this.registeredTournaments.indexOf(message.instanceID);
        this.registeredTournaments.splice(index, 1);
        if (this.registeredTournaments.length == 0) {
            this.store.dispatch(
                updateTourneysData({
                    sngJPInstanceDetails: [],
                    dynaDetails: {
                        literalsMap: {},
                    },
                }),
            );
        } else {
            this.RequestSNGJPLobbyData(this.registeredTournaments, 0);
        }
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('SNGJPUnRegistrationResponse', 45712)
    sngJPUnRegistrationResponse(message: any) {
        // this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        let index = this.registeredTournaments.indexOf(message.sngJPInstanceID);
        this.registeredTournaments.splice(index, 1);
        if (this.registeredTournaments.length == 0) {
            this.store.dispatch(
                updateTourneysData({
                    sngJPInstanceDetails: [],
                    dynaDetails: {
                        literalsMap: {},
                    },
                }),
            );
        } else {
            this.RequestSNGJPLobbyData(this.registeredTournaments, 0);
        }
        let messageToShow;
        if (message.responseMessage) {
            messageToShow = CommonGameProperties.langPack['getFormattedString'](message.responseMessage);
            if (messageToShow == '' || messageToShow == null)
                messageToShow = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
            this.eventDispatcherGlobal.dispatchEvent('showUnregisterPopup', messageToShow);
        }
    }

    showAssetsDialog() {
        const data = {
            title: 'Network Error', //this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: 'Assets are not loaded, do you want to reload?',
            buttons: [
                { text: 'No', result: 'No' },
                { text: 'Yes', result: 'Yes' },
            ], //[{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }]
        };
        this.dialog.open(PWCReconnectionDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }
}
