import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { Subject } from 'rxjs';

import { PokerApiService } from '../api/api.service';

//import {HandHistoryService} from '@pokercore/module/hand-history';
//import { HandHistoryInputModel } from '@pokercore/module/hand-history';

@Injectable({ providedIn: 'root' })
export class SimulatedService {
    content: any;
    isMobile: boolean = true;
    test: any;
    public playerName: any = 'player';
    public nativeApp: boolean = false;
    public selfPlyerImg: any;
    private route: ActivatedRouteSnapshot;
    public screenName: string;
    public invokerApp: string;
    constructor(
        private activatedRoute: ActivatedRoute,
        private api: PokerApiService,
        //private handService:HandHistoryService
    ) {
        this.getGetInitData();
        this.route = this.activatedRoute.snapshot;
        this.screenName = this.route.queryParams?.screenname ?? null;
        this.invokerApp = this.route.queryParams?.invokerapp ?? null;
    }

    getGetInitData() {
        return this.api.get('pictures/mock/content.json', { responseType: 'text' });
    }

    getDemoData() {
        return this.api.get('pictures/mock/testData.json', { responseType: 'text' });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTestData(ind: number) {
        // if (ind === 0)
        //     return this.http.get('pictures/mock/hand1.json', { responseType: 'text' });
        // else if (ind === 1)
        //     return this.http.get('pictures/mock/hand2.json', { responseType: 'text' });
        // else if (ind === 2)
        //     return this.http.get('pictures/mock/hand3.json', { responseType: 'text' });
        // else if (ind === 10)
        //     return this.http.get('pictures/mock/hand4.json', { responseType: 'text' });
        // else if (ind === 4)
        //     return this.http.get('pictures/mock/hand5.json', { responseType: 'text' });
        // else if (ind === 5)
        //     return this.http.get('pictures/mock/hand6.json', { responseType: 'text' });
        // else if (ind === 6)
        //     return this.http.get('pictures/mock/hand7.json', { responseType: 'text' });
        // else if (ind === 7)
        //     return this.http.get('pictures/mock/hand8.json', { responseType: 'text' });
        // else if (ind === 8)
        //     return this.http.get('pictures/mock/hand9.json', { responseType: 'text' });
        // else if (ind === 9)
        //     return this.http.get('pictures/mock/hand10.json', { responseType: 'text' });
        // else if (ind === 3)
        //     return this.http.get('pictures/mock/hand11.json', { responseType: 'text' });
        return this.api.get('pictures/mock/hand11.json', { responseType: 'text' });
    }

    private informSource = new Subject<void>();
    informStatus$ = this.informSource.asObservable();

    public warnToTipComponent() {
        this.informSource.next();
    }
}
