import { Message } from '../../message';

export class ResponseDynaDetailsDeltaChanges extends Message {
    private MESSAGE_NAME: string = 'ResponseDynaDetailsDeltaChanges';
    private templateId: number = 0;
    private content: string | null = null;
    private literalsMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, templateId: number = 0, content: string | null = null, literalsMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.templateId = templateId;
        this.content = content;
        this.literalsMap = literalsMap;
    }

    getTemplateId(): number {
        return this.templateId;
    }

    setTemplateId(templateId: number) {
        this.templateId = templateId;
    }
    getContent(): string | null {
        return this.content;
    }

    setContent(content: string | null) {
        this.content = content;
    }
    getLiteralsMap(): Map<any, any> | null {
        return this.literalsMap;
    }

    setLiteralsMap(literalsMap: Map<any, any> | null) {
        this.literalsMap = literalsMap;
    }
}
