// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class TableView extends PIXI.Container {
    currenttable: any;
    displayUtil: DisplayUtil = new DisplayUtil();
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    constructor() {
        super();
    }
    createView = function (EventDispatcher, maxNoOfSeats) {
        this.name = 'TableView';
        this.eventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.tableContainer = this.displayUtil.getContainer();
        this.addChild(this.tableContainer);
        this.tableTextContainer = this.displayUtil.getContainer();
        this.addChild(this.tableTextContainer);
        ResponsiveContainer.call(this.tableTextContainer, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.eventDispatcher,
        });
        this.tableTextContainer.name = 'TableTextContainer';
        this.tableTextContainer.alpha = 1;
        this.displayUtil.setProperties(this.tableTextContainer, this.positionConfig.tableTextContainer);
        this.table = this.displayUtil.getSprite('');
        this.tableContainer.addChild(this.table);
        this.playerIconPos = {
            x: [40, 310, 600],
            y: [530, 900, 530],
        };
        this.playerInitialCountMsg = ['waiting for the players to Join', 3, 2, 1];
        this.playerBetPos = {
            x: [640, 350, 75],
            y: [490, 850, 490],
        };
        this.playerBalancePos = {
            x: [640, 350, 75],
            y: [660, 1030, 660],
        };
        const rebuyToMaxText = 'AUTO REBUY MAX ENABLED';
        this.addTableText(rebuyToMaxText, 3, 0.75, 'AutoRebuyMax');
        this.tabletextAutoRebuyMax.visible = false;
        this.tableTextContainer.setSize(720, 1280);
        // this.displayUtil.setContainerScaleFitToScreen(this);
    };
    checkForScale = function () {
        if (uiConfig.windowWidth >= 400) {
            this.tableTextContainer.y = this.tableTextContainer.y - 15;
        }
    };
    checkForResize = function () {
        if (this.scale.x == 0 || this.scale.y == 0) {
            this.setSize(720, 1280);
        }
    };
    updateTableData = function (gtsTableStatus, currentGameId, nextGameData, tableType, peerId) {
        let buyInValue;
        //gt->nTourneyBuyIn + gt->nTourneyEntryFee + gt->nBountyFee > 0
        if (tableType == serverIdConstants.TABLE_TYPE_SPINS) {
            buyInValue = gtsTableStatus.tourneyBuyIn + gtsTableStatus.tourneyEntryFee + gtsTableStatus.bountyFee;
            // buyInValue /= 100;
            buyInValue = CommonGameProperties.mathUtil['transform'](buyInValue, gtsTableStatus.gameCurrency);
        } else {
            buyInValue =
                CommonGameProperties.mathUtil['transform'](gtsTableStatus.smallBlind, gtsTableStatus.gameCurrency) +
                '/' +
                CommonGameProperties.mathUtil['transform'](gtsTableStatus.bigBlind, gtsTableStatus.gameCurrency);
        }
        if (gtsTableStatus.ante > 0) {
            buyInValue =
                buyInValue +
                ' ' +
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_OPTIONS_ANTE') +
                ' ' +
                CommonGameProperties.mathUtil['transform'](gtsTableStatus.ante, gtsTableStatus.gameCurrency);
        }
        let strGameType;
        switch (gtsTableStatus.gameTypeId) {
            case 0:
                strGameType = 'NLH';
                break;
            case 1:
                strGameType = 'PLO';
                break;
            case 2:
                strGameType = 'PLO';
                break;
            default:
                strGameType = '';
        }
        let tableName = '';
        if (gtsTableStatus.tableName)
            //tableName = facade.langPack.getFormattedString(gtsTableStatus.tableName);
            tableName = gtsTableStatus.tableName.params[0];
        uiConfig.gameType = strGameType;
        if (tableType == serverIdConstants.TABLE_TYPE_SPINS) {
            this.addTableText(strGameType + ' ' + buyInValue + ' - ' + tableName, 1, 0.5, 'GameType');
        } else {
            this.addTableText(strGameType + ' ' + buyInValue, 1, 0.5, 'GameType');
            this.addTableText(tableName, 2, 0.5, 'tableName');
        }
        if (nextGameData != null) {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
            this.timerInterval = setInterval(
                function () {
                    // const presentTime = this.gameTableNotificationService.getTimeForPeer(peerId);
                    let presentTime;
                    try {
                        presentTime = this.gameTableNotificationService.getTimeForPeer(peerId);
                    } catch {
                        clearInterval(this.timerInterval);
                        return;
                    }
                    const distance = nextGameData.nextLevelValue - presentTime;
                    let minutes: any = Math.floor(distance / 60);
                    let seconds: any = Math.floor(distance % 60);
                    if (minutes >= 0 && minutes < 10) {
                        minutes = '0' + minutes;
                    }
                    if (seconds >= 0 && seconds < 10) {
                        seconds = '0' + seconds;
                    }
                    if (seconds < 0) {
                        //this.nextGameDetailsText.text = "";
                        return;
                    }
                    this.addTableText(
                        'Next Lvl in ' +
                            minutes +
                            ' : ' +
                            seconds +
                            ' mins ' +
                            nextGameData.nextLevelLowStake / 100 +
                            '/' +
                            nextGameData.nextLevelHighStake / 100 +
                            ' Blinds',
                        2,
                        0.75,
                        'NextLvl',
                    );
                }.bind(this),
                1000,
            );
        }
    };
    resetTableText = function () {
        /*for (let i in this.tableTextContainer.children){
          this.tableTextContainer.removeChild(this.tableTextContainer.children[i]);
        }*/
        /*this['NextLvl'] = false
        this['GameType'] = false;*/
    };
    addTableText = function (text, index, alpha, textLabel) {
        if (!this[textLabel]) {
            this[textLabel] = true;
            this['tabletext' + textLabel] = this.displayUtil.getTextField(this.fontProps.tableTxt);
            this['tabletext' + textLabel].name = 'tabletext1';
            this['tabletext' + textLabel].anchor.set(0.5);
            this['tabletext' + textLabel].alpha = 0.3;
            this.tableTextContainer.addChild(this['tabletext' + textLabel]);
            this.displayUtil.setObjectPosition(this['tabletext' + textLabel], 360, 725 + (index - 1) * 20);
        }
        if (this['tabletext' + textLabel].style && this['tabletext' + textLabel].style.fontFamily) {
            this['tabletext' + textLabel].style.fontFamily = 'Roboto';
        }
        this['tabletext' + textLabel].text = text;
    };
    resizeContainer = function () {};
    addGame = function () {
        // unreachable code
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ADD_NEW_TABLE);
    };
    setDispatcherScope = function (EventDispatcher) {
        this.eventDispatcher = EventDispatcher;
    };
    updateRebuyStatus = function (type) {
        if (this.tabletextAutoRebuyMax) {
            this.tabletextAutoRebuyMax.visible = type;
        }
    };
    resetGame = function () {};
    clearTableInterval = function () {
        if (this.timerInterval) clearInterval(this.timerInterval);
    };
}
