import { Routes } from '@angular/router';

import { PokerOmahaSeriesSynopsisResolver } from './poker-omaha-series-synopsis.resolver';
import { PokerOmahaSeriesResolver } from './poker-omaha-series.resolver';

export const pokerOmahaSeriesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerOmahaSeriesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerOmahaSeriesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerOmahaSeriesResolver,
        },
    },
];
