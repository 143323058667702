import { Message } from '../../message';

export class LSMultiDayWiseData extends Message {
    private MESSAGE_NAME: string = 'LSMultiDayWiseData';
    private pauseType: number = 0;
    private pauseValue: number = 0;
    private resumeTime: any | null = null;
    private resumeLevel: number = 0;
    private displayType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        pauseType: number = 0,
        pauseValue: number = 0,
        resumeTime: any | null = null,
        resumeLevel: number = 0,
        displayType: number = 0,
    ) {
        super(reqServerPeerId);
        this.pauseType = pauseType;
        this.pauseValue = pauseValue;
        this.resumeTime = resumeTime;
        this.resumeLevel = resumeLevel;
        this.displayType = displayType;
    }

    getPauseType(): number {
        return this.pauseType;
    }

    setPauseType(pauseType: number) {
        this.pauseType = pauseType;
    }
    getPauseValue(): number {
        return this.pauseValue;
    }

    setPauseValue(pauseValue: number) {
        this.pauseValue = pauseValue;
    }
    getResumeTime(): any | null {
        return this.resumeTime;
    }

    setResumeTime(resumeTime: any | null) {
        this.resumeTime = resumeTime;
    }
    getResumeLevel(): number {
        return this.resumeLevel;
    }

    setResumeLevel(resumeLevel: number) {
        this.resumeLevel = resumeLevel;
    }
    getDisplayType(): number {
        return this.displayType;
    }

    setDisplayType(displayType: number) {
        this.displayType = displayType;
    }
}
