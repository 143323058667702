import { Message } from '../../message';

export class MTCTRequestRebuyAddonDialogInfo extends Message {
    private MESSAGE_NAME: string = 'MTCTRequestRebuyAddonDialogInfo';
    private addonDialog: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, addonDialog: boolean | null = false) {
        super(reqServerPeerId);
        this.addonDialog = addonDialog;
    }

    getAddonDialog(): boolean | null {
        return this.addonDialog;
    }

    setAddonDialog(addonDialog: boolean | null) {
        this.addonDialog = addonDialog;
    }
}
