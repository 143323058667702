import { Message } from '../../message';

export class ResponseRebuyAddonGenericFailure extends Message {
    private MESSAGE_NAME: string = 'ResponseRebuyAddonGenericFailure';
    private responseId: number = 0;
    private message: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseId: number = 0, message: any | null = null) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.message = message;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
}
