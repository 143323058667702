import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { NavigationService } from '@frontend/vanilla/core';
import { WebGameEngineClientConfig } from '@pokercore/module/core';
import { Observable, catchError, map, of } from 'rxjs';

import { PokerWebClientService } from './poker-web-client.service';

@Injectable({
    providedIn: 'root',
})
export class PlayGuard {
    constructor(
        private pokerWebClientService: PokerWebClientService,
        private webGameEngineClientConfig: WebGameEngineClientConfig,
        private navigation: NavigationService,
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.webGameEngineClientConfig.isPlayRouteRequired) {
            if (this.webGameEngineClientConfig.isQueryParamRequired) {
                const accessQueryParam = next.queryParams['accessId'];
                if (accessQueryParam === null || accessQueryParam === undefined) {
                    this.navigation.goToLastKnownProduct();
                    return false;
                } else {
                    return this.pokerWebClientService.validateAccessId(accessQueryParam).pipe(
                        map((response: any) => {
                            if (response.isValidAccessId) {
                                return true;
                            } else {
                                this.navigation.goToLastKnownProduct();
                                return false;
                            }
                        }),
                        catchError((error) => {
                            console.error('Error validating access ID:', error);
                            this.navigation.goToLastKnownProduct();
                            return of(false);
                        }),
                    );
                }
            } else {
                return true;
            }
        } else {
            this.navigation.goToLastKnownProduct();
            return false;
        }
    }
}
