import { BaseConnector } from './baseConnector';
import { IPGConnector } from './interfaces/IpgConnector';

export class BaseLobbyConnector extends BaseConnector {
    private connect: IPGConnector;

    constructor(connector: IPGConnector) {
        super(connector);
        this.connect = connector;
    }
    closePlayConnection(): void {
        this.connect.closePlayConnection();
    }

    closeNonGamingConnection(): void {
        this.connect.closeNonGamingConnection();
    }

    override sendClientLogout(): void {
        this.connect.sendClientLogout();
    }
}
