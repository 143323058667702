import { BaseLobbyConnector } from '../pgBackendConnectors/baseLobbyConnector';
import { PokerBackendConnector } from '../pgBackendConnectors/pokerBackendConnector';
import { CSD } from '../pgConnectors/sal/CSD';
import { FXRateRequest } from '../pgConnectors/server/common/araMessageFactory/messages/FXRateRequest';
import { FXConversionFactor } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/FXConversionFactor';
import { FXRateData } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/FXRateData';
import { FXRateSnapshot } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/FXRateSnapshot';
import { EventDispatcherGlobal } from '../service/event-despacher-global';

export class FxRateHelperClassService extends BaseLobbyConnector {
    static fxRateHelper: FxRateHelperClassService;
    fxRatesMasterList: any;
    latestSnapshotId: number;
    productDefaultCurrency: any;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    private constructor() {
        super(PokerBackendConnector.getInstance());
        this.eventDispatcherGlobal.addEventListener('FxRateSnapshotListener', this.FXRateSnapshotResponse.bind(this));
    }
    public static getInstance() {
        if (!FxRateHelperClassService.fxRateHelper) {
            FxRateHelperClassService.fxRateHelper = new FxRateHelperClassService();
        }
        return FxRateHelperClassService.fxRateHelper;
    }
    FXRateSnapshotResponse(message: any) {
        //console.log('..component..FXRateSnapshotResponse', message);
        this.processFxRates(message);
        //     setTimeout(() => {
        //       this.sendFXRateRequest("RUB","USD","cashgames",this.latestSnapshotId);
        //   }, 14000)
    }
    setProductCurrencyCode(productCurrencyCode) {
        this.productDefaultCurrency = productCurrencyCode;
    }
    processFxRates(message) {
        this.latestSnapshotId = message.snapshotId;
        if (this.fxRatesMasterList === undefined) {
            this.fxRatesMasterList = {};
        }

        this.fxRatesMasterList[message.snapshotId] = { fxRates: message.fxRates };
        //console.log(this.fxRatesMasterList);
    }
    getFxRate(fromCurrency, toCurrency, markupType, snapshotId) {
        const fxss = this.fxRatesMasterList[snapshotId];
        if (fxss !== undefined && fxss.snapshotId == snapshotId) {
            return this.getFxRateFromSnapshot(fromCurrency, toCurrency, markupType, fxss);
        } else {
            this.sendFXRateRequest(fromCurrency, toCurrency, markupType, snapshotId);
        }
        return -1;
    }

    getLatestSnapshotId() {
        return this.latestSnapshotId;
    }

    getProductCurrency() {
        return this.productDefaultCurrency;
    }
    getFxRateWithOutSnapShotId(fromCurrency, toCurrency, markupType) {
        if (markupType == null || markupType == '') markupType = 'deposit';

        const fxss = this.fxRatesMasterList[this.latestSnapshotId];

        if (fxss != null) {
            return this.getFxRateFromSnapshot(fromCurrency, toCurrency, markupType, fxss);
        }

        return -1;
    }
    // Define the FACTOR property
    private FACTOR: number = Math.pow(10, 9); // You can assign the appropriate value here

    getFxRateFromSnapshot(fromCurrency, toCurrency, markupType, fxss) {
        const fxDataList = fxss.fxRates;
        for (let i = 0; i < fxDataList.length; i++) {
            const fxRateData = fxDataList[i];
            if (fromCurrency === fxRateData.fromCurrencyCode && toCurrency === fxRateData.toCurrencyCode) {
                const factors = fxRateData.factors;
                for (let j = 0; j < factors.length; j++) {
                    const factor = factors[j];
                    if (markupType === factor.markupType) {
                        return factor.conversionFactor4Client / this.FACTOR;
                    }
                }
            } else if (fromCurrency === fxRateData.toCurrencyCode && toCurrency === fxRateData.fromCurrencyCode) {
                const factors = fxRateData.factors;
                for (let j = 0; j < factors.length; j++) {
                    const factor = factors[j];
                    if (markupType === factor.markupType) {
                        return factor.reverseConversionFactor4Client / this.FACTOR;
                    }
                }
            }
        }
        return 0;
    }

    processFXRateDetails(message) {
        const fromCurrency = message.fromCurrencyCode;
        const toCurrency = message.toCurrencyCode;
        const markupType = message.markupType;
        const snapShotId = message.snapShotId;

        const fxCF = new FXConversionFactor();
        fxCF.setMarkupType(markupType);

        let fxss = this.fxRatesMasterList[snapShotId];
        if (fxss == null || fxss === undefined) {
            fxss = new FXRateSnapshot();
            fxss.setSnapshotId(snapShotId);
            this.fxRatesMasterList[snapShotId] = fxss;
        }
        let fxDataList = fxss.fxRates;
        if (fxDataList === null || fxDataList === undefined) {
            fxDataList = [];
            fxss.setFxRates(fxDataList);
        }

        let fxRateData: any = null;
        for (let i = 0; i < fxDataList.size(); i++) {
            fxRateData = fxDataList[i];
            if (fxRateData != null && fxRateData.fromCurrencyCode === fromCurrency && fxRateData.toCurrencyCode === toCurrency) {
                fxCF.setConversionFactor4Client(fxRateData.conversionFactor4Client);
                fxCF.setReverseConversionFactor4Client(fxRateData.reverseConversionFactor4Client);
                let fxcfs = fxRateData.factors;
                if (fxcfs == null || fxcfs == undefined) {
                    fxcfs = [];
                    fxRateData.factors = fxcfs;
                }
                fxcfs.push(fxCF);
                return;
            }
            if (fxRateData.fromCurrencyCode === toCurrency && fxRateData.toCurrencyCode === fromCurrency) {
                fxCF.setConversionFactor4Client(fxRateData.reverseConversionFactor4Client);
                fxCF.setReverseConversionFactor4Client(fxRateData.conversionFactor4Client);
                let fxcfs = fxRateData.factors;
                if (fxcfs == null || fxcfs == undefined) {
                    fxcfs = [];
                    fxRateData.factors = fxcfs;
                }
                fxcfs.push(fxCF);
                return;
            }

            fxRateData = new FXRateData();
            fxRateData.setFromCurrencyCode(fromCurrency);
            fxRateData.setToCurrencyCode(toCurrency);
            fxDataList.push(fxRateData);
            const fxcfs: any[] = [];
            fxCF.setConversionFactor4Client(message.conversionFactor4Client);
            fxCF.setReverseConversionFactor4Client(message.reverseConversionFactor4Client);
            fxcfs.push(fxCF);
            fxRateData.factors = fxcfs;
        }
    }
    sendFXRateRequest(fromCurrency, toCurrency, markupType, snapshotId) {
        const msg: FXRateRequest = new FXRateRequest(fromCurrency, markupType, snapshotId, toCurrency);
        this.sendMessageOnDomain(msg, CSD.REAL);
    }
}
