import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { SimulatedHandsBootstrapService } from './simulated-hands.bootstrap.service';
import { SimulatedService } from './simulated-hands.service';

export function provideSimulatedHandsFeed(): Provider[] {
    return [runOnAppInit(SimulatedHandsBootstrapService), SimulatedService];
}
