import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as psf from './poker-schedule-feed.component';

@Injectable({ providedIn: 'root' })
export class PokerScheduleFeedPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('schedulefeedtemplate', psf.PokerScheduleFeedComponent);
        });
    }
}
