import { Message } from '../../message';

export class ServerParamUpdateToClient extends Message {
    private MESSAGE_NAME: string = 'ServerParamUpdateToClient';
    private serverParamConfigMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, serverParamConfigMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.serverParamConfigMap = serverParamConfigMap;
    }

    getServerParamConfigMap(): Map<any, any> | null {
        return this.serverParamConfigMap;
    }

    setServerParamConfigMap(serverParamConfigMap: Map<any, any> | null) {
        this.serverParamConfigMap = serverParamConfigMap;
    }
}
