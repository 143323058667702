import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { PokerPromotionsResourceService } from './poker-promotions-resource.service';

@Injectable({ providedIn: 'root' })
export class PokerMillionsOnlineResolver implements Resolve<any> {
    constructor(private resource: PokerPromotionsResourceService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        let folderId = route.paramMap.get('folderId');
        if (folderId == null) {
            folderId = 'overview';
        }
        return this.resource.getInitValues('Millions-Online', folderId);
    }
}
