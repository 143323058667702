import { Message } from '../../message';

export class PlayerEntryStatus extends Message {
    private MESSAGE_NAME: string = 'PlayerEntryStatus';
    private playerEntryId: number = 0;
    private entryStatus: number = 0;
    private activeTableId: number = 0;
    private balance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerEntryId: number = 0, entryStatus: number = 0, activeTableId: number = 0, balance: number = 0) {
        super(reqServerPeerId);
        this.playerEntryId = playerEntryId;
        this.entryStatus = entryStatus;
        this.activeTableId = activeTableId;
        this.balance = balance;
    }

    getPlayerEntryId(): number {
        return this.playerEntryId;
    }

    setPlayerEntryId(playerEntryId: number) {
        this.playerEntryId = playerEntryId;
    }
    getEntryStatus(): number {
        return this.entryStatus;
    }

    setEntryStatus(entryStatus: number) {
        this.entryStatus = entryStatus;
    }
    getActiveTableId(): number {
        return this.activeTableId;
    }

    setActiveTableId(activeTableId: number) {
        this.activeTableId = activeTableId;
    }
    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
}
