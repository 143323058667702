import { ModuleWithProviders, NgModule } from '@angular/core';

//
//import { CommonModule } from '@angular/common';
import { OnAppInitHandler } from './on-app-init.handler';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
})
export class OnAppInitModule {
    public static forRoot(): ModuleWithProviders<OnAppInitModule> {
        return {
            ngModule: OnAppInitModule,
            providers: [OnAppInitHandler],
        };
    }
}
