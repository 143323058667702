import { Message } from '../../message';
import { LSHeadsUpTourenyPlayerSequence } from './LSHeadsUpTourenyPlayerSequence';

export class ResponseLSHeadsUpTourenyPlayerSequence extends Message {
    private MESSAGE_NAME: string = 'ResponseLSHeadsUpTourenyPlayerSequence';
    private lsHeadsUpTourenyPlayerSequence: LSHeadsUpTourenyPlayerSequence | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lsHeadsUpTourenyPlayerSequence: LSHeadsUpTourenyPlayerSequence | null = null) {
        super(reqServerPeerId);
        this.lsHeadsUpTourenyPlayerSequence = lsHeadsUpTourenyPlayerSequence;
    }

    getLsHeadsUpTourenyPlayerSequence(): LSHeadsUpTourenyPlayerSequence | null {
        return this.lsHeadsUpTourenyPlayerSequence;
    }

    setLsHeadsUpTourenyPlayerSequence(lsHeadsUpTourenyPlayerSequence: LSHeadsUpTourenyPlayerSequence | null) {
        this.lsHeadsUpTourenyPlayerSequence = lsHeadsUpTourenyPlayerSequence;
    }
}
