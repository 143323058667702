import { Message } from '../../message';

export class ResponseTableDetailsList extends Message {
    private MESSAGE_NAME: string = 'ResponseTableDetailsList';
    private tabIdTableDetailsMap: Map<any, any> | null = null;
    private clearAllTabIdSnapShots: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tabIdTableDetailsMap: Map<any, any> | null = null, clearAllTabIdSnapShots: boolean | null = false) {
        super(reqServerPeerId);
        this.tabIdTableDetailsMap = tabIdTableDetailsMap;
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
    }

    getTabIdTableDetailsMap(): Map<any, any> | null {
        return this.tabIdTableDetailsMap;
    }

    setTabIdTableDetailsMap(tabIdTableDetailsMap: Map<any, any> | null) {
        this.tabIdTableDetailsMap = tabIdTableDetailsMap;
    }
    getClearAllTabIdSnapShots(): boolean | null {
        return this.clearAllTabIdSnapShots;
    }

    setClearAllTabIdSnapShots(clearAllTabIdSnapShots: boolean | null) {
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
    }
}
