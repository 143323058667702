import { Properties } from '../utils/properties';
import { Card } from './card';
import { Chip } from './chip';
import { Dealer } from './dealer';
import { Player } from './player';
import { Point } from './point';
import { Pot } from './pot';
import { Seat } from './seat';
import { TableEvent } from './table-event';
import { TableEvents } from './table-events.enum';

export class GameTable {
    private _name: string;
    private _seats: Seat[];
    private _players: Player[];
    private _pot: Pot;
    private _totalPot: Pot;
    private _communityCards: Card[];
    private _maxSeats: number;
    private _events: TableEvent[];
    private _dealer: Dealer;
    private _gameType: string;
    private _changeChip = false;
    private _changeChipPos: Point;
    private _sidePot: Chip;
    private _disableNextButton: any = false;
    private _disablePrevButton: any = true;
    private _potMap = new Map<number, number>();
    private _extraAmountCalculationMap: any = new Map<number, number>();
    private _playerVsExtraChip = new Map<number, number>();
    private _winHighMap: any = new Map<number, number>();
    private _roundVsExtraChips: any = [];
    private _roundVsPlayerExtraChips = new Map<number, number>();
    private _potsize: any;
    private _tableViewCommunitycards: any = [];
    private _replay: boolean = false;
    private _currentBettingRound: any;
    private _createdActivePotId: any;
    private _potId: any;
    private _actualGameName: any;
    private _directShowDownEventValue: any;

    private _totalAnteAmount: any;
    private _tillFlopMainPotValue: any;
    private _cardAnimation: any = false;
    private _totalNumberofEvents: number;
    private _isShortDeck: any;

    //Showdown variables
    private _showTable: any = false;
    private _onInit: boolean = true;
    private _isTableLoaded: boolean = false;

    //increasing the speedBar value
    private _gameSpeedBarSetter: any;
    private _accumulateChipSetTimeOutValue: any;

    //Antecash game Variables
    private _isAnteCashGameOrTourny: number;
    private _anteAmount: number;

    //RIT Game Variables
    private _isRit: boolean = false;
    private _isRitEnabled: boolean = false;
    private _totalNumberOfCommunityCards: Number;
    private _potArrayMap = new Map<number, any>();
    private _uncalledBetPostedPlayer: any;
    private _uncalledBetGivenBacktoPlayer: boolean = false;

    //RIT Game Variables End

    playersMap = new Map<number, Player>();
    eventMap = new Map<TableEvents, Map<any, Chip>>();
    cardMap = new Map<number, any>();
    roundChipAmount = new Map<any, any>();
    public chipMap = new Map<any, number>();
    playerCardMap = new Map<number, any>();
    playerFourthCardMap = new Map<number, any>();
    playerFifthCardMap = new Map<number, any>();
    playerSixthCardMap = new Map<number, any>();
    playerSeventhCardMap = new Map<number, any>();
    communityCardMap = new Map<any, any>();
    playerAnteChips = new Map<any, any>();
    private mPots = false;
    public pause = false;
    public startbtndisable: boolean;
    public roundID: any;
    public gameLimit: any;
    public gType: any;
    public smallBlind: any;
    public bigBlind: any;
    public mainPotChipvalue: any;
    timer: any;
    private _playerWInAmount: any;
    k = 0;
    n = 0;
    totalRake: any = 0;
    private _sidePotCreatedAmount: any;
    private _calculatedMainpotValue: any;
    private _activePot: any;
    public pots: any = [];
    private _rakeAmount: any;
    public roundObjects: any = {};
    private _isTourny: boolean = false;
    private _rakeMap: any = new Map<any, any>();
    private _rakePosition: any;
    private _rakeFromSidePot: boolean;
    private _gameRoundDetails: any;
    private _lastLockedPotAmount: any = 0;
    private _rakeTillShowdown: any;
    private _unCalledFlag: any;
    private _unCalledBetAmount: any;
    public audio = new Audio('pictures/sounds/alert1.mp3');

    private _presentBettingRound: any;
    eventName: any;

    public mainPotAmounttillPotCreated: number;
    public playermainPotWinAmount: any;
    private timeOutTime: any = 800;

    public sidePotRound: any = false;
    public totalPotAmount: any = 0;
    private _extra: any = 0;

    private _ROUND_POT = new Pot();
    private _MAIN_POT = new Pot();
    private _SIDE_POTS = new Array<Pot>();

    private _POTVSPLAYERS = new Map();

    private _mainPotFreezed = false;

    public enableTableMask: boolean = false;
    private _replaySpeedValue: any;
    private _valueUpdatedPotId: any;
    private _mainPotLockedValue: any;
    public potCreatedObject: any = {};
    //AAMS ID for .it brnads
    private _requestingPlayerSessionAamsId: string;
    private _requestingPlayerUserAamsId: string;
    private _isAamsIdPresent: boolean = false;

    //potrait table variables
    private _isMobile: any = false;

    //Request Player dealer Seat
    private _isrequestingPlayerDealer: boolean;

    //replayer UncallbetAmount
    public _unCalledBetAmountDetails: any;
    public isUnCalledAmountMatched: boolean = false;
    private _isUncalledBet: boolean = false;
    private _isUSUncalledbet: boolean = false;

    //Replayer3.0
    public currentActivePot: number = 0;
    private _allInSeatSequence = new Array<Pot>();
    public _isReplayerV3: boolean;
    public existingpotTotalValue: number = 0;
    public getTotalSidePotslength: number = 0;
    private _getTotalPotIdsAtWinhigh: any = [];
    private _WinPotSeatMap = new Map<number, number>();
    public playerVsWinAmout = new Map<number, number>();
    public RITplayerVsWinAmount = new Map<number, number>();

    //replayer cashout
    public playerCashoutMap = new Map<number, number>();
    public isCashedOut: boolean = false;
    public CashedOutPopUp: boolean = false;
    public cashOutOffered: boolean;
    public cashOutOfferedinHHparser: boolean;
    public CashedOutPopUpinFlop: boolean;
    public CashedOutPopUpinTurn: boolean;
    public CashedOutPopUpinRiver: boolean;
    public requestingPlayerSeatNo: number;
    private _decisions: any = [];
    public smallControlls: any = [];
    public bigControlls: any = [];
    public currentPlayer: any;
    public isShowControlls: boolean = false;
    public extraBetAmount: number = 0;
    public displayValues: any = [];
    public controlsType: any = '';
    public isGameInProgres: boolean = false;
    public miniTableCards: any = [];
    public isGameCompleted: boolean = false;
    public gameInfo: boolean = false;
    public postMsg: any = '';
    public isSoundsOn = true;
    public animationType: number;
    public showCustomBetMsg = false;
    public playerEvtMinTime: number = 10;
    public playerEvtMaxTime: number = 20;
    public eventTime: number = 2;
    public showTime: number = 1;
    public playerRating: number = 1;
    public isShowGameInfo: boolean = true;
    public isShowWizAnimation: boolean = true;
    public show_bubble: boolean = false;

    constructor() {
        this._seats = [];
        this._players = [];
        this._pot = new Pot();
        this._communityCards = [];
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get tillFlopMainPotValue(): any {
        return this._tillFlopMainPotValue;
    }

    set tillFlopMainPotValue(value: any) {
        this._tillFlopMainPotValue = value;
    }

    get sidepotcreatedamount(): any {
        return this._sidePotCreatedAmount;
    }

    set sidepotcreatedamount(value: any) {
        this._sidePotCreatedAmount = value;
    }

    get totalAnteAmount(): any {
        return this._totalAnteAmount;
    }

    set totalAnteAmount(value: any) {
        this._totalAnteAmount = value;
    }

    get unCalledFlag(): any {
        return this._unCalledFlag;
    }

    set unCalledFlag(value: any) {
        this._unCalledFlag = value;
    }

    get unCalledBetAmount(): any {
        return this._unCalledBetAmount;
    }

    set unCalledBetAmount(value: any) {
        this._unCalledBetAmount = value;
    }

    get calculatedMainpotValue(): any {
        return this._calculatedMainpotValue;
    }

    set calculatedMainpotValue(value: any) {
        this._calculatedMainpotValue = value;
    }

    get rakeTillShowdown(): any {
        return this._rakeTillShowdown;
    }

    set rakeTillShowdown(value: any) {
        this._rakeTillShowdown = value;
    }

    get potId(): any {
        return this._potId;
    }

    set potId(value: any) {
        this._potId = value;
    }

    get rakeamount(): any {
        return this._rakeAmount;
    }

    set rakeamount(value: any) {
        this._rakeAmount = value;
    }

    get directShowDownEventValue(): any {
        return this._directShowDownEventValue;
    }

    set directShowDownEventValue(value: any) {
        this._directShowDownEventValue = value;
    }

    get createdActivePotId(): any {
        return this._createdActivePotId;
    }

    set createdActivePotId(value: any) {
        this._createdActivePotId = value;
    }

    get lastLockedPotAmount(): any {
        return this._lastLockedPotAmount;
    }

    set lastLockedPotAmount(value: any) {
        this._lastLockedPotAmount = value;
    }

    get actualGameName(): any {
        return this._actualGameName;
    }

    set actualGameName(value: any) {
        this._actualGameName = value;
    }

    get gameRoundDetails(): any {
        return this._gameRoundDetails;
    }

    set gameRoundDetails(value: any) {
        this._gameRoundDetails = value;
    }

    get currentBettingRound(): any {
        return this._currentBettingRound;
    }

    set currentBettingRound(value: any) {
        this._currentBettingRound = value;
    }

    get rakeMap(): any {
        return this._rakeMap;
    }

    set rakeMap(value: any) {
        this._rakeMap = value;
    }

    get rakePosition(): any {
        return this._rakePosition;
    }

    set rakePosition(value: any) {
        this._rakePosition = value;
    }

    get rakeFromSidePot(): boolean {
        return this._rakeFromSidePot;
    }

    set rakeFromSidePot(value: boolean) {
        this._rakeFromSidePot = value;
    }

    get replaySpeedValue(): any {
        return this._replaySpeedValue;
    }

    set replaySpeedValue(value: any) {
        this._replaySpeedValue = value;
    }

    get timeOut(): any {
        return this.timeOutTime;
    }

    set timeOut(value: any) {
        this.timeOutTime = value;
    }

    get potSize(): any {
        return this._potsize;
    }

    set potSize(value: any) {
        this._potsize = value;
    }

    get seats(): Seat[] {
        return this._seats;
    }

    set seats(value: Seat[]) {
        this._seats = value;
    }

    get textViewCommunityCards(): any[] {
        return this._tableViewCommunitycards;
    }

    set textViewCommunityCards(value: any[]) {
        this.textViewCommunityCards = value;
    }

    get playerVsExtraChip(): Map<number, number> {
        return this._playerVsExtraChip;
    }

    set playerVsExtraChip(value: Map<number, number>) {
        this._playerVsExtraChip = value;
    }

    get gameType(): string {
        return this._gameType;
    }

    set gameType(value: string) {
        this._gameType = value;
    }

    get isShortDeck(): boolean {
        return this._isShortDeck;
    }

    set isShortDeck(value: boolean) {
        this._isShortDeck = value;
    }

    get playerWinAmount(): any {
        return this._playerWInAmount;
    }

    set playerWinAmount(value: any) {
        this._playerWInAmount = value;
    }

    get winHighMap(): any {
        return this._winHighMap;
    }

    set winHighMap(value: any) {
        this._winHighMap = value;
    }

    get disableNextButton(): any {
        return this._disableNextButton;
    }

    set disableNextButton(value: any) {
        this._disableNextButton = value;
    }

    get disablePrevButton(): any {
        return this._disablePrevButton;
    }

    set disablePrevButton(value: any) {
        this._disablePrevButton = value;
    }
    get changeChip(): boolean {
        return this._changeChip;
    }

    set changeChip(value: boolean) {
        this._changeChip = value;
    }

    get replay(): boolean {
        return this._replay;
    }

    set replay(value: boolean) {
        this._replay = value;
    }

    get players(): Player[] {
        return this._players;
    }

    set players(value: Player[]) {
        this._players = value;
    }

    get pot(): Pot {
        return this._pot;
    }

    set pot(value: Pot) {
        this._pot = value;
    }

    get totalPot(): Pot {
        return this._totalPot;
    }

    set totalPot(value: Pot) {
        this._totalPot = value;
    }

    get mpot(): boolean {
        return this.mPots;
    }

    set mpot(value: boolean) {
        this.mPots = value;
    }

    get communityCards(): Card[] {
        return this._communityCards;
    }

    set communityCards(value: Card[]) {
        this._communityCards = value;
    }

    get maxSeats(): number {
        return this._maxSeats;
    }

    set maxSeats(value: number) {
        this._maxSeats = value;
    }

    get dealer(): Dealer {
        return this._dealer;
    }

    set dealer(value: Dealer) {
        this._dealer = value;
    }
    get presentBettingRound(): any {
        return this._presentBettingRound;
    }

    set presentBettingRound(value: any) {
        this._presentBettingRound = value;
    }

    get extraAmountCalculationMap(): Map<number, number> {
        return this._extraAmountCalculationMap;
    }

    set extraAmountCalculationMap(value: Map<number, number>) {
        this._extraAmountCalculationMap = value;
    }

    get changeChipPos(): Point {
        return this._changeChipPos;
    }
    set changeChipPos(value: Point) {
        this._changeChipPos = value;
    }
    get isTourny(): boolean {
        return this._isTourny;
    }

    set isTourny(value: boolean) {
        this._isTourny = value;
    }

    get sidePot(): Chip {
        return this._sidePot;
    }

    set sidePot(value: Chip) {
        this._sidePot = value;
    }

    get activePot(): any {
        return this._activePot;
    }

    set activePot(v: any) {
        this._activePot = v;
    }

    get extra(): any {
        return this._extra;
    }

    set extra(v: any) {
        this._extra = v;
    }

    //Amount Updation Variables

    get valueUpdatedPotId(): any {
        return this._valueUpdatedPotId;
    }

    set valueUpdatedPotId(value: any) {
        this._valueUpdatedPotId = value;
    }

    get mainPotLockedValue(): any {
        return this._mainPotLockedValue;
    }

    set mainPotLockedValue(value: any) {
        this._mainPotLockedValue = value;
    }

    // Amount Update variables close
    //AAMS ID for .it brands

    get requestingPlayerSessionAamsId(): string {
        return this._requestingPlayerSessionAamsId;
    }
    set requestingPlayerSessionAamsId(value: string) {
        this._requestingPlayerSessionAamsId = value;
    }

    get requestingPlayerUserAamsId(): string {
        return this._requestingPlayerUserAamsId;
    }

    set requestingPlayerUserAamsId(value: string) {
        this._requestingPlayerUserAamsId = value;
    }

    get isAamsIdPresent(): boolean {
        return this._isAamsIdPresent;
    }

    set isAamsIdPresent(value: boolean) {
        this._isAamsIdPresent = value;
    }
    //Showdown variables

    get onInit(): any {
        return this._onInit;
    }

    set onInit(value: any) {
        this._onInit = value;
    }

    get cardAnimation(): any {
        return this._cardAnimation;
    }

    set cardAnimation(value: any) {
        this._cardAnimation = value;
    }

    get showTable(): any {
        return this._showTable;
    }

    set showTable(value: any) {
        this._showTable = value;
    }

    get isTableLoaded(): any {
        return this._isTableLoaded;
    }

    set isTableLoaded(value: any) {
        this._isTableLoaded = value;
    }

    get totalNumberofEvents(): number {
        return this._totalNumberofEvents;
    }

    set totalNumberofEvents(value: number) {
        this._totalNumberofEvents = value;
    }

    //GameSpeed increase variables

    get gameSpeedBarSetter(): any {
        return this._gameSpeedBarSetter;
    }
    set gameSpeedBarSetter(value: any) {
        this._gameSpeedBarSetter = value;
    }
    get accumulateChipSetTimeOutValue(): any {
        return this._accumulateChipSetTimeOutValue;
    }

    set accumulateChipSetTimeOutValue(value: any) {
        this._accumulateChipSetTimeOutValue = value;
    }

    //AnteCash game Variables

    get isAnteCashGameOrTourny(): number {
        return this._isAnteCashGameOrTourny;
    }

    set isAnteCashGameOrTourny(value: number) {
        this._isAnteCashGameOrTourny = value;
    }

    get anteAmount(): number {
        return this._anteAmount;
    }

    set anteAmount(value: number) {
        this._anteAmount = value;
    }

    //RIT gameVariables

    get isRit(): boolean {
        return this._isRit;
    }

    set isRit(value: boolean) {
        this._isRit = value;
    }

    get isRitEnabled(): boolean {
        return this._isRitEnabled;
    }

    set isRitEnabled(value: boolean) {
        this._isRitEnabled = value;
    }

    get uncalledBetPostedPlayer(): any {
        return this._uncalledBetPostedPlayer;
    }

    set uncalledBetPostedPlayer(value: any) {
        this._uncalledBetPostedPlayer = value;
    }

    get uncalledBetGivenBacktoPlayer(): boolean {
        return this._uncalledBetGivenBacktoPlayer;
    }

    set uncalledBetGivenBacktoPlayer(value: boolean) {
        this._uncalledBetGivenBacktoPlayer = value;
    }

    get totalNumberOfCommunityCards(): Number {
        return this._totalNumberOfCommunityCards;
    }

    set totalNumberOfCommunityCards(value: Number) {
        this._totalNumberOfCommunityCards = value;
    }

    get potArrayMap(): Map<number, any> {
        return this._potArrayMap;
    }

    set potArrayMap(value: Map<number, any>) {
        this._potArrayMap = value;
    }

    //RIT Game Variables End
    //ReplayerVersion 3.0

    get isReplayerV3(): any {
        return this._isReplayerV3;
    }

    set isReplayerV3(value: any) {
        this._isReplayerV3 = value;
    }

    get allInSeatSequence(): any {
        return this._allInSeatSequence;
    }

    set allInSeatSequence(value: any) {
        this._allInSeatSequence = value;
    }

    get winPotSeatMap(): Map<number, number> {
        return this._WinPotSeatMap;
    }

    set winPotSeatMap(value: Map<number, number>) {
        this._WinPotSeatMap = value;
    }

    get TotalPotIdsAtWinhigh(): any {
        return this._getTotalPotIdsAtWinhigh;
    }

    set TotalPotIdsAtWinhigh(value: any) {
        this._getTotalPotIdsAtWinhigh = value;
    }

    //ReplayerVersion 3.0  End

    //replayer UncalledBetAmountDetails
    get unCalledBetAmountDetails(): any {
        return this._unCalledBetAmountDetails;
    }

    set unCalledBetAmountDetails(value: any) {
        this._unCalledBetAmountDetails = value;
    }

    get isUnCalledBet(): any {
        return this._isUncalledBet;
    }

    set isUnCalledBet(value: any) {
        this._isUncalledBet = value;
    }

    get isUSUncalledbet(): any {
        return this._isUSUncalledbet;
    }

    set isUSUncalledbet(value: any) {
        this._isUSUncalledbet = value;
    }

    get isMobile(): boolean {
        return this._isMobile;
    }

    set isMobile(value: boolean) {
        this._isMobile = value;
    }

    get isrequestingPlayerDealer(): boolean {
        return this._isrequestingPlayerDealer;
    }

    set isrequestingPlayerDealer(value: boolean) {
        this._isrequestingPlayerDealer = value;
    }

    get decisions(): any[] {
        return this._decisions;
    }

    set decisions(value: any[]) {
        this._decisions = value;
    }

    createSeats() {
        if (!this._seats) {
            this._seats = [];
        }
        for (let i = 0; i < this._maxSeats; i++) {
            this._seats.push(new Seat(i, null));
        }
    }

    fillSeatsWithPlayers(players: Player[]) {
        players.forEach((player: any) => {
            const seat = this._seats[player.seatNo];
            seat.player = player.name;
            this.playersMap.set(player.seatNo, player);
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.playersMap.forEach((v, k) => {});

        this._players = players;
    }

    captureEvents(events: TableEvent[]) {
        this._events = events;
        this._totalNumberofEvents = events.length;
    }

    captureDecisions(decisions: any) {
        this._decisions = decisions;
    }

    getPlayer(seat: number): Player {
        return this.playersMap.get(seat)!;
    }

    prev(value: boolean) {
        if (this.k - 1 < 0) {
            clearInterval(this.timer);
            this.disablePrevButton = true;
            return;
        }
        if (this.k - 1 === 0) {
            clearInterval(this.timer);
            this.disablePrevButton = true;
        }
        if (this.replay) {
            this.replay = false;
        }
        this.onInit = false;
        this.disableNextButton = false;
        this.pause = false;
        const event = this._events[--this.k];
        this.eventName = TableEvents[event.getEventType()];
        this.totalNumberofEvents++;
        event.prev(this);
        if (value && this.timer) {
            clearInterval(this.timer);
            this.accumulateChipSetTimeOutValue = 500;
            this.gameSpeedBarSetter = 500;
        } else if (value) {
            this.accumulateChipSetTimeOutValue = 500;
            this.gameSpeedBarSetter = 500;
        }
    }

    next(value: boolean) {
        if (this.currentPlayer) {
            this.currentPlayer.playerTimer = false;
            this.currentPlayer.stopTimer();
        }

        if (this.k + 1 > this._events.length) {
            this.pause = false;
            this.isTableLoaded = true;
            this.showTable = true;
            this.onInit = false;
            this.disableNextButton = true;
            clearInterval(this.timer);
            return;
        }
        this.disablePrevButton = false;
        const event = this._events[this.k++];
        this.eventName = TableEvents[event.getEventType()];
        this.totalNumberofEvents--;
        if (this.eventName === 'WIN_HIGH' || this.totalNumberofEvents === 0) {
            event.next(this);
            if (this.postMsg === '' || this.postMsg == undefined) {
                this.postMsg = 'hand_common_msg';
            }
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const This = this;

            setTimeout(function () {
                This.isGameCompleted = true;
                This.showBubbleAnimation();
            }, This.showTime * 1000);
        } else if (this.eventName === 'HAND_STRENGTH') {
            event.next(this);
            this.playerTimer();
        } else if (this.eventName === 'PLAYER_HOLECARDS_EVENT' && this.isShowGameInfo) {
            event.next(this);
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const This = this;

            setTimeout(function () {
                This.gameInfo = true;
            }, This.eventTime * 400);
        } else if (
            this.requestingPlayerSeatNo == event.getSeatNumber() &&
            this.eventName != 'PLAYER_SHOW_CARDS' &&
            this.eventName != 'POST_SMALL_BLIND' &&
            this.eventName != 'POST_BIG_BLIND' &&
            this.eventName != 'HAND_STRENGTH'
        ) {
            this.showButton();
        } else {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const This = this;

            event.next(this);

            if (event.getSeatNumber() != undefined) {
                setTimeout(function () {
                    This.playerTimer();
                }, 400);
            } else {
                this.playerTimer();
            }
            if (value && this.timer) {
                clearInterval(this.timer);
                this.pause = false;
                this.accumulateChipSetTimeOutValue = 500;
                this.gameSpeedBarSetter = 500;
            } else if (value) {
                this.accumulateChipSetTimeOutValue = 500;
                this.gameSpeedBarSetter = 500;
            }
        }
    }

    showBubbleAnimation() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const This = this;

        setTimeout(function () {
            This.show_bubble = true;
        }, 3000);
    }

    showButton() {
        if (this.n >= 0) {
            this.isGameInProgres = true;
            this.isShowControlls = true;
            const obj = this.decisions[this.n];
            this.smallControlls = obj.smallLabels;
            this.bigControlls = obj.bigLables;
            this.controlsType = obj.controlsType;
            if (obj.displayAmounts != undefined) this.displayValues = obj.displayAmounts;
        }
    }

    play() {
        this.onInit = false;
        if (this.pause) {
            this.pause = false;
            this.startbtndisable = false;
            clearInterval(this.timer);
        } else {
            this.pause = true;
            this.startbtndisable = true;
            this.next(false);
        }
    }

    playerAction(val: any) {
        if (this.smallControlls.length > 0 && val === 'BET') {
            this.showCustomBetMsg = true;
        } else {
            this.isShowControlls = false;
            this.showCustomBetMsg = false;
            const obj = this.decisions[this.n];
            if (val === 'RAISE' || val === 'ALL IN') {
                const eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.raise);
                this.n = obj.raise_i;
                if (eventIndex >= 0) {
                    this.k = eventIndex;
                }
                if (this.n < 0) {
                    this.postMsg = obj.postHandMessages.raise;
                    this.animationType = obj.animation.raise.animationType;
                    this.playerRating = obj.animation.raise.star;
                }
                this.startIntervel();
            } else if (val === 'CALL') {
                const eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.call);
                this.n = obj.call_i;
                if (eventIndex >= 0) {
                    this.k = eventIndex;
                }
                if (this.n < 0) {
                    this.postMsg = obj.postHandMessages.call;
                    this.animationType = obj.animation.call.animationType;
                    this.playerRating = obj.animation.call.star;
                }
                this.startIntervel();
            } else if (val === 'BET') {
                const eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.bet);
                this.n = obj.bet_i;
                if (eventIndex >= 0) {
                    this.k = eventIndex;
                }
                if (this.n < 0) {
                    this.postMsg = obj.postHandMessages.bet;
                    this.animationType = obj.animation.bet.animationType;
                    this.playerRating = obj.animation.bet.star;
                }
                this.startIntervel();
            } else if (val === 'CHECK') {
                const eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.check);
                this.n = obj.check_i;
                if (eventIndex >= 0) {
                    this.k = eventIndex;
                }
                if (this.n < 0) {
                    this.postMsg = obj.postHandMessages.check;
                    this.animationType = obj.animation.check.animationType;
                    this.playerRating = obj.animation.check.star;
                }
                this.startIntervel();
            } else if (val === 'FOLD') {
                const eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.fold);
                this.n = obj.fold_i;
                if (eventIndex >= 0) {
                    this.k = eventIndex;
                }
                if (obj.fold == 'D2F') {
                    if (eventIndex >= 0) {
                        this.k = eventIndex;
                    }
                    this.postMsg = obj.postHandMessages.fold;
                    this.animationType = obj.animation.fold.animationType;
                    this.playerRating = obj.animation.fold.star;
                    this.startIntervel();
                } else {
                    const event = this._events[this.k++];
                    this.eventName = TableEvents[event.getEventType()];
                    this.totalNumberofEvents--;
                    event.next(this);
                    this.postMsg = obj.postHandMessages.fold;
                    this.animationType = obj.animation.fold.animationType;
                    this.playerRating = obj.animation.fold.star;
                    this.isGameCompleted = true;
                }
            }
        }
    }
    playerBetAction(val: any) {
        this.isShowControlls = false;
        this.showCustomBetMsg = false;
        const obj = this.decisions[this.n];
        let eventIndex = -1;
        if (val === '1') {
            eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.bet1);
            this.n = obj.bet1_i;
            if (this.n < 0) {
                this.postMsg = obj.postHandMessages.bet1;
                this.animationType = obj.animation.bet1.animationType;
                this.playerRating = obj.animation.bet1.star;
            }
        } else if (val === '2') {
            if (this.smallControlls[2] === 'ALL IN') {
                eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.raise);
                this.n = obj.raise_i;
                if (this.n < 0) {
                    this.postMsg = obj.postHandMessages.raise;
                    this.animationType = obj.animation.raise.animationType;
                    this.playerRating = obj.animation.raise.star;
                }
            } else {
                eventIndex = this._events.findIndex((item) => item.getEventIndex() == obj.bet2);
                this.n = obj.bet2_i;
                if (this.n < 0) {
                    this.postMsg = obj.postHandMessages.bet2;
                    this.animationType = obj.animation.bet2.animationType;
                    this.playerRating = obj.animation.bet2.star;
                }
            }
        }
        if (eventIndex >= 0) {
            this.k = eventIndex;
        }
        this.startIntervel();
    }

    startIntervel() {
        const event = this._events[this.k++];
        this.eventName = TableEvents[event.getEventType()];
        this.totalNumberofEvents--;
        event.next(this);
        this.playerTimer();
    }

    playerTimer() {
        const event = this._events[this.k];
        let num = this.eventTime * 100;
        if (
            TableEvents[event.getEventType()] == 'POST_BIG_BLIND' ||
            TableEvents[event.getEventType()] == 'PLAYER_SHOW_CARDS' ||
            TableEvents[event.getEventType()] == 'HAND_STRENGTH' ||
            TableEvents[event.getEventType()] == 'WIN_HIGH'
        ) {
            num = this.eventTime * 100;
        } else if (event.getSeatNumber() != undefined && this.requestingPlayerSeatNo != event.getSeatNumber()) {
            this.currentPlayer = this.getPlayer(event.getSeatNumber());
            this.currentPlayer.playerTimer = true;
            this.currentPlayer.startTimer();
            this.isGameInProgres = true;
            num = this.getRandomInt(this.playerEvtMinTime, this.playerEvtMaxTime) * 100;
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const This = this;
        setTimeout(function () {
            This.next(false);
        }, num);
    }

    getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    reset() {
        this.disablePrevButton = true;
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    toShowDown() {
        this.startbtndisable = true;
        for (let i = 0; i < this._events.length; i++) {
            this.next(false);
        }
    }

    addToPot(amount: number) {
        this._pot.addChip(new Chip(amount));
        this.ROUND_POT.addChip(new Chip(amount));
    }

    substractFromPot(amount: number) {
        this._pot.removeChip(new Chip(amount));
        this.ROUND_POT.removeChip(new Chip(amount));
    }

    addCommunityCard(card: Card) {
        this._communityCards.push(card);
    }
    removeCommunityCards(card: any) {
        this._communityCards.splice(this._communityCards.indexOf(card), 1);
    }

    moveToPot() {
        if (this.mpot) {
            this.mpot = !this.mpot;
        } else {
            this.mpot = true;
        }
        return this.mpot;
    }
    accumulateChip(potId: any, accumulate: boolean) {
        this.playersMap.forEach((player) => {
            let pChipPos: any;
            if (accumulate) {
                if (
                    this.SIDE_POTS.length > 0 &&
                    this.currentBettingRound > 1 &&
                    this.SIDE_POTS[this.SIDE_POTS.length - 1].totalValue > 0 &&
                    !this.isRitEnabled
                ) {
                    pChipPos = Properties.propFile['COMMONSECTION'][`SIDEPOTPOS${this.SIDE_POTS.length}`].split('#');
                } else if (
                    this.SIDE_POTS.length > 1 &&
                    this.currentBettingRound > 2 &&
                    this.SIDE_POTS[this.SIDE_POTS.length - 1].totalValue <= 0 &&
                    !this.isRitEnabled
                ) {
                    pChipPos = Properties.propFile['COMMONSECTION'][`SIDEPOTPOS${this.SIDE_POTS.length - 1}`].split('#');
                } else {
                    if (this.isRitEnabled) {
                        pChipPos = Properties.propFile[this.gameType]['RITMAINPOTCHIPPOS'].split('#');
                    } else {
                        pChipPos = Properties.propFile[this.gameType]['MAINPOTCHIPPOS'].split('#');
                    }
                }
            } else {
                pChipPos = Properties.propFile[this.gameType][`CHIPPOS${player.seatNo}`].split('#');
            }
            player.playerChipPos = new Point(pChipPos[0], pChipPos[1]);
        });
        this.enableTableMask = true;
        setTimeout(() => {
            this.changeChip = true;
        }, this.accumulateChipSetTimeOutValue);
    }
    revertChip(potId: any) {
        const sidepots = this.pot.sidePots;
        sidepots.forEach((sidepot: any) => {
            if (potId == sidepot.id) {
                sidepot.view = false;
            }
        });
        this.changeChip = false;
    }

    get potMap(): Map<number, number> {
        return this._potMap;
    }

    set potMap(value: Map<number, number>) {
        this._potMap = value;
    }

    createSidePot(pot: Pot) {
        pot.sidePots.push(pot);
    }
    get MAIN_POT(): Pot {
        return this._MAIN_POT;
    }

    set MAIN_POT(value: Pot) {
        this._MAIN_POT = value;
    }

    get SIDE_POTS(): Pot[] {
        return this._SIDE_POTS;
    }

    set SIDE_POTS(value: Pot[]) {
        this._SIDE_POTS = value;
    }

    get ROUND_POT(): Pot {
        return this._ROUND_POT;
    }

    set ROUND_POT(value: Pot) {
        this._ROUND_POT = value;
    }

    get POTVSPLAYERS(): Map<any, any> {
        return this._POTVSPLAYERS;
    }

    set POTVSPLAYERS(value: Map<any, any>) {
        this._POTVSPLAYERS = value;
    }

    get mainPotFreezed(): boolean {
        return this._mainPotFreezed;
    }

    set mainPotFreezed(value: boolean) {
        this._mainPotFreezed = value;
    }

    get roundVsExtraChips(): any[] {
        return this._roundVsExtraChips;
    }

    set roundVsExtraChips(value: any[]) {
        this._roundVsExtraChips = value;
    }

    get roundVsPlayerExtraChips(): Map<any, any> {
        return this._roundVsPlayerExtraChips;
    }

    set roundVsPlayerExtraChips(value: Map<any, any>) {
        this._roundVsPlayerExtraChips = value;
    }

    freezeMainPot(playerContributionToMainPot: any) {
        this._mainPotFreezed = true;
        this.mainPotAmounttillPotCreated = this.MAIN_POT.totalValue;
        this.mainPotLockedValue = Number(playerContributionToMainPot);
        this.tillFlopMainPotValue = this.MAIN_POT.totalValue;
        if (this.presentBettingRound) {
            this.roundObjects[this.presentBettingRound] = this.MAIN_POT.totalValue;
        }
    }

    resetPots() {
        this.totalPotAmount = 0;
        const t = [this.MAIN_POT, ...this.SIDE_POTS];
        this.playermainPotWinAmount = t[0].totalValue;
        if (this.SIDE_POTS.length) {
            this.sidePotRound = true;
        }
        t.forEach((p) => {
            this.totalPotAmount += Number(p.totalValue);
        });
        this.ROUND_POT.totalValue = 0;
        this.SIDE_POTS = [];
        this.MAIN_POT.totalValue = 0;
    }

    playerPosition: any = 0;
    alreadyCalled: boolean = false;
    constructPotsFromPotMap(value: any) {
        this.alreadyCalled = false;
        if (value) {
            let totalPotValue = 0;
            let points: any;
            this.potMap.forEach((amount, pot) => {
                totalPotValue += amount;
                if (pot === 0 && !this.isReplayerV3) {
                    this.MAIN_POT.totalValue = amount;
                } else {
                    const sidePot = new Pot();
                    if (this.isReplayerV3 && this.allInSeatSequence.length > 0) {
                        if (pot < this.allInSeatSequence.length) {
                            this.playerPosition = this.allInSeatSequence[pot];
                            points = Properties.propFile[this.gameType][`SIDEPOS${this.playerPosition}`].split('#');
                        }
                    } else if (this.potMap.size > 0 && this.isReplayerV3 && this.allInSeatSequence.length === 0) {
                        if (pot === 0) {
                            for (const player in this.players) {
                                if (this.players[Number(player)] != this.players[Number(player) + 1]) {
                                    if (this.players[Number(player)].toPot.value > this.players[Number(player) + 1].toPot.value) {
                                        this.playerPosition = this.players[Number(player)].seatNo;
                                    } else {
                                        this.playerPosition = this.players[Number(player) + 1].seatNo;
                                    }
                                }
                                break;
                            }
                            points = Properties.propFile[this.gameType][`SIDEPOS${this.playerPosition}`].split('#');
                        } else {
                            return;
                        }
                    } else {
                        this.playerPosition = this.SIDE_POTS.length;
                        points = Properties.propFile['COMMONSECTION'][`SIDEPOTPOS${pot}`].split('#');
                    }
                    sidePot.position = new Point(points[0], points[1]);
                    sidePot.potId = pot;
                    sidePot.totalValue = amount;
                    sidePot.showPot = true;
                    if (this.isUnCalledBet && !this.alreadyCalled) {
                        if (sidePot.totalValue != this.unCalledBetAmountDetails['uncalledUnit'].unit) {
                            this.SIDE_POTS.push(sidePot);
                        } else {
                            totalPotValue = totalPotValue - sidePot.totalValue;
                            this.alreadyCalled = true;
                        }
                    } else {
                        this.SIDE_POTS.push(sidePot);
                    }
                }
                if (this.isReplayerV3 && this.allInSeatSequence.length) {
                    if (this.SIDE_POTS.length != this.allInSeatSequence.length && this.SIDE_POTS.length === this.allInSeatSequence.length + 1) {
                        this.currentActivePot = this.SIDE_POTS[this.allInSeatSequence.length].totalValue;
                        this.SIDE_POTS.pop();
                    }
                }
            });
            this.ROUND_POT.totalValue = totalPotValue;
            this.alreadyCalled = false;
        } else if (this._extra >= 0) {
            this.ROUND_POT.totalValue = this.totalPotAmount + this._extra;
            this.MAIN_POT.totalValue = this.ROUND_POT.totalValue - this._extra;
        }
    }
    addWinAmountToPlayer(p: Player) {
        const player = this.playersMap.get(p.seatNo) ?? new Player();
        player.toPot.value = p.toPot.value;
        const winChipPos = Properties.propFile[this.gameType][`CHIPPOS${Number(p.seatNo)}`].split('#');
        player.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
        player.chips = p.chips;
    }
    addRitWinAmountToPlayer(p: Player) {
        const player = this.getPlayer(p.seatNo);
        player.toPot.value = p.toPot.value;
        const winChipPos = Properties.propFile[this.gameType][`CHIPPOS${Number(p.seatNo)}`].split('#');
        player.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
        player.chips = p.chips;
    }

    addRake(r: any) {
        this.totalRake = this.totalRake + r;
        return this.totalRake;
    }

    substractRake(r: any) {
        this.totalRake = this.totalRake - r;
    }
}
