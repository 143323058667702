import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class Fold extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.FOLD;
    }

    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.FOLD;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.playerStatus = this.getPlayerStatus();
        player.getOptionSet(true);
        if (!gameTable.onInit) {
            player.highlightEvent();
        }
        if (!player.isPlayerRequesting) {
            player.removeFoldCard(false);
        } else {
            player.isSelfPlayerfolded = true;
        }
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.playerStatus = this.getPlayerStatus();
        player.getOptionSet(false);
        player.playerStatus = this.getPlayerStatus();
        if (!player.isPlayerRequesting) {
            player.removeFoldCard(true);
        } else {
            player.isSelfPlayerfolded = false;
        }
    }
}
