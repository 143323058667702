import CustomEvent from 'custom-event-js';

export class EventDispatcherGlobal {
    private customEvent;
    constructor() {
        this.customEvent = CustomEvent;
    }
    dispatchEvent(funcName: string, params: any): void {
        if (params != undefined) {
            this.customEvent.dispatch(funcName, params);
        } else {
            this.customEvent.dispatch(funcName);
        }
    }

    addEventListener(eventName: string, callback: any): void {
        this.customEvent.on(eventName, callback);
    }

    removeEventListener(eventName: string): void {
        this.customEvent.off(eventName);
    }
}
