import { Routes } from '@angular/router';

import { PokerReferAFriendSynopsisResolver } from './poker-refer-a-friend-synopsis.resolver';
import { PokerReferAFriendResolver } from './poker-refer-a-friend.resolver';

export const pokerReferAFriendRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerReferAFriendResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerReferAFriendSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerReferAFriendResolver,
        },
    },
];
