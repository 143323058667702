import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { NativeAppService, TrackingService } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { PageNotFoundComponent } from '@pokercore/module/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { formatCurrencyPipe } from '../poker-schedule-feed/formatCurrency.pipe';
import { BigWinResolver } from './bigwin.resolver';
import { BigWinService } from './bigwin.service';

interface MessageToNativeInterface {
    closeContainer(param: any): void;
    getRtmsData(eventNm: any): void;
    withdrawEvent(eventNm: any): void;
    keepPlayingEvent(eventNm: any): void;
}
declare let MessageToNative: MessageToNativeInterface;

@Component({
    selector: 'pk-bigwin',
    templateUrl: 'bigwin.component.html',
    standalone: true,
    imports: [CommonModule, PageNotFoundComponent],
    providers: [formatCurrencyPipe, BigWinResolver],
})
export class BigWinComponent implements OnInit {
    initData: any;
    basetemplateId: any;
    currencyParam: string;
    balanceParam: number;
    bigWinTrackingId: number;
    locationEvent: string;
    positionEvent: string;
    private readonly logger: PokerLogger;

    constructor(
        public bigwinService: BigWinService,
        private nativeApp: NativeAppService,
        private formatCurrencyPipe: formatCurrencyPipe,
        loggerFactory: LoggerFactory,
        private routeData: RouteDataService,
        private vanillaTrackingService: TrackingService,
    ) {
        this.logger = loggerFactory.getLogger('PokerLoggingComponent');
    }

    ngOnInit(): void {
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Android/)) {
            MessageToNative.getRtmsData('GET_BIGWIN_DATA');
        } else this.requestRTMSContent();
        this.logger.info(` bigwin notification user agent :` + userAgent);
        this.nativeApp.eventsFromNative.subscribe((e) => {
            this.logger.info(` bigwin notification json :` + JSON.stringify(e));
            this.currencyParam = e.parameters?.Currency;
            this.balanceParam = e.parameters?.currentBalance;
            this.bigWinTrackingId = e.parameters?.bigWinTrackingId;
            this.locationEvent = e.parameters?.locationEvent;
            this.positionEvent = e.parameters?.positionEvent;
        });

        this.initData = this?.routeData?.getInitData();

        this.vanillaTrackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'deposit_prompt',
            'component.LabelEvent': 'big balance prompt',
            'component.ActionEvent': 'load',
            'component.PositionEvent': this.positionEvent,
            'component.LocationEvent': this.locationEvent,
            'component.EventDetails': 'big balance prompt',
            'page.bigWinTrackingId': this.bigWinTrackingId,
            'component.URLClicked': 'not applicable',
        });
    }

    withdraw() {
        const userAgent: any = navigator.userAgent;
        const clientEvent = this.initData.cashierCTA[0]['nativeEvent']; //"app:OPEN_CASHIER";
        if (clientEvent) {
            if (userAgent.match(/Android/)) {
                MessageToNative.withdrawEvent(clientEvent);
                MessageToNative.closeContainer('closeContainer');
            } else {
                this.nativeApp.sendToNative({ eventName: clientEvent, parameters: {} });
                this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
            }

            this.vanillaTrackingService.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'deposit_prompt',
                'component.LabelEvent': 'big balance prompt',
                'component.ActionEvent': 'click',
                'component.PositionEvent': this.positionEvent,
                'component.LocationEvent': this.locationEvent,
                'component.EventDetails': 'withdraw cta',
                'page.bigWinTrackingId': this.bigWinTrackingId,
                'component.URLClicked': 'not applicable',
            });
        }
    }

    keepPlaying() {
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Android/)) {
            MessageToNative.closeContainer('closeContainer');
        } else {
            this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
        }

        this.vanillaTrackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'deposit_prompt',
            'component.LabelEvent': 'big balance prompt',
            'component.ActionEvent': 'click',
            'component.PositionEvent': this.positionEvent,
            'component.LocationEvent': this.locationEvent,
            'component.EventDetails': 'keep playing cta',
            'page.bigWinTrackingId': this.bigWinTrackingId,
            'component.URLClicked': 'not applicable',
        });
    }

    closeContainer() {
        if (this.nativeApp.isDownloadClient) this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
    }
    requestRTMSContent() {
        this.nativeApp.sendToNative({ eventName: 'GET_BIGWIN_DATA' });
    }

    getFormatCurrency(symbol: string, unit: number) {
        return this.formatCurrencyPipe.transform(symbol, unit);
    }
}
