import { Message } from '../../message';

export class PlayerSearchResult extends Message {
    private MESSAGE_NAME: string = 'PlayerSearchResult';
    private playerId: string | null = null;
    private foundTables: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerId: string | null = null, foundTables: any[] | null = null) {
        super(reqServerPeerId);
        this.playerId = playerId;
        this.foundTables = foundTables;
    }

    getPlayerId(): string | null {
        return this.playerId;
    }

    setPlayerId(playerId: string | null) {
        this.playerId = playerId;
    }
    getFoundTables(): any[] | null {
        return this.foundTables;
    }

    setFoundTables(foundTables: any[] | null) {
        this.foundTables = foundTables;
    }
}
