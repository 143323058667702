<div class="contentWrap">
    <div class="col">
        <div *ngIf="casinotickerEnabled && communityblurbEnabled && sportsblurbEnabled">
            <div class="card-deck text-center row d-flex flex-wrap justify-content-center py-4">
                <div class="mb-3 mb-sm-3 mb-md-0 col-md-4 col-sm-6 px-sm-1 px-md-2" *ngIf="casinotickerEnabled">
                    <div class="card shadow casino-ticker m-0">
                        <div class="jackpotWrap">
                            <pt-jackpot-ticker></pt-jackpot-ticker>
                            <img *ngIf="casinoimageContent.image" [src]="casinoimageContent.image.src" alt="casino" class="card-img-top img-fluid" />
                        </div>
                        <div class="card-body">
                            <h3>{{ casinotickerContent.title }}</h3>
                            <div [innerHtml]="casinotickerContent.text"></div>
                            <a
                                class="btn btn-primary"
                                *ngIf="casinotickerContent.titleLink"
                                [href]="casinotickerContent.titleLink.url"
                                [target]="casinotickerContent.titleLink.attributes.target || '_self'"
                                >{{ casinotickerContent.titleLink.text }}</a
                            >
                        </div>
                    </div>
                </div>

                <div class="mb-3 mb-sm-3 mb-md-0 col-md-4 col-sm-6 px-sm-1 px-md-2" *ngIf="sportsblurbEnabled">
                    <div class="card shadow sports-blurb m-0">
                        <img *ngIf="sportsimageContent.image" [src]="sportsimageContent.image.src" alt="sports" class="card-img-top img-fluid" />
                        <div class="card-body">
                            <h3>{{ sportsblurbContent.title }}</h3>
                            <div [innerHtml]="sportsblurbContent.text"></div>
                            <a
                                class="btn btn-primary"
                                *ngIf="sportsblurbContent.titleLink"
                                [href]="sportsblurbContent.titleLink.url"
                                [target]="sportsblurbContent.titleLink.attributes.target || '_self'"
                                >{{ sportsblurbContent.titleLink.text }}</a
                            >
                        </div>
                    </div>
                </div>

                <div class="mb-3 mb-sm-3 mb-md-0 col-md-4 col-sm-6 px-sm-1 px-md-2" *ngIf="communityblurbEnabled">
                    <div class="card shadow community-blurb m-0">
                        <img
                            *ngIf="communityimageContent.image"
                            [src]="communityimageContent.image.src"
                            alt="community"
                            class="card-img-top img-fluid" />
                        <div class="card-body">
                            <h3>{{ communityblurbContent.title }}</h3>
                            <div [innerHtml]="communityblurbContent.text"></div>
                            <div class="social-icons row d-flex flex-wrap justify-content-center">
                                <a *ngIf="blogiconContent.imageLink" [href]="blogiconContent.imageLink.url" target="_blank"
                                    ><img [src]="blogiconContent.image.src" alt="blog"
                                /></a>
                                <a *ngIf="facebookiconContent.imageLink" [href]="facebookiconContent.imageLink.url" target="_blank"
                                    ><img [src]="facebookiconContent.image.src" alt="facebbok"
                                /></a>
                                <a *ngIf="twittericonContent.imageLink" [href]="twittericonContent.imageLink.url" target="_blank"
                                    ><img [src]="twittericonContent.image.src" alt="twitter"
                                /></a>
                                <a *ngIf="twitchiconContent.imageLink" [href]="twitchiconContent.imageLink.url" target="_blank"
                                    ><img [src]="twitchiconContent.image.src" alt="twitch"
                                /></a>
                                <a *ngIf="youtubeiconContent.imageLink" [href]="youtubeiconContent.imageLink.url" target="_blank"
                                    ><img [src]="youtubeiconContent.image.src" alt="youtube"
                                /></a>
                                <a *ngIf="instagramiconContent.imageLink" [href]="instagramiconContent.imageLink.url" target="_blank"
                                    ><img [src]="instagramiconContent.image.src" alt="instagram"
                                /></a>
                                <a *ngIf="myppliveiconContent.imageLink" [href]="myppliveiconContent.imageLink.url" target="_blank"
                                    ><img [src]="myppliveiconContent.image.src" alt="pplive"
                                /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
