import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CardComponent } from './card/card.component';
import { ChipComponent } from './chip/chip.component';
import { CommunityCardsComponent } from './community-cards/community-cards.component';
import { DealerComponent } from './dealer/dealer.component';
import { GameTableComponent } from './game-table/game-table.component';
import { GameTableService } from './game-table/game-table.service';
import { PlayerPotComponent } from './player-pot/player-pot.component';
import { PlayerPotsComponent } from './player-pots/player-pots.component';
import { PlayerComponent } from './player/player.component';
import { PotComponent } from './pot/pot.component';
import { RakeComponent } from './rake/rake.component';
import { SeatComponent } from './seat/seat.component';
import { TableTextViewComponent } from './table-text-view/table-text-view.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // VanillaCoreModule
    ],
    declarations: [
        GameTableComponent,
        ChipComponent,
        PlayerComponent,
        SeatComponent,
        CardComponent,
        DealerComponent,
        PotComponent,
        CommunityCardsComponent,
        PlayerPotsComponent,
        PlayerPotComponent,
        TableTextViewComponent,
        RakeComponent,
    ],
    exports: [GameTableComponent],
})
export class GameModule {
    public static forRoot(): ModuleWithProviders<GameModule> {
        return {
            ngModule: GameModule,
            providers: [GameTableService],
        };
    }
}
