<div class="contentWrap promoWrap">
    <div class="col">
        <h1 *ngIf="offersData.pokerPromotionsSitecoreInfo.title">{{ offersData.pokerPromotionsSitecoreInfo.title }}</h1>
        <div class="row p-2">
            <ng-container *ngFor="let promo of offersData.relatedPromos; let promoIndex = index">
                <div
                    class="px-md-2 px-sm-1 mb-2 mb-sm-0 medium-homepage-promo col-md-4 col-sm-6"
                    *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisImage"
                    (click)="promoTilesClick($event, promo, promoIndex)">
                    <a [href]="getPromoLink(offersData.pokerPromotionsSitecoreInfo.promoListEx[promoIndex].path)">
                        <div class="card shadow homepage-promo-content">
                            <img [src]="promo.pokerPromotionsSitecoreInfo.synopsisImage.src" alt="Promo Section" class="card-img-top img-fluid" />
                            <div class="card-body pc-txt">
                                <p>
                                    {{ promo.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                </p>
                                <div [innerHtml]="promo.pokerPromotionsSitecoreInfo.synopsisDescription"></div>
                                {{ promo.pokerPromotionsSitecoreInfo.synopsisCTA }}
                            </div>
                            <div class="card-footer" *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisTerms">
                                <small class="text-muted">{{ promo.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                            </div>
                        </div>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
