import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BottomNavService, MenuActionsService, NavigationService, PlainLinkComponent, TrackingService } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { Swiper } from 'swiper';
import { FreeMode, Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { PokerApiService } from './api.service';
import { NavigationClientConfig, PokerHomePageClientConfig } from './client-config.models';
import { NavigationBootstrapService } from './navigation-bootstrap.service';
import { VCClientService } from './vc-client.service';

@Component({
    selector: 'pk-navigation-bar-poker',
    templateUrl: 'navigation-bar-poker.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, DslPipe, PlainLinkComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationBarPokerComponent implements OnInit {
    activeMenuItemUrl: string;
    navItems: any;
    activeLink: any;
    isMobile: boolean;
    isEpcotNavigation: boolean;
    previousUrl: any;
    activeNavigation: boolean = false;
    swiper: Swiper;
    mobileNativeApp: boolean;

    public config: SwiperOptions = {
        modules: [FreeMode, Navigation],
        slidesPerView: 'auto',
        freeMode: {
            enabled: true,
            sticky: true,
        },
        spaceBetween: 10,
        mousewheel: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };
    constructor(
        public api: PokerApiService,
        private navigationConfig: NavigationClientConfig,
        private navigation: NavigationService,
        private bottomNavService: BottomNavService,
        private eRef: ElementRef,
        private menuActionsService: MenuActionsService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private navigationBootstrap: NavigationBootstrapService,
        private tracking: TrackingService,
        private vCClientService: VCClientService,
    ) {
        this.menuActionsService.register('openPokerNavigation', () => {
            document.body.classList.add('showMenu');
            const cls = document.querySelector('.nav-item .close-btn');
            const pkNav = document.querySelector('pk-navigation-bar');
            if (cls != null) {
                pkNav?.appendChild(cls);
            }
        });
    }
    @ViewChild('swiperComponent') componentRef?: ElementRef;

    @HostListener('document:click', ['$event']) clickOut(event: any) {
        if (!this.eRef.nativeElement.contains(event.target) && !event.target.classList.contains('testtoggler')) {
            document.body.classList.remove('showMenu');
        }
    }

    ngOnInit(): void {
        this.isEpcotNavigation = this.pokerHomePageClientConfig.useEpcotNavigation;
        this.navItems = this.navigationConfig.items;
        this.isMobile = false;
        this.previousUrl = this.navigationBootstrap.getPreviousUrl();
        this.mobileNativeApp = this.vCClientService.isMobileApp();
        this.bottomNavService.whenReady.subscribe(() => {
            this.bottomNavService.isEnabled().subscribe((response: boolean) => {
                if (response == true) {
                    this.isMobile = true;
                }
            });
        });

        for (const item of this.navItems) {
            item.active = false;
        }

        this.activeLink = window.location.href;
        this.checkActiveNav();
    }

    onResize() {
        this.adjustNavOnLandscapeMode();
    }

    ngAfterViewInit() {
        this.adjustNavOnLandscapeMode();
        if (this.componentRef && this.componentRef.nativeElement) {
            this.swiper = new Swiper(this.componentRef.nativeElement, this.config);
        }
    }

    private adjustNavOnLandscapeMode() {
        let hdrEle: any;
        let hdrHeight = 0;
        if (document.querySelector('header')) {
            hdrEle = document.querySelector('header');
            hdrHeight = hdrEle.offsetHeight;
        }

        if (document.querySelector('header')) {
            hdrEle = document.querySelector('header');
            hdrHeight = hdrEle.offsetHeight;
            document.querySelector('pk-navigation-bar-poker')?.setAttribute('style', 'top:' + hdrHeight + 'px;');
        }
    }

    removeBodyClass(event: any, url: string, menuIndex: number) {
        if (menuIndex) {
            for (let i = 0; i < this.navItems.length; i++) {
                if (i == menuIndex) {
                    this.navItems[i].active = true;
                } else {
                    this.navItems[i].active = false;
                }
            }
        }
        if (!url || url == 'null' || url == 'undefined') {
            event.preventDefault();
        }
    }

    menuItemClicked(event: any, itemUrl: string) {
        this.activeMenuItemUrl = itemUrl;
        event.preventDefault();
        this.navigation.goTo(itemUrl);
    }

    toggleMenu(activeIndex: any) {
        if (this.isMobile) {
            for (let i = 0; i < this.navItems.length; i++) {
                if (i !== activeIndex) {
                    this.navItems[i].active = false;
                }
            }
            this.navItems[activeIndex].active = !this.navItems[activeIndex].active;
        }
    }

    navigateToBack() {
        this.navigation.goTo(this.previousUrl);
    }

    checkActiveNav() {
        this.navItems.forEach((navItem: any) => {
            if (this.activeLink == navItem.url && navItem.name != 'home') {
                this.activeNavigation = true;
            }
        });
    }

    trackingEvent(event: any, promotion: any, id: any) {
        this.tracking.triggerEvent('Event.NavigationMenus', {
            'event': 'Event.NavigationMenus',
            'eventTimeout': 500,
            'gtm.uniqueEventId': id,
            'page.navigationMenu': promotion.text,
            'page.siteSection': 'Navigation',
        });
    }
}
