export class PlayerStatus {
    public static readonly POST_SMALL_BLIND = 'PostSmallBlind';
    public static readonly POST_BIG_BLIND = 'PostBigBlind';
    public static readonly MISSED_SMALL_BLIND = 'MissedBlind';
    public static readonly ALL_IN_BIG_BLIND = 'AllInBigBlind';
    public static readonly ALL_IN_SMALL_BLIND = 'AllInSmallBlind';
    public static readonly ALL_IN_ANTE = 'All-In';
    public static readonly ALL_IN_BUTTON_ANTE = 'All-In';
    public static readonly POST_ANTE = 'PostAnte';
    public static readonly PLAYERS_ANTE_EVENT = 'PostAnte';
    public static readonly POST_BUTTON_ANTE = 'PostButtonAnte';
    public static readonly FOLD = 'FOLD';
    public static readonly CHECK = 'CHECK';
    public static readonly CALL = 'CALL';
    public static readonly BET = 'BET';
    public static readonly DOUBLE_BET = 'DoubleBet';
    public static readonly RAISE = 'RAISE';
    public static readonly DOUBLE_RAISE = 'DoubleRaise';
    public static readonly ALL_IN_DOUBLE_RAISE = 'AllInDoubleRaise';
    public static readonly OPTION_SET = '';
    public static readonly ALL_IN_DOUBLE_BET = 'AllInDoubleBet';
    public static readonly PLAYER_MUCK_CARDS = 'Muck';
    public static readonly WIN_HIGH = 'Winss';
    public static readonly WIN_LOW = 'Winss';
    public static readonly DEALING_FLOP = 'dealingFlop';
    public static readonly DEALING_TURN = 'dealingTurn';
    public static readonly DEALING_RIVER = 'dealingRiver';
    public static readonly SHOWDOWN = 'showdown';
    public static readonly POST_OTHER_BLIND = 'PostOtherBlind';
    public static readonly ALL_IN_RAISE = 'AllInRaise';
    public static readonly ALL_IN_CALL = 'AllInCall';
    public static readonly ALL_IN_BET = 'AllInBet';
    public static readonly DEALING_THIRD_STREET = 'DealingThirdStreet';
    public static readonly POST_BRING_IN = 'BringIn';
    public static readonly COMPLETE_BRING_IN = 'CompleteBringIn';
    public static readonly ALL_IN_COMPLETE_BRING_IN = 'AllInCompleteBringIn';
    public static readonly ALL_IN_BRING_IN = 'AllInBringIn';
    public static readonly DEALING_FOURTH_STREET = 'DealingFourthStreet';
    public static readonly DEALING_FIFTH_STREET = 'DealingFifthStreet';
    public static readonly DEALING_SIXTH_STREET = 'DealingSixthStreet';
    public static readonly DEALING_SEVENTH_STREET = 'DealingSeventhStreet';
    public static readonly POST_NEW_PLAYER_BLIND = 'MissedBlind';
    public static readonly ALL_IN_OTHER_BLIND = 'PostOtherBlind';
    public static readonly POST_DUMMY_ANTE = 'PostAnte';
    public static readonly POSTING_BLINDS = 'PostAnte';
    public static readonly CASHOUT = 'cashout';
}
