import { Injectable } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { QSGAMETYPES, SERVER_EVENTS } from '../../../constants/app-constants';
import { cashLobbyDataSelect } from '../../../store/lobby.store';
import { allFilters } from '../utils/filterConfig.model';
import * as filterTypeConfingData from '../utils/filterTypesConfig.json';

export interface cashGamesCardsFilter {
    gameTypes: any[];
    limit: any[];
    variant: any[];
    cashGameType: any[];
    stakeRange: any[];
    tableSize: any[];
}
@Injectable({
    providedIn: 'root',
})
export class CgFiltersEventBusService {
    currentFiltersData: cashGamesCardsFilter;
    filtersConfig: allFilters[] = (filterTypeConfingData as any).default.allFilters;
    filteredCgCards: any;
    cardsFilter: any;
    totalcards: number;
    cashCards: any;
    private readonly logger: PokerLogger;
    constructor(
        // private router: Router,
        private store: Store,
        private navigation: NavigationService,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('cgFilterseventbusserviceLoggingComponent');
        if (localStorage.getItem('currentFiltersData') == null) {
            this.currentFiltersData = this.getDefaultFilters();
        } else {
            this.currentFiltersData = JSON.parse(localStorage.getItem('currentFiltersData')!);
        }
        localStorage.setItem('currentFiltersData', JSON.stringify(this.currentFiltersData));
        this.updateCurrentFilters(this.currentFiltersData);
        this.store.select(cashLobbyDataSelect).subscribe((data) => {
            this.cashCards = data;
            this.filterCashGamesCards(this.cashCards, false);
        });
    }

    public currentFilters = new BehaviorSubject<cashGamesCardsFilter>({
        gameTypes: [],
        limit: [],
        variant: [],
        cashGameType: [],
        stakeRange: [],
        tableSize: [],
    });

    public filteredCardsCount = new BehaviorSubject<number>(0);

    get filteredCards() {
        return this.filteredCardsCount.asObservable();
    }

    updateFilteredCardsCount() {
        const count = this.filterCashGamesCards(this.cashCards, false).length;
        this.filteredCardsCount.next(count);
    }

    get getCurrentFilters() {
        return this.currentFilters.asObservable();
    }
    getDefaultFilters() {
        return {
            gameTypes: ['Omaha', 'Hold’em'],
            limit: ['No Limit', 'Pot Limit'],
            variant: ['Hi', 'Hi-Lo'],
            cashGameType: ['Ante', 'Real Name', 'Regular'],
            stakeRange: ['High Roller', 'High', 'Medium', 'Low', 'Micro'],
            tableSize: ['7-8 (Full Ring)', '5-6 (Short Handed)', '2 (Heads Up)'],
        };
    }
    updateCurrentFilters(filters) {
        this.logger.info('updateCurrentFilters' + JSON.stringify(filters));
        this.currentFilters.next(filters);
        this.currentFiltersData = filters;
        this.cardsFilter = filters;
        this.updateFilteredCardsCount();
    }

    applyFilters() {
        this.logger.info('applyFilters');
        localStorage.setItem('currentFiltersData', JSON.stringify(this.currentFilters.value));
        // this.router.navigate(['/play/cash']);
        this.navigation.goTo('/play/cash');
        // this.cashGamesStore.dispatch(updateFilteredCashGamesCards({ filter: this.currentFilters.value }));
        // this.router.navigate(['home/cash-games']);
    }

    resetFilters() {
        this.logger.info('resetFilters');
        this.currentFiltersData = this.getDefaultFilters();
        localStorage.setItem('currentFiltersData', JSON.stringify(this.currentFiltersData));
        this.updateCurrentFilters(this.currentFiltersData);
        // this.cashGamesStore.dispatch(resetCashGamesFilter({}));
    }

    updateFilter(filterType, filterValue, availableFiltersCount) {
        const currentFilterDataCopy = JSON.parse(JSON.stringify(this.currentFiltersData));
        const index = currentFilterDataCopy[filterType].indexOf(filterValue);
        const selectedFiltersCount = currentFilterDataCopy[filterType].length;

        if (availableFiltersCount === selectedFiltersCount) {
            currentFilterDataCopy[filterType] = [filterValue];
        } else {
            if (index > -1) {
                currentFilterDataCopy[filterType].splice(index, 1);
            } else {
                currentFilterDataCopy[filterType].push(filterValue);
            }
        }

        // if(currentFilterDataCopy[filterType].length == 0) {
        //   currentFilterDataCopy[filterType].push(...this.getAllFilterTypes(filterType));
        // }

        this.updateCurrentFilters(currentFilterDataCopy);
    }

    updateFilterWithAllValues(filterType) {
        const currentFilterDataCopy = JSON.parse(JSON.stringify(this.currentFiltersData));
        if (currentFilterDataCopy[filterType].length == this.getAllFilterTypes(filterType).length) currentFilterDataCopy[filterType] = [];
        else {
            currentFilterDataCopy[filterType] = [];
            currentFilterDataCopy[filterType].push(...this.getAllFilterTypes(filterType));
        }
        this.updateCurrentFilters(currentFilterDataCopy);
    }

    getAllFilterTypes(filterType) {
        return (this.filtersConfig.find((filter) => filter.path === filterType) as any).allFilterTypes;
    }

    // }
    filterCashGamesCards(cashGamescards, updateFilterStore) {
        if (updateFilterStore == true) this.updateCurrentFilters(JSON.parse(localStorage.getItem('currentFiltersData')!));
        const sortedFiltered = cashGamescards;
        this.filteredCgCards = _.sortBy(sortedFiltered, ['text2', 'text1']);
        this.totalcards = this.filteredCgCards.length;
        //Keeps track if any filter was selected or not
        let filterAdded = false;

        let filteredCardsData = this.filteredCgCards;

        //Game Type Filter Logic
        // if (this.cardsFilter.gameTypes.length > 0) {
        filterAdded = true;
        filteredCardsData = _.sortBy(
            _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.gameTypes, (b) => f.gameTypeIdString === b).length > 0),
            ['text2', 'text1'],
        );
        // }

        //Game Limit Filter Logic
        if (this.cardsFilter.limit.length > 0) {
            filterAdded = true;
            filteredCardsData = _.sortBy(
                _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.limit, (b) => f.limitType === b).length > 0),
                ['text2', 'text1'],
            );
        }

        //Game Variant Filter logic
        if (this.cardsFilter.gameTypes.includes('Omaha')) {
            const omahaCards: any[] = [];

            filteredCardsData.forEach((card) => {
                if (card.gameTypeIdString == 'Omaha') {
                    omahaCards.push(card);
                }
            });

            filteredCardsData = filteredCardsData.filter((res) => res.gameTypeIdString != 'Omaha');

            if (omahaCards.length > 0) {
                filterAdded = true;
                let hiVariantData: any[] = [];
                let hiLoVariantData: any[] = [];
                const hiVariant = this.cardsFilter.variant.indexOf(SERVER_EVENTS.HI_VARIANT) > -1;
                const hiLoVariant = this.cardsFilter.variant.indexOf(SERVER_EVENTS.HI_LO_VARIANT) > -1;
                if (hiVariant) {
                    const omahahi = _.sortBy(
                        _.filter(
                            omahaCards,
                            (f) => _.filter(this.cardsFilter.variant, () => f.gameTypeId === QSGAMETYPES.GAME_TYPE_OMAHA_HI).length > 0,
                        ),
                        ['text2', 'text1'],
                    );
                    const studHi = _.sortBy(
                        _.filter(
                            omahaCards,
                            (f) => _.filter(this.cardsFilter.variant, () => f.gameTypeId === QSGAMETYPES.GAME_TYPE_SEVEN_STUD_HI).length > 0,
                        ),
                        ['text2', 'text1'],
                    );
                    hiVariantData = _.union(omahahi, studHi);
                }
                if (hiLoVariant) {
                    const omahaHiLo = _.sortBy(
                        _.filter(
                            omahaCards,
                            (f) => _.filter(this.cardsFilter.variant, () => f.gameTypeId === QSGAMETYPES.GAME_TYPE_OMAHA_HI_LO).length > 0,
                        ),
                        ['text2', 'text1'],
                    );
                    const studHiLo = _.sortBy(
                        _.filter(
                            omahaCards,
                            (f) => _.filter(this.cardsFilter.variant, () => f.gameTypeId === QSGAMETYPES.GAME_TYPE_SEVEN_STUD_HI_LO).length > 0,
                        ),
                        ['text2', 'text1'],
                    );
                    hiLoVariantData = _.union(omahaHiLo, studHiLo);
                }
                filteredCardsData.push(..._.union(hiVariantData, hiLoVariantData));
            }
        }

        // Table Size Filter Logic
        if (this.cardsFilter.tableSize.length > 0) {
            filterAdded = true;
            let fullRingTables: any[] = []; // 7-8 Seater
            let shortHandedTables: any[] = []; // 5-6 Seater
            let headsUpTables: any[] = []; // 2 Seater

            //fullRing (7-8 Seater),  shortHanded (5-6 Seater),  headsUp (2 Seater)

            const fullRingSelected = this.cardsFilter.tableSize.indexOf(SERVER_EVENTS.TABLE_SIZE_FULL_RING) > -1;
            const shortHandedSelected = this.cardsFilter.tableSize.indexOf(SERVER_EVENTS.TABLE_SIZE_SHORT_HANDED) > -1;
            const headsUpSelected = this.cardsFilter.tableSize.indexOf(SERVER_EVENTS.TABLE_SIZE_HEADSUP) > -1;

            if (fullRingSelected) {
                fullRingTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.tableSize, () => this.inRange(f.maxNoOfSeats, 7, 8)).length > 0),
                    ['text2', 'text1'],
                );
            }
            if (shortHandedSelected) {
                shortHandedTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.tableSize, () => this.inRange(f.maxNoOfSeats, 5, 6)).length > 0),
                    ['text2', 'text1'],
                );
            }
            if (headsUpSelected) {
                headsUpTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.tableSize, () => f.maxNoOfSeats === 2).length > 0),
                    ['text2', 'text1'],
                );
            }
            filteredCardsData = _.union(fullRingTables, shortHandedTables, headsUpTables);
        }

        // Stake Range Filter Logic
        if (this.cardsFilter.stakeRange.length > 0) {
            filterAdded = true;
            let highRollerTables: any[] = []; // High Roller($1250+)
            let highTables: any[] = []; //  High ($250-$1000)
            let mediumTables: any[] = []; // Medium ($50-$200)
            let lowTables: any[] = []; // Low ($12.50-$50)
            let microTables: any[] = []; // Micro ($10 and Under)

            const highRollerSelected = this.cardsFilter.stakeRange.indexOf(SERVER_EVENTS.HIGH_ROLLER_STAKE_RANGE) > -1;
            const highSelected = this.cardsFilter.stakeRange.indexOf(SERVER_EVENTS.HIGH_STAKE_RANGE) > -1;
            const mediumSelected = this.cardsFilter.stakeRange.indexOf(SERVER_EVENTS.MEDIUM_STAKE_RANGE) > -1;
            const lowSelected = this.cardsFilter.stakeRange.indexOf(SERVER_EVENTS.LOW_STAKE_RANGE) > -1;
            const microSelected = this.cardsFilter.stakeRange.indexOf(SERVER_EVENTS.MICRO_STAKE_RANGE) > -1;

            if (highRollerSelected) {
                highRollerTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.stakeRange, () => f.formattedBuyIn >= 1250).length > 0),
                    ['text2', 'text1'],
                );
            }
            if (highSelected) {
                highTables = _.sortBy(
                    _.filter(
                        filteredCardsData,
                        (f) => _.filter(this.cardsFilter.stakeRange, () => this.inRange(f.formattedBuyIn, 250, 1000)).length > 0,
                    ),
                    ['text2', 'text1'],
                );
            }
            if (mediumSelected) {
                mediumTables = _.sortBy(
                    _.filter(
                        filteredCardsData,
                        (f) => _.filter(this.cardsFilter.stakeRange, () => this.inRange(f.formattedBuyIn, 50, 200)).length > 0,
                    ),
                    ['text2', 'text1'],
                );
            }
            if (lowSelected) {
                lowTables = _.sortBy(
                    _.filter(
                        filteredCardsData,
                        (f) => _.filter(this.cardsFilter.stakeRange, () => this.inRange(f.formattedBuyIn, 12.5, 50)).length > 0,
                    ),
                    ['text2', 'text1'],
                );
            }
            if (microSelected) {
                microTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.stakeRange, () => this.inRange(f.formattedBuyIn, 0, 10)).length > 0),
                    ['text2', 'text1'],
                );
            }
            filteredCardsData = _.union(highRollerTables, highTables, mediumTables, lowTables, microTables);
        }

        //Cash Game type filter logic
        if (this.cardsFilter.cashGameType.length > 0) {
            filterAdded = true;
            let anteTables: any[] = [];
            let realNameTables: any[] = [];
            let featuredTables: any[] = [];
            let regularTables: any[] = [];

            const anteSelected = this.cardsFilter.cashGameType.indexOf(SERVER_EVENTS.CASH_GAME_TYPE_ANTE) > -1;
            const realNameSelected = this.cardsFilter.cashGameType.indexOf(SERVER_EVENTS.CASH_GAME_TYPE_REALNAME) > -1;
            const featuredSelected = this.cardsFilter.cashGameType.indexOf(SERVER_EVENTS.CASH_GAME_TYPE_FEATURED) > -1;
            const regularSelected = this.cardsFilter.cashGameType.indexOf(SERVER_EVENTS.CASH_GAME_TYPE_REGULAR) > -1;

            if (anteSelected) {
                anteTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.cashGameType, () => f.ante > 0).length > 0),
                    ['text2', 'text1'],
                );
            }
            if (realNameSelected) {
                realNameTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.cashGameType, () => f.realNameTable == true).length > 0),
                    ['text2', 'text1'],
                );
            }
            if (featuredSelected) {
                featuredTables = _.sortBy(
                    _.filter(filteredCardsData, (f) => _.filter(this.cardsFilter.cashGameType, () => f.tableProfileType == true).length > 0),
                    ['text2', 'text1'],
                );
            }
            if (regularSelected) {
                regularTables = _.sortBy(
                    _.filter(
                        filteredCardsData,
                        (f) =>
                            _.filter(this.cardsFilter.cashGameType, () => f.ante === 0 && f.realNameTable == false && f.tableProfileType == false)
                                .length > 0,
                    ),
                    ['text2', 'text1'],
                );
            }
            filteredCardsData = _.union(regularTables, anteTables, realNameTables, featuredTables);
        }

        if (filterAdded) {
            this.filteredCgCards = JSON.parse(JSON.stringify(filteredCardsData));
        }
        // this.updateFilteredCardsCount(this.filteredCgCards.length);
        return filteredCardsData;
        // if (!this.ref['destroyed']) {
        //   this.ref.detectChanges();
        // }

        // this.selectedWinUptos = _.sortBy(_.uniq(_.map(this.filteredCgCards, (o) => o.text2)));
        // if (this.filteredCgCards.length > 0) {
        //   this.slideConfig.maxValueText = this.currencyFormatterPipe.transform((_.maxBy(this.filteredCgCards, (v) => v.formatAmount).formatAmount), '', true);
        //   this.slideConfig.minValueText = this.currencyFormatterPipe.transform((_.minBy(this.filteredCgCards, (v) => v.formatAmount).formatAmount), '', true);

        //   this.filteredCgCards = _.sortBy(this.filteredCgCards, ['text2', 'text1']);
        //   setTimeout(() => { this.isMinAndMaxValuesUpdated = false; }, 0);
        //   if (!this.gridView) {
        //     this.carouselInitialized = false;
        //     this.carouselCards = this.filteredCgCards.slice(this.carouselCardsStartIndex, (this.carouselCardsEndIndex + 1));
        //     this.initializeCarousel();
        //   } else {
        //     this.carouselInitialized = false;
        //     let modalStartPositionRef = document.getElementById(this.currentCardSelectedIndex + 'cardId');
        //     if (modalStartPositionRef) {
        //       modalStartPositionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
        //     }
        //   }
        // }
    }
    inRange(num, start, end) {
        return num >= start && num <= end;
    }
}
