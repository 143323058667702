import { Message } from '../../message';
import { WaitlistCriteriaType } from './WaitlistCriteriaType';

export class JoinWaitlistResponse extends Message {
    private MESSAGE_NAME: string = 'JoinWaitlistResponse';
    private status: boolean | null = false;
    private tableId: number = 0;
    private criteria: WaitlistCriteriaType | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, status: boolean | null = false, tableId: number = 0, criteria: WaitlistCriteriaType | null = null) {
        super(reqServerPeerId);
        this.status = status;
        this.tableId = tableId;
        this.criteria = criteria;
    }

    getStatus(): boolean | null {
        return this.status;
    }

    setStatus(status: boolean | null) {
        this.status = status;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getCriteria(): WaitlistCriteriaType | null {
        return this.criteria;
    }

    setCriteria(criteria: WaitlistCriteriaType | null) {
        this.criteria = criteria;
    }
}
