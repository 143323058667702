import { Message } from '../../message';

export class JackpotAmount extends Message {
    private MESSAGE_NAME: string = 'JackpotAmount';
    private jackpotId: number = 0;
    private jackpotAmount: number = 0;
    private familyId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, jackpotId: number = 0, jackpotAmount: number = 0, familyId: number = 0) {
        super(reqServerPeerId);
        this.jackpotId = jackpotId;
        this.jackpotAmount = jackpotAmount;
        this.familyId = familyId;
    }

    getJackpotId(): number {
        return this.jackpotId;
    }

    setJackpotId(jackpotId: number) {
        this.jackpotId = jackpotId;
    }
    getJackpotAmount(): number {
        return this.jackpotAmount;
    }

    setJackpotAmount(jackpotAmount: number) {
        this.jackpotAmount = jackpotAmount;
    }
    getFamilyId(): number {
        return this.familyId;
    }

    setFamilyId(familyId: number) {
        this.familyId = familyId;
    }
}
