import { Message } from '../../message';

export class DownloadAndExecute extends Message {
    private MESSAGE_NAME: string = 'DownloadAndExecute';
    private silent: boolean | null = false;
    private url: string | null = null;
    private context: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, silent: boolean | null = false, url: string | null = null, context: any | null = null) {
        super(reqServerPeerId);
        this.silent = silent;
        this.url = url;
        this.context = context;
    }

    getSilent(): boolean | null {
        return this.silent;
    }

    setSilent(silent: boolean | null) {
        this.silent = silent;
    }
    getUrl(): string | null {
        return this.url;
    }

    setUrl(url: string | null) {
        this.url = url;
    }
    getContext(): any | null {
        return this.context;
    }

    setContext(context: any | null) {
        this.context = context;
    }
}
