import { Message } from '../../message';

export class LSTableDetails extends Message {
    private MESSAGE_NAME: string = 'LSTableDetails';
    private avgFlopSeen: number = 0;
    private avgPot: number = 0;
    private handsPerHour: number = 0;
    private playerCount: number = 0;
    private reservedSeatsCount: number = 0;
    private serverPeerId: number = 0;
    private tableId: number = 0;
    private tableInfoId: number = 0;
    private tableName: any | null = null;
    private tourneyGameStatus: number = 0;
    private waitingCount: number = 0;
    private challengeType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        avgFlopSeen: number = 0,
        avgPot: number = 0,
        handsPerHour: number = 0,
        playerCount: number = 0,
        reservedSeatsCount: number = 0,
        serverPeerId: number = 0,
        tableId: number = 0,
        tableInfoId: number = 0,
        tableName: any | null = null,
        tourneyGameStatus: number = 0,
        waitingCount: number = 0,
        challengeType: number = 0,
    ) {
        super(reqServerPeerId);
        this.avgFlopSeen = avgFlopSeen;
        this.avgPot = avgPot;
        this.handsPerHour = handsPerHour;
        this.playerCount = playerCount;
        this.reservedSeatsCount = reservedSeatsCount;
        this.serverPeerId = serverPeerId;
        this.tableId = tableId;
        this.tableInfoId = tableInfoId;
        this.tableName = tableName;
        this.tourneyGameStatus = tourneyGameStatus;
        this.waitingCount = waitingCount;
        this.challengeType = challengeType;
    }

    getAvgFlopSeen(): number {
        return this.avgFlopSeen;
    }

    setAvgFlopSeen(avgFlopSeen: number) {
        this.avgFlopSeen = avgFlopSeen;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
    getHandsPerHour(): number {
        return this.handsPerHour;
    }

    setHandsPerHour(handsPerHour: number) {
        this.handsPerHour = handsPerHour;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getReservedSeatsCount(): number {
        return this.reservedSeatsCount;
    }

    setReservedSeatsCount(reservedSeatsCount: number) {
        this.reservedSeatsCount = reservedSeatsCount;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getTableName(): any | null {
        return this.tableName;
    }

    setTableName(tableName: any | null) {
        this.tableName = tableName;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
    getWaitingCount(): number {
        return this.waitingCount;
    }

    setWaitingCount(waitingCount: number) {
        this.waitingCount = waitingCount;
    }
    getChallengeType(): number {
        return this.challengeType;
    }

    setChallengeType(challengeType: number) {
        this.challengeType = challengeType;
    }
}
