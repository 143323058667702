import { Message } from '../../message';

export class GameTableStatus extends Message {
    private MESSAGE_NAME: string = 'GameTableStatus';
    private tableId: number = 0;
    private tableTypeId: number = 0;
    private tableCategory: number = 0;
    private tableName: any | null = null;
    private serverPeerId: number = 0;
    private maxNoOfSeats: number = 0;
    private gameId: number = 0;
    private gameTypeId: number = 0;
    private stakesLowerLimit: number = 0;
    private stakesUpperLimit: number = 0;
    private playerCount: number = 0;
    private waitListCount: number = 0;
    private watchersCount: number = 0;
    private avgPot: number = 0;
    private playersPerFlop: number = 0;
    private handsPerHour: number = 0;
    private activeFlag: boolean | null = false;
    private tourneyBuyIn: number = 0;
    private tourneyEntryFee: number = 0;
    private tourneyGameStatus: number = 0;
    private groupId: number = 0;
    private dbTableInfoId: number = 0;
    private tabIds: any[] | null = null;
    private tableLimitType: number = 0;
    private minBuyInAmount: number = 0;
    private maxBuyInAmount: number = 0;
    private autoLoad: boolean | null = false;
    private pvtTableType: number = 0;
    private allowPlayerToSit: boolean | null = false;
    private smallBlind: number = 0;
    private bigBlind: number = 0;
    private ante: number = 0;
    private bringIn: number = 0;
    private jackpotId: number = 0;
    private disconProtectType: number = 0;
    private countDownTime: number = 0;
    private tableColorCode: number = 0;
    private gamePlayType: number = 0;
    private familyId: number = 0;
    private mtctId: number = 0;
    private fppBuyIn: number = 0;
    private tbtMaxTime: number = 0;
    private trnyChips: number = 0;
    private casinoTrnyLevelInfo: any[] | null = null;
    private isTBTRandomized: boolean | null = false;
    private reservedSeatsCount: number = 0;
    private mtctBuyIn: number = 0;
    private avgFlopSeen: number = 0;
    private protectionLevel: number = 0;
    private gameCurrency: string | null = null;
    private isChampionshipTable: boolean | null = false;
    private challengeType: number = 0;
    private tournamentCategory: number = 0;
    private huTableType: number = 0;
    private bountyFee: number = 0;
    private tableProfileType: number = 0;
    private trnyMixMaxType: number = 0;
    private balanceThreshold: number = 0;
    private rebuyAddonTrnyTable: boolean | null = false;
    private realNameTable: boolean | null = false;
    private anteCashAmount: number = 0;
    private restrictedRoomId: number = 0;
    private clubId: number = 0;
    private isFFTable: boolean | null = false;
    private sngPayOutType: number = 0;
    private athApplicable: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        tableTypeId: number = 0,
        tableCategory: number = 0,
        tableName: any | null = null,
        serverPeerId: number = 0,
        maxNoOfSeats: number = 0,
        gameId: number = 0,
        gameTypeId: number = 0,
        stakesLowerLimit: number = 0,
        stakesUpperLimit: number = 0,
        playerCount: number = 0,
        waitListCount: number = 0,
        watchersCount: number = 0,
        avgPot: number = 0,
        playersPerFlop: number = 0,
        handsPerHour: number = 0,
        activeFlag: boolean | null = false,
        tourneyBuyIn: number = 0,
        tourneyEntryFee: number = 0,
        tourneyGameStatus: number = 0,
        groupId: number = 0,
        dbTableInfoId: number = 0,
        tabIds: any[] | null = null,
        tableLimitType: number = 0,
        minBuyInAmount: number = 0,
        maxBuyInAmount: number = 0,
        autoLoad: boolean | null = false,
        pvtTableType: number = 0,
        allowPlayerToSit: boolean | null = false,
        smallBlind: number = 0,
        bigBlind: number = 0,
        ante: number = 0,
        bringIn: number = 0,
        jackpotId: number = 0,
        disconProtectType: number = 0,
        countDownTime: number = 0,
        tableColorCode: number = 0,
        gamePlayType: number = 0,
        familyId: number = 0,
        mtctId: number = 0,
        fppBuyIn: number = 0,
        tbtMaxTime: number = 0,
        trnyChips: number = 0,
        casinoTrnyLevelInfo: any[] | null = null,
        isTBTRandomized: boolean | null = false,
        reservedSeatsCount: number = 0,
        mtctBuyIn: number = 0,
        avgFlopSeen: number = 0,
        protectionLevel: number = 0,
        gameCurrency: string | null = null,
        isChampionshipTable: boolean | null = false,
        challengeType: number = 0,
        tournamentCategory: number = 0,
        huTableType: number = 0,
        bountyFee: number = 0,
        tableProfileType: number = 0,
        trnyMixMaxType: number = 0,
        balanceThreshold: number = 0,
        rebuyAddonTrnyTable: boolean | null = false,
        realNameTable: boolean | null = false,
        anteCashAmount: number = 0,
        restrictedRoomId: number = 0,
        clubId: number = 0,
        isFFTable: boolean | null = false,
        sngPayOutType: number = 0,
        athApplicable: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.tableTypeId = tableTypeId;
        this.tableCategory = tableCategory;
        this.tableName = tableName;
        this.serverPeerId = serverPeerId;
        this.maxNoOfSeats = maxNoOfSeats;
        this.gameId = gameId;
        this.gameTypeId = gameTypeId;
        this.stakesLowerLimit = stakesLowerLimit;
        this.stakesUpperLimit = stakesUpperLimit;
        this.playerCount = playerCount;
        this.waitListCount = waitListCount;
        this.watchersCount = watchersCount;
        this.avgPot = avgPot;
        this.playersPerFlop = playersPerFlop;
        this.handsPerHour = handsPerHour;
        this.activeFlag = activeFlag;
        this.tourneyBuyIn = tourneyBuyIn;
        this.tourneyEntryFee = tourneyEntryFee;
        this.tourneyGameStatus = tourneyGameStatus;
        this.groupId = groupId;
        this.dbTableInfoId = dbTableInfoId;
        this.tabIds = tabIds;
        this.tableLimitType = tableLimitType;
        this.minBuyInAmount = minBuyInAmount;
        this.maxBuyInAmount = maxBuyInAmount;
        this.autoLoad = autoLoad;
        this.pvtTableType = pvtTableType;
        this.allowPlayerToSit = allowPlayerToSit;
        this.smallBlind = smallBlind;
        this.bigBlind = bigBlind;
        this.ante = ante;
        this.bringIn = bringIn;
        this.jackpotId = jackpotId;
        this.disconProtectType = disconProtectType;
        this.countDownTime = countDownTime;
        this.tableColorCode = tableColorCode;
        this.gamePlayType = gamePlayType;
        this.familyId = familyId;
        this.mtctId = mtctId;
        this.fppBuyIn = fppBuyIn;
        this.tbtMaxTime = tbtMaxTime;
        this.trnyChips = trnyChips;
        this.casinoTrnyLevelInfo = casinoTrnyLevelInfo;
        this.isTBTRandomized = isTBTRandomized;
        this.reservedSeatsCount = reservedSeatsCount;
        this.mtctBuyIn = mtctBuyIn;
        this.avgFlopSeen = avgFlopSeen;
        this.protectionLevel = protectionLevel;
        this.gameCurrency = gameCurrency;
        this.isChampionshipTable = isChampionshipTable;
        this.challengeType = challengeType;
        this.tournamentCategory = tournamentCategory;
        this.huTableType = huTableType;
        this.bountyFee = bountyFee;
        this.tableProfileType = tableProfileType;
        this.trnyMixMaxType = trnyMixMaxType;
        this.balanceThreshold = balanceThreshold;
        this.rebuyAddonTrnyTable = rebuyAddonTrnyTable;
        this.realNameTable = realNameTable;
        this.anteCashAmount = anteCashAmount;
        this.restrictedRoomId = restrictedRoomId;
        this.clubId = clubId;
        this.isFFTable = isFFTable;
        this.sngPayOutType = sngPayOutType;
        this.athApplicable = athApplicable;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTableTypeId(): number {
        return this.tableTypeId;
    }

    setTableTypeId(tableTypeId: number) {
        this.tableTypeId = tableTypeId;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getTableName(): any | null {
        return this.tableName;
    }

    setTableName(tableName: any | null) {
        this.tableName = tableName;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
    getMaxNoOfSeats(): number {
        return this.maxNoOfSeats;
    }

    setMaxNoOfSeats(maxNoOfSeats: number) {
        this.maxNoOfSeats = maxNoOfSeats;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getStakesLowerLimit(): number {
        return this.stakesLowerLimit;
    }

    setStakesLowerLimit(stakesLowerLimit: number) {
        this.stakesLowerLimit = stakesLowerLimit;
    }
    getStakesUpperLimit(): number {
        return this.stakesUpperLimit;
    }

    setStakesUpperLimit(stakesUpperLimit: number) {
        this.stakesUpperLimit = stakesUpperLimit;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getWaitListCount(): number {
        return this.waitListCount;
    }

    setWaitListCount(waitListCount: number) {
        this.waitListCount = waitListCount;
    }
    getWatchersCount(): number {
        return this.watchersCount;
    }

    setWatchersCount(watchersCount: number) {
        this.watchersCount = watchersCount;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
    getPlayersPerFlop(): number {
        return this.playersPerFlop;
    }

    setPlayersPerFlop(playersPerFlop: number) {
        this.playersPerFlop = playersPerFlop;
    }
    getHandsPerHour(): number {
        return this.handsPerHour;
    }

    setHandsPerHour(handsPerHour: number) {
        this.handsPerHour = handsPerHour;
    }
    getActiveFlag(): boolean | null {
        return this.activeFlag;
    }

    setActiveFlag(activeFlag: boolean | null) {
        this.activeFlag = activeFlag;
    }
    getTourneyBuyIn(): number {
        return this.tourneyBuyIn;
    }

    setTourneyBuyIn(tourneyBuyIn: number) {
        this.tourneyBuyIn = tourneyBuyIn;
    }
    getTourneyEntryFee(): number {
        return this.tourneyEntryFee;
    }

    setTourneyEntryFee(tourneyEntryFee: number) {
        this.tourneyEntryFee = tourneyEntryFee;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
    getGroupId(): number {
        return this.groupId;
    }

    setGroupId(groupId: number) {
        this.groupId = groupId;
    }
    getDbTableInfoId(): number {
        return this.dbTableInfoId;
    }

    setDbTableInfoId(dbTableInfoId: number) {
        this.dbTableInfoId = dbTableInfoId;
    }
    getTabIds(): any[] | null {
        return this.tabIds;
    }

    setTabIds(tabIds: any[] | null) {
        this.tabIds = tabIds;
    }
    getTableLimitType(): number {
        return this.tableLimitType;
    }

    setTableLimitType(tableLimitType: number) {
        this.tableLimitType = tableLimitType;
    }
    getMinBuyInAmount(): number {
        return this.minBuyInAmount;
    }

    setMinBuyInAmount(minBuyInAmount: number) {
        this.minBuyInAmount = minBuyInAmount;
    }
    getMaxBuyInAmount(): number {
        return this.maxBuyInAmount;
    }

    setMaxBuyInAmount(maxBuyInAmount: number) {
        this.maxBuyInAmount = maxBuyInAmount;
    }
    getAutoLoad(): boolean | null {
        return this.autoLoad;
    }

    setAutoLoad(autoLoad: boolean | null) {
        this.autoLoad = autoLoad;
    }
    getPvtTableType(): number {
        return this.pvtTableType;
    }

    setPvtTableType(pvtTableType: number) {
        this.pvtTableType = pvtTableType;
    }
    getAllowPlayerToSit(): boolean | null {
        return this.allowPlayerToSit;
    }

    setAllowPlayerToSit(allowPlayerToSit: boolean | null) {
        this.allowPlayerToSit = allowPlayerToSit;
    }
    getSmallBlind(): number {
        return this.smallBlind;
    }

    setSmallBlind(smallBlind: number) {
        this.smallBlind = smallBlind;
    }
    getBigBlind(): number {
        return this.bigBlind;
    }

    setBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
    }
    getAnte(): number {
        return this.ante;
    }

    setAnte(ante: number) {
        this.ante = ante;
    }
    getBringIn(): number {
        return this.bringIn;
    }

    setBringIn(bringIn: number) {
        this.bringIn = bringIn;
    }
    getJackpotId(): number {
        return this.jackpotId;
    }

    setJackpotId(jackpotId: number) {
        this.jackpotId = jackpotId;
    }
    getDisconProtectType(): number {
        return this.disconProtectType;
    }

    setDisconProtectType(disconProtectType: number) {
        this.disconProtectType = disconProtectType;
    }
    getCountDownTime(): number {
        return this.countDownTime;
    }

    setCountDownTime(countDownTime: number) {
        this.countDownTime = countDownTime;
    }
    getTableColorCode(): number {
        return this.tableColorCode;
    }

    setTableColorCode(tableColorCode: number) {
        this.tableColorCode = tableColorCode;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getFamilyId(): number {
        return this.familyId;
    }

    setFamilyId(familyId: number) {
        this.familyId = familyId;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getFppBuyIn(): number {
        return this.fppBuyIn;
    }

    setFppBuyIn(fppBuyIn: number) {
        this.fppBuyIn = fppBuyIn;
    }
    getTbtMaxTime(): number {
        return this.tbtMaxTime;
    }

    setTbtMaxTime(tbtMaxTime: number) {
        this.tbtMaxTime = tbtMaxTime;
    }
    getTrnyChips(): number {
        return this.trnyChips;
    }

    setTrnyChips(trnyChips: number) {
        this.trnyChips = trnyChips;
    }
    getCasinoTrnyLevelInfo(): any[] | null {
        return this.casinoTrnyLevelInfo;
    }

    setCasinoTrnyLevelInfo(casinoTrnyLevelInfo: any[] | null) {
        this.casinoTrnyLevelInfo = casinoTrnyLevelInfo;
    }
    getIsTBTRandomized(): boolean | null {
        return this.isTBTRandomized;
    }

    setIsTBTRandomized(isTBTRandomized: boolean | null) {
        this.isTBTRandomized = isTBTRandomized;
    }
    getReservedSeatsCount(): number {
        return this.reservedSeatsCount;
    }

    setReservedSeatsCount(reservedSeatsCount: number) {
        this.reservedSeatsCount = reservedSeatsCount;
    }
    getMtctBuyIn(): number {
        return this.mtctBuyIn;
    }

    setMtctBuyIn(mtctBuyIn: number) {
        this.mtctBuyIn = mtctBuyIn;
    }
    getAvgFlopSeen(): number {
        return this.avgFlopSeen;
    }

    setAvgFlopSeen(avgFlopSeen: number) {
        this.avgFlopSeen = avgFlopSeen;
    }
    getProtectionLevel(): number {
        return this.protectionLevel;
    }

    setProtectionLevel(protectionLevel: number) {
        this.protectionLevel = protectionLevel;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getIsChampionshipTable(): boolean | null {
        return this.isChampionshipTable;
    }

    setIsChampionshipTable(isChampionshipTable: boolean | null) {
        this.isChampionshipTable = isChampionshipTable;
    }
    getChallengeType(): number {
        return this.challengeType;
    }

    setChallengeType(challengeType: number) {
        this.challengeType = challengeType;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
    getHuTableType(): number {
        return this.huTableType;
    }

    setHuTableType(huTableType: number) {
        this.huTableType = huTableType;
    }
    getBountyFee(): number {
        return this.bountyFee;
    }

    setBountyFee(bountyFee: number) {
        this.bountyFee = bountyFee;
    }
    getTableProfileType(): number {
        return this.tableProfileType;
    }

    setTableProfileType(tableProfileType: number) {
        this.tableProfileType = tableProfileType;
    }
    getTrnyMixMaxType(): number {
        return this.trnyMixMaxType;
    }

    setTrnyMixMaxType(trnyMixMaxType: number) {
        this.trnyMixMaxType = trnyMixMaxType;
    }
    getBalanceThreshold(): number {
        return this.balanceThreshold;
    }

    setBalanceThreshold(balanceThreshold: number) {
        this.balanceThreshold = balanceThreshold;
    }
    getRebuyAddonTrnyTable(): boolean | null {
        return this.rebuyAddonTrnyTable;
    }

    setRebuyAddonTrnyTable(rebuyAddonTrnyTable: boolean | null) {
        this.rebuyAddonTrnyTable = rebuyAddonTrnyTable;
    }
    getRealNameTable(): boolean | null {
        return this.realNameTable;
    }

    setRealNameTable(realNameTable: boolean | null) {
        this.realNameTable = realNameTable;
    }
    getAnteCashAmount(): number {
        return this.anteCashAmount;
    }

    setAnteCashAmount(anteCashAmount: number) {
        this.anteCashAmount = anteCashAmount;
    }
    getRestrictedRoomId(): number {
        return this.restrictedRoomId;
    }

    setRestrictedRoomId(restrictedRoomId: number) {
        this.restrictedRoomId = restrictedRoomId;
    }
    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
    getIsFFTable(): boolean | null {
        return this.isFFTable;
    }

    setIsFFTable(isFFTable: boolean | null) {
        this.isFFTable = isFFTable;
    }
    getSngPayOutType(): number {
        return this.sngPayOutType;
    }

    setSngPayOutType(sngPayOutType: number) {
        this.sngPayOutType = sngPayOutType;
    }
    getAthApplicable(): boolean | null {
        return this.athApplicable;
    }

    setAthApplicable(athApplicable: boolean | null) {
        this.athApplicable = athApplicable;
    }
}
