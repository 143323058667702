import { ServerTime } from '../server/common/connectorMessageFactory/messages/ServerTime';
import { TimeSyncRequest } from '../server/common/connectorMessageFactory/messages/TimeSyncRequest';
import { CSD } from './CSD';
import { ConnectionManager } from './connectionManager';

export class TimeSyncManager {
    private manager: ConnectionManager;
    private realServerClockDiff: number = 0;
    private realEstDiff: number = 0;
    private playServerClockDiff: number = 0;
    private playEstDiff: number = 0;

    constructor(manager: ConnectionManager) {
        this.manager = manager;
    }

    sendTimeSyncRequest(domainId) {
        const timeSyncRequest = new TimeSyncRequest();
        timeSyncRequest.setRequestSentTickCount(Math.round(new Date().getTime() / 1000));
        this.manager.sendMessageOnDomain(timeSyncRequest, domainId);
    }

    handleServerTime(msg: ServerTime, domainId) {
        const serverTime = msg;
        const utcCurrentTime = Math.round(new Date().getTime() / 1000);

        //Calculate round trip time for the message
        const roundTripTime = utcCurrentTime - serverTime.getClientTickCount();

        //Calculate server clock difference and est difference. Ideally server clock should be equal to EST time.
        const serverClockDiff = serverTime.getServerClock() - roundTripTime / 2 - utcCurrentTime;
        const estDiff = serverTime.getTimeEST() - roundTripTime / 2 - utcCurrentTime;

        if (domainId == CSD.REAL) {
            this.realServerClockDiff = serverClockDiff;
            this.realEstDiff = estDiff;
        } else {
            this.playServerClockDiff = serverClockDiff;
            this.playEstDiff = estDiff;
        }
        //Round trip time greater than 3 seconds indicates inconsistency in the client/server clocks.
        if (roundTripTime > 3) {
            this.sendTimeSyncRequest(domainId);
        }
    }

    // Method to get EST time diff for peer.
    getESTDiffForPeer(peerId) {
        const domainId = this.manager.strategy.getPeersDomainId(peerId);
        return domainId === CSD.REAL ? this.realEstDiff : this.playEstDiff;
    }

    getServerClockForPeer(peerId: number) {
        const domainId = this.manager.strategy.getPeersDomainId(peerId);
        const utcServerTime = new Date().getTime() / 1000;
        const serverClockDiff = domainId === CSD.REAL ? this.realServerClockDiff : this.playServerClockDiff;
        return utcServerTime + serverClockDiff;
    }
}
