import { Message } from '../../message';

export class ScratchCardTicker extends Message {
    private MESSAGE_NAME: string = 'ScratchCardTicker';
    private tickerData: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tickerData: number = 0) {
        super(reqServerPeerId);
        this.tickerData = tickerData;
    }

    getTickerData(): number {
        return this.tickerData;
    }

    setTickerData(tickerData: number) {
        this.tickerData = tickerData;
    }
}
