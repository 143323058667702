import { Message } from '../../message';

export class DealMakingSelectedPayout extends Message {
    private MESSAGE_NAME: string = 'DealMakingSelectedPayout';
    private payouts: any[] | null = null;
    private payoutType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, payouts: any[] | null = null, payoutType: number = 0) {
        super(reqServerPeerId);
        this.payouts = payouts;
        this.payoutType = payoutType;
    }

    getPayouts(): any[] | null {
        return this.payouts;
    }

    setPayouts(payouts: any[] | null) {
        this.payouts = payouts;
    }
    getPayoutType(): number {
        return this.payoutType;
    }

    setPayoutType(payoutType: number) {
        this.payoutType = payoutType;
    }
}
