import { Properties } from '../../utils/properties';
import { GameTable } from '../game-table';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class CommunityCard extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.COMMUNITY_CARD;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    next(gameTable: GameTable): void {
        this.card.show = true;
        this.card.cardAnimation = true;
        this.card.cardHide = false;
        const cardPos = Properties.propFile['COMMONSECTION']['COMMUNITYHAND_POS'].split('#');
        this.card.position = new Point(cardPos[0], cardPos[1]);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prev(gameTable: GameTable): void {
        this.card.cardHide = true;
        this.card.show = false;
        this.card.cardAnimation = false;
        this.card.cardState = '';
        // let cardPos = gameTable.dealer.position;
        // this.card.position = new Point((cardPos).x, (cardPos).y);
    }
}
