import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';

export class PlayerViewModel {
    gameService: any;
    constructor(gs) {
        this.gameService = gs;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        //this.createPlayerInstances();
        this.EventDispatcher = EventDispatcher;
        /*this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_DEAL_CARDS, this.handleDealCards.bind(this));*/
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_PLAYER_STATUS, this.handlePlayerStatus.bind(this));
        const playerData = this.gameService.getPlayerStatus();
        if (playerData.length > 0) {
            this.handlePlayerStatus();
        }
        this.allInForSelf = false;
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_FULL_GAME_CLOSE, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_ADD_PLAYER, this.updateAddPlayerData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION, this.updateOthPlayerSelectedOpt.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_POT_WINNERS, this.showPotWinners.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_BET_TIMER, this.showBetTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_BET_OPTIONS, this.showBetTimerForSelf.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.BET_OPTION_SELECTED, this.removeBetTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_DISCONNECTION_STATE, this.clearBetTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_END_THE_ROUND, this.removeBetTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_NEW_GAME, this.handleNewGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_PLAYER_BALANCES, this.updatePlayerBalances.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.REMOVE_PLAYER, this.removePlayer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_ANTE_DATA, this.handleAnteData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_BLIND_DATA, this.handleBlindData.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.FORCE_REFRESH_TABLE, this.forceRefreshTable.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_FULL_GAME_CLOSE, this.clearBetTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_CHANGE_PLAYERSTATE, this.handlechangePlayerState.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_OTHER_PLAYER_RABIT, this.showOtherPlayerRabit.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.UPDATE_DEALER_ICON_POS, this.showUpdatedDealerPos.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_PLAYER_CLICK, this.disablePlayerClick.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_PLAYER_CLICK, this.enablePlayerClick.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_WINCHIPS_ANIMATION_COMPLETED, this.showYouWinAnimation.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.TIME_BANK_NOTIFICATION, this.enableTimeBankNotifyAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_TIMER_FOR_ALL_IN, this.enableTimerForAllIn.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_PLAYER_ACTION, this.hidePlayerAction.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_POPUP_ON_WIDGET, this.showPopUpOnWidget.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_POPUP_ON_WIDGET, this.hidePopUpOnWidget.bind(this));

        this.EventDispatcher.addEventListener(SlotCoreGameEvent.CASHOUT_TIMER, this.cashOutTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SELECTED_CASHOUT_OPTION, this.removeCashOutTimer.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_CASHOUT_BUTTONS, this.disableAllinAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_ANIMATIONS_FOR_CASHOUT, this.disableAllinAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_LOCK_ICON_ANIM, this.showLockIconAnim.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_PLAYER_BALANCE, this.hidePlayerBalance.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_PLAYER_BALANCE, this.showPlayerBalance.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.DISABLE_SHOW_PLAYERPROFILE, this.disableShowPlayerProfile.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_SHOW_PLAYERPROFILE, this.enableShowPlayerProfile.bind(this));
    };
    hidePlayerBalance = function () {
        this.view.hidePlayerBalance();
    };
    showPlayerBalance = function () {
        this.view.showPlayerBalance();
    };
    disableAllinAnim = function () {
        this.view.disableAllinAnim();
    };
    showPopUpOnWidget = function (widPopUpContainer) {
        this.view.showPopUpOnWidget(widPopUpContainer);
    };
    hidePopUpOnWidget = function (widPopUpContainer) {
        this.view.hidePopUpOnWidget(widPopUpContainer);
    };
    hidePlayerAction = function (seatNo) {
        this.view.hidePlayerAction(seatNo);
    };
    enableTimerForAllIn = function () {
        this.allInForSelf = true;
    };
    enableTimeBankNotifyAnim = function () {
        const obj = this.gameService.getCurrentTimerStatus();
        if (!obj) {
            return;
        }
        const seatNo = this.gameService.getClientSideSeatNo(obj.seatNo);
        const timeBankAvailableTime = this.gameService.getTimeBankBalance();
        const optionSetData = this.gameService.getOptionSetData();
        if (!optionSetData || !optionSetData.timeBankAutoEnable) return;
        if (timeBankAvailableTime == 0 || (optionSetData && optionSetData.timeBankAvailableTime == 0)) {
            return;
        }
        this.view.enableTimeBankNotifyAnim(timeBankAvailableTime, seatNo);
    };
    showYouWinAnimation = function (data) {
        const res = data.detail;
        let totalWinAmount = this.gameService.getPlayerWinAmount(this.gameService.getServerSideSeatPos(res.seatNo));
        if (!totalWinAmount && res.amount) totalWinAmount = res.amount;
        this.view.callYouWinAni(res.seatNo, res.amount, totalWinAmount, res.isUnCalledBet);
    };

    showOtherPlayerRabit = function (seatNo) {
        this.view.showOtherPlayerRabit(seatNo);
    };
    showLockIconAnim = function (data) {
        const playerSeat = this.gameService.getClientSideSeatNo(data.detail.seatNo);
        this.view.showLockIconAnim(playerSeat);
    };

    forceRefreshTable = function () {
        this.handleNewGame();
        this.updatePlayerBalances();
    };
    clearBetTimer = function () {
        this.view.clearBetTimer();
    };
    updateOthPlayerSelectedOpt = function () {
        this.updatePlayerBalances();
        const selectedOptionData = this.gameService.getOtherPlayerSelectedOption();
        this.view.updateOthPlayerSelectedOpt(selectedOptionData);
    };
    handlePlayerStatus = function () {
        const playerStatusData = this.gameService.getPlayerStatus();
        //let playerStatusData = {"communityHand":[],"currentStake":0,"dealer":-1,"gameId":0,"jackpotRake":0,"players":[{"avatarImage":"40","balance":20000,"bet":0,"betVector":[],"brandId":"BWINCOM","cards":[],"chatIndicator":false,"city":"City1","country":"Canada","imageURL":null,"isCustomAvatar":false,"isSeatedPlayer":true,"playerId":"qa4pro1","pointCardStatus":0,"seatingPosition":0,"sex":"M","state":2,"avatarImageName":null,"avatarImagePath":null,"forceSitOutReasonId":0,"PlayerAccountId":0,"playerFullName":null,"playerRealName":null},{"avatarImage":"39","balance":20000,"bet":0,"betVector":[],"brandId":"BWINCOM","cards":[],"chatIndicator":false,"city":"City3","country":"Canada","imageURL":null,"isCustomAvatar":false,"isSeatedPlayer":true,"playerId":"qa4pro3","pointCardStatus":0,"seatingPosition":1,"sex":"M","state":2,"avatarImageName":null,"avatarImagePath":null,"forceSitOutReasonId":0,"PlayerAccountId":0,"playerFullName":null,"playerRealName":null},{"avatarImage":"20","balance":20000,"bet":0,"betVector":[],"brandId":"BWINCOM","cards":[],"chatIndicator":false,"city":"City1","country":"Canada","imageURL":null,"isCustomAvatar":false,"isSeatedPlayer":true,"playerId":"wild11","pointCardStatus":0,"seatingPosition":2,"sex":"M","state":2,"avatarImageName":null,"avatarImagePath":null,"forceSitOutReasonId":0,"PlayerAccountId":0,"playerFullName":null,"playerRealName":null}],"pots":[],"rabbitCommunityCards":[],"rake":0,"reservedSeats":[],"roundCount":-1,"seatNo":-1,"tourneyGameStatus":125,"trnyLevelInfo":null,"trnyStartedAt":0,"waitingNo":0,"waitingStatus":1,"waitingUsersCount":0,"msgNumber":0,"peerId":5125884,"className":"GameStatus"};
        this.view.handlePlayerStatus(playerStatusData);
    };
    resetGame = function () {
        this.view.resetGame();
        this.removeBetTimer();
    };
    updateAddPlayerData = function () {
        this.view.updateAddPlayerData(this.gameService.getAddPlayerData());
    };
    showPotWinners = function () {
        const potWinnersData = this.gameService.getPotWinnersData();
        this.view.showPotWinners(potWinnersData.winners);
        this.removeBetTimer();
    };
    showBetTimer = function () {
        const timeOutData = this.gameService.getCurrentTimerStatus();
        //get this from optionSetMessage
        if (this.gameService.getClientSideSeatNo(timeOutData.seatNo) == uiConfig.selfUserClientPosInd && timeOutData.timeOutType == 0) {
            return;
        }
        this.view.showBetTimer(timeOutData, this.allInForSelf);
    };
    cashOutTimer = function (timeOutPeriod) {
        this.view.playersArray[uiConfig.selfUserClientPosInd].showBetTimer(timeOutPeriod.detail, this.allInForSelf);
        this.view.playersArray[uiConfig.selfUserClientPosInd].enableTimer();
        this.view.setCashoutEnabled();
    };
    removeCashOutTimer = function () {
        this.view.playersArray[uiConfig.selfUserClientPosInd].removeBetTimer();
        this.view.setCashoutDisabled();
    };

    showBetTimerForSelf = function () {
        // console.log("showBetTimerForSelf second");
        const optionSetData = this.gameService.getOptionSetData();
        //  console.log(optionSetData);
        // console.log( optionSetData.generatedAt + optionSetData.betTimeOutPeriod);
        const timeOutData = {
            period: optionSetData.generatedAt + optionSetData.betTimeOutPeriod,
            seatNo: this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd),
            timeOutType: 0,
            className: 'TimeOut',
        };
        // if(this.gameService.getClientSideSeatNo(timeOutData.seatNo) == uiConfig.selfUserClientPosInd) {
        //   this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_TIMER_ANIM, timeOutData);
        // }
        this.view.showBetTimer(timeOutData, this.allInForSelf);
        //this.view.showBetTimerForSelf(this.gameService.getGeneratedAt(), this.gameService.getPeerId())
    };
    removeBetTimer = function () {
        this.view.removeAnimations();
        this.view.removeBetTimer();
    };
    handleNewGame = function () {
        this.showUpdatedDealerPos();
        // this.view.removeAnimations();
        this.view.handleNewGame(this.gameService.getCurrentGameId());
    };

    showUpdatedDealerPos = function () {
        setTimeout(
            function () {
                const dealerSeatNo = this.gameService.getDealerSeatNo();
                this.view.showUpdatedDealerPos(dealerSeatNo);
            }.bind(this),
            5,
        );
    };

    updatePlayerBalances = function () {
        setTimeout(() => {
            this.view.updatePlayerBalances(this.gameService.getPlayerBalances());
        }, 100);
    };
    removePlayer = function () {
        const gameTableStatus = this.gameService.getTableStatusData();
        const removePlayerData = this.gameService.getRemovePlayerData();
        const isTourneyStarted = this.gameService.getIsTourneyStarted();
        this.view.removePlayer(removePlayerData, gameTableStatus, isTourneyStarted);
    };
    handleAnteData = function () {
        const anteData = this.gameService.getAnteData();
        const userBalance = this.gameService.getUserBalanceAtSeat(anteData.seatNo);
        this.view.handleAnteData(anteData, userBalance);
    };
    handleBlindData = function () {
        const blindData = this.gameService.getBlidData();
        const userBalance = this.gameService.getUserBalanceAtSeat(blindData.seatNo);
        this.view.handleBlindData(blindData, userBalance);
    };
    handlechangePlayerState = function () {
        const playerStateData = this.gameService.getPlayerStateData();
        this.view.handlechangePlayerState(playerStateData);
    };
    playTimerSound = function () {
        this.view.playTimerSound();
    };
    disablePlayerClick = function () {
        this.view.disablePlayerClick();
    };
    enablePlayerClick = function () {
        this.view.enablePlayerClick();
    };
    disableShowPlayerProfile = function (val) {
        this.view.disableShowPlayerProfile(val.detail.seatNo);
    };
    enableShowPlayerProfile = function (val) {
        this.view.enableShowPlayerProfile(val.detail.seatNo);
    };
}
