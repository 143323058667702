import { Routes } from '@angular/router';

import { routeData } from '@frontend/vanilla/core';

import { TournamentSynopsisResolver } from './tournaments-synopsis.resolver';
import { TournamentsResolver } from './tournaments.resolver';

export const pokerTournamentsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./hybrid-poker-tournaments-component').then((x) => x.HybridPokerTournamentsComponent),
        resolve: {
            initData: TournamentSynopsisResolver,
        },
    },
    {
        path: '',
        children: [
            {
                path: 'calendar',
                loadComponent: () => import('@pokercore/module/tournament-calendar').then((x) => x.TournamentCalendarComponent),
                // resolve: {
                //     initData: TournamentCalendarResolver
                // },
                data: routeData({ allowAllWorkflowTypes: true, allowAuthorized: true }),
            },
            {
                path: 'calendar/:id',
                loadComponent: () => import('@pokercore/module/tournament-calendar').then((x) => x.TournamentDetailsComponent),
                data: routeData({ allowAllWorkflowTypes: true, allowAuthorized: true }),
            },
        ],
    },
    {
        path: ':folderId',
        loadComponent: () => import('./hybrid-poker-tournaments-component').then((x) => x.HybridPokerTournamentsComponent),
        resolve: {
            initData: TournamentsResolver,
        },
        data: {
            isPokerPromotionsModule: true,
        },
    },
];
