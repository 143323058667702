import { Message } from '../../message';

export class PromptLogin extends Message {
    private MESSAGE_NAME: string = 'PromptLogin';
    private alertMessageToShow: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, alertMessageToShow: any | null = null) {
        super(reqServerPeerId);
        this.alertMessageToShow = alertMessageToShow;
    }

    getAlertMessageToShow(): any | null {
        return this.alertMessageToShow;
    }

    setAlertMessageToShow(alertMessageToShow: any | null) {
        this.alertMessageToShow = alertMessageToShow;
    }
}
