import { Message } from '../../message';

export class SSOKeyMessage extends Message {
    private MESSAGE_NAME: string = 'SSOKeyMessage';
    private encodedSSOKey: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, encodedSSOKey: string | null = null) {
        super(reqServerPeerId);
        this.encodedSSOKey = encodedSSOKey;
    }

    getEncodedSSOKey(): string | null {
        return this.encodedSSOKey;
    }

    setEncodedSSOKey(encodedSSOKey: string | null) {
        this.encodedSSOKey = encodedSSOKey;
    }
}
