import { Injectable } from '@angular/core';

import { OnAppInit, Page, PageMatrixService } from '@frontend/vanilla/core';

import * as pc1 from './components-v1/pc.components';
import * as pc2 from './components-v2/pc.components';

@Injectable({ providedIn: 'root' })
export class PageMatrixBootstrapService implements OnAppInit {
    constructor(
        private pageMatrixService: PageMatrixService,
        private page: Page,
    ) {}

    onAppInit() {
        if (this.page) {
            this.pageMatrixService.whenReady.subscribe(() => {
                this.pageMatrixService.registerComponent('pc-text-with-header-bar', pc2.PCTextWithHeaderBarComponent);
            });
        } else {
            this.pageMatrixService.whenReady.subscribe(() => {
                this.pageMatrixService.registerComponent('pc-text-with-header-bar', pc1.PCTextWithHeaderBarComponent);
            });
        }
    }
}
