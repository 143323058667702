import { Message } from '../../message';

export class Option extends Message {
    private MESSAGE_NAME: string = 'Option';
    private optionId: number = 0;
    private optionTitle: any[] | null = null;
    private amount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, optionId: number = 0, optionTitle: any[] | null = null, amount: number = 0) {
        super(reqServerPeerId);
        this.optionId = optionId;
        this.optionTitle = optionTitle;
        this.amount = amount;
    }

    getOptionId(): number {
        return this.optionId;
    }

    setOptionId(optionId: number) {
        this.optionId = optionId;
    }
    getOptionTitle(): any[] | null {
        return this.optionTitle;
    }

    setOptionTitle(optionTitle: any[] | null) {
        this.optionTitle = optionTitle;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
}
