import { Message } from '../../message';

export class CanPlayerRegister extends Message {
    private MESSAGE_NAME: string = 'CanPlayerRegister';
    private conversationId: number = 0;
    private mtctId: number = 0;
    private targetSNGId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, conversationId: number = 0, mtctId: number = 0, targetSNGId: number = 0) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.mtctId = mtctId;
        this.targetSNGId = targetSNGId;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTargetSNGId(): number {
        return this.targetSNGId;
    }

    setTargetSNGId(targetSNGId: number) {
        this.targetSNGId = targetSNGId;
    }
}
