import { Message } from '../../message';

export class RequestMTCTLobbyData extends Message {
    private MESSAGE_NAME: string = 'RequestMTCTLobbyData';
    private mtctIds: any[] | null = null;
    private tourneyRequestType: number = 0;
    private tournyType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctIds: any[] | null = null, tourneyRequestType: number = 0, tournyType: number = 0) {
        super(reqServerPeerId);
        this.mtctIds = mtctIds;
        this.tourneyRequestType = tourneyRequestType;
        this.tournyType = tournyType;
    }

    getMtctIds(): any[] | null {
        return this.mtctIds;
    }

    setMtctIds(mtctIds: any[] | null) {
        this.mtctIds = mtctIds;
    }
    getTourneyRequestType(): number {
        return this.tourneyRequestType;
    }

    setTourneyRequestType(tourneyRequestType: number) {
        this.tourneyRequestType = tourneyRequestType;
    }
    getTournyType(): number {
        return this.tournyType;
    }

    setTournyType(tournyType: number) {
        this.tournyType = tournyType;
    }
}
