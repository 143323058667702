import { Message } from '../../message';

export class LSTourneyParticipantName extends Message {
    private MESSAGE_NAME: string = 'LSTourneyParticipantName';
    private participantNo: number = 0;
    private screenName: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, participantNo: number = 0, screenName: string | null = null) {
        super(reqServerPeerId);
        this.participantNo = participantNo;
        this.screenName = screenName;
    }

    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
}
