import { Message } from '../../message';

export class PlayersCashoutEquityDetails extends Message {
    private MESSAGE_NAME: string = 'PlayersCashoutEquityDetails';
    private tableId: number = 0;
    private gameId: number = 0;
    private surcharge: number = 0;
    private generatedAt: number = 0;
    private timeOutPeriod: number = 0;
    private playerCashoutEquityDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        gameId: number = 0,
        surcharge: number = 0,
        generatedAt: number = 0,
        timeOutPeriod: number = 0,
        playerCashoutEquityDetails: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.gameId = gameId;
        this.surcharge = surcharge;
        this.generatedAt = generatedAt;
        this.timeOutPeriod = timeOutPeriod;
        this.playerCashoutEquityDetails = playerCashoutEquityDetails;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getSurcharge(): number {
        return this.surcharge;
    }

    setSurcharge(surcharge: number) {
        this.surcharge = surcharge;
    }
    getGeneratedAt(): number {
        return this.generatedAt;
    }

    setGeneratedAt(generatedAt: number) {
        this.generatedAt = generatedAt;
    }
    getTimeOutPeriod(): number {
        return this.timeOutPeriod;
    }

    setTimeOutPeriod(timeOutPeriod: number) {
        this.timeOutPeriod = timeOutPeriod;
    }
    getPlayerCashoutEquityDetails(): any[] | null {
        return this.playerCashoutEquityDetails;
    }

    setPlayerCashoutEquityDetails(playerCashoutEquityDetails: any[] | null) {
        this.playerCashoutEquityDetails = playerCashoutEquityDetails;
    }
}
