import { Message } from '../../message';

export class PlayerStatus extends Message {
    private MESSAGE_NAME: string = 'PlayerStatus';
    private playerId: string | null = null;
    private seatingPosition: number = 0;
    private country: string | null = null;
    private imageURL: string | null = null;
    private bet: number = 0;
    private balance: number = 0;
    private state: number = 0;
    private cards: any[] | null = null;
    private sex: string | null = null;
    private city: string | null = null;
    private betVector: any[] | null = null;
    private isSeatedPlayer: boolean | null = false;
    private chatIndicator: boolean | null = false;
    private isCustomAvatar: boolean | null = false;
    private avatarImage: string | null = null;
    private pointCardStatus: number = 0;
    private brandId: string | null = null;
    private avatarImageName: string | null = null;
    private avatarImagePath: string | null = null;
    private forceSitOutReasonId: number = 0;
    private PlayerAccountId: number = 0;
    private playerFullName: string | null = null;
    private playerRealName: string | null = null;
    private playerNoteId: string | null = null;
    private privateScn: boolean | null = false;
    private countryCode: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playerId: string | null = null,
        seatingPosition: number = 0,
        country: string | null = null,
        imageURL: string | null = null,
        bet: number = 0,
        balance: number = 0,
        state: number = 0,
        cards: any[] | null = null,
        sex: string | null = null,
        city: string | null = null,
        betVector: any[] | null = null,
        isSeatedPlayer: boolean | null = false,
        chatIndicator: boolean | null = false,
        isCustomAvatar: boolean | null = false,
        avatarImage: string | null = null,
        pointCardStatus: number = 0,
        brandId: string | null = null,
        avatarImageName: string | null = null,
        avatarImagePath: string | null = null,
        forceSitOutReasonId: number = 0,
        PlayerAccountId: number = 0,
        playerFullName: string | null = null,
        playerRealName: string | null = null,
        playerNoteId: string | null = null,
        privateScn: boolean | null = false,
        countryCode: string | null = null,
    ) {
        super(reqServerPeerId);
        this.playerId = playerId;
        this.seatingPosition = seatingPosition;
        this.country = country;
        this.imageURL = imageURL;
        this.bet = bet;
        this.balance = balance;
        this.state = state;
        this.cards = cards;
        this.sex = sex;
        this.city = city;
        this.betVector = betVector;
        this.isSeatedPlayer = isSeatedPlayer;
        this.chatIndicator = chatIndicator;
        this.isCustomAvatar = isCustomAvatar;
        this.avatarImage = avatarImage;
        this.pointCardStatus = pointCardStatus;
        this.brandId = brandId;
        this.avatarImageName = avatarImageName;
        this.avatarImagePath = avatarImagePath;
        this.forceSitOutReasonId = forceSitOutReasonId;
        this.PlayerAccountId = PlayerAccountId;
        this.playerFullName = playerFullName;
        this.playerRealName = playerRealName;
        this.playerNoteId = playerNoteId;
        this.privateScn = privateScn;
        this.countryCode = countryCode;
    }

    getPlayerId(): string | null {
        return this.playerId;
    }

    setPlayerId(playerId: string | null) {
        this.playerId = playerId;
    }
    getSeatingPosition(): number {
        return this.seatingPosition;
    }

    setSeatingPosition(seatingPosition: number) {
        this.seatingPosition = seatingPosition;
    }
    getCountry(): string | null {
        return this.country;
    }

    setCountry(country: string | null) {
        this.country = country;
    }
    getImageURL(): string | null {
        return this.imageURL;
    }

    setImageURL(imageURL: string | null) {
        this.imageURL = imageURL;
    }
    getBet(): number {
        return this.bet;
    }

    setBet(bet: number) {
        this.bet = bet;
    }
    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
    getState(): number {
        return this.state;
    }

    setState(state: number) {
        this.state = state;
    }
    getCards(): any[] | null {
        return this.cards;
    }

    setCards(cards: any[] | null) {
        this.cards = cards;
    }
    getSex(): string | null {
        return this.sex;
    }

    setSex(sex: string | null) {
        this.sex = sex;
    }
    getCity(): string | null {
        return this.city;
    }

    setCity(city: string | null) {
        this.city = city;
    }
    getBetVector(): any[] | null {
        return this.betVector;
    }

    setBetVector(betVector: any[] | null) {
        this.betVector = betVector;
    }
    getIsSeatedPlayer(): boolean | null {
        return this.isSeatedPlayer;
    }

    setIsSeatedPlayer(isSeatedPlayer: boolean | null) {
        this.isSeatedPlayer = isSeatedPlayer;
    }
    getChatIndicator(): boolean | null {
        return this.chatIndicator;
    }

    setChatIndicator(chatIndicator: boolean | null) {
        this.chatIndicator = chatIndicator;
    }
    getIsCustomAvatar(): boolean | null {
        return this.isCustomAvatar;
    }

    setIsCustomAvatar(isCustomAvatar: boolean | null) {
        this.isCustomAvatar = isCustomAvatar;
    }
    getAvatarImage(): string | null {
        return this.avatarImage;
    }

    setAvatarImage(avatarImage: string | null) {
        this.avatarImage = avatarImage;
    }
    getPointCardStatus(): number {
        return this.pointCardStatus;
    }

    setPointCardStatus(pointCardStatus: number) {
        this.pointCardStatus = pointCardStatus;
    }
    getBrandId(): string | null {
        return this.brandId;
    }

    setBrandId(brandId: string | null) {
        this.brandId = brandId;
    }
    getAvatarImageName(): string | null {
        return this.avatarImageName;
    }

    setAvatarImageName(avatarImageName: string | null) {
        this.avatarImageName = avatarImageName;
    }
    getAvatarImagePath(): string | null {
        return this.avatarImagePath;
    }

    setAvatarImagePath(avatarImagePath: string | null) {
        this.avatarImagePath = avatarImagePath;
    }
    getForceSitOutReasonId(): number {
        return this.forceSitOutReasonId;
    }

    setForceSitOutReasonId(forceSitOutReasonId: number) {
        this.forceSitOutReasonId = forceSitOutReasonId;
    }
    getPlayerAccountId(): number {
        return this.PlayerAccountId;
    }

    setPlayerAccountId(PlayerAccountId: number) {
        this.PlayerAccountId = PlayerAccountId;
    }
    getPlayerFullName(): string | null {
        return this.playerFullName;
    }

    setPlayerFullName(playerFullName: string | null) {
        this.playerFullName = playerFullName;
    }
    getPlayerRealName(): string | null {
        return this.playerRealName;
    }

    setPlayerRealName(playerRealName: string | null) {
        this.playerRealName = playerRealName;
    }
    getPlayerNoteId(): string | null {
        return this.playerNoteId;
    }

    setPlayerNoteId(playerNoteId: string | null) {
        this.playerNoteId = playerNoteId;
    }
    getPrivateScn(): boolean | null {
        return this.privateScn;
    }

    setPrivateScn(privateScn: boolean | null) {
        this.privateScn = privateScn;
    }
    getCountryCode(): string | null {
        return this.countryCode;
    }

    setCountryCode(countryCode: string | null) {
        this.countryCode = countryCode;
    }
}
