import { Message } from '../../message';

export class ResponseBuyMoreChipsSessiontimeExpired extends Message {
    private MESSAGE_NAME: string = 'ResponseBuyMoreChipsSessiontimeExpired';
    private limitType: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, limitType: string | null = null) {
        super(reqServerPeerId);
        this.limitType = limitType;
    }

    getLimitType(): string | null {
        return this.limitType;
    }

    setLimitType(limitType: string | null) {
        this.limitType = limitType;
    }
}
