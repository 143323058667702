import { Routes } from '@angular/router';

import { PokerDownloadComponent } from './poker-download.component';
//import { PokerDownloadModule } from "./poker-download.module";
import { PokerDownloadResolver } from './poker-download.resolver';

export const pokerDownloadRoutes: Routes = [
    {
        path: '',
        component: PokerDownloadComponent,
        resolve: {
            initData: PokerDownloadResolver,
        },
    },
];
