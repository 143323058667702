import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { PlainLinkComponent } from '@frontend/vanilla/core';
import { NavigationBarComponent } from '@pokercore/module/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import * as r from './leaderboard.resolvers';
import { LeaderBoardService } from './leaderboard.service';

@Component({
    selector: 'pk-leaderboard',
    templateUrl: 'leaderboard.component.html',
    standalone: true,
    imports: [
        CommonModule,
        HttpClientModule,
        // HttpModule,
        PlainLinkComponent,
        FormsModule,
        HttpClientModule,
        NgxDatatableModule,
        NavigationBarComponent,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    providers: [LeaderBoardService, r.LeaderBoardResolver],
})
export class LeaderBoardComponent implements OnInit {
    data = [];
    displayedColumns: string[] = ['rank', 'screenName', 'points', 'prize'];
    dataSource = new MatTableDataSource<any>();
    promoID: string;
    noDataFound: boolean = false;
    isFetching: boolean = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private leaderBoardService: LeaderBoardService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        const paginatorIntl = this.paginator._intl;
        paginatorIntl.nextPageLabel = '';
        paginatorIntl.previousPageLabel = '';
        paginatorIntl.firstPageLabel = '';
        paginatorIntl.lastPageLabel = '';
        // Check whether promoID parameter is being passed
        this.promoID = this.getQueryParamIgnoreCase(this.route.snapshot.queryParamMap['params'], 'promoID');
        if (this.promoID) {
            this.leaderBoardService.getLeaderBoardByID(this.promoID).subscribe((response: any) => {
                if (response && response.lbDetails) {
                    this.data = response.lbDetails.lbInfoList;
                    this.dataSource = new MatTableDataSource(this.data);
                    this.dataSource.paginator = this.paginator;
                } else {
                    this.noDataFound = true;
                }
                this.isFetching = false;
            });
        }
    }

    // Allow for case insensitivity in query param map
    getQueryParamIgnoreCase(paramMap: object, key: string) {
        const matchedKey = Object.keys(paramMap).find((k: any) => k.toLowerCase() === key.toLowerCase());
        if (matchedKey) {
            return paramMap[matchedKey];
        }
        return null;
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
}
