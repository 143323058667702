<div class="tmt-subnav">
    <pk-navigation-bar-poker *ngIf="!isPartyNavigation"></pk-navigation-bar-poker>
    <pk-navigation-bar *ngIf="isPartyNavigation"></pk-navigation-bar>
</div>
<vn-message-panel></vn-message-panel>
<div *ngIf="!bwinDisplayMode" class="tmt-details-sec">
    <div *ngIf="tournament">
        <div class="tmt-details">
            <div class="tmt-heading">
                <span class="theme-arrow-left" (click)="goBack()"></span>{{ tournament?.tournamentDetails?.partyGamingTournament?.name }}
            </div>
            <div class="tmt-heading-mobile">
                <div class="heading-info">
                    <span class="theme-left" (click)="goBack()"></span>{{ tournament?.tournamentDetails?.partyGamingTournament?.name }}
                </div>
                <div class="theme-ex" (click)="goBack()"></div>
            </div>
            <div class="row mb-3 w-100">
                <div class="col-sm-4">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            Game starts on
                            <span>
                                {{ tournament?.tournamentDetails?.partyGamingTournament?.startDate | date: 'dd-MM-yyyy' }}
                                {{ tournament?.tournamentDetails?.partyGamingTournament?.startDate | date: 'HH:mm' }}
                            </span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            Late reg level
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.lateRegistrationLevel }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['text-minplayers'] }}
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.minPlayers }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['text-maxplayers'] }}
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.maxPlayers }}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-4">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['combo-type-003'] }}
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.rebuy }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            Addon
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.addOn }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            Starting chips
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.startingChip }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['text-gametype'] }}
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.gameType }}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-4">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['text-prize-pool'] }}
                            <span>{{
                                tournament?.tournamentDetails?.partyGamingTournament?.prizePool?.monetaryType
                                    | formatCurrencyPipe: tournament?.tournamentDetails?.partyGamingTournament?.prizePool?.unit
                            }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['text-registered-players'] }}
                            <span>{{ tournament?.tournamentDetails?.partyGamingTournament?.registeredPlayers }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ pmPokerNavigationView.messages['text-buyin'] }}
                            <span>
                                {{
                                    tournament?.tournamentDetails?.partyGamingTournament?.buyIn?.monetaryType
                                        | formatCurrencyPipe: tournament?.tournamentDetails?.partyGamingTournament?.buyIn?.unit
                                }}
                                +
                                {{
                                    tournament?.tournamentDetails?.partyGamingTournament?.fee?.monetaryType
                                        | formatCurrencyPipe: tournament?.tournamentDetails?.partyGamingTournament?.fee?.unit
                                }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="left-sec pull-left">
                <div [innerHtml]="tournament.tournamentDetails.partyGamingTournament.description | trustAsHtml"></div>
            </div>
            <div class="right-sec pull-left">
                <div class="clear-fix btn-add-cal-desktop">
                    <div class="btn btn-primary" (click)="showMenu($event)">
                        {{ pmPokerNavigationView.messages['AddToCalendar'] }}
                    </div>
                </div>
            </div>
            <div class="clear-fix"></div>
        </div>
        <div class="clear-fix btn-add-cal-mobile">
            <div class="btn btn-primary" (click)="showMenu($event)">{{ pmPokerNavigationView.messages['AddToCalendar'] }}</div>
        </div>
        <div id="menu-items" class="menu-items">
            <div class="pull-right theme-ex" (click)="closeMenu($event)"></div>
            <div class="clear-fix social-links">
                <a [href]="googleCalendarHost + tournament.tournamentDetails.partyGamingTournament.GoogleCalendarUrl" target="_blank"
                    >{{ pmPokerNavigationView.messages['AddToCalendarGoogle'] }}<span class="theme-right"></span
                ></a>
            </div>
            <div class="social-links">
                <a [href]="msnCalendarHost + tournament.tournamentDetails.partyGamingTournament.MsnCalendarUrl" target="_blank"
                    >{{ pmPokerNavigationView.messages['AddToCalendarMsn'] }}<span class="theme-right"></span
                ></a>
            </div>
            <div class="social-links yahoo-cls">
                <a [href]="yahooCalendarHost + tournament.tournamentDetails.partyGamingTournament.YahooCalendarUrl" target="_blank"
                    >{{ pmPokerNavigationView.messages['AddToCalendarYahoo'] }}<span class="theme-right"></span
                ></a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bwinDisplayMode">
    <ng-container *ngIf="tournament">
        <div class="col pm-simple-lalyout pm-page pt-5">
            <div class="tmt-details">
                <div class="tmt-heading">
                    <span class="theme-arrow-left pr-3" (click)="goBack()"></span
                    ><b>{{ tournament.tournamentDetails.partyGamingTournament.name | removeColorCodesPipe }}</b>
                </div>
            </div>
            <p [innerHtml]="tournament.tournamentDetails.partyGamingTournament.description | trustAsHtml"></p>
            <div class="row">
                <div class="col-sm-6" id="tournament-details">
                    <div class="row">
                        <div class="col-sm-3">
                            <p>
                                <strong>{{ pmPokerNavigationView.sortBy }}</strong>
                            </p>
                            <p>
                                {{ tournament.tournamentDetails.partyGamingTournament.startDate | date: 'dd-MM-yyyy' }}
                                {{ tournament.tournamentDetails.partyGamingTournament.startDate | date: 'HH:mm' }}
                            </p>
                        </div>
                        <div class="col-sm-3">
                            <p>
                                <strong>{{ pmPokerNavigationView.messages['text-gametype'] }}</strong>
                            </p>
                            <p>{{ tournament.tournamentDetails.partyGamingTournament.gameType | gameNamePipe }}</p>
                        </div>
                        <div class="col-sm-3">
                            <p>
                                <strong>{{ pmPokerNavigationView.messages['text-registered-players'] }}</strong>
                            </p>
                            <p>{{ tournament.tournamentDetails.partyGamingTournament.registeredPlayers }}</p>
                        </div>
                        <div class="col-sm-3">
                            <p>
                                <strong>{{ pmPokerNavigationView.messages['text-buyin'] }}</strong>
                            </p>
                            <p>
                                {{
                                    tournament.tournamentDetails.partyGamingTournament.buyIn.monetaryType
                                        | formatCurrencyPipe: tournament.tournamentDetails.partyGamingTournament.buyIn.unit
                                }}
                                +
                                {{
                                    tournament.tournamentDetails.partyGamingTournament.fee.monetaryType
                                        | formatCurrencyPipe: tournament.tournamentDetails.partyGamingTournament.fee.unit
                                }}
                            </p>
                        </div>
                        <div class="row mb-3 w-100">
                            <div class="col-sm-6">
                                <ul class="list-group">
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ pmPokerNavigationView.messages['combo-type-003'] }}
                                        <span>{{ tournament.tournamentDetails.partyGamingTournament.rebuy }}</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Addon
                                        <span>{{ tournament.tournamentDetails.partyGamingTournament.addOn }}</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Starting chips
                                        <span>{{ tournament.tournamentDetails.partyGamingTournament.startingChip }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <ul class="list-group">
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Late reg level
                                        <span>{{ tournament.tournamentDetails.partyGamingTournament.lateRegistrationLevel }}</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ pmPokerNavigationView.messages['text-minplayers'] }}
                                        <span>{{ tournament.tournamentDetails.partyGamingTournament.minPlayers }}</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ pmPokerNavigationView.messages['text-maxplayers'] }}
                                        <span>{{ tournament.tournamentDetails.partyGamingTournament.maxPlayers }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3" id="prize-pool">
                    <h3>{{ pmPokerNavigationView.messages['text-prize-pool'] }}</h3>
                    <p>
                        Current:
                        {{
                            tournament.tournamentDetails.partyGamingTournament.prizePool.monetaryType
                                | formatCurrencyPipe: tournament.tournamentDetails.partyGamingTournament.prizePool.unit
                        }}
                    </p>
                </div>
                <div class="col-sm-3" id="player-details">
                    <h3>{{ pmPokerNavigationView.messages['text-players'] }}</h3>
                    <p>
                        {{ pmPokerNavigationView.messages['text-registered-players'] }}
                        {{ tournament.tournamentDetails.partyGamingTournament.registeredPlayers }}
                    </p>
                </div>
                <button class="btn btn-block btn-primary mb-3" (click)="goToTournamentCalendar()">
                    {{ pmPokerNavigationView.messages['text-selecttournament'] }}
                </button>
            </div>
        </div>
    </ng-container>
</div>
