import { Message } from '../../message';
import { Buddy } from './Buddy';

export class AddBuddy extends Message {
    private MESSAGE_NAME: string = 'AddBuddy';
    private buddy: Buddy | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, buddy: Buddy | null = null) {
        super(reqServerPeerId);
        this.buddy = buddy;
    }

    getBuddy(): Buddy | null {
        return this.buddy;
    }

    setBuddy(buddy: Buddy | null) {
        this.buddy = buddy;
    }
}
