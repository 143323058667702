import { Message } from '../../message';

export class PartyInboxInfo extends Message {
    private MESSAGE_NAME: string = 'PartyInboxInfo';
    private isPmcEnabled: boolean | null = false;
    private mailCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, isPmcEnabled: boolean | null = false, mailCount: number = 0) {
        super(reqServerPeerId);
        this.isPmcEnabled = isPmcEnabled;
        this.mailCount = mailCount;
    }

    getIsPmcEnabled(): boolean | null {
        return this.isPmcEnabled;
    }

    setIsPmcEnabled(isPmcEnabled: boolean | null) {
        this.isPmcEnabled = isPmcEnabled;
    }
    getMailCount(): number {
        return this.mailCount;
    }

    setMailCount(mailCount: number) {
        this.mailCount = mailCount;
    }
}
