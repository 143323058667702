import { Message } from '../../message';

export class ChangeEmail extends Message {
    private MESSAGE_NAME: string = 'ChangeEmail';
    private newEmail: string | null = null;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, newEmail: string | null = null, conversationId: number = 0) {
        super(reqServerPeerId);
        this.newEmail = newEmail;
        this.conversationId = conversationId;
    }

    getNewEmail(): string | null {
        return this.newEmail;
    }

    setNewEmail(newEmail: string | null) {
        this.newEmail = newEmail;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
