<section class="psimu-get-started">
    <div id="getStarted">
        <div class="psimu-head">
            <a [href]="homePage" class="close-btn theme-ex mobile" *ngIf="!nativeApp" (click)="trackingCloseButton()"></a>
        </div>
        <div class="psimu-get-started-main">
            <div class="psimu-wiz-content">
                <h2>{{ commonParams.hey }}, <br />{{ commonParams.Im_Whiz }}</h2>
                <h5>{{ commonParams.poker_before }}</h5>
                <div class="psimu-wiz" [ngClass]="{ 'psimu-wiz-show': showWiz }">
                    <img src="https://scmedia.itsfogo.com/$-$/fa0e92b2cfdb47deb12eca1e58d81613.png" alt="Get Started" />
                </div>
            </div>
            <div class="psimu-get-started-bottom">
                <div class="psimu-check-buttons d-flex">
                    <button class="btn btn-primary" (click)="checkEvent(true)">{{ commonParams.yes }}</button>
                    <button class="btn btn-primary" (click)="checkEvent(false)">{{ commonParams.no }}</button>
                </div>
                <div class="psimu-download-link mt-5" *ngIf="!nativeApp">
                    <span>{{ commonParams.love_poker }}</span>
                    <a [href]="parameters?.url" (click)="trackingDownloadEvent(parameters?.url, parameters?.text || 'Download now')">{{
                        parameters?.text || 'Download now'
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="wiz-modal hidden-anime">
    <div class="wiz-animate"></div>
</div>
