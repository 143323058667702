import { KeyValue } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { DeviceService, MessageQueueService, NavigationService, UserService } from '@frontend/vanilla/core';
import { HandHistoryClientConfig, NavigationBootstrapService, VCClientService } from '@pokercore/module/core';

import { HandHistoryRoundInputModel } from '../hand-history.models';
import { HandHistoryService } from '../hand-history.service';
import { HandFiltersComponent } from './hand-filters/hand-filters.component';
import { GameTableService } from './hh-replayer/replayer/game/game-table/game-table.service';
import { MobileTabConstants } from './hh-replayer/replayer/models/MobileTab';
import { TabConstants } from './hh-replayer/replayer/models/Tab';
import { GameTable } from './hh-replayer/replayer/models/game-table';
import { GameService } from './hh-replayer/replayer/services/game.service';
import { TextViewComponent } from './text-view/text-view.component';

@Component({
    selector: 'pk-hands-mainbody',
    templateUrl: 'hands-mainbody.component.html',
})
export class HandsMainbodyComponent implements OnInit {
    toggleReplayHand: boolean;
    activeRoundId: string | undefined;
    index: number;
    tournamentHands: any;
    gameTypes: any;
    username: string;
    userid: string;
    toggleDateCG: boolean = false;
    toggleDateTR: boolean = false;
    landingPageUrl: string;
    touchStartX: number;
    prevUrl: string;
    showErrorMsg: any;
    isTenSeaterTable: boolean = false;
    currency = {
        USD: '$',
        EUR: '€',
        CAD: 'CAD',
        GBP: '£',
        RUB: '₽',
        DKK: 'Kr',
        GEL: '‎₾',
    };

    public gameTable: GameTable;
    mobileNativeApp: boolean;
    MacDesktop: boolean;
    vcClient: boolean;
    lastGroupType: string;
    isUnCalledBet: boolean = false;
    isReplayerThree: boolean = false;
    disableTextView: boolean = false;

    //@ViewChild(ReplayHandComponent) replay_hand: any;
    @ViewChild(HandFiltersComponent) handFilter: any;
    @ViewChild(TextViewComponent) textView: any;
    @Output() roundIdError = new EventEmitter<string>();

    constructor(
        public serve: HandHistoryService,
        public userService: UserService,
        public gameService: GameService,
        private GameTable: GameTableService,
        public navService: NavigationBootstrapService,
        public navigation: NavigationService,
        private vCClientService: VCClientService,
        private deviceService: DeviceService,
        private handhistoryConfig: HandHistoryClientConfig,
        // private windowref: WindowRef,
        private messageQueue: MessageQueueService,
    ) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    ngOnInit() {
        this.gameTypes = this.getGameTypes();
        this.username = this.userService.screenname ?? '';
        this.userid = this.userService.username ?? '';
        this.landingPageUrl = '/' + this.userService.lang + '/poker';
        this.prevUrl = this.navService.getPreviousUrl();
        this.mobileNativeApp = this.vCClientService.isMobileApp();
        this.MacDesktop = this.vCClientService.isMacDesktop();
        this.vcClient = this.vCClientService.isClient();

        this.handhistoryConfig.whenReady.subscribe(() => {
            this.isUnCalledBet = this.handhistoryConfig.isUnCalledBet;
            this.isReplayerThree = this.handhistoryConfig.isReplayerThree;
            this.gameService.isReplayerV3 = this.isReplayerThree;
            this.disableTextView = this.handhistoryConfig.disableTextView;
        });

        if (this.serve.isMobile) {
            this.isReplayerThree = false;
        }
        this.GameTable.gtInitializeDone = false;
        // this.serve.isMobile = this.deviceService.isMobile;
        //let moment = require('moment-timezone');
        //let timeZone = moment.tz.guess();
        //let time = new Date();
        //let timeZoneOffset = time.getTimezoneOffset();
        //this.timeZone = moment.tz.zone(timeZone).abbr(timeZoneOffset);
    }

    roundIdErrorMethod(event: any) {
        this.roundIdError.next(event);
    }

    getGameTypes() {
        const gametypes = this.serve.content.form.gametypevalues.values,
            extractedGameTypes = {};
        for (let i = 0; i < gametypes.length; i++) {
            extractedGameTypes[gametypes[i].value] = gametypes[i].text;
        }
        return extractedGameTypes;
    }

    showHandDetails(index: number, event: any) {
        const target = event.target || event.srcElement || event.currentTarget,
            idAttr = target.attributes[0],
            roundId = event.currentTarget.getAttribute('roundid');
        if (idAttr && (idAttr.value.indexOf('hand-btn') > -1 || idAttr.value.indexOf('hand-icons') > -1)) {
            this.index = index;
            if (idAttr.value.indexOf('replay-btn') > -1 || idAttr.value.indexOf('hand-icons') > -1) {
                let PROPERTIES: any;
                if (this.isReplayerThree) {
                    PROPERTIES = TabConstants.Tabnew;
                } else {
                    PROPERTIES = TabConstants.Tab;
                }
                this.serve.getReplayer(roundId).subscribe(
                    (res) => {
                        const bodyElement = document.getElementsByTagName('body')[0],
                            gameTableContainer: any = document.querySelector('.game-table-container'),
                            winWidth = window.outerWidth,
                            replayHandPosition = window.scrollY;
                        this.serve.activeHandID = roundId;
                        const finalRes = res.gameRoundJson;
                        const content = this.serve.content;
                        if (this.serve.isMobile) {
                            this.gameService.PROPERTIES = MobileTabConstants.Tab;
                            if (this.deviceService.isTablet || this.GameTable.IpadTabUserAgent) {
                                this.gameService.PROPERTIES = MobileTabConstants.Tablet;
                            }
                        } else {
                            this.gameService.PROPERTIES = PROPERTIES;
                        }
                        this.gameService.gameTable = finalRes;
                        this.gameService.response = finalRes;
                        this.gameService.content = content;
                        this.gameService.isMobile = this.serve.isMobile;
                        this.serve.showErrorMessage = false;
                        if (finalRes.gameRound.maxplayers === 10) {
                            this.serve.showErrorMessage = true;
                        }
                        this.gameService.isReplayerOnInit(true);
                        this.gameService.isMobile = this.serve.isMobile;
                        this.gameTable = this.gameService.gameTable;
                        this.gameService.isReplayerV3 = this.isReplayerThree;
                        this.GameTable.initGameTable(this.gameTable);
                        this.GameTable.isMobile = this.serve.isMobile;
                        this.serve.popupToggle = true;
                        this.serve.replayHandPosition = replayHandPosition;
                        this.gameTable.isMobile = this.serve.isMobile;
                        this.gameTable.onInit = true;

                        const navClass: any = document.getElementsByClassName('slot-main')[0];
                        const filter: any = document.getElementsByClassName('filter_btn')[0];
                        const mobilefilter: any = document.getElementsByClassName('apply-filters')[1];
                        const subnav: any = document.getElementsByClassName('mobile-only-menu')[0];
                        navClass.classList.add('hideNav');
                        filter.classList.add('hideHHOfilter');
                        mobilefilter.classList.add('hideHHOfilter');
                        if (this.serve.isMobile) {
                            subnav.classList.add('hidesubNav');
                        }
                        (document.querySelector('body') as HTMLElement).style.top = -replayHandPosition + 'px';
                        if (bodyElement.className.indexOf('noscroll') <= -1) {
                            bodyElement.className += ' noscroll';
                        }
                        if (winWidth < 768 && gameTableContainer) {
                            gameTableContainer.click();
                        }
                        const hideBottomNavForIdevice: any = document.getElementsByClassName('bottom-nav')[0];
                        if (hideBottomNavForIdevice && hideBottomNavForIdevice.className.indexOf('bottom-nav') > -1) {
                            this.serve.showBottomNavForIdevice = false;
                            hideBottomNavForIdevice.style.zIndex = 0;
                        }
                        // }
                    },
                    (error) => {
                        this.messageQueue.clear();
                        if (error.errorCode === '403') {
                            this.messageQueue.add(error.vnMessages[0].html);
                        } else if (!error.hasOwnProperty('errorCode')) {
                            this.messageQueue.add(error.message);
                        }
                    },
                );
            } else if (idAttr.value.indexOf('text-view-btn') > -1 || idAttr.value.indexOf('icon-text-view') > -1) {
                this.getTextViewPopup(roundId, true);
            }
        }
    }

    // convertTime(time: number) {
    // 	let d: any = new Date(time);
    // 	d = d.toLocaleTimeString().toString();
    // 	//let meridian = d.split(' ')[1];
    // 	d = d.split(':')[0] + ':' + d.split(':')[1];
    // 	return d;
    // }

    getTime(time: any) {
        time = time.split('T')[1].split(':');
        time = time[0] + ':' + time[1];
        return time;
    }

    getDateString(groupType: string) {
        let date: any = new Date();
        switch (groupType) {
            case 'YesterdayHands':
                date.setDate(date.getDate() - 1);
                break;
            case 'Last7daysHands':
                date.setDate(date.getDate() - 7);
                break;
            case 'Last2weeksHands':
                date.setDate(date.getDate() - 14);
                break;
            case 'Last30daysHands':
                date.setDate(date.getDate() - 30);
                break;
            case 'Last3monthsHands':
                date.setDate(date.getDate() - 90);
                break;
        }
        date = date.toDateString();
        date = date.split(' ');
        date = date[2] + ' ' + date[1] + ', ' + date[3];
        return date;
    }

    convertUnixTime(time: any) {
        const d = new Date(time);
        return d.getTime();
    }

    getTextViewPopup(roundId: string, singleHand: boolean) {
        const bodyElement = document.getElementsByTagName('body')[0];
        if (!this.serve.singleHand || singleHand) {
            this.textView.showTextPopup = true;
            this.serve.popupToggle = true;
            this.textView.textButtonPosition = window.scrollY;
            (document.querySelector('body') as HTMLElement).style.top = -this.textView.textButtonPosition + 'px';
            if (bodyElement.className.indexOf('noscroll') <= -1) {
                bodyElement.className += ' noscroll';
            }
        }
        const handHistoryRoundInputModel: HandHistoryRoundInputModel = new HandHistoryRoundInputModel();
        handHistoryRoundInputModel.RoundId = roundId;
        this.textView.getRoundTextView(handHistoryRoundInputModel, roundId);
    }

    swipe(currentIndex: number, eventType: any, groupItem: string) {
        let currentElement;
        if (groupItem === 'singleHand') {
            currentElement = document.querySelector('.hands-details.singleHand');
        } else {
            currentElement = document.querySelectorAll('.grouped-hands[group-type="' + groupItem + '"] .hands-details')[currentIndex];
        }
        if (eventType == 'swipeleft' && currentElement.className.indexOf('show-details') <= -1) {
            this.activeRoundId = currentElement.getAttribute('roundid');
        } else if (eventType == 'swiperight' && currentElement.className.indexOf('show-details') >= 0) {
            this.activeRoundId = undefined;
        }
    }

    convertToString(value: any) {
        return value.toString();
    }

    sortHands() {
        let filterHandsDate,
            filterHands,
            groupedBlocks = document.getElementsByClassName('grouped-hands');
        if (this.serve.hhGames === 'cashgames') {
            filterHandsDate = this.serve.filterGroupedHandsCG;
            filterHands = this.serve.filterHandsCG;
            if (filterHandsDate && groupedBlocks.length > 1) {
                this.serve.filterGroupedHandsCG = false;
                this.serve.filterHandsCG = false;
            } else if (!filterHandsDate && groupedBlocks.length > 1) {
                this.serve.filterGroupedHandsCG = true;
                this.serve.filterHandsCG = false;
            } else if (filterHands && groupedBlocks.length === 1) {
                const grouptype = (document.querySelector('.grouped-hands.active') as HTMLElement).getAttribute('group-type') ?? '';
                this.serve[grouptype] = this.serve[grouptype].reverse();
                this.serve.filterHandsCG = false;
                this.serve.filterGroupedHandsCG = false;
            } else if (!filterHands && groupedBlocks.length === 1) {
                const grouptype = (document.querySelector('.grouped-hands.active') as HTMLElement).getAttribute('group-type') ?? '';
                this.serve[grouptype] = this.serve[grouptype].reverse();
                this.serve.filterHandsCG = true;
                this.serve.filterGroupedHandsCG = false;
            }
        }
        if (this.serve.hhGames === 'tournaments') {
            filterHandsDate = this.serve.filterGroupedHandsTR;
            filterHands = this.serve.filterHandsTR;
            if (filterHandsDate && groupedBlocks.length > 1) {
                this.serve.filterGroupedHandsTR = false;
                this.serve.filterHandsTR = false;
            } else if (!filterHandsDate && groupedBlocks.length > 1) {
                this.serve.filterGroupedHandsTR = true;
                this.serve.filterHandsTR = false;
            } else if (filterHands && groupedBlocks.length === 1) {
                const grouptype = (document.querySelector('.grouped-hands.active') as HTMLElement).getAttribute('group-type') ?? '';
                this.serve[grouptype] = this.serve[grouptype].reverse();
                this.serve.filterHandsTR = false;
                this.serve.filterGroupedHandsTR = false;
            } else if (!filterHands && groupedBlocks.length === 1) {
                const grouptype = (document.querySelector('.grouped-hands.active') as HTMLElement).getAttribute('group-type') ?? '';
                this.serve[grouptype] = this.serve[grouptype].reverse();
                this.serve.filterHandsTR = true;
                this.serve.filterGroupedHandsTR = false;
            }
        }
    }

    checkFilterStatus() {
        if (this.serve.hhGames === 'cashgames') {
            if (this.serve.filterGroupedHandsCG) {
                return true;
            } else if (this.serve.filterHandsCG) {
                return true;
            } else {
                return false;
            }
        } else {
            if (this.serve.filterGroupedHandsTR) {
                return true;
            } else if (this.serve.filterHandsTR) {
                return true;
            } else {
                return false;
            }
        }
    }

    getFilterIcon() {
        const groupedBlocks = document.getElementsByClassName('grouped-hands');
        if (this.serve.handHistory.gameRounds.length > 0 && !this.serve.singleHand && groupedBlocks.length > 1) {
            return true;
        } else {
            return false;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    compareCards(playerHoleCards: any, finalCards: any, gameType: string) {
        const checkDiff = false;
        if (finalCards) {
            if (finalCards.length != 5) {
                return false;
            } else {
                return true;
            }
        }
        return checkDiff;
    }

    touchStart(event: any) {
        this.touchStartX = event.targetTouches[0].pageX;
    }

    touchEnd(event: any, currentIndex: number, groupItem: string) {
        const touchEndX = event.changedTouches[0].pageX;
        if (touchEndX > this.touchStartX + 50) {
            this.swipe(currentIndex, 'swiperight', groupItem);
        } else if (this.touchStartX > touchEndX + 50) {
            this.swipe(currentIndex, 'swipeleft', groupItem);
        }
    }

    groupItems() {
        let groupItems;
        if (this.serve.hhGames === 'cashgames') {
            groupItems = ['TodayHandsCG', 'YesterdayHandsCG', 'Last7daysHandsCG', 'Last2weeksHandsCG', 'Last30daysHandsCG', 'Last3monthsHandsCG'];
            let groupedBlocks = document.getElementsByClassName('grouped-hands'),
                i;
            for (i = groupItems.length - 1; i >= 0; i--) {
                if (this.serve[groupItems[i]] && this.serve[groupItems[i]].length > 0) {
                    this.lastGroupType = groupItems[i];
                    break;
                }
            }
            if (this.serve.filterGroupedHandsCG) {
                if (groupedBlocks.length > 1) {
                    groupItems.reverse();
                }
            }
        } else {
            groupItems = ['TodayHandsTR', 'YesterdayHandsTR', 'Last7daysHandsTR', 'Last2weeksHandsTR', 'Last30daysHandsTR', 'Last3monthsHandsTR'];
            let groupedBlocks = document.getElementsByClassName('grouped-hands'),
                i;
            for (i = groupItems.length - 1; i >= 0; i--) {
                if (this.serve[groupItems[i]] && this.serve[groupItems[i]].length > 0) {
                    this.lastGroupType = groupItems[i];
                    break;
                }
            }
            if (this.serve.filterGroupedHandsTR) {
                if (groupedBlocks.length > 1) {
                    groupItems.reverse();
                }
            }
        }
        return groupItems;
    }

    navigateBack() {
        this.navigation.goTo(this.prevUrl, { replace: true });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    roundsTotalCount(groupItem: string) {
        let totalCount = this.serve.handHistory.totalCount,
            groupItems: any = [],
            lastGroupType = '';
        if (this.serve.hhGames === 'cashgames') {
            groupItems = ['TodayHandsCG', 'YesterdayHandsCG', 'Last7daysHandsCG', 'Last2weeksHandsCG', 'Last30daysHandsCG', 'Last3monthsHandsCG'];
        } else {
            groupItems = ['TodayHandsTR', 'YesterdayHandsTR', 'Last7daysHandsTR', 'Last2weeksHandsTR', 'Last30daysHandsTR', 'Last3monthsHandsTR'];
        }
        for (let i = groupItems.length - 1; i >= 0; i--) {
            if (this.serve[groupItems[i]].length > 0) {
                if (lastGroupType.length === 0) {
                    lastGroupType = groupItems[i];
                }
                totalCount -= this.serve[groupItems[i]].length;
            }
        }
        totalCount += this.serve[lastGroupType].length;
        return {
            lastGroupType: lastGroupType,
            totalCount: totalCount,
        };
    }

    groupCount(groupItem: string) {
        let suitedElement: any;
        if (this.serve.hhGames === 'cashgames') {
            suitedElement = document.getElementById('pocketSuitedCG');
        } else {
            suitedElement = document.getElementById('pocketSuitedTR');
        }
        if (
            this.roundsTotalCount(groupItem).totalCount > this.serve[groupItem].length &&
            this.roundsTotalCount(groupItem).lastGroupType === groupItem &&
            suitedElement &&
            !suitedElement.checked
        ) {
            return true;
        }
        return false;
    }

    getSubString(name: any) {
        let subString: any;
        subString = name.substring(3, name.length);
        return subString;
    }

    closeErrorBox(event: any) {
        const className = event.target.className;
        const winWidth = window.outerWidth;
        if (winWidth >= 768 || className.indexOf('button') > -1) {
            if (className.indexOf('game-table-container') > -1 || className.indexOf('button') > -1) {
                const bodyElement = document.getElementsByTagName('body')[0];
                this.GameTable.gtInitializeDone = false;
                this.showErrorMsg = false;
                (document.querySelector('body') as HTMLElement).style.top = -this.serve.replayHandPosition + 'px';
                (document.querySelector('body') as HTMLElement).style.top = '0px';
                if (bodyElement.className.indexOf('noscroll') > -1) {
                    bodyElement.classList.remove('noscroll');
                }
                window.scroll(0, this.serve.replayHandPosition);
            }
        }
    }
    getFormatedDate(dateValue: any) {
        const d: any = dateValue.substring(5, 7) + '/' + dateValue.substring(8, 10) + '/' + dateValue.substring(0, 4);
        return d;
    }
}
