import { Message } from '../../message';

export class FRSessionTimer extends Message {
    private MESSAGE_NAME: string = 'FRSessionTimer';
    private sessionTimeRemaining: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sessionTimeRemaining: number = 0) {
        super(reqServerPeerId);
        this.sessionTimeRemaining = sessionTimeRemaining;
    }

    getSessionTimeRemaining(): number {
        return this.sessionTimeRemaining;
    }

    setSessionTimeRemaining(sessionTimeRemaining: number) {
        this.sessionTimeRemaining = sessionTimeRemaining;
    }
}
