import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnChanges, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { PokerTournamentRibbonService } from '../poker-tournament-ribbon.service';
import { ParseRibbonMarkupPipe } from './parse-ribbon-markup.pipe';

@Component({
    selector: 'pk-tournament-timer-ribbon',
    templateUrl: 'pk-tournament-timer-ribbon.component.html',
    standalone: true,
    imports: [CommonModule, TrustAsHtmlPipe, RouterModule, DynamicHtmlDirective, ParseRibbonMarkupPipe],
})
export class PkTournamentTimerRibbonComponent implements OnChanges {
    tournamentDataList: any;
    widgetConfig: any;
    currenttime: any;
    minutes: number;
    hours: number;
    seconds: number;
    daysleft: number;
    endTime: any;
    lateRegEndTime: any;
    millisecondsleft: number;
    lateRegisMillisecondsleft: number;
    tournamentData: any;
    ids: string;
    lateRegistrationInMinutes: string;

    upcomingTournamentStartText: string;
    tournamentName: string;
    tournamentTime: string;
    lateRegistrationText: string;
    htmlText: any;
    htmlTextUpdated: any;
    htmlStyles: any;
    replacements: any = {};
    parameters: any;
    hideDays: any;
    ctaLink: string;
    ribbonStatusClass: string;
    isRegistrationClosed: boolean = false;
    htmlTextPlaceholder: any;
    showRibbon: boolean = false;
    @Input() tournamentRibbonData: any;
    @Input() singlePromoTimerStyles: any;
    @Output() registrationStatus = new EventEmitter<boolean>();

    constructor(
        public zone: NgZone,
        private pokerTournamentRibbonService: PokerTournamentRibbonService,
        private parseRibbonMarkupPipe: ParseRibbonMarkupPipe,
    ) {}

    ngOnChanges(): void {
        this.htmlText = this.tournamentRibbonData?.text;
        this.htmlStyles = this.singlePromoTimerStyles?.text;
        this.ids = this.tournamentRibbonData?.parameters?.Ids;
        this.lateRegistrationInMinutes = this.tournamentRibbonData?.parameters?.lateRegistrationInMinutes;
        this.parameters = this.tournamentRibbonData?.parameters;

        this.upcomingTournamentStartText = this.tournamentRibbonData?.parameters?.upcomingTournamentStartTextFormat;
        this.tournamentName = this.tournamentRibbonData?.parameters?.tournamentName;
        this.tournamentTime = this.tournamentRibbonData?.parameters?.tournamentTime;
        this.lateRegistrationText = this.tournamentRibbonData?.parameters?.lateRegistrationTextFormat;
        if (this.ids != undefined && this.ids != null) {
            this.pokerTournamentRibbonService
                .getTournamentFeedData(JSON.stringify(this.ids), this.lateRegistrationInMinutes)
                .subscribe((res: any) => {
                    this.tournamentDataList = res?.tournaments;
                    this.ctaLink = res?.widgetConfig?.ctaLink;
                    this.initiateCountdown();
                });
        }

        setInterval(() => {
            this.refreshFeed();
        }, 1000);
    }

    initiateCountdown() {
        this.getCountdownMarkup();
        setInterval(() => {
            this.refreshFeed();
        }, 1000);
        setTimeout(() => {
            this.showRibbon = true;
        }, 1000);
    }

    getCountdownMarkup() {
        const ribbonPlaceElement = document.createElement('div');
        ribbonPlaceElement.innerHTML = this.htmlText;

        const placeholderSelector = ribbonPlaceElement.querySelector('.countdown-content');
        if (placeholderSelector !== null) {
            this.htmlTextPlaceholder = placeholderSelector?.innerHTML;
        } else {
            setTimeout(() => {
                const placeholderSelector = document.querySelector('.countdown-content');
                if (placeholderSelector !== null) {
                    this.htmlTextPlaceholder = placeholderSelector?.innerHTML;
                }
            }, 1000);
        }
    }

    getData(ids: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.pokerTournamentRibbonService.getTournamentFeedData(JSON.stringify(ids)).subscribe((res: any) => {});
    }

    private formatTimeStr(days: number, hours: number, minutes: number, seconds: number, tournament: any) {
        const cta = this.ctaLink.replace('{{id}}', tournament?.id);
        this.replacements['DAYS'] = days;
        this.replacements['HOURS'] = hours;
        this.replacements['MINUTES'] = minutes;
        this.replacements['SECONDS'] = seconds;
        this.replacements['PROMOFLASHMESSAGE'] = tournament?.name;
        this.replacements['BUYIN'] = this.formatBuyIn(tournament?.buyIn);
        this.replacements['CTALINK'] = cta;

        // this.htmlTextUpdated = this.parseRibbonMarkupPipe.transform(this.htmlText, this.replacements);
        const placeholderSelector = document.querySelector('.countdown-content');
        if (placeholderSelector) {
            // this.htmlTextPlaceholder = placeholderSelector?.innerHTML;
            placeholderSelector.innerHTML = this.parseRibbonMarkupPipe.transform(this.htmlTextPlaceholder, this.replacements);
        }

        this.hideDays = days <= 0 ? true : false;
    }

    refreshFeed() {
        if (this.tournamentDataList?.length) {
            for (let t = 0; t < this.tournamentDataList.length; t++) {
                this.currenttime = new Date();
                this.endTime = new Date(this.tournamentDataList[t].startDate);
                this.lateRegEndTime = new Date(this.tournamentDataList[t].durationLateRegClose);
                this.millisecondsleft = this.endTime - this.currenttime;
                this.lateRegisMillisecondsleft = this.lateRegEndTime - this.currenttime;
                if (this.millisecondsleft > 0 || this.lateRegisMillisecondsleft > 0) {
                    if (this.millisecondsleft > 0) {
                        this.daysleft = Math.floor(this.millisecondsleft / (24 * 60 * 60 * 1000));
                        this.hours = Math.floor((this.millisecondsleft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

                        this.minutes = Math.floor((this.millisecondsleft % (60 * 60 * 1000)) / (60 * 1000));
                        this.seconds = Math.floor((this.millisecondsleft % (60 * 1000)) / 1000);

                        this.formatTimeStr(this.daysleft, this.hours, this.minutes, this.seconds, this.tournamentDataList[t]);
                        this.ribbonStatusClass = 'registration-started';
                    }
                    if (this.millisecondsleft <= 0 && this.lateRegisMillisecondsleft > 0) {
                        this.daysleft = Math.floor(this.lateRegisMillisecondsleft / (24 * 60 * 60 * 1000));
                        this.hours = Math.floor((this.lateRegisMillisecondsleft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

                        this.minutes = Math.floor((this.lateRegisMillisecondsleft % (60 * 60 * 1000)) / (60 * 1000));
                        this.seconds = Math.floor((this.lateRegisMillisecondsleft % (60 * 1000)) / 1000);

                        this.formatTimeStr(this.daysleft, this.hours, this.minutes, this.seconds, this.tournamentDataList[t]);
                        this.ribbonStatusClass = 'late-registration';
                    }
                    this.isRegistrationClosed = false;
                    this.registrationStatus.emit(true);
                    return;
                } else {
                    this.isRegistrationClosed = true;
                    this.registrationStatus.emit(false);
                }
            }
        } else {
            this.isRegistrationClosed = true;
            this.registrationStatus.emit(false);
        }
    }

    formatBuyIn(value: string) {
        const splitVal: any = value.split('+');
        const symbol = splitVal[0].replace(/[.+0-9\s]/g, '');
        const primaryVal: any = Number(splitVal[0].replace(/[^0-9\.+]/g, ''));
        const secondaryVal: any = Number(splitVal[1].replace(/[^0-9\.+]/g, ''));

        const finalValue = parseFloat(primaryVal) + parseFloat(secondaryVal);
        return symbol + finalValue.toFixed(2);
    }

    openClient(tournament: any) {
        location.href = this.widgetConfig.ctaLink.replace('{{id}}', tournament.id);
    }
}
