import { Message } from '../../message';

export class MTCTPrizePoolChangeEvent extends Message {
    private MESSAGE_NAME: string = 'MTCTPrizePoolChangeEvent';
    private mtctId: number = 0;
    private totalPrizePool: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, totalPrizePool: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.totalPrizePool = totalPrizePool;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
}
