import { Message } from '../../message';

export class PoolKickoutNotification extends Message {
    private MESSAGE_NAME: string = 'PoolKickoutNotification';
    private entryId: number = 0;
    private messageToClient: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, entryId: number = 0, messageToClient: any | null = null) {
        super(reqServerPeerId);
        this.entryId = entryId;
        this.messageToClient = messageToClient;
    }

    getEntryId(): number {
        return this.entryId;
    }

    setEntryId(entryId: number) {
        this.entryId = entryId;
    }
    getMessageToClient(): any | null {
        return this.messageToClient;
    }

    setMessageToClient(messageToClient: any | null) {
        this.messageToClient = messageToClient;
    }
}
