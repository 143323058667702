import { Message } from '../../message';

export class ArjelEndOfTheGameSeatData extends Message {
    private MESSAGE_NAME: string = 'ArjelEndOfTheGameSeatData';
    private conversationId: number = 0;
    private handNumber: number = 0;
    private playersInfo: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, conversationId: number = 0, handNumber: number = 0, playersInfo: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.conversationId = conversationId;
        this.handNumber = handNumber;
        this.playersInfo = playersInfo;
    }

    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getHandNumber(): number {
        return this.handNumber;
    }

    setHandNumber(handNumber: number) {
        this.handNumber = handNumber;
    }
    getPlayersInfo(): Map<any, any> | null {
        return this.playersInfo;
    }

    setPlayersInfo(playersInfo: Map<any, any> | null) {
        this.playersInfo = playersInfo;
    }
}
