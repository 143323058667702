import { Routes } from '@angular/router';

import { PokerPublicPageLoaderComponent } from './poker-public-page-loader.component';

// import { PokerPublicPageModule } from "./poker-public-page.module";

export const pokerTeamPartypokerPublicPageLoaderRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'team-partypoker',
                component: PokerPublicPageLoaderComponent,
                data: {
                    publicPageRoot: 'MobilePoker-v1.0/PublicPages/team-partypoker/',
                },
            },
            {
                path: '**',
                component: PokerPublicPageLoaderComponent,
                data: {
                    publicPageRoot: 'MobilePoker-v1.0/PublicPages/',
                },
            },
        ],
    },
];
