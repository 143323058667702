import { Message } from '../../message';

export class PvtTableTypes extends Message {
    private MESSAGE_NAME: string = 'PvtTableTypes';
    private pvtTablesList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, pvtTablesList: any[] | null = null) {
        super(reqServerPeerId);
        this.pvtTablesList = pvtTablesList;
    }

    getPvtTablesList(): any[] | null {
        return this.pvtTablesList;
    }

    setPvtTablesList(pvtTablesList: any[] | null) {
        this.pvtTablesList = pvtTablesList;
    }
}
