import { Message } from '../../message';

export class ReqEnableRabbit extends Message {
    private MESSAGE_NAME: string = 'ReqEnableRabbit';
    private gameId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, gameId: number = 0) {
        super(reqServerPeerId);
        this.gameId = gameId;
    }

    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
}
