import { Message } from '../../message';
import { Buddy } from './Buddy';

export class BlockBuddy extends Message {
    private MESSAGE_NAME: string = 'BlockBuddy';
    private buddy: Buddy | null = null;
    private block: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, buddy: Buddy | null = null, block: boolean | null = false) {
        super(reqServerPeerId);
        this.buddy = buddy;
        this.block = block;
    }

    getBuddy(): Buddy | null {
        return this.buddy;
    }

    setBuddy(buddy: Buddy | null) {
        this.buddy = buddy;
    }
    getBlock(): boolean | null {
        return this.block;
    }

    setBlock(block: boolean | null) {
        this.block = block;
    }
}
