import { Message } from '../../message';

export class CanLeaveTable extends Message {
    private MESSAGE_NAME: string = 'CanLeaveTable';

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0) {
        super(reqServerPeerId);
    }
}
