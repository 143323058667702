import { Message } from '../../message';

export class PrePromoTicker extends Message {
    private MESSAGE_NAME: string = 'PrePromoTicker';
    private startDate: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, startDate: number = 0) {
        super(reqServerPeerId);
        this.startDate = startDate;
    }

    getStartDate(): number {
        return this.startDate;
    }

    setStartDate(startDate: number) {
        this.startDate = startDate;
    }
}
