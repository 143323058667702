import { Message } from '../../message';

export class LSTourneyRebuyAddonChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyRebuyAddonChange';
    private rebuyCountTillNow: number = 0;
    private addonCountTillNow: number = 0;
    private rebuyAddonStatus: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, rebuyCountTillNow: number = 0, addonCountTillNow: number = 0, rebuyAddonStatus: number = 0) {
        super(reqServerPeerId);
        this.rebuyCountTillNow = rebuyCountTillNow;
        this.addonCountTillNow = addonCountTillNow;
        this.rebuyAddonStatus = rebuyAddonStatus;
    }

    getRebuyCountTillNow(): number {
        return this.rebuyCountTillNow;
    }

    setRebuyCountTillNow(rebuyCountTillNow: number) {
        this.rebuyCountTillNow = rebuyCountTillNow;
    }
    getAddonCountTillNow(): number {
        return this.addonCountTillNow;
    }

    setAddonCountTillNow(addonCountTillNow: number) {
        this.addonCountTillNow = addonCountTillNow;
    }
    getRebuyAddonStatus(): number {
        return this.rebuyAddonStatus;
    }

    setRebuyAddonStatus(rebuyAddonStatus: number) {
        this.rebuyAddonStatus = rebuyAddonStatus;
    }
}
