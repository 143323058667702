import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

let makeLastPotFalse: boolean = false;
export class PostingBlind extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.POSTING_BLINDS;
    }
    getPlayerStatus(): any {
        return PlayerStatus.POSTING_BLINDS;
    }
    next(gameTable: GameTable): void {
        gameTable.presentBettingRound = '0';
        if (gameTable.isAnteCashGameOrTourny === 1) {
            gameTable.currentBettingRound = gameTable.currentBettingRound + 1;
        } else {
            gameTable.currentBettingRound = 1;
        }

        // if (gameTable.isRit) {
        //   const potPosition = Properties.propFile[gameTable.gameType]['RITMAINPOTCHIPPOS'].split('#');
        //   gameTable.playersMap.forEach((player: any, seat: any) => {
        //     gameTable.changeChipPos = new Point(potPosition[0], (potPosition[1]));
        //   });
        // } else {
        const potPosition = Properties.propFile[gameTable.gameType]['MAINPOTCHIPPOS'].split('#');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTable.playersMap.forEach((player: any, seat: any) => {
            player.highlightEvent();
            gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
        });
        // };
        gameTable.mpot = false;
        const mpot = gameTable.moveToPot();
        if (!gameTable.onInit) {
            gameTable.accumulateChip(this.potCreated, true);
        }

        const eventMap = gameTable.eventMap;

        let playerMap = eventMap.get(this.getPlayerStatus());
        if (!playerMap) {
            playerMap = new Map<string, Chip>();
            eventMap.set(this.getPlayerStatus(), playerMap);
        }
        gameTable.players.forEach((p: any) => {
            if (playerMap) {
                playerMap.set(p.name, p.toPot);
            }
        });

        const extraChip = this.getPlayerExtraChips(gameTable, playerMap);
        console.log(extraChip);
        if (gameTable.mainPotFreezed) {
            gameTable.MAIN_POT.totalValue = gameTable.mainPotLockedValue;
        } else {
            gameTable.MAIN_POT.totalValue = gameTable.ROUND_POT.totalValue;
        }
        gameTable.totalAnteAmount = gameTable.MAIN_POT.totalValue;
        if (gameTable.lastLockedPotAmount > 0) {
            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 2].activePotNumber - 1].totalValue =
                gameTable.lastLockedPotAmount;
        }

        gameTable.roundObjects['0'] = gameTable.MAIN_POT.totalValue;
        if (mpot && !gameTable.onInit) {
            gameTable.enableTableMask = true;
            setTimeout(() => {
                gameTable.playersMap.forEach((player: any) => {
                    player.toPot = new Chip(0);
                });
                gameTable.accumulateChip(this.potCreated, false);
                gameTable.enableTableMask = false;
                if (!gameTable.mpot) {
                    gameTable.changeChip = true;
                }
                if (!gameTable.isReplayerV3) {
                    if (gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]) {
                        if (
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].totalLockedPots === 0 &&
                            gameTable.SIDE_POTS.length > 0 &&
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                        ) {
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                        }
                    }
                }
                gameTable.SIDE_POTS.forEach((pot) => {
                    if (pot.totalValue > 0) {
                        if (
                            gameTable.valueUpdatedPotId >= 0 &&
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId] &&
                            gameTable.potCreatedObject.hasOwnProperty(gameTable.currentBettingRound - 1)
                        ) {
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId].totalValue =
                                gameTable.potCreatedObject[gameTable.currentBettingRound - 1];
                        }
                        pot.showPot = true;
                    }
                });
                gameTable.enableTableMask = false;
                if (gameTable.isReplayerV3 && makeLastPotFalse && gameTable.SIDE_POTS.length > 0) {
                    const sidepotValue = gameTable.SIDE_POTS.length;
                    gameTable.SIDE_POTS[sidepotValue - 1].showPot = false;
                }
            }, gameTable.gameSpeedBarSetter);
        } else {
            gameTable.playersMap.forEach((player: any) => {
                player.toPot = new Chip(0);
            });
        }
        let crossCheck: number = 0;
        if (gameTable.isReplayerV3) {
            gameTable.SIDE_POTS.forEach((pot) => {
                crossCheck = crossCheck + pot.totalValue;
            });
            gameTable.existingpotTotalValue = crossCheck;
            gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;
        }
        gameTable.lastLockedPotAmount = 0;
        if (gameTable.isReplayerV3 && gameTable.currentActivePot <= 0 && gameTable.SIDE_POTS.length > 0) {
            // console.log(gameTable.SIDE_POTS.length);
            const sidepotValue = gameTable.SIDE_POTS.length;
            gameTable.currentActivePot = gameTable.SIDE_POTS[sidepotValue - 1].totalValue;
            makeLastPotFalse = true;
        }
    }

    prev(gameTable: GameTable): void {
        gameTable.existingpotTotalValue = 0;
        gameTable.currentBettingRound = gameTable.currentBettingRound - 1;
        gameTable.lastLockedPotAmount = 0;
        this.getPlrReverseExtraChips(gameTable);
        gameTable.mpot = false;

        const currentBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber;
        const prevBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]
            ? gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber
            : 0;

        for (let i = currentBettingRoundActivePotNumber; i > prevBettingRoundActivePotNumber; i--) {
            if (gameTable.SIDE_POTS.length >= i) {
                gameTable.SIDE_POTS[i - 1].showPot = false;
            }
        }

        if (!gameTable.isReplayerV3) {
            if (
                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
                gameTable.SIDE_POTS.length > 0 &&
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
            ) {
                gameTable.lastLockedPotAmount =
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue;
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
            }

            if (gameTable.gameRoundDetails[gameTable.currentBettingRound]) {
                if (
                    gameTable.gameRoundDetails[gameTable.currentBettingRound].totalLockedPots === 0 &&
                    gameTable.SIDE_POTS.length > 0 &&
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                ) {
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber - 1].totalValue =
                        gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                }
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const dis = this;
        gameTable.playersMap.forEach((player: any, seat: any) => {
            const potPosition = Properties.propFile[gameTable.gameType][`CHIPPOS${seat}`].split('#');
            player.playerChipPos = new Point(potPosition[0], potPosition[1]);
            gameTable.revertChip(dis.potCreated);
            const event = gameTable.eventMap;
            const t = event.get(this.getPlayerStatus()) ?? new Map();
            const f = t.get(player.name);
            player.toPot.value = f.value;
        });

        let timer: any;
        timer = setInterval(() => {
            gameTable.prev(false);
            if (gameTable.eventName != 'PLAYERS_ANTE_EVENT') {
                gameTable.prev(false);
            } else {
                clearInterval(timer);
            }
        }, 100);
        if (gameTable.isReplayerV3) {
            gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;
        }
        if (gameTable.isReplayerV3 && gameTable.SIDE_POTS.length > 0 && gameTable.currentActivePot <= 0) {
            if (prevBettingRoundActivePotNumber > 0) {
                gameTable.currentActivePot = gameTable.SIDE_POTS[prevBettingRoundActivePotNumber - 1].totalValue;
                gameTable.SIDE_POTS[prevBettingRoundActivePotNumber - 1].showPot = false;
            } else {
                gameTable.currentActivePot = gameTable.SIDE_POTS[prevBettingRoundActivePotNumber].totalValue;
                gameTable.SIDE_POTS[prevBettingRoundActivePotNumber].showPot = false;
            }
        }
    }
}
