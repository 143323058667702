import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { HeaderService } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { PokerPromotionsSynopsisResourceService } from './poker-promotions-synopsis-resource.service';

@Injectable({ providedIn: 'root' })
export class TournamentSynopsisResolver implements Resolve<any> {
    constructor(
        private resource: PokerPromotionsSynopsisResourceService,
        private headerService: HeaderService,
    ) {}

    resolve(): Observable<any> {
        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('poker');
        });
        return this.resource.getInitSynopsisData('TournamentsHome/home');
    }
}
