import { Message } from '../../message';
import { SNGJPPayoutInfo } from './SNGJPPayoutInfo';

export class SNGJackpotInfo extends Message {
    private MESSAGE_NAME: string = 'SNGJackpotInfo';
    private sngJackpotID: number = 0;
    private name: any | null = null;
    private buyIn: number = 0;
    private tourneyFee: number = 0;
    private gameCurrency: string | null = null;
    private sngJPType: number = 0;
    private gameType: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;
    private maxRegistrations: number = 0;
    private gamePlayType: number = 0;
    private minMultiplier: number = 0;
    private maxMultiplier: number = 0;
    private serverPeerId: string | null = null;
    private sngJPPayoutInfo: SNGJPPayoutInfo | null = null;
    private selectedMultiplier: number = 0;
    private minMultiplierNew: number = 0;
    private maxMultiplierNew: number = 0;
    private tableBackgroundImage: string | null = null;
    private cardType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        name: any | null = null,
        buyIn: number = 0,
        tourneyFee: number = 0,
        gameCurrency: string | null = null,
        sngJPType: number = 0,
        gameType: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
        maxRegistrations: number = 0,
        gamePlayType: number = 0,
        minMultiplier: number = 0,
        maxMultiplier: number = 0,
        serverPeerId: string | null = null,
        sngJPPayoutInfo: SNGJPPayoutInfo | null = null,
        selectedMultiplier: number = 0,
        minMultiplierNew: number = 0,
        maxMultiplierNew: number = 0,
        tableBackgroundImage: string | null = null,
        cardType: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.name = name;
        this.buyIn = buyIn;
        this.tourneyFee = tourneyFee;
        this.gameCurrency = gameCurrency;
        this.sngJPType = sngJPType;
        this.gameType = gameType;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
        this.maxRegistrations = maxRegistrations;
        this.gamePlayType = gamePlayType;
        this.minMultiplier = minMultiplier;
        this.maxMultiplier = maxMultiplier;
        this.serverPeerId = serverPeerId;
        this.sngJPPayoutInfo = sngJPPayoutInfo;
        this.selectedMultiplier = selectedMultiplier;
        this.minMultiplierNew = minMultiplierNew;
        this.maxMultiplierNew = maxMultiplierNew;
        this.tableBackgroundImage = tableBackgroundImage;
        this.cardType = cardType;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getTourneyFee(): number {
        return this.tourneyFee;
    }

    setTourneyFee(tourneyFee: number) {
        this.tourneyFee = tourneyFee;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getSngJPType(): number {
        return this.sngJPType;
    }

    setSngJPType(sngJPType: number) {
        this.sngJPType = sngJPType;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getMaxRegistrations(): number {
        return this.maxRegistrations;
    }

    setMaxRegistrations(maxRegistrations: number) {
        this.maxRegistrations = maxRegistrations;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getMinMultiplier(): number {
        return this.minMultiplier;
    }

    setMinMultiplier(minMultiplier: number) {
        this.minMultiplier = minMultiplier;
    }
    getMaxMultiplier(): number {
        return this.maxMultiplier;
    }

    setMaxMultiplier(maxMultiplier: number) {
        this.maxMultiplier = maxMultiplier;
    }
    getServerPeerId(): string | null {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: string | null) {
        this.serverPeerId = serverPeerId;
    }
    getSngJPPayoutInfo(): SNGJPPayoutInfo | null {
        return this.sngJPPayoutInfo;
    }

    setSngJPPayoutInfo(sngJPPayoutInfo: SNGJPPayoutInfo | null) {
        this.sngJPPayoutInfo = sngJPPayoutInfo;
    }
    getSelectedMultiplier(): number {
        return this.selectedMultiplier;
    }

    setSelectedMultiplier(selectedMultiplier: number) {
        this.selectedMultiplier = selectedMultiplier;
    }
    getMinMultiplierNew(): number {
        return this.minMultiplierNew;
    }

    setMinMultiplierNew(minMultiplierNew: number) {
        this.minMultiplierNew = minMultiplierNew;
    }
    getMaxMultiplierNew(): number {
        return this.maxMultiplierNew;
    }

    setMaxMultiplierNew(maxMultiplierNew: number) {
        this.maxMultiplierNew = maxMultiplierNew;
    }
    getTableBackgroundImage(): string | null {
        return this.tableBackgroundImage;
    }

    setTableBackgroundImage(tableBackgroundImage: string | null) {
        this.tableBackgroundImage = tableBackgroundImage;
    }
    getCardType(): number {
        return this.cardType;
    }

    setCardType(cardType: number) {
        this.cardType = cardType;
    }
}
