import { Message } from '../../message';

export class ResponseChangeEmailSuccess extends Message {
    private MESSAGE_NAME: string = 'ResponseChangeEmailSuccess';
    private msg: any | null = null;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, msg: any | null = null, conversationId: number = 0) {
        super(reqServerPeerId);
        this.msg = msg;
        this.conversationId = conversationId;
    }

    getMsg(): any | null {
        return this.msg;
    }

    setMsg(msg: any | null) {
        this.msg = msg;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
