{
    "gameTypes": [
        {
            "title": "PARTY_POKER_GC_QSLOBBY_FAVSTTALL",
            "value": "All"
        },
        {
            "title": "PARTY_POKER_GC_COMMON_HOLDEM",
            "value": "Hold’em"
        },
        {
            "title": "PARTY_POKER_GS_TABCONFIG_Omaha",
            "value": "Omaha"
        }
    ],
    "limit": [
        {
            "title": "PARTY_POKER_GC_QSLOBBY_FAVSTTALL",
            "value": "All"
        },
        {
            "title": "PARTY_POKER_GC_BUYINCHIPS_FIXEDLIMIT",
            "value": "Fixed Limit"
        },
        {
            "title": "PARTY_POKER_GC_BUYINCHIPS_NOLIMIT",
            "value": "No Limit"
        },
        {
            "title": "PARTY_POKER_GC_BUYINCHIPS_POTLIMIT",
            "value": "Pot Limit"
        }
    ],
    "variant": [
        {
            "title": "PARTY_POKER_GC_QSLOBBY_FAVSTTALL",
            "value": "All"
        },
        {
            "title": "PARTY_POKER_GC_GAMELOBBY_GAMEINFOLISTHI",
            "value": "Hi"
        },
        {
            "title": "PARTY_POKER_GC_GAMELOBBY_GAMEINFOLISTHL",
            "value": "Hi-Lo"
        }
    ],
    "cashGameType": [
        {
            "title": "PARTY_POKER_GC_QSLOBBY_FAVSTTALL",
            "value": "All"
        },
        {
            "title": "PARTY_POKER_GC_QSLOBBY_ANTE",
            "value": "Ante"
        },
        {
            "title": "PARTY_POKER_GS_TABCONFIG_RealName",
            "value": "Real Name"
        },
        {
            "title": "PARTY_POKER_GC_ONECLICK_FEATURED",
            "value": "Featured"
        },
        {
            "title": "PARTY_POKER_GS_TABCONFIG_Regular",
            "value": "Regular"
        }
    ],
    "stakeRange": [
        {
            "title": "PARTY_POKER_GC_QSLOBBY_FAVSTTALL",
            "value": "All"
        },
        {
            "title": "High Roller",
            "extraInformation": "($1250+)",
            "value": "High Roller"
        },
        {
            "title": "PARTY_POKER_GS_TABCONFIG_High",
            "extraInformation": "($250-$1000)",
            "value": "High"
        },
        {
            "title": "PARTY_POKER_GS_TABCONFIG_Medium",
            "extraInformation": "($50-$200)",
            "value": "Medium"
        },
        {
            "title": "PARTY_POKER_GS_RankNameLow",
            "extraInformation": "($12.50-$50)",
            "value": "Low"
        },
        {
            "title": "PARTY_POKER_GS_TABCONFIG_Micro",
            "extraInformation": "($10 and Under)",
            "value": "Micro"
        }
    ],
    "tableSize": [
        {
            "title": "PARTY_POKER_GC_QSLOBBY_FAVSTTALL",
            "value": "All"
        },
        {
            "title": "7-8 (Full Ring)",
            "value": "7-8 (Full Ring)"
        },
        {
            "title": "5-6 (Short Handed)",
            "value": "5-6 (Short Handed)"
        },
        {
            "title": "2 (Heads Up)",
            "value": "2 (Heads Up)"
        }
    ],
    "allFilters": [
        {
            "title": "Game Type",
            "path": "gameTypes",
            "displayValue": "Game Type",
            "maxFilterLength": 3,
            "filterData": [],
            "allFilterTypes": ["Omaha", "Hold’em"]
        },
        {
            "title": "Limit",
            "path": "limit",
            "displayValue": "Limit",
            "maxFilterLength": 3,
            "filterData": [],
            "allFilterTypes": ["No Limit", "Pot Limit", "Fixed Limit"]
        },
        {
            "title": "Omaha variant",
            "path": "variant",
            "displayValue": "Omaha variant",
            "maxFilterLength": 2,
            "filterData": [],
            "allFilterTypes": ["Hi", "Hi-Lo"]
        },
        {
            "title": "Cash Game Type",
            "path": "cashGameType",
            "displayValue": "Cash Game Type",
            "maxFilterLength": 4,
            "filterData": [],
            "allFilterTypes": ["Ante", "Real Name", "Regular", "Featured"]
        },
        {
            "title": "Stake Range",
            "path": "stakeRange",
            "displayValue": "Stack Range",
            "maxFilterLength": 5,
            "filterData": [],
            "allFilterTypes": ["High Roller", "High", "Medium", "Low", "Micro"]
        },
        {
            "title": "Table Size",
            "path": "tableSize",
            "displayValue": "Table Size",
            "maxFilterLength": 3,
            "filterData": [],
            "allFilterTypes": ["7-8 (Full Ring)", "5-6 (Short Handed)", "2 (Heads Up)"]
        }
    ]
}
