import { Message } from '../../message';

export class MTTPlayerAutoRebuyAddonPreferences extends Message {
    private MESSAGE_NAME: string = 'MTTPlayerAutoRebuyAddonPreferences';
    private autoRebuyOption: number = 0;
    private autoAddonOption: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, autoRebuyOption: number = 0, autoAddonOption: number = 0) {
        super(reqServerPeerId);
        this.autoRebuyOption = autoRebuyOption;
        this.autoAddonOption = autoAddonOption;
    }

    getAutoRebuyOption(): number {
        return this.autoRebuyOption;
    }

    setAutoRebuyOption(autoRebuyOption: number) {
        this.autoRebuyOption = autoRebuyOption;
    }
    getAutoAddonOption(): number {
        return this.autoAddonOption;
    }

    setAutoAddonOption(autoAddonOption: number) {
        this.autoAddonOption = autoAddonOption;
    }
}
