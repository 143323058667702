import { Message } from '../../message';

export class FavoriteCards extends Message {
    private MESSAGE_NAME: string = 'FavoriteCards';
    private favorites: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, favorites: any[] | null = null) {
        super(reqServerPeerId);
        this.favorites = favorites;
    }

    getFavorites(): any[] | null {
        return this.favorites;
    }

    setFavorites(favorites: any[] | null) {
        this.favorites = favorites;
    }
}
