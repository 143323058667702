import { Message } from '../../message';

export class BroadcastShowRabbit extends Message {
    private MESSAGE_NAME: string = 'BroadcastShowRabbit';
    private seatNo: number = 0;
    private gameId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatNo: number = 0, gameId: number = 0) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.gameId = gameId;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
}
