import { Pipe, PipeTransform } from '@angular/core';

import { currencyConverterMap } from './currency-map';

@Pipe({ name: 'formatCurrencyPipe', standalone: true })
export class formatCurrencyPipe extends currencyConverterMap implements PipeTransform {
    transform(unit: number, symbol: string) {
        return (
            this.currencySymbolMap(symbol) +
            ((unit / 100) % 1 === 0
                ? new Intl.NumberFormat().format(unit / 100)
                : new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(unit / 100))
        );
    }
}
