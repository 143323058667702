import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

@Component({
    selector: 'homepage-software-updatesection',
    templateUrl: 'homepage-software-updatesection.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class HomePageSoftwareUpdateSectionComponent implements OnInit {
    softwareUpdatesContent: any;
    softwareupdatesEnabled: boolean;

    constructor(private homePageService: HomepageResourceService) {}
    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            if (allData[i].metadata.id.itemName.toLowerCase() === 'softwareupdates') {
                this.softwareUpdatesContent = allData[i];
                this.softwareupdatesEnabled = this.homePageService.setContentFromID(allData[i].metadata.id.itemName.toLowerCase());
                break;
            }
        }
    }
}
