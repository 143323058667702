import { Message } from '../../message';

export class FXRateData extends Message {
    private MESSAGE_NAME: string = 'FXRateData';
    private fromCurrencyCode: string | null = null;
    private toCurrencyCode: string | null = null;
    private factors: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        fromCurrencyCode: string | null = null,
        toCurrencyCode: string | null = null,
        factors: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.fromCurrencyCode = fromCurrencyCode;
        this.toCurrencyCode = toCurrencyCode;
        this.factors = factors;
    }

    getFromCurrencyCode(): string | null {
        return this.fromCurrencyCode;
    }

    setFromCurrencyCode(fromCurrencyCode: string | null) {
        this.fromCurrencyCode = fromCurrencyCode;
    }
    getToCurrencyCode(): string | null {
        return this.toCurrencyCode;
    }

    setToCurrencyCode(toCurrencyCode: string | null) {
        this.toCurrencyCode = toCurrencyCode;
    }
    getFactors(): any[] | null {
        return this.factors;
    }

    setFactors(factors: any[] | null) {
        this.factors = factors;
    }
}
