import { Message } from '../../message';

export class LSTourneyStartTimeChange extends Message {
    private MESSAGE_NAME: string = 'LSTourneyStartTimeChange';
    private tourneyStartTime: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tourneyStartTime: any | null = null) {
        super(reqServerPeerId);
        this.tourneyStartTime = tourneyStartTime;
    }

    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
}
