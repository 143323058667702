import { Message } from '../../message';

export class ResponsePeerConnectivityStatus extends Message {
    private MESSAGE_NAME: string = 'ResponsePeerConnectivityStatus';
    private peerConnStatus: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, peerConnStatus: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.peerConnStatus = peerConnStatus;
    }

    getPeerConnStatus(): Map<any, any> | null {
        return this.peerConnStatus;
    }

    setPeerConnStatus(peerConnStatus: Map<any, any> | null) {
        this.peerConnStatus = peerConnStatus;
    }
}
