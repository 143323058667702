import { Message } from '../../message';

export class OneClickTourneyLateRegistrationChange extends Message {
    private MESSAGE_NAME: string = 'OneClickTourneyLateRegistrationChange';
    private cardId: number = 0;
    private lateRegInProgress: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardId: number = 0, lateRegInProgress: boolean | null = false) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.lateRegInProgress = lateRegInProgress;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getLateRegInProgress(): boolean | null {
        return this.lateRegInProgress;
    }

    setLateRegInProgress(lateRegInProgress: boolean | null) {
        this.lateRegInProgress = lateRegInProgress;
    }
}
