import { Message } from '../../message';

export class GlobalWaitlistCriteria extends Message {
    private MESSAGE_NAME: string = 'GlobalWaitlistCriteria';
    private minPlayers: number = 0;
    private maxSeats: number = 0;
    private dpAny: number = 0;
    private tableTypeId: number = 0;
    private gameTypeId: number = 0;
    private stakesLowerLimit: number = 0;
    private stakesUpperLimit: number = 0;
    private tableLimitType: number = 0;
    private gamePlayType: number = 0;
    private isBeginner: boolean | null = false;
    private includeAnteCashGames: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        minPlayers: number = 0,
        maxSeats: number = 0,
        dpAny: number = 0,
        tableTypeId: number = 0,
        gameTypeId: number = 0,
        stakesLowerLimit: number = 0,
        stakesUpperLimit: number = 0,
        tableLimitType: number = 0,
        gamePlayType: number = 0,
        isBeginner: boolean | null = false,
        includeAnteCashGames: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.minPlayers = minPlayers;
        this.maxSeats = maxSeats;
        this.dpAny = dpAny;
        this.tableTypeId = tableTypeId;
        this.gameTypeId = gameTypeId;
        this.stakesLowerLimit = stakesLowerLimit;
        this.stakesUpperLimit = stakesUpperLimit;
        this.tableLimitType = tableLimitType;
        this.gamePlayType = gamePlayType;
        this.isBeginner = isBeginner;
        this.includeAnteCashGames = includeAnteCashGames;
    }

    getMinPlayers(): number {
        return this.minPlayers;
    }

    setMinPlayers(minPlayers: number) {
        this.minPlayers = minPlayers;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getDpAny(): number {
        return this.dpAny;
    }

    setDpAny(dpAny: number) {
        this.dpAny = dpAny;
    }
    getTableTypeId(): number {
        return this.tableTypeId;
    }

    setTableTypeId(tableTypeId: number) {
        this.tableTypeId = tableTypeId;
    }
    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getStakesLowerLimit(): number {
        return this.stakesLowerLimit;
    }

    setStakesLowerLimit(stakesLowerLimit: number) {
        this.stakesLowerLimit = stakesLowerLimit;
    }
    getStakesUpperLimit(): number {
        return this.stakesUpperLimit;
    }

    setStakesUpperLimit(stakesUpperLimit: number) {
        this.stakesUpperLimit = stakesUpperLimit;
    }
    getTableLimitType(): number {
        return this.tableLimitType;
    }

    setTableLimitType(tableLimitType: number) {
        this.tableLimitType = tableLimitType;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getIsBeginner(): boolean | null {
        return this.isBeginner;
    }

    setIsBeginner(isBeginner: boolean | null) {
        this.isBeginner = isBeginner;
    }
    getIncludeAnteCashGames(): boolean | null {
        return this.includeAnteCashGames;
    }

    setIncludeAnteCashGames(includeAnteCashGames: boolean | null) {
        this.includeAnteCashGames = includeAnteCashGames;
    }
}
