import { Card } from '../card';
import { GameTable } from '../game-table';
import { Player } from '../player';
import { TableEvent } from '../table-event';
import { TableEvents } from '../table-events.enum';

export abstract class TableEventInfo implements TableEvent {
    private _seat: number;
    private _card: Card;
    private _amount: number;
    private _potId: number;
    private _potCreated: number;

    get seat(): number {
        return this._seat;
    }

    set seat(value: number) {
        this._seat = value;
    }

    get card(): Card {
        return this._card;
    }

    set card(value: Card) {
        this._card = value;
    }

    get amount(): number {
        return this._amount;
    }

    set amount(value: number) {
        this._amount = value;
    }

    get potId(): number {
        return this._potId;
    }

    set potId(value: number) {
        this._potId = value;
    }

    get potCreated(): any {
        return this._potCreated;
    }

    set potCreated(value: any) {
        this._potCreated = value;
    }

    abstract getEventType(): TableEvents;

    abstract next(gameTable: GameTable): void;

    abstract prev(gameTable: GameTable): void;

    protected getPlayerExtraChips(gameTable: GameTable, playerMap: any) {
        gameTable.players.forEach((p: Player) => {
            playerMap.set(p.name, p.toPot);
        });

        const playerPotContribution: any = [];
        gameTable.players.forEach((p) => {
            if (p.toPot.value > 0) {
                playerPotContribution.push(p);
            }
        });

        const betDone = playerPotContribution.length > 0;

        if (betDone) {
            let extraChip = 0;
            playerPotContribution.sort((p1: Player, p2: Player) => {
                return p1.toPot.value - p2.toPot.value;
            });

            let extraChipPlayer: Player | null = null;
            if (playerPotContribution.length > 1) {
                const lp: Player = playerPotContribution[playerPotContribution.length - 1];
                const nlp: Player = playerPotContribution[playerPotContribution.length - 2];

                if (lp.toPot.value - nlp.toPot.value !== 0) {
                    extraChip = lp.toPot.value - nlp.toPot.value;
                    extraChipPlayer = lp;
                }
            } else if (playerPotContribution.length === 1) {
                const lp: Player = playerPotContribution[playerPotContribution.length - 1];
                extraChipPlayer = lp;
                extraChip = lp.toPot.value;
            }

            if (extraChipPlayer) {
                gameTable.playerVsExtraChip.set(extraChipPlayer.seatNo, extraChip);
                gameTable.roundVsExtraChips.push(this.getEventType());
                gameTable.roundVsPlayerExtraChips.set(this.getEventType(), extraChipPlayer.seatNo);
            }
        }

        let uncalledBet = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTable.playerVsExtraChip.forEach((v, k) => {
            uncalledBet += Number(v);
        });
        return uncalledBet;
    }
    protected getPlrReverseExtraChips(gameTable: GameTable) {
        // let uncalledBet = 0
        // if (gameTable.roundVsExtraChips.indexOf(this.getEventType()) !== -1) {
        // gameTable.playerVsExtraChip.forEach((v, k) => {
        //   uncalledBet += Number(v);
        // });

        //   return uncalledBet;
        // } else {
        //   gameTable.playerVsExtraChip.clear();
        //   gameTable.roundVsExtraChips = [];
        // }

        let uncalledBet = 0;
        if (gameTable.roundVsExtraChips.indexOf(this.getEventType()) >= 0) {
            const rRounds = new Array<number>();
            gameTable.roundVsExtraChips.forEach((r) => {
                if (r === this.getEventType()) {
                    const plr = gameTable.roundVsPlayerExtraChips.get(r);
                    gameTable.playerVsExtraChip.set(plr, 0);
                } else {
                    rRounds.push(r);
                }
            });

            gameTable.roundVsExtraChips = rRounds;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTable.playerVsExtraChip.forEach((v, k) => {
            uncalledBet += Number(v);
        });

        return uncalledBet;
    }
}
