import { Message } from '../../message';
import { BlockSeatResponse } from './BlockSeatResponse';

export class RatHoleResponse extends Message {
    private MESSAGE_NAME: string = 'RatHoleResponse';
    private minAmount: number = 0;
    private blockSeatResponse: BlockSeatResponse | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, minAmount: number = 0, blockSeatResponse: BlockSeatResponse | null = null) {
        super(reqServerPeerId);
        this.minAmount = minAmount;
        this.blockSeatResponse = blockSeatResponse;
    }

    getMinAmount(): number {
        return this.minAmount;
    }

    setMinAmount(minAmount: number) {
        this.minAmount = minAmount;
    }
    getBlockSeatResponse(): BlockSeatResponse | null {
        return this.blockSeatResponse;
    }

    setBlockSeatResponse(blockSeatResponse: BlockSeatResponse | null) {
        this.blockSeatResponse = blockSeatResponse;
    }
}
