import { Message } from '../../message';

export class ArjelGameCards extends Message {
    private MESSAGE_NAME: string = 'ArjelGameCards';
    private date: number = 0;
    private cardsDealt: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, date: number = 0, cardsDealt: string | null = null) {
        super(reqServerPeerId);
        this.date = date;
        this.cardsDealt = cardsDealt;
    }

    getDate(): number {
        return this.date;
    }

    setDate(date: number) {
        this.date = date;
    }
    getCardsDealt(): string | null {
        return this.cardsDealt;
    }

    setCardsDealt(cardsDealt: string | null) {
        this.cardsDealt = cardsDealt;
    }
}
