<div class="poker-product">
    <div class="teasers-crousale teasers" *ngIf="teasersResponse.length">
        <div [ngClass]="TeaserMode" class="md-teaser-section hero-teaser-new small-teaser">
            <vn-swiper [swiperOptions]="swiperOptions" [class]="{ 'hide-teaser-pagination': teasersResponse.length === 1 }">
                <div *ngFor="let teaser of teasersResponse; let i = index" class="swiper-slide bottom-terms">
                    <div (click)="ctaClick($event, teaser, i + 1)" [ngClass]="assignBrowserSpecificClass()" style="width: 100%">
                        <div [ngClass]="teasersMainClass">
                            <div class="item-slide" class="{{ teaser.attributes.teaserAlignments }}" [ngClass]="{ 'show-terms': teaser.termsText }">
                                <div [ngClass]="{ 'bottom-title': teaser.attributes.bottomTitle === true }">
                                    <div class="md-teaser-body">
                                        <div
                                            class="md-teaser-image"
                                            *ngIf="teaser.backgroundImage"
                                            [ngStyle]="{ 'background-image': 'url(' + teaser.backgroundImage.src + ')' }"></div>
                                        <div class="md-teaser-text">
                                            <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                            <div class="md-teaser-inner-text">
                                                <div
                                                    class="title"
                                                    [ngStyle]="{ color: teaser.attributes.titleColor }"
                                                    [innerHtml]="teaser.title | trustAsHtml"></div>
                                                <div
                                                    class="title"
                                                    [ngStyle]="{ color: teaser.attributes.titleColor }"
                                                    [innerHtml]="teaser.additionalTitle | trustAsHtml"></div>
                                                <div
                                                    class="subtitle"
                                                    [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                    [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                                <div class="md-teaser-cta">
                                                    <a
                                                        *ngIf="teaser.ctaLink && teaser.ctaTitle"
                                                        [href]="teaser.ctaLink.url"
                                                        target="{{ teaser.ctaLink.attributes.target }}"
                                                        class="btn btn-primary"
                                                        [ngStyle]="{ color: teaser.attributes.ctaBgColor }"
                                                        [innerHtml]="teaser.ctaTitle | trustAsHtml"></a>
                                                </div>
                                            </div>
                                            <div
                                                class="small-terms"
                                                [innerHtml]="teaser.termsText | trustAsHtml"
                                                [ngStyle]="{ color: teaser.attributes.termsColor }"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vn-swiper>
        </div>
    </div>
</div>
