import { Message } from '../../message';

export class UncalledBetReturn extends Message {
    private MESSAGE_NAME: string = 'UncalledBetReturn';
    private playerId: string | null = null;
    private uncalledBetAmount: number = 0;
    private potNo: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, playerId: string | null = null, uncalledBetAmount: number = 0, potNo: number = 0) {
        super(reqServerPeerId);
        this.playerId = playerId;
        this.uncalledBetAmount = uncalledBetAmount;
        this.potNo = potNo;
    }

    getPlayerId(): string | null {
        return this.playerId;
    }

    setPlayerId(playerId: string | null) {
        this.playerId = playerId;
    }
    getUncalledBetAmount(): number {
        return this.uncalledBetAmount;
    }

    setUncalledBetAmount(uncalledBetAmount: number) {
        this.uncalledBetAmount = uncalledBetAmount;
    }
    getPotNo(): number {
        return this.potNo;
    }

    setPotNo(potNo: number) {
        this.potNo = potNo;
    }
}
