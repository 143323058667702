import { Message } from '../../message';

export class LSDeletedPoolInfoDetails extends Message {
    private MESSAGE_NAME: string = 'LSDeletedPoolInfoDetails';
    private poolId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, poolId: number = 0) {
        super(reqServerPeerId);
        this.poolId = poolId;
    }

    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
}
