import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NavigationService, Page } from '@frontend/vanilla/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { CashErrorDialogModel } from '../../models/cash-error-dialog.model';

@Component({
    selector: 'pwc-reconnection-dialog',
    templateUrl: 'pwc-reconnection-dialog.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PWCReconnectionDialogComponent implements OnInit {
    private readonly logger: PokerLogger;
    btnText1: string;
    btnText2: string;

    constructor(
        loggerFactory: LoggerFactory,
        private navigation: NavigationService,
        private page: Page,
        private dialogRef: MatDialogRef<PWCReconnectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CashErrorDialogModel,
    ) {
        this.logger = loggerFactory.getLogger('pwcerrordialogLoggingComponent');
    }
    ngOnInit() {
        this.btnText1 = 'Yes'; //this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK').toUpperCase();
        this.btnText2 = 'No';
    }

    buttonYes() {
        this.navigation.goTo('/play', { forceReload: true, appendReferrer: false });
        this.closePopup(true);
    }
    buttonNo() {
        this.navigation.goTo(this.page.homePage);
        this.closePopup(false);
    }
    closePopup(showReconnPopup: boolean) {
        this.logger.info('clicked on close popup icon..!');
        this.dialogRef.close(showReconnPopup);
    }
}
