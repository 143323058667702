import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NavigationService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import Swiper from 'swiper';
import { Autoplay, EffectCoverflow, Pagination, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { ATHPopupComponent } from '../../common/ath-popup/ath-popup.component';
import { CashFFBuyInPopUpComponent } from '../../common/cash-ff-buyin/cash-ff-buyIn.component';
import { CashLobbyTileComponent } from '../../common/cash-lobby-tile/cash-lobby-tile.component';
import { DeBalancePopUpComponent } from '../../common/de-table-balance/de-balance-pop-up.component';
import { PWCErrorDialogComponent } from '../../common/error-dialog/pwc-error-dialog.component';
import { CgFiltersEventBusService } from '../../common/lobby-filters/services/cg-filters-event-bus.service';
import { LobbyTileInfoComponent } from '../../common/lobby-tile-info/lobby-tile-info.component';
import { SubLobbyHeaderComponent } from '../../common/sub-lobby-header/sub-lobby-header.component';
import { CardInfo } from '../../models/tile-card-info.model';
import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { PokerWebClientService } from '../../poker-web-client.service';
import { FxRateHelperClassService } from '../../service/Fxratemanager';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
// import { EventDispatcherGlobal } from '../../service/event-despacher-global';
// import { EventDispatcherGlobal } from '../../service/event-despacher-global';
// import { GameTableNotificationService } from '../../service/game-table-notification.service';
import { PokerWebClientLoginService } from '../../service/poker-web-client-login.service';
import { GameTableSettingsSelect } from '../../store/game-table-settings.store';
import { AthBlockTime, AthHandCount, cashLobbyDataSelect } from '../../store/lobby.store';
import { miniTablesSelect } from '../../store/mini-table.store';
import { dynaconConfigsSelect, siteCoreLiteralsSelect } from '../../store/siteCore-Dynacon.store';
import { userBalanceSelect, userIsAuthenticatedSelect, userProfileSelect, userSSOSelect } from '../../store/user.store';
import { CashGameService } from './cash-lobby.service';

@Component({
    selector: 'pwc-cash-lobby',
    templateUrl: 'cash-lobby.component.html',
    imports: [
        CommonModule,
        SubLobbyHeaderComponent,
        CashLobbyTileComponent,
        LobbyTileInfoComponent,
        CashFFBuyInPopUpComponent,
        TranslatePipe,
        SwiperComponent,
        formatCurrencyPipe,
        DeBalancePopUpComponent,
        ATHPopupComponent,
        PWCErrorDialogComponent,
    ],
    providers: [CashGameService, formatCurrencyPipe, TranslatePipe],
    standalone: true,
})
export class CashLobbyComponent implements OnInit {
    storeData$: Observable<any>;

    peerId: number = 0;
    public gridView = true;
    public cardOrderBy = 'asc';
    public selectedTableIndex = 0;
    public isAutoreBuyin = false;
    public selectedTable: any;
    tileLeastBuyinValue: number;
    tileMaxBuyinValue: number;
    totalBuyIn: number;
    currentLobbyInfo: any;
    finalData = [] as any;
    public gameTypes = {
        'All': false,
        'Hold’em': false,
        'Omaha': false,
    };

    isDePopupEnabled = false;
    deBalanceInfo;
    isLoggedIn: boolean;
    thumbsSwiper: any;
    popupIsOpen = false;
    athBlockTime: number = 0;
    private readonly logger: PokerLogger;
    public lobbySwaiperConfig: SwiperOptions = {
        modules: [Pagination, EffectCoverflow, Autoplay, Virtual],
        direction: 'horizontal',
        effect: 'coverflow',
        centeredSlides: true,
        spaceBetween: 50,
        slidesPerView: 1.9,
        loop: true,
        navigation: true,
        autoplay: false,
        virtual: {
            addSlidesBefore: 2,
            addSlidesAfter: 5,
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: true,
        },
        pagination: {
            el: '.swiper-pagination-cust',
            dynamicBullets: true,
            dynamicMainBullets: 6,
            clickable: true,
        },
        keyboard: true,
        rewind: true,
        observer: true,
        on: {
            click: (swiper: Swiper, event: any) => {
                this.onSlideClick(swiper, event);
            },
        },
    };
    isSwiperInit: boolean = false;
    @ViewChild('SwiperComponent', { static: false })
    componentRef?: SwiperComponent;
    isATHEnabled: boolean;
    // private eventDispatcherGlobal = new EventDispatcherGlobal();
    athHandCount: any;
    // logger: PokerLogger;
    athalertpopup = false;
    athpenalitypopup = false;
    public userBalance = 0;
    @ViewChild('gridContainer', { read: ElementRef }) gridContainer: ElementRef;

    constructor(
        private dialog: MatDialog,
        // private userService: UserService,
        // private loginDialogService: LoginDialogService,
        // private location: Location,
        private navigation: NavigationService,
        private shareService: PokerWebClientSharedService,
        public cashGameService: CashGameService,
        loggerFactory: LoggerFactory,
        private store: Store,
        private eventBus: CgFiltersEventBusService,
        private clientToServer: ClientToServerRequestServices,
        private formatCurrencyPipe: formatCurrencyPipe,
        private translatePipe: TranslatePipe,
        private pokerWebClientLoginService: PokerWebClientLoginService,
        private pokerWebClientService: PokerWebClientService,
    ) {
        // this.clientToServer.updatePageTitle("cash games");

        this.clientToServer.SendLobbyUpdateRequest(true, 0, 'CASH');
        this.logger = loggerFactory.getLogger('CashLobbyLoggingComponent');
        this.getStoreValues();
        this.getLobbyData();
        this.showInitialGameType(JSON.parse(localStorage.getItem('currentFiltersData') ?? '{}'));
        this.isATHEnabled = this.clientToServer.dynaconConfigs['webClientConfigs']['enableATH'];
    }

    ngOnInit(): void {
        this.store.select(userBalanceSelect).subscribe((data) => {
            if (!_.isEmpty(data) && !_.isEmpty(data['netRealBalance'])) {
                let conversionFactor4balance = FxRateHelperClassService.getInstance().getFxRateWithOutSnapShotId(
                    data['netRealBalance']['accountCurrency'],
                    'USD',
                    'cashgames',
                );
                this.userBalance =
                    data['netRealBalance']['accountBalance'] * conversionFactor4balance +
                    (data['tourneyCurrencyBalance'] != null ? data['tourneyCurrencyBalance']['tourneyCurrencyBalance'] : 0);
            }
        });
        this.store.select(AthHandCount).subscribe((data) => {
            this.athHandCount = data;
        });
        // this.eventDispatcherGlobal.addEventListener('showATHStrikeHandCountConfigPopUp', this.showATHStrikeHandCountConfigPopUp.bind(this));
        const currentURL = window.location.pathname;
        this.currentLobbyInfo = _.find(this.shareService.lobbyPromotions.lobbyPromotions.lobbyPromotions, (obj) => {
            return currentURL.includes(obj.gameType);
        });
        // this.translatePipe.transform("asd");
        this.shareService.setPageHeaderTitle({ title: this.currentLobbyInfo.title, url: '/play', showBalanceBlock: true });
        this.store.select(AthBlockTime).subscribe((data) => {
            this.athBlockTime = data;
        });
        this.deBalanceInfo = this.pokerWebClientService.getTableBalanceLimit();
        this.getDeTableBalancePopUp();
    }

    getDeTableBalancePopUp() {
        // if (this.appConfig.ENABLE_TABLE_BALANCE_POPUP) { (this.AppDataModel.getTableBalanceLimit()!=0)

        // }
        if (localStorage.getItem('DO_NOT_SHOW_BALANCE_REDUCTION_POPUP') != '1' && this.pokerWebClientService.getTableBalanceLimit() != 0) {
            const resetobj = {
                detail: {
                    balanceLimit: 0,
                },
            };
            this.isDePopupEnabled = true;
            this.pokerWebClientService.setTableBalanceLimit(resetobj);
        } else {
            this.showATHStrikeHandCountConfigPopUp();
        }
    }
    // swiper = new Swiper(".mySwiper", {
    //     pagination: {
    //         el: ".swiper-pagination",
    //         clickable: true,
    //         renderBullet: function (index, className) {
    //             return (
    //                 '<span class="' + className + '">' + (index + 1) + "</span>"
    //             );
    //         },
    //     },
    // });

    checkSwiper(swiper: any) {
        if (swiper.initialized) {
            this.isSwiperInit = true;
        }
    }
    isItGridViewType(e: boolean) {
        this.gridView = e;
        this.logger.info('clicked on Grid Icon..!' + JSON.stringify(e));
        if (!this.gridView) {
            this.setSwiperBuyinValues(this.finalData);
        }
        setTimeout(() => {
            if (this.componentRef) {
                if (!this.gridView) {
                    this.componentRef.swiper.slideToLoop(this.selectedTableIndex);
                }
                this.componentRef.swiper.on(
                    'slideChange',
                    function (swiperRef) {
                        this.selectedCard(swiperRef.realIndex, this.finalData[swiperRef.realIndex]);
                    }.bind(this),
                );
            }
        }, 200);
        if (this.gridView) {
            setTimeout(() => {
                const selectedElement = this.gridContainer.nativeElement.querySelector(`.card-${this.selectedTableIndex}`);
                const gridViewContainer = selectedElement.closest('.sub-lobby-content');
                if (selectedElement) {
                    gridViewContainer.scroll({
                        behavior: 'smooth',
                        top: selectedElement.offsetTop - gridViewContainer.offsetTop,
                    });
                }
            }, 100);
        }
    }

    onSlideClick(swiper: Swiper, event: any) {
        let clickPosition: any;
        let clickedSlideNext: any = document.querySelector<HTMLDivElement>('.swiper-slide-next');
        let clickedSlidePrev: any = document.querySelector<HTMLDivElement>('.swiper-slide-prev');

        if (event instanceof MouseEvent) {
            clickPosition = swiper.clickedSlide?.dataset.swiperSlideIndex;
        } else if (event instanceof TouchEvent && swiper.clickedIndex >= 0) {
            clickPosition = swiper.clickedSlide?.dataset.swiperSlideIndex;
        } else {
            console.error('Event type not supported for determining click position');
            return;
        }
        const pervSelectNumber: any = clickedSlidePrev?.dataset.swiperSlideIndex;
        const nextSelectNumber: any = clickedSlideNext?.dataset.swiperSlideIndex;

        clickPosition = parseInt(clickPosition);
        if (clickPosition === parseInt(pervSelectNumber)) {
            swiper.slidePrev();
        } else if (clickPosition === parseInt(nextSelectNumber)) {
            swiper.slideNext();
        }
    }

    showATHStrikeHandCountConfigPopUp() {
        if (this.athHandCount > 0 && this.isATHEnabled && localStorage.getItem('isATHAlertShown') != 'true') {
            this.athalertpopup = true;
        }
    }

    getLobbyData() {
        this.store.select(cashLobbyDataSelect).subscribe((data) => {
            if (data.length != 0) {
                this.finalData = this.eventBus.filterCashGamesCards(data, true);
                if (this.finalData?.length <= 10 && this.lobbySwaiperConfig) {
                    this.lobbySwaiperConfig.pagination = {
                        el: '.swiper-pagination-cust',
                        dynamicBullets: true,
                        clickable: true,
                    };
                    this.lobbySwaiperConfig.virtual = false;
                }
                this.logger.info(' cardsData in cash lobby ' + JSON.stringify(this.finalData));
                this.selectedCard(0, this.finalData[this.selectedTableIndex]);
            } else {
                // this.clientToServer.requestForLobbyData(true,0,'CASH');
                this.finalData = [];
                this.logger.info(' cardsData in cash lobby ' + JSON.stringify(this.finalData));
            }
            this.finalData = _.sortBy(this.finalData, [(obj: any) => obj.formattedBuyIn]);
        });
    }

    updateGameType(data) {
        const filterData = JSON.parse(localStorage.getItem('currentFiltersData') ?? '');
        filterData.gameTypes = data;
        localStorage.setItem('currentFiltersData', JSON.stringify(filterData));
        this.getLobbyData();
    }

    showInitialGameType(cgFilter) {
        if (cgFilter.gameTypes.length == 2) {
            this.gameTypes['All'] = true;
            this.gameTypes['Omaha'] = true;
            this.gameTypes['Hold’em'] = true;
        }

        if (cgFilter.gameTypes.includes('Omaha')) {
            this.gameTypes['Omaha'] = true;
            this.gameTypes['All'] = false;
        } else {
            if (cgFilter.gameTypes.length != 0) this.gameTypes['Omaha'] = false;
        }

        if (cgFilter.gameTypes.includes('Hold’em')) {
            this.gameTypes['Hold’em'] = true;
            this.gameTypes['All'] = false;
        } else {
            if (cgFilter.gameTypes.length != 0) this.gameTypes['Hold’em'] = false;
        }

        if (this.gameTypes['Hold’em'] && this.gameTypes['Omaha']) {
            this.gameTypes['All'] = true;
        }
    }

    openInfoPopup(e: any) {
        this.logger.info('clicked on info popup icon' + JSON.stringify(e));
        const data: CardInfo = {
            viewType: 1,
            headerImageUrl: `${this.currentLobbyInfo.infoActorImage.src}`,
            description: `${this.currentLobbyInfo.infoSubDescription}`,
            cardDetails: {
                game: this.getLimitTypeAndGameType(this.selectedTable.tableLimitType, this.selectedTable.gameTypeId),
                format: this.translatePipe.transform('PARTY_POKER_GC_SHORTDECKQL_CASHGAMEVAL'),
                blinds:
                    this.formatCurrencyPipe.transform(this.selectedTable.smallBlind, this.selectedTable.gameCurrency) +
                    '/' +
                    this.formatCurrencyPipe.transform(this.selectedTable.bigBlind, this.selectedTable.gameCurrency),
                type: ` ${this.selectedTable.maxNoOfSeats} ` + this.translatePipe.transform('PARTY_POKER_MOBILE_LOBBY_DE_Max'), // `${this.selectedTable.maxNoOfSeats === 2 ? '-Heads Up' : '-Max'}`,
            },
            ctaButtons: [this.currentLobbyInfo.secondaryCTA],
        };
        this.dialog.open(LobbyTileInfoComponent, {
            width: '95%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'wpc-card-info',
            hasBackdrop: true,
            backdropClass: 'wpc-card-info-bg',
            disableClose: false,
            closeOnNavigation: true,
        });
    }

    sortTables(e: any) {
        this.logger.info('Clicked on sort icon' + JSON.stringify(e));
        this.cardOrderBy = e;
        this.finalData.reverse();
        this.selectedCard(0, this.finalData[this.selectedTableIndex]);
    }

    openFilterPopup(e: any) {
        this.logger.info('Clicked on filter icon' + JSON.stringify(e));
        this.clientToServer.updatePageTitle('Filters');

        // this.router.navigate(["/play/cash-filters"]);
        this.navigation.goTo('/play/cash-filters');
    }

    selectedCard(i, card) {
        this.logger.info('Clicked on selected cards' + JSON.stringify(i, card));
        this.selectedTableIndex = i;
        this.selectedTable = card;
        this.totalBuyIn = card?.minBuyInAmount / 100;
    }

    onClickPlayNowButton() {
        this.logger.info('clicked on playnow');
        if (this.isLoggedIn) {
            if (this.userBalance / 100 >= this.selectedTable?.minBuyInAmount / 100) {
                if (this.isATHEnabled && this.athBlockTime > 0 && this.selectedTable.maxNoOfSeats != 2) {
                    this.athpenalitypopup = true;
                } else {
                    this.popupIsOpen = true;
                }
            } else {
                this.dialog.open(PWCErrorDialogComponent, {
                    width: '75%',
                    maxHeight: '100vh',
                    data: {
                        title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                        message: this.translatePipe.transform('PARTY_POKER_GC_BUYINCHIPS_INSUFFFUNDSGOCASHADDFNDS'),
                        buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
                    },
                    autoFocus: false,
                    panelClass: 'cg-error-wrapper',
                    hasBackdrop: true,
                    backdropClass: 'cg-error-overlay',
                    disableClose: false,
                    closeOnNavigation: true,
                });
            }
        } else {
            this.pokerWebClientLoginService.requestForPortalLogin();
        }
    }

    getLimitTypeAndGameType(limitType: number, gameType: number) {
        const lType = limitType === 0 ? 'Fixed Limit' : limitType === 1 ? 'No Limit' : 'Pot Limit';
        let gType = '';
        switch (gameType) {
            case 0:
            case 6:
            case 9:
            case 10:
                gType = "Hold'em";
                break;
            case 1:
            case 2:
            case 3:
            case 4:
                gType = 'Omaha';
                break;
            // case 7:
            //     gType = 'Short Deck';
            //     break;
        }
        return lType + ' ' + gType;
    }
    closePopup() {
        this.logger.info('clicked on close popup icon...! ');
        this.popupIsOpen = false;
        this.athalertpopup = false;
        this.athpenalitypopup = false;
    }

    onBuyInButtonClick(values: any) {
        this.logger.info('buyInData..' + JSON.stringify(values));
        this.clientToServer.cashGameBuyinRequest(this.selectedTable, values);
        // this.cashGameService.requestForQuickSeatRing(this.buyInData,this.isAutoBuyInTrue,this.selectedBuyIn, this.gameCount);
    }

    getStoreValues() {
        combineLatest([
            this.store.select(userIsAuthenticatedSelect),
            this.store.select(userSSOSelect),
            this.store.select(userProfileSelect),
            this.store.select(miniTablesSelect),
            this.store.select(GameTableSettingsSelect),
            this.store.select(siteCoreLiteralsSelect),
            this.store.select(dynaconConfigsSelect),
        ]).subscribe(([authState, userSSO, userProfile, miniTables, gameTableSettings]) => {
            this.logger.info('getStoreValues...' + authState + userSSO + userProfile + miniTables + gameTableSettings);
            this.isLoggedIn = authState;
        });
    }
    closeGermanBalanceInfoPopup() {
        this.isDePopupEnabled = false;
        this.showATHStrikeHandCountConfigPopUp();
    }
    setSwiperBuyinValues(cardsData) {
        this.tileLeastBuyinValue = cardsData[0]['maxBuyInAmount'] / 100;
        this.tileMaxBuyinValue = cardsData[cardsData.length - 1]['maxBuyInAmount'] / 100;
    }
}
