<div class="menu-items-wrap game-table-side-navigation">
    <div [ngClass]="{ 'd-none': !showMainItems }">
        <div class="auth-block">
            <pwc-client-lhn-user-info (emitSideNavToggle)="closeLHN()"></pwc-client-lhn-user-info>
        </div>

        <div class="menu-group">
            <h4 class="menu-group-title" (click)="goToSubItem('tableThemes')">{{ 'PARTY_POKER_GC_COMMON_TABLETHEMES' | translate }}</h4>
        </div>

        <div class="menu-group">
            <h4 class="menu-group-title" (click)="goToSubItem('gameSettings')">{{ 'PARTY_POKER_MOBILE_TABLE_COMMON_GAMESETTINGS' | translate }}</h4>
        </div>
        <div class="menu-group">
            <h4 class="menu-group-title" (click)="goToSubItem('raisedBtns')">
                {{ 'PARTY_POKER_MOBILE_TABLE_COMMON_RAISEBUTTONSETTINGS' | translate }}
            </h4>
        </div>
        <div class="menu-group">
            <h4 class="menu-group-title" (click)="goToLobby()">{{ 'PARTY_POKER_GC_COMMON_LOBBY' | translate }}</h4>
        </div>
        <div class="menu-group">
            <h4 class="menu-group-title" (click)="leaveTable()" [class.disabled]="isRatholPopupActive">
                {{ 'PARTY_POKER_GC_LEAVETABLE' | translate }}
            </h4>
        </div>
    </div>
    <div [ngClass]="{ 'd-none': showMainItems }">
        <div class="sub-menu-group">
            <h4 class="menu-group-title"><span class="theme-left" (click)="backToMainMenuItems()"></span>{{ showSubItemTitle | translate }}</h4>
        </div>
        <div class="d-none" [class.d-block]="selectedSubItem === 'tableThemes'">
            <ul class="px-3 py-2">
                <li class="mb-3" *ngFor="let link of tableThemes[0].children" [ngClass]="link.value">
                    <span class="menu-item" *ngIf="link?.text.length !== 0"
                        ><span>{{ link?.text | translate }}</span></span
                    >
                    <mat-button-toggle-group #{{link.value}}="matButtonToggleGroup" [value]="selectedGameTableValues[link.value]">
                        <mat-button-toggle
                            *ngFor="let option of link?.children"
                            [value]="option?.value"
                            (change)="emitSelectedGameSettingValue(link.value, option?.value)">
                            <i class="theme-spot-filled"></i>
                            <i class="theme-success-i"></i>
                            <img src="{{ getAsset(option.text) }}" alt="Asset" class="table-theme-icon" />
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </li>
            </ul>
        </div>
        <div class="d-none px-3 py-2" [class.d-block]="selectedSubItem === 'gameSettings'">
            <ul class="table-themes-game-settings p-2">
                <li *ngFor="let link of gameSettings[0].children">
                    <span>{{ link?.text | translate }}</span>
                    <div class="switcher-lg">
                        <label class="custom-control custom-checkbox custom-control-switcher">
                            <input
                                type="checkbox"
                                [checked]="selectedGameTableValues[link.selector] ? 'checked' : ''"
                                [id]="link.selector"
                                [name]="link.selector"
                                class="custom-control-input"
                                (change)="emitSelectedGameSettingValue(link.selector, !selectedGameTableValues[link.selector])" />
                            <span class="custom-control-switcher-lever">
                                <small *ngIf="selectedGameTableValues[link.selector]">{{ 'PARTY_POKER_GC_REBUY_AUTOREBUY_ON' | translate }}</small>
                                <small *ngIf="!selectedGameTableValues[link.selector]">{{ 'PARTY_POKER_GC_REBUY_AUTOREBUY_OFF' | translate }}</small>
                            </span>
                        </label>
                    </div>
                </li>
            </ul>
        </div>
        <div class="d-none px-3 py-2" [class.d-block]="selectedSubItem === 'raisedBtns'">
            <a class="menu-item table-theme-prePostFlop">
                <label class="custom-control custom-checkbox custom-control-switcher">
                    <span class="table-theme-prePostFlop-text" [class.active]="!selectedRaiseBtnValues">{{
                        'PARTY_POKER_MOBILE_TABLE_ROUNDS_PREFLOP' | translate
                    }}</span>
                    <input
                        type="checkbox"
                        [checked]="selectedRaiseBtnValues ? 'checked' : ''"
                        id="prePostFlop"
                        name="prePostFlop"
                        class="custom-control-input"
                        (change)="emitSelectedRaisedBtnValue()" />
                    <span class="custom-control-switcher-lever"></span>
                    <span class="table-theme-prePostFlop-text" [class.active]="selectedRaiseBtnValues">{{
                        'PARTY_POKER_MOBILE_TABLE_ROUNDS_POSTFLOP' | translate
                    }}</span>
                </label>
            </a>
            <ng-container *ngIf="!selectedRaiseBtnValues">
                <a class="menu-item">
                    <span>{{ 'PARTY_POKER_MOBILE_TABLE_BUTTONS_RAISEBUTTON' | translate }} 1</span>
                </a>
                <mat-button-toggle-group
                    ngClass="table-theme-custom-button"
                    #betButton1="matButtonToggleGroup"
                    [value]="selectedRaiseBtnPreFlopValues['button0']">
                    <mat-button-toggle
                        *ngFor="let option of betButtonsPreFlop"
                        [value]="option?.value"
                        (change)="emitSelectedPreFlopRaisedBtnValue('button0', option?.value)">
                        {{ option?.text | titlecase }}<span class="table-theme-custom-button-type">{{ option?.type | titlecase }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <a class="menu-item">
                    <span>{{ 'PARTY_POKER_MOBILE_TABLE_BUTTONS_RAISEBUTTON' | translate }} 2</span>
                </a>
                <mat-button-toggle-group
                    ngClass="table-theme-custom-button"
                    #betButton2="matButtonToggleGroup"
                    [value]="selectedRaiseBtnPreFlopValues['button1']">
                    <mat-button-toggle
                        *ngFor="let option of betButtonsPreFlop"
                        [value]="option?.value"
                        (change)="emitSelectedPreFlopRaisedBtnValue('button1', option?.value)">
                        {{ option?.text | titlecase }}<span class="table-theme-custom-button-type">{{ option?.type | titlecase }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <a class="menu-item">
                    <span>{{ 'PARTY_POKER_MOBILE_TABLE_BUTTONS_RAISEBUTTON' | translate }} 3</span>
                </a>
                <mat-button-toggle-group
                    ngClass="table-theme-custom-button"
                    #betButton3="matButtonToggleGroup"
                    [value]="selectedRaiseBtnPreFlopValues['button2']">
                    <mat-button-toggle
                        *ngFor="let option of betButtonsPreFlop"
                        [value]="option?.value"
                        (change)="emitSelectedPreFlopRaisedBtnValue('button2', option?.value)">
                        {{ option?.text | titlecase }}<span class="table-theme-custom-button-type">{{ option?.type | titlecase }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </ng-container>
            <ng-container *ngIf="selectedRaiseBtnValues">
                <a class="menu-item">
                    <span>{{ 'PARTY_POKER_MOBILE_TABLE_BUTTONS_RAISEBUTTON' | translate }} 1</span>
                </a>
                <mat-button-toggle-group
                    ngClass="table-theme-custom-button"
                    #betButton1="matButtonToggleGroup"
                    [value]="selectedRaiseBtnPostFlopValues['button0']">
                    <mat-button-toggle
                        *ngFor="let option of betButtonsPostFlop"
                        [value]="option?.value"
                        (change)="emitSelectedPostFlopRaisedBtnValue('button0', option?.value)">
                        {{ option?.text | titlecase }}<span class="table-theme-custom-button-type">{{ option?.type | titlecase }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <a class="menu-item">
                    <span>{{ 'PARTY_POKER_MOBILE_TABLE_BUTTONS_RAISEBUTTON' | translate }} 2</span>
                </a>
                <mat-button-toggle-group
                    ngClass="table-theme-custom-button"
                    #betButton2="matButtonToggleGroup"
                    [value]="selectedRaiseBtnPostFlopValues['button1']">
                    <mat-button-toggle
                        *ngFor="let option of betButtonsPostFlop"
                        [value]="option?.value"
                        (change)="emitSelectedPostFlopRaisedBtnValue('button1', option?.value)">
                        {{ option?.text | titlecase }}<span class="table-theme-custom-button-type">{{ option?.type | titlecase }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <a class="menu-item">
                    <span>{{ 'PARTY_POKER_MOBILE_TABLE_BUTTONS_RAISEBUTTON' | translate }} 3</span>
                </a>
                <mat-button-toggle-group
                    ngClass="table-theme-custom-button"
                    #betButton3="matButtonToggleGroup"
                    [value]="selectedRaiseBtnPostFlopValues['button2']">
                    <mat-button-toggle
                        *ngFor="let option of betButtonsPostFlop"
                        [value]="option?.value"
                        (change)="emitSelectedPostFlopRaisedBtnValue('button2', option?.value)">
                        {{ option?.text | titlecase }}<span class="table-theme-custom-button-type">{{ option?.type | titlecase }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </ng-container>
        </div>
    </div>
</div>
