import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';

export class CardsViewModel {
    gameService;
    tableSettingsDataModel;
    constructor(gs, gsd) {
        this.gameService = gs;
        this.tableSettingsDataModel = gsd;
    }
    setView = function (view, EventDispatcher) {
        this.view = view;
        this.EventDispatcher = EventDispatcher;
        this.peerId = this.gameService.getPeerId();
        this.view.currentTablePeerId(this.peerId);
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_DEAL_CARDS, this.handleDealCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.RESET_GAME, this.resetGame.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CARDS, this.handleShowCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_PLAYER_STATUS, this.updateGameType.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION, this.getOtherPlayerSelectedOption.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SELF_HANDSTRENGTH, this.handleHandStrength.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_WINNER_CARDS, this.handleWinnerCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.CHANGE_CARD_STYLE, this.setCardStyle.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_SETTING_DATA, this.updatePlayerCardStyle.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HANDLE_FULL_GAME_CLOSE, this.handleFullGameClose.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.PROBABILITY_OF_WINNING, this.handleProbabilityOfWinning.bind(this));
        //EventDispatcher.addEventListener("onSpinMultiplierComplete", this.initiateTableCards.bind(this));
        // moving showemoji from selfemojieview.s to cardsview for responsive
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.BROADCAST_EMOJI, this.broadcastEmoji.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ON_WINCHIPS_ANIMATION_COMPLETED, this.hideBackCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_BACKCARDS, this.hideBackCards.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_CASHOUT_BUTTONS, this.handleEquityPercentage.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CASHOUT_AMOUNT, this.showCashOutAmt.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SELECTED_CASHOUT_OPTION, this.removeCashoutAmount.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_EQUITY_PERCENTAGE, this.handleEquityPercentage.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_ANIMATIONS_FOR_CASHOUT, this.hideEmojiForCashOut.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_ACTION_FOR_CASHOUT, this.showEmojiForCashOut.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.HIDE_CARDS, this.hideCards.bind(this));
    };
    hideCards = function (seatNo) {
        this.view.hideCards(seatNo);
    };
    showEmojiForCashOut = function () {
        this.view.showEmojiForCashOut();
    };
    hideEmojiForCashOut = function (tempSeatPos: any) {
        this.view.hideEmojiForCashOut(tempSeatPos.detail);
    };
    removeCashoutAmount = function (isSelected) {
        if (isSelected.detail.isSelected == 0) this.view.removeCashoutAmount();
    };
    showCashOutAmt = function (isCashedOut) {
        const playersCashoutEquityDetails = this.gameService.gameInfoModel.getPlayersCashoutEquityDetails().playerCashoutEquityDetails;
        for (let i = 0; i < playersCashoutEquityDetails.length; i++) {
            const playerSeat = this.gameService.getClientSideSeatNo(playersCashoutEquityDetails[i].seatNo);
            if (playerSeat == uiConfig.selfUserClientPosInd)
                this.view.showCashOutAmt(playersCashoutEquityDetails[i].allPotsTotEquityWin, isCashedOut.detail.isCashedOut);
        }
    };
    handleEquityPercentage = function () {
        const cashoutDetails = this.gameService.gameInfoModel.getPlayersCashoutEquityDetails().playerCashoutEquityDetails;
        for (let i = 0; i < cashoutDetails.length; i++) {
            this.view.showWinningProb(cashoutDetails[i].equityPercentage[0], cashoutDetails[i].seatNo);
        }
    };
    hideBackCards = function (data) {
        this.view.hideBackCards(data.detail.seatNo);
    };
    handleFullGameClose = function () {
        this.view.handleFullGameClose();
    };
    handleWinnerCards = function () {
        const winnerCards = this.gameService.getWinnerCards();
        this.view.handleWinnerCards(winnerCards);
    };
    updatePlayerCardStyle = function (data) {
        this.view.updatePlayerCardStyle(data.detail.cardStyle.cardViewStyle);
        this.view.updateBackCardStyle(data.detail.cardStyle.cardBackgroundStyle);
    };
    getOtherPlayerSelectedOption = function () {
        this.view.getOtherPlayerSelectedOption(this.gameService.getOtherPlayerSelectedOption());
    };
    setCardStyle = function () {
        this.view.setCardStyle(this.gameService.getCardStyle());
    };
    updateGameType = function () {
        this.view.updateGameType(this.gameService.getGameTypeId());
    };
    handleDealCards = function () {
        this.view.updatePlayerCardStyle(this.tableSettingsDataModel.getGameSettingData().cardStyle.cardViewStyle);
        this.view.updateBackCardStyle(this.tableSettingsDataModel.getGameSettingData().cardStyle.cardBackgroundStyle);
        if (!this.view.noOfCards) {
            this.view.updateGameType(this.gameService.getGameTypeId());
        }
        this.view.handlePlayersDealCards(this.gameService.getDealCards());
    };
    resetGame = function () {
        this.view.resetGame();
    };
    handleHandStrength = function () {
        const obj = this.gameService.getHandStrengthData();
        if (CommonGameProperties.facade && CommonGameProperties.langPack) {
            const handStrengthOfPLayer = CommonGameProperties.langPack['getFormattedString'](obj.handDescription);
            this.view.showSelfHandStrength(handStrengthOfPLayer, uiConfig.selfUserClientPosInd);
        }
    };
    handleShowCards = function () {
        const obj = this.gameService.getShowCardsData();
        this.view.handleShowCards(obj);
        let handStrengthOfPLayer;
        if (obj.show && obj.handStrengthHi !== null) {
            obj.handStrengthHi.params = []; // this should not be done for actual response
            handStrengthOfPLayer = CommonGameProperties.langPack['getFormattedString'](obj.handStrengthHi);
            const tempSeatPos = this.gameService.getClientSideSeatNo(obj.seatNo);
            if (tempSeatPos == uiConfig.selfUserClientPosInd) this.view.showSelfHandStrength(handStrengthOfPLayer, tempSeatPos);
            else this.view.showHandStrength(handStrengthOfPLayer, tempSeatPos);
        }
    };
    handleProbabilityOfWinning = function () {
        setTimeout(
            function () {
                const probabilityOfWinningObj = this.gameService.getProbabilityOfWinning();
                this.view.handleProbabilityOfWinning(probabilityOfWinningObj);
            }.bind(this),
            10,
        );
    };
    broadcastEmoji = function () {
        this.view.broadcastEmoji();
    };
}
