import { Message } from '../../message';

export class PokerPlayerProtectionInfo extends Message {
    private MESSAGE_NAME: string = 'PokerPlayerProtectionInfo';
    private protectionLevel: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, protectionLevel: number = 0) {
        super(reqServerPeerId);
        this.protectionLevel = protectionLevel;
    }

    getProtectionLevel(): number {
        return this.protectionLevel;
    }

    setProtectionLevel(protectionLevel: number) {
        this.protectionLevel = protectionLevel;
    }
}
