import { Message } from '../../message';
import { Buddy } from './Buddy';

export class BuddyResponse extends Message {
    private MESSAGE_NAME: string = 'BuddyResponse';
    private responseId: number = 0;
    private buddy: Buddy | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseId: number = 0, buddy: Buddy | null = null) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.buddy = buddy;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getBuddy(): Buddy | null {
        return this.buddy;
    }

    setBuddy(buddy: Buddy | null) {
        this.buddy = buddy;
    }
}
