import { Message } from '../../message';

export class ResponseTourneyCount extends Message {
    private MESSAGE_NAME: string = 'ResponseTourneyCount';
    private trnyCount: number = 0;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, trnyCount: number = 0, requestType: number = 0) {
        super(reqServerPeerId);
        this.trnyCount = trnyCount;
        this.requestType = requestType;
    }

    getTrnyCount(): number {
        return this.trnyCount;
    }

    setTrnyCount(trnyCount: number) {
        this.trnyCount = trnyCount;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
