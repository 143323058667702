import { Message } from '../../message';

export class PlayerVerificationStatus extends Message {
    private MESSAGE_NAME: string = 'PlayerVerificationStatus';
    private verified: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor(reqServerPeerId: number = 0, verified: boolean | null = false) {
        super(reqServerPeerId);
        this.verified = verified;
    }

    getVerified(): boolean | null {
        return this.verified;
    }

    setVerified(verified: boolean | null) {
        this.verified = verified;
    }
}
