import { Injectable } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class SpinFiltersEventBusService {
    private cardsFilter: { gameTypes: Array<string>; winUptos: Array<number> };
    public filteredSngJPCards: any[] = [];
    public selectedWinUptos: any[] = [];
    SNG_JACKPOT_TAB_MODE: number;
    SNG_JACKPOT_EXPO_TAB_MODE = 3;
    spinsPlayUltraBtn: boolean = false;
    private readonly logger: PokerLogger;
    constructor(loggerFactory: LoggerFactory) {
        this.logger = loggerFactory.getLogger('SpinsFilterseventbusserviceLoggingComponent');
        if (localStorage.getItem('sngJpCardsFilter') == null) {
            this.cardsFilter = { gameTypes: [], winUptos: [] };
        } else {
            this.cardsFilter = JSON.parse(localStorage.getItem('sngJpCardsFilter')!);
        }
        localStorage.setItem('sngJpCardsFilter', JSON.stringify(this.cardsFilter));
    }

    filterSNgJPCards(sngjpcards, spinsType) {
        this.logger.info('filtersngjpcards' + JSON.stringify(sngjpcards, spinsType));
        this.cardsFilter = JSON.parse(localStorage.getItem('sngJpCardsFilter')!);
        if (!this.cardsFilter || (this.cardsFilter.gameTypes.length === 0 && this.cardsFilter.winUptos.length === 0)) {
            const sortedFiltered = sngjpcards;
            this.filteredSngJPCards = _.sortBy(sortedFiltered, ['slide.text2', 'slide.text1']);
        } else if (this.cardsFilter.gameTypes.length > 0 && this.cardsFilter.winUptos.length === 0) {
            this.filteredSngJPCards = _.sortBy(
                _.filter(sngjpcards, (f) => _.filter(this.cardsFilter.gameTypes, (b) => f.gameTypeTxt === b).length > 0),
                ['slide.text2', 'slide.text1'],
            );
        } else {
            const filtered1 = _.filter(sngjpcards, (f) => _.filter(this.cardsFilter.winUptos, (a) => f.slide.text2 === a).length > 0);
            this.filteredSngJPCards = _.sortBy(
                _.filter(filtered1, (f) => _.filter(this.cardsFilter.gameTypes, (b) => f.gameTypeTxt === b).length > 0),
                ['slide.text2', 'slide.text1'],
            );
        }
        return this.filteredSngJPCards;
    }
}
