import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { DynamicHtmlDirective, Page, PlainLinkComponent } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import {
    NavigationBarComponent,
    NavigationBarPokerComponent,
    PageNotFoundComponent,
    PokerHomePageClientConfig,
    ScrollToTopComponent,
} from '@pokercore/module/core';

@Component({
    selector: 'pk-poker-synopsis-promotions',
    templateUrl: 'poker-promotions-synopsis.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        PageNotFoundComponent,
        TrustAsHtmlPipe,
        DynamicHtmlDirective,
        NavigationBarComponent,
        NavigationBarPokerComponent,
        ScrollToTopComponent,
        PlainLinkComponent,
    ],
})
export class PokerPromotionsSynopsisComponent implements OnInit, AfterViewInit {
    @Input() initData: any;
    pokerPromotionsHome: any;
    relatedPromos: any;
    menuItems: any;
    lang: string;
    menuContent: any;
    showConfigureNavigation: boolean = false;
    disableHtmlHeadTag: boolean = false;
    parentElement: any;
    heroCTAClass: any;
    constructor(
        private routeData: RouteDataService,
        private page: Page,
        private titleService: Title,
        private metaService: Meta,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
    ) {}

    getPromoLink(promoName: string) {
        if (promoName != null) {
            if (promoName.includes('publicpages')) {
                promoName = promoName.replace('publicpages', 'p');
            } else if (promoName.includes('mobilepoker-v1.0')) {
                promoName = promoName.replace('mobilepoker-v1.0', this.lang);
            }
            const tokens = promoName.split('/').slice(2);
            promoName = tokens.join('/');
        }
        //console.log(this.lang,promoName)
        return `${this.lang}/${promoName}`;
    }

    ngOnInit(): void {
        if (this.initData === null || this.initData === undefined) {
            this.initData = this.routeData.getInitData();
        }

        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;

        const el = document.getElementById('main-content') as HTMLElement;
        el.className = 'fixed-width';

        if (!this.initData.notFound) {
            this.pokerPromotionsHome = this.initData.promoHomeModel.pokerPromotionsSitecoreInfo;
            this.relatedPromos = this.initData.promoHomeModel.relatedPromos;
            this.initData?.teaserConfigPromos.forEach((element: any) => {
                this.relatedPromos.push(element);
            });
            this.relatedPromos.sort((a: any, b: any) => (a.promoIndex > b.promoIndex ? 1 : -1));
            this.menuItems = this.pokerPromotionsHome.menuContent;
            this.heroCTAClass = this.pokerPromotionsHome?.heroCTAClass?.url;
            this.lang = this.page.lang;
            this.titleService.setTitle(this.pokerPromotionsHome.title);
            this.metaService.updateTag({ name: 'description', content: this.pokerPromotionsHome.description });
        }
    }

    menuItemClicked() {
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf('/') + 1);
        //let itemId = itemUrl.substr(itemUrl.lastIndexOf(windowObj.clientConfig.lhProductHomepagesConfig.poker)+ 1);
        /*let windowObj:any =  window;
        let itemId:string;
        let siteUrl:string;
        siteUrl = windowObj.clientConfig.lhProductHomepagesConfig.poker;
        if(itemUrl.indexOf(siteUrl)>=0){
            itemId = itemUrl.substr(siteUrl.length+ 1);
        }else{
            itemId = itemUrl;
        }
        
        this.api.get('/PokerPromotion', { folderId: itemId },{showSpinner:true}).subscribe((response) => {
            
            this.pokerPromotionsInitData = response.promoModel;
            this.promoItem = response.promoItem;
            this.relatedPromos = response.promoModel.relatedPromos;
            this.promoListEx = response.promoModel.promoListEx;
        }, (error) => {
        });*/
        //console.log(itemUrl)
    }

    ngAfterViewInit() {
        //var accItem = document.getElementsByClassName('accordionItem');
        const accHD = document.getElementsByClassName('accordionItemHeading');
        if (accHD) {
            for (let i = 0; i < accHD.length; i++) {
                this.parentElement = accHD[i].parentElement?.className;
                accHD[i].addEventListener('click', this.toggleItem, false);
            }
        }
    }

    toggleItem() {
        this.parentElement.className == 'accordionItem close'
            ? (this.parentElement.className = 'accordionItem open')
            : (this.parentElement.className = 'accordionItem close');
    }
}
