import { Message } from '../../message';

export class QSBlockSeatCancel extends Message {
    private MESSAGE_NAME: string = 'QSBlockSeatCancel';
    private peerTableList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, peerTableList: any[] | null = null) {
        super(reqServerPeerId);
        this.peerTableList = peerTableList;
    }

    getPeerTableList(): any[] | null {
        return this.peerTableList;
    }

    setPeerTableList(peerTableList: any[] | null) {
        this.peerTableList = peerTableList;
    }
}
