import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';

export class CoreViewModel {
    gameService;
    EventDispatcher;
    tableSettingsDataModel;

    eventDispatcherGlobal = new EventDispatcherGlobal();

    constructor(gs, EventDispatcher, gsd) {
        this.gameService = gs;
        this.EventDispatcher = EventDispatcher;
        this.tableSettingsDataModel = gsd;
    }
    initiate = function (facade, pokerGameContainer) {
        this.pendingGameStatusDetected = false;
        this.optionSetDetected = false;
        this.optionSetDataObj = undefined;
        this.gameFacade = facade;
        this.pokerGameContainer = pokerGameContainer;
    };
    createEvents = function (EventDispatcher) {
        EventDispatcher.addEventListener(SlotCoreGameEvent.ADD_NEW_TABLE, this.addNewTable.bind(this));
        //this.pokerGameContainer.hideKothBtn();
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.GAME_VIEW_CREATED, this.checkForGameStatus.bind(this));
        //on off sounds
    };
    checkForGameStatus = function () {
        if (this.pendingGameStatusDetected && this.gameStatusData) {
            clearTimeout(this.handleGameStatusTimeout);
            this.handleGameStatus(this.gameStatusData);
        }
    };
    addNewTable = function () {
        this.newTableReq = true;
        this.gameFacade.sendAddNewTableRequest();
    };
    // -------------- server responses --------------------------
    handleConnectionStatus = function (status) {
        this.gameService.setConnectionStatus(status);
        if (this.gameFacade.controlPanelViewModel) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_CONNECTION_TICKER, this.gameService.getConnectionStatusMessage());
        }
    };
    //-------------poker core view model new methods------------------
    handleJoinTableResponse = function (data) {
        this.gameService.setJoinTableResponse(data);
        if (data.tourneyGameStatus) this.handleTourneyGameStatus(data.tourneyGameStatus);
    };

    handlePlayersCashoutEquityDetails = function (data) {
        if (CommonGameProperties.dynaconConfigs['webClientConfigs']['isCashoutEquityEnabled'] == 'false') {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ON_CASHOUT_DISABLED);
            return;
        }
        this.gameService.setPlayersCashoutEquityDetails(data);
        for (let i = 0; i < data.playerCashoutEquityDetails.length; i++) {
            const playerSeat = this.gameService.getClientSideSeatNo(data.playerCashoutEquityDetails[i].seatNo);
            const allPotsTotEquityWin = data.playerCashoutEquityDetails[i].allPotsTotEquityWin;
            const equityPercent = data.playerCashoutEquityDetails[i].equityPercentage[0];
            const noOfPotsReceived = Object.keys(data.playerCashoutEquityDetails[i].equityPercentage).length;

            if (playerSeat == uiConfig.selfUserClientPosInd) {
                if (allPotsTotEquityWin === 0) {
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_EQUITY_PERCENTAGE);
                    this.pokerGameContainer.sendCashoutOption(0);
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CASHOUT_WAIT_TEXT);
                } else if (data.playerCashoutEquityDetails.length === 2 && noOfPotsReceived === 1 && Math.floor(equityPercent / 100) === 50) {
                    let showCOE = true;
                    for (let j = 0; j < data.playerCashoutEquityDetails.length; j++) {
                        const opsPotCount = Object.keys(data.playerCashoutEquityDetails[j].equityPercentage).length;
                        if (opsPotCount === 2 && this.gameService.getCommunityCardsArray().length >= 3) {
                            showCOE = true;
                            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_CASHOUT_BUTTONS);
                            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CASHOUT_AMOUNT, { isCashedOut: false });
                            break;
                        } else {
                            showCOE = false;
                        }
                    }

                    if (showCOE === false) {
                        if (this.gameService.getCommunityCardsArray().length === 0) {
                            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_CASHOUT_BUTTONS);
                            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CASHOUT_AMOUNT, { isCashedOut: false });
                        } else {
                            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_EQUITY_PERCENTAGE);
                            this.pokerGameContainer.sendCashoutOption(0);
                        }
                    }
                } else if (Math.floor(equityPercent / 100) === 100) {
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_EQUITY_PERCENTAGE);
                    this.pokerGameContainer.sendCashoutOption(0);
                } else {
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_CASHOUT_BUTTONS);
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CASHOUT_AMOUNT, { isCashedOut: false });
                }
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_ANIMATIONS_FOR_CASHOUT, { playerSeat });
            }
        }
    };
    handlePlayerCashedOutEquityStatus = function (data) {
        if (CommonGameProperties.dynaconConfigs['webClientConfigs']['isCashoutEquityEnabled'] == 'false') return;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.OPPONENT_CASHOUT_ANIM, { seatNo: data.seatNo });
    };
    handleResponseUserTableBalanceReduceChips = function (data) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_LOCK_ICON_ANIM, { seatNo: data.seatNo });
    };
    handleResponseCashoutEquity = function (data) {
        if (CommonGameProperties.dynaconConfigs['webClientConfigs']['isCashoutEquityEnabled'] == 'false') return;
        if (data.statusCode == 1) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CASHOUT_ANIM);
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CASHOUT_AMOUNT, { isCashedOut: true });
        }
    };
    /*
      GameStatus is snapshot of the table at that particular instance.
      client recieves this message several times:
      1. During previousPlayingGTs
      2. During start of game
      3. client requests for GameStatus upon disconnection and reconnection state.
      once it recieves the GameStatus, reset everything and initiate the table with the current snapshot of gamestatus
      4. few other cases where lot of updates needs to be processed.
      */
    handleGameStatus = function (data) {
        //initially views creation and EventListeners takes some time for creation
        if (data.gameId && !this.gameService.getIsTableCreated()) {
            this.pendingGameStatusDetected = true;
            this.gameStatusData = data;
            this.handleGameStatusTimeout = setTimeout(
                function () {
                    this.handleGameStatusTimeout = undefined;
                    this.gameStatusData = undefined;
                    this.handleGameStatus(data);
                }.bind(this),
                3000,
            );
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_FORCE_TABLE_LOADER);
            return;
        } else {
            this.RequestEndOfTheGame(); //raises event SlotCoreGameEvent.RESET_GAME internally
            this.gameService.setGameStatus(data); //set the data it got reset
            this.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_NONE);
            //start a new game
            if (data.gameId) {
                const newGamedata = {
                    gameId: data.gameId,
                    seatNo: data.seatNo,
                    msgNumber: 0,
                    peerId: data.peerId,
                    dealerPosition: data.dealer,
                    className: 'NewGame',
                };
                this.handleNewGame(newGamedata);
            }
            this.gameService.setRoundCount(data.roundCount);
            //handle player statuses
            if (data.players && data.players.length > 0) this.handlePlayerStatus(data.players);
            if (data.trnyLevelInfo) {
                if (this.gameService.getTableStatusData().tableCategory == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
                    this.handleTourneyLevelInfo(data.trnyLevelInfo);
                }
            }
            if (data.communityHand && data.communityHand.length > 0) {
                this.checkForCommunityCards(data.communityHand);
            }
            if (data.players) {
                this.checkForDealCards(data.players);
            }
            if (data.tourneyGameStatus) {
                this.handleTourneyGameStatus(data.tourneyGameStatus);
            }
            // window.parent.AppController.SendMessageToSwitcher({
            //   eventName: "gameStatus",
            //   gameData: data
            // });
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_GAME_STATUS);
            this.forceRefreshTable();
            //start handling messages which came during pendingGameStatus
            if (this.optionSetDetected && this.optionSetDataObj) {
                this.HandleOptionSet(this.optionSetDataObj);
            }
            this.pendingGameStatusDetected = false;
        }
    };
    checkForCommunityCards = function (data) {
        const obj = {};
        obj['cardNo'] = 0;
        obj['cardType'] = 0;
        obj['cards'] = data;
        this.handleAddCommunityCards(obj);
    };
    checkForDealCards = function (obj) {
        //check for obj.players length and if the self player id doesn't map in the array then clse the table (waiting for confirmation from product)
        //{ "communityHand": [ ], "currentStake": 0, "dealer": 1, "gameId": 15936146733, "jackpotRake": 0, "players": [ { "avatarImage": "20", "balance": 46000, "bet": 4000, "betVector": [ 4000 ], "brandId": "BWINCOM", "cards": [ "XX", "XX" ], "chatIndicator": false, "city": "City1", "country": "Canada", "imageURL": null, "isCustomAvatar": false, "isSeatedPlayer": true, "playerId": "wild11", "pointCardStatus": 0, "seatingPosition": 0, "sex": "M", "state": 5, "avatarImageName": null, "avatarImagePath": null, "forceSitOutReasonId": 0, "PlayerAccountId": 0, "playerFullName": null, "playerRealName": null }, { "avatarImage": "40", "balance": 98000, "bet": 2000, "betVector": [ 2000 ], "brandId": "BWINCOM", "cards": [ "XX", "XX" ], "chatIndicator": false, "city": "City9", "country": "United Kingdom", "imageURL": null, "isCustomAvatar": false, "isSeatedPlayer": true, "playerId": "usd19", "pointCardStatus": 0, "seatingPosition": 1, "sex": "M", "state": 2, "avatarImageName": null, "avatarImagePath": null, "forceSitOutReasonId": 0, "PlayerAccountId": 0, "playerFullName": null, "playerRealName": null } ], "pots": [ { "amount": 0, "potNo": 0 } ], "rabbitCommunityCards": [ ], "rake": 0, "reservedSeats": [ ], "roundCount": 1, "seatNo": 1, "tourneyGameStatus": 125, "trnyLevelInfo": null, "trnyStartedAt": 0, "waitingNo": 0, "waitingStatus": 1, "waitingUsersCount": 0, "msgNumber": 0, "peerId": 5230462, "className": "GameStatus" }
        const dealCardsArr = [] as any,
            playerDealCardsObj = {};
        for (let playerIndex = 0; playerIndex < obj.length; playerIndex++) {
            const cards = {};
            if (obj[playerIndex].cards && obj[playerIndex].cards.length > 0) {
                cards['cards'] = obj[playerIndex].cards;
                cards['seatingPosition'] = obj[playerIndex].seatingPosition;
                dealCardsArr.push(cards);
            }
        }
        if (dealCardsArr.length > 0) {
            playerDealCardsObj['playerDealCards'] = dealCardsArr;
            this.handleDealCards(playerDealCardsObj);
        }
    };
    handleDisconnectedState = function (obj) {
        if (!parseInt(obj.status)) {
            this.optionSetDetected = false;
            this.optionSetDataObj = undefined;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_DISCONNECTION_STATE, obj);
    };
    forceRefreshTable = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.FORCE_REFRESH_TABLE);
    };
    handleTourneyGameStatus = function (tourneyGameStatus) {
        if (!tourneyGameStatus) return;
        switch (tourneyGameStatus) {
            case serverIdConstants.TOURNEY_STATUS_UNKNOWN:
            case serverIdConstants.TOURNEY_STATUS_FINISHED:
            case serverIdConstants.TOURNEY_STATUS_DELETED:
                this.closeTable();
                break;
            case serverIdConstants.TOURNEY_STATUS_PAUSED:
            case serverIdConstants.TOURNEY_STATUS_STARTED: {
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_REGISTRAION_VIEW);
                break;
            }
            case serverIdConstants.TOURNEY_STATUS_WAITING_FOR_MORE_PLAYERS:
                break;
        }
    };
    handlePlayerStatus = function (obj) {
        this.gameService.PlayerStatus(obj);
        //{"seatNo":1,"state":4,"msgNumber":0,"peerId":6385328,"className":"ChangePlayerState"}
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_PLAYER_STATUS);
        for (let i = 0; i < obj.length; i++) {
            const ChangePlayerStateObj = {
                seatNo: obj[i].seatingPosition,
                state: obj[i].state,
                className: 'ChangePlayerState',
            };
            this.ChangePlayerState(ChangePlayerStateObj);
        }
    };
    handleAddCommunityCards = function (obj) {
        this.gameService.AddCommunityCards(obj);
        setTimeout(
            function () {
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_COMMUNITYCARDS);
            }.bind(this),
            300,
        );
    };
    handleAddPlayer = function (obj) {
        if (!obj.avatarImage) obj.avatarImage = obj.imageUrl;
        this.gameService.AddPlayer(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_ADD_PLAYER);
        //below condition for cashgames to support reduce funds
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH) {
            this.PlayerBalance(obj);
        }
    };
    handleCurrentPlayer = function (obj) {
        this.gameService.CurrentPlayer(obj);
    };
    handleDealCards = function (obj) {
        this.gameService.DealCards(obj);
        let dataToSend = obj.playerDealCards[this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd)];
        if (!dataToSend || dataToSend.cards[0] == 'XX') {
            dataToSend = [];
            let tempCardsData;
            for (let i = 0; i < obj.playerDealCards.length; i++) {
                tempCardsData = obj.playerDealCards[i];
                if (tempCardsData.cards && tempCardsData.cards[0] != 'XX') {
                    dataToSend = tempCardsData;
                    break;
                }
            }
        }
        const message = {};
        message['className'] = 'UpdateGameTableCards';
        message['tableId'] = this.gameService.getPeerId();
        message['cards'] = dataToSend;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_DEAL_CARDS);
    };
    handleDealer = function (obj) {
        this.gameService.Dealer(obj);
    };
    handleEndTheRound = function (obj) {
        //this is called after river, trun and flop Cards
        this.optionSetDetected = false;
        this.optionSetDataObj = undefined;
        if (obj.roundCount == 5) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.Disable_All_In_Glow);
        } else {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_SELF_HAND_STRENGTH, false);
        }
        this.gameService.setEndTheRound(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_END_THE_ROUND);
    };
    handleNewGame = function (obj) {
        //{"gameId":15931711947,"seatNo":1,"msgNumber":0,"peerId":5136981,"className":"NewGame"}
        this.gameService.NewGame(obj); //set the dealer pos;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_NEW_GAME, this.gameService.getPeerId());
        this.pokerGameContainer.sendRequestMuckLosingHand(this.tableSettingsDataModel.getGameSettingData().autoMuck);
    };
    handlePotWinners = function (obj) {
        this.gameService.PotWinners(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_POT_WINNERS);
    };
    handleGameTableStatus = function (obj) {
        this.gameService.GameTableStatus(obj);
    };
    setRemovedPlayersData = function (obj) {
        const playerStatus = this.gameService.getplayerStates();
        const size = Object.keys(playerStatus.states).length;
        let sitOutPlayers = 0;
        for (const prop in playerStatus.states) {
            if (playerStatus.states[prop] == serverIdConstants.PLAYERSTATE_SIT_OUT) sitOutPlayers += 1;
        }
        if (sitOutPlayers >= size - 1 || playerStatus.states[obj.seatNumber] == serverIdConstants.PLAYERSTATE_SIT_OUT) this.handleRemovePlayer(obj);
        else this.gameService.setRemovedPlayersData(obj);
    };
    handleRemovedPlayers = function () {
        this.removedPlayersData = this.gameService.getRemovedPlayersData();
        for (let i = 0; i < this.removedPlayersData.length; i++) this.handleRemovePlayer(this.removedPlayersData[i]);
    };
    handleRemovePlayer = function (obj) {
        this.gameService.handleRemovePlayer(obj);
        if (obj.seatNumber == this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd)) {
            if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
                this.handleClosePoolEntry();
            }
        }
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH && this.gameService.getMaxNoOfSeats() == 2)
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_KOTH_PLUS);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_PLAYER);
    };
    handleReserveSeat = function (obj) {
        this.gameService.ReserveSeat(obj);
    };
    handleShowCardsOptions = function (obj) {
        this.gameService.ShowCardsOptions(obj);
    };
    handleTimeOut = function (obj) {
        //let obj = {"period":1562933043,"seatNo":2,"showClockAt":0,"msgNumber":0,"peerId":5133864,"className":"TimeOut"};
        this.gameService.TimeOut(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_BET_TIMER);
    };
    handleWaitStatus = function (obj) {
        this.gameService.WaitStatus(obj);
    };
    handlePlayerDealCards = function (obj) {
        this.gameService.PlayerDealCards(obj);
    };
    handleSNGJackpotInfo = function (obj) {
        this.gameService.SNGJackpotInfo(obj);
        //facade.updateJackpotInfo(this.gameService.getPeerId(), obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_BACKGROUND_ON_DISC);
        if (window.parent) {
            window.parent.dispatchEvent(
                new CustomEvent('JACKPOT_DATA', {
                    detail: obj,
                }),
            );
        }
        const message = {};
        message['className'] = 'UpdateSNGJPJackpotInfo';
        message['tableId'] = this.gameService.getPeerId();
        message['data'] = obj;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        if (obj.cardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_NORMAL || obj.cardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_ULTRA) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_PLAYER_BALANCE);
        }
        if (obj.sngJPPayoutInfo && obj.sngJPPayoutInfo.totalPrize && obj.sngJPPayoutInfo.totalPrize != null) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_PRIZEPOOLVALUE, obj);
        }
    };
    playerStates = function (obj) {
        this.gameService.setplayerStates(obj);
        let ChangePlayerStateObj;
        const avaiPlayersArr = [] as any;
        for (const i in obj.states) {
            avaiPlayersArr.push(parseInt(i));
            ChangePlayerStateObj = {
                seatNo: i,
                state: obj.states[i],
                msgNumber: 0,
                peerId: obj.peerId,
                className: 'ChangePlayerState',
            };
            this.ChangePlayerState(ChangePlayerStateObj);
        }
        if (avaiPlayersArr.length < this.gameService.getMaxNoOfSeats()) {
            for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
                if (avaiPlayersArr.indexOf(i) === -1) {
                    const tempRemovePlayerArr = {
                        dontShowSeatOpen: false,
                        isReserved: false,
                        isSeatedPlayer: true,
                        seatNumber: i,
                        msgNumber: 0,
                        peerId: obj.peerId,
                        className: 'RemovePlayer',
                    };
                    this.handleRemovePlayer(tempRemovePlayerArr);
                }
            }
        }
    };
    ChangePlayerState = function (obj) {
        //let obj = {"seatNo":2,"state":4,"msgNumber":0,"peerId":5133878,"className":"ChangePlayerState"}
        /*PLAYERSTATE_SIT_OUT        1
          PLAYERSTATE_SIT_IN        2
          PLAYERSTATE_ALL_IN        3
          PLAYERSTATE_FOLDED        4
          PLAYERSTATE_AUTOPOST_BLIND_AND_FOLD    5
          PLAYERSTATE_ALL_IN_NOTRESPONDING        7
          PLAYERSTATE_NOT_IN_GAME    9*/
        this.gameService.ChangePlayerState(obj);
        const currentPeerId = this.gameService.getPeerId();
        let selfUserInd;
        if (uiConfig['tableConfig' + currentPeerId].playerSeatInx) {
            selfUserInd = this.gameService.getClientSideSeatNo(obj.seatNo);
        } else {
            selfUserInd = uiConfig.selfUserClientPosInd;
        }
        if (uiConfig.selfUserClientPosInd == selfUserInd) {
            this.updatePlayerState(obj.state);
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_CHANGE_PLAYERSTATE);
    };
    updatePlayerState = function (state) {
        if (state == undefined || state == null) return;
        this.playerState = state;
        switch (state) {
            case serverIdConstants.PLAYERSTATE_SIT_OUT:
            case serverIdConstants.PLAYERSTATE_AUTOPOST_BLIND_AND_FOLD:
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_IAM_BACK);
                this.playerState = serverIdConstants.PLAYERSTATE_SIT_OUT;
                this.gameService.setSelfEmojiStatus(false);
                break;
            case serverIdConstants.PLAYERSTATE_SIT_IN:
                this.playerState = serverIdConstants.PLAYERSTATE_SIT_IN;
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_IAM_BACK);
                this.gameService.setSelfEmojiStatus(true);
                break;
            case serverIdConstants.PLAYERSTATE_FOLDED:
                this.playerState = serverIdConstants.PLAYERSTATE_FOLDED;
                this.gameService.setSelfEmojiStatus(false);
                break;
            default:
                this.playerState = serverIdConstants.PLAYERSTATE_SIT_IN;
                this.gameService.setSelfEmojiStatus(true);
        }
        const message = {};
        message['className'] = 'UpdateGameTableStatus';
        message['tableId'] = this.gameService.getPeerId();
        message['status'] = this.playerState;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    selfPlayerTimerStarted = function (time, peerId) {
        const message = {};
        message['className'] = 'UpdateGameTableTimer';
        message['tableId'] = this.gameService.getPeerId();
        message['TotalTimeAvailable'] = time;
        message['peerId'] = peerId;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    selfPlayerTimerEnded = function (peerId) {
        const message = {};
        message['className'] = 'StopGameTableTimer';
        message['tableId'] = this.gameService.getPeerId();
        message['peerId'] = peerId;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    TimeOut = function (obj) {
        this.gameService.TimeOut(obj);
        this.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_PLAY_OPTIONS);
    };
    PlayerBalance = function (obj) {
        this.gameService.handlePlayerBalance(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_PLAYER_BALANCES);
        // below condition for cashgames reduce funds support in hamburger menu
        if (this.gameService.getClientSideSeatNo(obj.seatNo) == uiConfig.selfUserClientPosInd) {
            //console.log("rohith", obj.seatNo, obj.balance);
            const message = {};
            message['className'] = 'playerBalance';
            message['gameData'] = obj;
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        }
    };
    Blind = function (obj) {
        this.gameService.Blind(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_BLIND_DATA);
    };
    PostBlind = function (obj) {
        this.gameService.PostBlind(obj);
    };
    Ante = function (obj) {
        this.gameService.Ante(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_ANTE_DATA);
    };
    PostAnte = function (obj) {
        this.gameService.PostAnte(obj);
    };
    handlePlayerTimeBankBalance = function (obj) {
        this.gameService.handlePlayerTimeBankBalance(obj);
    };
    changeCardStyle = function (obj) {
        this.gameService.setCardStyle(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.CHANGE_CARD_STYLE);
    };
    ShowCards = function (obj) {
        this.gameService.ShowCards(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_CARDS);
    };
    HighlightWinCards = function (obj) {
        if (obj.winnersCards && obj.winnersCards.length > 0) {
            for (const winnerCards of obj.winnersCards) this.handleWinnerCards(winnerCards);
        }
        //this.gameService.HighlightWinCards(obj);
    };
    handleWinnerCards = function (winnerCardsData) {
        //{"seatNo":2,"winningCards":["Ac","Kh","Ks","4c","4d"]}
        this.gameService.WinnerCards(winnerCardsData);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_WINNER_CARDS);
    };
    Winner = function (obj) {
        this.gameService.Winner(obj);
    };
    ShowCongrats = function (obj) {
        this.gameService.ShowCongrats(obj);
        this.gameService.setCurrentShowingOption(serverIdConstants.SHOWING_NONE);
    };
    RequestEndOfTheGame = function (obj) {
        this.gameService.RequestEndOfTheGame(obj);
        /////request for remove the cards ////
        const message = {};
        message['className'] = 'UpdateGameTableCards';
        message['tableId'] = this.gameService.getPeerId();
        message['cards'] = [];
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        //below condition for cashgames rebuy check
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH) {
            if (this.gameService.getAutoRebuyStatus() != undefined && !this.gameService.getAutoRebuyStatus()) {
                this.checkLowOnBalanceForRebuy(true);
            }
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.RESET_GAME);
    };
    HandleSNGJPPayoutAnimation = function (obj) {
        this.gameService.setSNGJPPayoutAnimation(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_BACKGROUND_ON_DISC);
        // window.parent.AppController.SendMessageToSwitcher({
        //   eventName: "StartSpinAnimation",
        //   TableId: this.gameService.getPeerId()
        // });
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.START_SPIN_MULTILPIER);
        // window.parent.dispatchEvent(new Event("StartSpinAnimation"));
        if (obj.sngCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_NORMAL || obj.sngCardType == serverIdConstants.TABLE_TYPE_EXPO_SPIN_ULTRA) {
            const message = {};
            message['className'] = 'SNGJPExpoAnimation';
            message['data'] = obj;
            // window.postMessage(message, "*");
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        }
        // this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.PLAY_EXPOSPIN_VID,obj);
    };
    handleSNGJPTournamentRankInfo = function (obj) {
        this.gameService.setSNGJPTournamentRankInfo(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SNGJP_TOURNAMENT_RANKINFO);
        // below code for to send message to switcher for to update to playerstate 2 when we get winnerpop and player is in away
        const playerState = serverIdConstants.PLAYERSTATE_SIT_IN;
        const message = {};
        message['className'] = 'UpdateGameTableStatus';
        message['tableId'] = this.gameService.getPeerId();
        message['status'] = playerState;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    };
    HandleSNGJPGameAboutToStart = function (obj) {
        this.gameService.setSNGJPGameAboutToStart(obj);
        if (window.parent) {
            window.parent.dispatchEvent(
                new CustomEvent('JACKPOT_DATA', {
                    detail: obj,
                }),
            );
        }
        if (obj.sngJackpotPayoutInfo && obj.sngJackpotPayoutInfo.totalPrize && obj.sngJackpotPayoutInfo.totalPrize != null) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_PRIZEPOOLVALUE, obj);
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_REGISTRAION_VIEW);
        const iframeId = this.getActiveIframeId(obj.peerId);
        if (
            iframeId != undefined &&
            CommonGameProperties.expoAnime[iframeId]?.expoSpinAnime != null &&
            CommonGameProperties.expoAnime[iframeId].expoSpinAnime.activeStatus == true
        ) {
            this.eventDispatcherGlobal.dispatchEvent('resumeExpoAnime', CommonGameProperties.expoAnime[iframeId].expoSpinAnime);
        }
    };
    getActiveIframeId = function (tableId) {
        let activeIframe;
        for (let i = 0; i < 4; i++) {
            // let iframe = document.getElementById("expoSpinIframe"+i);
            // if(CommonGameProperties.expoAnime[i].expoSpinAnime.activeStatus==true &&CommonGameProperties.expoAnime[i].expoSpinAnime.tableId==tableId)
            if (CommonGameProperties.expoAnime[i].expoSpinAnime.tableId == tableId) {
                activeIframe = i;
                break;
            }
        }
        return activeIframe;
    };
    HandleAutoOptionSet = function (obj) {
        this.gameService.HandleAutoOptionSet(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_AUTO_BET_OPTIONS);
    };
    HandleShowCardsOptions = function (obj) {
        this.gameService.HandleShowCardsOptions(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_SHOWCARD_OPTIONS);
    };
    HandleMuckCards = function (obj) {
        this.gameService.HandleMuckCards(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_MUCKCARDS);
    };
    HandleOptionSet = function (obj) {
        this.optionSetDetected = true;
        this.optionSetDataObj = obj;
        this.gameService.HandleOptionSet(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_BET_OPTIONS);
    };
    HandleShowHUPairTable = function (obj) {
        this.gameService.setShowHuPairTableStatus(obj);
        this.gameService.setOppPlayerName(obj);
    };
    HandleHUEvenGame = function (obj) {
        this.gameService.setHUEvenGame(obj);
    };
    HandleHUPairTableSelectStatus = function (obj) {
        this.gameService.setHUPairTableSelectStatus(obj);
    };
    clearSpinsInterval = function () {
        this.EventDispatcher.dispatchEvent('clearSpinsInterval');
    };
    HandleSelectedOption = function (obj) {
        this.optionSetDetected = false;
        this.gameService.HandleOtherPlayerSelectedOption(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.OTHERPLAYER_SELECTED_OPTION);
    };
    HandleOption = function (obj) {
        this.gameService.HandleOption(obj);
    };
    HandleResThrowAnimation = function (obj) {
        this.gameService.HandleResThrowAnimation(obj);
    };
    HandleThrowAnimation = function (obj) {
        this.gameService.HandleThrowAnimation(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.THROW_ANIMATION);
    };
    handleDiamondBalance = function (obj) {
        this.gameService.handleDiamondBalance(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_DIAMOND_BALANCE);
    };
    handleBroadcastShowRabbit = function (obj) {
        this.gameService.handleBroadcastShowRabbit(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.BROADCAST_SHOW_RABBIT);
    };
    handleCloseTable = function (obj) {
        if (!obj?.message) {
            if (obj === undefined) {
                this.closeTable();
            } else {
                setTimeout(this.closeTable.bind(this), obj.delayInSecs * 1000);
            }
            return;
        }
        //let strMessage = facade.langPack.getFormattedString(obj.message);
        const strMessage = '';
        if (obj.delayInSecs > 0) {
            //display message, OnOk and OnTimeOut, close the table
            if (strMessage != '') this.showCloseTablePopUp(strMessage);
            setTimeout(this.closeTable.bind(this), obj.delayInSecs * 1000);
        } else if (obj.delayInSecs == 0 && strMessage != '' && strMessage != null) {
            //show lobby message but close the table without any user action
            this.showCloseTablePopUp(strMessage);
            this.closeTable();
        } else this.closeTable();
        this.gameService.handleCloseTable();
    };
    showCloseTablePopUp = function (msg) {
        this.gameFacade.showGenericMessageOnLobby(msg);
    };
    closeTable = function () {
        this.RequestEndOfTheGame(); //calls SlotCoreGameEvent.RESET_GAME internally
        this.handleKothBtns(); //for showing plus icon when tables are less than 4
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.CLOSE_TABLE);
    };
    handleKothBtns = function () {
        for (let i = 0; i < this.gameFacade.activeTables.length; i++) {
            const tableId = this.gameFacade.activeTables[i];
            if (
                this.gameFacade['table' + tableId].gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
                this.gameFacade['table' + tableId].gameService.getMaxNoOfSeats() == 2
            )
                this.gameFacade['table' + tableId].kothTableViewModel.view.showkothPlusIcon();
        }
    };
    handleSelfEmoji = function (obj) {
        this.gameService.handleSelfEmoji(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.BROADCAST_EMOJI);
    };
    handleHandStrength = function (obj) {
        this.gameService.handleHandStrength(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SELF_HANDSTRENGTH);
    };
    handleShowRabbit = function (obj) {
        this.gameService.handleShowRabbit(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_RABBIT);
    };
    handleProbabilityOfWinning = function (obj) {
        this.gameService.ProbabilityOfWinning(obj);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.PROBABILITY_OF_WINNING);
    };
    handlePlayerBalances = function (obj) {
        //this.gameService.handlePlayerBalances(obj);
        for (const index in obj.balances) {
            const playerBalanceObj = {
                balance: obj.balances[index],
                seatNo: index,
                msgNumber: 0,
                peerId: obj.peerId,
                className: 'PlayerBalance',
            };
            this.PlayerBalance(playerBalanceObj);
        }
    };
    // below method type(true,false) for checking from where it is getting called,case prevplayingpool
    checkLowOnBalanceForRebuy = function (type) {
        //it takes a while to get playerbalances from server ani comment pettu, so added settimeout
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            if (this.lowBalanceTimeout) {
                clearTimeout(this.lowBalanceTimeout);
            }
            this.lowBalanceTimeout = setTimeout(
                function () {
                    if (
                        (!this.gameService.getCurrentGameId() && type) ||
                        this.gameService.getAllPlayerStates()[this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd)] !=
                            serverIdConstants.PLAYERSTATE_SIT_OUT
                    )
                        return;
                    const tableCategory = this.gameService.getTableStatusData().tableCategory;
                    if (tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT && this.gameService.getUserBalance(uiConfig.selfUserId) == 0) {
                        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.OPEN_REBUY_WINDOW);
                        return true;
                    }
                    return false;
                }.bind(this),
                1000,
            );
        } else if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH) {
            if (!this.gameService.getCurrentGameId() && type) return;
            const tableCategory = this.gameService.getTableStatusData().tableCategory;
            if (tableCategory != serverIdConstants.TABLE_CATEGORY_TOURNAMENT && this.gameService.getUserBalance(uiConfig.selfUserId) == 0) {
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.OPEN_REBUY_WINDOW);
                return true;
            }
            return false;
        }
        return false;
    };
    handleGameSettingData = function (data) {
        this.gameService.setSettingsData(data);
        uiConfig['toggleSoundVal'] = data.sound;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_SETTING_DATA, data);
    };
    handleThemeChange = function (data) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_THEME_CHANGE, data);
    };
    handleTourneyLevelInfo = function (data) {
        this.gameService.handleTourneyLevelInfo(data);
    };
    // fast-farword msgs from here //
    handleJoinPoolResponse = function (data) {
        this.gameService.setPoolAvailabilityResponse(data.tableStatus);
        this.handlePlayerStatus([data.playerStatus]);
    };
    handleGameTableType = function (data) {
        this.gameService.setGameTableType(data);
    };
    handleRequestEndOfTheGameFastPoker = function () {
        this.RequestEndOfTheGame();
        this.forceRefreshTable();
    };
    handlePoolWaitingNotification = function () {
        this.RequestEndOfTheGame();
        this.forceRefreshTable();
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_WAITING_FOR_PLAYERS);
    };
    handlePoolStartGameNotification = function (data) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_REGISTRAION_VIEW);
        this.pokerGameContainer.setTableId(data.tableId);
    };
    handlePoolEndGameNotification = function () {
        this.RequestEndOfTheGame();
        const selfUserServerPos = this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd);
        const maxNoOfSeats = this.gameService.getMaxNoOfSeats();
        for (let i = 0; i < maxNoOfSeats; i++) {
            if (i != selfUserServerPos && this.gameService.getIsSeatOccupied(i)) {
                const tempRemovePlayerArr = {
                    dontShowSeatOpen: false,
                    isReserved: false,
                    isSeatedPlayer: true,
                    seatNumber: i,
                    msgNumber: 0,
                    className: 'RemovePlayer',
                };
                this.handleRemovePlayer(tempRemovePlayerArr);
            }
        }
        // this.gameService.setCurrentGameId(null);
        const dealerObj = {
            dealerId: null,
            seatingPosition: -1,
        };
        this.handleDealer(dealerObj);
        if (!this.gameService.getAutoRebuyStatus()) {
            this.checkLowOnBalanceForRebuy(true);
        }
        const serverPosForSelfUser = this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd);
        uiConfig['tableConfig' + this.gameService.getPeerId()].playerSeatInx = [];
        uiConfig['tableConfig' + this.gameService.getPeerId()].playerSeatInx[serverPosForSelfUser] = uiConfig.selfUserClientPosInd;
        this.forceRefreshTable();
    };
    handlePoolSitOutNotification = function (data) {
        this.gameService.handlePoolSitOutNotification(data);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_REGISTRAION_VIEW);
        const playerState = {
            seatNo: this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd),
            state: serverIdConstants.PLAYERSTATE_SIT_OUT,
            msgNumber: 0,
            peerId: this.gameService.getPeerId(),
            className: 'ChangePlayerState',
            info: 'from PoolSitOutNotification',
        };
        this.ChangePlayerState(playerState);
        this.forceRefreshTable();
    };
    handleClosePoolEntry = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.CONCLUDED_LEAVE);
    };
    handleReqNextHandOnFoldAndWatch = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_NEXT_HAND);
    };
    updateSelfPlayerBalance = function (balance) {
        const playerBalanceObj = {
            balance: balance,
            seatNo: this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd),
            msgNumber: 0,
            peerId: this.gameService.getPeerId(),
            info: 'obj built from updateSelfPlayerBalance',
            className: 'PlayerBalance',
        };
        this.PlayerBalance(playerBalanceObj);
    };
    handlePoolFraudPlayerKickoutNotification = function () {
        const messageText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_FRAUDKICKOUTMSG', '', '', true);
        this.showKickedOutError(messageText);
    };
    handleResponseLossLimitExceed = function (data) {
        const messageText = CommonGameProperties.langPack['getFormattedString'](data.message);
        this.showKickedOutError(messageText);
    };
    handlePoolKickOutNotification = function (data) {
        let messageText = CommonGameProperties.langPack['getFormattedString'](data.messageToClient);
        if (messageText == null) {
            messageText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_LOBBY_FASTFORWARD_POOLKICKOUTNOTIFICATION', '', '', true);
        }
        if (messageText) {
            this.showKickedOutError(messageText);
        }
    };
    handlePoolShutdownNotification = function (data) {
        let messageText = CommonGameProperties.langPack['getFormattedString'](data.messageToClient);
        if (messageText == null) {
            messageText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_LOBBY_FASTFORWARD_POOLSHUTDOWNNOTIFICATION', '', '', true);
        }
        if (messageText) {
            this.showKickedOutError(messageText);
        }
    };
    handlePoolRebuyResponse = function (obj) {
        let playerBalanceObj = {};
        let message;
        switch (obj.rebuyStatus) {
            case 0: //FAST_POOL_REBUY_SUCCESS
                playerBalanceObj = {
                    balance: obj.userBalance,
                    seatNo: this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd),
                    msgNumber: 0,
                    peerId: obj.peerId,
                    className: 'PlayerBalance',
                };
                this.PlayerBalance(playerBalanceObj);
                break;
            case 1: //FAST_POOL_REBUY_FAILURE
                message = CommonGameProperties.langPack['getFormattedString'](
                    obj.messageToClient,
                    this.gameService.getTableStatusData().gameCurrency,
                );
                if (!message) {
                    message = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_TRANSFERERR');
                }
                this.showGenericError(message, 'PARTY_POKER_GC_GAMETABLE_TRANSFERERR');
                break;
            case 2: //FAST_POOL_REBUY_CANCELLED
                if (this.gameService.getUserBalance(uiConfig.selfUserId) <= this.gameService.getTableStatusData()['tableStatus'].maxBuyInAmount) {
                    this.showGenericError('Rebuy got Cancelled.', 'PARTY_POKER_GC_GAMETABLE_TRANSFERERR');
                }
                break;
            case 3: //FAST_POOL_REBUY_MIDGAME
                this.handleResponseBuyMoreChipsPlaying();
                break;
            case 4: //FAST_POOL_REBUY_INSUFFICIENTBALANCE
                //To be done
                break;
            case 5: //FAST_POOL_REBUY_SUCCESS_PARTIAL
                playerBalanceObj = {
                    balance: obj.userBalance,
                    seatNo: this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd),
                    msgNumber: 0,
                    peerId: obj.peerId,
                    className: 'PlayerBalance',
                };
                this.PlayerBalance(playerBalanceObj);
                //To be done
                // if(autoRebuyEnabled)
                //   this.handleResponseAutoRebuyInsufficientFunds();
                break;
            case 6: //FAST_POOL_REBUY_ERROR_REQ_LESS_THAN_MIN_AMT
                message = CommonGameProperties.langPack['getFormattedString'](
                    obj.messageToClient,
                    this.gameService.getTableStatusData().gameCurrency,
                );
                this.showGenericError(message, 'PARTY_POKER_GC_GAMETABLE_TRANSFERERR');
                break;
            case 2501: //PLR_BLOCKED_RG
            case 2502: //PLR_BLOCKED_COOLOFF
                message = CommonGameProperties.langPack['getFormattedString'](obj.messageToClient);
                if (!message) {
                    message = CommonGameProperties.langPack['getLiteral']('PARTY_COMMON_GC_SERVICECLOSURE_SELFEXCLUSION');
                }
                this.showGenericError(message, 'PARTY_COMMON_GC_SERVICECLOSURE_SELFEXCLUSION');
                break;
            case 2503: //PLR_BLOCKED_SC_BY_PLAYER
                message = CommonGameProperties.langPack['getFormattedString'](obj.messageToClient);
                if (!message) {
                    message = CommonGameProperties.langPack['langPack']('PARTY_COMMON_GC_SERVICECLOSURE_CLOSEDPRODUCT');
                }
                this.showGenericError(message, 'PARTY_COMMON_GC_SERVICECLOSURE_CLOSEDPRODUCT');
                //onOk show serviceClosure page
                break;
            case 2604: //FAST_POOL_REBUY_ITALIA_FAILURE
            case 2504: //PLR_BLOCKED_SC_BY_AGENT
                message = CommonGameProperties.langPack['getFormattedString'](obj.messageToClient);
                if (!message) {
                    message = CommonGameProperties.langPack['getLiteral']('PARTY_COMMON_GC_SERVICECLOSURE_CLOSEDPRODUCT');
                }
                this.showGenericError(message, 'PARTY_COMMON_GC_SERVICECLOSURE_CLOSEDPRODUCT');
                break;
            case 2601: //FAST_POOL_REBUY_FAIL_PLR_BLOCKED_REALITYCHECK //this.showRealityCheckErrorPopup();
            case 2602: //R_GAME_SESSION_TIMEDOUT  //this.showSessionTimeOutErrorPopup();
            case 11: //FAST_POOL_FAILURE_BETLIMIT //this.showBetLimitPopupDlg();
            case 2505: //FAST_POOL_REBUY_FAIL_LOCATION_CHECK  //this.checkAndShowGeoLocationPopup();
            case 2506: //FAST_POOL_REBUY_FAIL_SESSION_EXPIRED //this.showSessionLimitPopup();
            case 2600: //FAST_POOL_REBUY_FAIL_PLR_BLOCKED_GLOBAL_REALITYCHECK //this.ShowRCPUErrorMsgPopup()
            case 2507: //FAST_POOL_REBUY_FAIL_LOSSLIMIT_EXCEEDED //this.ShowLossLimitPopup()
            case 2508: //FAST_POOL_REBUY_FAIL_LOSSLIMIT_WARNING //this.ShowLossLimitPopup();
                if (obj.messageToClient) {
                    message = CommonGameProperties.langPack['getLiteral'](obj.messageToClient);
                    if (message != '') this.showGenericError(message, 'FAST_POOL_REBUY_FAIL');
                }
                break;
            default:
        }
    };
    handleResponseBuyMoreChipsPlaying = function () {
        let message = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_BUYMORECHIPSPLAYING');
        this.showGenericError(message, 'PARTY_POKER_GC_GAMETABLE_BUYMORECHIPSPLAYING');
    };
    handlePlayerEntryStatus = function (data) {
        //data = {"playerEntryId":this.gameSService.getPeerId(), "entryStatus":2, "activeTableId":-1, "balance":199};
        if (data.balance != undefined) this.updateSelfPlayerBalance(data.balance);

        let sitOutMessage: any;
        switch (data.entryStatus) {
            case 0: //FAST_PLAYER_ENTRY_ON_GAME_TABLE
                //gets handled from fast-forward controller
                // this.gameFacade.addPeer(data.activeTableId, this.gameService.getTableStatusData().tableTypeId);
                // this.handlePoolStartGameNotification({"entryId": data.entryId, "tableId":data.tableId});
                break;
            case 1: //FAST_PLAYER_ENTRY_WAITING_FOR_PLAYERS
                this.handlePoolWaitingNotification();
                break;
            case 2: //FAST_PLAYER_ENTRY_SITTING_OUT
                this.handlePoolSitOutNotification();
                break;
            case 3: //FAST_PLAYER_ENTRY_FORCED_GEO_SIT_OUT
                sitOutMessage = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GEOLOCATION_HEADER_LOADGAME', '', '', true); //SITOUT_GEOLOCATION_INVALID
                break;
            case 4: //FAST_PLAYER_ENTRY_FORCED_SESSION_SIT_OUT
                sitOutMessage = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_SESSIONLIMIT_GTTIMEOUT', '', '', true); //SITOUT_SESSION_EXPIRED
                break;
            case 6: //FAST_PLAYER_FORCED_SITOUT
                sitOutMessage = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_SESSIONLIMIT_POPUP', '', '', true); //R_GAME_SESSION_TIMEDOUT
                break;
        }
        if (sitOutMessage) {
            this.showKickedOutError(sitOutMessage);
        }
        const msgObj_disconnection = {
            className: 'disConnected',
            status: 1,
            peerId: this.gameService.getPeerId(),
        };
        this.handleDisconnectedState(msgObj_disconnection);
    };
    showGenericError = function (text, id, titleText) {
        const genericErrorPopUp: any = uiConfig.genericPopUpConfig;
        if (titleText) {
            genericErrorPopUp.titleText = titleText;
        } else {
            genericErrorPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_ERROR');
        }
        genericErrorPopUp.bodyText = text;
        genericErrorPopUp.showCloseButton = false;
        genericErrorPopUp.showCheckBox = false;
        genericErrorPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_OK')];
        genericErrorPopUp.buttonsActionArr = [''];
        genericErrorPopUp.popUpId = id; //PARTY_POKER_GC_LEAVETABLE
        this.gameService.setAddPopUpData(genericErrorPopUp);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ADD_GENERIC_POPUP);
    };
    showKickedOutError = function (text) {
        let closeTableErrPopUp: any = uiConfig.genericPopUpConfig;
        closeTableErrPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_ERROR');
        closeTableErrPopUp.bodyText = text;
        closeTableErrPopUp.showCloseButton = false;
        closeTableErrPopUp.showCheckBox = false;
        closeTableErrPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_OK')];
        closeTableErrPopUp.buttonsActionArr = [SlotCoreGameEvent.CONCLUDED_LEAVE];
        closeTableErrPopUp.popUpId = 'FORCE_SIT_OUT_POPUP'; //PARTY_POKER_GC_LEAVETABLE
        this.gameService.setAddPopUpData(closeTableErrPopUp);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ADD_GENERIC_POPUP);
    };
    /* below method for rebuystatus for FF games
          params{boolean}
      */
    setAutoRebuyStatus = function (val) {
        this.gameService.setAutoRebuyStatus(val);
    };
    getAutoRebuyStatus = function () {
        return this.gameService.getAutoRebuyStatus();
    };
    handleBlockSeatResponse = function (data) {
        let sitResponseMsg;
        switch (data.blockSeatStatus) {
            case serverIdConstants.BLOCKSEAT_BLOCKED:
            case serverIdConstants.BLOCKSEAT_RECOMMENDED_BLOCKED:
                this.gameFacade.sendSitRequest(
                    0,
                    0,
                    this.gameService.getTableStatusData().minBuyInAmount,
                    data.seatNumber,
                    this.gameService.getTableCategory(),
                    this.pokerGameContainer.tableId,
                );
                break;
            case serverIdConstants.BLOCKSEAT_NOT_AVAILABLE:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_FULLTABLEMSG', '', '', true);
                break;
            case serverIdConstants.BLOCKSEAT_REAL_TABLE:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_BIN_ACCTBLMSG', '', '', true);
                break;
            case serverIdConstants.BLOCKSEAT_NOT_LOGGED_IN:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_NEEDTOLOGINBEFSIT', '', '', true);
                break;
            case serverIdConstants.BLOCKSEAT_FAILED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_PWF_ERROR_IP_COUNTRY_BLOCKED', '', '', true);
                break;
            case serverIdConstants.BLOCKSEAT_NOT_IN_PLAYERS_CLUB:
                sitResponseMsg = CommonGameProperties.langPack['getFormattedString'](data.messageToClient);
                break;
            case serverIdConstants.BLOCKSEAT_INSUFFICIENT_POINTS:
                sitResponseMsg = CommonGameProperties.langPack['getFormattedString'](data.messageToClient);
                break;
            case serverIdConstants.BLOCKSEAT_TIMER_4_QS_EXPIRED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_BUYINCHIPS_SORRYRESTIMEOUT', '', '', true);
                break;
            case serverIdConstants.BLOCKSEAT_HOGGING_PLAYER_FAILED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_HUTABLEHOGGINGBLOCKSEATERROR', '', '', true);
                break;
            default:
                sitResponseMsg = CommonGameProperties.langPack['getFormattedString'](data.messageToClient);
                break;
        }
        if (sitResponseMsg) {
            this.showKickedOutError(sitResponseMsg);
        }
    };
    // below method for cash games request on sitresponse to be sent
    handleSitResponse = function (data) {
        let sitResponseMsg;
        switch (data.responseId) {
            // sit response success
            case serverIdConstants.R_SIT_SUCCESS:
            case serverIdConstants.R_SIT_REGAIN_PLAYER_SUCCESS:
            case serverIdConstants.R_REGAIN_PLAYER_SUCCESS:
                this.pokerGameContainer.sendRequestAutoPostBlindOn(data.peerId);
                this.pokerGameContainer.sendRequestRebuyOnOff(this.gameService.getAutoRebuyStatus(), data.peerId);
                break;
            case serverIdConstants.R_BUYCHIPS_FAILED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_TRNYCHIPSTRANSFERERR', '', '', true);
                break;
            case serverIdConstants.R_SIT_NOT_LOGGED_IN:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_NEEDTOLOGINBEFSIT', '', '', true);
                break;
            case serverIdConstants.R_SIT_REALTABLE_PERMISSION_DENIED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_NEEDTOLOGINBEFSIT', '', '', true);
                break;
            case serverIdConstants.R_SERVER_SHUTTING_DOWN:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_SERVERDHUTDOWNTEMP', '', '', true);
                break;
            case serverIdConstants.R_SIT_TABLE_SHUTTING_DOWN:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_TBLSOONCLSMAINJOINANOTHERTBL', '', '', true);
                break;
            case serverIdConstants.R_SIT_FAILED_SAME_IP:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_MSGRESPALREADYSEATED', '', '', true);
                break;
            case serverIdConstants.R_SIT_ALREADY_OCCUPIED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_SEATALREADYRESVTAKEANOTHERSEAT', '', '', true);
                break;
            case serverIdConstants.R_SIT_REGAIN_PLAYER_FAILED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMECLIENT_PLAYERACTNFAILEDTRYLATERSRY', '', '', true);
                break;
            case serverIdConstants.R_SIT_INSUFFICIENT_CHIPS:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_INSUFFFUNDSGOCASH', '', '', true);
                break;
            case serverIdConstants.R_SIT_RESERVED:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_SEATALREADYRESTAKEANOTHERSEAT', '', '', true);
                break;
            case serverIdConstants.R_BUYCHIPS_USING_FREEROLL_FAILURE:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_FREEROOLNOTAVAILCONTCUST', '', '', true);
                break;
            case serverIdConstants.R_BUYCHIPS_USING_FPP_FAILURE:
                sitResponseMsg = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_FPPNOTAVAILCONTCUST', '', '', true);
                break;
            default:
                break;
        }
        if (sitResponseMsg) {
            this.showKickedOutError(sitResponseMsg);
        }
    };
    //for cash games, handleReduceChipsResponse
    /* eslint-disable @typescript-eslint/no-unused-vars */
    handleReduceChipsResponse = function (data) {
        return;
    };
    handleResponseBuyMoreChipsSuccess = function () {};
    handleResponseBuyMoreChipsAllowedAmount = function () {};
    handleRGLimitErrorMsg = function (data) {
        let strMessage;
        if (data.errorMessageToShow) {
            strMessage = CommonGameProperties.langPack['getFormattedString'](data.errorMessageToShow);
        }
        if (strMessage) {
            this.showGenericError(strMessage);
        }
    };
    handleSitOutWarningOff = function (data) {
        let strMessage;
        if (data) {
            strMessage = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_TABLE_HUMSG', '', '', true);
        }
        if (strMessage) {
            this.showGenericError(strMessage, 'PARTY_POKER_GC_TABLE_HUMSG', 'partypoker');
        }
    };
    handlePlayedGamesCount = function (data) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_PLAYED_GAMES_COUNT, data);
    };
    handleResponseExpoAudio = function (data) {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.PLAY_AUDIO_EXPO_SPIN, data);
    };
}
