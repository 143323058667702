import { Message } from '../../message';
import { DealMakingDetails } from './DealMakingDetails';

export class DealMakingConfirmData extends Message {
    private MESSAGE_NAME: string = 'DealMakingConfirmData';
    private payoutType: number = 0;
    private confirmedPlayers: number = 0;
    private dealMakingDetails: DealMakingDetails | null = null;
    private customPayout: any[] | null = null;
    private postDealPayout: any[] | null = null;
    private hasResponded: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        payoutType: number = 0,
        confirmedPlayers: number = 0,
        dealMakingDetails: DealMakingDetails | null = null,
        customPayout: any[] | null = null,
        postDealPayout: any[] | null = null,
        hasResponded: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.payoutType = payoutType;
        this.confirmedPlayers = confirmedPlayers;
        this.dealMakingDetails = dealMakingDetails;
        this.customPayout = customPayout;
        this.postDealPayout = postDealPayout;
        this.hasResponded = hasResponded;
    }

    getPayoutType(): number {
        return this.payoutType;
    }

    setPayoutType(payoutType: number) {
        this.payoutType = payoutType;
    }
    getConfirmedPlayers(): number {
        return this.confirmedPlayers;
    }

    setConfirmedPlayers(confirmedPlayers: number) {
        this.confirmedPlayers = confirmedPlayers;
    }
    getDealMakingDetails(): DealMakingDetails | null {
        return this.dealMakingDetails;
    }

    setDealMakingDetails(dealMakingDetails: DealMakingDetails | null) {
        this.dealMakingDetails = dealMakingDetails;
    }
    getCustomPayout(): any[] | null {
        return this.customPayout;
    }

    setCustomPayout(customPayout: any[] | null) {
        this.customPayout = customPayout;
    }
    getPostDealPayout(): any[] | null {
        return this.postDealPayout;
    }

    setPostDealPayout(postDealPayout: any[] | null) {
        this.postDealPayout = postDealPayout;
    }
    getHasResponded(): boolean | null {
        return this.hasResponded;
    }

    setHasResponded(hasResponded: boolean | null) {
        this.hasResponded = hasResponded;
    }
}
