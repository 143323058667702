import { Message } from '../../message';

export class LSTableInfoUpdate extends Message {
    private MESSAGE_NAME: string = 'LSTableInfoUpdate';
    private tableInfoId: number = 0;
    private playerCount: number = 0;
    private tableCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableInfoId: number = 0, playerCount: number = 0, tableCount: number = 0) {
        super(reqServerPeerId);
        this.tableInfoId = tableInfoId;
        this.playerCount = playerCount;
        this.tableCount = tableCount;
    }

    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getTableCount(): number {
        return this.tableCount;
    }

    setTableCount(tableCount: number) {
        this.tableCount = tableCount;
    }
}
