import { Message } from '../../message';
import { TourneyLevelInfo } from './TourneyLevelInfo';

export class GameStatus extends Message {
    private MESSAGE_NAME: string = 'GameStatus';
    private players: any[] | null = null;
    private communityHand: any[] | null = null;
    private pots: any[] | null = null;
    private gameId: number = 0;
    private dealer: number = 0;
    private seatNo: number = 0;
    private roundCount: number = 0;
    private currentStake: number = 0;
    private rake: number = 0;
    private reservedSeats: any[] | null = null;
    private waitingStatus: number = 0;
    private waitingNo: number = 0;
    private waitingUsersCount: number = 0;
    private tourneyGameStatus: number = 0;
    private trnyLevelInfo: TourneyLevelInfo | null = null;
    private jackpotRake: number = 0;
    private trnyStartedAt: number = 0;
    private communityHand2: any[] | null = null;
    private boosterType: number = 0;
    private boosterValFromBB: number = 0;
    private boosterValToBB: number = 0;
    private boosterWinnerPerc: number = 0;
    private boosterLooserPerc: number = 0;
    private rabbitCommunityCards: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        players: any[] | null = null,
        communityHand: any[] | null = null,
        pots: any[] | null = null,
        gameId: number = 0,
        dealer: number = 0,
        seatNo: number = 0,
        roundCount: number = 0,
        currentStake: number = 0,
        rake: number = 0,
        reservedSeats: any[] | null = null,
        waitingStatus: number = 0,
        waitingNo: number = 0,
        waitingUsersCount: number = 0,
        tourneyGameStatus: number = 0,
        trnyLevelInfo: TourneyLevelInfo | null = null,
        jackpotRake: number = 0,
        trnyStartedAt: number = 0,
        communityHand2: any[] | null = null,
        boosterType: number = 0,
        boosterValFromBB: number = 0,
        boosterValToBB: number = 0,
        boosterWinnerPerc: number = 0,
        boosterLooserPerc: number = 0,
        rabbitCommunityCards: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.players = players;
        this.communityHand = communityHand;
        this.pots = pots;
        this.gameId = gameId;
        this.dealer = dealer;
        this.seatNo = seatNo;
        this.roundCount = roundCount;
        this.currentStake = currentStake;
        this.rake = rake;
        this.reservedSeats = reservedSeats;
        this.waitingStatus = waitingStatus;
        this.waitingNo = waitingNo;
        this.waitingUsersCount = waitingUsersCount;
        this.tourneyGameStatus = tourneyGameStatus;
        this.trnyLevelInfo = trnyLevelInfo;
        this.jackpotRake = jackpotRake;
        this.trnyStartedAt = trnyStartedAt;
        this.communityHand2 = communityHand2;
        this.boosterType = boosterType;
        this.boosterValFromBB = boosterValFromBB;
        this.boosterValToBB = boosterValToBB;
        this.boosterWinnerPerc = boosterWinnerPerc;
        this.boosterLooserPerc = boosterLooserPerc;
        this.rabbitCommunityCards = rabbitCommunityCards;
    }

    getPlayers(): any[] | null {
        return this.players;
    }

    setPlayers(players: any[] | null) {
        this.players = players;
    }
    getCommunityHand(): any[] | null {
        return this.communityHand;
    }

    setCommunityHand(communityHand: any[] | null) {
        this.communityHand = communityHand;
    }
    getPots(): any[] | null {
        return this.pots;
    }

    setPots(pots: any[] | null) {
        this.pots = pots;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getDealer(): number {
        return this.dealer;
    }

    setDealer(dealer: number) {
        this.dealer = dealer;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getRoundCount(): number {
        return this.roundCount;
    }

    setRoundCount(roundCount: number) {
        this.roundCount = roundCount;
    }
    getCurrentStake(): number {
        return this.currentStake;
    }

    setCurrentStake(currentStake: number) {
        this.currentStake = currentStake;
    }
    getRake(): number {
        return this.rake;
    }

    setRake(rake: number) {
        this.rake = rake;
    }
    getReservedSeats(): any[] | null {
        return this.reservedSeats;
    }

    setReservedSeats(reservedSeats: any[] | null) {
        this.reservedSeats = reservedSeats;
    }
    getWaitingStatus(): number {
        return this.waitingStatus;
    }

    setWaitingStatus(waitingStatus: number) {
        this.waitingStatus = waitingStatus;
    }
    getWaitingNo(): number {
        return this.waitingNo;
    }

    setWaitingNo(waitingNo: number) {
        this.waitingNo = waitingNo;
    }
    getWaitingUsersCount(): number {
        return this.waitingUsersCount;
    }

    setWaitingUsersCount(waitingUsersCount: number) {
        this.waitingUsersCount = waitingUsersCount;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
    getTrnyLevelInfo(): TourneyLevelInfo | null {
        return this.trnyLevelInfo;
    }

    setTrnyLevelInfo(trnyLevelInfo: TourneyLevelInfo | null) {
        this.trnyLevelInfo = trnyLevelInfo;
    }
    getJackpotRake(): number {
        return this.jackpotRake;
    }

    setJackpotRake(jackpotRake: number) {
        this.jackpotRake = jackpotRake;
    }
    getTrnyStartedAt(): number {
        return this.trnyStartedAt;
    }

    setTrnyStartedAt(trnyStartedAt: number) {
        this.trnyStartedAt = trnyStartedAt;
    }
    getCommunityHand2(): any[] | null {
        return this.communityHand2;
    }

    setCommunityHand2(communityHand2: any[] | null) {
        this.communityHand2 = communityHand2;
    }
    getBoosterType(): number {
        return this.boosterType;
    }

    setBoosterType(boosterType: number) {
        this.boosterType = boosterType;
    }
    getBoosterValFromBB(): number {
        return this.boosterValFromBB;
    }

    setBoosterValFromBB(boosterValFromBB: number) {
        this.boosterValFromBB = boosterValFromBB;
    }
    getBoosterValToBB(): number {
        return this.boosterValToBB;
    }

    setBoosterValToBB(boosterValToBB: number) {
        this.boosterValToBB = boosterValToBB;
    }
    getBoosterWinnerPerc(): number {
        return this.boosterWinnerPerc;
    }

    setBoosterWinnerPerc(boosterWinnerPerc: number) {
        this.boosterWinnerPerc = boosterWinnerPerc;
    }
    getBoosterLooserPerc(): number {
        return this.boosterLooserPerc;
    }

    setBoosterLooserPerc(boosterLooserPerc: number) {
        this.boosterLooserPerc = boosterLooserPerc;
    }
    getRabbitCommunityCards(): any[] | null {
        return this.rabbitCommunityCards;
    }

    setRabbitCommunityCards(rabbitCommunityCards: any[] | null) {
        this.rabbitCommunityCards = rabbitCommunityCards;
    }
}
