import chunk from './chunk.js';
import compact from './compact.js';
import concat from './concat.js';
import difference from './difference.js';
import differenceBy from './differenceBy.js';
import differenceWith from './differenceWith.js';
import drop from './drop.js';
import dropRight from './dropRight.js';
import dropRightWhile from './dropRightWhile.js';
import dropWhile from './dropWhile.js';
import fill from './fill.js';
import findIndex from './findIndex.js';
import findLastIndex from './findLastIndex.js';
import first from './first.js';
import flatten from './flatten.js';
import flattenDeep from './flattenDeep.js';
import flattenDepth from './flattenDepth.js';
import fromPairs from './fromPairs.js';
import head from './head.js';
import indexOf from './indexOf.js';
import initial from './initial.js';
import intersection from './intersection.js';
import intersectionBy from './intersectionBy.js';
import intersectionWith from './intersectionWith.js';
import join from './join.js';
import last from './last.js';
import lastIndexOf from './lastIndexOf.js';
import nth from './nth.js';
import pull from './pull.js';
import pullAll from './pullAll.js';
import pullAllBy from './pullAllBy.js';
import pullAllWith from './pullAllWith.js';
import pullAt from './pullAt.js';
import remove from './remove.js';
import reverse from './reverse.js';
import slice from './slice.js';
import sortedIndex from './sortedIndex.js';
import sortedIndexBy from './sortedIndexBy.js';
import sortedIndexOf from './sortedIndexOf.js';
import sortedLastIndex from './sortedLastIndex.js';
import sortedLastIndexBy from './sortedLastIndexBy.js';
import sortedLastIndexOf from './sortedLastIndexOf.js';
import sortedUniq from './sortedUniq.js';
import sortedUniqBy from './sortedUniqBy.js';
import tail from './tail.js';
import take from './take.js';
import takeRight from './takeRight.js';
import takeRightWhile from './takeRightWhile.js';
import takeWhile from './takeWhile.js';
import union from './union.js';
import unionBy from './unionBy.js';
import unionWith from './unionWith.js';
import uniq from './uniq.js';
import uniqBy from './uniqBy.js';
import uniqWith from './uniqWith.js';
import unzip from './unzip.js';
import unzipWith from './unzipWith.js';
import without from './without.js';
import xor from './xor.js';
import xorBy from './xorBy.js';
import xorWith from './xorWith.js';
import zip from './zip.js';
import zipObject from './zipObject.js';
import zipObjectDeep from './zipObjectDeep.js';
import zipWith from './zipWith.js';
export default {
  chunk,
  compact,
  concat,
  difference,
  differenceBy,
  differenceWith,
  drop,
  dropRight,
  dropRightWhile,
  dropWhile,
  fill,
  findIndex,
  findLastIndex,
  first,
  flatten,
  flattenDeep,
  flattenDepth,
  fromPairs,
  head,
  indexOf,
  initial,
  intersection,
  intersectionBy,
  intersectionWith,
  join,
  last,
  lastIndexOf,
  nth,
  pull,
  pullAll,
  pullAllBy,
  pullAllWith,
  pullAt,
  remove,
  reverse,
  slice,
  sortedIndex,
  sortedIndexBy,
  sortedIndexOf,
  sortedLastIndex,
  sortedLastIndexBy,
  sortedLastIndexOf,
  sortedUniq,
  sortedUniqBy,
  tail,
  take,
  takeRight,
  takeRightWhile,
  takeWhile,
  union,
  unionBy,
  unionWith,
  uniq,
  uniqBy,
  uniqWith,
  unzip,
  unzipWith,
  without,
  xor,
  xorBy,
  xorWith,
  zip,
  zipObject,
  zipObjectDeep,
  zipWith
};