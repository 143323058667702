import { Message } from '../../message';

export class ResponseTourneyIdsAcceptingFreerolls extends Message {
    private MESSAGE_NAME: string = 'ResponseTourneyIdsAcceptingFreerolls';
    private tourneyIds: any[] | null = null;
    private isFullUpdate: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tourneyIds: any[] | null = null, isFullUpdate: boolean | null = false) {
        super(reqServerPeerId);
        this.tourneyIds = tourneyIds;
        this.isFullUpdate = isFullUpdate;
    }

    getTourneyIds(): any[] | null {
        return this.tourneyIds;
    }

    setTourneyIds(tourneyIds: any[] | null) {
        this.tourneyIds = tourneyIds;
    }
    getIsFullUpdate(): boolean | null {
        return this.isFullUpdate;
    }

    setIsFullUpdate(isFullUpdate: boolean | null) {
        this.isFullUpdate = isFullUpdate;
    }
}
