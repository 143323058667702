import { Message } from '../../message';

export class ResponseUserTableBalanceReduceChips extends Message {
    private MESSAGE_NAME: string = 'ResponseUserTableBalanceReduceChips';
    private seatNo: number = 0;
    private reduceChipsApplied: boolean | null = false;
    private playerBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, seatNo: number = 0, reduceChipsApplied: boolean | null = false, playerBalance: number = 0) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.reduceChipsApplied = reduceChipsApplied;
        this.playerBalance = playerBalance;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getReduceChipsApplied(): boolean | null {
        return this.reduceChipsApplied;
    }

    setReduceChipsApplied(reduceChipsApplied: boolean | null) {
        this.reduceChipsApplied = reduceChipsApplied;
    }
    getPlayerBalance(): number {
        return this.playerBalance;
    }

    setPlayerBalance(playerBalance: number) {
        this.playerBalance = playerBalance;
    }
}
