import { Message } from '../../message';

export class TimeOut extends Message {
    private MESSAGE_NAME: string = 'TimeOut';
    private period: number = 0;
    private seatNo: number = 0;
    private showClockAt: number = 0;
    private timeOutType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, period: number = 0, seatNo: number = 0, showClockAt: number = 0, timeOutType: number = 0) {
        super(reqServerPeerId);
        this.period = period;
        this.seatNo = seatNo;
        this.showClockAt = showClockAt;
        this.timeOutType = timeOutType;
    }

    getPeriod(): number {
        return this.period;
    }

    setPeriod(period: number) {
        this.period = period;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getShowClockAt(): number {
        return this.showClockAt;
    }

    setShowClockAt(showClockAt: number) {
        this.showClockAt = showClockAt;
    }
    getTimeOutType(): number {
        return this.timeOutType;
    }

    setTimeOutType(timeOutType: number) {
        this.timeOutType = timeOutType;
    }
}
