import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class CashOut extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.CASHOUT;
    }

    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.CASHOUT;
    }

    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        // cashout player details to be saved
        gameTable.playerCashoutMap.set(player.seatNo, this.amount);
        // gameTable.isCashedOut = true;
        // player.cashedOut = true;
        player.cashedOutAmount = this.amount;
        gameTable.cashOutOffered = gameTable.cashOutOfferedinHHparser;

        // console.log(gameTable.playerCashoutMap);

        player.playerStatus = this.getPlayerStatus();

        if (player.chips.value <= 0) {
            player.isPlayerAllIn = true;
        } else {
            player.isPlayerAllIn = false;
        }
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        // gameTable.isCashedOut = false;
        // player.cashedOut = false;
        // gameTable.substractFromPot(this.amount)
        // player.subtractFromPot(this.amount);
        // player.chips.value = player.chips.value - Number(this.amount);
        player.cashedOutAmount = 0;
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
        if (player.chips.value > 0) {
            player.isPlayerAllIn = false;
        } else {
            player.isPlayerAllIn = true;
        }
    }
}
