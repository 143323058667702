import { Message } from '../../message';

export class LSMTCTLevelChangeEvent extends Message {
    private MESSAGE_NAME: string = 'LSMTCTLevelChangeEvent';
    private mtctId: number = 0;
    private currentLevel: number = 0;
    private currSeatSize: number = 0;
    private bigBlindAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, currentLevel: number = 0, currSeatSize: number = 0, bigBlindAmount: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.currentLevel = currentLevel;
        this.currSeatSize = currSeatSize;
        this.bigBlindAmount = bigBlindAmount;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getCurrentLevel(): number {
        return this.currentLevel;
    }

    setCurrentLevel(currentLevel: number) {
        this.currentLevel = currentLevel;
    }
    getCurrSeatSize(): number {
        return this.currSeatSize;
    }

    setCurrSeatSize(currSeatSize: number) {
        this.currSeatSize = currSeatSize;
    }
    getBigBlindAmount(): number {
        return this.bigBlindAmount;
    }

    setBigBlindAmount(bigBlindAmount: number) {
        this.bigBlindAmount = bigBlindAmount;
    }
}
