<div class="wpc-card-info-wrapper">
    <div class="card-info-modal">
        <div class="card-info-header">
            <div class="headerImage">
                <img *ngIf="data.headerImageUrl" [src]="data.headerImageUrl" alt="info_popup_logo" />
            </div>
            <span class="close theme-ex" (click)="closeModal()"></span>
        </div>
        <div class="card-info-body">
            <p>{{ data.description }}</p>
            <ul *ngIf="data.cardDetails">
                <li>
                    <label>game</label>: <label>{{ data.cardDetails.game }}</label>
                </li>
                <li>
                    <label>format</label>: <label>{{ data.cardDetails.format }}</label>
                </li>
                <li>
                    <label>blinds</label>: <label>{{ data.cardDetails.blinds }}</label>
                </li>
                <li>
                    <label>type</label>: <label>{{ data.cardDetails.type }}</label>
                </li>
            </ul>
        </div>
        <div class="card-info-footer" *ngIf="data.ctaButtons">
            <button
                *ngFor="let ctaBtn of data.ctaButtons; let i = index"
                class="btn btn-md w-100 mt-3 text-capitalize"
                [ngClass]="i === 0 ? 'btn-primary' : 'btn-dark'"
                (click)="onButtonClick(ctaBtn)">
                {{ ctaBtn.text }}
            </button>
        </div>
    </div>
</div>
