export class MobileTabConstants {
    public static readonly Tab: any = {
        '7CARDSTUD': {
            AVATARPOS0: '56#-0.5',
            AVATARPOS1: '82#14.5',
            AVATARPOS2: '82#43',
            AVATARPOS3: '56#59.5',
            AVATARPOS4: '23#59.5',
            AVATARPOS5: '0.5#43',
            AVATARPOS6: '0.5#14.5',
            AVATARPOS7: '23#-0.5',

            CHIPPOS0: '62#19',
            CHIPPOS1: '75#29.5',
            CHIPPOS2: '75#45.5',
            CHIPPOS3: '60.5#53',
            CHIPPOS4: '37#53',
            CHIPPOS5: '24#45.5',
            CHIPPOS6: '24#29.5',
            CHIPPOS7: '35.5#19',

            BETPOS0: '465#91',
            BETPOS1: '615#142',
            BETPOS2: '573#210',
            BETPOS3: '525#305',
            BETPOS4: '249#305',
            BETPOS5: '207#210',
            BETPOS6: '180#142',
            BETPOS7: '242#91',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#131',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '493#375',
            DEALCARDPOS4: '237#375',
            DEALCARDPOS5: '50#290',
            DEALCARDPOS6: '50#131',
            DEALCARDPOS7: '237#55',

            DEALERBTNPOS0: '55#17',
            DEALERBTNPOS1: '72#24.4',
            DEALERBTNPOS2: '73#51.5',
            DEALERBTNPOS3: '53.5#59.5',
            DEALERBTNPOS4: '41#59.5',
            DEALERBTNPOS5: '23#51.5',
            DEALERBTNPOS6: '23#24.4',
            DEALERBTNPOS7: '40#17',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#49.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '492#28',
            PLAYEROPENCARDS1: '645#114',
            PLAYEROPENCARDS2: '643#293',
            PLAYEROPENCARDS3: '459#321',
            PLAYEROPENCARDS4: '200#309',
            PLAYEROPENCARDS5: '183#322',
            PLAYEROPENCARDS6: '-3#89',
            PLAYEROPENCARDS7: '108#9',

            SEATOPENPOS0: '471#47',
            SEATOPENPOS1: '658#123',
            SEATOPENPOS2: '658#282',
            SEATOPENPOS3: '471#367',
            SEATOPENPOS4: '191#367',
            SEATOPENPOS5: '4#282',
            SEATOPENPOS6: '4#123',
            SEATOPENPOS7: '191#47',

            TIMELEFT: '332#364',
        },

        '7CARDHEADSUP': {
            AVATARPOS0: '39#0',
            AVATARPOS1: '42#60',
            CHIPPOS0: '47.5#16.5',
            CHIPPOS1: '47.5#53',
            BETPOS0: '573#210',
            BETPOS1: '207#210',
            DEALCARDPOS0: '596#232',
            DEALCARDPOS1: '260#230',
            DEALERBTNPOS0: '53#17',
            DEALERBTNPOS1: '36.1#60',
            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#49.5',
            RITMAINPOTCHIPPOS: '24#24.5',
            PLAYEROPENCARDS0: '633#293',
            PLAYEROPENCARDS1: '1#290',
            SEATOPENPOS0: '642#252',
            SEATOPENPOS1: '50#252',
            TIMELEFT: '332#364',
        },

        'COMMONSECTION': {
            ACTIONAREAPOS: '413#438#380#110',
            ACTIONAREA_ADVACTIONPOS1: '3#45#122#28',
            ACTIONAREA_ADVACTIONPOS2: '3#75#122#28',
            ACTIONAREA_ADVACTIONPOS3: '131#45#122#28',
            ACTIONAREA_ADVACTIONPOS4: '131#75#122#28',
            ACTIONAREA_ADVACTIONPOS5: '259#45#122#28',
            ACTIONAREA_ADVACTIONPOS6: '259#75#122#28',
            ACTIONAREA_ADVACTIONPOS7: '131#13#122#28',
            ACTIONAREA_ADVACTIONPOS8: '259#13#122#28',
            ACTIONAREA_CURRENTACTIONPOS1: '1#59#124#48',
            ACTIONAREA_CURRENTACTIONPOS2: '128#59#124#48',
            ACTIONAREA_CURRENTACTIONPOS3: '255#59#124#48',
            ACTIONAREA_CURRENTACTIONPOS4: '255#8#124#48',
            ACTIONAREA_FOLDTOANYBETPOS: '11#3#114#15',
            ACTIONAREA_IAMBACKBTNPOS: '0#61#124#48',
            ACTIONAREA_IAMBACKTEXTPOS: '181#51#189#52',
            ACTIONAREA_JOINWAITLISTBTNPOS: '0#61#124#48',
            ACTIONAREA_JOINWAITLISTSTATUSPOS: '181#51#189#52',
            ACTIONAREA_SLIDERCHANNELCOLOR: '66, 168, 39',
            ACTIONAREA_SLIDERPOS: '0#-1#386#58',
            AUTOPOSTBLIND: '5#497#500#15',
            AUTOREBUYPOS: '5#479#150#15',
            AVATARBALANCE_POS: '13#66#120#16',
            AVATARBASEOFFSET: '0#82',
            AVATARFOLDEDNAME_POS: '1#68#104#16',
            AVATARIMAGE_POS: '0#39#52#52',
            AVATARIMAGE_BORDER: '126#39#51#51',
            AVATAROPTIONSEATOPEN_POS: '90#34#10#10',
            AVATAROPTION_POS: '94#80#10#10',
            AVATARPLAYERNAME_POS: '13#46#120#16',
            AVATAR_ACTIONTEXT_COLOR: '255,255,255',
            AVATAR_ACTIONTIMERANIMATIONPOS: '81#53#18#16',
            AVATAR_ACTIONTIMERPOS: '0#12#40#40',
            AVATAR_ACTIONTIMERRECTPOS: '4#60#87#10',
            AVATAR_ACTIONTIMERRECTWITHANIPOS: '4#60#99#10',
            AVATAR_ACTIVEBALANCEVALUE_COLOR: '235,235,235',
            AVATAR_ACTIVEPLAYERNAME_COLOR: '255,255,255',
            AVATAR_CARDPOS: '0#0#0#0',
            AVATAR_CHATOFFPOS3: '0#0#0#0',
            AVATAR_CHATOFFPOS: '90#41',
            AVATAR_CHATOFFPOSSTUD: '0#75',
            AVATAR_CPCARDPOS: '0#0#0#0',
            AVATAR_FOLDEDBRUSH_COLOR: '51,174,218',
            AVATAR_FOLDEDCARD_TEXT_COLOR: '235,235,235',
            AVATAR_NOTESICONPOS: '5#44#15#15',
            AVATAR_PROGRESSIVEICONPOS: '2#41#58#16',
            AVATAR_PROGRESSIVETEXTPOS: '22#42#40#16',
            AVATAR_NOTESICONPOSSTUD: '105#72#15#15',
            AVATAR_PROG_NOTESICONPOS: '5#5#15#15',
            AVATAR_OUTBALANCEVALUE_COLOR: '150,150,150',
            AVATAR_OUTPLAYERNAME_COLOR: '201,201,201',
            AVATAR_PLAYEROUTINICATOR: '52#25',
            AVATAR_SEATEMPTY: '0#14#98#59',
            AVATAR_SEATOPEN: '0#14#98#59',
            AVATAR_SEATRESERVED: '0#14#98#59 ',
            AVATAR_SIZE: '176#103',
            AVATAR_TIMERPROGRESS1_COLOR: '126,211,33',
            AVATAR_TIMERPROGRESS2_COLOR: '229,201,8',
            AVATAR_TIMERPROGRESS3_COLOR: '245,104,19',
            AVATAR_TIMERPROGRESS4_COLOR: '206,8,8',
            AVATAR_TIMERPROGRESS5_COLOR: '0,0,0',
            AVATAR_WONBACKGRNRECT_COLOR: '232,74,13',
            AVATAR_WONTEXT_COLOR: '255,255,255',
            BIGCARDBACK: 'bigcardback.bmp',
            BJBUTTON: '762#26#45#52',
            ROULETTEBUTTON: '723#26#45#52',
            TEXASBONUSPOKERBUTTON: '684#26#45#52',
            FFICONBUTTON: '684#26#45#52',
            BUYINDLG_BACKGROUND_COLOR: '255,255,255',
            BUYINDLG_BORDERCOLOR: '255,255,255',
            BUYINDLG_ENCLOSED_TEXTCOLOR: '64,64,64',
            BUYINDLG_FILLCOLOR: '210,210,210',
            BUYINDLG_PLAYERBALANCE_TEXTCOLOR: '231,73,13',
            BUYINDLG_TEXTCOLOR: '64,64,64',
            BUYINDLG_TITLE_TEXTCOLOR: '64,64,64',
            BUYINDLG_USEALLBTNTXTCOLOR: '231,73,13',
            REBUYTAB_USEALLBTNTXTCOLOR: '255,255,255',
            CHECKFOLDOPTIONCOLOR: '255,255,255',
            CALLOUT: 'callout.bmp',
            CALLOUTMASK: 'calloutmsk.bmp',
            CARDBORDERSIZE: '0#3',
            CARDSIZE: '59#82',
            CASCADE_BTN_LOCATION: '567#3',
            CHATBOX: '7#426#360#58',
            CHATBOXBKCOLOR: '255,255,255',
            CHATMESSAGE: '7#492#280#11',
            CHATTYPE: 'chattype.bmp',
            COMMUNITYHAND_POS: '23.5#37.5',
            CONGANDCONN: '260#220',
            COUNTER_POS: '340#141#115#115',
            DEALERBUTTON: 'DealerControl.bmp',
            DEALERBUTTONMASK: 'DealerControlmsk.bmp',
            DEALERIMAGE: 'dealer.bmp',
            DEALERTYPE: '215#507#104#22',
            DEALMAKING_CHATAREABGCOLOR: '255,255,255',
            DEALMAKING_CHATDEFAULTMSGTEXTCOLOR: '64,64,64',
            DEALMAKING_CHATDLGBGCOLOR: '24, 112, 25',
            DEALMAKING_SUBTITLETEXTCOLOR: '64,64,64',
            DEALMAKING_RESULTVALUETEXTCOLOR: '64,64,64',
            DEALMAKING_PLACECOLUMNTEXTCOLOR: '0,0,0',
            DEALMAKING_PLACECOLUMNBGCOLOR: '255,255,255',
            DEALMAKING_CHATHIGHLIGHTMSGTEXTCOLOR: '0,155,204',
            DEALMAKING_CUSTOMPAYOUTBGCOLOR: '255,255,255',
            DEALMAKING_DEFAULTTEXTCOLOR: '64,64,64',
            DEALMAKING_DISABLEBGCOLOR: '217,217,217',
            DEALMAKING_EDITBGCOLOR: '255,255,255',
            DEALMAKING_EXITDEALBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_HELPDEALBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_SENDCHATBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_HELP_LOCATION: '72#338',
            DEALMAKING_IMG_LOCATION: '0#334#132#64',
            DEALMAKING_LISTCONTROLHEADERBORDERCOLOR: '255,255,255',
            DEALMAKING_NO_LOCATION: '9#381',
            DEALMAKING_OTHERTEXT_COLOR: '51,51,51',
            DEALMAKING_SELBGCOLOR: '220,228,240',
            DEALMAKING_PLAYERCOUNTBGCOLOR: '220,228,240',
            DEALMAKING_SELRADIOBTNTEXTCOLOR: '64,64,64',
            DEALMAKING_SUBMITDEALBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_TEXT_LOCATION: '4#408#64#29',
            DEALMAKING_UNSELRADIOBTNTEXTCOLOR: '64,64,64',
            DEALMAKING_USERCHATTEXTCOLOR: '0,155,204',
            DEALMAKING_YESNOCOLOR: '51,51,51',
            DEALMAKING_HEADERTEXTCLR: '0,0,0',
            DEALMAKING_YES_LOCATION: '9#366',
            DEALMEOUT: '5#533#89#15',
            DLRCHTTXTCOLOR: '64,64,64',
            EMBEDDED_MESSAGE_X: '225',
            EMBEDDED_MESSAGE_Y: '107',
            EMOTICONBKCOLOR: '148,130,66',
            FOLDNSHOW_CARD_POS: '93#4',
            FOLDTOANYBET2: '403#403#104#15',
            FOOTERSECTIONPOS: '0#511#787#26',
            FOOTERSECTION_AVATAR: '96#512',
            FOOTERSECTION_CASHIER: '0#512',
            FOOTERSECTION_CASINO: '72#512',
            FOOTERSECTION_DIVIDER: '724#516',
            FOOTERSECTION_HHISTORY: '24#512',
            FOOTERSECTION_PREFSEATING: '120#512',
            FOOTERSECTION_STATS: '48#512',
            FOOTERSECTION_TABLEOPTION: '623#516#98#16',
            FULLTABLE_POPUP_TEXTCOLOR: '64,64,64',
            KEYBOARD_SHORTCUT_LOC: '93#9',
            LASTGAME: '625#15#131#250',
            LEAVETABLE: 'leavetable.bmp',
            LOVETOWIN_POS: '45#440#270#40',
            MESSAGEPANELSTRINGPOS: '415#464#794#477',
            MESSAGEPANELSTRIPPOS: '407#454',
            MUCKLOSINGHAND: '5#515#173#15',
            NEXTMOVE: '366#415',
            NOCONNECTION: '265#191',
            OPENSIMILARTABLEBTN_STPOS: '0#0',
            OPTION: 'option.bmp',
            OPTIONMASK: 'option_mask.bmp',
            OTHERTLBOPT1: '747#271',
            OTHERTLBOPT2: '695#400',
            OTHERTLBOPT3: '695#383',
            OUTBUTTON: 'out.bmp',
            OUTBUTTONMASK: 'outmsk.bmp',
            PERSISTENTOPTION_CHKBTN: '7#4#198#15',
            PERSISTENTOPTION_CLOSEPOS: '570#431#224#28',
            PERSISTENTOPTION_MOREBTN: '204#24#15#15',
            PERSISTENTOPTION_OPENPOS: '570#365#224#68',
            PERSISTENTOPTION_RZBTNDN: '762#348#32#18',
            PERSISTENTOPTION_RZBTNUP: '762#381#32#18',
            PIEANIMATIONTXTSIZE: '140#40',
            PLAYERINFOFRAMECOLOR: '255,215,0',
            PLAYERINFO_ACTEDCOLOR: '255,215,0',
            PLAYERINFO_CURRENTPLAYERCOLOR: '255,0,0',
            PLAYERINFO_FOLDEDCOLOR: '191,191,191',
            PLAYERINFO_YETTOACTCOLOR: '255,215,0',
            POINTFACEDOWN: '42#17',
            POINTFACEDOWNNOAVATAR: '20#17',
            POPUPOFFSET: '42#14',
            PRZEINFODLG_POS: '647#18',
            QUICKDEPOSIT_IMGBTN_TXTCOLOR: '255,255,255',
            RAKEPOS: '40#20',
            READY: '192#162#415#40',
            REBUY: 'rebuy.bmp',
            REBUYTAB_HYPERLINK_TEXTCOLOR: '255,255,255',
            RESERVERSEAT: 'reserved.bmp',
            RESERVERSEATMASK: 'reserved_mask.bmp',
            SIDEPOTBG: '248#117',
            SIDEPOTPOS0: '50#27',
            SIDEPOTPOS1: '50#27',
            SIDEPOTPOS2: '50#27',
            SIDEPOTPOS3: '50#27',
            SIDEPOTPOS4: '50#27',
            SIDEPOTPOS5: '50#27',
            SIDEPOTPOS6: '50#27',
            SIDEPOTPOS7: '50#27',
            SIDEPOTPOS8: '50#27',
            SIDEPOTPOS9: '50#27',
            SIDEPOTPOS10: '65#27',
            SIDEPOTTITLE: '350#98#98#9',
            SIDEPOTTITLETEXTCOLOR: '255,255,255',
            SLIDER: '0#16#378#43',
            SLIDERBGCOLOR: '0,0,0',
            SLIDERDLRIMGRECTPOS: '249#37#12#21',
            SLIDEREDITBGCOLOR: '255,255,204',
            SLIDEREDITCTRLRECTPOS: '270#36#100#21',
            SLIDERFRAMECOLOR: '58,215,37',
            SLIDERMINUSCTRLRECTPOS: '4#32#27#27',
            SLIDERPLUSCTRLRECTPOS: '213#32#27#27',
            SLIDERSLIDERCTRLRECTPOS: '32#37#180#19',
            SLIDERTABIMGCORRECTOFFSET: '3#',
            SLIDERTABIMGOFFSETPOS: '98#3',
            SLIDERTABIMGSIZE: '215#19',
            SMALLCARDBACK1: 'smallcard1.bmp',
            SMALLCARDBACK2: 'smallcard2.bmp',
            SMALLCARDBACK3: 'smallcard3.bmp',
            SMALLCARDBACK4: 'smallcard4.bmp',
            SNGJP1STPLACETXT: '4#1#74#15',
            SNGJP1STPLACEVAL: '69#1#78#15',
            SNGJP1LINERPRIZETXT: '211#162#185#38',
            SNGJP1LINERPRIZETXTEND: '211#212',
            SNGJP1LINERPRIZEVAL: '396#162#185#38',
            SNGJP1LINERPRIZEVALEND: '396#212',
            SNGJP1LINERWIN1TXT: '211#215#185#29',
            SNGJP1LINERWIN1VAL: '396#215#185#29',
            SNGJP2LINERPRIZETXT: '339#153#173#29',
            SNGJP2LINERPRIZETXTEND: '339#230',
            SNGJP2LINERPRIZEVAL: '494#153#109#29',
            SNGJP2LINERPRIZEVALEND: '494#230',
            SNGJP2LINERWIN1TXT: '339#200#173#20',
            SNGJP2LINERWIN1VAL: '494#200#109#20',
            SNGJP2LINERWIN2TXT: '339#229#173#20',
            SNGJP2LINERWIN2VAL: '494#229#109#20',
            SNGJP2NDPLACETXT: '4#16#74#15',
            SNGJP2NDPLACEVAL: '69#16#78#15',
            SNGJP3LINERPRIZETXT: '339#139#173#28',
            SNGJP3LINERPRIZETXTEND: '339#240',
            SNGJP3LINERPRIZEVAL: '494#139#109#28',
            SNGJP3LINERPRIZEVALEND: '603#240',
            SNGJP3LINERWIN1TXT: '339#186#173#20',
            SNGJP3LINERWIN1VAL: '494#186#109#20',
            SNGJP3LINERWIN2TXT: '339#215#173#20',
            SNGJP3LINERWIN2VAL: '494#215#109#20',
            SNGJP3LINERWIN3TXT: '339#243#173#20',
            SNGJP3LINERWIN3VAL: '494#243#109#20',
            SNGJP3RDPLACETXT: '4#31#74#15',
            SNGJP3RDPLACEVAL: '69#31#78#15',
            SNGJP4LINERPRIZETXT: '339#131#173#23',
            SNGJP4LINERPRIZETXTEND: '339#240',
            SNGJP4LINERPRIZEVAL: '494#131#109#23',
            SNGJP4LINERPRIZEVALEND: '494#240',
            SNGJP4LINERWIN1TXT: '339#173#173#17',
            SNGJP4LINERWIN1VAL: '494#173#109#17',
            SNGJP4LINERWIN2TXT: '339#199#173#17',
            SNGJP4LINERWIN2VAL: '494#199#109#17',
            SNGJP4LINERWIN3TXT: '339#225#173#17',
            SNGJP4LINERWIN3VAL: '494#225#109#17',
            SNGJP4LINERWIN4TXT: '339#250#173#17',
            SNGJP4LINERWIN4VAL: '494#250#109#17',
            SNGJPDELTACOL1X1: '122#0',
            SNGJPDELTACOL1X2: '119#0',
            SNGJPDELTACOL2X1: '101#0',
            SNGJP4THPLACETXT: '4#46#74#15',
            SNGJP4THPLACEVAL: '69#46#78#15',
            SNGJPBLINDSLINELEFT: '1#50',
            SNGJPBLINDSLINERIGHT: '130#50',
            SNGJPBLINDSTXT: '-1#34#72#16',
            SNGJPBLINDSVALUE: '71#34#60#16',
            SNGJPBOUNTYAWARDINFO: '575#0',
            SNGJPBOUNTYTEXT: '214,214,214',
            SNGJPBOUNTYTXT: '4#61#74#15',
            SNGJPBOUNTYVAL: '69#61#78#15',
            SNGJPBUNREGTEXTCOLOR: '255,255,255',
            SNGJPDURATIONLINELEFT: '1#66',
            SNGJPDURATIONLINERIGHT: '130#66',
            SNGJPDURATIONTXT: '-1#50#72#16',
            SNGJPDURATIONVALUE: '71#50#60#16',
            SNGJPHHLINKFILLCOLOR: '19,23,24',
            SNGJPINFOFILLCOLOR: '39,40,42',
            SNGJPINFOLINECOLOR: '75,75,75',
            SNGJPLEVELLINELEFT: '1#34',
            SNGJPLEVELLINERIGHT: '130#34',
            SNGJPLEVELTXT: '-1#18#72#16',
            SNGJPLEVELVALUE: '71#18#60#16',
            SNGJPLOGOBGPOS: '127#113',
            SNGJPMULBGCOLOR: '117,68,73',
            USD: '$',
            EUR: '€',
            CAD: 'C$',
            GBP: '£',
            RUB: '₽',
            SNGJPMULFADEDBGCOLOR: '117,68,73',
            SNGJPMULTIPIER: '148#172#191#45',
            SNGJPMULTIPIEREND: '166#228',
            SNGJPMULTIPLIERTEXT: '254,111,46',
            SNGJPMULTOTALPRIZERECT: '269#181#251#20',
            SNGJPNEXTBLINDSTXT: '-1#65#85#16',
            SNGJPNEXTBLINDSVAL: '71#65#60#16',
            SNGJPPAYOUTDATA: '255,255,255',
            SNGJPPRIZEINFODLG_LINE: '0#15#128#1',
            SNGJPPRIZEINFOTEXT: '184,184,184',
            SNGJPPRIZESUMMARYCOLOR: '35,224,104',
            SNGJPPRIZETEXT: '255,255,255',
            SNGJPREGISTERBUTTON: '131#56#177#51',
            SNGJPRENDERRECT: '118#104#561#200',
            SNGJPSINGLEMULTIPIER: '234#142#327#49',
            SNGJPSINGLEMULTIPIEREND: '121#188',
            SNGJPSINGLEPRIZE: '160#211#466#46',
            SNGJPSINGLEPRIZEEND: '183#253',
            SNGJPWATCHERCOUNTONTABLETEXT: '255,255,255',
            SNGJPTOTALPRIZEBUTTON: '629#0',
            SOCIAL_DLG_AVATAR_RECT_COLOR: '0,0,0',
            SOCIAL_DLG_BACKGROUND_COLOR: '64,64,64',
            SOCIAL_DLG_ITEM_LINE_COLOR: '115,115,115',
            SOCIAL_DLG_ITEM_TAG_TEXT_COLOR: '135,135,135',
            SOCIAL_DLG_ITEM_TEXT_COLOR: '210,210,210',
            SOCIAL_DLG_SELITEM_FILLCOLOR: '79,79,79',
            SOCIAL_DLG_TITLE_TEXT_COLOR: '255,255,255',
            SPLCHTTXTCOLOR: '64,64,64',
            TABBEDAREA_DISBLCHATPOS: '170#8',
            TABBEDAREA_DISBLCHATSIZE: '135#18',
            TABBEDAREA_EMOTICONCOUNT: '18',
            TABBEDAREA_ICONBTNPOS: '350#93',
            TABBEDAREA_ICONBTNSIZE: '26#16',
            TABBEDAREA_INSETRECT: '0#21#404#90',
            TABBEDAREA_MINBTNPOS: '0#0',
            TABBEDAREA_MINBTNSIZE: '9#21',
            TABBEDAREA_MINIMIZED: '408#404#9#21',
            TABBEDAREA_MSGDISPPOS: '5#24',
            TABBEDAREA_MSGDISPSIZE: '396#61',
            TABBEDAREA_MSGEDITPOS: '5#94',
            TABBEDAREA_MSGEDITSIZE: '287#16',
            TABBEDAREA_NORMAL: '1#440#404#111',
            TABBEDAREA_NOTESEDITPOS: '7#31',
            TABBEDAREA_NOTESEDITSIZE: '385#60',
            TABBEDAREA_OPTNBTNPOS: '383#93',
            TABBEDAREA_OPTNBTNSIZE: '16#16',
            TABBEDAREA_CHATSETBTNSIZE: '16#16',
            TABBEDAREA_PLYIDSCMBPOS: '3#7',
            TABBEDAREA_PLYIDSCMBSIZE: '130#20',
            TABBEDAREA_POSBTNACCREFRESH: '382#25',
            TABBEDAREA_POSBTNADDMORECHIPS: '279#24',
            TABBEDAREA_POSBTNREBUY: '326#66',
            TABBEDAREA_POSBTNUSEALL: '7#66',
            TABBEDAREA_POSCHKAUTOREBUY: '291#50',
            TABBEDAREA_POSCHKDOUBLEREBUY: '9#66',
            TABBEDAREA_POSCHKSINGLEREBUY: '9#50',
            TABBEDAREA_POSEDITCHIPAMT: '165#69',
            TABBEDAREA_POSHYPERLINKCASHIER: '323#25',
            TABBEDAREA_POSHYPERLINKEXCHANGERATE: '219#25',
            TABBEDAREA_POSHYPERLINKTOURNEYFEE: '20#68',
            TABBEDAREA_POSREBUYERRORMSGOKBTN: '326#66',
            TABBEDAREA_POSTXTDECIDEAMT: '9#49',
            TABBEDAREA_POSTXTMAXBUYIN: '9#25',
            TABBEDAREA_POSTXTMINBUYIN: '9#9',
            TABBEDAREA_POSTXTYOURBANKROLL: '180#9',
            TABBEDAREA_POSVALMAXBUYIN: '69#25',
            TABBEDAREA_POSVALMINBUYIN: '69#9',
            TABBEDAREA_POSVALYOURBANKROLL: '307#9',
            TABBEDAREA_QDDLGRECT: '5#419#399#89',
            TABBEDAREA_REBUYERRORMSGSIZE: '404#90',
            TABBEDAREA_REBUYERRORMSGSTARTPOS: '0#0',
            TABBEDAREA_RESTORE_BTN_POS: '0#440',
            TABBEDAREA_SAVENOTESPOS: '325#1',
            TABBEDAREA_SAVENOTESSIZE: '74#26',
            TABBEDAREA_SENDBTNPOS: '300#93',
            TABBEDAREA_SENDBTNSIZE: '46#16',
            TABBEDAREA_CHATSETTINGSPOS: '378#93',
            TABBEDAREA_SIZBTNACCREFRESH: '14#14',
            TABBEDAREA_SIZBTNADDMORECHIPS: '100#23',
            TABBEDAREA_SIZBTNREBUY: '74#26',
            TABBEDAREA_SIZBTNUSEALL: '72#26',
            TABBEDAREA_SIZEDITCHIPAMT: '80#17',
            TABBEDAREA_SIZHYPERLINKCASHIER: '54#14',
            TABBEDAREA_SIZHYPERLINKEXCHANGERATE: '90#14',
            TABBEDAREA_TABBTNSIZE: '37#21',
            TABBEDAREA_TABBUTTON_OVERLAP: '1#0',
            TABBEDAREA_WTHLSTCMBPOS: '138#8',
            TABBEDAREA_WTHLSTCMBSIZE: '40#14',
            TABLECASHIER: 'gamecashier.bmp',
            TABLEHELP: 'help.bmp',
            TABLELOBBY: 'Lobby.bmp',
            TABS_IN_CASHIER: '281#75#190#27',
            TABSELECTEDCOLOR: '140,248,85',
            TBTTIMERIMAGEPOS: '0#372',
            TBTTIMERPOS: '46#380#44#15',
            TEXT_COLOR: '175,175,175',
            THANDNO: '6#25#131#15',
            THISGAME: '625#2#131#250',
            TILE_BTN_LOCATION: '598#3',
            TIMEBANK: '375#28',
            TIMELEFTTEXTCOLOR: '252,216,150',
            TITLEBARBUTTONSAREAWIDTH: '375',
            TLBADVANCEOPTIONSTEXTCOLOR: '0,0,0',
            TLBHANDSTRENGTHTEXTCOLOR: '0,0,0',
            TLBHELP: '689#484',
            TLBLEAVETABLE: '689#528',
            TLBLOBBY: '724 #546',
            TLBOPTIONSTEXTCOLOR: '255,255,255',
            TLBTEXTCOLOR: '199,199,199',
            TOOLTIP_REBUY_CASHIER: 'PARTY_POKER_GC_BIN_REBUYCASHIER',
            TOTALPOTAMOUNT: '50#33#192#20',
            TOTALPRIZEBTNTEXTCLR: '184,184,184',
            TOURNEYINFO2: '6#15#131#30',
            TOURNEYINFO: '6#4#131#30',
            TOURVEYCONN: 'tourn_congrats.gif',
            TURNINDICATOR: '425#245#57#13',
            USERCHATTXTCOLOR: '0,155,204',
            VIEWDEAL_IMG_LOCATION: '332#249#136#30',
            VIEWHANDSTRENGTH: '6#380#189#15',
            VIEW_DEALMAKING_LOCATION: '332#255#136#32',
            WAITBB: '350#443',
            YOUAREREGISTERED: '177#178#445#30',
            YOURTURN: '753#336',
            YOURTURNTXT: '754#346',
            PROGRESSBARBG: '230,230,230',
            PROGRESSBAR: '232,73,13',
            PROGRESSBARRECT: '25#220#345#6',
            NOTIFICATIONRECTPOS: '260#2#548#27',
            BTNDISMISSCLR: '183,183,183',
            REGRECTPOS: '410#4',
            BTNRECTPOS: '480#4',
            CHATAREA_PLAYERCHATPOS: '200#30',
            CHATAREA_OBSERVERCHATPOS: '200#50',
            CHATAREA_CLOSEBTNPOS: '380#5',
            CHATAREA_SAVEBTNPOS: '310#63',
            CHATAREA_DLRTEXTPOS: '15#10',
            CHATAREA_ALLACTIONPOS: '30#30',
            CHATAREA_DLRSUMMARYPOS: '30#50',
            CHATAREA_DLRSILENTPOS: '30#70',
            CHATAREA_CHATOPTNTXTPOS: '178#10',
            CHATAREA_DLRTEXTSIZE: '140#17',
            CHATAREA_CLOSEBTNSIZE: '25#25',
            CHATAREA_SAVEBTNSIZE: '74#26',
            CHATAREA_CHATOPTNTXTSIZE: '140#17',
            AVATAR_BORDERBLINKING_COLOR: '192,192,192',
            TIMEBANK_TEXTLOC: '21#3',
            FOLDBTNTXTCOLOR: '255,255,255',
        },

        'TEXASHOLDEM10MAX': {
            AVATARPOS0: '56#0',
            AVATARPOS1: '75.5#10',
            AVATARPOS2: '82.5#29.5',
            AVATARPOS3: '75#49.5',
            AVATARPOS4: '53#59.5',
            AVATARPOS5: '27#59.5',
            AVATARPOS6: '4#49.5',
            AVATARPOS7: '0#29.5',
            AVATARPOS8: '5#10',
            AVATARPOS9: '24#0',

            CHIPPOS0: '61#19',
            CHIPPOS1: '75#28.2',
            CHIPPOS2: '74#40.5',
            CHIPPOS3: '72.5#48',
            CHIPPOS4: '59#52',
            CHIPPOS5: '39#52',
            CHIPPOS6: '24#48',
            CHIPPOS7: '24#40.5',
            CHIPPOS8: '23#28',
            CHIPPOS9: '35.5#19',

            BETPOS0: '465#100',
            BETPOS1: '570#129',
            BETPOS2: '647#197',
            BETPOS3: '568#256',
            BETPOS4: '443#282',
            BETPOS5: '330#282',
            BETPOS6: '208#256',
            BETPOS7: '132#197',
            BETPOS8: '208#129',
            BETPOS9: '313#100',

            DEALCARDPOS0: '500#55',
            DEALCARDPOS1: '655#110',
            DEALCARDPOS2: '680#216',
            DEALCARDPOS3: '650#323',
            DEALCARDPOS4: '467#375',
            DEALCARDPOS5: '263#375',
            DEALCARDPOS6: '80#323',
            DEALCARDPOS7: '50#216',
            DEALCARDPOS8: '75#110',
            DEALCARDPOS9: '230#55',

            DEALERBTNPOS0: '55#18',
            DEALERBTNPOS1: '70#24',
            DEALERBTNPOS2: '75#35.5',
            DEALERBTNPOS3: '69.5#54.5',
            DEALERBTNPOS4: '51#58',
            DEALERBTNPOS5: '44#58',
            DEALERBTNPOS6: '26#54',
            DEALERBTNPOS7: '21#35',
            DEALERBTNPOS8: '26#22',
            DEALERBTNPOS9: '42#18',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#43.8',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '650#40',
            PLAYEROPENCARDS2: '683#185',
            PLAYEROPENCARDS3: '659#300',
            PLAYEROPENCARDS4: '455#337',
            PLAYEROPENCARDS5: '209#339',
            PLAYEROPENCARDS6: '18#286',
            PLAYEROPENCARDS7: '6#180',
            PLAYEROPENCARDS8: '28#49',
            PLAYEROPENCARDS9: '219#35',
            SEATOPENPOS0: '478#47',
            SEATOPENPOS1: '633#102',
            SEATOPENPOS2: '658#208',
            SEATOPENPOS3: '626#315',
            SEATOPENPOS4: '445#367',
            SEATOPENPOS5: '217#367',
            SEATOPENPOS6: '34#315',
            SEATOPENPOS7: '4#208',
            SEATOPENPOS8: '29#102',
            SEATOPENPOS9: '184#47',
            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM8MAX': {
            AVATARPOS0: '38#16',
            AVATARPOS1: '77.5#33.5',
            AVATARPOS2: '77.5#50',
            AVATARPOS3: '79.5#70.5',
            AVATARPOS4: '39#100',
            AVATARPOS5: '0.5#70.5',
            AVATARPOS6: '0#50',
            AVATARPOS7: '0#33.5',

            CHIPPOS0: '50#21',
            CHIPPOS1: '71.5#28',
            CHIPPOS2: '71.5#50',
            CHIPPOS3: '71.5#59.5',
            CHIPPOS4: '50#63',
            CHIPPOS5: '29.5#59.5',
            CHIPPOS6: '29#50',
            CHIPPOS7: '29#28',

            BETPOS0: '524#100',
            BETPOS1: '620#150',
            BETPOS2: '620#250',
            BETPOS3: '485#305',
            BETPOS4: '297#305',
            BETPOS5: '156#250',
            BETPOS6: '156#150',
            BETPOS7: '260#100',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#131',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '493#375',
            DEALCARDPOS4: '237#375',
            DEALCARDPOS5: '50#290',
            DEALCARDPOS6: '50#131',
            DEALCARDPOS7: '237#55',

            DEALERBTNPOS0: '61#18',
            DEALERBTNPOS1: '80#34.5',
            DEALERBTNPOS2: '83#51',
            DEALERBTNPOS3: '85#72',
            DEALERBTNPOS4: '38.5#75.5',
            DEALERBTNPOS5: '12#72',
            DEALERBTNPOS6: '14#51',
            DEALERBTNPOS7: '17#34.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '492#28',
            PLAYEROPENCARDS1: '645#114',
            PLAYEROPENCARDS2: '643#293',
            PLAYEROPENCARDS3: '459#321',
            PLAYEROPENCARDS4: '200#309',
            PLAYEROPENCARDS5: '183#322',
            PLAYEROPENCARDS6: '-3#89',
            PLAYEROPENCARDS7: '108#9',

            SEATOPENPOS0: '471#47',
            SEATOPENPOS1: '658#123',
            SEATOPENPOS2: '658#282',
            SEATOPENPOS3: '471#367',
            SEATOPENPOS4: '191#367',
            SEATOPENPOS5: '4#282',
            SEATOPENPOS6: '4#123',
            SEATOPENPOS7: '191#47',

            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM9MAX': {
            AVATARPOS0: '56#17',
            AVATARPOS1: '77.5#33.5',
            AVATARPOS2: '77.5#50',
            AVATARPOS3: '79.5#70.5',
            AVATARPOS4: '39#100',
            AVATARPOS5: '0.5#70.5',
            AVATARPOS6: '0#50',
            AVATARPOS7: '0#33.5',
            AVATARPOS8: '19.5#17',

            CHIPPOS0: '65#20.5',
            CHIPPOS1: '71.5#28',
            CHIPPOS2: '71.5#50',
            CHIPPOS3: '71.5#59.5',
            CHIPPOS4: '50#63',
            CHIPPOS5: '29.5#59.5',
            CHIPPOS6: '29#50',
            CHIPPOS7: '29#28',
            CHIPPOS8: '36#20.5',

            BETPOS0: '465#100',
            BETPOS1: '570#129',
            BETPOS2: '647#197',
            BETPOS3: '617#263',
            BETPOS4: '360#287',
            BETPOS5: '167#263',
            BETPOS6: '132#197',
            BETPOS7: '208#129',
            BETPOS8: '313#100',

            DEALCARDPOS0: '500#55',
            DEALCARDPOS1: '655#118',
            DEALCARDPOS2: '680#220',
            DEALCARDPOS3: '623#351',
            DEALCARDPOS4: '365#375',
            DEALCARDPOS5: '116#351',
            DEALCARDPOS6: '50#220',
            DEALCARDPOS7: '75#118',
            DEALCARDPOS8: '230#55',

            DEALERBTNPOS0: '53#17.5',
            DEALERBTNPOS1: '80#34.5',
            DEALERBTNPOS2: '83#51',
            DEALERBTNPOS3: '85#72',
            DEALERBTNPOS4: '38.5#75.5',
            DEALERBTNPOS5: '12#72',
            DEALERBTNPOS6: '14#51',
            DEALERBTNPOS7: '17#34.5',
            DEALERBTNPOS8: '44#17.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',
            NEXTMOVE: '220#415',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '650#40',
            PLAYEROPENCARDS2: '683#185',
            PLAYEROPENCARDS3: '659#300',
            PLAYEROPENCARDS4: '349#347',
            PLAYEROPENCARDS5: '18#286',
            PLAYEROPENCARDS6: '6#180',
            PLAYEROPENCARDS7: '28#49',
            PLAYEROPENCARDS8: '219#35',

            SEATOPENPOS0: '478#47',
            SEATOPENPOS1: '633#110',
            SEATOPENPOS2: '658#212',
            SEATOPENPOS3: '601#343',
            SEATOPENPOS4: '343#367',
            SEATOPENPOS5: '70#343',
            SEATOPENPOS6: '4#212',
            SEATOPENPOS7: '29#110',
            SEATOPENPOS8: '184#47   ',

            SLIDER: '200#399#140#35',
            TIMELEFT: '209#364',
        },

        'TEXASHOLDEM7MAX': {
            AVATARPOS0: '56#17',
            AVATARPOS1: '77.5#33.5',
            AVATARPOS2: '79.5#70.5',
            AVATARPOS3: '39#100',
            AVATARPOS4: '0.5#70.5',
            AVATARPOS5: '0#33.5',
            AVATARPOS6: '19.5#17',

            CHIPPOS0: '65#20.5',
            CHIPPOS1: '71.5#28',
            CHIPPOS2: '71.5#59.5',
            CHIPPOS3: '50#63',
            CHIPPOS4: '29.5#59.5',
            CHIPPOS5: '29#28',
            CHIPPOS6: '36#20.5',

            BETPOS0: '524#100',
            BETPOS1: '620#150',
            BETPOS2: '620#250',
            BETPOS3: '360#287',
            BETPOS4: '156#250',
            BETPOS5: '156#150',
            BETPOS6: '260#100',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#131',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '365#375',
            DEALCARDPOS4: '50#290',
            DEALCARDPOS5: '50#131',
            DEALCARDPOS6: '237#55',

            DEALERBTNPOS0: '53#17.5',
            DEALERBTNPOS1: '80#34.5',
            DEALERBTNPOS2: '85#72',
            DEALERBTNPOS3: '38.5#75.5',
            DEALERBTNPOS4: '12#72',
            DEALERBTNPOS5: '17#34.5',
            DEALERBTNPOS6: '44#17.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '492#28',
            PLAYEROPENCARDS1: '645#114',
            PLAYEROPENCARDS2: '643#293',
            PLAYEROPENCARDS3: '349#347',
            PLAYEROPENCARDS4: '183#322',
            PLAYEROPENCARDS5: '-3#89',
            PLAYEROPENCARDS6: '108#9',

            SEATOPENPOS0: '498#55',
            SEATOPENPOS1: '652#132',
            SEATOPENPOS2: '652#291',
            SEATOPENPOS3: '350#385',
            SEATOPENPOS4: '48#291',
            SEATOPENPOS5: '48#132',
            SEATOPENPOS6: '199#55',

            TIMELEFT: '332#36',
        },

        'TEXASHOLDEM5MAX': {
            AVATARPOS0: '77.5#33.5',
            AVATARPOS1: '78.5#70.5',
            AVATARPOS2: '39#100',
            AVATARPOS3: '0.5#70.5',
            AVATARPOS4: '0#33.5',

            CHIPPOS0: '71.5#28.5',
            CHIPPOS1: '71.5#62',
            CHIPPOS2: '50#68',
            CHIPPOS3: '29.5#62',
            CHIPPOS4: '29#28.5',

            BETPOS0: '465#100',
            BETPOS1: '647#197',
            BETPOS2: '360#287',
            BETPOS3: '132#197',
            BETPOS4: '313#100',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#216',
            DEALCARDPOS2: '365#375',
            DEALCARDPOS3: '50#216',
            DEALCARDPOS4: '237#55',

            DEALERBTNPOS0: '80#34.5',
            DEALERBTNPOS1: '85#72',
            DEALERBTNPOS2: '38.5#75.5',
            DEALERBTNPOS3: '12#72',
            DEALERBTNPOS4: '17#34.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',
            NEXTMOVE: '220#415',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '683#185',
            PLAYEROPENCARDS2: '349#347',
            PLAYEROPENCARDS3: '6#180',
            PLAYEROPENCARDS4: '219#35',

            SEATOPENPOS0: '447#47',
            SEATOPENPOS1: '658#208',
            SEATOPENPOS2: '343#367',
            SEATOPENPOS3: '4#208',
            SEATOPENPOS4: '191#47',

            SLIDER: '200#399#140#35',
            TIMELEFT: '209#364',
        },

        'TEXASHOLDEM3MAX': {
            AVATARPOS0: '77.5#30',
            AVATARPOS1: '39#100',
            AVATARPOS2: '0#30',

            CHIPPOS0: '71.5#25',
            CHIPPOS1: '50#63',
            CHIPPOS2: '29#25',

            BETPOS0: '465#100',
            BETPOS1: '360#287',
            BETPOS2: '313#100',

            DEALCARDPOS0: '655#118',
            DEALCARDPOS1: '365#375',
            DEALCARDPOS2: '75#118',

            DEALERBTNPOS0: '80#31',
            DEALERBTNPOS1: '38.5#74.5',
            DEALERBTNPOS2: '17#31',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',
            NEXTMOVE: '220#415',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '349#347',
            PLAYEROPENCARDS2: '219#35',

            SEATOPENPOS0: '609#110',
            SEATOPENPOS1: '343#367',
            SEATOPENPOS2: '29#110',

            SLIDER: '200#399#140#35',
            TIMELEFT: '209#364',
        },

        'TEXASHOLDEM6MAX': {
            AVATARPOS0: '38#18',
            AVATARPOS1: '77.5#33.5',
            AVATARPOS2: '79.5#70.5',
            AVATARPOS3: '39#100',
            AVATARPOS4: '0.5#70.5',
            AVATARPOS5: '0#33.5',

            CHIPPOS0: '50#21',
            CHIPPOS1: '71.5#28.5',
            CHIPPOS2: '71.5#59.5',
            CHIPPOS3: '50#63',
            CHIPPOS4: '29.5#59.5',
            CHIPPOS5: '29#28.5',

            BETPOS0: '465#100',
            BETPOS1: '647#197',
            BETPOS2: '453#262',
            BETPOS3: '320#262',
            BETPOS4: '142#197',
            BETPOS5: '313#90',

            DEALCARDPOS0: '365#55',
            DEALCARDPOS1: '680#122',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '365#375',
            DEALCARDPOS4: '50#290',
            DEALCARDPOS5: '50#122',

            DEALERBTNPOS0: '61#18',
            DEALERBTNPOS1: '80#34.5',
            DEALERBTNPOS2: '85#72',
            DEALERBTNPOS3: '38.5#74.5',
            DEALERBTNPOS4: '12#72',
            DEALERBTNPOS5: '17#34.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '683#185',
            PLAYEROPENCARDS2: '455#337',
            PLAYEROPENCARDS3: '209#339',
            PLAYEROPENCARDS4: '6#180',
            PLAYEROPENCARDS5: '219#35',

            SEATOPENPOS0: '319#47',
            SEATOPENPOS1: '658#114',
            SEATOPENPOS2: '658#282',
            SEATOPENPOS3: '343#367',
            SEATOPENPOS4: '4#282',
            SEATOPENPOS5: '4#114',

            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM4MAX': {
            AVATARPOS0: '38#18',
            AVATARPOS1: '77.5#33.5',
            AVATARPOS2: '39#100',
            AVATARPOS3: '0#33.5',

            CHIPPOS0: '50#21',
            CHIPPOS1: '71.5#28.5',
            CHIPPOS2: '50#63',
            CHIPPOS3: '29#28.5',

            BETPOS0: '465#100',
            BETPOS1: '443#282',
            BETPOS2: '330#282',
            BETPOS3: '313#100',

            DEALCARDPOS0: '365#55',
            DEALCARDPOS1: '680#216',
            DEALCARDPOS2: '365#375',
            DEALCARDPOS3: '55#216',

            DEALERBTNPOS0: '61#18',
            DEALERBTNPOS1: '80#34.5',
            DEALERBTNPOS2: '38.5#75.5',
            DEALERBTNPOS3: '17#34.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '455#337',
            PLAYEROPENCARDS2: '209#339',
            PLAYEROPENCARDS3: '219#35',

            SEATOPENPOS0: '319#47',
            SEATOPENPOS1: '658#208',
            SEATOPENPOS2: '343#367',
            SEATOPENPOS3: '4#208',

            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM2MAX': {
            AVATARPOS0: '38#18',
            AVATARPOS1: '39#100',

            CHIPPOS0: '50#21',
            CHIPPOS1: '50#63',

            BETPOS0: '568#256',
            BETPOS1: '208#256',

            DEALCARDPOS0: '365#55',
            DEALCARDPOS1: '365#375',

            DEALERBTNPOS0: '61#18',
            DEALERBTNPOS1: '38.5#75.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '659#300',
            PLAYEROPENCARDS1: '18#286',

            SEATOPENPOS0: '323#47',
            SEATOPENPOS1: '323#367',
        },
    };
    public static readonly Tablet: any = {
        '7CARDSTUD': {
            AVATARPOS0: '56#-0.5',
            AVATARPOS1: '82#14.5',
            AVATARPOS2: '82#43',
            AVATARPOS3: '56#59.5',
            AVATARPOS4: '23#59.5',
            AVATARPOS5: '0.5#43',
            AVATARPOS6: '0.5#14.5',
            AVATARPOS7: '23#-0.5',

            CHIPPOS0: '62#19',
            CHIPPOS1: '75#29.5',
            CHIPPOS2: '75#45.5',
            CHIPPOS3: '60.5#53',
            CHIPPOS4: '37#53',
            CHIPPOS5: '24#45.5',
            CHIPPOS6: '24#29.5',
            CHIPPOS7: '35.5#19',

            BETPOS0: '465#91',
            BETPOS1: '615#142',
            BETPOS2: '573#210',
            BETPOS3: '525#305',
            BETPOS4: '249#305',
            BETPOS5: '207#210',
            BETPOS6: '180#142',
            BETPOS7: '242#91',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#131',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '493#375',
            DEALCARDPOS4: '237#375',
            DEALCARDPOS5: '50#290',
            DEALCARDPOS6: '50#131',
            DEALCARDPOS7: '237#55',

            DEALERBTNPOS0: '55#17',
            DEALERBTNPOS1: '72#24.4',
            DEALERBTNPOS2: '73#51.5',
            DEALERBTNPOS3: '53.5#59.5',
            DEALERBTNPOS4: '41#59.5',
            DEALERBTNPOS5: '23#51.5',
            DEALERBTNPOS6: '23#24.4',
            DEALERBTNPOS7: '40#17',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#49.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '492#28',
            PLAYEROPENCARDS1: '645#114',
            PLAYEROPENCARDS2: '643#293',
            PLAYEROPENCARDS3: '459#321',
            PLAYEROPENCARDS4: '200#309',
            PLAYEROPENCARDS5: '183#322',
            PLAYEROPENCARDS6: '-3#89',
            PLAYEROPENCARDS7: '108#9',

            SEATOPENPOS0: '471#47',
            SEATOPENPOS1: '658#123',
            SEATOPENPOS2: '658#282',
            SEATOPENPOS3: '471#367',
            SEATOPENPOS4: '191#367',
            SEATOPENPOS5: '4#282',
            SEATOPENPOS6: '4#123',
            SEATOPENPOS7: '191#47',

            TIMELEFT: '332#364',
        },

        '7CARDHEADSUP': {
            AVATARPOS0: '39#0',
            AVATARPOS1: '42#60',
            CHIPPOS0: '47.5#16.5',
            CHIPPOS1: '47.5#53',
            BETPOS0: '573#210',
            BETPOS1: '207#210',
            DEALCARDPOS0: '596#232',
            DEALCARDPOS1: '260#230',
            DEALERBTNPOS0: '53#17',
            DEALERBTNPOS1: '36.1#60',
            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#49.5',
            RITMAINPOTCHIPPOS: '24#24.5',
            PLAYEROPENCARDS0: '633#293',
            PLAYEROPENCARDS1: '1#290',
            SEATOPENPOS0: '642#252',
            SEATOPENPOS1: '50#252',
            TIMELEFT: '332#364',
        },

        'COMMONSECTION': {
            ACTIONAREAPOS: '413#438#380#110',
            ACTIONAREA_ADVACTIONPOS1: '3#45#122#28',
            ACTIONAREA_ADVACTIONPOS2: '3#75#122#28',
            ACTIONAREA_ADVACTIONPOS3: '131#45#122#28',
            ACTIONAREA_ADVACTIONPOS4: '131#75#122#28',
            ACTIONAREA_ADVACTIONPOS5: '259#45#122#28',
            ACTIONAREA_ADVACTIONPOS6: '259#75#122#28',
            ACTIONAREA_ADVACTIONPOS7: '131#13#122#28',
            ACTIONAREA_ADVACTIONPOS8: '259#13#122#28',
            ACTIONAREA_CURRENTACTIONPOS1: '1#59#124#48',
            ACTIONAREA_CURRENTACTIONPOS2: '128#59#124#48',
            ACTIONAREA_CURRENTACTIONPOS3: '255#59#124#48',
            ACTIONAREA_CURRENTACTIONPOS4: '255#8#124#48',
            ACTIONAREA_FOLDTOANYBETPOS: '11#3#114#15',
            ACTIONAREA_IAMBACKBTNPOS: '0#61#124#48',
            ACTIONAREA_IAMBACKTEXTPOS: '181#51#189#52',
            ACTIONAREA_JOINWAITLISTBTNPOS: '0#61#124#48',
            ACTIONAREA_JOINWAITLISTSTATUSPOS: '181#51#189#52',
            ACTIONAREA_SLIDERCHANNELCOLOR: '66, 168, 39',
            ACTIONAREA_SLIDERPOS: '0#-1#386#58',
            AUTOPOSTBLIND: '5#497#500#15',
            AUTOREBUYPOS: '5#479#150#15',
            AVATARBALANCE_POS: '13#66#120#16',
            AVATARBASEOFFSET: '0#82',
            AVATARFOLDEDNAME_POS: '1#68#104#16',
            AVATARIMAGE_POS: '0#39#52#52',
            AVATARIMAGE_BORDER: '126#39#51#51',
            AVATAROPTIONSEATOPEN_POS: '90#34#10#10',
            AVATAROPTION_POS: '94#80#10#10',
            AVATARPLAYERNAME_POS: '13#46#120#16',
            AVATAR_ACTIONTEXT_COLOR: '255,255,255',
            AVATAR_ACTIONTIMERANIMATIONPOS: '81#53#18#16',
            AVATAR_ACTIONTIMERPOS: '0#12#40#40',
            AVATAR_ACTIONTIMERRECTPOS: '4#60#87#10',
            AVATAR_ACTIONTIMERRECTWITHANIPOS: '4#60#99#10',
            AVATAR_ACTIVEBALANCEVALUE_COLOR: '235,235,235',
            AVATAR_ACTIVEPLAYERNAME_COLOR: '255,255,255',
            AVATAR_CARDPOS: '0#0#0#0',
            AVATAR_CHATOFFPOS3: '0#0#0#0',
            AVATAR_CHATOFFPOS: '90#41',
            AVATAR_CHATOFFPOSSTUD: '0#75',
            AVATAR_CPCARDPOS: '0#0#0#0',
            AVATAR_FOLDEDBRUSH_COLOR: '51,174,218',
            AVATAR_FOLDEDCARD_TEXT_COLOR: '235,235,235',
            AVATAR_NOTESICONPOS: '5#44#15#15',
            AVATAR_PROGRESSIVEICONPOS: '2#41#58#16',
            AVATAR_PROGRESSIVETEXTPOS: '22#42#40#16',
            AVATAR_NOTESICONPOSSTUD: '105#72#15#15',
            AVATAR_PROG_NOTESICONPOS: '5#5#15#15',
            AVATAR_OUTBALANCEVALUE_COLOR: '150,150,150',
            AVATAR_OUTPLAYERNAME_COLOR: '201,201,201',
            AVATAR_PLAYEROUTINICATOR: '52#25',
            AVATAR_SEATEMPTY: '0#14#98#59',
            AVATAR_SEATOPEN: '0#14#98#59',
            AVATAR_SEATRESERVED: '0#14#98#59 ',
            AVATAR_SIZE: '176#103',
            AVATAR_TIMERPROGRESS1_COLOR: '126,211,33',
            AVATAR_TIMERPROGRESS2_COLOR: '229,201,8',
            AVATAR_TIMERPROGRESS3_COLOR: '245,104,19',
            AVATAR_TIMERPROGRESS4_COLOR: '206,8,8',
            AVATAR_TIMERPROGRESS5_COLOR: '0,0,0',
            AVATAR_WONBACKGRNRECT_COLOR: '232,74,13',
            AVATAR_WONTEXT_COLOR: '255,255,255',
            BIGCARDBACK: 'bigcardback.bmp',
            BJBUTTON: '762#26#45#52',
            ROULETTEBUTTON: '723#26#45#52',
            TEXASBONUSPOKERBUTTON: '684#26#45#52',
            FFICONBUTTON: '684#26#45#52',
            BUYINDLG_BACKGROUND_COLOR: '255,255,255',
            BUYINDLG_BORDERCOLOR: '255,255,255',
            BUYINDLG_ENCLOSED_TEXTCOLOR: '64,64,64',
            BUYINDLG_FILLCOLOR: '210,210,210',
            BUYINDLG_PLAYERBALANCE_TEXTCOLOR: '231,73,13',
            BUYINDLG_TEXTCOLOR: '64,64,64',
            BUYINDLG_TITLE_TEXTCOLOR: '64,64,64',
            BUYINDLG_USEALLBTNTXTCOLOR: '231,73,13',
            REBUYTAB_USEALLBTNTXTCOLOR: '255,255,255',
            CHECKFOLDOPTIONCOLOR: '255,255,255',
            CALLOUT: 'callout.bmp',
            CALLOUTMASK: 'calloutmsk.bmp',
            CARDBORDERSIZE: '0#3',
            CARDSIZE: '59#82',
            CASCADE_BTN_LOCATION: '567#3',
            CHATBOX: '7#426#360#58',
            CHATBOXBKCOLOR: '255,255,255',
            CHATMESSAGE: '7#492#280#11',
            CHATTYPE: 'chattype.bmp',
            COMMUNITYHAND_POS: '26.5#38',
            CONGANDCONN: '260#220',
            COUNTER_POS: '340#141#115#115',
            DEALERBUTTON: 'DealerControl.bmp',
            DEALERBUTTONMASK: 'DealerControlmsk.bmp',
            DEALERIMAGE: 'dealer.bmp',
            DEALERTYPE: '215#507#104#22',
            DEALMAKING_CHATAREABGCOLOR: '255,255,255',
            DEALMAKING_CHATDEFAULTMSGTEXTCOLOR: '64,64,64',
            DEALMAKING_CHATDLGBGCOLOR: '24, 112, 25',
            DEALMAKING_SUBTITLETEXTCOLOR: '64,64,64',
            DEALMAKING_RESULTVALUETEXTCOLOR: '64,64,64',
            DEALMAKING_PLACECOLUMNTEXTCOLOR: '0,0,0',
            DEALMAKING_PLACECOLUMNBGCOLOR: '255,255,255',
            DEALMAKING_CHATHIGHLIGHTMSGTEXTCOLOR: '0,155,204',
            DEALMAKING_CUSTOMPAYOUTBGCOLOR: '255,255,255',
            DEALMAKING_DEFAULTTEXTCOLOR: '64,64,64',
            DEALMAKING_DISABLEBGCOLOR: '217,217,217',
            DEALMAKING_EDITBGCOLOR: '255,255,255',
            DEALMAKING_EXITDEALBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_HELPDEALBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_SENDCHATBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_HELP_LOCATION: '72#338',
            DEALMAKING_IMG_LOCATION: '0#334#132#64',
            DEALMAKING_LISTCONTROLHEADERBORDERCOLOR: '255,255,255',
            DEALMAKING_NO_LOCATION: '9#381',
            DEALMAKING_OTHERTEXT_COLOR: '51,51,51',
            DEALMAKING_SELBGCOLOR: '220,228,240',
            DEALMAKING_PLAYERCOUNTBGCOLOR: '220,228,240',
            DEALMAKING_SELRADIOBTNTEXTCOLOR: '64,64,64',
            DEALMAKING_SUBMITDEALBTNTEXTCOLOR: '255,255,255',
            DEALMAKING_TEXT_LOCATION: '4#408#64#29',
            DEALMAKING_UNSELRADIOBTNTEXTCOLOR: '64,64,64',
            DEALMAKING_USERCHATTEXTCOLOR: '0,155,204',
            DEALMAKING_YESNOCOLOR: '51,51,51',
            DEALMAKING_HEADERTEXTCLR: '0,0,0',
            DEALMAKING_YES_LOCATION: '9#366',
            DEALMEOUT: '5#533#89#15',
            DLRCHTTXTCOLOR: '64,64,64',
            EMBEDDED_MESSAGE_X: '225',
            EMBEDDED_MESSAGE_Y: '107',
            EMOTICONBKCOLOR: '148,130,66',
            FOLDNSHOW_CARD_POS: '93#4',
            FOLDTOANYBET2: '403#403#104#15',
            FOOTERSECTIONPOS: '0#511#787#26',
            FOOTERSECTION_AVATAR: '96#512',
            FOOTERSECTION_CASHIER: '0#512',
            FOOTERSECTION_CASINO: '72#512',
            FOOTERSECTION_DIVIDER: '724#516',
            FOOTERSECTION_HHISTORY: '24#512',
            FOOTERSECTION_PREFSEATING: '120#512',
            FOOTERSECTION_STATS: '48#512',
            FOOTERSECTION_TABLEOPTION: '623#516#98#16',
            FULLTABLE_POPUP_TEXTCOLOR: '64,64,64',
            KEYBOARD_SHORTCUT_LOC: '93#9',
            LASTGAME: '625#15#131#250',
            LEAVETABLE: 'leavetable.bmp',
            LOVETOWIN_POS: '45#440#270#40',
            MESSAGEPANELSTRINGPOS: '415#464#794#477',
            MESSAGEPANELSTRIPPOS: '407#454',
            MUCKLOSINGHAND: '5#515#173#15',
            NEXTMOVE: '366#415',
            NOCONNECTION: '265#191',
            OPENSIMILARTABLEBTN_STPOS: '0#0',
            OPTION: 'option.bmp',
            OPTIONMASK: 'option_mask.bmp',
            OTHERTLBOPT1: '747#271',
            OTHERTLBOPT2: '695#400',
            OTHERTLBOPT3: '695#383',
            OUTBUTTON: 'out.bmp',
            OUTBUTTONMASK: 'outmsk.bmp',
            PERSISTENTOPTION_CHKBTN: '7#4#198#15',
            PERSISTENTOPTION_CLOSEPOS: '570#431#224#28',
            PERSISTENTOPTION_MOREBTN: '204#24#15#15',
            PERSISTENTOPTION_OPENPOS: '570#365#224#68',
            PERSISTENTOPTION_RZBTNDN: '762#348#32#18',
            PERSISTENTOPTION_RZBTNUP: '762#381#32#18',
            PIEANIMATIONTXTSIZE: '140#40',
            PLAYERINFOFRAMECOLOR: '255,215,0',
            PLAYERINFO_ACTEDCOLOR: '255,215,0',
            PLAYERINFO_CURRENTPLAYERCOLOR: '255,0,0',
            PLAYERINFO_FOLDEDCOLOR: '191,191,191',
            PLAYERINFO_YETTOACTCOLOR: '255,215,0',
            POINTFACEDOWN: '42#17',
            POINTFACEDOWNNOAVATAR: '20#17',
            POPUPOFFSET: '42#14',
            PRZEINFODLG_POS: '647#18',
            QUICKDEPOSIT_IMGBTN_TXTCOLOR: '255,255,255',
            RAKEPOS: '40#20',
            READY: '192#162#415#40',
            REBUY: 'rebuy.bmp',
            REBUYTAB_HYPERLINK_TEXTCOLOR: '255,255,255',
            RESERVERSEAT: 'reserved.bmp',
            RESERVERSEATMASK: 'reserved_mask.bmp',
            SIDEPOTBG: '248#117',
            SIDEPOTPOS0: '50#27',
            SIDEPOTPOS1: '50#27',
            SIDEPOTPOS2: '50#27',
            SIDEPOTPOS3: '50#27',
            SIDEPOTPOS4: '50#27',
            SIDEPOTPOS5: '50#27',
            SIDEPOTPOS6: '50#27',
            SIDEPOTPOS7: '50#27',
            SIDEPOTPOS8: '50#27',
            SIDEPOTPOS9: '50#27',
            SIDEPOTPOS10: '65#27',
            SIDEPOTTITLE: '350#98#98#9',
            SIDEPOTTITLETEXTCOLOR: '255,255,255',
            SLIDER: '0#16#378#43',
            SLIDERBGCOLOR: '0,0,0',
            SLIDERDLRIMGRECTPOS: '249#37#12#21',
            SLIDEREDITBGCOLOR: '255,255,204',
            SLIDEREDITCTRLRECTPOS: '270#36#100#21',
            SLIDERFRAMECOLOR: '58,215,37',
            SLIDERMINUSCTRLRECTPOS: '4#32#27#27',
            SLIDERPLUSCTRLRECTPOS: '213#32#27#27',
            SLIDERSLIDERCTRLRECTPOS: '32#37#180#19',
            SLIDERTABIMGCORRECTOFFSET: '3#',
            SLIDERTABIMGOFFSETPOS: '98#3',
            SLIDERTABIMGSIZE: '215#19',
            SMALLCARDBACK1: 'smallcard1.bmp',
            SMALLCARDBACK2: 'smallcard2.bmp',
            SMALLCARDBACK3: 'smallcard3.bmp',
            SMALLCARDBACK4: 'smallcard4.bmp',
            SNGJP1STPLACETXT: '4#1#74#15',
            SNGJP1STPLACEVAL: '69#1#78#15',
            SNGJP1LINERPRIZETXT: '211#162#185#38',
            SNGJP1LINERPRIZETXTEND: '211#212',
            SNGJP1LINERPRIZEVAL: '396#162#185#38',
            SNGJP1LINERPRIZEVALEND: '396#212',
            SNGJP1LINERWIN1TXT: '211#215#185#29',
            SNGJP1LINERWIN1VAL: '396#215#185#29',
            SNGJP2LINERPRIZETXT: '339#153#173#29',
            SNGJP2LINERPRIZETXTEND: '339#230',
            SNGJP2LINERPRIZEVAL: '494#153#109#29',
            SNGJP2LINERPRIZEVALEND: '494#230',
            SNGJP2LINERWIN1TXT: '339#200#173#20',
            SNGJP2LINERWIN1VAL: '494#200#109#20',
            SNGJP2LINERWIN2TXT: '339#229#173#20',
            SNGJP2LINERWIN2VAL: '494#229#109#20',
            SNGJP2NDPLACETXT: '4#16#74#15',
            SNGJP2NDPLACEVAL: '69#16#78#15',
            SNGJP3LINERPRIZETXT: '339#139#173#28',
            SNGJP3LINERPRIZETXTEND: '339#240',
            SNGJP3LINERPRIZEVAL: '494#139#109#28',
            SNGJP3LINERPRIZEVALEND: '603#240',
            SNGJP3LINERWIN1TXT: '339#186#173#20',
            SNGJP3LINERWIN1VAL: '494#186#109#20',
            SNGJP3LINERWIN2TXT: '339#215#173#20',
            SNGJP3LINERWIN2VAL: '494#215#109#20',
            SNGJP3LINERWIN3TXT: '339#243#173#20',
            SNGJP3LINERWIN3VAL: '494#243#109#20',
            SNGJP3RDPLACETXT: '4#31#74#15',
            SNGJP3RDPLACEVAL: '69#31#78#15',
            SNGJP4LINERPRIZETXT: '339#131#173#23',
            SNGJP4LINERPRIZETXTEND: '339#240',
            SNGJP4LINERPRIZEVAL: '494#131#109#23',
            SNGJP4LINERPRIZEVALEND: '494#240',
            SNGJP4LINERWIN1TXT: '339#173#173#17',
            SNGJP4LINERWIN1VAL: '494#173#109#17',
            SNGJP4LINERWIN2TXT: '339#199#173#17',
            SNGJP4LINERWIN2VAL: '494#199#109#17',
            SNGJP4LINERWIN3TXT: '339#225#173#17',
            SNGJP4LINERWIN3VAL: '494#225#109#17',
            SNGJP4LINERWIN4TXT: '339#250#173#17',
            SNGJP4LINERWIN4VAL: '494#250#109#17',
            SNGJPDELTACOL1X1: '122#0',
            SNGJPDELTACOL1X2: '119#0',
            SNGJPDELTACOL2X1: '101#0',
            SNGJP4THPLACETXT: '4#46#74#15',
            SNGJP4THPLACEVAL: '69#46#78#15',
            SNGJPBLINDSLINELEFT: '1#50',
            SNGJPBLINDSLINERIGHT: '130#50',
            SNGJPBLINDSTXT: '-1#34#72#16',
            SNGJPBLINDSVALUE: '71#34#60#16',
            SNGJPBOUNTYAWARDINFO: '575#0',
            SNGJPBOUNTYTEXT: '214,214,214',
            SNGJPBOUNTYTXT: '4#61#74#15',
            SNGJPBOUNTYVAL: '69#61#78#15',
            SNGJPBUNREGTEXTCOLOR: '255,255,255',
            SNGJPDURATIONLINELEFT: '1#66',
            SNGJPDURATIONLINERIGHT: '130#66',
            SNGJPDURATIONTXT: '-1#50#72#16',
            SNGJPDURATIONVALUE: '71#50#60#16',
            SNGJPHHLINKFILLCOLOR: '19,23,24',
            SNGJPINFOFILLCOLOR: '39,40,42',
            SNGJPINFOLINECOLOR: '75,75,75',
            SNGJPLEVELLINELEFT: '1#34',
            SNGJPLEVELLINERIGHT: '130#34',
            SNGJPLEVELTXT: '-1#18#72#16',
            SNGJPLEVELVALUE: '71#18#60#16',
            SNGJPLOGOBGPOS: '127#113',
            SNGJPMULBGCOLOR: '117,68,73',
            USD: '$',
            EUR: '€',
            CAD: 'C$',
            GBP: '£',
            RUB: '₽',
            SNGJPMULFADEDBGCOLOR: '117,68,73',
            SNGJPMULTIPIER: '148#172#191#45',
            SNGJPMULTIPIEREND: '166#228',
            SNGJPMULTIPLIERTEXT: '254,111,46',
            SNGJPMULTOTALPRIZERECT: '269#181#251#20',
            SNGJPNEXTBLINDSTXT: '-1#65#85#16',
            SNGJPNEXTBLINDSVAL: '71#65#60#16',
            SNGJPPAYOUTDATA: '255,255,255',
            SNGJPPRIZEINFODLG_LINE: '0#15#128#1',
            SNGJPPRIZEINFOTEXT: '184,184,184',
            SNGJPPRIZESUMMARYCOLOR: '35,224,104',
            SNGJPPRIZETEXT: '255,255,255',
            SNGJPREGISTERBUTTON: '131#56#177#51',
            SNGJPRENDERRECT: '118#104#561#200',
            SNGJPSINGLEMULTIPIER: '234#142#327#49',
            SNGJPSINGLEMULTIPIEREND: '121#188',
            SNGJPSINGLEPRIZE: '160#211#466#46',
            SNGJPSINGLEPRIZEEND: '183#253',
            SNGJPWATCHERCOUNTONTABLETEXT: '255,255,255',
            SNGJPTOTALPRIZEBUTTON: '629#0',
            SOCIAL_DLG_AVATAR_RECT_COLOR: '0,0,0',
            SOCIAL_DLG_BACKGROUND_COLOR: '64,64,64',
            SOCIAL_DLG_ITEM_LINE_COLOR: '115,115,115',
            SOCIAL_DLG_ITEM_TAG_TEXT_COLOR: '135,135,135',
            SOCIAL_DLG_ITEM_TEXT_COLOR: '210,210,210',
            SOCIAL_DLG_SELITEM_FILLCOLOR: '79,79,79',
            SOCIAL_DLG_TITLE_TEXT_COLOR: '255,255,255',
            SPLCHTTXTCOLOR: '64,64,64',
            TABBEDAREA_DISBLCHATPOS: '170#8',
            TABBEDAREA_DISBLCHATSIZE: '135#18',
            TABBEDAREA_EMOTICONCOUNT: '18',
            TABBEDAREA_ICONBTNPOS: '350#93',
            TABBEDAREA_ICONBTNSIZE: '26#16',
            TABBEDAREA_INSETRECT: '0#21#404#90',
            TABBEDAREA_MINBTNPOS: '0#0',
            TABBEDAREA_MINBTNSIZE: '9#21',
            TABBEDAREA_MINIMIZED: '408#404#9#21',
            TABBEDAREA_MSGDISPPOS: '5#24',
            TABBEDAREA_MSGDISPSIZE: '396#61',
            TABBEDAREA_MSGEDITPOS: '5#94',
            TABBEDAREA_MSGEDITSIZE: '287#16',
            TABBEDAREA_NORMAL: '1#440#404#111',
            TABBEDAREA_NOTESEDITPOS: '7#31',
            TABBEDAREA_NOTESEDITSIZE: '385#60',
            TABBEDAREA_OPTNBTNPOS: '383#93',
            TABBEDAREA_OPTNBTNSIZE: '16#16',
            TABBEDAREA_CHATSETBTNSIZE: '16#16',
            TABBEDAREA_PLYIDSCMBPOS: '3#7',
            TABBEDAREA_PLYIDSCMBSIZE: '130#20',
            TABBEDAREA_POSBTNACCREFRESH: '382#25',
            TABBEDAREA_POSBTNADDMORECHIPS: '279#24',
            TABBEDAREA_POSBTNREBUY: '326#66',
            TABBEDAREA_POSBTNUSEALL: '7#66',
            TABBEDAREA_POSCHKAUTOREBUY: '291#50',
            TABBEDAREA_POSCHKDOUBLEREBUY: '9#66',
            TABBEDAREA_POSCHKSINGLEREBUY: '9#50',
            TABBEDAREA_POSEDITCHIPAMT: '165#69',
            TABBEDAREA_POSHYPERLINKCASHIER: '323#25',
            TABBEDAREA_POSHYPERLINKEXCHANGERATE: '219#25',
            TABBEDAREA_POSHYPERLINKTOURNEYFEE: '20#68',
            TABBEDAREA_POSREBUYERRORMSGOKBTN: '326#66',
            TABBEDAREA_POSTXTDECIDEAMT: '9#49',
            TABBEDAREA_POSTXTMAXBUYIN: '9#25',
            TABBEDAREA_POSTXTMINBUYIN: '9#9',
            TABBEDAREA_POSTXTYOURBANKROLL: '180#9',
            TABBEDAREA_POSVALMAXBUYIN: '69#25',
            TABBEDAREA_POSVALMINBUYIN: '69#9',
            TABBEDAREA_POSVALYOURBANKROLL: '307#9',
            TABBEDAREA_QDDLGRECT: '5#419#399#89',
            TABBEDAREA_REBUYERRORMSGSIZE: '404#90',
            TABBEDAREA_REBUYERRORMSGSTARTPOS: '0#0',
            TABBEDAREA_RESTORE_BTN_POS: '0#440',
            TABBEDAREA_SAVENOTESPOS: '325#1',
            TABBEDAREA_SAVENOTESSIZE: '74#26',
            TABBEDAREA_SENDBTNPOS: '300#93',
            TABBEDAREA_SENDBTNSIZE: '46#16',
            TABBEDAREA_CHATSETTINGSPOS: '378#93',
            TABBEDAREA_SIZBTNACCREFRESH: '14#14',
            TABBEDAREA_SIZBTNADDMORECHIPS: '100#23',
            TABBEDAREA_SIZBTNREBUY: '74#26',
            TABBEDAREA_SIZBTNUSEALL: '72#26',
            TABBEDAREA_SIZEDITCHIPAMT: '80#17',
            TABBEDAREA_SIZHYPERLINKCASHIER: '54#14',
            TABBEDAREA_SIZHYPERLINKEXCHANGERATE: '90#14',
            TABBEDAREA_TABBTNSIZE: '37#21',
            TABBEDAREA_TABBUTTON_OVERLAP: '1#0',
            TABBEDAREA_WTHLSTCMBPOS: '138#8',
            TABBEDAREA_WTHLSTCMBSIZE: '40#14',
            TABLECASHIER: 'gamecashier.bmp',
            TABLEHELP: 'help.bmp',
            TABLELOBBY: 'Lobby.bmp',
            TABS_IN_CASHIER: '281#75#190#27',
            TABSELECTEDCOLOR: '140,248,85',
            TBTTIMERIMAGEPOS: '0#372',
            TBTTIMERPOS: '46#380#44#15',
            TEXT_COLOR: '175,175,175',
            THANDNO: '6#25#131#15',
            THISGAME: '625#2#131#250',
            TILE_BTN_LOCATION: '598#3',
            TIMEBANK: '375#28',
            TIMELEFTTEXTCOLOR: '252,216,150',
            TITLEBARBUTTONSAREAWIDTH: '375',
            TLBADVANCEOPTIONSTEXTCOLOR: '0,0,0',
            TLBHANDSTRENGTHTEXTCOLOR: '0,0,0',
            TLBHELP: '689#484',
            TLBLEAVETABLE: '689#528',
            TLBLOBBY: '724 #546',
            TLBOPTIONSTEXTCOLOR: '255,255,255',
            TLBTEXTCOLOR: '199,199,199',
            TOOLTIP_REBUY_CASHIER: 'PARTY_POKER_GC_BIN_REBUYCASHIER',
            TOTALPOTAMOUNT: '50#33#192#20',
            TOTALPRIZEBTNTEXTCLR: '184,184,184',
            TOURNEYINFO2: '6#15#131#30',
            TOURNEYINFO: '6#4#131#30',
            TOURVEYCONN: 'tourn_congrats.gif',
            TURNINDICATOR: '425#245#57#13',
            USERCHATTXTCOLOR: '0,155,204',
            VIEWDEAL_IMG_LOCATION: '332#249#136#30',
            VIEWHANDSTRENGTH: '6#380#189#15',
            VIEW_DEALMAKING_LOCATION: '332#255#136#32',
            WAITBB: '350#443',
            YOUAREREGISTERED: '177#178#445#30',
            YOURTURN: '753#336',
            YOURTURNTXT: '754#346',
            PROGRESSBARBG: '230,230,230',
            PROGRESSBAR: '232,73,13',
            PROGRESSBARRECT: '25#220#345#6',
            NOTIFICATIONRECTPOS: '260#2#548#27',
            BTNDISMISSCLR: '183,183,183',
            REGRECTPOS: '410#4',
            BTNRECTPOS: '480#4',
            CHATAREA_PLAYERCHATPOS: '200#30',
            CHATAREA_OBSERVERCHATPOS: '200#50',
            CHATAREA_CLOSEBTNPOS: '380#5',
            CHATAREA_SAVEBTNPOS: '310#63',
            CHATAREA_DLRTEXTPOS: '15#10',
            CHATAREA_ALLACTIONPOS: '30#30',
            CHATAREA_DLRSUMMARYPOS: '30#50',
            CHATAREA_DLRSILENTPOS: '30#70',
            CHATAREA_CHATOPTNTXTPOS: '178#10',
            CHATAREA_DLRTEXTSIZE: '140#17',
            CHATAREA_CLOSEBTNSIZE: '25#25',
            CHATAREA_SAVEBTNSIZE: '74#26',
            CHATAREA_CHATOPTNTXTSIZE: '140#17',
            AVATAR_BORDERBLINKING_COLOR: '192,192,192',
            TIMEBANK_TEXTLOC: '21#3',
            FOLDBTNTXTCOLOR: '255,255,255',
        },

        'TEXASHOLDEM10MAX': {
            AVATARPOS0: '56#0',
            AVATARPOS1: '75.5#10',
            AVATARPOS2: '82.5#29.5',
            AVATARPOS3: '75#49.5',
            AVATARPOS4: '53#59.5',
            AVATARPOS5: '27#59.5',
            AVATARPOS6: '4#49.5',
            AVATARPOS7: '0#29.5',
            AVATARPOS8: '5#10',
            AVATARPOS9: '24#0',

            CHIPPOS0: '61#19',
            CHIPPOS1: '75#28.2',
            CHIPPOS2: '74#40.5',
            CHIPPOS3: '72.5#48',
            CHIPPOS4: '59#52',
            CHIPPOS5: '39#52',
            CHIPPOS6: '24#48',
            CHIPPOS7: '24#40.5',
            CHIPPOS8: '23#28',
            CHIPPOS9: '35.5#19',

            BETPOS0: '465#100',
            BETPOS1: '570#129',
            BETPOS2: '647#197',
            BETPOS3: '568#256',
            BETPOS4: '443#282',
            BETPOS5: '330#282',
            BETPOS6: '208#256',
            BETPOS7: '132#197',
            BETPOS8: '208#129',
            BETPOS9: '313#100',

            DEALCARDPOS0: '500#55',
            DEALCARDPOS1: '655#110',
            DEALCARDPOS2: '680#216',
            DEALCARDPOS3: '650#323',
            DEALCARDPOS4: '467#375',
            DEALCARDPOS5: '263#375',
            DEALCARDPOS6: '80#323',
            DEALCARDPOS7: '50#216',
            DEALCARDPOS8: '75#110',
            DEALCARDPOS9: '230#55',

            DEALERBTNPOS0: '55#18',
            DEALERBTNPOS1: '70#24',
            DEALERBTNPOS2: '75#35.5',
            DEALERBTNPOS3: '69.5#54.5',
            DEALERBTNPOS4: '51#58',
            DEALERBTNPOS5: '44#58',
            DEALERBTNPOS6: '26#54',
            DEALERBTNPOS7: '21#35',
            DEALERBTNPOS8: '26#22',
            DEALERBTNPOS9: '42#18',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#43.8',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '650#40',
            PLAYEROPENCARDS2: '683#185',
            PLAYEROPENCARDS3: '659#300',
            PLAYEROPENCARDS4: '455#337',
            PLAYEROPENCARDS5: '209#339',
            PLAYEROPENCARDS6: '18#286',
            PLAYEROPENCARDS7: '6#180',
            PLAYEROPENCARDS8: '28#49',
            PLAYEROPENCARDS9: '219#35',
            SEATOPENPOS0: '478#47',
            SEATOPENPOS1: '633#102',
            SEATOPENPOS2: '658#208',
            SEATOPENPOS3: '626#315',
            SEATOPENPOS4: '445#367',
            SEATOPENPOS5: '217#367',
            SEATOPENPOS6: '34#315',
            SEATOPENPOS7: '4#208',
            SEATOPENPOS8: '29#102',
            SEATOPENPOS9: '184#47',
            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM9MAX': {
            AVATARPOS0: '53#16',
            AVATARPOS1: '71#29',
            AVATARPOS2: '74.3#49',
            AVATARPOS3: '77#69',
            AVATARPOS4: '41#100',
            AVATARPOS5: '5#69',
            AVATARPOS6: '8.2#49',
            AVATARPOS7: '11.5#29',
            AVATARPOS8: '29#16',

            CHIPPOS0: '61#19',
            CHIPPOS1: '68#27',
            CHIPPOS2: '69#50',
            CHIPPOS3: '71#59.5',
            CHIPPOS4: '50#60',
            CHIPPOS5: '27.5#59.5',
            CHIPPOS6: '28.5#50',
            CHIPPOS7: '32.5#27',
            CHIPPOS8: '38#19',

            BETPOS0: '465#100',
            BETPOS1: '570#129',
            BETPOS2: '647#197',
            BETPOS3: '617#263',
            BETPOS4: '360#287',
            BETPOS5: '167#263',
            BETPOS6: '132#197',
            BETPOS7: '208#129',
            BETPOS8: '313#100',

            DEALCARDPOS0: '500#55',
            DEALCARDPOS1: '655#118',
            DEALCARDPOS2: '680#220',
            DEALCARDPOS3: '623#351',
            DEALCARDPOS4: '365#375',
            DEALCARDPOS5: '116#351',
            DEALCARDPOS6: '50#220',
            DEALCARDPOS7: '75#118',
            DEALCARDPOS8: '230#55',

            DEALERBTNPOS0: '52#13',
            DEALERBTNPOS1: '74#31.5',
            DEALERBTNPOS2: '78#51',
            DEALERBTNPOS3: '80#71',
            DEALERBTNPOS4: '41#71',
            DEALERBTNPOS5: '18#71',
            DEALERBTNPOS6: '20#51',
            DEALERBTNPOS7: '24#31.5',
            DEALERBTNPOS8: '45#13',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            NEXTMOVE: '220#415',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '650#40',
            PLAYEROPENCARDS2: '683#185',
            PLAYEROPENCARDS3: '659#300',
            PLAYEROPENCARDS4: '349#347',
            PLAYEROPENCARDS5: '18#286',
            PLAYEROPENCARDS6: '6#180',
            PLAYEROPENCARDS7: '28#49',
            PLAYEROPENCARDS8: '219#35',

            SEATOPENPOS0: '478#47',
            SEATOPENPOS1: '633#110',
            SEATOPENPOS2: '658#212',
            SEATOPENPOS3: '601#343',
            SEATOPENPOS4: '343#367',
            SEATOPENPOS5: '70#343',
            SEATOPENPOS6: '4#212',
            SEATOPENPOS7: '29#110',
            SEATOPENPOS8: '184#47   ',

            SLIDER: '200#399#140#35',
            TIMELEFT: '209#364',
        },

        'TEXASHOLDEM8MAX': {
            AVATARPOS0: '41#12.5',
            AVATARPOS1: '71#29',
            AVATARPOS2: '74.3#49',
            AVATARPOS3: '77#69',
            AVATARPOS4: '41#100',
            AVATARPOS5: '5#69',
            AVATARPOS6: '8.2#49',
            AVATARPOS7: '11.5#29',

            CHIPPOS0: '49#16',
            CHIPPOS1: '68#27',
            CHIPPOS2: '69#50',
            CHIPPOS3: '71#59.5',
            CHIPPOS4: '50#60',
            CHIPPOS5: '27.5#59.5',
            CHIPPOS6: '28.5#50',
            CHIPPOS7: '32.5#27',

            BETPOS0: '524#100',
            BETPOS1: '620#150',
            BETPOS2: '620#250',
            BETPOS3: '485#305',
            BETPOS4: '297#305',
            BETPOS5: '156#250',
            BETPOS6: '156#150',
            BETPOS7: '260#100',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#131',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '493#375',
            DEALCARDPOS4: '237#375',
            DEALCARDPOS5: '50#290',
            DEALCARDPOS6: '50#131',
            DEALCARDPOS7: '237#55',

            DEALERBTNPOS0: '58#13',
            DEALERBTNPOS1: '74#31.5',
            DEALERBTNPOS2: '78#51',
            DEALERBTNPOS3: '80#71',
            DEALERBTNPOS4: '41#71',
            DEALERBTNPOS5: '18#71',
            DEALERBTNPOS6: '20#51',
            DEALERBTNPOS7: '24#31.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '492#28',
            PLAYEROPENCARDS1: '645#114',
            PLAYEROPENCARDS2: '643#293',
            PLAYEROPENCARDS3: '459#321',
            PLAYEROPENCARDS4: '200#309',
            PLAYEROPENCARDS5: '183#322',
            PLAYEROPENCARDS6: '-3#89',
            PLAYEROPENCARDS7: '108#9',

            SEATOPENPOS0: '471#47',
            SEATOPENPOS1: '658#123',
            SEATOPENPOS2: '658#282',
            SEATOPENPOS3: '471#367',
            SEATOPENPOS4: '191#367',
            SEATOPENPOS5: '4#282',
            SEATOPENPOS6: '4#123',
            SEATOPENPOS7: '191#47',

            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM7MAX': {
            AVATARPOS0: '53#16',
            AVATARPOS1: '71#29',
            AVATARPOS2: '77#69',
            AVATARPOS3: '41#100',
            AVATARPOS4: '5#69',
            AVATARPOS5: '11.5#29',
            AVATARPOS6: '29#16',

            CHIPPOS0: '61#19',
            CHIPPOS1: '68#27',
            CHIPPOS2: '71#59.5',
            CHIPPOS3: '50#60',
            CHIPPOS4: '27.5#59.5',
            CHIPPOS5: '32.5#27',
            CHIPPOS6: '38#19',

            BETPOS0: '524#100',
            BETPOS1: '620#150',
            BETPOS2: '620#250',
            BETPOS3: '360#287',
            BETPOS4: '156#250',
            BETPOS5: '156#150',
            BETPOS6: '260#100',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#131',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '365#375',
            DEALCARDPOS4: '50#290',
            DEALCARDPOS5: '50#131',
            DEALCARDPOS6: '237#55',

            DEALERBTNPOS0: '52#13',
            DEALERBTNPOS1: '74#31.5',
            DEALERBTNPOS2: '80#71',
            DEALERBTNPOS3: '41#71',
            DEALERBTNPOS4: '18#71',
            DEALERBTNPOS5: '24#31.5',
            DEALERBTNPOS6: '45#13',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '492#28',
            PLAYEROPENCARDS1: '645#114',
            PLAYEROPENCARDS2: '643#293',
            PLAYEROPENCARDS3: '349#347',
            PLAYEROPENCARDS4: '183#322',
            PLAYEROPENCARDS5: '-3#89',
            PLAYEROPENCARDS6: '108#9',

            SEATOPENPOS0: '498#55',
            SEATOPENPOS1: '652#132',
            SEATOPENPOS2: '652#291',
            SEATOPENPOS3: '350#385',
            SEATOPENPOS4: '48#291',
            SEATOPENPOS5: '48#132',
            SEATOPENPOS6: '199#55',

            TIMELEFT: '332#36',
        },

        'TEXASHOLDEM6MAX': {
            AVATARPOS0: '41#12.5',
            AVATARPOS1: '71#29',
            AVATARPOS2: '77#69',
            AVATARPOS3: '41#100',
            AVATARPOS4: '5#69',
            AVATARPOS5: '11.5#29',

            CHIPPOS0: '49#16',
            CHIPPOS1: '68#27',
            CHIPPOS2: '71#59.5',
            CHIPPOS3: '50#60',
            CHIPPOS4: '27.5#59.5',
            CHIPPOS5: '32.5#27',

            BETPOS0: '465#100',
            BETPOS1: '647#197',
            BETPOS2: '453#262',
            BETPOS3: '320#262',
            BETPOS4: '142#197',
            BETPOS5: '313#90',

            DEALCARDPOS0: '365#55',
            DEALCARDPOS1: '680#122',
            DEALCARDPOS2: '680#290',
            DEALCARDPOS3: '365#375',
            DEALCARDPOS4: '50#290',
            DEALCARDPOS5: '50#122',

            DEALERBTNPOS0: '58#13',
            DEALERBTNPOS1: '74#31.5',
            DEALERBTNPOS2: '80#71',
            DEALERBTNPOS3: '41#71',
            DEALERBTNPOS4: '18#71',
            DEALERBTNPOS5: '24#31.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '683#185',
            PLAYEROPENCARDS2: '455#337',
            PLAYEROPENCARDS3: '209#339',
            PLAYEROPENCARDS4: '6#180',
            PLAYEROPENCARDS5: '219#35',

            SEATOPENPOS0: '319#47',
            SEATOPENPOS1: '658#114',
            SEATOPENPOS2: '658#282',
            SEATOPENPOS3: '343#367',
            SEATOPENPOS4: '4#282',
            SEATOPENPOS5: '4#114',

            TIMELEFT: '332#364',
        },

        'TEXASHOLDEM5MAX': {
            AVATARPOS0: '71#29',
            AVATARPOS1: '77#69',
            AVATARPOS2: '41#100',
            AVATARPOS3: '5#69',
            AVATARPOS4: '11.5#29',

            CHIPPOS0: '68#27',
            CHIPPOS1: '71#59.5',
            CHIPPOS2: '50#60',
            CHIPPOS3: '27.5#59.5',
            CHIPPOS4: '32.5#27',

            BETPOS0: '465#100',
            BETPOS1: '647#197',
            BETPOS2: '360#287',
            BETPOS3: '132#197',
            BETPOS4: '313#100',

            DEALCARDPOS0: '493#55',
            DEALCARDPOS1: '680#216',
            DEALCARDPOS2: '365#375',
            DEALCARDPOS3: '50#216',
            DEALCARDPOS4: '237#55',

            DEALERBTNPOS0: '74#31.5',
            DEALERBTNPOS1: '80#71',
            DEALERBTNPOS2: '41#71',
            DEALERBTNPOS3: '18#71',
            DEALERBTNPOS4: '24#31.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            NEXTMOVE: '220#415',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '683#185',
            PLAYEROPENCARDS2: '349#347',
            PLAYEROPENCARDS3: '6#180',
            PLAYEROPENCARDS4: '219#35',

            SEATOPENPOS0: '447#47',
            SEATOPENPOS1: '658#208',
            SEATOPENPOS2: '343#367',
            SEATOPENPOS3: '4#208',
            SEATOPENPOS4: '191#47',

            SLIDER: '200#399#140#35',
            TIMELEFT: '209#364',
        },

        'TEXASHOLDEM4MAX': {
            AVATARPOS0: '41#12.5',
            AVATARPOS1: '71#29',
            AVATARPOS2: '41#100',
            AVATARPOS3: '11.5#29',

            CHIPPOS0: '49#16',
            CHIPPOS1: '68#27',
            CHIPPOS2: '50#60',
            CHIPPOS3: '32.5#27',

            BETPOS0: '465#100',
            BETPOS1: '443#282',
            BETPOS2: '330#282',
            BETPOS3: '313#100',

            DEALCARDPOS0: '365#55',
            DEALCARDPOS1: '680#216',
            DEALCARDPOS2: '365#375',
            DEALCARDPOS3: '55#216',

            DEALERBTNPOS0: '58#13',
            DEALERBTNPOS1: '74#31.5',
            DEALERBTNPOS2: '41#71',
            DEALERBTNPOS3: '24#31.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '455#337',
            PLAYEROPENCARDS2: '209#339',
            PLAYEROPENCARDS3: '219#35',

            SEATOPENPOS0: '319#47',
            SEATOPENPOS1: '658#208',
            SEATOPENPOS2: '343#367',
            SEATOPENPOS3: '4#208',

            TIMELEFT: '332#364',
        },
        'TEXASHOLDEM3MAX': {
            AVATARPOS0: '71#29',
            AVATARPOS1: '41#100',
            AVATARPOS2: '11.5#29',

            CHIPPOS0: '68#27',
            CHIPPOS1: '50#60',
            CHIPPOS2: '32.5#27',

            BETPOS0: '465#100',
            BETPOS1: '360#287',
            BETPOS2: '313#100',

            DEALCARDPOS0: '655#118',
            DEALCARDPOS1: '365#375',
            DEALCARDPOS2: '75#118',

            DEALERBTNPOS0: '74#31.5',
            DEALERBTNPOS1: '41#71',
            DEALERBTNPOS2: '24#31.5',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            NEXTMOVE: '220#415',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '516#10',
            PLAYEROPENCARDS1: '349#347',
            PLAYEROPENCARDS2: '219#35',

            SEATOPENPOS0: '609#110',
            SEATOPENPOS1: '343#367',
            SEATOPENPOS2: '29#110',

            SLIDER: '200#399#140#35',
            TIMELEFT: '209#364',
        },

        'TEXASHOLDEM2MAX': {
            AVATARPOS0: '41#12.5',
            AVATARPOS1: '41#100',

            CHIPPOS0: '49#16',
            CHIPPOS1: '50#60',

            BETPOS0: '568#256',
            BETPOS1: '208#256',

            DEALCARDPOS0: '365#55',
            DEALCARDPOS1: '365#375',

            DEALERBTNPOS0: '58#13',
            DEALERBTNPOS1: '41#71',

            MAINPOTPOS: '46.5#48',
            MAINPOTCHIPPOS: '50#37.5',
            RITMAINPOTCHIPPOS: '24#24.5',

            PLAYEROPENCARDS0: '659#300',
            PLAYEROPENCARDS1: '18#286',

            SEATOPENPOS0: '323#47',
            SEATOPENPOS1: '323#367',
        },
    };
}
