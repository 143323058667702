import { Routes } from '@angular/router';

import { PokerMicroSeriesSynopsisResolver } from './poker-micro-series-synopsis.resolver';
import { PokerMicroSeriesResolver } from './poker-micro-series.resolver';

export const pokerMicroSeriesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMicroSeriesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerMicroSeriesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMicroSeriesResolver,
        },
    },
];
