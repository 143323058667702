import { Message } from '../../message';
import { SNGJPPayoutInfo } from './SNGJPPayoutInfo';

export class SNGJPGameAboutToStart extends Message {
    private MESSAGE_NAME: string = 'SNGJPGameAboutToStart';
    private sngJackpotID: number = 0;
    private tableId: number = 0;
    private sngJPInstanceID: number = 0;
    private gamePlayStartTime: string | null = null;
    private sngJackpotPayoutInfo: SNGJPPayoutInfo | null = null;
    private tableBackgroundImage: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        tableId: number = 0,
        sngJPInstanceID: number = 0,
        gamePlayStartTime: string | null = null,
        sngJackpotPayoutInfo: SNGJPPayoutInfo | null = null,
        tableBackgroundImage: string | null = null,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.tableId = tableId;
        this.sngJPInstanceID = sngJPInstanceID;
        this.gamePlayStartTime = gamePlayStartTime;
        this.sngJackpotPayoutInfo = sngJackpotPayoutInfo;
        this.tableBackgroundImage = tableBackgroundImage;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getSngJPInstanceID(): number {
        return this.sngJPInstanceID;
    }

    setSngJPInstanceID(sngJPInstanceID: number) {
        this.sngJPInstanceID = sngJPInstanceID;
    }
    getGamePlayStartTime(): string | null {
        return this.gamePlayStartTime;
    }

    setGamePlayStartTime(gamePlayStartTime: string | null) {
        this.gamePlayStartTime = gamePlayStartTime;
    }
    getSngJackpotPayoutInfo(): SNGJPPayoutInfo | null {
        return this.sngJackpotPayoutInfo;
    }

    setSngJackpotPayoutInfo(sngJackpotPayoutInfo: SNGJPPayoutInfo | null) {
        this.sngJackpotPayoutInfo = sngJackpotPayoutInfo;
    }
    getTableBackgroundImage(): string | null {
        return this.tableBackgroundImage;
    }

    setTableBackgroundImage(tableBackgroundImage: string | null) {
        this.tableBackgroundImage = tableBackgroundImage;
    }
}
