import { Message } from '../../message';

export class TourneyResult extends Message {
    private MESSAGE_NAME: string = 'TourneyResult';
    private rank: number = 0;
    private winAmount: number = 0;
    private amountAC: number = 0;
    private accountCurrencyCode: string | null = null;
    private huRematchDialogueTime: number = 0;
    private sttReplayAllowed: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        rank: number = 0,
        winAmount: number = 0,
        amountAC: number = 0,
        accountCurrencyCode: string | null = null,
        huRematchDialogueTime: number = 0,
        sttReplayAllowed: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.rank = rank;
        this.winAmount = winAmount;
        this.amountAC = amountAC;
        this.accountCurrencyCode = accountCurrencyCode;
        this.huRematchDialogueTime = huRematchDialogueTime;
        this.sttReplayAllowed = sttReplayAllowed;
    }

    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getWinAmount(): number {
        return this.winAmount;
    }

    setWinAmount(winAmount: number) {
        this.winAmount = winAmount;
    }
    getAmountAC(): number {
        return this.amountAC;
    }

    setAmountAC(amountAC: number) {
        this.amountAC = amountAC;
    }
    getAccountCurrencyCode(): string | null {
        return this.accountCurrencyCode;
    }

    setAccountCurrencyCode(accountCurrencyCode: string | null) {
        this.accountCurrencyCode = accountCurrencyCode;
    }
    getHuRematchDialogueTime(): number {
        return this.huRematchDialogueTime;
    }

    setHuRematchDialogueTime(huRematchDialogueTime: number) {
        this.huRematchDialogueTime = huRematchDialogueTime;
    }
    getSttReplayAllowed(): boolean | null {
        return this.sttReplayAllowed;
    }

    setSttReplayAllowed(sttReplayAllowed: boolean | null) {
        this.sttReplayAllowed = sttReplayAllowed;
    }
}
