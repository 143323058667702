import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../../api/api.service';

@Injectable({ providedIn: 'root' })
export class PPLGalleryContainerService {
    constructor(private api: PokerApiService) {}

    getCloudinaryGalleryImages(galleryId: number, contentType: string): Observable<any> {
        return this.api.get('PPLGallery/GetGalleryImages', { galleryId: galleryId, contentType: contentType });
    }
}
