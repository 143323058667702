import { Message } from '../../message';

export class UpdateCardMTTRegistrationStatus extends Message {
    private MESSAGE_NAME: string = 'UpdateCardMTTRegistrationStatus';
    private cardId: number = 0;
    private status: number = 0;
    private registrationStatus: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardId: number = 0, status: number = 0, registrationStatus: boolean | null = false) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.status = status;
        this.registrationStatus = registrationStatus;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getRegistrationStatus(): boolean | null {
        return this.registrationStatus;
    }

    setRegistrationStatus(registrationStatus: boolean | null) {
        this.registrationStatus = registrationStatus;
    }
}
