import { Message } from '../../message';

export class ResponseCashoutEquity extends Message {
    private MESSAGE_NAME: string = 'ResponseCashoutEquity';
    private tableId: number = 0;
    private gameId: number = 0;
    private statusCode: number = 0;
    private equityPercentage: number = 0;
    private equityValue: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        gameId: number = 0,
        statusCode: number = 0,
        equityPercentage: number = 0,
        equityValue: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.gameId = gameId;
        this.statusCode = statusCode;
        this.equityPercentage = equityPercentage;
        this.equityValue = equityValue;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getStatusCode(): number {
        return this.statusCode;
    }

    setStatusCode(statusCode: number) {
        this.statusCode = statusCode;
    }
    getEquityPercentage(): number {
        return this.equityPercentage;
    }

    setEquityPercentage(equityPercentage: number) {
        this.equityPercentage = equityPercentage;
    }
    getEquityValue(): number {
        return this.equityValue;
    }

    setEquityValue(equityValue: number) {
        this.equityValue = equityValue;
    }
}
