import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class WinHigh extends TableEventInfo {
    private _playerVsWinAmout = new Map<number, number>();

    get playerVsWinAmout(): Map<number, number> {
        return this._playerVsWinAmout;
    }

    set playerVsWinAmout(value: Map<number, number>) {
        this._playerVsWinAmout = value;
    }

    getEventType(): TableEvents {
        return TableEvents.WIN_HIGH;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.WIN_HIGH;
    }

    next(gameTable: GameTable): void {
        gameTable.mpot = false;
        gameTable.players.forEach((p) => {
            let winAmout =
                gameTable.MAIN_POT.totalValue >= gameTable.ROUND_POT.totalValue ? gameTable.MAIN_POT.totalValue : gameTable.ROUND_POT.totalValue;
            // eslint-disable-next-line no-console
            console.log('  Main_POT:: ' + gameTable.MAIN_POT.totalValue + '  ROUND_POT:: ' + gameTable.ROUND_POT.totalValue);
            if (winAmout && !gameTable.isCashedOut) {
                if (gameTable.isUnCalledBet) {
                    if (
                        winAmout === gameTable.unCalledBetAmountDetails['uncalledUnit'].unit &&
                        gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)
                    ) {
                        p.chips = new Chip(Number(p.chips.value));
                    } else if (
                        winAmout !== gameTable.unCalledBetAmountDetails['uncalledUnit'].unit &&
                        gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)
                    ) {
                        winAmout = winAmout - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                        p.toPot.value = winAmout;
                        p.chips = new Chip(Number(p.chips.value) + Number(winAmout));
                    } else {
                        p.toPot.value = winAmout;
                        p.chips = new Chip(Number(p.chips.value) + Number(winAmout));
                    }
                } else {
                    if (this.seat == p.seatNo) {
                        p.toPot.value = winAmout;
                        p.chips = new Chip(Number(p.chips.value) + Number(winAmout));
                    }
                }

                gameTable.enableTableMask = true;
                setTimeout(function () {
                    gameTable.addWinAmountToPlayer(p);
                    gameTable.enableTableMask = false;
                }, 100);
            } else if (winAmout && gameTable.isCashedOut) {
                if (gameTable.isUnCalledBet && p.name.includes(gameTable.unCalledBetAmountDetails['unCallBetPostingPlayer'])) {
                    p.chips = new Chip(Number(p.cashedOutAmount) + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit);
                } else {
                    p.chips = new Chip(Number(p.cashedOutAmount) + p.chips.value);
                }
            }
            p.isPlayerAllIn = false;
        });

        let rakeMapTotalRake: any = 0;
        gameTable.rakeMap.forEach((singleRake: any) => {
            rakeMapTotalRake += singleRake;
        });
        if (gameTable.totalRake != rakeMapTotalRake) {
            const differeceOftwoValue = rakeMapTotalRake;
            gameTable.addRake(differeceOftwoValue);
        }

        gameTable.getTotalSidePotslength = gameTable.SIDE_POTS.length;
        const sidePotLength = gameTable.SIDE_POTS.length;
        if (gameTable.SIDE_POTS.length === 1 && gameTable.SIDE_POTS[sidePotLength - 1].totalValue <= 0 && gameTable.potMap.size === 1) {
            gameTable.potMap.set(gameTable.potMap.size, 0);
        }

        gameTable.resetPots();
    }

    prev(gameTable: GameTable): void {
        gameTable.existingpotTotalValue = 0;
        if (gameTable.sidePotRound) {
            gameTable.constructPotsFromPotMap(true);
            gameTable.changeChip = true;
            gameTable.players.forEach((p) => {
                let winAmout = gameTable.extraAmountCalculationMap.get(Number(p.seatNo));
                if (winAmout && !gameTable.isCashedOut) {
                    if (gameTable.isUnCalledBet) {
                        if (
                            winAmout === gameTable.unCalledBetAmountDetails['uncalledUnit'].unit &&
                            gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)
                        ) {
                            p.chips = new Chip(Number(p.chips.value));
                            if (p.toPot.value === winAmout) {
                                gameTable.ROUND_POT.totalValue =
                                    gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                            }
                        } else {
                            if (gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)) {
                                winAmout = winAmout - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                            }
                            p.chips = new Chip(Number(p.chips.value) - Number(winAmout));
                            const winChipPos = Properties.propFile[gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
                            p.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
                        }
                    } else {
                        p.chips = new Chip(Number(p.chips.value) - Number(winAmout));
                        const winChipPos = Properties.propFile[gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
                        p.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
                    }
                    p.toPot.value = 0;
                } else if (winAmout && gameTable.isCashedOut) {
                    p.chips = new Chip(p.chips.value - Number(p.cashedOutAmount));
                }
            });
            gameTable.players.forEach((p) => {
                if (p.chips.value <= 0) {
                    p.isPlayerAllIn = true;
                }
            });
        } else {
            gameTable.constructPotsFromPotMap(false);

            gameTable.players.forEach((p) => {
                let winAmout = gameTable.extraAmountCalculationMap.get(Number(p.seatNo));
                if (winAmout) {
                    if (gameTable.isUnCalledBet) {
                        if (
                            winAmout === gameTable.unCalledBetAmountDetails['uncalledUnit'].unit &&
                            gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)
                        ) {
                            p.chips = new Chip(Number(p.chips.value));
                            if (p.toPot.value === winAmout) {
                                gameTable.ROUND_POT.totalValue =
                                    gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                            }
                        } else {
                            if (gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)) {
                                winAmout = winAmout - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                            }
                            p.chips = new Chip(Number(p.chips.value) - Number(winAmout));
                            const winChipPos = Properties.propFile[gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
                            p.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
                        }
                    } else {
                        p.chips = new Chip(Number(p.chips.value) - Number(winAmout));
                        const winChipPos = Properties.propFile[gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
                        p.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
                    }
                    p.toPot.value = 0;
                }
            });
            gameTable.players.forEach((p) => {
                if (p.chips.value <= 0) {
                    p.isPlayerAllIn = true;
                }
            });
        }
        if (gameTable.totalRake > 0) {
            gameTable.totalRake = 0;
        }
        gameTable.changeChip = true;
        let crossCheck: number = 0;
        if (gameTable.isReplayerV3) {
            gameTable.SIDE_POTS.forEach((pot) => {
                crossCheck = crossCheck + pot.totalValue;
            });
            gameTable.existingpotTotalValue = crossCheck;
            gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;
            gameTable.MAIN_POT.totalValue = gameTable.currentActivePot;
        }
        if (gameTable.isReplayerV3 && gameTable.currentActivePot <= 0 && gameTable.SIDE_POTS.length > 0) {
            const sidepotValue = gameTable.SIDE_POTS.length;
            gameTable.currentActivePot = gameTable.SIDE_POTS[sidepotValue - 1].totalValue;
            gameTable.MAIN_POT.totalValue = gameTable.currentActivePot;
            gameTable.SIDE_POTS[sidepotValue - 1].showPot = false;
        }
    }
}
