import { Message } from '../../message';

export class UpdateCardMTTRegistrantCount extends Message {
    private MESSAGE_NAME: string = 'UpdateCardMTTRegistrantCount';
    private cardId: number = 0;
    private registrantCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cardId: number = 0, registrantCount: number = 0) {
        super(reqServerPeerId);
        this.cardId = cardId;
        this.registrantCount = registrantCount;
    }

    getCardId(): number {
        return this.cardId;
    }

    setCardId(cardId: number) {
        this.cardId = cardId;
    }
    getRegistrantCount(): number {
        return this.registrantCount;
    }

    setRegistrantCount(registrantCount: number) {
        this.registrantCount = registrantCount;
    }
}
