import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

// import * as pc from './poker-promotions-generic.component';
// import { PokerVideoComponent } from '../poker-video/poker-video.component';
import { PPContainerComponent } from '../pp-container/pp-container.component';
import { PPModalContainerComponent } from '../pp-modal-container/pp-modal-container.component';

@Injectable({ providedIn: 'root' })
export class PokerCustomTemplatesBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            //this.pageMatrixService.registerComponent('PokerPromotions', pc.PokerPromotionsGenericComponent);
            //this.pageMatrixService.registerComponent('pm1colpage', pc.PokerPromotionsGenericComponent);
            //this.pageMatrixService.registerComponent('pokervideotemplate', PokerVideoComponent);
            this.pageMatrixService.registerComponent('ppcontainer', PPContainerComponent);
            this.pageMatrixService.registerComponent('ppmodalcontainer', PPModalContainerComponent);
        });
    }
}
