import { Message } from '../../message';

export class PlayBalance extends Message {
    private MESSAGE_NAME: string = 'PlayBalance';
    private chips: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, chips: number = 0) {
        super(reqServerPeerId);
        this.chips = chips;
    }

    getChips(): number {
        return this.chips;
    }

    setChips(chips: number) {
        this.chips = chips;
    }
}
