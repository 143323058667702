import { Message } from '../../message';

export class ShowESC extends Message {
    private MESSAGE_NAME: string = 'ShowESC';
    private mtctId: number = 0;
    private show: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, show: boolean | null = false) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.show = show;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getShow(): boolean | null {
        return this.show;
    }

    setShow(show: boolean | null) {
        this.show = show;
    }
}
