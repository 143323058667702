import { Message } from '../../message';

export class RequestTourneyCount extends Message {
    private MESSAGE_NAME: string = 'RequestTourneyCount';
    private tabIds: any[] | null = null;
    private requestType: number = 0;
    private filterHeadsUp: boolean | null = false;
    private filter7CardStud: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tabIds: any[] | null = null,
        requestType: number = 0,
        filterHeadsUp: boolean | null = false,
        filter7CardStud: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.tabIds = tabIds;
        this.requestType = requestType;
        this.filterHeadsUp = filterHeadsUp;
        this.filter7CardStud = filter7CardStud;
    }

    getTabIds(): any[] | null {
        return this.tabIds;
    }

    setTabIds(tabIds: any[] | null) {
        this.tabIds = tabIds;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
    getFilterHeadsUp(): boolean | null {
        return this.filterHeadsUp;
    }

    setFilterHeadsUp(filterHeadsUp: boolean | null) {
        this.filterHeadsUp = filterHeadsUp;
    }
    getFilter7CardStud(): boolean | null {
        return this.filter7CardStud;
    }

    setFilter7CardStud(filter7CardStud: boolean | null) {
        this.filter7CardStud = filter7CardStud;
    }
}
