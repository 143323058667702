import { Message } from '../../message';

export class ShowCongrats extends Message {
    private MESSAGE_NAME: string = 'ShowCongrats';
    private congratsType: number = 0;
    private desc: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, congratsType: number = 0, desc: any | null = null) {
        super(reqServerPeerId);
        this.congratsType = congratsType;
        this.desc = desc;
    }

    getCongratsType(): number {
        return this.congratsType;
    }

    setCongratsType(congratsType: number) {
        this.congratsType = congratsType;
    }
    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
}
