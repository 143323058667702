import { Injectable } from '@angular/core';

//import { Router } from '@angular/router';
import { ClaimsService, CookieService, NavigationService, OnAppInit, Page, ProductHomepagesConfig, UserService } from '@frontend/vanilla/core';

import { DownloadClientConfig, HtmlHeadTagsClientConfig, PokerHomePageClientConfig, RedirixRedirectsClientConfig } from './client-config.models';
import { HtmlHeadtagIframeService } from './html-head-tag-iframe.service';
import { HtmlHeadtagNavigationService } from './html-head-tag-navigation-fix.service';
import { ScrollToTargetService } from './scroll-to-target.service';

// import { PokerApiService } from './api.service';
// import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationBootstrapService implements OnAppInit {
    previousNavigationUrl: string;
    countOne: boolean;
    restrictedUrls: any;

    constructor(
        private navigation: NavigationService,
        private page: Page,
        private cookieService: CookieService,
        private claimsService: ClaimsService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private downloadClientConfig: DownloadClientConfig,
        private iframeService: HtmlHeadtagIframeService,
        private htmlHeadtagNavigationService: HtmlHeadtagNavigationService,
        private scrollToTargetService: ScrollToTargetService,
        private redirixRedirects: RedirixRedirectsClientConfig,
        private userService: UserService,
        private htmlHeadTagsClientConfig: HtmlHeadTagsClientConfig,
        private productHomepagesConfig: ProductHomepagesConfig,

        // private router: Router,
        // private deviceService: DeviceService,
        // private api: PokerApiService,
        // private windowRef: WindowRef,
    ) {
        // Enable Splash screen on /play route
        this.enablePWCSplashScreen();

        window.addEventListener('click', (event: any) => {
            //event.preventDefault();                     // Don't navigate!
            const anchor = event.target.closest('a'); // Find closest Anchor (or self)
            if (!anchor) return; // Not found. Exit here.
            let routes: any;
            this.redirixRedirects.whenReady.subscribe(() => (routes = this.redirixRedirects.redirectRoutes));
            if (routes) {
                let reqUrl = '';
                if (anchor.getAttribute('href')) {
                    reqUrl = anchor.href.replace(anchor.baseURI, '');
                }
                const obj = routes.find((o) => this.page.lang + o.fromUrl === reqUrl);

                if (obj) {
                    this.navigation.goTo((this.page.homePage + obj.toUrl).toLocaleLowerCase());
                }
            }
        });
    }

    //File contains HTML Head tags /**/
    onAppInit() {
        this.navigation.locationChange.subscribe((e) => {
            // this.windowRef.nativeWindow.addEventListener('click', (event) =>{
            //     this.download(event);
            // });
            if (this.pokerHomePageClientConfig.disableHtmlHeadTags) {
                /*1*/
                this.appendCurrentUrlToBodyTag();
            }

            //this.initRedirixRedirect(e);

            this.disablePWCSplashScreen();

            const currentBrowserUrl: string = window.location.href;
            if (currentBrowserUrl.toLowerCase() === e.previousUrl.toLowerCase()) {
                this.previousNavigationUrl = document.referrer;
            } else {
                this.previousNavigationUrl = e.previousUrl;
            }

            this.htmlHeadTagsClientConfig.whenReady.subscribe(() => {
                this.restrictedUrls = this.htmlHeadTagsClientConfig.restrictedWebUrls;
                for (let i = 0; i < this.restrictedUrls.length; i++) {
                    if (currentBrowserUrl === this.page.homePage + this.restrictedUrls[i].toLowerCase()) {
                        if (this.userService.isAuthenticated === false) {
                            this.navigation.goTo(this.productHomepagesConfig.portal + '/labelhost/login?rurl=' + this.page.homePage + '/alpha');
                        }
                    }
                }
            });
        });
        if (this.pokerHomePageClientConfig.disableHtmlHeadTags) {
            /*2*/
            this.iframeService.iframeResizer();
            /*3*/
            this.htmlHeadtagNavigationService.navigationFixHtmlTag();
            /*4*/
            this.setDocumentCookie();
            /*5*/
            this.scrollToTargetService.scrollToTargetMain();
        }

        // if (this.pokerHomePageClientConfig.disableHtmlHeadTags) {
        //     this.navigationtagsScript.navigationFixHtmlTag();
        // }

        // const config = this.router.config;
        // for(let j=0; j < this.router.config.length; j++)
        // {
        //      if(config[j].path == 'en') // get the locale
        //      {
        //          for(let i=0; i<this.routesClient.pokerPromotionsPaths.length; i++){
        //              //PROMOTIONS_ROUTES.push(this.routesClient.pokerPromotionsPaths[i]);
        //              config[1].children.unshift(this.routesClient.pokerPromotionsPaths[i]);
        //          }
        //      }
        //  }
        // this.router.resetConfig(config);
    }

    // Enable Splash screen on /play route
    enablePWCSplashScreen() {
        const currentBrowserUrl: string = window.location.href;
        if (currentBrowserUrl.includes('/play')) {
            const loadingIndicator = document.getElementById('splash');
            if (loadingIndicator) {
                loadingIndicator.classList.add('pwc-enable-loader');
            }
        }
    }

    // Enable Splash screen on /play route
    disablePWCSplashScreen() {
        const currentBrowserUrl: string = window.location.href;
        if (!currentBrowserUrl.includes('/play')) {
            const loadingIndicator = document.getElementById('splash');
            if (loadingIndicator) {
                loadingIndicator.classList.remove('pwc-enable-loader');
            }
        }
    }

    getPreviousUrl(): string {
        return this.previousNavigationUrl;
    }

    appendCurrentUrlToBodyTag() {
        const currentBrowserUrl = window.location.pathname;
        const body = document.getElementsByTagName('body')[0];

        if (
            currentBrowserUrl != '/' + this.page.lang &&
            currentBrowserUrl != '/' &&
            window.location.href != this.page.homePage &&
            window.location.href != this.page.homePage.replace('/' + this.page.lang, '')
        ) {
            body.setAttribute('data-path', currentBrowserUrl.split('/').slice(2).join('/'));
        } else {
            body.setAttribute('data-path', '');
        }
    }

    setDocumentCookie() {
        const campaign = this.getPPQuerySring('c');
        const gclid = this.getPPQuerySring('gclid');

        if (!location.search.match('wm=') && !document.cookie.match('trackerId=')) {
            document.cookie = 'trackerId=' + this.downloadClientConfig.defaultTrackingId + '; domain=' + this.page.domain + '; path=/';
            document.cookie = 'trackingAffiliate=' + this.downloadClientConfig.defaultTrackingId + '; domain=' + this.page.domain + '; path=/';
        }

        if (!document.cookie.match('trackingAffiliate=') || this.cookieService.get('trackingAffiliate') != this.cookieService.get('trackerId')) {
            if (document.cookie.match('trackerId=')) {
                const cv = this.cookieService.get('trackerId');
                document.cookie = 'trackingAffiliate=' + cv + '; domain=' + this.page.domain + '; path=/';
            }
        }

        if (campaign) {
            document.cookie = 'CAMPAIGN_ID=' + campaign + '; domain=' + this.page.domain + '; path=/';
        }

        if (gclid) {
            document.cookie = 'btag=' + gclid + '; domain=' + this.page.domain + '; path=/';
        }

        const country = this.claimsService.get('country');
        document.cookie = 'CCID=' + country + '; domain=' + this.page.domain + '; path=/';

        let pid = this.getPPQuerySring('pid');
        if (pid == null && pid == undefined) {
            pid = this.cookieService.get('pid');
        }
        if (pid == null && pid == undefined) {
            const trackerIdCookie = this.cookieService.get('trackerId');

            if (trackerIdCookie == this.downloadClientConfig.wmid.google || trackerIdCookie == this.downloadClientConfig.wmid.others) {
                document.cookie = 'pid=SEO; domain=' + this.page.domain + '; path=/';
            }
            if (
                !(trackerIdCookie == this.downloadClientConfig.wmid.google || trackerIdCookie == this.downloadClientConfig.wmid.others) &&
                trackerIdCookie != this.downloadClientConfig.defaultTrackingId
            ) {
                document.cookie = 'pid=AFF; domain=' + this.page.domain + '; path=/';
            } else {
                document.cookie = 'pid=DIRECT; domain=' + this.page.domain + '; path=/';
            }
        }
    }

    getPPQuerySring(name: any) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return results[2].replace(/\+/g, ' ');
    }

    // initRedirixRedirect(e:any){
    //     let routes = this.redirixRedirects.redirectRoutes;
    //     if(routes !=null && routes.length > 0){
    //         for(let i=0; i < routes.length; i++){
    //             if(e.nextUrl.toLowerCase() === (this.page.homePage + routes[i].fromUrl).toLocaleLowerCase()){
    //                 this.navigation.goTo(this.page.homePage + routes[i].toUrl);
    //             }
    //         }
    //     }else{
    //         this.navigation.goTo(e.nextUrl);
    //     }
    // }
    // download(event?:any) {

    //     let downloadapp: string = 'downloadClient';
    //     this.countOne = true;
    //     let downloadAttribute: any = event.target.getAttribute('data-download');
    //     if (downloadAttribute == downloadapp) {

    //         var downloadFrame = document.createElement('iframe');
    //         downloadFrame.setAttribute('id', 'downloadiFrame');
    //         downloadFrame.style.display = "none";

    //         this.getDownloadUrl().subscribe((res:any) => {
    //             if (this.countOne) {
    //                 downloadFrame.setAttribute('src', res.downloadUrl);
    //                 document.body.appendChild(downloadFrame);
    //                 setTimeout(() => {
    //                     this.router.navigate([this.page.lang + this.downloadClientConfig.navigationRouteAfterDownload]);
    //                 }, 5000);
    //                 this.countOne = false;
    //             }
    //         })
    //     }
    // }

    // private getDownloadUrl(): Observable<any> {
    //     if (this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet) {
    //         return this.api.get('download/GetAutomaticAppDownloadUrl');
    //     }
    //     return this.api.get('download/GetAutomaticDownloadUrl');
    // }
}
