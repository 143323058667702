import { Injectable } from '@angular/core';

import { PokerApiService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JackpotTickerService {
    constructor(private api: PokerApiService) {}

    getAll(): Observable<any> {
        return this.api.get('jackpotTicker/GetJackpotFeedData');
    }
}
