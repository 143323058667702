import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

import { JackpotTickerComponent } from './jackpot-ticker/jackpot-ticker.component';

@Component({
    selector: 'homepage-sportsection',
    templateUrl: 'homepage-sportsection.component.html',
    standalone: true,
    imports: [JackpotTickerComponent, CommonModule],
})
export class HomePageSportSectionComponent implements OnInit {
    sportsComponents: any = [
        'casinoticker',
        'casinoimage',
        'sportsblurb',
        'sportsimage',
        'communityblurb',
        'communityimage',
        'blogicon',
        'facebookicon',
        'twittericon',
        'twitchicon',
        'youtubeicon',
        'instagramicon',
        'myppliveicon',
    ];
    casinotickerEnabled: boolean;
    communityblurbEnabled: boolean;
    sportsblurbEnabled: boolean;

    blogiconContent: any;
    casinoimageContent: any;
    casinotickerContent: any;
    communityimageContent: any;
    communityblurbContent: any;
    facebookiconContent: any;
    instagramiconContent: any;
    myppliveiconContent: any;
    sportsimageContent: any;
    sportsblurbContent: any;
    twittericonContent: any;
    twitchiconContent: any;
    youtubeiconContent: any;

    blogiconContentEnabled: boolean;
    casinoimageContentEnabled: boolean;
    casinotickerContentEnabled: boolean;
    communityblurbContentEnabled: boolean;
    communityimageContentEnabled: boolean;
    facebookiconContentEnabled: boolean;
    instagramiconContentEnabled: boolean;
    myppliveiconContentEnabled: boolean;
    sportsimageContentEnabled: boolean;
    sportsblurbContentEnabled: boolean;
    twittericonContentEnabled: boolean;
    twitchiconContentEnabled: boolean;
    youtubeiconContentEnabled: boolean;

    constructor(private homePageService: HomepageResourceService) {}

    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            if (this.sportsComponents.indexOf(allData[i].metadata.id.itemName) > -1) {
                switch (allData[i].metadata.id.itemName.toLowerCase()) {
                    case 'blogicon':
                        this.blogiconContent = allData[i];
                        break;
                    case 'casinoimage':
                        this.casinoimageContent = allData[i];
                        break;
                    case 'casinoticker':
                        this.casinotickerContent = allData[i];
                        this.casinotickerEnabled = this.homePageService.setContentFromID(allData[i].metadata.id.itemName);
                        break;
                    case 'communityblurb':
                        this.communityblurbContent = allData[i];
                        this.communityblurbEnabled = this.homePageService.setContentFromID(allData[i].metadata.id.itemName);
                        break;
                    case 'communityimage':
                        this.communityimageContent = allData[i];
                        break;
                    case 'facebookicon':
                        this.facebookiconContent = allData[i];
                        break;
                    case 'instagramicon':
                        this.instagramiconContent = allData[i];
                        break;
                    case 'myppliveicon':
                        this.myppliveiconContent = allData[i];
                        break;
                    case 'sportsimage':
                        this.sportsimageContent = allData[i];
                        break;
                    case 'sportsblurb':
                        this.sportsblurbContent = allData[i];
                        this.sportsblurbEnabled = this.homePageService.setContentFromID(allData[i].metadata.id.itemName.toLowerCase());
                        break;
                    case 'twittericon':
                        this.twittericonContent = allData[i];
                        break;
                    case 'twitchicon':
                        this.twitchiconContent = allData[i];
                        break;
                    case 'youtubeicon':
                        this.youtubeiconContent = allData[i];
                        break;
                }
                // this[`${allData[i].name}Content`] = allData[i];
                // this[`${allData[i].name}Enabled`] = this.homePageService.setContentFromID(allData[i].name);
            }
        }
    }
}
