<div class="promo-section" [ngClass]="{ 'promo-wrapper-touch': isTouch, 'promo-wrapper-nontouch': !isTouch }">
    <div class="col">
        <div class="home-heading-wrap" *ngIf="offersData && offersData.pokerPromotionsSitecoreInfo">
            <h2 *ngIf="offersData.pokerPromotionsSitecoreInfo.synopsisTitle" class="promo-heading">
                {{ offersData.pokerPromotionsSitecoreInfo.synopsisTitle }}
            </h2>
        </div>
        <div class="promo-section-nontouch-row">
            <div *ngIf="showOffersArrowBtns">
                <ngu-carousel #relatedPromosCarousel [inputs]="carouselConfig" *ngIf="!isTouch" [dataSource]="validRelatedPromos">
                    <ngu-item *nguCarouselDef="let promo; let promoIndex = index" NguCarouselItem>
                        <div *ngIf="promo.pokerPromotionsSitecoreInfo">
                            <div class="medium-homepage-promo">
                                <a [href]="getPromoLink(offersData.pokerPromotionsSitecoreInfo.promoListEx[promoIndex].path)">
                                    <div class="homepage-promo-content">
                                        <img [src]="promo.pokerPromotionsSitecoreInfo.synopsisImage.src" alt="promotion" class="img-fluid" />
                                        <div class="card-body-text">
                                            <p class="card-body-title">
                                                {{ promo.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                            </p>
                                            <div
                                                *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisDescription"
                                                [innerHtml]="promo.pokerPromotionsSitecoreInfo.synopsisDescription"
                                                class="synopsisDescription"></div>
                                            {{ promo.pokerPromotionsSitecoreInfo.synopsisCTA }}
                                        </div>
                                        <div class="promo-footer" *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisTerms">
                                            <small class="text-promo-footer">{{ promo.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </ngu-item>
                    <button
                        NguCarouselPrev
                        [style.opacity]="relatedPromosCarousel.isFirst ? 0 : 1"
                        class="theme-left ngx-button-left promo-btn"></button>
                    <button
                        NguCarouselNext
                        [style.opacity]="relatedPromosCarousel.isLast ? 0 : 1"
                        class="theme-right ngx-button-right promo-btn"></button>
                </ngu-carousel>
            </div>
        </div>
        <div class="row promo-section-touch-row">
            <div *ngIf="isTouch" class="promo-section-touch">
                <div class="medium-touch-promo">
                    <div class="scrollable-promos" *ngFor="let promo of validRelatedPromos; let promoIndex = index">
                        <div *ngIf="promo.pokerPromotionsSitecoreInfo">
                            <a [href]="getPromoLink(offersData.pokerPromotionsSitecoreInfo.promoListEx[promoIndex].path)">
                                <div class="touch-promo-content">
                                    <img [src]="promo.pokerPromotionsSitecoreInfo.synopsisImage.src" alt="Promotion" class="img-fluid" />
                                    <div class="touch-body-text">
                                        <p class="card-body-title-touch" *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisTitle">
                                            {{ promo.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                        </p>

                                        <div
                                            *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisDescription"
                                            [innerHtml]="promo.pokerPromotionsSitecoreInfo.synopsisDescription"
                                            class="synopsisDescription"></div>
                                    </div>
                                    {{ promo.pokerPromotionsSitecoreInfo.synopsisCTA }}
                                    <div class="touch-footer" *ngIf="promo.pokerPromotionsSitecoreInfo.synopsisTerms">
                                        <span class="text-touch-footer">{{ promo.pokerPromotionsSitecoreInfo.synopsisTerms }}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
