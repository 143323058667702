import { TSDataInputStream } from '../../../sal/dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from '../../../sal/dataInputOutputStream/TSDataOutputStream';
import { Message } from '../message';
import { PGMessageFactory } from '../pgmessagefactory';
import { DomainChangeAdvice } from './messages/DomainChangeAdvice';
import { DomainMapping } from './messages/DomainMapping';
import { ExtendedAttribs } from './messages/ExtendedAttribs';
import { HandShake } from './messages/HandShake';
import { HandShakeResponse } from './messages/HandShakeResponse';
import { Logout } from './messages/Logout';
import { NotifyPlayerProfile } from './messages/NotifyPlayerProfile';
import { Ping } from './messages/Ping';
import { RequestDomainMapping } from './messages/RequestDomainMapping';
import { RequestPeerConnectivityStatus } from './messages/RequestPeerConnectivityStatus';
import { ResponsePeerConnectivityStatus } from './messages/ResponsePeerConnectivityStatus';
import { ResponseSubscribeFailed } from './messages/ResponseSubscribeFailed';
import { ResponseSubscribeNotAllowed } from './messages/ResponseSubscribeNotAllowed';
import { ResponseSubscribeSuccess } from './messages/ResponseSubscribeSuccess';
import { ResponseUnsubscribeFailed } from './messages/ResponseUnsubscribeFailed';
import { ResponseUnsubscribeSuccess } from './messages/ResponseUnsubscribeSuccess';
import { ServerTime } from './messages/ServerTime';
import { SubscriptionRequest } from './messages/SubscriptionRequest';
import { TimeSyncRequest } from './messages/TimeSyncRequest';
import { UCID } from './messages/UCID';

export class ConnectorMessageFactory extends PGMessageFactory {
    override classes: Map<number, any> = new Map<number, any>();
    override ids: Map<any, number> = new Map<any, number>();

    constructor() {
        super();
        this.classes.set(28673, Ping);
        this.classes.set(28674, RequestPeerConnectivityStatus);
        this.classes.set(28675, ResponsePeerConnectivityStatus);
        this.classes.set(28676, HandShake);
        this.classes.set(28677, HandShakeResponse);
        this.classes.set(28678, ExtendedAttribs);
        this.classes.set(28679, Logout);
        this.classes.set(40969, SubscriptionRequest);
        this.classes.set(40970, TimeSyncRequest);
        this.classes.set(40981, ServerTime);
        this.classes.set(41103, ResponseSubscribeFailed);
        this.classes.set(41104, ResponseSubscribeNotAllowed);
        this.classes.set(41105, ResponseSubscribeSuccess);
        this.classes.set(41110, ResponseUnsubscribeFailed);
        this.classes.set(41111, ResponseUnsubscribeSuccess);
        this.classes.set(41169, NotifyPlayerProfile);
        this.classes.set(41175, RequestDomainMapping);
        this.classes.set(41176, DomainMapping);
        this.classes.set(41177, DomainChangeAdvice);
        this.classes.set(41179, UCID);
        for (const [key, value] of this.classes.entries()) {
            this.ids.set(value, key);
        }
    }

    read(obj: Message, inStream: TSDataInputStream) {
        const classId = this.getClassId(obj.constructor);
        let result = false;
        switch (classId) {
            case 28673:
                this.readPing(inStream, obj as Ping);
                result = true;
                break;
            case 28674:
                this.readRequestPeerConnectivityStatus(inStream, obj as RequestPeerConnectivityStatus);
                result = true;
                break;
            case 28675:
                this.readResponsePeerConnectivityStatus(inStream, obj as ResponsePeerConnectivityStatus);
                result = true;
                break;
            case 28676:
                this.readHandShake(inStream, obj as HandShake);
                result = true;
                break;
            case 28677:
                this.readHandShakeResponse(inStream, obj as HandShakeResponse);
                result = true;
                break;
            case 28678:
                this.readExtendedAttribs(inStream, obj as ExtendedAttribs);
                result = true;
                break;
            case 28679:
                this.readLogout(inStream, obj as Logout);
                result = true;
                break;
            case 40969:
                this.readSubscriptionRequest(inStream, obj as SubscriptionRequest);
                result = true;
                break;
            case 40970:
                this.readTimeSyncRequest(inStream, obj as TimeSyncRequest);
                result = true;
                break;
            case 40981:
                this.readServerTime(inStream, obj as ServerTime);
                result = true;
                break;
            case 41103:
                this.readResponseSubscribeFailed(inStream, obj as ResponseSubscribeFailed);
                result = true;
                break;
            case 41104:
                this.readResponseSubscribeNotAllowed(inStream, obj as ResponseSubscribeNotAllowed);
                result = true;
                break;
            case 41105:
                this.readResponseSubscribeSuccess(inStream, obj as ResponseSubscribeSuccess);
                result = true;
                break;
            case 41110:
                this.readResponseUnsubscribeFailed(inStream, obj as ResponseUnsubscribeFailed);
                result = true;
                break;
            case 41111:
                this.readResponseUnsubscribeSuccess(inStream, obj as ResponseUnsubscribeSuccess);
                result = true;
                break;
            case 41169:
                this.readNotifyPlayerProfile(inStream, obj as NotifyPlayerProfile);
                result = true;
                break;
            case 41175:
                this.readRequestDomainMapping(inStream, obj as RequestDomainMapping);
                result = true;
                break;
            case 41176:
                this.readDomainMapping(inStream, obj as DomainMapping);
                result = true;
                break;
            case 41177:
                this.readDomainChangeAdvice(inStream, obj as DomainChangeAdvice);
                result = true;
                break;
            case 41179:
                this.readUCID(inStream, obj as UCID);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    write(obj: Message, outStream: TSDataOutputStream) {
        const classId = this.getClassIdByName(obj);
        let result = false;
        switch (classId) {
            case 28673:
                this.writePing(outStream, obj as Ping);
                result = true;
                break;
            case 28674:
                this.writeRequestPeerConnectivityStatus(outStream, obj as RequestPeerConnectivityStatus);
                result = true;
                break;
            case 28675:
                this.writeResponsePeerConnectivityStatus(outStream, obj as ResponsePeerConnectivityStatus);
                result = true;
                break;
            case 28676:
                this.writeHandShake(outStream, obj as HandShake);
                result = true;
                break;
            case 28677:
                this.writeHandShakeResponse(outStream, obj as HandShakeResponse);
                result = true;
                break;
            case 28678:
                this.writeExtendedAttribs(outStream, obj as ExtendedAttribs);
                result = true;
                break;
            case 28679:
                this.writeLogout(outStream, obj as Logout);
                result = true;
                break;
            case 40969:
                this.writeSubscriptionRequest(outStream, obj as SubscriptionRequest);
                result = true;
                break;
            case 40970:
                this.writeTimeSyncRequest(outStream, obj as TimeSyncRequest);
                result = true;
                break;
            case 40981:
                this.writeServerTime(outStream, obj as ServerTime);
                result = true;
                break;
            case 41103:
                this.writeResponseSubscribeFailed(outStream, obj as ResponseSubscribeFailed);
                result = true;
                break;
            case 41104:
                this.writeResponseSubscribeNotAllowed(outStream, obj as ResponseSubscribeNotAllowed);
                result = true;
                break;
            case 41105:
                this.writeResponseSubscribeSuccess(outStream, obj as ResponseSubscribeSuccess);
                result = true;
                break;
            case 41110:
                this.writeResponseUnsubscribeFailed(outStream, obj as ResponseUnsubscribeFailed);
                result = true;
                break;
            case 41111:
                this.writeResponseUnsubscribeSuccess(outStream, obj as ResponseUnsubscribeSuccess);
                result = true;
                break;
            case 41169:
                this.writeNotifyPlayerProfile(outStream, obj as NotifyPlayerProfile);
                result = true;
                break;
            case 41175:
                this.writeRequestDomainMapping(outStream, obj as RequestDomainMapping);
                result = true;
                break;
            case 41176:
                this.writeDomainMapping(outStream, obj as DomainMapping);
                result = true;
                break;
            case 41177:
                this.writeDomainChangeAdvice(outStream, obj as DomainChangeAdvice);
                result = true;
                break;
            case 41179:
                this.writeUCID(outStream, obj as UCID);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    readPing(inStream: TSDataInputStream, obj: Ping): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClientTime(inStream.getInteger());
    }

    writePing(outStream: TSDataOutputStream, obj: Ping): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getClientTime());
    }
    readRequestPeerConnectivityStatus(inStream: TSDataInputStream, obj: RequestPeerConnectivityStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPeersConnected(inStream.getIntArray());
    }

    writeRequestPeerConnectivityStatus(outStream: TSDataOutputStream, obj: RequestPeerConnectivityStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getPeersConnected());
    }
    readResponsePeerConnectivityStatus(inStream: TSDataInputStream, obj: ResponsePeerConnectivityStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPeerConnStatus(inStream.getInt2BoolMap());
    }

    writeResponsePeerConnectivityStatus(outStream: TSDataOutputStream, obj: ResponsePeerConnectivityStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2BoolMap(obj.getPeerConnStatus());
    }
    readHandShake(inStream: TSDataInputStream, obj: HandShake): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setARABuildNumber(inStream.getInteger());
        obj.setGRABuildNumber(inStream.getInteger());
        obj.setEncProfile(inStream.getBytes());
        obj.setFrontendId(inStream.getString());
        obj.setLoginId(inStream.getString());
        obj.setMessageVector(inStream.getObjectArray());
        obj.setPassword(inStream.getString());
        obj.setProductId(inStream.getString());
        obj.setSessionKey(inStream.getString());
        obj.setType(inStream.getInteger());
        obj.setUcid(inStream.getString());
    }

    writeHandShake(outStream: TSDataOutputStream, obj: HandShake): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getARABuildNumber());
        outStream.putInteger(obj.getGRABuildNumber());
        outStream.putBytes(obj.getEncProfile());
        outStream.putString(obj.getFrontendId());
        outStream.putString(obj.getLoginId());
        outStream.putObjectArray(obj.getMessageVector());
        outStream.putString(obj.getPassword());
        outStream.putString(obj.getProductId());
        outStream.putString(obj.getSessionKey());
        outStream.putInteger(obj.getType());
        outStream.putString(obj.getUcid());
    }
    readHandShakeResponse(inStream: TSDataInputStream, obj: HandShakeResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDesc(inStream.getStringEx());
        obj.setEffectiveLanguage(inStream.getString());
        obj.setProductCurrencyCode(inStream.getString());
        obj.setResponseId(inStream.getInteger());
        obj.setServerType(inStream.getByte());
        obj.setSessionKey(inStream.getString());
        obj.setShowCaptcha(inStream.getBoolean());
    }

    writeHandShakeResponse(outStream: TSDataOutputStream, obj: HandShakeResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringEx(obj.getDesc());
        outStream.putString(obj.getEffectiveLanguage());
        outStream.putString(obj.getProductCurrencyCode());
        outStream.putInteger(obj.getResponseId());
        outStream.putByte(obj.getServerType());
        outStream.putString(obj.getSessionKey());
        outStream.putBoolean(obj.getShowCaptcha());
    }
    readExtendedAttribs(inStream: TSDataInputStream, obj: ExtendedAttribs): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setExtendedAttribs(inStream.getString2StringMap());
    }

    writeExtendedAttribs(outStream: TSDataOutputStream, obj: ExtendedAttribs): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getExtendedAttribs());
    }
    readLogout(inStream: TSDataInputStream, obj: Logout): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeLogout(outStream: TSDataOutputStream, obj: Logout): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readSubscriptionRequest(inStream: TSDataInputStream, obj: SubscriptionRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginInProgress(inStream.getBoolean());
        obj.setOldSubscribedDomain(inStream.getInteger());
        obj.setOperationCode(inStream.getInteger());
        obj.setRegInProgress(inStream.getBoolean());
    }

    writeSubscriptionRequest(outStream: TSDataOutputStream, obj: SubscriptionRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getLoginInProgress());
        outStream.putInteger(obj.getOldSubscribedDomain());
        outStream.putInteger(obj.getOperationCode());
        outStream.putBoolean(obj.getRegInProgress());
    }
    readTimeSyncRequest(inStream: TSDataInputStream, obj: TimeSyncRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestSentTickCount(inStream.getInteger());
    }

    writeTimeSyncRequest(outStream: TSDataOutputStream, obj: TimeSyncRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestSentTickCount());
    }
    readServerTime(inStream: TSDataInputStream, obj: ServerTime): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClientTickCount(inStream.getLong());
        obj.setServerClock(inStream.getLong());
        obj.setTimeEST(inStream.getLong());
    }

    writeServerTime(outStream: TSDataOutputStream, obj: ServerTime): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getClientTickCount());
        outStream.putLong(obj.getServerClock());
        outStream.putLong(obj.getTimeEST());
    }
    readResponseSubscribeFailed(inStream: TSDataInputStream, obj: ResponseSubscribeFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseSubscribeFailed(outStream: TSDataOutputStream, obj: ResponseSubscribeFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseSubscribeNotAllowed(inStream: TSDataInputStream, obj: ResponseSubscribeNotAllowed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseSubscribeNotAllowed(outStream: TSDataOutputStream, obj: ResponseSubscribeNotAllowed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseSubscribeSuccess(inStream: TSDataInputStream, obj: ResponseSubscribeSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseSubscribeSuccess(outStream: TSDataOutputStream, obj: ResponseSubscribeSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseUnsubscribeFailed(inStream: TSDataInputStream, obj: ResponseUnsubscribeFailed): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseUnsubscribeFailed(outStream: TSDataOutputStream, obj: ResponseUnsubscribeFailed): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseUnsubscribeSuccess(inStream: TSDataInputStream, obj: ResponseUnsubscribeSuccess): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeResponseUnsubscribeSuccess(outStream: TSDataOutputStream, obj: ResponseUnsubscribeSuccess): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readNotifyPlayerProfile(inStream: TSDataInputStream, obj: NotifyPlayerProfile): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setEncryptedProfile(inStream.getBytes());
        obj.setSessionKey(inStream.getString());
    }

    writeNotifyPlayerProfile(outStream: TSDataOutputStream, obj: NotifyPlayerProfile): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBytes(obj.getEncryptedProfile());
        outStream.putString(obj.getSessionKey());
    }
    readRequestDomainMapping(inStream: TSDataInputStream, obj: RequestDomainMapping): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestDomainMapping(outStream: TSDataOutputStream, obj: RequestDomainMapping): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readDomainMapping(inStream: TSDataInputStream, obj: DomainMapping): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDomainMap(inStream.getString2StringMap());
    }

    writeDomainMapping(outStream: TSDataOutputStream, obj: DomainMapping): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString2StringMap(obj.getDomainMap());
    }
    readDomainChangeAdvice(inStream: TSDataInputStream, obj: DomainChangeAdvice): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDomainName(inStream.getString());
        obj.setValidityMode(inStream.getInteger());
    }

    writeDomainChangeAdvice(outStream: TSDataOutputStream, obj: DomainChangeAdvice): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getDomainName());
        outStream.putInteger(obj.getValidityMode());
    }
    readUCID(inStream: TSDataInputStream, obj: UCID): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setId(inStream.getString());
    }

    writeUCID(outStream: TSDataOutputStream, obj: UCID): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getId());
    }
}
