import { Message } from '../../message';

export class LSLobbyCardDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSLobbyCardDeltaUpdate';
    private deltaUpdatesList: any[] | null = null;
    private allCardsOrder: any[] | null = null;
    private snapShotTimeInNanos: number = 0;
    private lobbyType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        deltaUpdatesList: any[] | null = null,
        allCardsOrder: any[] | null = null,
        snapShotTimeInNanos: number = 0,
        lobbyType: number = 0,
    ) {
        super(reqServerPeerId);
        this.deltaUpdatesList = deltaUpdatesList;
        this.allCardsOrder = allCardsOrder;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.lobbyType = lobbyType;
    }

    getDeltaUpdatesList(): any[] | null {
        return this.deltaUpdatesList;
    }

    setDeltaUpdatesList(deltaUpdatesList: any[] | null) {
        this.deltaUpdatesList = deltaUpdatesList;
    }
    getAllCardsOrder(): any[] | null {
        return this.allCardsOrder;
    }

    setAllCardsOrder(allCardsOrder: any[] | null) {
        this.allCardsOrder = allCardsOrder;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getLobbyType(): number {
        return this.lobbyType;
    }

    setLobbyType(lobbyType: number) {
        this.lobbyType = lobbyType;
    }
}
