import { Pipe, PipeTransform } from '@angular/core';

import { currencyConverterMap } from './currency-map';

@Pipe({
    name: 'FormatamountPipe',
    standalone: true,
})
export class FormatamountPipe extends currencyConverterMap implements PipeTransform {
    transform(count: number, symbol): any {
        let base;
        count = count / 100;
        if (count < 10000) {
            return (
                this.currencySymbolMap(symbol) +
                (count % 1 === 0 ? count.toLocaleString() : count.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            );
        }
        count.toString().length > 4 ? (base = 1000) : (base = 10000);
        const COUNT_ABBRS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
        let i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(base));
        if (i < 0) {
            i = 0;
        }
        const result = parseFloat((count / Math.pow(1000, i)).toFixed(2));
        return this.currencySymbolMap(symbol) + (result + `${COUNT_ABBRS[i < 0 ? 0 : i]}`);
    }
}
