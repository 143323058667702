import { Message } from '../../message';

export class LSTabIdPoolDetails extends Message {
    private MESSAGE_NAME: string = 'LSTabIdPoolDetails';
    private snapShotTimeInNanos: number = 0;
    private fullUpdate: boolean | null = false;
    private pools: any[] | null = null;
    private deletedPools: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        snapShotTimeInNanos: number = 0,
        fullUpdate: boolean | null = false,
        pools: any[] | null = null,
        deletedPools: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.fullUpdate = fullUpdate;
        this.pools = pools;
        this.deletedPools = deletedPools;
    }

    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getFullUpdate(): boolean | null {
        return this.fullUpdate;
    }

    setFullUpdate(fullUpdate: boolean | null) {
        this.fullUpdate = fullUpdate;
    }
    getPools(): any[] | null {
        return this.pools;
    }

    setPools(pools: any[] | null) {
        this.pools = pools;
    }
    getDeletedPools(): any[] | null {
        return this.deletedPools;
    }

    setDeletedPools(deletedPools: any[] | null) {
        this.deletedPools = deletedPools;
    }
}
