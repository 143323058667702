<div class="homepage-section" *ngIf="softwareupdatesEnabled">
    <div class="col lobby-width">
        <div class="row">
            <div class="col-md-6 text-center">
                <img *ngIf="softwareUpdatesContent.image" [src]="softwareUpdatesContent.image.src" alt="Software Section" class="img-fluid" />
            </div>
            <div class="col-md-5 pc-txt decription-section">
                <div class="decription-section-inner">
                    <h2 class="heading-text">{{ softwareUpdatesContent.title }}</h2>
                    <div class="home-section-desc" [innerHtml]="softwareUpdatesContent.text"></div>
                    <div class="homepage-button" *ngIf="softwareUpdatesContent.optionalText" [innerHtml]="softwareUpdatesContent.optionalText"></div>
                    <div class="homepage-button" *ngIf="softwareUpdatesContent.titleLink.linkText">
                        <a
                            class="ml-4"
                            [href]="softwareUpdatesContent.titleLink.url"
                            [target]="softwareUpdatesContent.titleLink.attributes.target || '_self'"
                            >{{ softwareUpdatesContent.titleLink.linkText }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
