import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ClaimsService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subject } from 'rxjs';

import { DemoVideoComponent } from './demo-video/demo-video.component';
import { GetStartedCheckComponent } from './get-started-check/get-started-check.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { DemoGameTableComponent } from './poker-trainer/pokertrainer/game/game-table/demo-game-table.component';
import { PlaybuttonComponent } from './poker-trainer/pokertrainer/game/playbutton/playbutton.component';
import { PokerTrainerModule } from './poker-trainer/pokertrainer/game/pokerTrainer.module';
import { SimulatedService } from './simulated-hands.service';

@Component({
    selector: 'pk-simulated-hands',
    templateUrl: 'simulated-hands.component.html',
    standalone: true,

    imports: [
        CommonModule,
        RouterModule,
        PokerTrainerModule,
        ReactiveFormsModule,
        SwiperComponent,
        GetStartedComponent,
        GetStartedCheckComponent,
        DemoVideoComponent,
        FormsModule,
        PlaybuttonComponent,
    ],
})
export class SimulatedHandsComponent extends PCComponent implements OnInit {
    isReplayerThree: boolean = false;
    getStartedModal: boolean = false;
    getStartedCheckModal: boolean = true;
    screenname: any;
    unsubscribe = new Subject<void>();
    @Input() clientContent: any;
    public isDemoSelect = false;
    @ViewChild('demoTable', { static: false }) demoTableEle: DemoGameTableComponent;
    constructor(
        private serve: SimulatedService,
        private claimService: ClaimsService,
    ) {
        super();
    }

    @HostBinding()
    get class() {
        return this.createClass(this.item?.name);
    }

    ngOnInit(): void {
        if (this.clientContent && !this.item) {
            this.item = this.clientContent;
        }
        // Check screenname from native client
        if (this.getParameterByName('screenname')) {
            this.screenname = this.getParameterByName('screenname');
        } else {
            // Check screenname from native client
            this.screenname = this.claimService.get('screenname');
        }

        this.checkNativeClient();

        const bodyElement = document.getElementsByTagName('body')[0];
        if (bodyElement.className.indexOf('noscroll') <= -1) {
            bodyElement.className += ' noscroll';
        }
    }

    // Check if native client
    checkNativeClient() {
        const invokerapp: any = this.getParameterByName('invokerapp');
        this.serve.nativeApp = !!invokerapp || !!this.clientContent;
    }

    onNicknamePassed(eventData: { nickname: string; getStartedModal: boolean }) {
        this.getStartedModal = eventData.getStartedModal;
        this.serve.playerName = eventData.nickname;
        this.demoTableEle.showPlayerInfo();
    }
    getCheckEvent(eventData: { getCheckEvent: boolean }) {
        this.getStartedCheckModal = false;
        if (eventData.getCheckEvent) {
            if (this.screenname) {
                this.getStartedCheckModal = false;
                this.serve.playerName = this.screenname;
                this.demoTableEle.showPlayerInfo();
            } else {
                this.getStartedModal = true;
            }
        } else {
            this.isDemoSelect = true;
        }
    }

    showGetCheckModal(eventData: { getStartedModal: boolean; getStartedCheckModal: boolean }) {
        this.getStartedModal = eventData.getStartedModal;
        this.getStartedCheckModal = eventData.getStartedCheckModal;
    }

    getParameterByName(name: any) {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParam = urlParams.get(name);
        return queryParam;
    }

    hideDemo() {
        this.isDemoSelect = false;
        if (this.screenname) {
            this.getStartedCheckModal = false;
            this.serve.playerName = this.screenname;
            this.demoTableEle.showPlayerInfo();
        } else {
            this.getStartedModal = true;
        }
    }

    ngOnDestroy() {
        const bodyElement = document.getElementsByTagName('body')[0];
        bodyElement.classList.remove('noscroll');
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
