import { Message } from '../../message';

export class DiamondBalance extends Message {
    private MESSAGE_NAME: string = 'DiamondBalance';
    private currPromoCurrBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currPromoCurrBalance: number = 0) {
        super(reqServerPeerId);
        this.currPromoCurrBalance = currPromoCurrBalance;
    }

    getCurrPromoCurrBalance(): number {
        return this.currPromoCurrBalance;
    }

    setCurrPromoCurrBalance(currPromoCurrBalance: number) {
        this.currPromoCurrBalance = currPromoCurrBalance;
    }
}
