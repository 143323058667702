import { CommonModule } from '@angular/common';
import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { MetaTagsService, Page, PlainLinkComponent, UserService } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import {
    HomepageGetAllResponse,
    HomepageResourceService,
    NavigationBarComponent,
    NavigationBarPokerComponent,
    PokerHomePageClientConfig,
    ScrollToTopComponent,
} from '@pokercore/module/core';
import { LeaderBoardComponent } from '@pokercore/module/leaderboard';
import { TournamentCalendarTimerComponent } from '@pokercore/module/tournament-calendar';

import { BlogComponent } from './blog/blog.component';
import { HomePageDownloadSectionComponent } from './homepage-downloadsection.component';
import { HomepageDynamicComponent } from './homepage-dynamic.component';
import { HomePageHowtoplaySectionComponent } from './homepage-howtoplaysection.component';
import { TrustAsResourceUrlPipe } from './homepage-pipes/trust-as-resource-url.pipe';
import { HomepagePromoSectionComponent } from './homepage-promosection.component';
import { HomePageCasinosectionComponent } from './homepage-section/homepage-casinosection.component';
import { HomePageHowtoplayComponent } from './homepage-section/homepage-howtoplay.component';
import { HomepagePromoComponent } from './homepage-section/homepage-promo.component';
import { HomePageSeocontentComponent } from './homepage-section/homepage-seocontent.component';
import { HomePageSoftwareUpdateComponent } from './homepage-section/homepage-software-update.component';
import { HomePageTournamentCalendarComponent } from './homepage-section/homepage-tournament-calendar.component';
import { HomePageSeocontentSectionComponent } from './homepage-seocontentsection.component';
import { HomePageSlideSectionComponent } from './homepage-slidesection.component';
import { HomePageSocialFeedSectionComponent } from './homepage-socialfeedsection.component';
import { HomePageSoftwareUpdateSectionComponent } from './homepage-software-updatesection.component';
import { HomePageSportSectionComponent } from './homepage-sportsection.component';
import * as r from './homepage.resolvers';
import { JackpotTickerComponent } from './jackpot-ticker/jackpot-ticker.component';
import { PublicPageWrapperComponent } from './public-page-wrapper/public-page-wrapper.component';
import { TeasersComponent } from './teasers/teasers.component';
import { TouchSwiperComponent } from './touch-swiper/touch-swiper.component';
import { TwitterComponent } from './twitter/twitter.component';

@Component({
    selector: 'pt-homepage',
    templateUrl: 'homepage.component.html',
    standalone: true,
    imports: [
        CommonModule,
        HomePageHowtoplayComponent,
        HomepageDynamicComponent,
        HomePageSoftwareUpdateSectionComponent,
        HomePageSeocontentComponent,
        HomePageDownloadSectionComponent,
        HomePageSportSectionComponent,
        HomepagePromoSectionComponent,
        HomePageHowtoplaySectionComponent,
        HomePageSeocontentSectionComponent,
        HomePageSlideSectionComponent,
        PlainLinkComponent,
        BlogComponent,
        TwitterComponent,
        TouchSwiperComponent,
        PublicPageWrapperComponent,
        JackpotTickerComponent,
        LeaderBoardComponent,
        NavigationBarComponent,
        MatGridListModule,
        TeasersComponent,
        TournamentCalendarTimerComponent,
        ScrollToTopComponent,
        TrustAsResourceUrlPipe,
    ],
    providers: [HomepageResourceService, r.HomepageResolver],
})
export class HomepageComponent implements OnInit {
    @Input() data: any;
    componentRef_: any;
    @ViewChild('template', { read: ViewContainerRef }) container: ViewContainerRef;

    initData: HomepageGetAllResponse;
    lang: string;
    showConfigureNavigation: boolean = false;
    disableHtmlHeadTag: boolean = false;
    componetsview = {
        HomePageSlideSectionComponent: HomePageSlideSectionComponent,
        HomePageDownloadSectionComponent: HomePageDownloadSectionComponent,
        HomepagePromoSectionComponent: HomepagePromoSectionComponent,
        HomePageSoftwareUpdateSectionComponent: HomePageSoftwareUpdateSectionComponent,
        HomePageSportSectionComponent: HomePageSportSectionComponent,
        HomePageSocialFeedSectionComponent: HomePageSocialFeedSectionComponent,
        HomePageHowtoplaySectionComponent: HomePageHowtoplaySectionComponent,
        HomePageSeocontentSectionComponent: HomePageSeocontentSectionComponent,
        NavigationBarComponent: NavigationBarComponent,
        NavigationBarPokerComponent: NavigationBarPokerComponent,
        HomePageSoftwareUpdateComponent: HomePageSoftwareUpdateComponent,
        HomepagePromoComponent: HomepagePromoComponent,
        HomePageSeocontentComponent: HomePageSeocontentComponent,
        TeasersComponent: TeasersComponent,
        HomePageHowtoplayComponent: HomePageHowtoplayComponent,
        HomePageCasinosectionComponent: HomePageCasinosectionComponent,
        HomePageTournamentCalendarComponent: HomePageTournamentCalendarComponent,
        HomepageDynamicComponent: HomepageDynamicComponent,
    };
    constructor(
        private routeData: RouteDataService,
        public user: UserService,
        private metaTagsService: MetaTagsService,
        private page: Page,
        private homePageService: HomepageResourceService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private componentResolver: ComponentFactoryResolver,
    ) {}

    getPromoLink(promoName: string) {
        this.lang = this.page.lang;
        if (promoName != null) {
            const tokens = promoName.split('/').slice(2);
            promoName = tokens.join('/');
        }
        return `${this.lang}/${promoName}`;
    }

    getSeoTags(id: string) {
        const re = /<\/?p>/gi;
        const content = this.initData.viewModel.contentItems;
        const seoObj = content.filter((item: any) => item.metadata && item.metadata.id.itemName === id);
        return seoObj && seoObj[0].text ? seoObj[0].text.replace(re, '') : '';
    }

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;

        const el = document.getElementById('main-content') as HTMLElement;
        el.className = 'fixed-width';
        this.initData = this.routeData.getInitData();
        this.homePageService.dynamicContent = this.initData;
        if (this.initData.viewModel.contentItems && this.initData.viewModel.contentItems.length > 0) {
            this.metaTagsService.whenReady.subscribe(() => {
                this.metaTagsService.setPageTags(this.getSeoTags('seotitle'), {
                    description: this.getSeoTags('seodescription'),
                });
            });
        }
    }
    ngAfterViewInit() {
        this.generateDynamicComponents();
    }

    generateDynamicComponents() {
        let componentList = this.pokerHomePageClientConfig.homePageComposition;
        if (this.pokerHomePageClientConfig.homePageComposition.constructor === Array) {
            for (let i = 0; i < componentList.length; i++) {
                if (componentList[i] && componentList[i].Enabled) {
                    const componentFactory = this.componentResolver.resolveComponentFactory(this.componetsview[componentList[i].Name]);
                    this.componentRef_ = this.container.createComponent(componentFactory);
                    this.componentRef_.instance = componentFactory;
                }
            }
        } else {
            for (let key in componentList) {
                if (componentList[key] && componentList[key].Enabled) {
                    const componentFactory = this.componentResolver.resolveComponentFactory(this.componetsview[key]);
                    this.componentRef_ = this.container.createComponent(componentFactory);
                    this.componentRef_.instance = componentFactory;
                }
            }
        }
    }

    mergeInputData(src: any) {
        const data: Object = {};
        for (const key in src) {
            data[key] = src[key];
        }
        return data;
    }

    ngOnDestroy() {
        if (this.componentRef_ != null && this.componentRef_ != undefined) {
            this.componentRef_.destroy();
        }
    }
}
