import { Injectable } from '@angular/core';

import { CookieService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class VCClientService {
    constructor(private cookieService: CookieService) {}
    isClient(): boolean {
        if (
            this.cookieService.get('NativeApp') &&
            (this.cookieService.get('NativeApp') === 'pokervc' || this.cookieService.get('NativeApp') === 'pokerwd')
        ) {
            return true;
        } else {
            return false;
        }
    }
    isMobileApp() {
        if (
            this.cookieService.get('NativeApp') &&
            (this.cookieService.get('NativeApp') === 'POKER' || this.cookieService.get('NativeApp') === 'pokerwc')
        ) {
            return true;
        } else {
            return false;
        }
    }
    isMacDesktop() {
        if (this.cookieService.get('NativeApp') && this.cookieService.get('NativeApp') === 'pokermc') {
            return true;
        } else {
            return false;
        }
    }
}
