import { Message } from '../../message';

export class RequestUnSubscribePlayerTrackInfo extends Message {
    private MESSAGE_NAME: string = 'RequestUnSubscribePlayerTrackInfo';
    private mtctId: number = 0;
    private tournamentCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, tournamentCategory: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tournamentCategory = tournamentCategory;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
}
