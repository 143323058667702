import { Message } from '../../message';

export class ResponseSNGInstance extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGInstance';
    private requestId: number = 0;
    private mtctId: number = 0;
    private responseId: number = 0;
    private sourceType: number = 0;
    private tourneyCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        requestId: number = 0,
        mtctId: number = 0,
        responseId: number = 0,
        sourceType: number = 0,
        tourneyCategory: number = 0,
    ) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.mtctId = mtctId;
        this.responseId = responseId;
        this.sourceType = sourceType;
        this.tourneyCategory = tourneyCategory;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getSourceType(): number {
        return this.sourceType;
    }

    setSourceType(sourceType: number) {
        this.sourceType = sourceType;
    }
    getTourneyCategory(): number {
        return this.tourneyCategory;
    }

    setTourneyCategory(tourneyCategory: number) {
        this.tourneyCategory = tourneyCategory;
    }
}
