import { Message } from '../../message';

export class ServerTime extends Message {
    private MESSAGE_NAME: string = 'ServerTime';
    private clientTickCount: number = 0;
    private serverClock: number = 0;
    private timeEST: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clientTickCount: number = 0, serverClock: number = 0, timeEST: number = 0) {
        super(reqServerPeerId);
        this.clientTickCount = clientTickCount;
        this.serverClock = serverClock;
        this.timeEST = timeEST;
    }

    getClientTickCount(): number {
        return this.clientTickCount;
    }

    setClientTickCount(clientTickCount: number) {
        this.clientTickCount = clientTickCount;
    }
    getServerClock(): number {
        return this.serverClock;
    }

    setServerClock(serverClock: number) {
        this.serverClock = serverClock;
    }
    getTimeEST(): number {
        return this.timeEST;
    }

    setTimeEST(timeEST: number) {
        this.timeEST = timeEST;
    }
}
