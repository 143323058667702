export enum TableEvents {
    POSTING_BLINDS,
    POST_ANTE,
    ALL_IN_ANTE,
    ALL_IN_SMALL_BLIND,
    ALL_IN_BRING_IN,
    ALL_IN_DOUBLE_BET,
    POST_DUMMY_ANTE,
    POSTING_ANTES,
    ALL_IN_BUTTON_ANTE,
    ALL_IN_DOUBLE_RAISE,
    POST_BUTTON_ANTE,
    POST_SMALL_BLIND,
    POST_BIG_BLIND,
    DOUBLE_BET,
    PLAYERS_ANTE_EVENT,
    POST_NEW_PLAYER_BLIND,
    ALL_IN_BIG_BLIND,
    ALL_IN_OTHER_BLIND,
    MISSED_SMALL_BLIND,
    PLAYER_TIMED_OUT,
    POST_OTHER_BLIND,
    POST_BRING_IN,
    COMPLETE_BRING_IN,
    ALL_IN_COMPLETE_BRING_IN,
    DEALING_HOLE_CARDS,
    DEALING_THIRD_STREET,
    DEALING_FOURTH_STREET,
    DEALING_FIFTH_STREET,
    DEALING_SIXTH_STREET,
    DEALING_SEVENTH_STREET,
    CARD_HIDDEN,
    PLAYER_HOLECARDS_EVENT,
    SEVENCARD_PLAYER_HOLECARDS_EVENT,
    SEVENCARD_DEALINGFOURTHSTREET_PLAYER_HOLECARDS_EVENT,
    SEVENCARD_DEALINGFIFTHSTREET_PLAYER_HOLECARDS_EVENT,
    SEVENCARD_DEALINGSIXTHSTREET_PLAYER_HOLECARDS_EVENT,
    SEVENCARD_DEALINGSEVENTHSTREET_PLAYER_HOLECARDS_EVENT,
    CARD_SHOWN,
    OPTION_SET,
    FOLD,
    ALL_IN_RAISE,
    ALL_IN_CALL,
    ALL_IN_BET,
    CHECK,
    CALL,
    BET,
    RAISE,
    DOUBLE_RAISE,
    POT_CREATED,
    COMMUNITY_CARD,
    DEALING_FLOP,
    DEALING_TURN,
    DEALING_RIVER,
    SHOWDOWN,
    SELECTED_ADVANCED,
    DESELECTED_ADVANCED,
    PLAYER_MUCK_CARDS,
    PLAYER_SHOW_CARDS,
    PLAYER_HURRY_UP,
    COMMUNITY_CARD_TASK,
    RIT_COMMUNITY_CARD_TASK,
    PLAYER_CHAT,
    UNKNOWN_CARDS,
    WIN_HIGH,
    RIT_WIN_HIGH,
    WIN_LOW,
    CASHOUT,
    HAND_STRENGTH,
}
