import { Routes } from '@angular/router';

import { PokerTrickettsRoomSynopsisResolver } from './poker-tricketts-room-synopsis.resolver';
import { PokerTrickettsRoomResolver } from './poker-tricketts-room.resolver';

export const pokerTrickettsRoomRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTrickettsRoomResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerTrickettsRoomSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTrickettsRoomResolver,
        },
    },
];
