import { Message } from '../../message';

export class TableReduceBalanceLimit extends Message {
    private MESSAGE_NAME: string = 'TableReduceBalanceLimit';
    private balanceLimit: number = 0;
    private gameCurrency: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, balanceLimit: number = 0, gameCurrency: string | null = null) {
        super(reqServerPeerId);
        this.balanceLimit = balanceLimit;
        this.gameCurrency = gameCurrency;
    }

    getBalanceLimit(): number {
        return this.balanceLimit;
    }

    setBalanceLimit(balanceLimit: number) {
        this.balanceLimit = balanceLimit;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
}
