import { Message } from '../../message';
import { PlayBalance } from './PlayBalance';
import { RealBalance } from './RealBalance';
import { TourneyCurrencyBalance } from './TourneyCurrencyBalance';

export class ResponseUserBalanceInfo extends Message {
    private MESSAGE_NAME: string = 'ResponseUserBalanceInfo';
    private playBalance: PlayBalance | null = null;
    private realBalance: RealBalance | null = null;
    private tourneyCurrencyBalance: TourneyCurrencyBalance | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playBalance: PlayBalance | null = null,
        realBalance: RealBalance | null = null,
        tourneyCurrencyBalance: TourneyCurrencyBalance | null = null,
    ) {
        super(reqServerPeerId);
        this.playBalance = playBalance;
        this.realBalance = realBalance;
        this.tourneyCurrencyBalance = tourneyCurrencyBalance;
    }

    getPlayBalance(): PlayBalance | null {
        return this.playBalance;
    }

    setPlayBalance(playBalance: PlayBalance | null) {
        this.playBalance = playBalance;
    }
    getRealBalance(): RealBalance | null {
        return this.realBalance;
    }

    setRealBalance(realBalance: RealBalance | null) {
        this.realBalance = realBalance;
    }
    getTourneyCurrencyBalance(): TourneyCurrencyBalance | null {
        return this.tourneyCurrencyBalance;
    }

    setTourneyCurrencyBalance(tourneyCurrencyBalance: TourneyCurrencyBalance | null) {
        this.tourneyCurrencyBalance = tourneyCurrencyBalance;
    }
}
