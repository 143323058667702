import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoadingIndicatorService, NavigationService, Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { PPLEventClientConfig } from '@pokercore/module/core';

import { PPLGalleryContainerService } from './ppl-gallery-container.service';

@Component({
    selector: 'ppl-gallery-container',
    templateUrl: 'ppl-gallery-container.component.html',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    standalone: true,
    imports: [CommonModule],
})
export class PPLGalleryContainerComponent extends PCComponent {
    @ViewChild('thumbnailContainer') thumbnailContainer: ElementRef;

    slideIndex = 0;
    galleryItems: any = [];
    galleryLayer: string = 'layer0';
    contentType: string = 'Album';
    currentGalleryItem: string;
    location: Location;
    pageTitle: string;
    hashFragment: any;
    isPopState: boolean = false;
    isModalVisible = false;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    constructor(
        private galleryService: PPLGalleryContainerService,
        private loadingIndicatorService: LoadingIndicatorService,
        private activatedRoute: ActivatedRoute,
        location: Location,
        private page: Page,
        private navigation: NavigationService,
        private cdr: ChangeDetectorRef,
        private pplEventClientConfig: PPLEventClientConfig,
    ) {
        super();
        this.location = location;

        this.location.subscribe((value: any) => this.locationPop(value));

        const hash = window.location.hash;
        this.galleryItems = [];
        if (hash) {
            const id = hash.replace('#', '');
            if (id) {
                this.galleryItems = id;
            }
        }
    }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.navigation.locationChange.subscribe((e: any) => {
            const hashFragment: string = this.activatedRoute.snapshot.fragment ?? '';

            if (hashFragment != null) {
                this.locationChangeBasedOnHashFragment(hashFragment);
            } else {
                this.galleryItems = this.item.pplGalleryContent;
                this.galleryLayer = 'layer1';
                this.pageTitle = this.item.pplGalleryStaticData.galleryPageTitle;
            }
        });

        this.contentType = this.item.parameters.contentType;
        const hashFragment: string = this.activatedRoute.snapshot.fragment ?? '';

        if (hashFragment && this.item.pplGalleryContent.length > 0) {
            if (this.activatedRoute.snapshot.fragment) {
                this.locationChangeBasedOnHashFragment(this.activatedRoute.snapshot.fragment);
            }
        } else {
            this.pageTitle = this.item.pplGalleryStaticData.galleryPageTitle;
            if (this.item.parameters.contentType === 'album') {
                this.galleryItems = this.item.pplGalleryContent[0].properties;
            } else {
                this.galleryItems = this.item.pplGalleryContent;

                this.pplEventClientConfig.whenReady.subscribe(() => {
                    const path = this.pplEventClientConfig.eventsFolderConfigurationPath;
                    const url = `/${this.page.lang}/${path}/gallery`;
                    this.location.go(url, '');
                });
            }
        }
    }

    goToItemDetails(item: any, isCategoryItem: boolean, contentType: string) {
        this.isPopState = false;
        if (isCategoryItem) {
            this.galleryLayer = 'layer1';
            this.galleryItems = [];
            this.galleryItems = item.properties;
            this.pageTitle = item.eventTitle;
            const galleryLayerOneStateData: any[] = [{ item, isCategoryItem, contentType, galleryLayer: 'layer1' }];
            this.pplEventClientConfig.whenReady.subscribe(() => {
                const path = this.pplEventClientConfig.eventsFolderConfigurationPath;
                const url = `/${this.page.lang}/${path}/gallery#${item.hashFragment}`;
                this.location.go(url, '', { stateData: galleryLayerOneStateData });
            });
        } else {
            this.getCloudinaryItems(item.galleryNumber, contentType);
            this.galleryLayer = 'layer2';
            this.pageTitle = item.eventTitle;
        }
    }

    getCloudinaryItems(galleryNumber: number, contentType: string) {
        const loadingIndicator = this.loadingIndicatorService.start();
        this.galleryService.getCloudinaryGalleryImages(galleryNumber, contentType).subscribe({
            next: (res) => {
                this.galleryItems = [];
                if (res && res.length !== 0) {
                    this.basicLightboxExample(res, galleryNumber);
                    loadingIndicator.done();
                }
            },
            error: (e) => {
                if (e) {
                    this.galleryItems = [];
                    loadingIndicator.done();
                }
            },
        });
    }
    basicLightboxExample(res: any, galleryNumber: number) {
        const ImageQualityChange = res.map((obj) => obj.replace('/image/upload/', '/image/upload/q_auto/'));
        this.galleryItems = ImageQualityChange.map((item) => ({ type: 'image', data: { src: item, thumb: item } }));
        this.galleryLayer = 'layer2';
        if (!this.isPopState) {
            this.location.go(`/${this.page.lang}/${this.pplEventClientConfig.eventsFolderConfigurationPath}/gallery#${galleryNumber}`, '', {
                stateData: this.galleryItems,
            });
        }
    }

    locationPop(state: any) {
        this.cdr.detectChanges();
        if (state.type === 'popstate') {
            if (window.location.hash) {
                this.locationChangeBasedOnHashFragment(window.location.hash.replace('#', ''));
            }
            this.isPopState = true;
        }
    }

    locationChangeBasedOnHashFragment(hashFragment: string) {
        this.item.pplGalleryContent.forEach((element: any) => {
            if (element.hashFragment === hashFragment) {
                this.galleryLayer = 'layer1';
                this.galleryItems = element?.properties;
                this.pageTitle = element?.eventTitle;
            } else {
                element?.properties.forEach((e: any) => {
                    if (e.galleryNumber === hashFragment) {
                        this.pageTitle = e?.eventTitle;
                        this.getCloudinaryItems(Number(hashFragment), this.item.parameters.contentType);
                    }
                });
            }
        });
    }

    openModal() {
        this.isModalVisible = true;
        document.body.style.overflow = 'hidden';
    }
    closeModal() {
        this.isModalVisible = false;
        document.body.style.overflow = 'auto';
    }

    // Listen for the 'escape' key press to close the popup
    @HostListener('document:keydown.escape', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onKeydownHandler(event: KeyboardEvent) {
        this.closeModal();
    }

    plusSlides(n: number) {
        this.showSlides((this.slideIndex += n));
        this.moveThumbnailContainer();
    }

    currentSlide(n: number) {
        this.showSlides((this.slideIndex = n));
        this.moveThumbnailContainer();
    }

    showSlides(n: number) {
        let i;
        const slides = document.getElementsByClassName('img-slides') as HTMLCollectionOf<HTMLElement>;
        const dots = document.getElementsByClassName('thumbnail') as HTMLCollectionOf<HTMLElement>;
        if (n > slides.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].classList.remove('active');
        }
        slides[this.slideIndex - 1].style.display = 'block';
        if (dots && dots.length > 0) {
            dots[this.slideIndex - 1].classList.add('active');
        }
    }

    moveThumbnailContainer() {
        if (this.thumbnailContainer && this.thumbnailContainer.nativeElement) {
            setTimeout(() => {
                const containerElement = this.thumbnailContainer.nativeElement;
                const thumbnails = document.getElementsByClassName('thumbnail') as HTMLCollectionOf<HTMLElement>;
                let totalWidth = 0;
                if (thumbnails && thumbnails.length > 0) {
                    for (let i = 0; i < this.slideIndex - 1; i++) {
                        totalWidth += thumbnails[i].offsetWidth;
                    }
                }
                containerElement.scrollLeft = totalWidth;
            }, 100);
        }
    }
}

export interface ImageItem {
    type: string;
    data: any;
}

export interface GalleryItem {
    data?: any;
    type?: string;
}
