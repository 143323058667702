import { Message } from '../../message';
import { LobbyBigBlindChange } from './LobbyBigBlindChange';

export class DealerSbAndBbPositions extends Message {
    private MESSAGE_NAME: string = 'DealerSbAndBbPositions';
    private lobbyBigBlindChange: LobbyBigBlindChange | null = null;
    private tableId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lobbyBigBlindChange: LobbyBigBlindChange | null = null, tableId: number = 0) {
        super(reqServerPeerId);
        this.lobbyBigBlindChange = lobbyBigBlindChange;
        this.tableId = tableId;
    }

    getLobbyBigBlindChange(): LobbyBigBlindChange | null {
        return this.lobbyBigBlindChange;
    }

    setLobbyBigBlindChange(lobbyBigBlindChange: LobbyBigBlindChange | null) {
        this.lobbyBigBlindChange = lobbyBigBlindChange;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
}
