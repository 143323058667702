import { Message } from '../../message';

export class ResponsePlayerTrackInfo extends Message {
    private MESSAGE_NAME: string = 'ResponsePlayerTrackInfo';
    private mtctId: number = 0;
    private chipsInPlay: number = 0;
    private playerCurrentPosition: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, chipsInPlay: number = 0, playerCurrentPosition: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.chipsInPlay = chipsInPlay;
        this.playerCurrentPosition = playerCurrentPosition;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getChipsInPlay(): number {
        return this.chipsInPlay;
    }

    setChipsInPlay(chipsInPlay: number) {
        this.chipsInPlay = chipsInPlay;
    }
    getPlayerCurrentPosition(): number {
        return this.playerCurrentPosition;
    }

    setPlayerCurrentPosition(playerCurrentPosition: number) {
        this.playerCurrentPosition = playerCurrentPosition;
    }
}
