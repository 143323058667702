import { Message } from '../../message';

export class ArjelPlayerCards extends Message {
    private MESSAGE_NAME: string = 'ArjelPlayerCards';
    private cards: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, cards: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.cards = cards;
    }

    getCards(): Map<any, any> | null {
        return this.cards;
    }

    setCards(cards: Map<any, any> | null) {
        this.cards = cards;
    }
}
