import { Message } from '../../message';

export class RequestLSMTCTParticipantNames extends Message {
    private MESSAGE_NAME: string = 'RequestLSMTCTParticipantNames';
    private mtctId: number = 0;
    private participantNos: any[] | null = null;
    private tournamentCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, participantNos: any[] | null = null, tournamentCategory: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.participantNos = participantNos;
        this.tournamentCategory = tournamentCategory;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getParticipantNos(): any[] | null {
        return this.participantNos;
    }

    setParticipantNos(participantNos: any[] | null) {
        this.participantNos = participantNos;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
}
