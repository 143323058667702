import { Message } from '../../message';

export class ArjelBlindsRoundData extends Message {
    private MESSAGE_NAME: string = 'ArjelBlindsRoundData';
    private blindRoundPlayerData: Map<any, any> | null = null;
    private date: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, blindRoundPlayerData: Map<any, any> | null = null, date: number = 0) {
        super(reqServerPeerId);
        this.blindRoundPlayerData = blindRoundPlayerData;
        this.date = date;
    }

    getBlindRoundPlayerData(): Map<any, any> | null {
        return this.blindRoundPlayerData;
    }

    setBlindRoundPlayerData(blindRoundPlayerData: Map<any, any> | null) {
        this.blindRoundPlayerData = blindRoundPlayerData;
    }
    getDate(): number {
        return this.date;
    }

    setDate(date: number) {
        this.date = date;
    }
}
