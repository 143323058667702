import { Message } from '../../message';

export class ReqShowEmoji extends Message {
    private MESSAGE_NAME: string = 'ReqShowEmoji';
    private emojiId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, emojiId: number = 0) {
        super(reqServerPeerId);
        this.emojiId = emojiId;
    }

    getEmojiId(): number {
        return this.emojiId;
    }

    setEmojiId(emojiId: number) {
        this.emojiId = emojiId;
    }
}
