import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as ptw from './poker-upcoming-tournaments.component';

@Injectable({ providedIn: 'root' })
export class PokerUpcomingTournamentsPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('UpcomingTournamentFeedTemplate', ptw.PokerUpcomingTornamentsComponent);
        });
    }
}
