import { Message } from '../../message';

export class AutoOptionToOptionsMappings extends Message {
    private MESSAGE_NAME: string = 'AutoOptionToOptionsMappings';
    private mappings: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mappings: any[] | null = null) {
        super(reqServerPeerId);
        this.mappings = mappings;
    }

    getMappings(): any[] | null {
        return this.mappings;
    }

    setMappings(mappings: any[] | null) {
        this.mappings = mappings;
    }
}
