import { Message } from '../../message';

export class ChangeTabRequest extends Message {
    private MESSAGE_NAME: string = 'ChangeTabRequest';
    private lobbyTypeId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lobbyTypeId: number = 0) {
        super(reqServerPeerId);
        this.lobbyTypeId = lobbyTypeId;
    }

    getLobbyTypeId(): number {
        return this.lobbyTypeId;
    }

    setLobbyTypeId(lobbyTypeId: number) {
        this.lobbyTypeId = lobbyTypeId;
    }
}
