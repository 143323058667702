import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

import { TouchSwiperComponent } from './touch-swiper/touch-swiper.component';

@Component({
    selector: 'homepage-slidesection',
    templateUrl: 'homepage-slidesection.component.html',
    standalone: true,
    imports: [CommonModule, TouchSwiperComponent],
})
export class HomePageSlideSectionComponent implements OnInit {
    initData: any;

    constructor(private homePageService: HomepageResourceService) {}
    ngOnInit() {
        this.initData = this.homePageService.dynamicContent;
    }
}
