import { Message } from '../../message';

export class SendMTCTParticipantNames extends Message {
    private MESSAGE_NAME: string = 'SendMTCTParticipantNames';
    private mtctId: number = 0;
    private rangeStart: number = 0;
    private rangeEnd: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, rangeStart: number = 0, rangeEnd: number = 0) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.rangeStart = rangeStart;
        this.rangeEnd = rangeEnd;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getRangeStart(): number {
        return this.rangeStart;
    }

    setRangeStart(rangeStart: number) {
        this.rangeStart = rangeStart;
    }
    getRangeEnd(): number {
        return this.rangeEnd;
    }

    setRangeEnd(rangeEnd: number) {
        this.rangeEnd = rangeEnd;
    }
}
