import { Routes } from '@angular/router';

import { PokerTheDeckSynopsisResolver } from './poker-the-deck-synopsis.resolver';
import { PokerTheDeckResolver } from './poker-the-deck.resolver';

export const pokerTheDeckRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTheDeckResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerTheDeckSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTheDeckResolver,
        },
    },
];
