import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

import { PokerCoreLazyPlaceholderComponent } from '../poker-core-lazy-placeholder/poker-core-lazy-placeholder.component';

@Component({
    selector: 'poker-twitch-feed',
    templateUrl: 'poker-twitch-feed.component.html',
    standalone: true,
    imports: [CommonModule, PokerCoreLazyPlaceholderComponent],
})
export class PokerTwitchFeedComponent extends PCComponent {
    // twitchData: any;
    // textTruncatClipTitle: any;
    // thumbnailSizes: string = '127x71';
    // videoClipUrl: any;
    // selectedClip: number;
    // videoAutoPlay: boolean;
    // liveStream: any;
    // params: any;
    // domain: any;
    //item: any;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    //ngOnInit(): void {
    // this.domain = window.location.host;
    // this.thumbnailSizes = this.item.thumbnailSize;
    // this.twitchData = this.item.twitchFeedData.twitchApi;
    // this.params = this.item;
    // this.onResizeToTruncat(window.innerWidth);
    // this.videoAutoPlay = false;
    // if(this.twitchData.length != 0){
    //     this.playVideoClip(this.twitchData[0]);
    // }
    //}

    // ngAfterViewInit() {
    //     this.twitchListHeight();
    // }

    // onResize(event: any){
    //     this.twitchListHeight();
    // }

    // videosCheck(){
    //     if(this.params.twitchDisplayOptions == 'HideWhenVideosNotAvailable' && !this.params.twitchFeedData.isLiveStreamAvailable && this.twitchData.length != 0){
    //         return true;
    //     }
    //     else if (this.params.twitchDisplayOptions == undefined && !this.params.twitchFeedData.isLiveStreamAvailable && this.twitchData.length != 0){
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    // streamCheck(){
    //     if(this.params.twitchDisplayOptions == 'HideWhenStreamNotAvailable' && !this.params.twitchFeedData.isLiveStreamAvailable && this.twitchData.length != 0){
    //         return true;
    //     }
    //     else if (this.params.twitchDisplayOptions == undefined && this.params.twitchFeedData.isLiveStreamAvailable && this.twitchData.length != 0){
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }
    // onlyVideosCheck(){
    //     if(this.params.twitchDisplayOptions == 'ShowOnlyVideos' && !this.params.twitchFeedData.isLiveStreamAvailable && this.twitchData.length != 0){
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    // playVideoClip(clip: any){
    //     this.selectedClip =  clip;
    //     this.videoAutoPlay = true;
    //     this.videoClipUrl = clip.videoEmbedUrl;
    // }

    // onResizeToTruncat(winWidth:any){
    //     let innerWidth = winWidth;

    //     if(innerWidth < 960 ){
    //         this.textTruncatClipTitle = '100';
    //     }
    //     else if(innerWidth < 1366 ){
    //         this.textTruncatClipTitle = '40';
    //     }
    //     else{
    //         this.textTruncatClipTitle = '30';
    //     }
    // }

    // twitchListHeight() {
    //     let listHeight = document.querySelectorAll('.twitchFeed .embed-responsive');

    //     for (let i = 0; i < listHeight.length; i++) {
    //         let lh = listHeight[i].clientHeight + 'px';

    //         let videoList = document.querySelectorAll('.twitchFeed .videoListWrapper');
    //         videoList[i].setAttribute("style", "height:" + lh);
    //     }
    // }

    // _decodeUriComponent(str: any) {
    //     return decodeURIComponent(str);
    // }
}
