import { Message } from '../../message';

export class SNGJPInstanceCard extends Message {
    private MESSAGE_NAME: string = 'SNGJPInstanceCard';
    private sngJPId: number = 0;
    private tournamentID: number = 0;
    private tableId: number = 0;
    private buyIn: number = 0;
    private tourneyFee: number = 0;
    private isTrnyFeeTypePercent: boolean | null = false;
    private trnyFeePercent: number = 0;
    private totalPrizePool: number = 0;
    private gameCurrency: string | null = null;
    private observerCardId: number = 0;
    private status: number = 0;
    private remainingPlayers: number = 0;
    private name: any | null = null;
    private gameType: number = 0;
    private maxSeats: number = 0;
    private gamePlayType: number = 0;
    private limitType: number = 0;
    private instanceDisplayType: number = 0;
    private sngJPType: number = 0;
    private watchersCount: number = 0;
    private startTime: any | null = null;
    private imageId: string | null = null;
    private customisedImage: boolean | null = false;
    private cardType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJPId: number = 0,
        tournamentID: number = 0,
        tableId: number = 0,
        buyIn: number = 0,
        tourneyFee: number = 0,
        isTrnyFeeTypePercent: boolean | null = false,
        trnyFeePercent: number = 0,
        totalPrizePool: number = 0,
        gameCurrency: string | null = null,
        observerCardId: number = 0,
        status: number = 0,
        remainingPlayers: number = 0,
        name: any | null = null,
        gameType: number = 0,
        maxSeats: number = 0,
        gamePlayType: number = 0,
        limitType: number = 0,
        instanceDisplayType: number = 0,
        sngJPType: number = 0,
        watchersCount: number = 0,
        startTime: any | null = null,
        imageId: string | null = null,
        customisedImage: boolean | null = false,
        cardType: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJPId = sngJPId;
        this.tournamentID = tournamentID;
        this.tableId = tableId;
        this.buyIn = buyIn;
        this.tourneyFee = tourneyFee;
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
        this.trnyFeePercent = trnyFeePercent;
        this.totalPrizePool = totalPrizePool;
        this.gameCurrency = gameCurrency;
        this.observerCardId = observerCardId;
        this.status = status;
        this.remainingPlayers = remainingPlayers;
        this.name = name;
        this.gameType = gameType;
        this.maxSeats = maxSeats;
        this.gamePlayType = gamePlayType;
        this.limitType = limitType;
        this.instanceDisplayType = instanceDisplayType;
        this.sngJPType = sngJPType;
        this.watchersCount = watchersCount;
        this.startTime = startTime;
        this.imageId = imageId;
        this.customisedImage = customisedImage;
        this.cardType = cardType;
    }

    getSngJPId(): number {
        return this.sngJPId;
    }

    setSngJPId(sngJPId: number) {
        this.sngJPId = sngJPId;
    }
    getTournamentID(): number {
        return this.tournamentID;
    }

    setTournamentID(tournamentID: number) {
        this.tournamentID = tournamentID;
    }
    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getTourneyFee(): number {
        return this.tourneyFee;
    }

    setTourneyFee(tourneyFee: number) {
        this.tourneyFee = tourneyFee;
    }
    getIsTrnyFeeTypePercent(): boolean | null {
        return this.isTrnyFeeTypePercent;
    }

    setIsTrnyFeeTypePercent(isTrnyFeeTypePercent: boolean | null) {
        this.isTrnyFeeTypePercent = isTrnyFeeTypePercent;
    }
    getTrnyFeePercent(): number {
        return this.trnyFeePercent;
    }

    setTrnyFeePercent(trnyFeePercent: number) {
        this.trnyFeePercent = trnyFeePercent;
    }
    getTotalPrizePool(): number {
        return this.totalPrizePool;
    }

    setTotalPrizePool(totalPrizePool: number) {
        this.totalPrizePool = totalPrizePool;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getObserverCardId(): number {
        return this.observerCardId;
    }

    setObserverCardId(observerCardId: number) {
        this.observerCardId = observerCardId;
    }
    getStatus(): number {
        return this.status;
    }

    setStatus(status: number) {
        this.status = status;
    }
    getRemainingPlayers(): number {
        return this.remainingPlayers;
    }

    setRemainingPlayers(remainingPlayers: number) {
        this.remainingPlayers = remainingPlayers;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
    getGamePlayType(): number {
        return this.gamePlayType;
    }

    setGamePlayType(gamePlayType: number) {
        this.gamePlayType = gamePlayType;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getInstanceDisplayType(): number {
        return this.instanceDisplayType;
    }

    setInstanceDisplayType(instanceDisplayType: number) {
        this.instanceDisplayType = instanceDisplayType;
    }
    getSngJPType(): number {
        return this.sngJPType;
    }

    setSngJPType(sngJPType: number) {
        this.sngJPType = sngJPType;
    }
    getWatchersCount(): number {
        return this.watchersCount;
    }

    setWatchersCount(watchersCount: number) {
        this.watchersCount = watchersCount;
    }
    getStartTime(): any | null {
        return this.startTime;
    }

    setStartTime(startTime: any | null) {
        this.startTime = startTime;
    }
    getImageId(): string | null {
        return this.imageId;
    }

    setImageId(imageId: string | null) {
        this.imageId = imageId;
    }
    getCustomisedImage(): boolean | null {
        return this.customisedImage;
    }

    setCustomisedImage(customisedImage: boolean | null) {
        this.customisedImage = customisedImage;
    }
    getCardType(): number {
        return this.cardType;
    }

    setCardType(cardType: number) {
        this.cardType = cardType;
    }
}
