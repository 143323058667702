import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pec from './ppl-event-widget.component';

@Injectable({ providedIn: 'root' })
export class PPLEventWidgetPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('PPLEventWidget', pec.PPLEventWidgetComponent);
        });
    }
}
