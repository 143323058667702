import { Message } from '../../message';

export class SNGJPPlayNowRequest extends Message {
    private MESSAGE_NAME: string = 'SNGJPPlayNowRequest';
    private sngJackpotID: number = 0;
    private entries: number = 0;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sngJackpotID: number = 0, entries: number = 0, conversationId: number = 0) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.entries = entries;
        this.conversationId = conversationId;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getEntries(): number {
        return this.entries;
    }

    setEntries(entries: number) {
        this.entries = entries;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
