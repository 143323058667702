import { Message } from '../../message';

export class ResponseLSMTCTParticipantNames extends Message {
    private MESSAGE_NAME: string = 'ResponseLSMTCTParticipantNames';
    private mtctId: number = 0;
    private participantNames: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, participantNames: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.participantNames = participantNames;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getParticipantNames(): any[] | null {
        return this.participantNames;
    }

    setParticipantNames(participantNames: any[] | null) {
        this.participantNames = participantNames;
    }
}
