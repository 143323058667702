import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlagsConfig } from './flags.client-config';

/**
 * @whatItDoes Provides functionality to fetch flags based on culture route value.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class FlagsService {
    /** Gets all available items. */
    get available(): Observable<{ [key: string]: string | null }> {
        return this.config.whenReady.pipe(map(() => this.config.flags));
    }

    constructor(private config: FlagsConfig) {}

    /** Finds flag image by key. */
    find(routeValue: string): Observable<string | null> {
        return this.config.whenReady.pipe(
            map(() => {
                return this.config.flags ? this.config.flags[routeValue]! : null;
            }),
        );
    }
}
