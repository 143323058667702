import { Message } from '../../message';

export class UserBalance extends Message {
    private MESSAGE_NAME: string = 'UserBalance';
    private playMoneyBalance: number = 0;
    private realMoneyBalance: number = 0;
    private realMoneyBalanceGC: number = 0;
    private balanceFor: number = 0;
    private gDollarBalanceGC: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        playMoneyBalance: number = 0,
        realMoneyBalance: number = 0,
        realMoneyBalanceGC: number = 0,
        balanceFor: number = 0,
        gDollarBalanceGC: number = 0,
    ) {
        super(reqServerPeerId);
        this.playMoneyBalance = playMoneyBalance;
        this.realMoneyBalance = realMoneyBalance;
        this.realMoneyBalanceGC = realMoneyBalanceGC;
        this.balanceFor = balanceFor;
        this.gDollarBalanceGC = gDollarBalanceGC;
    }

    getPlayMoneyBalance(): number {
        return this.playMoneyBalance;
    }

    setPlayMoneyBalance(playMoneyBalance: number) {
        this.playMoneyBalance = playMoneyBalance;
    }
    getRealMoneyBalance(): number {
        return this.realMoneyBalance;
    }

    setRealMoneyBalance(realMoneyBalance: number) {
        this.realMoneyBalance = realMoneyBalance;
    }
    getRealMoneyBalanceGC(): number {
        return this.realMoneyBalanceGC;
    }

    setRealMoneyBalanceGC(realMoneyBalanceGC: number) {
        this.realMoneyBalanceGC = realMoneyBalanceGC;
    }
    getBalanceFor(): number {
        return this.balanceFor;
    }

    setBalanceFor(balanceFor: number) {
        this.balanceFor = balanceFor;
    }
    getGDollarBalanceGC(): number {
        return this.gDollarBalanceGC;
    }

    setGDollarBalanceGC(gDollarBalanceGC: number) {
        this.gDollarBalanceGC = gDollarBalanceGC;
    }
}
