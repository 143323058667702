import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

// import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { BaseLobbyConnector } from '../../pgBackendConnectors/baseLobbyConnector';
import { PokerBackendConnector } from '../../pgBackendConnectors/pokerBackendConnector';
// import { currencyConverterMap } from '../../pipes/currency-map';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { FxRateHelperClassService } from '../../service/Fxratemanager';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { userBalanceSelect } from '../../store/user.store';

// import { CashFFBuyInPopUpComponent } from '../cash-ff-buyin/cash-ff-buyIn.component';

@Component({
    selector: 'pwc-app-p-dollar-info-pop-up',
    templateUrl: './p-dollar-info-pop-up.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, TranslatePipe],
    providers: [TranslatePipe],
    // styleUrls: ['./p-dollar-info-pop-up.component.scss']
})
export class PDollarInfoPopUpComponent extends BaseLobbyConnector {
    public isShowBalance: boolean;
    public mainBalance = 0;
    public userCurrency = 'USD';
    public userCurrencySymbol = '$';
    public tourneyBalance = '0';
    public isDecimal = false;
    availableBalanceIsDecimal = false;
    public time;
    public totalAvailableBalance = 0;
    public positionTop;
    FxRateManager;
    conversionFactor;
    @Output() closeBalanceInfo = new EventEmitter<void>();
    private readonly logger: PokerLogger;
    balanceIcons: any;

    constructor(
        private store: Store,
        loggerFactory: LoggerFactory,
        private clientToServerServices: ClientToServerRequestServices,
    ) {
        super(PokerBackendConnector.getInstance());
        this.logger = loggerFactory.getLogger('pdollarinfopopupcomponentLoggingComponent');
        // this.time = 61//this.pdollarinfoPopUpInfoService.data.data.time || 61;
        this.balanceIcons = this.clientToServerServices.webAssets['lobby']['pp-dollar'];
    }

    ngOnInit() {
        this.FxRateManager = FxRateHelperClassService.getInstance();
        this.store.select(userBalanceSelect).subscribe((userBalance) => {
            if (!_.isEmpty(userBalance) && !_.isEmpty(userBalance['netRealBalance'])) {
                this.userCurrency = userBalance['netRealBalance']['accountCurrency'];
                this.conversionFactor = this.FxRateManager.getFxRateWithOutSnapShotId(this.userCurrency, 'USD', 'cashgames');
                this.mainBalance = (userBalance['netRealBalance']['accountBalance'] / 100) * this.conversionFactor;
                this.tourneyBalance =
                    userBalance['tourneyCurrencyBalance'] != null
                        ? Intl.NumberFormat().format(userBalance['tourneyCurrencyBalance']['tourneyCurrencyBalance'] / 100)
                        : '0';
                this.totalAvailableBalance =
                    this.mainBalance +
                    (userBalance['tourneyCurrencyBalance'] != null ? userBalance['tourneyCurrencyBalance']['tourneyCurrencyBalance'] / 100 : 0);

                this.isDecimal = this.mainBalance % 1 != 0 ? false : true;
                this.availableBalanceIsDecimal = this.totalAvailableBalance % 1 != 0 ? false : true;
            }
            // this.userCurrencySymbol = new currencyConverterMap().currencySymbolMap(this.userCurrency);
        });

        this.time = 61; //this.pdollarinfoPopUpInfoService.data.data.time || 61;
        // var This = this;
        // setTimeout(function(){
        //     // This.cancel();
        // }, 1000*this.time);
    }

    cancel() {
        this.logger.info('clicked on P-dollar info popup cross icon..!');
        this.closeBalanceInfo.emit();
    }
}
