import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';

import { DeviceService, MessageQueueService, NativeAppService, NavigationService, Page, TrackingService } from '@frontend/vanilla/core';
import { NavigationBootstrapService, VCClientService } from '@pokercore/module/core';
import { Subject } from 'rxjs';

import { SimulatedService } from '../../../../simulated-hands.service';
import { MobileTabConstants } from '../../models/MobileTab';
//import { TabConstants } from '../../models/Tab';
import { GameTable } from '../../models/game-table';
import { playerNames } from '../../models/playerNames';
import { GameService } from '../../services/game.service';
import { DemoGameTableService } from '../game-table/demo-game-table.service';

@Component({
    selector: 'pkr-demo-game-table',
    templateUrl: './demo-game-table.component.html',
})
export class DemoGameTableComponent implements OnChanges {
    gameTable: GameTable;
    posX: any;
    posY: any;
    content: any;
    vcClient: any;
    mNativeApp: any;
    macDesktop: any;
    isMobile: boolean;
    isTablet: boolean;
    isMobileRotatedToLandScape: any;
    IpadTabUserAgent: any = false;
    maxSeats: any;
    isReplayerThree: boolean = false;
    unsubscribe = new Subject<void>();
    tooltipToggle: boolean = false;
    infoRibbon: boolean = true;
    tooltipText: any = '';
    windowWidth: any;
    isDownloadVisible: boolean;
    @Input() item: any;
    //public selfPlayerName : any;
    public gameInfo: boolean = false;
    public isShowtable: boolean = true;
    public counter = 0;
    public parameters: any = {
        hand_common_fold_msg:
            'It’s too cautious to fold a pocket pair on the button when the action folds to you. Raising in this situation is the preferred play',
        hand_common_msg:
            '<b>Great job!</b> You raised your strong hand in a strong position and bet for value. You could consider betting the flop too and getting money in the pot right away.',
    };
    //public playerInfo:boolean = false;
    @ViewChild('videoplayer') videoplayer: ElementRef;
    @ViewChild('gTooltip') gTooltip: ElementRef;
    @ViewChild('gTip') gTip: ElementRef;

    public playAnimationHandler: any;
    public playerInfo: boolean = false;
    public commonParams: any;
    public nativeApp: boolean = false;
    public plyrNames: any = [];
    public plyerCount: number = 0;
    public enblepop: boolean = false;
    public finalScreen: boolean = false;
    public imgId = Math.floor(Math.random() * 43) + 1;
    public glossaryId: any;
    public showGlossary: boolean;
    public isShowWizAnimation: boolean;
    public isAndroid: boolean = false;
    public invokerApp: string;

    // destroyClickListener: () => void;

    constructor(
        public gameTableService: DemoGameTableService,
        public vcClientServce: VCClientService,
        private deviceService: DeviceService,
        public serve: SimulatedService,
        public gameService: GameService,
        private messageQueue: MessageQueueService,
        private cdr: ChangeDetectorRef,
        private vnPage: Page,
        private trackingService: TrackingService,
        private page: Page,
        private navigationBootstrap: NavigationBootstrapService,
        private navigation: NavigationService,
        private nativeAppService: NativeAppService,
    ) {}

    ngOnChanges() {
        this.cdr.detectChanges();
    }

    ngOnInit() {
        this.isMobile = true;
        window.addEventListener('win_game', this.playAnimationHandler);
        const userAgent: any = navigator.userAgent;
        if (navigator.userAgent.match(/Macintosh/) !== null) {
            this.IpadTabUserAgent = Object.values(userAgent.match(/Macintosh/))[0] === 'Macintosh' && navigator.maxTouchPoints > 2;
        }
        this.isTablet = this.deviceService.isTablet || this.IpadTabUserAgent;
        // this.vcClient = this.vcClientServce.isClient();
        this.mNativeApp = this.vcClientServce.isMobileApp();
        // this.macDesktop = this.vcClientServce.isMacDesktop();
        // this.content = this.item.parameters;
        this.serve.content = this.item.parameters;
        this.serve.isMobile = true;
        this.maxSeats = this.gameTableService.gameTable?.maxSeats;
        // this.potAmount=this.gameTableService.gameTable.MAIN_POT.totalValue > 0 ? this.gameTableService.gameTable.MAIN_POT.totalValue : this.gameTableService.gameTable.ROUND_POT.totalValue;
        //console.log(this.content);
        // this.selfPlayerName = this.cookieService.get("simu-nickname");
        this.nativeApp = this.serve.nativeApp;
        //console.log('Demo this.serve ', this.serve);
        this.serve.selfPlyerImg = this.imgId;
        this.invokerApp = this.serve.invokerApp;
    }

    ngDoCheck() {
        this.isMobileRotatedToLandScape = false;
        if (window.orientation === 90 || window.orientation === -90) {
            this.changeOrientations();
        }
        this.maxSeats = this.gameTableService.gameTable?.maxSeats;
    }

    showPlayerInfo() {
        this.isShowtable = false;
        this.gameTableService.isMobile = true;
        this.isDownloadVisible = false;
        const replaceTxt = this.item.parameters.playerInfo.replace('%%', this.serve.playerName);
        this.item.parameters.playerInfo = replaceTxt;
        this.commonParams = this.item.parameters;
        this.showGlossary = this.commonParams?.showGlossary == 'true' ? true : false;
        this.isShowWizAnimation = this.commonParams?.isShowWizAnimation == 'true' ? true : false;
        if (this.commonParams.isShowPlayerInfo == 'true') {
            this.playerInfo = true;
        } else {
            this.startNewGame();
        }

        //console.log("this.commonParams: "+ this.commonParams)
        //console.log("this.item:  "+this.item)
    }

    checkInsight(data: any) {
        if (data) {
            const checkDataAttr = data.includes('data-type');
            return checkDataAttr;
        } else return false;
    }

    startNewGame() {
        this.plyrNames = [];
        this.plyerCount = 0;
        this.getPlayerNames();
        this.playerInfo = false;
        // this.isDownloadVisible = true;
        this.closeTooltip();
        //this.counter = 2;
        //console.log("this.gameInfo"+ this.gameInfo);
        //console.log(' playNextHand this.counter ' + this.counter);

        this.playDemo(this.item.gameHandsJsons[this.counter]);
        //this.playTestDemo(this.counter);
        this.parameters = this.item.gameHandsJsons[this.counter].parameters;
        //let This = this;
        // if(this.counter == 0){
        //   setTimeout(function(){
        //     This.gameInfo = true;
        //     this.isDownloadVisible = false;
        //   }, 3000);
        // }else{
        //   this.gameInfo = true;
        //   this.isDownloadVisible = false;
        // }
        //this.playTestDemo(this.counter);
        //this.gameInfo = true;
        this.isDownloadVisible = false;
        // this.trackingPageEvent(); // OLD GTM TRACKING
        this.counter = this.counter + 1;
    }

    tryAgain() {
        this.counter = this.counter - 1;
        this.startNewGame();
    }

    @HostListener('window:resize', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onResize(event) {
        this.windowWidth = window.innerWidth;
    }

    showTooltip(e: any) {
        e.stopPropagation();
        const data = e.target.getAttribute('data-type');
        if (data != null && data != undefined && this.showGlossary) {
            this.tooltipText = this.commonParams[data];

            if (this.glossaryId !== data && this.tooltipText) {
                this.tooltipToggle = true;
                this.windowWidth = window.innerWidth;

                if (e.pageX / this.windowWidth > 0.7) {
                    this.gTooltip.nativeElement.style.left = this.windowWidth - e.pageX + 50 + 'px';
                } else {
                    this.gTooltip.nativeElement.style.left = e.pageX - 40 + 'px';
                }
                this.gTooltip.nativeElement.style.top = e.pageY + 20 + 'px';
                this.gTip.nativeElement.style.left = e.pageX - 10 + 'px';
                this.gTip.nativeElement.style.top = e.pageY + 10 + 'px';
                this.glossaryId = data;
            } else {
                this.closeTooltip();
            }
        }
    }

    closeTooltip() {
        this.tooltipToggle = false;
        this.tooltipText = '';
        this.glossaryId = '';
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @HostListener('document:click', ['$event']) onDocumentClick(event) {
        this.closeTooltip();
    }

    handleTooltip(e: any) {
        e.stopPropagation();
    }

    closeInfoRibbon() {
        this.infoRibbon = false;
    }
    // 375 - 290 = 85

    playDemo(res: any) {
        //var roundId = "19856100275";
        //console.log('playDemo:::');
        //this.gameInfo = false;
        //let PROPERTIES: any;
        this.isReplayerThree = true;
        // if (this.isReplayerThree) {
        //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //     PROPERTIES = TabConstants.Tabnew;
        // } else {
        //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //     PROPERTIES = TabConstants.Tab;
        // }

        const bodyElement = document.getElementsByTagName('body')[0],
            gameTableContainer: any = document.querySelector('.game-table-container'),
            winWidth = window.outerWidth,
            replayHandPosition = window.scrollY;
        //this.serve.activeHandID = roundId;
        const finalRes = res.gameHandsJson;
        //console.log('simu-nickname:: ' + this.serve.playerName);
        this.gameService.selfPlayerName = this.serve.playerName;
        this.gameService.playerNames = this.plyrNames;
        this.gameService.playerEvtMaxTime = this.commonParams.playerEvtMaxTime;
        this.gameService.playerEvtMinTim = this.commonParams.playerEvtMinTim;
        this.gameService.eventTime = this.commonParams.eventTime;
        this.gameService.showTime = this.commonParams.showTime;
        // 	this.gameService.PROPERTIES = MobileTabConstants.Tab;
        // 	if (this.deviceService.isTablet || this.demoGameTable.IpadTabUserAgent) {
        // 		this.gameService.PROPERTIES = MobileTabConstants.Tablet;
        // 	}
        //  } //else {
        // 	this.gameService.PROPERTIES = PROPERTIES
        // }
        //console.log(this.deviceService.isTablet + 'Properties' + PROPERTIES);
        this.gameService.PROPERTIES = MobileTabConstants.Tab;
        this.gameService.gameTable = finalRes;
        this.gameService.response = finalRes;
        this.gameService.content = this.serve.content;
        this.gameService.isMobile = true;

        //	this.gameService.isMobile = this.serve.isMobile;
        // this.serve.showErrorMessage = false;
        // if (this.serve.isMobile && (finalRes.gameRound.maxplayers === 10 || finalRes.gameRound.ritGame)) {
        // 	this.serve.showErrorMessage = true;
        // }
        this.gameService.isReplayerOnInit(true);
        //this.gameService.isMobile = this.serve.isMobile;
        this.gameTable = this.gameService.gameTable;
        this.gameService.isReplayerV3 = this.isReplayerThree;
        this.gameTableService.initGameTable(this.gameTable);
        this.gameTableService.isMobile = true;
        //this.gameTableService.gameTable.gameInfo = true;
        //console.log(" this.gameTableService.gameTable.gameInfo:::"+ this.gameTableService.gameTable.gameInfo);
        //this.demoGameTable.isMobile = this.serve.isMobile;
        //this.serve.popupToggle = true;
        //this.serve.replayHandPosition = replayHandPosition;
        //this.gameTable.isMobile = this.serve.isMobile;
        this.gameTable.isMobile = true;
        this.gameTable.onInit = true;

        const navClass: any = document.getElementsByClassName('slot-main')[0];
        navClass.classList.add('hideNav');
        // {
        //   let bodyElement: HTMLBodyElement | null = document.querySelector('body');
        // }
        // if (document.querySelector('body') != null)
        bodyElement.style.top = -replayHandPosition + 'px';
        if (bodyElement.className.indexOf('noscroll') <= -1) {
            bodyElement.className += ' noscroll';
        }
        if (winWidth < 768 && gameTableContainer) {
            gameTableContainer.click();
        }
        const hideBottomNavForIdevice: any = document.getElementsByClassName('bottom-nav')[0];
        if (hideBottomNavForIdevice && hideBottomNavForIdevice.className.indexOf('bottom-nav') > -1) {
            //this.serve.showBottomNavForIdevice = false;
            hideBottomNavForIdevice.style.zIndex = 0;
        }
        this.gameTableService.gameTable.isShowGameInfo = this.commonParams?.isShowGameInfo == 'true' ? true : false;
        this.gameTableService.play();
    }

    closeGameTable(event: any) {
        this.trackingCloseButton();
        const className = event.target.className;
        const winWidth = window.outerWidth;
        if (winWidth >= 768 || className.indexOf('close-btn') > -1) {
            if (
                className.indexOf('game-table-wrapper') > -1 ||
                className.indexOf('game-table-innerwrapper') > -1 ||
                className.indexOf('close-btn') > -1
            ) {
                this.showBottomNavForIdevices();
                //this.exitFullScreenForMobiles();
                const bodyElement = document.getElementsByTagName('body')[0];
                //this.gameTableService.gtInitializeDone = false;
                //this.serve.popupToggle = false;
                this.gameTableService.disablePrevHandButton = false;
                this.gameTableService.disableNextHandButton = false;
                (document.querySelector('body') as HTMLElement).style.top = '0px';
                if (bodyElement.className.indexOf('noscroll') > -1) {
                    bodyElement.classList.remove('noscroll');
                }
                // window.scroll(0, this.serve.replayHandPosition);
                const navClass: any = document.getElementsByClassName('slot-main')[0];
                navClass.classList.remove('hideNav');
                this.isShowtable = true;
                window.open(this.vnPage.homePage, '_self');
            }
        }
    }

    closeGame() {
        this.gameTableService.gtInitializeDone = false;
        if (this.gameTableService.gameTable != undefined) this.gameTableService.gameTable.isGameInProgres = false;
    }

    ContinuePlay() {
        this.gameTableService.gameTable.gameInfo = false;
        this.isDownloadVisible = true;
        this.gameTableService.gameTable.playerTimer();
    }

    showFinalScreen() {
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Android/)) {
            this.isAndroid = true;
        }
        this.gameInfo = false;
        this.isDownloadVisible = true;
        this.finalScreen = true;
        this.closeTooltip();
    }

    sendNativeEvent() {
        if (this.invokerApp) {
            if (this.invokerApp.toUpperCase() == 'AN' || this.invokerApp.toUpperCase() == 'IN') {
                this.nativeAppService.sendToNative({ eventName: 'closeContainer', parameters: {} });
            }
            if (['AW', 'IW'].includes(this.invokerApp.toUpperCase())) {
                const previousUrl = this.navigationBootstrap.getPreviousUrl();
                const lobbyUrl = this.page.homePage;

                if (previousUrl) {
                    this.navigation.goTo(previousUrl);
                } else {
                    window.open(`${lobbyUrl}/play`, '_self');
                }
            }
        }
    }

    ngAfterViewChecked() {
        const width = window.innerWidth;
        if (this.gameTableService.isMobile && !this.deviceService.isTablet) {
            const ele: any = document.getElementById('game-table-container');
            const elenew: any = document.getElementById('Player-id');
            const elepop: any = document.getElementById('popUp');
            let zoomValue = width / 720;
            if (zoomValue > 1.46) {
                zoomValue = 1.46;
            }
            if (ele) {
                ele.style.zoom = zoomValue;
            }
            if (elenew) {
                elenew.style.zoom = zoomValue;
            }
            if (elepop) {
                elepop.style.zoom = zoomValue;
            }
        } else if (this.deviceService.isTablet || this.gameTableService.IpadTabUserAgent) {
            const ele: any = document.getElementById('game-table-container');
            const elenew: any = document.getElementById('Player-id');
            const elepop: any = document.getElementById('popUp');
            let zoomValue = width / 1024;
            if (zoomValue > 1.46) {
                zoomValue = 1.46;
            }
            if (ele) {
                ele.style.zoom = zoomValue;
            }
            if (elenew) {
                elenew.style.zoom = zoomValue;
            }
            if (elepop) {
                elepop.style.zoom = zoomValue;
            }
        }

        if (this.gameTableService.isMobile) {
            const ele: any = document.querySelectorAll('.wiz-modal-zoom');
            // let elenew: any = document.getElementById('Player-id');
            let zoomValue = width / 500;
            if (zoomValue > 1.46) {
                zoomValue = 1.46;
            }
            if (ele && ele.length != 0) {
                ele.forEach((modal: any) => {
                    modal.style.zoom = zoomValue;
                });
            }
        }
    }

    getXaxis() {
        this.posX = this.gameTableService.gameTable.changeChipPos.x;
        return `${this.posX}%`;
    }

    getYaxis() {
        this.posY = this.gameTableService.gameTable.changeChipPos.y;
        return `${this.posY}%`;
    }

    changeOrientations() {
        this.isMobileRotatedToLandScape = true;
    }

    exitFullScreenForMobiles() {
        const elem: any = document.getElementsByClassName('web-veiw-wrapper')[0];
        // if (elem && this.serve.isMobile && !this.serve.Idevices && !this.serve.isTenSeaterGame) {
        if (elem) {
            if (document.exitFullscreen) {
                /* Chrome, Safari and Opera */
                document.exitFullscreen();
            } else if (elem.webkitExitFullscreen) {
                elem.webkitExitFullscreen();
            }
        } else {
            return;
        }
    }

    showBottomNavForIdevices() {
        const hideBottomNavForIdevice: any = document.getElementsByClassName('bottom-nav')[0];
        // if (hideBottomNavForIdevice && hideBottomNavForIdevice.className.indexOf('bottom-nav') > -1 && this.serve.isMobile && !this.serve.showBottomNavForIdevice) {
        if (hideBottomNavForIdevice && hideBottomNavForIdevice.className.indexOf('bottom-nav') > -1) {
            hideBottomNavForIdevice.style.zIndex = 1030;
        }
    }

    showWizSpeech(animationStatus: any) {
        if (animationStatus) {
            //console.log('showWizSpeech ', animationStatus);
            setTimeout(() => {
                //console.log('showWizSpeech setTimeout', animationStatus);
            }, 5000);
            return 'show-wiz-speech';
        } else {
            return 'hide-wiz-speech';
        }
    }

    playTestDemo(index: number) {
        //var roundId = "19856100275";

        //let PROPERTIES: any;
        this.isReplayerThree = true;
        // if (this.isReplayerThree) {
        //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //     const PROPERTIES = TabConstants.Tabnew;
        //     //console.log(PROPERTIES);
        // } else {
        //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //     const PROPERTIES = TabConstants.Tab;
        //     //console.log(PROPERTIES);
        // }

        this.serve.getTestData(index).subscribe(
            (resp) => {
                const res = JSON.parse(resp);
                //console.log("result" + JSON.stringify(this.item));
                const bodyElement = document.getElementsByTagName('body')[0],
                    gameTableContainer: any = document.querySelector('.game-table-container'),
                    winWidth = window.outerWidth,
                    replayHandPosition = window.scrollY;
                //this.serve.activeHandID = roundId;
                const finalRes = res;
                this.gameService.selfPlayerName = this.serve.playerName;
                this.gameService.playerNames = this.plyrNames;
                this.gameService.PROPERTIES = MobileTabConstants.Tab;
                this.gameService.gameTable = finalRes;
                this.gameService.response = finalRes;
                this.gameService.content = this.serve.content;
                this.gameService.isMobile = true;
                this.gameService.isReplayerOnInit(true);
                //this.gameService.isMobile = this.serve.isMobile;
                this.gameTable = this.gameService.gameTable;
                this.gameService.isReplayerV3 = this.isReplayerThree;
                this.gameTableService.initGameTable(this.gameTable);
                this.gameTableService.isMobile = true;
                this.gameTable.isMobile = true;
                this.gameTable.onInit = true;

                const navClass: any = document.getElementsByClassName('slot-main')[0];
                navClass.classList.add('hideNav')(document.querySelector('body') as HTMLElement).style.top = -replayHandPosition + 'px';
                if (bodyElement.className.indexOf('noscroll') <= -1) {
                    bodyElement.className += ' noscroll';
                }
                if (winWidth < 768 && gameTableContainer) {
                    gameTableContainer.click();
                }
                const hideBottomNavForIdevice: any = document.getElementsByClassName('bottom-nav')[0];
                if (hideBottomNavForIdevice && hideBottomNavForIdevice.className.indexOf('bottom-nav') > -1) {
                    //this.serve.showBottomNavForIdevice = false;
                    hideBottomNavForIdevice.style.zIndex = 0;
                }
                this.gameTableService.gameTable.isShowGameInfo = this.commonParams?.isShowGameInfo == 'true' ? true : false;
                this.gameTableService.play();
            },
            (error) => {
                this.messageQueue.clear();
                if (error.errorCode === '403') {
                    this.messageQueue.add(error.vnMessages[0].html);
                } else if (!error.hasOwnProperty('errorCode')) {
                    this.messageQueue.add(error.message);
                }
            },
        );
    }

    randomNumber(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    getPlayerNames() {
        for (let i = 0; this.plyerCount < 6; i++) {
            const ind = Math.ceil(this.randomNumber(1, 44));
            if (!this.plyrNames.includes(playerNames[ind])) {
                this.plyrNames.push(playerNames[ind]);
                this.plyerCount = this.plyerCount + 1;
            }
        }
    }

    // ------ GTM tracking start-------

    trackingCloseButton() {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent': this.getCounterHandNumberForTrackingInfo() + ' hand',
            'component.EventDetails': 'close icon',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingSkipToDownloadClickEvent(clickUrl: any, downloadRefStr: string) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent': this.counter + ' hand',
            'component.EventDetails': downloadRefStr + ' link',
            'component.URLClicked': clickUrl,
        });
    }

    trackingPageEvent() {
        this.trackingService.triggerEvent('contentView', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'load',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent': this.getCounterHandNumberForTrackingInfo() + ' hand',
            'component.EventDetails': 'sim hands tutorials',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingClickEventGoogleAppStore(clickUrl: any, text: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent': this.getCounterHandNumberForTrackingInfo() + ' hand',
            'component.EventDetails': text + ' cta', //downloadRefStr
            'component.URLClicked': clickUrl,
        });
    }

    trackingClickEvent(clickUrl: any, text: any, cta = ' link') {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent': this.getCounterHandNumberForTrackingInfo() + ' hand',
            'component.EventDetails': text + ' ' + cta,
            'component.URLClicked': clickUrl,
        });
    }

    trackingPlayTutorialNextHandCTA(text: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent':
                text == this.commonParams.playTutorial ? 'not applicable' : this.getCounterHandNumberForTrackingInfo() + ' hand',
            'component.EventDetails': text + ' cta',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingTryAgainContdCTA(str: any, count: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.LocationEvent': 'demo game table screen',
            'component.PositionEvent': count + ' hand',
            'component.EventDetails': str + ' cta',
            'component.URLClicked': 'not applicable',
        });
    }

    getCounterHandNumberForTrackingInfo(): string {
        // console.log('___________________________START________________________________');
        // console.log((this.counter + 1).toString());
        // console.log('___________________________END________________________________');
        return (this.counter + 1).toString();
    }

    // ------GTM tracking end-------

    ngOnDestroy() {
        this.closeGame();
        this.showBottomNavForIdevices();
        const navClass: any = document.getElementsByClassName('slot-main')[0];
        const bodyElement = document.getElementsByTagName('body')[0];
        navClass.classList.remove('hideNav');
        (document.querySelector('body') as HTMLElement).style.top = '0px';
        bodyElement.classList.remove('noscroll');

        this.unsubscribe.next();
        this.unsubscribe.complete();
        // this.destroyClickListener();
    }
}
