import after from './after.js';
import ary from './ary.js';
import before from './before.js';
import bind from './bind.js';
import bindKey from './bindKey.js';
import curry from './curry.js';
import curryRight from './curryRight.js';
import debounce from './debounce.js';
import defer from './defer.js';
import delay from './delay.js';
import flip from './flip.js';
import memoize from './memoize.js';
import negate from './negate.js';
import once from './once.js';
import overArgs from './overArgs.js';
import partial from './partial.js';
import partialRight from './partialRight.js';
import rearg from './rearg.js';
import rest from './rest.js';
import spread from './spread.js';
import throttle from './throttle.js';
import unary from './unary.js';
import wrap from './wrap.js';
export default {
  after,
  ary,
  before,
  bind,
  bindKey,
  curry,
  curryRight,
  debounce,
  defer,
  delay,
  flip,
  memoize,
  negate,
  once,
  overArgs,
  partial,
  partialRight,
  rearg,
  rest,
  spread,
  throttle,
  unary,
  wrap
};