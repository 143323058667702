import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, Router, RouterEvent, RouterModule } from '@angular/router';

// import { NavigationService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import { Subscription, filter } from 'rxjs';

// import { AssetLoaderService } from './PWC-games-lib/lib/services/asset-loader.service';
import { ClientFooterComponent } from './common/client-footer/client-footer.component';
import { ClientHeaderComponent } from './common/client-header/client-header.component';
import { PWCErrorDialogComponent } from './common/error-dialog/pwc-error-dialog.component';
import { CgFiltersEventBusService } from './common/lobby-filters/services/cg-filters-event-bus.service';
import { PWCReconnectionDialogComponent } from './common/reconnection-popup/pwc-reconnection-dialog.component';
import { GameTableComponent } from './game/game.component';
// import { formatCurrencyPipe } from './pipes/formatCurrency.pipe';
// import { TranslatePipe } from './pipes/translate.pipe';
import { LoaderComponent } from './loader/loader.component';
import { CashErrorDialogModel } from './models/cash-error-dialog.model';
import { CSD } from './pgConnectors/sal/CSD';
import { formatCurrencyPipe } from './pipes/formatCurrency.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { PokerWebClientDelegatorService } from './poker-web-client-delegator.service';
import { PlayGuard } from './poker-web-client-play-guard';
import { PokerWebClientSharedService } from './poker-web-client-shared.service';
import { ClientToServerRequestServices } from './service/client-to-server-requests.service';
import { EventDispatcherGlobal } from './service/event-despacher-global';
import { GameTableNotificationService } from './service/game-table-notification.service';
import { PokerWebClientLoginService } from './service/poker-web-client-login.service';
import { SpinGameService } from './service/spin-game.service';
import { userDuplicateLoginSelect, userLoginFailureSelect } from './store/user.store';

@Component({
    selector: 'pwc-poker-web-client',
    templateUrl: 'poker-web-client.component.html',
    imports: [
        CommonModule,
        RouterModule,
        ClientHeaderComponent,
        ClientFooterComponent,
        GameTableComponent,
        formatCurrencyPipe,
        PWCErrorDialogComponent,
        TranslatePipe,
        PWCReconnectionDialogComponent,
    ],
    providers: [
        PokerWebClientLoginService,
        formatCurrencyPipe,
        TranslatePipe,
        CgFiltersEventBusService,
        GameTableNotificationService,
        SpinGameService,
        PlayGuard,
    ],
    standalone: true,
})
export class PokerWebClientComponent extends PCComponent {
    isGameTableOpen = false;
    routeSubscription!: Subscription;
    connectionStatus: boolean = false;
    handshakeSuccessful: boolean = false;
    isLoaderOpened: boolean = false;
    readonly logger: PokerLogger;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    private duplicateLogin: boolean = false;
    gameInTileView: boolean;
    prevConnectionStatus: number = 0;
    orientationImg: any;

    constructor(
        private router: Router,
        private routeData: RouteDataService,
        private shareService: PokerWebClientSharedService,
        private clientToServer: ClientToServerRequestServices,
        private PokerWebClientLoginService: PokerWebClientLoginService,
        // private assetLoader: AssetLoaderService,
        private dialog: MatDialog,
        private pokerWebClientDelegatorService: PokerWebClientDelegatorService,
        // private formatCurrencyPipe: formatCurrencyPipe,
        private store: Store,
        // private translatePipe: TranslatePipe,
        loggerFactory: LoggerFactory,
    ) {
        super();
        //this.logger = loggerFactory.getLogger("PokerWebClientrServicecomponent");
        // document.addEventListener('DOMContentLoaded', function () {

        // });
        this.logger = loggerFactory.getLogger('PokerWebClientrLoggingComponent');
        this.clientToServer.dynaconConfigs = this.routeData.getInitData().dynaconData;
        this.clientToServer.siteCoreData = this.routeData.getInitData().siteCoreData;
        this.clientToServer.mainLobbyBanners = this.routeData.getInitData().lobbyBanners;
        this.clientToServer.webAssets = this.routeData.getInitData().webAssets;
        this.clientToServer.externalLinks = this.routeData.getInitData().externalLinks;
        this.clientToServer.requestForSpinLobbyData(0, 1);
        // eslint-disable-next-line no-console
        console.log('@@..routeData..', this.routeData.getInitData());
        this.store.select(userDuplicateLoginSelect).subscribe((state) => {
            if (state) {
                this.duplicateLogin = state;
                this.terminateTheSession();
            }
        });
        this.store.select(userLoginFailureSelect).subscribe((state) => {
            if (state) {
                this.showLoginFailurePopup();
            }
        });
        this.orientationImg = this.clientToServer.webAssets['lobby']['common']['orientationimg']['image']['src'];
        this.eventDispatcherGlobal.addEventListener('gameEventListener', this.getTileViewStatus.bind(this));
    }

    ngOnInit(): void {
        //this.lobbyPromotions = this.routeData.getInitData();
        this.handshakeSuccessful = this.routeData.getInitData().handshakeSuccessful;
        if (this.handshakeSuccessful) {
            this.shareService.lobbyPromotions = this.routeData.getInitData();
        } else {
            return;
        }
        //reference for navigation items.
        // console.log("pwcNavigationClientConfig navina", this.pwcNavigationClientConfig);
        this.router.events.pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)).subscribe((e: RouterEvent) => {
            this.isGameTableOpen = e.url.includes('/game-table');
            this.logger.info('Is game table opened:' + this.isGameTableOpen);
        });
        this.pokerWebClientDelegatorService.getConnectionStatus().subscribe((status) => {
            this.handleDisconnection(status);
        });

        this.disablePWCSplashScreen();
    }

    disablePWCSplashScreen() {
        const loadingIndicator = document.getElementById('splash');
        if (loadingIndicator) {
            loadingIndicator.classList.remove('pwc-enable-loader');
        }
    }

    handleDisconnection(status: number) {
        if (!this.duplicateLogin) {
            if (status == CSD.CONNECTION_BAD || status == CSD.NO_CONNECTION || status == CSD.SHOW_RECONNECTION_POPUP) {
                if (this.PokerWebClientLoginService.isloggedOut) {
                    return;
                }
                this.showReconnectionPopup();
                //     if (this.prevConnectionStatus !== status) {
                //         this.prevConnectionStatus = status;
                //         this.dialog.open(LoaderComponent, {
                //             width: '100%',
                //             height: '100%',
                //             maxHeight: '100vh',
                //             data: status,
                //             autoFocus: false,
                //             panelClass: 'wpc-card-info_BlackBG',
                //             hasBackdrop: true,
                //             backdropClass: 'wpc-card-info-loader',
                //             disableClose: false,
                //             closeOnNavigation: true,
                //         });
                //         this.isLoaderOpened = true;
                //     }
                // } else if (status == CSD.SHOW_RECONNECTION_POPUP) {
                //     this.showReconnectionPopup();
                // } else {
                //     if (this.isLoaderOpened == true) {
                //         this.prevConnectionStatus = status;
                //         this.eventDispatcherGlobal.dispatchEvent('updateConnectionStatus', {});
                //         this.isLoaderOpened = false;
                //     }
            }
        }
    }

    terminateTheSession() {
        this.openErrorDialog({
            title: 'Error', //this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message:
                'You just logged in from a different computer. Your current session will be terminated. In case you have received this message in error, please contact the Customer Support immediately',
            buttons: [{ text: 'OK' }], //{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }
        });
    }

    showLoginFailurePopup() {
        this.openErrorDialog({
            title: 'ERROR', //this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: 'We have an error in your login to the Game Server. Please try again.',
            buttons: [{ text: 'OK' }], //[{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }]
        });
    }

    showReconnectionPopup() {
        this.openReconnectionErrorDialog({
            title: 'Reconnect?', //this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: 'You have been disconnected/inactive.',
            buttons: [
                { text: 'Yes', result: 'Yes' },
                { text: 'No', result: 'No' },
            ], //[{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }]
        });
    }

    openReconnectionErrorDialog(data: CashErrorDialogModel) {
        const dialog = this.dialog.open(PWCReconnectionDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });

        dialog.afterClosed().subscribe((showReconnPopup) => {
            if (showReconnPopup) {
                this.dialog.open(LoaderComponent, {
                    width: '100%',
                    height: '100%',
                    maxHeight: '100vh',
                    data: CSD.CONNECTION_BAD,
                    autoFocus: false,
                    panelClass: 'wpc-card-info_BlackBG',
                    hasBackdrop: true,
                    backdropClass: 'wpc-card-info-loader',
                    disableClose: false,
                    closeOnNavigation: true,
                });
            }
        });
    }

    openErrorDialog(data: CashErrorDialogModel) {
        const dialog = this.dialog.open(PWCErrorDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });

        // dialog.afterClosed().subscribe((result) => {
        dialog.afterClosed().subscribe((result) => {
            // eslint-disable-next-line no-console
            console.log(result);
            this.PokerWebClientLoginService.requestForPortalLogOut();
        });
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    getTileViewStatus(message: any) {
        if (message.detail.className === 'showWidget') {
            this.gameInTileView = message.detail.show;
        }
    }
}
