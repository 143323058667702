import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';

import { PCComponent } from '@frontend/vanilla/features/content';

import { PokerCoreLazyPlaceholderComponent } from '../poker-core-lazy-placeholder/poker-core-lazy-placeholder.component';
import { PPBlogSelectionViewWrapperComponent } from './pp-blog-selection-view-wrapper/pp-blog-selection-view-wrapper.component';

@Component({
    selector: 'pp-blog-feed',
    templateUrl: 'pp-blog-feed.component.html',
    standalone: true,
    imports: [CommonModule, PokerCoreLazyPlaceholderComponent, PPBlogSelectionViewWrapperComponent],
})
export class PPBlogFeedComponent extends PCComponent implements OnInit {
    //ppBlogFeed: any

    info: any = {};
    isSelectionView: Boolean;

    @HostBinding()
    get class() {
        //this.ppBlogFeed = this.item.blogData.items;
        return this.createClass(this.item.name);
    }

    ngOnInit(): void {
        if (this.item && this.item.hasOwnProperty('parameters') && this.item.parameters.hasOwnProperty('isSelectionView')) {
            this.isSelectionView = this.item.parameters.isSelectionView === 'true' ? true : false;
        } else {
            this.isSelectionView = false;
        }
    }

    // getFormattedDate(dateString: string) {
    //     return (new Date(dateString)).toLocaleDateString('en-GB', {
    //         day: 'numeric',
    //         month: 'long',
    //         year: 'numeric'
    //     });
    // }
}
