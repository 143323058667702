import { Message } from '../../message';

export class RequestSNGPromoUpdate extends Message {
    private MESSAGE_NAME: string = 'RequestSNGPromoUpdate';
    private lastSnapShotTimeInNanos: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lastSnapShotTimeInNanos: number = 0) {
        super(reqServerPeerId);
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }

    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
}
