import { Message } from '../../message';

export class DealMakingPostDealPayout extends Message {
    private MESSAGE_NAME: string = 'DealMakingPostDealPayout';
    private payoutInCents: number = 0;
    private payoutinPercentage: number = 0;
    private place: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, payoutInCents: number = 0, payoutinPercentage: number = 0, place: number = 0) {
        super(reqServerPeerId);
        this.payoutInCents = payoutInCents;
        this.payoutinPercentage = payoutinPercentage;
        this.place = place;
    }

    getPayoutInCents(): number {
        return this.payoutInCents;
    }

    setPayoutInCents(payoutInCents: number) {
        this.payoutInCents = payoutInCents;
    }
    getPayoutinPercentage(): number {
        return this.payoutinPercentage;
    }

    setPayoutinPercentage(payoutinPercentage: number) {
        this.payoutinPercentage = payoutinPercentage;
    }
    getPlace(): number {
        return this.place;
    }

    setPlace(place: number) {
        this.place = place;
    }
}
