import { Routes } from '@angular/router';

import { PokerWptWorldOnlineChampionshipSynopsisResolver } from './poker-wpt-world-online-championship-synopsis.resolver';
import { PokerWptWorldOnlineChampionshipResolver } from './poker-wpt-world-online-championship.resolver';

export const pokerWptWorldOnlineChampionshipRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWptWorldOnlineChampionshipResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerWptWorldOnlineChampionshipSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWptWorldOnlineChampionshipResolver,
        },
    },
];
