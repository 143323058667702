// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class KothTableView extends PIXI.Container {
    gameService: any;
    EventDispatcher: any;
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor() {
        super();
    }
    createView = function (parentViewModel, EventDispatcher, gameService) {
        this.parentViewModel = parentViewModel;
        this.gameService = gameService;
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        this.isKothSelected = false;
        this.similarClick = false;
        this.playerIconPositions =
            uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + 2 + 'MaxPos'].playersUIData.playerIconPositions;
        this.kothBtnContainer = this.displayUtil.getContainer();
        this.addChild(this.kothBtnContainer);
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        this.setSize(720, 1280);
        //  this.displayKothButtons();
        this.kothBtnBg = this.displayUtil.getSprite('koth_button_bg');
        this.kothBtnContainer.addChild(this.kothBtnBg);
        this.kothBtnBg.scale.y = 1.02;
        this.displayUtil.setObjectPosition(this.kothBtnContainer, 40, 162);
        this.kothBtnBgText = this.displayUtil.getTextField(this.fontProps.tableKothText);
        this.kothBtnContainer.addChild(this.kothBtnBgText);
        this.kothBtnBgText.anchor.set(0.5);
        this.displayUtil.setObjectPosition(this.kothBtnBgText, 106, 137);
        this.kothAddTableBtn = this.displayUtil.getSprite('koth_add_table_btn');
        this.kothBtnContainer.addChild(this.kothAddTableBtn);
        this.kothAddTableBtn.anchor.set(0.5);
        this.kothBtnBg.visible = false;
        this.kothBtnBgText.visible = false;
        this.kothAddTableBtn.visible = false;
        this.displayUtil.setClickable(this.kothAddTableBtn, true);
        this.displayUtil.setObjectPosition(this.kothAddTableBtn, 108, 62);
        this.koth_addTableBtnSelectedState = this.displayUtil.getSprite('koth_selected_btn');
        this.kothBtnContainer.addChild(this.koth_addTableBtnSelectedState);
        this.koth_addTableBtnSelectedState.anchor.set(0.5);
        this.koth_addTableBtnSelectedState.visible = false;
        this.displayUtil.setClickable(this.koth_addTableBtnSelectedState, true);
        this.displayUtil.setObjectPosition(this.koth_addTableBtnSelectedState, 86, 62);
        this.koth_plusIcon = this.displayUtil.getSprite('koth_add_table_btn_small');
        this.kothBtnContainer.addChild(this.koth_plusIcon);
        this.koth_plusIcon.anchor.set(0.5);
        this.koth_plusIcon.visible = false;
        this.isPlayerJoined = false;
        this.displayUtil.setClickable(this.koth_plusIcon, true);
        this.displayUtil.setObjectPosition(this.koth_plusIcon, this.playerIconPositions.x[1] + 118, this.playerIconPositions.y[1] - 49);
    };
    displayKothButtons = function () {
        this.kothOppPlayerName = this.parentViewModel.gameService.kothOppPlayerName;
        if (this.kothOppPlayerName) {
            this.kothBtnBgText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_KOTH_TEXT');
            this.kothBtnBgText.text = this.kothBtnBgText.text.replace('%1s', this.kothOppPlayerName);
        }
        this.isPlayerJoined = true;
        if (CommonGameProperties.facade['activeTables'].length < 4) {
            this.kothBtnBg.visible = true;
            this.kothBtnBgText.visible = true;
            this.kothAddTableBtn.visible = true;
        }
        this.isKothSelected = false;
        this.displayUtil.addInteraction(this.kothAddTableBtn, this.kothBtnClick.bind(this));
        this.koth_addTableBtnSelectedState.visible = false;
        this.displayUtil.addInteraction(this.koth_addTableBtnSelectedState, this.deSelectBtns.bind(this));
        this.koth_plusIcon.visible = false;
        this.displayUtil.addInteraction(this.koth_plusIcon, this.kothAddSimilarClick.bind(this));
        if (CommonGameProperties.facade['activeTables'].length < 4) {
            this.stopOtherTimers();
            this.displayKothButtonsTimer = setTimeout(
                function () {
                    this.kothBtnBg.visible = false;
                    this.kothBtnBgText.visible = false;
                    this.kothAddTableBtn.visible = false;
                    this.koth_plusIcon.visible = true;
                }.bind(this),
                3000,
            );
            if (CommonGameProperties.facade['activeTables'].length > 0) {
                for (let i = 0; i < CommonGameProperties.facade['activeTables'].length; i++) {
                    const tableId = CommonGameProperties.facade['activeTables'][i];
                    if (
                        !(CommonGameProperties.facade['table' + tableId] == undefined) &&
                        CommonGameProperties.facade['table' + tableId].kothTableViewModel != undefined
                    )
                        if (CommonGameProperties.facade['table' + tableId].kothTableViewModel.view.isKothSelected === true) {
                            this.kothBtnClick();
                            break;
                        }
                }
            }
        }
        // this.hideShowBG();
    };
    kothBtnClick = function () {
        this.similarClick = true;
        this.isKothSelected = true;
        this.kothBtnBg.visible = false;
        this.kothBtnBgText.visible = false;
        this.kothAddTableBtn.visible = false;
        this.koth_plusIcon.visible = false;
        this.koth_addTableBtnSelectedState.visible = true;
        this.stopOtherTimers();
        this.kothBtnClickTimer = setTimeout(
            function () {
                this.koth_addTableBtnSelectedState.visible = false;
                this.koth_plusIcon.visible = true;
            }.bind(this),
            3000,
        );
        this.parentViewModel.sendHuPairTableSelectStatus(true);
    };
    deSelectBtns = function () {
        this.isKothSelected = false;
        this.koth_addTableBtnSelectedState.visible = false;
        this.kothBtnBg.visible = true;
        this.kothBtnBgText.visible = true;
        this.kothAddTableBtn.visible = true;
        this.koth_plusIcon.visible = false;
        this.similarClick = true;
        this.stopOtherTimers();
        this.deSelectBtnsTimer = setTimeout(
            function () {
                this.kothBtnBg.visible = false;
                this.kothBtnBgText.visible = false;
                this.kothAddTableBtn.visible = false;
                this.koth_plusIcon.visible = true;
            }.bind(this),
            3000,
        );
        this.parentViewModel.sendHuPairTableSelectStatus(false);
    };
    resetBtns = function () {
        this.kothBtnContainer.removeChild(this.kothBtnBg);
        this.kothBtnContainer.removeChild(this.kothBtnBgText);
        this.kothBtnContainer.removeChild(this.kothAddTableBtn);
        this.kothBtnContainer.removeChild(this.koth_addTableBtnSelectedState);
        this.kothBtnBg.visible = false;
        this.kothBtnBgText.visible = false;
        this.kothAddTableBtn.visible = false;
        this.koth_addTableBtnSelectedState.visible = false;
    };
    kothAddSimilarClick = function () {
        this.koth_plusIcon.visible = false;
        this.similarClick = true;
        if (this.isKothSelected) {
            this.koth_addTableBtnSelectedState.visible = true;
            this.stopOtherTimers();
            this.kothAddSimilarClickTimer = setTimeout(
                function () {
                    this.koth_addTableBtnSelectedState.visible = false;
                    this.koth_plusIcon.visible = true;
                }.bind(this),
                3000,
            );
        } else {
            this.kothBtnBg.visible = true;
            this.kothBtnBgText.visible = true;
            this.kothAddTableBtn.visible = true;
            this.stopOtherTimers();
            this.kothAddSimilarClickTimer = setTimeout(
                function () {
                    this.kothBtnBg.visible = false;
                    this.kothBtnBgText.visible = false;
                    this.kothAddTableBtn.visible = false;
                    this.koth_plusIcon.visible = true;
                }.bind(this),
                3000,
            );
        }
    };
    kothPlusVisibility = function (isKothSelected) {
        this.isKothSelected = isKothSelected;
        this.koth_addTableBtnSelectedState.visible = false;
        //this.koth_plusIcon.visible = true;
    };
    showkothPlusIcon = function () {
        if (this.isPlayerJoined == true) this.koth_plusIcon.visible = true;
    };
    // hiding this button on opponent players removed
    hideKothBtn = function () {
        this.stopOtherTimers();
        this.koth_plusIcon.visible = false;
        this.kothBtnBg.visible = false;
        this.kothBtnBgText.visible = false;
        this.kothAddTableBtn.visible = false;
        this.koth_addTableBtnSelectedState.visible = false;
    };
    stopOtherTimers = function () {
        clearTimeout(this.kothAddSimilarClickTimer);
        clearTimeout(this.deSelectBtnsTimer);
        clearTimeout(this.kothBtnClickTimer);
        clearTimeout(this.displayKothButtonsTimer);
    };
}
