import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as _ from 'lodash-es';

// export interface TableItem {
//     // index:number,
//     tableId:number,
//     winUpto:number,
//     from: string,
//     gameTypeId: number,
//     reBuy: boolean,
//     tableInfoId: number
// }
export interface State {
    miniTables: any;
}

const initialMiniTablesState: State = {
    miniTables: [],
};

// selector while consuming store.
export const selectMiniTableState = createFeatureSelector<State[]>('MiniTables');

// create Selector
export const miniTablesSelect = createSelector(selectMiniTableState, (table: State[]) => table);

// create Action
export const addMiniTable = createAction('[Mini Tables] Add Mini table', (table: any) => table);
export const removeMiniTable = createAction('[Mini Tables] Remove Mini table', (tableId: any) => tableId);
export const updateActiveTable = createAction('[Mini Tables] Update Active Mini table', (table: any) => table);
export const updateTableCards = createAction('[Mini Tables] Update table cards', (table: any) => table);
export const updateTableStatus = createAction('[Mini Tables] Update table Status', (table: any) => table);
export const updateReBuyStatus = createAction('[Mini Tables] Update table Re-Buy Status', (table: any) => table);
export const updateProgressBarValue = createAction('[Mini Tables] Update Progress Bar Value', (table: any) => table);
export const updateSpinsTableCount = createAction('[Mini Tables] Update SpinsTableCount', (table: any) => table);

// create Reducer
export const miniTablesReducer = createReducer(
    initialMiniTablesState,
    on(addMiniTable, (state, table) => {
        const existingTableIndex = state.miniTables.findIndex((obj) => obj.tableId === table.miniTables.tableId);
        if (existingTableIndex !== -1) {
            // Table already exists, return the current state without updating
            return state;
        }
        // const tableId = table.miniTables.tableId;
        // let objIndex1 = _.maxBy(state.miniTables, 'index') !== undefined ? _.maxBy(state.miniTables, 'index')['index'] : 0;
        let objIndex1: number;
        const maxMiniTable: any = _.maxBy(state.miniTables, 'index');
        if (maxMiniTable && maxMiniTable.index !== undefined) {
            objIndex1 = maxMiniTable.index;
        } else {
            objIndex1 = 0;
        }

        const index =
            state.miniTables[table.miniTables.tableId] === undefined || state.miniTables[table.miniTables.tableId].index === undefined
                ? objIndex1 + 1
                : state.miniTables[table.miniTables.tableId].index;

        let tableObj;
        if (table.miniTables && table.miniTables.entryIds && table.miniTables.entryIds.length > 0) {
            // FF games
            tableObj = {
                index,
                from: 'fast-forward',
                reBuy: false,
                isActive: false,
                tableId: table.miniTables.tableId,
                gameTypeId: table.miniTables.tableStatus.gameTypeId,
                // tableInfoId: table.miniTables.tableStatus.dbTableInfoId,
                winUpto: table.miniTables.tableStatus.maxBuyin / 100,
                winUptoText: table.miniTables.tableStatus.gameTypeId === 7 ? 'SD' : 'FF',
                tableObject: table.miniTables,
                tableCards: [],
                miniTableStatus: {},
                progressBarValue: 0,
                TotalTimeAvailable: 0,
            };
        } else if (table.miniTables.tableCategory === 3 || table.miniTables.tableCategory === 4) {
            // Spins OR Expo
            tableObj = {
                index,
                from: 'spins-overdrive',
                reBuy: false,
                isActive: false,
                tableId: table.miniTables.tableId,
                gameTypeId: table.miniTables.gameTypeId,
                tableInfoId: table.miniTables.dbTableInfoId,
                winUpto: (table.miniTables.tourneyEntryFee + table.miniTables.tourneyBuyIn) / 100,
                winUptoText: table.miniTables.gameTypeId === 7 ? 'SD' : 'Overdrive',
                tableObject: table.miniTables,
                tableCards: [],
                miniTableStatus: {},
                progressBarValue: 0,
                TotalTimeAvailable: 0,
            };
        } else {
            // cash Games
            tableObj = {
                index,
                from: 'cash',
                reBuy: false,
                isActive: false,
                tableId: table.miniTables.tableId,
                gameTypeId: table.miniTables.gameTypeId,
                tableInfoId: table.miniTables.dbTableInfoId,
                winUpto: (table.miniTables.maxBuyInAmount > 0 ? table.miniTables.maxBuyInAmount : table.miniTables.minBuyInAmount) / 100,
                winUptoText: table.miniTables.gameTypeId === 7 ? 'SD' : 'Cash',
                tableObject: table.miniTables,
                tableCards: [],
                miniTableStatus: {},
                progressBarValue: 0,
                TotalTimeAvailable: 0,
            };
        }

        return { miniTables: [...state.miniTables, tableObj] };
    }),
    on(removeMiniTable, (state, table) => ({
        miniTables: state.miniTables.filter((u) => u.tableId !== table.tableId),
    })),
    on(updateActiveTable, (state, table) => ({
        miniTables: state.miniTables.map((obj) => (obj.tableId === table.tableId ? { ...obj, isActive: true } : { ...obj, isActive: false })),
    })),
    on(updateTableCards, (state, table) => ({
        miniTables: state.miniTables.map((obj) => (obj.tableId === table.tableId ? { ...obj, tableCards: table.cards } : { ...obj })),
    })),
    on(updateTableStatus, (state, table) => ({
        miniTables: state.miniTables.map((obj) => (obj.tableId === table.tableId ? { ...obj, miniTableStatus: table.status } : { ...obj })),
    })),
    on(updateReBuyStatus, (state, table) => ({
        miniTables: state.miniTables.map((obj) => (obj.tableId === table.tableId ? { ...obj, reBuy: table.reBuy } : { ...obj })),
    })),
    on(updateProgressBarValue, (state, table) => ({
        miniTables: state.miniTables.map((obj) =>
            obj.tableId === table.tableId
                ? { ...obj, progressBarValue: table.progressBarValue, TotalTimeAvailable: table.TotalTimeAvailable }
                : { ...obj },
        ),
    })),
    on(updateSpinsTableCount, (state, table) => ({
        miniTables: state.miniTables.map((obj) => (obj.tableId === table.tableId ? { ...obj, spinsTableCount: table.spinsTableCount } : { ...obj })),
    })),
);
