import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClaimsService, Page, ProductService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import * as _ from 'lodash-es';
import { DropdownModule } from 'primeng/dropdown';

import { formatCurrencyPipe } from './formatCurrency.pipe';
import { PPCashierOptionsFaqsModalComponent } from './pp-cashier-option-faqs-modal.component';
import { PPCashierOptionsService } from './pp-cashier-options.service';

@Component({
    selector: 'pp-cashier-options',
    templateUrl: 'pp-cashier-options.component.html',
    styles: [],
    standalone: true,
    imports: [
        CommonModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        PPCashierOptionsFaqsModalComponent,
        TrustAsHtmlPipe,
        formatCurrencyPipe,
    ],
})
export class PPCashierOptionsComponent extends PCComponent implements OnInit {
    @ViewChildren('optionList') optionList: QueryList<ElementRef>;

    constructor(
        private cashierOptionsService: PPCashierOptionsService,
        private claimsService: ClaimsService,
        public productService: ProductService,
        //private toasterService: ToasterService,
        private page: Page,
    ) {
        super();
    }

    public countries: Array<any> = [
        { code: 'DZ', name: 'Algeria', currency: 'EUR' },
        { code: 'AD', name: 'Andorra', currency: 'EUR' },
        { code: 'AG', name: 'Antigua and Barbuda', currency: 'USD' },
        { code: 'AR', name: 'Argentina', currency: 'USD' },
        { code: 'AM', name: 'Armenia', currency: 'USD' },
        { code: 'AW', name: 'Aruba', currency: 'USD' },
        { code: 'AU', name: 'Australia', currency: 'AUD' },
        { code: 'AT', name: 'Austria', currency: 'EUR' },
        { code: 'AZ', name: 'Azerbaijan', currency: 'USD' },
        { code: 'BD', name: 'Bangladesh', currency: 'USD' },
        { code: 'BB', name: 'Barbados', currency: 'USD' },
        { code: 'BY', name: 'Belarus', currency: 'USD' },
        { code: 'BE', name: 'Belgium', currency: 'EUR' },
        { code: 'BZ', name: 'Belize', currency: 'USD' },
        { code: 'BT', name: 'Bhutan', currency: 'USD' },
        { code: 'BQ', name: 'Bonaire St. Eustatius and Saba', currency: 'USD' },
        { code: 'BA', name: 'Bosnia and Herzegovina', currency: 'EUR' },
        { code: 'BW', name: 'Botswana', currency: 'USD' },
        { code: 'BR', name: 'Brazil', currency: 'USD' },
        { code: 'KH', name: 'Cambodia', currency: 'USD' },
        { code: 'CA', name: 'Canada', currency: 'CAD' },
        { code: 'KY', name: 'Cayman Islands', currency: 'USD' },
        { code: 'CL', name: 'Chile', currency: 'USD' },
        { code: 'CO', name: 'Colombia', currency: 'USD' },
        { code: 'CD', name: 'Congo, The Democratic Republic', currency: 'USD' },
        { code: 'CR', name: 'Costa Rica', currency: 'USD' },
        { code: 'CI', name: "Cote d'Ivoire", currency: 'EUR' },
        { code: 'HR', name: 'Croatia', currency: 'USD' },
        { code: 'CW', name: 'Curacao', currency: 'USD' },
        { code: 'CY', name: 'Cyprus', currency: 'EUR' },
        { code: 'CZ', name: 'Czech Republic', currency: 'USD' },
        { code: 'DK', name: 'Denmark', currency: 'DKK' },
        { code: 'DO', name: 'Dominican Republic', currency: 'USD' },
        { code: 'EC', name: 'Ecuador', currency: 'USD' },
        { code: 'SV', name: 'El Salvador', currency: 'USD' },
        { code: 'EE', name: 'Estonia', currency: 'EUR' },
        { code: 'FO', name: 'Faroe Islands', currency: 'USD' },
        { code: 'FI', name: 'Finland', currency: 'EUR' },
        { code: 'FR', name: 'France', currency: 'EUR' },
        { code: 'GF', name: 'French Guiana', currency: 'EUR' },
        { code: 'PF', name: 'French Polynesia', currency: 'EUR' },
        { code: 'TF', name: 'French Southern Territories', currency: 'EUR' },
        { code: 'GE', name: 'Georgia', currency: 'USD' },
        { code: 'DE', name: 'Germany', currency: 'EUR' },
        { code: 'GI', name: 'Gibraltar', currency: 'USD' },
        { code: 'GR', name: 'Greece', currency: 'EUR' },
        { code: 'GL', name: 'Greenland', currency: 'USD' },
        { code: 'GG', name: 'Guernsey', currency: 'GBP' },
        { code: 'HN', name: 'Honduras', currency: 'USD' },
        { code: 'HU', name: 'Hungary', currency: 'USD' },
        { code: 'IS', name: 'Iceland', currency: 'USD' },
        { code: 'IE', name: 'Ireland', currency: 'EUR' },
        { code: 'IM', name: 'Isle of Man', currency: 'GBP' },
        { code: 'JM', name: 'Jamaica', currency: 'USD' },
        { code: 'JP', name: 'Japan', currency: 'USD' },
        { code: 'JE', name: 'Jersey', currency: 'GBP' },
        { code: 'KZ', name: 'Kazakhstan', currency: 'USD' },
        { code: 'KO', name: 'Kosovo', currency: 'EUR' },
        { code: 'LV', name: 'Latvia', currency: 'USD' },
        { code: 'LI', name: 'Liechtenstein', currency: 'USD' },
        { code: 'LT', name: 'Lithuania', currency: 'USD' },
        { code: 'LU', name: 'Luxembourg', currency: 'EUR' },
        { code: 'MO', name: 'Macau', currency: 'USD' },
        { code: 'MK', name: 'Macedonia', currency: 'EUR' },
        { code: 'MT', name: 'Malta', currency: 'EUR' },
        { code: 'MU', name: 'Mauritius', currency: 'USD' },
        { code: 'MX', name: 'Mexico', currency: 'USD' },
        { code: 'MD', name: 'Moldova', currency: 'USD' },
        { code: 'MC', name: 'Monaco', currency: 'USD' },
        { code: 'ME', name: 'Montenegro', currency: 'EUR' },
        { code: 'NA', name: 'Namibia', currency: 'USD' },
        { code: 'NL', name: 'Netherlands', currency: 'EUR' },
        { code: 'NC', name: 'New Caledonia', currency: 'EUR' },
        { code: 'NZ', name: 'New Zealand', currency: 'NZD' },
        { code: 'NI', name: 'Nicaragua', currency: 'USD' },
        { code: 'NO', name: 'Norway', currency: 'USD' },
        { code: 'PA', name: 'Panama', currency: 'USD' },
        { code: 'PY', name: 'Paraguay', currency: 'USD' },
        { code: 'PE', name: 'Peru', currency: 'USD' },
        { code: 'PH', name: 'Philippines', currency: 'USD' },
        { code: 'PL', name: 'Poland', currency: 'PLN' },
        { code: 'PT', name: 'Portugal', currency: 'EUR' },
        { code: 'RO', name: 'Romania', currency: 'RON' },
        { code: 'RU', name: 'Russia', currency: 'USD' },
        { code: 'SM', name: 'San Marino', currency: 'EUR' },
        { code: 'SN', name: 'Senegal', currency: 'EUR' },
        { code: 'RS', name: 'Serbia', currency: 'EUR' },
        { code: 'SX', name: 'Sint Maarten', currency: 'USD' },
        { code: 'SK', name: 'Slovakia', currency: 'EUR' },
        { code: 'SI', name: 'Slovenia', currency: 'EUR' },
        { code: 'ZA', name: 'South Africa', currency: 'ZAR' },
        { code: 'ES', name: 'Spain', currency: 'EUR' },
        { code: 'SE', name: 'Sweden', currency: 'SEK' },
        { code: 'CH', name: 'Switzerland', currency: 'CHF' },
        { code: 'TT', name: 'Trinidad and Tobago', currency: 'USD' },
        { code: 'TC', name: 'Turks and Caicos Islands', currency: 'USD' },
        { code: 'UA', name: 'Ukraine', currency: 'USD' },
        { code: 'GB', name: 'United Kingdom', currency: 'GBP' },
        { code: 'UY', name: 'Uruguay', currency: 'USD' },
        { code: 'VA', name: 'Vatican City State', currency: 'EUR' },
        { code: 'VE', name: 'Venezuela', currency: 'USD' },
        { code: 'VN', name: 'Viet Nam', currency: 'USD' },
        { code: 'ZZ', name: 'Others', currency: 'USD' },
    ];
    public optionType: string;
    public cashierOptions: any;
    public splitCashierOptions: any;
    public cashierOptionsList: any;
    public widgetOptions: any;
    public splitTypesMap: any;
    public faqOptions: any;
    public faqServiceParams: any;
    public showDesc: boolean = false;
    public countryCode: string;
    public currencyCode: string;
    public languageCode: string;
    public selectedCardInfo: any;
    public depositInfoClass: string = '';
    public countriesTest: any = [];
    public selectedCountry: any;
    public enableLoader: boolean;
    public apiBaseUrl: string = '';
    public widgetConfig: any;
    // public config: ToasterConfig =
    // new ToasterConfig({
    //     showCloseButton: false,
    //     tapToDismiss: false,
    //     timeout: 2000,
    //     positionClass: 'toast-bottom-right',
    //     animation: 'fade',
    //     limit: 1,
    //     preventDuplicates: false
    // });

    ngOnInit() {
        this.countryCode = this.claimsService.get('country') ?? '';
        this.currencyCode = this.claimsService.get('currency') ?? '';
        this.languageCode = this.page.lang;

        this.enableLoader = true;
        this.apiBaseUrl = this.productService.getMetadata('poker').apiBaseUrl;
        this.optionType = this.item.displayOption.toLowerCase() || 'deposit';
        if (this.languageCode == 'de-at') {
            this.languageCode = 'de';
        }
        this.cashierOptionsService
            .getCashierOptions(this.countryCode, this.optionType, this.currencyCode, this.languageCode)
            .subscribe((response: any) => {
                this.cashierOptions = response.cashierOptions;
                this.widgetConfig = response.contentConfigurations;
                this.cashierOptionsList = this.cashierOptions.optiondetails;
                this.splitCashierOptions = this.splitArr(this.cashierOptions.optiondetails, 4);
                this.widgetOptions = response.widgetOptions;
                this.enableLoader = false;
            });

        this.selectedCountry = { code: 'GB', name: 'United Kingdom', currency: 'GBP' };
    }

    filterPaymentOptions(paymentOption: any, event: any) {
        const checkValue = event.target.checked;
        const paymentOptionsIds = this.widgetOptions.types[paymentOption.key];
        const optionsFiltered = this.cashierOptions.optiondetails.filter((el: any) => {
            return paymentOptionsIds.some((f: any) => {
                el.id = el.id.replace('pay_', '');
                return f === el.id;
            });
        });

        if (checkValue) {
            optionsFiltered.forEach((item: any) => {
                this.cashierOptionsList.unshift(item);
            });
        } else {
            const optionsFilteredSub = this.cashierOptionsList.filter(
                (entry1: any) => !optionsFiltered.some((entry2: any) => entry1.id === entry2.id),
            );
            this.cashierOptionsList = optionsFilteredSub;
        }

        this.splitCashierOptions = this.splitArr(this.cashierOptionsList, 4);
        this.closeDepositInfo();
    }

    refreshFeed(event: any) {
        this.enableLoader = true;
        this.cashierOptionsService
            .getCashierOptions(event.value.code, this.optionType, event.value.currency, this.languageCode)
            .subscribe((response: any) => {
                this.cashierOptions = response.cashierOptions;
                this.cashierOptionsList = null;
                this.cashierOptionsList = this.cashierOptions.optiondetails;
                this.splitCashierOptions = this.splitArr(this.cashierOptions.optiondetails, 4);
                this.widgetOptions = response.widgetOptions;
                this.selectedCardInfo = '';
                this.optionList.forEach((item: any) => {
                    item.nativeElement.checked = true;
                });
                this.enableLoader = false;
            });
    }

    getFaq(option: string) {
        const faqParams: any = {
            country: this.countryCode,
            optionType: this.optionType,
            option: option,
            currency: this.currencyCode,
            language: this.languageCode,
        };
        this.faqServiceParams = faqParams;
    }

    resetFaqs(event: any) {
        if (event) {
            this.faqServiceParams = '';
        }
    }

    //showByType(event: any) {
    //this.toasterService.pop('error', 'Error', this.widgetConfig?.faqsNotFound);
    //}

    // splitArr(arr: any, size: number) {
    //     let newArr = [];
    //     let sortArray = _.orderBy(arr, ['name'], ['asc']);
    //     for (let i = 0; i < sortArray.length; i += size) {
    //         newArr.push(sortArray.slice(i, i + size));
    //     }
    //     return newArr;
    // }

    splitArr(arr: any[], size: number): any[][] {
        const newArr: any[][] = [];
        const sortArray = _.orderBy(arr, ['name'], ['asc']);
        for (let i = 0; i < sortArray.length; i += size) {
            newArr.push(sortArray.slice(i, i + size));
        }
        return newArr;
    }

    compareFee(fee: string) {
        const feeOne = '0.00';
        const feeTwo = '0.0&nbsp;';
        if (fee.includes(feeOne) || fee.includes(feeTwo)) {
            return true;
        } else {
            return false;
        }
    }

    showCardInfor(cardInfo: any, index: any) {
        this.splitCashierOptions.forEach((item: any) => {
            return item.map((subItem: any) => {
                subItem.active = false;
                return subItem;
            });
        });
        if (_.isEqual(this.selectedCardInfo, cardInfo)) {
            this.selectedCardInfo = '';
        } else {
            this.selectedCardInfo = cardInfo;
            const cardIndex = index + 1;
            if (cardIndex == 1) {
                this.depositInfoClass = 'show-first-card';
            } else if (cardIndex == 2) {
                this.depositInfoClass = 'show-second-card';
            } else if (cardIndex == 3) {
                this.depositInfoClass = 'show-third-card';
            } else if (cardIndex == 4) {
                this.depositInfoClass = 'show-fourth-card';
            } else if (cardIndex == 5) {
                this.depositInfoClass = 'show-fifth-card';
            } else if (cardIndex == 6) {
                this.depositInfoClass = 'show-sixth-card';
            }

            cardInfo.active = true;
        }
    }

    closeDepositInfo() {
        this.splitCashierOptions.forEach((item: any) => {
            return item.map((subItem: any) => {
                subItem.active = false;
                return subItem;
            });
        });
        this.selectedCardInfo = '';
    }
}
