import { Message } from '../../message';
import { FXRateDetails } from '../../pgSharedMessageFactory/messages/FXRateDetails';

export class CanPlayerRegisterResponse extends Message {
    private MESSAGE_NAME: string = 'CanPlayerRegisterResponse';
    private mtctId: number = 0;
    private errorMessage: any | null = null;
    private balance: number = 0;
    private isRealMoney: boolean | null = false;
    private freeRollBalance: number = 0;
    private fppBalance: number = 0;
    private fppBuyIn: number = 0;
    private tdBalance: number = 0;
    private fxSnapshotId: number = 0;
    private fxRateDetails: FXRateDetails | null = null;
    private isFreerollOnlyTourny: boolean | null = false;
    private buyInType: number = 0;
    private noOfEntriesUsed: number = 0;
    private isPasswordTrny: boolean | null = false;
    private rebuyBuyIn: number = 0;
    private rebuyFee: number = 0;
    private addonBuyIn: number = 0;
    private addonFee: number = 0;
    private prizeType: number = 0;
    private allowLateRegistrationTillLevel: number = 0;
    private considerLRCloseConfig: boolean | null = false;
    private lrCloseTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        errorMessage: any | null = null,
        balance: number = 0,
        isRealMoney: boolean | null = false,
        freeRollBalance: number = 0,
        fppBalance: number = 0,
        fppBuyIn: number = 0,
        tdBalance: number = 0,
        fxSnapshotId: number = 0,
        fxRateDetails: FXRateDetails | null = null,
        isFreerollOnlyTourny: boolean | null = false,
        buyInType: number = 0,
        noOfEntriesUsed: number = 0,
        isPasswordTrny: boolean | null = false,
        rebuyBuyIn: number = 0,
        rebuyFee: number = 0,
        addonBuyIn: number = 0,
        addonFee: number = 0,
        prizeType: number = 0,
        allowLateRegistrationTillLevel: number = 0,
        considerLRCloseConfig: boolean | null = false,
        lrCloseTime: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.errorMessage = errorMessage;
        this.balance = balance;
        this.isRealMoney = isRealMoney;
        this.freeRollBalance = freeRollBalance;
        this.fppBalance = fppBalance;
        this.fppBuyIn = fppBuyIn;
        this.tdBalance = tdBalance;
        this.fxSnapshotId = fxSnapshotId;
        this.fxRateDetails = fxRateDetails;
        this.isFreerollOnlyTourny = isFreerollOnlyTourny;
        this.buyInType = buyInType;
        this.noOfEntriesUsed = noOfEntriesUsed;
        this.isPasswordTrny = isPasswordTrny;
        this.rebuyBuyIn = rebuyBuyIn;
        this.rebuyFee = rebuyFee;
        this.addonBuyIn = addonBuyIn;
        this.addonFee = addonFee;
        this.prizeType = prizeType;
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
        this.considerLRCloseConfig = considerLRCloseConfig;
        this.lrCloseTime = lrCloseTime;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getErrorMessage(): any | null {
        return this.errorMessage;
    }

    setErrorMessage(errorMessage: any | null) {
        this.errorMessage = errorMessage;
    }
    getBalance(): number {
        return this.balance;
    }

    setBalance(balance: number) {
        this.balance = balance;
    }
    getIsRealMoney(): boolean | null {
        return this.isRealMoney;
    }

    setIsRealMoney(isRealMoney: boolean | null) {
        this.isRealMoney = isRealMoney;
    }
    getFreeRollBalance(): number {
        return this.freeRollBalance;
    }

    setFreeRollBalance(freeRollBalance: number) {
        this.freeRollBalance = freeRollBalance;
    }
    getFppBalance(): number {
        return this.fppBalance;
    }

    setFppBalance(fppBalance: number) {
        this.fppBalance = fppBalance;
    }
    getFppBuyIn(): number {
        return this.fppBuyIn;
    }

    setFppBuyIn(fppBuyIn: number) {
        this.fppBuyIn = fppBuyIn;
    }
    getTdBalance(): number {
        return this.tdBalance;
    }

    setTdBalance(tdBalance: number) {
        this.tdBalance = tdBalance;
    }
    getFxSnapshotId(): number {
        return this.fxSnapshotId;
    }

    setFxSnapshotId(fxSnapshotId: number) {
        this.fxSnapshotId = fxSnapshotId;
    }
    getFxRateDetails(): FXRateDetails | null {
        return this.fxRateDetails;
    }

    setFxRateDetails(fxRateDetails: FXRateDetails | null) {
        this.fxRateDetails = fxRateDetails;
    }
    getIsFreerollOnlyTourny(): boolean | null {
        return this.isFreerollOnlyTourny;
    }

    setIsFreerollOnlyTourny(isFreerollOnlyTourny: boolean | null) {
        this.isFreerollOnlyTourny = isFreerollOnlyTourny;
    }
    getBuyInType(): number {
        return this.buyInType;
    }

    setBuyInType(buyInType: number) {
        this.buyInType = buyInType;
    }
    getNoOfEntriesUsed(): number {
        return this.noOfEntriesUsed;
    }

    setNoOfEntriesUsed(noOfEntriesUsed: number) {
        this.noOfEntriesUsed = noOfEntriesUsed;
    }
    getIsPasswordTrny(): boolean | null {
        return this.isPasswordTrny;
    }

    setIsPasswordTrny(isPasswordTrny: boolean | null) {
        this.isPasswordTrny = isPasswordTrny;
    }
    getRebuyBuyIn(): number {
        return this.rebuyBuyIn;
    }

    setRebuyBuyIn(rebuyBuyIn: number) {
        this.rebuyBuyIn = rebuyBuyIn;
    }
    getRebuyFee(): number {
        return this.rebuyFee;
    }

    setRebuyFee(rebuyFee: number) {
        this.rebuyFee = rebuyFee;
    }
    getAddonBuyIn(): number {
        return this.addonBuyIn;
    }

    setAddonBuyIn(addonBuyIn: number) {
        this.addonBuyIn = addonBuyIn;
    }
    getAddonFee(): number {
        return this.addonFee;
    }

    setAddonFee(addonFee: number) {
        this.addonFee = addonFee;
    }
    getPrizeType(): number {
        return this.prizeType;
    }

    setPrizeType(prizeType: number) {
        this.prizeType = prizeType;
    }
    getAllowLateRegistrationTillLevel(): number {
        return this.allowLateRegistrationTillLevel;
    }

    setAllowLateRegistrationTillLevel(allowLateRegistrationTillLevel: number) {
        this.allowLateRegistrationTillLevel = allowLateRegistrationTillLevel;
    }
    getConsiderLRCloseConfig(): boolean | null {
        return this.considerLRCloseConfig;
    }

    setConsiderLRCloseConfig(considerLRCloseConfig: boolean | null) {
        this.considerLRCloseConfig = considerLRCloseConfig;
    }
    getLrCloseTime(): number {
        return this.lrCloseTime;
    }

    setLrCloseTime(lrCloseTime: number) {
        this.lrCloseTime = lrCloseTime;
    }
}
