import { Message } from '../../message';

export class KeyValuePair extends Message {
    private MESSAGE_NAME: string = 'KeyValuePair';
    private key: string | null = null;
    private value: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, key: string | null = null, value: string | null = null) {
        super(reqServerPeerId);
        this.key = key;
        this.value = value;
    }

    getKey(): string | null {
        return this.key;
    }

    setKey(key: string | null) {
        this.key = key;
    }
    getValue(): string | null {
        return this.value;
    }

    setValue(value: string | null) {
        this.value = value;
    }
}
