import { Message } from '../../message';

export class CreateChallengeHUCashRequest extends Message {
    private MESSAGE_NAME: string = 'CreateChallengeHUCashRequest';
    private challengeId: number = 0;
    private isDefaultMessage: boolean | null = false;
    private message: string | null = null;
    private invitee: string | null = null;
    private tableInfoId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        challengeId: number = 0,
        isDefaultMessage: boolean | null = false,
        message: string | null = null,
        invitee: string | null = null,
        tableInfoId: number = 0,
    ) {
        super(reqServerPeerId);
        this.challengeId = challengeId;
        this.isDefaultMessage = isDefaultMessage;
        this.message = message;
        this.invitee = invitee;
        this.tableInfoId = tableInfoId;
    }

    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
    getIsDefaultMessage(): boolean | null {
        return this.isDefaultMessage;
    }

    setIsDefaultMessage(isDefaultMessage: boolean | null) {
        this.isDefaultMessage = isDefaultMessage;
    }
    getMessage(): string | null {
        return this.message;
    }

    setMessage(message: string | null) {
        this.message = message;
    }
    getInvitee(): string | null {
        return this.invitee;
    }

    setInvitee(invitee: string | null) {
        this.invitee = invitee;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
}
