import { Message } from '../../message';

export class ItaliaAuthorizationCodes extends Message {
    private MESSAGE_NAME: string = 'ItaliaAuthorizationCodes';
    private sessionId: string | null = null;
    private participationId: string | null = null;
    private mtctId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, sessionId: string | null = null, participationId: string | null = null, mtctId: number = 0) {
        super(reqServerPeerId);
        this.sessionId = sessionId;
        this.participationId = participationId;
        this.mtctId = mtctId;
    }

    getSessionId(): string | null {
        return this.sessionId;
    }

    setSessionId(sessionId: string | null) {
        this.sessionId = sessionId;
    }
    getParticipationId(): string | null {
        return this.participationId;
    }

    setParticipationId(participationId: string | null) {
        this.participationId = participationId;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
}
