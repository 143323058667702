import { Message } from '../../message';

export class DealMakingAcceptPlayerCount extends Message {
    private MESSAGE_NAME: string = 'DealMakingAcceptPlayerCount';
    private acceptedPlayerCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, acceptedPlayerCount: number = 0) {
        super(reqServerPeerId);
        this.acceptedPlayerCount = acceptedPlayerCount;
    }

    getAcceptedPlayerCount(): number {
        return this.acceptedPlayerCount;
    }

    setAcceptedPlayerCount(acceptedPlayerCount: number) {
        this.acceptedPlayerCount = acceptedPlayerCount;
    }
}
