import { Message } from '../../message';
import { GameTableStatus } from './GameTableStatus';

export class SeatAvailable extends Message {
    private MESSAGE_NAME: string = 'SeatAvailable';
    private tableId: number = 0;
    private gameTableStatus: GameTableStatus | null = null;
    private seatNumber: number = 0;
    private timeOut: number = 0;
    private forGlobalWaitlist: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        gameTableStatus: GameTableStatus | null = null,
        seatNumber: number = 0,
        timeOut: number = 0,
        forGlobalWaitlist: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.gameTableStatus = gameTableStatus;
        this.seatNumber = seatNumber;
        this.timeOut = timeOut;
        this.forGlobalWaitlist = forGlobalWaitlist;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getGameTableStatus(): GameTableStatus | null {
        return this.gameTableStatus;
    }

    setGameTableStatus(gameTableStatus: GameTableStatus | null) {
        this.gameTableStatus = gameTableStatus;
    }
    getSeatNumber(): number {
        return this.seatNumber;
    }

    setSeatNumber(seatNumber: number) {
        this.seatNumber = seatNumber;
    }
    getTimeOut(): number {
        return this.timeOut;
    }

    setTimeOut(timeOut: number) {
        this.timeOut = timeOut;
    }
    getForGlobalWaitlist(): boolean | null {
        return this.forGlobalWaitlist;
    }

    setForGlobalWaitlist(forGlobalWaitlist: boolean | null) {
        this.forGlobalWaitlist = forGlobalWaitlist;
    }
}
