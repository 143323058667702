import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class NavigationBarResourceService {
    constructor(private api: PokerApiService) {}

    getInitValues(): Observable<any> {
        return this.api.get('Promotions/GetMainMenu');
    }
}
