import { Message } from '../../message';

export class TournamentLateRegistrationDetails extends Message {
    private MESSAGE_NAME: string = 'TournamentLateRegistrationDetails';
    private lrCloseTime: number = 0;
    private remainingLRTime: number = 0;
    private tourneyStartTime: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, lrCloseTime: number = 0, remainingLRTime: number = 0, tourneyStartTime: any | null = null) {
        super(reqServerPeerId);
        this.lrCloseTime = lrCloseTime;
        this.remainingLRTime = remainingLRTime;
        this.tourneyStartTime = tourneyStartTime;
    }

    getLrCloseTime(): number {
        return this.lrCloseTime;
    }

    setLrCloseTime(lrCloseTime: number) {
        this.lrCloseTime = lrCloseTime;
    }
    getRemainingLRTime(): number {
        return this.remainingLRTime;
    }

    setRemainingLRTime(remainingLRTime: number) {
        this.remainingLRTime = remainingLRTime;
    }
    getTourneyStartTime(): any | null {
        return this.tourneyStartTime;
    }

    setTourneyStartTime(tourneyStartTime: any | null) {
        this.tourneyStartTime = tourneyStartTime;
    }
}
