import { Injectable } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class ScrollToTargetService {
    element: HTMLElement;
    constructor(private deviceService: DeviceService) {}

    scrollToTargetMain() {
        if (this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet) {
            document.addEventListener('touchend', this.scrollToTarget);
        } else {
            document.addEventListener('click', this.scrollToTarget);
        }
    }

    private scrollToTarget(e: any) {
        const targetAttr = e.target.getAttribute('data-scrolltotarget');
        if (targetAttr) {
            const splitValue = targetAttr.split('-');
            const getTargetId = splitValue[0];
            const headerOffset = splitValue[1];

            this.element = document.getElementById(getTargetId) as HTMLElement;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = this.element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    }
}
