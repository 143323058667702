import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { LauncherGaurdService } from './launcher-gaurd.service';

@Injectable({ providedIn: 'root' })
export class LauncherActivateGuard implements CanActivate {
    constructor(private launcherAuthService: LauncherGaurdService) {}

    canActivate(): boolean {
        // LOGIC
        this.launcherAuthService.getVerifiedLaunchAccess();
        return true;
    }
}
