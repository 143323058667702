import { Message } from '../../message';
import { LSDynamicLanguagePackUpdate } from './LSDynamicLanguagePackUpdate';

export class LSTabIdDynamicLanguagePackDetails extends Message {
    private MESSAGE_NAME: string = 'LSTabIdDynamicLanguagePackDetails';
    private dynamicLanguagePackUpdate: LSDynamicLanguagePackUpdate | null = null;
    private snapShotTimeInNanos: number = 0;
    private fullUpdate: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        dynamicLanguagePackUpdate: LSDynamicLanguagePackUpdate | null = null,
        snapShotTimeInNanos: number = 0,
        fullUpdate: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.dynamicLanguagePackUpdate = dynamicLanguagePackUpdate;
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.fullUpdate = fullUpdate;
    }

    getDynamicLanguagePackUpdate(): LSDynamicLanguagePackUpdate | null {
        return this.dynamicLanguagePackUpdate;
    }

    setDynamicLanguagePackUpdate(dynamicLanguagePackUpdate: LSDynamicLanguagePackUpdate | null) {
        this.dynamicLanguagePackUpdate = dynamicLanguagePackUpdate;
    }
    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getFullUpdate(): boolean | null {
        return this.fullUpdate;
    }

    setFullUpdate(fullUpdate: boolean | null) {
        this.fullUpdate = fullUpdate;
    }
}
