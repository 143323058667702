import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { SpinGameService } from '../../service/spin-game.service';
import { registeredTourneysList } from '../../store/lobby.store';

@Component({
    selector: 'pwc-my-tourneys',
    templateUrl: 'myTourneys.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    providers: [TranslatePipe],
})
export class MyTourneysComponent implements OnInit {
    tourneysData: any = [];
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    constructor(
        private store: Store,
        private spinGameService: SpinGameService,
        private shareService: PokerWebClientSharedService,
        private translatePipe: TranslatePipe,
    ) {}

    ngOnInit(): void {
        this.shareService.setPageHeaderTitle({
            title: this.translatePipe.transform('PARTY_POKER_MOBILE_TOURNAMENTS_MY_TOURNEYS'),
            url: '/play',
            showBalanceBlock: true,
        });
        let tIds: any = [];
        this.store.select(registeredTourneysList).subscribe((data) => {
            this.tourneysData = data;
            tIds = [];
            data.sngJPInstanceDetails.forEach((value: any) => {
                tIds.push(value.tournamentID);
            });
        });
        this.spinGameService.RequestSNGJPLobbyData(tIds, 0);
    }

    getTournamentStatusText(status) {
        let tableStatus = '';
        switch (status) {
            case 5:
                tableStatus = 'PARTY_POKER_GC_COMMON_TRNYSTATUS_RUNNING';
                break;
            case 11:
                tableStatus = 'PARTY_POKER_GC_COMMON_TRNYSTATUSONBREAK';
                break;
            case 10:
                tableStatus = 'PARTY_POKER_GC_COMMON_TRNYSTATUS_PAUSED';
                break;
            case 18:
                tableStatus = 'PARTY_POKER_GC_TOURNAMENTLOBBY_HEADSUPWAITING';
                break;
            case 6:
                tableStatus = 'PARTY_POKER_GC_POKERUTIL_TRNYSTATUSFINISHED';
                break;
            case 7:
                tableStatus = 'PARTY_POKER_GC_POKERUTIL_MTCTSTATUSCRASHED';
                break;
            case 8:
                tableStatus = 'PARTY_POKER_GC_POKERUTIL_MTCTSTATUSCANCELLED';
                break;
            case 9:
                tableStatus = 'PARTY_POKER_GC_POKERUTIL_TOURNEYSTATUSSETTLED';
                break;
            default:
                tableStatus = 'PARTY_POKER_GC_COMMON_TRNYSTATUS_REGISTERED';
                break;
        }
        return tableStatus;
    }

    selectedTourney(tourney: any) {
        const message = {};
        message['className'] = 'selectTourney';
        message['tableId'] = tourney.tableId;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    getTournamentName(id: number) {
        return this.tourneysData?.dynaDetails?.literalsMap[id];
    }
}
