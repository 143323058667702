<div class="contentWrap dark">
    <div class="col">
        <div class="row p-4" *ngIf="softwareupdatesEnabled">
            <div class="col-md-6 text-center">
                <img *ngIf="softwareUpdatesContent.image" [src]="softwareUpdatesContent.image.src" alt="Software" class="img-fluid" />
            </div>
            <div class="col-md-5 offset-md-1 pc-txt">
                <h2>{{ softwareUpdatesContent.title }}</h2>
                <div [innerHtml]="softwareUpdatesContent.text"></div>
                <span *ngIf="softwareUpdatesContent.optionalText" [innerHtml]="softwareUpdatesContent.optionalText"></span>
                <span>
                    <a
                        class="ml-4"
                        *ngIf="softwareUpdatesContent.titleLink"
                        [href]="softwareUpdatesContent.titleLink.url"
                        [target]="softwareUpdatesContent.titleLink.attributes.target || '_self'"
                        >{{ softwareUpdatesContent.titleLink.linkText }}</a
                    >
                </span>
            </div>
        </div>
    </div>
</div>
