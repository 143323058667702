import { Message } from '../../message';
import { FXRateDetails } from '../../pgSharedMessageFactory/messages/FXRateDetails';

export class SNGJPPlayNowResponse extends Message {
    private MESSAGE_NAME: string = 'SNGJPPlayNowResponse';
    private sngJackpotID: number = 0;
    private responseCode: number = 0;
    private requestedEntries: number = 0;
    private availableEntries: number = 0;
    private responseMessage: any | null = null;
    private accountBalance: number = 0;
    private availableTickets: number = 0;
    private fxSnapshotID: number = 0;
    private fxDetails: FXRateDetails | null = null;
    private tdBalance: number = 0;
    private remainingEntries: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        responseCode: number = 0,
        requestedEntries: number = 0,
        availableEntries: number = 0,
        responseMessage: any | null = null,
        accountBalance: number = 0,
        availableTickets: number = 0,
        fxSnapshotID: number = 0,
        fxDetails: FXRateDetails | null = null,
        tdBalance: number = 0,
        remainingEntries: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.responseCode = responseCode;
        this.requestedEntries = requestedEntries;
        this.availableEntries = availableEntries;
        this.responseMessage = responseMessage;
        this.accountBalance = accountBalance;
        this.availableTickets = availableTickets;
        this.fxSnapshotID = fxSnapshotID;
        this.fxDetails = fxDetails;
        this.tdBalance = tdBalance;
        this.remainingEntries = remainingEntries;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getRequestedEntries(): number {
        return this.requestedEntries;
    }

    setRequestedEntries(requestedEntries: number) {
        this.requestedEntries = requestedEntries;
    }
    getAvailableEntries(): number {
        return this.availableEntries;
    }

    setAvailableEntries(availableEntries: number) {
        this.availableEntries = availableEntries;
    }
    getResponseMessage(): any | null {
        return this.responseMessage;
    }

    setResponseMessage(responseMessage: any | null) {
        this.responseMessage = responseMessage;
    }
    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getAvailableTickets(): number {
        return this.availableTickets;
    }

    setAvailableTickets(availableTickets: number) {
        this.availableTickets = availableTickets;
    }
    getFxSnapshotID(): number {
        return this.fxSnapshotID;
    }

    setFxSnapshotID(fxSnapshotID: number) {
        this.fxSnapshotID = fxSnapshotID;
    }
    getFxDetails(): FXRateDetails | null {
        return this.fxDetails;
    }

    setFxDetails(fxDetails: FXRateDetails | null) {
        this.fxDetails = fxDetails;
    }
    getTdBalance(): number {
        return this.tdBalance;
    }

    setTdBalance(tdBalance: number) {
        this.tdBalance = tdBalance;
    }
    getRemainingEntries(): number {
        return this.remainingEntries;
    }

    setRemainingEntries(remainingEntries: number) {
        this.remainingEntries = remainingEntries;
    }
}
