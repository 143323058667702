<div *ngIf="serve.handHistory" class="hh-mainbody" [class.mac-desktop]="MacDesktop" [class.mobile-nativeapp]="mobileNativeApp">
    <div class="heading-strip hh-page">
        <a (click)="navigateBack()"><i class="back-arrow icon-arrow-left"></i></a>
        <h2>{{ serve.content.messages.HandsHistory }}</h2>
        <a
            *ngIf="serve.handHistory.hasOwnProperty('gameRounds') && serve.handHistory.gameRounds.length > 0 && !serve.singleHandStatus"
            class="filter-date filter-date-arrows"
            [class.filter-on]="checkFilterStatus()"
            [class.filter-on]="checkFilterStatus()"
            (click)="sortHands()">
            <span class="left-arrow"><i class="theme-arrow-down"></i></span>
            <span class="right-arrow"><i class="theme-arrow-down"></i></span>
        </a>
    </div>
    <div
        *ngIf="
            serve.handHistory.hasOwnProperty('gameRounds') && serve.handHistory.gameRounds.length > 0 && !serve.singleHandStatus && !serve.noHands;
            else singleHand
        ">
        <ng-container *ngFor="let groupItem of groupItems(); let i = index">
            <div class="grouped-block" *ngIf="serve[groupItem]">
                <div
                    class="grouped-hands"
                    [attr.group-type]="groupItem"
                    *ngIf="serve[groupItem].length > 0"
                    [class.active]="serve[groupItem].length > 0">
                    <div class="sub-heading-block">
                        <p class="hands-data">
                            {{ serve.content.messages[groupItem] }}
                            <span *ngIf="groupItem.indexOf('TodayHands') > -1">{{ getDateString(groupItem) }}</span>
                            <span class="hands-count"
                                >({{ serve[groupItem].length }}
                                <span *ngIf="serve[groupItem].length === 1; else hands">{{ serve.content.messages.Hand }}</span>
                                <ng-template #hands> {{ serve.content.messages.Hands }}</ng-template
                                >)
                            </span>
                        </p>
                    </div>
                    <ng-container *ngFor="let gameRound of serve[groupItem]; let index = index">
                        <div
                            class="hands-details"
                            [attr.group-type]="groupItem"
                            [attr.data-unix-start-date]="convertUnixTime(gameRound.gameRoundTime.start)"
                            [attr.roundid]="gameRound.roundReference.roundNumber"
                            [class.show-details]="activeRoundId === convertToString(gameRound.roundReference.roundNumber)"
                            [class.last-hand]="groupItem === lastGroupType && index === serve[groupItem].length - 1"
                            (click)="showHandDetails(index, $event)"
                            (touchstart)="touchStart($event)"
                            (touchend)="touchEnd($event, index, groupItem)"
                            [attr.game-type]="gameRound.game.gameType"
                            [attr.index]="index"
                            #handDetails>
                            <div class="hand-sub-block">
                                <p class="time-block">
                                    <span class="player-time"
                                        >{{ getTime(gameRound.gameRoundTime.start) }}<span class="time-zone">(EST)</span>
                                        <span class="player-date">{{ getFormatedDate(gameRound.gameRoundTime.start) }}</span>
                                    </span>
                                    <i class="thumbs icon-thumb-up" *ngIf="userid === getSubString(gameRound.winnerAccountName)"></i>
                                    <i class="thumbs icon-thumb-down" *ngIf="userid !== getSubString(gameRound.winnerAccountName)"></i>
                                    <span class="game-col">
                                        <span class="gameType">
                                            <i class="thumbs icon-thumb-up" *ngIf="userid === getSubString(gameRound.winnerAccountName)"></i>
                                            <i class="thumbs icon-thumb-down" *ngIf="userid !== getSubString(gameRound.winnerAccountName)"></i>
                                            <span class="winner-name-wrapper" [class.looser]="userid !== getSubString(gameRound.winnerAccountName)"
                                                ><span class="winner-name">{{ gameRound.winnerNickName }}</span
                                                ><span class="data-wins">&nbsp;{{ serve.content.messages.Wins }}</span></span
                                            >
                                        </span>
                                        <span class="sb-bb" *ngIf="serve.hhGames === 'cashgames'">
                                            <span>{{ currency[gameRound.anteInfo.smallBlind.monetaryType] }}</span>
                                            <span *ngIf="gameRound.game.gameType.indexOf('SevenCard') > -1"
                                                >{{ gameRound.lowerStake.unit / 100 }}/{{ currency[gameRound.upperStake.monetaryType]
                                                }}{{ gameRound.upperStake.unit / 100 }}&nbsp;</span
                                            >
                                            <span *ngIf="gameRound.game.gameType.indexOf('SevenCard') <= -1"
                                                >{{ gameRound.anteInfo.smallBlind.unit / 100 }}/{{ currency[gameRound.anteInfo.bigBlind.monetaryType]
                                                }}{{ gameRound.anteInfo.bigBlind.unit / 100 }}&nbsp;</span
                                            >
                                            <span *ngIf="gameRound.game.anteCashOrFF"
                                                >{{ serve.content.messages.Ante }} {{ currency[gameRound.anteInfo.anteCost.monetaryType]
                                                }}{{ gameRound.anteInfo.anteCost.unit / 100 }}
                                            </span>
                                            - {{ gameTypes[gameRound.game.gameType + gameRound.game.limitType] }} -
                                            {{ gameRound.roundReference.tableName }}
                                            <span *ngIf="gameRound.isRitHand">{{ serve.content.messages.Rit }}</span>
                                        </span>
                                        <span class="sb-bb" *ngIf="serve.hhGames === 'tournaments'">
                                            <span class="tournament-name" *ngIf="gameRound.tournamentName" [attr.title]="gameRound.tournamentName">
                                                <span>{{ gameRound.tournamentName }}</span>
                                                -
                                            </span>
                                            <span>{{ gameTypes[gameRound.game.gameType + gameRound.game.limitType] }} </span>
                                        </span>
                                        <span class="pot-size" [class.to-bold]="userid === getSubString(gameRound.winnerAccountName)"
                                            >{{ serve.content.messages.PotSize }}
                                            <span>
                                                <span *ngIf="gameRound.roundReference.gameCode === 'R5'">{{ currency[gameRound.currency] }}</span>
                                                <span *ngIf="!gameRound.isRitHand && !isUnCalledBet">{{ gameRound.totalPotSize.unit / 100 }}</span>
                                                <span *ngIf="!gameRound.isRitHand && isUnCalledBet">{{
                                                    (gameRound.totalPotSize.unit - gameRound.unCalledBetAmt) / 100
                                                }}</span>
                                                <span *ngIf="gameRound.isRitHand">{{
                                                    ((gameRound.totalPotSize.unit - gameRound.unCalledBetAmt) / 100).toFixed(2)
                                                }}</span>
                                            </span>

                                            <br />
                                            <span
                                                class="aamsIdDetails"
                                                *ngIf="
                                                    gameRound.extendedProperties &&
                                                    gameRound.extendedProperties.sessionAamsId &&
                                                    gameRound.extendedProperties.userAamsId
                                                ">
                                                <span class="sid"
                                                    >{{ serve.content.messages.Sid }} : {{ gameRound.extendedProperties.sessionAamsId }}</span
                                                >/
                                                <span class="pid">
                                                    {{ serve.content.messages.Pid }} : {{ gameRound.extendedProperties.userAamsId }}</span
                                                >
                                            </span>
                                        </span>
                                        <div *ngIf="gameRound.boosterInfo && gameRound.boosterInfo.istriggered" class="table-booster-desktop">
                                            <div>
                                                <div class="tableBoosterInfo">
                                                    <div class="boosterIcon"></div>
                                                    {{ serve.content.messages.HotTablePayouts }}
                                                </div>
                                            </div>

                                            <div
                                                class="playerWinAmountInfo"
                                                *ngFor="let item of gameRound.boosterInfo.playerWinAmtInfo | keyvalue: originalOrder; index as i">
                                                <div *ngIf="i === 0; else other_content">
                                                    {{ serve.content.messages.Winner }}: {{ item.key }} : {{ currency.USD }}{{ item.value / 100 }}
                                                </div>

                                                <ng-template #other_content>
                                                    {{ serve.content.messages.Participant }}: {{ item.key }} : {{ currency.USD
                                                    }}{{ item.value / 100 }}
                                                </ng-template>
                                            </div>
                                        </div>
                                    </span>
                                </p>
                                <div class="cards-hub">
                                    <ul class="user-cards">
                                        <li
                                            *ngFor="let holeCards of gameRound.playerHoleCards"
                                            [attr.game-type]="gameRound.game.gameType"
                                            class="play-cards"
                                            [attr.card-value]="holeCards"></li>
                                    </ul>
                                    <ul
                                        class="offered-cards"
                                        *ngIf="compareCards(gameRound.playerHoleCards, gameRound.playerFinalHandCards, gameRound.game.gameType)"
                                        [attr.game-type]="gameRound.game.gameType">
                                        <li
                                            *ngFor="let finalCards of gameRound.playerFinalHandCards"
                                            [attr.game-type]="gameRound.game.gameType"
                                            class="play-cards"
                                            [attr.card-value]="finalCards"></li>
                                    </ul>
                                </div>
                                <p
                                    class="footer-note"
                                    [class.hasAamsId]="
                                        gameRound.extendedProperties &&
                                        gameRound.extendedProperties.sessionAamsId &&
                                        gameRound.extendedProperties.userAamsId
                                    ">
                                    <span class="player-date">{{ getFormatedDate(gameRound.gameRoundTime.start) }}</span>
                                    <span class="sb-bb">
                                        <span *ngIf="gameRound.game.gameType.indexOf('SevenCard') > -1">
                                            <span *ngIf="gameRound.roundReference.gameCode === 'R5'">{{
                                                currency[gameRound.lowerStake.monetaryType]
                                            }}</span
                                            >{{ gameRound.lowerStake.unit / 100 }}
                                            /
                                            <span *ngIf="gameRound.roundReference.gameCode === 'R5'">{{
                                                currency[gameRound.upperStake.monetaryType]
                                            }}</span
                                            >{{ gameRound.upperStake.unit / 100 }}</span
                                        >
                                        <span *ngIf="gameRound.game.gameType.indexOf('SevenCard') <= -1">
                                            <span *ngIf="gameRound.roundReference.gameCode === 'R5'">{{
                                                currency[gameRound.anteInfo.smallBlind.monetaryType]
                                            }}</span
                                            >{{ gameRound.anteInfo.smallBlind.unit / 100 }}
                                            /
                                            <span *ngIf="gameRound.roundReference.gameCode === 'R5'">{{
                                                currency[gameRound.anteInfo.bigBlind.monetaryType]
                                            }}</span
                                            >{{ gameRound.anteInfo.bigBlind.unit / 100 }}</span
                                        >
                                        &nbsp;
                                        <span *ngIf="gameRound.game.anteCashOrFF"
                                            >{{ serve.content.messages.Ante }} {{ currency[gameRound.anteInfo.anteCost.monetaryType]
                                            }}{{ gameRound.anteInfo.anteCost.unit / 100 }}
                                        </span>
                                        <span *ngIf="gameRound.isRitHand">{{ serve.content.messages.Rit }}</span>
                                    </span>
                                    <span class="winner">
                                        {{ gameTypes[gameRound.game.gameType + gameRound.game.limitType] }}
                                    </span>
                                    <span class="pot-size" [class.to-bold]="userid === getSubString(gameRound.winnerAccountName)"
                                        >{{ serve.content.messages.Pot }}
                                        <span>
                                            <span *ngIf="gameRound.roundReference.gameCode === 'R5'">{{ currency[gameRound.currency] }}</span>
                                            <span *ngIf="gameRound.isRitHand">{{
                                                (gameRound.totalPotSize.unit - gameRound.unCalledBetAmt) / 100
                                            }}</span>
                                            <span *ngIf="!gameRound.isRitHand && !isUnCalledBet">{{ gameRound.totalPotSize.unit / 100 }}</span>
                                            <span *ngIf="!gameRound.isRitHand && isUnCalledBet">{{
                                                (gameRound.totalPotSize.unit - gameRound.unCalledBetAmt) / 100
                                            }}</span>
                                        </span>
                                    </span>
                                    <br />
                                    <span
                                        class="aamsIdDetails"
                                        *ngIf="
                                            gameRound.extendedProperties &&
                                            gameRound.extendedProperties.sessionAamsId &&
                                            gameRound.extendedProperties.userAamsId
                                        ">
                                        <span class="sid">{{ serve.content.messages.Sid }} : {{ gameRound.extendedProperties.sessionAamsId }} </span>/
                                        <span class="pid"> {{ serve.content.messages.Pid }} : {{ gameRound.extendedProperties.userAamsId }}</span>
                                    </span>
                                </p>
                                <div *ngIf="gameRound.boosterInfo && gameRound.boosterInfo.istriggered" class="table-booster-mobile">
                                    <div>
                                        <div class="tableBoosterInfo">
                                            <div class="boosterIcon"></div>
                                            {{ serve.content.messages.HotTablePayouts }}
                                        </div>
                                    </div>

                                    <div
                                        class="playerWinAmountInfo"
                                        *ngFor="let item of gameRound.boosterInfo.playerWinAmtInfo | keyvalue: originalOrder; index as i">
                                        <div *ngIf="i === 0; else other_content">
                                            {{ serve.content.messages.Winner }}: {{ item.key }} : {{ currency.USD }}{{ item.value / 100 }}
                                        </div>

                                        <ng-template #other_content>
                                            {{ serve.content.messages.Participant }}: {{ item.key }} : {{ currency.USD }}{{ item.value / 100 }}
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <button class="text-view-btn hand-btn" *ngIf="serve.disableTextView">
                                <i class="icon-text-view hand-icons"></i> {{ serve.content.messages.TextView }}
                            </button>
                            <button
                                class="replay-btn hand-btn"
                                [class.aamsId]="
                                    gameRound.extendedProperties &&
                                    gameRound.extendedProperties.sessionAamsId &&
                                    gameRound.extendedProperties.userAamsId
                                ">
                                <span class="hand-icons"></span>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #singleHand>
        <ng-container *ngIf="serve.singleHand && serve.singleHandStatus; else noHands">
            <div
                class="hands-details singleHand"
                *ngIf="serve.singleGameRound"
                [attr.roundid]="serve.singleGameRound.roundReference.roundNumber"
                [class.show-details]="activeRoundId === convertToString(serve.singleGameRound.roundReference.roundNumber)"
                (click)="showHandDetails(0, $event)"
                (touchstart)="touchStart($event)"
                (touchend)="touchEnd($event, 0, 'singleHand')"
                #handDetails>
                <div class="hand-sub-block">
                    <p class="time-block">
                        <span class="player-time"
                            >{{ getTime(serve.singleGameRound.gameRoundtime.start) }}<span class="time-zone">(EST)</span>
                            <span class="player-date">{{ getFormatedDate(serve.singleGameRound.gameRoundtime.start) }}</span>
                        </span>
                        <i class="thumbs icon-thumb-up" *ngIf="userid === getSubString(textView.winnerUserName)"></i>
                        <i class="thumbs icon-thumb-down" *ngIf="userid !== getSubString(textView.winnerUserName)"></i>
                        <span class="game-col">
                            <span class="gameType">
                                <i class="thumbs icon-thumb-up" *ngIf="userid === getSubString(textView.winnerUserName)"></i>
                                <i class="thumbs icon-thumb-down" *ngIf="userid !== getSubString(textView.winnerUserName)"></i>
                                <span class="winner-name-wrapper" [class.looser]="userid !== getSubString(textView.winnerUserName)"
                                    ><span class="winner-name">{{ textView.winnerNickName }}</span
                                    ><span class="data-wins">&nbsp;{{ serve.content.messages.Wins }}</span></span
                                >
                            </span>
                            <span class="sb-bb" *ngIf="!serve.singleGameRound.tourneyInfo.tournamentId"
                                ><span>{{ currency[serve.singleGameRound.anteInfo.smallBlind.monetaryType] }}</span
                                ><span *ngIf="serve.singleGameRound.game.gameType.indexOf('SevenCard') > -1"
                                    >{{ serve.singleGameRound.anteInfo.smallBet.unit / 100 }}/{{
                                        serve.singleGameRound.anteInfo.bigBet.unit / 100
                                    }}</span
                                ><span *ngIf="serve.singleGameRound.game.gameType.indexOf('SevenCard') <= -1"
                                    >{{ serve.singleGameRound.anteInfo.smallBlind.unit / 100 }}/{{
                                        currency[serve.singleGameRound.anteInfo.bigBlind.monetaryType]
                                    }}{{ serve.singleGameRound.anteInfo.bigBlind.unit / 100 }}&nbsp;</span
                                ><span *ngIf="serve.singleGameRound.game.anteCashOrFF"
                                    >{{ serve.content.messages.Ante }} {{ currency[serve.singleGameRound.anteInfo.anteCost.monetaryType]
                                    }}{{ serve.singleGameRound.anteInfo.anteCost.unit / 100 }}</span
                                ><span *ngIf="serve.singleGameRound.ritGame">{{ serve.content.messages.Rit }}</span
                                >&nbsp; - {{ gameTypes[serve.singleGameRound.game.gameType + serve.singleGameRound.game.limitType] }} -
                                {{ serve.singleGameRound.tableName }}&nbsp;</span
                            >

                            <span class="sb-bb" *ngIf="serve.singleGameRound.tourneyInfo.tournamentId">
                                <span
                                    class="tournament-name"
                                    *ngIf="serve.singleGameRound.tourneyInfo.tournamentName"
                                    [attr.title]="serve.singleGameRound.tourneyInfo.tournamentName">
                                    <span>{{ serve.singleGameRound.tourneyInfo.tournamentName }}</span>
                                    -
                                </span>
                                <span>{{ gameTypes[serve.singleGameRound.game.gameType + serve.singleGameRound.game.limitType] }} </span>
                            </span>
                            <span class="pot-size" [class.to-bold]="userid === getSubString(textView.winnerUserName)"
                                >{{ serve.content.messages.PotSize }}
                                <span>
                                    <span *ngIf="!serve.singleGameRound.hasOwnProperty('tournament')">{{
                                        currency[serve.singleGameRound.rake.monetaryType]
                                    }}</span>
                                    <span *ngIf="serve.singleGameRound.unCalledFlag === 1 && !serve.singleGameRound.ritGame">{{
                                        (serve.singleGameRound.totalPotSize - serve.singleGameRound.unBetPotSize) / 100
                                    }}</span>
                                    <span *ngIf="serve.singleGameRound.unCalledFlag === 0 && !serve.singleGameRound.ritGame && !isUnCalledBet">{{
                                        serve.singleGameRound.totalPotSize / 100
                                    }}</span>
                                    <span *ngIf="serve.singleGameRound.unCalledFlag === 0 && !serve.singleGameRound.ritGame && isUnCalledBet">{{
                                        (serve.singleGameRound.totalPotSize - serve.singleGameRound.unBetPotSize) / 100
                                    }}</span>
                                    <span *ngIf="serve.singleGameRound.ritGame">{{
                                        (serve.singleGameRound.totalPotSize - serve.singleGameRound.unBetPotSize) / 100
                                    }}</span>
                                </span>

                                <br />
                                <span
                                    class="aamsIdDetails"
                                    *ngIf="
                                        serve.singleGameRound.extendedProperties &&
                                        serve.singleGameRound.extendedProperties.sessionAamsId &&
                                        serve.singleGameRound.extendedProperties.userAamsId
                                    ">
                                    <span class="sid"
                                        >{{ serve.content.messages.Sid }} : {{ serve.singleGameRound.extendedProperties.sessionAamsId }}</span
                                    >/
                                    <span class="pid">
                                        {{ serve.content.messages.Pid }} : {{ serve.singleGameRound.extendedProperties.userAamsId }}</span
                                    >
                                </span>
                            </span>
                            <br />
                        </span>
                    </p>
                    <div class="cards-hub">
                        <span *ngFor="let player of serve.singleGameRound.roundPlayers">
                            <ul class="user-cards" *ngIf="player.requestingPlayer">
                                <li
                                    *ngFor="let holeCard of player.playerHoleCards"
                                    [attr.game-type]="serve.singleGameRound.game.gameType"
                                    class="play-cards"
                                    [attr.card-value]="holeCard"></li>
                            </ul>
                            <ul
                                class="offered-cards"
                                *ngIf="
                                    player.requestingPlayer &&
                                    compareCards(player.playerHoleCards, player.hiHandCards, serve.singleGameRound.game.gameType)
                                "
                                [attr.game-type]="serve.singleGameRound.game.gameType">
                                <li
                                    *ngFor="let finalCard of player.hiHandCards"
                                    [attr.game-type]="serve.singleGameRound.game.gameType"
                                    class="play-cards"
                                    [attr.card-value]="finalCard"></li>
                            </ul>
                        </span>
                    </div>
                    <p
                        class="footer-note"
                        [class.hasAamsId]="
                            serve.singleGameRound.extendedProperties &&
                            serve.singleGameRound.extendedProperties.sessionAamsId &&
                            serve.singleGameRound.extendedProperties.userAamsId
                        ">
                        <span class="player-date">{{ getFormatedDate(serve.singleGameRound.gameRoundtime.start) }}</span>
                        <span class="sb-bb"
                            ><span *ngIf="serve.singleGameRound.game.gameType.indexOf('SevenCard') > -1"
                                ><span *ngIf="serve.singleGameRound.roundReference.gameCode === 'R5'">{{
                                    currency[serve.singleGameRound.anteInfo.smallBet.monetaryType]
                                }}</span
                                >{{ serve.singleGameRound.anteInfo.smallBet.unit / 100 }} /<span
                                    *ngIf="serve.singleGameRound.roundReference.gameCode === 'R5'">
                                    {{ currency[serve.singleGameRound.anteInfo.bigBet.monetaryType] }}</span
                                >{{ serve.singleGameRound.anteInfo.bigBet.unit / 100 }}</span
                            ><span *ngIf="serve.singleGameRound.game.gameType.indexOf('SevenCard') <= -1"
                                ><span *ngIf="serve.singleGameRound.roundReference.gameCode === 'R5'">{{
                                    currency[serve.singleGameRound.anteInfo.smallBlind.monetaryType]
                                }}</span
                                >{{ serve.singleGameRound.anteInfo.smallBlind.unit / 100 }} /
                                <span *ngIf="serve.singleGameRound.roundReference.gameCode === 'R5'">{{
                                    currency[serve.singleGameRound.anteInfo.bigBlind.monetaryType]
                                }}</span
                                >{{ serve.singleGameRound.anteInfo.bigBlind.unit / 100 }}&nbsp;</span
                            ><span *ngIf="serve.singleGameRound.game.anteCashOrFF"
                                >{{ serve.content.messages.Ante }} {{ currency[serve.singleGameRound.anteInfo.anteCost.monetaryType]
                                }}{{ serve.singleGameRound.anteInfo.anteCost.unit / 100 }}</span
                            ><span *ngIf="serve.singleGameRound.ritGame">{{ serve.content.messages.Rit }}</span></span
                        >&nbsp;
                        <span class="winner">{{ gameTypes[serve.singleGameRound.game.gameType + serve.singleGameRound.game.limitType] }}&nbsp; </span
                        >&nbsp;
                        <span class="pot-size" [class.to-bold]="userid === getSubString(textView.winnerUserName)"
                            >{{ serve.content.messages.Pot }}
                            <span>
                                <span *ngIf="!serve.singleGameRound.hasOwnProperty('tournament')">
                                    <span *ngIf="serve.singleGameRound.roundReference.gameCode === 'R5'">{{
                                        currency[serve.singleGameRound.rake.monetaryType]
                                    }}</span>
                                </span>
                                <span *ngIf="serve.singleGameRound.unCalledFlag === 1 && !serve.singleGameRound.ritGame">{{
                                    (serve.singleGameRound.totalPotSize - serve.singleGameRound.unBetPotSize) / 100
                                }}</span>
                                <span *ngIf="serve.singleGameRound.unCalledFlag === 0 && !serve.singleGameRound.ritGame && !isUnCalledBet">{{
                                    serve.singleGameRound.totalPotSize / 100
                                }}</span>
                                <span *ngIf="serve.singleGameRound.unCalledFlag === 0 && !serve.singleGameRound.ritGame && isUnCalledBet">{{
                                    (serve.singleGameRound.totalPotSize - serve.singleGameRound.unBetPotSize) / 100
                                }}</span>
                                <span *ngIf="serve.singleGameRound.ritGame">{{
                                    (serve.singleGameRound.totalPotSize - serve.singleGameRound.unBetPotSize) / 100
                                }}</span>
                            </span>
                        </span>
                        <br />
                        <span
                            class="aamsIdDetails"
                            *ngIf="
                                serve.singleGameRound.extendedProperties &&
                                serve.singleGameRound.extendedProperties.sessionAamsId &&
                                serve.singleGameRound.extendedProperties.userAamsId
                            ">
                            <span class="sid">{{ serve.content.messages.Sid }} : {{ serve.singleGameRound.extendedProperties.sessionAamsId }}</span
                            >/
                            <span class="pid"> {{ serve.content.messages.Pid }} : {{ serve.singleGameRound.extendedProperties.userAamsId }}</span>
                        </span>
                    </p>
                </div>
                <button class="text-view-btn hand-btn" *ngIf="serve.disableTextView">
                    <i class="icon-text-view hand-icons"></i> {{ serve.content.messages.TextView }}
                </button>

                <button
                    class="replay-btn hand-btn"
                    [class.aamsId]="
                        serve.singleGameRound.extendedProperties &&
                        serve.singleGameRound.extendedProperties.sessionAamsId &&
                        serve.singleGameRound.extendedProperties.userAamsId
                    ">
                    <span class="hand-icons"></span>
                </button>
            </div>
        </ng-container>
    </ng-template>
    <ng-template #noHands>
        <div class="no-hands" *ngIf="!serve.singleHandStatus && !serve.singleHand">{{ serve.content.messages.NoHands }}</div>
    </ng-template>
</div>
<pk-text-view (roundIdError)="roundIdErrorMethod($event)"></pk-text-view>
<div class="gametable-bg"></div>
<pkr-game-table [class.V3]="isReplayerThree" [class.V1]="!isReplayerThree"></pkr-game-table>
