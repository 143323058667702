import { Message } from '../../message';

export class RequestPeerConnectivityStatus extends Message {
    private MESSAGE_NAME: string = 'RequestPeerConnectivityStatus';
    private peersConnected: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, peersConnected: any[] | null = null) {
        super(reqServerPeerId);
        this.peersConnected = peersConnected;
    }

    getPeersConnected(): any[] | null {
        return this.peersConnected;
    }

    setPeersConnected(peersConnected: any[] | null) {
        this.peersConnected = peersConnected;
    }
}
