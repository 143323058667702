import { Compiler, Inject, Injectable, Injector, NgModuleFactory, Type, ViewContainerRef } from '@angular/core';

import { POKER_LAZY_MODULES_MAP, PokerLazyModules } from './poker-core-lazy-routes';

export type ModuleWithRoot = Type<any> & { rootComponent: Type<any> };

@Injectable({
    providedIn: 'root',
})
export class PokerCoreLazyRouteService {
    constructor(
        private injector: Injector,
        private compiler: Compiler,
        @Inject(POKER_LAZY_MODULES_MAP) private modulesMap: PokerLazyModules,
    ) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async loadAndRenderLazyComponents(data: any, container: ViewContainerRef) {
        //const { type, data: componentData } = data;
        const { type } = data;
        const moduleMap = this.modulesMap[type];
        if (moduleMap) {
            const moduleOrFactory = await moduleMap.loadChildren();
            let moduleFactory;
            if (moduleOrFactory instanceof NgModuleFactory) {
                moduleFactory = moduleOrFactory; // AOT
            } else {
                moduleFactory = await this.compiler.compileModuleAsync(moduleOrFactory); //JIT
            }
            const moduleRef = moduleFactory.create(this.injector);
            const rootComponent = (moduleFactory.moduleType as ModuleWithRoot).rootComponent;
            const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);
            return factory;
            // const componentRef = container.createComponent(factory, index, this.injector);
            // return componentRef;
        }
    }
}
