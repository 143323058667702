import { Message } from '../../message';

export class Ping extends Message {
    private MESSAGE_NAME: string = 'Ping';
    private clientTime: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clientTime: number = 0) {
        super(reqServerPeerId);
        this.clientTime = clientTime;
    }

    getClientTime(): number {
        return this.clientTime;
    }

    setClientTime(clientTime: number) {
        this.clientTime = clientTime;
    }
}
