import { Routes } from '@angular/router';

import { PokerMillionsOnlineKnockoutEditionSynopsisResolver } from './poker-millions-online-knockout-edition-synopsis.resolver';
import { PokerMillionsOnlineKnockoutEditionResolver } from './poker-millions-online-knockout-edition.resolver';

export const pokerMillionsOnlineKnockoutEditionRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMillionsOnlineKnockoutEditionResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerMillionsOnlineKnockoutEditionSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerMillionsOnlineKnockoutEditionResolver,
        },
    },
];
