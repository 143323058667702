import { Message } from '../../message';

export class ResponseSNGJPFeedDelta extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGJPFeedDelta';
    private lastSnapShotTimeInNanos: number = 0;
    private domain: number = 0;
    private deltaEvents: any[] | null = null;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        lastSnapShotTimeInNanos: number = 0,
        domain: number = 0,
        deltaEvents: any[] | null = null,
        requestType: number = 0,
    ) {
        super(reqServerPeerId);
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
        this.domain = domain;
        this.deltaEvents = deltaEvents;
        this.requestType = requestType;
    }

    getLastSnapShotTimeInNanos(): number {
        return this.lastSnapShotTimeInNanos;
    }

    setLastSnapShotTimeInNanos(lastSnapShotTimeInNanos: number) {
        this.lastSnapShotTimeInNanos = lastSnapShotTimeInNanos;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
