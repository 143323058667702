import { Message } from '../../message';

export class PreliminaryAccountCurrency extends Message {
    private MESSAGE_NAME: string = 'PreliminaryAccountCurrency';
    private currencyCode: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currencyCode: string | null = null) {
        super(reqServerPeerId);
        this.currencyCode = currencyCode;
    }

    getCurrencyCode(): string | null {
        return this.currencyCode;
    }

    setCurrencyCode(currencyCode: string | null) {
        this.currencyCode = currencyCode;
    }
}
