import { Message } from '../../message';

export class LSTableInfoDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSTableInfoDeltaUpdate';
    private snapShotInNanos: number = 0;
    private tableInfos: any[] | null = null;
    private deletedTableInfos: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, snapShotInNanos: number = 0, tableInfos: any[] | null = null, deletedTableInfos: any[] | null = null) {
        super(reqServerPeerId);
        this.snapShotInNanos = snapShotInNanos;
        this.tableInfos = tableInfos;
        this.deletedTableInfos = deletedTableInfos;
    }

    getSnapShotInNanos(): number {
        return this.snapShotInNanos;
    }

    setSnapShotInNanos(snapShotInNanos: number) {
        this.snapShotInNanos = snapShotInNanos;
    }
    getTableInfos(): any[] | null {
        return this.tableInfos;
    }

    setTableInfos(tableInfos: any[] | null) {
        this.tableInfos = tableInfos;
    }
    getDeletedTableInfos(): any[] | null {
        return this.deletedTableInfos;
    }

    setDeletedTableInfos(deletedTableInfos: any[] | null) {
        this.deletedTableInfos = deletedTableInfos;
    }
}
