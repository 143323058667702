import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { PlainLinkComponent } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { TournamentCalendarTimerComponent } from '@pokercore/module/tournament-calendar';

import { PokerCoreLazyPlaceholderComponent } from '../poker-core-lazy-placeholder/poker-core-lazy-placeholder.component';

@Component({
    selector: 'poker-tournament-wrapper',
    templateUrl: 'poker-upcoming-tournaments.component.html',
    standalone: true,
    imports: [CommonModule, PlainLinkComponent, TournamentCalendarTimerComponent, PokerCoreLazyPlaceholderComponent],
})
export class PokerUpcomingTornamentsComponent extends PCComponent {
    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    // ngOnInit(){
    //     this.googleCalendarHost = this.tournamentsCalendarClientConfig.googleCalendarHost;
    //     this.yahooCalendarHost = this.tournamentsCalendarClientConfig.yahooCalendarHost;
    //     this.msnCalendarHost = this.tournamentsCalendarClientConfig.msnCalendarHost;
    //     this.tournamentsItems = this.item.allScheduledTournaments.pagedTournamentItemsList ? this.item.allScheduledTournaments.pagedTournamentItemsList : this.item.allScheduledTournaments.tournaments;
    //     this.filteredTournamentList = this.tournamentsItems;
    //     this.filteredTournamentList.forEach(t => {
    //         t.isClose = false;
    //         t.isHidden = true;
    //     });

    //     this.widgetConfig = this.item.allScheduledTournaments.widgetConfig;
    //     this.totalTournamentsCount = (this.item.cta && this.item.cta.includes('{{showTotal}}'))?'('+this.item.totalTournamentsCount+')':'';
    //     this.item.cta = (this.item.cta && this.item.cta.includes('{{showTotal}}'))?this.item.cta.replace('{{showTotal}}',''):this.item.cta;
    //     this.contentIivtDataSource = this.item.allScheduledTournaments.tournaments != undefined ? true : false;
    //     this.refreshFeed();
    //     setInterval(() => {
    //         this.refreshFeed();
    //     }, 1000);
    // }

    // currenttime: any;
    // minutes: number;
    // hours: number;
    // seconds: number;
    // endTime: any;
    // millisecondsleft: number;
    // secondsleft: any;
    // timeLeft: string;

    // refreshFeed() {
    //     this.zone.run(() => {
    //         //run the code that should update the view
    //         let hiddenItemCount = 0;
    //         this.activetournamentsItems = [];
    //         for(let t = 0; t< this.filteredTournamentList.length ; t++)
    //         {
    //             this.filteredTournamentList[t].isHidden = true;
    //             this.currenttime = new Date();
    //             this.endTime = new Date(this.filteredTournamentList[t].startDate);
    //             this.millisecondsleft = this.endTime - this.currenttime;
    //             if (this.millisecondsleft > 0) {
    //                 this.filteredTournamentList[t].isHidden = false;
    //                 hiddenItemCount++;
    //                 this.activetournamentsItems.push(this.filteredTournamentList[t]);
    //             }
    //             if(hiddenItemCount == this.item.itemCount)
    //             {
    //                 return;
    //             }
    //         }

    //         if(this.activetournamentsItems.length < this.item.itemCount)
    //         {
    //             // then fill the remaining of the array from the bottom of the actual list
    //             // to make it always eqaul to show the required itemCount
    //             let missingCountItems = this.item.itemCount - this.activetournamentsItems.length;
    //             var ss = this.filteredTournamentList.slice().reverse();
    //             var requiredItems = ss.slice(this.activetournamentsItems.length , missingCountItems);
    //             requiredItems.forEach(element => {
    //                 element.isHidden = false;

    //                 this.activetournamentsItems.push(element);
    //             });
    //         }

    //     });
    // }

    // navigateToTournament(id: number, navigate: boolean = false) {
    //     if (navigate) {
    //         this.navigation.goTo('/tournaments/calendar/'+id);
    //     }
    // }

    // viewAllTournaments() {
    //         this.navigation.goTo('/tournaments/calendar');
    // }

    // showMenu(event: any, id: string, i: number) {
    //     // if other addtocalendar is pressed before the previous one is closed
    //     if (this.previousValue || this.previousValue == 0) {
    //         document.getElementById(id + this.previousValue).style.display = 'none';
    //         //document.getElementById('add-to-cal' + this.previousValue).innerHTML = this.parameters.add_to_calendar;
    //     }
    //     this.previousValue = i;
    //     document.getElementById(id + i).style.display = 'block';
    //     //document.getElementById('add-to-cal' + i).innerHTML = this.parameters.close;
    // }
    // closeMenu(event: any, id: string, i: number) {
    //     document.getElementById(id + i).style.display = 'none';
    //     //document.getElementById('add-to-cal' + i).innerHTML = this.parameters.add_to_calendar;
    // }

    // action(event: any, id: string, i: number, tournament: any) {

    //     this.filteredTournamentList.forEach(t => {
    //         t.isClose = false;
    //         if (t.id == tournament.id) {
    //             t.isClose = true;
    //         }
    //     });

    //     //showmenu func is called if it is AddToCalendar or CloseMenu if it is Close
    //     // if (document.getElementById('add-to-cal' + i).innerHTML.includes(this.parameters.add_to_calendar)) {
    //     //     this.showMenu(event, id, i);
    //     // }
    //     // else {
    //     //     tournament.isClose = false;
    //     //     this.closeMenu(event, id, i);
    //     // }

    //     this.tracking.triggerEvent('Event.Clicks', {
    //         'component.CategoryEvent': 'Tournaments',
    //         'component.LabelEvent': 'Tournament Page',
    //         'component.ActionEvent': 'Add to Calendar CTA - Clicked',
    //         'component.PositionEvent': 'not applicable',
    //         'component.LocationEvent': 'not applicable',
    //         'component.EventDetails': 'User clicks on add to calendar to show options',
    //         'component.URLClicked': 'not applicable'
    //     });
    // }

    // openClient(tournament: any){
    //     location.href = this.widgetConfig.ctaLink.replace('{{id}}', tournament.id);
    // }

    // calenderClicked(event: string){
    //     this.tracking.triggerEvent('Event.Clicks', {
    //         'component.CategoryEvent': 'Tournaments',
    //         'component.LabelEvent': 'Tournament Page',
    //         'component.ActionEvent': 'Calendar App - Choose',
    //         'component.PositionEvent': 'not applicable',
    //         'component.LocationEvent': 'not applicable',
    //         'component.EventDetails': event+'-Choosen',
    //         'component.URLClicked': 'not applicable'
    //     });
    // }
}
