import { Message } from '../../message';

export class DomainMapping extends Message {
    private MESSAGE_NAME: string = 'DomainMapping';
    private domainMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, domainMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.domainMap = domainMap;
    }

    getDomainMap(): Map<any, any> | null {
        return this.domainMap;
    }

    setDomainMap(domainMap: Map<any, any> | null) {
        this.domainMap = domainMap;
    }
}
