import { Message } from '../../message';

export class GenInfo extends Message {
    private MESSAGE_NAME: string = 'GenInfo';
    private typeOfInfo: number = 0;
    private info: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, typeOfInfo: number = 0, info: any[] | null = null) {
        super(reqServerPeerId);
        this.typeOfInfo = typeOfInfo;
        this.info = info;
    }

    getTypeOfInfo(): number {
        return this.typeOfInfo;
    }

    setTypeOfInfo(typeOfInfo: number) {
        this.typeOfInfo = typeOfInfo;
    }
    getInfo(): any[] | null {
        return this.info;
    }

    setInfo(info: any[] | null) {
        this.info = info;
    }
}
