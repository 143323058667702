import { Routes } from '@angular/router';

import { PokerTournamentsResolver } from './poker-tournaments.resolver';

export const pokerTournamentsHomeRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTournamentsResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerTournamentsResolver,
        },
    },
];
