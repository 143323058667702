<div class="filters-fields mt-lg-3" [ngClass]="{ showMobileFilters: mobileFiltersOn }" *ngIf="filterStatus && scheduleFeedItems?.length">
    <div class="schedule-filters-heading-mobile text-uppercase d-block d-lg-none">
        {{ configStrings.filtersMobileTitle }}
        <div class="theme-ex filters-close" (click)="closeFilters()"></div>
    </div>
    <div class="schedule-filters-in">
        <div class="row align-items-end">
            <div class="col-md">
                <div class="row mb-4 mb-md-0">
                    <div class="col-md-4" *ngIf="showBuyin && buyInCategory?.length > 0">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.buyinCategory || 'Buyin Category' }}</label>
                            <mat-form-field floatLabel="never" class="d-block">
                                <mat-label>{{ configStrings.select || 'Select' }}</mat-label>
                                <mat-select multiple [formControl]="buyInSelect">
                                    <mat-option *ngFor="let buyInValue of buyInCategory" [value]="buyInValue">{{ buyInValue.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="gameTypes?.length > 0">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterByGameTypes || 'Game Types' }}</label>
                            <mat-form-field floatLabel="never" class="d-block">
                                <mat-label>{{ configStrings.select || 'Select' }}</mat-label>
                                <mat-select multiple [formControl]="gameTypesSelect">
                                    <mat-option *ngFor="let type of gameTypes" [value]="type">{{ type }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="regStatus?.length > 0">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.registrationStatus || 'Registration Status' }}</label>
                            <mat-form-field floatLabel="never" class="d-block">
                                <mat-label>{{ configStrings.select || 'Select' }}</mat-label>
                                <mat-select multiple [formControl]="registrationSelect">
                                    <mat-option *ngFor="let registrationValue of regStatus" [value]="registrationValue.value">{{
                                        registrationValue.displayText
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="format?.length > 0">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterByGameFormat || 'Format' }}</label>
                            <mat-form-field floatLabel="never" class="d-block">
                                <mat-label>{{ configStrings.select || 'Select' }}</mat-label>
                                <mat-select multiple [formControl]="formatSelect">
                                    <mat-option *ngFor="let format of format" [value]="format">{{ format }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="speedCategory?.length > 0">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterBySpeedCategory || 'Speed Category' }}</label>
                            <mat-form-field floatLabel="never" class="d-block">
                                <mat-label>{{ configStrings.select || 'Select' }}</mat-label>
                                <mat-select multiple [formControl]="speedCategorySelect">
                                    <mat-option *ngFor="let speedCate of speedCategory" [value]="speedCate">{{ speedCate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="currencyList?.length > 1">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterCurrency || 'Currency' }}</label>
                            <mat-form-field floatLabel="never" class="d-block">
                                <mat-label>{{ configStrings.select || 'Select' }}</mat-label>
                                <mat-select multiple [formControl]="currencySelect">
                                    <mat-option *ngFor="let symbol of currencyList" [value]="symbol">{{ symbol }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterByDate || 'Filter by Date' }}</label>
                            <ngx-slider
                                (userChange)="dateSliderChange()"
                                [(value)]="minDate"
                                [(highValue)]="maxDate"
                                [options]="byDateOptions"></ngx-slider>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="showBuyin">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterByBuyin || 'Filter by Buyin' }}</label>
                            <ngx-slider
                                (userChange)="buyinSliderChange()"
                                [(value)]="buyInMinO"
                                [(highValue)]="buyInMaxO"
                                [manualRefresh]="manualRefresh"
                                [options]="buyInSliderOptionsTick"></ngx-slider>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="filter-filed mb-3 mr-md-2">
                            <label class="filter-label">{{ configStrings.filterByGuarantee || 'Filter by Guarantee' }}</label>
                            <ngx-slider
                                (userChange)="guaranteeSliderChange()"
                                [(value)]="minGuarantee"
                                [(highValue)]="maxGuarantee"
                                [manualRefresh]="manualRefresh"
                                [options]="guaranteeOptions"></ngx-slider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-md-right text-center col-md-auto pb-md-5">
                <div class="schedule-filters-foot">
                    <button class="btn btn-secondary btn-sm ml-3" [disabled]="disableReset" (click)="resetFilters()">
                        {{ configStrings.resetFilters || 'Reset Filters' }}
                    </button>
                    <button class="btn btn-primary btn-sm ml-3 d-inline-block d-lg-none" [disabled]="disableReset" (click)="submitFilters()">
                        {{ configStrings.submitFilters || 'Submit Filters' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="filters-btn-mobile text-uppercase d-lg-none text-center" (click)="showMobileFilters()">
    <span class="theme-filters pr-1"></span>{{ configStrings.filtersBtn || 'Filters' }} <span class="filter-on" *ngIf="!disableReset"></span>
</div>
