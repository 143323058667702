import { Message } from '../../message';

export class TournamentBackendUnRegistrationsInfo extends Message {
    private MESSAGE_NAME: string = 'TournamentBackendUnRegistrationsInfo';
    private unregistredMtcts: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, unregistredMtcts: any[] | null = null) {
        super(reqServerPeerId);
        this.unregistredMtcts = unregistredMtcts;
    }

    getUnregistredMtcts(): any[] | null {
        return this.unregistredMtcts;
    }

    setUnregistredMtcts(unregistredMtcts: any[] | null) {
        this.unregistredMtcts = unregistredMtcts;
    }
}
