import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class RitWinHigh extends TableEventInfo {
    private _playerVsRitWinAmout = new Map<number, number>();

    get playerVsRitWinAmout(): Map<number, number> {
        return this._playerVsRitWinAmout;
    }

    set playerVsRitWinAmout(value: Map<number, number>) {
        this._playerVsRitWinAmout = value;
    }

    getEventType(): TableEvents {
        return TableEvents.RIT_WIN_HIGH;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.WIN_HIGH;
    }

    next(gameTable: GameTable): void {
        gameTable.mpot = false;
        const ritWinSeats: any = this._playerVsRitWinAmout.get(this.potId);
        const a: any = Array.from(ritWinSeats.keys());

        a.forEach((seat: any) => {
            const p = gameTable.getPlayer(seat);
            const winAmout = ritWinSeats.get(seat);
            if (winAmout) {
                if (p.toPot.value > 0) {
                    p.toPot.value += winAmout;
                } else {
                    p.toPot.value = winAmout;
                }
                if (!gameTable.onInit) {
                    p.highlightEvent();
                }
                p.chips = new Chip(Number(p.chips.value) + Number(winAmout));
                if (this.potId === 0) {
                    if (gameTable.rakeMap.get(this.potId)) {
                        gameTable.MAIN_POT.totalValue -= winAmout + gameTable.rakeMap.get(this.potId);
                    } else {
                        gameTable.MAIN_POT.totalValue -= winAmout;
                    }
                }
                if (gameTable.isReplayerV3 && gameTable.currentActivePot != 0) {
                    gameTable.currentActivePot -= p.toPot.value;
                }
                if (gameTable.isReplayerV3) {
                    if (gameTable.SIDE_POTS[this.potId]) {
                        gameTable.SIDE_POTS[this.potId].showPot = false;
                    }
                } else {
                    if (gameTable.SIDE_POTS[this.potId - 1]) {
                        gameTable.SIDE_POTS[this.potId - 1].showPot = false;
                    }
                }
                gameTable.enableTableMask = true;
                setTimeout(function () {
                    gameTable.addRitWinAmountToPlayer(p);
                    gameTable.enableTableMask = false;
                }, 100);
            }
            p.playerStatus = this.getPlayerStatus();
            gameTable.players.forEach((player: any) => {
                player.isPlayerAllIn = false;
            });
        });
        if (this.potId > -1) {
            const rakeAmount: any = gameTable.rakeMap.get(this.potId);
            gameTable.addRake(rakeAmount);
        }
        gameTable.getTotalSidePotslength = gameTable.SIDE_POTS.length;
        const sidePotLength = gameTable.SIDE_POTS.length;
        if (gameTable.SIDE_POTS.length === 1 && gameTable.SIDE_POTS[sidePotLength - 1].totalValue <= 0 && gameTable.potMap.size === 1) {
            gameTable.potMap.set(gameTable.potMap.size, 0);
        }
    }

    prev(gameTable: GameTable): void {
        let currentPotValue: any = 0;
        const ritWinSeats: any = this._playerVsRitWinAmout.get(this.potId);
        const a: any = Array.from(ritWinSeats.keys());
        gameTable.changeChip = true;
        a.forEach((seat: any) => {
            const p = gameTable.getPlayer(seat);
            const winAmout = ritWinSeats.get(seat);
            if (winAmout) {
                p.chips = new Chip(Number(p.chips.value) - Number(winAmout));
                p.toPot.value -= winAmout;
                if (p.toPot.value <= 0) {
                    const winChipPos = Properties.propFile[gameTable.gameType][`RITMAINPOTCHIPPOS`].split('#');
                    p.playerChipPos = new Point(winChipPos[0], winChipPos[1]);
                }
                if (this.potId === 0) {
                    const rake: any = gameTable.rakeMap.get(this.potId);
                    gameTable.rakeMap.get(this.potId);
                    gameTable.MAIN_POT.totalValue += winAmout + rake;
                }
                if (gameTable.isReplayerV3 && this.potId === gameTable.SIDE_POTS.length - 1) {
                    currentPotValue += winAmout;
                }
                if (gameTable.isReplayerV3) {
                    if (gameTable.SIDE_POTS[this.potId]) {
                        // gameTable.SIDE_POTS[gameTable.SIDE_POTS.length - 1].totalValue = gameTable.gameRoundDetails[(gameTable.currentBettingRound) - 1].activePotAmount.unit;;
                        gameTable.SIDE_POTS[this.potId].showPot = true;
                    }
                } else {
                    if (gameTable.SIDE_POTS[this.potId - 1]) {
                        gameTable.SIDE_POTS[gameTable.SIDE_POTS.length - 1].totalValue =
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                        gameTable.SIDE_POTS[this.potId - 1].showPot = true;
                    }
                }
            }
            p.playerStatus = this.getPlayerStatus();
        });

        if (gameTable.totalRake > 0 && gameTable.isReplayerV3) {
            currentPotValue += gameTable.totalRake;
        }
        if (currentPotValue === gameTable.SIDE_POTS[this.potId].totalValue && gameTable.isReplayerV3) {
            gameTable.currentActivePot = gameTable.SIDE_POTS[this.potId].totalValue;
            gameTable.SIDE_POTS[this.potId].showPot = false;
            gameTable.MAIN_POT.totalValue = gameTable.currentActivePot;
        }

        gameTable.players.forEach((player: any) => {
            if (player.chips.value <= 0) {
                player.isPlayerAllIn = true;
            }
        });
        gameTable.enableTableMask = false;
        if (gameTable.totalRake > 0) {
            gameTable.totalRake -= gameTable.rakeMap.get(this.potId);
        }
    }
}
