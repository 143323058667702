import { Message } from '../../message';

export class LSPoolInfoDetails extends Message {
    private MESSAGE_NAME: string = 'LSPoolInfoDetails';
    private poolId: number = 0;
    private poolName: any | null = null;
    private noOfSeats: number = 0;
    private gameType: number = 0;
    private tableInfoId: number = 0;
    private smallBlindAmount: number = 0;
    private bigBlindAmount: number = 0;
    private gameCurrency: string | null = null;
    private poolTypeId: number = 0;
    private limitType: number = 0;
    private colorCode: number = 0;
    private category: number = 0;
    private minBuyInAmount: number = 0;
    private maxBuyInAmount: number = 0;
    private subLiquidityType: number = 0;
    private anteCashAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        poolId: number = 0,
        poolName: any | null = null,
        noOfSeats: number = 0,
        gameType: number = 0,
        tableInfoId: number = 0,
        smallBlindAmount: number = 0,
        bigBlindAmount: number = 0,
        gameCurrency: string | null = null,
        poolTypeId: number = 0,
        limitType: number = 0,
        colorCode: number = 0,
        category: number = 0,
        minBuyInAmount: number = 0,
        maxBuyInAmount: number = 0,
        subLiquidityType: number = 0,
        anteCashAmount: number = 0,
    ) {
        super(reqServerPeerId);
        this.poolId = poolId;
        this.poolName = poolName;
        this.noOfSeats = noOfSeats;
        this.gameType = gameType;
        this.tableInfoId = tableInfoId;
        this.smallBlindAmount = smallBlindAmount;
        this.bigBlindAmount = bigBlindAmount;
        this.gameCurrency = gameCurrency;
        this.poolTypeId = poolTypeId;
        this.limitType = limitType;
        this.colorCode = colorCode;
        this.category = category;
        this.minBuyInAmount = minBuyInAmount;
        this.maxBuyInAmount = maxBuyInAmount;
        this.subLiquidityType = subLiquidityType;
        this.anteCashAmount = anteCashAmount;
    }

    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
    getPoolName(): any | null {
        return this.poolName;
    }

    setPoolName(poolName: any | null) {
        this.poolName = poolName;
    }
    getNoOfSeats(): number {
        return this.noOfSeats;
    }

    setNoOfSeats(noOfSeats: number) {
        this.noOfSeats = noOfSeats;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getSmallBlindAmount(): number {
        return this.smallBlindAmount;
    }

    setSmallBlindAmount(smallBlindAmount: number) {
        this.smallBlindAmount = smallBlindAmount;
    }
    getBigBlindAmount(): number {
        return this.bigBlindAmount;
    }

    setBigBlindAmount(bigBlindAmount: number) {
        this.bigBlindAmount = bigBlindAmount;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getPoolTypeId(): number {
        return this.poolTypeId;
    }

    setPoolTypeId(poolTypeId: number) {
        this.poolTypeId = poolTypeId;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getColorCode(): number {
        return this.colorCode;
    }

    setColorCode(colorCode: number) {
        this.colorCode = colorCode;
    }
    getCategory(): number {
        return this.category;
    }

    setCategory(category: number) {
        this.category = category;
    }
    getMinBuyInAmount(): number {
        return this.minBuyInAmount;
    }

    setMinBuyInAmount(minBuyInAmount: number) {
        this.minBuyInAmount = minBuyInAmount;
    }
    getMaxBuyInAmount(): number {
        return this.maxBuyInAmount;
    }

    setMaxBuyInAmount(maxBuyInAmount: number) {
        this.maxBuyInAmount = maxBuyInAmount;
    }
    getSubLiquidityType(): number {
        return this.subLiquidityType;
    }

    setSubLiquidityType(subLiquidityType: number) {
        this.subLiquidityType = subLiquidityType;
    }
    getAnteCashAmount(): number {
        return this.anteCashAmount;
    }

    setAnteCashAmount(anteCashAmount: number) {
        this.anteCashAmount = anteCashAmount;
    }
}
