import { Message } from '../../message';

export class ResponseTableInfoDetailsList extends Message {
    private MESSAGE_NAME: string = 'ResponseTableInfoDetailsList';
    private snapShotTimeInNanos: number = 0;
    private fullUpdate: boolean | null = false;
    private tableInfos: any[] | null = null;
    private deletedTableInfos: any[] | null = null;
    private clearAllTabIdSnapShots: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        snapShotTimeInNanos: number = 0,
        fullUpdate: boolean | null = false,
        tableInfos: any[] | null = null,
        deletedTableInfos: any[] | null = null,
        clearAllTabIdSnapShots: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.fullUpdate = fullUpdate;
        this.tableInfos = tableInfos;
        this.deletedTableInfos = deletedTableInfos;
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
    }

    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getFullUpdate(): boolean | null {
        return this.fullUpdate;
    }

    setFullUpdate(fullUpdate: boolean | null) {
        this.fullUpdate = fullUpdate;
    }
    getTableInfos(): any[] | null {
        return this.tableInfos;
    }

    setTableInfos(tableInfos: any[] | null) {
        this.tableInfos = tableInfos;
    }
    getDeletedTableInfos(): any[] | null {
        return this.deletedTableInfos;
    }

    setDeletedTableInfos(deletedTableInfos: any[] | null) {
        this.deletedTableInfos = deletedTableInfos;
    }
    getClearAllTabIdSnapShots(): boolean | null {
        return this.clearAllTabIdSnapShots;
    }

    setClearAllTabIdSnapShots(clearAllTabIdSnapShots: boolean | null) {
        this.clearAllTabIdSnapShots = clearAllTabIdSnapShots;
    }
}
