import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

@Component({
    selector: 'pwc-sub-lobby-header',
    templateUrl: 'sub-lobby-header.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class SubLobbyHeaderComponent {
    @Input() tablesLength;
    @Input() initialGridViewType;
    @Input() cardOrderType;
    @Input() gameTypes;
    @Input() isWidgetShow: boolean;
    @Output() isGridViewType: EventEmitter<any> = new EventEmitter();
    @Output() viewInfoPopup: EventEmitter<any> = new EventEmitter();
    @Output() sortTable: EventEmitter<any> = new EventEmitter();
    @Output() viewFilterPopup: EventEmitter<any> = new EventEmitter();
    @Output() updateGameType: EventEmitter<any> = new EventEmitter();
    private readonly logger: PokerLogger;
    public selectedGameTypes = [];
    constructor(loggerFactory: LoggerFactory) {
        this.logger = loggerFactory.getLogger('subLobbyHaderLobbyLoggingComponent');
    }
    changeView() {
        this.logger.info('clicked on Grid Icon..!');
        this.initialGridViewType = !this.initialGridViewType;
        this.isGridViewType.emit(this.initialGridViewType);
    }
    getKeys(): string[] {
        return Object.keys(this.gameTypes);
    }
    updateSelectedGameType() {
        const data = Object.keys(this.gameTypes).filter((key) => this.gameTypes[key]);
        this.updateGameType.emit(data);
    }
    updateSelection(data) {
        if (data === 'All') {
            // this.gameTypes = Object.keys(this.gameTypes).reduce((obj, key) => (obj[key] = true, obj), {});
            if (this.gameTypes['All'] == false)
                this.gameTypes = Object.keys(this.gameTypes).reduce((obj, key) => {
                    obj[key] = true;
                    return obj;
                }, {});
            else
                this.gameTypes = Object.keys(this.gameTypes).reduce((obj, key) => {
                    obj[key] = false;
                    return obj;
                }, {});
        } else {
            // if (Object.keys(this.gameTypes).length == Object.values(this.gameTypes).filter(value => value === true).length) {
            //     this.gameTypes = Object.keys(this.gameTypes).reduce((acc, key) => (acc[key] = key === data, acc), {});
            // } else {
            this.gameTypes[data] = !this.gameTypes[data];
            this.gameTypes['All'] = false;
            // }
        }
        if (Object.values(this.gameTypes).filter((value) => value === true).length == Object.keys(this.gameTypes).length - 1)
            this.gameTypes['All'] = true;
        this.updateSelectedGameType();
    }
    openInfoPopup() {
        this.logger.info('clicked on info popup icon');
        this.viewInfoPopup.emit();
    }

    sortTables() {
        this.logger.info('Clicked on sort icon');
        this.cardOrderType = this.cardOrderType === 'asc' ? 'desc' : 'asc';
        this.sortTable.emit(this.cardOrderType);
        // this.sortTable.emit();
    }

    openFilterPopup() {
        this.logger.info('Clicked on filter icon');
        this.viewFilterPopup.emit();
    }
}
