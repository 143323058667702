import { Routes } from '@angular/router';

import { PokerPublicPageLoaderComponent } from './poker-public-page-loader.component';

export const pokerMysteryBountyRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: '**',
                component: PokerPublicPageLoaderComponent,
                data: {
                    publicPageRoot: 'MobilePoker-v1.0/mystery-bounty/',
                },
            },
        ],
    },
];
