<div class="ppl-categories">
    <div class="row m-0 mt-5 ppl-cursor-pointer" *ngFor="let category of getFewEventCategory" (click)="gotoDetails(category.eventLink)">
        <div class="col-12 col-sm-4 p-0">
            <img
                class="w-100 rounded"
                *ngIf="category.pplEventConfigContent.eventImage"
                [src]="category.pplEventConfigContent.eventImage.src"
                [alt]="category.pplEventConfigContent.eventImage.alt" />
        </div>
        <div class="col-12 col-sm-8 p-0 pl-sm-4">
            <h2
                class="txt-black mb-2 mb-md-4 mt-0"
                *ngIf="category.pplEventConfigContent.eventTitle"
                [innerHTML]="category.pplEventConfigContent.eventTitle"></h2>
            <p
                class="txt-body-60 m-0 p-0"
                *ngIf="category.pplEventConfigContent.eventOverview"
                [innerHTML]="category.pplEventConfigContent.eventOverview"></p>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-4" *ngIf="showLoadMoreBtn">
        <button type="button" class="btn btn-primary flex-grow-1 flex-sm-grow-0 text-uppercase" (click)="loadMore()">
            {{ item.eventWidgetStaticData.loadMore }}
        </button>
    </div>
</div>
