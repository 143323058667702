@if (item) {
    <div class="ch ch-contact ch-shade p-2 ch__font--sm card-bg align-items-center" (click)="itemClick($event, item, 'ProfilePage_HelpSection')">
        <div class="mr-1">
            @if (!item.image) {
                <span class="ch-contact__icon text-info theme-help-contact mr-2"></span>
            }
            @if (item.image; as image) {
                <vn-image [src]="image.src" [alt]="image.alt" imageClass="mx-auto d-block" />
            }
        </div>
        <div class="d-flex flex-column">
            <span class="ch__font--title text-info font-weight-bold mb-1" [innerHtml]="item.text | trustAsHtml"></span>
            <span [innerHtml]="item.resources.Text | trustAsHtml"></span>
        </div>
    </div>
}
