import { Message } from '../../message';

export class ResponseHUSNGRematchAccepted extends Message {
    private MESSAGE_NAME: string = 'ResponseHUSNGRematchAccepted';
    private targetSNGId: number = 0;
    private rematchMTCTId: number = 0;
    private buyIn: number = 0;
    private domain: number = 0;
    private fxSnapshotId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        targetSNGId: number = 0,
        rematchMTCTId: number = 0,
        buyIn: number = 0,
        domain: number = 0,
        fxSnapshotId: number = 0,
    ) {
        super(reqServerPeerId);
        this.targetSNGId = targetSNGId;
        this.rematchMTCTId = rematchMTCTId;
        this.buyIn = buyIn;
        this.domain = domain;
        this.fxSnapshotId = fxSnapshotId;
    }

    getTargetSNGId(): number {
        return this.targetSNGId;
    }

    setTargetSNGId(targetSNGId: number) {
        this.targetSNGId = targetSNGId;
    }
    getRematchMTCTId(): number {
        return this.rematchMTCTId;
    }

    setRematchMTCTId(rematchMTCTId: number) {
        this.rematchMTCTId = rematchMTCTId;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getFxSnapshotId(): number {
        return this.fxSnapshotId;
    }

    setFxSnapshotId(fxSnapshotId: number) {
        this.fxSnapshotId = fxSnapshotId;
    }
}
