import { Message } from '../../message';

export class SessionLimitNotification extends Message {
    private MESSAGE_NAME: string = 'SessionLimitNotification';
    private sessionStatus: number = 0;
    private userSessionLimit: number = 0;
    private sessionTimeElapsed: number = 0;
    private loggedInSince: number = 0;
    private isTimeLimitUpdated: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sessionStatus: number = 0,
        userSessionLimit: number = 0,
        sessionTimeElapsed: number = 0,
        loggedInSince: number = 0,
        isTimeLimitUpdated: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.sessionStatus = sessionStatus;
        this.userSessionLimit = userSessionLimit;
        this.sessionTimeElapsed = sessionTimeElapsed;
        this.loggedInSince = loggedInSince;
        this.isTimeLimitUpdated = isTimeLimitUpdated;
    }

    getSessionStatus(): number {
        return this.sessionStatus;
    }

    setSessionStatus(sessionStatus: number) {
        this.sessionStatus = sessionStatus;
    }
    getUserSessionLimit(): number {
        return this.userSessionLimit;
    }

    setUserSessionLimit(userSessionLimit: number) {
        this.userSessionLimit = userSessionLimit;
    }
    getSessionTimeElapsed(): number {
        return this.sessionTimeElapsed;
    }

    setSessionTimeElapsed(sessionTimeElapsed: number) {
        this.sessionTimeElapsed = sessionTimeElapsed;
    }
    getLoggedInSince(): number {
        return this.loggedInSince;
    }

    setLoggedInSince(loggedInSince: number) {
        this.loggedInSince = loggedInSince;
    }
    getIsTimeLimitUpdated(): boolean | null {
        return this.isTimeLimitUpdated;
    }

    setIsTimeLimitUpdated(isTimeLimitUpdated: boolean | null) {
        this.isTimeLimitUpdated = isTimeLimitUpdated;
    }
}
