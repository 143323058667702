import { SubscriptionRequest } from '../server/common/connectorMessageFactory/messages/SubscriptionRequest';
import { Message } from '../server/common/message';
import { CSD } from './CSD';
import TSUtil from './common/tsUtil';
import { ConnectionHandlerContext } from './connectionHandlerContext';
import { ConnectionManager } from './connectionManager';

export class SubscriptionManager {
    private manager: ConnectionManager;
    private subscriptionRequired: boolean = true;
    constructor(manager: ConnectionManager) {
        this.manager = manager;
    }

    sendSubscriptionRequest(domainId) {
        if (this.subscriptionRequired) {
            this.subscriptionRequired = false;
            const subscribedDomain = domainId;
            const subscriptionRequest = new SubscriptionRequest();
            subscriptionRequest.setOperationCode(TSUtil.SUBSCRIBE_CODE);
            subscriptionRequest.setRegInProgress(false);
            subscriptionRequest.setLoginInProgress(this.manager.getLoginInProgress());
            subscriptionRequest.setOldSubscribedDomain(subscribedDomain);
            this.manager.sendMessageOnDomain(subscriptionRequest, domainId);
        } else {
            return;
        }
    }

    handleSubscriptionSuccess(response: Message, currentDomain: number): void {
        this.manager.delegate.log(CSD.LOG_INFO, `Subscription success on domain ${currentDomain}`);
        this.manager.subscriptionInProgress = false;

        if (this.manager.isDomainChangeAdviceInProgress) {
            this.manager.isDomainChangeAdviceInProgress = false;
            // const oldConnectionId: number = this.manager.subscribedConnectionid;
            const oldContext: ConnectionHandlerContext | null = this.manager.lookupConnHandContext(this.manager.oldSubscribedDomain);
            if (oldContext) this.unSubscribeWithServer(CSD.UNSUBSCRIBE_WITH_SERVER, this.manager.oldSubscribedDomain, false, false, oldContext);
            this.manager.delegate.log(CSD.LOG_INFO, `Sending unsubscribe request on domain ${this.manager.oldSubscribedDomain}`);
            const currentContext: ConnectionHandlerContext | null = this.manager.lookupConnHandContext(currentDomain);
            if (currentContext) currentContext.addPeer(this.manager.getGameClientPeerId());

            // moveGameClientPrivilegedPackets(oldContext, currentContext);
            if (oldContext) {
                if (oldContext.shutdownIfUnUsed()) {
                    if (this.manager.domainsHandlerContext && this.manager.domainsHandlerContext !== null) {
                        const domainContext = this.manager.domainsHandlerContext.get(this.manager.oldSubscribedDomain);
                        if (domainContext) {
                            if (domainContext.size === 1) {
                                // Since we shut down the oldContext, if that is the only connection in that domain, then we will remove the entry of that domain from domainsHandlerContext.
                                this.manager.domainsHandlerContext.delete(this.manager.oldSubscribedDomain);
                            }
                        }
                    }
                }
            }
        }

        this.manager.oldSubscribedDomain = currentDomain;
        // sendMessagesOnSubscription(currentConnId);
        this.manager.subscriptionDone = true;
    }

    unSubscribeWithServer(
        subscribeOperationCode: number,
        oldDomainId: number,
        regProg: boolean,
        loginProg: boolean,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        oldCtx: ConnectionHandlerContext,
    ) {
        const unSubscribeRequest: SubscriptionRequest = new SubscriptionRequest();
        unSubscribeRequest.setOperationCode(subscribeOperationCode);
        unSubscribeRequest.setOldSubscribedDomain(oldDomainId);
        unSubscribeRequest.setRegInProgress(regProg);
        unSubscribeRequest.setLoginInProgress(loginProg);
        // oldCtx.sendMessage(unSubscribeRequest);
    }
}
