import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { PokerHomePageClientConfig } from '@pokercore/module/core';

@Component({
    selector: 'pk-threetile-container',
    templateUrl: 'poker-threeTile-container.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PokerThreeTileContainerComponent implements OnInit {
    content: any;
    initData: any;
    showConfigureNavigation: boolean = false;
    threeTiles: any;
    // For threeTileContainer
    @Input() threeTileContainer: any;

    constructor(private pokerHomePageClientConfig: PokerHomePageClientConfig) {}

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.threeTiles = this.threeTileContainer;
    }
    //ngOnDestroy() {}
}
