import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';

import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { AthBlockTime, AthHandCount, updateAthHandCount } from '../../store/lobby.store';

@Component({
    selector: 'pwc-ath-popup',
    templateUrl: 'ath-popup.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, TranslatePipe],
    providers: [],
})
export class ATHPopupComponent implements OnInit {
    @Input() athalertpopup: any;
    @Input() athpenalitypopup: any;
    displayTime: string;
    athBlockTime: any = 0;
    penality: boolean = false;
    @Output() close = new EventEmitter<void>();
    athHandCount: any;
    mobileLoginMsg: any;
    mobileLoginMsgLiteral: string;
    constructor(
        private store: Store,
        private translatePipe: TranslatePipe,
        private navigation: NavigationService,
        private sharedService: PokerWebClientSharedService,
        private clientToServer: ClientToServerRequestServices,
    ) {}
    ngOnInit(): void {
        // this.store.select(cashLobbyDataSelect).subscribe((data) => {
        //   if(data.isCashGamesOpened>0)
        //     this.athHandCount=data.isCashGamesOpened;
        //   this.athBlockTime = data.athBlockTime;
        // });
        // this.store.dispatch(UpdateisCashGamesOpened({ isOpened: 0 }));
        this.store.select(AthHandCount).subscribe((data) => {
            this.athHandCount = data;
        });
        this.store.select(AthBlockTime).subscribe((data) => {
            this.athBlockTime = data;
        });
        this.msToTime(this.athBlockTime);
        this.mobileLoginMsgLiteral = this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_MOBILELOGINMSG_LINETWO');
        this.mobileLoginMsg = this.mobileLoginMsgLiteral.replace('%1s', this.athHandCount);
        this.athHandCount = 0;
        localStorage.setItem('isATHAlertShown', 'true');
        this.store.dispatch(updateAthHandCount(AthHandCount));
    }
    openff() {
        this.closePopup();
        this.navigation.goTo('/play/fast-forward');
    }
    msToTime(duration) {
        const hourText = this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_HOUR');
        const hoursText = this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_HOURS');
        const minuteText = this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_MINUTE');
        const minutesText = this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_MINUTES');
        const secondsText = this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_SECONDS');

        duration = duration * 1000 - new Date().getTime();
        if (duration <= 0) {
            return;
        }
        // let milliseconds = (duration % 1000) / 100;
        const seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        const days = Math.floor(duration / (1000 * 60 * 60) / 24);
        if (days > 0) {
            hours = minutes > 29 ? hours + 1 : hours;
            this.displayTime =
                days +
                ' ' +
                (days == 1
                    ? this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_DAY')
                    : this.translatePipe.transform('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_DAYS')) +
                ' ' +
                (hours % 24) +
                ' ' +
                (hours % 24 == 1 ? hourText : hoursText);
        } else if (minutes >= 5) {
            minutes = seconds > 29 ? minutes + 1 : minutes;
            if (minutes == 60) {
                minutes = 0;
                hours += 1;
            }
            let hourToBeDisplayed = '';
            let minutesToBeDisplayed = '';
            if (hours > 0) hourToBeDisplayed = hours + ' ' + (hours == 1 ? hourText : hoursText) + ' ';
            if (minutes > 0) minutesToBeDisplayed = minutes + ' ' + (minutes == 1 ? minuteText : minutesText);
            this.displayTime = hourToBeDisplayed + minutesToBeDisplayed;
        } else if (minutes > 0) {
            let secsToBeDisplayed = '';
            let minutesToBeDisplayed = '';
            if (minutes > 0) minutesToBeDisplayed = minutes + ' ' + (minutes == 1 ? minuteText : minutesText) + ' ';
            if (seconds > 0) secsToBeDisplayed = seconds + ' ' + secondsText;
            this.displayTime = minutesToBeDisplayed + secsToBeDisplayed;
        } else this.displayTime = seconds + ' ' + secondsText;
    }

    closePopup() {
        this.close.emit();
    }

    openlearnmorelink() {
        this.sharedService.sendPortalPage({
            text: this.clientToServer.externalLinks['ath-link']['title'],
            url: this.clientToServer.externalLinks['ath-link']['titleLink']['url'],
        });
        this.navigation.goTo('/play/' + 'portal');
    }
}
