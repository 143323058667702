import { Injectable } from '@angular/core';

import { OnAppInit, PageMatrixService } from '@frontend/vanilla/core';

import * as pcd from './poker-countdown.component';

@Injectable({ providedIn: 'root' })
export class PokercountdownPageMatrixBootstrapService implements OnAppInit {
    constructor(private pageMatrixService: PageMatrixService) {}

    onAppInit() {
        this.pageMatrixService.whenReady.subscribe(() => {
            this.pageMatrixService.registerComponent('TournamentCountdownTemplate', pcd.PokerCountdownComponent);
        });
    }
}
