import { Injectable } from '@angular/core';

import { CommonGameProperties } from '../PWC-games-lib/lib/game-config/CommonGameProperties';
import { BaseGameTableConnector } from '../pgBackendConnectors/baseGameTableConnector';
import { ClassMessageListener, MessageListener } from '../pgBackendConnectors/messageListener';
import { PokerBackendConnector } from '../pgBackendConnectors/pokerBackendConnector';
import { CSD } from '../pgConnectors/sal/CSD';
import { AutoOption } from '../pgConnectors/server/common/pokerMessageFactory/messages/AutoOption';
import { AutoOptionToOptionsMappings } from '../pgConnectors/server/common/pokerMessageFactory/messages/AutoOptionToOptionsMappings';
import { BackToPoolRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/BackToPoolRequest';
import { BuyMoreChips } from '../pgConnectors/server/common/pokerMessageFactory/messages/BuyMoreChips';
import { CanLeaveTable } from '../pgConnectors/server/common/pokerMessageFactory/messages/CanLeaveTable';
import { CanSitOut } from '../pgConnectors/server/common/pokerMessageFactory/messages/CanSitOut';
import { EnableTimeBank } from '../pgConnectors/server/common/pokerMessageFactory/messages/EnableTimeBank';
import { GetPokerDiamondBalance } from '../pgConnectors/server/common/pokerMessageFactory/messages/GetPokerDiamondBalance';
import { JoinPoolRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinPoolRequest';
import { JoinPoolResponse } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinPoolResponse';
import { JoinTable } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinTable';
import { LeavePoolRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/LeavePoolRequest';
import { Option } from '../pgConnectors/server/common/pokerMessageFactory/messages/Option';
import { OptionSetAck } from '../pgConnectors/server/common/pokerMessageFactory/messages/OptionSetAck';
import { PoolAvailabilityRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/PoolAvailabilityRequest';
import { PoolAvailabilityResponse } from '../pgConnectors/server/common/pokerMessageFactory/messages/PoolAvailabilityResponse';
import { PoolRebuyRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/PoolRebuyRequest';
import { PoolRequestUserReconnect } from '../pgConnectors/server/common/pokerMessageFactory/messages/PoolRequestUserReconnect';
import { PoolSitOutNotification } from '../pgConnectors/server/common/pokerMessageFactory/messages/PoolSitOutNotification';
import { PrevPlayingGTs } from '../pgConnectors/server/common/pokerMessageFactory/messages/PrevPlayingGTs';
import { ReqEnableRabbit } from '../pgConnectors/server/common/pokerMessageFactory/messages/ReqEnableRabbit';
import { ReqNextHandOffFoldAndWatch } from '../pgConnectors/server/common/pokerMessageFactory/messages/ReqNextHandOffFoldAndWatch';
import { ReqShowEmoji } from '../pgConnectors/server/common/pokerMessageFactory/messages/ReqShowEmoji';
import { ReqThrowAnimation } from '../pgConnectors/server/common/pokerMessageFactory/messages/ReqThrowAnimation';
import { RequestAutoCheckOff } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoCheckOff';
import { RequestAutoCheckOn } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoCheckOn';
import { RequestAutoOptionsOptionsMappings } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoOptionsOptionsMappings';
import { RequestAutoPostBlindAndFoldOn } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoPostBlindAndFoldOn';
import { RequestAutoPostBlindOff } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoPostBlindOff';
import { RequestAutoPostBlindOn } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoPostBlindOn';
import { RequestAutoRebuyOn } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutoRebuyOn';
import { RequestAutopostBlindAndFoldOff } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutopostBlindAndFoldOff';
import { RequestAutorebuyOff } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestAutorebuyOff';
import { RequestCashoutEquity } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestCashoutEquity';
import { RequestGameStatus } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestGameStatus';
import { RequestHUPairTable } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestHUPairTable';
import { RequestLeaveTable } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestLeaveTable';
import { RequestMuckLosingHandOff } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestMuckLosingHandOff';
import { RequestMuckLosingHandOn } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestMuckLosingHandOn';
import { RequestPoolSitout } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestPoolSitOut';
import { RequestRegainPlayer } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestRegainPlayer';
import { RequestSitoutNextHandOff } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestSitoutNextHandOff';
import { RequestSitoutNextHandOn } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestSitoutNextHandOn';
import { ResponseShowCardsOptionDontShow } from '../pgConnectors/server/common/pokerMessageFactory/messages/ResponseShowCardsOptionDontShow';
import { ResponseShowCardsOptionMuck } from '../pgConnectors/server/common/pokerMessageFactory/messages/ResponseShowCardsOptionMuck';
import { ResponseShowCardsOptionShow } from '../pgConnectors/server/common/pokerMessageFactory/messages/ResponseShowCardsOptionShow';
import { SNGJPUnRegistrationRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/SNGJPUnRegistrationRequest';
import { SelectOption } from '../pgConnectors/server/common/pokerMessageFactory/messages/SelectOption';
import { SelectedAutoOption } from '../pgConnectors/server/common/pokerMessageFactory/messages/SelectedAutoOption';
import { Sit } from '../pgConnectors/server/common/pokerMessageFactory/messages/Sit';
import { FxRateHelperClassService } from './Fxratemanager';
import { EventDispatcherGlobal } from './event-despacher-global';

@Injectable()
@ClassMessageListener
export class GameTableNotificationService extends BaseGameTableConnector {
    eventDispatcherGlobal = new EventDispatcherGlobal();
    private tabeleIdArr: any[] = [];

    PoolAvailabilityRequestPoolId: number[] = [];
    private PoolAvailabilityResponseArray: { [key: number]: PoolAvailabilityResponse } = {};
    private prevPlayingFastPokerPoolsArr: any[] = [];
    private entryToPoolMap = {};
    private poolConnectionObj: any[] = [];
    private pendingPoolAvailabilityReqArr: any[] = [];
    isFFPreviuosPlayingPool: boolean;
    constructor() {
        super(PokerBackendConnector.getInstance());
        this.eventDispatcherGlobal.addEventListener('onPrimaryAssetsLoadComplete', this.onPrimaryAssetsLoadComplete.bind(this));
    }

    @MessageListener('HandShakeResponse', 28677)
    handShakeResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    onPrimaryAssetsLoadComplete(msg) {
        CommonGameProperties.primaryAssetsLoaded = msg.detail.status;
        if (this.tabeleIdArr.length > 0) {
            for (let i = 0; i < this.tabeleIdArr.length; i++) {
                const joinTableReq = new JoinTable();
                this.addPeer(this.tabeleIdArr[i], CSD.REAL);
                joinTableReq.setReqServerPeerId(this.tabeleIdArr[i]);
                this.sendMessageOnDomain(joinTableReq, CSD.REAL);
            }
        }
        if (this.prevPlayingFastPokerPoolsArr.length > 0) {
            this.processPendingPoolAvailabilityReq();
        }
    }
    buyMoreChips(data) {
        const buyMoreChips = new BuyMoreChips();
        buyMoreChips.setAmount(data.amount);
        buyMoreChips.setConversationId(data.conversationId);
        buyMoreChips.setIncludePartnerWallet(data.includePartnerWallet);
        buyMoreChips.setReqServerPeerId(data.tableId);
        this.sendMessageOnDomain(buyMoreChips, CSD.REAL);
    }

    poolRebuyRequest(data) {
        let rebuyRequest: PoolRebuyRequest = new PoolRebuyRequest();
        rebuyRequest.setEntryId(data.entryId);
        rebuyRequest.setAmount(data.amount);
        rebuyRequest.setReqServerPeerId(data.poolId);
        rebuyRequest.setConversationId(data.conversationId);
        this.sendMessageOnDomain(rebuyRequest, CSD.REAL);
        // var poolRebuyRequest = new window.LSConnector.mainConnector.PokerMessageFactory.Classes.PoolRebuyRequest(amount, entryId, conversationId);
        //     window.Connector.sendMessage(poolRebuyRequest, poolId);
    }

    @MessageListener('PrevPlayingGTs', 45106)
    prevPlayingGTs(message: PrevPlayingGTs) {
        if (message['gtList']!.length > 0) {
            for (let i = 0; i < message['gtList']!.length; i++) {
                // let msg: any = message['gtList'];
                this.openTable(message['gtList']![i]['tableId']);
                this.sendRequestRegainPlayer(message['gtList']![i]['tableId']);
            }
            const msg = message;
            msg['className'] = 'PreviousTableExists';
            const tableidArr: any[] = [];
            for (let i = 0; i < msg['gtList']!.length; i++) {
                tableidArr.push(msg['gtList']![0].tableId);
            }
            msg['existingTablesArr'] = tableidArr;
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', msg);
        }
    }
    openTable(tableId) {
        if (CommonGameProperties.primaryAssetsLoaded) {
            const joinTableReq = new JoinTable();
            this.addPeer(tableId, CSD.REAL);
            joinTableReq.setReqServerPeerId(tableId);
            this.sendMessageOnDomain(joinTableReq, CSD.REAL);
        } else {
            this.tabeleIdArr.push(tableId);
        }
    }
    sendRequestRegainPlayer(peerId) {
        const reqRegainPlayer: RequestRegainPlayer = new RequestRegainPlayer();
        reqRegainPlayer.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(reqRegainPlayer, CSD.REAL);
    }

    /********************************************************************************
     **************************** Request for Fast Forward Games *********************
     ********************************************************************************/

    @MessageListener('PoolAvailabilityResponse', 45560)
    PoolAvailabilityResponse(message: PoolAvailabilityResponse) {
        // Check if PoolAvailabilityRequestPoolId exists in local storage
        const storedArray = localStorage.getItem('poolAvailabilityRequestPoolId');
        if (storedArray) {
            let poolIdArray = JSON.parse(storedArray);

            // Get the poolID from the message
            const poolID = message.getReqServerPeerId();

            // Check if poolID exists in the array
            const index = poolIdArray.indexOf(poolID);
            if (index !== -1) {
                const storedData = localStorage.getItem('PoolAvailabilityResponseArray');
                const poolAvailabilityArray = storedData ? JSON.parse(storedData) : {};

                poolAvailabilityArray[poolID] = message;
                localStorage.setItem('PoolAvailabilityResponseArray', JSON.stringify(poolAvailabilityArray));
                // Remove the poolId from the array
                poolIdArray = poolIdArray.filter((poolId: number) => poolId !== poolID);
                // Store the updated array back into local storage
                localStorage.setItem('poolAvailabilityRequestPoolId', JSON.stringify(poolIdArray));
                if (localStorage.getItem('isFFPreviuosPlayingPool') === 'true') {
                    this.startPrevPrevPlayingFastPokerPools();
                    const storedPoolAvailabilityRequestPoolId = localStorage.getItem('poolAvailabilityRequestPoolId');

                    if (storedPoolAvailabilityRequestPoolId && JSON.parse(storedPoolAvailabilityRequestPoolId).length === 0) {
                        localStorage.setItem('isFFPreviuosPlayingPool', 'false');
                    }
                } else {
                    this.eventDispatcherGlobal.dispatchEvent('PoolAvailabilityResponseForFF', message);
                }
            } else {
                // Handle the case where poolID is not found in the array
            }
        } else {
            // Handle the case where PoolAvailabilityRequestPoolId is not found in local storage
        }
    }

    joinPoolRequest(poolId: number, buyInValues: any) {
        // console.log('ls...this.poolFinalData..', this.poolFinalData)
        const joinPool: JoinPoolRequest = new JoinPoolRequest();

        joinPool.setNoOfEntries(buyInValues.noOfGamesSelected);
        joinPool.setBuyInAmount(buyInValues.selectedBuyIn);
        joinPool.setRebuyToMax(buyInValues.autoBuyIn);
        joinPool.setReqServerPeerId(poolId);

        this.sendMessageOnDomain(joinPool, CSD.REAL);
    }

    @MessageListener('JoinPoolResponse', 45561)
    JoinPoolResponse(msg: JoinPoolResponse) {
        let errorMessage = '';
        const storedPoolIdData = localStorage.getItem('PoolAvailabilityResponseArray');
        const poolAvailabilityArray = storedPoolIdData ? JSON.parse(storedPoolIdData) : {};
        msg['tableStatus'] = poolAvailabilityArray[msg['reqServerPeerId']];

        switch (msg['poolJoinStatus']) {
            case 0: // POKER_POOL_AVAILABLE, JOIN_FAST_POOL_SUCCESS 0
            case 1: // JOIN_FAST_POOL_SUCCESS_PARTIAL 1
                for (const entryId of msg['entryIds'] || []) {
                    const message = {
                        ...msg, // Copying all properties from msg
                        poolID: msg['reqServerPeerId'], // Assigning poolID
                        peerId: msg['reqServerPeerId'], // Assigning peerId
                        className: msg['MESSAGE_NAME'], // Assigning className
                        entryId: entryId, // Assigning entryId
                        tableId: entryId, // Assigning tableId
                    };
                    // this.store.dispatch(addMiniTable({ miniTables: message }));
                    this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
                }
                break;
            case 2: // JOIN_FAST_POOL_FAILURE 2
            case 5: // JOIN_FAST_POOL_LESS_BUYIN_AMOUNT 5
            case 6: // JOIN_FAST_POOL_MORE_BUYIN_AMOUNT 6
            case 7: // JOIN_POOL_UNKNOWN_TABLE_LIMIT 7
            case 8: // JOIN_FAST_POOL_WALLET_FAILURE 8
            case 9: // case for insufficient balance 9
                errorMessage = CommonGameProperties.langPack['getFormattedString'](msg.getMessageToClient());
                break;
            case 2602: // R_GAME_SESSION_TIMEDOUT
                errorMessage = 'Sorry, you do not have the tournament ticket required to register for this tournament';
                break;
            default:
                if (msg.getMessageToClient() != null) {
                    errorMessage = CommonGameProperties.langPack['getFormattedString'](msg.getMessageToClient());
                } else {
                    errorMessage = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_DB_TRADE_MTCTREGISTER_ProblemTryAgain');
                }
        }
        if (errorMessage !== '') {
            this.eventDispatcherGlobal.dispatchEvent('JoinPoolResponseError', errorMessage);
        }
    }

    /********************************************************************************
     **************************** PrevPlayingFastPokerPools **************************
     ********************************************************************************/

    // @MessageListener('PrevPlayingFastPokerPools', 45592)
    // prevPlayingFastPokerPools(message: any) {
    //     // this.isFFPreviuosPlayingPool = true;
    //     localStorage.setItem('isFFPreviuosPlayingPool', 'true');
    //     let isFFPreviuosPlayingPool = localStorage.getItem('isFFPreviuosPlayingPool');
    //     this.isFFPreviuosPlayingPool = isFFPreviuosPlayingPool === 'true';
    //     this.prevPlayingFastPokerPoolsArr.push(message);
    //     this.pendingPoolAvailabilityReqArr[message.fastPoolId] = { poolId: message.fastPoolId, tableEntries: message.entryIds.length };
    //     if (CommonGameProperties.primaryAssetsLoaded) {
    //         this.processPendingPoolAvailabilityReq();
    //     }
    // }

    @MessageListener('PrevPlayingFastPokerPools', 45592)
    prevPlayingFastPokerPools(message: any) {
        localStorage.setItem('isFFPreviuosPlayingPool', 'true');
        this.isFFPreviuosPlayingPool = localStorage.getItem('isFFPreviuosPlayingPool') === 'true';

        // Push new message to the array
        this.prevPlayingFastPokerPoolsArr.push(message);

        // Save the updated array to localStorage
        localStorage.setItem('prevPlayingFastPokerPoolsArr', JSON.stringify(this.prevPlayingFastPokerPoolsArr));

        this.pendingPoolAvailabilityReqArr[message.fastPoolId] = {
            poolId: message.fastPoolId,
            tableEntries: message.entryIds.length,
        };

        if (CommonGameProperties.primaryAssetsLoaded) {
            this.processPendingPoolAvailabilityReq();
        }
    }

    @MessageListener('PoolUserReconnectResponse', 45588)
    poolUserReconnectResponse(data: any) {
        this.poolConnectionObj[data.reqServerPeerId] = true; //this pool is connected.
        let message;
        data.playerEntryStatuses.forEach(
            function (playerEntryStatus) {
                message = playerEntryStatus;
                switch (message.entryStatus) {
                    case 0: //FAST_PLAYER_ENTRY_ON_GAME_TABLE
                        //window.Connector.addPeer(message.activeTableId, 0 /*DOMAIN_REAL*/);
                        const PoolStartGameNotification = {
                            entryId: message.playerEntryId,
                            tableId: message.activeTableId,
                            msgNumber: 0,
                            className: 'PoolStartGameNotification',
                            info: 'from ffController',
                        };
                        this.poolStartGameNotification(PoolStartGameNotification);
                        break;
                    case 2: //FAST_PLAYER_ENTRY_SITTING_OUT
                    case 3: //FAST_PLAYER_ENTRY_FORCED_GEO_SIT_OUT
                    case 4: //FAST_PLAYER_ENTRY_FORCED_SESSION_SIT_OUT
                    case 6: //FAST_PLAYER_FORCED_SITOUT
                        const PoolEndGameNotification = {
                            entryId: message.playerEntryId,
                            tableId: -1,
                            msgNumber: 0,
                            className: 'PoolEndGameNotification',
                            info: 'from ffController',
                        };
                        this.poolEndGameNotification(PoolEndGameNotification);
                        break;
                }
                message['entryId'] = message.playerEntryId;
                message['className'] = 'playerEntryStatus';
                message['info'] = 'dispatched from PoolUserReconnectResponse';
                this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
            }.bind(this),
        );
    }
    processPendingPoolAvailabilityReq() {
        this.pendingPoolAvailabilityReqArr.forEach((val) => {
            this.poolAvailabilityRequest(val['poolId'], val['tableEntries']);
        });
    }
    poolAvailabilityRequest(poolID, TableEntries) {
        if (!CommonGameProperties.primaryAssetsLoaded) {
            this.pendingPoolAvailabilityReqArr[poolID] = { poolId: poolID, tableEntries: TableEntries };
            this.eventDispatcherGlobal.dispatchEvent('showAssetsErrorDialog', {});
            return;
        }
        const poolAvailability: PoolAvailabilityRequest = new PoolAvailabilityRequest();
        poolAvailability.setCurrentEntries(TableEntries);
        poolAvailability.setReqServerPeerId(poolID);
        // this.PoolAvailabilityRequestPoolId = poolID;
        // Push poolID into the array stored in local storage
        const storedPoolIdArray = localStorage.getItem('poolAvailabilityRequestPoolId');
        const poolIdArray = storedPoolIdArray ? JSON.parse(storedPoolIdArray) : [];
        poolIdArray.push(poolID);
        localStorage.setItem('poolAvailabilityRequestPoolId', JSON.stringify(poolIdArray));
        this.PoolAvailabilityRequestPoolId = poolIdArray;
        this.sendMessageOnDomain(poolAvailability, CSD.REAL);
    }
    startPrevPrevPlayingFastPokerPools() {
        const storedData = localStorage.getItem('prevPlayingFastPokerPoolsArr');
        if (storedData) {
            this.prevPlayingFastPokerPoolsArr = JSON.parse(storedData);
        }
        for (const msg of this.prevPlayingFastPokerPoolsArr) {
            for (const entryId of msg.entryIds) {
                const message = {
                    ...msg, // Copying all properties from msg
                    poolId: msg.fastPoolId, // Assigning poolId
                    entryId: entryId, // Assigning entryId
                    tableId: entryId,
                    className: 'PrevPlayingFastPokerPools', // Assigning className
                };

                const storedData = localStorage.getItem('PoolAvailabilityResponseArray');
                const poolAvailabilityArray = storedData ? JSON.parse(storedData) : {};
                message.tableStatus = poolAvailabilityArray[message.fastPoolId];

                this.entryToPoolMap[entryId] = msg.fastPoolId;
                this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
                this.initPoolRequestUserReconnect(msg.entryIds, msg.fastPoolId);
            }
        }
        this.prevPlayingFastPokerPoolsArr = [];
        this.pendingPoolAvailabilityReqArr = [];
    }

    initPoolRequestUserReconnect(entryIds, poolId) {
        const poolRequestUserReconnect: PoolRequestUserReconnect = new PoolRequestUserReconnect();
        poolRequestUserReconnect.setEntryIds(entryIds);
        poolRequestUserReconnect.setReqServerPeerId(poolId);
        this.sendMessageOnDomain(poolRequestUserReconnect, CSD.REAL);
        this.poolConnectionObj[poolId] = false; //message sent to server, waiting for response.
        //setTimeout(this.checkForPoolReconnection.bind(this, poolId), 3000);
    }

    getGameTableStatusForEntry(entryId) {
        return this.PoolAvailabilityResponseArray[entryId];
    }
    /*
        even after 2 seconds, if server doesn't send reconnection response, it means the user is being kicked out of the pool.
    */
    checkForPoolReconnection(poolId) {
        if (!this.poolConnectionObj[poolId]) {
            const entryIds = this.getEntriesForPoolId(poolId);
            for (const entryId of entryIds) {
                const message = {};
                message['messageToClient'] = '';
                message['entryId'] = entryId;
                message['className'] = 'PoolKickoutNotification';
                message['info'] = 'initiated at ffcontroller for disconnection';
                this.PoolKickOutNotification(message);
            }
        }
    }
    PoolKickOutNotification(message) {
        // message['entryId'] = message.entryId;
        message['className'] = 'PoolKickoutNotification';
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    getEntriesForPoolId(poolId) {
        const entryIds: any[] = [];
        for (const entry in this.entryToPoolMap) {
            if (this.entryToPoolMap[entry] == poolId) {
                entryIds.push(entry);
            }
        }
        return entryIds;
    }

    /********************************************************************************
     **************************** End Fast Forward Games *****************************
     ********************************************************************************/

    @MessageListener('GameStatus', 45228)
    gameStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayerStatus', 45098)
    playerStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('SitResponse', 45318)
    sitResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('AddCommunityCards', 45070)
    addCommunityCards(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('AddPlayer', 45071)
    addPlayer(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('CurrentPlayer', 45076)
    currentPlayer(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('DealCards', 45077)
    dealCards(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('Dealer', 45078)
    dealer(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('EndTheRound', 45079)
    endTheRound(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('NewGame', 45082)
    newGame(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('PotWinners', 45087)
    potWinners(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('GameTableStatus', 45089)
    gameTableStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('RemovePlayer', 45091)
    removePlayer(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('ReserveSeat', 45092)
    reserveSeat(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('TimeOut', 45095)
    timeOut(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('WaitStatus', 45096)
    waitStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('PlayerDealCards', 45097)
    playerDealCards(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('ChangePlayerState', 45075)
    changePlayerState(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('PlayerStates', 45525)
    playerStates(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('PlayerBalance', 45122)
    playerBalance(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('Blind', 45058)
    blind(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('Ante', 45057)
    ante(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('PostAnte', 45085)
    postAnte(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('PostBlind', 45086)
    postBlind(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('ShowCards', 45211)
    showCards(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('HighlightWinCards', 45113)
    highlightWinCards(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('Winner', 45101)
    winner(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('RequestEndOfTheGame', 45393)
    requestEndOfTheGame(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }
    @MessageListener('CloseTable', 45203)
    closeTable(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayerBalances', 45524)
    playerBalances(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    // @MessageListener("LoginSuccessResponse", 41203)
    // loginSuccessResponse(message:any){
    //     this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
    // }

    // @MessageListener("LoginSuccessUserProfile", 41204)
    // loginSuccessUserProfile(message:any){
    //     this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
    // }

    @MessageListener('BalanceInfo', 41158)
    balanceInfo(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    // @MessageListener("UserFreeRollList", 45653)
    // userFreeRollList(message:any){
    //     this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
    // }

    @MessageListener('OptionSet', 45083)
    optionSet(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseRegainPlayerFailed', 45451)
    responseRegainPlayerFailed(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('CulturalFormat', 41027)
    culturalFormat(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('CurrencyDetailsList', 41020)
    currencyDetailsList(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('TimeZoneDetails', 41047)
    timeZoneDetails(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('FXRateSnapshot', 41022)
    fXRateSnapshot(message: any) {
        FxRateHelperClassService.getInstance().FXRateSnapshotResponse(message);
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('SNGJackpotInfo', 45713)
    sngJackpotInfo(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ShowHUPairTable', 45766)
    showHUPairTable(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('HUPairTableSelectStatus', 45763)
    huPairTableSelectStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('HUEvenGame', 45770)
    huEvenGame(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ShowCongrats', 45212)
    showCongrats(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('SNGJPPayoutAnimation', 45714)
    sngJPPayoutAnimation(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    SNGJPUnRegistrationRequest(sngJackpotID: number, tableId: number, sngJPInstanceID: number, participantNo: number) {
        const lobbyUpdate: SNGJPUnRegistrationRequest = new SNGJPUnRegistrationRequest();
        lobbyUpdate.setSngJackpotID(sngJackpotID);
        lobbyUpdate.setReqServerPeerId(sngJackpotID);
        lobbyUpdate.setTableId(tableId);
        lobbyUpdate.setSngJPInstanceID(sngJPInstanceID);
        lobbyUpdate.setParticipantNo(participantNo);
        lobbyUpdate.setConversationId(0);
        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
    }

    @MessageListener('SNGJPGameAboutToStart', 45719)
    sngJPGameAboutToStart(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('AutoOptionSet', 45074)
    autoOptionSet(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('AutoOption', 45222)
    autoOption(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ShowCardsOptions', 45093)
    showCardsOptions(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('MuckCards', 45081)
    muckCards(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('SelectedOption', 45066)
    selectedOption(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolShutdownNotification', 45590)
    poolShutdownNotification(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolFraudPlayerKickoutNotification', 45593)
    poolFraudPlayerKickoutNotification(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ShowEmoji', 45750)
    showEmoji(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    // @MessageListener('SNGJPUnRegistrationResponse', 45712)
    // sngJPUnRegistrationResponse(message: any) {
    //     // this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    //     let messageToShow;
    //     if (message.responseMessage) {
    //         messageToShow = CommonGameProperties.langPack['getFormattedString'](message.responseMessage);
    //         if (messageToShow == '' || messageToShow == null)
    //             messageToShow = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
    //         this.eventDispatcherGlobal.dispatchEvent('showUnregisterPopup', messageToShow);
    //     }
    // }

    @MessageListener('HandStrength', 45206)
    HandStrength(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ShowRabbit', 45751)
    showRabbit(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('BroadcastShowRabbit', 45753)
    broadcastShowRabbit(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ProbabilityOfWinning', 45758)
    probabilityOfWinning(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('TourneyLevelInfo', 45216)
    tourneyLevelInfo(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    // @MessageListener('SNGJPTournamentRankInfo', 45720)
    // sngJPTournamentRankInfo(message: any) {
    //     this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    // }

    @MessageListener('RequestKillGametableSilently', 45398)
    requestKillGametableSilently(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    // @MessageListener("JoinPoolResponse", 45561)
    // joinPoolResponse(message:any){
    //     this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
    // }

    @MessageListener('PoolWaitingNotification', 45578)
    poolWaitingNotification(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolStartGameNotification', 45577)
    poolStartGameNotification(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ReqNextHandOnFoldAndWatch', 45585)
    reqNextHandOnFoldAndWatch(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('RequestFoldAndWatchOn', 45570)
    requestFoldAndWatchOn(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('RequestFoldAndWatchOff', 45571)
    requestFoldAndWatchOff(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolKickoutNotification', 45581)
    poolKickoutNotification(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolEndGameNotification', 45579)
    poolEndGameNotification(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('RequestEndOfTheGameFastPoker', 45586)
    requestEndOfTheGameFastPoker(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ClosePoolEntry', 45743)
    closePoolEntry(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseLossLimitExceed', 45731)
    responseLossLimitExceed(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayerEntryStatus', 45589)
    PlayerEntryStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolRebuyResponse', 45573)
    poolRebuyResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('BlockSeatResponse', 45200)
    blockSeatResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ReduceChipsResponse', 45739)
    reduceChipsResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseBuyMoreChipsSuccess', 45425)
    responseBuyMoreChipsSuccess(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseBuyMoreChipsAllowedAmount', 45477)
    responseBuyMoreChipsAllowedAmount(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseBuyMoreChipsPlaying', 45424)
    responseBuyMoreChipsPlaying(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('RGLimitErrorMsg', 45482)
    rgLimitErrorMsg(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('SitOutWarningOff', 45684)
    sitOutWarningOff(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayedGamesCount', 45805)
    playedGamesCount(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('CanLeaveTableResponse', 45821)
    canLeaveTableResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    onCanLeaveTable(tableId: number) {
        CommonGameProperties.facade['table' + tableId].canLeaveTable();
        // this.eventDispatcherGlobal.dispatchEvent(SlotCoreGameEvent.ON_LEAVE,);
    }

    @MessageListener('CanSitOutResponse', 45809)
    canSitOutResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ATHAutoSitOutMsg', 45810)
    athAutoSitOutMsg(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayersCashoutEquityDetails', 45803)
    playersCashoutEquityDetails(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayerCashoutEquityDetails', 45802)
    playerCashoutEquityDetails(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseCashoutEquity', 45804)
    responseCashoutEquity(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PlayerCashedOutEquityStatus', 45801)
    playerCashedOutEquityStatus(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseUserTableBalanceReduceChips', 45816)
    responseUserTableBalanceReduceChips(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('RatHoleResponse', 45287)
    ratHoleResponse(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResponseRegainPlayerSuccess', 45452)
    responseRegainPlayerSuccess(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    // case "errorResponse":
    // case "lowbalance":
    // case "unFinishedGameResponse":
    // case "connectionStatus":

    // case "PlayerTimeBankBalance": Missing
    // case "RemoveGameTable":  need check
    // case "SwitchActiveTable": need check
    // case "doAppLogOut": need check
    // case "disConnected": need check

    @MessageListener('CompositeTableMessage', 45523)
    compositeTableMessage(message: any) {
        if (message.messages) {
            for (let i = 0; i < message.messages.length; i++) {
                message.messages[i]['peerId'] = message.reqServerPeerId;
                this.sendTableData(message.messages[i]);
            }
        }
    }

    @MessageListener('DiamondBalance', 45759)
    diamondBalance(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('PoolSitOutNotification', 45580)
    PoolSitOutNotification(message: PoolSitOutNotification) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('AutoOptionToOptionsMappings', 45111)
    AutoOptionToOptionsMappings(message: AutoOptionToOptionsMappings) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ResThrowAnimation', 45755)
    resThrowAnimation(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('ThrowAnimation', 45756)
    throwAnimation(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('TableReduceBalanceLimit', 45817)
    TableReduceBalanceLimit(message: any) {
        this.eventDispatcherGlobal.dispatchEvent('TABLE_REDUCE_BALANCE_INFO', message);
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    autoRebuyStatusChange(peerId, entryId, autoRebuyStatus) {
        const message = {};
        message['className'] = 'autoRebuyStatusChange';
        message['peerId'] = peerId;
        message['rebuyStatus'] = {
            entryId: entryId,
            autoRebuyStatus: autoRebuyStatus,
        };
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    sendTableData = function (message) {
        // message["peerId"] = peerId;
        // message["className"] = message.className;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        //playerStatus info will come, where we should display the information of the 3rd player
    };
    sendRequestAutoOptionsOptionsMappings() {
        const requestAutoOptionsOptionsMappings: RequestAutoOptionsOptionsMappings = new RequestAutoOptionsOptionsMappings();
        this.sendMessageOnDomain(requestAutoOptionsOptionsMappings, CSD.REAL);
    }
    sendRequestAutoPostBlindOff(peerId) {
        const requestAutoPostBlindOff: RequestAutoPostBlindOff = new RequestAutoPostBlindOff();
        requestAutoPostBlindOff.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestAutoPostBlindOff, CSD.REAL);
    }
    sendRequestAutoPostBlindOn(peerId) {
        const requestAutoPostBlindOn: RequestAutoPostBlindOn = new RequestAutoPostBlindOn();
        requestAutoPostBlindOn.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestAutoPostBlindOn, CSD.REAL);
    }
    sendRequestAutoRebuyOff(peerId) {
        const requestAutoRebuyOff: RequestAutorebuyOff = new RequestAutorebuyOff();
        requestAutoRebuyOff.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestAutoRebuyOff, CSD.REAL);
    }
    sendRequestAutoRebuyOn(peerId) {
        const requestAutoRebuyOn: RequestAutoRebuyOn = new RequestAutoRebuyOn();
        requestAutoRebuyOn.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestAutoRebuyOn, CSD.REAL);
    }
    sendDontShowOptionSelected(peerId) {
        const dontShowOptionSelected: ResponseShowCardsOptionDontShow = new ResponseShowCardsOptionDontShow();
        dontShowOptionSelected.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(dontShowOptionSelected, CSD.REAL);
    }
    sendMuckOptionSelected(peerId) {
        const muckOptionSelected: ResponseShowCardsOptionMuck = new ResponseShowCardsOptionMuck();
        muckOptionSelected.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(muckOptionSelected, CSD.REAL);
    }
    sendShowOptionSelected(peerId) {
        const showOptionSelected: ResponseShowCardsOptionShow = new ResponseShowCardsOptionShow();
        showOptionSelected.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(showOptionSelected, CSD.REAL);
    }
    sendReqNextHandOffFoldAndWatch(peerId) {
        const reqNextHandOffFoldAndWatch: ReqNextHandOffFoldAndWatch = new ReqNextHandOffFoldAndWatch();
        reqNextHandOffFoldAndWatch.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(reqNextHandOffFoldAndWatch, CSD.REAL);
    }
    sendReqEnableRabbit(peerId, gameId) {
        const reqEnableRabbit: ReqEnableRabbit = new ReqEnableRabbit();
        reqEnableRabbit.setReqServerPeerId(peerId);
        reqEnableRabbit.setGameId(gameId);
        this.sendMessageOnDomain(reqEnableRabbit, CSD.REAL);
    }
    sendCashoutOption(tableId, gameId, isSelected) {
        const requestCashoutEquity: RequestCashoutEquity = new RequestCashoutEquity();
        requestCashoutEquity.setTableId(tableId);
        requestCashoutEquity.setGameId(gameId);
        requestCashoutEquity.setRequestCode(isSelected);
        requestCashoutEquity.setReqServerPeerId(tableId);
        this.sendMessageOnDomain(requestCashoutEquity, CSD.REAL);
    }
    sendSelectedEmoji(emojiId, peerId) {
        const selectedEmoji: ReqShowEmoji = new ReqShowEmoji();
        selectedEmoji.setEmojiId(emojiId);
        selectedEmoji.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(selectedEmoji, CSD.REAL);
    }
    sendLeavePoolRequest(entryId: number, poolId: number) {
        const leavePoolRequest: LeavePoolRequest = new LeavePoolRequest();
        leavePoolRequest.setEntryId(entryId);
        leavePoolRequest.setReqServerPeerId(poolId);
        this.sendMessageOnDomain(leavePoolRequest, CSD.REAL);
    }
    sendRequestAutopostBlindAndFoldOff(peerId) {
        const requestAutopostBlindAndFoldOff: RequestAutopostBlindAndFoldOff = new RequestAutopostBlindAndFoldOff();
        requestAutopostBlindAndFoldOff.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestAutopostBlindAndFoldOff, CSD.REAL);
    }
    sendRequestAutoCheck(autoCheck, peerId) {
        const requestAutoCheck = autoCheck ? new RequestAutoCheckOn() : new RequestAutoCheckOff();
        requestAutoCheck.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestAutoCheck, CSD.REAL);
    }
    sendRequestSitoutNextHandOff(peerId) {
        const requestSitoutNextHandOff: RequestSitoutNextHandOff = new RequestSitoutNextHandOff();
        requestSitoutNextHandOff.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestSitoutNextHandOff, CSD.REAL);
    }
    sendSitOutNexthandOn(peerId) {
        const sitOutNexthandOn: RequestSitoutNextHandOn = new RequestSitoutNextHandOn();
        sitOutNexthandOn.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(sitOutNexthandOn, CSD.REAL);
    }
    sendPoolSitOutNotification(peerId) {
        const entryId = peerId;
        const poolSitOutNotification: PoolSitOutNotification = new PoolSitOutNotification();
        poolSitOutNotification.setReqServerPeerId(peerId);
        poolSitOutNotification.setEntryId(entryId);
        this.sendMessageOnDomain(poolSitOutNotification, CSD.REAL);
    }
    sendRequestPoolSitout(peerId: number, poolId: number) {
        const requestPoolSitout: RequestPoolSitout = new RequestPoolSitout();
        requestPoolSitout.setReqServerPeerId(poolId);
        requestPoolSitout.setEntryId(peerId);
        this.sendMessageOnDomain(requestPoolSitout, CSD.REAL);
    }
    sendBackToPoolRequest(peerId: number, poolId: number) {
        //here peerId is EntryId
        const backToPoolRequest: BackToPoolRequest = new BackToPoolRequest();
        backToPoolRequest.setReqServerPeerId(poolId);
        backToPoolRequest.setEntryId(peerId);
        this.sendMessageOnDomain(backToPoolRequest, CSD.REAL);
    }
    sendSelectedOption(optionId, optionSetId, displayedOptionsForSecs, mouseClickSimulationType, optionSelectedAt, peerId) {
        optionSelectedAt = this.getTimeForPeer(peerId);
        const option = new Option();
        option.setAmount(optionId.amount);
        option.setOptionId(optionId.optionId);
        option.setOptionTitle(optionId.optionTitle);
        const selectedOption: SelectOption = new SelectOption();
        selectedOption.setReqServerPeerId(peerId);
        selectedOption.setDisplayedOptionsForSecs(displayedOptionsForSecs);
        selectedOption.setMouseClickSimulationType(mouseClickSimulationType);
        selectedOption.setOption(option);
        selectedOption.setOptionSelectedAt(optionSelectedAt);
        selectedOption.setOptionSetId(optionSetId);
        this.sendMessageOnDomain(selectedOption, CSD.REAL);
    }
    sendSitRequest(conversationId, frFppIndicator, noOfChips, seatingPosition, tableCategory, peerId) {
        const sitRequestSit = new Sit();
        sitRequestSit.setReqServerPeerId(peerId);
        sitRequestSit.setConversationId(conversationId);
        sitRequestSit.setFrFppIndicator(frFppIndicator);
        sitRequestSit.setNoOfChips(noOfChips);
        sitRequestSit.setSeatingPosition(seatingPosition);
        sitRequestSit.setTableCategory(tableCategory);
        this.sendMessageOnDomain(sitRequestSit, CSD.REAL);
    }
    sendOptionSetAckReq(optionSetId, clientServerClock, peerId) {
        clientServerClock = parseInt(this.getTimeForPeer(peerId));
        const optionSetAckReq: OptionSetAck = new OptionSetAck();
        optionSetAckReq.setReqServerPeerId(peerId);
        optionSetAckReq.setClientServerClock(clientServerClock);
        optionSetAckReq.setOptionSetId(optionSetId);
        this.sendMessageOnDomain(optionSetAckReq, CSD.REAL);
    }
    sendEnableTimeBank(optionSetId, timeBankTimeoutAt, timeBankAutoEnabled, peerId) {
        const enableTimeBank: EnableTimeBank = new EnableTimeBank();
        enableTimeBank.setReqServerPeerId(peerId);
        enableTimeBank.setOptionSetId(optionSetId);
        enableTimeBank.setTimeBankAutoEnabled(timeBankAutoEnabled);
        enableTimeBank.setTimeBankTimeoutAt(timeBankTimeoutAt);
        this.sendMessageOnDomain(enableTimeBank, CSD.REAL);
    }
    sendRequestMuckLosingHand(status, peerId) {
        let requestToSend;
        if (status == 1) {
            requestToSend = new RequestMuckLosingHandOn();
        } else {
            requestToSend = new RequestMuckLosingHandOff();
        }
        requestToSend.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestToSend, CSD.REAL);
    }
    sendReqThrowAnimation(animationId, toPlayerSeatNo, peerId) {
        const reqThrowAnimation: ReqThrowAnimation = new ReqThrowAnimation();
        reqThrowAnimation.setAnimationId(animationId);
        reqThrowAnimation.setReqServerPeerId(peerId);
        reqThrowAnimation.setDestinationPlayerSeatNo(toPlayerSeatNo);
        this.sendMessageOnDomain(reqThrowAnimation, CSD.REAL);
    }
    sendRequestHUPairTable(boolVal, peerId) {
        const requestHUPairTable: RequestHUPairTable = new RequestHUPairTable();
        requestHUPairTable.setReqServerPeerId(peerId);
        requestHUPairTable.setSelected(boolVal);
        this.sendMessageOnDomain(requestHUPairTable, CSD.REAL);
    }
    sendAutoSelectedOption(autoOptionData, peerId) {
        const autoOption: AutoOption = new AutoOption();
        autoOption.setAmount(autoOptionData.amount);
        autoOption.setAutoOptionId(autoOptionData.autoOptionId);
        autoOption.setIsAllIn(autoOptionData.autoOptionId);
        autoOption.setTitle(autoOptionData.title);
        autoOption.setReqServerPeerId(peerId);
        const selectedAutoOption: SelectedAutoOption = new SelectedAutoOption();
        selectedAutoOption.setAutoOption(autoOption);
        selectedAutoOption.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(selectedAutoOption, CSD.REAL);
    }
    /// On Sit Out
    sendRequestAutoPostBlindAndFoldOn(peerId) {
        const reqSitoutNextBlindOn: RequestAutoPostBlindAndFoldOn = new RequestAutoPostBlindAndFoldOn();
        reqSitoutNextBlindOn.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(reqSitoutNextBlindOn, CSD.REAL);
    }
    sendRequestCanSitOut(tableId, requestType) {
        const requestCanSitOut: CanSitOut = new CanSitOut();
        requestCanSitOut.setRequestType(requestType);
        requestCanSitOut.setReqServerPeerId(tableId);
        this.sendMessageOnDomain(requestCanSitOut, CSD.REAL);
    }
    sendRequestCanLeaveTable(tableId) {
        const requestCanLeaveTable = new CanLeaveTable();
        requestCanLeaveTable.setReqServerPeerId(tableId);
        this.sendMessageOnDomain(requestCanLeaveTable, CSD.REAL);
    }
    sendRequestGameStatus = function (peerId) {
        const reqGameStatus: RequestGameStatus = new RequestGameStatus();
        reqGameStatus.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(reqGameStatus, CSD.REAL);
    };
    sendRequestLeaveTable = function (tableCategory, peerId) {
        const requestLeaveTable: RequestLeaveTable = new RequestLeaveTable();
        requestLeaveTable.setConversationId(0);
        requestLeaveTable.setTableCategory(tableCategory);
        requestLeaveTable.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(requestLeaveTable, CSD.REAL);
    };
    sendReqDiamondBalance = function (peerId) {
        const getPokerDiamondBalance: GetPokerDiamondBalance = new GetPokerDiamondBalance();
        getPokerDiamondBalance.setReqServerPeerId(peerId);
        this.sendMessageOnDomain(getPokerDiamondBalance, CSD.REAL);
    };
}
