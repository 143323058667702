import { Message } from '../../message';

export class ShowCards2 extends Message {
    private MESSAGE_NAME: string = 'ShowCards2';
    private seatNo: number = 0;
    private cards: any[] | null = null;
    private handStrengthHi2: any | null = null;
    private bestCards: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNo: number = 0,
        cards: any[] | null = null,
        handStrengthHi2: any | null = null,
        bestCards: string | null = null,
    ) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.cards = cards;
        this.handStrengthHi2 = handStrengthHi2;
        this.bestCards = bestCards;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getCards(): any[] | null {
        return this.cards;
    }

    setCards(cards: any[] | null) {
        this.cards = cards;
    }
    getHandStrengthHi2(): any | null {
        return this.handStrengthHi2;
    }

    setHandStrengthHi2(handStrengthHi2: any | null) {
        this.handStrengthHi2 = handStrengthHi2;
    }
    getBestCards(): string | null {
        return this.bestCards;
    }

    setBestCards(bestCards: string | null) {
        this.bestCards = bestCards;
    }
}
