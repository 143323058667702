import { Message } from '../../message';

export class EnableChat extends Message {
    private MESSAGE_NAME: string = 'EnableChat';
    private enabled: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, enabled: boolean | null = false) {
        super(reqServerPeerId);
        this.enabled = enabled;
    }

    getEnabled(): boolean | null {
        return this.enabled;
    }

    setEnabled(enabled: boolean | null) {
        this.enabled = enabled;
    }
}
