import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class PokerLeaderboardService {
    constructor(private api: PokerApiService) {}

    getLeaderboardFeedData(contentParameters?: string, buttonParameters?: string): Observable<any> {
        return this.api.get('leaderboard/GetLeaderboardPosFeedData', { contentParameters: contentParameters, buttonParameters: buttonParameters });
    }

    getLeaderboardFeedByPromoId(promoId: string) {
        return this.api.get(`leaderboard/GetLeaderboardPosFeedByPromoId?promoId=${promoId}`);
    }
}
