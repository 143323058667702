import { Message } from '../../message';

export class TimeZoneDetails extends Message {
    private MESSAGE_NAME: string = 'TimeZoneDetails';
    private msgSTZ: boolean | null = false;
    private rowOffset: number = 0;
    private useDSTime: boolean | null = false;
    private startMonth: number = 0;
    private startDay: number = 0;
    private startDayOfWeek: number = 0;
    private startTime: number = 0;
    private windowsTZid: string | null = null;
    private metaZoneID: string | null = null;
    private startMode: number = 0;
    private endMonth: number = 0;
    private endDay: number = 0;
    private endDayOfWeek: number = 0;
    private endTime: number = 0;
    private endMode: number = 0;
    private dstSavings: number = 0;
    private standardShortDisplayName: number = 0;
    private daylightShortDisplayName: number = 0;
    private standardLongDisplayName: number = 0;
    private daylightLongDisplayName: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        msgSTZ: boolean | null = false,
        rowOffset: number = 0,
        useDSTime: boolean | null = false,
        startMonth: number = 0,
        startDay: number = 0,
        startDayOfWeek: number = 0,
        startTime: number = 0,
        windowsTZid: string | null = null,
        metaZoneID: string | null = null,
        startMode: number = 0,
        endMonth: number = 0,
        endDay: number = 0,
        endDayOfWeek: number = 0,
        endTime: number = 0,
        endMode: number = 0,
        dstSavings: number = 0,
        standardShortDisplayName: number = 0,
        daylightShortDisplayName: number = 0,
        standardLongDisplayName: number = 0,
        daylightLongDisplayName: number = 0,
    ) {
        super(reqServerPeerId);
        this.msgSTZ = msgSTZ;
        this.rowOffset = rowOffset;
        this.useDSTime = useDSTime;
        this.startMonth = startMonth;
        this.startDay = startDay;
        this.startDayOfWeek = startDayOfWeek;
        this.startTime = startTime;
        this.windowsTZid = windowsTZid;
        this.metaZoneID = metaZoneID;
        this.startMode = startMode;
        this.endMonth = endMonth;
        this.endDay = endDay;
        this.endDayOfWeek = endDayOfWeek;
        this.endTime = endTime;
        this.endMode = endMode;
        this.dstSavings = dstSavings;
        this.standardShortDisplayName = standardShortDisplayName;
        this.daylightShortDisplayName = daylightShortDisplayName;
        this.standardLongDisplayName = standardLongDisplayName;
        this.daylightLongDisplayName = daylightLongDisplayName;
    }

    getMsgSTZ(): boolean | null {
        return this.msgSTZ;
    }

    setMsgSTZ(msgSTZ: boolean | null) {
        this.msgSTZ = msgSTZ;
    }
    getRowOffset(): number {
        return this.rowOffset;
    }

    setRowOffset(rowOffset: number) {
        this.rowOffset = rowOffset;
    }
    getUseDSTime(): boolean | null {
        return this.useDSTime;
    }

    setUseDSTime(useDSTime: boolean | null) {
        this.useDSTime = useDSTime;
    }
    getStartMonth(): number {
        return this.startMonth;
    }

    setStartMonth(startMonth: number) {
        this.startMonth = startMonth;
    }
    getStartDay(): number {
        return this.startDay;
    }

    setStartDay(startDay: number) {
        this.startDay = startDay;
    }
    getStartDayOfWeek(): number {
        return this.startDayOfWeek;
    }

    setStartDayOfWeek(startDayOfWeek: number) {
        this.startDayOfWeek = startDayOfWeek;
    }
    getStartTime(): number {
        return this.startTime;
    }

    setStartTime(startTime: number) {
        this.startTime = startTime;
    }
    getWindowsTZid(): string | null {
        return this.windowsTZid;
    }

    setWindowsTZid(windowsTZid: string | null) {
        this.windowsTZid = windowsTZid;
    }
    getMetaZoneID(): string | null {
        return this.metaZoneID;
    }

    setMetaZoneID(metaZoneID: string | null) {
        this.metaZoneID = metaZoneID;
    }
    getStartMode(): number {
        return this.startMode;
    }

    setStartMode(startMode: number) {
        this.startMode = startMode;
    }
    getEndMonth(): number {
        return this.endMonth;
    }

    setEndMonth(endMonth: number) {
        this.endMonth = endMonth;
    }
    getEndDay(): number {
        return this.endDay;
    }

    setEndDay(endDay: number) {
        this.endDay = endDay;
    }
    getEndDayOfWeek(): number {
        return this.endDayOfWeek;
    }

    setEndDayOfWeek(endDayOfWeek: number) {
        this.endDayOfWeek = endDayOfWeek;
    }
    getEndTime(): number {
        return this.endTime;
    }

    setEndTime(endTime: number) {
        this.endTime = endTime;
    }
    getEndMode(): number {
        return this.endMode;
    }

    setEndMode(endMode: number) {
        this.endMode = endMode;
    }
    getDstSavings(): number {
        return this.dstSavings;
    }

    setDstSavings(dstSavings: number) {
        this.dstSavings = dstSavings;
    }
    getStandardShortDisplayName(): number {
        return this.standardShortDisplayName;
    }

    setStandardShortDisplayName(standardShortDisplayName: number) {
        this.standardShortDisplayName = standardShortDisplayName;
    }
    getDaylightShortDisplayName(): number {
        return this.daylightShortDisplayName;
    }

    setDaylightShortDisplayName(daylightShortDisplayName: number) {
        this.daylightShortDisplayName = daylightShortDisplayName;
    }
    getStandardLongDisplayName(): number {
        return this.standardLongDisplayName;
    }

    setStandardLongDisplayName(standardLongDisplayName: number) {
        this.standardLongDisplayName = standardLongDisplayName;
    }
    getDaylightLongDisplayName(): number {
        return this.daylightLongDisplayName;
    }

    setDaylightLongDisplayName(daylightLongDisplayName: number) {
        this.daylightLongDisplayName = daylightLongDisplayName;
    }
}
