import { Message } from '../../message';

export class PlayAllowedDays extends Message {
    private MESSAGE_NAME: string = 'PlayAllowedDays';
    private optionalDaysLeft: number = 0;
    private madatoryDaysLeft: number = 0;
    private isMandatory: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, optionalDaysLeft: number = 0, madatoryDaysLeft: number = 0, isMandatory: boolean | null = false) {
        super(reqServerPeerId);
        this.optionalDaysLeft = optionalDaysLeft;
        this.madatoryDaysLeft = madatoryDaysLeft;
        this.isMandatory = isMandatory;
    }

    getOptionalDaysLeft(): number {
        return this.optionalDaysLeft;
    }

    setOptionalDaysLeft(optionalDaysLeft: number) {
        this.optionalDaysLeft = optionalDaysLeft;
    }
    getMadatoryDaysLeft(): number {
        return this.madatoryDaysLeft;
    }

    setMadatoryDaysLeft(madatoryDaysLeft: number) {
        this.madatoryDaysLeft = madatoryDaysLeft;
    }
    getIsMandatory(): boolean | null {
        return this.isMandatory;
    }

    setIsMandatory(isMandatory: boolean | null) {
        this.isMandatory = isMandatory;
    }
}
