import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'poker-publicpage-breadcrumb',
    templateUrl: './public-page-breadcrumb.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class PublicPageBreadcrumbComponent extends PCComponent implements OnInit {
    finalBreadcrumbs: any = [];
    nItems: any = [];
    navigationItems: any;
    breadcrumbs: any;

    finalSchema: any = [];
    scriptSchema: any = [];
    jsonLdSchema: any;

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    constructor(private sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit() {
        if (this.item.navigationItems && this.item.navigationItems.length > 0) {
            this.nItems['children'] = this.item.navigationItems;

            const home = {
                text: this.item.titleLink.text,
                url: this.item.titleLink.url,
            };

            this.nItems['text'] = home.text;
            this.nItems['url'] = home.url;

            if (this.nItems != null && this.nItems.children.length > 0) {
                this.searchAndFetchUrlAndText();
                this.jsonLDInit(this.breadcrumbs);
            }
        }
    }

    private searchAndFetchUrlAndText() {
        const currentUrl: string = window.location.href;

        if (currentUrl != null && currentUrl.length > 0) {
            const items: any = this.nItems;
            this.breadcrumbs = this.getBreadcrumb(items, currentUrl, []);
            // if (this.breadcrumbs) {
            //     console.log(this.breadcrumbs);
            // }
            // else {
            //     console.log("*** Path not found. ***");
            // }
        }
    }

    private getBreadcrumb(navItems: any, currentUrl: string, finalBreadcrumbs: any = {}): any {
        if (!finalBreadcrumbs) {
            return null;
        }

        if (currentUrl === navItems.url) {
            const breadcrumb: any = {
                text: navItems.text,
                url: navItems.url,
            };
            finalBreadcrumbs.push(breadcrumb);
            return finalBreadcrumbs;
        } else {
            if (!navItems.children) {
                return null;
            } else {
                const currentBreadcrumb: any = {
                    text: navItems.text,
                    url: navItems.url,
                };
                finalBreadcrumbs.push(currentBreadcrumb);
                for (let i = 0; i < navItems.children.length; i++) {
                    const element = navItems.children[i];
                    const bc: any = this.getBreadcrumb(element, currentUrl, finalBreadcrumbs.slice());
                    if (bc) {
                        return bc;
                    }
                }
            }
        }
    }

    //Json-LD Implementation
    jsonLDInit(breadcrumbObject: any) {
        for (let i = 0; i < breadcrumbObject.length; i++) {
            const tempSchemaData = {};
            if (i != breadcrumbObject.length - 1) {
                tempSchemaData['@type'] = 'ListItem';
                tempSchemaData['position'] = i + 1;
                tempSchemaData['name'] = breadcrumbObject[i].text;
                tempSchemaData['item'] = breadcrumbObject[i].url;
                this.finalSchema.push(tempSchemaData);
            } else {
                tempSchemaData['@type'] = 'ListItem';
                tempSchemaData['position'] = i + 1;
                tempSchemaData['name'] = breadcrumbObject[i].text;
                this.finalSchema.push(tempSchemaData);
            }
        }

        this.scriptSchema = JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': this.finalSchema,
        });

        this.jsonLdSchema = this.getSafeHtml(this.scriptSchema);
    }

    getSafeHtml(jsonValue: any) {
        const html = `<script type="application/ld+json">${jsonValue}</script>`;
        const schema = this.sanitizer.bypassSecurityTrustHtml(html);
        return schema;
    }
}
