import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

import { LoadingIndicatorService, NativeAppService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import moment from 'moment';
import 'moment/locale/en-in';

import { FilterEventSchedulesPipe } from './filter-schedules.pipe';

moment.locale('en-in');

@Component({
    selector: 'ppl-event-schedule',
    templateUrl: 'ppl-event-schedule.component.html',
    standalone: true,
    imports: [CommonModule, MatExpansionModule, FormsModule],
})
export class PPLEventScheduleComponent extends PCComponent implements OnInit {
    static readonly CCBPPLSCHEDULEPRINT = 'CCB_PPL_SCHEDULE_PRINT';
    eventSchedules: any;
    result: any = [];

    ffSchedules: any = [];
    ffSchedulesKeys: any = [];

    scheduleFilters = [
        {
            key: 'eventType',
            options: [
                { key: 'live', value: 'live' },
                { key: 'online', value: 'online' },
            ],
        },
        {
            key: 'buyIn',
            options: [
                { key: 'buyInMedium', value: 'medium' },
                { key: 'buyInHigh', value: 'high' },
                { key: 'buyInHighRoller', value: 'high-roller' },
            ],
        },
    ];
    scheduleTableHeaders: Array<string> = ['time', 'lateReg', 'event', 'buyIn', 'clock', 'chips', 'format', 'register'];

    selectedEventType: Array<string> = [];
    selectedBuyIn: Array<string> = [];
    selectedRank: Array<string> = [];

    showPastEvents: boolean = false;
    showFilters = false;

    constructor(
        private schedulesPipe: FilterEventSchedulesPipe,
        private loadingIndicatorService: LoadingIndicatorService,
        private nativeAppService: NativeAppService,
    ) {
        super();
    }
    ctaLink: any;
    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit(): void {
        this.showCompletedEvents(false);
        this.generateRankFilters(this.item.liveAndOnlineTournaments.scheduleRanks);
        //console.log("CTA LINK ===" + this.item.eventScheduleStaticData.ctaLink);
        this.ctaLink = this.item.eventScheduleStaticData.ctaLink;
    }

    // register click method
    openClient(tournament: any) {
        window.open(this.ctaLink.replace('{{id}}', tournament.id), '_blank');
    }

    returnScheduleTime(scheduleDate: string): string {
        return scheduleDate.split('T')[1].slice(0, 5);
    }

    eventGroupHeader(eventDay: string): string {
        return moment(eventDay).format('dddd, MMMM DD, YYYY');
    }

    groupByKey(array: any, key: any) {
        return array.reduce((arr: any, obj: any) => {
            if (obj[key] === undefined) return arr;
            return Object.assign(arr, {
                [obj[key].split('T')[0]]: (arr[obj[key].split('T')[0]] || []).concat(obj),
            });
        }, {});
    }

    returnSelectedItem(event: any, itemCollection: string) {
        const itemType =
            itemCollection === 'buyIn' ? this.selectedBuyIn : itemCollection === 'eventType' ? this.selectedEventType : this.selectedRank;
        if (event.target.checked) {
            itemType.push(event.target.value);
        } else {
            itemType.splice(itemType.indexOf(event.target.value), 1);
        }
        this.filterSchedules();
    }

    showCompletedEvents(showPastEvents: any) {
        const loadingIndicator = this.loadingIndicatorService.start();
        this.showPastEvents = showPastEvents;
        this.eventSchedules = showPastEvents ? this.item.liveAndOnlineTournaments.pastScheduleData : this.item.liveAndOnlineTournaments.scheduleData;
        this.filterSchedules();
        loadingIndicator.done();
    }

    displayFilters() {
        this.showFilters = !this.showFilters;
    }

    generateRankFilters(ranks: Array<string>) {
        const rankObj = {
            key: 'rank',
            options: [] as any,
        };
        for (const items of ranks) {
            rankObj.options.push({
                key: '#' + items,
                value: items,
            });
        }
        this.scheduleFilters.push(rankObj);
    }

    filterSchedules() {
        const filteredData = this.schedulesPipe.transform(this.eventSchedules, this.selectedEventType, this.selectedBuyIn, this.selectedRank);
        if (filteredData.length !== 0) {
            this.ffSchedules = this.groupByKey(filteredData, 'time');
            this.ffSchedulesKeys = Object.keys(this.ffSchedules);
        } else {
            this.ffSchedules = [];
            this.ffSchedulesKeys = [];
        }
    }

    printWindow() {
        window.print();
        this.nativeAppService.sendToNative({ eventName: PPLEventScheduleComponent.CCBPPLSCHEDULEPRINT });
    }
}
