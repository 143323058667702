import { Message } from '../../message';

export class ResponseWaitList extends Message {
    private MESSAGE_NAME: string = 'ResponseWaitList';
    private tableId: number = 0;
    private position: number = 0;
    private size: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, position: number = 0, size: number = 0) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.position = position;
        this.size = size;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getPosition(): number {
        return this.position;
    }

    setPosition(position: number) {
        this.position = position;
    }
    getSize(): number {
        return this.size;
    }

    setSize(size: number) {
        this.size = size;
    }
}
