import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import serverIdConstants from '../game-config/serverIdConstants.json';
import { uiConfig } from '../game-config/uiconfig';
import { CommunityCardsInfoModel } from '../models/CommunityCardsInfoModel';
import { FastForwardInfoModel } from '../models/FastForwardInfoModel';
import { GameInfoModel } from '../models/GameInfoModel';
import { GameStateModel } from '../models/GameStateModel';
import { PlayerInfoModel } from '../models/PlayerInfoModel';
import { SpinInfoModel } from '../models/SpinInfoModel';
import { ThrowAnimationModel } from '../models/ThrowAnimationModel';
import { UserModel } from '../models/UserModel';
import { ModalPopupViewModel } from '../vms/ModalPopupViewModel';

export class SlotCoreService {
    currentWinIndex = 0;
    secondaryAssetsLoaded = true;
    wildSymbol = 0;
    scatterSymbol = 12;
    gameInfoModel = new GameInfoModel();
    gameStateModel = new GameStateModel();
    modalPopupViewModel = new ModalPopupViewModel();
    userModel = new UserModel();
    throwAnimationModel = new ThrowAnimationModel();
    playerInfoModel = new PlayerInfoModel();
    SpinInfoModel = new SpinInfoModel();
    FastForwardInfoModel = new FastForwardInfoModel();
    communityCardsInfoModel = new CommunityCardsInfoModel();
    eventDispatcherGlobal = new EventDispatcherGlobal();
    isAutoRebuy = false;
    allPlayerStates = [];
    selfEmojiStatus = false;

    constructor() {
        this.eventDispatcherGlobal.addEventListener('onSecondaryAssetsComplete', this.onSecondaryAssetsComplete.bind(this));
    }

    setSelfEmojiStatus = function (status) {
        this.selfEmojiStatus = status;
    };
    getSelfEmojiStatus = function () {
        return this.selfEmojiStatus;
    };

    onSecondaryAssetsComplete = function () {
        this.secondaryAssetsLoaded = true;
    };
    getConnectionStatus = function () {
        return this.gameStateModel.getConnectionStatus();
    };
    setConnectionStatus = function (val) {
        this.gameStateModel.setConnectionStatus(val);
    };
    /*setAccountBalance = function (balance) {
            playerInfoModel.setBalance(balance);
        }*/
    // get feature related objects
    getConnectionStatusMessage = function () {
        //let status = this.getConnectionStatus();
        // switch (status) {
        //   case -1:
        //     return $.__("Establishing The Connection");
        //     break;
        //   case 0:
        //     return $.__("Connecting To Game Server");
        //     break;
        //   case 1:
        //     return $.__("Authenticating the Player");
        //     break;
        //   case 2:
        //     return $.__("Loading Bet Configuration");
        //     break;
        //   case 3:
        //     return $.__("Loading Game configuration");
        //     break;
        //   default:
        //     break;
        // }
    };
    getCurrenyCode = function () {
        return this.userModel.getCurrenyCode();
    };
    setCurrenyCode = function (val) {
        this.userModel.setCurrenyCode(val);
    };
    getLanguageId = function () {
        return this.userModel.getLanguageId();
    };
    getAccountName = function () {
        return this.userModel.getAccountName();
    };
    getLocale = function () {
        return this.userModel.getLocale();
    };
    //Cultural Format Details
    //----------- poker games code ----------
    setJoinTableResponse = function (obj) {
        this.gameInfoModel.setJoinTableResponse(obj);
        this.gameStateModel.GameTableStatus(obj.tableStatus);
        this.playerInfoModel.setMaxNoOfSeats(obj.tableStatus.maxNoOfSeats);
    };
    setPoolAvailabilityResponse = function (obj) {
        this.gameStateModel.setPoolAvailabilityResponse(obj);
        this.playerInfoModel.setMaxNoOfSeats(obj.maxNoOfSeats);
    };
    setGameStatus = function (obj) {
        if (obj.players) {
            this.playerInfoModel.setPlayerData(obj.players);
        }
        this.gameStateModel.setGameStatus(obj);
    };
    getPlayerSeatingData = function () {
        return this.playerInfoModel.getPlayerSeatingData();
    };
    getPlayerStatusDataAtSeat = function (playerId) {
        return this.playerInfoModel.getPlayerStatusDataAtSeat(playerId);
    };
    getPlayerCount = function () {
        return this.gameStateModel.getPlayerCount();
    };
    PlayerStatus = function (obj) {
        this.playerInfoModel.setPlayerData(obj);
    };
    getPlayerStatus = function () {
        return this.playerInfoModel.getPlayerData();
    };
    AddCommunityCards = function (obj) {
        this.communityCardsInfoModel.setCommunityCardsInfo(obj);
    };
    AddPlayer = function (obj) {
        this.playerInfoModel.setAddPlayerData(obj);
        this.gameStateModel.AddPlayer(obj);
    };
    getAddPlayerData = function () {
        return this.gameStateModel.getAddPlayerData();
    };
    CurrentPlayer = function (obj) {
        this.gameStateModel.CurrentPlayer(obj);
        this.playerInfoModel.SetCurrentPlayer(obj);
    };
    DealCards = function (obj) {
        this.playerInfoModel.DealCards(obj);
    };
    getDealCards = function () {
        return this.playerInfoModel.getDealCards();
    };
    Dealer = function (obj) {
        this.gameStateModel.Dealer(obj);
    };
    resetBetVector = function () {
        this.playerInfoModel.resetBetVector();
    };
    setEndTheRound = function (obj) {
        this.gameStateModel.setEndTheRound(obj);
    };
    setRoundCount = function (roundCount) {
        this.gameStateModel.setRoundCount(roundCount);
    };
    NewGame = function (obj) {
        this.gameStateModel.NewGame(obj);
    };
    PotWinners = function (obj) {
        this.playerInfoModel.PotWinners(obj);
    };
    getPlayerWinAmount = function (playerId) {
        return this.playerInfoModel.getPlayerWinAmount(playerId);
    };
    GameTableStatus = function (obj) {
        this.gameStateModel.GameTableStatus(obj);
        this.playerInfoModel.setMaxNoOfSeats(obj.maxNoOfSeats);
    };
    handleRemovePlayer = function (obj) {
        this.playerInfoModel.setRemovePlayer(obj);
        this.gameStateModel.updatePlayerCount();
    };
    getRemovePlayerData = function () {
        return this.playerInfoModel.getRemovePlayerData();
    };
    ReserveSeat = function (obj) {
        this.gameStateModel.ReserveSeat(obj);
    };
    ShowCardsOptions = function (obj) {
        this.gameStateModel.ShowCardsOptions(obj);
    };
    TimeOut = function (obj) {
        this.gameStateModel.TimeOut(obj);
    };
    WaitStatus = function (obj) {
        this.gameStateModel.WaitStatus(obj);
    };
    PlayerDealCards = function (obj) {
        this.gameStateModel.PlayerDealCards(obj);
    };
    Winner = function (obj) {
        this.gameStateModel.Winner(obj);
    };
    WinnerCards = function (obj) {
        this.gameStateModel.WinnerCards(obj);
    };
    getWinnerCards = function () {
        return this.gameStateModel.getWinnerCards();
    };
    SNGJackpotInfo = function (obj) {
        this.SpinInfoModel.SNGJackpotInfo(obj);
    };
    ChangePlayerState = function (obj) {
        this.setAllPlayerStates(obj);
        this.gameStateModel.ChangePlayerState(obj);
    };
    getPlayerStateData = function () {
        return this.gameStateModel.getPlayerStateData();
    };
    setUncalledBetPotNo = function (potNumber) {
        this.gameStateModel.setUncalledBetPotNo(potNumber);
    };
    getUncalledBetPotNo = function () {
        return this.gameStateModel.getUncalledBetPotNo();
    };
    handlePlayerBalance = function (obj) {
        this.playerInfoModel.setPlayerBalance(obj);
    };
    Blind = function (obj) {
        this.playerInfoModel.Blind(obj);
    };
    PostBlind = function (obj) {
        this.playerInfoModel.PostBlind(obj);
    };
    Ante = function (obj) {
        this.playerInfoModel.Ante(obj);
    };
    PostAnte = function (obj) {
        this.playerInfoModel.PostAnte(obj);
    };
    handlePlayerTimeBankBalance = function (obj) {
        this.setTimeBankBalance(obj.availableTimeBank);
    };
    setCardStyle = function (obj) {
        this.gameInfoModel.setCardStyle(obj);
    };
    getCardStyle = function () {
        return this.gameInfoModel.getCardStyle();
    };
    ShowCards = function (obj) {
        this.playerInfoModel.ShowCards(obj);
    };
    ProbabilityOfWinning = function (obj) {
        this.playerInfoModel.ProbabilityOfWinning(obj);
    };
    getProbabilityOfWinning = function () {
        return this.playerInfoModel.getProbabilityOfWinning();
    };
    HighlightWinCards = function (obj) {
        this.gameStateModel.HighlightWinCards(obj);
    };
    ShowCongrats = function (obj) {
        this.gameStateModel.ShowCongrats(obj);
    };
    RequestEndOfTheGame = function () {
        this.gameStateModel.resetDataModel();
        this.playerInfoModel.resetDataModel();
        this.gameInfoModel.resetDataModel();
        this.userModel.resetDataModel();
        this.communityCardsInfoModel.resetDataModel();
        this.SpinInfoModel.resetDataModel();
        this.FastForwardInfoModel.resetDataModel();
        this.allPlayerStates = [];
        //	  gameResultModel.resetDataModel();
        //dealerInfoModel.resetDataModel();
    };
    setSNGJPPayoutAnimation = function (obj) {
        this.SpinInfoModel.setSNGJPPayoutAnimation(obj);
        //this.SNGJPPayoutData = obj;
    };
    setSNGJPTournamentRankInfo = function (obj) {
        this.SpinInfoModel.setSNGJPTournamentRankInfo(obj);
    };
    getSNGJPTournamentRankInfo = function () {
        return this.SpinInfoModel.getSNGJPTournamentRankInfo();
    };
    setSNGJPGameAboutToStart = function (obj) {
        this.SpinInfoModel.setSNGJPGameAboutToStart(obj);
    };
    HandleAutoOptionSet = function (obj) {
        this.gameInfoModel.HandleAutoOptionSet(obj);
    };
    HandleShowHUPairTable = function (obj) {
        this.gameInfoModel.HandleShowHUPairTable(obj);
        this.setOppPlayerName(obj.opponetName);
    };
    HandleShowCardsOptions = function (obj) {
        this.gameInfoModel.HandleShowCardsOptions(obj);
    };
    HandleMuckCards = function (obj) {
        this.gameInfoModel.HandleMuckCards(obj);
    };
    HandleOptionSet = function (obj) {
        this.gameInfoModel.HandleOptionSet(obj);
        this.setTimeBankBalance(obj.timeBankAvailableTime);
    };
    getGeneratedAt = function () {
        return this.gameInfoModel.getGeneratedAt();
    };
    getTimeoutPeriod = function () {
        return this.gameInfoModel.getTimeoutPeriod();
    };
    HandleOtherPlayerSelectedOption = function (obj) {
        this.playerInfoModel.HandleOtherPlayerSelectedOption(obj);
        this.gameStateModel.setTimeOut(null);
    };
    HandleOption = function (obj) {
        this.gameInfoModel.HandleOption(obj);
    };
    setSelectedBetOption = function (optionId) {
        this.selectedBetOption = optionId;
    };
    setAutoSelectedBetOption = function (autoOptionData) {
        //debugger;
        this.gameInfoModel.setAutoSelectedBetOption(autoOptionData);
    };
    setPeerId = function (peerId) {
        this.peerId = peerId;
    };
    handleTourneyLevelInfo = function (obj) {
        this.gameInfoModel.HandleTourneyLevelInfo(obj);
    };
    /*updatePlayerdata = function(playerId, seatNo) {
            this.playerInfoModel.updatePlayerdata(playerId, seatNo);
        }*/
    /*------------------getters--------------*/
    getEndTheRoundData = function () {
        return this.gameStateModel.getEndTheRoundData();
    };
    getSNGJPPayoutAnimation = function () {
        return this.SpinInfoModel.getSNGJPPayoutAnimation();
    };
    getPlayerSeatNo = function (playerId) {
        return this.playerInfoModel.getPlayerSeatNo(playerId);
    };
    getPeerId = function () {
        return this.peerId;
    };
    getNextleavelData = function () {
        return this.gameInfoModel.getNextleavelData();
    };
    getHighStake = function () {
        return this.gameInfoModel.getHighStake();
    };
    getLowStake = function () {
        return this.gameInfoModel.getLowStake();
    };
    getsetSNGJPGameAboutToStart = function () {
        return this.SpinInfoModel.getsetSNGJPGameAboutToStart();
    };
    getOptionSetData = function () {
        return this.gameInfoModel.getOptionSetData();
    };
    setOptionSetData = function (obj) {
        this.gameInfoModel.setOptionSetData(obj);
    };
    getAutoOptionSetData = function () {
        return this.gameInfoModel.getAutoOptionSetData();
    };
    setShowCardsOptionsData = function (obj) {
        this.gameInfoModel.setShowCardsOptionsData(obj);
    };
    getShowCardsOptionsData = function () {
        return this.gameInfoModel.getShowCardsOptionsData();
    };
    getMuckCardsData = function () {
        return this.gameInfoModel.getMuckCardsData();
    };
    getUserBalance = function (playerId) {
        const playerSeatNo = this.playerInfoModel.getPlayerSeatNo(playerId);
        return this.playerInfoModel.getPlayerBalance(playerSeatNo);
    };
    getUserBalanceAtSeat = function (serverSeatNo) {
        return this.playerInfoModel.getPlayerBalance(serverSeatNo);
    };
    getSmallBlind = function () {
        return this.gameStateModel.getSmallBlind();
    };
    getBigBlind = function () {
        return this.gameStateModel.getBigBlind();
    };
    getTableCategory = function () {
        return this.gameStateModel.getTableCategory();
    };
    getCommunityCardsInfo = function () {
        return this.communityCardsInfoModel.getCommunityCardsInfo();
    };
    getOptionSetId = function () {
        return this.gameInfoModel.getOptionSetId();
    };
    getAutoOptionSetId = function () {
        return this.gameInfoModel.getAutoOptionSetId();
    };
    getShowCardsOptionsId = function () {
        return this.gameInfoModel.getShowCardsOptionsId;
    };
    getMuckCardsId = function () {
        return this.gameInfoModel.getMuckCardsId;
    };
    getSelectedBetOption = function () {
        return this.selectedBetOption;
    };
    getAutoSelectedBetOption = function () {
        return this.gameInfoModel.getAutoSelectedBetOption();
    };
    getTableStatusData = function () {
        return this.gameStateModel.getTableStatusData();
    };
    HandleThrowAnimation = function (obj) {
        this.throwAnimationModel.setThrowAnimationData(obj);
    };
    setReqThrowAnimationData = function (obj) {
        this.throwAnimationModel.setReqThrowAnimationData(obj);
    };
    getReqThrowAnimationData = function () {
        return this.throwAnimationModel.getReqThrowAnimationData();
    };
    resetReqThrowAnimationData = function () {
        return this.throwAnimationModel.resetReqThrowAnimationData();
    };
    getTimeBankData = function () {
        return this.playerInfoModel.getTimeBankData();
    };
    setTimeBankData = function (obj) {
        this.playerInfoModel.setTimeBankData(obj);
    };
    getTimeBankBalance = function () {
        return this.playerInfoModel.getTimeBankBalance();
    };
    setTimeBankBalance = function (timeBankBalance) {
        this.playerInfoModel.setTimeBankBalance(timeBankBalance);
    };
    handlePoolSitOutNotification = function () {};
    handleCloseTable = function () {};
    handleSelfEmoji = function (obj) {
        this.playerInfoModel.setBroadcastDetails(obj);
    };
    getBroadcastDetails = function () {
        return this.playerInfoModel.getBroadcastDetails();
    };
    handleHandStrength = function (obj) {
        this.playerInfoModel.setHandStrength(obj);
    };
    handleShowRabbit = function (obj) {
        this.gameInfoModel.setShowRabbitDetails(obj);
    };
    getShowRabbitDetails = function () {
        return this.gameInfoModel.getShowRabbitDetails();
    };
    getHandStrengthData = function () {
        return this.playerInfoModel.getHandStrength();
    };
    getThrowAnimationData = function () {
        return this.throwAnimationModel.getThrowAnimationData();
    };
    HandleResThrowAnimation = function () {
        // if(obj.status){
        // 	//this.setUserPromoCurrency(obj.promoCurrBalance);
        // else{
        // 	//OOPS something wen't wrong, your request for throw animation failed.
        // }
    };
    handleDiamondBalance = function (obj) {
        this.setDiamondBalance(obj.currPromoCurrBalance);
    };
    handleBroadcastShowRabbit = function (obj) {
        this.gameInfoModel.setBroadcastRabbitDetails(obj);
    };
    getBroadcastShowRabbit = function () {
        return this.gameInfoModel.getBroadcastRabbitDetails();
    };
    getOtherPlayerSelectedOption = function () {
        return this.playerInfoModel.getOtherPlayerSelectedOption();
    };
    getPotWinnersData = function () {
        return this.playerInfoModel.getPotWinnersData();
    };
    getCurrentPotNumber = function () {
        return this.playerInfoModel.getCurrentPotNumber();
    };
    getCurrentTimerStatus = function () {
        return this?.gameStateModel.getCurrentTimerStatus();
    };
    getShowCardsData = function () {
        return this.playerInfoModel.getShowCardsData();
    };
    getDealerSeatNo = function () {
        return this.gameStateModel.getDealerSeatNo();
    };
    getBlidData = function () {
        return this.playerInfoModel.getBlidData();
    };
    getAnteData = function () {
        return this.playerInfoModel.getAnteData();
    };
    getCurrentGameId = function () {
        return this.gameStateModel.getCurrentGameId();
    };
    setCurrentGameId = function (gameId) {
        this.gameStateModel.setCurrentGameId(gameId);
    };
    getPrevHandId = function () {
        return this.gameStateModel.getPrevHandId();
    };
    getClientSideSeatNo = function (serverSeatPos) {
        if (uiConfig['tableConfig' + this.getPeerId()].playerSeatInx) {
            let clientSeatNo = uiConfig['tableConfig' + this.getPeerId()].playerSeatInx[serverSeatPos];
            if (clientSeatNo == 0 || clientSeatNo) {
                return clientSeatNo;
            } else {
                const tempArr = Object.values(uiConfig['tableConfig' + this.getPeerId()].playerSeatInx);
                for (let i: any = 0; i < this.getMaxNoOfSeats(); i++) {
                    // if ($.inArray(parseInt(i), tempArr) == -1) {
                    //   clientSeatNo = i;
                    // }
                    if (tempArr.indexOf(parseInt(i)) === -1) {
                        clientSeatNo = i;
                    }
                }
                return clientSeatNo;
            }
        } else {
            return serverSeatPos;
        }
    };
    getServerSideSeatPos = function (clientSeatPos) {
        if (uiConfig['tableConfig' + this.getPeerId()].playerSeatInx) {
            const sValues = Object.values(uiConfig['tableConfig' + this.getPeerId()].playerSeatInx);
            const sKeys = Object.keys(uiConfig['tableConfig' + this.getPeerId()].playerSeatInx);
            return sKeys[sValues.indexOf(clientSeatPos)];
        } else {
            return clientSeatPos;
        }
    };
    getPlayerBalances = function () {
        return this.playerInfoModel.getPlayerBalances();
    };
    setEnableTimeBankBeforeFlop = function (bEnableTimeBankBeforeFlop) {
        this.playerInfoModel.setEnableTimeBankBeforeFlop(bEnableTimeBankBeforeFlop);
    };
    getEnableTimeBankBeforeFlop = function () {
        return this.playerInfoModel.getEnableTimeBankBeforeFlop();
    };
    setCurrentShowingOption = function (showingOption) {
        this.gameStateModel.setCurrentShowingOption(showingOption);
    };
    getCurrentShowingOption = function () {
        return this.gameStateModel.getCurrentShowingOption();
    };
    setDiamondBalance = function (userPromoCurrency) {
        this.playerInfoModel.setDiamondBalance(userPromoCurrency);
    };
    getDiamondBalance = function () {
        return this.playerInfoModel.getDiamondBalance();
    };
    getSngJackpotID = function () {
        return this.SpinInfoModel.getSngJackpotID();
    };
    getSngJPInstanceID = function () {
        return this.SpinInfoModel.getSngJPInstanceID();
    };
    getMaxRaiseAllInStatus = function () {
        return this.gameInfoModel.getMaxRaiseAllInStatus();
    };
    getMaxRaiseLimit = function () {
        return this.gameInfoModel.getMaxRaiseLimit();
    };
    getBetRaiseAmount = function () {
        return this.gameInfoModel.getBetRaiseAmount();
    };
    getSngJPPayoutInfo = function () {
        return this.SpinInfoModel.getSngJPPayoutInfo();
    };
    getGameTypeId = function () {
        return this.gameStateModel.getGameTypeId();
    };
    setSettingsData = function () {
        //#ToBeChecked
    };
    getSettingsData = function () {
        return this.gameInfoModel.getSettingsData();
        //#ToBeChecked
    };
    clearUserModels = function () {
        this.gameInfoModel = null;
        this.playerInfoModel = null;
        //gameResultModel = null;
        this.gameStateModel = null;
        this.modalPopupViewModel = null;
        this.userModel = null;
        this.throwAnimationModel = null;
        //dealerInfoModel = null;
        this.communityCardsInfoModel = null;
    };
    getAddPopUpData = function () {
        return this.gameInfoModel.getAddPopUpData();
    };
    setAddPopUpData = function (obj) {
        this.gameInfoModel.setAddPopUpData(obj);
    };
    getRoundCount = function () {
        return this.gameStateModel.getRoundCount();
    };
    getTableLimitType = function () {
        return this.gameStateModel.getTableLimitType();
    };
    setAutoCheckEnabled = function (autoCheckEnabled) {
        this.gameStateModel.setAutoCheckEnabled(autoCheckEnabled);
    };
    getAutoCheckEnabled = function () {
        return this.gameStateModel.getAutoCheckEnabled();
    };
    getBetVector = function (serverSeatNo) {
        return this.playerInfoModel.getBetVector(serverSeatNo);
    };
    getTourneyGameStatus = function () {
        return this.gameStateModel.getTourneyGameStatus();
    };
    getTableBgType = function () {
        return this.SpinInfoModel.getTableBgType();
    };
    getSpinCardType = function () {
        return this.SpinInfoModel.getSpinCardType();
    };
    getPots = function () {
        return this.gameStateModel.getPots();
    };
    getMtctId = function () {
        return this.gameInfoModel.getMtctId();
    };
    getIsTourneyStarted = function () {
        const tourneyGameStatus = this.getTourneyGameStatus();
        return tourneyGameStatus && tourneyGameStatus != serverIdConstants.TOURNEY_STATUS_WAITING_FOR_MORE_PLAYERS;
    };
    getIsSeatReserved = function (seatNo) {
        return this.playerInfoModel.getIsSeatReserved(seatNo);
    };
    getIsSeatOccupied = function (seatNo) {
        return this.playerInfoModel.getIsSeatOccupied(seatNo);
    };
    getSeatNoToBetAmtMap = function () {
        const seatNoToBetAmtMap = {};
        for (let seatNo = 0; seatNo < this.getMaxNoOfSeats(); seatNo++) {
            const betAmt = this.getBetVector(seatNo);
            if (betAmt) seatNoToBetAmtMap[seatNo] = betAmt;
        }
        return seatNoToBetAmtMap;
    };
    getGameCurrency = function () {
        return this.gameStateModel.getGameCurrency();
    };
    getIsTableCreated = function () {
        return this.gameStateModel.getIsTableCreated();
    };
    setIsTableCreated = function (obj) {
        return this.gameStateModel.setIsTableCreated(obj);
    };
    getCommunityCardsArray = function () {
        return this.communityCardsInfoModel.getCommunityCardsArray();
    };
    getMaxNoOfSeats = function () {
        const val = this.gameStateModel.getMaxNoOfSeats();
        return val;
    };
    getSeatAlignment = function (clientSeatNo) {
        if (clientSeatNo == uiConfig.selfUserClientPosInd) return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
        const centerSeatNo = this.getMaxNoOfSeats() / 2;
        if (centerSeatNo == clientSeatNo) return serverIdConstants.SEAT_ALIGNMENT_TOP;
        return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    };
    // for ff or spins game
    setGameTableType = function (data) {
        if (data && data.entryIds && data.entryIds.length > 0) {
            // for FF
            this.gTableType = serverIdConstants.TABLE_TYPE_FF;
        } else {
            if (this.getTableCategory() == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
                if (this.gameStateModel.getTournamentCategory() == serverIdConstants.TOURNAMENT_CATEGORY_SNGJP)
                    this.gTableType = serverIdConstants.TABLE_TYPE_SPINS;
                else this.gTableType = serverIdConstants.TABLE_TYPE_TOURNAMENT;
            } else this.gTableType = serverIdConstants.TABLE_TYPE_CASH;
        }
    };
    getGameTableType = function () {
        return this.gTableType;
    };
    /* below method for displaying Koth view , opponent Name for Cash games */
    setHuPairTableStatusVal = function (boolVal) {
        this.gameInfoModel.setHuPairTableStatusVal(boolVal);
    };
    getHuPairTableStatusVal = function () {
        return this.gameInfoModel.getHuPairTableStatusVal();
    };
    setShowHuPairTableStatus = function (data) {
        this.showHUPairTableStatus = data.className;
    };
    getShowHuPairTableStatus = function () {
        return this.showHUPairTableStatus;
    };
    setHUEvenGame = function (data) {
        this.checkHUEvenGame = data.evenGame;
        this.gameInfoModel.setHUEvenGame(this.checkHUEvenGame);
    };
    getHUEvenGame = function () {
        return this.gameInfoModel.getHUEvenGame();
    };
    setOppPlayerName = function (data) {
        this.kothOppPlayerName = data.opponetName;
    };
    getOppPlayerName = function () {
        return this.kothOppPlayerName;
    };
    setHUPairTableSelectStatus = function () {
        // to-d0
    };
    getHUPairTableSelectStatus = function () {
        //to-do
    };
    /* below method for rebuystatus for FF games
          params{boolean}
      */
    setAutoRebuyStatus = function (val) {
        this.isAutoRebuy = val;
    };
    getAutoRebuyStatus = function () {
        return this.isAutoRebuy;
    };
    handleTableBoosterTriggered = function (data) {
        // if (!data) {
        //   data = { "boosterType": 2, "fromBB": 4, "toBB": 9, "gameId": 15983447326, "msgNumber": 0, "peerId": 10662532, "className": "TableBoosterTriggered" };
        // }
        // console.log('TableBoosterTriggered', data);
        this.gameInfoModel.setTableBoosterTriggered(data);
    };
    handleBroadcastTableBoosterTrigger = function (data) {
        // if (!data) {
        //   data = { "boosterType": 2, "fromBB": 4, "toBB": 9, "gameId": 15983447326, "tableId": 10662532, "msgNumber": 0, "className": "BroadcastTableBoosterTrigger" };
        // }
        // console.log('BroadcastTableBoosterTrigger', data);
        this.gameInfoModel.setBroadcastTableBoosterTrigger(data);
    };
    handleTableBoosterWinners = function (data) {
        // if (!data) {
        //   data = { "gameId": 15983447327, "boosterType": 2, "boosterMultiplyValue": 7, "boosterWinners": [], "msgNumber": 0, "peerId": 10662533, "className": "TableBoosterWinners" };
        //   console.log('TableBoosterWinners', data);
        // }
        this.gameInfoModel.setTableBoosterWinners(data);
    };
    getTableBoosterTriggered = function () {
        return this.gameInfoModel.getTableBoosterTriggered();
    };
    getBroadcastTableBoosterTrigger = function () {
        return this.gameInfoModel.getBroadcastTableBoosterTrigger();
    };
    getTableBoosterWinners = function () {
        return this.gameInfoModel.getTableBoosterWinners();
    };
    setPlayersCashoutEquityDetails = function (data) {
        this.gameInfoModel.setPlayersCashoutEquityDetails(data);
    };
    setRemovedPlayersData = function (obj) {
        this.gameInfoModel.setRemovedPlayersData(obj);
    };
    getRemovedPlayersData = function () {
        return this.gameInfoModel.getRemovedPlayersData();
    };
    setplayerStates = function (obj) {
        this.gameInfoModel.setplayerStates(obj);
    };
    getplayerStates = function () {
        return this.gameInfoModel.getplayerStates();
    };
    setAllPlayerStates = function (obj) {
        this.allPlayerStates[obj.seatNo] = obj.state;
    };
    getAllPlayerStates = function () {
        return this.allPlayerStates;
    };
    getPlayersCashoutEquityDetails = function () {
        this.gameInfoModel.getPlayersCashoutEquityDetails();
    };
}
