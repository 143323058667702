import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { HomepageResourceService } from '@pokercore/module/core';

@Component({
    selector: 'homepage-seocontent',
    templateUrl: 'homepage-seocontent.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class HomePageSeocontentComponent implements OnInit {
    seoContentTitle: any;
    seoContent: any;

    constructor(private homePageService: HomepageResourceService) {}
    ngOnInit() {
        const allData = this.homePageService.dynamicContent.viewModel.contentItems;
        for (let i = 0; i < allData.length; i++) {
            if (allData[i].metadata.id.itemName.toLowerCase() === 'seocontent') {
                this.seoContent = allData[i];
                break;
            }
        }
        if (this.seoContent && this.seoContent.text) {
            this.seoContentTitle = this.seoContent.text;
        }
    }
}
