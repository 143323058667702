import { Message } from '../../message';

export class LSTopCardsOrderDeltaUpdate extends Message {
    private MESSAGE_NAME: string = 'LSTopCardsOrderDeltaUpdate';
    private topCardsOrder: any[] | null = null;
    private lobbyType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, topCardsOrder: any[] | null = null, lobbyType: number = 0) {
        super(reqServerPeerId);
        this.topCardsOrder = topCardsOrder;
        this.lobbyType = lobbyType;
    }

    getTopCardsOrder(): any[] | null {
        return this.topCardsOrder;
    }

    setTopCardsOrder(topCardsOrder: any[] | null) {
        this.topCardsOrder = topCardsOrder;
    }
    getLobbyType(): number {
        return this.lobbyType;
    }

    setLobbyType(lobbyType: number) {
        this.lobbyType = lobbyType;
    }
}
