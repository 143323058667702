import { Message } from '../../message';

export class ITMAdditionalInformation extends Message {
    private MESSAGE_NAME: string = 'ITMAdditionalInformation';
    private totalPrizeAmount: number = 0;
    private firstPrizeAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, totalPrizeAmount: number = 0, firstPrizeAmount: number = 0) {
        super(reqServerPeerId);
        this.totalPrizeAmount = totalPrizeAmount;
        this.firstPrizeAmount = firstPrizeAmount;
    }

    getTotalPrizeAmount(): number {
        return this.totalPrizeAmount;
    }

    setTotalPrizeAmount(totalPrizeAmount: number) {
        this.totalPrizeAmount = totalPrizeAmount;
    }
    getFirstPrizeAmount(): number {
        return this.firstPrizeAmount;
    }

    setFirstPrizeAmount(firstPrizeAmount: number) {
        this.firstPrizeAmount = firstPrizeAmount;
    }
}
