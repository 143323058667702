import { QSGAMETYPES, SERVER_EVENTS } from '../constants/app-constants';

export class HelperClassService {
    tabConfigs = {
        RM: {
            FF: [
                320013, 320011, 320010, 320009, 320008, 320007, 320006, 320005, 320004, 320003, 320002, 320001, 330008, 330007, 330006, 330005,
                330004, 330003, 330002, 330001, 320012, 330012, 320015, 330015,
            ],
            CASH: [120000, 150000, 197000, 110000],
            CASH_FF: [321000, 331000],
            SNG: [210035, 210034, 210033, 210032, 210031, 210030, 210029, 210028, 210027, 210026, 210025, 210024, 210023, 210036, 210037],
        },
        PM: {
            FF: [692010],
            SNG: [670004, 670002, 670001],
        },
    };

    constructor() {}

    getTabConfigs(gameType: string) {
        return this.tabConfigs.RM[gameType];
    }
    getLimitType(value) {
        if (value === QSGAMETYPES.LIMIT_TYPE_REGULAR) {
            return 'Fixed Limit';
        } else if (value === QSGAMETYPES.LIMIT_TYPE_NO_LIMIT) {
            return 'No Limit';
        } else {
            return 'Pot Limit';
        }
    }
    getGameTypeIdString(gameTypeId) {
        switch (gameTypeId) {
            case SERVER_EVENTS.GAME_TYPE_NOLIMIT_HOLDEM:
            case SERVER_EVENTS.GAME_TYPE_POTLIMIT_HOLDEM:
            case SERVER_EVENTS.GAME_TYPE_TEXAS_HOLDEM:
            case SERVER_EVENTS.GAME_TYPE_DOUBLE_HOLDEM:
                return 'Hold’em';

            case SERVER_EVENTS.GAME_TYPE_OMAHA_HI:
            case SERVER_EVENTS.GAME_TYPE_OMAHA_HI_LO:
            case QSGAMETYPES.GAME_TYPE_SEVEN_STUD_HI:
            case QSGAMETYPES.GAME_TYPE_SEVEN_STUD_HI_LO:
                return 'Omaha';

            case SERVER_EVENTS.GAME_TYPE_SHORT_DECK:
                return 'Short Deck';
            default:
                return;
        }
    }
}
