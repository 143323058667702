import { Message } from '../../message';

export class LSTourneyLobbyStackInfo extends Message {
    private MESSAGE_NAME: string = 'LSTourneyLobbyStackInfo';
    private mtctId: number = 0;
    private largestStack: number = 0;
    private smallestStack: number = 0;
    private averageStack: number = 0;
    private chipsInPlay: number = 0;
    private totalTablesCount: number = 0;
    private watchersCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        largestStack: number = 0,
        smallestStack: number = 0,
        averageStack: number = 0,
        chipsInPlay: number = 0,
        totalTablesCount: number = 0,
        watchersCount: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.largestStack = largestStack;
        this.smallestStack = smallestStack;
        this.averageStack = averageStack;
        this.chipsInPlay = chipsInPlay;
        this.totalTablesCount = totalTablesCount;
        this.watchersCount = watchersCount;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getLargestStack(): number {
        return this.largestStack;
    }

    setLargestStack(largestStack: number) {
        this.largestStack = largestStack;
    }
    getSmallestStack(): number {
        return this.smallestStack;
    }

    setSmallestStack(smallestStack: number) {
        this.smallestStack = smallestStack;
    }
    getAverageStack(): number {
        return this.averageStack;
    }

    setAverageStack(averageStack: number) {
        this.averageStack = averageStack;
    }
    getChipsInPlay(): number {
        return this.chipsInPlay;
    }

    setChipsInPlay(chipsInPlay: number) {
        this.chipsInPlay = chipsInPlay;
    }
    getTotalTablesCount(): number {
        return this.totalTablesCount;
    }

    setTotalTablesCount(totalTablesCount: number) {
        this.totalTablesCount = totalTablesCount;
    }
    getWatchersCount(): number {
        return this.watchersCount;
    }

    setWatchersCount(watchersCount: number) {
        this.watchersCount = watchersCount;
    }
}
