import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

const inDealingTurnnext: boolean = false;
let inDealingTurnprev: boolean = false;
let makeLastPotFalse: boolean = false;
export class DealingTurn extends TableEventInfo {
    isuncalledBetFuncAlreadyCalledinTurn: boolean = false;
    getEventType(): TableEvents {
        return TableEvents.DEALING_TURN;
    }
    getPlayerStatus(): any {
        return PlayerStatus.DEALING_TURN;
    }

    next(gameTable: GameTable): void {
        gameTable.presentBettingRound = '1';
        gameTable.currentBettingRound = gameTable.currentBettingRound + 1;

        if (gameTable.isRit && !gameTable.isMobile) {
            if (gameTable.totalNumberOfCommunityCards === 7) {
                gameTable.players.forEach((player: any) => {
                    player.showHoleCards();
                    gameTable.isRitEnabled = true;
                });
            }
            if (gameTable.isRitEnabled) {
                const potPosition = Properties.propFile[gameTable.gameType]['RITMAINPOTCHIPPOS'].split('#');
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                gameTable.playersMap.forEach((player: any, seat: any) => {
                    gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
                });
                if (gameTable.unCalledFlag === 0 && !gameTable.uncalledBetGivenBacktoPlayer) {
                    let playerSeat: any;
                    if (gameTable.isReplayerV3) {
                        playerSeat = Properties.seatMap.get(gameTable.uncalledBetPostedPlayer.seat);
                        // if (gameTable.isUnCalledBet) {
                        gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmount;
                        // }
                    } else {
                        playerSeat = gameTable.uncalledBetPostedPlayer.seat;
                    }
                    const p: any = gameTable.getPlayer(playerSeat);
                    p.isPlayerAllIn = false;
                    p.chips = new Chip(Number(p.chips.value) + Number(gameTable.unCalledBetAmount));
                    if (p.chips.value === 0) {
                        p.isPlayerAllIn = true;
                    }
                    gameTable.uncalledBetGivenBacktoPlayer = true;
                }
            } else {
                const potPosition = Properties.propFile[gameTable.gameType]['MAINPOTCHIPPOS'].split('#');
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                gameTable.playersMap.forEach((player: any, seat: any) => {
                    gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
                });
            }
        } else {
            const potPosition = Properties.propFile[gameTable.gameType]['MAINPOTCHIPPOS'].split('#');
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            gameTable.playersMap.forEach((player: any, seat: any) => {
                gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
            });
        }

        gameTable.mpot = false;
        if (!gameTable.onInit) {
            gameTable.accumulateChip(this.potCreated, true);
        }
        const mpot = gameTable.moveToPot();

        const eventMap = gameTable.eventMap;
        let playerMap: any;

        if (!playerMap) {
            playerMap = new Map<string, Chip>();
            eventMap.set(this.getPlayerStatus(), playerMap);
        }

        gameTable.players.forEach((p: any) => {
            playerMap.set(p.name, p.toPot);
        });

        const extraChip = this.getPlayerExtraChips(gameTable, playerMap);
        console.log(extraChip);
        if (gameTable.mainPotFreezed) {
            gameTable.MAIN_POT.totalValue = gameTable.mainPotLockedValue;
        } else {
            gameTable.MAIN_POT.totalValue = gameTable.ROUND_POT.totalValue;
        }
        gameTable.roundObjects['1'] = gameTable.MAIN_POT.totalValue;
        if (gameTable.lastLockedPotAmount > 0) {
            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 2].activePotNumber - 1].totalValue =
                gameTable.lastLockedPotAmount;
        }
        if (gameTable.onInit) {
            if (gameTable.isUnCalledBet) {
                this.unCalledBetAmountforNext(gameTable);
                this.isuncalledBetFuncAlreadyCalledinTurn = false;
                inDealingTurnprev = false;
            }
        }

        if (mpot && !gameTable.onInit) {
            gameTable.enableTableMask = true;
            if (!inDealingTurnnext && gameTable.isUnCalledBet) {
                this.unCalledBetAmountforNext(gameTable);
            }
            setTimeout(() => {
                gameTable.playersMap.forEach((player: any) => {
                    player.toPot = new Chip(0);
                });
                gameTable.accumulateChip(this.potCreated, false);
                gameTable.enableTableMask = false;
                if (!gameTable.onInit) {
                    gameTable.changeChip = true;
                }
                if (!gameTable.isReplayerV3) {
                    if (gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]) {
                        if (
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].totalLockedPots === 0 &&
                            gameTable.SIDE_POTS.length > 0 &&
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                        ) {
                            if (
                                gameTable.isUnCalledBet &&
                                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit !=
                                    gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotAmount.unit
                            ) {
                                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                            }
                            if (!gameTable.isUnCalledBet) {
                                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                            }
                        }
                    }
                }

                gameTable.SIDE_POTS.forEach((pot) => {
                    if (pot.totalValue > 0) {
                        if (
                            gameTable.valueUpdatedPotId >= 0 &&
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId] &&
                            gameTable.potCreatedObject.hasOwnProperty(gameTable.currentBettingRound - 1)
                        ) {
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId].totalValue =
                                gameTable.potCreatedObject[gameTable.currentBettingRound - 1];
                        }
                        pot.showPot = true;
                    } else if (pot.totalValue == 0 && gameTable.isReplayerV3) {
                        gameTable.SIDE_POTS.pop();
                    }
                });
                if (gameTable.isReplayerV3 && makeLastPotFalse && gameTable.SIDE_POTS.length > 0) {
                    const sidepotValue = gameTable.SIDE_POTS.length;
                    gameTable.SIDE_POTS[sidepotValue - 1].showPot = false;
                }
            }, gameTable.gameSpeedBarSetter);
        } else {
            gameTable.playersMap.forEach((player: any) => {
                player.toPot = new Chip(0);
            });
        }
        gameTable.lastLockedPotAmount = 0;
        let crossCheck: number = 0;
        if (gameTable.isReplayerV3) {
            gameTable.SIDE_POTS.forEach((pot) => {
                crossCheck = crossCheck + pot.totalValue;
            });
            gameTable.existingpotTotalValue = crossCheck;
            gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;
        }
        gameTable.lastLockedPotAmount = 0;

        if (gameTable.isReplayerV3 && gameTable.currentActivePot <= 0 && gameTable.SIDE_POTS.length > 0) {
            const sidepotValue = gameTable.SIDE_POTS.length;
            gameTable.currentActivePot = gameTable.SIDE_POTS[sidepotValue - 1].totalValue;
            makeLastPotFalse = true;
        }

        if (gameTable.cashOutOffered) {
            gameTable.CashedOutPopUpinTurn = true;
            gameTable.CashedOutPopUp = true;
            gameTable.cashOutOffered = false;
        }
    }

    prev(gameTable: GameTable): void {
        gameTable.existingpotTotalValue = 0;
        gameTable.currentBettingRound = gameTable.currentBettingRound - 1;
        gameTable.mpot = false;
        gameTable.lastLockedPotAmount = 0;
        this.getPlrReverseExtraChips(gameTable);

        if (gameTable.isRit && gameTable.totalNumberOfCommunityCards === 7) {
            gameTable.players.forEach((player: any) => {
                if (!player.isPlayerRequesting) {
                    player.hideHolecards();
                } else {
                    player.showHoleCards();
                }
            });
        }

        if (gameTable.isRit) {
            if (gameTable.totalNumberOfCommunityCards === 7) {
                gameTable.isRitEnabled = false;
            }
            if (gameTable.isRitEnabled) {
                const potPosition = Properties.propFile[gameTable.gameType]['RITMAINPOTCHIPPOS'].split('#');
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                gameTable.playersMap.forEach((player: any, seat: any) => {
                    gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
                });
            } else {
                const potPosition = Properties.propFile[gameTable.gameType]['MAINPOTCHIPPOS'].split('#');
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                gameTable.playersMap.forEach((player: any, seat: any) => {
                    gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
                });
                if (gameTable.unCalledFlag === 0 && gameTable.uncalledBetGivenBacktoPlayer) {
                    let playerSeat: any;
                    if (gameTable.isReplayerV3) {
                        playerSeat = Properties.seatMap.get(gameTable.uncalledBetPostedPlayer.seat);
                        // if (gameTable.isUnCalledBet) {
                        gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue + gameTable.unCalledBetAmount;
                        // }
                    } else {
                        playerSeat = gameTable.uncalledBetPostedPlayer.seat;
                    }
                    const p: any = gameTable.getPlayer(playerSeat);
                    p.chips = new Chip(Number(p.chips.value) - Number(gameTable.unCalledBetAmount));
                    if (p.chips.value === 0) {
                        p.isPlayerAllIn = true;
                    }
                    gameTable.uncalledBetGivenBacktoPlayer = false;
                }
            }
        }

        const currentBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber;
        const prevBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber;

        for (let i = currentBettingRoundActivePotNumber; i > prevBettingRoundActivePotNumber; i--) {
            if (gameTable.SIDE_POTS.length >= i) {
                gameTable.SIDE_POTS[i - 1].showPot = false;
            }
        }

        if (gameTable.isReplayerV3) {
            gameTable.SIDE_POTS.forEach((pot) => {
                if (pot.totalValue == 0) {
                    gameTable.SIDE_POTS.pop();
                }
                if (pot.showPot) {
                    gameTable.existingpotTotalValue = gameTable.existingpotTotalValue + pot.totalValue;
                }
            });
        }

        if (!gameTable.isReplayerV3) {
            if (
                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
                gameTable.SIDE_POTS.length > 0 &&
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
            ) {
                gameTable.lastLockedPotAmount =
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue;
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
            }

            if (gameTable.gameRoundDetails[gameTable.currentBettingRound]) {
                if (
                    gameTable.gameRoundDetails[gameTable.currentBettingRound].totalLockedPots === 0 &&
                    gameTable.SIDE_POTS.length > 0 &&
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                ) {
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber - 1].totalValue =
                        gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                }
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const dis = this;
        gameTable.playersMap.forEach(function (player: any, seat: any) {
            const potPosition = Properties.propFile[gameTable.gameType][`CHIPPOS${seat}`].split('#');
            player.playerChipPos = new Point(potPosition[0], potPosition[1]);
            gameTable.revertChip(dis.potCreated);
            const event = gameTable.eventMap;
            const t = event.get(dis.getPlayerStatus()) ?? new Map();
            const f = t.get(player.name);
            player.toPot.value = f.value;
        });

        gameTable.players.forEach((p: any) => {
            if (inDealingTurnprev && gameTable.isUnCalledBet) {
                if (gameTable.unCalledBetAmountDetails['accountname'].includes(p.name)) {
                    if (!p.isPlayerAllIn) {
                        p.chips.value = p.chips.value - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                        if (p.chips.value === 0) {
                            p.isPlayerAllIn = true;
                        }
                    } else {
                        p.isPlayerAllIn = true;
                        p.chips.value = 0;
                    }
                    gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    gameTable.isUnCalledAmountMatched = true;
                    // inDealingTurnOnly = false;
                }
            }
        });

        const totalchipvalue = gameTable.roundObjects;
        if (gameTable.roundObjects.hasOwnProperty(0)) {
            gameTable.MAIN_POT.totalValue = totalchipvalue[0];
            gameTable.changeChip = true;
        } else {
            gameTable.MAIN_POT.totalValue = 0;
            gameTable.changeChip = false;
        }

        gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;

        if (gameTable.isReplayerV3 && gameTable.SIDE_POTS.length > 0 && gameTable.currentActivePot <= 0) {
            if (prevBettingRoundActivePotNumber > 0) {
                gameTable.currentActivePot = gameTable.SIDE_POTS[prevBettingRoundActivePotNumber - 1].totalValue;
                gameTable.SIDE_POTS[prevBettingRoundActivePotNumber - 1].showPot = false;
            } else {
                gameTable.currentActivePot = gameTable.SIDE_POTS[prevBettingRoundActivePotNumber].totalValue;
                gameTable.SIDE_POTS[prevBettingRoundActivePotNumber].showPot = false;
            }
        }
        if (gameTable.CashedOutPopUpinTurn) {
            gameTable.CashedOutPopUp = false;
        }
    }

    unCalledBetAmountforNext(gameTable: GameTable) {
        gameTable.players.forEach((p: any) => {
            if (gameTable.unCalledBetAmountDetails['accountname'].includes(p.name) && gameTable.isUnCalledAmountMatched) {
                // console.log("in dealing turn");
                if (!p.isPlayerAllIn) {
                    // if (p.toPot.value === gameTable.unCalledBetAmountDetails["uncalledUnit"].unit) {
                    p.chips.value = p.chips.value + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    // }
                } else {
                    p.isPlayerAllIn = false;
                    p.chips.value = gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    if (p.chips.value === 0) {
                        p.isPlayerAllIn = true;
                    }
                }
                gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                gameTable.isUnCalledAmountMatched = false;
                // isHappeninginDealingTurn = true;
                this.isuncalledBetFuncAlreadyCalledinTurn = true;
                inDealingTurnprev = true;
            } else if (gameTable.unCalledBetAmountDetails['accountname'].includes(p.name) && !gameTable.isUnCalledAmountMatched) {
                if (p.toPot.value === gameTable.unCalledBetAmountDetails['uncalledUnit'].unit) {
                    // console.log(p.toPot.value, "in dealing turn");
                    if (p.chips.value === 0) {
                        p.isPlayerAllIn = true;
                    }
                    p.chips.value = p.chips.value + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    if (p.chips.value != 0) {
                        p.isPlayerAllIn = false;
                    }
                    gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    inDealingTurnprev = true;
                    // gameTable.isUnCalledAmountMatched = true;
                    // console.log("are you calling");
                }
            }
        });
    }
}
