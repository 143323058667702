/* eslint-disable sonarjs/no-identical-functions */
import { TSDataInputStream } from '../../../sal/dataInputOutputStream/TSDataInputStream';
import { TSDataOutputStream } from '../../../sal/dataInputOutputStream/TSDataOutputStream';
import { Message } from '../message';
import { PGMessageFactory } from '../pgmessagefactory';
import { AddFavoriteCard } from './messages/AddFavoriteCard';
import { ChangeTabRequest } from './messages/ChangeTabRequest';
import { DeleteCard } from './messages/DeleteCard';
import { DeleteFavoriteCard } from './messages/DeleteFavoriteCard';
import { FFCard } from './messages/FFCard';
import { FavoriteCards } from './messages/FavoriteCards';
import { LSCurrencyAmount } from './messages/LSCurrencyAmount';
import { LSDealerBlindPositions } from './messages/LSDealerBlindPositions';
import { LSDeletedPoolDetails } from './messages/LSDeletedPoolDetails';
import { LSDeletedPoolInfoDetails } from './messages/LSDeletedPoolInfoDetails';
import { LSDeletedTableDetails } from './messages/LSDeletedTableDetails';
import { LSDeletedTableInfoDetails } from './messages/LSDeletedTableInfoDetails';
import { LSDynamicLanguagePackUpdate } from './messages/LSDynamicLanguagePackUpdate';
import { LSFeaturedTableDetailsDeltaUpdate } from './messages/LSFeaturedTableDetailsDeltaUpdate';
import { LSFlightedData } from './messages/LSFlightedData';
import { LSFlightedNextDayDetails } from './messages/LSFlightedNextDayDetails';
import { LSHeadsUpParticipantStatus } from './messages/LSHeadsUpParticipantStatus';
import { LSHeadsUpTourenyPlayerSequence } from './messages/LSHeadsUpTourenyPlayerSequence';
import { LSItaliaAuthorizationCodes } from './messages/LSItaliaAuthorizationCodes';
import { LSLobbyCardDeltaUpdate } from './messages/LSLobbyCardDeltaUpdate';
import { LSMTCTCustomPropertyChangeEvent } from './messages/LSMTCTCustomPropertyChangeEvent';
import { LSMTCTDeleteEvent } from './messages/LSMTCTDeleteEvent';
import { LSMTCTDetailsDeltaUpdate } from './messages/LSMTCTDetailsDeltaUpdate';
import { LSMTCTEventsQualifiersChangeEvent } from './messages/LSMTCTEventsQualifiersChangeEvent';
import { LSMTCTFirstRankChangeEvent } from './messages/LSMTCTFirstRankChangeEvent';
import { LSMTCTLateRegistrationChangeEvent } from './messages/LSMTCTLateRegistrationChangeEvent';
import { LSMTCTLevelChangeEvent } from './messages/LSMTCTLevelChangeEvent';
import { LSMTCTLobbyData } from './messages/LSMTCTLobbyData';
import { LSMTCTPlayerCountChangeEvent } from './messages/LSMTCTPlayerCountChangeEvent';
import { LSMTCTPrizePoolChangeEvent } from './messages/LSMTCTPrizePoolChangeEvent';
import { LSMTCTRegistrationChangeEvent } from './messages/LSMTCTRegistrationChangeEvent';
import { LSMTCTStartTimeChangeEvent } from './messages/LSMTCTStartTimeChangeEvent';
import { LSMTCTStatusChangeEvent } from './messages/LSMTCTStatusChangeEvent';
import { LSMTCTUnRegistrationCloseChangeEvent } from './messages/LSMTCTUnRegistrationCloseChangeEvent';
import { LSMTCTUpdateChanges } from './messages/LSMTCTUpdateChanges';
import { LSMTCTUpdateMinChanges } from './messages/LSMTCTUpdateMinChanges';
import { LSMixMaxData } from './messages/LSMixMaxData';
import { LSMixMaxEntry } from './messages/LSMixMaxEntry';
import { LSMultiDayChangeData } from './messages/LSMultiDayChangeData';
import { LSMultiDayData } from './messages/LSMultiDayData';
import { LSMultiDayWiseData } from './messages/LSMultiDayWiseData';
import { LSPlayerBalances } from './messages/LSPlayerBalances';
import { LSPlayerInfo } from './messages/LSPlayerInfo';
import { LSPlayerListDeltaUpdate } from './messages/LSPlayerListDeltaUpdate';
import { LSPoolDetails } from './messages/LSPoolDetails';
import { LSPoolDetailsDeltaUpdate } from './messages/LSPoolDetailsDeltaUpdate';
import { LSPoolGameStats } from './messages/LSPoolGameStats';
import { LSPoolInfoDetails } from './messages/LSPoolInfoDetails';
import { LSPoolInfoDetailsDeltaUpdate } from './messages/LSPoolInfoDetailsDeltaUpdate';
import { LSPoolPlayerCount } from './messages/LSPoolPlayerCount';
import { LSPoolPlayerCountDeltaUpdate } from './messages/LSPoolPlayerCountDeltaUpdate';
import { LSPoolPlayerInfo } from './messages/LSPoolPlayerInfo';
import { LSPoolPlayerListDeltaUpdate } from './messages/LSPoolPlayerListDeltaUpdate';
import { LSPoolRemovePlayerInfo } from './messages/LSPoolRemovePlayerInfo';
import { LSPrizeDetailsMessage } from './messages/LSPrizeDetailsMessage';
import { LSReentryInfo } from './messages/LSReentryInfo';
import { LSRemovedPlayerInfo } from './messages/LSRemovedPlayerInfo';
import { LSRequestMTCTId } from './messages/LSRequestMTCTId';
import { LSRequestMTCTIdRes } from './messages/LSRequestMTCTIdRes';
import { LSSNGPromoUpdate } from './messages/LSSNGPromoUpdate';
import { LSSTTTableGameStatus } from './messages/LSSTTTableGameStatus';
import { LSTabIdDynamicLanguagePackDetails } from './messages/LSTabIdDynamicLanguagePackDetails';
import { LSTabIdLastUpdatedTime } from './messages/LSTabIdLastUpdatedTime';
import { LSTabIdMTCTDetails } from './messages/LSTabIdMTCTDetails';
import { LSTabIdPoolDetails } from './messages/LSTabIdPoolDetails';
import { LSTabIdTableDetails } from './messages/LSTabIdTableDetails';
import { LSTabIdTableInfoDeltaUpdate } from './messages/LSTabIdTableInfoDeltaUpdate';
import { LSTabIdTableInfoDetails } from './messages/LSTabIdTableInfoDetails';
import { LSTableDetails } from './messages/LSTableDetails';
import { LSTableDetailsDeltaUpdate } from './messages/LSTableDetailsDeltaUpdate';
import { LSTableGameStats } from './messages/LSTableGameStats';
import { LSTableInfoDeltaUpdate } from './messages/LSTableInfoDeltaUpdate';
import { LSTableInfoDetails } from './messages/LSTableInfoDetails';
import { LSTableInfoUpdate } from './messages/LSTableInfoUpdate';
import { LSTableOccupancyChange } from './messages/LSTableOccupancyChange';
import { LSTableOccupancyDeltaUpdate } from './messages/LSTableOccupancyDeltaUpdate';
import { LSTopCardsOrderDeltaUpdate } from './messages/LSTopCardsOrderDeltaUpdate';
import { LSTourneyBlindLevel } from './messages/LSTourneyBlindLevel';
import { LSTourneyBlindStructureDetails } from './messages/LSTourneyBlindStructureDetails';
import { LSTourneyCompletionChangeEvent } from './messages/LSTourneyCompletionChangeEvent';
import { LSTourneyCustomPropertyChange } from './messages/LSTourneyCustomPropertyChange';
import { LSTourneyDealMakingChange } from './messages/LSTourneyDealMakingChange';
import { LSTourneyDeleteChange } from './messages/LSTourneyDeleteChange';
import { LSTourneyEventsQualifiersChange } from './messages/LSTourneyEventsQualifiersChange';
import { LSTourneyLateRegistrationChange } from './messages/LSTourneyLateRegistrationChange';
import { LSTourneyLevelChange } from './messages/LSTourneyLevelChange';
import { LSTourneyLobbyData } from './messages/LSTourneyLobbyData';
import { LSTourneyLobbyStackInfo } from './messages/LSTourneyLobbyStackInfo';
import { LSTourneyParticipantName } from './messages/LSTourneyParticipantName';
import { LSTourneyParticipantStatus } from './messages/LSTourneyParticipantStatus';
import { LSTourneyPlayerCountChange } from './messages/LSTourneyPlayerCountChange';
import { LSTourneyPlayerGridRow } from './messages/LSTourneyPlayerGridRow';
import { LSTourneyPrizeInfo } from './messages/LSTourneyPrizeInfo';
import { LSTourneyRebuyAddonChange } from './messages/LSTourneyRebuyAddonChange';
import { LSTourneyRebuyAddonInfo } from './messages/LSTourneyRebuyAddonInfo';
import { LSTourneyRegistrationChange } from './messages/LSTourneyRegistrationChange';
import { LSTourneyRoundChange } from './messages/LSTourneyRoundChange';
import { LSTourneyRoundInfo } from './messages/LSTourneyRoundInfo';
import { LSTourneyShootoutInfo } from './messages/LSTourneyShootoutInfo';
import { LSTourneyStartTimeChange } from './messages/LSTourneyStartTimeChange';
import { LSTourneyStatusChange } from './messages/LSTourneyStatusChange';
import { LSTourneySyncAddonBreakTimeChange } from './messages/LSTourneySyncAddonBreakTimeChange';
import { LSTourneyTablePlayer } from './messages/LSTourneyTablePlayer';
import { LSTourneyTablesGridRowDetails } from './messages/LSTourneyTablesGridRowDetails';
import { LSTourneyTimeBank } from './messages/LSTourneyTimeBank';
import { LSTourneyTimeToAct } from './messages/LSTourneyTimeToAct';
import { LSTourneyUnRegistrationCloseChangeEvent } from './messages/LSTourneyUnRegistrationCloseChangeEvent';
import { LSTourneyWatcherCountChange } from './messages/LSTourneyWatcherCountChange';
import { LobbyPlayerTableCounts } from './messages/LobbyPlayerTableCounts';
import { MTCTFontDetails } from './messages/MTCTFontDetails';
import { MTSGCard } from './messages/MTSGCard';
import { MTTCard } from './messages/MTTCard';
import { OneClickTourneyLateRegistrationChange } from './messages/OneClickTourneyLateRegistrationChange';
import { OneClickTourneyRegistrationChange } from './messages/OneClickTourneyRegistrationChange';
import { OneClickTourneyStatusChange } from './messages/OneClickTourneyStatusChange';
import { OneClickTourneyTicketCardChanges } from './messages/OneClickTourneyTicketCardChanges';
import { OneClickTourneyUnregistrationCloseChange } from './messages/OneClickTourneyUnregistrationCloseChange';
import { PLSAuthenticationRequest } from './messages/PLSAuthenticationRequest';
import { PLSAuthenticationResponse } from './messages/PLSAuthenticationResponse';
import { PMUQuintePokCounter } from './messages/PMUQuintePokCounter';
import { PlayerLobbyToGameplayTrackerInfo } from './messages/PlayerLobbyToGameplayTrackerInfo';
import { PromoSNGTemplate } from './messages/PromoSNGTemplate';
import { RequestFFDataOnClick } from './messages/RequestFFDataOnClick';
import { RequestFeatureGamesLobbyUpdate } from './messages/RequestFeatureGamesLobbyUpdate';
import { RequestGroupLobbyUpdate } from './messages/RequestGroupLobbyUpdate';
import { RequestLSHeadsUpTourenyPlayerSequence } from './messages/RequestLSHeadsUpTourenyPlayerSequence';
import { RequestLSLobbyDyna } from './messages/RequestLSLobbyDyna';
import { RequestLSMTCTParticipantNames } from './messages/RequestLSMTCTParticipantNames';
import { RequestLSPlayerTableCounts } from './messages/RequestLSPlayerTableCounts';
import { RequestLSPlayerTourneyTabInfo } from './messages/RequestLSPlayerTourneyTabInfo';
import { RequestLSTourneyParticipantNames } from './messages/RequestLSTourneyParticipantNames';
import { RequestLobbyActive } from './messages/RequestLobbyActive';
import { RequestLobbyConfig } from './messages/RequestLobbyConfig';
import { RequestLobbyDyna } from './messages/RequestLobbyDyna';
import { RequestLobbyUnSubscribe } from './messages/RequestLobbyUnSubscribe';
import { RequestLobbyUpdate } from './messages/RequestLobbyUpdate';
import { RequestLobbyUpdateOneClick } from './messages/RequestLobbyUpdateOneClick';
import { RequestMTCTLobbyData } from './messages/RequestMTCTLobbyData';
import { RequestMTCTLobbyGridOnPageNumber } from './messages/RequestMTCTLobbyGridOnPageNumber';
import { RequestMTCTLobbyGridOnScreenName } from './messages/RequestMTCTLobbyGridOnScreenName';
import { RequestMTCTLobbyPlayerTablesUnSubscribe } from './messages/RequestMTCTLobbyPlayerTablesUnSubscribe';
import { RequestMTCTLobbyPlayersUnSubscribe } from './messages/RequestMTCTLobbyPlayersUnSubscribe';
import { RequestMTCTLobbyTablesOnPageNumber } from './messages/RequestMTCTLobbyTablesOnPageNumber';
import { RequestMTCTLobbyTablesOnScreenName } from './messages/RequestMTCTLobbyTablesOnScreenName';
import { RequestMTCTLobbyTablesUnSubscribe } from './messages/RequestMTCTLobbyTablesUnSubscribe';
import { RequestMonitorMTCTTable } from './messages/RequestMonitorMTCTTable';
import { RequestOpenMTCTLobby } from './messages/RequestOpenMTCTLobby';
import { RequestPlayerList } from './messages/RequestPlayerList';
import { RequestPlayerSearch } from './messages/RequestPlayerSearch';
import { RequestPlayerTrackInfo } from './messages/RequestPlayerTrackInfo';
import { RequestPoolPlayerList } from './messages/RequestPoolPlayerList';
import { RequestPxPlayersGridOnPageNumber } from './messages/RequestPxPlayersGridOnPageNumber';
import { RequestPxTablesGridOnPageNumber } from './messages/RequestPxTablesGridOnPageNumber';
import { RequestQuickSeatFastForward } from './messages/RequestQuickSeatFastForward';
import { RequestQuickSeatRing } from './messages/RequestQuickSeatRing';
import { RequestSNGGroupRegister } from './messages/RequestSNGGroupRegister';
import { RequestSNGInstance } from './messages/RequestSNGInstance';
import { RequestSNGJPFeed } from './messages/RequestSNGJPFeed';
import { RequestSNGJPLobbyData } from './messages/RequestSNGJPLobbyData';
import { RequestSNGJPObserverUpdate } from './messages/RequestSNGJPObserverUpdate';
import { RequestSNGJPPlayNowUpdate } from './messages/RequestSNGJPPlayNowUpdate';
import { RequestSNGPromoUpdate } from './messages/RequestSNGPromoUpdate';
import { RequestSNGQuickRegister } from './messages/RequestSNGQuickRegister';
import { RequestSNGQuickRegisterAdvanced } from './messages/RequestSNGQuickRegisterAdvanced';
import { RequestSatellitesData } from './messages/RequestSatellitesData';
import { RequestSearchMTCTParticipantNames } from './messages/RequestSearchMTCTParticipantNames';
import { RequestSimilarMTCT } from './messages/RequestSimilarMTCT';
import { RequestTableForMTCTParticipant } from './messages/RequestTableForMTCTParticipant';
import { RequestTourneyCount } from './messages/RequestTourneyCount';
import { RequestTourneyLobbyActive } from './messages/RequestTourneyLobbyActive';
import { RequestTourneySearchMTCTParticipantNames } from './messages/RequestTourneySearchMTCTParticipantNames';
import { RequestUnSubscribePlayerTrackInfo } from './messages/RequestUnSubscribePlayerTrackInfo';
import { ResponseAddFavoriteCard } from './messages/ResponseAddFavoriteCard';
import { ResponseDeleteFavoriteCard } from './messages/ResponseDeleteFavoriteCard';
import { ResponseDynaDetailsDeltaChanges } from './messages/ResponseDynaDetailsDeltaChanges';
import { ResponseFFDataOnClick } from './messages/ResponseFFDataOnClick';
import { ResponseFeatureGamesLobbyUpdate } from './messages/ResponseFeatureGamesLobbyUpdate';
import { ResponseGroupCashDetailsList } from './messages/ResponseGroupCashDetailsList';
import { ResponseLSDynaDeltaChanges } from './messages/ResponseLSDynaDeltaChanges';
import { ResponseLSHeadsUpTourenyPlayerSequence } from './messages/ResponseLSHeadsUpTourenyPlayerSequence';
import { ResponseLSMTCTParticipantNames } from './messages/ResponseLSMTCTParticipantNames';
import { ResponseLSMTCTStatus } from './messages/ResponseLSMTCTStatus';
import { ResponseLSPlayerTourneyTabInfo } from './messages/ResponseLSPlayerTourneyTabInfo';
import { ResponseLSTourneyParticipantNames } from './messages/ResponseLSTourneyParticipantNames';
import { ResponseLobbyConfig } from './messages/ResponseLobbyConfig';
import { ResponseLobbyDyna } from './messages/ResponseLobbyDyna';
import { ResponseLobbyUpdateOneClick } from './messages/ResponseLobbyUpdateOneClick';
import { ResponseMTCTDetailsList } from './messages/ResponseMTCTDetailsList';
import { ResponseMTCTDynaDetailsList } from './messages/ResponseMTCTDynaDetailsList';
import { ResponseMTCTLobbyData } from './messages/ResponseMTCTLobbyData';
import { ResponseMTCTTableInfoForParticipant } from './messages/ResponseMTCTTableInfoForParticipant';
import { ResponseMyTicketsCount } from './messages/ResponseMyTicketsCount';
import { ResponsePlayerList } from './messages/ResponsePlayerList';
import { ResponsePlayerSearch } from './messages/ResponsePlayerSearch';
import { ResponsePlayerTrackInfo } from './messages/ResponsePlayerTrackInfo';
import { ResponsePoolDetailsList } from './messages/ResponsePoolDetailsList';
import { ResponsePoolInfoDetailsList } from './messages/ResponsePoolInfoDetailsList';
import { ResponsePoolPlayerList } from './messages/ResponsePoolPlayerList';
import { ResponseQuickSeatFastForward } from './messages/ResponseQuickSeatFastForward';
import { ResponseQuickSeatRing } from './messages/ResponseQuickSeatRing';
import { ResponseSNGGroupRegister } from './messages/ResponseSNGGroupRegister';
import { ResponseSNGInstance } from './messages/ResponseSNGInstance';
import { ResponseSNGJPFeed } from './messages/ResponseSNGJPFeed';
import { ResponseSNGJPFeedDelta } from './messages/ResponseSNGJPFeedDelta';
import { ResponseSNGJPLobbyData } from './messages/ResponseSNGJPLobbyData';
import { ResponseSNGJPObserverDeltaChanges } from './messages/ResponseSNGJPObserverDeltaChanges';
import { ResponseSNGJPObserverUpdate } from './messages/ResponseSNGJPObserverUpdate';
import { ResponseSNGJPPlayNowDeltaChanges } from './messages/ResponseSNGJPPlayNowDeltaChanges';
import { ResponseSNGJPPlayNowUpdate } from './messages/ResponseSNGJPPlayNowUpdate';
import { ResponseSNGPromoDeltaChanges } from './messages/ResponseSNGPromoDeltaChanges';
import { ResponseSNGPromoUpdate } from './messages/ResponseSNGPromoUpdate';
import { ResponseSNGQuickRegister } from './messages/ResponseSNGQuickRegister';
import { ResponseSNGQuickRegisterAdvanced } from './messages/ResponseSNGQuickRegisterAdvanced';
import { ResponseSatellitesData } from './messages/ResponseSatellitesData';
import { ResponseSimilarMTCT } from './messages/ResponseSimilarMTCT';
import { ResponseTableDetailsList } from './messages/ResponseTableDetailsList';
import { ResponseTableInfoDetailsList } from './messages/ResponseTableInfoDetailsList';
import { ResponseTickersUpdate } from './messages/ResponseTickersUpdate';
import { ResponseTourenyLobbyPlayersGrid } from './messages/ResponseTourenyLobbyPlayersGrid';
import { ResponseTourenyLobbyTablesGrid } from './messages/ResponseTourenyLobbyTablesGrid';
import { ResponseTourenyTablePlayerList } from './messages/ResponseTourenyTablePlayerList';
import { ResponseTourneyCount } from './messages/ResponseTourneyCount';
import { ResponseTourneyIdsAcceptingFreerolls } from './messages/ResponseTourneyIdsAcceptingFreerolls';
import { ResponseTourneyLobbyDeltaChanges } from './messages/ResponseTourneyLobbyDeltaChanges';
import { ResponseTourneyLobbyDetails } from './messages/ResponseTourneyLobbyDetails';
import { ResponseTourneyParticipantStatus } from './messages/ResponseTourneyParticipantStatus';
import { ResponseWaitList } from './messages/ResponseWaitList';
import { ResultQuickSeatRing } from './messages/ResultQuickSeatRing';
import { RingGameCard } from './messages/RingGameCard';
import { RingPlayerSearchResultTable } from './messages/RingPlayerSearchResultTable';
import { SNGCard } from './messages/SNGCard';
import { SNGJPCard } from './messages/SNGJPCard';
import { SNGJPCardDeleteEvent } from './messages/SNGJPCardDeleteEvent';
import { SNGJPInstanceCard } from './messages/SNGJPInstanceCard';
import { SNGJPInstanceCardDeleteEvent } from './messages/SNGJPInstanceCardDeleteEvent';
import { SNGJPInstanceWatchers } from './messages/SNGJPInstanceWatchers';
import { SNGJPWinner } from './messages/SNGJPWinner';
import { SNGJPWinnerDeleteEvent } from './messages/SNGJPWinnerDeleteEvent';
import { SNGPromoDeleteEvent } from './messages/SNGPromoDeleteEvent';
import { SNGPromoSNGTemplateDeleteEvent } from './messages/SNGPromoSNGTemplateDeleteEvent';
import { SNGPromoUpdate } from './messages/SNGPromoUpdate';
import { STTPlayerSearchResultTable } from './messages/STTPlayerSearchResultTable';
import { SearchResultForPlayer } from './messages/SearchResultForPlayer';
import { UpdateCardLastPlayed } from './messages/UpdateCardLastPlayed';
import { UpdateCardMTTRegistrantCount } from './messages/UpdateCardMTTRegistrantCount';
import { UpdateCardMTTRegistrationStatus } from './messages/UpdateCardMTTRegistrationStatus';
import { UpdateCardTag } from './messages/UpdateCardTag';

export class PokerLobbyMessageFactory extends PGMessageFactory {
    override classes: Map<number, any> = new Map<number, any>();
    override ids: Map<any, number> = new Map<any, number>();

    constructor() {
        super();
        this.classes.set(86017, LSTabIdLastUpdatedTime);
        this.classes.set(86018, RequestLobbyUpdate);
        this.classes.set(86019, RequestLobbyActive);
        this.classes.set(86020, LSTableInfoDetails);
        this.classes.set(86021, LSDeletedTableInfoDetails);
        this.classes.set(86022, ResponseTableInfoDetailsList);
        this.classes.set(86023, LSTableInfoDeltaUpdate);
        this.classes.set(86024, LSTableDetails);
        this.classes.set(86025, LSDeletedTableDetails);
        this.classes.set(86026, LSTabIdTableDetails);
        this.classes.set(86027, ResponseTableDetailsList);
        this.classes.set(86028, LSTableGameStats);
        this.classes.set(86029, LSTableOccupancyChange);
        this.classes.set(86030, LSTableDetailsDeltaUpdate);
        this.classes.set(86031, RequestPlayerList);
        this.classes.set(86032, LSPlayerInfo);
        this.classes.set(86033, LSDealerBlindPositions);
        this.classes.set(86034, ResponsePlayerList);
        this.classes.set(86035, LSPlayerBalances);
        this.classes.set(86036, LSRemovedPlayerInfo);
        this.classes.set(86037, LSPlayerListDeltaUpdate);
        this.classes.set(86038, RequestPlayerSearch);
        this.classes.set(86040, SearchResultForPlayer);
        this.classes.set(86041, ResponsePlayerSearch);
        this.classes.set(86042, LSPoolInfoDetails);
        this.classes.set(86043, LSDeletedPoolInfoDetails);
        this.classes.set(86044, ResponsePoolInfoDetailsList);
        this.classes.set(86045, LSPoolInfoDetailsDeltaUpdate);
        this.classes.set(86046, LSPoolDetails);
        this.classes.set(86047, LSDeletedPoolDetails);
        this.classes.set(86048, LSTabIdPoolDetails);
        this.classes.set(86049, ResponsePoolDetailsList);
        this.classes.set(86050, LSPoolPlayerCount);
        this.classes.set(86051, LSPoolGameStats);
        this.classes.set(86052, LSPoolDetailsDeltaUpdate);
        this.classes.set(86053, RequestPoolPlayerList);
        this.classes.set(86054, LSPoolPlayerInfo);
        this.classes.set(86055, ResponsePoolPlayerList);
        this.classes.set(86056, LSPoolRemovePlayerInfo);
        this.classes.set(86057, LSPoolPlayerListDeltaUpdate);
        this.classes.set(86058, ResponseWaitList);
        this.classes.set(86059, LSMTCTLobbyData);
        this.classes.set(86060, LSTabIdMTCTDetails);
        this.classes.set(86061, ResponseMTCTDetailsList);
        this.classes.set(86062, LSMTCTDeleteEvent);
        this.classes.set(86063, LSMTCTStatusChangeEvent);
        this.classes.set(86064, LSMTCTCustomPropertyChangeEvent);
        this.classes.set(86065, LSMTCTLevelChangeEvent);
        this.classes.set(86066, LSMTCTPrizePoolChangeEvent);
        this.classes.set(86067, LSMTCTFirstRankChangeEvent);
        this.classes.set(86068, LSMTCTPlayerCountChangeEvent);
        this.classes.set(86069, LSMTCTRegistrationChangeEvent);
        this.classes.set(86070, LSMTCTLateRegistrationChangeEvent);
        this.classes.set(86071, LSMTCTUnRegistrationCloseChangeEvent);
        this.classes.set(86072, LSMTCTDetailsDeltaUpdate);
        this.classes.set(86073, ResponseMTCTDynaDetailsList);
        this.classes.set(86074, LSTabIdDynamicLanguagePackDetails);
        this.classes.set(86075, LSDynamicLanguagePackUpdate);
        this.classes.set(86076, RequestMTCTLobbyData);
        this.classes.set(86077, ResponseMTCTLobbyData);
        this.classes.set(86078, LSTourneyRoundInfo);
        this.classes.set(86079, LSTourneyPrizeInfo);
        this.classes.set(86080, LSTourneyRebuyAddonInfo);
        this.classes.set(86081, LSTourneyShootoutInfo);
        this.classes.set(86082, LSTourneyLobbyStackInfo);
        this.classes.set(86083, LSHeadsUpTourenyPlayerSequence);
        this.classes.set(86084, LSTourneyBlindLevel);
        this.classes.set(86085, LSTourneyBlindStructureDetails);
        this.classes.set(86086, LSTourneyLobbyData);
        this.classes.set(86087, LSTourneyCustomPropertyChange);
        this.classes.set(86088, LSTourneyDealMakingChange);
        this.classes.set(86089, LSTourneyLateRegistrationChange);
        this.classes.set(86090, LSTourneyLevelChange);
        this.classes.set(86091, LSTourneyPlayerGridRow);
        this.classes.set(86092, LSTourneyRebuyAddonChange);
        this.classes.set(86093, LSTourneyRegistrationChange);
        this.classes.set(86094, LSTourneyRoundChange);
        this.classes.set(86095, LSTourneyStatusChange);
        this.classes.set(86096, LSTourneySyncAddonBreakTimeChange);
        this.classes.set(86097, LSTourneyTablePlayer);
        this.classes.set(86098, LSTourneyTablesGridRowDetails);
        this.classes.set(86099, LSTourneyUnRegistrationCloseChangeEvent);
        this.classes.set(86100, LSTourneyWatcherCountChange);
        this.classes.set(86101, LSTourneyPlayerCountChange);
        this.classes.set(86102, LSTourneyParticipantName);
        this.classes.set(86103, RequestMonitorMTCTTable);
        this.classes.set(86104, RequestLobbyDyna);
        this.classes.set(86105, RequestMTCTLobbyGridOnPageNumber);
        this.classes.set(86106, RequestMTCTLobbyGridOnScreenName);
        this.classes.set(86107, RequestMTCTLobbyTablesOnPageNumber);
        this.classes.set(86108, RequestMTCTLobbyTablesOnScreenName);
        this.classes.set(86109, RequestLSMTCTParticipantNames);
        this.classes.set(86110, RequestOpenMTCTLobby);
        this.classes.set(86111, ResponseLobbyDyna);
        this.classes.set(86112, ResponseLSMTCTParticipantNames);
        this.classes.set(86113, ResponseTourenyLobbyPlayersGrid);
        this.classes.set(86114, ResponseTourenyLobbyTablesGrid);
        this.classes.set(86115, ResponseTourenyTablePlayerList);
        this.classes.set(86116, ResponseTourneyLobbyDeltaChanges);
        this.classes.set(86117, ResponseTourneyLobbyDetails);
        this.classes.set(86118, LSSTTTableGameStatus);
        this.classes.set(86119, RingPlayerSearchResultTable);
        this.classes.set(86120, STTPlayerSearchResultTable);
        this.classes.set(86121, ResponseDynaDetailsDeltaChanges);
        this.classes.set(86122, RequestLobbyUnSubscribe);
        this.classes.set(86123, ResponseTourneyParticipantStatus);
        this.classes.set(86124, LSTourneyParticipantStatus);
        this.classes.set(86125, RequestTableForMTCTParticipant);
        this.classes.set(86126, ResponseMTCTTableInfoForParticipant);
        this.classes.set(86127, LSTourneyCompletionChangeEvent);
        this.classes.set(86128, FFCard);
        this.classes.set(86129, MTTCard);
        this.classes.set(86130, RingGameCard);
        this.classes.set(86131, MTSGCard);
        this.classes.set(86132, SNGCard);
        this.classes.set(86133, ResponseLobbyUpdateOneClick);
        this.classes.set(86134, RequestQuickSeatFastForward);
        this.classes.set(86135, ResponseQuickSeatFastForward);
        this.classes.set(86136, RequestQuickSeatRing);
        this.classes.set(86137, ResponseQuickSeatRing);
        this.classes.set(86138, LSCurrencyAmount);
        this.classes.set(86139, RequestSearchMTCTParticipantNames);
        this.classes.set(86140, RequestLSHeadsUpTourenyPlayerSequence);
        this.classes.set(86141, ResponseLSHeadsUpTourenyPlayerSequence);
        this.classes.set(86142, ResponseLSMTCTStatus);
        this.classes.set(86143, LSMTCTStartTimeChangeEvent);
        this.classes.set(86144, LSTourneyStartTimeChange);
        this.classes.set(86145, LSMTCTEventsQualifiersChangeEvent);
        this.classes.set(86146, LSTourneyEventsQualifiersChange);
        this.classes.set(86147, LSPoolPlayerCountDeltaUpdate);
        this.classes.set(86148, LSTableOccupancyDeltaUpdate);
        this.classes.set(86149, LSTourneyDeleteChange);
        this.classes.set(86150, RequestLSPlayerTableCounts);
        this.classes.set(86156, ResultQuickSeatRing);
        this.classes.set(86157, RequestLobbyUpdateOneClick);
        this.classes.set(86158, LSLobbyCardDeltaUpdate);
        this.classes.set(86159, DeleteCard);
        this.classes.set(86160, UpdateCardLastPlayed);
        this.classes.set(86161, UpdateCardMTTRegistrationStatus);
        this.classes.set(86162, UpdateCardMTTRegistrantCount);
        this.classes.set(86163, UpdateCardTag);
        this.classes.set(86164, PlayerLobbyToGameplayTrackerInfo);
        this.classes.set(86165, RequestLobbyConfig);
        this.classes.set(86166, ResponseLobbyConfig);
        this.classes.set(86167, LSTopCardsOrderDeltaUpdate);
        this.classes.set(86168, AddFavoriteCard);
        this.classes.set(86169, DeleteFavoriteCard);
        this.classes.set(86170, ResponseDeleteFavoriteCard);
        this.classes.set(86171, ResponseAddFavoriteCard);
        this.classes.set(86173, FavoriteCards);
        this.classes.set(86174, RequestSNGQuickRegister);
        this.classes.set(86175, ResponseSNGQuickRegister);
        this.classes.set(86176, OneClickTourneyStatusChange);
        this.classes.set(86177, OneClickTourneyRegistrationChange);
        this.classes.set(86178, OneClickTourneyLateRegistrationChange);
        this.classes.set(86179, OneClickTourneyTicketCardChanges);
        this.classes.set(86181, LSItaliaAuthorizationCodes);
        this.classes.set(86182, LobbyPlayerTableCounts);
        this.classes.set(86183, RequestSNGQuickRegisterAdvanced);
        this.classes.set(86184, ResponseSNGQuickRegisterAdvanced);
        this.classes.set(86185, OneClickTourneyUnregistrationCloseChange);
        this.classes.set(86186, ChangeTabRequest);
        this.classes.set(86187, PMUQuintePokCounter);
        this.classes.set(86188, ResponseTickersUpdate);
        this.classes.set(86189, RequestTourneyLobbyActive);
        this.classes.set(86190, RequestSNGGroupRegister);
        this.classes.set(86191, ResponseSNGGroupRegister);
        this.classes.set(86192, ResponseTourneyIdsAcceptingFreerolls);
        this.classes.set(86194, ResponseMyTicketsCount);
        this.classes.set(86195, SNGPromoUpdate);
        this.classes.set(86196, LSSNGPromoUpdate);
        this.classes.set(86197, RequestSNGInstance);
        this.classes.set(86198, ResponseSNGInstance);
        this.classes.set(86199, RequestTourneyCount);
        this.classes.set(86200, ResponseTourneyCount);
        this.classes.set(86201, LSPrizeDetailsMessage);
        this.classes.set(86202, RequestLSPlayerTourneyTabInfo);
        this.classes.set(86203, ResponseLSPlayerTourneyTabInfo);
        this.classes.set(86204, LSRequestMTCTId);
        this.classes.set(86205, LSRequestMTCTIdRes);
        this.classes.set(86206, RequestSNGJPPlayNowUpdate);
        this.classes.set(86207, ResponseSNGJPPlayNowUpdate);
        this.classes.set(86208, ResponseSNGJPPlayNowDeltaChanges);
        this.classes.set(86209, SNGJPCard);
        this.classes.set(86210, SNGJPCardDeleteEvent);
        this.classes.set(86211, RequestSNGJPObserverUpdate);
        this.classes.set(86212, ResponseSNGJPObserverUpdate);
        this.classes.set(86213, ResponseSNGJPObserverDeltaChanges);
        this.classes.set(86214, SNGJPInstanceCard);
        this.classes.set(86215, SNGJPInstanceCardDeleteEvent);
        this.classes.set(86216, RequestSNGJPFeed);
        this.classes.set(86217, SNGJPWinner);
        this.classes.set(86218, ResponseSNGJPFeed);
        this.classes.set(86219, ResponseSNGJPFeedDelta);
        this.classes.set(86220, RequestSNGJPLobbyData);
        this.classes.set(86221, ResponseSNGJPLobbyData);
        this.classes.set(86222, SNGJPWinnerDeleteEvent);
        this.classes.set(86223, RequestFFDataOnClick);
        this.classes.set(86224, ResponseFFDataOnClick);
        this.classes.set(86225, PromoSNGTemplate);
        this.classes.set(86226, RequestSNGPromoUpdate);
        this.classes.set(86227, ResponseSNGPromoUpdate);
        this.classes.set(86228, SNGPromoDeleteEvent);
        this.classes.set(86229, SNGPromoSNGTemplateDeleteEvent);
        this.classes.set(86230, ResponseSNGPromoDeltaChanges);
        this.classes.set(86231, SNGJPInstanceWatchers);
        this.classes.set(86232, LSMultiDayWiseData);
        this.classes.set(86233, LSMultiDayData);
        this.classes.set(86234, LSMultiDayChangeData);
        this.classes.set(86235, LSFlightedNextDayDetails);
        this.classes.set(86236, LSFlightedData);
        this.classes.set(86237, RequestFeatureGamesLobbyUpdate);
        this.classes.set(86238, ResponseFeatureGamesLobbyUpdate);
        this.classes.set(86239, LSFeaturedTableDetailsDeltaUpdate);
        this.classes.set(86240, LSMixMaxEntry);
        this.classes.set(86241, LSMixMaxData);
        this.classes.set(86244, LSHeadsUpParticipantStatus);
        this.classes.set(86247, RequestMTCTLobbyPlayersUnSubscribe);
        this.classes.set(86248, RequestMTCTLobbyTablesUnSubscribe);
        this.classes.set(86249, RequestMTCTLobbyPlayerTablesUnSubscribe);
        this.classes.set(86250, RequestPlayerTrackInfo);
        this.classes.set(86251, RequestUnSubscribePlayerTrackInfo);
        this.classes.set(86252, ResponsePlayerTrackInfo);
        this.classes.set(86253, LSMTCTUpdateChanges);
        this.classes.set(86254, LSMTCTUpdateMinChanges);
        this.classes.set(86255, RequestTourneySearchMTCTParticipantNames);
        this.classes.set(86256, RequestLSTourneyParticipantNames);
        this.classes.set(86257, ResponseLSTourneyParticipantNames);
        this.classes.set(86258, MTCTFontDetails);
        this.classes.set(86273, RequestSimilarMTCT);
        this.classes.set(86274, ResponseSimilarMTCT);
        this.classes.set(86275, LSReentryInfo);
        this.classes.set(86276, LSTableInfoUpdate);
        this.classes.set(86277, LSTabIdTableInfoDetails);
        this.classes.set(86278, RequestGroupLobbyUpdate);
        this.classes.set(86279, ResponseGroupCashDetailsList);
        this.classes.set(86280, RequestSatellitesData);
        this.classes.set(86281, ResponseSatellitesData);
        this.classes.set(86282, LSTourneyTimeToAct);
        this.classes.set(86283, LSTourneyTimeBank);
        this.classes.set(86284, ResponseLSDynaDeltaChanges);
        this.classes.set(86285, RequestLSLobbyDyna);
        this.classes.set(86286, LSTabIdTableInfoDeltaUpdate);
        this.classes.set(86287, PLSAuthenticationRequest);
        this.classes.set(86288, PLSAuthenticationResponse);
        this.classes.set(86290, RequestPxTablesGridOnPageNumber);
        this.classes.set(86291, RequestPxPlayersGridOnPageNumber);
        for (const [key, value] of this.classes.entries()) {
            this.ids.set(value, key);
        }
    }

    read(obj: Message, inStream: TSDataInputStream) {
        const classId = this.getClassId(obj.constructor);
        let result = false;
        switch (classId) {
            case 86017:
                this.readLSTabIdLastUpdatedTime(inStream, obj as LSTabIdLastUpdatedTime);
                result = true;
                break;
            case 86018:
                this.readRequestLobbyUpdate(inStream, obj as RequestLobbyUpdate);
                result = true;
                break;
            case 86019:
                this.readRequestLobbyActive(inStream, obj as RequestLobbyActive);
                result = true;
                break;
            case 86020:
                this.readLSTableInfoDetails(inStream, obj as LSTableInfoDetails);
                result = true;
                break;
            case 86021:
                this.readLSDeletedTableInfoDetails(inStream, obj as LSDeletedTableInfoDetails);
                result = true;
                break;
            case 86022:
                this.readResponseTableInfoDetailsList(inStream, obj as ResponseTableInfoDetailsList);
                result = true;
                break;
            case 86023:
                this.readLSTableInfoDeltaUpdate(inStream, obj as LSTableInfoDeltaUpdate);
                result = true;
                break;
            case 86024:
                this.readLSTableDetails(inStream, obj as LSTableDetails);
                result = true;
                break;
            case 86025:
                this.readLSDeletedTableDetails(inStream, obj as LSDeletedTableDetails);
                result = true;
                break;
            case 86026:
                this.readLSTabIdTableDetails(inStream, obj as LSTabIdTableDetails);
                result = true;
                break;
            case 86027:
                this.readResponseTableDetailsList(inStream, obj as ResponseTableDetailsList);
                result = true;
                break;
            case 86028:
                this.readLSTableGameStats(inStream, obj as LSTableGameStats);
                result = true;
                break;
            case 86029:
                this.readLSTableOccupancyChange(inStream, obj as LSTableOccupancyChange);
                result = true;
                break;
            case 86030:
                this.readLSTableDetailsDeltaUpdate(inStream, obj as LSTableDetailsDeltaUpdate);
                result = true;
                break;
            case 86031:
                this.readRequestPlayerList(inStream, obj as RequestPlayerList);
                result = true;
                break;
            case 86032:
                this.readLSPlayerInfo(inStream, obj as LSPlayerInfo);
                result = true;
                break;
            case 86033:
                this.readLSDealerBlindPositions(inStream, obj as LSDealerBlindPositions);
                result = true;
                break;
            case 86034:
                this.readResponsePlayerList(inStream, obj as ResponsePlayerList);
                result = true;
                break;
            case 86035:
                this.readLSPlayerBalances(inStream, obj as LSPlayerBalances);
                result = true;
                break;
            case 86036:
                this.readLSRemovedPlayerInfo(inStream, obj as LSRemovedPlayerInfo);
                result = true;
                break;
            case 86037:
                this.readLSPlayerListDeltaUpdate(inStream, obj as LSPlayerListDeltaUpdate);
                result = true;
                break;
            case 86038:
                this.readRequestPlayerSearch(inStream, obj as RequestPlayerSearch);
                result = true;
                break;
            case 86040:
                this.readSearchResultForPlayer(inStream, obj as SearchResultForPlayer);
                result = true;
                break;
            case 86041:
                this.readResponsePlayerSearch(inStream, obj as ResponsePlayerSearch);
                result = true;
                break;
            case 86042:
                this.readLSPoolInfoDetails(inStream, obj as LSPoolInfoDetails);
                result = true;
                break;
            case 86043:
                this.readLSDeletedPoolInfoDetails(inStream, obj as LSDeletedPoolInfoDetails);
                result = true;
                break;
            case 86044:
                this.readResponsePoolInfoDetailsList(inStream, obj as ResponsePoolInfoDetailsList);
                result = true;
                break;
            case 86045:
                this.readLSPoolInfoDetailsDeltaUpdate(inStream, obj as LSPoolInfoDetailsDeltaUpdate);
                result = true;
                break;
            case 86046:
                this.readLSPoolDetails(inStream, obj as LSPoolDetails);
                result = true;
                break;
            case 86047:
                this.readLSDeletedPoolDetails(inStream, obj as LSDeletedPoolDetails);
                result = true;
                break;
            case 86048:
                this.readLSTabIdPoolDetails(inStream, obj as LSTabIdPoolDetails);
                result = true;
                break;
            case 86049:
                this.readResponsePoolDetailsList(inStream, obj as ResponsePoolDetailsList);
                result = true;
                break;
            case 86050:
                this.readLSPoolPlayerCount(inStream, obj as LSPoolPlayerCount);
                result = true;
                break;
            case 86051:
                this.readLSPoolGameStats(inStream, obj as LSPoolGameStats);
                result = true;
                break;
            case 86052:
                this.readLSPoolDetailsDeltaUpdate(inStream, obj as LSPoolDetailsDeltaUpdate);
                result = true;
                break;
            case 86053:
                this.readRequestPoolPlayerList(inStream, obj as RequestPoolPlayerList);
                result = true;
                break;
            case 86054:
                this.readLSPoolPlayerInfo(inStream, obj as LSPoolPlayerInfo);
                result = true;
                break;
            case 86055:
                this.readResponsePoolPlayerList(inStream, obj as ResponsePoolPlayerList);
                result = true;
                break;
            case 86056:
                this.readLSPoolRemovePlayerInfo(inStream, obj as LSPoolRemovePlayerInfo);
                result = true;
                break;
            case 86057:
                this.readLSPoolPlayerListDeltaUpdate(inStream, obj as LSPoolPlayerListDeltaUpdate);
                result = true;
                break;
            case 86058:
                this.readResponseWaitList(inStream, obj as ResponseWaitList);
                result = true;
                break;
            case 86059:
                this.readLSMTCTLobbyData(inStream, obj as LSMTCTLobbyData);
                result = true;
                break;
            case 86060:
                this.readLSTabIdMTCTDetails(inStream, obj as LSTabIdMTCTDetails);
                result = true;
                break;
            case 86061:
                this.readResponseMTCTDetailsList(inStream, obj as ResponseMTCTDetailsList);
                result = true;
                break;
            case 86062:
                this.readLSMTCTDeleteEvent(inStream, obj as LSMTCTDeleteEvent);
                result = true;
                break;
            case 86063:
                this.readLSMTCTStatusChangeEvent(inStream, obj as LSMTCTStatusChangeEvent);
                result = true;
                break;
            case 86064:
                this.readLSMTCTCustomPropertyChangeEvent(inStream, obj as LSMTCTCustomPropertyChangeEvent);
                result = true;
                break;
            case 86065:
                this.readLSMTCTLevelChangeEvent(inStream, obj as LSMTCTLevelChangeEvent);
                result = true;
                break;
            case 86066:
                this.readLSMTCTPrizePoolChangeEvent(inStream, obj as LSMTCTPrizePoolChangeEvent);
                result = true;
                break;
            case 86067:
                this.readLSMTCTFirstRankChangeEvent(inStream, obj as LSMTCTFirstRankChangeEvent);
                result = true;
                break;
            case 86068:
                this.readLSMTCTPlayerCountChangeEvent(inStream, obj as LSMTCTPlayerCountChangeEvent);
                result = true;
                break;
            case 86069:
                this.readLSMTCTRegistrationChangeEvent(inStream, obj as LSMTCTRegistrationChangeEvent);
                result = true;
                break;
            case 86070:
                this.readLSMTCTLateRegistrationChangeEvent(inStream, obj as LSMTCTLateRegistrationChangeEvent);
                result = true;
                break;
            case 86071:
                this.readLSMTCTUnRegistrationCloseChangeEvent(inStream, obj as LSMTCTUnRegistrationCloseChangeEvent);
                result = true;
                break;
            case 86072:
                this.readLSMTCTDetailsDeltaUpdate(inStream, obj as LSMTCTDetailsDeltaUpdate);
                result = true;
                break;
            case 86073:
                this.readResponseMTCTDynaDetailsList(inStream, obj as ResponseMTCTDynaDetailsList);
                result = true;
                break;
            case 86074:
                this.readLSTabIdDynamicLanguagePackDetails(inStream, obj as LSTabIdDynamicLanguagePackDetails);
                result = true;
                break;
            case 86075:
                this.readLSDynamicLanguagePackUpdate(inStream, obj as LSDynamicLanguagePackUpdate);
                result = true;
                break;
            case 86076:
                this.readRequestMTCTLobbyData(inStream, obj as RequestMTCTLobbyData);
                result = true;
                break;
            case 86077:
                this.readResponseMTCTLobbyData(inStream, obj as ResponseMTCTLobbyData);
                result = true;
                break;
            case 86078:
                this.readLSTourneyRoundInfo(inStream, obj as LSTourneyRoundInfo);
                result = true;
                break;
            case 86079:
                this.readLSTourneyPrizeInfo(inStream, obj as LSTourneyPrizeInfo);
                result = true;
                break;
            case 86080:
                this.readLSTourneyRebuyAddonInfo(inStream, obj as LSTourneyRebuyAddonInfo);
                result = true;
                break;
            case 86081:
                this.readLSTourneyShootoutInfo(inStream, obj as LSTourneyShootoutInfo);
                result = true;
                break;
            case 86082:
                this.readLSTourneyLobbyStackInfo(inStream, obj as LSTourneyLobbyStackInfo);
                result = true;
                break;
            case 86083:
                this.readLSHeadsUpTourenyPlayerSequence(inStream, obj as LSHeadsUpTourenyPlayerSequence);
                result = true;
                break;
            case 86084:
                this.readLSTourneyBlindLevel(inStream, obj as LSTourneyBlindLevel);
                result = true;
                break;
            case 86085:
                this.readLSTourneyBlindStructureDetails(inStream, obj as LSTourneyBlindStructureDetails);
                result = true;
                break;
            case 86086:
                this.readLSTourneyLobbyData(inStream, obj as LSTourneyLobbyData);
                result = true;
                break;
            case 86087:
                this.readLSTourneyCustomPropertyChange(inStream, obj as LSTourneyCustomPropertyChange);
                result = true;
                break;
            case 86088:
                this.readLSTourneyDealMakingChange(inStream, obj as LSTourneyDealMakingChange);
                result = true;
                break;
            case 86089:
                this.readLSTourneyLateRegistrationChange(inStream, obj as LSTourneyLateRegistrationChange);
                result = true;
                break;
            case 86090:
                this.readLSTourneyLevelChange(inStream, obj as LSTourneyLevelChange);
                result = true;
                break;
            case 86091:
                this.readLSTourneyPlayerGridRow(inStream, obj as LSTourneyPlayerGridRow);
                result = true;
                break;
            case 86092:
                this.readLSTourneyRebuyAddonChange(inStream, obj as LSTourneyRebuyAddonChange);
                result = true;
                break;
            case 86093:
                this.readLSTourneyRegistrationChange(inStream, obj as LSTourneyRegistrationChange);
                result = true;
                break;
            case 86094:
                this.readLSTourneyRoundChange(inStream, obj as LSTourneyRoundChange);
                result = true;
                break;
            case 86095:
                this.readLSTourneyStatusChange(inStream, obj as LSTourneyStatusChange);
                result = true;
                break;
            case 86096:
                this.readLSTourneySyncAddonBreakTimeChange(inStream, obj as LSTourneySyncAddonBreakTimeChange);
                result = true;
                break;
            case 86097:
                this.readLSTourneyTablePlayer(inStream, obj as LSTourneyTablePlayer);
                result = true;
                break;
            case 86098:
                this.readLSTourneyTablesGridRowDetails(inStream, obj as LSTourneyTablesGridRowDetails);
                result = true;
                break;
            case 86099:
                this.readLSTourneyUnRegistrationCloseChangeEvent(inStream, obj as LSTourneyUnRegistrationCloseChangeEvent);
                result = true;
                break;
            case 86100:
                this.readLSTourneyWatcherCountChange(inStream, obj as LSTourneyWatcherCountChange);
                result = true;
                break;
            case 86101:
                this.readLSTourneyPlayerCountChange(inStream, obj as LSTourneyPlayerCountChange);
                result = true;
                break;
            case 86102:
                this.readLSTourneyParticipantName(inStream, obj as LSTourneyParticipantName);
                result = true;
                break;
            case 86103:
                this.readRequestMonitorMTCTTable(inStream, obj as RequestMonitorMTCTTable);
                result = true;
                break;
            case 86104:
                this.readRequestLobbyDyna(inStream, obj as RequestLobbyDyna);
                result = true;
                break;
            case 86105:
                this.readRequestMTCTLobbyGridOnPageNumber(inStream, obj as RequestMTCTLobbyGridOnPageNumber);
                result = true;
                break;
            case 86106:
                this.readRequestMTCTLobbyGridOnScreenName(inStream, obj as RequestMTCTLobbyGridOnScreenName);
                result = true;
                break;
            case 86107:
                this.readRequestMTCTLobbyTablesOnPageNumber(inStream, obj as RequestMTCTLobbyTablesOnPageNumber);
                result = true;
                break;
            case 86108:
                this.readRequestMTCTLobbyTablesOnScreenName(inStream, obj as RequestMTCTLobbyTablesOnScreenName);
                result = true;
                break;
            case 86109:
                this.readRequestLSMTCTParticipantNames(inStream, obj as RequestLSMTCTParticipantNames);
                result = true;
                break;
            case 86110:
                this.readRequestOpenMTCTLobby(inStream, obj as RequestOpenMTCTLobby);
                result = true;
                break;
            case 86111:
                this.readResponseLobbyDyna(inStream, obj as ResponseLobbyDyna);
                result = true;
                break;
            case 86112:
                this.readResponseLSMTCTParticipantNames(inStream, obj as ResponseLSMTCTParticipantNames);
                result = true;
                break;
            case 86113:
                this.readResponseTourenyLobbyPlayersGrid(inStream, obj as ResponseTourenyLobbyPlayersGrid);
                result = true;
                break;
            case 86114:
                this.readResponseTourenyLobbyTablesGrid(inStream, obj as ResponseTourenyLobbyTablesGrid);
                result = true;
                break;
            case 86115:
                this.readResponseTourenyTablePlayerList(inStream, obj as ResponseTourenyTablePlayerList);
                result = true;
                break;
            case 86116:
                this.readResponseTourneyLobbyDeltaChanges(inStream, obj as ResponseTourneyLobbyDeltaChanges);
                result = true;
                break;
            case 86117:
                this.readResponseTourneyLobbyDetails(inStream, obj as ResponseTourneyLobbyDetails);
                result = true;
                break;
            case 86118:
                this.readLSSTTTableGameStatus(inStream, obj as LSSTTTableGameStatus);
                result = true;
                break;
            case 86119:
                this.readRingPlayerSearchResultTable(inStream, obj as RingPlayerSearchResultTable);
                result = true;
                break;
            case 86120:
                this.readSTTPlayerSearchResultTable(inStream, obj as STTPlayerSearchResultTable);
                result = true;
                break;
            case 86121:
                this.readResponseDynaDetailsDeltaChanges(inStream, obj as ResponseDynaDetailsDeltaChanges);
                result = true;
                break;
            case 86122:
                this.readRequestLobbyUnSubscribe(inStream, obj as RequestLobbyUnSubscribe);
                result = true;
                break;
            case 86123:
                this.readResponseTourneyParticipantStatus(inStream, obj as ResponseTourneyParticipantStatus);
                result = true;
                break;
            case 86124:
                this.readLSTourneyParticipantStatus(inStream, obj as LSTourneyParticipantStatus);
                result = true;
                break;
            case 86125:
                this.readRequestTableForMTCTParticipant(inStream, obj as RequestTableForMTCTParticipant);
                result = true;
                break;
            case 86126:
                this.readResponseMTCTTableInfoForParticipant(inStream, obj as ResponseMTCTTableInfoForParticipant);
                result = true;
                break;
            case 86127:
                this.readLSTourneyCompletionChangeEvent(inStream, obj as LSTourneyCompletionChangeEvent);
                result = true;
                break;
            case 86128:
                this.readFFCard(inStream, obj as FFCard);
                result = true;
                break;
            case 86129:
                this.readMTTCard(inStream, obj as MTTCard);
                result = true;
                break;
            case 86130:
                this.readRingGameCard(inStream, obj as RingGameCard);
                result = true;
                break;
            case 86131:
                this.readMTSGCard(inStream, obj as MTSGCard);
                result = true;
                break;
            case 86132:
                this.readSNGCard(inStream, obj as SNGCard);
                result = true;
                break;
            case 86133:
                this.readResponseLobbyUpdateOneClick(inStream, obj as ResponseLobbyUpdateOneClick);
                result = true;
                break;
            case 86134:
                this.readRequestQuickSeatFastForward(inStream, obj as RequestQuickSeatFastForward);
                result = true;
                break;
            case 86135:
                this.readResponseQuickSeatFastForward(inStream, obj as ResponseQuickSeatFastForward);
                result = true;
                break;
            case 86136:
                this.readRequestQuickSeatRing(inStream, obj as RequestQuickSeatRing);
                result = true;
                break;
            case 86137:
                this.readResponseQuickSeatRing(inStream, obj as ResponseQuickSeatRing);
                result = true;
                break;
            case 86138:
                this.readLSCurrencyAmount(inStream, obj as LSCurrencyAmount);
                result = true;
                break;
            case 86139:
                this.readRequestSearchMTCTParticipantNames(inStream, obj as RequestSearchMTCTParticipantNames);
                result = true;
                break;
            case 86140:
                this.readRequestLSHeadsUpTourenyPlayerSequence(inStream, obj as RequestLSHeadsUpTourenyPlayerSequence);
                result = true;
                break;
            case 86141:
                this.readResponseLSHeadsUpTourenyPlayerSequence(inStream, obj as ResponseLSHeadsUpTourenyPlayerSequence);
                result = true;
                break;
            case 86142:
                this.readResponseLSMTCTStatus(inStream, obj as ResponseLSMTCTStatus);
                result = true;
                break;
            case 86143:
                this.readLSMTCTStartTimeChangeEvent(inStream, obj as LSMTCTStartTimeChangeEvent);
                result = true;
                break;
            case 86144:
                this.readLSTourneyStartTimeChange(inStream, obj as LSTourneyStartTimeChange);
                result = true;
                break;
            case 86145:
                this.readLSMTCTEventsQualifiersChangeEvent(inStream, obj as LSMTCTEventsQualifiersChangeEvent);
                result = true;
                break;
            case 86146:
                this.readLSTourneyEventsQualifiersChange(inStream, obj as LSTourneyEventsQualifiersChange);
                result = true;
                break;
            case 86147:
                this.readLSPoolPlayerCountDeltaUpdate(inStream, obj as LSPoolPlayerCountDeltaUpdate);
                result = true;
                break;
            case 86148:
                this.readLSTableOccupancyDeltaUpdate(inStream, obj as LSTableOccupancyDeltaUpdate);
                result = true;
                break;
            // case 86149: this.readLSTourneyDeleteChange(inStream, obj as LSTourneyDeleteChange); result = true; break;
            case 86150:
                this.readRequestLSPlayerTableCounts(inStream, obj as RequestLSPlayerTableCounts);
                result = true;
                break;
            case 86156:
                this.readResultQuickSeatRing(inStream, obj as ResultQuickSeatRing);
                result = true;
                break;
            case 86157:
                this.readRequestLobbyUpdateOneClick(inStream, obj as RequestLobbyUpdateOneClick);
                result = true;
                break;
            case 86158:
                this.readLSLobbyCardDeltaUpdate(inStream, obj as LSLobbyCardDeltaUpdate);
                result = true;
                break;
            case 86159:
                this.readDeleteCard(inStream, obj as DeleteCard);
                result = true;
                break;
            case 86160:
                this.readUpdateCardLastPlayed(inStream, obj as UpdateCardLastPlayed);
                result = true;
                break;
            case 86161:
                this.readUpdateCardMTTRegistrationStatus(inStream, obj as UpdateCardMTTRegistrationStatus);
                result = true;
                break;
            case 86162:
                this.readUpdateCardMTTRegistrantCount(inStream, obj as UpdateCardMTTRegistrantCount);
                result = true;
                break;
            case 86163:
                this.readUpdateCardTag(inStream, obj as UpdateCardTag);
                result = true;
                break;
            case 86164:
                this.readPlayerLobbyToGameplayTrackerInfo(inStream, obj as PlayerLobbyToGameplayTrackerInfo);
                result = true;
                break;
            case 86165:
                this.readRequestLobbyConfig(inStream, obj as RequestLobbyConfig);
                result = true;
                break;
            case 86166:
                this.readResponseLobbyConfig(inStream, obj as ResponseLobbyConfig);
                result = true;
                break;
            case 86167:
                this.readLSTopCardsOrderDeltaUpdate(inStream, obj as LSTopCardsOrderDeltaUpdate);
                result = true;
                break;
            case 86168:
                this.readAddFavoriteCard(inStream, obj as AddFavoriteCard);
                result = true;
                break;
            case 86169:
                this.readDeleteFavoriteCard(inStream, obj as DeleteFavoriteCard);
                result = true;
                break;
            case 86170:
                this.readResponseDeleteFavoriteCard(inStream, obj as ResponseDeleteFavoriteCard);
                result = true;
                break;
            case 86171:
                this.readResponseAddFavoriteCard(inStream, obj as ResponseAddFavoriteCard);
                result = true;
                break;
            case 86173:
                this.readFavoriteCards(inStream, obj as FavoriteCards);
                result = true;
                break;
            case 86174:
                this.readRequestSNGQuickRegister(inStream, obj as RequestSNGQuickRegister);
                result = true;
                break;
            case 86175:
                this.readResponseSNGQuickRegister(inStream, obj as ResponseSNGQuickRegister);
                result = true;
                break;
            case 86176:
                this.readOneClickTourneyStatusChange(inStream, obj as OneClickTourneyStatusChange);
                result = true;
                break;
            case 86177:
                this.readOneClickTourneyRegistrationChange(inStream, obj as OneClickTourneyRegistrationChange);
                result = true;
                break;
            case 86178:
                this.readOneClickTourneyLateRegistrationChange(inStream, obj as OneClickTourneyLateRegistrationChange);
                result = true;
                break;
            case 86179:
                this.readOneClickTourneyTicketCardChanges(inStream, obj as OneClickTourneyTicketCardChanges);
                result = true;
                break;
            case 86181:
                this.readLSItaliaAuthorizationCodes(inStream, obj as LSItaliaAuthorizationCodes);
                result = true;
                break;
            case 86182:
                this.readLobbyPlayerTableCounts(inStream, obj as LobbyPlayerTableCounts);
                result = true;
                break;
            case 86183:
                this.readRequestSNGQuickRegisterAdvanced(inStream, obj as RequestSNGQuickRegisterAdvanced);
                result = true;
                break;
            case 86184:
                this.readResponseSNGQuickRegisterAdvanced(inStream, obj as ResponseSNGQuickRegisterAdvanced);
                result = true;
                break;
            case 86185:
                this.readOneClickTourneyUnregistrationCloseChange(inStream, obj as OneClickTourneyUnregistrationCloseChange);
                result = true;
                break;
            case 86186:
                this.readChangeTabRequest(inStream, obj as ChangeTabRequest);
                result = true;
                break;
            case 86187:
                this.readPMUQuintePokCounter(inStream, obj as PMUQuintePokCounter);
                result = true;
                break;
            case 86188:
                this.readResponseTickersUpdate(inStream, obj as ResponseTickersUpdate);
                result = true;
                break;
            case 86189:
                this.readRequestTourneyLobbyActive(inStream, obj as RequestTourneyLobbyActive);
                result = true;
                break;
            case 86190:
                this.readRequestSNGGroupRegister(inStream, obj as RequestSNGGroupRegister);
                result = true;
                break;
            case 86191:
                this.readResponseSNGGroupRegister(inStream, obj as ResponseSNGGroupRegister);
                result = true;
                break;
            case 86192:
                this.readResponseTourneyIdsAcceptingFreerolls(inStream, obj as ResponseTourneyIdsAcceptingFreerolls);
                result = true;
                break;
            case 86194:
                this.readResponseMyTicketsCount(inStream, obj as ResponseMyTicketsCount);
                result = true;
                break;
            case 86195:
                this.readSNGPromoUpdate(inStream, obj as SNGPromoUpdate);
                result = true;
                break;
            case 86196:
                this.readLSSNGPromoUpdate(inStream, obj as LSSNGPromoUpdate);
                result = true;
                break;
            case 86197:
                this.readRequestSNGInstance(inStream, obj as RequestSNGInstance);
                result = true;
                break;
            case 86198:
                this.readResponseSNGInstance(inStream, obj as ResponseSNGInstance);
                result = true;
                break;
            case 86199:
                this.readRequestTourneyCount(inStream, obj as RequestTourneyCount);
                result = true;
                break;
            case 86200:
                this.readResponseTourneyCount(inStream, obj as ResponseTourneyCount);
                result = true;
                break;
            case 86201:
                this.readLSPrizeDetailsMessage(inStream, obj as LSPrizeDetailsMessage);
                result = true;
                break;
            case 86202:
                this.readRequestLSPlayerTourneyTabInfo(inStream, obj as RequestLSPlayerTourneyTabInfo);
                result = true;
                break;
            case 86203:
                this.readResponseLSPlayerTourneyTabInfo(inStream, obj as ResponseLSPlayerTourneyTabInfo);
                result = true;
                break;
            case 86204:
                this.readLSRequestMTCTId(inStream, obj as LSRequestMTCTId);
                result = true;
                break;
            case 86205:
                this.readLSRequestMTCTIdRes(inStream, obj as LSRequestMTCTIdRes);
                result = true;
                break;
            case 86206:
                this.readRequestSNGJPPlayNowUpdate(inStream, obj as RequestSNGJPPlayNowUpdate);
                result = true;
                break;
            case 86207:
                this.readResponseSNGJPPlayNowUpdate(inStream, obj as ResponseSNGJPPlayNowUpdate);
                result = true;
                break;
            case 86208:
                this.readResponseSNGJPPlayNowDeltaChanges(inStream, obj as ResponseSNGJPPlayNowDeltaChanges);
                result = true;
                break;
            case 86209:
                this.readSNGJPCard(inStream, obj as SNGJPCard);
                result = true;
                break;
            case 86210:
                this.readSNGJPCardDeleteEvent(inStream, obj as SNGJPCardDeleteEvent);
                result = true;
                break;
            case 86211:
                this.readRequestSNGJPObserverUpdate(inStream, obj as RequestSNGJPObserverUpdate);
                result = true;
                break;
            case 86212:
                this.readResponseSNGJPObserverUpdate(inStream, obj as ResponseSNGJPObserverUpdate);
                result = true;
                break;
            case 86213:
                this.readResponseSNGJPObserverDeltaChanges(inStream, obj as ResponseSNGJPObserverDeltaChanges);
                result = true;
                break;
            case 86214:
                this.readSNGJPInstanceCard(inStream, obj as SNGJPInstanceCard);
                result = true;
                break;
            case 86215:
                this.readSNGJPInstanceCardDeleteEvent(inStream, obj as SNGJPInstanceCardDeleteEvent);
                result = true;
                break;
            case 86216:
                this.readRequestSNGJPFeed(inStream, obj as RequestSNGJPFeed);
                result = true;
                break;
            case 86217:
                this.readSNGJPWinner(inStream, obj as SNGJPWinner);
                result = true;
                break;
            case 86218:
                this.readResponseSNGJPFeed(inStream, obj as ResponseSNGJPFeed);
                result = true;
                break;
            case 86219:
                this.readResponseSNGJPFeedDelta(inStream, obj as ResponseSNGJPFeedDelta);
                result = true;
                break;
            case 86220:
                this.readRequestSNGJPLobbyData(inStream, obj as RequestSNGJPLobbyData);
                result = true;
                break;
            case 86221:
                this.readResponseSNGJPLobbyData(inStream, obj as ResponseSNGJPLobbyData);
                result = true;
                break;
            case 86222:
                this.readSNGJPWinnerDeleteEvent(inStream, obj as SNGJPWinnerDeleteEvent);
                result = true;
                break;
            case 86223:
                this.readRequestFFDataOnClick(inStream, obj as RequestFFDataOnClick);
                result = true;
                break;
            case 86224:
                this.readResponseFFDataOnClick(inStream, obj as ResponseFFDataOnClick);
                result = true;
                break;
            case 86225:
                this.readPromoSNGTemplate(inStream, obj as PromoSNGTemplate);
                result = true;
                break;
            case 86226:
                this.readRequestSNGPromoUpdate(inStream, obj as RequestSNGPromoUpdate);
                result = true;
                break;
            case 86227:
                this.readResponseSNGPromoUpdate(inStream, obj as ResponseSNGPromoUpdate);
                result = true;
                break;
            case 86228:
                this.readSNGPromoDeleteEvent(inStream, obj as SNGPromoDeleteEvent);
                result = true;
                break;
            case 86229:
                this.readSNGPromoSNGTemplateDeleteEvent(inStream, obj as SNGPromoSNGTemplateDeleteEvent);
                result = true;
                break;
            case 86230:
                this.readResponseSNGPromoDeltaChanges(inStream, obj as ResponseSNGPromoDeltaChanges);
                result = true;
                break;
            case 86231:
                this.readSNGJPInstanceWatchers(inStream, obj as SNGJPInstanceWatchers);
                result = true;
                break;
            case 86232:
                this.readLSMultiDayWiseData(inStream, obj as LSMultiDayWiseData);
                result = true;
                break;
            case 86233:
                this.readLSMultiDayData(inStream, obj as LSMultiDayData);
                result = true;
                break;
            case 86234:
                this.readLSMultiDayChangeData(inStream, obj as LSMultiDayChangeData);
                result = true;
                break;
            case 86235:
                this.readLSFlightedNextDayDetails(inStream, obj as LSFlightedNextDayDetails);
                result = true;
                break;
            case 86236:
                this.readLSFlightedData(inStream, obj as LSFlightedData);
                result = true;
                break;
            case 86237:
                this.readRequestFeatureGamesLobbyUpdate(inStream, obj as RequestFeatureGamesLobbyUpdate);
                result = true;
                break;
            case 86238:
                this.readResponseFeatureGamesLobbyUpdate(inStream, obj as ResponseFeatureGamesLobbyUpdate);
                result = true;
                break;
            case 86239:
                this.readLSFeaturedTableDetailsDeltaUpdate(inStream, obj as LSFeaturedTableDetailsDeltaUpdate);
                result = true;
                break;
            case 86240:
                this.readLSMixMaxEntry(inStream, obj as LSMixMaxEntry);
                result = true;
                break;
            case 86241:
                this.readLSMixMaxData(inStream, obj as LSMixMaxData);
                result = true;
                break;
            case 86244:
                this.readLSHeadsUpParticipantStatus(inStream, obj as LSHeadsUpParticipantStatus);
                result = true;
                break;
            case 86247:
                this.readRequestMTCTLobbyPlayersUnSubscribe(inStream, obj as RequestMTCTLobbyPlayersUnSubscribe);
                result = true;
                break;
            case 86248:
                this.readRequestMTCTLobbyTablesUnSubscribe(inStream, obj as RequestMTCTLobbyTablesUnSubscribe);
                result = true;
                break;
            case 86249:
                this.readRequestMTCTLobbyPlayerTablesUnSubscribe(inStream, obj as RequestMTCTLobbyPlayerTablesUnSubscribe);
                result = true;
                break;
            case 86250:
                this.readRequestPlayerTrackInfo(inStream, obj as RequestPlayerTrackInfo);
                result = true;
                break;
            case 86251:
                this.readRequestUnSubscribePlayerTrackInfo(inStream, obj as RequestUnSubscribePlayerTrackInfo);
                result = true;
                break;
            case 86252:
                this.readResponsePlayerTrackInfo(inStream, obj as ResponsePlayerTrackInfo);
                result = true;
                break;
            case 86253:
                this.readLSMTCTUpdateChanges(inStream, obj as LSMTCTUpdateChanges);
                result = true;
                break;
            case 86254:
                this.readLSMTCTUpdateMinChanges(inStream, obj as LSMTCTUpdateMinChanges);
                result = true;
                break;
            case 86255:
                this.readRequestTourneySearchMTCTParticipantNames(inStream, obj as RequestTourneySearchMTCTParticipantNames);
                result = true;
                break;
            case 86256:
                this.readRequestLSTourneyParticipantNames(inStream, obj as RequestLSTourneyParticipantNames);
                result = true;
                break;
            case 86257:
                this.readResponseLSTourneyParticipantNames(inStream, obj as ResponseLSTourneyParticipantNames);
                result = true;
                break;
            case 86258:
                this.readMTCTFontDetails(inStream, obj as MTCTFontDetails);
                result = true;
                break;
            case 86273:
                this.readRequestSimilarMTCT(inStream, obj as RequestSimilarMTCT);
                result = true;
                break;
            case 86274:
                this.readResponseSimilarMTCT(inStream, obj as ResponseSimilarMTCT);
                result = true;
                break;
            case 86275:
                this.readLSReentryInfo(inStream, obj as LSReentryInfo);
                result = true;
                break;
            case 86276:
                this.readLSTableInfoUpdate(inStream, obj as LSTableInfoUpdate);
                result = true;
                break;
            case 86277:
                this.readLSTabIdTableInfoDetails(inStream, obj as LSTabIdTableInfoDetails);
                result = true;
                break;
            case 86278:
                this.readRequestGroupLobbyUpdate(inStream, obj as RequestGroupLobbyUpdate);
                result = true;
                break;
            case 86279:
                this.readResponseGroupCashDetailsList(inStream, obj as ResponseGroupCashDetailsList);
                result = true;
                break;
            case 86280:
                this.readRequestSatellitesData(inStream, obj as RequestSatellitesData);
                result = true;
                break;
            case 86281:
                this.readResponseSatellitesData(inStream, obj as ResponseSatellitesData);
                result = true;
                break;
            case 86282:
                this.readLSTourneyTimeToAct(inStream, obj as LSTourneyTimeToAct);
                result = true;
                break;
            case 86283:
                this.readLSTourneyTimeBank(inStream, obj as LSTourneyTimeBank);
                result = true;
                break;
            case 86284:
                this.readResponseLSDynaDeltaChanges(inStream, obj as ResponseLSDynaDeltaChanges);
                result = true;
                break;
            case 86285:
                this.readRequestLSLobbyDyna(inStream, obj as RequestLSLobbyDyna);
                result = true;
                break;
            case 86286:
                this.readLSTabIdTableInfoDeltaUpdate(inStream, obj as LSTabIdTableInfoDeltaUpdate);
                result = true;
                break;
            case 86287:
                this.readPLSAuthenticationRequest(inStream, obj as PLSAuthenticationRequest);
                result = true;
                break;
            case 86288:
                this.readPLSAuthenticationResponse(inStream, obj as PLSAuthenticationResponse);
                result = true;
                break;
            case 86290:
                this.readRequestPxTablesGridOnPageNumber(inStream, obj as RequestPxTablesGridOnPageNumber);
                result = true;
                break;
            case 86291:
                this.readRequestPxPlayersGridOnPageNumber(inStream, obj as RequestPxPlayersGridOnPageNumber);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    write(obj: Message, outStream: TSDataOutputStream) {
        const classId = this.getClassIdByName(obj);
        let result = false;
        switch (classId) {
            case 86017:
                this.writeLSTabIdLastUpdatedTime(outStream, obj as LSTabIdLastUpdatedTime);
                result = true;
                break;
            case 86018:
                this.writeRequestLobbyUpdate(outStream, obj as RequestLobbyUpdate);
                result = true;
                break;
            case 86019:
                this.writeRequestLobbyActive(outStream, obj as RequestLobbyActive);
                result = true;
                break;
            case 86020:
                this.writeLSTableInfoDetails(outStream, obj as LSTableInfoDetails);
                result = true;
                break;
            case 86021:
                this.writeLSDeletedTableInfoDetails(outStream, obj as LSDeletedTableInfoDetails);
                result = true;
                break;
            case 86022:
                this.writeResponseTableInfoDetailsList(outStream, obj as ResponseTableInfoDetailsList);
                result = true;
                break;
            case 86023:
                this.writeLSTableInfoDeltaUpdate(outStream, obj as LSTableInfoDeltaUpdate);
                result = true;
                break;
            case 86024:
                this.writeLSTableDetails(outStream, obj as LSTableDetails);
                result = true;
                break;
            case 86025:
                this.writeLSDeletedTableDetails(outStream, obj as LSDeletedTableDetails);
                result = true;
                break;
            case 86026:
                this.writeLSTabIdTableDetails(outStream, obj as LSTabIdTableDetails);
                result = true;
                break;
            case 86027:
                this.writeResponseTableDetailsList(outStream, obj as ResponseTableDetailsList);
                result = true;
                break;
            case 86028:
                this.writeLSTableGameStats(outStream, obj as LSTableGameStats);
                result = true;
                break;
            case 86029:
                this.writeLSTableOccupancyChange(outStream, obj as LSTableOccupancyChange);
                result = true;
                break;
            case 86030:
                this.writeLSTableDetailsDeltaUpdate(outStream, obj as LSTableDetailsDeltaUpdate);
                result = true;
                break;
            case 86031:
                this.writeRequestPlayerList(outStream, obj as RequestPlayerList);
                result = true;
                break;
            case 86032:
                this.writeLSPlayerInfo(outStream, obj as LSPlayerInfo);
                result = true;
                break;
            case 86033:
                this.writeLSDealerBlindPositions(outStream, obj as LSDealerBlindPositions);
                result = true;
                break;
            case 86034:
                this.writeResponsePlayerList(outStream, obj as ResponsePlayerList);
                result = true;
                break;
            case 86035:
                this.writeLSPlayerBalances(outStream, obj as LSPlayerBalances);
                result = true;
                break;
            case 86036:
                this.writeLSRemovedPlayerInfo(outStream, obj as LSRemovedPlayerInfo);
                result = true;
                break;
            case 86037:
                this.writeLSPlayerListDeltaUpdate(outStream, obj as LSPlayerListDeltaUpdate);
                result = true;
                break;
            case 86038:
                this.writeRequestPlayerSearch(outStream, obj as RequestPlayerSearch);
                result = true;
                break;
            case 86040:
                this.writeSearchResultForPlayer(outStream, obj as SearchResultForPlayer);
                result = true;
                break;
            case 86041:
                this.writeResponsePlayerSearch(outStream, obj as ResponsePlayerSearch);
                result = true;
                break;
            case 86042:
                this.writeLSPoolInfoDetails(outStream, obj as LSPoolInfoDetails);
                result = true;
                break;
            case 86043:
                this.writeLSDeletedPoolInfoDetails(outStream, obj as LSDeletedPoolInfoDetails);
                result = true;
                break;
            case 86044:
                this.writeResponsePoolInfoDetailsList(outStream, obj as ResponsePoolInfoDetailsList);
                result = true;
                break;
            case 86045:
                this.writeLSPoolInfoDetailsDeltaUpdate(outStream, obj as LSPoolInfoDetailsDeltaUpdate);
                result = true;
                break;
            case 86046:
                this.writeLSPoolDetails(outStream, obj as LSPoolDetails);
                result = true;
                break;
            case 86047:
                this.writeLSDeletedPoolDetails(outStream, obj as LSDeletedPoolDetails);
                result = true;
                break;
            case 86048:
                this.writeLSTabIdPoolDetails(outStream, obj as LSTabIdPoolDetails);
                result = true;
                break;
            case 86049:
                this.writeResponsePoolDetailsList(outStream, obj as ResponsePoolDetailsList);
                result = true;
                break;
            case 86050:
                this.writeLSPoolPlayerCount(outStream, obj as LSPoolPlayerCount);
                result = true;
                break;
            case 86051:
                this.writeLSPoolGameStats(outStream, obj as LSPoolGameStats);
                result = true;
                break;
            case 86052:
                this.writeLSPoolDetailsDeltaUpdate(outStream, obj as LSPoolDetailsDeltaUpdate);
                result = true;
                break;
            case 86053:
                this.writeRequestPoolPlayerList(outStream, obj as RequestPoolPlayerList);
                result = true;
                break;
            case 86054:
                this.writeLSPoolPlayerInfo(outStream, obj as LSPoolPlayerInfo);
                result = true;
                break;
            case 86055:
                this.writeResponsePoolPlayerList(outStream, obj as ResponsePoolPlayerList);
                result = true;
                break;
            case 86056:
                this.writeLSPoolRemovePlayerInfo(outStream, obj as LSPoolRemovePlayerInfo);
                result = true;
                break;
            case 86057:
                this.writeLSPoolPlayerListDeltaUpdate(outStream, obj as LSPoolPlayerListDeltaUpdate);
                result = true;
                break;
            case 86058:
                this.writeResponseWaitList(outStream, obj as ResponseWaitList);
                result = true;
                break;
            case 86059:
                this.writeLSMTCTLobbyData(outStream, obj as LSMTCTLobbyData);
                result = true;
                break;
            case 86060:
                this.writeLSTabIdMTCTDetails(outStream, obj as LSTabIdMTCTDetails);
                result = true;
                break;
            case 86061:
                this.writeResponseMTCTDetailsList(outStream, obj as ResponseMTCTDetailsList);
                result = true;
                break;
            case 86062:
                this.writeLSMTCTDeleteEvent(outStream, obj as LSMTCTDeleteEvent);
                result = true;
                break;
            case 86063:
                this.writeLSMTCTStatusChangeEvent(outStream, obj as LSMTCTStatusChangeEvent);
                result = true;
                break;
            case 86064:
                this.writeLSMTCTCustomPropertyChangeEvent(outStream, obj as LSMTCTCustomPropertyChangeEvent);
                result = true;
                break;
            case 86065:
                this.writeLSMTCTLevelChangeEvent(outStream, obj as LSMTCTLevelChangeEvent);
                result = true;
                break;
            case 86066:
                this.writeLSMTCTPrizePoolChangeEvent(outStream, obj as LSMTCTPrizePoolChangeEvent);
                result = true;
                break;
            case 86067:
                this.writeLSMTCTFirstRankChangeEvent(outStream, obj as LSMTCTFirstRankChangeEvent);
                result = true;
                break;
            case 86068:
                this.writeLSMTCTPlayerCountChangeEvent(outStream, obj as LSMTCTPlayerCountChangeEvent);
                result = true;
                break;
            case 86069:
                this.writeLSMTCTRegistrationChangeEvent(outStream, obj as LSMTCTRegistrationChangeEvent);
                result = true;
                break;
            case 86070:
                this.writeLSMTCTLateRegistrationChangeEvent(outStream, obj as LSMTCTLateRegistrationChangeEvent);
                result = true;
                break;
            case 86071:
                this.writeLSMTCTUnRegistrationCloseChangeEvent(outStream, obj as LSMTCTUnRegistrationCloseChangeEvent);
                result = true;
                break;
            case 86072:
                this.writeLSMTCTDetailsDeltaUpdate(outStream, obj as LSMTCTDetailsDeltaUpdate);
                result = true;
                break;
            case 86073:
                this.writeResponseMTCTDynaDetailsList(outStream, obj as ResponseMTCTDynaDetailsList);
                result = true;
                break;
            case 86074:
                this.writeLSTabIdDynamicLanguagePackDetails(outStream, obj as LSTabIdDynamicLanguagePackDetails);
                result = true;
                break;
            case 86075:
                this.writeLSDynamicLanguagePackUpdate(outStream, obj as LSDynamicLanguagePackUpdate);
                result = true;
                break;
            case 86076:
                this.writeRequestMTCTLobbyData(outStream, obj as RequestMTCTLobbyData);
                result = true;
                break;
            case 86077:
                this.writeResponseMTCTLobbyData(outStream, obj as ResponseMTCTLobbyData);
                result = true;
                break;
            case 86078:
                this.writeLSTourneyRoundInfo(outStream, obj as LSTourneyRoundInfo);
                result = true;
                break;
            case 86079:
                this.writeLSTourneyPrizeInfo(outStream, obj as LSTourneyPrizeInfo);
                result = true;
                break;
            case 86080:
                this.writeLSTourneyRebuyAddonInfo(outStream, obj as LSTourneyRebuyAddonInfo);
                result = true;
                break;
            case 86081:
                this.writeLSTourneyShootoutInfo(outStream, obj as LSTourneyShootoutInfo);
                result = true;
                break;
            case 86082:
                this.writeLSTourneyLobbyStackInfo(outStream, obj as LSTourneyLobbyStackInfo);
                result = true;
                break;
            case 86083:
                this.writeLSHeadsUpTourenyPlayerSequence(outStream, obj as LSHeadsUpTourenyPlayerSequence);
                result = true;
                break;
            case 86084:
                this.writeLSTourneyBlindLevel(outStream, obj as LSTourneyBlindLevel);
                result = true;
                break;
            case 86085:
                this.writeLSTourneyBlindStructureDetails(outStream, obj as LSTourneyBlindStructureDetails);
                result = true;
                break;
            case 86086:
                this.writeLSTourneyLobbyData(outStream, obj as LSTourneyLobbyData);
                result = true;
                break;
            case 86087:
                this.writeLSTourneyCustomPropertyChange(outStream, obj as LSTourneyCustomPropertyChange);
                result = true;
                break;
            case 86088:
                this.writeLSTourneyDealMakingChange(outStream, obj as LSTourneyDealMakingChange);
                result = true;
                break;
            case 86089:
                this.writeLSTourneyLateRegistrationChange(outStream, obj as LSTourneyLateRegistrationChange);
                result = true;
                break;
            case 86090:
                this.writeLSTourneyLevelChange(outStream, obj as LSTourneyLevelChange);
                result = true;
                break;
            case 86091:
                this.writeLSTourneyPlayerGridRow(outStream, obj as LSTourneyPlayerGridRow);
                result = true;
                break;
            case 86092:
                this.writeLSTourneyRebuyAddonChange(outStream, obj as LSTourneyRebuyAddonChange);
                result = true;
                break;
            case 86093:
                this.writeLSTourneyRegistrationChange(outStream, obj as LSTourneyRegistrationChange);
                result = true;
                break;
            case 86094:
                this.writeLSTourneyRoundChange(outStream, obj as LSTourneyRoundChange);
                result = true;
                break;
            case 86095:
                this.writeLSTourneyStatusChange(outStream, obj as LSTourneyStatusChange);
                result = true;
                break;
            case 86096:
                this.writeLSTourneySyncAddonBreakTimeChange(outStream, obj as LSTourneySyncAddonBreakTimeChange);
                result = true;
                break;
            case 86097:
                this.writeLSTourneyTablePlayer(outStream, obj as LSTourneyTablePlayer);
                result = true;
                break;
            case 86098:
                this.writeLSTourneyTablesGridRowDetails(outStream, obj as LSTourneyTablesGridRowDetails);
                result = true;
                break;
            case 86099:
                this.writeLSTourneyUnRegistrationCloseChangeEvent(outStream, obj as LSTourneyUnRegistrationCloseChangeEvent);
                result = true;
                break;
            case 86100:
                this.writeLSTourneyWatcherCountChange(outStream, obj as LSTourneyWatcherCountChange);
                result = true;
                break;
            case 86101:
                this.writeLSTourneyPlayerCountChange(outStream, obj as LSTourneyPlayerCountChange);
                result = true;
                break;
            case 86102:
                this.writeLSTourneyParticipantName(outStream, obj as LSTourneyParticipantName);
                result = true;
                break;
            case 86103:
                this.writeRequestMonitorMTCTTable(outStream, obj as RequestMonitorMTCTTable);
                result = true;
                break;
            case 86104:
                this.writeRequestLobbyDyna(outStream, obj as RequestLobbyDyna);
                result = true;
                break;
            case 86105:
                this.writeRequestMTCTLobbyGridOnPageNumber(outStream, obj as RequestMTCTLobbyGridOnPageNumber);
                result = true;
                break;
            case 86106:
                this.writeRequestMTCTLobbyGridOnScreenName(outStream, obj as RequestMTCTLobbyGridOnScreenName);
                result = true;
                break;
            case 86107:
                this.writeRequestMTCTLobbyTablesOnPageNumber(outStream, obj as RequestMTCTLobbyTablesOnPageNumber);
                result = true;
                break;
            case 86108:
                this.writeRequestMTCTLobbyTablesOnScreenName(outStream, obj as RequestMTCTLobbyTablesOnScreenName);
                result = true;
                break;
            case 86109:
                this.writeRequestLSMTCTParticipantNames(outStream, obj as RequestLSMTCTParticipantNames);
                result = true;
                break;
            case 86110:
                this.writeRequestOpenMTCTLobby(outStream, obj as RequestOpenMTCTLobby);
                result = true;
                break;
            case 86111:
                this.writeResponseLobbyDyna(outStream, obj as ResponseLobbyDyna);
                result = true;
                break;
            case 86112:
                this.writeResponseLSMTCTParticipantNames(outStream, obj as ResponseLSMTCTParticipantNames);
                result = true;
                break;
            case 86113:
                this.writeResponseTourenyLobbyPlayersGrid(outStream, obj as ResponseTourenyLobbyPlayersGrid);
                result = true;
                break;
            case 86114:
                this.writeResponseTourenyLobbyTablesGrid(outStream, obj as ResponseTourenyLobbyTablesGrid);
                result = true;
                break;
            case 86115:
                this.writeResponseTourenyTablePlayerList(outStream, obj as ResponseTourenyTablePlayerList);
                result = true;
                break;
            case 86116:
                this.writeResponseTourneyLobbyDeltaChanges(outStream, obj as ResponseTourneyLobbyDeltaChanges);
                result = true;
                break;
            case 86117:
                this.writeResponseTourneyLobbyDetails(outStream, obj as ResponseTourneyLobbyDetails);
                result = true;
                break;
            case 86118:
                this.writeLSSTTTableGameStatus(outStream, obj as LSSTTTableGameStatus);
                result = true;
                break;
            case 86119:
                this.writeRingPlayerSearchResultTable(outStream, obj as RingPlayerSearchResultTable);
                result = true;
                break;
            case 86120:
                this.writeSTTPlayerSearchResultTable(outStream, obj as STTPlayerSearchResultTable);
                result = true;
                break;
            case 86121:
                this.writeResponseDynaDetailsDeltaChanges(outStream, obj as ResponseDynaDetailsDeltaChanges);
                result = true;
                break;
            case 86122:
                this.writeRequestLobbyUnSubscribe(outStream, obj as RequestLobbyUnSubscribe);
                result = true;
                break;
            case 86123:
                this.writeResponseTourneyParticipantStatus(outStream, obj as ResponseTourneyParticipantStatus);
                result = true;
                break;
            case 86124:
                this.writeLSTourneyParticipantStatus(outStream, obj as LSTourneyParticipantStatus);
                result = true;
                break;
            case 86125:
                this.writeRequestTableForMTCTParticipant(outStream, obj as RequestTableForMTCTParticipant);
                result = true;
                break;
            case 86126:
                this.writeResponseMTCTTableInfoForParticipant(outStream, obj as ResponseMTCTTableInfoForParticipant);
                result = true;
                break;
            case 86127:
                this.writeLSTourneyCompletionChangeEvent(outStream, obj as LSTourneyCompletionChangeEvent);
                result = true;
                break;
            case 86128:
                this.writeFFCard(outStream, obj as FFCard);
                result = true;
                break;
            case 86129:
                this.writeMTTCard(outStream, obj as MTTCard);
                result = true;
                break;
            case 86130:
                this.writeRingGameCard(outStream, obj as RingGameCard);
                result = true;
                break;
            case 86131:
                this.writeMTSGCard(outStream, obj as MTSGCard);
                result = true;
                break;
            case 86132:
                this.writeSNGCard(outStream, obj as SNGCard);
                result = true;
                break;
            case 86133:
                this.writeResponseLobbyUpdateOneClick(outStream, obj as ResponseLobbyUpdateOneClick);
                result = true;
                break;
            case 86134:
                this.writeRequestQuickSeatFastForward(outStream, obj as RequestQuickSeatFastForward);
                result = true;
                break;
            case 86135:
                this.writeResponseQuickSeatFastForward(outStream, obj as ResponseQuickSeatFastForward);
                result = true;
                break;
            case 86136:
                this.writeRequestQuickSeatRing(outStream, obj as RequestQuickSeatRing);
                result = true;
                break;
            case 86137:
                this.writeResponseQuickSeatRing(outStream, obj as ResponseQuickSeatRing);
                result = true;
                break;
            case 86138:
                this.writeLSCurrencyAmount(outStream, obj as LSCurrencyAmount);
                result = true;
                break;
            case 86139:
                this.writeRequestSearchMTCTParticipantNames(outStream, obj as RequestSearchMTCTParticipantNames);
                result = true;
                break;
            case 86140:
                this.writeRequestLSHeadsUpTourenyPlayerSequence(outStream, obj as RequestLSHeadsUpTourenyPlayerSequence);
                result = true;
                break;
            case 86141:
                this.writeResponseLSHeadsUpTourenyPlayerSequence(outStream, obj as ResponseLSHeadsUpTourenyPlayerSequence);
                result = true;
                break;
            case 86142:
                this.writeResponseLSMTCTStatus(outStream, obj as ResponseLSMTCTStatus);
                result = true;
                break;
            case 86143:
                this.writeLSMTCTStartTimeChangeEvent(outStream, obj as LSMTCTStartTimeChangeEvent);
                result = true;
                break;
            case 86144:
                this.writeLSTourneyStartTimeChange(outStream, obj as LSTourneyStartTimeChange);
                result = true;
                break;
            case 86145:
                this.writeLSMTCTEventsQualifiersChangeEvent(outStream, obj as LSMTCTEventsQualifiersChangeEvent);
                result = true;
                break;
            case 86146:
                this.writeLSTourneyEventsQualifiersChange(outStream, obj as LSTourneyEventsQualifiersChange);
                result = true;
                break;
            case 86147:
                this.writeLSPoolPlayerCountDeltaUpdate(outStream, obj as LSPoolPlayerCountDeltaUpdate);
                result = true;
                break;
            case 86148:
                this.writeLSTableOccupancyDeltaUpdate(outStream, obj as LSTableOccupancyDeltaUpdate);
                result = true;
                break;
            // case 86149: this.writeLSTourneyDeleteChange(outStream, obj as LSTourneyDeleteChange); result = true; break;
            case 86150:
                this.writeRequestLSPlayerTableCounts(outStream, obj as RequestLSPlayerTableCounts);
                result = true;
                break;
            case 86156:
                this.writeResultQuickSeatRing(outStream, obj as ResultQuickSeatRing);
                result = true;
                break;
            case 86157:
                this.writeRequestLobbyUpdateOneClick(outStream, obj as RequestLobbyUpdateOneClick);
                result = true;
                break;
            case 86158:
                this.writeLSLobbyCardDeltaUpdate(outStream, obj as LSLobbyCardDeltaUpdate);
                result = true;
                break;
            case 86159:
                this.writeDeleteCard(outStream, obj as DeleteCard);
                result = true;
                break;
            case 86160:
                this.writeUpdateCardLastPlayed(outStream, obj as UpdateCardLastPlayed);
                result = true;
                break;
            case 86161:
                this.writeUpdateCardMTTRegistrationStatus(outStream, obj as UpdateCardMTTRegistrationStatus);
                result = true;
                break;
            case 86162:
                this.writeUpdateCardMTTRegistrantCount(outStream, obj as UpdateCardMTTRegistrantCount);
                result = true;
                break;
            case 86163:
                this.writeUpdateCardTag(outStream, obj as UpdateCardTag);
                result = true;
                break;
            case 86164:
                this.writePlayerLobbyToGameplayTrackerInfo(outStream, obj as PlayerLobbyToGameplayTrackerInfo);
                result = true;
                break;
            case 86165:
                this.writeRequestLobbyConfig(outStream, obj as RequestLobbyConfig);
                result = true;
                break;
            case 86166:
                this.writeResponseLobbyConfig(outStream, obj as ResponseLobbyConfig);
                result = true;
                break;
            case 86167:
                this.writeLSTopCardsOrderDeltaUpdate(outStream, obj as LSTopCardsOrderDeltaUpdate);
                result = true;
                break;
            case 86168:
                this.writeAddFavoriteCard(outStream, obj as AddFavoriteCard);
                result = true;
                break;
            case 86169:
                this.writeDeleteFavoriteCard(outStream, obj as DeleteFavoriteCard);
                result = true;
                break;
            case 86170:
                this.writeResponseDeleteFavoriteCard(outStream, obj as ResponseDeleteFavoriteCard);
                result = true;
                break;
            case 86171:
                this.writeResponseAddFavoriteCard(outStream, obj as ResponseAddFavoriteCard);
                result = true;
                break;
            case 86173:
                this.writeFavoriteCards(outStream, obj as FavoriteCards);
                result = true;
                break;
            case 86174:
                this.writeRequestSNGQuickRegister(outStream, obj as RequestSNGQuickRegister);
                result = true;
                break;
            case 86175:
                this.writeResponseSNGQuickRegister(outStream, obj as ResponseSNGQuickRegister);
                result = true;
                break;
            case 86176:
                this.writeOneClickTourneyStatusChange(outStream, obj as OneClickTourneyStatusChange);
                result = true;
                break;
            case 86177:
                this.writeOneClickTourneyRegistrationChange(outStream, obj as OneClickTourneyRegistrationChange);
                result = true;
                break;
            case 86178:
                this.writeOneClickTourneyLateRegistrationChange(outStream, obj as OneClickTourneyLateRegistrationChange);
                result = true;
                break;
            case 86179:
                this.writeOneClickTourneyTicketCardChanges(outStream, obj as OneClickTourneyTicketCardChanges);
                result = true;
                break;
            case 86181:
                this.writeLSItaliaAuthorizationCodes(outStream, obj as LSItaliaAuthorizationCodes);
                result = true;
                break;
            case 86182:
                this.writeLobbyPlayerTableCounts(outStream, obj as LobbyPlayerTableCounts);
                result = true;
                break;
            case 86183:
                this.writeRequestSNGQuickRegisterAdvanced(outStream, obj as RequestSNGQuickRegisterAdvanced);
                result = true;
                break;
            case 86184:
                this.writeResponseSNGQuickRegisterAdvanced(outStream, obj as ResponseSNGQuickRegisterAdvanced);
                result = true;
                break;
            case 86185:
                this.writeOneClickTourneyUnregistrationCloseChange(outStream, obj as OneClickTourneyUnregistrationCloseChange);
                result = true;
                break;
            case 86186:
                this.writeChangeTabRequest(outStream, obj as ChangeTabRequest);
                result = true;
                break;
            case 86187:
                this.writePMUQuintePokCounter(outStream, obj as PMUQuintePokCounter);
                result = true;
                break;
            case 86188:
                this.writeResponseTickersUpdate(outStream, obj as ResponseTickersUpdate);
                result = true;
                break;
            case 86189:
                this.writeRequestTourneyLobbyActive(outStream, obj as RequestTourneyLobbyActive);
                result = true;
                break;
            case 86190:
                this.writeRequestSNGGroupRegister(outStream, obj as RequestSNGGroupRegister);
                result = true;
                break;
            case 86191:
                this.writeResponseSNGGroupRegister(outStream, obj as ResponseSNGGroupRegister);
                result = true;
                break;
            case 86192:
                this.writeResponseTourneyIdsAcceptingFreerolls(outStream, obj as ResponseTourneyIdsAcceptingFreerolls);
                result = true;
                break;
            case 86194:
                this.writeResponseMyTicketsCount(outStream, obj as ResponseMyTicketsCount);
                result = true;
                break;
            case 86195:
                this.writeSNGPromoUpdate(outStream, obj as SNGPromoUpdate);
                result = true;
                break;
            case 86196:
                this.writeLSSNGPromoUpdate(outStream, obj as LSSNGPromoUpdate);
                result = true;
                break;
            case 86197:
                this.writeRequestSNGInstance(outStream, obj as RequestSNGInstance);
                result = true;
                break;
            case 86198:
                this.writeResponseSNGInstance(outStream, obj as ResponseSNGInstance);
                result = true;
                break;
            case 86199:
                this.writeRequestTourneyCount(outStream, obj as RequestTourneyCount);
                result = true;
                break;
            case 86200:
                this.writeResponseTourneyCount(outStream, obj as ResponseTourneyCount);
                result = true;
                break;
            case 86201:
                this.writeLSPrizeDetailsMessage(outStream, obj as LSPrizeDetailsMessage);
                result = true;
                break;
            case 86202:
                this.writeRequestLSPlayerTourneyTabInfo(outStream, obj as RequestLSPlayerTourneyTabInfo);
                result = true;
                break;
            case 86203:
                this.writeResponseLSPlayerTourneyTabInfo(outStream, obj as ResponseLSPlayerTourneyTabInfo);
                result = true;
                break;
            case 86204:
                this.writeLSRequestMTCTId(outStream, obj as LSRequestMTCTId);
                result = true;
                break;
            case 86205:
                this.writeLSRequestMTCTIdRes(outStream, obj as LSRequestMTCTIdRes);
                result = true;
                break;
            case 86206:
                this.writeRequestSNGJPPlayNowUpdate(outStream, obj as RequestSNGJPPlayNowUpdate);
                result = true;
                break;
            case 86207:
                this.writeResponseSNGJPPlayNowUpdate(outStream, obj as ResponseSNGJPPlayNowUpdate);
                result = true;
                break;
            case 86208:
                this.writeResponseSNGJPPlayNowDeltaChanges(outStream, obj as ResponseSNGJPPlayNowDeltaChanges);
                result = true;
                break;
            case 86209:
                this.writeSNGJPCard(outStream, obj as SNGJPCard);
                result = true;
                break;
            case 86210:
                this.writeSNGJPCardDeleteEvent(outStream, obj as SNGJPCardDeleteEvent);
                result = true;
                break;
            case 86211:
                this.writeRequestSNGJPObserverUpdate(outStream, obj as RequestSNGJPObserverUpdate);
                result = true;
                break;
            case 86212:
                this.writeResponseSNGJPObserverUpdate(outStream, obj as ResponseSNGJPObserverUpdate);
                result = true;
                break;
            case 86213:
                this.writeResponseSNGJPObserverDeltaChanges(outStream, obj as ResponseSNGJPObserverDeltaChanges);
                result = true;
                break;
            case 86214:
                this.writeSNGJPInstanceCard(outStream, obj as SNGJPInstanceCard);
                result = true;
                break;
            case 86215:
                this.writeSNGJPInstanceCardDeleteEvent(outStream, obj as SNGJPInstanceCardDeleteEvent);
                result = true;
                break;
            case 86216:
                this.writeRequestSNGJPFeed(outStream, obj as RequestSNGJPFeed);
                result = true;
                break;
            case 86217:
                this.writeSNGJPWinner(outStream, obj as SNGJPWinner);
                result = true;
                break;
            case 86218:
                this.writeResponseSNGJPFeed(outStream, obj as ResponseSNGJPFeed);
                result = true;
                break;
            case 86219:
                this.writeResponseSNGJPFeedDelta(outStream, obj as ResponseSNGJPFeedDelta);
                result = true;
                break;
            case 86220:
                this.writeRequestSNGJPLobbyData(outStream, obj as RequestSNGJPLobbyData);
                result = true;
                break;
            case 86221:
                this.writeResponseSNGJPLobbyData(outStream, obj as ResponseSNGJPLobbyData);
                result = true;
                break;
            case 86222:
                this.writeSNGJPWinnerDeleteEvent(outStream, obj as SNGJPWinnerDeleteEvent);
                result = true;
                break;
            case 86223:
                this.writeRequestFFDataOnClick(outStream, obj as RequestFFDataOnClick);
                result = true;
                break;
            case 86224:
                this.writeResponseFFDataOnClick(outStream, obj as ResponseFFDataOnClick);
                result = true;
                break;
            case 86225:
                this.writePromoSNGTemplate(outStream, obj as PromoSNGTemplate);
                result = true;
                break;
            case 86226:
                this.writeRequestSNGPromoUpdate(outStream, obj as RequestSNGPromoUpdate);
                result = true;
                break;
            case 86227:
                this.writeResponseSNGPromoUpdate(outStream, obj as ResponseSNGPromoUpdate);
                result = true;
                break;
            case 86228:
                this.writeSNGPromoDeleteEvent(outStream, obj as SNGPromoDeleteEvent);
                result = true;
                break;
            case 86229:
                this.writeSNGPromoSNGTemplateDeleteEvent(outStream, obj as SNGPromoSNGTemplateDeleteEvent);
                result = true;
                break;
            case 86230:
                this.writeResponseSNGPromoDeltaChanges(outStream, obj as ResponseSNGPromoDeltaChanges);
                result = true;
                break;
            case 86231:
                this.writeSNGJPInstanceWatchers(outStream, obj as SNGJPInstanceWatchers);
                result = true;
                break;
            case 86232:
                this.writeLSMultiDayWiseData(outStream, obj as LSMultiDayWiseData);
                result = true;
                break;
            case 86233:
                this.writeLSMultiDayData(outStream, obj as LSMultiDayData);
                result = true;
                break;
            case 86234:
                this.writeLSMultiDayChangeData(outStream, obj as LSMultiDayChangeData);
                result = true;
                break;
            case 86235:
                this.writeLSFlightedNextDayDetails(outStream, obj as LSFlightedNextDayDetails);
                result = true;
                break;
            case 86236:
                this.writeLSFlightedData(outStream, obj as LSFlightedData);
                result = true;
                break;
            case 86237:
                this.writeRequestFeatureGamesLobbyUpdate(outStream, obj as RequestFeatureGamesLobbyUpdate);
                result = true;
                break;
            case 86238:
                this.writeResponseFeatureGamesLobbyUpdate(outStream, obj as ResponseFeatureGamesLobbyUpdate);
                result = true;
                break;
            case 86239:
                this.writeLSFeaturedTableDetailsDeltaUpdate(outStream, obj as LSFeaturedTableDetailsDeltaUpdate);
                result = true;
                break;
            case 86240:
                this.writeLSMixMaxEntry(outStream, obj as LSMixMaxEntry);
                result = true;
                break;
            case 86241:
                this.writeLSMixMaxData(outStream, obj as LSMixMaxData);
                result = true;
                break;
            case 86244:
                this.writeLSHeadsUpParticipantStatus(outStream, obj as LSHeadsUpParticipantStatus);
                result = true;
                break;
            case 86247:
                this.writeRequestMTCTLobbyPlayersUnSubscribe(outStream, obj as RequestMTCTLobbyPlayersUnSubscribe);
                result = true;
                break;
            case 86248:
                this.writeRequestMTCTLobbyTablesUnSubscribe(outStream, obj as RequestMTCTLobbyTablesUnSubscribe);
                result = true;
                break;
            case 86249:
                this.writeRequestMTCTLobbyPlayerTablesUnSubscribe(outStream, obj as RequestMTCTLobbyPlayerTablesUnSubscribe);
                result = true;
                break;
            case 86250:
                this.writeRequestPlayerTrackInfo(outStream, obj as RequestPlayerTrackInfo);
                result = true;
                break;
            case 86251:
                this.writeRequestUnSubscribePlayerTrackInfo(outStream, obj as RequestUnSubscribePlayerTrackInfo);
                result = true;
                break;
            case 86252:
                this.writeResponsePlayerTrackInfo(outStream, obj as ResponsePlayerTrackInfo);
                result = true;
                break;
            case 86253:
                this.writeLSMTCTUpdateChanges(outStream, obj as LSMTCTUpdateChanges);
                result = true;
                break;
            case 86254:
                this.writeLSMTCTUpdateMinChanges(outStream, obj as LSMTCTUpdateMinChanges);
                result = true;
                break;
            case 86255:
                this.writeRequestTourneySearchMTCTParticipantNames(outStream, obj as RequestTourneySearchMTCTParticipantNames);
                result = true;
                break;
            case 86256:
                this.writeRequestLSTourneyParticipantNames(outStream, obj as RequestLSTourneyParticipantNames);
                result = true;
                break;
            case 86257:
                this.writeResponseLSTourneyParticipantNames(outStream, obj as ResponseLSTourneyParticipantNames);
                result = true;
                break;
            case 86258:
                this.writeMTCTFontDetails(outStream, obj as MTCTFontDetails);
                result = true;
                break;
            case 86273:
                this.writeRequestSimilarMTCT(outStream, obj as RequestSimilarMTCT);
                result = true;
                break;
            case 86274:
                this.writeResponseSimilarMTCT(outStream, obj as ResponseSimilarMTCT);
                result = true;
                break;
            case 86275:
                this.writeLSReentryInfo(outStream, obj as LSReentryInfo);
                result = true;
                break;
            case 86276:
                this.writeLSTableInfoUpdate(outStream, obj as LSTableInfoUpdate);
                result = true;
                break;
            case 86277:
                this.writeLSTabIdTableInfoDetails(outStream, obj as LSTabIdTableInfoDetails);
                result = true;
                break;
            case 86278:
                this.writeRequestGroupLobbyUpdate(outStream, obj as RequestGroupLobbyUpdate);
                result = true;
                break;
            case 86279:
                this.writeResponseGroupCashDetailsList(outStream, obj as ResponseGroupCashDetailsList);
                result = true;
                break;
            case 86280:
                this.writeRequestSatellitesData(outStream, obj as RequestSatellitesData);
                result = true;
                break;
            case 86281:
                this.writeResponseSatellitesData(outStream, obj as ResponseSatellitesData);
                result = true;
                break;
            case 86282:
                this.writeLSTourneyTimeToAct(outStream, obj as LSTourneyTimeToAct);
                result = true;
                break;
            case 86283:
                this.writeLSTourneyTimeBank(outStream, obj as LSTourneyTimeBank);
                result = true;
                break;
            case 86284:
                this.writeResponseLSDynaDeltaChanges(outStream, obj as ResponseLSDynaDeltaChanges);
                result = true;
                break;
            case 86285:
                this.writeRequestLSLobbyDyna(outStream, obj as RequestLSLobbyDyna);
                result = true;
                break;
            case 86286:
                this.writeLSTabIdTableInfoDeltaUpdate(outStream, obj as LSTabIdTableInfoDeltaUpdate);
                result = true;
                break;
            case 86287:
                this.writePLSAuthenticationRequest(outStream, obj as PLSAuthenticationRequest);
                result = true;
                break;
            case 86288:
                this.writePLSAuthenticationResponse(outStream, obj as PLSAuthenticationResponse);
                result = true;
                break;
            case 86290:
                this.writeRequestPxTablesGridOnPageNumber(outStream, obj as RequestPxTablesGridOnPageNumber);
                result = true;
                break;
            case 86291:
                this.writeRequestPxPlayersGridOnPageNumber(outStream, obj as RequestPxPlayersGridOnPageNumber);
                result = true;
                break;
            default:
                break;
        }
        return result;
    }

    readLSTabIdLastUpdatedTime(inStream: TSDataInputStream, obj: LSTabIdLastUpdatedTime): void {
        obj.setTabId(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
    }

    writeLSTabIdLastUpdatedTime(outStream: TSDataOutputStream, obj: LSTabIdLastUpdatedTime): void {
        outStream.putInteger(obj.getTabId());
        outStream.putLong(obj.getSnapShotTimeInNanos());
    }
    readRequestLobbyUpdate(inStream: TSDataInputStream, obj: RequestLobbyUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActive(inStream.getBoolean());
        obj.setTabSnapshotTimeInNanosList(inStream.getObjectArray());
        obj.setTableId(inStream.getInteger());
        obj.setTableInfoSnapShotTimeInNanos(inStream.getLong());
        obj.setRequestType(inStream.getInteger());
        obj.setFfTabSnapShotList(inStream.getObjectArray());
        obj.setMtctTabSnapShotList(inStream.getObjectArray());
    }

    writeRequestLobbyUpdate(outStream: TSDataOutputStream, obj: RequestLobbyUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getActive());
        outStream.putObjectArray(obj.getTabSnapshotTimeInNanosList());
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getTableInfoSnapShotTimeInNanos());
        outStream.putInteger(obj.getRequestType());
        outStream.putObjectArray(obj.getFfTabSnapShotList());
        outStream.putObjectArray(obj.getMtctTabSnapShotList());
    }
    readRequestLobbyActive(inStream: TSDataInputStream, obj: RequestLobbyActive): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActive(inStream.getBoolean());
    }

    writeRequestLobbyActive(outStream: TSDataOutputStream, obj: RequestLobbyActive): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getActive());
    }
    readLSTableInfoDetails(inStream: TSDataInputStream, obj: LSTableInfoDetails): void {
        obj.setTableInfoId(inStream.getInteger());
        obj.setTableTypeId(inStream.getByte());
        obj.setTableCategory(inStream.getByte());
        obj.setGroupId(inStream.getByte());
        obj.setMaxNoOfSeats(inStream.getByte());
        obj.setGameTypeId(inStream.getByte());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setStakesUpperLimit(inStream.getInteger());
        obj.setTourneyBuyIn(inStream.getInteger());
        obj.setTourneyEntryFee(inStream.getInteger());
        obj.setTableLimitType(inStream.getByte());
        obj.setMinBuyInAmount(inStream.getInteger());
        obj.setMaxBuyInAmount(inStream.getInteger());
        obj.setSmallBlind(inStream.getInteger());
        obj.setBigBlind(inStream.getInteger());
        obj.setAnte(inStream.getInteger());
        obj.setBringIn(inStream.getInteger());
        obj.setAutoLoad(inStream.getBoolean());
        obj.setAllowPlayerToSit(inStream.getBoolean());
        obj.setTabIds(inStream.getIntArray());
        obj.setDisconProtectType(inStream.getInteger());
        obj.setJackpotId(inStream.getInteger());
        obj.setCountDownTime(inStream.getShort());
        obj.setTableColorCode(inStream.getByte());
        obj.setSttCountryRestrictionId(inStream.getInteger());
        obj.setPvtTableType(inStream.getByte());
        obj.setGamePlayType(inStream.getInteger());
        obj.setFamilyId(inStream.getByte());
        obj.setFppBuyIn(inStream.getInteger());
        obj.setTbtMaxTime(inStream.getByte());
        obj.setIsTBTRandomized(inStream.getBoolean());
        obj.setTrnyChips(inStream.getLong());
        obj.setGameCurrency(inStream.getString());
        obj.setIsChampionshipTable(inStream.getBoolean());
        obj.setHuTableType(inStream.getByte());
        obj.setBrandIdList(inStream.getStringArray());
        obj.setIsBeginner(inStream.getBoolean());
        obj.setProtectionLevel(inStream.getInteger());
        obj.setTableProfileType(inStream.getInteger());
        obj.setFeaturedTableImageUrl(inStream.getString());
        obj.setFeaturedTableFeedOrder(inStream.getInteger());
        obj.setTrickettsRoom(inStream.getBoolean());
        obj.setSubLiquidityType(inStream.getInteger());
        obj.setFfPool(inStream.getBoolean());
        obj.setRealNameTable(inStream.getBoolean());
        obj.setAnteCashAmount(inStream.getInteger());
        obj.setRestrictedRoomId(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
        obj.setTablesCount(inStream.getInteger());
        obj.setPlayersCount(inStream.getInteger());
        obj.setName(inStream.getString());
        obj.setGroupingDisbaled(inStream.getBoolean());
    }

    writeLSTableInfoDetails(outStream: TSDataOutputStream, obj: LSTableInfoDetails): void {
        outStream.putInteger(obj.getTableInfoId());
        outStream.putByte(obj.getTableTypeId());
        outStream.putByte(obj.getTableCategory());
        outStream.putByte(obj.getGroupId());
        outStream.putByte(obj.getMaxNoOfSeats());
        outStream.putByte(obj.getGameTypeId());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getStakesUpperLimit());
        outStream.putInteger(obj.getTourneyBuyIn());
        outStream.putInteger(obj.getTourneyEntryFee());
        outStream.putByte(obj.getTableLimitType());
        outStream.putInteger(obj.getMinBuyInAmount());
        outStream.putInteger(obj.getMaxBuyInAmount());
        outStream.putInteger(obj.getSmallBlind());
        outStream.putInteger(obj.getBigBlind());
        outStream.putInteger(obj.getAnte());
        outStream.putInteger(obj.getBringIn());
        outStream.putBoolean(obj.getAutoLoad());
        outStream.putBoolean(obj.getAllowPlayerToSit());
        outStream.putIntArray(obj.getTabIds());
        outStream.putInteger(obj.getDisconProtectType());
        outStream.putInteger(obj.getJackpotId());
        outStream.putShort(obj.getCountDownTime());
        outStream.putByte(obj.getTableColorCode());
        outStream.putInteger(obj.getSttCountryRestrictionId());
        outStream.putByte(obj.getPvtTableType());
        outStream.putInteger(obj.getGamePlayType());
        outStream.putByte(obj.getFamilyId());
        outStream.putInteger(obj.getFppBuyIn());
        outStream.putByte(obj.getTbtMaxTime());
        outStream.putBoolean(obj.getIsTBTRandomized());
        outStream.putLong(obj.getTrnyChips());
        outStream.putString(obj.getGameCurrency());
        outStream.putBoolean(obj.getIsChampionshipTable());
        outStream.putByte(obj.getHuTableType());
        outStream.putStringArray(obj.getBrandIdList());
        outStream.putBoolean(obj.getIsBeginner());
        outStream.putInteger(obj.getProtectionLevel());
        outStream.putInteger(obj.getTableProfileType());
        outStream.putString(obj.getFeaturedTableImageUrl());
        outStream.putInteger(obj.getFeaturedTableFeedOrder());
        outStream.putBoolean(obj.getTrickettsRoom());
        outStream.putInteger(obj.getSubLiquidityType());
        outStream.putBoolean(obj.getFfPool());
        outStream.putBoolean(obj.getRealNameTable());
        outStream.putInteger(obj.getAnteCashAmount());
        outStream.putInteger(obj.getRestrictedRoomId());
        outStream.putInteger(obj.getClubId());
        outStream.putInteger(obj.getTablesCount());
        outStream.putInteger(obj.getPlayersCount());
        outStream.putString(obj.getName());
        outStream.putBoolean(obj.getGroupingDisbaled());
    }
    readLSDeletedTableInfoDetails(inStream: TSDataInputStream, obj: LSDeletedTableInfoDetails): void {
        obj.setTableInfoId(inStream.getInteger());
    }

    writeLSDeletedTableInfoDetails(outStream: TSDataOutputStream, obj: LSDeletedTableInfoDetails): void {
        outStream.putInteger(obj.getTableInfoId());
    }
    readResponseTableInfoDetailsList(inStream: TSDataInputStream, obj: ResponseTableInfoDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
        obj.setTableInfos(inStream.getObjectArray());
        obj.setDeletedTableInfos(inStream.getIntArray());
        obj.setClearAllTabIdSnapShots(inStream.getBoolean());
    }

    writeResponseTableInfoDetailsList(outStream: TSDataOutputStream, obj: ResponseTableInfoDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
        outStream.putObjectArray(obj.getTableInfos());
        outStream.putIntArray(obj.getDeletedTableInfos());
        outStream.putBoolean(obj.getClearAllTabIdSnapShots());
    }
    readLSTableInfoDeltaUpdate(inStream: TSDataInputStream, obj: LSTableInfoDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapShotInNanos(inStream.getLong());
        obj.setTableInfos(inStream.getObjectArray());
        obj.setDeletedTableInfos(inStream.getObjectArray());
    }

    writeLSTableInfoDeltaUpdate(outStream: TSDataOutputStream, obj: LSTableInfoDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSnapShotInNanos());
        outStream.putObjectArray(obj.getTableInfos());
        outStream.putObjectArray(obj.getDeletedTableInfos());
    }
    readLSTableDetails(inStream: TSDataInputStream, obj: LSTableDetails): void {
        obj.setAvgFlopSeen(inStream.getShort());
        obj.setAvgPot(inStream.getLong());
        obj.setHandsPerHour(inStream.getShort());
        obj.setPlayerCount(inStream.getInteger());
        obj.setReservedSeatsCount(inStream.getByte());
        obj.setServerPeerId(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setTableName(inStream.getStringEx());
        obj.setTourneyGameStatus(inStream.getByte());
        obj.setWaitingCount(inStream.getInteger());
        obj.setChallengeType(inStream.getInteger());
    }

    writeLSTableDetails(outStream: TSDataOutputStream, obj: LSTableDetails): void {
        outStream.putShort(obj.getAvgFlopSeen());
        outStream.putLong(obj.getAvgPot());
        outStream.putShort(obj.getHandsPerHour());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putByte(obj.getReservedSeatsCount());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putStringEx(obj.getTableName());
        outStream.putByte(obj.getTourneyGameStatus());
        outStream.putInteger(obj.getWaitingCount());
        outStream.putInteger(obj.getChallengeType());
    }
    readLSDeletedTableDetails(inStream: TSDataInputStream, obj: LSDeletedTableDetails): void {
        obj.setTableId(inStream.getInteger());
    }

    writeLSDeletedTableDetails(outStream: TSDataOutputStream, obj: LSDeletedTableDetails): void {
        outStream.putInteger(obj.getTableId());
    }
    readLSTabIdTableDetails(inStream: TSDataInputStream, obj: LSTabIdTableDetails): void {
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
        obj.setTables(inStream.getObjectArray());
        obj.setDeletedTables(inStream.getIntArray());
    }

    writeLSTabIdTableDetails(outStream: TSDataOutputStream, obj: LSTabIdTableDetails): void {
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
        outStream.putObjectArray(obj.getTables());
        outStream.putIntArray(obj.getDeletedTables());
    }
    readResponseTableDetailsList(inStream: TSDataInputStream, obj: ResponseTableDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabIdTableDetailsMap(inStream.getInt2ObjectMap());
        obj.setClearAllTabIdSnapShots(inStream.getBoolean());
    }

    writeResponseTableDetailsList(outStream: TSDataOutputStream, obj: ResponseTableDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2ObjectMap(obj.getTabIdTableDetailsMap());
        outStream.putBoolean(obj.getClearAllTabIdSnapShots());
    }
    readLSTableGameStats(inStream: TSDataInputStream, obj: LSTableGameStats): void {
        obj.setTableId(inStream.getInteger());
        obj.setAvgPot(inStream.getLong());
        obj.setHandsPerHour(inStream.getShort());
        obj.setAvgFlopSeen(inStream.getShort());
    }

    writeLSTableGameStats(outStream: TSDataOutputStream, obj: LSTableGameStats): void {
        outStream.putInteger(obj.getTableId());
        outStream.putLong(obj.getAvgPot());
        outStream.putShort(obj.getHandsPerHour());
        outStream.putShort(obj.getAvgFlopSeen());
    }
    readLSTableOccupancyChange(inStream: TSDataInputStream, obj: LSTableOccupancyChange): void {
        obj.setTableId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setReservedCount(inStream.getByte());
        obj.setWaitingCount(inStream.getInteger());
    }

    writeLSTableOccupancyChange(outStream: TSDataOutputStream, obj: LSTableOccupancyChange): void {
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putByte(obj.getReservedCount());
        outStream.putInteger(obj.getWaitingCount());
    }
    readLSTableDetailsDeltaUpdate(inStream: TSDataInputStream, obj: LSTableDetailsDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabId(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSTableDetailsDeltaUpdate(outStream: TSDataOutputStream, obj: LSTableDetailsDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTabId());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readRequestPlayerList(inStream: TSDataInputStream, obj: RequestPlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
    }

    writeRequestPlayerList(outStream: TSDataOutputStream, obj: RequestPlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
    }
    readLSPlayerInfo(inStream: TSDataInputStream, obj: LSPlayerInfo): void {
        obj.setScreenName(inStream.getString());
        obj.setSeatingPosition(inStream.getInteger());
        obj.setBalance(inStream.getLong());
        obj.setSeatingStatus(inStream.getInteger());
    }

    writeLSPlayerInfo(outStream: TSDataOutputStream, obj: LSPlayerInfo): void {
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getSeatingPosition());
        outStream.putLong(obj.getBalance());
        outStream.putInteger(obj.getSeatingStatus());
    }
    readLSDealerBlindPositions(inStream: TSDataInputStream, obj: LSDealerBlindPositions): void {
        obj.setBigBlindPosition(inStream.getByte());
        obj.setDealerPosition(inStream.getByte());
        obj.setSmallBlindPosition(inStream.getByte());
    }

    writeLSDealerBlindPositions(outStream: TSDataOutputStream, obj: LSDealerBlindPositions): void {
        outStream.putByte(obj.getBigBlindPosition());
        outStream.putByte(obj.getDealerPosition());
        outStream.putByte(obj.getSmallBlindPosition());
    }
    readResponsePlayerList(inStream: TSDataInputStream, obj: ResponsePlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setPlayers(inStream.getObjectArray());
        obj.setDealerBlindPositions(inStream.getObject());
    }

    writeResponsePlayerList(outStream: TSDataOutputStream, obj: ResponsePlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putObjectArray(obj.getPlayers());
        outStream.putObject(obj.getDealerBlindPositions());
    }
    readLSPlayerBalances(inStream: TSDataInputStream, obj: LSPlayerBalances): void {
        obj.setPlayerBalances(inStream.getShort2LongMap());
    }

    writeLSPlayerBalances(outStream: TSDataOutputStream, obj: LSPlayerBalances): void {
        outStream.putShort2LongMap(obj.getPlayerBalances());
    }
    readLSRemovedPlayerInfo(inStream: TSDataInputStream, obj: LSRemovedPlayerInfo): void {
        obj.setScreenName(inStream.getString());
    }

    writeLSRemovedPlayerInfo(outStream: TSDataOutputStream, obj: LSRemovedPlayerInfo): void {
        outStream.putString(obj.getScreenName());
    }
    readLSPlayerListDeltaUpdate(inStream: TSDataInputStream, obj: LSPlayerListDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSPlayerListDeltaUpdate(outStream: TSDataOutputStream, obj: LSPlayerListDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readRequestPlayerSearch(inStream: TSDataInputStream, obj: RequestPlayerSearch): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerIdsToSearch(inStream.getStringArray());
    }

    writeRequestPlayerSearch(outStream: TSDataOutputStream, obj: RequestPlayerSearch): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putStringArray(obj.getPlayerIdsToSearch());
    }
    readSearchResultForPlayer(inStream: TSDataInputStream, obj: SearchResultForPlayer): void {
        obj.setScreenName(inStream.getString());
        obj.setFoundTables(inStream.getObjectArray());
    }

    writeSearchResultForPlayer(outStream: TSDataOutputStream, obj: SearchResultForPlayer): void {
        outStream.putString(obj.getScreenName());
        outStream.putObjectArray(obj.getFoundTables());
    }
    readResponsePlayerSearch(inStream: TSDataInputStream, obj: ResponsePlayerSearch): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResult(inStream.getObjectArray());
    }

    writeResponsePlayerSearch(outStream: TSDataOutputStream, obj: ResponsePlayerSearch): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getResult());
    }
    readLSPoolInfoDetails(inStream: TSDataInputStream, obj: LSPoolInfoDetails): void {
        obj.setPoolId(inStream.getInteger());
        obj.setPoolName(inStream.getStringEx());
        obj.setNoOfSeats(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setSmallBlindAmount(inStream.getInteger());
        obj.setBigBlindAmount(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setPoolTypeId(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setCategory(inStream.getByte());
        obj.setSubLiquidityType(inStream.getInteger());
        obj.setColorCode(inStream.getByte());
        obj.setAnteCashAmount(inStream.getInteger());
        obj.setMaxBuyInAmount(inStream.getInteger());
        obj.setMinBuyInAmount(inStream.getInteger());
    }

    writeLSPoolInfoDetails(outStream: TSDataOutputStream, obj: LSPoolInfoDetails): void {
        outStream.putInteger(obj.getPoolId());
        outStream.putStringEx(obj.getPoolName());
        outStream.putInteger(obj.getNoOfSeats());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putInteger(obj.getSmallBlindAmount());
        outStream.putInteger(obj.getBigBlindAmount());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getPoolTypeId());
        outStream.putInteger(obj.getLimitType());
        outStream.putByte(obj.getCategory());
        outStream.putInteger(obj.getSubLiquidityType());
        outStream.putByte(obj.getColorCode());
        outStream.putInteger(obj.getAnteCashAmount());
        outStream.putInteger(obj.getMaxBuyInAmount());
        outStream.putInteger(obj.getMinBuyInAmount());
    }
    readLSDeletedPoolInfoDetails(inStream: TSDataInputStream, obj: LSDeletedPoolInfoDetails): void {
        obj.setPoolId(inStream.getInteger());
    }

    writeLSDeletedPoolInfoDetails(outStream: TSDataOutputStream, obj: LSDeletedPoolInfoDetails): void {
        outStream.putInteger(obj.getPoolId());
    }
    readResponsePoolInfoDetailsList(inStream: TSDataInputStream, obj: ResponsePoolInfoDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
        obj.setPoolInfos(inStream.getObjectArray());
        obj.setDeletedPoolInfos(inStream.getIntArray());
        obj.setClearAllTabIdSnapShots(inStream.getBoolean());
    }

    writeResponsePoolInfoDetailsList(outStream: TSDataOutputStream, obj: ResponsePoolInfoDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
        outStream.putObjectArray(obj.getPoolInfos());
        outStream.putIntArray(obj.getDeletedPoolInfos());
        outStream.putBoolean(obj.getClearAllTabIdSnapShots());
    }
    readLSPoolInfoDetailsDeltaUpdate(inStream: TSDataInputStream, obj: LSPoolInfoDetailsDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setPoolInfos(inStream.getObjectArray());
        obj.setDeletedPoolInfos(inStream.getObjectArray());
    }

    writeLSPoolInfoDetailsDeltaUpdate(outStream: TSDataOutputStream, obj: LSPoolInfoDetailsDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putObjectArray(obj.getPoolInfos());
        outStream.putObjectArray(obj.getDeletedPoolInfos());
    }
    readLSPoolDetails(inStream: TSDataInputStream, obj: LSPoolDetails): void {
        obj.setTableInfoId(inStream.getInteger());
        obj.setPoolId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setHandsPerHour(inStream.getShort());
        obj.setAvgStack(inStream.getLong());
        obj.setAvgFlopsPerSeen(inStream.getShort());
        obj.setAvgPot(inStream.getLong());
        obj.setServerPeerId(inStream.getInteger());
    }

    writeLSPoolDetails(outStream: TSDataOutputStream, obj: LSPoolDetails): void {
        outStream.putInteger(obj.getTableInfoId());
        outStream.putInteger(obj.getPoolId());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putShort(obj.getHandsPerHour());
        outStream.putLong(obj.getAvgStack());
        outStream.putShort(obj.getAvgFlopsPerSeen());
        outStream.putLong(obj.getAvgPot());
        outStream.putInteger(obj.getServerPeerId());
    }
    readLSDeletedPoolDetails(inStream: TSDataInputStream, obj: LSDeletedPoolDetails): void {
        obj.setPoolId(inStream.getInteger());
    }

    writeLSDeletedPoolDetails(outStream: TSDataOutputStream, obj: LSDeletedPoolDetails): void {
        outStream.putInteger(obj.getPoolId());
    }
    readLSTabIdPoolDetails(inStream: TSDataInputStream, obj: LSTabIdPoolDetails): void {
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
        obj.setPools(inStream.getObjectArray());
        obj.setDeletedPools(inStream.getIntArray());
    }

    writeLSTabIdPoolDetails(outStream: TSDataOutputStream, obj: LSTabIdPoolDetails): void {
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
        outStream.putObjectArray(obj.getPools());
        outStream.putIntArray(obj.getDeletedPools());
    }
    readResponsePoolDetailsList(inStream: TSDataInputStream, obj: ResponsePoolDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClearAllTabIdSnapShots(inStream.getBoolean());
        obj.setTabIdPoolDetailsMap(inStream.getInt2ObjectMap());
    }

    writeResponsePoolDetailsList(outStream: TSDataOutputStream, obj: ResponsePoolDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getClearAllTabIdSnapShots());
        outStream.putInt2ObjectMap(obj.getTabIdPoolDetailsMap());
    }
    readLSPoolPlayerCount(inStream: TSDataInputStream, obj: LSPoolPlayerCount): void {
        obj.setPoolId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
    }

    writeLSPoolPlayerCount(outStream: TSDataOutputStream, obj: LSPoolPlayerCount): void {
        outStream.putInteger(obj.getPoolId());
        outStream.putInteger(obj.getPlayerCount());
    }
    readLSPoolGameStats(inStream: TSDataInputStream, obj: LSPoolGameStats): void {
        obj.setPoolId(inStream.getInteger());
        obj.setHandsPerHour(inStream.getShort());
        obj.setAvgStack(inStream.getLong());
        obj.setAvgFlopsPerSeen(inStream.getShort());
        obj.setAvgPot(inStream.getLong());
    }

    writeLSPoolGameStats(outStream: TSDataOutputStream, obj: LSPoolGameStats): void {
        outStream.putInteger(obj.getPoolId());
        outStream.putShort(obj.getHandsPerHour());
        outStream.putLong(obj.getAvgStack());
        outStream.putShort(obj.getAvgFlopsPerSeen());
        outStream.putLong(obj.getAvgPot());
    }
    readLSPoolDetailsDeltaUpdate(inStream: TSDataInputStream, obj: LSPoolDetailsDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabId(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSPoolDetailsDeltaUpdate(outStream: TSDataOutputStream, obj: LSPoolDetailsDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTabId());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readRequestPoolPlayerList(inStream: TSDataInputStream, obj: RequestPoolPlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPoolId(inStream.getInteger());
    }

    writeRequestPoolPlayerList(outStream: TSDataOutputStream, obj: RequestPoolPlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPoolId());
    }
    readLSPoolPlayerInfo(inStream: TSDataInputStream, obj: LSPoolPlayerInfo): void {
        obj.setScreenName(inStream.getString());
        obj.setNoOfEntreis(inStream.getShort());
    }

    writeLSPoolPlayerInfo(outStream: TSDataOutputStream, obj: LSPoolPlayerInfo): void {
        outStream.putString(obj.getScreenName());
        outStream.putShort(obj.getNoOfEntreis());
    }
    readResponsePoolPlayerList(inStream: TSDataInputStream, obj: ResponsePoolPlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPoolId(inStream.getInteger());
        obj.setPlayers(inStream.getObjectArray());
    }

    writeResponsePoolPlayerList(outStream: TSDataOutputStream, obj: ResponsePoolPlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPoolId());
        outStream.putObjectArray(obj.getPlayers());
    }
    readLSPoolRemovePlayerInfo(inStream: TSDataInputStream, obj: LSPoolRemovePlayerInfo): void {
        obj.setScreenName(inStream.getString());
    }

    writeLSPoolRemovePlayerInfo(outStream: TSDataOutputStream, obj: LSPoolRemovePlayerInfo): void {
        outStream.putString(obj.getScreenName());
    }
    readLSPoolPlayerListDeltaUpdate(inStream: TSDataInputStream, obj: LSPoolPlayerListDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPoolId(inStream.getInteger());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSPoolPlayerListDeltaUpdate(outStream: TSDataOutputStream, obj: LSPoolPlayerListDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPoolId());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readResponseWaitList(inStream: TSDataInputStream, obj: ResponseWaitList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setPosition(inStream.getInteger());
        obj.setSize(inStream.getInteger());
    }

    writeResponseWaitList(outStream: TSDataOutputStream, obj: ResponseWaitList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getPosition());
        outStream.putInteger(obj.getSize());
    }
    readLSMTCTLobbyData(inStream: TSDataInputStream, obj: LSMTCTLobbyData): void {
        obj.setAddonSupported(inStream.getBoolean());
        obj.setAllowLateRegistration(inStream.getBoolean());
        obj.setAllowLateRegistrationTillLevel(inStream.getInteger());
        obj.setAllowedCountryCodes(inStream.getString());
        obj.setAnnounceTime(inStream.getTimestamp());
        obj.setBountyAmount(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setColorCode(inStream.getByte());
        obj.setEntryFee(inStream.getInteger());
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setGameCurrency(inStream.getString());
        obj.setGamePlayType(inStream.getByte());
        obj.setGameType(inStream.getInteger());
        obj.setIsQuinteTourney(inStream.getBoolean());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxParticipants(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setMtctEvents(inStream.getIntArray());
        obj.setMtctId(inStream.getInteger());
        obj.setMtsgId(inStream.getInteger());
        obj.setMttType(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setNoOfParticipants(inStream.getInteger());
        obj.setNoOfPlayers(inStream.getInteger());
        obj.setPartyPoints(inStream.getInteger());
        obj.setQuintepokChampFee(inStream.getInteger());
        obj.setRebuyAddonStatus(inStream.getByte());
        obj.setRegistrationEndTime(inStream.getTimestamp());
        obj.setRegistrationStartTime(inStream.getTimestamp());
        obj.setStatus(inStream.getInteger());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setTotalRoundCount(inStream.getInteger());
        obj.setTournamentDescription(inStream.getStringEx());
        obj.setTourneyCurrentLevel(inStream.getInteger());
        obj.setTourneyEndTime(inStream.getTimestamp());
        obj.setTourneyStartTime(inStream.getTimestamp());
        obj.setTrnyMinPlrs(inStream.getInteger());
        obj.setTrnyTypeId(inStream.getInteger());
        obj.setUnRegOffset(inStream.getLong());
        obj.setUnregClosed(inStream.getBoolean());
        obj.setQualifiers(inStream.getIntArray());
        obj.setTabIds(inStream.getIntArray());
        obj.setImage(inStream.getString());
        obj.setIsLink(inStream.getBoolean());
        obj.setFeaturedPosition(inStream.getInteger());
        obj.setIsFeatured(inStream.getBoolean());
        obj.setOverLayText(inStream.getString());
        obj.setShortName(inStream.getStringEx());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setTournamentType(inStream.getInteger());
        obj.setTrnyFeePercent(inStream.getShort());
        obj.setIsTrnyFeeTypePercent(inStream.getBoolean());
        obj.setTemplateId(inStream.getInteger());
        obj.setPasswordProtected(inStream.getBoolean());
        obj.setParticipantEntryType(inStream.getInteger());
        obj.setPlayerEntriesAllowed(inStream.getInteger());
        obj.setEventSeriesType(inStream.getInteger());
        obj.setFlightedMTTType(inStream.getByte());
        obj.setEventSeriesTypes(inStream.getIntArray());
        obj.setBountyType(inStream.getByte());
        obj.setBountyTopUpPercentage(inStream.getInteger());
        obj.setMultiDayData(inStream.getObject());
        obj.setFlightedData(inStream.getObject());
        obj.setGuaranteedPrizePoolRanges(inStream.getIntArray());
        obj.setBlindLevelType(inStream.getByte());
        obj.setSubLiquidityType(inStream.getInteger());
        obj.setGenderAllowed(inStream.getString());
        obj.setMixmaxData(inStream.getObject());
        obj.setGuaranteedAmount(inStream.getLong());
        obj.setFontDetails(inStream.getObject());
        obj.setLrCloseTime(inStream.getLong());
        obj.setRemainingLRTime(inStream.getLong());
        obj.setRealNameGame(inStream.getBoolean());
        obj.setAgeRestrictionValue(inStream.getInteger());
        obj.setReentryInfo(inStream.getObject());
        obj.setDefaultChips(inStream.getLong());
        obj.setBigBlindAmount(inStream.getLong());
        obj.setSpeedFilterType(inStream.getInteger());
        obj.setOnDemandMTCT(inStream.getBoolean());
    }

    writeLSMTCTLobbyData(outStream: TSDataOutputStream, obj: LSMTCTLobbyData): void {
        outStream.putBoolean(obj.getAddonSupported());
        outStream.putBoolean(obj.getAllowLateRegistration());
        outStream.putInteger(obj.getAllowLateRegistrationTillLevel());
        outStream.putString(obj.getAllowedCountryCodes());
        outStream.putTimestamp(obj.getAnnounceTime());
        outStream.putInteger(obj.getBountyAmount());
        outStream.putInteger(obj.getBuyIn());
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putByte(obj.getColorCode());
        outStream.putInteger(obj.getEntryFee());
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putString(obj.getGameCurrency());
        outStream.putByte(obj.getGamePlayType());
        outStream.putInteger(obj.getGameType());
        outStream.putBoolean(obj.getIsQuinteTourney());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxParticipants());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putIntArray(obj.getMtctEvents());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getMtsgId());
        outStream.putInteger(obj.getMttType());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getNoOfParticipants());
        outStream.putInteger(obj.getNoOfPlayers());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putInteger(obj.getQuintepokChampFee());
        outStream.putByte(obj.getRebuyAddonStatus());
        outStream.putTimestamp(obj.getRegistrationEndTime());
        outStream.putTimestamp(obj.getRegistrationStartTime());
        outStream.putInteger(obj.getStatus());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putInteger(obj.getTotalRoundCount());
        outStream.putStringEx(obj.getTournamentDescription());
        outStream.putInteger(obj.getTourneyCurrentLevel());
        outStream.putTimestamp(obj.getTourneyEndTime());
        outStream.putTimestamp(obj.getTourneyStartTime());
        outStream.putInteger(obj.getTrnyMinPlrs());
        outStream.putInteger(obj.getTrnyTypeId());
        outStream.putLong(obj.getUnRegOffset());
        outStream.putBoolean(obj.getUnregClosed());
        outStream.putIntArray(obj.getQualifiers());
        outStream.putIntArray(obj.getTabIds());
        outStream.putString(obj.getImage());
        outStream.putBoolean(obj.getIsLink());
        outStream.putInteger(obj.getFeaturedPosition());
        outStream.putBoolean(obj.getIsFeatured());
        outStream.putString(obj.getOverLayText());
        outStream.putStringEx(obj.getShortName());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putInteger(obj.getTournamentType());
        outStream.putShort(obj.getTrnyFeePercent());
        outStream.putBoolean(obj.getIsTrnyFeeTypePercent());
        outStream.putInteger(obj.getTemplateId());
        outStream.putBoolean(obj.getPasswordProtected());
        outStream.putInteger(obj.getParticipantEntryType());
        outStream.putInteger(obj.getPlayerEntriesAllowed());
        outStream.putInteger(obj.getEventSeriesType());
        outStream.putByte(obj.getFlightedMTTType());
        outStream.putIntArray(obj.getEventSeriesTypes());
        outStream.putByte(obj.getBountyType());
        outStream.putInteger(obj.getBountyTopUpPercentage());
        outStream.putObject(obj.getMultiDayData());
        outStream.putObject(obj.getFlightedData());
        outStream.putIntArray(obj.getGuaranteedPrizePoolRanges());
        outStream.putByte(obj.getBlindLevelType());
        outStream.putInteger(obj.getSubLiquidityType());
        outStream.putString(obj.getGenderAllowed());
        outStream.putObject(obj.getMixmaxData());
        outStream.putLong(obj.getGuaranteedAmount());
        outStream.putObject(obj.getFontDetails());
        outStream.putLong(obj.getLrCloseTime());
        outStream.putLong(obj.getRemainingLRTime());
        outStream.putBoolean(obj.getRealNameGame());
        outStream.putInteger(obj.getAgeRestrictionValue());
        outStream.putObject(obj.getReentryInfo());
        outStream.putLong(obj.getDefaultChips());
        outStream.putLong(obj.getBigBlindAmount());
        outStream.putInteger(obj.getSpeedFilterType());
        outStream.putBoolean(obj.getOnDemandMTCT());
    }
    readLSTabIdMTCTDetails(inStream: TSDataInputStream, obj: LSTabIdMTCTDetails): void {
        obj.setMtcts(inStream.getObjectArray());
        obj.setDeletedMtcts(inStream.getIntArray());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
        obj.setUpdatemtcts(inStream.getObjectArray());
    }

    writeLSTabIdMTCTDetails(outStream: TSDataOutputStream, obj: LSTabIdMTCTDetails): void {
        outStream.putObjectArray(obj.getMtcts());
        outStream.putIntArray(obj.getDeletedMtcts());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
        outStream.putObjectArray(obj.getUpdatemtcts());
    }
    readResponseMTCTDetailsList(inStream: TSDataInputStream, obj: ResponseMTCTDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setClearAllTabIdSnapShots(inStream.getBoolean());
        obj.setTabIdMtctDetailsMap(inStream.getInt2ObjectMap());
    }

    writeResponseMTCTDetailsList(outStream: TSDataOutputStream, obj: ResponseMTCTDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getClearAllTabIdSnapShots());
        outStream.putInt2ObjectMap(obj.getTabIdMtctDetailsMap());
    }
    readLSMTCTDeleteEvent(inStream: TSDataInputStream, obj: LSMTCTDeleteEvent): void {
        obj.setMtctId(inStream.getInteger());
    }

    writeLSMTCTDeleteEvent(outStream: TSDataOutputStream, obj: LSMTCTDeleteEvent): void {
        outStream.putInteger(obj.getMtctId());
    }
    readLSMTCTStatusChangeEvent(inStream: TSDataInputStream, obj: LSMTCTStatusChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
    }

    writeLSMTCTStatusChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTStatusChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getStatus());
    }
    readLSMTCTCustomPropertyChangeEvent(inStream: TSDataInputStream, obj: LSMTCTCustomPropertyChangeEvent): void {
        obj.setAllowedCountryCodes(inStream.getString());
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setMtctId(inStream.getInteger());
        obj.setPartyPoints(inStream.getInteger());
    }

    writeLSMTCTCustomPropertyChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTCustomPropertyChangeEvent): void {
        outStream.putString(obj.getAllowedCountryCodes());
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPartyPoints());
    }
    readLSMTCTLevelChangeEvent(inStream: TSDataInputStream, obj: LSMTCTLevelChangeEvent): void {
        obj.setCurrentLevel(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setCurrSeatSize(inStream.getInteger());
        obj.setBigBlindAmount(inStream.getLong());
    }

    writeLSMTCTLevelChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTLevelChangeEvent): void {
        outStream.putInteger(obj.getCurrentLevel());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getCurrSeatSize());
        outStream.putLong(obj.getBigBlindAmount());
    }
    readLSMTCTPrizePoolChangeEvent(inStream: TSDataInputStream, obj: LSMTCTPrizePoolChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setPlacesPaid(inStream.getInteger());
    }

    writeLSMTCTPrizePoolChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTPrizePoolChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putInteger(obj.getPlacesPaid());
    }
    readLSMTCTFirstRankChangeEvent(inStream: TSDataInputStream, obj: LSMTCTFirstRankChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setTourneyEndTime(inStream.getTimestamp());
    }

    writeLSMTCTFirstRankChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTFirstRankChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putTimestamp(obj.getTourneyEndTime());
    }
    readLSMTCTPlayerCountChangeEvent(inStream: TSDataInputStream, obj: LSMTCTPlayerCountChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
    }

    writeLSMTCTPlayerCountChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTPlayerCountChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPlayerCount());
    }
    readLSMTCTRegistrationChangeEvent(inStream: TSDataInputStream, obj: LSMTCTRegistrationChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setNoOfParticipants(inStream.getInteger());
    }

    writeLSMTCTRegistrationChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTRegistrationChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getNoOfParticipants());
    }
    readLSMTCTLateRegistrationChangeEvent(inStream: TSDataInputStream, obj: LSMTCTLateRegistrationChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setLrCloseTime(inStream.getLong());
        obj.setRemainingLRTime(inStream.getLong());
    }

    writeLSMTCTLateRegistrationChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTLateRegistrationChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putLong(obj.getLrCloseTime());
        outStream.putLong(obj.getRemainingLRTime());
    }
    readLSMTCTUnRegistrationCloseChangeEvent(inStream: TSDataInputStream, obj: LSMTCTUnRegistrationCloseChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setUnregClosed(inStream.getBoolean());
    }

    writeLSMTCTUnRegistrationCloseChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTUnRegistrationCloseChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getUnregClosed());
    }
    readLSMTCTDetailsDeltaUpdate(inStream: TSDataInputStream, obj: LSMTCTDetailsDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabId(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSMTCTDetailsDeltaUpdate(outStream: TSDataOutputStream, obj: LSMTCTDetailsDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTabId());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readResponseMTCTDynaDetailsList(inStream: TSDataInputStream, obj: ResponseMTCTDynaDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDynamicLanguagePackUpdate(inStream.getObject());
    }

    writeResponseMTCTDynaDetailsList(outStream: TSDataOutputStream, obj: ResponseMTCTDynaDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getDynamicLanguagePackUpdate());
    }
    readLSTabIdDynamicLanguagePackDetails(inStream: TSDataInputStream, obj: LSTabIdDynamicLanguagePackDetails): void {
        obj.setDynamicLanguagePackUpdate(inStream.getObject());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
    }

    writeLSTabIdDynamicLanguagePackDetails(outStream: TSDataOutputStream, obj: LSTabIdDynamicLanguagePackDetails): void {
        outStream.putObject(obj.getDynamicLanguagePackUpdate());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
    }
    readLSDynamicLanguagePackUpdate(inStream: TSDataInputStream, obj: LSDynamicLanguagePackUpdate): void {
        obj.setLiteralsMap(inStream.getInt2StringMap());
    }

    writeLSDynamicLanguagePackUpdate(outStream: TSDataOutputStream, obj: LSDynamicLanguagePackUpdate): void {
        outStream.putInt2StringMap(obj.getLiteralsMap());
    }
    readRequestMTCTLobbyData(inStream: TSDataInputStream, obj: RequestMTCTLobbyData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctIds(inStream.getIntArray());
        obj.setTourneyRequestType(inStream.getByte());
        obj.setTournyType(inStream.getInteger());
    }

    writeRequestMTCTLobbyData(outStream: TSDataOutputStream, obj: RequestMTCTLobbyData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getMtctIds());
        outStream.putByte(obj.getTourneyRequestType());
        outStream.putInteger(obj.getTournyType());
    }
    readResponseMTCTLobbyData(inStream: TSDataInputStream, obj: ResponseMTCTLobbyData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctDetails(inStream.getObjectArray());
        obj.setDynaDetails(inStream.getObject());
        obj.setTourneyRequestType(inStream.getByte());
    }

    writeResponseMTCTLobbyData(outStream: TSDataOutputStream, obj: ResponseMTCTLobbyData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getMtctDetails());
        outStream.putObject(obj.getDynaDetails());
        outStream.putByte(obj.getTourneyRequestType());
    }
    readLSTourneyRoundInfo(inStream: TSDataInputStream, obj: LSTourneyRoundInfo): void {
        obj.setNextRoundBeginsAt(inStream.getLong());
        obj.setCurrentRound(inStream.getByte());
    }

    writeLSTourneyRoundInfo(outStream: TSDataOutputStream, obj: LSTourneyRoundInfo): void {
        outStream.putLong(obj.getNextRoundBeginsAt());
        outStream.putByte(obj.getCurrentRound());
    }
    readLSTourneyPrizeInfo(inStream: TSDataInputStream, obj: LSTourneyPrizeInfo): void {
        obj.setDealDistributed(inStream.getLong());
        obj.setIgnorePrizesUpdate(inStream.getBoolean());
        obj.setMessage(inStream.getStringEx());
        obj.setPrizeMessages(inStream.getObjectArray());
        obj.setRemainingPrizePool(inStream.getLong());
        obj.setTotalDistributed(inStream.getLong());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setMainPrizePool(inStream.getLong());
        obj.setBountyPrizePool(inStream.getLong());
        obj.setCollectedPrizePool(inStream.getLong());
        obj.setConsiderLRCloseConfig(inStream.getBoolean());
        obj.setPlacesPaid(inStream.getInteger());
    }

    writeLSTourneyPrizeInfo(outStream: TSDataOutputStream, obj: LSTourneyPrizeInfo): void {
        outStream.putLong(obj.getDealDistributed());
        outStream.putBoolean(obj.getIgnorePrizesUpdate());
        outStream.putStringEx(obj.getMessage());
        outStream.putObjectArray(obj.getPrizeMessages());
        outStream.putLong(obj.getRemainingPrizePool());
        outStream.putLong(obj.getTotalDistributed());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putLong(obj.getMainPrizePool());
        outStream.putLong(obj.getBountyPrizePool());
        outStream.putLong(obj.getCollectedPrizePool());
        outStream.putBoolean(obj.getConsiderLRCloseConfig());
        outStream.putInteger(obj.getPlacesPaid());
    }
    readLSTourneyRebuyAddonInfo(inStream: TSDataInputStream, obj: LSTourneyRebuyAddonInfo): void {
        obj.setAddonChips(inStream.getInteger());
        obj.setAddonBuyIn(inStream.getInteger());
        obj.setAddonCountTillNow(inStream.getInteger());
        obj.setAddonSupported(inStream.getBoolean());
        obj.setMaxAddonCount(inStream.getInteger());
        obj.setMaxRebuyCount(inStream.getInteger());
        obj.setRebuyAddonStatus(inStream.getByte());
        obj.setRebuyChips(inStream.getInteger());
        obj.setRebuyBuyIn(inStream.getInteger());
        obj.setRebuyCountTillNow(inStream.getInteger());
        obj.setRebuyEndBreakNumber(inStream.getInteger());
        obj.setRebuyFee(inStream.getInteger());
        obj.setAddonFee(inStream.getInteger());
        obj.setRebuyEndMode(inStream.getInteger());
        obj.setRebuyEndValue(inStream.getInteger());
    }

    writeLSTourneyRebuyAddonInfo(outStream: TSDataOutputStream, obj: LSTourneyRebuyAddonInfo): void {
        outStream.putInteger(obj.getAddonChips());
        outStream.putInteger(obj.getAddonBuyIn());
        outStream.putInteger(obj.getAddonCountTillNow());
        outStream.putBoolean(obj.getAddonSupported());
        outStream.putInteger(obj.getMaxAddonCount());
        outStream.putInteger(obj.getMaxRebuyCount());
        outStream.putByte(obj.getRebuyAddonStatus());
        outStream.putInteger(obj.getRebuyChips());
        outStream.putInteger(obj.getRebuyBuyIn());
        outStream.putInteger(obj.getRebuyCountTillNow());
        outStream.putInteger(obj.getRebuyEndBreakNumber());
        outStream.putInteger(obj.getRebuyFee());
        outStream.putInteger(obj.getAddonFee());
        outStream.putInteger(obj.getRebuyEndMode());
        outStream.putInteger(obj.getRebuyEndValue());
    }
    readLSTourneyShootoutInfo(inStream: TSDataInputStream, obj: LSTourneyShootoutInfo): void {
        obj.setPlayerCount1(inStream.getInteger());
        obj.setPlayerCount2(inStream.getInteger());
        obj.setPlayerCount3(inStream.getInteger());
        obj.setPlayerCount4(inStream.getInteger());
        obj.setPrize11(inStream.getInteger());
        obj.setPrize12(inStream.getInteger());
        obj.setPrize13(inStream.getInteger());
        obj.setPrize21(inStream.getInteger());
        obj.setPrize22(inStream.getInteger());
        obj.setPrize23(inStream.getInteger());
        obj.setPrize31(inStream.getInteger());
        obj.setPrize32(inStream.getInteger());
        obj.setPrize33(inStream.getInteger());
        obj.setPrize41(inStream.getInteger());
        obj.setPrize42(inStream.getInteger());
        obj.setPrize43(inStream.getInteger());
        obj.setTotalRoundCount(inStream.getInteger());
        obj.setNextShootoutBreakDuration(inStream.getInteger());
    }

    writeLSTourneyShootoutInfo(outStream: TSDataOutputStream, obj: LSTourneyShootoutInfo): void {
        outStream.putInteger(obj.getPlayerCount1());
        outStream.putInteger(obj.getPlayerCount2());
        outStream.putInteger(obj.getPlayerCount3());
        outStream.putInteger(obj.getPlayerCount4());
        outStream.putInteger(obj.getPrize11());
        outStream.putInteger(obj.getPrize12());
        outStream.putInteger(obj.getPrize13());
        outStream.putInteger(obj.getPrize21());
        outStream.putInteger(obj.getPrize22());
        outStream.putInteger(obj.getPrize23());
        outStream.putInteger(obj.getPrize31());
        outStream.putInteger(obj.getPrize32());
        outStream.putInteger(obj.getPrize33());
        outStream.putInteger(obj.getPrize41());
        outStream.putInteger(obj.getPrize42());
        outStream.putInteger(obj.getPrize43());
        outStream.putInteger(obj.getTotalRoundCount());
        outStream.putInteger(obj.getNextShootoutBreakDuration());
    }
    readLSTourneyLobbyStackInfo(inStream: TSDataInputStream, obj: LSTourneyLobbyStackInfo): void {
        obj.setAverageStack(inStream.getLong());
        obj.setChipsInPlay(inStream.getLong());
        obj.setLargestStack(inStream.getLong());
        obj.setMtctId(inStream.getInteger());
        obj.setSmallestStack(inStream.getLong());
        obj.setTotalTablesCount(inStream.getInteger());
        obj.setWatchersCount(inStream.getInteger());
    }

    writeLSTourneyLobbyStackInfo(outStream: TSDataOutputStream, obj: LSTourneyLobbyStackInfo): void {
        outStream.putLong(obj.getAverageStack());
        outStream.putLong(obj.getChipsInPlay());
        outStream.putLong(obj.getLargestStack());
        outStream.putInteger(obj.getMtctId());
        outStream.putLong(obj.getSmallestStack());
        outStream.putInteger(obj.getTotalTablesCount());
        outStream.putInteger(obj.getWatchersCount());
    }
    readLSHeadsUpTourenyPlayerSequence(inStream: TSDataInputStream, obj: LSHeadsUpTourenyPlayerSequence): void {
        obj.setMtctId(inStream.getInteger());
        obj.setIsByeRound(inStream.getBoolean());
        obj.setOrderList(inStream.getIntArray());
    }

    writeLSHeadsUpTourenyPlayerSequence(outStream: TSDataOutputStream, obj: LSHeadsUpTourenyPlayerSequence): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getIsByeRound());
        outStream.putIntArray(obj.getOrderList());
    }
    readLSTourneyBlindLevel(inStream: TSDataInputStream, obj: LSTourneyBlindLevel): void {
        obj.setAnte(inStream.getLong());
        obj.setBigBlind(inStream.getLong());
        obj.setBlindLevelValue(inStream.getInteger());
        obj.setLevel(inStream.getInteger());
        obj.setSmallBlind(inStream.getLong());
    }

    writeLSTourneyBlindLevel(outStream: TSDataOutputStream, obj: LSTourneyBlindLevel): void {
        outStream.putLong(obj.getAnte());
        outStream.putLong(obj.getBigBlind());
        outStream.putInteger(obj.getBlindLevelValue());
        outStream.putInteger(obj.getLevel());
        outStream.putLong(obj.getSmallBlind());
    }
    readLSTourneyBlindStructureDetails(inStream: TSDataInputStream, obj: LSTourneyBlindStructureDetails): void {
        obj.setBlindStructureDetails(inStream.getObjectArray());
    }

    writeLSTourneyBlindStructureDetails(outStream: TSDataOutputStream, obj: LSTourneyBlindStructureDetails): void {
        outStream.putObjectArray(obj.getBlindStructureDetails());
    }
    readLSTourneyLobbyData(inStream: TSDataInputStream, obj: LSTourneyLobbyData): void {
        obj.setMtctId(inStream.getInteger());
        obj.setSynchronisedBreakTrny(inStream.getBoolean());
        obj.setDefaultChips(inStream.getLong());
        obj.setInfo(inStream.getStringEx());
        obj.setIsTrnyFeeTypePercent(inStream.getBoolean());
        obj.setBreakDuration(inStream.getInteger());
        obj.setBreakInterval(inStream.getInteger());
        obj.setDealMakingOn(inStream.getBoolean());
        obj.setNoOfDealMakings(inStream.getShort());
        obj.setNextBreakTime(inStream.getLong());
        obj.setNextLevelValue(inStream.getLong());
        obj.setMainLobbyData(inStream.getObject());
        obj.setTourneyRoundInfo(inStream.getObject());
        obj.setPrizeInfo(inStream.getObject());
        obj.setRebuyAddonInfo(inStream.getObject());
        obj.setShootoutInfo(inStream.getObject());
        obj.setStackInfo(inStream.getObject());
        obj.setBlindStructureDetails(inStream.getObject());
        obj.setHeadsUpTourenyPlayerSequence(inStream.getObject());
        obj.setByeRoundOrderList(inStream.getIntArray());
        obj.setAddedPrizePool(inStream.getLong());
        obj.setRingFencedAmount(inStream.getLong());
        obj.setFinalTableHandsPerLevel(inStream.getInteger());
        obj.setNextLevelChangeType(inStream.getInteger());
        obj.setTimetoAct(inStream.getObject());
        obj.setPreFlopTimeBank(inStream.getObject());
        obj.setPostFlopTimeBank(inStream.getObject());
    }

    writeLSTourneyLobbyData(outStream: TSDataOutputStream, obj: LSTourneyLobbyData): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putBoolean(obj.getSynchronisedBreakTrny());
        outStream.putLong(obj.getDefaultChips());
        outStream.putStringEx(obj.getInfo());
        outStream.putBoolean(obj.getIsTrnyFeeTypePercent());
        outStream.putInteger(obj.getBreakDuration());
        outStream.putInteger(obj.getBreakInterval());
        outStream.putBoolean(obj.getDealMakingOn());
        outStream.putShort(obj.getNoOfDealMakings());
        outStream.putLong(obj.getNextBreakTime());
        outStream.putLong(obj.getNextLevelValue());
        outStream.putObject(obj.getMainLobbyData());
        outStream.putObject(obj.getTourneyRoundInfo());
        outStream.putObject(obj.getPrizeInfo());
        outStream.putObject(obj.getRebuyAddonInfo());
        outStream.putObject(obj.getShootoutInfo());
        outStream.putObject(obj.getStackInfo());
        outStream.putObject(obj.getBlindStructureDetails());
        outStream.putObject(obj.getHeadsUpTourenyPlayerSequence());
        outStream.putIntArray(obj.getByeRoundOrderList());
        outStream.putLong(obj.getAddedPrizePool());
        outStream.putLong(obj.getRingFencedAmount());
        outStream.putInteger(obj.getFinalTableHandsPerLevel());
        outStream.putInteger(obj.getNextLevelChangeType());
        outStream.putObject(obj.getTimetoAct());
        outStream.putObject(obj.getPreFlopTimeBank());
        outStream.putObject(obj.getPostFlopTimeBank());
    }
    readLSTourneyCustomPropertyChange(inStream: TSDataInputStream, obj: LSTourneyCustomPropertyChange): void {
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setPartyPoints(inStream.getInteger());
        obj.setAllowedCountryCodes(inStream.getString());
    }

    writeLSTourneyCustomPropertyChange(outStream: TSDataOutputStream, obj: LSTourneyCustomPropertyChange): void {
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putString(obj.getAllowedCountryCodes());
    }
    readLSTourneyDealMakingChange(inStream: TSDataInputStream, obj: LSTourneyDealMakingChange): void {
        obj.setNoOfDealMakings(inStream.getShort());
        obj.setDealMakingOn(inStream.getBoolean());
    }

    writeLSTourneyDealMakingChange(outStream: TSDataOutputStream, obj: LSTourneyDealMakingChange): void {
        outStream.putShort(obj.getNoOfDealMakings());
        outStream.putBoolean(obj.getDealMakingOn());
    }
    readLSTourneyLateRegistrationChange(inStream: TSDataInputStream, obj: LSTourneyLateRegistrationChange): void {
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setLrCloseTime(inStream.getLong());
        obj.setRemainingLRTime(inStream.getLong());
    }

    writeLSTourneyLateRegistrationChange(outStream: TSDataOutputStream, obj: LSTourneyLateRegistrationChange): void {
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putLong(obj.getLrCloseTime());
        outStream.putLong(obj.getRemainingLRTime());
    }
    readLSTourneyLevelChange(inStream: TSDataInputStream, obj: LSTourneyLevelChange): void {
        obj.setLevel(inStream.getInteger());
        obj.setCurrSeatSize(inStream.getInteger());
    }

    writeLSTourneyLevelChange(outStream: TSDataOutputStream, obj: LSTourneyLevelChange): void {
        outStream.putInteger(obj.getLevel());
        outStream.putInteger(obj.getCurrSeatSize());
    }
    readLSTourneyPlayerGridRow(inStream: TSDataInputStream, obj: LSTourneyPlayerGridRow): void {
        obj.setBountyOnPlayerHead(inStream.getInteger());
        obj.setChips(inStream.getLong());
        obj.setChipsRank(inStream.getInteger());
        obj.setNoOfBounties(inStream.getInteger());
        obj.setParticipantno(inStream.getInteger());
        obj.setPrizeAmt(inStream.getInteger());
        obj.setPrizeMsgTemplateId(inStream.getInteger());
        obj.setPrizeType(inStream.getByte());
        obj.setRank(inStream.getInteger());
        obj.setTotalBountyWon(inStream.getInteger());
        obj.setUserTTPAwardType(inStream.getByte());
        obj.setDisplayRank(inStream.getInteger());
        obj.setBioCountryCode(inStream.getString());
        obj.setBountyValue(inStream.getInteger());
        obj.setQualifiedChips(inStream.getLong());
    }

    writeLSTourneyPlayerGridRow(outStream: TSDataOutputStream, obj: LSTourneyPlayerGridRow): void {
        outStream.putInteger(obj.getBountyOnPlayerHead());
        outStream.putLong(obj.getChips());
        outStream.putInteger(obj.getChipsRank());
        outStream.putInteger(obj.getNoOfBounties());
        outStream.putInteger(obj.getParticipantno());
        outStream.putInteger(obj.getPrizeAmt());
        outStream.putInteger(obj.getPrizeMsgTemplateId());
        outStream.putByte(obj.getPrizeType());
        outStream.putInteger(obj.getRank());
        outStream.putInteger(obj.getTotalBountyWon());
        outStream.putByte(obj.getUserTTPAwardType());
        outStream.putInteger(obj.getDisplayRank());
        outStream.putString(obj.getBioCountryCode());
        outStream.putInteger(obj.getBountyValue());
        outStream.putLong(obj.getQualifiedChips());
    }
    readLSTourneyRebuyAddonChange(inStream: TSDataInputStream, obj: LSTourneyRebuyAddonChange): void {
        obj.setRebuyCountTillNow(inStream.getInteger());
        obj.setAddonCountTillNow(inStream.getInteger());
        obj.setRebuyAddonStatus(inStream.getByte());
    }

    writeLSTourneyRebuyAddonChange(outStream: TSDataOutputStream, obj: LSTourneyRebuyAddonChange): void {
        outStream.putInteger(obj.getRebuyCountTillNow());
        outStream.putInteger(obj.getAddonCountTillNow());
        outStream.putByte(obj.getRebuyAddonStatus());
    }
    readLSTourneyRegistrationChange(inStream: TSDataInputStream, obj: LSTourneyRegistrationChange): void {
        obj.setNoOfParticipants(inStream.getInteger());
    }

    writeLSTourneyRegistrationChange(outStream: TSDataOutputStream, obj: LSTourneyRegistrationChange): void {
        outStream.putInteger(obj.getNoOfParticipants());
    }
    readLSTourneyRoundChange(inStream: TSDataInputStream, obj: LSTourneyRoundChange): void {
        obj.setCurrentRound(inStream.getByte());
        obj.setNextRoundBeginsAt(inStream.getLong());
        obj.setNextShootoutBreakDuration(inStream.getInteger());
    }

    writeLSTourneyRoundChange(outStream: TSDataOutputStream, obj: LSTourneyRoundChange): void {
        outStream.putByte(obj.getCurrentRound());
        outStream.putLong(obj.getNextRoundBeginsAt());
        outStream.putInteger(obj.getNextShootoutBreakDuration());
    }
    readLSTourneyStatusChange(inStream: TSDataInputStream, obj: LSTourneyStatusChange): void {
        obj.setStatus(inStream.getInteger());
    }

    writeLSTourneyStatusChange(outStream: TSDataOutputStream, obj: LSTourneyStatusChange): void {
        outStream.putInteger(obj.getStatus());
    }
    readLSTourneySyncAddonBreakTimeChange(inStream: TSDataInputStream, obj: LSTourneySyncAddonBreakTimeChange): void {
        obj.setNextBreakTime(inStream.getLong());
        obj.setNextLevelValue(inStream.getLong());
        obj.setLevelChangeType(inStream.getInteger());
    }

    writeLSTourneySyncAddonBreakTimeChange(outStream: TSDataOutputStream, obj: LSTourneySyncAddonBreakTimeChange): void {
        outStream.putLong(obj.getNextBreakTime());
        outStream.putLong(obj.getNextLevelValue());
        outStream.putInteger(obj.getLevelChangeType());
    }
    readLSTourneyTablePlayer(inStream: TSDataInputStream, obj: LSTourneyTablePlayer): void {
        obj.setBalance(inStream.getLong());
        obj.setBountyOnPlayerHead(inStream.getInteger());
        obj.setNoOfBounties(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
        obj.setTotalBountyWon(inStream.getInteger());
    }

    writeLSTourneyTablePlayer(outStream: TSDataOutputStream, obj: LSTourneyTablePlayer): void {
        outStream.putLong(obj.getBalance());
        outStream.putInteger(obj.getBountyOnPlayerHead());
        outStream.putInteger(obj.getNoOfBounties());
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getTotalBountyWon());
    }
    readLSTourneyTablesGridRowDetails(inStream: TSDataInputStream, obj: LSTourneyTablesGridRowDetails): void {
        obj.setLargestStack(inStream.getLong());
        obj.setPlayerCount(inStream.getInteger());
        obj.setServerPeerId(inStream.getInteger());
        obj.setSmallestStack(inStream.getLong());
        obj.setTableId(inStream.getInteger());
        obj.setTableNumber(inStream.getInteger());
    }

    writeLSTourneyTablesGridRowDetails(outStream: TSDataOutputStream, obj: LSTourneyTablesGridRowDetails): void {
        outStream.putLong(obj.getLargestStack());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putLong(obj.getSmallestStack());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableNumber());
    }
    readLSTourneyUnRegistrationCloseChangeEvent(inStream: TSDataInputStream, obj: LSTourneyUnRegistrationCloseChangeEvent): void {
        obj.setUnregClosed(inStream.getBoolean());
    }

    writeLSTourneyUnRegistrationCloseChangeEvent(outStream: TSDataOutputStream, obj: LSTourneyUnRegistrationCloseChangeEvent): void {
        outStream.putBoolean(obj.getUnregClosed());
    }
    readLSTourneyWatcherCountChange(inStream: TSDataInputStream, obj: LSTourneyWatcherCountChange): void {
        obj.setWatchersCount(inStream.getInteger());
    }

    writeLSTourneyWatcherCountChange(outStream: TSDataOutputStream, obj: LSTourneyWatcherCountChange): void {
        outStream.putInteger(obj.getWatchersCount());
    }
    readLSTourneyPlayerCountChange(inStream: TSDataInputStream, obj: LSTourneyPlayerCountChange): void {
        obj.setPlayerCount(inStream.getInteger());
    }

    writeLSTourneyPlayerCountChange(outStream: TSDataOutputStream, obj: LSTourneyPlayerCountChange): void {
        outStream.putInteger(obj.getPlayerCount());
    }
    readLSTourneyParticipantName(inStream: TSDataInputStream, obj: LSTourneyParticipantName): void {
        obj.setParticipantNo(inStream.getInteger());
        obj.setScreenName(inStream.getString());
    }

    writeLSTourneyParticipantName(outStream: TSDataOutputStream, obj: LSTourneyParticipantName): void {
        outStream.putInteger(obj.getParticipantNo());
        outStream.putString(obj.getScreenName());
    }
    readRequestMonitorMTCTTable(inStream: TSDataInputStream, obj: RequestMonitorMTCTTable): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTableNo(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestMonitorMTCTTable(outStream: TSDataOutputStream, obj: RequestMonitorMTCTTable): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTableNo());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestLobbyDyna(inStream: TSDataInputStream, obj: RequestLobbyDyna): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTemplateIds(inStream.getIntArray());
        obj.setTournyType(inStream.getInteger());
    }

    writeRequestLobbyDyna(outStream: TSDataOutputStream, obj: RequestLobbyDyna): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getTemplateIds());
        outStream.putInteger(obj.getTournyType());
    }
    readRequestMTCTLobbyGridOnPageNumber(inStream: TSDataInputStream, obj: RequestMTCTLobbyGridOnPageNumber): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPageNumber(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setKeepTableSubscription(inStream.getBoolean());
    }

    writeRequestMTCTLobbyGridOnPageNumber(outStream: TSDataOutputStream, obj: RequestMTCTLobbyGridOnPageNumber): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPageNumber());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putBoolean(obj.getKeepTableSubscription());
    }
    readRequestMTCTLobbyGridOnScreenName(inStream: TSDataInputStream, obj: RequestMTCTLobbyGridOnScreenName): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setKeepTableSubscription(inStream.getBoolean());
    }

    writeRequestMTCTLobbyGridOnScreenName(outStream: TSDataOutputStream, obj: RequestMTCTLobbyGridOnScreenName): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putBoolean(obj.getKeepTableSubscription());
    }
    readRequestMTCTLobbyTablesOnPageNumber(inStream: TSDataInputStream, obj: RequestMTCTLobbyTablesOnPageNumber): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPageNumber(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setKeepPlayerSubscription(inStream.getBoolean());
    }

    writeRequestMTCTLobbyTablesOnPageNumber(outStream: TSDataOutputStream, obj: RequestMTCTLobbyTablesOnPageNumber): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPageNumber());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putBoolean(obj.getKeepPlayerSubscription());
    }
    readRequestMTCTLobbyTablesOnScreenName(inStream: TSDataInputStream, obj: RequestMTCTLobbyTablesOnScreenName): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setKeepPlayerSubscription(inStream.getBoolean());
    }

    writeRequestMTCTLobbyTablesOnScreenName(outStream: TSDataOutputStream, obj: RequestMTCTLobbyTablesOnScreenName): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putBoolean(obj.getKeepPlayerSubscription());
    }
    readRequestLSMTCTParticipantNames(inStream: TSDataInputStream, obj: RequestLSMTCTParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipantNos(inStream.getIntArray());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestLSMTCTParticipantNames(outStream: TSDataOutputStream, obj: RequestLSMTCTParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putIntArray(obj.getParticipantNos());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestOpenMTCTLobby(inStream: TSDataInputStream, obj: RequestOpenMTCTLobby): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLobbyStatus(inStream.getByte());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestOpenMTCTLobby(outStream: TSDataOutputStream, obj: RequestOpenMTCTLobby): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putByte(obj.getLobbyStatus());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readResponseLobbyDyna(inStream: TSDataInputStream, obj: ResponseLobbyDyna): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDynaDetails(inStream.getObject());
    }

    writeResponseLobbyDyna(outStream: TSDataOutputStream, obj: ResponseLobbyDyna): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getDynaDetails());
    }
    readResponseLSMTCTParticipantNames(inStream: TSDataInputStream, obj: ResponseLSMTCTParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipantNames(inStream.getObjectArray());
    }

    writeResponseLSMTCTParticipantNames(outStream: TSDataOutputStream, obj: ResponseLSMTCTParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putObjectArray(obj.getParticipantNames());
    }
    readResponseTourenyLobbyPlayersGrid(inStream: TSDataInputStream, obj: ResponseTourenyLobbyPlayersGrid): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPageNo(inStream.getInteger());
        obj.setTotalPages(inStream.getInteger());
        obj.setPage(inStream.getObjectArray());
    }

    writeResponseTourenyLobbyPlayersGrid(outStream: TSDataOutputStream, obj: ResponseTourenyLobbyPlayersGrid): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPageNo());
        outStream.putInteger(obj.getTotalPages());
        outStream.putObjectArray(obj.getPage());
    }
    readResponseTourenyLobbyTablesGrid(inStream: TSDataInputStream, obj: ResponseTourenyLobbyTablesGrid): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPageNo(inStream.getInteger());
        obj.setPageRows(inStream.getObjectArray());
        obj.setTotalPages(inStream.getInteger());
        obj.setIsDeltaUpdate(inStream.getBoolean());
    }

    writeResponseTourenyLobbyTablesGrid(outStream: TSDataOutputStream, obj: ResponseTourenyLobbyTablesGrid): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPageNo());
        outStream.putObjectArray(obj.getPageRows());
        outStream.putInteger(obj.getTotalPages());
        outStream.putBoolean(obj.getIsDeltaUpdate());
    }
    readResponseTourenyTablePlayerList(inStream: TSDataInputStream, obj: ResponseTourenyTablePlayerList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTableNo(inStream.getInteger());
        obj.setMtctTablePlayers(inStream.getObjectArray());
    }

    writeResponseTourenyTablePlayerList(outStream: TSDataOutputStream, obj: ResponseTourenyTablePlayerList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTableNo());
        outStream.putObjectArray(obj.getMtctTablePlayers());
    }
    readResponseTourneyLobbyDeltaChanges(inStream: TSDataInputStream, obj: ResponseTourneyLobbyDeltaChanges): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeResponseTourneyLobbyDeltaChanges(outStream: TSDataOutputStream, obj: ResponseTourneyLobbyDeltaChanges): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readResponseTourneyLobbyDetails(inStream: TSDataInputStream, obj: ResponseTourneyLobbyDetails): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLsTourneyLobbyData(inStream.getObject());
    }

    writeResponseTourneyLobbyDetails(outStream: TSDataOutputStream, obj: ResponseTourneyLobbyDetails): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getLsTourneyLobbyData());
    }
    readLSSTTTableGameStatus(inStream: TSDataInputStream, obj: LSSTTTableGameStatus): void {
        obj.setTableId(inStream.getInteger());
        obj.setTourneyGameStatus(inStream.getByte());
    }

    writeLSSTTTableGameStatus(outStream: TSDataOutputStream, obj: LSSTTTableGameStatus): void {
        outStream.putInteger(obj.getTableId());
        outStream.putByte(obj.getTourneyGameStatus());
    }
    readRingPlayerSearchResultTable(inStream: TSDataInputStream, obj: RingPlayerSearchResultTable): void {
        obj.setTableId(inStream.getInteger());
        obj.setTableTypeId(inStream.getByte());
        obj.setTableName(inStream.getStringEx());
        obj.setMaxNoOfSeats(inStream.getInteger());
        obj.setGameTypeId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setTableLimitType(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setGroupId(inStream.getInteger());
        obj.setTableCategory(inStream.getInteger());
        obj.setStakesLowerLimit(inStream.getInteger());
        obj.setStakesUpperLimit(inStream.getInteger());
        obj.setWaitListCount(inStream.getInteger());
    }

    writeRingPlayerSearchResultTable(outStream: TSDataOutputStream, obj: RingPlayerSearchResultTable): void {
        outStream.putInteger(obj.getTableId());
        outStream.putByte(obj.getTableTypeId());
        outStream.putStringEx(obj.getTableName());
        outStream.putInteger(obj.getMaxNoOfSeats());
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putInteger(obj.getTableLimitType());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getGroupId());
        outStream.putInteger(obj.getTableCategory());
        outStream.putInteger(obj.getStakesLowerLimit());
        outStream.putInteger(obj.getStakesUpperLimit());
        outStream.putInteger(obj.getWaitListCount());
    }
    readSTTPlayerSearchResultTable(inStream: TSDataInputStream, obj: STTPlayerSearchResultTable): void {
        obj.setTableId(inStream.getInteger());
        obj.setTableTypeId(inStream.getByte());
        obj.setTableName(inStream.getStringEx());
        obj.setMaxNoOfSeats(inStream.getInteger());
        obj.setGameTypeId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setTableLimitType(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setGroupId(inStream.getInteger());
        obj.setTableCategory(inStream.getInteger());
        obj.setTourneyBuyIn(inStream.getInteger());
        obj.setTourneyEntryFee(inStream.getInteger());
        obj.setFppBuyIn(inStream.getInteger());
        obj.setTourneyGameStatus(inStream.getInteger());
        obj.setMaxBuyInAmount(inStream.getInteger());
    }

    writeSTTPlayerSearchResultTable(outStream: TSDataOutputStream, obj: STTPlayerSearchResultTable): void {
        outStream.putInteger(obj.getTableId());
        outStream.putByte(obj.getTableTypeId());
        outStream.putStringEx(obj.getTableName());
        outStream.putInteger(obj.getMaxNoOfSeats());
        outStream.putInteger(obj.getGameTypeId());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putInteger(obj.getTableLimitType());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getGroupId());
        outStream.putInteger(obj.getTableCategory());
        outStream.putInteger(obj.getTourneyBuyIn());
        outStream.putInteger(obj.getTourneyEntryFee());
        outStream.putInteger(obj.getFppBuyIn());
        outStream.putInteger(obj.getTourneyGameStatus());
        outStream.putInteger(obj.getMaxBuyInAmount());
    }
    readResponseDynaDetailsDeltaChanges(inStream: TSDataInputStream, obj: ResponseDynaDetailsDeltaChanges): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTemplateId(inStream.getInteger());
        obj.setContent(inStream.getString());
        obj.setLiteralsMap(inStream.getInt2StringMap());
    }

    writeResponseDynaDetailsDeltaChanges(outStream: TSDataOutputStream, obj: ResponseDynaDetailsDeltaChanges): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTemplateId());
        outStream.putString(obj.getContent());
        outStream.putInt2StringMap(obj.getLiteralsMap());
    }
    readRequestLobbyUnSubscribe(inStream: TSDataInputStream, obj: RequestLobbyUnSubscribe): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestLobbyUnSubscribe(outStream: TSDataOutputStream, obj: RequestLobbyUnSubscribe): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseTourneyParticipantStatus(inStream: TSDataInputStream, obj: ResponseTourneyParticipantStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipantStatus(inStream.getInt2IntMap());
    }

    writeResponseTourneyParticipantStatus(outStream: TSDataOutputStream, obj: ResponseTourneyParticipantStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInt2IntMap(obj.getParticipantStatus());
    }
    readLSTourneyParticipantStatus(inStream: TSDataInputStream, obj: LSTourneyParticipantStatus): void {
        obj.setParticipantStatus(inStream.getInt2IntMap());
    }

    writeLSTourneyParticipantStatus(outStream: TSDataOutputStream, obj: LSTourneyParticipantStatus): void {
        outStream.putInt2IntMap(obj.getParticipantStatus());
    }
    readRequestTableForMTCTParticipant(inStream: TSDataInputStream, obj: RequestTableForMTCTParticipant): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setParticipantNo(inStream.getInteger());
    }

    writeRequestTableForMTCTParticipant(outStream: TSDataOutputStream, obj: RequestTableForMTCTParticipant): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putInteger(obj.getParticipantNo());
    }
    readResponseMTCTTableInfoForParticipant(inStream: TSDataInputStream, obj: ResponseMTCTTableInfoForParticipant): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setServerPeerId(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setTableSeqNumber(inStream.getInteger());
    }

    writeResponseMTCTTableInfoForParticipant(outStream: TSDataOutputStream, obj: ResponseMTCTTableInfoForParticipant): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableSeqNumber());
    }
    readLSTourneyCompletionChangeEvent(inStream: TSDataInputStream, obj: LSTourneyCompletionChangeEvent): void {
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setTourneyEndTime(inStream.getTimestamp());
    }

    writeLSTourneyCompletionChangeEvent(outStream: TSDataOutputStream, obj: LSTourneyCompletionChangeEvent): void {
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putTimestamp(obj.getTourneyEndTime());
    }
    readFFCard(inStream: TSDataInputStream, obj: FFCard): void {
        obj.setCardId(inStream.getInteger());
        obj.setLowerStakesOrBlinds(inStream.getInteger());
        obj.setHigherStakesOrBlinds(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGameSpeed(inStream.getInteger());
        obj.setTag(inStream.getInteger());
        obj.setImageName(inStream.getString());
        obj.setIsImageURL(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setLastPlayed(inStream.getBoolean());
        obj.setPoolId(inStream.getInteger());
        obj.setMinBuyin(inStream.getInteger());
        obj.setMaxBuyin(inStream.getInteger());
        obj.setPoolName(inStream.getStringEx());
    }

    writeFFCard(outStream: TSDataOutputStream, obj: FFCard): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getLowerStakesOrBlinds());
        outStream.putInteger(obj.getHigherStakesOrBlinds());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getGameSpeed());
        outStream.putInteger(obj.getTag());
        outStream.putString(obj.getImageName());
        outStream.putInteger(obj.getIsImageURL());
        outStream.putString(obj.getGameCurrency());
        outStream.putBoolean(obj.getLastPlayed());
        outStream.putInteger(obj.getPoolId());
        outStream.putInteger(obj.getMinBuyin());
        outStream.putInteger(obj.getMaxBuyin());
        outStream.putStringEx(obj.getPoolName());
    }
    readMTTCard(inStream: TSDataInputStream, obj: MTTCard): void {
        obj.setCardId(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGameSpeed(inStream.getInteger());
        obj.setTag(inStream.getInteger());
        obj.setImageName(inStream.getString());
        obj.setIsImageURL(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setIsFeatured(inStream.getBoolean());
        obj.setTourneyId(inStream.getInteger());
        obj.setScheduledTime(inStream.getLong());
        obj.setShortName(inStream.getStringEx());
        obj.setOverlayText(inStream.getString());
        obj.setStatus(inStream.getByte());
        obj.setRegistrationStatus(inStream.getBoolean());
        obj.setNoOfRegistrants(inStream.getInteger());
        obj.setBuyInType(inStream.getByte());
        obj.setTourneyFee(inStream.getInteger());
        obj.setFreeRollIdList(inStream.getIntArray());
        obj.setIsEvent(inStream.getBoolean());
        obj.setEventName(inStream.getString());
        obj.setColorCode(inStream.getInteger());
        obj.setQuintepokChampFee(inStream.getInteger());
        obj.setBountyAmount(inStream.getInteger());
        obj.setPartyPoints(inStream.getInteger());
        obj.setUnregClosed(inStream.getBoolean());
        obj.setParticipantEntryType(inStream.getInteger());
        obj.setPlayerEntriesAllowed(inStream.getInteger());
    }

    writeMTTCard(outStream: TSDataOutputStream, obj: MTTCard): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getGameSpeed());
        outStream.putInteger(obj.getTag());
        outStream.putString(obj.getImageName());
        outStream.putInteger(obj.getIsImageURL());
        outStream.putString(obj.getGameCurrency());
        outStream.putBoolean(obj.getIsFeatured());
        outStream.putInteger(obj.getTourneyId());
        outStream.putLong(obj.getScheduledTime());
        outStream.putStringEx(obj.getShortName());
        outStream.putString(obj.getOverlayText());
        outStream.putByte(obj.getStatus());
        outStream.putBoolean(obj.getRegistrationStatus());
        outStream.putInteger(obj.getNoOfRegistrants());
        outStream.putByte(obj.getBuyInType());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putIntArray(obj.getFreeRollIdList());
        outStream.putBoolean(obj.getIsEvent());
        outStream.putString(obj.getEventName());
        outStream.putInteger(obj.getColorCode());
        outStream.putInteger(obj.getQuintepokChampFee());
        outStream.putInteger(obj.getBountyAmount());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putBoolean(obj.getUnregClosed());
        outStream.putInteger(obj.getParticipantEntryType());
        outStream.putInteger(obj.getPlayerEntriesAllowed());
    }
    readRingGameCard(inStream: TSDataInputStream, obj: RingGameCard): void {
        obj.setCardId(inStream.getInteger());
        obj.setLowerStakesOrBlinds(inStream.getInteger());
        obj.setHigherStakesOrBlinds(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGameSpeed(inStream.getInteger());
        obj.setTag(inStream.getInteger());
        obj.setImageName(inStream.getString());
        obj.setIsImageURL(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setLastPlayed(inStream.getBoolean());
        obj.setMinBuyin(inStream.getInteger());
        obj.setMaxBuyin(inStream.getInteger());
        obj.setUniqueCode(inStream.getString());
        obj.setFavourite(inStream.getBoolean());
        obj.setProtectionLevel(inStream.getInteger());
    }

    writeRingGameCard(outStream: TSDataOutputStream, obj: RingGameCard): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getLowerStakesOrBlinds());
        outStream.putInteger(obj.getHigherStakesOrBlinds());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getGameSpeed());
        outStream.putInteger(obj.getTag());
        outStream.putString(obj.getImageName());
        outStream.putInteger(obj.getIsImageURL());
        outStream.putString(obj.getGameCurrency());
        outStream.putBoolean(obj.getLastPlayed());
        outStream.putInteger(obj.getMinBuyin());
        outStream.putInteger(obj.getMaxBuyin());
        outStream.putString(obj.getUniqueCode());
        outStream.putBoolean(obj.getFavourite());
        outStream.putInteger(obj.getProtectionLevel());
    }
    readMTSGCard(inStream: TSDataInputStream, obj: MTSGCard): void {
        obj.setCardId(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGameSpeed(inStream.getInteger());
        obj.setTag(inStream.getInteger());
        obj.setImageName(inStream.getString());
        obj.setIsImageURL(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setIsFeatured(inStream.getInteger());
        obj.setTourneyId(inStream.getInteger());
        obj.setSngGame(inStream.getInteger());
        obj.setStatus(inStream.getByte());
        obj.setNoOfRegistrants(inStream.getInteger());
        obj.setBuyInType(inStream.getByte());
        obj.setTourneyFee(inStream.getInteger());
        obj.setFreeRollIdList(inStream.getIntArray());
        obj.setColorCode(inStream.getInteger());
        obj.setQuintepokChampFee(inStream.getInteger());
        obj.setBountyAmount(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setPartyPoints(inStream.getInteger());
        obj.setMaxParticipants(inStream.getInteger());
    }

    writeMTSGCard(outStream: TSDataOutputStream, obj: MTSGCard): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getGameSpeed());
        outStream.putInteger(obj.getTag());
        outStream.putString(obj.getImageName());
        outStream.putInteger(obj.getIsImageURL());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getIsFeatured());
        outStream.putInteger(obj.getTourneyId());
        outStream.putInteger(obj.getSngGame());
        outStream.putByte(obj.getStatus());
        outStream.putInteger(obj.getNoOfRegistrants());
        outStream.putByte(obj.getBuyInType());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putIntArray(obj.getFreeRollIdList());
        outStream.putInteger(obj.getColorCode());
        outStream.putInteger(obj.getQuintepokChampFee());
        outStream.putInteger(obj.getBountyAmount());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putInteger(obj.getMaxParticipants());
    }
    readSNGCard(inStream: TSDataInputStream, obj: SNGCard): void {
        obj.setCardId(inStream.getInteger());
        obj.setTourneyTemplateId(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setTourneyFee(inStream.getInteger());
        obj.setBuyInType(inStream.getInteger());
        obj.setFreeRollIdList(inStream.getIntArray());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGameSpeed(inStream.getInteger());
        obj.setTag(inStream.getInteger());
        obj.setImageName(inStream.getString());
        obj.setIsImageURL(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setLastPlayed(inStream.getBoolean());
        obj.setSngGame(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setPartyPoints(inStream.getInteger());
        obj.setUniqueCode(inStream.getString());
        obj.setFavourite(inStream.getBoolean());
    }

    writeSNGCard(outStream: TSDataOutputStream, obj: SNGCard): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getTourneyTemplateId());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putInteger(obj.getBuyInType());
        outStream.putIntArray(obj.getFreeRollIdList());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getGameSpeed());
        outStream.putInteger(obj.getTag());
        outStream.putString(obj.getImageName());
        outStream.putInteger(obj.getIsImageURL());
        outStream.putString(obj.getGameCurrency());
        outStream.putBoolean(obj.getLastPlayed());
        outStream.putInteger(obj.getSngGame());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putString(obj.getUniqueCode());
        outStream.putBoolean(obj.getFavourite());
    }
    readResponseLobbyUpdateOneClick(inStream: TSDataInputStream, obj: ResponseLobbyUpdateOneClick): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAllCardsMap(inStream.getInt2ObjectMap());
        obj.setAllCardsOrder(inStream.getIntArray());
        obj.setTopCardsOrder(inStream.getObjectArray());
        obj.setLobbyType(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setIsFullUpdate(inStream.getBoolean());
        obj.setDeletedCardIds(inStream.getIntArray());
        obj.setClearAllOneClickUpdate(inStream.getBoolean());
        obj.setTourneyTicketCards(inStream.getIntArray());
    }

    writeResponseLobbyUpdateOneClick(outStream: TSDataOutputStream, obj: ResponseLobbyUpdateOneClick): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2ObjectMap(obj.getAllCardsMap());
        outStream.putIntArray(obj.getAllCardsOrder());
        outStream.putObjectArray(obj.getTopCardsOrder());
        outStream.putInteger(obj.getLobbyType());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getIsFullUpdate());
        outStream.putIntArray(obj.getDeletedCardIds());
        outStream.putBoolean(obj.getClearAllOneClickUpdate());
        outStream.putIntArray(obj.getTourneyTicketCards());
    }
    readRequestQuickSeatFastForward(inStream: TSDataInputStream, obj: RequestQuickSeatFastForward): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setLowerStakesOrBlinds(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setSpeedType(inStream.getInteger());
        obj.setBuyInValue(inStream.getInteger());
    }

    writeRequestQuickSeatFastForward(outStream: TSDataOutputStream, obj: RequestQuickSeatFastForward): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getLowerStakesOrBlinds());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getSpeedType());
        outStream.putInteger(obj.getBuyInValue());
    }
    readResponseQuickSeatFastForward(inStream: TSDataInputStream, obj: ResponseQuickSeatFastForward): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
        obj.setPoolId(inStream.getInteger());
    }

    writeResponseQuickSeatFastForward(outStream: TSDataOutputStream, obj: ResponseQuickSeatFastForward): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getResponseCode());
        outStream.putInteger(obj.getPoolId());
    }
    readRequestQuickSeatRing(inStream: TSDataInputStream, obj: RequestQuickSeatRing): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setTableIdList(inStream.getIntArray());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setLowerStakesOrBlinds(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setSpeedType(inStream.getInteger());
        obj.setSelectedBuyIn(inStream.getLong());
        obj.setMinBuyIn(inStream.getLong());
        obj.setNoOfTables(inStream.getInteger());
        obj.setAnonymousFlag(inStream.getInteger());
        obj.setActionType(inStream.getInteger());
        obj.setCardId(inStream.getInteger());
        obj.setBeginnerFlag(inStream.getInteger());
        obj.setPrevSearchedSeats(inStream.getIntArray());
        obj.setProtectionLevel(inStream.getInteger());
        obj.setActionButtonId(inStream.getByte());
        obj.setSubLiquidityType(inStream.getInteger());
        obj.setRealNameTable(inStream.getBoolean());
        obj.setAnteAmount(inStream.getInteger());
        obj.setRestrictedRoomId(inStream.getInteger());
        obj.setClubId(inStream.getInteger());
        obj.setNoOfSeats(inStream.getInteger());
        obj.setBuyInAmount(inStream.getLong());
        obj.setUpperStakesOrBlinds(inStream.getInteger());
        obj.setRebuyToMax(inStream.getBoolean());
    }

    writeRequestQuickSeatRing(outStream: TSDataOutputStream, obj: RequestQuickSeatRing): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putIntArray(obj.getTableIdList());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getLowerStakesOrBlinds());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getSpeedType());
        outStream.putLong(obj.getSelectedBuyIn());
        outStream.putLong(obj.getMinBuyIn());
        outStream.putInteger(obj.getNoOfTables());
        outStream.putInteger(obj.getAnonymousFlag());
        outStream.putInteger(obj.getActionType());
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getBeginnerFlag());
        outStream.putIntArray(obj.getPrevSearchedSeats());
        outStream.putInteger(obj.getProtectionLevel());
        outStream.putByte(obj.getActionButtonId());
        outStream.putInteger(obj.getSubLiquidityType());
        outStream.putBoolean(obj.getRealNameTable());
        outStream.putInteger(obj.getAnteAmount());
        outStream.putInteger(obj.getRestrictedRoomId());
        outStream.putInteger(obj.getClubId());
        outStream.putInteger(obj.getNoOfSeats());
        outStream.putLong(obj.getBuyInAmount());
        outStream.putInteger(obj.getUpperStakesOrBlinds());
        outStream.putBoolean(obj.getRebuyToMax());
    }
    readResponseQuickSeatRing(inStream: TSDataInputStream, obj: ResponseQuickSeatRing): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setResponseCode(inStream.getByte());
        obj.setResultList(inStream.getObjectArray());
        obj.setCardId(inStream.getInteger());
        obj.setPrevSearchedSeats(inStream.getIntArray());
        obj.setNextRecommendedSeater(inStream.getInteger());
        obj.setAthBlockTime(inStream.getLong());
        obj.setResponseErrorValue(inStream.getLong());
    }

    writeResponseQuickSeatRing(outStream: TSDataOutputStream, obj: ResponseQuickSeatRing): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putByte(obj.getResponseCode());
        outStream.putObjectArray(obj.getResultList());
        outStream.putInteger(obj.getCardId());
        outStream.putIntArray(obj.getPrevSearchedSeats());
        outStream.putInteger(obj.getNextRecommendedSeater());
        outStream.putLong(obj.getAthBlockTime());
        outStream.putLong(obj.getResponseErrorValue());
    }
    readLSCurrencyAmount(inStream: TSDataInputStream, obj: LSCurrencyAmount): void {
        obj.setCurrencyCode(inStream.getString());
        obj.setCurrencyAmount(inStream.getLong());
    }

    writeLSCurrencyAmount(outStream: TSDataOutputStream, obj: LSCurrencyAmount): void {
        outStream.putString(obj.getCurrencyCode());
        outStream.putLong(obj.getCurrencyAmount());
    }
    readRequestSearchMTCTParticipantNames(inStream: TSDataInputStream, obj: RequestSearchMTCTParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setSearchString(inStream.getString());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestSearchMTCTParticipantNames(outStream: TSDataOutputStream, obj: RequestSearchMTCTParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getSearchString());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestLSHeadsUpTourenyPlayerSequence(inStream: TSDataInputStream, obj: RequestLSHeadsUpTourenyPlayerSequence): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestLSHeadsUpTourenyPlayerSequence(outStream: TSDataOutputStream, obj: RequestLSHeadsUpTourenyPlayerSequence): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readResponseLSHeadsUpTourenyPlayerSequence(inStream: TSDataInputStream, obj: ResponseLSHeadsUpTourenyPlayerSequence): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLsHeadsUpTourenyPlayerSequence(inStream.getObject());
    }

    writeResponseLSHeadsUpTourenyPlayerSequence(outStream: TSDataOutputStream, obj: ResponseLSHeadsUpTourenyPlayerSequence): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getLsHeadsUpTourenyPlayerSequence());
    }
    readResponseLSMTCTStatus(inStream: TSDataInputStream, obj: ResponseLSMTCTStatus): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setResponseId(inStream.getInteger());
    }

    writeResponseLSMTCTStatus(outStream: TSDataOutputStream, obj: ResponseLSMTCTStatus): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getResponseId());
    }
    readLSMTCTStartTimeChangeEvent(inStream: TSDataInputStream, obj: LSMTCTStartTimeChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setTourneyStartTime(inStream.getTimestamp());
    }

    writeLSMTCTStartTimeChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTStartTimeChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putTimestamp(obj.getTourneyStartTime());
    }
    readLSTourneyStartTimeChange(inStream: TSDataInputStream, obj: LSTourneyStartTimeChange): void {
        obj.setTourneyStartTime(inStream.getTimestamp());
    }

    writeLSTourneyStartTimeChange(outStream: TSDataOutputStream, obj: LSTourneyStartTimeChange): void {
        outStream.putTimestamp(obj.getTourneyStartTime());
    }
    readLSMTCTEventsQualifiersChangeEvent(inStream: TSDataInputStream, obj: LSMTCTEventsQualifiersChangeEvent): void {
        obj.setMtctId(inStream.getInteger());
        obj.setMtctEvents(inStream.getIntArray());
        obj.setQualifiers(inStream.getIntArray());
    }

    writeLSMTCTEventsQualifiersChangeEvent(outStream: TSDataOutputStream, obj: LSMTCTEventsQualifiersChangeEvent): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putIntArray(obj.getMtctEvents());
        outStream.putIntArray(obj.getQualifiers());
    }
    readLSTourneyEventsQualifiersChange(inStream: TSDataInputStream, obj: LSTourneyEventsQualifiersChange): void {
        obj.setMtctEvents(inStream.getIntArray());
        obj.setQualifiers(inStream.getIntArray());
    }

    writeLSTourneyEventsQualifiersChange(outStream: TSDataOutputStream, obj: LSTourneyEventsQualifiersChange): void {
        outStream.putIntArray(obj.getMtctEvents());
        outStream.putIntArray(obj.getQualifiers());
    }
    readLSPoolPlayerCountDeltaUpdate(inStream: TSDataInputStream, obj: LSPoolPlayerCountDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLsPoolPlayerCount(inStream.getObject());
    }

    writeLSPoolPlayerCountDeltaUpdate(outStream: TSDataOutputStream, obj: LSPoolPlayerCountDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getLsPoolPlayerCount());
    }
    readLSTableOccupancyDeltaUpdate(inStream: TSDataInputStream, obj: LSTableOccupancyDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLsTableOccupancyChange(inStream.getObject());
    }

    writeLSTableOccupancyDeltaUpdate(outStream: TSDataOutputStream, obj: LSTableOccupancyDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObject(obj.getLsTableOccupancyChange());
    }
    // readLSTourneyDeleteChange(inStream: TSDataInputStream, obj: LSTourneyDeleteChange): void {
    // }

    // writeLSTourneyDeleteChange(outStream: TSDataOutputStream, obj: LSTourneyDeleteChange): void {
    // }
    readRequestLSPlayerTableCounts(inStream: TSDataInputStream, obj: RequestLSPlayerTableCounts): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSubscribed(inStream.getBoolean());
    }

    writeRequestLSPlayerTableCounts(outStream: TSDataOutputStream, obj: RequestLSPlayerTableCounts): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getSubscribed());
    }
    readResultQuickSeatRing(inStream: TSDataInputStream, obj: ResultQuickSeatRing): void {
        obj.setTableId(inStream.getInteger());
        obj.setTableInfoId(inStream.getInteger());
        obj.setMaxNoOfSeats(inStream.getInteger());
        obj.setPeerId(inStream.getInteger());
        obj.setErrorCode(inStream.getInteger());
        obj.setSeatNo(inStream.getInteger());
        obj.setBlockSeatExpiresAt(inStream.getLong());
    }

    writeResultQuickSeatRing(outStream: TSDataOutputStream, obj: ResultQuickSeatRing): void {
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getTableInfoId());
        outStream.putInteger(obj.getMaxNoOfSeats());
        outStream.putInteger(obj.getPeerId());
        outStream.putInteger(obj.getErrorCode());
        outStream.putInteger(obj.getSeatNo());
        outStream.putLong(obj.getBlockSeatExpiresAt());
    }
    readRequestLobbyUpdateOneClick(inStream: TSDataInputStream, obj: RequestLobbyUpdateOneClick): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLobbyType(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
    }

    writeRequestLobbyUpdateOneClick(outStream: TSDataOutputStream, obj: RequestLobbyUpdateOneClick): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLobbyType());
        outStream.putLong(obj.getSnapShotTimeInNanos());
    }
    readLSLobbyCardDeltaUpdate(inStream: TSDataInputStream, obj: LSLobbyCardDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDeltaUpdatesList(inStream.getObjectArray());
        obj.setAllCardsOrder(inStream.getIntArray());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setLobbyType(inStream.getInteger());
    }

    writeLSLobbyCardDeltaUpdate(outStream: TSDataOutputStream, obj: LSLobbyCardDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getDeltaUpdatesList());
        outStream.putIntArray(obj.getAllCardsOrder());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putInteger(obj.getLobbyType());
    }
    readDeleteCard(inStream: TSDataInputStream, obj: DeleteCard): void {
        obj.setCardId(inStream.getInteger());
    }

    writeDeleteCard(outStream: TSDataOutputStream, obj: DeleteCard): void {
        outStream.putInteger(obj.getCardId());
    }
    readUpdateCardLastPlayed(inStream: TSDataInputStream, obj: UpdateCardLastPlayed): void {
        obj.setCardId(inStream.getInteger());
        obj.setLastPlayed(inStream.getBoolean());
    }

    writeUpdateCardLastPlayed(outStream: TSDataOutputStream, obj: UpdateCardLastPlayed): void {
        outStream.putInteger(obj.getCardId());
        outStream.putBoolean(obj.getLastPlayed());
    }
    readUpdateCardMTTRegistrationStatus(inStream: TSDataInputStream, obj: UpdateCardMTTRegistrationStatus): void {
        obj.setCardId(inStream.getByte());
        obj.setStatus(inStream.getByte());
        obj.setRegistrationStatus(inStream.getBoolean());
    }

    writeUpdateCardMTTRegistrationStatus(outStream: TSDataOutputStream, obj: UpdateCardMTTRegistrationStatus): void {
        outStream.putByte(obj.getCardId());
        outStream.putByte(obj.getStatus());
        outStream.putBoolean(obj.getRegistrationStatus());
    }
    readUpdateCardMTTRegistrantCount(inStream: TSDataInputStream, obj: UpdateCardMTTRegistrantCount): void {
        obj.setCardId(inStream.getInteger());
        obj.setRegistrantCount(inStream.getInteger());
    }

    writeUpdateCardMTTRegistrantCount(outStream: TSDataOutputStream, obj: UpdateCardMTTRegistrantCount): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getRegistrantCount());
    }
    readUpdateCardTag(inStream: TSDataInputStream, obj: UpdateCardTag): void {
        obj.setCardId(inStream.getInteger());
        obj.setTag(inStream.getByte());
        obj.setLastPlayed(inStream.getBoolean());
    }

    writeUpdateCardTag(outStream: TSDataOutputStream, obj: UpdateCardTag): void {
        outStream.putInteger(obj.getCardId());
        outStream.putByte(obj.getTag());
        outStream.putBoolean(obj.getLastPlayed());
    }
    readPlayerLobbyToGameplayTrackerInfo(inStream: TSDataInputStream, obj: PlayerLobbyToGameplayTrackerInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCardId(inStream.getInteger());
        obj.setLobbyType(inStream.getInteger());
        obj.setOriginalCardPosition(inStream.getInteger());
        obj.setCurrentCardPosition(inStream.getInteger());
        obj.setTrackerIdList(inStream.getLongArray());
        obj.setFilterUsedByPlayer(inStream.getInteger());
        obj.setCardType(inStream.getInteger());
        obj.setTourneyId(inStream.getInteger());
        obj.setPlayerAccountName(inStream.getString());
        obj.setTag(inStream.getInteger());
        obj.setTopCardIds(inStream.getIntArray());
    }

    writePlayerLobbyToGameplayTrackerInfo(outStream: TSDataOutputStream, obj: PlayerLobbyToGameplayTrackerInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getLobbyType());
        outStream.putInteger(obj.getOriginalCardPosition());
        outStream.putInteger(obj.getCurrentCardPosition());
        outStream.putLongArray(obj.getTrackerIdList());
        outStream.putInteger(obj.getFilterUsedByPlayer());
        outStream.putInteger(obj.getCardType());
        outStream.putInteger(obj.getTourneyId());
        outStream.putString(obj.getPlayerAccountName());
        outStream.putInteger(obj.getTag());
        outStream.putIntArray(obj.getTopCardIds());
    }
    readRequestLobbyConfig(inStream: TSDataInputStream, obj: RequestLobbyConfig): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMd5sum(inStream.getString());
        obj.setIsLogedIn(inStream.getBoolean());
    }

    writeRequestLobbyConfig(outStream: TSDataOutputStream, obj: RequestLobbyConfig): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getMd5sum());
        outStream.putBoolean(obj.getIsLogedIn());
    }
    readResponseLobbyConfig(inStream: TSDataInputStream, obj: ResponseLobbyConfig): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabConfigText(inStream.getString());
        obj.setMd5sum(inStream.getString());
    }

    writeResponseLobbyConfig(outStream: TSDataOutputStream, obj: ResponseLobbyConfig): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getTabConfigText());
        outStream.putString(obj.getMd5sum());
    }
    readLSTopCardsOrderDeltaUpdate(inStream: TSDataInputStream, obj: LSTopCardsOrderDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTopCardsOrder(inStream.getObjectArray());
        obj.setLobbyType(inStream.getInteger());
    }

    writeLSTopCardsOrderDeltaUpdate(outStream: TSDataOutputStream, obj: LSTopCardsOrderDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getTopCardsOrder());
        outStream.putInteger(obj.getLobbyType());
    }
    readAddFavoriteCard(inStream: TSDataInputStream, obj: AddFavoriteCard): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCards(inStream.getObjectArray());
    }

    writeAddFavoriteCard(outStream: TSDataOutputStream, obj: AddFavoriteCard): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getCards());
    }
    readDeleteFavoriteCard(inStream: TSDataInputStream, obj: DeleteFavoriteCard): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFavoriteId(inStream.getString());
    }

    writeDeleteFavoriteCard(outStream: TSDataOutputStream, obj: DeleteFavoriteCard): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getFavoriteId());
    }
    readResponseDeleteFavoriteCard(inStream: TSDataInputStream, obj: ResponseDeleteFavoriteCard): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
        obj.setFavoriteId(inStream.getString());
    }

    writeResponseDeleteFavoriteCard(outStream: TSDataOutputStream, obj: ResponseDeleteFavoriteCard): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseCode());
        outStream.putString(obj.getFavoriteId());
    }
    readResponseAddFavoriteCard(inStream: TSDataInputStream, obj: ResponseAddFavoriteCard): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setResponseCode(inStream.getInteger());
        obj.setFavoriteId(inStream.getString());
    }

    writeResponseAddFavoriteCard(outStream: TSDataOutputStream, obj: ResponseAddFavoriteCard): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getResponseCode());
        outStream.putString(obj.getFavoriteId());
    }
    readFavoriteCards(inStream: TSDataInputStream, obj: FavoriteCards): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFavorites(inStream.getObjectArray());
    }

    writeFavoriteCards(outStream: TSDataOutputStream, obj: FavoriteCards): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getFavorites());
    }
    readRequestSNGQuickRegister(inStream: TSDataInputStream, obj: RequestSNGQuickRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setCardId(inStream.getInteger());
        obj.setSelectedBuyInType(inStream.getByte());
        obj.setBuyInValue(inStream.getInteger());
        obj.setSngTemplateId(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
    }

    writeRequestSNGQuickRegister(outStream: TSDataOutputStream, obj: RequestSNGQuickRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getCardId());
        outStream.putByte(obj.getSelectedBuyInType());
        outStream.putInteger(obj.getBuyInValue());
        outStream.putInteger(obj.getSngTemplateId());
        outStream.putLong(obj.getConversationId());
    }
    readResponseSNGQuickRegister(inStream: TSDataInputStream, obj: ResponseSNGQuickRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setCardId(inStream.getInteger());
        obj.setFxSnapShotId(inStream.getLong());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setMtctId(inStream.getInteger());
        obj.setResponseId(inStream.getByte());
        obj.setTrackerIdList(inStream.getLongArray());
        obj.setConversationId(inStream.getLong());
        obj.setParticipantNo(inStream.getInteger());
    }

    writeResponseSNGQuickRegister(outStream: TSDataOutputStream, obj: ResponseSNGQuickRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getCardId());
        outStream.putLong(obj.getFxSnapShotId());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putInteger(obj.getMtctId());
        outStream.putByte(obj.getResponseId());
        outStream.putLongArray(obj.getTrackerIdList());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getParticipantNo());
    }
    readOneClickTourneyStatusChange(inStream: TSDataInputStream, obj: OneClickTourneyStatusChange): void {
        obj.setCardId(inStream.getInteger());
        obj.setStatus(inStream.getByte());
    }

    writeOneClickTourneyStatusChange(outStream: TSDataOutputStream, obj: OneClickTourneyStatusChange): void {
        outStream.putInteger(obj.getCardId());
        outStream.putByte(obj.getStatus());
    }
    readOneClickTourneyRegistrationChange(inStream: TSDataInputStream, obj: OneClickTourneyRegistrationChange): void {
        obj.setCardId(inStream.getInteger());
        obj.setNoOfParticipants(inStream.getInteger());
    }

    writeOneClickTourneyRegistrationChange(outStream: TSDataOutputStream, obj: OneClickTourneyRegistrationChange): void {
        outStream.putInteger(obj.getCardId());
        outStream.putInteger(obj.getNoOfParticipants());
    }
    readOneClickTourneyLateRegistrationChange(inStream: TSDataInputStream, obj: OneClickTourneyLateRegistrationChange): void {
        obj.setCardId(inStream.getInteger());
        obj.setLateRegInProgress(inStream.getBoolean());
    }

    writeOneClickTourneyLateRegistrationChange(outStream: TSDataOutputStream, obj: OneClickTourneyLateRegistrationChange): void {
        outStream.putInteger(obj.getCardId());
        outStream.putBoolean(obj.getLateRegInProgress());
    }
    readOneClickTourneyTicketCardChanges(inStream: TSDataInputStream, obj: OneClickTourneyTicketCardChanges): void {
        obj.setCardIds(inStream.getIntArray());
    }

    writeOneClickTourneyTicketCardChanges(outStream: TSDataOutputStream, obj: OneClickTourneyTicketCardChanges): void {
        outStream.putIntArray(obj.getCardIds());
    }
    readLSItaliaAuthorizationCodes(inStream: TSDataInputStream, obj: LSItaliaAuthorizationCodes): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipationId(inStream.getString());
        obj.setSessionId(inStream.getString());
    }

    writeLSItaliaAuthorizationCodes(outStream: TSDataOutputStream, obj: LSItaliaAuthorizationCodes): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getParticipationId());
        outStream.putString(obj.getSessionId());
    }
    readLobbyPlayerTableCounts(inStream: TSDataInputStream, obj: LobbyPlayerTableCounts): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setTableCount(inStream.getInteger());
    }

    writeLobbyPlayerTableCounts(outStream: TSDataOutputStream, obj: LobbyPlayerTableCounts): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putInteger(obj.getTableCount());
    }
    readRequestSNGQuickRegisterAdvanced(inStream: TSDataInputStream, obj: RequestSNGQuickRegisterAdvanced): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setGametype(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setBuyInValue(inStream.getInteger());
    }

    writeRequestSNGQuickRegisterAdvanced(outStream: TSDataOutputStream, obj: RequestSNGQuickRegisterAdvanced): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getGametype());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getBuyInValue());
    }
    readResponseSNGQuickRegisterAdvanced(inStream: TSDataInputStream, obj: ResponseSNGQuickRegisterAdvanced): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setFxSnapShotId(inStream.getLong());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setMtctId(inStream.getInteger());
        obj.setResponseId(inStream.getByte());
        obj.setTrackerIdList(inStream.getLongArray());
        obj.setParticipantNo(inStream.getInteger());
    }

    writeResponseSNGQuickRegisterAdvanced(outStream: TSDataOutputStream, obj: ResponseSNGQuickRegisterAdvanced): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putLong(obj.getFxSnapShotId());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putInteger(obj.getMtctId());
        outStream.putByte(obj.getResponseId());
        outStream.putLongArray(obj.getTrackerIdList());
        outStream.putInteger(obj.getParticipantNo());
    }
    readOneClickTourneyUnregistrationCloseChange(inStream: TSDataInputStream, obj: OneClickTourneyUnregistrationCloseChange): void {
        obj.setCardId(inStream.getInteger());
    }

    writeOneClickTourneyUnregistrationCloseChange(outStream: TSDataOutputStream, obj: OneClickTourneyUnregistrationCloseChange): void {
        outStream.putInteger(obj.getCardId());
    }
    readChangeTabRequest(inStream: TSDataInputStream, obj: ChangeTabRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLobbyTypeId(inStream.getInteger());
    }

    writeChangeTabRequest(outStream: TSDataOutputStream, obj: ChangeTabRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getLobbyTypeId());
    }
    readPMUQuintePokCounter(inStream: TSDataInputStream, obj: PMUQuintePokCounter): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChampionshipAmount(inStream.getLong());
        obj.setCounterStatus(inStream.getByte());
        obj.setMtctid(inStream.getInteger());
    }

    writePMUQuintePokCounter(outStream: TSDataOutputStream, obj: PMUQuintePokCounter): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getChampionshipAmount());
        outStream.putByte(obj.getCounterStatus());
        outStream.putInteger(obj.getMtctid());
    }
    readResponseTickersUpdate(inStream: TSDataInputStream, obj: ResponseTickersUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTickers(inStream.getObjectArray());
    }

    writeResponseTickersUpdate(outStream: TSDataOutputStream, obj: ResponseTickersUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getTickers());
    }
    readRequestTourneyLobbyActive(inStream: TSDataInputStream, obj: RequestTourneyLobbyActive): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActive(inStream.getBoolean());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestTourneyLobbyActive(outStream: TSDataOutputStream, obj: RequestTourneyLobbyActive): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getActive());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestSNGGroupRegister(inStream: TSDataInputStream, obj: RequestSNGGroupRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setSngTemplateId(inStream.getInteger());
        obj.setBuyInValue(inStream.getInteger());
        obj.setConversationId(inStream.getLong());
    }

    writeRequestSNGGroupRegister(outStream: TSDataOutputStream, obj: RequestSNGGroupRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getSngTemplateId());
        outStream.putInteger(obj.getBuyInValue());
        outStream.putLong(obj.getConversationId());
    }
    readResponseSNGGroupRegister(inStream: TSDataInputStream, obj: ResponseSNGGroupRegister): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setFxSnapShotId(inStream.getLong());
        obj.setErrorMessage(inStream.getStringEx());
        obj.setMtctId(inStream.getInteger());
        obj.setResponseId(inStream.getByte());
        obj.setTrackerIdList(inStream.getLongArray());
        obj.setConversationId(inStream.getLong());
        obj.setParticipantNo(inStream.getInteger());
    }

    writeResponseSNGGroupRegister(outStream: TSDataOutputStream, obj: ResponseSNGGroupRegister): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putLong(obj.getFxSnapShotId());
        outStream.putStringEx(obj.getErrorMessage());
        outStream.putInteger(obj.getMtctId());
        outStream.putByte(obj.getResponseId());
        outStream.putLongArray(obj.getTrackerIdList());
        outStream.putLong(obj.getConversationId());
        outStream.putInteger(obj.getParticipantNo());
    }
    readResponseTourneyIdsAcceptingFreerolls(inStream: TSDataInputStream, obj: ResponseTourneyIdsAcceptingFreerolls): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTourneyIds(inStream.getIntArray());
        obj.setIsFullUpdate(inStream.getBoolean());
    }

    writeResponseTourneyIdsAcceptingFreerolls(outStream: TSDataOutputStream, obj: ResponseTourneyIdsAcceptingFreerolls): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getTourneyIds());
        outStream.putBoolean(obj.getIsFullUpdate());
    }
    readResponseMyTicketsCount(inStream: TSDataInputStream, obj: ResponseMyTicketsCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setCount(inStream.getInteger());
    }

    writeResponseMyTicketsCount(outStream: TSDataOutputStream, obj: ResponseMyTicketsCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getCount());
    }
    readSNGPromoUpdate(inStream: TSDataInputStream, obj: SNGPromoUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPromoAvailable(inStream.getBoolean());
        obj.setPromoId(inStream.getInteger());
        obj.setTimeStamp(inStream.getLong());
    }

    writeSNGPromoUpdate(outStream: TSDataOutputStream, obj: SNGPromoUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getPromoAvailable());
        outStream.putInteger(obj.getPromoId());
        outStream.putLong(obj.getTimeStamp());
    }
    readLSSNGPromoUpdate(inStream: TSDataInputStream, obj: LSSNGPromoUpdate): void {
        obj.setPromoAvailable(inStream.getBoolean());
        obj.setPromoId(inStream.getInteger());
        obj.setTimeStamp(inStream.getLong());
    }

    writeLSSNGPromoUpdate(outStream: TSDataOutputStream, obj: LSSNGPromoUpdate): void {
        outStream.putBoolean(obj.getPromoAvailable());
        outStream.putInteger(obj.getPromoId());
        outStream.putLong(obj.getTimeStamp());
    }
    readRequestSNGInstance(inStream: TSDataInputStream, obj: RequestSNGInstance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setSngTemplateId(inStream.getInteger());
        obj.setSourceType(inStream.getByte());
        obj.setTourneyCategory(inStream.getByte());
    }

    writeRequestSNGInstance(outStream: TSDataOutputStream, obj: RequestSNGInstance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getSngTemplateId());
        outStream.putByte(obj.getSourceType());
        outStream.putByte(obj.getTourneyCategory());
    }
    readResponseSNGInstance(inStream: TSDataInputStream, obj: ResponseSNGInstance): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setRequestId(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setResponseId(inStream.getByte());
        obj.setSourceType(inStream.getByte());
        obj.setTourneyCategory(inStream.getByte());
    }

    writeResponseSNGInstance(outStream: TSDataOutputStream, obj: ResponseSNGInstance): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getRequestId());
        outStream.putInteger(obj.getMtctId());
        outStream.putByte(obj.getResponseId());
        outStream.putByte(obj.getSourceType());
        outStream.putByte(obj.getTourneyCategory());
    }
    readRequestTourneyCount(inStream: TSDataInputStream, obj: RequestTourneyCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabIds(inStream.getIntArray());
        obj.setRequestType(inStream.getInteger());
        obj.setFilterHeadsUp(inStream.getBoolean());
        obj.setFilter7CardStud(inStream.getBoolean());
    }

    writeRequestTourneyCount(outStream: TSDataOutputStream, obj: RequestTourneyCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getTabIds());
        outStream.putInteger(obj.getRequestType());
        outStream.putBoolean(obj.getFilterHeadsUp());
        outStream.putBoolean(obj.getFilter7CardStud());
    }
    readResponseTourneyCount(inStream: TSDataInputStream, obj: ResponseTourneyCount): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTrnyCount(inStream.getInteger());
        obj.setRequestType(inStream.getInteger());
    }

    writeResponseTourneyCount(outStream: TSDataOutputStream, obj: ResponseTourneyCount): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTrnyCount());
        outStream.putInteger(obj.getRequestType());
    }
    readLSPrizeDetailsMessage(inStream: TSDataInputStream, obj: LSPrizeDetailsMessage): void {
        obj.setPrizeMessage(inStream.getStringEx());
        obj.setTooltip(inStream.getStringExArray());
        obj.setTicketType(inStream.getByte());
    }

    writeLSPrizeDetailsMessage(outStream: TSDataOutputStream, obj: LSPrizeDetailsMessage): void {
        outStream.putStringEx(obj.getPrizeMessage());
        outStream.putStringExArray(obj.getTooltip());
        outStream.putByte(obj.getTicketType());
    }
    readRequestLSPlayerTourneyTabInfo(inStream: TSDataInputStream, obj: RequestLSPlayerTourneyTabInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setTableId(inStream.getInteger());
    }

    writeRequestLSPlayerTourneyTabInfo(outStream: TSDataOutputStream, obj: RequestLSPlayerTourneyTabInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
        outStream.putString(obj.getScreenName());
        outStream.putInteger(obj.getTableId());
    }
    readResponseLSPlayerTourneyTabInfo(inStream: TSDataInputStream, obj: ResponseLSPlayerTourneyTabInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setChipsInPlay(inStream.getLong());
        obj.setLargestStackSize(inStream.getLong());
        obj.setSmallestStackSize(inStream.getLong());
        obj.setAverageStackSize(inStream.getLong());
        obj.setNumberOfPlayerRemaining(inStream.getInteger());
        obj.setNextBreakInSecs(inStream.getLong());
        obj.setPlayerCurrentPosition(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
    }

    writeResponseLSPlayerTourneyTabInfo(outStream: TSDataOutputStream, obj: ResponseLSPlayerTourneyTabInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getChipsInPlay());
        outStream.putLong(obj.getLargestStackSize());
        outStream.putLong(obj.getSmallestStackSize());
        outStream.putLong(obj.getAverageStackSize());
        outStream.putInteger(obj.getNumberOfPlayerRemaining());
        outStream.putLong(obj.getNextBreakInSecs());
        outStream.putInteger(obj.getPlayerCurrentPosition());
        outStream.putInteger(obj.getTableId());
    }
    readLSRequestMTCTId(inStream: TSDataInputStream, obj: LSRequestMTCTId): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setFreerollId(inStream.getInteger());
    }

    writeLSRequestMTCTId(outStream: TSDataOutputStream, obj: LSRequestMTCTId): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getFreerollId());
    }
    readLSRequestMTCTIdRes(inStream: TSDataInputStream, obj: LSRequestMTCTIdRes): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
    }

    writeLSRequestMTCTIdRes(outStream: TSDataOutputStream, obj: LSRequestMTCTIdRes): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
    }
    readRequestSNGJPPlayNowUpdate(inStream: TSDataInputStream, obj: RequestSNGJPPlayNowUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getByte());
        obj.setRequestType(inStream.getInteger());
    }

    writeRequestSNGJPPlayNowUpdate(outStream: TSDataOutputStream, obj: RequestSNGJPPlayNowUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putByte(obj.getDomain());
        outStream.putInteger(obj.getRequestType());
    }
    readResponseSNGJPPlayNowUpdate(inStream: TSDataInputStream, obj: ResponseSNGJPPlayNowUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getByte());
        obj.setSngJPCards(inStream.getObjectArray());
        obj.setIsFullUpdate(inStream.getBoolean());
        obj.setDeletedSNGJPCards(inStream.getIntArray());
        obj.setRequestType(inStream.getInteger());
    }

    writeResponseSNGJPPlayNowUpdate(outStream: TSDataOutputStream, obj: ResponseSNGJPPlayNowUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putByte(obj.getDomain());
        outStream.putObjectArray(obj.getSngJPCards());
        outStream.putBoolean(obj.getIsFullUpdate());
        outStream.putIntArray(obj.getDeletedSNGJPCards());
        outStream.putInteger(obj.getRequestType());
    }
    readResponseSNGJPPlayNowDeltaChanges(inStream: TSDataInputStream, obj: ResponseSNGJPPlayNowDeltaChanges): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getByte());
        obj.setDeltaEvents(inStream.getObjectArray());
        obj.setRequestType(inStream.getInteger());
    }

    writeResponseSNGJPPlayNowDeltaChanges(outStream: TSDataOutputStream, obj: ResponseSNGJPPlayNowDeltaChanges): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putByte(obj.getDomain());
        outStream.putObjectArray(obj.getDeltaEvents());
        outStream.putInteger(obj.getRequestType());
    }
    readSNGJPCard(inStream: TSDataInputStream, obj: SNGJPCard): void {
        obj.setSngJPId(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setBuyIn(inStream.getInteger());
        obj.setTourneyFee(inStream.getInteger());
        obj.setIsTrnyFeeTypePercent(inStream.getBoolean());
        obj.setTrnyFeePercent(inStream.getShort());
        obj.setGameCurrency(inStream.getString());
        obj.setSngJPType(inStream.getByte());
        obj.setGameType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setMaxRegistrations(inStream.getByte());
        obj.setGamePlayType(inStream.getByte());
        obj.setMinMultiplier(inStream.getInteger());
        obj.setMaxMultiplier(inStream.getInteger());
        obj.setDefaultChips(inStream.getLong());
        obj.setBuyInType(inStream.getByte());
        obj.setTournmentTicketList(inStream.getIntArray());
        obj.setIsPasswordProtected(inStream.getBoolean());
        obj.setServerPeerId(inStream.getInteger());
        obj.setWinUptoAmount(inStream.getLong());
        obj.setPromotionalTitle(inStream.getString());
        obj.setPromotionalSubTitle(inStream.getString());
        obj.setPayOutType(inStream.getByte());
        obj.setTotalPrizeValue(inStream.getLong());
        obj.setJackpotTemplateId(inStream.getInteger());
        obj.setSummary(inStream.getStringEx());
        obj.setMoreInfoUrl(inStream.getString());
        obj.setBlindDuration(inStream.getInteger());
        obj.setSubLiquidityType(inStream.getInteger());
        obj.setImageId(inStream.getString());
        obj.setCustomisedImage(inStream.getBoolean());
        obj.setCardType(inStream.getInteger());
        obj.setCardPropertiesId(inStream.getInteger());
    }

    writeSNGJPCard(outStream: TSDataOutputStream, obj: SNGJPCard): void {
        outStream.putInteger(obj.getSngJPId());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putBoolean(obj.getIsTrnyFeeTypePercent());
        outStream.putShort(obj.getTrnyFeePercent());
        outStream.putString(obj.getGameCurrency());
        outStream.putByte(obj.getSngJPType());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getLimitType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putByte(obj.getMaxRegistrations());
        outStream.putByte(obj.getGamePlayType());
        outStream.putInteger(obj.getMinMultiplier());
        outStream.putInteger(obj.getMaxMultiplier());
        outStream.putLong(obj.getDefaultChips());
        outStream.putByte(obj.getBuyInType());
        outStream.putIntArray(obj.getTournmentTicketList());
        outStream.putBoolean(obj.getIsPasswordProtected());
        outStream.putInteger(obj.getServerPeerId());
        outStream.putLong(obj.getWinUptoAmount());
        outStream.putString(obj.getPromotionalTitle());
        outStream.putString(obj.getPromotionalSubTitle());
        outStream.putByte(obj.getPayOutType());
        outStream.putLong(obj.getTotalPrizeValue());
        outStream.putInteger(obj.getJackpotTemplateId());
        outStream.putStringEx(obj.getSummary());
        outStream.putString(obj.getMoreInfoUrl());
        outStream.putInteger(obj.getBlindDuration());
        outStream.putInteger(obj.getSubLiquidityType());
        outStream.putString(obj.getImageId());
        outStream.putBoolean(obj.getCustomisedImage());
        outStream.putInteger(obj.getCardType());
        outStream.putInteger(obj.getCardPropertiesId());
    }
    readSNGJPCardDeleteEvent(inStream: TSDataInputStream, obj: SNGJPCardDeleteEvent): void {
        obj.setSngJPId(inStream.getInteger());
    }

    writeSNGJPCardDeleteEvent(outStream: TSDataOutputStream, obj: SNGJPCardDeleteEvent): void {
        outStream.putInteger(obj.getSngJPId());
    }
    readRequestSNGJPObserverUpdate(inStream: TSDataInputStream, obj: RequestSNGJPObserverUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getByte());
    }

    writeRequestSNGJPObserverUpdate(outStream: TSDataOutputStream, obj: RequestSNGJPObserverUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putByte(obj.getDomain());
    }
    readResponseSNGJPObserverUpdate(inStream: TSDataInputStream, obj: ResponseSNGJPObserverUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getByte());
        obj.setSngJPInstanceCards(inStream.getObjectArray());
        obj.setIsFullUpdate(inStream.getBoolean());
        obj.setDeletedObserverCardIds(inStream.getIntArray());
    }

    writeResponseSNGJPObserverUpdate(outStream: TSDataOutputStream, obj: ResponseSNGJPObserverUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putByte(obj.getDomain());
        outStream.putObjectArray(obj.getSngJPInstanceCards());
        outStream.putBoolean(obj.getIsFullUpdate());
        outStream.putIntArray(obj.getDeletedObserverCardIds());
    }
    readResponseSNGJPObserverDeltaChanges(inStream: TSDataInputStream, obj: ResponseSNGJPObserverDeltaChanges): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getByte());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeResponseSNGJPObserverDeltaChanges(outStream: TSDataOutputStream, obj: ResponseSNGJPObserverDeltaChanges): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putByte(obj.getDomain());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readSNGJPInstanceCard(inStream: TSDataInputStream, obj: SNGJPInstanceCard): void {
        obj.setSngJPId(inStream.getInteger());
        obj.setTournamentID(inStream.getInteger());
        obj.setTableId(inStream.getInteger());
        obj.setBuyIn(inStream.getInteger());
        obj.setTourneyFee(inStream.getInteger());
        obj.setIsTrnyFeeTypePercent(inStream.getBoolean());
        obj.setTrnyFeePercent(inStream.getShort());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setGameCurrency(inStream.getString());
        obj.setObserverCardId(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setRemainingPlayers(inStream.getInteger());
        obj.setName(inStream.getStringEx());
        obj.setGameType(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGamePlayType(inStream.getInteger());
        obj.setLimitType(inStream.getInteger());
        obj.setInstanceDisplayType(inStream.getByte());
        obj.setSngJPType(inStream.getByte());
        obj.setWatchersCount(inStream.getInteger());
        obj.setStartTime(inStream.getTimestamp());
        obj.setImageId(inStream.getString());
        obj.setCustomisedImage(inStream.getBoolean());
        obj.setCardType(inStream.getInteger());
    }

    writeSNGJPInstanceCard(outStream: TSDataOutputStream, obj: SNGJPInstanceCard): void {
        outStream.putInteger(obj.getSngJPId());
        outStream.putInteger(obj.getTournamentID());
        outStream.putInteger(obj.getTableId());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putBoolean(obj.getIsTrnyFeeTypePercent());
        outStream.putShort(obj.getTrnyFeePercent());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getObserverCardId());
        outStream.putInteger(obj.getStatus());
        outStream.putInteger(obj.getRemainingPlayers());
        outStream.putStringEx(obj.getName());
        outStream.putInteger(obj.getGameType());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putInteger(obj.getGamePlayType());
        outStream.putInteger(obj.getLimitType());
        outStream.putByte(obj.getInstanceDisplayType());
        outStream.putByte(obj.getSngJPType());
        outStream.putInteger(obj.getWatchersCount());
        outStream.putTimestamp(obj.getStartTime());
        outStream.putString(obj.getImageId());
        outStream.putBoolean(obj.getCustomisedImage());
        outStream.putInteger(obj.getCardType());
    }
    readSNGJPInstanceCardDeleteEvent(inStream: TSDataInputStream, obj: SNGJPInstanceCardDeleteEvent): void {
        obj.setObserverCardId(inStream.getInteger());
    }

    writeSNGJPInstanceCardDeleteEvent(outStream: TSDataOutputStream, obj: SNGJPInstanceCardDeleteEvent): void {
        outStream.putInteger(obj.getObserverCardId());
    }
    readRequestSNGJPFeed(inStream: TSDataInputStream, obj: RequestSNGJPFeed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setRequestType(inStream.getInteger());
    }

    writeRequestSNGJPFeed(outStream: TSDataOutputStream, obj: RequestSNGJPFeed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putInteger(obj.getRequestType());
    }
    readSNGJPWinner(inStream: TSDataInputStream, obj: SNGJPWinner): void {
        obj.setFeedId(inStream.getInteger());
        obj.setScreenName(inStream.getString());
        obj.setWinningAmount(inStream.getLong());
        obj.setWinningType(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setBuyIn(inStream.getInteger());
        obj.setMultiplier(inStream.getInteger());
        obj.setAwardTime(inStream.getLong());
        obj.setCardPropertiesId(inStream.getInteger());
    }

    writeSNGJPWinner(outStream: TSDataOutputStream, obj: SNGJPWinner): void {
        outStream.putInteger(obj.getFeedId());
        outStream.putString(obj.getScreenName());
        outStream.putLong(obj.getWinningAmount());
        outStream.putInteger(obj.getWinningType());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getMultiplier());
        outStream.putLong(obj.getAwardTime());
        outStream.putInteger(obj.getCardPropertiesId());
    }
    readResponseSNGJPFeed(inStream: TSDataInputStream, obj: ResponseSNGJPFeed): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getInteger());
        obj.setDeletedFeedIds(inStream.getIntArray());
        obj.setSngJPWinners(inStream.getObjectArray());
        obj.setIsFullUpdate(inStream.getBoolean());
        obj.setRequestType(inStream.getInteger());
    }

    writeResponseSNGJPFeed(outStream: TSDataOutputStream, obj: ResponseSNGJPFeed): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putInteger(obj.getDomain());
        outStream.putIntArray(obj.getDeletedFeedIds());
        outStream.putObjectArray(obj.getSngJPWinners());
        outStream.putBoolean(obj.getIsFullUpdate());
        outStream.putInteger(obj.getRequestType());
    }
    readResponseSNGJPFeedDelta(inStream: TSDataInputStream, obj: ResponseSNGJPFeedDelta): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setDomain(inStream.getInteger());
        obj.setDeltaEvents(inStream.getObjectArray());
        obj.setRequestType(inStream.getInteger());
    }

    writeResponseSNGJPFeedDelta(outStream: TSDataOutputStream, obj: ResponseSNGJPFeedDelta): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putInteger(obj.getDomain());
        outStream.putObjectArray(obj.getDeltaEvents());
        outStream.putInteger(obj.getRequestType());
    }
    readRequestSNGJPLobbyData(inStream: TSDataInputStream, obj: RequestSNGJPLobbyData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTournamentIDs(inStream.getIntArray());
        obj.setTourneyRequestType(inStream.getByte());
    }

    writeRequestSNGJPLobbyData(outStream: TSDataOutputStream, obj: RequestSNGJPLobbyData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putIntArray(obj.getTournamentIDs());
        outStream.putByte(obj.getTourneyRequestType());
    }
    readResponseSNGJPLobbyData(inStream: TSDataInputStream, obj: ResponseSNGJPLobbyData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setSngJPInstanceDetails(inStream.getObjectArray());
        obj.setDynaDetails(inStream.getObject());
        obj.setTourneyRequestType(inStream.getByte());
    }

    writeResponseSNGJPLobbyData(outStream: TSDataOutputStream, obj: ResponseSNGJPLobbyData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getSngJPInstanceDetails());
        outStream.putObject(obj.getDynaDetails());
        outStream.putByte(obj.getTourneyRequestType());
    }
    readSNGJPWinnerDeleteEvent(inStream: TSDataInputStream, obj: SNGJPWinnerDeleteEvent): void {
        obj.setFeedID(inStream.getInteger());
    }

    writeSNGJPWinnerDeleteEvent(outStream: TSDataOutputStream, obj: SNGJPWinnerDeleteEvent): void {
        outStream.putInteger(obj.getFeedID());
    }
    readRequestFFDataOnClick(inStream: TSDataInputStream, obj: RequestFFDataOnClick): void {
        obj.setMsgNumber(inStream.getInteger());
    }

    writeRequestFFDataOnClick(outStream: TSDataOutputStream, obj: RequestFFDataOnClick): void {
        outStream.putInteger(obj.getMsgNumber());
    }
    readResponseFFDataOnClick(inStream: TSDataInputStream, obj: ResponseFFDataOnClick): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setAllCardsMap(inStream.getInt2ObjectMap());
    }

    writeResponseFFDataOnClick(outStream: TSDataOutputStream, obj: ResponseFFDataOnClick): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2ObjectMap(obj.getAllCardsMap());
    }
    readPromoSNGTemplate(inStream: TSDataInputStream, obj: PromoSNGTemplate): void {
        obj.setSngName(inStream.getStringEx());
        obj.setBuyIn(inStream.getInteger());
        obj.setTourneyFee(inStream.getInteger());
        obj.setMaxSeats(inStream.getInteger());
        obj.setGameCurrency(inStream.getString());
        obj.setTemplateId(inStream.getInteger());
        obj.setSngTemplateOrderNumber(inStream.getInteger());
    }

    writePromoSNGTemplate(outStream: TSDataOutputStream, obj: PromoSNGTemplate): void {
        outStream.putStringEx(obj.getSngName());
        outStream.putInteger(obj.getBuyIn());
        outStream.putInteger(obj.getTourneyFee());
        outStream.putInteger(obj.getMaxSeats());
        outStream.putString(obj.getGameCurrency());
        outStream.putInteger(obj.getTemplateId());
        outStream.putInteger(obj.getSngTemplateOrderNumber());
    }
    readRequestSNGPromoUpdate(inStream: TSDataInputStream, obj: RequestSNGPromoUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
    }

    writeRequestSNGPromoUpdate(outStream: TSDataOutputStream, obj: RequestSNGPromoUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
    }
    readResponseSNGPromoUpdate(inStream: TSDataInputStream, obj: ResponseSNGPromoUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setPromoSNGTemplates(inStream.getObjectArray());
        obj.setDeletedSNGTemplateIds(inStream.getIntArray());
        obj.setPromoName(inStream.getStringEx());
        obj.setPromotionId(inStream.getInteger());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
        obj.setIsFullUpdate(inStream.getBoolean());
    }

    writeResponseSNGPromoUpdate(outStream: TSDataOutputStream, obj: ResponseSNGPromoUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getPromoSNGTemplates());
        outStream.putIntArray(obj.getDeletedSNGTemplateIds());
        outStream.putStringEx(obj.getPromoName());
        outStream.putInteger(obj.getPromotionId());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
        outStream.putBoolean(obj.getIsFullUpdate());
    }
    readSNGPromoDeleteEvent(inStream: TSDataInputStream, obj: SNGPromoDeleteEvent): void {
        obj.setPromotionId(inStream.getInteger());
    }

    writeSNGPromoDeleteEvent(outStream: TSDataOutputStream, obj: SNGPromoDeleteEvent): void {
        outStream.putInteger(obj.getPromotionId());
    }
    readSNGPromoSNGTemplateDeleteEvent(inStream: TSDataInputStream, obj: SNGPromoSNGTemplateDeleteEvent): void {
        obj.setPromotionId(inStream.getInteger());
        obj.setSngTemplateId(inStream.getInteger());
    }

    writeSNGPromoSNGTemplateDeleteEvent(outStream: TSDataOutputStream, obj: SNGPromoSNGTemplateDeleteEvent): void {
        outStream.putInteger(obj.getPromotionId());
        outStream.putInteger(obj.getSngTemplateId());
    }
    readResponseSNGPromoDeltaChanges(inStream: TSDataInputStream, obj: ResponseSNGPromoDeltaChanges): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setDeltaEvents(inStream.getObjectArray());
        obj.setLastSnapShotTimeInNanos(inStream.getLong());
    }

    writeResponseSNGPromoDeltaChanges(outStream: TSDataOutputStream, obj: ResponseSNGPromoDeltaChanges): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putObjectArray(obj.getDeltaEvents());
        outStream.putLong(obj.getLastSnapShotTimeInNanos());
    }
    readSNGJPInstanceWatchers(inStream: TSDataInputStream, obj: SNGJPInstanceWatchers): void {
        obj.setObserverCardId(inStream.getInteger());
        obj.setTournamentID(inStream.getInteger());
        obj.setWatchersCount(inStream.getInteger());
    }

    writeSNGJPInstanceWatchers(outStream: TSDataOutputStream, obj: SNGJPInstanceWatchers): void {
        outStream.putInteger(obj.getObserverCardId());
        outStream.putInteger(obj.getTournamentID());
        outStream.putInteger(obj.getWatchersCount());
    }
    readLSMultiDayWiseData(inStream: TSDataInputStream, obj: LSMultiDayWiseData): void {
        obj.setPauseType(inStream.getInteger());
        obj.setPauseValue(inStream.getInteger());
        obj.setResumeTime(inStream.getTimestamp());
        obj.setResumeLevel(inStream.getInteger());
        obj.setDisplayType(inStream.getInteger());
    }

    writeLSMultiDayWiseData(outStream: TSDataOutputStream, obj: LSMultiDayWiseData): void {
        outStream.putInteger(obj.getPauseType());
        outStream.putInteger(obj.getPauseValue());
        outStream.putTimestamp(obj.getResumeTime());
        outStream.putInteger(obj.getResumeLevel());
        outStream.putInteger(obj.getDisplayType());
    }
    readLSMultiDayData(inStream: TSDataInputStream, obj: LSMultiDayData): void {
        obj.setNoOfDays(inStream.getInteger());
        obj.setCurrentDay(inStream.getInteger());
        obj.setPrevDaysRunTime(inStream.getInteger());
        obj.setCurrentDayStartTime(inStream.getTimestamp());
        obj.setDays(inStream.getObjectArray());
    }

    writeLSMultiDayData(outStream: TSDataOutputStream, obj: LSMultiDayData): void {
        outStream.putInteger(obj.getNoOfDays());
        outStream.putInteger(obj.getCurrentDay());
        outStream.putInteger(obj.getPrevDaysRunTime());
        outStream.putTimestamp(obj.getCurrentDayStartTime());
        outStream.putObjectArray(obj.getDays());
    }
    readLSMultiDayChangeData(inStream: TSDataInputStream, obj: LSMultiDayChangeData): void {
        obj.setCurrentDay(inStream.getInteger());
        obj.setPrevDaysRunTime(inStream.getInteger());
        obj.setCurrentDayStartTime(inStream.getTimestamp());
        obj.setNextDay(inStream.getInteger());
        obj.setNextDayResumeLevel(inStream.getInteger());
    }

    writeLSMultiDayChangeData(outStream: TSDataOutputStream, obj: LSMultiDayChangeData): void {
        outStream.putInteger(obj.getCurrentDay());
        outStream.putInteger(obj.getPrevDaysRunTime());
        outStream.putTimestamp(obj.getCurrentDayStartTime());
        outStream.putInteger(obj.getNextDay());
        outStream.putInteger(obj.getNextDayResumeLevel());
    }
    readLSFlightedNextDayDetails(inStream: TSDataInputStream, obj: LSFlightedNextDayDetails): void {
        obj.setMtctId(inStream.getInteger());
        obj.setStartTime(inStream.getTimestamp());
    }

    writeLSFlightedNextDayDetails(outStream: TSDataOutputStream, obj: LSFlightedNextDayDetails): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putTimestamp(obj.getStartTime());
    }
    readLSFlightedData(inStream: TSDataInputStream, obj: LSFlightedData): void {
        obj.setPauseType(inStream.getInteger());
        obj.setPauseValue(inStream.getInteger());
        obj.setNextDayMTT(inStream.getObject());
        obj.setDefaultChips(inStream.getLong());
        obj.setFlightedBuyinOption(inStream.getInteger());
        obj.setFlightedDisplayId(inStream.getInteger());
    }

    writeLSFlightedData(outStream: TSDataOutputStream, obj: LSFlightedData): void {
        outStream.putInteger(obj.getPauseType());
        outStream.putInteger(obj.getPauseValue());
        outStream.putObject(obj.getNextDayMTT());
        outStream.putLong(obj.getDefaultChips());
        outStream.putInteger(obj.getFlightedBuyinOption());
        outStream.putInteger(obj.getFlightedDisplayId());
    }
    readRequestFeatureGamesLobbyUpdate(inStream: TSDataInputStream, obj: RequestFeatureGamesLobbyUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActive(inStream.getBoolean());
        obj.setTabSnapshotTimeInNanosList(inStream.getObjectArray());
        obj.setTableInfoSnapShotTimeInNanos(inStream.getLong());
    }

    writeRequestFeatureGamesLobbyUpdate(outStream: TSDataOutputStream, obj: RequestFeatureGamesLobbyUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getActive());
        outStream.putObjectArray(obj.getTabSnapshotTimeInNanosList());
        outStream.putLong(obj.getTableInfoSnapShotTimeInNanos());
    }
    readResponseFeatureGamesLobbyUpdate(inStream: TSDataInputStream, obj: ResponseFeatureGamesLobbyUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabIdTableDetailsMap(inStream.getInt2ObjectMap());
        obj.setTableInfos(inStream.getObjectArray());
        obj.setDeletedTableInfos(inStream.getIntArray());
    }

    writeResponseFeatureGamesLobbyUpdate(outStream: TSDataOutputStream, obj: ResponseFeatureGamesLobbyUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2ObjectMap(obj.getTabIdTableDetailsMap());
        outStream.putObjectArray(obj.getTableInfos());
        outStream.putIntArray(obj.getDeletedTableInfos());
    }
    readLSFeaturedTableDetailsDeltaUpdate(inStream: TSDataInputStream, obj: LSFeaturedTableDetailsDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabId(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSFeaturedTableDetailsDeltaUpdate(outStream: TSDataOutputStream, obj: LSFeaturedTableDetailsDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTabId());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readLSMixMaxEntry(inStream: TSDataInputStream, obj: LSMixMaxEntry): void {
        obj.setStartRange(inStream.getInteger());
        obj.setEndRange(inStream.getInteger());
        obj.setValue(inStream.getInteger());
    }

    writeLSMixMaxEntry(outStream: TSDataOutputStream, obj: LSMixMaxEntry): void {
        outStream.putInteger(obj.getStartRange());
        outStream.putInteger(obj.getEndRange());
        outStream.putInteger(obj.getValue());
    }
    readLSMixMaxData(inStream: TSDataInputStream, obj: LSMixMaxData): void {
        obj.setMixmaxType(inStream.getInteger());
        obj.setMixmaxEntries(inStream.getObjectArray());
    }

    writeLSMixMaxData(outStream: TSDataOutputStream, obj: LSMixMaxData): void {
        outStream.putInteger(obj.getMixmaxType());
        outStream.putObjectArray(obj.getMixmaxEntries());
    }
    readLSHeadsUpParticipantStatus(inStream: TSDataInputStream, obj: LSHeadsUpParticipantStatus): void {
        obj.setParticipantNo(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
    }

    writeLSHeadsUpParticipantStatus(outStream: TSDataOutputStream, obj: LSHeadsUpParticipantStatus): void {
        outStream.putInteger(obj.getParticipantNo());
        outStream.putInteger(obj.getStatus());
    }
    readRequestMTCTLobbyPlayersUnSubscribe(inStream: TSDataInputStream, obj: RequestMTCTLobbyPlayersUnSubscribe): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestMTCTLobbyPlayersUnSubscribe(outStream: TSDataOutputStream, obj: RequestMTCTLobbyPlayersUnSubscribe): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestMTCTLobbyTablesUnSubscribe(inStream: TSDataInputStream, obj: RequestMTCTLobbyTablesUnSubscribe): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestMTCTLobbyTablesUnSubscribe(outStream: TSDataOutputStream, obj: RequestMTCTLobbyTablesUnSubscribe): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestMTCTLobbyPlayerTablesUnSubscribe(inStream: TSDataInputStream, obj: RequestMTCTLobbyPlayerTablesUnSubscribe): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestMTCTLobbyPlayerTablesUnSubscribe(outStream: TSDataOutputStream, obj: RequestMTCTLobbyPlayerTablesUnSubscribe): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestPlayerTrackInfo(inStream: TSDataInputStream, obj: RequestPlayerTrackInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestPlayerTrackInfo(outStream: TSDataOutputStream, obj: RequestPlayerTrackInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestUnSubscribePlayerTrackInfo(inStream: TSDataInputStream, obj: RequestUnSubscribePlayerTrackInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestUnSubscribePlayerTrackInfo(outStream: TSDataOutputStream, obj: RequestUnSubscribePlayerTrackInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readResponsePlayerTrackInfo(inStream: TSDataInputStream, obj: ResponsePlayerTrackInfo): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getLong());
        obj.setChipsInPlay(inStream.getLong());
        obj.setPlayerCurrentPosition(inStream.getInteger());
    }

    writeResponsePlayerTrackInfo(outStream: TSDataOutputStream, obj: ResponsePlayerTrackInfo): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putLong(obj.getMtctId());
        outStream.putLong(obj.getChipsInPlay());
        outStream.putInteger(obj.getPlayerCurrentPosition());
    }
    readLSMTCTUpdateChanges(inStream: TSDataInputStream, obj: LSMTCTUpdateChanges): void {
        obj.setMtctId(inStream.getInteger());
        obj.setCurrentLevel(inStream.getInteger());
        obj.setBuyInInfo(inStream.getStringEx());
        obj.setPartyPoints(inStream.getInteger());
        obj.setAllowedCountryCodes(inStream.getString());
        obj.setFirstRankPlayerName(inStream.getString());
        obj.setTourneyEndTime(inStream.getTimestamp());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setPlayerCount(inStream.getInteger());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setNoOfParticipants(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setUnregClosed(inStream.getBoolean());
        obj.setMultiDayChangeData(inStream.getObject());
        obj.setEvents(inStream.getIntArray());
        obj.setQualifiers(inStream.getIntArray());
        obj.setTourneyStartTime(inStream.getTimestamp());
        obj.setMaxSeats(inStream.getInteger());
    }

    writeLSMTCTUpdateChanges(outStream: TSDataOutputStream, obj: LSMTCTUpdateChanges): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getCurrentLevel());
        outStream.putStringEx(obj.getBuyInInfo());
        outStream.putInteger(obj.getPartyPoints());
        outStream.putString(obj.getAllowedCountryCodes());
        outStream.putString(obj.getFirstRankPlayerName());
        outStream.putTimestamp(obj.getTourneyEndTime());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putInteger(obj.getNoOfParticipants());
        outStream.putInteger(obj.getStatus());
        outStream.putBoolean(obj.getUnregClosed());
        outStream.putObject(obj.getMultiDayChangeData());
        outStream.putIntArray(obj.getEvents());
        outStream.putIntArray(obj.getQualifiers());
        outStream.putTimestamp(obj.getTourneyStartTime());
        outStream.putInteger(obj.getMaxSeats());
    }
    readLSMTCTUpdateMinChanges(inStream: TSDataInputStream, obj: LSMTCTUpdateMinChanges): void {
        obj.setMtctId(inStream.getInteger());
        obj.setCurrentLevel(inStream.getInteger());
        obj.setLateRegInProgress(inStream.getBoolean());
        obj.setPlayerCount(inStream.getInteger());
        obj.setTotalPrizePool(inStream.getLong());
        obj.setNoOfParticipants(inStream.getInteger());
        obj.setStatus(inStream.getInteger());
        obj.setEvents(inStream.getIntArray());
        obj.setQualifiers(inStream.getIntArray());
        obj.setTourneyStartTime(inStream.getTimestamp());
        obj.setMaxSeats(inStream.getInteger());
    }

    writeLSMTCTUpdateMinChanges(outStream: TSDataOutputStream, obj: LSMTCTUpdateMinChanges): void {
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getCurrentLevel());
        outStream.putBoolean(obj.getLateRegInProgress());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putLong(obj.getTotalPrizePool());
        outStream.putInteger(obj.getNoOfParticipants());
        outStream.putInteger(obj.getStatus());
        outStream.putIntArray(obj.getEvents());
        outStream.putIntArray(obj.getQualifiers());
        outStream.putTimestamp(obj.getTourneyStartTime());
        outStream.putInteger(obj.getMaxSeats());
    }
    readRequestTourneySearchMTCTParticipantNames(inStream: TSDataInputStream, obj: RequestTourneySearchMTCTParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setSearchString(inStream.getString());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestTourneySearchMTCTParticipantNames(outStream: TSDataOutputStream, obj: RequestTourneySearchMTCTParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putString(obj.getSearchString());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readRequestLSTourneyParticipantNames(inStream: TSDataInputStream, obj: RequestLSTourneyParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipantNos(inStream.getIntArray());
        obj.setTournamentCategory(inStream.getInteger());
    }

    writeRequestLSTourneyParticipantNames(outStream: TSDataOutputStream, obj: RequestLSTourneyParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putIntArray(obj.getParticipantNos());
        outStream.putInteger(obj.getTournamentCategory());
    }
    readResponseLSTourneyParticipantNames(inStream: TSDataInputStream, obj: ResponseLSTourneyParticipantNames): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setParticipantNames(inStream.getInt2StringMap());
    }

    writeResponseLSTourneyParticipantNames(outStream: TSDataOutputStream, obj: ResponseLSTourneyParticipantNames): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInt2StringMap(obj.getParticipantNames());
    }
    readMTCTFontDetails(inStream: TSDataInputStream, obj: MTCTFontDetails): void {
        obj.setFontId(inStream.getShort());
        obj.setPrefixFontId(inStream.getShort());
        obj.setSuffixFontId(inStream.getShort());
    }

    writeMTCTFontDetails(outStream: TSDataOutputStream, obj: MTCTFontDetails): void {
        outStream.putShort(obj.getFontId());
        outStream.putShort(obj.getPrefixFontId());
        outStream.putShort(obj.getSuffixFontId());
    }
    readRequestSimilarMTCT(inStream: TSDataInputStream, obj: RequestSimilarMTCT): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTemplateId(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
    }

    writeRequestSimilarMTCT(outStream: TSDataOutputStream, obj: RequestSimilarMTCT): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTemplateId());
        outStream.putInteger(obj.getMtctId());
    }
    readResponseSimilarMTCT(inStream: TSDataInputStream, obj: ResponseSimilarMTCT): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
    }

    writeResponseSimilarMTCT(outStream: TSDataOutputStream, obj: ResponseSimilarMTCT): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
    }
    readLSReentryInfo(inStream: TSDataInputStream, obj: LSReentryInfo): void {
        obj.setReentryBuyIn(inStream.getInteger());
        obj.setReentryFee(inStream.getInteger());
        obj.setReentryChips(inStream.getLong());
    }

    writeLSReentryInfo(outStream: TSDataOutputStream, obj: LSReentryInfo): void {
        outStream.putInteger(obj.getReentryBuyIn());
        outStream.putInteger(obj.getReentryFee());
        outStream.putLong(obj.getReentryChips());
    }
    readLSTableInfoUpdate(inStream: TSDataInputStream, obj: LSTableInfoUpdate): void {
        obj.setTableInfoId(inStream.getInteger());
        obj.setPlayerCount(inStream.getInteger());
        obj.setTableCount(inStream.getInteger());
    }

    writeLSTableInfoUpdate(outStream: TSDataOutputStream, obj: LSTableInfoUpdate): void {
        outStream.putInteger(obj.getTableInfoId());
        outStream.putInteger(obj.getPlayerCount());
        outStream.putInteger(obj.getTableCount());
    }
    readLSTabIdTableInfoDetails(inStream: TSDataInputStream, obj: LSTabIdTableInfoDetails): void {
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setFullUpdate(inStream.getBoolean());
        obj.setTableInfos(inStream.getObjectArray());
        obj.setDeletedTableInfos(inStream.getIntArray());
        obj.setUpdatedTableInfos(inStream.getObjectArray());
    }

    writeLSTabIdTableInfoDetails(outStream: TSDataOutputStream, obj: LSTabIdTableInfoDetails): void {
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putBoolean(obj.getFullUpdate());
        outStream.putObjectArray(obj.getTableInfos());
        outStream.putIntArray(obj.getDeletedTableInfos());
        outStream.putObjectArray(obj.getUpdatedTableInfos());
    }
    readRequestGroupLobbyUpdate(inStream: TSDataInputStream, obj: RequestGroupLobbyUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setActive(inStream.getBoolean());
        obj.setTabSnapshotTimeInNanosList(inStream.getObjectArray());
        obj.setRequestType(inStream.getInteger());
        obj.setFfTabSnapShotList(inStream.getObjectArray());
    }

    writeRequestGroupLobbyUpdate(outStream: TSDataOutputStream, obj: RequestGroupLobbyUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putBoolean(obj.getActive());
        outStream.putObjectArray(obj.getTabSnapshotTimeInNanosList());
        outStream.putInteger(obj.getRequestType());
        outStream.putObjectArray(obj.getFfTabSnapShotList());
    }
    readResponseGroupCashDetailsList(inStream: TSDataInputStream, obj: ResponseGroupCashDetailsList): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabIdTableInfoDetailsMap(inStream.getInt2ObjectMap());
        obj.setClearAllTabIdSnapShots(inStream.getBoolean());
        obj.setTabIdTableDetailsMap(inStream.getInt2ObjectMap());
    }

    writeResponseGroupCashDetailsList(outStream: TSDataOutputStream, obj: ResponseGroupCashDetailsList): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2ObjectMap(obj.getTabIdTableInfoDetailsMap());
        outStream.putBoolean(obj.getClearAllTabIdSnapShots());
        outStream.putInt2ObjectMap(obj.getTabIdTableDetailsMap());
    }
    readRequestSatellitesData(inStream: TSDataInputStream, obj: RequestSatellitesData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setSatelliteMTTCTIds(inStream.getIntArray());
    }

    writeRequestSatellitesData(outStream: TSDataOutputStream, obj: RequestSatellitesData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putIntArray(obj.getSatelliteMTTCTIds());
    }
    readResponseSatellitesData(inStream: TSDataInputStream, obj: ResponseSatellitesData): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setSatelliteMTTCTDetails(inStream.getObjectArray());
        obj.setLiteralsMap(inStream.getInt2StringMap());
    }

    writeResponseSatellitesData(outStream: TSDataOutputStream, obj: ResponseSatellitesData): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putObjectArray(obj.getSatelliteMTTCTDetails());
        outStream.putInt2StringMap(obj.getLiteralsMap());
    }
    readLSTourneyTimeToAct(inStream: TSDataInputStream, obj: LSTourneyTimeToAct): void {
        obj.setPreFlopStandardTime(inStream.getInteger());
        obj.setFlopStandardTime(inStream.getInteger());
    }

    writeLSTourneyTimeToAct(outStream: TSDataOutputStream, obj: LSTourneyTimeToAct): void {
        outStream.putInteger(obj.getPreFlopStandardTime());
        outStream.putInteger(obj.getFlopStandardTime());
    }
    readLSTourneyTimeBank(inStream: TSDataInputStream, obj: LSTourneyTimeBank): void {
        obj.setInitialTimeBankInSeconds(inStream.getInteger());
        obj.setReplenishTimeInSeconds(inStream.getInteger());
        obj.setRateOfReplenishTimeInMInutes(inStream.getInteger());
        obj.setTimeBankCapInSeconds(inStream.getInteger());
    }

    writeLSTourneyTimeBank(outStream: TSDataOutputStream, obj: LSTourneyTimeBank): void {
        outStream.putInteger(obj.getInitialTimeBankInSeconds());
        outStream.putInteger(obj.getReplenishTimeInSeconds());
        outStream.putInteger(obj.getRateOfReplenishTimeInMInutes());
        outStream.putInteger(obj.getTimeBankCapInSeconds());
    }
    readResponseLSDynaDeltaChanges(inStream: TSDataInputStream, obj: ResponseLSDynaDeltaChanges): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLiteralsMap(inStream.getInt2StringMap());
    }

    writeResponseLSDynaDeltaChanges(outStream: TSDataOutputStream, obj: ResponseLSDynaDeltaChanges): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInt2StringMap(obj.getLiteralsMap());
    }
    readRequestLSLobbyDyna(inStream: TSDataInputStream, obj: RequestLSLobbyDyna): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTemplateId(inStream.getInteger());
        obj.setTournyType(inStream.getInteger());
    }

    writeRequestLSLobbyDyna(outStream: TSDataOutputStream, obj: RequestLSLobbyDyna): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTemplateId());
        outStream.putInteger(obj.getTournyType());
    }
    readLSTabIdTableInfoDeltaUpdate(inStream: TSDataInputStream, obj: LSTabIdTableInfoDeltaUpdate): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setTabId(inStream.getInteger());
        obj.setSnapShotTimeInNanos(inStream.getLong());
        obj.setDeltaEvents(inStream.getObjectArray());
    }

    writeLSTabIdTableInfoDeltaUpdate(outStream: TSDataOutputStream, obj: LSTabIdTableInfoDeltaUpdate): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getTabId());
        outStream.putLong(obj.getSnapShotTimeInNanos());
        outStream.putObjectArray(obj.getDeltaEvents());
    }
    readPLSAuthenticationRequest(inStream: TSDataInputStream, obj: PLSAuthenticationRequest): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginId(inStream.getString());
        obj.setGtid(inStream.getString());
    }

    writePLSAuthenticationRequest(outStream: TSDataOutputStream, obj: PLSAuthenticationRequest): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLoginId());
        outStream.putString(obj.getGtid());
    }
    readPLSAuthenticationResponse(inStream: TSDataInputStream, obj: PLSAuthenticationResponse): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setLoginId(inStream.getString());
        obj.setIsAuthSuccess(inStream.getBoolean());
    }

    writePLSAuthenticationResponse(outStream: TSDataOutputStream, obj: PLSAuthenticationResponse): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putString(obj.getLoginId());
        outStream.putBoolean(obj.getIsAuthSuccess());
    }
    readRequestPxTablesGridOnPageNumber(inStream: TSDataInputStream, obj: RequestPxTablesGridOnPageNumber): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPageNumber(inStream.getInteger());
        obj.setKeepPlayerSubscription(inStream.getBoolean());
    }

    writeRequestPxTablesGridOnPageNumber(outStream: TSDataOutputStream, obj: RequestPxTablesGridOnPageNumber): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPageNumber());
        outStream.putBoolean(obj.getKeepPlayerSubscription());
    }
    readRequestPxPlayersGridOnPageNumber(inStream: TSDataInputStream, obj: RequestPxPlayersGridOnPageNumber): void {
        obj.setMsgNumber(inStream.getInteger());
        obj.setMtctId(inStream.getInteger());
        obj.setPageNumber(inStream.getInteger());
        obj.setKeepTableSubscription(inStream.getBoolean());
    }

    writeRequestPxPlayersGridOnPageNumber(outStream: TSDataOutputStream, obj: RequestPxPlayersGridOnPageNumber): void {
        outStream.putInteger(obj.getMsgNumber());
        outStream.putInteger(obj.getMtctId());
        outStream.putInteger(obj.getPageNumber());
        outStream.putBoolean(obj.getKeepTableSubscription());
    }
}
