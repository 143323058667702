import { Message } from '../../message';

export class DepositedFriendCount extends Message {
    private MESSAGE_NAME: string = 'DepositedFriendCount';
    private referredCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, referredCount: number = 0) {
        super(reqServerPeerId);
        this.referredCount = referredCount;
    }

    getReferredCount(): number {
        return this.referredCount;
    }

    setReferredCount(referredCount: number) {
        this.referredCount = referredCount;
    }
}
