import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class PostAnte extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.POST_ANTE;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.POST_ANTE;
    }
    next(gameTable: GameTable): void {
        gameTable.currentBettingRound = 1;
        const player = gameTable.getPlayer(this.seat);
        gameTable.addToPot(this.amount);
        player.addToPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
        if (player.chips.value <= 0) {
            player.isPlayerAllIn = true;
        }
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        gameTable.substractFromPot(this.amount);
        player.subtractFromPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
        if (player.chips.value >= 0) {
            player.isPlayerAllIn = false;
        }
    }
}
