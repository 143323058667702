import { Message } from '../../message';

export class BonusInfo extends Message {
    private MESSAGE_NAME: string = 'BonusInfo';
    private bonusOfferUrl: string | null = null;
    private bonusOffer1: string | null = null;
    private bonusOffer2: string | null = null;
    private bonusOffer3: string | null = null;
    private bonusOffer4: string | null = null;
    private abCurrBonus: string | null = null;
    private abTotalBonus: string | null = null;
    private capBonusPercent: string | null = null;
    private capMaxBonus: string | null = null;
    private capAccCurrType: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        bonusOfferUrl: string | null = null,
        bonusOffer1: string | null = null,
        bonusOffer2: string | null = null,
        bonusOffer3: string | null = null,
        bonusOffer4: string | null = null,
        abCurrBonus: string | null = null,
        abTotalBonus: string | null = null,
        capBonusPercent: string | null = null,
        capMaxBonus: string | null = null,
        capAccCurrType: string | null = null,
    ) {
        super(reqServerPeerId);
        this.bonusOfferUrl = bonusOfferUrl;
        this.bonusOffer1 = bonusOffer1;
        this.bonusOffer2 = bonusOffer2;
        this.bonusOffer3 = bonusOffer3;
        this.bonusOffer4 = bonusOffer4;
        this.abCurrBonus = abCurrBonus;
        this.abTotalBonus = abTotalBonus;
        this.capBonusPercent = capBonusPercent;
        this.capMaxBonus = capMaxBonus;
        this.capAccCurrType = capAccCurrType;
    }

    getBonusOfferUrl(): string | null {
        return this.bonusOfferUrl;
    }

    setBonusOfferUrl(bonusOfferUrl: string | null) {
        this.bonusOfferUrl = bonusOfferUrl;
    }
    getBonusOffer1(): string | null {
        return this.bonusOffer1;
    }

    setBonusOffer1(bonusOffer1: string | null) {
        this.bonusOffer1 = bonusOffer1;
    }
    getBonusOffer2(): string | null {
        return this.bonusOffer2;
    }

    setBonusOffer2(bonusOffer2: string | null) {
        this.bonusOffer2 = bonusOffer2;
    }
    getBonusOffer3(): string | null {
        return this.bonusOffer3;
    }

    setBonusOffer3(bonusOffer3: string | null) {
        this.bonusOffer3 = bonusOffer3;
    }
    getBonusOffer4(): string | null {
        return this.bonusOffer4;
    }

    setBonusOffer4(bonusOffer4: string | null) {
        this.bonusOffer4 = bonusOffer4;
    }
    getAbCurrBonus(): string | null {
        return this.abCurrBonus;
    }

    setAbCurrBonus(abCurrBonus: string | null) {
        this.abCurrBonus = abCurrBonus;
    }
    getAbTotalBonus(): string | null {
        return this.abTotalBonus;
    }

    setAbTotalBonus(abTotalBonus: string | null) {
        this.abTotalBonus = abTotalBonus;
    }
    getCapBonusPercent(): string | null {
        return this.capBonusPercent;
    }

    setCapBonusPercent(capBonusPercent: string | null) {
        this.capBonusPercent = capBonusPercent;
    }
    getCapMaxBonus(): string | null {
        return this.capMaxBonus;
    }

    setCapMaxBonus(capMaxBonus: string | null) {
        this.capMaxBonus = capMaxBonus;
    }
    getCapAccCurrType(): string | null {
        return this.capAccCurrType;
    }

    setCapAccCurrType(capAccCurrType: string | null) {
        this.capAccCurrType = capAccCurrType;
    }
}
