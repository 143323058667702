import { Message } from '../../message';

export class PvtTableType extends Message {
    private MESSAGE_NAME: string = 'PvtTableType';
    private gameTypeId: number = 0;
    private stakesLowerLimit: number = 0;
    private tableInfoId: number = 0;
    private maxBuyin: number = 0;
    private limitType: number = 0;
    private maxSeats: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        gameTypeId: number = 0,
        stakesLowerLimit: number = 0,
        tableInfoId: number = 0,
        maxBuyin: number = 0,
        limitType: number = 0,
        maxSeats: number = 0,
    ) {
        super(reqServerPeerId);
        this.gameTypeId = gameTypeId;
        this.stakesLowerLimit = stakesLowerLimit;
        this.tableInfoId = tableInfoId;
        this.maxBuyin = maxBuyin;
        this.limitType = limitType;
        this.maxSeats = maxSeats;
    }

    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getStakesLowerLimit(): number {
        return this.stakesLowerLimit;
    }

    setStakesLowerLimit(stakesLowerLimit: number) {
        this.stakesLowerLimit = stakesLowerLimit;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getMaxBuyin(): number {
        return this.maxBuyin;
    }

    setMaxBuyin(maxBuyin: number) {
        this.maxBuyin = maxBuyin;
    }
    getLimitType(): number {
        return this.limitType;
    }

    setLimitType(limitType: number) {
        this.limitType = limitType;
    }
    getMaxSeats(): number {
        return this.maxSeats;
    }

    setMaxSeats(maxSeats: number) {
        this.maxSeats = maxSeats;
    }
}
