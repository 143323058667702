import { Message } from '../../message';
import { FXRateDetails } from '../../pgSharedMessageFactory/messages/FXRateDetails';

export class SNGRankResultWithReplayRequest extends Message {
    private MESSAGE_NAME: string = 'SNGRankResultWithReplayRequest';
    private templateId: number = 0;
    private buyInType: number = 0;
    private buyIn: number = 0;
    private fee: number = 0;
    private tournamentName: any | null = null;
    private gameType: number = 0;
    private rankInfo: any[] | null = null;
    private rank: number = 0;
    private participantNo: number = 0;
    private mtctId: number = 0;
    private numberOfTourneyTickets: number = 0;
    private accBalanceGC: number = 0;
    private fxSnapshotID: number = 0;
    private fxDetails: FXRateDetails | null = null;
    private ticketType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        templateId: number = 0,
        buyInType: number = 0,
        buyIn: number = 0,
        fee: number = 0,
        tournamentName: any | null = null,
        gameType: number = 0,
        rankInfo: any[] | null = null,
        rank: number = 0,
        participantNo: number = 0,
        mtctId: number = 0,
        numberOfTourneyTickets: number = 0,
        accBalanceGC: number = 0,
        fxSnapshotID: number = 0,
        fxDetails: FXRateDetails | null = null,
        ticketType: number = 0,
    ) {
        super(reqServerPeerId);
        this.templateId = templateId;
        this.buyInType = buyInType;
        this.buyIn = buyIn;
        this.fee = fee;
        this.tournamentName = tournamentName;
        this.gameType = gameType;
        this.rankInfo = rankInfo;
        this.rank = rank;
        this.participantNo = participantNo;
        this.mtctId = mtctId;
        this.numberOfTourneyTickets = numberOfTourneyTickets;
        this.accBalanceGC = accBalanceGC;
        this.fxSnapshotID = fxSnapshotID;
        this.fxDetails = fxDetails;
        this.ticketType = ticketType;
    }

    getTemplateId(): number {
        return this.templateId;
    }

    setTemplateId(templateId: number) {
        this.templateId = templateId;
    }
    getBuyInType(): number {
        return this.buyInType;
    }

    setBuyInType(buyInType: number) {
        this.buyInType = buyInType;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getFee(): number {
        return this.fee;
    }

    setFee(fee: number) {
        this.fee = fee;
    }
    getTournamentName(): any | null {
        return this.tournamentName;
    }

    setTournamentName(tournamentName: any | null) {
        this.tournamentName = tournamentName;
    }
    getGameType(): number {
        return this.gameType;
    }

    setGameType(gameType: number) {
        this.gameType = gameType;
    }
    getRankInfo(): any[] | null {
        return this.rankInfo;
    }

    setRankInfo(rankInfo: any[] | null) {
        this.rankInfo = rankInfo;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getNumberOfTourneyTickets(): number {
        return this.numberOfTourneyTickets;
    }

    setNumberOfTourneyTickets(numberOfTourneyTickets: number) {
        this.numberOfTourneyTickets = numberOfTourneyTickets;
    }
    getAccBalanceGC(): number {
        return this.accBalanceGC;
    }

    setAccBalanceGC(accBalanceGC: number) {
        this.accBalanceGC = accBalanceGC;
    }
    getFxSnapshotID(): number {
        return this.fxSnapshotID;
    }

    setFxSnapshotID(fxSnapshotID: number) {
        this.fxSnapshotID = fxSnapshotID;
    }
    getFxDetails(): FXRateDetails | null {
        return this.fxDetails;
    }

    setFxDetails(fxDetails: FXRateDetails | null) {
        this.fxDetails = fxDetails;
    }
    getTicketType(): number {
        return this.ticketType;
    }

    setTicketType(ticketType: number) {
        this.ticketType = ticketType;
    }
}
