<div
    class="cashoutPopup"
    *ngIf="player.isPlayerRequesting && gameTableService.gameTable.CashedOutPopUp && player.cashedOutAmount > 0"
    [ngClass]="{ 'cashout-max': maxSeats >= 7 }">
    {{ content?.CashoutMessage }} : &nbsp;${{ (player.cashedOutAmount / 100).toLocaleString() }}
</div>
<div
    class="player-seat-container"
    [style.left]="player.position.getXAxis()"
    [style.top]="player.position.getYAxis()"
    [class.selfPlayer]="player.isPlayerRequesting">
    <div class="player-seat">
        <div class="cashout-block">
            <div
                class="cashout-icon"
                ngClass="{{ player.transformPosition }}"
                *ngIf="gameTableService.gameTable.CashedOutPopUp && player.cashedOutAmount > 0"></div>
        </div>
        <div class="player-events" *ngIf="player.event" ngClass="{{ getStatus() }}">
            <span>{{ getStatus() }}</span>
        </div>
        <svg class="timerSvg" *ngIf="player._playerTimer">
            <circle class="meter-1" cx="50" cy="50" r="45" />
        </svg>

        <div
            *ngIf="!player.isPlayerRequesting"
            class="player_div"
            ngClass="{{ player.transformPosition }}"
            [class.optionSet]="player.OptionSet"
            [class.hideAvatar]="gameTableService.gameTable.isShowControlls">
            <img class="player_img" src="pictures/images/mobile-poker-trainer/Avatar/PlayerImage_{{ imgId }}.png" alt="Poker Trainer" />
            <div class="player-border"></div>
        </div>

        <div
            *ngIf="player.isPlayerRequesting"
            class="player_div"
            ngClass="{{ player.transformPosition }}"
            [class.optionSet]="player.OptionSet"
            [class.hideAvatar]="gameTableService.gameTable.isShowControlls">
            <img class="player_img" src="pictures/images/mobile-poker-trainer/Avatar/PlayerImage_{{ serve.selfPlyerImg }}.png" alt="Player Image" />
            <div class="player-border"></div>
        </div>

        <div class="seat" ngClass="{{ player.transformPosition }}" [class.optionSet]="player.OptionSet" [class.fold]="player.event">
            <div class="handrank" *ngIf="player.isStrengthShow">{{ serve.content[player.handStrength] }}</div>
            <div class="seat-info">
                <p class="name" [class.fold]="player.event && !serve.isMobile" *ngIf="!gameTableService.gameTable.isReplayerV3">
                    <span>{{ player.name }}</span>
                </p>
                <p class="name" [class.fold]="player.event" *ngIf="gameTableService.gameTable.isReplayerV3">
                    <span>{{ player.name }}</span>
                </p>
                <div *ngIf="!isMobile; else mobilePlayerPotHTML">
                    <p class="balance">
                        <span *ngIf="!player.isPlayerAllIn">
                            <span *ngIf="!player.isTourny">{{
                                (player.chips.value / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}</span>
                            <span *ngIf="player.isTourny">{{ (player.chips.value / 100).toLocaleString() }}</span>
                        </span>
                        <span class="all-in-flag" *ngIf="player.isPlayerAllIn"> {{ allInText }}</span>
                    </p>
                </div>
                <ng-template #mobilePlayerPotHTML>
                    <p class="balance" [hidden]="formatChange()">
                        <span *ngIf="!player.isPlayerAllIn">
                            <span *ngIf="!player.isTourny">{{ amount }}</span>
                            <span *ngIf="player.isTourny">{{ amount }}</span>
                        </span>
                        <span *ngIf="player.isPlayerAllIn"> {{ allInText }}</span>
                    </p>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<div
    class="hold-cards"
    [class.selfPlayer]="player.isPlayerRequesting"
    ngClass="{{ player.transformPosition }}"
    [class.displayCards]="!player.displayCards"
    [class.requestedPlayerFolded]="player.isSelfPlayerfolded"
    [class.closedCards]="!player.isPlayerCardsOpened"
    [class.sevenCards]="player.sevenCardStud"
    [class.omaha]="player.Omahagame"
    [style.left]="getxaxis()">
    <pkr-card
        [card]="card"
        [index]="i"
        [seq]="getHoleCardsSequence(i)"
        *ngFor="let card of player.cards; let i = index"
        [topValue]="player.position.getYAxis()"
        [leftValue]="player.position.getXAxis()"></pkr-card>
</div>
