<pk-page-not-found *ngIf="initData.notFound"></pk-page-not-found>
<div *ngIf="initData && !initData.notFound">
    <pk-navigation-bar *ngIf="showConfigureNavigation"></pk-navigation-bar>
    <pk-navigation-bar-poker *ngIf="!showConfigureNavigation"></pk-navigation-bar-poker>

    <div id="promotions-home">
        <a class="contentWrap black" *ngIf="showConfigureNavigation" [class]="{ 'no-banner-href': !heroCTAClass }" [href]="heroCTAClass">
            <div
                [class]="pokerPromotionsHome.heroTitle && pokerPromotionsHome.heroTitle.length > 0 ? 'heroLeft col dark-bg' : 'heroCenter col'"
                class="col">
                <div *ngIf="pokerPromotionsHome.heroImage.src" class="heroImageWrap">
                    <img *ngIf="pokerPromotionsHome.heroImage.src" src="{{ pokerPromotionsHome.heroImage.src }}" alt="Promo Image" />
                </div>
                <div *ngIf="pokerPromotionsHome.heroTitle" class="heroContentWrap">
                    <h2 *ngIf="pokerPromotionsHome.heroTitle" class="promoTitle">{{ pokerPromotionsHome.heroTitle }}</h2>
                    <div *ngIf="pokerPromotionsHome.heroDescription" [innerHtml]="pokerPromotionsHome.heroDescription | trustAsHtml"></div>
                    <span *ngIf="pokerPromotionsHome.heroCTA" class="btn btn-primary">{{ pokerPromotionsHome.heroCTA }}</span>
                </div>
                <p *ngIf="pokerPromotionsHome.synopsisTerms" class="significantTerms">
                    {{ pokerPromotionsHome.synopsisTerms }}
                </p>
            </div>
        </a>

        <div class="contentWrap" *ngIf="showConfigureNavigation">
            <div *ngIf="pokerPromotionsHome.mainContent1" class="pc-text pm-widget-box pc-component col">
                <section class="text pc-txt">
                    <div [vnDynamicHtml]="pokerPromotionsHome.mainContent1"></div>
                </section>
            </div>
            <div class="col">
                <div class="p-2">
                    <section *ngIf="menuContent" class="pm-area-navigation col-lg-2 col-md-3 col-sm-4 p-0 pc-menu mb-3 mb-sm-3">
                        <div class="poker-promo-menu"></div>
                    </section>
                    <div
                        [class]="
                            menuContent !== null
                                ? 'col-lg-10 col-md-9 col-sm-8 pl-md-5 pl-sm-3 pm-area-main d-flex flex-wrap row'
                                : 'd-flex flex-wrap row'
                        "
                        class="pm-area-main d-flex flex-wrap">
                        <ng-container *ngFor="let promotion of relatedPromos; let promoIndex = index">
                            <div
                                class="p-md-2 p-sm-1 mb-2 mb-sm-0 medium-homepage-promo col-md-4 col-sm-4"
                                *ngIf="promotion.pokerPromotionsSitecoreInfo && promotion.pokerPromotionsSitecoreInfo?.synopsisImage">
                                <a [href]="getPromoLink(promotion.path)" *ngIf="promotion.path">
                                    <div class="card shadow homepage-promo-content">
                                        <img
                                            [src]="promotion.pokerPromotionsSitecoreInfo.synopsisImage.src"
                                            alt="promotion"
                                            class="card-img-top img-fluid" />
                                        <div *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle" class="card-body pc-txt">
                                            <p *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle">
                                                {{ promotion.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                            </p>
                                            <div
                                                *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"
                                                [innerHtml]="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"></div>
                                            {{ promotion.synopsisCTA }}
                                        </div>
                                        <div class="card-footer" *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTerms">
                                            <small class="text-muted">{{ promotion.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                                        </div>
                                    </div>
                                </a>

                                <a
                                    [href]="getPromoLink(promotion.pokerPromotionsSitecoreInfo.data.metadata.id.path)"
                                    *ngIf="!promotion.path && getPromoLink(promotion.pokerPromotionsSitecoreInfo.data.metadata.id.path)">
                                    <div class="card shadow homepage-promo-content">
                                        <img
                                            [src]="promotion.pokerPromotionsSitecoreInfo.synopsisImage.src"
                                            alt="promotion"
                                            class="card-img-top img-fluid" />
                                        <div *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle" class="card-body pc-txt">
                                            <p *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle">
                                                {{ promotion.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                            </p>
                                            <div
                                                *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"
                                                [innerHtml]="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"></div>
                                            {{ promotion.synopsisCTA }}
                                        </div>
                                        <div class="card-footer" *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTerms">
                                            <small class="text-muted">{{ promotion.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="promotion-page-new promo-section" *ngIf="!showConfigureNavigation">
            <div class="col lobby-padding">
                <div class="home-heading-wrap">
                    <div class="promo-heading" [vnDynamicHtml]="pokerPromotionsHome.mainContent1"></div>
                </div>
                <div class="row">
                    <ng-container *ngFor="let promotion of relatedPromos; let promoIndex = index">
                        <div
                            class="col-md-4 col-sm-6 col-xs-12 promotion-column"
                            *ngIf="promotion.pokerPromotionsSitecoreInfo && promotion.pokerPromotionsSitecoreInfo?.synopsisImage">
                            <a [href]="getPromoLink(promotion.path)" *ngIf="promotion.path">
                                <img [src]="promotion.pokerPromotionsSitecoreInfo.synopsisImage.src" alt="promotion" class="card-img-top img-fluid" />
                                <div *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle" class="card-body-text">
                                    <p *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle" class="card-body-title">
                                        {{ promotion.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                    </p>
                                    <div
                                        *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"
                                        class="card-body-title"
                                        [innerHtml]="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"></div>
                                    <p *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisCTA" class="card-body-title">
                                        {{ promotion.pokerPromotionsSitecoreInfo.synopsisCTA }}
                                    </p>
                                </div>
                                <div class="card-footer-promotion-page" *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTerms">
                                    <small class="text-footer">{{ promotion.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                                </div>
                            </a>

                            <a
                                [href]="getPromoLink(promotion.pokerPromotionsSitecoreInfo.data.metadata.id.path)"
                                *ngIf="!promotion.path && getPromoLink(promotion.pokerPromotionsSitecoreInfo.data.metadata.id.path)">
                                <img [src]="promotion.pokerPromotionsSitecoreInfo.synopsisImage.src" alt="promotion" class="card-img-top img-fluid" />
                                <div *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle" class="card-body-text">
                                    <p *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTitle" class="card-body-title">
                                        {{ promotion.pokerPromotionsSitecoreInfo.synopsisTitle }}
                                    </p>
                                    <div
                                        *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"
                                        class="card-body-title"
                                        [innerHtml]="promotion.pokerPromotionsSitecoreInfo.synopsisDescription"></div>
                                    <p *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisCTA" class="card-body-title">
                                        {{ promotion.pokerPromotionsSitecoreInfo.synopsisCTA }}
                                    </p>
                                </div>
                                <div class="card-footer-promotion-page" *ngIf="promotion.pokerPromotionsSitecoreInfo.synopsisTerms">
                                    <small class="text-footer">{{ promotion.pokerPromotionsSitecoreInfo.synopsisTerms }}</small>
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <scroll-to-top *ngIf="disableHtmlHeadTag"></scroll-to-top>
    </div>
</div>
