// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class WinTableView extends PIXI.Container {
    userCurrencyCode: any;
    public ENABLE_NEW_SPINS_BUYIN = true;
    displayUtil: DisplayUtil = new DisplayUtil();
    constructor() {
        super();
        //this.userCurrencyCode = window.parent.LSConnector.mainConnector.AppDataModel.getUserProfile("accountCurrencyCode");
        this.userCurrencyCode = 'USD';
    }
    createView = function (EventDispatcher, winnTableViewModel) {
        this.viewModel = winnTableViewModel;
        this.blackBG = this.displayUtil.getRectangleSprite(uiConfig.windowWidth / this.scale.x, uiConfig.windowHeight / this.scale.y, '#000000');
        this.displayUtil.setObjectPosition(this.blackBG, -this.x / this.scale.x, -this.y / this.scale.y);
        this.addChild(this.blackBG);
        this.blackBG.alpha = 0.5;
        this.blackBG.interactive = true;
        this.blackBG.name = 'blackBG';
        this.blackBG.visible = false;
        this.EventDispatcher = EventDispatcher;
        this.fontProps = uiConfig.font.props;
        this.visible = false;
        this.blackBg = this.displayUtil.getRectangleSprite(uiConfig.windowWidth / this.scale.x, uiConfig.windowHeight / this.scale.y, '#000000');
        this.displayUtil.setObjectPosition(this.blackBg, -this.x / this.scale.x, -this.y / this.scale.y);
        this.addChild(this.blackBg);
        this.blackBg.alpha = 0.01;
        this.blackBg.visible = true;
        this.resizeBlackBg();
        this.blackBg.buttonMode = false;
        this.blackBg.name = 'blackBg';
        this.blackBg.interactive = true;
        // this.ENABLE_NEW_SPINS_BUYIN = localStorage.getItem("ENABLE_NEW_SPINS_BUYIN") === "true";
        const mainLobbyTiles: any = localStorage.getItem('Main_Lobby_Tiles');
        if (mainLobbyTiles != null) {
            this.Main_Lobby_Tiles = JSON.parse(mainLobbyTiles);
        }
        const SNGJACKPOTTABMODE: any = localStorage.getItem('SNG_JACKPOT_TAB_MODE');
        if (SNGJACKPOTTABMODE != null) {
            this.SNG_JACKPOT_TAB_MODE = JSON.parse(SNGJACKPOTTABMODE);
        }
        this.expoSpinGameCount = 3;
        this.expoSpinTicketCount = 1;
        this.tableStatusData;
        this.enableRegister = true;
        this.enableCashAlone = false;
        this.enableCashAndTicket = false;
        this.maxGameTableRange;
        this.disableTicketBtn = false;
        this.ticketAvailable = false;
        // this.onResize();
        //this.displayUtil.setButtonActive(this.blackBg, true);
    };
    resizeBlackBg = function () {
        this.blackBG.width = uiConfig.windowWidth;
        this.blackBG.height = uiConfig.windowHeight;
    };
    showOldWinTable = function (tableStatusData, selfplayerData, winDetailsData) {
        this.blackBg.buttonMode = true;
        this.tableStatusData = tableStatusData;
        this.selfplayerData = selfplayerData;
        this.winDetailsData = winDetailsData;
        this.winTableId = winDetailsData.peerId;
        if (this.tableStatusData != null) {
            const buyInValue = this.tableStatusData.tourneyBuyIn + this.tableStatusData.tourneyEntryFee + this.tableStatusData.bountyFee;
            this.tableBuyInValueText.text = CommonGameProperties.mathUtil['transform'](buyInValue, tableStatusData.gameCurrency);
        }
        if (this.selfplayerData != null) {
            let avatarImage = this.selfplayerData.avatarImage;
            if (this.selfplayerData.isCustomAvatar) {
                avatarImage = this.getFormattedImageValue(this.selfplayerData);
            }
            this.createSprite('playerIconImage', 'winTableContainer', 'avatar_' + avatarImage, 235, 818);
            //this.playerIconImage.scale.x = 1.3;
            //this.playerIconImage.scale.y = 1.3;
            this.playerName.text = this.selfplayerData.playerId;
        }
        if (this.winDetailsData != null) {
            //facade.muteSoundsOnWinScreen(this.winTableId);
            //  facade[this.tableStatusData]
            const formattedWinAmount = this.winDetailsData.winAmount.amount; //  CommonGameProperties.mathUtil["transform"](this.winDetailsData.winAmount.amount, tableStatusData.gameCurrency);
            let cupType;
            let rankText = '';
            let winTextStyle = this.fontProps.finalCupWinText;
            let winPrizeStyle = this.fontProps.finalCupWinAmountText;
            let congratsText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogCongratulations');
            let winPrize = '' + formattedWinAmount;
            switch (this.winDetailsData.rank) {
                case 1:
                    cupType = 'final';
                    rankText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLEDLG_ANIMATIONWINNER');
                    winTextStyle = this.fontProps.finalCupWinText;
                    break;
                case 2:
                    cupType = 'silver';
                    rankText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogRankSecond');
                    winTextStyle = this.fontProps.silverCupWinText;
                    winPrizeStyle = this.fontProps.silverCupWinAmountText;
                    break;
                case 3:
                    cupType = 'bronze';
                    rankText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogRankThird');
                    winTextStyle = this.fontProps.bronzeCupWinText;
                    winPrizeStyle = this.fontProps.bronzeCupWinAmountText;
                    break;
                default:
                    cupType = 'bronze';
                    winTextStyle = this.fontProps.bronzeCupWinText;
                    winPrizeStyle = this.fontProps.bronzeCupWinAmountText;
            }
            const cupName = 'cup_' + cupType;
            if (this.winDetailsData.winAmount.amount > 0) {
                this.oldCreateText(
                    'winText',
                    'winTableContainer',
                    CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogYouWon'),
                    369,
                    155,
                    winTextStyle,
                );
            } else {
                congratsText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogAlmost');
                winPrize = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_POKERUTIL_TRNYSTATUSFINISHED');
            }
            this.oldCreateText('winPrize', 'winTableContainer', winPrize, 369, 228, winPrizeStyle);
            this.playerCongText.text = congratsText;
            this.createSprite('winCup', 'winTableContainer', cupName, 369, 480);
            this.oldCreateText('winStripTitle', 'winCup', rankText, 3, 111, this.fontProps.winStripTitleStyle);
        }
        this.visible = true;
    };
    getFormattedImageValue = function (addPlayerData) {
        let avatarId;
        if (addPlayerData && addPlayerData.avatarImage) {
            const str = addPlayerData.avatarImage.toString();
            let totalSum = 0;
            for (let i = 0; i < str.length; i++) {
                // eslint-disable-next-line unicorn/prefer-code-point
                totalSum += str.charCodeAt(i);
            }
            avatarId = totalSum % 42;
            if (avatarId == 0 || avatarId == 34 || avatarId == 42) {
                avatarId = 1;
            }
            return avatarId.toString();
        }
    };
    createSprite = function (spriteName, parentName, sprite, xPos, yPos) {
        this[spriteName] = this.displayUtil.getSprite(sprite);
        this[parentName].addChild(this[spriteName]);
        this[spriteName].anchor.set(0.5);
        this[spriteName].name = spriteName;
        this.displayUtil.setObjectPosition(this[spriteName], xPos, yPos);
    };
    createPDollarSprite = function (spriteName, parentName, sprite, xPos, yPos) {
        this[spriteName] = this.displayUtil.getSprite(sprite);
        this[parentName].addChild(this[spriteName]);
        this[spriteName].name = spriteName;
        this.displayUtil.setObjectPosition(this[spriteName], xPos, yPos);
    };
    createText = function (textName, parentName, text, xPos, yPos, reqStyle) {
        this[textName] = this.displayUtil.getTextField(reqStyle);
        this[parentName].addChild(this[textName]);
        this[textName].text = text;
        //this[textName].anchor.set(0.5);
        this.displayUtil.setObjectPosition(this[textName], xPos, yPos);
    };
    oldCreateText = function (textName, parentName, text, xPos, yPos, reqStyle) {
        this[textName] = this.displayUtil.getTextField(reqStyle);
        this[parentName].addChild(this[textName]);
        this[textName].text = text;
        this[textName].anchor.set(0.5);
        this.displayUtil.setObjectPosition(this[textName], xPos, yPos);
    };
    createWinScreen = function (tableStatusData, selfplayerData, winDetailsData) {
        if (this.SNG_JACKPOT_TAB_MODE == 1 || this.SNG_JACKPOT_TAB_MODE == 3) {
            this.oldWinScreen(tableStatusData, selfplayerData, winDetailsData);
        } else {
            if (this.ENABLE_NEW_SPINS_BUYIN == true) {
                this.expoSpinWinScreen(tableStatusData, selfplayerData, winDetailsData);
            } else {
                this.oldWinScreen(tableStatusData, selfplayerData, winDetailsData);
            }
        }
        this.createPDollarInfo();
    };
    createPDollarInfo = function () {
        this.createPDollarSprite('pDollarPnl', 'winTableContainer', 'pDollerPnl', 125, 380);
        this.createText('pokerBalanceTxt', 'pDollarPnl', '', 50, 35, this.fontProps.playerStripCongStyle);
        this.pokerBalanceTxt.text = 'Poker Balance';
        this.createText('realBalanceAmt', 'pDollarPnl', '', 110, 105, this.fontProps.expoSpinBalanceAmt);
        this.realBalanceAmt.text = '$10000000';
        this.createText(
            'currencyTypeTxt',
            'pDollarPnl',
            '',
            this.realBalanceAmt.x + this.realBalanceAmt.width + 5,
            105,
            this.fontProps.expoSpinBuyOutText,
        );
        this.currencyTypeTxt.text = 'USD';
        this.createText('playBalanceAmt', 'pDollarPnl', '', 110, 160, this.fontProps.expoSpinBalanceAmt);
        this.playBalanceAmt.text = '10000000';
        this.createText('totalPBalanceTxt', 'pDollarPnl', '', 50, 290, this.fontProps.expoSpinBuyOutText);
        this.totalPBalanceTxt.text = 'Total';
        this.createText('totalPBalanceAmt', 'pDollarPnl', '', 545, 290, this.fontProps.pDollerTotalAmt);
        this.totalPBalanceAmt.text = '$10000';
        this.totalPBalanceAmt.anchor.x = 1;
        this.pDollarPnl.visible = false;
        this.pDollarcloseBtn = this.displayUtil.getButton('close');
        this.pDollarPnl.addChild(this.pDollarcloseBtn);
        this.displayUtil.addInteraction(this.pDollarcloseBtn, this.pDollarcloseBtnClick.bind(this));
        this.displayUtil.setClickable(this.pDollarcloseBtn, true);
        this.displayUtil.setObjectPosition(this.pDollarcloseBtn, 510, 28);
    };
    updatePDollarData = function () {
        const obj: any = CommonGameProperties.facade['getPDollerData']();
        if (obj != null) {
            const balance = obj['netRealBalance']['accountBalance'];
            const gDoller = obj['tourneyCurrencyBalance']['tourneyCurrencyBalance'];
            const totalbal = balance + gDoller;
            this.realBalanceAmt.text = CommonGameProperties.mathUtil['transform'](balance, 'USD');
            this.currencyTypeTxt.text = 'USD';
            this.playBalanceAmt.text = CommonGameProperties.mathUtil['transform'](gDoller, 'USD');
            this.totalPBalanceAmt.text = CommonGameProperties.mathUtil['transform'](totalbal, 'USD');
            this.displayUtil.setObjectPosition(this.currencyTypeTxt, this.realBalanceAmt.x + this.realBalanceAmt.width + 5, this.currencyTypeTxt.y);
        }
    };
    pDollarcloseBtnClick = function () {
        this.pDollarPnl.visible = false;
    };
    oldWinScreen = function (tableStatusData, selfplayerData, winDetailsData) {
        this.visible = true;
        if (!this.winTableContainer && !this.winTableMainContainer) {
            this.winTableMainContainer = this.displayUtil.getContainer();
            this.addChild(this.winTableMainContainer);
            this.winScreenBg = this.displayUtil.getRectangleSprite(
                uiConfig.windowWidth / this.scale.x,
                uiConfig.windowHeight / this.scale.y,
                '#000066',
            );
            // this.createSprite("winScreenBg", "winTableMainContainer", "full_bg", 0, 0);
            this.winTableMainContainer.addChild(this.winScreenBg);
            //this.onResizeMainContainer();
            this.winTableContainer = this.displayUtil.getContainer();
            this.addChild(this.winTableContainer);
            this.closeButton = this.displayUtil.getButton('close');
            this.winTableContainer.addChild(this.closeButton);
            this.displayUtil.addInteraction(this.closeButton, this.closeBtnClick.bind(this));
            this.displayUtil.setClickable(this.closeButton, true);
            this.displayUtil.setObjectPosition(this.closeButton, 635, 140);
            this.displayUtil.assignHitArea(
                this.closeButton,
                {
                    x: 0,
                    y: -10,
                    w: this.closeButton.width + 20,
                    h: this.closeButton.height + 20,
                },
                'rect',
            );
            this.createSprite('playerStrip', 'winTableContainer', 'player_bg', 360, 817);

            this.oldCreateText('playerCongText', 'winTableContainer', '', 450, 795, this.fontProps.playerStripCongStyle);

            this.oldCreateText('playerName', 'winTableContainer', '', 450, 840, this.fontProps.playerStripCongStyle);

            this.oldCreateText(
                'playAgainText',
                'winTableContainer',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_STTREPLAY_MSG'),
                360,
                965,
                this.fontProps.playerStripCongStyle,
            );

            this.oldCreateText(
                'tableBuyInText',
                'winTableContainer',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_QSLOBBY_QUERY_BUYIN'),
                170,
                1030,
                this.fontProps.buyInTextStyle,
            );

            this.oldCreateText('tableBuyInValueText', 'winTableContainer', '', 550, 1030, this.fontProps.buyInTextStyle);
            const includeFee = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_COMMON_INCLUDEFEE', [
                CommonGameProperties.mathUtil['transform'](tableStatusData.tourneyEntryFee, tableStatusData.gameCurrency),
            ]);
            this.oldCreateText('includingFeeText', 'winTableContainer', includeFee, 185, 1079, this.fontProps.includingFeeTextStyle);
            const buyInValue = tableStatusData.tourneyBuyIn + tableStatusData.tourneyEntryFee + tableStatusData.bountyFee;
            let buyinFree;
            if (tableStatusData.gameCurrency == this.userCurrencyCode) {
                buyinFree = CommonGameProperties.mathUtil['transform'](buyInValue, tableStatusData.gameCurrency) + ' ';
            } else {
                buyinFree = '(' + CommonGameProperties.mathUtil['transform'](buyInValue, this.userCurrencyCode) + ' ' + this.userCurrencyCode + ')';
            }
            if (tableStatusData.gameCurrency != this.userCurrencyCode) {
                this.oldCreateText('includingFeeValueText', 'winTableContainer', buyinFree, 550, 1079, this.fontProps.includingFeeTextStyle);
            }
            this.registerBtn = this.displayUtil.getButton('btn');
            this.winTableContainer.addChild(this.registerBtn);
            this.registerBtn.anchor.set(0.5);
            this.displayUtil.addInteraction(this.registerBtn, this.OnRegisterBtnClick.bind(this));
            this.displayUtil.setClickable(this.registerBtn, true);
            this.displayUtil.setObjectPosition(this.registerBtn, 360, 1200);
            this.oldCreateText(
                'registerBtnText',
                'registerBtn',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_REGISTER'),
                0,
                0,
                this.fontProps.registerTextStyle,
            );
        }
        this.showOldWinTable(tableStatusData, selfplayerData, winDetailsData);
        //this.onResize();
        ResponsiveContainer.call(this.winTableContainer, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        this.winTableContainer.setSize(720, 1280);
    };
    closeBtnClick = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_EXPOSPIN_VID);
        this.viewModel.onClose();
        this.winTableContainer.visible = false;
        this.blackBg.buttonMode = false;
    };
    OnRegisterBtnClick = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_EXPOSPIN_VID);
        uiConfig.winTableViewFullScaleView = CommonGameProperties.facade['fullScaleView'];
        this.viewModel.onRegister(this.expoSpinGameCount);
        this.winTableContainer.visible = false;
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.WIN_GAME_CLOSE);
    };
    onResize = function () {
        const scaleXValue = uiConfig.windowWidth / 720;
        const scaleYValue = uiConfig.windowHeight / 1280;
        if (this.winTableContainer) {
            //this.winTableContainer.scale.set(Math.max(scaleXValue, scaleYValue));
            //this.winTableContainer.x = (uiConfig.windowWidth - this.winTableContainer.width)/2
            this.winTableContainer.scale.x = scaleXValue;
            this.winTableContainer.scale.y = scaleYValue;
            this.displayUtil.setObjectPosition(
                this.winTableContainer,
                (uiConfig.windowWidth - this.winTableContainer.width) / 2,
                (uiConfig.windowHeight - this.winTableContainer.height) / 2,
            );
            if (this.winCup) {
                this.winCup.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.winCup.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                //below scale for streching of avtar in winppop
                this.playerIconImage.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerIconImage.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                // scaling all to check
                this.closeButton.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.closeButton.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerStrip.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerStrip.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerCongText.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerCongText.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerName.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.playerName.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.playAgainText.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.playAgainText.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.tableBuyInText.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.tableBuyInText.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.tableBuyInValueText.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.tableBuyInValueText.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.includingFeeText.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.includingFeeText.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.registerBtn.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.registerBtn.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                if (this.winText) {
                    this.winText.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                    this.winText.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                }
                this.winPrize.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.winPrize.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                this.winStripTitle.scale.x = (1 / scaleXValue) * ((scaleXValue + scaleYValue) / 2);
                this.winStripTitle.scale.y = (1 / scaleYValue) * ((scaleXValue + scaleYValue) / 2);
                //
            }
        }
        this.blackBG.scale.set(Math.max(scaleXValue, scaleYValue));
        this.blackBg.scale.set(Math.max(scaleXValue, scaleYValue));
    };
    expoSpinWinScreen = function (tableStatusData, selfplayerData, winDetailsData) {
        this.visible = true;
        this.tableStatusData = tableStatusData;
        this.winDetailsData = winDetailsData;
        this.availableTickets = 0;
        if (winDetailsData.ticketOnly == true) {
            this.enableCashAlone = false;
            if (winDetailsData.availableTickets > 0) {
                this.enableCashAndTicket = false;
                this.disableTicketBtn = true;
                this.availableTickets = winDetailsData.availableTickets;
            } else {
                this.enableCashAndTicket = false;
                this.availableTickets = 0;
                this.expoSpinTicketCount = 0;
                this.disableTicketBtn = false;
            }
        } else {
            this.disableTicketBtn = false;
            if (winDetailsData.ticketAvailable == true && winDetailsData.availableTickets > 0) {
                this.enableCashAlone = false;
                this.enableCashAndTicket = true;
                this.ticketAvailable = true;
                this.availableTickets = winDetailsData.availableTickets;
            } else {
                this.enableCashAlone = true;
                this.enableCashAndTicket = false;
                this.availableTickets = 0;
                this.expoSpinTicketCount = 0;
                this.ticketAvailable = false;
            }
        }
        if (!this.winTableContainer && !this.winTableMainContainer) {
            this.winTableMainContainer = this.displayUtil.getContainer();
            this.addChild(this.winTableMainContainer);
            this.winScreenBg = this.displayUtil.getRectangleSprite(
                uiConfig.windowWidth / this.scale.x,
                uiConfig.windowHeight / this.scale.y,
                '#121314',
            );
            this.winTableMainContainer.addChild(this.winScreenBg);
            this.winTableContainer = this.displayUtil.getContainer();
            this.addChild(this.winTableContainer);
            this.createSprite('expoSpinContainer', 'winTableContainer', 'expoSpinContainer', 360, 715);
            this.expoSpinContainer.y = this.expoSpinContainer.y - 25;
            this.expoSpinContainer.width = this.expoSpinContainer.width + 60;
            this.expoSpinContainer.height = this.expoSpinContainer.height + 50;
            this.closeButton = this.displayUtil.getButton('close');
            this.winTableContainer.addChild(this.closeButton);
            this.displayUtil.addInteraction(this.closeButton, this.closeBtnClick.bind(this));
            this.displayUtil.setClickable(this.closeButton, true);
            this.displayUtil.setObjectPosition(this.closeButton, 635, 140);
            this.displayUtil.assignHitArea(
                this.closeButton,
                {
                    x: 0,
                    y: -10,
                    w: this.closeButton.width + 20,
                    h: this.closeButton.height + 20,
                },
                'rect',
            );
            const adjY = 50;
            this.createSprite('playerStrip', 'winTableContainer', 'player_bg', 360, 610 + adjY);
            this.oldCreateText('playerCongText', 'winTableContainer', '', 450, 588 + adjY, this.fontProps.playerStripCongStyle);
            this.oldCreateText('playerName', 'winTableContainer', '', 450, 633 + adjY, this.fontProps.playerStripCongStyle);

            this.createSprite('balancePanal', 'winTableContainer', 'expoSpinSmallPanal', 360, 750 + adjY);
            this.balancePanal.scale.x = 1.1;
            this.balancePanal.scale.y = 1.1;
            this.createText(
                'availableBalanceTxt',
                'balancePanal',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_AVAILABLE_BALANCE'),
                -285,
                -15,
                this.fontProps.expoSpinBuyOutText,
            );
            const obj: any = CommonGameProperties.facade['getPDollerData']();
            let balance = obj['netRealBalance']['accountBalance'];
            let gDoller = obj['tourneyCurrencyBalance']['tourneyCurrencyBalance'];
            const availableBalance = CommonGameProperties.mathUtil['transform'](balance + gDoller, tableStatusData.gameCurrency) + ' ';
            this.createText('availableBalanceAmt', 'balancePanal', availableBalance, 250, -15, this.fontProps.expoSpinBalanceAmt);
            this.availableBalanceAmt.anchor.x = 1;
            this.createSprite('expoSpinInfo_icon', 'balancePanal', 'expoSpinInfo_icon', 270, 0);
            this.displayUtil.addInteraction(this.expoSpinInfo_icon, this.expoSpinInfo_iconClick.bind(this));
            this.displayUtil.setClickable(this.expoSpinInfo_icon, true);
            this.createSprite('numberOfGamepanal', 'winTableContainer', 'expoSpinSmallPanal', 360, 831 + adjY);
            this.numberOfGamepanal.scale.x = 1.1;
            this.numberOfGamepanal.scale.y = 1.1;
            this.createText(
                'numberOfGamesTxt',
                'numberOfGamepanal',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_NUM_OF_GAMES'),
                -285,
                -15,
                this.fontProps.expoSpinBuyOutText,
            );
            this.createSprite('expoSpinNumberPanal', 'numberOfGamepanal', 'expoSpinNumberPanal', 220, 0);
            this.createText('numberOfGames', 'expoSpinNumberPanal', '3', -5, -14, this.fontProps.expoSpinBalanceAmt);
            this.createSprite('expoSpinPlusBtn', 'expoSpinNumberPanal', 'expoSpinPlusBtn', 40, 0);
            this.displayUtil.addInteraction(this.expoSpinPlusBtn, this.expoSpinPlusBtnClick.bind(this));
            this.displayUtil.setClickable(this.expoSpinPlusBtn, true);
            this.createSprite('expoSpinMinusBtn', 'expoSpinNumberPanal', 'expoSpinMinusBtn', -40, 0);
            this.displayUtil.addInteraction(this.expoSpinMinusBtn, this.expoSpinMinusBtnClick.bind(this));
            this.displayUtil.setClickable(this.expoSpinMinusBtn, true);
            this.createSprite('ticketPanal', 'winTableContainer', 'expoSpinSmallPanal', 360, 911 + adjY);
            this.ticketPanal.scale.x = 1.1;
            this.ticketPanal.scale.y = 1.1;
            this.createText('ticketCount', 'ticketPanal', '10', -285, -15, this.fontProps.expoSpinTicketCount);
            this.createText(
                'numberOfticketText',
                'ticketPanal',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_TICKETS_AVAILABLE'),
                -255,
                -15,
                this.fontProps.expoSpinBuyOutText,
            );
            this.createSprite('expoSpinTicketNumberPanal', 'ticketPanal', 'expoSpinNumberPanal', 220, 0);
            this.createText('numberOfTicket', 'expoSpinTicketNumberPanal', '2', -5, -14, this.fontProps.expoSpinBalanceAmt);
            this.createSprite('expoSpinTicketPlusBtn', 'expoSpinTicketNumberPanal', 'expoSpinPlusBtn', 40, 0);
            this.displayUtil.addInteraction(this.expoSpinTicketPlusBtn, this.expoSpinTicketPlusBtnClick.bind(this));
            this.displayUtil.setClickable(this.expoSpinTicketPlusBtn, true);
            this.createSprite('expoSpinTicketMinusBtn', 'expoSpinTicketNumberPanal', 'expoSpinMinusBtn', -40, 0);
            this.displayUtil.addInteraction(this.expoSpinTicketMinusBtn, this.expoSpinTicketMinusBtnClick.bind(this));
            this.displayUtil.setClickable(this.expoSpinTicketMinusBtn, true);
            if (this.disableTicketBtn == true) {
                this.expoSpinTicketPlusBtn.alpha = 0.5;
                this.expoSpinTicketMinusBtn.alpha = 0.5;
            }
            this.oldCreateText(
                'expoAlertText',
                'winTableContainer',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_NO_ENOUGH_FUNDS'),
                360,
                1125,
                this.fontProps.expoSpinNoFundAlert,
            );
            this.expoAlertText.visible = false;
            this.oldCreateText(
                'expoNoTicketAlertTxt',
                'winTableContainer',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_NO_ENOUGH_TICKETS'),
                360,
                1125,
                this.fontProps.expoSpinNoFundAlert,
            );
            this.expoNoTicketAlertTxt.visible = false;
            this.createSprite('gameBuyinPanal', 'winTableContainer', 'expoSpinMidiumPanal', 360, 1017 + adjY);
            this.gameBuyinPanal.scale.x = 1.1;
            this.gameBuyinPanal.scale.y = 1.1;
            this.createText(
                'gameBuyInTxt',
                'gameBuyinPanal',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_GAME_BUY_IN'),
                -285,
                -40,
                this.fontProps.expoSpinBalanceAmt,
            );
            this.createText('availableBalanceTxt', 'gameBuyinPanal', '', -285, 0, this.fontProps.expoSpinBuyOutText);
            this.createText('availableBalanceAmt', 'gameBuyinPanal', '', 275, 0, this.fontProps.expoSpinBalanceAmt);
            this.availableBalanceAmt.anchor.x = 1;
            this.createSprite('expoSpinBottomPanal', 'winTableContainer', 'expoSpinBottomPanal', 360, 1200);
            this.expoSpinBottomPanal.width = this.expoSpinBottomPanal.width + 60;
            this.createSprite('cancelBtn', 'expoSpinBottomPanal', 'expoSpinGrayBtn', -150, 14);
            this.cancelBtn.scale.x = 0.99;
            this.cancelBtn.scale.y = 0.99;
            this.cancelBtn.anchor.set(0.5);
            this.displayUtil.addInteraction(this.cancelBtn, this.closeBtnClick.bind(this));
            this.displayUtil.setClickable(this.cancelBtn, true);
            // this.oldCreateText("cancelBtnText", "cancelBtn",
            //  CommonGameProperties.langPack["getLiteral"]("PARTY_ARA_GC_FC_PCMessageBox_NO"), 0, 0, this.fontProps.registerTextStyle);
            this.oldCreateText(
                'cancelBtnText',
                'cancelBtn',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_TABLE_WAITLIST_NOTNOW').toUpperCase(),
                0,
                0,
                this.fontProps.registerTextStyle,
            );

            this.createSprite('registerBtn', 'expoSpinBottomPanal', 'expoSpinOrengeBtn', 150, 18);
            this.registerBtn.scale.x = 0.99;
            this.registerBtn.scale.y = 0.99;
            this.registerBtn.anchor.set(0.5);
            this.displayUtil.addInteraction(this.registerBtn, this.OnExpoSpinRegisterBtnClick.bind(this));
            this.displayUtil.setClickable(this.registerBtn, true);
            this.oldCreateText(
                'registerBtnText',
                'registerBtn',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_REGISTER').toUpperCase(),
                0,
                0,
                this.fontProps.registerTextStyle,
            );

            this.createSprite('depositBtn', 'expoSpinBottomPanal', 'expoSpinOrengeBtn', 175, 18);
            this.depositBtn.scale.x = 0.99;
            this.depositBtn.scale.y = 0.99;
            this.depositBtn.anchor.set(0.5);
            this.depositBtn.visible = false;
            this.displayUtil.addInteraction(this.depositBtn, this.OnDepositBtnClick.bind(this));
            this.displayUtil.setClickable(this.depositBtn, true);
            this.oldCreateText(
                'depositBtnText',
                'depositBtn',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_DEPOSIT'),
                0,
                0,
                this.fontProps.registerTextStyle,
            );

            this.createSprite('expoOKBtn', 'expoSpinBottomPanal', 'btn_up', 0, 18);
            this.expoOKBtn.scale.x = 0.99;
            this.expoOKBtn.scale.y = 0.99;
            this.expoOKBtn.anchor.set(0.5);
            this.expoOKBtn.visible = false;
            this.displayUtil.addInteraction(this.expoOKBtn, this.closeBtnClick.bind(this));
            this.displayUtil.setClickable(this.expoOKBtn, true);
            this.oldCreateText(
                'expoOKBtnText',
                'expoOKBtn',
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_LOGIN_OK'),
                0,
                0,
                this.fontProps.registerTextStyle,
            );
        }
        this.showExpoSpinWinTable(tableStatusData, selfplayerData, winDetailsData);
        this.numberOfGames.text = this.getExpoSpinGameCount();
        const buyInData = this.getBuyinValue(this.expoSpinGameCount);
        this.availableBalanceTxt.text = buyInData.buyinFee;
        this.availableBalanceAmt.text = buyInData.buyInGBP;
        if (this.enableCashAlone == true) {
            this.ticketPanal.visible = false;
            this.gameBuyinPanal.y = 990;
        } else {
            if (this.availableTickets > 0) {
                this.ticketPanal.visible = true;
                this.ticketCount.text = this.availableTickets;
                if (this.disableTicketBtn == true) {
                    this.numberOfTicket.text = 1;
                    this.expoSpinTicketCount = 1;
                } else {
                    this.numberOfTicket.text = 0;
                    this.expoSpinTicketCount = 0;
                }
                this.checkEnoughBalance();
                this.checkAvailableTickets();
            } else {
                this.numberOfGamepanal.visible = false;
                this.ticketPanal.visible = false;
                this.gameBuyinPanal.visible = false;
                this.cancelBtn.visible = false;
                this.registerBtn.visible = false;
                this.depositBtn.visible = false;
                this.expoOKBtn.visible = true;
            }
        }
        ResponsiveContainer.call(this.winTableContainer, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        this.winTableContainer.setSize(720, 1280);
    };
    expoSpinInfo_iconClick = function () {
        this.pDollarPnl.visible = true;
        this.updatePDollarData();
    };
    OnExpoSpinRegisterBtnClick = function () {
        if (this.enableRegister == true) {
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_EXPOSPIN_VID);
            uiConfig.winTableViewFullScaleView = CommonGameProperties.facade['fullScaleView'];
            this.viewModel.onExpoSpinRegister(this.expoSpinGameCount, this.expoSpinTicketCount);
            this.winTableContainer.visible = false;
            this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.WIN_GAME_CLOSE);
        }
    };
    getBuyinValue = function (gameTableCount) {
        this.buyInValue = this.tableStatusData.tourneyBuyIn + this.tableStatusData.tourneyEntryFee + this.tableStatusData.bountyFee;
        const buyIn = CommonGameProperties.mathUtil['transform'](this.buyInValue, this.tableStatusData.gameCurrency);
        const totalBuyin = CommonGameProperties.mathUtil['transform'](this.buyInValue * gameTableCount, this.tableStatusData.gameCurrency) + ' ';
        const includeFee = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_COMMON_INCLUDEFEE', [
            CommonGameProperties.mathUtil['transform'](this.tableStatusData.tourneyEntryFee * gameTableCount, this.tableStatusData.gameCurrency),
        ]);
        const returnVal = {
            buyinFee: gameTableCount + ' x ' + buyIn + ' ' + includeFee,
            buyInGBP:
                totalBuyin +
                ' (' +
                CommonGameProperties.currencyConverter['getFormattedAmountValue'](this.buyInValue * gameTableCount, 'GBP') +
                ' GBP)',
        };
        return returnVal;
    };
    checkEnoughBalance = function () {
        const availableBalance = this.winDetailsData.accountBalance;
        const totalBuyIn = this.buyInValue * this.expoSpinGameCount;
        if (this.ticketAvailable == true) {
            if (totalBuyIn > availableBalance) {
                const totalTicketBuyin = this.buyInValue * this.expoSpinTicketCount;
                const availableBalanceWithTicket = totalTicketBuyin + availableBalance;
                if (totalBuyIn > availableBalanceWithTicket) {
                    this.showInsufficiantBalanace(true);
                } else {
                    this.showInsufficiantBalanace(false);
                }
            } else {
                this.showInsufficiantBalanace(false);
            }
        } else {
            if (totalBuyIn > availableBalance) {
                this.showInsufficiantBalanace(true);
            } else {
                this.showInsufficiantBalanace(false);
            }
        }
    };
    getExpoSpinGameCount = function () {
        let expoSpinGameVal = localStorage.getItem('spinsGameCount');
        if (expoSpinGameVal === null || expoSpinGameVal === undefined) expoSpinGameVal = '1';
        let expoSpinGameCount = 0;
        if (expoSpinGameVal != null) {
            expoSpinGameCount = parseInt(expoSpinGameVal);
        }
        const activeGameCount = CommonGameProperties.facade['activeTables'].length;
        let availableGameCount = 5 - activeGameCount;
        let returnVal = 0;
        if (expoSpinGameCount <= availableGameCount) {
            returnVal = expoSpinGameCount;
        } else {
            returnVal = availableGameCount;
        }
        this.expoSpinGameCount = returnVal;
        this.maxGameTableRange = availableGameCount == 5 ? 4 : availableGameCount;
        localStorage.setItem('spinsGameCount', returnVal.toString());
        return returnVal;
    };
    saveExpoSpinGameCount = function (gameCount) {
        localStorage.setItem('spinsGameCount', gameCount);
    };
    OnDepositBtnClick = function () {
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_EXPOSPIN_VID);
        CommonGameProperties.clienttoserver['openDepositPage']('deposit');
    };
    expoSpinPlusBtnClick = function () {
        if (this.expoSpinGameCount < this.maxGameTableRange) {
            if (this.disableTicketBtn == true && this.expoSpinTicketCount == this.expoSpinGameCount) {
                this.expoSpinTicketPlus();
            }
            this.expoSpinGameCount += 1;
            this.numberOfGames.text = this.expoSpinGameCount;
        }
        this.updateBuyIn(this.expoSpinGameCount);
        this.checkAvailableTickets();
        this.saveExpoSpinGameCount(this.expoSpinGameCount);
    };
    updateBuyIn = function (expoSpinGameCount) {
        const buyInData = this.getBuyinValue(expoSpinGameCount);
        this.availableBalanceTxt.text = buyInData.buyinFee;
        this.availableBalanceAmt.text = buyInData.buyInGBP;
    };
    expoSpinMinusBtnClick = function () {
        if (this.expoSpinGameCount > 1) {
            if (this.disableTicketBtn == true && this.expoSpinTicketCount == this.expoSpinGameCount) {
                this.expoSpinTicketMinus();
            }
            this.expoSpinGameCount -= 1;
            this.numberOfGames.text = this.expoSpinGameCount;
        }
        this.updateBuyIn(this.expoSpinGameCount);
        this.checkAvailableTickets();
        this.saveExpoSpinGameCount(this.expoSpinGameCount);
    };
    expoSpinTicketPlusBtnClick = function () {
        if (this.disableTicketBtn == false) {
            this.expoSpinTicketPlus();
        }
    };
    expoSpinTicketPlus = function () {
        if (this.expoSpinTicketCount < this.availableTickets) {
            if (this.disableTicketBtn == true) {
                if (this.expoSpinTicketCount <= this.expoSpinGameCount) {
                    this.ticketPlus();
                }
            } else {
                if (this.expoSpinTicketCount < this.expoSpinGameCount) {
                    this.ticketPlus();
                }
            }
        }
    };
    ticketPlus = function () {
        this.expoSpinTicketCount += 1;
        this.numberOfTicket.text = this.expoSpinTicketCount;
        this.checkAvailableTickets();
    };
    expoSpinTicketMinusBtnClick = function () {
        if (this.disableTicketBtn == false) {
            this.expoSpinTicketMinus();
        }
    };
    expoSpinTicketMinus = function () {
        if (this.expoSpinTicketCount > 0) {
            this.expoSpinTicketCount -= 1;
            this.numberOfTicket.text = this.expoSpinTicketCount;
            this.checkAvailableTickets();
        }
    };
    checkAvailableTickets = function () {
        if (this.enableCashAlone == false && this.ticketAvailable == false) {
            if (this.enableCashAndTicket == true) {
                if (this.expoSpinTicketCount < this.expoSpinGameCount && this.expoSpinTicketCount > 0) {
                    this.showNotEnoughTicket(true);
                    this.enableRegister = false;
                } else {
                    this.showNotEnoughTicket(false);
                    this.enableRegister = true;
                }
            } else {
                if (this.expoSpinTicketCount < this.expoSpinGameCount) {
                    this.showNotEnoughTicket(true);
                    this.enableRegister = false;
                } else {
                    this.showNotEnoughTicket(false);
                    this.enableRegister = true;
                }
            }
        }
        this.checkEnoughBalance();
    };
    showNotEnoughTicket = function (showWarning) {
        let moveUp = 0;
        if (showWarning == true) {
            if (this.expoNoTicketAlertTxt.visible == false) {
                moveUp = 30;
                this.expoNoTicketAlertTxt.visible = true;
                this.expoNoTicketAlertTxt.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_NO_ENOUGH_TICKETS');
            }
        } else {
            if (this.expoNoTicketAlertTxt.visible == true) {
                moveUp = -30;
                this.expoNoTicketAlertTxt.visible = false;
            }
        }
        this.expoSpinItemsMoveUp(moveUp);
    };
    showInsufficiantBalanace = function (showWarning) {
        let moveUp = 0;
        if (showWarning == true) {
            if (this.expoAlertText.visible == false) {
                moveUp = 30;
                this.expoAlertText.visible = true;
                this.depositBtn.visible = true;
                this.registerBtn.visible = false;
                this.cancelBtnText.text = CommonGameProperties.langPack['getLiteral']('PARTY_COMMON_GC_CANCEL');
                this.expoAlertText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_EXPOSPINS_NO_ENOUGH_FUNDS');
            }
        } else {
            if (this.expoAlertText.visible == true) {
                moveUp = -30;
                this.expoAlertText.visible = false;
                this.depositBtn.visible = false;
                this.registerBtn.visible = true;
                this.cancelBtnText.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_TABLE_WAITLIST_NOTNOW').toUpperCase();
            }
        }
        this.expoSpinItemsMoveUp(moveUp);
    };
    expoSpinItemsMoveUp = function (moveUp) {
        this.expoSpinContainer.y = this.expoSpinContainer.y - moveUp;
        this.playerStrip.y = this.playerStrip.y - moveUp;
        this.playerCongText.y = this.playerCongText.y - moveUp;
        this.playerName.y = this.playerName.y - moveUp;
        this.balancePanal.y = this.balancePanal.y - moveUp;
        this.numberOfGamepanal.y = this.numberOfGamepanal.y - moveUp;
        this.ticketPanal.y = this.ticketPanal.y - moveUp;
        this.gameBuyinPanal.y = this.gameBuyinPanal.y - moveUp;
        this.playerIconImage.y = this.playerIconImage.y - moveUp;
        this.winCup.y = this.winCup.y - moveUp;
        this.winPrize.y = this.winPrize.y - moveUp;
    };
    showExpoSpinWinTable = function (tableStatusData, selfplayerData, winDetailsData) {
        this.blackBg.buttonMode = true;
        this.tableStatusData = tableStatusData;
        this.selfplayerData = selfplayerData;
        this.winDetailsData = winDetailsData;
        this.winTableId = winDetailsData.peerId;
        const adjY = 75;
        if (this.selfplayerData != null) {
            let avatarImage = this.selfplayerData.avatarImage;
            if (this.selfplayerData.isCustomAvatar) {
                avatarImage = this.getFormattedImageValue(this.selfplayerData);
            } else {
                avatarImage = avatarImage == '42' ? '1' : avatarImage;
            }
            //If server requests for avatarImage which is not present and is greater than 44 then we are assigning one avatar image from JSON
            //Here, in this case, assigning avatarImage number 43.
            if (parseInt(avatarImage) > 41) {
                avatarImage = '41';
            }
            this.createSprite('playerIconImage', 'winTableContainer', 'avatar_' + avatarImage, 235, 589 + adjY);
            this.playerName.text = this.selfplayerData.playerId;
        }
        if (this.winDetailsData != null) {
            const formattedWinAmount = CommonGameProperties.mathUtil['transform'](this.winDetailsData.winAmount.amount, tableStatusData.gameCurrency);
            let cupType;
            let rankText = '';
            let congratsText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogCongratulations');
            let winPrize = '' + formattedWinAmount;
            switch (this.winDetailsData.rank) {
                case 1:
                    cupType = 'final';
                    rankText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLEDLG_ANIMATIONWINNER');
                    break;
                case 2:
                    cupType = 'silver';
                    rankText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogRankSecond');
                    congratsText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogAlmost');
                    break;
                case 3:
                    cupType = 'bronze';
                    rankText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogRankThird');
                    congratsText =
                        this.winDetailsData.winAmount.amount > 0
                            ? CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogAlmost')
                            : CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogUnlucky');
                    break;
                default:
                    cupType = 'bronze';
            }
            const cupName = 'cup_' + cupType;
            let youWonVal = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_POKERUTIL_TRNYSTATUSFINISHED');
            if (this.winDetailsData.winAmount.amount > 0) {
                let youwonTxt = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_ANSPINS_PtmWinnerDialogYouWon');
                this.oldCreateText(
                    'winPrize',
                    'winTableContainer',
                    youwonTxt.charAt(0) + youwonTxt.slice(1).toLowerCase() + ' ' + winPrize,
                    369,
                    460 + adjY,
                    this.fontProps.expoSpinsWinAmountText,
                );
            } else {
                winPrize = youWonVal.charAt(0) + youWonVal.slice(1).toLowerCase();
                this.oldCreateText('winPrize', 'winTableContainer', winPrize, 369, 460 + adjY, this.fontProps.expoSpinsWinAmountText);
            }
            this.createSprite('winCup', 'winTableContainer', cupName, 369, 290 + adjY);
            this['winCup'].scale.x = 0.8;
            this['winCup'].scale.y = 0.8;
            this.playerCongText.text = congratsText;
            this.oldCreateText('winStripTitle', 'winCup', rankText, 3, 111, this.fontProps.winStripTitleStyle);
        }
        this.visible = true;
    };
}
