import { Message } from '../../message';

export class PlayerBalanceInfo extends Message {
    private MESSAGE_NAME: string = 'PlayerBalanceInfo';
    private previousBalance: number = 0;
    private transactionAmount: number = 0;
    private currentBalance: number = 0;
    private sessionId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        previousBalance: number = 0,
        transactionAmount: number = 0,
        currentBalance: number = 0,
        sessionId: number = 0,
    ) {
        super(reqServerPeerId);
        this.previousBalance = previousBalance;
        this.transactionAmount = transactionAmount;
        this.currentBalance = currentBalance;
        this.sessionId = sessionId;
    }

    getPreviousBalance(): number {
        return this.previousBalance;
    }

    setPreviousBalance(previousBalance: number) {
        this.previousBalance = previousBalance;
    }
    getTransactionAmount(): number {
        return this.transactionAmount;
    }

    setTransactionAmount(transactionAmount: number) {
        this.transactionAmount = transactionAmount;
    }
    getCurrentBalance(): number {
        return this.currentBalance;
    }

    setCurrentBalance(currentBalance: number) {
        this.currentBalance = currentBalance;
    }
    getSessionId(): number {
        return this.sessionId;
    }

    setSessionId(sessionId: number) {
        this.sessionId = sessionId;
    }
}
