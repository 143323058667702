import { Message } from '../../message';

export class CurrencyDetailsRequest extends Message {
    private MESSAGE_NAME: string = 'CurrencyDetailsRequest';
    private currencyCodes: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currencyCodes: any[] | null = null) {
        super(reqServerPeerId);
        this.currencyCodes = currencyCodes;
    }

    getCurrencyCodes(): any[] | null {
        return this.currencyCodes;
    }

    setCurrencyCodes(currencyCodes: any[] | null) {
        this.currencyCodes = currencyCodes;
    }
}
