<!doctype html>
<html lang="en">
    <head>
        <meta charset="utf-8" />
        <title>HHReplayer</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </head>

    <body>
        <div class="web-veiw-wrapper hand-history-mainbody hh mobile" [class.invokerApp]="invokerApp" [hidden]="isShowtable">
            <div class="game-table-wrapper" [class.vcClient]="vcClient" [class.m-NativeApp]="mNativeApp" [class.macDesktop]="macDesktop">
                <div class="game-table-innerwrapper demo-table-wrapper web-veiw" [class.tablet]="isTablet" [class.mobileReplayer]="isMobile">
                    <div
                        class="close-btn theme-ex close-simu-btn"
                        *ngIf="
                            !nativeApp &&
                            isDownloadVisible &&
                            gameTableService.gtInitializeDone &&
                            !gameTableService.gameTable.animationType &&
                            !gameTableService.gameTable.gameInfo
                        "
                        (click)="closeGameTable($event)"></div>
                    <div class="game-table-container" id="game-table-container">
                        <div *ngIf="gameTableService.gtInitializeDone">
                            <div class="game-player-info" *ngIf="!gameTableService.showErrorMessage">
                                <div class="step1">
                                    <span class="bids" *ngIf="gameTableService.gameTable.smallBlind > 0 && gameTableService.gameTable.bigBlind > 0">
                                        <span class="tableName"> {{ gameTableService.gameTable.name }}&nbsp;</span>
                                        {{ gameTableService.gameTable.smallBlind / 100 | number: '1.0-2' }}/{{
                                            (gameTableService.gameTable.bigBlind / 100).toString().trim() | number: '1.0-2'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="table-ring"></div>
                            <div class="tableInfo h-screen">
                                <span class="tableName">{{ gameTableService.gameTable.name }} - </span>
                                <span class="gameLimit" *ngIf="!gameTableService.gameTable.isShortDeck">{{
                                    gameTableService.gameTable.gameLimit
                                }}</span>
                                <span class="gameType" *ngIf="!gameTableService.gameTable.isShortDeck"
                                    >{{ gameTableService.gameTable.gType }} ({{ gameTableService.gameTable.maxSeats }}-{{ serve.content.Max }}) -
                                </span>
                                <span class="gameType" *ngIf="gameTableService.gameTable.isShortDeck"
                                    >{{ gameTableService.gameTable.gType }}
                                    <span class="gameLimit" *ngIf="gameTableService.gameTable.isShortDeck">{{
                                        gameTableService.gameTable.gameLimit
                                    }}</span>
                                    Holde'm ({{ gameTableService.gameTable.maxSeats }}-{{ serve.content.Max }}) -
                                </span>
                                <span class="bids" *ngIf="gameTableService.gameTable.smallBlind > 0 && gameTableService.gameTable.bigBlind > 0">
                                    {{ gameTableService.gameTable.smallBlind / 100 }}/
                                    {{ gameTableService.gameTable.bigBlind / 100 }}
                                    <span
                                        class="ante"
                                        *ngIf="!gameTableService.gameTable.isTourny && gameTableService.gameTable.isAnteCashGameOrTourny === 1"
                                        >Ante {{ gameTableService.gameTable.pot.gameCurrency }}
                                    </span>
                                    <span *ngIf="gameTableService.gameTable.anteAmount > 0">{{ gameTableService.gameTable.anteAmount / 100 }}</span>
                                </span>
                                <div class="handID"></div>
                            </div>
                            <div *ngIf="!gameTableService.showErrorMessage">
                                <pkr-player *ngFor="let player of gameTableService.gameTable.players" [player]="player"> </pkr-player>
                                <pkr-dealer [dealer]="gameTableService.gameTable.dealer" *ngIf="gameTableService.gameTable.dealer"> </pkr-dealer>
                                <pkr-pot
                                    [pot]="gameTableService.gameTable.pot"
                                    [gameTable]="gameTableService.gameTable"
                                    [totalPot]="gameTableService.gameTable.MAIN_POT.totalValue"></pkr-pot>
                                <pkr-community-cards
                                    [cards]="gameTableService.gameTable.communityCards"
                                    [gameTable]="gameTableService.gameTable"></pkr-community-cards>
                                <pkr-player-pots [players]="gameTableService.gameTable.players" [gameTable]="gameTableService.gameTable">
                                </pkr-player-pots>
                            </div>

                            <div class="mobile-player-control" *ngIf="gameTableService.gameTable.isShowControlls">
                                <div class="backGround">
                                    <div class="smallcontrols" *ngIf="gameTableService.gameTable.smallControlls.length > 0">
                                        <a
                                            class="bet1"
                                            *ngIf="gameTableService.gameTable.smallControlls[0] !== ''"
                                            (click)="gameTableService.bet(gameTableService.gameTable.displayValues[0])">
                                            <span class="smallbuttonText">{{ gameTableService.gameTable.smallControlls[0] }}</span>
                                            <span class="buttonFormat">{{ commonParams[gameTableService.gameTable.controlsType] }}</span>
                                            <div class="val" *ngIf="gameTableService.gameTable.displayValues.length > 0">
                                                <!-- <span>{{gameTableService.gameTable.pot.gameCurrency}}</span> -->
                                                <span class="val">{{ gameTableService.gameTable.displayValues[0] }}</span>
                                            </div>
                                        </a>

                                        <a class="bet2" (click)="gameTableService.bet('1')">
                                            <span class="smallbuttonText">{{ gameTableService.gameTable.smallControlls[1] }}</span>
                                            <span class="buttonFormat">{{ commonParams[gameTableService.gameTable.controlsType] }}</span>
                                            <div class="val" *ngIf="gameTableService.gameTable.displayValues.length > 0">
                                                <!-- <span>{{gameTableService.gameTable.pot.gameCurrency}}</span> -->
                                                <span class="val">{{ gameTableService.gameTable.displayValues[1] }}</span>
                                            </div>
                                        </a>
                                        <a
                                            class="bet3"
                                            *ngIf="
                                                gameTableService.gameTable.smallControlls[2] !== 'POT' &&
                                                gameTableService.gameTable.smallControlls[2] !== 'ALL IN'
                                            "
                                            (click)="gameTableService.bet('2')">
                                            <span class="smallbuttonText">{{ gameTableService.gameTable.smallControlls[2] }}</span>
                                            <span class="buttonFormat">{{ commonParams[gameTableService.gameTable.controlsType] }}</span>
                                            <div class="val" *ngIf="gameTableService.gameTable.displayValues.length > 0">
                                                <span class="val">{{ gameTableService.gameTable.displayValues[2] }}</span>
                                            </div>
                                        </a>

                                        <a
                                            class="bet3"
                                            *ngIf="gameTableService.gameTable.smallControlls[2] === 'POT'"
                                            (click)="gameTableService.bet(gameTableService.gameTable.displayValues[2])">
                                            <span class="pot">{{ commonParams['POT'] }}</span>
                                            <div class="val" *ngIf="gameTableService.gameTable.displayValues.length > 0">
                                                <span class="val">{{ gameTableService.gameTable.displayValues[2] }}</span>
                                            </div>
                                        </a>

                                        <a
                                            class="bet3"
                                            *ngIf="gameTableService.gameTable.smallControlls[2] === 'ALL IN'"
                                            (click)="gameTableService.bet('2')">
                                            <span class="pot allin">{{ commonParams['ALL IN'] }}</span>
                                            <div class="val" *ngIf="gameTableService.gameTable.displayValues.length > 0">
                                                <span>{{ gameTableService.gameTable.displayValues[2] }}</span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="demoControls">
                                        <a
                                            class="fold"
                                            *ngIf="gameTableService.gameTable.bigControlls[0] !== ''"
                                            (click)="gameTableService.next(gameTableService.gameTable.bigControlls[0])">
                                            <span class="buttonText">{{ commonParams[gameTableService.gameTable.bigControlls[0]] }}</span>
                                            <div
                                                class="val"
                                                *ngIf="
                                                    gameTableService.gameTable.displayValues.length > 0 &&
                                                    gameTableService.gameTable.displayValues[0] !== '' &&
                                                    gameTableService.gameTable.smallControlls.length === 0
                                                ">
                                                <span>{{ gameTableService.gameTable.displayValues[0] }}</span>
                                            </div>
                                        </a>
                                        <a
                                            class="raise"
                                            *ngIf="gameTableService.gameTable.bigControlls[1] !== ''"
                                            (click)="gameTableService.next(gameTableService.gameTable.bigControlls[1])">
                                            <span class="buttonText">{{ commonParams[gameTableService.gameTable.bigControlls[1]] }}</span>
                                            <div
                                                class="val"
                                                *ngIf="
                                                    gameTableService.gameTable.displayValues.length > 0 &&
                                                    gameTableService.gameTable.displayValues[1] !== '' &&
                                                    gameTableService.gameTable.smallControlls.length === 0
                                                ">
                                                <span>{{ gameTableService.gameTable.displayValues[1] }}</span>
                                            </div>
                                        </a>
                                        <a
                                            class="call"
                                            *ngIf="gameTableService.gameTable.bigControlls[2] !== ''"
                                            (click)="gameTableService.next(gameTableService.gameTable.bigControlls[2])">
                                            <span class="buttonText">{{ commonParams[gameTableService.gameTable.bigControlls[2]] }}</span>
                                            <div
                                                class="val"
                                                *ngIf="
                                                    gameTableService.gameTable.displayValues.length > 0 &&
                                                    gameTableService.gameTable.displayValues[2] !== '' &&
                                                    gameTableService.gameTable.smallControlls.length === 0
                                                ">
                                                <span>{{ gameTableService.gameTable.displayValues[2] }}</span>
                                            </div>
                                        </a>

                                        <div class="customBet" *ngIf="gameTableService.gameTable.showCustomBetMsg">
                                            <div class="square">
                                                {{ commonParams.custom_bet_mesg }}<span>{{ commonParams.custom_bet_mesg1 }}</span>
                                            </div>
                                            <div class="triangle-up"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="wiz-modal wiz-modal-zoom player-info-modal modal1" *ngIf="playerInfo" id="wizModalInfo">
                        <div class="close-btn theme-ex close-simu-btn" *ngIf="!infoRibbon && !invokerApp" (click)="closeGameTable($event)"></div>
                        <div
                            (click)="showTooltip($event)"
                            [ngClass]="{ 'hide-glossary': !showGlossary }"
                            class="wiz-speech"
                            [innerHTML]="commonParams['playerInfo'] | trustAsHtml"></div>
                        <div class="wiz-static"></div>
                        <a
                            class="skip-to-download"
                            *ngIf="!invokerApp"
                            [href]="item?.titleLink?.url"
                            (click)="trackingSkipToDownloadClickEvent(item?.titleLink?.url, commonParams.Skip_to_Download)"
                            >{{ commonParams.Skip_to_Download }}</a
                        >
                        <a class="skip-to-download" *ngIf="invokerApp" (click)="sendNativeEvent()">{{ commonParams.go_to_lobby }}</a>
                        <div class="next-hand-btn-wrap">
                            <a
                                class="btn btn-primary"
                                *ngIf="counter < item.gameHandsJsons.length"
                                (click)="trackingPlayTutorialNextHandCTA(commonParams.playTutorial); startNewGame()"
                                >{{ commonParams.playTutorial }}</a
                            >
                        </div>
                    </div>

                    <ng-container *ngIf="gameTableService.gtInitializeDone" id="wizModalInfo">
                        <div class="wiz-modal wiz-modal-zoom player-info-modal modal2" *ngIf="gameTableService.gameTable.gameInfo">
                            <div class="close-btn theme-ex close-simu-btn" *ngIf="!infoRibbon && !invokerApp" (click)="closeGameTable($event)"></div>
                            <div class="info-ribbon" *ngIf="infoRibbon && checkInsight(parameters.preGameInfo)">
                                {{ commonParams.tab_word1 }} <span class="close-info theme-ex mobile" (click)="closeInfoRibbon()">&nbsp;</span>
                            </div>
                            <div
                                class="wiz-speech"
                                [ngClass]="{ 'hide-glossary': !showGlossary }"
                                [innerHTML]="parameters.preGameInfo | trustAsHtml"
                                (click)="showTooltip($event)"></div>
                            <div class="wiz-static"></div>
                            <a
                                class="skip-to-download"
                                *ngIf="!invokerApp"
                                [href]="item?.titleLink?.url"
                                (click)="trackingSkipToDownloadClickEvent(item?.titleLink?.url, commonParams.Skip_to_Download)"
                                >{{ commonParams.Skip_to_Download }}</a
                            >
                            <a class="skip-to-download" *ngIf="invokerApp" (click)="sendNativeEvent()">{{ commonParams.go_to_lobby }}</a>
                            <div class="next-hand-btn-wrap">
                                <a class="btn btn-primary" (click)="ContinuePlay(); trackingTryAgainContdCTA(commonParams.Continue, counter)">{{
                                    commonParams.Continue
                                }}</a>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Reward modal -->
                    <section class="psimu-get-started final-reward-modal modal3" *ngIf="counter >= item.gameHandsJsons.length && finalScreen">
                        <div id="reward" class="wiz-modal-zoom">
                            <div class="psimu-get-started-main">
                                <div class="psimu-wiz-content">
                                    <h2>{{ item?.parameters?.finalTitleOne }}</h2>
                                    <h4>{{ item?.parameters?.finalTitleTwo }}</h4>
                                    <h5 *ngIf="!invokerApp" [innerHTML]="item?.parameters?.finalDownloadtext"></h5>
                                    <h5 *ngIf="invokerApp" [innerHTML]="item?.parameters?.finalDownloadtextNative"></h5>

                                    <div class="psimu-wiz">
                                        <img src="https://scmedia.itsfogo.com/$-$/33f88d3a774846a58b3e46b7a69fd2bb.png" alt="Simulated Demo" />
                                    </div>
                                </div>
                                <div class="psimu-get-started-bottom">
                                    <div class="psimu-check-buttons">
                                        <a
                                            class="btn btn-primary"
                                            *ngIf="isAndroid && !invokerApp"
                                            [href]="item?.parameters?.downloadFinalScreen"
                                            (click)="
                                                trackingClickEventGoogleAppStore(
                                                    item?.parameters?.downloadFinalScreen,
                                                    item?.parameters?.googlePlayCtaText ? item?.parameters?.googlePlayCtaText : 'Download now'
                                                )
                                            ">
                                            {{ item?.parameters?.googlePlayCtaText }}
                                        </a>
                                        <a
                                            class="btn btn-primary"
                                            *ngIf="!isAndroid && !invokerApp"
                                            [href]="item?.parameters?.downloadFinalScreen"
                                            (click)="
                                                trackingClickEventGoogleAppStore(
                                                    item?.parameters?.downloadFinalScreen,
                                                    item?.parameters?.appStoreCtaText ? item?.parameters?.appStoreCtaText : 'Download now'
                                                )
                                            ">
                                            {{ item?.parameters?.appStoreCtaText || 'Send me to App Store' }}
                                        </a>

                                        <a class="btn btn-primary" *ngIf="invokerApp" (click)="sendNativeEvent()">
                                            {{ item?.parameters?.lobby || 'Lobby' }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div
                        class="bottom-menu-items rank-popup"
                        [ngClass]="{ 'disable': gameTableService.gameTable.showCustomBetMsg, 'rank-popup-enabled': enblepop }"
                        id="popUp"
                        *ngIf="gameTableService.gtInitializeDone">
                        <span class="info" (click)="enblepop = true"> </span>
                        <div class="info-popup-block" *ngIf="enblepop">
                            <div class="rank-info-block"></div>
                            <a class="close-icon" (click)="enblepop = false"></a>
                        </div>
                        <span class="emoji"></span>
                        <span class="smreplayer"></span>
                        <span class="tileview"></span>
                    </div>
                    <div
                        class="download-button-wrap"
                        *ngIf="
                            !nativeApp &&
                            isDownloadVisible &&
                            gameTableService.gtInitializeDone &&
                            !gameTableService.gameTable.animationType &&
                            !gameTableService.gameTable.gameInfo
                        ">
                        <a
                            class="btn btn-primary btn-sm"
                            [href]="item?.titleLink?.url"
                            (click)="trackingClickEvent(item?.titleLink?.url, item?.titleLink?.text || 'Download now', 'cta')"
                            >{{ item?.titleLink?.text || 'Download now' }}</a
                        >
                    </div>
                </div>
            </div>
            <ng-container *ngIf="gameTableService.gtInitializeDone && !finalScreen && isShowWizAnimation">
                <!-- Animation 1-->
                <div
                    class="wiz-modal wiz-modal-zoom wiz-animate-{{ gameTableService.gameTable.animationType }} modal4"
                    [ngClass]="{ 'd-none': !gameTableService.gameTable.isGameCompleted }"
                    id="wizModal">
                    <div class="close-btn theme-ex close-simu-btn" *ngIf="!infoRibbon && !invokerApp" (click)="closeGameTable($event)"></div>
                    <div class="info-ribbon" *ngIf="infoRibbon && checkInsight(parameters[gameTableService.gameTable.postMsg])">
                        {{ commonParams.tab_word1 }} <span class="close-info theme-ex mobile" (click)="closeInfoRibbon()">&nbsp;</span>
                    </div>
                    <div
                        [ngClass]="{ 'hide-glossary': !showGlossary }"
                        (click)="showTooltip($event)"
                        class="wiz-speech"
                        [ngClass]="gameTableService.gameTable.show_bubble ? 'show-wiz-speech' : 'hide-wiz-speech'"
                        [innerHTML]="parameters[gameTableService.gameTable.postMsg] | trustAsHtml"></div>
                    <div class="wiz-animate"></div>
                    <div class="wiz-animate wiz-animate-loop"></div>
                    <a
                        class="skip-to-download"
                        *ngIf="!invokerApp"
                        [href]="item?.titleLink?.url"
                        (click)="trackingClickEvent(item?.titleLink?.url, commonParams.Skip_to_Download)"
                        >{{ commonParams.Skip_to_Download }}</a
                    >
                    <a class="skip-to-download" *ngIf="invokerApp" (click)="sendNativeEvent()">{{ commonParams.go_to_lobby }}</a>
                    <div class="next-hand-btn-wrap">
                        <a class="btn btn-primary try-again-btn" (click)="tryAgain(); trackingTryAgainContdCTA(commonParams.tryAgain, counter)">{{
                            commonParams.tryAgain
                        }}</a>
                        <a
                            class="btn btn-primary"
                            *ngIf="counter < item.gameHandsJsons.length"
                            (click)="trackingPlayTutorialNextHandCTA(commonParams.NextHand); startNewGame()"
                            >{{ commonParams.NextHand }}</a
                        >
                        <a
                            class="btn btn-primary"
                            *ngIf="counter >= item.gameHandsJsons.length"
                            (click)="showFinalScreen(); trackingTryAgainContdCTA(commonParams.Continue, counter)"
                            >{{ commonParams.Continue }}</a
                        >
                    </div>
                </div>
                <span class="g-tip" [ngClass]="{ 'd-none': !tooltipToggle && tooltipText === '' }" #gTip>&nbsp;</span>

                <div class="g-tooltip" (click)="handleTooltip($event)" [ngClass]="{ 'd-none': !tooltipToggle && tooltipText === '' }" #gTooltip>
                    <div class="g-tooltip-content">
                        <span class="close-tooltip theme-ex mobile" (click)="closeTooltip()">&nbsp;</span>
                        <div [innerHTML]="tooltipText"></div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="gameTableService.gtInitializeDone && !finalScreen && !isShowWizAnimation && gameTableService.gameTable.isGameCompleted">
                <div class="wiz-modal wiz-modal-zoom wiz-animate-{{ gameTableService.gameTable.animationType }} modal5" id="wizModal">
                    <a
                        class="skip-to-download"
                        *ngIf="!invokerApp"
                        [href]="item?.titleLink?.url"
                        (click)="trackingClickEvent(item?.titleLink?.url, commonParams.Skip_to_Download)"
                        >{{ commonParams.Skip_to_Download }}</a
                    >
                    <a class="skip-to-download" *ngIf="invokerApp" (click)="sendNativeEvent()">{{ commonParams.go_to_lobby }}</a>
                    <div class="next-hand-btn-wrap">
                        <a class="btn btn-primary try-again-btn" (click)="tryAgain(); trackingTryAgainContdCTA(commonParams.tryAgain, counter)">{{
                            commonParams.tryAgain
                        }}</a>
                        <a
                            class="btn btn-primary"
                            *ngIf="counter < item.gameHandsJsons.length"
                            (click)="startNewGame(); trackingPlayTutorialNextHandCTA(commonParams.NextHand)"
                            >{{ commonParams.NextHand }}</a
                        >
                        <a
                            class="btn btn-primary"
                            *ngIf="counter >= item.gameHandsJsons.length"
                            (click)="showFinalScreen(); trackingTryAgainContdCTA(commonParams.Continue, counter)"
                            >{{ commonParams.Continue }}</a
                        >
                    </div>
                </div>
            </ng-container>
        </div>
    </body>
</html>
