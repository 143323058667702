<div class="event-schedule-wrapper" id="event-schedule-wrapper">
    <div class="schedule-header">
        <div class="title">
            <h3>
                {{ item.eventScheduleStaticData.fullFestivalSchedule | titlecase }}
            </h3>
            <div class="social-buttons">
                <span
                    class="theme-filter-v2 s-icon btn btn-tertiary btn-sm"
                    [title]="item.eventScheduleStaticData.filters | titlecase"
                    (click)="displayFilters()">
                    <span class="d-none d-sm-inline-flex">
                        {{ item.eventScheduleStaticData.filters | titlecase }}
                    </span>
                </span>
                <span
                    class="theme-print s-icon btn btn-tertiary btn-sm"
                    [title]="item.eventScheduleStaticData.print | titlecase"
                    (click)="printWindow()">
                    <span class="d-none d-sm-inline-flex">
                        {{ item.eventScheduleStaticData.print | titlecase }}
                    </span>
                </span>
            </div>
        </div>
        <div class="schedule-filters flex-column flex-lg-row justify-content-lg-end" [class.d-flex]="showFilters === true">
            <div class="d-flex justify-content-center align-items-center m-2 mt-lg-0 mb-lg-0 ml-lg-5 mr-lg-5">
                <label class="m-0 mr-2 font-weight-bold">{{ item.eventScheduleStaticData.show }} : </label>
                <label class="m-0 check-box-label">
                    <input
                        type="checkbox"
                        name="showCompletedEvents"
                        [value]="showPastEvents"
                        (change)="showCompletedEvents($event.target.checked)" />
                    <span class="checkmark"></span>
                    {{ item.eventScheduleStaticData.completed }}
                </label>
            </div>
            <div class="filter-by-wrapper d-flex flex-column flex-lg-row flex-lg-grow-1">
                <div class="filter-by mt-lg-2 mb-lg-2 font-weight-bold d-none d-lg-flex justify-content-center align-items-center">
                    {{ item.eventScheduleStaticData.filterBy }} :
                </div>
                <div class="filter-by-items d-flex flex-column flex-md-row flex-lg-grow-1">
                    <div
                        class="flex-sm-fill m-2 mt-lg-0 mb-lg-0 position-relative schedule-dropDown-wrapper ppl-cursor-pointer"
                        *ngFor="let filterItem of scheduleFilters">
                        <div class="dropDown-title">
                            <span class="d-inline d-lg-none">{{ item.eventScheduleStaticData.filterBy | titlecase }} </span>
                            {{ item.eventScheduleStaticData[filterItem.key] | titlecase }}
                            <span class="theme-right"></span>
                        </div>
                        <div class="dropDown-options">
                            <label class="check-box-label" *ngFor="let option of filterItem.options"
                                >{{ item.eventScheduleStaticData[option.key] || option.key | titlecase }}
                                <input
                                    type="checkbox"
                                    [name]="option.value"
                                    [value]="option.value"
                                    (change)="returnSelectedItem($event, filterItem.key)" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="schedule-table-header hide-in-mobile">
        <span *ngFor="let headerItem of scheduleTableHeaders">
            {{ item.eventScheduleStaticData[headerItem] | uppercase }}
        </span>
    </div>
    <div *ngIf="ffSchedules.length !== 0">
        <div class="schedule-table-body" *ngFor="let key of ffSchedulesKeys">
            <h6 class="schedule-table-title">
                {{ eventGroupHeader(key) }}
            </h6>
            <div
                class="schedule-table-data"
                *ngFor="let event of ffSchedules[key]"
                [ngStyle]="{
                    'background-color': event.hexColor + '10'
                }">
                <div>
                    <span class="hide-in-mobile">{{ returnScheduleTime(event.time) }}</span>
                    <span class="show-in-mobile">
                        {{ item.eventScheduleStaticData.s | uppercase }}:
                        <span> {{ returnScheduleTime(event.time) }}</span>
                        <br />
                        {{ item.eventScheduleStaticData.lr | uppercase }}:
                        <span> {{ returnScheduleTime(event.lateReg) }}</span>
                    </span>
                </div>
                <div class="hide-in-mobile">
                    <span>{{ returnScheduleTime(event.lateReg) }}</span>
                </div>
                <div class="event-type-rank-name">
                    <span
                        [ngStyle]="{
                            'background-color': event.hexColor
                        }"
                        >#{{ event.rank }}</span
                    >
                    <span
                        [ngStyle]="{
                            'background-color': event.scheduleType === 'Live' ? '#000000' : '#ff8c00'
                        }"
                        >{{ event.scheduleType }}</span
                    >
                    <span> - {{ event.eventname }}</span>
                </div>
                <div>
                    <span class="show-in-mobile">{{ item.eventScheduleStaticData.buyIn }}:</span>
                    <span>{{ event.buyin }}</span>
                </div>
                <div>
                    <span class="show-in-mobile">{{ item.eventScheduleStaticData.clock }}:</span>
                    <span>{{ event.clock }}</span>
                </div>
                <div>
                    <span class="show-in-mobile">{{ item.eventScheduleStaticData.chips }}:</span>
                    <span>{{ event.chips }}</span>
                </div>
                <div>
                    <span class="show-in-mobile">{{ item.eventScheduleStaticData.format }}:</span>
                    <span>{{ event.format }}</span>
                </div>
                <div>
                    <span class="show-in-mobile"> {{ item.eventScheduleStaticData.register }}: </span>
                    <div *ngIf="event.scheduleType === 'Online'">
                        <button class="btn btn-primary btn-sm" (click)="openClient(event)">{{ item.eventScheduleStaticData.register }}</button>
                    </div>
                    <div *ngIf="event.scheduleType === 'Live'">-</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ffSchedules.length === 0">
        <p class="text-center m-5">
            {{ item.eventScheduleStaticData.sorry }},
            {{ item.eventScheduleStaticData.noResultsFound }}
        </p>
    </div>
</div>
