import { Message } from '../../message';

export class LSTourneyBlindStructureDetails extends Message {
    private MESSAGE_NAME: string = 'LSTourneyBlindStructureDetails';
    private blindStructureDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, blindStructureDetails: any[] | null = null) {
        super(reqServerPeerId);
        this.blindStructureDetails = blindStructureDetails;
    }

    getBlindStructureDetails(): any[] | null {
        return this.blindStructureDetails;
    }

    setBlindStructureDetails(blindStructureDetails: any[] | null) {
        this.blindStructureDetails = blindStructureDetails;
    }
}
