import { Message } from '../../message';

export class AutoOptionSet extends Message {
    private MESSAGE_NAME: string = 'AutoOptionSet';
    private selectedAutoOptionId: number = 0;
    private autoOptionSet: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, selectedAutoOptionId: number = 0, autoOptionSet: any[] | null = null) {
        super(reqServerPeerId);
        this.selectedAutoOptionId = selectedAutoOptionId;
        this.autoOptionSet = autoOptionSet;
    }

    getSelectedAutoOptionId(): number {
        return this.selectedAutoOptionId;
    }

    setSelectedAutoOptionId(selectedAutoOptionId: number) {
        this.selectedAutoOptionId = selectedAutoOptionId;
    }
    getAutoOptionSet(): any[] | null {
        return this.autoOptionSet;
    }

    setAutoOptionSet(autoOptionSet: any[] | null) {
        this.autoOptionSet = autoOptionSet;
    }
}
