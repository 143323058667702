import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Page, TrackingService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { SimulatedService } from '../simulated-hands.service';

@Component({
    selector: 'pk-demo-video',
    templateUrl: './demo-video.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class DemoVideoComponent implements OnInit {
    @Output() passSkipDemo = new EventEmitter<any>();
    @Input() item: any;
    @ViewChild('demoVideo', { static: false }) videoPlayer: any;

    pauseTimeFrames = [
        2, 8, 12, 16, 21, 24, 32, 36, 41, 45, 48, 57, 60, 62, 65, 68, 71, 73, 76, 78, 81, 85, 87, 92, 99, 105, 110, 113, 118, 122, 125, 136,
    ]; // in seconds
    insights = [
        {
            time: 6,
            animationType: 1,
            message:
                'Welcome to the poker table! <br><br>I’m going to show you how poker works, so you’ll be ready to jump onto the tables and play against other Whiz’s like me!',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step1',
        },
        {
            time: 8,
            animationType: 1,
            message:
                'In this tutorial, we will run through a hand of Texas Hold’em. This is a poker variant where each player is dealt two cards along with five community cards which will be shared amongst all players. The aim is to make the best five-card poker hand, using any combination of your two cards and the five community cards.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step2',
        },
        {
            time: 11,
            animationType: 2,
            message: 'In each poker game, there will be a dealer button. This signifies the order in which players will take turns.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step5',
        },
        {
            time: 15,
            animationType: 2,
            message:
                'The player directly to the left of the button will act first on any betting round. At the end of each hand, the dealer button is moved clockwise to the next player.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step5',
        },
        {
            time: 18,
            animationType: 2,
            message:
                'The two players to the direct left of the dealer button, known as the small blind and the big blind, will have to post forced bets called blinds before any cards are dealt. <br><br>This is to ensure that there is something in the pot to play for at the start of each hand.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step5',
        },
        {
            time: 23,
            animationType: 2,
            message:
                'Each player is dealt two cards, called hole cards. Yours are K ♦ K ♣ for this hand. These are your unique cards. Other players can’t see them, nor use them to make their hand.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step6',
        },
        {
            time: 31,
            animationType: 2,
            message: 'Another player has raised to $2.50. You now have three options.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step7',
        },
        {
            time: 35,
            animationType: 2,
            message: 'You can either Fold – this means you will put no more chips in, and will no longer be in this hand.',
            animationDirection: 'right',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step8',
        },
        {
            time: 39,
            animationType: 2,
            message: 'Call – match the amount of the most recent bet or raise on this betting round. Here, that would be $2.50.',
            animationDirection: 'right',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step9',
        },
        {
            time: 43,
            animationType: 2,
            message:
                'Or Raise – put more chips into the pot than the last player who didn’t fold. You usually raise when you have a strong hand or want to bluff. Bluffing is an important part of poker strategy, as you won’t always have a hand strong enough to beat your opponent’s most likely holdings.',
            animationDirection: 'right',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step10',
        },
        {
            time: 47,
            animationType: 2,
            message: 'However, in this situation, KK is a strong hand, so let’s put in some more chips and raise to $7.50 here. ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step11',
        },
        {
            time: 54,
            animationType: 2,
            message:
                'Your opponent has called your raise, which concludes the first round of betting (called preflop). Now, three community cards will be dealt. ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step12',
        },
        {
            time: 59,
            animationType: 2,
            message:
                'These three cards are called the Flop. <br><br>Each player can use as many of the community cards as they like, in combination with their unique hole cards to make the best five card hand.',
            animationDirection: 'right',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step13',
        },
        {
            time: 62,
            animationType: 2,
            message: 'Now there will be a round of betting. ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step14',
        },
        {
            time: 64,
            animationType: 2,
            message: 'Your opponent has checked, this means that no-one has made a bet on this betting round before us, which gives you two options.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step15',
        },
        {
            time: 67,
            animationType: 2,
            message:
                'You can Check – which means you put no chips in the middle and both players will see the next community card for free. You have the option to do this because no-one else has made a bet on this betting round.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step16',
        },
        {
            time: 70,
            animationType: 2,
            message:
                'Or you can Bet - Just like when you raise, you put more chips into the pot, which your opponent will either have to re-raise or call to continue in the hand, if they fold, then you win right away!',
            animationDirection: 'right',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step17',
        },
        {
            time: 73,
            animationType: 2,
            message: 'Your opponent could make a better hand when the next card comes, but right now you definitely have the best hand.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step18',
        },
        {
            time: 73,
            animationType: 2,
            message:
                'You have three of a kind here (the two Kings in our hole cards and one King on the board). This is the best possible hand right now, because there are no possible straights or flushes with the cards on the board.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step19',
        },
        {
            time: 78,
            animationType: 2,
            message: 'You can see a list of the hand rankings by clicking the info icon here.',
            animationDirection: 'right',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step20',
        },
        {
            time: 81,
            animationType: 0,
            message: null,
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step21',
        },
        {
            time: 84,
            animationType: 2,
            message: 'Since we know we have the best hand, let’s to bet and hope our opponent calls! ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step22',
        },
        {
            time: 86,
            animationType: 2,
            message: 'Raising or betting in poker is “aggressive” play, whereas checking and calling is considered “passive”. ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step23',
        },
        {
            time: 91,
            animationType: 2,
            message: 'One more community card has been dealt. This is called the Turn.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step24',
        },
        {
            time: 98,
            animationType: 2,
            message:
                'Your opponent has checked to you. The turn card didn’t improve your hand and we still have three of a kind, but it’s still good enough to bet, so let’s do that!',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step25',
        },
        {
            time: 104,
            animationType: 2,
            message: 'The last community card has been dealt – this is called the River.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step26',
        },
        {
            time: 109,
            animationType: 2,
            message: 'This card improved you to a full house. Remember, you can look at the hand rankings here.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step27',
        },
        {
            time: 112,
            animationType: 2,
            message:
                'There is one more round of betting left and your opponent has checked to you. Let’s bet all of your chips (also known as All-In) and hope that our opponent calls.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step28',
        },
        {
            time: 118,
            animationType: 2,
            message:
                'Your opponent has called and now you are at showdown. This is where both players will show their cards and the player with the best hand will win. ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step29',
        },
        {
            time: 121,
            animationType: 2,
            message: 'Here, your opponent has three of a kind, using the 7 in their hole cards and the two sevens on the board.',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step30',
        },
        {
            time: 124,
            animationType: 2,
            message: 'A full house is better than three of a kind, so you win the pot! ',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step31',
        },
        {
            time: 130,
            animationType: 2,
            message: 'Now you know your way around the game, why don’t you try playing a few hands in my demo? See you there!',
            animationDirection: 'left',
            positions: {
                x: 20,
                y: 30,
            },
            extraClass: 'ani-step32',
        },
    ];
    currentTime: any;
    pauseFlag: boolean = true;
    public nativeApp: boolean = false;
    public commonParams: any;
    public videoStart: boolean = true;
    public videoEnd: boolean = false;
    public isAndroid: boolean = false;
    public playReminder: boolean = false;
    public clrIntervel: any;
    public insight: any;
    public showInsight: boolean = false;
    public showLoopAnimate: boolean = false;
    public subscription: Subscription;

    constructor(
        public serve: SimulatedService,
        private vnPage: Page,
        public trackingService: TrackingService,
    ) {}
    ngOnInit(): void {
        this.nativeApp = this.serve.nativeApp;
        this.commonParams = this.item.parameters;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.subscription = this.serve.informStatus$.subscribe((_) => {
            this.getVideo();
        });
    }

    ngAfterViewInit() {
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Android/)) {
            this.isAndroid = true;
        }
        this.getVideo();
    }

    getVideo() {
        setTimeout(() => {
            this.videoPlayer.nativeElement.currentTime = 5;
            this.videoPlayer.nativeElement.play();
            this.videoPlayer.nativeElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
        }, 500);
    }

    onTimeUpdate() {
        this.currentTime = Math.floor(this.videoPlayer.nativeElement.currentTime);
        this.playReminder = false;

        const matchingFrame = this.insights.find((frame) => frame.time === this.currentTime);

        if (matchingFrame && !this.pauseFlag) {
            this.insight = matchingFrame;
            this.showInsight = true;
            this.videoPlayer.nativeElement.pause();
            this.pauseFlag = true;
            this.playReminderUser();

            // Hold time for second animation
            const holdTimeOptions = {
                2: 1000,
                8: 1000,
            };

            const holdTime = holdTimeOptions[this.insight?.time] || 450;

            setTimeout(() => {
                this.showLoopAnimate = true;
            }, holdTime);

            if (this.currentTime === 130) {
                this.videoEnd = true;
                if (this.clrIntervel) {
                    clearTimeout(this.clrIntervel);
                }
            }
        } else if (!matchingFrame && this.pauseFlag) {
            this.pauseFlag = false;
            if (this.clrIntervel) {
                clearTimeout(this.clrIntervel);
            }
        }
    }

    playVideo() {
        if (this.videoEnd) return;
        this.videoPlayer.nativeElement.play();
        this.videoStart = false;
        this.showInsight = false;
        this.showLoopAnimate = false;
    }

    playReminderUser() {
        this.clrIntervel = setTimeout(() => {
            this.playReminder = true;
        }, 1500);
    }

    skipDemo() {
        this.passSkipDemo.emit();
    }

    closeGameTable() {
        this.trackingCloseButton();
        window.open(this.vnPage.homePage, '_self');
    }

    // ------GTM tracking start-------

    trackingCloseButton() {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'demo video screen',
            'component.EventDetails': 'close icon',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingSkipVideo(text: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //hand num or not applicable
            'component.LocationEvent': 'demo video screen',
            'component.EventDetails': text + ' link',
            'component.URLClicked': 'not applicable',
        });
    }

    trackingSkipToDownloadClick(url: any, urltext: any) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //hand num or not applicable
            'component.LocationEvent': 'demo video screen',
            'component.EventDetails': urltext + ' link',
            'component.URLClicked': url,
        });
    }

    trackingPlayTutorial(text: any, cta: string) {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'poker onboarding',
            'component.LabelEvent': 'sim hands',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', // hand num or not applicable
            'component.LocationEvent': 'demo video screen',
            'component.EventDetails': text + ' ' + cta,
            'component.URLClicked': 'not applicable',
        });
    }
    // ------GTM tracking end-------

    ngAfterViewChecked() {
        const width = window.innerWidth;
        const ele: any = document.querySelectorAll('.wiz-modal-zoom');
        let zoomValue = width / 500;
        if (zoomValue > 1.46) {
            zoomValue = 1.46;
        }
        if (ele && ele.length != 0) {
            ele.forEach((modal: any) => {
                modal.style.zoom = zoomValue;
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }
}
