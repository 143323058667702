import { Message } from '../../message';

export class PromptScreenName extends Message {
    private MESSAGE_NAME: string = 'PromptScreenName';
    private requestId: number = 0;
    private suggestedScreenName: string | null = null;
    private desc: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestId: number = 0, suggestedScreenName: string | null = null, desc: any | null = null) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.suggestedScreenName = suggestedScreenName;
        this.desc = desc;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getSuggestedScreenName(): string | null {
        return this.suggestedScreenName;
    }

    setSuggestedScreenName(suggestedScreenName: string | null) {
        this.suggestedScreenName = suggestedScreenName;
    }
    getDesc(): any | null {
        return this.desc;
    }

    setDesc(desc: any | null) {
        this.desc = desc;
    }
}
