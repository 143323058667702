import { Routes } from '@angular/router';

import { PokerWptOnlineSeriesSynopsisResolver } from './poker-wpt-online-series-synopsis.resolver';
import { PokerWptOnlineSeriesResolver } from './poker-wpt-online-series.resolver';

export const pokerWptOnlineSeriesRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWptOnlineSeriesResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerWptOnlineSeriesSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerWptOnlineSeriesResolver,
        },
    },
];
