import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PokerApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class PokerTournamentRibbonService {
    constructor(private api: PokerApiService) {}

    getTournamentFeedData(ids?: string, lateRegistrationInMinutes?: string): Observable<any> {
        return this.api.get('tournamentTimer/GetTournamentFeedData', { ids: ids, lateRegistrationInMinutes: lateRegistrationInMinutes });
    }
}
