import { Routes } from '@angular/router';

import { PokerOmahaDailyLegendsSynopsisResolver } from './poker-omaha-daily-legends-synopsis.resolver';
import { PokerOmahaDailyLegendsResolver } from './poker-omaha-daily-legends.resolver';

export const pokerOmahaDailyLegendsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerOmahaDailyLegendsResolver,
        },
    },
    {
        path: 'promotions',
        loadComponent: () => import('./poker-promotions-synopsis.component').then((x) => x.PokerPromotionsSynopsisComponent),
        resolve: {
            initData: PokerOmahaDailyLegendsSynopsisResolver,
        },
    },
    {
        path: ':folderId',
        loadComponent: () => import('./poker-promotions.component').then((x) => x.PokerPromotionsComponent),
        resolve: {
            initData: PokerOmahaDailyLegendsResolver,
        },
    },
];
