import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { ClientSubHeaderModel } from '../../models/client-sub-header.model';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { userIsAuthenticatedSelect } from '../../store/user.store';
import { ClientHeaderBalanceComponent } from '../client-header-balance/client-header-balance.component';

@Component({
    selector: 'pwc-client-sub-header',
    templateUrl: 'client-sub-header.component.html',
    standalone: true,
    imports: [CommonModule, ClientHeaderBalanceComponent],
    providers: [],
})
export class ClientSubHeaderComponent {
    data: ClientSubHeaderModel;
    userIsAuthenticated = false;
    eventDispatcherGlobal = new EventDispatcherGlobal();
    private readonly logger: PokerLogger;
    constructor(
        private store: Store,
        private navigation: NavigationService,
        loggerFactory: LoggerFactory,
        private sharedService: PokerWebClientSharedService,
    ) {
        this.store.select(userIsAuthenticatedSelect).subscribe((authState) => {
            this.userIsAuthenticated = authState;
        });
        this.sharedService.getPageHeaderTitle().subscribe((info) => {
            this.data = info;
        });
        this.logger = loggerFactory.getLogger('ClientSubHeaderLoggingComponent');
    }

    goBack(url: string) {
        if (url) {
            this.logger.info('clicked on goback..!' + JSON.stringify(url));
            if (url == '/play/cash-filters') this.eventDispatcherGlobal.dispatchEvent('cashFilterNavigation', 0);
            else this.navigation.goTo(url);
        } else {
            this.navigation.goTo('/play');
        }
    }
}
