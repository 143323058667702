import { Message } from '../../message';

export class PlayerTourneyRank extends Message {
    private MESSAGE_NAME: string = 'PlayerTourneyRank';
    private screenName: string | null = null;
    private rank: number = 0;
    private winAmount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor(reqServerPeerId: number = 0, screenName: string | null = null, rank: number = 0, winAmount: number = 0) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.rank = rank;
        this.winAmount = winAmount;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getWinAmount(): number {
        return this.winAmount;
    }

    setWinAmount(winAmount: number) {
        this.winAmount = winAmount;
    }
}
