// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class RegistrationView extends PIXI.Container {
    viewModel: any;
    EventDispatcher: any;
    positionConfig: any = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait;
    fontProps: any = uiConfig.font.props;
    displayUtil: DisplayUtil = new DisplayUtil();
    waitingForPlayersContainer: any;
    rectangleBG: any;
    waitingMessage: any;
    constructor() {
        super();
    }

    createView(EventDispatcher, parentViewModel) {
        this.viewModel = parentViewModel;
        this.EventDispatcher = EventDispatcher;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait;
        this.fontProps = uiConfig.font.props;
        /*this.background = DisplayUtil.getRectangleSprite(uiConfig.windowWidth, uiConfig.windowHeight, 0x000000);
          this.background.x = uiConfig.windowWidth/2;
          this.background.y = uiConfig.windowHeight/2;
          this.background.pivot.x = this.background.width/2;
          this.background.pivot.y = this.background.height/2;
          this.background.alpha = 0.2;
          this.addChild(this.background);*/
        if (this.viewModel.gameService.gTableType == 0) {
            this.createRegistrationSuccessMessage();
            this.createUnregisterButton();
        }
        ResponsiveContainer.call(this, {
            portX: 0,
            portY: 0,
            EventDispatcher: this.EventDispatcher,
        });
        // this.setSize(720, 1280);
    }
    createWaitingForPlayers() {
        this.waitingForPlayersContainer = this.displayUtil.getContainer();
        this.addChild(this.waitingForPlayersContainer);
        this.displayUtil.setProperties(this.waitingForPlayersContainer, this.positionConfig.registrationView.waitingForPlayersContainer);
        //Adjust to algin center
        this.displayUtil.setObjectPosition(
            this.waitingForPlayersContainer,
            this.positionConfig.registrationView.waitingForPlayersContainer.x,
            this.positionConfig.registrationView.waitingForPlayersContainer.y -
                uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.displacementToCenter.y,
        );
        //create rectangular bg
        this.rectangleBG = this.displayUtil.getBorderRoundRectangleSprite(340, 111, '0x000000', 8, true, 2, '0xffffff', 2);
        this.rectangleBG.alpha = 0.2;
        this.waitingForPlayersContainer.addChild(this.rectangleBG);
        this.displayUtil.setObjectPosition(this.rectangleBG, 720 / 2 - 340 / 2, 0);
        //create text
        this.waitingMessage = this.displayUtil.getTextField(this.fontProps.waitingTextStyle);
        this.waitingMessage.anchor.set(0.5);
        this.waitingMessage.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLEDLG_WAITINGFORPLAYERSMSG');
        this.waitingForPlayersContainer.addChild(this.waitingMessage);
        this.displayUtil.setObjectPosition(
            this.waitingMessage,
            this.rectangleBG.x + this.rectangleBG.width / 2,
            this.rectangleBG.y + this.rectangleBG.height / 2,
        );
    }
    createRegistrationSuccessMessage = function () {
        this.unRegisterContainer = this.displayUtil.getContainer();
        this.registrationSuccessHeader = this.displayUtil.getTextField(this.fontProps.registrationHeaderText);
        this.registrationSuccessHeader.anchor.x = 0.5;
        this.registrationSuccessHeader.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_REGISTERED');
        this.displayUtil.setObjectPosition(this.registrationSuccessHeader, 360, 0);
        this.unRegisterContainer.addChild(this.registrationSuccessHeader);
        this.unRegistrationMessage = this.displayUtil.getTextField(this.fontProps.unRegistrationMessageStyle);
        this.unRegistrationMessage.anchor.x = 0.5;
        this.unRegistrationMessage.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_TABLE_COMMON_UNREGMSG');
        // "You can unregister until the tournaments start"
        //);
        this.displayUtil.setObjectPosition(this.unRegistrationMessage, 360, 80);
        this.unRegisterContainer.addChild(this.unRegistrationMessage);
        //this.unRegisterContainer.y = (uiConfig.windowHeight - this.unRegisterContainer.height)/2 - 150
        this.displayUtil.setProperties(this.unRegisterContainer, this.positionConfig.registrationView.unRegisterContainer);
        this.addChild(this.unRegisterContainer);
    };
    createUnregisterButton = function () {
        /*var bgGraphics = DisplayUtil.getBorderRoundRectangleSprite( 500, 100, 0x000000, 25, true, 3, 0xffffff, 0.3);
          this.unRegisterButton = new PIXI.Sprite(bgGraphics.generateCanvasTexture());*/
        this.unRegisterButton = this.displayUtil.getButton('unRegister');
        this.unRegisterContainer.addChild(this.unRegisterButton);
        this.unRegisterLabel = this.displayUtil.getTextField(this.fontProps.unRegisterLabelStyle);
        this.unRegisterLabel.anchor.x = 0.5;
        this.unRegisterLabel.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_SPINS_UNREGISTER', '', 'UNREGISTER');
        this.unRegisterButton.addChild(this.unRegisterLabel);
        this.displayUtil.addInteraction(this.unRegisterButton, this.unRegisterBtnClick.bind(this));
        this.displayUtil.setClickable(this.unRegisterButton, true);
        /*DisplayUtil.setObjectPosition(this.unRegisterLabel, this.unRegisterButton.width/2, (this.unRegisterButton.height - this.unRegisterLabel.height)/2);
          this.unRegisterButton.addChild(this.unRegisterLabel);*/
        //DisplayUtil.setObjectPosition(this.unRegisterButton, 234, 750);
        //this.unRegisterButton.scale.set(1.4);
        this.displayUtil.setProperties(this.unRegisterLabel, this.positionConfig.registrationView.unregisterLabel);
        this.displayUtil.setProperties(this.unRegisterButton, this.positionConfig.registrationView.unregisterBtn);
    };
    unRegisterBtnClick = function () {
        // if (isTouch && context && context.resume) {
        //   context.resume().then(function () {});
        // }
        this.viewModel.unRegister();
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_EXPOSPIN_VID);
    };

    showWaitingForPlayers = function () {
        this.visible = true;
        if (!this.waitingForPlayersContainer) {
            this.createWaitingForPlayers();
        }
        this.waitingForPlayersContainer.visible = true;
    };
    hideWaitingForPlayers() {
        if (this.waitingForPlayersContainer) this.waitingForPlayersContainer.visible = false;
    }
}
