import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavigationBarComponent, NavigationBarPokerComponent, PageNotFoundComponent, PromotionsClientConfig } from '@pokercore/module/core';
import { PublicPageWrapperComponent } from '@pokercore/module/homepage';
import { PokerPromotionsPublicPageComponent, PokerPromotionsSynopsisPublicPageComponent } from '@pokercore/module/poker-promotions';

import { PokerApiService } from '../../api/api.service';

@Component({
    selector: 'pk-promotion-wrapper',
    templateUrl: 'promotion-wrapper.component.html',
    standalone: true,
    imports: [
        CommonModule,
        PokerPromotionsPublicPageComponent,
        PokerPromotionsSynopsisPublicPageComponent,
        PageNotFoundComponent,
        NavigationBarPokerComponent,
        NavigationBarComponent,
        // PublicPageWrapperModule,
        PublicPageWrapperComponent,
    ],
})
export class PromotionWrapperComponent implements OnInit {
    @Input() info: any;
    initData: any;
    @Output() onNotFound: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private api: PokerApiService,
        private promotionsClientConfig: PromotionsClientConfig,
    ) {}

    ngOnInit() {
        if (this.promotionsClientConfig.whenReady.subscribe(() => this.promotionsClientConfig.usePokerPromotionsNewTemplate)) {
            this.api.get('PokerPromotion/GetBrandNewPromotions', { sitecorePath: this.info.sitecorePath }).subscribe(
                (response) => {
                    if (response.promoModel && response.promoModel.pokerPromotionsSitecoreInfo) {
                        this.initData = response.promoModel.pokerPromotionsSitecoreInfo;
                        this.initData.menuContent = response.menuContent;
                        this.initData.relatedPromos = response.promoModel.relatedPromos;
                    }
                },
                (error) => {
                    if (error.notFound) {
                        this.onNotFound.emit(true);
                    }
                },
            );
        } else {
            this.api.get('PokerPromotion/GetPromotion', { sitecorePath: this.info.sitecorePath }).subscribe(
                (response) => {
                    if (response.promoModel && response.promoModel.pokerPromotionsSitecoreInfo) {
                        this.initData = response.promoModel.pokerPromotionsSitecoreInfo;
                        this.initData.menuContent = response.menuContent;
                        this.initData.relatedPromos = response.promoModel.relatedPromos;
                    }
                },
                (error) => {
                    if (error.notFound) {
                        this.onNotFound.emit(true);
                    }
                },
            );
        }
    }
}
