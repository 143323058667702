<div class="filters-list">
    <ul class="filter-menu">
        <li class="filter-items" *ngFor="let filter of filtersConfig" (click)="updateFilter(filter, filtersConfig.length - 1)">
            <div>
                <div class="sidemenu_text">{{ filter.title | translate }}</div>
            </div>
            <span [ngClass]="{ 'icon-selected theme-check': isFilterSelected(filter.value) }"></span>
        </li>
    </ul>
</div>
