import { Message } from '../../message';

export class MsgTrafficStats extends Message {
    private MESSAGE_NAME: string = 'MsgTrafficStats';
    private trafficStats: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, trafficStats: any | null = null) {
        super(reqServerPeerId);
        this.trafficStats = trafficStats;
    }

    getTrafficStats(): any | null {
        return this.trafficStats;
    }

    setTrafficStats(trafficStats: any | null) {
        this.trafficStats = trafficStats;
    }
}
