import { Message } from '../../message';

export class UserClubMemeberShipApproved extends Message {
    private MESSAGE_NAME: string = 'UserClubMemeberShipApproved';
    private clubId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, clubId: number = 0) {
        super(reqServerPeerId);
        this.clubId = clubId;
    }

    getClubId(): number {
        return this.clubId;
    }

    setClubId(clubId: number) {
        this.clubId = clubId;
    }
}
