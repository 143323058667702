import { Message } from '../../message';

export class ShowCards extends Message {
    private MESSAGE_NAME: string = 'ShowCards';
    private seatNo: number = 0;
    private cards: any[] | null = null;
    private handStrengthHi: any | null = null;
    private handStrengthLo: any | null = null;
    private show: boolean | null = false;
    private bestCards: string | null = null;
    private showCardsType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        seatNo: number = 0,
        cards: any[] | null = null,
        handStrengthHi: any | null = null,
        handStrengthLo: any | null = null,
        show: boolean | null = false,
        bestCards: string | null = null,
        showCardsType: number = 0,
    ) {
        super(reqServerPeerId);
        this.seatNo = seatNo;
        this.cards = cards;
        this.handStrengthHi = handStrengthHi;
        this.handStrengthLo = handStrengthLo;
        this.show = show;
        this.bestCards = bestCards;
        this.showCardsType = showCardsType;
    }

    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getCards(): any[] | null {
        return this.cards;
    }

    setCards(cards: any[] | null) {
        this.cards = cards;
    }
    getHandStrengthHi(): any | null {
        return this.handStrengthHi;
    }

    setHandStrengthHi(handStrengthHi: any | null) {
        this.handStrengthHi = handStrengthHi;
    }
    getHandStrengthLo(): any | null {
        return this.handStrengthLo;
    }

    setHandStrengthLo(handStrengthLo: any | null) {
        this.handStrengthLo = handStrengthLo;
    }
    getShow(): boolean | null {
        return this.show;
    }

    setShow(show: boolean | null) {
        this.show = show;
    }
    getBestCards(): string | null {
        return this.bestCards;
    }

    setBestCards(bestCards: string | null) {
        this.bestCards = bestCards;
    }
    getShowCardsType(): number {
        return this.showCardsType;
    }

    setShowCardsType(showCardsType: number) {
        this.showCardsType = showCardsType;
    }
}
