import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { ResponsiveContainer } from '../app/ResponsiveContainer';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';

export class CashoutEquityView extends PIXI.Container {
    gameService: any;
    EventDispatcher: any;
    peerId: any;
    displayUtil: DisplayUtil = new DisplayUtil();
    currenttable: string;
    animatedBgTween: any[];
    constructor(EventDispatcher, gameService) {
        super();
        this.gameService = gameService;
        this.EventDispatcher = EventDispatcher;
        this.peerId = this.gameService.getPeerId();
        this.currenttable = 'table' + this.peerId;
        // this.userCurrencyCode = window.parent.LSConnector.mainConnector.AppDataModel.getUserProfile("accountCurrencyCode");
        this.animatedBgTween = []; //  for clearing fadein/out animation on leave table for ff
        // register the plugin
        gsap.registerPlugin(PixiPlugin);
        // give the plugin a reference to the PIXI object
        PixiPlugin.registerPIXI(PIXI);
    }

    createView = function (i, maxNoOfSeats) {
        this.currentIndex = i;
        this.fontProps = uiConfig.font.props;
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + maxNoOfSeats + 'MaxPos'];
        this.playerAbsPos = this.positionConfig.playersUIData.playerAbsPos;
        this.opponentRabbitPos = this.positionConfig.playersUIData.playerIconPositions;
        this.dealerConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait.dealerConfig;
        // if(this.gameService.gTableType == serverIdConstants.TABLE_TYPE_SPINS) {
        this.playerViewContainerPos = this.positionConfig.playersUIData.playerViewContainerPos;
        this.playerIconPositions = this.positionConfig.playersUIData.playerIconPositions;
        this.CashoutEquityContainer = this.displayUtil.getContainer();
        this.CashoutEquityContainer.name = 'CashoutEquityContainer' + i;
        this.addChild(this.CashoutEquityContainer);
        const seatAlign = this.getSeatAlignmentforGap(i, maxNoOfSeats);
        if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_LEFT) {
            ResponsiveContainer.call(this.CashoutEquityContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignX: 'LEFT',
                EventDispatcher: this.EventDispatcher,
            });
        } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_RIGHT) {
            ResponsiveContainer.call(this.CashoutEquityContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignX: 'RIGHT',
                EventDispatcher: this.EventDispatcher,
            });
        } else if (seatAlign == serverIdConstants.SEAT_ALIGNMENT_TOP) {
            ResponsiveContainer.call(this.CashoutEquityContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignY: 'TOP',
                EventDispatcher: this.EventDispatcher,
            });
        } else {
            ResponsiveContainer.call(this.CashoutEquityContainer, {
                portX: this.playerViewContainerPos.x[i],
                portY: this.playerViewContainerPos.y[i],
                portAlignY: 'BOTTOM',
                EventDispatcher: this.EventDispatcher,
            });
        }
        this.CashoutEquityContainer.setSize(145, 335.5);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.PLAYER_CONTAINER_POS, {
            index: i,
            PosX: this.CashoutEquityContainer.x,
            PosY: this.CashoutEquityContainer.y,
            scale: this.CashoutEquityContainer.scale.x,
        });
    };
    //to get seatalignment as left / center / right / top
    getSeatAlignmentforGap = function (clientSeatNo, maxSeats) {
        if (clientSeatNo == uiConfig.selfUserClientPosInd) return serverIdConstants.SEAT_ALIGNMENT_BOTTOM;
        const centerSeatNo = maxSeats / 2;
        if (centerSeatNo == clientSeatNo) return serverIdConstants.SEAT_ALIGNMENT_TOP;
        return clientSeatNo > centerSeatNo ? serverIdConstants.SEAT_ALIGNMENT_RIGHT : serverIdConstants.SEAT_ALIGNMENT_LEFT;
    };
    showCashOutAnim = function (tempSeatPos) {
        this.currTablePeerId = 'table' + this.gameService.getPeerId();
        this.cashOutAnimation = this.displayUtil.getSpriteDetails('cashOutEquityAnim');
        this.addCashAnim = this.displayUtil.getCustomAnimatedSprite(this.cashOutAnimation);
        this.addCashAnim.loop = false;
        this.addCashAnim.name = 'CashOut';
        this.CashoutEquityContainer.addChild(this.addCashAnim);
        let posY;
        const posX = this.playerIconPositions.x[tempSeatPos];
        if (tempSeatPos != uiConfig.selfUserClientPosInd) posY = this.playerIconPositions.y[tempSeatPos];
        else posY = this.playerIconPositions.y[tempSeatPos] - 5;
        this.displayUtil.setObjectPosition(this.addCashAnim, posX, posY);
        this.addCashAnim.anchor.set(0.5);
        this.addCashAnim.play();
        CommonGameProperties.facade[this.currTablePeerId].soundObj.playSound(uiConfig.SoundProps.cashout_player_sounds, true);
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.DISABLE_SHOW_PLAYERPROFILE, { seatNo: tempSeatPos });
        setTimeout(
            function () {
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_ACTION_FOR_CASHOUT);
            }.bind(this),
            4000,
        );
        this.addCashAnim.onComplete = function () {
            if (this.addCashAnim) {
                this.addCashAnim.destroy();
                this.removeChild(this.addCashAnim);
                this.addCashAnim = null;
                this.cashOut = this.displayUtil.getSprite('cashout68');
                this.cashOut.name = 'DefaultPlate';
                this.CashoutEquityContainer.addChild(this.cashOut);
                this.cashOut.anchor.set(0.5);
                this.cashOut.scale.set(0.72);
                this.setCashOutPosition(tempSeatPos);
                gsap.from(this.cashOut, 0.5, {
                    x: 65,
                });
                gsap.fromTo(
                    this.cashOut,
                    0.7,
                    {
                        alpha: 0,
                    },
                    {
                        alpha: 1,
                    },
                );
                this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.ENABLE_SHOW_PLAYERPROFILE, { seatNo: tempSeatPos });
            }
        }.bind(this);
    };
    setCashOutPosition = function (clientSideSeatNo) {
        switch (this.gameService.getSeatAlignment(clientSideSeatNo)) {
            case serverIdConstants.SEAT_ALIGNMENT_RIGHT:
                this.displayUtil.setObjectPosition(this.cashOut, 7.5, 171);
                break;
            case serverIdConstants.SEAT_ALIGNMENT_LEFT:
                this.displayUtil.setObjectPosition(this.cashOut, 127.5, 171);
                break;
            case serverIdConstants.SEAT_ALIGNMENT_BOTTOM:
                this.displayUtil.setObjectPosition(this.cashOut, 148, 294);
                break;
            case serverIdConstants.SEAT_ALIGNMENT_TOP:
                this.displayUtil.setObjectPosition(this.cashOut, 12.5, 171);
                break;
        }
    };
    removeAnimations = function () {
        if (this.cashOut) this.cashOut.visible = false;
        this.cashOut = null;
        if (this.cashOutDisabledContainer) this.cashOutDisabledContainer = null;
        if (this.CashoutEquityContainer.children.length > 0) this.CashoutEquityContainer.removeChildren();
    };
    onCashOutDisabled = function () {
        this.cashOutDisabledContainer = this.displayUtil.getContainer();
        this.CashoutEquityContainer.addChild(this.cashOutDisabledContainer);
        this.cashOutDisabledContainer.name = 'cashOutDisabledContainer';
        this.cashOutDisabledtxt = this.displayUtil.getTextField(this.fontProps.cashOutBrandTxt);
        this.cashOutDisabledtxt.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHOUT_CASHOUT_NOT_AVAILABLE_MESSAGE');
        this.displayUtil.setObjectPosition(this.cashOutDisabledtxt, 9, 14);
        this.displayUtil.setObjectPosition(this.cashOutDisabledContainer, -137, -285);
        // this.displayUtil.setObjectPosition(this.cashOutDisabledContainer, -141, -182);
        this.cashOutDisabledBG = this.displayUtil.getBorderRoundRectangleSprite(
            this.cashOutDisabledtxt.width + 18,
            this.cashOutDisabledtxt.height + 30,
            '0x000c16',
            10,
            true,
            3,
            '0xffffff',
            0.5,
        );
        this.cashOutDisabledBG.alpha = 0.85;
        this.cashOutDisabledContainer.addChild(this.cashOutDisabledBG);
        this.cashOutDisabledContainer.addChild(this.cashOutDisabledtxt);
    };
    removeCashoutDisabled = function () {
        for (let i = 0; i < this.CashoutEquityContainer.children.length; i++) {
            if (this.CashoutEquityContainer.children[i].name == 'cashOutDisabledContainer') this.CashoutEquityContainer.children[i].visible = false;
            if (this.CashoutEquityContainer.children[i].name == 'cashOutWaitMsgContainer') this.CashoutEquityContainer.children[i].visible = false;
        }
    };

    showCashoutWaitMsg = function () {
        this.cashOutWaitMsgContainer = this.displayUtil.getContainer();
        this.CashoutEquityContainer.addChild(this.cashOutWaitMsgContainer);
        this.cashOutWaitMsgContainer.name = 'cashOutWaitMsgContainer';
        this.cashOutWaitmsgtxt = this.displayUtil.getTextField(this.fontProps.cashoutWaitingTxt);
        this.cashOutWaitmsgtxt.text = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_CASHOUT_OTHER_PLAYER_CASHOUT_WAIT');
        this.displayUtil.setObjectPosition(this.cashOutWaitmsgtxt, 9, 14);
        this.displayUtil.setObjectPosition(this.cashOutWaitMsgContainer, -117, -280);
        this.cashOutWaitMsgBG = this.displayUtil.getBorderRoundRectangleSprite(
            this.cashOutWaitmsgtxt.width + 18,
            this.cashOutWaitmsgtxt.height + 30,
            '0x000c16',
            10,
            true,
            3,
            '0xffffff',
            0.5,
        );
        this.cashOutWaitMsgBG.alpha = 0.85;
        this.cashOutWaitMsgContainer.addChild(this.cashOutWaitMsgBG);
        this.cashOutWaitMsgContainer.addChild(this.cashOutWaitmsgtxt);
    };
}
