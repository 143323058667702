import { Message } from '../../message';

export class LSTourneyPlayerGridRow extends Message {
    private MESSAGE_NAME: string = 'LSTourneyPlayerGridRow';
    private participantno: number = 0;
    private chips: number = 0;
    private rank: number = 0;
    private prizeAmt: number = 0;
    private prizeMsgTemplateId: number = 0;
    private chipsRank: number = 0;
    private noOfBounties: number = 0;
    private totalBountyWon: number = 0;
    private userTTPAwardType: number = 0;
    private prizeType: number = 0;
    private bountyOnPlayerHead: number = 0;
    private displayRank: number = 0;
    private bioCountryCode: string | null = null;
    private bountyValue: number = 0;
    private qualifiedChips: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        participantno: number = 0,
        chips: number = 0,
        rank: number = 0,
        prizeAmt: number = 0,
        prizeMsgTemplateId: number = 0,
        chipsRank: number = 0,
        noOfBounties: number = 0,
        totalBountyWon: number = 0,
        userTTPAwardType: number = 0,
        prizeType: number = 0,
        bountyOnPlayerHead: number = 0,
        displayRank: number = 0,
        bioCountryCode: string | null = null,
        bountyValue: number = 0,
        qualifiedChips: number = 0,
    ) {
        super(reqServerPeerId);
        this.participantno = participantno;
        this.chips = chips;
        this.rank = rank;
        this.prizeAmt = prizeAmt;
        this.prizeMsgTemplateId = prizeMsgTemplateId;
        this.chipsRank = chipsRank;
        this.noOfBounties = noOfBounties;
        this.totalBountyWon = totalBountyWon;
        this.userTTPAwardType = userTTPAwardType;
        this.prizeType = prizeType;
        this.bountyOnPlayerHead = bountyOnPlayerHead;
        this.displayRank = displayRank;
        this.bioCountryCode = bioCountryCode;
        this.bountyValue = bountyValue;
        this.qualifiedChips = qualifiedChips;
    }

    getParticipantno(): number {
        return this.participantno;
    }

    setParticipantno(participantno: number) {
        this.participantno = participantno;
    }
    getChips(): number {
        return this.chips;
    }

    setChips(chips: number) {
        this.chips = chips;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getPrizeAmt(): number {
        return this.prizeAmt;
    }

    setPrizeAmt(prizeAmt: number) {
        this.prizeAmt = prizeAmt;
    }
    getPrizeMsgTemplateId(): number {
        return this.prizeMsgTemplateId;
    }

    setPrizeMsgTemplateId(prizeMsgTemplateId: number) {
        this.prizeMsgTemplateId = prizeMsgTemplateId;
    }
    getChipsRank(): number {
        return this.chipsRank;
    }

    setChipsRank(chipsRank: number) {
        this.chipsRank = chipsRank;
    }
    getNoOfBounties(): number {
        return this.noOfBounties;
    }

    setNoOfBounties(noOfBounties: number) {
        this.noOfBounties = noOfBounties;
    }
    getTotalBountyWon(): number {
        return this.totalBountyWon;
    }

    setTotalBountyWon(totalBountyWon: number) {
        this.totalBountyWon = totalBountyWon;
    }
    getUserTTPAwardType(): number {
        return this.userTTPAwardType;
    }

    setUserTTPAwardType(userTTPAwardType: number) {
        this.userTTPAwardType = userTTPAwardType;
    }
    getPrizeType(): number {
        return this.prizeType;
    }

    setPrizeType(prizeType: number) {
        this.prizeType = prizeType;
    }
    getBountyOnPlayerHead(): number {
        return this.bountyOnPlayerHead;
    }

    setBountyOnPlayerHead(bountyOnPlayerHead: number) {
        this.bountyOnPlayerHead = bountyOnPlayerHead;
    }
    getDisplayRank(): number {
        return this.displayRank;
    }

    setDisplayRank(displayRank: number) {
        this.displayRank = displayRank;
    }
    getBioCountryCode(): string | null {
        return this.bioCountryCode;
    }

    setBioCountryCode(bioCountryCode: string | null) {
        this.bioCountryCode = bioCountryCode;
    }
    getBountyValue(): number {
        return this.bountyValue;
    }

    setBountyValue(bountyValue: number) {
        this.bountyValue = bountyValue;
    }
    getQualifiedChips(): number {
        return this.qualifiedChips;
    }

    setQualifiedChips(qualifiedChips: number) {
        this.qualifiedChips = qualifiedChips;
    }
}
