import { Message } from '../../message';
import { CurrencyAmount } from '../../pgSharedMessageFactory/messages/CurrencyAmount';

export class TournamentParticipantRankInfo extends Message {
    private MESSAGE_NAME: string = 'TournamentParticipantRankInfo';
    private mtctId: number = 0;
    private tournamentCategory: number = 0;
    private participantNo: number = 0;
    private rank: number = 0;
    private rankInfo: any[] | null = null;
    private dialogDisplayTimeInsSecs: number = 0;
    private reentryAllowed: boolean | null = false;
    private eliminatedPosition: number = 0;
    private winAmount: CurrencyAmount | null = null;
    private bountyAmount: CurrencyAmount | null = null;
    private ticketValue: CurrencyAmount | null = null;
    private tournamentTokens: CurrencyAmount | null = null;
    private ticketType: number = 0;
    private name: any | null = null;
    private buyIn: number = 0;
    private entryFee: number = 0;
    private accountBalance: number = 0;
    private ticketAvailable: boolean | null = false;
    private fxSnapshotID: number = 0;
    private flightedNextPhaseNo: number = 0;
    private qualifiedChips: number = 0;
    private tdBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        tournamentCategory: number = 0,
        participantNo: number = 0,
        rank: number = 0,
        rankInfo: any[] | null = null,
        dialogDisplayTimeInsSecs: number = 0,
        reentryAllowed: boolean | null = false,
        eliminatedPosition: number = 0,
        winAmount: CurrencyAmount | null = null,
        bountyAmount: CurrencyAmount | null = null,
        ticketValue: CurrencyAmount | null = null,
        tournamentTokens: CurrencyAmount | null = null,
        ticketType: number = 0,
        name: any | null = null,
        buyIn: number = 0,
        entryFee: number = 0,
        accountBalance: number = 0,
        ticketAvailable: boolean | null = false,
        fxSnapshotID: number = 0,
        flightedNextPhaseNo: number = 0,
        qualifiedChips: number = 0,
        tdBalance: number = 0,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.tournamentCategory = tournamentCategory;
        this.participantNo = participantNo;
        this.rank = rank;
        this.rankInfo = rankInfo;
        this.dialogDisplayTimeInsSecs = dialogDisplayTimeInsSecs;
        this.reentryAllowed = reentryAllowed;
        this.eliminatedPosition = eliminatedPosition;
        this.winAmount = winAmount;
        this.bountyAmount = bountyAmount;
        this.ticketValue = ticketValue;
        this.tournamentTokens = tournamentTokens;
        this.ticketType = ticketType;
        this.name = name;
        this.buyIn = buyIn;
        this.entryFee = entryFee;
        this.accountBalance = accountBalance;
        this.ticketAvailable = ticketAvailable;
        this.fxSnapshotID = fxSnapshotID;
        this.flightedNextPhaseNo = flightedNextPhaseNo;
        this.qualifiedChips = qualifiedChips;
        this.tdBalance = tdBalance;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getRank(): number {
        return this.rank;
    }

    setRank(rank: number) {
        this.rank = rank;
    }
    getRankInfo(): any[] | null {
        return this.rankInfo;
    }

    setRankInfo(rankInfo: any[] | null) {
        this.rankInfo = rankInfo;
    }
    getDialogDisplayTimeInsSecs(): number {
        return this.dialogDisplayTimeInsSecs;
    }

    setDialogDisplayTimeInsSecs(dialogDisplayTimeInsSecs: number) {
        this.dialogDisplayTimeInsSecs = dialogDisplayTimeInsSecs;
    }
    getReentryAllowed(): boolean | null {
        return this.reentryAllowed;
    }

    setReentryAllowed(reentryAllowed: boolean | null) {
        this.reentryAllowed = reentryAllowed;
    }
    getEliminatedPosition(): number {
        return this.eliminatedPosition;
    }

    setEliminatedPosition(eliminatedPosition: number) {
        this.eliminatedPosition = eliminatedPosition;
    }
    getWinAmount(): CurrencyAmount | null {
        return this.winAmount;
    }

    setWinAmount(winAmount: CurrencyAmount | null) {
        this.winAmount = winAmount;
    }
    getBountyAmount(): CurrencyAmount | null {
        return this.bountyAmount;
    }

    setBountyAmount(bountyAmount: CurrencyAmount | null) {
        this.bountyAmount = bountyAmount;
    }
    getTicketValue(): CurrencyAmount | null {
        return this.ticketValue;
    }

    setTicketValue(ticketValue: CurrencyAmount | null) {
        this.ticketValue = ticketValue;
    }
    getTournamentTokens(): CurrencyAmount | null {
        return this.tournamentTokens;
    }

    setTournamentTokens(tournamentTokens: CurrencyAmount | null) {
        this.tournamentTokens = tournamentTokens;
    }
    getTicketType(): number {
        return this.ticketType;
    }

    setTicketType(ticketType: number) {
        this.ticketType = ticketType;
    }
    getName(): any | null {
        return this.name;
    }

    setName(name: any | null) {
        this.name = name;
    }
    getBuyIn(): number {
        return this.buyIn;
    }

    setBuyIn(buyIn: number) {
        this.buyIn = buyIn;
    }
    getEntryFee(): number {
        return this.entryFee;
    }

    setEntryFee(entryFee: number) {
        this.entryFee = entryFee;
    }
    getAccountBalance(): number {
        return this.accountBalance;
    }

    setAccountBalance(accountBalance: number) {
        this.accountBalance = accountBalance;
    }
    getTicketAvailable(): boolean | null {
        return this.ticketAvailable;
    }

    setTicketAvailable(ticketAvailable: boolean | null) {
        this.ticketAvailable = ticketAvailable;
    }
    getFxSnapshotID(): number {
        return this.fxSnapshotID;
    }

    setFxSnapshotID(fxSnapshotID: number) {
        this.fxSnapshotID = fxSnapshotID;
    }
    getFlightedNextPhaseNo(): number {
        return this.flightedNextPhaseNo;
    }

    setFlightedNextPhaseNo(flightedNextPhaseNo: number) {
        this.flightedNextPhaseNo = flightedNextPhaseNo;
    }
    getQualifiedChips(): number {
        return this.qualifiedChips;
    }

    setQualifiedChips(qualifiedChips: number) {
        this.qualifiedChips = qualifiedChips;
    }
    getTdBalance(): number {
        return this.tdBalance;
    }

    setTdBalance(tdBalance: number) {
        this.tdBalance = tdBalance;
    }
}
