import { Message } from '../../message';

export class MorePlayMoneyGeneralResponse extends Message {
    private MESSAGE_NAME: string = 'MorePlayMoneyGeneralResponse';
    private responseId: number = 0;
    private updatedPlayMoney: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseId: number = 0, updatedPlayMoney: number = 0) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.updatedPlayMoney = updatedPlayMoney;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getUpdatedPlayMoney(): number {
        return this.updatedPlayMoney;
    }

    setUpdatedPlayMoney(updatedPlayMoney: number) {
        this.updatedPlayMoney = updatedPlayMoney;
    }
}
