import { Message } from '../../message';

export class ChallengeTemplatesList extends Message {
    private MESSAGE_NAME: string = 'ChallengeTemplatesList';
    private huCashChallengeTemplatesList: any[] | null = null;
    private huSNGChallengeTemplatesList: any[] | null = null;
    private privateTablesTemplatesList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        huCashChallengeTemplatesList: any[] | null = null,
        huSNGChallengeTemplatesList: any[] | null = null,
        privateTablesTemplatesList: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.huCashChallengeTemplatesList = huCashChallengeTemplatesList;
        this.huSNGChallengeTemplatesList = huSNGChallengeTemplatesList;
        this.privateTablesTemplatesList = privateTablesTemplatesList;
    }

    getHuCashChallengeTemplatesList(): any[] | null {
        return this.huCashChallengeTemplatesList;
    }

    setHuCashChallengeTemplatesList(huCashChallengeTemplatesList: any[] | null) {
        this.huCashChallengeTemplatesList = huCashChallengeTemplatesList;
    }
    getHuSNGChallengeTemplatesList(): any[] | null {
        return this.huSNGChallengeTemplatesList;
    }

    setHuSNGChallengeTemplatesList(huSNGChallengeTemplatesList: any[] | null) {
        this.huSNGChallengeTemplatesList = huSNGChallengeTemplatesList;
    }
    getPrivateTablesTemplatesList(): any[] | null {
        return this.privateTablesTemplatesList;
    }

    setPrivateTablesTemplatesList(privateTablesTemplatesList: any[] | null) {
        this.privateTablesTemplatesList = privateTablesTemplatesList;
    }
}
