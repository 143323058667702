<div id="instagramfeed" class="social-feed-section" *ngIf="pokerInstagramFeed">
    <div class="row no-gutters">
        <vn-pc-component-header-v2 class="" *ngIf="item.title" [item]="item"></vn-pc-component-header-v2>
        <h3 *ngIf="item.instagramProfileTitle" class="mt-0 col-auto pl-0">
            <a [href]="item.instagramProfileLink" target="_blank" class="color-primary">{{ item.instagramProfileTitle }}</a>
        </h3>
    </div>

    <div class="insta-feed-wrap">
        <div class="row no-gutters">
            <ng-container *ngFor="let feed of pokerInstagramFeed">
                <div class="col-4 col-sm-6 col-md-4 p-1">
                    <a [href]="feed.permaLink" class="insta-item-thumb d-block" target="_blank">
                        <img [src]="feed.thumbnailSource" alt="Instagram Thumbnail" />
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
