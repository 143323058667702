import { Injectable } from '@angular/core';

// import { SoundUtilService } from '../services/sound-util.service';
import FontFaceObserver from 'fontfaceobserver-es';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';

import { AssetPaths, AssetloaderConfig, FontsList, SimpleObjectType } from '../models/asset-loader.model';

@Injectable({
    providedIn: 'root',
})
export class AssetLoaderService {
    loadConfig: any; //LoadConfig | undefined ;
    deviceCategories: string[] = [];
    resolution: string = '';
    assetPaths!: AssetPaths;
    resolutionAssetsPath: any = {};
    fontsPath: any = {};
    soundsPath: any = {};
    pixiLoadPercent: number = 0;
    soundLoadPercent: number = 0;
    loadProgressCallback?: Function;
    loadPercentSubject = new BehaviorSubject(0);
    public shareLoadPercent = this.loadPercentSubject.asObservable();

    constructor() {}

    setConfig(data: AssetloaderConfig): void {
        this.loadConfig = data.loadConfig;
        this.deviceCategories = ['common', data.device];
        this.assetPaths = data.assetPaths;
        this.resolutionAssetsPath = {};
        this.fontsPath = {};
        this.soundsPath = {};
        for (const x in this.assetPaths) {
            if (x !== 'default') {
                this.resolutionAssetsPath[x] = this.assetPaths[x] + data.resolution + '/';
                this.fontsPath[x] = this.assetPaths[x] + 'fonts/';
                this.soundsPath[x] = this.assetPaths[x] + 'sounds/';
            }
        }
        this.resolutionAssetsPath['coreLangAssets'] = this.assetPaths['gameAssets'] + data.resolution + '/lang/' + data.language + '/';
        this.resolutionAssetsPath['gameLangAssets'] = this.assetPaths['gameAssets'] + data.resolution + '/lang/' + data.language + '/';
    }

    loadAssets(assetCategory: string): Observable<any> {
        const loader: PIXI.Loader = new PIXI.Loader();
        const soundsList: SimpleObjectType = {};
        const fontsList: FontsList[] = [];
        this.pixiLoadPercent = 0;
        this.soundLoadPercent = 0;

        this.deviceCategories?.forEach((deviceCategory) => {
            if (this.loadConfig[deviceCategory]) {
                const assets = this.loadConfig[deviceCategory][assetCategory];
                for (const pathCategory in assets) {
                    const assetList = assets[pathCategory];

                    // Load spritesheets
                    const spritesheets = assetList?.spritesheets;
                    spritesheets?.forEach((element: string) => {
                        loader.add(this.resolutionAssetsPath[pathCategory] + element);
                    });

                    // Load lang Spritesheets
                    if (assetList.langSpritesheets != undefined) {
                        for (const langSprite in assetList?.langSpritesheets) {
                            loader.add(langSprite, this.resolutionAssetsPath['gameLangAssets'] + assetList.langSpritesheets[langSprite]);
                        }
                    }

                    if (assetList.gameMovieClips !== undefined) {
                        const gameClips = assetList.gameMovieClips;
                        for (const clipName in gameClips) {
                            loader.add(clipName, this.resolutionAssetsPath[pathCategory] + gameClips[clipName]);
                        }
                    }

                    // Load fonts
                    if (assetList.fonts !== undefined) {
                        for (let i = 0; i < assetList.fonts.length; i++) {
                            fontsList.push({
                                name: assetList.fonts[i].name,
                                path: this.fontsPath[pathCategory] + assetList.fonts[i].file,
                            });
                        }
                    }

                    // Load sounds
                    if (assetList.sounds !== undefined) {
                        for (const soundName in assetList.sounds) {
                            if (Array.isArray(assetList.sounds[soundName])) {
                                soundsList[soundName] = assetList.sounds[soundName].map((value: string) => {
                                    return this.soundsPath[pathCategory] + value;
                                });
                            } else {
                                soundsList[soundName] = this.soundsPath[pathCategory] + assetList.sounds[soundName];
                            }
                        }
                    }
                    //loading langugae specific sounds from game folder
                    if (assetList.soundsLanguageFiles !== undefined) {
                        for (const lSoundName in assetList.soundsLanguageFiles) {
                            if (Array.isArray(assetList.soundsLanguageFiles[lSoundName])) {
                                soundsList[lSoundName] = assetList.soundsLanguageFiles[lSoundName].map((value: string) => {
                                    return this.soundsPath['gameLangSounds'] + value;
                                });
                            } else {
                                soundsList[lSoundName] = this.soundsPath['gameLangSounds'] + assetList.soundsLanguageFiles[lSoundName];
                            }
                        }
                    }
                }
            }
        });

        if (Object.keys(loader.resources).length === 0) {
            this.pixiLoadPercent = 100;
        }
        if (Object.keys(soundsList).length === 0) {
            this.soundLoadPercent = 100;
        }

        loader.onProgress.add((loader) => {
            this.pixiLoadPercent = loader.progress;
            this.updateLoadProgress();
        });

        const pixiLoaderObservable = new Observable((subscriber) => {
            loader.load((loader, resources) => {
                subscriber.next(resources);
                subscriber.complete();
            });
        });

        // let soundLoaderObservable = new Observable(subscriber => {
        //   this.soundUtilService.loadSounds(soundsList, () => {
        //     subscriber.next();
        //     subscriber.complete();
        //   }, (loadPercent: number) => {
        //     this.soundLoadPercent = loadPercent;
        //     this.updateLoadProgress()
        //   });
        // });

        const fontObservables = [] as any;
        for (let i = 0; i < fontsList.length; i++) {
            fontObservables.push(this.loadFontToDOM(fontsList[i].name, fontsList[i].path));
        }

        return forkJoin([pixiLoaderObservable].concat(fontObservables));
    }

    updateLoadProgress(): void {
        const totalLoadPercent = Math.floor((this.pixiLoadPercent + this.soundLoadPercent) / 2);
        this.loadPercentSubject.next(totalLoadPercent);
    }

    loadFontToDOM(fontName: string, path: string): Observable<any> {
        const fontCSS = document.createElement('style');
        fontCSS.appendChild(document.createTextNode('@font-face {' + "font-family: '" + fontName + "';" + 'src: url(' + path + ');' + '}'));
        document.head.appendChild(fontCSS);
        const sampleText = document.createElement('span');
        sampleText.style.fontFamily = fontName;
        sampleText.style.visibility = 'hidden';
        sampleText.style.position = 'absolute';
        // eslint-disable-next-line unicorn/prefer-dom-node-text-content
        sampleText.innerText = '.';
        document.body.appendChild(sampleText);

        const observer = new FontFaceObserver(fontName);
        return new Observable((subscriber) => {
            observer.load('a', 30000).then(() => {
                subscriber.next();
                subscriber.complete();
            });
        });
    }
}
