import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable, map, switchMap } from 'rxjs';

import { PokerWebClientService } from './poker-web-client.service';

@Injectable({ providedIn: 'root' })
export class PokerWebClientResolver implements Resolve<any> {
    constructor(private resource: PokerWebClientService) {}

    // resolve(route: ActivatedRouteSnapshot): Observable<any> {
    resolve(): Observable<any> {
        return this.resource.getDynaconConfigs().pipe(
            switchMap((dynacon) => {
                // if (dynacon) {
                this.resource.loadPrimaryAssets(dynacon.webClientConfigs);
                return this.resource
                    .handshakeResponseObservable(dynacon.gameEngineConfigs)
                    .pipe(map((handshakeSuccessful) => ({ dynaconData: dynacon, handshakeSuccessful })));
                // }
            }),
            switchMap((dynacon) => {
                return this.resource.getSiteCoreLiterals().pipe(map((data) => ({ ...dynacon, siteCoreData: data.content })));
            }),
            switchMap((dynacon) => {
                return this.resource.getLobbyPromotions().pipe(map((data) => ({ ...dynacon, lobbyPromotions: data })));
            }),
            switchMap((dynacon) => {
                return this.resource.getBannerDetails(null).pipe(map((data) => ({ ...dynacon, lobbyBanners: data })));
            }),
            switchMap((dynacon) => {
                return this.resource.getGameTableAssets().pipe(map((data) => ({ ...dynacon, gameTableAssets: data.htmlLinks })));
            }),
            switchMap((dynacon) => {
                return this.resource.getPokerWebAssets().pipe(map((data) => ({ ...dynacon, webAssets: data.htmlAssets })));
            }),
            switchMap((dynacon) => {
                return this.resource.getClientExternalLinks().pipe(map((data) => ({ ...dynacon, externalLinks: data.htmlLinks })));
            }),
        );
    }
}
