import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash-es';

import { ClientToServerRequestServices } from '../service/client-to-server-requests.service';

@Pipe({
    name: 'translate',
    standalone: true,
})
export class TranslatePipe implements PipeTransform {
    storeData: any;
    constructor(private clientToServer: ClientToServerRequestServices) {
        this.storeData = this.clientToServer.siteCoreData;
    }

    transform(key: string, replaceArgs = {}): string {
        let data;
        if (key) {
            data = _.find(this.storeData, (obj) => {
                return obj['name' || 'id'] === key ? obj.value : '';
            });
        }
        if (data) {
            data.value = Object.keys(replaceArgs).length > 0 ? this.allReplace(data?.value, replaceArgs) : data?.value;
        }
        return data?.value ? data.value : key;
    }

    allReplace(str, obj) {
        for (const x in obj) {
            str = str.replace(new RegExp(x, 'g'), obj[x]);
        }
        return str;
    }
}
