import { Message } from '../../message';

export class ResultQuickSeatRing extends Message {
    private MESSAGE_NAME: string = 'ResultQuickSeatRing';
    private tableId: number = 0;
    private tableInfoId: number = 0;
    private maxNoOfSeats: number = 0;
    private peerId: number = 0;
    private errorCode: number = 0;
    private seatNo: number = 0;
    private blockSeatExpiresAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        tableInfoId: number = 0,
        maxNoOfSeats: number = 0,
        peerId: number = 0,
        errorCode: number = 0,
        seatNo: number = 0,
        blockSeatExpiresAt: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.tableInfoId = tableInfoId;
        this.maxNoOfSeats = maxNoOfSeats;
        this.peerId = peerId;
        this.errorCode = errorCode;
        this.seatNo = seatNo;
        this.blockSeatExpiresAt = blockSeatExpiresAt;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getTableInfoId(): number {
        return this.tableInfoId;
    }

    setTableInfoId(tableInfoId: number) {
        this.tableInfoId = tableInfoId;
    }
    getMaxNoOfSeats(): number {
        return this.maxNoOfSeats;
    }

    setMaxNoOfSeats(maxNoOfSeats: number) {
        this.maxNoOfSeats = maxNoOfSeats;
    }
    getPeerId(): number {
        return this.peerId;
    }

    setPeerId(peerId: number) {
        this.peerId = peerId;
    }
    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getBlockSeatExpiresAt(): number {
        return this.blockSeatExpiresAt;
    }

    setBlockSeatExpiresAt(blockSeatExpiresAt: number) {
        this.blockSeatExpiresAt = blockSeatExpiresAt;
    }
}
