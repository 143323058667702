import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MenuActionComponent, Page, PlainLinkComponent, TrackingService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';

@Component({
    selector: 'pp-teaser-widget',
    templateUrl: 'pp-teaser-widget.component.html',
    standalone: true,
    imports: [PlainLinkComponent, MenuActionComponent, CommonModule, RouterModule],
})
export class PPTeaserWidgetComponent extends PCComponent implements OnInit {
    constructor(
        private vanillaTrackingService: TrackingService,
        private page: Page,
    ) {
        super();
    }
    private lang: any;
    // @HostBinding('class.d-none') shouldNotRender: boolean = false;

    @HostBinding()
    get class() {
        if (!(this.item.relatedPagePath || this.item.synopsisLink)) {
            return this.createClass('d-none');
        }
        return this.createClass(this.item.name);
    }

    ngOnInit(): void {
        this.lang = this.page.lang;
    }

    isCurrentRedirectedUrlHomepageUrl(): boolean {
        const currentBrowserUrlPath = window.location.pathname;
        if (currentBrowserUrlPath == '/' || currentBrowserUrlPath == '/' + this.page.lang) {
            return true;
        }
        return false;
    }

    getPromoLink(promoName: string) {
        if (promoName != null) {
            if (promoName.includes('publicpages')) {
                promoName = promoName.replace('publicpages', 'p');
            }

            const tokens = promoName.split('/').slice(2);
            promoName = tokens.join('/');
            if (!this.item.isPromoPage) {
                promoName = promoName.substring(0, promoName.lastIndexOf('/'));
            }
        }
        return `${this.lang}/${promoName}`;
    }

    pkPromoOffersCtaTracking(offerName: string, linkUrl: string, event: any, synopsisTitle: string) {
        let promoPositionRank = 0;

        if (this.isCurrentRedirectedUrlHomepageUrl()) {
            // Get the parent <pp-teaser-widget> element
            const teaserWidget = event.target.closest('pp-teaser-widget');

            if (teaserWidget) {
                // Get all <pp-teaser-widget> elements within <vn-pc-container>
                const allTeaserWidgets = document.querySelectorAll('vn-pc-container pp-teaser-widget');

                const currentTeaserWidgetIndex = Array.from(allTeaserWidgets).indexOf(teaserWidget);

                // Get the total number of <pp-teaser-widget> elements
                //const totalTeaserWidgets = allTeaserWidgets.length;
                //console.log('Number of <pp-teaser-widget> elements : ', totalTeaserWidgets);

                //console.log('Current length of the clicked <pp-teaser-widget>:', currentTeaserWidgetIndex + 1);
                promoPositionRank = currentTeaserWidgetIndex; // + 1;
            }
            // vanilla tracking..
            this.vanillaTrackingService.triggerEvent('Event.Tracking', {
                'component.CategoryEvent': 'promo hub',
                'component.LabelEvent': 'poker',
                'component.ActionEvent': 'promo - clicked',
                'component.PositionEvent': promoPositionRank, //ex: 1            or 2
                'component.LocationEvent': offerName, //ex:            pick_a_box,flip_a_coin
                'component.EventDetails': 'more info',
                'component.URLClicked': linkUrl,
                'component.ContentPosition': promoPositionRank, //            ex: 1 or 2
                'campaignId': 'not applicable', //'{campaign ID}', // ex: 1481906 or ""
                'page.siteSection': 'poker',
                'page.siteSubSection': 'promo poker offers',
                'component.PromotionType': 'not applicable', //'{static or dynamic promotion}', //ex: static promotions ?? whats the difference
                'component.Product': 'poker',
                'component.PromoOfferName': synopsisTitle,
                'component.PromoIntent': 'not applicable', //'{promo intent type}' //            ex: retention, acquisition etc
            });
        }
    }
}
