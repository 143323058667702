import { Message } from '../../message';

export class MTCTLobbySnapshotChange extends Message {
    private MESSAGE_NAME: string = 'MTCTLobbySnapshotChange';
    private snapshotId: number = 0;
    private tabIds: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, snapshotId: number = 0, tabIds: any[] | null = null) {
        super(reqServerPeerId);
        this.snapshotId = snapshotId;
        this.tabIds = tabIds;
    }

    getSnapshotId(): number {
        return this.snapshotId;
    }

    setSnapshotId(snapshotId: number) {
        this.snapshotId = snapshotId;
    }
    getTabIds(): any[] | null {
        return this.tabIds;
    }

    setTabIds(tabIds: any[] | null) {
        this.tabIds = tabIds;
    }
}
