import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { MessageQueueService, MessageType, UserService } from '@frontend/vanilla/core';

import { HandHistoryRoundInputModel } from '../../hand-history.models';
import { HandHistoryService } from './../../hand-history.service';

@Component({
    selector: 'pk-text-view',
    templateUrl: 'text-view.component.html',
})
export class TextViewComponent implements OnInit {
    textButtonPosition: any;
    showTextPopup: boolean = false;
    anteBlindEvents: any;
    flopEvents: any;
    preFlopEvents: any;
    riverEvents: any;
    roundEvents: any;
    showdownEvents: any;
    turnEvents: any;
    thirdStreetEvents: any;
    fourthStreetEvents: any;
    fifthStreetEvents: any;
    sixthStreetEvents: any;
    seventhStreetEvents: any;
    roundTextView: any;
    roundPlayers: any = {};
    roundId: string;
    winnerNickName: string;
    winnerUserName: string;
    // winnerSeatNumber: number;
    prevDisabled: boolean = false;
    nextDisabled: boolean = false;
    events: any;
    flopCommunityCards: any = [];
    riverCommunityCards: any = [];
    turnCommunityCards: any = [];
    // mainPotAmount: number;
    handId: any;
    sidePotCreated: boolean = false;
    currency = {
        USD: '$',
        EUR: '€',
        CAD: 'CAD',
        GBP: '£',
        RUB: '₽',
        DKK: 'Kr',
        GEL: '‎₾',
    };
    cardRank: any = {};
    gameTypes: any = {};
    tournamentType: boolean;
    username: string;
    userid: string;
    uncalledSidePots: any = [];
    winners: any;

    @Output() roundIdError = new EventEmitter<string>();

    constructor(
        public serve: HandHistoryService,
        private messageQueue: MessageQueueService,
        public userService: UserService,
    ) {}

    ngOnInit() {
        const gameValues = this.serve.content.form.gametypevaluesabbr.values;
        this.username = this.userService.screenname ?? '';
        this.userid = this.userService.username ?? '';
        if (this.serve.content) {
            const messages = this.serve.content.messages;
            this.events = {
                POST_SMALL_BLIND: messages.PostSmallBlinds,
                POST_BIG_BLIND: messages.PostBigBlinds,
                ALL_IN_SMALL_BLIND: messages.PostSmallBlinds,
                ALL_IN_BIG_BLIND: messages.PostBigBlinds,
                POST_OTHER_BLIND: messages.PostDeadBlind,
                POST_BIG_BLIND_PLUS_DEAD: messages.PostDeadBlind,
                ALL_IN_OTHER_BLIND: messages.PostDeadBlind,
                MISSED_SMALL_BLIND: messages.PostSmallBlinds,
                ALL_IN_MISSED_SMALL_BLIND: messages.PostSmallBlinds,
                POST_MISSED_SMALL_BLIND: messages.PostSmallBlinds,
                MISSED_BIG_BLIND: messages.PostBigBlinds,
                ALL_IN_MISSED_BIG_BLIND: messages.PostBigBlinds,
                POST_MISSED_BIG_BLIND: messages.PostBigBlinds,
                FOLD: messages.Folds,
                CHECK: messages.Checks,
                CALL: messages.Calls,
                BET: messages.Bet,
                RAISE: messages.Raises,
                DOUBLE_RAISE: messages.DoubleRaises,
                ALL_IN_DOUBLE_RAISE: messages.DoubleRaises,
                DOUBLE_BET: messages.DoubleBets,
                ALL_IN_DOUBLE_BET: messages.DoubleBets,
                PLAYER_MUCK_CARDS: messages.Mucks,
                PLAYER_SHOW_CARDS: messages.Shows,
                CARD_SHOWN: messages.Shows,
                WIN_HIGH: messages.WinHigh,
                WINS: messages.Wins,
                WIN_LOW: messages.WinLow,
                POST_BRING_IN: messages.PostBringIn,
                COMPLETE_BRING_IN: messages.CompletesBringIn,
                ALL_IN_BRING_IN: messages.PostBringIn,
                ALL_IN_COMPLETE_BRING_IN: messages.CompletesBringIn,
                POST_ANTE: messages.PostsAnte,
                ALL_IN_ANTE: messages.PostsAnte,
                ALL_IN_RAISE: messages.AllInRaises,
                ALL_IN_CALL: messages.AllInCalls,
                ALL_IN_BET: messages.AllInBets,
                CARD_HIDDEN: messages.DealtTo,
                POST_BUTTON_ANTE: messages.PostsButtonAnte,
                ALL_IN_BUTTON_ANTE: messages.PostsButtonAnte,
            };
            this.cardRank = {
                RoyalFlush: {
                    title: messages.RoyalFlush,
                    rank: 0,
                },
                StraightFlush: {
                    title: messages.StraightFlush,
                    rank: 1,
                },
                Quads: {
                    title: messages.Quads,
                    rank: 2,
                },
                Fullhouse: {
                    title: messages.FullHouse,
                    rank: 3,
                },
                Flush: {
                    title: messages.Flush,
                    rank: 4,
                },
                Straight: {
                    title: messages.Straight,
                    rank: 5,
                },
                Set: {
                    title: messages.ThreeOfKind,
                    rank: 6,
                },
                TwoPairs: {
                    title: messages.TwoPairs,
                    rank: 7,
                },
                Pair: {
                    title: messages.Pair,
                    rank: 8,
                },
                Highcard: {
                    title: messages.Highcard,
                    rank: 9,
                },
            };
            for (let i = 0; i < gameValues.length; i++) {
                this.gameTypes[gameValues[i].value] = gameValues[i].text;
            }
        }
        // let moment = require('moment-timezone');
        // let june = moment("2019-01-04T01:15:15.816Z");
        // let timeZone = moment.tz.guess();
    }

    closeTextPopup() {
        this.showTextPopup = false;
        this.serve.popupToggle = false;
        document.getElementsByTagName('body')[0].classList.remove('noscroll');
        (document.querySelector('body') as HTMLElement).style.top = '0px';
        window.scroll(0, this.textButtonPosition);
    }

    convertTime(time: number, type: string) {
        let date: any = new Date(time);
        const t: any = date.toTimeString();
        if (type == 'datetime') {
            date = date.toDateString();
            date = date.split(' ');
            date = date[2] + ' ' + date[1] + ' ' + date[3] + ' ' + t.substr(0, 5);
            return date;
        } else {
            const timeZone = t.substr(9, t.length - 9).split(' ')[0];
            return timeZone;
        }
    }

    sortWinners(winners: any) {
        const pots: any = [],
            sortedWinners: any = [];
        winners.forEach((winObj: any) => {
            pots.push(winObj.potId);
        });
        pots.sort();
        pots.forEach((pot: number) => {
            winners.forEach((winObj: any) => {
                if (winObj.potId === pot) {
                    sortedWinners.push(winObj);
                }
            });
        });
        return sortedWinners;
    }

    getRoundTextView(handHistoryRoundInputModel: any, roundId: string) {
        let winners: any = [],
            singleHand: boolean = this.serve.singleHand;
        this.roundId = roundId;
        // this.serve.singleHandStatus = false;
        this.serve.getReplayer(roundId).subscribe(
            (data: any) => {
                let element = document.querySelector('.hands-details[roundid="' + roundId + '"]'),
                    roundPlayers;
                this.messageQueue.clear();
                this.roundPlayers = {};
                this.roundTextView = data.gameRoundJson.gameRound;
                roundPlayers = this.roundTextView.roundPlayers;
                for (let i = 0; i < roundPlayers.length; i++) {
                    const seat = roundPlayers[i].seat;
                    this.roundPlayers[seat] = roundPlayers[i].nickName;
                    this.roundPlayers[seat + 'lowHandRank'] = roundPlayers[i].lowHandRank;
                    this.roundPlayers[seat + 'hiHandRank'] = roundPlayers[i].hiHandRank;
                    this.roundPlayers[seat + 'hhrankInfo'] = roundPlayers[i].hhrankInfo;
                    this.roundPlayers[seat + 'startMoney'] = roundPlayers[i].startMoney.unit;
                    this.roundPlayers[seat + 'requestingPlayer'] = roundPlayers[i].requestingPlayer;
                    this.roundPlayers[seat + 'winAmount'] = 0;
                    this.roundPlayers[seat + 'iterate'] = i;
                    if (roundPlayers[i].hasOwnProperty('playerHoleCards')) {
                        this.roundPlayers[seat + 'playerHoleCards'] = roundPlayers[i].playerHoleCards;
                    }
                }
                this.anteBlindEvents = this.getEvents(this.roundTextView.anteBlindEvents, 'anteBlindEvents');
                this.flopCommunityCards = this.getCommunityCards(this.roundTextView.flopEvents);
                this.preFlopEvents = this.getEvents(this.roundTextView.preFlopEvents, 'preFlopEvents');
                this.flopEvents = this.getEvents(this.roundTextView.flopEvents, 'flopEvents');
                this.riverCommunityCards = this.getCommunityCards(this.roundTextView.riverEvents);
                this.riverEvents = this.getEvents(this.roundTextView.riverEvents, 'riverEvents');
                this.roundEvents = this.getEvents(this.roundTextView.roundEvents, 'roundEvents');
                this.showdownEvents = this.getEvents(this.roundTextView.showdownEvents, 'showdownEvents');
                this.turnEvents = this.getEvents(this.roundTextView.turnEvents, 'turnEvents');
                this.turnCommunityCards = this.getCommunityCards(this.roundTextView.turnEvents);
                this.thirdStreetEvents = this.getEvents(this.roundTextView.thirdStreetEvents, 'thirdStreetEvents');
                this.fourthStreetEvents = this.getEvents(this.roundTextView.fourthStreetEvents, 'fourthStreetEvents');
                this.fifthStreetEvents = this.getEvents(this.roundTextView.fifthStreetEvents, 'fifthStreetEvents');
                this.sixthStreetEvents = this.getEvents(this.roundTextView.sixthStreetEvents, 'sixthStreetEvents');
                this.seventhStreetEvents = this.getEvents(this.roundTextView.seventhStreetEvents, 'seventhStreetEvents');
                this.handId = this.roundTextView.roundReference.roundNumber;
                roundPlayers = this.roundTextView.roundPlayers;
                const playersInfo = this.roundPlayers;
                for (let j = this.showdownEvents.length - 1; j >= 0; j--) {
                    if (this.showdownEvents[j].handType.indexOf('WIN_') > -1) {
                        winners.push(this.showdownEvents[j]);
                        playersInfo[this.showdownEvents[j].seat + 'winAmount'] += this.showdownEvents[j].amount.unit;
                    }
                }
                winners = this.sortWinners(winners);
                this.winners = winners;
                this.roundPlayers = playersInfo;
                for (let i = 0; i < roundPlayers.length; i++) {
                    roundPlayers[i] = this.setRoundWinners(roundPlayers[i]);
                }
                // this.winnerNickName = this.roundPlayers[winners[0].seat];
                this.winnerNickName = this.roundTextView.winnerNickName.split('##')[0];
                this.winnerUserName = this.roundTextView.winnerNickName.split('##')[1];
                let pots = data.gameRoundJson.gameRound.pots,
                    totalPot = 0,
                    rakeAmount = 0,
                    monetaryType;
                for (let i = 0; i < pots.length; i++) {
                    totalPot += pots[i].amount.unit;
                    if (pots[i].rakeAmount) {
                        rakeAmount += pots[i].rakeAmount.unit;
                        monetaryType = pots[i].rakeAmount.monetaryType;
                    }
                }
                this.roundTextView['rake'] = {
                    monetaryType: monetaryType,
                    unit: rakeAmount,
                };
                if (!singleHand) {
                    let navRoundid;
                    navRoundid = this.checkForHands(element, 'prev', this.roundId);
                    if (navRoundid.length <= 0) {
                        this.prevDisabled = true;
                    } else {
                        this.prevDisabled = false;
                    }
                    navRoundid = this.checkForHands(element, 'next', this.roundId);
                    if (navRoundid.length <= 0) {
                        this.nextDisabled = true;
                    } else {
                        this.nextDisabled = false;
                    }
                } else {
                    this.serve.singleHandStatus = true;
                    this.serve.singleGameRound = data.gameRoundJson.gameRound;
                    if (window.outerWidth < 1366) {
                        this.roundIdError.next('');
                    }
                    if (!this.roundTextView.rake) {
                        this.serve.singleGameRound['rake'] = this.roundTextView.rake;
                    }
                    if (this.serve.singleGameRound.unBetPotSize) {
                        this.serve.singleGameRound['totalPotSize'] = totalPot + this.serve.singleGameRound.unBetPotSize;
                    } else {
                        this.serve.singleGameRound['totalPotSize'] = totalPot;
                    }
                }
                if (this.roundTextView.roundReference.gameCode === 'T5') {
                    this.tournamentType = true;
                } else {
                    this.tournamentType = false;
                }
                this.getExtraSidePot();
            },
            (error: any) => {
                const bodyElement = document.getElementsByTagName('body')[0];
                if (this.serve.singleHand) {
                    this.serve.singleHand = false;
                    this.serve.singleHandStatus = false;
                }
                if (bodyElement.className.indexOf('noscroll') >= 0) {
                    bodyElement.classList.remove('noscroll');
                }
                this.messageQueue.clear();
                if (error.errorCode === '403') {
                    const roundid = this.roundId.toString();
                    const spaceFinder = roundid.split(' ');
                    const testRoundId = parseInt(this.roundId).toString();
                    if (spaceFinder.length > 1) {
                        error.vnMessages[0].html = this.serve.content.messages.RoundIdSpaceError;
                    } else if (roundid.length != testRoundId.length) {
                        error.vnMessages[0].html = this.serve.content.messages.roundIdErrorMsg;
                    } else {
                        error.vnMessages[0].html =
                            this.serve.content.messages.HandWithId + ' "' + this.roundId + '" ' + this.serve.content.messages.NotFound;
                    }
                    this.messageQueue.add(error.vnMessages[0].html, MessageType.Error);
                } else {
                    this.messageQueue.add(error.message, MessageType.Error);
                }
            },
        );
    }

    setRoundWinners(player: any) {
        const showdownEvents = this.showdownEvents;
        for (let i = 0; i < showdownEvents.length; i++) {
            if (showdownEvents[i].handType === 'WIN_HIGH' && showdownEvents[i].seat === player.seat) {
                player['winStatus'] = true;
                break;
            }
        }
        return player;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getEvents(events: any, eventType: string) {
        let filteredEvents: any = [],
            cardHidden = false;
        if (events) {
            for (let i = 0; i < events.length; i++) {
                if (events[i].amount) {
                    if (!this.roundPlayers[events[i].seat + 'spentAmount']) {
                        this.roundPlayers[events[i].seat + 'spentAmount'] = events[i].amount.unit;
                    } else {
                        this.roundPlayers[events[i].seat + 'spentAmount'] += events[i].amount.unit;
                    }
                }
                if (
                    events[i].amount &&
                    events[i].amount.unit != 0 &&
                    this.roundPlayers[events[i].seat + 'spentAmount'] === this.roundPlayers[events[i].seat + 'startMoney']
                ) {
                    events[i]['allInSatus'] = true;
                }

                if (
                    events[i].handType === 'CARD_HIDDEN' &&
                    events[i].card != 'XX' &&
                    !cardHidden &&
                    (this.roundPlayers[events[i].seat + 'requestingPlayer'] || this.roundPlayers[events[i].seat] === this.userid)
                ) {
                    cardHidden = true;
                    events[i]['cards'] = [];
                    events[i]['cards'].push(events[i].card);
                    filteredEvents.push(events[i]);
                } else if (
                    events[i].handType === 'CARD_HIDDEN' &&
                    events[i].card != 'XX' &&
                    cardHidden &&
                    (this.roundPlayers[events[i].seat + 'requestingPlayer'] || this.roundPlayers[events[i].seat] === this.userid)
                ) {
                    for (let j = filteredEvents.length - 1; j >= 0; j--) {
                        if (filteredEvents[j].handType === 'CARD_HIDDEN') {
                            filteredEvents[j]['cards'].push(events[i].card);
                        }
                    }
                }
                if ((events[i].handType != 'CARD_HIDDEN' && this.events.hasOwnProperty(events[i].handType)) || events[i]['allInSatus']) {
                    filteredEvents.push(events[i]);
                }
            }
        }
        return filteredEvents;
    }

    getCommunityCards(events: any) {
        const communityCards: any = [];
        if (events) {
            for (let i = 0; i < events.length; i++) {
                if (events[i].handType === 'COMMUNITY_CARD') {
                    communityCards.push(events[i].card);
                }
            }
        }
        return communityCards;
    }

    checkForHands(element: any, direction: string, roundid: any) {
        let prevBlock: any,
            nextBlock: any,
            prevElement: any,
            nextElement: any,
            navRoundid: any = '';
        if (direction === 'prev') {
            prevElement = element.previousElementSibling;
        } else if (direction === 'next') {
            nextElement = element.nextElementSibling;
        }
        if ((!prevElement || prevElement.className.indexOf('hands-details') <= -1) && direction === 'prev') {
            prevBlock = document.querySelector('.hands-details[roundid="' + roundid + '"]');
            prevBlock = prevBlock.parentElement.parentElement;
            if (prevBlock && prevBlock.previousElementSibling && prevBlock.previousElementSibling.className.indexOf('grouped-block') > -1) {
                do {
                    prevBlock = prevBlock.previousElementSibling;
                    if (prevBlock.querySelector('.grouped-hands')) {
                        prevElement = prevBlock.querySelector('.hands-details:last-child');
                        break;
                    }
                } while (prevBlock.previousElementSibling);
            }
        } else if ((!nextElement || nextElement.className.indexOf('hands-details') <= -1) && direction === 'next') {
            nextBlock = document.querySelector('.hands-details[roundid="' + roundid + '"]');
            nextBlock = nextBlock.parentElement.parentElement;
            if (nextBlock && nextBlock.nextElementSibling && nextBlock.nextElementSibling.className.indexOf('grouped-block') > -1) {
                do {
                    nextBlock = nextBlock.nextElementSibling;
                    if (nextBlock.querySelector('.grouped-hands')) {
                        nextElement = nextBlock.querySelector('.hands-details');
                        break;
                    }
                } while (nextBlock.nextElementSibling);
            }
        }
        if (direction === 'prev' && prevElement && prevElement.className.indexOf('hands-details') > -1) {
            navRoundid = prevElement.getAttribute('roundid');
        } else if (direction === 'next' && nextElement && nextElement.className.indexOf('hands-details') > -1) {
            navRoundid = nextElement.getAttribute('roundid');
        }
        return navRoundid;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    goToTextView(direction: string, event: any) {
        let roundid = (document.getElementById('text-view-popup') as HTMLElement).getAttribute('roundid'),
            navRoundid,
            element: any = document.querySelector('.hands-details[roundid="' + roundid + '"]');
        navRoundid = this.checkForHands(element, direction, roundid);
        if (navRoundid.length > 0) {
            const handHistoryRoundInputModel: HandHistoryRoundInputModel = new HandHistoryRoundInputModel();
            handHistoryRoundInputModel.RoundId = navRoundid;
            this.getRoundTextView(handHistoryRoundInputModel, navRoundid);
        }
    }

    fixedToTwo(unit: number) {
        // return (unit/100).toFixed(2);
        return unit / 100;
    }

    toggleAmount(event: any) {
        if (
            event.handType != 'CHECK' &&
            event.handType != 'FOLD' &&
            event.handType != 'PLAYER_MUCK_CARDS' &&
            event.handType != 'CARD_SHOWN' &&
            event.handType != 'CARD_HIDDEN'
        ) {
            return true;
        } else {
            return false;
        }
    }

    toggleAllIn(event: any) {
        if (event.handType.indexOf('ALL_IN') > -1 || event['allInSatus']) {
            return true;
        } else {
            return false;
        }
    }

    toggleShowDown(event: any, type: string) {
        let flag = false;
        switch (type) {
            case 'wins':
                if (
                    this.roundTextView.game.gameType === 'OmahaHigh' &&
                    this.roundTextView.game.gameType.indexOf('SevenCard') <= -1 &&
                    event.handType.indexOf('WIN') > -1
                ) {
                    flag = true;
                }
                break;
            case 'amount':
                if (
                    event.handType != 'CHECK' &&
                    event.handType != 'FOLD' &&
                    event.handType != 'PLAYER_MUCK_CARDS' &&
                    event.handType != 'PLAYER_SHOW_CARDS'
                ) {
                    flag = true;
                }
                break;
        }
        return flag;
    }

    toggleSummary(event: any, type: string) {
        let flag = false;
        switch (type) {
            case 'playerDetails':
                if (event.net.unit != 0 || event.hasOwnProperty('winStatus') || event.bet.unit + event.net.unit > 0) {
                    flag = true;
                }
                break;
            case 'winnerStatus':
                if ((event.hasOwnProperty('winStatus') && event.winStatus) || event.bet.unit + event.net.unit > 0) {
                    flag = true;
                }
                break;
        }
        return flag;
    }

    checkEvents(events: any) {
        if (events && events.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    getExtraSidePot() {
        const pots = this.roundTextView.pots,
            roundPlayers = this.roundTextView.roundPlayers,
            showdownEvents = this.showdownEvents,
            winEvents: any = [];
        this.uncalledSidePots = [];
        for (let i = 0; i < showdownEvents.length; i++) {
            if (showdownEvents[i].handType.indexOf('WIN_') > -1) {
                winEvents.push(showdownEvents[i]);
            }
        }
        if (winEvents.length > pots.length) {
            for (let j = 0; j < winEvents.length; j++) {
                if (winEvents[j].potId >= pots.length) {
                    this.uncalledSidePots.push(winEvents[j]);
                }
            }
        }
        if (this.uncalledSidePots.length === 0) {
            for (let i = 0; i < roundPlayers.length; i++) {
                if (
                    this.roundPlayers[roundPlayers[i].seat + 'winAmount'] != 0 &&
                    roundPlayers[i].bet.unit + roundPlayers[i].net.unit > this.roundPlayers[roundPlayers[i].seat + 'winAmount']
                ) {
                    this.uncalledSidePots.push({
                        seat: roundPlayers[i].seat,
                        amount: {
                            monetaryType: roundPlayers[i].bet.monetaryType,
                            unit: roundPlayers[i].bet.unit + roundPlayers[i].net.unit - this.roundPlayers[roundPlayers[i].seat + 'winAmount'],
                        },
                        handType: 'WIN_HIGH',
                        potId: this.roundTextView.pots[this.roundTextView.pots.length - 1].id + 1,
                    });
                }
            }
        }
    }
}
