import { Message } from '../../message';

export class SuspiciousLocationAnswers extends Message {
    private MESSAGE_NAME: string = 'SuspiciousLocationAnswers';
    private suspiciuosQnAnsMap: Map<any, any> | null = null;
    private suspiciousLocationLoginStatus: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, suspiciuosQnAnsMap: Map<any, any> | null = null, suspiciousLocationLoginStatus: boolean | null = false) {
        super(reqServerPeerId);
        this.suspiciuosQnAnsMap = suspiciuosQnAnsMap;
        this.suspiciousLocationLoginStatus = suspiciousLocationLoginStatus;
    }

    getSuspiciuosQnAnsMap(): Map<any, any> | null {
        return this.suspiciuosQnAnsMap;
    }

    setSuspiciuosQnAnsMap(suspiciuosQnAnsMap: Map<any, any> | null) {
        this.suspiciuosQnAnsMap = suspiciuosQnAnsMap;
    }
    getSuspiciousLocationLoginStatus(): boolean | null {
        return this.suspiciousLocationLoginStatus;
    }

    setSuspiciousLocationLoginStatus(suspiciousLocationLoginStatus: boolean | null) {
        this.suspiciousLocationLoginStatus = suspiciousLocationLoginStatus;
    }
}
