import { Message } from '../../message';

export class HUDAnonymousState extends Message {
    private MESSAGE_NAME: string = 'HUDAnonymousState';
    private isAnonymousState: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, isAnonymousState: boolean | null = false) {
        super(reqServerPeerId);
        this.isAnonymousState = isAnonymousState;
    }

    getIsAnonymousState(): boolean | null {
        return this.isAnonymousState;
    }

    setIsAnonymousState(isAnonymousState: boolean | null) {
        this.isAnonymousState = isAnonymousState;
    }
}
