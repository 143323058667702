import { Message } from '../../message';

export class ResponseSNGJPPlayNowDeltaChanges extends Message {
    private MESSAGE_NAME: string = 'ResponseSNGJPPlayNowDeltaChanges';
    private snapShotTimeInNanos: number = 0;
    private domain: number = 0;
    private deltaEvents: any[] | null = null;
    private requestType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        snapShotTimeInNanos: number = 0,
        domain: number = 0,
        deltaEvents: any[] | null = null,
        requestType: number = 0,
    ) {
        super(reqServerPeerId);
        this.snapShotTimeInNanos = snapShotTimeInNanos;
        this.domain = domain;
        this.deltaEvents = deltaEvents;
        this.requestType = requestType;
    }

    getSnapShotTimeInNanos(): number {
        return this.snapShotTimeInNanos;
    }

    setSnapShotTimeInNanos(snapShotTimeInNanos: number) {
        this.snapShotTimeInNanos = snapShotTimeInNanos;
    }
    getDomain(): number {
        return this.domain;
    }

    setDomain(domain: number) {
        this.domain = domain;
    }
    getDeltaEvents(): any[] | null {
        return this.deltaEvents;
    }

    setDeltaEvents(deltaEvents: any[] | null) {
        this.deltaEvents = deltaEvents;
    }
    getRequestType(): number {
        return this.requestType;
    }

    setRequestType(requestType: number) {
        this.requestType = requestType;
    }
}
