import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { of } from 'rxjs';

import { SimulatedService } from '../../../../simulated-hands.service';
import { GameTable } from '../../models/game-table';
import { Pot } from '../../models/pot';
import { Properties } from '../../utils/properties';
import { DemoGameTableService } from '../game-table/demo-game-table.service';

@Component({
    selector: 'pkr-pot',
    templateUrl: './pot.component.html',
})
export class PotComponent implements OnInit, OnChanges {
    @Input() pot: Pot;
    @Input() gameTable: GameTable;
    @Input() totalPot: Pot;
    posX: any;
    posY: any;
    content: any;
    chiparray: any = [];
    chipKeys: any = [];
    reverseChipKeys: any = [];
    amount: any;

    sidePotContent: any;
    sidePotContentHeight: any;
    status: boolean = false;
    hideDiv: boolean;
    isMobile: any;
    arr: any = [];
    itemsInRow: number = 4;
    itemInRowRange: any;
    items: any;

    constructor(
        public serve: SimulatedService,
        private gameTableService: DemoGameTableService,
    ) {}

    ngOnInit() {
        this.content = this.serve.content;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(temp: any) {
        this.isMobile = this.gameTableService.isMobile;
        if (this.gameTable.isReplayerV3) {
            this.noOfChips(this.gameTable.currentActivePot);
        } else {
            this.noOfChips(this.totalPot);
        }
    }

    getXaxis() {
        this.posX = this.gameTable.changeChipPos.x;
        return `${this.posX}%`;
    }
    getYaxis() {
        this.posY = this.gameTable.changeChipPos.y;
        return `${this.posY}%`;
    }
    getDollars(val: number) {
        return val;
    }

    getFixedXAxis() {
        const pos = Properties.propFile[this.gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
        this.posX = pos[0];
        return `${this.posX}%`;
    }
    getFixedYAxis() {
        const pos = Properties.propFile[this.gameTable.gameType][`MAINPOTCHIPPOS`].split('#');
        this.posY = pos[1];
        return `${this.posY}%`;
    }

    getCents(val: any) {
        return val;
    }
    noOfChips(totalVal: any) {
        const noOfChiparray: any = [];
        let obj;
        let denom;
        const combo: any = new Map();
        // let denom = [100000000000, 50000000000, 10000000000, 2500000000, 500000000, 100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000, 2500, 500, 100, 25, 5, 1];
        if (this.isMobile) {
            // totalVal = totalVal/100;
            //refer   = [ $5000000000, $1000000000, $250000000, $50000000, $10000000, $5000000, $1000000,  $500000,   $100000, $25000, $5000,  $1000   $500,  $100, $25,  $5, $1,  50c, 25c, 10c, 5c,1c ]
            denom = [
                100000000000, 50000000000, 10000000000, 2500000000, 500000000, 100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000,
                2500, 500, 100, 50, 25, 10, 5, 1,
            ];
        } else {
            //for desktop or VC
            denom = [
                100000000000, 50000000000, 10000000000, 2500000000, 500000000, 100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000,
                2500, 500, 100, 25, 5, 1,
            ];
        }
        for (let i = 0; i < denom.length; i++) {
            if (totalVal <= 0) break;
            let nchips: any = Math.floor(totalVal / denom[i]);
            if (nchips == 0) continue;
            const nVal = denom[i];
            totalVal -= nVal * nchips;
            for (let j = 0; j < nchips; j++) {
                if (nchips > 0) {
                    const chipM: any = new Map();
                    chipM.set(nVal, nchips);
                    combo.set(nVal, nchips);
                    nchips = Array(nchips).fill(1);
                    obj = [
                        {
                            nval: nVal ? `c${nVal}` : 0,
                            nChips: nchips ? nchips : 0,
                        },
                    ];
                    noOfChiparray[nVal] = obj;
                    this.chiparray = noOfChiparray;
                    this.chipKeys = Object.keys(this.chiparray);
                }
            }
            this.reverseChipKeys = this.chipKeys.slice().reverse();
        }
        if (combo.size > 0) {
            let i = 0;
            this.arr = [];
            const iterate = combo.values();
            const iterate1 = combo.keys();
            let check = iterate.next().value;
            let check2 = iterate1.next().value;
            for (let k = 0; k < combo.size; k++) {
                for (let j = 0; j < check; j++) {
                    this.arr[i] = `c${check2}`;
                    i++;
                }
                check = iterate.next().value;
                check2 = iterate1.next().value;
            }
        }
        this.itemInRowRange = Array.from(Array(this.itemsInRow).keys());
        this.items = of(this.arr);
    }

    formatChange(sidepotValue?: any) {
        if (sidepotValue) {
            this.formatCount(sidepotValue, true, 2, this.isMobile);
        } else {
            this.formatCount(this.gameTable.ROUND_POT.totalValue, true, 2, this.isMobile);
        }
    }

    formatCount(count: any, withAbbr: boolean, decimals: number, isMobile: boolean) {
        let result: any;
        count = count / 100;
        if (isMobile && count > 999999) {
            const COUNT_ABBRS: any = ['', '', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
            const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
            result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
            if (withAbbr) {
                result += `${COUNT_ABBRS[i]}`;
            }
            this.amount = result;
        } else {
            this.amount = count;
            //this.amount = this.amount.toString();
            if (!this.gameTable.isTourny) {
                this.amount = this.amount.toLocaleString(undefined, { minimumFractionDigits: 2 });
            }
            this.amount = this.amount.toString();
            this.amount = this.amount.replace(/\.00$/, '');
            this.amount = this.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        // if (this.amount.length > 6) {
        // this.isAmountTruncation = true;
        // }
    }
}
