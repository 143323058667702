import { Message } from '../../message';

export class TableOccupancy extends Message {
    private MESSAGE_NAME: string = 'TableOccupancy';
    private tableId: number = 0;
    private serverPeerId: number = 0;
    private playerCount: number = 0;
    private waitingCount: number = 0;
    private avgPot: number = 0;
    private tourneyGameStatus: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        serverPeerId: number = 0,
        playerCount: number = 0,
        waitingCount: number = 0,
        avgPot: number = 0,
        tourneyGameStatus: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.serverPeerId = serverPeerId;
        this.playerCount = playerCount;
        this.waitingCount = waitingCount;
        this.avgPot = avgPot;
        this.tourneyGameStatus = tourneyGameStatus;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getWaitingCount(): number {
        return this.waitingCount;
    }

    setWaitingCount(waitingCount: number) {
        this.waitingCount = waitingCount;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
}
