import { Message } from '../../message';

export class ResponseRebuyAddonAccClosureFailure extends Message {
    private MESSAGE_NAME: string = 'ResponseRebuyAddonAccClosureFailure';
    private rebuyAddonAccountClosureFailureMessage: any | null = null;
    private responseId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, rebuyAddonAccountClosureFailureMessage: any | null = null, responseId: number = 0) {
        super(reqServerPeerId);
        this.rebuyAddonAccountClosureFailureMessage = rebuyAddonAccountClosureFailureMessage;
        this.responseId = responseId;
    }

    getRebuyAddonAccountClosureFailureMessage(): any | null {
        return this.rebuyAddonAccountClosureFailureMessage;
    }

    setRebuyAddonAccountClosureFailureMessage(rebuyAddonAccountClosureFailureMessage: any | null) {
        this.rebuyAddonAccountClosureFailureMessage = rebuyAddonAccountClosureFailureMessage;
    }
    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
}
