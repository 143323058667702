<div class="main-tile-list">
    <div class="tile-item">
        <div class="tile-data-content">
            <div class="tile-item-title" [innerHTML]="threeTiles.tile1.title">{{ threeTiles.title }}</div>
            <div class="tile-item-description" [innerHTML]="threeTiles.tile1.description">{{ threeTiles.description }}</div>
        </div>
        <div class="tile-item-image">
            <img [src]="threeTiles.tile1.image.src" alt="Three Tiles" class="tile-item-image" />
        </div>
    </div>
    <div class="tile-item">
        <div class="tile-data-content">
            <div class="tile-item-title" [innerHTML]="threeTiles.tile2.title"></div>
            <div class="tile-item-description" [innerHTML]="threeTiles.tile2.description"></div>
        </div>
        <div class="tile-item-image">
            <img [src]="threeTiles.tile2.image.src" alt="Three Tiles" class="tile-item-image" />
        </div>
    </div>
    <div class="tile-item">
        <div class="tile-data-content">
            <div class="tile-item-title" [innerHTML]="threeTiles.tile3.title"></div>
            <div class="tile-item-description" [innerHTML]="threeTiles.tile3.description"></div>
        </div>
        <div class="tile-item-image">
            <img [src]="threeTiles.tile3.image.src" alt="Three Tiles" class="tile-item-image" />
        </div>
    </div>
</div>
