import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routeData } from '@frontend/vanilla/core';

import { HandHistoryComponent } from './hand-history.component';
import { HandHistoryModule } from './hand-history.module';
import { HandHistoryResolver } from './hand-history.resolver';

export const handHistoryRoutes: Routes = [
    {
        path: '',
        component: HandHistoryComponent,
        resolve: {
            initData: HandHistoryResolver,
        },
        data: routeData({ allowAllWorkflowTypes: true, allowAuthorized: true }),
    },
];

@NgModule({
    imports: [RouterModule.forChild(handHistoryRoutes), HandHistoryModule],
    exports: [RouterModule],
})
export class HandHistoryRoutingModule {}
