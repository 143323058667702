import { Message } from '../../message';

export class AccountCurrencyChange extends Message {
    private MESSAGE_NAME: string = 'AccountCurrencyChange';
    private currentCurrencyCode: string | null = null;
    private newCurrencyCode: string | null = null;
    private type: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, currentCurrencyCode: string | null = null, newCurrencyCode: string | null = null, type: number = 0) {
        super(reqServerPeerId);
        this.currentCurrencyCode = currentCurrencyCode;
        this.newCurrencyCode = newCurrencyCode;
        this.type = type;
    }

    getCurrentCurrencyCode(): string | null {
        return this.currentCurrencyCode;
    }

    setCurrentCurrencyCode(currentCurrencyCode: string | null) {
        this.currentCurrencyCode = currentCurrencyCode;
    }
    getNewCurrencyCode(): string | null {
        return this.newCurrencyCode;
    }

    setNewCurrencyCode(newCurrencyCode: string | null) {
        this.newCurrencyCode = newCurrencyCode;
    }
    getType(): number {
        return this.type;
    }

    setType(type: number) {
        this.type = type;
    }
}
