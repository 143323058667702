import * as PIXI from 'pixi.js';

import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { IndividualPlayerView } from '../views/IndividualPlayerView';

export class PlayerView extends PIXI.Container {
    gameService: any;
    EventDispatcher: any;
    peerId: any;
    displayUtil: DisplayUtil = new DisplayUtil();
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    constructor(gs, EventDispatcher, peerId) {
        super();
        this.gameService = gs;
        this.EventDispatcher = EventDispatcher;
        this.peerId = peerId;
    }
    createView = function (vm) {
        this.viewModel = vm;
        this.maxPlayerCount = this.gameService.getMaxNoOfSeats();
        this.positionConfig = uiConfig.controlPanel.config.ControlPanelUIDataMPortrait['TableData' + this.maxPlayerCount + 'MaxPos'];
        if (this.maxPlayerCount == undefined || this.maxPlayerCount == null) this.maxPlayerCount = 3;
        this.playersArray = [];
        this.playerContainer = this.displayUtil.getContainer();
        this.playerContainer.name = 'PlayerView';
        this.addChild(this.playerContainer);
        for (let i = 0; i < this.maxPlayerCount; i++) {
            const player = new IndividualPlayerView(this.EventDispatcher, this.gameService);
            // not needed as it is creating issue in player container alpha
            //player.seatingPosition = i;
            player.createView(i, this.maxPlayerCount);
            this.playersArray.push(player);
            this.playerContainer.addChild(player);
        }
        this.isCashoutEnabled = false;
        // ResponsiveContainer.call(this.playerContainer, {
        //   portX: 0,
        //   portY:  0,
        //   EventDispatcher: this.EventDispatcher
        // });
        // this.playerContainer.setSize(720, 1280);
        // this.dealerImg = this.displayUtil.getSprite("dealer");
        // this.dealerImg.scale.set(0.8);
        // this.addChild(this.dealerImg);
        // this.dealerImg.visible = false;
        this.betArray = [[], [], []];
        uiConfig['tableConfig' + this.peerId]['playerSeatInx'] = {};
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.Disable_All_In_Glow, this.disableAllInGlow.bind(this));
        this.EventDispatcher.addEventListener(SlotCoreGameEvent.BOOSTER_ENABLED, this.boosterEnabled.bind(this));
    };
    handlePlayerStatus = function (playerStatusData) {
        //need to change once we include avatar images in assets
        //this.defaultPlate.setTexture(playersData[j].avatarImage);
        const maxNoOfSeats = this.gameService.getMaxNoOfSeats();
        if (!uiConfig.selfUserId) {
            //uiConfig.selfUserId = window.parent.LSConnector.mainConnector.selfUserId;
            uiConfig.selfUserId = 0;
        }
        if (!uiConfig.selfUserId) {
            //  console.log("seating issue undefined uiconfig selfuserId");
            return;
        }
        for (const j in playerStatusData) {
            if (uiConfig.selfUserId === playerStatusData[j].playerId) {
                if (this.viewModeOnly) {
                    this.clearAllPlayers(playerStatusData);
                    //uiConfig["tableConfig" + this.peerId].playerSeatInx = {};
                }
                this.selfPlayerSeatDifference =
                    maxNoOfSeats -
                    playerStatusData[j].seatingPosition + //seats from self user till end
                    uiConfig.selfUserClientPosInd; //seats till self user
                if (this.viewModeOnly) {
                    this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.FORCE_REFRESH_TABLE);
                }
                this.viewModeOnly = false;
                break;
            }
        }
        if (this.selfPlayerSeatDifference === undefined) {
            //  console.log("seating issue, self player not found in playerstatus" + uiConfig.selfUserId);
            this.viewModeOnly = true;
            this.selfPlayerSeatDifference = 0;
        }
        for (const i in playerStatusData) {
            const tempPlayerSeatInd = (this.selfPlayerSeatDifference + playerStatusData[i].seatingPosition) % maxNoOfSeats;
            uiConfig['tableConfig' + this.peerId].playerSeatInx[playerStatusData[i].seatingPosition] = tempPlayerSeatInd;
            //this.playersArray[playerStatusData[i].seatingPosition].seatingPosition = tempPlayerSeatInd;
            this.playersArray[tempPlayerSeatInd].seatingPosition = playerStatusData[i].seatingPosition;
            this.playersArray[tempPlayerSeatInd].clientSeatPosition = tempPlayerSeatInd;
            this.playersArray[tempPlayerSeatInd].handlePlayerStatus(playerStatusData[i]);
        }
    };
    hidePlayerBalance = function () {
        for (let i = 0; i < this.playersArray.length; i++) {
            this.playersArray[i].hidePlayerBalance();
        }
    };
    showPlayerBalance = function () {
        for (let i = 0; i < this.playersArray.length; i++) {
            this.playersArray[i].showPlayerBalance();
        }
    };
    updateAddPlayerData = function (addPlayerData) {
        if (!uiConfig.selfUserId) {
            //  console.log("seating issue undefined uiconfig selfuserId");
            return;
        }
        if (this.selfPlayerSeatDifference === undefined || this.viewModeOnly) {
            //for cash games, after sitresponse, we get addplayer. in this case refresh all seats
            if (addPlayerData.playerId == uiConfig.selfUserId) {
                //since we show all players in server position till now, we need to refresh entire table
                this.gameTableNotificationService.sendRequestGameStatus(this.peerId);
                this.handlePlayerStatus(this.gameService.getPlayerSeatingData());
                return;
            } else {
                // console.log("seating issue, self player not found in addPlayer  " + uiConfig.selfUserId);
                return;
            }
        }
        const maxNoOfSeats = this.gameService.gameStateModel.getMaxNoOfSeats();
        const tempPlayerSeatInd = (this.selfPlayerSeatDifference + addPlayerData.seatingPosition) % maxNoOfSeats;
        uiConfig['tableConfig' + this.peerId].playerSeatInx[addPlayerData.seatingPosition] = tempPlayerSeatInd;
        this.playersArray[tempPlayerSeatInd].seatingPosition = addPlayerData.seatingPosition;
        this.playersArray[tempPlayerSeatInd].clientSeatPosition = tempPlayerSeatInd;
        this.playersArray[tempPlayerSeatInd].updateAddPlayerData(addPlayerData);
    };
    updateOthPlayerSelectedOpt = function (selectedOptData) {
        //let obj = {"amount":4000,"betVector":[4000],"isAllIn":false,"optionId":2,"seatNo":2,"msgNumber":0,"peerId":5133293,"className":"SelectedOption"}
        const seatVal = this.gameService.getClientSideSeatNo(selectedOptData.seatNo);
        if (this.playersArray[seatVal]) {
            this.playersArray[seatVal].updateOthPlayerSelectedOpt(selectedOptData, seatVal);
        }
        this.removeBetTimer(this.playersArray[seatVal]);
    };
    hidePlayerAction = function (seatNo) {
        if (this.playersArray[seatNo]) {
            this.playersArray[seatNo].hidePlayerAction();
        }
    };
    showLockIconAnim = function (seatNo) {
        if (this.playersArray[seatNo]) {
            this.playersArray[seatNo].showLockIconAnim();
        }
    };
    clearBetTimer = function () {
        for (let j = 0; j < this.playersArray.length; j++) {
            this.playersArray[j].clearBetTimer();
        }
    };
    showPotWinners = function (winnersData) {
        const len = winnersData.length;
        if (len < 1) {
            return;
        }
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < this.playersArray.length; j++) {
                if (this.playersArray[j].playerId === winnersData[i].winnerId && winnersData[i].isCashoutEqtyWinner == 0) {
                    try {
                        // this.playersArray[
                        //   this.gameService.getClientSideSeatNo(this.gameService.getPlayerSeatNo(winnersData[i].winnerId))
                        // ].showPotWin(
                        //   winnersData[i].amount, potNum,
                        //   this.gameService.getClientSideSeatNo(this.gameService.getPlayerSeatNo(winnersData[i].winnerId)));
                        break;
                    } catch (e) {
                        //console.log(e);
                    }
                }
            }
        }
    };
    showBetTimer = function (timerData, allInForSelf) {
        this.timePeriodToAct = timerData.period;
        this.timeoutSeatNo = timerData.seatNo;
        this.peerId - timerData.peerId;
        const peerTime = this.gameTableNotificationService.getTimeForPeer(this.peerId);
        const timeDiffToShow = Math.floor(timerData.period - peerTime);
        const ceiltimeDiffToShow = Math.ceil(timerData.period - peerTime);
        for (let j = 0; j < this.playersArray.length; j++) {
            if (this.isCashoutEnabled == true && j == 0) continue;
            this.playersArray[j].removeBetTimer();
        }
        const clientSeatNo = this.gameService.getClientSideSeatNo(timerData.seatNo);
        // console.log(clientSeatNo + "clientSeatNo");
        this.playersArray[clientSeatNo].showBetTimer(timeDiffToShow, allInForSelf);
        this.currentBetTimerInd = clientSeatNo + 1;
        // console.log(this.currentBetTimerInd + "currentBetTimerInd");
        this.playersArray[clientSeatNo].checkTimeBankAni(timerData.timeOutType, ceiltimeDiffToShow, clientSeatNo);
    };
    callYouWinAni = function (seatNo, amount, totalWinAmount, isUnCalledBet) {
        this.playersArray[seatNo].callYouWinAni(seatNo, amount, totalWinAmount, isUnCalledBet);
    };
    disableAllInGlow = function () {
        for (let i = 0; i < this.playersArray.length; i++) {
            this.playersArray[i].disableAllInGlow();
        }
    };
    showOtherPlayerRabit = function (seatNo) {
        this.playersArray[seatNo].showOtherPlayerRabit(seatNo);
    };
    removeBetTimer = function () {
        if (this.currentBetTimerInd) {
            this.playersArray[this.currentBetTimerInd - 1].removeBetTimer();
        }
    };
    showUpdatedDealerPos = function (DealerSeatPos) {
        if (DealerSeatPos == -1 || DealerSeatPos == undefined) {
            // this.dealerImg.visible = false;
            return;
        }
        //let tempSeatPos=DealerSeatPos;
        const tempSeatPos = this.gameService.getClientSideSeatNo(DealerSeatPos);
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            this.playersArray[i].dealerImg.visible = false;
        }
        this.playersArray[tempSeatPos].showDealerBtnPos(tempSeatPos);
    };
    updatePlayerBalances = function (balances) {
        for (const i in balances) {
            this.playersArray[this.gameService.getClientSideSeatNo(balances[i].seatNo)].updatePlayerBalance(
                balances[i],
                this.gameService.getBetVector(balances[i].seatNo),
            );
        }
    };
    showPopUpOnWidget = function (widPopUpContainer) {
        this.popUpContainer = this.displayUtil.getContainer();
        this.addChild(this.popUpContainer);
        this.popUpContainer.addChild(widPopUpContainer);
        widPopUpContainer.visible = true;
    };
    hidePopUpOnWidget = function (widPopUpContainer) {
        if (this.popUpContainer) {
            this.removeChild(this.popUpContainer);
            this.popUpContainer.removeChild(widPopUpContainer);
            widPopUpContainer.visible = false;
        }
    };
    resetGame = function () {
        this.isCashoutEnabled = false;
        uiConfig.currentSelfUserSeatInd = null;
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            const isSeatOccupied = this.gameService.getIsSeatOccupied(this.playersArray[i].seatingPosition);
            this.playersArray[i].resetGame(isSeatOccupied, i);
        }
    };
    disableAllinAnim = function () {
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            const isSeatOccupied = this.gameService.getIsSeatOccupied(this.playersArray[i].seatingPosition);
            this.playersArray[i].enableCashOut();
            this.playersArray[i].disableAllinAnim(isSeatOccupied, i);
        }
    };
    boosterEnabled = function () {
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            this.playersArray[i].isBoosterEnabled = true;
        }
    };
    clearAllPlayers = function (playerStatusData) {
        const isTourneyStarted = this.gameService.getIsTourneyStarted();
        for (const i in playerStatusData) {
            if (playerStatusData[i].seatingPosition != undefined) {
                playerStatusData[i].seatNumber = playerStatusData[i].seatingPosition;
            } else {
                playerStatusData[i].seatNumber = playerStatusData[i].seatNo;
            }
            this.removePlayer(playerStatusData[i], this.gameService.getTableStatusData(), isTourneyStarted);
        }
    };
    removePlayer = function (removePlayerData, gameTableStatus, isTourneyStarted) {
        const clientSeatNo = this.gameService.getClientSideSeatNo(removePlayerData.seatNumber);
        if (clientSeatNo == uiConfig.selfUserClientPosInd) this.selfPlayerSeatDifference = undefined;
        if (this.playersArray[clientSeatNo]) {
            this.playersArray[clientSeatNo].updateRemovePlayerData(removePlayerData, gameTableStatus, isTourneyStarted);
            this.playersArray[clientSeatNo].seatingPosition = null;
            this.playersArray[clientSeatNo].clientSeatPosition = null;
        }
        this.EventDispatcher.dispatchEvent(SlotCoreGameEvent.HIDE_CARDS, clientSeatNo);
    };
    removeAnimations = function () {
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            this.playersArray[i].removeAnimations();
        }
    };
    handleNewGame = function (gameId) {
        for (const i in this.playersArray) {
            if (this.playersArray) this.playersArray[i].handleNewGame(gameId);
        }
    };
    handleAnteData = function (antedata, userBalance) {
        this.playersArray[this.gameService.getClientSideSeatNo(antedata.seatNo)].handleAnteData(antedata, userBalance);
    };
    handleBlindData = function (blindData, userBalance) {
        this.playersArray[this.gameService.getClientSideSeatNo(blindData.seatNo)].handleBlindData(blindData, userBalance);
    };
    handlechangePlayerState = function (playerStateData) {
        this.playersArray[this.gameService.getClientSideSeatNo(playerStateData.seatNo)].handlechangePlayerState(playerStateData);
        for (let i = 0; i < this.gameService.getMaxNoOfSeats(); i++) {
            const isSeatOccupied = this.gameService.getIsSeatOccupied(this.playersArray[i].seatingPosition);
            if (isSeatOccupied != true) this.playersArray[i].disableAllinAnim(true, i);
        }
    };
    playTimerSound = function () {};
    disablePlayerClick = function () {
        for (let k = 0; k < this.playersArray.length; k++) {
            this.playersArray[k].disablePlayerClick();
        }
    };
    enablePlayerClick = function () {
        for (let k = 0; k < this.playersArray.length; k++) {
            this.playersArray[k].enablePlayerClick();
        }
    };
    enableTimeBankNotifyAnim = function (time, seatNO) {
        if (seatNO != uiConfig.selfUserClientPosInd) {
            this.playersArray[seatNO].callOtherPlayerTimeBank(seatNO, time);
        }
    };
    removeTotalWinAmnt = function () {
        for (let k = 0; k < this.playersArray.length; k++) {
            this.playersArray[k].removeTotalWinAmnt();
        }
    };
    setCashoutEnabled = function () {
        this.isCashoutEnabled = true;
    };
    cashOutAnim = function (seatNo) {
        this.cashEquityPlayersArray[seatNo].showCashOutAnim(seatNo);
    };
    setCashoutDisabled = function () {
        this.isCashoutEnabled = false;
    };
    disableShowPlayerProfile = function (seatNo) {
        this.playersArray[seatNo].disablePlayerClick();
    };
    enableShowPlayerProfile = function (seatNo) {
        this.playersArray[seatNo].enablePlayerClick();
    };
}
