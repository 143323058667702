import { Message } from '../../message';

export class RequestTourneyLobbyActive extends Message {
    private MESSAGE_NAME: string = 'RequestTourneyLobbyActive';
    private active: boolean | null = false;
    private mtctId: number = 0;
    private tournamentCategory: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, active: boolean | null = false, mtctId: number = 0, tournamentCategory: number = 0) {
        super(reqServerPeerId);
        this.active = active;
        this.mtctId = mtctId;
        this.tournamentCategory = tournamentCategory;
    }

    getActive(): boolean | null {
        return this.active;
    }

    setActive(active: boolean | null) {
        this.active = active;
    }
    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getTournamentCategory(): number {
        return this.tournamentCategory;
    }

    setTournamentCategory(tournamentCategory: number) {
        this.tournamentCategory = tournamentCategory;
    }
}
