import { Message } from '../../message';

export class ResponseQuickSeatFastForward extends Message {
    private MESSAGE_NAME: string = 'ResponseQuickSeatFastForward';
    private requestId: number = 0;
    private responseCode: number = 0;
    private poolId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, requestId: number = 0, responseCode: number = 0, poolId: number = 0) {
        super(reqServerPeerId);
        this.requestId = requestId;
        this.responseCode = responseCode;
        this.poolId = poolId;
    }

    getRequestId(): number {
        return this.requestId;
    }

    setRequestId(requestId: number) {
        this.requestId = requestId;
    }
    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getPoolId(): number {
        return this.poolId;
    }

    setPoolId(poolId: number) {
        this.poolId = poolId;
    }
}
