export class CSD {
    public static REAL = 0;
    public static PLAY = 1;
    public static SOCIAL = 2;
    public static NON_GAMING = 3;

    public static DUMMY_PLAY_DOMAIN_PEERID = -100;
    public static DUMMY_REAL_DOMAIN_PEERID = -200;
    public static DUMMY_NON_GAMING_DOMAIN_PEERID = -300;
    public static DUMMY_SOCIAL_DOMAIN_PEERID = -400;

    static goodConnection: number = 1;
    static NO_CONNECTION: number = -1;
    static CONNECTION_BAD = 3;
    static SHOW_RECONNECTION_POPUP = 4;

    static UNSUBSCRIBE_WITH_SERVER = 2;

    static LOG_INFO = 1;
    static LOG_ERROR = -1;
    static LOG_WARN = 2;
}
