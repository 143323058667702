<div class="flex-container">
    <div class="content">
        <div class="header-wrapper">
            <div class="header">Table Balance Limit</div>
            <span (click)="closePopUp()" class="cross1"></span>
        </div>

        <div class="content-wrapper">
            <div class="container">
                <img [src]="balanceIcons.detablebalance.image.src" alt="DE Table Balance Feature" />
            </div>
            <div class="content-text-wrapper">
                <div class="text-one">
                    {{ balanceLimitInfo }}
                </div>
                <div class="button-wrapper d-flex">
                    <button class="close-btn" (click)="closePopUp()">{{ btnText }}</button>
                    <button class="nav-btn" (click)="doNotShow()">{{ doNotShowText }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
