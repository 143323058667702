import { Message } from '../../message';

export class HyperLinkedDealerChat extends Message {
    private MESSAGE_NAME: string = 'HyperLinkedDealerChat';
    private sender: string | null = null;
    private type: number = 0;
    private message: any | null = null;
    private tagId: number = 0;
    private tagAction: string | null = null;
    private tableName: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sender: string | null = null,
        type: number = 0,
        message: any | null = null,
        tagId: number = 0,
        tagAction: string | null = null,
        tableName: any | null = null,
    ) {
        super(reqServerPeerId);
        this.sender = sender;
        this.type = type;
        this.message = message;
        this.tagId = tagId;
        this.tagAction = tagAction;
        this.tableName = tableName;
    }

    getSender(): string | null {
        return this.sender;
    }

    setSender(sender: string | null) {
        this.sender = sender;
    }
    getType(): number {
        return this.type;
    }

    setType(type: number) {
        this.type = type;
    }
    getMessage(): any | null {
        return this.message;
    }

    setMessage(message: any | null) {
        this.message = message;
    }
    getTagId(): number {
        return this.tagId;
    }

    setTagId(tagId: number) {
        this.tagId = tagId;
    }
    getTagAction(): string | null {
        return this.tagAction;
    }

    setTagAction(tagAction: string | null) {
        this.tagAction = tagAction;
    }
    getTableName(): any | null {
        return this.tableName;
    }

    setTableName(tableName: any | null) {
        this.tableName = tableName;
    }
}
