import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

// Initial Value
export const initialUserAuthenticationState = false;
export const initialUserSSO = null;
export const initialUserProfileState = {};
export const initialuserBalance = {};
export const initialuserTicket: number = 0;
export const initialLoginFailure: boolean = false;
export const duplicateLogin: boolean = false;

// selector while consuming store.
export const selectIsAuthenticated = createFeatureSelector<boolean>('IsAuthenticated');
export const selectUserSSO = createFeatureSelector<string>('UserSSOKey');
export const selectProfileState = createFeatureSelector<string>('UserProfile');
export const selectUserBalance = createFeatureSelector<string>('UserBalance');
export const selectUserTicket = createFeatureSelector<number>('UserTicket');
export const selectLoginFailure = createFeatureSelector<boolean>('LoginFailure');
export const selectDuplicateLogin = createFeatureSelector<boolean>('DuplicateLogin');

// create Selector
export const userIsAuthenticatedSelect = createSelector(selectIsAuthenticated, (state: boolean) => state);
export const userSSOSelect = createSelector(selectUserSSO, (state: string) => state);
export const userProfileSelect = createSelector(selectProfileState, (state: string) => state);
export const userBalanceSelect = createSelector(selectUserBalance, (state: string) => state);
export const userTicketSelect = createSelector(selectUserTicket, (state: number) => state);
export const userLoginFailureSelect = createSelector(selectLoginFailure, (state: boolean) => state);
export const userDuplicateLoginSelect = createSelector(selectDuplicateLogin, (state: boolean) => state);

// create Action
export const updateIsAuthenticatedStatus = createAction('[User] IsAuthenticated', (isAuthenticated: boolean) => ({ isAuthenticated }));
export const updateUserSSO = createAction('[User] update user sso key', (ssoKey: any) => ({ ssoKey }));
export const updateUserProfile = createAction('[User] update User Profile', (user: any) => ({ user }));
export const updateUserBalance = createAction('[User] update User Balance', (user: any) => ({ user }));
export const updateUserTicket = createAction('[User] update User Ticket', (user: number) => ({ user }));
export const updateLoginFailure = createAction('[User] update Login Failure', (loginFailure: boolean) => ({ loginFailure }));
export const updateDuplicateLogin = createAction('[User] update Duplicate Login', (duplicateLogin: boolean) => ({ duplicateLogin }));

// create Reducer
export const userIsAuthenticatedReducer = createReducer(
    initialUserAuthenticationState,
    on(updateIsAuthenticatedStatus, (state, { isAuthenticated }) => isAuthenticated),
);
export const userSSOKeyReducer = createReducer(
    initialUserSSO,
    on(updateUserSSO, (state, { ssoKey }) => ssoKey),
);
export const userProfileReducer = createReducer(
    initialUserProfileState,
    on(updateUserProfile, (state, { user }) => user),
);
export const userBalanceReducer = createReducer(
    initialuserBalance,
    on(updateUserBalance, (state, { user }) => user),
);
export const userTicketReducer = createReducer(
    initialuserTicket,
    on(updateUserTicket, (state, { user }) => user),
);
export const userLoginFailureReducer = createReducer(
    initialLoginFailure,
    on(updateLoginFailure, (state, { loginFailure }) => loginFailure),
);
export const userDuplicateLoginReducer = createReducer(
    duplicateLogin,
    on(updateDuplicateLogin, (state, { duplicateLogin }) => duplicateLogin),
);
