import { Message } from '../../message';

export class RequestJoinOtherDomainWaitlist extends Message {
    private MESSAGE_NAME: string = 'RequestJoinOtherDomainWaitlist';
    private tableid: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableid: number = 0) {
        super(reqServerPeerId);
        this.tableid = tableid;
    }

    getTableid(): number {
        return this.tableid;
    }

    setTableid(tableid: number) {
        this.tableid = tableid;
    }
}
