import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PPLGalleryContainerPageMatrixBootstrapService } from './ppl-gallery-container.bootstrapservice';
import { PPLGalleryContainerService } from './ppl-gallery-container.service';

export function providePPLGalleryContainer(): Provider[] {
    return [runOnAppInit(PPLGalleryContainerPageMatrixBootstrapService), PPLGalleryContainerService];
}
