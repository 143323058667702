import { Message } from '../../message';

export class ArjelGamePlayerAction extends Message {
    private MESSAGE_NAME: string = 'ArjelGamePlayerAction';
    private date: number = 0;
    private seatNo: number = 0;
    private action: number = 0;
    private actionAmtIfAny: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, date: number = 0, seatNo: number = 0, action: number = 0, actionAmtIfAny: number = 0) {
        super(reqServerPeerId);
        this.date = date;
        this.seatNo = seatNo;
        this.action = action;
        this.actionAmtIfAny = actionAmtIfAny;
    }

    getDate(): number {
        return this.date;
    }

    setDate(date: number) {
        this.date = date;
    }
    getSeatNo(): number {
        return this.seatNo;
    }

    setSeatNo(seatNo: number) {
        this.seatNo = seatNo;
    }
    getAction(): number {
        return this.action;
    }

    setAction(action: number) {
        this.action = action;
    }
    getActionAmtIfAny(): number {
        return this.actionAmtIfAny;
    }

    setActionAmtIfAny(actionAmtIfAny: number) {
        this.actionAmtIfAny = actionAmtIfAny;
    }
}
