import { Message } from '../../message';
import { ChallengeDetails } from './ChallengeDetails';
import { GameTableStatus } from './GameTableStatus';

export class AcceptChallengeResponse extends Message {
    private MESSAGE_NAME: string = 'AcceptChallengeResponse';
    private challengeId: number = 0;
    private errorCode: number = 0;
    private gameTableStatus: GameTableStatus | null = null;
    private challengeDetails: ChallengeDetails | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        challengeId: number = 0,
        errorCode: number = 0,
        gameTableStatus: GameTableStatus | null = null,
        challengeDetails: ChallengeDetails | null = null,
    ) {
        super(reqServerPeerId);
        this.challengeId = challengeId;
        this.errorCode = errorCode;
        this.gameTableStatus = gameTableStatus;
        this.challengeDetails = challengeDetails;
    }

    getChallengeId(): number {
        return this.challengeId;
    }

    setChallengeId(challengeId: number) {
        this.challengeId = challengeId;
    }
    getErrorCode(): number {
        return this.errorCode;
    }

    setErrorCode(errorCode: number) {
        this.errorCode = errorCode;
    }
    getGameTableStatus(): GameTableStatus | null {
        return this.gameTableStatus;
    }

    setGameTableStatus(gameTableStatus: GameTableStatus | null) {
        this.gameTableStatus = gameTableStatus;
    }
    getChallengeDetails(): ChallengeDetails | null {
        return this.challengeDetails;
    }

    setChallengeDetails(challengeDetails: ChallengeDetails | null) {
        this.challengeDetails = challengeDetails;
    }
}
