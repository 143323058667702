import { Message } from '../../message';

export class LSTableGameStats extends Message {
    private MESSAGE_NAME: string = 'LSTableGameStats';
    private tableId: number = 0;
    private avgPot: number = 0;
    private handsPerHour: number = 0;
    private avgFlopSeen: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, avgPot: number = 0, handsPerHour: number = 0, avgFlopSeen: number = 0) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.avgPot = avgPot;
        this.handsPerHour = handsPerHour;
        this.avgFlopSeen = avgFlopSeen;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
    getHandsPerHour(): number {
        return this.handsPerHour;
    }

    setHandsPerHour(handsPerHour: number) {
        this.handsPerHour = handsPerHour;
    }
    getAvgFlopSeen(): number {
        return this.avgFlopSeen;
    }

    setAvgFlopSeen(avgFlopSeen: number) {
        this.avgFlopSeen = avgFlopSeen;
    }
}
