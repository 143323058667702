import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { HeaderService } from '@frontend/vanilla/core';
import { HomepageResourceService } from '@pokercore/module/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomepageResolver implements Resolve<any> {
    constructor(
        private resource: HomepageResourceService,
        private headerService: HeaderService,
    ) {}

    resolve(): Observable<any> {
        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('poker');
        });
        return this.resource.getAll();
    }
}
