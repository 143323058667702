import { Message } from '../../message';

export class ShowScreen extends Message {
    private MESSAGE_NAME: string = 'ShowScreen';
    private screenType: number = 0;
    private alertMessageToShow: any | null = null;
    private alertMessageType: number = 0;
    private titleMessage: any | null = null;
    private isMandatory: boolean | null = false;
    private failureMessage: any | null = null;
    private cancelMessage: any | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        screenType: number = 0,
        alertMessageToShow: any | null = null,
        alertMessageType: number = 0,
        titleMessage: any | null = null,
        isMandatory: boolean | null = false,
        failureMessage: any | null = null,
        cancelMessage: any | null = null,
    ) {
        super(reqServerPeerId);
        this.screenType = screenType;
        this.alertMessageToShow = alertMessageToShow;
        this.alertMessageType = alertMessageType;
        this.titleMessage = titleMessage;
        this.isMandatory = isMandatory;
        this.failureMessage = failureMessage;
        this.cancelMessage = cancelMessage;
    }

    getScreenType(): number {
        return this.screenType;
    }

    setScreenType(screenType: number) {
        this.screenType = screenType;
    }
    getAlertMessageToShow(): any | null {
        return this.alertMessageToShow;
    }

    setAlertMessageToShow(alertMessageToShow: any | null) {
        this.alertMessageToShow = alertMessageToShow;
    }
    getAlertMessageType(): number {
        return this.alertMessageType;
    }

    setAlertMessageType(alertMessageType: number) {
        this.alertMessageType = alertMessageType;
    }
    getTitleMessage(): any | null {
        return this.titleMessage;
    }

    setTitleMessage(titleMessage: any | null) {
        this.titleMessage = titleMessage;
    }
    getIsMandatory(): boolean | null {
        return this.isMandatory;
    }

    setIsMandatory(isMandatory: boolean | null) {
        this.isMandatory = isMandatory;
    }
    getFailureMessage(): any | null {
        return this.failureMessage;
    }

    setFailureMessage(failureMessage: any | null) {
        this.failureMessage = failureMessage;
    }
    getCancelMessage(): any | null {
        return this.cancelMessage;
    }

    setCancelMessage(cancelMessage: any | null) {
        this.cancelMessage = cancelMessage;
    }
}
