import { Message } from '../../message';
import { FXConversionFactor } from '../../pgSharedMessageFactory/messages/FXConversionFactor';

export class PoolAvailabilityResponse extends Message {
    private MESSAGE_NAME: string = 'PoolAvailabilityResponse';
    private userBalance: number = 0;
    private TimeOutAt: number = 0;
    private messageToClient: any | null = null;
    private fXConversionFactor: FXConversionFactor | null = null;
    private poolAvailabiltyStatus: number = 0;
    private maxBuyin: number = 0;
    private minBuyin: number = 0;
    private poolName: any | null = null;
    private tableTypeId: number = 0;
    private gameTypeId: number = 0;
    private maxNoOfSeats: number = 0;
    private stakesLowerLimit: number = 0;
    private stakesUpperLimit: number = 0;
    private tableLimitType: number = 0;
    private smallBlind: number = 0;
    private bigBlind: number = 0;
    private gameCurrency: string | null = null;
    private groupId: number = 0;
    private HUTabletype: number = 0;
    private tableCategory: number = 0;
    private anteCashAmount: number = 0;
    private gDollarBalance: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        userBalance: number = 0,
        TimeOutAt: number = 0,
        messageToClient: any | null = null,
        fXConversionFactor: FXConversionFactor | null = null,
        poolAvailabiltyStatus: number = 0,
        maxBuyin: number = 0,
        minBuyin: number = 0,
        poolName: any | null = null,
        tableTypeId: number = 0,
        gameTypeId: number = 0,
        maxNoOfSeats: number = 0,
        stakesLowerLimit: number = 0,
        stakesUpperLimit: number = 0,
        tableLimitType: number = 0,
        smallBlind: number = 0,
        bigBlind: number = 0,
        gameCurrency: string | null = null,
        groupId: number = 0,
        HUTabletype: number = 0,
        tableCategory: number = 0,
        anteCashAmount: number = 0,
        gDollarBalance: number = 0,
    ) {
        super(reqServerPeerId);
        this.userBalance = userBalance;
        this.TimeOutAt = TimeOutAt;
        this.messageToClient = messageToClient;
        this.fXConversionFactor = fXConversionFactor;
        this.poolAvailabiltyStatus = poolAvailabiltyStatus;
        this.maxBuyin = maxBuyin;
        this.minBuyin = minBuyin;
        this.poolName = poolName;
        this.tableTypeId = tableTypeId;
        this.gameTypeId = gameTypeId;
        this.maxNoOfSeats = maxNoOfSeats;
        this.stakesLowerLimit = stakesLowerLimit;
        this.stakesUpperLimit = stakesUpperLimit;
        this.tableLimitType = tableLimitType;
        this.smallBlind = smallBlind;
        this.bigBlind = bigBlind;
        this.gameCurrency = gameCurrency;
        this.groupId = groupId;
        this.HUTabletype = HUTabletype;
        this.tableCategory = tableCategory;
        this.anteCashAmount = anteCashAmount;
        this.gDollarBalance = gDollarBalance;
    }

    getUserBalance(): number {
        return this.userBalance;
    }

    setUserBalance(userBalance: number) {
        this.userBalance = userBalance;
    }
    getTimeOutAt(): number {
        return this.TimeOutAt;
    }

    setTimeOutAt(TimeOutAt: number) {
        this.TimeOutAt = TimeOutAt;
    }
    getMessageToClient(): any | null {
        return this.messageToClient;
    }

    setMessageToClient(messageToClient: any | null) {
        this.messageToClient = messageToClient;
    }
    getFXConversionFactor(): FXConversionFactor | null {
        return this.fXConversionFactor;
    }

    setFXConversionFactor(fXConversionFactor: FXConversionFactor | null) {
        this.fXConversionFactor = fXConversionFactor;
    }
    getPoolAvailabiltyStatus(): number {
        return this.poolAvailabiltyStatus;
    }

    setPoolAvailabiltyStatus(poolAvailabiltyStatus: number) {
        this.poolAvailabiltyStatus = poolAvailabiltyStatus;
    }
    getMaxBuyin(): number {
        return this.maxBuyin;
    }

    setMaxBuyin(maxBuyin: number) {
        this.maxBuyin = maxBuyin;
    }
    getMinBuyin(): number {
        return this.minBuyin;
    }

    setMinBuyin(minBuyin: number) {
        this.minBuyin = minBuyin;
    }
    getPoolName(): any | null {
        return this.poolName;
    }

    setPoolName(poolName: any | null) {
        this.poolName = poolName;
    }
    getTableTypeId(): number {
        return this.tableTypeId;
    }

    setTableTypeId(tableTypeId: number) {
        this.tableTypeId = tableTypeId;
    }
    getGameTypeId(): number {
        return this.gameTypeId;
    }

    setGameTypeId(gameTypeId: number) {
        this.gameTypeId = gameTypeId;
    }
    getMaxNoOfSeats(): number {
        return this.maxNoOfSeats;
    }

    setMaxNoOfSeats(maxNoOfSeats: number) {
        this.maxNoOfSeats = maxNoOfSeats;
    }
    getStakesLowerLimit(): number {
        return this.stakesLowerLimit;
    }

    setStakesLowerLimit(stakesLowerLimit: number) {
        this.stakesLowerLimit = stakesLowerLimit;
    }
    getStakesUpperLimit(): number {
        return this.stakesUpperLimit;
    }

    setStakesUpperLimit(stakesUpperLimit: number) {
        this.stakesUpperLimit = stakesUpperLimit;
    }
    getTableLimitType(): number {
        return this.tableLimitType;
    }

    setTableLimitType(tableLimitType: number) {
        this.tableLimitType = tableLimitType;
    }
    getSmallBlind(): number {
        return this.smallBlind;
    }

    setSmallBlind(smallBlind: number) {
        this.smallBlind = smallBlind;
    }
    getBigBlind(): number {
        return this.bigBlind;
    }

    setBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
    }
    getGameCurrency(): string | null {
        return this.gameCurrency;
    }

    setGameCurrency(gameCurrency: string | null) {
        this.gameCurrency = gameCurrency;
    }
    getGroupId(): number {
        return this.groupId;
    }

    setGroupId(groupId: number) {
        this.groupId = groupId;
    }
    getHUTabletype(): number {
        return this.HUTabletype;
    }

    setHUTabletype(HUTabletype: number) {
        this.HUTabletype = HUTabletype;
    }
    getTableCategory(): number {
        return this.tableCategory;
    }

    setTableCategory(tableCategory: number) {
        this.tableCategory = tableCategory;
    }
    getAnteCashAmount(): number {
        return this.anteCashAmount;
    }

    setAnteCashAmount(anteCashAmount: number) {
        this.anteCashAmount = anteCashAmount;
    }
    getGDollarBalance(): number {
        return this.gDollarBalance;
    }

    setGDollarBalance(gDollarBalance: number) {
        this.gDollarBalance = gDollarBalance;
    }
}
