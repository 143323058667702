import { Message } from '../../message';

export class SNGJPUnRegistrationResponse extends Message {
    private MESSAGE_NAME: string = 'SNGJPUnRegistrationResponse';
    private sngJackpotID: number = 0;
    private responseCode: number = 0;
    private sngJPInstanceID: number = 0;
    private participantNo: number = 0;
    private responseMessage: any | null = null;
    private conversationId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        responseCode: number = 0,
        sngJPInstanceID: number = 0,
        participantNo: number = 0,
        responseMessage: any | null = null,
        conversationId: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.responseCode = responseCode;
        this.sngJPInstanceID = sngJPInstanceID;
        this.participantNo = participantNo;
        this.responseMessage = responseMessage;
        this.conversationId = conversationId;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getResponseCode(): number {
        return this.responseCode;
    }

    setResponseCode(responseCode: number) {
        this.responseCode = responseCode;
    }
    getSngJPInstanceID(): number {
        return this.sngJPInstanceID;
    }

    setSngJPInstanceID(sngJPInstanceID: number) {
        this.sngJPInstanceID = sngJPInstanceID;
    }
    getParticipantNo(): number {
        return this.participantNo;
    }

    setParticipantNo(participantNo: number) {
        this.participantNo = participantNo;
    }
    getResponseMessage(): any | null {
        return this.responseMessage;
    }

    setResponseMessage(responseMessage: any | null) {
        this.responseMessage = responseMessage;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
}
