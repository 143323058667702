<pkr-demo-game-table
    [hidden]="isDemoSelect"
    [item]="item"
    [class.V3]="isReplayerThree"
    [class.V1]="!isReplayerThree"
    #demoTable></pkr-demo-game-table>
<pk-demo-video [item]="item" *ngIf="isDemoSelect" (passSkipDemo)="hideDemo()"></pk-demo-video>
<pk-get-started-check
    [commonParams]="item?.parameters"
    *ngIf="getStartedCheckModal"
    [parameters]="item?.titleLink"
    (passCheckEvent)="getCheckEvent($event)"></pk-get-started-check>
<pk-get-started
    [commonParams]="item?.parameters"
    *ngIf="getStartedModal && !isDemoSelect"
    [parameters]="item?.titleLink"
    (backToCheck)="showGetCheckModal($event)"
    (passNickname)="onNicknamePassed($event)"></pk-get-started>

<!-- Pre-loading assets -->
<div class="wiz-modal wiz-modal-hidden">
    <div class="wiz-animate-1"><div class="wiz-animate-static"></div></div>
    <div class="wiz-animate-2"><div class="wiz-animate-static"></div></div>
    <div class="wiz-animate-3"><div class="wiz-animate-static"></div></div>
    <div class="wiz-animate-4"><div class="wiz-animate-static"></div></div>
</div>
<div class="dv-modal dv-modal-hidden">
    <div class="hi-animate animate"></div>
    <div class="hi-animate-loop animate-static"></div>
    <div class="default-animate animate"></div>
    <div class="default-animate-loop animate-static"></div>
</div>
