<div class="pwc-lobby-main upda sdsd">
    <div class="mobilebanner-wrap my-1 px-3">
        <pwc-main-lobby-banner></pwc-main-lobby-banner>
    </div>
    <div class="row px-2 m-0">
        <ng-container *ngFor="let promo of lobbyPromotions">
            <!-- Promo Item -->
            <div class="col p-0" [ngClass]="promo.fullSizeTile ? 'col-12' : 'col-6'" *ngIf="dynaconData.mainLobbyTiles[promo.gameType]">
                <div class="pwc-lobby-promo">
                    <figure (click)="gameLobby(promo.gameType)"><img [src]="promo.actorImage.src" [alt]="promo.actorImage.alt" /></figure>
                    <button class="promo-info-btn theme-info" (click)="gameInfo(promo)"></button>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- <div class="row">
        <button (click)="showExpo()"></button>
        <expospin-animation *ngIf="enableExpo"> </expospin-animation>
    </div> -->
</div>
