import { Message } from '../../message';

export class UserPersonalInfo extends Message {
    private MESSAGE_NAME: string = 'UserPersonalInfo';
    private accountName: string | null = null;
    private title: string | null = null;
    private firstName: string | null = null;
    private lastName: string | null = null;
    private dob: string | null = null;
    private phoneNumber: string | null = null;
    private address: string | null = null;
    private city: string | null = null;
    private state: string | null = null;
    private zipCode: string | null = null;
    private country: string | null = null;
    private cityOfBirth: string | null = null;
    private stateOfBirth: string | null = null;
    private countryOfBirth: string | null = null;
    private mobileNumber: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        accountName: string | null = null,
        title: string | null = null,
        firstName: string | null = null,
        lastName: string | null = null,
        dob: string | null = null,
        phoneNumber: string | null = null,
        address: string | null = null,
        city: string | null = null,
        state: string | null = null,
        zipCode: string | null = null,
        country: string | null = null,
        cityOfBirth: string | null = null,
        stateOfBirth: string | null = null,
        countryOfBirth: string | null = null,
        mobileNumber: string | null = null,
    ) {
        super(reqServerPeerId);
        this.accountName = accountName;
        this.title = title;
        this.firstName = firstName;
        this.lastName = lastName;
        this.dob = dob;
        this.phoneNumber = phoneNumber;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.country = country;
        this.cityOfBirth = cityOfBirth;
        this.stateOfBirth = stateOfBirth;
        this.countryOfBirth = countryOfBirth;
        this.mobileNumber = mobileNumber;
    }

    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getTitle(): string | null {
        return this.title;
    }

    setTitle(title: string | null) {
        this.title = title;
    }
    getFirstName(): string | null {
        return this.firstName;
    }

    setFirstName(firstName: string | null) {
        this.firstName = firstName;
    }
    getLastName(): string | null {
        return this.lastName;
    }

    setLastName(lastName: string | null) {
        this.lastName = lastName;
    }
    getDob(): string | null {
        return this.dob;
    }

    setDob(dob: string | null) {
        this.dob = dob;
    }
    getPhoneNumber(): string | null {
        return this.phoneNumber;
    }

    setPhoneNumber(phoneNumber: string | null) {
        this.phoneNumber = phoneNumber;
    }
    getAddress(): string | null {
        return this.address;
    }

    setAddress(address: string | null) {
        this.address = address;
    }
    getCity(): string | null {
        return this.city;
    }

    setCity(city: string | null) {
        this.city = city;
    }
    getState(): string | null {
        return this.state;
    }

    setState(state: string | null) {
        this.state = state;
    }
    getZipCode(): string | null {
        return this.zipCode;
    }

    setZipCode(zipCode: string | null) {
        this.zipCode = zipCode;
    }
    getCountry(): string | null {
        return this.country;
    }

    setCountry(country: string | null) {
        this.country = country;
    }
    getCityOfBirth(): string | null {
        return this.cityOfBirth;
    }

    setCityOfBirth(cityOfBirth: string | null) {
        this.cityOfBirth = cityOfBirth;
    }
    getStateOfBirth(): string | null {
        return this.stateOfBirth;
    }

    setStateOfBirth(stateOfBirth: string | null) {
        this.stateOfBirth = stateOfBirth;
    }
    getCountryOfBirth(): string | null {
        return this.countryOfBirth;
    }

    setCountryOfBirth(countryOfBirth: string | null) {
        this.countryOfBirth = countryOfBirth;
    }
    getMobileNumber(): string | null {
        return this.mobileNumber;
    }

    setMobileNumber(mobileNumber: string | null) {
        this.mobileNumber = mobileNumber;
    }
}
