import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { OptInStatus } from '../models/loyalty-cashback-optin';
import { WeeklyPointsDto } from '../models/loyalty-cashback-weeklyPoints';

export interface State {
    optIn: OptInStatus;
    weeklyPoints: WeeklyPointsDto;
    isCashBackEnabled: boolean;
}

export const initialState: State = {
    optIn: {} as OptInStatus,
    weeklyPoints: {} as WeeklyPointsDto,
    isCashBackEnabled: false,
};

// selector while consuming store.
export const selectLoyaltyState = createFeatureSelector<State[]>('LoyaltyCashBack');

// create Selector
export const selectLoyaltyCashBack = createSelector(selectLoyaltyState, (dataObject: State[]) => dataObject);

// create Action
export const updateOptInStatus = createAction('[Loyalty CashBack] Update OptIn State', (dataObject: any) => dataObject);
export const updateLoyaltyWeeklyPoints = createAction('[Loyalty CashBack] Update Loyalty Weekly Points', (dataObject: any) => dataObject);
export const updateIsCashBackEnabledStatus = createAction(
    '[Loyalty CashBack] Update IsCashBackEnabled flag from Dynacon',
    (dataObject: any) => dataObject,
);

// create Reducer
export const LoyaltyCashBackReducer = createReducer(
    initialState,
    on(updateOptInStatus, (state, OptInStatus) => ({
        ...state,
        optIn: OptInStatus.optIn,
    })),
    on(updateLoyaltyWeeklyPoints, (state, WeeklyPoints) => ({
        ...state,
        weeklyPoints: WeeklyPoints.weeklyPoints,
    })),
    on(updateIsCashBackEnabledStatus, (state, isEnabled) => ({
        ...state,
        isCashBackEnabled: isEnabled.isCashBackEnabled,
    })),
);
