import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { NavigationService, UserService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';
import Swiper from 'swiper';
import { Autoplay, EffectCoverflow, Pagination, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { CashFFBuyInPopUpComponent } from '../../common/cash-ff-buyin/cash-ff-buyIn.component';
import { PWCErrorDialogComponent } from '../../common/error-dialog/pwc-error-dialog.component';
import { commonFiltersComponent } from '../../common/lobby-filters/ff-filters/common-filters.component';
import { SpinFiltersEventBusService } from '../../common/lobby-filters/services/spins-filters.event-bus.service';
import { LobbyTileInfoComponent } from '../../common/lobby-tile-info/lobby-tile-info.component';
import { SpinsBuyInPopUpComponent } from '../../common/spins-buyin/spins-buyin.component';
import { SubLobbyHeaderComponent } from '../../common/sub-lobby-header/sub-lobby-header.component';
import { CashErrorDialogModel } from '../../models/cash-error-dialog.model';
import { CardInfo } from '../../models/tile-card-info.model';
import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { PokerWebClientLoginService } from '../../service/poker-web-client-login.service';
import { SpinGameService } from '../../service/spin-game.service';
import { spinsOverdriveDataSelect } from '../../store/lobby.store';
import { miniTablesSelect } from '../../store/mini-table.store';
import { userIsAuthenticatedSelect } from '../../store/user.store';

@Component({
    selector: 'pwc-spin-lobby',
    templateUrl: 'spins-overdrive-lobby.component.html',
    providers: [SpinGameService, TranslatePipe, FormatamountPipe, formatCurrencyPipe],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SubLobbyHeaderComponent,
        LobbyTileInfoComponent,
        CashFFBuyInPopUpComponent,
        commonFiltersComponent,
        SpinsBuyInPopUpComponent,
        TranslatePipe,
        SwiperComponent,
        FormatamountPipe,
        formatCurrencyPipe,
    ],
})
export class SpinsOverdriveLobbyComponent implements OnInit {
    @Output() expospinData;
    @Output() sngjpid;
    @Output() selectedcardindex;
    @ViewChild('gridContainer', { read: ElementRef }) gridContainer: ElementRef;
    // @Output() buyInData;
    // snjpcardsData: any;
    public gridView = true;
    sngJackpotTabMode: number;
    sngJackpotExpoTabMode: number;
    spinsOverdrivePlayNowBtn: boolean = false;
    spinsOverdrivePlayUltraBtn: boolean = false;
    spinsOverdriveBothBtn: boolean = false;
    uniqWinUptos: any[] = [];
    payoutValue: any;
    cardOrderBy = true;
    buyInTypeValue: any;
    sngjpcards: any[] = [];
    isLoggedIn = false;
    popupIsOpen = false;
    public selectedTableIndex = 0;
    public currentCardSelectedIndex = 0;
    tileLeastBuyinValue: number;
    tileMaxBuyinValue: number;
    finalData: any;
    buyindata: any;
    buyindataa: any;
    currentLobbyInfo: any;
    TileView: any;
    public gameTypes = {
        'All': true,
        'Hold’em': true,
        'Omaha': true,
    };
    private readonly logger: PokerLogger;
    spinsType: number = 1;
    public lobbySwaiperConfig: SwiperOptions = {
        modules: [Pagination, EffectCoverflow, Autoplay, Virtual],
        direction: 'horizontal',
        effect: 'coverflow',
        centeredSlides: true,
        spaceBetween: 40,
        slidesPerView: 1.8,
        loop: true,
        navigation: true,
        autoplay: false,
        virtual: {
            addSlidesBefore: 2,
            addSlidesAfter: 5,
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: true,
        },
        pagination: {
            el: '.swiper-pagination-cust',
            dynamicBullets: true,
            clickable: true,
        },
        keyboard: true,
        rewind: true,
        observer: true,
        on: {
            click: (swiper: Swiper, event: any) => {
                this.onSlideClick(swiper, event);
            },
        },
    };
    isSwiperInit: boolean = false;
    @ViewChild('SwiperComponent', { static: false })
    componentRef?: SwiperComponent;

    carouselGoldAsset: any;
    carouselGoldSelected: any;
    activeNormalSelected: any;
    nonActiveNormal: any;
    miniTableCount: any;
    private eventDispatcherGlobal = new EventDispatcherGlobal();

    constructor(
        private dialog: MatDialog,
        private navigation: NavigationService,
        private userService: UserService,
        private store: Store,
        private clientToServer: ClientToServerRequestServices,
        private shareService: PokerWebClientSharedService,
        private spinFiltersEventBusService: SpinFiltersEventBusService,
        private spinGameService: SpinGameService,
        private translatePipe: TranslatePipe,
        private pokerWebClientLoginService: PokerWebClientLoginService,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('SpinsOverdriveLobbyLoggingComponent');
        this.store.select(miniTablesSelect).subscribe((data) => {
            this.miniTableCount = data['miniTables'].length;
        });
        // this.clientToServer.updatePageTitle('spins overdrive games');
        this.isLoggedIn = this.userService.isAuthenticated;
        this.sngJackpotExpoTabMode = this.clientToServer.dynaconConfigs['webClientConfigs']['mainLobbyTiles']['sng-jackpot-expo-tab-mode'];

        this.carouselGoldAsset = this.clientToServer.webAssets['lobby']['spins']['carousel-gold'];
        this.carouselGoldSelected = this.clientToServer.webAssets['lobby']['spins']['carousel-gold-selected'];
        this.activeNormalSelected = this.clientToServer.webAssets['lobby']['spins']['active-normal-selected'];
        this.nonActiveNormal = this.clientToServer.webAssets['lobby']['spins']['non-active-normal'];

        this.store.select(userIsAuthenticatedSelect).subscribe((data) => {
            this.isLoggedIn = data;
        });
        this.clientToServer.requestForSpinLobbyData(0, 1);
        this.logger.info('requesting for cardsdata');
        this.getLobbyData();
        const sngJpCardsFilterData = localStorage.getItem('sngJpCardsFilter')!;
        this.showInitialGameType(JSON.parse(sngJpCardsFilterData));
    }
    ngOnInit(): void {
        const currentURL = window.location.pathname;
        this.currentLobbyInfo = this.shareService.lobbyPromotions.lobbyPromotions.lobbyPromotions.find(
            (promotion) => currentURL.includes(promotion.gameType) && promotion.gameType !== 'spins',
        );
        this.shareService.setPageHeaderTitle({
            title: this.currentLobbyInfo.title,
            url: '/play',
            showBalanceBlock: true,
            image: this.currentLobbyInfo.infoActorImage.src,
            gameType: this.currentLobbyInfo.gameType,
        });

        if (this.spinsType == 1) {
            if (this.sngJackpotExpoTabMode == 1) {
                this.spinsOverdrivePlayNowBtn = true;
            } else if (this.sngJackpotExpoTabMode == 2) {
                this.spinsOverdrivePlayUltraBtn = true;
            } else if (this.sngJackpotExpoTabMode == 3) {
                this.spinsOverdriveBothBtn = true;
            }
        }
        this.eventDispatcherGlobal.addEventListener('showSpinsBuyin', this.showSpinsBuyIn.bind(this));
        this.onCardSelect(0);
    }
    getLobbyData() {
        this.store.select(spinsOverdriveDataSelect).subscribe((data) => {
            this.updateBuyinFilters(data);
            this.logger.info('spinsoverdrive cards data...' + JSON.stringify(data));
            if (data != undefined && data.length != 0) {
                this.sngjpcards = this.spinFiltersEventBusService.filterSNgJPCards(data, this.spinsType);
                if (this.sngjpcards?.length <= 10 && this.lobbySwaiperConfig) {
                    this.lobbySwaiperConfig.pagination = {
                        el: '.swiper-pagination-cust',
                        dynamicBullets: true,
                        clickable: true,
                    };
                    this.lobbySwaiperConfig.virtual = false;
                }
                this.sngjpcards = _.sortBy(this.sngjpcards, [(obj) => obj.formattedBuyIn]);
            } else {
                this.sngjpcards = [];
            }
            this.logger.info('Overdrive lobby final CardsData.....' + this.sngjpcards);
        });
    }
    updateBuyinFilters(data) {
        this.uniqWinUptos = _.sortBy(_.uniq(_.map(data, (o) => o.formattedBuyIn)));
        const spinsCardsFilter = JSON.parse(localStorage.getItem('sngJpCardsFilter')!);
        const selectedGameTypes = spinsCardsFilter.gameTypes;
        const selectedBuyIn = spinsCardsFilter.winUptos;
        if (selectedGameTypes.length == 0 && selectedBuyIn.length == 0) {
            for (const key in this.gameTypes) {
                if (this.gameTypes[key]) {
                    selectedGameTypes.push(key);
                }
            }
        }
        const obj: any = {
            gameTypes: selectedGameTypes,
            winUptos: selectedBuyIn.length == 0 ? this.uniqWinUptos : selectedBuyIn,
        };
        localStorage.setItem('sngJpCardsFilter', JSON.stringify(obj));
    }
    checkSwiper(swiper: any) {
        if (swiper.initialized) {
            this.isSwiperInit = true;
        }
    }
    isItGridViewType(e: boolean) {
        this.gridView = e;
        this.logger.info('clicked on Grid Icon..!' + e);
        // let globalThis: this;
        if (!this.gridView) {
            this.setSwiperBuyinValues(this.sngjpcards);
        }
        setTimeout(() => {
            if (this.componentRef) {
                if (!this.gridView) {
                    this.componentRef.swiper.slideToLoop(this.currentCardSelectedIndex);
                }
                this.componentRef.swiper.on(
                    'slideChange',
                    function (swiperRef) {
                        this.onCardSelect(swiperRef.realIndex);
                    }.bind(this),
                );
            }
        }, 200);
        if (this.gridView) {
            setTimeout(() => {
                const selectedElement = this.gridContainer.nativeElement.querySelector(`.card-${this.currentCardSelectedIndex}`);
                const gridViewContainer = selectedElement.closest('.spins-overdrive-wrap');
                if (selectedElement) {
                    gridViewContainer.scroll({
                        behavior: 'smooth',
                        top: selectedElement.offsetTop - gridViewContainer.offsetTop,
                    });
                }
            }, 100);
        }
    }
    openInfoPopup(e: any) {
        this.logger.info('clicked on info popup icon' + e);
        const data: CardInfo = {
            viewType: 1,
            headerImageUrl: `${this.currentLobbyInfo.infoActorImage.src}`,
            description: `${this.currentLobbyInfo.infoDescription}`,
            ctaButtons: [this.currentLobbyInfo.secondaryCTA],
        };
        this.dialog.open(LobbyTileInfoComponent, {
            width: '95%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'wpc-card-info',
            hasBackdrop: true,
            backdropClass: 'wpc-card-info-bg',
            disableClose: false,
            closeOnNavigation: true,
        });
    }
    sortTables(e: any) {
        this.logger.info('Clicked on Sort Icon' + e);
        this.cardOrderBy = e;
        this.sngjpcards.reverse();
        this.onCardSelect(0);
    }
    openFilterPopup(e: any) {
        this.logger.info('Clicked on filter Icon' + e);
        this.navigation.goTo('/play/filters?key=spin-filters');
        // this.router.navigate(['/play/filters'],{ queryParams: { key: "spin-filters" } });
    }
    onCardSelect(cardIndex) {
        this.currentCardSelectedIndex = cardIndex;
        this.selectedcardindex = this.sngjpcards[this.currentCardSelectedIndex];
        this.sngjpid = this.sngjpcards[this.currentCardSelectedIndex].sngJPId;
        // this.spinGameService.RequestSNGJPPlayNow(this.sngjpid, 1, 0, this.sngjpcards[this.currentCardSelectedIndex].slide.ticketCount, true);
        // this.spinGameService.getPlayNowBuyinDetails().subscribe((data) => (this.expospinData = data));
    }
    closePopup() {
        this.logger.info('clicked on close popup icon...! ');
        this.popupIsOpen = false;
    }
    onClickPlayNowButton() {
        // this.isLoggedIn ? (this.popupIsOpen = true) : this.pokerWebClientLoginService.requestForPortalLogin();
        if (this.isLoggedIn) {
            const remainingTables = 4 - this.miniTableCount;
            if (remainingTables <= 0) {
                this.openErrorDialog({
                    title: this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
                    message: this.translatePipe.transform('PARTY_POKER_GC_QSLOBBY_OPENEDMAXTBLSMSG'),
                    buttons: [{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }],
                });
            } else {
                this.spinGameService.RequestSNGJPPlayNow(this.sngjpid, 1, 0, this.sngjpcards[this.currentCardSelectedIndex].slide.ticketCount, true);
                // this.spinGameService.getPlayNowBuyinDetails().subscribe((data) => (this.expospinData = data));
                // this.popupIsOpen = true;
                this.logger.info('buyin data ' + JSON.stringify(this.expospinData));
            }
        } else {
            this.pokerWebClientLoginService.requestForPortalLogin();
        }

        // this.loginDialogService.open({ returnUrl: this.location.path()}).beforeClosed().subscribe((resp:any) => {
        //     this.logger.info("login resp "+resp);
        // });
        // this.isLoggedIn ? (this.popupIsOpen = true) : this.clientToServer.requestForPortalLogin();
    }
    openErrorDialog(data: CashErrorDialogModel) {
        this.dialog.open(PWCErrorDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }

    openBuyInPopUp() {
        this.logger.info('Clicked on Playnow ');
        this.popupIsOpen = true;
    }
    poolAvailabilityResponse(data) {
        let errorMessage;
        switch (data) {
            case 1: //POKER_POOL_AVAILABLE
                this.openBuyInPopUp();
                return;
            case -4: //USER_NOT_REAL_OR_PSEUDO_REAL
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
                break;
            case 268: //USER_BLOCKED_REALITY_CHECK
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_ERRMSG_SomeProblem');
                break;
            case -2: //R_GAME_SESSION_TIMEDOUT
                errorMessage = this.translatePipe.transform('PARTY_POKER_GS_TOURNAMENTS_PREREGCONDITION_FreerollRegTourErrMsg');
                break;
            default:
                errorMessage = 'Play Now error';
        }
        if (errorMessage != '') {
            window.alert(errorMessage);
        }
    }
    onBuyInButtonClick(values: any) {
        this.logger.info('buyInData..' + JSON.stringify(values));
    }
    showInitialGameType(spinFilter) {
        if (spinFilter.gameTypes.length == 3 || (spinFilter.gameTypes.length == 0 && spinFilter.winUptos.length == 0)) {
            this.gameTypes['All'] = true;
            this.gameTypes['Omaha'] = true;
            this.gameTypes['Hold’em'] = true;
        } else if (spinFilter.gameTypes.length == 0) {
            this.gameTypes['All'] = false;
            this.gameTypes['Omaha'] = false;
            this.gameTypes['Hold’em'] = false;
        }

        if (spinFilter.gameTypes.includes('Omaha')) {
            this.gameTypes['Omaha'] = true;
            this.gameTypes['All'] = false;
        } else {
            if (spinFilter.gameTypes.length != 0) this.gameTypes['Omaha'] = false;
        }

        if (spinFilter.gameTypes.includes('Hold’em')) {
            this.gameTypes['Hold’em'] = true;
            this.gameTypes['All'] = false;
        } else {
            if (spinFilter.gameTypes.length != 0) this.gameTypes['Hold’em'] = false;
        }
        if (this.gameTypes['Hold’em'] && this.gameTypes['Omaha']) {
            this.gameTypes['All'] = true;
        }
    }
    updateGameType(data) {
        const filterData = JSON.parse(localStorage.getItem('sngJpCardsFilter')!);
        filterData.gameTypes = data;
        localStorage.setItem('sngJpCardsFilter', JSON.stringify(filterData));
        this.getLobbyData();
    }
    setSwiperBuyinValues(cardsData) {
        this.tileLeastBuyinValue = cardsData[0]['formattedBuyIn'];
        this.tileMaxBuyinValue = cardsData[cardsData.length - 1]['formattedBuyIn'];
    }

    showSpinsBuyIn(message) {
        this.expospinData = message.detail;
        this.popupIsOpen = true;
    }

    onSlideClick(swiper: Swiper, event: any) {
        let clickPosition: any;
        let clickedSlideNext: any = document.querySelector<HTMLDivElement>('.swiper-slide-next');
        let clickedSlidePrev: any = document.querySelector<HTMLDivElement>('.swiper-slide-prev');

        if (event instanceof MouseEvent) {
            clickPosition = swiper.clickedSlide?.dataset.swiperSlideIndex;
        } else if (event instanceof TouchEvent && swiper.clickedIndex >= 0) {
            clickPosition = swiper.clickedSlide?.dataset.swiperSlideIndex;
        } else {
            console.error('Event type not supported for determining click position');
            return;
        }
        const pervSelectNumber: any = clickedSlidePrev?.dataset.swiperSlideIndex;
        const nextSelectNumber: any = clickedSlideNext?.dataset.swiperSlideIndex;

        clickPosition = parseInt(clickPosition);
        if (clickPosition === parseInt(pervSelectNumber)) {
            swiper.slidePrev();
        } else if (clickPosition === parseInt(nextSelectNumber)) {
            swiper.slideNext();
        }
    }
}
