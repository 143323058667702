/* eslint-disable no-redeclare */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-namespace */
import { ByteBuffer } from './byteBuffer';

class Charset {
    decode(buf: ByteBuffer): string {
        // Implement the decode logic for the base class if needed
        return '';
    }

    encode(text: string, buf: ByteBuffer): void {
        // Implement the encode logic for the base class if needed
    }
}

namespace Charset {
    export class UTF8 extends Charset {
        override decode(buf: ByteBuffer): string {
            const result: number[] = [];
            while (buf.hasRemaining()) {
                const remaining = buf.remaining();
                const value = buf.getUnsigned();
                const byteCount = charByteCount(value);

                if (remaining < byteCount) {
                    buf.skip(-1);
                    break;
                }

                let charValue: number | null = null;
                switch (byteCount) {
                    case 1:
                        charValue = value;
                        break;
                    case 2:
                        charValue = ((value & 31) << 6) | (buf.getUnsigned() & 63);
                        break;
                    case 3:
                        charValue = ((value & 15) << 12) | ((buf.getUnsigned() & 63) << 6) | (buf.getUnsigned() & 63);
                        break;
                    case 4:
                        charValue =
                            ((value & 7) << 18) | ((buf.getUnsigned() & 63) << 12) | ((buf.getUnsigned() & 63) << 6) | (buf.getUnsigned() & 63);
                        break;
                }

                if (charValue !== null) {
                    result.push(charValue);
                }
            }

            return String.fromCharCode.apply(null, result);
        }

        override encode(text: string, buf: ByteBuffer): void {
            for (let i = 0; i < text.length; i++) {
                const charCode = text.charCodeAt(i);
                if (charCode < 128) {
                    buf.put(charCode);
                } else {
                    if (charCode < 2048) {
                        buf.put((charCode >> 6) | 192);
                        buf.put((charCode & 63) | 128);
                    } else {
                        if (charCode < 65536) {
                            buf.put((charCode >> 12) | 224);
                            buf.put(((charCode >> 6) & 63) | 128);
                            buf.put((charCode & 63) | 128);
                        } else {
                            if (charCode < 1114112) {
                                buf.put((charCode >> 18) | 240);
                                buf.put(((charCode >> 12) & 63) | 128);
                                buf.put(((charCode >> 6) & 63) | 128);
                                buf.put((charCode & 63) | 128);
                            } else {
                                throw new Error('Invalid UTF-8 string');
                            }
                        }
                    }
                }
            }
        }
    }
}

function charByteCount(b: number): number {
    if ((b & 128) === 0) {
        return 1;
    }
    if ((b & 32) === 0) {
        return 2;
    }
    if ((b & 16) === 0) {
        return 3;
    }
    if ((b & 8) === 0) {
        return 4;
    }
    throw new Error('Invalid UTF-8 bytes');
}

export default Charset;
