import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NavigationService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { combineLatest } from 'rxjs';

import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { miniTablesSelect } from '../../store/mini-table.store';
import { userBalanceSelect, userProfileSelect } from '../../store/user.store';
import { ClientLHNUserInfoComponent } from '../client-lhn-user-info/client-lhn-user-info.component';

@Component({
    selector: 'pwc-game-table-side-navigation',
    templateUrl: 'game-table-side-navigation.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonToggleModule,
        MatIconModule,
        MatExpansionModule,
        MatSlideToggleModule,
        FormatamountPipe,
        TranslatePipe,
        ClientLHNUserInfoComponent,
    ],
    providers: [],
})
export class GameTableSideNavigationComponent implements OnInit {
    miniTables: any;
    activeTable: any;
    userName: string;
    accountBalance = 0;
    accountCurrency = 'USD';
    selectedValues = {};
    selectedGameTableValues = {};
    selectedRaiseBtnValues = true;
    selectedRaiseBtnPreFlopValues = {};
    selectedRaiseBtnPostFlopValues = {};

    showMainItems = true;
    showSubItemTitle = 'PARTY_POKER_GC_COMMON_TABLETHEMES';
    selectedSubItem: string;

    tileViewActiveTable: any;
    isRatholPopupActive: boolean = false;

    tableThemes = [
        {
            text: 'PARTY_POKER_GC_COMMON_TABLETHEMES',
            class: 'theme-bingo-trophy',
            children: [
                {
                    text: 'PARTY_POKER_MOBILE_LOBBY_GAMESETTINGS_CARDSTYLES',
                    value: 'tableThemesCardBackground',
                    children: [
                        {
                            text: 'card_backcard_1',
                            value: 0,
                        },
                        {
                            text: 'card_backcard_2',
                            value: 1,
                        },
                        {
                            text: 'card_backcard_3',
                            value: 2,
                        },
                        {
                            text: 'card_backcard_4',
                            value: 3,
                        },
                    ],
                },
                {
                    text: '',
                    value: 'tableThemesCardStyle',
                    children: [
                        {
                            text: 'card_deck_0',
                            value: 0,
                        },
                        {
                            text: 'card_deck_1',
                            value: 1,
                        },
                        {
                            text: 'card_deck_2',
                            value: 2,
                        },
                        {
                            text: 'card_deck_3',
                            value: 3,
                        },
                    ],
                },
                {
                    text: 'PARTY_POKER_MOBILE_LOBBY_GAMESETTINGS_TABLEFELTS',
                    value: 'tableThemesTableFelt',
                    children: [
                        {
                            text: 'tablefelt1',
                            value: 1,
                        },
                        {
                            text: 'tablefelt2',
                            value: 2,
                        },
                        {
                            text: 'tablefelt3',
                            value: 3,
                        },
                        {
                            text: 'tablefelt4',
                            value: 0,
                        },
                    ],
                },
                {
                    text: 'PARTY_POKER_MOBILE_LOBBY_GAMESETTINGS_TABLESTYLES',
                    value: 'tableThemesTableStyle',
                    children: [
                        {
                            text: 'table_style1',
                            value: 0,
                        },
                        {
                            text: 'table_style2',
                            value: 1,
                        },
                    ],
                },
            ],
        },
    ];
    gameSettings = [
        {
            text: 'PARTY_POKER_MOBILE_TABLE_COMMON_GAMESETTINGS',
            value: 'gameSettings',
            children: [
                {
                    text: 'PARTY_POKER_MOBILE_TABLE_BUTTONS_AUTOMUCK',
                    selector: 'autoMuck',
                    value: false,
                },
                {
                    text: 'PARTY_POKER_MOBILE_COMMON_SOUND',
                    selector: 'sound',
                    value: false,
                },
                {
                    text: 'PARTY_POKER_MOBILE_LOBBY_GAMESETTINGS_RaiseBarAutoConfirm',
                    selector: 'raiseBarAutoConfirm',
                    value: false,
                },
                {
                    text: 'PARTY_POKER_MOBILE_TABLE_COMMON_THROWANIMATIONS',
                    selector: 'throwAnimations',
                    value: false,
                },
                {
                    text: 'PARTY_POKER_MOBILE_Fast Forward_REBUYTOMAX',
                    selector: 'reBuyToMax',
                    value: false,
                },
            ],
        },
    ];
    betButtonsPreFlop = [
        {
            text: '2',
            type: 'BB',
            value: 2,
        },
        {
            text: '2.1',
            type: 'BB',
            value: 2.1,
        },
        {
            text: '2.2',
            type: 'BB',
            value: 2.2,
        },
        {
            text: '2.5',
            type: 'BB',
            value: 2.5,
        },
        {
            text: '3',
            type: 'BB',
            value: 3,
        },
        {
            text: '4',
            type: 'BB',
            value: 4,
        },
        {
            text: 'POT',
            type: '',
            value: 'POT',
        },
        {
            text: 'ALL IN',
            type: '',
            value: 'AllIN',
        },
    ];
    betButtonsPostFlop = [
        {
            text: '1/4',
            value: 0.25,
            type: 'POT',
        },
        {
            text: '1/3',
            value: 0.3333333333333333,
            type: 'POT',
        },
        {
            text: '1/2',
            value: 0.5,
            type: 'POT',
        },
        {
            text: '2/3',
            value: 0.6666666666666666,
            type: 'POT',
        },
        {
            text: '3/4',
            value: 0.75,
            type: 'POT',
        },
        {
            text: '4/5',
            value: 0.8,
            type: 'POT',
        },
        {
            text: '1',
            value: 1,
            type: 'POT',
        },
        {
            text: 'ALL IN',
            value: 'AllIN',
            type: '',
        },
    ];
    betButtonSettings = [];

    @Output() emitSideNavToggle: EventEmitter<any> = new EventEmitter();

    private eventDispatcherGlobal = new EventDispatcherGlobal();

    constructor(
        private store: Store,
        private navigation: NavigationService,
        private clientToServer: ClientToServerRequestServices,
    ) {
        this.eventDispatcherGlobal.addEventListener('UpdateLHNStatus', this.checkLHNStatus.bind(this));
        this.eventDispatcherGlobal.addEventListener('UpdateTileViewStatus', this.getTileViewStatus.bind(this));
        this.eventDispatcherGlobal.addEventListener('Rathol_PopUpActive', this.isRatholePopupActive.bind(this));

        this.getStoreValues();
        this.store.select(miniTablesSelect).subscribe((data) => {
            this.miniTables = data['miniTables'];
            this.activeTable = data['miniTables'].filter((item) => item.isActive === true);
        });
    }

    ngOnInit(): void {
        this.emitSelectedRaisedBtnValue();
        // localStorage.setItem("PreFlopData", JSON.stringify(this.selectedRaiseBtnPreFlopValues));
        // localStorage.setItem("PostFlopData", JSON.stringify(this.selectedRaiseBtnPostFlopValues));

        this.updateGameTableValues('storeGameSettingData', this.selectedGameTableValues);
        this.updateGameTableValues('storePreflopSettingData', this.selectedRaiseBtnPreFlopValues);
        this.updateGameTableValues('storePostFlopSettingData', this.selectedRaiseBtnPostFlopValues);
    }

    isRatholePopupActive(isRatholePopupActive: boolean) {
        this.isRatholPopupActive = isRatholePopupActive;
        setTimeout(() => {
            this.isRatholPopupActive = false;
        }, this.clientToServer.dynaconConfigs['webClientConfigs']['ratholProgressBarTimeint'] * 1000);
    }
    getStoreValues() {
        combineLatest([this.store.select(userProfileSelect), this.store.select(userBalanceSelect)]).subscribe(([userProfile, userBalance]) => {
            const gsData = localStorage.getItem('gameSettingData');
            const preFData = localStorage.getItem('PreFlopData');
            const postFData = localStorage.getItem('PostFlopData');

            if (gsData) {
                this.selectedGameTableValues = JSON.parse(gsData);
            }
            if (preFData) {
                this.selectedRaiseBtnPreFlopValues = JSON.parse(preFData);
            }
            if (postFData) {
                this.selectedRaiseBtnPostFlopValues = JSON.parse(postFData);
            }

            this.userName = userProfile['screenName'];

            if (!_.isEmpty(userBalance) && !_.isEmpty(userBalance['netRealBalance']) && !_.isEmpty(userBalance['tourneyCurrencyBalance'])) {
                this.accountCurrency = userBalance['netRealBalance']['accountCurrency'];
                this.accountBalance = userBalance['netRealBalance']['accountBalance'];
            }
        });
    }

    emitSelectedGameSettingValue(type: string, value: any) {
        this.selectedGameTableValues[type] = value;
        // this.store.dispatch(updateGameTableSettings(this.selectedGameTableValues));
        localStorage.setItem('gameSettingData', JSON.stringify(this.selectedGameTableValues));
        // this.getStoreValues();
        let isFeltChange;

        if (type === 'tableThemesTableFelt' || type === 'tableThemesTableStyle') {
            isFeltChange = 1;
        } else {
            isFeltChange = 0;
        }
        // let isFeltChange = (type === "'tableThemesTableFelt'" || "'tableThemesTableStyle'") ? 1 : 0;

        this.updateGameTableValues('storeGameSettingData', this.selectedGameTableValues, isFeltChange);
    }

    emitSelectedRaisedBtnValue() {
        this.selectedRaiseBtnValues = !this.selectedRaiseBtnValues;
        if (this.selectedRaiseBtnValues) {
            this.emitSelectedPostFlopRaisedBtnValue('isPreFlopActive', this.selectedRaiseBtnValues);
        } else {
            this.emitSelectedPreFlopRaisedBtnValue('isPreFlopActive', this.selectedRaiseBtnValues);
        }
    }

    emitSelectedPreFlopRaisedBtnValue(type: string, value: any) {
        this.selectedRaiseBtnPreFlopValues[type] = value;
        localStorage.setItem('PreFlopData', JSON.stringify(this.selectedRaiseBtnPreFlopValues));
        this.updateGameTableValues('storePreflopSettingData', this.selectedRaiseBtnPreFlopValues);
    }

    emitSelectedPostFlopRaisedBtnValue(type: string, value: any) {
        this.selectedRaiseBtnPostFlopValues[type] = value;
        localStorage.setItem('PostFlopData', JSON.stringify(this.selectedRaiseBtnPostFlopValues));
        this.updateGameTableValues('storePostFlopSettingData', this.selectedRaiseBtnPostFlopValues);
    }

    goToLobby() {
        const path = this.activeTable.length > 0 ? this.activeTable[0].from : this.tileViewActiveTable.form;
        this.emitSideNavToggle.emit();
        const message = {};
        message['className'] = 'hideExpoAnimation';
        message['peerId'] = this.activeTable.length > 0 ? this.activeTable[0].tableId : this.tileViewActiveTable.tableId;
        this.eventDispatcherGlobal.dispatchEvent('hideExpoAnimation', message);
        let data = {};
        data['className'] = 'removeReplayers';
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', data);
        this.navigation.goTo(`/play/${path}`);
        // this.showWidget();
    }

    // showWidget() {
    //     const message = {};
    //     message['className'] = 'showWidget';
    //     // message["tableId"] = peerId;
    //     message['show'] = true;
    //     this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    // }
    leaveTable() {
        this.emitSideNavToggle.emit();
        const message = {};
        message['className'] = 'CanLeaveTable';
        message['peerId'] = this.activeTable.length > 0 ? this.activeTable[0].tableId : this.tileViewActiveTable.tableId;
        message['tableOpenStatus'] = true;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        // this.store.dispatch(removeMiniTable(this.activeTable[0]));
        // if(this.miniTables.length > 1){
        //     this.store.dispatch(updateActiveTable(this.miniTables[this.miniTables.length - 1]));
        // } else{
        //     this.navigation.goTo(`/play/${this.activeTable[0].from}`);
        // }
    }

    updateGameTableValues(name: string, values: any, isFeltChange = 0) {
        const message = { className: name, data: values, isFeltChange: isFeltChange };
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    goToSubItem(item: string) {
        this.showMainItems = false;
        this.selectedSubItem = item;

        if (item === 'tableThemes') {
            this.showSubItemTitle = 'PARTY_POKER_GC_COMMON_TABLETHEMES';
        } else if (item === 'gameSettings') {
            this.showSubItemTitle = 'PARTY_POKER_MOBILE_TABLE_COMMON_GAMESETTINGS';
        } else {
            this.showSubItemTitle = 'PARTY_POKER_MOBILE_TABLE_COMMON_RAISEBUTTONSETTINGS';
        }
    }

    backToMainMenuItems() {
        this.showMainItems = true;
    }

    checkLHNStatus(message: any) {
        if (!message.detail.status) {
            this.closeLHN();
        }
    }

    closeLHN() {
        this.showMainItems = true;
    }

    getAsset(value: string) {
        return this.clientToServer.webAssets['game-table']['table-settings'][value].image.src;
    }

    getTileViewStatus(message: any) {
        this.emitSideNavToggle.emit();
        this.tileViewActiveTable = this.miniTables.filter((obj) => obj.tableId === message.detail.tableId);
    }
}
