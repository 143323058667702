<div class="col mb-5 d-flex flex-wrap ppl-wrapper ppl-news">
    <h1 class="w-100 text-uppercase pl-2 pr-2 pr-sm-0 pl-sm-0">
        {{ info.blogStaticData.latestPokerNews }}
    </h1>
    <div class="news-category w-100 mb-2" *ngIf="newsCategory.length !== 0">
        <label class="ml-sm-2">{{ info.blogStaticData.chooseCategory }}:</label>
        <span [class.active]="item === tabName" *ngFor="let item of newsCategory" (click)="getDataBasedOnCategory(item)">{{
            info.blogStaticData.categories[item] | titlecase
        }}</span>
    </div>
    <ng-container *ngIf="blogFeedItem.length !== 0">
        <div
            *ngFor="let item of blogFeedItem; let i = index"
            class="col-xs-12 col-sm-6 col-md-4 mb-4 ppl-event-thumbnail"
            [ngClass]="{
                'col-md-6': i <= 1,
                'col-md-4': i >= 2
            }"
            [title]="item.title"
            (click)="goToDetails(item.url)">
            <div class="ppl-thumbnail-img">
                <img *ngIf="item.mainblock_image" class="w-100 rounded" [src]="item.mainblock_image" [alt]="item.title" />
            </div>
            <h3 class="ppl-thumbnail-title" *ngIf="item.title">
                {{ item.title }}
            </h3>
            <p class="d-flex align-content-end ppl-thumbnail-description" *ngIf="item.date_published">
                <span class="theme-clock"></span>
                <span>{{ returnNewsDate(item.date_published) }}</span>
            </p>
        </div>
    </ng-container>
    <div *ngIf="blogFeedItem.length === 0" class="d-block w-100 text-center mt-5 mb-5">
        {{ info.blogStaticData.errorMessage }}
    </div>
</div>
