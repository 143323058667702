import { Message } from '../../message';

export class LSHeadsUpTourenyPlayerSequence extends Message {
    private MESSAGE_NAME: string = 'LSHeadsUpTourenyPlayerSequence';
    private mtctId: number = 0;
    private isByeRound: boolean | null = false;
    private orderList: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, mtctId: number = 0, isByeRound: boolean | null = false, orderList: any[] | null = null) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.isByeRound = isByeRound;
        this.orderList = orderList;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getIsByeRound(): boolean | null {
        return this.isByeRound;
    }

    setIsByeRound(isByeRound: boolean | null) {
        this.isByeRound = isByeRound;
    }
    getOrderList(): any[] | null {
        return this.orderList;
    }

    setOrderList(orderList: any[] | null) {
        this.orderList = orderList;
    }
}
