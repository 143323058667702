import { Message } from '../../message';
import { MTCTPrizeInfo } from './MTCTPrizeInfo';
import { MTCTRebuyAddonInfo } from './MTCTRebuyAddonInfo';
import { MTCTTourneyInfo } from './MTCTTourneyInfo';
import { MTCTTourneyStatus } from './MTCTTourneyStatus';
import { ShootoutInfo } from './ShootoutInfo';

export class MTCTDetails extends Message {
    private MESSAGE_NAME: string = 'MTCTDetails';
    private mtctId: number = 0;
    private mtctTourneyStatus: MTCTTourneyStatus | null = null;
    private mtctTourneyInfo: MTCTTourneyInfo | null = null;
    private prizeInfo: MTCTPrizeInfo | null = null;
    private participantStatus: Map<any, any> | null = null;
    private lastUpdateTimeStamp: number = 0;
    private lastParticipantNo: number = 0;
    private rebuyAddonInfo: MTCTRebuyAddonInfo | null = null;
    private shootoutInfo: ShootoutInfo | null = null;
    private mtctEvents: any[] | null = null;
    private currentHeadsUpRound: number = 0;
    private currentSOSRound: number = 0;
    private removeScheduleOnDeltaUpdate: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        mtctTourneyStatus: MTCTTourneyStatus | null = null,
        mtctTourneyInfo: MTCTTourneyInfo | null = null,
        prizeInfo: MTCTPrizeInfo | null = null,
        participantStatus: Map<any, any> | null = null,
        lastUpdateTimeStamp: number = 0,
        lastParticipantNo: number = 0,
        rebuyAddonInfo: MTCTRebuyAddonInfo | null = null,
        shootoutInfo: ShootoutInfo | null = null,
        mtctEvents: any[] | null = null,
        currentHeadsUpRound: number = 0,
        currentSOSRound: number = 0,
        removeScheduleOnDeltaUpdate: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.mtctTourneyStatus = mtctTourneyStatus;
        this.mtctTourneyInfo = mtctTourneyInfo;
        this.prizeInfo = prizeInfo;
        this.participantStatus = participantStatus;
        this.lastUpdateTimeStamp = lastUpdateTimeStamp;
        this.lastParticipantNo = lastParticipantNo;
        this.rebuyAddonInfo = rebuyAddonInfo;
        this.shootoutInfo = shootoutInfo;
        this.mtctEvents = mtctEvents;
        this.currentHeadsUpRound = currentHeadsUpRound;
        this.currentSOSRound = currentSOSRound;
        this.removeScheduleOnDeltaUpdate = removeScheduleOnDeltaUpdate;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getMtctTourneyStatus(): MTCTTourneyStatus | null {
        return this.mtctTourneyStatus;
    }

    setMtctTourneyStatus(mtctTourneyStatus: MTCTTourneyStatus | null) {
        this.mtctTourneyStatus = mtctTourneyStatus;
    }
    getMtctTourneyInfo(): MTCTTourneyInfo | null {
        return this.mtctTourneyInfo;
    }

    setMtctTourneyInfo(mtctTourneyInfo: MTCTTourneyInfo | null) {
        this.mtctTourneyInfo = mtctTourneyInfo;
    }
    getPrizeInfo(): MTCTPrizeInfo | null {
        return this.prizeInfo;
    }

    setPrizeInfo(prizeInfo: MTCTPrizeInfo | null) {
        this.prizeInfo = prizeInfo;
    }
    getParticipantStatus(): Map<any, any> | null {
        return this.participantStatus;
    }

    setParticipantStatus(participantStatus: Map<any, any> | null) {
        this.participantStatus = participantStatus;
    }
    getLastUpdateTimeStamp(): number {
        return this.lastUpdateTimeStamp;
    }

    setLastUpdateTimeStamp(lastUpdateTimeStamp: number) {
        this.lastUpdateTimeStamp = lastUpdateTimeStamp;
    }
    getLastParticipantNo(): number {
        return this.lastParticipantNo;
    }

    setLastParticipantNo(lastParticipantNo: number) {
        this.lastParticipantNo = lastParticipantNo;
    }
    getRebuyAddonInfo(): MTCTRebuyAddonInfo | null {
        return this.rebuyAddonInfo;
    }

    setRebuyAddonInfo(rebuyAddonInfo: MTCTRebuyAddonInfo | null) {
        this.rebuyAddonInfo = rebuyAddonInfo;
    }
    getShootoutInfo(): ShootoutInfo | null {
        return this.shootoutInfo;
    }

    setShootoutInfo(shootoutInfo: ShootoutInfo | null) {
        this.shootoutInfo = shootoutInfo;
    }
    getMtctEvents(): any[] | null {
        return this.mtctEvents;
    }

    setMtctEvents(mtctEvents: any[] | null) {
        this.mtctEvents = mtctEvents;
    }
    getCurrentHeadsUpRound(): number {
        return this.currentHeadsUpRound;
    }

    setCurrentHeadsUpRound(currentHeadsUpRound: number) {
        this.currentHeadsUpRound = currentHeadsUpRound;
    }
    getCurrentSOSRound(): number {
        return this.currentSOSRound;
    }

    setCurrentSOSRound(currentSOSRound: number) {
        this.currentSOSRound = currentSOSRound;
    }
    getRemoveScheduleOnDeltaUpdate(): boolean | null {
        return this.removeScheduleOnDeltaUpdate;
    }

    setRemoveScheduleOnDeltaUpdate(removeScheduleOnDeltaUpdate: boolean | null) {
        this.removeScheduleOnDeltaUpdate = removeScheduleOnDeltaUpdate;
    }
}
