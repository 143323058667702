import { Message } from '../../message';
import { UserProfile } from '../../pgSharedMessageFactory/messages/UserProfile';

export class SendProfile extends Message {
    private MESSAGE_NAME: string = 'SendProfile';
    private responseId: number = 0;
    private userProfile: UserProfile | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, responseId: number = 0, userProfile: UserProfile | null = null) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.userProfile = userProfile;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getUserProfile(): UserProfile | null {
        return this.userProfile;
    }

    setUserProfile(userProfile: UserProfile | null) {
        this.userProfile = userProfile;
    }
}
