import { Message } from '../../message';
import { LSDynamicLanguagePackUpdate } from './LSDynamicLanguagePackUpdate';

export class ResponseMTCTDynaDetailsList extends Message {
    private MESSAGE_NAME: string = 'ResponseMTCTDynaDetailsList';
    private dynamicLanguagePackUpdate: LSDynamicLanguagePackUpdate | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, dynamicLanguagePackUpdate: LSDynamicLanguagePackUpdate | null = null) {
        super(reqServerPeerId);
        this.dynamicLanguagePackUpdate = dynamicLanguagePackUpdate;
    }

    getDynamicLanguagePackUpdate(): LSDynamicLanguagePackUpdate | null {
        return this.dynamicLanguagePackUpdate;
    }

    setDynamicLanguagePackUpdate(dynamicLanguagePackUpdate: LSDynamicLanguagePackUpdate | null) {
        this.dynamicLanguagePackUpdate = dynamicLanguagePackUpdate;
    }
}
