import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

// Initial Value
export const initialState = [];
export const athInitialState = 0;
export const buyInEnabledInitialState = true;

// initialize Selector
export const selectCashState = createFeatureSelector<any[]>('CashGameLobbyData');
export const selectFFState = createFeatureSelector<any[]>('FFGameLobbyData');
export const selectSpinsOverdriveState = createFeatureSelector<any[]>('SpinsOverdriveGameLobbyData');
export const selectSpinsBuyinState = createFeatureSelector<any[]>('SpinsBuyinData');
export const selectspinTypeState = createFeatureSelector<number>('SpinType');
export const selectAthStrikeHandCount = createFeatureSelector<any>('HandCount');
export const selectPlayerAthStrike = createFeatureSelector<any>('BlockTime');
export const selectSpinsBuyIn = createFeatureSelector<any>('isSpinsBuyInEnabled');
export const registeredTourneys = createFeatureSelector<any>('registeredTourneysData');

// update Selector
export const cashLobbyDataSelect = createSelector(selectCashState, (state: Array<object>) => state);
export const ffLobbyDataSelect = createSelector(selectFFState, (state: Array<object>) => state);
export const spinsOverdriveDataSelect = createSelector(selectSpinsOverdriveState, (state: Array<object>) => state);
export const spinsBuyin = createSelector(selectSpinsBuyinState, (state: any) => state);
export const AthHandCount = createSelector(selectAthStrikeHandCount, (state: any) => state);
export const AthBlockTime = createSelector(selectPlayerAthStrike, (state: any) => state);
export const isSpinsBuyIn = createSelector(selectSpinsBuyIn, (state: any) => state);
export const registeredTourneysList = createSelector(registeredTourneys, (state: any) => state);

// create Action
export const updateCashLobbyData = createAction('[Cash Game] Update Lobby data', (cashLobbyData: any) => ({ cashLobbyData }));
export const updateFFLobbyData = createAction('[Fast Forward Game] Update Lobby data', (ffLobbyData: any) => ({ ffLobbyData }));
export const updateSpinsOverdriveLobbyData = createAction('[spins Overdrive] Update Lobby data', (spinsOverdriveLobbyData: []) => ({
    spinsOverdriveLobbyData,
}));
export const updateSpinBuyinData = createAction('[spins Overdrive] Update Buyin data', (spinsOverdriveBuyinData: any) => ({
    spinsOverdriveBuyinData,
}));
export const updateSpinTypeData = createAction('[ spinType] Update SpinType data', (spinTypeData: number) => ({ spinTypeData }));
export const updateAthHandCount = createAction('[HandCount] Update  Ath HandCount', (HandCount: any) => ({ HandCount }));
export const updateAthBlockTime = createAction('[HandCount] Update  Ath BlockTime', (BlockTime: any) => ({ BlockTime }));
export const updateisSpinsBuyIn = createAction('[isSpinsBuyInEnabled] Update  isSpinsBuyInEnabled', (isSpinsBuyInEnabled: any) => ({
    isSpinsBuyInEnabled,
}));
export const updateTourneysData = createAction('[registeredTourneysData] Update  registeredTourneysData', (registeredTourneysData: any) => ({
    registeredTourneysData,
}));

// create Reducer
export const cashLobbyDataReducer = createReducer(
    initialState,
    on(updateCashLobbyData, (state, { cashLobbyData }) => cashLobbyData),
);
export const ffLobbyDataReducer = createReducer(
    initialState,
    on(updateFFLobbyData, (state, { ffLobbyData }) => ffLobbyData),
);
export const spinsOverdriveLobbyDataReducer = createReducer(
    initialState,
    on(updateSpinsOverdriveLobbyData, (state, { spinsOverdriveLobbyData }) => spinsOverdriveLobbyData),
);
export const spinsBuyinReducer = createReducer(
    initialState,
    on(updateSpinBuyinData, (state, { spinsOverdriveBuyinData }) => spinsOverdriveBuyinData),
);
export const athHandCountReducer = createReducer(
    athInitialState,
    on(updateAthHandCount, (state, { HandCount }) => HandCount),
);
export const athBlockTimeReducer = createReducer(
    athInitialState,
    on(updateAthBlockTime, (state, { BlockTime }) => BlockTime),
);
export const isSpinsBuyinReducer = createReducer(
    buyInEnabledInitialState,
    on(updateisSpinsBuyIn, (state, { isSpinsBuyInEnabled }) => isSpinsBuyInEnabled),
);
export const registeredTourneysListReducer = createReducer(
    initialState,
    on(updateTourneysData, (state, { registeredTourneysData }) => registeredTourneysData),
);
// export const spinTypeReducer = createReducer(initialState, on(updateSpinTypeData, (state, {spinTypeData}) => spinTypeData));
