import { Message } from '../../message';
import { LSDynamicLanguagePackUpdate } from './LSDynamicLanguagePackUpdate';

export class ResponseLobbyDyna extends Message {
    private MESSAGE_NAME: string = 'ResponseLobbyDyna';
    private dynaDetails: LSDynamicLanguagePackUpdate | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, dynaDetails: LSDynamicLanguagePackUpdate | null = null) {
        super(reqServerPeerId);
        this.dynaDetails = dynaDetails;
    }

    getDynaDetails(): LSDynamicLanguagePackUpdate | null {
        return this.dynaDetails;
    }

    setDynaDetails(dynaDetails: LSDynamicLanguagePackUpdate | null) {
        this.dynaDetails = dynaDetails;
    }
}
