import { CommonModule } from '@angular/common';
import { Component, HostBinding, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

import { Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { SocialComponentClientConfig, TournamentWidgetsClientConfig } from '@pokercore/module/core';
import * as _ from 'lodash-es';
import moment from 'moment';

import { CalendarCountdownTimerComponent } from '../calendar-countdown-timer/calendar-countdown-timer.component';
import { FormatDecimalPipe } from './format-decimal.pipe';
import { FormatPrizepoolPipe } from './format-prizepool.pipe';

@Component({
    selector: 'poker-tournaments-widgets',
    templateUrl: 'poker-tournament-widgets.component.html',
    standalone: true,
    imports: [CommonModule, CalendarCountdownTimerComponent, FormsModule, MatPaginatorModule, FormatDecimalPipe, FormatPrizepoolPipe],
})
export class PokerTournamentWidgetsComponent extends PCComponent {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    public tournaments: any[];
    public filterTournaments: any[];
    //public userLanguage = this.claimsService.get('language');
    public defaultCover: string;
    public hoursDiffer: any;
    public searchFilterValue: any;
    public activePageDataChunk: any = [];
    public firstCut: any;
    public secondCut: any;
    public pageLength: any;

    public widgetsConfig: any;

    public pageSize: any = 25;
    public pageSizeOptions: number[] = [25, 50, 100];
    currencyRePosition = 'false';

    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }

    constructor(
        private socialComponentClientConfig: SocialComponentClientConfig,
        public zone: NgZone,
        public tournamentWidgetsConfig: TournamentWidgetsClientConfig,
        public page: Page,
    ) {
        super();
    }

    ngOnInit() {
        this.hoursDiffer = this.socialComponentClientConfig.addHoursToTournamentTime;
        this.tournamentWidgetsConfig.whenReady.subscribe(() => {
            this.widgetsConfig = this.tournamentWidgetsConfig;
            this.initMethod();
        });
    }

    initMethod() {
        this.tournaments = this.item.tournamentWidgetData?.tournaments;
        this.defaultCover = this.item.parameters.defaultCover || null;
        this.removeLateRegFeed();
        this.addTimeZoneHours();
        this.updateLateRegFeed();
        setInterval(() => {
            this.updateLateRegFeed();
        }, 400);
        this.activePageDataChunk = this.tournaments?.slice(0, this.pageSize);
        this.pageLength = this.tournaments?.length;
        this.firstCut = 0;
        this.secondCut = this.pageSize;

        this.paginator._intl.itemsPerPageLabel = this.widgetsConfig?.pagination?.itemsPerPage || 'Items per page:';
        this.paginator._intl.nextPageLabel = this.widgetsConfig?.pagination?.nextPage || 'Next Page';
        this.paginator._intl.lastPageLabel = this.widgetsConfig?.pagination?.lastPage || 'Last Page';
        this.paginator._intl.previousPageLabel = this.widgetsConfig?.pagination?.previousPage || 'Previous Page';
        this.paginator._intl.firstPageLabel = this.widgetsConfig?.pagination?.firstPage || 'First Page';
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
        }
    }

    onPageChanged(e: any) {
        this.firstCut = e.pageIndex * e.pageSize;
        this.secondCut = this.firstCut + e.pageSize;
        this.activePageDataChunk = this.tournaments?.slice(this.firstCut, this.secondCut);
    }

    // Add additional hours from timeZone
    addTimeZoneHours() {
        this.tournaments?.map((item: any) => {
            const itemDate = item.startDate;
            const dateMoment = moment(itemDate);
            const newDate = dateMoment.add(this.hoursDiffer, 'hours').format('YYYY-MM-DDTHH:mm:ss');
            item.startDate = new Date(newDate);

            item.lateDate = null;
            if (item.status.toLowerCase() == 'late registration') {
                let dateHolder = item.startDate;
                if (item.durationLateRegClose != 0) {
                    const lateDuration = item.durationLateRegClose.split(':');
                    dateHolder = moment(dateHolder).add({ hours: lateDuration[0], minutes: lateDuration[1], seconds: lateDuration[2] });
                    item.lateDate = new Date(dateHolder);
                }
            }
        });
    }

    updateLateRegFeed() {
        this.zone.run(() => {
            this.tournaments?.forEach((item: any) => {
                const currentTime: any = new Date();
                let lateRegSecondsLeft: number;
                const endTime: any = new Date(item.countdownDate);
                const millisecondsleft = endTime - currentTime;

                if (item.lateDate && item.durationLateRegClose != 0) {
                    const remainingTime = item.lateDate - currentTime;
                    item.tournamentStatus = null;
                    if (remainingTime < 0) {
                        this.tournaments.splice(this.tournaments.indexOf(item), 1);
                    }
                    if (item.durationLateRegClose == '0') {
                        this.tournaments.splice(this.tournaments.indexOf(item), 1);
                    }
                }

                if (millisecondsleft > 0) {
                    item.tournamentStatus = this.widgetsConfig.statusTimerStrings.registering;
                } else {
                    if (item.status.toLowerCase() == 'registering') {
                        lateRegSecondsLeft = millisecondsleft + 3600000;
                    } else {
                        const lateDate: any = new Date(item.lateDate);
                        lateRegSecondsLeft = lateDate - currentTime;
                    }
                    if (Math.sign(lateRegSecondsLeft) == 1) {
                        item.tournamentStatus = this.widgetsConfig.statusTimerStrings?.lateRegInProgress;
                    }
                }
            });
        });
        if (!this.searchFilterValue) {
            this.activePageDataChunk = this.tournaments?.slice(this.firstCut, this.secondCut);
            this.pageLength = this.tournaments?.length;
        }
    }

    removeLateRegFeed() {
        // Remove item which contains value "Restricted" in byin
        _.remove(this.tournaments, function (feedItem: any) {
            return feedItem.buyIn == 'Restricted';
        });
        // Remove item which contains "durationLateRegClose = 0"
        _.remove(this.tournaments, function (feedItem: any) {
            return feedItem.status.toLowerCase() == 'late registration' && feedItem.durationLateRegClose == 0;
        });
    }

    formatDate(date: any) {
        if (this.page.lang) {
            return moment(date).locale(this.page.lang).format('ddd DD MMM HH:mm');
        }
        return moment(date).format('ddd DD MMM HH:mm');
    }

    applySearchFilter(sval: string) {
        this.filterTournaments = this.tournaments?.filter((item: any) => {
            return item.name.toLowerCase().includes(sval.toLowerCase());
        });

        this.pageLength = this.filterTournaments?.length;
        this.activePageDataChunk = this.filterTournaments?.slice(0, this.paginator.pageSize);
        this.paginator.pageIndex = 0;
    }
    clearSearchInput() {
        this.searchFilterValue = '';
        this.applySearchFilter('');
    }

    // register click method
    openClient(tournament: any) {
        window.open(this.widgetsConfig.ctaLink.replace('{{id}}', tournament.id), '_blank');
    }
}
