import { Message } from '../../message';

export class PopUpInfoExML extends Message {
    private MESSAGE_NAME: string = 'PopUpInfoExML';
    private infoType: number = 0;
    private interval: number = 0;
    private appearance: number = 0;
    private contentType: number = 0;
    private icon: number = 0;
    private buttons: number = 0;
    private popupTemplate: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        infoType: number = 0,
        interval: number = 0,
        appearance: number = 0,
        contentType: number = 0,
        icon: number = 0,
        buttons: number = 0,
        popupTemplate: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.infoType = infoType;
        this.interval = interval;
        this.appearance = appearance;
        this.contentType = contentType;
        this.icon = icon;
        this.buttons = buttons;
        this.popupTemplate = popupTemplate;
    }

    getInfoType(): number {
        return this.infoType;
    }

    setInfoType(infoType: number) {
        this.infoType = infoType;
    }
    getInterval(): number {
        return this.interval;
    }

    setInterval(interval: number) {
        this.interval = interval;
    }
    getAppearance(): number {
        return this.appearance;
    }

    setAppearance(appearance: number) {
        this.appearance = appearance;
    }
    getContentType(): number {
        return this.contentType;
    }

    setContentType(contentType: number) {
        this.contentType = contentType;
    }
    getIcon(): number {
        return this.icon;
    }

    setIcon(icon: number) {
        this.icon = icon;
    }
    getButtons(): number {
        return this.buttons;
    }

    setButtons(buttons: number) {
        this.buttons = buttons;
    }
    getPopupTemplate(): any[] | null {
        return this.popupTemplate;
    }

    setPopupTemplate(popupTemplate: any[] | null) {
        this.popupTemplate = popupTemplate;
    }
}
