import { Message } from '../../message';

export class RequestCashoutEquity extends Message {
    private MESSAGE_NAME: string = 'RequestCashoutEquity';
    private tableId: number = 0;
    private gameId: number = 0;
    private requestCode: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tableId: number = 0, gameId: number = 0, requestCode: number = 0) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.gameId = gameId;
        this.requestCode = requestCode;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getRequestCode(): number {
        return this.requestCode;
    }

    setRequestCode(requestCode: number) {
        this.requestCode = requestCode;
    }
}
