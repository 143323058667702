import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';

import { Card } from '../../models/card';
import { GameTable } from '../../models/game-table';
import { Properties } from '../../utils/properties';
import { DemoGameTableService } from '../game-table/demo-game-table.service';

@Component({
    selector: 'pkr-card',
    templateUrl: './card.component.html',
    animations: [
        trigger('myanimation', [
            state('communityCard', style({})),
            transition(
                '* => communityCard',
                [
                    animate(
                        150,
                        keyframes([
                            style({ transform: 'rotate3d(1,0,0,0deg) scale({{scaleValue}})', backgroundPosition: '-4957px 0px', offset: 0 }),
                            style({ transform: 'rotate3d(1,0,0,90deg) scale({{scaleValue}})', backgroundPosition: '-4957px 0px', offset: 0.49 }),
                            style({ transform: 'rotate3d(1,0,0,-90deg) scale({{scaleValue}})', backgroundPosition: '{{rotateValue}}', offset: 0.5 }),
                            style({ transform: 'rotate3d(1,0,0,0deg) scale({{scaleValue}})', backgroundPosition: '{{rotateValue}}', offset: 1.0 }),
                        ]),
                    ),
                ],
                { params: { rotateValue: '', scaleValue: '' } },
            ),
            state('mobilecommunityCard', style({})),
            transition(
                '* => mobilecommunityCard',
                [
                    animate(
                        150,
                        keyframes([
                            style({ transform: 'rotate3d(1,0,0,0deg)', backgroundPosition: '-4487px 0px', offset: 0 }),
                            style({ transform: 'rotate3d(1,0,0,90deg)', backgroundPosition: '-4487px 0px', offset: 0.49 }),
                            style({ transform: 'rotate3d(1,0,0,-90deg)', backgroundPosition: '{{rotateValue}}', offset: 0.5 }),
                            style({ transform: 'rotate3d(1,0,0,0deg)', backgroundPosition: '{{rotateValue}}', offset: 1.0 }),
                        ]),
                    ),
                ],
                { params: { rotateValue: '', scaleValue: '' } },
            ),
            state('pocketCard', style({})),
            transition(
                '* => pocketCard',
                [
                    animate(
                        '100ms',
                        keyframes([
                            style({ top: '33%', left: '45%', offset: 0 }),
                            style({ top: '{{top}}', left: '{{left}}', opacity: 1, offset: 1.0 }),
                        ]),
                    ),
                ],
                { params: { top: '', left: '' } },
            ),

            state('pocketCards', style({})),
            transition(
                '* => pocketCards',
                [
                    animate('100ms 0s', keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 0, offset: 1.0 })])),

                    animate(
                        '100ms {{cardIndex}}ms',
                        keyframes([
                            style({ top: '33%', left: '45%', opacity: 1, offset: 0 }),
                            style({ top: '{{top}}', left: '{{left}}', opacity: 1, offset: 1.0 }),
                        ]),
                    ),
                ],
                { params: { top: '', left: '', cardIndex: '' } },
            ),
        ]),
    ],
})
export class CardComponent implements AfterViewChecked {
    @Input() card: Card;
    @Input() index: number;
    @Input() seq: number;
    @Input() gameTable: GameTable;
    @Input() leftValue: any;
    @Input() topValue: any;
    scaleValue: any;
    cardIndex: any;
    isMobile: any;

    constructor(
        private cdr: ChangeDetectorRef,
        private gameService: DemoGameTableService,
    ) {}

    ngOnInit() {
        this.scaleCards();
        this.isMobile = this.gameService.isMobile;
    }

    // ngOnChanges() {
    //   this.cdr.detectChanges();
    // }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
    scaleCards() {
        const winWidth = window.outerWidth;
        if (winWidth <= 980 && winWidth > 767) {
            this.scaleValue = 0.9;
        } else if (winWidth <= 767) {
            this.scaleValue = 0.55;
        } else {
            this.scaleValue = 1;
        }
    }

    cardanima() {
        const animat = this.card.cardAnimation;
        return animat;
    }

    cardHide() {
        const cardHide: boolean = this.card.cardHide;
        return cardHide;
    }

    // getX() {
    //   if (this.card.communityCard) {
    //     // this.card.show = true;
    //     // if (!this.card.cardAnimation || (this.card.cardAnimation && this.card.cardHide)) {
    //     //   return this.gameTable.dealer.position.getXAxis();
    //     // }
    //     // else {
    //       if( this.card.cardAnimation ){
    //         this.card.cardState = 'communityCard';
    //       }
    //       return `${Number(this.card.position.x) + Number((this.index * Properties.COMMUNITY_CARD_SEQUENCE))}%`;
    //     // }
    //   }
    //   else {
    //     let point = Properties.pointMap.get(this.index);
    //     return `${point.x}px`
    //   }
    // }

    // getY() {
    //   if (this.card.communityCard) {
    //     return `${this.card.position.y}%`;
    //   }
    //   else {
    //     let point = Properties.pointMap.get(this.index)
    //     return `${point.y}px`
    //   }
    // }

    calcLeftValue(leftValue: any, index: number) {
        if (this.card.communityCard) {
            if (this.card.cardAnimation && this.gameService.gameTable.cardAnimation) {
                if (this.isMobile) {
                    this.card.cardState = 'mobilecommunityCard';
                } else {
                    this.card.cardState = 'communityCard';
                }
            } else {
                this.card.cardState = '';
            }
            return `${Number(this.card.position.x) + Number(this.index * Properties.COMMUNITY_CARD_SEQUENCE)}%`;
        } else {
            const gameType = this.gameService.gameTable.gType;
            this.card.cardState = '';
            if (index == 0) {
                if (!this.gameService.gameTable.cardAnimation) {
                    this.card.cardState = '';
                } else {
                    this.card.cardState = 'pocketCard';
                }
                return leftValue;
            }
            if (index != 0) {
                if (!this.gameService.gameTable.cardAnimation) {
                    this.card.cardState = '';
                } else {
                    this.card.cardState = 'pocketCards';
                }
                this.cardIndex = 80 * index;
                if (gameType.indexOf('7 Card') > -1) {
                    leftValue = parseFloat(leftValue) + 2.1 * index;
                } else if (gameType.indexOf('Omaha') > -1) {
                    if (this.isMobile) {
                        leftValue = parseFloat(leftValue) + 3.4 * index;
                    } else {
                        leftValue = parseFloat(leftValue) + 3 * index;
                    }
                } else {
                    if (this.isMobile) {
                        leftValue = parseFloat(leftValue) + 0;
                    } else {
                        leftValue = parseFloat(leftValue) + 7 * index;
                    }
                }
                return leftValue + '%';
            }
        }
    }

    calcTopValue(topValue: any) {
        if (this.card.communityCard) {
            return `${this.card.position.y}%`;
        } else {
            return topValue;
        }
    }

    @HostListener('window:resize', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onResize(event: any) {
        this.scaleCards();
    }
}
