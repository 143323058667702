import { Injectable } from '@angular/core';

import { Howl } from 'howler';

import { CommonGameProperties } from '../game-config/CommonGameProperties';
import { uiConfig } from '../game-config/uiconfig';

@Injectable({
    providedIn: 'root',
})
export class SoundUtilService {
    callerSound;
    soundVolume;
    isGlobalMute;
    soundLibrary;
    isSoundPresent;
    filesLoaded;
    totalFiles;
    tableId;
    playingSound = null as any;
    errorName;
    constructor() {
        this.callerSound = '';
        this.soundVolume = 1;
        this.isGlobalMute = false;
        if (this.soundLibrary === undefined) this.soundLibrary = {};
        this.isSoundPresent = false;
        this.filesLoaded = 0;
        this.totalFiles = 0;
        this.tableId = 0;
        this.playingSound = null;
    }

    isiOS() {
        return navigator.userAgent.match(/iPhone|iPod|iPad/i) !== null;
    }

    globalMute() {}

    handleSoundsLoaded() {
        this.filesLoaded++;
        if (this.filesLoaded >= this.totalFiles) {
            this.isSoundPresent = true;
        }
    }
    handleLoadError = function (name) {
        this.errorName = name;
        //console.log('sound load error ' + name);
    };

    loadSounds(path, arr) {
        const len = arr.length;
        this.totalFiles = len;
        this.filesLoaded = 0;
        for (let k = 0; k < len; k++) {
            const file1 = path + arr[k].file + '.mp3';
            this.soundLibrary[arr[k].name] = new Howl({
                src: [file1],
                loop: arr[k].loop,
                autoplay: false,
                onload: this.handleSoundsLoaded.bind(this),
                onloaderror: this.handleLoadError.bind(this, arr[k].name),
            });
        }
    }
    setTableId(tableId) {
        this.tableId = tableId;
    }
    playSound(name) {
        //console.log(CommonGameProperties['facade']['isContextAvailable'] + 'isContextAvailable');
        if (!CommonGameProperties['facade']['isContextAvailable']) return;
        try {
            //console.log(CommonGameProperties['facade']['activeTableId'] + 'facade.activeTableId');
            //console.log(CommonGameProperties['facade']['table' + this.tableId].currentTableScale() + 'currentTablescale');
            //console.log(uiConfig['toggleSoundVal'] + 'uiConfig');
            //console.log(this.tableId + 'this.tableId');
            if (
                CommonGameProperties['facade']['table' + this.tableId].currentTableScale() == 1 &&
                CommonGameProperties['facade']['activeTableId'] == this.tableId &&
                uiConfig['toggleSoundVal']
            ) {
                this.soundVolume = 1;
            } else {
                this.soundVolume = 0;
            }
            //console.log(this.isSoundPresent + 'isSoundPresent');
            //console.log(this.soundLibrary + 'soundLibrary');
            if (this.isSoundPresent && this.soundLibrary[name]) {
                this.soundLibrary[name].play();
                this.soundLibrary[name].volume(this.soundVolume);
                this.playingSound = this.soundLibrary[name];
                this.soundLibrary[name].on(
                    'end',
                    function (name) {
                        this.soundLibrary[name].off('end');
                        this.playingSound = null;
                    }.bind(this, name),
                );
            }
        } catch (e) {
            //console.log('playSound ==> ', e);
        }
    }
    stopSound(names) {
        try {
            for (let i = 0; i < names.length; i++) {
                if (this.isSoundPresent && this.soundLibrary[names[i]]) {
                    this.soundLibrary[names[i]].stop();
                }
            }
        } catch (error) {
            //console.log(error);
        }
    }
    stopLoopSound(name) {
        try {
            if (this.isSoundPresent) {
                this.soundLibrary[name].stop();
            }
        } catch (error) {
            //console.log(error);
        }
    }
    pauseLoopSound(name) {
        try {
            if (this.isSoundPresent) {
                this.soundLibrary[name].pause();
            }
        } catch (error) {
            //console.log(error);
        }
    }
    setSoundVolume(curVolume) {
        this.soundVolume = curVolume;
        //console.log(this.playingSound + 'this.playingSound');
        if (this.playingSound) this.playingSound.volume(this.soundVolume);
    }

    multiSoundPlay(arr) {
        if (!CommonGameProperties['facade']['isContextAvailable']) return;

        if (!this.isSoundPresent) {
            return;
        }
        const name = arr[0];
        arr.splice(0, 1);
        this.soundLibrary[name].play();
        this.soundLibrary[name].volume(this.soundVolume);
        this.playingSound = this.soundLibrary[name];
        this.soundLibrary[name].on(
            'end',
            function (name) {
                if (arr.length > 0) {
                    this.playNextSound(arr);
                } else {
                    this.soundLibrary[name].off('end');
                    this.playingSound = null;
                }
            }.bind(this, name),
        );
    }
    playNextSound(arr) {
        if (arr.length > 1) {
            setTimeout(this.multiSoundPlay.bind(this, arr), 550);
        } else {
            setTimeout(this.playSound.bind(this, arr[0]), 550);
            arr.splice(0, 1);
        }
    }
}
