<div class="poker-modal" *ngIf="faqData">
    <div class="modal-cust modal-deposit-faq" tabindex="-1" style="display: block">
        <div class="">
            <div class="modal-main modal-popup-lg">
                <div class="modal-head">
                    <h5 class="modal-title text-center">{{ widgetConfig?.faqsTitle || 'FAQs' }} | {{ faqData?.name }}</h5>
                    <button
                        type="button"
                        class="btn btn-modal-close btn-link theme-ex"
                        (click)="closeFAQsModal(true)"
                        data-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-content-in p-3">
                    <div class="">
                        <div class="mb-3 faq-search-form form-base">
                            <div class="form-group">
                                <div class="form-control-container">
                                    <input
                                        name="faqSearch"
                                        [(ngModel)]="faqSearch"
                                        type="text"
                                        value=""
                                        placeholder="{{ widgetConfig?.search || 'Search' }}"
                                        class="form-control w-100 themepark-validation-icon form-control-i-r" />
                                    <div class="recipient-currency-symbol"><span class="theme-search"></span></div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="faqData?.faqs | search: 'question' : faqSearch as result">
                            <div *ngFor="let faq of result" class="card p-4 shadow-sm mb-4">
                                <h5>{{ faq?.question }}</h5>
                                <div class="faq-answer" [innerHtml]="getFaqAnswer(faq?.answer)"></div>
                            </div>
                            <div *ngIf="result.length === 0" class="p-3 text-center mt-5 mb-4">
                                <h5 class="mb-0">{{ widgetConfig?.noResultsFound || 'No results found' }}</h5>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader" *ngIf="enableLoader">
    <span>{{ widgetConfig?.pleaseWait || 'Please Wait' }}</span>
</div>
