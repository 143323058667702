import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseRibbonmarkup',
    standalone: true,
})
export class ParseRibbonMarkupPipe implements PipeTransform {
    transform(text: string, replacements: any): string {
        if (text) {
            const search = Object.keys(replacements);
            for (let i = 0; i < search.length; i++) {
                text = text.replace('%' + search[i] + '%', replacements[search[i]]);
            }
            return text;
        }
        return '';
    }
}
