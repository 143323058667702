import { Message } from '../../message';

export class SecurityTokenOTPRequest extends Message {
    private MESSAGE_NAME: string = 'SecurityTokenOTPRequest';
    private loginId: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, loginId: string | null = null) {
        super(reqServerPeerId);
        this.loginId = loginId;
    }

    getLoginId(): string | null {
        return this.loginId;
    }

    setLoginId(loginId: string | null) {
        this.loginId = loginId;
    }
}
