<div *ngIf="!initData.contentNotFound" class="bigwin-popup-block" [style.background]="initData?.bigWinBackgroundColor">
    <h1 class="title-head" [ngStyle]="{ backgroundImage: 'url(' + initData?.titleBackgroundImage.src + ')' }">{{ initData?.title }}</h1>
    <div class="bigwin-wrap">
        <div class="bigwin-content">
            <div
                *ngIf="initData?.balanceTitle !== null && initData?.balanceTitle !== undefined && initData?.balanceTitle.length > 4; else noBalance"
                class="balance-block">
                {{ initData?.balanceTitle }}
                <span *ngIf="currencyParam !== null && currencyParam !== ''"
                    >{{ getFormatCurrency(currencyParam, balanceParam) }} {{ currencyParam | uppercase }}</span
                >
            </div>
            <ng-template #noBalance>
                <div class="bigwin-no-balance">&nbsp;</div>
            </ng-template>
            <div class="win-info" [innerHTML]="initData?.description"></div>
            <span class="message">{{ initData?.ctaDescription }}</span>
            <div class="button-block">
                <button
                    class="btn withdraw-button mr-2"
                    [style.background-color]="initData?.cashierCTA?.[0]?.backgroundColor"
                    [style.color]="initData?.cashierCTA?.[0]?.color"
                    (click)="withdraw()">
                    {{ initData?.cashierCTA?.[0]?.text }}
                </button>

                <button
                    class="btn play-button ml-2"
                    [style.background-color]="initData?.keepPlayingCTA?.[0]?.backgroundColor"
                    [style.color]="initData?.keepPlayingCTA?.[0]?.color"
                    (click)="keepPlaying()">
                    {{ initData?.keepPlayingCTA?.[0]?.text }}
                </button>
            </div>
        </div>
    </div>
</div>
<pk-page-not-found *ngIf="initData.contentNotFound"></pk-page-not-found>
