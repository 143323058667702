import { Message } from '../../message';

export class StringMapResponse extends Message {
    private MESSAGE_NAME: string = 'StringMapResponse';
    private msgType: string | null = null;
    private attributeMap: Map<any, any> | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, msgType: string | null = null, attributeMap: Map<any, any> | null = null) {
        super(reqServerPeerId);
        this.msgType = msgType;
        this.attributeMap = attributeMap;
    }

    getMsgType(): string | null {
        return this.msgType;
    }

    setMsgType(msgType: string | null) {
        this.msgType = msgType;
    }
    getAttributeMap(): Map<any, any> | null {
        return this.attributeMap;
    }

    setAttributeMap(attributeMap: Map<any, any> | null) {
        this.attributeMap = attributeMap;
    }
}
