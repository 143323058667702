import * as PIXI from 'pixi.js';

import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { GameTableNotificationService } from '../../../service/game-table-notification.service';
import { selectLoyaltyCashBack } from '../../../store/loyalty-cashback.store';
import { EventDispatcher } from '../../lib/services/EventDispatcher';
import { BGView } from '../../lib/views/BGView';
import { BetView } from '../../lib/views/BetView';
import { BottomPanelView } from '../../lib/views/BottomPanelView';
import { CardsView } from '../../lib/views/CardsView';
import { CommunityCardsView } from '../../lib/views/CommunityCardsView';
import { ControlPanelView } from '../../lib/views/ControlPanelView';
import { PlayerView } from '../../lib/views/PlayerView';
import { TableView } from '../../lib/views/TableView';
import { BGViewModel } from '../../lib/vms/BGViewModel';
import { BetViewModel } from '../../lib/vms/BetViewModel';
import { CardsViewModel } from '../../lib/vms/CardsViewModel';
import { CommunityCardsViewModel } from '../../lib/vms/CommunityCardsViewModel';
import { PlayerViewModel } from '../../lib/vms/PlayerViewModel';
import { TableViewModel } from '../../lib/vms/TableViewModel';
import { CommonGameProperties } from '../game-config/CommonGameProperties';
import AssetPaths from '../game-config/assetpath.json';
import LoadConfig from '../game-config/loadconfig.json';
import serverIdConstants from '../game-config/serverIdConstants.json';
import SlotCoreGameEvent from '../game-config/slotCoreGameEvent.json';
import { uiConfig } from '../game-config/uiconfig';
import { DisplayUtil } from '../game-utils/DisplayUtils';
import { SlotCoreService } from '../services/SlotCoreService';
import { SoundUtilService } from '../services/sound-util.service';
import { CashoutEquityParentView } from '../views/CashoutEquityParentView';
import { DisconnectionView } from '../views/DisconnectionView';
import { GenericPopUpView } from '../views/GenericPopUpView';
import { InfoTableView } from '../views/InfoTableView';
import { KothTableView } from '../views/KothTableView';
import { LoyaltyCashBackView } from '../views/LoyaltyCashBackView';
import { RabbitView } from '../views/RabbitView';
import { RegistrationView } from '../views/RegistrationView';
import { SelfEmojiView } from '../views/SelfEmojiView';
import { ThrowAnimationView } from '../views/ThrowAnimationView';
import { TimeBankView } from '../views/TimeBankView';
import { UserProfileView } from '../views/UserProfileView';
import { WinTableView } from '../views/WinTableView';
import { BottomPanelViewModel } from '../vms/BottomPanelViewModel';
import { CashoutEquityParentViewModel } from '../vms/CashoutEquityParentViewModel';
import { ControlPanelViewModel } from '../vms/ControlPanelViewModel';
import { CoreViewModel } from '../vms/CoreViewModel';
import { DisconnectionViewModel } from '../vms/DisconnectionViewModel';
import { GenericPopUpViewModel } from '../vms/GenericPopUpViewModel';
import { InfoTableViewModel } from '../vms/InfoTableViewModel';
import { KothTableViewModel } from '../vms/KothTableViewModel';
import { LoyaltyCashBackViewModel } from '../vms/LoyaltyCashBackViewModel';
import { RabbitViewModel } from '../vms/RabbitViewModel';
import { RegistrationViewModel } from '../vms/RegistrationViewModel';
import { SelfEmojiViewModel } from '../vms/SelfEmojiViewModel';
import { ThrowAnimationViewModel } from '../vms/ThrowAnimationViewModel';
import { TimeBankViewModel } from '../vms/TimeBankViewModel';
import { UserProfileViewModel } from '../vms/UserProfileViewModel';
import { WinTableViewModel } from '../vms/WinTableViewModel';

export class PokerGameContainer extends PIXI.Container {
    displayUtil: DisplayUtil = new DisplayUtil();
    gameApp?: any;
    soundUtil: any;
    mainGameContainer: any;
    popupContainer: any;
    eventDispatcher: EventDispatcher;
    bgView: BGView;
    bgViewModel: BGViewModel;
    playerView: PlayerView;
    playerViewModel: PlayerViewModel;
    tableView: TableView;
    tableViewModel: TableViewModel;
    communityCardsView: CommunityCardsView;
    communityCardsViewModel: CommunityCardsViewModel;
    cardsView: CardsView;
    cardsViewModel: CardsViewModel;
    betView: BetView;
    betViewModel: BetViewModel;
    controlPanelView: ControlPanelView;
    controlPanelViewModel: ControlPanelViewModel;
    bottomPanelView: BottomPanelView;
    bottomPanelViewModel: BottomPanelViewModel;
    timeBankView: TimeBankView;
    timeBankViewModel: TimeBankViewModel;
    coreViewModel: CoreViewModel;
    disconnectionView: DisconnectionView;
    disconnectionViewModel: DisconnectionViewModel;
    selfEmojiView: SelfEmojiView;
    infoTableView: InfoTableView;
    infoTableViewModel: InfoTableViewModel;
    viewCreationDelay = 0;
    compIndex: any;
    parentFacade: any;
    tableId: any;
    gameService: SlotCoreService;
    viewsArray = [];
    viewModelsArr = [];
    tableOpenStatus: any;
    messageQueue = [];
    toHomeLobby: any;
    isATHEnabled: any;
    onCloseTableObj: any;
    isPlayerActive: any;
    ratholData: any;
    activeRatholTables = [];
    totalWarning = 0;
    eventDispatcherGlobal = new EventDispatcherGlobal();
    gameTableNotificationService: GameTableNotificationService = new GameTableNotificationService();
    peerId;
    initialPoint;
    finalPoint;
    isTouchStart = false;
    soundObj: any;
    soundsPath = AssetPaths.gameAssets + 'sounds/';
    poolId: any;
    constructor(id) {
        super();
        this.eventDispatcher = new EventDispatcher(id);
        this.soundUtil = CommonGameProperties.facade['soundObj'];
        this.parentFacade = CommonGameProperties.facade;
        this.tableId = id;
        this.peerId = id;
        this.gameService = new SlotCoreService();
        uiConfig['tableAvailableStatus' + this.tableId] = true;
        this.tableOpenStatus = true;
        uiConfig['toggleSoundVal'] = true;
        this.viewsArray = [];
        this.viewModelsArr = [];
        this.messageQueue = [];
        this.toHomeLobby = false;
        this.isATHEnabled = CommonGameProperties.dynaconConfigs['webClientConfigs']['enableATH'];
        this.loadTableConfig(id);
        this.initPostTableCreation();
        this.onCloseTableObj;
        this.isPlayerActive = true;
        this.getAntiTblLocalStorage();
        this.ratholData = {};
        this.activeRatholTables = [];
        //this.soundObj = new SoundUtilService(this.tableId);
        this.soundObj = new SoundUtilService();
        this.soundObj.setTableId(this.tableId);
        const soundFiles = LoadConfig.gameSound.sounds;
        this.soundObj.loadSounds(this.soundsPath, soundFiles);
    }
    createContainers = function () {
        this.mainGameContainer = this.displayUtil.getContainer();
        this.popupContainer = this.displayUtil.getContainer();
        this.addChild(this.mainGameContainer);
        this.mainGameContainer.name = 'MainGameContainer';
        this.addChild(this.popupContainer);
        this.mainGameContainer.interactive = true;
        this.gameService.setDiamondBalance(this.parentFacade.sessionDataModel.getDiamondBalance());
    };
    initPostTableCreation = function () {
        //this.gameService.setDiamondBalance(this.parentFacade.getDiamondbalance());
        this.parentFacade.sendRequestAutoOptionsOptionsMappings();
    };
    getAntiTblLocalStorage = function () {
        const antiTblObj = localStorage.getItem('antiTblHop');
        if (antiTblObj != null) {
            this.totalWarning = JSON.parse(antiTblObj).totalWarning;
        } else {
            this.totalWarning = 0;
        }
    };
    createNextView = function () {
        this.compIndex++;
        switch (this.compIndex) {
            case 1:
                break;
            case 2:
                this.createRabbitView(new RabbitView());
                this.createTableView(new TableView());
                break;
            case 3:
                this.createCommunityView(new CommunityCardsView());
                break;
            case 4:
                this.createBetView(new BetView(this.gameService));
                break;
            case 5:
                this.createPlayerView(new PlayerView(this.gameService, this.eventDispatcher, this.tableId));
                this.createLoyaltyCashBackView(new LoyaltyCashBackView());
                break;
            case 6:
                this.createCardsView(new CardsView(this.gameService));
                break;
            case 7:
                this.createCashoutEquityParentView(new CashoutEquityParentView(this.gameService, this.eventDispatcher, this.tableId));
                break;
            case 8:
                this.createControlPanel(new ControlPanelView());
                break;
            case 9:
                this.createBottomPanel(new BottomPanelView());
                break;
            case 10:
                this.createTimeBank(new TimeBankView(this.gameService));
                break;
            case 11:
                this.createSelfEmoji(false);
                break;
            case 12:
                this.createRegistrationView(new RegistrationView());
                break;
            case 13:
                this.createUserProfileView(new UserProfileView());
                break;
            case 14:
                this.createInfoTable(new InfoTableView());
                break;
            case 15:
                if (this.gameService.gTableType == serverIdConstants.TABLE_TYPE_SPINS) {
                    this.createWinTable(new WinTableView());
                    // this.createExpoSpinsTable(new ExpoSpinView());
                } else {
                    setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
                }
                break;
            case 16:
                if (this.gameService.gTableType == serverIdConstants.TABLE_TYPE_CASH && this.gameService.getMaxNoOfSeats() == 2) {
                    this.createKothTableView(new KothTableView());
                    this.hideKothBtn();
                } else {
                    setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
                }
                break;
            case 17:
                //onGameViewCreated --should be at last as it won't invoke createNextView
                this.onGameViewCreated();
                break;
        }
    };
    initiateViewsCreation = function () {
        this.compIndex = 1;
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        setTimeout(this.createBackground(new BGView()), this.viewCreationDelay);
        // setTimeout(function () {
        //     this.createBackground(new BGView());
        // }, this.viewCreationDelay);
    };
    createEventDispatcher = function () {
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.REMOVED_PLAYERS, this.handleRemovedPlayers.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ADD_NEW_TABLE, this.addNewTable.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.WIN_GAME_CLOSE, this.winGameCloseReq.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.RESIZE_TABLE_VIEW, this.resizeTable.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.RESIZE_TABLE_VIEW, this.resizeBg.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.MAXIMISE_TABLE_VIEW, this.maximiseTableView.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ON_GAME_RESIZE, this.resizeGame.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_BET_OPTIONS, this.sendOptionSetAck.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.BET_OPTION_SELECTED, this.sendSelectOption.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.AUTO_BET_OPTION_SELECTED, this.sendAutoSelectOption.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.IAM_BACK_CLICKED, this.sendIamBackBtnClick.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SELF_EMOJI_SELECTED, this.sendSelectedEmoji.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.REQ_THROW_ANIMATION, this.sendReqThrowAnimation.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SEND_REQUEST_AUTO_CHECK_ON, this.sendRequestAutoCheckOn.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.UN_REGISTER, this.sendUnRegisterReq.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ON_LEAVE, this.canLeaveTable.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ON_SITOUT, this.onSitOut.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.TIMER_STARTED_NOTIFICATION, this.optionTimerStarted.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.TIMER_STOPPED_NOTIFICATION, this.optionTimerRemoved.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.CLOSE_TABLE, this.closeTable.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ENABLE_TIME_BANK, this.sendEnableTimeBank.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.CONCLUDED_LEAVE, this.removeGameTable.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.REQ_ENABLE_RABBIT, this.sendRabbitReq.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CARD_OPTION_SHOW_SELECTED, this.sendShowOptionSelected.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CARD_OPTION_MUCK_SELECTED, this.sendMuckOptionSelected.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.NEXT_HAND_SELECTED, this.sendReqNextHandOffFoldAndWatch.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_CARD_OPTION_DONTSHOW_SELECTED, this.sendDontShowOptionSelected.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ADD_GENERIC_POPUP, this.addGenericPopUp.bind(this));
        // this.eventDispatcher.addEventListener(
        //   SlotCoreGameEvent.SHOW_SIDE_MENU,
        //   this.showSideMenuReq.bind(this)
        // );
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_FORCE_AWAY_STATE, this.showForceAwayState.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.OPEN_REBUY_WINDOW, this.openRebuyWindow.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.HU_PAIR_TABLE_STATUS, this.sendHuPairTableStatus.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.MUTE_SOUNDS, this.muteSounds.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_LOYALTY_CASH_BACK_POPUP, this.showLoyaltyCashBackPopUp.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_ANTITBL_HOP_SITOUT, this.showAntiTblHopSitOut.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.ATH_CLOSE_TABLE, this.onCloseTable.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SITIN_ALL_TBL, this.sitinAllTbl.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SHOW_ANTITBL_HOP_AUTO_SITOUT, this.showAntiTblHopAutoSitOutPopup.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.SELECTED_CASHOUT_OPTION, this.sendCashoutOption.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.RATHOL_BUYIN, this.ratholBuyIn.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.RATHOL_CANCEL, this.ratholCancel.bind(this));
        this.eventDispatcher.addEventListener(SlotCoreGameEvent.OPEN_ATH_LEARN_MORE, this.openATHLearnMore.bind(this));
    };
    createCoreViewModels = function () {
        this.coreViewModel = new CoreViewModel(this.gameService, this.eventDispatcher, this.parentFacade.tableSettingsDataModel);
        this.coreViewModel.initiate(this.parentFacade, this);
        this.coreViewModel.createEvents(this.eventDispatcher);
    };
    createBackground = function (view) {
        this.bgViewModel = new BGViewModel(this.gameService, this);
        view.createView(this.eventDispatcher, this.bgViewModel, this.parentFacade.tableSettingsDataModel.gameSettingsDataObj);
        this.bgView = view;
        this.mainGameContainer.addChild(view);
        this.bgChildContainer = this.displayUtil.getContainer();
        this.bgView.addChild(this.bgChildContainer);
        this.bgViewModel = new BGViewModel(this.gameService, this);
        this.bgViewModel.setView(view, this.eventDispatcher);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createPlayerView = function (view) {
        this.playerViewModel = new PlayerViewModel(this.gameService);
        this.tableView.addChild(view);
        this.playerView = view;
        this.playerView.createView(this.playerViewModel);
        this.playerViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.playerViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createTableView = function (view) {
        view.createView(this.eventDispatcher, this.gameService.getMaxNoOfSeats());
        this.bgChildContainer.addChild(view);
        this.tableView = view;
        this.tableViewModel = new TableViewModel(this.gameService);
        this.tableViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.tableViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createCommunityView = function (view) {
        this.CommunityCardsViewModel = new CommunityCardsViewModel(this.gameService, this.parentFacade.tableSettingsDataModel);
        this.tableView.addChild(view);
        view.createView(this.eventDispatcher, this.CommunityCardsViewModel, this.gameService.getMaxNoOfSeats());
        this.CommunityCardsViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.CommunityCardsViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createBetView = function (view) {
        view.createView(this.eventDispatcher, this.gameService.getMaxNoOfSeats());
        this.tableView.addChild(view);
        this.betViewModel = new BetViewModel(this.gameService);
        this.betViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.betViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createCardsView = function (view) {
        this.cardsViewModel = new CardsViewModel(this.gameService, this.parentFacade.tableSettingsDataModel);
        this.tableView.addChild(view);
        view.createView(this.eventDispatcher, this.cardsViewModel, this.gameService.getMaxNoOfSeats());
        this.cardsViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.cardsViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createControlPanel = function (view) {
        this.controlPanelViewModel = new ControlPanelViewModel(this.gameService);
        view.createView(this.controlPanelViewModel, this.eventDispatcher, this.parentFacade.tableSettingsDataModel);
        this.controlPanelView = view;
        this.tableView.addChild(view);
        this.controlPanelViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.controlPanelViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createBottomPanel = function (view) {
        view.createView(this.eventDispatcher, this.gameService);
        this.bottomPanelView = view;
        this.tableView.addChild(view);
        this.bottomPanelViewModel = new BottomPanelViewModel(this.gameService);
        this.bottomPanelViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.bottomPanelViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createTimeBank = function (view) {
        this.timeBankViewModel = new TimeBankViewModel(this.gameService);
        this.tableView.addChild(view);
        view.createView(this.eventDispatcher, this.timeBankViewModel, this.gameService.getMaxNoOfSeats());
        this.timeBankViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.timeBankViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createRabbitView = function (view) {
        this.rabbitViewModel = new RabbitViewModel(this.gameService, this.parentFacade.tableSettingsDataModel);
        this.bgChildContainer.addChild(view);
        view.createView(this.eventDispatcher, this.rabbitViewModel);
        this.rabbitViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.rabbitViewModel);
    };
    handleGameServerResponse = function (message, data, title, msg, btn) {
        if (message == 'JoinPoolResponse' || message == 'PrevPlayingFastPokerPools') {
            this.poolId = data['poolID'];
        }
        if (
            !this.gameService.getIsTableCreated() &&
            message != 'JoinTableResponse' &&
            message != 'JoinPoolResponse' &&
            message != 'PrevPlayingFastPokerPools'
        ) {
            this.messageQueue.push({
                message: message,
                data: data,
                title: title,
                btn: btn,
            });
            return;
        }
        switch (message) {
            case 'balance':
                this.coreViewModel.handleBalanceResponse(data);
                this.destroyPreloader();
                setTimeout(
                    function () {
                        this.showPlaceYourBetsMessage();
                    }.bind(this),
                    0,
                );
                break;
            case 'ResponseRegainPlayerFailed':
                this.removeGameTable();
                break;
            case 'CulturalFormat':
                this.coreViewModel.handleCulturalFormatResponse(data);
                break;
            case 'CurrencyDetailsList':
                this.coreViewModel.handleCurrencyDetailsListResponse(data);
                break;
            case 'TimeZoneDetails':
                this.coreViewModel.handleTimeZoneDetailsResponse(data);
                break;
            case 'FXRateSnapshot':
                this.coreViewModel.handleFXRateSnapshotResponse(data);
                break;
            case 'unFinishedGame':
                this.coreViewModel.handleUnFinishedGameResponse(data);
                this.destroyPreloader();
                this.isInitialLoad = false;
                break;
            case 'LoginSuccess':
                this.coreViewModel.handleLoginSuccess(data);
                break;
            case 'errorResponse':
                this.coreViewModel.handleErrorResponse(title, msg, btn, data);
                break;
            case 'lowbalance':
                this.coreViewModel.handleLowbalance(title, msg, btn);
                break;
            case 'unFinishedGameResponse':
                this.coreViewModel.handleUnFinishedGameResult(data);
                this.isInitialLoad = false;
                break;
            case 'connectionStatus':
                this.coreViewModel.handleConnectionStatus(data);
                break;
            case 'ShowCashierMobile':
                this.showCashierMobile();
                break;
            case 'JoinTableResponse':
                this.coreViewModel.handleJoinTableResponse(data);
                this.coreViewModel.handleGameTableType(data);
                break;
            case 'GameStatus':
                this.coreViewModel.handleGameStatus(data);
                break;
            case 'PlayerStatus':
                this.coreViewModel.handlePlayerStatus(data);
                break;
            case 'SitResponse':
                this.coreViewModel.handleSitResponse(data);
                break;
            case 'AddCommunityCards':
                this.coreViewModel.handleAddCommunityCards(data);
                break;
            case 'AddPlayer':
                this.coreViewModel.handleAddPlayer(data);
                break;
            case 'CurrentPlayer':
                this.coreViewModel.handleCurrentPlayer(data);
                break;
            case 'DealCards':
                this.coreViewModel.handleDealCards(data);
                break;
            case 'Dealer':
                this.coreViewModel.handleDealer(data);
                break;
            case 'EndTheRound':
                this.coreViewModel.handleEndTheRound(data);
                break;
            case 'NewGame':
                this.coreViewModel.handleNewGame(data);
                break;
            case 'PotWinners':
                this.coreViewModel.handlePotWinners(data);
                break;
            case 'GameTableStatus':
                this.coreViewModel.handleGameTableStatus(data);
                break;
            case 'RemovePlayer':
                this.coreViewModel.setRemovedPlayersData(data);
                break;
            case 'ReserveSeat':
                this.coreViewModel.handleReserveSeat(data);
                break;
            case 'TimeOut':
                this.coreViewModel.handleTimeOut(data);
                break;
            case 'WaitStatus':
                this.coreViewModel.handleWaitStatus(data);
                break;
            case 'PlayerDealCards':
                this.coreViewModel.handlePlayerDealCards(data);
                break;
            case 'SNGJackpotInfo':
                this.coreViewModel.handleSNGJackpotInfo(data);
                this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPADATEEXPOSPINBG);
                break;
            case 'ShowHUPairTable':
                this.coreViewModel.HandleShowHUPairTable(data);
                this.showHuPairTableView();
                this.hideKothBtn();
                break;
            case 'HUPairTableSelectStatus':
                this.coreViewModel.HandleHUPairTableSelectStatus(data);
                break;
            case 'HUEvenGame':
                this.coreViewModel.HandleHUEvenGame(data);
                break;
            case 'ChangePlayerState':
                this.coreViewModel.ChangePlayerState(data);
                break;
            case 'PlayerStates':
                this.coreViewModel.playerStates(data);
                break;
            // case 'TimeOut':
            //     this.coreViewModel.TimeOut(data);
            //     break;
            case 'PlayerBalance':
                this.coreViewModel.PlayerBalance(data);
                break;
            case 'Blind':
                this.coreViewModel.Blind(data);
                break;
            case 'Ante':
                this.coreViewModel.Ante(data);
                break;
            case 'PostAnte':
                this.coreViewModel.PostAnte(data);
                break;
            case 'PlayerTimeBankBalance':
                this.coreViewModel.handlePlayerTimeBankBalance(data);
                break;
            case 'PostBlind':
                this.coreViewModel.PostBlind(data);
                break;
            case 'ShowCards':
                this.coreViewModel.ShowCards(data);
                break;
            case 'HighlightWinCards':
                this.coreViewModel.HighlightWinCards(data);
                break;
            case 'Winner':
                this.coreViewModel.Winner(data);
                break;
            case 'ShowCongrats':
                this.coreViewModel.ShowCongrats(data);
                break;
            case 'RequestEndOfTheGame':
                this.coreViewModel.RequestEndOfTheGame(data);
                break;
            case 'SNGJPPayoutAnimation':
                this.coreViewModel.HandleSNGJPPayoutAnimation(data);
                break;
            case 'SNGJPGameAboutToStart':
                this.coreViewModel.HandleSNGJPGameAboutToStart(data);
                break;
            case 'AutoOptionSet':
                this.coreViewModel.HandleAutoOptionSet(data);
                break;
            case 'AutoOption':
                this.coreViewModel.HandleAutoOption(data);
                break;
            case 'OptionSet':
                this.coreViewModel.HandleOptionSet(data);
                break;
            case 'ShowCardsOptions':
                this.coreViewModel.HandleShowCardsOptions(data);
                break;
            case 'MuckCards':
                this.coreViewModel.HandleMuckCards(data);
                break;
            case 'SelectedOption':
                this.coreViewModel.HandleSelectedOption(data);
                break;
            case 'Option':
                this.coreViewModel.HandleOption(data);
                break;
            case 'ResThrowAnimation':
                this.coreViewModel.HandleResThrowAnimation(data);
                break;
            case 'ThrowAnimation':
                this.coreViewModel.HandleThrowAnimation(data);
                break;
            case 'PoolShutdownNotification':
                this.coreViewModel.handlePoolShutdownNotification();
                break;
            case 'PoolFraudPlayerKickoutNotification':
                this.coreViewModel.handlePoolFraudPlayerKickoutNotification();
                break;
            case 'CloseTable':
                this.onCloseTable();
                break;
            case 'ATHCloseTable':
                this.athAutoSitoutCloseTable(data);
                break;
            case 'ShowEmoji':
                this.coreViewModel.handleSelfEmoji(data);
                break;
            case 'PlayerBalances':
                this.coreViewModel.handlePlayerBalances(data);
                break;
            case 'SNGJPUnRegistrationResponse':
                this.unRegistrationResponse();
                break;
            case 'SwitchActiveTable':
                this.switchActiveTable(data);
                break;
            case 'HandStrength':
                this.coreViewModel.handleHandStrength(data);
                break;
            case 'ShowRabbit':
                this.coreViewModel.handleShowRabbit(data);
                break;
            case 'DiamondBalance':
                this.coreViewModel.handleDiamondBalance(data);
                break;
            case 'BroadcastShowRabbit':
                this.coreViewModel.handleBroadcastShowRabbit(data);
                break;
            case 'ProbabilityOfWinning':
                this.coreViewModel.handleProbabilityOfWinning(data);
                break;
            case 'ChangeCardStyle':
                this.coreViewModel.changeCardStyle(data);
                break;
            case 'gameSettingData':
                this.coreViewModel.handleGameSettingData(data);
                this.updateSoundVolume();
                break;
            case 'themeChange':
                this.coreViewModel.handleThemeChange(data);
                break;
            case 'TourneyLevelInfo':
                this.coreViewModel.handleTourneyLevelInfo(data);
                break;
            case 'SNGJPTournamentRankInfo':
                this.coreViewModel.handleSNGJPTournamentRankInfo(data);
                break;
            case 'RequestKillGametableSilently':
                this.sendRequestLeaveTable();
                break;
            case 'doAppLogOut':
                this.DoAppLogOut();
                break;
            case 'disConnected':
                this.coreViewModel.handleDisconnectedState(data);
                break;
            // fastforward msg's handling from here
            case 'JoinPoolResponse':
            case 'PrevPlayingFastPokerPools':
                this.coreViewModel.handleJoinPoolResponse(data);
                this.coreViewModel.handleGameTableType(data);
                break;
            case 'PoolWaitingNotification':
                this.coreViewModel.handlePoolWaitingNotification();
                break;
            case 'PoolStartGameNotification':
                this.tableId = data.tableId;
                this.coreViewModel.handlePoolStartGameNotification(data);
                break;
            case 'PoolSitOutNotification':
                //This message is sent by server on Pool Peer to notify client that the player is sitting out on the entry
                this.coreViewModel.handlePoolSitOutNotification(data);
                break;
            case 'ReqNextHandOnFoldAndWatch':
                this.coreViewModel.handleReqNextHandOnFoldAndWatch();
                break;
            case 'RequestFoldAndWatchOn':
                // request to server to turn on Fold and Watch
                break;
            case 'RequestFoldAndWatchOff':
                // request to server to turn off Fold and Watch
                break;
            case 'PoolKickoutNotification':
                //This message is sent by server on Pool Peer to notify client that the player has been kicked out of the pool
                this.coreViewModel.handlePoolKickOutNotification(data);
                break;
            case 'PoolEndGameNotification':
                this.tableId = data.entryId;
                this.coreViewModel.handlePoolEndGameNotification(data);
                //This message is sent by server on Pool Peer to notify client that the player’s game has ended
                // on a particular entry on the pool
                break;
            case 'ClosePoolEntry':
                this.coreViewModel.handleClosePoolEntry();
                break;
            case 'ResponseLossLimitExceed':
                this.coreViewModel.handleResponseLossLimitExceed();
                break;
            case 'playerEntryStatus':
                this.coreViewModel.handlePlayerEntryStatus(data);
                break;
            case 'PoolRebuyResponse':
                this.coreViewModel.handlePoolRebuyResponse(data);
                break;
            case 'RequestAutoRebuyOff':
                this.handleRequestAutoRebuyOff(data);
                break;
            case 'RequestAutoRebuyOn':
                this.handleRequestAutoRebuyOn(data);
                break;
            case 'autoRebuyStatusChange':
                this.autoRebuyStatusChange(data);
                break;
            // cash msg's handling from here
            case 'BlockSeatResponse':
                this.coreViewModel.handleBlockSeatResponse(data);
                break;
            case 'RequestAutoPostBlindOn':
                this.sendRequestAutoPostBlindOn(data.peerId);
                break;
            case 'RequestAutoPostBlindOff':
                this.sendRequestAutoPostBlindOff(data.peerId);
                break;
            case 'ReduceChipsResponse':
                this.coreViewModel.handleReduceChipsResponse(data);
                break;
            case 'ResponseBuyMoreChipsSuccess':
                this.coreViewModel.handleResponseBuyMoreChipsSuccess();
                break;
            case 'ResponseBuyMoreChipsAllowedAmount':
                this.coreViewModel.handleResponseBuyMoreChipsAllowedAmount();
                break;
            case 'ResponseBuyMoreChipsPlaying':
                this.coreViewModel.handleResponseBuyMoreChipsPlaying(data);
                break;
            case 'RGLimitErrorMsg':
                this.coreViewModel.handleRGLimitErrorMsg(data);
                break;
            case 'SitOutWarningOff':
                //  this.coreViewModel.handleSitOutWarningOff(data);
                break;
            case 'PlayedGamesCount':
                this.handlePlayedGamesCount(data);
                break;
            case 'CanLeaveTableResponse':
                this.viewConformationPopUp(data);
                break;
            case 'CanSitOutResponse':
                this.cashGameSitOut(data);
                break;
            case 'ATHAutoSitOutMsg':
                this.showAntiTblHopAutoSitOutPopup(data);
                break;
            case 'PlayersCashoutEquityDetails':
                this.coreViewModel.handlePlayersCashoutEquityDetails(data);
                break;
            case 'PlayerCashoutEquityDetails':
                break;
            case 'ResponseCashoutEquity':
                this.coreViewModel.handleResponseCashoutEquity(data);
                break;
            case 'PlayerCashedOutEquityStatus':
                this.coreViewModel.handlePlayerCashedOutEquityStatus(data);
                break;
            case 'ResponseUserTableBalanceReduceChips':
                if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH) {
                    this.coreViewModel.handleResponseUserTableBalanceReduceChips(data);
                }
                break;
            case 'ResponseExpoAudio':
                // this.coreViewModel.handleResponseExpoAudio(data);
                this.playAudioExpoSpin(data);
                break;
            case 'SwipeExpoAnimation':
                this.onSwipeTable(data.dir);
                break;
            case 'RatHoleResponse':
                this.ratholingMessagePopUp(data);
                break;
            case 'CanLeaveTable':
                this.canLeaveTable(data);
                break;
            default:
                break;
        }
        if (this.parentFacade.isCreateTileView) {
            this.parentFacade.isCreateTileView = false;
            this.parentFacade.PrimaryAsstsLoaded(this.gameService);
        }
    };
    addViewToMap = function (view, viewModel) {
        if (view) this.viewsArray.push(view);
        if (viewModel) this.viewModelsArr.push(viewModel);
    };
    loadTableConfig = function (peerId) {
        this.gameService.setPeerId(peerId);
        uiConfig['tableConfig' + peerId] = [];
    };
    resizeTable = function () {
        this.parentFacade.resizeTableView(this.gameService.getPeerId());
        // commented below line for tableId for ff and spins from gameservice
        //this.parentFacade.resizeTableView(this.tableId);
        // this.updateSoundVolumeState();
    };
    resizeBg = function () {};
    muteSounds = function () {
        //this.soundObj.setSoundVolume(0);
        this.soundObj.isSoundPresent = false;
    };
    updateSoundVolumeState = function () {
        if (this.tableId != this.parentFacade.activeTableId) {
            this.soundObj.setSoundVolume(0);
        } else {
            this.soundObj.setSoundVolume(1);
        }
    };
    updateSoundVolume = function () {
        // if(data.sound){
        //   this.soundObj.setSoundVolume(1)
        // }else {
        //   this.soundObj.setSoundVolume(0)
        // }
        if (uiConfig['toggleSoundVal'] == true && this.tableId == this.parentFacade.activeTableId) {
            this.soundObj.setSoundVolume(1);
        } else {
            this.soundObj.setSoundVolume(0);
        }
    };
    maximiseTableView = function () {
        this.parentFacade.maximiseTableView(this.tableId);
    };
    resizeGame = function () {
        if (this.scale.y == 1 && this.tableId !== 1) {
            this.height = window.innerHeight / 2;
        } else if (this.tableId > 2) {
            this.y = this.parentFacade['table' + (this.tableId - 2)].height;
        }
    };
    addNewTable = function () {
        this.parentFacade.addNewTable();
    };
    currentTableScale = function () {
        uiConfig['tableScaleValue'] = this.scale.x;
        return this.scale.x;
    };
    currentTablePos = function () {
        uiConfig['tablePosX'] = this.position.x;
        uiConfig['tablePosY'] = this.position.y;
        const posObj = {
            posX: this.position.x,
            posY: this.position.y,
            widthCenter: this.width,
            heightCenter: this.height,
        };
        return posObj;
    };

    createDisconnectionView = function () {
        this.disconnectionView = new DisconnectionView();
        this.disconnectionViewModel = new DisconnectionViewModel(this.gameService);
        this.bgChildContainer.addChild(this.disconnectionView);
        this.disconnectionView.createView(this.eventDispatcher);
        this.disconnectionViewModel.setView(this.disconnectionView, this.eventDispatcher);
        this.addViewToMap(this.disconnectionView, this.disconnectionViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    resetViews = function () {
        for (const i in this.viewsArray) {
            this.viewsArray[i] = null;
        }
    };
    resetViewModels = function () {
        for (const i in this.viewModelsArr) {
            this.viewModelsArr[i] = null;
        }
    };
    /*This method gets called when client gets SNGJPTournamentRankInfo message,
    this method creates the winnerTableView and displays it
    */
    createWinTable = function (view) {
        this.winnTableViewModel = new WinTableViewModel(this.gameService, this.eventDispatcher, this);
        //this.bgChildContainer.addChild(view);
        this.tableView.addChild(view);
        this.winnTableViewModel.setView(view, this.eventDispatcher);
        view.createView(this.eventDispatcher, this.winnTableViewModel);
        this.addViewToMap(view, this.winnTableViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createExpoSpinsTable = function () {
        // this.expoSpinViewModel = new ExpoSpinViewModel(this.gameService, this.eventDispatcher, this);
        // this.bgChildContainer.addChild(view);
        // this.expoSpinViewModel.setView(view, this.eventDispatcher);
        // view.createView(this.eventDispatcher, this.expoSpinViewModel);
        // this.addViewToMap(view, this.expoSpinViewModel);
    };
    /* This method gets called when it is cash and two seater game */
    createKothTableView = function (view) {
        this.kothTableViewModel = new KothTableViewModel(this.gameService);
        view.createView(this.kothTableViewModel, this.eventDispatcher);
        this.tableView.addChild(view);
        this.kothTableViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.kothTableViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createGenericPopup = function (view) {
        this.tableView.addChild(view);
        this.genericPopupViewModel = new GenericPopUpViewModel(this.gameService, this.eventDispatcher);
        this.genericPopupViewModel.setView(view);
        view.createView(this.eventDispatcher);
        view.setParentViewModel(this.genericPopupViewModel);
        this.addViewToMap(view, this.genericPopupViewModel);
    };
    addGenericPopUp = function () {
        const obj = this.gameService.getAddPopUpData();
        if (!this.genericPopupViewModel) this.createGenericPopup(new GenericPopUpView());
        this.genericPopupViewModel.addPopUp(obj);
    };

    createUserProfileView = function (view) {
        this.userProfileViewModel = new UserProfileViewModel(this.gameService, this.parentFacade);
        this.tableView.addChild(view);
        this.userProfileViewModel.setView(view, this.eventDispatcher);
        view.createView(this.eventDispatcher, this.userProfileViewModel, this.gameService.getPeerId());
        this.addViewToMap(view, this.userProfileViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createLoyaltyCashBackView = function (view) {
        let showLoyaltyCashBack = false;
        if (this.scale.x == 1) {
            showLoyaltyCashBack = true;
        } else {
            showLoyaltyCashBack = false;
        }
        let loyaltyCashbackObj;
        CommonGameProperties.facade['store'].select(selectLoyaltyCashBack).subscribe((data) => {
            loyaltyCashbackObj = data;
        });
        this.loyaltyCashBackViewModel = new LoyaltyCashBackViewModel(this.gameService);
        this.tableView.addChild(view);
        this.loyaltyCashBackViewModel.setView(view, this.eventDispatcher);
        view.createView(this.eventDispatcher, this.loyaltyCashBackViewModel, loyaltyCashbackObj, showLoyaltyCashBack);
        this.addViewToMap(view, this.loyaltyCashBackViewModel);
    };
    loyaltyCashBackResetObj = function () {
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.RESET_LCB_DATA);
    };
    updateLCBHandEnd = function (message) {
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_LCB_HANDEND, message);
    };
    onResizeForDesktop = function () {};
    reset = function () {};
    onResize = function () {};
    createSpinMultilpierComponent = function () {
        // this.spinMultiplierViewModel = new SpinMultiplierViewModel(this.gameService);
        // view.createView(this.eventDispatcher);
        // this.spinMultiplierViewModel.setView(view, this.eventDispatcher);
        // this.bgChildContainer.addChild(view);
        // this.addViewToMap(view, this.spinMultiplierViewModel);
        // setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createRegistrationView = function (view) {
        this.registrationViewModel = new RegistrationViewModel(this.gameService);
        view.createView(this.eventDispatcher, this.registrationViewModel);
        this.registrationViewView = view;
        this.tableView.addChild(view);
        this.registrationViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.registrationViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createWindowVisibility = function () {
        // this.windowVisibility = new WindowVisibility(this.gameService);
        // this.windowVisibility.createView(this.eventDispatcher);
        // this.addViewToMap(this.windowVisibility, null);
        // setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createInfoTable = function (view) {
        view.createView(this.eventDispatcher);
        this.infoTableView = view;
        this.bgChildContainer.addChild(view);
        this.infoTableViewModel = new InfoTableViewModel(this.gameService);
        this.infoTableViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.infoTableViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createSecondaryViews = function () {
        this.createSelfEmoji(true);
        this.createThrowAnimationView(true);
    };
    createSelfEmoji = function (onSecondaryLoaded) {
        if (CommonGameProperties.secondaryAssetsLoaded && !this.selfEmojiView) {
            const view = new SelfEmojiView();
            this.selfEmojiViewModel = new SelfEmojiViewModel(this.gameService);
            view.createView(this.eventDispatcher, this.selfEmojiViewModel, this.gameService.getMaxNoOfSeats());
            this.selfEmojiView = view;
            this.tableView.addChild(view);
            this.selfEmojiViewModel.setView(view, this.eventDispatcher);
            this.addViewToMap(view, this.selfEmojiViewModel);
        }
        if (!onSecondaryLoaded) setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    checkToHandleGameInit = function () {
        if (this.gameInitReceived && this.isViewCreated) {
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_GAME_INIT);
        }
    };
    onGameViewCreated = function () {
        /*-------------Swipe Up Functiionality---------------*/
        // if (uiConfig.hasSwipe) {
        //   window.dispatchEvent(new Event(SlotCoreGameEvent.CHECK_FOR_ADDRESS_BAR));
        // }
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.GAME_VIEW_CREATED);
        this.gameService.setIsTableCreated(true);
        this.isViewCreated = true;
        //MathUtil.initializeFormatter();
        this.checkToHandleGameInit();
        this.processMessageQueue();
        this.onResize();
        this.isInitialLoad = true;
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH && this.gameService.getMaxNoOfSeats() == 2) {
            this.showKothOnboardingPopup();
        }
        this.bgView.interactive = true;
        if (CommonGameProperties.secondaryAssetsLoaded === true) {
            this.createSecondaryViews();
        }
    };
    createThrowAnimationView = function (onSecondaryLoaded) {
        if (CommonGameProperties.secondaryAssetsLoaded && !this.ThrowAnimationViewModel) {
            const view = new ThrowAnimationView(this.gameService, this.parentFacade.tableSettingsDataModel);
            view.createView(this.eventDispatcher, this.gameService, this.gameService.getMaxNoOfSeats());
            this.tableView.addChild(view);
            this.ThrowAnimationViewModel = new ThrowAnimationViewModel(this.gameService);
            this.ThrowAnimationViewModel.setView(view, this.eventDispatcher, this.parentFacade.tableSettingsDataModel);
            this.addViewToMap(view, this.ThrowAnimationViewModel);
        }
        if (!onSecondaryLoaded) setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    createCashoutEquityParentView = function (view) {
        this.CashoutEquityParentViewModel = new CashoutEquityParentViewModel(this.gameService);
        this.tableView.addChild(view);
        this.CashoutEquityParentView = view;
        this.CashoutEquityParentView.createView(this.CashoutEquityParentViewModel);
        this.CashoutEquityParentViewModel.setView(view, this.eventDispatcher);
        this.addViewToMap(view, this.CashoutEquityParentViewModel);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    showGameErrorMessage = function (title, message, btn) {
        const genericErrorPopUp: any = uiConfig.genericPopUpConfig;
        genericErrorPopUp.titleText = title; // CommonGameProperties.langPack["getLiteral"]("PARTY_POKER_COMMON_ERROR");
        genericErrorPopUp.bodyText = message;
        genericErrorPopUp.showCloseButton = false;
        genericErrorPopUp.showCheckBox = false;
        genericErrorPopUp.buttonsTextArr = [btn];
        genericErrorPopUp.buttonsActionArr = [''];
        genericErrorPopUp.isAntiTblHop = false;
        genericErrorPopUp.popUpId = 'GENERIC_MESSAGE'; //PARTY_POKER_GC_LEAVETABLE
        this.gameService.setAddPopUpData(genericErrorPopUp);
        this.addGenericPopUp();
    };
    showAntiTblHopLeavePopup = function (data) {
        //data = JSON.parse('{"errorMessage":{"msg":null,"templateId":209962,"params":[120000,13],"isLPElement":true},"msgNumber":0,"responseCode":0,"athBlockTime":0,"className":"CanSitOutResponse","peerId":12587754}');
        if (data == undefined) {
            return;
        }
        const antiTblHopPopUp: any = uiConfig.genericPopUpConfig;
        antiTblHopPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_ANMISSING_commonwarning');
        let multiLangPos;
        if (data.athBlockTime == 0) {
            if (this.checkIsPlayerActive() == true) {
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLE_ACTIVEHAND_LEAVECONFIRMATION',
                );
            } else {
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLE_INACTIVEHAND_LEAVECONFIRMATION',
                );
            }
            multiLangPos = this.multiLangPos(1);
            antiTblHopPopUp.antiWarningMSG = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLE_ACTIVEHAND_WARNINGINFO',
            )
                .replace('(Y)', data.errorMessage.params[0])
                .replace('(X)', data.errorMessage.params[1]);
            antiTblHopPopUp.showWarning = true;
            antiTblHopPopUp.type = 1;
            antiTblHopPopUp.showCloseButton = true;
            antiTblHopPopUp.showCheckBox = false;
            antiTblHopPopUp.isAntiTblHop = true;
            antiTblHopPopUp.enableDarkBG = false;
            antiTblHopPopUp.learnMoreYAdj = multiLangPos.learnMoreYAdj.yAdj;
            antiTblHopPopUp.buttonsTextArr = [
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLETXT'),
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_KEEPPLAYINGTXT'),
            ];
            antiTblHopPopUp.buttonsActionArr = [SlotCoreGameEvent.CONCLUDED_LEAVE, ''];
            antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_LEAVETABLE';
        } else {
            let showWarning;
            const multiLangPos = this.multiLangPos(2);
            const timeObj = this.timeCalculate(parseInt(data.errorMessage.params[0]));
            if (this.checkIsPlayerActive() == true) {
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLE_ACTIVEHAND_LEAVECONFIRMATION',
                );
                showWarning = false;
            } else {
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLE_INACTIVEHAND_LEAVECONFIRMATION',
                );
                showWarning = true;
            }
            const warningTxt = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLE_ACTIVEHAND_PENALTYINFO',
            )
                .replace('$', timeObj.time)
                .replace('(X)', data.errorMessage.params[1]);
            antiTblHopPopUp.antiWarningMSG = warningTxt.split('\\n')[0] + '.\n\n' + warningTxt.split('\\n')[1];
            antiTblHopPopUp.showXYBold = true;
            antiTblHopPopUp.showWarning = showWarning;
            antiTblHopPopUp.type = 2;
            antiTblHopPopUp.showCloseButton = true;
            antiTblHopPopUp.showCheckBox = false;
            antiTblHopPopUp.isAntiTblHop = true;
            antiTblHopPopUp.enableDarkBG = false;
            antiTblHopPopUp.learnMoreYAdj = multiLangPos.learnMoreYAdj.yAdj;
            antiTblHopPopUp.buttonsTextArr = [
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_LEAVETABLETXT'),
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_KEEPPLAYINGTXT'),
            ];
            antiTblHopPopUp.buttonsActionArr = [SlotCoreGameEvent.CONCLUDED_LEAVE, ''];
            antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_LEAVETABLE';
        }
        this.gameService.setAddPopUpData(antiTblHopPopUp);
        this.addGenericPopUp();
    };
    adjustSpace = function (timeObj) {
        let returnVal = 0;
        for (let i = 0; i < timeObj.time.match(/\d+/g).length; i++) {
            const time = parseInt(timeObj.time.match(/\d+/g)[i]);
            if (time <= 1) {
                returnVal += i;
            } else if (time < 9) {
                returnVal += 2;
            } else if (time > 9) {
                returnVal += 4;
            }
        }
        return returnVal;
    };
    multiLangPos = function (condentId) {
        let multiLangPos;
        let returnVal;
        switch (condentId) {
            case 1:
                //For Leave table popup warning
                multiLangPos = uiConfig.athMultiStyleConfig.leaveTableWarning;
                break;
            case 2:
                //For Leave table popup penalty for minute
                multiLangPos = uiConfig.athMultiStyleConfig.leaveTablePenalty;
                break;
            case 3:
                //For Manual Sitout popup warning
                multiLangPos = uiConfig.athMultiStyleConfig.manualSitoutWarning;
                break;
            case 4:
                //For Manual Sitout popup penalty
                multiLangPos = uiConfig.athMultiStyleConfig.manualSitoutPenalty;
                break;
            case 5:
                //For Auto Sitout popup penalty
                multiLangPos = uiConfig.athMultiStyleConfig.autoSitoutPenalty;
                break;
        }
        multiLangPos.forEach(
            function (item) {
                //if (item.lang == window.lang) {
                if (item.lang == 'en') {
                    returnVal = item;
                }
            }.bind(this),
        );
        return returnVal;
    };
    showKothOnboardingPopup = function () {
        const kothPopUpConfig: any = uiConfig.genericPopUpConfig;
        const isChecked = localStorage.getItem('isChecked');
        kothPopUpConfig.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_KOTH_TITLEBAR_TEXT_NEW');
        kothPopUpConfig.titleText.anchor(0.5);
        kothPopUpConfig.showCloseButton = false;
        kothPopUpConfig.showCheckBox = true;
        kothPopUpConfig.isAntiTblHop = false;
        kothPopUpConfig.bodyText =
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_TABLE_COMMON_KOTH_ONBOARDING_MSG') + '\n\n' + '      ';
        kothPopUpConfig.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_COMMON_OK')];
        kothPopUpConfig.buttonsActionArr = [''];
        kothPopUpConfig.popUpId = 'PARTY_POKER_MC_COMMON_OK';
        this.gameService.setAddPopUpData(kothPopUpConfig);
        if (isChecked != 'true') this.addGenericPopUp();
        // this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ADD_GENERIC_POPUP , false);
    };
    createMobileTopPanel = function () {
        /*if (!uiConfig.isDesktop || uiConfig.isTablet) {
            this.mobileTopPanel = new MobileTopPanel();
            this.panelContainer.addChild(this.mobileTopPanel);
            this.mobileTopPanel.createView();
        }*/
        //this.addViewToMap(this.mobileTopPanel, null);
        setTimeout(this.createNextView.bind(this), this.viewCreationDelay);
    };
    handleGameAvailability = function (obj) {
        this.coreViewModel.handleGameAvailability(obj);
    };
    processMessageQueue = function () {
        while (this.messageQueue.length > 0) {
            const obj = this.messageQueue.shift();
            this.handleGameServerResponse(obj.message, obj.data, obj.title, obj.msg, obj.btn);
        }
    };
    handlePlayedGamesCount = function (data) {
        if (
            this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
            this.gameService.getMaxNoOfSeats() > 2 &&
            this.isATHEnabled == true &&
            this.gameService.gameStateModel.tableStatus.athApplicable == true
        ) {
            this.coreViewModel.handlePlayedGamesCount(data);
        }
    };
    handleRequestAutoRebuyOn = function (obj) {
        let peerId = this.parentFacade.tableToEntryMap[obj.peerId]; // getting entryId for autorebuy for FF games
        if (!peerId) {
            // if there is no entryid like for cash games
            peerId = obj.peerId;
        }
        // window.parent.AppController.SendMessageToSwitcher({
        //   eventName: "AddNewGameTable",
        //   TableId: peerId,
        //   reBuy: true,
        // });
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_REBUY_STATUS, { status: true });
    };
    autoRebuyStatusChange = function (obj) {
        // let peerId = obj.rebuyStatus.entryId; // getting entryId for autorebuy
        // window.parent.AppController.SendMessageToSwitcher({
        //   eventName: "AddNewGameTable",
        //   TableId: peerId,
        //   reBuy: obj.rebuyStatus.autoRebuyStatus,
        // });
        this.coreViewModel.setAutoRebuyStatus(obj.rebuyStatus.autoRebuyStatus);
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_REBUY_STATUS, { status: obj.rebuyStatus.autoRebuyStatus });
    };
    handleRequestAutoRebuyOff = function (obj) {
        let peerId = this.parentFacade.tableToEntryMap[obj.peerId]; // getting entryId for autorebuy
        if (!peerId) {
            // if there is no entryid like for cash games
            peerId = obj.peerId;
        }
        // window.parent.AppController.SendMessageToSwitcher({
        //   eventName: "AddNewGameTable",
        //   TableId: peerId,
        //   reBuy: false,
        // });
        const message = {};
        message['className'] = 'AddNewGameTable';
        message['tableId'] = peerId;
        message['reBuy'] = false;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.UPDATE_REBUY_STATUS, { status: false });
    };
    showHuPairTableView = function () {
        if (this.gameService.getShowHuPairTableStatus()) {
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.SHOW_HU_PAIR_TABLE);
        }
    };
    unRegistrationResponse = function () {
        this.closeTable();
    };
    switchActiveTable = function (data) {
        this.triggerMiniSwitcherSelect();
        this.parentFacade.switchActiveTable(data.peerId);
    };
    triggerMiniSwitcherSelect = function () {
        this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.MINI_SWITCHER_CLICKED);
    };

    canLeaveTable = function (data) {
        if (
            this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
            this.gameService.getMaxNoOfSeats() > 2 &&
            this.isATHEnabled == true &&
            this.gameService.gameStateModel.tableStatus.athApplicable == true
        ) {
            //ATH feature only for Cash games and Should not be two seater game
            this.parentFacade.sendRequestCanLeaveTable(this.tableId);
        } else {
            //Spins, FF and two seater Cash games
            data = { responseCode: 0 };
            this.viewConformationPopUp(data);
        }
    };
    viewConformationPopUp = function (data) {
        if (data.responseCode == 0) {
            const leaveTablePopUp: any = uiConfig.genericPopUpConfig;
            leaveTablePopUp.showCheckBox = false;
            leaveTablePopUp.isAntiTblHop = false;
            leaveTablePopUp.isRathol = false;
            leaveTablePopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_LEAVETABLE');
            if (
                this.gameService.getTableCategory() != serverIdConstants.TABLE_CATEGORY_TOURNAMENT &&
                this.gameService.getHUEvenGame() == false &&
                this.gameService.getGameTableType() != (serverIdConstants.TABLE_TYPE_FF || serverIdConstants.TABLE_TYPE_SPINS)
            ) {
                leaveTablePopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_LEAVETABLE');
                leaveTablePopUp.bodyText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_KOTH_LEAVEMSGNEW');
                //leaveTablePopUp.bodyText = 'If you leave now, your hand will be folded.\\n\\nIf the number of hands are not equal, your next blind will be posted and you will automatically fold. Your remaining table balance will be credited to your account. \\n\\nDo you really want to leave the table?';
                leaveTablePopUp.bodyText = leaveTablePopUp.bodyText.split('\\n').join('\n');
            } else if (this.gameService.getTableCategory() != serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
                leaveTablePopUp.bodyText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_GAMESLOBBY_TOURNAMENTINFO_LEAVETABLEMSG');
            } else {
                leaveTablePopUp.bodyText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_TABLE_COMMON_SNGLEAVETABLEMSG');
            }
            //"Are you sure you wanted to leave table ? Your Buy-in will not be refunded and blinds will continue to be posted.";
            leaveTablePopUp.buttonsTextArr = [
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MOBILE_COMMON_YES').toUpperCase(),
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_COMMON_NO'),
            ];
            leaveTablePopUp.buttonsActionArr = [SlotCoreGameEvent.CONCLUDED_LEAVE, ''];
            leaveTablePopUp.popUpId = 'PARTY_POKER_GC_LEAVETABLE'; //PARTY_POKER_GC_LEAVETABLE
            this.gameService.setAddPopUpData(leaveTablePopUp);
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.ADD_GENERIC_POPUP);
        } else {
            this.showAntiTblHopLeavePopup(data);
        }
    };
    removeGameTable = function () {
        this.coreViewModel.handleKothBtns();
        this.sendRequestLeaveTable();
    };
    sendOptionSetAck = function () {
        this.parentFacade.sendOptionSetAck(this.gameService.getOptionSetId(), 0, this.tableId);
    };
    sendSelectOption = function () {
        const optionSetData = this.gameService.getOptionSetData();
        const displayedOptionsForSecs = optionSetData ? this.gameTableNotificationService.getTimeForPeer(this.peerId) - optionSetData.generatedAt : 0;
        this.parentFacade.sendSelectedOption(
            this.gameService.getSelectedBetOption(),
            this.gameService.getOptionSetId(),
            displayedOptionsForSecs,
            0,
            0,
            this.tableId,
        );
        this.gameService.setOptionSetData(null);
    };
    sendCashoutOption = function (isSelected) {
        const tableId = this.gameService.gameInfoModel.getPlayersCashoutEquityDetails().tableId;
        const gameId = this.gameService.gameInfoModel.getPlayersCashoutEquityDetails().gameId;
        this.parentFacade.sendCashoutOption(tableId, gameId, isSelected.detail.isSelected);
    };
    showForceAwayState = function () {
        this.coreViewModel.updatePlayerState(serverIdConstants.PLAYERSTATE_SIT_OUT);
    };
    sendIamBackBtnClick = function () {
        //if balance is zero, open rebuy window
        this.coreViewModel.checkLowOnBalanceForRebuy(false);
        // return;
        //}
        this.coreViewModel.updatePlayerState(serverIdConstants.PLAYERSTATE_SIT_IN);
        const gameTableType = this.gameService.getGameTableType();
        //tableId and entry Id remains same when there's no gameplay
        if (gameTableType == serverIdConstants.TABLE_TYPE_FF && this.gameService.getPeerId() == this.tableId) {
            //send it on entryId, peerId in gameservice is always entryId for ff
            this.parentFacade.sendBackToPoolRequest(this.gameService.getPeerId(), this.tableId);
        } else if (this.gameService.getAutoCheckEnabled()) {
            this.parentFacade.sendRequestAutoCheck(false, this.tableId);
        } else if (this.gameService.getTableStatusData().tableCategory == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.parentFacade.sendRequestAutopostBlindAndFoldOff(this.tableId);
        } else {
            this.parentFacade.sendRequestSitoutNextHandOff(this.tableId);
        }
        this.gameService.setAutoCheckEnabled(false);
    };
    sendSelectedEmoji = function (emojiId) {
        this.parentFacade.sendSelectedEmoji(emojiId.detail, this.tableId);
    };
    sendUnRegisterReq = function () {
        const participantNo = parseInt(this.gameService.getServerSideSeatPos(uiConfig.selfUserClientPosInd)) + 1;
        this.parentFacade.sendUnRegisterReq(this.tableId, this.gameService.getSngJackpotID(), this.gameService.getMtctId(), participantNo);
        // need to close after checking unregisterresponse
        this.closeTable();
    };
    sendRequestLeaveTable = function () {
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            this.parentFacade.sendLeavePoolRequest(this.gameService.getPeerId()); //send entryId on the pool
        } else {
            if (this.gameService.gTableType == serverIdConstants.TABLE_TYPE_SPINS) {
                this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.REMOVE_EXPOSPIN_VID);
            }
            this.parentFacade.sendRequestLeaveTable(this.gameService.getTableStatusData().tableCategory, this.tableId);
        }
        this.closeTable();
    };
    winGameCloseReq = function () {
        this.parentFacade.removeReplayers(); // removing replayers for close table message
        this.parentFacade.sendRequestLeaveTable(this.gameService.getTableStatusData().tableCategory, this.tableId);
        this.closeTable();
    };
    DoAppLogOut = function () {
        this.parentFacade.sendRequestDoAppLogOut();
        this.closeTable();
    };
    onSitOut = function () {
        if (this.gameService.getTableCategory() == serverIdConstants.TABLE_CATEGORY_TOURNAMENT) {
            this.parentFacade.sendRequestAutoPostBlindAndFoldOn(this.tableId);
        } else {
            //if no gameplay is happening, peerId will be entryId for FF
            if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF && this.gameService.getPeerId() == this.tableId) {
                this.parentFacade.sendRequestPoolSitout(this.tableId);
            } else {
                if (
                    this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
                    this.gameService.getMaxNoOfSeats() > 2 &&
                    this.isATHEnabled == true &&
                    this.gameService.gameStateModel.tableStatus.athApplicable == true
                ) {
                    //ATH feature only for Cash games and Should not be two seater game
                    this.parentFacade.sendRequestCanSitOut(this.tableId, 0);
                } else {
                    //Spins, FF and two seater Cash games
                    this.parentFacade.sendSitOutNexthandOn(this.tableId);
                }
            }
        }
    };
    cashGameSitOut = function (data) {
        const responseCode = data.responseCode;
        if (responseCode == 0) {
            this.parentFacade.sendSitOutNexthandOn(this.tableId);
        } else {
            this.showAntiTblHopSitOutPopup(data);
        }
    };
    showAntiTblHopSitOutPopup = function (data) {
        //data = JSON.parse('{"errorMessage":{"msg":null,"templateId":209962,"params":[86400,13],"isLPElement":true},"msgNumber":0,"responseCode":0,"athBlockTime":1,"className":"CanSitOutResponse","peerId":12587754}');
        if (data == undefined) {
            return;
        }
        if (data.athBlockTime == 0) {
            const antiTblHopPopUp: any = uiConfig.genericPopUpConfig;
            antiTblHopPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_ANMISSING_commonwarning');
            antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_MANUALSITOUT_SITOUTCONFIRMATION',
            );
            const multiLangPos = this.multiLangPos(3);
            antiTblHopPopUp.antiWarningMSG = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_MANUALSITOUT_SITOUTWARNINGINFO',
            )
                .replace('(Z)', data.errorMessage.params[0])
                .replace('(X)', data.errorMessage.params[1]);
            antiTblHopPopUp.showXYBold = true;
            antiTblHopPopUp.showWarning = true;
            antiTblHopPopUp.type = 3;
            antiTblHopPopUp.showCloseButton = true;
            antiTblHopPopUp.showCheckBox = false;
            antiTblHopPopUp.isAntiTblHop = true;
            antiTblHopPopUp.enableDarkBG = false;
            antiTblHopPopUp.learnMoreYAdj = multiLangPos.learnMoreYAdj.yAdj;
            antiTblHopPopUp.buttonsTextArr = [
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_SITOUTTXT'),
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_KEEPPLAYINGTXT'),
            ];
            antiTblHopPopUp.buttonsActionArr = [SlotCoreGameEvent.SHOW_ANTITBL_HOP_SITOUT, SlotCoreGameEvent.IAM_BACK_CLICKED];
            antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_SITOUTTABLE';
            this.gameService.setAddPopUpData(antiTblHopPopUp);
            this.addGenericPopUp();
        } else {
            const antiTblHopPopUp: any = uiConfig.genericPopUpConfig;
            antiTblHopPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_ANMISSING_commonwarning');
            antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_MANUALSITOUT_SITOUTCONFIRMATION',
            );
            const timeObj = this.timeCalculate(parseInt(data.errorMessage.params[0]));
            const multiLangPos = this.multiLangPos(4);
            const bodyText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_MANUALSITOUT_SITOUTPENALTYINFO')
                .replace('$', timeObj.time)
                .replace('(Y)', data.errorMessage.params[1]);
            antiTblHopPopUp.antiWarningMSG = bodyText.split('\\n')[0] + '.\n\n' + bodyText.split('\\n')[1] + '.';
            antiTblHopPopUp.showXYBold = true;
            antiTblHopPopUp.learnMoreYAdj = multiLangPos.learnMoreYAdj.yAdj;
            antiTblHopPopUp.type = 4;
            antiTblHopPopUp.showCloseButton = true;
            antiTblHopPopUp.showCheckBox = false;
            antiTblHopPopUp.isAntiTblHop = true;
            antiTblHopPopUp.enableDarkBG = false;
            antiTblHopPopUp.showWarning = true;
            antiTblHopPopUp.buttonsTextArr = [
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_SITOUTTXT'),
                CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_KEEPPLAYINGTXT'),
            ];
            antiTblHopPopUp.buttonsActionArr = [SlotCoreGameEvent.SHOW_ANTITBL_HOP_SITOUT, SlotCoreGameEvent.IAM_BACK_CLICKED];
            antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_SITOUTTABLE';
            this.gameService.setAddPopUpData(antiTblHopPopUp);
            this.addGenericPopUp();
        }
    };
    showAntiTblHopSitOut = function () {
        this.isSitOut = true;
        this.parentFacade.sendSitOutNexthandOn(this.tableId);
    };
    timeCalculate = function (runningTime) {
        const seconds = Math.floor(runningTime / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        const remainingMinutes = minutes % 60;
        const remainingSeconds = seconds % 60;
        const result = [] as any;
        if (days > 0) {
            result.push(
                `${days} ${days > 1 ? CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_DAYS') : CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_DAY')}`,
            );
        }
        if (remainingHours > 0) {
            result.push(
                `${remainingHours} ${remainingHours > 1 ? CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_HOURS') : CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_HOUR')}`,
            );
        }
        if (remainingMinutes > 0) {
            result.push(
                `${remainingMinutes} ${remainingMinutes > 1 ? CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_MINUTES') : CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_MINUTE')}`,
            );
        }
        if (remainingSeconds > 0) {
            result.push(
                `${remainingSeconds} ${remainingSeconds > 1 ? CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_SECONDS') : CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_TIMEUNITS_SECONDS')}`,
            );
        }
        if (result.length === 0) {
            return '0 seconds';
        }
        if (result.length > 1) {
            const len = result.length;
            const lastTwoUnits = result.splice(len - 2, 2).join(' and ');
            result.push(lastTwoUnits);
        }
        return { time: result.join(', ') };
    };
    showAntiTblHopAutoSitOutPopup = function (data) {
        //data = JSON.parse('{"errorMessage":{"msg":null,"templateId":209962,"params":[2,12],"isLPElement":true},"msgNumber":0,"responseCode":1,"athBlockTime":0,"className":"CanSitOutResponse","peerId":12588274}');
        if (this.isATHEnabled == true && this.gameService.gameStateModel.tableStatus.athApplicable == true) {
            const antiTblHopPopUp: any = uiConfig.genericPopUpConfig;
            antiTblHopPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_ANMISSING_commonwarning');
            if (data.athBlockTime == 0) {
                antiTblHopPopUp.showXYBold = false;
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_MULTITABLECONFIRMATION',
                );
                antiTblHopPopUp.antiWarningMSG = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_MULTITBLWARNINGINFO',
                );
                antiTblHopPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_COMMON_OK')];
                antiTblHopPopUp.buttonsActionArr = ['', ''];
                antiTblHopPopUp.showCloseButton = true;
                antiTblHopPopUp.showCheckBox = false;
                antiTblHopPopUp.isAntiTblHop = true;
                antiTblHopPopUp.learnMoreYAdj = 25;
                antiTblHopPopUp.showWarning = true;
                antiTblHopPopUp.enableDarkBG = false;
                antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_AUTO_SITOUTTABLE';
                this.gameService.setAddPopUpData(antiTblHopPopUp);
                this.addGenericPopUp();
            }
        }
    };
    athAutoSitoutCloseTable = function (data) {
        //data = JSON.parse('{"delayInSecs":60,"message":{"msg":null,"templateId":12628,"params":[],"isLPElement":true},"tableId":12588274,"athBlockTime":60000,"athStrikePresent":true,"msgNumber":0,"className":"ATHCloseTable","peerId":12588274}');
        //data = JSON.parse('{"delayInSecs":60,"message":{"msg":null,"templateId":12628,"params":[],"isLPElement":true},"tableId":12588274,"athBlockTime":0,"athStrikePresent":true,"msgNumber":0,"className":"ATHCloseTable","peerId":12588274}');
        this.onCloseTableObj = data;
        this.onCloseTableObj.delayInSecs = 0;
        this.onCloseTableObj.message.templateId = 0;
        if (this.isATHEnabled == true && this.gameService.gameStateModel.tableStatus.athApplicable == true) {
            if (data.athBlockTime == 0 && data.athStrikePresent == true) {
                const antiTblHopPopUp: any = uiConfig.genericPopUpConfig;
                antiTblHopPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_ANMISSING_commonwarning');
                antiTblHopPopUp.showXYBold = false;
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_WARNINGINFO',
                );
                antiTblHopPopUp.antiWarningMSG = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_WARNINGMSG',
                );
                antiTblHopPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_COMMON_OK')];
                antiTblHopPopUp.buttonsActionArr = [SlotCoreGameEvent.ATH_CLOSE_TABLE, SlotCoreGameEvent.ATH_CLOSE_TABLE];
                antiTblHopPopUp.showCloseButton = true;
                antiTblHopPopUp.showCheckBox = false;
                antiTblHopPopUp.isAntiTblHop = true;
                antiTblHopPopUp.learnMoreYAdj = 25;
                antiTblHopPopUp.showWarning = true;
                antiTblHopPopUp.enableDarkBG = true;
                antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_AUTO_SITOUTTABLE';
                this.gameService.setAddPopUpData(antiTblHopPopUp);
                this.addGenericPopUp();
            } else if (data.athBlockTime > 0) {
                const antiTblHopPopUp: any = uiConfig.genericPopUpConfig;
                antiTblHopPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_AN_GEN_ANMISSING_commonwarning');
                antiTblHopPopUp.showXYBold = true;
                antiTblHopPopUp.bodyText = CommonGameProperties.langPack['getLiteral'](
                    'PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_WARNINGINFO',
                );
                antiTblHopPopUp.antiWarningMSG =
                    CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_PENALTYINFOLINE1').replace(
                        '$',
                        this.timeCalculate(data.athBlockTime).time,
                    ) +
                    '\n\n' +
                    CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_ANTITABELHOPPING_AUTOSITOUT_PENALTYINFOLINE2');
                antiTblHopPopUp.xMorehandsTxt = '';
                antiTblHopPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_MC_COMMON_OK')];
                antiTblHopPopUp.buttonsActionArr = [SlotCoreGameEvent.ATH_CLOSE_TABLE, SlotCoreGameEvent.ATH_CLOSE_TABLE];
                antiTblHopPopUp.showCloseButton = true;
                antiTblHopPopUp.showCheckBox = false;
                antiTblHopPopUp.isAntiTblHop = true;
                antiTblHopPopUp.learnMoreYAdj = 25;
                antiTblHopPopUp.showWarning = true;
                antiTblHopPopUp.enableDarkBG = true;
                antiTblHopPopUp.popUpId = 'PARTY_POKER_GC_AUTO_SITOUTTABLE';
                this.gameService.setAddPopUpData(antiTblHopPopUp);
                this.addGenericPopUp();
            }
        } else {
            this.onCloseTable();
        }
    };
    onCloseTable = function () {
        this.toHomeLobby = true;
        this.parentFacade.removeReplayers(); // removing replayers for close table message
        this.coreViewModel.handleCloseTable(this.onCloseTableObj);
    };
    checkIsPlayerActive = function () {
        let status = true;
        if (this.parentFacade['table' + this.tableId].playerView.playersArray[0].activePlaterStatus != true) {
            status = false;
        }
        if (this.parentFacade['table' + this.tableId].controlPanelView.iamBackContainer.visible == true) {
            status = false;
        }
        if (this.parentFacade['table' + this.tableId].playerView.playersArray[0].alpha != 1) {
            status = false;
        }
        return status;
    };
    sitinAllTbl = function () {
        this.parentFacade.sitinAllTbl();
    };
    sendRequestAutoPostBlindAndFoldOn = function () {
        this.parentFacade.sendRequestAutoPostBlindAndFoldOn(this.tableId);
    };
    sendAutoSelectOption = function () {
        this.parentFacade.sendAutoSelectedOption(this.gameService.getAutoSelectedBetOption(), this.tableId);
    };
    sendHuPairTableStatus = function () {
        this.parentFacade.sendRequestHUPairTable(this.gameService.getHuPairTableStatusVal(), this.tableId);
    };
    sendRequestAutoCheckOn = function () {
        this.parentFacade.sendRequestAutoCheckOn(this.tableId);
    };
    sendReqThrowAnimation = function () {
        const obj = this.gameService.getReqThrowAnimationData();
        this.parentFacade.sendReqThrowAnimation(obj.animationId, obj.toPlayerSeatNo, this.tableId);
        this.gameService.resetReqThrowAnimationData();
    };
    sendRequestMuckLosingHand = function (autoMuck) {
        this.parentFacade.sendRequestMuckLosingHand(autoMuck ? serverIdConstants.DEFAULT_ON : serverIdConstants.DEFAULT_OFF, this.tableId);
    };
    // RequestAutoPostBlindOn for cash games
    sendRequestAutoPostBlindOn = function (peerId) {
        this.parentFacade.sendRequestAutoPostBlindOn(peerId);
    };
    // RequestAutoPostBlindOff for cash games
    sendRequestAutoPostBlindOff = function (peerId) {
        this.parentFacade.sendRequestAutoPostBlindOff(peerId);
    };
    // Request for cash games auto rebuy enable disable, here entryid is peerid for cash
    sendRequestRebuyOnOff = function (rebuyStatus, peerId) {
        const msg: any = {};
        msg.rebuyStatus = {
            autoRebuyStatus: rebuyStatus,
            entryId: peerId,
        };
        this.parentFacade.sendRequestAutoRebuyChange(msg);
    };
    sendRabbitReq = function () {
        this.parentFacade.sendRabbitReq(this.tableId, this.gameService.getCurrentGameId());
    };
    optionTimerStarted = function (data) {
        this.coreViewModel.selfPlayerTimerStarted(data.detail);
    };
    optionTimerRemoved = function () {
        this.coreViewModel.selfPlayerTimerEnded();
    };
    closeTable = function () {
        if (this.eventDispatcher) {
            this.eventDispatcher.dispatchEvent(SlotCoreGameEvent.HANDLE_FULL_GAME_CLOSE);
        }
        //TweeTweenMax.killAll();
        if (this.tableId) {
            uiConfig['tableAvailableStatus' + this.tableId] = false;
            const message = {};
            message['className'] = 'RemoveMiniTable';
            message['tableId'] = this.gameService.getPeerId();
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        }
        this.tableOpenStatus = false;
        if (this.tableId && this.parentFacade) {
            this.parentFacade.updateTablePlay(this.gameService.getPeerId(), this.gameService.getGameTableType());
        }
        uiConfig['tableConfig' + this.tableId] = null;
        const totalChildren = this.children.length;
        for (let i = 0; i < totalChildren; i++) {
            const currentChild = this.children.pop();
            currentChild.destroy({
                children: true,
            });
        }
        this.resetViewModels();
        this.resetViews();
        this.isViewCreated = false;
        this.isInitialLoad = false;
        this.pageContainer = null;
        this.mainGameContainer = null;
        this.popupContainer = null;
        this.panelContainer = null;
        this.bonusGameContainer = null;
        this.bgContainer = null;
        this.compIndex = null;
        this.userProfileViewModel = null;
        this.coreViewModel = null;
        this.eventDispatcher = null;
        this.modalPopupViewModel = null;
        this.pageBG = null;
        this.bgViewModel = null;
        this.tableView = null;
        this.CommunityCardsViewModel = null;
        this.CashoutEquityParentViewModel = null;
        this.CashoutEquityParentView = null;
        this.spinMultiplierViewModel = null;
        this.registrationViewModel = null;
        this.registrationViewView = null;
        /*this.celebrationView = null;
        this.celebrationViewModel = null;*/
        this.rabbitViewModel = null;
        this.celebrationViewModel = null;
        this.bgView = null;
        this.tableViewModel = null;
        this.windowVisibility = null;
        this.controlPanelViewModel = null;
        this.controlPanelView = null;
        this.bottomPanelView = null;
        this.bottomPanelViewModel = null;
        this.infoTableView = null;
        this.infoTableViewModel = null;
        this.selfEmojiViewModel = null;
        this.selfEmojiView = null;
        this.cardsViewModel = null;
        this.betViewModel = null;
        this.dealerViewModel = null;
        this.ThrowAnimationViewModel = null;
        this.winnTableViewModel = null;
        this.genericPopupViewModel = null;
        this.playerViewModel = null;
        this.playerView = null;
        this.timeBankViewModel = null;
        if (this.gameService) {
            this.gameService.clearUserModels();
        }
        this.gameService = null;
        if (this.parentFacade) {
            this.parentFacade.tableClosed(this.tableId);
        }
        this.parentFacade = null;
        this.tableId = null;
    };
    sendEnableTimeBank = function () {
        const obj = this.gameService.getTimeBankData();
        this.parentFacade.sendEnableTimeBank(obj.optionSetId, obj.timeBankTimeoutAt, obj.timeBankAutoEnabled, this.tableId);
    };
    getSngJackpotID = function () {
        return this.gameService.getSngJackpotID();
    };
    sendReqNextHandOffFoldAndWatch = function () {
        this.parentFacade.sendReqNextHandOffFoldAndWatch(this.tableId);
    };
    sendShowOptionSelected = function () {
        this.parentFacade.sendShowOptionSelected(this.tableId);
    };
    sendMuckOptionSelected = function () {
        this.parentFacade.sendMuckOptionSelected(this.tableId);
    };
    sendDontShowOptionSelected = function () {
        this.parentFacade.sendDontShowOptionSelected(this.tableId);
    };
    showSideMenuReq = function () {
        this.parentFacade.showSideMenuReq(this.gameService.getPeerId(), this.gameService.gTableType, this.tableView.tabletextAutoRebuyMax.visible);
    };
    onSwipeTable = function (dir) {
        if (this.scale.x == 1) {
            this.parentFacade.swipeTable(dir);
        }
    };
    openRebuyWindow = function () {
        let obj: any;
        if (this.gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_FF) {
            obj = {
                tableId: this.gameService.getPeerId(),
                type: this.gameService.getUserBalance(uiConfig.selfUserId) == 0 ? 'burstOut' : 'normal',
                gametype: 'FF',
                tableStatus: this.gameService.getTableStatusData(),
                isAutoreBuyin: true,
                poolId: this.poolId,
            };
        } else {
            // else or cash
            obj = {
                tableId: this.gameService.getPeerId(),
                type: this.gameService.getUserBalance(uiConfig.selfUserId) == 0 ? 'burstOut' : 'normal',
                gametype: 'Cash',
                tableStatus: this.gameService.getTableStatusData(),
                isAutoreBuyin: true,
            };
        }
        if (this.gameService.getUserBalance(uiConfig.selfUserId) < obj['tableStatus'].maxBuyInAmount) {
            this.eventDispatcherGlobal.dispatchEvent('onShowRebuyPopUp', {
                table: obj['tableStatus'],
                isAutoreBuyin: obj['isAutoreBuyin'],
                userBalance: this.gameService.getUserBalance(uiConfig.selfUserId),
                type: obj['gametype'],
                poolId: obj['poolId'],
                entryId: obj['tableId'],
            });
        } else {
            this.showGenericError(
                'You can take a maximum of $' + obj['tableStatus'].maxBuyInAmount / 100 + ' to this table.',
                'PARTY_POKER_GC_GAMETABLE_REALCANMAXNCHPS',
            );
        }
        // this.eventDispatcherGlobal.dispatchEvent('onShowRebuyPopUp', {
        //     table: obj['tableStatus'],
        //     isAutoreBuyin: obj['isAutoreBuyin'],
        //     userBalance: this.gameService.getUserBalance(uiConfig.selfUserId),
        //     type: obj['gametype'],
        //     poolId: obj['poolId'],
        //     entryId: obj['tableId'],
        // });
        // window.parent.dispatchEvent(
        //   new CustomEvent("OPEN_LOBBY_REBUY_WINDOW", {
        //     detail: obj,
        //   })
        // );
    };
    setTableId = function (tableId) {
        this.tableId = tableId;
    };
    showLoyaltyCashBackPopUp = function () {
        this.parentFacade.showLoyaltyCashBackPopUp();
    };
    hideKothBtn = function () {
        if (this.parentFacade.activeTables.length > 3) {
            for (let i = 0; i < this.parentFacade.activeTables.length; i++) {
                const tableId = this.parentFacade.activeTables[i];
                if (
                    this.parentFacade['table' + tableId].gameService.getGameTableType() == serverIdConstants.TABLE_TYPE_CASH &&
                    this.parentFacade['table' + tableId].gameService.getMaxNoOfSeats() == 2 &&
                    !(this.parentFacade['table' + tableId] == undefined) &&
                    this.parentFacade['table' + tableId].kothTableViewModel != undefined
                )
                    this.parentFacade['table' + tableId].kothTableViewModel.hideKothBtn();
            }
        }
    };
    showKothSelectedIcon = function (tableIds) {
        for (let i = 0; i < tableIds.length; i++) {
            const tableId = tableIds[i];
            if (!(this.parentFacade['table' + tableId] == undefined) && this.parentFacade['table' + tableId].kothTableViewModel != undefined)
                this.parentFacade['table' + tableId].kothTableViewModel.view.isKothSelected = true;
        }
    };
    deSelectAllBtns = function (tableIds) {
        for (let i = 0; i < tableIds.length; i++) {
            const tableId = tableIds[i];
            if (!(this.parentFacade['table' + tableId] == undefined) && this.parentFacade['table' + tableId].kothTableViewModel != undefined)
                this.parentFacade['table' + tableId].kothTableViewModel.view.isKothSelected = false;
        }
    };
    handleHUPairTableSelectStatus = function (data) {
        if (data.selected == false) this.deSelectAllBtns(data.tables);
        else this.showKothSelectedIcon(data.tables);
    };
    handleRemovedPlayers = function () {
        this.coreViewModel.handleRemovedPlayers();
    };

    ratholingMessagePopUp = function (data) {
        const ratholPopUp: any = uiConfig.genericPopUpConfig;
        ratholPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_BUYINCHIPS_BUYINTITLE');

        ratholPopUp.bodyText = {
            text1: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_RATHOLING_PREV_BALANCE_TEXT').replace('<b> %1s</b>', ''),
            text2: CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_RATHOLING_BUYIN_DESCRIPTION'),
            rathAmt: ` $${data.minAmount / 100}`,
            progressBarTime: CommonGameProperties.dynaconConfigs['webClientConfigs']['ratholProgressBarTimeint'], // 20 sec
            tableId: data.peerId,
        };

        ratholPopUp.isRathol = true;
        ratholPopUp.buttonsTextArr = [
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_QSLOBBY_SAVECANCEL').toUpperCase(),
            CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_BUYINCHIPS_BUYINTITLE').toUpperCase(),
        ];
        ratholPopUp.buttonsActionArr = [SlotCoreGameEvent.RATHOL_CANCEL, SlotCoreGameEvent.RATHOL_BUYIN];
        ratholPopUp.showCloseButton = false;
        ratholPopUp.popUpId = 'PARTY_POKER_RATHOLING';
        this.ratholData = data;

        this.activeRatholTables = window.localStorage.getItem('activeRatholTables');
        this.activeRatholTables = this.activeRatholTables ? JSON.parse(this.activeRatholTables) : [];
        // console.log('ls..activeRatholTables..', this.activeRatholTables);
        this.activeRatholTables.push(this.ratholData.peerId.toString());
        window.localStorage.setItem('activeRatholTables', JSON.stringify(this.activeRatholTables));
        this.gameService.setAddPopUpData(ratholPopUp);
        this.addGenericPopUp();
        this.eventDispatcherGlobal.dispatchEvent('Rathol_PopUpActive', true);
    };

    ratholCancel = function () {
        // console.log("ls..ratholCancel");
        this.sendRequestLeaveTable();
        this.checkRatholPopup(this.ratholData.peerId);
    };
    ratholBuyIn = function () {
        // console.log("ls..ratholBuyIn");
        if (this.ratholData.blockSeatResponse.userBalance > this.ratholData.minAmount) {
            this.parentFacade.sendSitRequest(
                0,
                0,
                this.ratholData.minAmount,
                this.ratholData.blockSeatResponse.seatNumber,
                this.gameService.getTableCategory(),
                this.ratholData.peerId,
            );
            this.checkRatholPopup(this.ratholData.peerId);
        } else {
            const ratholerrorPopUp: any = uiConfig.genericPopUpConfig;
            ratholerrorPopUp.isRathol = false;
            ratholerrorPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_ERROR').toUpperCase();
            ratholerrorPopUp.bodyText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_BUYINCHIPS_INSUFFFUNDSGOCASHADDFNDS');
            ratholerrorPopUp.showCloseButton = false;
            ratholerrorPopUp.showCheckBox = false;
            ratholerrorPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_OK').toUpperCase()];
            ratholerrorPopUp.buttonsActionArr = [SlotCoreGameEvent.CONCLUDED_LEAVE];
            ratholerrorPopUp.isAntiTblHop = false;
            ratholerrorPopUp.popUpId = 'GENERIC_MESSAGE';
            this.gameService.setAddPopUpData(ratholerrorPopUp);
            this.addGenericPopUp();
        }
    };
    checkRatholPopup = function (tableId) {
        this.activeRatholTables.splice(this.activeRatholTables.indexOf(tableId), 1);
        window.localStorage.setItem('activeRatholTables', JSON.stringify(this.activeRatholTables));
    };
    playAudioExpoSpin = function (data) {
        const audio = data.audio;
        const currenttable = 'table' + this.gameService.getPeerId();
        switch (audio) {
            case 'expo_MusicIntro_V1':
                CommonGameProperties.facade[currenttable].soundObj.playSound(uiConfig.SoundProps['expo_MusicIntro_V1'], false);
                break;
            case 'expo_CarStart_V1':
                CommonGameProperties.facade[currenttable].soundObj.multiSoundPlay(['expo_CarStart_V1', 'expo_CarLoop_V1']);
                break;
            case 'expo_Checkpoint_V1':
                CommonGameProperties.facade[currenttable].soundObj.playSound(uiConfig.SoundProps['expo_Checkpoint_V1']);
                break;
            case 'expo_FinalBlast_V1':
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_CarLoop_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_MusicLoop_V1']);
                CommonGameProperties.facade[currenttable].soundObj.playSound(uiConfig.SoundProps['expo_FinalBlast_V1']);
                break;
            case 'expo_InitialCountdown_V1':
                CommonGameProperties.facade[currenttable].soundObj.playSound(uiConfig.SoundProps['expo_InitialCountdown_V1']);
                break;
            case 'expo_CarLoop_V1':
                CommonGameProperties.facade[currenttable].soundObj.playSound(uiConfig.SoundProps['expo_CarLoop_V1']);
                break;
            case 'expo_MusicLoop_V1':
                CommonGameProperties.facade[currenttable].soundObj.playSound(uiConfig.SoundProps['expo_MusicLoop_V1']);
                break;
            case 'stopExpoAudio':
                this.isExpoPlaying = false;
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_MusicLoop_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_CarStart_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_Checkpoint_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_FinalBlast_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_InitialCountdown_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_CarLoop_V1']);
                CommonGameProperties.facade[currenttable].soundObj.stopLoopSound(uiConfig.SoundProps['expo_MusicLoop_V1']);
                break;
            case 'pauseAudio':
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_MusicIntro_V1']);
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_CarStart_V1']);
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_Checkpoint_V1']);
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_FinalBlast_V1']);
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_InitialCountdown_V1']);
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_CarLoop_V1']);
                CommonGameProperties.facade[currenttable].soundObj.pauseLoopSound(uiConfig.SoundProps['expo_MusicLoop_V1']);
                break;
        }
    };

    openATHLearnMore = function () {
        this.eventDispatcherGlobal.dispatchEvent('OPEN_ATH_LEARN_MORE');
    };

    showGenericError = function (text, id, titleText) {
        const genericErrorPopUp: any = uiConfig.genericPopUpConfig;
        if (titleText) {
            genericErrorPopUp.titleText = titleText;
        } else {
            genericErrorPopUp.titleText = CommonGameProperties.langPack['getLiteral']('PARTY_POKER_COMMON_ERROR');
        }
        genericErrorPopUp.bodyText = text;
        genericErrorPopUp.showCloseButton = false;
        genericErrorPopUp.showCheckBox = false;
        genericErrorPopUp.buttonsTextArr = [CommonGameProperties.langPack['getLiteral']('PARTY_POKER_GC_GAMETABLE_OK')];
        genericErrorPopUp.buttonsActionArr = [''];
        genericErrorPopUp.popUpId = id; //PARTY_POKER_GC_LEAVETABLE
        this.gameService.setAddPopUpData(genericErrorPopUp);
        this.addGenericPopUp();
    };
}
