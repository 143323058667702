import { Message } from '../../message';

export class ResponseTermsAndConditionsAcceptance extends Message {
    private MESSAGE_NAME: string = 'ResponseTermsAndConditionsAcceptance';
    private termsAndConditionsAccepted: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, termsAndConditionsAccepted: boolean | null = false) {
        super(reqServerPeerId);
        this.termsAndConditionsAccepted = termsAndConditionsAccepted;
    }

    getTermsAndConditionsAccepted(): boolean | null {
        return this.termsAndConditionsAccepted;
    }

    setTermsAndConditionsAccepted(termsAndConditionsAccepted: boolean | null) {
        this.termsAndConditionsAccepted = termsAndConditionsAccepted;
    }
}
