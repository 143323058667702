import { Message } from '../../message';

export class SNGJPBountyWinner extends Message {
    private MESSAGE_NAME: string = 'SNGJPBountyWinner';
    private screenName: string | null = null;
    private amount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, screenName: string | null = null, amount: number = 0) {
        super(reqServerPeerId);
        this.screenName = screenName;
        this.amount = amount;
    }

    getScreenName(): string | null {
        return this.screenName;
    }

    setScreenName(screenName: string | null) {
        this.screenName = screenName;
    }
    getAmount(): number {
        return this.amount;
    }

    setAmount(amount: number) {
        this.amount = amount;
    }
}
