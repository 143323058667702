import { Message } from '../server/common/message';
import { PGMessageFactoryRegistration } from './PGMessageFactoryRegistration';
import { TSDataInputStream } from './dataInputOutputStream/TSDataInputStream';
import { FrameDecoder } from './frameDecoder';
import { IFrame } from './interfaces/Iframe';
import { MessageFactoryManager } from './messageFactoryManager';

export class MessageDecoder {
    private messageFactory: MessageFactoryManager;
    private msgFactryReg: PGMessageFactoryRegistration;
    constructor(msgFactryReg: PGMessageFactoryRegistration) {
        this.msgFactryReg = msgFactryReg;
        this.messageFactory = new MessageFactoryManager(this.msgFactryReg);
    }

    receiveBytesFromServer(byteStream: any) {
        let decodedFrames: IFrame[] | null = FrameDecoder.decode(byteStream);
        let msg: Message | null = null;
        // var isBCEnabled = this.connectionManager.getIfBackwardCompatibilityEnabled();
        for (let frameIndex = 0; frameIndex < decodedFrames.length; ++frameIndex) {
            const frame = decodedFrames[frameIndex];
            let iStream: TSDataInputStream | null = new TSDataInputStream(frame.msgBody, this.msgFactryReg); //isBCEnabled
            msg = this.messageFactory.getClassNameForClassId(frame.classId);
            if (typeof frame.reqServerPeerId === 'number' && msg) {
                msg.setReqServerPeerId(frame.reqServerPeerId);
            }
            if (msg) {
                this.messageFactory.messageRead(msg, iStream);
                frame.msgBody.dispose();
                frame.msgBody = null;
                iStream = null;
                msg.classId = frame.classId;
                // return msg;
            } else {
                throw new Error('No Class Found in Serializer for ClassId : ' + frame.classId);
            }
        }

        decodedFrames.length = 0;
        decodedFrames = null;
        return msg;
    }
}
