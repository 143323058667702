import { Message } from '../../message';

export class MessageDeliveryFailure extends Message {
    private MESSAGE_NAME: string = 'MessageDeliveryFailure';
    private failedMsgClassId: number = 0;
    private failedMsgTargetId: number = 0;
    private failureCause: number = 0;
    private failedMsg: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        failedMsgClassId: number = 0,
        failedMsgTargetId: number = 0,
        failureCause: number = 0,
        failedMsg: any[] | null = null,
    ) {
        super(reqServerPeerId);
        this.failedMsgClassId = failedMsgClassId;
        this.failedMsgTargetId = failedMsgTargetId;
        this.failureCause = failureCause;
        this.failedMsg = failedMsg;
    }

    getFailedMsgClassId(): number {
        return this.failedMsgClassId;
    }

    setFailedMsgClassId(failedMsgClassId: number) {
        this.failedMsgClassId = failedMsgClassId;
    }
    getFailedMsgTargetId(): number {
        return this.failedMsgTargetId;
    }

    setFailedMsgTargetId(failedMsgTargetId: number) {
        this.failedMsgTargetId = failedMsgTargetId;
    }
    getFailureCause(): number {
        return this.failureCause;
    }

    setFailureCause(failureCause: number) {
        this.failureCause = failureCause;
    }
    getFailedMsg(): any[] | null {
        return this.failedMsg;
    }

    setFailedMsg(failedMsg: any[] | null) {
        this.failedMsg = failedMsg;
    }
}
