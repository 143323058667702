import { Properties } from '../../utils/properties';
import { Chip } from '../chip';
import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { Point } from '../point';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

const inShowDownnext: boolean = false;

export class ShowDown extends TableEventInfo {
    isuncalledBetFuncAlreadyCalled: boolean = false;
    inShowDownprev: boolean = false;
    makeLastPotFalse: boolean = false;

    getEventType(): TableEvents {
        return TableEvents.SHOWDOWN;
    }
    getPlayerStatus(): any {
        return PlayerStatus.SHOWDOWN;
    }
    next(gameTable: GameTable): void {
        gameTable.presentBettingRound = '3';
        gameTable.currentBettingRound = gameTable.currentBettingRound + 1;
        if (gameTable.gameType === '7CARDSTUD' || gameTable.gameType === '7CARDHEADSUP') {
            gameTable.currentBettingRound = 7;
        }
        if (gameTable.isRit && !gameTable.isMobile) {
            const potPosition = Properties.propFile[gameTable.gameType]['RITMAINPOTCHIPPOS'].split('#');
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            gameTable.playersMap.forEach((player: any, seat: any) => {
                gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
            });
        } else {
            const potPosition = Properties.propFile[gameTable.gameType]['MAINPOTCHIPPOS'].split('#');
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            gameTable.playersMap.forEach((player: any, seat: any) => {
                gameTable.changeChipPos = new Point(potPosition[0], potPosition[1]);
            });
        }
        gameTable.mpot = false;
        const mpot = gameTable.moveToPot();
        if (!gameTable.onInit) {
            gameTable.accumulateChip(this.potCreated, true);
        }

        const eventMap = gameTable.eventMap;
        let playerMap = eventMap.get(this.getPlayerStatus());

        if (!playerMap) {
            playerMap = new Map<string, Chip>();
            eventMap.set(this.getPlayerStatus(), playerMap);
        }

        gameTable.players.forEach((p: any) => {
            if (playerMap) {
                playerMap.set(p.name, p.toPot);
            }
        });

        if (gameTable.lastLockedPotAmount > 0) {
            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 2].activePotNumber - 1].totalValue =
                gameTable.lastLockedPotAmount;
        }

        const extraChip = this.getPlayerExtraChips(gameTable, playerMap);
        console.log(extraChip);
        if (gameTable.mainPotFreezed) {
            gameTable.MAIN_POT.totalValue = gameTable.mainPotLockedValue;
        } else {
            gameTable.MAIN_POT.totalValue = gameTable.ROUND_POT.totalValue;
        }
        if (gameTable.gameType === '7CARDHEADSUP' || gameTable.gameType === 'TABLE6MAX' || gameTable.gameType === '7CARDSTUD') {
            gameTable.roundObjects['5'] = gameTable.MAIN_POT.totalValue;
        } else {
            gameTable.roundObjects['3'] = gameTable.MAIN_POT.totalValue;
            // gameTable.directShowDownEventValue = gameTable.MAIN_POT.totalValue;
        }

        if (gameTable.onInit && gameTable.isUnCalledBet) {
            this.unCalledBetAmountforNext(gameTable);
            this.isuncalledBetFuncAlreadyCalled = false;
            this.inShowDownprev = false;
        }

        if (mpot && !gameTable.onInit) {
            gameTable.enableTableMask = true;
            if (!inShowDownnext && gameTable.isUnCalledBet) {
                this.unCalledBetAmountforNext(gameTable);
            }
            setTimeout(() => {
                gameTable.playersMap.forEach((player: any) => {
                    player.toPot = new Chip(0);
                });
                gameTable.accumulateChip(this.potCreated, false);
                gameTable.enableTableMask = false;

                if (!gameTable.isReplayerV3) {
                    if (gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]) {
                        if (
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].totalLockedPots === 0 &&
                            gameTable.SIDE_POTS.length > 0 &&
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                        ) {
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                        }
                    }
                }
                if (!gameTable.isReplayerV3) {
                    if (gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]) {
                        if (
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].totalLockedPots === 0 &&
                            gameTable.SIDE_POTS.length > 0 &&
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                        ) {
                            gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                        }
                    }
                }

                gameTable.SIDE_POTS.forEach((pot) => {
                    if (pot.totalValue > 0) {
                        if (
                            gameTable.valueUpdatedPotId >= 0 &&
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId] &&
                            gameTable.potCreatedObject.hasOwnProperty(gameTable.currentBettingRound - 1)
                        ) {
                            gameTable.SIDE_POTS[gameTable.valueUpdatedPotId].totalValue =
                                gameTable.potCreatedObject[gameTable.currentBettingRound - 1];
                        }
                        pot.showPot = true;
                    }
                });

                if (gameTable.isReplayerV3 && this.makeLastPotFalse && gameTable.SIDE_POTS.length > 0) {
                    const sidepotValue = gameTable.SIDE_POTS.length;
                    gameTable.SIDE_POTS[sidepotValue - 1].showPot = false;
                }
            }, gameTable.gameSpeedBarSetter);
        } else {
            gameTable.playersMap.forEach((player: any) => {
                player.toPot = new Chip(0);
            });
        }
        let crossCheck: number = 0;
        if (gameTable.isReplayerV3) {
            gameTable.SIDE_POTS.forEach((pot) => {
                crossCheck = crossCheck + pot.totalValue;
            });
            gameTable.existingpotTotalValue = crossCheck;
            gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;
        }
        gameTable.lastLockedPotAmount = 0;
        if (gameTable.isReplayerV3 && gameTable.currentActivePot <= 0 && gameTable.SIDE_POTS.length > 0) {
            const sidepotValue = gameTable.SIDE_POTS.length;
            gameTable.currentActivePot = gameTable.SIDE_POTS[sidepotValue - 1].totalValue;
            this.makeLastPotFalse = true;
        }
    }

    prev(gameTable: GameTable): void {
        let f: any;
        gameTable.existingpotTotalValue = 0;
        gameTable.currentBettingRound = gameTable.currentBettingRound - 1;
        gameTable.lastLockedPotAmount = 0;
        if (gameTable.gameType === '7CARDSTUD' || gameTable.gameType === '7CARDHEADSUP') {
            gameTable.currentBettingRound = 6;
        }
        gameTable.mpot = false;
        this.getPlrReverseExtraChips(gameTable);
        gameTable.playersMap.forEach((player: any, seat: any) => {
            const potPosition = Properties.propFile[gameTable.gameType][`CHIPPOS${seat}`].split('#');
            player.playerChipPos = new Point(potPosition[0], potPosition[1]);
            gameTable.revertChip(this.potCreated);
            const event = gameTable.eventMap;
            const t = event.get(this.getPlayerStatus());
            if (t != undefined) f = t.get(player.name);
            if (f != undefined) player.toPot.value = f.value;
            gameTable.existingpotTotalValue = gameTable.existingpotTotalValue + f.value;
        });

        let currentBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber;
        let prevBettingRoundActivePotNumber = gameTable.gameRoundDetails[gameTable.currentBettingRound - 1]
            ? gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber
            : 0;

        for (let i = currentBettingRoundActivePotNumber; i > prevBettingRoundActivePotNumber; i--) {
            if (gameTable.SIDE_POTS.length >= i) {
                gameTable.SIDE_POTS[i - 1].showPot = false;
            }
        }

        if (gameTable.isReplayerV3) {
            gameTable.SIDE_POTS.forEach((pot) => {
                if (pot.totalValue == 0) {
                    gameTable.SIDE_POTS.pop();
                }
                if (pot.showPot) {
                    gameTable.existingpotTotalValue = gameTable.existingpotTotalValue + pot.totalValue;
                }
            });
        }

        if (
            Object.keys(gameTable.roundObjects).length === 2 &&
            gameTable.roundObjects.hasOwnProperty(0) &&
            (gameTable.roundObjects.hasOwnProperty(5) || gameTable.roundObjects.hasOwnProperty(3)) &&
            gameTable.SIDE_POTS.length === 1
        ) {
            gameTable.SIDE_POTS[0].showPot = false;
        } else if (Object.keys(gameTable.roundObjects).length === 1 && gameTable.SIDE_POTS.length > 0) {
            gameTable.SIDE_POTS.forEach((spot) => {
                spot.showPot = false;
            });
        }

        if (!gameTable.isReplayerV3) {
            if (
                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
                gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber &&
                gameTable.SIDE_POTS.length > 0 &&
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
            ) {
                gameTable.lastLockedPotAmount =
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue;
                gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1].totalValue =
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
            }
        }

        gameTable.players.forEach((p: any) => {
            if (this.inShowDownprev && gameTable.isUnCalledBet) {
                if (gameTable.uncallbetPlayerName.includes(p.name)) {
                    if (!p.isPlayerAllIn) {
                        // if (p.toPot.value === gameTable.unCalledBetAmountDetails["uncalledUnit"].unit) {
                        p.chips.value = p.chips.value - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                        if (p.chips.value === 0) {
                            p.isPlayerAllIn = true;
                        }
                    } else {
                        p.isPlayerAllIn = true;
                        p.chips.value = 0;
                    }
                    gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    // console.log(gameTable.ROUND_POT.totalValue);
                    gameTable.isUnCalledAmountMatched = true;
                }
            }
            // }
        });

        const totalchipvalue = gameTable.roundObjects;
        if (!gameTable.isReplayerV3) {
            if (gameTable.gameRoundDetails[gameTable.currentBettingRound]) {
                if (
                    gameTable.gameRoundDetails[gameTable.currentBettingRound].totalLockedPots === 0 &&
                    gameTable.SIDE_POTS.length > 0 &&
                    gameTable.gameRoundDetails[gameTable.currentBettingRound - 1] &&
                    gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotNumber - 1]
                ) {
                    if (gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber - 1]) {
                        gameTable.SIDE_POTS[gameTable.gameRoundDetails[gameTable.currentBettingRound].activePotNumber - 1].totalValue =
                            gameTable.gameRoundDetails[gameTable.currentBettingRound - 1].activePotAmount.unit;
                    }
                }
            }
        }

        // const totalchipvalue = gameTable.roundObjects;
        if (gameTable.gameType == '7CARDHEADSUP' || gameTable.gameType == 'TABLE6MAX' || gameTable.gameType == '7CARDSTUD') {
            for (let i = 4; i >= 0; i--) {
                if (gameTable.roundObjects.hasOwnProperty(i)) {
                    gameTable.MAIN_POT.totalValue = totalchipvalue[i];
                    gameTable.changeChip = true;
                    break;
                }
            }
        } else {
            for (let i = 2; i >= 0; i--) {
                if (gameTable.roundObjects.hasOwnProperty(i)) {
                    gameTable.MAIN_POT.totalValue = totalchipvalue[i];
                    gameTable.changeChip = true;
                    break;
                } else {
                    gameTable.MAIN_POT.totalValue = 0;
                    gameTable.changeChip = false;
                }
            }
        }
        if (gameTable.isReplayerV3) {
            gameTable.currentActivePot = gameTable.ROUND_POT.totalValue - gameTable.existingpotTotalValue;
        }
        if (gameTable.isReplayerV3 && gameTable.currentActivePot <= 0 && gameTable.SIDE_POTS.length > 0) {
            gameTable.currentActivePot = gameTable.SIDE_POTS[prevBettingRoundActivePotNumber - 1].totalValue;
            gameTable.SIDE_POTS[prevBettingRoundActivePotNumber - 1].showPot = false;
        }
    }

    unCalledBetAmountforNext(gameTable: GameTable) {
        gameTable.players.forEach((p: any) => {
            if (gameTable.uncallbetPlayerName.includes(p.name) && gameTable.isUnCalledAmountMatched) {
                if (!p.isPlayerAllIn) {
                    // if (p.toPot.value === gameTable.unCalledBetAmountDetails["uncalledUnit"].unit) {
                    p.chips.value = p.chips.value + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    // }
                } else {
                    p.isPlayerAllIn = false;
                    p.chips.value = gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                }
                gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                gameTable.isUnCalledAmountMatched = false;
                // isHappeninginDealingRiver = true;
                this.isuncalledBetFuncAlreadyCalled = true;
                this.inShowDownprev = true;
            } else if (gameTable.uncallbetPlayerName.includes(p.name) && !gameTable.isUnCalledAmountMatched) {
                if (p.toPot.value === gameTable.unCalledBetAmountDetails['uncalledUnit'].unit) {
                    // console.log(p.toPot.value, "in dealing river");
                    if (p.chips.value === 0) {
                        p.isPlayerAllIn = true;
                    }
                    p.chips.value = p.chips.value + gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    if (p.chips.value != 0) {
                        p.isPlayerAllIn = false;
                    }
                    gameTable.ROUND_POT.totalValue = gameTable.ROUND_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    gameTable.MAIN_POT.totalValue = gameTable.MAIN_POT.totalValue - gameTable.unCalledBetAmountDetails['uncalledUnit'].unit;
                    this.inShowDownprev = true;
                    // gameTable.isUnCalledAmountMatched = true;
                    // console.log("are you calling");
                }
            }
        });
    }
}
