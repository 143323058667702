import { Injectable } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class FFFiltersEventBusService {
    private cardsFilter: { gameTypes: Array<string>; winUptos: Array<number> };
    // public filteredFastForwardCards = [];
    public selectedWinUptos: any[] = [];
    private readonly logger: PokerLogger;
    constructor(loggerFactory: LoggerFactory) {
        this.logger = loggerFactory.getLogger('ffFilterseventbusserviceLoggingComponent');
        if (localStorage.getItem('fastForwardCardsFilter') == null) {
            this.cardsFilter = { gameTypes: [], winUptos: [] };
        } else {
            this.cardsFilter = JSON.parse(localStorage.getItem('fastForwardCardsFilter')!);
        }
        localStorage.setItem('fastForwardCardsFilter', JSON.stringify(this.cardsFilter));
    }
    filterFastForwardCards(fastForwardcards) {
        this.logger.info('filterFastForwardCards' + JSON.stringify(fastForwardcards));
        this.cardsFilter = JSON.parse(localStorage.getItem('fastForwardCardsFilter')!);
        let filteredFastForwardCards: any[] = [];
        if (!this.cardsFilter || (this.cardsFilter.gameTypes.length === 0 && this.cardsFilter.winUptos.length === 0)) {
            const sortedFiltered = fastForwardcards;
            filteredFastForwardCards = _.sortBy(sortedFiltered, ['formattedBuyIn', 'formattedBuyIn']);
        } else if (this.cardsFilter.gameTypes.length > 0 && this.cardsFilter.winUptos.length > 0) {
            const filtered1 = _.filter(fastForwardcards, (f) => _.filter(this.cardsFilter.winUptos, (a) => f.formattedBuyIn === a).length > 0);
            filteredFastForwardCards = _.sortBy(
                _.filter(filtered1, (f) => _.filter(this.cardsFilter.gameTypes, (b) => f.gameTypeTxt === b).length > 0),
                ['formattedBuyIn', 'formattedBuyIn'],
            );
        } else if (this.cardsFilter.gameTypes.length > 0 && this.cardsFilter.winUptos.length === 0) {
            filteredFastForwardCards = _.sortBy(
                _.filter(fastForwardcards, (f) => _.filter(this.cardsFilter.gameTypes, (b) => f.gameTypeTxt === b).length > 0),
                ['formattedBuyIn', 'formattedBuyIn'],
            );
        } //else if (this.cardsFilter.gameTypes.length === 0 && this.cardsFilter.winUptos.length > 0) {
        //   filteredFastForwardCards = _.sortBy(_.filter(fastForwardcards, (f) => _.filter(this.cardsFilter.winUptos, (a) => f.formattedBuyIn === a).length > 0), ['formattedBuyIn', 'formattedBuyIn']);
        // }
        this.selectedWinUptos = _.sortBy(_.uniq(_.map(filteredFastForwardCards, (o) => o.formattedBuyIn)));
        return filteredFastForwardCards;
    }
}
