import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';

import { LoginDialogService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { MyGameClientConfig } from '@pokercore/module/core';
import { filter } from 'rxjs/operators';

import { MyGameDataService } from './poker-my-game-data.service';

@Component({
    selector: 'poker-my-game',
    templateUrl: 'poker-my-game.component.html',
    standalone: true,
    imports: [TrustAsHtmlPipe, CommonModule],
})
export class PokerMyGameComponent extends PCComponent implements OnInit {
    myGameContainer: HTMLElement;
    myGameServiceData: any;
    myGameData: any;
    params: any;
    contentConfig: any;
    errorConfig: any;
    contentConfigItems: any;
    minimumHandsRequiredint: number = 100;

    @HostBinding()
    get class() {
        this.myGameServiceData = this.item;
        this.params = this.item?.parameters;
        return this.createClass(this.item.name);
    }
    constructor(
        private myGameDataService: MyGameDataService,
        private myGameClientConfig: MyGameClientConfig,
        private loginService: LoginDialogService,
        private userService: UserService,
    ) {
        super();
        this.errorConfig = this.myGameClientConfig.errorMessagesConfigItem;
        this.contentConfigItems = this.myGameClientConfig.contentConfigItem;
    }

    ngOnInit() {
        this.getGameData();
        this.subscribeToUserEvents();
    }

    subscribeToUserEvents() {
        this.userService.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(
            () => {
                this.getGameData();
            },
            () => {
                this.getGameData();
                //console.log('error: ' + JSON.stringify(error));
            },
        );
    }

    // Get My Game data after login
    getGameData() {
        this.myGameDataService.getMyGameData().subscribe((gameData: any) => {
            this.myGameData = gameData;
            this.contentConfig = gameData?.contentConfigItems;
        });
    }

    // CTA button opens My Game page
    viewFullScore() {
        this.myGameDataService.getPlayerCredentials().subscribe(
            (playerCreds: any) => {
                window.open(
                    `${this.params?.mygameLive}/clientLogin?partnerToken=${playerCreds?.partneR_TOKEN}&accountId=${playerCreds?.accountid}`,
                    '_blank',
                );
            },
            () => {
                //console.log('error: ' + JSON.stringify(error));
            },
        );
    }

    // Open Login modal
    loginDialog() {
        this.loginService
            .open({ openedBy: 'myGame' })
            .afterClosed()
            .subscribe(() => {
                setTimeout(() => {
                    this.myGameContainer = document.getElementById('myGameContainer') as HTMLElement;
                    this.myGameContainer.scrollIntoView({ block: 'center' });
                }, 1000);
            });
    }
}
