import { Message } from '../../message';

export class SNGJPRegistrationRequest extends Message {
    private MESSAGE_NAME: string = 'SNGJPRegistrationRequest';
    private sngJackpotID: number = 0;
    private entries: number = 0;
    private ticketsToUse: number = 0;
    private conversationId: number = 0;
    private sourceType: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    public override isPrivileged(): boolean {
        return true;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        sngJackpotID: number = 0,
        entries: number = 0,
        ticketsToUse: number = 0,
        conversationId: number = 0,
        sourceType: number = 0,
    ) {
        super(reqServerPeerId);
        this.sngJackpotID = sngJackpotID;
        this.entries = entries;
        this.ticketsToUse = ticketsToUse;
        this.conversationId = conversationId;
        this.sourceType = sourceType;
    }

    getSngJackpotID(): number {
        return this.sngJackpotID;
    }

    setSngJackpotID(sngJackpotID: number) {
        this.sngJackpotID = sngJackpotID;
    }
    getEntries(): number {
        return this.entries;
    }

    setEntries(entries: number) {
        this.entries = entries;
    }
    getTicketsToUse(): number {
        return this.ticketsToUse;
    }

    setTicketsToUse(ticketsToUse: number) {
        this.ticketsToUse = ticketsToUse;
    }
    getConversationId(): number {
        return this.conversationId;
    }

    setConversationId(conversationId: number) {
        this.conversationId = conversationId;
    }
    getSourceType(): number {
        return this.sourceType;
    }

    setSourceType(sourceType: number) {
        this.sourceType = sourceType;
    }
}
