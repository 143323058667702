import { Message } from '../../message';

export class WaitStatus extends Message {
    private MESSAGE_NAME: string = 'WaitStatus';
    private waitingStatus: number = 0;
    private waitingNo: number = 0;
    private waitingUsersCount: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, waitingStatus: number = 0, waitingNo: number = 0, waitingUsersCount: number = 0) {
        super(reqServerPeerId);
        this.waitingStatus = waitingStatus;
        this.waitingNo = waitingNo;
        this.waitingUsersCount = waitingUsersCount;
    }

    getWaitingStatus(): number {
        return this.waitingStatus;
    }

    setWaitingStatus(waitingStatus: number) {
        this.waitingStatus = waitingStatus;
    }
    getWaitingNo(): number {
        return this.waitingNo;
    }

    setWaitingNo(waitingNo: number) {
        this.waitingNo = waitingNo;
    }
    getWaitingUsersCount(): number {
        return this.waitingUsersCount;
    }

    setWaitingUsersCount(waitingUsersCount: number) {
        this.waitingUsersCount = waitingUsersCount;
    }
}
