import { Message } from '../../message';

export class MTCTTableStatus extends Message {
    private MESSAGE_NAME: string = 'MTCTTableStatus';
    private tableId: number = 0;
    private playerCount: number = 0;
    private largestStack: number = 0;
    private smallestStack: number = 0;
    private totalStack: number = 0;
    private serverPeerId: number = 0;
    private tableNumber: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        playerCount: number = 0,
        largestStack: number = 0,
        smallestStack: number = 0,
        totalStack: number = 0,
        serverPeerId: number = 0,
        tableNumber: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.playerCount = playerCount;
        this.largestStack = largestStack;
        this.smallestStack = smallestStack;
        this.totalStack = totalStack;
        this.serverPeerId = serverPeerId;
        this.tableNumber = tableNumber;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getLargestStack(): number {
        return this.largestStack;
    }

    setLargestStack(largestStack: number) {
        this.largestStack = largestStack;
    }
    getSmallestStack(): number {
        return this.smallestStack;
    }

    setSmallestStack(smallestStack: number) {
        this.smallestStack = smallestStack;
    }
    getTotalStack(): number {
        return this.totalStack;
    }

    setTotalStack(totalStack: number) {
        this.totalStack = totalStack;
    }
    getServerPeerId(): number {
        return this.serverPeerId;
    }

    setServerPeerId(serverPeerId: number) {
        this.serverPeerId = serverPeerId;
    }
    getTableNumber(): number {
        return this.tableNumber;
    }

    setTableNumber(tableNumber: number) {
        this.tableNumber = tableNumber;
    }
}
