import { Message } from '../../message';
import { FXConversionFactor } from '../../pgSharedMessageFactory/messages/FXConversionFactor';
import { GameTableStatus } from './GameTableStatus';

export class JoinTableResponse extends Message {
    private MESSAGE_NAME: string = 'JoinTableResponse';
    private responseId: number = 0;
    private tableStatus: GameTableStatus | null = null;
    private messageToShow: any | null = null;
    private fXConversionFactor: FXConversionFactor | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        responseId: number = 0,
        tableStatus: GameTableStatus | null = null,
        messageToShow: any | null = null,
        fXConversionFactor: FXConversionFactor | null = null,
    ) {
        super(reqServerPeerId);
        this.responseId = responseId;
        this.tableStatus = tableStatus;
        this.messageToShow = messageToShow;
        this.fXConversionFactor = fXConversionFactor;
    }

    getResponseId(): number {
        return this.responseId;
    }

    setResponseId(responseId: number) {
        this.responseId = responseId;
    }
    getTableStatus(): GameTableStatus | null {
        return this.tableStatus;
    }

    setTableStatus(tableStatus: GameTableStatus | null) {
        this.tableStatus = tableStatus;
    }
    getMessageToShow(): any | null {
        return this.messageToShow;
    }

    setMessageToShow(messageToShow: any | null) {
        this.messageToShow = messageToShow;
    }
    getFXConversionFactor(): FXConversionFactor | null {
        return this.fXConversionFactor;
    }

    setFXConversionFactor(fXConversionFactor: FXConversionFactor | null) {
        this.fXConversionFactor = fXConversionFactor;
    }
}
