import { Message } from '../../message';

export class LSReentryInfo extends Message {
    private MESSAGE_NAME: string = 'LSReentryInfo';
    private reentryBuyIn: number = 0;
    private reentryFee: number = 0;
    private reentryChips: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, reentryBuyIn: number = 0, reentryFee: number = 0, reentryChips: number = 0) {
        super(reqServerPeerId);
        this.reentryBuyIn = reentryBuyIn;
        this.reentryFee = reentryFee;
        this.reentryChips = reentryChips;
    }

    getReentryBuyIn(): number {
        return this.reentryBuyIn;
    }

    setReentryBuyIn(reentryBuyIn: number) {
        this.reentryBuyIn = reentryBuyIn;
    }
    getReentryFee(): number {
        return this.reentryFee;
    }

    setReentryFee(reentryFee: number) {
        this.reentryFee = reentryFee;
    }
    getReentryChips(): number {
        return this.reentryChips;
    }

    setReentryChips(reentryChips: number) {
        this.reentryChips = reentryChips;
    }
}
