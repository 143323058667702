import { Message } from '../../message';

export class LossLimitResponse extends Message {
    private MESSAGE_NAME: string = 'LossLimitResponse';
    private accountName: string | null = null;
    private limitsDetails: any[] | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, accountName: string | null = null, limitsDetails: any[] | null = null) {
        super(reqServerPeerId);
        this.accountName = accountName;
        this.limitsDetails = limitsDetails;
    }

    getAccountName(): string | null {
        return this.accountName;
    }

    setAccountName(accountName: string | null) {
        this.accountName = accountName;
    }
    getLimitsDetails(): any[] | null {
        return this.limitsDetails;
    }

    setLimitsDetails(limitsDetails: any[] | null) {
        this.limitsDetails = limitsDetails;
    }
}
