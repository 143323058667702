import { Message } from '../../message';

export class LobbyTableDataChange extends Message {
    private MESSAGE_NAME: string = 'LobbyTableDataChange';
    private tableId: number = 0;
    private avgPot: number = 0;
    private handsPerHour: number = 0;
    private waitingCount: number = 0;
    private tourneyGameStatus: number = 0;
    private playerCount: number = 0;
    private avgFlopSeen: number = 0;
    private reservedCnt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        tableId: number = 0,
        avgPot: number = 0,
        handsPerHour: number = 0,
        waitingCount: number = 0,
        tourneyGameStatus: number = 0,
        playerCount: number = 0,
        avgFlopSeen: number = 0,
        reservedCnt: number = 0,
    ) {
        super(reqServerPeerId);
        this.tableId = tableId;
        this.avgPot = avgPot;
        this.handsPerHour = handsPerHour;
        this.waitingCount = waitingCount;
        this.tourneyGameStatus = tourneyGameStatus;
        this.playerCount = playerCount;
        this.avgFlopSeen = avgFlopSeen;
        this.reservedCnt = reservedCnt;
    }

    getTableId(): number {
        return this.tableId;
    }

    setTableId(tableId: number) {
        this.tableId = tableId;
    }
    getAvgPot(): number {
        return this.avgPot;
    }

    setAvgPot(avgPot: number) {
        this.avgPot = avgPot;
    }
    getHandsPerHour(): number {
        return this.handsPerHour;
    }

    setHandsPerHour(handsPerHour: number) {
        this.handsPerHour = handsPerHour;
    }
    getWaitingCount(): number {
        return this.waitingCount;
    }

    setWaitingCount(waitingCount: number) {
        this.waitingCount = waitingCount;
    }
    getTourneyGameStatus(): number {
        return this.tourneyGameStatus;
    }

    setTourneyGameStatus(tourneyGameStatus: number) {
        this.tourneyGameStatus = tourneyGameStatus;
    }
    getPlayerCount(): number {
        return this.playerCount;
    }

    setPlayerCount(playerCount: number) {
        this.playerCount = playerCount;
    }
    getAvgFlopSeen(): number {
        return this.avgFlopSeen;
    }

    setAvgFlopSeen(avgFlopSeen: number) {
        this.avgFlopSeen = avgFlopSeen;
    }
    getReservedCnt(): number {
        return this.reservedCnt;
    }

    setReservedCnt(reservedCnt: number) {
        this.reservedCnt = reservedCnt;
    }
}
