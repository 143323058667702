import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DeviceService, MessageQueueService, MetaTagsService, NavigationService, PlainLinkComponent, TrackingService } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import {
    NavigationBarComponent,
    NavigationBarPokerComponent,
    PokerApiService,
    PokerHomePageClientConfig,
    ScrollToTopComponent,
    TournamentCalendarPagerService,
    TournamentsCalendarClientConfig,
} from '@pokercore/module/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSliderModule } from 'ngx-slider-v2';
import { Subscription } from 'rxjs';

import { limitNamePipe } from './LimitName.pipe';
import { removeColorCodesPipe } from './RemoveColorCodes.pipe';
import { formatCurrencyPipe } from './formatCurrency.pipe';
import { gameNamePipe } from './gameName.pipe';
import { TournamentCalendarFiltersComponent } from './tournament-calendar-filters.component';
import { TournamentCalendarTimerComponent } from './tournament-calendar-timer/tournament-calendar-timer.component';
import { TournamentCalendarResolver } from './tournamentcalendar.resolver';
import { TournamentCalendarService } from './tournamentcalendar.service';
import { WindowScrollingService } from './window-scrolling.service';

@Component({
    selector: 'pt-tournament-calendar',
    templateUrl: 'tournamentcalendar.component.html',
    styleUrls: ['./tournamentcalendar.css'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TrustAsHtmlPipe,
        MessagePanelComponent,
        PlainLinkComponent,
        FormsModule,
        NgxDatatableModule,
        NavigationBarComponent,
        NavigationBarPokerComponent,
        TournamentCalendarTimerComponent,
        NgxSliderModule,
        ScrollToTopComponent,
        TournamentCalendarFiltersComponent,
        formatCurrencyPipe,
        gameNamePipe,
        limitNamePipe,
        removeColorCodesPipe,
    ],
    providers: [TournamentCalendarResolver, TournamentCalendarService, TournamentCalendarPagerService, WindowScrollingService],
})
export class TournamentCalendarComponent {
    initData: any;
    sortOrder: string;
    sortBy: string;
    selectedValue: object = {};
    selectedBuyIn: string;
    type: any;
    i: any = 1;
    gameType: any = 'AllGames';
    buyIn: any = 'Interval0';
    tournamentType: any;
    hideRunning: any;
    startTimeDisplayMode: any;
    pageno: number = 0;
    clickId: number;
    clicked: boolean = false;
    filteredTournamentList: Array<any> = [];
    isFetching: boolean = false;
    noDataFound: boolean = false;
    startIndex: number;
    endIndex: number;
    defaultPageSize: number = 18;
    currentPage: number = 1;
    googleCalendarHost: string;
    yahooCalendarHost: string;
    msnCalendarHost: string;
    pager: any = {};
    requestParams: any = {};
    GameTypeValue: string;
    summaryView: boolean = false;
    hidePagination: boolean;
    totalTournaments: number;
    buyInMax: number;
    EnableAndDisable: boolean = false;
    previousValue: number;
    previousFilterValues: any;
    initialLoad: boolean = true;
    hardCodeData: any;
    isPartyNavigation: boolean;
    @Input()
    itemsPerPage: number = 15;
    showConfigureNavigation: boolean = false;
    filterDisplayStatus: boolean;
    deviceName: string = '';
    disableHtmlHeadTag: boolean = false;
    private filterSubscription: Subscription;
    errorMessage: string;
    @ViewChild('filtersInfo') filtersInfo: ElementRef;
    constructor(
        public tournamentCalendarService: TournamentCalendarService,
        private messageQueue: MessageQueueService,
        private metaTagsService: MetaTagsService,
        private navigation: NavigationService,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
        private tournamentsCalendarClientConfig: TournamentsCalendarClientConfig,
        private api: PokerApiService,
        private tournamentCalendarPager: TournamentCalendarPagerService,
        private windowScrollingService: WindowScrollingService,
        private renderer: Renderer2,
        private tracking: TrackingService,
        private device: DeviceService,
    ) {}

    ngOnInit() {
        this.tournamentsCalendarClientConfig.whenReady.subscribe(() => {
            this.googleCalendarHost = this.tournamentsCalendarClientConfig.googleCalendarHost;
            this.yahooCalendarHost = this.tournamentsCalendarClientConfig.yahooCalendarHost;
            this.msnCalendarHost = this.tournamentsCalendarClientConfig.msnCalendarHost;
            this.summaryView = this.tournamentsCalendarClientConfig.hideCalendarFilters;
            this.hideRunning = this.tournamentsCalendarClientConfig.defaultHideRunningOrFinished;
            this.hidePagination = this.tournamentsCalendarClientConfig.hidePagination;
            this.startTimeDisplayMode = this.tournamentsCalendarClientConfig.startTimeDisplayMode;
            this.buyInMax = this.tournamentsCalendarClientConfig.buyInMax;
            this.defaultPageSize = this.tournamentsCalendarClientConfig.defaultPageSize;
            this.isPartyNavigation = this.tournamentsCalendarClientConfig.isPartyNavigation;
        });

        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;

        this.previousFilterValues = this.tournamentCalendarService.currentFiltervalues;

        if (this.device.isMobile) {
            this.deviceName = 'Mobile';
        } else if (this.device.isTablet) {
            this.deviceName = 'Tablet';
        } else {
            this.deviceName = 'Desktop';
        }
        const pmPokerNavigationView = this.tournamentCalendarService.getPmPokerNavigationView();
        if (!pmPokerNavigationView) {
            this.tournamentCalendarService.getData().subscribe(
                (response) => {
                    if (response) {
                        this.getDataSuccess(response);
                        this.tournamentCalendarService.setinitialpagedTournamentItemsList(response.pagedTournamentItemsList);
                    }
                },
                (error) => {
                    this.errorMessage = error + 'An error occurred while fetching data. Please try again later.';
                },
            );
        } else {
            this.initData = {
                pmPokerNavigationView: this.tournamentCalendarService.getPmPokerNavigationView(),
                formElementTemplateChilds: this.tournamentCalendarService.getFormElementTemplateChilds(),
            };
            if (!this.previousFilterValues) {
                this.setFilteredTournamentData();
            }
        }
        if (this.summaryView && this.itemsPerPage === 15) {
            this.itemsPerPage = 5;
        }
        this.subscribeToFilters();
        this.trackEventsOnLoad();
    }
    ngOnDestroy() {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }
    trackEventsOnLoad() {
        if (this.device.isMobile || this.device.isMobilePhone || this.device.isTablet) {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Tournaments',
                'component.LabelEvent': 'Tournament Page',
                'component.ActionEvent': 'Tournament Page - Displayed',
                'component.PositionEvent': 'not applicable',
                'component.LocationEvent': 'not applicable',
                'component.EventDetails': this.deviceName,
                'component.URLClicked': 'not applicable',
            });
            //Filter sticky CTA at the bottom is displayed in mobile
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Tournaments',
                'component.LabelEvent': 'Tournament Page - Displayed',
                'component.ActionEvent': 'Filter CTA  - Displayed',
                'component.PositionEvent': 'Tournament Page',
                'component.LocationEvent': 'not applicable',
                'component.EventDetails': this.deviceName,
                'component.URLClicked': 'not applicable',
            });
        } else {
            this.tracking.triggerEvent('Event.Clicks', {
                'component.CategoryEvent': 'Tournaments',
                'component.LabelEvent': 'Tournament Page',
                'component.ActionEvent': 'Tournament Page - Displayed',
                'component.PositionEvent': 'not applicable',
                'component.LocationEvent': 'not applicable',
                'component.EventDetails': this.deviceName,
                'component.URLClicked': 'not applicable',
            });
        }
    }
    subscribeToFilters() {
        this.filterSubscription = this.tournamentCalendarService.getFilteredTournamentData.subscribe((filteredList) => {
            if (filteredList != null) {
                this.requestParams = filteredList;
                this.tournamentCalendarService.pageNumber = this.requestParams.pageNumber;
                this.setFilteredTournamentData();
            }
        });
    }

    setFilteredTournamentData() {
        if (this.tournamentCalendarService.pageBack && this.previousFilterValues && this.previousFilterValues.pageNumber) {
            this.previousFilterValues.pageNumber = this.tournamentCalendarService.pageNumber + 1;
            this.setTournamentCalendarPager(this.previousFilterValues.pageNumber);
        } else {
            this.setTournamentCalendarPager(this.tournamentCalendarService.pageNumber);
        }
    }

    getDataSuccess(response: any) {
        this.initData = response;
        this.tournamentCalendarService.setSitecoreInfo(this.initData);
        this.setRequiredValues();
        this.metaTagsService.whenReady.subscribe(() => {
            this.metaTagsService.setPageTags(this.initData.pmPokerNavigationView.pageTitle, {
                description: this.initData.pmPokerNavigationView.pageDescription,
            });
        });
        //console.warn('page title', this.initData.pageTitle);
        this.setInitialPager();
    }
    setTournamentCalendarPager(page: number) {
        this.EnableAndDisable = this.tournamentCalendarService.enableAndDisable;
        if (!this.requestParams.gameType) {
            this.requestParams.gameType = 'AllGames';
        }
        if (!this.requestParams.buyInMin) {
            this.requestParams.buyInMin = 0;
        }
        if (!this.requestParams.buyInMax) {
            this.requestParams.buyInMax = this.buyInMax + 5;
        }
        if (!this.requestParams.hideRunning) {
            this.requestParams.hideRunning = false;
        }
        if (!this.tournamentCalendarService.pageNumber || Number.isNaN(this.tournamentCalendarService.pageNumber)) {
            this.tournamentCalendarService.pageNumber = 1;
        }
        this.requestParams.pageNumber = page - 1;
        this.tournamentCalendarService.pageNumber = page;
        if (this.requestParams.pageNumber < 0) {
            this.requestParams.pageNumber = 0;
        }
        this.totalTournaments = 0;
        this.api.get('Tournament/GetTournamentData', this.requestParams).subscribe(
            (response: any) => {
                if (response.numberOfTournaments) {
                    this.totalTournaments = response.numberOfTournaments;
                }
                if (response.pagedTournamentItemsList) {
                    this.filteredTournamentList = response.pagedTournamentItemsList;
                    this.filteredTournamentList.forEach((t) => {
                        t.isClose = false;
                    });
                }
                if (this.totalTournaments != 0) {
                    this.setPaginationDisplay(page, this.totalTournaments);
                    this.pager = this.tournamentCalendarPager.getTournamentCalendarPager(this.totalTournaments, page);
                }
            },
            (error: any) => {
                //console.log(error);
                this.errorMessage = error + 'An error occurred while fetching data. Please try again later.';
            },
        );
    }

    setInitialPager() {
        if (this.initData) {
            this.setPaginationDisplay(1, this.initData.numberOfTournaments);
            this.pager = this.tournamentCalendarPager.getTournamentCalendarPager(this.initData.numberOfTournaments, 1);
            this.tournamentCalendarService.pageNumber = 1;
        }
    }

    setPaginationDisplay(page: number, totalTournaments: number) {
        if (page == 0) {
            page = 1;
        }
        const totalPages = Math.ceil(totalTournaments / this.defaultPageSize);
        this.startIndex = (page - 1) * this.defaultPageSize + 1;
        if (page == totalPages) {
            this.endIndex = totalTournaments;
        } else {
            this.endIndex = page * this.defaultPageSize;
        }
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournament Page Filter',
            'component.ActionEvent': 'Filter Results - Displayed',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'page 1 to ' + totalPages + ' are Displayed',
            'component.EventDetails': 'Number of Filters:2|Number of Results:' + this.totalTournaments,
            'component.URLClicked': 'not applicable',
        });
    }

    setRequiredValues() {
        if (this.initData) {
            this.tournamentCalendarService.setTitle(this.initData.pmPokerNavigationView.title);
            this.tournamentCalendarService.tournamentList = this.initData.pagedTournamentItemsList;
            this.filteredTournamentList = this.initData.pagedTournamentItemsList;
            if (this.filteredTournamentList) {
                if (this.filteredTournamentList.length > 0) {
                    this.filteredTournamentList.forEach((t) => {
                        t.isClose = false;
                    });
                }
            }
            this.totalTournaments = this.initData.numberOfTournaments;
            this.tournamentCalendarService.setGameTypes(
                this.tournamentCalendarService.getItemValues('gametypes', this.initData.formElementTemplateChilds),
            );
            this.tournamentCalendarService.setLimits(this.tournamentCalendarService.getItemValues('buyin', this.initData.formElementTemplateChilds));
            this.tournamentCalendarService.values = this.tournamentCalendarService.getItemValues(
                'gametypes',
                this.initData.formElementTemplateChilds,
            );
            this.tournamentCalendarService.setInitialDefaultFilterValues();
        }
    }

    handleTournamentClick(id: number, navigate: boolean = false) {
        if (this.summaryView || navigate) {
            this.navigation.goTo('./' + id);
        }
    }

    showDetails(id: number) {
        this.clicked = this.clickId && this.clickId === id ? !this.clicked : true;
        this.clickId = id;
    }
    action(event: any, id: string, i: number, tournament: any) {
        this.filteredTournamentList.forEach((t) => {
            t.isClose = false;
            if (t.id == tournament.id) {
                t.isClose = true;
            }
        });
        if (id == 'menu-items-mobile' && screen.width <= 620) {
            tournament.isClose = false;
        }
        //showmenu func is called if it is AddToCalendar or CloseMenu if it is Close
        if (
            document.getElementById('add-to-cal' + i)?.innerHTML.includes(this.initData.pmPokerNavigationView.messages['AddToCalendar']) ||
            document.getElementById('add-to-cal-mob' + i)?.innerHTML.includes(this.initData.pmPokerNavigationView.messages['AddToCalendar'])
        ) {
            this.showMenu(event, id, i);
        } else {
            tournament.isClose = false;
            this.closeMenu(event, id, i);
        }
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournament Page',
            'component.ActionEvent': 'Add to Calendar CTA - Clicked',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'not applicable',
            'component.EventDetails': 'User clicks on add to calendar to show options',
            'component.URLClicked': 'not applicable',
        });
    }
    showMenu(event: any, id: string, i: number) {
        // if other addtocalendar is pressed before the previous one is closed
        if (this.previousValue || this.previousValue == 0) {
            (document.getElementById(id + this.previousValue) as HTMLElement).style.display = 'none';
            (document.getElementById('add-to-cal' + this.previousValue) as HTMLElement).innerHTML =
                this.initData.pmPokerNavigationView.messages['AddToCalendar'];
            (document.getElementById('add-to-cal-mob' + this.previousValue) as HTMLElement).innerHTML =
                this.initData.pmPokerNavigationView.messages['AddToCalendar'];
        }
        this.previousValue = i;
        (document.getElementById(id + i) as HTMLElement).style.display = 'block';
        (document.getElementById('add-to-cal' + i) as HTMLElement).innerHTML = this.initData.pmPokerNavigationView.messages['text-close'];
        (document.getElementById('add-to-cal-mob' + i) as HTMLElement).innerHTML = this.initData.pmPokerNavigationView.messages['text-close'];
        if (id == 'menu-items-mobile' && screen.width <= 620) {
            (document.getElementById('add-to-cal-mob' + i) as HTMLElement).innerHTML = this.initData.pmPokerNavigationView.messages['AddToCalendar'];
            (document.getElementById('transparent-div' + i) as HTMLElement).style.display = 'block';
            document.body.style.overflow = 'hidden';
        }
    }
    closeMenu(event: any, id: string, i: number) {
        (document.getElementById(id + i) as HTMLElement).style.display = 'none';
        (document.getElementById('add-to-cal' + i) as HTMLElement).innerHTML = this.initData.pmPokerNavigationView.messages['AddToCalendar'];
        (document.getElementById('add-to-cal-mob' + i) as HTMLElement).innerHTML = this.initData.pmPokerNavigationView.messages['AddToCalendar'];
        if (id == 'menu-items-mobile' && screen.width <= 620) {
            (document.getElementById('menu-items-mobile' + i) as HTMLElement).style.display = 'none';
            (document.getElementById('transparent-div' + i) as HTMLElement).style.display = 'none';
            document.body.style.overflow = 'unset';
        }
    }
    filterTournamentListByGame(gameType: string) {
        this.GameTypeValue = gameType;
        this.fetchFilteredTournaments(gameType, this.selectedBuyIn, this.hideRunning);
    }
    hideRunnungOrFinished() {
        this.hideRunning = !this.hideRunning;
        this.fetchFilteredTournaments(this.GameTypeValue, this.selectedBuyIn, this.hideRunning);
    }

    filterTournamentListByBuyIn(buyIn: string) {
        this.selectedBuyIn = buyIn;
        this.fetchFilteredTournaments(this.GameTypeValue, buyIn, this.hideRunning);
    }
    //API call to get filtered tournaments
    fetchFilteredTournaments(gameType: string, buyIn: string, hiddenval: boolean) {
        const requestParams: any = {
            gameType: gameType,
            buyIn: buyIn,
            hideRunning: hiddenval,
        };
        this.api.get('Tournament/GetTournamentData', requestParams).subscribe(
            (response: any) => {
                this.filteredTournamentList = response.pagedTournamentItemsList;
                this.filteredTournamentList.forEach((t) => {
                    t.isClose = false;
                });
            },
            () => {},
        );
    }

    sortTournamentlist(gameType: any, buyIn: any) {
        if (this.i % 2 == 0) {
            this.sortOrder = 'asc';
            this.i++;
        } else {
            this.sortOrder = 'desc';
            this.i++;
        }
        this.sortBy = 'Time';
        this.gameType = gameType;
        this.buyIn = buyIn;
        this.tournamentType = 'AllTypes';
        this.tournamentCalendarService
            .sort(this.pageno, this.sortBy, this.sortOrder, buyIn, gameType, this.tournamentType, this.hideRunning)
            .subscribe(
                (response) => {
                    this.tournamentCalendarService.tournamentList = response.pagedTournamentItemsList;
                },
                (error) => {
                    this.messageQueue.clear();
                    this.messageQueue.add(error.data.common.messages[0]);
                },
            );
    }
    showFilters() {
        this.renderer.setStyle(this.filtersInfo.nativeElement, 'display', 'block');
        this.windowScrollingService.disable();
        window.scrollTo(0, 0);
        this.filterDisplayStatus = true;

        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournament Page',
            'component.ActionEvent': 'Filter CTA - Clicked',
            'component.PositionEvent': 'Tournament Page',
            'component.LocationEvent': 'not applicable',
            'component.EventDetails': this.deviceName,
            'component.URLClicked': 'not applicable',
        });
    }
    hideFilters(value: boolean) {
        if (!value) {
            this.filterDisplayStatus = false;
            this.renderer.setStyle(this.filtersInfo.nativeElement, 'display', 'none');
            this.windowScrollingService.enable();
        }
    }

    calenderClicked(event: string) {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Tournaments',
            'component.LabelEvent': 'Tournament Page',
            'component.ActionEvent': 'Calendar App - Choose',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'not applicable',
            'component.EventDetails': event + '-Choosen',
            'component.URLClicked': 'not applicable',
        });
    }
}
