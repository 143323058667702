import { Message } from '../../message';

export class MTCTCustomPropertyChangeEvent extends Message {
    private MESSAGE_NAME: string = 'MTCTCustomPropertyChangeEvent';
    private mtctId: number = 0;
    private buyInInfo: any | null = null;
    private partyPoints: number = 0;
    private allowedCountryCodes: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        mtctId: number = 0,
        buyInInfo: any | null = null,
        partyPoints: number = 0,
        allowedCountryCodes: string | null = null,
    ) {
        super(reqServerPeerId);
        this.mtctId = mtctId;
        this.buyInInfo = buyInInfo;
        this.partyPoints = partyPoints;
        this.allowedCountryCodes = allowedCountryCodes;
    }

    getMtctId(): number {
        return this.mtctId;
    }

    setMtctId(mtctId: number) {
        this.mtctId = mtctId;
    }
    getBuyInInfo(): any | null {
        return this.buyInInfo;
    }

    setBuyInInfo(buyInInfo: any | null) {
        this.buyInInfo = buyInInfo;
    }
    getPartyPoints(): number {
        return this.partyPoints;
    }

    setPartyPoints(partyPoints: number) {
        this.partyPoints = partyPoints;
    }
    getAllowedCountryCodes(): string | null {
        return this.allowedCountryCodes;
    }

    setAllowedCountryCodes(allowedCountryCodes: string | null) {
        this.allowedCountryCodes = allowedCountryCodes;
    }
}
