import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';

import { CookieService, Page } from '@frontend/vanilla/core';
import { PCComponent, PageMatrixComponent } from '@frontend/vanilla/features/content';
import moment from 'moment';

@Component({
    selector: 'pp-ribbon-container',
    templateUrl: 'pp-ribbon-container.component.html',
    standalone: true,
    imports: [PageMatrixComponent],
})
export class PPRibbonContainerComponent extends PCComponent {
    @ViewChild('ribbonBanner') pokerRibbon: ElementRef;
    sitecoreHtml: any;
    isEnabled: boolean;
    cookieName: string;
    cookieValue: string;

    constructor(
        private cookieService: CookieService,
        private page: Page,
    ) {
        super();
    }

    @HostBinding() get class() {
        return this.createClass(this.item.name);
    }

    ngOnInit() {
        this.sitecoreHtml = this.item.items;
        this.checkIsEnabled();
    }

    ngAfterViewInit() {
        this.appendElement();
    }

    appendElement() {
        const vnHeaderMessages = document.getElementsByTagName('vn-header-messages')[0];
        vnHeaderMessages.appendChild(this.pokerRibbon.nativeElement);
    }

    checkIsEnabled() {
        this.cookieName = 'pp-ribbon-' + this.item.name;
        this.cookieValue = this.page.domain + '-' + this.item.name;
        const getCookieValue = this.cookieService.get(this.cookieName);
        if (getCookieValue && getCookieValue == this.cookieValue) {
            this.isEnabled = false;
        } else {
            this.isEnabled = true;
        }
    }

    closeRibbon() {
        const expDate = moment().add(1, 'M').toDate();
        document.cookie = this.cookieName + '=' + this.cookieValue + '; domain=' + this.page.domain + '; path=/' + '; expires=' + expDate;
        this.isEnabled = false;
    }
}
