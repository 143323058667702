import { Message } from '../../message';

export class CasinoTrnyLevelInfo extends Message {
    private MESSAGE_NAME: string = 'CasinoTrnyLevelInfo';
    private levelId: number = 0;
    private gamesCount: number = 0;
    private duration: number = 0;
    private minBet: number = 0;
    private maxBet: number = 0;
    private levelStructId: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        levelId: number = 0,
        gamesCount: number = 0,
        duration: number = 0,
        minBet: number = 0,
        maxBet: number = 0,
        levelStructId: number = 0,
    ) {
        super(reqServerPeerId);
        this.levelId = levelId;
        this.gamesCount = gamesCount;
        this.duration = duration;
        this.minBet = minBet;
        this.maxBet = maxBet;
        this.levelStructId = levelStructId;
    }

    getLevelId(): number {
        return this.levelId;
    }

    setLevelId(levelId: number) {
        this.levelId = levelId;
    }
    getGamesCount(): number {
        return this.gamesCount;
    }

    setGamesCount(gamesCount: number) {
        this.gamesCount = gamesCount;
    }
    getDuration(): number {
        return this.duration;
    }

    setDuration(duration: number) {
        this.duration = duration;
    }
    getMinBet(): number {
        return this.minBet;
    }

    setMinBet(minBet: number) {
        this.minBet = minBet;
    }
    getMaxBet(): number {
        return this.maxBet;
    }

    setMaxBet(maxBet: number) {
        this.maxBet = maxBet;
    }
    getLevelStructId(): number {
        return this.levelStructId;
    }

    setLevelStructId(levelStructId: number) {
        this.levelStructId = levelStructId;
    }
}
