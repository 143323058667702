import { GameTable } from '../game-table';
import { PlayerStatus } from '../player-status';
import { TableEvents } from '../table-events.enum';
import { TableEventInfo } from './table-event-info';

export class CompleteBringIn extends TableEventInfo {
    getEventType(): TableEvents {
        return TableEvents.COMPLETE_BRING_IN;
    }
    getPlayerStatus(): PlayerStatus {
        return PlayerStatus.COMPLETE_BRING_IN;
    }
    next(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.addToPot(this.amount);
        gameTable.addToPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        if (!gameTable.onInit) {
            player.highlightEvent();
        }
        if (player.chips.value <= 0) {
            player.isPlayerAllIn = true;
        }
    }

    prev(gameTable: GameTable): void {
        const player = gameTable.getPlayer(this.seat);
        player.subtractFromPot(this.amount);
        gameTable.substractFromPot(this.amount);
        player.playerStatus = this.getPlayerStatus();
        player.highlightEvent();
        if (player.chips.value >= 0) {
            player.isPlayerAllIn = false;
        }
    }
}
