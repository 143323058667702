import { Message } from '../../message';
import { PvtTableType } from './PvtTableType';

export class CreatePvtTable extends Message {
    private MESSAGE_NAME: string = 'CreatePvtTable';
    private privateTableType: PvtTableType | null = null;
    private password: string | null = null;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, privateTableType: PvtTableType | null = null, password: string | null = null) {
        super(reqServerPeerId);
        this.privateTableType = privateTableType;
        this.password = password;
    }

    getPrivateTableType(): PvtTableType | null {
        return this.privateTableType;
    }

    setPrivateTableType(privateTableType: PvtTableType | null) {
        this.privateTableType = privateTableType;
    }
    getPassword(): string | null {
        return this.password;
    }

    setPassword(password: string | null) {
        this.password = password;
    }
}
