import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

import * as Hammer from 'hammerjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PIXI from 'pixi.js';

import { EventDispatcherGlobal } from '../../../service/event-despacher-global';
import { uiConfig } from '../game-config/uiconfig';

@Component({
    selector: 'pwc-lib-game-canvas',
    templateUrl: './game-canvas.component.html',
    standalone: true,
})
export class GameCanvasComponent implements AfterViewInit {
    @ViewChild('canvasContainer') canvasContainer?: ElementRef;
    @ViewChild('gameCanvas') gameCanvas?: ElementRef;
    @Input() backgroundAlpha: number = 1;
    @Output() resizeEvent: EventEmitter<any> = new EventEmitter<any>();
    app?: PIXI.Application;
    hammerInstance: HammerManager | null = null; // Store Hammer instance
    eventDispatcherGlobal = new EventDispatcherGlobal();
    constructor() {}

    ngAfterViewInit(): void {
        if (this.canvasContainer && this.gameCanvas) {
            // const nativeElement = this.canvasContainer.nativeElement;
            this.app = new PIXI.Application({
                width: uiConfig.windowDimension.width,
                height: uiConfig.windowDimension.height,
                view: this.gameCanvas.nativeElement,
                backgroundAlpha: this.backgroundAlpha,
                resolution: window.devicePixelRatio,
            });
            globalThis.__PIXI_APP__ = this.app;
            this.onResize();
            this.initializeHammer();
            this.resizeCanvas();
        }
    }

    initializeHammer() {
        this.hammerInstance = new Hammer.Manager(this.gameCanvas!.nativeElement); // Initialize Hammer
        this.hammerInstance.add(new Hammer.Swipe());
        this.hammerInstance.on('swipeleft', () => {
            const message = {};
            message['className'] = 'OnSwipe';
            message['dir'] = 'left';
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        });

        this.hammerInstance.on('swiperight', () => {
            const message = {};
            message['className'] = 'OnSwipe';
            message['dir'] = 'right';
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        });
    }

    getStage(): any {
        return this.app?.stage;
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.triggerResize();
        setTimeout(() => this.triggerResize(), 10);
    }

    triggerResize(): void {
        this.app?.resize();
        const dimensions = {
            width: this.canvasContainer?.nativeElement.offsetWidth,
            height: this.canvasContainer?.nativeElement.offsetHeight,
        };
        this.resizeEvent.emit(dimensions);
    }

    ngOnDestroy() {
        if (this.hammerInstance) {
            this.hammerInstance.destroy();
        }
    }

    //resize the canvas based on device Width and Height
    resizeCanvas() {
        uiConfig.windowWidth = window.parent.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        uiConfig.windowHeight = window.parent.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let adjustHeight = document.documentElement.clientHeight - window.parent.innerHeight;
        if (adjustHeight !== 0) {
            window.scrollBy(100, 100);
        }
        if (window.parent.innerWidth > window.parent.innerHeight) {
            uiConfig.windowWidth = window.parent.innerHeight;
            uiConfig.windowHeight = window.parent.innerWidth;
        } else {
            uiConfig.windowWidth = window.parent.innerWidth;
            uiConfig.windowHeight = window.parent.innerHeight;
        }

        this.app?.renderer.resize(uiConfig.windowWidth, uiConfig.windowHeight);
        // checkDeviceAndOS();
        // if (facade != undefined) {
        //   facade.onResize();
        // }
        if (this.app && this.app.renderer && this.app.renderer.view) {
            this.app.renderer.view.style.width = '100%';
            this.app.renderer.view.style.height = '100%';
        }
    }
}
