import { DomainChangeAdvice } from '../server/common/connectorMessageFactory/messages/DomainChangeAdvice';
import { DomainMapping } from '../server/common/connectorMessageFactory/messages/DomainMapping';
import { ExtendedAttribs } from '../server/common/connectorMessageFactory/messages/ExtendedAttribs';
import { HandShake } from '../server/common/connectorMessageFactory/messages/HandShake';
import { HandShakeResponse } from '../server/common/connectorMessageFactory/messages/HandShakeResponse';
import { NotifyPlayerProfile } from '../server/common/connectorMessageFactory/messages/NotifyPlayerProfile';
import { Ping } from '../server/common/connectorMessageFactory/messages/Ping';
import { ResponsePeerConnectivityStatus } from '../server/common/connectorMessageFactory/messages/ResponsePeerConnectivityStatus';
import { ResponseSubscribeSuccess } from '../server/common/connectorMessageFactory/messages/ResponseSubscribeSuccess';
import { ResponseUnsubscribeSuccess } from '../server/common/connectorMessageFactory/messages/ResponseUnsubscribeSuccess';
import { ServerTime } from '../server/common/connectorMessageFactory/messages/ServerTime';
import { UCID } from '../server/common/connectorMessageFactory/messages/UCID';
import { Message } from '../server/common/message';
import { FXRateSnapshot } from '../server/common/pgSharedMessageFactory/messages/FXRateSnapshot';
import { LoginRequest } from '../server/common/pgSharedMessageFactory/messages/LoginRequest';
import { LoginSuccessResponse } from '../server/common/pgSharedMessageFactory/messages/LoginSuccessResponse';
import { LoginSuccessUserProfile } from '../server/common/pgSharedMessageFactory/messages/LoginSuccessUserProfile';
import { RequestTerminateLoggedOnOtherMachine } from '../server/common/pgSharedMessageFactory/messages/RequestTerminateLoggedOnOtherMachine';
import { SSOKeyMessage } from '../server/common/pgSharedMessageFactory/messages/SSOKeyMessage';
import { UserInfo } from '../server/common/pgSharedMessageFactory/messages/UserInfo';
import { UserProfile } from '../server/common/pgSharedMessageFactory/messages/UserProfile';
import { CompositeLobbyMessage } from '../server/common/pokerMessageFactory/messages/CompositeLobbyMessage';
import { CompositeTableMessage } from '../server/common/pokerMessageFactory/messages/CompositeTableMessage';
import { CSD } from './CSD';
import { ByteBuffer } from './Lib/byteBuffer';
import { Base64Codec } from './Lib/toFromBase64';
import { PGMessageFactoryRegistration } from './PGMessageFactoryRegistration';
import { TimeZoneConfig } from './common/timeZoneConfig';
import TSUtil from './common/tsUtil';
import { ConnectionHandler } from './connectionHandler';
import { ConnectionHandlerContext } from './connectionHandlerContext';
import { ConnectionHandlerStrategy } from './connectionHandlerStrategy';
import { HealthMonitor } from './healthMonitor';
import { IConnectionManager } from './interfaces/IconnectionManager';
import { IConnectorDelegate } from './interfaces/IconnectorDelegate';
import { MessageDecoder } from './messageDecoder';
import { MessageEncoder } from './messageEncoder';
import { PrivilegedMessageManager } from './priviligedManager';
import { SubscriptionManager } from './subscriptionManager';
import { TimeSyncManager } from './timeSyncManager';

export abstract class ConnectionManager implements IConnectionManager {
    static domainConnectionServiceIps: Map<any, string> | null = new Map<any, string>();
    private static veryGoodConnection: number = 1;
    private static goodConnection: number = 2;
    private static badConnection: number = 3;
    host: string | null = null;
    delegate: IConnectorDelegate;
    public gameClientContext: ConnectionHandlerContext;
    public strategy: ConnectionHandlerStrategy = new ConnectionHandlerStrategy();
    domainPeerHealthMonitor: Map<number, HealthMonitor> = new Map<number, HealthMonitor>();
    private priviligedManager: PrivilegedMessageManager = new PrivilegedMessageManager(this);
    protected timeSync: TimeSyncManager = new TimeSyncManager(this);
    protected subscriptionManager: SubscriptionManager = new SubscriptionManager(this);
    public subscribedConnectionid: number = -1;
    private FRONTEND_ID: string = 'PP';
    private ucid: string | null = null;
    private PRODUCT_ID: string = 'POKER';
    public REAL_CS_URL: string;
    public NG_PRODUCT_ID: string = 'POKERCRM';
    public SOCIAL_PRODUCT_ID: string = 'NOTIFICATIONSERVERSERVICE';
    private CID: string = 'IW';
    private sessionLang: string;
    private BRAND_ID: string;
    public containerChannelId: string;
    private pingTimer: number = 10000;
    private peerTimer: number = 5000;
    private userProfile: UserProfile | null;
    public domainsHandlerContext: Map<number, Map<number, ConnectionHandlerContext>> = new Map<number, Map<number, ConnectionHandlerContext>>();
    private sessionKey: string | null;
    private fxSnapShotId: number = -1;
    public loginId: string | null;
    private msgFactryReg: PGMessageFactoryRegistration;
    private reloginRequired: boolean;
    private playerGTID: string | null;
    public isDomainChangeAdviceInProgress: boolean;
    public subscriptionInProgress: boolean = false;
    public subscriptionDone: boolean = false;
    private subscribedDomain: number = CSD.REAL;
    public oldSubscribedDomain: number = this.subscribedDomain;
    private gameClientNo: number = 0;
    private isFallbackToweb2tcp: boolean = false;
    disconnectionForMoreThan2Minutes: boolean = false;
    duplicateLogin: boolean = false;

    protected constructor(serverIp: string) {
        this.host = serverIp;
        this.gameClientContext = new ConnectionHandlerContext();
        this.gameClientContext.setDomain(CSD.REAL);
        const gameClientMap = new Map<number, ConnectionHandlerContext>();
        gameClientMap.set(this.strategy.lookupOrCreateConnectionId(CSD.REAL, this.getGameClientPeerId()), this.gameClientContext);
    }

    sendMessageOnPeerId(msg: Message, peerId: number): void {
        this.sendMessage(msg, this.strategy.getPeersDomainId(peerId));
    }

    public static getVeryGoodConnection() {
        return ConnectionManager.veryGoodConnection;
    }

    public static getGoodConnection() {
        return ConnectionManager.goodConnection;
    }

    public static getBadConnection() {
        return ConnectionManager.badConnection;
    }

    getGameClientPeerId(): number {
        return this.strategy.getGameClientPeerId();
    }

    setDelegate(delegate: IConnectorDelegate): void {
        this.delegate = delegate;
    }

    getDelegate(): IConnectorDelegate {
        return this.delegate;
    }
    setisFallbackToweb2tcp(isFallbackToweb2tcp: boolean) {
        this.isFallbackToweb2tcp = isFallbackToweb2tcp;
    }

    getisFallbackToweb2tcp(): boolean {
        return this.isFallbackToweb2tcp;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initiateConnectionWithParams(params: Map<string, any>): void {}

    closeAllConnections(): void {
        for (const [domainId] of this.domainsHandlerContext) {
            this.closeConnection(domainId);
        }
    }

    resumeAllConnections(): void {
        throw new Error('Method not implemented.');
    }

    setSessionKey(sessionKey: string) {
        this.sessionKey = sessionKey;
    }

    getSessionKey(): string | null {
        return this.sessionKey;
    }

    closeConnection(domainId: number): void {
        const connHandCtxMap: Map<number, ConnectionHandlerContext> | undefined | null = this.domainsHandlerContext.get(domainId);
        if (connHandCtxMap) {
            for (const [connId] of connHandCtxMap) {
                const ctx: ConnectionHandlerContext | undefined | null = connHandCtxMap.get(connId);
                if (ctx) {
                    const handler = ctx.getHandler();
                    if (handler) {
                        handler.closeConnection();
                    }
                }
            }
        }
    }

    setMessageFactoryRegistration(msgFctryRegister: PGMessageFactoryRegistration): void {
        this.msgFactryReg = msgFctryRegister;
    }

    sendClientLogout(): void {
        this.refreshHandShake();
        for (const [, ConnHandCtxMap] of this.domainsHandlerContext.entries()) {
            if (ConnHandCtxMap) {
                for (const [, ctx] of ConnHandCtxMap.entries()) {
                    if (ctx) {
                        const connHandler: ConnectionHandler | null = ctx.getHandler();
                        if (connHandler) {
                            connHandler.doLogOut();
                        }
                    }
                }
            }
        }
    }

    refreshHandShake() {
        this.userProfile = null;
        this.sessionKey = null;
        this.loginId = null;
        this.gameClientContext.setLoggedIn(false);
    }

    closePlayConnection(): void {
        this.closeConnection(CSD.PLAY);
        this.delegate.log(CSD.LOG_INFO, 'Play Connection Closed');
    }

    closeNonGamingConnection(): void {
        this.closeConnection(CSD.NON_GAMING);
        this.delegate.log(CSD.LOG_INFO, 'NonGaming Connection Closed');
    }

    createSocialConnection(): void {
        const domain: number = CSD.SOCIAL;
        const connId: number = this.strategy.lookupOrCreateConnectionId(domain, CSD.DUMMY_SOCIAL_DOMAIN_PEERID);
        this.lookupOrCreateConnHandContext(domain, connId);
    }

    closeSocialConnection(): void {
        this.closeConnection(CSD.SOCIAL);
        this.delegate.log(CSD.LOG_INFO, 'Social Connection Closed');
    }

    setLoginInProgress(bool: boolean) {
        this.gameClientContext.setLoginInProgress(bool);
    }

    getLoginInProgress(): boolean {
        return this.gameClientContext.getisLoginInProgress();
    }

    getGameClientContext(): ConnectionHandlerContext {
        return this.gameClientContext;
    }

    initiateConnections(params: Map<string, any>) {
        this.FRONTEND_ID = params.get('FRONTEND_ID');
        this.ucid = params.get('UCID');
        this.PRODUCT_ID = params.get('PRODUCT_ID');
        this.CID = params.get('CID');
        this.REAL_CS_URL = params.get('REAL_CS_URL');
        this.setisFallbackToweb2tcp(params.get('isFallbackToweb2tcp'));
        this.sessionLang = params.get('SL');
        this.BRAND_ID = params.get('BRAND_ID');
        if (params.get('CONTAINER_CHANNEL_ID') != null) {
            this.containerChannelId = params.get('CONTAINER_CHANNEL_ID');
        } else {
            this.containerChannelId = this.CID;
        }
    }

    connectToServer(peerId: number, domain: number): void {
        const connId: number = this.strategy.lookupOrCreateConnectionId(domain, peerId);
        const context: ConnectionHandlerContext = this.lookupOrCreateConnHandContext(domain, connId);

        // Adding the zero peerId
        context.peers.push(peerId);
        this.subscribedConnectionid = this.strategy.lookupPeersConnectionId(peerId);
        context.connId = this.subscribedConnectionid;
    }

    isDefault(domainId: number): boolean {
        let isDefault: boolean = true;
        const connectionHandlerContexts: Map<number, ConnectionHandlerContext> | undefined = this.domainsHandlerContext.get(domainId);

        if (connectionHandlerContexts !== undefined) {
            for (const connId of connectionHandlerContexts.keys()) {
                const ctx: ConnectionHandlerContext | undefined = connectionHandlerContexts.get(connId);

                if (ctx === undefined) {
                    continue;
                }

                const isValidConnection = ctx.isInitialHandshakeDone();

                if (isValidConnection) {
                    isDefault = false;
                }
            }
        }
        return isDefault;
    }

    public getConnectionName(connId: number, domain: number) {
        return this.strategy.getConnectionName(connId, domain);
    }

    getCtxHandler(domainId: number): ConnectionHandlerContext {
        let peerId = -1;
        if (domainId == CSD.REAL) {
            peerId = CSD.DUMMY_REAL_DOMAIN_PEERID;
        } else if (domainId == CSD.NON_GAMING) {
            peerId = CSD.DUMMY_NON_GAMING_DOMAIN_PEERID;
        } else if (domainId == CSD.SOCIAL) {
            peerId = CSD.DUMMY_SOCIAL_DOMAIN_PEERID;
        } else {
            peerId = CSD.DUMMY_PLAY_DOMAIN_PEERID;
        }
        const connId = this.strategy.lookupOrCreateConnectionId(domainId, peerId);
        return this.lookupOrCreateConnHandContext(domainId, connId);
    }

    doHandshake(isDefault, connId, domainId) {
        const hs = this.getHandshakeMessage(isDefault, connId, domainId);
        if (hs) this.sendMessage(hs, domainId);
        this.delegate.log(CSD.LOG_INFO, `Handshake Message Sent!! for domain ${domainId}`);
    }

    public createNonGamingConnection() {
        const domain: number = CSD.NON_GAMING;
        const connId: number = this.strategy.lookupOrCreateConnectionId(domain, CSD.DUMMY_NON_GAMING_DOMAIN_PEERID);
        this.lookupOrCreateConnHandContext(domain, connId);
    }

    lookupConnHandContext(peerId: number): ConnectionHandlerContext | null {
        const connId: number = this.strategy.lookupPeersConnectionId(peerId);

        if (connId === -1) {
            return null;
        }

        for (const domain of this.domainsHandlerContext.keys()) {
            const connectionHandlerContext = this.domainsHandlerContext.get(domain);

            if (connectionHandlerContext && connectionHandlerContext.has(connId)) {
                const ctx: ConnectionHandlerContext | undefined = connectionHandlerContext.get(connId);

                if (ctx) {
                    const peersOnTheConnection = [...ctx.getPeers()];

                    for (const peer of peersOnTheConnection) {
                        if (peer === peerId) {
                            return ctx;
                        }
                    }
                }
            }
        }

        return null;
    }
    getTimeForPeer(peerId: number) {
        return this.timeSync.getServerClockForPeer(peerId);
    }

    sendMessage(message: Message, domainId: number) {
        if (message instanceof LoginRequest) {
            this.setLoginInProgress(true);
        }
        if (message.isPrivileged()) {
            this.priviligedManager.cacheIfPrivilegedMessage(message, domainId);
        }
        const bytes = new MessageEncoder(this.msgFactryReg).convertMessageToByteStream(message);
        const ctxHandler = this.getCtxHandler(domainId);
        const handler = ctxHandler.getHandler();
        if (this.getisFallbackToweb2tcp()) {
            const toBase64 = Base64Codec.toBase64(bytes.array);
            if (handler) handler.sendMessage(toBase64);
        } else {
            if (handler) handler.sendMessage(new Uint8Array(bytes.array));
        }
    }

    sendMessageOnDomain(message: Message, domainId: number) {
        this.sendMessage(message, domainId);
    }

    receiveMessage(bytes: ByteBuffer, domainId: number, connId: number) {
        const message: Message | undefined | null = new MessageDecoder(this.msgFactryReg).receiveBytesFromServer(bytes);
        if (message == null || undefined) {
            return;
        }
        if (message.getMsgNumber() > 0) {
            this.priviligedManager.removeAcknowledgedMessage(message.getMsgNumber());
        }
        if (message instanceof CompositeTableMessage) {
            const messages = message.getMessages();
            if (messages)
                messages.forEach((msg) => {
                    msg.reqServerPeerId = message.getReqServerPeerId();
                    this.dispatchMessage(msg, domainId, connId);
                });
        } else if (message instanceof CompositeLobbyMessage) {
            const messages = message.getMessages();
            if (messages)
                messages.forEach((msg) => {
                    msg.reqServerPeerId = message.getReqServerPeerId();
                    this.dispatchMessage(msg, domainId, connId);
                });
        }
        this.dispatchMessage(message, domainId, connId);
    }

    dispatchMessage(msg: Message, domainId: number, connId: number) {
        if (msg instanceof UCID) {
            this.ucid = msg.getId();
        } else if (msg instanceof HandShakeResponse) {
            this.handleHandShakeResponse(msg, domainId, connId);
            if (domainId == CSD.REAL) {
                if (msg.getResponseId() === 1) {
                    if (domainId != CSD.NON_GAMING && domainId != CSD.SOCIAL) {
                        this.subscriptionManager.sendSubscriptionRequest(this.oldSubscribedDomain);
                    }
                }
            }
        } else if (msg instanceof Ping) {
            this.delegate.updateConnectionStatus(0, ConnectionManager.getGoodConnection());
        } else if (msg instanceof FXRateSnapshot) {
            this.fxSnapShotId = msg.getSnapshotId();
        } else if (msg instanceof ServerTime) {
            this.timeSync.handleServerTime(msg, domainId);
        } else if (msg instanceof DomainMapping) {
            ConnectionManager.domainConnectionServiceIps = msg.getDomainMap();
        } else if (msg instanceof ResponsePeerConnectivityStatus) {
        } else if (msg instanceof UserInfo) {
            this.userProfile = msg.getProfile();
            if (this.userProfile !== null) this.loginId = this.userProfile.getLoginId();
        } else if (msg instanceof LoginSuccessUserProfile) {
            this.userProfile = msg.getUserProfile();
            this.handleLoginSuccessUserProfile(domainId);
        } else if (msg instanceof LoginSuccessResponse) {
            this.setLoginInProgress(false);
            this.playerGTID = msg.getSsoKey();
            this.reloginRequired = false;
            this.gameClientContext.setLoggedIn(true);
            const ctxHandler = this.lookupOrCreateConnHandContext(domainId, connId);
            if (ctxHandler !== null) {
                const handler = ctxHandler.getHandler();
                if (handler) this.delegate.connectionSuccessforServer(handler.getHost());
            }
        } else if (msg instanceof ResponseSubscribeSuccess) {
            this.subscriptionManager.handleSubscriptionSuccess(msg, domainId);
        } else if (msg instanceof ResponseUnsubscribeSuccess) {
        } else if (msg instanceof DomainChangeAdvice) {
        } else if (msg instanceof SSOKeyMessage) {
        } else if (msg instanceof RequestTerminateLoggedOnOtherMachine) {
            this.duplicateLogin = true;
        }
        this.delegate.handleMessage(msg, msg.getReqServerPeerId(), domainId);
    }

    handleHandShakeResponse(hsr: HandShakeResponse, domainId: number, connId: number) {
        const handler = this.lookupOrCreateConnHandContext(domainId, connId);
        if (hsr.getResponseId() === 1) {
            this.delegate.handshakeResponseStatus(true, domainId);
            this.gameClientContext.setInitialHandshakeDone(true);
            if (handler !== null) {
                const connHandler: ConnectionHandler | null = handler.getHandler();
                if (connHandler !== null) {
                    connHandler.setPinger(new HealthMonitor(connHandler, this.pingTimer, this.peerTimer, true, false));
                    this.domainPeerHealthMonitor.set(domainId, connHandler.getPinger());
                }
            }
        } else {
            this.delegate.handshakeResponseStatus(false, domainId);
            return;
        }
        const sKey: string | null = hsr.getSessionKey();
        if (sKey !== null && sKey !== undefined) {
            this.setSessionKey(sKey);
        }
        if (domainId != CSD.NON_GAMING && domainId != CSD.SOCIAL) {
            this.timeSync.sendTimeSyncRequest(domainId);
        }
        this.completeHandshakeResponse(domainId, connId);
        this.delegate.updateConnectionStatus(CSD.REAL, ConnectionManager.getGoodConnection());
        if (this.reloginRequired) {
            const login: LoginRequest = new LoginRequest();
            login.setSsoKey(this.playerGTID);
            this.sendMessageOnDomain(login, domainId);
        }
    }

    completeHandshakeResponse(domainId: number, connId: number) {
        const contextHandler = this.lookupOrCreateConnHandContext(domainId, connId);
        contextHandler.setInitialHandshakeDone(true);
    }

    getHandshakeMessage(isDefault: boolean, connId: number, domainId: number): HandShake | null {
        try {
            const hs = new HandShake();
            const attribute: any[] = [];
            const attributes: {
                [key: string]: any;
            } = {};
            const timeZoneConfig = new TimeZoneConfig();
            const extendedAttribs = new ExtendedAttribs();
            const ARABuildNumber = 90;
            const GRABuildNumber = 1;
            let type: number;

            hs.setFrontendId(this.FRONTEND_ID);
            hs.setUcid(this.ucid);
            hs.setSessionKey(null);
            if (!this.gameClientContext.isInitialHandshakeDone()) {
                type = TSUtil.HANDSHAKE_INIT_CONNECTION;
                if (this.userProfile != null) {
                    hs.setLoginId(this.userProfile.getLoginId());
                    this.reloginRequired = true;
                }
            } else {
                if (this.userProfile != null && this.userProfile.getLoginId() != null) {
                    hs.setLoginId(this.userProfile.getLoginId());
                    type = TSUtil.HANDSHAKE_RELOGIN;
                    attributes['GTID'] = this.playerGTID;
                    if (domainId == CSD.NON_GAMING || domainId == CSD.SOCIAL) {
                        attributes['SSO_KEY'] = this.playerGTID;
                        type = TSUtil.HANDSHAKE_INIT_CONNECTION;
                    }
                } else {
                    type = TSUtil.HANDSHAKE_REVISIT;
                }
                if (domainId != CSD.NON_GAMING && domainId != CSD.SOCIAL) {
                    hs.setSessionKey(this.getSessionKey());
                }
                if (domainId == CSD.NON_GAMING || domainId == CSD.SOCIAL) {
                    attributes['NON_SUBSCRIBE_RECONNECTED'] = 'TRUE';
                    attributes['SUBSCRIBED_DOMAIN'] = this.subscribedDomain;
                }
            }
            const encProfile: any = this.userProfile ? this.userProfile.getEncProfile() : null;
            hs.setType(type);
            hs.setEncProfile(encProfile);
            hs.setARABuildNumber(ARABuildNumber);
            hs.setGRABuildNumber(GRABuildNumber);
            hs.setPassword('');
            attributes['CID'] = this.CID;
            attributes['CONTAINER_CHANNEL_ID'] = this.CID;
            attributes['SL'] = this.sessionLang;
            attributes['BRAND_ID'] = this.BRAND_ID;
            attributes['USER_TIME_ZONE_ID'] = timeZoneConfig.localTimeZoneStandardName();
            attributes['CONNECTION_NAME'] = this.getConnectionName(connId, domainId);
            attributes['FX_SNAPSHOT_ID'] = this.fxSnapShotId + '';
            attributes['LAUNCH_TYPE'] = 'IL';
            extendedAttribs.setExtendedAttribs(attributes);
            attribute.push(extendedAttribs);
            if (domainId == CSD.NON_GAMING) {
                hs.setProductId(this.NG_PRODUCT_ID);
                attributes['NS_INVOKER_PRODUCT'] = this.PRODUCT_ID;
            } else if (domainId == CSD.SOCIAL) {
                hs.setProductId(this.SOCIAL_PRODUCT_ID);
                attributes['NS_INVOKER_PRODUCT'] = this.PRODUCT_ID;
            } else {
                hs.setProductId(this.PRODUCT_ID);
                attributes['INVOKER_PRODUCT'] = this.PRODUCT_ID;
            }
            hs.setMessageVector(attribute);
            return hs;
        } catch (e) {
            this.delegate.log(CSD.LOG_ERROR, 'Handshake Message Error at getHandshakeMessage(): ' + e);
            return null;
        }
    }

    handleLoginSuccessUserProfile(domainId: number) {
        const playerProfile: NotifyPlayerProfile = new NotifyPlayerProfile();
        playerProfile.setReqServerPeerId(this.getGameClientPeerId());
        playerProfile.setSessionKey(this.sessionKey);
        if (this.userProfile) playerProfile.setEncryptedProfile(this.userProfile.getEncProfile());
        this.sendMessageOnDomain(playerProfile, domainId);
    }

    handleDomainChangeAdvice(msg: DomainChangeAdvice): void {
        this.isDomainChangeAdviceInProgress = true;
        this.subscriptionDone = false;

        const getDomainName: string | null = msg.getDomainName();
        let subscribedDomain;
        if (getDomainName) {
            subscribedDomain = parseInt(getDomainName, 10);
        }
        this.delegate.log(CSD.LOG_INFO, `Handling domainChangeAdvice message for subscribedDomain: ${subscribedDomain}`);

        const newConnectionId = this.strategy.getNewConnectionId(subscribedDomain);
        this.subscribedConnectionid = newConnectionId;
    }

    public addPeer(peerId: number, domainId: number) {
        if (peerId === this.getGameClientPeerId()) {
            if (!this.gameClientContext) {
                this.gameClientContext = new ConnectionHandlerContext();
            }

            if (!this.gameClientContext.peers) {
                this.gameClientContext.peers = [];
            }

            this.gameClientContext.peers.unshift(peerId);

            const connectionsHandlerContext = new Map<number, ConnectionHandlerContext>();
            const connId = this.strategy.lookupOrCreateConnectionId(domainId, peerId);
            connectionsHandlerContext.set(connId, this.gameClientContext);
            this.domainsHandlerContext.set(domainId, connectionsHandlerContext);
        } else {
            const connId = this.strategy.lookupOrCreateConnectionId(domainId, peerId);
            const ctx = this.lookupOrCreateConnHandContext(domainId, connId);

            ctx.peers.push(peerId);
            this.getCtxHandler(domainId).getHandler();
            const healthChecker = this.domainPeerHealthMonitor.get(domainId);
            if (healthChecker)
                if (!healthChecker.enabledRpcs) {
                    healthChecker.addTableId(peerId);
                    healthChecker.startRPCS();
                } else {
                    healthChecker.addTableId(peerId);
                }
        }

        this.delegate.log(CSD.LOG_INFO, `Added peer ${peerId} on domain ${domainId}`);

        this.delegate.log(CSD.LOG_INFO, `DomainPeerMap = ${JSON.stringify(this.strategy.getExistingConnIdsMap())}`);
    }

    removePeer(peerID: number, domainID: number): void {
        if (peerID !== 0 && peerID === this.gameClientNo) {
            return;
        }

        const ctx = this.lookupConnHandContext(peerID);
        if (ctx === null) return;

        const vector: number[] = ctx.getPeers();
        const peerIndex = vector.indexOf(peerID);
        if (peerIndex !== -1) {
            vector.splice(peerIndex, 1);
        }

        /*
         * Remove the health checker for this tableid; it's important that this
         * happens before the conn-handler/conn-hand-context is shut down, or
         * else this recreates a new connection-handler and sends the
         * connectivity status req on it.
         */
        const healthChecker: HealthMonitor | undefined | null = this.domainPeerHealthMonitor.get(domainID);
        if (healthChecker !== null && healthChecker !== undefined) {
            healthChecker.removeTableId(peerID);
            if (healthChecker.getArrofTbleids().length === 0) {
                healthChecker.stopRPCS();
            }
        }

        // Removing peerID from existingConnIdsMap in ConnectionHandlerStrategy.
        this.strategy.removePeerFromDomain(peerID, domainID);

        if (vector.length === 0) {
            // Removing the connection
            this.domainsHandlerContext.get(domainID)?.delete(this.strategy.lookupPeersConnectionId(peerID));

            // Removing the domain if all the connections are closed
            if (this.domainsHandlerContext.get(domainID)?.size === 0) {
                this.domainsHandlerContext.delete(domainID);
            }
        }

        this.delegate.log(CSD.LOG_INFO, `Removed peer ${peerID} on domain ${domainID}`);
        this.delegate.log(CSD.LOG_INFO, `DomainPeerMap = ${this.strategy.getExistingConnIdsMap()}`);
    }

    protected lookupOrCreateConnHandContext(domainId: number, connectionId: number): ConnectionHandlerContext {
        let contextMap = this.domainsHandlerContext.get(domainId);
        if (contextMap === undefined) {
            contextMap = new Map<number, ConnectionHandlerContext>();
            this.domainsHandlerContext.set(domainId, contextMap);
        }
        // Populating handler context; connectionHandler
        let ctx = contextMap.get(connectionId);
        if (ctx === undefined) {
            ctx = new ConnectionHandlerContext();
            ctx.setConnId(connectionId);
            ctx.setDomain(domainId);
            contextMap.set(connectionId, ctx);
        }
        if (ctx.getHandlerConnector() === null) {
            const finalCtx = ctx;
            const handlerConnector = {
                start: () => {
                    try {
                        this.connectHandler(finalCtx);
                    } catch (e) {
                        console.error(`Error while initiating ConnectionHandler for domain ${domainId}`, e);
                        this.delegate.log(CSD.LOG_ERROR, `Error while initiating ConnectionHandler for domain ${domainId} and error is :` + e);
                    }
                },
            };
            ctx.setHandlerConnector(handlerConnector);
            handlerConnector.start();
        }
        return ctx;
    }

    private connectHandler(ctx: ConnectionHandlerContext) {
        let handler: ConnectionHandler | null = null;
        let ipTobeConnected: string | null = null;
        ipTobeConnected = ipTobeConnected !== undefined ? ipTobeConnected : null;
        if (ipTobeConnected === null) {
            ipTobeConnected = this.host;
        } else {
            if (ipTobeConnected) {
                ipTobeConnected = `wss://${ipTobeConnected}:2160/`;
                this.host = `wss://${ipTobeConnected}:2160/`;
            }
        }
        // eslint-disable-next-line no-console
        console.log(`Handshake Domain:${ctx.getDomain()} host:${ipTobeConnected}`);
        this.delegate.log(CSD.LOG_INFO, `Handshake Domain:${ctx.getDomain()} host:${ipTobeConnected}`);

        try {
            handler = new ConnectionHandler(this, ipTobeConnected, ctx.getConnId(), ctx.getDomain(), ctx);
            ctx.setHandler(handler);
        } catch (e: any) {
            this.delegate.log(CSD.LOG_ERROR, `Exception in ConnectionManager::connectHandler() and error is :` + e);
            throw new Error('Exception in ConnectionManager::connectHandler()' + e);
        }
    }
}
