import { Message } from '../../message';

export class GiveTourneyChips extends Message {
    private MESSAGE_NAME: string = 'GiveTourneyChips';
    private tourneyChips: number = 0;
    private trnyStartedAt: number = 0;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(reqServerPeerId: number = 0, tourneyChips: number = 0, trnyStartedAt: number = 0) {
        super(reqServerPeerId);
        this.tourneyChips = tourneyChips;
        this.trnyStartedAt = trnyStartedAt;
    }

    getTourneyChips(): number {
        return this.tourneyChips;
    }

    setTourneyChips(tourneyChips: number) {
        this.tourneyChips = tourneyChips;
    }
    getTrnyStartedAt(): number {
        return this.trnyStartedAt;
    }

    setTrnyStartedAt(trnyStartedAt: number) {
        this.trnyStartedAt = trnyStartedAt;
    }
}
