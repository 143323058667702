import { Message } from '../../message';

export class TableBoosterWinners extends Message {
    private MESSAGE_NAME: string = 'TableBoosterWinners';
    private gameId: number = 0;
    private boosterType: number = 0;
    private boosterMultiplyValue: number = 0;
    private boosterCalcValue: number = 0;
    private boosterWinners: any[] | null = null;
    private prizeRevealBoosterRangeVals: any[] | null = null;
    private shortAnimation: boolean | null = false;

    getMessageName(): string {
        return this.MESSAGE_NAME;
    }

    constructor();

    constructor(
        reqServerPeerId: number = 0,
        gameId: number = 0,
        boosterType: number = 0,
        boosterMultiplyValue: number = 0,
        boosterCalcValue: number = 0,
        boosterWinners: any[] | null = null,
        prizeRevealBoosterRangeVals: any[] | null = null,
        shortAnimation: boolean | null = false,
    ) {
        super(reqServerPeerId);
        this.gameId = gameId;
        this.boosterType = boosterType;
        this.boosterMultiplyValue = boosterMultiplyValue;
        this.boosterCalcValue = boosterCalcValue;
        this.boosterWinners = boosterWinners;
        this.prizeRevealBoosterRangeVals = prizeRevealBoosterRangeVals;
        this.shortAnimation = shortAnimation;
    }

    getGameId(): number {
        return this.gameId;
    }

    setGameId(gameId: number) {
        this.gameId = gameId;
    }
    getBoosterType(): number {
        return this.boosterType;
    }

    setBoosterType(boosterType: number) {
        this.boosterType = boosterType;
    }
    getBoosterMultiplyValue(): number {
        return this.boosterMultiplyValue;
    }

    setBoosterMultiplyValue(boosterMultiplyValue: number) {
        this.boosterMultiplyValue = boosterMultiplyValue;
    }
    getBoosterCalcValue(): number {
        return this.boosterCalcValue;
    }

    setBoosterCalcValue(boosterCalcValue: number) {
        this.boosterCalcValue = boosterCalcValue;
    }
    getBoosterWinners(): any[] | null {
        return this.boosterWinners;
    }

    setBoosterWinners(boosterWinners: any[] | null) {
        this.boosterWinners = boosterWinners;
    }
    getPrizeRevealBoosterRangeVals(): any[] | null {
        return this.prizeRevealBoosterRangeVals;
    }

    setPrizeRevealBoosterRangeVals(prizeRevealBoosterRangeVals: any[] | null) {
        this.prizeRevealBoosterRangeVals = prizeRevealBoosterRangeVals;
    }
    getShortAnimation(): boolean | null {
        return this.shortAnimation;
    }

    setShortAnimation(shortAnimation: boolean | null) {
        this.shortAnimation = shortAnimation;
    }
}
