import { Message } from '../server/common/message';
import { CSD } from './CSD';
import { Timer } from './common/timer';
import { ConnectionManager } from './connectionManager';

export class PrivilegedMessageManager {
    private manager: ConnectionManager;
    private PendingMessages: Map<number, any> = new Map<number, any>();
    // private PrivilegedMessageNumberCounter: number = 1;
    private PendingMessageResendTimer: Timer;

    constructor(manager: ConnectionManager) {
        this.manager = manager;
        this.PendingMessageResendTimer = new Timer(1000, this.handlePendingMessageResendTimer.bind(this));
        this.PendingMessageResendTimer.Start();
    }

    // Method to cache if privileged message.
    public cacheIfPrivilegedMessage(message: Message, domainId: number): void {
        if (!this.isMessageAlreadyCached(message.getMsgNumber())) {
            message['domainId'] = domainId;
            message.setLastMessageSendTime(new Date().getTime() / 1000);

            // If the message is with the block status, then send the message after an interval of time
            if (message.isBlocked()) {
                message.setSecondsToWaitBeforeResend(40);
                message.setTimesMessageSent(1);
            } else {
                message.setSecondsToWaitBeforeResend(60);
            }
            this.PendingMessages.set(message.getMsgNumber(), message);
            this.manager.delegate.log(
                CSD.LOG_INFO,
                `JSPrivilegedMessageManager::cacheIfPrivilegedMessage:: Cached Privileged Message <ClassId : ${message.getMessageName()}> with Message Number : ${message.getMsgNumber()}`,
            );
        }
    }

    // Method to check if a message is already cached.
    public isMessageAlreadyCached(msgNumber: number): boolean {
        return msgNumber in this.PendingMessages;
    }

    // Method to remove acknowledged message.
    public removeAcknowledgedMessage(msgNumber: number): void {
        if (this.PendingMessages.has(msgNumber)) {
            this.manager.delegate.log(
                CSD.LOG_INFO,
                `JSPrivilegedMessageManager::removeAcknowledgedMessage:: Removed Privileged Message <MessageClass : ${this.PendingMessages.get(msgNumber).MESSAGE_NAME}> with Message Number : ${msgNumber}`,
            );
            this.PendingMessages.delete(msgNumber);
        }
    }

    // Method to send all queued messages.
    public sendQueuedMessages(): void {
        this.PendingMessages.forEach((msg) => {
            if (msg.domainId !== undefined) {
                this.manager.sendMessageOnDomain(msg, msg.domainId);
            } else {
                this.manager.sendMessageOnPeerId(msg, msg.reqServerPeerId);
            }
            this.manager.delegate.log(
                CSD.LOG_INFO,
                `PrivilegedMessageManager::sendQueuedMessages:: Resending Privileged Message <MessageClass : ${msg.MESSAGE_NAME}> with Message Number : ${msg.msgNumber}`,
            );
        });
    }

    // Method to update ssoKey for cached messages.
    public updateSSOKeyForQueuedMessages(ssoKey: any): void {
        for (const msgNumber in this.PendingMessages) {
            const messageInfo = this.PendingMessages[msgNumber];

            if (messageInfo.message.classId === 41199) {
                //Login Request
                messageInfo.message.ssoKey = ssoKey;
            }
        }
    }

    // Handle the Pending Message Resend Timer
    private handlePendingMessageResendTimer(): void {
        const currentTime: number = Date.now() / 1000;
        const messagesToRemove: number[] = [];

        for (const msgNumber of this.PendingMessages.keys()) {
            const message: Message = this.PendingMessages.get(msgNumber);
            if (!message) continue;

            const secondsSinceLastTry: number = currentTime - message.getLastMessageSendTime();

            if (secondsSinceLastTry > message.getSecondsToWaitBeforeResend()) {
                // If it's a blocked message or the number of times to send the message limit is crossed,
                // then remove the message from the privileged queue and post the failure.
                if (message.isBlocked() || message.getTimesMessageSent() >= 4) {
                    messagesToRemove.push(msgNumber);
                } else {
                    message.setTimesMessageSent(message.getTimesMessageSent() + 1);
                    message.setLastMessageSendTime(currentTime);
                    this.manager.sendMessageOnPeerId(message, message.getReqServerPeerId());
                }
            }
        }

        for (const removeIndex of messagesToRemove) {
            this.PendingMessages.delete(removeIndex);
        }
    }
}
