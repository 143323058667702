import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';

@Component({
    selector: 'pwc-de-balance-pop-up',
    templateUrl: './de-balance-pop-up.component.html',
    standalone: true,
    imports: [TranslatePipe],
    providers: [TranslatePipe],
})
export class DeBalancePopUpComponent implements OnInit {
    // debalnceObtainedInfo: any; //IDataInfo;
    balanceLimitInfoLiteral = '';
    balanceLimitInfo = '';
    doNotShowText = '';
    btnText = '';
    athBlockTime;
    balanceIcons;
    @Output() closeGermanBalanceInfo = new EventEmitter<void>();
    @Input() debalnceObtainedInfo: any;

    constructor(
        private translatePipe: TranslatePipe,
        private clientToServerServices: ClientToServerRequestServices,
        // private dialogRef: MatDialogRef<DeBalancePopUpComponent>,
        // @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        // this.debalnceObtainedInfo = "data";
        this.balanceIcons = this.clientToServerServices.webAssets['lobby']['de-balance'];
    }
    doNotShow() {
        // let propertySettings = {
        //   DO_NOT_SHOW_BALANCE_REDUCTION_POPUP: JSON.stringify(1)
        // }
        // window.AppController.updateUserPropSettings(propertySettings);
        localStorage.setItem('DO_NOT_SHOW_BALANCE_REDUCTION_POPUP', JSON.stringify(1));
        this.closeGermanBalanceInfo.emit();
    }
    ngOnInit() {
        this.balanceLimitInfoLiteral = this.translatePipe.transform('PARTY_POKER_MOBILE_COMMON_TABLE_BALANCE_LIMIT_POPUP');
        this.balanceLimitInfo = this.balanceLimitInfoLiteral.replace('%1s', this.debalnceObtainedInfo);
        this.doNotShowText = this.translatePipe.transform('PARTY_POKER_GC_QSLOBBY_ADDFAVDLGDONOTSHOW').toUpperCase();
        this.btnText = this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK').toUpperCase();
    }

    closePopUp() {
        this.closeGermanBalanceInfo.emit();
        // this.dialogRef.close();
    }

    // navigateToSpecified(route) {
    //   this.activeModal.close(CLICK_STATUS.AUTO_CLOSE);
    //   this._router.navigate(route);
    // }
    closeErrorPage() {
        this.closeGermanBalanceInfo.emit();
        // this.activeModal.close([]);
    }
}
